import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformTagJunctionService } from '../services/transform-tag-junction.service';

export class TransformTagJunction extends ActiveRecord {
  protected provider = TransformTagJunctionService;

  public id: number;
  public name: string;
  public transformation_id: number;
  public tag_id: number;
  public is_input: number;

  protected fields() {
    return [
      'id',
      'tag_id',
      'transformation_id',
      'is_input',

      'name',
    ];
  }
}


