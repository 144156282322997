import { EventEmitter } from '@angular/core';
import {
    ISpinnerDefault
  } from '../../spinner/spinner-completion.interface';
  import { EDeepStreamMessage } from '../../values/deepstream-message-type.enum';
import { BaseDeepStreamService } from './base-deepstream.service';

export class ReportPdfDeepStream extends BaseDeepStreamService {

    private static _reportPdfErrorsStream$: EventEmitter<ISpinnerDefault> = new EventEmitter<ISpinnerDefault>();
    private static _reportPdfSuccessStream$: EventEmitter<ISpinnerDefault> = new EventEmitter<ISpinnerDefault>();
    private static _reportPdfProgressStream$: EventEmitter<ISpinnerDefault> = new EventEmitter<ISpinnerDefault>();

    protected messageTypes: EDeepStreamMessage[] = [
        EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_ERRORS,
        EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_PROGRESS,
        EDeepStreamMessage. DEEPSTREAM_EVENT_REPORT_SUCCESS
    ];

    public receiver: string

    public emit(data: any) {
        switch (data.type) {
            case EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_ERRORS:
                return ReportPdfDeepStream._reportPdfErrorsStream$.emit(data);
            case EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_PROGRESS:
                return ReportPdfDeepStream._reportPdfProgressStream$.emit(data);
            case EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_SUCCESS:
                return ReportPdfDeepStream._reportPdfSuccessStream$
                  .emit(data);
        }
    }

    public get reportPdfErrorsStream$(): EventEmitter<any> {
        return ReportPdfDeepStream._reportPdfErrorsStream$;
    }

    public get reportPdfSuccessStream$(): EventEmitter<any> {
        return ReportPdfDeepStream._reportPdfSuccessStream$;
    }
    public get reportPdfProgressStream$(): EventEmitter<any> {
        return ReportPdfDeepStream._reportPdfProgressStream$;
    }

    

}