<article [formGroup]="formGroup" *ngIf="passport">

  <label class="passport__tab--label">
    4. {{ 'Environmental and social performance' | translate }}
  </label>
  <ng-container *ngTemplateOutlet="tabLong; context: {
      label: 'C2C &' + ('Material health certificate' | translate),
      tab: 'MATERIAL_HEALTH'}">
  </ng-container>
  <section class="passport-edit__performance--wrapper" *ngIf="isTabContentShown('MATERIAL_HEALTH')"
    [ngClass]="{'passport-edit__performance-tab--gap': isLongVersionAndTabOpened('MATERIAL_HEALTH')}">

    <div class="passport-edit__performance--certification">
      <div class="flex align-center">
        <strong>{{'C2C certification' | translate}} ?</strong>
        <label class="form-group__label" *ngIf="viewMode == VIEW_MODE.DEFAULT"
          mdTooltip="{{ 'PASSPORT_C2C_CERTIFICATION_INFO_TOOLTIP' | translate }}">
          <i class="form-group__label--question">?</i>
        </label>
      </div>

      <md-radio-group class="passport-edit__performance--radio-buttons" formControlName="c2cCertified">
        <md-radio-button *ngFor="let radio of performanceRadios" [value]="radio.key"
          class="passport-edit__performance--radio-button">
          {{radio.value | translate}}
        </md-radio-button>
      </md-radio-group>
    </div>

    <div *ngIf="areC2cAdditionalFieldsShown()"
      class="passport-edit__performance--pickers flex passport-edit__performance--pickers-sm-left-margin">
      <div>
        <md-select class="passport-edit__performance--certification-global-level" formControlName="certificationLevel"
          placeholder="{{certificateLevelPlaceholder}}{{asterisk}}">
          <md-option *ngFor="let unit of certificationLevels" [value]="unit.key">{{unit.value | translate}}
          </md-option>
        </md-select>
      </div>
      <div class="passport-edit__performance--pickers-date" (click)="onInputWhichNotifiesClick($event);">
        <md-input-container class="input">
          <input mdInput autocomplete="off" ng2-datetime-picker formControlName="certificationExpiration"
            date-only="true" date-format="DD-MM-YYYY" parse-format="YYYY-MM-DD"
            placeholder="{{'Expiry date' | translate}}{{asterisk}}"
            (ngModelChange)="setCertificationExpirationDate($event)">
        </md-input-container>
        <i class="icon icon--calendar"></i>
      </div>
      <div>
        <div class="passport-edit__performance--certification-version-container">
          <label class="passport-edit__performance--certification-version-label primary-color">
            {{'Version of certification' | translate}}
          </label>
          <md-radio-group class="passport-edit__performance--radio-buttons
                                       passport-edit__performance--certification-version-radio-group"
            formControlName="certificationVersion">
            <md-radio-button *ngFor="let radio of certificationVersion" class="passport-edit__performance--radio-button
                                          passport-edit__performance--certification-version-radio" [value]="radio.key">
              {{radio.value | translate}}
            </md-radio-button>
          </md-radio-group>
        </div>
      </div>
    </div>

    <section *ngIf="getCertificationType().additionalLevels"
      class="passport-edit__performance--certification-level-container primary-color">
      <ng-container *ngFor="let input of certificationLevelInputs">
        <md-select class="passport-edit__performance--certification-level" [formControlName]="input.formControlName"
          [placeholder]="input.label() | translate">
          <md-option *ngFor="let unit of certificationLevels" [value]="unit.key">
            {{unit.value | translate}}
          </md-option>
        </md-select>
      </ng-container>
    </section>
  </section>

  <ng-container *ngTemplateOutlet="tabLong; context: {
      label: ('Life Cycle Analysis parameters' | translate),
      tooltip: ('MASS_OF_FUNCTIONAL_UNIT_TOOLTIP' | translate),
      tab: 'LIFE_CYCLE',
      func: expandLifecycleTextArea.bind(this)
      }">
  </ng-container>
  <section *ngIf="isTabContentShown('LIFE_CYCLE')"
    [ngClass]="{'passport-edit__performance-tab--gap': isLongVersionAndTabOpened('LIFE_CYCLE')}">
    <ng-container *ngTemplateOutlet="tabShort; context: {
          label: ('Life Cycle Analysis parameters' | translate),
          tooltip: ('MASS_OF_FUNCTIONAL_UNIT_TOOLTIP' | translate)}">
    </ng-container>

    <div class="passport-edit__life-cycle-wrapper">
      <div class="passport-edit__life-cycle-section-wrapper">
        <h3 class="passport-edit__life-cycle-section-title">
          {{'Source of data' | translate}}
        </h3>

        <div class="passport-edit__life-cycle-flex-wrapper">
          <div class="passport-edit__life-cycle-column">
            <div class="passport-edit__life-cycle-input-container">
              <document-type-input-list [disabled]="isSynchronizedFieldDisabled()" [selectedValue]="getEpdType()"
                (selectionChange)="setEpdType($event)"></document-type-input-list>
            </div>
            <div class="passport-edit__life-cycle-input-container">
              <div *ngIf="isVisible('endDateOfLCA')" class="passport-edit__life-cycle-date flex"
                (click)="onInputWhichNotifiesClick($event);">
                <md-input-container class="input ">
                  <input mdInput (ngModelChange)="setEndOfValidityForLCA($event)" autocomplete="off" ng2-datetime-picker
                    formControlName="endDateOfLCA" date-only="true" date-format="DD-MM-YYYY" parse-format="YYYY-MM-DD"
                    placeholder="{{'End of validity for LCA data' | translate}}{{asterisk}}"
                    [readonly]="isSynchronizedFieldDisabled()">
                </md-input-container>
                <i class="icon icon--calendar"></i>
              </div>
            </div>
            <div class="passport-edit__life-cycle-input-container">
              <country-data-input-list [disabled]="isSynchronizedFieldDisabled()" [selectedValue]="getCountry()"
                (selectionChange)="setCountry($event)"></country-data-input-list>
            </div>
          </div>

          <div class="passport-edit__life-cycle-column">
            <div class="passport-edit__life-cycle-input-row">
              <div class="passport-edit__life-cycle-input-container">
                <source-input-list [disabled]="isSynchronizedFieldDisabled()" [selectedValue]="getSource()"
                  (selectionChange)="setSource($event)"></source-input-list>
              </div>

              <div class="passport-edit__life-cycle-input-container other" *ngIf="isSourceOther()">
                <md-input-container> <!-- Document Database - Other -->
                  <input formControlName="otherSource" mdInput [placeholder]="'Document database - other' | translate"
                    [readonly]="isSynchronizedFieldDisabled()">
                </md-input-container>
              </div>
            </div>

            <div class="passport-edit__life-cycle-input-row">
              <div class="passport-edit__life-cycle-input-container">
                <operator-input-list [disabled]="isSynchronizedFieldDisabled()" [selectedValue]="getOperator()"
                  (selectionChange)="setOperator($event)"></operator-input-list>
              </div>

              <div class="passport-edit__life-cycle-input-container other" *ngIf="isOperatorOther()">
                <md-input-container> <!-- Document Provider - Other -->
                  <input formControlName="otherOperator" mdInput [placeholder]="'Data provider - other' | translate"
                    [readonly]="isSynchronizedFieldDisabled()">
                </md-input-container>
              </div>
            </div>

            <div class="passport-edit__life-cycle-input-row">
              <div class="passport-edit__life-cycle-input-container">
                <md-input-container> <!-- Document ID -->
                  <input formControlName="documentId" mdInput [placeholder]="'documentId' | translate"
                    [readonly]="isSynchronizedFieldDisabled()">
                </md-input-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="passport-edit__life-cycle-section-wrapper">
        <h3>
          {{'Functional unit' | translate}}
        </h3>

        <div class="passport-edit__performance--footprint-input-wrapper">
          <div class="passport-edit__performance--footprint-input-container">
            <div class="passport-edit__performance--footprint-input-block" (click)="onInputWhichNotifiesClick($event);">
              <div class="passport-edit__performance--footprint-input-caption functionalUnit">
                1&nbsp;{{'functional unit' | translate}}&nbsp;=&nbsp;
              </div>
              <md-input-container class="passport-edit__performance--footprint-input  passport-edit__input">
                <input class="passport-edit__performance-functional-input functionalUnit" mdInput autocomplete="off"
                  [formControlName]="PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_CONVERSION"
                  [readonly]="isSynchronizedFieldDisabled()" min="0" type="number">
                <md-hint *ngIf="formGroup && formGroup.get(PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_CONVERSION).invalid"
                  class="error-hint" align="start">
                  {{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}
                </md-hint>
              </md-input-container>
              <div class="passport-edit__performance--footprint-input-caption functionalUnit">
                {{'kg' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="passport-used-energy__item--description flex">
          <md-input-container>
            <textarea formControlName="descriptionOfUnit" mdInput #lifeCycleDescription
              (input)="expandLifecycleTextArea()" rows="1"
              [placeholder]="'Description of the functional unit' | translate"></textarea>
          </md-input-container>
        </div>
      </div>
    </div>
  </section>

  <ng-container *ngTemplateOutlet="tabLong; context: {
          label: ('Carbon footprint' | translate),
          tooltip: ('PASSPORT_C2C_FOOTPRINT_TOOLTIP' | translate),
          tab: 'CARBON_FOOTPRINT'
          }">
  </ng-container>
  <!-- Carbon Footprint Section -->
  <section *ngIf="isTabContentShown('CARBON_FOOTPRINT')"
    [ngClass]="{'passport-edit__performance-tab--gap': isLongVersionAndTabOpened('CARBON_FOOTPRINT')}"
    class="passport-edit__performance--footprint">

    <ng-container *ngTemplateOutlet="tabShort; context: {
          label: (('Carbon footprint' | translate) + ' ' + ('in kg CO2 eq'| translate)),
          tooltip: ('PASSPORT_C2C_FOOTPRINT_TOOLTIP' | translate)
        }"></ng-container>

    <div class="passport-edit__performance--footprint-input-wrapper-columns">
      <!-- Left Column -->
      <div class="passport-edit__performance--footprint-input-column">
        <ng-container *ngFor="let carbonData of carbonFootprintInput; let i = index" ngProjectAs="div">
          <div *ngIf="isVisible(carbonData.formControlName) && carbonData.column === 'left'"
            class="passport-edit__performance--footprint-input-container"
            [ngClass]="{'passport-edit__performance--footprint-production': isLongVersionActive && i === 0}">
            <span class="passport-edit__performance--footprint-input-label">
              {{ carbonData.label() | translate }}
            </span>
            <span class="passport-edit__performance--footprint-input-label-description primary-color">
              {{ 'Carbon footprint' | translate }}
            </span>
            <div class="passport-edit__performance--footprint-input-block ng-star-inserted">
              <md-input-container class="passport-edit__performance-energy-lifecycle-input">
                <input [formControlName]="carbonData.formControlName" mdInput autocomplete="off"
                  [readonly]="isSynchronizedFieldDisabled()" [class.readonly]="isSynchronizedFieldDisabled()"
                  type="number" [disabled]="true">
                <md-hint *ngIf="formGroup.get(carbonData.formControlName).invalid" class="error-hint" align="start">
                  {{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}
                </md-hint>
              </md-input-container>
              <div class="passport-edit__performance--footprint-input-caption">
                kg CO2 eq / {{ 'functional unit' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Right Column -->
      <div class="passport-edit__performance--footprint-input-column">
        <ng-container *ngFor="let carbonData of carbonFootprintInput; let i = index" ngProjectAs="div">
          <div *ngIf="isVisible(carbonData.formControlName) && carbonData.column === 'right'"
            class="passport-edit__performance--footprint-input-container"
            [ngClass]="{'passport-edit__performance--footprint-production': isLongVersionActive && i === 0}">
            <span class="passport-edit__performance--footprint-input-label">
              {{ carbonData.label() | translate }}
            </span>
            <span class="passport-edit__performance--footprint-input-label-description primary-color">
              {{ 'Carbon footprint' | translate }}
            </span>
            <div class="passport-edit__performance--footprint-input-block ng-star-inserted">
              <md-input-container class="passport-edit__performance-energy-lifecycle-input">
                <input [formControlName]="carbonData.formControlName" mdInput autocomplete="off"
                  [readonly]="isSynchronizedFieldDisabled()" type="number">
                <md-hint *ngIf="formGroup.get(carbonData.formControlName).invalid" class="error-hint" align="start">
                  {{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}
                </md-hint>
              </md-input-container>
              <div class="passport-edit__performance--footprint-input-caption">
                kg CO2 eq / {{ 'functional unit' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
  <!-- End of Carbon Footprint Section -->

  <ng-container *ngTemplateOutlet="tabLong; context: {
    label: ('Water Stewardship' | translate),
    tooltip: ('PASSPORT_C2C_STEWARDSHIP_TOOLTIP' | translate),
    tab: 'WATER_STEWARDSHIP'
    }">
  </ng-container>
  <!-- Water Stewardship Section -->
  <section *ngIf="isTabContentShown('WATER_STEWARDSHIP')"
    [ngClass]="{'passport-edit__performance-tab--gap': isLongVersionAndTabOpened('WATER_STEWARDSHIP')}"
    class="passport-edit__performance--footprint">

    <ng-container *ngTemplateOutlet="tabShort; context: {
    label: (('Water Stewardship' | translate) + ' ' + ('in m3'| translate)),
    tooltip: ('PASSPORT_C2C_STEWARDSHIP_TOOLTIP' | translate)
  }"></ng-container>
    <div class="passport-edit__performance--footprint-input-wrapper-columns">
      <!-- Left Column -->
      <div class="passport-edit__performance--footprint-input-column">
        <ng-container *ngFor="let waterData of passportWaterStewardshipInputs; let i = index" ngProjectAs="div">
          <div *ngIf="isVisible(waterData.formControlName) && waterData.column === 'left'"
            class="passport-edit__performance--footprint-input-container"
            [ngClass]="{'passport-edit__performance--footprint-production': isLongVersionActive && i === 0}">
            <span class="passport-edit__performance--footprint-input-label">
              {{ waterData.labelDescription() | translate }}
            </span>
            <span class="passport-edit__performance--footprint-input-label-description primary-color">
              {{ waterData.label() | translate }}
            </span>
            <div class="passport-edit__performance--footprint-input-block ng-star-inserted">
              <md-input-container class="passport-edit__performance-energy-lifecycle-input">
                <input [formControlName]="waterData.formControlName" mdInput autocomplete="off"
                  [readonly]="isSynchronizedFieldDisabled()" type="number">
                <md-hint *ngIf="formGroup.get(waterData.formControlName).invalid" class="error-hint" align="start">
                  {{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}
                </md-hint>
              </md-input-container>
              <div class="passport-edit__performance--footprint-input-caption">
                m3 / {{ 'functional unit' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Right Column -->
      <div class="passport-edit__performance--footprint-input-column">
        <ng-container *ngFor="let waterData of passportWaterStewardshipInputs; let i = index" ngProjectAs="div">
          <div *ngIf="isVisible(waterData.formControlName) && waterData.column === 'right'"
            class="passport-edit__performance--footprint-input-container"
            [ngClass]="{'passport-edit__performance--footprint-production': isLongVersionActive && i === 0}">
            <span class="passport-edit__performance--footprint-input-label">
              {{ waterData.labelDescription() | translate }}
            </span>
            <span class="passport-edit__performance--footprint-input-label-description primary-color">
              {{ waterData.label() | translate }}
            </span>
            <div class="passport-edit__performance--footprint-input-block ng-star-inserted">
              <md-input-container class="passport-edit__performance-energy-lifecycle-input">
                <input [formControlName]="waterData.formControlName" mdInput autocomplete="off"
                  [readonly]="isSynchronizedFieldDisabled()" type="number">
                <md-hint *ngIf="formGroup.get(waterData.formControlName).invalid" class="error-hint" align="start">
                  {{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}
                </md-hint>
              </md-input-container>
              <div class="passport-edit__performance--footprint-input-caption">
                m3 / {{ 'functional unit' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div>
      <ng-container *ngIf="isVisible('waterStewardshipCertified')">
        <div class="passport-edit__performance-water-stewardship">
          <span class="passport-edit__performance-water-stewardship-text">
            {{'C2C_WATER_STEWARDSHIP_CERTIFY'| translate}}
          </span>
        </div>
        <div class="passport-edit__performance--radio-wrapper flex align-start justify-between">
          <md-radio-group class="passport-edit__performance--stewardship-radio-buttons"
            [(ngModel)]="passport.water_stewardship_certified" formControlName="waterStewardshipCertified">
            <md-radio-button *ngFor="let radio of defaultRadioValues" [value]="radio.key"
              class="passport-edit__performance--stewardship-radio-button">
              {{radio.value | translate}}
            </md-radio-button>
          </md-radio-group>
        </div>
      </ng-container>
    </div>
  </section>
  <!-- End of Water Stewardship Section -->

  <ng-container *ngTemplateOutlet="tabLong; context: {
          label: ('Energy' | translate),
          tooltip: ('PASSPORT_C2C_FINAL_ENERGY_TOOLTIP' | translate),
          tab: 'ENERGY',
          func: expandEffortsForRenewableEnergyTextArea.bind(this)
          }">
  </ng-container>
  <section class="passport-edit__performance--production" *ngIf="isTabContentShown('ENERGY')  && isEnergyVisible()"
    [ngClass]="{'passport-edit__performance-tab--gap': isLongVersionAndTabOpened('ENERGY')}">
    <ng-container *ngTemplateOutlet="tabShort; context: {
            label: ('Energy' | translate),
            tooltip: ('PASSPORT_C2C_FINAL_ENERGY_TOOLTIP' | translate)
            }">
    </ng-container>
    <div class="passport-edit__performance--used-energy flex-column">
      <ul class=" flex-row
                          passport-edit__performance-energy-lifecycle">
        <li *ngFor="let group of passportEnergyInputGroups"
          class="passport-edit__performance-energy-lifecycle-list flex-column">
          <span class="passport-edit__performance--footprint-input-label">{{ group.label() | translate}}</span>
          <div class="passport-edit__performance--footprint-input-wrapper-columns
                               ">
            <div *ngFor="let input of group.inputs" class="passport-edit__performance-energy-lifecycle-container"
              [ngClass]="input.classList || []">
              <span class="passport-edit__performance--footprint-input-label-description primary-color">
                {{ input.label() | translate }}
              </span>
              <div class="passport-edit__performance--footprint-input-block">
                <md-input-container class="passport-edit__performance-energy-lifecycle-input">
                  <input mdInput type="number" autocomplete="off" [formControlName]="input.formControlName"
                    [readonly]="isSynchronizedFieldDisabled()">
                  <md-hint *ngIf="formGroup && formGroup.get(input.formControlName).invalid" class="error-hint"
                    align="start">
                    {{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}
                  </md-hint>
                </md-input-container>
                <div class="passport-edit__performance--footprint-input-caption"> MJ
                  / {{'functional unit' | translate}}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <h3 class="passport-edit__performance-energy-lifecycle-list-label">
        {{'Distribution of the energy mix in the manufacturing process' | translate}}
      </h3>
      <passport-used-energy (onSave)="onUpdatePassportData.emit($event)" [listener]="listener" [passport]="passport"
        [isDisabled]="areFormControlsDisabled" [viewMode]="viewMode"
        [parentFormGroup]="parentFormGroup"></passport-used-energy>
      <div class="passport-used-energy__item--description flex">
        <md-input-container>
          <textarea rows="1" #effortsForRenewableEnergy (input)="expandEffortsForRenewableEnergyTextArea()"
            formControlName="optimizationStrategy" mdInput maxlength="2000"
            [placeholder]="getOptimizationsStrategyPlaceholder()"></textarea>
        </md-input-container>
        <label class="form-group__label  passport-edit__performance--caption-question"
          *ngIf="viewMode == VIEW_MODE.DEFAULT" mdTooltipPosition="above"
          mdTooltip="{{ 'DESCRIBE_AN_OPTIMIZED_STRATEGY_TOOLTIP' | translate }}">
          <i class="form-group__label--question">?</i>
        </label>
      </div>
    </div>
  </section>

  <ng-container *ngTemplateOutlet="tabLong; context: {
              label: ('Social Fairness' | translate),
              tooltip: ('PASSPORT_C2C_FAIRNESS_TOOLTIP' | translate),
              tooltipClass: ['passport-edit__performance-tooltip'],
              tab: 'SOCIAL_FAIRNESS'
              }">
  </ng-container>

  <section class="passport-edit__performance--fairness"
    *ngIf="isTabContentShown('SOCIAL_FAIRNESS') && isVisible('socialFairnessCertified')"
    [ngClass]="{'passport-edit__performance-tab--gap': isLongVersionAndTabOpened('SOCIAL_FAIRNESS')}">
    <div class="passport-edit__performance-water-stewardship">
      <ng-container *ngTemplateOutlet="tabShort; context: {
              label: ('Social Fairness' | translate),
              tooltip: ('PASSPORT_C2C_FAIRNESS_TOOLTIP' | translate)
              }">
      </ng-container>
      <span class="passport-edit__performance-water-stewardship-text">
        {{'C2C_SOCIAL_FAIRNESS_CERTIFY'| translate}}
      </span>
    </div>
    <div class="passport-edit__performance--radio-wrapper flex align-start justify-between">
      <md-radio-group class="passport-edit__performance--fairness-radio-buttons"
        formControlName="socialFairnessCertified">
        <md-radio-button *ngFor="let radio of defaultRadioValues" [value]="radio.key"
          class="passport-edit__performance--fairness-radio-button">
          {{radio.value | translate}}
        </md-radio-button>
      </md-radio-group>
    </div>
  </section>
</article>


<ng-template #tabLong let-tab="tab" let-label="label" let-tooltip="tooltip" let-tooltipClass="tooltipClass"
  let-func="func">
  <div class="passport-edit__performance-tab background-primary-color" *ngIf="isLongVersion()"
    (click)="changeTab(tab); func ? func() : null">
    <div>
      <span class="passport-edit__performance-tab--text">{{ label}}</span>
      <ng-container *ngIf="!!tooltipClass">
        <label class="passport-edit__performance-tab--question" *ngIf="viewMode == VIEW_MODE.DEFAULT && tooltip"
          [tooltipClass]="tooltipClass" appCustomTooltip mdTooltip="{{tooltip | translate }}">
          <i class="passport-edit__performance-tab-label--question">?</i>
        </label>
      </ng-container>
      <ng-container *ngIf="!tooltipClass">
        <label class="passport-edit__performance-tab--question" *ngIf="viewMode == VIEW_MODE.DEFAULT && tooltip"
          mdTooltip="{{tooltip | translate }}">
          <i class="passport-edit__performance-tab-label--question">?</i>
        </label>
      </ng-container>
    </div>
    <i class="passport-edit__performance-tab--icon"
      [ngClass]="isOpen(tab) ? 'icon--unfold-arrow-white' : 'icon--fold-arrow-white' ">
    </i>
  </div>
</ng-template>

<ng-template #tabShort let-label="label" let-tooltip="tooltip">
  <div class="passport-edit__performance--caption" *ngIf="!isLongVersion()">
    <span>{{label}}</span>
    <label class="form-group__label  passport-edit__performance--caption-question"
      *ngIf="viewMode == VIEW_MODE.DEFAULT && tooltip" mdTooltip="{{ tooltip | translate }}">
      <i class="form-group__label--question">?</i>
    </label>
  </div>
</ng-template>