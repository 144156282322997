import { Injectable } from '@angular/core';
import {CrudService} from '../../../shared/services/crud.service';
import {RequestFile} from '../models/request-file.class';

@Injectable()
export class RequestFileService extends CrudService {
  protected ModelClass = RequestFile;
  protected namespace = 'request-file';

  public getUploadedFileUrl() {
    return this.getEndpoint(this.namespace + '/create');
  }
}

