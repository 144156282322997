import * as tslib_1 from "tslib";
import { NgZone, OnInit } from '@angular/core';
import { MdDialog } from '@angular/material';
import { ZoneFormDialogComponent } from '../../dialogs/zone-form-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { Zone } from '../../../../shared/models/zone.class';
import { Router } from '@angular/router';
import { ZoneService } from '../../../../shared/services/zone.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { LANDFILL_MAP } from '../../../../shared/values/landfill.map';
import { ZONE_STATUS } from '../../../../shared/values/zone-status.const';
import { DeepStreamService } from '../../../../shared/services/deepstream.service';
import { IZoneCopyingDataContainer, IZoneDeletionDataContainer } from '../../../../shared/interfaces/zone-deepstream-message.interface';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { PassportTagService } from '../../../../entities/passport/services/passport-tag.service';
import { SynonymService } from '../../../../shared/services/synonym.service';
import { Synonym } from '../../../../shared/models/synonym.class';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
var ZonesComponent = /** @class */ (function () {
    function ZonesComponent(mdDialog, _zone, _router, zoneService, passportTagService, synonymService, _translate, _ds, _notificationService) {
        var _this = this;
        this.mdDialog = mdDialog;
        this._zone = _zone;
        this._router = _router;
        this.zoneService = zoneService;
        this.passportTagService = passportTagService;
        this.synonymService = synonymService;
        this._translate = _translate;
        this._ds = _ds;
        this._notificationService = _notificationService;
        this.ZONE_STATUS = ZONE_STATUS;
        this.searchControl = new FormControl();
        this.searchControl
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function (value) { return _this.search = value; });
    }
    ZonesComponent.prototype.ngDoCheck = function () {
        var _this = this;
        var query = {
            expand: 'synonyms',
            show_hidden: true,
            search: this.search || null,
        };
        if (!_.isEqual(this.oldQuery, query)) {
            this.passportTagService.get(query).subscribe(function (res) {
                _this.tags = res;
            });
            this.oldQuery = _.cloneDeep(query);
        }
    };
    ZonesComponent.prototype.ngOnInit = function () {
        this.zones = [];
        this.getZones();
        this.getTagsAndSynonyms();
    };
    ZonesComponent.prototype.ngOnDestroy = function () {
        this.destroyZoneCopyingSubscription();
        this.destroyZoneDeletionSubscription();
    };
    ZonesComponent.prototype.getZones = function () {
        var _this = this;
        this.zoneService.get({ expand: 'name,status,passport_count', nopaginate: 1 }).subscribe(function (zones) { return _this.zones = zones; });
    };
    ZonesComponent.prototype.getTagsAndSynonyms = function () {
        var _this = this;
        this.passportTagService.get({ expand: 'synonyms', show_hidden: true, search: this.search || null })
            .subscribe(function (result) {
            _this.tags = result;
        });
    };
    ZonesComponent.prototype.mapLandFields = function (landfillVariables) {
        var landfill_cost_variables = {
            inert_soils: null,
            gravats: null,
            ordinary_industrial_waste: null,
            demolition_wood: null,
            demolition_wood_max: null,
            clean_plaster: null,
            paper_and_cardboard: null,
            plants: null
        };
        landfillVariables.map(function (landfill) {
            landfill_cost_variables[LANDFILL_MAP[landfill.id]] = landfill.value;
        });
        return landfill_cost_variables;
    };
    ZonesComponent.prototype.newZone = function () {
        var _this = this;
        this._zone.run(function () {
            _this.mdDialog.open(ZoneFormDialogComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: {
                    zone: new Zone({ landfill_cost_variables: {} })
                } })).afterClosed().subscribe(function () {
                _this.getZones();
            });
        });
    };
    ZonesComponent.prototype.editZone = function (zone) {
        var _this = this;
        this._zone.run(function () {
            _this.zoneService.getLandfillVariables(zone.id, { expand: 'name,value' }).subscribe(function (landfill) {
                zone.landfill_cost_variables = _this.mapLandFields(landfill);
                _this.mdDialog.open(ZoneFormDialogComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: { zone: zone } })).afterClosed().subscribe(function () {
                    _this.getZones();
                });
            });
        });
    };
    ZonesComponent.prototype.goToZone = function (zone) {
        var _this = this;
        this._zone.run(function () { return (_this._router.navigate(["superadmin/zones/" + zone.id + "/generic-passports/"])); });
    };
    ZonesComponent.prototype.copyZone = function (zone) {
        var _this = this;
        this.zoneService.duplicate(zone.id)
            .subscribe(function () { return (_this.subscribeOnZoneCopyingStream()); });
    };
    ZonesComponent.prototype.removeZone = function (zone) {
        var _this = this;
        this._translate.get('CONFIRM_DELETE_ZONE')
            .flatMap(function (translation) {
            var dialogRef = _this.mdDialog.open(ConfirmDialogComponent, {
                data: {
                    text: translation
                }
            });
            return dialogRef.afterClosed();
        })
            .flatMap(function (confirmed) {
            return confirmed
                ? zone.destroy().map(function () { return true; })
                : Observable.of(false);
        })
            .subscribe(function (deleted) {
            if (deleted) {
                _this.subscribeOnZoneDeletionStream();
            }
        });
    };
    ZonesComponent.prototype.destroyZoneDeletionSubscription = function () {
        if (!!this.zoneDeletionSubscription) {
            this.zoneDeletionSubscription.unsubscribe();
            this.zoneDeletionSubscription = null;
        }
    };
    ZonesComponent.prototype.destroyZoneCopyingSubscription = function () {
        if (!!this.zoneCopyingSubscription) {
            this.zoneCopyingSubscription.unsubscribe();
            this.zoneCopyingSubscription = null;
        }
    };
    ZonesComponent.prototype.showNotification = function (isDone, message) {
        return this._notificationService[isDone ? 'success' : 'error'](message);
    };
    ZonesComponent.prototype.subscribeOnZoneDeletionStream = function () {
        var _this = this;
        this.destroyZoneDeletionSubscription();
        this.zoneDeletionSubscription = this._ds.zoneDeepStream$.deletionStream$
            .subscribe(function (container) {
            _this.showNotification(container.data.done, _this._translate.instant(container.data.message));
            return _this._zone.run(function () { return _this.getZones(); });
        }, function () { });
    };
    ZonesComponent.prototype.subscribeOnZoneCopyingStream = function () {
        var _this = this;
        this.destroyZoneCopyingSubscription();
        this.zoneCopyingSubscription = this._ds.zoneDeepStream$.copyingStream$
            .subscribe(function (container) {
            _this.showNotification(container.data.done, _this._translate.instant(container.data.message));
            _this._zone.run(function () { return _this.getZones(); });
        }, function () { });
    };
    ZonesComponent.prototype.onEnter = function (tagName, tag_id) {
        var _this = this;
        this.synonymService.create(new Synonym({ name: tagName, tag_id: tag_id })).subscribe(function () {
            _this.getTagsAndSynonyms();
        });
    };
    ZonesComponent.prototype.removeSynonym = function (id) {
        var _this = this;
        this.synonymService.remove(id).subscribe(function () {
            _this.getTagsAndSynonyms();
        });
    };
    return ZonesComponent;
}());
export { ZonesComponent };
