import { PassportStatus } from '../models/passport-status.class';

export enum PASSPORT_STATUS {
  DRAFT = 1,
  ASK_FOR_MODIFICATION,
  PUBLISHED,
  WAITING_FOR_PUBLICATION,
  FROZEN,
  WAITING_FOR_REVISIONS
}

const PassportStatusDefinitions: { [K in PASSPORT_STATUS]: PassportStatus } = {
  [PASSPORT_STATUS.DRAFT]: { value: PASSPORT_STATUS.DRAFT, text: 'DRAFT', view: 'Draft' },
  [PASSPORT_STATUS.ASK_FOR_MODIFICATION]: { value: PASSPORT_STATUS.ASK_FOR_MODIFICATION, text: 'ASK_FOR_MODIFICATION', view: 'Asked for modification' },
  [PASSPORT_STATUS.PUBLISHED]: { value: PASSPORT_STATUS.PUBLISHED, text: 'PUBLISHED', view: 'Published' },
  [PASSPORT_STATUS.WAITING_FOR_PUBLICATION]: { value: PASSPORT_STATUS.WAITING_FOR_PUBLICATION, text: 'WAITING_FOR_PUBLICATION', view: 'Waiting for publication' },
  [PASSPORT_STATUS.WAITING_FOR_REVISIONS]: { value: PASSPORT_STATUS.WAITING_FOR_REVISIONS, text: 'WAITING_FOR_REVISIONS', view: 'waitingForRevision' },
  [PASSPORT_STATUS.FROZEN]: { value: PASSPORT_STATUS.FROZEN, text: 'FROZEN', view: 'Frozen' },
};

export const PASSPORT_STATUS_CONST = {
  DRAFT: PassportStatusDefinitions[PASSPORT_STATUS.DRAFT],
  ASK_FOR_MODIFICATION: PassportStatusDefinitions[PASSPORT_STATUS.ASK_FOR_MODIFICATION],
  PUBLISHED: PassportStatusDefinitions[PASSPORT_STATUS.PUBLISHED],
  WAITING_FOR_PUBLICATION: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_PUBLICATION],
  WAITING_FOR_REVISIONS: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_REVISIONS],
};

export const PASSPORT_STATUS_CONST_MANUFACTURER_TOBETREATED = {
  DRAFT: PassportStatusDefinitions[PASSPORT_STATUS.DRAFT],
  WAITING_FOR_REVISIONS: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_REVISIONS],
};

export const PASSPORT_STATUS_CONST_MANUFACTURER = {
  ASK_FOR_MODIFICATION: PassportStatusDefinitions[PASSPORT_STATUS.ASK_FOR_MODIFICATION],
  WAITING_FOR_PUBLICATION: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_PUBLICATION],
  DRAFT: PassportStatusDefinitions[PASSPORT_STATUS.DRAFT],
  PUBLISHED: PassportStatusDefinitions[PASSPORT_STATUS.PUBLISHED],
};

export const PASSPORT_STATUS_CONST_SPECIAL_ADMIN = {
  PUBLISHED: PassportStatusDefinitions[PASSPORT_STATUS.PUBLISHED],
  WAITING_FOR_PUBLICATION: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_PUBLICATION],
  ASK_FOR_MODIFICATION: PassportStatusDefinitions[PASSPORT_STATUS.ASK_FOR_MODIFICATION],
};
