import { Component, OnInit } from '@angular/core';
import { MdDialog } from '@angular/material';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './../../../../../shared/confirm-dialog/confirm-dialog.component';
import { ClientLocation } from './../../../../client/model/location.class';
import { LocationService } from './../../../../client/service/location.service';
import { NewSiteDialogComponent } from './PopupDialog/new-site-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'need-sites',
  templateUrl: 'need-sites.component.html',
  styleUrls: ['need-sites.component.scss']
})
export class NeedSitesComponent implements OnInit {

  /**
   * List of ClientLocation instances fetched from the backend.
   */
  clientLocations: ClientLocation[];
  search: string;
  public page: number = 1;

  public pageInfo = {
    totalCount: 1,
    perPage: 10,
    pageCount: 1,
    currentPage: 1
  };

  constructor(
    private locationService: LocationService,
    private dialogService: MdDialog,
    private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.fetchSites();
  }

  /**
   * Calls the LocationService to fetch the list of client locations based on provided parameters.
   * Handles any errors encountered during the fetch.
   */
  public fetchSites(): void {
    const params = this.getFetchParams();

    this.locationService.getWithArray(params)
      .subscribe(
        (res) => this.handleFetchResponse(res),
        error => console.error('Erreur lors du chargement des sites', error)
      );
  }

  /**
   * Generates the set of parameters to be sent with the request to the LocationService.
   * 
   * @return any[] The array of parameters.
   */
  private getFetchParams(): any[] {
    const baseParams = [
      ['expand', 'need_Count,client'],
      ['sort', 'name'],
      ['paginate', true],
      ['page', this.page]
    ];
    if (this.search) {
      baseParams.push(['search', this.search]);
    }
    return baseParams;
  }

  /**
  * Handles the response received from the LocationService.
  * Populates the clientLocations and pageInfo properties based on the response.
  *
  * @param res The response from the LocationService.
  */
  private handleFetchResponse(res: any): void {
    // Ensure the response exists and has a valid body before proceeding.
    if (!res || !res.json) {
      console.error('Invalid or empty response received.');
      return;
    }
    const locations = res.json().map(location => {
      const { need_Count } = location;
      return new ClientLocation({ ...location, needCount: need_Count });
    });
    this.clientLocations = locations;
    this.pageInfo = {
      totalCount: Number(res.headers.get('X-Pagination-Total-Count')),
      perPage: Number(res.headers.get('X-Pagination-Per-Page')),
      pageCount: Number(res.headers.get('X-Pagination-Page-Count')),
      currentPage: Number(res.headers.get('X-Pagination-Current-Page'))
    };
  }

  /**
   * Reloads the list of client locations from the backend.
   */
  refreshSites(): void {
    this.fetchSites();
  }

  /**
   * Opens the site dialog. 
   * If a site (ClientLocation instance) is provided, it loads the dialog in edit mode. Otherwise, in creation mode.
   *
   * @param site An optional ClientLocation instance.
   */
  openSiteDialog(site?: ClientLocation): void {
    const dialogConfig = {
      width: '900px',
      data: site || {}
    };

    const dialogRef = this.dialogService.open(NewSiteDialogComponent, dialogConfig);

    dialogRef.componentInstance.siteCreated.subscribe(() => this.fetchSites());
  }

  /**
   * Triggers the deletion process for a given site (location).
   * Shows a confirmation dialog before proceeding. 
   * If the deletion is confirmed and successful, reloads the sites.
   *
   * @param location The ClientLocation instance to be deleted.
   */
  deleteLocation(location: ClientLocation): void {
    location.canDelete()
      .pipe(
        switchMap(canDelete => {
          if (!canDelete) return of(false);

          const dialogConfig = {
            data: {
              text: this._translate.instant('deleteDeliverySiteConfirmation'),
              imgUrl: 'assets/png/warning_purple.png',
              subText: this._translate.instant('All_attached_data_will_be_lost')
            }
          };

          const dialogRef = this.dialogService.open(ConfirmDialogComponent, dialogConfig);

          return dialogRef.afterClosed().pipe(
            switchMap(confirmed => confirmed ? location.destroy().pipe(map(() => true)) : of(false))
          );
        })
      )
      .subscribe(deleted => {
        if (deleted) this.fetchSites();
      });
  }

  /**
   * Placeholder function for setting default location.
   * To be implemented.
   */
  setdefaultLocation() {
  }

  /**
  * Placeholder function for duplicating a location.
  * To be implemented.
  */
  duplicateLocation() {
  }

  /**
  * Opens the site dialog in edit mode for a given site (location).
  *
  * @param location The ClientLocation instance to be edited.
  */
  viewEditLocation(location: ClientLocation) {
    this.openSiteDialog(location);
  }

  /**
   * Handles page change events, updating the current page and reloading the sites.
   *
   * @param $event The new page number.
   */
  pageChange($event: number) {
    this.page = $event;
    this.fetchSites();
  }
}
