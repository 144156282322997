import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {TimeLeftTextPipe} from '../pipes/time-left-text.pipe';
import {TimeSinceExpire} from '../pipes/time-since-expire.pipe';
import {EDepositAvailableStatus} from '../values/deposit-available-status.enum';

@Injectable()
export class CustomListDateAvailabilityService {

constructor(public timeSinceExpire: TimeSinceExpire
  , public timeLeftTextPipe: TimeLeftTextPipe) {}

  public static getDepositAvailableStatus(availability_date: string, availability_expiration: string): EDepositAvailableStatus {
    if (new Date(availability_date).getTime() < Date.now()) {
      if (!!availability_expiration && moment().isAfter(availability_expiration, 'day')) {
        return EDepositAvailableStatus.EXPIRED;
      } else {
        return EDepositAvailableStatus.AVAILABLE;
      }
    } else {
      return EDepositAvailableStatus.AVAILABLE_IN;
    }
  }

  public getColumn(availability_date: string, availability_expiration: string) {
     const status = CustomListDateAvailabilityService.getDepositAvailableStatus(availability_date, availability_expiration);
       if (status === EDepositAvailableStatus.EXPIRED) {
        return {
          key: 'Expired since',
          value: this.timeSinceExpire.transform(availability_expiration),
          cssClass: 'important-value--expired',
        };
      } else if (status === EDepositAvailableStatus.AVAILABLE) {
        return {
          value: 'AVAILABLE',
          key: '',
          cssClass: 'important-value',
        };
      } else if (status === EDepositAvailableStatus.AVAILABLE_IN) {
        return {
          key: 'Available in',
          value: this.timeLeftTextPipe.transform(availability_date),
          cssClass: 'important-value-secondary',
        };
      }
  }
}

