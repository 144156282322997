import { MAP_IMPORT_PROGRESS_STATUS } from './report-progress.const';
import { ReportProgressService } from './report-progress.service';
var ReportProgressComponent = /** @class */ (function () {
    function ReportProgressComponent(reportProgressService) {
        this.reportProgressService = reportProgressService;
        this.MAP_IMPORT_PROGRESS_STATUS = MAP_IMPORT_PROGRESS_STATUS;
    }
    ReportProgressComponent.prototype.close = function () {
        this.reportProgressService.closeImport();
        this.reportProgressService.showImportProgress = false;
    };
    ReportProgressComponent.prototype.getText = function () {
        switch (this.reportProgressService.statusImportProgress) {
            case MAP_IMPORT_PROGRESS_STATUS.PROGRESS:
                return 'CIRCULAR_SIGNATURE_PROGRESS';
            case MAP_IMPORT_PROGRESS_STATUS.ERROR:
                return 'Error in file';
            case MAP_IMPORT_PROGRESS_STATUS.SUCCESS:
                return 'CIRCULAR_SIGNATURE_DONE';
        }
    };
    ReportProgressComponent.prototype.downloadZip = function (record) {
        window.open(this.reportProgressService.zipfile_link, '_blank');
    };
    Object.defineProperty(ReportProgressComponent.prototype, "isSuccess", {
        get: function () {
            return MAP_IMPORT_PROGRESS_STATUS.SUCCESS === this.reportProgressService.statusImportProgress;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportProgressComponent.prototype, "isError", {
        get: function () {
            return MAP_IMPORT_PROGRESS_STATUS.ERROR === this.reportProgressService.statusImportProgress;
        },
        enumerable: true,
        configurable: true
    });
    return ReportProgressComponent;
}());
export { ReportProgressComponent };
