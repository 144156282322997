import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import {LanguageService } from './../../entities/client/service/language.service' ;//src/entities/client/service/language.service.ts
import { TLanguage } from '../../shared/types/language-type';

@Component({
  selector: 'app-public-passport-list',
  templateUrl: './public-passport-list.component.html',
  styleUrls: ['./public-passport-list.component.scss']
})
export class PublicPassportListComponent implements OnInit {

  constructor(private languageService: LanguageService,
    private route: ActivatedRoute) { }

  lang: TLanguage;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.lang = params['lang'] as TLanguage;
      this.languageService.setLang(this.lang);
    });
  }

}
