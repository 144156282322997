import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { dateDiffLargestPeriod } from '../helpers/date-diff-largest-period';


/*
    TODO: Add marker for translation.
    Translation keys: 
    marker(['day', 'days', 'today', 'yesterday', 'tomorrow', 'month', 'months', 'year', 'years']) 
*/

@Pipe({
  name: 'timeSinceExpire'
})
export class TimeSinceExpire implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    transform(date: string) {
        const expirationDate = moment(date);
        const today = moment();
        return dateDiffLargestPeriod(expirationDate, today, 
            {day: 'day'
            , oneDay: 'yesterday'
            , month: 'month'
            , year: 'year',
            zeroDays: 'today'
        }).map(date => typeof date === 'string' ? this._translate.instant(date) : date).join(' ');
        }

}