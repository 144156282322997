<md-dialog-content class="order-transaction-confirmation__dialog"
                   [ngClass]="{'order-transaction-confirmation__dialog--hidden'
                    : this.confirmOrderDialogService.isConfirmDialogOpen}">
  <dialog-close-button class="order-transaction-confirmation__dialog-close-button"
                       (click)="mdDialogRef.close()"
  ></dialog-close-button>
  <transformation-order-transaction-confirm
    appNotificationAsWideDialogContent
    [id]="mdDialogData.id"
    [step_id]="mdDialogData.step_id"
    [disabled]="mdDialogData.disabled"
    [showESMText]="mdDialogData.showESMText"
    (onConfirm)="openConfirmation($event)"
    (onCancel)="mdDialogRef.close()"
  >
  </transformation-order-transaction-confirm>
</md-dialog-content>
