/**
 * Created by natalja on 29/06/2017.
 */

import { Component, OnInit } from '@angular/core';
import {
  ECustomListKeyPairClass,
  ECustomListRecordStatus,
  ECustomListThemeClass, ECustomListUnit, ICustomListKeyPair,
  ICustomListRecord
} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { FormControl } from '@angular/forms';
import { Searchable } from '../../../../shared/models/searchable.class';
import { TransformService } from '../../../../entities/transformation/services/transform.service';
import { TsmListService } from '../../../../entities/transformation/services/tsm-list.service';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DatePipe } from '@angular/common';
import { TRANSFORM_STATUSES } from '../../../transformation-manager/values/transform-statuses.const';
import { TransformStatus } from '../../../../entities/transformation/models/transform-status.class';
import { TransformDetailsDialogComponent } from '../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { MdDialog } from '@angular/material';
import {getTransformTypeLcView, TRANSFORM_TYPES} from '../../../transformation-manager/values/transform-types.const';
import {getSearchStatus} from '../../../../shared/helpers/search-status-update';
import {TranslateService} from "@ngx-translate/core";
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'supervisor-transformations-catalog',
  templateUrl: 'supervisor-transformations-catalog.component.html'
})
export class SupervisorTransformationsCatalogComponent implements OnInit {

  records: ICustomListRecord[] = [];

  //filters
  filterStatuses: TransformStatus[] = [
    TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION,
    TRANSFORM_STATUSES.PUBLISHED,
    TRANSFORM_STATUSES.ASK_FOR_MODIFICATION,
    //TRANSFORM_STATUSES.REJECTED // supervisor won't see rejected data anymore
  ];
  searchStatus: number[] = this.filterStatuses.map((status) => status.value);
  search = new FormControl();
  tsms: Searchable[];
  tsmId: number;

  sortOptions: any = [
    { field: '-created_at', view: 'Creation date'},
    { field: 'tsm_name', view: 'Transformation Manager'},  //TODO doesn't seem to work
    { field: 'name', view: 'Name'},
  ];
  sortValue: string = '-created_at';

  TRANSFORM_TYPES = TRANSFORM_TYPES;
  transformType: number;

  constructor(private _transformService: TransformService,
              private _translationService: TranslateService,
              private _tsmListService: TsmListService,
              private _datePipe: DatePipe,
              private dialog: MdDialog,
              private navBarState: NavbarStateService,) {
    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => this.getRecords())
    ;
    this
      ._tsmListService
      .get()
      .subscribe((tsms: Searchable[]) => this.tsms = tsms)
    ;
  }

  ngOnInit(): void {
    this.getRecords();
  }

  getRecords() {
    this.records.length = 0;

    let params: any = {
      expand: 'files,materials,tsm_name,tags,sites,sectors',
      supervisor: true,
      supervisor_view:1,
      search: this.search.value || null,
      tsm: this.tsmId || null,
      transformation_type: this.transformType || null,
      'status[]': [],
      sort: this.sortValue,
      nopaginate: 1,
    };
    for (let stat of this.searchStatus) {
      params['status[]'].push(stat);
    }

    if (this.navBarState.multilanguage) {
      params.english = true;
    }

    this._transformService
      .get(params)
      .subscribe((data: Transform[]) => data.forEach((item) => this.addRecord(item)))
    ;
  }

  addRecord(data: Transform) {
    //-blue hand for waiting for publication
    //-green check for published
    let status:ECustomListRecordStatus;
    switch (data.status) {
      case TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.value:
        status = ECustomListRecordStatus.MANUAL;
        break;
      case TRANSFORM_STATUSES.PUBLISHED.value:
        status = ECustomListRecordStatus.CONFIRM;
        break;
      default:
        status = ECustomListRecordStatus.NO_STATUS;
    }

    let incomeMaterials = data.materials.filter((material) => material.is_input === 1);
    let incomeMaterialsString = (incomeMaterials.map((material) => {return this._translationService.instant(material.name)})).join(', ');
    let outcomeMaterials = data.materials.filter((material) => material.is_input !== 1);
    let outcomeMaterialsString = (outcomeMaterials.map((material) =>{return this._translationService.instant(material.name)})).join(', ');

    let incomeTags = data.tags.filter(tag => tag.is_input === 1).map(tag => tag.name).join(', ');
    let outcomeTags = data.tags.filter(tag => tag.is_input !== 1).map(tag => tag.name).join(', ');

    this.records.push(
      this.createTransformationRecord(
        status,
        ECustomListThemeClass.DARK,
        data.transformation_type_data.value ? getTransformTypeLcView(data.transformation_type_data.value) : '',
        data.viewName,
        data.transformation_type_data.view.replace(/./, (c: string) => c.toUpperCase()),
        incomeMaterialsString,
        outcomeMaterialsString,
        incomeTags,
        outcomeTags,
        data.transformation_type_data.includes.storingSites ? data.sites.length : data.sectors.length,
        this._datePipe.transform(data.created_at, 'dd.MM.y'),
        data.tsm_name,
        data,
      )
    );
  }

  createTransformationRecord(status: ECustomListRecordStatus,
                             theme: ECustomListThemeClass,
                             icon: string,
                             title: string,
                             subtitle: string,
                             inputMaterials: string,
                             outputMaterials: string,
                             inputTags: string,
                             outputTags: string,
                             totalSites: number,
                             addedDate: string,
                             author: string,
                             transform: Transform): CustomListRecord {
    const showValidationButtons = transform.status === TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.value;
    const reversedColumnEntries: Partial<ICustomListKeyPair> = {cssClass: ECustomListKeyPairClass.REVERSED_LIST_COLUMN, seperator: ' '};
    let materials: ICustomListKeyPair[] = [];
    let keywordsOrControlType: ICustomListKeyPair[] = [];
    if(transform.transformation_type_data.includes.incomeOutcome) {
      materials = [{
        key: 'Input', value: inputMaterials
      }, {
        key: 'Output', value: outputMaterials
      }];

      keywordsOrControlType = [{
        key: 'Keywords', value: inputTags
      }, {
        key: 'Keywords', value: outputTags
      }];
    } else if(transform.transformation_type_data.includes.acceptedMaterials) {
      materials = [{
        key: 'Accepted materials', value: transform.acceptedMaterialsView, ...reversedColumnEntries
      }];

      if(transform.transformation_type_data.includes.controlType) {
        keywordsOrControlType = [
          { key: 'Control type', value: transform.controlTypeView, ...reversedColumnEntries },
        ];
      }
    }

    return CustomListRecord
      .build()
      .setPrimaryKey(transform.id)
      .setStatus(status)
      .setTheme(theme)
      .addIconColumn(icon)
      .addTitleColumn(title, subtitle, transform.english_name)
      .addListColumn(materials)
      .addListColumn(keywordsOrControlType)
      .addCounterColumn(totalSites,
        transform.transformation_type_data.includes.storingSites
          ? 'Sites attached'
          : 'Sectors attached')
      .addDateColumn(addedDate, 'Added')
      .addAuthorColumn(author)
      .addControlsColumn([{
        fn: (data: ICustomListRecord) => {
          this.dialog.open(TransformDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR,
            data: {
              id: transform.id,
              readOnly: true,
              showValidationButtons: showValidationButtons,
            }
          }).afterClosed().subscribe(() => this.getRecords());
        },
        name: 'See details'
      },
        ...([])
      ]);
  }

  toggleState(state: number) {
    this.searchStatus = getSearchStatus(state, this.searchStatus);
    this.getRecords();
  }
}
