import { Component, Input, OnInit } from '@angular/core';
import { ToxicityState } from '../../../entities/passport/partials/passport-rating/passport-rating.component';


@Component({
    selector: 'app-rating-recyclability-icon',
    templateUrl: './rating-recyclability-icon.component.html',
})

export class RatingRecyclabilityCompositionIconComponent {
    @Input() toxicityState: ToxicityState; // Toxicity state of the product
    ToxicityState = ToxicityState; // Enum for toxicity state for the template
    
    constructor() { }
}
