<div class="additionaly">
  <section class="form-group" [formGroup]="unitInfoForm">
    <label class="form-group__label">{{ 'Quantity, availability & origin' | translate }}</label>
    <div class="additionaly__info">
      <div class="additionaly__info-quantity">
        <md-input-container
          class="additionaly__quantity-input">
          <input
            mdInput
            placeholder="{{ 'Quantity' | translate }}*"
            required="true"
            [(ngModel)]="deposit.quantity"
            formControlName="quantity"
            autocomplete="doNotAutocomplete"
            [class.highlighted]="controlStates?.quantity?.highlighted" />
          <md-error *ngIf="unitInfoForm?.get('quantity')?.errors?.min">
            {{'Quantity must be no less than 0.' | translate}}
          </md-error>
          <md-error *ngIf="unitInfoForm?.get('quantity')?.errors?.pattern">
            {{"Quantity must be a number." | translate }}
          </md-error>
          <md-error *ngIf="unitInfoForm?.get('quantity')?.errors?.required">
            {{""}}
          </md-error>
        </md-input-container>
        <md-input-container class="additionaly__unit-input">
          <input
            mdInput
            autocomplete="off"
            placeholder="{{ 'Unit' | translate }}*"
            [value]="deposit.passport?.unit_text"
            formControlName="unit">
        </md-input-container>
      </div>

      <div></div>

      <div class="additionaly__info-data">
        <md-input-container
          class="additionaly__number-input--fit-content">
          <input
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            mdInput
            autocomplete="off"
            placeholder="{{ 'Availability date' | translate }}*"
            required="true"
            formControlName="availability_date"
            [class.highlighted]="controlStates?.availability_date?.highlighted"/>
            <i class="icon icon--calendar additionaly__icon-calendar"></i>
        </md-input-container>
      </div>

      <div class="additionaly__info-data">
          <md-checkbox class="additionaly__use-period-checkbox"
            [md-tooltip]="getUsePeriodTooltipMessage()"
            formControlName="use_period_of_use">
              {{ 'Use period of use to define the availability date.' | translate }}
          </md-checkbox>
        <md-icon  class="additionaly__icon-remove">remove</md-icon>
        <md-input-container
          class="additionaly__number-input additionaly__icon-calendar-wrapper">
          <input
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            mdInput
            autocomplete="off"
            placeholder="{{ 'Expiration date' | translate }}"
            formControlName="availability_expiration"
            [class.highlighted]="controlStates?.availability_expiration?.highlighted"/>
          <i class="icon icon--calendar additionaly__icon-calendar"></i>
        </md-input-container>
      </div>
    </div>
  </section>
  <section class="form-group" [formGroup]="unitInfoForm">
    <label class="form-group__label additionaly__itemstate">{{ 'Deposit origin & potential becoming' | translate }}</label>
    <div class="additionaly__source">
      <md-select
        class="additionaly__source-select"
        formControlName="reuse_source"
        [placeholder]="'Source' | translate">
        <md-option *ngFor="let option of DEPOSIT_SOURCE_LIST" [value]="option.key">
          {{option.value | translate}}
        </md-option>
      </md-select>
    </div>
    <div class="additionaly__source additionaly__source--l-bottom">
      <md-checkbox class="additionaly__source-checkbox"
                   formControlName="potential_reuse">
        {{ 'Reuse potential' | translate }}
      </md-checkbox>
    </div>
  </section>

  <section class="form-group">
    <label class="form-group__label additionaly__itemstate additionaly__demontability-label">
      {{ 'Demountability / separability' | translate }}
    </label>
    <div class="form-group__content--row additionaly__state">
      <div class="additionaly__state-slider">
        <md-card-content>
          <md-slider
            md-discrete
            [max]="4"
            [min]="0"
            [step]="1"
            [(ngModel)]="deposit.deposit_demountability"
          ></md-slider>
        </md-card-content>
        <section class="additionaly__state-position">
          <div class="additionaly__state-position--item lowercase" *ngFor="let state of demountabilityStates">
            {{state.view | translate}}
          </div>
        </section>

      </div>
    </div>
  </section>

  <section class="form-group">
    <label class="form-group__label
      additionaly__itemstate additionaly__demontability-label
      additionaly__demontability-label--l-height">
      {{ 'State of use' | translate }}
    </label>
    <div class="form-group__content--row additionaly__state additionaly__state">
      <div class="additionaly__state-slider">
        <md-card-content>
          <!--[thumb-label]="thumbLabel"-->
          <!--[tick-interval]="tickInterval"-->
          <md-slider
            md-discrete
            [max]="5"
            [min]="1"
            [step]="1"
            [value]="1"
            [(ngModel)]="deposit.condition"
            [class.highlighted]="controlStates?.condition?.highlighted"
          ></md-slider>
        </md-card-content>
        <section class="additionaly__state-position">
          <div class="additionaly__state-position--irrelevant lowercase"> {{ 'Irrelevant' | translate }}</div>
          <div class="additionaly__state-position--asnew lowercase">{{ 'As new' | translate }}</div>
          <div class="additionaly__state-position--lightlynew lowercase">{{ 'Slightly used' | translate }}</div>
          <div class="additionaly__state-position--used lowercase">{{ 'FILTER_USED' | translate }}</div>
          <div class="additionaly__state-position--degraded lowercase">{{ 'Deteriorated' | translate }}</div>
        </section>

      </div>
      <div class="additionaly__state-text">
        <md-input-container
          class="additionaly__statedescription-textarea"
          [class.highlighted]="controlStates?.description?.highlighted"
        >
          <textarea
            mdInput
            #otherDescription
            autocomplete="off"
            placeholder="{{ 'State description' | translate }}"
            [(ngModel)]="deposit.description"
          > </textarea>
        </md-input-container>
      </div>
    </div>
  </section>

  <section class="form-group" [formGroup]="unitInfoForm">
    <label class="form-group__label additionaly__itemstate">
      {{ 'Release price' | translate }}
    </label>
    <div class="additionaly__price-wrapper">
      <div class="additionaly__price">
        <md-input-container
          class="additionaly__price-input">
          <input
            mdInput
            placeholder="{{ 'Price' | translate }}"
            formControlName="price"
            autocomplete="off"/>
          <md-error *ngIf="unitInfoForm?.get('price')?.errors">
            {{"Price must be a number." | translate }}
          </md-error>
        </md-input-container>
        <span class="additionaly__price-suffix">
          €
        </span>
        <label md-tooltip="{{
          'Price advertised by the reseller of the second-hand or secondary raw material deposit' | translate}}">
          <i class="form-group__label--question additionaly__label--question additionaly__price-label">?</i>
        </label>
      </div>
      <md-input-container
        class="additionaly__price-link">
        <input
          mdInput
          placeholder="{{ 'Link to the reseller\'s website' | translate }}"
          formControlName="url_link"
          autocomplete="off"/>
        <md-error *ngIf="unitInfoForm?.get('url_link')?.errors?.maxlength">
          {{"[X] must be a positive number" | translate:{X: ('Link to the reseller\'s website' | translate)} }}
        </md-error>
        <md-error *ngIf="unitInfoForm?.get('url_link')?.errors?.pattern">
          {{"VALIDATOR_ERROR_PRIMARY_MESSAGE" | translate }}
        </md-error>
      </md-input-container>
    </div>
  </section>

  <section class="form-group" [formGroup]="unitInfoForm">
    <label class="form-group__label additionaly__itemstate">
      {{ 'Additional product characteristics' | translate }}
      <label md-tooltip="{{ 'These fields allow you to add information specific to the products ' +
              'in your deposit to complete / adjust the information in the passport' | translate }}">
        <i class="form-group__label--question additionaly__label--question">?</i>
      </label>
    </label>
    <div class="additionaly__product-additonal">
      <md-input-container
        floatPlaceholder="always"
        class="additionaly__product-additonal-input--double-placeholder">
        <textarea
          #productCharacteristics
          mdInput
          placeholder="{{'Product characteristics' | translate}}"
          formControlName="product_comment"
          autocomplete="off"></textarea>
        <span class="additionaly__product-additonal-input--double-placeholder-wrapper"
              [attr.data-additonal-placeholder]="'Additional product characteristics' | translate "></span>
        <md-error  *ngIf="unitInfoForm?.get('product_comment')?.errors">
          {{'[X] should contain at most [Y] characters.'
          | translate:{X: 'Product characteristics' | translate, Y: URL_MAX_LENGTH + ''} }}
        </md-error>
      </md-input-container>

      <div class="additionaly__dimensions">
        <md-input-container
          class="additionaly__quantity-input">
          <input
            mdInput
            placeholder="{{ 'Length' | translate }}"
            formControlName="length"
            autocomplete="off"/>
          <md-error *ngIf="unitInfoForm?.get('length')?.errors">
            {{"[X] must be a positive number" | translate:{X: ('Length'  | translate)} }}
          </md-error>
        </md-input-container>
        <span class="additionaly__dimensions-unit">mm</span>
        <span class="additionaly__dimensions-delimiter">X</span>
        <md-input-container
          class="additionaly__quantity-input">
          <input
            mdInput
            placeholder="{{ 'Width' | translate }}"
            formControlName="width"
            autocomplete="off"/>
          <md-error *ngIf="unitInfoForm?.get('width')?.errors">
            {{"[X] must be a positive number" | translate:{X: ('Width'  | translate)} }}
          </md-error>
        </md-input-container>
        <span class="additionaly__dimensions-unit">mm</span>
        <span class="additionaly__dimensions-delimiter">X</span>
        <md-input-container
          class="additionaly__quantity-input">
          <input
            mdInput
            placeholder="{{ 'Height' | translate }}"
            formControlName="height"
            autocomplete="off"/>
          <md-error *ngIf="unitInfoForm?.get('height')?.errors">
            {{"[X] must be a positive number" | translate:{X: ('Height' | translate)} }}
          </md-error>
        </md-input-container>
        <span class="additionaly__dimensions-unit">mm</span>
      </div>

      <md-input-container
        floatPlaceholder="always"
        class="additionaly__product-additonal-input--double-placeholder">
        <textarea
          #color
          mdInput
          placeholder="{{ 'Appearance and color' | translate }}"
          formControlName="color"
          autocomplete="off"></textarea>
        <span class="additionaly__product-additonal-input--double-placeholder-wrapper"
              [attr.data-additonal-placeholder]="'Aspects, colors, opacity...' | translate "></span>
        <md-error>
          {{'[X] should contain at most [Y] characters.'
          | translate:{X: 'Appearance and color' | translate, Y: URL_MAX_LENGTH + ''} }}
        </md-error>
      </md-input-container>

      <passport-conversion *ngIf="isConversionShown()"
        class="additionaly__conversion"
        [ngModel]="deposit.conversion"
        [unitView]="deposit.passport.unit_text"
        formControlName="conversion"
      >
      </passport-conversion>
    </div>
  </section>

  <section class="form-group">
    <label class="form-group__label">
      {{ 'Deposit comments, photos & documents' | translate }}
    </label>
    <div class="additionaly__document">
      <md-input-container
        class="additionaly__statecomment-textarea"
        [class.highlighted]="controlStates?.comments?.highlighted"
      >
          <textarea
            #otherComment
            mdInput
            autocomplete="off"
            placeholder="{{ 'Comment' | translate }}"
            [(ngModel)]="deposit.comments"
          ></textarea>
      </md-input-container>

      <h3 class="additionaly__document-label">
        {{ 'Photos' | translate }}
      </h3>
      <documents-uploader
        class="additionaly__document-photo"
        [files]="uploadedFiles"
        [uploadUrl]="_depositService.getFileUploadUrl(deposit.id)"
        [service]="_depositPhotoService"
        [uploader]="depositPhotoUploader"
      >
      </documents-uploader>

      <h3 class="additionaly__document-label">
        {{ 'Documents' | translate }}
      </h3>

      <documents-uploader
        class="additionaly__document-files"
        [files]="uploadedDocs"
        [uploadUrl]="_depositService.getDocUploadUrl(deposit.id)"
        [service]="fileService"
      >
      </documents-uploader>

    </div>
  </section>
</div>
