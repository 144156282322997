import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PIE_CHART_SIZE } from '../../../../values/pie-chart-size.const';
import { ReportService } from '../../../../services/report.service';
import { ActivatedRoute } from '@angular/router';
import { ReportBase } from '../report-base/report-base.class';
import { REPORT_NAME } from '../types/report-name.const';
import { ICircularityProductCategory } from '../../new-report/circularity/helpers/get-main-product-categories.helper';
import { LanguageService } from '../../../../../../entities/client/service/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportSiteTagPresenterService } from '../../../../services/report-site-tag-presenter.service';
import { ElRowCounterService } from '../../../../services/el-row-counter.service';
var CircularityReportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CircularityReportComponent, _super);
    function CircularityReportComponent(reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter) {
        var _this = _super.call(this, reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter) || this;
        _this.reportService = reportService;
        _this.activatedRoute = activatedRoute;
        _this.languageService = languageService;
        _this.translateService = translateService;
        _this.reportPresenter = reportPresenter;
        _this.rowCounter = rowCounter;
        _this.reportName = REPORT_NAME.CIRCULARITY;
        _this.categoriesTable = [];
        _this.errorsTable = [];
        _this.categories = [];
        _this.pieChartSize = PIE_CHART_SIZE;
        return _this;
    }
    CircularityReportComponent.prototype.ngOnInit = function () {
        this.createReport();
    };
    CircularityReportComponent.prototype.createReport = function () {
        var _this = this;
        this.reportRequest()
            .subscribe(function () {
            _this.loadMainProductCategory();
            _this.prepareTablesDataForRender();
        });
    };
    CircularityReportComponent.prototype.loadMainProductCategory = function () {
        var sortedByGrade = this.getSortedProductsBy('circularity_grade');
        var getProductCategoryBlockData = function (key) {
            return key ? tslib_1.__assign({}, key, { titleForFooter: key.category.name, mainValue: Math.ceil(key.circularity_grade) }) : null;
        };
        this.mainProductCategory = {
            mostCircular: getProductCategoryBlockData(sortedByGrade[0]),
            leastCircular: getProductCategoryBlockData(sortedByGrade[sortedByGrade.length - 1]),
            mostPresent: getProductCategoryBlockData(this.getSortedProductsBy('weight')[0])
        };
    };
    CircularityReportComponent.prototype.setCategories = function () {
        this.categories = [
            this.getDefaultCategory()
        ].concat(this.getSortedProductsBy('circularity_grade')
            .filter(function (product) { return !!product.deposits_considered; }));
    };
    CircularityReportComponent.prototype.getDefaultCategory = function () {
        return {
            category: { name: 'Total', parent_id: void 0, id: void 0 },
            isDefault: true,
            weight: this.result.aggregate.total_weight,
            deposit_count: this.result.aggregate.deposit_count,
            deposits_considered: this.result.aggregate.deposits_considered,
            demountability_grade: this.result.aggregate.demountability_grade,
            next_usage_potential_grade: this.result.aggregate.next_usage_potential_grade,
            information_characterization_and_rating_grade: this.result.aggregate.information_characterization_and_rating_grade,
            past_life_grade: this.result.aggregate.past_life_grade,
            future_life_grade: this.result.aggregate.future_life_grade,
            circularity_grade: this.result.aggregate.circularity_grade,
            percentage_by_weight: 100,
            reused_percentage_by_weight: this.result.aggregate.reused_percentage,
            percentage_of_recycled_material: this.result.aggregate.recycled_percentage,
        };
    };
    CircularityReportComponent.prototype.prepareTablesDataForRender = function () {
        this.prepareCategoriesTable();
        this.prepareErrorsTable();
    };
    CircularityReportComponent.prototype.prepareCategoriesTable = function () {
        var _this = this;
        this.setCategories();
        this.onEachChunkedCategory(function (chuck, index) {
            _this.categoriesTable.push({
                pageNumber: index,
                pageContent: chuck
            });
        });
    };
    CircularityReportComponent.prototype.prepareErrorsTable = function () {
        var _this = this;
        this.onEachChunkedError(function (chuck, index) {
            _this.errorsTable.push({
                pageNumber: index + _this.categoriesTable.length,
                pageContent: chuck
            });
        });
    };
    return CircularityReportComponent;
}(ReportBase));
export { CircularityReportComponent };
