import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import { Passport } from '../../models/passport.class';
import { PassportKeywordService } from '../../services/keyword.service';
import { PassportFileService } from '../../services/passport-file.service';
import { PassportComponent } from '../../models/component.class';
import { PassportFile } from '../../models/passport-file.class';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { Subscription } from 'rxjs/Subscription';
import { PASSPORT_UNITS, PASSPORT_UNITS_TYPE } from '../../values/passport-units.const';
import {IPeriodOfUseType} from '../../models/period-of-use.interface';
import {PERIOD_OF_USE_TYPES} from '../../values/period-of-use.values';
import {PERFORMANCE_RADIOS, PERFORMANCE_RADIOS_VALUES} from '../../values/performance-radios.const';
import {PASSPORT_CERTIFICATION_LEVELS} from '../../values/passport-certification-levels.const';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {PASSPORT_TAB} from '../../values/passport-tab.const';
import {TranslateService} from '@ngx-translate/core';
import {IParentEvent} from '../../../../shared/interfaces/parent-event.interface';
import { NavbarStateService } from '../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'passport-details-view',
  templateUrl: 'passport-details-view.component.html',
  styleUrls: ['passport-details-view.component.scss'],
})
export class PassportDetailsViewComponent implements OnInit, OnDestroy {
  @Input() passport: Passport;
  selectedPassportComponent: PassportComponent;
  uploadedPassportFiles: PassportFile[] = [];
  period_types: IPeriodOfUseType[] = PERIOD_OF_USE_TYPES;
  performanceRadios = PERFORMANCE_RADIOS;
  certificationLevels = PASSPORT_CERTIFICATION_LEVELS;
  parentModule: string;
  subcription: Subscription;
  isToggleChecked: boolean;
  PASSPORT_UNITS = PASSPORT_UNITS;
  moduleNames = ModuleNames;
  private selectedTab = PASSPORT_TAB.GENERAL;
  @Input() listener: EventEmitter<IParentEvent>;

  constructor(private passportKeywordService: PassportKeywordService,
              private passportFileService: PassportFileService,
              public navigationService: NavigationService,
              private traslateService: TranslateService,
              public state: NavbarStateService) {
    this.parentModule = this.navigationService.getModuleName();
  }

  ngOnInit() {
    if(this.passport) {
      this.passport.syncComponents();
      this.preloadUploadedPassportItems();

      this.subcription = this.passport.onSyncComponents$.subscribe(() => {
        this.selectedPassportComponent = this.passport.components.find((component) => component.isDefault);
      });
    }
  }

  translate(value) {
    return this.traslateService.instant(value);
  }

  showConversion() {
    return this.passport.unit != PASSPORT_UNITS_TYPE.KG && this.passport.unit != PASSPORT_UNITS_TYPE.TONNE
  }
  ngOnDestroy() {
    if(this.subcription) {
      this.subcription.unsubscribe();
    }
  }

  selectComponent(passportComponent: PassportComponent) {
    this.selectedPassportComponent = passportComponent;
  }

  preloadUploadedPassportItems() {
    if (!this.passport.id) {
      return;
    }

    this.uploadedPassportFiles = [];
    this.passportFileService
      .get<PassportFile>({passport_id: this.passport.id})
      .subscribe((results) => {
        for (const file of results) {
          if (file.isVisibleForAll()) {
            this.uploadedPassportFiles.push(new PassportFile(file));
          }
        }
      });
  }
  getImageStyle() {
    if (!this.passport.thumbnail) {
      return;
    }
    return {
      'background': `url(${this.passport.thumbnail}) no-repeat center center`,
      'height': '100%',
      'width': '100%',
      'background-size': 'contain'
    };
  }
  getLabelClasses(field) {
    return {
      'primary-color': field,
      'label-color': !field,
      'passport-edit__color-transition': true
    };
  }
  getCertificationLevelValue(): string {
    if (!this.passport || !this.passport.certification_level) {
      return null;
    }
    const type = this.certificationLevels.find(i => i.key === this.passport.certification_level);
    return type && type.value;
  }
  getPassportTypeValue(): string {
    if (!this.passport || !this.passport.period_type) {
      return null;
    }
    const type = this.period_types.find(i => i.value === this.passport.period_type);
    return type && this.translate(type.momentUnit);
  }
  areC2cAdditionalFieldsShown(): boolean {
    return this.passport.c2c_certified === PERFORMANCE_RADIOS_VALUES.YES;
  }
  nil() {
    return;
  }
}
