<h2 *ngIf="!this.id">{{'CREATE_NEW_MYUPCYCLEA_SPACE' | translate}}</h2>

<ng-container [formGroup]="userForm">
  <div *ngIf="parentModule != moduleNames.SPECIAL_ADMIN"
       class="group"
       [ngClass]="{'group--small-gap': isAdministrator(),
          'group--large-gap': !isAdministrator()}">
    <h3 [ngClass]="{'header--big-font': isAdministrator()}">
      {{'Administrator entity' | translate}}
    </h3>
    <div>
      <md-input-container [ngClass]="{'entity-name': isAdministrator(), 'header--left-indent': !isAdministrator()}">
        <input mdInput
               autocomplete="new-password"
               formControlName="admin_entity_name"
               required="true"
               placeholder="{{'Entity name' | translate }} *"
               [(ngModel)]="user.company.name" [value]="user.company?.name || ''">
      </md-input-container>
    </div>
    <!--<div class="row">-->
    <!--<md-input-container class="wide">-->
    <!--<input mdInput formControlName="company_description" [placeholder]="'Description' | translate" [(ngModel)]="user.company.description">-->
    <!--</md-input-container>-->
    <!--</div>-->
  </div>

  <div *ngIf="isExistingUser()" class="group">
    <h3>{{'Company ID: ' + user.company.id}}</h3>
  </div>

  <div class="group--smaller-gap" *ngIf="isAdministrator()">
    <div>
      <md-select *ngIf="organisation_types"
                 class="organisation-type organisation-type--none-floating-placeholder-offset"
                 placeholder="{{'Organisation type' | translate }} *"
                 formControlName="organisation_type">
        <md-option
          *ngFor="let option of organisation_types"
          [value]="option.value">
          {{ option.text  | translate }}
        </md-option>
      </md-select>
    </div>

    <div class="group--tiny-gap row row--no-tab">
      <md-checkbox class="checkbox--wider-gap checkbox--thin-font-weight" formControlName="free_pilot">
        {{ 'Free pilot' | translate }}
      </md-checkbox>
      <div *ngIf="userForm">
        <md-input-container class="date">
          <input
            mdInput
            autocomplete="new-password"
            ng2-datetime-picker
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            date-only="true"
            [placeholder]="'End date' | translate"
            formControlName="pilot_end_date"
          >
        </md-input-container>
      </div>
    </div>
    <div class="group--tiny-gap">
      <md-checkbox class="checkbox--wider-gap checkbox--thin-font-weight" formControlName="need_access">
        {{ 'Need' | translate }}
      </md-checkbox>
    </div>
    <div class="group--tiny-gap">
      <md-checkbox class="checkbox--wider-gap checkbox--thin-font-weight" formControlName="transformation_acces">
        {{ 'Transformation' | translate }}
      </md-checkbox>
    </div>
    <div class="group--tiny-gap">
      <md-checkbox class="checkbox--wider-gap checkbox--thin-font-weight" formControlName="noah_access">
        {{ 'ADMIN_NOAH_CHECKBOX' | translate }}
      </md-checkbox>
    </div>
    <div class="group--tiny-gap">
      <md-checkbox class="checkbox--wider-gap checkbox--thin-font-weight" formControlName="noah_account">
        {{ 'ADMIN_NOAH_ACCOUNT_CHECKBOX' | translate }}
      </md-checkbox>
    </div>
  </div>

  <div class="group">
    <h3 [ngClass]="{'header--tiny-font': isAdministrator()
      ,'header--tiny-margin': isAdministrator()}">
      {{'Documents' | translate}}
    </h3>
    <documents-uploader *ngIf="isAdministrator()"
      class="passport__uploader user-docs"
      [autoupload]="!!id"
      [enableQueueFileEdit]="!isExistingUser()"
      [uploadUrlSet]="this.fileUploadUrlSet$"
      [service]="fileUploaderService"
      [files]="companyFiles"
      [onUploadAll]="saveUserDocuments$"
      (onAllItemsUploaded)="filesUploaded$.emit($event)"
    >
    </documents-uploader>
  </div>

    <ng-container *ngIf="userForm.value.organisation_type === ORGANISATION_TYPE_IDS.Building && isAdministrator()">
      <div>
        <h3 class="header--big-font header--smaller-margin header--offset-top">{{'Subscription' | translate}}</h3>
        <div class="row row--wide-gap row--no-tab">
          <div class="site-status-row">
            <md-checkbox class="checkbox--wider-gap checkbox--thin-font-weight" formControlName="construction">
              {{ 'construction' | translate | capitalize }}
            </md-checkbox>
            <md-checkbox class="checkbox--wider-gap checkbox--thin-font-weight" formControlName="operation">
              {{ 'operation' | translate | capitalize }}
            </md-checkbox>
            <md-checkbox class="checkbox--wider-gap checkbox--thin-font-weight" formControlName="storage">
              {{ 'storage' | translate | capitalize }}
            </md-checkbox>
          </div>
          <md-input-container class="esm-number-input-container">
            <input mdInput
                   type="number"
                   formControlName="max_ESM"
                   placeholder="{{'Number of ESM' | translate}}">
          </md-input-container>
        </div>
      </div>
      <company-sites [userId]="id" [companyId]="user?.company?.id"></company-sites>
    </ng-container>

  <div class="group flex-column">
    <h3 *ngIf="!isExistingUser()">{{'Responsible User' | translate}} ({{'Entity Admin' | translate}})</h3>
    <h3 *ngIf="isExistingUser()">{{'Contact details' | translate}} (
      <span *ngFor="let role of user.roles; let index = index">
          {{ role | translate }}{{ index === user.roles.length - 1 ? '' : ','}}
      </span>
      )</h3>
    <div class="flex">
      <div class="group">

        <div class="row">
          <md-input-container>
            <input mdInput autocomplete="new-password" formControlName="first_name"
                   placeholder="{{'First name' | translate }} *"
                   [(ngModel)]="user.first_name">
          </md-input-container>
          <md-input-container>
            <input mdInput autocomplete="new-password" formControlName="last_name"
                   placeholder="{{'Last name' | translate }} *"
                   [(ngModel)]="user.last_name">
          </md-input-container>
        </div>


        <div class="row">
          <md-input-container>
            <input mdInput autocomplete="new-password" formControlName="phone" [placeholder]="'Phone' | translate"
                   [(ngModel)]="user.phone">
          </md-input-container>
          <md-input-container>
            <input mdInput autocomplete="new-password" formControlName="email" placeholder="{{'E-mail' | translate }} *"
                   [(ngModel)]="user.email"
                   required="true"
                   (ngModelChange)="emailChanged()"
                   email>
          </md-input-container>
        </div>

        <div class="row">
          <md-input-container class="wide">
            <input mdInput autocomplete="new-password" formControlName="description"
                   [placeholder]="'Description' | translate" [(ngModel)]="user.description">
          </md-input-container>
        </div>

        <div class="row">
          <md-input-container>
            <input mdInput
                   autocomplete="new-password"
                   formControlName="company_name"
                   placeholder="{{'Organisation name' | translate }}{{isExistingUser() ?  '' : ' *'}}"
                   required="true"
                   [(ngModel)]="user.company_name">
          </md-input-container>
          <md-input-container>
            <input mdInput
                   autocomplete="new-password"
                   formControlName="SIRET"
                   [placeholder]="'SIRET' | translate"
                   [(ngModel)]="user.SIRET"
            >
          </md-input-container>
        </div>
      </div>
      <div class="group-avatar-control">
        <div class="user-details__profile-img-control">
          <avatar-uploader
            [(preview)]="user.avatar_url"
            [userId]="user.id"
          ></avatar-uploader>
        </div>
        <language-selector
          [autosetLanguage]="false"
          [(language)]="user.language"
          showPlaceHolder="'always'"
        ></language-selector>
      </div>
    </div>
  </div>


  <div class="group">
    <h3>{{'Contact credentials' | translate}}</h3>

    <div class="row">
      <md-input-container>
        <input mdInput autocomplete="new-password" formControlName="username" required="true" placeholder="{{'Username' | translate }} *"
               [(ngModel)]="user.username">
      </md-input-container>

      <md-checkbox
        *ngIf="!isExistingUser()"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="emailAsUsername"
        (ngModelChange)="updateEmailAsUsername()"
      >{{'Use Email as Username' | translate}}</md-checkbox>
    </div>

    <div class="row" formArrayName="password" *ngIf="isPasswordFieldsVisible()">
      <md-input-container>
        <input mdInput autocomplete="new-password" type="password" [placeholder]="'Password' | translate"
               formControlName="0"
               [(ngModel)]="user.password">
      </md-input-container>
      <md-input-container
        [class.ng-valid]="this.userForm.controls.password.valid"
        [class.ng-invalid]="this.userForm.controls.password.invalid"
      >
        <input
          mdInput
          autocomplete="new-password"
          type="password"
          [placeholder]="'Repeat password' | translate"
          formControlName="1"
          [(ngModel)]="user.passwordConfirm"
        >
      </md-input-container>
    </div>
    <button
      class="delete-button text"
      *ngIf="user.status==0"
      (click)="resendActivation()"
    >{{ 'Resend activation email' | translate }}
    </button>
  </div>
</ng-container>

<div class="row actions">
  <button *ngIf="isExistingAgent()"
          class="delete-button text"
          (click)="deleteCompany()"
  >{{'Delete the whole company'}}
  </button>
  <button *ngIf="!isExistingUser()"
          class="delete-button text"
  >{{'Delete permanently' | translate}}
  </button>
  <button
    class="save-button"
    (click)="save()"
    [disabled]="!userForm.valid || this.isLoading"
  >{{'Save' | translate}}
  </button>
  <button
    test-id="login-as-user"
    (click)="loginAsUser()"
    class="login-as-this-user-button text"
  >{{'Login as this user' | translate}}
  </button>
</div>
