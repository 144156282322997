import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NavigationService} from '../../../../shared/services/navigation.service';

@Component({
  template: `
    <company-details
      (onDeleteAction)="router.navigate([parentModule+'/users'])"
      (action)="router.navigate([parentModule+'/users'])"
      [id]="activatedRoute.snapshot.params['id'] * 1"
    ></company-details>
  `,
  styles: [`
    :host {
      width: initial !important;
      margin: auto;
      margin-top: 55px;
      background: white;
      border-radius: 0.3125rem;
      box-shadow: 0 0.09375rem 0.09375rem 0 rgba(0, 0, 0, 0.22);
    }
  `],
})
export class CompanyEditComponent {
  parentModule: string;

  constructor(public activatedRoute: ActivatedRoute,
              private _navigationService: NavigationService,
              public router: Router) {
    this.parentModule = this._navigationService.getModuleName();
  }
}
