import { Ecosystem } from '../../models/ecosystem.class';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { AbstractReportStrategy } from './abstract-report-strategy.service';

export class WasteStrategy extends AbstractReportStrategy {
    fileType = ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_WASTE;
    unit = 'Kg';
    otherAdd(ecosystem: Ecosystem, value?: number): number {
      this.setOtherTotal(value, (val) => ecosystem.add_other_waste = val);
      return ecosystem.add_other_waste;
    }
    otherSubtract(ecosystem: Ecosystem, value?: number): number {
      this.setOtherTotal(value, (val) => ecosystem.subtract_other_waste = val);
      return ecosystem.subtract_other_waste;
    }
    otherDescription(ecosystem: Ecosystem, value?: string): string {
      this.setOtherDescription(value, (val) => ecosystem.description_other_waste = val);
      return ecosystem.description_other_waste;
    }

    getTotalErrors(ecosystem: Ecosystem) {
        return ecosystem.waste_total.errors;
    }
}
