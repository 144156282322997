import * as tslib_1 from "tslib";
import { StatsContainer } from './stats-container.class';
import { AgentStats } from './agent-stats.class';
var AgentStatsContainer = /** @class */ (function (_super) {
    tslib_1.__extends(AgentStatsContainer, _super);
    function AgentStatsContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AgentStatsContainer.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
        return json;
    };
    AgentStatsContainer.prototype.createModel = function (json, key) {
        key.forEach(function (k) {
            if (!!json[k]) {
                json[k] = json[k].map(function (data) { return new AgentStats(data); });
            }
        });
    };
    AgentStatsContainer.prototype.getDeposits = function () {
        var result = {};
        var deposits_assigned = 0, deposits_characterized = 0, deposits_published = 0, deposits_rejected = 0;
        if (this.total) {
            this.total.forEach(function (item) {
                if (item.deposits_assigned) {
                    deposits_assigned += item.deposits_assigned;
                }
                if (item.deposits_characterized) {
                    deposits_characterized += item.deposits_characterized;
                }
                if (item.deposits_published) {
                    deposits_published += item.deposits_published;
                }
                if (item.deposits_rejected) {
                    deposits_rejected += item.deposits_rejected;
                }
            });
        }
        result.deposits_assigned = deposits_assigned;
        result.deposits_published = deposits_published;
        result.deposits_characterized = deposits_characterized;
        result.deposits_characterized_pct = this.calculatePercentage(deposits_characterized, deposits_published + deposits_rejected);
        result.deposits_rejected = deposits_rejected;
        result.deposits_rejected_pct = this.calculatePercentage(deposits_rejected, deposits_published + deposits_rejected);
        return result;
    };
    AgentStatsContainer.prototype.getSites = function () {
        var result = {};
        var locations_assigned = 0, locations_published = 0;
        if (this.total) {
            this.total.forEach(function (item) {
                if (item.deposit_locations_assigned) {
                    locations_assigned += item.deposit_locations_assigned;
                }
                if (item.deposit_locations_published) {
                    locations_published += item.deposit_locations_published;
                }
            });
        }
        result.locations_assigned = locations_assigned;
        result.locations_published = locations_published;
        return result;
    };
    AgentStatsContainer.prototype.calculatePercentage = function (value, total) {
        if (total === 0) {
            return '0%';
        }
        return (value / total * 100).toFixed(1) + '%';
    };
    return AgentStatsContainer;
}(StatsContainer));
export { AgentStatsContainer };
