<form *ngIf="genericPassport" [formGroup]="genericPassportForm">
  <section class="form-group">
    <div class="band"> {{'General information' | translate}}</div>

    
    <div class="form-group__field gptags">
        <h5 class="titles">{{'Name tags' | translate}} *</h5>
      <md-chip-list class="gptags-chiplist">
        <md-chip class="gpchip" *ngFor="let tag of genericPassport.tags">
          <span *ngIf="tag.name.length > tagsMaxlength ;else tagIncomeBlock" md-tooltip="{{getSynonymNames(tag.synonyms)}}">
            {{tag.name | slice:0:tagsMaxlength }}..
            <input type="button" value="X" (click)="removeTag(tag)" class="gptag__delete">
          </span>
          <ng-template #tagIncomeBlock>
            <span md-tooltip="{{getSynonymNames(tag.synonyms)}}">
              {{tag.name}}
              <input type="button" value="X" (click)="removeTag(tag)" class="gptag__delete">
            </span>
          </ng-template>
        </md-chip>
      </md-chip-list>
      <md-input-container class="input-tags">
        <input mdInput autocomplete="off" formControlName="tags" placeholder="{{'Name' || translate}} *" [(ngModel)]="tagInputTexts[0]"
          (change)="addTag(0)">
      </md-input-container>
    </div>

    <div class="form-group__field">
      <md-input-container (click)="openCategoryeDialog($event)">
        <input mdInput autocomplete="off" formControlName="category" placeholder="{{ 'Category' | translate }} *">
      </md-input-container>
    </div>

    <div class="form-group__field">
      <md-select placeholder="{{ 'Construction layer' | translate }}*" [(ngModel)]="genericPassport.construction_layer" formControlName="constructionLayer">
        <md-option *ngFor="let item of constructionLayers" [value]="item.id">{{item.title | translate}}
        </md-option>
      </md-select>
    </div>

    <div class="form-group__field gpmaterials">
      <h6 class="titles">{{'Materials' | translate}} *</h6>
      <div class="gpmaterials-chiplist">
        <md-chip-list>
          <md-chip class="gpchip" *ngFor="let material of genericPassport.materials">
            <span *ngIf="material.name.length > 20 ;else elseBlock" md-tooltip="{{material.name | translate}}">
              {{material.name | translate | slice:0:20 }}..
              <input class="gpdelete-material" type="button" (click)="removeMaterial(material)" value="X">
            </span>
            <ng-template #elseBlock>
              <span md-tooltip="{{material.name | translate}}">
                {{material.name | translate }}
                <input class="gpdelete-material" type="button" (click)="removeMaterial(material)" value="X">
              </span>
            </ng-template>
          </md-chip>
        </md-chip-list>

      </div>
      <div class="gp-addmaterial" (click)="addMaterial(1)">
        + <span>{{'add a material' | translate}}</span>
      </div>

    </div>


    <div class="form-group">
      <div class="form-group__field display-inline">
        <md-select placeholder="{{ 'Unit' | translate }} *" (change)="unitChanged()" [(ngModel)]="genericPassport.unit" formControlName="unit">
          <md-option *ngFor="let unit of units" [value]="unit.id">{{unit .title | translate}}
          </md-option>
        </md-select>
      </div>
      <div class="form-group__field display-inline">
        <md-input-container class="">
          <input mdInput type="number" [(ngModel)]="genericPassport.conversion_factor"
            formControlName="conversionFactor" min="0" (ngModelChange)="generateFields()" placeholder=" {{ 'Conversion factor' | translate}} *">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        T/Unit
      </div>
    </div>
    <div class="form-group__field gp-textarea-field">
      <md-input-container class="textarea-field">
        <textarea [(ngModel)]="genericPassport.comment" formControlName="comment" mdInput
          placeholder="{{ 'Comment section' | translate }}"></textarea>
        </md-input-container>
      </div>
      <div class="form-group">
        <div class="form-group__field display-inline">
          <md-input-container class="control-group__item--msmall ">
            <input mdInput autocomplete="off" [(ngModel)]="genericPassport.purchase_cost" (ngModelChange)="generateFields()"
              formControlName="purchase_cost" type="number" min="0" placeholder="{{ 'Purchase cost' | translate}}">
          </md-input-container>
        </div>
        <div class="form-group__field display-inline gplabel">
          €/Unit
        </div>
      </div>
      <div class="form-group__field">
        <md-select placeholder="{{ 'Waste category' | translate }}" formControlName="waste_category"
          [(ngModel)]="genericPassport.waste_category_id" (ngModelChange)="changWasteCategory()">
          <md-option *ngFor="let landfill of landfillVariables" [value]="landfill.id">{{ landfill.name | translate }}
          </md-option>
        </md-select>
      </div>
      <div class="form-group">

        <div class="form-group__field display-inline">
          <md-input-container class="control-group__item--msmall ">
            <input mdInput [(ngModel)]="genericPassport.default_recoverability_average"
              formControlName="default_recoverability_average" type="number" min="0"
              autocomplete="off" placeholder="{{'Recoverability average' | translate }}">
          </md-input-container>
        </div>
        <div class="form-group__field display-inline gplabel">
          %
        </div>
      </div>
      <div class="form-group">
        <div class="form-group__field display-inline">
          <md-input-container class="control-group__item--msmall ">
            <input mdInput type="number" formControlName="landfilCost" [(ngModel)]="genericPassport.landfil_cost"
              (ngModelChange)="generateFields()" min="0" autocomplete="off" placeholder="{{'Landfill cost' | translate}}">
          </md-input-container>
        </div>
        <div class="form-group__field display-inline gplabel">
          €/T
        </div>
      </div>

  </section>
  <div class="band"> {{'Upcycling' | translate}}</div>
  <section class="form-group">

    <div class="form-group">
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput [(ngModel)]="genericPassport.upcycling_extra_cost_for_clean_removal"
            formControlName="upcyclingExtraCostForCleanRemoval" type="number" min="0"
            autocomplete="off" placeholder="{{ 'Extra costs for clean removal' | translate }}" (ngModelChange)="generateFields()">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/U
      </div>
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput [(ngModel)]="genericPassport.upcycling_resale_price_up"
            formControlName="upcyclingResalePriceUp" (ngModelChange)="generateFields()" type="number" 
            autocomplete="off" placeholder="{{'Resale price up' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/T
      </div>
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number" [(ngModel)]="genericPassport.upcycling_resale_price_down"
            formControlName="upcyclingResalePriceDown" (ngModelChange)="generateFields()" 
            autocomplete="off" placeholder="{{'Resale price down' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/T
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number" [(ngModel)]="genericPassport.upcycling_residual_value_up"
            formControlName="upcyclingResidualValueUp"  [readonly]="true"
            autocomplete="off" placeholder="{{'Residual value (high hypothesis)' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        %
      </div>
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number"  [readonly]="true" [(ngModel)]="genericPassport.upcycling_added_value_up"
            autocomplete="off" formControlName="upcyclingAddedValueUp"
            placeholder="{{ 'Added value(high hypothesis)' | translate}}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/U
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number"  [(ngModel)]="genericPassport.upcycling_residual_value_down"
          autocomplete="off" formControlName="upcyclingResidualValueDown" [readonly]="true" placeholder="{{'Residual value (low hypothesis)' | translate}}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        %
      </div>
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number"  [readonly]="true"
            [(ngModel)]="genericPassport.upcycling_added_value_down" formControlName="upcyclingAddedValueDown"
            autocomplete="off" placeholder="{{ 'Added value (low hypothesis)' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/U
      </div>
    </div>
  </section>
  <div class="band"> {{'Reuse' | translate}}</div>
  <section class="form-group">
    <div class="form-group">
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number" [(ngModel)]="genericPassport.reuse_extra_cost_for_clean_removal"
            formControlName="reuseExtraCostForCleanRemoval" (ngModelChange)="generateFields()" min="0"
            autocomplete="off" placeholder="{{'Extra costs for clean removal' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/U
      </div>
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number" [(ngModel)]="genericPassport.reuse_resale_price_up"
            formControlName="reuseResalePriceUp"  (ngModelChange)="generateFields()"
            autocomplete="off" placeholder="{{'Resale price up' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/U
      </div>
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number" [(ngModel)]="genericPassport.reuse_resale_price_down"
            formControlName="reuseResalePriceDown"  (ngModelChange)="generateFields()"
            autocomplete="off" placeholder="{{'Resale price down' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/U
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number"  [(ngModel)]="genericPassport.reuse_residual_value_up"
            formControlName="reuseResidualValueUp" [readonly]="true"
            autocomplete="off" placeholder="{{'Residual value (high hypothesis)' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        %
      </div>
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number"  [readonly]="true" [(ngModel)]="genericPassport.reuse_added_value_up"
          autocomplete="off" formControlName="reuseAddedValueUp" placeholder="{{'Added value(high hypothesis)' | translate}}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/U
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number"  [(ngModel)]="genericPassport.reuse_residual_value_down"
            formControlName="reuseResidualValueDown" [readonly]="true"
            autocomplete="off" placeholder="{{ 'Residual value (low hypothesis)' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        %
      </div>
      <div class="form-group__field display-inline">
        <md-input-container class="control-group__item--msmall ">
          <input mdInput type="number" [readonly]="true"  [(ngModel)]="genericPassport.reuse_added_value_down"
          autocomplete="off"  formControlName="reuseAddedValueDown" placeholder="{{ 'added value (low hypothesis)' | translate }}">
        </md-input-container>
      </div>
      <div class="form-group__field display-inline gplabel">
        €/U
      </div>
    </div>
  </section>

</form>