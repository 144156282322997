<div class="esm-catalog">
  <section class="esm-catalog__choice type--flex">
  <!--<header class="esm-catalog__header checkbox">-->
    <!--<ul class="esm-catalog__choice-list type&#45;&#45;auto">-->
      <!--<li class="esm-catalog__choice-item">-->
        <!--<md-checkbox-->
          <!--class="esm-catalog__group&#45;&#45;checkbox checkbox-group"-->
          <!--*ngFor="let type of TRANSFORM_TYPES"-->
          <!--(change)="addState(type.value); getRecords()"-->
          <!--[checked]="true"-->
        <!--&gt;{{type.view | capitalize | translate}}-->
        <!--</md-checkbox>-->
      <!--</li>-->
    <!--</ul>-->
    <ul class="esm-catalog__choice-list type--auto">
      <li class="esm-catalog__choice-item width--auto">
        <md-checkbox class="esm-catalog__group--checkbox checkbox-group"
                     [(ngModel)]="item.checked"
                     (change)="getRecords()"
                     *ngFor="let item of statuses"
                     [name] ="item.status">
          {{ item.text | capitalize | translate }}
        </md-checkbox>
      </li>
    </ul>
  <!--</header>-->

    <div class="esm-catalog__right-content">
      <a routerLink="transformations/new" class="esm-catalog__create-new">+ {{'create new' | translate}}</a>
    </div>
  </section>
  <section class="esm-catalog__choice type--flex">
    <ul class="esm-catalog__choice-list type--auto">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input mdInput test-id="search"
                 autocomplete="off" 
                 placeholder="{{'Search' | translate}}"
                 [formControl]="search"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput
                 autocomplete="off" 
                 test-id="available-date-from-filter"
                 ng2-datetime-picker
                 date-only="true"
                 date-format="DD-MM-YYYY"
                 placeholder="{{'Published FROM' | translate}}"
                 [(ngModel)]="publishedFrom"
                 (ngModelChange)="getRecords()"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput
                 autocomplete="off" 
                 test-id="available-date-to-filter"
                 ng2-datetime-picker
                 date-only="true"
                 date-format="DD-MM-YYYY"
                 placeholder="{{'Published TO' | translate}}"
                 [(ngModel)]="publishedTo"
                 (ngModelChange)="getRecords()"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <md-select
          test-id="transformation-type-selector"
          class="select-group"
          placeholder="{{ 'Type of transformation' | translate }}"
          [(ngModel)]="type"
          (ngModelChange)="getRecords()"
        >
          <md-option>{{ 'Any' | translate }}</md-option>
          <md-option
            *ngFor="let type of TRANSFORM_TYPES"
            [value]="type.value"
          >{{type.view | capitalize | translate}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--gray-sort"></i>
        <md-select
          test-id="sort-selector"
          class="select-group"
          placeholder="{{ 'Sort by' | translate }}"
          [(ngModel)]="sortValue"
          (ngModelChange)="getRecords()"
        >
          <md-option
            *ngFor="let sortValue of sortOptions"
            [value]="sortValue.field"
          >{{ sortValue.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
  ></custom-list>
</div>
