import {Component, Input, Output, EventEmitter, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NEED_SORT_NAME} from '../../../values/need-sort.enum';
import { ACTIVE_NEED_STATUSES, ARCHIVED_NEED_STATUSES } from '../../../values/need-statuses.const';
import { ECOSYSTEM_OFFER_ACTIVE_STATUS, ECOSYSTEM_OFFER_ARCHIVED_STATUS } from '../../../../../dashboards/ecosystem-manager/values/ecosystem-offer-status.const';
@Component({
    moduleId: module.id,
    selector: 'need-filter',
    templateUrl: 'need-filter.component.html',
    styleUrls: ['need-filter.component.scss']
})

export class NeedFilterComponent implements OnInit {
    @Output() onFilterUpdate: EventEmitter<any> = new EventEmitter<any>();

    @Input() public activeIndex: number;
    public sort_order = NEED_SORT_NAME;
    public searchControl = new FormControl();
    public searchKey = '';
    public orderBy = '-created_at';
    public createdDateFrom = '';
    public createdDateTo = '';
    public deliveryDateFrom = '';
    public deliveryDateTo = '';
    public isTargetedOnly = false;
    public isNeedWithOfferOnly = false; 
    public statusList = [];
    public offerStatusList = [];
    public recipientList = [];
    public deliveryLocationList = [];
    public depositLocationList = [];
    public recipientListLoaded = false;
    public deliveryLocationListLoaded = false;
    public depositLocationListLoaded = false;
    @Input('recipientList') set setRecipientList(data: ({value: number, text: string, view: string})[]) {
      this.recipientList =  this.populateFilterList(data, true, 'recipientList');
    }
    @Input('deliveryLocationList') set setDeliveryLocationList(data: ({value: number, text: string, view: string})[]) {
      this.deliveryLocationList = this.populateFilterList(data, true, 'deliveryLocationList');
    }
    @Input('depositLocationList') set setDepositLocationList(data: ({value: number, text: string, view: string})[]) {
      this.depositLocationList =  this.populateFilterList(data, true, 'depositLocationList');
    }
    public activeStatusList = ACTIVE_NEED_STATUSES;
    public archivedStatusList = ARCHIVED_NEED_STATUSES;
    public activeOfferStatusList = ECOSYSTEM_OFFER_ACTIVE_STATUS;
    public archivedOfferStatusList = ECOSYSTEM_OFFER_ARCHIVED_STATUS;

    constructor() {
        this.searchControl
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(value => {
                this.searchKey = value;
                this.updateFilters();
            });
    }

    ngOnInit() {
        this.statusList = this.activeStatusList.map(status => Object.assign({selected: true}, status));
        this.offerStatusList = this.activeOfferStatusList.map(status => Object.assign({selected: true}, status));
        this.loadFilterState();
    }

    ngOnChanges(changes: SimpleChanges) {
      let filterState = this.getCorrectFilterState();
      if (changes['setRecipientList'] && !changes['setRecipientList'].firstChange) {
        this.loadSavedList('recipientList', filterState);
        this.recipientListLoaded = true;
      }
      if (changes['setDeliveryLocationList'] && !changes['setDeliveryLocationList'].firstChange) {
        this.loadSavedList('deliveryLocationList', filterState);
        this.deliveryLocationListLoaded = true;
      }
      if (changes['setDepositLocationList'] && !changes['setDepositLocationList'].firstChange) {
        this.loadSavedList('depositLocationList', filterState);
        this.depositLocationListLoaded = true;
      }
      if (changes['activeIndex'] && changes['activeIndex'].currentValue !== changes['activeIndex'].previousValue) {
        this.statusList = this.getCorrectStatusList().map(status => Object.assign({selected: false}, status));
        this.offerStatusList = this.getCorrectOfferStatusList().map(status => Object.assign({selected: true}, status));
        this.loadFilterState();
        this.updateFilters();
      }
    }

    /**
     * Get the default values for the filter
     * @returns {Object}
     */
    getDefaultFilter() {
      return {
        searchKey: "",
        orderBy: "-created_at",
        createdDateFrom: "",
        createdDateTo: "",
        deliveryDateFrom: "",
        deliveryDateTo: "",
        isTargetedOnly: false,
      }
    }

    populateFilterList(optionList, defaultSelection, tableName) {
      return [
        this.createOptionSelectAll(tableName),
        ...optionList.map(option => Object.assign({selected: defaultSelection}, option)),
      ];
    }

    private createOptionSelectAll(tableName) {
      const self = this;
      return  {
          linkToSelectedFunc: this.isAllOptionsSelected.bind(this, tableName),
          value: 99,
          _selected: false,
          get selected() {
              return this._selected;
          },
          set selected(value) {
              this._selected = value;
              self[tableName].forEach(option => {
                  if (option.value !== 99) {
                      option.selected = value;
                  }
              });
          },
          text: 'SELECT_ALL',
          view: 'Select all'
      };
  }
  

    isAllOptionsSelected(tableName) {
      return this[tableName].every(option => option.selected || option.value === 99);
    }

    /**
     * Get the list of need status corresponding to the current tab
     * @returns {Array}
     */
    getCorrectStatusList() {
      return this.activeIndex === 0 ? this.activeStatusList : this.archivedStatusList;
    }

    /**
     * Get the list of need status corresponding to the current tab
     * @returns {Array}
     */
    getCorrectOfferStatusList() {
      return this.activeIndex === 0 ? this.activeOfferStatusList : this.archivedOfferStatusList;
    }

    clearFilters() {
        this.resetFilters();
        this.updateFilters();
    }

    resetFilters() {
        this.searchControl.reset();
        this.searchKey = '';
        this.createdDateFrom = '';
        this.createdDateTo = '';
        this.deliveryDateFrom = '';
        this.deliveryDateTo = '';
        this.resetStatus();
        this.resetClient();
        this.resetLocation();
        this.resetDepositLocation();
        this.resetOfferStatus();
        this.resetToggleTargetedOnly();
        this.resetToggleNeedWithOfferOnly();
    }

    /**
     * Reset status list so that everything is unchecked
     */
    resetStatus() {
      this.statusList.forEach(status => status.selected = true);
    }

    /**
     * Reset client list so that everything is unchecked
     */
    resetClient() {
      this.recipientList.forEach(recipient => recipient.selected = false);
    }

    resetToggleTargetedOnly() {
      this.isTargetedOnly = false;
    }

    /**
     * Reset toggle that display need with offers only
     */
    resetToggleNeedWithOfferOnly() {
      this.isNeedWithOfferOnly = false;
    }
    
    /**
     * Reset location list so that everything is unchecked
     */
    resetLocation() {
      this.deliveryLocationList.forEach(location => location.selected = false);
    }

    resetDepositLocation() {
      this.depositLocationList.forEach(location => location.selected = false);
    }

    /**
     * Reset offer status list so that everything is unchecked
     */
    resetOfferStatus() {
      this.offerStatusList.forEach(status => status.selected = true);
    }

    /**
     * Called when filters have changed
     * Emit an event which prevents the site that filters have changed
     */
    updateFilters() {
        this.saveFilterState();
        this.onFilterUpdate.emit();
    }

    /**
     * Called when a status has been checked or unchecked
     * Changed the selected attribute of the corresponding status
     * @param {String} event - The value of the status which has been clicked 
     */
    updateStatus(event) {
        let updatedStatus = this.statusList.find(status => status.value === event);
        updatedStatus.selected = !updatedStatus.selected;
        this.updateFilters();
    }

    /**
     * Called when a recipient has been checked or unchecked
     * Changed the selected attribute of the corresponding recipient
     * @param {String} event - The value of the recipient which has been clicked 
     */
    updateRecipient(event) {
      let updatedRecipient = this.recipientList.find(recipient => recipient.value === event);
      updatedRecipient.selected = !updatedRecipient.selected;
      this.updateFilters();
    }

    /**
     * Called when a location has been checked or unchecked
     * Changed the selected attribute of the corresponding location
     * @param {String} event - The value of the location which has been clicked 
     */
    updateLocation(event) {
      let updatedLocation = this.deliveryLocationList.find(location => location.value === event);
      updatedLocation.selected = !updatedLocation.selected;
      this.updateFilters();
    }

    /**
     * Toggle the targeted button
     * @param {Boolean} event - The state the targeted button has been toggled into 
     */
    toggleTargetedOnly(event) {
      this.isTargetedOnly = event;
      if (!this.isTargetedOnly) {
        this.resetDepositLocation();
      }
      this.updateFilters();
    }

    toggleNeedWithOffers(event) {
      this.isNeedWithOfferOnly = event;
      if (!this.isNeedWithOfferOnly) {
        this.resetOfferStatus();
      }
      this.updateFilters();
    }

    updateDepositLocation(event) {
      let updatedLocation = this.depositLocationList.find(location => location.value === event);
      updatedLocation.selected = !updatedLocation.selected;
      this.updateFilters();
    }

    /**
     * Get the name of the storage key for the current filter
     * @param {Number} filterIndex - The index of the filter
     * @returns 
     */
    getFilterStorageKey(filterIndex) {
      return filterIndex === 0 ? "activeNeedFilter" : "archivedNeedList";
    }

    /**
     * Get the filter state corresponding to the active tab
     * @param {Number} filterIndex - The index of the tab we want to retrieve the filter for
     * @returns {Object}
     */
    getCorrectFilterState(filterIndex?) {
      let storageKey = this.getFilterStorageKey(filterIndex === undefined ? this.activeIndex : filterIndex);
      return JSON.parse(localStorage.getItem(storageKey)) || {};
    }

    /**
     * Load the saved state of the filter
     */
    loadFilterState() {
      let savedFilter = this.getCorrectFilterState();
      let defaultFilter = this.getDefaultFilter();

      for (let attribute in defaultFilter) {
        this[attribute] = savedFilter[attribute] ? savedFilter[attribute] : defaultFilter[attribute];
      }
      this.searchControl.setValue(this.searchKey);

      this.loadSavedStatusList('statusList', savedFilter);
      this.loadSavedStatusList('offerStatusList', savedFilter);
      this.loadSavedList('recipientList', savedFilter);
      this.loadSavedList('deliveryLocationList', savedFilter);
      this.loadSavedList('depositLocationList', savedFilter);
    }

    /**
     * Load the data for lists
     * @param {String} listName - The attribute name of the list to load
     * @param {Object} savedState - The save object from which we want to load data
     */
    loadSavedList(listName, savedState) {
      this[listName].forEach(element => element.selected = (savedState[listName] || []).includes(element.value));
    }

    /**
     * Load the data for lists
     * @param {String} listName - The attribute name of the list to load
     * @param {Object} savedState - The save object from which we want to load data
     */
    loadSavedStatusList(listName, savedState) {
      this[listName].forEach(element => element.selected = !(savedState[listName] || []).includes(element.value));
    }

    /**
     * Save the current state of the filter to be loaded later
     * @param {Number} filterIndex - Number indicating the current index we are on (0 = Active, 1 = Archive)
     */
    saveFilterState(filterIndex?) {
      if (filterIndex === undefined) {
        filterIndex = this.activeIndex;
      }
      let filterStorageKey = this.getFilterStorageKey(filterIndex);
      let currentStoredFilter = this.getCorrectFilterState(filterIndex);

      let filterToSave = {
        searchKey: this.searchKey,
        orderBy: this.orderBy,
        createdDateFrom: this.createdDateFrom,
        createdDateTo: this.createdDateTo,
        deliveryDateFrom: this.deliveryDateFrom,
        deliveryDateTo: this.deliveryDateTo,
        isTargetedOnly: this.isTargetedOnly,
        isNeedWithOfferOnly: this.isNeedWithOfferOnly,
        statusList: this.statusList.reduce((savedList, status) => !status.selected ? savedList.concat(status.value) : savedList, []),
        offerStatusList: this.offerStatusList.reduce((savedList, status) => !status.selected ? savedList.concat(status.value) : savedList, []),
        recipientList: this.recipientListLoaded 
                          ? this.recipientList.reduce((savedList, status) => status.selected ? savedList.concat(status.value) : savedList, []) 
                          : (currentStoredFilter.recipientList || []),
        deliveryLocationList: this.deliveryLocationListLoaded
                          ? this.deliveryLocationList.reduce((savedList, status) => status.selected ? savedList.concat(status.value) : savedList, [])
                          : (currentStoredFilter.deliveryLocationList || []),
        depositLocationList: this.depositLocationListLoaded
                          ? this.depositLocationList.reduce((savedList, status) => status.selected ? savedList.concat(status.value) : savedList, [])
                          : (currentStoredFilter.depositLocationList || []),
      }

      let jsonFilter = JSON.stringify(filterToSave);
      localStorage.setItem(filterStorageKey, jsonFilter);
    }

    /**
     * Called when an offer status has been checked or unchecked
     * Changed the selected attribute of the corresponding offer status
     * @param {String} event - The value of the offer status which has been clicked 
     */
    updateOfferStatus(event) {
        let updatedOfferStatus = this.offerStatusList.find(status => status.value === event);
        updatedOfferStatus.selected = !updatedOfferStatus.selected;
        this.updateFilters();
    }

    /**
     * Check whether there has been changes in the filter or not
     * @returns {Boolean} True if there has been some change in the filter
     */
    hasChange() {
        return !!this.searchKey 
            || !!this.createdDateFrom 
            || !!this.createdDateTo 
            || !!this.deliveryDateFrom 
            || !!this.deliveryDateTo
            || this.statusList.some(status => !status.selected)
            || this.recipientList.some(recipient => recipient.selected)
            || this.deliveryLocationList.some(location => location.selected)
            || this.isTargetedOnly
            || this.offerStatusList.some(status => !status.selected);
    }
  
    /**
     * Gather information from the filter to create the query
     * @returns {Array}
     */
    buildOptions(): Array<any[]> {

      const options: any = [];

      options.push(['need_list', true]);
  
      if (this.activeIndex === 1) {
        options.push(['archived', true]);
        options.push(['ecosystemOfferArchived', true]);
      }

      if (this.searchKey) {
        options.push(['search', this.searchKey]);
      }

      if (this.orderBy) {
        options.push(['sort', this.orderBy]);
      }
  
      if (!!this.createdDateFrom) {
        options.push(['created_from', this.createdDateFrom]);
      }
  
      if (!!this.createdDateTo) {
        options.push(['created_to', this.createdDateTo]);
      }
  
      if (!!this.deliveryDateFrom) {
        options.push(['delivery_from', this.deliveryDateFrom]);
      }
  
      if (!!this.deliveryDateTo) {
        options.push(['delivery_to', this.deliveryDateTo]);
      }

      if (this.statusList.some(status => status.selected)) {
        this.statusList.forEach(status => {
          if (status.selected) {
            options.push(['status[]', status.value]);
          }
        });
      } else {
        this.getCorrectStatusList().forEach(status => {
          options.push(['status[]', status.value]);
        });
      }
      

      this.recipientList.forEach(recipient => {
        if (recipient.selected) {
          options.push(['client[]', recipient.value]);
        }
      });

      this.deliveryLocationList.forEach(location => {
        if (location.selected) {
          options.push(['location[]', location.value]);
        }
      });

      if (this.isTargetedOnly) {
        options.push(['from_any_deposit', true]);
      }

      if (this.isNeedWithOfferOnly) {
        options.push(['with_offer_only', true])
      }

      this.depositLocationList.forEach(location => {
        if (location.selected) {
          options.push(['from_deposit', true]);
          options.push(['deposit_location[]', location.value]);
        }
      });

      if (!this.offerStatusList.every(status => status.selected) && this.offerStatusList.some(status => status.selected)) {
        this.offerStatusList.forEach(status => {
          if (status.selected) {
            options.push(['need_offer_status[]', status.value]);
          }
        });
      } else {
        this.getCorrectOfferStatusList().forEach(status => {
          options.push(['need_offer_status[]', status.value]);
        });
      }
  
      return options;
    }
  }
  