<div class="import-checked">
  <div class="import-checked__caption">
    {{'File checked !' | translate}}
  </div>
  <div class="import-checked__wrapper">
    <div class="import-checked__check-circle"></div>
    <div class="import-checked__check-mark">
      <i class="icon icon--check-import"></i>
    </div>
  </div>

  <div class="import-checked__atention-message-wrapper">
    <div class="import-checked__attention">!</div>
    <div class="import-checked__attention--text">{{'IMPORT_CHECK_CONFIRMATION' | translate}}</div>
  </div>

  <div class="import-checked__footer">
    <button class="deposit-warning__button" md-button md-raised-button (click)="mdDialogRef.close()" >{{ 'OK !' | translate | uppercase}}</button>
  </div>
</div>
