import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICarbonFootprintForm } from '../../../../../../interfaces/carbon-footprint.interface';
import { REPORT_FORM_VALUES } from '../../../../../../values/deposit-types-for-reports.const';
  @Component({
    moduleId: module.id,
    selector: 'carbon-footprint-form',
    templateUrl: 'carbon-footprint-form.component.html',
    styleUrls: ['carbon-footprint-form.component.scss']
  })
  export class CarbonFootprintFormComponent implements OnInit {

    @Input() depositType: REPORT_FORM_VALUES;
    @Input() companyType: number;

    @Input() carbonFootprintForm: ICarbonFootprintForm;
    @Output() carbonFootprintFormChange = new EventEmitter<ICarbonFootprintForm>();
    reportFormValues = REPORT_FORM_VALUES;

    constructor (private _translate: TranslateService) {
    }
      ngOnInit() {
      }
      updateCarbonFootprintForm(){
        this.carbonFootprintFormChange.emit(this.carbonFootprintForm);
      }
      getCustomTooltipMessage(message: string){
        return this._translate.instant(message);
      }
      getEmbodiedCarbonOptionText(){
        if(this.isDepositTypeOfBuilding()){
          return "Embodied (A1-A5 + B1-B5 + C)";
        } else {
          return "Embodied carbon (A1-A3 + B1-B5 + C)";
        }
      }
      isDepositTypeOfBuilding(){
        return this.depositType === this.reportFormValues.DEPOSIT_TYPE_BUILDING
      }
  }
