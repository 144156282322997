import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformTagJunctionService } from '../services/transform-tag-junction.service';
var TransformTagJunction = /** @class */ (function (_super) {
    tslib_1.__extends(TransformTagJunction, _super);
    function TransformTagJunction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformTagJunctionService;
        return _this;
    }
    TransformTagJunction.prototype.fields = function () {
        return [
            'id',
            'tag_id',
            'transformation_id',
            'is_input',
            'name',
        ];
    };
    return TransformTagJunction;
}(ActiveRecord));
export { TransformTagJunction };
