import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Type } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ServiceLocator} from '../../../shared/services/service-locator';
import {ManufacturersService} from '../services/manufacturers.service';

export class Manufacturers extends ActiveRecord {
  protected provider: Type<ManufacturersService> = ManufacturersService;

  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);
  id: number;
  name: string;
  passport_id: number;

  fields() {
    return ['name', 'passport_id', 'id'];
  }
}
