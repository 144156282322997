import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { NextUsage } from '../models/next-usage.class';
var NextUsageService = /** @class */ (function (_super) {
    tslib_1.__extends(NextUsageService, _super);
    function NextUsageService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'next-usages';
        _this.namespaceSingular = 'next-usage';
        _this.ModelClass = NextUsage;
        _this.AFFILIATE_BRANCH_EVENT = {
            SAVE: 'save'
        };
        return _this;
    }
    return NextUsageService;
}(CrudService));
export { NextUsageService };
