import * as tslib_1 from "tslib";
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { ReportFileService } from '../services/report-file.service';
var ReportFile = /** @class */ (function (_super) {
    tslib_1.__extends(ReportFile, _super);
    function ReportFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = ReportFileService;
        return _this;
    }
    ReportFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'report_file_id',
        ]);
    };
    return ReportFile;
}(UploadedDocument));
export { ReportFile };
