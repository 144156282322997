import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { PassportTag } from '../models/passport-tag.class';
var PassportTagService = /** @class */ (function (_super) {
    tslib_1.__extends(PassportTagService, _super);
    function PassportTagService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'generic-passport-tags';
        _this.namespaceSingular = 'generic-passport-tag';
        _this.ModelClass = PassportTag;
        return _this;
    }
    return PassportTagService;
}(CrudService));
export { PassportTagService };
