<section *ngIf="calculationVariables" class="variables-block">
  <h2 class="variables-block__header">
    {{ 'List of calculated variables' | translate }}
  </h2>
  <h3 class="variables-block__title-block">{{ 'Demountability grade variables' | translate }}</h3>
  <div class="variables-block__section">
    <md-input-container *ngFor="let item of calculationVariables.demountability_grade_variables" class="additionaly__number-input variables-block__item">
      <label>{{demountabilityGradeNames[item.id] | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.value"
        (input)="changeInputValue()"
      />
    </md-input-container>
  </div>
  <h3 class="variables-block__title-block">{{ 'Future life grade variables' | translate }}</h3>
  <div class="variables-block__section">
    <md-input-container *ngFor="let item of calculationVariables.future_life_grade_variables" class="additionaly__number-input variables-block__item">
      <label>{{futureLifeGradeNames[item.id] | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.value"
        (input)="changeInputValue()"
      />
    </md-input-container>
  </div>
  <h3 class="variables-block__title-block">{{ 'Next usages potential grade variables' | translate }}</h3>
  <div class="variables-block__section">
    <md-input-container *ngFor="let item of calculationVariables.potential_grade_variables" class="additionaly__number-input variables-block__item">
      <label>{{potentialGradeNames[item.id] | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.value"
        (input)="changeInputValue()"
      />
    </md-input-container>
  </div>
  <h3 class="variables-block__title-block">{{ 'Information characterization & rating grade variables' | translate }}</h3>
  <div class="variables-block__section">
    <md-input-container *ngFor="let item of calculationVariables.rating_grade_variables" class="additionaly__number-input variables-block__item">
      <label>{{ratingGradeNames[item.id] | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.value"
        (input)="changeInputValue()"
      />
    </md-input-container>
  </div>
  <h3 class="variables-block__title-block">{{ 'Renewal rates variables' | translate }}</h3>
  <div *ngFor="let item of calculationVariables.renewal_rates_variables" class="variables-block__section">
    <h4 class="variables-block__title-block--additional-title">{{renewalRatesNames[item.id] | translate}}: </h4>
    <md-input-container class="additionaly__number-input variables-block__item">
      <label>{{renewalRatesNamesItems.deconstruction | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.deconstruction"
        (input)="changeInputValue()"
      />
    </md-input-container>
    <md-input-container class="additionaly__number-input variables-block__item">
      <label>{{renewalRatesNamesItems.rehabilitation | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.rehabilitation"
        (input)="changeInputValue()"
      />
    </md-input-container>
    <md-input-container class="additionaly__number-input variables-block__item">
      <label>{{renewalRatesNamesItems.over_30_years_commercial | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.over_30_years_commercial"
        (input)="changeInputValue()"
      />
    </md-input-container>
    <md-input-container class="additionaly__number-input variables-block__item">
      <label>{{renewalRatesNamesItems.over_30_years_residential | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.over_30_years_residential"
        (input)="changeInputValue()"
      />
    </md-input-container>
    <md-input-container class="additionaly__number-input variables-block__item">
      <label>{{renewalRatesNamesItems.over_30_years_institutional | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.over_30_years_institutional"
        (input)="changeInputValue()"
      />
    </md-input-container>
  </div>
  <h3 class="variables-block__title-block">{{ 'Residual value variables' | translate }}</h3>
  <div class="variables-block__section">
    <md-input-container *ngFor="let item of calculationVariables.residual_value_variables" class="additionaly__number-input variables-block__item">
      <label>{{residualValueNames[item.id] | translate}}: </label>
      <input
        class="variables-block__item--input"
        mdInput
        autocomplete="off" 
        type="number"
        maxlength="6"
        [(ngModel)]="item.value"
        (input)="changeInputValue()"
      />
    </md-input-container>
  </div>
</section>
