<md-select 
  class="select-group"
  placeholder="{{ 'Language' | translate }}"
  [floatPlaceholder]="showPlaceHolder"
  [(ngModel)]="language"
  (change)="setLanguage($event.value)"
>
  <md-option
    *ngFor="let lang of availableLanguages"
    [value]="lang"
  >
    {{getLanguageByKey(lang)}}
  </md-option>
</md-select>
