import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { ProductSector } from '../models/product-sector.class';

@Injectable()
export class ProductSectorService extends CrudService {
  protected namespace = 'product-sectors';
  protected namespaceSingular = 'product-sector';
  protected ModelClass = ProductSector;
}
