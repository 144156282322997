import { Country } from './../../../shared/models/country.class';
import { count } from 'rxjs/operators';
import { DepositLocationService } from '../services/deposit-location.service';
import { IMapMarker } from '../../../shared/map-view/map-marker.interface';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { DepositTag } from './deposit-tag.class';
import { DepositLocationFile } from './deposit-location-file.class';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

export class DepositLocation extends ExtendedActiveRecord implements IMapMarker {
  protected provider: any = DepositLocationService;

  id: number;
  expired: boolean;
  user_id: number;
  description: string;
  name: string;
  lat: number;
  lng: number;
  address: string;
  country: string;
  city: string;
  postalCode: string;
  verification: number;
  creator: string;
  use_count: number;
  created_at: string;
  bim_tool_id: number;
  bim_id: number;
  floor_area: number;
  tags: DepositTag[];
  end_of_work: string;
  expire_date: string;
  site_status: number;
  checked: number;
  active: boolean;
  deposits: any;
  assigned: number;
  files: DepositLocationFile[];
  expired_date: string;
  living_area: number;
  count: number;

  beforeSave(data: any): any {
    const dataValues = super.beforeSave(data);
    dataValues.end_of_work = new DatePipe('en-US').transform(dataValues.end_of_work, 'yyyy-MM-dd');
    return dataValues;
  }

  set(data) {
    super.set(data);
    if (!!this.tags) {
      this.tags = this.tags.map(tag => new DepositTag({ ...tag }));
    }
    if (!!this.files) {
      this.files = this.files.map(file => new DepositLocationFile(file));
    }
    return this;
  }

  fields() {
    return ['id', 'expired', 'expire_date', 'user_id', 'description', 'name', 'lat', 'lng', 'country', 'city', 'postalCode'
      , 'address', 'verification', 'creator', 'use_count', 'created_at'
      , 'bim_tool_id', 'bim_id', 'floor_area', 'tags', 'files', 'assigned', 'end_of_work', 'site_status', 'checked', 'active'
      , 'deposits', 'is_expired_by_superadmin', 'expired_date', 'living_area', 'count'];
  }

  get lock() {
    return this.verification === 1;
  }

  get nameAddress(): string {
    return this.address ? this.name + ', ' + this.address : this.name;
  }

  get isExpire() {
    return moment().isAfter(moment(this.end_of_work, 'YYYY-MM-DD'), 'days');
  }

  get is_expired_by_superadmin() {
    return !!this.expired_date;
  }

  set is_expired_by_superadmin(state: boolean) {
    this.expired_date = state ? moment().format('YYYY-MM-DD') : null;
  }

  assign() {
    this.build();
    this.beforeSave$.emit(this);
    return (this.service as DepositLocationService).assign(
      this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
        this.afterSave$.emit(this);
        return res;
      });
  }
}
