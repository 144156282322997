import {IColumnOptions, IControlMeta} from '../../../../../import-spreadsheet/values/interfaces/column-meta.interface';
import {COLUMNS_MAPPING} from './deposit-location-columns.const';
import {Validators} from '@angular/forms';
import {TDocumentControls} from '../../../../../import-spreadsheet/values/import-document-controls.const';

export type TColumnsKeys = 'site_name_field' |
  'description_field' |
  'address_field';


export const DEPOSIT_LOCATION_IMPORT_COLUMNS: { [x in TColumnsKeys]?: IColumnOptions } = {
  site_name_field: {
    name: 'Site name',
    description: 'SITE_MUST_BE_UNIQUE_WARNING',
    order: COLUMNS_MAPPING.SITE_NAME
  },
  address_field: {
    name: 'Address',
    description: 'ADDRESS_MUST_BE_GOOGLE_WARNING',
    order: COLUMNS_MAPPING.ADDRESS
  },
  description_field: {
    name: 'Description',
    order: COLUMNS_MAPPING.DESCRIPTION,
    showWarningSign: false
  }
};

export const DEPOSIT_LOCATION_IMPORT_CONTROL_OPTIONS: {[x in TColumnsKeys]: any} = {
  site_name_field: ['', Validators.required],
  address_field: ['', Validators.required],
  description_field: ['']
};

type TDepositImportControlMeta = { [x in (TColumnsKeys | TDocumentControls)]: IControlMeta  };
export let DEPOSIT_LOCATION_IMPORT_CONTROL_META: TDepositImportControlMeta = {
  site_name_field: {error: '', type: COLUMNS_MAPPING.SITE_NAME},
  address_field: {error: '', type: COLUMNS_MAPPING.ADDRESS},
  description_field: {error: '', type: COLUMNS_MAPPING.DESCRIPTION},
  tab_name: {error: '', type: COLUMNS_MAPPING.TAB_IMPORT},
  first_row: {error: '', type: COLUMNS_MAPPING.FIRST_ROW},
  last_row: {error: '', type: COLUMNS_MAPPING.LAST_ROW},
};

