import * as tslib_1 from "tslib";
import { ElementRef, Renderer2 } from '@angular/core';
import { MdTooltip } from '@angular/material';
import { AbstractTooltipPrefix } from './abstract-tooltip-prefix';
/**
 * This directive is work around to support customization for tooltips for @angular/material library version 2.0.0-beta.3.
 * Please remove or rewrite this directive if another library is used for tooltips or if @angular/material library is upgraded.
 *
 */
var CustomTooltipDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CustomTooltipDirective, _super);
    function CustomTooltipDirective(parentElem, renderer, materialTooltip) {
        var _this = _super.call(this) || this;
        _this.parentElem = parentElem;
        _this.renderer = renderer;
        _this.materialTooltip = materialTooltip;
        _this.validateOnlyScrolled = false;
        _this.materialTooltip.hideDelay = 0;
        return _this;
    }
    CustomTooltipDirective.prototype.addStyleToTooltip = function () {
        var _this = this;
        setTimeout(function () { return _this.addStyle(); });
    };
    CustomTooltipDirective.prototype.addStyle = function () {
        var _this = this;
        var nodeList = this.getElements();
        if (this.validate() && !!nodeList.length) {
            this.tooltipClass.forEach(function (className) {
                return _this.renderer.addClass(nodeList[nodeList.length - 1], className);
            });
            this.onInitTooltipPositionBugWorkaround();
        }
        else {
            this.materialTooltip.hide(0);
        }
    };
    CustomTooltipDirective.prototype.getElements = function () {
        var elem = this.getCdkContainer(this.materialTooltip);
        return elem ? elem.querySelectorAll('.mat-tooltip') : [];
    };
    CustomTooltipDirective.prototype.validate = function () {
        var parent = this.parentElem.nativeElement;
        if (this.validateOnlyScrolled) {
            return parent.scrollWidth > parent['offsetWidth'];
        }
        return true;
    };
    CustomTooltipDirective.prototype.onInitTooltipPositionBugWorkaround = function () {
        var _this = this;
        setTimeout(function () {
            _this.updatePosition(_this.materialTooltip);
        });
    };
    return CustomTooltipDirective;
}(AbstractTooltipPrefix));
export { CustomTooltipDirective };
