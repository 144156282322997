import * as tslib_1 from "tslib";
import { EcosystemService } from '../../../entities/ecosystem/services/ecosystem.service';
import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
var EcosystemResolver = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemResolver, _super);
    function EcosystemResolver(_service) {
        var _this = _super.call(this) || this;
        _this._service = _service;
        _this.query = { expand: 'need,steps.deposit,steps.transformation,file_count,creator_name,updater_name,ecosystem_offer,orders,waste_total,economic_total,emissions_total' };
        return _this;
    }
    return EcosystemResolver;
}(EntityResolver));
export { EcosystemResolver };
