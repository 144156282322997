import { Component, OnInit } from '@angular/core';
import { Searchable } from '../../../../../shared/models/searchable.class';
import { FormControl } from '@angular/forms';
import {
  ECustomListKeyPairClass,
  ICustomListKeyPair,
  ICustomListRecord
} from '../../../../../shared/custom-list/interfaces/custom-list.interface';
import { MdDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { TransformService } from '../../../../../entities/transformation/services/transform.service';
import { TsmListService } from '../../../../../entities/transformation/services/tsm-list.service';
import {getTransformTypeLcView, TRANSFORM_TYPES} from '../../../../transformation-manager/values/transform-types.const';
import { Transform } from '../../../../../entities/transformation/models/transform.class';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import { TransformDetailsDialogComponent } from '../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { IMapMarker } from '../../../../../shared/map-view/map-marker.interface';
import { TransformLocation } from '../../../../../entities/transformation/models/transform-location.class';
import { TRANSFORM_ORDER_STATUS } from '../../../../transformation-manager/values/transform-order-statuses.const';
import { TransformStatus } from '../../../../../entities/transformation/models/transform-status.class';
import { TRANSFORM_STATUSES } from '../../../../transformation-manager/values/transform-statuses.const';
import {DIALOG_THEME} from '../../../../../shared/helpers/dialog-themes.const';
import * as moment from 'moment';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'supervisor-data-transformations-catalog',
  templateUrl: 'supervisor-data-transformations-catalog.component.html'
})
export class SupervisorDataTransformationsCatalogComponent implements OnInit {
  TRANSFORM_TYPES = TRANSFORM_TYPES;
  tsms: Searchable[];
  tsmId: number;
  search = new FormControl();
  type: number;
  markers: IMapMarker[] = [];
  mapIsReady: boolean;
  records: ICustomListRecord[] = [];

  filterStatuses: TransformStatus[] = [
    TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION,
    TRANSFORM_STATUSES.PUBLISHED
  ];

  sortOptions: any = [
    { field: 'name', view: 'Name'},
    { field: '-created_at', view: 'Creation date'},
    { field: 'tsm_name', view: 'Transformation Manager'},  //TODO doesn't seem to work
    { field: 'transformation_type', view: 'Type'}
  ];
  sortValue: string = 'name';

  searchStatus = this.filterStatuses.map((status) => status.value);

  constructor(private _transformService: TransformService,
              private dialog: MdDialog,
              private datePipe: DatePipe,
              private tsmListService: TsmListService,
              private mdDialog: MdDialog,
              private navBarState: NavbarStateService,) {
    this
      .tsmListService
      .get()
      .subscribe((tsms: Searchable[]) =>
        this.tsms = tsms
      )
    ;
  }

  ngOnInit(): void {
    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => this.getRecords())
    ;
    this.getRecords();
  }

  downloadPdfReport():string {
    return this._transformService.getPdfReportUrl({
      supervisor: true,
      search: this.search.value || null,
      transformation_type: this.type || null,
      tsm: this.tsmId || null,
      status: this.searchStatus,
      timezone_offset: moment().utcOffset()
    });
  }

  getRecords() {
    if (this.searchStatus.length <= 0) {
      this.resetResults();
      return;
    }
    this.records.length = 0;

    let params: any = {
      expand: 'files,materials,tsm_name,sites,tags',
      supervisor: true,
      'status[]': this.searchStatus,
      search: this.search.value || null,
      transformation_type: this.type || null,
      tsm: this.tsmId || null,
      sort: this.sortValue,
    }

    if (this.navBarState.multilanguage) {
      params.english = true;
    }

    this._transformService
      .get(params)
      .subscribe((data: Transform[]) => {
        this.mapIsReady = false;
        this.markers = [];
        data.forEach((item: Transform) => this.addRecord(item));
        data.forEach((item: Transform) => this.addMarkers(item));
        this.mapIsReady = true;
      })
    ;
  }

  addMarkers(data: Transform): void {
    this.markers.push(...data.sites
      .map((item: TransformLocation) => this.toMarker(data.name, item, data.english_name))
    );
  }

  toMarker(transformName: string, data: TransformLocation, english_name?: string): IMapMarker {
    return {
      lat: data.lat,
      lng: data.lng,
      name: transformName,
      english_name: english_name,
      lines: [
        `${data.name}`,
        `${data.address}`
      ]
    };
  }


  addRecord(data: Transform) {

    let incomeMaterials = data.materials.filter((material) => material.is_input === 1);
    let incomeMaterialsString = (incomeMaterials.map((material) => material.name)).join(', ');
    let outcomeMaterials = data.materials.filter((material) => material.is_input !== 1);
    let outcomeMaterialsString = (outcomeMaterials.map((material) => material.name)).join(', ');

    let incomeTags = data.tags.filter(tag => tag.is_input === 1).map(tag => tag.name).join(', ');
    let outcomeTags = data.tags.filter(tag => tag.is_input !== 1).map(tag => tag.name).join(', ');

    this.records.push(
      this.createTransformationRecord(
        data.transformation_type_data.value ? getTransformTypeLcView(data.transformation_type_data.value) : '',
        data.english_name,
        data.viewName,
        data.transformation_type_data.view.replace(/./, (c: string) => c.toUpperCase()),
        incomeMaterialsString,
        outcomeMaterialsString,
        incomeTags,
        outcomeTags,
        (data.materials.map((material) => material.name)).join(', '),
        data.transformation_type_data.includes.storingSites ? data.sites.length : data.sectors.length,
        this.datePipe.transform(data.created_at, 'dd.MM.y'),
        data.tsm_name,
        data
      )
    );
  }

  toggleStatus(statusId: number) {
    let index: number = this.searchStatus.indexOf(statusId);
    if (index > -1) {
      this.searchStatus.splice(index, 1);
    } else {
      this.searchStatus.push(statusId);
    }
  }

  createTransformationRecord(icon: string,
                             english_name: string,
                             title: string,
                             subtitle: string,
                             inputMaterials: string,
                             outputMaterials: string,
                             inputTags: string,
                             outputTags: string,
                             text: string,
                             totalSitesCount: number,
                             addedDate: string,
                             author: string,
                             transform: Transform,): CustomListRecord {

    let materials: ICustomListKeyPair[] = [];
    let keywordsOrControlType: ICustomListKeyPair[] = [];
    const reversedColumnEntries: Partial<ICustomListKeyPair> = {cssClass: ECustomListKeyPairClass.REVERSED_LIST_COLUMN, seperator: ' '};
    if(transform.transformation_type_data.includes.incomeOutcome) {
      materials = [{
        key: 'Input', value: inputMaterials
      }, {
        key: 'Output', value: outputMaterials
      }];

      keywordsOrControlType = [{
        key: 'Keywords', value: inputTags
      }, {
        key: 'Keywords', value: outputTags
      }];
    } else if(transform.transformation_type_data.includes.acceptedMaterials) {
      materials = [{
        key: 'Accepted materials', value: transform.acceptedMaterialsView, ...reversedColumnEntries
      }];

      if(transform.transformation_type_data.includes.controlType) {
        keywordsOrControlType = [
          { key: 'Control type', value: transform.controlTypeView, ...reversedColumnEntries },
        ];
      }
    }

    return CustomListRecord
      .build()
      .setPrimaryKey(transform.id)
      .addIconColumn(icon)
      .addTitleColumn(title, subtitle, english_name)
      .addListColumn(materials)
      .addListColumn(keywordsOrControlType)
      .addCounterColumn(totalSitesCount,
        transform.transformation_type_data.includes.storingSites
          ? 'Sites attached'
          : 'Sectors attached')
      .addDateColumn(addedDate, 'Added')
      .addAuthorColumn(author)
      .addControlsColumn([{
        fn: (data: ICustomListRecord) => {
          this.mdDialog.open(TransformDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR,
            data: {
              id: transform.id,
              readOnly: true,
            }
          });
        },
        name: 'See details'
      }, {
        fn: (data: ICustomListRecord) => {
          location.href = 'mailto:';
        },
        name: 'Send Email'
      }]);
  }

  private resetResults() {
    this.markers = [];
    this.records = [];
  }
}
