import {Component, ViewEncapsulation} from '@angular/core';
import {IConfirmDialogIconData} from '../../confirm-dialog/values/interface/confirm-dialog-data.interface';
import {MdDialogRef} from '@angular/material';
import {INotificationWarningData} from '../interfaces/notification-warning-data';

@Component({
  selector: 'app-notification-warning',
  templateUrl: './notification-warning-dialog.component.html',
  styleUrls: ['./notification-warning-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationWarningDialogComponent implements INotificationWarningData {
  iconData: IConfirmDialogIconData;
  title: string;
  cssClass: string;
  body: string;
  bodyHint: string;
  showCross: boolean;
  warnings: {titleText?: string, text: string}[];

  constructor(public dialog: MdDialogRef<any>) {
    const data = this.dialog._containerInstance.dialogConfig.data;
    this.iconData = data.iconData || null;
    this.title = data.title || '';
    this.body =  data.body || '';
    this.bodyHint = data.bodyHint;
    this.warnings =  data.warnings || [];
    this.cssClass = data.cssClass || '';
    this.showCross = data.showCross || true;
  }

}
