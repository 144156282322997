<div *ngIf="reportData">
  <report-pdf [pageNumber]=1 [reportHeader]="reportHeader" *ngIf="showSitesInOnePage">
    <report-pdf-site-list [sites]="reportData.sites"></report-pdf-site-list>
    <div class="first-block__body">
      <div class="first-block__body--description">
        <p class="first-block__body--description-text"
          [innerHTML]="'DESCRIPTION_TEXT_FOR_HEAD_IN_CIRCULARITY' | translate"></p>
      </div>
  </div>
  </report-pdf>
<report-pdf
  [pageNumber] = "addTitlePagesNumber(-1)"
  [reportHeader]="reportHeader"
>
<report-pdf-site-list *ngIf="!showSitesInOnePage"
  [sites]="reportData.sites"
  [isLongVersion]="true"
  [measureRows]="false"
  [isGenerateFromFile]="reportData.created_by_file"
></report-pdf-site-list>
  <div *ngIf="!isEmptyProductCategory()" class="first-block">
    <div class="first-block__body">
      <div class="first-block__body--description">
        <p class="first-block__body--description-text" [innerHTML]="'DESCRIPTION_TEXT_FOR_HEAD_IN_CIRCULARITY' | translate"></p>
      </div>
      <div class="first-block__body--main-charts">
        <div class="first-block__body--main-charts-left-block">
          <pie-chart
            [mainColor]="'#34495E'"
            [mainValue]="result.aggregate.circularity_grade | number: '1.0-0'"
            [pieChartSize]="pieChartSize.medium"
          >
          </pie-chart>
          <div class="first-block__body--main-charts-left-block-text">
            <p>{{result.aggregate.deposits_considered}} / {{result.aggregate.deposit_count}} {{ 'deposits considered' | translate }}</p>
            <p>{{result.aggregate.total_weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}} {{ 'tons of deposits in total' | translate }}</p>
          </div>
        </div>
        <div class="first-block__body--main-charts-right-block">
          <div class="first-block__body--main-charts-right-block-contents">
            <h3 class="first-block__body--main-charts-right-block-contents-title">{{ 'Previous lives' | translate }}</h3>
            <div class="first-block__body--main-charts-right-block-contents-hr"></div>
            <div class="first-block__body--main-charts-right-block-first-content">
              <p class="first-block__body--main-charts-right-block-first-content-value">
                {{result.aggregate.recycled_percentage | number: '1.0-0' | decimalSeparator:','}}%
              </p>
              <div class="first-block__body--main-charts-right-block-first-content-text">
                <p>{{ 'of recycled or rapidly' | translate }}</p>
                <p>{{ 'renewable materials' | translate }}</p>
                <p>{{ 'incorporated into products' | translate }}</p>
              </div>

              <p class="first-block__body--main-charts-right-block-first-content-value">
                {{result.aggregate.reused_percentage | number: '1.0-0' | decimalSeparator:','}}%
              </p>
              <div class="first-block__body--main-charts-right-block-first-content-text">
                <p>{{ 'of deposits' | translate }}</p>
                <p>{{ 'resulting' | translate }}</p>
                <p>{{ 'from reuse' | translate }}</p>
              </div>

            </div>
            <h3 class="first-block__body--main-charts-right-block-contents-title">{{ 'Next usages' | translate }}</h3>
            <div class="first-block__body--main-charts-right-block-contents-hr"></div>
            <div class="first-block__body--main-charts-right-block-second-contents">
              <div class="first-block__body--main-charts-right-block-second-content">
                <p [ngStyle]="result.aggregate.demountability_grade == null && {'color': 'gray'}">{{ 'Disassemblability of products and deposits' | translate }}</p>
                <circularity-rating-scale
                  [gradeValue]="result.aggregate.demountability_grade"
                  [ratingPointsAmount]="6"
                  [mainColor]="'#34495E'"
                  *ngIf="result.aggregate.demountability_grade != null"
                >
                </circularity-rating-scale>
                <div class="first-block__body--missing-data" *ngIf="result.aggregate.demountability_grade == null">
                  <i class="icon icon--exclamation-point-red"></i>&nbsp;<span>{{ 'Missing data' | translate }}</span>
                </div>
              </div>
              <div class="first-block__body--main-charts-right-block-second-content">
                <p>{{ 'Identifcation and potential of future uses' | translate }}</p>
                <circularity-rating-scale
                  [gradeValue]="result.aggregate.next_usage_potential_grade"
                  [ratingPointsAmount]="6"
                  [mainColor]="'#34495E'"
                >
                </circularity-rating-scale>
              </div>
              <div class="first-block__body--main-charts-right-block-second-content">
                <p>{{ 'Presence and reliability of information' | translate }}</p>
                <circularity-rating-scale
                  [gradeValue]="result.aggregate.information_characterization_and_rating_grade"
                  [ratingPointsAmount]="6"
                  [mainColor]="'#34495E'"
                >
                </circularity-rating-scale>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="first-block__body--minor-charts">
        <div class="first-block__body--minor-charts-row">
          <div class="first-block__body--minor-charts-row-title">

          </div>
          <div class="first-block__body--minor-charts-row-title first-block__body--minor-charts-color-purple">
            <p [innerHTML]="'CIRCULARITY_REPORT.CHART_CAPTION.PRESENT' | translate"></p>
          </div>
          <div class="first-block__body--minor-charts-row-title first-block__body--minor-charts-color-green">
            <p [innerHTML]="'CIRCULARITY_REPORT.CHART_CAPTION.MOST' | translate"></p>
          </div>
          <div class="first-block__body--minor-charts-row-title first-block__body--minor-charts-color-red">
            <p [innerHTML]="'CIRCULARITY_REPORT.CHART_CAPTION.LEAST' | translate"></p>
          </div>
        </div>
        <div class="first-block__body--minor-charts-row">
          <div class="first-block__body--minor-charts-column-title">
            <p>{{ 'Circularity grade' | translate }}</p>
          </div>
          <div class="first-block__body--minor-charts-column">
            <div class="first-block__body--minor-charts-column-pie-chart first-block__body--minor-charts-color-purple">
              <pie-chart
                [mainColor]="'#622893'"
                [mainValue]="mainProductCategory.mostPresent.mainValue"
                [pieChartSize]="pieChartSize.small"
              >
              </pie-chart>
            </div>
          </div>
          <div class="first-block__body--minor-charts-column">
            <div class="first-block__body--minor-charts-column-pie-chart first-block__body--minor-charts-color-green">
              <pie-chart
                [mainColor]="'#6BC84A'"
                [mainValue]="mainProductCategory.mostCircular.mainValue"
                [pieChartSize]="pieChartSize.small"
              >
              </pie-chart>
            </div>
          </div>
          <div class="first-block__body--minor-charts-column">
            <div class="first-block__body--minor-charts-column-pie-chart first-block__body--minor-charts-color-red">
              <pie-chart
                [mainColor]="'#EF2269'"
                [mainValue]="mainProductCategory.leastCircular.mainValue"
                [pieChartSize]="pieChartSize.small"
              >
              </pie-chart>
            </div>
          </div>
        </div>
        <div class="first-block__body--minor-charts-row">
          <div class="first-block__body--minor-charts-column-title">
            <p>(1) {{ '% of recycled or rapidly renewable materials' | translate }}</p>
            <p>(2) {{ '% of deposits resulting from reuse' | translate }}</p>
          </div>
          <div class="first-block__body--minor-charts-column first-block__body--flex-start">
            <p>(1)</p>
            <p class="first-block__body--main-charts-column-ball-value first-block__body--main-charts-column-bgc-purple">
              {{mainProductCategory.mostPresent.percentage_of_recycled_material | number: '1.0-0' | decimalSeparator:','}}%
            </p>
            <p>(2)</p>
            <p class="first-block__body--main-charts-column-ball-value first-block__body--main-charts-column-bgc-purple">
              {{mainProductCategory.mostPresent.reused_percentage_by_weight | number: '1.0-0' | decimalSeparator:','}}%
            </p>
          </div>
          <div class="first-block__body--minor-charts-column first-block__body--flex-start">
            <p>(1)</p>
            <p class="first-block__body--main-charts-column-ball-value first-block__body--main-charts-column-bgc-green">
              {{mainProductCategory.mostCircular.percentage_of_recycled_material | number: '1.0-0' | decimalSeparator:','}}%
            </p>
            <p>(2)</p>
            <p class="first-block__body--main-charts-column-ball-value first-block__body--main-charts-column-bgc-green">
              {{mainProductCategory.mostCircular.reused_percentage_by_weight | number: '1.0-0' | decimalSeparator:','}}%
            </p>
          </div>
          <div class="first-block__body--minor-charts-column first-block__body--flex-start">
            <p>(1)</p>
            <p class="first-block__body--main-charts-column-ball-value first-block__body--main-charts-column-bgc-red">
              {{mainProductCategory.leastCircular.percentage_of_recycled_material | number: '1.0-0' | decimalSeparator:','}}%
            </p>
            <p>(2)</p>
            <p class="first-block__body--main-charts-column-ball-value first-block__body--main-charts-column-bgc-red">
              {{mainProductCategory.leastCircular.reused_percentage_by_weight | number: '1.0-0' | decimalSeparator:','}}%
            </p>
          </div>
        </div>
        <div class="first-block__body--minor-charts-row">
          <div class="first-block__body--minor-charts-column-title">
            <p>{{ 'Disassemblability of products and deposits' | translate }}</p>
          </div>
          <div class="first-block__body--minor-charts-column">
            <circularity-rating-scale
              [gradeValue]="mainProductCategory.mostPresent.demountability_grade"
              [ratingPointsAmount]="6"
              [mainColor]="'#622893'"
              *ngIf="mainProductCategory.mostPresent.demountability_grade != null"
            >
            </circularity-rating-scale>
            <div class="first-block__body--missing-data" *ngIf="result.aggregate.demountability_grade == null">
              <i class="icon icon--exclamation-point-red"></i>&nbsp;<span>{{ 'Missing data' | translate }}</span>
            </div>
          </div>
          <div class="first-block__body--minor-charts-column">
            <circularity-rating-scale
              [gradeValue]="mainProductCategory.mostCircular.demountability_grade"
              [ratingPointsAmount]="6"
              [mainColor]="'#6BC84A'"
              *ngIf="mainProductCategory.mostCircular.demountability_grade != null"
            >
            </circularity-rating-scale>
            <div class="first-block__body--missing-data" *ngIf="result.aggregate.demountability_grade == null">
              <i class="icon icon--exclamation-point-red"></i>&nbsp;<span>{{ 'Missing data' | translate }}</span>
            </div>
          </div>
          <div class="first-block__body--minor-charts-column">
            <circularity-rating-scale
              [gradeValue]="mainProductCategory.leastCircular.demountability_grade"
              [ratingPointsAmount]="6"
              [mainColor]="'#EF2269'"
              *ngIf="mainProductCategory.leastCircular.demountability_grade != null"
            >
            </circularity-rating-scale>
            <div class="first-block__body--missing-data" *ngIf="result.aggregate.demountability_grade == null">
              <i class="icon icon--exclamation-point-red"></i>&nbsp;<span>{{ 'Missing data' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="first-block__body--minor-charts-row">
          <div class="first-block__body--minor-charts-column-title">
            <p>{{ 'Identification and potential of future uses' | translate }}</p>
          </div>
          <div class="first-block__body--minor-charts-column">
            <circularity-rating-scale
              [gradeValue]="mainProductCategory.mostPresent.next_usage_potential_grade"
              [ratingPointsAmount]="6"
              [mainColor]="'#622893'"
            >
            </circularity-rating-scale>
          </div>
          <div class="first-block__body--minor-charts-column">
            <circularity-rating-scale
              [gradeValue]="mainProductCategory.mostCircular.next_usage_potential_grade"
              [ratingPointsAmount]="6"
              [mainColor]="'#6BC84A'"
            >
            </circularity-rating-scale>
          </div>
          <div class="first-block__body--minor-charts-column">
            <circularity-rating-scale
              [gradeValue]="mainProductCategory.leastCircular.next_usage_potential_grade"
              [ratingPointsAmount]="6"
              [mainColor]="'#EF2269'"
            >
            </circularity-rating-scale>
          </div>
        </div>
        <div class="first-block__body--minor-charts-row">
          <div class="first-block__body--minor-charts-column-title">
            <p>{{ 'Presence and reliability of information' | translate }}</p>
          </div>
          <div class="first-block__body--minor-charts-column">
            <circularity-rating-scale
              [gradeValue]="mainProductCategory.mostPresent.information_characterization_and_rating_grade"
              [ratingPointsAmount]="6"
              [mainColor]="'#622893'"
            >
            </circularity-rating-scale>
          </div>
          <div class="first-block__body--minor-charts-column">
            <circularity-rating-scale
              [gradeValue]="mainProductCategory.mostCircular.information_characterization_and_rating_grade"
              [ratingPointsAmount]="6"
              [mainColor]="'#6BC84A'"
            >
            </circularity-rating-scale>
          </div>
          <div class="first-block__body--minor-charts-column">
            <circularity-rating-scale
              [gradeValue]="mainProductCategory.leastCircular.information_characterization_and_rating_grade"
              [ratingPointsAmount]="6"
              [mainColor]="'#EF2269'"
            >
            </circularity-rating-scale>
          </div>
        </div>
        <div class="first-block__body--minor-charts-row">
          <div class="first-block__body--minor-charts-column-title"></div>
          <div class="first-block__body--minor-charts-column">
            <div class="first-block__body--minor-charts-column-footer-items">
              <h3>{{mainProductCategory.mostPresent.titleForFooter | translate}}</h3>
              <p>{{mainProductCategory.mostPresent.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}} {{ 'tons' | translate }}</p>
              <p>{{mainProductCategory.mostPresent.percentage_by_weight | number: '1.1-1' | decimalSeparator:','}}% {{ 'of the total weight' | translate }}</p>
            </div>
          </div>
          <div class="first-block__body--minor-charts-column">
            <div class="first-block__body--minor-charts-column-footer-items">
              <h3>{{mainProductCategory.mostCircular.titleForFooter | translate}}</h3>
              <p>{{mainProductCategory.mostCircular.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}} {{ 'tons' | translate }}</p>
              <p>{{mainProductCategory.mostCircular.percentage_by_weight | number: '1.1-1' | decimalSeparator:','}}% {{ 'of the total weight' | translate }}</p>
            </div>
          </div>
          <div class="first-block__body--minor-charts-column">
            <div class="first-block__body--minor-charts-column-footer-items">
              <h3>{{mainProductCategory.leastCircular.titleForFooter | translate}}</h3>
              <p>{{mainProductCategory.leastCircular.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}} {{ 'tons' | translate }}</p>
              <p>{{mainProductCategory.leastCircular.percentage_by_weight | number: '1.1-1' | decimalSeparator:','}}% {{ 'of the total weight' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section *ngIf="isEmptyProductCategory()" class="section-error">
    <h3 class="section-error__title">{{ 'No deposit considered' | translate }}</h3>
  </section>
</report-pdf>

<report-pdf
  *ngFor="let table of categoriesTable; let i = index"

  [pageNumber]="addTitlePagesNumber(table.pageNumber)"
  [reportHeader]="reportHeader"
>
  <div class="second-block">
    <div class="second-block__title--wrapper">
      <h2 class="second-block__title--item">{{'All categories' | translate}}</h2>
    </div>
    <div class="second-block__table">
      <div class="second-block__table--row second-block__table--row-default">
        <p class="second-block__table--row-title">{{'Category' | translate}}</p>
        <p class="second-block__table--row-title">{{'NUMBER_OF_DEPOSITS_CONSIDERED' | translate}}</p>
        <p class="second-block__table--row-title" [innerHTML]="'Weight (Tons)' | translate"></p>
        <p class="second-block__table--row-title">{{'% of total weight' | translate}}</p>
        <p class="second-block__table--row-title">{{'Circularity grade' | translate}}</p>
        <p class="second-block__table--row-title">{{'CIRCULARITY_PRODUCT_RENEW_INFO' | translate}}</p>
        <p class="second-block__table--row-title">{{'% of deposits resulting from reuse' | translate}}</p>
        <p class="second-block__table--row-title">{{'CIRCULARITY_PRODUCT_DISASSEMBLABILITY' | translate}}</p>
        <p class="second-block__table--row-title">{{'CIRCULARITY_PRODUCT_FUTURE_LIFE' | translate}}</p>
        <p class="second-block__table--row-title">{{'CIRCULARITY_PRODUCT_PRESENCE_INFO' | translate}}</p>
      </div>
      <div class="second-block__table--row-wrapper">
            <div class="second-block__table--row" *ngFor="let item of table.pageContent; let index = index;">
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['category'].name | translate}}
              </p>
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['deposits_considered']}}
              </p>
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['weight'] | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
              </p>
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['percentage_by_weight'] | number: '1.1-1':'fr' | decimalSeparator:','}}%
              </p>
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['circularity_grade'] | number: '1.0-0':'fr' | decimalSeparator:','}}%
              </p>
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['percentage_of_recycled_material'] | number: '1.0-0':'fr' | decimalSeparator:','}}%
              </p>
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['reused_percentage_by_weight'] | number: '1.0-0':'fr' | decimalSeparator:','}}%
              </p>
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['demountability_grade'] | number: '1.0-0':'fr' | decimalSeparator:','}}%
              </p>
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['next_usage_potential_grade'] | number: '1.0-0':'fr' | decimalSeparator:','}}%
              </p>
              <p [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['information_characterization_and_rating_grade'] || 0 | number: '1.0-0':'fr' | decimalSeparator:','}}%
              </p>
        </div>
      </div>
    </div>
  </div>
</report-pdf>

<report-pdf
  *ngFor="let table of errorsTable; let i = index"
  [pageNumber]="addTitlePagesNumber(table.pageNumber)"
  [reportHeader]="reportHeader"
>
  <div class="third-block">
    <div class="third-block__title--wrapper">
      <h2 class="third-block__title--item">
        {{'Error report' | translate}} (<span class="third-block__title--item-span">{{getAmountOf('errors')}} {{'errors' | translate}}, {{getAmountOf('warnings')}} {{'warnings' | translate}}</span>)
      </h2>
    </div>
    <div class="third-block__body">
      <div class="third-block__body--description">
        <div class="third-block__body--description">
          <p [innerHTML]="'ERROR_REPORT_DESCRIPTION_INTRO' | translate"></p>
          <br>
          <p><i class="icon--admin-warning icon"></i>{{'ERROR_REPORT_DESCRIPTION_WARNING' | translate}}</p>
          <br>
          <p><i class="icon--admin-error icon"></i>{{'ERROR_REPORT_DESCRIPTION_ERROR' | translate}}</p>
          <br>
          <p [innerHTML]="'ERROR_REPORT_DESCRIPTION_OUTRO' | translate"></p>
        </div>
      </div>
      <div *ngIf="reportData.is_asynchronous_generated" class="third-block__body--table">
        <div class="third-block__body--table-row">
          <div class="third-block__body--table-row-title">
          </div>
          <span class="third-block__body--table-row-item-divider"></span>
          <div class="third-block__body--table-row-title">
            <p>{{'NUMBER_DEPOSITS_CONSIDERED' | translate}}</p>
          </div>
          <span class="third-block__body--table-row-item-divider"></span>
          <div class="third-block__body--table-row-title">
            <p>{{'Error explanation' | translate}}</p>
          </div>
        </div>
        <div *ngFor="let item of table.pageContent; let index = index" class="third-block__body--table-row">
          <div class="third-block__body--table-row-item">
            <i [ngClass]="[isError(item['type']) ? 'icon--admin-error' : 'icon--admin-warning', 'icon']"></i>
          </div>
          <span class="third-block__body--table-row-item-divider"></span>
          <div class="third-block__body--table-row-item">
            <p>{{item.counter}}</p>
          </div>
          <span class="third-block__body--table-row-item-divider"></span>
          <div class="third-block__body--table-row-item">
            <p>{{getErrorMessage(item)}}</p>
          </div>
        </div>
      </div>
      
      <div *ngIf="!reportData.is_asynchronous_generated" class="third-block__body--table">
        <div class="third-block__body--table-row">
          <div class="third-block__body--table-row-title">
          </div>
          <span class="third-block__body--table-row-item-divider"></span>
          <div class="third-block__body--table-row-title">
            <p>{{'Deposit name' | translate}}</p>
          </div>
          <span class="third-block__body--table-row-item-divider"></span>
          <div class="third-block__body--table-row-title">
            <p>{{'Error explanation' | translate}}</p>
          </div>
        </div>
        <div *ngFor="let item of table.pageContent; let index = index" class="third-block__body--table-row">
          <div class="third-block__body--table-row-item">
            <i [ngClass]="[isError(item['type']) ? 'icon--admin-error' : 'icon--admin-warning', 'icon']"></i>
          </div>
          <span class="third-block__body--table-row-item-divider"></span>
          <div class="third-block__body--table-row-item">
            <p>{{item.deposit_name}}</p>
          </div>
          <span class="third-block__body--table-row-item-divider"></span>
          <div class="third-block__body--table-row-item">
            <p>{{getErrorMessage(item)}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</report-pdf>
</div>