import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { ReportFile } from '../models/report-file.class';

@Injectable()
export class ReportFileService extends CrudService {
  protected namespace = 'report-import-files';
  protected namespaceSingular = 'report-import-file';
  protected ModelClass = ReportFile;

}
