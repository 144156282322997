import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Stakeholder } from '../../models/stakeholder.class';
import { StakeholderService } from '../../services/stakeholder.service';
import { STAKEHOLDER_TYPES } from '../../values/stakeholder-types.const';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';

@Component({
  moduleId: module.id,
  selector: 'stakeholder-details',
  templateUrl: 'stakeholder-details.component.html',
  styleUrls: ['stakeholder-details.component.scss'],
  providers: [ReadOnlyService],
})
export class StakeholderDetailsComponent implements OnInit {
  STAKEHOLDER_TYPES = STAKEHOLDER_TYPES;
  TYPES = Object.values(STAKEHOLDER_TYPES).filter(parseInt);

  @Input() readOnly = false;
  @Input() id: number;
  stakeholder: Stakeholder;
  @Output() saved = new EventEmitter();

  constructor(public readOnlyService: ReadOnlyService,
              private stakeholderService: StakeholderService,) {
  }

  ngOnInit() {
    this.readOnlyService.readOnly = this.readOnly;

    if (this.id) {
      this.stakeholderService.view(this.id).subscribe((res: Stakeholder) => {
        this.stakeholder = res;
        this.stakeholder.loaded = true;
      });
    } else {
      this.stakeholder = new Stakeholder();
      this.stakeholder.loaded = true;
    }
  }

  save() {
    this.stakeholder.save().subscribe(() => {
      this.saved.emit();
    });
  }
}
