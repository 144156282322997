import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { DepositLocation } from '../models/deposit-location.class';
import {Response} from '@angular/http';

@Injectable()
export class DepositLocationService extends ExtendedCrudService {
  relations = ['tags'];
  protected namespace = 'deposit-locations';
  protected namespaceSingular = 'deposit-location';
  protected ModelClass = DepositLocation;

  getFileUploadUrlExpanded(id: number): string {
    return super.getFileUploadUrl(id) + '?expand=file,basename';
  }

  getAssignUrl(id: number) {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/assign');
  }

  assign(id: number, data) {
    return this.sendPost(this.getAssignUrl(id), {...data, deposit_location_id: data.id} )
      .map((res: Response) => <DepositLocation> res.json())
      .catch(this.onError.bind(this));
  }
  deleteAllFiltered(filtersData) {
    return this.sendPost(this.getEndpoint(`${this.namespaceSingular}/deleteall`), filtersData)
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  canUseName(name: string) {
    return this.sendGet(this.getEndpoint(`${this.namespaceSingular}/can_use_name`), {search: {name}})
      .catch(this.onError.bind(this));
  }

  getTargetedNeedLocations(params?) {
    return this.sendGet(this.getEndpoint(`${this.namespaceSingular}/list_targeted_deposit_location`), {search: params})
      .map((data) => data.json())
      .catch(this.onError.bind(this));
  }

  /**
   * Request the backend to send locations for the map
   * /!\ This is only for the need-deposit-list component for now /!\
   * @param {Object} params - Params for the location search
   * @returns {DepositLocation[]}
   */
  getMapLocations(params) {
    return this.sendGet(this.getEndpoint(`${this.namespaceSingular}/map_locations`), {search: params})
      .map((data) => data.json())
      .catch(this.onError.bind(this));
  }
}
