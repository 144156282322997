import * as tslib_1 from "tslib";
import { EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BACKSPACE, DELETE, MdDialog, MdDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DepositImportWarningComponent } from '../deposit-import-warning/deposit-import-warning.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { FileUploader } from 'ng2-file-upload';
import { DepositImportService } from '../../../../entities/deposit/services/deposit-import.service';
import { DepositImport } from '../../../../entities/deposit/models/deposit-import.class';
import { DepositFileService } from '../../../../entities/deposit/services/deposit-file.service';
import { IMPORT_ERROR } from '../../../../entities/deposit/values/import-error.const';
import { DepositImportErrorsComponent } from '../../../../entities/deposit/dialogs/deposit-import-errors/deposit-import-errors.component';
import { DeepStreamService } from '../../../../shared/services/deepstream.service';
import { ImportSpinnerComponent } from '../../../../entities/deposit/dialogs/import-spinner/import-spinner.component';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { merge } from 'rxjs';
import { ImportCheckedComponent } from '../../../../entities/deposit/dialogs/import-checked/import-checked.component';
import { ReportImport } from '../../../../entities/deposit/models/report-import.class';
import { DocumentsUploaderComponent } from '../../../../shared/uploaders/documents-uploader/documents-uploader.component';
import { ReportImportService } from '../../../../entities/deposit/services/report-import.service';
import { ReportFileService } from '../../../../entities/deposit/services/report-file.service';
import { EErrorStatus } from '../../../../shared/spinner/spinner-completion.enum';
var DepositImportDialogComponent = /** @class */ (function () {
    function DepositImportDialogComponent(notification, mdDialogRef, _dialog, _fb, depositImportService, reportImportService, depositFileService, reportFileService, logNotificationService, _translate, zone, ds, mdDialogData) {
        this.notification = notification;
        this.mdDialogRef = mdDialogRef;
        this._dialog = _dialog;
        this._fb = _fb;
        this.depositImportService = depositImportService;
        this.reportImportService = reportImportService;
        this.depositFileService = depositFileService;
        this.reportFileService = reportFileService;
        this.logNotificationService = logNotificationService;
        this._translate = _translate;
        this.zone = zone;
        this.ds = ds;
        this.mdDialogData = mdDialogData;
        this.depositFileUploader = new FileUploader({});
        this.uploadedDepositFile = [];
        this.uploadedReportFile = [];
        this.depositImport = new DepositImport();
        this.reportImport = new ReportImport();
        this.isReport = false;
        this.onDocumentSave$ = new EventEmitter();
        this.onUpdateUploadUrl$ = new EventEmitter();
        this.fields = [
            { id: 'tab_name', name: 'Tab name' },
            { id: 'first_row', name: 'No. of the first line to be imported' },
            { id: 'last_row', name: 'No. of the last line to be imported' },
            { id: 'passport_id_field', name: 'Passport ID' },
            { id: 'tags_field', name: 'Tag(s)' },
            { id: 'site_name_field', name: 'Site Name' },
            { id: 'quantity_field', name: 'Quantity' },
            { id: 'unit_field', name: 'Unit' },
            { id: 'availability_date_field', name: 'Availability date' },
            { id: 'availability_expiration_field', name: 'Expiration date' },
            { id: 'reference_date', name: 'Reference date' },
            { id: 'state_field', name: 'State' },
            { id: 'state_description_field', name: 'Description of the state' },
            { id: 'demountability_field', name: 'Demountability' },
            { id: 'comments_field', name: 'Comments' },
            { id: 'reused_field', name: 'Reused' },
        ];
        this.spinnerPercentage = new BehaviorSubject(0);
        this.isImportInProcess = false;
        this._columnsWithSameStyle = [
            { id: 'availability_expiration_field', name: 'Expiration date', description: 'Format DD/MM/YYYYY', report: false },
            { id: 'state_field', name: 'State', description: 'PROCESSED_STATE_0_TO_4', report: true },
            { id: 'state_description_field', name: 'Description of the state',
                description: 'Fields are limited to 65535 characters', report: false },
            { id: 'demountability_field', name: 'Demountability', description: 'PROCESSED_DEMOUNTABILITY_0_TO_4', report: true },
            { id: 'comments_field', name: 'Comments', description: 'Fields are limited to 65535 characters', report: false },
            { id: 'reused_field', name: 'Reused',
                description: 'First character of the cell must be 0 if not reused and 1 if reused', report: true },
        ];
        this.DEPOSIT_IMPORT_STEP = { VALIDATION: 1, LOAD: 2 };
        this.isReport = mdDialogData.isReport;
    }
    DepositImportDialogComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        this.importParsedRowsStreamSubscription.unsubscribe();
        this.importErrorStreamSubscription.unsubscribe();
        if (!!this.isImportInProcess) {
            merge(this.ds.depositImportDeepStream$.depositImportFatalError$.filter(function (e) { return _this.isOngoingImport(e); }), this.ds.depositImportDeepStream$.depositCompletionStream$
                .filter(function (e) { return !!e.data.success && _this.isOngoingImport(e); }))
                .subscribe(function () { return _this.unsubscribeObserversAfterLoad(); });
        }
        else {
            this.unsubscribeObserversAfterLoad();
        }
    };
    DepositImportDialogComponent.prototype.unsubscribeObserversAfterLoad = function () {
        this.importCompletionStreamSubscription.unsubscribe();
        this.importFatalErrorStreamSubscription.unsubscribe();
    };
    DepositImportDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.depositImportForm = this._fb.group({
            id: null,
            tab_name: ['', Validators.required],
            first_row: ['', Validators.required],
            last_row: ['', Validators.required],
            passport_id_field: ['', Validators.required],
            site_name_field: !this.isReport ? ['', Validators.required] : [''],
            tags_field: [''],
            quantity_field: ['', Validators.required],
            unit_field: ['', Validators.required],
            availability_date_field: [''],
            availability_expiration_field: [''],
            reference_date: [''],
            reference_date_expire: [''],
            state_field: [''],
            state_description_field: [''],
            demountability_field: [''],
            comments_field: [''],
            reused_field: [''],
        });
        this.importParsedRowsStreamSubscription = this.ds.depositImportDeepStream$
            .depositImportParsedRowsStream$.filter(function (e) { return _this.isOngoingImport(e); })
            .subscribe(function (e) {
            var progress = _this.toPercent(e.data.count, e.data.total, false);
            _this.zone.run(function () { return _this.spinnerPercentage.next(progress); });
            if (!_this.importSpinnerDialogRef) {
                _this.showLoadingSpinner();
                _this.importSpinnerInstance.showPercentage();
            }
        });
        this.importErrorStreamSubscription = this.ds.depositImportDeepStream$
            .depositImportErrorsStream$.filter(function (e) { return _this.isOngoingImport(e); })
            .subscribe(function (e) {
            _this.importSpinnerInstance.showPercentage(false);
            _this.showErrorsComponent(e.data.import_id);
        });
        this.importCompletionStreamSubscription = this.ds.depositImportDeepStream$
            .depositCompletionStream$.filter(function (e) { return _this.isOngoingImport(e); })
            .subscribe(function (e) {
            var isCompletedOnSuccessMessage = _this.isReport || (e.data.success !== undefined && !!e.data.success);
            var progress = _this.toPercent(e.data.count, e.data.total, isCompletedOnSuccessMessage);
            _this.zone.run(function () { return _this.spinnerPercentage.next(progress); });
            if (!_this.importSpinnerDialogRef) {
                _this.loadSpinner();
            }
            _this.checkImportSuccess();
        });
        this.importFatalErrorStreamSubscription = this.ds.depositImportDeepStream$
            .depositImportFatalError$.filter(function (e) { return _this.isOngoingImport(e); })
            .subscribe(function (e) {
            if (e.data.error_status === EErrorStatus.UNKNOWN) {
                _this.logNotificationService.error('Failed to create the content for unknown reason', undefined, true);
                if (!!_this.importSpinnerDialogRef) {
                    _this.destroyImportSpinner();
                }
            }
        });
    };
    Object.defineProperty(DepositImportDialogComponent.prototype, "importSpinnerInstance", {
        get: function () {
            return !!this.importSpinnerDialogRef && this.importSpinnerDialogRef.componentInstance;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositImportDialogComponent.prototype, "columnsWithSameStyle", {
        get: function () {
            return this.isReport ? this._columnsWithSameStyle.filter(function (col) { return !!col.report; }) : this._columnsWithSameStyle;
        },
        enumerable: true,
        configurable: true
    });
    DepositImportDialogComponent.prototype.isOngoingImport = function (e) {
        var importId = e.data.import_id;
        return importId === this.depositImport.id || importId === this.reportImport.id;
    };
    DepositImportDialogComponent.prototype.toPercent = function (value, total, allowHundredPercent) {
        if (allowHundredPercent === void 0) { allowHundredPercent = true; }
        var progress = Math.trunc((value / total) * 100);
        return (progress === 100 && !allowHundredPercent) ? 99 : progress;
    };
    DepositImportDialogComponent.prototype.checkImportSuccess = function () {
        if (!!this.importSpinnerInstance && this.importSpinnerInstance.waitingForErrors) {
            this.importSpinnerInstance.waitForImport();
        }
        if (this.spinnerPercentage.getValue() === 100) {
            this.onImportSuccess();
        }
    };
    DepositImportDialogComponent.prototype.loadSpinner = function () {
        this.showLoadingSpinner();
        this.importSpinnerInstance.waitForImport();
    };
    DepositImportDialogComponent.prototype.showLoadingSpinner = function () {
        if (!!this.importSpinnerDialogRef) {
            return null;
        }
        this.importSpinnerDialogRef = this._dialog.open(ImportSpinnerComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { disableClose: true, data: { percentage: this.spinnerPercentage } }));
    };
    DepositImportDialogComponent.prototype.onImportSuccess = function () {
        var _this = this;
        this.destroyImportSpinner();
        this.zone.run(function () {
            if (_this.isReport) {
                _this._dialog.open(ImportCheckedComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY)).afterClosed().subscribe(function () {
                    _this.mdDialogRef.close({
                        reportFile: _this.uploadedReportFile,
                        reportImport: _this.reportImport
                    });
                });
            }
            else {
                _this.mdDialogRef.close(true);
                _this.notification.success('Import successful', 2000, true);
            }
        });
    };
    DepositImportDialogComponent.prototype.destroyImportSpinner = function () {
        var _this = this;
        this.isImportInProcess = false;
        this.zone.run(function () {
            _this.importSpinnerDialogRef.close();
            _this.importSpinnerDialogRef.afterClosed().subscribe(function () {
                _this.importSpinnerDialogRef = void 0;
                _this.spinnerPercentage.next(0);
            });
        });
    };
    DepositImportDialogComponent.prototype.documentUploaded = function () {
        var _this = this;
        this.showLoadingSpinner();
        this.spinnerPercentage.next(0);
        this.importSpinnerInstance.showPercentage();
        this.importSpinnerInstance.showStep(true, Object.keys(this.DEPOSIT_IMPORT_STEP).length);
        var spreadsheetImport = this.isReport ? this.reportImport : this.depositImport;
        spreadsheetImport.startImport().subscribe(function () {
            _this.isImportInProcess = true;
        }, function () { return _this.destroyImportSpinner(); });
    };
    DepositImportDialogComponent.prototype.allowOnlyGreaterThanZeroNumbers = function (e) {
        this.allowOnly(e, /[0-9]/i);
    };
    DepositImportDialogComponent.prototype.allowOnlyLetters = function (e) {
        this.allowOnly(e, /[a-z]/i);
    };
    DepositImportDialogComponent.prototype.checkRequiredDates = function () {
        return !this.depositImportForm.controls['availability_date_field'].value &&
            !this.depositImportForm.controls['reference_date'].value;
    };
    DepositImportDialogComponent.prototype.datePickerKeydown = function (e) {
        if (e.keyCode === BACKSPACE || e.keyCode === DELETE) {
            this.depositImportForm.controls['reference_date'].setValue(null);
        }
        e.preventDefault();
    };
    DepositImportDialogComponent.prototype.allowOnly = function (e, regex) {
        var testChar = String.fromCharCode(e.keyCode >= 96 && e.keyCode <= 105 ? e.keyCode - 48 : e.keyCode);
        if (!(regex.test(testChar)) && ![8, 9, 37, 39, 46].some(function (k) { return k === e.keyCode; })) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    DepositImportDialogComponent.prototype.getCharOfColumnsWithSameStyle = function (index) {
        return String.fromCharCode((this.isReport ? 'D' : 'G').charCodeAt(0) + index);
    };
    DepositImportDialogComponent.prototype.updateImportAndShowWarning = function () {
        var _this = this;
        if (!this.validateAll()) {
            return;
        }
        this.depositImportForm.controls['id'].setValue(this.depositImport.id);
        this.depositImport = new DepositImport(this.depositImportForm.value);
        this.depositImport.reference_date = this.formatDateToMinUTCString(this.depositImport.reference_date);
        this._dialog.open(DepositImportWarningComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: { depositImport: this.depositImport } })).afterClosed().subscribe(function (isConfirmed) {
            if (!isConfirmed) {
                return;
            }
            _this.depositImport.save().subscribe(function (depositImportInserted) {
                _this.depositImport = depositImportInserted;
                if (_this.uploadedDepositFile.length === 1) {
                    _this.documentUploaded();
                }
                else {
                    _this.onUpdateUploadUrl$.emit(_this.depositImport.getFileUploadUrl());
                    _this.onDocumentSave$.emit();
                }
            });
        });
    };
    DepositImportDialogComponent.prototype.formatDateToMinUTCString = function (dateString) {
        if (dateString === '') {
            return '';
        }
        var arrDate = dateString.split('-');
        return arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
    };
    DepositImportDialogComponent.prototype.checkFile = function () {
        var _this = this;
        if (!this.validateAll()) {
            return;
        }
        this.depositImportForm.controls['id'].setValue(this.reportImport.id);
        this.reportImport = new ReportImport(this.depositImportForm.value);
        this.reportImport.save().subscribe(function (depositImportInserted) {
            _this.reportImport = depositImportInserted;
            _this.onUpdateUploadUrl$.emit(_this.reportImport.getFileUploadUrl());
            _this.onDocumentSave$.emit();
            _this.showLoadingSpinner();
            if (_this.uploadedReportFile.length === 1) {
                _this.documentUploaded();
            }
            else {
                _this.onUpdateUploadUrl$.emit(_this.reportImport.getFileUploadUrl());
                _this.onDocumentSave$.emit();
            }
        });
    };
    DepositImportDialogComponent.prototype.validateAll = function () {
        var duplicates = this.checkDuplicatedColumnNames();
        if (duplicates.length > 0) {
            this.logNotificationService.error(this._translate.instant(this.fields.find(function (f) { return f.id === duplicates[0]; }).name) + "\n                                        : " + this._translate.instant('This column is already used'));
            return false;
        }
        this.clearDuplicatedErrorsAndTouch();
        var requiredField = this.findControlNameWithRequiredError();
        if (requiredField) {
            this.logNotificationService.error(this._translate.instant(requiredField) + " " + this._translate.instant('is required') + ".", null, true);
            return false;
        }
        if (!this.isReport && this.checkRequiredDates()) {
            this.logNotificationService.error(this._translate.instant('Date of reference is required if date of availability is empty'));
            return false;
        }
        if (this.documentsUploader.queueFiles !== 1 &&
            (this.isReport ? this.uploadedReportFile.length !== 1 : this.uploadedDepositFile.length !== 1)) {
            this.logNotificationService.error(this._translate.instant('File has not been upload'));
            return false;
        }
        if (this.depositImportForm.get('first_row').value > this.depositImportForm.get('last_row').value) {
            this.depositImportForm.get('first_row').setErrors({ required: true });
            this.depositImportForm.get('first_row').markAsTouched({ onlySelf: true });
            this.logNotificationService.error(this._translate.instant('First row is greater than last row'));
            return false;
        }
        return true;
    };
    DepositImportDialogComponent.prototype.checkDuplicatedColumnNames = function () {
        var _this = this;
        var spreadSheetColumns = Object.keys(this.depositImportForm.controls)
            .filter(function (element) { return !['tab_name', 'first_row', 'last_row', 'id', 'reference_date'].includes(element); });
        var lookup = spreadSheetColumns.reduce(function (a, e) {
            a[_this.depositImportForm.get(e).value.toUpperCase()] = ++a[_this.depositImportForm.get(e).value.toUpperCase()] || 0;
            return a;
        }, {});
        return spreadSheetColumns.filter(function (e) {
            var control = _this.depositImportForm.get(e);
            if (!!control.value && lookup[control.value.toUpperCase()]) {
                control.setErrors({ duplicate: true });
                control.markAsTouched({ onlySelf: true });
                return e;
            }
        });
    };
    DepositImportDialogComponent.prototype.clearDuplicatedErrorsAndTouch = function () {
        var _this = this;
        Object.keys(this.depositImportForm.controls).forEach(function (field) {
            var control = _this.depositImportForm.get(field);
            if (control.getError('duplicate')) {
                control.setErrors(null);
            }
            control.markAsTouched({ onlySelf: true });
        });
    };
    DepositImportDialogComponent.prototype.findControlNameWithRequiredError = function () {
        var _this = this;
        var errorField = this.fields
            .filter(function (field) { return !['availability_date_field', 'reference_date'].includes(field.id); })
            .find(function (field) { return _this.depositImportForm.controls[field.id].getError('required'); });
        return errorField ? errorField.name : null;
    };
    DepositImportDialogComponent.prototype.showErrorsComponent = function (depositImportId) {
        var _this = this;
        var importService = this.isReport ? this.reportImportService : this.depositImportService;
        importService.view(depositImportId, { expand: 'errors' })
            .subscribe(function (data) {
            var errors = !!data.errors.length ? data.errors.map(function (e) {
                var errorDescription = IMPORT_ERROR[e.error_type] ? _this.translate(IMPORT_ERROR[e.error_type], e.value) : '';
                return { error: errorDescription, line: e.row, value: e.value };
            }) : [];
            if (!!errors.length) {
                _this.isImportInProcess = false;
                if (_this.importSpinnerDialogRef) {
                    _this.zone.run(function () {
                        _this.importSpinnerDialogRef.close();
                        _this.importSpinnerDialogRef = void 0;
                    });
                }
                _this.zone.run(function () {
                    if (!!_this.importErrorDialogRef && !!_this.importErrorDialogRef.componentInstance) {
                        return;
                    }
                    _this.importErrorDialogRef = _this._dialog.open(DepositImportErrorsComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: { color: _this.isReport ? 'blue' : 'green', errors: errors } }));
                });
            }
            else {
                if (!!_this.importSpinnerDialogRef) {
                    _this.importSpinnerInstance.waitForImport();
                    _this.importSpinnerInstance.changeStep(_this.DEPOSIT_IMPORT_STEP.LOAD);
                    _this.spinnerPercentage.next(0);
                }
            }
        });
    };
    DepositImportDialogComponent.prototype.translate = function (key, x) {
        var message = '';
        this._translate.get(key, { X: x }).subscribe(function (res) {
            message += res;
        });
        return message;
    };
    return DepositImportDialogComponent;
}());
export { DepositImportDialogComponent };
