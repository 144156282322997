import { Component, NgZone, OnInit } from '@angular/core';
import { NeedService } from '../../services/need.service';
import { Need } from '../../models/need.class';
import {ReadOnlyService} from '../../../../shared/read-only/read-only.service';
import { LocalStorageService } from '../../../../shared/services/localStorage.service';

@Component({
  moduleId: module.id,
  selector: 'need-directory',
  templateUrl: 'need-directory.component.html',
  styleUrls: ['need-directory.component.scss'],
  providers: [ReadOnlyService]
})
export class NeedDirectoryComponent implements OnInit {
  
  needs: Need[] = [];
  activeIndex: number = 0;
  displayInformation: boolean = false;
  
  constructor(private needService: NeedService, private _zone: NgZone, private _localStorageService: LocalStorageService) {
    this.displayInformation = this._localStorageService.getStoredValue("needDirectoryHeaderSeen") !== "true";
  }

  ngOnInit() {

  }
  
  changeTab(index: number) {
    this._zone.run(() => this.activeIndex = index);
  }

  /**
   * Close the information notice
   */
  closeInformation() {
    this.displayInformation = false;
    this._localStorageService.storeValue("needDirectoryHeaderSeen", true);
  }
}
