/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/read-only/form-elems-hidden.directive";
import * as i3 from "../../../shared/read-only/read-only.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./create-button.component";
var styles_CreateButtonComponent = [i0.styles];
var RenderType_CreateButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateButtonComponent, data: {} });
export { RenderType_CreateButtonComponent as RenderType_CreateButtonComponent };
export function View_CreateButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "create-button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCreate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.FormElemsHiddenDirective, [[2, i3.ReadOnlyService]], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "create-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "create-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((i1.ɵnov(_v, 1).readOnlyService == null) ? null : i1.ɵnov(_v, 1).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.label)); _ck(_v, 5, 0, currVal_1); }); }
export function View_CreateButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-button", [], null, null, null, View_CreateButtonComponent_0, RenderType_CreateButtonComponent)), i1.ɵdid(1, 49152, null, 0, i5.CreateButtonComponent, [], null, null)], null, null); }
var CreateButtonComponentNgFactory = i1.ɵccf("app-create-button", i5.CreateButtonComponent, View_CreateButtonComponent_Host_0, { label: "label" }, { create: "create" }, []);
export { CreateButtonComponentNgFactory as CreateButtonComponentNgFactory };
