/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Input } from '@angular/core';
import { Contact } from '../../../client/model/contact.class';
import { MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'view-contact',
  templateUrl: 'view-contact.component.html',
  styleUrls: ['view-contact.component.scss'],
})
export class ViewContactDialogComponent {

  @Input() public contact: Contact;

  constructor(private dialog: MdDialogRef<any>) {
  }
}
