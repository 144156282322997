import * as tslib_1 from "tslib";
import { ActiveRecord } from './active-record.class';
var Eula = /** @class */ (function (_super) {
    tslib_1.__extends(Eula, _super);
    function Eula() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Eula.prototype.hasFiles = function () {
        return !!this.files.length;
    };
    Eula.prototype.fields = function () {
        return ['id', 'name', 'files', 'created_at'];
    };
    Eula.prototype.getPdfUrl = function (language) {
        var file = this.files.find(function (lang) { return lang.language === language; });
        if (file) {
            return file.filepath + '#toolbar=0&view=FitH';
        }
    };
    return Eula;
}(ActiveRecord));
export { Eula };
