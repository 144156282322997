import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import { CurrentUserService } from '../services/current-user.service';
import { Observable } from 'rxjs/Observable';
/**
 * Created by aleksandr on 31.07.17.
 */

export abstract class ModuleGuard implements CanActivate {

  protected abstract context: string;
  protected abstract _navigationService: NavigationService;
  protected abstract _userService: CurrentUserService;
  protected abstract _router: Router;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log('Try activate', this.context, route, state, this
      ._userService.isLoggedChecked);
    return this
      ._userService
      .loggedInChecked$
      .map((res) => {
        if (this._userService.hasContext(this.context)) {
          this._navigationService.setModule(this.context);
          return true;
        } else {
          console.log(this.context + ' context not available for you');
          let context = this._navigationService.getContext();
          this._router.navigate([context ? context.homeUrl : '']);
          return false;
        }
      });
  }

}
