/**
 * Created by aleksandr on 29.08.17.
 */
import { OnceBeforeRouteActivatedHook } from '../adn-common/once-before-route-activated.guard';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CurrentUserService } from '../services/current-user.service';
import { ChatService } from '../chat/chat.service';
import { DeepStreamService } from '../services/deepstream.service';
import { Observer } from 'rxjs/Observer';
import { ServiceLocator } from '../services/service-locator';

export class DeepStreamInitHook extends OnceBeforeRouteActivatedHook {
  _userService: CurrentUserService;
  _chatService: ChatService;
  _deepStreamService: DeepStreamService;
  constructor(
     // private _userService: CurrentUserService,
      //private _chatService: ChatService,
     // private _deepStreamService: DeepStreamService
  ) {
    super();
     this._userService = ServiceLocator.injector.get(CurrentUserService);
     this._chatService = ServiceLocator.injector.get(ChatService);
     this._deepStreamService = ServiceLocator.injector.get(DeepStreamService);
    console.log('Construct DeepStreamInitHook');
  }

  beforeRouteActivated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | void {
    console.log('Launch DeepStreamInitHook');
    return this
      ._userService
      .loggedInChecked$
      .map((isChecked) => this._userService.isLoggedIn)
      .do((isLoggedIn) => isLoggedIn ? this.setupDeepstream().subscribe(
        () => {},
        (err) => console.log('Error while try ro connect deepstream', err)
      ) : null);
  }

  setupDeepstream(): Observable<any> {
    return Observable.create((observer: Observer<void>) => {
      console.log('DeepStreamInitHook -> Chat', this._userService.infoData);
      let info = this._userService.infoData;
      this._chatService.setOwnerId(info.id);
      if (info.avatar_url) {
        this._chatService.setOwnerAvatar(info.avatar_url);
      } else {
        this._chatService.setOwnerAvatar('../assets/png/profile.png');
      }
      let timeout = setTimeout(() => {
        observer.error('Failed to connect to the deepstream');
        observer.complete();
      }, 4000);
      if (!!info.username) {
        console.log('DeepStreamInitHook -> Chat -> try to login');
        this._deepStreamService.login(null, (success, data) => {
          console.log('DeepStreamInitHook -> Chat -> login', success, data);
          if (success) {
            this._deepStreamService.subscribeChannel(info.username);
            this._chatService.connectToStream();
          }
          clearTimeout(timeout);
          observer.next(null);
          observer.complete();
        });
      }
    });
  }


}
