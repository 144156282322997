import { Client } from './../../../../../../shared/detail-view/client';
import { ClientService } from './../../../../../client/service/client.service';
import { Component, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import { LogoUploaderComponent } from './../../../../../../shared/uploaders/logo-uploader/logo-uploader.component';

interface Contact {
  id?: number;
  name?: string;
  function?: string;
  phone?: string;
  email?: string;
}

@Component({
  selector: 'app-new-recipients-dialog',
  templateUrl: 'new-recipients-dialog.component.html',
  styleUrls: ['new-recipients-dialog.component.scss']
})
export class NewRecipientsDialogComponent {
  @Output() siteCreated = new EventEmitter<boolean>();
  @ViewChild('logoUploader') public logoUploader: LogoUploaderComponent;
  client: Client = new Client();
  formGroup: FormGroup;
  isUploading: boolean = false;
  logoIsUpdating: boolean = false;

  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
    private dialogRef: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) public data: Client
  ) {
    this.initFormGroup(data);
  }

  /**
   * Initialize form group with provided client data or default values.
   * @param data Client data.
   */
  private initFormGroup(data: Client) {
    this.formGroup = this.fb.group({
      client_id: [null],
      name: [data.name || '', [Validators.required]],
      contacts: this.initContacts(data.client_contacts || []),
    });

    if (data.logo) {
      this.client.logo = data.logo;
    }
  }

  /**
   * Handle form submission. If the form is valid, determine whether to create or update.
   */
  onSubmit() {
    if (this.formGroup.valid) {
      this.isUploading = true;

      const formData = this.processForm();
      const postProcessing = this.getPostProcessingFunction();

      // If ID is present, it's an update.
      if (this.data && this.data.id) {
        this.clientService.update<Client>(this.data.id, formData)
          .subscribe(postProcessing, this.handleSubmissionError.bind(this));
      } else {
        // Else, it's a creation.
        this.clientService.create<Client>(formData)
          .subscribe(postProcessing, this.handleSubmissionError.bind(this));
      }
    } else {
      this.isUploading = false;
      console.error("Required fields are not filled");
    }
  }

  /**
   * Process the form and returns the final data to be submitted.
   */
  private processForm() {
    const formData = this.formGroup.value;
  
    // Clean the name by removing leading and trailing spaces
    formData.name = formData.name.trim();
  
    // Set fullName for each contact
    formData.contacts.forEach(contact => {
      contact.name = contact.name.trim();
      contact.firstName = contact.firstName.trim();
      contact.name = `${contact.name} ${contact.firstName}`;
      
      // Do the same for other contact properties if needed
      if (contact.function) {
        contact.function = contact.function.trim();
      }
      if (contact.phone) {
        contact.phone = contact.phone.trim();
      }
      if (contact.email) {
        contact.email = contact.email.trim();
      }
    });
  
    return formData;
  }  

  /**
   * Returns the post-processing function after a successful submission.
   */
  private getPostProcessingFunction() {
    return (response) => {
      if (this.logoIsUpdating) { // Check if there's a new logo to upload.
        if (this.logoUploader.isEmptyQueue()) {
          this.clientService.removeLogo(response.id)
            .subscribe(this.handleSuccess.bind(this, response), this.handleUploadError.bind(this));
        } else {
          this.logoUploader.triggerUpload(this.getLogoUploaderUrl(response.id))
            .then(this.handleSuccess.bind(this, response))
            .catch(this.handleUploadError.bind(this));
        }
      } else {
        this.handleSuccess(response);
      }
    };
  }

  /**
   * Handles the post submission success scenario.
   */
  private handleSuccess(client) {
    this.siteCreated.emit(true);
    this.closeDialog(client);
    this.isUploading = false;
  }

  /**
   * Handles any errors that occur during form submission.
   * @param error Error object.
   */
  private handleSubmissionError(error: any) {
    this.isUploading = false;
    console.error("Error while processing the site", error);
  }

  /**
   * Handles any errors that occur during logo upload.
   * @param error Error object.
   */
  private handleUploadError(error: any) {
    this.isUploading = false;
    console.error("Error while uploading the logo", error);
  }

  /**
   * Initialize contacts array form group.
   * @param contacts Contacts data.
   */
  initContacts(contacts?: Contact[]): FormArray {
    let contactFormGroups: FormGroup[];

    if (contacts && contacts.length) {
        contactFormGroups = contacts.map(contact => this.initContact(contact));
    } else {
        contactFormGroups = [this.initContact()];
    }

    return this.fb.array(contactFormGroups, this.atLeastOneContactValidator);
  }

  /**
   * Initialize a single contact form group.
   * @param contact Contact data with specific properties id, name, function, phone, and email.
   */
  initContact(contact?: Contact) {
    // Use destructuring to extract properties from the contact object, providing default values
    const { id = null, name: fullName = '', function: contactFunction = '', phone = '', email = '' } = contact || {};

    // Split the full name into name and firstName, if available
    let [name, ...firstNameParts] = fullName.split(' ');
    const firstName = firstNameParts.join(' ');

    // Use FormBuilder to create a group with form controls and respective validators
    return this.fb.group({
      id: [id],
      name: [name,  [Validators.required, this.nameValidator]],
      firstName: [firstName,  [Validators.required, this.nameValidator]],
      function: [contactFunction],
      phone: [phone, [Validators.pattern(/^\+?[0-9\s]+$/)]],
      email: [email, [Validators.email]],
    });
  }

  /**
   * Validator to ensure at least one contact exists in the contacts form array.
   * @param formArray FormArray of contacts.
   */
  atLeastOneContactValidator(formArray: FormArray): { [s: string]: boolean } | null {
    if (formArray.length < 1) {
      return { 'noContact': true };
    }
    return null;
  }

  nameValidator(control: FormControl): { [s: string]: boolean } | null {
    const nameRegexp = /^[A-Za-zÀ-ÖØ-öø-ÿ\s\-]*$/; // Regex to check that the string contains only letters, hyphens and spaces (accepts accentuated letters)
    if (!control.value.match(nameRegexp)) {
      return { 'invalidName': true };
    }
    return null;
  }
  
  addContact() {
    (<FormArray>this.formGroup.controls['contacts']).push(this.initContact());
  }

  removeContact(index: number) {
    if (index == 0) return; // Can't delete the first contact
    (<FormArray>this.formGroup.controls['contacts']).removeAt(index);
  }

  closeDialog(client) {
    this.dialogRef.close(client);
  }

  getLogoUploaderUrl(id: number): string {
    return this.clientService.getLogoUploadUrl(id);
  }

  onLogoUpload(response: any) {
    const data = typeof response === 'string' ? JSON.parse(response) : response;

    if (data) {
      this.client.logo = data.image;
    }
  }

  onClickLogoUpload() {
    this.logoIsUpdating = true;
  }

  removeClientLogo() {
    this.logoIsUpdating = true;
  }

  get contactsArray(): FormArray {
    return this.formGroup.get('contacts') as FormArray;
  } 
}
