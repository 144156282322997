import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformServiceAreaService } from '../services/transform-service-area.service';

export class TransformServiceArea extends ActiveRecord {
  protected provider = TransformServiceAreaService;

  public id: number;
  public name: string;
  public transformation_id: number;

  protected fields() {
    return [
      'id',
      'name',
      'transformation_id',
    ];
  }
}
