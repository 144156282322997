<div class="content">
  <ng-content></ng-content>
</div>
<div class="popup" [class.focus]="isFocus">
  <span class="result-count">{{results.length}}</span>

  <div class="results">
    <button
      *ngFor="let result of results; let index = index"
      (click)="select(result)"
      [class.focus]="focusedResultIndex === index"
    >{{ result.description }}
    </button>
  </div>
</div>
