<ng-container [ngSwitch]="toxicityState">
    <!--None-->
    <svg *ngSwitchCase="ToxicityState.None" width="26" height="26" viewBox="0 0 26 26" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
            <path opacity="0.9"
                d="M18.1658 2.28377C18.1658 2.24969 18.1319 2.24969 18.1319 2.21562C17.9621 2.04524 17.7244 1.90894 17.4867 1.90894C17.2151 1.90894 17.0114 2.01116 16.8416 2.21562C16.8416 2.21562 16.8076 2.24969 16.8076 2.28377C16.536 2.62453 16.1625 3.06751 15.7551 3.57864C14.9402 4.63499 13.9216 6.06617 13.0048 7.70181C11.4429 10.496 10.0508 13.9377 9.98291 17.5497C9.98291 17.6178 9.98291 17.686 9.98291 17.7541C9.98291 18.8105 10.1527 19.8328 10.4922 20.7528C10.6959 21.298 10.9336 21.8092 11.2052 22.2521C12.5634 24.5011 14.8723 26.0005 17.4867 26.0005C21.6631 26.0005 25.0245 22.3203 25.0245 17.7541C24.9905 10.9049 20.0672 4.46461 18.1658 2.28377ZM17.4867 23.0018C16.7397 23.0018 16.0267 22.7974 15.4155 22.4566C18.5053 21.6729 20.984 18.9809 21.7989 15.5051C22.0366 16.1526 22.1724 16.8682 22.1724 17.6178C22.1724 20.5824 20.0672 23.0018 17.4867 23.0018Z"
                fill="#555C63" />
            <path opacity="0.7"
                d="M5.50054 0.204455C5.50054 0.170379 5.50054 0.204455 5.50054 0.204455L5.46658 0.170379C5.36471 0.0681514 5.19495 0 5.02518 0C4.85541 0 4.68564 0.0681514 4.58378 0.170379L4.54984 0.204455C4.34611 0.408909 4.10842 0.64744 3.83679 0.954121C3.29353 1.56748 2.61446 2.3853 2.00329 3.33942C0.950719 4.94098 0.0339539 6.95145 0 9.06414C0 9.09822 0 9.13229 0 9.20044C0 9.81381 0.135816 10.3931 0.339539 10.9383C0.475355 11.245 0.645133 11.5517 0.814903 11.8243C1.69771 13.1192 3.25957 13.971 5.02518 13.971C7.8094 13.971 10.0504 11.8243 10.0504 9.20044C10.0504 5.21358 6.75684 1.46526 5.50054 0.204455ZM5.05915 12.2332C4.54984 12.2332 4.10842 12.131 3.66702 11.9265C5.73821 11.4835 7.368 9.91603 7.91126 7.90557C8.08103 8.2804 8.18289 8.68931 8.18289 9.13229C8.18289 10.8361 6.7908 12.2332 5.05915 12.2332Z"
                fill="#555C63" />
        </g>
    </svg>
    <!--Unverified-->
    <svg *ngSwitchCase="ToxicityState.Unverified" width="26" height="26" viewBox="0 0 26 26" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.9"
            d="M18.1658 2.28377C18.1658 2.24969 18.1319 2.24969 18.1319 2.21562C17.9621 2.04524 17.7244 1.90894 17.4867 1.90894C17.2151 1.90894 17.0114 2.01116 16.8416 2.21562C16.8416 2.21562 16.8076 2.24969 16.8076 2.28377C16.536 2.62453 16.1625 3.06751 15.7551 3.57864C14.9402 4.63499 13.9216 6.06617 13.0048 7.70181C11.4429 10.496 10.0508 13.9377 9.98291 17.5497C9.98291 17.6178 9.98291 17.686 9.98291 17.7541C9.98291 18.8105 10.1527 19.8328 10.4922 20.7528C10.6959 21.298 10.9336 21.8092 11.2052 22.2521C12.5634 24.5011 14.8723 26.0005 17.4867 26.0005C21.6631 26.0005 25.0245 22.3203 25.0245 17.7541C24.9905 10.9049 20.0672 4.46461 18.1658 2.28377ZM17.4867 23.0018C16.7397 23.0018 16.0267 22.7974 15.4155 22.4566C18.5053 21.6729 20.984 18.9809 21.7989 15.5051C22.0366 16.1526 22.1724 16.8682 22.1724 17.6178C22.1724 20.5824 20.0672 23.0018 17.4867 23.0018Z"
            fill="black" fill-opacity="0.8" />
        <path opacity="0.7"
            d="M5.50054 0.204455C5.50054 0.170379 5.50054 0.204455 5.50054 0.204455L5.46658 0.170379C5.36471 0.0681514 5.19495 0 5.02518 0C4.85541 0 4.68564 0.0681514 4.58378 0.170379L4.54984 0.204455C4.34611 0.408909 4.10842 0.64744 3.83679 0.954121C3.29353 1.56748 2.61446 2.3853 2.00329 3.33942C0.950719 4.94098 0.0339539 6.95145 0 9.06414C0 9.09822 0 9.13229 0 9.20044C0 9.81381 0.135816 10.3931 0.339539 10.9383C0.475355 11.245 0.645133 11.5517 0.814903 11.8243C1.69771 13.1192 3.25957 13.971 5.02518 13.971C7.8094 13.971 10.0504 11.8243 10.0504 9.20044C10.0504 5.21358 6.75684 1.46526 5.50054 0.204455ZM5.05915 12.2332C4.54984 12.2332 4.10842 12.131 3.66702 11.9265C5.73821 11.4835 7.368 9.91603 7.91126 7.90557C8.08103 8.2804 8.18289 8.68931 8.18289 9.13229C8.18289 10.8361 6.7908 12.2332 5.05915 12.2332Z"
            fill="black" fill-opacity="0.8" />
    </svg>
    <!--Verified-->
    <svg *ngSwitchCase="ToxicityState.Verified" width="26" height="26" viewBox="0 0 26 26" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.9"
            d="M18.1658 2.28377C18.1658 2.24969 18.1319 2.24969 18.1319 2.21562C17.9621 2.04524 17.7244 1.90894 17.4867 1.90894C17.2151 1.90894 17.0114 2.01116 16.8416 2.21562C16.8416 2.21562 16.8076 2.24969 16.8076 2.28377C16.536 2.62453 16.1625 3.06751 15.7551 3.57864C14.9402 4.63499 13.9216 6.06617 13.0048 7.70181C11.4429 10.496 10.0508 13.9377 9.98291 17.5497C9.98291 17.6178 9.98291 17.686 9.98291 17.7541C9.98291 18.8105 10.1527 19.8328 10.4922 20.7528C10.6959 21.298 10.9336 21.8092 11.2052 22.2521C12.5634 24.5011 14.8723 26.0005 17.4867 26.0005C21.6631 26.0005 25.0245 22.3203 25.0245 17.7541C24.9905 10.9049 20.0672 4.46461 18.1658 2.28377ZM17.4867 23.0018C16.7397 23.0018 16.0267 22.7974 15.4155 22.4566C18.5053 21.6729 20.984 18.9809 21.7989 15.5051C22.0366 16.1526 22.1724 16.8682 22.1724 17.6178C22.1724 20.5824 20.0672 23.0018 17.4867 23.0018Z"
            fill="#00ACEE" />
        <path opacity="0.7"
            d="M5.50054 0.204455C5.50054 0.170379 5.50054 0.204455 5.50054 0.204455L5.46658 0.170379C5.36471 0.0681514 5.19495 0 5.02518 0C4.85541 0 4.68564 0.0681514 4.58378 0.170379L4.54984 0.204455C4.34611 0.408909 4.10842 0.64744 3.83679 0.954121C3.29353 1.56748 2.61446 2.3853 2.00329 3.33942C0.950719 4.94098 0.0339539 6.95145 0 9.06414C0 9.09822 0 9.13229 0 9.20044C0 9.81381 0.135816 10.3931 0.339539 10.9383C0.475355 11.245 0.645133 11.5517 0.814903 11.8243C1.69771 13.1192 3.25957 13.971 5.02518 13.971C7.8094 13.971 10.0504 11.8243 10.0504 9.20044C10.0504 5.21358 6.75684 1.46526 5.50054 0.204455ZM5.05915 12.2332C4.54984 12.2332 4.10842 12.131 3.66702 11.9265C5.73821 11.4835 7.368 9.91603 7.91126 7.90557C8.08103 8.2804 8.18289 8.68931 8.18289 9.13229C8.18289 10.8361 6.7908 12.2332 5.05915 12.2332Z"
            fill="#00ACEE" />
    </svg>

</ng-container>