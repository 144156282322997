<li class="upload-files__item"
    [ngClass]="itemClass"
    [mdTooltip]="fileName"
    [mdTooltipPosition]="'above'"
    appCustomTooltip
    [tooltipClass]="['passportlist__name-tooltip'
             , customClassPosition
             , 'background-primary-color']"
>
  <a class="upload-files__wrapper" [href]="docHref" (click)="clickOnLink()" target="_blank">
    <img *ngIf="isImage" class="upload-files__icon" [src]="srcImg" alt="">
    <div *ngIf="!isImage" class="upload-files__icon-default background-primary-color">
      <p class="upload-files__icon-extension">{{extension}}</p>
    </div>
    <span *ngIf="isRemoveFromFilesDisabled" class="upload-files__text document-item__text">{{fileName}}</span>
    <button *ngIf="!isRemoveFromFilesDisabled" (click)="changeName($event)" type="button"
            class="upload-files__text document-item__text document-item__text-button">
      {{fileName}}</button>
  </a>
  <div *ngIf="!isRemoveFromFilesDisabled">
    <button class="upload-files__delete" (click)="remove.emit()" type="button">
      <i class="icon icon--cross"></i>
    </button>
  </div>
  <div class="document-item__edit"
       [ngClass]="{'document-item__edit--focus': editMode}">
    <input #edit
           [(ngModel)]="editName"
           (blur)="editSend()"
           (change)="editSend()">
  </div>
</li>

