import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DepositService} from '../../services/deposit.service';
import {Deposit} from '../../models/deposit.class';
import {ReadOnlyService} from '../../../../shared/read-only/read-only.service';
import {MdDialog} from '@angular/material';
import {DEPOSIT_STATUSES} from '../../values/deposit-statuses.const';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {MapDialogComponent} from '../../dialogs/view-map/view-map.component';
import {FormControl} from '@angular/forms';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {ValidationService} from '../../../../shared/services/validation-service';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {Router} from '@angular/router';
import {ConfirmDialogComponent} from '../../../../shared/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {IParentEvent} from '../../../../shared/interfaces/parent-event.interface';
import {setTextareaHeightToFitContent} from '../../../../shared/helpers/set-textarea-height-to-fit-content';
import {DepositTagLocationJunctionService} from '../../services/deposit-tag-location-junction.service';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {DepositTagLocationJunction} from '../../models/deposit-tag-location-junction.class';
import {combineLatest} from 'rxjs';
import {DEPOSIT_REUSE_SOURCE_VIEW, EDepositReuseSource} from '../../values/deposit-reuse-source.const';
import { PASSPORT_UNIT_WITHOUT_CONVERSION } from '../../../passport/values/passport-units.const';

@Component({
  moduleId: module.id,
  selector: 'deposit-details',
  templateUrl: 'deposit-details.component.html',
  styleUrls: ['deposit-details.component.scss'],
  providers: [ReadOnlyService],
})
export class DepositDetailsComponent implements OnInit {
  PASSPORT_UNIT_WITHOUT_CONVERSION = PASSPORT_UNIT_WITHOUT_CONVERSION;
  parentModule: string;
  moduleNames = ModuleNames;
  modificationModalIsVisible: boolean;
  modificationIsNull: boolean;
  eventStream$: EventEmitter<IParentEvent> = new EventEmitter();
  @Output() action = new EventEmitter();
  @ViewChild('detailsComment', { read: ElementRef }) commentsTextarea: ElementRef;
  @ViewChild('detailsDescription', { read: ElementRef }) descriptionTextarea: ElementRef;
  @ViewChild('productCharacteristics', { read: ElementRef }) productCharacteristicsTextarea: ElementRef;
  @ViewChild('color', { read: ElementRef }) colorTextarea: ElementRef;
  @Input() readOnly: boolean;
  @Input() id: number;
  @Input() showValidationButtons: boolean;
  @Input() isDepositOwner: boolean;

  public deposit: Deposit;
  public depositTags: DepositTagLocationJunction[];
  public totalControl = new FormControl(null,
    [
      ValidationService.validateQuantity,
      (control: FormControl) => {
        if (!this.deposit) {
          return null;
        }
        const normalizedQuantity = ValidationService.normalizeQuantity(control.value);
        if (!control.value || normalizedQuantity < this.deposit.booked) {
          return { 'totalLessBooked': true };
        } else {
          const floatString = this.toStringFloatRounded(control.value);
          if (floatString) {
            control.setValue(floatString);
          }
          return null;
        }
      },
    ]
  );

  constructor(private depositService: DepositService,
              public readOnlyService: ReadOnlyService,
              private navigationService: NavigationService,
              private mdDialog: MdDialog,
              public notification: LogNotificationService,
              private router: Router,
              private translate: TranslateService,
              private dialog: MdDialog,
              private tagJunctionService: DepositTagLocationJunctionService) {
    this.parentModule = this.navigationService.getModuleName();
  }

  ngOnInit() {
    this.readOnlyService.readOnly = this.readOnly;
    const observerTags = this.tagJunctionService.get({deposit_id: this.id}).pipe(catchError(() => Observable.of([])))
      .map((tagJunction: DepositTagLocationJunction[]) => this.depositTags = tagJunction);
    const observerDeposit = this.depositService.view(
      this.id, {
        expand: 'passport,nomenclature,passport_components,passport_materials,creator,deposit_location' +
          ',deposit_photos,passport.category,passport.gtins,url_link,color,product_comment,deposit_file',
      }).map((res: Deposit) => {
      this.deposit = res;
      this.deposit.loaded = true;
      this.totalControl.setValue(res.total);
      this.checkStatusNotificationUpdateOptions();
      setTimeout(() => setTextareaHeightToFitContent(
        [this.commentsTextarea, this.descriptionTextarea, this.productCharacteristicsTextarea, this.colorTextarea]
      ));
    });
    combineLatest([observerTags, observerDeposit]).subscribe();
  }

  private checkStatusNotificationUpdateOptions() {
    if (this.isDepositOwner) {
      if ( this.deposit.status === DEPOSIT_STATUSES.PUBLISHED.value ||
        this.deposit.status === DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION.value) {
          // console.log('published or waiting for publication');
          this.readOnly = false ;
          this.readOnlyService.readOnly = this.readOnly;
          this.showValidationButtons = false ;
        }
        if (this.deposit.status === DEPOSIT_STATUSES.WAITING_FOR_VALIDATION.value ) {
          // console.log('waiting for validation');
          this.readOnly = true ;
          this.readOnlyService.readOnly = this.readOnly;
          this.showValidationButtons = true ;
      }
    }
  }

  public getNumeric(field: number, unit: string) {
    return this.isNum(field) ? `${field} ${unit}` : '';
  }

  private isNum(field) {
    return !!field || field === 0;
  }

  public getSource(reused: boolean, source: number) {
    return DEPOSIT_REUSE_SOURCE_VIEW[reused ? source : EDepositReuseSource.NO_SOURCE];
  }

  public  potentialBecoming(value: boolean) {
    return value ? 'Yes' : 'No';
  }

  private toStringFloatRounded(value: string, precision = 2) {
    // Calculate quantity with avoiding precision error
    // this.deposit.quantity = (Math.floor(normalizedQuantity * 100) - this.deposit.booked * 100) / 100;
    const valueStr = String(value);
    const usedSeparator = valueStr.indexOf(',') > -1 ? ',' : '.';
    const valueSplit = valueStr.split(usedSeparator);
    // Limit value to have maximum 2 decimals
    if (valueSplit.length > 1 && valueSplit[1].length > precision) {
      return valueSplit[0] + usedSeparator + valueSplit[1].slice(0, precision);
    }
    return null;
  }

  cancel() {
    this.action.emit();
  }

  save() {
    // TODO use error codes for translation
    if (this.totalControl.hasError('totalLessBooked')) {
      this.notification.error('Total quantity must be more than booked', null, true);
    } else if (this.totalControl.hasError('invalidQuantity')) {
      this.notification.error('Quantity must be a number.', null, true);
    }

    if (this.totalControl.invalid) {
      return;
    }
    this.deposit.quantity = this.totalControl.value ;
    this.deposit.save().subscribe((res) => {
      console.log('RES', res);
      this.action.emit();
      this.eventStream$.emit({key: 'savePassport', data: this.deposit.passport});
    });
  }

  validate() {
    this.deposit.publish().subscribe(() => {
      this.action.emit();
      this.eventStream$.emit({key: 'savePassport', data: this.deposit.passport});
    });
  }

  reject() {
    this.deposit.reject().subscribe(() => {
      this.action.emit();
    });
  }

  askForModification() {
    if (!this.deposit.modification) {
      this.modificationIsNull = true;
      return;
    }
    this.deposit.askForModification().subscribe(() => {
      this.action.emit();
    });
  }

  checkStatusValidated() {
    if (this.navigationService.getContext().moduleName === ModuleNames.SUPERVISOR) {
      return this.deposit.status !== DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION.value;
    }

    if (this.navigationService.getContext().moduleName === ModuleNames.DEPOSIT_OWNER) {
      return this.deposit.status !== DEPOSIT_STATUSES.WAITING_FOR_VALIDATION.value;
    }

    return true;
  }

  changeVisibilityOfModificationModal() {
    this.deposit.modification = '';
    this.modificationModalIsVisible = !this.modificationModalIsVisible;
  }

  modificationChanged() {
    this.modificationIsNull = !this.deposit.modification;
  }

  onShowMapClick() {
    // this.depositService.selectedDepositForMap.next(this.deposit);
    // this.action.emit();

    if (!!this.deposit.location && !!this.deposit.location.lat && !!this.deposit.location.lng) {
       this.mdDialog.open(MapDialogComponent, {
        data: {
          readonlyMode: true,
          coords: {
            lat: this.deposit.location.lat,
            lng: this.deposit.location.lng,
          },
          address: this.deposit.location.address,
        }
      });
    }
  }
  onSeeFullPassport() {
    this.translate.get('CONFIRM_OPEN_PASSPORT_PAGE')
    .subscribe(( translation ) => {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          text: translation
        }
      }).afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate([this.parentModule + '/passports', this.deposit.passport.id]);
        }
      });
    });
  }
}
