import * as tslib_1 from "tslib";
import { StatsContainer } from './stats-container.class';
import { EcosystemStats } from './ecosystem-stats.class';
var EcosystemStatsContainer = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemStatsContainer, _super);
    function EcosystemStatsContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EcosystemStatsContainer.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
        return json;
    };
    EcosystemStatsContainer.prototype.createModel = function (json, key) {
        key.forEach(function (k) {
            if (!!json[k]) {
                json[k] = json[k].map(function (data) { return new EcosystemStats(data); });
            }
        });
    };
    EcosystemStatsContainer.prototype.getCreatedTotals = function () {
        var _this = this;
        var result = {};
        var keys = ['total', 'last_year', 'last_month', 'last_week'];
        keys.forEach(function (key) {
            var created = 0;
            if (_this[key]) {
                _this[key].forEach(function (item) {
                    if (item.ecosystems_created) {
                        created += item.ecosystems_created;
                    }
                });
            }
            result[key] = created;
        });
        return result;
    };
    EcosystemStatsContainer.prototype.getDeletedTotals = function () {
        var _this = this;
        var result = { percentage: {} };
        var keys = ['total', 'last_year', 'last_month', 'last_week'];
        keys.forEach(function (key) {
            var deleted = 0, created = 0;
            if (_this[key]) {
                _this[key].forEach(function (item) {
                    if (item.ecosystems_deleted) {
                        deleted += item.ecosystems_deleted;
                    }
                    if (item.ecosystems_created) {
                        created += item.ecosystems_created;
                    }
                });
            }
            result[key] = deleted;
            result.percentage[key] = _this.calculatePercentage(deleted, created);
        });
        return result;
    };
    EcosystemStatsContainer.prototype.getModifiedTotals = function () {
        var _this = this;
        var result = { percentage: {} };
        var keys = ['total', 'last_year', 'last_month', 'last_week'];
        keys.forEach(function (key) {
            var modified = 0, created = 0;
            if (_this[key]) {
                _this[key].forEach(function (item) {
                    if (item.ecosystems_modified) {
                        modified += item.ecosystems_modified;
                    }
                    if (item.ecosystems_created) {
                        created += item.ecosystems_created;
                    }
                });
            }
            result[key] = modified;
            result.percentage[key] = _this.calculatePercentage(modified, created);
        });
        return result;
    };
    EcosystemStatsContainer.prototype.getDoneTotals = function () {
        var _this = this;
        var result = {};
        var keys = ['total', 'last_year', 'last_month', 'last_week'];
        keys.forEach(function (key) {
            var done = 0;
            if (_this[key]) {
                _this[key].forEach(function (item) {
                    if (item.ecosystems_done) {
                        done += item.ecosystems_done;
                    }
                });
            }
            result[key] = done;
        });
        return result;
    };
    EcosystemStatsContainer.prototype.getInProcessTotals = function () {
        var _this = this;
        var result = {};
        var keys = ['total', 'last_year', 'last_month', 'last_week'];
        keys.forEach(function (key) {
            var done = 0;
            if (_this[key]) {
                _this[key].forEach(function (item) {
                    if (item.ecosystems_in_process) {
                        done += item.ecosystems_in_process;
                    }
                });
            }
            result[key] = done;
        });
        return result;
    };
    EcosystemStatsContainer.prototype.getInProcessEvolution = function () {
        var result = [];
        if (this.total.length) {
            result.push(['Date', 'In process']);
        }
        this.total.forEach(function (item) {
            result.push([new Date(item.y, item.m - 1, 1), item.ecosystems_in_process || 0]);
        });
        return result;
    };
    EcosystemStatsContainer.prototype.getCanceledTotals = function () {
        var _this = this;
        var result = {};
        var keys = ['total', 'last_year', 'last_month', 'last_week'];
        keys.forEach(function (key) {
            var done = 0;
            if (_this[key]) {
                _this[key].forEach(function (item) {
                    if (item.ecosystems_cancelled) {
                        done += item.ecosystems_cancelled;
                    }
                });
            }
            result[key] = done;
        });
        return result;
    };
    EcosystemStatsContainer.prototype.getCanceledEvolution = function () {
        var result = [];
        if (this.total.length) {
            result.push(['Date', 'Canceled']);
        }
        this.total.forEach(function (item) {
            result.push([new Date(item.y, item.m - 1, 1), item.ecosystems_cancelled || 0]);
        });
        return result;
    };
    EcosystemStatsContainer.prototype.calculatePercentage = function (value, total) {
        if (total === 0) {
            return '0%';
        }
        return (value / total * 100).toFixed(1) + '%';
    };
    return EcosystemStatsContainer;
}(StatsContainer));
export { EcosystemStatsContainer };
