/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../partials/tree-view/tree-view.component.ngfactory";
import * as i2 from "ngx-perfect-scrollbar";
import * as i3 from "../../partials/tree-view/tree-view.component";
import * as i4 from "../../service/nomenclature.service";
import * as i5 from "./select-dialog.component";
import * as i6 from "@angular/material";
var styles_SelectDialogComponent = ["nomenclature-tree-view[_ngcontent-%COMP%] {\n        height: 100%;\n        display: block;\n      }"];
var RenderType_SelectDialogComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SelectDialogComponent, data: {} });
export { RenderType_SelectDialogComponent as RenderType_SelectDialogComponent };
export function View_SelectDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "nomenclature-tree-view", [], null, [[null, "onSave"], [null, "ps-scroll-y"], [null, "ps-scroll-x"], [null, "ps-scroll-up"], [null, "ps-scroll-down"], [null, "ps-scroll-left"], [null, "ps-scroll-right"], [null, "ps-y-reach-end"], [null, "ps-y-reach-start"], [null, "ps-x-reach-end"], [null, "ps-x-reach-start"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ps-scroll-y" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).psScrollY($event) !== false);
        ad = (pd_0 && ad);
    } if (("ps-scroll-x" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).psScrollX($event) !== false);
        ad = (pd_1 && ad);
    } if (("ps-scroll-up" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).psScrollUp($event) !== false);
        ad = (pd_2 && ad);
    } if (("ps-scroll-down" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).psScrollDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("ps-scroll-left" === en)) {
        var pd_4 = (i0.ɵnov(_v, 1).psScrollLeft($event) !== false);
        ad = (pd_4 && ad);
    } if (("ps-scroll-right" === en)) {
        var pd_5 = (i0.ɵnov(_v, 1).psScrollRight($event) !== false);
        ad = (pd_5 && ad);
    } if (("ps-y-reach-end" === en)) {
        var pd_6 = (i0.ɵnov(_v, 1).psReachEndY($event) !== false);
        ad = (pd_6 && ad);
    } if (("ps-y-reach-start" === en)) {
        var pd_7 = (i0.ɵnov(_v, 1).psReachStartY($event) !== false);
        ad = (pd_7 && ad);
    } if (("ps-x-reach-end" === en)) {
        var pd_8 = (i0.ɵnov(_v, 1).psReachEndX($event) !== false);
        ad = (pd_8 && ad);
    } if (("ps-x-reach-start" === en)) {
        var pd_9 = (i0.ɵnov(_v, 1).psReachStartX($event) !== false);
        ad = (pd_9 && ad);
    } if (("onSave" === en)) {
        var pd_10 = (_co.mdDialogRef.close($event) !== false);
        ad = (pd_10 && ad);
    } return ad; }, i1.View_TreeViewComponent_0, i1.RenderType_TreeViewComponent)), i0.ɵdid(1, 999424, null, 0, i2.PerfectScrollbarDirective, [i0.NgZone, i0.KeyValueDiffers, i0.ElementRef, i0.PLATFORM_ID, [2, i2.PERFECT_SCROLLBAR_CONFIG]], null, null), i0.ɵdid(2, 245760, null, 0, i3.TreeViewComponent, [i4.NomenclatureService], null, { onSave: "onSave" })], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 2, 0); }, null); }
export function View_SelectDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_SelectDialogComponent_0, RenderType_SelectDialogComponent)), i0.ɵdid(1, 49152, null, 0, i5.SelectDialogComponent, [i6.MdDialogRef], null, null)], null, null); }
var SelectDialogComponentNgFactory = i0.ɵccf("ng-component", i5.SelectDialogComponent, View_SelectDialogComponent_Host_0, {}, {}, []);
export { SelectDialogComponentNgFactory as SelectDialogComponentNgFactory };
