import { NgModule } from '@angular/core';
import { PassportModule } from '../../entities/passport/passport.module';
import { SharedModule } from '../../shared/shared.module';
import { NeedModule } from '../../entities/need/need.module';
import { DepositModule } from '../../entities/deposit/deposit.module';
import { TransformationModule } from '../../entities/transformation/transformation.module';
import { RouterModule } from '@angular/router';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';
import {MANUFACTURER_CONTEXT} from './manufacturer.context';
import {MANUFACTURER_ROUTES} from './manufacturer.routes';
import {ManufacturerGuard} from './manufacturer.guard';
import { FormsModule } from '@angular/forms';
import { ManufacturerDashboardComponent } from './interfaces/manufacturer-dashboard.component';
import { IconsModule } from '../../app/icons.module';
import { BaseComponentsModule } from '../../app/components/base/base-components.module';
import { GenericDeleteWarningComponent } from '../../app/components/base/dialog/delete-warning/generic-delete-warning.component';
import { FilterComponentsModule } from './components/filter.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([{
      path: MANUFACTURER_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: MANUFACTURER_ROUTES
    }]),
    PassportModule,
    DepositModule,
    TransformationModule,
    NeedModule,
    FormsModule,
    IconsModule,
    BaseComponentsModule,
    FilterComponentsModule
  ],
  declarations: [
    ManufacturerDashboardComponent,
  ],
  exports: [
    ManufacturerDashboardComponent
  ],
  providers: [
    ManufacturerGuard
  ],
  entryComponents: [
    GenericDeleteWarningComponent
  ]
})
export class ManufacturerModule {

}
