import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DepositLocation} from '../../models/deposit-location.class';
import {ReadOnlyService} from '../../../../shared/read-only/read-only.service';
import {Router} from '@angular/router';
import {MdDialogRef} from '@angular/material';
import {BIMService} from '../../../../shared/services/bim.service';
import {BIM} from '../../../../shared/models/bim.class';
import {DepositLocationFile} from '../../models/deposit-location-file.class';
import {DepositLocationFileService} from '../../services/deposit-location-file.service';
import {CurrentUserService} from '../../../../shared/services/current-user.service';
import {UserService} from '../../../../shared/services/user.service';
import {OrganisationTIds} from '../../../../shared/values/company-values.enum';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {ERoleIds} from '../../../../shared/values/role-ids.enum';
import moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'deposit-site-details',
  templateUrl: 'deposit-site-details.component.html',
  styleUrls: ['deposit-site-details.component.scss'],
  providers: [ReadOnlyService],
})
export class DepositSiteDetailsComponent implements OnInit {
  @Input() id: number;
  @Input() readOnly = false;
  @Input() type?: OrganisationTIds;
  @Input() userId: number;
  @Output() onSave = new EventEmitter();
  public files: DepositLocationFile[] = [];
  location: DepositLocation = new DepositLocation;
  isCompanyBuilding: boolean;
  isViewModeRestricted = false;
  BIMs: BIM[] = [];
  onDocumentSave$: EventEmitter<string> = new EventEmitter();
  onUpdateUploadUrl$: EventEmitter<string> = new EventEmitter();

  constructor(private router: Router,
              private navigation: NavigationService,
              public readOnlyService: ReadOnlyService,
              public mdDialogRef: MdDialogRef<void>,
              public BIMService: BIMService,
              private userService: UserService,
              private _currentUserService: CurrentUserService,
              public depositLocationFileService: DepositLocationFileService,
              ) {}

  ngOnInit() {
    this.preloadUploadedItems();
    this.readOnlyService.readOnly = this.readOnly;
    this.BIMService.get({expand: 'name'}).subscribe((BIMs: BIM[]) => this.BIMs = BIMs);
    this.location.id = this.id;
    this.location.user_id = this.userId || null;
    const isTypeBuilding = (type) => this.isCompanyBuilding = type === OrganisationTIds.Building;
    this.type ? isTypeBuilding(this.type) : this.getSiteType().subscribe(type => {
      isTypeBuilding(type);
      this.isViewModeRestricted = this.navigation.roleId !== ERoleIds.SUPERADMIN && this.isCompanyBuilding;
    });
    if (this.location.id) {
      this.location.load({expand: 'active'}).subscribe((location: DepositLocation) => {
            this.location = location;
            this.location.loaded = true;
          });
    }
  }

  private getSiteType() {
    return this.userService.view(this._currentUserService.infoData.id, {expand: 'company'})
      .map(user => {
       return user.company.organisation_type;
      });
  }

  preloadUploadedItems() {
    if (this.id) {
      this.depositLocationFileService
      .get<File>({ deposit_location_id: this.id, expand: 'basename,file,type' })
      .subscribe(
        result => {
          for (const item of result) {
            this.files.push(new DepositLocationFile(item));
          }
        }
      );
    }
  }

  save() {
    this.location.save().subscribe((location: DepositLocation) => {
      this.onSave.emit(location);
      this.onUpdateUploadUrl$.emit(location.getFileUploadUrl());
      this.onDocumentSave$.emit();
    });
  }
}
