import {IColumnOptions, IControlMeta} from '../../../../../../import-spreadsheet/values/interfaces/column-meta.interface';
import {COLUMNS_MAPPING} from './deposit-import-synonym';
import {TDocumentControls} from '../../../../../../import-spreadsheet/values/import-document-controls.const';
import {DepositImport} from '../../../../../../entities/deposit/models/deposit-import.class';


export type TColumnsKeys = Partial<keyof DepositImport>;


export const DEPOSIT_IMPORT_COLUMNS: { [x in TColumnsKeys]?: IColumnOptions } = {
  deposit_name_field: {
    name: 'DEPOSIT_NAME_FIELD',
    order: COLUMNS_MAPPING.DEPOSIT_NAME,
    showWarningSign: false
  },
  passport_id_field: {
    name: 'Passport ID',
    description: 'The passport and its ID must previously exist in myUpcyclea',
    order: COLUMNS_MAPPING.PASSPORT_ID
  },
  site_name_field: {
    order: COLUMNS_MAPPING.SITE_NAME
    , name: 'Site name'
    , description: 'The site name must previously exist in myUpcyclea'
    , additionalDescription: 'The name of the site must be exact'
  },
  tags_field: {
    name: 'Tag(s)',
    description: 'Tags must previously exist in myUpcyclea and belong to the site you selected.',
    additionalDescription: 'Tags must be separated by commas.',
    order: COLUMNS_MAPPING.TAG
  },
  quantity_field: {
    name: 'Quantity',
    description: 'Must be a number.',
    order: COLUMNS_MAPPING.QUANTITY
  },
  unit_field: {
    name: 'Unit',
    description: 'Unit sizes : piece / kg / ton / L / m / m2 / m3',
    additionalDescription: 'The unit must be identical to that of the associated passport',
    order: COLUMNS_MAPPING.UNIT},
  availability_date_field: {
    name: 'Availability date',
    description: 'Format DD/MM/YYYYY'
    , order: COLUMNS_MAPPING.AVAILABLITY_DATE
  },
  availability_expiration_field: {
    name: 'Expiration date',
    order: COLUMNS_MAPPING.EXPIRATION_DATE,
    description: 'Format DD/MM/YYYYY'
  },
  deposit_origin_field: {
    name: 'DEPOSIT_ORIGIN_FIELD',
    description: 'DEPOSIT_ORIGIN_DESCRIPTION',
    order: COLUMNS_MAPPING.SOURCE
  },
  potential_becoming_field: {
    name: 'POTENTIAL_BECOMING_FIELD',
    description: 'POTENTIAL_BECOMING_DESCRIPTION',
    order: COLUMNS_MAPPING.POTENTIAL_BECOMING
  },
  demountability_field: {
    name: 'Demountability / separability',
    description: 'DEMOUNTABILITY_DESCRIPTION',
    order: COLUMNS_MAPPING.DEMOUNTABILITY
  },
  state_field: {
    name: 'State of use',
    order: COLUMNS_MAPPING.STATE_OF_USE,
    description: 'STATE_OF_USE_DESCRIPTION'
  },
  state_description_field: {
    name: 'State description',
    description: 'Fields are limited to 65535 characters',
    order: COLUMNS_MAPPING.STATE_DESCRIPTION
  },
  resale_price_field: {
    name: 'RESALE_PRICE_FIELD',
    description: 'RESALE_PRICE_DESCRIPTION',
    order: COLUMNS_MAPPING.RESALE_PRICE
  },
  resale_url_field: {
    order: COLUMNS_MAPPING.LINK
    , name: 'RESALE_URL_FIELD'
    , description: 'RESALE_URL_DESCRIPTION'
  },
  product_characteristics_field: {
    name: 'PRODUCT_CHARACTERISTICS_FIELD'
    , className: 'deposit-import-column__input--wide'
    , description: 'PRODUCT_CHARACTERISTICS_DESCRIPTION'
    , columnLetter: 'P, Q, R ...'
    , multiInput: true
    , order: COLUMNS_MAPPING.ADDITIONAL_PRODUCT
  },
  length_field: {
    hideDefault: true
    , order: COLUMNS_MAPPING.LENGTH
    , description: 'DIMENSION_DESCRIPTION'
  },
  apperance_field: {
    name: 'APPEARANCE_FIELD',
    showWarningSign: false,
    order: COLUMNS_MAPPING.PRODUCT_APPEARANCE
  },
  conversion_field: {
    name: 'CONVERSION_FIELD',
    description: 'CONVERSION_DESCRIPTION',
    order: 99
  },
  comments_field: {
    name: 'Comments',
    columnLetter: 'V, W, X ...',
    className: 'deposit-import-column__input--wide',
    multiInput: true,
    description: 'PRODUCT_CHARACTERISTICS_DESCRIPTION',
    order: COLUMNS_MAPPING.COMMENTS
  },
  photo_field: {
    name: 'PHOTO_FIELD',
    description: 'RESALE_URL_DESCRIPTION',
    additionalDescription: 'DOCUMENTS_DESCRIPTION',
    order: COLUMNS_MAPPING.PHOTOS
  },
  documents_field: {
    name: 'DOCUMENTS_FIELD',
    description: 'RESALE_URL_DESCRIPTION',
    additionalDescription: 'DOCUMENTS_DESCRIPTION',
    order: COLUMNS_MAPPING.DOCUMENTS
  },
};

export const DEPOSIT_IMPORT_CONTROL_OPTIONS: {[x in TColumnsKeys]?: any} = {
  deposit_name_field: [''],
  passport_id_field: [''],
  site_name_field: [''],
  tags_field: [''],
  quantity_field: [''],
  unit_field: [''],
  availability_date_field: [''],
  availability_expiration_field: [''],
  deposit_origin_field: [''],
  potential_becoming_field: [''],
  reference_date: [''],
  state_field: [''],
  state_description_field: [''],
  demountability_field: [''],
  comments_field: [''],
  reused_field: [''],
  resale_price_field: [''],
  resale_url_field: [''],
  product_characteristics_field: [''],
  length_field: [''],
  width_field: [''],
  height_field: [''],
  apperance_field: [''],
  photo_field: [''],
  documents_field: [''],
  conversion_field: [''],
};

type TDepositImportControlMeta = { [x in (TColumnsKeys | TDocumentControls)]?: IControlMeta  };
export let DEPOSIT_IMPORT_CONTROL_META: TDepositImportControlMeta = {
  deposit_name_field: {error: 'DEPOSIT_NAME_FIELD', type: COLUMNS_MAPPING.DEPOSIT_NAME},
  passport_id_field: {error: 'Passport ID', type: COLUMNS_MAPPING.PASSPORT_ID},
  tags_field: {error: 'Tag(s)', type: COLUMNS_MAPPING.TAG},
  site_name_field: {error: 'Site Name', type: COLUMNS_MAPPING.SITE_NAME},
  quantity_field: {error: 'Quantity', type: COLUMNS_MAPPING.QUANTITY},
  unit_field: {error: 'Unit', type: COLUMNS_MAPPING.UNIT},
  availability_date_field: {error: 'Availability date', type: COLUMNS_MAPPING.AVAILABLITY_DATE},
  availability_expiration_field: {error: 'Expiration date', type: COLUMNS_MAPPING.EXPIRATION_DATE},
  reference_date: {error: 'Reference date', type: COLUMNS_MAPPING.REFERENCE_DATE},
  state_field: {error: 'State', type: COLUMNS_MAPPING.STATE_OF_USE},
  state_description_field: {error: 'Description of the state', type: COLUMNS_MAPPING.STATE_DESCRIPTION},
  comments_field: {error: 'Comments', type: COLUMNS_MAPPING.COMMENTS},
  reused_field: {error: 'Reused', type: COLUMNS_MAPPING.REUSED_FIELD},
  resale_price_field: {error: 'RESALE_PRICE_FIELD', type: COLUMNS_MAPPING.RESALE_PRICE},
  resale_url_field: {error: 'RESALE_URL_FIELD', type: COLUMNS_MAPPING.RESALE_URL},
  product_characteristics_field: {error: 'PRODUCT_CHARACTERISTICS_FIELD', type: COLUMNS_MAPPING.PRODUCT_CHARACTERISTICS_FIELD},
  length_field: {error: 'LENGTH_FIELD', type: COLUMNS_MAPPING.LENGTH},
  photo_field: {error: 'PHOTO_FIELD', type: COLUMNS_MAPPING.PHOTOS},
  documents_field: {error: 'DOCUMENTS_FIELD', type: COLUMNS_MAPPING.DOCUMENTS},
  deposit_origin_field: {error: 'DEPOSIT_ORIGIN_FIELD', type: COLUMNS_MAPPING.SOURCE},
  potential_becoming_field: {error: 'POTENTIAL_BECOMING_FIELD', type: COLUMNS_MAPPING.POTENTIAL_BECOMING},
  demountability_field: {error: '', type: COLUMNS_MAPPING.DEMOUNTABILITY},
  width_field: {error: 'WIDTH_FIELD', type: COLUMNS_MAPPING.WIDTH},
  height_field: {error: 'HEIGHT_FIELD', type: COLUMNS_MAPPING.HEIGHT},
  apperance_field: {error: 'APPEARANCE_FIELD', type: COLUMNS_MAPPING.PRODUCT_APPEARANCE},
  conversion_field: {error: '', type: COLUMNS_MAPPING.CONVERSION_FACTOR},
  tab_name: {error: '', type: COLUMNS_MAPPING.TAB_IMPORT},
  first_row: {error: '', type: COLUMNS_MAPPING.FIRST_ROW},
  last_row: {error: '', type: COLUMNS_MAPPING.LAST_ROW},
};

