import { Component, ElementRef, OnInit } from '@angular/core';
import { Config } from '../shared/config/env.config';
import './operators';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { CurrentUserService } from '../shared/services/current-user.service';
import { MdDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../shared/services/navigation.service';
import {DateTimePickerTranslationService} from '../shared/datetime-translation/datetime-translation';
import { LanguageService } from '../entities/client/service/language.service';
import {PermissionService} from '../shared/services/permission.service';
import {User} from '../shared/models/user.class';

/*
 * This class represents the main application component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  constructor(public navigation: NavigationService,
              private permission: PermissionService,
              private _userService: CurrentUserService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private mdDialog: MdDialog,
              private _translateService: TranslateService,
              private dateTimePicker: DateTimePickerTranslationService,
              private languageService: LanguageService) {
    console.log('Environment config', Config);
    const languages = languageService.availableLanguages;
    this.setupTranslations(languages);

    this._translateService.addLangs(languages);
    this._translateService.setDefaultLang('fr');
    this.dateTimePicker.setLocal('fr');

    this._translateService.use('fr');
  }

  ngOnInit(): void {
    this
      .navigation
      .changeModule
      .subscribe((module: string) => {
        console.log('changeModule', module);
        this.setModuleClass(this.navigation.getModuleClass());
        this.permission.setDefaultPermissionOnContextChange(
          new User(this._userService.infoData) , this.navigation.roleId);
      });

    this
      .router
      .events
      .subscribe((val: NavigationStart) => this.mdDialog.closeAll());

      this
      .navigation
      .changeModule.emit();
  }

  setupTranslations(languages) {
    for (const lang of languages) {
      this._translateService.setTranslation(lang, require('../assets/i18n/' + lang + '.json'));
    }
  }

  setModuleClass(classname: string) {
    this.elRef.nativeElement.className = classname;
  }
}
