import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MAIN_TAB_STEPS } from '../../admin-reporting.const';
import { ReportBase } from '../report-base/report-base.class';
import { MaterialPieCharData } from '../../../../services/material-pie-char-data.service';
var MaterialHealthComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MaterialHealthComponent, _super);
    function MaterialHealthComponent(materialPieCharData) {
        var _this = _super.call(this) || this;
        _this.materialPieCharData = materialPieCharData;
        _this.isDescriptionBlockVisible = true;
        _this.filteredProductCategory = {};
        return _this;
    }
    MaterialHealthComponent.prototype.hideDescriptionBlock = function () {
        this.isDescriptionBlockVisible = false;
    };
    MaterialHealthComponent.prototype.ngOnInit = function () {
        this.prepareMainChartData();
        this.prepareSecondaryChartsData();
    };
    MaterialHealthComponent.prototype.prepareMainChartData = function () {
        this.mainChartData = this.materialPieCharData.getRoundPieChartData({ mainData: this.report.report_result.material_health.aggregate });
        this.minDepositsConsidered = this.report.report_result.material_health.aggregate.total_deposit_considered;
        this.maxDepositsConsidered = this.report.report_result.material_health.aggregate.total_deposit_count;
        this.tonsOfDeposits = this.report.report_result.material_health.aggregate.total_weight;
    };
    MaterialHealthComponent.prototype.prepareSecondaryChartsData = function () {
        this.setHealthiestProduct();
        this.setMostToxicProduct();
        this.setMostImportantProduct();
    };
    MaterialHealthComponent.prototype.setHealthiestProduct = function () {
        this.healthiestProductChartData = this.materialPieCharData.getRoundPieChartData({ product: this.getProductByHealthiness('MOST') });
    };
    MaterialHealthComponent.prototype.getProductByHealthiness = function (type) {
        var products = this.orderProductsBy('healthiness');
        if (!!products.length) {
            switch (type) {
                case 'LEAST':
                    return products[products.length - 1];
                case 'MOST':
                    return products[0];
            }
        }
    };
    MaterialHealthComponent.prototype.setMostToxicProduct = function () {
        this.mostToxicProductChartData = this.materialPieCharData.getRoundPieChartData({ product: this.getProductByHealthiness('LEAST') });
    };
    MaterialHealthComponent.prototype.setMostImportantProduct = function () {
        var product = this.orderProductsBy('weight')[0];
        this.mostImportantProductChartData = this.materialPieCharData.getRoundPieChartData({ product: product });
    };
    MaterialHealthComponent.prototype.orderProductsBy = function (orderBy) {
        var categories = this.report.report_result.material_health.aggregate.category;
        if (!!categories) {
            var categoriesFiltered = this.filteredProductCategory = tslib_1.__assign({}, Object.values(categories).filter(function (e) { return !!e['deposits_considered']; }));
            return Object.values(categoriesFiltered).sort(function (a, b) { return a[orderBy] < b[orderBy] ? 1 : (b[orderBy] < a[orderBy] ? -1 : 0); });
        }
    };
    MaterialHealthComponent.prototype.isEmptyProductCategory = function () {
        return Object.keys(this.filteredProductCategory).length === 0;
    };
    MaterialHealthComponent.prototype.goToArchive = function () {
        this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
    };
    return MaterialHealthComponent;
}(ReportBase));
export { MaterialHealthComponent };
