/**
 * Created by simo on 31.10.19.
 */

import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
import { ILiteral } from 'ng2-adn-common';
import { GenericPassport } from '../models/generic-passport.class';
import { GenericPassportService } from '../service/generic-passport.service';


@Injectable()
export class GenericPassportResolver extends EntityResolver<GenericPassport> implements Resolve<GenericPassport> {

  protected query: ILiteral = {
    expand: 'category,materials,waste_category,tags'
  };

  constructor(protected _service: GenericPassportService) {
    super();
  }
}
