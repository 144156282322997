export class Modification {
  constructor(data: any) {
    [
      'type',
      'item_id',
      'modified',
    ].forEach((field) => {
      (<any>this)[field] = data[field];
    });
  }

  public type: number;
  public item_id: number;
  public modified: string;
}
