/**
 * Created by atat on 18/06/2017.
 */

import { Component, EventEmitter, OnInit } from '@angular/core';
import {
  ECustomListRecordStatus,
  ICustomListRecord
} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { TransformService } from '../../../../entities/transformation/services/transform.service';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { FormControl } from '@angular/forms';
import {getTransformTypeLcView, TRANSFORM_TYPES} from '../../values/transform-types.const';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TRANSFORM_STATUSES } from '../../values/transform-statuses.const';
import { TransformLocation } from '../../../../entities/transformation/models/transform-location.class';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { MdDialog } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { TransformServiceArea } from '../../../../entities/transformation/models/transform-service-area.class';
import {TranslatePipe} from "@ngx-translate/core";
import {NavigationService} from '../../../../shared/services/navigation.service';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import { CopyTransformationDialogComponent } from '../../../../entities/transformation/dialogs/copy-transformation-dialog/copy-transformation-dialog.component';
import { NavbarStateService } from '../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  providers: [TranslatePipe],
  selector: 'tsm-transformations-catalog',
  templateUrl: 'tsm-transformations-catalog.component.html'
})
export class TsmTransformationsCatalogComponent implements OnInit {

  onChange$: EventEmitter<void> = new EventEmitter<void>();

  sortOptions: any = [
    { field: 'transformation_type', view: 'Type'},
    { field: 'name', view: 'Name'},
    { field: '-created_at', view: 'Creation date'}
  ];
  sortValue: string = 'name';

  filters = TRANSFORM_STATUSES;
  // Filters
  statuses: any = [
    {
      text: TRANSFORM_STATUSES.DRAFT.view,
      value: TRANSFORM_STATUSES.DRAFT.value,
      checked: true,
      status: 'DRAFT'
    },
    {
      text: TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.view,
      value: TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.value,
      checked: true,
      status: 'WAITING_FOR_PUBLICATION'
    },
    {
      text: TRANSFORM_STATUSES.PUBLISHED.view,
      value: TRANSFORM_STATUSES.PUBLISHED.value,
      checked: true,
      status: 'PUBLISHED'
    },
    {
      text: TRANSFORM_STATUSES.ASK_FOR_MODIFICATION.view,
      value: TRANSFORM_STATUSES.ASK_FOR_MODIFICATION.value,
      checked: true,
      status: 'ASK_FOR_MODIFICATION'
    },
  ];

  TRANSFORM_TYPES = TRANSFORM_TYPES;
  type: number;

  search = new FormControl();
  searchValue:string;
  publishedFrom: string;
  publishedTo: string;
  parentModule: string;

  records: ICustomListRecord[] = [];

  private _confirmDeletionText: string;

  constructor(private _dialog: MdDialog,
              private _transformService: TransformService,
              protected _navigationService: NavigationService,
              private _translate: TranslateService,
              private _datepipe: DatePipe,
              private _router: Router,
              private navbarState: NavbarStateService) {
    this._translate.get('CONFIRM_DELETE_TRANSFORMATION').subscribe((translation) => {
      this._confirmDeletionText = translation;
      this.parentModule = this._navigationService.getModuleName();
      if (this.parentModule !== ModuleNames.TSMI) {
        this.statuses.push({
          text: TRANSFORM_STATUSES.REJECTED.view,
          value: TRANSFORM_STATUSES.REJECTED.value,
          checked: false,
          status: 'REJECTED'
        });
      }
    });

    this
      .onChange$
      .switchMap(() => this.fetchRecords())
      .subscribe((records:Transform[]) => this.onRecordsLoaded(records));

    this
      .search
      .valueChanges
      .debounceTime(300)
      .do((value) => this.setSearchValue(value))
      //.distinctUntilChanged()
      .subscribe(() => this.onChange$.emit());

  }

  ngOnInit(): void {
    this.onChange$.emit();
  }

  setSearchValue(value:string) {
    console.log('Update value', value);
    this.searchValue = value;
  }

  fetchRecords() {
    let options: any = {
      expand: 'creator,files,materials,sites,sectors',
      search: this.searchValue || null,
      published_from: this.publishedFrom || null,
      published_to: this.publishedTo || null,
      transformation_type: this.type || null,
      'status[]': this.statuses
        .filter(status => status.checked)
        .map(status => status.value),
      sort: this.sortValue
    }

    if (this.navbarState.multilanguage) {
      options.english = true;
    }

    return this._transformService.get(options);
  }

  onRecordsLoaded(records: Transform[]) {
    this.records.length = 0;
    records.forEach((record: Transform) => this.addRecord(record));
  }

  getRecords() {
    this.onChange$.emit();
  }


  addRecord(transform: Transform) {
    console.log("ADDING RECORD");

    // -nothing for draft
    // -green check for published
    // -red cross for rejected

    let status:ECustomListRecordStatus;
    switch (transform.status) {
      case TRANSFORM_STATUSES.DRAFT.value:
        status = ECustomListRecordStatus.NO_STATUS;
        break;
      case TRANSFORM_STATUSES.PUBLISHED.value:
        status = ECustomListRecordStatus.CONFIRM;
        break;
      case TRANSFORM_STATUSES.REJECTED.value:
        status = ECustomListRecordStatus.CANCELED;
        break;
      case TRANSFORM_STATUSES.ASK_FOR_MODIFICATION.value:
        status = ECustomListRecordStatus.QUESTION;
        break;
      default:
        status = ECustomListRecordStatus.NO_STATUS;
    }

    this.records.push(
      this.createTransformationRecord(
        status,
        transform.transformation_type_data.value ? getTransformTypeLcView(transform.transformation_type_data.value) : '',
        transform.viewName,
        transform.transformation_type_data.view.replace(/./, c => c.toUpperCase()),

        transform.inputMaterials.map((material) => {return this._translate.instant(material.name)}).join(', '),
        transform.outputMaterials.map((material) =>  {return this._translate.instant(material.name)}).join(', '),

        transform.created_at ? this._datepipe.transform(transform.created_at, 'dd.MM.yyyy') : '',
        transform.sites.map((site: TransformLocation) => site.name).join(', '),
        transform.sectors.map((sector: TransformServiceArea) => sector.name).join(', '),
        transform,
      )
    );
  }

  createTransformationRecord(status: ECustomListRecordStatus,
                             icon: string,
                             title: string,
                             subtitle: string,
                             inputMaterials: string,
                             outputMaterials: string,
                             addedDate: string,
                             sitesAttached: string,
                             sectors: string,
                             transform: Transform): CustomListRecord {

    let customListRecord = CustomListRecord
      .build()
      .setPrimaryKey(transform.id)
      .setStatus(status)
      .addIconColumn(icon)
      .addTitleColumn(title, subtitle, transform.english_name);

      //TODO rework CustomList to get rid of empty columns
    if(transform.transformation_type_data.includes.incomeOutcome) {
      customListRecord
        .addTitleColumn(inputMaterials, 'Input materials')
        .addTitleColumn(outputMaterials, 'Output materials');

    } else if(transform.transformation_type_data.includes.acceptedMaterials) {
      customListRecord
        .addTitleColumn(transform.acceptedMaterialsView, 'Accepted materials');
      if(!transform.transformation_type_data.includes.controlType) {
        customListRecord.addTitleColumn('', '');
      }

    } else {
      customListRecord.addTitleColumn('', '');
      customListRecord.addTitleColumn('', '');
    }

    if(transform.transformation_type_data.includes.controlType) {
      customListRecord
        .addTitleColumn(transform.controlTypeView, 'Control type');
    }

    if(transform.transformation_type_data.includes.serviceArea) {
      customListRecord
        .addTitleColumn(sectors, 'Service area');
    }

    if(transform.transformation_type_data.includes.storingSites) {
      customListRecord
        .addTitleColumn(sitesAttached, 'Sites attached');
    }

    customListRecord
      .addDateColumn(addedDate, 'Added')
      .addControlsColumn([{
        fn: (data: ICustomListRecord) => {
          this._router.navigate([this.parentModule + '/transformations', transform.id]);
        },
        name: 'See details'
      }, {
        fn: (data: ICustomListRecord) => {
          this.copyTransformation(transform);
        },
        name: 'Duplicate'
      },{
        fn: (data: ICustomListRecord) => {
          this.deleteTransformation(transform);
        },
        name: 'Delete'
      }]);

    return customListRecord;
  }

  copyTransformation (transform : Transform) {
    let dialogRef = this._dialog.open(CopyTransformationDialogComponent, {
      data: {
        name: transform.name
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        this._transformService.duplicate(transform.id, {name: result}, true).subscribe((response: any) => {
          this._router.navigate([this.parentModule + '/transformations', response.id]);
         // this.s(<Passport>response);
         // this.enableFormControls();
         console.log('response ', response);
        }, (err: any) => console.log(err));
      } else {
        //this.disableFormControls();
      }
    });
  }
  deleteTransformation(transform: Transform) {
    transform.canDelete()
      .flatMap((canDelete) => {
        if(canDelete) {
          let dialogRef = this._dialog.open(ConfirmDialogComponent, {
            data: {
              text: this._confirmDeletionText
            }
          });

          return dialogRef.afterClosed();
        } else {
          return Observable.of(false);
        }
      })
      .flatMap((confirmed) => {
        return confirmed
          ? transform.destroy().map(() => true)
          : Observable.of(false);
      })
      .subscribe((deleted) => {
        if(deleted) {
          this.getRecords();
        }
      });
  }
}
