import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'suggestible-text-input',
  templateUrl: 'suggestible-text-input.component.html'
})
export class SuggestibleTextInputComponent {
  @Input() suggestions: string[];
  @Input() value: string;
  @Input() viewProperty: string;
  @Input() placeholder: string;
  @Input() itemsCount: number;
  @Output() onFocusOut: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  isFocus: boolean = false;

  focusIn() {
    this.isFocus = true;
  }

  focusOut() {
    setTimeout(() => {
      this.isFocus = false;
      this.onFocusOut.emit(true);
    }, 150);
  }

  suggest(suggestion: string) {
    this.onChange.emit(suggestion);
  }

  showSuggestion(item: any) {
    return typeof item === 'string' ? item : item[this.viewProperty];
  }
}
