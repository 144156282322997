import * as tslib_1 from "tslib";
import { StatsContainer } from './stats-container.class';
import { SupervisorStats } from './supervisor-stats.class';
var SupervisorStatsContainer = /** @class */ (function (_super) {
    tslib_1.__extends(SupervisorStatsContainer, _super);
    function SupervisorStatsContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SupervisorStatsContainer.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
        return json;
    };
    SupervisorStatsContainer.prototype.createModel = function (json, key) {
        key.forEach(function (k) {
            if (!!json[k]) {
                json[k] = json[k].map(function (data) { return new SupervisorStats(data); });
            }
        });
    };
    SupervisorStatsContainer.prototype.getDepositsTotals = function () {
        var _this = this;
        var result = { rejected: {}, characterized: {}, contracts: {} };
        var keys = ['total', 'last_year', 'last_month', 'last_week'];
        keys.forEach(function (key) {
            var deposits_published = 0, deposits_rejected = 0, deposits_characterized = 0, deposit_contracts = 0;
            if (_this[key]) {
                _this[key].forEach(function (item) {
                    if (item.deposits_published) {
                        deposits_published += item.deposits_published;
                    }
                    if (item.deposits_rejected) {
                        deposits_rejected += item.deposits_rejected;
                    }
                    if (item.deposits_characterized) {
                        deposits_characterized += item.deposits_characterized;
                    }
                    if (item.deposit_orders_processed) {
                        deposit_contracts += item.deposit_orders_processed;
                    }
                });
            }
            result.rejected[key] = _this.calculatePercentage(deposits_rejected, deposits_published + deposits_rejected);
            result.characterized[key] = _this.calculatePercentage(deposits_characterized, deposits_published + deposits_rejected);
            result.contracts[key] = deposit_contracts;
        });
        return result;
    };
    SupervisorStatsContainer.prototype.getNeedsTotals = function () {
        var _this = this;
        var result = { rejected: {}, contracts: {} };
        var keys = ['total', 'last_year', 'last_month', 'last_week'];
        keys.forEach(function (key) {
            var needs_published = 0, needs_rejected = 0, need_contracts = 0;
            if (_this[key]) {
                _this[key].forEach(function (item) {
                    if (item.needs_published) {
                        needs_published += item.needs_published;
                    }
                    if (item.needs_rejected) {
                        needs_rejected += item.needs_rejected;
                    }
                    if (item.offers_processed) {
                        need_contracts += item.offers_processed;
                    }
                });
            }
            result.rejected[key] = _this.calculatePercentage(needs_rejected, needs_published + needs_rejected);
            result.contracts[key] = need_contracts;
        });
        return result;
    };
    SupervisorStatsContainer.prototype.getTransformationsTotals = function () {
        var _this = this;
        var result = { rejected: {}, contracts: {} };
        var keys = ['total', 'last_year', 'last_month', 'last_week'];
        keys.forEach(function (key) {
            var transformations_published = 0, transformations_rejected = 0, transformation_contracts = 0;
            if (_this[key]) {
                _this[key].forEach(function (item) {
                    if (item.transformations_published) {
                        transformations_published += item.transformations_published;
                    }
                    if (item.transformations_rejected) {
                        transformations_rejected += item.transformations_rejected;
                    }
                    if (item.transformatin_orders_processed) {
                        transformation_contracts += item.transformatin_orders_processed;
                    }
                });
            }
            result.rejected[key] = _this.calculatePercentage(transformations_rejected, transformations_published + transformations_rejected);
            result.contracts[key] = transformation_contracts;
        });
        return result;
    };
    SupervisorStatsContainer.prototype.calculatePercentage = function (value, total) {
        if (total === 0) {
            return '0%';
        }
        return (value / total * 100).toFixed(1) + '%';
    };
    return SupervisorStatsContainer;
}(StatsContainer));
export { SupervisorStatsContainer };
