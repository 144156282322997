import { BaseModel } from 'ng2-adn-common';
import { IClientStats } from '../interfaces/client-stats.interface';
import { Utils } from '../../../shared/utils/utils.class';

export class ClientStats extends BaseModel implements IClientStats {
  created: number;
  m: number;
  y: number;

  fields(): string[] {
    return [
      'created',
      'm',
      'y'
    ];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    Utils.convertJsonStringToNumber(json, [
      'created',
      'm',
      'y'
    ]);
    return json;
  }
}
