<div class="passport-rating-explanation">
  <div class="passport-rating-explanation__close" (click)="mdDialogRef.close()">
    <i class="icon icon--close_s"></i>
  </div>

  <div class="passport-rating-explanation__wrapper">

    <div class="passport-rating-explanation__header">
      <div class="passport-rating-explanation__header--icon">
      </div>
      <div class="passport-rating-explanation__header--caption" [innerHTML]="'PASSPORT_RATING_EXPLANATION_HEADER_CAPTION' | translate">
      </div>
    </div>

    <div class="passport-rating-explanation__information background-primary-color">
      <div [innerHTML]="'PASSPORT_RATING_EXPLANATION_INFO_FIRST_BLOCK' | translate"></div>
      <br/>
      <div [innerHTML]="'PASSPORT_RATING_EXPLANATION_INFO_SECOND_BLOCK' | translate"></div>
    </div>

    <div class="passport-rating-explanation__description" [innerHTML]="'PASSPORT_RATING_EXPLANATION_DESCRIPTION' | translate"></div>

    <div class="passport-rating-explanation__cards">
      <div class="passport-rating-explanation__card" *ngFor="let card of getExplanationCards()">
        <div [class]="getIconClass(card.iconClass, 'passport-rating-explanation__card')">
        </div>
        <div class="passport-rating-explanation__card--label" [innerHTML]="card.label | translate">
        </div>
        <div [innerHTML]="getCardContent(card.content)">
        </div>
      </div>
    </div>

    <div class="passport-rating-explanation__proofs">
      <div class="passport-rating-explanation__proofs-block">
        <div  class="passport-rating-explanation__proofs--dark" *ngFor="let card of getExplanationCards()">
          <div [class]="getIconClass(card.iconClass, 'passport-rating-explanation__proofs--dark')"></div>
        </div>
        <div class="passport-rating-explanation__proofs-text"  [innerHTML]="'PASSPORT_RATING_EXPLANATION_SECOND_PROOF' | translate">
        </div>
      </div>

      <div class="passport-rating-explanation__proofs-block">
        <div  class="passport-rating-explanation__proofs--colored" *ngFor="let card of getExplanationCards()">
          <div [class]="getIconClass(card.iconClass, 'passport-rating-explanation__proofs--colored')"></div>
        </div>
        <div class="passport-rating-explanation__proofs-text" [innerHTML]="'PASSPORT_RATING_EXPLANATION_FIRST_PROOF' | translate">
        </div>
      </div>

    </div>

    <hr class="passport-rating-explanation__seporator" *ngIf="isActionButtonsShown">

    <div class="passport-rating-explanation__by-click-on" *ngIf="isActionButtonsShown">
      <i class="form-group__label--question">!</i>
      <div [innerHTML]="'BY_CLICK_ON_ASK_FOR_RATING' | translate">
      </div>
    </div>

    <div class="passport-rating-explanation__align-center" *ngIf="isActionButtonsShown">

      <md-radio-group
      class="passport-rating-explanation__readio-wrapper"
      [(ngModel)]="askForRatingOption" 
      >
        <md-radio-button [value]="askForRating.SUBSCRIPTION">
          <div size="small">
            <div size="big">{{'I have a Basic, Standard or Premium subscription.' | capitalize | translate}}
            </div>
              {{'This rating will take place as soon as it complies with the conditions of your subscription.' 
                | capitalize | translate}}
          </div>
        </md-radio-button>
        <md-radio-button [value]="askForRating.TOKEN">
          {{'I enter my rating token :' | translate}}
          <md-input-container class="input-group passport-rating-explanation__input">
            <input autocomplete="off" mdInput type="text"
            (keydown)="proceedIfNonNegativeInt($event)"
            (paste)="pasteOnlyIfNonNegativeInt($event)"
            [formControl]="tokenFormControl">
          </md-input-container>
          <div class="passport-rating-explanation__readio-wrapper__tooltip passport-rating-explanation__by-click-on">
            <i class="form-group__label--question" mdTooltip="{{'PASSPORT_RATING_TOOLTIP'| translate}}" mdTooltipPosition="above">?</i>
          </div>
        </md-radio-button>
      </md-radio-group>
    </div>

    <div class="flex justify-center passport-rating-explanation__action-buttons" *ngIf="isActionButtonsShown">
      <ul>
        <li class="button-group__item button-group__item--wide passport-rating-explanation__action-button">
          <button md-button
                  (click)="onAskForARating()"
                  [disabled]="isAskForRatingDisabled"
          >{{ 'Ask for a rating' | translate }}</button>
        </li>
        <li class="button-group__item button-group__item--wide passport-rating-explanation__action-button">
          <button md-button md-raised-button
                  (click)="mdDialogRef.close()"
          >{{'Cancel' | translate}}</button>
        </li>
      </ul>
    </div>

  </div>


</div>
