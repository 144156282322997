<app-import-spreadsheet
  [importForm]="depositImportForm"
  [labelText]="'import sites from a spreadsheet'"
  [importModel]="depositImport"
  [fields]="fields"
  [onDocumentSave$]="onDocumentSave$"
  [onUpdateUploadUrl$]="onUpdateUploadUrl$"
  [importPreferences]="importPreferences"
  [importFormAdditionalData]="DEPOSIT_IMPORT_CONTROL_META"
  [userProhibitColumnLetters]="[]"
  [uploadedDepositFile]="uploadedDepositFile"
  (submit)="updateImportAndShowWarning()"
  (documentUploaded)="documentUploaded()"
>
</app-import-spreadsheet>
