/**
 * Created by SImo 25/10/2019.
 */
import { Component, Input, OnInit } from '@angular/core';

import { NomenclatureItem } from '../../../nomenclature/models/nomenclature-item.class';
import { CategoryItem } from '../../../category/models/category-item.class';
import { ViewCategoryDialogComponent } from '../../../passport/dialogs/view-category/view-category.component';
import { MdDialog } from '@angular/material';
import { ViewNomenclatureDialogComponent } from '../../../passport/dialogs/view-nomenclature/view-nomenclature.component';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GenericPassport } from '../../models/generic-passport.class';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { ChangeEvent } from 'angular2-virtual-scroll';
import { FormControl } from '@angular/forms';
import { ZoneService } from '../../../../shared/services/zone.service';
import { Zone } from '../../../../shared/models/zone.class';
import {PaginationUnmarshallingService} from '../../../../shared/services/pagination-unmarshalling.service';

@Component({
  moduleId: module.id,
  selector: 'generic-passport-list',
  templateUrl: 'generic-passport-list.component.html',
  styleUrls: ['generic-passport-list.component.scss']
})
export class GenericPassportListComponent implements OnInit {

  public tags: NomenclatureItem[] = [];
  public categoryTags: CategoryItem[] = [];
  public records: GenericPassport[] = [];
  public parentModuleName: string;

  public currentPage = 1;
  public pageCount: number;
  public perPage: number;
  public totalCount: number;
  private isRecordsLoaded: boolean;
  private isLoadingNextPage: boolean;
  private _confirmDeletionText: string;
  @Input() isInfiniteLoadingPageSize: boolean;
  @Input() zoneId: number;
  public search = new FormControl();
  zone: Zone;

  constructor(public dialog: MdDialog,
    private _navigationService: NavigationService,
    private _mdDialog: MdDialog,
    private _router: Router,
    private _service: ZoneService,
    private _translate: TranslateService,
    private paginationService: PaginationUnmarshallingService
  ) {
    this.parentModuleName = this._navigationService.getModuleName();
    this._translate.get('CONFIRM_DELETE_GENERIC_PASSPORT').subscribe((translation) => {
      this._confirmDeletionText = translation;
    });
  }
  ngOnInit() {
    this.loadRecordsAtStoredPage();
    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(async val => {
        if (this.isStringLengthMoreThan(val)) {
          return await this.loadRecords();
        }
        this.resetSearch();
          return await this.loadRecords(null, true);
        }
      );
      this._service.view(this.zoneId, {expand: 'name'}).subscribe((zone: Zone) => {
        this.zone = zone;
      });
  }

  private isStringLengthMoreThan(value: string, length = 2) {
    return value && value.length > length;
  }

  loadRecordsAtStoredPage() {
    const storedPage = this._service.getListPage();
    const options: any = {};
    !!storedPage ? this.loadRecords(storedPage) : this.loadRecords();
  }
  resetSearch(): void {
    this.records = [];
    this.currentPage = null;
    this.pageCount = null;
    this.perPage = null;
    this.totalCount = null;
  }

  navigateToCreateGenericPassport() {
    // console.log('create passport');
    this._router.navigate([this.parentModuleName + `/zones/${this.zoneId}/generic-passports/new`]);
  }

  removeTag(tag: any, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.tags.splice(this.tags.indexOf(tag), 1);
    this.loadRecords();
  }
  removeCategoryTag(tag: any, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.categoryTags.splice(this.categoryTags.indexOf(tag), 1);
    this.loadRecords();
  }

  async loadRecords(page?: number, noSearch?: boolean) {
    this.isRecordsLoaded = false;
    const options = this.getLoadRecordsOptions(page, noSearch);
    this._service.getGenericPassports(this.zoneId, options).subscribe((res) => {
      const pageProps = this.paginationService.unmarshall(res);
      this.currentPage = pageProps.currentPage;
      this.pageCount = pageProps.pageCount;
      this.perPage = pageProps.perPage;
      this.totalCount = pageProps.totalCount;
      //   console.log( this.count +' '+this.perpage);
      //  this.alreadyLoadedIndexes = [];
      this.records = res.json().map((item: any) => new GenericPassport(item));
      this.isRecordsLoaded = true;
    });
  }
  getLoadRecordsOptions(page?: number, noSearch?: boolean) {
    const options: any = [
      ['expand', 'materials,category,tags']
    ];
    if (this.isStringLengthMoreThan(this.search.value) && !noSearch) {
      options.push(['search', this.search.value]);
    }
    options.push(['page', page]);
    return options;
  }
  loadNextPage(event: ChangeEvent) {
    if (this.totalCount <= 20
      || this.totalCount === this.records.length
      || event.end !== this.records.length - 1) {
      return;
    }
    this.isLoadingNextPage = true;
    this.isInfiniteLoadingPageSize = true;
    this._service.getWithArray(this.getLoadRecordsOptions(++this.currentPage)).subscribe((res) => {
      const headers = res.headers;
      this.pageCount = Number(headers.get('X-Pagination-Page-Count')) || 1;
      this.perPage = Number(headers.get('X-Pagination-Per-Page')) || res.json().length;
      this.totalCount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length;
      res.json().map((item: any) => {
        this.records = [...this.records, new GenericPassport(item)];
      });
      this.isLoadingNextPage = false;
    });
  }

  openNomenclatureDialog(materialList: any) {
    // MdDialogConfig
    const dialogRef = this.dialog.open(ViewNomenclatureDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    });
    materialList.blur();
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tags.push(result);
        // this.loadRecords();
      }
    });
  }
  openCategoryDialog(categorie: any): void {
    categorie.blur();
    const dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.categoryTags.push(result);
        // this.loadRecords();
      }
    });
  }
  pageChanged(page: number) {
    this._service.setListPage(page);
    this.loadRecords(page, !this.isStringLengthMoreThan(this.search.value));
  }

  deleteRecord(record: GenericPassport) {
    //  console.log('delete that shit ');
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: this._confirmDeletionText
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        //  console.log(result);
        record.destroy().map(() => true).subscribe((deleted) => {
          if (deleted) {
            this.records.splice(this.records.indexOf(record), 1);
          }
        });
      }
    });
  }
  openRecord(record: GenericPassport) {
    this._router.navigate([this.parentModuleName + `/zones/${this.zoneId}/generic-passports`, record.id]);
  }

}
