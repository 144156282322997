import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
import { IEcosystemStats } from '../interfaces/ecosystem-stats.interface';

export class EcosystemStats extends BaseModel implements IEcosystemStats {
  ecosystems_created: number;
  ecosystems_deleted: number;
  ecosystems_done: number;
  ecosystems_modified: number;
  ecosystems_cancelled: number;
  ecosystems_in_process: number;
  offers_accepted: number;
  offers_rejected: number;
  revenue: number;
  m: number;
  y: number;

  fields(): string[] {
    return [
      'ecosystems_created',
      'ecosystems_deleted',
      'ecosystems_done',
      'ecosystems_modified',
      'ecosystems_cancelled',
      'ecosystems_in_process',
      'offers_accepted',
      'offers_rejected',
      'revenue',
      'm',
      'y'
    ];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    Utils.convertJsonStringToNumber(json, [
      'ecosystems_created',
      'ecosystems_deleted',
      'ecosystems_done',
      'ecosystems_modified',
      'ecosystems_cancelled',
      'ecosystems_in_process',
      'offers_accepted',
      'offers_rejected',
      'revenue',
      'm',
      'y'
    ]);
    return json;
  }
}
