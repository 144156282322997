<div [ngClass]="{'flex align-center': true, 'passport-edit__info-disabled': disabled || control?.disabled}">
  <div class="flex passport-edit__info--group-conversion-size">
    1 {{unitView | translate}}
  </div>
  <div class="flex passport-edit__info--group-conversion-equal">
    =
  </div>
  <div class="flex passport-edit__info--dimensions-item">
    <md-input-container class="control-group__item--msmall  passport-edit__input">
      <input mdInput
             type="number"
             autocomplete="off"
             [formControl]="control"
      >
      <md-hint
        *ngIf="showValidationError()"
        class="error-hint"
        align="start"
      >{{ errorText | translate }}</md-hint>
    </md-input-container>
    <div class="passport-edit__info--dimensions-size">
      {{unitConvertTo | translate }}
    </div>
  </div>
</div>
