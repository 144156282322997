import * as tslib_1 from "tslib";
import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
var SalesStats = /** @class */ (function (_super) {
    tslib_1.__extends(SalesStats, _super);
    function SalesStats() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SalesStats.prototype.fields = function () {
        return [
            'needs_done',
            'needs_published',
            'needs_refused',
            'needs_treated',
            'offers_accepted',
            'offers_rejected',
            'revenue'
        ];
    };
    SalesStats.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        Utils.convertJsonStringToNumber(json, [
            'needs_done',
            'needs_published',
            'needs_refused',
            'needs_treated',
            'offers_accepted',
            'offers_rejected',
            'revenue'
        ]);
        return json;
    };
    return SalesStats;
}(BaseModel));
export { SalesStats };
