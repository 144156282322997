/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CategoryService } from '../../service/category.service';
import { FormControl } from '@angular/forms';
import { TreeComponent, TreeNode } from 'angular-tree-component';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'category-tree-view',
  templateUrl: 'tree-view.component.html',
  styleUrls: ['tree-view.component.scss'],
  providers: [CategoryService]
})
export class TreeViewComponent implements OnInit {
  @Input() viewId: number;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  events: Subscription;
  search = new FormControl();
  treeData: any[];
  nodes: any = [];
  OTHER_CATEGORIES_IDS: Array<String> = ["13", "17", "43", "51", "67", "72", "82"];

  @ViewChild('tree') private tree: TreeComponent;

  constructor(private categoryService: CategoryService) {
  }

  getTree(view_id: number) {
    this.categoryService.get<any>().subscribe((res) => {
      this.treeData = res;
      this.sortCategoryNodeChildren(this.treeData[0]);
    });

    this.events = this
      .search
      .valueChanges
      .debounceTime(1000)
      .distinctUntilChanged()
      .subscribe((keyword: string) => this.filterTree(keyword));
  }

  /**
   * Method to recursively sort all category children
   * @param {Object} node - The node to sort 
   * @returns {undefined}
   */
  sortCategoryNodeChildren(node) {
    if (!node.children) {
      return;
    }

    node.children.sort((childA, childB) => {
      //Force the "other" categories to be put in last
      if (this.OTHER_CATEGORIES_IDS.includes(childA.id)) {
        return 1;
      }
      if (this.OTHER_CATEGORIES_IDS.includes(childB.id)) {
        return -1;
      }

      if (this.removeAccents(childA.name) < this.removeAccents(childB.name)) {
        return -1;
      }

      if (this.removeAccents(childA.name) > this.removeAccents(childB.name)) {
        return 1;
      }

      return 0
    });

    node.children.forEach(child => {
      this.sortCategoryNodeChildren(child);
    })
  }

  /**
   * Remove accents from strings
   * @param {String} string The string from which we want to remove the accents
   * @returns {String} The string without accents
   */
  removeAccents(string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  ngOnDestroy() {
    if(this.events) {
      this.events.unsubscribe();
    }
  }

  ngOnInit() {
    // This needs to get the view id from the user data
    if (!this.treeData) {
      this.getTree(1);
    }
  }

  filterTree(keyword: string) {
    if (this.tree && this.tree.treeModel) {
      setTimeout(() => this.appendSearchCount());
      setTimeout(() => this.filterNodes(keyword));
      setTimeout(() => this.updateSearchCount());
      setTimeout(() => this.collapseAll());
    }
  }

  appendSearchCount() {
    this.tree.treeModel.doForAll((node: TreeNode) => {
      let name = node.data.name.replace('(' + node.data.search_count + ')', '');
      node.data.name = name;
      node.data.search_count = 0;
      node.data.in_view = false;
    });
  }

  filterNodes(keyword: string) {
    this.tree.treeModel.filterNodes((node: TreeNode) => {
      const openTag = '<em class=\'search-highlight\'>';
      const closeTag = '<\/em>';
      let name = node.data.name.replace(openTag, '').replace(closeTag, '');

      if (node.data.search_count > 0) {
        if (node.parent.data.search_count > 0) {
          node.parent.data.search_count += node.data.search_count;
        } else {
          node.parent.data.search_count = node.data.search_count;
        }
      }

      let index = name.toLowerCase().indexOf(keyword.toLowerCase());
      let accept = index > -1;
      if (keyword && accept) {
        this.propagateSearchCount(node.parent);
        name = [name.slice(0, index),
          openTag,
          name.slice(index, index + keyword.length),
          closeTag,
          name.slice(index + keyword.length)].join('');

        for (let child of node.children) {
          child.data.in_view = true;
        }
      } else {
        if (node.data.in_view === true) {
          for (let child of node.children) {
            child.data.in_view = true;
          }
        }
      }

      node.data.name = name;

      if (node.data.in_view === true) {
        return true;
      } else {
        return accept;
      }
    });
  }

  updateSearchCount() {
    this.tree.treeModel.doForAll((node: TreeNode) => {
      let name = node.data.name;
      name = [name, '(' + node.data.search_count + ')'].join(' ');
      node.data.name = name;
    });
  }

  collapseAll() {
    this.tree.treeModel.collapseAll();
  }

  propagateSearchCount(item: TreeNode) {
    if (!!item) {
      if (item.data.search_count > 0) {
        item.data.search_count += 1;
      } else {
        item.data.search_count = 1;
      }
      this.propagateSearchCount(item.parent);
    }
  }

  closeDialog(treeItem: any) {
    //check if selectable
    const openTag = '<em class=\'search-highlight\'>';
    const closeTag = '<\/em>';
    let name = treeItem.node.data.name.replace(openTag, '').replace(closeTag, '').replace('(' + treeItem.node.data.search_count + ')', '');
    treeItem.node.data.name = name;
    this.onSave.emit(treeItem.node.data);
  }

  onTreeEvent(event: any) {
    if (event.eventName === 'toggleExpanded' && event.isExpanded) {
      this.tree.treeModel.doForAll((n: any) => {
        if (n !== event.node && n.parent === event.node.parent && n.isExpanded) {
          n.collapse();
        }
      });
    }
  }

}
