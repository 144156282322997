import { StatsContainer } from './stats-container.class';
import { ITransformationStats } from '../interfaces/transformation-stats.interface';
import { ILiteral } from 'ng2-adn-common';
import { TransformationStats } from './transformation-stats.class';

export class TransformationStatsContainer extends StatsContainer<ITransformationStats> {

  extractData(data: any): any {
    let json = super.extractData(data);
    this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
    return json;
  }

  createModel(json: ILiteral, key: string[]) {
    key.forEach((k) => {
      if (!!json[k]) {
        json[k] = json[k].map((data: ILiteral) => new TransformationStats(data));
      }
    });
  }

  getTotal(): any {
    let result: any = {total:0,last_year:0,last_month:0,last_week:0};
    this.total.forEach(item => {
      result.total += item.published + item.rejected;
    });
    this.last_year.forEach(item => {
      result.last_year += item.published + item.rejected;
    });
    this.last_month.forEach(item => {
      result.last_month += item.published + item.rejected;
    });
    this.last_week.forEach(item => {
      result.last_week += item.published + item.rejected;
    });
    return result;
  }

  getEvolution() {
    let result: any[] = [];
    if (this.total.length) {
      result.push(['Date','Transformation sites']);
    }
    this.total.forEach(item => {
      result.push([new Date(item.y, item.m - 1, 1), (item.published + item.rejected) || 0]);
    });
    return result;
  }
}
