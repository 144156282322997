import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
import { ITSMStats } from '../interfaces/tsm-stats.interface';

export class TSMStats extends BaseModel implements ITSMStats {
  orders_accepted: number;
  orders_rejected: number;
  transformation_locations_assigned: number;
  transformation_locations_published: number;
  transformations_assigned: number;
  transformations_published: number;
  transformations_rejected: number;

  fields(): string[] {
    return [
      'orders_accepted',
      'orders_rejected',
      'transformation_locations_assigned',
      'transformation_locations_published',
      'transformations_assigned',
      'transformations_published',
      'transformations_rejected'
    ];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    Utils.convertJsonStringToNumber(json, [
      'orders_accepted',
      'orders_rejected',
      'transformation_locations_assigned',
      'transformation_locations_published',
      'transformations_assigned',
      'transformations_published',
      'transformations_rejected'
    ]);
    return json;
  }
}
