import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Passport } from '../../../../entities/passport/models/passport.class';

/**
 * `PassportCollectionComponent` displays a collection of passports, allowing users to view and interact with a list of passport entities.
 * It supports pagination, loading states, and selection of individual or all passports within the collection. This component is designed
 * for use in areas of the application where managing a list of passport objects is required, providing both visual representation and interaction capabilities.
 */
@Component({
  selector: 'app-passport-collection',
  templateUrl: './passport-collection.component.html',
  styleUrls: ['./passport-collection.component.scss']
})
export class PassportCollectionComponent implements OnChanges {
  /** Array of passport objects to display */
  @Input() passports: Passport[] = [];
  /** Indicates if the passport data is currently being loaded */
  @Input() isLoading: boolean = false;
  /** Current page number in pagination */
  @Input() currentPage: number;
  /** Total number of pages available in pagination */
  @Input() totalPages: number;
  /** Total number of passports in the collection */
  @Input() totalPassportCount: number;
  /** Indicates if passport can be selected */
  @Input() canSelect: boolean = true;
  /** Indicates if show status of passport */
  @Input() showStatus: boolean = true;
  /** Indicates which action button displays */
  @Input() actionButtonsConfig: { duplicate: boolean, copyId: boolean, delete: boolean, version: boolean } = { duplicate: true, copyId: true, delete: true, version: true };

  /** Event emitter for page change actions */
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  /** Event emitter for delete passport actions */
  @Output() deletePassportEvent: EventEmitter<Passport> = new EventEmitter<Passport>();
  /** Event emmiter for clicking on versions of passports */
  @Output() versionClicked: EventEmitter<Passport> = new EventEmitter<Passport>();
  /** Event emiter for clicking on all selection passports in all pages */
  @Output() selectAllPages: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Event emiter for clicking on duplicate passport */
  @Output() duplicateRequest: EventEmitter<Passport> = new EventEmitter<Passport>();

  /** Array to track selected passports */
  selectedPassports: Passport[] = [];
  /** Flag to indicate if all passports are selected */
  selectAllChecked: boolean = false;
  /** Flag to indicate if a version is selected */
  isVersionSelected = false;
  /** Flag to indicate if all passports are selected in all pages */
  isAllPagesSelected = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['passports'] && !changes['passports'].firstChange) {
      this.selectAllChecked = this.allSelectOnPage();
    }
  }

  /**
   * Handles selection changes for individual passports, adding or removing them from the selection list based on their selected state.
   * @param passport The passport object being toggled.
   * @param isSelected Indicates if the passport is being selected or deselected.
   */
  onSelectionChange(passport: Passport, isSelected: boolean): void {
    if (this.isAllPagesSelected) {
      if (isSelected && !this.isSelected(passport)) {
        this.selectedPassports = this.selectedPassports.filter(p => p.id !== passport.id);
      }
      else if (!isSelected) {
        this.selectedPassports.push(passport);
      }
      if (this.selectedPassports.length === this.totalPassportCount) {
        this.handleDeselectAllPages();
      }
    }
    else {
      if (isSelected && !this.isSelected(passport)) {
        this.selectedPassports.push(passport);
      } else if (!isSelected) {
        this.selectedPassports = this.selectedPassports.filter(p => p.id !== passport.id);
      }
    }
    this.selectAllChecked = this.allSelectOnPage();
  }

  /**
   * Toggles the selection state of all passports in the collection, either selecting all or deselecting all based on the current state.
   */
  toggleSelectAll(): void {
    let select = !this.selectAllChecked;
    this.passports.forEach(passport => {
      this.onSelectionChange(passport, select);
    });
  }

  /**
   * Determines if a given passport is selected check with id of passport.
   * @param passport The passport to check for selection.
   * @returns True if the passport is selected, false otherwise.
   */
  isSelected(passport: Passport): boolean {
    if (!this.isAllPagesSelected)
      return this.selectedPassports.some(p => p.id === passport.id);
    else
      return !this.selectedPassports.some(p => p.id === passport.id);
  }

  /**
   * Emits an event to indicate a change in the current page of the passport collection, typically triggered by pagination controls.
   * @param page The new page number to navigate to.
   */
  onPageChange(page: number) {
    this.pageChange.emit(page);
  }

  /**
   * Emits an event to indicate a delete request for a passport.
   * @param passport The passport to be deleted.
   */
  handleDeleteRequest(passport: Passport): void {
    this.deletePassportEvent.emit(passport);
  }

  /**
   * Emits an event to indicate a vers
   * ion filter request for a passport.
   * @param passport The passport to be filtered.
   */
  handleVersionClicked(passport: Passport): void {
    this.isVersionSelected = !this.isVersionSelected;
    this.versionClicked.emit(passport);
  }

  /**
   * Emits an event to indicate a select all request for all passports in all pages.
   */
  handleSelectAllPages(): void {
    this.selectAllPages.emit(true);
    this.isAllPagesSelected = true;
    this.selectedPassports = [];
    this.selectAllChecked = true;
  }

  /**
   * Emits an event to indicate a deselect all request for all passports in all pages.
   */
  handleDeselectAllPages(): void {
    this.selectAllPages.emit(false);
    this.clearSelection();
  }

  /**
   * Emits event to indicate a duplicate request for a passport.
   */
  handleDuplicateRequest(passport: Passport): void {
    this.duplicateRequest.emit(passport);
  }

  allSelectOnPage(): boolean {
    return this.passports.every(p => this.isSelected(p));
  }

  public clearVersionSelection(): void {
    this.isVersionSelected = false;
  }

  public clearSelection(): void {
    this.selectedPassports = [];
    this.isAllPagesSelected = false;
    this.selectAllChecked = false;
  }

}