import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {Manufacturers} from '../models/manufacturers.class';

@Injectable()
export class ManufacturersService extends CrudService {
  protected namespace = 'passport-manufacturers';
  protected namespaceSingular = 'passport-manufacturers';
  protected ModelClass = Manufacturers;
}
