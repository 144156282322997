<md-sidenav-container class="sidebar-container">
  <ng-content></ng-content>
  <md-sidenav #sidenav align="end" mode="side" class="sidebar-sidenav" *ngIf="show">
    <div class="sidebar-sidenav__content">
      <div class="sidebar-sidenav__header">
        <button class="toggle-button" (click)="sidenav.toggle()">
          <i class="icon icon--toggle_arrow"></i>
        </button>
        <div class="search-field">
          <md-input-container class="container" floatPlaceholder="never">
            <i class="icon icon--search"></i>
            <input
              mdInput
              autocomplete="off" 
              [formControl]="search"
              #searchbar
              placeholder="{{'Search participants' | translate}}"
            >
          </md-input-container>
        </div>
      </div>
      <div class="sidebar-sidenav__body">
        <div class="sidebar-sidenav__body--empty" *ngIf="groups.length === 0">
          <button class="toggle-button" (click)="$event.stopPropagation(); sidenav.toggle()">
            <i class="icon icon--toggle_arrow"></i>
          </button>
        </div>
        <div class="sidebar-sidenav__group" *ngFor="let group of groups; let index = index">
          <div class="header" (click)="groupToggle(group.name)">
            <button class="toggle-button" (click)="$event.stopPropagation();sidenav.toggle()" *ngIf="index === 0">
              <i class="icon icon--toggle_arrow"></i>
            </button>
            <div class="name">{{group.name | translate}}</div>
            <i class="icon" [ngClass]="group.isOpen ? 'icon--unfold-tab' : 'icon--fold-tab' "></i>
          </div>
          <div class="sidebar-sidenav__group--users" *ngIf="group.isOpen">
            <div class="sidebar-sidenav__user"
                 [ngClass]="{
                           'active':contact.selected,
                           'available': contact.status === contactStatuses.AVAILABLE,
                           'busy': contact.status === contactStatuses.BUSY,
                           'away': contact.status === contactStatuses.AWAY
                           }"
                 (click)="openChat(contact)"
                 *ngFor="let contact of group.contacts"
            >
              <div [hidden]="!contact.visible">
              <div class="avatar" [ngStyle]="{'background-image': 'url(' + contact.image + ')'}"></div>
              <div class="name">{{contact.name}}</div>
              <div class="messages" *ngIf="contact && contact.new_messages > 0">
                {{contact.new_messages}}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-sidenav__footer">
        <div class="sidebar-sidenav__group" *ngIf="supportContact">
          <div class="header">
            <div class="name">{{ 'Ask Upcyclea' | translate }}</div>
          </div>
          <div class="sidebar-sidenav__group--users">
            <div class="sidebar-sidenav__user"
                 [ngClass]="{
                           'active':supportContact.selected
                           }"
                 (click)="openChat(supportContact)"
            > <div>
              <div class="avatar" [ngStyle]="{'background-image': 'url(' + supportContact.image + ')'}"></div>
              <div class="name">{{supportContact.name}}</div>
              <div class="messages" *ngIf="supportContact.thread && supportContact.thread.unreadMessages > 0">
                {{supportContact.thread.unreadMessages}}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar-sidenav__content secondary" *ngIf="selectedContact">
      <div class="sidebar-sidenav__group">
        <div class="header">
          <button class="toggle-button" (click)="closeChat()">
            <i class="icon icon--toggle_arrow"></i>
          </button>
          <div class="name">{{ 'Messaging' | translate }}</div>
        </div>
      </div>
      <div class="sidebar-sidenav__content--chat">
        <chat-form></chat-form>
      </div>
    </div>
  </md-sidenav>
</md-sidenav-container>
