import {Component, ElementRef, ViewChild, AfterViewInit, Input} from '@angular/core';
import * as d3 from 'd3';
import {MATERIAL_HEALTH_COLOR} from '../values/colors.enum';
import {CHART_DATA} from '../values/chart-data.const';
import {IMaterialHealthPieChart} from '../values/material-heath-pie-chart-data.interface';
import {IChartData} from './chart-data.interface';
import { arch } from 'os';

@Component({
  selector: 'material-pie-chart',
  templateUrl: 'material-pie-chart.html'
})
export class MaterialPieChartComponent implements AfterViewInit {
  @ViewChild('containerPieChart') pieChartRefEl: ElementRef;

  @Input() width = 400;
  @Input() height = 500;
  @Input() data: IMaterialHealthPieChart;

  private readonly radius: number = Math.min(this.width, this.height) / 2;

  private chartData: IChartData[];
  private svg: d3.Selection<SVGGElement, any, HTMLElement, any>;
  private formatter: Function;

  private readonly smallValue = 7.9;
  private readonly minValueWithoutSpread = 3.6;

  ngAfterViewInit() {
    this.setChartData();
    this.setSvg();
    this.appendPieChart();
  }

  setChartData(): void {
    let locale = d3.formatLocale({
      decimal: ","
    });
    this.formatter = locale.format(".3r");
    this.chartData = Object.entries(this.data.data).map(([key, value]) => ({key, value}));
  }

  private setSvg(): void {
    const svg = d3.select(this.pieChartRefEl.nativeElement).append('svg')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`);
    this.svg = svg.append('g')
      .attr('transform', `translate(${this.width / 2},${this.height / 2})`);
  }

  private appendPieChart(): void {
    const arcSelection = this.svg.selectAll('.arc')
      .data(d3.pie().sort(null).value((d: IChartData) => d.value)(this.chartData))
      .enter()
      .append('g')
      .attr('class', 'arc');
    this.appendArc(arcSelection);
  }

  private appendArc(arcSelection: d3.Selection<SVGGElement, any, HTMLElement, any>): void {
    const arc = d3.arc<any>()
      .innerRadius(0)
      .outerRadius(
        (d) => {
          const ratio = d.value / Math.max(...arcSelection.data().map(i => i.value));
          return (this.radius * ratio) + ((1 - ratio) * 100);
        }
      );
    let spreadlTextsCentroid = this.spreadSmallValueText(this.chartData.map(data => data.value));  
    arcSelection.append('path')
      .attr('d', arc)
      .attr('stroke', 'white')
      .attr('stroke-width', (d, index) => this.chartData[index].value < 1 ? 0 : 3)
      .attr('fill', (datum) => this.getColor(datum));
    arcSelection.append('text')
      .style('font-size', (d, index) => this.getFontSize(d) + 'px')
      .attr('transform', (datum, index) => {
          if (datum.data.value === 100) {
            return `translate(0 ${this.getFontSize(datum) / 4})`;
          } else if (datum.data.value > this.smallValue) {
            return 'translate(' + arc.centroid(datum) + ')';
          } else {
            const c = spreadlTextsCentroid[index] ? spreadlTextsCentroid[index] : arc.centroid(datum), x = c[0], y = c[1], h = Math.sqrt(x * x + y * y);
            if (isNaN(c[0]) || isNaN(c[1])) {
              return 'translate(0 0)';
            }
            return 'translate(' + (x / h * (this.radius + 3)) + ',' + (y / h * (this.radius + 3)) + ')';
          }
        }
      )
      .attr('text-anchor', (d, index) => spreadlTextsCentroid[index] ? 'start' :  'middle')
      .text((datum, index) => {
        return this.chartData[index].value > 0 ? this.formatter(this.chartData[index].value) + "%" : '';
      })
      .style('text-anchor', (d, index) => spreadlTextsCentroid[index] ? 'start' :  'middle')
      .style('fill', (d, index) => this.chartData[index].value > this.smallValue ? 'white' : this.getColor(d))
      .style('font-weight', '600');
  }

  spreadSmallValueText(values: number[]) {
    const gap = this.minValueWithoutSpread * Math.PI / 50;
    let optionalPaths = []
    let counterAngel = 0, counterIndex = 0;
    let startAngle = 0;
    for (let i = 0; i < values.length; i++) {
      if (counterIndex > 0 || values[i] < this.minValueWithoutSpread) {
        counterIndex += 1
      }
      let pieceFreeSpace = values[i] > this.minValueWithoutSpread ? (values[i] + this.minValueWithoutSpread) / 2: 0;
      if (this.smallValue < values[i] || counterAngel + pieceFreeSpace >= counterIndex * this.minValueWithoutSpread) {
        optionalPaths.push(null);
        startAngle += values[i] * Math.PI / 50;
        counterIndex = 0, counterAngel = 0;
      } else {
        optionalPaths.push(d3.arc<any>().innerRadius(0).outerRadius(203).startAngle(startAngle).endAngle(startAngle).centroid());
        if (values[i] < 0.1) {
          continue;
        }
        startAngle += gap;
        counterAngel += values[i];
      }
    }
    return optionalPaths; 
  }

  getFontSize(data): number {
    const chartData: IChartData = data.data;
    const value = chartData.value;
    if (value >= 49) {
      return 54;
    } else if (value >= 29) {
      return 34;
    } else if (value >= 19) {
      return 23;
    } else if (value > this.smallValue) {
      return 12;
    }  else if (value >= 0) {
      return 20; 
    } else {
      return 12;
    }
  }

  getColor(data) {
    const chartData: IChartData = data.data;
    switch (chartData.key) {
      case CHART_DATA.percentage_weight_of_controlled_toxicity:
        return MATERIAL_HEALTH_COLOR.CONTROLLED_TOCICITY_PRODUCTS;
      case CHART_DATA.percentage_weight_of_controlled_verified_toxicity:
        return MATERIAL_HEALTH_COLOR.CONTROLLED_AND_VERIFIED_PRODUCTS;
      case CHART_DATA.percentage_weight_of_healthy_product:
        return MATERIAL_HEALTH_COLOR.HEALTHY_PRODUCTS;
      case CHART_DATA.percentage_weight_of_unknown_toxicity:
        return MATERIAL_HEALTH_COLOR.UNKNOW_TOXICITY_PRODUCTS;
      case CHART_DATA.percentage_weight_of_known_toxicity:
        return MATERIAL_HEALTH_COLOR.KNOW_TOXICITY_PRODUCTS;
      default:
        break;
    }
  }
}
