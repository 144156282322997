<article class="genericpassport-edit">
        <section class="genericpassport-title">
                <div class="passport-edit__header">
                        <div class="passport-edit__header--heading">{{ 'New System Passport' | translate }}</div>
                </div>
                <generic-passport-form [zoneId]="zoneId" [genericPassport]="genericPassport" [parentFormGroup]="form">
                </generic-passport-form>

                <footer class="controls">
                        <ul class="button-group__list">
                                <li class="button-group__item button-group__item--wide">
                                        <button md-button md-raised-button
                                                (click)="clickSave()">{{ 'Save' | translate }}</button>
                                </li>
                        </ul>
                </footer>
        </section>

</article>