<svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.2231 5.70364L9.29916 3.19981L9.6973 2.73589L12.6213 5.23973L12.2231 5.70364Z" fill="white"
        stroke="#747474" />
    <path
        d="M14.3505 3.21928L14.3497 3.22018L13.5979 4.10152L10.6712 1.59738L11.4232 0.715803C11.4233 0.715673 11.4234 0.715543 11.4235 0.715413C11.6456 0.456751 12.0353 0.42694 12.2937 0.648285C12.2937 0.648312 12.2938 0.64834 12.2938 0.648367L14.2799 2.35165L14.2812 2.35277C14.5422 2.57504 14.5717 2.96124 14.3505 3.21928Z"
        fill="white" stroke="#747474" />
    <path
        d="M3.33618 15.2658L3.33616 15.2658L2.4931 15.5948C2.35032 15.2997 2.14724 15.0343 1.89446 14.8174C1.6412 14.5985 1.3465 14.4388 1.03256 14.343L1.2261 13.4604C1.22611 13.4604 1.22612 13.4603 1.22613 13.4603C1.22614 13.4602 1.22615 13.4602 1.22616 13.4601C1.29924 13.1279 1.41 12.8065 1.5558 12.5009C1.58801 12.5285 1.62153 12.5573 1.65622 12.5871C1.99405 12.877 2.44437 13.2628 2.89457 13.6484C3.3448 14.034 3.79502 14.4193 4.13268 14.7083L4.23107 14.7925C3.95161 14.9835 3.65236 15.1425 3.33618 15.2658Z"
        fill="white" stroke="#747474" />
    <path
        d="M0.591479 16.3307L0.172017 16.494C0.351064 16.4245 0.534835 16.5849 0.494945 16.7693C0.494818 16.7699 0.494692 16.7705 0.49456 16.7711L0.494949 16.7693L0.591479 16.3307ZM0.591479 16.3307L0.703839 15.8202M0.591479 16.3307L1.08014 16.1405M0.703839 15.8202L1.21797 16.5008C1.19997 16.3735 1.15366 16.2501 1.08014 16.1405M0.703839 15.8202C0.777422 15.8543 0.847599 15.8991 0.912062 15.9547L0.912038 15.9547L0.916732 15.9587C0.981034 16.0127 1.03558 16.0741 1.08014 16.1405M0.703839 15.8202L1.08014 16.1405M0.703839 15.8202L0.808246 15.3458L0.319932 15.2383L0.324605 15.7383C0.453395 15.7371 0.583196 15.7643 0.703839 15.8202Z"
        fill="white" stroke="#747474" />
    <mask id="path-5-inside-1_10914_31909" fill="white">
        <path d="M4.8336 14.0344L5.33398 14.4648L11.9797 6.73998L11.4794 6.3095L4.8336 14.0344Z" />
    </mask>
    <path d="M4.8336 14.0344L5.33398 14.4648L11.9797 6.73998L11.4794 6.3095L4.8336 14.0344Z" fill="white" />
    <path
        d="M5.33398 14.4648L4.68178 15.2229L5.43988 15.8751L6.09208 15.117L5.33398 14.4648ZM4.8336 14.0344L4.0755 13.3822L3.4233 14.1403L4.1814 14.7925L4.8336 14.0344ZM11.4794 6.3095L12.1316 5.5514L11.3735 4.8992L10.7213 5.6573L11.4794 6.3095ZM11.9797 6.73998L12.7378 7.39218L13.39 6.63408L12.6319 5.98188L11.9797 6.73998ZM5.98618 13.7067L5.4858 13.2763L4.1814 14.7925L4.68178 15.2229L5.98618 13.7067ZM5.5917 14.6866L12.2375 6.9617L10.7213 5.6573L4.0755 13.3822L5.5917 14.6866ZM10.8272 7.0676L11.3275 7.49808L12.6319 5.98188L12.1316 5.5514L10.8272 7.0676ZM11.2216 6.08778L4.57588 13.8126L6.09208 15.117L12.7378 7.39218L11.2216 6.08778Z"
        fill="#747474" mask="url(#path-5-inside-1_10914_31909)" />
    <mask id="path-7-inside-2_10914_31909" fill="white">
        <path d="M3.79454 13.1398L4.29492 13.5703L10.9407 5.84545L10.4403 5.41497L3.79454 13.1398Z" />
    </mask>
    <path d="M3.79454 13.1398L4.29492 13.5703L10.9407 5.84545L10.4403 5.41497L3.79454 13.1398Z" fill="white" />
    <path
        d="M4.29492 13.5703L3.64272 14.3284L4.40082 14.9806L5.05302 14.2225L4.29492 13.5703ZM3.79454 13.1398L3.03644 12.4876L2.38424 13.2457L3.14234 13.8979L3.79454 13.1398ZM10.4403 5.41497L11.0925 4.65687L10.3344 4.00467L9.6822 4.76277L10.4403 5.41497ZM10.9407 5.84545L11.6988 6.49765L12.351 5.73955L11.5929 5.08735L10.9407 5.84545ZM4.94712 12.8122L4.44674 12.3817L3.14234 13.8979L3.64272 14.3284L4.94712 12.8122ZM4.55264 13.792L11.1984 6.06717L9.6822 4.76277L3.03644 12.4876L4.55264 13.792ZM9.7881 6.17307L10.2885 6.60355L11.5929 5.08735L11.0925 4.65687L9.7881 6.17307ZM10.1826 5.19325L3.53682 12.9181L5.05302 14.2225L11.6988 6.49765L10.1826 5.19325Z"
        fill="#747474" mask="url(#path-7-inside-2_10914_31909)" />
    <mask id="path-9-inside-3_10914_31909" fill="white">
        <path d="M2.75547 12.2492L3.25586 12.6797L9.90162 4.95483L9.40124 4.52434L2.75547 12.2492Z" />
    </mask>
    <path d="M2.75547 12.2492L3.25586 12.6797L9.90162 4.95483L9.40124 4.52434L2.75547 12.2492Z" fill="white" />
    <path
        d="M3.25586 12.6797L2.60366 13.4378L3.36176 14.09L4.01396 13.3319L3.25586 12.6797ZM2.75547 12.2492L1.99737 11.597L1.34517 12.3551L2.10327 13.0073L2.75547 12.2492ZM9.40124 4.52434L10.0534 3.76624L9.29534 3.11404L8.64314 3.87214L9.40124 4.52434ZM9.90162 4.95483L10.6597 5.60703L11.3119 4.84893L10.5538 4.19673L9.90162 4.95483ZM3.90806 11.9216L3.40767 11.4911L2.10327 13.0073L2.60366 13.4378L3.90806 11.9216ZM3.51357 12.9014L10.1593 5.17654L8.64314 3.87214L1.99737 11.597L3.51357 12.9014ZM8.74904 5.28244L9.24942 5.71293L10.5538 4.19673L10.0534 3.76624L8.74904 5.28244ZM9.14352 4.30263L2.49776 12.0275L4.01396 13.3319L10.6597 5.60703L9.14352 4.30263Z"
        fill="#747474" mask="url(#path-9-inside-3_10914_31909)" />
    <mask id="path-11-inside-4_10914_31909" fill="white">
        <path d="M1.71641 11.3547L2.2168 11.7852L8.86256 4.0603L8.36217 3.62981L1.71641 11.3547Z" />
    </mask>
    <path d="M1.71641 11.3547L2.2168 11.7852L8.86256 4.0603L8.36217 3.62981L1.71641 11.3547Z" fill="white" />
    <path
        d="M2.2168 11.7852L1.5646 12.5433L2.3227 13.1955L2.9749 12.4374L2.2168 11.7852ZM1.71641 11.3547L0.95831 10.7025L0.30611 11.4606L1.06421 12.1128L1.71641 11.3547ZM8.36217 3.62981L9.01437 2.87171L8.25627 2.21951L7.60407 2.97761L8.36217 3.62981ZM8.86256 4.0603L9.62066 4.7125L10.2729 3.9544L9.51476 3.3022L8.86256 4.0603ZM2.869 11.0271L2.36861 10.5966L1.06421 12.1128L1.5646 12.5433L2.869 11.0271ZM2.47451 12.0069L9.12027 4.28201L7.60407 2.97761L0.95831 10.7025L2.47451 12.0069ZM7.70997 4.38791L8.21036 4.8184L9.51476 3.3022L9.01437 2.87171L7.70997 4.38791ZM8.10446 3.4081L1.4587 11.133L2.9749 12.4374L9.62066 4.7125L8.10446 3.4081Z"
        fill="#747474" mask="url(#path-11-inside-4_10914_31909)" />
</svg>