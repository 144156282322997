<div class="esm-catalog">
  <header class="esm-catalog__header checkbox header--title-group">
    <h3 class="esm-catalog__inline-title">{{'Deposits' | translate}}</h3>
    <div *ngFor="let status of filterStatuses">
      <md-checkbox class="esm-catalog__group--checkbox checkbox-group"
                   [name]="getStatusLabel(status.value)"
                   (change)="toggleState(status.value);"
                   checked="true">
        {{status.view | capitalize | translate}}
      </md-checkbox>
    </div>
  </header>
  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input test-id="search"
                 mdInput
                 autocomplete="off"
                 placeholder="{{'Search' | translate}}"
                 [formControl]="search"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--people-grey"></i>
        <md-select test-id="do-selector"
                   class="select-group"
                   placeholder="{{'Deposit owner' | translate}}"
                   [(ngModel)]="owner"
                   (change)="resetRecords()">
          <md-option>{{'Any' | translate}}</md-option>
          <md-option *ngFor="let owner of ownerList" [value]="owner.id">{{owner.name}}</md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select">
        <md-select test-id="sort-selector"
                   class="select-group"
                   placeholder="{{'Sort By' | translate}}"
                   [(ngModel)]="sortValue"
                   (change)="resetRecords()"
        >
          <md-option *ngFor="let sortOption of sortOptions"
                     [value]="sortOption.field">
            {{ sortOption.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
    [beforeExpandObserver]="loadAllDepositsRecursiveObserver()"
    [beforeCollapseObserver]="stopLoadingAllDepositsObserver()"
    (onExpand)="isExpanded = !!$event"
    (onEndOfList)="onScroll$.emit()"
  ></custom-list>
</div>
