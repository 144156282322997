import { Component, OnInit } from '@angular/core';
import { ModificationService } from '../../../../shared/services/modfication.service';
import { MODIFICATION_TYPES } from '../../../../shared/values/modification-types.const';
import { Modification } from '../../../../shared/models/modification.class';
import { NeedControlStates } from '../../../../entities/need/models/need-control-states.class';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  templateUrl: 'supervisor-need.component.html',
})
export class SupervisorNeedComponent implements OnInit {
  public controlStates = new NeedControlStates;

  constructor(private modificationService: ModificationService,
              private activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.controlStates = {
      name: {disabled: true},
      client_id: {disabled: true},
      client_contact_id: {disabled: true},
      quantity: {disabled: true},
      quantity_precision: {disabled: true},
      delivery_date: {disabled: true},
      date_precision: {disabled: true},
      client_location_id: {disabled: true},
      save: {disabled: true},
      reject: {disabled: false},
      publish: {disabled: false},
      askForModification: {disabled: false},
    };

    let needId = parseInt(this.activeRoute.snapshot.params['id'], 10);
    this
      .modificationService
      .get({type: MODIFICATION_TYPES.NEED, item_id: needId})
      .subscribe((mods: Modification[]) => {
        let controlStates: any = {};

        mods.forEach((modification) => {
          controlStates[modification.modified] = {highlighted: true};
        });

        _.merge(this.controlStates, controlStates);
      });
  }
}
