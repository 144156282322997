import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformConditionService } from '../services/transform-condition.service';
var TransformCondition = /** @class */ (function (_super) {
    tslib_1.__extends(TransformCondition, _super);
    function TransformCondition() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformConditionService;
        return _this;
    }
    TransformCondition.prototype.fields = function () {
        return [
            'id',
            'description',
            'transformation_id',
        ];
    };
    return TransformCondition;
}(ActiveRecord));
export { TransformCondition };
