import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MdDialog, MdDialogRef} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ImportSpinnerComponent} from '../../../../entities/deposit/dialogs/import-spinner/import-spinner.component';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {Subscription} from 'rxjs';
import {
  IErrorImportErrorRecordContainer,
  IErrorReadyCompletionRecordContainer,
  ISpinnerCompletionRecordContainer
} from '../../../../shared/spinner/spinner-completion.interface';
import {DepositImportService} from '../../../../entities/deposit/services/deposit-import.service';
import {DepositImport} from '../../../../entities/deposit/models/deposit-import.class';
import {IMPORT_ERROR, IMPORT_LOCATION_ERROR} from '../../../../entities/deposit/values/import-error.const';
import {DepositImportErrorsComponent} from '../../../../entities/deposit/dialogs/deposit-import-errors/deposit-import-errors.component';
import {EErrorStatus} from '../../../../shared/spinner/spinner-completion.enum';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {ImportProgressService} from '../../../services/import-progress.service';
import {MAP_IMPORT_PROGRESS_STATUS} from '../../../values/import-progres.const';
import {ImportSuccessSummaryService} from '../../../../shared/services/import-success-summary.service';
import {DepositImportSuccessComponent} from '../../../../entities/deposit/dialogs/deposit-import-success/deposit-import-success.component';
import {ImportDeepstreamService} from '../../../services/import-deepstream.service';
import {ImportWorkerService} from '../../../services/import-worker.service';
import {EImportEntityStatus} from '../../../values/enums/import-entity-status.enum';
import {ActiveImport} from '../../../models/active-import.class';
import {ActiveImportService} from '../../../services/active-import.service';
import {ELastImportType} from '../../../values/enums/last-import-type.enum';
import {IConfirmDialogIconData} from '../../../../shared/confirm-dialog/values/interface/confirm-dialog-data.interface';
import {EConfirmDialogIcon} from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import {ImportSuccessReport} from '../../../../entities/deposit/models/import-success-summary.class';
import {DepositLocationImportService} from '../../../../entities/deposit/services/deposit-location-import.service';
import {
  NotificationWarningDialogComponent
} from '../../../../shared/notification-dialogs/notification-warning/notification-warning-dialog.component';
import {INotificationWarningData} from '../../../../shared/notification-dialogs/interfaces/notification-warning-data';
import {IDepositLocationSummarySuccess} from '../../../../entities/deposit/values/deposit-location-summary-success.interface';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {delay, first} from 'rxjs/operators';


@Component({
  moduleId: module.id,
  selector: 'import-progress-wrapper',
  styleUrls: ['../import-progress.component.scss'],
  templateUrl: 'import-progress-wrapper.component.html'
})

export class ImportProgressWrapperComponent implements OnInit, OnDestroy {

  private spinnerPercentage = new BehaviorSubject<number>(0);
  private spinnerTotal = new BehaviorSubject<number>(0);
  private errors = [];
  private importParsedRowsStreamSubscription: Subscription;
  private importErrorStreamSubscription: Subscription;
  private importCompletionStreamSubscription: Subscription;
  private importFatalErrorStreamSubscription: Subscription;

  private importSpinnerDialogRef: MdDialogRef<ImportSpinnerComponent>;
  private importErrorDialogRef: MdDialogRef<DepositImportErrorsComponent>;
  private importSuccessDialogRef: MdDialogRef<any>;
  private readSubscription;
  private successSummary: Observable<any>;
  private typeCheckSubscription: Subscription;
  private errorCheckSubscription: Subscription;

  constructor(
    public importProgressService: ImportProgressService,
    public depositImportService: DepositImportService,
    public depositLocationImportService: DepositLocationImportService,
    private _dialog: MdDialog,
    private ds: ImportDeepstreamService,
    private _translate: TranslateService,
    private zone: NgZone,
    private logNotificationService: LogNotificationService,
    private importSuccessSummaryService: ImportSuccessSummaryService,
    private importWorkerService: ImportWorkerService,
    private activeImportService: ActiveImportService,
    private translateService: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.subscribe();
    this.importWorkerService.getImportIfExist().subscribe((activeImports: ActiveImport[]) => {
      const activeImport = activeImports.length ? activeImports[0] : new ActiveImport();
      if (activeImport.id) {
        this.ds.setType(activeImport.import_type);
        this.importProgressService.currentDepositId = activeImport.import_id;
        this.changeProgress(activeImport.status);
      }
    });
    this.typeCheckSubscription = this.ds.emitTypeChange.subscribe(() => {
        this.unsubscribe();
        this.subscribe();
      });
    this.readSubscription = this.importProgressService.resetProgress$
      .subscribe((isNew) => {
        if (!isNew) {
          this.activeImportService.read(this.importProgressService.currentDepositId).subscribe();
        } else {
          this.onMaximize();
        }
        this.resetProgress();
      });
  }

  subscribe() {
    const stream$ = this.ds.getStream();
    this.importParsedRowsStreamSubscription = stream$.depositImportParsedRowsStream$
      .filter(e => this.isOngoingImport(e))
      .subscribe((e: ISpinnerCompletionRecordContainer) => {
        const progress = this.toPercent(e.data.count, e.data.total, false);
        this.importProgressService.setCheckingmodeProgressModal();
        this.importProgressService.setImportPercentageValue(progress);
        this.zone.run(() => this.spinnerPercentage.next(progress));
        this.zone.run(() => this.spinnerTotal.next(e.data.total + 1));
      });

    this.importErrorStreamSubscription = stream$
      .depositImportErrorsStream$.filter(e => this.isOngoingImport(e))
      .subscribe((e: IErrorReadyCompletionRecordContainer) => {
        this.showErrorsComponent(e.data.import_id);
        this.importProgressService.setErrorModeProgressModal();
      });

    this.importCompletionStreamSubscription = stream$
      .depositCompletionStream$.filter(e => this.isOngoingImport(e))
      .subscribe((e: ISpinnerCompletionRecordContainer) => {
        if (this.errorCheckSubscription) {
          this.errorCheckSubscription.unsubscribe();
          if (!!this.importSpinnerDialogRef) {
            this.importSpinnerInstance.changeStep(this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD);
          }
        }
        const isCompletedOnSuccessMessage = e.data.success !== undefined && !!e.data.success;
        const progress = this.toPercent(e.data.count, e.data.total, isCompletedOnSuccessMessage);
        this.importProgressService.setImportPercentageValue(progress);
        this.importProgressService.setProgressModeProgressModal();
        this.zone.run(() => this.spinnerPercentage.next(progress));
        this.zone.run(() => this.spinnerTotal.next(e.data.total + 1));
        this.checkImportSuccess();
      });

    this.importFatalErrorStreamSubscription = stream$
      .depositImportFatalError$.filter(e => this.isOngoingImport(e))
      .subscribe((e: IErrorImportErrorRecordContainer) => {
        if (e.data.error_status === EErrorStatus.UNKNOWN) {
          this.logNotificationService.error('Failed to create the content for unknown reason', undefined, true);
          if (!!this.importSpinnerDialogRef) {
            this.destroyImportSpinner();
          }
          this.zone.run(() => this.importProgressService.setErrorModeProgressModal());
          this.resetProgress();
        }
      });
  }

  private changeProgress(activeImportStatus: EImportEntityStatus) {
    switch (activeImportStatus) {
      case EImportEntityStatus.CHECKED_UNREAD:
        this.importProgressService.showImportProgressModal();
        this.showErrorsComponent(this.importProgressService.currentDepositId);
        this.importProgressService.setErrorModeProgressModal();
        break;
      case EImportEntityStatus.WORKING:
        this.importProgressService.showImportProgressModal();
        break;
      case EImportEntityStatus.QUEUED:
        this.importProgressService.showImportProgressModal();
        this.importProgressService.setCheckingmodeProgressModal();
        break;
      case EImportEntityStatus.ERROR_UNREAD:
        this.importProgressService.showImportProgressModal();
        this.showErrorsComponent(this.importProgressService.currentDepositId);
        this.importProgressService.setErrorModeProgressModal();
        break;
      case EImportEntityStatus.SUCCESS_UNREAD:
        this.importProgressService.showImportProgressModal();
        this.onImportSuccess();
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.typeCheckSubscription.unsubscribe();
    this.readSubscription.unsubscribe();
  }

  unsubscribe() {
    if (this.importParsedRowsStreamSubscription) {
      this.importParsedRowsStreamSubscription.unsubscribe();
    }
    if (this.importErrorStreamSubscription) {
      this.importErrorStreamSubscription.unsubscribe();
    }
    if (this.importCompletionStreamSubscription) {
      this.importCompletionStreamSubscription.unsubscribe();
    }
    if (this.importFatalErrorStreamSubscription) {
      this.importFatalErrorStreamSubscription.unsubscribe();
    }
  }

  private resetProgress() {
    this.zone.run(() => this.spinnerPercentage.next(0));
    this.zone.run(() => this.spinnerTotal.next(0));
    this.importProgressService.setImportPercentageValue(0);
  }

  get importService() {
    if (this.ds.getType() === ELastImportType.DEPOSIT) {
      return this.depositImportService;
    } else if (this.ds.getType() === ELastImportType.SITES) {
      return this.depositLocationImportService;
    }
    return this.depositImportService;
  }

  get importSpinnerInstance(): ImportSpinnerComponent {
    return !!this.importSpinnerDialogRef && this.importSpinnerDialogRef.componentInstance;
  }

  onMaximize() {
    switch (this.importProgressService.statusImportProgress) {
      case MAP_IMPORT_PROGRESS_STATUS.ERROR:
        this.toggleErrorDialog();
        break;
      case MAP_IMPORT_PROGRESS_STATUS.PROGRESS:
        this.toggleLoadingSpinner();
        break;
      case MAP_IMPORT_PROGRESS_STATUS.CHECKING:
        this.toggleLoadingSpinner();
        break;
      case MAP_IMPORT_PROGRESS_STATUS.SUCCESS:
        this.toggleSuccessDialog();
        break;
    }
  }

  checkImportSuccess(): void {
    //   if (!!this.importSpinnerInstance && this.importSpinnerInstance.waitingForErrors) {
    //     this.importSpinnerInstance.waitForImport();
    //   }
    console.log('Check import success');
    if (this.spinnerPercentage.getValue() === 100) {
      this.onImportSuccess();
    }
  }

  onImportSuccess(): void {
    this.zone.run(() => {
      this.importProgressService.setSuccessModeProgressModal();
      this.destroyImportSpinner();
    });
    this.resetProgress();
    this.preloadSuccessReport();
    // this.unsubscribe();
    // this.logNotificationService.success('Import successful', 2000, true);
  }

  private preloadSuccessReport() {
    this.successSummary = new Subject<any>();
    let request;
    let fallbackValue;
    if (this.ds.getType() === ELastImportType.DEPOSIT) {
      fallbackValue = new ImportSuccessReport();
      request = this.importSuccessSummaryService.getImportSummarySuccess(this.importProgressService.currentDepositId);
    } else if (this.ds.getType() === ELastImportType.SITES) {
      fallbackValue = [];
      request = ((this.importService as any) as DepositLocationImportService)
        .getSuccessSummary(this.importProgressService.currentDepositId, {expand: 'deposit_location' });
    }
    request.pipe(delay(1000)).subscribe(value => {
          (this.successSummary as Subject<any>).next(value);
          this.successSummary = Observable.of(value);
        }, () => {
          (this.successSummary as Subject<any>).next(fallbackValue);
          this.successSummary = Observable.of(fallbackValue);
        });
  }

  destroyImportSpinner(): void {
    // this.isImportInProcess = false;
    // tslint:disable-next-line:no-unused-expression
    this.importSpinnerDialogRef && this.zone.run(() => {
      this.importSpinnerDialogRef.close();
      this.importSpinnerDialogRef.afterClosed().subscribe(() => {
        this.importSpinnerDialogRef = void 0;
        this.spinnerPercentage.next(0);
      });
    });
  }

  private isOngoingImport(e: IErrorReadyCompletionRecordContainer | ISpinnerCompletionRecordContainer) {
    const importId = e.data.import_id;
    return importId === this.importProgressService.currentDepositId;
  }

  private toPercent(value: number, total: number, allowHundredPercent = true) {
    const progress = Math.trunc((value / total) * 100);
    return (progress === 100 && !allowHundredPercent) ? 99 : progress;
  }

  private showErrorsComponent(depositImportId: number) {
    this.errorCheckSubscription = this.importService.view(depositImportId, {expand: 'errors'})
      .subscribe((data: DepositImport) => {
        this.errors = this.getErrors(data.errors);
        if (!!this.errors.length) {
          // this.isImportInProcess = false;
          if (this.importSpinnerDialogRef) {
            this.zone.run(() => {
              this.importSpinnerDialogRef.close();
              this.importSpinnerDialogRef = void 0;
            });
          }
          this.resetProgress();
          this.zone.run(() => {
            if (!!this.importErrorDialogRef && !!this.importErrorDialogRef.componentInstance) {
              return;
            }
            this.toggleErrorDialog();
          });
        } else {
          // this.importProgressService.setCurrentStep(this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD);
          this.importProgressService.setProgressModeProgressModal();
          if (!!this.importSpinnerDialogRef) {
            this.importSpinnerInstance.waitForImport();
            this.importSpinnerInstance.changeStep(this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD);
            this.spinnerPercentage.next(0);
          }
        }
      });
  }

  private getErrors(errors) {
    const importErrors = this.ds.getType() === ELastImportType.SITES ? IMPORT_LOCATION_ERROR : IMPORT_ERROR;
    return !!errors.length ? errors.map(e => {
      const errorDescription = importErrors[e.error_type] ? this.translate(importErrors[e.error_type], e.value) : '';
      return {error: errorDescription, line: e.row, value: e.value};
    }) : [];
  }

  private translate(key: string, x): string {
    let message = '';
    this._translate.get(key, {X: x}).subscribe((res: string) => {
      message += res;
    });
    return message;
  }

  toggleErrorDialog() {

    if (!!this.importErrorDialogRef) {
      this.importErrorDialogRef.close();
      return;
    }

    this.importErrorDialogRef = this._dialog.open(DepositImportErrorsComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
      data: {color: 'green', errors: this.errors}
    });

    this.importErrorDialogRef.afterClosed().subscribe(() => {
      this.importErrorDialogRef = void 0;
      // this.spinnerPercentage.next(0);
    });
  }


  toggleLoadingSpinner() {
    if (!!this.importSpinnerDialogRef) {
      this.importSpinnerDialogRef.close();
      return;
    }
    this.importSpinnerDialogRef = this._dialog.open(ImportSpinnerComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
      data: {
        percentage: this.spinnerPercentage,
        totals: this.spinnerTotal,
        progressLabel:
          this.ds.getType() === ELastImportType.DEPOSIT ?  'Import of deposits ...' : 'Import of sites ...',
        stepLabel: {
          [this.importProgressService.DEPOSIT_IMPORT_STEP.VALIDATION]: 'Checking for errors'
        },
        stepShowProgress: {
          [this.importProgressService.DEPOSIT_IMPORT_STEP.VALIDATION]: {
            percentage: false,
            totals: true,
          },
          [this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD]: {
            percentage: true,
            totals: true,
          }
        },
        footerText: '',
        currentTotalText: this.ds.getType() === ELastImportType.DEPOSIT ?
          '[X] of [Y] imported deposits' : '[X] of [Y] imported sites',
        iconData: {
          iconType: EConfirmDialogIcon.USUAL,
          iconClass: 'icon arrow-down--icon--l'
        } as IConfirmDialogIconData
      }
    });

    this.importSpinnerDialogRef.afterClosed().subscribe(() => {
      this.importSpinnerDialogRef = void 0;
      // this.spinnerPercentage.next(0);
    });
    let step = this.importProgressService.DEPOSIT_IMPORT_STEP.VALIDATION;
    switch (this.importProgressService.statusImportProgress) {
      case MAP_IMPORT_PROGRESS_STATUS.PROGRESS:
        step = this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD;
        break;
    }
    this.importSpinnerInstance.changeStep(step);
    switch (step) {
      case this.importProgressService.DEPOSIT_IMPORT_STEP.VALIDATION:
        this.importSpinnerInstance.showPercentage(false);
        break;
      case this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD:
        this.importSpinnerInstance.showPercentage(true);
        break;
    }
  }

  toggleSuccessDialog() {
    if (!!this.importSuccessDialogRef) {
      this.importSuccessDialogRef.close();
      return;
    }
    this.successSummary.pipe(first()).subscribe((data) => {
      if (this.ds.getType() === ELastImportType.DEPOSIT) {
        this.importSuccessDialogRef = this._dialog.open(DepositImportSuccessComponent, {
          ...DIALOG_THEME.SUCCESS_IMPORT_DEPOSIT,
          data: {report: data}
        });
      } else if (this.ds.getType() === ELastImportType.SITES) {
        this.importSuccessDialogRef = this._dialog.open(NotificationWarningDialogComponent, {
          data: {
            cssClass: 'import-progress-site-success',
            iconData: {
              iconType: EConfirmDialogIcon.SVG,
              primaryFill: true,
              showIconURL: 'assets/svg/done_import_circle_icon.svg'
            },
            showCross: true,
            title: 'IMPORT_SITE_SUCCESS_SUMMARY_TITLE',
            body: this.translateService.instant('IMPORT_SITE_NUMBER_CREATED_PLURAL', {X: data.length}),
            bodyHint: 'DEPOSIT_LOCATION_IMPORT_SUCCESS_HINT',
            warnings: (data as IDepositLocationSummarySuccess[]).map(item => ({
              titleText: item.deposit_location.name, text: item.deposit_location.address
            }))
          } as INotificationWarningData
        });
      }
      if (this.importSuccessDialogRef) {
        this.importSuccessDialogRef.afterClosed().subscribe(() => {
          this.importSuccessDialogRef = void 0;
          // this.spinnerPercentage.next(0);
        });
      }
    });
  }
}
