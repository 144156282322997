import { StatsContainer } from './stats-container.class';
import { ILiteral } from 'ng2-adn-common';
import { ISupervisorStats } from '../interfaces/supervisor-stats.interface';
import { SupervisorStats } from './supervisor-stats.class';

export class SupervisorStatsContainer extends StatsContainer<ISupervisorStats> {

  extractData(data: any): any {
    let json = super.extractData(data);
    this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
    return json;
  }

  createModel(json: ILiteral, key: string[]) {
    key.forEach((k) => {
      if (!!json[k]) {
        json[k] = json[k].map((data: ILiteral) => new SupervisorStats(data));
      }
    });
  }

  getDepositsTotals() {
    let result: any = {rejected:{},characterized:{},contracts:{}};
    let keys = ['total','last_year','last_month','last_week'];
    keys.forEach(key => {
      let deposits_published = 0, deposits_rejected = 0, deposits_characterized = 0, deposit_contracts = 0;
      if ((<any>this)[key]) {
        (<any>this)[key].forEach((item: SupervisorStats) => {
          if (item.deposits_published) {
            deposits_published += item.deposits_published;
          }
          if (item.deposits_rejected) {
            deposits_rejected += item.deposits_rejected;
          }
          if (item.deposits_characterized) {
            deposits_characterized += item.deposits_characterized;
          }
          if (item.deposit_orders_processed) {
            deposit_contracts += item.deposit_orders_processed;
          }
        });
      }
      result.rejected[key] = this.calculatePercentage(deposits_rejected,deposits_published+deposits_rejected);
      result.characterized[key] = this.calculatePercentage(deposits_characterized,deposits_published+deposits_rejected);
      result.contracts[key] = deposit_contracts;
    });
    return result;
  }

  getNeedsTotals() {
    let result: any = {rejected:{},contracts:{}};
    let keys = ['total','last_year','last_month','last_week'];
    keys.forEach(key => {
      let needs_published = 0, needs_rejected = 0, need_contracts = 0;
      if ((<any>this)[key]) {
        (<any>this)[key].forEach((item: SupervisorStats) => {
          if (item.needs_published) {
            needs_published += item.needs_published;
          }
          if (item.needs_rejected) {
            needs_rejected += item.needs_rejected;
          }
          if (item.offers_processed) {
            need_contracts += item.offers_processed;
          }
        });
      }
      result.rejected[key] = this.calculatePercentage(needs_rejected,needs_published+needs_rejected);
      result.contracts[key] = need_contracts;
    });
    return result;
  }

  getTransformationsTotals() {
    let result: any = {rejected:{},contracts:{}};
    let keys = ['total','last_year','last_month','last_week'];
    keys.forEach(key => {
      let transformations_published = 0, transformations_rejected = 0, transformation_contracts = 0;
      if ((<any>this)[key]) {
        (<any>this)[key].forEach((item: SupervisorStats) => {
          if (item.transformations_published) {
            transformations_published += item.transformations_published;
          }
          if (item.transformations_rejected) {
            transformations_rejected += item.transformations_rejected;
          }
          if (item.transformatin_orders_processed) {
            transformation_contracts += item.transformatin_orders_processed;
          }
        });
      }
      result.rejected[key] = this.calculatePercentage(transformations_rejected,transformations_published+transformations_rejected);
      result.contracts[key] = transformation_contracts;
    });
    return result;
  }

  calculatePercentage(value: number, total: number): string {
    if (total === 0) {
      return '0%';
    }
    return (value / total * 100).toFixed(1) + '%';
  }
}
