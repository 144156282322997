import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
;

@Component({
  moduleId: module.id,
  selector: 'deposit-export-dialog',
  templateUrl: 'deposit-export-dialog.component.html',
  styleUrls :['deposit-export-dialog.component.scss'],
})
export class DepositExportDialogComponent implements OnInit {

 
    data : any ;

  constructor(public _dialog: MdDialogRef<any>,
    private _depositService: DepositService,
              ) {
    this.data = this._dialog._containerInstance.dialogConfig.data.data;
  }

  ngOnInit() {
      console.log(this.data);  
  }

  getPdfReport() {
    //console.log(this.data)
    return this._depositService.getPdfReportUrl(this.data);
}

getExcelReport() {
    
    return this._depositService.getXlsReportUrl(this.data);
    
  }
  

  
}
