/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "../../components/esm-need-picker/esm-need-picker.component.ngfactory";
import * as i3 from "../../components/esm-need-picker/esm-need-picker.component";
import * as i4 from "../../../../entities/need/services/need.service";
import * as i5 from "../../../../entities/need/services/need-client-list.service";
import * as i6 from "../../../../entities/need/services/need-sales-list.service";
import * as i7 from "./esm-need-picker-dialog.component";
var styles_EsmNeedPickerDialogComponent = [];
var RenderType_EsmNeedPickerDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EsmNeedPickerDialogComponent, data: {} });
export { RenderType_EsmNeedPickerDialogComponent as RenderType_EsmNeedPickerDialogComponent };
export function View_EsmNeedPickerDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "md-dialog-content", [["class", "ecosystem-dashboard__need-dialog"]], [[2, "mat-dialog-content", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MdPrefixRejector, [[2, i1.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i1.MdDialogContent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "need-picker", [], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.selectValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EsmNeedPickerComponent_0, i2.RenderType_EsmNeedPickerComponent)), i0.ɵdid(4, 49152, null, 0, i3.EsmNeedPickerComponent, [i4.NeedService, i5.NeedClientListService, i6.NeedSalesListService], null, { onSelect: "onSelect" })], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_EsmNeedPickerDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "need-picker-dialog", [], null, null, null, View_EsmNeedPickerDialogComponent_0, RenderType_EsmNeedPickerDialogComponent)), i0.ɵdid(1, 49152, null, 0, i7.EsmNeedPickerDialogComponent, [i1.MdDialogRef], null, null)], null, null); }
var EsmNeedPickerDialogComponentNgFactory = i0.ɵccf("need-picker-dialog", i7.EsmNeedPickerDialogComponent, View_EsmNeedPickerDialogComponent_Host_0, {}, {}, []);
export { EsmNeedPickerDialogComponentNgFactory as EsmNeedPickerDialogComponentNgFactory };
