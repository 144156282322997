import {AbstractControl, FormArray, FormGroup} from '@angular/forms';

export function touchEachFormControl(form: AbstractControl) {
  let controls = [];
  form.markAsTouched();
  if (form instanceof FormGroup) {
    controls = Object.values(form.controls);
  } else if (form instanceof FormArray) {
    controls = form.controls;
  }
  controls.forEach(control => {
    if (form instanceof FormArray || form instanceof FormGroup) {
      touchEachFormControl(control);
    } else {
      control.markAsTouched();
    }
  });
}
