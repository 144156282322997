<div class="ecosystem-edit">
  <article class="ecosystem-edit__editor">
    <header class="ecosystem-edit__header">
      <section class="ecosystem-edit__controls">
        <ul class="ecosystem-edit__control-list">
          <li class="ecosystem-edit__control-list-item">
            <ul class="ecosystem-edit__control-item--status_and_name_wrapper">
              <li class="ecosystem-edit__control-item ecosystem-edit__control-item--status">
                <div
                  class="status-badge status-badge--{{ECOSYSTEM_STATUS[ecosystem.status]?.toLowerCase()}}"
                ><div>{{(ECOSYSTEM_STATUS_VIEW[ecosystem.status] || 'unknown') | translate}}</div>
                </div>
              </li>
              <li class="ecosystem-edit__control-item ecosystem-edit__control-item--caption">
                <p>{{ 'Ecosystem name' | translate }}</p>
                <input type="text"
                       autocomplete="off"
                       [(ngModel)]="ecosystem.name"
                       (focus)="onFocusChanged(true)"
                       (blur)="onFocusChanged(false)"
                >
              </li>
            </ul>
          </li>
          <li class="ecosystem-edit__control-item ecosystem-edit__control-item--buttons">
            <ng-container *ngFor="let button of buttonControlGroup">
              <button *ngIf="button.isExist()"
                class="ecosystem-edit__control-button"
                (click)="button.onClick()">
                <div class="ecosystem-edit__button-title">
                  <div class="icon-container">
                    <div *ngIf="button.iconGroupKey && button.iconGroupKey === ICON_GROUP_KEY.ADD_MESSAGE"
                         class="ecosystem-edit__button-title-icon-group
                          ecosystem-edit__button-title-icon-group--align-left">
                      <i class="icon icon--s icon--close-grey ecosystem-edit__button-title-icon--rotate"></i>
                      <i class="icon--portrait icon--ecosystem-message"></i>
                    </div>
                    <i *ngIf="button.iconClass" [ngClass]="button.iconClass"></i>
                  </div>
                  <div class="ecosystem-edit__button-name">
                    <div>{{ button.name | translate }}</div>
                  </div>
                </div>
              </button>
            </ng-container>
          </li>
        </ul>
      </section>
    </header>

    <main class="ecosystem-edit__workspace-container">
      <div class="ecosystem-edit__progress-sidebar">
        <div class="ecosystem-edit__progress-sidebar-button-wrapper">
          <button (click)="openDescription()" class="ecosystem-edit__progress-sidebar-button">
            <i class="icon icon--description"></i>
          </button>
          <p class="ecosystem-edit__progress-sidebar-description">{{'Description' | translate}}</p>
        </div>
        <div class="ecosystem-edit__progress-sidebar-button-wrapper">
          <button (click)="openIndicators()" class="ecosystem-edit__progress-sidebar-button">
            <i class="icon icon--indicators"></i>
          </button>
          <p class="ecosystem-edit__progress-sidebar-description">{{'Indicators' | translate}}</p>
        </div>
        <div class="ecosystem-edit__progress-sidebar-button-wrapper">
          <button (click)="openFiles()" class="ecosystem-edit__progress-sidebar-button">
            <i class="icon icon--paper-clip"></i>
          </button>
          <p class="ecosystem-edit__progress-sidebar-description">{{'Files' | translate}}</p>
        </div>
      </div>
      <div class="ecosystem-edit__workspace">
        <section class="ecosystem-edit__status-bar">
          <div class="ecosystem-edit__modification-dates">
            <span>{{ 'Created on' | translate }} {{ecosystem.created_at| date:'dd.MM.y'}} <span
              class="lowercase">{{ 'By' | translate }}</span>{{' '}}
              <span class="link">{{ecosystem.creator_name}}</span>
            </span>
            <span>{{ 'Modified on' | translate }} {{ecosystem.updated_at| date:'dd.MM.y'}} <span
              class="lowercase">{{ 'By' | translate }}</span>{{' '}}
              <span class="link">{{ecosystem.updater_name}}</span>
            </span>
          </div>
        </section>
        <ecosystem-tree
          *ngIf="ecosystem"
          class="ecosystem-edit__tree"
          [ecosystem]="ecosystem"
          [(activeStep)]="activeStep"
          [(openChat)]="openChat"
        ></ecosystem-tree>
      </div>
    </main>
  </article>
  <aside [ngClass]="{'ecosystem-edit__sidebar': true,
  'ecosystem-edit__sidebar-magic-view' : _isCompilerExtended && !activeStep,
  'ecosystem-edit__sidebar--compiler-with-title':
  activeStep?.type === ECOSYSTEM_STEP_TYPE.NEED ||
  activeStep?.type === ECOSYSTEM_STEP_TYPE.DEPOSIT ||
  activeStep?.type === ECOSYSTEM_STEP_TYPE.TRANSFORM}"
         *ngIf="ecosystem.canEditSteps || activeStep">
    <header class="ecosystem-edit__sidebar-header" (click)="toggleExpand()">
      <h4 class="ecosystem-edit__sidebar-title">{{ 'Ecosystem Compiler' | translate }}</h4>
      <div class="ecosystem-compiler__expander">
        <button class="ecosystem-compiler__fold-tab button-transparent">
          <i class="icon" [ngClass]="isCompilerExpanded ? 'icon--fold-tab' : 'icon--unfold-tab' "></i>
        </button>
      </div>
    </header>

    <ecosystem-step-search
      *ngIf="isCompilerExpanded && !activeStep && ecosystem.canEditSteps"
      [ecosystemNeedId]="ecosystem.need_id"
      (onExtendCompiler)="toggleCompilerExtendedView($event)"
    ></ecosystem-step-search>
    <article class="ecosystem-edit__active-step" *ngIf="isCompilerExpanded && activeStep">

      <div *ngIf="!activeStep.isStepValid()">
        <section class="ecosystem-compiler ecosystem-compiler__not-valid">
            {{  activeStep.getInvalidTitle() | translate }}
        </section>
      </div>

      <esm-compiler-need
        *ngIf="activeStep.type === ECOSYSTEM_STEP_TYPE.NEED &&  activeStep.isStepValid()"
        [step]="activeStep"
        (stepChange)="onStepChange($event)"
        [openChat]="openChat"
        #activeStepComponent
      ></esm-compiler-need>
      <esm-compiler-transformation
        *ngIf="activeStep.type === ECOSYSTEM_STEP_TYPE.TRANSFORM && activeStep.isStepValid()"
        [step]="activeStep"
        [openChat]="openChat"
        #activeStepComponent
      ></esm-compiler-transformation>
      <esm-compiler-stakeholder
        *ngIf="activeStep.type === ECOSYSTEM_STEP_TYPE.STAKEHOLDER"
        [step]="activeStep"
        #activeStepComponent
      ></esm-compiler-stakeholder>
      <esm-compiler-deposit
        *ngIf="activeStep.type === ECOSYSTEM_STEP_TYPE.DEPOSIT && activeStep.isStepValid()"
        [step]="activeStep"
        [openChat]="openChat"
        #activeStepComponent
      ></esm-compiler-deposit>
      <esm-compiler-virgin-product
        *ngIf="activeStep.type === ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT"
        [step]="activeStep"
        #activeStepComponent
      ></esm-compiler-virgin-product>
    </article>
  </aside>
</div>

