import {Component, NgZone} from '@angular/core';
import {OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../entities/client/service/language.service';
import {Eula} from '../../shared/models/eula.class';
import {IEula} from '../../shared/interfaces/eula.interface';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {EulaService} from '../services/eula.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../shared/models/user.class';
import {AuthService} from '../../shared/services/auth.service';
import {CurrentUserService} from '../../shared/services/current-user.service';
import { TLanguage } from '../../shared/types/language-type';
import { FilterStateService } from '../../shared/services/filterState.service';
import { NavbarStateService } from '../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'activating-account',
  templateUrl: 'activating-account.component.html',
  styleUrls: ['activating-account.component.scss'],
})

export class ActivatingAccountComponent implements OnInit {
  public activeStep: number = 1;
  public isAgreedWithEULA: boolean = false;
  public activationAuthKey: string;
  private eula: IEula;
  public user: User;
  public allowedForRender: boolean;

  constructor(
    private _translateService: TranslateService,
    protected router: Router,
    protected _languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private eulaService: EulaService,
    private sanitizer: DomSanitizer,
    private userService: CurrentUserService,
    private authService: AuthService,
    private filterStateService: FilterStateService,
    private navbarStateService: NavbarStateService
  ) {
  }

  ngOnInit() {
    this._languageService.initLang();
    this.checkAndSetPermissions();
    this.requestAndSetLastEula();
  }

  checkAndSetPermissions(): void {
    this.activationAuthKey = this.activatedRoute.snapshot.queryParams['auth_key'];
    if (!this.userService.isUsernameStored()) {
      (!this.activationAuthKey)
        ? this.router.navigate([''])
        : this.allowRender();
      return;
    }
    this.userService.info()
      .map(res => res.json())
      .subscribe(user => {
        this.user = user;
        if (this.user.has_accepted_eula) {
          return this.router.navigate(['']);
        }
        this.allowRender();
      });
  }

  setLang(lang: TLanguage) {
    this._languageService.setLang(lang);
  }

  getLangImage(): string {
    return this.getImage(this._languageService.getUsedLanguage());
  }

  getImage(language: string) {
    switch (language) {
      case 'en': {
        return '/assets/svg/en-lang--icon.svg';
      }
      case 'fr': {
        return '/assets/svg/fr-lang--icon.svg';
      }
      case 'es': {
        return '/assets/svg/es-lang--icon.svg';
      }
      case 'pt': {
        return '/assets/svg/pt-lang--icon.svg';
      }
    }
  }

  getImageMainBlock() {
    switch (this._languageService.getUsedLanguage()) {
      case 'en': {
        return '/assets/png/en-upcyclea-team.png';
      }
      case 'fr': {
        return '/assets/png/fr-upcyclea-team.png';
      }
      case 'es': {
        return '/assets/png/en-upcyclea-team.png';
      }
      case 'pt': {
        return '/assets/png/en-upcyclea-team.png';
      }
      default : 
      return '/assets/png/en-upcyclea-team.png';
    }
  }

  setStepNumber(index: number) {
    this.activeStep = index;
  }

  onEulaAcceptance() {
    this.router.navigate(['login/confirmation-password'], {
      queryParams: {
        auth_key: this.activationAuthKey
      }
    });
  }

  onRedirectedFromLoginAcceptance() {
    this.eulaService.accept({
      user_id: this.user.id,
      eula_id: this.eula.id
    })
      .subscribe((res: any) => {
        const role = this.userService.userDefaultContext;
        this.router.navigate([role.homeUrl || '']);
      });
  }

  refuseEula(): void {
    this.authService.logout().subscribe(() => {
      this.filterStateService.depositList = null;
      this.filterStateService.productPassportList = null;
      this.navbarStateService.multilanguage = false;
    });
  }

  requestAndSetLastEula(): any {
    this.eulaService.getMostRecent()
      .subscribe((res) => this.eula = new Eula(res));
  }

  getSafePdfSource(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.eula.getPdfUrl(this._languageService.getUsedLanguage()));
  }

  allowRender(): void {
    this.allowedForRender = true;
  }
}
