import { OnDestroy, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { DepositLocationStatusName, EDepositLocationStatus } from '../../../../entities/deposit/values/deposit-location-status.enum';
import { ESiteRequestType } from '../../values/site-request-type.enum';
import { RequestFileService } from '../../services/request-file.service';
import { TranslateService } from '@ngx-translate/core';
import { RequestFileSenderService } from '../../services/request-file-sender.service';
import { allowOnlyPositiveNumbers } from '../../../../shared/helpers/proceed-if-non-negative-int';
var RequestSiteCreationDialogComponent = /** @class */ (function () {
    function RequestSiteCreationDialogComponent(formBuilder, sender, translate, requestFileService, dialog) {
        var _a;
        this.formBuilder = formBuilder;
        this.sender = sender;
        this.translate = translate;
        this.requestFileService = requestFileService;
        this.dialog = dialog;
        this.DEPOSIT_LOCATION_STATUS_OPTIONS = Object.entries(DepositLocationStatusName).map(function (_a) {
            var key = _a[0], name = _a[1];
            return ({ value: key, name: name });
        });
        this.DEPOSIT_LOCATION_STATUS = EDepositLocationStatus;
        this.FRStatusTranslation = (_a = {},
            _a[EDepositLocationStatus.IN_CONSTRUCTION] = 'en construction',
            _a[EDepositLocationStatus.IN_OPERATION] = 'en exploitation',
            _a[EDepositLocationStatus.STORAGE] = 'stockage',
            _a);
        this.isExpireFilledRequired = this.isExpireFilledRequired.bind(this);
    }
    RequestSiteCreationDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            address: ['', Validators.required],
            area: ['', [Validators.required, Validators.min(0)]],
            living_area: ['', [Validators.required, Validators.min(0)]],
            status: ['', Validators.required],
            expire: ['', this.expireValidator()]
        });
        this.updateStatusObserver = this.form.controls['status'].valueChanges.subscribe(function (value) {
            _this.form.controls['status'].setValue(value, { emitEvent: false });
            _this.form.controls['expire'].updateValueAndValidity();
        });
        this.updateAreaObserver = allowOnlyPositiveNumbers(this.form.controls['area']);
        this.updateLivingAreaObserver = allowOnlyPositiveNumbers(this.form.controls['living_area']);
    };
    RequestSiteCreationDialogComponent.prototype.expireValidator = function () {
        var _this = this;
        return function (control) {
            return _this.isExpireFilledRequired(control) ? { message: 'Expire required' } : null;
        };
    };
    RequestSiteCreationDialogComponent.prototype.isExpireFilledRequired = function (control) {
        var status = this.form ? Number(this.form.value['status']) : null;
        return status === EDepositLocationStatus.IN_CONSTRUCTION && !control.value;
    };
    RequestSiteCreationDialogComponent.prototype.ngOnDestroy = function () {
        if (this.updateStatusObserver) {
            this.updateStatusObserver.unsubscribe();
        }
        if (this.updateAreaObserver) {
            this.updateAreaObserver.unsubscribe();
        }
        if (this.updateLivingAreaObserver) {
            this.updateLivingAreaObserver.unsubscribe();
        }
    };
    Object.defineProperty(RequestSiteCreationDialogComponent.prototype, "status", {
        get: function () {
            return Number(this.form.value.status);
        },
        enumerable: true,
        configurable: true
    });
    RequestSiteCreationDialogComponent.prototype.closeDialog = function () {
        this.dialog.close(null);
    };
    RequestSiteCreationDialogComponent.prototype.confirmRequest = function () {
        var callback = this.transmitRequestAndClose.bind(this);
        this.sender.uploadRequest(callback);
    };
    RequestSiteCreationDialogComponent.prototype.transmitRequestAndClose = function (files) {
        var status = Number(this.form.value['status']);
        var options = {
            type: ESiteRequestType.NEW_SITE,
            site_name: this.form.value['name'],
            surface: this.form.value['area'],
            living_area: this.form.value['living_area'],
            address: this.form.value['address'],
            status: this.FRStatusTranslation[status],
            expiration_date: status === EDepositLocationStatus.IN_CONSTRUCTION ? this.form.value['expire'] : null,
            file_ids: files.map(function (file) { return file.id; })
        };
        this.dialog.close(options);
    };
    return RequestSiteCreationDialogComponent;
}());
export { RequestSiteCreationDialogComponent };
