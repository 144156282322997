import {Component, EventEmitter, HostListener, NgZone, OnInit, ViewChild} from '@angular/core';
import {MdDialog} from '@angular/material';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {ENextLive, NextLives} from '../../../../shared/interfaces/filter-states.interface';
import {IMapMarker} from '../../../../shared/map-view/map-marker.interface';
import {NavbarStateService} from '../../../../shared/services/navbar.service';
import {DepositDetailsDialogComponent} from '../../../deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import {Deposit} from '../../../deposit/models/deposit.class';
import {DepositService} from '../../../deposit/services/deposit.service';
import {ViewCategoryDialogComponent} from '../../../passport/dialogs/view-category/view-category.component';
import {DepositLocation} from '../../../deposit/models/deposit-location.class';
import {EDepositItemType} from '../../../deposit/values/deposit-item-type.enum';
import {PassportFamily} from '../../../passport/models/passport.class';
import {Router} from '@angular/router';
import {DepositLocationService} from '../../../deposit/services/deposit-location.service';
import {DepositFilter} from '../../../deposit/models/deposit-filter';
import {PaginationUnmarshallingService} from '../../../../shared/services/pagination-unmarshalling.service';
import {DEPOSIT_SORT_NAME} from '../../../deposit/values/deposit-sort.enum';
import {Subscription} from 'rxjs/Subscription';
import * as _ from 'lodash';
import {DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES} from '../../../deposit/values/deposit-reuse-source.const';
import {ConfirmDialogComponent} from '../../../../shared/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep, omit} from 'lodash';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import { UserService } from '../../../../shared/services/user.service';
import { User } from '../../../../shared/models/user.class';


@Component({
  moduleId: module.id,
  selector: 'need-depostlist',
  templateUrl: 'need-deposit-list.component.html',
  styleUrls: ['need-deposit-list.component.scss']
})

export class NeedDepositListComponent implements OnInit {
  public depositSourceList = DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES;
  DEPOSIT_ITEM_TYPE = EDepositItemType;
  markerAvailableOnLabelText: any;
  sites: DepositLocation[] = [];
  siteWithoutNoah: DepositLocation[] = [];
  sitesWithNoah: DepositLocation[] = [];
  sort_order = DEPOSIT_SORT_NAME;
  depositFilter = new DepositFilter({isOnlyActiveSites: true, expand: 'passport,nomenclature,deposit_location,isNoah'});
  public records: Deposit[] = [];
  public ratingNameAlias: Partial<{[x in  keyof DepositFilter['ratingFilter']]: string}> = {
    passportWithoutRating: 'Passports without rating'
  };
  public ratingIconAlias: Partial<{[x in  keyof DepositFilter['ratingFilter']]: string}> = {
    passportWithoutRating: ''
  };
  NextLives = NextLives;
  NextLive = ENextLive;
  isFilterNextLivesVisible = false;
  clearFilterForm = new EventEmitter();
  beforeMapUpdate = new EventEmitter<void>();
  updateMap = new EventEmitter<DepositLocation[]>();
  resetFilters = cloneDeep(omit(this.depositFilter, this.depositFilter.noneResetValues));

  private mapSubscription: Subscription;

  public noahAccess = false;

  @ViewChild('filterNextLives') filterNextLives;
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this.filterNextLives.nativeElement.contains(targetElement)) {
      this.isFilterNextLivesVisible = false;
    }
  }

  constructor(
    private _dialog: MdDialog,
    private depositService: DepositService,
    private navBarStateService: NavbarStateService,
    private router: Router,
    private zone: NgZone,
    private sitesService: DepositLocationService,
    private translate: TranslateService,
    private paginationService: PaginationUnmarshallingService,
    private currentUser: CurrentUserService,
    private userService: UserService) {}

  ngOnInit(): void {
    this.updateList();
    this.initSites();
    this.depositFilter.searchControl
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => {
        this.updateList();
        this.mapChanges();
      });
  }

  /**
   * Initialize the map section
   * Get all the available non-Noah sites
   * And, if the user has access to the noah database, get all the Noah sites
   */
  private initSites() {
    this.beforeMapUpdate.emit();
    this.mapSubscription = this.loadSites(false).subscribe(sites => {
      this.sites = sites;
      this.updateMap.emit(sites);
    });

    this.userService.view(this.currentUser.infoData.id, { expand: 'company' }).subscribe((user: User) => {
      this.noahAccess = user.company.noah_access;
      if (this.noahAccess) {
        this.loadSites(true).subscribe();
      }
    });
  }

  /**
   * Function triggered when the filters are updated
   */
  updateFilters() {
    this.updateList();
    this.mapChanges();
  }

  /**
   * Function called to update the list of deposits
   */
  private updateList() {
    this.depositService.getWithArray(this.buildOptions()).subscribe((deposits) => {
      const pagination = this.paginationService.unmarshall(deposits);
      this.depositFilter.updateRecords(deposits, pagination);
      this.records = this.depositFilter.records.map(data => new Deposit(data));
    });
  }

  /**
   * Function called to update the map
   */
  private mapChanges() {
    this.beforeMapUpdate.emit();
    if (this.mapSubscription) {
      this.mapSubscription.unsubscribe();
    }

    this.mapSubscription = this.loadSites(null, this.buildMapOptions()).subscribe(locations => this.updateMap.emit(locations));
  }

  /**
   * Load sites to show on the map
   * Return the sites which have at least one deposit that corresponds to the criteria
   * @param {Boolean|null} isNoah - True if we want to include the Noah sites, False if we want to exclude the Noah sites, null else
   * @param {Object} filterParams - The list of params to add to the site search
   * @returns {DepositLocation[]}
   */
  private loadSites(isNoah = null, filterParams = {}) {
    let params = Object.assign(filterParams, this.getDefaultMapParams());

    if (isNoah) {
      Object.assign(params, {noah_view: 1});
    }

    return this.sitesService.getMapLocations(params)
      .map((data: DepositLocation[]) => {
        let locations = data.map((datum) => new DepositLocation(datum));
        isNoah === null
          ? params['noah_view'] ? this.sitesWithNoah = locations : this.siteWithoutNoah = locations
          : isNoah ? this.sitesWithNoah = locations : this.siteWithoutNoah = locations;
        return locations;
      });
  }

  /**
   * Get the default params needed to send the location request for the need manager view
   * @returns {Object}
   */
  getDefaultMapParams() {
    return {
      active_sites: true,
      sales_view : 1,
    };
  }

  /**
   * Build the options array to send the request for the deposits
   * @param {DepositFilter} filter - The filter containing the info about the deposits to search for
   * @returns {Array<Array<String|Number|Boolean>>}
   */
  buildOptions(filter = this.depositFilter) {
    let options = filter.buildOptions();
    options = filter.buildRating(options);
    options.push(['sales_view', 1]);
    return options;
  }

  /**
   * Build the options object to use in the request for the locations
   * @returns {Object}
   */
  buildMapOptions() {
    let optionArray = this.depositFilter.buildOptions();
    optionArray = this.depositFilter.buildRating(optionArray);
    let options = optionArray.reduce((result, option) => {
      if (!Object.keys(result).includes(option[0])) {
        result[option[0]] = [option[1]];
      } else {
        result[option[0]].push(option[1]);
      }
      
      return result;
    }, {})
    return options;
  }

  toMarker(data: Deposit): IMapMarker {
    return {
      lat: data.deposit_location.lat || null,
      lng: data.deposit_location.lng || null,
      name: data.name || null,
      lines: [
        `${data.quantity ? data.quantity : 0} ${data.passport.unit_text}`,
        `${this.markerAvailableOnLabelText}: ${data.availability_date ? data.availability_date : ''}`
      ]
    };
  }

  openCategoryDialog(category: any): void {
    category.blur();
    this._dialog.open(ViewCategoryDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    }).afterClosed().subscribe(result => {
      if (result && !this.depositFilter.categoryTags.some(category => category.id === result.id)) {
        this.depositFilter.categoryTags.push(result);
        this.updateFilters();
      }
    });
  }

  removeCategoryTag(tag: any , event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.depositFilter.categoryTags.splice(this.depositFilter.categoryTags.indexOf(tag), 1);
    this.updateFilters();
  }

  get canClearFiltersAlternativeRules() {
    return {
      filterBy: {
        C2cCertified: true,
        composition: {color: true, black: true},
        cycle: {color: true, black: true},
        energy: {color: true, black: true},
        water: {color: true, black: true},
        social: {color: true, black: true},
        passportWithoutRating: true,
        selectRatingFilter: true,
      },
      searchControl: ''
    };
  }

  clearFilters($event) {
    this.depositFilter = Object.assign(this.depositFilter, $event);
    this.clearFilterForm.emit();
    this.updateFilters();
  }

  get nextLivesKeys() {
    return Object.keys(this.depositFilter.nextLives);
  }

  get isAnyNextLivesFilterApplied() {
    return Object.keys(this.depositFilter.nextLives).some(key => this.depositFilter.nextLives[key]);
  }

  get categoryLabel() {
    return this.depositFilter.categoryTags.length === 0 ? 'Product category' : '';
  }

  openDetailView(deposit: Deposit) {
    this._dialog.open(DepositDetailsDialogComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
      data: {
        readOnly: true,
        id: deposit.id,
        showValidationButtons: false,
      },
    });
  }

  createNewNeed(deposit: Deposit) {
    const passport = deposit.passport;
    const navigate = () => this.zone.run(() => this.router.navigate(
      ['/sales/needs/new/deposits', deposit.id, 'independent', deposit.isNoah || '']));
    navigate();
  }

  setChosenLocationsById(locationIds: number[]) {
    const siteMap = new Map<number, DepositLocation>();
    this.sites.forEach(site => siteMap.set(site.id, site));
    this.depositFilter.selectedSites = locationIds.map(id => siteMap.has(id) ? siteMap.get(id) : new DepositLocation({id}));
    this.updateFilters();
  }

  pageChange(page: number) {
      this.depositFilter.currentPage = page;
      this.updateList();
  }

  onToggleChanged(event) {
    this.sites = event.checked ? this.sitesWithNoah : this.siteWithoutNoah;
    this.updateFilters();
  }
}
