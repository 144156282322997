<div [formGroup]="parentForm" class="passport-rating" *ngIf="passport.rating">
    <div class="passport-rating__wrapper">
      <div class="passport-rating__caption-column primary-color">
        <div class="passport-rating__caption-row">
          {{ 'PASSPORT_RATING_UNKNOWN' | translate }}
        </div>
        <div class="passport-rating__caption-row">
          {{ 'PASSPORT_RATING_DECLARATION_VALID' | translate }}
        </div>
        <div class="passport-rating__caption-row">
          {{ 'PASSPORT_RATING_THIRD_PARTY_VALID' | translate }}
        </div>
      </div>
      <div class="passport-rating__rating-block">
        <div class="passport-rating__rating-column">
          <md-radio-group
            [(ngModel)]="passport.rating.rating_composition"
            formControlName="ratingComposition"
            class="passport-rating__rating-column--composition"
          >
            <div class="passport-rating__rating-row-icon icon--rating-composition"></div>
            <div class="passport-rating__rating-row"
                 *ngFor="let value of objectValues(ratingValues)"
            >
              <md-radio-button
                [value]="value"
              >
              </md-radio-button>
            </div>
          </md-radio-group>
        </div>
        <div class="passport-rating__rating-column">
          <md-radio-group
            [(ngModel)]="passport.rating.rating_recyclability"
            formControlName="ratingRecyclability"
            class="passport-rating__rating-column--cycle"
          >
            <div class="passport-rating__rating-row-icon icon--rating-cycle"></div>
            <div class="passport-rating__rating-row"
                 *ngFor="let value of objectValues(ratingValues)"
            >
              <md-radio-button
                [value]="value"
              >
              </md-radio-button>
            </div>
          </md-radio-group>
        </div>
        <div class="passport-rating__rating-column">
          <md-radio-group
            [(ngModel)]="passport.rating.rating_energy"
            formControlName="ratingEnergy"
            class="passport-rating__rating-column--energy"
          >
            <div class="passport-rating__rating-row-icon icon--rating-energy"></div>
            <div class="passport-rating__rating-row"
                 *ngFor="let value of objectValues(ratingValues)"
            >
              <md-radio-button
                [value]="value"
              >
              </md-radio-button>
            </div>
          </md-radio-group>
        </div>
        <div class="passport-rating__rating-column">
          <md-radio-group
            [(ngModel)]="passport.rating.rating_water_stewardship"
            formControlName="ratingWaterStewardship"
            class="passport-rating__rating-column--water"
          >
            <div class="passport-rating__rating-row-icon icon--rating-water"></div>
            <div class="passport-rating__rating-row"
                 *ngFor="let value of objectValues(ratingValues)"
            >
              <md-radio-button
                [value]="value"
              >
              </md-radio-button>
            </div>
          </md-radio-group>
        </div>
        <div class="passport-rating__rating-column">
          <md-radio-group
            [(ngModel)]="passport.rating.rating_social_fairness"
            formControlName="ratingSocialFairness"
            class="passport-rating__rating-column--social"
          >
            <div class="passport-rating__rating-row-icon icon--rating-social"></div>
            <div class="passport-rating__rating-row"
                 *ngFor="let value of objectValues(ratingValues)"
            >
              <md-radio-button
                [value]="value"
              >
              </md-radio-button>
            </div>
          </md-radio-group>
        </div>
      </div>
    </div>
  </div>