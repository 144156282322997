/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./BIM-model-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i5 from "../../../../shared/read-only/read-only.service";
import * as i6 from "@angular/material";
import * as i7 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i8 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i9 from "./BIM-model-dialog.component";
import * as i10 from "../../../../shared/services/bim.service";
import * as i11 from "../../../../entities/deposit/services/deposit-location.service";
var styles_BIMModelDialogComponent = [i0.styles];
var RenderType_BIMModelDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BIMModelDialogComponent, data: {} });
export { RenderType_BIMModelDialogComponent as RenderType_BIMModelDialogComponent };
function View_BIMModelDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "bim-model__toggle-row": 0, "gray": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "bim-model__row-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "bim-model__site-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" - "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "bim-model__site-address"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "bim-model__button-divider-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "bim-model__divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "bim-model__row-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openBIM(_v.context.$implicit.bim_id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "span", [["class", "bim-model__button"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(16, 1)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, true, _v.context.odd); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.address; _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, _ck(_v, 16, 0, i1.ɵnov(_v.parent.parent.parent, 0), i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("open")))); _ck(_v, 14, 0, currVal_3); }); }
function View_BIMModelDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BIMModelDialogComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.group[_v.parent.context.$implicit]; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BIMModelDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "bim-model__toggle-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "bim-model__toggle-header gray"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "bim-model__toggle-header-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "bim-model__tool-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "bim-model__bim-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " ", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "button-transparent"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "i", [["class", "icon"]], null, null, null, null, null)), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BIMModelDialogComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "icon"; var currVal_5 = (_co.isExpanded(_v.context.index) ? "black-arrow-down--icon x5" : "black-arrow-up--icon x5"); _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_6 = _co.isExpanded(_v.context.index); _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getBIMName(_co.group[_v.context.$implicit][0]); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.group[_v.context.$implicit].length; var currVal_2 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 7).transform("BIM models")); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = ((i1.ɵnov(_v, 10).readOnlyService == null) ? null : i1.ɵnov(_v, 10).readOnlyService.readOnly); _ck(_v, 9, 0, currVal_3); }); }
export function View_BIMModelDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 11, "md-dialog-content", [["class", "bim-model"]], [[2, "mat-dialog-content", null]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i6.MdPrefixRejector, [[2, i6.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(3, 16384, null, 0, i6.MdDialogContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DialogCloseButtonComponent_0, i7.RenderType_DialogCloseButtonComponent)), i1.ɵdid(5, 49152, null, 0, i8.DialogCloseButtonComponent, [], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "bim-model__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "bim-model__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BIMModelDialogComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "white"; _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.groupKeys; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).applyClassPosition; _ck(_v, 4, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("BIM models")))); _ck(_v, 7, 0, currVal_3); }); }
export function View_BIMModelDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BIMModelDialogComponent_0, RenderType_BIMModelDialogComponent)), i1.ɵdid(1, 114688, null, 0, i9.BIMModelDialogComponent, [i6.MD_DIALOG_DATA, i6.MdDialogRef, i10.BIMService, i11.DepositLocationService, i6.MdDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BIMModelDialogComponentNgFactory = i1.ɵccf("ng-component", i9.BIMModelDialogComponent, View_BIMModelDialogComponent_Host_0, {}, {}, []);
export { BIMModelDialogComponentNgFactory as BIMModelDialogComponentNgFactory };
