/**
 * Created by tmp on 28.07.17.
 */
import { Component } from '@angular/core';
import { MdDialog } from '@angular/material';
import { GoogleMapStyles } from '../../../../shared/config/google-maps-styles';
import { NomenclatureChangeDialogComponent } from '../../../../entities/nomenclature/dialogs/nomenclature-change-dialog/nomenclature-change-dialog.component';
import { EcosystemService } from '../../../../entities/ecosystem/services/ecosystem.service';
import {DepositService} from "../../../../entities/deposit/services/deposit.service";
import {TransformService} from "../../../../entities/transformation/services/transform.service";
import {NeedService} from "../../../../entities/need/services/need.service";

@Component({
  moduleId: module.id,
  templateUrl: 'supervisor-data.component.html',
  styleUrls: ['supervisor-data.component.scss']
})
export class SupervisorDataComponent {
  public mapStyles = GoogleMapStyles;
  activeIndex = 0;
  ecosystemsInProcessCount: number;

  uncheckedPassports:number;
  needsUntreated:number;
  depositsAvailable:number;
  transformationsAvailable:number;

  constructor(private mdDialog: MdDialog,
              private ecosystemService: EcosystemService,
              private _depositService: DepositService,
              private _transformationService: TransformService,
              private _needService: NeedService,
  ) {
    this._needService.getUntreatedCount().subscribe(c => this.needsUntreated = c);
    this._depositService.getAvailableCount().subscribe(c => this.depositsAvailable = c);
    this._transformationService.getCount().subscribe(c => this.transformationsAvailable = c);
    this.ecosystemService.getProcessCount().subscribe(c => this.ecosystemsInProcessCount = c);
  }

  askForNomenclatureChange() {
    this.mdDialog.open(NomenclatureChangeDialogComponent);
  }
}
