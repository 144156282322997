import {Component, Input} from '@angular/core';

@Component({
  selector: 'deposit-import-column-description',
  templateUrl: './deposit-import-column-description.component.html',
  styleUrls: ['./deposit-import-column-description.component.scss']
})
export class DepositImportColumnDescriptionComponent {
  @Input() description: string;
  @Input() showWarningSign = true;

  constructor() { }

  isDescriptionString() {
    return typeof this.description === 'string';
  }

}
