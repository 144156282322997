/**
 * Created by Aleksandr C. on 10.02.17.
 */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Need } from '../../../models/need.class';
import { Router } from '@angular/router';
import { ENeedStatusesView, NEED_STATUSES } from '../../../values/need-statuses.const';
import { lowerCase } from 'lodash';
import { EcosystemOffer } from '../../../../ecosystem-offer/ecosystem-offer.model';
import { GET_OFFER_STATUS_LABEL, ECOSYSTEM_OFFER_STATUS } from '../../../../../../src/dashboards/ecosystem-manager/values/ecosystem-offer-status.const';
import { MdDialog } from '@angular/material';
import { DepositDetailsDialogComponent } from '../../../../deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { PASSPORT_UNITS_MAP } from '../../../../passport/values/passport-units.const';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'need-item',
  templateUrl: 'need-item.component.html',
  styleUrls: ['need-item.component.scss'],
})
export class NeedItemComponent implements OnInit {

  @Input() need: Need;
  @Input() offers: EcosystemOffer[] = [];
  @Output() onDuplicateRecord: EventEmitter<Need> = new EventEmitter();
  @Output() onViewOffer: EventEmitter<{ offer: EcosystemOffer, need: Need }> = new EventEmitter
  @Output() onreportRecord: EventEmitter<EcosystemOffer> = new EventEmitter();
  @Output() onconfirmRecord: EventEmitter<EcosystemOffer> = new EventEmitter();
  @Output() onArchive: EventEmitter<Need> = new EventEmitter();
  @Output() onDelete: EventEmitter<Need> = new EventEmitter<Need>();
  PASSPORT_UNITS_MAP = PASSPORT_UNITS_MAP;

  public ECOSYSTEM_OFFER_STATUS = ECOSYSTEM_OFFER_STATUS;

  createdDate: string;
  updatedDate: string;
  deliveryDate: string;
  imageError: boolean = false;
  showOffers: boolean = false;
  status: string;
  statusoffer: string[];

  constructor(
    private router: Router,
    private _dialog: MdDialog,
    private _translate: TranslateService,
  ) {
    this.statusoffer = [];
  }

  ngOnInit() {
    if (this.need) {
      this.need.ecosystem_count = +this.need.ecosystem_count;
      let cDate = new Date(this.need.created_at);
      let uDate = new Date(this.need.updated_at);
      this.createdDate = cDate.toDateString();
      this.updatedDate = uDate.toDateString();
      const statusObj = Object.values(NEED_STATUSES).find(status => status.value === this.need.status);
      this.status = statusObj ? lowerCase(statusObj.view) : '';
      if (this.offers) {
        this.statusoffer = this.offers.map(offer => {
          return GET_OFFER_STATUS_LABEL(offer.status);
        });
      }
      if (this.need.delivery_date) {
        let dDate = new Date(this.need.delivery_date);
        this.deliveryDate = dDate.toDateString();
      }
    }
  }

  getAvatar(): any {
    if (this.need.client && this.need.client.image) {
      return { 'background-image': 'url(' + this.need.client.image + ')' };
    } else {
      return null;
    }
  }

  viewDeposit(need: Need) {
    this._dialog.open(DepositDetailsDialogComponent, {
      data: {
        id: need.from_deposit,
        readOnly: true
      }
    });
  }

  viewNeed(need: Need) {
    this.router.navigate(['sales/needs', need.id, 'edit']);
  }

  deleteNeed(need: Need): void {
    this.onDelete.emit(need);
  }

  /**
  * Gets the thumbnail image source.
  * Prioritizes the thumbnail from the DepositEntity if it exists.
  * Otherwise, defaults to the passport thumbnail.
  *
  * @param {Need} need - The object containing potential image sources.
  * @returns {string} - The thumbnail image source.
  */
  getImageSrc(need: Need): string {
    if (need.fromDepositEntity) {
      if (need.fromDepositEntity.thumbnail) {
        return need.fromDepositEntity.thumbnail;
      }
    }
    return need.passport.thumbnail;
  }

  getImageAlt(need: any): string {
    return need.name;
  }

  onImageError(event) {
    this.imageError = true;
  }

  toggleOffers() {
    this.showOffers = !this.showOffers;
  }

  getColorByStatusClass(): string {
    switch (this.need.status) {
      case NEED_STATUSES.PUBLISHED.value:
        return 'published';
      case NEED_STATUSES.WAITING_FOR_PUBLICATION.value:
        return 'waiting-for-publication';
      case NEED_STATUSES.ASK_FOR_MODIFICATION.value:
      case NEED_STATUSES.REJECTED.value:
        return 'ask-for-modification-or-rejected';
      case NEED_STATUSES.ARCHIVED.value:
        return 'archived';
      default:
        return 'default-color';
    }
  }

  getStatusOfferColorClass(index: number): string {
    switch (this.offers[index].status) {
      case ECOSYSTEM_OFFER_STATUS.CONSIDERED:
      case ECOSYSTEM_OFFER_STATUS.VALIDATED:
        return 'considered-validated';
      case ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION:
      case ECOSYSTEM_OFFER_STATUS.WAITING_FOR_VALIDATION:
      case ECOSYSTEM_OFFER_STATUS.ASK_FOR_MODIFICATION:
        return 'waiting-confirmation-validation-modification';
      case ECOSYSTEM_OFFER_STATUS.CONFIRMED:
        return 'confirmed';
      default:
        return 'default-color';
    }
  }

  getKeyStatus(): string {
    if (!this.need.status)
      return 'Status is null';

    const statusObj = Object.values(NEED_STATUSES)
      .find(status => status.value === this.need.status);

    return statusObj ? statusObj.view : 'Status not found';
  }


  isExpired(): boolean {
    if (this.need.delivery_date) {
      let dDate = new Date(this.need.delivery_date);
      return dDate < new Date();
    }
    return false;
  }

  emitDuplicate(need: Need): void {
    this.onDuplicateRecord.emit(need);
  }

  emitArchive(need: Need): void {
    this.onArchive.emit(need);
  }
  emitViewOffer(offer: EcosystemOffer): void {
    this.onViewOffer.emit({ offer, need: this.need });
  }

  emitReport(offer: EcosystemOffer): void {
    this.onreportRecord.emit(offer);
  }

  emitConfirm(offer: EcosystemOffer): void {
    this.onconfirmRecord.emit(offer);
  }

}
