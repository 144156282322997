<div class="esm-catalog">
  <section class="esm-catalog__choice type--flex">
    <ul class="esm-catalog__choice-list type--auto esm-catalog__header">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input mdInput
                 autocomplete="off"
                 placeholder="{{ 'Search' | translate }}"
                 [formControl]="search"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--people-grey"></i>
        <md-select class="select-group"
                   placeholder="{{ 'Esm contact' | translate }}"
                   [(ngModel)]="ecoManagerId"
                   (change)="getRecords()"
        >
          <md-option>{{'Any' | translate}}</md-option>
          <md-option
            *ngFor="let ecoManager of ecosystemManagerList"
            [value]="ecoManager.id"
          >{{ecoManager.name}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select-auto-width">
        <!--(ngModelChange)="getRecords()"-->
        <md-checkbox
          class="esm-catalog__group--checkbox checkbox-group"
          (change)="getRecords()"
          *ngFor="let filterStatus of filterStatuses"
          [name] ="ECOSYSTEM_ORDER_STATUS[filterStatus.status]"
          [(ngModel)]="filterStatus.value"
          >{{getStatusAsText(filterStatus.status) | translate}}
        </md-checkbox>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--gray-sort"></i>
        <md-select class="select-group"
                   placeholder="{{'Sort By' | translate}}"
                   [(ngModel)]="sortValue"
                   (change)="getRecords()"
        >
          <md-option *ngFor="let sortOption of sortOptions"
                     [value]="sortOption.field">
            {{ sortOption.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
  ></custom-list>
</div>