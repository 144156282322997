import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../shared/models/user.class';
import {DateTimePickerTranslationService} from '../../../shared/datetime-translation/datetime-translation';
import { TLanguage } from '../../../shared/types/language-type';

@Injectable()
export class LanguageService {
  protected languages: TLanguage[] = ['en', 'fr', 'es','pt'];
  user: User;

  constructor(
    private _translateService: TranslateService,
    private dateTimePicker: DateTimePickerTranslationService) {
    }

  initLang() {
    const language = localStorage.getItem('language') as TLanguage;
    language && this.languages.includes(language)
      ? this.setLang(language)
      : this.setLang(this._translateService.getDefaultLang() as TLanguage);
  }

  getUsedLanguage(): TLanguage {
    return (localStorage.getItem('language') || '') as TLanguage;
  }

  get availableLanguages() {
    return this.languages;
  }

  getLanguageByKey(language: TLanguage) {
    switch (language) {
      case 'en':
        return 'English';
      case 'fr':
        return 'Français';
      case 'es':
        return 'Español';
      case 'pt' :
          return 'Português';
    }
  }

  setLang(lang: TLanguage) {
    this.languages.forEach(item => {
      if (item === lang) {
        localStorage.setItem('language', lang);
        this._translateService.use(lang);
        this.dateTimePicker.setLocal(lang);
        if (this.user) {
          this.user.language = lang;
          this.user.save();
        }
      }
    });
  }
}
