import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { Material } from '../models/material.class';
import { merge as mergeStatic } from 'rxjs';

import { Observable } from 'rxjs/Observable';

@Injectable()
export class MaterialService extends CrudService {
  protected namespace = 'passport-materials';
  protected namespaceSingular = 'passport-material';
  protected ModelClass = Material;

  public updateMaterials(materials: Material[]): Observable<Material[]> {
    let requests = materials.map((item: Material) => item.save());
    return mergeStatic(...requests);
  }
}
