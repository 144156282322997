/**
 * Created by atat on 18/06/2017.
 */
 import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
 import { DepositOrder } from '../../../ecosystem-manager/models/deposit-order.class';
 import { DatePipe } from '@angular/common';
 import { DepositOrderService } from '../../../ecosystem-manager/services/deposit-order.service';
 import { ECOSYSTEM_STEP_STATUS } from '../../../ecosystem-manager/values/ecosystem-step-status.const';
 import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
 import { DepositDetailsDialogComponent } from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
 import { MdDialog } from '@angular/material';
 import { UploadedDocument } from '../../../../shared/models/uploaders/document.class';
 import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import { TranslateService } from '@ngx-translate/core';


 @Component({
   moduleId: module.id,
   selector: 'deposit-order-details',
   templateUrl: 'deposit-order-details.component.html',
   styleUrls: ['deposit-order-details.component.css'],
   providers: [ReadOnlyService],
 })
 export class DepositOrderDetailsComponent implements OnInit {
   @Input() id: number;
   @Input() readOnly: boolean;

   @Output() validated = new EventEmitter();
   @Output() unvalidated = new EventEmitter();
   @Output() rejected = new EventEmitter();
   @Output() reportedProblem = new EventEmitter;
   @Output() askedForModification = new EventEmitter;
   @Output() confirmed = new EventEmitter();

   reportProblemModalIsVisible = false;
   problemIsNull: boolean = false;
   modificationModalIsVisible = false;
   modificationIsNull: boolean = false;
   files: UploadedDocument[] = [];
   order: DepositOrder;

   get isOrderConfirmed(): boolean {
    return this.order.status === ECOSYSTEM_STEP_STATUS.WAITING_FOR_CONFIRMATION
   }

   constructor(
               private _translateService: TranslateService,
               private datePipe: DatePipe,
               private depositOrderService: DepositOrderService,
               private readOnlyService: ReadOnlyService,
               private mdDialog: MdDialog) {
   }

   ngOnInit() {
     this.readOnlyService.readOnly = this.readOnly;
     this
       .depositOrderService
       .view(this.id, {expand: 'deposit,address,order_passport,creator,ecosystem_file_junction,DO_price,deposit_location,deposit_passport,deposit_passport.composite_name'})
       .subscribe((order: DepositOrder) => {
         this.order = order;
         if (this.order.ecosystem_file_junction) {
           this.files = this.order.ecosystem_file_junction.map((junction) => junction.ecosystem_file);
         }
         this.order.loaded = true;
       })
     ;
   }

   updateDate(dateString: string, datePart: Date): string {
     let date = new Date(dateString);
     date.setFullYear(datePart.getFullYear());
     date.setMonth(datePart.getMonth());
     date.setDate(datePart.getDate());
     return this.toDateString(date);
   }

   updateTime(dateString: string, datePart: Date): string {
     let date = new Date(dateString);
     date.setHours(datePart.getHours());
     date.setMinutes(datePart.getMinutes());
     date.setSeconds(datePart.getSeconds());
     return this.toDateString(date);
   }

   toDateString(date: Date): string {
     return this.datePipe.transform(date, 'dd-MM-yyyy');
   }

   validate() {
     this.validated.emit();
     this.order.order_passport.save().subscribe(() => {
       this.order.validate().subscribe();
     });
   }

   confirm() {
     this.confirmed.emit();
     this.order.confirm().subscribe();
   }

   reject() {
     this.rejected.emit();
     this.order.order_passport.save().subscribe(() => {
       this.order.reject().subscribe();
     });
   }

   changeVisibilityOfReportProblemModal() {
     this.order.problem = '';
     this.reportProblemModalIsVisible = !this.reportProblemModalIsVisible;
   }

   changeVisibilityOfModificationModal() {
     this.order.modification = '';
     this.modificationModalIsVisible = !this.modificationModalIsVisible;
   }

   problemChanged() {
     this.problemIsNull = !this.order.problem;
   }

   reportProblem(id: number, problem: string) {
     if (!problem) {
       this.problemIsNull = true;
       return;
     }
     this.reportedProblem.emit();
     this.depositOrderService.sendReport(id, problem).subscribe(() => {
       this.changeVisibilityOfReportProblemModal();
     });
   }

   modificationChanged() {
     this.modificationIsNull = !this.order.modification;
   }

   askForModification() {
     if (!this.order.modification) {
       this.modificationIsNull = true;
       return;
     }
     this.order.askForModification().subscribe(() => {
       this.askedForModification.emit();
     });
   }

   checkStatusValidated() {
     return this.order.status !== ECOSYSTEM_STEP_STATUS.STAND_BY
       && this.order.status !== ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION
       && this.order.status !== ECOSYSTEM_STEP_STATUS.ASK_FOR_MODIFICATION;
   }

   seeDeposit(id: number) {
     event.preventDefault();
     this.mdDialog.open(DepositDetailsDialogComponent, {
       ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
       data: {
             id: id,
             readOnly: true,
             showValidationButtons: false,
         },
     });
   }

   getDepositOrderStatusText() {
     return this._translateService.instant(ECOSYSTEM_STEP_STATUS[this.order.status]);
   }

   setRowHeight(inputString: string){
    const rowLength = 35;
    let rowSize = 1;
    if(!inputString){
      return rowSize;
    } else if(inputString.length < rowLength){
      return rowSize
    } else {
      rowSize = Math.ceil(inputString.length / rowLength);
      return rowSize;
    }
  }
 }
