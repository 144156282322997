<div class="material-health">
  <section class="material-health__head">
    <h2 class="material-health__head--title">{{ 'Material Health' | translate }}</h2>
    <report-site-list [sites]="report.sites" [tags]="report.tags"  [createdByFile]="report.created_by_file"></report-site-list>
    <div *ngIf="isDescriptionBlockVisible" class="material-health__head--description-block">
      <p class="material-health__head--description-text">{{ 'DESCRIPTION_TEXT_FOR_HEAD_IN_MATERIAL_HEALTH' | translate }}</p>
      <i (click)="hideDescriptionBlock()" class="ib-icon icon--close_s material-health__head--description-icon"></i>
    </div>
  </section>
  <section *ngIf="!isEmptyProductCategory()" class="material-health__body">
    <div class="material-health__body--left-block">
      <side-report-component class="side-component" [title]="'HEALTHY_PRODUCTS' | translate"
        [content]="'HEALTHY_PRODUCTS_DESCRIPTION_TEXT' | translate" [leafsToDisplay]="3">
      </side-report-component>

      <side-report-component class="side-component"
        [title]="'PRODUCTS_WITH_CONTROLLED_AND_VERIFIED_TOXICITY' | translate"
        [content]="'PRODUCTS_WITH_CONTROLLED_AND_VERIFIED_TOXICITY_DESCRIPTION_TEXT' | translate" [leafsToDisplay]="2">
      </side-report-component>

    </div>
    <div class="material-health__body--center-block">
      <div class="material-health__chart-container">
        <material-pie-chart [data]="mainChartData"></material-pie-chart>
      </div>
      <div class="material-health__body--left-block-text">
        <p><span class="material-health__body--left-block-text-value">{{minDepositsConsidered}} /
            {{maxDepositsConsidered}}</span> {{ 'deposits considered' | translate }}</p>
        <p><span class="material-health__body--left-block-text-value">
          {{tonsOfDeposits | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}</span>
          {{ 'tons of deposits in total' | translate }}</p>
        <div class="material-health__body--left-block-link">
          <i class="icon blue-eye--icon"></i>
          <a class="material-health__body--left-block-link-item"
          (click)="goToArchive()">{{ 'See error report for more details' | translate }}</a>
        </div>
      </div>
    </div>
    <div class="material-health__body--right-block">
      <side-report-component class="side-component material-health__body--right-block-first"
        [title]="'PRODUCTS_WITH_CONTROLLED_TOXICITY' | translate"
        [content]="'PRODUCTS_WITH_CONTROLLED_TOXICITY_DESCRIPTION_TEXT' | translate"
        [leafsToDisplay]="1">
      </side-report-component>

      <side-report-component class="side-component"
      [title]="'PRODUCTS_WITH_UNKNOWN_TOXICITY' | translate"
      [content]="'PRODUCTS_WITH_UNKNOWN_TOXICITY_DESCRIPTION_TEXT' | translate"
      [leafsToDisplay]="4">
      </side-report-component>

      <side-report-component class="side-component"
        [title]="'PRODUCTS_WITH_KNOWN_TOXICITY' | translate"
        [content]="'PRODUCTS_WITH_KNOWN_TOXICITY_DESCRIPTION_TEXT' | translate"
        [leafsToDisplay]="0">
      </side-report-component>

     

    </div>
  </section>
  <section *ngIf="!isEmptyProductCategory()" class="material-health__footer">
    <section class="material-health__footer">
      <div class="material-health__footer--pie-charts">
        <div class="material-health__footer--bottom-chart">
          <div class="material-health__footer--bottom-chart-header"
            [innerHTML]="'MOST_IMPORTANT_PRODUCT_CATEGORY_BY_MASS' | translate"></div>
          <div class="material-health__chart-container-small">
            <material-pie-chart [data]="mostImportantProductChartData"></material-pie-chart>
          </div>
          <div class="material-health__footer--bottom-chart-bottom-text-i">
            {{mostImportantProductChartData.category.name | translate}}</div>
          <b>
            <div class="material-health__footer--bottom-chart-bottom-text-ii">
              <span>
                {{mostImportantProductChartData.category.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
              </span>
              {{ 'tons' | translate }}</div>
          </b>
          <div class="material-health__footer--bottom-chart-bottom-text-ii"><span>{{mostImportantProductChartData.category.percentTotalWeight | number: numberFormat(mostImportantProductChartData.category.percentTotalWeight):'fr' | decimalSeparator:','}}</span>%
            {{ 'of the total weight' | translate }}</div>
        </div>
        <div class="material-health__footer--bottom-chart">
          <div class="material-health__footer--bottom-chart-header-green"
            [innerHTML]="'HEALTHIEST_PRODUCT_CATEGORY' | translate"></div>
          <div class="material-health__chart-container-small">
            <material-pie-chart [data]="healthiestProductChartData"></material-pie-chart>
          </div>
          <div class="material-health__footer--bottom-chart-bottom-text-i">
            {{healthiestProductChartData.category.name | translate}}
          </div>
          <b>
            <div class="material-health__footer--bottom-chart-bottom-text-ii">
              <span>
              {{healthiestProductChartData.category.weight | floatingDigitNumberAfterComma: 'fr' | decimalSeparator:','}}
              </span>
              {{ 'tons' | translate }}</div>
          </b>
          <div class="material-health__footer--bottom-chart-bottom-text-ii">
            <span>{{healthiestProductChartData.category.percentTotalWeight | number: numberFormat(healthiestProductChartData.category.percentTotalWeight):'fr' | decimalSeparator:','}}</span>%
            {{ 'of the total weight' | translate }}</div>
        </div>
        <div class="material-health__footer--bottom-chart">
          <div class="material-health__footer--bottom-chart-header-red"
            [innerHTML]="'MOST_TOXIC_PRODUCT_CATEGORY' | translate"></div>
          <div class="material-health__chart-container-small">
            <material-pie-chart [data]="mostToxicProductChartData"></material-pie-chart>
          </div>
          <div class="material-health__footer--bottom-chart-bottom-text-i">
            {{mostToxicProductChartData.category.name | translate}}
          </div>
          <b>
            <div class="material-health__footer--bottom-chart-bottom-text-ii">
              <span>
                {{mostToxicProductChartData.category.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
              </span>
              {{ 'tons' | translate }}</div>
          </b>
          <div class="material-health__footer--bottom-chart-bottom-text-ii">
            <span>{{mostToxicProductChartData.category.percentTotalWeight | number: numberFormat(mostToxicProductChartData.category.percentTotalWeight):'fr' | decimalSeparator:','}}</span>%
            {{ 'of the total weight' | translate }}</div>
        </div>
      </div>
    </section>
  </section>
  <section *ngIf="isEmptyProductCategory()" class="material-health__body section">
    <h3 class="section__error-title">{{ 'No deposit considered' | translate }}</h3>
  </section>
</div>
