import * as tslib_1 from "tslib";
import { AfterViewChecked, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, Renderer } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdDialog } from '@angular/material';
import { Passport, PASSPORT_DEFAULT_CATEGORY } from '../../models/passport.class';
import 'rxjs/add/operator/switchMap';
import { PASSPORT_UNITS, PASSPORT_UNITS_TYPE } from '../../values/passport-units.const';
import { PassportService } from '../../services/passport.service';
import { PassportFileService } from '../../services/passport-file.service';
import { PassportFile } from '../../models/passport-file.class';
import { CopyPassportDialogComponent } from '../../dialogs/copy-passport-dialog/copy-passport-dialog.component';
import { PassportFormatService } from '../../services/format.service';
import { PassportControlStates } from '../../models/passport-control-states.class';
import { PASSPORT_STATUS } from '../../values/passport-status.const';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import { PERIOD_OF_USE_TYPES } from '../../values/period-of-use.values';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../shared/services/user.service';
import { User } from '../../../../shared/models/user.class';
import { CurrentUser } from '../../../../shared/auth/current-user.class';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { PASSPORT_TYPES } from '../../values/passport-types.const';
import { Router } from '@angular/router';
import { PERFORMANCE_RADIOS, PERFORMANCE_RADIOS_VALUES } from '../../values/performance-radios.const';
import { PASSPORT_CERTIFICATION_LEVELS } from '../../values/passport-certification-levels.const';
import { NameGenerationDialogComponent } from '../../dialogs/name-generation-dialog/name-generation-dialog.component';
import { MaterialService } from '../../services/material.service';
import { CustomSearchComponent } from '../../../../shared/custom-search/custom-search.component';
import { AdvancedResearchDialogComponent } from '../../dialogs/advanced-research-dialog/advanced-research-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { ViewCategoryDialogComponent } from '../../dialogs/view-category/view-category.component';
import { Observable } from 'rxjs/Observable';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as moment from 'moment';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { PassportValidationNotificationService } from '../../services/passport-validation-notification.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { PassportGtinBufferService } from '../../services/passport-gtin-buffer.service';
import { PassportGroupBuilderService } from '../../services/passport-group-builder/passport-group-builder.service';
import { PassportGtinService } from '../../services/passport-gtin.service';
import { PASSPORT_STATUS_CONST } from '../../values/passport-status.const';
var EditPassportComponent = /** @class */ (function () {
    function EditPassportComponent(dialog, _fb, gtinBuffer, passportService, passportFileService, passportFormatService, stateService, loadingSpinnerService, router, readOnlyService, navigationService, _userService, materialService, renderer, translate, cd, notification, _passportValidationNotification, _changeDetectionRef, passportFormBuilder, passportGtinService) {
        this.dialog = dialog;
        this._fb = _fb;
        this.gtinBuffer = gtinBuffer;
        this.passportService = passportService;
        this.passportFileService = passportFileService;
        this.passportFormatService = passportFormatService;
        this.stateService = stateService;
        this.loadingSpinnerService = loadingSpinnerService;
        this.router = router;
        this.readOnlyService = readOnlyService;
        this.navigationService = navigationService;
        this._userService = _userService;
        this.materialService = materialService;
        this.renderer = renderer;
        this.translate = translate;
        this.cd = cd;
        this.notification = notification;
        this._passportValidationNotification = _passportValidationNotification;
        this._changeDetectionRef = _changeDetectionRef;
        this.passportFormBuilder = passportFormBuilder;
        this.passportGtinService = passportGtinService;
        this.asDialog = false;
        this.disabled = false;
        this.enableSearch = true;
        this.allowNameChange = true;
        this.viewMode = 1;
        this.passport = new Passport;
        this.readOnly = false;
        this.spinnerEnabled = true;
        this.controlStates = new PassportControlStates;
        this.onChange = new EventEmitter();
        this.passportChange = new EventEmitter();
        /**
         * Replaced by onSaveComplete
         * @deprecated
         */
        this.onSave = new EventEmitter();
        this.onSaveError = new EventEmitter();
        this.onSaveComplete = new EventEmitter();
        this.onInvalidField = new EventEmitter();
        this.units = PASSPORT_UNITS;
        this.period_types = PERIOD_OF_USE_TYPES;
        this.performanceRadios = PERFORMANCE_RADIOS;
        this.certificationLevels = PASSPORT_CERTIFICATION_LEVELS;
        this.VIEW_MODE = VIEW_MODE;
        this.moduleNames = ModuleNames;
        this.invalidFields = [];
        this.eventStream$ = new EventEmitter();
        this.uploadedFiles = [];
        this.onUpdateUploadUrl$ = new EventEmitter();
        this.formatSuggestions = [];
        this.areFormControlsDisabled = true;
        this.supervisorWarning = false;
        this.PASSPORT_STATUS_CONST = PASSPORT_STATUS_CONST;
        this.requestsSubject = new BehaviorSubject([]);
        this.saveGtinsInBuffer = this.saveGtinsInBuffer.bind(this);
    }
    EditPassportComponent.prototype.ngAfterViewChecked = function () {
        this.cd.detectChanges();
    };
    EditPassportComponent.prototype.onLogoUpload = function (response) {
        var data = null;
        try {
            data = typeof response === 'string' ? JSON.parse(response) : response;
        }
        catch (e) {
            console.log(e);
        }
        if (data) {
            this.passport.set({ image: data.image });
            this.passport.set({ thumbnail: data.thumbnail });
        }
    };
    EditPassportComponent.prototype.getLogoUploaderUrl = function () {
        return this.passportService.getLogoUploadUrl(this.passport.id);
    };
    EditPassportComponent.prototype.canEditDocuments = function () {
        return this.navigationService.getContext().roleId === ERoleIds.SUPERVISOR
            || this.createdPassportId > 0;
    };
    EditPassportComponent.prototype.isSelectAnotherPassportShown = function () {
        return this.parentModule === this.moduleNames.AGENT || this.parentModule === this.moduleNames.SALES;
    };
    EditPassportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isPassportChanged = true;
        // this.passport.family = 4;
        this.onChange.subscribe(function (passport) {
            _this.passportChange.emit(passport);
            if (_this.isPassportChanged) {
                _this.isPassportChanged = false;
                _this.eventStream$.emit({ key: 'changePassport', data: passport });
            }
            _this.enableSearch = (!_this.passportExist);
        });
        this.createForm();
        this.setConversionValidator();
        this.gtinBuffer.setNotifier(this.eventStream$, this.saveGtinsInBuffer);
        if (this.passport && this.passport.id > 0) {
            this.setPassport(this.passport);
            this.updateFormControlsDisabled();
        }
        else {
            this.disableFormControlsWithUpdate();
        }
        this.statusChanged();
        if (this.listener) {
            this.listener.subscribe(function (event) {
                if (event.key === 'changePassport') {
                    _this.setPassport(event.data);
                }
                else if (event.key === 'updatePassport') {
                    _this.updatePassport(_this.passportForm);
                }
                else if (event.key === 'publishPassport') {
                    _this.publishPassport(_this.passportForm);
                }
                else if (event.key === 'validatePassport') {
                    _this.validatePassport();
                }
                else if (!!_this.passport.id) {
                    _this.savePassport(_this.passportForm);
                }
            });
        }
        if (this.parentFormGroup) {
            this.parentFormGroup.setControl('passport', this.passportForm);
        }
        this.translate.get('Cant upload without passport created').subscribe(function (text) {
            _this.uploadErrorText = text;
        });
        this.requestsSubject
            .skip(1)
            .debounceTime(100)
            .subscribe(function () { return _this.subscribeOnRequests(); });
        this._passportValidationNotification.passportForm = this.passportForm;
    };
    EditPassportComponent.prototype.ngOnDestroy = function () {
        this._passportValidationNotification.clearPassportForm();
        this.passportFormBuilder.unsubscribe(this.passportForm);
        this.gtinBuffer.unsubcribe();
    };
    // TODO remove save logic in bufferService, it removes edit-passport and edit-passport-v2 duplication code.
    EditPassportComponent.prototype.saveGtinsInBuffer = function (e, observable) {
        if (e.key === 'savePassport') {
            this.onUpdatePassportData({ request: observable, type: 'gtins save' });
        }
    };
    EditPassportComponent.prototype.setCorrectData = function (event) {
        if (event) {
            this.passport.certification_expiration = moment(event).format('YYYY-MM-DD');
        }
    };
    EditPassportComponent.prototype.unitChange = function () {
        // this.passportForm.get('conversion').updateValueAndValidity();
        /*console.log(this.passportForm.get('passportUnit').value);
        console.log(this.passportForm.get('conversion').value);
        console.log('hereedsds');
        if (this.isPassportConversionShown() ) {
          console.log('is shown')
          this.passportForm.controls['conversion'].setValidators([Validators.required,Validators.min(0)]);
          this.passportForm.controls['conversion'].updateValueAndValidity();
        }
        else
        {
          console.log('is not shown')
          //this.passportForm.controls['conversion'].updateValueAndValidity();
          //   this.passportForm.controls['conversion'].clearAsyncValidators();
        //  this.passportForm.reset();
          this.passportForm.controls['conversion'].clearValidators()
         // this.passportForm.controls['conversion'].setValidators([Validators.min(0)]);
         // this.passportForm.controls['conversion'].setErrors(null);
          this.passportForm.updateValueAndValidity();
        }
        this._changeDetectionRef.detectChanges(); */
    };
    EditPassportComponent.prototype.onLockedPassportInteract = function ($event) {
        var _this = this;
        if (this.isPassportFrozen()) {
            return;
        }
        var dialogRef = this.dialog.open(CopyPassportDialogComponent, {
            data: {
                name: this.passport.name,
                isSelectAnotherPassportShown: this.isSelectAnotherPassportShown(),
                parentModule: this.parentModule
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if ($event && !result) {
                _this.renderer.invokeElementMethod($event.target, 'blur', []);
            }
            if (result) {
                if (!!result.resetForm) {
                    _this.changePassport();
                    return;
                }
                _this.passportService.duplicate(_this.passport.id, {
                    name: result,
                    status: PASSPORT_STATUS.DRAFT,
                    family: _this.passport.getPassportFamilyBaseOnModule(_this.parentModule)
                }, true)
                    .subscribe(function (response) {
                    _this.passportService.view(response.id, { expand: 'passport_components,passport_materials,nomenclature,english_name,category' })
                        .subscribe(function (duplicatedPassport) {
                        _this.setPassport(duplicatedPassport);
                        // this.updatePassport(this.passportForm);
                        _this.eventStream$.emit({ key: 'changePassport', data: _this.passport });
                        _this.onSave.emit();
                        _this.enableFormControlsWithUpdate();
                    });
                }, function (err) { return console.log(err); });
            }
            else {
                _this.disableFormControlsWithUpdate();
            }
        });
    };
    EditPassportComponent.prototype.changePassport = function () {
        this.clearForm();
        this.eventStream$.emit({ key: 'changePassport', data: this.passport });
        this.focusOnPassportSelect();
    };
    // If the publication is not published and role === SUPERVISOR -> can be edited.
    EditPassportComponent.prototype.updateFormControlsDisabled = function () {
        this.areFormControlsDisabled = this.isPassportFrozen()
            || (this.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor());
    };
    EditPassportComponent.prototype.showErrors = function () {
        return !(this.passport.status === PASSPORT_STATUS.PUBLISHED);
    };
    EditPassportComponent.prototype.statusChanged = function () {
        this.updateFormControlsDisabled();
        if (this.areFormControlsDisabled) {
            this.disableFormControls();
        }
        else {
            this.enableFormControls();
        }
    };
    EditPassportComponent.prototype.disableFormControls = function () {
        var _this = this;
        setTimeout(function () { return _this.passportForm.disable(); });
        this.eventStream$.emit({ key: 'disableStatus', data: { isDisabled: true } });
    };
    EditPassportComponent.prototype.enableFormControls = function () {
        var _this = this;
        Object.keys(this.passportForm.controls).forEach(function (key) { return _this.passportForm.controls[key].enable(); });
        this.eventStream$.emit({ key: 'disableStatus', data: { isDisabled: false } });
    };
    EditPassportComponent.prototype.disableFormControlsWithUpdate = function () {
        this.disableFormControls();
        this.updateFormControlsDisabled();
    };
    EditPassportComponent.prototype.enableFormControlsWithUpdate = function () {
        this.enableFormControls();
        this.updateFormControlsDisabled();
    };
    EditPassportComponent.prototype.createForm = function () {
        this.passportForm = this.passportFormBuilder.buildGroupShort(this.passport).addControlOptions({
            passportName: [this.passport.name, Validators.required],
            passportEnglishName: [this.passport.english_name, Validators.required],
            category: ['', Validators.required],
            passportUnit: [this.passport.unit, Validators.required],
            quantity: [this.passport.period, [Validators.pattern(/^\d+$/), Validators.min(0)]],
            recyclePercentage: [this.passport.recycle_percentage, [Validators.min(0), Validators.max(100)]],
        }).build();
    };
    EditPassportComponent.prototype.setConversionValidator = function () {
        var conversionControl = this.passportForm.get('conversion');
        // const unitControl = this.passportForm.get('passportUnit');
        this.passportForm.get('passportUnit').valueChanges
            .subscribe(function (unit) {
            // tslint:disable-next-line:triple-equals
            if (unit != PASSPORT_UNITS_TYPE.TONNE && unit != PASSPORT_UNITS_TYPE.KG) {
                conversionControl.setValidators([Validators.required, Validators.min(0)]);
            }
            else {
                conversionControl.clearValidators();
                conversionControl.updateValueAndValidity();
            }
        });
    };
    EditPassportComponent.prototype.getPassportCustomName = function (record) {
        if (record) {
            return record.getProductName(this.stateService.multilanguage);
        }
    };
    EditPassportComponent.prototype.onSelect = function (passport) {
        var _this = this;
        this.setPassport(passport);
        if (this.createdPassportId > 0) {
            this.passportService.remove(this.createdPassportId).subscribe(function () {
                _this.createdPassportId = -1;
                // this.readOnlyService.readOnly = !this.canEditDocuments();
            });
        }
        this.enableSearch = false;
        this.disableFormControlsWithUpdate();
    };
    EditPassportComponent.prototype.setPassport = function (passport) {
        var _this = this;
        this.onChange.emit(passport);
        this.passport = passport;
        this.passportFormBuilder.changePassport(this.passportForm, this.passport);
        //  this.unitChange()
        this.onUpdateUploadUrl$.emit(this.passport.getFileUploadUrl());
        this.passportForm.controls['passportName'].setValue(passport.name);
        this.passportForm.controls['passportEnglishName'].setValue(passport.english_name);
        if (this.passport.id) {
            var categoryName = !!this.passport.category && !!this.passport.category.name && this.passport.category.name;
            var translation = !!categoryName ? this.translate.instant(categoryName) : '';
            this.passportForm.controls['category'].setValue(translation);
        }
        if (!!this.passportSyncSubscription) {
            this.passportSyncSubscription.unsubscribe();
        }
        this.gtinBuffer.setOwnerObject(this.passport);
        this.statusChanged();
        this.preloadUploadedItems();
        this.preloadComponents();
        this.preloadMaterials();
        if (!this.passport.gtins.length) {
            this.preloadGTINs();
        }
        this
            .passportFormatService
            .get()
            .subscribe(function (res) { return _this.formatSuggestions = res; });
    };
    EditPassportComponent.prototype.onNameEdit = function (name) {
        this.passport.name = name;
    };
    EditPassportComponent.prototype.onCreate = function (passport) {
        var _this = this;
        this.passport = new Passport({ name: this.searchValue });
        this.passport.materials = [];
        this.passport.components = [];
        this.passport.category_id = null;
        this.passport.category = null;
        this.passport.version = String(new Date().getFullYear());
        this.uploadedFiles = [];
        if (CurrentUser.infoData.id && this.parentModule === ModuleNames.MANUFACTURER) {
            this._userService.view(CurrentUser.infoData.id, { expand: 'company' })
                .subscribe(function (user) {
                _this.passport.manufacturer = user.company_name;
            });
            this.passport.type = PASSPORT_TYPES.MANUFACTURER;
        }
        if (this.searchValue.trim() !== '') {
            this.passportService.createPassport(this.passport, this.parentModule).subscribe(function (result) {
                var id = result.id, name = result.name, family = result.family, english_name = result.english_name;
                _this.createdPassportId = id;
                _this.passport = Object.assign(_this.passport, {
                    id: id, name: name, english_name: english_name, family: family, user: new User(tslib_1.__assign({}, CurrentUser.infoData))
                });
                _this.setPassport(_this.passport);
                _this.enableFormControlsWithUpdate();
                _this.onOpenGenerationNameDialog(null, true);
            });
        }
        else {
            this.enableFormControlsWithUpdate();
            this.onOpenGenerationNameDialog(null, true);
        }
    };
    EditPassportComponent.prototype.preloadComponents = function () {
        var _this = this;
        if (!this.passport.id) {
            return;
        }
        this.passport.components = [];
        this.passport.onSyncComponents$.subscribe(function (res) {
            _this.passport.components = res;
        });
    };
    EditPassportComponent.prototype.preloadMaterials = function () {
        var _this = this;
        if (!this.passport.id) {
            return;
        }
        this.passport.materials = [];
        this.passport.onSyncMaterials$.subscribe(function (res) {
            _this.passport.materials = res;
        });
    };
    EditPassportComponent.prototype.preloadUploadedItems = function () {
        var _this = this;
        if (!this.passport.id) {
            return;
        }
        this.uploadedFiles = [];
        this.passportFileService
            .get({ passport_id: this.passport.id })
            .subscribe(function (results) {
            for (var _i = 0, results_1 = results; _i < results_1.length; _i++) {
                var item = results_1[_i];
                if (item.isVisibleForAll()) {
                    _this.uploadedFiles.push(new PassportFile(item));
                }
            }
        });
    };
    EditPassportComponent.prototype.preloadGTINs = function () {
        var _this = this;
        this.passportGtinService.get({ passport_id: this.passport.id }).subscribe(function (result) { return _this.passport.gtins = result; });
    };
    EditPassportComponent.prototype.savePassport = function (passportForm) {
        var _this = this;
        console.log('save passport ');
        if (!passportForm.valid) {
            return false;
        }
        if (this.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor()) {
            return false;
        }
        this.passport.category = this.passportForm.get('category').value
            ? this.passportForm.get('category').value
            : PASSPORT_DEFAULT_CATEGORY;
        this.passport.save().subscribe(function (res) {
            _this.eventStream$.emit({ key: 'savePassport', data: _this.passport });
            _this.onSave.emit();
        });
        return true;
    };
    EditPassportComponent.prototype.publishPassport = function (passportForm) {
        var _this = this;
        if (!passportForm.valid) {
            return false;
        }
        this.passport.category = this.passportForm.get('category').value
            ? this.passportForm.get('category').value
            : PASSPORT_DEFAULT_CATEGORY;
        this.passport.publish().subscribe(function (res) {
            _this.eventStream$.emit({ key: 'savePassport', data: _this.passport });
            _this.onSave.emit();
        });
        return true;
    };
    EditPassportComponent.prototype.updatePassport = function (passportForm) {
        var _this = this;
        this.passport.update().subscribe(function (res) {
            console.log('update passport:', res);
            _this.eventStream$.emit({ key: 'savePassport', data: _this.passport });
            _this.onSave.emit();
        });
        return true;
    };
    EditPassportComponent.prototype.validatePassport = function () {
        var _this = this;
        this.passport.publish().subscribe(function (res) {
            console.log('Validate passport:', res);
            _this.router.navigate([_this.parentModule + '/passports']);
        });
        return true;
    };
    EditPassportComponent.prototype.removePassportPreview = function () {
        var _this = this;
        this.passportService.removeLogo(this.passport.id).subscribe(function () {
            _this.passport.image = null;
            console.log('Deleted');
        });
    };
    EditPassportComponent.prototype.onOpenCopyDialog = function ($event) {
        if (this.validateCopyPassport()) {
            this.onLockedPassportInteract($event);
        }
    };
    EditPassportComponent.prototype.onOpenGenerationNameDialog = function ($event, force) {
        var _this = this;
        if (!this.validateNameGeneration(force)) {
            return;
        }
        var categoryId = this.passport.category_id;
        this.dialog.open(NameGenerationDialogComponent, {
            data: {
                passport: this.passport,
                viewMode: this.viewMode,
                controlStates: this.controlStates,
                enableSearch: this.enableSearch,
                parentModule: this.parentModule,
                selectExistingPassportAvailable: true
            }
        }).afterClosed().subscribe(function (data) {
            console.log('afterClosed NameGenerationDialogComponent v1', data);
            if (!data) {
                return;
            }
            if (!!data.clearForm) {
                _this.clearForm();
                _this.focusOnPassportSelect();
            }
            if (!!data.passport) {
                if (!data.passport.id) {
                    _this.passportService.createPassport(data.passport, _this.moduleNames).subscribe(function (passport) {
                        _this.passport.category_id = categoryId;
                        _this.setPassport(passport);
                        _this.enableSearch = false;
                    });
                }
                else {
                    _this.enableSearch = false;
                    data.passport.category_id = categoryId;
                    return _this.setPassport(data.passport);
                }
            }
        });
    };
    EditPassportComponent.prototype.validatePassportAction = function () {
        return !this.isPassportFrozen()
            && this.parentModule !== this.moduleNames.SPECIAL_ADMIN;
    };
    EditPassportComponent.prototype.validateCopyPassport = function () {
        return this.validatePassportAction()
            && !this.readOnly && this.areFormControlsDisabled;
    };
    EditPassportComponent.prototype.validateNameGeneration = function (force) {
        return this.validatePassportAction()
            && (force || !this.areFormControlsDisabled);
    };
    EditPassportComponent.prototype.isShownCatalogNameMenu = function () {
        return (this.showCatalogNameMenu === null || this.showCatalogNameMenu === undefined)
            ? this.validatePassportAction() : !!this.showCatalogNameMenu;
    };
    EditPassportComponent.prototype.clearForm = function () {
        this.uploadedFiles = [];
        this.passportFormBuilder.reset(this.passportForm);
        this.enableSearch = true;
        this.passport = new Passport();
    };
    EditPassportComponent.prototype.focusOnPassportSelect = function () {
        var _this = this;
        setTimeout(function () {
            if (!!_this.customSearch) {
                _this.customSearch.focus();
            }
        });
    };
    EditPassportComponent.prototype.getLabelClasses = function (field) {
        return {
            'primary-color': field,
            'label-color': !field,
            'passport-edit__color-transition': true
        };
    };
    EditPassportComponent.prototype.isPassportFrozen = function () {
        return this.passport.status === PASSPORT_STATUS.FROZEN;
    };
    EditPassportComponent.prototype.areC2cAdditionalFieldsShown = function () {
        return this.passport.c2c_certified === PERFORMANCE_RADIOS_VALUES.YES;
    };
    EditPassportComponent.prototype.openAdvancedResearch = function ($event) {
        var _this = this;
        this.advancedResearchDialog = this.dialog.open(AdvancedResearchDialogComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { data: {
                includeDrafts: false
            } })).afterClosed().subscribe(function (data) {
            if (!data) {
                return;
            }
            _this.setPassport(data);
            _this.enableSearch = false;
            _this.disableFormControlsWithUpdate();
        });
    };
    EditPassportComponent.prototype.openCategoryeDialog = function ($event) {
        var _this = this;
        if (this.readOnlyService && this.readOnlyService.readOnly) {
            return;
        }
        if (!!this.areFormControlsDisabled) {
            return;
        }
        var dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
            height: '80%',
            width: '80%',
            position: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.passport.category_id = result.id;
                _this.passport.category = result;
                _this.passportForm.controls['category'].setValue(result.name ? _this.translate.instant(result.name) : '');
                _this.passportForm.controls['category'].disable();
            }
        });
    };
    EditPassportComponent.prototype.isPassportConversionShown = function () {
        if (!this.passport || !this.passport.unit) {
            return false;
        }
        return this.passport.unit !== PASSPORT_UNITS_TYPE.KG && this.passport.unit !== PASSPORT_UNITS_TYPE.TONNE;
    };
    EditPassportComponent.prototype.presentLoadingSpinner = function () {
        if (!this.spinnerEnabled) {
            return;
        }
        this.cd.detach();
        this.loadingSpinnerService.show('circular passport');
    };
    EditPassportComponent.prototype.destroyLoadingSpinner = function () {
        this.loadingSpinnerService.destroy();
        this.cd.reattach();
    };
    EditPassportComponent.prototype.handlePassportDataRequest = function (type, responseError) {
        var _this = this;
        this.requestsSubject.next([]);
        this.destroyLoadingSpinner();
        return {
            success: function () {
                _this.onSave.emit();
                _this.onSaveComplete.emit();
            },
            error: function () {
                _this.onSaveError.emit();
                var duration = 3000;
                _this.notification.error(_this.translate.instant('CANNOT_BE_SAVED_ERROR', { X: type }), duration);
                setTimeout(function () {
                    _this.passportService.onError(responseError);
                }, duration);
            }
        };
    };
    EditPassportComponent.prototype.onUpdatePassportData = function (event) {
        this.presentLoadingSpinner();
        this.requestsSubject.next(this.requestsSubject.getValue().concat([
            event.request.catch(function (err) { return Observable.throw({ error: err, field: event.type }); })
        ]));
    };
    EditPassportComponent.prototype.subscribeOnRequests = function () {
        var _this = this;
        this.requestSubscription = forkJoin(this.requestsSubject.getValue())
            .subscribe(function () { return _this.handlePassportDataRequest().success(); }, function (err) { return _this.handlePassportDataRequest(err.field, err.error).error(); });
    };
    Object.defineProperty(EditPassportComponent.prototype, "passportExist", {
        get: function () {
            return this.passport.getProductName();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditPassportComponent.prototype, "isPassportVoid", {
        get: function () {
            return this.passport.isVoid;
        },
        enumerable: true,
        configurable: true
    });
    return EditPassportComponent;
}());
export { EditPassportComponent };
