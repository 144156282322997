import { Component, OnInit, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { GenericPassport } from '../../models/generic-passport.class';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { ZoneService } from '../../../../shared/services/zone.service';


@Component({
  moduleId: module.id,
  selector: 'generic-passport-edit',
  templateUrl: 'generic-passport-edit.component.html',
  styleUrls: ['generic-passport-edit.component.scss']
})
export class GenericPassportEdit extends EntityViewerComponent<GenericPassport> implements OnInit, AfterViewInit {
  public genericPassport: GenericPassport;
  public isNewGenericPassport;
  form: FormGroup;
  zoneId: number;


  constructor(protected route: ActivatedRoute,
    protected router: Router,
    private _fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private _translate: TranslateService,
    private notificationService: LogNotificationService,
    private _zone: NgZone,
    private zoneService: ZoneService
  ) {
    super(route, router);

  }
  ngOnInit(): any {
    this.form = this._fb.group({});
    //this.formValid = false;
    super.ngOnInit();
    this.zoneId = +this.route.snapshot.paramMap.get('zoneId');
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  onNewEntity(): void {
    this.zoneId = +this.route.snapshot.paramMap.get('zoneId');
    this.genericPassport = new GenericPassport();
    this.genericPassport.loaded = true;
    this.isNewGenericPassport = true;
    this.genericPassport.zone_id = this.zoneId;
  }
  clickSave() {
    if (this.form.valid) {
      if (this.genericPassport.materials.length < 1) {
        this.notificationService.error(this._translate.instant('At least one material is required'));
        return;
      }
      if (this.genericPassport.tags.length < 1) {
        this.notificationService.error(this._translate.instant('At least one tag is required'));
        return;
      }
      if (this.genericPassport.waste_category_id === 0) {
        this.genericPassport.waste_category_id = null;
      } 
     // else {
     //   console.log('here landfulle cost');
     //   this.genericPassport.landfil_cost = null;
     // }
   //  console.log(this.genericPassport.construction_layer);
   this.zoneService.savePassport(this.zoneId, this.genericPassport)
   .subscribe((res) => {
        if (res) {
          this.genericPassport.id = res.id;
          this.saveMaterials();
          this.saveTags();
          this.notificationService.success(this._translate.instant('Generic passport created successfully'));
          this._zone.run(() => this.router.navigate([`superadmin/zones/${this.zoneId}/generic-passports`]));
        }
      });
    } else {
      this.notificationService.error(this._translate.instant('All field with * are required'));
    }
  }
  saveMaterials() {
    if (this.genericPassport.materials.length > 0) {
      this.genericPassport.materials.forEach(mat => {
        if (!mat.id || mat.id < 1) {
          mat.generic_passport_id = this.genericPassport.id;
          mat.save().subscribe((res) => {
            mat.id = res.id;
          });
        }
      });
    }
  }
  saveTags() {
    if (this.genericPassport.tags.length > 0) {
      this.genericPassport.tags.forEach(tag => {
        if (!tag.id || tag.id < 1) {
          tag.generic_passport_id = this.genericPassport.id;
          tag.save().subscribe((res) => {
            tag.id = res.id;
            //console.log(tag);
          });
        }
      });
    }
  }
  onExistEntity(entity: GenericPassport): void {
    this.genericPassport = entity;
    this.genericPassport.loaded = true;
    this.isNewGenericPassport = false;
  }

}