import { MdDialogRef } from '@angular/material';
import { EConfirmDialogIcon } from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import { IConfirmDialogIconData } from '../../../../shared/confirm-dialog/values/interface/confirm-dialog-data.interface';
var DepositImportErrorsComponent = /** @class */ (function () {
    function DepositImportErrorsComponent(mdDialogRef, mdDialogData) {
        this.mdDialogRef = mdDialogRef;
        this.mdDialogData = mdDialogData;
        this.iconData = {
            iconType: EConfirmDialogIcon.SVG,
            showIconURL: 'assets/svg/warning--icon.svg',
            iconClass: 'deposit-import-errors__image-triangle'
        };
    }
    DepositImportErrorsComponent.prototype.selectText = function (selector) {
        var element = document.querySelector(selector);
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    };
    DepositImportErrorsComponent.prototype.onSelectAll = function (e) {
        var controlKeyDown = (e.ctrlKey || e.metaKey);
        var characterADown = (e.code === 'KeyA');
        if (controlKeyDown && characterADown) {
            e.preventDefault();
            this.selectText('#table-errors');
        }
    };
    Object.defineProperty(DepositImportErrorsComponent.prototype, "imgSrc", {
        get: function () {
            return "assets/png/warning_" + this.mdDialogData.color + ".png";
        },
        enumerable: true,
        configurable: true
    });
    return DepositImportErrorsComponent;
}());
export { DepositImportErrorsComponent };
