<div class="process-data" *ngIf="location.loaded">
  <h1 class="title">{{ location.name }}</h1>

  <md-input-container class="name">
    <input mdInput [placeholder]="'Site Name' | translate"
            [(ngModel)]="location.name">
  </md-input-container>

  <md-input-container class="address">
    <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
      <input
        mdInput
        [placeholder]="'Address' | translate"
        [(ngModel)]="location.address"
        placeAutocomplete
        (placeChange)="onPlaceChange($event)"
        (change) = "onTextChange()"
      >
    </place-autocomplete>
  </md-input-container>

  <md-input-container class="description">
    <input mdInput [placeholder]="'Description' | translate" autocomplete="off" [(ngModel)]="location.description">
  </md-input-container>
</div>

<div class="save-submit-container" *ngIf="!readOnlyService.readOnly">
  <button md-raised-button class="save" (click)="save()">{{'Save' | translate}}</button>
</div>
