/**
 * Created by Aleksandr C. on 8.02.17.
 */

import { Observable } from 'rxjs/Observable';
import { Passport } from '../../passport/models/passport.class';
import { Client } from '../../client/model/client.class';
import { ClientLocation } from '../../client/model/location.class';
import { NeedService } from '../services/need.service';
import { Contact } from '../../client/model/contact.class';
import { NEED_STATUSES, ENeedStatusesView } from '../values/need-statuses.const';
import { DatePipe } from '@angular/common';
import {EventEmitter} from '@angular/core';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
import { ClientService } from '../../client/service/client.service';
import { ServiceLocator } from '../../../shared/services/service-locator';
import * as _ from 'lodash';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { IPlumbStepSource } from '../../../dashboards/ecosystem-manager/interfaces/plumb-step-source.interface';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import { DATE_PRECISION } from '../values/date-precision.const';
import { NeedStatus } from './need-status.class';
import { QUANTITY_PRECISION } from '../values/quantity-precision.const';
import { NeedFile } from './need-file.class';
import { Ecosystem } from '../../../dashboards/ecosystem-manager/models/ecosystem.class';
import { EcosystemOffer } from '../../ecosystem-offer/ecosystem-offer.model';
import { ValidationService } from '../../../shared/services/validation-service';
import {TranslateService} from '@ngx-translate/core';
import { User } from '../../../shared/models/user.class';
import { NEED_DEPENDENCY } from '../../../dashboards/sales/values/sale-dependency.const';
import { CurrentUser } from '../../../shared/auth/current-user.class';
import {EDatePrecision} from '../values/date-precision.const';
import { isArray } from 'lodash';
import { Deposit } from '../../../entities/deposit/models/deposit.class';
import {ENeedMatchDepositsType} from '../values/need-match-deposits-type.enum';
import {INeedMatchDeposits} from '../values/need-match-deposits.interface';

export class Need extends ExtendedActiveRecord implements IPlumbStepSource {
  protected provider = NeedService;
  protected clientService: ClientService = ServiceLocator.injector.get(ClientService);
  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);
  public id: number;
  public name: string;
  public status: number;
  public user_id: number;
  public client_id: number;
  public client: Client;
  public passport_id: number;
  public passport: Passport;
  public client_contact_id: number;
  public client_contact: Contact;
  public client_location_id: number;
  public client_location: ClientLocation;
  public quantity: number;
  public _quantity_precision: number;
  public satisfying_quantity: number;
  public delivery_date: string;
  public _date_precision: EDatePrecision;
  public description: string;
  public location_comment: string;
  public created_at: string;
  public updated_at: string;
  public files: NeedFile[];
  public nomenclature: NomenclatureItem[];
  public ecosystems: Ecosystem[];
  public ecosystem_count: number;
  public sales_contact: string;
  public sales_name: string;
  public sales_email: string;
  public modification?: string;
  public esm_name: string;
  public esm_id: number;
  public sales_avatar: string;
  public offer: EcosystemOffer[];
  public independent: number;
  public sales_user: User;
  public treatable: boolean;
  public display_status: number;
  public deposits: Deposit[];
  public from_deposit: number;
  public fromDepositEntity: Deposit;
  public match_deposits: INeedMatchDeposits;
  public from_noah: boolean;

  public plumb_type = ECOSYSTEM_STEP_TYPE.NEED;

  set date_precision(newValue: EDatePrecision) {
    if (newValue === EDatePrecision.UNKNOWN || newValue === EDatePrecision.RECURRENT) {
      this.delivery_date = null;
    }
    this._date_precision = newValue;
  }
  get date_precision(): EDatePrecision {
    return this._date_precision;
  }
  get datePrecisionViewValue(): string {
    const datePres = DATE_PRECISION.find(i => i.key === this._date_precision);
    return datePres ? datePres.value : null;
  }

  get quantityTypeCasted(): number {
    return ValidationService.normalizeQuantity(this.quantity);
  }

  set quantity_precision(newValue: number) {
    if (newValue === 0) {
      this.quantity = null;
    }
    this._quantity_precision = newValue;
  }
  get quantity_precision(): number {
    return this._quantity_precision;
  }

  get quantity_text(): string {
    if (this.quantity === null) {
      return this.translateService.instant('unknown');
    }else {
      return this.quantity.toString();
    }
  }


  onChangeClient: EventEmitter<Client> = new EventEmitter();
  onChangePassport: EventEmitter<Passport> = new EventEmitter();
  onChangeContact: EventEmitter<Contact> = new EventEmitter();
  onChangeLocation: EventEmitter<ClientLocation> = new EventEmitter();

  get statusData(): NeedStatus {
    return Object
      .values(NEED_STATUSES)
      .find((item) => item.value === this.status);
  }

  get isDeliveryDateRequired() {
    return !this.delivery_date && this.date_precision !== EDatePrecision.RECURRENT
    && this.date_precision !== EDatePrecision.UNKNOWN;
  }

  protected fields() {
    return [
      'id',
      'name',
      'status',
      'user_id',
      'client_id',
      'client',
      'passport_id',
      'passport',
      'client_contact_id',
      'client_contact',
      'client_location_id',
      'client_location',
      'quantity',
      'quantity_precision',
      '_quantity_precision',
      'satisfying_quantity',
      'delivery_date',
      'date_precision',
      '_date_precision',
      'description',
      'location_comment',
      'created_at',
      'updated_at',
      'nomenclature',
      'files',
      'passport_materials',
      'passport_components',
      'passport_price',
      'ecosystems',
      'ecosystem_count',
      'sales_contact',
      'sales_name',
      'sales_email',
      'modification',
      'esm_name',
      'esm_id',
      'sales_avatar',
      'offer',
      'independent',
      'sales_user',
      'treatable',
      'display_status',
      'deposits',
      'from_deposit',
      'fromDepositEntity',
      'match_deposits',
      'from_noah'
    ];
  }

  dropClient() {
    this.client = new Client();
    this.client_id = null;
  }

  setClient(value: Client) {
    if (value instanceof Client) {
      this.client = value;
      this.client_id = value.id;
    } else {
      this.client = new Client();
      this.client_id = null;
    }
    this.onChangeClient.emit(this.client);
  }

  setLocation(value: ClientLocation | null) {
    if (value instanceof ClientLocation) {
      this.client_location = value;
      this.client_location_id = value.id;
    } else {
      this.client_location = new ClientLocation();
      this.client_location_id = null;
    }
    this.onChangeLocation.emit(this.client_location);
  }

  setPassport(value: Passport | null) {
    if (value instanceof Passport) {
      this.passport = value;
      this.passport_id = value.id;
    } else {
      this.passport = new Passport();
      this.passport_id = null;
    }
  }

  setClientContact(value: Contact | null) {
    if (value instanceof Contact) {
      this.client_contact = value;
      this.client_contact_id = value.id;
    } else {
      this.client_contact = null;
      this.client_contact_id = null;
    }
  }

  getContact(): Observable<Contact | null> {
    return new Observable((observer: any) => {
      if (!this.client_contact_id) {
        observer.next(null);
      } else if (this.client_contact && this.client_contact.id === this.client_contact_id) {
        observer.next(this.client_contact);
      } else {
        this.client_contact = new Contact({id: this.client_contact_id});
        this.client_contact.load().subscribe((res) => {
          this.client_contact.set(res);
          observer.next(this.client_contact);
        });
      }
    });
  }

  getClient(): Observable<Client | null> {
    if (!this.client_id) {
      return Observable.of(null);
    } else if (this.client && this.client.id === this.client_id) {
      return this.client.getContacts().map((res) => this.client);
    } else {
      this.client = new Client({id: this.client_id});
      return this
        .client
        .load({expand: this.clientService.relations.join(',')})
        .map((res) => this.client.getContacts())
        .map((res) => this.client);
    }
  }

  getProgress(): number {
    const disableValue = this.translateService.instant('unknown');
    if (this.client_id > 0 && this.client_contact_id > 0 && this.name) {
      if (this.passport_id > 0) {
        if (this.client_location_id > 0 &&
          (!this.isDeliveryDateRequired) &&
          (this.quantityTypeCasted > 0 || this.quantity_precision_text === disableValue)) {
          return 3;
        }
        return 2;
      }
      return 1;
    }
    return 0;
  }

  getValidationErrors() {
    const disableValue = this.translateService.instant('unknown');
    const disableDate = this.translateService.instant('recurrent');

    const errors: {type: string, text: string, progressStep: number}[] = [];
    if (!this.name) {
      errors.push({type: 'name', text: 'Need name is required', progressStep: 0});
    }
    if (!(this.client_id > 0)) {
      errors.push({type: 'client_id', text: 'Recipient must be set', progressStep: 0});
    }
    if (!(this.client_contact_id > 0)) {
      errors.push({type: 'client_contact_id', text: 'Recipient contact must be set', progressStep: 0});
    }
    if (!(this.passport_id > 0)) {
      errors.push({type: 'passport_id', text: 'Passport must be set', progressStep: 1});
    }
    if (!(this.client_location_id > 0)) {
      errors.push({type: 'client_location_id', text: 'Delivery location is required', progressStep: 2});
    }
    if (this.isDeliveryDateRequired) {
      errors.push({type: 'delivery_date', text: 'Delivery date is required', progressStep: 2});
    }
    if (!(this.quantityTypeCasted > 0) && this.quantity_precision_text !== disableValue) {
      errors.push({type: 'quantity', text: 'Quantity must be greater than 0', progressStep: 2});
    }
    return errors;
  }

  get status_data(): any {
    return _.values(NEED_STATUSES).find((STATUS: any) => STATUS.value === this.status);
  }

  get date_precision_text(): string {
    const found = DATE_PRECISION.find((item) => item.key === this.date_precision);
    return found ? this.translateService.instant(found.value) : '';
  }

  get quantity_precision_text(): string {
    const found = QUANTITY_PRECISION.find((item) => item.key === this.quantity_precision);
    return found ? this.translateService.instant(found.value) : '';
  }

  extractData(data: any): any {
    const json = super.extractData(data);
    if (!this.client || json.client) json.client = new Client(json.client);
    if (!this.client_location || json.client_location) {
      json.client_location = new ClientLocation(json.client_location);
    }
    if (!this.passport || json.passport) {
      Object.assign(json.passport || {}, {
        components: json.passport_components,
        materials: json.passport_materials,
        nomenclature: json.nomenclature,
        price: json.passport_price,
      });
      json.passport = new Passport(json.passport);
    }
    if (!this.client_contact || json.client_contact) json.client_contact = new Contact(json.client_contact);
    if (!this.nomenclature || json.nomenclature) {
      const array: NomenclatureItem[] = [];
      if (json.nomenclature) {
        json.nomenclature.map((item: any) => new NomenclatureItem((item)));
      }
    }
    json.files = this.files || (json.files || []).map((d: any) => new NeedFile(d));
    json.ecosystems = this.ecosystems || (json.ecosystems || []).map((d: any) => new Ecosystem(d));
    if (json.match_deposits && json.match_deposits.data) {
      json.match_deposits.data = (json.match_deposits.data || []).map(rawDeposit => new Deposit(rawDeposit));
    }
    if (json.offer) {
      if (isArray(json.offer)) {
        json.offer = json.offer.map((needOffer) => {
          needOffer.need = null;
          return new EcosystemOffer(needOffer);
        });
      } else {
        json.offer.need = null;
        json.offer = [new EcosystemOffer(json.offer)];
      }
    }
    return json;
  }

  hasUnEditableStatus(): boolean {
    const status =  [
      NEED_STATUSES.REFUSED.value ,
      NEED_STATUSES.REJECTED.value ,
      NEED_STATUSES.DONE.value
    ];
    return status.includes(this.status);
  }

  getViewStatus(): number {
   if ([ENeedStatusesView.SUSPENDED,
    ENeedStatusesView.EXPIRED,
    ENeedStatusesView.REFUSED,
    ENeedStatusesView.REJECTED,
    ENeedStatusesView.DONE].includes(this.status)){
    return ENeedStatusesView.ASK_FOR_MODIFICATION;
   } if ([ENeedStatusesView.DRAFT,
    ENeedStatusesView.WAITING_FOR_PUBLICATION,
    ENeedStatusesView.ASK_FOR_MODIFICATION,
    ENeedStatusesView.PUBLISHED].includes(this.status)){
    return ENeedStatusesView.DRAFT;
   } if ([ENeedStatusesView.TREATED,
    ENeedStatusesView.WAITING_FOR_VALIDATION,
    ENeedStatusesView.IN_PROGRESS,
    ENeedStatusesView.WAITING_FOR_CONFIRMATION,
    ENeedStatusesView.WAITING_FOR_QUOTE].includes(this.status)){
    return ENeedStatusesView.WAITING_FOR_PUBLICATION;
   } return ENeedStatusesView.CREATED;
  }

  includesText(searchText: string) {
    searchText = searchText.toLocaleLowerCase();

    const doesNameInclude = _.includes(this.name.toLocaleLowerCase(), searchText);
    const doesClientNameInclude = this.client && this.client.name && _.includes(this.client.name.toLocaleLowerCase(), searchText);
    const doesClientContactNameInclude = this.client_contact && this.client_contact.name && _.includes(this.client_contact.name.toLocaleLowerCase(), searchText);
    const doesPassportNameInclude = this.passport && this.passport.name && _.includes(this.passport.name.toLocaleLowerCase(), searchText);
    const doesClientLocationNameInclude = this.client_location && this.client_location.name && _.includes(this.client_location.name.toLocaleLowerCase(), searchText);
    const isInNomenclature = this.passport_id && this.nomenclature.some(item => item.name.toLocaleLowerCase() === searchText);

    return doesNameInclude ||
      doesClientNameInclude ||
      doesClientContactNameInclude ||
      doesPassportNameInclude ||
      doesClientLocationNameInclude ||
      isInNomenclature
      ;
  }

  beforeSave(data: any): any {
    const dataValues = super.beforeSave(data);
    if (dataValues.delivery_date) {
      try {
        dataValues.delivery_date = new DatePipe('en-Us').transform(dataValues.delivery_date, 'yyyy-MM-dd');
      } catch (e) {
        console.warn(e);
      }
    }
    if (dataValues._date_precision !== null) {
      dataValues.date_precision = dataValues._date_precision;
    }
    if (dataValues._quantity_precision !== null) {
      dataValues.quantity_precision = dataValues._quantity_precision;
    }
    if (dataValues.quantity) {
      dataValues.quantity = this.quantityTypeCasted;
    }

    return dataValues;
  }

  constructor(data = {}) {
    super(data);
  }
  isPublic() {
    return this.independent == NEED_DEPENDENCY.PUBLIC;
  }

  isOwnedByCurrentcompany() {
      return this.sales_user.company.id == CurrentUser.infoData.company_id;
  }

}
