import { ControlState } from '../../../shared/models/control-state.class';
import { PassportControlStates } from '../../passport/models/passport-control-states.class';

export class NeedControlStates {
  name?: ControlState;
  client_id?: ControlState;
  client_contact_id?: ControlState;
  passport_id?: ControlState;
  passport?: PassportControlStates;
  quantity?: ControlState;
  quantity_precision?: ControlState;
  delivery_date?: ControlState;
  date_precision?: ControlState;
  client_location_id?: ControlState;
  description?: ControlState;
  files?: ControlState;
  save?: ControlState;
  reject: ControlState = {disabled: true};
  publish: ControlState;
  askForModification: ControlState = {disabled: true};
}
