<div class="income-outcome__wrapper">
  <div class="income-outcome__block income-outcome__optional" id="income">
    <h2 class="income-outcome__header">{{'Input' | translate}}</h2>

    <div class="income-outcome__content">
      <h3 class="income-outcome__title">{{'Materials' | translate}}</h3>
      <md-chip-list class="income-outcome__list">
        <md-chip class="income-outcome__item"
                 *ngFor="let material of transform.inputMaterials">
            <span *ngIf="material.name.length > maxlength ;else elseBlock" md-tooltip="{{material.name | translate}}">
           {{material.name | translate | slice:0:maxlength }}..
          <button class="income-outcome__delete"
                  *ngIf="!readOnly"
                  (click)="removeMaterial(material)"
          ></button>
          </span>
          <ng-template #elseBlock>
            <span md-tooltip="{{material.name | translate}}">
              {{material.name | translate }}
              <button class="income-outcome__delete"
                      *ngIf="!readOnly"
                      (click)="removeMaterial(material)"
              ></button>
              </span>
          </ng-template>
        </md-chip>
      </md-chip-list>
      <div class="income-outcome__add primary-color"
           *ngIf="!readOnly"
           (click)="addMaterial(1)">
        + <span>{{'add a material' | translate}}</span>
      </div>

      <h3 class="income-outcome__title">{{'Keywords' | translate}}</h3>
      <div class="income-outcome__input-container">
        <md-chip-list class="income-outcome__container-list" id="keyword-items" >
          <md-chip class="income-outcome__item" 
                   *ngFor="let tag of transform.inputTags">
              <span *ngIf="tag.name.length > maxlength ;else tagIncomeBlock" md-tooltip="{{tag.name}}">
              {{tag.name | slice:0:maxlength }}..
               <button (click)="removeTag(tag)"
                       *ngIf="!readOnly"
                       class="income-outcome__delete"></button>
              </span>
            <ng-template #tagIncomeBlock>
                <span md-tooltip="{{tag.name}}">
                  {{tag.name }}
                  <button (click)="removeTag(tag)"
                          *ngIf="!readOnly"
                          class="income-outcome__delete"></button>
                  </span>
            </ng-template>
          </md-chip>
        </md-chip-list>
        <md-input-container *ngIf="isInputShown(transform.inputTags)">
            <input class="income-outcome__change"
            id="keyword-input"
                   mdInput
                   autocomplete="off" 
                   [disabled]="readOnly"
                   [(ngModel)]="tagInputTexts[1]"
                   (change)="addTag(1)">
        </md-input-container>
      </div>

      <h3 class="income-outcome__title">{{'Format' | translate}}</h3>
      <div class="income-outcome__input-container">
        <md-chip-list class="income-outcome__container-list">
          <md-chip class="income-outcome__item"
                   *ngFor="let format of transform.inputFormats">
              <span *ngIf="format.name.length > maxlength ;else formatIncomeBlock" md-tooltip="{{format.name}}">
              {{format.name | slice:0:maxlength }}..
               <button (click)="removeFormat(format)"
                       class="income-outcome__delete"></button>
              </span>
            <ng-template #formatIncomeBlock>
                <span md-tooltip="{{format.name}}">
                  {{format.name }}
                  <button (click)="removeFormat(format)"
                          class="income-outcome__delete"></button>
                  </span>
            </ng-template>
          </md-chip>
        </md-chip-list>
        <md-input-container  *ngIf="isInputShown(transform.inputFormats)">
          <input class="income-outcome__change"
                 mdInput
                 autocomplete="off" 
                 [disabled]="readOnly"
                 [(ngModel)]="formatInputTexts[1]"
                 (change)="addFormat(1)">
        </md-input-container>
      </div>

      <h3 class="income-outcome__title">{{'Packaging' | translate}}</h3>
      <div class="income-outcome__input-container">
        <md-chip-list class="income-outcome__container-list">
          <md-chip class="income-outcome__item"
                   *ngFor="let packaging of transform.inputPackagings">
              <span *ngIf="packaging.name.length > maxlength ;else packingIncomeBlock" md-tooltip="{{packaging.name}}">
              {{packaging.name | slice:0:maxlength }}..
               <button (click)="removePackaging(packaging)"
                       class="income-outcome__delete"></button>
              </span>
            <ng-template #packingIncomeBlock>
                <span md-tooltip="{{packaging.name}}">
                  {{packaging.name }}
                  <button (click)="removePackaging(packaging)"
                          class="income-outcome__delete"></button>
                  </span>
            </ng-template>
          </md-chip>
        </md-chip-list>
        <md-input-container *ngIf="isInputShown(transform.inputPackagings)">
          <input class="income-outcome__change"
                 mdInput
                 autocomplete="off" 
                 [disabled]="readOnly"
                 [(ngModel)]="packagingInputTexts[1]"
                 (change)="addPackaging(1)">
        </md-input-container>
      </div>

      <h3 class="income-outcome__title">{{'Quality' | translate}}</h3>
      <div class="income-outcome__input-container">
        <md-chip-list class="income-outcome__container-list">
          <md-chip class="income-outcome__item"
                   *ngFor="let quality of transform.inputQualities">
              <span *ngIf="quality.name.length > maxlength ;else qualitiesIncomeBlock" md-tooltip="{{quality.name}}">
              {{quality.name | slice:0:maxlength }}..
               <button (click)="removeQuality(quality)"
                       class="income-outcome__delete"></button>
              </span>
            <ng-template #qualitiesIncomeBlock>
                <span md-tooltip="{{quality.name}}">
                  {{quality.name }}
                  <button (click)="removeQuality(quality)"
                          class="income-outcome__delete"></button>
                  </span>
            </ng-template>
          </md-chip>
        </md-chip-list>
        <md-input-container  *ngIf="isInputShown(transform.inputQualities)">
          <input class="income-outcome__change"
                 mdInput
                 autocomplete="off" 
                 [disabled]="readOnly"
                 [(ngModel)]="qualityInputTexts[1]"
                 (change)="addQuality(1)">
        </md-input-container>
      </div>
    </div>

<!--    <div *ngIf="!readOnly" class="income-outcome__copy-all"-->
<!--         [mdTooltip]="'Copy all materials'"-->
<!--         [mdTooltipPosition]="'right'">-->
<!--      <md-icon>forward</md-icon>-->
<!--      <md-icon>forward</md-icon>-->
<!--    </div>-->

  </div>
  <div class="income-outcome__block" id="outcome">
    <h2 class="income-outcome__header">{{'Output' | translate}}</h2>
    <div class="income-outcome__content">
      <h3 class="income-outcome__title">{{'Materials' | translate}}</h3>
      <md-chip-list class="income-outcome__list">
        <md-chip class="income-outcome__item"
                 *ngFor="let material of transform.outputMaterials">
          <span *ngIf="material.name.length > maxlength ;else outcomeMatBlock"
                md-tooltip="{{material.name | translate}}">
          {{material.name | translate| slice:0:maxlength }}..
          <button class="income-outcome__delete"
                  *ngIf="!readOnly"
                  (click)="removeMaterial(material)"
          ></button>
          </span>
          <ng-template #outcomeMatBlock>
          <span md-tooltip="{{material.name | translate}}">
            {{material.name | translate }}
            <button class="income-outcome__delete"
                    *ngIf="!readOnly"
                    (click)="removeMaterial(material)"
            ></button>
            </span>
          </ng-template>
        </md-chip>
      </md-chip-list>
      <div class="income-outcome__add primary-color"
           *ngIf="!readOnly"
           (click)="addMaterial(0)">
        + <span>{{'add a material' | translate}}</span>
      </div>

      <h3 class="income-outcome__title">
        {{'Keywords' | translate}}
        <div class="income-outcome__label">
          <label *ngIf="!readOnly"
                 class="form-group__label"
                 [mdTooltip]="'TRANSFORMATION_INCOME_OUTCOME_TOOLTIP_KEYWORDS' | translate">
            <i class="form-group__label--question">?</i>
          </label>
        </div>
      </h3>
      <div class="income-outcome__input-container">
        <md-chip-list class="income-outcome__container-list">
          <md-chip class="income-outcome__item"
                   *ngFor="let tag of transform.outputTags">
              <span *ngIf="tag.name.length > maxlength ;else tagOutcomeBlock" md-tooltip="{{tag.name}}">
              {{tag.name | slice:0:maxlength }}..
               <button (click)="removeTag(tag)"
                       *ngIf="!readOnly"
                       class="income-outcome__delete"></button>
              </span>
            <ng-template #tagOutcomeBlock>
                <span md-tooltip="{{tag.name}}">
                  {{tag.name }}
                  <button (click)="removeTag(tag)"
                          *ngIf="!readOnly"
                          class="income-outcome__delete"></button>
                  </span>
            </ng-template>
          </md-chip>
        </md-chip-list>
        <md-input-container  *ngIf="isInputShown(transform.inputTags)">
          <input class="income-outcome__change"
                 mdInput
                 autocomplete="off" 
                 [disabled]="readOnly"
                 [(ngModel)]="tagInputTexts[0]"
                 (change)="addTag(0)">
        </md-input-container>
      </div>

      <h3 class="income-outcome__title">
        {{'Format' | translate}}
        <div class="income-outcome__label">
          <label *ngIf="!readOnly"
                 class="form-group__label"
                 md-tooltip="{{ 'TRANSFORMATION_INCOME_OUTCOME_TOOLTIP_FORMAT' | translate }}">
            <i class="form-group__label--question">?</i>
          </label>
        </div>
      </h3>
      <div class="income-outcome__input-container">
        <md-chip-list class="income-outcome__container-list">
          <md-chip class="income-outcome__item"
                   *ngFor="let format of transform.outputFormats">
              <span *ngIf="format.name.length > maxlength ;else formatOutcomeBlock" md-tooltip="{{format.name}}">
              {{format.name | slice:0:maxlength }}..
               <button (click)="removeFormat(format)"
                       class="income-outcome__delete"></button>
              </span>
            <ng-template #formatOutcomeBlock>
                <span md-tooltip="{{format.name}}">
                  {{format.name }}
                  <button (click)="removeFormat(format)"
                          class="income-outcome__delete"></button>
                  </span>
            </ng-template>
          </md-chip>
        </md-chip-list>
        <md-input-container  *ngIf="isInputShown(transform.outputFormats)">
          <input class="income-outcome__change"
                 mdInput
                 autocomplete="off" 
                 [disabled]="readOnly"
                 [(ngModel)]="formatInputTexts[0]"
                 (change)="addFormat(0)">
        </md-input-container>
      </div>

      <h3 class="income-outcome__title">
        {{'Packaging' | translate}}
        <div class="income-outcome__label">
          <label *ngIf="!readOnly"
                 class="form-group__label"
                 md-tooltip="{{ 'TRANSFORMATION_INCOME_OUTCOME_TOOLTIP_PACKAGING' | translate }}">
            <i class="form-group__label--question">?</i>
          </label>
        </div>
      </h3>
      <div class="income-outcome__input-container">
        <md-chip-list class="income-outcome__container-list">
          <md-chip class="income-outcome__item"
                   *ngFor="let packaging of transform.outputPackagings">
              <span *ngIf="packaging.name.length > maxlength ;else packagingOutcomeBlock" md-tooltip="{{packaging.name}}">
              {{packaging.name | slice:0:maxlength }}..
               <button (click)="removePackaging(packaging)"
                       class="income-outcome__delete"></button>
              </span>
            <ng-template #packagingOutcomeBlock>
                <span md-tooltip="{{packaging.name}}">
                  {{packaging.name }}
                  <button (click)="removePackaging(packaging)"
                          class="income-outcome__delete"></button>
                  </span>
            </ng-template>
          </md-chip>
        </md-chip-list>
        <md-input-container  *ngIf="isInputShown(transform.outputPackagings)">
          <input class="income-outcome__change"
                 mdInput
                 autocomplete="off" 
                 [disabled]="readOnly"
                 [(ngModel)]="packagingInputTexts[0]"
                 (change)="addPackaging(0)">
        </md-input-container>
      </div>

      <h3 class="income-outcome__title">
        {{'Quality' | translate}}
        <div class="income-outcome__label">
          <label *ngIf="!readOnly"
                 class="form-group__label"
                 md-tooltip="{{ 'TRANSFORMATION_INCOME_OUTCOME_TOOLTIP_QUALITY' | translate }}">
            <i class="form-group__label--question">?</i>
          </label>
        </div>
      </h3>
      <div class="income-outcome__input-container">
        <md-chip-list class="income-outcome__container-list">
          <md-chip class="income-outcome__item"
                   *ngFor="let quality of transform.outputQualities">
              <span *ngIf="quality.name.length > maxlength ;else qualitiesOutcomeBlock" md-tooltip="{{quality.name}}">
              {{quality.name | slice:0:maxlength }}..
               <button (click)="removeQuality(quality)"
                       class="income-outcome__delete"></button>
              </span>
            <ng-template #qualitiesOutcomeBlock>
                <span md-tooltip="{{quality.name}}">
                  {{quality.name }}
                  <button (click)="removeQuality(quality)"
                          class="income-outcome__delete"></button>
                  </span>
            </ng-template>
          </md-chip>
        </md-chip-list>
        <md-input-container  *ngIf="isInputShown(transform.outputQualities)">
          <input class="income-outcome__change"
                 mdInput
                 autocomplete="off" 
                 [disabled]="readOnly"
                 [(ngModel)]="qualityInputTexts[0]"
                 (change)="addQuality(0)">
        </md-input-container>
      </div>
    </div>
  </div>
</div>

