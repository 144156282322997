import { Injectable } from '@angular/core';
import {CrudService} from '../../../shared/services/crud.service';
import {PassportGtin} from '../models/passport-gtin.class';
import {Response} from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class PassportGtinService extends CrudService {
  protected ModelClass = PassportGtin;
  protected namespace = 'passport-gtins';
  protected namespaceSingular = 'passport-gtin';

  private get saveMultipleEndpoint() {
    return this.getEndpoint(this.singularEndpoint + '/create_multiple');
  }

  public saveMultipleWithSamePassports(passportId, codes: string[]) {
    return this.sendPost(this.saveMultipleEndpoint, {passport_id: passportId, codes})
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }
}
