import {Component, forwardRef, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {EOrderTransactionConfirmResultFieldType} from './order-transaction-confirm-result-field-type.enum';
import {PASSPORT_UNITS_MAP, PASSPORT_UNITS_TYPE} from '../../../entities/passport/values/passport-units.const';
import {ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {lowerCase} from 'lodash';
import {OrderPassport} from '../../../dashboards/ecosystem-manager/models/order-passport.class';
import moment from 'moment';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'order-transaction-confirm-result-field',
  templateUrl: './order-transaction-confirm-result-field.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OrderTransactionConfirmResultFieldComponent),
    multi: true
  }],
})
export class OrderTransactionConfirmResultFieldComponent implements OnInit, ControlValueAccessor {
  PASSPORT_UNIT_MAP = PASSPORT_UNITS_MAP;
  DEFAULT_CONVERSION = 1;
  FIELD_TYPE = EOrderTransactionConfirmResultFieldType;
  @Input() formControlName: string;
  @Input() className: string;
  @Input() type: EOrderTransactionConfirmResultFieldType;
  @Input() set placeholder(placeholder: string) {
    this.placeholderExpected = this.getPlaceholderResultText(placeholder, false);
    this.placeholderConfirmed = this.getPlaceholderResultText(placeholder, true);
  }
  @Input() placeholderExpected: string;
  @Input() placeholderConfirmed: string;
  @Input() entityPassport: OrderPassport;
  @Input() defaultValue: any;
  @Input() conversionFactor = 0;
  conversionUnit = PASSPORT_UNITS_TYPE.KG;
  disabled = false;
  control: FormControl;
  observer: Subscription;
  onTouch: Function;
  onChange: Function;
  @Input('conversionUnit') set setConversionUnit(unit: number) {
    this.conversionUnit = unit || PASSPORT_UNITS_TYPE.KG;
  }

  constructor(public controlContainer: ControlContainer,
              private translationService: TranslateService) { }

  ngOnInit() {
    this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
  }

  getPlaceholderResultText(name: string, isConfirmed: boolean) {
    const confirmText = lowerCase(this.translationService.instant(isConfirmed ? 'Confirmed' : 'expected'));
    return `${this.translationService.instant(name)} (${confirmText})`;
  }

  changed(event) {
    this.onTouch();
    this.onChange(event);
  }

  changeDate(event) {
    this.control.setValue(moment(event).format('DD-MM-YYYY'));
  }

  autofillButtonClick() {
    this.onChange(this.defaultValue);
  }
}
