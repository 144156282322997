/**
 * Created by Aleksandr C. on 9.02.17.
 */
import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { PassportFile } from '../models/passport-file.class';

@Injectable()
export class PassportFileService extends CrudService {
  protected namespace = 'passport-files';
  protected namespaceSingular = 'passport-file';
  protected ModelClass = PassportFile;

}
