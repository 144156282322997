<div class="flex align-center">
    <common-info-sign
      class="deposit-import-column-description__sign"
      *ngIf="showWarningSign"
      [borderSizeClassModifier]="'1'"
      [sizeClassModifier]="'18'"
    >!</common-info-sign>
  <div class="deposit-import-column-description__text"
       *ngIf="isDescriptionString()">
    {{description}}
  </div>
</div>
