import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TranslateService } from '@ngx-translate/core';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { ColorsService } from '../services/colors.service';
var Colors = /** @class */ (function (_super) {
    tslib_1.__extends(Colors, _super);
    function Colors() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = ColorsService;
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        return _this;
    }
    Colors.prototype.fields = function () {
        return ['color_id', 'passport_id', 'id', 'opacity'];
    };
    return Colors;
}(ActiveRecord));
export { Colors };
