import * as tslib_1 from "tslib";
var AbstractReportStrategy = /** @class */ (function () {
    function AbstractReportStrategy() {
    }
    AbstractReportStrategy.prototype.setOtherTotal = function (value, callback) {
        if (value === null || value === 0) {
            callback(0);
        }
        if (value) {
            callback(value);
        }
    };
    AbstractReportStrategy.prototype.setOtherDescription = function (value, callback) {
        if (value === null) {
            callback(null);
        }
        if (value) {
            callback(value);
        }
    };
    return AbstractReportStrategy;
}());
export { AbstractReportStrategy };
var AbstractReportStrategyWithDetails = /** @class */ (function (_super) {
    tslib_1.__extends(AbstractReportStrategyWithDetails, _super);
    function AbstractReportStrategyWithDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AbstractReportStrategyWithDetails.prototype.getOrders = function (step) {
        return step.summary_details ? (step.summary_details.orders || []) : [];
    };
    return AbstractReportStrategyWithDetails;
}(AbstractReportStrategy));
export { AbstractReportStrategyWithDetails };
