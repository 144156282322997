/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./place-autocomplete.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./place-autocomplete.component";
import * as i4 from "@agm/core/services/maps-api-loader/maps-api-loader";
import * as i5 from "../read-only/read-only.service";
var styles_PlaceAutocompleteComponent = [i0.styles];
var RenderType_PlaceAutocompleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlaceAutocompleteComponent, data: {} });
export { RenderType_PlaceAutocompleteComponent as RenderType_PlaceAutocompleteComponent };
function View_PlaceAutocompleteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [], [[2, "focus", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.focusedResultIndex === _v.context.index); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 1, 0, currVal_1); }); }
export function View_PlaceAutocompleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "popup"]], [[2, "focus", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "result-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "results"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaceAutocompleteComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.results; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFocus; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.results.length; _ck(_v, 4, 0, currVal_1); }); }
export function View_PlaceAutocompleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "place-autocomplete", [], null, null, null, View_PlaceAutocompleteComponent_0, RenderType_PlaceAutocompleteComponent)), i1.ɵdid(1, 1097728, null, 1, i3.PlaceAutocompleteComponent, [i4.MapsAPILoader, i1.ChangeDetectorRef, [2, i5.ReadOnlyService]], null, null), i1.ɵqud(335544320, 1, { directive: 0 })], null, null); }
var PlaceAutocompleteComponentNgFactory = i1.ɵccf("place-autocomplete", i3.PlaceAutocompleteComponent, View_PlaceAutocompleteComponent_Host_0, { options: "options" }, {}, ["*"]);
export { PlaceAutocompleteComponentNgFactory as PlaceAutocompleteComponentNgFactory };
