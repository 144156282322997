import * as tslib_1 from "tslib";
import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
var AgentStats = /** @class */ (function (_super) {
    tslib_1.__extends(AgentStats, _super);
    function AgentStats() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AgentStats.prototype.fields = function () {
        return [
            'deposit_locations_assigned',
            'deposit_locations_published',
            'deposits_assigned',
            'deposits_characterized',
            'deposits_published',
            'deposits_rejected',
            'deposits_validated'
        ];
    };
    AgentStats.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        Utils.convertJsonStringToNumber(json, [
            'deposit_locations_assigned',
            'deposit_locations_published',
            'deposits_assigned',
            'deposits_characterized',
            'deposits_published',
            'deposits_rejected',
            'deposits_validated'
        ]);
        return json;
    };
    return AgentStats;
}(BaseModel));
export { AgentStats };
