var DepositMapMarkersService = /** @class */ (function () {
    function DepositMapMarkersService() {
        this.markers = [];
    }
    DepositMapMarkersService.prototype.updateMarkers = function (deposits) {
        var _this = this;
        if (!this.isOpenPopUpOpen) {
            var groupedDepositsByLocationId_1 = this.groupByDepositLocationId(deposits);
            Object.keys(groupedDepositsByLocationId_1).map(function (locationId) {
                if (!_this.markers.some(function (m) { return m.id === Number(locationId); })) {
                    var deposit = groupedDepositsByLocationId_1[locationId][0];
                    if (deposit.deposit_location && deposit.deposit_location.lat && deposit.deposit_location.lng) {
                        _this.markers.push(_this.convertDepositToMapMarker(deposit));
                    }
                }
            });
        }
        else {
            this.markers[0].deposits += deposits.length;
        }
    };
    DepositMapMarkersService.prototype.handleMarkerClick = function (marker) {
        this.site = marker.id;
        this.markers = this.markers.filter(function (m) { return m.id === marker.id; });
    };
    DepositMapMarkersService.prototype.handlePopUpClosed = function () {
        this.site = null;
    };
    DepositMapMarkersService.prototype.convertDepositToMapMarker = function (deposit, depositsAmount) {
        return {
            lat: deposit.deposit_location.lat || null,
            lng: deposit.deposit_location.lng || null,
            name: deposit.deposit_location.name || null,
            deposits: depositsAmount || 0,
            id: deposit.deposit_location.id || null,
            address: deposit.deposit_location.address || null
        };
    };
    DepositMapMarkersService.prototype.groupByDepositLocationId = function (deposits) {
        return deposits.filter(function (d) { return d.deposit_location_id; }).reduce(function (obj, deposit) {
            if (!obj.hasOwnProperty(deposit.deposit_location_id)) {
                obj[deposit.deposit_location_id] = [];
            }
            obj[deposit.deposit_location_id].push(deposit);
            return obj;
        }, {});
    };
    Object.defineProperty(DepositMapMarkersService.prototype, "isOpenPopUpOpen", {
        get: function () { return !!this.site; },
        enumerable: true,
        configurable: true
    });
    DepositMapMarkersService.prototype.resetMarkers = function () {
        if (this.isOpenPopUpOpen) {
            this.markers[0].deposits = 0;
        }
        else {
            this.markers = [];
        }
    };
    DepositMapMarkersService.prototype.reset = function () {
        this.markers = [];
        this.site = null;
    };
    return DepositMapMarkersService;
}());
export { DepositMapMarkersService };
