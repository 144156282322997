/**
 * Created by aleksandr on 8.08.17.
 */

import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ActiveRecord } from '../models/active-record.class';

export abstract class EntityViewerComponent<M extends ActiveRecord> implements OnInit, OnDestroy {
  public subscription: Subscription;
  protected dataName = 'entity'

  constructor(protected route: ActivatedRoute,
              protected router: Router) {}

  abstract onExistEntity(entity: M): void;

  abstract onNewEntity(): void;

  ngOnInit() {
    this.subscription = this.router
      .events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => this.onEntityChanged(event));
    this.onEntityChanged();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onEntityChanged(event?: NavigationEnd) {
    let entity: M = this.route.snapshot.data[this.dataName];
    console.log('EntityViewer -> onEntityChanged:', entity);
    if (entity) {
      this.onExistEntity(entity);
    } else {
      this.onNewEntity();
    }
  }


}
