/**
 * Created by atat on 18/06/2017.
 */

 import { Component, OnInit } from '@angular/core';
 import { ICustomListRecord } from '../../../../shared/custom-list/interfaces/custom-list.interface';
 import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
 import { DepositOrderService } from '../../../ecosystem-manager/services/deposit-order.service';
 import { Searchable } from '../../../../shared/models/searchable.class';
 import { ECOSYSTEM_ORDER_STATUS, ECOSYSTEM_ORDER_STATUSES } from '../../../ecosystem-manager/values/ecosystem-order-statuses.const';
 import { DepositOrder } from '../../../ecosystem-manager/models/deposit-order.class';
 import { DatePipe } from '@angular/common';
 import { FormControl } from '@angular/forms';
 import { TranslateService } from '@ngx-translate/core';
 import { MdDialog } from '@angular/material';
 import { DepositOrderDetailsDialogComponent } from '../../dialogs/deposit-order-details-dialog/deposit-order-details-dialog.component';
 import {
   DepositOrderTransactionConfirmDialogComponent
 } from '../../dialogs/deposit-order-transaction-confirm-dialog/deposit-order-transaction-confirm-dialog.component';
 import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
 import {EcosystemProblemUtilsService} from '../../../../entities/ecosystem/services/ecosystem-problem-utils.service';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';

 @Component({
   moduleId: module.id,
   selector: 'do-archive-catalog',
   templateUrl: 'do-archive-catalog.component.html'
 })
 export class DoArchiveCatalogComponent implements OnInit {
   ECOSYSTEM_ORDER_STATUS = ECOSYSTEM_ORDER_STATUS;
   records: ICustomListRecord[] = [];
 
   ecosystemManagerList: Searchable[];
 
   sortOptions: any = [
     { field: 'confirmation_date', view: 'Date of archiving'},
     { field: 'esm_name', view: 'Ecosystem Manager'},
     { field: 'deposit_name', view: 'Deposit name'}
   ];
   sortValue: string = 'confirmation_date';
 
   //Filters
   ecoManagerId: number;
   search = new FormControl();
 
   filterStatuses: {
     status: ECOSYSTEM_ORDER_STATUS,
     statuses: ECOSYSTEM_ORDER_STATUS[]
     value: boolean
   }[] = [
     // {
     //   status: ECOSYSTEM_ORDER_STATUS.CONFIRMED,
     //   value: true,
     // },
     {
       status: ECOSYSTEM_ORDER_STATUS.DONE,
       statuses:[ECOSYSTEM_ORDER_STATUS.DONE, ECOSYSTEM_ORDER_STATUS.CONFIRMED],
       value: true,
     },
     {
       status: ECOSYSTEM_ORDER_STATUS.REJECTED,
       statuses:[ECOSYSTEM_ORDER_STATUS.REJECTED],
       value: true,
     },
     {
      status: ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED,
      statuses: [ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED],
      value: true,
    }
   ];
 
   constructor(private _orderService: DepositOrderService,
               private datePipe: DatePipe,
               private dialog: MdDialog,
               private translateService: TranslateService,
               private ecosystemProblemUtilsService: EcosystemProblemUtilsService,
               private notifications: LogNotificationService) {
     this._orderService.getEcoSystemManagerList({is_archive: true}).subscribe((managers) => this.ecosystemManagerList = managers);
 
     this
       .search
       .valueChanges
       .debounceTime(400)
       .distinctUntilChanged()
       .subscribe(() => this.getRecords())
     ;
   }
   getStatusAsText(orderStatus: ECOSYSTEM_ORDER_STATUS) {
    const metadata = ECOSYSTEM_ORDER_STATUSES.find(item => item.value === orderStatus);
    return metadata ? metadata.view : '';
  }

   getRecords() {
 
     let statuses =  this.filterStatuses.filter(d => d.value).map(d => d.statuses);
     let inlinestatuses = statuses.reduce((a, b) => a.concat(b), []);
 
     this._orderService
       .get({
         expand: 'deposit, creator, ecosystem, deposit_passport,order_passport',
         supervisor: true,
         deposit_owner: true,
         search: this.search.value || null,
         sort: this.sortValue || null,
         esm: this.ecoManagerId || null,
         'status[]': inlinestatuses
       })
       .subscribe((data: DepositOrder[]) => {
         this.records = [];
         this.records.push(...data.map(d => this.toRecord(d)));
       })
     ;
   }
 
   toRecord(order: DepositOrder) {
     let archiveString = '';
     if ([ECOSYSTEM_ORDER_STATUS.CONFIRMED, ECOSYSTEM_ORDER_STATUS.DONE].includes(+order.status)) {
       archiveString = 'Confirmed';
     }else {
       archiveString = 'Rejected';
     }
 
     return CustomListRecord
       .build()
       .addTitleColumn(order.deposit.name)
       .addTextColumn(order.ecosystem.name)
       .addUnitColumn(order.order_passport.quantity ? order.order_passport.quantity :  this.translateService.instant('Unknown')  ,order.order_passport.quantity? order.deposit.passport.customListUnit : null )
       .addDateColumn(order.confirmation_date, archiveString)
       .addAuthorColumn(order.creator).addControlsColumn([{
         fn: (data: ICustomListRecord) => {
           this.dialog.open(DepositOrderDetailsDialogComponent, {
             data: {
               id: order.id,
               readOnly: true,
             }
           }).afterClosed().subscribe(() => {
             console.log('closed');
           });
         },
         name: 'See details'
       }, ...( [ECOSYSTEM_ORDER_STATUS.DONE, ECOSYSTEM_ORDER_STATUS.CONFIRMED].includes(order.status) ? [{
         fn: (data: ICustomListRecord) => {
           this.dialog.open(DepositOrderTransactionConfirmDialogComponent, {
             ...DIALOG_THEME.TRANSACTION_ORDER_CONFIRMATION,
             data: {
                 id: order.id,
                 disabled: true,
               }
         });},
         name: 'See confirmation'
       }] : []) 
       , ...( [ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED].includes(order.status) ? [{
        fn: (data: ICustomListRecord) => this._orderService
          .view(order.id, {expand: 'problem'}).subscribe((orderWithProblem: DepositOrder) => {
            if (orderWithProblem.status === ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED) {
              console.log(orderWithProblem.problem);
              this.ecosystemProblemUtilsService.openOrderProblemDialog(orderWithProblem.problem.description || '');
            } else {
              this.notifications.error('No result found', null, true);
              this.getRecords();
            }
          }
      ),
        name: 'See order problem'
      }] : [])
      ])
   }
 
   ngOnInit() {
     this.getRecords();
   }
 }