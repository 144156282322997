/**
 * Created by atat on 18/06/2017.
 */
import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ECustomListHintClass, ECustomListRecordStatus } from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { TransformOrderService } from '../../../ecosystem-manager/services/transform-order.service';
import { TransformOrder } from '../../../ecosystem-manager/models/transform-order.class';
import { DatePipe } from '@angular/common';
import { TRANSFORM_ORDER_STATUS } from '../../values/transform-order-statuses.const';
import { MdDialog } from '@angular/material';
import { TsmOrderDetailsDialogComponent } from '../../dialogs/tsm-order-details-dialog/tsm-order-details-dialog.component';
import { TRANSFORM_TYPES } from '../../values/transform-types.const';
import * as moment from 'moment';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { isIndependent } from '../../values/transform-dependency.const';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { PASSPORT_UNITS_MAP } from '../../../../entities/passport/values/passport-units.const';
import { TransformationOrderTransactionConfirmDialogComponent } from '../../dialogs/transformation-order-transaction-confirm-dialog/transformation-order-transaction-confirm-dialog.component';
import { ReportProblemDialogComponent } from '../../../../shared/report-problem-dialog/report-problem-dialog.component';
import { NotificationSuccessComponent } from '../../../../shared/notification-dialogs/notification-success/notification-success.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
var TsmOrdersCatalogComponent = /** @class */ (function () {
    function TsmOrdersCatalogComponent(_ordersService, mdDialog, datepipe, translate, _translate, _globalEvents) {
        var _this = this;
        this._ordersService = _ordersService;
        this.mdDialog = mdDialog;
        this.datepipe = datepipe;
        this.translate = translate;
        this._translate = _translate;
        this._globalEvents = _globalEvents;
        this.records = [];
        this.transformOrders = [];
        this.currentPage = 1;
        this.totalPagesCount = 0;
        this.isNextPageLoading = false;
        this.PASSPORT_UNIT_MAP = PASSPORT_UNITS_MAP;
        this.sortOptions = [
            { field: 'publication_date', view: 'Days since reception' },
            { field: 'creator', view: 'Ecosystem Manager' },
            { field: 'transformation_name', view: 'Transformation name' }
        ];
        this.sortValue = 'publication_date';
        // Filters
        this.statuses = [
            {
                text: 'Ask for modification',
                value: TRANSFORM_ORDER_STATUS.ASK_FOR_MODIFICATION,
                checked: false
            },
            {
                text: 'Validated',
                value: TRANSFORM_ORDER_STATUS.VALIDATED,
                checked: false
            },
            {
                text: 'Booked',
                value: TRANSFORM_ORDER_STATUS.PENDING,
                checked: false
            },
            // {
            //   text: 'Confirmed',
            //   value: TRANSFORM_ORDER_STATUS.CONFIRMED
            // },
            {
                text: 'Waiting for validation',
                value: TRANSFORM_ORDER_STATUS.WAITING_FOR_VALIDATION,
                checked: true
            },
            {
                text: 'Waiting for confirmation',
                value: TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION,
                checked: true
            }
        ];
        this.TRANSFORM_TYPES = TRANSFORM_TYPES;
        this.type = null;
        var options = {
            'tsm_view': 1,
        };
        this._ordersService.getEsmList(options).subscribe(function (esmContacts) {
            _this.esmContacts = esmContacts;
        });
    }
    TsmOrdersCatalogComponent.prototype.addRecord = function (order) {
        var _this = this;
        var inputData = [];
        var orderWithCommonStep = [order].concat(order.orders_common_step.map(function (item) {
            item.transformation = order.transformation;
            return item;
        }));
        var arrivalAt = order.in_order_passport.linked_datetime;
        arrivalAt = arrivalAt ? this.datepipe.transform(arrivalAt, 'dd.MM.yyyy') : '';
        var arrivalIsToday = arrivalAt === this.datepipe.transform(new Date(), 'dd.MM.yyyy');
        var departureAt = order.out_order_passport.linked_datetime;
        departureAt = departureAt ? this.datepipe.transform(departureAt, 'dd.MM.yyyy') : '';
        orderWithCommonStep.forEach(function (filteredOrder) { return inputData.push({ key: _this.getInputDataString(filteredOrder), seperator: ' ', value: _this.getInputDataUnit(filteredOrder) }); });
        var daysPassed = moment()
            .diff(moment(order.publication_date || order.confirmation_date), 'days')
            .toFixed() || '';
        // -blue hand for waiting for validation
        // -nothing for pending & ask for a modification & confirmed
        // -green check for validated
        // -blue ? mark for waiting for confirmation
        var status;
        switch (order.status) {
            case TRANSFORM_ORDER_STATUS.WAITING_FOR_VALIDATION:
                status = ECustomListRecordStatus.MANUAL;
                break;
            case TRANSFORM_ORDER_STATUS.PENDING:
                status = ECustomListRecordStatus.NO_STATUS;
                break;
            case TRANSFORM_ORDER_STATUS.ASK_FOR_MODIFICATION:
                status = ECustomListRecordStatus.NO_STATUS;
                break;
            case TRANSFORM_ORDER_STATUS.CONFIRMED:
                status = ECustomListRecordStatus.NO_STATUS;
                break;
            case TRANSFORM_ORDER_STATUS.VALIDATED:
                status = ECustomListRecordStatus.CONFIRM;
                break;
            case TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION:
                status = ECustomListRecordStatus.QUESTION;
                break;
            default:
                status = ECustomListRecordStatus.NO_STATUS;
        }
        this.records.push(this.createOrderRecord(status, arrivalIsToday ? ECustomListHintClass.CONFIRM : ECustomListHintClass.NO_CLASS, arrivalIsToday ? 'Arrival is today' : '', this.getTransformationType(order, false), order.transformation.name, this.getTransformationType(order, true), order.ecosystem_name, daysPassed, arrivalAt, departureAt, isIndependent(order.transformation.independent) ? order.creator_company : order.creator, order, inputData));
    };
    TsmOrdersCatalogComponent.prototype.getTransformationType = function (order, capitalized) {
        var transformationType = TRANSFORM_TYPES.find(function (TYPE) { return TYPE.value === order.transformation.transformation_type; }).view;
        transformationType = this.translate.transform(transformationType);
        if (capitalized === true) {
            return transformationType.charAt(0).toUpperCase() + transformationType.slice(1).toLowerCase();
        }
        return transformationType;
    };
    TsmOrdersCatalogComponent.prototype.ngOnInit = function () {
        this.getRecords();
    };
    TsmOrdersCatalogComponent.prototype.onEndOfListTriggered = function () {
        if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount) {
            this.getRecords(this.currentPage + 1);
        }
    };
    TsmOrdersCatalogComponent.prototype.getRecords = function (forPage) {
        var _this = this;
        if (forPage === void 0) { forPage = 0; }
        if (!this.statuses.find(function (status) { return status.checked; })) {
            this.records.length = 0;
        }
        else {
            var commonStepOptions = ['in_order_passport', 'default_unit', 'default_conversion', 'child_step'];
            var options = {
                'expand': 'transformation,out_order_passport,creator,ecosystem_name,creator_company,'
                    + commonStepOptions.join(',') + ','
                    + commonStepOptions.map(function (option) { return "orders_common_step." + option; }).join(','),
                'supervisor': 1,
                'tsm_view': 1,
                'search': this.search || null,
                'esm': this.esmContact || null,
                'sort': this.sortValue || null,
                'status[]': this.statuses
                    .filter(function (status) { return status.checked; })
                    .map(function (status) { return status.value; }),
                'group_by_step': 1,
                'transformation_type': this.type,
                'page': forPage,
            };
            this.isNextPageLoading = true;
            this._ordersService
                .getWithPaginationData(options)
                .subscribe(function (res) {
                if (forPage === 0) {
                    _this.records = [];
                }
                var headers = res.headers;
                _this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
                _this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
                _this.transformOrders = res.json().map(function (item) { return new TransformOrder(item); });
                res.json().map(function (item) { return _this.addRecord(new TransformOrder(item)); });
                _this.isNextPageLoading = false;
            });
        }
    };
    TsmOrdersCatalogComponent.prototype.createOrderRecord = function (status, hintCssClass, hintText, icon, title, subtitle, esmName, daysPassed, arrivalAt, departureAt, author, entity, inputData) {
        var _this = this;
        var showValidationButtons = entity.status === TRANSFORM_ORDER_STATUS.WAITING_FOR_VALIDATION;
        var showConfirmationButton = entity.status === TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION;
        var showInvalidateButton = entity.status === TRANSFORM_ORDER_STATUS.PENDING;
        var controls = [
            {
                fn: function (data) {
                    var dialogRef = _this.mdDialog.open(TsmOrderDetailsDialogComponent, {
                        data: {
                            id: entity.id,
                            readOnly: !showValidationButtons,
                        },
                    });
                    dialogRef.afterClosed().subscribe(function (result) {
                        _this.getRecords();
                    });
                },
                name: 'See details'
            }
        ].concat((showValidationButtons ? [
            {
                fn: function () {
                    entity.validateByStep().subscribe(function () {
                        _this.getRecords();
                    });
                },
                name: 'Validate'
            },
            {
                fn: function () {
                    entity.rejectByStep().subscribe(function () {
                        _this.getRecords();
                    });
                },
                name: 'Reject'
            }
        ] : []), (showConfirmationButton ? [
            {
                fn: function () {
                    _this.mdDialog.open(TransformationOrderTransactionConfirmDialogComponent, {
                        width: '653px',
                        data: {
                            id: entity.id
                        }
                    }).afterClosed().subscribe(function () {
                        _this.getRecords();
                    });
                },
                name: 'Confirm'
            }
        ] : []), (showConfirmationButton ? [{ fn: function (data) {
                    var dialogRef = _this.mdDialog.open(ReportProblemDialogComponent, tslib_1.__assign({}, DIALOG_THEME.PROBLEM_REPORT, { data: {
                            foreword: 'The transformation could not be achieved?',
                            readOnly: !showConfirmationButton,
                            onConfirm: function (description) { return _this._ordersService.sendReport(entity.id, description).map(function () { return true; }); }
                        } }));
                    dialogRef.afterClosed().subscribe(function (result) {
                        if (result) {
                            _this.mdDialog.open(NotificationSuccessComponent, DIALOG_THEME.PROBLEM_REPORT);
                            _this.getRecords();
                        }
                    });
                },
                name: 'Problem with order'
            }] : []), (showInvalidateButton ? [
            {
                fn: function () {
                    entity.unvalidate().subscribe(function () {
                        _this.getRecords();
                    });
                },
                name: 'Invalidate'
            }
        ] : []));
        return CustomListRecord
            .build()
            .setStatus(status)
            .setHint(hintText, hintCssClass)
            .addTitleColumn(title, subtitle)
            .addCounterColumn(esmName, 'Ecosystem :')
            .addListColumn(inputData)
            .addListColumn([
            { key: daysPassed, value: '', seperator: ' ' },
            { key: '', value: this._translate.instant('Days since'), seperator: '' },
            { key: '', value: this._translate.instant('reception'), seperator: '' }
        ])
            .addCounterColumn(departureAt, 'End of transformation :')
            .addAuthorColumn(author)
            .addControlsColumn({ options: controls, icon: 'menu' });
    };
    TsmOrdersCatalogComponent.prototype.getInputDataString = function (order) {
        return order.inputNameView;
    };
    TsmOrdersCatalogComponent.prototype.getInputDataUnit = function (order) {
        return (!!order.orderInQuantityView || order.orderInQuantityView === 0)
            ? ' (' + (order.orderInQuantityView) + ' ' + this.PASSPORT_UNIT_MAP[order.unitInView] + ')' : '';
    };
    TsmOrdersCatalogComponent.prototype.openChat = function (targetId) {
        console.log('openChat', targetId);
        this._globalEvents.chatUserClicked$.emit(targetId);
    };
    return TsmOrdersCatalogComponent;
}());
export { TsmOrdersCatalogComponent };
