import { DepositImportService } from '../services/deposit-import.service';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { Observable } from 'rxjs';
import {EImportEntityStatus} from '../../../import-spreadsheet/values/enums/import-entity-status.enum';
export class DepositImport extends ExtendedActiveRecord {

  protected provider = DepositImportService;

  public id: number;
  public tab_name: string;
  public first_row: number;
  public last_row: number;
  public passport_id_field: string;
  public site_name_field: string;
  public tags_field: string;
  public quantity_field: string;
  public unit_field: string;
  public availability_date_field: string;
  public availability_expiration_field: string;
  public reference_date: string;
  public state_field: string;
  public state_description_field: string;
  public demountability_field: string;
  public comments_field: string;
  public reused_field: string;
  public deposit_name_field: string;
  public resale_url_field: string;
  public resale_price_field: string;
  public product_characteristics_field: string;
  public length_field: string;
  public width_field: string;
  public height_field: string;
  public apperance_field: string;
  public photo_field: string;
  public documents_field: string;
  public deposit_origin_field: string;
  public conversion_field: string;
  public potential_becoming_field: string;
  public status: EImportEntityStatus;

  public errors: {id: number, import_id: number, error_type: number, row: number, value: string}[];

  constructor(data?: any) {
    super(data);
  }

  protected fields() {
    return [
      'id',
      'tab_name',
      'first_row',
      'last_row',
      'passport_id_field',
      'site_name_field',
      'tags_field',
      'quantity_field',
      'unit_field',
      'availability_date_field',
      'availability_expiration_field',
      'reference_date',
      'state_field',
      'state_description_field',
      'demountability_field',
      'comments_field',
      'reused_field',
      'errors',
      'deposit_name_field',
      'resale_url_field',
      'resale_price_field',
      'product_characteristics_field',
      'length_field',
      'width_field',
      'height_field',
      'apperance_field',
      'photo_field',
      'documents_field',
      'deposit_origin_field',
      'conversion_field',
      'potential_becoming_field',
      'status'
    ];
  }

  startImport(): Observable<any> {
    return this.simplePost('/' + this.id + '/start');
  }
}
