<md-dialog-content class="deposit__view-map">
  <div class="deposit__view-map-header">
    <md-input-container class="deposit__view-map-search">
      <input
              mdInput
              autofocus
              autocomplete="off"
              placeholder="{{ 'Address' | translate }}"
              [(ngModel)]="selectionAddress"
              [readonly]="readonlyMode"
              #placesAutocompleteSearch
      >
    </md-input-container>
    <button md-button
            md-raised-button
            *ngIf="!readonlyMode"
            class="deposit__view-map-get-address"
            (click)="getAddressFromSelectionMarker()"
    >{{ 'Get address from marker' | translate }}
    </button>
    <button md-button
            md-raised-button
            *ngIf="!readonlyMode"
            class="deposit__view-map-select"
            (click)="select()"
    >{{ 'Select' | translate }}
    </button>
  </div>

  <agm-map
    [(latitude)]="mapCoords.lat"
    [(longitude)]="mapCoords.lng"
    [(zoom)]="mapZoom"
    (mapClick)="mapClick($event)"
  >
    <agm-marker
      *ngIf="currentCoords?.lat && currentCoords?.lng"
      [latitude]="currentCoords.lat"
      [longitude]="currentCoords.lng"
      iconUrl="/assets/svg/google-maps-my-location.svg"
    ></agm-marker>

    <agm-marker
      *ngIf="selectionCoords?.lat && selectionCoords?.lng"
      [(latitude)]="selectionCoords.lat"
      [(longitude)]="selectionCoords.lng"
      [markerDraggable]="!readonlyMode"
      (dragEnd)="selectionMarkerDragEnd($event)"
    >
      <agm-info-window *ngIf="!readonlyMode" [isOpen]="isSelectionInfoWindowOpen">
        {{ 'Move me to desired location' | translate }}
      </agm-info-window>
    </agm-marker>

    <view-map-deposit-location-circle
      *ngFor="let depositLocation of depositLocations"
      [depositLocation]="depositLocation"
      (select)="select(depositLocation)"
    ></view-map-deposit-location-circle>
  </agm-map>
</md-dialog-content>
