import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { TransformOrder } from '../../../ecosystem-manager/models/transform-order.class';
import { TransformOrderService } from '../../../ecosystem-manager/services/transform-order.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../../ecosystem-manager/values/ecosystem-file-junction-item-type.const';
import { EcosystemFileJunctionService } from '../../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TRANSFORM_TYPE, TRANSFORM_TYPES } from '../../values/transform-types.const';
import { CapitalizePipe } from '../../../../shared/pipes/capitalize.pipe';
import { EcosystemFileService } from '../../../../entities/ecosystem/services/ecosystem-file.service';
import { EcosystemService } from '../../../../entities/ecosystem/services/ecosystem.service';
import { EOrderTransactionConfirmResultFieldType } from '../../../../shared/convert-components/order-transaction-confirm-result-field/order-transaction-confirm-result-field-type.enum';
import { touchEachFormControl } from '../../../../shared/helpers/touch-each-form-control';
import { EcosystemFileToJunctionService } from '../../../../entities/ecosystem/services/ecosystem-file-to-junction.service';
import { RequestFileSenderService } from '../../../admin/services/request-file-sender.service';
import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { mergeMap } from 'rxjs/operators';
import { Transform } from '../../../../entities/transformation/models/transform.class';
var TransformationOrderTransactionConfirmComponent = /** @class */ (function () {
    function TransformationOrderTransactionConfirmComponent(ecosystemService, ecosystemFileService, requestFileSenderService, ecosystemJunctionService, ecosystemFileToJunctionService, transformationOrderService, notifications, translationService, capitalizePipe, fb) {
        this.ecosystemService = ecosystemService;
        this.ecosystemFileService = ecosystemFileService;
        this.requestFileSenderService = requestFileSenderService;
        this.ecosystemJunctionService = ecosystemJunctionService;
        this.ecosystemFileToJunctionService = ecosystemFileToJunctionService;
        this.transformationOrderService = transformationOrderService;
        this.notifications = notifications;
        this.translationService = translationService;
        this.capitalizePipe = capitalizePipe;
        this.fb = fb;
        this.TRANSFORM_TYPE = TRANSFORM_TYPE;
        this.RESULT_FIELD_TYPE = EOrderTransactionConfirmResultFieldType;
        this.disabled = false;
        this.showESMText = false;
        this.onConfirm = new EventEmitter();
        this.onCancel = new EventEmitter();
        this.allFiles = [];
        this.filesWithJunction = [];
    }
    TransformationOrderTransactionConfirmComponent.prototype.ngOnInit = function () {
        this.loadTransformation();
    };
    TransformationOrderTransactionConfirmComponent.prototype.loadTransformation = function () {
        if (this.orders) {
            this.onAfterOrderLoaded();
        }
        else if (this.id) {
            this.loadTransformationById();
        }
        else if (this.step_id) {
            this.loadTransformationByStepId();
        }
        else {
            this.onLoadErrorMessage();
        }
    };
    TransformationOrderTransactionConfirmComponent.prototype.loadTraceabilityFiles = function () {
        var _this = this;
        this.ecosystemJunctionService.get({
            item_id: this.orderParent.id, item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_TRANSFORMATION_ORDER_TRACEABILITY
        }).subscribe(function (junctions) {
            _this.allFiles = junctions.map(function (junction) { return junction.ecosystem_file; });
            _this.filesWithJunction = _this.allFiles.slice();
        });
    };
    TransformationOrderTransactionConfirmComponent.prototype.loadTransformationById = function () {
        var _this = this;
        this.transformationOrderService.view(this.id, { expand: this.getCommonRequestExpandAsString() })
            .subscribe(function (order) {
            _this.orderParent = order;
            _this.orders = [order];
            _this.onAfterOrderLoaded();
        });
    };
    TransformationOrderTransactionConfirmComponent.prototype.getCommonRequestExpandAsString = function () {
        return 'in_order_passport,out_order_passport,transformation,ecosystem,ecosystem_id,transaction_quantity' +
            ',transaction_price,transaction_distance,transaction_description,transaction_date,default_conversion,default_unit';
    };
    TransformationOrderTransactionConfirmComponent.prototype.onAfterOrderLoaded = function () {
        if (!this.disabled) {
            this.resetTransactionConfirmationRequiredFields();
        }
        this.initForm();
        this.loadTraceabilityFiles();
    };
    TransformationOrderTransactionConfirmComponent.prototype.loadTransformationByStepId = function () {
        var _this = this;
        this.transformationOrderService.get({ expand: this.getCommonRequestExpandAsString(), step_id: this.step_id })
            .subscribe(function (orders) {
            if (orders.length) {
                _this.orderParent = orders[0];
                _this.orders = orders;
                _this.onAfterOrderLoaded();
            }
            else {
                _this.onLoadErrorMessage();
            }
        });
    };
    TransformationOrderTransactionConfirmComponent.prototype.onLoadErrorMessage = function () {
        this.notifications.error('No result found', null, true);
    };
    TransformationOrderTransactionConfirmComponent.prototype.initForm = function () {
        var _this = this;
        var getConfirmControlConfig = function (value) { return ([{ disabled: _this.disabled, value: value }, Validators.required]); };
        var asControl = function (name, confirmed) {
            var _a;
            return (_a = {}, _a[name] = getConfirmControlConfig(confirmed), _a);
        };
        this.form = this.fb.group({
            inputs: this.fb.array(this.orders.map(function (order) { return _this.fb.group(tslib_1.__assign({ entity: [order], entityPassport: [order.in_order_passport] }, asControl('quantity', _this.getTransactionQuantity(order.in_order_passport, order)), (_this.transformType !== TRANSFORM_TYPE.STORAGE ?
                asControl('date', _this.getTransactionDate(order.in_order_passport)) : {}))); })),
            outputs: this.fb.array([
                this.fb.group(tslib_1.__assign({ entity: [this.orderParent], entityPassport: [this.orderParent.out_order_passport] }, (this.isTransformTypeDefault() ?
                    asControl('quantity', this.getTransactionQuantity(this.orderParent.out_order_passport, this.orderParent, false)) : {}), asControl('date', this.getTransactionDate(this.orderParent.out_order_passport))))
            ]),
            modalities: this.fb.group(tslib_1.__assign({ entity: [this.orderParent], entityPassport: [this.orderParent.out_order_passport] }, asControl('price', this.getTransactionPrice(this.orderParent.out_order_passport, this.orderParent)), (this.transformType === TRANSFORM_TYPE.TRANSPORT
                ? asControl('distance', this.getTransactionDistance()) : {}))),
            description: [{ disabled: this.disabled, value: this.orderParent.out_order_passport.transaction_description }]
        });
    };
    TransformationOrderTransactionConfirmComponent.prototype.resetTransactionConfirmationRequiredFields = function () {
        var reset = function (transformOrder) {
            return [transformOrder.in_order_passport, transformOrder.out_order_passport].forEach(function (order) {
                order.transaction_distance = null;
                order.transaction_date = null;
                order.transaction_quantity = null;
                order.transaction_price = null;
            });
        };
        this.orders.forEach(reset);
        reset(this.orderParent);
    };
    TransformationOrderTransactionConfirmComponent.prototype.getTransactionQuantity = function (passport, order, input) {
        if (input === void 0) { input = true; }
        if (input) {
            return this.transactionConfirmValue(order.getCustomInQuantityView(passport, passport.transaction_quantity), order.orderInQuantityView);
        }
        else {
            return this.transactionConfirmValue(Transform.transformQuantityToCustomDefault(passport.transaction_quantity, order.transformation), order.orderOutQuantityView);
        }
    };
    TransformationOrderTransactionConfirmComponent.prototype.getTransactionDate = function (order) {
        return this.transactionConfirmValue(order.transaction_date, order.linked_datetime);
    };
    TransformationOrderTransactionConfirmComponent.prototype.getTransactionPrice = function (order, transformOrder) {
        return this.transactionConfirmValue(order.transaction_price, transformOrder.price);
    };
    TransformationOrderTransactionConfirmComponent.prototype.getTransactionDistance = function () {
        return this.transactionConfirmValue(this.orderParent.out_order_passport.transaction_distance, this.orderParent.child_step["estimated_distance"]);
    };
    TransformationOrderTransactionConfirmComponent.prototype.transactionConfirmValue = function (confirm, defaultValue) {
        return this.disabled ? (confirm || (confirm === 0 ? 0 : defaultValue)) : '';
    };
    Object.defineProperty(TransformationOrderTransactionConfirmComponent.prototype, "transformType", {
        get: function () {
            return this.orderParent.transformation.transformation_type;
        },
        enumerable: true,
        configurable: true
    });
    TransformationOrderTransactionConfirmComponent.prototype.getPlaceholderInputTitle = function () {
        switch (this.transformType) {
            case TRANSFORM_TYPE.TRANSPORT: {
                return this.translationService.instant('Deposits to transport');
            }
            case TRANSFORM_TYPE.STORAGE: {
                return this.translationService.instant('Deposits to be stored');
            }
            default: {
                return this.translationService.instant('Input');
            }
        }
    };
    TransformationOrderTransactionConfirmComponent.prototype.getPlaceholderInputDateExpected = function () {
        switch (this.transformType) {
            case TRANSFORM_TYPE.TRANSPORT: {
                return this.translationService.instant('Pickup date (expected)');
            }
            default: {
                return this.translationService.instant('Reception date (expected)');
            }
        }
    };
    TransformationOrderTransactionConfirmComponent.prototype.getPlaceholderInputDateConfirm = function () {
        switch (this.transformType) {
            case TRANSFORM_TYPE.TRANSPORT: {
                return this.translationService.instant('Pickup date (confirmed)');
            }
            default: {
                return this.translationService.instant('Reception date (confirmed)');
            }
        }
    };
    Object.defineProperty(TransformationOrderTransactionConfirmComponent.prototype, "outputQuantity", {
        get: function () {
            return this.orders.reduce(function (accumulator, item) { return accumulator + item.out_order_passport.quantity; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    TransformationOrderTransactionConfirmComponent.prototype.getPlaceholderOutputDateExpexted = function () {
        switch (this.transformType) {
            default: {
                return this.translationService.instant('End of transformation date (expected)');
            }
        }
    };
    TransformationOrderTransactionConfirmComponent.prototype.getPlaceholderOutputDateConfirmed = function () {
        switch (this.transformType) {
            default: {
                return this.translationService.instant('End of transformation date (confirmed)');
            }
        }
    };
    TransformationOrderTransactionConfirmComponent.prototype.getPlaceholderModalitiesDate = function () {
        switch (this.transformType) {
            case TRANSFORM_TYPE.TRANSPORT: {
                return 'Delivery date';
            }
            case TRANSFORM_TYPE.STORAGE: {
                return 'End date of storage';
            }
            default: {
                return '';
            }
        }
    };
    TransformationOrderTransactionConfirmComponent.prototype.isTransformTypeDefault = function () {
        return !(this.transformType === TRANSFORM_TYPE.STORAGE
            || this.transformType === TRANSFORM_TYPE.TRANSPORT);
    };
    TransformationOrderTransactionConfirmComponent.prototype.getControlEntity = function (control) {
        return this.getControlValueByName(control, 'entity');
    };
    TransformationOrderTransactionConfirmComponent.prototype.getControlEntityPassport = function (control) {
        return this.getControlValueByName(control, 'entityPassport');
    };
    TransformationOrderTransactionConfirmComponent.prototype.getControlValueByName = function (control, name) {
        return control.controls[name] && control.controls[name].value;
    };
    Object.defineProperty(TransformationOrderTransactionConfirmComponent.prototype, "inputs", {
        get: function () {
            return this.getFormArray('inputs');
        },
        enumerable: true,
        configurable: true
    });
    TransformationOrderTransactionConfirmComponent.prototype.getFormArray = function (name) {
        return (this.form
            ? this.form.controls[name].controls
            : this.fb.array([]).controls);
    };
    Object.defineProperty(TransformationOrderTransactionConfirmComponent.prototype, "outputs", {
        get: function () {
            return this.getFormArray('outputs');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformationOrderTransactionConfirmComponent.prototype, "title", {
        get: function () {
            var _this = this;
            var typeName = TRANSFORM_TYPES.find(function (item) { return item.value ===
                (_this.orderParent.transformation && _this.transformType); });
            if (this.disabled) {
                return this.translationService.instant('Transformation') + ' ' + this.translationService.instant('confirmation');
            }
            else {
                return this.translationService.instant('Confirm') + " " + (typeName ? this.translationService.instant(typeName.view) : '');
            }
        },
        enumerable: true,
        configurable: true
    });
    TransformationOrderTransactionConfirmComponent.prototype.createFileJunctions = function (uploadedFiles) {
        var _this = this;
        var junctionService = this.ecosystemFileToJunctionService;
        var callback = function (data) {
            _this.requestFileSenderService.documentUploadObservableNext(data.files, !!data.failed.length);
        };
        junctionService.updateFilesAndJunctions({ entity: this.orderParent, type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_TRANSFORMATION_ORDER_TRACEABILITY }, uploadedFiles, this.filesWithJunction, callback);
    };
    TransformationOrderTransactionConfirmComponent.prototype.deleteOnlyLinkIfUploaded = function () {
        var _this = this;
        return function (file) {
            var reset = function (junction) {
                _this.allFiles = _this.allFiles.filter(function (item) { return item.id !== junction.file_id; });
                _this.filesWithJunction = _this.filesWithJunction.filter(function (item) { return item.file_id !== file.id; });
            };
            return !_this.ecosystemFileToJunctionService.deleteLinkInsteadIfExist(file, _this.filesWithJunction, reset);
        };
    };
    TransformationOrderTransactionConfirmComponent.prototype.confirmOrder = function () {
        var _this = this;
        if (this.form && this.form.valid) {
            var _a = this.getValuesFromControl(this.outputs[0]), date_1 = _a.date, quantity_1 = _a.quantity;
            var commons_1 = {
                transaction_price: this.orderParent.out_order_passport.transaction_price,
                transaction_distance: this.orderParent.out_order_passport.transaction_distance,
                transaction_description: this.orderParent.out_order_passport.transaction_description,
            };
            this.orders.forEach(function (order) {
                order.out_order_passport.transaction_date = date_1;
                order.out_order_passport.transaction_quantity = _this.orderParent.transformFromOutView(quantity_1);
                order.out_order_passport.transaction_price = commons_1.transaction_price;
                order.out_order_passport.transaction_distance = commons_1.transaction_distance;
                order.out_order_passport.transaction_description = commons_1.transaction_description;
                order.confirmed_distance = commons_1.transaction_distance;
            });
            this.inputs.forEach(function (item) {
                var inputData = _this.getValuesFromControl(item);
                var inOrder = item.controls['entity'].value;
                inOrder.in_order_passport.transaction_quantity = inOrder.transformFromInView(inputData.quantity);
                inOrder.in_order_passport.transaction_date = inputData.date;
            });
            this.onConfirm.emit(Observable.zip.apply(Observable, this.orders.map(function (item) {
                return Observable.combineLatest(item.in_order_passport.save(), item.out_order_passport.save()).pipe(mergeMap(function () { return item.confirm(); }));
            })));
        }
        else {
            touchEachFormControl(this.form);
            this.notifications.error('You must fill in all the fields on the right to confirm the data', null, true);
        }
    };
    TransformationOrderTransactionConfirmComponent.prototype.getValuesFromControl = function (control) {
        var date = control.controls['date']
            && moment(control.controls['date'].value, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
        var quantity = control.controls['quantity'] && control.controls['quantity'].value;
        return { date: date, quantity: quantity };
    };
    return TransformationOrderTransactionConfirmComponent;
}());
export { TransformationOrderTransactionConfirmComponent };
