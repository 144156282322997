import { ActiveRecord } from '../../../shared/models/active-record.class';
import {  GenericPassportMaterialService } from '../service/generic-passport-material.service';


export class GenericPassportMaterial extends ActiveRecord {
  protected provider = GenericPassportMaterialService;

  public id: number;
  public nomenclature_id: number;
  public generic_passport_id: number;
 
  public name: string;

  protected fields() {
    return [
      'id',
      'nomenclature_id',
      'generic_passport_id',
      'name',
    ];
  }

}


