import { Component } from '@angular/core';


@Component({
  moduleId: module.id,
  templateUrl: 'ecosystem-dashboard.component.html',
  styleUrls: ['ecosystem-dashboard.component.scss'],
})
export class EcosystemDashboardComponent {

  constructor() {

    console.info('Launch  -> EcosystemDashboardComponent');
  }
}
