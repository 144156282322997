import {Component, Inject, OnInit} from '@angular/core';
import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {PassportService} from '../../services/passport.service';
import {Passport} from '../../models/passport.class';
import {VIEW_MODE} from '../../values/view-mode-values.const';
import {PassportControlStates} from '../../models/passport-control-states.class';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PASSPORT_NAME_TYPE, PASSPORT_NAME_TYPE_VIEW} from '../../values/passport-name-types.const';
import {CurrentUser} from '../../../../shared/auth/current-user.class';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {User} from '../../../../shared/models/user.class';
import {PASSPORT_TYPES} from '../../values/passport-types.const';
import {UserService} from '../../../../shared/services/user.service';
import {TranslationService} from '../../../../shared/services/translation.service';
import {ITranslationRequest, ITranslationResponse} from '../../services/translation.interface';

@Component({
  moduleId: module.id,
  selector: 'name-generation-dialog',
  templateUrl: 'name-generation-dialog.component.html'
})
export class NameGenerationDialogComponent implements OnInit {
  public passport: Passport;
  public controlStates: PassportControlStates;
  public viewMode: VIEW_MODE;
  public nameGenerationFormGroup: FormGroup;
  public nameTypes = PASSPORT_NAME_TYPE;
  public nameTypesView = PASSPORT_NAME_TYPE_VIEW;
  public ObjectValues = Object.values;
  public enableSearch: boolean;
  public askForRatingMode: boolean;
  public parentModule;
  selectExistingPassportAvailable: boolean;
  VIEW_MODE = VIEW_MODE;

  constructor(@Inject(MD_DIALOG_DATA) public dialogData: any,
              public _dialogRef: MdDialogRef<any>,
              public _fb: FormBuilder,
              protected _router: Router,
              private passportService: PassportService,
              private userService: UserService,
              private translationService: TranslationService) {
    this.passport = this.dialogData.passport || new Passport();
    this.viewMode = this.dialogData.viewMode;
    this.controlStates = this.dialogData.controlStates;
    this.enableSearch = this.dialogData.enableSearch;
    this.askForRatingMode = this.dialogData.askForRatingMode || false;
    this.parentModule = this.dialogData.parentModule;
    this.selectExistingPassportAvailable = this.dialogData.selectExistingPassportAvailable;

    this.setManufacturer();
  }

  ngOnInit() {
    
    this.nameGenerationFormGroup = this._fb.group({
      nameType: [''],
      passportName: ['', Validators.required],
      passportEnglishName: ['', Validators.required],
      passportFormat: [''],
      manufacturer: [''],
      tradeName: [''],
      productRefrence: [''],
      typology: [''],
      additional: [''],
      compositeName: [''],
      version:[{value:this.getVersion()},[Validators.pattern('^[0-9]{4}-?[0-9]*$'), Validators.required]]
    });
    this.passport.version = this.passport.version || this.getVersion();
   
  }
  getVersion() {
   return  this.passport.version ? this.passport.version : String(this.getYear()) ;
  }
  setManufacturer() {
    if (CurrentUser.infoData.id && this.parentModule === ModuleNames.MANUFACTURER && !this.passport.id) {
      this.userService.view(CurrentUser.infoData.id, {expand: 'company'}).subscribe((user: User) => {
        this.passport.manufacturer = user.company_name;
      });
      this.passport.type = PASSPORT_TYPES.MANUFACTURER;
    }
  }

  onControlClick($event) {
    console.log('onControlClick', $event);
  }
  getYear() {
    return new Date().getFullYear();
  }

  validate() {
    if (this.nameGenerationFormGroup.invalid) {
      this.nameGenerationFormGroup.get('passportName').markAsTouched();
      this.nameGenerationFormGroup.get('passportEnglishName').markAsTouched();
      this.nameGenerationFormGroup.get('version').markAsTouched();
      return;
    }
    this._dialogRef.close({passport: this.passport, clearForm: false});
  }

  close() {
    this._dialogRef.close({passport: null, clearForm: false});
  }

  onSelectAnExistingPassport($event) {
    console.log('onSelectAnExistingPassport', $event);
    this._dialogRef.close({passport: new Passport(), clearForm: true});
  }

  setAsterisk() {
    return this.askForRatingMode ? '*' : '';
  }

  translationIsPossible() {
    return this.passport.name && this.passport.name.length > 0;
  }

  translateNameToEnglish() {
    if (this.passport.name && this.passport.name.length > 0) {
      const payload: ITranslationRequest = {'q': [this.passport.name], 'target': 'en'};
      this.translationService.translate(payload).subscribe((response: ITranslationResponse[]) => {
        if (response.length === 1) {
          this.passport.english_name = response[0].translatedText;
        }
      });
    }
  }
}
