/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./deposit-delete-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material";
import * as i4 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i5 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i6 from "../../../../shared/read-only/read-only.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./deposit-delete-modal.component";
import * as i9 from "../../../../entities/deposit/services/deposit.service";
var styles_DepositDeleteModalComponent = [i0.styles];
var RenderType_DepositDeleteModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DepositDeleteModalComponent, data: {} });
export { RenderType_DepositDeleteModalComponent as RenderType_DepositDeleteModalComponent };
export function View_DepositDeleteModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 27, "md-dialog-content", [], [[2, "mat-dialog-content", null]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(3, 16384, null, 0, i3.MdDialogContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 24, "div", [["class", "deposit-delete-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "deposit-delete-modal__heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "deposit-delete-modal__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["class", "deposit-delete-modal__triangle"], ["src", "assets/png/warning_green.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "deposit-delete-modal__exclamation"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["!"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "deposit-delete-modal__text-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "deposit-delete-modal__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 16, "div", [["class", "deposit-delete-modal__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 7, "button", [["class", "deposit-delete-modal__button"], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MdButton_0, i4.RenderType_MdButton)), i1.ɵdid(14, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(15, 180224, null, 0, i3.MdButton, [i1.ElementRef, i1.Renderer, i3.FocusOriginMonitor], null, null), i1.ɵdid(16, 16384, null, 0, i3.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(17, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null), (_l()(), i1.ɵted(18, 0, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(20, 1), (_l()(), i1.ɵeld(21, 0, null, null, 7, "button", [["class", "deposit-delete-modal__button gray"], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MdButton_0, i4.RenderType_MdButton)), i1.ɵdid(22, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(23, 180224, null, 0, i3.MdButton, [i1.ElementRef, i1.Renderer, i3.FocusOriginMonitor], null, null), i1.ɵdid(24, 16384, null, 0, i3.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(25, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null), (_l()(), i1.ɵted(26, 0, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(28, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.warningMessage; _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 15).disabled; var currVal_3 = true; var currVal_4 = ((i1.ɵnov(_v, 17).readOnlyService == null) ? null : i1.ɵnov(_v, 17).readOnlyService.readOnly); _ck(_v, 13, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 18, 0, _ck(_v, 20, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("Confirm")))); _ck(_v, 18, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 23).disabled; var currVal_7 = true; var currVal_8 = ((i1.ɵnov(_v, 25).readOnlyService == null) ? null : i1.ɵnov(_v, 25).readOnlyService.readOnly); _ck(_v, 21, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = i1.ɵunv(_v, 26, 0, _ck(_v, 28, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 26, 0, i1.ɵnov(_v, 27).transform("Cancel")))); _ck(_v, 26, 0, currVal_9); }); }
export function View_DepositDeleteModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "deposit-delete-modal", [], null, null, null, View_DepositDeleteModalComponent_0, RenderType_DepositDeleteModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.DepositDeleteModalComponent, [i3.MdDialogRef, i3.MD_DIALOG_DATA, i9.DepositService, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DepositDeleteModalComponentNgFactory = i1.ɵccf("deposit-delete-modal", i8.DepositDeleteModalComponent, View_DepositDeleteModalComponent_Host_0, {}, {}, []);
export { DepositDeleteModalComponentNgFactory as DepositDeleteModalComponentNgFactory };
