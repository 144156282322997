/**
 * Created by Aleksandr C. on 7.02.17.
 */
import { Injectable } from '@angular/core';
import {Ng2Datetime } from 'ng2-datetime-picker';
import {DAY_NAMES_EN, DAY_NAMES_ES, DAY_NAMES_FR} from './dayname-values.const';
import {MONTH_NAMES_EN, MONTH_NAMES_ES, MONTH_NAMES_FR} from './monthname-values.const';
import {ADDITIONAL_DATETIME_NAMES_EN, ADDITIONAL_DATETIME_NAMES_ES, ADDITIONAL_DATETIME_NAMES_FR} from './additional-datetime-names-values.const';

@Injectable()
export class DateTimePickerTranslationService{
  public setLocal(local:string) {

    // let month_names:{}[]=[];
    // let day_names:{}[]=[];
    let month_names = MONTH_NAMES_FR;
    let day_names = DAY_NAMES_FR;
    let additional_names = ADDITIONAL_DATETIME_NAMES_FR;

    switch (local) {
      case 'fr': {
        month_names = MONTH_NAMES_FR;
        day_names = DAY_NAMES_FR;
        additional_names = ADDITIONAL_DATETIME_NAMES_FR;
        break;
      }
      case 'en': {
        day_names = DAY_NAMES_EN;
        month_names = MONTH_NAMES_EN;
        additional_names = ADDITIONAL_DATETIME_NAMES_EN;
        break;
      }
      case 'es': {
        day_names = DAY_NAMES_ES;
        month_names = MONTH_NAMES_ES;
        additional_names = ADDITIONAL_DATETIME_NAMES_ES;
        break;
      }
    }
    Ng2Datetime.daysOfWeek = day_names;
    Ng2Datetime.months = month_names;
    Ng2Datetime.locale = additional_names;

  }
}
