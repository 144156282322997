import * as tslib_1 from "tslib";
import { Searchable } from '../../../shared/models/searchable.class';
import { CrudService } from '../../../shared/services/crud.service';
var TsmListService = /** @class */ (function (_super) {
    tslib_1.__extends(TsmListService, _super);
    function TsmListService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation/tsm_list';
        _this.ModelClass = Searchable;
        return _this;
    }
    return TsmListService;
}(CrudService));
export { TsmListService };
