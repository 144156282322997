<div class="import-progress-wrapper" *ngIf="importProgressService.showImportProgress">
    <div [ngSwitch]="importProgressService.statusImportProgress">
      <!--<md-progress-spinner *ngSwitchCase="MAP_IMPORT_PROGRESS_STATUS.CHECKING" class="import-progress-spinner" mode="indeterminate"></md-progress-spinner>
      <md-progress-spinner *ngSwitchCase="MAP_IMPORT_PROGRESS_STATUS.PROGRESS" class="import-progress-spinner" mode="indeterminate"></md-progress-spinner>
      <div *ngSwitchCase="MAP_IMPORT_PROGRESS_STATUS.ERROR">❌</div>
      <div *ngSwitchCase="MAP_IMPORT_PROGRESS_STATUS.SUCCESS">✅</div>
      <div *ngSwitchCase="MAP_IMPORT_PROGRESS_STATUS.WARNING">⚠️</div>-->
    </div>
    <div class="import-progress-text">{{getText()}}</div>
    <div class="import-progress-bar primary-color"
         [ngClass]="{'import-progress-bar--success': isSuccess,'import-progress-bar--error': isError}">
        <md-progress-bar mode="determinate" [value]="importProgressService.importPercentageValue"></md-progress-bar>
    </div>
    <i class="import-progress-toggle icon arrow-up--icon" (click)="maximize()"></i>
    <i class="import-progress-close icon close-grey--icon" *ngIf="importProgressService.canProgressModalBeClosed()" (click)="close()"></i>


</div>
