/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Need } from '../../models/need.class';
import { NeedViewService } from '../../services/need-view.service';
import { NeedControlStates } from '../../models/need-control-states.class';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { NEED_STATUSES, ENeedStatusesView } from '../../values/need-statuses.const';
import {TranslateService} from '@ngx-translate/core';
import { PASSPORT_STATUS } from '../../../passport/values/passport-status.const';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ECOSYSTEM_OFFER_STATUS } from '../../../../dashboards/ecosystem-manager/values/ecosystem-offer-status.const';
import { PassportValidationNotificationService } from '../../../passport/services/passport-validation-notification.service';
import { Passport, PassportFamily } from '../../../passport/models/passport.class';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { MdDialog } from '@angular/material';
import * as _ from 'lodash';
import {Deposit} from '../../../deposit/models/deposit.class';

/**
 * This class represents the lazy loaded NeedEditComponent.
 */
@Component({
  moduleId: module.id,
  selector: 'need-edit',
  templateUrl: 'need-edit.component.html',
  styleUrls: ['need-edit.component.scss']
})
export class NeedEditComponent extends EntityViewerComponent<Need> implements OnInit, OnDestroy, AfterViewInit {

  // TODO not used as an input
  @Input() controlStates = new NeedControlStates;

  public need: Need;
  public validated = false;
  public stepId = 0;
  public showVoidPassportCreationMessage = false;
  isIndependentControlDisabledByDefault = false;
  private needId: number;
  public savePassport = new EventEmitter();
  private passportSaveComplete = new EventEmitter();
  private initialPrivacyState: boolean;
  //private originIndependent: number;

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              private _passportValidationNotification: PassportValidationNotificationService,
              private _translate: TranslateService,
              private cd: ChangeDetectorRef,
              private notification: LogNotificationService,
              private navigationService: NavigationService,
              private zone: NgZone,
              public dialog: MdDialog,
              public loadingSpinner: LoadingSpinnerService,
              public viewService: NeedViewService
  ) {
    super(route, router);
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  onExistEntity(entity: Need): void {
    this.need = entity;
    this.need.loaded = true;
    //active step?
    this.initialPrivacyState = this.need.isPublic();
    this.stepId = this.need.getProgress();
    if (this.need.passport && new Date(this.need.updated_at) < new Date(this.need.passport.updated_at)) {
      this.notification.warn('Passport has been updated since last edit', 7000);
    }
    if ( this.need.from_deposit && !(this.need.passport || this.need.passport.id)) {
      this.showVoidPassportCreationMessage = true;
    }
    if (this.need.from_noah) {
      this.isIndependentControlDisabledByDefault = true;
    }
    const isPublished = this.isPublished();
    const isPublicityUnChangable = this.isFormDisabled();
    // TODO edit validation is not implemented on backend
    // Need can be saved as draft only it is not in process
   // this.controlStates.save = { disabled: this.need.getState() !== 1 };
    this.controlStates.publish = { disabled: this.need.getViewStatus() !== ENeedStatusesView.DRAFT };

    this.controlStates.save = { disabled: isPublished};

    // Editable during creation
    this.controlStates.quantity = { disabled:  isPublicityUnChangable };
    this.controlStates.quantity_precision = { disabled: isPublicityUnChangable };
    this.controlStates.delivery_date = { disabled: isPublicityUnChangable  };
    this.controlStates.date_precision = { disabled: isPublicityUnChangable };
    this.controlStates.client_location_id = { disabled: isPublished };

    // Passport can be changed only if need is not in process
    // this.controlStates.passport_id = { disabled: this.need.getState() !== 1 };

    // Name and client can be changed only if need is not published
    this.controlStates.name = {
      disabled: !(this.need.status === NEED_STATUSES.DRAFT.value
        || this.need.status === NEED_STATUSES.ASK_FOR_MODIFICATION.value
        || this.need.status === NEED_STATUSES.WAITING_FOR_PUBLICATION.value
        || !this.isNeedInArchive()

      )
    };
    this.controlStates.passport_id = { disabled: isPublished };

    // Name and client can be changed only if need is not published
    /* this.controlStates.name = {
      disabled: isDeliveryPlanned
    this*/
    this.controlStates.client_id = {
      disabled: isPublicityUnChangable
    };

    // Can edit during both creation and in process
    //  this.controlStates.description =  { disabled: this.need.getState() !== 1 };
    // this.controlStates.client_contact_id = { disabled: false };
    // this.controlStates.files = { disabled: this.need.getState() !== 1 };

    this.controlStates.description = { disabled: isPublicityUnChangable };
    this.controlStates.client_contact_id = { disabled: isPublicityUnChangable };
    this.controlStates.files = { disabled: isPublicityUnChangable };
  }

  isNeedInArchive(): boolean {
    // archived need are not editable at all
   return  this.need.hasUnEditableStatus();
  }
  onNewEntity(): void {
    this.need = new Need({});
    let passport: Passport = this.route.snapshot.data['passport'];
    const deposit: Deposit = this.route.snapshot.data['deposit'];
    let independent = this.route.snapshot.params['independent'];
    independent = !!independent ? independent.trim() !== 'false' : null;
    if (deposit) {
      passport = deposit.passport;
      this.need.from_deposit = deposit.id;
    }
    if (!!passport) {
        this.need.passport = passport;
    }
    if (independent !== null) {
      this.need.independent = independent;
      this.initialPrivacyState = independent;
      this.need.from_noah = independent;
      this.isIndependentControlDisabledByDefault = true;
    }
    this.need.loaded = true;
  }

  isPublished() {
    return this.need.status >= NEED_STATUSES.PUBLISHED.value;
  }

  isDeliveryPlanned(): boolean {
    return this.need.offer && this.need.offer.some(needOffer => (
      needOffer.status === ECOSYSTEM_OFFER_STATUS.VALIDATED
      || needOffer.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION
      || needOffer.status === ECOSYSTEM_OFFER_STATUS.PROBLEM_REPORTED)
    );
  }


  saveDraft(index?: number, isRedirect?: boolean) {
    if (this._passportValidationNotification.showChildNotificationErrors()) return;
    this.need.status = 1;
    this.zone.run(() => {
      if (this.savePassportIsNeeded()) {
        this.savePassport.emit({key: 'savePassport'});
        this.passportSaveComplete.subscribe(() => {
          this.save(index, isRedirect);
        });
        return;
      }
      this.save(index, isRedirect);
    });
  }

  save(index?: number, isRedirect?: boolean) {
    this.need.save().subscribe((res) => {
      if (isRedirect && res.id !== this.needId) {
        this.router.navigate(['sales/needs/', res.id, 'edit']);
      }
      if (index) {
        this.stepId = index;
      } else {
        this.router.navigate(['sales/needs']);
      }
    });
  }


  isNextDisabled(): boolean {
    return this.need.getProgress() <= this.stepId;
  }

  isPrevDisabled(): boolean {
    return this.stepId <= 0;
  }

  changeTab(index: number): void {
    if (index === this.stepId) {
      return;
    }
    if (this._passportValidationNotification.showChildNotificationErrors()) return;
    const requiredFieldsError: string = this.getMiniumRequiredDataFirstError(index);
    if (requiredFieldsError) {
      this.validated = true;
      this.notification.error(requiredFieldsError, null, true);
      return;
    }
    if (index > 0 && !this.need.id && (!this.need.passport || index > 1)) {
      this.saveDraft(index, true);
      return;
    }
    this.zone.run(() => {
      this.stepId = index;
    });
  }

  nextStep(): void {
    if (!this.isNextDisabled()) {
      this.stepId++;
    }
  }

  prevStep(): void {
    if (!this.isPrevDisabled()) {
      this.stepId--;
    }
  }


  isConfirmWindowRequired(){
    if(this.need.isPublic()){
      if(this.initialPrivacyState === false){
        return true;
      } if (!this.need.from_noah && this.need.getViewStatus() === ENeedStatusesView.CREATED){
        return true;
      }
    } else {
      return false;
    }
  }

  confirmSubmit(): void {
    if(this.isConfirmWindowRequired()){
      this._translate.get('CONFIRM_PUBLIC_NEED')
      .flatMap((translation) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            confirmText: 'Ok, submit',
            text: translation,
            cssClass: "need-edit-public-dialog__confirmation"
          }
        });

        return dialogRef.afterClosed();
      })
      .subscribe((submittedPublically) => {
        if (submittedPublically) {
        this.submit();
        } else return;
      });
    } else {
      this.submit();
    }
  }

  submit(): void {
    if (this._passportValidationNotification.showChildNotificationErrors()) return;
    const requiredFieldsError: string = this.getMiniumRequiredDataFirstError();
    if (requiredFieldsError) {
      this.validated = true;
      this.notification.error(requiredFieldsError, null, true);
      return;
    }

    if (this.savePassportIsNeeded()) {
      this.savePassport.emit({key: 'savePassport'});
      this.passportSaveComplete.subscribe(() => {
        this.publish();
      });
      return;
    }

    this.publish();
  }

  private getMiniumRequiredDataFirstError(stepIndex: number = 3) {
    const progress = this.need.getProgress();
    if (progress < stepIndex) {
      const error = this.need.getValidationErrors().shift();
      if (error.progressStep < 3) {
        this.stepId = error.progressStep;
      }
      return error ? error.text : 'Unknown error';
    }
  }

  savePassportIsNeeded(): boolean {
    return this.need.passport && !!this.need.passport.id
      && !(this.need.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor());
  }

  publish() {
    this.loadingSpinner.show('need');
    this.need.publish().subscribe((res) => {
      console.log('Submission Result:', res);
      this.need = new Need(res);
      this.viewService.setNeed(this.need);
      this.router.navigate(['/sales']);
    }, () => this.loadingSpinner.destroy(), () => this.loadingSpinner.destroy());
  }

  askForModification() {
    this.need.askForModification().subscribe((res) => {
      this.router.navigate(['/sales']);
    });
  }

  reject() {
    this.need.reject().subscribe((res) => {
      this.router.navigate(['/sales']);
    });
  }

  onPassportSaveComplete($event) {
    this.passportSaveComplete.emit();
  }

  isFormDisabled() {
    return this.isNeedInArchive() || this.isDeliveryPlanned();
  }

  isPublicityUnChangable(): boolean {
     return this.isFormDisabled() || this.isIndependentControlDisabledByDefault;

  }
}
