import { Component, ElementRef, ViewChild } from '@angular/core';
import { MdDialog } from '@angular/material';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';
import { UserService } from '../../../../shared/services/user.service';
import { TestDataGenerationService } from '../../test-data-generation.service';
import { SuperadminRawReportDialog } from './superadmin-raw-report-download/superadmin-raw-report-download-dialog.component';
@Component({
  moduleId: module.id,
  templateUrl: 'superadmin-dashboard.component.html',
  styleUrls: ['./superadmin-dashboard.component.scss']
})
export class SuperadminDashboardComponent {
  activeIndex = 0;
  ecosystemsInProcessCount: number;

  nomenclatureChangeRequests: number;
  companies: number;
  usersRegistered: number;

  @ViewChild('generateData') private generateDataInput: ElementRef;

  constructor(private mdDialog: MdDialog,
              // private ecosystemService: EcosystemService,
              private _userService: UserService) {
    this._userService.getRoleCounters({ role: ERoleIds.SUPERADMIN }).subscribe(
      (res: any) => {
        this.nomenclatureChangeRequests = Number(res.nomenclature_request);
        this.companies = Number(res.companies);
        this.usersRegistered = Number(res.users_registered);
      });
    // this.ecosystemService.getProcessCount().subscribe(c => this.ecosystemsInProcessCount = c);
  }

  openRawReportDownloader() {
    this.mdDialog.open(SuperadminRawReportDialog)
  }
}

