/**
 * Created by natalja on 29/06/2017.
 */

import {Component, EventEmitter, OnDestroy} from '@angular/core';
import {
  ECustomListHintClass,
  ECustomListRecordStatus,
  ECustomListUnit,
  ICustomListKeyPair,
  ICustomListRecord
} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { DEPOSIT_STATUSES } from '../../../../entities/deposit/values/deposit-statuses.const';
import { DepositStatus } from '../../../../entities/deposit/models/deposit-status.class';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
import { Deposit } from '../../../../entities/deposit/models/deposit.class';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { Searchable } from '../../../../shared/models/searchable.class';
import { FormControl } from '@angular/forms';
import { DepositDetailsDialogComponent
    } from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { MdDialog } from '@angular/material';
import { LazyLoadedList } from '../../../../shared/models/lazy-loaded-list.class';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { PASSPORT_STATUS } from '../../../../entities/passport/values/passport-status.const';
import * as moment from 'moment';
import {getSearchStatus} from '../../../../shared/helpers/search-status-update';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import {PaginationUnmarshallingService} from '../../../../shared/services/pagination-unmarshalling.service';
import {filter, tap, map} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {Subscriber} from 'rxjs';
import {Subject} from 'rxjs/Subject';

@Component({
  moduleId: module.id,
  selector: 'supervisor-deposits-catalog',
  templateUrl: 'supervisor-deposits-catalog.component.html'
})
export class SupervisorDepositsCatalogComponent extends LazyLoadedList<Deposit> implements OnDestroy {

  onChange$: EventEmitter<void> = new EventEmitter<void>();
  onScroll$: EventEmitter<void> = new EventEmitter<void>();

  records: ICustomListRecord[] = [];

  filterStatuses: DepositStatus[] = [
    DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION,
    DEPOSIT_STATUSES.PUBLISHED,
    DEPOSIT_STATUSES.ASK_FOR_MODIFICATION
  ];

  sortOptions: any = [
    { field: '-created_at', view: 'Creation date'},
    { field: 'owner_name', view: 'Deposit owner'},
    { field: 'name', view: 'Name'},
  ];
  sortValue = '-created_at';

  // Filters
  owner: number;
  search = new FormControl();
  searchStatus: number[] = this.filterStatuses.map((status) => status.value);
  ownerList: Searchable[] = [];

  PASSPORT_STATUS = PASSPORT_STATUS;

  constructor(private _depositService: DepositService,
              private _globalEvents: GlobalEventsManager,
              private dialog: MdDialog,
              private navBarState: NavbarStateService,
              public paginationUnmarshallingService: PaginationUnmarshallingService
              ) {
    super(paginationUnmarshallingService);

    this.onScroll$
      // .debounceTime(400)
      .pipe(filter(() => this.canLoadNextPage()))
      .switchMap(() => this.fetchRecords(this.currentPage + 1))
      .subscribe((deposits: Deposit[]) => this.onRecordsLoaded(deposits, false));

    this
      .onChange$
      // .debounceTime(400)
      .do(() => this.observerOnCollapse.subscribe())
      .switchMap(() => this.fetchRecords())
      .do(() => this.isExpanded ? this.observerOnToggle.subscribe() : null)
      .subscribe((deposits: Deposit[]) => this.onRecordsLoaded(deposits));

    this
      .search
      .valueChanges
      // .debounceTime(300)
      .distinctUntilChanged()
      .subscribe(() => this.resetRecords())
    ;
    this.resetRecords();
  }

  ngOnDestroy() {
    this.observerOnCollapse.subscribe();
  }

  resetRecords() {
    // this.records.length = 0;
    // this.resetPages();
    // this.loadRecords();
    this.onChange$.emit();
  }

  public loadDataRecursive() {
    if (this.currentPage < this.pageCount) {
      return this.fetchRecords(this.currentPage + 1).pipe(map((deposits) => {
        return deposits;
      }));
    }
    return Observable.of([]);
  }

  afterLoadRecursive(data) {
    this.onRecordsLoaded(data, false);
  }

  private canLoadNextPage() {
    return !this.isLoadingPage && this.currentPage < this.pageCount;
  }

  fetchRecords(page = 1) {
    const options: any = {
      expand: 'passport,owner_id,owner_name',
      supervisor: 1,
      supervisor_view: 1 ,
      search: this.search.value || null,
      // creator_id: this.agent,
      owner: this.owner,
      'status[]': this.searchStatus,
      sort: this.sortValue,
      page: page
    };

    if (this.navBarState.multilanguage) {
      options.english = true;
    }
    return this.loadWithPagination(this._depositService, options);
  }

  onRecordsLoaded(deposits: Deposit[], reset = true) {
    if (reset) {
      this.records.length = 0;
      this.resetPages();
    }
    deposits.forEach((deposit) => this.addRecord(deposit));
  }

  addRecord(data: Deposit) {
    // -blue hand for waiting for publication
    // -green check for published
    // -purple ! for ask for modification
    let status: ECustomListRecordStatus;
    switch (data.status) {
      case DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION.value:
        status = ECustomListRecordStatus.MANUAL;
        break;
      case DEPOSIT_STATUSES.PUBLISHED.value:
        status = ECustomListRecordStatus.CONFIRM;
        break;
      case DEPOSIT_STATUSES.ASK_FOR_MODIFICATION.value:
        status = ECustomListRecordStatus.IMPORTANT;
        break;
      default:
        status = ECustomListRecordStatus.NO_STATUS;
    }

    let hintCssClass = ECustomListHintClass.NO_CLASS;
    let hintText = '';
    // TODO figure out if this should be used as subtitle
    let units = ECustomListUnit.PCS;
    if (data.passport) {
      if (data.passport.status === PASSPORT_STATUS.DRAFT) {
        hintCssClass = ECustomListHintClass.INFO;
        hintText = 'New circular passport';
      }
      units = data.passport.customListUnit;
    }

    if (!this.ownerList.find((owner: any) => owner.id === data.owner_id)) {
      this.ownerList.push(new Searchable({id: data.owner_id, name: data.owner_name}));
    }

    // let createdDate = data.created_at;
    // if (data.created_at && data.created_at.length === 19) {
    //   createdDate = data.created_at.slice(0, 10);
    // }
    const createdDate = moment(data.created_at).format('DD.MM.YYYY');
    const expirationDate = data.availability_expiration ? moment(data.availability_expiration)
    .format('DD/MM/YYYY') : null;
    const availabilityDate = moment(data.availability_date || data.created_at).format('DD/MM/YYYY');

    const availableTo = data.availability_date ? data.availability_date : data.created_at;
    let isAvailableRightNow = false;
    if (!!data.availability_expiration) {
      isAvailableRightNow = moment().isBetween(availableTo, data.availability_expiration, 'day', '[)');
    } else {
      isAvailableRightNow = moment().isAfter(availableTo, 'day');
    }

    this.records.push(
      this.createDepositRecord(
        status,
        hintCssClass,
        hintText,
        data.name,
        data.passport.name || '',
        data.quantity,
        units,
        data.conditionData ? data.conditionData.view.replace(/./, c => c.toUpperCase()) : '',
        createdDate,
        availabilityDate,
        expirationDate,
        isAvailableRightNow,
        data.owner_name,
        data,
      )
    );
  }

  createDepositRecord(status: ECustomListRecordStatus,
                      hintCssClass: ECustomListHintClass,
                      hintText: string,
                      title: string,
                      subtitle: string,
                      amount: number,
                      unit: ECustomListUnit,
                      unitStatus: string,
                      createdAt: string,
                      availableAt: string,
                      expiredAt: string,
                      isAvailableAtTheMoment: boolean,
                      author: string,
                      deposit: Deposit): CustomListRecord {

    const listColumn: ICustomListKeyPair[] = [{key: 'Created', value: createdAt}];
    listColumn.push({key: 'Availability', value: availableAt + (expiredAt ? ' - ' + expiredAt : '')
      , cssClass: isAvailableAtTheMoment ? 'important-value' : null});
    const showValidationButtons = deposit.status === DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION.value;
    return CustomListRecord
      .build()
      .setPrimaryKey(deposit.id)
      .setStatus(status)
      .setHint(hintText, hintCssClass)
      .addTitleColumn(title, subtitle, null, deposit.passport.english_name)
      .addUnitColumn(amount, unit, unitStatus)
      .addListColumn(listColumn)
      .addAuthorColumn(author)
      .addControlsColumn([{
        fn: (data: ICustomListRecord) => {
          this.dialog.open(DepositDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
            data: {
              readOnly: true,
              id: deposit.id,
              showValidationButtons: showValidationButtons,
            },
          }).afterClosed().subscribe(() => this.resetRecords());
        },
        name: 'See details'
      }, {
        fn: (data: ICustomListRecord) => this.openChat(deposit.owner_id),
        name: 'Chat with deposit owner'
      }, {
        fn: (data: ICustomListRecord) => this.openChat(deposit.creator_id),
        name: 'Chat with agent'
      },
        ...(showValidationButtons ? [
          {
            name: 'Validate',
            fn: () => {
              deposit.publish().subscribe(() => this.resetRecords());
            }
          },
          {
            name: 'Reject',
            fn: () => {
              deposit.reject().subscribe(() => this.resetRecords());
            }
          }
        ] : [])
      ])
      ;
  }

  toggleState(state: number) {
    this.searchStatus = getSearchStatus(state, this.searchStatus);
    this.resetRecords();
  }

  getStatusLabel(state: number) {
    const key = Object.keys(DEPOSIT_STATUSES).find(( key) => (DEPOSIT_STATUSES as any)[key].value === state);
    return key || 'N/A';
  }

  openChat(targetId: number) {
    console.log('openChat', targetId);
    this._globalEvents.chatUserClicked$.emit(targetId);
  }
}
