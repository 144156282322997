/**
 * Created by Aleksandr C. on 9.02.17.
 */
import { Injectable } from '@angular/core';
import { Deposit } from '../models/deposit.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { Subject } from 'rxjs/Subject';
import { ILiteral } from 'ng2-adn-common';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';

@Injectable()
export class DepositService extends ExtendedCrudService {
  protected namespace = 'deposits';
  protected namespaceSingular = 'deposit';
  protected ModelClass = Deposit;

  getFileUploadUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/photos');
  }

  getDocUploadUrl(id: number): string {
    return  this.getEndpoint(this.singularEndpoint + '/' + id + '/files');
  }

  getLogoUploadUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + "/" + id + "/logo");
  }

  getPdfReportUrl(query: ILiteral) {
    const q = this.formQueryString(query);
    return this.getEndpoint(`${this.namespaceSingular}/report${q ? '?' + q : ''}`);
  }
  getXlsReportUrl(query: ILiteral) {
    const q = this.formQueryString(query);
    return this.getEndpoint(`${this.namespaceSingular}/report_xls${q ? '?' + q : ''}`);
  }

  getPdfReport(query: ILiteral) {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/report'), {search: query})
      .catch(this.onError.bind(this));
  }

  getAvailableCount(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getWaiting(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/waiting'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getInEcosystem(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/in_ecosystem'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getByRelevancy(needId : number, query: any = {}): Observable<any> {
    query.need_id = needId;

    return this.sendPost(this.getEndpoint(this.namespaceSingular + '/relevant'), query)
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  deleteFiltereds(query: any = {}): Observable<any> {
    return this.sendPost(this.getEndpoint(this.namespaceSingular + '/deleteall'), query)
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  removeLogo(id:number) {
    return this.sendDelete(this.getEndpoint(`${this.namespaceSingular}/${id}/logo`))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  deleteAllDeposits() {
    return this.sendPost(this.getEndpoint(`${this.namespaceSingular}/delete_all_by_agent`))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  massUpdate(data, query) {
    return this.sendPostWithArray(this.getEndpoint(`${this.namespaceSingular}/mass_update`), data, query)
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  quickUpdate(data) {
    return this.sendPost(this.getEndpoint(`${this.namespaceSingular}/quick_update`), data)
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }
}
