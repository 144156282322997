
  <hr [ngClass]="'ecosystem-indicators__separator ecosystem-indicators__separator--' + cssStyleClass">

  <header class="ecosystem-indicators__toggle-header"
    (click)="toggleDetails()">
    <h4 class="ecosystem-indicators__toggle-title" [innerHTML]="subTitle">
    </h4>
    <button class="button-transparent ecosystem-indicators__toggle-button">
      <i class="icon" [ngClass]="isDetailsOpen ? 'icon--fold-tab' : 'icon--unfold-tab' "></i>
    </button>
  </header>

  <div *ngIf="isDetailsOpen" class="ecosystem-indicators__card-content">

    <div class="ecosystem-indicators__details-section" *ngIf="virginProductsSteps.length > 0">
      {{'Virgin Product(s)' | translate}}
    </div>
    <div [ngClass]="'ecosystem-indicators__input-container ecosystem-indicators__label--' + cssStyleClass"
      *ngFor="let step of virginProductsSteps">
      <md-input-container [ngClass]="{'form-group__field ecosystem-indicators__number-input ecosystem-indicators__details': true,
        'ecosystem-indicators__missing-data-error': isDataMissing(step.summary_details.step) }"
        floatPlaceholder="always">
        <input autocomplete="off" mdInput placeholder="{{step.title}}"
          value="{{  getFormattedValue(step.summary_details.step) }}" disabled="true">
      </md-input-container>
      <div class="ecosystem-indicators__unit">
        {{stepUnit}}
      </div>

      <div class="ecosystem-indicators__card-item-warning" *ngIf="isDataMissing(step.summary_details.step) ">
        <div class="ecosystem-indicators__warning-message">
          <div class="ecosystem-indicators__exclamation">!</div>
        </div>
      </div>

    </div>

    <div class="ecosystem-indicators__details-section" *ngIf="depositSteps.length > 0">
      {{'Deposit(s)' | translate}}
    </div>
    <div [ngClass]="'ecosystem-indicators__input-container ecosystem-indicators__label--' + cssStyleClass"
      *ngFor="let step of depositSteps">
      <md-input-container [ngClass]="{'form-group__field ecosystem-indicators__number-input ecosystem-indicators__details': true,
        'ecosystem-indicators__missing-data-error':   isDataMissing(step.summary_details.step)  }"
        floatPlaceholder="always">
        <input autocomplete="off" mdInput placeholder="{{step.title}}" disabled="true"
          value="{{ getFormattedValue(step.summary_details.step)}}">
      </md-input-container>
      <div class="ecosystem-indicators__unit">
        {{stepUnit}}
      </div>

      <div class="ecosystem-indicators__card-item-warning" *ngIf="isDataMissing(step.summary_details.step)">
        <div class="ecosystem-indicators__warning-message">
          <div class="ecosystem-indicators__exclamation">!</div>
        </div>
      </div>

    </div>

    <div class="ecosystem-indicators__details-section" *ngIf="transformSteps.length > 0">
      {{'Transformation(s)' | translate}}
    </div>
    <ng-container *ngFor="let step of transformSteps">
      <div [ngClass]="'ecosystem-indicators__input-container ecosystem-indicators__label--' + cssStyleClass"
        *ngFor="let order of getOrders(step)">
        <md-input-container [ngClass]="{'form-group__field ecosystem-indicators__number-input ecosystem-indicators__details': true,
            'ecosystem-indicators__missing-data-error': isDataMissing(order) }" floatPlaceholder="always">
          <input autocomplete="off" mdInput placeholder="{{step.title}}"  disabled="true"
            value="{{ getFormattedValue(order) }}">
        </md-input-container>
        <div class="ecosystem-indicators__unit">

          {{stepUnit}}
        </div>

        <div class="ecosystem-indicators__card-item-warning" *ngIf="isDataMissing(order)">

          <div class="ecosystem-indicators__warning-message">
            <div class="ecosystem-indicators__exclamation">!</div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="ecosystem-indicators__details-section" *ngIf="transportSteps.length > 0">
      {{'Transport' | translate}}
    </div>
    <ng-container *ngFor="let step of transportSteps">
      <div [ngClass]="'ecosystem-indicators__input-container ecosystem-indicators__label--' + cssStyleClass"
        *ngFor="let order of getOrders(step)">
        <md-input-container [ngClass]="{'form-group__field ecosystem-indicators__number-input ecosystem-indicators__details': true,
          'ecosystem-indicators__missing-data-error': isDataMissing(order) }" floatPlaceholder="always">
          <input autocomplete="off" mdInput placeholder="{{step.title}}" disabled="true"
            value="{{ getFormattedValue(order)}}">
        </md-input-container>
        <div class="ecosystem-indicators__unit">

          {{stepUnit}}
        </div>

        <div class="ecosystem-indicators__card-item-warning" *ngIf="isDataMissing(order)">
          <div class="ecosystem-indicators__warning-message">
            <div class="ecosystem-indicators__exclamation">!</div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="ecosystem-indicators__details-section" *ngIf="needSteps.length > 0">
      {{'Need' | translate}}
    </div>
    <div [ngClass]="'ecosystem-indicators__input-container ecosystem-indicators__label--' + cssStyleClass" *ngFor="let step of needSteps">
      <md-input-container [ngClass]="{'form-group__field ecosystem-indicators__number-input ecosystem-indicators__details': true,
        'ecosystem-indicators__missing-data-error': isDataMissing(step.summary_details.step)  }"
        floatPlaceholder="always">
        <input autocomplete="off" mdInput placeholder="{{step.title}}" disabled="true"
          value="{{ getFormattedValue(step.summary_details.step) }}">
      </md-input-container>
      <div class="ecosystem-indicators__unit">
        {{stepUnit}}
      </div>

      <div class="ecosystem-indicators__card-item-warning" *ngIf="isDataMissing(step.summary_details.step)">
        <div class="ecosystem-indicators__warning-message">
          <div class="ecosystem-indicators__exclamation">!</div>
        </div>
      </div>

      <div class="ecosystem-indicators__card-item-warning" *ngIf="hasWarnings(step.summary_details.step)">
        <div class="ecosystem-indicators__information-message">
          <div class="ecosystem-indicators__information-sign">i</div>
          <div class="ecosystem-indicators__information-wrapper">
            <ul>
              <li *ngFor="let warning of getWarnings(step.summary_details.step)">{{ warning | translate }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
