import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { TransformationModule } from '../../entities/transformation/transformation.module';
import { RouterModule } from '@angular/router';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';
import {TransformationManagerIndependentGuard} from './transformation-manager-independent.guard';
import {TSMI_ROUTES} from './transformation-manager-independent.routes';
import {TRANSFORM_INDEPENDENT_CONTEXT} from './transformation-manager-independent.context';
import {TsmOrderDetailsDialogComponent} from '../transformation-manager/dialogs/tsm-order-details-dialog/tsm-order-details-dialog.component';
import {TransformDetailsDialogComponent} from '../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import {TransformSiteDetailsDialogComponent} from '../transformation-manager/dialogs/transform-site-details-dialog/transform-site-details-dialog.component';
import {CreateTransformSiteDialogComponent} from '../transformation-manager/dialogs/create-transform-site-dialog/create-transform-site-dialog.component';
import {TransformationManagerModule} from '../transformation-manager/transformation-manager.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild([{
      path: TRANSFORM_INDEPENDENT_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: TSMI_ROUTES
    }]),
    TransformationModule,
    TransformationManagerModule
  ],
  declarations: [],
  exports: [],
  providers: [TransformationManagerIndependentGuard],
  entryComponents: [
    TsmOrderDetailsDialogComponent,
    TransformDetailsDialogComponent,
    TransformSiteDetailsDialogComponent,
    CreateTransformSiteDialogComponent,
  ],
})
export class TransformationManagerIndependentModule {
}
