export const PASSPORT_CERTIFICATION_LEVELS: {key: number; value: string}[] = [
  {key: 1, value: 'Basic'},
  {key: 2, value: 'Bronze'},
  {key: 3, value: 'Silver'},
  {key: 4, value: 'Gold'},
  {key: 5, value: 'Platinum'},
];

export const PASSPORT_CERTIFICATION_VALUE = {
  BASIC: 1,
  BRONZE: 2,
  SILVER: 3,
  GOLD: 4,
  PLATINUM: 5
};

export enum EPassportCertificationVersion {
  VERSION_3 = 1,
  VERSION_4 = 2
}

export const PASSPORT_CERTIFICATION_VERSION_VALUE: {key: number; value: string}[] = [{
  key: EPassportCertificationVersion.VERSION_3,
  value: 'Version 3.1'
}, {
  key: EPassportCertificationVersion.VERSION_4,
  value: 'Version 4.0'
}];
