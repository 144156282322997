<article class="need-catalog">
  <div>
    <button md-raised-button (click)="newNeed()">+ {{ 'New need' | translate }}</button>
  </div>
  <div>
    <section class="needManager-dashboard__section needManager-dashboard__section--counters">
      <ul class="needManager-dashboard__counter-list">
        <li class="needManager-dashboard__counter-item">
          <div class="needManager-dashboard__counter-value theme-color--primary">{{pendingModificationsCount}}</div>
          <span class="needManager-dashboard__counter-title">{{'Need_pending_modifications' | translate}}</span>
        </li>
        <li class="needManager-dashboard__counter-item">
          <div class="needManager-dashboard__counter-value theme-color--primary ">{{pendingValidationCount}}</div>
          <span class="needManager-dashboard__counter-title">{{'offer_pending_validation' | translate}}</span>
        </li>
        <li class="needManager-dashboard__counter-item">
          <div class="needManager-dashboard__counter-value theme-color--primary">{{pendingConfirmationCount}}</div>
          <span class="needManager-dashboard__counter-title">{{'offer_pending_confirmation' | translate}}</span>
        </li>
      </ul>
    </section>
  </div>
  <header class="need-catalog__header">
    <ul class="need-catalog__caption-list">
      <li class="need-catalog__caption-item" (click)="changeTab(0)" [ngClass]="{'active': activeIndex === 0}">
        <span class="need-catalog__caption-title">{{ 'Active_needs' | translate }}</span>
      </li>
      <li class="need-catalog__caption-item" (click)="changeTab(1)" [ngClass]="{'active': activeIndex === 1}">
        <span class="need-catalog__caption-title">{{ 'Archived_needs' | translate }}</span>
      </li>
    </ul>
  </header>
  <section class="need-catalog__content">
    <ul class="need-catalog__content-list">
      <li class="need-catalog__content-item">
        <need-list 
          [activeIndex]="activeIndex"
          (onUserAction)="updateCounters()"
        >
        </need-list>
      </li>           
    </ul>
  </section>
</article>