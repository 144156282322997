<div class="esm-need-matcher__container">
<!--  <header class="esm-need-matcher__header background-primary-color">-->
<!--    {{'Deposits meeting the declared needs' | translate}}-->
<!--    &lt;!&ndash; <i class="esm-need-matcher__header__icon-close icon icon&#45;&#45;close_s" ></i> &ndash;&gt;-->
<!--  </header> -->
  <!--<div class="esm-need-matcher__sub-header">{{"Needs" | translate | uppercase}}</div>-->
  <section class="esm-need-matcher__filter">
    <div class="esm-need-matcher__filter-match-type">
      <mat-slide-toggle class="esm-need-matcher__filter-match-type-toggle"
                        [(ngModel)]="needFilter.from_deposit"
                        (ngModelChange)="loadRecords()"
      ></mat-slide-toggle>
      <span class="esm-need-matcher__filter-match-type-text primary-color">
        {{'Display only deposit match' | translate}}
      </span>
      <common-info-sign
        [md-tooltip]="'SHOW_ONLY_TARGETED_MATCH_TOOLTIP' | translate"
        class="esm-need-matcher__filter-match-type-info"
        borderSizeClassModifier="1-half">?
      </common-info-sign>
    </div>
    <md-input-container
      class="md-input--datepicker md-input--datepicker--with-offset md-input--datepicker--with-offset-sm
      esm-need-matcher__filter-start-date">
      <input mdInput
             ng2-datetime-picker
             autocomplete="off"
             date-only="true"
             date-format="DD-MM-YYYY"
             parse-format="YYYY-MM-DD"
             [(ngModel)]="needFilter.delivery_from"
             (ngModelChange)="loadRecords()"
             placeholder="{{'Available FROM' | translate}}">
      <i class="icon icon--calendar md-input__icon md-input__icon--grey"></i>
    </md-input-container>
    <i class="material-icons esm-need-matcher__filter-separator">remove</i>
    <md-input-container
      class="md-input--datepicker md-input--datepicker--with-offset md-input--datepicker--with-offset-sm
       esm-need-matcher__filter-start-date">
      <input mdInput
             autocomplete="off"
             ng2-datetime-picker
             date-only="true"
             date-format="DD-MM-YYYY"
             parse-format="YYYY-MM-DD"
             [(ngModel)]="needFilter.delivery_to"
             (ngModelChange)="loadRecords()"
             placeholder="{{'Available TO' | translate}}">
      <i class="icon icon--calendar md-input__icon md-input__icon--grey"></i>
    </md-input-container>
  </section>
  <div class="esm-need-matcher__needs-absent" *ngIf="needs.length === 0">{{'No match found' | translate}}</div>
  <div *ngFor="let need of needs | paginate:
    {id:'need_match_pagination'
          , itemsPerPage: needFilter.perPage
          , currentPage: needFilter.currentPage
          , totalItems: needFilter.totalCount}"
       class="esm-need-matcher__match">
    <ul class="esm-need-matcher__need">
      <li class="esm-need-matcher__need-info flex">
        <div class="esm-need-matcher__need-icon">
          <confirm-dialog-icon
            [iconType]="CUSTOM_ICON.IMG"
            [primaryFill]="false"
            [showIconURL]="need.isPublic() ? 'assets/png/cloud_grey.png' : 'assets/png/ecosystem/need.png'"
          >
          </confirm-dialog-icon>
        </div>
        <div class="esm-need-matcher__need-info-name">
          <div class="esm-need-matcher__need-info-name--normal">{{need.name}}</div>
          <div class="esm-need-matcher__need-info-name--sub">{{need.passport?.name}}</div>
        </div>
      </li>
      <li class="esm-need-matcher__need-info">
        <div class="esm-need-matcher__need-info-delivery">
          <div class="esm-need-matcher__need-info-name esm-need-matcher__need-info-name--sub
          esm-need-matcher__need-info-name--sub-bold">
            {{need.quantity ? need.quantity : 'unknown' | translate | capitalize}}
            {{need.passport?.unit_text | translate}}
          </div>
          <div class="esm-need-matcher__need-info-name esm-need-matcher__need-info-name--line-height-fit">
            <span class="esm-need-matcher__need-info-name--sub">{{'for' | translate}}</span>{{' '}}
            <ng-container *ngTemplateOutlet="need.delivery_date ? dateSpecify : notSpecify"></ng-container>


            <ng-template #dateSpecify>
              <span class="esm-need-matcher__need-info-name--sub esm-need-matcher__need-info-name--sub-bold">
                {{need.delivery_date | localizedDate: 'dd-MM-y'}}
              </span>
              <span class="esm-need-matcher__need-info-name--sub">
                {{need.date_precision ? '(' + need.date_precision_text + ')' : '' | translate}}
              </span>
            </ng-template>
            <ng-template #notSpecify>
              <span class="esm-need-matcher__need-info-name--sub">
                {{(need.date_precision === NEED_DATE_PRECISION.RECURRENT ? 'recurrent' : 'unknown') | translate | capitalize}}
              </span>
            </ng-template>
          </div>
        </div>
      </li>
      <li class="esm-need-matcher__need-info">
        <div class="esm-need-matcher__need-info-logo">
          <div class="esm-need-matcher__need-info-name">
            <div class="esm-need-matcher__need-info-name--normal">{{need.client.name}}</div>
            <div class="esm-need-matcher__need-info-name--sub">{{need.client?.address}}</div>
          </div>
          <img
            class="esm-need-matcher__need-info-logo-img"
            [src]="need.client.image"
            [hidden]="!need.client.image"
            onerror="this.hidden=true"
            [alt]="need.client.name">
        </div>
      </li>
      <li class="esm-need-matcher__need-info">
        <button class="esm-need-matcher__need-more-info-button"
                (click)="openNeedDetailsDialog(need.id)"
                aria-label="See details"
        >
          <confirm-dialog-icon
            [iconType]="CUSTOM_ICON.IMG"
            [primaryFill]="false"
            [showIconURL]="'assets/png/ecosystem/view.png'"
          >
          </confirm-dialog-icon>
        </button>
      </li>
    </ul>
    <div class="esm-need-matcher__deposit-tab">
      <div class="esm-need-matcher__deposit-tab-deposit-summary">
        <span *ngIf="need.match_deposits.type === NEED_MATCH_DEPOSITS_TYPE_PASSPORT">
          {{need.match_deposits.data.length ? need.match_deposits.data.length : 0 + ''}}
          {{'deposits might fit the need'| translate}}
        </span>
        <span *ngIf="need.match_deposits.type === NEED_MATCH_DEPOSITS_TYPE_FROM_DEPOSIT">
          <i class="icon icon--match esm-need-matcher__deposit-tab-deposit-summary-icon"></i>
          <span class="esm-need-matcher__deposit-tab-deposit-summary-text">{{'Targeted need'| translate}}</span>
        </span>
      </div>
      <div class="esm-need-matcher__deposit-tab-icon">
        <button class="esm-need-matcher__need-more-info-button"
                (click)="needTabIsOpen[need.id] = !needTabIsOpen[need.id]"
                aria-label="toggle">
          <confirm-dialog-icon
            [iconType]="CUSTOM_ICON.USUAL"
            [primaryFill]="false"
            [iconClass]="'icon arrow-' + (needTabIsOpen[need.id] ? 'down' : 'up') + '--icon'"
          >
          </confirm-dialog-icon>
        </button>
      </div>
    </div>
    <div *ngIf="needTabIsOpen[need.id]" class="esm-need-matcher__deposit-list">
      <deposit-item *ngFor="let deposit of need.match_deposits.data"
                    (onCreateEcosystem)="createEcosystemFromNeed(need, deposit)"
                    (onNotifyReseller)="sendConsultDepositLinkNotificationToDO(deposit, need)"
                    [deposit]="deposit" [itemView]="DEPOSIT_ITEM_TYPE.ESM_NEED_MATCH_ITEM">

      </deposit-item>
    </div>
  </div>
  <pagination-controls class="passportlist__pagination esm-need-matcher__paginate"
                       id="need_match_pagination" (pageChange)="this.loadRecords($event)"
                       maxSize="8"
                       directionLinks="true"
                       autoHide="true"
                       previousLabel="arrow_back_ios"
                       nextLabel="arrow_forward_ios"
                       screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
                       screenReaderPageLabel="{{ 'page' | translate }}"
                       screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"
  ></pagination-controls>
</div>
