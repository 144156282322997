import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DashboardComponentBase } from '../../../app/components/base/dashboard/dashboard-component-base.directive';
import { PassportCollectionComponent } from '../../../app/components/passport/passport-Collection/passport-collection.component';
import { ManufacturerService } from '../../../entities/passport/services/manufacturer.service';
import { Router } from '@angular/router';
import { MdDialog } from '@angular/material';
import { Passport } from '../../../entities/passport/models/passport.class';
import { ManufacturerGenericComponent } from '../../../entities/passport/dialogs/manufacturer-generic-dialog/manufacturer-generic-dialog.component';
import { PassportRatingExplanationDialogComponent } from '../../../entities/passport/dialogs/passport-rating-explanation-dialog/passport-rating-explanation-dialog';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { CopyPassportDialogComponent } from '../../../entities/passport/dialogs/copy-passport-dialog/copy-passport-dialog.component';
import { GenericDeleteWarningComponent } from '../../../app/components/base/dialog/delete-warning/generic-delete-warning.component';
import { NavbarStateService } from '../../../shared/services/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_THEME } from '../../../shared/helpers/dialog-themes.const';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { PASSPORT_STATUS_CONST_MANUFACTURER, PASSPORT_STATUS_CONST_MANUFACTURER_TOBETREATED } from '../../../entities/passport/values/passport-status.const';
import { NomenclatureChangeDialogComponent } from '../../../entities/nomenclature/dialogs/nomenclature-change-dialog/nomenclature-change-dialog.component';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
var DashboardTabs;
(function (DashboardTabs) {
    DashboardTabs["Published"] = "published";
    DashboardTabs["toBeTreated"] = "toBeTreated";
})(DashboardTabs || (DashboardTabs = {}));
var ManufacturerDashboardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ManufacturerDashboardComponent, _super);
    function ManufacturerDashboardComponent(manufacturerService, router, dialog, navbarService, translate, currentUserService, localStorageService) {
        var _this = _super.call(this) || this;
        _this.manufacturerService = manufacturerService;
        _this.router = router;
        _this.dialog = dialog;
        _this.navbarService = navbarService;
        _this.translate = translate;
        _this.currentUserService = currentUserService;
        _this.localStorageService = localStorageService;
        _this.currentTab = DashboardTabs.Published;
        _this.isSpecialAdmin = false;
        _this.tabs = [
            { name: 'Published', count: 0, icon: null },
            { name: 'toBeTreated', count: 0, icon: 'pen' },
        ];
        _this.isSpecialAdmin = _this.currentUserService.isSpecialAdmin;
        return _this;
    }
    ManufacturerDashboardComponent.prototype.ngOnInit = function () {
        this.initializeFilterStates();
        this.loadDataForTab();
    };
    ManufacturerDashboardComponent.prototype.initializeFilterStates = function () {
        this.pagination = {
            current_page: 1,
            page_count: null,
            total_count: null,
            pagination_per_page: null,
            published_count: null,
            to_be_treated_count: null,
        };
        var stored = this.localStorageService.getStoredObject('manufacturerFilters');
        if (stored) {
            this.filterStates = stored.states;
            this.currentTab = stored.currentTab;
        }
        else {
            this.filterStates[DashboardTabs.Published] = this.getDefaultFilterState();
            this.filterStates[DashboardTabs.toBeTreated] = this.getDefaultFilterState();
            this.filterStates[DashboardTabs.toBeTreated].status = [1, 6];
        }
    };
    ManufacturerDashboardComponent.prototype.onStatusChanged = function (status) {
        _super.prototype.onStatusChanged.call(this, status);
    };
    ManufacturerDashboardComponent.prototype.loadDataForTab = function (resetPage) {
        var _this = this;
        if (resetPage === void 0) { resetPage = true; }
        if (resetPage) {
            this.pagination.current_page = 1;
            this.passportCollection.clearSelection();
        }
        this.isLoading = true;
        this.passports = []; // Clear current data for safety
        this.updateTabCounts();
        if (this.passportsRequestSubscription) {
            this.passportsRequestSubscription.unsubscribe();
        }
        var currentFilters = this.filterStates[this.currentTab] || this.getDefaultFilterState();
        var requestPayload = this.convertFilterStateToObj(currentFilters);
        if (requestPayload.status.length === 0) {
            requestPayload.status = this.getDefaultStatusFilter();
        }
        // Store the filters in local storage
        this.localStorageService.storeObject('manufacturerFilters', { states: this.filterStates, currentTab: this.currentTab });
        this.passportsRequestSubscription = this.manufacturerService.getPassports(this.pagination.current_page, 10, requestPayload).subscribe({
            next: function (response) {
                _this.handleResponse(response);
                _this.isLoading = false;
            },
            error: function () {
                _this.isLoading = false;
            },
        });
    };
    ManufacturerDashboardComponent.prototype.handleResponse = function (response) {
        var body = JSON.parse(response._body);
        var headers = response.headers;
        this.pagination = {
            current_page: headers.get('X-Pagination-Current-Page'),
            page_count: headers.get('X-Pagination-Page-Count'),
            total_count: headers.get('X-Pagination-Total-Count'),
            pagination_per_page: headers.get('X-Pagination-Per-Page'),
            published_count: headers.get('X-Total-Published'),
            to_be_treated_count: headers.get('X-Total-Drafts'),
        };
        this.updateTabCounts();
        this.passports = body.map(function (item) { return new Passport(item); });
    };
    ManufacturerDashboardComponent.prototype.getDefaultFilterState = function () {
        return {
            sortBy: 'performance',
            search: '',
            families: [],
            materials: [],
            categories: [],
            searchId: [],
            selectedOptions: [],
            databases: [],
            dataProviders: [],
            dataRepresentativity: [],
            epdType: [],
            rating: {
                passportWithoutRating: false,
                selectRatingFilter: false,
                C2cCertified: false,
                composition: { color: false, black: false },
                cycle: { color: false, black: false },
                energy: { color: false, black: false },
                water: { color: false, black: false },
                social: { color: false, black: false },
            },
            status: this.getDefaultStatusFilter()
        };
    };
    ManufacturerDashboardComponent.prototype.getDefaultStatusFilter = function () {
        if (this.isSpecialAdmin) {
            if (this.currentTab === DashboardTabs.Published) {
                return [3];
            }
            return [1, 6];
        }
        return [2, 4, 1, 3];
    };
    ManufacturerDashboardComponent.prototype.onTabSelected = function (selectedTabName) {
        this.pagination.current_page = 1;
        this.versionReference = null;
        this.passportCollection.clearVersionSelection();
        this.currentTab = DashboardTabs[selectedTabName];
        this.loadDataForTab();
    };
    ManufacturerDashboardComponent.prototype.onCreateNewPassport = function () {
        this.navigateToCreatePassport();
    };
    ManufacturerDashboardComponent.prototype.havePassportsSelected = function () {
        return this.passportCollection.selectedPassports.length > 0 || this.allPagesSelected;
    };
    ManufacturerDashboardComponent.prototype.navigateToCreatePassport = function () {
        var _this = this;
        if (this.isSpecialAdmin) {
            this.openManufacturerGenericDialog(function (data) {
                if (data && data.isValidated) {
                    var value = data.value;
                    _this.router.navigate(['manufacturer/passports/new'], { queryParams: { type: value } });
                }
            });
        }
        else {
            this.router.navigate(['manufacturer/passports/new']);
        }
    };
    ManufacturerDashboardComponent.prototype.openManufacturerGenericDialog = function (callback) {
        var dialogRef = this.dialog.open(ManufacturerGenericComponent, {
            width: '650px',
            height: '310px',
        });
        dialogRef.afterClosed().subscribe(function (result) {
            callback(result);
        });
    };
    ManufacturerDashboardComponent.prototype.openRatingExplanationDialog = function () {
        this.dialog.open(PassportRatingExplanationDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                isActionButtonsShown: false,
            } }));
    };
    ManufacturerDashboardComponent.prototype.deleteOnePassport = function (passport) {
        this.processSelectedPassports('delete', [passport], false);
    };
    ManufacturerDashboardComponent.prototype.publishSelectedPassports = function () {
        if (this.passportCollection) {
            var selectedPassports = this.passportCollection.selectedPassports;
            if (selectedPassports && (selectedPassports.length > 0 || this.allPagesSelected)) {
                this.processSelectedPassports('publish', selectedPassports, this.allPagesSelected);
            }
        }
    };
    ManufacturerDashboardComponent.prototype.deleteSelectedPassports = function () {
        if (this.passportCollection) {
            var selectedPassports = this.passportCollection.selectedPassports;
            if (selectedPassports && (selectedPassports.length > 0 || this.allPagesSelected)) {
                this.processSelectedPassports('delete', selectedPassports, this.allPagesSelected);
            }
        }
    };
    ManufacturerDashboardComponent.prototype.showConfirmationDialog = function (confirmationMessage, onConfirm) {
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '880px',
            data: {
                text: confirmationMessage,
                imgUrl: 'assets/png/warning_darkpurple.png',
            }
        });
        dialogRef.afterClosed().subscribe(function (confirmed) {
            if (confirmed) {
                onConfirm();
            }
        });
    };
    ManufacturerDashboardComponent.prototype.processSelectedPassports = function (action, selectedPassports, isAllSelected) {
        var _this = this;
        var operationName = action === 'publish' ? 'confirmationPassportPublish' : 'confirmationPassportDeletion';
        var multipleOperationName = action === 'publish' ? 'multiplePublishConfirm' : 'multipleDeletionConfirmation';
        var confirmationMessage;
        if (selectedPassports.length === 1) {
            var passportName = selectedPassports[0].getProductName(this.navbarService.multilanguage) + " - V" + selectedPassports[0].version;
            confirmationMessage = this.translate.instant(operationName, { passportName: passportName });
        }
        else {
            confirmationMessage = this.translate.instant(multipleOperationName, { X: selectedPassports.length });
        }
        this.showConfirmationDialog(confirmationMessage, function () {
            _this.isLoading = true;
            var passportIds = selectedPassports.map(function (passport) { return passport.id.toString(); });
            var tab = _this.currentTab;
            var currentFilters = Object.assign({}, _this.filterStates[tab]);
            if (tab === DashboardTabs.toBeTreated && _this.filterStates[tab].status.length === 0) {
                currentFilters.status = [1, 6, 4, 2];
            }
            var requestPayload = _this.convertFilterStateToObj(currentFilters);
            var payload = tslib_1.__assign({ passportIds: passportIds, isAllPagesSelected: isAllSelected }, isAllSelected && requestPayload);
            var serviceMethod = action === 'publish' ? _this.manufacturerService.publishPassports : _this.manufacturerService.deletePassports;
            serviceMethod.call(_this.manufacturerService, payload).subscribe({
                next: function (response) {
                    response = JSON.parse(response._body);
                    if (response.status === 'success') {
                    }
                    else if (response.status === "error") {
                        _this.openDeleteWarningDialog(response.errors, action === 'publish' ? "passportPublicationNotWorking" : "passportDeletionNotWorking");
                    }
                },
                error: function (error) {
                    console.error("Error " + action + "ing passports:", error);
                    _this.isLoading = false;
                },
                complete: function () {
                    _this.isLoading = false;
                    _this.loadDataForTab();
                }
            });
        });
    };
    ManufacturerDashboardComponent.prototype.resetFiltersToDefault = function () {
        this.filterStates[this.currentTab] = this.getDefaultFilterState();
        this.loadDataForTab();
    };
    ManufacturerDashboardComponent.prototype.duplicatePassport = function (record) {
        var _this = this;
        var dialogRef = this.dialog.open(CopyPassportDialogComponent, {
            data: {
                name: record.name,
                parentModule: 'manufacturer'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.isLoading = true;
                _this.manufacturerService.duplicatePassports(record.id, result).subscribe({
                    next: function (response) {
                        var body = JSON.parse(response._body);
                        _this.isLoading = false;
                        _this.router.navigate(['agent/passports', body.id]);
                    },
                    error: function (error) {
                        _this.isLoading = false;
                        console.error('Error duplicating passport:', error);
                    }
                });
            }
        });
    };
    ManufacturerDashboardComponent.prototype.openDeleteWarningDialog = function (errors, title) {
        var _this = this;
        var errorItems = errors.map(function (error) { return ({
            name: error.name,
            error: _this.translate.instant(error.error) +
                (error.missingFields ? error.missingFields.map(function (field) { return _this.translate.instant(field); }).join(', ') : '')
        }); });
        this.dialog.open(GenericDeleteWarningComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { data: {
                title: title,
                category: this.translate.instant("Passport name"),
                items: errorItems,
            }, width: '880px', height: '400px' }));
    };
    ManufacturerDashboardComponent.prototype.updateTabCounts = function () {
        this.tabs = [
            tslib_1.__assign({}, this.tabs[0], { count: this.pagination.published_count }),
            tslib_1.__assign({}, this.tabs[1], { count: this.pagination.to_be_treated_count }),
        ];
    };
    ManufacturerDashboardComponent.prototype.getStatusFilter = function () {
        if (this.isSpecialAdmin) {
            return Object.values(PASSPORT_STATUS_CONST_MANUFACTURER_TOBETREATED);
        }
        return Object.values(PASSPORT_STATUS_CONST_MANUFACTURER);
    };
    ManufacturerDashboardComponent.prototype.onAskForChangeInNomenclatureOpenDialog = function () {
        this.dialog.open(NomenclatureChangeDialogComponent);
    };
    return ManufacturerDashboardComponent;
}(DashboardComponentBase));
export { ManufacturerDashboardComponent };
