<input
  id="avatarUploader"
  class="avatar-uploader__input"
  type="file"
  name="preview"
  placeholder="{{ 'Avatar' | translate }}"
  ng2FileSelect
  [uploader]="uploader"
  (change)="uploadImg($event);"
  #fileInput
  (click)="fileInput.value = null"
  *ngIf="!readOnly"
>
<div class="avatar-uploader__avatar-container">
    <label
      [style.background-image]="getBackgroundImage()"
      for="avatarUploader"
      class="avatar-uploader__avatar">{{ 'Upload' | translate }}
    </label>
    <button class="avatar-uploader__delete-button" *ngIf="preview && !readOnly" (click)="$event.stopPropagation();deleteAvatar()">
        <img src="assets/png/profile-delete.png">
    </button>
</div>
