import { INotificationParam } from './notification-param.interface';
/**
 * Created by aleksandr on 20.07.17.
 */
export interface INotificationEntry {
  id: number;
  user_id: number;
  message_id: number;
  type: INotificationEntryType;
  status: number;
  role: INotificationEntryRole;
  params: INotificationParam[];
  created_at: string;
  updated_at: string;
}


export enum INotificationEntryRole {
  ROLE_ALL = -1,
  ROLE_AGENT = 1,
  ROLE_SUPERVISOR = 2,
  ROLE_SALE = 3,
  ROLE_TSM = 4,
  ROLE_DEPOSITOWNER = 5,
  ROLE_ESM = 6,
  ROLE_ADMIN = 7,
  ROLE_SUPERADMIN = 8,
  ROLE_SPECIALADMIN = 9,
  ROLE_MANUFACTURER = 10,
  ROLE_TSMI = 11
}

export type INotificationEntryType = INotificationEntrySalesType
  | INotificationEntryTsmType
  | INotificationEntryEsmType
  | INotificationEntryDoType
  | INotificationEntrySupervisorType
  | INotificationEntryAgentType
  | INotificationEntryAdminType
  | INotificationEntrySuperadminType
  | INotificationEntrySpecialAdminType
  | INotificationEntryAllType;

export enum INotificationEntrySalesType {
  TYPE_SALE_NEW_NEED_PUBLISHED = 1,
  TYPE_SALE_ASK_FOR_MODIFICATION = 2,
  TYPE_SALE_DEPOSIT_REJECTED = 3,
  TYPE_SALE_NEW_ECOSYSTEM = 4,
  TYPE_SALE_NEW_OFFER = 5,
  TYPE_SALE_ECOSYSTEM_CANCELLED = 6,
  TYPE_SALE_DELIVERY_DATE_TODAY = 7,
  TYPE_SALE_SUCCESSFUL_ACTIVATION = 8,
  TYPE_SALE_NEW_ECOSYSTEM_PUBLIC = 9,
  TYPE_SALE_NEW_OFFER_PUBLIC = 10,
  TYPE_SALE_OFFER_WAITING_FOR_CONFIRMATION = 11,
  TYPE_SALE_CONSULT_DEPOSIT_LINK = 12,
  TYPE_ID_SALE_NEW_SITE = 100,
  TYPE_ID_SALE_NEW_SITES = 101
}

export enum INotificationEntryTsmType {
  TYPE_TSM_NEW_TF_PUBLISHED = 1,
  TYPE_TSM_TF_ASK_FOR_MODIFICATION = 2,
  TYPE_TSM_TF_REJECTED = 3,
  TYPE_TSM_ORDER_NEW = 4,
  TYPE_TSM_ORDER_VALIDATED_MANUALLY = 5,
  TYPE_TSM_ORDER_IN_PROCESS = 6,
  TYPE_TSM_ORDER_IS_TODAY = 7,
  TYPE_TSM_SUCCESSFUL_ACTIVATION = 8,
  TYPE_TSM_ORDER_CANCELLED = 9,
  TYPE_TSM_ORDER_WAITING_FOR_VALIDATION = 10,
  TYPE_TSM_ENDS_TODAY = 11,
  TYPE_TSM_ORDER_WAITING_FOR_CONFIRMATION = 12
}

export enum INotificationEntryEsmType {
  TYPE_ESM_NEED_PUBLISHED = 1,
  TYPE_ESM_NEED_EDITED = 2,
  TYPE_ESM_TF_NEW = 3,
  TYPE_ESM_TF_EDITED = 4,
  TYPE_ESM_ORDER_WILL_EXPIRE = 5, // DEPRECATED - ORDERS NO LONGER EXPIRE
  TYPE_ESM_ORDER_REJECTED_BY_TSM = 6,
  TYPE_ESM_ORDER_REJECTED_BY_DO = 7,
  TYPE_ESM_ORDER_ACCEPTED_BY_TSM = 8,
  TYPE_ESM_ORDER_ACCEPTED_BY_DO = 9,
  TYPE_ESM_ORDER_ASK_FOR_MODIFICATION_BY_TSM = 10,
  TYPE_ESM_ORDER_ASK_FOR_MODIFICATION_BY_DO = 11,
  TYPE_ESM_ORDER_CONFIRMED = 12,
  TYPE_ESM_OFFER_ACCEPTED = 13,
  TYPE_ESM_ASK_FOR_COUNTER_PROPOSAL = 14,
  TYPE_ESM_ECOSYSTEM_DONE = 15,
  TYPE_ESM_NEW_MESSAGE = 16,
  TYPE_ESM_SUCCESSFUL_ACTIVATION = 17,
  TYPE_ESM_OFFER_REFUSED = 18,
  TYPE_ESM_DEPOSIT_PUBLISHED = 19,
  TYPE_ESM_ECOSYSTEM_OFFER_CONFIRMED=20,
  TYPE_ESM_ECOSYSTEM_ORDER_CONFIRMED_BY_TSM=21,
  TYPE_ESM_ECOSYSTEM_ORDER_CONFIRMED_BY_DO=22,
  TYPE_ESM_ORDER_ACCEPTED_BY_TSMI=23,
  TYPE_ESM_ORDER_ASK_FOR_MODIFICATION_BY_TSMI=24,
  TYPE_ESM_ORDER_REJECTED_BY_TSMI=25,
  TYPE_ESM_ASK_FOR_COUNTER_PROPOSAL_PUBLIC = 26,
  TYPE_ESM_OFFER_ACCEPTED_PUBLIC = 27,
  TYPE_ESM_NEED_DELETED = 28 ,
  TYPE_ESM_PASSPORT_CHANGED_UNIT_BY_MANUFACTURER = 29,
  TYPE_ESM_ORDER_INVALIDATED_BY_TSMI = 30 ,
  TYPE_ESM_ORDER_INVALIDATED_BY_TSM = 31 ,
  TYPE_ESM_ORDER_INVALIDATED_BY_DO = 32 ,
  TYPE_ESM_ORDER_PROBLEM_BY_TSM = 33 ,
  TYPE_ESM_ORDER_PROBLEM_BY_DO = 34 ,
  TYPE_ESM_ORDER_PROBLEM_BY_SALE = 35,
  TYPE_ESM_DEPOSIT_ORDER_DEPOSIT_PASSPORT_CHANGED = 36,
  TYPE_ESM_DEPOSIT_ORDER_INDEPENDENT_PASSPORT_CHANGED = 37,
  TYPE_ESM_DEPOSIT_ORDER_ENTITY_GENERAL_CHANGED = 38,
  TYPE_ESM_DEPOSIT_ORDER_ENTITY_OTHER_CHANGED = 39,
  TYPE_ESM_DEPOSIT_ORDER_ENTITY_PASSPORT_CHANGED = 40,
  TYPE_ESM_NEW_MATCHES=41
}

export enum INotificationEntryAgentType {
  TYPE_AGENT_NEW_DEPOSIT_PUBLISHED = 1,
  TYPE_AGENT_DEPOSIT_ASK_FOR_MODIFICATION_BV_SUPERVISOR = 2,
  TYPE_AGENT_DEPOSIT_REJECTED_BY_DEPO_OWNER = 3,
  TYPE_AGENT_DEPOSIT_REJECTED_BY_SUPERVISOR = 4,
  TYPE_AGENT_SUCCESSFUL_ACTIVATION = 5,
  TYPE_AGENT_DEPOSIT_ASK_FOR_MODIFICATION_BV_DO = 6,
  TYPE_AGENT_PASSPORT_CHANGED_UNIT_BY_MANUFACTURER= 7,
  TYPE_AGENT_AGENT_PASSPORT_IS_MODIFIED = 8
}
export enum INotificationEntrySupervisorType {
  TYPE_SUPERVISOR_DEPOSIT_NEW = 1,
  TYPE_SUPERVISOR_DEPOSIT_NEW_PASSPORT = 2,
  TYPE_SUPERVISOR_SUCCESSFUL_ACTIVATION = 3,
  TYPE_SUPERVISOR_NEED_NEW = 4,
  TYPE_SUPERVISOR_TRANSFORM_NEW = 5,
  TYPE_SUPERVISOR_AGENT_IMPORT = 6,
  TYPE_SUPERVISOR_DEPOSIT_EDITED = 7
}
export enum INotificationEntryAllType {
  TYPE_ALL_SUCCESSFUL_ACTIVATION = 1
}
export enum INotificationEntryDoType {
  TYPE_DEPO_OWNER_NEW_DEPOSIT_PUBLISHED = 1,
  TYPE_DEPO_OWNER_DEPOSIT_ASK_FOR_MODIFICATION = 2,
  TYPE_DEPO_OWNER_DEPOSIT_REJECTED_BY_SUPERVISOR = 3,
  TYPE_DEPO_OWNER_DEPOSIT_WAITING_FOR_VALIDATION = 4,
  TYPE_DEPO_OWNER_DEPOSIT_EDITED = 5,
  TYPE_DEPO_OWNER_ORDER_WAITING_FOR_VALIDATION = 6,
  TYPE_DEPO_OWNER_ORDER_VALIDATED_MANUALLY = 7,
  TYPE_DEPO_OWNER_ORDER_IN_PROCESS = 8,
  TYPE_DEPO_OWNER_ORDER_IS_TODAY = 9,
  TYPE_DEPO_OWNER_ORDER_ECOSYSTEM_CANCELLED = 10,
  TYPE_DEPO_OWNER_NEW_MESSAGE = 11,
  TYPE_DEPO_OWNER_SUCCESSFUL_ACTIVATION = 12,
  TYPE_DEPO_OWNER_PICKUP_TODAY = 13,
  TYPE_ID_DEPO_OWNER_SITE_ADDED = 14,
  TYPE_ID_DEPO_OWNER_SITE_DELETED = 15,
  TYPE_DEPO_OWNER_AGENT_IMPORT = 16,
  TYPE_ID_DEPO_OWNER_SITE_EXPIRED_ONE_WEEK = 17,
  TYPE_ID_DEPO_OWNER_SITE_EXPIRED_TOMORROW = 18,
  TYPE_ID_DEPO_OWNER_SITE_EXPIRED = 19,
  TYPE_ID_DEPO_OWNER_SITE_DELETE_BY_SUPERADMIN = 20,
  TYPE_ID_DEPO_OWNER_CHANGE_END_OF_WORK = 21,
  TYPE_ID_DEPO_OWNER_SITE_EXPIRED_TWO_MONTHS = 22,
  TYPE_DEPO_OWNER_ORDER_WAITING_FOR_CONFIRMATION = 23



}
export enum INotificationEntryAdminType {
  TYPE_ADMIN_ECOSYSTEM_IN_PROCESS = 1,
  TYPE_ADMIN_ECOSYSTEM_DONE = 2,
  TYPE_ADMIN_USER_ACTIVATED = 3,
  TYPE_ADMIN_USER_INSCRIPTION = 4,
  TYPE_ADMIN_ID_SITE_DELETED = 7,
  TYPE_ADMIN_ID_SITE_EXPIRED_TWO_MONTHS = 8,
  TYPE_ADMIN_ID_SITE_EXPIRED_ONE_WEEK = 9,
  TYPE_ADMIN_ID_SITE_EXPIRED_TOMORROW = 10,
  TYPE_ADMIN_ID_SITE_EXPIRED = 11,
  TYPE_ADMIN_ID_SITE_CREATED_BY_SUPERADMIN = 12,
  TYPE_ADMIN_ID_SITE_DELETE_BY_SUPERADMIN = 13,
  TYPE_ADMIN_ID_NUMBER_OF_ESM = 14,
  TYPE_ADMIN_ID_CHANGE_END_OF_WORK = 15

}

export enum INotificationEntrySuperadminType {
  TYPE_SUPERADMIN_CHANGE_NOMENCLATURE_SUPERVISOR = 1,
  TYPE_SUPERADMIN_CHANGE_NOMENCLATURE_MANUFACTURER = 2,
  TYPE_SUPERADMIN_SITE_CAN_DELETE = 3
}

export enum INotificationEntrySpecialAdminType {
  TYPE_SPECIALADMIN_USER_ACTIVATED = 1,
  TYPE_SPECIALADMIN_NEW_PASSPORT = 2,
  TYPE_SPECIALADMIN_NEW_TF = 3,
  TYPE_ID_SPECIALADMIN_FOR_RATING = 4,
  TYPE_ID_SPECIALADMIN_FOR_RATING_WITH_TOKEN = 5
}

export enum INotificationEntryManufacturerType {
  TYPE_MANUFACTURER_PASSPORT_FOR_MOD = 1,
  TYPE_MANUFACTURER_PASSPORT_PUBLISHED = 2,
}

export enum INotificationEntryTsmiType {
  TYPE_TSMI_TF_ASK_FOR_MODIFICATION = 1,
  TYPE_TSMI_NEW_TF_PUBLISHED = 2,
  TYPE_TSMI_ORDER_WAITING_FOR_VALIDATION = 3,
  TYPE_TSMI_ORDER_VALIDATED_MANUALLY = 4,
  TYPE_TSMI_ORDER_ENDS_TODAY = 5,
  TYPE_TSMI_ORDER_CANCELLED = 6,
  TYPE_TSMI_ORDER_VALIDATED_MANUALLY_WITHOUT_SITE = 7,
  TYPE_TSMI_ORDER_WAITING_FOR_CONFIRMATION = 8
}
