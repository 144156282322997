<div class="manufacturer-generic-dialog__wrapper">
    <div class="manufacturer-generic-dialog__close" (click)="close()">
      <i class="icon icon--close_s"></i>
    </div>
    <div class="manufacturer-generic-dialog__content">
        <div class="manufacturer-generic-dialog__input">

        <div class="manufacturer-generic-dialog__radio-group">
         <div class="manufacturer-generic-dialog__label">{{'DIALOG_PASSPORT_TYPE_TITLE' | translate}}</div>
            <div class="manufacturer-generic-dialog__radio-container ">
                <md-radio-group
                [(ngModel)]="passportType"
                class="manufacturer-generic-dialog__radio-group"

                >

                        <md-radio-button
                            *ngFor="let type of radioBtnArray"
                            [value]="type.value"
                            class="manufacturer-generic-dialog__radio-element"
                        >
                            {{type.name | capitalize | translate}}
                        </md-radio-button>
                </md-radio-group>
                
            </div>
        </div>

        </div>
    </div>

    <footer class="controls manufacturer-generic-dialog__footer">
        <ul class="button-group__list manufacturer-generic-dialog__button-group">
          <li class="button-group__item manufacturer-generic-dialog__button">
            <button
              md-button md-raised-button
              (click)="onValidate()">{{ 'DIALOG_PASSPORT_TYPE_VALIDATE' | translate }}
            </button>
          </li>
          <li class="button-group__item manufacturer-generic-dialog__button">
            <button
                    md-button md-raised-button
                    (click)="onCancel()">{{ 'DIALOG_PASSPORT_TYPE_CANCEL' | translate }}
            </button>
          </li>
        </ul>
      </footer>
</div>