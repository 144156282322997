import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-passports-status-icon',
    templateUrl: './passports-status-icon.component.html',
})
export class PassportStatusIconComponent {
    @Input() status: number;
    
}
