import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../shared/models/active-record.class';
import { ActiveImportService } from '../services/active-import.service';
var ActiveImport = /** @class */ (function (_super) {
    tslib_1.__extends(ActiveImport, _super);
    function ActiveImport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = ActiveImportService;
        return _this;
    }
    ActiveImport.prototype.fields = function () {
        return ['id', 'user_id', 'import_config_id', 'import_id', 'import_type', 'status'];
    };
    return ActiveImport;
}(ActiveRecord));
export { ActiveImport };
