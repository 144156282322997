import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { Observable } from 'rxjs';
import {DepositLocationImportService} from '../services/deposit-location-import.service';
import {DepositLocationImportFile} from './deposit-location-import-file.class';
import {EImportEntityStatus} from '../../../import-spreadsheet/values/enums/import-entity-status.enum';
export class DepositLocationImport extends ExtendedActiveRecord {

  protected provider = DepositLocationImportService;

  public id: number;
  public tab_name: string;
  public first_row: number;
  public last_row: number;
  public site_name_field: string;
  public description_field: string;
  public address_field: string;
  public status: EImportEntityStatus;

  public errors: {id: number, import_id: number, error_type: number, row: number, value: string}[];
  public files: DepositLocationImportFile;

  constructor(data?: any) {
    super(data);
  }

  protected fields() {
    return [
      'id',
      'tab_name',
      'first_row',
      'last_row',
      'site_name_field',
      'description_field',
      'address_field',
      'errors',
      'files',
      'status'
    ];
  }

  startImport(): Observable<any> {
    return this.simplePost('/' + this.id + '/start');
  }
}
