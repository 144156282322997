<ng-container *ngIf="deposit?.loaded">
  <div class="deposit-details">
    <div class="deposit-details__row deposit-details__row--heading">
      <div class="deposit-details__heading flex justify-between align-start">
        <div class="deposit-details__heading--label">
          {{ 'Deposit' | translate }}
        </div>
        <div class="deposit-details__heading--info-modified">
          {{ 'Modified by' | translate }}: <img src="/assets/png/user--icon.png"
                                                class="deposit-details__heading--info-modified-icon" alt="">
          <span class="deposit-details__heading--info-name primary-color">
          <a href="#">{{deposit.creator}}</a>
          </span>
          {{deposit.updated_at | date: 'dd.MM.y HH:mm'}}
        </div>
      </div>

      <div class="flex deposit-details__name-row">
        <div class="flex-column">
          <div class="deposit-details__header--label primary-color">
            {{ 'Deposit name' | translate }}
          </div>
          <div class="deposit-details__name-wrapper">
            <div class="deposit-details__header--name">{{deposit.name}}</div>
          </div>
        </div>
        <div class="deposit-details__image-wrapper" *ngIf="deposit.thumbnail">
            <a *ngIf="deposit.logo; else noLogo" [href]="deposit.logo" target="_blank">
              <img class="logo" [src]="deposit.thumbnail" alt="">
            </a>
            <ng-template #noLogo>
              <img class="logo" [src]="deposit.thumbnail" alt="">
            </ng-template>
        </div>
      </div>

    </div>

    <div class="deposit-details__separator flex">
      <div class="deposit-details__separator--number primary-color">
        1
      </div>
      <div class="deposit-details__separator--content background-primary-color">
        <div>
          {{ 'Circular passport' | translate | uppercase}}
        </div>
        <div class="deposit-details__full-passport" *ngIf="parentModule === moduleNames.SUPERVISOR"
             (click)="onSeeFullPassport()">
          <div class="deposit-details__full-passport-icon eye--icon"></div>
          <div class="deposit-details__full-passport-text">{{ 'See the full passport' | translate}}</div>
        </div>
      </div>
    </div>

    <passport-details-view *ngIf="deposit.passport" [passport]="deposit.passport" [listener]="eventStream$" class="deposit-details__row">
    </passport-details-view>

    <div class="deposit-details__separator flex">
      <div class="deposit-details__separator--number primary-color">
        2
      </div>
      <div class="deposit-details__separator--content background-primary-color">
        {{ 'Location' | translate | uppercase}}
      </div>
    </div>

    <div class="deposit-details__row flex">
      <md-input-container class="deposit-details__location-block">
        <input mdInput autocomplete="off" placeholder="{{ 'Site name' | translate }}" readonly [value]="deposit.deposit_location.name">
      </md-input-container>
      <md-input-container class="deposit-details__location-block">
        <input mdInput autocomplete="off" placeholder="{{ 'Address' | translate }}" readonly [value]="deposit.deposit_location.address">
      </md-input-container>
      <button
        class="view-map deposit-details__location-block"
        md-button
        [hidden]="null"
        (click)="onShowMapClick()">
        <img src="/assets/png/map--icon.png" class="icon" alt="">
        {{ 'View map' | translate }}
      </button>
    </div>

    <div class="deposit-details__row">
      <div class="flex">
        <md-input-container>
          <input
            class="deposit-details__description-row"
            mdInput
            autocomplete="off"
            readonly
            placeholder="{{ 'Description' | translate }}"
            [value]="deposit.location.description">
        </md-input-container>
      </div>
    </div>
    <div class="deposit-details__row">
      <div class="form-group__label location__tag-label deposit-details__tags-label">{{ 'Tag(s)' | translate }}</div>
      <div class="location__tag-item b-module background-primary-color deposit-details__tags-item"
           *ngFor="let tag of depositTags">
        <span>{{tag.name}}</span>
      </div>
    </div>
    <div class="deposit-details__separator flex">
      <div class="deposit-details__separator--number primary-color">
        3
      </div>
      <div class="deposit-details__separator--content background-primary-color">
        {{ 'Deposit info' | translate | uppercase}}
      </div>
    </div>

    <div class="deposit deposit-details__other">
      <div class="deposit-details__other-group deposit-details__other-group--quantity">
        <md-input-container class="quantity total" [class.ng-invalid]="totalControl.hasError('totalLessBooked')">
          <input
            mdInput
            autocomplete="off"
            placeholder="{{ 'Total quantity' | translate }}"
            [formControl]="totalControl">
        </md-input-container>
        <md-input-container class="quantity booked">
          <input mdInput autocomplete="off" placeholder="{{ 'Booked' | translate }}" readonly
                 [value]="deposit.booked + ' ' + (deposit.passport.unit_text | translate)">
        </md-input-container>
        <md-input-container class="quantity available">
          <input mdInput autocomplete="off" placeholder="{{ 'Available' | translate }}" readonly
                 [value]="(deposit.total - deposit.booked) + ' ' + (deposit.passport.unit_text | translate)">
        </md-input-container>
      </div>

      <div class="deposit-details__other-group deposit-details__other-group--2fr">
        <md-input-container class="availability_date">
          <input mdInput autocomplete="off" placeholder="{{ 'Availability date' | translate }}" readonly
                 [value]="deposit.availability_date | date: 'dd.MM.y'">
        </md-input-container>
        <md-input-container class="availability_date" [floatPlaceholder]="'always'">
          <input mdInput autocomplete="off" placeholder="{{ 'Expiration date' | translate }}" readonly
                 [value]="deposit.availability_expiration | date: 'dd.MM.y'">
        </md-input-container>
      </div>

      <div class="deposit-details__other-group deposit-details__other-group--2fr">
        <md-input-container class="condition">
          <input mdInput autocomplete="off" placeholder="{{ 'Item state' | translate }}" readonly
                 [value]="deposit.conditionData?.view | capitalize | translate">
        </md-input-container>
        <md-input-container class="condition">
          <input mdInput autocomplete="off" placeholder="{{ 'Demountability' | translate }}" readonly
                 [value]="deposit.demountabilityData?.view | capitalize | translate">
        </md-input-container>
      </div>
    </div>
    <div class="flex">
      <md-input-container  class="deposit-details__other deposit-details__other--block deposit-details__description-row">
        <textarea #detailsDescription
                  autocomplete="off"
                  mdInput
                  placeholder="{{ 'State description' | translate }}"
                  readonly
                  [value]="deposit.description"></textarea>
      </md-input-container>
    </div>
    <div class="deposit deposit-details__other">
      <div class="deposit-details__other-group deposit-details__other-group--origin">
        <md-input-container class="condition condition--l">
          <input mdInput autocomplete="off" placeholder="{{ 'DEPOSIT_ORIGIN_FIELD' | translate }}" readonly
                 [value]="getSource(!!deposit.reused, deposit.deposit_source) | translate">
        </md-input-container>
        <md-input-container class="condition condition--l">
          <input mdInput autocomplete="off" placeholder="{{ 'POTENTIAL_BECOMING_FIELD' | translate }}" readonly
                 [value]="potentialBecoming(!!deposit.potential_reuse) | translate">
        </md-input-container>
      </div>
      <div class="deposit-details__other-group deposit-details__other-group--characteristics">
        <div class="deposit-details__other-group">
          <md-input-container class="quantity medium">
            <input mdInput autocomplete="off" placeholder="{{ 'Price' | translate }}" readonly
                   [value]="getNumeric(deposit.price, '€')">
          </md-input-container>
        </div>

        <div class="deposit-details__other-group deposit-details__other-group--3fr">
          <md-input-container class="quantity medium">
            <input mdInput autocomplete="off" placeholder="{{ 'Length' | translate }}" readonly
                   [value]="getNumeric(deposit.length, 'mm')">
          </md-input-container>
          <md-input-container class="quantity medium">
            <input mdInput autocomplete="off" placeholder="{{ 'Width' | translate }}" readonly
                   [value]="getNumeric(deposit.width, 'mm')">
          </md-input-container>
          <md-input-container class="quantity medium">
            <input mdInput autocomplete="off" placeholder="{{ 'Height' | translate }}" readonly
                   [value]="getNumeric(deposit.height, 'mm')">
          </md-input-container>
        </div>


        <passport-conversion *ngIf="!PASSPORT_UNIT_WITHOUT_CONVERSION.includes(deposit.passport.unit)"
          class="additionaly__conversion"
          [ngModel]="deposit.conversion"
          [unitView]="deposit.passport.unit_text"
        >
        </passport-conversion>
      </div>
    </div>

    <div class="deposit-details__row flex-column">
      <div class="flex" *ngIf="parentModule !== moduleNames.SALES">
        <md-input-container class="deposit-details__description-row">
          <input    autocomplete="off" mdInput
                    placeholder="{{ 'Link to the reseller\'s website' | translate }}"
                    readonly
                    [value]="deposit.url_link || ''" />
        </md-input-container>
      </div>
      <div class="flex">
        <md-input-container class="deposit-details__description-row">
          <textarea #productCharacteristics
                    autocomplete="off"
                    mdInput
                    placeholder="{{ 'Product characteristics' | translate }}"
                    readonly
                    [value]="deposit.product_comment  || ''"></textarea>
        </md-input-container>
      </div>
      <div class="flex">
        <md-input-container class="deposit-details__description-row">
          <textarea #color
                    autocomplete="off"
                    mdInput
                    placeholder="{{ 'Appearance and color' | translate }}"
                    readonly
                    [value]="deposit.color  || ''"></textarea>
        </md-input-container>
      </div>
      <div class="flex">
        <md-input-container class="deposit-details__description-row">
          <textarea #detailsComment autocomplete="off" mdInput placeholder="{{ 'Comment' | translate }}" readonly [value]="deposit.comments"></textarea>
        </md-input-container>
      </div>
    </div>

    <div class="deposit-details__row deposit-details__uploader-label"
         *ngIf="deposit.deposit_photos?.length > 0">
      {{ 'Deposit' | translate }} {{ 'Photos' | translate | lowercase }}
    </div>
    <div class="deposit-details__row">
      <documents-uploader
        [files]="deposit.deposit_photos"
      ></documents-uploader>
    </div>

    <div class="deposit-details__row deposit-details__row--heading deposit-details__uploader-label"
         *ngIf="deposit.deposit_file?.length > 0">
      {{ 'Deposit' | translate }} {{ 'Documents' | translate | lowercase }}
    </div>
    <div class="deposit-details__row">
      <documents-uploader
        [files]="deposit.deposit_file"
      ></documents-uploader>
    </div>

    <div class="flex actions actions-row">
      <button
        class="gray"
        md-button
        (click)="cancel()"
      >{{ 'Cancel' | translate }}
      </button>
      <button
        md-button
        (click)="save()"
      >{{ 'Save' | translate }}
      </button>

      <div class="small-modal" *ngIf="modificationModalIsVisible">
        <div class="small-modal__header">
          <div class="small-modal__info">
            {{ 'Message to Need manager or Agent' | translate }}
            <span class="small-modal__data">{{deposit.modification?.length || 0}}/3000</span>
          </div>
          <div class="small-modal__button">
            <button md-button md-raised-button [hidden]="null"
                    (click)="askForModification()">{{ 'Send' | translate }}</button>
          </div>
        </div>
        <div class="small-modal__body">
          <textarea [(ngModel)]="deposit.modification"
                    (ngModelChange)="modificationChanged()"
                    [class.modification-field--error]="modificationIsNull"
                    [readonly]="false"
                    placeholder="{{ 'Type your modification here' | translate }}"
                    maxlength="3000"></textarea>
        </div>
      </div>
      <div *ngIf="showValidationButtons">
        <ul class="button-group__list deposit-details__actions-group">
          <li class="button-group__item">
            <button
              [hidden]="null"
              md-button
              (click)="reject()"
            >{{ 'Reject' | translate }}
            </button>
          </li>
          <li class="button-group__item">
            <button
              [hidden]="null"
              md-button
              (click)="changeVisibilityOfModificationModal()"
              *ngIf="!checkStatusValidated()"
            >{{ 'Ask for modification' | translate }}
            </button>
          </li>
          <li class="button-group__item">
            <button
              [hidden]="null"
              md-button
              (click)="validate()"
            >{{ 'Validate' | translate }}
            </button>
          </li>

        </ul>
      </div>
    </div>
  </div>
</ng-container>
