import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../../entities/client/service/language.service';
import { CurrentUserService } from '../../shared/services/current-user.service';


@Component({
  moduleId: module.id,
  selector: 'forgot-password',
  templateUrl: 'forgot-password.component.html',
  providers: [CurrentUserService]
})

export class ForgotPasswordComponent implements OnInit {

  private username: string;
  public sent: boolean = false;
  public failed: boolean = false;

  constructor(private userService: CurrentUserService,
              private router: Router,
              private languageService: LanguageService) {
  }

  ngOnInit() {
    this.languageService.initLang();
  }

  send() {
    console.log('send');
    this.userService.requestPassword(this.username).subscribe(
      response => {
        this.failed = false;
        this.sent = true;
      },
      err => {
        this.sent = false;
        this.failed = true;
      }
    );
  }

  goToLoginPage() {
    this.router.navigate(['login']);
  }
}
