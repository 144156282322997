import {Component, Input, Output, EventEmitter} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {Passport} from '../../models/passport.class';
import { NavbarStateService } from '../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'passport-info-display',
  templateUrl: 'passport-info-display.component.html'
})
export class PassportInfoDisplayComponent {
  @Input() passport: Passport;
  @Input() showPassportNameMenu = false;
  @Input() showCopyId = true;
  @Output() public onOpenGenerationNameDialog = new EventEmitter<Event>();

  constructor(
    private translateService: TranslateService,
    public stateService: NavbarStateService,

  ){}

  openGenerationNameDialog(event){
    this.onOpenGenerationNameDialog.emit(event);
  }


}
