import { ControlState } from '../../../shared/models/control-state.class';
import { PassportControlStates } from '../../passport/models/passport-control-states.class';

export class DepositControlStates {
  name?: ControlState;
  passport_id?: ControlState;
  passport?: PassportControlStates;
  deposit_location_id?: ControlState;
  quantity?: ControlState;
  availability_date?: ControlState;
  availability_expiration?: ControlState;
  condition?: ControlState;
  description?: ControlState;
  comments?: ControlState;
  photos?: ControlState;

  save?: ControlState;
  publish?: ControlState;
  delete?: ControlState;
}
