import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { NomenclatureItem } from '../models/nomenclature-item.class';

@Injectable()
export class NomenclatureService extends CrudService {
  protected namespace = 'nomenclatures';
  protected namespaceSingular = 'nomenclature';
  protected ModelClass = NomenclatureItem;

  getChangeNomenclatureUrl(): string {
    return this.getEndpoint(this.singularEndpoint + '/request');
  }

  public sendChangeNomenclature(material: string, description: string) {
    return this.sendPost(this.getChangeNomenclatureUrl(), {name: material, description: description})
      .catch(this.onError.bind(this));
  }
}
