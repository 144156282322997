import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { TransformTag } from '../models/transform-tag.class';

@Injectable()
export class TransformTagService extends CrudService {
  protected namespace = 'transformation-tags';
  protected namespaceSingular = 'transformation-tag';
  protected ModelClass = TransformTag;
}
