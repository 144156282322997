import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { EcosystemFile } from '../../../dashboards/ecosystem-manager/models/ecosystem-file.class';
var EcosystemFileService = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemFileService, _super);
    function EcosystemFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'ecosystem-files';
        _this.namespaceSingular = 'ecosystem-file';
        _this.ModelClass = EcosystemFile;
        return _this;
    }
    return EcosystemFileService;
}(ExtendedCrudService));
export { EcosystemFileService };
