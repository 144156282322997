import * as tslib_1 from "tslib";
import { ApiService } from '../../../../shared/services/api.service';
var RankingFormulaService = /** @class */ (function (_super) {
    tslib_1.__extends(RankingFormulaService, _super);
    function RankingFormulaService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RankingFormulaService.prototype.getFormula = function () {
        return this.sendGet(this.getEndpoint('ranking-formula'))
            .map(function (response) { return response.json() && response.json().length > 0
            ? response.json()[0]
            : null; });
    };
    RankingFormulaService.prototype.updateFormula = function (formula) {
        return this.sendPut(this.getEndpoint('ranking-formula/1'), formula)
            .map(function (response) { return response.json(); });
    };
    return RankingFormulaService;
}(ApiService));
export { RankingFormulaService };
