import { StatsContainer } from './stats-container.class';
import { IDepositStats } from '../interfaces/deposit-stats.interface';
import { DepositStats } from './deposit-stats.class';
import { ILiteral } from 'ng2-adn-common';

export class DepositStatsContainer extends  StatsContainer<IDepositStats> {

  extractData(data: any): any {
    let json = super.extractData(data);
    this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
    return json;
  }

  createModel(json: ILiteral, key: string[]) {
    key.forEach((k) => {
      if (!!json[k]) {
        json[k] = json[k].map((data: ILiteral) => new DepositStats(data));
      }
    });
  }

  getTotal(): any {
    let result: any = {total:0,last_year:0,last_month:0,last_week:0};
    this.total.forEach(item => {
      result.total += item.published;
    });
    this.last_year.forEach(item => {
      result.last_year += item.published;
    });
    this.last_month.forEach(item => {
      result.last_month += item.published;
    });
    this.last_week.forEach(item => {
      result.last_week += item.published;
    });
    return result;
  }

  getEvolution() {
    let result: any[] = [];
    if (this.total.length) {
      result.push(['Date','Deposits']);
    }
    this.total.forEach(item => {
      result.push([new Date(item.y, item.m - 1, 1), item.published || 0]);
    });
    return result;
  }
}
