import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VirginProduct } from '../../models/virgin-product.class';
import { ProductSector } from '../../models/product-sector.class';
import { VirginProductService } from '../../services/virgin-product.service';
import { Observable } from 'rxjs/Observable';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';

@Component({
  moduleId: module.id,
  selector: 'virgin-product-details',
  templateUrl: 'virgin-product-details.component.html',
  styleUrls: ['virgin-product-details.component.scss'],
  providers: [ReadOnlyService],
})
export class VirginProductDetailsComponent implements OnInit {
  @Input() id: number;
  @Input() readOnly: boolean;
  @Output() saved = new EventEmitter();
  public savePassport = new EventEmitter();
  public virginProduct: VirginProduct;

  public sectorName = '';

  constructor(private virginProductService: VirginProductService,
              public readOnlyService: ReadOnlyService,) {
  }

  ngOnInit() {
    this.readOnlyService.readOnly = this.readOnly;
    this.virginProduct = new VirginProduct;
    if (this.id) {
      this.virginProductService.view(
        this.id,
        {expand: 'passport,sectors,passport.category'}
      ).subscribe((res: VirginProduct) => {
        this.virginProduct = res;
        this.virginProduct.loaded = true;
      });
    } else {
      this.virginProduct.loaded = true;
    }
  }

  addSector() {
    if (this.sectorName) {
      let sector = new ProductSector({
        name: this.sectorName,
      });
      this.virginProduct.sectors.push(sector);
      this.sectorName = '';
    }
  }

  removeSector(sector: ProductSector) {
    this.virginProduct.sectors.splice(
      this.virginProduct.sectors.indexOf(sector),
      1
    );
  }

  save() {
    this.savePassport.emit({key: 'savePassport'});
    this.virginProduct.name = this.virginProduct.passport.name;
    this.virginProduct.save().subscribe((virginProduct: VirginProduct) => {
      this.virginProduct.id = virginProduct.id;
      if (this.virginProduct.sectors.length) {
        Observable.zip(
          ...this
            .virginProduct
            .sectors
            .map(sector => {
              sector.virgin_product_id = this.virginProduct.id;
              return sector.save();
            })
        )
          .subscribe(null, null, () => this.saved.emit())
        ;

      } else {
        this.saved.emit();
      }
    });
  }
}
