/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./request-sent-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/capitalize.pipe";
import * as i3 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i4 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./request-sent-dialog.component";
import * as i7 from "@angular/material";
var styles_RequestSentDialogComponent = [i0.styles];
var RenderType_RequestSentDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RequestSentDialogComponent, data: {} });
export { RenderType_RequestSentDialogComponent as RenderType_RequestSentDialogComponent };
export function View_RequestSentDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CapitalizePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DialogCloseButtonComponent_0, i3.RenderType_DialogCloseButtonComponent)), i1.ɵdid(2, 49152, null, 0, i4.DialogCloseButtonComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "request-sent-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i", [["class", "form-group__label--question request-sent-dialog__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "request-sent-dialog__message request-sent-dialog__message-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "request-sent-dialog__message--request-result"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "span", [["class", "request-sent-dialog__message request-sent-dialog__message--promise"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(13, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).applyClassPosition; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("Your request has been taken into account.")); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("we will process it as soon as possible.")))); _ck(_v, 11, 0, currVal_2); }); }
export function View_RequestSentDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-request-sent-dialog", [], null, null, null, View_RequestSentDialogComponent_0, RenderType_RequestSentDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.RequestSentDialogComponent, [i7.MdDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RequestSentDialogComponentNgFactory = i1.ɵccf("app-request-sent-dialog", i6.RequestSentDialogComponent, View_RequestSentDialogComponent_Host_0, {}, {}, []);
export { RequestSentDialogComponentNgFactory as RequestSentDialogComponentNgFactory };
