/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Input, OnDestroy, OnInit, HostListener, ElementRef } from '@angular/core';
import { Client } from '../../../client/model/client.class';
import { ClientService } from '../../../client/service/client.service';
import { MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'create-client',
  templateUrl: 'create-client.component.html'
})
export class CreateClientDialogComponent implements OnInit, OnDestroy {

  public clientId: number;
  private client: Client;
  private clientSaved = false;
  showDialog = false;



  constructor(private clientService: ClientService,
              private _dialog: MdDialogRef<any>,private eleRef: ElementRef) {
  }

  @Input() name: string;

  ngOnInit() {
    const name = this._dialog._containerInstance.dialogConfig.data.name;
    if (name) {
      const client = new Client({name});
      client.build().save().subscribe(
        value => {
          this.clientId = value.id;
          client.set(value);
          this.client = client;
          this.showDialog = true;
        },
        err => console.log(err)
      );
    } else {
      this.showDialog = true;
    }
  }

  onSave(client: Client) {
    if (client instanceof Client) {
      this.client = client;
      console.log(client + 'this is the recipient ');
    } else {
      this.client = new Client(client);
    }
    this._dialog.close(this.client);
    this.clientSaved = true ;
  }

  ngOnDestroy() {
    console.log('CreateClientDialogComponent -> onDestroy');
   // this._dialog.close();
    if (!this.clientSaved) {
      console.log('clicked outsideyooww');
      this._dialog._containerInstance.dialogConfig.data.need.dropClient();
      this.clientService.remove(this.client.id).subscribe();
      this._dialog._containerInstance.dialogConfig.data.need.setClient(new Client({name: name}));
      console.log('undo everything') ;
     // this.clientSaved = false ;
    }
    else this.clientSaved = false
   //console.log('the client created ' + this.client.id);


  }





}
