<article class="ecosystem-dashboard type--simple" test-id="deposit-owner-dashboard">
  <main class="ecosystem-dashboard__content">
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--counters">
      <ul class="ecosystem-dashboard__counter-list">
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{newDeposit}}</div>
          <span class="ecosystem-dashboard__counter-title">{{ 'New deposits' | translate }}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{newOrders}}</div>
          <span class="ecosystem-dashboard__counter-title">{{ 'New orders' | translate }}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{depositsInEcosystem}}</div>
          <span class="ecosystem-dashboard__counter-title">{{ 'Deposits in Ecosystems' | translate }}</span>
        </li>
      </ul>
    </section>
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--auto">
      <ul class="ecosystem-catalog__content-list  type--single type--double">
        <li class="ecosystem-catalog__content-item">
          <do-deposits-catalog></do-deposits-catalog>
        </li>
        <li class="ecosystem-catalog__content-item">
          <do-orders-catalog></do-orders-catalog>
        </li>
      </ul>
    </section>
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--tabs">
      <do-catalog></do-catalog>
    </section>
  </main>
</article>
