import { NgZone } from '@angular/core';
import { LogNotificationService } from '../../../shared/services/log-notification.service';
import { ValidationErrorsToMessage } from '../pipes/validation-errors-to-message.pipe';
import { getPassportValidationFieldValue, PASSPORT_CONTROLS_WITH_VALIDATION } from '../values/passport-validation-fields.const';
var PassportValidationNotificationService = /** @class */ (function () {
    function PassportValidationNotificationService(notification, _validationMessage, zone) {
        this.notification = notification;
        this._validationMessage = _validationMessage;
        this.zone = zone;
    }
    Object.defineProperty(PassportValidationNotificationService.prototype, "passportForm", {
        set: function (passportForm) {
            this.childPassportForm = passportForm;
        },
        enumerable: true,
        configurable: true
    });
    PassportValidationNotificationService.prototype.clearPassportForm = function () {
        this.childPassportForm = null;
    };
    PassportValidationNotificationService.prototype.showNotificationErrors = function (validator) {
        var _this = this;
        if (validator.invalid) {
            var errors_1 = this.getMessages(validator);
            console.log(errors_1);
            this.zone.run(function () { return _this.notification.error(_this._validationMessage.transform(errors_1)); });
            return true;
        }
        else {
            return false;
        }
    };
    PassportValidationNotificationService.prototype.showChildNotificationErrors = function () {
        if (this.childPassportForm) {
            return this.showNotificationErrors(this.childPassportForm);
        }
        else {
            console.warn('child component have not subscribed to validation notification service');
            return false;
        }
    };
    PassportValidationNotificationService.prototype.getMessages = function (passportForm) {
        var invalidControls = this.getInvalidPassportControls(passportForm);
        return invalidControls.map(function (field) {
            console.log(field);
            var label = getPassportValidationFieldValue(field);
            return !!label ? label : '';
        });
    };
    PassportValidationNotificationService.prototype.getInvalidPassportControls = function (passportForm) {
        var invalid = [];
        var controls = passportForm['controls'];
        for (var field in controls) {
            if (controls[field].invalid) {
                console.log(field);
                if (field === PASSPORT_CONTROLS_WITH_VALIDATION.COMPONENTS) {
                    this.getInvalidFieldOfFormGroupsInFormArray(controls[field])
                        .forEach(function (errorField) { return invalid.push(errorField); });
                }
                else {
                    if (field === PASSPORT_CONTROLS_WITH_VALIDATION.SOCIAL_PERFORMANCE) {
                        this.getInvalidFieldOfFormGroup(controls[field])
                            .forEach(function (errorField) { return invalid.push(errorField); });
                    }
                    else {
                        invalid.push(field);
                    }
                }
            }
        }
        return invalid;
    };
    PassportValidationNotificationService.prototype.getInvalidFieldOfFormGroupsInFormArray = function (formArray) {
        var _this = this;
        var invalidFields = new Set();
        formArray.controls.forEach(function (group) {
            if (group.invalid) {
                invalidFields = _this.getInvalidFieldOfFormGroup(group, invalidFields);
            }
        });
        return invalidFields;
    };
    PassportValidationNotificationService.prototype.getInvalidFieldOfFormGroup = function (formGroup, invalidFields) {
        invalidFields = invalidFields || new Set();
        Object.keys(formGroup.controls).filter(function (controllerName) { return formGroup.controls[controllerName].invalid; })
            .forEach(function (controllerName) {
            if (!(controllerName in invalidFields)) {
                invalidFields.add(controllerName);
            }
        });
        return invalidFields;
    };
    return PassportValidationNotificationService;
}());
export { PassportValidationNotificationService };
