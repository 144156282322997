import {TransformType, TransformTypeIncludes} from '../../../entities/transformation/models/transform-type.interface';

  export enum TRANSFORM_TYPE {
  SORTING = 1,
  UPCYCLING = 2,
  RECYCLING = 3,
  TRANSPORT = 4,
  CONTROL = 5,
  STORAGE = 6,
  OTHER = 7,
  METHANIZATION = 8,
  COMPOSTING = 9,
  REMOVAL = 10,
  REPAIR = 11,
}
export const TRANSFORM_CONVERNED_FOR_CALCULATIONS  = [
  TRANSFORM_TYPE.UPCYCLING,
  TRANSFORM_TYPE.SORTING,
  TRANSFORM_TYPE.COMPOSTING,
  TRANSFORM_TYPE.METHANIZATION,
  TRANSFORM_TYPE.TRANSPORT,
];
export const TRANSFORM_WITH_WATER_CONSUMPTION = [
  TRANSFORM_TYPE.UPCYCLING,
  TRANSFORM_TYPE.SORTING,
  TRANSFORM_TYPE.COMPOSTING,
  TRANSFORM_TYPE.METHANIZATION
];
const TRANSFORM_TYPE_INCLUDES: {[key: string]: TransformTypeIncludes} = {
  REPAIR: {
    conditions: true,
    concerned: true,
    geoServiceArea: true,
    serviceArea: true,
  },
  SORTING: {
    incomeOutcome: true,
    storingSites: true,
    takeBack: true,
    quantities: true,
    transformationDescription: true
  },
  TRANSPORT: {
    acceptedMaterials: true,
    serviceArea: true,
    conditions: true,
    quantities: true,
    transportedDetails: true,
    co2_generated: true
  }
};
export const getTransformTypeLcView = (type: TRANSFORM_TYPE): string => {
  if (!type) {
    return '';
  }
  const transformType = TRANSFORM_TYPES.find(transform => transform.value === type);
  return !!transformType ? transformType.view.toLowerCase().split(' ')[0] : '';
};


export const TRANSFORM_TYPES_WITH_SITES: TransformType[] = [
  {
    value: TRANSFORM_TYPE.SORTING,
    view: 'Sorting',
    iconUrl: 'assets/png/transform/sorting.png',
    includes: TRANSFORM_TYPE_INCLUDES.SORTING
  },
  {
    value: TRANSFORM_TYPE.UPCYCLING,
    view: 'Upcycling / recycling',
    iconUrl: 'assets/png/transform/upcycling.png',
    includes: TRANSFORM_TYPE_INCLUDES.SORTING
  },
  {
    value: TRANSFORM_TYPE.METHANIZATION,
    view: 'Methanization',
    iconUrl: 'assets/png/transform/methanization.png',
    includes: TRANSFORM_TYPE_INCLUDES.SORTING
  },
  {
    value: TRANSFORM_TYPE.COMPOSTING,
    view: 'Composting',
    iconUrl: 'assets/png/transform/composting.png',
    includes: TRANSFORM_TYPE_INCLUDES.SORTING
  },

  {
    value: TRANSFORM_TYPE.STORAGE,
    view: 'STORAGE',
    iconUrl: 'assets/png/transform/storage.png',
    includes: {
      acceptedMaterials: true,
      storingSites: true,
    }
  },
  {
    value: TRANSFORM_TYPE.OTHER,
    view: 'Other',
    iconUrl: 'assets/png/transform/other.jpg',
    includes: {
      storingSites: true
    },
  },
];
export const TRANSFORM_TYPES: TransformType[] = [
  {
    value: TRANSFORM_TYPE.SORTING,
    view: 'Sorting',
    iconUrl: 'assets/png/transform/sorting.png',
    includes: TRANSFORM_TYPE_INCLUDES.SORTING
  },
  {
    value: TRANSFORM_TYPE.UPCYCLING,
    view: 'Upcycling / recycling',
    iconUrl: 'assets/png/transform/upcycling.png',
    includes: TRANSFORM_TYPE_INCLUDES.SORTING
  },
  {
    value: TRANSFORM_TYPE.TRANSPORT,
    view: 'Transport / collection',
    iconUrl: 'assets/png/transform/transport.png',
    includes: TRANSFORM_TYPE_INCLUDES.TRANSPORT
  },
  {
    value: TRANSFORM_TYPE.CONTROL,
    view: 'Control',
    iconUrl: 'assets/png/transform/controlling.png',
    includes: {
      acceptedMaterials: true,
      serviceArea: true,
      controlType: true,
    }
  },
  {
    value: TRANSFORM_TYPE.METHANIZATION,
    view: 'Methanization',
    iconUrl: 'assets/png/transform/methanization.png',
    includes: TRANSFORM_TYPE_INCLUDES.SORTING
  },
  {
    value: TRANSFORM_TYPE.COMPOSTING,
    view: 'Composting',
    iconUrl: 'assets/png/transform/composting.png',
    includes: TRANSFORM_TYPE_INCLUDES.SORTING
  },

  {
    value: TRANSFORM_TYPE.STORAGE,
    view: 'STORAGE',
    iconUrl: 'assets/png/transform/storage.png',
    includes: {
      acceptedMaterials: true,
      storingSites: true,
    }
  },
  {
    value: TRANSFORM_TYPE.REMOVAL,
    view: 'Removal / disassembling',
    iconUrl: 'assets/png/transform/removal.png',
    includes: TRANSFORM_TYPE_INCLUDES.REPAIR
  },
  {
    value: TRANSFORM_TYPE.REPAIR,
    view: 'Repair',
    iconUrl: 'assets/png/transform/repair.png',
    includes: TRANSFORM_TYPE_INCLUDES.REPAIR
  },
  {
    value: TRANSFORM_TYPE.OTHER,
    view: 'Other',
    iconUrl: 'assets/png/transform/other.jpg',
    includes: {
      storingSites: true
    },
  },
];
