/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/convert-components/conversion-field/conversion-field.component.ngfactory";
import * as i2 from "../../../shared/convert-components/conversion-field/conversion-field.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../shared/services/weightAndVolumeConversion.service";
import * as i5 from "../../../shared/services/conversionState.service";
import * as i6 from "./transform-conversion.component";
var styles_TransformConversionComponent = [];
var RenderType_TransformConversionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TransformConversionComponent, data: {} });
export { RenderType_TransformConversionComponent as RenderType_TransformConversionComponent };
export function View_TransformConversionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "conversion-field", [], null, [[null, "quantityInTonsChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("quantityInTonsChanged" === en)) {
        var pd_0 = (_co.emitQuantityValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ConversionFieldComponent_0, i1.RenderType_ConversionFieldComponent)), i0.ɵdid(1, 114688, null, 0, i2.ConversionFieldComponent, [i3.TranslateService, i4.WeightAndVolumeConversionService, i5.ConversionStateService], { identifier: [0, "identifier"], placeholder: [1, "placeholder"], volumeUnit: [2, "volumeUnit"], conversionFactor: [3, "conversionFactor"], isDisabled: [4, "isDisabled"], emptyQuantityHolder: [5, "emptyQuantityHolder"], setQuantity: [6, "setQuantity"] }, { quantityInTonsChanged: "quantityInTonsChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.identifier; var currVal_1 = _co.placeholder; var currVal_2 = _co.volumeUnitField; var currVal_3 = _co.conversionFactorField; var currVal_4 = _co.disabled; var currVal_5 = ""; var currVal_6 = _co.quantityField; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_TransformConversionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "transform-conversion", [], null, null, null, View_TransformConversionComponent_0, RenderType_TransformConversionComponent)), i0.ɵdid(1, 49152, null, 0, i6.TransformConversionComponent, [], null, null)], null, null); }
var TransformConversionComponentNgFactory = i0.ɵccf("transform-conversion", i6.TransformConversionComponent, View_TransformConversionComponent_Host_0, { placeholder: "placeholder", transform: "transform", quantity: "quantity", isInputConversion: "isInputConversion", disabled: "disabled", disabledEmptyPlaceholder: "disabledEmptyPlaceholder", defaultUnit: "defaultUnit", previousEntity: "previousEntity", previousUnit: "previousUnit", previousConversion: "previousConversion", identifier: "identifier" }, { quantityChanged: "quantityChanged" }, []);
export { TransformConversionComponentNgFactory as TransformConversionComponentNgFactory };
