/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Contact } from '../../../client/model/contact.class';
import { ContactService } from '../../../client/service/contact.service';
import { MdDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'create-contact',
  templateUrl: 'create-contact.component.html'
})
export class CreateContactDialogComponent implements OnInit, OnDestroy {

  @Input() public clientId: number;
  public contact: Contact;

  constructor(private contactService: ContactService,
              private dialog: MdDialogRef<any>,
              private _translate: TranslateService) {
  }

  ngOnInit() {
    let contact = new Contact;

    contact.client_id = this.clientId;
    this.contact = contact;

    this._translate.get('New Contact').subscribe((translation) => {
      contact.name = translation;
    });
  }

  onSave(contact: Contact) {
    console.log('onSaveEvent', contact);
    this.dialog.close(contact);
  }

  ngOnDestroy() {

  }

}
