export enum TRANSFORM_CONTROL_TYPE {
  QUALITY = 1,
  SECURITY = 2,
  CONDITION = 3,
  OTHER = 4,
}

export const TRANSFORM_CONTROL_TYPES: {
  value: number,
  view: string
}[] = [
  {
    value: TRANSFORM_CONTROL_TYPE.QUALITY,
    view: 'Quality',
  },
  {
    value: TRANSFORM_CONTROL_TYPE.SECURITY,
    view: 'Security',
  },
  {
    value: TRANSFORM_CONTROL_TYPE.CONDITION,
    view: 'Condition',
  },
  {
    value: TRANSFORM_CONTROL_TYPE.OTHER,
    view: 'Other',
  },
];

export function GET_TRANSFORM_CONTROL_TYPE_LABEL(type: number) {
  if(!type) {
    type = 1;
  }

  let label = TRANSFORM_CONTROL_TYPES.find((item) => item.value === type);
  return label ? label.view : 'Unknown';
}
