<dialog-close-button
      (click)="mdDialogRef.close()"
></dialog-close-button>
<div class="deposit-delete-warning">
  <div class="deposit-delete-warning__image">
    <img src="assets/png/warning_green.png" class="deposit-delete-warning__triangle">
    <span class="deposit-delete-warning__exclamation">!</span>
  </div>
  <div class="deposit-delete-warning__errors-wrapper">
    <p class="deposit-delete-warning__file-erors">
      {{'the following deposits could not be removed' | translate | uppercase}}
    </p>
    <p class="deposit-delete-warning__file-erors">
      {{'because they belong to an ecosystem' | translate | uppercase}} :
    </p>
  </div>
  <div class="deposit-delete-warning__row">
    <div class="deposit-delete-warning__line deposit-delete-warning__header">{{'Deposit name' | translate}}</div>
  </div>
  <ng-container *ngFor="let deposit of deposits; let even=even">
    <div [ngClass]="{'deposit-delete-warning__row':true, 'gray':even}">
        <div class="deposit-delete-warning__line">{{deposit.name}}</div>
    </div>
  </ng-container>
</div>