<div class="product-category-block">
  <h2 [ngStyle]="{'color': mainColor}" class="product-category-block__title" [innerHTML]="title"></h2>
  <section class="product-category-block__chart">
    <pie-chart
    [mainColor]="mainColor"
    [mainValue]="data.mainValue"
    [pieChartSize]="pieChartSize.small"
    >
    </pie-chart>
    <div class="product-category-block__chart--right-block-first-content">
      <div class="flex">
        <p [ngStyle]="{'background-color': mainColor}" class="product-category-block__chart--right-block-first-content-value">
          {{data.percentage_of_recycled_material | number: numberFormat(data.percentage_of_recycled_material, '1.0-0', '1.2-2'):'fr' | decimalSeparator:','}}%
        </p>
        <div class="product-category-block__chart--right-block-first-content-text">
          <p>{{ 'of recycled or rapidly' | translate }}</p>
          <p>{{ 'renewable materials' | translate }}</p>
          <p>{{ 'incorporated into products' | translate }}</p>
        </div>
      </div>
      <div class="flex">
        <p [ngStyle]="{'background-color': mainColor}" class="product-category-block__chart--right-block-first-content-value">
          {{data.reused_percentage_by_weight | number: numberFormat(data.reused_percentage_by_weight, '1.0-0', '1.2-2'):'fr' | decimalSeparator:','}}%
        </p>
        <div class="product-category-block__chart--right-block-first-content-text">
          <p>{{ 'of deposits' | translate }}</p>
          <p>{{ 'resulting' | translate }}</p>
          <p>{{ 'from reuse' | translate }}</p>
        </div>
      </div>
    </div>
  </section>
  <section class="product-category-block__rating">
    <div class="product-category-block__rating--contents">
      <div class="product-category-block__rating--content">
        <div class="product-category-block__rating--content-item">
          <p [ngStyle]="data.demountability_grade == null && {'color': 'gray'}">{{ 'Disassemblability of products and deposits' | translate }}</p>
          <circularity-rating-scale
            [gradeValue]="data.demountability_grade"
            [ratingPointsAmount]="6"
            [mainColor]="mainColor"
            *ngIf="data.demountability_grade != null"
          >
          </circularity-rating-scale>
          <div class="product-category-block__rating--missing-data" *ngIf="data.demountability_grade == null">
            <i class="icon icon--exclamation-point-red"></i>&nbsp;<span>{{ 'Missing data' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="product-category-block__rating--content">
        <div class="product-category-block__rating--content-item">
          <p>{{ 'Identifcation and potential of future uses' | translate }}</p>
          <circularity-rating-scale
            [gradeValue]="data.next_usage_potential_grade"
            [ratingPointsAmount]="6"
            [mainColor]="mainColor"
          >
          </circularity-rating-scale>
        </div>
      </div>
      <div class="product-category-block__rating--content">
        <div class="product-category-block__rating--content-item">
          <p>{{ 'Presence and reliability of information' | translate }}</p>
          <circularity-rating-scale
            [gradeValue]="data.information_characterization_and_rating_grade"
            [ratingPointsAmount]="6"
            [mainColor]="mainColor"
          >
          </circularity-rating-scale>
        </div>
      </div>
    </div>
  </section>
  <section class="product-category-block__footer">
    <div class="product-category-block__footer--contents">
      <h3 [innerHTML]="data?.titleForFooter | translate"></h3>
      <p>{{data.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}} {{ 'tons' | translate }}</p>
      <p>{{data.percentage_by_weight | number: numberFormat(data.percentage_by_weight):'fr' | decimalSeparator:','}}% {{ 'of the total weight' | translate }}</p>
    </div>
  </section>
</div>
