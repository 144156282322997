import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import * as OldApi from './services/api.service';
import { NeedNavbarComponent } from './common-navbar/common-navbar.component';
import { CommonSidebarComponent } from './common-sidebar/common-sidebar.component';
import { CustomSearchComponent } from './custom-search/custom-search.component';
import { SuggestibleTextInputComponent } from './suggestible-text-input/suggestible-text-input.component';
import { LogoUploaderComponent } from './uploaders/logo-uploader/logo-uploader.component';
import { DocumentsUploaderComponent } from './uploaders/documents-uploader/documents-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { Ng2DatetimePickerModule } from 'ng2-datetime-picker';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ValidationService } from './services/validation-service';
import { AgmCoreModule } from '@agm/core';
import { GoogleConfig } from './config/env.config';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ApiConfigService, ApiService, IApiConfig } from 'ng2-adn-common';
import { TimeLeftTextPipe } from './pipes/time-left-text.pipe';
import { Ng2PaginationModule } from 'ng2-pagination';
import { Ng2DragDropModule } from 'ng2-drag-drop';
import { StationaryClickDirective } from './directives/stationary-click.directive';
import { CustomListComponent } from './custom-list/custom-list.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DialogCloseButtonComponent } from './dialog-close-button/dialog-close-button.component';
import { ReadOnlyModule } from './read-only/read-only.module';
import { PlaceAutocompleteModule } from './place-autocomplete/place-autocomplete.module';
import { ChatModule } from './chat/chat.module';
import { GroupByPipe } from './pipes/group-by.pipe';
import { DocumentsInlineUploaderComponent } from './uploaders/documents-inline-uploader/documents-inline-uploader.component';
import { LogNotificationService } from './services/log-notification.service';
import { NotificationService } from './notification/notification.service';
import { NotificationEntryComponent } from './notification/components/notification-entry/notification-entry.component';
import { NotificationWrapperComponent } from './notification/components/notification-wrapper/notification-wrapper.component';
import { NotificationListComponent } from './notification/components/notification-list/notification-list.component';
import { UserService } from './services/user.service';
import { LoginGuard } from './auth/login.guard';
import { NavigationService } from './services/navigation.service';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { GuestGuard } from './auth/guest.guard';
import { AuthResolver } from './auth/auth.resolver';
import { PlatformGuard } from './auth/platform.guard';
import { MapViewComponent } from './map-view/map-view.component';
import { ProfileComponent } from './profile/profile.component';
import { AvatarUploaderComponent } from './uploaders/avatar-uploader/avatar-uploader.component';
import { GoogleChartComponent } from './charts/google-chart.component';
import { CustomTranslateModule } from './custom-translate/custom-translate.module';
import { LocaleService } from './services/locale-service';
import {LocalizedDatePipe} from './pipes/localize.pipe';
import {DateTimePickerTranslationService} from './datetime-translation/datetime-translation';
import {ConfirmC2cDialogComponent} from '../entities/passport/dialogs/confirm-c2c-dialog/confirm-c2c-dialog.component';
import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';
import {AuthService} from './services/auth.service';
import {StyleLoaderService} from './services/style-loader.service';
import { AgmJsMarkerClustererModule, ClusterManager } from '@agm/js-marker-clusterer';
import { AgmMarkerSpiderModule } from 'agm-oms';
import { ToggleIndependent } from './toggle-independent/toggle-independent.component';
import { DecimalSeparator } from './pipes/decimalSeparator';
import { SearchPipe } from './pipes/search.pipe';
import { LanguageSelectComponent } from './language-selector/language-select.component';
import { BIMService } from './services/bim.service';
import { FilterStateService } from './services/filterState.service';
import { NavbarStateService } from './services/navbar.service';
import { FilterStateOfUseComponent } from '../entities/deposit/partials/deposit-list/filter-state-of-use/filter-state-of-use.component';
import { DepositItemComponent } from '../entities/deposit/partials/deposit-list/deposit-item/deposit-item.component';
import { DepositCheckableItemComponent } from '../entities/deposit/partials/deposit-list/deposit-checkable-item/deposit-checkable-item.component';
import { DepositMapMarkersService } from './services/deposit-map-marker.service.class';
import { DepositNeedMapMarkersService } from './services/deposit-need-map-marker.service.class';
import { SpinnerDialogComponent } from './spinner/dialogs/spinner-dialog/spinner-dialog.component';
import { LoadingSpinnerService } from './services/loading-spinner.service';
import { TimeSinceExpire } from './pipes/time-since-expire.pipe';
import { CustomListDateAvailabilityService } from './services/custom-list-date-availability.service';
import {PermissionService} from './services/permission.service';
import { TextareaAutocompletePolyfillDirective } from './directives/textarea-autocomplete-polyfill.directive';
import { ReadonlyWithoutFocusDirective } from './directives/readonly-without-focus.directive';
import {PaginationUnmarshallingService} from './services/pagination-unmarshalling.service';
import { ConfirmWithHeaderComponent } from './confirm-dialog/confirm-with-header-dialog/confirm-with-header.component';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { DocumentItemComponent } from './uploaders/documents-uploader/document-item/document-item.component';
import { IeNotificationComponent } from './ie-notification/ie-notification.component';
import {CustomSelectionListWithChipComponent} from './custom-selection-list-with-chip/custom-selection-list-with-chip.component';
import { ConvertButtonComponent } from './convert-components/convert-button/convert-button.component';
import { ConversionStateService } from './services/conversionState.service';
import { WeightAndVolumeConversionService } from './services/weightAndVolumeConversion.service';
import { ConversionFieldComponent } from './convert-components/conversion-field/conversion-field.component';
import {ConfirmOrderDialogService} from './services/confirm-order-dialog.service';
import { NotificationAsWideDialogContentDirective } from './directives/notification-as-wide-dialog-content.directive';
import {
  OrderTransactionConfirmResultFieldComponent
} from './convert-components/order-transaction-confirm-result-field/order-transaction-confirm-result-field.component';
import { ReportProblemDialogComponent } from './report-problem-dialog/report-problem-dialog.component';
import { NotificationSuccessComponent } from './notification-dialogs/notification-success/notification-success.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import { ConfirmDialogIconComponent } from './confirm-dialog/components/confirm-dialog-icon/confirm-dialog-icon.component';
import { NotificationWarningDialogComponent } from './notification-dialogs/notification-warning/notification-warning-dialog.component';
import { CommonInfoSignComponent } from './common-info-sign/common-info-sign.component';
import { CustomTemplateTooltipDirective } from './directives/custom-template-tooltip.directive';
import { MockApiService } from './services/mock/mock-api.service';
import {LoaderComponent} from '../entities/spinner/LoaderComponent';
/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */

const SHARED_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  FileUploadModule,
  Ng2DatetimePickerModule,
  Ng2DragDropModule,
  PlaceAutocompleteModule,
  CustomMaterialModule,
  ChatModule,
  Ng2PaginationModule,
  ReadOnlyModule,
  CustomTranslateModule,
  // NguiInfiniteListModule, //Provide a runtime error
];

const SHARED_COMPONENTS = [
  IeNotificationComponent,
  NeedNavbarComponent,
  CommonSidebarComponent,
  LogoUploaderComponent,
  DocumentsUploaderComponent,
  DocumentsInlineUploaderComponent,
  CustomSearchComponent,
  CustomListComponent,
  SuggestibleTextInputComponent,
  DialogCloseButtonComponent,
  ConfirmC2cDialogComponent,
  ConfirmDialogComponent,
  AlertDialogComponent,
  MapViewComponent,
  StationaryClickDirective,
  ProfileComponent,
  AvatarUploaderComponent,
  GoogleChartComponent,
  ToggleIndependent,
  LanguageSelectComponent,
  FilterStateOfUseComponent,
  DepositItemComponent,
  DepositCheckableItemComponent,
  SpinnerDialogComponent,
  ReadonlyWithoutFocusDirective,
  SpinnerDialogComponent,
  TextareaAutocompletePolyfillDirective,
  NotificationAsWideDialogContentDirective,
  CustomTooltipDirective,
  DocumentItemComponent,
  ConfirmWithHeaderComponent,
  CustomSelectionListWithChipComponent,
  ConvertButtonComponent,
  ConversionFieldComponent,
  OrderTransactionConfirmResultFieldComponent,
  ReportProblemDialogComponent,
  ConfirmDialogIconComponent,
  NotificationSuccessComponent,
  CommonInfoSignComponent,
  NotificationSuccessComponent,
  CustomTemplateTooltipDirective
];

const SHARED_PIPES = [
  CapitalizePipe,
  TimeLeftTextPipe,
  TimeSinceExpire,
  GroupByPipe,
  LocalizedDatePipe,
  DecimalSeparator,
  SearchPipe,
];

@NgModule({
  imports: [
    RouterModule,
    AgmJsMarkerClustererModule,
    AngularSvgIconModule,
    CommonModule,
    ...SHARED_MODULES,
    AgmCoreModule.forRoot({
      apiKey: GoogleConfig.API_KEY,
      libraries: ['places']
    }),
    AgmMarkerSpiderModule,
  ],
  declarations: [
    ...SHARED_COMPONENTS,
    ...SHARED_PIPES,
    NotificationWrapperComponent,
    NotificationListComponent,
    NotificationEntryComponent,
    ConfirmDialogIconComponent,
    NotificationWarningDialogComponent,
    LoaderComponent
  ],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_PIPES,
    AgmCoreModule,
    PerfectScrollbarModule,
    LoaderComponent
  ],
  providers: [
    DatePipe,
    ClusterManager,
    TimeLeftTextPipe,
    TimeSinceExpire,
    CapitalizePipe
  ],
  entryComponents: [ConfirmDialogComponent
    , AlertDialogComponent
    , SpinnerDialogComponent
    , ReportProblemDialogComponent
    , NotificationSuccessComponent
    , NotificationWarningDialogComponent
  ]
})
export class SharedModule {
  static forRoot(ApiConfig: IApiConfig): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        OldApi.ApiService,
        ApiService,
        UserService,
        LogNotificationService,
        ValidationService,
        AuthService,
        StyleLoaderService,
        NavigationService,
        PermissionService,
        FilterStateService,
        NavbarStateService,
        DateTimePickerTranslationService,
        {
          provide: ApiConfigService,
          useValue: ApiConfig
        },
        NotificationService,
        LoginGuard,
        GuestGuard,
        PlatformGuard,
        AuthResolver,
        LocaleService,
        BIMService,
        DepositMapMarkersService,
        DepositNeedMapMarkersService,
        LoadingSpinnerService,
        CustomListDateAvailabilityService,
        PaginationUnmarshallingService,
        ConversionStateService,
        WeightAndVolumeConversionService,
        ConfirmOrderDialogService,
        MockApiService
      ]
    };
  }
}
