import * as tslib_1 from "tslib";
import { TransformService } from '../services/transform.service';
import { TRANSFORM_TYPE, TRANSFORM_TYPES } from '../../../dashboards/transformation-manager/values/transform-types.const';
import { TransformCondition } from './transform-condition.class';
import { TransformFile } from './transform-file.class';
import * as _ from 'lodash';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import { TransformLocation } from './transform-location.class';
import { TransformOrder } from '../../../dashboards/ecosystem-manager/models/transform-order.class';
import { TransformTagJunction } from './transform-tag-junction.class';
import { TransformServiceArea } from './transform-service-area.class';
import { TransformMaterial } from './transform-material.class';
import { TransformSiteJunction } from './transform-site-junction.class';
import { GET_TRANSFORM_CONTROL_TYPE_LABEL, TRANSFORM_CONTROL_TYPE } from '../../../dashboards/transformation-manager/values/transform-control-types.const';
import { TranslateService } from '@ngx-translate/core';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { PASSPORT_UNITS } from '../../passport/values/passport-units.const';
import { TransformFormatJunction } from './transform-format-junction.class';
import { TransformPackagingJunction } from './transform-packaging-junction.class';
import { TransformQualityJunction } from './transform-quality-junction.class';
import { CategoryItem } from '../../category/models/category-item.class';
import { TRANSFORM_DEPENDENCY } from '../../../dashboards/transformation-manager/values/transform-dependency.const';
import { SourceConnectorService } from '../../../../e2e/helpers/source-connector/source-connector.service';
var PASSPORT_UNIT_TYPE = SourceConnectorService.PASSPORT_UNIT_TYPE;
import lodash from 'lodash';
var Transform = /** @class */ (function (_super) {
    tslib_1.__extends(Transform, _super);
    function Transform(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this) || this;
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        _this.conditions = [];
        _this.locations = [];
        _this.materials = [];
        _this.tags = [];
        _this.formats = [];
        _this.packagings = [];
        _this.qualities = [];
        _this.files = [];
        _this.sectors = [];
        _this.sites = [];
        _this.plumb_type = ECOSYSTEM_STEP_TYPE.TRANSFORM;
        _this.orders = [];
        _this.categories = [];
        _this.transformation_categories = [];
        _this.provider = TransformService;
        _this.set(data);
        _this.conditions = _this.conditions.map(function (condition) { return new TransformCondition(condition); });
        _this.locations = _this.locations.map(function (location) { return new TransformSiteJunction(location); });
        _this.files = _this.files.map(function (file) { return new TransformFile(file); });
        _this.materials = _this.materials.map(function (material) { return new TransformMaterial(material); });
        _this.tags = _this.tags.map(function (tag) { return new TransformTagJunction(tag); });
        _this.sectors = _this.sectors.map(function (service_area) { return new TransformServiceArea(service_area); });
        _this.sites = _this.sites.map(function (site) { return new TransformLocation(site); });
        _this.orders = _this.orders.map(function (order) { return new TransformOrder(order); });
        _this.formats = _this.formats.map(function (format) { return new TransformFormatJunction(format); });
        _this.packagings = _this.packagings.map(function (packaging) { return new TransformPackagingJunction(packaging); });
        _this.qualities = _this.qualities.map(function (quality) { return new TransformQualityJunction(quality); });
        _this.categories = _this.categories.map(function (category) { return new CategoryItem(category); });
        return _this;
    }
    Transform.isWithoutUnitByType = function (transformType) {
        return TRANSFORM_TYPES.filter(function (data) { return !data.includes.incomeOutcome; }).map(function (data) { return data.value; }).slice().includes(transformType);
    };
    Transform.getConversionInUnit = function (transform, substitution, defaultUnit) {
        if (defaultUnit === void 0) { defaultUnit = PASSPORT_UNIT_TYPE.TONNE; }
        return Transform.getUnit(transform.input_unit, transform, substitution, defaultUnit);
    };
    Transform.getConversionOutUnit = function (transform, substitution, defaultUnit) {
        if (defaultUnit === void 0) { defaultUnit = PASSPORT_UNIT_TYPE.TONNE; }
        return Transform.getUnit(transform.output_unit, transform, substitution, defaultUnit);
    };
    Transform.getUnit = function (unit, transform, substitution, defaultUnit) {
        if (Transform.isWithoutUnitByType(transform.transformation_type)) {
            var isUnitValue = typeof substitution === 'number'
                && Object.values(PASSPORT_UNIT_TYPE).some(function (unitInList) { return unitInList === substitution; });
            return isUnitValue ? substitution : defaultUnit;
        }
        return unit || PASSPORT_UNIT_TYPE.KG;
    };
    Transform.transformQuantityToCustomDefault = function (input, transform, defaultConversion) {
        if (defaultConversion === void 0) { defaultConversion = Transform.TON_CONVERSION; }
        if (Transform.isTransformNumberToDefault(input, transform)) {
            return input / defaultConversion;
        }
        return input;
    };
    Transform.inverseQuantityToCustomDefault = function (input, transform, defaultConversion) {
        if (defaultConversion === void 0) { defaultConversion = Transform.TON_CONVERSION; }
        if (Transform.isTransformNumberToDefault(input, transform)) {
            return input * defaultConversion;
        }
        return input;
    };
    Transform.isTransformNumberToDefault = function (input, transform) {
        var isNumeric = function (quantity) { return typeof quantity === 'string' && !!quantity; };
        var isFiniteNumber = function (num) {
            var transformFromString = isNumeric(num) ? Number(num) : num;
            return lodash.isFinite(transformFromString) ? num : null;
        };
        return Transform.isWithoutUnitByType(transform.transformation_type) && isFiniteNumber(input) !== null;
    };
    Transform.getConversion = function (transform, conversion, defaultConversion) {
        if (defaultConversion === void 0) { defaultConversion = 1; }
        if (Transform.isWithoutUnitByType(transform.transformation_type)) {
            return defaultConversion;
        }
        return conversion;
    };
    Transform.prototype.fields = function () {
        return [
            'id',
            'status',
            'transformation_type',
            'independent',
            'name',
            'english_name',
            'description',
            'details',
            'material_chemical',
            'material_infectious',
            'material_asbesthos',
            'material_inert',
            'material_solid',
            'material_liquid',
            'material_other',
            'message',
            'controls_types',
            'controls_other',
            'conditions',
            'locations',
            'files',
            'materials',
            'tags',
            'formats',
            'packagings',
            'qualities',
            'created_at',
            'updated_at',
            'sectors',
            'sites',
            'tsm_name',
            'tsm_email',
            'tsm_avatar',
            'tsm_org',
            'user_id',
            'creator',
            'orders',
            'output_quantity',
            'input_unit',
            'output_unit',
            'in_conversion',
            'out_conversion',
            'min_quantity',
            'min_unit',
            'max_quantity',
            'max_unit',
            'co2_generated',
            'water_consumption',
            'take_back',
            'categories',
            'transformation_categories',
        ];
    };
    Object.defineProperty(Transform.prototype, "transformation_type_data", {
        get: function () {
            var transformationType = this.transformation_type === TRANSFORM_TYPE.RECYCLING
                ? TRANSFORM_TYPE.UPCYCLING
                : this.transformation_type;
            return TRANSFORM_TYPES.find(function (TYPE) { return TYPE.value === transformationType; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "inputMaterials", {
        get: function () {
            return this.materials.filter(function (material) { return material.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "outputMaterials", {
        get: function () {
            return this.materials.filter(function (material) { return !material.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "inputTags", {
        get: function () {
            return this.tags.filter(function (tag) { return tag.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "outputTags", {
        get: function () {
            return this.tags.filter(function (tag) { return !tag.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "inputFormats", {
        get: function () {
            return this.formats.filter(function (format) { return format.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "outputFormats", {
        get: function () {
            return this.formats.filter(function (format) { return !format.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "inputPackagings", {
        get: function () {
            return this.packagings.filter(function (packaging) { return packaging.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "outputPackagings", {
        get: function () {
            return this.packagings.filter(function (packaging) { return !packaging.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "inputQualities", {
        get: function () {
            return this.qualities.filter(function (quality) { return quality.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "outputQualities", {
        get: function () {
            return this.qualities.filter(function (quality) { return !quality.is_input; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "viewName", {
        get: function () {
            return this.name || this.translateService.instant('(Unnamed)');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "controlTypeView", {
        get: function () {
            if (this.controls_types === TRANSFORM_CONTROL_TYPE.OTHER) {
                return this.controls_other;
            }
            else {
                return this.translateService.instant(GET_TRANSFORM_CONTROL_TYPE_LABEL(this.controls_types));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "acceptedMaterialsViewValues", {
        get: function () {
            var acceptedMaterials = [];
            if (this.material_chemical) {
                acceptedMaterials.push(this.translateService.instant('Dangerous chemical'));
            }
            if (this.material_infectious) {
                acceptedMaterials.push(this.translateService.instant('Infectious product'));
            }
            if (this.material_asbesthos) {
                acceptedMaterials.push(this.translateService.instant('Asbesthos product'));
            }
            if (this.material_inert) {
                acceptedMaterials.push(this.translateService.instant('Inert product'));
            }
            if (this.material_solid) {
                acceptedMaterials.push(this.translateService.instant('Solid non hazardous product'));
            }
            if (this.material_liquid) {
                acceptedMaterials.push(this.translateService.instant('Liquid or wet non hazardous product'));
            }
            if (this.material_other) {
                acceptedMaterials.push(this.material_other);
            }
            return acceptedMaterials;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Transform.prototype, "acceptedMaterialsView", {
        get: function () {
            return this.acceptedMaterialsViewValues.join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Transform.prototype.includesText = function (text) {
        text = text.toLocaleLowerCase();
        var doesNameInclude = this.name && _.includes(this.name.toLocaleLowerCase(), text);
        var isInTags = this.tags.some(function (item) { return item.name.toLocaleLowerCase() === text; });
        var isInMaterials = this.materials.some(function (item) { return item.name.toLocaleLowerCase() === text; });
        var isInFormats = this.formats.some(function (item) { return item.name.toLocaleLowerCase() === text; });
        return doesNameInclude || isInTags || isInMaterials || isInFormats;
    };
    Transform.prototype.getUnitText = function (unit) {
        var foundUnit = PASSPORT_UNITS.find(function (item) { return item.id === unit; });
        return foundUnit ? this.translateService.instant(foundUnit.title) : unit;
    };
    Transform.prototype.getUnitTextWithoutTranslation = function (unit) {
        var foundUnit = PASSPORT_UNITS.find(function (item) { return item.id === unit; });
        return foundUnit ? foundUnit.title : unit;
    };
    Transform.prototype.is = function (type) {
        return this.transformation_type === type;
    };
    Transform.prototype.isIndependent = function () {
        return this.independent === TRANSFORM_DEPENDENCY.INDEPENDENT;
    };
    Transform.TON_CONVERSION = 1000;
    return Transform;
}(ExtendedActiveRecord));
export { Transform };
