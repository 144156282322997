import { Component, OnInit, EventEmitter } from '@angular/core';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
import { Deposit } from '../../../../entities/deposit/models/deposit.class';
import { LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { FormControl } from '@angular/forms';
import { MdDialogRef } from '@angular/material';
import { Searchable } from '../../../../shared/models/searchable.class';
import { DepositOwnerListService } from '../../../../dashboards/deposit-owner/services/deposit-owner-list.service';
import { DepositMapMarkersService } from '../../../../shared/services/deposit-map-marker.service.class';
//dashboards/deposit-owner/services/deposit-owner-list.service';

@Component({
  moduleId: module.id,
  selector: 'esm-deposit-dialog',
  templateUrl: 'esm-deposit-dialog.component.html',
})

/**
 * TODO Rename this
 */
export class EsmDepositDialogComponent implements OnInit {
  deposits: Deposit[];
  public mapBounds: LatLngBounds | LatLngBoundsLiteral = {north: 0, south: 0, east: 0, west: 0};

  depositOwners: Searchable[];
  // Filters
  depositOwner: number;
  search = new FormControl();
  availableDateFrom: number;
  availableDateTo: number;
  mapZoom = 8;
  initialZoomApplied: boolean;
  beforeUpdateMap = new EventEmitter<void>();
  updateMap = new EventEmitter<{ deposits: Deposit[], totalCount: number }>();
  siteIds = []; 

  constructor(private depositService: DepositService,
              private _dialog: MdDialogRef<any>,
              private depositOwnerListService: DepositOwnerListService,
              public depositMapMarkersService: DepositMapMarkersService) {
                this
      .depositOwnerListService
      .get()
      .subscribe((depositOwners: Searchable[]) => this.depositOwners = depositOwners);
  }

  ngOnInit() {
    this.getRecords();
  }

  getRecords() {
   this.beforeUpdateMap.emit();
    this.depositService
    .getWithPaginationData({
        expand: 'deposit_location,passport',
        available_from: this.availableDateFrom || null,
        available_to: this.availableDateTo || null,
        search: this.search.value || null,
        'sites[]': this.siteIds,
        supervisor:1,
        nopaginate:1
      })
      .subscribe((res) => {
        this.updateMap.emit({deposits: res.data, totalCount: 0});
      });
  }

  closeDialog() {
    this._dialog.close();
  }

  setChosenLocationsById(locationIds: number[]) {
    this.siteIds = locationIds;
    this.getRecords();
  } 
}
