import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {EConfirmDialogIcon} from '../../values/enums/confirm-dialog-icon.enum';
import {IConfirmDialogIconData} from '../../values/interface/confirm-dialog-data.interface';

@Component({
  selector: 'confirm-dialog-icon',
  templateUrl: './confirm-dialog-icon.component.html',
  styleUrls: ['./confirm-dialog-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogIconComponent implements OnInit, IConfirmDialogIconData {
  CONFIRM_DIALOG_ICON = EConfirmDialogIcon;
  @Input('iconData') set iconData(data: IConfirmDialogIconData) {
    Object.assign(this, data);
  }
  @Input() iconType: EConfirmDialogIcon;
  @Input() showIconText: string;
  @Input() showIconURL: string;
  @Input() primaryFill = true;
  @Input() iconClass = '';

  constructor() { }

  ngOnInit() {
  }

  getEndpoint(src: string) {
    return window.location.origin + '/' + src;
  }
}
