import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { PassportGtinService } from '../services/passport-gtin.service';
var PassportGtin = /** @class */ (function (_super) {
    tslib_1.__extends(PassportGtin, _super);
    function PassportGtin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = PassportGtinService;
        return _this;
    }
    PassportGtin.prototype.fields = function () {
        return [
            'id',
            'code',
            'passport_id'
        ];
    };
    return PassportGtin;
}(ActiveRecord));
export { PassportGtin };
