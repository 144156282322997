import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { GenericPassport } from '../../../models/generic-passport.class';
import { ModuleNames } from '../../../../../shared/values/module-names.const';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdDialog } from '@angular/material';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { CopyGenericPassportDialogComponent } from '../../../dialogs/copy-generic-passport-dialog.component';


@Component({
  moduleId: module.id,
  selector: 'generic-passport-item',
  templateUrl: 'generic-passport-item.component.html',
  styleUrls: ['generic-passport-item.component.scss']
})
export class GenericPassportItemComponent {

  @Input() genericPassport: GenericPassport;
  @Input() index: number;
  @Input() zoneId: number;

  @Input() parentModuleName: string;
  @Output() onOpen?: EventEmitter<any> = new EventEmitter();
  @Output() onDelete?: EventEmitter<any> = new EventEmitter();
  @Output() onCopy?: EventEmitter<any> = new EventEmitter();

  public moduleNames = ModuleNames;

  constructor(private router: Router,
    private mdDialog: MdDialog,
    private _zone: NgZone,
    private translateService: TranslateService) {

  }

  openGenericPassport(record: GenericPassport) {
    this.onOpen.emit(record);
  }


  deleteGenericPassport(record: GenericPassport) {
    if (this.onDelete) {
      this.onDelete.emit(record);
    }
  }

  copyPassport(record: GenericPassport) {
    this._zone.run(() => {
      this.mdDialog.open(CopyGenericPassportDialogComponent, {
        ...DIALOG_THEME.ALERT_PRIMARY,
        data: { genericPassport: record, zoneId: this.zoneId }
      }).afterClosed().subscribe(() => {
        this.onCopy.emit();
      });
    });
  }

  getMaterials(record: GenericPassport, fullname: boolean) {
    const max_char = 33;
    let materialLabel = '';
    if (record && record.materials && record.materials.length > 0) {
      record.materials.forEach(mat => {
        this.translateService.get(mat.name).subscribe((translation) => {
          materialLabel += translation + ', ';
        });
      });
    }
    materialLabel = materialLabel.substring(0, materialLabel.length - 2);
    //   materialLabel = materialLabel.slice(0, -1);
   // console.log(materialLabel);
    if (fullname) {
      return materialLabel;
    }
    return materialLabel.length > max_char ? materialLabel.substring(0, max_char) + '..' : materialLabel;
  }
  getNameFromTags(record: GenericPassport) {
    let nameLabel = '';
    record.tags.forEach(tag => {
     // console.log(tag);
      nameLabel += tag.name + ', ';
    });
    return nameLabel.substring(0, nameLabel.length - 2);;
  }
  createNew() {
    this.router.navigate(['generic-passports/new']);
  }

}
