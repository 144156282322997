<rating-filter
  class="passportlist__info-rating-filter--alternative"
  [ngClass]="classList"
  [ratingFilter]="ratingFilterView"
  [nameAlias]="ratingNameAlias"
  [aliasClass]="ratingClassAlias"
  [isSelectAllShown]="false"
  [placeholder]="'Product rating'"
  [ratingApplyRule]="ratingApplyRule"
  (ratingFilterChange)="updateRatingFilter($event)"
>
</rating-filter>
