import { ActiveRecord } from '../../../shared/models/active-record.class';
import {TransformPackagingService} from '../services/transform-packaging.service';

export class TransformPackaging extends ActiveRecord {
  protected provider = TransformPackagingService;

  public id: number;
  public name: string;

  protected fields() {
    return [
      'id',
      'name',
    ];
  }
}


