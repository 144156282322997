/**
 * Created by aleksandr on 8.08.17.
 */
import * as tslib_1 from "tslib";
import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
import { NeedService } from '../services/need.service';
var NeedResolver = /** @class */ (function (_super) {
    tslib_1.__extends(NeedResolver, _super);
    function NeedResolver(_service) {
        var _this = _super.call(this) || this;
        _this._service = _service;
        _this.query = {
            expand: 'client,client_location,client_contact,passport,passport_price,passport.category,offer,passport.gtins,from_noah'
        };
        return _this;
    }
    return NeedResolver;
}(EntityResolver));
export { NeedResolver };
