import {Component, Input, OnInit} from '@angular/core';
import {IReportResponse} from '../../../../../../interfaces/reportResponse.interface';
import {TResidualValueReport} from '../../types/residual-value-report.type';
import {IResidualValueAggregate, IResidualValueCategoryItem} from '../../types/residual-value.interface';
import { MAIN_TAB_STEPS } from '../../../../admin-reporting.const';
import { ReportBase } from '../../../report-base/report-base.class';

@Component({
  moduleId: module.id,
  selector: 'residual-value-second-chart',
  templateUrl: 'residual-value-second-chart.component.html',
  styleUrls: ['residual-value-second-chart.component.scss']
})
export class ResidualValueSecondChartComponent extends ReportBase implements OnInit {
  @Input() report: IReportResponse;
  @Input() type: TResidualValueReport;
  @Input() isPDFReport = false;
  @Input() changeTab: Function;

  public numberOfChartRows = 7;
  public graphicCanvasHeight = 464;
  public heightLimitForTwoCircles = 100;
  public heightLimitForOneCircle = 47;

  public mostPresentCategory: IResidualValueCategoryItem;
  public highestResidualValueCategory: IResidualValueCategoryItem;
  public lowerResidualValueCategory: IResidualValueCategoryItem;
  public totalValueCategory;

  get data(): IResidualValueAggregate {
    if(this.report.report_result){
      return this.report.report_result.financial_residual_value[this.type].aggregate;
    }
    return this.report[this.type].aggregate;
  }


  ngOnInit(): void {
    this.setData();
  }

  getRoundValue(value: number): number {
    return Math.round( value * 10 ) / 10;
  }
  hasCategory(): boolean {
    if(this.report.report_result){
      return !!this.report.report_result.financial_residual_value[this.type].aggregate.category;
    }
    return !!this.report[this.type].aggregate.category;
  }
  setData() {
    if (!this.hasCategory()) {
      return;
    }
    const orderByUpEconomic =  this.orderProductsBy('financial_recovery_percentage_up_if_economic');
    this.mostPresentCategory = this.orderProductsBy('weight')[0];
    this.highestResidualValueCategory = orderByUpEconomic[0];
    this.lowerResidualValueCategory = orderByUpEconomic[orderByUpEconomic.length - 1];

    this.totalValueCategory = this.getDataForMainChartColumn();
  }
  getDataForMainChartColumn() {
    return this.data ?
      {
        title: 'RESIDUAL_VALUE_REPORT.CHART_CAPTION.TOTAL',
        mainDataForChart: {
          low: this.getRoundValue(this.data.total_financial_recovery_percentage_down_if_economic),
          high: this.getRoundValue(this.data.total_financial_recovery_percentage_up_if_economic),
        },
        considered: this.data.deposit_considered,
        totalDeposits: this.data.total_number_of_deposit,
        tonsOfWeight: this.data.deposit_total_weight,
        itemNumber: 'main-dark'
      }
      : null;
  }
  getDataForChartColumns() {
    return [
      {
        title: 'RESIDUAL_VALUE_REPORT.CHART_CAPTION.PRESENT',
        mainDataForChart: {
          low: this.getRoundValue(this.mostPresentCategory.financial_recovery_percentage_down_if_economic),
          high: this.getRoundValue(this.mostPresentCategory.financial_recovery_percentage_up_if_economic)
        },
        categoryName: this.mostPresentCategory.category.name,
        weight: this.mostPresentCategory.weight,
        percentageOfTotalWeight: this.mostPresentCategory.mass_percentage,
        itemNumber: 'first'
      },
      {
        title: 'RESIDUAL_VALUE_REPORT.CHART_CAPTION.HIGHEST',
        mainDataForChart: {
          low: this.getRoundValue(this.highestResidualValueCategory.financial_recovery_percentage_down_if_economic),
          high: this.getRoundValue(this.highestResidualValueCategory.financial_recovery_percentage_up_if_economic)
        },
        categoryName: this.highestResidualValueCategory.category.name,
        weight: this.highestResidualValueCategory.weight,
        percentageOfTotalWeight: this.highestResidualValueCategory.mass_percentage,
        itemNumber: 'second'

      },
      {
        title: 'RESIDUAL_VALUE_REPORT.CHART_CAPTION.LOWEST',
        mainDataForChart: {
          low: this.getRoundValue(this.lowerResidualValueCategory.financial_recovery_percentage_down_if_economic),
          high: this.getRoundValue(this.lowerResidualValueCategory.financial_recovery_percentage_up_if_economic)
        },
        categoryName: this.lowerResidualValueCategory.category.name,
        weight: this.lowerResidualValueCategory.weight,
        percentageOfTotalWeight: this.lowerResidualValueCategory.mass_percentage,
        itemNumber: 'third'
      }
    ];
  }
  getModifiedClassName(className: string, itemNumber: string): string {
    return `${className} ${className}-${itemNumber}`;
  }
  getGraphicCanvasHeight(): string {
    return this.graphicCanvasHeight + 'px';
  }
  orderProductsBy(orderBy: string): any {
    const report = this.report.report_result ? this.report.report_result.financial_residual_value[this.type] : this.report[this.type];
    const categories = report.aggregate.category;
    if (!!categories) {
      const categoriesFiltered = { ...Object.values(categories).filter((e: any) => !!e.deposit_considered) };
      return Object.values(categoriesFiltered).sort((a, b) => a[orderBy] < b[orderBy] ? 1 : (b[orderBy] < a[orderBy] ? -1 : 0));
    }
  }
  getOnePointVerticalAxisValues() {
    const maxPointForVerticalAxisValues = Math.max.apply(Math, [
      this.highestResidualValueCategory.financial_recovery_percentage_up_if_economic,
      this.data.total_financial_recovery_percentage_up_if_economic
    ]);
    return Math.ceil(maxPointForVerticalAxisValues / this.numberOfChartRows);
  }
  getNumberOfPixelsInOneRowVerticalAxis(): number {
    return this.graphicCanvasHeight / this.numberOfChartRows;
  }

  numberOfHrMarginTopValues(): any {
    return new Array(this.numberOfChartRows).fill((this.getNumberOfPixelsInOneRowVerticalAxis() - 1) + 'px');
  }
  getVerticalAxisValues(): any {
    let pointChartValue = 0;
    const pointChartValues = [];
    for (let i = 0; i <= this.numberOfChartRows; i++) {
      pointChartValues.push(pointChartValue);
      pointChartValue += this.getOnePointVerticalAxisValues();
    }
    return pointChartValues;
  }
  getAmountForOneChartRowInPixel(): number {
    return this.graphicCanvasHeight / (this.getOnePointVerticalAxisValues() * this.numberOfChartRows);
  }
  getAmountForPillarInPixels(maxValue: number, minValue: number): string {
    return (this.getAmountForOneChartRowInPixel() * (maxValue - minValue)) + 'px';
  }
  getBottomForPillarInPixels(max: number, min: number): string {
    const bottomForPillar = this.getAmountForOneChartRowInPixel() * min;
    const heightLimitForTwoCircles = this.graphicCanvasHeight - this.heightLimitForTwoCircles;
    const heightLimitForOneCircle = this.graphicCanvasHeight - this.heightLimitForOneCircle;

    if (bottomForPillar > heightLimitForTwoCircles) {
      return this.isMaxMoreThanMinByOnePercent(max, min)
        ? (heightLimitForTwoCircles) + 'px'
        : (heightLimitForOneCircle) + 'px';
    } else {
      return bottomForPillar + 'px';
    }
  }
  isMaxMoreThanMinByOnePercent(max: number, min: number): boolean {
    return max - min > 1;
  }
  getAverageOrMinValue(max: number, min: number): number {
    const averageValue = (max + min) / 2;
    return this.isMaxMoreThanMinByOnePercent(max, min)
      ? min
      : this.getRoundValue(averageValue);
  }
  goToArchive() {
    this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
  }
}
