import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { DepositPhoto } from '../models/deposit-photo.class';
var DepositPhotoService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositPhotoService, _super);
    function DepositPhotoService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'deposit-photos';
        _this.namespaceSingular = 'deposit-photo';
        _this.ModelClass = DepositPhoto;
        return _this;
    }
    return DepositPhotoService;
}(CrudService));
export { DepositPhotoService };
