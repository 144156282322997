import * as tslib_1 from "tslib";
import { CrudService } from './crud.service';
import { Modification } from '../models/modification.class';
var ModificationService = /** @class */ (function (_super) {
    tslib_1.__extends(ModificationService, _super);
    function ModificationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'modifications';
        _this.ModelClass = Modification;
        return _this;
    }
    return ModificationService;
}(CrudService));
export { ModificationService };
