import * as tslib_1 from "tslib";
var _a;
import { OrganisationTIds } from '../../shared/values/company-values.enum';
import { ADMIN_ESM_OPTIONS, ERoleIds } from '../../shared/values/role-ids.enum';
import { EDepositOwnerPermission } from './values/deposit-owner-permission.enum';
import { AbstractPermission } from '../../shared/models/abstract-permission.class';
var BuildingPermission = /** @class */ (function (_super) {
    tslib_1.__extends(BuildingPermission, _super);
    function BuildingPermission() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = 'Building deposit owner';
        return _this;
    }
    BuildingPermission.prototype.isValid = function (user) {
        return user.company.organisation_type === OrganisationTIds.Building
            && user.plan_id !== ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN;
    };
    BuildingPermission.id = EDepositOwnerPermission.BUILDING_DO;
    return BuildingPermission;
}(AbstractPermission));
export { BuildingPermission };
export var DO_PERMISSIONS = {
    roleId: ERoleIds.DEPOSIT_OWNER,
    permissions: (_a = {},
        _a[BuildingPermission.id] = new BuildingPermission(),
        _a),
    defaultPermissionsIdsBefore: [],
    defaultPermissionsIdsAfter: [BuildingPermission.id]
};
