import { TRANSFORM_TYPE } from '../../../transformation-manager/values/transform-types.const';
import { EcosystemStep } from '../../models/ecosystem-step.class';
import { Ecosystem } from '../../models/ecosystem.class';
import { SummaryDetailAbstract } from '../../models/summary-details.intercface';
import { ECOSYSTEM_CALC_WATER_ERRORS } from '../../values/ecosystem-calc-error.const';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { IEcosystemIndicatorReportSteps } from '../../values/ecosystem-indicator-report.const';
import { AbstractReportStrategyWithDetails } from './abstract-report-strategy.service';

export class WaterReportStrategy extends AbstractReportStrategyWithDetails {
  fileType = ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_WATER;
  unit = 'L';

  getSteps(allSteps: IEcosystemIndicatorReportSteps): IEcosystemIndicatorReportSteps {
    return {
      transforms: this.waterTransformSteps(allSteps.transforms),
      needs: allSteps.needs,
      deposits: allSteps.deposits,
    };
  }

  private waterTransformSteps(transforms: EcosystemStep[]): EcosystemStep[] {
    return transforms.filter(step => [TRANSFORM_TYPE.SORTING
      , TRANSFORM_TYPE.UPCYCLING
      , TRANSFORM_TYPE.METHANIZATION
      , TRANSFORM_TYPE.COMPOSTING].includes(step.transformation_type));
  }

  formatValue<T extends SummaryDetailAbstract>(step: T): string {
    if (Math.abs(step.water_consumption) < 1000) {
      return step.water_consumption.toPrecision(8);
    } else {
      return Math.round(step.water_consumption).toString();
    }
  }

  isDataMissing<T extends SummaryDetailAbstract>(errors: Array<any>, step: T): boolean {
    if (!errors || !Array.isArray(errors) || this.isWaterFieldNumber(step)) {
      return true;
    }
    let found = null;
    for (let i = 0; i < errors.length; i++) {
      found = ECOSYSTEM_CALC_WATER_ERRORS.find(element => element.id === errors[i]);
      if (found) {
        break;
      }
    }
    return !!found;
  }

  private isWaterFieldNumber<T extends SummaryDetailAbstract>(step: T) {
    return typeof step.water_consumption !== 'number' || Number.isNaN(step.water_consumption);
  }

  otherAdd(ecosystem: Ecosystem, value?: number) {
    this.setOtherTotal(value, (val) => ecosystem.add_other_water = val);
    return ecosystem.add_other_water;
  }

  otherSubtract(ecosystem: Ecosystem, value?: number) {
    this.setOtherTotal(value, (val) => ecosystem.subtract_other_water = val);
    return ecosystem.subtract_other_water;
  }

  otherDescription(ecosystem: Ecosystem, value?: string) {
    this.setOtherDescription(value, (val) => ecosystem.description_other_water = val);
    return ecosystem.description_other_water;
  }

  getTotalErrors(ecosystem: Ecosystem) {
    return ecosystem.water_total ? ecosystem.water_total.errors : [null];
  }
}
