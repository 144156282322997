import * as tslib_1 from "tslib";
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { LocationService } from '../service/location.service';
var ClientLocation = /** @class */ (function (_super) {
    tslib_1.__extends(ClientLocation, _super);
    function ClientLocation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = LocationService;
        return _this;
    }
    ClientLocation.prototype.fields = function () {
        return ['id', 'client', 'name', 'address', 'description', 'lat', 'lng', 'needCount'];
    };
    return ClientLocation;
}(ExtendedActiveRecord));
export { ClientLocation };
