<div class="deposit-location-filter">

    <md-chip-list class="deposit-location-filter__chip-list">
        <md-basic-chip
            class="deposit-location-filter__chip-list-item background-primary-color"
            *ngFor="let item of chosenItems"
            (focus)="false">
            <span
                class="deposit-location-filter__chip-list-item-text">
                {{item[field]}}
            </span>
            <button
                (click)="declineOfLocation(item)"
                class="deposit-location-filter__chip-list-item-delete">
            </button>
        </md-basic-chip>
    </md-chip-list>

    <suggestible-text-input
        [suggestions]="filteredItems"
        [viewProperty]="field"
        (onChange)="chooseItem($event)"
        [itemsCount]="filteredItems.length">
        <div class="deposit-location-filter__input-container">
            <i *ngIf="iconClass" [ngClass]="'icon ' + iconClass"></i>
            <md-input-container class="deposit-location-filter__input" [ngClass]="inputContainerClass">
                <input
                  mdInput
                  autocomplete="off"
                  [disabled]="disabled"
                  [placeholder]="depositLocationFilterPlaceholder"
                  [(ngModel)]="filteredString" (ngModelChange)="filterItem()"
                >
            </md-input-container>
        </div>
    </suggestible-text-input>

</div>
