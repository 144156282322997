import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 8.02.17.
 */
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { NeedFileService } from '../services/need-file.service';
var NeedFile = /** @class */ (function (_super) {
    tslib_1.__extends(NeedFile, _super);
    function NeedFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = NeedFileService;
        return _this;
    }
    NeedFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'need_id',
        ]);
    };
    return NeedFile;
}(UploadedDocument));
export { NeedFile };
