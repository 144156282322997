import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MAIN_TAB_STEPS } from '../../../../admin-reporting.const';
import { ReportBase } from '../../../report-base/report-base.class';
var ResidualValueSecondChartComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResidualValueSecondChartComponent, _super);
    function ResidualValueSecondChartComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isPDFReport = false;
        _this.numberOfChartRows = 7;
        _this.graphicCanvasHeight = 464;
        _this.heightLimitForTwoCircles = 100;
        _this.heightLimitForOneCircle = 47;
        return _this;
    }
    Object.defineProperty(ResidualValueSecondChartComponent.prototype, "data", {
        get: function () {
            if (this.report.report_result) {
                return this.report.report_result.financial_residual_value[this.type].aggregate;
            }
            return this.report[this.type].aggregate;
        },
        enumerable: true,
        configurable: true
    });
    ResidualValueSecondChartComponent.prototype.ngOnInit = function () {
        this.setData();
    };
    ResidualValueSecondChartComponent.prototype.getRoundValue = function (value) {
        return Math.round(value * 10) / 10;
    };
    ResidualValueSecondChartComponent.prototype.hasCategory = function () {
        if (this.report.report_result) {
            return !!this.report.report_result.financial_residual_value[this.type].aggregate.category;
        }
        return !!this.report[this.type].aggregate.category;
    };
    ResidualValueSecondChartComponent.prototype.setData = function () {
        if (!this.hasCategory()) {
            return;
        }
        var orderByUpEconomic = this.orderProductsBy('financial_recovery_percentage_up_if_economic');
        this.mostPresentCategory = this.orderProductsBy('weight')[0];
        this.highestResidualValueCategory = orderByUpEconomic[0];
        this.lowerResidualValueCategory = orderByUpEconomic[orderByUpEconomic.length - 1];
        this.totalValueCategory = this.getDataForMainChartColumn();
    };
    ResidualValueSecondChartComponent.prototype.getDataForMainChartColumn = function () {
        return this.data ?
            {
                title: 'RESIDUAL_VALUE_REPORT.CHART_CAPTION.TOTAL',
                mainDataForChart: {
                    low: this.getRoundValue(this.data.total_financial_recovery_percentage_down_if_economic),
                    high: this.getRoundValue(this.data.total_financial_recovery_percentage_up_if_economic),
                },
                considered: this.data.deposit_considered,
                totalDeposits: this.data.total_number_of_deposit,
                tonsOfWeight: this.data.deposit_total_weight,
                itemNumber: 'main-dark'
            }
            : null;
    };
    ResidualValueSecondChartComponent.prototype.getDataForChartColumns = function () {
        return [
            {
                title: 'RESIDUAL_VALUE_REPORT.CHART_CAPTION.PRESENT',
                mainDataForChart: {
                    low: this.getRoundValue(this.mostPresentCategory.financial_recovery_percentage_down_if_economic),
                    high: this.getRoundValue(this.mostPresentCategory.financial_recovery_percentage_up_if_economic)
                },
                categoryName: this.mostPresentCategory.category.name,
                weight: this.mostPresentCategory.weight,
                percentageOfTotalWeight: this.mostPresentCategory.mass_percentage,
                itemNumber: 'first'
            },
            {
                title: 'RESIDUAL_VALUE_REPORT.CHART_CAPTION.HIGHEST',
                mainDataForChart: {
                    low: this.getRoundValue(this.highestResidualValueCategory.financial_recovery_percentage_down_if_economic),
                    high: this.getRoundValue(this.highestResidualValueCategory.financial_recovery_percentage_up_if_economic)
                },
                categoryName: this.highestResidualValueCategory.category.name,
                weight: this.highestResidualValueCategory.weight,
                percentageOfTotalWeight: this.highestResidualValueCategory.mass_percentage,
                itemNumber: 'second'
            },
            {
                title: 'RESIDUAL_VALUE_REPORT.CHART_CAPTION.LOWEST',
                mainDataForChart: {
                    low: this.getRoundValue(this.lowerResidualValueCategory.financial_recovery_percentage_down_if_economic),
                    high: this.getRoundValue(this.lowerResidualValueCategory.financial_recovery_percentage_up_if_economic)
                },
                categoryName: this.lowerResidualValueCategory.category.name,
                weight: this.lowerResidualValueCategory.weight,
                percentageOfTotalWeight: this.lowerResidualValueCategory.mass_percentage,
                itemNumber: 'third'
            }
        ];
    };
    ResidualValueSecondChartComponent.prototype.getModifiedClassName = function (className, itemNumber) {
        return className + " " + className + "-" + itemNumber;
    };
    ResidualValueSecondChartComponent.prototype.getGraphicCanvasHeight = function () {
        return this.graphicCanvasHeight + 'px';
    };
    ResidualValueSecondChartComponent.prototype.orderProductsBy = function (orderBy) {
        var report = this.report.report_result ? this.report.report_result.financial_residual_value[this.type] : this.report[this.type];
        var categories = report.aggregate.category;
        if (!!categories) {
            var categoriesFiltered = tslib_1.__assign({}, Object.values(categories).filter(function (e) { return !!e.deposit_considered; }));
            return Object.values(categoriesFiltered).sort(function (a, b) { return a[orderBy] < b[orderBy] ? 1 : (b[orderBy] < a[orderBy] ? -1 : 0); });
        }
    };
    ResidualValueSecondChartComponent.prototype.getOnePointVerticalAxisValues = function () {
        var maxPointForVerticalAxisValues = Math.max.apply(Math, [
            this.highestResidualValueCategory.financial_recovery_percentage_up_if_economic,
            this.data.total_financial_recovery_percentage_up_if_economic
        ]);
        return Math.ceil(maxPointForVerticalAxisValues / this.numberOfChartRows);
    };
    ResidualValueSecondChartComponent.prototype.getNumberOfPixelsInOneRowVerticalAxis = function () {
        return this.graphicCanvasHeight / this.numberOfChartRows;
    };
    ResidualValueSecondChartComponent.prototype.numberOfHrMarginTopValues = function () {
        return new Array(this.numberOfChartRows).fill((this.getNumberOfPixelsInOneRowVerticalAxis() - 1) + 'px');
    };
    ResidualValueSecondChartComponent.prototype.getVerticalAxisValues = function () {
        var pointChartValue = 0;
        var pointChartValues = [];
        for (var i = 0; i <= this.numberOfChartRows; i++) {
            pointChartValues.push(pointChartValue);
            pointChartValue += this.getOnePointVerticalAxisValues();
        }
        return pointChartValues;
    };
    ResidualValueSecondChartComponent.prototype.getAmountForOneChartRowInPixel = function () {
        return this.graphicCanvasHeight / (this.getOnePointVerticalAxisValues() * this.numberOfChartRows);
    };
    ResidualValueSecondChartComponent.prototype.getAmountForPillarInPixels = function (maxValue, minValue) {
        return (this.getAmountForOneChartRowInPixel() * (maxValue - minValue)) + 'px';
    };
    ResidualValueSecondChartComponent.prototype.getBottomForPillarInPixels = function (max, min) {
        var bottomForPillar = this.getAmountForOneChartRowInPixel() * min;
        var heightLimitForTwoCircles = this.graphicCanvasHeight - this.heightLimitForTwoCircles;
        var heightLimitForOneCircle = this.graphicCanvasHeight - this.heightLimitForOneCircle;
        if (bottomForPillar > heightLimitForTwoCircles) {
            return this.isMaxMoreThanMinByOnePercent(max, min)
                ? (heightLimitForTwoCircles) + 'px'
                : (heightLimitForOneCircle) + 'px';
        }
        else {
            return bottomForPillar + 'px';
        }
    };
    ResidualValueSecondChartComponent.prototype.isMaxMoreThanMinByOnePercent = function (max, min) {
        return max - min > 1;
    };
    ResidualValueSecondChartComponent.prototype.getAverageOrMinValue = function (max, min) {
        var averageValue = (max + min) / 2;
        return this.isMaxMoreThanMinByOnePercent(max, min)
            ? min
            : this.getRoundValue(averageValue);
    };
    ResidualValueSecondChartComponent.prototype.goToArchive = function () {
        this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
    };
    return ResidualValueSecondChartComponent;
}(ReportBase));
export { ResidualValueSecondChartComponent };
