<article class="need-catalog"> 
  <div class="need-information" *ngIf="displayInformation">
    <h2 class="information-text">{{ 'NEED_DIRECTORY_INFORMATION_TEXT' | translate }}</h2>
    <div class="close-container" (click)="closeInformation()">
      <div class="leftright"></div>
      <div class="rightleft"></div>
    </div>
  </div>

  <header class="need-catalog__header">
    <ul class="need-catalog__caption-list">
      <li class="need-catalog__caption-item" (click)="changeTab(0)" [ngClass]="{'active': activeIndex === 0}">
        <span class="need-catalog__caption-title">{{ 'Recipients' | translate }}</span>
      </li>
      <li class="need-catalog__caption-item" (click)="changeTab(1)" [ngClass]="{'active': activeIndex === 1}">
        <span class="need-catalog__caption-title">{{ 'Sites' | translate }}</span>
      </li>
    </ul>
  </header>
  <section class="need-catalog__content">
    <ul class="need-catalog__content-list">
      <li class="need-catalog__content-item" *ngIf="activeIndex === 0">
        <need-recipients></need-recipients>
      </li>
      <li class="need-catalog__content-item" *ngIf="activeIndex === 1">
        <need-sites></need-sites>
      </li>             
    </ul>
  </section>
</article>