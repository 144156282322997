import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PassportSynchronizationData } from '../../../entities/passport/models/passport-synchronization-data.class';

@Component({
    selector: 'app-family-filter',
    templateUrl: './new-family-filter.component.html',
    styleUrls: ['./new-family-filter.component.scss']
})
export class NewFamilyFilterComponent implements OnInit{
    @Input() familySelected: number[] = [];
    @Input() userRole: string;
    @Input() disabled: boolean;
    @Output() familyChange = new EventEmitter<number[]>();
    family: string[];

    ngOnInit(): void {
        this.family = this.initFilters();
    }

    initFilters() {
        switch (this.userRole) {
            case 'supervisor':
            case 'sales':
            case 'agent':
                return [ 'FAMILY_VIEW_TYPE_1', 'FAMILY_VIEW_TYPE_2', 'FAMILY_VIEW_TYPE_3' ];
            default:
                return [ 'FAMILY_VIEW_TYPE_2', 'FAMILY_VIEW_TYPE_3' ];
        }
    }

    mapFamilyToKeyNumber(family: string) {
        switch (family) {
            case 'FAMILY_VIEW_TYPE_1':
                return 1;
            case 'FAMILY_VIEW_TYPE_2':
                return 2;
            case 'FAMILY_VIEW_TYPE_3':
                return 3;
            default:
                return 0;
        }
    }

    mapNumberToFamily(key: number) : string {
        switch (key) {
            case 1:
                return 'FAMILY_VIEW_TYPE_1';
            case 2:
                return 'FAMILY_VIEW_TYPE_2';
            case 3:
                return 'FAMILY_VIEW_TYPE_3';
            default:
                return '';
        }
    }

    convertFamilySelectedToKeyNumber() : string[] {
        return this.familySelected.map(family => this.mapNumberToFamily(family));
    }

    handleSelectionChange(selectedFamilies: string[]) {
        this.familyChange.emit(selectedFamilies.map(family => this.mapFamilyToKeyNumber(family)));
    }
}
