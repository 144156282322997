<section
  class="custom-list"
  [ngClass]="{'custom-list__overflow--none' : expand}"
  ngui-infinite-list
  (endVisible)="endVisible.emit()"
  (scroll)="onScroll($event)"
>
  <ul class="custom-list__records" *ngIf= "records && records.length > 0">
    <li *ngFor="let item of records"
        class="custom-list__item"
        [attr.data-primary-key]="item.primaryKey"
        [ngClass]="item.theme.cssClass">
      <div class="custom-list__data">
        <div class="custom-list__status-bar" [ngClass]="item.statusCssClass">{{item.status | json}}</div>
        <div class="custom-list__archive-status">{{item.recordStatus | lowercase |capitalize | translate}}</div>
        <ul class="custom-list__columns-list">
          <li
            *ngFor="let column of item.columns; let i = index"
            class="custom-list__columns-item"
            [attr.data-column-key]="getColumnKey(i)"
            [ngClass]="{
               'column--title': column.type === types.TITLE,
               'column--counter': column.type === types.COUNTER,
               'column--date': column.type === types.DATE,
               'column--icon': column.type === types.ICON,
               'column--list': column.type === types.LIST,
               'column--text': column.type === types.TEXT,
               'column--unit': column.type === types.UNIT,
               'column--author': column.type === types.AUTHOR,
               'column--controls': column.type === types.CONTROLS,
               'column--image': column.type === types.IMAGE
            }"
          >
            <div class="custom-list__column custom-list__column--title" *ngIf="column.type === types.TITLE">
              <h4 class="custom-list__column-label" [innerHtml]="getMultilangName(column.value)"></h4>
              <span class="custom-list__column-sub-label">{{getMultilangName(column.label) | translate}}</span>
            </div>
            
            <div class="custom-list__column custom-list__column--counter" *ngIf="column.type === types.COUNTER">
              <span class="custom-list__column--counter-label">{{column.label | translate}}</span>
              <h4 class="custom-list__column--counter-value">{{column.value}}</h4>
            </div>

            <div class="custom-list__column custom-list__column--date" *ngIf="column.type === types.DATE">
              <small class="custom-list__column--date-label">{{column.label | translate}}</small>
              <div class="custom-list__date-wrapper">
                <i class="icon icon--calendar" *ngIf="item.theme.value === theme.LIGHT"></i>
                <i class="icon icon--calendar-white" *ngIf="item.theme.value === theme.DARK"></i>
                <span class="custom-list__date-wrapper-value">{{column.value }}</span>
              </div>
            </div>

            <div class="custom-list__column custom-list__column--icon"
                 *ngIf="column.type === types.ICON">
              <i class="icon"
                 [ngClass]="'icon--list-'+column.value"
                 *ngIf="item.theme.value === theme.LIGHT">
              </i>
              <i class="icon"
                 [ngClass]="'icon--list-'+column.value+'-white'"
                 *ngIf="item.theme.value === theme.DARK">
              </i>
            </div>

            <div class="custom-list__column custom-list__column--list" *ngIf="column.type === types.LIST">
              <ul class="custom-list__sublist">
                <li class="custom-list__subitem" *ngFor="let row of column.value" [ngClass]="row?.cssClass">
                  <span class="custom-list__subkey">
                    <div class="custom-list__subkey-block">
                      <span [innerHtml]="row?.key | translate"></span>
                      {{row && row?.key ? row.seperator || ':' : ''}}
                    </div>
                  </span>
                  <span class="custom-list__subvalue"
                        [innerHtml]="row?.value | translate"
                        (click)="row.valueClickClosure ? row.valueClickClosure() : {}">
                  </span>
                </li>
              </ul>
            </div>

            <div class="custom-list__column custom-list__column--text" *ngIf="column.type === types.TEXT">
              {{column.value}}
            </div>

            <div class="custom-list__column custom-list__column--unit" *ngIf="column.type === types.UNIT">
              <ng-container *ngIf="column.value">
                <i class="icon" [ngClass]="column.value.cssClass"></i>
                <span class="custom-list__column--value-amount">&nbsp;{{column.value.amount}}</span>
                <span class="custom-list__column--value-unit">{{column.value.unit}}</span>
                <div *ngIf="column.value.status" class="custom-list__unit-status">{{column.value.status}}</div>
                <div *ngIf="column.label" class="custom-list__unit-status">{{ column.label | translate }}</div>
              </ng-container>
            </div>

            <div class="custom-list__column custom-list__column--author" *ngIf="column.type === types.AUTHOR">
              <i [ngClass]="[cloudIcon && item.independent ? 'icon icon--cloud-arrow-down' : 'icon icon--people-dark']" *ngIf="item.theme.value === theme.LIGHT"></i>
              <i [ngClass]="[cloudIcon && item.independent ? 'icon icon--cloud-arrow-down' : 'icon icon--people-white']" *ngIf="item.theme.value === theme.DARK"></i>
              <span class="custom-list__column--author-value">{{column.value}}</span>
            </div>

            <div class="custom-list__column custom-list__column--controls" *ngIf="column.type === types.CONTROLS">
              <button md-icon-button [mdMenuTriggerFor]="menu" [ngClass]="column.value.cssClass || ''">
                <md-icon>{{column.value.icon}}</md-icon>
              </button>
              <md-menu #menu="mdMenu">
                <button md-menu-item
                        *ngFor="let menuOption of column.value.options"
                        (click)="menuOption.fn()">
                  {{ menuOption.name | translate }}
                </button>
              </md-menu>
            </div>
            <div class="custom-list__column custom-list__column--image"
                 *ngIf="column.type === types.IMAGE">
              <div [ngStyle]="getAvatar(column.value)"></div>
            </div>
          </li>
        </ul>
      </div>
      <div class="custom-list__hint" [ngClass]="item.hint?.cssClass" *ngIf="item.hint?.value">{{item.hint?.value | translate}}</div>
    </li>
    <li *ngIf="records.length === 0"
        class="custom-list__item theme--light"
        [attr.data-primary-key]="0">
      <div class="custom-list__data">
        <div class="custom-list__no-records">
          No records found
        </div>
      </div>
    </li>
  </ul>
  <div ngui-infinite-list-end></div>
</section>

<footer class="esm-catalog__footer" *ngIf="expandable">
  <div class="esm-catalog__footer--info theme-color--primary"
       [ngClass]="{'esm-catalog__footer--info-loading': expandIsLoading}"
       (click)="toggleExpand()">+/-</div>
</footer>
