/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i4 from "../read-only/form-elems-hidden.directive";
import * as i5 from "../read-only/read-only.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./alert-dialog.component";
var styles_AlertDialogComponent = [];
var RenderType_AlertDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertDialogComponent, data: {} });
export { RenderType_AlertDialogComponent as RenderType_AlertDialogComponent };
function View_AlertDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h1", [["md-dialog-title", ""]], [[2, "mat-dialog-title", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MdPrefixRejector, [[2, i1.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i1.MdDialogTitle, [], null, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); }); }
function View_AlertDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "alert-dialog__content"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_AlertDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "alert-dialog"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertDialogComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertDialogComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 9, "ul", [["class", "button-group__list alert-dialog__controls"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 8, "li", [["class", "button-group__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 7, "button", [["md-button", ""], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i0.ɵdid(8, 16384, null, 0, i1.MdPrefixRejector, [[2, i1.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(9, 180224, null, 0, i1.MdButton, [i0.ElementRef, i0.Renderer, i1.FocusOriginMonitor], null, null), i0.ɵdid(10, 16384, null, 0, i1.MdButtonCssMatStyler, [], null, null), i0.ɵdid(11, 16384, null, 0, i1.MdRaisedButtonCssMatStyler, [], null, null), i0.ɵdid(12, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i0.ɵted(13, 0, ["", ""])), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 9).disabled; var currVal_3 = true; var currVal_4 = true; var currVal_5 = ((i0.ɵnov(_v, 12).readOnlyService == null) ? null : i0.ɵnov(_v, 12).readOnlyService.readOnly); _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform("Ok")); _ck(_v, 13, 0, currVal_6); }); }
export function View_AlertDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert-dialog", [], null, null, null, View_AlertDialogComponent_0, RenderType_AlertDialogComponent)), i0.ɵdid(1, 114688, null, 0, i7.AlertDialogComponent, [i1.MdDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertDialogComponentNgFactory = i0.ɵccf("alert-dialog", i7.AlertDialogComponent, View_AlertDialogComponent_Host_0, {}, {}, []);
export { AlertDialogComponentNgFactory as AlertDialogComponentNgFactory };
