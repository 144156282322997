import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MAP_IMPORT_PROGRESS_STATUS} from './report-progress.const';
import { ReportProgressService } from './report-progress.service';

@Component({
    moduleId: module.id,
    selector: 'report-progress',
    templateUrl: './report-progress.component.html',
    styleUrls: ['./report-progress.component.scss']
  })
  export class ReportProgressComponent {
    MAP_IMPORT_PROGRESS_STATUS = MAP_IMPORT_PROGRESS_STATUS;
    @Input() tables: Array<any>;

    constructor(
      public reportProgressService: ReportProgressService
    ) {
    }

    close() {
      this.reportProgressService.closeImport();
      this.reportProgressService.showImportProgress = false;
    }

    getText() {

        switch (this.reportProgressService.statusImportProgress) {
            case MAP_IMPORT_PROGRESS_STATUS.PROGRESS:
                return 'CIRCULAR_SIGNATURE_PROGRESS';
            case MAP_IMPORT_PROGRESS_STATUS.ERROR:
                return 'Error in file';
            case MAP_IMPORT_PROGRESS_STATUS.SUCCESS:
                return 'CIRCULAR_SIGNATURE_DONE';
        }
    }

    downloadZip(record: any) {
      window.open(this.reportProgressService.zipfile_link, '_blank');
    }
    get isSuccess() {
      return MAP_IMPORT_PROGRESS_STATUS.SUCCESS === this.reportProgressService.statusImportProgress;
    }

    get isError() {
      return MAP_IMPORT_PROGRESS_STATUS.ERROR === this.reportProgressService.statusImportProgress;
    }
  }

