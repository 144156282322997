import { IPeriodOfUseType } from '../models/period-of-use.interface';

export const PERIOD_OF_USE_TYPES: IPeriodOfUseType[] = [
  {
    value: 1,
    view: 'Weeks',
    momentUnit: 'weeks',
  },
  {
    value: 2,
    view: 'Month',
    momentUnit: 'months'
  },
  {
    value: 3,
    view: 'Year',
    momentUnit: 'years'
  }
];
