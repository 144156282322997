export enum EDeepStreamMessage {
  ERRORS_READY = 6,
  IMPORT_COMPLETION = 5,
  ZONE_DELETED = 7,
  ZONE_COPIED = 8,
  DEEPSTREAM_EVENT_DEPOSIT_IMPORT_RESULT = 9,
  DEPOSIT_IMPORT_PROCESS_DOC = 10,
  DEPOSIT_IMPORT_PROGRESS_ERROR = 11,
  SITE_IMPORT_PROCESS_DOC = 12,
  SITE_IMPORT_ERRORS = 13,
  SITE_IMPORT_PROGRESS = 14,
  SITE_IMPORT_PROGRESS_ERROR = 15,
  SITE_IMPORT_RESULT = 16,
  DEEPSTREAM_EVENT_REPORT_ERRORS=21,
  DEEPSTREAM_EVENT_REPORT_PROGRESS=22,
  DEEPSTREAM_EVENT_REPORT_PROGRESS_ERROR=23,
  DEEPSTREAM_EVENT_REPORT_SUCCESS=24

}
