/**
 * Created by aleksandr on 29.08.17.
 */
import * as tslib_1 from "tslib";
import { OnceBeforeRouteActivatedHook } from '../adn-common/once-before-route-activated.guard';
import { CurrentUserService } from '../services/current-user.service';
import { ServiceLocator } from '../services/service-locator';
var CheckAuthHook = /** @class */ (function (_super) {
    tslib_1.__extends(CheckAuthHook, _super);
    function CheckAuthHook(
    //private _userService: CurrentUserService
    ) {
        var _this = _super.call(this) || this;
        _this._userService = ServiceLocator.injector.get(CurrentUserService);
        console.log('Construct CheckAuthHook');
        return _this;
    }
    CheckAuthHook.prototype.beforeRouteActivated = function (route, state) {
        console.log('Launch CheckAuthHook');
        return this
            ._userService
            .isAuth()
            .do(function (isAuth) { return console.log('Launch completed CheckAuthHook -> isAuth', isAuth); });
    };
    return CheckAuthHook;
}(OnceBeforeRouteActivatedHook));
export { CheckAuthHook };
