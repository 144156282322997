<ul class="upload-files__list">
  <document-item *ngFor="let file of files; let i=index"
                 [file]="file"
                 [isRemoveFromFilesDisabled]="isRemoveFromFilesDisabled || !uploadUrl
                  || readOnlyService?.readOnly"
                 (remove)="remove(i)">
  </document-item>
  <document-item *ngFor="let item of uploader.queue; let i=index"
                 [file]="item"
                 [disableNameChangeDefault]="enableQueueFileEdit"
                 [srcImg]="item.file['previewPath']"
                 [docHref]="item.file['previewPath']"
                 [noLink]="!enableQueueFileEdit"
                 [fileName]="item.file.name"
                 [extension]="getExtensionName(item.file.name)"
                 [transformFileNameBeforeEdit]="queueNameTransform.bind(this)"
                 [isImage]="isImage(item.file.name)"
                 [isRemoveFromFilesDisabled]="isRemoveFromFilesDisabled"
                 (remove)="removeFromQueue(i)"
                 (onNameChange)="queueNameChange($event, item)"
  >
  </document-item>

  <li
    *ngIf="isDownloadEnable()"
    class="upload-files__download"
    (click)="onUploadButtonClick($event)"
  >
    <label >
      <i class="upload-files__camera camera--icon upload-files__placeholder-icon"></i>
    </label>

    <input class="upload-files__add"
           type="file"
           #fileInput
           ng2FileSelect
           [disabled]="isDownLoadDisabled"
           [uploader]="uploader"
           [multiple]="isMultiple">
  </li>
</ul>
