/**
 * Created by Aleksandr C. on 8.02.17.
 */
import * as tslib_1 from "tslib";
import { Passport } from '../../passport/models/passport.class';
import { DepositLocation } from './deposit-location.class';
import { DepositService } from '../services/deposit.service';
import { DEPOSIT_STATUSES } from '../values/deposit-statuses.const';
import { DatePipe } from '@angular/common';
import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { DEPOSIT_CONDITIONS } from '../values/deposit-conditions.const';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import { DepositPhoto } from './deposit-photo.class';
import { ValidationService } from '../../../shared/services/validation-service';
import { TranslateService } from '@ngx-translate/core';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { DEMOUNTABILITY_STATES } from '../values/demountability-states.const';
var Deposit = /** @class */ (function (_super) {
    tslib_1.__extends(Deposit, _super);
    function Deposit(data) {
        var _this_1 = _super.call(this, data) || this;
        _this_1.provider = DepositService;
        _this_1.translateService = ServiceLocator.injector.get(TranslateService);
        _this_1.passport_materials = [];
        _this_1.tags = [];
        _this_1.plumb_type = ECOSYSTEM_STEP_TYPE.DEPOSIT;
        _this_1.onChangePassport = new EventEmitter();
        _this_1.onChangeLocation = new EventEmitter();
        _this_1.progressStep1StrategyStrategy = _this_1.defaultProgressStep1Strategy;
        _this_1.progressStep2StrategyStrategy = _this_1.defaultProgressStep2Strategy;
        _this_1.progressStep3StrategyStrategy = _this_1.defaultProgressStep3Strategy;
        data = data || {};
        data.passport = data.passport || {};
        _this_1.passport = new Passport(Object.assign(data.passport || {}, {
            materials: data.passport_materials,
            components: data.passport_components,
            nomenclature: data.nomenclature,
            price: data.passport_price,
        }));
        _this_1.deposit_location = _this_1.location = new DepositLocation(data.deposit_location);
        _this_1.deposit_photos = (data.deposit_photos || []).map(function (d) { return new DepositPhoto(d); });
        _this_1.deposit_file = (data.deposit_file || []).map(function (d) { return new DepositPhoto(d); });
        _this_1.booked = _this_1.booked || 0;
        _this_1.passport_materials = data.passport_materials;
        _this_1.material_names = data.material_names;
        _this_1.tags = data.tags;
        return _this_1;
    }
    Deposit.prototype.fields = function () {
        return [
            'id',
            'name',
            'status',
            'booked',
            'condition',
            'creator_id',
            'owner_id',
            'owner_name',
            'supervisor_id',
            'passport_id',
            'passport',
            'deposit_location_id',
            'quantity',
            'in_stock',
            'availability_date',
            'availability_expiration',
            'publication_date',
            'description',
            'comments',
            'created_at',
            'updated_at',
            'active_step',
            'creator',
            'creator_email',
            'owner_email',
            'modification',
            'passport_materials',
            'passport_price',
            'material_names',
            'deposit_demountability',
            'reused',
            'image',
            'logo',
            'thumbnail',
            'deposit_source',
            'unit',
            'deposit_potential_source',
            'potential_reuse',
            'url_link',
            'product_comment',
            'length',
            'width',
            'height',
            'color',
            'price',
            'isNoah',
            'conversion',
            'deposit_file'
        ];
    };
    Object.defineProperty(Deposit.prototype, "availableQuantity", {
        get: function () {
            return this.total - (this.booked || 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Deposit.prototype, "total", {
        get: function () {
            return this.quantity;
        },
        enumerable: true,
        configurable: true
    });
    Deposit.prototype.setValidationStrategy = function (_this, step, strategy) {
        var func = strategy.bind(_this);
        switch (step) {
            case 1:
                this.progressStep1StrategyStrategy = func;
                break;
            case 2:
                this.progressStep2StrategyStrategy = func;
                break;
            case 3:
                this.progressStep3StrategyStrategy = func;
                break;
        }
    };
    Deposit.prototype.getProgress = function () {
        if (this.progressStep1StrategyStrategy()) {
            if (this.progressStep2StrategyStrategy()) {
                if (this.progressStep3StrategyStrategy()) {
                    return 3;
                }
                return 2;
            }
            return 1;
        }
        return 0;
    };
    Deposit.prototype.defaultProgressStep1Strategy = function () {
        return this.passport_id > 0 && this.name;
    };
    Deposit.prototype.defaultProgressStep2Strategy = function () {
        return this.deposit_location_id > 0;
    };
    Deposit.prototype.defaultProgressStep3Strategy = function () {
        return (this.quantity > 0 || /^\d+([,|.]?\d+)?$/g.test(String(this.quantity)))
            && this.condition && this.availability_date;
    };
    Object.defineProperty(Deposit.prototype, "statusData", {
        get: function () {
            var _this_1 = this;
            return _.values(DEPOSIT_STATUSES).find(function (STATUS) { return STATUS.value === _this_1.status; });
        },
        enumerable: true,
        configurable: true
    });
    Deposit.prototype.setPassport = function (value) {
        if (value instanceof Passport) {
            this.passport = value;
            this.passport_id = value.id;
        }
        else {
            this.passport = new Passport();
            this.passport_id = null;
        }
        this.onChangePassport.emit(this.passport);
    };
    Deposit.prototype.setLocation = function (value) {
        if (value instanceof DepositLocation) {
            this.deposit_location = value;
            this.deposit_location_id = value.id;
        }
        else {
            this.deposit_location = new DepositLocation();
            this.deposit_location_id = null;
        }
        this.onChangeLocation.emit(this.deposit_location);
    };
    Deposit.prototype.beforeSave = function (data) {
        var dataValues = _super.prototype.beforeSave.call(this, data);
        if (!!dataValues.availability_date && typeof dataValues.availability_date === 'object') {
            try {
                // dataValues.availability_date = new DatePipe(dataValues.availability_date)
                // default
                dataValues.availability_date = new DatePipe('en-US')
                    .transform(dataValues.availability_date, 'yyyy-MM-dd');
                if (!!dataValues.availability_expiration && typeof dataValues.availability_expiration === 'object') {
                    dataValues.availability_expiration = new DatePipe('en-US')
                        .transform(dataValues.availability_expiration, 'yyyy-MM-dd');
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        if (dataValues.quantity) {
            dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
        }
        // if(typeof dataValues.quantity === 'string') {
        //   let parsedQuantity = LocaleService.tryParseToNumber(dataValues.quantity);
        //   if(typeof parsedQuantity === 'number') {
        //     dataValues.quantity = parsedQuantity;
        //   }
        // }
        return dataValues;
    };
    Deposit.prototype.includesText = function (searchText) {
        searchText = searchText.toLocaleLowerCase();
        return _.includes(this.name.toLocaleLowerCase(), searchText);
    };
    Object.defineProperty(Deposit.prototype, "conditionData", {
        get: function () {
            var _this_1 = this;
            return DEPOSIT_CONDITIONS.find(function (cond) { return cond.value === _this_1.condition; }) || DEPOSIT_CONDITIONS[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Deposit.prototype, "demountabilityData", {
        get: function () {
            var _this_1 = this;
            return DEMOUNTABILITY_STATES.find(function (state) { return state.value === _this_1.deposit_demountability; }) || DEMOUNTABILITY_STATES[0];
        },
        enumerable: true,
        configurable: true
    });
    Deposit.prototype.conditionDataView = function () {
        return this.translateService.instant(this.conditionData.view);
    };
    Deposit.prototype.getConversion = function () {
        return this.conversion || this.passport.conversion;
    };
    return Deposit;
}(ExtendedActiveRecord));
export { Deposit };
