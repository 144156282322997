var _a, _b;
export var EDepositReuseSource;
(function (EDepositReuseSource) {
    EDepositReuseSource[EDepositReuseSource["NO_SOURCE"] = 0] = "NO_SOURCE";
    EDepositReuseSource[EDepositReuseSource["DESTOCKED"] = 1] = "DESTOCKED";
    EDepositReuseSource[EDepositReuseSource["RECONDITIONED"] = 2] = "RECONDITIONED";
    EDepositReuseSource[EDepositReuseSource["REUSED"] = 3] = "REUSED";
    EDepositReuseSource[EDepositReuseSource["UNKNOWN"] = 4] = "UNKNOWN";
    EDepositReuseSource[EDepositReuseSource["SURPLUS"] = 5] = "SURPLUS";
    EDepositReuseSource[EDepositReuseSource["REMOVAL"] = 6] = "REMOVAL";
})(EDepositReuseSource || (EDepositReuseSource = {}));
export var DEPOSIT_REUSE_SOURCE_VIEW = (_a = {},
    _a[EDepositReuseSource.NO_SOURCE] = 'New product',
    _a[EDepositReuseSource.DESTOCKED] = 'From destocking',
    _a[EDepositReuseSource.SURPLUS] = 'From a construction site surplus',
    _a[EDepositReuseSource.RECONDITIONED] = 'From reconditioning',
    _a[EDepositReuseSource.REUSED] = 'Re-used',
    _a[EDepositReuseSource.REMOVAL] = 'From a construction site removal',
    _a[EDepositReuseSource.UNKNOWN] = 'Other',
    _a);
export var DEPOSIT_REUSE_SOURCE_VIEW_ORDER = (_b = {},
    _b[EDepositReuseSource.NO_SOURCE] = 1,
    _b[EDepositReuseSource.DESTOCKED] = 2,
    _b[EDepositReuseSource.SURPLUS] = 3,
    _b[EDepositReuseSource.RECONDITIONED] = 4,
    _b[EDepositReuseSource.REMOVAL] = 5,
    _b[EDepositReuseSource.REUSED] = 6,
    _b[EDepositReuseSource.UNKNOWN] = 7,
    _b);
export var DEPOSIT_REUSE = [
    { value: EDepositReuseSource.NO_SOURCE, view: 'New product' },
    { value: EDepositReuseSource.DESTOCKED, view: 'From destocking' },
    { value: EDepositReuseSource.SURPLUS, view: 'From a construction site surplus' },
    { value: EDepositReuseSource.RECONDITIONED, view: 'From reconditioning' },
    { value: EDepositReuseSource.REUSED, view: 'Re-used' },
    { value: EDepositReuseSource.REMOVAL, view: 'From a construction site removal' },
    { value: EDepositReuseSource.UNKNOWN, view: 'Other' }
];
export var DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES = Object.keys(DEPOSIT_REUSE_SOURCE_VIEW)
    .filter(function (key) { return ![EDepositReuseSource.REUSED].includes(EDepositReuseSource[EDepositReuseSource[key] + '']); })
    .sort(function (previous, next) { return DEPOSIT_REUSE_SOURCE_VIEW_ORDER[previous] - DEPOSIT_REUSE_SOURCE_VIEW_ORDER[next]; })
    .map(function (key) { return ({ key: key, value: DEPOSIT_REUSE_SOURCE_VIEW[key] }); }).slice();
export var DEPOSIT_REUSE_SOURCE_SELECT_ENTRIES = Object.keys(DEPOSIT_REUSE_SOURCE_VIEW)
    .filter(function (key) { return ![EDepositReuseSource.REUSED].includes(EDepositReuseSource[EDepositReuseSource[key] + '']); })
    .sort(function (previous, next) { return DEPOSIT_REUSE_SOURCE_VIEW_ORDER[previous] - DEPOSIT_REUSE_SOURCE_VIEW_ORDER[next]; })
    .map(function (key) { return ({ key: +key, value: DEPOSIT_REUSE_SOURCE_VIEW[key] }); }).slice();
