import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Passport} from '../../../../models/passport.class';
import {Utils} from '../../../../../../shared/utils/utils.class';
import {LogNotificationService} from '../../../../../../shared/services/log-notification.service';

@Component({
  selector: 'passport-catalog-name',
  templateUrl: './passport-catalog-name.component.html'
})
export class PassportCatalogNameComponent implements OnInit {

  @Input() passport: Passport;
  @Input() showMenu: boolean;
  @Input() showCopyId = true;
  @Input() multiLanguage: boolean;

  constructor(private notification: LogNotificationService) { }

  ngOnInit() {
  }

  copyId() {
    const msg = this.passport.product_passport_id ? 'Id copied' : 'Id not yet set';
    const message = this.passport.product_passport_id ? this.notification.success : this.notification.error;
    if (this.passport.product_passport_id) {
      Utils.copyToClipBoard(this.passport.product_passport_id);
    }
    message.apply(this.notification, [msg, 2000, true]);
  }

}
