<div class="control-group">
  <ul class="passport-used-energy__item flex align-center"
      *ngFor="let control of listCtrl?.controls; let i=index;"
      [formGroup]="listCtrl.controls[i]">

    <li class="control-group__item--icon">
      <div [hidden]="this.isDisabled" class="control-group__item-button"
           (click)="removeUsedEnergy(i)">
        <md-icon>{{'remove' | translate}}</md-icon>
      </div>
    </li>

    <li class="control-group__item input-medium">
      <md-select
        formControlName="energy_id"
        class="condition"
        placeholder="{{ 'Energy used' | translate }}">
        <div
          class="md-select-optgroup"
          *ngFor="let group of usedEnergyGroups">
          <md-option class="md-select-optgroup__label" [value]="group.energy.value">
            {{group.energy.viewValue | translate}}
          </md-option>
          <md-option *ngFor="let energy of group.energies" [value]="energy.value">
            {{energy.viewValue | translate}}
          </md-option>
        </div>
<!--        <md-option *ngFor="let type of usedEnergyTypes"-->
<!--                   [value]="type.key">-->
<!--          {{ type.value | translate }}-->
<!--        </md-option>-->
      </md-select>
    </li>
    <div>&nbsp;{{'at' | translate}}&nbsp;</div>
    <li class="control-group__item control-group__item--xsmall">
      <div class="flex align-center">
        <md-input-container class="passport-used-energy__input--percentage">
          <input
            mdInput
            autocomplete="off" 
            formControlName="percentage"
            type="number"
            min="0"
            max="100"
          >
          <md-hint
            *ngIf="isPercentagesInvalid(control)"
            class="error-hint"
            align="start"
          >{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>
        </md-input-container>
      </div>
    </li>
    <div class="control-group__item--char-symbol">%</div>

    <div class="passport-used-energy__item passport-used-energy__item--add-new flex align-center"
         (click)="addNew()"
         *ngIf="i === 0">
      <div [hidden]="this.isDisabled"  class="control-group__item-button">
        <md-icon>{{'add' | translate}}</md-icon>
      </div>
      <div [hidden]="this.isDisabled" class="control-group__item--subtext">
        {{'add production energy' | translate}}
      </div>
    </div>
  </ul>

<!--  <div class="passport-used-energy__item&#45;&#45;description flex">-->
<!--      <md-input-container>-->
<!--        <textarea-->
<!--          id="usedEnergyDescription"-->
<!--          name="optimization_strategy"-->
<!--          formControlName="optimization_strategy"-->
<!--          mdInput-->
<!--          placeholder="{{ 'describe an optimization strategy' | translate }}"-->
<!--        ></textarea>-->
<!--      </md-input-container>-->
<!--  </div>-->
</div>
