import * as tslib_1 from "tslib";
import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
var TSMStats = /** @class */ (function (_super) {
    tslib_1.__extends(TSMStats, _super);
    function TSMStats() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TSMStats.prototype.fields = function () {
        return [
            'orders_accepted',
            'orders_rejected',
            'transformation_locations_assigned',
            'transformation_locations_published',
            'transformations_assigned',
            'transformations_published',
            'transformations_rejected'
        ];
    };
    TSMStats.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        Utils.convertJsonStringToNumber(json, [
            'orders_accepted',
            'orders_rejected',
            'transformation_locations_assigned',
            'transformation_locations_published',
            'transformations_assigned',
            'transformations_published',
            'transformations_rejected'
        ]);
        return json;
    };
    return TSMStats;
}(BaseModel));
export { TSMStats };
