import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformFormatJunction } from '../models/transform-format-junction.class';
var TransformFormatJunctionService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformFormatJunctionService, _super);
    function TransformFormatJunctionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-formats-junctions';
        _this.namespaceSingular = 'transformation-formats-junction';
        _this.ModelClass = TransformFormatJunction;
        return _this;
    }
    return TransformFormatJunctionService;
}(CrudService));
export { TransformFormatJunctionService };
