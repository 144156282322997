export const PASSPORT_UNITS: any[] = [
  {id: 0, title: 'kg'},
  {id: 1, title: 'ton(s)'},
  {id: 2, title: 'm²'},
  {id: 3, title: 'm³'},
  {id: 4, title: 'piece(s)'},
  {id: 5, title: 'm'},
  // {id: 4, title: 'unit'},
  {id: 7, title: 'L'},
];

export const PASSPORT_UNITS_MAP = {
  0: 'kg',
  1: 'ton(s)',
  2: 'm²',
  3: 'm³',
  4: 'piece(s)',
  5: 'm',
  // 6: 'unit',
  7: 'L',
};

export const PASSPORT_UNITS_TYPE = {
  KG: 0,
  TONNE: 1,
  M2: 2,
  M3: 3,
  PIECE: 4,
  M: 5,
  L: 7
};

export const PASSPORT_UNIT_WITHOUT_CONVERSION = [
  PASSPORT_UNITS_TYPE.KG, PASSPORT_UNITS_TYPE.TONNE
];
