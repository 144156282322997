import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformMaterialService } from '../services/transform-material.service';

export class TransformMaterial extends ActiveRecord {
  protected provider = TransformMaterialService;

  public id: number;
  public nomenclature_id: number;
  public transformation_id: number;
  public is_input: number;

  public name: string;

  protected fields() {
    return [
      'id',
      'nomenclature_id',
      'transformation_id',
      'is_input',

      'name',
    ];
  }

}


