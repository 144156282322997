import { EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConversionStateService } from '../../services/conversionState.service';
import { WeightAndVolumeConversionService } from '../../services/weightAndVolumeConversion.service';
import { PASSPORT_UNITS_MAP, PASSPORT_UNITS_TYPE } from '../../../entities/passport/values/passport-units.const';
import lodash from 'lodash';
var ConversionFieldComponent = /** @class */ (function () {
    function ConversionFieldComponent(translate, conversionService, conversionStateService) {
        this.translate = translate;
        this.conversionService = conversionService;
        this.conversionStateService = conversionStateService;
        this.PASSPORT_UNIT_MAP = PASSPORT_UNITS_MAP;
        this.emptyQuantityHolder = 'unlimited';
        this.defaultUnit = PASSPORT_UNITS_TYPE.KG;
        this.isConvertOnWriteIfOffConversion = false;
        this.quantityChanged = new EventEmitter();
        this.quantityInTonsChanged = new EventEmitter();
    }
    Object.defineProperty(ConversionFieldComponent.prototype, "setQuantity", {
        set: function (quantity) {
            if (this.quantity !== quantity) {
                this.quantity = quantity;
                this.transformQuantityToView();
                if (this.isWeightUnchangeable() && !this.prohibitInitialConvert()) {
                    this.quantityInTons = this.conversionService.conversionToWeighUnits(this.conversionFactor, quantity);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConversionFieldComponent.prototype, "setWeightOnInit", {
        set: function (weight) {
            if (!this.stateOfFiled) {
                this.setInputWeightIfValid(weight);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConversionFieldComponent.prototype, "setWeight", {
        set: function (weight) {
            weight = this.setInputWeightIfValid(weight);
            if (!this.prohibitInitialConvert() && !this.disableConversion && weight !== null) {
                this.assignQuantityToTons(weight, false);
            }
        },
        enumerable: true,
        configurable: true
    });
    ConversionFieldComponent.prototype.setInputWeightIfValid = function (weight) {
        weight = this.transformToValidNumeric(weight);
        if (this.quantityInTons !== weight && !this.isDefaultUnits()) {
            this.quantityInTons = weight;
            return weight;
        }
        return null;
    };
    ConversionFieldComponent.prototype.ngOnInit = function () {
        this.stateOfFiled = this.conversionStateService.getOrCreateConversionState(this.identifier, this.setDefaultConversionOff);
        this.disableConversion = !this.stateOfFiled.active;
        if (!this.isDefaultUnits() && !this.prohibitInitialConvert(this.disableConversion)) {
            this.quantityInTons = this.conversionService.conversionToWeighUnits(this.conversionFactor, this.quantity);
        }
        this.transformQuantityToView();
        if (this.isDisabled && !this.quantity) {
            this.quantityInView = !!this.emptyQuantityHolder ? this.translate.instant(this.emptyQuantityHolder) : this.quantityInView;
            this.quantityInTons = !!this.emptyQuantityHolder ? this.translate.instant(this.emptyQuantityHolder) : this.quantityInView;
        }
        this.volumeUnit = this.volumeUnit || this.defaultUnit;
        this.volumeUnitText = this.PASSPORT_UNIT_MAP[this.volumeUnit];
    };
    ConversionFieldComponent.prototype.prohibitInitialConvert = function (initState) {
        if (initState === void 0) { initState = false; }
        return !this.isDisabled && (!this.stateOfFiled || initState) && !this.isConvertOnWriteIfOffConversion;
    };
    ConversionFieldComponent.prototype.onWeightChange = function (text) {
        var weightInTons = this.transformToValidNumeric(text);
        if (lodash.isFinite(Number(weightInTons.replace(',', '.')))) {
            this.quantityInTons = weightInTons;
            this.assignWeightIfValid(this.quantityInTons);
            if (!this.disableConversion) {
                this.assignQuantityToTons(weightInTons);
            }
        }
    };
    ConversionFieldComponent.prototype.assignWeightIfValid = function (text) {
        var number = this.castToNumber(text);
        if (this.isValidNumber(number)) {
            this.quantityChanged.emit({ value: number, isActive: !this.disableConversion });
        }
    };
    ConversionFieldComponent.prototype.onQuantityChange = function (quantity) {
        quantity = this.transformToValidNumeric(quantity);
        this.quantityInView = quantity;
        this.changeTonsOnly(quantity);
        this.assignQuantityIfValid(quantity);
    };
    ConversionFieldComponent.prototype.changeTonsOnly = function (quantity, isChangedForced) {
        if (quantity === void 0) { quantity = this.quantity; }
        if (isChangedForced === void 0) { isChangedForced = false; }
        if (this.isWeightUnchangeable() || isChangedForced) {
            this.quantityInTons = this.conversionService.conversionToWeighUnits(this.conversionFactor, quantity);
            this.assignWeightIfValid(this.quantityInTons);
        }
    };
    ConversionFieldComponent.prototype.isWeightUnchangeable = function () {
        return !this.disableConversion && !this.isDefaultUnits();
    };
    ConversionFieldComponent.prototype.assignQuantityToTons = function (weightInTons, emit) {
        if (weightInTons === void 0) { weightInTons = this.quantityInTons; }
        if (emit === void 0) { emit = true; }
        if (this.assignQuantityIfValid(this.conversionService.conversionToVolumeUnits(this.conversionFactor, weightInTons), emit)) {
            this.transformQuantityToView();
        }
    };
    ConversionFieldComponent.prototype.transformQuantityToView = function () {
        var isFixedRequired = this.quantity && this.quantity.toString().length > this.quantity.toFixed(this.conversionService.NUMBERS_AFTER_COMMA).length;
        this.quantityInView = typeof this.quantity === 'number'
            ? (isFixedRequired ? this.quantity.toFixed(this.conversionService.NUMBERS_AFTER_COMMA) : this.quantity.toString()) : '';
    };
    ConversionFieldComponent.prototype.assignQuantityIfValid = function (text, emit) {
        if (emit === void 0) { emit = true; }
        var number = this.castToNumber(text);
        if (this.isValidNumber(number)) {
            this.quantity = number;
            if (emit) {
                this.quantityInTonsChanged.emit(number);
            }
            return true;
        }
        return false;
    };
    ConversionFieldComponent.prototype.castToNumber = function (text) {
        return (!!text || text === 0) ? this.conversionService.transformToNumber(text) : null;
    };
    ConversionFieldComponent.prototype.isValidNumber = function (number) {
        return !Number.isNaN(number) || number === null;
    };
    ConversionFieldComponent.prototype.activateToggleConversion = function (isActive) {
        if (isActive) {
            this.onConvertButton();
        }
        this.disableConversion = !isActive;
        this.assignWeightIfValid(this.quantityInTons);
    };
    ConversionFieldComponent.prototype.onConvertButton = function () {
        if (this.quantityInView) {
            this.changeTonsOnly(this.quantity, true);
        }
        else {
            this.assignQuantityToTons();
        }
    };
    ConversionFieldComponent.prototype.isDefaultUnits = function () {
        if (this.volumeUnit === PASSPORT_UNITS_TYPE.KG || this.volumeUnit === PASSPORT_UNITS_TYPE.TONNE) {
            return true;
        }
    };
    ConversionFieldComponent.prototype.transformToValidNumeric = function (number) {
        var removeNotPositiveNumber = function (num) { return num.replace(/[^0-9.,]/g, ''); };
        var removeSecondCommaAndEverythingAfterIt = function (num) { return num.replace(/([.,].*?)[.,].*/, '$1'); };
        var removeLeadingZerosExpectFirstOne = function (num) { return num.replace(/^0*0/, '0'); };
        var chainFunctions = function (data, functions) {
            functions.forEach(function (f) {
                data = f(data);
            });
            return data;
        };
        number = typeof number === 'number' ? number.toString() : number;
        return number && chainFunctions(number, [
            removeNotPositiveNumber,
            removeSecondCommaAndEverythingAfterIt,
            removeLeadingZerosExpectFirstOne
        ]);
    };
    ConversionFieldComponent.prototype.preventInputUI = function (e, quantity) {
        var start = e.target.selectionStart;
        var end = e.target.selectionEnd;
        var value = e.target.value;
        if (quantity && value !== quantity) {
            start = start - (value.length - String(quantity).length);
            end = end - (value.length - String(quantity).length);
        }
        e.target.value = quantity;
        e.target.selectionStart = start;
        e.target.selectionEnd = end;
        e.preventDefault();
        e.stopPropagation();
    };
    return ConversionFieldComponent;
}());
export { ConversionFieldComponent };
