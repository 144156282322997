<div class="passportlist__item"
     [attr.data-primary-key]="passport.id">
  <passport-family-label [familyType]="passport.family"></passport-family-label>
  <div class="passportlist__item-img" (click)="openPassport(passport)">
    <img *ngIf="passport.thumbnail" [attr.data-src]="passport.thumbnail"
         class="passportlist__item-imglogo">
    <img *ngIf="!passport.thumbnail" [attr.data-src]="passport.image"
         class="passportlist__item-imglogo">
  </div>
  <div class="passportlist__item-info" (click)="openPassport(passport)"
       [ngClass]="{'passportlist__item-info-controls': parentModuleName !== moduleNames.SPECIAL_ADMIN}">
    <span *ngIf="!isForPublicAccess" class="passportlist__item-info-status"
          >{{getStatusView(passport.status)|capitalize|translate}}</span>
    <span class="passportlist__item-info-rating"
          *ngIf="parentModuleName === moduleNames.SPECIAL_ADMIN && passport.for_rating">{{ 'Waiting for rating' | translate }}</span>

    <div class="passportlist__item-top">

        <div class="passportlist__item-logoname"
             [mdTooltip]="passportName"
             [mdTooltipPosition]="'right'"
             [validateOnlyScrolled]="true"
             appCustomTooltip
             [tooltipClass]="['passportlist__name-tooltip'
             , 'passportlist__name-tooltip--left'
             , 'background-primary-color']">
          {{passportName}}
        </div>

      <div class="passportlist__item-row">
        <div class="passportlist__item-created-block">
          <div class="passportlist__item-created">
            {{'Created' | translate}}:
            <span class="passportlist__item-date"
            >{{formatPassportCreationDate() | localizedDate }}</span>
          </div>
          <div class="passportlist__item-name" *ngIf="!!getCreatedBy()">
            &nbsp;{{'by' | translate}}:
            <span class="passportlist__item-byname">
              {{
              getCreatedBy()
              }}
            </span>
          </div>
        </div>
        <div class="passportlist__item-created-block">
          <div class="passportlist__item-created">
            {{'Modified' | translate}}:
            <span class="passportlist__item-date"
            >{{formatPassportModifiedDate() | localizedDate }}</span>
          </div>
        </div>
      </div>

    </div>

    <div class="passportlist__item-content">
      <div class="passportlist__item-content-labels">
        <div class="passportlist__item-content-label">
          {{'Category' | translate}}:
          <span class="passportlist__item-content-label--value">{{ (passport.category? passport.category.name : '') | translate }}</span>
        </div>
        <div class="passportlist__item-content-label">
          {{'Material' | translate}}:
          <span class="passportlist__item-content-label--value"
                *ngFor="let nomenclature of passport.nomenclature;let isLast=last">
              {{nomenclature.name | translate}}{{isLast ? '' : ', '}}
          </span>
        </div>
        <div class="passportlist__item-content-label">
          {{'Unit' | translate}}:
          <span class="passportlist__item-content-label--value">
            {{passport.unit_text | translate}}
          </span>
          / &nbsp;{{'Version' | translate}}:
          <span class="passportlist__item-content-label--value">
            {{passport.version }}
          </span>
        </div>

      </div>

      <div class="passportlist__item-content-ratings" *ngIf="areRatingsVisible()">

        <div class="passportlist__item-content-ratings-icon">
          <div *ngIf="passport.c2c_certified"
               mdTooltipPosition="above"
               mdTooltip="{{'PASSPORT_LIST_ITEM_C2C_TOOLTIP' | translate: getC2cTooltipValue() }}"
               class="passportlist__item-content-ratings-icon
                   passportlist__item-content-ratings-icon-certification-colored">
          </div>
        </div>

        <div class="passportlist__item-content-ratings-icon">
          <div *ngIf="isRatingVisible(passport.rating.rating_composition)"
               mdTooltipPosition="above"
               mdTooltip="{{ 'PASSPORT_LIST_ITEM_TOXICITY_TOOLTIP' | translate }}"
               [ngClass]="getRatingClass(passportRatinglabel.COMPOSITION, passport.rating.rating_composition)"
          >
          </div>
        </div>

        <div class="passportlist__item-content-ratings-icon">
          <div *ngIf="isRatingVisible(passport.rating.rating_recyclability)"
               mdTooltipPosition="above"
               mdTooltip="{{ 'PASSPORT_LIST_ITEM_CIRCULARITY_TOOLTIP' | translate: getCircularityTooltipValue() }}"
               [ngClass]="getRatingClass(passportRatinglabel.CYCLE, passport.rating.rating_recyclability)"
          >
          </div>
        </div>

        <div class="passportlist__item-content-ratings-icon">
          <div *ngIf="isRatingVisible(passport.rating.rating_energy)"
               mdTooltipPosition="above"
               mdTooltip="{{ 'PASSPORT_LIST_ITEM_ENERGY_TOOLTIP' | translate: getEnergyTooltipValues() }}"
               [ngClass]="getRatingClass(passportRatinglabel.ENERGY, passport.rating.rating_energy)"
          >
          </div>
        </div>

        <div class="passportlist__item-content-ratings-icon">
          <div *ngIf="isRatingVisible(passport.rating.rating_water_stewardship)"
               mdTooltipPosition="above"
               mdTooltip="{{ 'PASSPORT_LIST_ITEM_WATER_TOOLTIP' | translate }}"
               [ngClass]="getRatingClass(passportRatinglabel.WATER, passport.rating.rating_water_stewardship)"
          >
          </div>
        </div>

        <div class="passportlist__item-content-ratings-icon">
          <div *ngIf="isRatingVisible(passport.rating.rating_social_fairness)"
               mdTooltipPosition="above"
               mdTooltip="{{ 'PASSPORT_LIST_ITEM_SOCIAL_TOOLTIP' | translate }}"
               [ngClass]="getRatingClass(passportRatinglabel.SOCIAL, passport.rating.rating_social_fairness)"
          >
          </div>
        </div>

      </div>

    </div>
  </div>
  <div class="passportlist__item-controls" *ngIf="isPassportIndependent() && !isForPublicAccess;else Indepedent">
    <div class="passportlist__item-delete" (click)="deletePassport(passport)"></div>
    <div class="passportlist__item-copy" (click)="duplicatePassport(passport)"></div>
    <div  class="passportlist__item-edit" (click)="copyProductId()"> Id </div>
    <div  appCustomTooltip
          [ngClass]="getVersionOnClass()"  
          (click)="filterByVersion(passport)"
          mdTooltipPosition="above"
          tooltipClass="passportlist__item-version-tooltip"
          mdTooltip="{{ 'VERSION_HOVER_TOOLTIP' | translate }}"> V </div>
  </div>
  <ng-template #Indepedent>
    <div class="passportlist__item-controls-indep">
      <div class="passportlist__item-edit-indep" (click)="copyProductId()">copy Id</div>
      <div 
            [ngClass]="getVersionOnClass()" 
            (click)="filterByVersion(passport)"
            [mdTooltipPosition]="'above'"
            mdTooltip="{{ 'VERSION_HOVER_TOOLTIP' | translate }}"
            appCustomTooltip
            [tooltipClass]="['passportlist__version-tooltip']"
            >V</div>
    </div>
  </ng-template>
</div>

