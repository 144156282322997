import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {MdDialogRef, MdSnackBar} from '@angular/material';
import {StyleLoaderService} from '../services/style-loader.service';

@Directive({
  selector: '[appNotificationAsWideDialogContent]'
})
export class NotificationAsWideDialogContentDirective implements OnInit, OnDestroy{

  @Input() customNotificationStyles =
    `snack-bar-container.error {
        box-sizing: border-box;
        height: 50px;
        padding: 15px 24px;
        text-align: center;
      }
      snack-bar-container.error .mat-simple-snackbar {
        justify-content: center;
      }`;

  private readonly customErrorMessage = 'customErrorMessage';
  private readonly responsiveErrorMessage = 'responsiveErrorMessage';

  constructor(private snackbar: MdSnackBar,
              private dialog: MdDialogRef<any>,
              private styleLoader: StyleLoaderService) {}

  ngOnInit() {
    this.styleLoader.addStyle(this.customErrorMessage, this.customNotificationStyles);
    if (this.dialog._containerInstance.dialogConfig.width) {
      this.styleLoader.addStyle(this.responsiveErrorMessage, `
      snack-bar-container.error {
        width: ${this.dialog._containerInstance.dialogConfig.width} !important;
        max-width: ${this.dialog._containerInstance.dialogConfig.width} !important;
        `);
    }
  }

  ngOnDestroy() {
    const remove = () => {
      this.styleLoader.removeStyle(this.customErrorMessage);
      this.styleLoader.removeStyle(this.responsiveErrorMessage);
    };
    if (this.snackbar._openedSnackBarRef) {
      this.snackbar._openedSnackBarRef.afterDismissed().subscribe(() => remove());
    } else {
      remove();
    }
  }
}
