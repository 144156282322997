import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 8.02.17.
 */
import { EcosystemFileService } from '../../../entities/ecosystem/services/ecosystem-file.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
var EcosystemFile = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemFile, _super);
    function EcosystemFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = EcosystemFileService;
        return _this;
    }
    EcosystemFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'ecosystem_id',
        ]);
    };
    return EcosystemFile;
}(UploadedDocument));
export { EcosystemFile };
