/**
 * Created by aleksandr on 29.08.17.
 */
export class GuardStates {

  public static activatedGuards:any = [];

  public static activateGuard(guard:any) {
    if(!GuardStates.isGuardActivated(guard)) {
      GuardStates.activatedGuards.push(guard);
    }
  }
  public static clearActivatedGuards() {
    GuardStates.activatedGuards.length = 0;
  }

  public static isGuardActivated(guard:any) {
    return GuardStates.activatedGuards.indexOf(guard) > -1;
  }


}
