import * as tslib_1 from "tslib";
import { Client } from '../model/client.class';
import { FileUploader } from 'ng2-file-upload';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var ClientService = /** @class */ (function (_super) {
    tslib_1.__extends(ClientService, _super);
    function ClientService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'clients';
        _this.namespaceSingular = 'client';
        _this.ModelClass = Client;
        _this.relations = ['client_locations', 'client_contacts'];
        return _this;
    }
    ClientService.prototype.getLogoUploader = function (id) {
        return new FileUploader({ url: this.getEndpoint(this.singularEndpoint + '/' + id + '/logo') });
    };
    ClientService.prototype.getLogoUploadUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/logo');
    };
    ClientService.prototype.removeLogo = function (id) {
        return this.sendDelete(this.getEndpoint(this.namespaceSingular + "/" + id + "/logo"))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    //TODO check location service if this could be made there instead
    ClientService.prototype.removeLocation = function (clientId, locationId) {
        return this.sendDelete(this.getEndpoint(this.singularEndpoint + '/' + clientId + '/locations/' + locationId))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    ClientService.prototype.removeContact = function (clientId, contactId) {
        return this.sendDelete(this.getEndpoint(this.singularEndpoint + '/' + clientId + '/contacts/' + contactId))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    ClientService.prototype.canDelete = function (clientId) {
        return this.sendGet(this.getEndpoint(this.singularEndpoint + '/' + clientId + '/candelete'))
            .catch(this.onError.bind(this));
    };
    return ClientService;
}(ExtendedCrudService));
export { ClientService };
