import { Injectable } from '@angular/core';

export const STORAGEKEYMAP = {
  'manufacturer': 'manufacturerFilters',
  'special_admin': 'specialAdminFilters',
  'agent': 'agentPassportFilters',
};

@Injectable()
export class LocalStorageService {
  constructor() {}

  /**
   * Get a value stored in the local storage
   * @param {string} key - The key of the value to retrieve 
   * @returns {string}
   */
  getStoredValue(key: string) {
    return localStorage.getItem(key);
  }

  getStoredObject<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  /**
   * Store a value in the local storage
   * @param {string} key - The key where the value should be stored
   * @param {any} value - The value to store
   */
  storeValue(key, value) {
    localStorage.setItem(key, value);
  }

  storeObject(key: string, value: any): void {
    const serialized = JSON.stringify(value);
    localStorage.setItem(key, serialized);
  }

  removeValue(key: string): void {
    localStorage.removeItem(key);
  }
}
