import {Component, OnInit} from '@angular/core';
import {ReportService} from '../../../../services/report.service';
import {ActivatedRoute} from '@angular/router';
import {ReportBase} from '../report-base/report-base.class';
import {REPORT_NAME} from '../types/report-name.const';
import {LanguageService} from '../../../../../../entities/client/service/language.service';
import {IReportPDFTable} from '../types/report-pdf-table.interface';
import {IReportError} from '../../../../interfaces/report-error.interface';
import {TReportName} from '../types/report-name.type';
import { IMaterialHealth, IMaterialHealthCategory } from '../../../../interfaces/material-health.interface';
import { CHART_CATEGORY } from '../../new-report/material-health/values/chart-data.const';
import { IMaterialHealthPieChart } from '../../new-report/material-health/values/material-heath-pie-chart-data.interface';
import {TranslateService} from '@ngx-translate/core';
import {MaterialPieCharData} from '../../../../services/material-pie-char-data.service';
import {ReportSiteTagPresenterService} from '../../../../services/report-site-tag-presenter.service';
import {ElRowCounterService} from '../../../../services/el-row-counter.service';

@Component({
  moduleId: module.id,
  selector: 'material-health-report',
  templateUrl: 'material-health-report.component.html',
  styleUrls: ['material-health-report.component.scss'],
  providers: [ReportSiteTagPresenterService, ElRowCounterService]
})
export class MaterialHealthReportComponent extends ReportBase<IMaterialHealth, IMaterialHealthCategory> implements OnInit {
  protected reportName: TReportName = REPORT_NAME.MATERIAL_HEALTH;

  public categoriesTable: IReportPDFTable[]= [];
  public errorsTable: IReportPDFTable[] = [];

  public categories: IMaterialHealthCategory[] = [];

  mainChartData: IMaterialHealthPieChart;
  minDepositsConsidered: number;
  maxDepositsConsidered: number;
  tonsOfDeposits: number;

  public mostImportantProductChartData: IMaterialHealthPieChart;
  public healthiestProductChartData: IMaterialHealthPieChart;
  public mostToxicProductChartData: IMaterialHealthPieChart;

  constructor(
    public reportService: ReportService,
    public activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    public translateService: TranslateService,
    private materialPieCharDate: MaterialPieCharData,
    public reportPresenter: ReportSiteTagPresenterService,
    public rowCounter: ElRowCounterService
  ) {
    super(reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter);
  }

  ngOnInit(): void {
    this.createReport();
  }

  createReport(): void {
    this.reportRequest()
      .subscribe(() => {
        this.prepareMainChartData();
        this.prepareTablesDataForRender();
    });
  }

  setCategories(): void {
    this.categories = [
      this.getDefaultCategory(),
      ...this.getSortedProductsBy(CHART_CATEGORY.healthiness)
        .filter(product => !!product.deposits_considered)
    ];
  }

  getDefaultCategory(): IMaterialHealthCategory {
    return {
      category: {
        name: 'Total', parent_id: void 0, id: void 0
      },
      category_mass_percentage: 100,
      isDefault: true,
      deposit_count: this.result.aggregate.total_deposit_count,
      deposits_considered: this.result.aggregate.total_deposit_considered,
      percentage_of_controlled_toxicity: this.result.aggregate.percentage_weight_of_controlled_toxicity,
      percentage_of_controlled_verified_toxicity: this.result.aggregate.percentage_weight_of_controlled_verified_toxicity,
      percentage_of_healthy_product: this.result.aggregate.percentage_weight_of_healthy_product,
      percentage_of_unknown_toxicity: this.result.aggregate.percentage_weight_of_unknown_toxicity,
      percentage_of_known_toxicity: this.result.aggregate.percentage_weight_of_known_toxicity,
      weight: this.result.aggregate.total_weight,
      weight_of_controlled_toxicity: this.result.aggregate.total_weight_of_controlled_toxicity,
      weight_of_controlled_verified_toxicity: this.result.aggregate.total_weight_of_controlled_verified_toxicity,
      weight_of_healthy_product: this.result.aggregate.total_weight_of_healthy_product,
      weight_of_unknown_toxicity: this.result.aggregate.total_weight_of_unknown_toxicity,
      weight_of_known_toxicity: this.result.aggregate.total_weight_of_known_toxicity,
      healthiness: 0
    };
  }

  getProductByHealthiness(type: 'MOST' | 'LEAST') {
    const products: IMaterialHealthCategory[] = this.getSortedProductsBy(CHART_CATEGORY.healthiness);
    if (type === 'MOST') {
      return products[0];
    } else if (type === 'LEAST') {
      return products[products.length - 1];
    }
  }

  setHealthiestProduct(): void {
    const product: IMaterialHealthCategory = this.getProductByHealthiness('MOST');
    this.healthiestProductChartData = this.materialPieCharDate.getRoundPieChartData({product});
  }

  setMostToxicProduct(): void {
    const product: IMaterialHealthCategory = this.getProductByHealthiness('LEAST');
    this.mostToxicProductChartData = this.materialPieCharDate.getRoundPieChartData({product});
  }

  setMostImportantProduct(): void {
    const product: IMaterialHealthCategory = this.getSortedProductsBy(CHART_CATEGORY.weight)[0];
    this.mostImportantProductChartData = this.materialPieCharDate.getRoundPieChartData({product});
  }

  sortByValueDesc = (object: {[key: string]: number}): any => Object.entries(object)
    .sort(([, v1], [, v2]) => +v1 - +v2)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})


  prepareMainChartData() {
    this.mainChartData = this.materialPieCharDate.getRoundPieChartData({mainData: this.result.aggregate});
    this.minDepositsConsidered = this.result.aggregate.total_deposit_considered;
    this.maxDepositsConsidered = this.result.aggregate.total_deposit_count;
    this.tonsOfDeposits        = this.result.aggregate.total_weight;
  }

  prepareTablesDataForRender(): void {
    this.prepareCategoriesTable();
    this.prepareErrorsTable();
    this.setHealthiestProduct();
    this.setMostToxicProduct();
    this.setMostImportantProduct();
  }

  prepareCategoriesTable(): void {
    this.firstPageCategoriesAmount = 8;
    this.setCategories();
    this.onEachChunkedCategory((chuck: IMaterialHealthCategory[], index: number) => {
      this.categoriesTable.push({
        pageNumber: index,
        pageContent: chuck
      });
    });
  }

  prepareErrorsTable(): void {
    this.onEachChunkedError((chuck: IReportError[], index: number) => {
      this.errorsTable.push({
        pageNumber: index + this.categoriesTable.length,
        pageContent: chuck
      });
    });
  }
}
