/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./passport-family-info.component";
var styles_PassportFamilyInfoComponent = [];
var RenderType_PassportFamilyInfoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PassportFamilyInfoComponent, data: {} });
export { RenderType_PassportFamilyInfoComponent as RenderType_PassportFamilyInfoComponent };
function View_PassportFamilyInfoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "passport-edit__information--text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "passport-edit__information--text-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("FAMILY_AGENT_INFO_PART_2")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("FAMILY_AGENT_INFO_PART_3")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("FAMILY_AGENT_INFO_PART_4")); _ck(_v, 10, 0, currVal_2); }); }
function View_PassportFamilyInfoComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "passport-edit__information--text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "passport-edit__information--text-top"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "passport-edit__information--text-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("FAMILY_MANUFACTURER_INFO_PART_1")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("FAMILY_MANUFACTURER_INFO_PART_2")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("FAMILY_MANUFACTURER_INFO_PART_3")); _ck(_v, 10, 0, currVal_2); }); }
function View_PassportFamilyInfoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["style", "padding-top: 5px;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassportFamilyInfoComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassportFamilyInfoComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.translationType === "AGENT"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.translationType === "MANUFACTURER"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PassportFamilyInfoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "passportlist__infobox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "background-primary-color"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "passport-edit__information": 0, "passport-edit__information--active": 1 }), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "passport-edit__information--header-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "passport-edit__information--header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["class", "icon icon--question-white"], ["style", "margin-right: 5px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "passport-edit__information--arrow"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassportFamilyInfoComponent_1)), i0.ɵdid(12, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "background-primary-color"; var currVal_1 = _ck(_v, 3, 0, true, _co.isShown); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.isShown; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("FAMILY_AGENT_INFO_PART_1")).toUpperCase(); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.isShown ? " icon white-arrow-down--icon" : "icon white-arrow-up--icon"); _ck(_v, 9, 0, currVal_3); }); }
export function View_PassportFamilyInfoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "passport-family-info", [], null, null, null, View_PassportFamilyInfoComponent_0, RenderType_PassportFamilyInfoComponent)), i0.ɵdid(1, 114688, null, 0, i3.PassportFamilyInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PassportFamilyInfoComponentNgFactory = i0.ɵccf("passport-family-info", i3.PassportFamilyInfoComponent, View_PassportFamilyInfoComponent_Host_0, { userRole: "userRole", isShown: "isShown" }, { isShownChange: "isShownChange" }, []);
export { PassportFamilyInfoComponentNgFactory as PassportFamilyInfoComponentNgFactory };
