<div class="depositlist__filter-container" #selectBody tabindex="-1" (keydown) = "handleKeyup($event)">
  <div *ngIf="isSelectOpen" class="depositlist__checkbox-filter">
    <div class="depositlist__choose"
         *ngFor="let option of allOptions; let i = index"
         test-id="checkbox-div"
    ><md-checkbox
      #optionEl
      [tabIndex]="focusVisibilityCheckbox()"
      [name] ="option.text"
      (change)="changeOption.emit(option.value)"
      [checked]="option.selected"
      test-id="checkbox"
      id="{{idKey + i}}"
    ></md-checkbox>
      <label class="depositlist__choose-name" test-id="checkbox-label" for="{{'input-' + idKey + i}}">
        {{option.view  | capitalize | translate}}
      </label>
    </div>
  </div>
  <md-input-container
    class="depositlist__form-item--no-width"
    [floatPlaceholder]="'never'"
    (click)="openSelectByClick()"
    (keyup)="onEnterShowRating($event)"
    [class.purple-placeholder]="!isSelectEmpty()"
  >
    <input mdInput
           [disabled]="disableSelection"
           [tabindex]="focusVisibilityInput()"
           autocomplete="off"
           class="passportlist__no-caret"
           placeholder="{{ placeholder | translate }}"
           maxlength="0">
    <div class="passportlist__info-icons">
      <span *ngIf="!isSelectEmpty()" [ngClass]="getIconClassName()"></span>
      <span class="mat-select-arrow"></span>
    </div>
  </md-input-container>
</div>
