import * as tslib_1 from "tslib";
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { DepositDocumentFileService } from '../services/deposit-document-file.service';
var DepositDocumentFile = /** @class */ (function (_super) {
    tslib_1.__extends(DepositDocumentFile, _super);
    function DepositDocumentFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = DepositDocumentFileService;
        return _this;
    }
    DepositDocumentFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'deposit_id'
        ]);
    };
    return DepositDocumentFile;
}(UploadedDocument));
export { DepositDocumentFile };
