import {ActiveRecord} from '../../shared/models/active-record.class';
import {ELastImportType} from '../values/enums/last-import-type.enum';
import {EImportEntityStatus} from '../values/enums/import-entity-status.enum';
import {ActiveImportService} from '../services/active-import.service';

export class ActiveImport extends ActiveRecord {
  protected provider = ActiveImportService;
  id: number;
  user_id: number;
  import_config_id: number;
  import_id: number;
  import_type: ELastImportType;
  status: EImportEntityStatus;

  protected fields(): string[] {
    return ['id', 'user_id', 'import_config_id', 'import_id', 'import_type', 'status'];
  }
}
