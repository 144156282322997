import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { ReportFileService } from '../services/report-file.service';

export class ReportFile extends UploadedDocument {
  protected provider = ReportFileService;

  report_file_id: number;

  protected fields() {
    return super.fields().concat([
      'report_file_id',
    ]);
  }
}
