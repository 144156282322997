import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Synonym } from '../../../shared/models/synonym.class';
import { GenericPassportTagJunctionService } from '../service/generic-passport-tag-junction.service';

export class GenericPassportTagJunction extends ActiveRecord {
  protected provider = GenericPassportTagJunctionService;

  public id: number;
  public name: string;
  public generic_passport_id: number;
  public tag_id: number;
  public synonyms: Synonym[];

  protected fields() {
    return [
      'id',
      'tag_id',
      'generic_passport_id',
      'name',
      'synonyms',
    ];
  }
}


