import { OnInit } from '@angular/core';
import { EConfirmDialogIcon } from '../../values/enums/confirm-dialog-icon.enum';
var ConfirmDialogIconComponent = /** @class */ (function () {
    function ConfirmDialogIconComponent() {
        this.CONFIRM_DIALOG_ICON = EConfirmDialogIcon;
        this.primaryFill = true;
        this.iconClass = '';
    }
    Object.defineProperty(ConfirmDialogIconComponent.prototype, "iconData", {
        set: function (data) {
            Object.assign(this, data);
        },
        enumerable: true,
        configurable: true
    });
    ConfirmDialogIconComponent.prototype.ngOnInit = function () {
    };
    ConfirmDialogIconComponent.prototype.getEndpoint = function (src) {
        return window.location.origin + '/' + src;
    };
    return ConfirmDialogIconComponent;
}());
export { ConfirmDialogIconComponent };
