import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SORT_MODES } from '../../../../shared/values/sort-modes.const';
import { GoogleMapStyles } from '../../../../shared/config/google-maps-styles';
import { Deposit } from '../../../../entities/deposit/models/deposit.class';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';

@Component({
  moduleId: module.id,
  templateUrl: 'supervisor-deposits.component.html',
  host: {'class': 'supervisor-list'},
})
export class SupervisorDepositsComponent implements OnInit {
  public SORT_MODES = [
    SORT_MODES.NAME,
    SORT_MODES.DATE_OF_CREATION,
  ];
  public mapStyles = GoogleMapStyles;

  public records: Deposit[];
  public results: Deposit[];

  public selectedSortMode = this.SORT_MODES[0];
  public sortOrder = 1;
  public form = new FormGroup({
    searchText: new FormControl(),
    publicationDate: new FormControl()
  });

  constructor(private depositService: DepositService) {
  }

  ngOnInit() {
    this.depositService.get<Deposit>({supervisor: true, supervisor_view:true}).subscribe((res) => {
      this.records = res;
      this.form.valueChanges.subscribe(() => {
        this.update();
      });
      this.update();
    });
  }

  sort(sortMode: any) {
    if (this.selectedSortMode === sortMode) {
      this.sortOrder *= -1;
    } else {
      this.selectedSortMode = sortMode;
      this.sortOrder = 1;
    }
    this.update();
  }

  update() {
    this.results = this.getResults(this.records);
  }

  getResults(results: Deposit[]) {
    let searchText = this.form.value.searchText;
    if (searchText) {
      results = results.filter(record =>
        record.includesText(searchText)
      );
    }

    let publicationDate = this.form.value.publicationDate;
    if (publicationDate) {
      results = results.filter(record =>
        new Date(record.created_at).toDateString() === new Date(publicationDate).toDateString()
      );
    }

    results.sort(this.selectedSortMode.comparator);

    if (this.sortOrder === -1) {
      results.reverse();
    }

    return results;
  }
}
