import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { DepositLocationSalesJunctionService } from '../services/deposit-location-sales-junction.service';
var DepositLocationSalesJunction = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationSalesJunction, _super);
    function DepositLocationSalesJunction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = DepositLocationSalesJunctionService;
        return _this;
    }
    DepositLocationSalesJunction.prototype.fields = function () {
        return ['id', 'site_id', 'user_id'];
    };
    return DepositLocationSalesJunction;
}(ActiveRecord));
export { DepositLocationSalesJunction };
