import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DepositLocation } from '../../../models/deposit-location.class';
import { BIM } from '../../../../../shared/models/bim.class';
import { CrudService } from '../../../../../shared/services/crud.service';
import { UploadedDocument } from '../../../../../shared/models/uploaders/document.class';
import { FileUploader } from 'ng2-file-upload';
import { EDepositLocationStatus, DepositLocationStatusName } from '../../../values/deposit-location-status.enum';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { NavigationService } from '../../../../../shared/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'deposit-site-details-base',
  templateUrl: './deposit-site-details-base.component.html',
  styleUrls: ['../deposit-site-details.component.scss']
})


export class DepositSiteDetailsBaseComponent {
  @Input() location: DepositLocation = new DepositLocation;
  @Input() isCompanyBuilding = false;
  @Input() BIMs: BIM[];
  @Input() onDocumentSave$: EventEmitter<string> = new EventEmitter();
  @Input() onUpdateUploadUrl$: EventEmitter<string> = new EventEmitter();
  @Input() files?: UploadedDocument[] = [];
  @Input() depositLocationFileService?: CrudService;
  @Input() uploader?: FileUploader;
  @Input() uploaderQueueEdit = false;
  @Input() isViewModeRestricted = false;
  DEPOSIT_LOCATION_STATUS_ID = EDepositLocationStatus;
  DEPOSIT_LOCATION_STATUS = Object.values(EDepositLocationStatus).filter(code => Number(code))
    .map(code => ({ value: code, name: DepositLocationStatusName[code] }));

  constructor(
    private notification: LogNotificationService,
    private _navigationService: NavigationService,
    private translate: TranslateService,
  ) { }

  onPlaceChange(place: google.maps.places.PlaceResult) {
    if (place.geometry) {
      this.location.lat = place.geometry.location.lat();
      this.location.lng = place.geometry.location.lng();
      this.location.address = place.formatted_address;

      this.location.city = null;
      this.location.country = null;
      this.location.postalCode = null;

      // Browse each address component to assign the correct values
      // according to their type.
      for (let i = 0; i < place.address_components.length; i++) {
        const component = place.address_components[i];
        if (component.types.includes('locality') || component.types.includes('administrative_area_level_3')) {
          this.location.city = component.long_name;
        } else if (component.types.includes('country')) {
          this.location.country = component.long_name;
        } else if (component.types.includes('postal_code')) {
          this.location.postalCode = component.long_name;
        }
      }
    } else {
      this.location.lat = null;
      this.location.lng = null;
      this.location.address = null;
      this.location.city = null;
      this.location.country = null;
      this.location.postalCode = null;
    }
  }


  uncheckFiles() {
    this.location.checked = 0;
  }

  checkExpire($event) {
    if (!this.location.expired_date) {
      this.location.expired_date = moment().format('YYYY-MM-DD');
      this.location.is_expired_by_superadmin = true;
    } else {
      this.location.expired_date = '';
      this.location.is_expired_by_superadmin = false;
    }
  }

  checkIsExpiredAndNotConstructionSite() {
    return this.location.is_expired_by_superadmin && this.location.site_status !== this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION ||
      this.location.isExpire && this.location.site_status !== this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION;
  }

  checkIsExpiredConstructionSite() {
    return this.location.isExpire && this.location.site_status === this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION ||
      this.location.is_expired_by_superadmin && this.location.site_status === this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION;
  }

  showExpiredSinceOrExpireText(location: DepositLocation) {
    if (this.location.is_expired_by_superadmin) {
      return this.translate.instant('Expired since') + ' : ';
    } else {
      return this.translate.instant('Expire');
    }
  }

  validateExpiration() {
    if (this.location.active && !this.isViewModeRestricted) {
      this.notification.warn('Some deposits of this site are being used in ecosystems !', 3000);
      return false;
    }
    return true;
  }
  checkSpanishLanguage() {
    if (this.translate.currentLang === 'es') {
      return true;
    };
  }
}
