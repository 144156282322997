import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import {EAskForRating} from './passport-rating-explanation-dialog.consts';
import {proceedIfNonNegativeInt, pasteOnlyIfNonNegativeInt} from '../../../../shared/helpers/proceed-if-non-negative-int'


@Component({
  moduleId: module.id,
  templateUrl: 'passport-rating-explanation-dialog.component.html',
  selector: 'passport-rating-expanation'
})
export class PassportRatingExplanationDialogComponent {
  tokenFormControl = new FormControl('', [Validators.pattern(/^\d{8,10}$/), Validators.required]);
  proceedIfNonNegativeInt = proceedIfNonNegativeInt;
  pasteOnlyIfNonNegativeInt = pasteOnlyIfNonNegativeInt;
  askForRating = EAskForRating;
  isActionButtonsShown;
  PASSPORT_RATING_EXPLANATION_CARDS: {
    iconClass: string, label: string, content: string
  }[] = [
    {iconClass: 'composition', label: 'Non-toxicity', content: 'PASSPORT_RATING_EXPLANATION.TOXICITY.CONTENT'},
    {iconClass: 'cycle', label: 'Circularity', content: 'PASSPORT_RATING_EXPLANATION.CIRCULARITY.CONTENT'},
    {iconClass: 'energy', label: 'Energy & Carbon Management', content: 'PASSPORT_RATING_EXPLANATION.ENERGY.CONTENT'},
    {iconClass: 'water', label: 'Water management', content: 'PASSPORT_RATING_EXPLANATION.WATER.CONTENT'},
    {iconClass: 'social', label: 'Social', content: 'PASSPORT_RATING_EXPLANATION.SOCIAL.CONTENT'},
    {iconClass: 'c2c-certification', label: 'C2C certification', content: 'PASSPORT_RATING_EXPLANATION.C2C.CONTENT'},
  ];
  askForRatingOption = null;
  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<any>,
              protected _router: Router,
              private translate: TranslateService) {
    this.isActionButtonsShown = this.mdDialogData.isActionButtonsShown;
  }
  getExplanationCards(): {iconClass: string, label: string, content: string}[] {
      return this.PASSPORT_RATING_EXPLANATION_CARDS;
  }
  getIconClass(iconClass: string, cardClass: string): string {
    return cardClass + '-' + iconClass;
  }
  applyPrimaryColor(translateConst: string, isListItem?: boolean) {
    const translation = this.translate.instant(translateConst);
    return isListItem ? `<span class="primary-color passport-rating-explanation__list-item"><strong>${translation}</strong></span>`
      : `<span class="primary-color"><strong>${translation}</strong></span>`;
  }

  getCardContent(content: string) {
    switch (content) {
      case 'PASSPORT_RATING_EXPLANATION.C2C.CONTENT':
        return this.translate.instant(content, {
          X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.C2C.ITEM.CRADLE'),
          Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.C2C.ITEM.CERTIFICATE')
        });
      case 'PASSPORT_RATING_EXPLANATION.SOCIAL.CONTENT':
        return this.translate.instant(content, {
          X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.SOCIAL.ITEM.CONFIRMATION'),
          Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.SOCIAL.ITEM.CHECKBOX')
        });
      case 'PASSPORT_RATING_EXPLANATION.WATER.CONTENT':
        return this.translate.instant(content, {
          X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.WATER.ITEM.CONFIRMATION'),
          Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.WATER.ITEM.CHECKBOX')
        });
      case 'PASSPORT_RATING_EXPLANATION.TOXICITY.CONTENT':
        return this.translate.instant(content, {
          X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.TOXICITY.ITEM.BANNED_LIST'),
          Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.TOXICITY.ITEM.COMMIT'),
        });
      case 'PASSPORT_RATING_EXPLANATION.CIRCULARITY.CONTENT':
        return this.translate.instant(content, {
          X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.PORTION_OF_MATERIALS', true),
          Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.OPTIMIZATION_STRATEGY'),
          Z: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.SHARE', true),
          V: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.FUTURE', true),
          W: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.FUTURE_USE', true),
        });
      case 'PASSPORT_RATING_EXPLANATION.ENERGY.CONTENT':
        return this.translate.instant(content, {
          V: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.ENERGY.ITEM.CARBON_FOOTPRINT'),
          W: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.ENERGY.ITEM.RAW_MATERIAL', true),
          X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.ENERGY.ITEM.RENEWABLE', true),
        });
    }
  }

  isValidRatingToken() {
    return this.tokenFormControl.status === 'VALID';
  }

  get isAskForRatingDisabled() {
    return !(this.askForRatingOption && (this.askForRatingOption === EAskForRating.SUBSCRIPTION
        || (this.askForRatingOption === EAskForRating.TOKEN && this.isValidRatingToken())));
  }

  onAskForARating() {
    this.askForRatingOption === EAskForRating.TOKEN
    ? this.mdDialogData.onAskForARating(this.tokenFormControl.value)
    : this.mdDialogData.onAskForARating();
  }
}
