import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { EDeepStreamMessage } from '../../values/deepstream-message-type.enum';
import { BaseDeepStreamService } from './base-deepstream.service';
var DepositImportDeepStream = /** @class */ (function (_super) {
    tslib_1.__extends(DepositImportDeepStream, _super);
    function DepositImportDeepStream() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.messageTypes = [
            EDeepStreamMessage.ERRORS_READY,
            EDeepStreamMessage.IMPORT_COMPLETION,
            EDeepStreamMessage.DEPOSIT_IMPORT_PROCESS_DOC,
            EDeepStreamMessage.DEPOSIT_IMPORT_PROGRESS_ERROR
        ];
        return _this;
    }
    DepositImportDeepStream.prototype.emit = function (data) {
        switch (data.type) {
            case EDeepStreamMessage.ERRORS_READY:
                return DepositImportDeepStream._depositImportErrorsStream$.emit({ target: this.receiver, data: data });
            case EDeepStreamMessage.IMPORT_COMPLETION:
                return DepositImportDeepStream._depositCompletionStream$.emit({ target: this.receiver, data: data });
            case EDeepStreamMessage.DEPOSIT_IMPORT_PROCESS_DOC:
                return DepositImportDeepStream._depositImportParsedRowsStream$
                    .emit({ target: this.receiver, data: data });
            case EDeepStreamMessage.DEPOSIT_IMPORT_PROGRESS_ERROR:
                return DepositImportDeepStream._depositImportFatalError$
                    .emit({ target: this.receiver, data: data });
        }
    };
    Object.defineProperty(DepositImportDeepStream.prototype, "depositImportErrorsStream$", {
        get: function () {
            return DepositImportDeepStream._depositImportErrorsStream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositImportDeepStream.prototype, "depositCompletionStream$", {
        get: function () {
            return DepositImportDeepStream._depositCompletionStream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositImportDeepStream.prototype, "depositImportParsedRowsStream$", {
        get: function () {
            return DepositImportDeepStream._depositImportParsedRowsStream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositImportDeepStream.prototype, "depositImportFatalError$", {
        get: function () {
            return DepositImportDeepStream._depositImportFatalError$;
        },
        enumerable: true,
        configurable: true
    });
    DepositImportDeepStream._depositImportErrorsStream$ = new EventEmitter();
    DepositImportDeepStream._depositCompletionStream$ = new EventEmitter();
    DepositImportDeepStream._depositImportParsedRowsStream$ = new EventEmitter();
    DepositImportDeepStream._depositImportFatalError$ = new EventEmitter();
    return DepositImportDeepStream;
}(BaseDeepStreamService));
export { DepositImportDeepStream };
