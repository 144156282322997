import { Deposit } from '../../entities/deposit/models/deposit.class';
import { IMapMarker } from '../map-view/map-marker.interface';

export class DepositMapMarkersService {
  site: number;
  markers: IMapMarker[] = [];

  updateMarkers(deposits: Deposit[]) {
    if(!this.isOpenPopUpOpen) {
      let groupedDepositsByLocationId = this.groupByDepositLocationId(deposits);
      Object.keys(groupedDepositsByLocationId).map(locationId => {
        if(!this.markers.some(m => m.id === Number(locationId))) {
          let deposit: Deposit = groupedDepositsByLocationId[locationId][0];
          if (deposit.deposit_location && deposit.deposit_location.lat && deposit.deposit_location.lng) {
          this.markers.push(this.convertDepositToMapMarker(deposit));
          }
        }
      })
    } else {
      this.markers[0].deposits += deposits.length;
    }
  }

  handleMarkerClick(marker) {
    this.site = marker.id;
    this.markers = this.markers.filter(m => m.id === marker.id);
  }

  handlePopUpClosed() {
    this.site = null;
  }

  convertDepositToMapMarker(deposit: Deposit, depositsAmount?: number): IMapMarker {
    return {
      lat: deposit.deposit_location.lat || null,
      lng: deposit.deposit_location.lng || null,
      name: deposit.deposit_location.name || null,
      deposits: depositsAmount || 0,
      id: deposit.deposit_location.id || null,
      address: deposit.deposit_location.address || null
    };
  }

  groupByDepositLocationId(deposits: Deposit[]): {[key: number]: Deposit[]} {
    return deposits.filter(d => d.deposit_location_id).reduce((obj, deposit) => {
      if (!obj.hasOwnProperty(deposit.deposit_location_id)) {
        obj[deposit.deposit_location_id] = [];
      }
      obj[deposit.deposit_location_id].push(deposit);
      return obj;
    }, {});
  }

  get isOpenPopUpOpen() {return !!this.site}

  resetMarkers() {
    if (this.isOpenPopUpOpen) {
      this.markers[0].deposits = 0;
    } else {
      this.markers = [];
    }
  }

  reset() {
    this.markers = [];
    this.site = null;
  }
}
