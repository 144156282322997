/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passport-collection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../entities/spinner/LoaderComponent.ngfactory";
import * as i3 from "../../../../entities/spinner/LoaderComponent";
import * as i4 from "../../../../shared/services/navigation.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../base/checkbox/base-checkbox.component.ngfactory";
import * as i7 from "../../base/checkbox/base-checkbox.component";
import * as i8 from "@angular/common";
import * as i9 from "../passport-Item/new-passport-item.component.ngfactory";
import * as i10 from "../passport-Item/new-passport-item.component";
import * as i11 from "../../../../shared/services/log-notification.service";
import * as i12 from "../../../../shared/services/navbar.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../../shared/services/current-user.service";
import * as i15 from "../../base/Page/pagination.component.ngfactory";
import * as i16 from "../../base/Page/pagination.component";
import * as i17 from "./passport-collection.component";
var styles_PassportCollectionComponent = [i0.styles];
var RenderType_PassportCollectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PassportCollectionComponent, data: {} });
export { RenderType_PassportCollectionComponent as RenderType_PassportCollectionComponent };
function View_PassportCollectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [["style", "width: 15px; height: 15px;"]], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.LoaderComponent, [i4.NavigationService], { width: [0, "width"], height: [1, "height"] }, null)], function (_ck, _v) { var currVal_0 = "45px"; var currVal_1 = "45px"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PassportCollectionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ". "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleSelectAllPages() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpod(5, { X: 0 }), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDeselectAllPages() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isAllPagesSelected ? (_co.totalPassportCount - _co.selectedPassports.length) : _co.selectedPassports.length); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform("Passports_selected")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform("Select_passports_all_pages", _ck(_v, 5, 0, _co.totalPassportCount))); _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("Deselect_all")); _ck(_v, 8, 0, currVal_3); }); }
function View_PassportCollectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "select-all-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-base-checkbox", [], null, [[null, "isCheckedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isCheckedChange" === en)) {
        var pd_0 = (_co.toggleSelectAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_BaseCheckboxComponent_0, i6.RenderType_BaseCheckboxComponent)), i1.ɵdid(2, 49152, null, 0, i7.BaseCheckboxComponent, [], { isChecked: [0, "isChecked"], label: [1, "label"] }, { isCheckedChange: "isCheckedChange" }), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassportCollectionComponent_5)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectAllChecked; var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform("selectDeselectAll")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.isAllPagesSelected || _co.selectedPassports.length); _ck(_v, 5, 0, currVal_2); }, null); }
function View_PassportCollectionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "passport-item-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "new-passport-item", [], null, [[null, "isSelectedChange"], [null, "deleteRequest"], [null, "duplicateRequest"], [null, "versionClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isSelectedChange" === en)) {
        var pd_0 = (_co.onSelectionChange(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } if (("deleteRequest" === en)) {
        var pd_1 = (_co.handleDeleteRequest($event) !== false);
        ad = (pd_1 && ad);
    } if (("duplicateRequest" === en)) {
        var pd_2 = (_co.handleDuplicateRequest($event) !== false);
        ad = (pd_2 && ad);
    } if (("versionClicked" === en)) {
        var pd_3 = (_co.handleVersionClicked($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i9.View_NewPassportItemComponent_0, i9.RenderType_NewPassportItemComponent)), i1.ɵdid(2, 49152, null, 0, i10.NewPassportItemComponent, [i11.LogNotificationService, i12.NavbarStateService, i13.Router, i14.CurrentUserService, i4.NavigationService, i5.TranslateService], { passport: [0, "passport"], isSelected: [1, "isSelected"], isSelectable: [2, "isSelectable"], isVersionSelected: [3, "isVersionSelected"], displayStatus: [4, "displayStatus"], actionButtonsConfig: [5, "actionButtonsConfig"] }, { isSelectedChange: "isSelectedChange", deleteRequest: "deleteRequest", versionClicked: "versionClicked", duplicateRequest: "duplicateRequest" }), (_l()(), i1.ɵted(-1, null, [" > "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.isSelected(_v.context.$implicit); var currVal_2 = _co.canSelect; var currVal_3 = _co.isVersionSelected; var currVal_4 = _co.showStatus; var currVal_5 = _co.actionButtonsConfig; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_PassportCollectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "passport-collection"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassportCollectionComponent_4)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassportCollectionComponent_6)), i1.ɵdid(4, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canSelect; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.passports; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PassportCollectionComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("No_Passport_Available")); _ck(_v, 1, 0, currVal_0); }); }
function View_PassportCollectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassportCollectionComponent_3)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noPassports", 2]], null, 0, null, View_PassportCollectionComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.passports.length; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PassportCollectionComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagination", [], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.onPageChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_PaginationComponent_0, i15.RenderType_PaginationComponent)), i1.ɵdid(1, 114688, null, 0, i16.PaginationComponent, [i4.NavigationService], { currentPage: [0, "currentPage"], totalPages: [1, "totalPages"] }, { pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPage; var currVal_1 = _co.totalPages; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PassportCollectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassportCollectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_PassportCollectionComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassportCollectionComponent_8)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.totalPages > 1); _ck(_v, 4, 0, currVal_2); }, null); }
export function View_PassportCollectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-passport-collection", [], null, null, null, View_PassportCollectionComponent_0, RenderType_PassportCollectionComponent)), i1.ɵdid(1, 573440, null, 0, i17.PassportCollectionComponent, [], null, null)], null, null); }
var PassportCollectionComponentNgFactory = i1.ɵccf("app-passport-collection", i17.PassportCollectionComponent, View_PassportCollectionComponent_Host_0, { passports: "passports", isLoading: "isLoading", currentPage: "currentPage", totalPages: "totalPages", totalPassportCount: "totalPassportCount", canSelect: "canSelect", showStatus: "showStatus", actionButtonsConfig: "actionButtonsConfig" }, { pageChange: "pageChange", deletePassportEvent: "deletePassportEvent", versionClicked: "versionClicked", selectAllPages: "selectAllPages", duplicateRequest: "duplicateRequest" }, []);
export { PassportCollectionComponentNgFactory as PassportCollectionComponentNgFactory };
