import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { PERFORMANCE_RADIOS, PERFORMANCE_RADIOS_VALUES } from '../../../values/performance-radios.const';
import { Passport } from '../../../models/passport.class';
import { EPassportCertificationVersion, PASSPORT_CERTIFICATION_LEVELS, PASSPORT_CERTIFICATION_VERSION_VALUE } from '../../../values/passport-certification-levels.const';
import moment from 'moment';
import { DEFAULT_RADIO_VALUES } from '../../../values/default-radio-values.const';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VIEW_MODE } from '../../../values/view-mode-values.const';
import { EPassportTabFormGroup, PASSPORT_CONTROLS_WITH_VALIDATION } from '../../../values/passport-validation-fields.const';
import { PassportGroupBuilderService } from '../../../services/passport-group-builder/passport-group-builder.service';
import { setTextareaHeightToFitContent } from '../../../../../shared/helpers/set-textarea-height-to-fit-content';
import { PassportCustomStoreService } from '../../../services/passport-custom-store.service';
var PassportSocialPerformanceComponent = /** @class */ (function () {
    function PassportSocialPerformanceComponent(translate, _fb, passportGroupBuilderService, passportChildComponentsEventListenerService) {
        var _this = this;
        this.translate = translate;
        this._fb = _fb;
        this.passportGroupBuilderService = passportGroupBuilderService;
        this.passportChildComponentsEventListenerService = passportChildComponentsEventListenerService;
        this.onUpdatePassportData = new EventEmitter();
        this.onElementWhichNotifiesClick = new EventEmitter();
        this.tabTypes = { MATERIAL_HEALTH: false,
            LIFE_CYCLE: false,
            CARBON_FOOTPRINT: false,
            WATER_STEWARDSHIP: false,
            ENERGY: false,
            SOCIAL_FAIRNESS: false };
        this.VIEW_MODE = VIEW_MODE;
        this.certificationLevels = PASSPORT_CERTIFICATION_LEVELS;
        this.certificationVersion = PASSPORT_CERTIFICATION_VERSION_VALUE;
        this.performanceRadios = PERFORMANCE_RADIOS;
        this.defaultRadioValues = DEFAULT_RADIO_VALUES;
        this.PASSPORT_CONTROLS_WITH_VALIDATION = PASSPORT_CONTROLS_WITH_VALIDATION;
        this.defaultControlsName = [
            'waterCycle', 'waterProduct', 'waterConstruction', 'waterUse', 'waterOperationalEnergy', 'waterOperationalWater', 'waterEndOfLife', 'waterResourceRecovery',
            'energyCycle', 'energyProduct', 'energyConstruction', 'energyUse', 'energyOperationalEnergy', 'energyOperationalWater', 'energyEndOfLife', 'energyResourceRecovery',
            'energyCycleNone', 'energyProductNone', 'energyConstructionNone', 'energyUseNone', 'energyOperationalEnergyNone', 'energyOperationalWaterNone', 'energyEndOfLifeNone', 'energyResourceRecoveryNone'
        ];
        this.controlNames = this.defaultControlsName.reduce(function (accumulator, item) {
            accumulator[item] = item;
            return accumulator;
        }, {});
        this.certificationLevelInputs = [
            {
                label: function () { return _this.translate.instant(_this.textTranslatedLevel) + " \u00AB Material Health \u00BB"; },
                formControlName: 'certificationLevelMaterialHealth',
                model: 'cert_material_health_level'
            }, {
                label: function () { return _this.translate.instant(_this.textTranslatedLevel) + " \u00AB Material Reuse \u00BB / \u00AB Product Circularity \u00BB"; },
                formControlName: 'certificationLevelMaterialReuse',
                model: 'cert_material_reuse_level'
            }, {
                label: function () { return _this.translate.instant(_this.textTranslatedLevel) + " \u00AB Renewable Energy & Carbon Management \u00BB "
                    + "/ \u00AB Clean Air & Climate Protection \u00BB"; },
                formControlName: 'certificationLevelCarbonManagement',
                model: 'cert_carbon_level'
            }, {
                label: function () { return _this.translate.instant(_this.textTranslatedLevel) + " \u00AB Water Stewardship \u00BB / \u00AB Water & Soil Stewarship \u00BB"; },
                formControlName: 'certificationLevelWaterStewardship',
                model: 'cert_water_level'
            }, {
                label: function () { return _this.translate.instant(_this.textTranslatedLevel) + " \u00AB Social Fairness \u00BB"; },
                formControlName: 'certificationLevelSocialFairness',
                model: 'cert_socials_level'
            }
        ];
        this.carbonFootprintInput = [
            {
                label: function () { return _this.cycleText; },
                formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_TOTAL,
                column: 'left'
            },
            {
                label: function () { return _this.productText; },
                formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_PRODUCT,
                column: 'left'
            },
            {
                label: function () { return _this.useStageText; },
                formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.USE_STAGE,
                column: 'left'
            },
            {
                label: function () { return _this.operationalWaterUseText; },
                formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.OPERATIONAL_WATER_USE,
                column: 'left'
            },
            {
                label: function () { return _this.resourceRecoveryStateText; },
                formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.RESOURCE_RECOVERY_STAGE,
                column: 'left'
            },
            {
                label: function () { return _this.carbonConstructionText; },
                formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_CONSTRUCTION,
                column: 'right'
            },
            {
                label: function () { return _this.operationalEnergyUseText; },
                formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.OPERATIONAL_ENERGY_USE,
                column: 'right'
            },
            {
                label: function () { return _this.carbonEndOfLifeText; },
                formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_END,
                column: 'right'
            }
        ];
        this.passportWaterStewardshipInputs = [
            {
                label: function () { return _this.waterText; },
                labelDescription: function () { return _this.cycleText; },
                formControlName: this.controlNames.waterCycle,
                model: 'water_cycle',
                inShort: false,
                column: 'left'
            }, {
                label: function () { return _this.waterText; },
                labelDescription: function () { return _this.productText; },
                formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.WATER_CONVERSION,
                model: 'water_conversion',
                inShort: true,
                column: 'left'
            }, {
                label: function () { return _this.waterText; },
                labelDescription: function () { return _this.constructionText; },
                formControlName: this.controlNames.waterConstruction,
                model: 'water_construction',
                inShort: false,
                column: 'right'
            }, {
                label: function () { return _this.waterText; },
                labelDescription: function () { return _this.useStageText; },
                formControlName: this.controlNames.waterUse,
                model: 'water_use_stage',
                inShort: false,
                column: 'left'
            }, {
                label: function () { return _this.waterText; },
                labelDescription: function () { return _this.operationalEnergyUseText; },
                formControlName: this.controlNames.waterOperationalEnergy,
                model: 'water_operational_energy_use',
                inShort: false,
                column: 'right'
            }, {
                label: function () { return _this.waterText; },
                labelDescription: function () { return _this.operationalWaterUseText; },
                formControlName: this.controlNames.waterOperationalWater,
                model: 'water_operational_water_use',
                inShort: false,
                column: 'left'
            }, {
                label: function () { return _this.waterText; },
                labelDescription: function () { return _this.endOfLifeText; },
                formControlName: this.controlNames.waterEndOfLife,
                model: 'water_end_of_life',
                inShort: true,
                column: 'right'
            }, {
                label: function () { return _this.waterText; },
                labelDescription: function () { return _this.resourceRecoveryStateText; },
                formControlName: this.controlNames.waterResourceRecovery,
                model: 'water_resource_recovery',
                inShort: false,
                column: 'left'
            }
        ];
        this.passportEnergyInputGroups = [
            {
                label: function () { return _this.cycleText; },
                inputs: [{
                        label: function () { return _this.renewableEnergyText; },
                        formControlName: this.controlNames.energyCycle,
                        model: 'energy_cycle_renewable'
                    }, {
                        label: function () { return _this.noneRenewableEnergyText; },
                        formControlName: this.controlNames.energyCycleNone,
                        model: 'energy_cycle'
                    }]
            },
            {
                label: function () { return _this.productText; },
                inputs: [{
                        label: function () { return _this.renewableEnergyText; },
                        formControlName: this.controlNames.energyProduct,
                        model: 'energy_product_renewable'
                    }, {
                        label: function () { return _this.noneRenewableEnergyText; },
                        formControlName: this.controlNames.energyProductNone,
                        model: 'energy_product'
                    }]
            },
            {
                label: function () { return _this.constructionText; },
                inputs: [{
                        label: function () { return _this.renewableEnergyText; },
                        formControlName: this.controlNames.energyConstruction,
                        model: 'energy_construction_renewable'
                    }, {
                        label: function () { return _this.noneRenewableEnergyText; },
                        formControlName: this.controlNames.energyConstructionNone,
                        model: 'energy_construction'
                    }]
            },
            {
                label: function () { return _this.useStageText; },
                inputs: [{
                        label: function () { return _this.renewableEnergyText; },
                        formControlName: this.controlNames.energyUse,
                        model: 'energy_use_stage_renewable'
                    }, {
                        label: function () { return _this.noneRenewableEnergyText; },
                        formControlName: this.controlNames.energyUseNone,
                        model: 'energy_use_stage'
                    }]
            },
            {
                label: function () { return _this.operationalEnergyUseText; },
                inputs: [{
                        label: function () { return _this.renewableEnergyText; },
                        formControlName: this.controlNames.energyOperationalEnergy,
                        model: 'energy_operational_energy_use_renewable'
                    }, {
                        label: function () { return _this.noneRenewableEnergyText; },
                        formControlName: this.controlNames.energyOperationalEnergyNone,
                        model: 'energy_operational_energy_use'
                    }]
            },
            {
                label: function () { return _this.operationalWaterUseText; },
                inputs: [{
                        label: function () { return _this.renewableEnergyText; },
                        formControlName: this.controlNames.energyOperationalWater,
                        model: 'energy_operational_water_use_renewable'
                    }, {
                        label: function () { return _this.noneRenewableEnergyText; },
                        formControlName: this.controlNames.energyOperationalWaterNone,
                        model: 'energy_operational_water_use'
                    }]
            },
            {
                label: function () { return _this.endOfLifeText; },
                inputs: [{
                        label: function () { return _this.renewableEnergyText; },
                        formControlName: this.controlNames.energyEndOfLife,
                        model: 'energy_end_of_life_renewable'
                    }, {
                        label: function () { return _this.noneRenewableEnergyText; },
                        formControlName: this.controlNames.energyEndOfLifeNone,
                        model: 'energy_end_of_life'
                    }]
            },
            {
                label: function () { return _this.resourceRecoveryStateText; },
                inputs: [{
                        label: function () { return _this.renewableEnergyText; },
                        formControlName: this.controlNames.energyResourceRecovery,
                        model: 'energy_resource_recovery_renewable'
                    }, {
                        label: function () { return _this.noneRenewableEnergyText; },
                        formControlName: this.controlNames.energyResourceRecoveryNone,
                        model: 'energy_resource_recovery'
                    }]
            },
        ];
        this.PASSPORT_CERTIFICATE_TYPE = {
            GLOBAL: {
                additionalLevels: true, isGlobal: true
            },
            MATERIAL_HEALTH: {
                additionalLevels: false, isGlobal: false
            }
        };
    }
    PassportSocialPerformanceComponent.prototype.ngOnInit = function () {
        var _a;
        // @ts-ignore
        this.formGroup = this.passportGroupBuilderService
            .buildSubGroup(EPassportTabFormGroup.PERFORMANCE, this.passport, this.parentFormGroup)
            .addControlOptions((_a = {
                certificationVersion: [this.passport.cert_version || EPassportCertificationVersion.VERSION_3]
            },
            // TODO check if Validators.pattern of CARBON_TOTAL, CARBON_PRODUCT should work.
            // TODO Right now Validators.pattern not working.
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_TOTAL] = [this.passport.carbon_total,
                [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_PRODUCT] = [this.passport.carbon_product,
                [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_END] = [this.passport.carbon_end,
                [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_CONSTRUCTION] = [this.passport.carbon_construction,
                [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.USE_STAGE] = [this.passport.use_stage,
                [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.OPERATIONAL_ENERGY_USE] = [this.passport.operational_energy_use,
                [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.RESOURCE_RECOVERY_STAGE] = [this.passport.resource_recovery_stage,
                [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_CONVERSION] = [this.passport.carbon_conversion,
                [Validators.min(0), this.getValidatorIsNotZero()]],
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.OPERATIONAL_WATER_USE] = [this.passport.carbon_operational_water_use, [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            _a)).build();
        console.log(this.formGroup);
        this.passportGroupBuilderService.addChildGroupToParent(this.parentFormGroup, this.formGroup, EPassportTabFormGroup.PERFORMANCE);
        if (this.passportChildComponentsEventListenerService) {
            this.passportChildComponentsEventListenerService
                .addFormProgrammaticlyAction.emit({ controlName: PASSPORT_CONTROLS_WITH_VALIDATION.SOCIAL_PERFORMANCE, control: this.formGroup, skip: true });
        }
        this.textTranslatedLevel = 'Level';
        this.waterText = 'Net use of fresh water';
        this.renewableEnergyText = "Use of renewable energy";
        this.noneRenewableEnergyText = "Use of non-renewable energy";
        this.cycleText = 'Total whole life cycle';
        this.productText = 'Production (A1-A3 module)';
        this.carbonProductText = 'Product stage (A1-A3 module)';
        this.constructionText = 'Construction (A4-A5 module)';
        this.carbonConstructionText = 'Construction process stage (A4-A5 module)';
        this.useStageText = 'Use stage (B1-B5 module)';
        this.operationalEnergyUseText = 'Operational energy use (B6 module)';
        this.operationalWaterUseText = 'Operational water use (B7 module)';
        this.endOfLifeText = 'End of life stage (C module)';
        this.carbonEndOfLifeText = 'End of life stage (C1-C4 module)';
        this.resourceRecoveryStateText = 'Reuse - recovery - recycling potential (D module)';
    };
    PassportSocialPerformanceComponent.prototype.getValidatorIsNotZero = function () {
        return function (control) {
            var value = control.value;
            if (typeof value !== 'number') {
                value = Number(!!value ? value : undefined);
            }
            return !Number.isNaN(value) && value === 0 ? { message: 'zero value not allowed' } : null;
        };
    };
    PassportSocialPerformanceComponent.prototype.getEpdType = function () {
        return this.passport && this.passport.epdType;
    };
    PassportSocialPerformanceComponent.prototype.setEpdType = function (epdType) {
        if (this.passport) {
            this.passport.epdType = epdType;
        }
    };
    PassportSocialPerformanceComponent.prototype.getSource = function () {
        return this.passport && this.passport.source;
    };
    PassportSocialPerformanceComponent.prototype.setSource = function (source) {
        if (this.passport) {
            this.passport.source = source;
        }
    };
    PassportSocialPerformanceComponent.prototype.isSourceOther = function () {
        return this.passport && this.passport.source ? this.passport.source == 99 : false;
    };
    PassportSocialPerformanceComponent.prototype.getOperator = function () {
        return this.passport && this.passport.operator;
    };
    PassportSocialPerformanceComponent.prototype.setOperator = function (operator) {
        if (this.passport) {
            this.passport.operator = operator;
        }
    };
    PassportSocialPerformanceComponent.prototype.isOperatorOther = function () {
        return this.passport && this.passport.operator ? this.passport.operator == 99 : false;
    };
    PassportSocialPerformanceComponent.prototype.isSynchronized = function () {
        return this.passport && !!this.passport.external_passport_id;
    };
    PassportSocialPerformanceComponent.prototype.getCountry = function () {
        return this.passport && this.passport.language;
    };
    PassportSocialPerformanceComponent.prototype.setCountry = function (country) {
        if (this.passport) {
            this.passport.language = country;
        }
    };
    /**
     * Check whether the synchronized fields (indicators and document info) are editable or not
     * They are editable if the document is not synchronized and the user can edit the passport
     * @returns {boolean}
     */
    PassportSocialPerformanceComponent.prototype.isSynchronizedFieldDisabled = function () {
        return this.isSynchronized() || this.areFormControlsDisabled;
    };
    PassportSocialPerformanceComponent.prototype.ngOnDestroy = function () {
        this.passportGroupBuilderService.unsubscribe(this.formGroup);
    };
    PassportSocialPerformanceComponent.prototype.getOptimizationsStrategyPlaceholder = function () {
        return this.translate.instant('describe an optimization strategy');
    };
    PassportSocialPerformanceComponent.prototype.areC2cAdditionalFieldsShown = function () {
        var certificateIncludesAdditionalInfo = [PERFORMANCE_RADIOS_VALUES.YES, PERFORMANCE_RADIOS_VALUES.MATERIAL_HEALTH];
        return this.passport ? certificateIncludesAdditionalInfo.includes(this.passport.c2c_certified) : false;
    };
    PassportSocialPerformanceComponent.prototype.getCertificationType = function () {
        if (this.passport.c2c_certified === PERFORMANCE_RADIOS_VALUES.YES) {
            return this.PASSPORT_CERTIFICATE_TYPE.GLOBAL;
        }
        else {
            return this.PASSPORT_CERTIFICATE_TYPE.MATERIAL_HEALTH;
        }
    };
    Object.defineProperty(PassportSocialPerformanceComponent.prototype, "certificateLevelPlaceholder", {
        get: function () {
            return this.getCertificationType().isGlobal ?
                this.translate.instant('Global certification level') : this.translate.instant('Level of certification');
        },
        enumerable: true,
        configurable: true
    });
    PassportSocialPerformanceComponent.prototype.onInputWhichNotifiesClick = function ($event) {
        this.onElementWhichNotifiesClick.emit($event);
    };
    PassportSocialPerformanceComponent.prototype.setCertificationExpirationDate = function (event) {
        this.passport.certification_expiration = this.getPickerDate(event) || this.passport.certification_expiration;
    };
    PassportSocialPerformanceComponent.prototype.getPickerDate = function (event) {
        if (event) {
            return moment(event).format('YYYY-MM-DD');
        }
    };
    /**
     * Actually got no clue what isVisible exactly does, but this method was used in the html
     * Will figure out later
     * @returns {boolean}
     */
    PassportSocialPerformanceComponent.prototype.isEnergyVisible = function () {
        return this.isVisible([
            'energyCycle', 'energyCycleNone',
            'energyProduct', 'energyProductNone',
            'energyConstruction', 'energyConstructionNone',
            'energyUse', 'energyUseNone',
            'energyOperationalEnergy', 'energyOperationalEnergyNone',
            'energyOperationalWater', 'energyOperationalWaterNone',
            'energyEndOfLife', 'energyEndOfLifeNone',
            'energyResourceRecovery', 'energyResourceRecoveryNone'
        ]);
    };
    PassportSocialPerformanceComponent.prototype.isVisible = function (name) {
        if (!Array.isArray(name)) {
            name = [name];
        }
        return this.passportGroupBuilderService.getVisibility(this.formGroup, name);
    };
    PassportSocialPerformanceComponent.prototype.isLongVersion = function () {
        return !!this.passportGroupBuilderService.getVisibilityLongControlValue(this.formGroup);
    };
    PassportSocialPerformanceComponent.prototype.setEndOfValidityForLCA = function (event) {
        this.passport.carbon_conversion_date = this.getPickerDate(event) || this.passport.carbon_conversion_date;
    };
    PassportSocialPerformanceComponent.prototype.isOpen = function (tabName) {
        return this.tabTypes[tabName];
    };
    PassportSocialPerformanceComponent.prototype.changeTab = function (tabName) {
        this.tabTypes[tabName] = !this.tabTypes[tabName];
    };
    PassportSocialPerformanceComponent.prototype.isLongVersionAndTabOpened = function (tabName) {
        return this.isOpen(tabName) && this.isLongVersion();
    };
    PassportSocialPerformanceComponent.prototype.isTabContentShown = function (tabName) {
        return this.isOpen(tabName) || !this.isLongVersion();
    };
    PassportSocialPerformanceComponent.prototype.expandLifecycleTextArea = function () {
        var _this = this;
        setTimeout(function () {
            return setTextareaHeightToFitContent([_this.lifeCycleDescriptionTextarea]);
        });
    };
    PassportSocialPerformanceComponent.prototype.expandEffortsForRenewableEnergyTextArea = function () {
        var _this = this;
        setTimeout(function () { return setTextareaHeightToFitContent([_this.effortsForRenewableEnergyTextarea
        ]); });
    };
    return PassportSocialPerformanceComponent;
}());
export { PassportSocialPerformanceComponent };
