import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Response} from '@angular/http';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { IReportRequest } from '../interfaces/reportRequest.interface';
import {Reports} from '../models/reports.class';
import {IReportResponse} from '../interfaces/reportResponse.interface';
import {ILiteral} from 'ng2-adn-common';

@Injectable()
export class ReportService extends ExtendedCrudService {
  protected namespace = 'reports';
  protected namespaceSingular = 'report';
  protected ModelClass = Reports;

  public getRequestReport(data: IReportRequest): Observable<IReportResponse> {
    return this.sendPost(this.getEndpoint(this.namespaceSingular + '/generate'), data, {search: {expand: 'sites.tags'}})
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }
  getReport(id: number, query?: ILiteral): Observable<IReportResponse> {
    return this.sendGet(this.getEndpoint(this.namespaceSingular) + '/' + id, {search: query})
      .map(res => res.json())
      .catch(this.onError.bind(this));
  }
  
  getActiveReport() {
    return this.sendGet(`${this.getEndpoint(this.namespaceSingular)}/active`)
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }

  acknowledgeReport() {
    return this.sendPost(`${this.getEndpoint(this.namespaceSingular)}/acknowledge`)
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }

  downloadRawReport(report_id) {
    return this.sendGet(`${this.getEndpoint(this.namespaceSingular)}/raw_report?report_id=${report_id}`)
          .map((res: Response) => res.json())
          .catch(this.onError.bind(this));
  }
}
