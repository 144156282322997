import {Component, OnInit} from '@angular/core';
import {MdDialogRef} from '@angular/material';
import {DepositLocation} from '../../../../entities/deposit/models/deposit-location.class';

@Component({
  selector: 'request-site-deletion-dialog',
  templateUrl: './request-site-deletion-dialog.component.html',
  styleUrls: ['./request-site-deletion-dialog.component.scss']
})
export class RequestSiteDeletionDialogComponent implements OnInit {

  siteList: DepositLocation[] = [];
  chosenSites: DepositLocation[] = [new DepositLocation()];

  constructor(private dialog: MdDialogRef<any>) { }

  ngOnInit() {
    this.siteList = this.dialog._containerInstance.dialogConfig.data.sites;
  }

  isDisabled() {
    return !this.chosenSet().size;
  }

  private chosenSet() {
    return new Set(this.chosenSites.filter(item => item.id).map(item => item.id));
  }

  updateChosen(chosen: DepositLocation, index: number) {
    this.chosenSites[index] = chosen;
  }

  getAvailableList(chosen: DepositLocation) {
    const chosenSet = this.chosenSet();
    return this.siteList.filter(item => !chosenSet.has(item.id) || chosen.id === item.id);
  }

  increaseChosenLength() {
    this.chosenSites.push(new DepositLocation());
  }

  confirmRequest() {
    this.dialog.close(this.chosenSites.filter(item => item.id));
  }

  cancel() {
    this.dialog.close(null);
  }
}
