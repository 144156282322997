import {User} from '../../shared/models/user.class';
import {OrganisationTIds} from '../../shared/values/company-values.enum';
import {ADMIN_ESM_OPTIONS, ERoleIds} from '../../shared/values/role-ids.enum';
import {IRolePermission, IRolePermissionContext} from '../../shared/interfaces/role-permission.interface';
import {EAdminPermission} from './values/admin-permission.enum';
import {AbstractPermission} from '../../shared/models/abstract-permission.class';

export class BuildingPermission extends AbstractPermission implements IRolePermission {
  public static readonly id = EAdminPermission.BUILDING_ADMIN;
  name = 'Building admin';

  isValid(user: User) {
    return user.company.organisation_type === OrganisationTIds.Building
      && user.plan_id !== ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN;
  }
}

export class RestrictedAdminPermission extends AbstractPermission implements IRolePermission {
  public static readonly id = EAdminPermission.RESTRICTED_ADMIN;
  name =  'Restricted admin';

  isValid(user: User): boolean {
    return user.plan_id === ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN;
  }
}

export const ADMIN_PERMISSIONS: IRolePermissionContext = {
  roleId: ERoleIds.ADMIN,
  permissions: {
    [RestrictedAdminPermission.id]: new RestrictedAdminPermission(),
    [BuildingPermission.id]: new BuildingPermission()
  },
  defaultPermissionsIdsBefore: [RestrictedAdminPermission.id],
  defaultPermissionsIdsAfter: [BuildingPermission.id]
};
