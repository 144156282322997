import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { MAP_IMPORT_PROGRESS_STATUS } from '../values/import-progres.const';
import * as i0 from "@angular/core";
var ImportProgressService = /** @class */ (function () {
    function ImportProgressService() {
        this.resetProgress$ = new EventEmitter();
        this.importPercentageValue = 0;
        this.showImportProgress = false;
        this.showImportProgressSubscription = new Subject();
        this.currentDepositId = null;
        this.currentStep = 1;
        this.DEPOSIT_IMPORT_STEP = { VALIDATION: 1, LOAD: 2 };
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.NOT_STARTED;
    }
    ImportProgressService.prototype.setImportPercentageValue = function (percentage) {
        this.importPercentageValue = percentage;
    };
    ImportProgressService.prototype.showImportProgressModal = function () {
        this.showImportProgress = true;
    };
    ImportProgressService.prototype.hideImportProgressModal = function () {
        this.showImportProgress = false;
    };
    ImportProgressService.prototype.setWarningModeProgressModal = function () {
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.WARNING;
    };
    ImportProgressService.prototype.setErrorModeProgressModal = function () {
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.ERROR;
    };
    ImportProgressService.prototype.setSuccessModeProgressModal = function () {
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.SUCCESS;
    };
    ImportProgressService.prototype.setProgressModeProgressModal = function () {
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.PROGRESS;
    };
    ImportProgressService.prototype.setCheckingmodeProgressModal = function () {
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.CHECKING;
    };
    ImportProgressService.prototype.setNotStartedModeProgressModal = function () {
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.NOT_STARTED;
    };
    ImportProgressService.prototype.canProgressModalBeClosed = function () {
        return ![MAP_IMPORT_PROGRESS_STATUS.PROGRESS, MAP_IMPORT_PROGRESS_STATUS.CHECKING].includes(this.statusImportProgress);
    };
    Object.defineProperty(ImportProgressService.prototype, "canStartImport", {
        get: function () {
            return this.canProgressModalBeClosed();
        },
        enumerable: true,
        configurable: true
    });
    ImportProgressService.prototype.resetProgressModal = function () {
        this.setNotStartedModeProgressModal();
        this.hideImportProgressModal();
        this.resetProgress$.emit(false);
    };
    ImportProgressService.prototype.startNewProcess = function () {
        this.setCheckingmodeProgressModal();
        this.showImportProgressModal();
        this.resetProgress$.emit(true);
    };
    ImportProgressService.prototype.setCurrentId = function (id) {
        this.currentDepositId = id;
    };
    ImportProgressService.prototype.setCurrentStep = function (v) {
        this.currentStep = v;
    };
    ImportProgressService.ngInjectableDef = i0.defineInjectable({ factory: function ImportProgressService_Factory() { return new ImportProgressService(); }, token: ImportProgressService, providedIn: "root" });
    return ImportProgressService;
}());
export { ImportProgressService };
