<md-dialog-content>
  <view-passport
    [passport]="mdDialogData.passport"
    [isViewMode]="true"
  ></view-passport>
  <!--<div class="ib-row">-->
  <!--<div class="ib-column ib-column-2-4">-->
  <!--<a md-raised-button routerLink=".">Choose this Passport</a>-->
  <!--</div>-->
  <!--<div class="ib-column ib-column-2-4">-->
  <!--<a md-raised-button routerLink=".">Edit this Passport</a>-->
  <!--</div>-->
  <!--</div>-->
</md-dialog-content>
