/**
 * Created by atat on 25/06/2017.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EcosystemOffer } from '../../ecosystem-offer.model';
import { ECOSYSTEM_STATUS } from '../../../../dashboards/ecosystem-manager/values/ecosystem-status.const';
import { EcosystemOfferService } from '../../ecosystem-offer.service';
import { Ecosystem } from '../../../../dashboards/ecosystem-manager/models/ecosystem.class';
import { NeedService } from '../../../need/services/need.service';
import { Need } from '../../../need/models/need.class';
import { EcosystemFileSelectorDialogComponent } from '../../../../dashboards/ecosystem-manager/dialogs/ecosystem-file-selector/ecosystem-file-selector.component';
import { EcosystemFile } from '../../../../dashboards/ecosystem-manager/models/ecosystem-file.class';
import { EcosystemFileJunction } from '../../../../dashboards/ecosystem-manager/models/ecosystem-file-junction.class';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../../../dashboards/ecosystem-manager/values/ecosystem-file-junction-item-type.const';
import { EcosystemFileJunctionService } from '../../../ecosystem/services/ecosystem-file-junctions.service';
import { MdDialog } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'view-offer',
  moduleId: module.id,
  templateUrl: 'view-offer.component.html'
})
export class ViewOfferComponent implements OnInit {
  offer: EcosystemOffer;
  need: Need;
  ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
  fileUploadeUrl: string;
  @Input() ecosystem: Ecosystem;
  @Output() action = new EventEmitter();

  offerForm: FormGroup;

  constructor(public dialog: MdDialog,
              private _fb: FormBuilder,
              private _ecosystemOfferService: EcosystemOfferService,
              private _ecoFileJunctionService: EcosystemFileJunctionService,
              private _needService: NeedService,
              private LognotificationService : LogNotificationService,
              private _translate: TranslateService) {

  }

  ngOnInit() {
    this.createForm();
    this.fetchData();
  }

  createForm() {
    this.offerForm = this._fb.group({
      deliveryFormat: [''],
      price: [''],
      quantity: [''],
      deliveryDate: [''],
      origin: [''],
    });
  }

  fetchData() {
    this._ecosystemOfferService
      .get({ecosystem_id: this.ecosystem.id, expand: 'ecosystem_file_junction'})
      .subscribe((offers: EcosystemOffer[]) => {
        if (offers.length > 0) {
          this.offer = offers.shift(); //Offer Exist
        } else {
          this.offer = new EcosystemOffer({ecosystem_id: this.ecosystem.id}); //New offer
          this.offer.save().subscribe(res => this.offer.id = res.id);
        }
        this.offer.ecosystem = this.ecosystem;
      });

    this._needService
      .view(this.ecosystem.need_id,
        {expand: 'client,passport,client_contact,client_location,passport_materials' +
            ',passport_components,nomenclature,sales_contact,passport.gtins'})
      .subscribe((need: Need) => {
        this.need = need;
        console.log(need);
        this.fileUploadeUrl = this._needService.getFileUploadUrl(this.need.id);
      });
  }

  validate() {
    this
      .offer
      .validate()
      .subscribe((res: EcosystemOffer) => {
        this.offer.id = res.id;
        this.offer.status = res.status;
        this.saveAndCloseDialog();
      })
    ;
  }

  requestValidation() {
    if (this.ecosystem.status == this.ECOSYSTEM_STATUS.DRAFT) {
      this.LognotificationService.error(this._translate.instant('Ecosystem must be published first'),null, true);
      return ;
    }
    if ( this.ecosystem.status != this.ECOSYSTEM_STATUS.WAITING_FOR_QUOTE) {
      this.LognotificationService.error(this._translate.instant('All steps must be validated in order to send the offer'),null, true);
      return ;
    }


    this
      .offer
      .publish()
      .subscribe((res: EcosystemOffer) => {
        this.offer.id = res.id;
        this.offer.status = res.status;
        this.offer.publication_date = res.publication_date;
        this.saveAndCloseDialog();
      })
    ;
  }

  confirm() {
    this
      .offer
      .confirm()
      .subscribe((res: EcosystemOffer) => {
        this.offer.status = res.status;
        this.saveAndCloseDialog();
      })
    ;
  }

  saveAndCloseDialog() {
    this.offer.save().subscribe(() => this.action.emit());
  }

  openFiles() {
    let dialogRef = this.dialog.open(EcosystemFileSelectorDialogComponent, {
      width: '800px',
      height: '600px',
      data: {
        ecosystem: this.ecosystem
      }
    });
    dialogRef.afterClosed().subscribe((file: EcosystemFile) => {
      if (file) {
        this.addFileToOffer(file);
      }
    });
  }

  addFileToOffer(file: EcosystemFile) {
    console.log("ADDING FILE ", file);
    let fileJunction = new EcosystemFileJunction({
      file_id: file.id,
      item_id: this.offer.id,
      item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_OFFER
    });
    this._ecoFileJunctionService
      .create(fileJunction)
      .subscribe((createdFileJunction: EcosystemFileJunction) => {
        if(createdFileJunction) {
          this.offer.ecosystem_file_junction.push(createdFileJunction);
        }
      });
  }

  removeJunctionFromOffer(junction: EcosystemFileJunction) {
    this._ecoFileJunctionService
      .remove(junction.id)
      .subscribe(() => {
        this.offer.ecosystem_file_junction
          .splice(this.offer.ecosystem_file_junction.indexOf(junction), 1);
      });
  }
}
