import { Injectable } from '@angular/core';
import {CrudService} from '../../../shared/services/crud.service';
import {DepositDocumentFile} from '../models/deposit-document-file.class';

@Injectable({
  providedIn: 'root'
})
export class DepositDocumentFileService extends CrudService {
  protected ModelClass = DepositDocumentFile;
  protected namespace = 'deposit-files';
  protected namespaceSingular = 'deposit-file';
}
