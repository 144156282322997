/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./documents-uploader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./document-item/document-item.component.ngfactory";
import * as i3 from "./document-item/document-item.component";
import * as i4 from "../../services/log-notification.service";
import * as i5 from "ng2-file-upload/file-upload/file-select.directive";
import * as i6 from "../../read-only/form-elems-read-only.directive";
import * as i7 from "../../read-only/read-only.service";
import * as i8 from "@angular/common";
import * as i9 from "./documents-uploader.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "@angular/platform-browser";
var styles_DocumentsUploaderComponent = [i0.styles];
var RenderType_DocumentsUploaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentsUploaderComponent, data: {} });
export { RenderType_DocumentsUploaderComponent as RenderType_DocumentsUploaderComponent };
function View_DocumentsUploaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "document-item", [], null, [[null, "remove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("remove" === en)) {
        var pd_0 = (_co.remove(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DocumentItemComponent_0, i2.RenderType_DocumentItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.DocumentItemComponent, [i4.LogNotificationService], { file: [0, "file"], isRemoveFromFilesDisabled: [1, "isRemoveFromFilesDisabled"] }, { remove: "remove" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = ((_co.isRemoveFromFilesDisabled || !_co.uploadUrl) || ((_co.readOnlyService == null) ? null : _co.readOnlyService.readOnly)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DocumentsUploaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "document-item", [], null, [[null, "remove"], [null, "onNameChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("remove" === en)) {
        var pd_0 = (_co.removeFromQueue(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("onNameChange" === en)) {
        var pd_1 = (_co.queueNameChange($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DocumentItemComponent_0, i2.RenderType_DocumentItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.DocumentItemComponent, [i4.LogNotificationService], { file: [0, "file"], docHref: [1, "docHref"], noLink: [2, "noLink"], srcImg: [3, "srcImg"], isImage: [4, "isImage"], extension: [5, "extension"], fileName: [6, "fileName"], transformFileNameBeforeEdit: [7, "transformFileNameBeforeEdit"], isRemoveFromFilesDisabled: [8, "isRemoveFromFilesDisabled"], disableNameChangeDefault: [9, "disableNameChangeDefault"] }, { onNameChange: "onNameChange", remove: "remove" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.$implicit.file["previewPath"]; var currVal_2 = !_co.enableQueueFileEdit; var currVal_3 = _v.context.$implicit.file["previewPath"]; var currVal_4 = _co.isImage(_v.context.$implicit.file.name); var currVal_5 = _co.getExtensionName(_v.context.$implicit.file.name); var currVal_6 = _v.context.$implicit.file.name; var currVal_7 = _co.queueNameTransform.bind(_co); var currVal_8 = _co.isRemoveFromFilesDisabled; var currVal_9 = _co.enableQueueFileEdit; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_DocumentsUploaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "upload-files__download"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUploadButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "upload-files__camera camera--icon upload-files__placeholder-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["fileInput", 1]], null, 2, "input", [["class", "upload-files__add"], ["ng2FileSelect", ""], ["type", "file"]], [[8, "disabled", 0], [8, "multiple", 0], [8, "readOnly", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i5.FileSelectDirective, [i1.ElementRef], { uploader: [0, "uploader"] }, null), i1.ɵdid(5, 16384, null, 0, i6.FormElemsReadOnlyDirective, [[2, i7.ReadOnlyService]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.uploader; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDownLoadDisabled; var currVal_1 = _co.isMultiple; var currVal_2 = ((i1.ɵnov(_v, 5).readOnlyService == null) ? null : i1.ɵnov(_v, 5).readOnlyService.readOnly); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_DocumentsUploaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { uploaderElem: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "ul", [["class", "upload-files__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsUploaderComponent_1)), i1.ɵdid(3, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsUploaderComponent_2)), i1.ɵdid(5, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsUploaderComponent_3)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.uploader.queue; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isDownloadEnable(); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_DocumentsUploaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "documents-uploader", [], null, null, null, View_DocumentsUploaderComponent_0, RenderType_DocumentsUploaderComponent)), i1.ɵdid(1, 245760, null, 0, i9.DocumentsUploaderComponent, [[2, i7.ReadOnlyService], i10.TranslateService, i4.LogNotificationService, i11.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentsUploaderComponentNgFactory = i1.ɵccf("documents-uploader", i9.DocumentsUploaderComponent, View_DocumentsUploaderComponent_Host_0, { uploadUrl: "uploadUrl", notShowClickErrors: "notShowClickErrors", service: "service", files: "files", uploadUrlMissingErrorText: "uploadUrlMissingErrorText", uploadUrlSet: "uploadUrlSet", customUploader: "uploader", type: "type", isRemoveFromFilesDisabled: "isRemoveFromFilesDisabled", isRemoveFromQueueDisabled: "isRemoveFromQueueDisabled", isDownLoadDisabled: "isDownLoadDisabled", hideUploadButton: "hideUploadButton", isMultiple: "isMultiple", beforeRemoveAction: "beforeRemoveAction", autoupload: "autoupload", onUploadAll: "onUploadAll", acceptedFormats: "acceptedFormats", maxFileSize: "maxFileSize", maxAllowedItems: "maxAllowedItems", isDisabled: "isDisabled", isRemoveDisabled: "isRemoveDisabled", enableQueueFileEdit: "enableQueueFileEdit" }, { onItemUploaded: "onItemUploaded", onAllItemsUploaded: "onAllItemsUploaded", onAfterAddingFile: "onAfterAddingFile", onAfterRemove: "onAfterRemove", onAfterQueueRemove: "onAfterQueueRemove", onClick: "onClick", isUploading: "isUploading" }, []);
export { DocumentsUploaderComponentNgFactory as DocumentsUploaderComponentNgFactory };
