/**
 * Created by atat on 26/06/2017.
 */
import {Component, HostListener, Input, OnChanges, OnDestroy} from '@angular/core';
import { EcosystemStep } from '../../../models/ecosystem-step.class';
import { OrderPassportService } from '../../../services/order-passport.service';
import { OrderPassport } from '../../../models/order-passport.class';
import { MdDialog } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { EcosystemEditComponent } from '../ecosystem-edit/ecosystem-edit.component';
import { NomenclatureItem } from '../../../../../entities/nomenclature/models/nomenclature-item.class';
import { VirginProduct } from '../../../../../entities/virgin-product/models/virgin-product.class';
import { VirginProductService } from '../../../../../entities/virgin-product/services/virgin-product.service';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';

@Component({
  moduleId: module.id,
  selector: 'esm-compiler-virgin-product',
  templateUrl: 'esm-compiler-virgin-product.component.html'
})
export class EsmCompilerVirginProductComponent implements OnChanges, OnDestroy {
  @Input() step: EcosystemStep;
  public virginProduct: VirginProduct = new VirginProduct;
  public orderPassport: OrderPassport;

  quantity: number;
  dateOfDelivery: string = 'TODO';

  materialId: number;
  public tags: NomenclatureItem[] = [];
  arrSection = [1, 1, 1, 1];
  dirty = false;


  constructor(public virginProductService: VirginProductService,
              public orderPassportsService: OrderPassportService,
              public dialog: MdDialog,
              private ecosystemEditComponent: EcosystemEditComponent,
              private globalEventsManager: GlobalEventsManager) {
  }

  @HostListener('focusin') onChange() {
    this.dirty = true;
  }

  ngOnChanges() {
    this.load();
  }

  saveStep() {
    this.globalEventsManager.compilerDataBeenUpdated = true;
    this.trySave().subscribe(() => {
      this.globalEventsManager.compilerDataUpdated.emit();
      this.globalEventsManager.compilerDataBeenUpdated = false;
    });
  }

  load() {
    if (this.dirty) {
      console.warn('loaded new step order ontop of an unsaved step??');
    }

    this.virginProductService
      .view(this.step.entity_id, {
        expand: 'passport,nomenclature',
      })
      .subscribe((virginProduct: VirginProduct) => {
        this.virginProduct = virginProduct;
        this.virginProduct.loaded = true;
      });

    this
      .orderPassportsService
      .get({
        child_step_id: this.step.id,
        expand: 'entity'
      })
      .subscribe((orderPassports: OrderPassport[]) => {
        if (orderPassports && orderPassports.length > 0) {
          this.orderPassport = orderPassports.find((orderPassport) => {
            return orderPassport.entity && orderPassport.entity.id === this.step.entity_id;
          });
          this.orderPassport.loaded = true;
          this.step.orderPassport = this.orderPassport;
        } else {
          this.orderPassport = null;
        }
      });
  }

  ngOnDestroy() {
    this.saveStep();
  }

  trySave(step?: EcosystemStep): Observable<EcosystemStep> {
    return new Observable((observer: Observer<EcosystemStep>) => {
      step = step || this.step;
      if (step.canEditOrder && this.dirty) {
        step.orderPassport.save().subscribe(
          value => observer.next(step),
          err => observer.error(err),
          () => {
            this.dirty = false;
            observer.complete();
          }

        );
        step.save().subscribe(
          value => observer.next(step),
          err => observer.error(err),
          () => {
            this.dirty = false;
            observer.complete();
          }
        );

      } else {
        observer.next(null);
        observer.complete();
      }
    });
    //this.arrSection[index] =+ !this.arrSection[index];
  }

  changeTab(index: number) {
    this.arrSection[index] = +!this.arrSection[index];
  }

  isOpen(index: number) {
    return this.arrSection[index];
  }

  sendEmail() {
    location.href = 'mailto:' + this.virginProduct.contact_email;
  }
}
