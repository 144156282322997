/**
 * Created by aleksandr on 30.03.17.
 */
import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { TRANSFORM_NAVIGATION } from './transformation-manager.navigation';
import { ERoleIds } from '../../shared/values/role-ids.enum';

export const TRANSFORM_CONTEXT: IRoleContextConfig = {
  navigation: TRANSFORM_NAVIGATION,
  moduleName: 'transform',
  themeClass: 'b-module-transform',
  roleId: ERoleIds.TRANSFORMATION_SITE_MANAGER,
  roleText: 'Transformation Site Manager',
  homeUrl: '/transform',
  apiModuleName: 'TSM',
  rootPath: 'transform'
};
