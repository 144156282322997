import * as tslib_1 from "tslib";
import { EcosystemOffer } from './ecosystem-offer.model';
import { ExtendedCrudService } from '../../shared/services/extended-crud.service';
var EcosystemOfferService = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemOfferService, _super);
    function EcosystemOfferService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'ecosystem-offers';
        _this.namespaceSingular = 'ecosystem-offer';
        _this.ModelClass = EcosystemOffer;
        return _this;
    }
    EcosystemOfferService.prototype.getResendUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/resend');
    };
    EcosystemOfferService.prototype.sendModification = function (id, modification) {
        return this.sendPost(this.getAskForModificationUrl(id), { modification: modification });
    };
    EcosystemOfferService.prototype.getCount = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemOfferService.prototype.sendReport = function (id, problem) {
        return this.sendPost(this.getReportUrl(id), { problem: problem });
    };
    EcosystemOfferService.prototype.getReportUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/problem');
    };
    EcosystemOfferService.prototype.resend = function (id, data) {
        return this.sendPost(this.getResendUrl(id), data)
            .map(function (res) { return new EcosystemOffer(res.json()); })
            .catch(this.onError.bind(this));
    };
    return EcosystemOfferService;
}(ExtendedCrudService));
export { EcosystemOfferService };
