import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { TransformService } from '../../../../entities/transformation/services/transform.service';
import { AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { FormControl } from '@angular/forms';
import { TRANSFORM_TYPES_WITH_SITES } from '../../../transformation-manager/values/transform-types.const';
import { MdDialogRef } from '@angular/material';
import { isIndependent } from '../../../../dashboards/transformation-manager/values/transform-dependency.const';
import { TRANSFORM_STATUSES } from '../../../../dashboards/transformation-manager/values/transform-statuses.const';
import { CurrentUser } from '../../../../shared/auth/current-user.class';
import { Searchable } from '../../../../shared/models/searchable.class';
import { TsmListService } from '../../../../entities/transformation/services/tsm-list.service';
import { NavbarStateService } from '../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'esm-transformation-dialog',
  templateUrl: 'esm-transformation-dialog.component.html',
})

/**
 * TODO Rename this
 */
export class EsmTransformationDialogComponent implements OnInit {
  transforms: Transform[];
  public mapBounds: LatLngBounds | LatLngBoundsLiteral = {north: 0, south: 0, east: 0, west: 0};

  // Filters
  search = new FormControl;
  type = 0;
  mapZoom = 8;
  tsms: Searchable[];
  tsmId: number;

  showIndependentTranformFilter : boolean ;
  TRANSFORM_TYPES = TRANSFORM_TYPES_WITH_SITES;
  initialZoomApplied = false;
  showIndependentTransformation = 0;

  constructor(private transformService: TransformService,
    private _dialog: MdDialogRef<any>,
    private changeDetectorRef: ChangeDetectorRef,
    private tsmListService: TsmListService,
    private navBarState: NavbarStateService) {
      this
      .tsmListService
      .get()
      .subscribe((tsms: Searchable[]) => this.tsms = tsms);
    }

  ngOnInit() {
    this.showIndependentTranformFilter = CurrentUser.infoData.has_transform_acces
    // transformations with sites  

    this.getRecords();
  }

  getRecords() {
    console.log('lol');
    let params: any = {
      nopaginate: 1,
      supervisor: 1,
      'status[]': [TRANSFORM_STATUSES.PUBLISHED.value],
      expand: 'sites,tsm_name,tsm_org',
      search: this.search.value || null,
      transformation_type: this.type || null,
      independent : this.showIndependentTransformation,
      tsm: this.tsmId || null,
    }

    if (this.navBarState.multilanguage) {
      params.english = true;
    }

    this.transformService
      .get(params)
      .subscribe((transforms: Transform[]) => {
        this.transforms = transforms;
        console.log(this.transforms.length);
        this.mapBounds = this.getMapBounds(this.transforms);
        this.initialZoomApplied = false;
        this.changeDetectorRef.detectChanges();
      });
    }

  onMapReady() {
    if (!this.initialZoomApplied) {
      this.mapZoom = 15;
      this.mapBounds = this.getMapBounds(this.transforms);
      this.changeDetectorRef.detectChanges();
      this.initialZoomApplied = true;
    }
  }

  getMapBounds(transforms: Transform[]): LatLngBounds {
    let bounds: any = new google.maps.LatLngBounds();

    transforms.forEach((transform) => {
      transform.sites.forEach((site) => {
        if (site.lat && site.lng) {
          bounds.extend(site);
        }
      });
    });
    return bounds;
  }

  isTransformIndependent(independent: number): boolean {
    return isIndependent(independent);
  }

  closeDialog() {
    this._dialog.close();
  }

  toggleIndependent(checked: boolean) {
    this.showIndependentTransformation = +checked;
    this.getRecords();
  }
}
