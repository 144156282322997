import { Injectable, NgZone } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { LogNotificationService } from '../../../shared/services/log-notification.service';
import { ValidationErrorsToMessage } from '../pipes/validation-errors-to-message.pipe';
import { getPassportValidationFieldValue, PASSPORT_CONTROLS_WITH_VALIDATION } from '../values/passport-validation-fields.const';

@Injectable()
export class PassportValidationNotificationService {

    private childPassportForm: AbstractControl;

    set passportForm(passportForm: AbstractControl) {
        this.childPassportForm = passportForm;
    }

    public clearPassportForm() {
        this.childPassportForm = null;
    }

    constructor(private notification: LogNotificationService
                , private _validationMessage: ValidationErrorsToMessage, private zone: NgZone) {}

    public showNotificationErrors(validator: AbstractControl) {
        if (validator.invalid) {
            const errors = this.getMessages(validator);
            console.log(errors);
            this.zone.run(() => this.notification.error(this._validationMessage.transform(errors)));
            return true;
        } else {
            return false;
        }
    }

    public showChildNotificationErrors() {
        if (this.childPassportForm) {
            return this.showNotificationErrors(this.childPassportForm);
        } else {
            console.warn('child component have not subscribed to validation notification service');
            return false;
        }
    }

    public getMessages(passportForm: any): string[] {
        const invalidControls = this.getInvalidPassportControls(passportForm);
        return invalidControls.map(field => {
          console.log(field);
          const label = getPassportValidationFieldValue(field);
          return !!label ? label : '';
        });
    }

    private getInvalidPassportControls(passportForm: FormGroup) {
        const invalid = [];
        const controls = passportForm['controls'];
        for (const field in controls) {
            if (controls[field].invalid) {
              console.log(field);
                if (field === PASSPORT_CONTROLS_WITH_VALIDATION.COMPONENTS) {
                    this.getInvalidFieldOfFormGroupsInFormArray(controls[field])
                      .forEach(errorField => invalid.push(errorField));
                } else {
                    if (field === PASSPORT_CONTROLS_WITH_VALIDATION.SOCIAL_PERFORMANCE) {
                        this.getInvalidFieldOfFormGroup((controls[field] as FormGroup))
                          .forEach(errorField => invalid.push(errorField));
                    } else {
                        invalid.push(field);
                    }
                }
            }
        }
        return invalid;
    }

    private getInvalidFieldOfFormGroupsInFormArray(formArray: any) {
        let invalidFields: Set<string> = new Set();
        formArray.controls.forEach((group: FormGroup) => {
            if (group.invalid) {
               invalidFields =  this.getInvalidFieldOfFormGroup(group, invalidFields);
            }
        });
        return invalidFields;
    }

    private getInvalidFieldOfFormGroup(formGroup: FormGroup, invalidFields?: Set<string>) {
        invalidFields = invalidFields || new Set();
        Object.keys(formGroup.controls).filter(controllerName => formGroup.controls[controllerName].invalid)
          .forEach(controllerName => {
            if (!(controllerName in invalidFields)) {
                invalidFields.add(controllerName);
            }
        });
        return invalidFields;
    }
}
