import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { DepositLocationFile } from '../models/deposit-location-file.class';
var DepositLocationFileService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationFileService, _super);
    function DepositLocationFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'deposit-location-files';
        _this.namespaceSingular = 'deposit-location-file';
        _this.ModelClass = DepositLocationFile;
        return _this;
    }
    return DepositLocationFileService;
}(ExtendedCrudService));
export { DepositLocationFileService };
