import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { ECOSYSTEM_STEP_TYPE } from '../../values/ecosystem-step-type.const';

@Component({
  moduleId: module.id,
  templateUrl: 'step-confirm.component.html',
  styleUrls: ['step-confirm.component.scss'],
})
export class StepConfirmDialogComponent {
  type: number;
  ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP_TYPE;
  constructor(@Inject(MD_DIALOG_DATA) private mdDialogData: any,
              public mdDialogRef: MdDialogRef<any>,) {
    this.type = mdDialogData.type;
  }

  confirmText(): string {
    let text = '';
    switch (this.type) {
        case ECOSYSTEM_STEP_TYPE.NEED:
          text = 'Do you confirm the delivery has been made as ordered?';
          break;
        case ECOSYSTEM_STEP_TYPE.DEPOSIT:
            text = 'Do you confirm that the deposit has been picked as ordered?';
            break;
        case ECOSYSTEM_STEP_TYPE.TRANSFORM:
            text = 'Do you confirm that the transformation has been made as ordered?';
            break;
        case ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT:
            text = 'Do you confirm that the virgin product has been made as ordered?';
            break;
        case ECOSYSTEM_STEP_TYPE.STAKEHOLDER:
            text = 'Do you confirm that the stakeholder has been made as ordered?';
            break;
        default:
          break;
    }
    return text;
  }

  chatText(): string {
    let text = '';
    switch (this.type) {
        case ECOSYSTEM_STEP_TYPE.DEPOSIT:
            text = 'Chat with owner';
            break;
        case ECOSYSTEM_STEP_TYPE.TRANSFORM:
            text = 'Chat with tsm';
            break;
        default:
            text = 'Chat with Need manager';
            break;
    }
    return text;
  }

  showChatButton(): boolean {
    return this.type !== ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT && this.type !== ECOSYSTEM_STEP_TYPE.STAKEHOLDER;
  }
}
