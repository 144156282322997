
import {EventEmitter} from '@angular/core';
import {
  IErrorImportErrorRecordContainer,
  IErrorReadyCompletionRecordContainer, ISpinner,
  ISpinnerCompletionRecord,
  ISpinnerCompletionRecordContainer,
  ISpinnerErrorReadyRecord, ISpinnerImportErrorRecord
} from '../../spinner/spinner-completion.interface';
import {EDeepStreamMessage} from '../../values/deepstream-message-type.enum';
import {BaseDeepStreamService} from './base-deepstream.service';

export class DepositLocationDeepstreamService extends BaseDeepStreamService {

  private static _depositLocationImportErrorsStream$: EventEmitter<IErrorReadyCompletionRecordContainer>
    = new EventEmitter<IErrorReadyCompletionRecordContainer>();
  private static _depositLocationCompletionStream$: EventEmitter<ISpinnerCompletionRecordContainer>
    = new EventEmitter<ISpinnerCompletionRecordContainer>();
  private static _depositLocationImportParsedRowsStream$: EventEmitter<ISpinnerCompletionRecordContainer>
    = new EventEmitter<ISpinnerCompletionRecordContainer>();
  private static _depositLocationImportFatalError$: EventEmitter<IErrorImportErrorRecordContainer>
    = new EventEmitter<IErrorImportErrorRecordContainer>();

  protected messageTypes: EDeepStreamMessage[] = [
    EDeepStreamMessage.SITE_IMPORT_ERRORS,
    EDeepStreamMessage.SITE_IMPORT_PROGRESS,
    EDeepStreamMessage.SITE_IMPORT_PROCESS_DOC,
    EDeepStreamMessage.SITE_IMPORT_PROGRESS_ERROR
  ];

  public receiver: string;

  public emit(data: ISpinnerCompletionRecord | ISpinnerErrorReadyRecord) {
    const emittedValue = { target: this.receiver, data };
    switch (data.type) {
      case EDeepStreamMessage.SITE_IMPORT_ERRORS:
        return this.depositImportErrorsStream$.emit(emittedValue as ISpinner<ISpinnerErrorReadyRecord>);
      case EDeepStreamMessage.SITE_IMPORT_PROGRESS:
        return this.depositCompletionStream$.emit(emittedValue as ISpinner<ISpinnerCompletionRecord>);
      case EDeepStreamMessage.SITE_IMPORT_PROCESS_DOC:
        return this.depositImportParsedRowsStream$.emit(emittedValue as ISpinner<ISpinnerCompletionRecord>);
      case EDeepStreamMessage.SITE_IMPORT_PROGRESS_ERROR:
        return this.depositImportFatalError$.emit(emittedValue as ISpinner<ISpinnerImportErrorRecord>);
    }
  }

  public get depositImportErrorsStream$(): EventEmitter<IErrorReadyCompletionRecordContainer> {
    return DepositLocationDeepstreamService._depositLocationImportErrorsStream$;
  }
  public get depositCompletionStream$(): EventEmitter<ISpinnerCompletionRecordContainer> {
    return DepositLocationDeepstreamService._depositLocationCompletionStream$;
  }
  public get depositImportParsedRowsStream$(): EventEmitter<ISpinnerCompletionRecordContainer> {
    return DepositLocationDeepstreamService._depositLocationImportParsedRowsStream$;
  }
  public get depositImportFatalError$(): EventEmitter<IErrorImportErrorRecordContainer> {
    return DepositLocationDeepstreamService._depositLocationImportFatalError$;
  }

}
