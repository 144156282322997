import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformQuality } from '../models/transform-quality.class';
var TransformQualityService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformQualityService, _super);
    function TransformQualityService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-qualities';
        _this.namespaceSingular = 'transformation-quality';
        _this.ModelClass = TransformQuality;
        return _this;
    }
    return TransformQualityService;
}(CrudService));
export { TransformQualityService };
