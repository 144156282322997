import { DepositLocation } from '../../entities/deposit/models/deposit-location.class';
import { IMapMarker } from '../map-view/map-marker.interface';

export class DepositNeedMapMarkersService {
    site: Number;
    markers: IMapMarker[] = [];

    get isOpenPopUpOpen() {
        return !!this.site
    }

    /**
     * Update the markers present on the map by placing the locations received
     * If a pop up is opened, ignore this
     * Else, reset the markers and create a marker for each new location
     * @param {DepositLocation[]} locations - The locations to set on the map
     */
    updateMarkers(locations: DepositLocation[]) {
        if (this.isOpenPopUpOpen) {
            const locationWithSiteId = locations.find(location => location.id === this.site);
            if (locationWithSiteId) {
                const markerToUpdate = this.markers.find(marker => Number(marker.id) === Number(this.site));
                if (markerToUpdate) {
                    markerToUpdate.deposits = locationWithSiteId.count;
                    markerToUpdate.totalCount = locationWithSiteId.count;
                }
                return;
            }   
        }
        this.reset();
        locations.forEach(location => {
            if (location.lat && location.lng && !this.markers.some(marker => marker.id === Number(location.id))) {
                this.markers.push(this.convertLocationToMapMarker(location));
            }
        })
    }

    /**
     * Handler of the click action on a marker
     * Set the site attribute to the marker id and remove all markers except the one focused
     * @param {IMapMarker} marker - The marker which was clicked on
     */
    handleMarkerClick(marker) {
        this.site = marker.id;
        this.markers = [marker];
    }

    /**
     * Handler of the pop up being closed
     * Empty the site value
     */
    handlePopUpClosed() {
        this.site = null;
    }

    /**
     * Convert a location into a marker to be represented on the map
     * @param {DepositLocation} location - The location to convert
     * @returns {IMapMarker}
     */
    convertLocationToMapMarker(location: DepositLocation): IMapMarker {
        return {
            lat: location.lat || null,
            lng: location.lng || null,
            name: location.name || null,
            deposits: location.count || 0,
            id: location.id || null,
            address: location.address || null,
            totalCount: location.count || 0,
        };
    }

    /**
     * Reset the markers
     */
    resetMarkers() {
        if (this.isOpenPopUpOpen) {
            this.markers[0].deposits = 0;
        } else {
            this.markers = [];
        }
    }

    /**
     * Reset the markers and the current site
     */
    reset() {
        this.markers = [];
        this.site = null;
    }
}
