import {
  Component, EventEmitter, OnInit, Output, ViewChild, ElementRef,
  HostListener, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../../shared/services/navigation.service';

// Uses OnPush change detection strategy for better performance
@Component({
  selector: 'app-selectable-list-filter',
  templateUrl: './selectable-list-filter.component.html',
  styleUrls: ['./selectable-list-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectableListFilterComponent implements OnInit, OnChanges {
  // Access to DOM elements
  @ViewChild('filterWrapper') filterWrapper: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  // Component inputs
  @Input() selectedItems: string[] = []; // List of selected items if you want to pre-populate the selection
  @Input() items: string[] = []; // All available items for selection
  @Input() placeholder: string = ''; // Placeholder text for the search input
  @Input() maxItemsDisplayed: number = 3; // Max items to display before showing "+more" label
  @Input() enableSearch: boolean = true; // Flag to enable or disable search functionality
  @Input() disabled = false; // Flag to disable the component

  // Component output - emits changes in the selection
  @Output() selectionChange = new EventEmitter<string[]>();

  // Local state variables
  filteredOptions = []; // Filtered list based on search term
  searchTerm: string = ''; // Current search term
  isOpen = false; // Flag for toggle display of the list
  allSelected = false; // Flag to indicate if all items are selected
  filteredOptionsCount: number = 0; // Count of currently filtered options
  moreItemsLabel: string = ''; // Label for displaying "+more" items

  constructor(private translateService: TranslateService, private _navigation: NavigationService) { }

  ngOnInit() {
    this.updateFilteredOptions(); // Initialize filtered options based on inputs
  }

  ngOnChanges(changes: SimpleChanges): void {
    // React to changes in input properties, especially 'selectedItems'
    if (changes.selectedItems && this.searchTerm === '') {
      this.updateFilteredOptions();
    }
  }

  // Updates the filtered options list according to the current search term.
  updateFilteredOptions() {
    if (!this.items) {
      return;
    }
    // Prepare options with immediate translation.
    this.filteredOptions = this.items
      .map(item => ({
        name: item,
        translatedName: this.translateService.instant(item), // Immediate translation assumed.
        selected: this.selectedItems.includes(item),
        sortOrder: this.getSortOrder(item) // Determine custom sort order.
      }))
      .sort((a, b) => {
        // Prioritize custom sort order.
        if (a.sortOrder !== b.sortOrder) {
          return a.sortOrder - b.sortOrder;
        }
        // If sort orders match, use translated names for sorting.
        return a.translatedName.localeCompare(b.translatedName);
      });
    this.filteredOptionsCount = this.filteredOptions.length; // Update count of filtered options.
    this.allSelected = this.filteredOptions.every(option => option.selected); // Check if all options are selected.
    this.updateMoreItemsLabel(); // Update label for additional items.
  }

  // Determines the sort order for an item based on its value.
  getSortOrder(item: string): number {
    // Assign a higher sort order to special cases to place them at the end.
    if (item === 'Other') {
      return 2; // Place 'Other' near the end.
    } else if (item === 'unknownSource' || item === 'unknownOperator' || item === 'Unknown_epd_type') {
      return 3; // Place 'Unknown' values at the very end.
    }
    return 1; // Default sort order for all other items.
  }

  // Filters available options based on the search term
  filterOptions() {
    let optionsToFilter = this.items.map(item => ({
      name: item,
      translatedName: this.translateService.instant(item),
      selected: this.selectedItems.includes(item),
      sortOrder: this.getSortOrder(item)
    }));

    if (this.searchTerm) {
      optionsToFilter = optionsToFilter
        .filter(option => option.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
        .sort((a, b) => this.translateService.instant(a.name).localeCompare(this.translateService.instant(b.name)));
    }

    this.filteredOptions = optionsToFilter;
    this.filteredOptionsCount = this.filteredOptions.length;
  }


  // Handles changes in selection, emits the new selection, and updates UI accordingly
  onChange() {
    const newSelection = this.filteredOptions.filter(option => option.selected).map(option => option.name);
    this.selectionChange.emit(newSelection);
    this.selectedItems = newSelection;
    this.updateMoreItemsLabel();
    this.allSelected = this.filteredOptions.every(option => option.selected);
  }

  onOptionChange(option) {
    option.selected = !option.selected;
    this.onChange();
  }

  onAllSelectedChange() {
    this.allSelected = !this.allSelected;
    this.filteredOptions.forEach(option => option.selected = this.allSelected);
    this.onChange();
  }

  // Toggles the view between open and closed states
  toggleView() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.filterOptions();
      setTimeout(() => {
        if (this.searchInput && this.searchInput.nativeElement) {
          this.searchInput.nativeElement.focus();
        }
      }, 0); // Un délai de 0 assure que cela s'exécute après que les événements de rendu du DOM sont terminés
    }
  }

  // Removes a tag from the selection
  removeTag(tagName: string, event: Event): void {
    event.stopPropagation();
    const index = this.filteredOptions.findIndex(option => option.name === tagName);
    if (index !== -1) {
      this.filteredOptions[index].selected = false;
      this.onChange();
    }
  }

  // Updates the label for displaying "+more" items
  updateMoreItemsLabel() {
    const selectedCount = this.selectedItems.length;
    this.moreItemsLabel = selectedCount > this.maxItemsDisplayed ? `+${selectedCount - this.maxItemsDisplayed}` : '';
  }

  // Utility function for trackBy in *ngFor to improve performance
  trackByFn(index, item) {
    return item.name;
  }

  // Closes the filter dropdown when clicking outside of it
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (this.isOpen && !this.filterWrapper.nativeElement.contains(targetElement)) {
      this.isOpen = false;
    }
  }

  /**
 * Determines the CSS class for coloring pagination based on the user's role.
 * 
 * @returns The CSS class corresponding to the user's role.
 */
  getClassColor(): string {
    if(!this._navigation || !this._navigation.getContext()) return 'role-guest';
    let role = this._navigation.getContext().roleText;
    switch (role) {
      case 'Agent':
        return 'role-agent';
      case 'Manufacturer':
        return 'role-manufacturer';
      case 'Specialadmin':
        return 'role-specialadmin';
      default:
        return '';
    }
  }
}
