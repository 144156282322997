import { Component, OnInit, Inject } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'deposit-delete-modal',
  templateUrl: 'deposit-delete-modal.component.html',
  styleUrls: ['deposit-delete-modal.component.scss']
})
export class DepositDeleteModalComponent implements OnInit {
  warningMessage = '';

  constructor(public mdDialogRef: MdDialogRef<void>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: {count: number, options: any},
    private _service: DepositService,
    private _translate: TranslateService) { }

  ngOnInit() {
    this._translate.get('DELETE_DEPOSITS_WARNING_MESSAGE', {X: this.mdDialogData.count})
    .subscribe((res: string) => this.warningMessage += res);
  }

  confirm() {
    this._service.deleteFiltereds(this.mdDialogData.options).subscribe(data => {
      this.mdDialogRef.close({
        resetSelection: true,
        deposits: data
      });
    });
  }

  closeDialog() {
    this.mdDialogRef.close({
      resetSelection: false
    });
  }
}
