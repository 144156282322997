/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../client/forms/contact-form/contact-form.component.ngfactory";
import * as i2 from "../../../client/forms/contact-form/contact-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../../client/service/contact.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/material";
import * as i7 from "@angular/common";
import * as i8 from "./create-contact.component";
var styles_CreateContactDialogComponent = [];
var RenderType_CreateContactDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateContactDialogComponent, data: {} });
export { RenderType_CreateContactDialogComponent as RenderType_CreateContactDialogComponent };
function View_CreateContactDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "client-contact-form", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ContactFormComponent_0, i1.RenderType_ContactFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.ContactFormComponent, [i3.FormBuilder, i4.ContactService, i5.TranslateService], { clientId: [0, "clientId"], asDialog: [1, "asDialog"] }, { onSave: "onSave" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clientId; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CreateContactDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "md-dialog-content", [], [[2, "mat-dialog-content", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i6.MdPrefixRejector, [[2, i6.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i6.MdDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateContactDialogComponent_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.contact; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_CreateContactDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "create-contact", [], null, null, null, View_CreateContactDialogComponent_0, RenderType_CreateContactDialogComponent)), i0.ɵdid(1, 245760, null, 0, i8.CreateContactDialogComponent, [i4.ContactService, i6.MdDialogRef, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateContactDialogComponentNgFactory = i0.ɵccf("create-contact", i8.CreateContactDialogComponent, View_CreateContactDialogComponent_Host_0, { clientId: "clientId" }, {}, []);
export { CreateContactDialogComponentNgFactory as CreateContactDialogComponentNgFactory };
