import {AfterContentChecked, ChangeDetectorRef, Component, ElementRef, Input, ViewChildren} from '@angular/core';
import {DepositLocation} from '../../../../../../../entities/deposit/models/deposit-location.class';
import {DepositTag} from '../../../../../../../entities/deposit/models/deposit-tag.class';
import {ReportSiteTagPresenterService} from '../../../../../services/report-site-tag-presenter.service';

@Component({
  selector: 'report-site-list',
  templateUrl: './report-site-list.component.html',
  styleUrls: ['./report-site-list.component.scss'],
  providers: [ReportSiteTagPresenterService]
})
export class ReportSiteListComponent implements AfterContentChecked{
  @Input() set sites(sites: DepositLocation[]) {
    this._sites = sites || [];
    this.isContentUpdate = true;
    this.tagPresenter.updateSiteList(this._sites);
  }
  @Input() set tags(tags: DepositTag[]) {
    this.isContentUpdate = true;
    this.tagPresenter.updateTagsById((tags || []).map(tag => tag.id ? tag.id : Number(tag)));
  }
  @Input() createdByFile = false;
  siteTags: {[id: number]: DepositTag[]} = {};
  _sites: DepositLocation[] = [];
  @ViewChildren('siteReport') siteBoxViewList: ElementRef[];
  private isContentUpdate = false;

  constructor(public tagPresenter: ReportSiteTagPresenterService, private changeDetection: ChangeDetectorRef) {}

  ngAfterContentChecked() {
    if (this.siteBoxViewList && this.isContentUpdate) {
      try {setTimeout(() => this.prettifyTagContainer()); } catch (e) {}
    }
  }

  prettifyTagContainer() {
      this.isContentUpdate = false;
      const parsePxToNumber = (data: string) => Number((data.match(/\d+/) || [0])[0]);
      this.siteBoxViewList.forEach(el => {
        const styles = window.getComputedStyle(el.nativeElement);
        if (el.nativeElement.clientHeight > parsePxToNumber(styles.minHeight)) {
          let maxWidth = 0;
          for (let i = 0; i < el.nativeElement.children.length; i++) {
            const child = el.nativeElement.children[i];
            const x = child.offsetLeft + child.clientWidth - el.nativeElement.offsetLeft;
            maxWidth = maxWidth < x ? x : maxWidth;
          }
          el.nativeElement.style.width = (maxWidth + parsePxToNumber(styles.paddingLeft)
            + parsePxToNumber(styles.paddingRight)) + 'px';
        }
      });
    
  }
}
