export const DEMOUNTABILITY_STATES = [
  {
    value: 0,
    view: 'Unknown',
  },
  {
    value: 1,
    view: 'Already separated',
  },
  {
    value: 2,
    view: 'Easily separable',
  },
  {
    value: 3,
    view: 'Hardly separable',
  },
  {
    value: 4,
    view: 'Inseparable',
  }
];

export const DEMOUNTABILITY_MAP = {
  0: 'Unknown',
  1: 'Already separated',
  2: 'Easily separable',
  3: 'Hardly separable',
  4: 'Inseparable'
}
