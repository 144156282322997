<header>
  {{ 'Formula of the global ranking system' }}
</header>
<br>
<br>
<form [formGroup]="formulaForm" *ngIf="formula">
  <md-input-container class="additionaly__quantity-input" style="width: 25px">
    <input
      mdInput
      autocomplete="off" 
      pattern="[0-9]*"
      formControlName="coefficient1"
      [(ngModel)]="formula.coefficient1"
    />
  </md-input-container>
  * MATERIAL ^
  <md-input-container class="additionaly__quantity-input" style="width: 25px">
    <input
      mdInput
      autocomplete="off" 
      pattern="[0-9]*"
      formControlName="exponent1"
      [(ngModel)]="formula.exponent1"
    />
  </md-input-container>
  +
  <md-input-container class="additionaly__quantity-input" style="width: 25px">
    <input
      mdInput
      autocomplete="off" 
      pattern="[0-9]*"
      formControlName="coefficient2"
      [(ngModel)]="formula.coefficient2"
    />
  </md-input-container>
  * DISTANCE ^
  <md-input-container class="additionaly__quantity-input" style="width: 25px">
    <input
      mdInput
      autocomplete="off" 
      pattern="[0-9]*"
      formControlName="exponent2"
      [(ngModel)]="formula.exponent2"
    />
  </md-input-container>
  +
  <md-input-container class="additionaly__quantity-input" style="width: 25px">
    <input
      mdInput
      autocomplete="off" 
      pattern="[0-9]*"
      formControlName="coefficient3"
      [(ngModel)]="formula.coefficient3"
    />
  </md-input-container>
  * USES ^
  <md-input-container class="additionaly__quantity-input" style="width: 25px">
    <input
      mdInput
      autocomplete="off" 
      pattern="[0-9]*"
      formControlName="exponent3"
      [(ngModel)]="formula.exponent3"
    />
  </md-input-container>
  +
  <md-input-container class="additionaly__quantity-input" style="width: 25px">
    <input
      mdInput
      autocomplete="off" 
      pattern="[0-9]*"
      formControlName="coefficient4"
      [(ngModel)]="formula.coefficient4"
    />
  </md-input-container>
  * KEYWORDS ^
  <md-input-container class="additionaly__quantity-input" style="width: 25px">
    <input
      mdInput
      autocomplete="off" 
      pattern="[0-9]*"
      formControlName="exponent4"
      [(ngModel)]="formula.exponent4"
    />
  </md-input-container>
  <button class="additionaly__button text"
          [disabled]="!formulaForm.valid || !formulaForm.dirty"
          (click)="save()"
  >{{'Save' | translate}}
  </button>

</form>
