<article class="ecosystem-card" ontouchstart="this.classList.toggle('hover');" (click)="openEcosystem()">
  <div >
    <!-- <div class="ecosystem-card__frontside" *ngIf="checkStatusInProcess()">
      <div class="ecosystem-card__folder-header" >
        <div class="ecosystem-card__folder-name">
          <span *ngIf="canDisplayFolderName()">/ {{ getFolderName(50) }}</span>
        </div>
        <div class="ecosystem-card__folder-checkbox">
          <md-checkbox (click)="onCheckbox($event)" (change)="onCheckboxChange($event.checked)" [(ngModel)]="ecosystem.checked">
          </md-checkbox>
        </div>
      </div>
      <div class="ecosystem-card__spinner">
        <md-progress-spinner
          mode="determinate"
          [value]="ecosystem.progress">
        </md-progress-spinner>
        <div class="ecosystem-card__spinner-text">
          <div class="ecosystem-card__spinner-label">{{ ECOSYSTEM_STATUS_VIEW[ecosystem.status] | translate}}</div>
          <div class="ecosystem-card__spinner-progress">{{ecosystem.steps_complete}} {{ 'ALTTRANSLATION:of' | translate}}
            {{ecosystem.steps_total}}
          </div>
        </div>
      </div>
      <div class="ecosystem-card__title"
      md-tooltip="{{ ecosystem.name }}"
      ><span>{{ getEcosystemDisplayName(50) }}</span> {{ 'Ecosystem' | translate }}</div>
    </div> -->
    <div class="ecosystem-card__backside"
         >
         <div class="ecosystem-card__folder-header" >
          <div class="ecosystem-card__folder-name">
            <img src="assets/png/folder-mini.png" class="ecosystem-card__folder-name-img" *ngIf="canDisplayFolderName()">
            <span> {{ getFolderName(50) }}</span>
          </div>
          <div class="ecosystem-card__folder-checkbox">
            <md-checkbox class="ecosystem-card__folder-checkbox-inner" (click)="onCheckbox($event)" (change)="onCheckboxChange($event.checked)" [(ngModel)]="ecosystem.checked">
            </md-checkbox>
          </div>
        </div>
      <header class="ecosystem-card__header">
       
        <div class="ecosystem-card__header-title">
          <div class="ecosystem-card__subtitle" md-tooltip="{{ ecosystem.name }}">{{getEcosystemDisplayName(50) }} </div>
          <div class="ecosystem-card__footer-group">
            <span class="ecosystem-card__footer-title">{{ 'Delivery' | translate }}:</span>
            <span class="ecosystem-card__footer-name">
              {{ (ecosystem.ecosystem_offer?.confirmation_date ? ecosystem.ecosystem_offer.confirmation_date : ecosystem.delivery_date) | date: 'dd.MM.yyyy' }}
            </span>            
          </div>
          <div class="ecosystem-card__footer-group">
            <span class="ecosystem-card__footer-title">{{ 'Recipient' | translate }}:</span>
            <span class="ecosystem-card__footer-name">
              <!--{{ ecosystem.need.delivery_date | date: 'dd.MM.yyyy' }} {{ ecosystem.need.date_precision_text | translate}}-->
              {{ ecosystem.client_name }}
            </span>
          </div>
          <div>
            <div class="ecosystem-card__status-container" [ngClass]="getStatusClass()" *ngIf="ecosystem.need_id">{{ ECOSYSTEM_STATUS_VIEW[ecosystem.status] | translate}}</div>
            <div class="ecosystem-card__status-label" *ngIf="!ecosystem.need_id">{{'Need deleted' | translate}}</div>
          </div>
          <!-- <div class="ecosystem-card__subtitle--offer" *ngIf="isOfferSent()">
            {{ 'Offer sent' | translate }}: <span>{{ ecosystem?.publication_date | date: 'dd.MM.yyyy' }}</span>
          </div> -->
        </div>
        <!-- <div class="ecosystem-card__spinner ecosystem-card__spinner--small"
             *ngIf="checkStatusInProcess()">
          <md-progress-spinner
            mode="determinate"
            [value]="ecosystem.progress">
          </md-progress-spinner>
        </div> -->
      </header>
      <section class="ecosystem-card__body">
        <div class="ecosystem-card__step-group">
          <div class="ecosystem-card__step-group-title">{{ 'Need' | uppercase | translate }}</div>
          <div class="ecosystem-card__step-item" *ngIf="ecosystem.need_id">
              <div [ngStyle]="{'color': getNeedStatusStyle(ecosystem.ecosystem_offer)}" class="ecosystem-card__step-item-check">
                {{ shouldDisplayNeedCheck(ecosystem.ecosystem_offer) ?  '✓' : ''}}
              </div>
              <div [ngStyle]="{'color': getNeedStatusStyle(ecosystem.ecosystem_offer)}" class="ecosystem-card__step-item-title"> {{ecosystem.need.name}}</div>            
          </div>
        </div>
        <div class="ecosystem-card__step-group">
          <div class="ecosystem-card__step-group-title">{{ 'Deposit' | uppercase | translate }}</div>
          <div class="ecosystem-card__step-item" *ngFor="let step of deposits">
            <div [ngStyle]="{'color': getStepStatusStyle(step)}" class="ecosystem-card__step-item-check">
              {{ shouldDisplayCheck(step) ?  '✓' : ''}}
            </div>
            <div [ngStyle]="{'color': getStepStatusStyle(step)}" class="ecosystem-card__step-item-title"> {{step.title}}</div>
          </div>
        </div>
        <div class="ecosystem-card__step-group">
          <div class="ecosystem-card__step-group-title">{{ 'Transformation' | uppercase | translate }}</div>
          <div class="ecosystem-card__step-item" *ngFor="let step of transformations">
            <div [ngStyle]="{'color': getStepStatusStyle(step)}" class="ecosystem-card__step-item-check">
              {{ shouldDisplayCheck(step) ?  '✓' : ''}}
            </div>
            <div [ngStyle]="{'color': getStepStatusStyle(step)}" class="ecosystem-card__step-item-title"> {{step.title}}</div>
          </div>
        </div>
        <!-- <ul class="ecosystem-card__step-group">
          <li class="ecosystem-card__step-item" *ngFor="let step of deposits">
            <div class="{{ step.cardClass }}">
              <span class="ecosystem-card__step-text">
                <span class="ecosystem-card__step-icon"><i class='icon icon--{{ step.cardClass }}'></i></span>
                {{step.title}}
              </span>
            </div>
          </li>
        </ul>
        <ul class="ecosystem-card__step-group">
          <li class="ecosystem-card__step-item" *ngFor="let step of transformations">
            <div class="{{ step.cardClass }}">
              <span class="ecosystem-card__step-text">
                <span class="ecosystem-card__step-icon"><i class="icon icon--{{ step.cardClass }}"></i></span>
                {{filterState.multilanguage ? step.english_title : step.title}}
              </span>
            </div>
          </li>
        </ul>
        <ul class="ecosystem-card__step-group">
          <li class="ecosystem-card__step-item" *ngFor="let step of stakeholders">
            <div class="{{ step.cardClass }}">
              <span class="ecosystem-card__step-text">
                <span class="ecosystem-card__step-icon"><i class="icon icon--{{ step.cardClass }}"></i></span>
                {{ step.title }}
              </span>
            </div>
          </li>
        </ul>
        <ul class="ecosystem-card__step-group">
          <li class="ecosystem-card__step-item" *ngFor="let step of virginProducts">
            <div class="{{ step.cardClass }}">
              <span class="ecosystem-card__step-text">
                <span class="ecosystem-card__step-icon"><i class="icon icon--{{ step.cardClass }}"></i></span>
                {{ step.title }}
              </span>
            </div>
          </li>
        </ul> -->
      </section>
      <ul class="ecosystem-card__footer">
        <!-- <li class="ecosystem-card__footer-group">
          <span class="ecosystem-card__footer-title">{{ 'Recipient' | translate }}:</span>
          <span class="ecosystem-card__footer-name">{{ ecosystem.client_name }}</span>
        </li>
        <li class="ecosystem-card__footer-group">
          <span class="ecosystem-card__footer-title">{{ 'Need' | translate }}:</span>
          <span class="ecosystem-card__footer-name--color">{{ ecosystem.need.name }}</span>
        </li> -->
        <li class="ecosystem-card__footer-data">
          <div class="ecosystem-card__footer-left">
            <div class="ecosystem-card__footer-group">
              <span class="ecosystem-card__footer-title">{{ 'Created' | translate }}:</span>
              <span class="ecosystem-card__footer-name">{{ ecosystem.created_at | date: 'dd.MM.yyyy' }}</span>
            </div>
          </div>
          <div class="ecosystem-card__footer-right">
            <div class="ecosystem-card__footer-group">
              <span class="ecosystem-card__footer-title">{{ 'Modified' | translate }}:</span>
              <span class="ecosystem-card__footer-name">{{ ecosystem.updated_at | date: 'dd.MM.yyyy' }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</article>
