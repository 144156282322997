export enum DO_STATISTICS_SEGMENTS {
  DEPOSITS = 1,
  AGENTS = 2,
  SITES = 3,
}

export const DO_STATISTICS_SEGMENTS_VIEW = {
  1: 'Deposits',
  2: 'Agents',
  3: 'Sites',
};
