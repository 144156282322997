import * as tslib_1 from "tslib";
import { NotificationEntry } from './models/notification-entry.model';
import { CrudService } from '../services/crud.service';
var NotificationService = /** @class */ (function (_super) {
    tslib_1.__extends(NotificationService, _super);
    function NotificationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'notification';
        _this.namespaceSingular = 'notification';
        _this.ModelClass = NotificationEntry;
        return _this;
    }
    NotificationService.prototype.readEndpointUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/read');
    };
    NotificationService.prototype.read = function (id) {
        return this.sendPost(this.readEndpointUrl(id));
    };
    NotificationService.prototype.getUnreadNotificationsCount = function (role_id) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + '/get-unread-count?role=' + role_id))
            .map(function (response) { return response.json(); })
            .catch(this.onError.bind(this));
    };
    NotificationService.prototype.getNotifications = function (role_id, page) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + '?expand=params&role=' + role_id + '&page=' + page))
            .catch(this.onError.bind(this));
    };
    return NotificationService;
}(CrudService));
export { NotificationService };
