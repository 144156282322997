import * as tslib_1 from "tslib";
import { CrudService } from './crud.service';
import { BIM } from '../models/bim.class';
var BIMService = /** @class */ (function (_super) {
    tslib_1.__extends(BIMService, _super);
    function BIMService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'bim-tool';
        _this.ModelClass = BIM;
        return _this;
    }
    return BIMService;
}(CrudService));
export { BIMService };
