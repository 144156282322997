import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {ICircularityCategory} from '../../../../../interfaces/circularity.interface';
import {PIE_CHART_SIZE} from '../../../../../values/pie-chart-size.const';
import { ReportBase } from '../../report-base/report-base.class';

@Component({
  moduleId: module.id,
  selector: 'circularity-product-category-block',
  templateUrl: 'circularity-product-category-block.component.html',
  styleUrls: ['circularity-product-category-block.component.scss']
})
export class CircularityProductCategoryBlockComponent extends ReportBase implements OnInit {
  @Input() data: ICircularityCategory;
  @Input() title: string;
  @Input() mainColor: string;
  public pieChartSize = PIE_CHART_SIZE;
  constructor(private _render: Renderer2) {
    super();
  }

  ngOnInit() {
    this.incomingDataProcessing();
  }
  incomingDataProcessing(): void {
    for (const item in this.data) {
      if (typeof this.data[item] === 'number') {
        this.data = {
          ...this.data,
          [item]: this.data[item]
        };
      }
    }
  }
}
