import {Component, Input, OnChanges, OnInit, HostListener, ViewChild, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { PassportFamily } from '../../../../models/passport.class';
@Component({
  moduleId: module.id,
  selector: 'family-filter',
  templateUrl: 'family-filter.component.html'
})
export class FamilyFilterComponent implements OnInit, OnChanges {

    @ViewChild('filterFamily') filterFamily;
    @Output() FamilyFilterChange = new EventEmitter<Array<number>>();
    @Input() userRole = 'AGENT';
    @Input() ownPassportOnly = false;
    isOpen = false;
    filterList: Array<{name: string, short: String, value: boolean, family: PassportFamily}> = [];
    selectMap: Object = {};
    allSelected: boolean;
    familyFilter: Array<Number> = [];
    familySelected: Array<Object> = [];
    private skipOnChange = false;
    @Input('familyFilter') set setFamilyFilter(items: number[]) {
      const itemSet =  new Set(items);
      this.allSelected = true;
        this.filterList.forEach(item => {
          item.value = itemSet.has(item.family);
          this.allSelected = this.allSelected && itemSet.has(item.family);
      });
        this.skipOnChange = true;
    }
    constructor() {
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
      if (this.isOpen && !this.filterFamily.nativeElement.contains(targetElement)) {
         this.isOpen = false;
         // this.ref.detectChanges();
      }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.ownPassportOnly && !changes.ownPassportOnly.previousValue && changes.ownPassportOnly.currentValue) {

            this.filterList = [
                {
                    name: 'FAMILY_VIEW_TYPE_1',
                    short: 'FAMILY_SHORT_VIEW_TYPE_1',
                    value: true,
                    family: PassportFamily.FAMILY_LOCAL
                }
            ];
            this.onChange();
        }
        if (changes.ownPassportOnly && changes.ownPassportOnly.previousValue && !changes.ownPassportOnly.currentValue) {
            this.initFilters();
        }
    }

    ngOnInit() {
      this.initFilters();
    }

    initFilters() {
        switch (this.userRole) {
            case 'supervisor':
            case 'sales':
            case 'agent':
                this.filterList = [
                    {
                        name: 'FAMILY_VIEW_TYPE_1',
                        short: 'FAMILY_SHORT_VIEW_TYPE_1',
                        value: true,
                        family: PassportFamily.FAMILY_LOCAL
                    },
                    {
                        name: 'FAMILY_VIEW_TYPE_2',
                        short: 'FAMILY_SHORT_VIEW_TYPE_2',
                        value: true,
                        family: PassportFamily.FAMILY_MANUFACTURER
                    },
                    {
                        name: 'FAMILY_VIEW_TYPE_3',
                        short: 'FAMILY_SHORT_VIEW_TYPE_3',
                        value: true,
                        family: PassportFamily.FAMILY_GENERIC
                    }
                ];
                break;
            default:
                this.filterList = [
                    {
                        name: 'FAMILY_VIEW_TYPE_2',
                        short: 'FAMILY_SHORT_VIEW_TYPE_2',
                        value: true,
                        family: PassportFamily.FAMILY_MANUFACTURER
                    },
                    {
                        name: 'FAMILY_VIEW_TYPE_3',
                        short: 'FAMILY_SHORT_VIEW_TYPE_3',
                        value: true,
                        family: PassportFamily.FAMILY_GENERIC
                    }
                ];
        }
        this.onChange();
    }

    toggleView() {
        this.isOpen = !this.isOpen;
    }

    onChange() {
        this.allSelected = this.filterList.reduce((acc, curr) => acc && curr.value, true);
        this.familyFilter = this.filterList.reduce((acc, curr) => (curr.value ? [ ...acc, curr.family] : acc), [] );
        this.familySelected = this.filterList.reduce((acc, curr) => (curr.value ? [ ...acc, curr] : acc), [] );
        this.updateParent(this.familyFilter);
    }

    onAllSelectedChange() {
      if (this.skipOnChange) {
        this.filterList = this.filterList.map((d) => ({...d, value: !this.allSelected}));
        this.familyFilter = this.filterList.reduce((acc, curr) => (curr.value ? [...acc, curr.family] : acc), []);
        this.familySelected = this.filterList.reduce((acc, curr) => (curr.value ? [ ...acc, curr] : acc), [] );
        this.updateParent(this.familyFilter);
      }
      this.skipOnChange = false;
    }

    updateParent(d) {
        this.FamilyFilterChange.emit(d);
    }

    get shouldDisplayFilterLogo() {
        return this.familyFilter.length > 0 && !this.allSelected;
    }

    removeCategoryTag({ family },event) {
        const index = this.filterList.findIndex((d) => d.family === family);
        this.filterList[index].value = false;
        this.onChange();
    }
}