import * as tslib_1 from "tslib";
import { EcosystemStepService } from '../../../entities/ecosystem/services/ecosystem-step.service';
import { ECOSYSTEM_STEP } from '../values/ecosystem-step-type.const';
import { ECOSYSTEM_STEP_STATUS } from '../values/ecosystem-step-status.const';
import * as PLUMB from '../values/plumb-styles.const';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { TransformLocation } from '../../../entities/transformation/models/transform-location.class';
import { TransformOrder } from './transform-order.class';
import { ECOSYSTEM_OFFER_STATUS, GET_NEED_STEP_STATUS_LABEL } from '../values/ecosystem-offer-status.const';
import { ECOSYSTEM_STATUS } from '../values/ecosystem-status.const';
import { VirginProduct } from '../../../entities/virgin-product/models/virgin-product.class';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { DatePipe } from '@angular/common';
import { GET_TRANSFORM_ORDER_STEP_STATUS_LABEL } from '../../transformation-manager/values/transform-order-statuses.const';
var IS_VALIDATABLE_STEP = ECOSYSTEM_STEP.IS_VALIDATABLE_STEP;
var ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP.TYPE;
var IS_CONFIRMABLE_STEP = ECOSYSTEM_STEP.IS_CONFIRMABLE_STEP;
import { Deposit } from '../../../entities/deposit/models/deposit.class';
import { Transform } from '../../../entities/transformation/models/transform.class';
import { EcosystemProblem } from '../../../entities/ecosystem/models/ecosystem-problem.model';
var EcosystemStep = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemStep, _super);
    function EcosystemStep(data) {
        var _this = _super.call(this, Object.assign({
            linked_date: '',
        }, data)) || this;
        _this.provider = EcosystemStepService;
        _this.orders = []; //TODO check if it is necessary to have an array here
        _this.datePipe = ServiceLocator.injector.get(DatePipe);
        data = !!data ? data : {};
        if (data.source) {
            var source = data.source;
            _this.title = source.name;
            _this.entity_id = source.id;
            _this.type = source.plumb_type;
            if (source instanceof TransformLocation) {
                _this.title = source.transformation_name;
                _this.entity_id = source.transformation_id;
                _this.site_id = source.id;
            }
            if (source instanceof VirginProduct) {
                _this.title = source.passport.name;
            }
        }
        if (data.deposit) {
            _this.deposit = new Deposit(data.deposit);
        }
        if (data.transformation) {
            _this.transform = new Transform(data.transformation);
        }
        if (data.orders) {
            _this.orders = data.orders.map(function (item) { return new TransformOrder(); });
        }
        if (data.problem) {
            _this.problem = new EcosystemProblem(data.problem);
        }
        if (data.estimated_distance || data.estimated_distance === 0) {
            _this["estimated_distance"] = data.estimated_distance;
        }
        _this.x_pos = _this.x_pos || 200;
        _this.y_pos = _this.y_pos || 100;
        return _this;
    }
    EcosystemStep.toSelectorId = function (id) {
        return "item_" + id;
    };
    EcosystemStep.fromSelectorId = function (selectorId) {
        return Number(selectorId.replace('item_', ''));
    };
    Object.defineProperty(EcosystemStep.prototype, "endpointsParams", {
        get: function () {
            var _a;
            var TYPE_ENDPOINTS = (_a = {},
                _a[ECOSYSTEM_STEP_TYPE.NEED] = [PLUMB.SINGLE_TARGET_ENDPOINT],
                _a[ECOSYSTEM_STEP_TYPE.DEPOSIT] = [PLUMB.SOURCE_ENDPOINT],
                _a[ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT] = [PLUMB.SOURCE_ENDPOINT],
                _a[ECOSYSTEM_STEP_TYPE.STAKEHOLDER] = [PLUMB.SOURCE_ENDPOINT, PLUMB.MULTI_TARGET_ENDPOINT],
                _a[ECOSYSTEM_STEP_TYPE.TRANSFORM] = [PLUMB.SOURCE_ENDPOINT, PLUMB.MULTI_TARGET_ENDPOINT],
                _a);
            return (TYPE_ENDPOINTS[this.type] || [])
                .map(function (endpoint) { return Object.assign({}, endpoint); });
        },
        enumerable: true,
        configurable: true
    });
    EcosystemStep.prototype.updateEndpoints = function (isEnabled) {
        var _this = this;
        this.endpoints.forEach(function (endpoint) {
            var _a;
            endpoint.setEnabled(isEnabled);
            endpoint.canvas.className = '';
            (_a = endpoint.canvas.classList).add.apply(_a, _this.endpointClass);
        });
    };
    EcosystemStep.prototype.updateOverlay = function () {
        try {
            this.overlay && this.overlay.setLabel(this.datePipe.transform(this.linked_date, 'dd-MM-y') || '');
        }
        catch (err) {
            console.warn(err);
        }
    };
    Object.defineProperty(EcosystemStep.prototype, "selectorId", {
        get: function () {
            return EcosystemStep.toSelectorId(this.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "selectorParentId", {
        get: function () {
            return EcosystemStep.toSelectorId(this.parent_id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "extraClass", {
        get: function () {
            return [
                this.typeClass,
                this.getStatusCssClass(),
            ].map(function (s) { return "plumb__item--" + s; }).join(' ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "overlayClass", {
        get: function () {
            var classes = [
                'ecosystem-edit__linked-date',
            ];
            if (this.type === ECOSYSTEM_STEP_TYPE.NEED) {
                classes.push('ecosystem-edit__linked-date-top');
            }
            else {
                classes.push('ecosystem-edit__linked-date-bottom');
            }
            return classes.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "endpointClass", {
        get: function () {
            return [
                'plumb__endpoint',
                'plumb__endpoint--' + this.getStatusCssClass(),
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "cardClass", {
        get: function () {
            var statusClass = '';
            if (this.type !== ECOSYSTEM_STEP_TYPE.NEED) {
                statusClass = 'ecosystem-card__step-status--' + ECOSYSTEM_STEP_STATUS[this.status].toLowerCase();
            }
            else {
                var status_1 = this.ecosystem.need.statusData;
                statusClass = 'ecosystem-card__step-status--' + status_1.text.toLowerCase();
            }
            return [
                statusClass,
            ].join(' ');
        },
        enumerable: true,
        configurable: true
    });
    //TODO Both deposit and transform order statuses are defined in GET_TRANSFORM_ORDER_STEP_STATUS_LABEL
    EcosystemStep.prototype.getStatusText = function () {
        var statusText = '';
        switch (this.type) {
            //this.order_agregate is one used before
            case ECOSYSTEM_STEP_TYPE.DEPOSIT:
                statusText = GET_TRANSFORM_ORDER_STEP_STATUS_LABEL(this.order_aggregate);
                break;
            case ECOSYSTEM_STEP_TYPE.TRANSFORM:
                statusText = GET_TRANSFORM_ORDER_STEP_STATUS_LABEL(this.order_aggregate);
                break;
            case ECOSYSTEM_STEP_TYPE.NEED:
                if (this.ecosystem.ecosystem_offer) {
                    statusText = GET_NEED_STEP_STATUS_LABEL(this.ecosystem.ecosystem_offer.status);
                }
                else {
                    statusText = GET_NEED_STEP_STATUS_LABEL(ECOSYSTEM_OFFER_STATUS.DRAFT);
                }
                break;
            default:
                statusText = ECOSYSTEM_STEP_STATUS[this.status];
        }
        return statusText.replace(/_/g, ' ');
    };
    EcosystemStep.prototype.getStatusCssClass = function () {
        var cssClass = '';
        switch (this.type) {
            case ECOSYSTEM_STEP_TYPE.NEED:
                cssClass = this.ecosystem.ecosystem_offer
                    ? ECOSYSTEM_OFFER_STATUS[this.ecosystem.ecosystem_offer.status]
                    : 'draft';
                break;
            //TODO double check if it breaks anything
            case ECOSYSTEM_STEP_TYPE.DEPOSIT:
                cssClass = this.order
                    ? ECOSYSTEM_STEP_STATUS[this.order.status]
                    : ECOSYSTEM_STEP_STATUS[this.status];
                if (this.status != ECOSYSTEM_OFFER_STATUS.DRAFT)
                    cssClass = this.deposit ? cssClass : ECOSYSTEM_STEP_STATUS[ECOSYSTEM_STEP_STATUS.REFUSED];
                break;
            case ECOSYSTEM_STEP_TYPE.TRANSFORM:
                cssClass = this.orders && this.orders.length > 0
                    ? ECOSYSTEM_STEP_STATUS[this.orders
                        .map(function (order) { return order.status; })
                        .reduce(function (lowestStatus, status) { return status < lowestStatus ? status : lowestStatus; })]
                    : ECOSYSTEM_STEP_STATUS[this.status];
                if (this.status != ECOSYSTEM_OFFER_STATUS.DRAFT)
                    cssClass = this.transform ? cssClass : ECOSYSTEM_STEP_STATUS[ECOSYSTEM_STEP_STATUS.REFUSED];
                break;
            default:
                cssClass = ECOSYSTEM_STEP_STATUS[this.status];
        }
        return cssClass.toLowerCase();
    };
    Object.defineProperty(EcosystemStep.prototype, "typeView", {
        // get statusView() {
        //   let cssClass = this.statusClass;
        //   if (this.type === ECOSYSTEM_STEP_TYPE.NEED) {
        //     cssClass = (<any>{
        //         draft: 'stand by',
        //         waiting_for_validation: 'offer sent',
        //         ask_for_modification: 'ask for counter proposal',
        //       })[cssClass] || cssClass;
        //   }
        //   return cssClass.replace(/_/g, ' ');
        // }
        get: function () {
            return this.typeClass.replace(/_/g, ' ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "typeClass", {
        // get statusClass(): string {
        //   let cssClass;
        //   if (this.type !== ECOSYSTEM_STEP_TYPE.NEED) {
        //     cssClass = ECOSYSTEM_STEP_STATUS[this.getCurrentStatus()];
        //   } else {
        //     cssClass = this.ecosystem.ecosystem_offer && ECOSYSTEM_OFFER_STATUS[this.ecosystem.ecosystem_offer.status] || 'DRAFT';
        //   }
        //   return cssClass.toLowerCase();
        // }
        get: function () {
            return ECOSYSTEM_STEP_TYPE[this.type].toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "canEditOrder", {
        get: function () {
            return [
                ECOSYSTEM_STEP_STATUS.BOOKED,
                ECOSYSTEM_STEP_STATUS.STAND_BY,
                ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION,
                ECOSYSTEM_STEP_STATUS.ASK_FOR_MODIFICATION,
            ].includes(this.status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "canEdit", {
        get: function () {
            return this.ecosystem.canEditSteps;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "canValidateManually", {
        get: function () {
            var typeMatched = IS_VALIDATABLE_STEP(this.type);
            var ecosystemStatusMatched = [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(this.ecosystem.status);
            var stepStatusMatched = this.status !== ECOSYSTEM_STEP_STATUS.BOOKED;
            return typeMatched && ecosystemStatusMatched && stepStatusMatched;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "canAskValidateAgain", {
        get: function () {
            var typeMatched = IS_VALIDATABLE_STEP(this.type);
            var ecosystemStatusMatched = [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(this.ecosystem.status);
            var stepStatusMatched = this.status === ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION;
            return typeMatched && ecosystemStatusMatched && stepStatusMatched;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "canUnvalidate", {
        get: function () {
            var typeMatched = IS_VALIDATABLE_STEP(this.type);
            var ecosystemStatusMatched = this.ecosystem.status === ECOSYSTEM_STATUS.CREATED;
            var stepStatusMatched = this.status === ECOSYSTEM_STEP_STATUS.BOOKED;
            return typeMatched && ecosystemStatusMatched && stepStatusMatched;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "canAskForValidation", {
        get: function () {
            var typeMatched = IS_VALIDATABLE_STEP(this.type);
            var ecosystemStatusMatched = [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(this.ecosystem.status);
            var stepStatusMatched = ![
                ECOSYSTEM_STEP_STATUS.BOOKED
            ].includes(this.status);
            return typeMatched && ecosystemStatusMatched && stepStatusMatched;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "canConfirmManually", {
        get: function () {
            var ecosystemStatusMatched = this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS;
            if (this.type === ECOSYSTEM_STEP_TYPE.NEED) {
                /* let hasUnconfirmedSteps = Boolean(~this
                   .ecosystem
                   .steps
                   .filter((step: EcosystemStep) => step.type !== ECOSYSTEM_STEP_TYPE.NEED) //Skip need steps
                   .findIndex((step: EcosystemStep) => step.status !== ECOSYSTEM_STEP_STATUS.CONFIRMED));*/
                var offerStatusMatched = this.ecosystem.ecosystem_offer
                    && [ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION,
                        ECOSYSTEM_OFFER_STATUS.PROBLEM_REPORTED,
                        ECOSYSTEM_OFFER_STATUS.VALIDATED].includes(this.ecosystem.ecosystem_offer.status);
                return ecosystemStatusMatched && offerStatusMatched;
            }
            else {
                var typeMatched = IS_CONFIRMABLE_STEP(this.type);
                var stepStatusMatched = this.status !== ECOSYSTEM_STEP_STATUS.CONFIRMED;
                // const dateIsPassed = !moment(this.linked_date, ['YYYY-MM-DD', 'DD-MM-YYYY']).isAfter(moment());
                return typeMatched && ecosystemStatusMatched && stepStatusMatched;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "canNeedConfirmManually", {
        get: function () {
            if (this.type === ECOSYSTEM_STEP_TYPE.NEED && this.status !== ECOSYSTEM_STEP_STATUS.CONFIRMED) {
                return this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemStep.prototype, "canConfirmWhenProblemReported", {
        get: function () {
            if (this.type === ECOSYSTEM_STEP_TYPE.NEED && this.status === ECOSYSTEM_STEP_STATUS.PROBLEM_REPORTED) {
                return this.ecosystem.status === ECOSYSTEM_STATUS.DONE;
            }
        },
        enumerable: true,
        configurable: true
    });
    EcosystemStep.prototype.getTypeName = function (value) {
        return ECOSYSTEM_STEP_TYPE[value];
    };
    // getCurrentStatus() : number {
    //   let currentStatus = this.status;
    //   if (this.orders && this.orders.length > 0) {
    //     currentStatus = this.orders[0].status;
    //     this.orders.forEach((order) => {
    //       // console.log(ECOSYSTEM_ORDER_STATUS[order.status]);
    //       if (this.getStatusPriority(order.status) < this.getStatusPriority(currentStatus)) {
    //         currentStatus = order.status;
    //       }
    //     });
    //   }else if (this.order) {
    //     currentStatus = this.order.status;
    //   }
    //   return currentStatus;
    // }
    //
    // getStatusPriority(statusValue: number) : number {
    //   let statusPriorities: number[] = [
    //     ECOSYSTEM_ORDER_STATUS.REJECTED,
    //     ECOSYSTEM_ORDER_STATUS.ASK_FOR_MODIFICATION,
    //     ECOSYSTEM_ORDER_STATUS.STAND_BY,
    //     ECOSYSTEM_ORDER_STATUS.PENDING,
    //     ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED,
    //     ECOSYSTEM_ORDER_STATUS.WAITING_FOR_CONFIRMATION,
    //     ECOSYSTEM_ORDER_STATUS.VALIDATED,
    //     ECOSYSTEM_ORDER_STATUS.CONFIRMED
    //   ];
    //   let priority = statusPriorities.indexOf(statusValue);
    //   if (priority === -1) {
    //     return statusPriorities.length;
    //   }else {
    //     return priority;
    //   }
    // }
    EcosystemStep.prototype.fields = function () {
        return [
            'id',
            'title',
            'entity_id',
            'parent_id',
            'order_aggregate',
            'type',
            'x_pos',
            'y_pos',
            'linked_date',
            'ecosystem_id',
            'status',
            'site_id',
            'summary_details',
            'confirmed_distance',
            'confirmed_price',
            'confirmed_quantity',
            'site_name',
            'transformation_type',
            'passport_name',
            'english_title',
            'virgin_product_price',
            'description',
            'problem'
        ];
    };
    EcosystemStep.prototype.isStepValid = function () {
        // add check for need if needed
        // if (this.type === ECOSYSTEM_STEP_TYPE.NEED)  return  this.ecosystem.need_id
        if (this.type === ECOSYSTEM_STEP_TYPE.TRANSFORM)
            return this.transform != null;
        if (this.type === ECOSYSTEM_STEP_TYPE.DEPOSIT)
            return this.deposit != null;
        return true;
    };
    EcosystemStep.prototype.getInvalidTitle = function () {
        var title = "Step is no longer valid";
        if (this.type === ECOSYSTEM_STEP_TYPE.NEED)
            title = 'The need is no longer valid';
        if (this.type === ECOSYSTEM_STEP_TYPE.TRANSFORM)
            title = 'The transformation is no longer valid';
        if (this.type === ECOSYSTEM_STEP_TYPE.DEPOSIT)
            title = 'The deposit is no longer valid';
        return title;
    };
    return EcosystemStep;
}(ExtendedActiveRecord));
export { EcosystemStep };
