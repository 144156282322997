<ng-container>
  <div class="passport-details-view__void-view" *ngIf="passport.isVoid">
    <passport-family-view-label [familyType]="passport.family"
                                [unit]="passport.unit + ''"></passport-family-view-label>
    <div class="passport-details-view__void-view-lable primary-color">
      <span class="form-group__label--question">!</span>
      <span>{{'WARNING_VOID_PASSPORT' | translate}}</span>
    </div>
  </div>
<div *ngIf="!passport.isVoid">
  <div class="flex justify-between">
    <div class="flex-column">
      <passport-info-display class="passport"
                             [showCopyId]="passport.hasProductPassportId()"
                             [passport]="passport"
                             (onOpenGenerationNameDialog)="nil()"></passport-info-display>
      <md-input-container class="form-group__field passport-details-view__input">
        <input mdInput
               autocomplete="off"
               placeholder="{{ 'Product category' | translate }}"
               readonly
               [value]="passport.category ?  translate(passport.category.name) : '' "
        >
      </md-input-container>
      <passport-multiple-field-input
        *ngIf="passport.isGtinVisible"
        [records]="passport.gtins"
        [recordNameField]="'code'"
        [placeholder]="'GTIN'"
        [disabled]="true"
      >
      </passport-multiple-field-input>


      <div class="flex flex-column" *ngIf="showConversion()">
        <div class="flex passport-edit__info--group-conversion"
             [ngClass]="getLabelClasses(passport.conversion)"
        >
          {{'Conversion' | translate}}
        </div>
        <div [ngClass]="{'flex align-center': true, 'passport-edit__info-disabled': true}">
          <div class="flex passport-edit__info--group-conversion-size">
            1 {{passport.unit_text | translate}}
          </div>
          <div class="flex passport-edit__info--group-conversion-equal">
            =
          </div>
          <div class="passport-edit__info--dimensions-size">
            {{passport.conversion}} kg
          </div>
        </div>
      </div>

      <div class="flex flex-column" *ngIf="passport.isRatingVisible">
        <div class="flex passport-edit__info--group-rating"
             [ngClass]="getLabelClasses(passport.rating)"
        >
          {{'Product rating' | translate}}
        </div>
      </div>
      <passport-rating-display *ngIf="passport.isRatingVisible" [passport]="passport">
      </passport-rating-display>
    </div>
    <div class="passport-details-view__image-wrapper" *ngIf="passport.image">
      <a [href]="passport.image" target="_blank">
        <div [ngStyle]="getImageStyle()"></div>
      </a>
    </div>
  </div>

  <div class="passport-details-view__label">
    {{ 'Materials and components' | translate }}&nbsp;
    <span
      class="passport-details-view__label-explanation">{{ 'PASSPORT_COMPONENT_EXPLANATION' | translate }}
  </span>
  </div>
  <passport-components
    [listener]="listener"
    [passport]="passport"
    [isDisabled]="true"
  ></passport-components>

  <div>

    <div class="passport-details-view__label">
      {{ 'First life' | translate }}
    </div>

    <div>
<!--      <div class="passport-edit__cycle&#45;&#45;life-wrapper">-->
<!---->
<!--        <div class="passport-details-view__input-wrapper">-->
<!---->
<!--          <div class="flex-column passport-details-view__recycle-row passport-details-view__delivery-input">-->
<!---->
<!--            <div class="passport-edit__cycle&#45;&#45;life-recycle-label passport-details-view__input-label"-->
<!--                 [ngClass]="getLabelClasses(passport.recycle_percentage)"-->
<!--            >{{'Part of recycled materials' | translate}}</div>-->
<!--            <div class="flex">-->
<!--              <md-input-container class="passport-details-view__recycle-percentage">-->
<!--                <input-->
<!--                  mdInput-->
<!--                  readonly-->
<!--                  type="number"-->
<!--                  placeholder=" "-->
<!--                  [value]="passport.recycle_percentage"-->
<!--                >-->
<!--              </md-input-container>-->
<!--              <div class="passport-edit__cycle&#45;&#45;life-recycle-symbol passport-details-view__symbol">%</div>-->
<!--            </div>-->
<!--          </div>-->
<!---->
<!--          <div class="flex passport-details-view__recycle-row passport-details-view__recycle-row-description">-->
<!--            <md-input-container>-->
<!--              <input-->
<!--                name="description"-->
<!--                [value]="passport.first_life"-->
<!--                mdInput-->
<!--                readonly-->
<!--                placeholder="{{ 'Description' | translate }}"-->
<!--              >-->
<!--            </md-input-container>-->
<!--          </div>-->
<!---->
<!--        </div>-->
<!---->
<!--      </div>-->


      <div class="passport-edit__cycle--life-recycle-wrapper flex-column">
        <div class="passport-edit__cycle--life-label"
             [ngClass]="getLabelClasses(passport.period)">{{'Period of use' | translate}}</div>
        <div class="passport-details-view__input-wrapper">
          <div class="passport-edit__cycle--life-quantity passport-details-view__input-row">
            <md-input-container>
              <input
                mdInput
                readonly
                autocomplete="off"
                type="number"
                [value]="passport.period">
            </md-input-container>
          </div>
          <div class="form-group__field passport-details-view__period" *ngIf="passport.period_type">
            <md-input-container>
              <input
                mdInput
                autocomplete="off"
                readonly
                [value]="getPassportTypeValue()">
            </md-input-container>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="passport-details-view__label">
    {{ 'Next lives' | translate }}
  </div>

  <div class="row component" *ngIf="passport.next_usages.length == 0">

    <md-input-container class="quantity" floatPlaceholder="always">
      <input mdInput
             autocomplete="off"
             placeholder="{{ 'Next usage type' | translate }}"
             readonly
      >
    </md-input-container>

    <md-input-container class="percentage_product" floatPlaceholder="always">
      <input mdInput
             autocomplete="off"
             placeholder="{{ 'PercentageProduct' | translate }}"
             readonly
      >
    </md-input-container>

    <md-input-container class="demountability" floatPlaceholder="always">
      <input mdInput
             autocomplete="off"
             placeholder="{{ 'Description' | translate }}"
             readonly
      >
    </md-input-container>

    <md-input-container class="name" floatPlaceholder="always">
      <input mdInput
             autocomplete="off"
             placeholder="{{ 'Stakeholder' | translate }}"
             readonly
      >
    </md-input-container>
    
   
  </div>

 
    <div class="row component" *ngFor="let usage of passport.next_usages">
    
      <md-input-container class="quantity" floatPlaceholder="always">
        <input mdInput
               placeholder="{{ 'Next usage type' | translate }}"
               readonly
               autocomplete="off"
               value="{{ usage.getTypeText() | translate }}"
        >
      </md-input-container>
      
      <md-input-container class="percentage_product" floatPlaceholder="always">
        <input mdInput
               autocomplete="off"
               placeholder="{{ 'PercentageProduct' | translate }}"
               readonly
               [value]="usage.percentage_product"
        >
      </md-input-container>

      <div class="column">
        <div class="row component" *ngIf="usage.affiliate_branch.length == 0">
      

          <md-input-container class="demountability" floatPlaceholder="always">
            <input mdInput
                   autocomplete="off"
                   placeholder="{{ 'Description' | translate }}"
                   readonly
            >
          </md-input-container>

          <md-input-container class="name" floatPlaceholder="always">
            <input mdInput
                   autocomplete="off"
                   placeholder="{{ 'Stakeholder' | translate }}"
                   readonly
            >
          </md-input-container>
          
         
        </div>

        <div class="row component" *ngFor="let af of usage.affiliate_branch">
  
        <md-input-container class="demountability" floatPlaceholder="always">
          <input mdInput
                 placeholder="{{ 'Description' | translate }}"
                 readonly
                 autocomplete="off"
                 [value]="af.description"
          >
        </md-input-container>
  
        <md-input-container class="name" floatPlaceholder="always">
          <input mdInput
                 placeholder="{{ 'Stakeholder' | translate }}"
                 readonly
                 autocomplete="off"
                 [value]="af.stakeholder"
          >
        </md-input-container>
        
        
      </div>
    </div>
  
    </div>
 
  <div class="passport-details-view__label">
    {{ 'Life cycle analysis' | translate }}
  </div>
  <div class="passport-details-view__label life-cycle">
    {{ 'functional unit' | translate | capitalize }}
  </div>
  <div class="row component life-cycle">
    <md-input-container class="name">
      <input mdInput
             placeholder="{{ 'functional unit' | translate | capitalize }}"
             readonly
             autocomplete="off"
             [value]="passport.carbon_conversion"
      >
    </md-input-container>
    <md-input-container class="demountability">
      <input mdInput
             placeholder="{{ 'Description of the functional unit' | translate }}"
             readonly
             autocomplete="off"
             [value]="passport.carbon_conversion_description"
      >
    </md-input-container>
  </div>
  <div class="passport-details-view__label life-cycle">
    {{ 'Carbon footprint' | translate }}
  </div>
  <div class="row component life-cycle">
    <md-input-container class="life-cycle-field">
      <input mdInput
             placeholder="{{ 'Production (A1-A3 module)' | translate | capitalize }}"
             readonly
             autocomplete="off"
             [value]="passport.carbon_product"
      >
    </md-input-container>
    <md-input-container class="life-cycle-field">
      <input mdInput
             placeholder="{{ 'End of life stage (C module)' | translate }}"
             readonly
             autocomplete="off"
             [value]="passport.carbon_end"
      >
    </md-input-container>
  </div>
  <div class="passport-details-view__label life-cycle">
    {{ 'Water Stewardship' | translate }}
  </div>
  <div class="row component life-cycle">
    <md-input-container class="life-cycle-field">
      <input mdInput
             placeholder="{{ 'Production (A1-A3 module)' | translate }}"
             readonly
             autocomplete="off"
             [value]="passport.water_conversion"
      >
    </md-input-container>
    <md-input-container class="life-cycle-field">
      <input mdInput
             placeholder="{{ 'End of life stage (C module)' | translate }}"
             readonly
             autocomplete="off"
             [value]="passport.water_end_of_life"
      >
    </md-input-container>
  </div>

  <div class="passport-details-view__label">{{ 'Commentaries' | translate }}</div>

  <div class="flex-column passport-details-view__comments">
    <md-input-container class="description passport-details-view__comment">
      <input mdInput
             autocomplete="off"
             placeholder="{{ 'Description' | translate }}"
             readonly
             [value]="passport.description"
      >
    </md-input-container>
  </div>

  <div class="passport-details-view__label">{{ 'Documents' | translate }}</div>
  <documents-uploader
    [files]="uploadedPassportFiles"
  ></documents-uploader>
</div>
</ng-container>
