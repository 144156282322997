import * as tslib_1 from "tslib";
import { CountryService } from '../services/country.service';
import { ActiveRecord } from './active-record.class';
var Country = /** @class */ (function (_super) {
    tslib_1.__extends(Country, _super);
    function Country() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = CountryService;
        return _this;
    }
    Country.prototype.fields = function () {
        return ['iso', 'name', 'id'];
    };
    return Country;
}(ActiveRecord));
export { Country };
