/**
 * Created by aleksandr on 16.05.17.
 */
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ESM_ROUTES } from './ecosystem-manager.routes';
import { EcosystemWrapperComponent } from './components/ecosystem-wrapper/ecosystem-wrapper.component';
import { EcosystemEditComponent } from './components/ecosystem-compiler/ecosystem-edit/ecosystem-edit.component';
import { EcosystemDashboardComponent } from './components/ecosystem-dashboard/ecosystem-dashboard.component';
import { LinkedDateDialogComponent } from './dialogs/link-date/link-date.component';
import { EcosystemDeletionDialogComponent } from './dialogs/ecosystem-deletion/ecosystem-deletion.component';
import { EcosystemDescriptionDialogComponent } from './dialogs/ecosystem-description/ecosystem-description.component';
import { EcosystemFileDialogComponent } from './dialogs/ecosystem-file/ecosystem-file.component';
import { EcosystemFileSelectorDialogComponent } from './dialogs/ecosystem-file-selector/ecosystem-file-selector.component';
import { DepositOrderService } from './services/deposit-order.service';
import { TransformOrderService } from './services/transform-order.service';
import { DepositOrderComponent } from './components/ecosystem-compiler/esm-compiler-deposit/esm-compiler-deposit.component';
import { OrderPassportService } from './services/order-passport.service';
import { OrderMaterialService } from './services/order-material.service';
import { EcosystemCatalogComponent } from './components/ecosystem-dashboard/ecosystem-catalog/ecosystem-catalog.component';
import { EcosystemNavigatorComponent } from './components/ecosystem-dashboard/ecosystem-navigator/ecosystem-navigator.component';
import { SearchMapDiagComponent } from './dialogs/search-map/search-map.component';
import { EsmNeedCatalogComponent } from './components/ecosystem-dashboard/ecosystem-catalog/esm-need-catalog/esm-need-catalog.component';

import { EcosystemTreeComponent } from './components/ecosystem-compiler/ecosystem-tree/ecosystem-tree.component';
import { EcosystemOrderMaterialsComponent } from './components/ecosystem-order-materials/ecosystem-order-materials.component';
import { EcosystemCardComponent } from './components/ecosystem-dashboard/ecosystem-navigator/ecosystem-card/ecosystem-card.component';
import { EcosystemStepSearchComponent } from './components/ecosystem-compiler/ecosystem-step-search/ecosystem-step-search.component';
import { EsmNeedDialogComponent } from './dialogs/esm-need-dialog/esm-need-dialog.component';
import { EsmTransformationDialogComponent } from './dialogs/esm-transformation-dialog/esm-transformation-dialog.component';
import { EsmDepositDialogComponent } from './dialogs/esm-deposit-dialog/esm-deposit-dialog.component';
import { EsmCompilerNeedComponent } from './components/ecosystem-compiler/esm-compiler-need/esm-compiler-need.component';
import { EcosystemOfferDialogComponent } from './dialogs/ecosystem-offer-dialog/ecosystem-offer-dialog.component';
import { EcosystemOfferModule } from '../../entities/ecosystem-offer/ecosystem-offer.module';
import { StepConfirmDialogComponent } from './dialogs/step-confirm/step-confirm.component';
import { StakeholderModule } from '../../entities/stakeholder/stakeholder-module';
import { EcosystemEcosystemsComponent } from './components/ecosystem-ecosystems/ecosystem-ecosystems.component';
import { CommonModule } from '@angular/common';
import { EcosystemModule } from '../../entities/ecosystem/ecosystem.module';
import { EcosystemManagerGuard } from './ecosystem-manager.guard';
import { RouterModule } from '@angular/router';
import { LoginGuard } from '../../shared/auth/login.guard';
import { PlatformGuard } from '../../shared/auth/platform.guard';
import { VirginProductModule } from '../../entities/virgin-product/virgin-product.module';
import { TransformOrdersComponent
} from './components/ecosystem-compiler/esm-compiler-transformation/esm-compiler-transformation.component';
import { EcosystemEcosystemsNavigatorComponent
} from './components/ecosystem-ecosystems/ecosystem-ecosystems-navigator/ecosystem-ecosystems-navigator.component';
import { EsmTransformationCatalogComponent
} from './components/ecosystem-dashboard/ecosystem-catalog/esm-transformation-catalog/esm-transformation-catalog.component';
import { EsmStakeholderCatalogComponent
} from './components/ecosystem-dashboard/ecosystem-catalog/esm-stakeholder-catalog/esm-stakeholder-catalog.component';
import { EsmDepositCatalogComponent
} from './components/ecosystem-dashboard/ecosystem-catalog/esm-deposit-catalog/esm-deposit-catalog.component';
import { EsmVirginCatalogComponent
} from './components/ecosystem-dashboard/ecosystem-catalog/esm-virgin-catalog/esm-virgin-catalog.component';
import { EsmCompilerVirginProductComponent
} from './components/ecosystem-compiler/esm-compiler-virgin-product/esm-compiler-virgin-product.component';
import { EsmCompilerStakeholderComponent
} from './components/ecosystem-compiler/esm-compiler-stakeholder/esm-compiler-stakeholder.component';
import { ECOSYSTEM_CONTEXT } from './ecosystem-manager.context';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';
import { EcosystemStatisticsComponent } from './components/ecosystem-statistics/ecosystem-statistics.component';
import { AgmJsMarkerClustererModule, ClusterManager } from '@agm/js-marker-clusterer';
import { AgmMarkerSpiderModule } from 'agm-oms';
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { EcosystemIndicatorsDialogComponent } from './dialogs/ecosystem-indicators/ecosystem-indicators.component';
import { EcosystemIndicatorsSummaryExpanderComponent
} from './dialogs/ecosystem-indicators/summary-expander/ecosystem-indicators-summary-expander.component';
import { EsmNeedMatchDialogComponent } from './dialogs/esm-need-match-dialog/esm-need-match-dialog.component';
import { EsmNeedMatchComponent } from './components/esm-need-match/esm-need-match.component';
import { EcosystemPrimaryHeaderComponent } from './components/ecosystem-primary-header/ecosystem-primary-header.component';
import { EcosystemIndicatorReportBaseComponent
} from './dialogs/ecosystem-indicators/ecosystem-indicator-report-base/ecosystem-indicator-report-base.component';
import { EcosystemIndicatorReportDetailsTabComponent
} from './dialogs/ecosystem-indicators/ecosystem-indicator-report-details-tab/ecosystem-indicator-report-details-tab.component';
import { EcosystemIndicatorCalculator } from './services/ecosystem-indicator-calculator.service';
import { DepositModule } from '../../entities/deposit/deposit.module';
import { EcosystemSectionCountersComponent } from './components/ecosystem-section-counters/ecosystem-section-counters.component';
import {EsmNeedPickerComponent} from './components/esm-need-picker/esm-need-picker.component';
import {EsmNeedPickerDialogComponent} from './dialogs/esm-need-picker-dialog/esm-need-picker-dialog.component';
import {TransformationModule} from '../../entities/transformation/transformation.module';
import { EcosystemFolderNavigatorComponent } from './components/ecosystem-ecosystems/ecosystem-ecosystems-navigator/folder-view/ecosystem-folder-navigator.component';
import { EcosystemFolderComponent } from './components/ecosystem-dashboard/ecosystem-navigator/ecosystem-folder/ecosystem-folder.component';
import { CreateFolderModalComponent } from './components/ecosystem-ecosystems/ecosystem-ecosystems-navigator/dialogs/create-folder/create-folder-modal.component';
import { MoveFolderModalComponent } from './components/ecosystem-ecosystems/ecosystem-ecosystems-navigator/dialogs/move-folder/move-folder-modal.component';
import { DeleteFolderModalComponent } from './components/ecosystem-ecosystems/ecosystem-ecosystems-navigator/dialogs/delete-folder/delete-folder-modal.component'; 
@NgModule({
  imports: [
    StakeholderModule,
    VirginProductModule,
    AgmJsMarkerClustererModule,
    EcosystemModule,
    SharedModule,
    CommonModule,
    RouterModule.forChild([{
      path: ECOSYSTEM_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: ESM_ROUTES
    }]),
    EcosystemOfferModule,
    AgmMarkerSpiderModule,
    VirtualScrollModule,
    DepositModule,
    TransformationModule
  ],
  declarations: [
    EcosystemWrapperComponent,
    EcosystemEditComponent,
    EcosystemDashboardComponent,
    EcosystemEcosystemsComponent,
    LinkedDateDialogComponent,
    TransformOrdersComponent,
    EcosystemNavigatorComponent,
    EcosystemEcosystemsNavigatorComponent,
    EcosystemCatalogComponent,
    EcosystemDeletionDialogComponent,
    EcosystemDescriptionDialogComponent,
    EcosystemIndicatorsDialogComponent,
    EcosystemIndicatorsSummaryExpanderComponent,
    SearchMapDiagComponent,
    EcosystemFileDialogComponent,
    EcosystemFileSelectorDialogComponent,
    EsmNeedCatalogComponent,
    EsmTransformationCatalogComponent,
    EsmDepositCatalogComponent,
    EsmNeedMatchComponent,
    EsmNeedMatchDialogComponent,
    EsmStakeholderCatalogComponent,
    EsmVirginCatalogComponent,
    EcosystemCardComponent,
    DepositOrderComponent,
    EcosystemStepSearchComponent,
    EcosystemOrderMaterialsComponent,
    EcosystemTreeComponent,
    EsmNeedDialogComponent,
    EsmTransformationDialogComponent,
    EsmDepositDialogComponent,
    EsmCompilerNeedComponent,
    EsmCompilerVirginProductComponent,
    EsmCompilerStakeholderComponent,
    EcosystemOfferDialogComponent,
    StepConfirmDialogComponent,
    EcosystemStatisticsComponent,
    EcosystemPrimaryHeaderComponent,
    EcosystemIndicatorReportBaseComponent,
    EcosystemIndicatorReportDetailsTabComponent,
    EcosystemSectionCountersComponent,
    EsmNeedPickerComponent,
    EsmNeedPickerDialogComponent,
    EcosystemFolderNavigatorComponent,
    EcosystemFolderComponent,
    CreateFolderModalComponent,
    MoveFolderModalComponent,
    DeleteFolderModalComponent
  ],
  exports: [
    EcosystemCardComponent,
    EcosystemSectionCountersComponent,
    EcosystemFolderComponent
  ],
  providers: [
    DepositOrderService,
    TransformOrderService,
    OrderPassportService,
    OrderMaterialService,
    ClusterManager,
    EcosystemManagerGuard,
    EcosystemIndicatorCalculator,
    CreateFolderModalComponent
    // EcosystemIndicatorReportContext
  ],
  entryComponents: [
    LinkedDateDialogComponent,
    EcosystemDescriptionDialogComponent,
    EcosystemDeletionDialogComponent,
    EcosystemIndicatorsDialogComponent,
    EcosystemIndicatorsSummaryExpanderComponent,
    EcosystemFileDialogComponent,
    EcosystemFileSelectorDialogComponent,
    SearchMapDiagComponent,
    EsmNeedDialogComponent,
    EsmTransformationDialogComponent,
    EsmDepositDialogComponent,
    EsmNeedMatchDialogComponent,
    EcosystemOfferDialogComponent,
    StepConfirmDialogComponent,
    EsmNeedPickerDialogComponent,
    CreateFolderModalComponent,
    MoveFolderModalComponent,
    DeleteFolderModalComponent
  ],
})
export class EcosystemManagerModule {
}
