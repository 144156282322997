<section class="ecosystem-compiler">
  <ul class="ecosystem-compiler__tab-list">
    <li class="ecosystem-compiler__tab-item">
      <header class="ecosystem-compiler__tab-header">
        <div class="ecosystem-compiler__tab-caption--stakeholder">
          <small class="ecosystem-compiler__tab-label--stakeholder">{{ 'Logistic' | translate }}</small>
          <div class="ecosystem-compiler__tab-title--virgin">
            <i class="icon icon--order-in"></i>
            <h3 class="ecosystem-compiler__tab-title">{{stakeHolder.company_name}}</h3>
          </div>
          <div class="ecosystem-compiler__tab-badge ecosystem-compiler__tab-badge--pink">{{ 'Stakeholder' | translate
            }}
          </div>
        </div>
        <div class="ecosystem-compiler__expander" (click)="changeTab(0)">
          <button class="ecosystem-compiler__fold-tab button-transparent">
            <i class="icon" [ngClass]="isOpen(0) ? 'icon--unfold-tab' : 'icon--fold-tab' "></i>
          </button>
        </div>
      </header>
      <section class="ecosystem-compiler__tab-section--stakeholder" *ngIf="isOpen(0)">
        <div class="ecosystem-compiler__transformation-description">
          <md-input-container>
            <input
              mdInput
              readonly
              autocomplete="off" 
              [ngModel]="stakeHolder?.description"
              placeholder="{{ 'Description' | translate }}"
            >
          </md-input-container>
        </div>
        <div class="ecosystem-compiler__transformation-description">
          <md-input-container>
            <input
              mdInput
              readonly
              autocomplete="off" 
              [ngModel]="stakeHolder?.address"
              placeholder="{{ 'Address' | translate }}"
            >
          </md-input-container>
        </div>
        <div class="ecosystem-compiler__contacts">{{ 'Contacts' | translate }}</div>
        <div class="ecosystem-compiler__row--contacts--virgin">
          <div class="ecosystem-compiler__column">
            <i class="icon icon--people-grey"></i> {{stakeHolder.name}}
          </div>
          <div class="ecosystem-compiler__column">
            <i class="icon icon--phone"></i> {{stakeHolder.phone}}
          </div>
          <div class="ecosystem-compiler__column">
            <button class="ecosystem-compiler__confirm-button"  (click)="sendEmail()">{{ 'Send Email' | translate }}</button>
          </div>
        </div>
      </section>
    </li>
  </ul>
</section>
