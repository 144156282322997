import { Component, OnInit, Input } from '@angular/core';
import { EcosystemFolderService } from '../../../../../../entities/ecosystem/services/ecosystem-folder.service';
@Component({
    moduleId: module.id,
    selector: 'ecosystem-folder-navigator',
    templateUrl: 'ecosystem-folder-navigator.component.html'
})
export class EcosystemFolderNavigatorComponent implements OnInit{

    @Input() folderMode: boolean;
    

    constructor(public ecoFolderService: EcosystemFolderService) {

    }

    ngOnInit(){
        this.ecoFolderService.search();
    }
}
