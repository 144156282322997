/**
 * Created by aleksandr on 13.06.17.
 */
import { Component, Input, OnInit } from '@angular/core';
import { Ecosystem } from '../../../../models/ecosystem.class';
import { ECOSYSTEM_STEP_TYPE } from '../../../../values/ecosystem-step-type.const';
import { EcosystemStep } from '../../../../models/ecosystem-step.class';
import { Router } from '@angular/router';
import { ECOSYSTEM_STATUS, ECOSYSTEM_STATUS_VIEW, ECOSYSTEM_STATUS_COLOR } from '../../../../values/ecosystem-status.const';
import { NavbarStateService } from '../../../../../../shared/services/navbar.service';
import { EcosystemFolder } from '../../../../models/ecosystem-folder.class';
import { EcosystemFolderService } from '../../../../../../entities/ecosystem/services/ecosystem-folder.service';
import { ECOSYSTEM_STEP_STATUS } from '../../../../../ecosystem-manager/values/ecosystem-step-status.const';
import { ECOSYSTEM_OFFER_STATUS } from '../../../../../ecosystem-manager/values/ecosystem-offer-status.const';
@Component({
  moduleId: module.id,
  selector: 'ecosystem-card',
  templateUrl: 'ecosystem-card.component.html'
})
export class EcosystemCardComponent implements OnInit {
  @Input() ecosystem: Ecosystem;
  public checked = false;
  ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
  ECOSYSTEM_STATUS_VIEW = ECOSYSTEM_STATUS_VIEW;

  filterStatuses: ECOSYSTEM_STATUS[] = [];

  get needs() {
    return this.getByType(ECOSYSTEM_STEP_TYPE.NEED);
  }

  get deposits() {
    return this.getByType(ECOSYSTEM_STEP_TYPE.DEPOSIT);
  }

  get transformations() {
    return this.getByType(ECOSYSTEM_STEP_TYPE.TRANSFORM);
  }

  get stakeholders() {
    return this.getByType(ECOSYSTEM_STEP_TYPE.STAKEHOLDER);
  }

  get virginProducts() {
    return this.getByType(ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT);
  }

  constructor(private _router: Router, public filterState: NavbarStateService, public ecosystemFolderService: EcosystemFolderService) {

  }


  ngOnInit(): void {

  }

  openEcosystem() {
    this._router.navigate(['ecosystem/ecosystems', this.ecosystem.id]);
  }

  protected getByType(stepType: number) {
    return this.ecosystem && this.ecosystem.steps
      ? this
        .ecosystem
        .steps
        .filter((step: EcosystemStep) => step.type === stepType)
      : [];
  }

  checkStatusInProcess() {
    return this.ecosystem.status === this.ECOSYSTEM_STATUS.IN_PROCESS || this.ecosystem.status === this.ECOSYSTEM_STATUS.DONE;
  }

  getEcosystemDisplayName(length) {
    return this.ecosystem.name.length > length ? this.ecosystem.name.substring(0,length - 1)+ '..' : this.ecosystem.name
  }

  isOfferSent() {
    return this.ecosystem.status == ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION ;
  }

  getFolderName(length) {
    return this.ecosystem.folder && (this.ecosystem.folder.name.length > length ? this.ecosystem.folder.name.substring(0,length - 1)+ '..' : this.ecosystem.folder.name)
  }

  canDisplayFolderName() {
    return this.ecosystem.folder !== null;
  }

  onCheckbox(e) {
    e.stopPropagation();
  }

  onCheckboxChange(checked) {
    if (this.ecosystemFolderService.allSelected) {
      this.ecosystemFolderService.allSelected = false;
    }
    return checked ?
    this.ecosystemFolderService.addToSelection(this.ecosystem.id, this.ecosystem.folder && this.ecosystem.folder.id) :
    this.ecosystemFolderService.removeFromSelection(this.ecosystem.id);
  }
  getStatusClass() {
    return `ecosystem-card__status-label__${ECOSYSTEM_STATUS_COLOR[this.ecosystem.status]}`
  }

  shouldDisplayCheck(step) {
    return step.status === ECOSYSTEM_STEP_STATUS.BOOKED || step.status === ECOSYSTEM_STEP_STATUS.VALIDATED || step.status === ECOSYSTEM_STEP_STATUS.CONFIRMED;
  }

  getStepStatusStyle(step) {
    switch(step.status) {
      case ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION:
      case ECOSYSTEM_STEP_STATUS.BOOKED:
      case ECOSYSTEM_STEP_STATUS.VALIDATED:
        return '#5B96ED';
      case ECOSYSTEM_STEP_STATUS.WAITING_FOR_CONFIRMATION:
      case ECOSYSTEM_STEP_STATUS.CONFIRMED:
        return '#58CB9F';
      default:
        return '#747474';
    } 
  }

  shouldDisplayNeedCheck(ecoOffer) {

    return ecoOffer && (ecoOffer.status === ECOSYSTEM_OFFER_STATUS.VALIDATED || ecoOffer.status === ECOSYSTEM_OFFER_STATUS.CONFIRMED);
  }

  getNeedStatusStyle(ecoOffer) {
    if (!ecoOffer) {
      return '#747474';
    }
    switch(ecoOffer.status) {
      case ECOSYSTEM_OFFER_STATUS.WAITING_FOR_VALIDATION:
      case ECOSYSTEM_OFFER_STATUS.VALIDATED:
        return '#5B96ED';
      case ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION:
      case ECOSYSTEM_OFFER_STATUS.CONFIRMED:
        return '#58CB9F';
      default:
        return '#747474';
    } 
  }
}
