import * as tslib_1 from "tslib";
import { DepositLocationService } from '../services/deposit-location.service';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { DepositTag } from './deposit-tag.class';
import { DepositLocationFile } from './deposit-location-file.class';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
var DepositLocation = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocation, _super);
    function DepositLocation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = DepositLocationService;
        return _this;
    }
    DepositLocation.prototype.beforeSave = function (data) {
        var dataValues = _super.prototype.beforeSave.call(this, data);
        dataValues.end_of_work = new DatePipe('en-US').transform(dataValues.end_of_work, 'yyyy-MM-dd');
        return dataValues;
    };
    DepositLocation.prototype.set = function (data) {
        _super.prototype.set.call(this, data);
        if (!!this.tags) {
            this.tags = this.tags.map(function (tag) { return new DepositTag(tslib_1.__assign({}, tag)); });
        }
        if (!!this.files) {
            this.files = this.files.map(function (file) { return new DepositLocationFile(file); });
        }
        return this;
    };
    DepositLocation.prototype.fields = function () {
        return ['id', 'expired', 'expire_date', 'user_id', 'description', 'name', 'lat', 'lng', 'country', 'city', 'postalCode',
            'address', 'verification', 'creator', 'use_count', 'created_at',
            'bim_tool_id', 'bim_id', 'floor_area', 'tags', 'files', 'assigned', 'end_of_work', 'site_status', 'checked', 'active',
            'deposits', 'is_expired_by_superadmin', 'expired_date', 'living_area', 'count'];
    };
    Object.defineProperty(DepositLocation.prototype, "lock", {
        get: function () {
            return this.verification === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositLocation.prototype, "nameAddress", {
        get: function () {
            return this.address ? this.name + ', ' + this.address : this.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositLocation.prototype, "isExpire", {
        get: function () {
            return moment().isAfter(moment(this.end_of_work, 'YYYY-MM-DD'), 'days');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositLocation.prototype, "is_expired_by_superadmin", {
        get: function () {
            return !!this.expired_date;
        },
        set: function (state) {
            this.expired_date = state ? moment().format('YYYY-MM-DD') : null;
        },
        enumerable: true,
        configurable: true
    });
    DepositLocation.prototype.assign = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.assign(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    return DepositLocation;
}(ExtendedActiveRecord));
export { DepositLocation };
