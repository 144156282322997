import {Validators} from '@angular/forms';

export type TDocumentControls =  'tab_name' | 'first_row' | 'last_row';

export const IMPORT_DOCUMENT_CONTROLS: {[key in (TDocumentControls | 'isFileLoaded')]: any} = {
  isFileLoaded: ['', Validators.required],
  tab_name: ['', [Validators.required]],
  first_row: ['', [Validators.required, Validators.min(0)]],
  last_row: ['', [Validators.required, Validators.min(0)]]
};

