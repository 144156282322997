/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import-progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i4 from "@angular/material";
import * as i5 from "./import-progress.component";
import * as i6 from "../../services/import-progress.service";
var styles_DepositImportProgressComponent = [i0.styles];
var RenderType_DepositImportProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DepositImportProgressComponent, data: {} });
export { RenderType_DepositImportProgressComponent as RenderType_DepositImportProgressComponent };
function View_DepositImportProgressComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "import-progress-close icon close-grey--icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_DepositImportProgressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "import-progress-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "import-progress-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "import-progress-bar primary-color"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "import-progress-bar--success": 0, "import-progress-bar--error": 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "md-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["mode", "determinate"], ["role", "progressbar"]], [[2, "mat-primary", null], [2, "mat-accent", null], [2, "mat-warn", null], [2, "mat-progress-bar", null], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i3.View_MdProgressBar_0, i3.RenderType_MdProgressBar)), i1.ɵdid(9, 16384, null, 0, i4.MdPrefixRejector, [[2, i4.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(10, 49152, null, 0, i4.MdProgressBar, [], { value: [0, "value"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "import-progress-toggle icon arrow-up--icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.maximize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositImportProgressComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.importProgressService.statusImportProgress; _ck(_v, 2, 0, currVal_0); var currVal_2 = "import-progress-bar primary-color"; var currVal_3 = _ck(_v, 7, 0, _co.isSuccess, _co.isError); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_10 = _co.importProgressService.importPercentageValue; var currVal_11 = "determinate"; _ck(_v, 10, 0, currVal_10, currVal_11); var currVal_12 = _co.importProgressService.canProgressModalBeClosed(); _ck(_v, 13, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getText(); _ck(_v, 4, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 10).color == "primary"); var currVal_5 = (i1.ɵnov(_v, 10).color == "accent"); var currVal_6 = (i1.ɵnov(_v, 10).color == "warn"); var currVal_7 = true; var currVal_8 = i1.ɵnov(_v, 10).value; var currVal_9 = i1.ɵnov(_v, 10).mode; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_DepositImportProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositImportProgressComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.importProgressService.showImportProgress; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DepositImportProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "import-progress", [], null, null, null, View_DepositImportProgressComponent_0, RenderType_DepositImportProgressComponent)), i1.ɵdid(1, 49152, null, 0, i5.DepositImportProgressComponent, [i6.ImportProgressService], null, null)], null, null); }
var DepositImportProgressComponentNgFactory = i1.ɵccf("import-progress", i5.DepositImportProgressComponent, View_DepositImportProgressComponent_Host_0, { tables: "tables" }, { onMaximize: "onMaximize" }, []);
export { DepositImportProgressComponentNgFactory as DepositImportProgressComponentNgFactory };
