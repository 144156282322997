<div class="passportlist" *ngIf="!advancedResearchTheme">
  <div class="passportlist__create">
    <button class="passportlist__create-button"
            *ngIf="parentModuleName !== moduleNames.SPECIAL_ADMIN && !isForPublicAccess"
            (click)="navigateToCreatePassport()">
      <i class="passportlist__plus">+</i>
      <span>{{ 'Create new Circular Passport' | translate }}</span>
    </button>
    <button md-raised-button class=""
            *ngIf="parentModuleName === moduleNames.MANUFACTURER"
            (click)="askForChangeInNomenclatureOpenDialog()">
      {{'Ask for change in nomenclature' | translate }}
    </button>
  </div>

  <div class="passportlist__filters" test-id="passport-list-filters">
    <div
    [ngClass]="{'passportlist__label-block': true, 'passportlist__label-block--big': isForPublicAccess}">
    <div *ngIf="isForPublicAccess">
      <span class="passportlist__toggle-multilanguage" md-tooltip="{{ 'MULTILANGUAGE_TOGGLE' | translate }}">
        <i *ngIf="navBarStateService.multilanguage" class="icon icon--terra-black" style="margin-right: 25%;"></i>
        <i *ngIf="!navBarStateService.multilanguage" class="icon icon--terra-black30" style="margin-right: 25%;"></i>
        <mat-slide-toggle
          (change)="navBarStateService.multilanguage = !navBarStateService.multilanguage"
          [checked]="navBarStateService.multilanguage">
        </mat-slide-toggle>
      </span>
    </div>
      <div *ngIf="isPassportRatingExplanationDialogAvailable()"
           class="passportlist__rating-explanation"
           (click)="openRatingExplanationDialog()">
      </div>
    </div>
    <div class="passportlist__info">
      <section class="flex">
        <mat-slide-toggle class="passportlist__toggler"
                          *ngIf="canDisplayOwnPassportFilter"
                          [checked]="passportFilter.loadSelfPassports"
                          [(ngModel)]=passportFilter.loadSelfPassports
        >
          {{'See only my passports' | translate}}
        </mat-slide-toggle>
        <mat-slide-toggle class="passportlist__toggler"
                          *ngIf="canDisplayDraftFilter"
                          (change)="loadRecords()"
                          [checked]="passportFilter.loadDraftPassports"
                          [(ngModel)]=passportFilter.loadDraftPassports
        >
          {{'See Draft' | translate}}
        </mat-slide-toggle>
        <passport-reset-button  class="passportlist__clear-filters--offset-right"
                                [ngClass]="{'passportlist__clear-filters--offset-bottom': isForPublicAccess}"
                                [passportFilter]="passportFilter"
                                [resetFilterState]="resetFilterState"
                                [alternativeRules]="canClearFiltersAlternativeRules"
                *ngIf="!isSpecialAdminOrManufactureActiveRole()"
                (onResetClick)="clearFilters($event)"
        >
        </passport-reset-button>
      </section>

      <div class="passportlist__filters-checkboxes"
           *ngIf="isSpecialAdminOrManufactureActiveRole()">
        <div *ngFor="let status of filterStatuses">
          <md-checkbox class="esm-catalog__group--checkbox checkbox-group"
                       [name]="status.text"
                       (change)="toggleState(status.value);"
                       [checked]="isStatusFilterChecked(status.value)">
            {{status.view| capitalize | translate}}
          </md-checkbox>
        </div>
        <passport-reset-button  class="passportlist__clear-filters--offset-right"
            [passportFilter]="passportFilter"
            [resetFilterState]="resetFilterState"
            [alternativeRules]="canClearFiltersAlternativeRules"
            *ngIf="isSpecialAdminOrManufactureActiveRole()" (onResetClick)="clearFilters($event)"
        >
        </passport-reset-button>
      </div>

      <div class="passportlist__info-top">
        <md-input-container class="passportlist__info-search">
          <input test-id="search"
                 mdInput
                 autocomplete="off"
                 class="passportlist__info-search-any"
                 placeholder="{{ 'Search by Anything' | translate }}"
                 [formControl]="passportFilter.search">
        </md-input-container>
        <family-filter class="passportlist__info-from" [ownPassportOnly]="passportFilter.loadSelfPassports"
                       [userRole]="parentModuleName"
                       [familyFilter]="this.passportFilter.family"
                       (FamilyFilterChange)="onFamilyFilterChange($event)"></family-filter>
        <rating-with-local-passport-filter
          class="passportlist__info-from"
          [classList]="[]"
          [ratingFilter]="ratingWithLocalPassportFilter"
          [defaultRatings]="resetFilterState.filterBy"
          (ratingFilterChange)="passportFilter.filterBy = $event; loadRecords()"
        ></rating-with-local-passport-filter>
      </div>
      <div class="passportlist__info-button">
        <!--<md-input-container class="passportlist__info-material">-->
        <!--<input mdInput class="passportlist__info-material-name" placeholder="Materials" [disabled]="true">-->
        <!--</md-input-container>-->

        <md-input-container
          class="passportlist__search-materials passportlist__search-materials--small-font"
          [floatPlaceholder]="passportFilter.tags.length ? 'always' : ''"
          (click)="openNomenclatureDialog(materiallist)"
        >
          <md-chip-list>
            <md-basic-chip *ngFor="let tag of passportFilter.tags" (focus)="false">
              {{tag.name}}
              <button (click)="removeTag(tag, $event)" class="delete"></button>
            </md-basic-chip>
          </md-chip-list>

          <input
            test-id="nomenclature-input"
            #materiallist
            mdInput
            autocomplete="off"
            placeholder="{{ 'Materials' | translate }}"
          >
        </md-input-container>
        <md-select *ngIf="parentModuleName !== moduleNames.MANUFACTURER"
                test-id="creator-selector"
                   class="passportlist__info-by"
                   placeholder="{{ 'Created by' | translate }}"
                   [(ngModel)]=passportFilter.createdBy
                   (change)="loadRecords()"
        >
          <md-option>{{'Any' | translate}}</md-option>
          <md-option *ngFor="let option of createdBy" [value]="option.key">
            {{option.value}}
          </md-option>

        </md-select>

        <md-input-container
        class="passportlist__search-materials passportlist__search-materials--small-font"
        [floatPlaceholder]="passportFilter.categoryTags.length ? 'always' : ''"
        (click)="openCategoryeDialog(categorie)"
      >
        <md-chip-list>
          <md-basic-chip *ngFor="let tag of passportFilter.categoryTags" (focus)="false" md-tooltip="{{ tag.name | translate }}">
            {{(tag.name.length > 7 ? tag.name.substr(0,7)+'..' : tag.name ) | translate }}

            <button (click)="removeCategoryTag(tag, $event)" class="delete"></button>
          </md-basic-chip>
        </md-chip-list>
        <input test-id="nomenclature-selector"
                   class="passportlist__info-by"
                   #categorie
                   autocomplete="off"
                   placeholder="{{ 'Category' | translate }}"
                   mdInput
        >
      </md-input-container>

        <md-select test-id="sort-selector"
                   (change)="loadRecords()"
                   class="passportlist__info-sort"
                   placeholder="{{ 'Sort by' | translate }}"
                   [(ngModel)]=passportFilter.orderBy
        >
          <md-option *ngFor="let sort of sort_order" [value]="sort.key">
            {{sort.value | translate}}
          </md-option>
        </md-select>
      </div>
    </div>
  </div>
  <passport-family-info [userRole]="parentModuleName"
                        [(isShown)]="storageViewState.showFamilyInfoBanner">
  </passport-family-info>
  <section *ngIf="parentModuleName === moduleNames.AGENT" class="passportlist__tutorial-info">
    <common-info-sign borderSizeClassModifier="1-half" class="passportlist__tutorial-info-sign">
      <i class="icon icon--information-agent" ></i>
    </common-info-sign>
    <span class="passportlist__tutorial-info-text">
      {{'AGENT_PASSPORT_LIST_TUTORIAL' | translate}}
      &nbsp;
    </span>
    <a class="passportlist__tutorial-info-link primary-color"
       [href]="agentTutorialLink"
       target="_blank"
    >
      {{'here' | translate}}
    </a>
  </section>
  <div class="passportlist__results" test-id="passport-list-results">
    <passport-item
      *ngFor="let record of records | paginate:{id:'passports', itemsPerPage: perPage, currentPage: currentPage, totalItems: totalCount }"

      [passport]="record"
      [isForPublicAccess]="isForPublicAccess"
      [parentModuleName]="parentModuleName"
      [isVersionFilterOn]="isVersionFilterOn"
      (onVersionFilter)="onVersionFilter($event)"
      (onDelete)="deleteRecord($event)"
      (onDuplicate)="duplicateRecord($event)"
      (onOpen)="openRecord($event)"
      lazy-load-images
    >
    </passport-item>
    <div *ngIf="!records.length && isRecordsLoaded" class="passportlist__results-empty">
      <common-info-sign
        class="passportlist__results-empty-sign"
        [sizeClassModifier]="'20'"
        [borderSizeClassModifier]="'1-half'">!</common-info-sign>
        <span class="passportlist__results-empty-text primary-color">{{'No result found' | translate}}</span>
    </div>
  </div>
  <div class="passportlist__container-pagination">
    <pagination-controls class="passportlist__pagination"
                         id="passports"
                         (pageChange)="pageChanged($event)"
                         maxSize="5"
                         directionLinks="true"
                         autoHide="true"
                         previousLabel=""
                         nextLabel=""
                         screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
                         screenReaderPageLabel="{{ 'page' | translate }}"
                         screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"
    >
    </pagination-controls>
  </div>
</div>










<div class="advanced-research-list" *ngIf="advancedResearchTheme">
  <div>
    <div class="advanced-research-list__search">
      <md-input-container class="advanced-research-list__search-container">
        <input test-id="search"
               [readonly]="!isRecordsLoaded"
               mdInput
               autocomplete="off"
               placeholder="{{ 'Search by Anything' | translate }}"
               [formControl]="passportFilter.search">
      </md-input-container>
      <div *ngIf="isRecordsLoaded" [ngClass]="{'advanced-research-list__found': true, 'primary-color': true}" [innerHTML]="getItemsAmountText()">
      </div>
    </div>

    <div class="advanced-research-list__blocks">
      <div class="advanced-research-list__filters">
        <div class="advanced-research-list__filters--item">
          <mat-slide-toggle class="advanced-research-list__toggler"
                            (change)="loadRecords()"
                            [disabled]="!isRecordsLoaded"
                            [checked]="advancedResearchLoadPassports"
                            [(ngModel)]=advancedResearchLoadPassports
          >
            {{'See only my passports' | translate}}
          </mat-slide-toggle>
        </div>


        <md-input-container
        class="passportlist__search-materials advanced-research-list__search-materials"
        [floatPlaceholder]="passportFilter.categoryTags.length ? 'always' : ''"
        (click)="openCategoryeDialog(categorie)"
      >
        <md-chip-list>
          <md-basic-chip *ngFor="let tag of passportFilter.categoryTags" (focus)="false" md-tooltip="{{ tag.name | translate }}">
            {{(tag.name.length > 7 ? tag.name.substr(0,7)+'..' : tag.name ) | translate }}

            <button (click)="removeCategoryTag(tag, $event)" class="delete" ></button>
          </md-basic-chip>
        </md-chip-list>
        <input test-id="nomenclature-selector"
                   class="passportlist__info-by"
                   #categorie
                   autocomplete="off"
                   placeholder="{{ 'Category' | translate }}"
                   mdInput
        >
      </md-input-container>



        <div class="advanced-research-list__filters--item">
          <md-input-container
            class="passportlist__search-materials advanced-research-list__search-materials"
            [floatPlaceholder]="passportFilter.tags.length ? 'always' : ''"
            (click)="openNomenclatureDialog(materiallist)"
          >
            <md-chip-list
            >
              <md-basic-chip *ngFor="let tag of passportFilter.tags" (focus)="false"
                             [disabled]="!isRecordsLoaded"
              >
                {{tag.name}}
                <button (click)="removeTag(tag, $event)" class="delete" ></button>
              </md-basic-chip>
            </md-chip-list>

            <input
              test-id="nomenclature-input"
              #materiallist
              [disabled]="!isRecordsLoaded"
              mdInput
              autocomplete="off"
              placeholder="{{ 'Materials' | translate }}"
            >
          </md-input-container>
        </div>
        <div class="advanced-research-list__filters--item">
          <family-filter [ownPassportOnly]="advancedResearchLoadPassports"
                        [userRole]="parentModuleName"
                        [familyFilter]="this.passportFilter.family"
                        (FamilyFilterChange)="onFamilyFilterChange($event)"></family-filter>
        </div>
        <div class="advanced-research-list__filters--item">
          <md-select test-id="sort-selector"
                     [disabled]="!isRecordsLoaded"
                     (change)="loadRecords()"
                     placeholder="{{ 'Sort by' | translate }}"
                     [(ngModel)]=passportFilter.orderBy
          >
            <md-option *ngFor="let sort of sort_order" [value]="sort.key">
              {{sort.value | translate}}
            </md-option>
          </md-select>
        </div>
       

      </div>
      <div class="advanced-research-list__content">
        <div class="advanced-research-list__list">
          <div *ngIf="!isRecordsLoaded" class="advanced-research-list__spinner--wrapper">
            <md-progress-spinner
              class="advanced-research-list__spinner--small"
              mode="indeterminate">
            </md-progress-spinner>
          </div>

          <div class="advanced-research-list__results"
               *ngIf="!!isRecordsLoaded"
          >
            <virtual-scroll
              *ngIf="!!records.length"
              #scroll
              [items]="records"
              (vsEnd)="loadNextPage($event)"
            >
              <passport-preview-item
                *ngFor="let item of scroll.viewPortItems; let i = index"
                [passport]="item"
                [isColored]="isItemColored(item)"
                [searchString]="passportFilter.search.value"
                class="advanced-research-list__item"
                [parentModuleName]="parentModuleName"
                (onPassportSelected)="onSelected.emit($event)"
                (onOpen)="openRecord($event)"
                [isVersionFilterOn]="isVersionFilterOn"
                (onVersionFilter)="onVersionFilter($event)"
              >
              </passport-preview-item>
              <div *ngIf="isLoadingNextPage"
                   class="advanced-research-list__spinner--wrapper advanced-research-list__spinner--wrapper--fixed">
                <md-progress-spinner
                  class="advanced-research-list__spinner--small--fixed"
                  mode="indeterminate">
                </md-progress-spinner>
              </div>
            </virtual-scroll>

            <div *ngIf="!records.length">
              <div>
                {{'No result found' | translate}}
              </div>
            </div>


          </div>

        </div>

      </div>
    </div>

  </div>

</div>
