/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ecosystem-dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ecosystem-primary-header/ecosystem-primary-header.component.ngfactory";
import * as i3 from "../ecosystem-primary-header/ecosystem-primary-header.component";
import * as i4 from "../../../../shared/services/user.service";
import * as i5 from "@angular/material";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../entities/need/services/need.service";
import * as i8 from "@angular/router";
import * as i9 from "./ecosystem-catalog/ecosystem-catalog.component.ngfactory";
import * as i10 from "./ecosystem-catalog/ecosystem-catalog.component";
import * as i11 from "./ecosystem-dashboard.component";
var styles_EcosystemDashboardComponent = [i0.styles];
var RenderType_EcosystemDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EcosystemDashboardComponent, data: {} });
export { RenderType_EcosystemDashboardComponent as RenderType_EcosystemDashboardComponent };
export function View_EcosystemDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "article", [["class", "ecosystem-dashboard"], ["test-id", "esm-dashboard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "main", [["class", "ecosystem-dashboard__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ecosystem-primary-header", [], null, null, null, i2.View_EcosystemPrimaryHeaderComponent_0, i2.RenderType_EcosystemPrimaryHeaderComponent)), i1.ɵdid(3, 49152, null, 0, i3.EcosystemPrimaryHeaderComponent, [i4.UserService, i1.NgZone, i5.MdDialog, i6.TranslateService, i7.NeedService, i8.Router], { isMainPage: [0, "isMainPage"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "section", [["class", "ecosystem-dashboard__section ecosystem-dashboard__section--tabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ecosystem-catalog", [], null, null, null, i9.View_EcosystemCatalogComponent_0, i9.RenderType_EcosystemCatalogComponent)), i1.ɵdid(6, 49152, null, 0, i10.EcosystemCatalogComponent, [i1.NgZone], null, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EcosystemDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_EcosystemDashboardComponent_0, RenderType_EcosystemDashboardComponent)), i1.ɵdid(1, 49152, null, 0, i11.EcosystemDashboardComponent, [], null, null)], null, null); }
var EcosystemDashboardComponentNgFactory = i1.ɵccf("ng-component", i11.EcosystemDashboardComponent, View_EcosystemDashboardComponent_Host_0, {}, {}, []);
export { EcosystemDashboardComponentNgFactory as EcosystemDashboardComponentNgFactory };
