import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Type } from '@angular/core';
import { PassportTagService } from '../services/passport-tag.service';
import { Synonym } from '../../../shared/models/synonym.class';

export class PassportTag extends ActiveRecord {
  protected provider: Type<PassportTagService> = PassportTagService;

  id: number;
  name: string;
  synonyms: Synonym[];

  fields() {
    return ['id', 'name', 'synonyms'];
  }
}
