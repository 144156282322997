
import { ActiveRecord } from '../../../shared/models/active-record.class';

import { GenericPassportService } from '../service/generic-passport.service';
import { ECustomListUnit } from '../../../shared/custom-list/interfaces/custom-list.interface';
import { GENERIC_PASSPORT_UNITS } from './values/generic-passport-units.const';
import { CategoryItem } from '../../category/models/category-item.class';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { GenericPassportMaterial } from './generic-passport-materials.class';
import { LandfillVariables } from '../../../dashboards/superadmin/components/landfill-variables/landfill-variables.model';
import { GenericPassportTagJunction } from './generic-passport-tag-junction.class';

export class GenericPassport extends ExtendedActiveRecord {
  protected provider = GenericPassportService;
  // general variables
  public id: number;
  //public name: string;
  public comment: Text;
  public category_id: number;
  public waste_category_id: number;
  public unit: number;
  public conversion_factor: number;
  public purchase_cost: number;
  public default_recoverability_average: number;
  public zone_id: number

  public landfil_cost: number ;
  //public landfil_cost: number ;

  //upcycling variables
  public upcycling_extra_cost_for_clean_removal: number;
  public upcycling_resale_price_up: number ;
  public upcycling_resale_price_down: number ;
  public upcycling_residual_value_up: number;
  public upcycling_residual_value_down: number;

  public upcycling_added_value_up: number ;
  public upcycling_added_value_down: number ;
  // reuse variables
  public reuse_extra_cost_for_clean_removal: number ;
  public reuse_resale_price_up: number ;
  public reuse_resale_price_down: number ;
  public reuse_residual_value_up: number ;
  public reuse_residual_value_down: number;
  public construction_layer: number;

  public reuse_added_value_up: number ;
  public reuse_added_value_down: number ;

  category: CategoryItem;
  waste_category: LandfillVariables;
  public materials: GenericPassportMaterial[] = [];
  public tags: GenericPassportTagJunction[] = [];

  constructor(data?: any) {
    super(data);
    if (data) {
      this.category = data.category ;
      this.waste_category = data.waste_category;
      if (data.materials) {
        this.materials = data.materials.map((material) => new GenericPassportMaterial(material));
      }
      if (data.tags) {
        this.tags = data.tags.map((tag) => new GenericPassportTagJunction(tag));
      }
    }
  }
  protected fields() {
    return [
      'id',
      'comment',
      'category_id',
      'waste_category_id',
      'construction_layer',
      'unit',
      'conversion_factor',
      'purchase_cost',
      'default_recoverability_average',
      'upcycling_extra_cost_for_clean_removal',
      'upcycling_residual_value_up',
      'upcycling_residual_value_down',
      'reuse_extra_cost_for_clean_removal',
      'reuse_residual_value_up',
      'reuse_residual_value_down',
      'reuse_resale_price_up',
      'reuse_resale_price_down',
      'upcycling_resale_price_up',
      'upcycling_resale_price_down',
      'unit_text',
      'landfil_cost',
      'zone_id'
    ];
  }

  get customListUnit() {
    return (<any>{
      'kg': ECustomListUnit.KG,
      'tonne': ECustomListUnit.TON,
      'm2': ECustomListUnit.M2,
      'm3': ECustomListUnit.M3,
      'unit': ECustomListUnit.UNIT,
      'piece': ECustomListUnit.PCS,
      'm': ECustomListUnit.M,
      'L': ECustomListUnit.L
    })[this.unit_text];
  }
  get unit_text() {
    const unit = GENERIC_PASSPORT_UNITS.find((item) => item.id === this.unit);
    return unit ? unit.title : this.unit;
  }

  get unitView() {
    return this.unit_text;
  }

}
