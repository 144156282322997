import { Injectable } from '@angular/core';
import {ImportConfigurationService} from './import-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ImportSiteConfigurationService extends ImportConfigurationService {
  public getUpdateColumnListEndpoint() {
    return this.getEndpoint('import-configuration/update_sites_columns_list');
  }
}
