export const CHART_DATA = {
    percentage_weight_of_controlled_toxicity: 'percentage_weight_of_controlled_toxicity',
    percentage_weight_of_controlled_verified_toxicity: 'percentage_weight_of_controlled_verified_toxicity',
    percentage_weight_of_healthy_product: 'percentage_weight_of_healthy_product',
    percentage_weight_of_unknown_toxicity: 'percentage_weight_of_unknown_toxicity',
    percentage_weight_of_known_toxicity: 'percentage_weight_of_known_toxicity'
};

export const CHART_CATEGORY = {
    percentage_of_healthy_product: 'percentage_of_healthy_product',
    percentage_of_unknown_toxicity: 'percentage_of_unknown_toxicity',
    percentage_of_known_toxicity: 'percentage_of_known_toxicity',
    healthiness: 'healthiness',
    weight: 'weight',
};
