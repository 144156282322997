import { NgModule } from '@angular/core';
import { StatsService } from './services/stats.service';

@NgModule({
  imports: [
  ],
  providers: [
    StatsService
  ],
  declarations: [
  ],
  entryComponents: [
  ],
  exports: [
  ]
})
export class StatsModule {
}
