<custom-selection-list-with-chip
  [allVariants]="countyRecords"
  [chosenItems]="areaRecords"
  listNameField="name"
  [disabledChip]="this.isDisabled"
  [disabledList]="this.disableStatus"
  [isItemChecked]="checkedPassportDistributionAreas"
  (onChange)="this.onPassportDistributionAreasChange($event.check, $event.value)"
  (onDelete)="onPassportDistributionAreasDeleted(null, $event)"
>
</custom-selection-list-with-chip>
