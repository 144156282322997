import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { Injectable } from '@angular/core';
import { EcosystemFile } from '../../../dashboards/ecosystem-manager/models/ecosystem-file.class';

@Injectable()
export class EcosystemFileService extends ExtendedCrudService {
  protected namespace = 'ecosystem-files';
  protected namespaceSingular = 'ecosystem-file';
  protected ModelClass = EcosystemFile;
}
