import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MdDialogRef} from '@angular/material';
import {IAdminWarningWithHeader} from './values/admin-warning-with-header.interface';

@Component({
  selector: 'admin-warning-with-header-dialog',
  templateUrl: './admin-warning-with-header-dialog.component.html',
  styleUrls: ['./admin-warning-with-header-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminWarningWithHeaderDialogComponent implements OnInit, IAdminWarningWithHeader {

  @Input() titleText: string;
  @Input() confirmText;
  @Input() subHeaders: Array<any>;
  @Input() main: Array<any>;
  @Input() className: string;

  constructor(private dialog: MdDialogRef<IAdminWarningWithHeader>) { }

  ngOnInit() {
    Object.assign(this, this.dialog._containerInstance.dialogConfig.data);
  }

  confirm() {
    this.dialog.close(true);
  }
  cancel() {
    this.dialog.close(false);
  }
}
