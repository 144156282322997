import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EcosystemIndicatorReportContext } from '../../../services/ecosystem-indicator-report/ecosystem-indicator-report-context.service';
import { EEcosystemIndicatorReportType } from '../../../values/ecosystem-indicator-report.const';
import { EcosystemStep } from '../../../models/ecosystem-step.class';
import {SummaryDetailOrder} from '../../../models/summary-details.intercface';


@Component({
    moduleId: module.id,
    selector: 'ecosystem-indicator-report-details-tab',
    templateUrl: 'ecosystem-indicator-report-details-tab.component.html',
    styleUrls: ['../ecosystem-indicators.component.scss'],
  })
export class EcosystemIndicatorReportDetailsTabComponent {
    @Input() cssStyleClass: string;
    @Input() subTitle: string;
    @Input() type: EEcosystemIndicatorReportType;
    @Input() reportContext: EcosystemIndicatorReportContext;
    isDetailsOpen = false;

    get virginProductsSteps(): EcosystemStep[]{
      return this.reportContext.getStepsStrategy(this.type).virginProducts || [];
    }

    get depositSteps(): EcosystemStep[] {
      return this.reportContext.getStepsStrategy(this.type).deposits || [];
    }

    get transformSteps(): EcosystemStep[] {
      return this.reportContext.getStepsStrategy(this.type).transforms || [];
    }

    getOrders(step: EcosystemStep): SummaryDetailOrder[] {
      return this.reportContext.getOrdersByStep(this.type, step);
    }

    get transportSteps(): EcosystemStep[] {
      return this.reportContext.getStepsStrategy(this.type).transports || [];
    }
    get needSteps(): EcosystemStep[] {
      return this.reportContext.getStepsStrategy(this.type).needs || [];
    }

    get stepUnit(): string {
      return this.reportContext.getUnit(this.type);
    }

    constructor(
      public translate: TranslateService
      ) {}

    toggleDetails() {
      this.isDetailsOpen = !this.isDetailsOpen;
    }

    getFormattedValue(step) {
      return  this.isDataMissing(step) ? this.translate.instant('Missing data')
        : this.reportContext.formatValueStrategy(this.type, step);
    }

    isDataMissing(step) {
      return  this.reportContext.isDataMissingStrategy(this.type, step.errors, step);
    }

    hasWarnings(step) {
      return this.reportContext.hasWarningsStrategy(this.type, step.warnings);
    }

    getWarnings(step) {
      return this.reportContext.getWarningsStrategy(this.type, step.warnings);
    }
}
