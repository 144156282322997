import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { TransformSiteJunction } from '../models/transform-site-junction.class';

@Injectable()
export class TransformSiteJunctionService extends CrudService {
  protected namespace = 'transformation-site-junctions';
  //protected namespaceSingular = "transformation-site-junction";
  protected ModelClass = TransformSiteJunction;
}
