<div
  class="custom-search suggestible-text-input"
  [ngClass]="{'custom-search--focus': isFocus}"
  (focusin)="focusIn()"
  (focusout)="focusOut()"
>
  <div class="custom-search__field ib-row--justify">
    <div class="custom-search__input">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="custom-search__overlay">
    <ul class="custom-search__results">
      <li
        class="custom-search__item"
        *ngFor="let suggestion of suggestions"
        (click)="suggest(suggestion)"
      >
        {{showSuggestion(suggestion)}}
      </li>
    </ul>
    <div class="control-group__badge--corner" *ngIf="itemsCount">
      <div class="control-group__badge">{{itemsCount}}</div>
    </div>
  </div>
</div>
