import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { ComponentService } from '../services/component.service';
import { DEMOUNTABLE } from '../values/demountable-values.const';
import { Material } from './material.class';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
import { ValidationService } from '../../../shared/services/validation-service';
var PassportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PassportComponent, _super);
    function PassportComponent(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = ComponentService;
        _this.materials = [];
        _this.nomenclature = [];
        _this.isDefault = false;
        if (data) {
            _this.materials = data.materials || [];
            _this.nomenclature = data.nomenclature || [];
            _this.isDefault = data.isDefault || false;
            _this.percentage_weight = data.percentage_weight || null;
            _this.demountable = data.demountable || null;
        }
        return _this;
    }
    PassportComponent.prototype.fields = function () {
        return ['id', 'name', 'quantity', 'passport_id', 'demountable', 'materials', 'nomenclature', 'percentage_weight'];
    };
    Object.defineProperty(PassportComponent.prototype, "demountableView", {
        get: function () {
            var _this = this;
            var foundItem = (DEMOUNTABLE.find(function (type) { return type.value === _this.demountable; }));
            return !!foundItem && foundItem.viewValue;
        },
        enumerable: true,
        configurable: true
    });
    PassportComponent.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        json.materials = (json.materials || []).map(function (mat) {
            return new Material(mat);
        });
        json.nomenclature = (json.nomenclature || []).map(function (nom) {
            return new NomenclatureItem(nom);
        });
        return json;
    };
    PassportComponent.prototype.beforeSave = function (data) {
        var dataValues = _super.prototype.beforeSave.call(this, data);
        if (dataValues.quantity) {
            dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
        }
        // if(typeof dataValues.quantity === 'string') {
        //   let parsedQuantity = LocaleService.tryParseToNumber(dataValues.quantity);
        //   if(typeof parsedQuantity === 'number') {
        //     dataValues.quantity = parsedQuantity;
        //   }
        // }
        return dataValues;
    };
    return PassportComponent;
}(ActiveRecord));
export { PassportComponent };
