<div class="form-report" *ngIf="loadData">
  <div>
    <h1>{{ 'DEFINE THE SCOPE OF THE REPORT' | translate}} :
    </h1>
    <md-radio-group class="deposit-types-details__radio-group"
      (change)="depositScopeChange()"
      [(ngModel)]="deposit_scope"
    >
      <ng-container *ngIf="!isRestrictedAdmin">
        <div *ngFor="let filter of deposit_scopes">
        <md-radio-button
        class="deposit-types-details__radio-group--buttons"
        [value]="filter.key"
        >
        {{filter.value | capitalize | translate}}
        </md-radio-button>
        <div *ngIf="deposit_scope === report_form_values.SELECTED_SITES
          && filter.key === report_form_values.SELECTED_SITES"
             [ngClass]="{'report_types__header': true,
              'report_types__header--sub': true,
              'report_types__disabled': disableSites}"
        >
          <div class="report_types__site-list">
            <div *ngFor="let item of sitesForm?.controls; let i=index" [@popClose]>
              <div class="report_types__site-card" [formGroup]="sitesForm.controls[i]">
                <md-icon class="report_types__site-card-close" (click)="removeSite(i)">{{'close' | translate}}</md-icon>
                <span class="report_types__site-card-number primary-color">{{ 'Site' | translate }} {{i + 1}}</span>
                <md-input-container floatPlaceholder="never" class="report_types__site-card-name-container">
                  <suggestible-text-input
                    [suggestions]="filteredSites"
                    [viewProperty]="'nameAddress'"
                    (onChange)="selectedSite($event, i)"
                    (onFocusOut)="siteFocusOut(i)"
                    [itemsCount]="filteredSites.length"
                  >
                    <input mdInput
                           class="report_types__site-card-name-value"
                           formControlName="name"
                           autocomplete="off"
                           placeholder="{{'Select a site' | translate}}"
                           (ngModelChange)="filterSiteSuggestion($event)">
                  </suggestible-text-input>
                </md-input-container>
                <div class="report_types__site-card-tags">
                  <div *ngFor="let tag of item.value.tags; let j = index"
                       class="report_types__site-card-tags-item background-primary-color"
                  >
                    <span>{{tag.name}}</span>
                    <md-icon (click)="removeTag(i, j)" >close</md-icon>
                  </div>
                  <div class="control-group__item-button report_types__site-card-add" >
                    <md-icon (click)="openTagSuggest(i)">{{'add' | translate}}</md-icon>
                    <div [hidden]="tagOpenSuggestIndex !== i">
                      <md-input-container class="report_types__site-card-tags-select">
                        <suggestible-text-input
                          [suggestions]="item.value.availableTags"
                          [viewProperty]="'name'"
                          (onFocusOut)="tagSuggestBlur(i)"
                          (onChange)="selectTag($event, i)"
                          [itemsCount]="!!item.value.availableTags ? item.value.availableTags.length : 0"
                        >
                          <input #tagInput
                                 mdInput
                                 autocomplete="off"
                                 formControlName="tagSuggestion"
                                 (ngModelChange)="filterTagSuggestion($event, i)"
                          >
                        </suggestible-text-input>
                      </md-input-container>
                    </div>
                    <div class="control-group__item--subtext report_types__site-card-add-text"
                         (click)="openTagSuggest(i)"
                    >
                      {{'add a tag' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isRestrictedAdmin" class="control-group__item flex
           align-center control-group__item-add report_types__header-add" (click)="addSite()">
            <div class="control-group__item-button">
              <md-icon>{{'add' | translate}}</md-icon>
            </div>
            <div class="control-group__item--subtext report_types__header-add-text">
              {{'add a site' | translate}}
            </div>
          </div>
        </div>
        </div>
      </ng-container>
    </md-radio-group>
  </div>

  <div class="report_types__header">
    <h1>{{ 'SELECT THE TYPE OF DEPOSITS' | translate}} :
      <label
        class="form-group__label"
        md-tooltip="{{ 'SELECT_THE_TYPE_OF_DEPOSITS_TOOLTIP' | translate }}"
      >
        <i class="form-group__label--question">?</i>
    </label>
    </h1>
    <md-radio-group class="deposit-types-details__radio-group"
      [(ngModel)]="depositType"
      
    >
      <md-radio-button
       
        class="deposit-types-details__radio-group--buttons"
        *ngFor="let filter of deposit_types"
        [value]="filter.key"
      >
        {{filter.value | capitalize | translate}}
      </md-radio-button>
    </md-radio-group>
  </div>

  <div class="report_types__list" >
      <h1 class="report_types__label">{{ 'SELECT THE TYPE OF REPORT YOU WANT' | translate}} :
        <label
          class="form-group__label"
          md-tooltip="{{ 'SELECT_THE_TYPE_OF_REPORT_TOOLTIP' | translate }}">
          <i class="form-group__label--question">?</i>
        </label>
      </h1>
        <md-checkbox class="report_types__item" [(ngModel)] = "report.carbon_foot_print"
        (change)="carbonReportTypeSelect()">
          {{ 'Carbon foot print' | translate }}
        </md-checkbox>
        <carbon-footprint-form  [companyType]="companyType"  [depositType]="depositType" [(carbonFootprintForm)]="carbonFootprintForm" *ngIf="isCarbonReportOptionSelected">
        </carbon-footprint-form>
        <md-checkbox class="report_types__item" [(ngModel)] = "report.material_health">
          {{ 'Material health' | translate }}
        </md-checkbox>
        <md-checkbox class="report_types__item" [(ngModel)] = "report.circularity">
          {{ 'Circularity' | translate }}
        </md-checkbox>
        <md-checkbox
          class="report_types__item"
          (change)="reportTypeSelect($event)"
          [(ngModel)] = "report.financial_residual_value"
          [disabled]="depositType === this.report_form_values.DEPOSIT_TYPE_OTHER"
        >
          {{ 'Financial residual value' | translate}}
          <label
            class="form-group__label"
            md-tooltip="{{ 'FINANCIAL_RESIDUAL_VALUE_TOOLTIP' | translate }}">
            <i class="form-group__label--question">?</i>
          </label>
        </md-checkbox>
  </div>

  <div class="residualval-details__list" *ngIf="depositType === this.report_form_values.DEPOSIT_TYPE_BUILDING && isRenewalRatesAble">
    <md-checkbox
      class="residualval-details__item"
      [(ngModel)]="deconstruction"
      [disabled]="!isRenewalRatesAble" >
      {{'Deconstruction'| translate}}
      <label
        class="form-group__label"
        md-tooltip="{{ 'DECONSTRUCTION_TOOLTIP' | translate }}">
        <i class="form-group__label--question">?</i>
      </label>
    </md-checkbox>
    <md-checkbox
      class="residualval-details__item"
      [(ngModel)]="rehabilitation"
      [disabled]="!isRenewalRatesAble" >
      {{'Rehabilitation' | translate}}
      <label
        class="form-group__label"
        md-tooltip="{{ 'REHABILITATION_TOOLTIP' | translate }}">
        <i class="form-group__label--question">?</i>
      </label>
    </md-checkbox>
    <md-checkbox
      class="residualval-details__item"
      [(ngModel)]="renewalBuildingComponents"
      (change)="checkResidualValueChecked($event)"
      [disabled]="!isRenewalRatesAble" >
      {{'Renewal of building components over 30 years' | translate}}
      <label
        class="form-group__label"
        md-tooltip="{{ 'RENEWAL_BUILDING_COMPONENTS_TOOLTIP' | translate }}">
        <i class="form-group__label--question">?</i>
      </label>
    </md-checkbox>
  </div>

 <div class = "building-category" *ngIf="building_categories.length > 0">
    <h1 class="bulding_cat_label">{{ 'SELECT THE CATEGORY OF YOUR BUILDING(s)' | translate}} :
      <label
        class="form-group__label"
        md-tooltip="{{ 'BUILDING_CATEGORY_TOOLTIP' | translate }}"
      >
        <i class="form-group__label--question">?</i>
      </label>
    </h1>
    <md-radio-group
      class="category-building-details__radio-group"
      [(ngModel)]="buildingCategory"
      (change)="buildingCategoryChanged($event)"
    >
      <md-radio-button
        class="category-building-details__radio-group--buttons"
        *ngFor="let bcat of building_categories"
        [value]="bcat.key"
        [disabled]="!bcat.active"
      >
        {{bcat.value | capitalize | translate}}
        <label *ngIf="bcat.tooltip"
          class="form-group__label"
          md-tooltip="{{ bcat.tooltip | translate }}">
          <i class="form-group__label--question">?</i>
         </label>
      </md-radio-button>
    </md-radio-group>
 </div>

   <div *ngIf="isTableVisible">
     <table >
        <thead>
          <tr> <th>{{ 'HYPOTHESIS OF 30-YEAR CONSTRUCTION LAYER RENEWAL RATES' | translate | uppercase }}</th><th></th> </tr>
        </thead>
        <tbody>

          <tr *ngFor="let item of constructionLayers" ><td>{{item.title | translate}} <br> <span style="font-size: 10px" *ngIf="item.subtitle != '' ">{{  item.subtitle | translate | uppercase}}</span></td><td class="value">{{item.value}}</td> <td> {{'time(s)'| translate}} </td></tr>

        </tbody>
     </table>
   </div>

  <div class="report_types__header">
    <h1>
      <span  [ngClass]="{'report_types__disabled': !isRenewalRatesAble}">
        {{ 'Select the geographical zone for calculations' | translate}} :
      </span>
      <label
        class="form-group__label"
        appCustomTooltip
        [tooltipClass]="['passportlist__version-tooltip']"
        md-tooltip="{{ 'SELECT_ZONE_REPORT_TOOLTIP' | translate }}"
      >
        <i class="form-group__label--question">?</i>
    </label>
    </h1>

    <div class="control-group__item report_types__zones-wrapper">
      <small [ngClass]="{'report_types__zone-label': true, 'report_types__disabled':!isRenewalRatesAble }">{{ 'Zone' | translate }}</small>
      <md-select floatPlaceholder="never" [(ngModel)]="zoneId" [disabled]="!isRenewalRatesAble">
        <md-option *ngFor="let item of zones" [value]="item.id">{{item.name}}
        </md-option>
      </md-select>
    </div>

  </div>

</div>

<div class="form-report" *ngIf="!loadData">
  <md-progress-spinner
      
      mode="indeterminate">
  </md-progress-spinner>
</div>
<div class="row btn-wrapper">
  <button  class="generate-button" (click)="generateReport()" [disabled]="!reportProgressService.canGenerateReport()" >
    {{'Generate report(s)' | translate}}
  </button>
</div>
