import { Component, Input } from '@angular/core';
import { NavigationService } from '../../shared/services/navigation.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() width: string = '48px';
  @Input() height: string = '48px';
  
  constructor(private _navigation: NavigationService) { }

  /**
  * Determines the CSS class for coloring pagination based on the user's role.
  * 
  * @returns The CSS class corresponding to the user's role.
  */
  getClassColor(): string {
    let role = this._navigation.getContext().roleText;
    switch (role) {
      case 'Agent':
        return 'role-agent';
      case 'Manufacturer':
        return 'role-manufacturer';
      case 'Specialadmin':
        return 'role-specialadmin';
      default:
        return '';
    }
  }
}
