import {Component, Inject, OnInit} from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
@Component({
  template: `
    <dialog-close-button
      (click)="mdDialogRef.close()">
    </dialog-close-button>

    <transform-details
      [id]="mdDialogData.id"
      [readOnly]="mdDialogData.readOnly"
      [showValidationButtons]="mdDialogData.showValidationButtons"
      (action)="mdDialogRef.close()"
    ></transform-details>
  `,
  host: {
    'class': 'dialog-close-button-container',
  },
})
export class TransformDetailsDialogComponent {
  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<void>) {
  }
}
