/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./do-import.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../import-spreadsheet/partials/import-spreadsheet/import-spreadsheet.component.ngfactory";
import * as i3 from "../../../../import-spreadsheet/partials/import-spreadsheet/import-spreadsheet.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/material";
import * as i6 from "../../../../shared/services/log-notification.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../entities/deposit/services/deposit-import.service";
import * as i9 from "../../../../import-spreadsheet/services/import-configuration.service";
import * as i10 from "../../../../import-spreadsheet/services/import-progress.service";
import * as i11 from "../../../../entities/deposit/services/deposit-file.service";
import * as i12 from "../../../../entities/deposit/services/deposit-location-import-file.service";
import * as i13 from "../../../../import-spreadsheet/services/import-site-configuration.service";
import * as i14 from "./do-import.component";
import * as i15 from "../../../../import-spreadsheet/services/import-validators.service";
import * as i16 from "../../../../import-spreadsheet/services/import-deepstream.service";
var styles_DoImportComponent = [i0.styles];
var RenderType_DoImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DoImportComponent, data: {} });
export { RenderType_DoImportComponent as RenderType_DoImportComponent };
export function View_DoImportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-import-spreadsheet", [], null, [[null, "submit"], [null, "documentUploaded"], ["window", "beforeunload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).beforeunloadHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("submit" === en)) {
        var pd_1 = (_co.updateImportAndShowWarning() !== false);
        ad = (pd_1 && ad);
    } if (("documentUploaded" === en)) {
        var pd_2 = (_co.documentUploaded() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ImportSpreadsheetComponent_0, i2.RenderType_ImportSpreadsheetComponent)), i1.ɵdid(1, 245760, null, 0, i3.ImportSpreadsheetComponent, [i4.FormBuilder, i5.MdDialog, i1.NgZone, i6.LogNotificationService, i7.TranslateService, i8.DepositImportService, i9.ImportConfigurationService, i10.ImportProgressService], { importModel: [0, "importModel"], labelText: [1, "labelText"], importForm: [2, "importForm"], importFormAdditionalData: [3, "importFormAdditionalData"], fields: [4, "fields"], importPreferences: [5, "importPreferences"], onDocumentSave$: [6, "onDocumentSave$"], onUpdateUploadUrl$: [7, "onUpdateUploadUrl$"], userProhibitColumnLetters: [8, "userProhibitColumnLetters"], uploadedDepositFile: [9, "uploadedDepositFile"] }, { submit: "submit", documentUploaded: "documentUploaded" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.depositImport; var currVal_1 = "import sites from a spreadsheet"; var currVal_2 = _co.depositImportForm; var currVal_3 = _co.DEPOSIT_IMPORT_CONTROL_META; var currVal_4 = _co.fields; var currVal_5 = _co.importPreferences; var currVal_6 = _co.onDocumentSave$; var currVal_7 = _co.onUpdateUploadUrl$; var currVal_8 = i1.ɵEMPTY_ARRAY; var currVal_9 = _co.uploadedDepositFile; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_DoImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-do-import", [], null, null, null, View_DoImportComponent_0, RenderType_DoImportComponent)), i1.ɵprd(6144, null, i11.DepositFileService, null, [i12.DepositLocationImportFileService]), i1.ɵprd(2048, null, i9.ImportConfigurationService, null, [i13.ImportSiteConfigurationService]), i1.ɵdid(3, 4308992, null, 0, i14.DoImportComponent, [i4.FormBuilder, i15.ImportValidatorsService, i9.ImportConfigurationService, i10.ImportProgressService, i16.ImportDeepstreamService, i5.MdDialog], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var DoImportComponentNgFactory = i1.ɵccf("app-do-import", i14.DoImportComponent, View_DoImportComponent_Host_0, { tables: "tables", savePreferencesAfterNumberOfChanges: "savePreferencesAfterNumberOfChanges", savePreferencesAfterInInterval: "savePreferencesAfterInInterval", savePreferencesAfterSubmit: "savePreferencesAfterSubmit", savePreferencesOnDestroy: "savePreferencesOnDestroy" }, { submit: "submit" }, []);
export { DoImportComponentNgFactory as DoImportComponentNgFactory };
