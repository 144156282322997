/**
 * Created by aleksandr on 14.06.17.
 */

export interface ICustomListRecord {
  primaryKey: string;
  columns: ICustomListColumn[];
  status: ECustomListRecordStatus;
  hint: ICustomListRecordHint;
  tooltype: ICustomListRecordTooltype;
  recordStatus: String;
}

export interface ICustomListColumn {
  type: ECustomListColumnType;
  label: string | {text: string, english_name: string};
  value: TCustomMenuValue;
  english_name?: string;
}
export interface ICustomListRecordHint {
  cssClass: string;
  value: string;
}

export interface ICustomListRecordTooltype {
  cssClass: string;
  value: string;
}

export interface ICustomListMenuOption {
  fn: (data: ICustomListRecord) => void;
  name: string;
}

export interface ICustomListMenu {
  options: ICustomListMenuOption[];
  cssClass?: string;
  icon: string;
}

export interface ICustomListKeyPair {
  key: string;
  value: string;
  seperator?: string;
  cssClass?: string;
  valueClickClosure?: (someData?: any) => void;
}

export interface ICustomListUnit {
  amount: string;
  unit: string;
  status?: string;
  cssClass?: string;
}

export interface ICustomListTheme {
  cssClass: string;
  value: number;
}

export interface ICustmonListArray {
  valueList: string[]
}

export interface  ICustomListImage{
  value: string;
}

export type TCustomMenuValue = string | ICustomListKeyPair[] | ICustomListMenu | ICustomListUnit | string[] | {text: string, english_name: string};

export enum ECustomListColumnType {
  TEXT = 0,
  ICON = 1,
  TITLE = 2,
  LIST = 3,
  AUTHOR = 4,
  CONTROLS = 5,
  COUNTER = 6,
  DATE = 7,
  UNIT = 8,
  IMAGE = 9,
}

export enum ECustomListRecordStatus {
  NO_STATUS = 0,
  CONFIRM = 1, // green check
  IMPORTANT = 2, // purple exclamation
  MANUAL = 3, // blue hand
  QUESTION = 4, // blue question mark
  CANCELED = 5 // red cross
}

export const CustomListStatusClass = [
  'status--default',
  'status--confirm',
  'status--important',
  'status--manual',
  'status--question',
  'status--canceled'
];

export enum ECustomListHintClass {
  NO_CLASS = 0,
  WARNING = 1,
  INFO = 2,
  CONFIRM = 3
}

export const CustomListHintClass = [
  'hint--default',
  'hint--warning',
  'hint--info',
  'hint--confirm'
];

export enum ECustomListUnit {
  TON,
  KG,
  M2,
  M3,
  UNIT,
  PCS,
  M,
  L
}

export const CustomListUnitValue = [
  'T',
  'kg',
  'm²',
  'm³',
  '',
  'pcs',
  'm',
  'L'
];

export const CustomListUnitCssClass = [
  'unit--weight',
  'unit--weight',
  'unit--square',
  'unit--square',
  '',
  'unit--amount',
  'unit--square',
  ''
];

export enum ECustomListThemeClass {
  LIGHT = 0,
  DARK = 1
}

export const CustomListThemeClass = [
  'theme--light',
  'theme--dark'
];

export const ECustomListKeyPairClass = {
  REVERSED_LIST_COLUMN: 'reversed-list-column'
};
