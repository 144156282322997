/**
 * Created by Aleksandr C. on 8.02.17.
 */
import { DepositPhotoService } from '../services/deposit-photo.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';

export class DepositPhoto extends UploadedDocument {
  protected provider = DepositPhotoService;

  public deposit_id: number;

  protected fields() {
    return super.fields().concat([
      'deposit_id',
    ]);
  }
}
