import * as tslib_1 from "tslib";
import { BIMService } from '../services/bim.service';
import { ActiveRecord } from './active-record.class';
var BIM = /** @class */ (function (_super) {
    tslib_1.__extends(BIM, _super);
    function BIM() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = BIMService;
        return _this;
    }
    BIM.prototype.fields = function () {
        return ['name', 'id'];
    };
    return BIM;
}(ActiveRecord));
export { BIM };
