import { Component, Inject, OnInit } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import { Deposit } from '../../../../entities/deposit/models/deposit.class';

@Component({
  moduleId: module.id,
  selector: 'deposit-delete-warning',
  templateUrl: 'deposit-delete-warning.component.html',
  styleUrls: ['deposit-delete-warning.component.scss']
})
export class DepositDeleteWarningComponent implements OnInit {

  deposits: Deposit[];

  constructor(public mdDialogRef: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any) {

  }

  ngOnInit(): void {
    this.deposits = this.mdDialogData.deposits;
  }

}
