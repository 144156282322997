<div #filterRating tabindex="-1" class="passportlist__info-rating-filter-wrapper">
  <div *ngIf="showFilterByRating" class="passportlist__info-rating-filter">
    <ng-container *ngFor="let key of ratingKeys; index as i">
      <ng-container *ngIf="isSelectAllShown && i === selectAllIndex">
        <ng-container *ngTemplateOutlet="select"></ng-container>
      </ng-container>
      <ng-container *ngIf="getItemType(key) === OBJECT">
        <ng-container *ngTemplateOutlet="object; context: {key: key}"></ng-container>
      </ng-container>
      <ng-container *ngIf="getItemType(key) === BOOLEAN">
        <ng-container *ngTemplateOutlet="boolean; context: {key: key}"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isSelectAllShown && ratingKeys.length <= selectAllIndex">
      <ng-container *ngTemplateOutlet="select"></ng-container>
    </ng-container>

    <ng-template #select>
      <div class="passportlist__info-rating-filter-row
          passportlist__info-rating-filter-row--zebra-striped
          passportlist__info-rating-filter-row-select">
        <md-checkbox #option (change)="selectOrDeselectAllRatingFilters($event)" [checked]="isAllFilterSelected">
          {{ 'select all' | translate}}
        </md-checkbox>
      </div>
    </ng-template>

    <ng-template #boolean let-key="key">
      <div class="passportlist__info-rating-filter-row passportlist__info-rating-filter-row--zebra-striped"
           [ngClass]="aliasClass[key]"
      >
        <md-checkbox [(ngModel)]="ratingFilter[key]"
                     #option
                     [aria-label]="getName(key) || key"
                     (ngModelChange)="ratingFilterChange.emit(ratingFilter)">
          <div *ngIf="getIcon(key)"
                class="passportlist__item-content-ratings-icon-{{getIcon(key)}}-colored-small"></div>
          <span *ngIf="getName(key)">{{getName(key) | translate}}</span>
        </md-checkbox>
      </div>
    </ng-template>

    <ng-template #object let-key="key">
      <div class="passportlist__info-rating-filter-row passportlist__info-rating-filter-row--zebra-striped"
           [ngClass]="aliasClass[key]">
        <md-checkbox #option
                     [(ngModel)]="ratingFilter[key].black"
                     [aria-label]="key + ' grey'"
                     (ngModelChange)="ratingFilterChange.emit(ratingFilter)">
          <div *ngIf="getIcon(key)"
               class="passportlist__item-content-ratings-icon-{{getIcon(key)}}-colored-small"></div>
          <span *ngIf="getName(key)">{{getName(key) | translate}}</span>
        </md-checkbox>
        <span class="passportlist__item-content-ratings-or">{{'or' | translate}}</span>
        <md-checkbox [(ngModel)]="ratingFilter[key].color"
                     [aria-label]="key + ' color'"
                     (ngModelChange)="ratingFilterChange.emit(ratingFilter)">
          <div *ngIf="getIcon(key)"
               class="passportlist__item-content-ratings-icon-{{getIcon(key)}}-dark-small"></div>
          <span *ngIf="getName(key)">{{getName(key) | translate}}</span>
        </md-checkbox>
      </div>
    </ng-template>
  </div>

  <div>

  </div>

  <md-input-container
    [floatPlaceholder]="'never'"
    class="passportlist__info-rating-filter-input"
    [ngClass]="{'passportlist__info-rating-filter-input--rating-applied': isRatingFilterApplied}"
    (click)="onClickShowRating()"
    (keyup)="onEnterShowRating($event)"
  >
    <input mdInput
           autocomplete="off"
           class="passportlist__no-caret"
           placeholder="{{ placeholder | translate }}"
           maxlength="0">
    <div class="passportlist__info-icons">
      <span *ngIf="isRatingFilterApplied" class="icon--rating-filter"></span>
      <span class="mat-select-arrow"></span>
    </div>
  </md-input-container>
</div>
