import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformLocation } from './transform-location.class';
import { TransformSiteJunctionService } from '../services/transform-site-junction.service';

export class TransformSiteJunction extends ActiveRecord {
  protected provider = TransformSiteJunctionService;

  public id: number;
  public transformation_id: number;
  public site_id: number;
  public site: TransformLocation = new TransformLocation();

  constructor(data: any = {}) {
    super();
    this.set(data);

    this.site = new TransformLocation({
      name: data.name,
      description: data.description,
      address: data.address,
      id: this.site_id,
    });
  }

  protected fields() {
    return [
      'id',
      'site_id',
      'transformation_id',

      'site',
    ];
  }
}


