<section class="form-group background-primary-color">
  <header class="form-group__header">
    <label class="form-group__header-title">{{ 'Offer' | translate }}</label>
    <div class="form-group__header-status">
        <span>
          {{offer.statusText | translate}}
        </span>  
    </div>
  </header>
</section>
<section class="form-column">
  <div class="form-row form-row--manager">    
    <span class="form-row-title">{{ 'Ecosystem manager' | translate }}:</span>
    <span class="form-row-name primary-color">{{ offer.creator }}</span>
  </div>
</section>
<div class="sub-header">
  <span class="sub-header-title">
    {{ 'Need information' | translate }}
  </span>
</div>
<section class="form-column">
  <div class="form-row">
    <div class="flex-column">
      <span class="form-column-title primary-color">{{ 'Need name' | translate }}</span>
      <md-input-container class="form-column-field">
        <textarea
          [rows]="setRowHeight(need.name, 34)" 
          mdInput
          [value]="need.name"
          disabled
        ></textarea>
      </md-input-container>
    </div>
    <img tabindex="1" class="form-column-eye" src="../../../../assets/svg/need-offer-eye--icon.svg" >
    <a class="form-column-link" (click)="openNeedDetailsDialog(need.id)">{{ 'see the need' | translate }}</a>    
    <div class="flex-column">
      <span class="form-column-title primary-color">{{ 'Passport name' | translate }}</span>
      <md-input-container class="form-column-field">
        <textarea
          [rows]="setRowHeight(need.passport.composite_name, 34)" 
          mdInput
          [value]="need.passport.composite_name"
          disabled
          ></textarea>
      </md-input-container>
    </div>
  </div>
</section>
<div class="sub-header">
  <span class="sub-header-title">
    {{ 'Recipient data' | translate }}
  </span>
</div>
<section class="form-column">
  <div class="form-row">
    <div class="flex-column">
      <span class="form-column-title primary-color">{{ 'Company' | translate }}</span>
      <md-input-container class="form-column-field--offer-company">
        <textarea
          [rows]="setRowHeight(need.client.name, 60)" 
          mdInput
          [value]="need.client.name"
          disabled
        ></textarea>
      </md-input-container>
    </div>    
    <div class="flex-column represantive">
      <span class="form-column-title primary-color represantive-title">{{ 'Representative' | translate }}</span>
      <p>
        {{need.client_contact.name}}<br>
        <md-icon>local_phone</md-icon>
        {{need.client_contact.phone}}<br>
        <md-icon>mail</md-icon>
        {{need.client_contact.email}}
      </p>
    </div>
  </div>
</section>
<div class="sub-header">
  <span class="sub-header-title">
    {{ 'Offer description & quantity' | translate }}
  </span>
</div>
<section class="form-column">
  <div class="form-row">
    <div class="flex-column">
      <span class="form-column-title primary-color">{{ 'Description' | translate }}</span>
      <md-input-container class="form-column-field--offer-description">
        <textarea
          [rows]="setRowHeight(offer.description, 60)" 
          mdInput
          [value]="offer.description"
          disabled
        ></textarea>
      </md-input-container>
    </div>    
    <div class="flex-column offer-conversions">
      <span class="form-column-title primary-color">
        {{ 'Quantity offered' | translate }}
      </span>
      <conversion-field
        [setToggleColorActive]="true"
        [emptyQuantityHolder]="''"
        identifier="sale-offer-offered-quantity"
        [volumeUnit]="need.passport.unit"
        [quantity]="offer.quantity"
        [conversionFactor]="need.passport.conversion"
        [isDisabled]="true">
      </conversion-field>
      <span class="form-column-title">
        {{ 'Quantity wanted' | translate }}
      </span>
      <conversion-field
        [setToggleColorActive]="true"
        identifier="sales-offer-wanted-quantity"
        [volumeUnit]="need.passport.unit"
        [quantity]="need.quantity"
        [conversionFactor]="need.passport.conversion"
        [isDisabled]="true">
      </conversion-field>
    </div>
  </div>
</section>
<div class="sub-header">
  <span class="sub-header-title">
    {{ 'Offer modalities' | translate }}
  </span>
</div>
<section class="form-column">
  <div class="form-row">
    <div class="flex-column">
      <span class="form-column-title primary-color">{{ 'Delivery date' | translate }}</span>
      <md-input-container class="form-column-field--date">
        <input
          mdInput
          date-only="true"
          [value]="datePipe.transform(this.offer.delivery_date, 'dd-MM-yyyy')"
          disabled
        />
      </md-input-container>
    </div>
    <div class="flex-column flex-column-pickup">
      <span class="form-column-title primary-color">{{ 'Delivery address' | translate }}</span>
      <md-input-container class="form-column-field--address">
        <input
          mdInput
          [value]="need.client_location.address"
          disabled
        />
      </md-input-container>
    </div>
  </div>
  <span class="form-column-title primary-color">{{ 'Price' | translate }}</span>
  <div class="form-row">
    <md-input-container class="form-column-field--price">
      <input
        mdInput
        [value]="offer.price"
        disabled
      />
    </md-input-container>
    <span class="form-column-price">€</span>
    <i
    class="form-group__label--question"
    mdTooltip="{{'ESM_COMPILER_NEED_STEP_PRICE_TOOLTIP' | translate}}"
    mdTooltipPosition="above"
    >?</i>
  </div>
  <span class="form-column-title primary-color" *ngIf="files.length > 0">{{ 'Documents' | translate }}</span>
</section>
<div class="form-group-documents" *ngIf="files.length > 0">
  <documents-uploader [files]="files"></documents-uploader>
</div>
<footer class="controls">
  <ul class="button-group__list" *ngIf="offer.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_VALIDATION">
    <li class="button-group__item ">
      <button md-raised-button (click)="reject()">{{ 'Refuse' | translate }}</button>
    </li>
    <li class="button-group__item" *ngIf="!checkStatusValidated()">
      <button md-raised-button (click)="changeVisibilityOfModificationModal()">
        {{ 'Ask for counter proposal' | translate }}
      </button>
    </li>
    <div class="small-modal" *ngIf="modificationModalIsVisible">
      <div class="small-modal__header">
        <div class="small-modal__info">
          {{ 'Message to Ecosystem Manager' | translate }}
          <span class="small-modal__data">{{offer.modification?.length || 0}}/3000</span>
        </div>
        <div class="small-modal__button">
          <button md-button md-raised-button (click)="askForModification(offer.id, offer.modification)">
            {{ 'Send' | translate }}
          </button>
        </div>
      </div>
      <div class="small-modal__body">
        <textarea [(ngModel)]="offer.modification"
                  (ngModelChange)="modificationChanged()"
                  [class.modification-field--error]="modificationIsNull"
                  placeholder="{{ 'Type your modification here' | translate }}" maxlength="3000"></textarea>
      </div>
    </div>
    <li class="button-group__item">
      <button md-raised-button (click)="checkStatusValidated() ? unvalidate() : validate()">{{checkStatusValidated() ? "Unvalidate" : "Validate" | translate}}
      </button>
    </li>
  </ul>
  <ul class="button-group__list" *ngIf="offer.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION">
    <li class="button-group__item">
      <button md-raised-button
      (click)="confirm()"
      >
      {{ 'Confirm' | translate }}
      </button>
    </li>
  </ul>
</footer>
