import {EventEmitter, Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import { ReportService } from '../../../../../dashboards/admin/services/report.service';
import {MAP_IMPORT_PROGRESS_STATUS} from './report-progress.const';

@Injectable({
  providedIn: 'root'
})
export class ReportProgressService {

  public resetProgress$ = new EventEmitter();
  public showImportProgress = false;
  public showImportProgressSubscription = new Subject();
  public statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.NOT_STARTED;
  public zipfile_link = null;

  constructor(public reportApiService: ReportService,) {
    
   }

  public setProgressModeProgressModal() {
    this.showImportProgress = true;
    this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.PROGRESS;
  }

  public setErrorModeProgressModal() {
    this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.ERROR;
  }

  public setSuccessModeProgressModal(zipfile_link) {
    this.showImportProgress = true;
    this.zipfile_link = zipfile_link;
    this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.SUCCESS;
  }

  public canProgressModalBeClosed() {
    this.statusImportProgress === MAP_IMPORT_PROGRESS_STATUS.SUCCESS;
  }

  public closeImport() {
    this.reportApiService.acknowledgeReport().subscribe();
  }

  public canGenerateReport() {
    return this.statusImportProgress !== MAP_IMPORT_PROGRESS_STATUS.PROGRESS;
  }
}
