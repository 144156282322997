export const ORDER_STATUSES: {
  view: string,
  text: string,
  value: number,
}[] = [
  {
    text: 'TO_BE_VALIDATED',
    view: 'to be validated',
    value: 1,
  },
  {
    text: 'PENDING',
    view: 'Booked',
    value: 2,
  },
  {
    text: 'VALIDATED',
    view: 'validated',
    value: 3,
  }
];
