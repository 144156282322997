import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MdDialog } from '@angular/material';
import {NeedService} from "../../../../entities/need/services/need.service";
import {EcosystemService} from "../../../../entities/ecosystem/services/ecosystem.service";
import {PassportService} from "../../../../entities/passport/services/passport.service";
import {DepositService} from "../../../../entities/deposit/services/deposit.service";
import {TransformService} from "../../../../entities/transformation/services/transform.service";
import { NomenclatureChangeDialogComponent } from '../../../../entities/nomenclature/dialogs/nomenclature-change-dialog/nomenclature-change-dialog.component';

@Component({
  moduleId: module.id,
  templateUrl: 'supervisor-dashboard.component.html',
})
export class SupervisorDashboardComponent {

  uncheckedPassports:number;
  needsWaiting:number;
  depositsWaiting:number;
  transformationsWaiting:number;


  constructor(private _router: Router,
              private _mdDialog: MdDialog,
              private _passportService: PassportService,
              private _depositService: DepositService,
              private _transformationService: TransformService,
              private _needService: NeedService,
  ) {

    this._needService.getWaiting().subscribe(c => this.needsWaiting = c);
    this._depositService.getWaiting().subscribe(c => this.depositsWaiting = c);
    this._transformationService.getWaiting().subscribe(c => this.transformationsWaiting = c);
    this._passportService.getWaiting().subscribe(c => this.uncheckedPassports = c);

  }
  navigateToCreatePassport() {
    this._router.navigate(['supervisor/passports/new']);
  }

  askForChangeInNomenclatureOpenDialog() {
    this._mdDialog.open(NomenclatureChangeDialogComponent);
  }
}
