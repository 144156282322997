import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { ERoleIds } from '../../shared/values/role-ids.enum';
import {TRANSFORM_INDEPENDENT_NAVIGATION} from './transformation-manager-independent.navigation';

export const TRANSFORM_INDEPENDENT_CONTEXT: IRoleContextConfig = {
  navigation: TRANSFORM_INDEPENDENT_NAVIGATION,
  moduleName: 'transform-independent',
  themeClass: 'b-module-transform-independent',
  roleId: ERoleIds.TRANSFORMATION_SITE_MANAGER_INDEPENDENT,
  roleText: 'Independent transformation manager',
  homeUrl: '/transform-independent',
  apiModuleName: 'TSMI',
  rootPath: 'transform-independent'
};
