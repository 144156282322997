import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { PassportSynchronizationData } from "../../../models/passport-synchronization-data.class";

@Component({
    selector: 'document-type-input-list',
    templateUrl: './document-type-input-list.component.html'
})

export class DocumentTypeInputListComponent implements OnInit {
    @Input() selectedValue: number = null;
    @Input() disabled: boolean = false;
    @Output() selectionChange = new EventEmitter<number | null>();

    epdTypes = [];
    
    ngOnInit() {
        this.epdTypes = PassportSynchronizationData.getEpdTypesKeyList();
    }

    onSelectionChange(option : string) {
        this.selectionChange.emit(option ? PassportSynchronizationData.getEpdTypeKey(option) : null);
    }

    getListEpdTypeName():string[] {
        return PassportSynchronizationData.getEpdTypesValueList();
    }

    getEpdSelectedValue(): string | null {
        return this.selectedValue != null ? PassportSynchronizationData.getEpdTypeValue(this.selectedValue) : null;
    }    
}