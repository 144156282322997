export enum ECOSYSTEM_STEP_STATUS {
  STAND_BY = 1,
  WAITING_FOR_VALIDATION = 2,
  ASK_FOR_MODIFICATION = 3,
  BOOKED = 4,
  VALIDATED = 5,
  CONFIRMED = 6,
  PROBLEM_REPORTED = 7,
  REFUSED = 8,
  DONE = 9,
  EXPIRED = 10,                     //TODO temp, this is an order status not a step
  WAITING_FOR_CONFIRMATION = 12,    //TODO probably same as ^^
}
