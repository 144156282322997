var DialogCloseButtonComponent = /** @class */ (function () {
    function DialogCloseButtonComponent() {
        this.defaultPosition = true;
    }
    Object.defineProperty(DialogCloseButtonComponent.prototype, "applyClassPosition", {
        get: function () {
            return this.defaultPosition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogCloseButtonComponent.prototype, "closeButtonClass", {
        get: function () {
            return 'icon icon--close_s' + (this.color ? '_' + this.color : '');
        },
        enumerable: true,
        configurable: true
    });
    return DialogCloseButtonComponent;
}());
export { DialogCloseButtonComponent };
