import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { AffiliateBranch } from '../models/affiliateBranch.class';
var AffiliateBranchService = /** @class */ (function (_super) {
    tslib_1.__extends(AffiliateBranchService, _super);
    function AffiliateBranchService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'affiliate-branches';
        _this.namespaceSingular = 'affiliate-branch';
        _this.ModelClass = AffiliateBranch;
        return _this;
    }
    return AffiliateBranchService;
}(CrudService));
export { AffiliateBranchService };
