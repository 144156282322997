import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { GenericPassportMaterialService } from '../service/generic-passport-material.service';
var GenericPassportMaterial = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassportMaterial, _super);
    function GenericPassportMaterial() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = GenericPassportMaterialService;
        return _this;
    }
    GenericPassportMaterial.prototype.fields = function () {
        return [
            'id',
            'nomenclature_id',
            'generic_passport_id',
            'name',
        ];
    };
    return GenericPassportMaterial;
}(ActiveRecord));
export { GenericPassportMaterial };
