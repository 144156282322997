export enum ECOSYSTEM_OFFER_STATUS {
  DRAFT = 1,
  WAITING_FOR_VALIDATION = 2,
  ASK_FOR_MODIFICATION = 3,
  VALIDATED = 4,
  CONFIRMED = 5,
  REFUSED = 6,
  CANCELLED = 7,
  WAITING_FOR_CONFIRMATION = 8,
  PROBLEM_REPORTED = 9,
  CONSIDERED = 10,
}

export const ECOSYSTEM_NEED_STEP_STATUS_LABELS: { [key: string]: string; } = {
  DRAFT: 'stand by',
  WAITING_FOR_VALIDATION: 'offer sent',
  ASK_FOR_MODIFICATION: 'ask for counter proposal',
  VALIDATED: 'validated',
  CONFIRMED: 'confirmed',
  REFUSED: 'refused',
  CANCELLED: 'canceled',
  WAITING_FOR_CONFIRMATION: 'waiting for confirmation',
  PROBLEM_REPORTED: 'Not achieved',
  CONSIDERED: 'Considered',
};

export const ECOSYSTEM_OFFER_STATUS_LABELS: { [key: string]: string; } = {
  DRAFT: 'draft',
  WAITING_FOR_VALIDATION: 'Waiting for validation',
  ASK_FOR_MODIFICATION: 'Requesting modification',
  VALIDATED: 'Booked',
  CONFIRMED: 'Confirmed',
  REFUSED: 'Refused',
  CANCELLED: 'Canceled',
  WAITING_FOR_CONFIRMATION: 'Waiting for confirmation',
  PROBLEM_REPORTED: 'Not achieved',
  CONSIDERED: 'Considered',
};

export const ECOSYSTEM_OFFER_ACTIVE_STATUS = [
  {
    value: ECOSYSTEM_OFFER_STATUS.WAITING_FOR_VALIDATION,
    text: ECOSYSTEM_OFFER_STATUS_LABELS.WAITING_FOR_VALIDATION,
    view: ECOSYSTEM_OFFER_STATUS_LABELS.WAITING_FOR_VALIDATION,
  },
  {
    value: ECOSYSTEM_OFFER_STATUS.ASK_FOR_MODIFICATION,
    text: ECOSYSTEM_OFFER_STATUS_LABELS.ASK_FOR_MODIFICATION,
    view: ECOSYSTEM_OFFER_STATUS_LABELS.ASK_FOR_MODIFICATION,
  },
  {
    value: ECOSYSTEM_OFFER_STATUS.VALIDATED,
    text: ECOSYSTEM_OFFER_STATUS_LABELS.VALIDATED,
    view: ECOSYSTEM_OFFER_STATUS_LABELS.VALIDATED,
  },
  {
    value: ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION,
    text: ECOSYSTEM_OFFER_STATUS_LABELS.WAITING_FOR_CONFIRMATION,
    view: ECOSYSTEM_OFFER_STATUS_LABELS.WAITING_FOR_CONFIRMATION,
  },
]

export const ECOSYSTEM_OFFER_ARCHIVED_STATUS = [
  {
    value: ECOSYSTEM_OFFER_STATUS.CONFIRMED,
    text: ECOSYSTEM_OFFER_STATUS_LABELS.CONFIRMED,
    view: ECOSYSTEM_OFFER_STATUS_LABELS.CONFIRMED,
  },
  {
    value: ECOSYSTEM_OFFER_STATUS.PROBLEM_REPORTED,
    text: ECOSYSTEM_OFFER_STATUS_LABELS.PROBLEM_REPORTED,
    view: ECOSYSTEM_OFFER_STATUS_LABELS.PROBLEM_REPORTED,
  },
  {
    value: ECOSYSTEM_OFFER_STATUS.REFUSED,
    text: ECOSYSTEM_OFFER_STATUS_LABELS.REFUSED,
    view: ECOSYSTEM_OFFER_STATUS_LABELS.REFUSED,
  },
]

export function GET_NEED_STEP_STATUS_LABEL(key: ECOSYSTEM_OFFER_STATUS): string {
  return ECOSYSTEM_NEED_STEP_STATUS_LABELS[ECOSYSTEM_OFFER_STATUS[key]];
}

export function GET_OFFER_STATUS_LABEL(key: ECOSYSTEM_OFFER_STATUS): string {
  return ECOSYSTEM_OFFER_STATUS_LABELS[ECOSYSTEM_OFFER_STATUS[key]];
}