<svg width="31" height="25" viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M8.85848 13.9623C8.22014 13.9628 7.59183 13.8034 7.03093 13.4986C6.47002 13.1939 5.99438 12.7535 5.64742 12.2177C5.30046 11.6819 5.09324 11.0677 5.04467 10.4312C4.9961 9.79468 5.10772 9.15616 5.36936 8.5739C5.63099 7.99164 6.0343 7.48418 6.54247 7.09786C7.05064 6.71153 7.64748 6.45864 8.27851 6.36227C8.90954 6.2659 9.55465 6.32913 10.155 6.54617C10.7553 6.76321 11.2917 7.12716 11.7152 7.60479H17.3494C16.938 6.23423 16.2008 4.98368 15.2009 3.9601C15.0547 3.81012 14.903 3.66517 14.7459 3.52525C14.5888 3.38533 14.4269 3.25085 14.2602 3.12182C12.7139 1.9269 10.8145 1.27948 8.86037 1.28125C3.97523 1.28125 0 5.25585 0 10.1416C0 15.0274 3.9746 19.002 8.86037 19.002C10.8145 19.004 12.7139 18.3568 14.2602 17.162C13.05 15.9078 12.1841 14.3624 11.7466 12.6753H11.7152C11.3574 13.08 10.9177 13.4041 10.4252 13.626C9.93271 13.8478 9.39866 13.9625 8.85848 13.9623Z"
        fill="#00C16D" />
    <path
        d="M7.61368 10.1654C7.60985 10.028 7.63386 9.89117 7.68427 9.76322C7.73468 9.63527 7.81045 9.51884 7.90701 9.42093C8.00357 9.32302 8.11894 9.24564 8.24617 9.19346C8.37341 9.14128 8.50988 9.11537 8.64739 9.11729C9.24876 9.11729 9.51457 9.50438 9.57929 9.79281L9.1589 9.9254C9.12748 9.78715 9.00054 9.55276 8.64739 9.55276C8.37906 9.55276 8.0768 9.74442 8.0768 10.1654C8.07299 10.2429 8.08516 10.3203 8.11255 10.3929C8.13994 10.4655 8.18196 10.5316 8.23601 10.5873C8.29005 10.6429 8.35497 10.6868 8.42671 10.7163C8.49845 10.7458 8.57549 10.7602 8.65304 10.7587C9.00054 10.7587 9.13879 10.5268 9.17838 10.3816L9.60506 10.5035C9.54222 10.78 9.27452 11.2036 8.65304 11.2036C8.0768 11.2036 7.61368 10.7882 7.61368 10.1642"
        fill="#0D0080" />
    <path
        d="M10.0234 11.1629V9.16016H11.2915V9.58118H10.4696V9.96262H11.2155V10.3554H10.4696V10.7393H11.2941V11.1629H10.0234Z"
        fill="#0D0080" />
    <path
        d="M12.3877 10.4169H12.2294V11.1629H11.7832V9.16016H12.5769C12.9721 9.16016 13.2266 9.43162 13.2266 9.78855C13.2308 9.91289 13.1945 10.0352 13.1233 10.1372C13.052 10.2392 12.9496 10.3153 12.8314 10.3541L13.2379 11.1647H12.7465L12.3877 10.4169ZM12.492 10.033C12.6762 10.033 12.7748 9.9312 12.7748 9.78981C12.7748 9.64842 12.6762 9.54725 12.492 9.54725H12.2294V10.033H12.492Z"
        fill="#0D0080" />
    <path d="M14.5698 9.58684V11.1629H14.1236V9.58684H13.5078V9.16016H15.1881V9.58684H14.5698Z" fill="#0D0080" />
    <path d="M15.5723 11.1629V10.1618V9.16016H15.7979H16.0241V10.1618V11.1629H15.7979H15.5723Z" fill="#0D0080" />
    <path d="M16.5703 11.1629V9.16016H17.861V9.58369H17.0165V10.0079H17.7592V10.4144H17.0196V11.1629H16.5703Z"
        fill="#0D0080" />
    <path d="M18.2871 11.1629V10.1618V9.16016H18.5133H18.7389V10.1618V11.1629H18.5133H18.2871Z" fill="#0D0080" />
    <path
        d="M19.2852 11.1629V9.16016H20.5533V9.58118H19.7313V9.96262H20.4772V10.3554H19.7313V10.7393H20.5558V11.1629H19.2852Z"
        fill="#0D0080" />
    <path
        d="M21.043 11.1629V9.16016H21.7688C22.3708 9.16016 22.7742 9.54411 22.7742 10.1656C22.7742 10.7871 22.3708 11.1654 21.7688 11.1654L21.043 11.1629ZM21.7493 10.7418C22.0572 10.7418 22.3086 10.564 22.3086 10.1631C22.3086 9.76216 22.0572 9.58118 21.7493 9.58118H21.4891V10.7418H21.7493Z"
        fill="#0D0080" />
    <path
        d="M1.57762 23.0917C1.57762 23.4059 1.78939 23.5831 2.04138 23.5831C2.13327 23.5873 2.22387 23.5603 2.29855 23.5066C2.37323 23.4529 2.42764 23.3756 2.45298 23.2872L2.98837 23.4512C2.90039 23.7987 2.573 24.1424 2.04138 24.1424C1.44943 24.1424 0.973107 23.7063 0.973107 23.0911C0.968982 22.952 0.993194 22.8136 1.04427 22.6842C1.09535 22.5548 1.17223 22.4371 1.27022 22.3384C1.36821 22.2396 1.48527 22.1618 1.61428 22.1097C1.74329 22.0576 1.88155 22.0324 2.02064 22.0354C2.56797 22.0354 2.88783 22.3716 2.9758 22.7266L2.43224 22.8906C2.41044 22.8025 2.35861 22.7248 2.28567 22.6707C2.21272 22.6167 2.12322 22.5898 2.03258 22.5947C1.78122 22.5947 1.57699 22.7706 1.57699 23.0905"
        fill="#0D0080" />
    <path
        d="M4.62301 22.6945C4.55995 22.6801 4.49539 22.6734 4.43072 22.6744C4.1869 22.6744 3.96759 22.8183 3.96759 23.2142V24.0814H3.35742V22.0944H3.94497V22.3621C4.04866 22.1378 4.30064 22.0743 4.46026 22.0743C4.51435 22.0735 4.56828 22.0802 4.6205 22.0944L4.62301 22.6945Z"
        fill="#0D0080" />
    <path
        d="M5.4749 22.9344L5.94242 22.8621C6.05051 22.8458 6.08632 22.7943 6.08632 22.7264C6.08632 22.6101 5.98641 22.5102 5.79475 22.5102C5.71116 22.5045 5.62866 22.5318 5.56505 22.5864C5.50145 22.6409 5.46185 22.7183 5.45479 22.8018L4.92882 22.695C4.9527 22.4109 5.21663 22.0352 5.8004 22.0352C6.44388 22.0352 6.68016 22.3946 6.68016 22.8024V23.7739C6.67976 23.877 6.68774 23.98 6.70404 24.0818H6.15419C6.13893 24.0083 6.13219 23.9332 6.13408 23.8581C6.0304 24.0259 5.83874 24.1378 5.57481 24.1378C5.1387 24.1378 4.88672 23.85 4.88672 23.5345C4.88672 23.1826 5.14687 22.9828 5.4749 22.935M6.08632 23.3026V23.2147L5.70929 23.2775C5.58361 23.2976 5.48558 23.3573 5.48558 23.4974C5.48558 23.6011 5.55345 23.7017 5.72123 23.7017C5.90095 23.7017 6.08507 23.6137 6.08507 23.3058"
        fill="#0D0080" />
    <path
        d="M9.13141 23.7262C9.13141 23.9059 9.14335 24.0404 9.14775 24.0819H8.56397C8.55423 24.0195 8.54877 23.9565 8.54764 23.8934C8.45589 24.0291 8.25606 24.129 8.01601 24.129C7.45674 24.129 7.0332 23.6973 7.0332 23.0859C7.0332 22.4939 7.43286 22.0503 7.99653 22.0503C8.33586 22.0503 8.48417 22.176 8.53193 22.2545V21.1875H9.13141V23.7262ZM8.09205 23.5823C8.32833 23.5823 8.54009 23.4145 8.54009 23.0865C8.54009 22.7585 8.32833 22.5951 8.09205 22.5951C7.85577 22.5951 7.64463 22.7547 7.64463 23.0865C7.64463 23.4183 7.8564 23.5823 8.09205 23.5823Z"
        fill="#0D0080" />
    <path d="M9.67969 24.0819V22.6347V21.1875H9.9832H10.2873V22.6347V24.0819H9.9832H9.67969Z" fill="#0D0080" />
    <path
        d="M12.629 23.5138C12.5316 23.8556 12.2174 24.1422 11.7147 24.1422C11.1673 24.1422 10.6797 23.75 10.6797 23.0827C10.6797 22.443 11.1554 22.0352 11.6707 22.0352C12.2865 22.0352 12.6667 22.4122 12.6667 23.0544C12.6675 23.1175 12.6633 23.1805 12.6541 23.2429H11.2716C11.2836 23.4666 11.4878 23.6269 11.7235 23.6269C11.9434 23.6269 12.0672 23.5232 12.1231 23.3674L12.629 23.5138ZM12.0772 22.8546C12.0691 22.7025 11.9654 22.5184 11.6776 22.5184C11.5811 22.513 11.4862 22.5449 11.4125 22.6075C11.3388 22.67 11.292 22.7585 11.2817 22.8546H12.0772Z"
        fill="#0D0080" />
    <path
        d="M14.2548 22.095H14.6425V22.6273H14.2548V23.3706C14.2548 23.5347 14.3428 23.5786 14.4748 23.5786C14.5285 23.5775 14.582 23.5708 14.6344 23.5585V24.0613C14.5353 24.1038 14.4278 24.1231 14.3202 24.1178C13.9079 24.1178 13.6566 23.874 13.6566 23.4781V22.6273H13.3047V22.095H13.4046C13.612 22.095 13.7125 21.9555 13.7125 21.7714V21.5156H14.2561L14.2548 22.095Z"
        fill="#0D0080" />
    <path
        d="M17.0173 23.0865C17.0208 23.2272 16.996 23.3673 16.9445 23.4983C16.893 23.6294 16.8158 23.7488 16.7175 23.8496C16.6192 23.9503 16.5017 24.0304 16.3719 24.0851C16.2421 24.1398 16.1028 24.1679 15.962 24.1679C15.8211 24.1679 15.6818 24.1398 15.552 24.0851C15.4223 24.0304 15.3047 23.9503 15.2064 23.8496C15.1081 23.7488 15.0309 23.6294 14.9794 23.4983C14.9279 23.3673 14.9031 23.2272 14.9066 23.0865C14.9066 22.4713 15.3703 22.0352 15.9616 22.0352C16.553 22.0352 17.0173 22.4713 17.0173 23.0865ZM16.4097 23.0865C16.4097 22.7509 16.1935 22.5951 15.9616 22.5951C15.7298 22.5951 15.5142 22.7509 15.5142 23.0865C15.5142 23.422 15.7342 23.5823 15.9616 23.5823C16.1891 23.5823 16.4097 23.4227 16.4097 23.0865Z"
        fill="#0D0080" />
    <path
        d="M18.2586 23.0923C18.2586 23.4065 18.471 23.5838 18.723 23.5838C18.8149 23.5879 18.9055 23.5609 18.9802 23.5072C19.0549 23.4535 19.1093 23.3762 19.1346 23.2878L19.67 23.4512C19.582 23.7987 19.254 24.1424 18.723 24.1424C18.1311 24.1424 17.6547 23.7063 17.6547 23.0911C17.6506 22.9521 17.6748 22.8137 17.7259 22.6843C17.7769 22.555 17.8537 22.4373 17.9516 22.3386C18.0496 22.2398 18.1666 22.162 18.2955 22.1099C18.4244 22.0578 18.5626 22.0324 18.7017 22.0354C19.2496 22.0354 19.5695 22.3716 19.6574 22.7266L19.1139 22.8906C19.092 22.8026 19.0401 22.7249 18.9672 22.6709C18.8943 22.6169 18.8048 22.5899 18.7142 22.5947C18.4629 22.5947 18.258 22.7706 18.258 23.0905"
        fill="#0D0080" />
    <path
        d="M21.3047 22.6945C21.2417 22.6801 21.1771 22.6734 21.1124 22.6744C20.8686 22.6744 20.6487 22.8183 20.6487 23.2142V24.0814H20.041V22.0944H20.6286V22.3621C20.7329 22.1378 20.9849 22.0743 21.1445 22.0743C21.1986 22.0735 21.2525 22.0802 21.3047 22.0944V22.6945Z"
        fill="#0D0080" />
    <path
        d="M22.1559 22.9344L22.6241 22.8621C22.7315 22.8458 22.768 22.7943 22.768 22.7264C22.768 22.6101 22.6681 22.5102 22.4758 22.5102C22.3922 22.5048 22.3099 22.5323 22.2464 22.5868C22.1829 22.6413 22.1432 22.7184 22.1358 22.8018L21.6086 22.695C21.6325 22.4109 21.8964 22.0352 22.4802 22.0352C23.1236 22.0352 23.3599 22.3946 23.3599 22.8024V23.7739C23.3599 23.877 23.3678 23.98 23.3838 24.0818H22.8371C22.8215 24.0083 22.8147 23.9332 22.817 23.8581C22.7114 24.0253 22.5229 24.1403 22.2558 24.1403C21.8203 24.1403 21.5684 23.8525 21.5684 23.537C21.5684 23.1851 21.8285 22.9853 22.1559 22.9375M22.768 23.3051V23.2172L22.3909 23.28C22.2652 23.3001 22.1666 23.3598 22.1666 23.4999C22.1666 23.6036 22.2351 23.7042 22.4029 23.7042C22.5826 23.7042 22.7667 23.6162 22.7667 23.3083"
        fill="#0D0080" />
    <path
        d="M25.8156 23.7262C25.8156 23.9059 25.8276 24.0404 25.8313 24.0819H25.2482C25.2381 24.0195 25.2327 23.9565 25.2319 23.8934C25.1401 24.0291 24.9403 24.129 24.7002 24.129C24.1403 24.129 23.7168 23.6973 23.7168 23.0859C23.7168 22.4939 24.1165 22.0503 24.6801 22.0503C25.0201 22.0503 25.1684 22.176 25.2161 22.2545V21.1875H25.8156V23.7262ZM24.7763 23.5823C25.0119 23.5823 25.2237 23.4145 25.2237 23.0865C25.2237 22.7585 25.0119 22.5951 24.7763 22.5951C24.5406 22.5951 24.3282 22.7547 24.3282 23.0865C24.3282 23.4183 24.5406 23.5823 24.7763 23.5823Z"
        fill="#0D0080" />
    <path d="M26.3633 24.0819V22.6347V21.1875H26.6668H26.9709V22.6347V24.0819H26.6668H26.3633Z" fill="#0D0080" />
    <path
        d="M29.3105 23.5139C29.2144 23.8583 28.8989 24.1461 28.395 24.1461C27.847 24.1461 27.3594 23.754 27.3594 23.0866C27.3594 22.4469 27.8351 22.0391 28.351 22.0391C28.9668 22.0391 29.3464 22.4161 29.3464 23.0583C29.347 23.1214 29.343 23.1844 29.3344 23.2468H27.952C27.9639 23.4705 28.1675 23.6308 28.4038 23.6308C28.6237 23.6308 28.7475 23.5271 28.8034 23.3713L29.3105 23.5139ZM28.7588 22.8547C28.7506 22.7026 28.647 22.5185 28.3591 22.5185C28.2626 22.5131 28.1678 22.545 28.0941 22.6076C28.0204 22.6701 27.9736 22.7586 27.9633 22.8547H28.7588Z"
        fill="#0D0080" />
    <path
        d="M18.6862 7.60277C19.2005 7.02214 19.8792 6.61172 20.6323 6.42602C21.3853 6.24033 22.1771 6.28816 22.9023 6.56315C23.6276 6.83813 24.252 7.32727 24.6926 7.96558C25.1332 8.60389 25.3692 9.36115 25.3692 10.1368C25.3692 10.9124 25.1332 11.6697 24.6926 12.308C24.252 12.9463 23.6276 13.4354 22.9023 13.7104C22.1771 13.9854 21.3853 14.0332 20.6323 13.8475C19.8792 13.6618 19.2005 13.2514 18.6862 12.6708H13.0508C13.4621 14.0426 14.1994 15.2944 15.1999 16.3192C15.3461 16.4692 15.4978 16.6142 15.6548 16.7541C15.8119 16.894 15.9741 17.0283 16.1412 17.1569C17.687 18.3523 19.5863 19 21.5404 18.9981C26.4262 18.9981 30.4008 15.0235 30.4008 10.1377C30.4008 5.25195 26.4268 1.27735 21.5404 1.27735C19.5864 1.27557 17.6872 1.923 16.1412 3.11792C17.3511 4.37114 18.2169 5.91551 18.6548 7.60151L18.6862 7.60277Z"
        fill="#0D0080" />
    <path
        d="M29.7653 1.12257C29.9186 1.12257 30.0684 1.16805 30.1959 1.25324C30.3233 1.33843 30.4226 1.45951 30.4812 1.60117C30.5398 1.74282 30.5551 1.89867 30.5251 2.049C30.4951 2.19934 30.4212 2.33739 30.3127 2.4457C30.2042 2.55401 30.066 2.6277 29.9157 2.65746C29.7653 2.68721 29.6094 2.67169 29.4679 2.61285C29.3263 2.55402 29.2054 2.45451 29.1204 2.32693C29.0354 2.19935 28.9902 2.04942 28.9905 1.89613C28.9905 1.79438 29.0105 1.69362 29.0494 1.59962C29.0884 1.50561 29.1454 1.4202 29.2174 1.34825C29.2893 1.2763 29.3748 1.21923 29.4688 1.18029C29.5628 1.14136 29.6635 1.12131 29.7653 1.12131M29.7653 0.964844C29.5811 0.964844 29.401 1.01946 29.2479 1.12179C29.0947 1.22412 28.9754 1.36957 28.9049 1.53974C28.8344 1.70991 28.8159 1.89716 28.8519 2.07781C28.8878 2.25846 28.9765 2.4244 29.1068 2.55464C29.237 2.68488 29.4029 2.77358 29.5836 2.80951C29.7642 2.84545 29.9515 2.827 30.1217 2.75652C30.2918 2.68603 30.4373 2.56667 30.5396 2.41352C30.6419 2.26037 30.6965 2.08032 30.6965 1.89613C30.6962 1.64924 30.598 1.41255 30.4234 1.23798C30.2488 1.0634 30.0122 0.965176 29.7653 0.964844Z"
        fill="#0D0080" />
    <path
        d="M30.2538 2.35892L30.0615 2.02273C30.0738 2.01825 30.0858 2.01301 30.0973 2.00702C30.1459 1.98238 30.1863 1.94418 30.2136 1.89705C30.239 1.85041 30.2522 1.79809 30.2519 1.74498C30.2529 1.6893 30.2395 1.63432 30.213 1.58536C30.1859 1.53832 30.1451 1.50062 30.0961 1.47728C30.0373 1.44927 29.9727 1.43569 29.9076 1.43769H29.3301V2.35892H29.5852V2.04786H29.8026L29.9698 2.35892H30.2538ZM29.5858 1.64569H29.8837C29.9553 1.64569 29.9918 1.679 29.9918 1.74623C29.9918 1.81347 29.9553 1.84552 29.8837 1.84552H29.5858V1.64569Z"
        fill="#0D0080" />
</svg>