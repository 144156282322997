import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { EcosystemModule } from '../../entities/ecosystem/ecosystem.module';
import { RouterModule } from '@angular/router';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';
import {AdminModule} from '../admin/admin.module';
import {SPECIALADMIN_CONTEXT} from './specialadmin.context';
import {SPECIALADMIN_ROUTES} from './specialadmin.routes';
import {SuperadminModule} from '../superadmin/superadmin.module';
import {SpecialAdminGuard} from './specialadmin.guard';
import {CompanyDeletionDialogComponent} from '../superadmin/dialogs/company-deletion-dialog.component';
import {SpecialAdminDashboardComponent} from './components/specialadmin-dashboard/specialadmin-dashboard.component';
import { SpecialAdminPassportDashboardComponent } from './dashboard/specialAdmin-passport-dashboard.component';
import { BaseComponentsModule } from '../../app/components/base/base-components.module';
import { FilterComponentsModule } from '../manufacturer/components/filter.module';
import { PassportModule } from '../../entities/passport/passport.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([{
      path: SPECIALADMIN_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: SPECIALADMIN_ROUTES
    }]),
    EcosystemModule,
    AdminModule,
    SuperadminModule,
    BaseComponentsModule,
    FilterComponentsModule,
    PassportModule
  ],
  declarations: [
    SpecialAdminDashboardComponent,
    SpecialAdminPassportDashboardComponent
  ],
  exports: [],
  providers: [
    SpecialAdminGuard,
  ],
  entryComponents: [
    CompanyDeletionDialogComponent
  ]
})
export class SpecialAdminModule {
}
