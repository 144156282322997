import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import { Component, Inject, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../../../shared/services/navigation.service';

@Component({
    moduleId: module.id,
    selector: 'app-base-dialog',
    templateUrl: './base-dialog.component.html',
    styleUrls: ['base-dialog.component.scss']
})
export class BaseDialogComponent {
    buttons: Array<Object> = [];
    @ViewChild('baseDialog') baseDialog;
    constructor(@Inject(MD_DIALOG_DATA) public data: any,
        public mdDialogRef: MdDialogRef<any>,
        protected _router: Router,
        private _navigation: NavigationService,
        private translate: TranslateService) {
        this.buttons = (data.buttons || []).concat([{
            action: this.closeModal.bind(this),
            content: "close"
        }]);
    }

    executeAction(action) {
        action();
    }

    getTranslatedText(text) {
        return this.translate.instant(text);
    }

    /**
     * Close the modal
     * Also, return "closed" as the reason to cancel any action which was supposed to be done, if necessary
     */
    closeModal() {
        this.mdDialogRef.close("closed");
    }

    /**
     * Closes the modal if a click occurs outside the modal element.
     * This provides a better user experience by allowing users to click away to close.
     */
    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        const targetElement = event.target as HTMLElement;
        if (!this.baseDialog.nativeElement.contains(targetElement)) {
            this.closeModal();
        }
    }

    /**
     * Determines the CSS class for coloring pagination based on the user's role.
     * 
     * @returns The CSS class corresponding to the user's role.
     */
    getClassColor(): string {
        if (!this._navigation || !this._navigation.getContext()) return 'role-guest';
        let role = this._navigation.getContext().roleText;
        switch (role) {
            case 'Agent':
                return 'role-agent';
            case 'Manufacturer':
                return 'role-manufacturer';
            case 'Specialadmin':
                return 'role-specialadmin';
            default:
                return '';
        }
    }
}
