<div class="user-details">
  <div class="user-details__wrapper">
    <h2 *ngIf="!isInEditMode">{{ 'Create new user' | translate }}</h2>
    <ng-container [formGroup]="userForm">
      <div
        class="user-details__group user-details__role"
        [formArrayName]=ROLES_FORM_NAME
      >
        <h3>{{'Role' | translate}}</h3>
        <div
          #roleList
          class="user-details__row user-details__role-item"
          [ngClass]="{'user-details__role-item--height-auto':
            isNextRoleItemSales(index, userForm?.get(ROLES_FORM_NAME)['controls']),
            'user-details__role-item--height-bigger':
             isNextRoleItemSales(index, userForm?.get(ROLES_FORM_NAME)['controls'], 2)
            }"
          *ngFor="let selectedRole of userForm?.get(ROLES_FORM_NAME)['controls']|| []; let index = index"
          [formGroupName]="index + ''"
        >
          <span>
            <i class="icon icon--gray-person"></i>
            <md-select
              placeholder="{{ 'User role' | translate }}"
              [formControlName]=ROLE_FORM_NAME
            >
              <md-option
                *ngFor="let role of USER_ROLES"
                [value]="role.value"
              >{{ role.view | translate }}
              </md-option>
            </md-select>
          </span>
          <span class="user-details__role-item-parent">
            <i class="icon icon--gray-person"></i>
            <md-select *ngIf="parentModule != moduleNames.SPECIAL_ADMIN"
              placeholder="{{getViewForRoleParent(index) | translate}} *"
              [formControlName]=ROLE_PARENT_FORM_NAME
              [disabled]="parentModule === moduleNames.SPECIAL_ADMIN"
            >
              <md-option
                *ngFor="let user of getUsersForRoleParent(index)"
                [value]="user.id"
              >
                {{ getParentRoleWithUsernameText(user) }}
              </md-option>
            </md-select>
            <md-hint
              *ngIf="showParentUserErrorHint(index)"
              class="mdselect-error-hint"
              align="start">{{'Please select a parent role' | translate }}
            </md-hint>
          </span>
          <span *ngIf="selectedRole.value[this.ROLE_FORM_NAME] === ROLE_IDS.SALES_MANAGER; else delimiter"
                class="custom-selection-list"
          >
            <span class="user-details__role-item-sites-header">
              <i class="form-group__label--question"
                 mdTooltip="{{'ADMIN_USER_CREATION_SALES_SITE_LIST_TOOLTIP' | translate}}">?</i>
            </span>
            <custom-selection-list-with-chip
              #sites
              [allVariants]="allLocationsRecords"
              [chosenItems]="salesSitesRecords"
              floatPlaceholder="always"
              placeholder="{{'Consultable deposits' | translate}}"
              (onChange)="changeSiteList($event)"
              (onDelete)="changeSiteList({check: false, value: $event})"
              [isItemChecked]="isSiteListItemCheck"
              [listOpenMode]="LIST_OPEN_MODE.CONTAINER_CLICK"
            >
            </custom-selection-list-with-chip>
          </span>
          <ng-template #delimiter>
            <div [ngClass]="delimiterClassName"></div>
          </ng-template>
          <span>
            <button (click)="removeRole(selectedRole)"
                    class="remove-button user-details__role-item-remove">
              <md-icon>remove</md-icon>
            </button>
          </span>

        </div>
        <button class="user-details__add-role-button text"

                (click)="addRole()">
          + {{ 'Add more roles' | translate }}
        </button>
      </div>

      <div class="group flex-column">
        <h3>{{ 'Contact details' | translate }}</h3>
        <div class="flex">
          <div class="group">
            <div class="user-details__row">
              <md-input-container>
                <input
                  mdInput
                  formControlName="first_name"

                       autocomplete="new-password"

                  placeholder="{{ 'First name' | translate }} *"
                >
                <md-hint
                  *ngIf="isControlInvalid(['first_name'])"
                  class="error-hint"
                  align="start">
                  {{'First name' | translate }} {{ getControlErrorMessage(['first_name']) | async }}
                </md-hint>
              </md-input-container>
              <md-input-container>
                <input mdInput
                       formControlName="last_name"
                       autocomplete="new-password"
                       placeholder="{{ 'Last name' | translate }} *"
                       >
                <md-hint
                  *ngIf="isControlInvalid(['last_name'])"
                  class="error-hint"
                  align="start">
                  {{'Last name' | translate }} {{ getControlErrorMessage(['last_name']) | async }}
                </md-hint>
              </md-input-container>
            </div>

            <div class="user-details__row">
              <md-input-container>
                <input mdInput

                       autocomplete="new-password"
                       formControlName="phone"
                       placeholder="{{ 'Phone' | translate }}"
                >
              </md-input-container>
              <md-input-container>
                <input mdInput formControlName="email"

                       autocomplete="new-password"
                       placeholder="{{ 'Email' | translate }} *"
                       email
                >
                <md-hint
                  *ngIf="isControlInvalid(['email'])"
                  class="error-hint"
                  align="start">{{ 'Email' | translate }} {{ getControlErrorMessage(['email']) | async }}
                </md-hint>
              </md-input-container>
            </div>

            <div class="user-details__row">
              <md-input-container>

                  <input mdInput

                  autocomplete="new-password"
                         formControlName="company_name"
                         placeholder="{{ 'Organisation name' | translate }}{{isInEditMode ? '' : ' *'}}"
                         [(ngModel)]="user.company_name"
                         (ngModelChange)="onCompanyChange($event)"
                  >
                <md-hint
                    *ngIf="isControlInvalid(['company_name'])"
                    class="suggestible-text-error-hint"
                    align="start">
                    {{'Organisation name' | translate }} {{ getControlErrorMessage(['company_name']) | async }}
                </md-hint>
              </md-input-container>

              <md-input-container>
                <input mdInput


                       autocomplete="new-password"
                       formControlName="SIRET"
                       placeholder="{{ 'SIRET' | translate }}"
                >
              </md-input-container>
            </div>

            <div class="user-details__row">
              <md-input-container class="wide">
                <input mdInput


                       autocomplete="new-password"
                      formControlName="description"
                      placeholder="{{ 'Description' | translate }}"
                >
              </md-input-container>
            </div>
          </div>
          <div class="group-avatar-control">
            <div class="user-details__profile-img-control">
              <avatar-uploader
                      #avatarUploader
                      [(preview)]="user.avatar_url"
                      [userId]="user.id"
              ></avatar-uploader>
            </div>
            <language-selector
              [autosetLanguage]="false"
              [(language)]="user.language"
              showPlaceHolder="'always'"
            ></language-selector>
          </div>
        </div>
      </div>

      <div class="user-details__group">
        <h3>{{ 'Contact credentials' | translate }}</h3>

        <div class="user-details__row">
          <md-input-container>
            <input mdInput
                   formControlName="username"

                       autocomplete="new-password"
                   placeholder="{{ 'Username' | translate }} *"
            >
            <md-hint
              *ngIf="isControlInvalid(['username'])"
              class="error-hint"
              align="start">
              {{'Username' | translate }} {{ getControlErrorMessage(['username']) | async }}
            </md-hint>
          </md-input-container>
          <md-checkbox
            *ngIf="!isInEditMode"
            formControlName="emailAsUsername"
          >
            {{ 'Use Email as Username' | translate }}
          </md-checkbox>
        </div>
        <div class="user-details__row" formArrayName="password" *ngIf="isPasswordFieldsVisible()">
          <md-input-container>
            <input mdInput type="password"
                   placeholder="{{ 'Password' | translate }}"
                   autocomplete="new-password"
                   formControlName="0"
            >
            <md-hint
              *ngIf="isControlInvalid(['password','0'])"
              class="error-hint"
              align="start">{{ 'Password' | translate }} {{ getControlErrorMessage(['password','0']) | async }}
            </md-hint>
          </md-input-container>
          <md-input-container
                  [class.ng-valid]="_passwordFormArray.valid"
                  [class.ng-invalid]="!_passwordFormArray.valid"
          >
            <input
                    mdInput
                    type="password"
                    placeholder="{{ 'Repeat password' | translate }}"
                    formControlName="1"
            >
            <md-hint
              *ngIf="isControlInvalid(['password'])"
              class="error-hint"
              align="start">{{ 'Password is not confirmed' | translate }}
            </md-hint>
          </md-input-container>
        </div>
        <button
          class="user-details__delete-button text"
          *ngIf="user.status==0"
          (click)="resendActivation()"
        >{{ 'Resend activation email' | translate }}
        </button>
      </div>
    </ng-container>

    <div class="user-details__row--actions">
      <button
              class="user-details__delete-button text"
              (click)="deleteUser()"
              *ngIf="isInEditMode"
      >{{ 'Delete permanently' | translate }}
      </button>
      <button
              class="user-details__save-button"
              (click)="saveChanges()"
              [disabled]="false"
      >{{ 'Save' | translate }}
      </button>
      <button
              class="user-details__login-as-this-user-button text"
              *ngIf="isInEditMode"
              (click)="loginAsUser()"
      >{{ 'Login as this user' | translate }}
      </button>
    </div>
  </div>
</div>
