/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ecosystem-deletion.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i4 from "@angular/material";
import * as i5 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i6 from "../../../../shared/read-only/read-only.service";
import * as i7 from "@angular/common";
import * as i8 from "./ecosystem-deletion.component";
var styles_EcosystemDeletionDialogComponent = [i0.styles];
var RenderType_EcosystemDeletionDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EcosystemDeletionDialogComponent, data: {} });
export { RenderType_EcosystemDeletionDialogComponent as RenderType_EcosystemDeletionDialogComponent };
function View_EcosystemDeletionDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ecosystem-deletion-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Are you sure you want to delete this ecosystem ?")); _ck(_v, 2, 0, currVal_0); }); }
function View_EcosystemDeletionDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ecosystem-deletion-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Your ecosystem has been successfully deleted")); _ck(_v, 2, 0, currVal_0); }); }
function View_EcosystemDeletionDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i1.ɵdid(1, 16384, null, 0, i4.MdPrefixRejector, [[2, i4.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(2, 180224, null, 0, i4.MdButton, [i1.ElementRef, i1.Renderer, i4.FocusOriginMonitor], null, null), i1.ɵdid(3, 16384, null, 0, i4.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(4, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null), (_l()(), i1.ɵted(5, 0, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = true; var currVal_2 = ((i1.ɵnov(_v, 4).readOnlyService == null) ? null : i1.ɵnov(_v, 4).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("Ok")); _ck(_v, 5, 0, currVal_3); }); }
function View_EcosystemDeletionDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i1.ɵdid(1, 16384, null, 0, i4.MdPrefixRejector, [[2, i4.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(2, 180224, null, 0, i4.MdButton, [i1.ElementRef, i1.Renderer, i4.FocusOriginMonitor], null, null), i1.ɵdid(3, 16384, null, 0, i4.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(4, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null), (_l()(), i1.ɵted(5, 0, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = true; var currVal_2 = ((i1.ɵnov(_v, 4).readOnlyService == null) ? null : i1.ɵnov(_v, 4).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("Cancel")); _ck(_v, 5, 0, currVal_3); }); }
function View_EcosystemDeletionDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToIndex() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i1.ɵdid(1, 16384, null, 0, i4.MdPrefixRejector, [[2, i4.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(2, 180224, null, 0, i4.MdButton, [i1.ElementRef, i1.Renderer, i4.FocusOriginMonitor], null, null), i1.ɵdid(3, 16384, null, 0, i4.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(4, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null), (_l()(), i1.ɵted(5, 0, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = true; var currVal_2 = ((i1.ɵnov(_v, 4).readOnlyService == null) ? null : i1.ɵnov(_v, 4).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("Ok")); _ck(_v, 5, 0, currVal_3); }); }
export function View_EcosystemDeletionDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemDeletionDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemDeletionDialogComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "ul", [["class", "ecosystem-deletion-dialog-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemDeletionDialogComponent_3)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemDeletionDialogComponent_4)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemDeletionDialogComponent_5)), i1.ɵdid(13, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.deleteConfirmed; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.deleteConfirmed; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.deleteConfirmed; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.deleteConfirmed; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.deleteConfirmed; _ck(_v, 13, 0, currVal_4); }, null); }
export function View_EcosystemDeletionDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ecosystem-deletion", [], null, null, null, View_EcosystemDeletionDialogComponent_0, RenderType_EcosystemDeletionDialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.EcosystemDeletionDialogComponent, [i4.MdDialogRef, i4.MD_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EcosystemDeletionDialogComponentNgFactory = i1.ɵccf("ecosystem-deletion", i8.EcosystemDeletionDialogComponent, View_EcosystemDeletionDialogComponent_Host_0, {}, {}, []);
export { EcosystemDeletionDialogComponentNgFactory as EcosystemDeletionDialogComponentNgFactory };
