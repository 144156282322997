import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, Renderer } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdDialog } from '@angular/material';
import { Passport } from '../../models/passport.class';
import 'rxjs/add/operator/switchMap';
import { PASSPORT_UNITS, PASSPORT_UNITS_TYPE } from '../../values/passport-units.const';
import { PassportService } from '../../services/passport.service';
import { PassportFileService } from '../../services/passport-file.service';
import { PassportFile } from '../../models/passport-file.class';
import { CopyPassportDialogComponent } from '../../dialogs/copy-passport-dialog/copy-passport-dialog.component';
import { PassportFormatService } from '../../services/format.service';
import { PassportControlStates } from '../../models/passport-control-states.class';
import { PASSPORT_STATUS, PASSPORT_STATUS_CONST } from '../../values/passport-status.const';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import { PERIOD_OF_USE_TYPES } from '../../values/period-of-use.values';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../shared/services/user.service';
import { User } from '../../../../shared/models/user.class';
import { CurrentUser } from '../../../../shared/auth/current-user.class';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { ActivatedRoute, Router } from '@angular/router';
import { PASSPORT_TAB, PASSPORT_TAB_TYPE, PASSPORT_TAB_TYPE_NUMERIC } from '../../values/passport-tab.const';
import { COMPOSITION_RADIO, COMPOSITION_RADIOS } from '../../values/composition-radios.const';
import { PERFORMANCE_RADIOS } from '../../values/performance-radios.const';
import { DEFAULT_RADIO_VALUES } from '../../values/default-radio-values.const';
import { PASSPORT_CERTIFICATION_LEVELS } from '../../values/passport-certification-levels.const';
import { ConfirmC2cDialogComponent } from '../../dialogs/confirm-c2c-dialog/confirm-c2c-dialog.component';
import { NameGenerationDialogComponent } from '../../dialogs/name-generation-dialog/name-generation-dialog.component';
import { Manufacturers } from '../../models/manufacturers.class';
import { ManufacturersService } from '../../services/manufacturers.service';
import { ColorsService } from '../../services/colors.service';
import { Colors } from '../../models/colors.class';
import { ViewCategoryDialogComponent } from '../../dialogs/view-category/view-category.component';
import { SpinnerDialogComponent } from '../../../../shared/spinner/dialogs/spinner-dialog/spinner-dialog.component';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { DistributionAreaService } from '../../services/distribution-area.service';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { NewPassportVersionDialog } from '../../dialogs/new-passport-version-dialog/new-passport-version-dialog.component';
import { Location } from '@angular/common';
import { PASSPORT_CONTROLS_WITH_VALIDATION, PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION } from '../../values/passport-validation-fields.const';
import { PassportGtinBufferService } from '../../services/passport-gtin-buffer.service';
import { PassportGroupBuilderService } from '../../services/passport-group-builder/passport-group-builder.service';
import { PassportCustomStoreService } from '../../services/passport-custom-store.service';
import { NewPassportPublicationComponent } from '../../dialogs/new-passport-publication-dialog/new-passport-publication.component';
var EditPassportV2Component = /** @class */ (function () {
    function EditPassportV2Component(passportStore, dialog, _fb, gtinBuffer, _userService, passportService, distributionAreaService, passportFileService, manufacturersService, colorsService, passportFormatService, readOnlyService, navigationService, router, renderer, translate, cd, notification, _changeDetectionRef, filterState, route, location, passportFormBuilder) {
        this.passportStore = passportStore;
        this.dialog = dialog;
        this._fb = _fb;
        this.gtinBuffer = gtinBuffer;
        this._userService = _userService;
        this.passportService = passportService;
        this.distributionAreaService = distributionAreaService;
        this.passportFileService = passportFileService;
        this.manufacturersService = manufacturersService;
        this.colorsService = colorsService;
        this.passportFormatService = passportFormatService;
        this.readOnlyService = readOnlyService;
        this.navigationService = navigationService;
        this.router = router;
        this.renderer = renderer;
        this.translate = translate;
        this.cd = cd;
        this.notification = notification;
        this._changeDetectionRef = _changeDetectionRef;
        this.filterState = filterState;
        this.route = route;
        this.location = location;
        this.passportFormBuilder = passportFormBuilder;
        this.passport = new Passport;
        this.asDialog = false;
        this.disabled = false;
        this.enableSearch = true;
        this.allowNameChange = true;
        this.viewMode = 1;
        this.askForRatingMode = false;
        this.controlStates = new PassportControlStates;
        this.lockPublished = false;
        this.spinnerEnabled = true;
        this.onChange = new EventEmitter();
        this.passportChange = new EventEmitter();
        this.isUploading = new EventEmitter();
        /**
         * Replaced by onSaveComplete
         * @deprecated
         */
        this.onSave = new EventEmitter();
        this.onSaveComplete = new EventEmitter();
        this.onNextTabClick = new EventEmitter();
        this.onEditabilityChanged = new EventEmitter();
        this.units = PASSPORT_UNITS;
        this.period_types = PERIOD_OF_USE_TYPES;
        this.requestsSubject = new BehaviorSubject([]);
        this.eventStream$ = new EventEmitter();
        this.uploadedfiles = [];
        this.onUpdateUploadUrl$ = new EventEmitter();
        this.formatSuggestions = [];
        this.allCategories = [];
        this.filteredCategories = [];
        this.areFormControlsDisabled = true;
        this.supervisorWarning = false;
        this.commodityPrice = 0;
        this.VIEW_MODE = VIEW_MODE;
        this.PASSPORT_TAB = PASSPORT_TAB;
        this.moduleNames = ModuleNames;
        this.passportStatuses = PASSPORT_STATUS_CONST;
        this.compositionRadios = COMPOSITION_RADIOS;
        this.performanceRadios = PERFORMANCE_RADIOS;
        this.defaultRadioValues = DEFAULT_RADIO_VALUES;
        this.certificationLevels = PASSPORT_CERTIFICATION_LEVELS;
        this.passportType = 'MANUFACTURER';
        this.destroyAction = function () {
            // this.savePassport(this.passportForm);
        };
        this.saveGtinsInBuffer = this.saveGtinsInBuffer.bind(this);
    }
    Object.defineProperty(EditPassportV2Component.prototype, "setIsLongVersionActive", {
        set: function (isShown) {
            this.isLongVersionActive = isShown;
            if (this.passportForm && this.passportFormBuilder.getVisibilityLongControl(this.passportForm)) {
                this.passportFormBuilder.getVisibilityLongControl(this.passportForm).setValue(isShown);
            }
        },
        enumerable: true,
        configurable: true
    });
    EditPassportV2Component.prototype.isVisible = function (name) {
        return this.passportFormBuilder.getVisibility(this.passportForm, Array.isArray(name) ? name : [name]);
    };
    EditPassportV2Component.prototype.isLocked = function () {
        return this.lockPublished && (this.passport.status === PASSPORT_STATUS.PUBLISHED);
    };
    EditPassportV2Component.prototype.openBannedComponentsList = function () {
        this.passportService.openBannedComponentsPdf(this.translate.currentLang);
    };
    EditPassportV2Component.prototype.getActiveType = function () {
        if (this.selectedTab === PASSPORT_TAB.INFO) {
            return null;
        }
        return (PASSPORT_TAB_TYPE[this.selectedTab.toUpperCase()]);
    };
    EditPassportV2Component.prototype.getUploadFiles = function () {
        var _this = this;
        return this.uploadedfiles.filter(function (file) { return file.type == _this.getActiveType(); });
    };
    EditPassportV2Component.prototype.onLogoUpload = function (response) {
        var data = null;
        try {
            data = typeof response === 'string' ? JSON.parse(response) : response;
        }
        catch (e) {
            console.log(e);
        }
        if (data) {
            this.passport.set({ image: data.image });
            this.passport.set({ thumbnail: data.thumbnail });
        }
    };
    EditPassportV2Component.prototype.getLogoUploaderUrl = function () {
        return this.passportService.getLogoUploadUrl(this.passport.id);
    };
    EditPassportV2Component.prototype.canEditDocuments = function () {
        return this.navigationService.getContext().roleId === ERoleIds.SUPERVISOR
            || this.createdPassportId > 0;
    };
    EditPassportV2Component.prototype.ngAfterViewInit = function () {
        this.cd.detectChanges();
    };
    EditPassportV2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.offerNewVersion = true;
        this.passportType = this.route.snapshot.paramMap.get('type') || 'MANUFACTURER';
        this.isPublicAccess = (ModuleNames.GUEST == this.parentModule);
        this.isPassportChanged = true;
        this.onChange.subscribe(function (passport) {
            _this.passportChange.emit(passport);
            if (_this.isPassportChanged) {
                _this.isPassportChanged = false;
                _this.eventStream$.emit({ key: 'changePassport', data: passport });
            }
        });
        this.createForm();
        this.passportStore.setForm(this.passportForm);
        this.gtinBuffer.setRecordTransform(this.gtinBuffer.gtinMultiSaveTransform);
        this.gtinBuffer.setNotifier(this.eventStream$, this.saveGtinsInBuffer);
        if (this.passport && this.passport.id > 0) {
            this.setPassport(this.passport);
            if (this.passport.supervisor_warning) {
                // get count
                this.supervisorWarning = false;
            }
            else {
                this.supervisorWarning = false;
            }
        }
        else {
            this.disableFormControls();
        }
        if (this.listener) {
            this.listener.subscribe(function (event) {
                if (event.key === 'changePassport') {
                    _this.setPassport(event.data);
                }
                else if (event.key === 'updatePassport') {
                    _this.updatePassport(_this.passportForm, event.data ? !!event.data.redirection : true);
                }
                else if (event.key === 'publishPassport') {
                    _this.publishPassport(_this.passportForm);
                }
                else if (event.key === 'validatePassport') {
                    _this.validatePassport();
                }
                else if (event.key === 'askForRating') {
                    _this.askForRating(_this.passportForm);
                }
                else if (!!_this.passport.id) {
                    _this.savePassport(_this.passportForm);
                }
            });
        }
        if (this.parentFormGroup) {
            this.parentFormGroup.setControl('passport', this.passportForm);
        }
        if (this.isNewVersionCreationAllowed() && this.offerNewVersion) {
            this.passportStore.formChangedByUserAction.subscribe(function () {
                if (_this.offerNewVersion) {
                    _this.openPopup();
                    _this.offerNewVersion = false;
                }
            });
        }
        /* this.passportService.getCategoriesList().subscribe((categories: string[]) => {
           let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
           this.allCategories = categories.sort(collator.compare);
           this.onCategoryChange(this.passport.category);
         });*/
        this.translate.get('Cant upload without passport created').subscribe(function (text) {
            _this.uploadErrorText = text;
        });
        this.requestsSubject
            .skip(1)
            .debounceTime(100)
            .subscribe(function () { return _this.subscribeOnRequests(); });
    };
    EditPassportV2Component.prototype.ngOnDestroy = function () {
        if (this.destroyAction !== null) {
            this.destroyAction();
        }
        this.passportStore.unSubscribeStore();
        this.passportFormBuilder.unsubscribe(this.passportForm);
        this.gtinBuffer.unsubcribe();
    };
    EditPassportV2Component.prototype.saveGtinsInBuffer = function (e, observable) {
        if (e.key === 'savePassport') {
            this.onUpdatePassportData({ request: observable, type: 'gtins save' });
        }
    };
    EditPassportV2Component.prototype.onAfterRemove = function ($event) {
        var passportFile = $event;
        this.uploadedfiles = this.uploadedfiles.filter(function (e) { return e !== passportFile; });
    };
    EditPassportV2Component.prototype.onItemUploaded = function ($event) {
        var passportFile = $event;
        this.uploadedfiles = this.uploadedfiles.concat(passportFile);
    };
    EditPassportV2Component.prototype.onControlClick = function ($event) {
        if (!this.disabled) {
            if (this.isLocked()) {
                $event.preventDefault();
                $event.stopPropagation();
                this.renderer.invokeElementMethod($event.target, 'blur', []);
                this.onLockedPassportInteract($event);
            }
        }
    };
    EditPassportV2Component.prototype.onLockedPassportInteract = function ($event) {
        var _this = this;
        var dialogRef = this.dialog.open(CopyPassportDialogComponent, {
            data: {
                name: this.passport.name,
                parentModule: this.parentModule
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if ($event && !result) {
                _this.renderer.invokeElementMethod($event.target, 'blur', []);
            }
            if (result) {
                _this.passportService.duplicate(_this.passport.id, {
                    name: result,
                    family: _this.passport.getPassportFamilyBaseOnModule(_this.parentModule)
                }, true).subscribe(function (response) {
                    _this.setPassport(response);
                    _this.enableFormControls();
                }, function (err) { return console.log(err); });
            }
            else {
                _this.disableFormControls();
            }
        });
    };
    EditPassportV2Component.prototype.getPassportCustomName = function (record) {
        if (record) {
            return record.getProductName();
        }
    };
    EditPassportV2Component.prototype.disableFormControls = function () {
        var _this = this;
        var ratingFields = [
            'ratingComposition', 'ratingRecyclability', 'ratingSocialFairness', 'ratingEnergy', 'ratingWaterStewardship'
        ];
        Object.keys(this.passportForm.controls)
            .filter(function (e) { return e !== ratingFields.find(function (i) { return e === i; }); })
            .forEach(function (key) { return _this.passportForm.controls[key].disable(); });
        this.eventStream$.emit({ key: 'disableStatus', data: { isDisabled: true } });
        this.areFormControlsDisabled = true;
        this.onFormControlsDisabledChanged(this.areFormControlsDisabled);
    };
    EditPassportV2Component.prototype.enableFormControls = function () {
        var _this = this;
        Object.keys(this.passportForm.controls).forEach(function (key) { return _this.passportForm.controls[key].enable(); });
        this.eventStream$.emit({ key: 'disableStatus', data: { isDisabled: false } });
        this.areFormControlsDisabled = false;
        this.onFormControlsDisabledChanged(this.areFormControlsDisabled);
    };
    /**
     * Event triggered when the form controls disability is changed
     * @param {boolean} state - true if the controls are disabled, false else
     */
    EditPassportV2Component.prototype.onFormControlsDisabledChanged = function (state) {
        this.onEditabilityChanged.emit(!state);
    };
    EditPassportV2Component.prototype.createForm = function () {
        var _a;
        this.passportForm = this.passportFormBuilder.buildGroupLong(this.passport)
            .omitMultiControls(PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION.long)
            .addControlOptions((_a = {
                passportName: [this.passport.name, Validators.required],
                passportEnglishName: [this.passport.english_name, Validators.required],
                quantity: [this.passport.period, [Validators.pattern(/^\d+$/), Validators.min(0)]],
                category: ['', Validators.required],
                passportUnit: [this.passport.unit, Validators.required],
                recyclePercentage: [this.passport.recycle_percentage, [Validators.min(0), Validators.max(100)]],
                conversion: [this.passport.conversion, [Validators.min(0)]],
                dimensionLength: [this.passport.dimension_length, [Validators.min(0)]],
                dimensionWidth: [this.passport.dimension_width, [Validators.min(0)]],
                dimensionHeight: [this.passport.dimension_height, [Validators.min(0)]],
                purchasePrice: [this.passport.purchase_price, [Validators.min(0)]]
            },
            _a[PASSPORT_CONTROLS_WITH_VALIDATION.SOCIAL_PERFORMANCE] = [],
            _a)).build();
        // this.passportForm.get('category').valueChanges.subscribe((value) => this.onCategoryChange(value));
        // this.passportForm.statusChanges.subscribe((value) => {
        //   if (this.passportForm.dirty) {
        //     this.commodityPrice = this.calculatePrice().toFixed(2);
        //   }
        // });
    };
    EditPassportV2Component.prototype.unitChange = function (emitEvent) {
        if (emitEvent === void 0) { emitEvent = false; }
        if (this.isPassportConversionShown()) {
            this.passportForm.controls['conversion'].setValidators([Validators.required, Validators.min(0)]);
        }
        else {
            this.passportForm.controls['conversion'].setValidators([Validators.min(0)]);
        }
        this.passportForm.controls['conversion'].updateValueAndValidity({ emitEvent: false });
        this._changeDetectionRef.detectChanges();
    };
    EditPassportV2Component.prototype.onSelect = function (passport) {
        var _this = this;
        this.setPassport(passport);
        if (this.passport.supervisor_warning) {
            // get count
            this.supervisorWarning = false;
        }
        else {
            this.supervisorWarning = false;
        }
        if (this.createdPassportId > 0) {
            this.passportService.remove(this.createdPassportId).subscribe(function () {
                _this.createdPassportId = -1;
                _this.readOnlyService.readOnly = !_this.canEditDocuments();
            });
        }
    };
    EditPassportV2Component.prototype.setCategory = function (emitEvent) {
        if (emitEvent === void 0) { emitEvent = false; }
        var categoryName = !!this.passport.category && !!this.passport.category.name && this.passport.category.name;
        var translation = !!categoryName ? this.translate.instant(categoryName) : '';
        this.passportForm.controls['category'].setValue(translation, { emitEvent: emitEvent });
    };
    EditPassportV2Component.prototype.setPassport = function (passport) {
        var _this = this;
        this.onChange.emit(passport);
        this.passport = passport;
        this.passportStore.setModel(this.passport);
        this.passportFormBuilder.changePassport(this.passportForm, passport);
        this.unitChange();
        this.passportStore.changeModelProgramaticly.emit(this.passport);
        this.onUpdateUploadUrl$.emit(this.passport.getFileUploadUrl());
        // this.readOnlyService.readOnly = !this.canEditDocuments();
        this.passportForm.controls['passportName'].setValue(passport.name, { emitEvent: false });
        this.passportForm.controls['passportEnglishName'].setValue(passport.english_name, { emitEvent: false });
        if (this.passport.id) {
            this.setCategory();
        }
        // this.commodityPrice = this.passport.price;
        // this.commodityPrice = !!this.commodityPrice ? this.commodityPrice.toFixed(2) : 0;
        if (!!this.passportSyncSubscription) {
            this.passportSyncSubscription.unsubscribe();
        }
        if (this.passport && !this.passport.materials) {
            this.passport.materials = [];
        }
        if (this.passport && !this.passport.components) {
            this.passport.components = [];
        }
        this.gtinBuffer.setOwnerObject(this.passport);
        // this.passportSyncSubscription = this.passport.onSyncComponents$.subscribe(() => {
        //   if (!this.commodityPrice) {
        //     this.commodityPrice = this.calculatePrice().toFixed(2);
        //   }
        //
        //   this.passportSyncSubscription.unsubscribe();
        // });
        if (this.isLocked() || this.disabled) {
            this.disableFormControls();
        }
        else {
            this.enableFormControls();
        }
        this.preloadUploadedItems();
        this.preloadManufacturers();
        this.preloadColors();
        this.preloadDistributionAreas();
        this
            .passportFormatService
            .get()
            .subscribe(function (res) { return _this.formatSuggestions = res; });
    };
    EditPassportV2Component.prototype.onNameEdit = function (name) {
        this.passport.name = name;
    };
    EditPassportV2Component.prototype.onCreate = function (passport) {
        var _this = this;
        this.passport = new Passport(tslib_1.__assign({}, passport, { unit: 0 }));
        this.passport.materials = [];
        this.passport.components = [];
        this.uploadedfiles = [];
        this.passport.manufacturers = [];
        this.passport.colors = [];
        if (!this.passport.id)
            this.passport.category_id = null;
        this.passport.category = null;
        this.passportService.createPassport(this.passport, this.parentModule, this.passportType).subscribe(function (result) {
            var id = result.id, name = result.name, family = result.family, english_name = result.english_name;
            _this.createdPassportId = id;
            _this.passport = Object.assign(_this.passport, {
                id: id, name: name, english_name: english_name, family: family, user: new User(tslib_1.__assign({}, CurrentUser.infoData))
            });
            _this.setPassport(_this.passport);
            _this.enableFormControls();
            // this.validatePassport();
        });
    };
    EditPassportV2Component.prototype.preloadColors = function () {
        var _this = this;
        if (!this.passport.id) {
            return;
        }
        this.passport.colors = [];
        this.colorsService
            .get({ passport_id: this.passport.id })
            .subscribe(function (results) {
            for (var _i = 0, results_1 = results; _i < results_1.length; _i++) {
                var item = results_1[_i];
                _this.passport.colors.push(new Colors(item));
            }
        });
    };
    EditPassportV2Component.prototype.preloadDistributionAreas = function () {
        var _this = this;
        if (!this.passport.id) {
            return;
        }
        this.passportService.view(this.passport.id, { expand: 'distribution_areas.country,category,gtins,passport_synchronization_data' })
            .subscribe(function (results) {
            _this.passport.distribution_areas = results.distribution_areas;
            _this.passport.gtins = results.gtins;
            _this.passport.category = _this.passport.category || results.category;
            _this.passportStore.changeModelProgramaticly.emit(_this.passport);
            _this.setCategory();
        });
    };
    EditPassportV2Component.prototype.preloadManufacturers = function () {
        var _this = this;
        if (!this.passport.id) {
            return;
        }
        this.passport.manufacturers = [];
        this.manufacturersService
            .get({ passport_id: this.passport.id })
            .subscribe(function (results) {
            for (var _i = 0, results_2 = results; _i < results_2.length; _i++) {
                var item = results_2[_i];
                _this.passport.manufacturers.push(new Manufacturers(item));
                _this.passportStore.changeModelProgramaticly.emit(_this.passport);
            }
        });
    };
    EditPassportV2Component.prototype.preloadUploadedItems = function () {
        var _this = this;
        if (!this.passport.id) {
            return;
        }
        this.uploadedfiles = [];
        this.passportFileService
            .get({ passport_id: this.passport.id })
            .subscribe(function (passportFiles) {
            for (var _i = 0, passportFiles_1 = passportFiles; _i < passportFiles_1.length; _i++) {
                var file = passportFiles_1[_i];
                _this.uploadedfiles.push(new PassportFile(file));
            }
        });
    };
    EditPassportV2Component.prototype.handleUploadStatus = function (status) {
        this.isUploading.emit(status);
    };
    EditPassportV2Component.prototype.savePassport = function (passportForm) {
        var _this = this;
        if (!passportForm.valid) {
            return false;
        }
        if (this.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor()) {
            return false;
        }
        //   this.passport.category = this.passportForm.get('category').value
        //    ? this.passportForm.get('category').value
        //    : PASSPORT_DEFAULT_CATEGORY;
        //   this.onSaveTest({type: 'savePassport', request: mergeStatic(this.passport.save())});
        var sub = this.onSaveComplete
            .subscribe(function () {
            sub.unsubscribe();
            _this.passport.save().subscribe(function (res) {
                _this.onSave.emit();
            });
        });
        this.eventStream$.emit({ key: 'savePassport', data: this.passport });
        return true;
    };
    EditPassportV2Component.prototype.publishPassport = function (passportForm) {
        var _this = this;
        if (!passportForm.valid) {
            return false;
        }
        if (!this.passportForm.controls['conversion'].value) {
            this.passport.conversion = null;
        }
        //   this.passport.category = this.passportForm.get('category').value
        //    ? this.passportForm.get('category').value
        //   : PASSPORT_DEFAULT_CATEGORY;
        var sub = this.onSaveComplete
            .subscribe(function () {
            sub.unsubscribe();
            _this.presentLoadingSpinner();
            _this.passport.publish().subscribe(function (res) {
                _this.passportService.savePassportSynchronizationData(_this.passport.aggregateSynchronizationData()).subscribe(function (result) { });
                _this.destroyLoadingSpinner();
                _this.onSave.emit();
            }, function () { return _this.destroyLoadingSpinner(); });
        });
        this.eventStream$.emit({ key: 'savePassport', data: this.passport });
        return true;
    };
    EditPassportV2Component.prototype.updatePassport = function (passportForm, redirection) {
        var _this = this;
        if (redirection === void 0) { redirection = true; }
        if (!this.passportForm.controls['conversion'].value) {
            this.passport.conversion = null;
        }
        var sub = this.onSaveComplete
            .subscribe(function () {
            sub.unsubscribe();
            _this.passport.update().subscribe(function (res) {
                _this.passportService.savePassportSynchronizationData(_this.passport.aggregateSynchronizationData()).subscribe(function (result) { });
                if (redirection)
                    _this.onSave.emit();
            });
        });
        this.eventStream$.emit({ key: 'savePassport', data: this.passport });
        return true;
    };
    EditPassportV2Component.prototype.validatePassport = function () {
        this.passport.publish().subscribe(function (res) {
            console.log('Validated passport:', res);
        });
        return true;
    };
    EditPassportV2Component.prototype.askForRating = function (passportForm) {
        var _this = this;
        if (!passportForm.valid) {
            return false;
        }
        var sub = this.onSaveComplete
            .subscribe(function () {
            sub.unsubscribe();
            _this.passport.askForRating().subscribe(function (res) {
                _this.onSave.emit();
            });
        });
        this.eventStream$.emit({ key: 'savePassport', data: this.passport });
        return true;
    };
    EditPassportV2Component.prototype.onChangeName = function (name) {
        if (this.passport.name !== name) {
            this.passportFormBuilder.reset(this.passportForm, ['passportName']);
            this.passport.id = null;
            this.uploadedfiles = [];
            this.passport = new Passport({ name: name });
            this.passport.set({ image: null });
            this.onChange.emit(this.passport);
        }
    };
    EditPassportV2Component.prototype.onCategoryChange = function (value, setControlValue) {
        this.filteredCategories = value
            ? this.allCategories.filter(function (category) {
                return ~category.toLowerCase().indexOf(value.toLowerCase()) && category !== value;
            })
            : this.allCategories;
        if (setControlValue) {
            this.passportForm.get('category').setValue(value);
        }
    };
    EditPassportV2Component.prototype.isProductPassportIdVisible = function () {
        return this.navigationService.getContext().roleId === ERoleIds.SUPERVISOR
            && !!this.passport.product_passport_id;
    };
    // calculatePrice(): number {
    //   let componentsForm = <FormArray>this.passportForm.get('components');
    //   if (componentsForm) {
    //     return componentsForm.controls
    //     .map((control) => <FormGroup>control ? this.calculateComponentPrice(<FormGroup>control) : 0)
    //     .reduce((sum, price) => sum + price) || 0;
    //   }
    //
    //   return 0;
    // }
    // calculateComponentPrice(componentForm: FormGroup): number {
    //   let quantity = componentForm.get('componentNumber').value || Number(componentForm.get('isDefault').value) || 0;
    //   let materialsFormArray = <FormArray>componentForm.get('materials');
    //   if (materialsFormArray) {
    //     let materialsCost = materialsFormArray.controls
    //     .map((control) => <FormGroup>control ? this.calculateMaterialPrice(<FormGroup>control) : 0)
    //     .reduce((sum, price) => sum + price) || 0;
    //
    //     return quantity * materialsCost;
    //   }
    //
    //   return 0;
    // }
    // calculateMaterialPrice(materialForm: FormGroup): number {
    //   let quantity = materialForm.get('quantity').value || 0;
    //   let price = materialForm.get('price').value || 0;
    //
    //   let parsedQuantity: number = ValidationService.normalizeQuantity(quantity);
    //   let parsedPrice: number = ValidationService.normalizeQuantity(price);
    //
    //   return (parsedQuantity * parsedPrice) / 1000;
    // }
    EditPassportV2Component.prototype.removePassportPreview = function () {
        var _this = this;
        this.passportService.removeLogo(this.passport.id).subscribe(function () {
            _this.passport.image = null;
            console.log('Deleted');
        });
    };
    EditPassportV2Component.prototype.handleC2cRadioClick = function (key) {
        var _this = this;
        if (this.disabled) {
            return;
        }
        if (key === COMPOSITION_RADIO.YES) {
            this.dialog.open(ConfirmC2cDialogComponent, {
                data: {
                    openBannedComponentsPdf: function () { return _this.openBannedComponentsList(); }
                }
            }).afterClosed().subscribe(function (data) {
                return _this.passport.no_banned_materials = !!data ? COMPOSITION_RADIO.YES : COMPOSITION_RADIO.NO;
            });
        }
        else {
            return this.passport.no_banned_materials = key;
        }
    };
    EditPassportV2Component.prototype.onOpenGenerationNameDialog = function () {
        // this.isDetailsView not used
        var _this = this;
        if (this.disabled || this.parentModule === this.moduleNames.SPECIAL_ADMIN) {
            return;
        }
        var dialog = this.dialog.open(NameGenerationDialogComponent, {
            data: {
                passport: this.passport,
                viewMode: this.viewMode,
                controlStates: this.controlStates,
                askForRatingMode: this.askForRatingMode,
                parentModule: this.parentModule
            }
        });
        dialog.afterClosed().subscribe(function (data) {
            dialog = null;
            if (!data || !data.passport) {
                return;
            }
            if (!data.passport.id) {
                return _this.onCreate(data.passport);
            }
            if (!!data.passport) {
                _this.setPassport(data.passport);
            }
        });
    };
    EditPassportV2Component.prototype.getLabelClasses = function (field) {
        return {
            'primary-color': field,
            'label-color': !field,
            'passport-edit__color-transition': true
        };
    };
    EditPassportV2Component.prototype.setAsterisk = function () {
        return this.askForRatingMode ? '*' : '';
    };
    EditPassportV2Component.prototype.isRatingBlockVisible = function () {
        var _this = this;
        var availableValues = [this.passportStatuses.PUBLISHED.value, this.passportStatuses.WAITING_FOR_PUBLICATION.value];
        return this.parentModule === this.moduleNames.SPECIAL_ADMIN && !!availableValues.find(function (i) { return i === _this.passport.status; });
    };
    EditPassportV2Component.prototype.isFieldInvalid = function (field) {
        if (!this.passportForm) {
            return;
        }
        var selectedControl = this.passportForm.get(field);
        return (!!selectedControl && !!selectedControl.invalid);
    };
    EditPassportV2Component.prototype.isConversionInvalid = function () {
        return this.isFieldInvalid('conversion');
    };
    EditPassportV2Component.prototype.isSupportingDocumentsVisible = function () {
        var passportUserId = (!!this.passport && this.passport.user_id);
        if (this.selectedTab === PASSPORT_TAB.GENERAL || this.selectedTab === PASSPORT_TAB.INFO || !this.isLongVersionActive) {
            return false;
        }
        if (this.parentModule === ModuleNames.SPECIAL_ADMIN
            || this.parentModule === ModuleNames.MANUFACTURER
                && CurrentUser.infoData.id === passportUserId) {
            return true;
        }
    };
    EditPassportV2Component.prototype.openCategoryeDialog = function ($event) {
        var _this = this;
        if (this.readOnlyService && this.readOnlyService.readOnly) {
            return;
        }
        if (!!this.areFormControlsDisabled) {
            return this.onControlClick($event);
        }
        var dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
            height: '80%',
            width: '80%',
            position: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.passport.category = result;
                _this.passport.category_id = result.id;
                _this.passportForm.controls['category'].setValue(result.name ? result.name : '');
                _this.passportForm.controls['category'].disable();
            }
        });
    };
    EditPassportV2Component.prototype.nextPassportStep = function () {
        var currentTab = PASSPORT_TAB_TYPE[this.selectedTab.toUpperCase()];
        var nextTab = PASSPORT_TAB_TYPE_NUMERIC[currentTab + 1];
        this.onNextTabClick.emit(nextTab);
    };
    EditPassportV2Component.prototype.isPassportConversionShown = function () {
        if (!this.passport || !this.passport.unit) {
            return false;
        }
        return this.passport.unit !== PASSPORT_UNITS_TYPE.KG && this.passport.unit !== PASSPORT_UNITS_TYPE.TONNE;
    };
    EditPassportV2Component.prototype.initSelfDestroySpinnerTimeout = function () {
        var _this = this;
        this.spinnerTimeout = setTimeout(function () {
            if (!_this.spinnerDialogRef) {
                return;
            }
            _this.destroyLoadingSpinner();
            _this.notification.error(_this.translate.instant('CANNOT_BE_SAVED_ERROR', { X: 'Circular passport' }), null);
            _this.requestsSubject.next([]);
            _this.requestSubscription.unsubscribe();
        }, 45000);
    };
    EditPassportV2Component.prototype.presentLoadingSpinner = function () {
        if (!this.spinnerEnabled || !!this.spinnerDialogRef) {
            return;
        }
        this.cd.detach();
        this.spinnerDialogRef = this.dialog.open(SpinnerDialogComponent, {
            disableClose: true,
            data: {
                caption: 'Saving circular passport', isLoadingDotsShown: true
            }
        });
        this.navigationService.showNavBar = false;
        this.initSelfDestroySpinnerTimeout();
    };
    EditPassportV2Component.prototype.destroyLoadingSpinner = function () {
        if (!this.spinnerDialogRef) {
            return;
        }
        this.cd.reattach();
        clearTimeout(this.spinnerTimeout);
        this.navigationService.showNavBar = true;
        this.spinnerDialogRef.close();
        this.spinnerDialogRef = null;
    };
    EditPassportV2Component.prototype.handlePassportDataRequest = function (type, responseError) {
        var _this = this;
        this.requestsSubject.next([]);
        this.destroyLoadingSpinner();
        return {
            success: function () {
                _this.onSaveComplete.emit();
            },
            error: function () {
                var duration = 3000;
                _this.notification.error(_this.translate.instant('CANNOT_BE_SAVED_ERROR', { X: type }), duration);
                setTimeout(function () {
                    _this.passportService.onError(responseError);
                }, duration);
            }
        };
    };
    EditPassportV2Component.prototype.onUpdatePassportData = function (event) {
        this.presentLoadingSpinner();
        this.requestsSubject.next(this.requestsSubject.getValue().concat([
            event.request.catch(function (err) { return Observable.throw({ error: err, field: event.type }); })
        ]));
    };
    EditPassportV2Component.prototype.subscribeOnRequests = function () {
        var _this = this;
        this.requestSubscription = forkJoin(this.requestsSubject.getValue())
            .subscribe(function () { return _this.handlePassportDataRequest().success(); }, function (err) { return _this.handlePassportDataRequest(err.field, err.error).error(); });
    };
    EditPassportV2Component.prototype.getCommetariesPlaceholder = function () {
        return this.disabled ? this.translate.instant('Comments') : this.translate.instant('add comments');
    };
    EditPassportV2Component.prototype.getRecommendationsForUsePlaceholder = function () {
        return this.disabled ? this.translate.instant('Recommendations for use') : this.translate.instant('add recommendations for use');
    };
    EditPassportV2Component.prototype.createAnotherVersion = function () {
        var _this = this;
        var dialogRef = this.dialog.open(NewPassportVersionDialog, {
            data: {
                passport: this.passport
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result.passport) {
                _this.offerNewVersion = false;
                _this.passport.id = result.passport.id;
                _this.passport.status = result.passport.status;
                _this.passport.version = result.passport.version;
                _this.passport.created_at = result.passport.created_at;
                _this.passport.updated_at = result.passport.updated_at;
                _this.passport.product_passport_id = result.passport.product_passport_id;
                _this.eventStream$.emit({ key: 'changePassport', data: _this.passport });
                _this.enableFormControls();
                _this.setCategory();
                _this.updateUrl();
            }
        });
    };
    EditPassportV2Component.prototype.updateUrl = function () {
        this.location.go(this.navigationService.getContext().moduleName + '/passports/' + this.passport.id);
    };
    EditPassportV2Component.prototype.isNewVersionCreationAllowed = function () {
        var isNewVersion = (this.passport.isPublished() || this.passport.isStatus(PASSPORT_STATUS.ASK_FOR_MODIFICATION));
        if (!this.isPublicAccess && isNewVersion) {
            if (this.parentModule === this.moduleNames.MANUFACTURER && this.passport.isIndependent()) {
                return this.passport.user.id === CurrentUser.infoData.id;
            }
            if (this.parentModule === this.moduleNames.SUPERVISOR) {
                return !this.passport.isIndependent();
            }
            if (this.parentModule === this.moduleNames.AGENT) {
                return this.passport.user.id === CurrentUser.infoData.id;
            }
        }
        return false;
    };
    EditPassportV2Component.prototype.openPopup = function () {
        var _this = this;
        var mdDialog = this.dialog.open(NewPassportPublicationComponent, {});
        mdDialog.afterClosed().subscribe(function (result) {
            if (result === 'edit') {
                return;
            }
            else if (result == 'new_version') {
                _this.createAnotherVersion();
            }
        });
    };
    /**
     * Get all the objects associated to the passport (next usages, colors, etc...)
     * /!\ For now, this views and directly calls functions in the child components, which seems horrible to do. Think about a way of making this better /!\
     * @returns {Object}
     */
    EditPassportV2Component.prototype.getAssociatedObjects = function () {
        return tslib_1.__assign({ colors: this.getColors(), next_usages: this.getNextUsages() }, this.getComponents(), { manufacturers: this.getManufacturers(), distribution_areas: this.getDistributionAreas() });
    };
    /**
     * Get the colors of the passport
     * @returns {Array<PassportColor>}
     */
    EditPassportV2Component.prototype.getColors = function () {
        return this.passportColors.getCurrentColors();
    };
    /**
     * Get the components of the passport
     * Also includes materials and attributes corresponding to the default component
     * @returns {Object}
     */
    EditPassportV2Component.prototype.getComponents = function () {
        return this.passportComponents.getCurrentComponents();
    };
    /**
     * Get the next usages of the passport
     * Also includes the affiliate branches corresponding to each next usage
     * @returns {Array<NextUsage>}
     */
    EditPassportV2Component.prototype.getNextUsages = function () {
        return this.passportNextUsages.getCurrentNextUsages();
    };
    /**
     * Get the manufacturers of the passport
     * @returns {Array<PassportManufacturer>}
     */
    EditPassportV2Component.prototype.getManufacturers = function () {
        return this.passportManufacturers.getCurrentManufacturers();
    };
    /**
     * Get the distribution areas of the passport
     * @returns {Array<Object>} The list of distribution areas
     */
    EditPassportV2Component.prototype.getDistributionAreas = function () {
        return this.passport.distribution_areas.map(this.formatDistributionArea);
    };
    /**
     * Format a distribution area to be understood by the backend
     * Mainly flatten it and get the country_iso available easily, even for new areas
     * @param {PassportDistributionArea} distributionArea - The distribution area to format
     * @returns {Object}
     */
    EditPassportV2Component.prototype.formatDistributionArea = function (distributionArea) {
        return {
            id: distributionArea.id,
            country_iso: distributionArea.country_id || distributionArea.country && distributionArea.country.iso || null
        };
    };
    return EditPassportV2Component;
}());
export { EditPassportV2Component };
