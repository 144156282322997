<div class="depositlist">
  <div class="depositlist__buttons-container">
    <button md-raised-button (click)="newDeposit()">
            +
            {{ 'CREATE_MANUALLY_DEPOSIT' | translate }}</button>
    <button md-raised-button (click)="importDeposits()">
            <i class="upload-files__camera button-image import--icon x4"></i>
            {{ 'import deposits' | translate }}</button>
  </div>

  <div class="depositlist__filters" test-id="deposit-list-filters">
    <section class="flex depositlist__filters-button-group">
      <passport-reset-button
        class="passportlist__clear-filters--offset-right"
        [alternativeRules]="canClearFiltersAlternativeRules"
        [resetFilterState]="resetFilters"
        [controlAsFilter]="['searchControl']"
        [passportFilter]="depositFilter"
        (onResetClick)="clearFilters($event)"
      >
      </passport-reset-button>
    </section>
    <div class="form-group">

      <div class="form-group__content--row depositlist__info">
        <md-input-container class="depositlist__info-name-input">
          <input
            test-id="search"
            mdInput
            autocomplete="off"
            class="depositlist__info-name-title"
            placeholder="{{ 'Search by name, materials, address' | translate }}... {{ 'etc' | translate }}."
            [formControl]="depositFilter.searchControl">
        </md-input-container>

        <md-input-container class="depositlist__info-disponable-input calendar--icon">
          <input
            test-id="available-date-from-filter"
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            mdInput
            autocomplete="off"
            class="depositlist__info-disponable-title"
            placeholder="{{ 'Available FROM' | translate }}"
            [(ngModel)]="depositFilter.availabilityDateFrom"
            (popupClosed)="searchChange()"
          >
        </md-input-container>

        <i class="material-icons span-icon">remove</i>

        <md-input-container class="depositlist__info-disponable-input calendar--icon">
          <input
            test-id="available-date-to-filter"
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            mdInput class="depositlist__info-disponable-title"
            placeholder="{{ 'Available TO' | translate }}"
            autocomplete="off"
            [(ngModel)]="depositFilter.availabilityDateTo"
            (popupClosed)="searchChange()"
          >
        </md-input-container>
      </div>

      <div class="form-group__content--row depositlist__info">
        <section class="depositlist__form-item-list depositlist__info-name-input--width">

          <deposit-custom-multi-select
            class="depositlist__form-item-list-sub-item"
            appMultiSelectMdSelectInput
            [options]="depositSourceList"
            [placeholder]="'Product source'"
            [ngModel]="this.depositFilter.reuseSource"
            (ngModelChange) = "this.depositFilter.reuseSource = $event; searchChange()"
            >
          </deposit-custom-multi-select>

          <div class="control-group__item depositlist__form-item depositlist__form-item-list-sub-item">
            <md-input-container (click)="openCategoryDialog(categorie)" class="passportlist__search-materials">
              <md-chip-list>
                <md-basic-chip *ngFor="let tag of depositFilter.categoryTags"
                               (focus)="false"
                               md-tooltip="{{ tag.name | translate }}">
                  {{(tag.name.length > 7 ? tag.name.substr(0,7)+'..' : tag.name ) | translate }}
                  <button (click)="removeCategoryTag(tag, $event)" class="delete"></button>
                </md-basic-chip>
              </md-chip-list>
              <input test-id="nomenclature-selector"
                         class="passportlist__info-by"
                         #categorie
                         autocomplete="off"
                         placeholder="{{ categoryPlaceholder | translate }}"
                         mdInput
              >
            </md-input-container>
          </div>
          <rating-with-local-passport-filter class="depositlist__form-item-list-sub-item"
            [classList]="['depositlist__info-rating']"
            [ratingFilter]="this.depositFilter.ratingFilter"
            [defaultRatings]="resetFilters.ratingFilter"
            (ratingFilterChange)="this.depositFilter.ratingFilter = $event; this.searchChange()"
            (onAnySelected)="isAnyPassportRatingSelected = $event"
          >
          </rating-with-local-passport-filter>

          <filter-state-of-use class="depositlist__form-item-list-sub-item"
          [stateOfUse]="depositFilter.stateOfUse"
          (selectionChanged)="searchChange()"
          ></filter-state-of-use>


        </section>

        <div class="control-group__item depositlist__form-item--no-width
         depositlist__info-disponable-input--width
         depositlist__form-item--site-and-tag
         depositlist__form-item--site-and-tag--offset-bottom">
          <deposit-location-filter
            field="nameAddress"
            placeholder="{{'Site(s)' | translate}}"
            iconClass=""
            [inputContainerClass]="[]"
            [availableItemsWithoutReset]="sites"
            [(ngModel)]="this.depositFilter.selectedSites"
            (ngModelChange)="searchChange()"
          >
          </deposit-location-filter>
          <deposit-filter-status
            (change)="searchChange(isDeletableStatus($event))"
            [defaultStatusValue]="{searchStatus: resetFilters.searchStatus
              , isAllSelected: resetFilters.isAllSelected
              , isOutOfStockSelected: resetFilters.isOutOfStockSelected}"
            [filterStatuses]="filterStatuses"
            [(searchStatus)]="depositFilter.searchStatus"
            [(isOutOfStockSelected)]="depositFilter.isOutOfStockSelected"
            [(isAllSelected)]="depositFilter.isAllSelected"
          >
          </deposit-filter-status>
        </div>
        <div class="depositlist__form-item-delimiter"></div>
        <div class="control-group__item depositlist__form-item--no-width
         depositlist__form-item--site-and-tag
         depositlist__form-item--site-and-tag--offset-bottom
         depositlist__info-disponable-input--width newline">
          <deposit-location-filter
            field="name"
            placeholder="{{'Tag(s)' | translate}}"
            iconClass=""
            [inputContainerClass]="[]"
            [disabled]="this.depositFilter.selectedSites.length !== 1"
            [availableItemsWithoutReset]="this.depositFilter.selectedSites.length === 1
            ? this.depositFilter.selectedSites[0]?.tags : []"
            [(ngModel)]="this.depositFilter.selectedTags"
            (ngModelChange)="searchChange()"
          >
          </deposit-location-filter>

          <md-select
            test-id="sort-selector"
            class="depositlist__info-sort
             depositlist__info-sort--width-whole"
            placeholder="{{ 'Sort by' | translate }}"
            [(ngModel)]=depositFilter.orderBy
            (change)="searchChange(true, true)"
          >
            <md-option *ngFor="let sort of sort_order" [value]="sort.key">
              {{sort.value | translate}}
            </md-option>
          </md-select>
        </div>

      </div>

      <div class="form-group__content--row depositlist__info">
        <div class="bottom-left-checkbox">
            <md-checkbox class="custom-checkbox"
                [(ngModel)]="depositFilter.isOutOfStockSelected"
                (ngModelChange)="searchChange()">
            </md-checkbox>
            <label class="checkbox-label">{{'Out_of_stocks' | translate}}</label>
        </div>
      </div>    
    </div>
  </div>

  <div class="depositlist__mass-buttons-container">
    <button md-raised-button (click)="askForDepositDeleteModal()" [disabled]="!areSomeItemChecked()" class="depositlist__mass-buttons-delete-button">
      <i class="upload-files__camera button-image icon--white-deletion-bin x3"></i>{{ 'Delete' | translate }}
    </button>
    <button md-raised-button (click)="openMassUpdateModal()" [disabled]="isMassModificationDisabled()" class="depositlist__mass-buttons-action-button">
      {{ 'DEPOSIT_MASS_UPDATE_BUTTON' | translate }}
    </button>
    <button md-raised-button (click)="openQuickUpdateModal()" [disabled]="isMassModificationDisabled()" class="depositlist__mass-buttons-action-button">
      {{ 'DEPOSIT_QUICK_UPDATE_BUTTON' | translate }}
    </button>
  </div>

  <div class="depositlist__header">
    <div class="depositlist__header-checkbox-container">
      <md-checkbox class="depositlist__header-checkbox" (change)="toggleCheckAll();" [checked]="isAllChecked()" [indeterminate]="isPartiallyChecked()"></md-checkbox>
    </div>
    <div class="depositlist__header-picture"></div>
    <div class="depositlist__header-info">
      <div class="depositlist__header-name">{{'DEPOSIT_LIST_HEADER_DESIGNATION' | translate}}</div>
      <div class="depositlist__header-quantity">{{'DEPOSIT_LIST_HEADER_QUANTITY' | translate}}</div>
      <div class="depositlist__header-location">{{'DEPOSIT_LIST_HEADER_SITE' | translate}}</div>
    </div>
    <div class="depositlist__header-condition">{{'DEPOSIT_LIST_HEADER_STATUS' | translate}}</div>
  </div>

  <div *ngIf="shouldDisplayCheckAllHeader()" class="depositlist__check-all-container">
    <p class="depositlist__check-all-container-message">
      {{getSelectedDepositsMessage()}}&nbsp;&nbsp;&nbsp;
      <a class="depositlist__link-green" *ngIf="shouldDisplayCheckAllMessage()" (click)="activateAllPageMode()">{{getSelectAllMessage()}}</a>
      <span style="text-decoration: none;">&nbsp;&nbsp;&nbsp;</span>
      <a class="depositlist__link-gray" (click)="deactivateAllPageMode()">{{getDeselectAllMessage()}}</a>
    </p>
  </div>

  <div class="depositlist__results" test-id="deposit-list-results">
    <deposit-checkable-item
    *ngFor="let record of records | paginate:{id:'deposits'
    , itemsPerPage: depositFilter.perPage
    , currentPage: depositFilter.currentPage
    , totalItems: depositFilter.totalCount }"
    [deposit]="record"
    [isChecked]="isChecked(record.id)"
    (onDelete)="deleteRecord($event)"
    (onOpen)="openRecord($event)"
    (onDuplicate)="duplicateRecord($event)"
    (onCheck)="toggleChecked($event)"
    ></deposit-checkable-item>
    <div *ngIf="!records.length && isLoaded" class="depositlist__results-empty">
      <common-info-sign
        class="depositlist__results-empty-sign"
        [sizeClassModifier]="'20'"
        [borderSizeClassModifier]="'1-half'">!</common-info-sign>
      <span class="primary-color depositlist__results-empty-text">{{'No deposits were found' | translate}}</span>
    </div>
  </div>

  <div class="depositlist__container-pagination" test-id="deposit-list-pagination">
    <pagination-controls
            class="depositlist__pagination"
            id="deposits"
            (pageChange)="pageChange($event)"
            maxSize="8"
            directionLinks="true"
            autoHide="true"
            previousLabel=""
            nextLabel=""
            screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
            screenReaderPageLabel="{{ 'page' | translate }}"
            screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"
    ></pagination-controls>
  </div>
</div>
