import {Component, Input, OnInit} from '@angular/core';
import { ECarbonFootprintReportType } from '../../../../../../dashboards/admin/values/carbon-footprint-status.enum';
import {IReportResponse} from '../../../../interfaces/reportResponse.interface';
import { ReportBase } from '../report-base/report-base.class';
@Component({
  moduleId: module.id,
  selector: 'carbon-footprint',
  templateUrl: 'carbon-footprint.component.html',
  styleUrls: ['carbon-footprint.component.scss']
})
export class CarbonFootprintComponent extends ReportBase implements OnInit {
  @Input() reports: IReportResponse;
  @Input() changeTab: Function;
  
  public reportTypes: ECarbonFootprintReportType[];


  ngOnInit(): void {
    this.reportTypes = [];
    if(this.reports.product_stage){
      this.reportTypes.push(ECarbonFootprintReportType.PRODUCT_STAGE);
    }
    if(this.reports.product_and_construction){
      this.reportTypes.push(ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION);
    }
    if(this.reports.embodied_carbon){
      this.reportTypes.push(ECarbonFootprintReportType.EMBODIED_CARBON);
    }
    if(this.reports.ic_component){
      this.reportTypes.push(ECarbonFootprintReportType.IC_COMPONENT);
    }
    if(this.reports.ic_construction){
      this.reportTypes.push(ECarbonFootprintReportType.IC_CONSTRUCTION);
    }

  }   
}
