/**
 * Created by Aleksandr C. on 9.02.17.
 */
import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { DepositPhoto } from '../models/deposit-photo.class';

@Injectable()
export class DepositPhotoService extends CrudService {
  protected namespace = 'deposit-photos';
  protected namespaceSingular = 'deposit-photo';
  protected ModelClass = DepositPhoto;
}
