import { Component } from '@angular/core';


@Component({
  moduleId: module.id,
  templateUrl: 'ecosystem-ecosystems.component.html',
})
export class EcosystemEcosystemsComponent {


  constructor(
    // private _ecosystemProcessCountService: EcosystemProcessCountService,
              ) {

    // this.ecosystemService.getProcessCount().subscribe(c => this.ecosystemsInProcessCount = c);
    // this._ecosystemOfferService.getCount().subscribe(c => this.offerCount = c);
    // this._ecosystemProcessCountService.get().subscribe((res: string) => {
    //   this.ecosystemsInProcessCount = res;
    // });
  }
}
