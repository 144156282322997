import { EDeepStreamMessage } from '../../values/deepstream-message-type.enum';

export abstract class BaseDeepStreamService {

    protected abstract messageTypes: EDeepStreamMessage[];

    public abstract emit(data: any);

    public abstract receiver: string;

    public isMessageTypeIncluded(type: EDeepStreamMessage): boolean {
        return this.messageTypes.includes(type);
    }


}