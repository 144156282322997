import {EventEmitter, Injectable} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {DepositLocation} from '../models/deposit-location.class';
import {User} from '../../../shared/models/user.class';
import {FileItem} from 'ng2-file-upload/file-upload/file-item.class';

@Injectable({
  providedIn: 'root'
})
export class DepositLocationTemporaryStorageService {
  public onLoad$ = new EventEmitter();
  private maxAllowedInstance = 100;
  private maxAllowedItems = 999;
  private maxUploadInstanceAtOnce = 10;
  private resetObservers = true;
  private uploaderMap: Map<DepositLocation, FileUploader> = new Map();
  private loadingLocationCount = 0;
  private loadingIndex = 0;
  private locationList = [];

  public setResetObservers(state: boolean) {
    this.resetObservers = state;
  }

  public getUploader(location: DepositLocation) {
    const uploaderIns = this.uploaderMap.get(location);
    if (uploaderIns) {
      const currentQueue = uploaderIns.queue.length;
      uploaderIns.options.queueLimit = this.getAvailableItemNumber() + currentQueue;
      return uploaderIns;
    }
    return null;
  }

  public getAvailableItemNumber() {
   return Object.values(this.uploaderMap)
      .reduce((totalAvailable, uploader: FileUploader) =>
        totalAvailable - uploader.queue.length, this.maxAllowedItems);
  }

  public getDepositLocations() {
    return this.locationList;
  }

  public getAvailableInstanceNumber() {
    return this.maxAllowedInstance - this.locationList.length;
  }

  public addLocation() {
    if (this.locationList.length > this.maxAllowedInstance) {
      return {location: null, fileUploader: null};
    }
    const location = new DepositLocation();
    const fileUploader = new FileUploader({queueLimit: this.getAvailableItemNumber()});
    this.locationList.push(location);
    this.uploaderMap.set(location, fileUploader);
    return {location, fileUploader};
  }

  public remove(item: DepositLocation) {
     this.locationList = this.locationList.filter(location => !Object.is(location, item));
     this.uploaderMap.delete(item);
  }

  public isLoading() {
    return this.loadingLocationCount !== 0;
  }

  configUploader(url: string, location: DepositLocation) {
    const uploaderIns = this.uploaderMap.get(location);
    if (uploaderIns) {
      uploaderIns.setOptions({url});
      if (this.resetObservers) {
        uploaderIns.onSuccessItem = () => {};
        uploaderIns.onCompleteItem = () => {};
        uploaderIns.onCompleteAll = () => {};
      }
    }
    return uploaderIns;
  }

  uploadTo(location: DepositLocation, url?: string) {
    const uploader = this.configUploader(url, location);
    if (uploader)  {
      uploader.uploadAll();
    }
  }

  uploadAll(user: User) {
    if (!this.isLoading()) {
      this.loadingIndex = 0;
      this.uploadChunk(user, [...this.locationList]);
    }
  }

  private uploadChunk(user: User, list: DepositLocation[]) {
    if (this.loadingIndex >= list.length) {
      if (!this.isLoading()) {
        this.onLoad$.emit();
      }
      return;
    }
    Array(this.maxUploadInstanceAtOnce).fill(1).forEach(() => {
      if (list.length <= this.loadingIndex) {
        return;
      }
      const site = list[this.loadingIndex++];
      this.loadingLocationCount += 1;
      site.user_id = user.id;
      site.save().subscribe((item: DepositLocation) =>  {
          this.uploadTo(site, item.getFileUploadUrl());
          --this.loadingLocationCount;
          this.uploadChunk(user, list);
        }, () => {
          --this.loadingLocationCount;
          this.uploadChunk(user, list);
        }
      );
    });
  }
}
