import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { IResidualValueAggregate } from '../../types/residual-value.interface';
import { COLOR, TOTAL_COLOR } from '../../types/residual-value-colors.const';
import { MAIN_TAB_STEPS } from '../../../../admin-reporting.const';
var ResidualValueThirdChartComponent = /** @class */ (function () {
    function ResidualValueThirdChartComponent() {
        this.isPDFReport = false;
        this.showTotal = true;
        this.showAxisLabel = true;
        this.numberOfChartRows = 7;
        this.graphicCanvasHeight = 464;
        this.heightLimitForTwoCircles = 100;
        this.heightLimitForOneCircle = 47;
        this.colors = Object.values(COLOR);
        this.chartCategories = [];
    }
    Object.defineProperty(ResidualValueThirdChartComponent.prototype, "data", {
        get: function () {
            if (this.report.report_result) {
                return this.report.report_result.financial_residual_value[this.type].aggregate;
            }
            return this.report[this.type].aggregate;
        },
        enumerable: true,
        configurable: true
    });
    ResidualValueThirdChartComponent.prototype.ngOnInit = function () {
        this.prepareChartColumns();
    };
    ResidualValueThirdChartComponent.prototype.hasCategory = function () {
        if (this.report.report_result) {
            return !!this.report.report_result.financial_residual_value[this.type].aggregate.category;
        }
        return !!this.report[this.type].aggregate;
    };
    ResidualValueThirdChartComponent.prototype.prepareChartColumns = function () {
        this.graphicCanvasHeight = this.isPDFReport ? 264 : 464;
        if (!this.hasCategory()) {
            return;
        }
        var amountOfCategories = Object.keys(this.data.category).length;
        if (!amountOfCategories) {
            return;
        }
        this.chartCategories = this.showTotal ? [
            this.getTotalCategory()
        ].concat(this.getChartCategories()) : this.getChartCategories().slice();
    };
    ResidualValueThirdChartComponent.prototype.getTotalCategory = function () {
        return this.data ?
            {
                data: {
                    high: this.getRoundValue(this.data.total_financial_recovery_percentage_up_if_economic),
                    low: this.getRoundValue(this.data.total_financial_recovery_percentage_down_if_economic)
                },
                name: 'TOTAL',
                color: TOTAL_COLOR,
                type: 'total'
            }
            : null;
    };
    ResidualValueThirdChartComponent.prototype.getChartCategories = function () {
        var _this = this;
        var categories = this.orderProductsBy('financial_recovery_percentage_up_if_economic');
        // const categories = [
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
        //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category)
        // ];
        var colorIndex = 0;
        return categories.map(function (i, index) {
            if (index > 0) {
                colorIndex += 1;
            }
            if (colorIndex + 1 > _this.colors.length) {
                colorIndex = 0;
            }
            return {
                data: {
                    high: _this.getRoundValue(i.financial_recovery_percentage_up_if_economic),
                    low: _this.getRoundValue(i.financial_recovery_percentage_down_if_economic)
                },
                name: i.category.name,
                color: _this.colors[colorIndex],
                type: Object.keys(COLOR)[colorIndex].toLowerCase()
            };
        });
    };
    ResidualValueThirdChartComponent.prototype.getModifiedClassName = function (className, itemNumber) {
        return className + " " + className + "-" + itemNumber;
    };
    ResidualValueThirdChartComponent.prototype.getGraphicCanvasHeight = function () {
        return this.graphicCanvasHeight + 'px';
    };
    ResidualValueThirdChartComponent.prototype.orderProductsBy = function (orderBy) {
        var report = this.report.report_result ? this.report.report_result.financial_residual_value[this.type] : this.report[this.type];
        var categories = report.aggregate.category;
        if (!!categories) {
            var categoriesFiltered = tslib_1.__assign({}, Object.values(categories).filter(function (e) { return !!e.deposit_considered; }));
            return Object.values(categoriesFiltered).sort(function (a, b) { return a[orderBy] < b[orderBy] ? 1 : (b[orderBy] < a[orderBy] ? -1 : 0); });
        }
    };
    ResidualValueThirdChartComponent.prototype.getOnePointVerticalAxisValues = function () {
        var maxPointForVerticalAxisValues = Math.max.apply(Math, [
            this.orderProductsBy('financial_recovery_percentage_up_if_economic')[0].financial_recovery_percentage_up_if_economic,
            this.data.total_financial_recovery_percentage_up_if_economic,
        ]);
        return Math.ceil(maxPointForVerticalAxisValues / this.numberOfChartRows);
    };
    ResidualValueThirdChartComponent.prototype.getNumberOfPixelsInOneRowVerticalAxis = function () {
        return this.graphicCanvasHeight / this.numberOfChartRows;
    };
    ResidualValueThirdChartComponent.prototype.numberOfHrMarginTopValues = function () {
        return new Array(this.numberOfChartRows).fill((this.getNumberOfPixelsInOneRowVerticalAxis() - 1) + 'px');
    };
    ResidualValueThirdChartComponent.prototype.getVerticalAxisValues = function () {
        var pointChartValue = 0;
        var pointChartValues = [];
        for (var i = 0; i <= this.numberOfChartRows; i++) {
            pointChartValues.push(pointChartValue);
            pointChartValue += this.getOnePointVerticalAxisValues();
        }
        return pointChartValues;
    };
    ResidualValueThirdChartComponent.prototype.getAmountForOneChartRowInPixel = function () {
        return this.graphicCanvasHeight / (this.getOnePointVerticalAxisValues() * this.numberOfChartRows);
    };
    ResidualValueThirdChartComponent.prototype.getAmountForPillarInPixels = function (maxValue, minValue) {
        return (this.getAmountForOneChartRowInPixel() * (maxValue - minValue)) + 'px';
    };
    ResidualValueThirdChartComponent.prototype.getRoundValue = function (value) {
        return Math.round(value * 10) / 10;
    };
    ResidualValueThirdChartComponent.prototype.getBottomForPillarInPixels = function (max, min) {
        var bottomForPillar = this.getAmountForOneChartRowInPixel() * min;
        var heightLimitForTwoCircles = this.graphicCanvasHeight - this.heightLimitForTwoCircles;
        var heightLimitForOneCircle = this.graphicCanvasHeight - this.heightLimitForOneCircle;
        if (bottomForPillar > heightLimitForTwoCircles) {
            return this.isMaxMoreThanMinByOnePercent(max, min)
                ? (heightLimitForTwoCircles) + 'px'
                : (heightLimitForOneCircle) + 'px';
        }
        else {
            return bottomForPillar + 'px';
        }
    };
    ResidualValueThirdChartComponent.prototype.isMaxMoreThanMinByOnePercent = function (max, min) {
        return max - min > 1;
    };
    ResidualValueThirdChartComponent.prototype.getAverageOrMinValue = function (max, min) {
        var averageValue = (max + min) / 2;
        return this.isMaxMoreThanMinByOnePercent(max, min)
            ? min
            : this.getRoundValue(averageValue);
    };
    ResidualValueThirdChartComponent.prototype.goToArchive = function () {
        this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
    };
    return ResidualValueThirdChartComponent;
}());
export { ResidualValueThirdChartComponent };
