import { ActiveRecord } from '../../../shared/models/active-record.class';
import { ICrud } from '../../../shared/interfaces';
import { IReportResponse } from '../interfaces/reportResponse.interface';
import { ReportService } from '../services/report.service';
import { DepositLocation } from '../../../entities/deposit/models/deposit-location.class';
import {DepositTag} from '../../../entities/deposit/models/deposit-tag.class';
import {IReportSnapshot} from '../interfaces/report-snapshot';

export class Reports extends ActiveRecord implements IReportResponse {
  protected service: ICrud;
  protected provider = ReportService;

  id: number;
  building_category: any;
  deconstruction: any;
  rehabilitation: any;
  renewal_building_components: any;
  report_files: any;
  carbon_foot_print: boolean;
  embodied_carbon: boolean;
  product_stage: boolean;
  ic_component: boolean;
  ic_construction: boolean;
  product_and_construction: boolean;
  circularity: boolean;
  financial_residual_value: boolean;
  material_health: boolean;
  report_result: any;
  created_at: any;
  zipfile_link: any;
  report_nr: any;
  timezone_offset: number;
  sites: DepositLocation[];
  tags: DepositTag[];
  created_by_file: boolean;
  result: any;
  snapshot: any;
  is_asynchronous_generated: boolean;
  private snapshotData: IReportSnapshot[];

  getSnapshot(): IReportSnapshot[] {
    if (!this.snapshotData) {
      try {
        const data = JSON.parse(this.snapshot);
        this.snapshotData = Object.values(data) as IReportSnapshot[];
      } catch (e) {
        console.warn(`Report snapshot is not defined for report nr ${this.report_nr}`);
      }
    }
    return this.snapshotData;
  }

  constructor(data?: any) {
    super(data);
    // this.id = null;
    // this.circularity = false ;
    // this.carbon_foot_print = false ;
    // this.financial_residual_value = false ;
    // this.material_health = false ;
  }

  fields() {
    return ['id', 'building_category', 'deconstruction', 'rehabilitation', 'renewal_building_components', 'report_files',
      'carbon_foot_print','embodied_carbon','ic_construction','ic_component', 'product_and_construction', 'product_stage', 'circularity', 'financial_residual_value', 'material_health', 'report_result', 'created_at',
      'zipfile_link', 'report_nr', 'timezone_offset', 'sites', 'tags', 'created_by_file', 'result', 'snapshot'];
  }


}
