import { LandfillVariablesService } from './landfill-variables.service';
import { ILandfillVariable } from './landfill-variables.interface';
import {ActiveRecord} from '../../../../shared/models/active-record.class';

export class LandfillVariables extends ActiveRecord implements ILandfillVariable {
  protected provider = LandfillVariablesService;
  id: number;
  value: number;
  name: string;
  currency: string;
  zone_id: number;

  fields() {
    return ['id', 'value', 'name', 'currency', 'zone_id'];
  }
}
