import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'decimalSeparator'})
export class DecimalSeparator implements PipeTransform {

  transform(value: string, separator: string) {
    if (value) {
      return value.replace(',', separator).replace('.', separator);
    }
    return value;
  }

}
