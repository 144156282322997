import * as tslib_1 from "tslib";
/**
 * Created by SImo 25/10/2019.
 */
import { OnInit } from '@angular/core';
import { ViewCategoryDialogComponent } from '../../../passport/dialogs/view-category/view-category.component';
import { MdDialog } from '@angular/material';
import { ViewNomenclatureDialogComponent } from '../../../passport/dialogs/view-nomenclature/view-nomenclature.component';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GenericPassport } from '../../models/generic-passport.class';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { FormControl } from '@angular/forms';
import { ZoneService } from '../../../../shared/services/zone.service';
import { PaginationUnmarshallingService } from '../../../../shared/services/pagination-unmarshalling.service';
var GenericPassportListComponent = /** @class */ (function () {
    function GenericPassportListComponent(dialog, _navigationService, _mdDialog, _router, _service, _translate, paginationService) {
        var _this = this;
        this.dialog = dialog;
        this._navigationService = _navigationService;
        this._mdDialog = _mdDialog;
        this._router = _router;
        this._service = _service;
        this._translate = _translate;
        this.paginationService = paginationService;
        this.tags = [];
        this.categoryTags = [];
        this.records = [];
        this.currentPage = 1;
        this.search = new FormControl();
        this.parentModuleName = this._navigationService.getModuleName();
        this._translate.get('CONFIRM_DELETE_GENERIC_PASSPORT').subscribe(function (translation) {
            _this._confirmDeletionText = translation;
        });
    }
    GenericPassportListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadRecordsAtStoredPage();
        this
            .search
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function (val) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isStringLengthMoreThan(val)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadRecords()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        this.resetSearch();
                        return [4 /*yield*/, this.loadRecords(null, true)];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        }); });
        this._service.view(this.zoneId, { expand: 'name' }).subscribe(function (zone) {
            _this.zone = zone;
        });
    };
    GenericPassportListComponent.prototype.isStringLengthMoreThan = function (value, length) {
        if (length === void 0) { length = 2; }
        return value && value.length > length;
    };
    GenericPassportListComponent.prototype.loadRecordsAtStoredPage = function () {
        var storedPage = this._service.getListPage();
        var options = {};
        !!storedPage ? this.loadRecords(storedPage) : this.loadRecords();
    };
    GenericPassportListComponent.prototype.resetSearch = function () {
        this.records = [];
        this.currentPage = null;
        this.pageCount = null;
        this.perPage = null;
        this.totalCount = null;
    };
    GenericPassportListComponent.prototype.navigateToCreateGenericPassport = function () {
        // console.log('create passport');
        this._router.navigate([this.parentModuleName + ("/zones/" + this.zoneId + "/generic-passports/new")]);
    };
    GenericPassportListComponent.prototype.removeTag = function (tag, event) {
        event.stopPropagation();
        event.preventDefault();
        this.tags.splice(this.tags.indexOf(tag), 1);
        this.loadRecords();
    };
    GenericPassportListComponent.prototype.removeCategoryTag = function (tag, event) {
        event.stopPropagation();
        event.preventDefault();
        this.categoryTags.splice(this.categoryTags.indexOf(tag), 1);
        this.loadRecords();
    };
    GenericPassportListComponent.prototype.loadRecords = function (page, noSearch) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isRecordsLoaded = false;
                options = this.getLoadRecordsOptions(page, noSearch);
                this._service.getGenericPassports(this.zoneId, options).subscribe(function (res) {
                    var pageProps = _this.paginationService.unmarshall(res);
                    _this.currentPage = pageProps.currentPage;
                    _this.pageCount = pageProps.pageCount;
                    _this.perPage = pageProps.perPage;
                    _this.totalCount = pageProps.totalCount;
                    //   console.log( this.count +' '+this.perpage);
                    //  this.alreadyLoadedIndexes = [];
                    _this.records = res.json().map(function (item) { return new GenericPassport(item); });
                    _this.isRecordsLoaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    GenericPassportListComponent.prototype.getLoadRecordsOptions = function (page, noSearch) {
        var options = [
            ['expand', 'materials,category,tags']
        ];
        if (this.isStringLengthMoreThan(this.search.value) && !noSearch) {
            options.push(['search', this.search.value]);
        }
        options.push(['page', page]);
        return options;
    };
    GenericPassportListComponent.prototype.loadNextPage = function (event) {
        var _this = this;
        if (this.totalCount <= 20
            || this.totalCount === this.records.length
            || event.end !== this.records.length - 1) {
            return;
        }
        this.isLoadingNextPage = true;
        this.isInfiniteLoadingPageSize = true;
        this._service.getWithArray(this.getLoadRecordsOptions(++this.currentPage)).subscribe(function (res) {
            var headers = res.headers;
            _this.pageCount = Number(headers.get('X-Pagination-Page-Count')) || 1;
            _this.perPage = Number(headers.get('X-Pagination-Per-Page')) || res.json().length;
            _this.totalCount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length;
            res.json().map(function (item) {
                _this.records = _this.records.concat([new GenericPassport(item)]);
            });
            _this.isLoadingNextPage = false;
        });
    };
    GenericPassportListComponent.prototype.openNomenclatureDialog = function (materialList) {
        var _this = this;
        // MdDialogConfig
        var dialogRef = this.dialog.open(ViewNomenclatureDialogComponent, {
            height: '80%',
            width: '80%',
            position: {}
        });
        materialList.blur();
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.tags.push(result);
                // this.loadRecords();
            }
        });
    };
    GenericPassportListComponent.prototype.openCategoryDialog = function (categorie) {
        var _this = this;
        categorie.blur();
        var dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
            height: '80%',
            width: '80%',
            position: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.categoryTags.push(result);
                // this.loadRecords();
            }
        });
    };
    GenericPassportListComponent.prototype.pageChanged = function (page) {
        this._service.setListPage(page);
        this.loadRecords(page, !this.isStringLengthMoreThan(this.search.value));
    };
    GenericPassportListComponent.prototype.deleteRecord = function (record) {
        var _this = this;
        //  console.log('delete that shit ');
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                text: this._confirmDeletionText
            }
        }).afterClosed().subscribe(function (result) {
            if (result) {
                //  console.log(result);
                record.destroy().map(function () { return true; }).subscribe(function (deleted) {
                    if (deleted) {
                        _this.records.splice(_this.records.indexOf(record), 1);
                    }
                });
            }
        });
    };
    GenericPassportListComponent.prototype.openRecord = function (record) {
        this._router.navigate([this.parentModuleName + ("/zones/" + this.zoneId + "/generic-passports"), record.id]);
    };
    return GenericPassportListComponent;
}());
export { GenericPassportListComponent };
