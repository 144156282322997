 import { Component, Input } from '@angular/core';
 import { MdDialogRef } from '@angular/material';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
 
 @Component({
   moduleId: module.id,
   selector: 'manufacturer-generic',
   templateUrl: 'manufacturer-generic-dialog.component.html',
 })
 export class ManufacturerGenericComponent {
    
    public passportType = 'MANUFACTURER';
    public radioBtnArray = [
        {
            name: 'DIALOG_PASSPORT_TYPE_MANUFACTURER',
            value: 'MANUFACTURER'
        }
    ];
   constructor(
    public _dialogRef: MdDialogRef<any>,
    private currentUserService: CurrentUserService
   ) {
    if(this.currentUserService.isSpecialAdmin) {
        this.passportType = 'GENERIC';
        this.radioBtnArray.unshift({
            name: 'DIALOG_PASSPORT_TYPE_GENERIC',
            value: 'GENERIC'
        });
    }
   }

    close() {
        this._dialogRef.close({ isValidated: false});
    }

    onValidate() {
        this._dialogRef.close({ isValidated: true, value: this.passportType })
    }

    onCancel() {
        this._dialogRef.close({ isValidated: false});
    }
 
 }
 