import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl, ValidationErrors} from '@angular/forms';
import {ActiveRecord} from '../../../../shared/models/active-record.class';
import {Observable} from 'rxjs/Observable';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';

@Component({
  selector: 'passport-multiple-field-input',
  templateUrl: './passport-multiple-field-input.component.html',
  styleUrls: ['./passport-multiple-field-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PassportMultipleFieldInputComponent implements OnInit, OnDestroy {


  disabled = false;
  @Input() records: any[];
  @Input() placeholder = '';
  @Input() recordNameField = 'name';
  @Input() recordNameFunc = this.getFieldNameDefault;
  @Input() recordDelete = this.deleteRecordDefault;
  @Input() control: FormControl;
  @Input() addRecordObservable: (name: string) => Observable<any> = this.addRecordDefault;
  @Input() showFirstRecordAsText = this.disabled;
  @Input() errorMessage = '';
  @Input() onValidationErrorFunction: (e: ValidationErrors) => void = this.errorHandleDefault;
  @Input() isOnAddErrorErrorMessageShown = false;
  @Input() onAddingErrorFunction: (e: any) => void = this.emptyFunction;
  @Input() inputSize;
  @ViewChild('input') input: ElementRef;
  @Output() onDeleteSuccess = new EventEmitter();
  isLoading = false;
  deletionSet = new Set();

  @Input('disabled') set setDisabled(state: boolean) {
    this.disabled = state;
    if (this.control) {
      state ? this.control.disable() : this.control.enable();
    }
  }

  constructor(private notificationService: LogNotificationService) { }

  ngOnInit() {
    if (!this.control) {
      this.control = new FormControl({value: '', disabled: this.disabled});
    }
    if (!this.inputSize && this.inputSize !== 0) {
      this.inputSize = this.disabled ? 1 : 6;
    }
    if (this.isOnAddErrorErrorMessageShown) {
      this.onAddingErrorFunction = this.errorHandleDefault;
    }
  }

  ngOnDestroy() {}

  getFieldNameDefault(record) {
    if (this.isObject(record)) {
      return record[this.recordNameField];
    } else {
      return record;
    }
  }

  private isObject(record) {
    return typeof record === 'object' && !!record;
  }

  deleteRecordDefault(model: any) {
   if (model instanceof ActiveRecord && model.id) {
     this.deletionSet.add(model.id);
     model.destroy().subscribe(() => {
       this.records = this.records.filter(item => !this.isObject(item) || item.id !== model.id);
       this.deletionSet.delete(model.id);
       this.onDeleteSuccess.emit(model);
     }, () => this.deletionSet.delete(model.id));
   } else {
     this.records = this.records.filter(item => item !== model);
     this.onDeleteSuccess.emit(model);
   }
  }

  addRecordDefault(name: string) {
     return  Observable.of(name);
  }

  changeRecord() {
    const value = this.control.value;
    if (!value) {
      return;
    } else if (!value.trim()) {
      this.resetInput();
    } else if (this.control.valid) {
      this.addRecordToList(value);
    } else {
      this.onValidationErrorFunction(this.control.errors);
    }
  }

  addRecordToList(name: string) {
    this.isLoading = true;
      this.addRecordObservable(name).subscribe(data => {
          this.records.push(data);
          this.resetInput();
          this.isLoading = false;
        }, (e) => {
          this.onAddingErrorFunction(e);
          this.isLoading = false;
        }
      );
  }

  errorHandleDefault(e) {
    this.notificationService.error(this.errorMessage);
  }

  private resetInput() {
    this.control.setValue('', {emitEvent: false});
  }

  showValuesAsChip() {
    return !this.showFirstRecordAsText || this.records.length > 1;
  }

  focusInput() {
    if (this.input) {
      this.input.nativeElement.focus();
    }
  }

  preventOnEnterEventBubble(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      if (this.input) {
        this.changeRecord();
      }
    }
  }

  emptyFunction() {}
}
