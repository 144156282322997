/**
 * Created by Aleksandr C. on 21.02.17.
 */
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class GlobalEventsManager {

  public _showNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public _navItemSource = new BehaviorSubject<number>(0);

  public chatUserClicked$ = new EventEmitter<number>();

  public compilerDataUpdated = new EventEmitter<null>();
  public compilerDataBeenUpdated = false;

  public circularSignatureClick = new EventEmitter<null>();

  constructor() {
    console.log('Global events manager inited');
  }

  showNavBar(ifShow: boolean) {
    this._showNavBar.next(ifShow);
  }

}
