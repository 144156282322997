/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-warning-confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material";
import * as i3 from "../../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i4 from "../../../../../shared/read-only/form-elems-hidden.directive";
import * as i5 from "../../../../../shared/read-only/read-only.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./admin-warning-confirm-dialog.component";
var styles_AdminWarningConfirmDialogComponent = [i0.styles];
var RenderType_AdminWarningConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminWarningConfirmDialogComponent, data: {} });
export { RenderType_AdminWarningConfirmDialogComponent as RenderType_AdminWarningConfirmDialogComponent };
export function View_AdminWarningConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "section", [["class", "admin-warning-confirm-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h1", [["class", "admin-warning-confirm-dialog__label"], ["md-dialog-title", ""]], [[2, "mat-dialog-title", null]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MdPrefixRejector, [[2, i2.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(3, 16384, null, 0, i2.MdDialogTitle, [], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "admin-warning-confirm-dialog__message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "admin-warning-confirm-dialog__message-decoration border-primary-color primary-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["!"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [["class", "admin-warning-confirm-dialog__message-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 16, "div", [["class", "button-group__list admin-warning-confirm-dialog__button-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 7, "button", [["class", "button-group__item admin-warning-confirm-dialog__button admin-warning-confirm-dialog__button--confirm"], ["md-button", ""], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm.emit(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i1.ɵdid(13, 16384, null, 0, i2.MdPrefixRejector, [[2, i2.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(14, 180224, null, 0, i2.MdButton, [i1.ElementRef, i1.Renderer, i2.FocusOriginMonitor], null, null), i1.ɵdid(15, 16384, null, 0, i2.MdButtonCssMatStyler, [], null, null), i1.ɵdid(16, 16384, null, 0, i2.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(17, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i1.ɵted(18, 0, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(20, 0, null, null, 7, "button", [["class", "button-group__item admin-warning-confirm-dialog__button admin-warning-confirm-dialog__button--cancel"], ["md-button", ""], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm.emit(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i1.ɵdid(21, 16384, null, 0, i2.MdPrefixRejector, [[2, i2.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(22, 180224, null, 0, i2.MdButton, [i1.ElementRef, i1.Renderer, i2.FocusOriginMonitor], null, null), i1.ɵdid(23, 16384, null, 0, i2.MdButtonCssMatStyler, [], null, null), i1.ɵdid(24, 16384, null, 0, i2.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(25, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i1.ɵted(26, 0, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.warningLabel; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.text; _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 14).disabled; var currVal_4 = true; var currVal_5 = true; var currVal_6 = ((i1.ɵnov(_v, 17).readOnlyService == null) ? null : i1.ɵnov(_v, 17).readOnlyService.readOnly); _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("Ok")); _ck(_v, 18, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 22).disabled; var currVal_9 = true; var currVal_10 = true; var currVal_11 = ((i1.ɵnov(_v, 25).readOnlyService == null) ? null : i1.ɵnov(_v, 25).readOnlyService.readOnly); _ck(_v, 20, 0, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = i1.ɵunv(_v, 26, 0, i1.ɵnov(_v, 27).transform("Cancel")); _ck(_v, 26, 0, currVal_12); }); }
export function View_AdminWarningConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-warning-confirm", [], null, null, null, View_AdminWarningConfirmDialogComponent_0, RenderType_AdminWarningConfirmDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.AdminWarningConfirmDialogComponent, [i2.MdDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminWarningConfirmDialogComponentNgFactory = i1.ɵccf("app-admin-warning-confirm", i7.AdminWarningConfirmDialogComponent, View_AdminWarningConfirmDialogComponent_Host_0, { text: "text", warningLabel: "warningLabel" }, { confirm: "confirm" }, []);
export { AdminWarningConfirmDialogComponentNgFactory as AdminWarningConfirmDialogComponentNgFactory };
