/**
 * Created by aleksandr on 16.05.17.
 */

 import 'jsplumb';
 import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ReflectiveInjector, ViewChild} from '@angular/core';
 import { Observable } from 'rxjs/Observable';
 import { Ecosystem } from '../../../models/ecosystem.class';
 import { ActivatedRoute, Router } from '@angular/router';
 import { EcosystemStep } from '../../../models/ecosystem-step.class';
 import { ECOSYSTEM_STEP_TYPE } from '../../../values/ecosystem-step-type.const';
 import { MdDialog } from '@angular/material';
 import { ECOSYSTEM_STATUS, ECOSYSTEM_STATUS_VIEW, EEcosystemResend } from '../../../values/ecosystem-status.const';
 import { EcosystemDescriptionDialogComponent } from '../../../dialogs/ecosystem-description/ecosystem-description.component';
 import { EcosystemFileDialogComponent } from '../../../dialogs/ecosystem-file/ecosystem-file.component';
 import { EcosystemOfferDialogComponent } from '../../../dialogs/ecosystem-offer-dialog/ecosystem-offer-dialog.component';
 import { DepositDetailsDialogComponent
   } from '../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
 import { TransformDetailsDialogComponent
   } from '../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
 import { StakeholderDetailsDialogComponent
 } from '../../../../../entities/stakeholder/dialogs/stakeholder-details-dialog/stakeholder-details-dialog.component';
 import { VPDetailsDialogComponent } from '../../../../../entities/virgin-product/dialogs/vp-details-dialog.component';
 import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
 import { EntityViewerComponent } from '../../../../../shared/base/enitity-viewer.component';
 import { NeedDetailsDialogComponent } from '../../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
 import { EcosystemService } from '../../../../../entities/ecosystem/services/ecosystem.service';
 import { DepositOrder } from '../../../models/deposit-order.class';
 import { TransformOrder } from '../../../models/transform-order.class';
 import { ECOSYSTEM_ORDER_STATUS } from '../../../values/ecosystem-order-statuses.const';
 import {
   EcosystemIndicatorsDialogComponent
 } from '../../../../../dashboards/ecosystem-manager/dialogs/ecosystem-indicators/ecosystem-indicators.component';
 import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
 import { EcosystemDeletionDialogComponent } from '../../../dialogs/ecosystem-deletion/ecosystem-deletion.component';
 import { TranslateService } from '@ngx-translate/core';
 import {EcosystemPrefillService} from '../../../services/ecosystem-prefill.service';
 import {EcosystemCompilerSharedActionsService} from '../../../services/ecosystem-compiler-shared-actions.service';
 import {IEcosystemStepSummary} from '../../../interfaces/ecosystem-step-summary.interface';
import { EcosystemPrefillCommandService } from '../../../../../dashboards/ecosystem-manager/services/ecosystem-prefill-command.service';
 
 @Component({
   moduleId: module.id,
   selector: 'ecosystem-edit',
   templateUrl: 'ecosystem-edit.component.html',
   providers: [{provide: EcosystemPrefillService, useClass: EcosystemPrefillService}
     , {provide: EcosystemCompilerSharedActionsService, useClass: EcosystemCompilerSharedActionsService},
    {provide: EcosystemPrefillCommandService, useClass: EcosystemPrefillCommandService}]
 })
 export class EcosystemEditComponent extends EntityViewerComponent<Ecosystem> implements  OnInit, OnDestroy {
   public static ICON_GROUP_KEY: {[key in 'ADD_MESSAGE'] : number} = {ADD_MESSAGE: 1};
   public ICON_GROUP_KEY = EcosystemEditComponent.ICON_GROUP_KEY;
   public ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
   public ECOSYSTEM_SUB_STATUS = EEcosystemResend;
   public ECOSYSTEM_STATUS_VIEW = ECOSYSTEM_STATUS_VIEW;
   public ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP_TYPE;
   public plumb: any; // jsPlumbInstance;
 
   public ecosystem: Ecosystem;
   public steps: EcosystemStep[];
   public activeStep: EcosystemStep = null;
   public openChat = false;
   public isCompilerExpanded = true;
   public _isCompilerExtended = false;
   private isNameInFocus = false;
   private prefillSubscription;
 
   buttonControlGroup: {isExist: () => boolean
     , onClick: Function, name: string
     , iconClass?: string
     , iconGroupKey?: typeof EcosystemEditComponent.ICON_GROUP_KEY[keyof typeof EcosystemEditComponent.ICON_GROUP_KEY]}[] = [
     {
       isExist: () => this.ecosystem.canDeleteByStatus,
       onClick: () => this.openDeletionMessage(),
       name: 'Delete ecosystem',
       iconClass: 'icon icon--deletion-bin'
     },  {
       isExist: () => [ECOSYSTEM_STATUS.IN_PROCESS, ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION].includes(this.ecosystem.status),
       onClick: () => this.unvalidateEcosystem(),
       name: 'Stop ecosystem',
       iconClass: 'icon--s icon--close-grey'
     /* }, {
       isExist: () => this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS && !this.ecosystem.isOrdersConfirmed,
       onClick: () => this.validateOrdersManually(),
       name: 'Confirm all manually',
       iconClass: 'icon--l icon--ok-grey ecosystem-edit__button-title-icon--offset-top' */
     },{
       isExist: () => this.ecosystem.canStopOrEdit,
       onClick: () => this.unpublishEcosystem(),
       name: 'Stop ecosystem',
       iconClass: 'icon--s icon--close-grey'
     }, {
       isExist: () => this.ecosystem.status === this.ECOSYSTEM_STATUS.DRAFT,
       onClick: () => this.saveDraft(),
       name: 'Save as draft',
       iconClass: 'icon icon--ok-grey'
     }, {
       isExist: () => this.ecosystem.status === this.ECOSYSTEM_STATUS.DRAFT,
       onClick: () => this.publishEcosystem(),
       name: 'Publish ecosystem',
       iconClass: 'icon icon--ecosystem-publish-grey'
     },  {
       isExist: () => [ECOSYSTEM_STATUS.CREATED, , ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION].includes(this.ecosystem.status),
       onClick: () => this.validateOrdersManually(),
       name: 'Validate all manually',
       iconClass: 'icon--l icon--ok-grey ecosystem-edit__button-title-icon--offset-top'
     }, {
       isExist: () => [ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, ECOSYSTEM_STATUS.WAITING_FOR_QUOTE].includes(this.ecosystem.status),
       onClick: () => this.validateOrdersManually(),
       name: 'Validate offer manually',
       iconClass: 'icon--l icon--ok-grey ecosystem-edit__button-title-icon--offset-top'
     }, {
       isExist: () => [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(this.ecosystem.status)  && !this.ecosystem.isAskForValidationAgain,
       onClick: () => this.askForValidation(),
       name: 'Ask for validation',
       iconClass: 'icon--portrait icon--ecosystem-message'
     }, {
       isExist: () => [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(this.ecosystem.status)  && this.ecosystem.isAskForValidationAgain,
       onClick: () => this.askForValidation(),
       name: 'Ask for validation again',
       iconGroupKey: EcosystemEditComponent.ICON_GROUP_KEY.ADD_MESSAGE
     }, {
       isExist: () => [ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, ECOSYSTEM_STATUS.WAITING_FOR_QUOTE].includes(this.ecosystem.status)
         && this.ecosystem.ordersBooked && !this.ecosystem.isAskForOfferValidationAgain,
       onClick: () => this.askForOfferValidation(),
       name: 'Ask for offer validation',
       iconClass: 'icon--portrait icon--ecosystem-message'
     }, {
       isExist: () => [ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, ECOSYSTEM_STATUS.WAITING_FOR_QUOTE].includes(this.ecosystem.status)
         && this.ecosystem.isAskForOfferValidationAgain,
       onClick: () => this.askForOfferValidation(),
       name: 'Ask for offer validation again',
       iconGroupKey: EcosystemEditComponent.ICON_GROUP_KEY.ADD_MESSAGE
     }, /*{
      
       isExist: () => this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS && this.ecosystem.ordersConfirmed(),
       onClick: () => this.validateOrdersManually(),
       name: 'Confirm offer manually',
       iconClass: 'icon--l icon--ok-grey ecosystem-edit__button-title-icon--offset-top'
     },*/ {
       isExist: () => this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS
         && !this.ecosystem.isOrdersConfirmed,
       onClick: () => this.askForValidation(),
       name: 'Ask for confirmation again',
       iconGroupKey: EcosystemEditComponent.ICON_GROUP_KEY.ADD_MESSAGE
     }, {
       isExist: () => this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS
         && this.ecosystem.ordersConfirmed() && this.ecosystem.isAskForOfferConfirmationAgain,
       onClick: () => this.askForValidation(),
       name: 'Ask for offer confirmation again',
       iconGroupKey: EcosystemEditComponent.ICON_GROUP_KEY.ADD_MESSAGE
     }
   ];
 
  @ViewChild('activeStepComponent') activeStepComponent: {
    trySave(): Observable<EcosystemStep> | null;
    load(): void;
    prohibitSave?(isProhibit: boolean);
  };

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              public dialog: MdDialog,
              public translate: TranslateService,
              private zone: NgZone,
              private ecosystemService: EcosystemService,
              private notificationService: LogNotificationService,
              private ngZone: NgZone,
              private prefillService: EcosystemPrefillService,
              private ecosystemCompilerSharedActionsService: EcosystemCompilerSharedActionsService,
) {
    super(route, router);
    // ReflectiveInjector.resolveAndCreate([{useValue: this, provide: EcosystemEditComponent}]);
  }

  ngOnInit() {
    super.ngOnInit();
    this.ecosystemCompilerSharedActionsService.ecosystem = this.ecosystem;
    this.initPrefill();
    this.prefillSubscription = this.ecosystem.onFetch$.subscribe(() => {
        this.ecosystemCompilerSharedActionsService.ecosystem = this.ecosystem;
        this.updatePrefill();
      });
  }

  private isEnableAutoPrefill() {
    return [ECOSYSTEM_STATUS.DRAFT, ECOSYSTEM_STATUS.CREATED, ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION].includes(this.ecosystem.status);
  }

  private initPrefill() {
    if (!this.prefillService.isPrefillInited() && this.isEnableAutoPrefill()) {
      this.ecosystem.reload();
    }
  }

  private updatePrefill() {
    if (this.isEnableAutoPrefill()) {
      this.prefillService.prefillAndChangeByEcosystem(this.ecosystem, false);
      this.ecosystemCompilerSharedActionsService.onPrefillFinished.next();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.prefillSubscription) {
      this.prefillSubscription.unsubscribe();
    }
  }

  onExistEntity(entity: Ecosystem): void {
    this.ecosystem = entity;
    this.steps = this.ecosystem.steps;
  }

  onNewEntity(): void {
    this.ecosystem = new Ecosystem({});
  }

  onFocusChanged(focus: boolean) {
    this.isNameInFocus = focus;
    if (!focus) {
      this.saveDraft();
    }
  }

  toggleExpand() {
    this.isCompilerExpanded = !this.isCompilerExpanded;
  }

  trySaveActiveStep(): Observable<any> {
    if (this.activeStepComponent && this.activeStepComponent.trySave) {
      return this.activeStepComponent.trySave();
    } else {
      return Observable.of(null);
    }
  }

  tryProhibitActiveStepSaving() {
    if (this.activeStepComponent && this.activeStepComponent.prohibitSave) {
      this.activeStepComponent.prohibitSave(true);
    }
  }

  tryLoadActiveStep() {
    if (this.activeStepComponent && this.activeStepComponent.load) {
      this.activeStepComponent.load();
    }
  }

  reload(observable: Observable<any>) {
    observable.subscribe(() => {
      // put active step back with possibly new data
      this.tryLoadActiveStep();

      // put ecosystem back with possibly new data
      this.ecosystem.fetch().subscribe();
    });
  }

  saveDraft() {
    this.reload(
      this.ecosystem.save().do(
        result => {
          this.ecosystem.id = result.id;
        }
      )
    );
    this.notificationService.success('Ecosystem created as draft', 3000, true);
  }

  publishEcosystem() {
    this.reload(
      this.ecosystem.publish().do(
        result => {
          this.ecosystem.id = result.id;
        }
      )
    );
  }

  unpublishEcosystem() {
    this.reload(
      this.ecosystem.unpublish()
    );
  }

  deleteEcosystem() {
    this.tryProhibitActiveStepSaving();
    this.trySaveActiveStep()
      .flatMap(() => this.ecosystem.destroy())
      .subscribe();
  }

  navigateToIndex() {
    this.router.navigate(['ecosystem']);
  }

  unvalidateEcosystem() {
    this.reload(this.ecosystem.unvalidate());
  }

  validateOrdersManually() {
    if (this.validateLinkDateAndShowWarning()) {
      this.reload(this.ecosystem.validateAll());
    }
  }

  private validateLinkDateAndShowWarning() {
    if (!this.ecosystem.steps.every(step => !!step.linked_date)) {
      this.notificationService.warn('not all dates are specified');
      return false;
    }
    return true;
  }

  private validateConfirmAndShowError() {
    if ([ECOSYSTEM_STATUS.IN_PROCESS].includes(this.ecosystem.status) && !this.ecosystem.ordersWaitingForConfirmation()) {
      this.notificationService.error( 'Some dates have not passed', null, true);
      return false;
    }
    return true;
  }


  askForValidation() {
   if (this.validateConfirmAndShowError()) {
     this.validateLinkDateAndShowWarning();
     this.reload(this.ecosystemService.askForValidationAll(this.ecosystem.id));
   }
  }

  askForOfferValidation() {
    this.reload(this.ecosystem.ecosystem_offer.publish());
  }

  openIndicators() {
    this.ngZone.run(() => {
      this.dialog.open(EcosystemIndicatorsDialogComponent, {
        ...DIALOG_THEME.NOMENCLATURE_PRIMARY,
        data: {
          ecosystem: this.ecosystem,
          updateSteps: (steps: IEcosystemStepSummary[]) => this.saveConfirmIndicators(steps)
        }
      });
    });
  }

  saveConfirmIndicators(steps: IEcosystemStepSummary[]) {
    steps.forEach(step => {
      const unconfirmedStep = this.ecosystem.steps.find(unconfirmStep => unconfirmStep.id === step.step_id);
      if (!!unconfirmedStep) {
      unconfirmedStep.confirmed_quantity = step.confirmed_quantity;
      unconfirmedStep.confirmed_price = step.confirmed_price;
      }
    });
  }

  openDescription() {

    this.zone.run(() => {
        const dialogRef = this.dialog.open(EcosystemDescriptionDialogComponent, {
          ...DIALOG_THEME.ALERT_PRIMARY,
          data: {
            view_description: this.ecosystem.description
          }
        });
        dialogRef.afterClosed().subscribe((description: string) => {
          if (description) {
            this.ecosystem.description = description;
            this.ecosystem.save().subscribe();
          }
        });
      });
  }

  openFiles() {
    this.zone.run(() => {
        this.dialog.open(EcosystemFileDialogComponent, {
          ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
          data: {
            isOpenFiles: true,
            ecosystem: this.ecosystem
          }
        });
      }
    );
  }

  openDeletionMessage() {
    this.zone.run(() => {
        this.dialog.open(EcosystemDeletionDialogComponent, {
          disableClose: true,
          ...DIALOG_THEME.BELOW_NAVBAR,
          data: {
            deleteEcosystem: this.deleteEcosystem.bind(this),
            navigateToIndex: this.navigateToIndex.bind(this)
          }
        });
      }
    );
  }

  viewOffer(step: EcosystemStep) {
    this.reload(
      this
        .dialog
        .open(EcosystemOfferDialogComponent, {
          position: {
            top: '123px',
          },
          height: '84%',
          data: {
            ecosystem: this.ecosystem,
          }
        })
        .afterClosed()
    );
  }

  viewDetails(type: number, id: number) {
    const dialogComponent = ({
      [ECOSYSTEM_STEP_TYPE.DEPOSIT]: DepositDetailsDialogComponent,
      [ECOSYSTEM_STEP_TYPE.TRANSFORM]: TransformDetailsDialogComponent,
      [ECOSYSTEM_STEP_TYPE.STAKEHOLDER]: StakeholderDetailsDialogComponent,
      [ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT]: VPDetailsDialogComponent,
      [ECOSYSTEM_STEP_TYPE.NEED]: NeedDetailsDialogComponent,
    })[type];

    if (dialogComponent) {
      this.dialog.open(dialogComponent, {
        position: {
          top: '123px',
        },
        data: {
          id: id,
          readOnly: true,
        }
      });
    }
  }

  toggleCompilerExtendedView(value: boolean) {
    this._isCompilerExtended = value;
  }

  onStepChange(step) {
    const item = this.ecosystem.steps.find(itemStep => itemStep.id === step.id);
    if (item) {
      Object.assign(item, step);
      item.updateOverlay();
    }
  }

  substituteDefaultPrefix() {
    return !this.isNameInFocus && this.ecosystem.name.match(/^For\s/);
  }

}
