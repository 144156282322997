import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ECarbonFootprintReportType } from '../../../../../../dashboards/admin/values/carbon-footprint-status.enum';
import { ReportBase } from '../report-base/report-base.class';
var CarbonFootprintComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CarbonFootprintComponent, _super);
    function CarbonFootprintComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CarbonFootprintComponent.prototype.ngOnInit = function () {
        this.reportTypes = [];
        if (this.reports.product_stage) {
            this.reportTypes.push(ECarbonFootprintReportType.PRODUCT_STAGE);
        }
        if (this.reports.product_and_construction) {
            this.reportTypes.push(ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION);
        }
        if (this.reports.embodied_carbon) {
            this.reportTypes.push(ECarbonFootprintReportType.EMBODIED_CARBON);
        }
        if (this.reports.ic_component) {
            this.reportTypes.push(ECarbonFootprintReportType.IC_COMPONENT);
        }
        if (this.reports.ic_construction) {
            this.reportTypes.push(ECarbonFootprintReportType.IC_CONSTRUCTION);
        }
    };
    return CarbonFootprintComponent;
}(ReportBase));
export { CarbonFootprintComponent };
