<div class="carbon-footprint">
    <section class="carbon-footprint__head">
        <div class="carbon-footprint__head-container">
            <h2 class="carbon-footprint__head-title">{{ 'Carbon footprint' | translate }}</h2>
            <h2 class="carbon-footprint__head-type"> - {{ getTypeTitleText() | translate}}
             <sub *ngIf="getIcSubItem()">{{ getIcSubItem()}}</sub>
            </h2>
        </div>
      <report-site-list
        [sites]="reports.sites"
        [tags]="reports.tags"
        [createdByFile] = "reports.created_by_file"
      ></report-site-list>
      <div class="carbon-footprint__head-description-block">
        <div class="carbon-footprint__head-description-block-title-row" (click)="toggleDescriptionVisibility()"
        [ngClass]="{ 'carbon-footprint__head-description-block-title-row-open': isFootprintDescriptionVisible}">
          <div class="carbon-footprint__head-description-block-title-row">
            <img class="carbon-footprint__head-description-block-question" src="../assets/svg/carbon-footprint-report-question--icon.svg">
            <span class="carbon-footprint__head-description-title">{{ getTypeDescriptionTitleText() | translate}}</span>
          </div>
          <img class="carbon-footprint__head-description-toggle" [src]="getDescriptionToggleImg()">
        </div>
        <div class="carbon-footprint__head-description-text" *ngIf="isFootprintDescriptionVisible">
          <p [innerHTML]=" getCarbonFootprintDescriptionText() | translate"></p>
        </div>
      </div>
    </section>
    <section *ngIf="!isEmptyProductCategory()" class="carbon-footprint__body" [ngClass]="{'carbon-footprint__body--justify-content': isReportTypeIcConstruction()}">
      <div class="carbon-footprint__body-left-block">
        <div class="carbon-footprint__body-left-block-row">
          <h3 class="carbon-footprint__body-left-block-title carbon-footprint__body-left-block-title-uppercase">{{ getTypeOfAllDepositsText()}}</h3>
          <h3 *ngIf="!isReportTypeIcConstruction()" class="carbon-footprint__body-left-block-title"> - {{ 'all deposits' | translate }}</h3>
        </div>
        <div class="carbon-footprint__body-left-block-title-hr"></div>
        <span [ngClass]="{'carbon-footprint__body-left-block-living-area--construction': isReportTypeIcConstruction() }"
          *ngIf="isReportTypeOfIcConstructionOrComponent()"
          class="carbon-footprint__body-left-block-living-area">
            {{ 'Living area' | translate }}:
          <span class="carbon-footprint__body-left-block-living-area-sqm"> {{ this.aggregateData.living_area_total}}
          </span> {{ 'sqm' | translate }}
        </span>
        <span [ngClass]="{'carbon-footprint__body-left-block-living-area--construction': isReportTypeIcConstruction() }"
          *ngIf="isReportTypeIcConstruction()" class="carbon-footprint__body-left-block-living-area">
          {{ 'Ic' | translate }} <sub>{{ 'chantier' | translate }}</sub>:
          <span class="carbon-footprint__body-left-block-living-area-sqm">
            {{ this.reports.ic_construction_co2 }}
          </span> kg Co<sub>2</sub> eq./{{ 'sqm' | translate }}
        </span>
        <div class="carbon-footprint__body-left-block-circle">
          <p class="carbon-footprint__body-left-block-circle-amount">{{ getCarbonFootprintCircleIndicatorData().value | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}</p>
          <p *ngIf="!isReportTypeOfIcConstructionOrComponent()" class="carbon-footprint__body-left-block-circle-text"
          [ngClass]="{'carbon-footprint__body-left-block-circle-text--spanish': isCurrentLanguageSpanish() }">{{getCarbonFootprintCircleIndicatorData().unit | translate}}  CO<sub>2</sub> eq.</p>
          <p 
            *ngIf="isReportTypeOfIcConstructionOrComponent()" 
            class="carbon-footprint__body-left-block-circle-text"
          >{{getCarbonFootprintCircleIndicatorData().unit | translate}}  CO<sub>2</sub> eq. / {{'sqm' | translate}}</p>
          <div class="carbon-footprint__body-left-block-circle-prompt">
            <label class="form-group__label"
                   md-tooltip="{{ 'CARBON_DEPOSIT_CONSIDERED_TOOLTIP' | translate }}"
            >
              <i class="form-group__label--question">?</i>
            </label>
          </div>
        </div>
        <div class="carbon-footprint__body-left-block-text">
          <p>
            <span class="carbon-footprint__body-left-block-text-value" >
              <span [ngClass]="{'carbon-footprint__partial-data': shouldDisplayPartialData()}"> {{ getTotalConsideredDepositsByReportType() }}</span> / {{ aggregateData.total_deposit_count }}
            </span>
            {{ 'deposits considered' | translate }}
          </p>
          <p>
            <span class="carbon-footprint__body-left-block-text-value">
              <span [ngClass]="{'carbon-footprint__partial-data': shouldDisplayPartialData()}">{{ getTotalConsideredWeightByReportType() | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}</span>
              /
              {{aggregateData.total_weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
            </span>
            {{ 'tons of deposits' | translate }}
          </p>
          <div class="carbon-footprint__body-left-block-link">
            <i class="icon blue-eye-icon"></i>
            <a class="carbon-footprint__body-left-block-link-item" (click)="goToArchive()">
              {{ 'See error report for more details' | translate }}
            </a>
          </div>
          <div class="carbon-footprint__body-left-block-link" *ngIf="shouldDisplayPartialData()" 
              [md-tooltip]="getTranslation()">
            <i class="icon icon--exclamation-point-red"></i>
            <div class="carbon-footprint__partial-data-wrapper">
              <div class="carbon-footprint__partial-data">{{ 'Missing data' | translate}}</div>
              <div class="carbon-footprint__partial-data">{{'See pdf for more details' | capitalize | translate}}</div>
            </div>
           
          </div>
        </div>
      </div>
      <div *ngIf="!isReportTypeIcConstruction()" class="carbon-footprint__body-right-block">
        <bar-chart
          [reportType]="reportType"
          [firstFootprint]="filteredAndSortedProductCategory[0]"
          [secondFootprint]="filteredAndSortedProductCategory[1]"
          [thirdFootprint]="filteredAndSortedProductCategory[2]"
        >
        </bar-chart>
      </div>
    </section>
    <span *ngIf="isReportTypeOfIcConstructionOrComponent()" class="carbon-footprint__body-bottom-description">{{ 'FRENCH_RE2020_EQUIVALENT_INDICATORS_TOOLTIP' | translate}}</span>
    <section *ngIf="isEmptyProductCategory()" class="carbon-footprint__body section">
      <h3 class="section__error-title">{{ 'No deposit considered' | translate }}</h3>
    </section>
  </div>
