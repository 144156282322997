import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { Injectable } from '@angular/core';
import { Ecosystem } from '../../../dashboards/ecosystem-manager/models/ecosystem.class';
import { ILiteral } from 'ng2-adn-common';
import { Response } from '@angular/http';
import { Searchable } from '../../../shared/models/searchable.class';
import { Observable } from 'rxjs/Observable';
import { EcosystemStep } from '../../../dashboards/ecosystem-manager/models/ecosystem-step.class';
import { DepositOrder } from '../../../dashboards/ecosystem-manager/models/deposit-order.class';
import { TransformOrder } from '../../../dashboards/ecosystem-manager/models/transform-order.class';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';

@Injectable()
export class EcosystemService extends ExtendedCrudService {
  protected namespace = 'ecosystems';
  protected namespaceSingular = 'ecosystem';
  protected ModelClass = Ecosystem;

  getClientList(query?: ILiteral): Observable<Searchable[]> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/client_list'), {search: query})
      .map((data: Response) => data.json().map((d: any) => new Searchable(d)))
      .catch(this.onError.bind(this));
  }

  getManagerList(query?: ILiteral): Observable<Searchable[]> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/managers'), {search: query})
      .map((data: Response) => data.json().map((d: any) => new Searchable(d)))
      .catch(this.onError.bind(this));
  }

  getSummary(ecosystemId: number): Observable<EcosystemStep[]> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + `/${ecosystemId}/steps`)
        , {search: {expand: 'summary_details,deposit,transformation'}})
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  saveSummary<T>(ecosystemId: number, summary: SummaryStep[]): Observable<any> {
    return this
    .sendPost(this.getEndpoint(this.namespaceSingular + `/${ecosystemId}/summary`), {steps: summary})
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }

  previewTotals<T>(ecosystemId: number, summary: SummaryStep[]): Observable<any> {
    return this
    .sendPost(this.getEndpoint(this.namespaceSingular + `/${ecosystemId}/preview_totals`), {steps: summary})
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }

  getProcessCount(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/process_count'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getStepOrders(step: EcosystemStep, ecosystemID: number): Observable<any | DepositOrder | TransformOrder[]> {
    return this.sendGet(this.getEndpoint('ecosystem-step/'+String(step.id)),
      {search: {expand: 'orders', ecosystem_id: ecosystemID}})
      .map((data: Response) => {
        let ordersContent = data.json()['orders'];
        if (ordersContent && step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
          return new DepositOrder(ordersContent);
        }else if (ordersContent && step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
          if (ordersContent instanceof Array && ordersContent.length > 0) {
            return ordersContent;
          }
        }
        return [];
      })
      .catch(this.onError.bind(this));
  }

  publishWithDeposit<T>(data: {name: string, need_id: number, deposit_id: number}) {
    return this.sendPost(this.getEndpoint(this.namespaceSingular + `/with_deposit`), data)
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }

  askForValidationAll<T>(id: number) {
    return this.sendPost(this.getEndpoint(this.namespaceSingular + `/ask_all/${id}`))
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  getEcosystemFolderRoot<T>(query = {}) {
    return this.sendGet(this.getEndpoint('ecosystem-folder/root'), query)
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }

  getEcosystemFolderById<T>(id: number, query) {
    return this.sendGet(this.getEndpoint(`ecosystem-folder/${id}`), query)
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }

  getAllFolders<T>() {
    return this.sendGet(this.getEndpoint(`ecosystem-folder/all`))
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }

  createFolder<T>(data) {
    return this.sendPost(this.getEndpoint(`ecosystem-folders`), data)
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }

  updateFolder<T>(id, data) {
    return this.sendPut(this.getEndpoint(`ecosystem-folder/${id}`), data)
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }

  moveFolder<T>(data) {
    return this.sendPost(this.getEndpoint(`ecosystem-folder/move`), data)
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }

  deleteFolder<T>(data) {
    console.log(data)
    return this.sendPost(this.getEndpoint(`ecosystem-folder/delete_all`), data)
    .map((res: Response) => <T> res.json())
    .catch(this.onError.bind(this));
  }
}
