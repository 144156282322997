<div class="control-group" #controlGroup
[@openClose]="{value: controlGroupState,
  params:{margin_bottom: margin_bottom, margin_bottom_initial: margin_bottom_initial}}">


  <div *ngIf="!disableStatus" class="control-group__list control-group__list--components control-group__item-add-aligned" (click)="addNew()">
    <div class="control-group__item-button">
      <md-icon>{{'add' | translate}}</md-icon>
    </div>
    <div class="control-group__item--subtext-material">
      {{'add a component' | translate}}
    </div>
  </div>
  <div class="control-group__list-wrapper-column"
       *ngFor="let item of listCtrl?.controls; let i=index;"
       [formGroup]="item" #components>
    <ul class="control-group__list control-group__list--components"
        (click)="selectCtrl(item, $event, i)"
        [ngClass]="{'control-group__list--selected': item === selectedCtrl, 'control-group__list--not-selected': item !== selectedCtrl}">
      <div class="control-group__list--fields">
        <li class="control-group__item control-group__item--icon">
          <div class="control-group__item-button" *ngIf="isRemoveButtonVisible(item)"
              (click)="removeComponent(i)">
            <md-icon>remove</md-icon>
          </div>
        </li>
        <li class="control-group__item control-group__item--component">
          <span *ngIf="isDefault(item)" class="control-group__item--default-label">
            <i>
              {{ '(misc)' | translate}}
            </i>
          </span>
          <div class="flex align-center" *ngIf="!isDefault(item)" >
            <div class="control-group__label"
                [ngClass]="getLabelClasses(item.get('componentName').value)">{{ 'Component' | translate }}</div>
          </div>
          <md-input-container *ngIf="!isDefault(item)" [floatPlaceholder]="'never'" class="control-group__item--aligned">
            <input
              mdInput
              [value]="getComponentValue(item)"
              formControlName="componentName"
              autocomplete="donotautocomplete"
              placeholder="{{'tray, frame, ...' | translate}}"
              name="name"
            />
          </md-input-container>
        </li>
        <li class="control-group__item control-group__item--component control-group__item--component-select">
            <div class="flex align-center">
              <div class="control-group__label"
                  [ngClass]="getLabelClasses(item.get('componentDemountable').value)">{{ 'Demountability' | translate }}</div>
            </div>
            <div *ngIf="disableStatus" class="control-group__item--component-select-disabled">
              {{getcomponentDemountableValue(item)}}
            </div>
            <div *ngIf="disableStatus" class="control-group__item--component-dots"></div>
            <md-select
            *ngIf="!disableStatus"
            formControlName="componentDemountable"
            class="condition control-group__item--aligned">
            <md-option *ngFor="let type of demountableTypes" [value]="type.value">
              {{ type.viewValue | translate }}
            </md-option>
          </md-select>
        </li>
        <li class="control-group__item control-group__item--component-percent">
          <div class="flex align-center control-group__item--component-percent">
            <div class="control-group__label"
                 [ngClass]="getLabelClasses(item.get('percentageWeight').value)"
            >% {{ 'by weight of the' | translate}} {{'Product' | translate | lowercase}}</div>
          </div>
          <div class="control-group__item--percentage-wrapper">
            <md-input-container class="control-group__item--percentage">
              <input
              mdInput
              autocomplete="off"
              type="number"
              min="0"
              max="100"
              (keypress)="preventLettersAndNumberGreaterThanOneHundred($event)"
              formControlName="percentageWeight">
            </md-input-container> %
          </div>
        </li>
      </div>
      <div class="control-group__list--materials"
      (click)="toggleMaterialVisibility(i, item.get('isMaterialOpen').value, $event)">
        <span class="control-group__list--vr"></span>
        <span class="control-group__list--material-label">
          {{'Materials' | translate}}
        </span>
        <span class="control-group__list--toggle-icon">
          <i [ngClass]="{'icon': true, 
          'arrow-up--icon': !item.get('isMaterialOpen').value, 
          'arrow-down--icon': item.get('isMaterialOpen').value}"></i>
        </span>
      </div>
    </ul>
    
    <div class="control-group__materials-float" [hidden]="item !== selectedCtrl || !item.get('isMaterialOpen').value">
          <div class="form-group__field passport__materials-field" id="width">
            <passport-materials
              (itemsChanged)="dropDownChanged(i, false)"
              [isDisabled]="disableStatus"
              [listCtrl]="item.get('materials')"
              [viewMode]="viewMode"
              [passport]="passport"
            ></passport-materials>
          </div>
      </div>
  </div>
</div>
