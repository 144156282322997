import * as tslib_1 from "tslib";
import { mergeMap } from 'rxjs/operators';
import { DepositResolver } from '../../deposit/resolvers/deposit.resolver';
import { PassportService } from '../../passport/services/passport.service';
import { DepositService } from '../../deposit/services/deposit.service';
var NeedMatchWithDepositResolve = /** @class */ (function (_super) {
    tslib_1.__extends(NeedMatchWithDepositResolve, _super);
    function NeedMatchWithDepositResolve(passportService, _service) {
        var _this = _super.call(this, _service) || this;
        _this.passportService = passportService;
        _this._service = _service;
        _this.query.expand += ',isNoah';
        return _this;
    }
    NeedMatchWithDepositResolve.prototype.resolve = function (route) {
        var _this = this;
        return _super.prototype.resolve.call(this, route).pipe(mergeMap(function (deposit, index) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var p, passport;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!deposit.isNoah) return [3 /*break*/, 3];
                        if (!(deposit.passport.family === 1 /* FAMILY_LOCAL */)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.passportService.duplicateNoah(deposit.passport.id).toPromise()];
                    case 1:
                        p = _a.sent();
                        return [4 /*yield*/, this.passportService.view(p.id, { expand: "category, gtins" }).toPromise()];
                    case 2:
                        passport = _a.sent();
                        deposit.passport = passport;
                        return [2 /*return*/, deposit];
                    case 3: return [2 /*return*/, deposit];
                }
            });
        }); }));
    };
    return NeedMatchWithDepositResolve;
}(DepositResolver));
export { NeedMatchWithDepositResolve };
