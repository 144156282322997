import * as tslib_1 from "tslib";
import { OnInit, Renderer2 } from '@angular/core';
import { ReportBase } from '../../report-base/report-base.class';
var PieChartComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PieChartComponent, _super);
    function PieChartComponent(_renderer) {
        var _this = _super.call(this) || this;
        _this._renderer = _renderer;
        _this.mainColor = '#777';
        _this.degreesInCircle = 360;
        _this.maxPercent = 100;
        return _this;
    }
    PieChartComponent.prototype.ngOnInit = function () {
        this.setStyleForCircle();
    };
    PieChartComponent.prototype.isMoreThenHalf = function () {
        return this.mainValue >= this.maxPercent / 2;
    };
    PieChartComponent.prototype.setStyleForCircle = function () {
        var amountRotateDeg = this.mainValue * (this.degreesInCircle / this.maxPercent);
        this._renderer.setStyle(this.leftSide.nativeElement, 'transform', 'rotate(' + amountRotateDeg + 'deg)');
    };
    return PieChartComponent;
}(ReportBase));
export { PieChartComponent };
