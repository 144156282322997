import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrashIconComponent } from './components/icons/trash-icon.component';
import { RatingCompositionIconComponent } from './components/icons/rating-composition-icon.component';
import { RatingRecyclabilityCompositionIconComponent } from './components/icons/rating-recyclability-icon.component';
import { RatingEnergyIconComponent } from './components/icons/rating-energy-icon.component';
import { RatingWaterStewardshipIconComponent } from './components/icons/rating-water-stewardship-icon.component';
import { RatingSocialFairnessIconComponent } from './components/icons/rating-social-fairness-icon.component';
import { CradleToCradleIconComponent } from './components/icons/cradle-to-cradle-icon.component';
import { LeftArrowIconComponent } from './components/icons/left-arrow-icon.component';
import { RightArrowIconComponent } from './components/icons/right-arrow-icon.component';
import { JumpPageIconComponent } from './components/icons/jump-page-icon.component';
import { PenIconComponent } from './components/icons/pen-icon.component';
import { PassportStatusIconComponent } from './components/icons/passports-status-icon.component';
import { DuplicateIconComponent } from './components/icons/duplicate-icon.component';

@NgModule({
  declarations: [
    TrashIconComponent,
    RatingCompositionIconComponent,
    RatingRecyclabilityCompositionIconComponent,
    RatingEnergyIconComponent,
    RatingWaterStewardshipIconComponent,
    RatingSocialFairnessIconComponent,
    CradleToCradleIconComponent,
    LeftArrowIconComponent,
    RightArrowIconComponent,
    JumpPageIconComponent,
    PenIconComponent,
    PassportStatusIconComponent,
    DuplicateIconComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TrashIconComponent,
    RatingCompositionIconComponent,
    RatingRecyclabilityCompositionIconComponent,
    RatingEnergyIconComponent,
    RatingWaterStewardshipIconComponent,
    RatingSocialFairnessIconComponent,
    CradleToCradleIconComponent,
    LeftArrowIconComponent,
    RightArrowIconComponent,
    JumpPageIconComponent,
    PenIconComponent,
    PassportStatusIconComponent,
    DuplicateIconComponent
  ]
})
export class IconsModule { }
