/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./residual-value.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./residual-value-report/residual-value-report.component.ngfactory";
import * as i3 from "./residual-value-report/residual-value-report.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./residual-value.component";
var styles_ResidualValueComponent = [i0.styles];
var RenderType_ResidualValueComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResidualValueComponent, data: {} });
export { RenderType_ResidualValueComponent as RenderType_ResidualValueComponent };
function View_ResidualValueComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "residual-value-report", [], null, null, null, i2.View_ResidualValueReportComponent_0, i2.RenderType_ResidualValueReportComponent)), i1.ɵdid(1, 114688, null, 0, i3.ResidualValueReportComponent, [], { type: [0, "type"], report: [1, "report"], changeTab: [2, "changeTab"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.report; var currVal_2 = _co.changeTab; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ResidualValueComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResidualValueComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportTypes; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ResidualValueComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "error-section__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("No deposit considered")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ResidualValueComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResidualValueComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noDeposit", 2]], null, 0, null, View_ResidualValueComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAnyReports(); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ResidualValueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "residual-value", [], null, null, null, View_ResidualValueComponent_0, RenderType_ResidualValueComponent)), i1.ɵdid(1, 114688, null, 0, i6.ResidualValueComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResidualValueComponentNgFactory = i1.ɵccf("residual-value", i6.ResidualValueComponent, View_ResidualValueComponent_Host_0, { report: "report", changeTab: "changeTab" }, {}, []);
export { ResidualValueComponentNgFactory as ResidualValueComponentNgFactory };
