<app-import-spreadsheet
  [importForm]="depositImportForm"
  [labelText]="'import deposits from a spreadsheet'"
  [importModel]="depositImport"
  [fields]="fields"
  [onDocumentSave$]="onDocumentSave$"
  [onUpdateUploadUrl$]="onUpdateUploadUrl$"
  [importPreferences]="importPreferences"
  [importFormAdditionalData]="DEPOSIT_IMPORT_CONTROL_META"
  [userProhibitColumnLetters]="['P', 'Q', 'R', 'S', 'V', 'X']"
  (submit)="updateImportAndShowWarning()"
  (documentUploaded)="documentUploaded()"
>
</app-import-spreadsheet>

<ng-template #afterDate
             let-checkRequireDates="checkRequireDates"
             let-datePickerKeydown="datePickerKeydown"
             let-parentForm="parentForm"
>
  <div [formGroup]="parentForm"
    class="inline-flex align-center deposit-import__input-wrapper
      deposit-import__input-wrapper&#45;&#45;margin"
  >
    <div class=" import-deposit__column-available-title primary-color">{{ 'Reference date' | translate }}
      <span class="deposit-import__input&#45;&#45;star" *ngIf="checkRequireDates(parentForm)">*</span>
    </div>
    <md-input-container class="md-input--datepicker import-deposit__column-available-date">
      <input
        autocomplete="off"
        ng2-datetime-picker
        date-only="true"
        [required]="checkRequireDates(parentForm)"
        date-format="DD-MM-YYYY"
        parse-format="YYYY-MM-DD"
        formControlName="reference_date"
        mdInput
        (keydown)="datePickerKeydown($event)"
      >
      <i class="icon icon--calendar md-input__icon md-input__icon--grey"></i>
    </md-input-container>
  </div>

  <common-info-sign
    class="import-deposit__column-available-sign"
    [borderSizeClassModifier]="'1'"
    [sizeClassModifier]="'16'"
    mdTooltip="{{ 'REFERENCE_DATE_TOOLTIP' | translate }}">?
  </common-info-sign>
</ng-template>

<ng-template #beforeDimension let-parentForm="parentForm">
  <deposit-import-column-custom
    [formGroup]="parentForm"
    description="{{ 'DIMENSION_DESCRIPTION' | translate }}"
    class="import-deposit__column-dimension"
    [ngClass]="{'import-deposit__column-dimension--wide': isPt()}"
  >
    <ng-container column>
      <deposit-import-column
        [ngClass]="{'import-deposit__column--wide': isPt()}"
        label="{{ 'LENGTH_FIELD' | translate }}"
        formControlName="length_field"
        (onKeyDown)="importValidation.preventKeypressIfNotLetterOrList($event)"
        columnName="Q"
      ></deposit-import-column>
      <div class="import-deposit__column-dimension-separator">X</div>
      <deposit-import-column
        label="{{ 'WIDTH_FIELD' | translate }}"
        formControlName="width_field"
        (onKeyDown)="importValidation.preventKeypressIfNotLetterOrList($event)"
        columnName="R"
      ></deposit-import-column>
      <div class="import-deposit__column-dimension-separator">X</div>
      <deposit-import-column
        label="{{ 'HEIGHT_FIELD' | translate }}"
        formControlName="height_field"
        (onKeyDown)="importValidation.preventKeypressIfNotLetterOrList($event)"
        columnName="S"
      ></deposit-import-column>
    </ng-container>
  </deposit-import-column-custom>
</ng-template>
