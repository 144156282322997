import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 22.02.17.
 */
import { NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { CurrentUserService } from '../services/current-user.service';
import { Router } from '@angular/router';
import { DeepStreamService } from '../services/deepstream.service';
import { DateTimePickerTranslationService } from '../datetime-translation/datetime-translation';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from '../auth/current-user.class';
import { UserService } from '../services/user.service';
import 'rxjs/add/operator/distinctUntilChanged';
import { ModuleNames } from '../values/module-names.const';
import { AuthService } from '../services/auth.service';
import { LanguageService } from '../../entities/client/service/language.service';
import { MdDialog } from '@angular/material';
import { DIALOG_THEME } from '../helpers/dialog-themes.const';
import { BIMModelDialogComponent } from '../../dashboards/deposit-owner/dialogs/BIM-models-dialog/BIM-model-dialog.component';
import { FilterStateService } from '../services/filterState.service';
import { NavbarStateService } from '../services/navbar.service';
import { GlobalEventsManager } from '../services/global-events-manager.service';
import { EAdminPermission } from '../../dashboards/admin/values/admin-permission.enum';
import * as _ from 'lodash';
import { PermissionService } from '../services/permission.service';
import { EDepositOwnerPermission } from '../../dashboards/deposit-owner/values/deposit-owner-permission.enum';
var NeedNavbarComponent = /** @class */ (function () {
    function NeedNavbarComponent(_navigation, _permission, _curUserService, _deepstreamService, ngZone, router, _translateService, dateTimePicker, _allUserService, authService, languageService, _dialog, filterStateService, navbarStateService, globalEventsManager) {
        var _this = this;
        this._navigation = _navigation;
        this._permission = _permission;
        this._curUserService = _curUserService;
        this._deepstreamService = _deepstreamService;
        this.ngZone = ngZone;
        this.router = router;
        this._translateService = _translateService;
        this.dateTimePicker = dateTimePicker;
        this._allUserService = _allUserService;
        this.authService = authService;
        this.languageService = languageService;
        this._dialog = _dialog;
        this.filterStateService = filterStateService;
        this.navbarStateService = navbarStateService;
        this.globalEventsManager = globalEventsManager;
        this.contexts = [];
        this.menu = [];
        this.moduleNames = ModuleNames;
        this.navigationChange = this._navigation.changeModule
            .distinctUntilChanged()
            .subscribe(function (moduleKey) {
            _this.setActiveContextData(moduleKey);
            if (_this.user) {
                setTimeout(function () { return _this._permission.setDefaultPermissionsOnUserExpandLoad(_this.user); });
            }
        });
        this.permissionChange = this._permission.updateAppliedPermissions.distinctUntilChanged().subscribe(function () { return _this.setActiveContextData(); });
    }
    NeedNavbarComponent.prototype.logout = function () {
        var _this = this;
        this.authService.logout().subscribe(function () {
            _this.filterStateService.depositList = null;
            _this.filterStateService.productPassportList = null;
            _this.navbarStateService.multilanguage = false;
        });
    };
    NeedNavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        var context = this._navigation.getContext();
        if (context) {
            this.setActiveContextData(context.moduleName);
        }
        if (localStorage.getItem('avatar_url') !== 'undefined') {
            this.fetchUserData();
        }
        else {
            this.username = 'loading';
            this._curUserService.info().subscribe(function (data) {
                _this.updateUserData(data);
                // this.setLang(data.json().language);
            });
        }
        this._curUserService.currentUserDidChanged.subscribe(function () {
            _this._curUserService.info().subscribe(function (userData) {
                _this.updateUserData(userData);
                // this.setLang(data.json().language);
            });
        });
        if (CurrentUser.infoData.id) {
            this._allUserService.view(CurrentUser.infoData.id, { expand: 'company' }).subscribe(function (user) {
                _this.user = user;
                _this.user.loaded = true;
                _this._permission.setDefaultPermissionsOnUserExpandLoad(_this.user);
                _this.setLang(user.language);
            });
        }
        if (!this.languageService.availableLanguages.includes(localStorage.getItem('language'))) {
            this.setLang(this._translateService.getDefaultLang());
        }
    };
    NeedNavbarComponent.prototype.updateUserData = function (data) {
        var result = data.json();
        localStorage.setItem('username', result.username);
        localStorage.setItem('roles', result.roles);
        localStorage.setItem('avatar_url', result.avatar_url);
        this.fetchUserData();
    };
    NeedNavbarComponent.prototype.fetchUserData = function () {
        this.username = localStorage.getItem('username');
        this.avatar_url = localStorage.getItem('avatar_url');
        this.roles = localStorage.getItem('roles').split(',');
    };
    NeedNavbarComponent.prototype.ngOnDestroy = function () {
        if (this.navigationChange) {
            this.navigationChange.unsubscribe();
        }
        if (this.permissionChange) {
            this.permissionChange.unsubscribe();
        }
    };
    // /**
    //  * hide for certain users (by username) report output
    //  */
    // getNavigationMenu() {
    // tslint:disable-next-line:max-line-length
    //   const allowedUsernames = ['clientmaker1', 'CircularityAdmin', 'Carbonadmin', 'Toxicityadmin', 'Financialadmin', 'Nexity', 'OlivierC', 'Nexityadmin'];
    //   let menu = this._navigation.getNavigationMenu();
    //   if (allowedUsernames.includes(this._curUserService.infoData.username)) {
    //     return menu;
    //   }
    //   return menu.filter(menuItem => menuItem.title !== 'Reporting');
    // }
    NeedNavbarComponent.prototype.setActiveContextData = function (moduleKey) {
        var applyRule = this.applyPermissionToContext();
        if (applyRule) {
            this.setActiveContextWithRule(applyRule);
        }
        else {
            this.setActiveContextWithoutPermissions();
        }
    };
    NeedNavbarComponent.prototype.applyPermissionToContext = function () {
        var context = this._navigation.getContext();
        var contexts = this._curUserService.getAvailableContexts();
        if (this.isRestrictedAdmin) {
            return this.restrictAdminContext(context, contexts);
        }
        if (this.isBuildAdmin) {
            return this.addBuildToContext(context, contexts);
        }
        return null;
    };
    NeedNavbarComponent.prototype.setActiveContextWithRule = function (context) {
        this.menu = context.navigation;
        this.roleText = context.roleText;
        this.homeUrl = context.homeUrl;
        this.contexts = context.availableContexts;
    };
    NeedNavbarComponent.prototype.setActiveContextWithoutPermissions = function (moduleKey) {
        this.menu = this._navigation.getNavigationMenu();
        this.roleText = this._navigation.getRoleText();
        this.homeUrl = this._navigation.getHomeUrl();
        this.contexts = this._curUserService.getAvailableContexts();
    };
    NeedNavbarComponent.prototype.setLang = function (lang) {
        localStorage.setItem('language', lang);
        var storedLanguage = localStorage.getItem('language');
        if (storedLanguage && this.languageService.availableLanguages.includes(storedLanguage)) {
            this._translateService.use(storedLanguage);
            this.dateTimePicker.setLocal(storedLanguage);
            this.user.language = storedLanguage;
            this.user.save().subscribe();
        }
        else {
            this._translateService.use(this._translateService.getDefaultLang());
            this.dateTimePicker.setLocal(this._translateService.getDefaultLang());
        }
    };
    Object.defineProperty(NeedNavbarComponent.prototype, "profileUrl", {
        get: function () {
            return (this._navigation.getContext() ? ('/' + this._navigation.getContext().rootPath) : '') + '/profile';
        },
        enumerable: true,
        configurable: true
    });
    NeedNavbarComponent.prototype.getAvatar = function () {
        if (this.avatar_url && this.avatar_url !== 'null' && this.avatar_url !== 'undefined') {
            return { 'background-image': 'url(' + this.avatar_url + ')' };
        }
        return null;
    };
    NeedNavbarComponent.prototype.openBIMModal = function () {
        this._dialog.open(BIMModelDialogComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { data: {} }));
    };
    Object.defineProperty(NeedNavbarComponent.prototype, "isRestrictedAdmin", {
        get: function () {
            return this._permission.hasAppliedPermission(EAdminPermission.RESTRICTED_ADMIN);
        },
        enumerable: true,
        configurable: true
    });
    NeedNavbarComponent.prototype.restrictAdminContext = function (context, contexts) {
        var availableNavigation = { url: 'admin/reporting' };
        context = _.cloneDeep(context);
        context.navigation = context.navigation.filter(function (navigation) { return availableNavigation.url === navigation.url; });
        context.homeUrl = availableNavigation.url;
        return tslib_1.__assign({}, context, { availableContexts: contexts });
    };
    Object.defineProperty(NeedNavbarComponent.prototype, "isBuildAdmin", {
        get: function () {
            return this._permission.hasAppliedPermission(EAdminPermission.BUILDING_ADMIN);
        },
        enumerable: true,
        configurable: true
    });
    NeedNavbarComponent.prototype.addBuildToContext = function (context, contexts) {
        var newNavigation = {
            url: 'admin/administration',
            title: 'Administration',
            icon: 'icon--administration',
            disabled: false
        };
        context = _.cloneDeep(context);
        context.navigation.push(newNavigation);
        return tslib_1.__assign({}, context, { availableContexts: contexts });
    };
    Object.defineProperty(NeedNavbarComponent.prototype, "isBuildDepositOwner", {
        get: function () {
            return this._permission.hasAppliedPermission(EDepositOwnerPermission.BUILDING_DO);
        },
        enumerable: true,
        configurable: true
    });
    NeedNavbarComponent.prototype.emitCircularSignatureClickEvent = function () {
        this.globalEventsManager.circularSignatureClick.emit();
    };
    return NeedNavbarComponent;
}());
export { NeedNavbarComponent };
