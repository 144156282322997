<article class="chat" *ngIf="chatRoom">
  <section class="chat__wrapper" id="messageList">
    <chat-history [history]="chatRoom.messages"></chat-history>
  </section>
  <footer class="chat__form">
    <button class="chat__button--attachment" (click)="uploadFiles()" *ngIf="uploader && !isChatRestricted">
      <i class="icon icon--attachment"></i>
    </button>
    <textarea *ngIf="!isChatRestricted" name="" id="" cols="30" rows="4" class="chat__new-message"
      [placeholder]="'Type your message here' | translate " [(ngModel)]="messageText"
      (keydown.enter)="sendMessage($event)"></textarea>

    <div class="restrict_content" *ngIf="isChatRestricted">
      <div class="restrict_icon">
        <img  class="icon" src="assets/png/!.png">
      </div>
      <div class="restrict_msg" >
        <label >{{ message | translate }}</label>
      </div>
    </div>
  </footer>
</article>

<input #fileInput type="file" ng2FileSelect [uploader]="uploader" style="display: none;">