
<div [@showOrHide]="stateName" class="notification-bar">
  <div class="notification-bar-content-wrapper">
    <div class="notification-bar-text-wrapper">
      <div class="notification-bar-text-wrapper-headline-text">
        <p><span>{{'MyUpcyclea is not yet developed to run on Internet Explorer, we recommend you use Chrome.' | translate}}</span></p>
      </div>
  </div>
      <button (click)="closeNotificationBar()" class="notification-bar-cta notification-bar-button">
      <div class="notification-bar-text-holder">
        <p>Ok, continue</p>
      </div>
    </button>
  </div>
</div>