import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformLocationService } from '../services/transform-location.service';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
var TransformLocation = /** @class */ (function (_super) {
    tslib_1.__extends(TransformLocation, _super);
    function TransformLocation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformLocationService;
        _this.plumb_type = ECOSYSTEM_STEP_TYPE.TRANSFORM;
        return _this;
    }
    TransformLocation.prototype.fields = function () {
        return [
            'id',
            'name',
            'address',
            'description',
            'lat',
            'lng',
            'user_id',
            'verification',
            'use_count',
            'creator',
        ];
    };
    return TransformLocation;
}(ActiveRecord));
export { TransformLocation };
