import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { EcosystemOffer } from '../../../../entities/ecosystem-offer/ecosystem-offer.model';
import * as _ from 'lodash';
import { ECOSYSTEM_STEP_STATUS } from '../../../ecosystem-manager/values/ecosystem-step-status.const';
import { EcosystemOfferService } from '../../../../entities/ecosystem-offer/ecosystem-offer.service';
import { ECOSYSTEM_OFFER_STATUS } from '../../../ecosystem-manager/values/ecosystem-offer-status.const';
import { NeedDetailsDialogComponent } from '../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { MdDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
var SalesOfferComponent = /** @class */ (function () {
    function SalesOfferComponent(_ecosystemOfferService, dialog, datePipe) {
        this._ecosystemOfferService = _ecosystemOfferService;
        this.dialog = dialog;
        this.datePipe = datePipe;
        this.refused = new EventEmitter();
        this.validated = new EventEmitter();
        this.unvalidated = new EventEmitter();
        this.askedForModification = new EventEmitter;
        this.confirmed = new EventEmitter();
        this.modificationModalIsVisible = false;
        this.modificationIsNull = false;
        this.files = [];
        this.ECOSYSTEM_OFFER_STATUS = ECOSYSTEM_OFFER_STATUS;
    }
    SalesOfferComponent.prototype.ngOnInit = function () {
        this.need = this.offer.need;
        if (this.offer.ecosystem_file_junction) {
            this.files = this.offer.ecosystem_file_junction.map(function (junction) { return junction.ecosystem_file; });
        }
    };
    Object.defineProperty(SalesOfferComponent.prototype, "passportMaterialsComponents", {
        get: function () {
            var passport = this.need.passport;
            return _.zip(passport.materials, passport.components);
        },
        enumerable: true,
        configurable: true
    });
    SalesOfferComponent.prototype.validate = function () {
        this.validated.emit();
        this.offer.validate().subscribe();
    };
    SalesOfferComponent.prototype.unvalidate = function () {
        this.unvalidated.emit();
        this.offer.unvalidate().subscribe();
    };
    SalesOfferComponent.prototype.reject = function () {
        this.refused.emit();
        this.offer.reject().subscribe();
    };
    //TODO why ecosystem step status is checked ?
    SalesOfferComponent.prototype.checkStatusValidated = function () {
        return this.offer.status !== ECOSYSTEM_STEP_STATUS.STAND_BY
            && this.offer.status !== ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION
            && this.offer.status !== ECOSYSTEM_STEP_STATUS.ASK_FOR_MODIFICATION;
    };
    SalesOfferComponent.prototype.changeVisibilityOfModificationModal = function () {
        this.offer.modification = '';
        this.modificationModalIsVisible = !this.modificationModalIsVisible;
    };
    SalesOfferComponent.prototype.modificationChanged = function () {
        this.modificationIsNull = !this.offer.modification;
    };
    SalesOfferComponent.prototype.askForModification = function (id, modification) {
        if (!modification) {
            this.modificationIsNull = true;
            return;
        }
        this.askedForModification.emit();
        this._ecosystemOfferService.sendModification(id, modification).subscribe();
    };
    SalesOfferComponent.prototype.confirm = function () {
        this.confirmed.emit();
        this.offer.confirm().subscribe();
    };
    SalesOfferComponent.prototype.setRowHeight = function (inputString, rowLength) {
        var rowSize = 1;
        if (!inputString) {
            return rowSize;
        }
        else if (inputString.length < rowLength) {
            return rowSize;
        }
        else {
            rowSize = Math.ceil(inputString.length / rowLength);
            return rowSize;
        }
    };
    SalesOfferComponent.prototype.openNeedDetailsDialog = function (id) {
        this.dialog.open(NeedDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { data: {
                id: id,
                readOnly: true,
                showValidationButtons: false
            } }));
    };
    return SalesOfferComponent;
}());
export { SalesOfferComponent };
