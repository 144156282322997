import { ActiveRecord } from '../../../shared/models/active-record.class';
import {TransformQualityService} from '../services/transform-quality.service';

export class TransformQuality extends ActiveRecord {
  protected provider = TransformQualityService;

  public id: number;
  public name: string;

  protected fields() {
    return [
      'id',
      'name',
    ];
  }
}


