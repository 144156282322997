import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Passport} from '../../models/passport.class';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {EntityViewerComponent} from '../../../../shared/base/enitity-viewer.component';
import {SUPERVISOR_CONTEXT} from '../../../../dashboards/supervisor/supervisor.context';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {MdDialog} from '@angular/material';
import {PassportModificationDialogComponent} from '../../dialogs/passport-modification-dialog/passport-modification-dialog.component';
import {PASSPORT_STATUS_CONST, PASSPORT_STATUS} from '../../values/passport-status.const';
import {PassportService} from '../../services/passport.service';
import {PASSPORT_TAB} from '../../values/passport-tab.const';
import {PASSPORT_MANDATORY_FIELDS} from '../../values/passport-mandatory-fields.const';
import {AlertDialogComponent} from '../../../../shared/alert-dialog/alert-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {PERFORMANCE_RADIOS_VALUES} from '../../values/performance-radios.const';
import { CurrentUser } from '../../../../shared/auth/current-user.class';
import {  ROLES_WITH_SPECIAL_ADMIN_SHORT } from '../../../../shared/values/role-ids.enum';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {PassportRatingExplanationDialogComponent} from '../../dialogs/passport-rating-explanation-dialog/passport-rating-explanation-dialog';
import { Location } from '@angular/common';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { PassportValidationNotificationService } from '../../services/passport-validation-notification.service';
import { NewPassportPublicationComponent } from '../../dialogs/new-passport-publication-dialog/new-passport-publication.component';
import { BaseDialogComponent } from '../../../../app/components/base/dialog/base-dialog.component';
import { CurrentUserService } from '../../../../shared/services/current-user.service';

@Component({
  moduleId: module.id,
  selector: 'passport-edit-v2',
  templateUrl: 'passport-edit-v2.component.html'
})
export class PassportEditV2Component extends EntityViewerComponent<Passport> implements OnInit, AfterViewInit {
  public passport: Passport;
  public isNewPassport: boolean;
  public save = new EventEmitter();
  public isStatic: boolean = false;
  public parentModule: string;
  public modificationMessage: string;
  public isUploading: boolean = false;
  public isLastVersion: boolean = false;
  moduleNames = ModuleNames;
  passportStatuses = PASSPORT_STATUS_CONST;

  form: FormGroup;
  validated: boolean = false;
  isToggleChecked: boolean = true;
  isInformationShown: boolean = true;
  private tabs = PASSPORT_TAB;
  private ObjectValues = Object.values;
  private selectedTab = PASSPORT_TAB.GENERAL;
  private emptyMandatoryFields: string[] = [];
  public supervisorView_premium_passports ;
  private isPublicAccess = false ;
  private isFormEditable = false;
  syncActionInProgress: boolean = false ;
  isSpecialAdmin = false;
  @ViewChild("passportSynchronizationInformation") passportSynchronizationInformation;
  @ViewChild("passportForm") passportForm;

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              private _passportService: PassportService,
              private _navigationService: NavigationService,
              private _mdDialog: MdDialog,
              private _fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private _translate: TranslateService,
              private notification: LogNotificationService,
              private ngZone: NgZone,
              private location: Location,
              private _passportValidationNotification: PassportValidationNotificationService,
              public filterState: NavbarStateService,
              private currentUserService: CurrentUserService) {
    super(route, router);
    this.isSpecialAdmin = this.currentUserService.isSpecialAdmin;
    this.form = this._fb.group({});
    this.parentModule = this._navigationService.getModuleName();

    this.isStatic = (this.parentModule === this.moduleNames.SPECIAL_ADMIN) ;
    this.supervisorView_premium_passports= false ;
    this.isPublicAccess =this.route.snapshot.data['isPublicAccess'];
  }

  ngOnInit(): any {
    super.ngOnInit();
    if (this.parentModule == this.moduleNames.SUPERVISOR || this.parentModule == this.moduleNames.AGENT) {
      this.ispassportCreatedbyPremiumAcount() ;
    }
    this.initializeLastVersion();
  }

  /// check if the passport createed by TSMI or Manufacturer then forbid user from editing the passport
  ispassportCreatedbyPremiumAcount () {
    if (this.passport.user){
    if ( this.passport.user.id != CurrentUser.infoData.id) {
      this.passport.user.roles.forEach(element => {
        ROLES_WITH_SPECIAL_ADMIN_SHORT.forEach(role => {
          if ( role.text == element ) {

            this.isStatic = true ;
            this.supervisorView_premium_passports =true ;
            return ;
            }
          });
      });
      return  ;
    }}else{
      return ;
    }
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  onExistEntity(entity: Passport): void {
    this.passport = entity;
    // TODO seems like this is causing ExpressionChangedAfterItHasBeenCheckedError
    if (!this.passport.isIndependent()) {
      this.supervisorView_premium_passports = this.passport.isIndependent() ;
    }
    this.passport.loaded = true;
    this.isNewPassport = false;
    if (this.passport.status === this.passportStatuses.ASK_FOR_MODIFICATION.value) {
      this._passportService.getModificationMessage(this.passport.id).subscribe((res: any) => {
        this.modificationMessage = res[0].message;
      });
    }
  }

  onNewEntity(): void {
    this.passport = new Passport();
    this.passport.loaded = true;
    this.isNewPassport = true;
  }

  onTabSelected(tab): void {
    this.selectedTab = tab;
  }

  getTabClasses(tab: string) {
    return {
      ['passport-edit__tabs--' + (tab.toLocaleLowerCase())]: true,
      ['passport-edit__tabs--tab-active']: tab === this.selectedTab,
      ['background-primary-color']: tab === this.selectedTab
    };
  }

  onToggleChanged(event) {
    this.isToggleChecked = event.checked;
  }

  onChangePassport(entity: Passport) {
    this.onExistEntity(entity);
  }

  onSavePassport() {
    this.ngZone.run(() => this.router.navigate([this.parentModule, 'passports']));
  }

  handleUploadStatus(isUploading: boolean): void {
    this.isUploading = isUploading;
  }

  clickSave() {
    if (this._passportValidationNotification.showNotificationErrors(this.getPassportCtrl())){
      this.validated = true;
      return;
    }
    this.save.emit({key: 'updatePassport'});
  }

  clickPublish() {
    if (this._passportValidationNotification.showNotificationErrors(this.getPassportCtrl())){
      this.validated = true;
      return;
    }
    this.save.emit({key: 'publishPassport'});
  }

  clickValidate() {
    this.save.emit({key: 'validatePassport'});
  }

  clickAskModification() {
    this._mdDialog.open(PassportModificationDialogComponent, {
      data: {
        passport: this.passport
      }
    });
  }

  clickAskForRating() {
    if (!this.isAskForRatingEnabled()) {
      return this.showMissingFieldsPopUp();
    }
    if (this._passportValidationNotification.showNotificationErrors(this.getPassportCtrl())){
      this.validated = true;
      return;
    }
    return this.openRatingExplanationDialog(true);
  }
  getPassportCtrl() {
    return this.form.get('passport');
  }
  isAskForRatingEnabled(): boolean {
    const passportCtrl = this.getPassportCtrl(); // Passport model cannot be used here because not all fields are updated on data change
    if (!passportCtrl) {
      return false;
    }
    this.updatePassportNameExistence(passportCtrl.get('passportName'));
    this.updatePassportEnglishNameExistence(passportCtrl.get('passportEnglishName'));
    this.updateCategoryExistence(passportCtrl.get('category'));
    this.updateC2cCertificationExistence(
      passportCtrl.get('c2cCertified'), passportCtrl.get('certificationLevel'), passportCtrl.get('certificationExpiration'));

    const hasValue = (field): boolean => !!this.emptyMandatoryFields.find(i => i === field);
    const notExistingValues = Object.values(PASSPORT_MANDATORY_FIELDS)
      .map(field => hasValue(field))
      .filter(e => !!e);
    return !notExistingValues.length;
  }
  updateEmptyMandatoryField(isExist: boolean, mandatoryField: string): void {
    return !isExist
      ? this.addEmptyMandatoryField(mandatoryField)
      : this.removeEmptyMandatoryField(mandatoryField);
  }
  updatePassportNameExistence(passportNameCtrl): void {
    const isExist = !!passportNameCtrl && !!passportNameCtrl.value;
    this.updateEmptyMandatoryField(isExist, PASSPORT_MANDATORY_FIELDS.GENERIC_NAME);
  }
  updatePassportEnglishNameExistence(passportEnglishNameCtrl): void {
    const isExist = !!passportEnglishNameCtrl && !!passportEnglishNameCtrl.value;
    this.updateEmptyMandatoryField(isExist, PASSPORT_MANDATORY_FIELDS.ENGLISH_GENERIC_NAME);
  }
  updateCategoryExistence(categoryCtrl: AbstractControl): void {
    const isExist = !!categoryCtrl && !!categoryCtrl.value;
    this.updateEmptyMandatoryField(isExist, PASSPORT_MANDATORY_FIELDS.CATEGORY);
  }

  updateC2cCertificationExistence(
    c2cCertifiedCtrl: AbstractControl, c2cLevelCtrl: AbstractControl, c2cExpirationCtrl: AbstractControl
  ): void {
    if (!!c2cCertifiedCtrl && c2cCertifiedCtrl.value === PERFORMANCE_RADIOS_VALUES.YES) {
      const isExist = !!c2cLevelCtrl.value && !!c2cExpirationCtrl.value;
      this.updateEmptyMandatoryField(isExist, PASSPORT_MANDATORY_FIELDS.C2C);
    } else {
      this.removeEmptyMandatoryField(PASSPORT_MANDATORY_FIELDS.C2C);
    }
  }

  addEmptyMandatoryField(field: string): void {
    if (!!this.emptyMandatoryFields.find(i => i === field)) {
      return;
    }
    this.emptyMandatoryFields = [...this.emptyMandatoryFields, field];
  }

  removeEmptyMandatoryField(field: string): void {
    if (!this.emptyMandatoryFields.find(i => i === field)) {
      return;
    }
    this.emptyMandatoryFields = this.emptyMandatoryFields.filter(e => e !== field);
  }

  showMissingFieldsPopUp() {
    const getAlertContent = (): string => {
      const translation = this._translate.instant(this.emptyMandatoryFields);
      const translateValues = Object.values(translation);
      return (translation && !!translateValues.length) ? translateValues.join(', ') : null;
    };
    const getAlertTitle = (): string => this._translate.instant('Missing fields');
    this._mdDialog.open(AlertDialogComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
      data: {
        text: getAlertContent(),
        title: getAlertTitle()
      }
    });
  }

  askForRatingMode(): boolean {
    return this.parentModule === this.moduleNames.MANUFACTURER;
  }

  getPassportTabTooltip(tab): string {
    const translate = (value) => this._translate.instant(value);
    switch (tab) {
      case PASSPORT_TAB.GENERAL:
        return translate('General information');
      case PASSPORT_TAB.COMPOSITION:
        return translate('Composition');
      case PASSPORT_TAB.PERFORMANCE:
        return translate('Environmental and social performance');
      case PASSPORT_TAB.INFO:
        return translate('Further information');
      case PASSPORT_TAB.CYCLE:
        return translate('Use(s) and cycle');
    }
  }

  openRatingExplanationDialog(isActionButtonsShown: boolean): void {
    this._mdDialog.open(PassportRatingExplanationDialogComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
      data: {
        onAskForARating: (token?: string) => {
          if (token) {
            this.passport.rating_token = token;
            this.passport.rating_token_provided = true;
          }
          this.save.emit({key: 'askForRating'});
        },
        isActionButtonsShown: isActionButtonsShown
      }
    });
  }
  onNextTabClick($event) {
    this.selectedTab = $event;
  }
  backToPassportList () {
    this.location.back()
  }
  isPassportPublished() {
    return this.passport.status == this.passportStatuses.PUBLISHED.value ;
  }

  /**
   * Initialize the isLastVersion attribute value
   */
  initializeLastVersion() {
    if (!this.passport) {
      return;
    }

    if (!this.passport.link_reference) {
      this.isLastVersion = true;
      return;
    }

    let data = {
      passport_id: this.passport.id,
      parent_passport: this.passport.link_reference
    };
    this._passportService.isLastVersion(data).subscribe((res: any) => {
      this.isLastVersion = res;
    });
  }

  /**
   * Change the information concerning the form editability
   * Mainly, the form is editable if the passport is local, and ineditable otherwise
   * @param {boolean} state - true if the form is editable, false else
   */
  changeEditable(state) {
    this.isFormEditable = state;
  }

  /**
   * Method which checks whether a passport is synchronized or not
   * @returns {boolean} true if the passport is synchronized, false else
   */
  isSynchronized() {
    return this.passport && !!this.passport.external_passport_id;
  }

  /**
   * Method which checks whether a passport can be synchronized or not
   * @returns {boolean} true if passport can be synchronized, false else
   */
  checkSynchronability() {
    return !!this.passport && !!this.passport.source && !!this.passport.operator && !!this.passport.documentId;
  }

  /**
   * Method called when the user toggles the sync toggle towards the syncing position
   */
  onSynchronize() {
    let params = {
      source: this.passport.source,
      operator: this.passport.operator,
      document_id: this.passport.documentId
    };

    this._passportService.checkExternalPassport(params).subscribe((res: any) => {
      if (res) {
        this.openSynchronizationModal();
      } else {
        this.openSynchronizationImpossibleModal();
      }
    });
  }

  /**
   * Open the synchronization modal to present the user with synchronization options (Mainly if they want to save before sync)
   */
  openSynchronizationModal() {
    this._mdDialog.open(BaseDialogComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
      data: {
        buttons: this.getSynchronizationButtons(),
        title: "Synchronization",
        paragraphs: ["PASSPORT_SYNCHRONIZATION_POPUP"]
      }
    }).afterClosed().subscribe((reason) => this.handleModalClosed(reason));
  }
  
  buttonIsDisabled() {
    return this.syncActionInProgress ;
  }

  /**
   * Method which returns the list of buttons present in the synchronization modal
   * @returns {Array<Object>}
   */
  getSynchronizationButtons() {
    return [
      {
        action: this.synchronizePassport.bind(this, false),
        content: "synchronize",
        isDisabled : this.buttonIsDisabled.bind(this) 
      },
      {
        action: this.saveAndSynchronizePassport.bind(this, true),
        content: "saveAndSynchronize",
        isDisabled : this.buttonIsDisabled.bind(this)
      },
    ]
  }

  /**
   * Synchronize a passport according to the synchronization data which it contains
   * Once the sync has been done, reroute to the new passport
   */
  synchronizePassport() {
    this.syncActionInProgress = true;
    let params = this.getSynchronizationData();
    this._passportService.synchronizePassport(params).subscribe((res: any) => {
      this.router.navigate([this.parentModule + '/passports/', res]).then(() => {
        this.syncActionInProgress = false;
        window.location.reload();
      });
    }, (error) => {
      this.syncActionInProgress = false;
    });
  }

  saveAndSynchronizePassport() {
    this.syncActionInProgress = true ;
    let associatedObjects = this.getAssociatedObjects();
    let passportToSave = this.preparePassport(this.passport, associatedObjects);
    this._passportService.savePassportManually(passportToSave).subscribe((res: any) => {
      this.synchronizePassport();
    }, (error) => {
      this.syncActionInProgress = false;
    });
  }

  /**
   * Prepare passport to be saved in the back
   * @param {Passport} passport - The passport to be saved
   * @param {Object} associatedObjects - The objects associated to the passport, and also to be saved
   * @returns {Object} The object to be sent to the backend
   */
  preparePassport(passport, associatedObjects) {
    return Object.assign({}, {
      additional: passport.additional,
      appearance: passport.appearance,
      c2c_certified: passport.c2c_certified,
      carbon_construction: passport.carbon_construction,
      carbon_conversion: passport.carbon_conversion,
      carbon_conversion_date: passport.carbon_conversion_date,
      carbon_conversion_description: passport.carbon_conversion_description,
      carbon_end: passport.carbon_end,
      carbon_product: passport.carbon_product,
      carbon_total: passport.carbon_total,
      catalogue_product_name: passport.catalogue_product_name,
      category_id: passport.category_id,
      cert_carbon_level: passport.cert_carbon_level,
      cert_material_health_level: passport.cert_material_health_level,
      cert_material_reuse_level: passport.cert_material_reuse_level,
      cert_socials_level: passport.cert_socials_level,
      cert_version: passport.cert_version,
      cert_water_level: passport.cert_water_level,
      certification_expiration: passport.certification_expiration,
      certification_level: passport.certification_level,
      colors: passport.colors,
      components: passport.components,
      composite_name: passport.composite_name,
      conversion: passport.conversion,
      custom_made_name: passport.custom_made_name,
      demountability_default: passport.demountability_default,
      description: passport.description,
      dimension_height: passport.dimension_height,
      dimension_length: passport.dimension_length,
      dimension_width: passport.dimension_width,
      distribution_areas: passport.distribution_areas,
      //documentId: passport.documentId,
      energies: passport.energies,
      energy_construction: passport.energy_construction,
      energy_construction_renewable: passport.energy_construction_renewable,
      energy_cycle: passport.energy_cycle,
      energy_cycle_renewable: passport.energy_cycle_renewable,
      energy_end_of_life: passport.energy_end_of_life,
      energy_end_of_life_renewable: passport.energy_end_of_life_renewable,
      energy_operational_energy_use: passport.energy_operational_energy_use,
      energy_operational_energy_use_renewable: passport.energy_operational_energy_use_renewable,
      energy_operational_water_use: passport.energy_operational_water_use,
      energy_operational_water_use_renewable: passport.energy_operational_water_use_renewable,
      energy_product: passport.energy_product,
      energy_product_renewable: passport.energy_product_renewable,
      energy_resource_recovery: passport.energy_resource_recovery,
      energy_resource_recovery_renewable: passport.energy_resource_recovery_renewable,
      energy_use_stage: passport.energy_use_stage,
      energy_use_stage_renewable: passport.energy_use_stage_renewable,
      english_name: passport.english_name,
      //epdType: passport.epdType,
      //external_passport_id: passport.external_passport_id,
      family: passport.family,
      first_life: passport.first_life,
      for_rating: passport.for_rating,
      format: passport.format,
      gtins: passport.getGtinsForSave(),
      id: passport.id,
      //language: passport.language,
      link_reference: passport.link_reference,
      manufacturer: passport.manufacturer,
      manufacturers: passport.manufacturers,
      materials: passport.materials,
      name: passport.name,
      name_type: passport.name_type,
      next_usages: passport.next_usages,
      no_banned_materials: passport.no_banned_materials,
      carbon_operational_water_use: passport.carbon_operational_water_use,
      operational_energy_use: passport.operational_energy_use,
      operator: passport.operator,
      optimization_strategy: passport.optimization_strategy,
      //otherOperator: passport.otherOperator,
      //otherSource: passport.otherSource,
      percentage_default: passport.percentage_default,
      period: passport.period,
      period_type: passport.period_type,
      product_passport_id: passport.product_passport_id,
      product_refrence: passport.product_refrence,
      purchase_price: passport.purchase_price,
      rating: passport.rating,
      rating_token: passport.rating_token,
      recommendation: passport.recommendation,
      recycle_percentage: passport.recycle_percentage,
      resource_recovery_stage: passport.resource_recovery_stage,
      social_fairness_certified: passport.social_fairness_certified,
      // source: passport.source,
      thumbnail: passport.thumbnail,
      trade_name: passport.trade_name,
      type: passport.type,
      typology: passport.typology,
      unit: passport.unit,
      use_stage: passport.use_stage,
      used_energy: passport.used_energy,
      user_id: passport.user_id,
      water_construction: passport.water_construction,
      water_conversion: passport.water_conversion,
      water_cycle: passport.water_cycle,
      water_end_of_life: passport.water_end_of_life,
      water_operational_energy_use: passport.water_operational_energy_use,
      water_operational_water_use: passport.water_operational_water_use,
      water_resource_recovery: passport.water_resource_recovery,
      water_stewardship_certified: passport.water_stewardship_certified,
      water_use_stage: passport.water_use_stage,
      passport_synchronization_data : this.getSynchronizationData()
    }, associatedObjects);
  }

  /**
   * Get all the objects associated to this passport
   * This includes next usages, distribution_areas, etc...
   * @returns {Object}
   */
  getAssociatedObjects() {
    return this.passportForm.getAssociatedObjects();
  }

  /**
   * Get the data needed to synchronize a passport
   * @returns  {Object}
   */
  getSynchronizationData() {
    return {
      source: this.passport.source,
      operator: this.passport.operator,
      document_id: this.passport.documentId,
      passport_id: this.passport.id
    }
  }

  /**
   * Open the modal explaining that the passport cannot be synchronized because no matching external passport was found
   */
  openSynchronizationImpossibleModal() {
    this._mdDialog.open(BaseDialogComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
      data: {
        buttons: [],
        title: "Synchronization impossible",
        paragraphs: ["PASSPORT_SYNCHRONIZATION_IMPOSSIBLE_POPUP"]
      }
    }).afterClosed().subscribe(this.handleModalClosed.bind(this));
  }

  /**
   * Method called when user untoggles the synchronization toggle
   * Pass the external passport id to null to indicate that the passport is no longer synchronized
   */
  onDesynchronize() {
    this.openDesynchronizationModal();
  }

  /**
   * Open the desynchronization modal to confirm they want to desynchronize
   */
  openDesynchronizationModal() {
    this._mdDialog.open(BaseDialogComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
      data: {
        buttons: this.getDesynchronizationButtons(),
        title: "Desynchronization",
        paragraphs: ["PASSPORT_DESYNCHRONIZATION_POPUP"]
      }
    }).afterClosed().subscribe(this.handleModalClosed.bind(this));
  }

  /**
   * Method which returns the list of buttons present in the desynchronization modal
   * @returns {Array<Object>}
   */
  getDesynchronizationButtons() {
    return [
      {
        action: this.desynchronizePassport.bind(this),
        content: "desynchronize"
      },
    ]
  }

  /**
   * Desynchronize the passport
   * This just consists in removing the external_passport_id
   * TODO Maybe we need to save as we ask for confirmation
   */
  desynchronizePassport() {
    this._passportService.desynchronizePassport({passport_id: this.passport.id}).subscribe((res: any) => {
      this.passport.external_passport_id = null;
      this._mdDialog.closeAll();
    });
  }

  /**
   * Handle a modal closing
   * @param {string} reason - The reason why the popup was closed
   */
  handleModalClosed(reason) {
    if (reason === "closed") {
      this.passportSynchronizationInformation.forceToggleState(this.isSynchronized());
    }
  }
}
