<div class="import-progress-wrapper" *ngIf="reportProgressService.showImportProgress">
    <div class="import-progress-text">{{getText() | translate }}</div>
    <div class="import-progress-bar primary-color"
         [ngClass]="{'import-progress-bar--success': isSuccess,'import-progress-bar--error': isError}">
        <md-progress-bar mode="indeterminate"></md-progress-bar>
    </div>
    <i class="import-progress-close icon arrow-download--icon" *ngIf="reportProgressService.statusImportProgress === 4" (click)="downloadZip()"></i>
    <i class="import-progress-close icon close-grey--icon" *ngIf="reportProgressService.statusImportProgress === 4" (click)="close()"></i>


</div>
