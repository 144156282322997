import { Component, Inject, OnInit } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';

interface ListItem {
  name: string;
  error: string;
}

@Component({
  moduleId: module.id,
  selector: 'generic-delete-warning',
  templateUrl: 'generic-delete-warning.component.html',
  styleUrls: ['generic-delete-warning.component.scss']
})
export class GenericDeleteWarningComponent implements OnInit {

  title: string;
  category: string;
  items: ListItem[];

  constructor(
    public mdDialogRef: MdDialogRef<GenericDeleteWarningComponent>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any
  ) { }

  ngOnInit(): void {
    this.title = this.mdDialogData.title;
    this.items = this.mdDialogData.items;
    this.category = this.mdDialogData.category;
  }

}
