/**
 * Created by aleksandr on 30.03.17.
 */

import { IModuleNavigation } from '../../shared/interfaces/module-navigation.interface';

export const SUPERVISOR_NAVIGATION: IModuleNavigation[] = [/*{
  url: 'supervisor/needs',
  title: 'Needs',
  icon: 'create--icon',
}, {
  url: 'supervisor/transforms',
  title: 'Trasnsformations',
  icon: 'refresh--icon',
}, {
  url: 'supervisor/deposits',
  title: 'Deposits',
  icon: 'my-deposits--icon',
},*/
//  {
//   url: '/supervisor/statistics',
//   title: 'Statistics',
//   icon: 'statistic--icon',
// },
  {
    url: 'supervisor/data',
    title: 'Data',
    icon: 'data--icon',
  },
  {
    url: 'supervisor/passports',
    title: 'Circular passports',
    icon: 'cube--icon'
  }
];
