<div class="spinner-dialog">
  <div>
    <md-progress-spinner
      mode="indeterminate">
    </md-progress-spinner>
    <div class="spinner-dialog__caption">
      {{caption | translate}}<span *ngIf="isLoadingDotsShown"><span class="first-dot">.</span><span class="second-dot">.</span><span class="third-dot">.</span></span>
    </div>
  </div>
</div>
