/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/custom-selection-list-with-chip/custom-selection-list-with-chip.component.ngfactory";
import * as i2 from "../../../../shared/custom-selection-list-with-chip/custom-selection-list-with-chip.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./passport-distribution-area.component";
import * as i5 from "../../services/passport.service";
import * as i6 from "../../../../shared/services/country.service";
import * as i7 from "../../services/distribution-area.service";
import * as i8 from "../../../../shared/read-only/read-only.service";
var styles_PassportDistributionAreaComponent = [];
var RenderType_PassportDistributionAreaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PassportDistributionAreaComponent, data: {} });
export { RenderType_PassportDistributionAreaComponent as RenderType_PassportDistributionAreaComponent };
export function View_PassportDistributionAreaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "custom-selection-list-with-chip", [["listNameField", "name"]], null, [[null, "onChange"], [null, "onDelete"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.onPassportDistributionAreasChange($event.check, $event.value) !== false);
        ad = (pd_1 && ad);
    } if (("onDelete" === en)) {
        var pd_2 = (_co.onPassportDistributionAreasDeleted(null, $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_CustomSelectionListWithChipComponent_0, i1.RenderType_CustomSelectionListWithChipComponent)), i0.ɵdid(1, 49152, null, 0, i2.CustomSelectionListWithChipComponent, [i3.TranslateService], { allVariants: [0, "allVariants"], chosenItems: [1, "chosenItems"], disabledChip: [2, "disabledChip"], disabledList: [3, "disabledList"], isItemChecked: [4, "isItemChecked"] }, { onChange: "onChange", onDelete: "onDelete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countyRecords; var currVal_1 = _co.areaRecords; var currVal_2 = _co.isDisabled; var currVal_3 = _co.disableStatus; var currVal_4 = _co.checkedPassportDistributionAreas; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_PassportDistributionAreaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "passport-distribution-areas", [], null, null, null, View_PassportDistributionAreaComponent_0, RenderType_PassportDistributionAreaComponent)), i0.ɵdid(1, 245760, null, 0, i4.PassportDistributionAreaComponent, [i5.PassportService, i6.CountryService, i7.DistributionAreaService, [2, i8.ReadOnlyService]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PassportDistributionAreaComponentNgFactory = i0.ɵccf("passport-distribution-areas", i4.PassportDistributionAreaComponent, View_PassportDistributionAreaComponent_Host_0, { listener: "listener", passport: "passport", isDisabled: "isDisabled", viewMode: "viewMode" }, { onSave: "onSave" }, []);
export { PassportDistributionAreaComponentNgFactory as PassportDistributionAreaComponentNgFactory };
