import * as tslib_1 from "tslib";
import { Zone } from '../models/zone.class';
import { ExtendedCrudService } from './extended-crud.service';
var ZoneService = /** @class */ (function (_super) {
    tslib_1.__extends(ZoneService, _super);
    function ZoneService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'zones';
        _this.namespaceSingular = 'zone';
        _this.ModelClass = Zone;
        return _this;
    }
    ZoneService.prototype.setListPage = function (pagenr) {
        this.pagenr = pagenr;
    };
    ZoneService.prototype.getListPage = function () {
        return this.pagenr;
    };
    ZoneService.prototype.getGenericPassports = function (id, query) {
        return this
            .sendGetWithArray(this.getEndpoint(this.singularEndpoint + ("/" + id + "/generic-passports")), query)
            .catch(this.onError.bind(this));
    };
    ZoneService.prototype.savePassport = function (zoneId, passport) {
        var _this = this;
        var request = null;
        request = passport.id ?
            this.sendPut(this.getEndpoint(this.singularEndpoint + ("/" + zoneId + "/generic-passports/" + passport.id)), passport) :
            this.sendPost(this.getEndpoint(this.singularEndpoint + ("/" + zoneId + "/generic-passports")), passport);
        return request
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    ZoneService.prototype.getLandfillVariables = function (zoneId, query) {
        return this.sendGet(this.getEndpoint(this.singularEndpoint + ("/" + zoneId + "/landfill-variables")), { search: query })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ZoneService.prototype.saveLandfillVariables = function (zoneId, query) {
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + ("/" + zoneId + "/landfill-variables/upsert-bulk")), query)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    return ZoneService;
}(ExtendedCrudService));
export { ZoneService };
