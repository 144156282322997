import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { ProductSector } from '../models/product-sector.class';
var ProductSectorService = /** @class */ (function (_super) {
    tslib_1.__extends(ProductSectorService, _super);
    function ProductSectorService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'product-sectors';
        _this.namespaceSingular = 'product-sector';
        _this.ModelClass = ProductSector;
        return _this;
    }
    return ProductSectorService;
}(CrudService));
export { ProductSectorService };
