import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { DepositOwnerDashboardComponent } from './partials/deposit-owner-dashboard/deposit-owner-dashoard.component';
import { DO_ROUTES } from './deposit-owner.routes';
import { DoArchiveCatalogComponent } from './partials/do-archive-catalog/do-archive-catalog.component';
import { DoCatalogComponent } from './partials/do-catalog/do-catalog.component';
import { DoSitesCatalogComponent } from './partials/do-sites-catalog/do-sites-catalog.component';
import { DoAgentsCatalogComponent } from './partials/do-agents-catalog/do-agents-catalog.component';
import { DoDepositsCatalogComponent } from './partials/do-deposits-catalog/do-deposits-catalog.component';
import { DoOrdersCatalogComponent } from './partials/do-orders-catalog/do-orders-catalog.component';
import { DepositOrderDetailsComponent } from './partials/deposit-order-details/deposit-order-details.component';
import { DepositOrderDetailsDialogComponent } from './dialogs/deposit-order-details-dialog/deposit-order-details-dialog.component';
import { DepositOwnerListService } from './services/deposit-owner-list.service';
import { DoDepositsListComponent } from './partials/do-deposits-list/do-deposits-list.component';
import { CommonModule } from '@angular/common';
import { DepositModule } from '../../entities/deposit/deposit.module';
import { DepositOwnerGuard } from './deposit-owner.guard';
import { RouterModule } from '@angular/router';
import { DEPOSIT_OWNER_CONTEXT } from './deposit-owner.context';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';
import { DoStatisticsComponent } from './partials/do-statistics/do-statistics.component';
import { BIMModelDialogComponent } from './dialogs/BIM-models-dialog/BIM-model-dialog.component';
import {
  DepositOrderTransactionConfirmationComponent
} from './partials/deposit-order-confirmation/deposit-order-transaction-confirmation.component';
import {
  DepositOrderTransactionConfirmDialogComponent
} from './dialogs/deposit-order-transaction-confirm-dialog/deposit-order-transaction-confirm-dialog.component';
import {EcosystemModule} from '../../entities/ecosystem/ecosystem.module';
import { DoSitesListComponent } from './partials/do-sites-list/do-sites-list.component';
import {PassportModule} from '../../entities/passport/passport.module';
import {ImportSpreadsheetModule} from '../../import-spreadsheet/import-spreadsheet.module';
import { DoImportComponent } from './partials/do-import/do-import.component';
import {DepositOwnerComponent} from './page/deposit-owner.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    DepositModule,
    PassportModule,
    EcosystemModule,
    ImportSpreadsheetModule,
    RouterModule.forChild([{
      path: DEPOSIT_OWNER_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: DO_ROUTES
    }]),
  ],
  declarations: [
    DepositOwnerDashboardComponent,
    DoArchiveCatalogComponent,
    DoCatalogComponent,
    DoSitesCatalogComponent,
    DoAgentsCatalogComponent,
    DoDepositsCatalogComponent,
    DoOrdersCatalogComponent,
    DepositOrderDetailsComponent,
    DepositOrderDetailsDialogComponent,
    DoDepositsListComponent,
    DoStatisticsComponent,
    BIMModelDialogComponent,
    DepositOrderTransactionConfirmationComponent,
    DepositOrderTransactionConfirmDialogComponent,
    DoSitesListComponent,
    DoImportComponent,
    DepositOwnerComponent
  ],
  entryComponents: [
    DepositOrderDetailsDialogComponent,
    DoDepositsListComponent,
    BIMModelDialogComponent,
    DepositOrderTransactionConfirmDialogComponent
  ],
  exports: [],
  providers: [
    DepositOwnerListService,
    DepositOwnerGuard
  ],
})
export class DepositOwnerModule {
}
