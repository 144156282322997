<div #filterWrapper class="passportlist__family-wrapper" [ngClass]="getClassColor()">
    <div *ngIf="isOpen" class="rating-filter-popup">
        <!-- Cradle to Cradle Checkbox -->
        <div>
            <app-base-checkbox [isChecked]="filterModel.C2cCertified"
                (isCheckedChange)="onFilterChange('C2cCertified', null, $event)"></app-base-checkbox>
            <app-cradle-to-cradle-icon></app-cradle-to-cradle-icon>
        </div>

        <!-- Select all ratings -->
        <div>
            <app-base-checkbox [isChecked]="filterModel.selectRatingFilter"
                (isCheckedChange)="onSelectAllRatingsChange()"></app-base-checkbox>
            <span>{{'Select_all_ratings' | translate}}</span>
        </div>

        <!-- Rating Options -->
        <div class="rating-options">
            <div *ngFor="let rating of ['composition', 'cycle', 'energy', 'water', 'social']; let i = index">
                <div>
                    <app-base-checkbox [isChecked]="filterModel[rating]['color']"
                        (isCheckedChange)="onFilterChange(rating,'color')"></app-base-checkbox>
                    <!-- Conditional Icon Display -->
                    <ng-container [ngSwitch]="rating">
                        <app-rating-composition-icon *ngSwitchCase="'composition'"
                            [toxicityState]="2"></app-rating-composition-icon>
                        <app-rating-recyclability-icon *ngSwitchCase="'cycle'"
                            [toxicityState]="2"></app-rating-recyclability-icon>
                        <app-rating-energy-icon *ngSwitchCase="'energy'" [toxicityState]="2"></app-rating-energy-icon>
                        <app-rating-water-stewardship-icon *ngSwitchCase="'water'"
                            [toxicityState]="2"></app-rating-water-stewardship-icon>
                        <app-rating-social-fairness-icon *ngSwitchCase="'social'"
                            [toxicityState]="2"></app-rating-social-fairness-icon>
                    </ng-container>
                </div>
                <span>or</span>
                <div>
                    <app-base-checkbox [isChecked]="filterModel[rating]['black']"
                        (isCheckedChange)="onFilterChange(rating,'black')"></app-base-checkbox>
                    <!-- Conditional Icon Display -->
                    <ng-container [ngSwitch]="rating">
                        <app-rating-composition-icon *ngSwitchCase="'composition'"
                            [toxicityState]="1"></app-rating-composition-icon>
                        <app-rating-recyclability-icon *ngSwitchCase="'cycle'"
                            [toxicityState]="1"></app-rating-recyclability-icon>
                        <app-rating-energy-icon *ngSwitchCase="'energy'" [toxicityState]="1"></app-rating-energy-icon>
                        <app-rating-water-stewardship-icon *ngSwitchCase="'water'"
                            [toxicityState]="1"></app-rating-water-stewardship-icon>
                        <app-rating-social-fairness-icon *ngSwitchCase="'social'"
                            [toxicityState]="1"></app-rating-social-fairness-icon>
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- Passports without rating -->
        <div>
            <app-base-checkbox [isChecked]="filterModel.passportWithoutRating"
                (isCheckedChange)="onFilterChange('passportWithoutRating', null, $event)"></app-base-checkbox>
            <span>{{'Passports without rating' | translate}}</span>
        </div>
    </div>

    <!-- Search Input -->
    <md-input-container style="width: 100%;" class="passportlist__search-materials" [floatPlaceholder]="'never'" (click)="toggleView()">
        <input mdInput autocomplete="off" class="passportlist__no-caret"
            placeholder="{{ 'Search by Rating' | translate }}">
        <div class="selected-ratings-icons">
            <ng-container *ngIf="filterModel.C2cCertified">
                <div class="icons-container">
                    <app-cradle-to-cradle-icon></app-cradle-to-cradle-icon>
                    <button class="upload-files__delete" (click)="removeC2cCertified()" type="button">
                        <i class="icon icon--cross"></i>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngFor="let rating of ['composition', 'cycle', 'energy', 'water', 'social']">
                <!-- Pour l'état color -->
                <ng-container *ngIf="filterModel[rating].color">
                    <ng-container [ngSwitch]="rating">
                        <div class="icons-container">
                            <app-rating-composition-icon *ngSwitchCase="'composition'"
                                [toxicityState]="2"></app-rating-composition-icon>
                            <app-rating-recyclability-icon *ngSwitchCase="'cycle'"
                                [toxicityState]="2"></app-rating-recyclability-icon>
                            <app-rating-energy-icon *ngSwitchCase="'energy'"
                                [toxicityState]="2"></app-rating-energy-icon>
                            <app-rating-water-stewardship-icon *ngSwitchCase="'water'"
                                [toxicityState]="2"></app-rating-water-stewardship-icon>
                            <app-rating-social-fairness-icon *ngSwitchCase="'social'"
                                [toxicityState]="2"></app-rating-social-fairness-icon>
                            <button class="upload-files__delete" (click)="removeRating(rating, 'color')" type="button">
                                <i class="icon icon--cross"></i>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- Pour l'état black -->
                <ng-container *ngIf="filterModel[rating].black">
                    <ng-container [ngSwitch]="rating">
                        <div class="icons-container">
                            <app-rating-composition-icon *ngSwitchCase="'composition'"
                                [toxicityState]="1"></app-rating-composition-icon>
                            <app-rating-recyclability-icon *ngSwitchCase="'cycle'"
                                [toxicityState]="1"></app-rating-recyclability-icon>
                            <app-rating-energy-icon *ngSwitchCase="'energy'"
                                [toxicityState]="1"></app-rating-energy-icon>
                            <app-rating-water-stewardship-icon *ngSwitchCase="'water'"
                                [toxicityState]="1"></app-rating-water-stewardship-icon>
                            <app-rating-social-fairness-icon *ngSwitchCase="'social'"
                                [toxicityState]="1"></app-rating-social-fairness-icon>
                            <button class="upload-files__delete" (click)="removeRating(rating, 'black')" type="button">
                                <i class="icon icon--cross"></i>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </md-input-container>
</div>