/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./right-arrow-icon.component";
var styles_RightArrowIconComponent = [];
var RenderType_RightArrowIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RightArrowIconComponent, data: {} });
export { RenderType_RightArrowIconComponent as RenderType_RightArrowIconComponent };
export function View_RightArrowIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "12"], ["viewBox", "0 0 7 12"], ["width", "7"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M1 11L6 6L1 1"], ["stroke", "#333333"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null))], null, null); }
export function View_RightArrowIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-right-arrow-icon", [], null, null, null, View_RightArrowIconComponent_0, RenderType_RightArrowIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.RightArrowIconComponent, [], null, null)], null, null); }
var RightArrowIconComponentNgFactory = i0.ɵccf("app-right-arrow-icon", i1.RightArrowIconComponent, View_RightArrowIconComponent_Host_0, {}, {}, []);
export { RightArrowIconComponentNgFactory as RightArrowIconComponentNgFactory };
