<confirm-with-header
  class="request-site-extend-date"
  title="{{'Extend end of work date' | translate}}"
  (onCancel)="mdDialogRef.close()"
  (onConfirm)="onConfirm()"
  [disabled]="!isValidEndDate() || sender.isLoading || sender.isEmptyQueue"
>
  <ul class="request-site-extend-date__hint">
    <li class="request-site-extend-date__hint-description">
      {{'Indicate the new end date of the project.' | translate}}
    </li>
    <li class="request-site-extend-date__hint-requirements">
      {{'For your application to be accepted, you must attach a supporting document' | translate}}
    </li>
    <li class="request-site-extend-date__hint-requirements">
      {{'(Construction schedule, signed declaration, etc.)' | translate}}
    </li>
  </ul>
  <section class="request-site-extend-date__form">
    <span tabindex="0" (focus)="catchFocusEventOnInit($event)"></span>
    <md-input-container class="request-site-extend-date__form-new-date">
      <input #datePicker
             mdInput
             ng2-datetime-picker
             date-only="true"
             placeholder="{{'End of work' | translate}}"
             date-format="DD-MM-YYYY"
             autocomplete="off"
             [(ngModel)]="endDateRequest"
      >
    </md-input-container>
    <section class="request-site-extend-date__form-files-container">
      <div class="request-site-extend-date__form-files-label">{{'Supporting documents' | translate}}</div>
      <div class="request-site-extend-date__form-files">
        <documents-uploader
          class="passport__uploader"
          [files]="sender.files"
          [service]="sender.service"
          [uploadUrl]="uploadFileService.getUploadedFileUrl()"
          [enableQueueFileEdit]="true"
          [autoupload]="false"
          [onUploadAll]="sender.onUploadAll"
          [uploader]="sender.fileUploader"
          (onItemUploaded)="sender.onCompleteItem($event)"
          (onAllItemsUploaded)="sender.onAllItemsUploaded($event)"
        >
        </documents-uploader>
      </div>
    </section>
  </section>
</confirm-with-header>
