import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { CategoryItem } from '../models/category-item.class';
var TransformationCategoryService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformationCategoryService, _super);
    function TransformationCategoryService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-categories';
        _this.namespaceSingular = 'transformation-category';
        _this.ModelClass = CategoryItem;
        return _this;
    }
    return TransformationCategoryService;
}(CrudService));
export { TransformationCategoryService };
