/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-contact.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material";
import * as i3 from "./view-contact.component";
var styles_ViewContactDialogComponent = [i0.styles];
var RenderType_ViewContactDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewContactDialogComponent, data: {} });
export { RenderType_ViewContactDialogComponent as RenderType_ViewContactDialogComponent };
export function View_ViewContactDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "md-dialog-content", [], [[2, "mat-dialog-content", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MdPrefixRejector, [[2, i2.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(2, 16384, null, 0, i2.MdDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " ", " ", " ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.contact.name; var currVal_2 = _co.contact.function; var currVal_3 = _co.contact.phone; var currVal_4 = _co.contact.email; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_ViewContactDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "view-contact", [], null, null, null, View_ViewContactDialogComponent_0, RenderType_ViewContactDialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.ViewContactDialogComponent, [i2.MdDialogRef], null, null)], null, null); }
var ViewContactDialogComponentNgFactory = i1.ɵccf("view-contact", i3.ViewContactDialogComponent, View_ViewContactDialogComponent_Host_0, { contact: "contact" }, {}, []);
export { ViewContactDialogComponentNgFactory as ViewContactDialogComponentNgFactory };
