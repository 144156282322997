/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../entities/ecosystem-offer/components/view-offer/view-offer.component.ngfactory";
import * as i2 from "../../../../entities/ecosystem-offer/components/view-offer/view-offer.component";
import * as i3 from "@angular/material";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../entities/ecosystem-offer/ecosystem-offer.service";
import * as i6 from "../../../../entities/ecosystem/services/ecosystem-file-junctions.service";
import * as i7 from "../../../../entities/need/services/need.service";
import * as i8 from "../../../../shared/services/log-notification.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./ecosystem-offer-dialog.component";
var styles_EcosystemOfferDialogComponent = [];
var RenderType_EcosystemOfferDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EcosystemOfferDialogComponent, data: {} });
export { RenderType_EcosystemOfferDialogComponent as RenderType_EcosystemOfferDialogComponent };
export function View_EcosystemOfferDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "view-offer", [], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ViewOfferComponent_0, i1.RenderType_ViewOfferComponent)), i0.ɵdid(1, 114688, null, 0, i2.ViewOfferComponent, [i3.MdDialog, i4.FormBuilder, i5.EcosystemOfferService, i6.EcosystemFileJunctionService, i7.NeedService, i8.LogNotificationService, i9.TranslateService], { ecosystem: [0, "ecosystem"] }, { action: "action" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mdDialogData.ecosystem; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EcosystemOfferDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_EcosystemOfferDialogComponent_0, RenderType_EcosystemOfferDialogComponent)), i0.ɵdid(1, 49152, null, 0, i10.EcosystemOfferDialogComponent, [i3.MdDialogRef, i3.MD_DIALOG_DATA], null, null)], null, null); }
var EcosystemOfferDialogComponentNgFactory = i0.ɵccf("ng-component", i10.EcosystemOfferDialogComponent, View_EcosystemOfferDialogComponent_Host_0, {}, {}, []);
export { EcosystemOfferDialogComponentNgFactory as EcosystemOfferDialogComponentNgFactory };
