/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../entities/spinner/LoaderComponent.ngfactory";
import * as i2 from "../../../../entities/spinner/LoaderComponent";
import * as i3 from "../../../services/navigation.service";
import * as i4 from "../notification-list/notification-list.component.ngfactory";
import * as i5 from "../notification-list/notification-list.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../read-only/form-elems-hidden.directive";
import * as i8 from "../../../read-only/read-only.service";
import * as i9 from "./notification-wrapper.component";
import * as i10 from "../../notification.service";
import * as i11 from "@angular/router";
var styles_NotificationWrapperComponent = [];
var RenderType_NotificationWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationWrapperComponent, data: {} });
export { RenderType_NotificationWrapperComponent as RenderType_NotificationWrapperComponent };
function View_NotificationWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "notifier--count"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-loader", [["style", "width: 15px;height: 15px;"]], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(2, 49152, null, 0, i2.LoaderComponent, [i3.NavigationService], { width: [0, "width"], height: [1, "height"] }, null)], function (_ck, _v) { var currVal_0 = "15px"; var currVal_1 = "15px"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_NotificationWrapperComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "notifier--count"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatTotalCount(_co.nbrNotifications); _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationWrapperComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notification-list", [["id", "notificationList"]], null, [[null, "onRead"], [null, "onAction"], [null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onRead" === en)) {
        var pd_0 = (_co.readNotification($event) !== false);
        ad = (pd_0 && ad);
    } if (("onAction" === en)) {
        var pd_1 = (_co.callToAction($event) !== false);
        ad = (pd_1 && ad);
    } if (("scroll" === en)) {
        var pd_2 = (_co.onScroll() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_NotificationListComponent_0, i4.RenderType_NotificationListComponent)), i0.ɵdid(1, 49152, null, 0, i5.NotificationListComponent, [], { records: [0, "records"], isLoading: [1, "isLoading"] }, { onRead: "onRead", onAction: "onAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifications; var currVal_1 = _co.isLoading; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_NotificationWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "notifier_group"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleNotifications() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "ib-icon notifier--icon"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationWrapperComponent_1)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationWrapperComponent_2)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "notification-list-bar"]], null, null, null, null, null)), i0.ɵdid(7, 278528, null, 0, i6.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(8, { "opened": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationWrapperComponent_3)), i0.ɵdid(10, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "button", [["class", "toggle-button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleNotifications() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 16384, null, 0, i7.FormElemsHiddenDirective, [[2, i8.ReadOnlyService]], null, null), (_l()(), i0.ɵeld(13, 0, null, null, 0, "i", [["class", "icon icon--toggle_arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 5, 0, currVal_1); var currVal_2 = "notification-list-bar"; var currVal_3 = _ck(_v, 8, 0, _co.isBarShown); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _co.isBarShown; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_5 = ((i0.ɵnov(_v, 12).readOnlyService == null) ? null : i0.ɵnov(_v, 12).readOnlyService.readOnly); _ck(_v, 11, 0, currVal_5); }); }
export function View_NotificationWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notification-wrapper", [], null, null, null, View_NotificationWrapperComponent_0, RenderType_NotificationWrapperComponent)), i0.ɵdid(1, 573440, null, 0, i9.NotificationWrapperComponent, [i10.NotificationService, i11.Router], null, null)], null, null); }
var NotificationWrapperComponentNgFactory = i0.ɵccf("notification-wrapper", i9.NotificationWrapperComponent, View_NotificationWrapperComponent_Host_0, { context: "context" }, {}, []);
export { NotificationWrapperComponentNgFactory as NotificationWrapperComponentNgFactory };
