/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tree-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-tree-component/dist/components/tree.component.ngfactory";
import * as i3 from "angular-tree-component/dist/models/tree.model";
import * as i4 from "angular-tree-component/dist/components/tree.component";
import * as i5 from "angular-tree-component/dist/models/tree-dragged-element.model";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "../../service/category.service";
import * as i9 from "@angular/http";
import * as i10 from "../../../../shared/services/log-notification.service";
import * as i11 from "../../../../shared/services/navigation.service";
import * as i12 from "../../../../shared/services/mock/mock-api.service";
import * as i13 from "./tree-view.component";
var styles_TreeViewComponent = [i0.styles];
var RenderType_TreeViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TreeViewComponent, data: {} });
export { RenderType_TreeViewComponent as RenderType_TreeViewComponent };
function View_TreeViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.node.data.name; _ck(_v, 0, 0, currVal_0); }); }
function View_TreeViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tree-root", [], null, [[null, "activate"], [null, "event"], ["body", "keydown"], ["body", "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("body:mousedown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMousedown($event) !== false);
        ad = (pd_1 && ad);
    } if (("activate" === en)) {
        var pd_2 = (_co.closeDialog($event) !== false);
        ad = (pd_2 && ad);
    } if (("event" === en)) {
        var pd_3 = (_co.onTreeEvent($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_TreeComponent_0, i2.RenderType_TreeComponent)), i1.ɵprd(512, null, i3.TreeModel, i3.TreeModel, []), i1.ɵdid(2, 573440, [[1, 4], ["tree", 4]], 4, i4.TreeComponent, [i3.TreeModel, i5.TreeDraggedElement, i1.Renderer], { nodes: [0, "nodes"] }, { activate: "activate", event: "event" }), i1.ɵqud(335544320, 2, { loadingTemplate: 0 }), i1.ɵqud(335544320, 3, { treeNodeTemplate: 0 }), i1.ɵqud(335544320, 4, { treeNodeWrapperTemplate: 0 }), i1.ɵqud(335544320, 5, { treeNodeFullTemplate: 0 }), (_l()(), i1.ɵand(0, [[3, 2], ["treeNodeTemplate", 2]], null, 0, null, View_TreeViewComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.treeData[0].children; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TreeViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { tree: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "nomenclature-browser"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "nomenclature-browser__filter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [["class", "nomenclature-browser__filter-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "nomenclature-browser__tree"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeViewComponent_1)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(9, null, ["\n", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.treeData; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Category")); _ck(_v, 4, 0, currVal_0); var currVal_2 = _co.nodes; _ck(_v, 9, 0, currVal_2); }); }
export function View_TreeViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "category-tree-view", [], null, null, null, View_TreeViewComponent_0, RenderType_TreeViewComponent)), i1.ɵprd(512, null, i8.CategoryService, i8.CategoryService, [i9.Http, i10.LogNotificationService, i11.NavigationService, i6.TranslateService, i12.MockApiService]), i1.ɵdid(2, 245760, null, 0, i13.TreeViewComponent, [i8.CategoryService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TreeViewComponentNgFactory = i1.ɵccf("category-tree-view", i13.TreeViewComponent, View_TreeViewComponent_Host_0, { viewId: "viewId" }, { onSave: "onSave" }, []);
export { TreeViewComponentNgFactory as TreeViewComponentNgFactory };
