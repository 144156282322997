import * as tslib_1 from "tslib";
import { ApiService } from '../../shared/services/api.service';
var TestDataGenerationService = /** @class */ (function (_super) {
    tslib_1.__extends(TestDataGenerationService, _super);
    function TestDataGenerationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TestDataGenerationService.prototype.getNewEntityWithGeneratedData = function () {
        return this.sendPost(this.getEndpoint('company/test'))
            .map(function (data) { return data.text(); });
        // return Observable.of({check : true});
    };
    return TestDataGenerationService;
}(ApiService));
export { TestDataGenerationService };
