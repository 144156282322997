import { ImportConfigurationService } from './import-configuration.service';
import { CurrentUserService } from '../../shared/services/current-user.service';
import { ImportProgressService } from './import-progress.service';
import { DepositImportService } from '../../entities/deposit/services/deposit-import.service';
import { DepositLocationImportService } from '../../entities/deposit/services/deposit-location-import.service';
import { Observable } from 'rxjs/Observable';
import { first } from 'rxjs/operators';
import { ImportDeepstreamService } from './import-deepstream.service';
import { ActiveImportService } from './active-import.service';
import { ActiveImport } from '../models/active-import.class';
import { ACTIVE_IMPORT_STREAM_JUNCTION } from '../values/active-import-stream-junction.const';
import * as i0 from "@angular/core";
import * as i1 from "./import-configuration.service";
import * as i2 from "../../shared/services/current-user.service";
import * as i3 from "./import-progress.service";
import * as i4 from "../../entities/deposit/services/deposit-import.service";
import * as i5 from "../../entities/deposit/services/deposit-location-import.service";
import * as i6 from "./import-deepstream.service";
import * as i7 from "./active-import.service";
var ImportWorkerService = /** @class */ (function () {
    function ImportWorkerService(importConfigurationService, currentUserService, importProgressService, depositImportService, depositLocationImportService, importDeepstreamService, activeImportService) {
        this.importConfigurationService = importConfigurationService;
        this.currentUserService = currentUserService;
        this.importProgressService = importProgressService;
        this.depositImportService = depositImportService;
        this.depositLocationImportService = depositLocationImportService;
        this.importDeepstreamService = importDeepstreamService;
        this.activeImportService = activeImportService;
    }
    ImportWorkerService.prototype.getImportIfExist = function () {
        var _this = this;
        var prefObserver = this.checkIsImportWorkerRunning();
        var events = this.importDeepstreamService.getAllImportStream();
        var skipLoad = Observable.merge.apply(Observable, Object.values(events)).pipe(first())
            .map(function (data, index) {
            return _this.createActiveImportFromStream(data);
        });
        return Observable.merge(prefObserver, skipLoad).pipe(first());
    };
    ImportWorkerService.prototype.checkIsImportWorkerRunning = function () {
        var user_id = this.currentUserService.infoData.id;
        return this.activeImportService.get({ user_id: user_id });
    };
    ImportWorkerService.prototype.createActiveImportFromStream = function (_a) {
        var value = _a.value, type = _a.type, stream = _a.stream;
        return [new ActiveImport({
                import_id: value.data.import_id,
                import_type: type,
                status: ACTIVE_IMPORT_STREAM_JUNCTION[stream]
            })];
    };
    ImportWorkerService.ngInjectableDef = i0.defineInjectable({ factory: function ImportWorkerService_Factory() { return new ImportWorkerService(i0.inject(i1.ImportConfigurationService), i0.inject(i2.CurrentUserService), i0.inject(i3.ImportProgressService), i0.inject(i4.DepositImportService), i0.inject(i5.DepositLocationImportService), i0.inject(i6.ImportDeepstreamService), i0.inject(i7.ActiveImportService)); }, token: ImportWorkerService, providedIn: "root" });
    return ImportWorkerService;
}());
export { ImportWorkerService };
