/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-passport-step.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../passport/partials/edit-passport/edit-passport.component.ngfactory";
import * as i3 from "../../../../passport/services/passport-gtin-buffer.service";
import * as i4 from "../../../../passport/services/passport-gtin.service";
import * as i5 from "../../../../../shared/read-only/read-only.service";
import * as i6 from "../../../../passport/partials/edit-passport/edit-passport.component";
import * as i7 from "@angular/material";
import * as i8 from "@angular/forms";
import * as i9 from "../../../../passport/services/passport.service";
import * as i10 from "../../../../passport/services/passport-file.service";
import * as i11 from "../../../../passport/services/format.service";
import * as i12 from "../../../../../shared/services/navbar.service";
import * as i13 from "../../../../../shared/services/loading-spinner.service";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../shared/services/navigation.service";
import * as i16 from "../../../../../shared/services/user.service";
import * as i17 from "../../../../passport/services/material.service";
import * as i18 from "@ngx-translate/core";
import * as i19 from "../../../../../shared/services/log-notification.service";
import * as i20 from "../../../../passport/services/passport-validation-notification.service";
import * as i21 from "../../../../passport/services/passport-group-builder/passport-group-builder.service";
import * as i22 from "./product-passport-step.component";
var styles_ProductPassportStepComponent = [i0.styles];
var RenderType_ProductPassportStepComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductPassportStepComponent, data: {} });
export { RenderType_ProductPassportStepComponent as RenderType_ProductPassportStepComponent };
export function View_ProductPassportStepComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "passport-edit-form", [], [[2, "highlighted", null]], [[null, "onChange"], [null, "onSaveComplete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onChangePassport($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSaveComplete" === en)) {
        var pd_1 = (_co.onSaveCompleteHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EditPassportComponent_0, i2.RenderType_EditPassportComponent)), i1.ɵprd(512, null, i3.PassportGtinBufferService, i3.PassportGtinBufferService, [i4.PassportGtinService]), i1.ɵprd(512, null, i5.ReadOnlyService, i5.ReadOnlyService, []), i1.ɵdid(3, 8634368, null, 0, i6.EditPassportComponent, [i7.MdDialog, i8.FormBuilder, i3.PassportGtinBufferService, i9.PassportService, i10.PassportFileService, i11.PassportFormatService, i12.NavbarStateService, i13.LoadingSpinnerService, i14.Router, i5.ReadOnlyService, i15.NavigationService, i16.UserService, i17.MaterialService, i1.Renderer, i18.TranslateService, i1.ChangeDetectorRef, i19.LogNotificationService, i20.PassportValidationNotificationService, i1.ChangeDetectorRef, i21.PassportGroupBuilderService, i4.PassportGtinService], { listener: [0, "listener"], showCatalogNameMenu: [1, "showCatalogNameMenu"], disabled: [2, "disabled"], parentModule: [3, "parentModule"], passport: [4, "passport"], readOnly: [5, "readOnly"], controlStates: [6, "controlStates"] }, { onChange: "onChange", onSaveComplete: "onSaveComplete" }), i1.ɵncd(1, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.listener; var currVal_2 = _co.showCatalogNameMenu; var currVal_3 = ((_co.controlStates == null) ? null : ((_co.controlStates.passport_id == null) ? null : _co.controlStates.passport_id.disabled)); var currVal_4 = _co.parentModule; var currVal_5 = _co.need.passport; var currVal_6 = _co.readOnly; var currVal_7 = ((_co.controlStates == null) ? null : _co.controlStates.passport); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.controlStates == null) ? null : ((_co.controlStates.passport_id == null) ? null : _co.controlStates.passport_id.highlighted)); _ck(_v, 0, 0, currVal_0); }); }
export function View_ProductPassportStepComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "product-passport-step", [], null, null, null, View_ProductPassportStepComponent_0, RenderType_ProductPassportStepComponent)), i1.ɵdid(1, 245760, null, 0, i22.ProductPassportStepComponent, [i7.MdDialog, i15.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductPassportStepComponentNgFactory = i1.ɵccf("product-passport-step", i22.ProductPassportStepComponent, View_ProductPassportStepComponent_Host_0, { need: "need", showCatalogNameMenu: "showCatalogNameMenu", controlStates: "controlStates", listener: "listener", readOnly: "readOnly" }, { onSaveComplete: "onSaveComplete" }, ["[passport-search-message]"]);
export { ProductPassportStepComponentNgFactory as ProductPassportStepComponentNgFactory };
