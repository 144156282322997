import { Client } from './../../../../../../shared/detail-view/client';
import { ClientService } from './../../../../../client/service/client.service';
import { EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MdDialogRef } from '@angular/material';
import { LogoUploaderComponent } from './../../../../../../shared/uploaders/logo-uploader/logo-uploader.component';
var NewRecipientsDialogComponent = /** @class */ (function () {
    function NewRecipientsDialogComponent(fb, clientService, dialogRef, data) {
        this.fb = fb;
        this.clientService = clientService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.siteCreated = new EventEmitter();
        this.client = new Client();
        this.isUploading = false;
        this.logoIsUpdating = false;
        this.initFormGroup(data);
    }
    /**
     * Initialize form group with provided client data or default values.
     * @param data Client data.
     */
    NewRecipientsDialogComponent.prototype.initFormGroup = function (data) {
        this.formGroup = this.fb.group({
            client_id: [null],
            name: [data.name || '', [Validators.required]],
            contacts: this.initContacts(data.client_contacts || []),
        });
        if (data.logo) {
            this.client.logo = data.logo;
        }
    };
    /**
     * Handle form submission. If the form is valid, determine whether to create or update.
     */
    NewRecipientsDialogComponent.prototype.onSubmit = function () {
        if (this.formGroup.valid) {
            this.isUploading = true;
            var formData = this.processForm();
            var postProcessing = this.getPostProcessingFunction();
            // If ID is present, it's an update.
            if (this.data && this.data.id) {
                this.clientService.update(this.data.id, formData)
                    .subscribe(postProcessing, this.handleSubmissionError.bind(this));
            }
            else {
                // Else, it's a creation.
                this.clientService.create(formData)
                    .subscribe(postProcessing, this.handleSubmissionError.bind(this));
            }
        }
        else {
            this.isUploading = false;
            console.error("Required fields are not filled");
        }
    };
    /**
     * Process the form and returns the final data to be submitted.
     */
    NewRecipientsDialogComponent.prototype.processForm = function () {
        var formData = this.formGroup.value;
        // Clean the name by removing leading and trailing spaces
        formData.name = formData.name.trim();
        // Set fullName for each contact
        formData.contacts.forEach(function (contact) {
            contact.name = contact.name.trim();
            contact.firstName = contact.firstName.trim();
            contact.name = contact.name + " " + contact.firstName;
            // Do the same for other contact properties if needed
            if (contact.function) {
                contact.function = contact.function.trim();
            }
            if (contact.phone) {
                contact.phone = contact.phone.trim();
            }
            if (contact.email) {
                contact.email = contact.email.trim();
            }
        });
        return formData;
    };
    /**
     * Returns the post-processing function after a successful submission.
     */
    NewRecipientsDialogComponent.prototype.getPostProcessingFunction = function () {
        var _this = this;
        return function (response) {
            if (_this.logoIsUpdating) { // Check if there's a new logo to upload.
                if (_this.logoUploader.isEmptyQueue()) {
                    _this.clientService.removeLogo(response.id)
                        .subscribe(_this.handleSuccess.bind(_this, response), _this.handleUploadError.bind(_this));
                }
                else {
                    _this.logoUploader.triggerUpload(_this.getLogoUploaderUrl(response.id))
                        .then(_this.handleSuccess.bind(_this, response))
                        .catch(_this.handleUploadError.bind(_this));
                }
            }
            else {
                _this.handleSuccess(response);
            }
        };
    };
    /**
     * Handles the post submission success scenario.
     */
    NewRecipientsDialogComponent.prototype.handleSuccess = function (client) {
        this.siteCreated.emit(true);
        this.closeDialog(client);
        this.isUploading = false;
    };
    /**
     * Handles any errors that occur during form submission.
     * @param error Error object.
     */
    NewRecipientsDialogComponent.prototype.handleSubmissionError = function (error) {
        this.isUploading = false;
        console.error("Error while processing the site", error);
    };
    /**
     * Handles any errors that occur during logo upload.
     * @param error Error object.
     */
    NewRecipientsDialogComponent.prototype.handleUploadError = function (error) {
        this.isUploading = false;
        console.error("Error while uploading the logo", error);
    };
    /**
     * Initialize contacts array form group.
     * @param contacts Contacts data.
     */
    NewRecipientsDialogComponent.prototype.initContacts = function (contacts) {
        var _this = this;
        var contactFormGroups;
        if (contacts && contacts.length) {
            contactFormGroups = contacts.map(function (contact) { return _this.initContact(contact); });
        }
        else {
            contactFormGroups = [this.initContact()];
        }
        return this.fb.array(contactFormGroups, this.atLeastOneContactValidator);
    };
    /**
     * Initialize a single contact form group.
     * @param contact Contact data with specific properties id, name, function, phone, and email.
     */
    NewRecipientsDialogComponent.prototype.initContact = function (contact) {
        // Use destructuring to extract properties from the contact object, providing default values
        var _a = contact || {}, _b = _a.id, id = _b === void 0 ? null : _b, _c = _a.name, fullName = _c === void 0 ? '' : _c, _d = _a.function, contactFunction = _d === void 0 ? '' : _d, _e = _a.phone, phone = _e === void 0 ? '' : _e, _f = _a.email, email = _f === void 0 ? '' : _f;
        // Split the full name into name and firstName, if available
        var _g = fullName.split(' '), name = _g[0], firstNameParts = _g.slice(1);
        var firstName = firstNameParts.join(' ');
        // Use FormBuilder to create a group with form controls and respective validators
        return this.fb.group({
            id: [id],
            name: [name, [Validators.required, this.nameValidator]],
            firstName: [firstName, [Validators.required, this.nameValidator]],
            function: [contactFunction],
            phone: [phone, [Validators.pattern(/^\+?[0-9\s]+$/)]],
            email: [email, [Validators.email]],
        });
    };
    /**
     * Validator to ensure at least one contact exists in the contacts form array.
     * @param formArray FormArray of contacts.
     */
    NewRecipientsDialogComponent.prototype.atLeastOneContactValidator = function (formArray) {
        if (formArray.length < 1) {
            return { 'noContact': true };
        }
        return null;
    };
    NewRecipientsDialogComponent.prototype.nameValidator = function (control) {
        var nameRegexp = /^[A-Za-zÀ-ÖØ-öø-ÿ\s\-]*$/; // Regex to check that the string contains only letters, hyphens and spaces (accepts accentuated letters)
        if (!control.value.match(nameRegexp)) {
            return { 'invalidName': true };
        }
        return null;
    };
    NewRecipientsDialogComponent.prototype.addContact = function () {
        this.formGroup.controls['contacts'].push(this.initContact());
    };
    NewRecipientsDialogComponent.prototype.removeContact = function (index) {
        if (index == 0)
            return; // Can't delete the first contact
        this.formGroup.controls['contacts'].removeAt(index);
    };
    NewRecipientsDialogComponent.prototype.closeDialog = function (client) {
        this.dialogRef.close(client);
    };
    NewRecipientsDialogComponent.prototype.getLogoUploaderUrl = function (id) {
        return this.clientService.getLogoUploadUrl(id);
    };
    NewRecipientsDialogComponent.prototype.onLogoUpload = function (response) {
        var data = typeof response === 'string' ? JSON.parse(response) : response;
        if (data) {
            this.client.logo = data.image;
        }
    };
    NewRecipientsDialogComponent.prototype.onClickLogoUpload = function () {
        this.logoIsUpdating = true;
    };
    NewRecipientsDialogComponent.prototype.removeClientLogo = function () {
        this.logoIsUpdating = true;
    };
    Object.defineProperty(NewRecipientsDialogComponent.prototype, "contactsArray", {
        get: function () {
            return this.formGroup.get('contacts');
        },
        enumerable: true,
        configurable: true
    });
    return NewRecipientsDialogComponent;
}());
export { NewRecipientsDialogComponent };
