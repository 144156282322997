import { Client } from './client.class';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { LocationService } from '../service/location.service';
import { IMapMarker } from '../../../shared/map-view/map-marker.interface';
export class ClientLocation extends ExtendedActiveRecord implements IMapMarker {
  protected provider: any = LocationService;

  id: number;
  client: Client;
  name: string;
  address: string;
  description: string;
  lat: number;
  lng: number;
  needCount?: number;

  fields() {
    return ['id', 'client', 'name', 'address', 'description', 'lat', 'lng', 'needCount'];
  }
}
