export enum OrganisationTIds {
    Territory = 1,
    Building = 2,
    Company = 3
}

export const ORGANISATION_TYPES: {
    text: string ,
    value: number ,
    } [] = [
    {
      text: 'Territory',
      value: OrganisationTIds.Territory
    } ,
    {
      text: 'Building',
      value: OrganisationTIds.Building
    },
    {
      text: 'Company',
      value: OrganisationTIds.Company
    }
];
