/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./material-pie-chart.component";
var styles_MaterialPieChartComponent = [];
var RenderType_MaterialPieChartComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MaterialPieChartComponent, data: {} });
export { RenderType_MaterialPieChartComponent as RenderType_MaterialPieChartComponent };
export function View_MaterialPieChartComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { pieChartRefEl: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["containerPieChart", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_MaterialPieChartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "material-pie-chart", [], null, null, null, View_MaterialPieChartComponent_0, RenderType_MaterialPieChartComponent)), i0.ɵdid(1, 4243456, null, 0, i1.MaterialPieChartComponent, [], null, null)], null, null); }
var MaterialPieChartComponentNgFactory = i0.ɵccf("material-pie-chart", i1.MaterialPieChartComponent, View_MaterialPieChartComponent_Host_0, { width: "width", height: "height", data: "data" }, {}, []);
export { MaterialPieChartComponentNgFactory as MaterialPieChartComponentNgFactory };
