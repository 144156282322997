import { Component } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { MdDialogRef } from '@angular/material';
import {User} from "../../../shared/models/user.class";

@Component({
  moduleId: module.id,
  selector: 'company-deletion-dialog',
  templateUrl: 'company-deletion-dialog.component.html'
})

export class CompanyDeletionDialogComponent {

  public companyId: number;
  command: string = '';
  buttonDisabled: boolean = false;

  constructor(public _dialog: MdDialogRef<any>,
              private _userService: UserService) {
    this.companyId = this._dialog._containerInstance.dialogConfig.data.companyId;
  }

  deleteCompany() {
    this.buttonDisabled = true;
    this._userService.deleteWholeCompany(this.companyId, this.command)
      .subscribe((successfull) => {
        this._dialog.close(successfull);
      });
  }

  }
