<div #filterWrapper class="selectable-list-filter-wrapper" [ngClass]="getClassColor()">
  <div *ngIf="isOpen" class="selectable-list-filter">
    <div *ngIf="enableSearch" class="search-container">
      <md-input-container>
        <input #searchInput mdInput [(ngModel)]="searchTerm" (ngModelChange)="filterOptions()"
          placeholder="{{'Search...' | translate}}" class="filter-search-input">
      </md-input-container>
      <span class="filter-result-count">{{ filteredOptionsCount }}</span>
    </div>
    <div class="result-filter-container">
      <div class="selectable-list-filter-row" *ngIf="filteredOptions.length > 0 && searchTerm.length === 0">
        <app-base-checkbox [isChecked]="allSelected" (isCheckedChange)="onAllSelectedChange()"
          label="{{'SELECT_UNSELECT_ALL' | translate}}">
        </app-base-checkbox>
      </div>
      <ng-container *ngFor="let option of filteredOptions; trackBy: trackByFn">
        <div class="selectable-list-filter-row">
          <app-base-checkbox [(isChecked)]="option.selected" (isCheckedChange)="onOptionChange(option)"
            label="{{ option.name | translate }}">
          </app-base-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
  <md-input-container class="selectable-list-search-materials" [floatPlaceholder]="'never'" (click)="toggleView()">
    <input mdInput autocomplete="off" [disabled]="disabled" placeholder="{{placeholder | translate}}" readonly>
    <md-chip-list>
      <md-basic-chip *ngFor="let tag of selectedItems.slice(0, maxItemsDisplayed)" (focus)="false">
        {{ tag | translate}}
        <button (click)="removeTag(tag, $event)" class="delete"></button>
      </md-basic-chip>
      <md-basic-chip *ngIf="selectedItems.length > maxItemsDisplayed" (focus)="false">
        {{ moreItemsLabel }}
      </md-basic-chip>
    </md-chip-list>
    <div class="selectable-list__info-icons">
      <span class="mat-select-arrow"></span>
    </div>
  </md-input-container>
</div>