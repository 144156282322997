export const MONTH_NAMES_EN:  {
  fullName: string,
  shortName: string
}[] =[
  {
    fullName:'January',
    shortName:'Jan'
  },
  {
    fullName:'February',
    shortName:'Feb'
  },
  {
    fullName:'March',
    shortName:'Mar'
  },
  {
    fullName:'April',
    shortName:'Apr'
  },
  {
    fullName:'May',
    shortName:'May'
  },

  {
    fullName:'June',
    shortName:'Jun'
  },
  {
    fullName:'July',
    shortName:'Jul'
  },
  {
    fullName:'August',
    shortName:'Aug'
  },
  {
    fullName:'September',
    shortName:'Sep'
  },
  {
    fullName:'October',
    shortName:'Oct'
  },
  {
    fullName:'November',
    shortName:'Nov'
  },
  {
    fullName:'December',
    shortName:'Dec'
  }
];


export const MONTH_NAMES_FR:  {
  fullName: string,
  shortName: string
}[] =[
  {
    fullName:'Janvier',
    shortName:'Jan'
  },
  {
    fullName:'Février',
    shortName:'Fév'
  },
  {
    fullName:'Mars',
    shortName:'Mar'
  },
  {
    fullName:'Avril',
    shortName:'Avr'
  },
  {
    fullName:'Mai',
    shortName:'Mai'
  },

  {
    fullName:'Juin',
    shortName:'Juin'
  },
  {
    fullName:'Juillet',
    shortName:'Juil'
  },
  {
    fullName:'Août',
    shortName:'Aou'
  },
  {
    fullName:'Septembre',
    shortName:'Sep'
  },
  {
    fullName:'Octobre',
    shortName:'Oct'
  },
  {
    fullName:'Novembre',
    shortName:'Nov'
  },
  {
    fullName:'December',
    shortName:'Déc'
  }
];

export const MONTH_NAMES_ES:  {
  fullName: string,
  shortName: string
}[] = [
  {
    fullName: 'Enero',
    shortName: 'Ene'
  },
  {
    fullName: 'Febrero',
    shortName: 'Feb'
  },
  {
    fullName: 'Marzo',
    shortName: 'Mar'
  },
  {
    fullName: 'Abril',
    shortName: 'Abr'
  },
  {
    fullName: 'Mayo',
    shortName: 'May'
  },

  {
    fullName: 'Junio',
    shortName: 'Jun'
  },
  {
    fullName: 'Julio',
    shortName: 'Jul'
  },
  {
    fullName: 'Agosto',
    shortName: 'Ago'
  },
  {
    fullName: 'Septiembre',
    shortName: 'Sep'
  },
  {
    fullName: 'Octubre',
    shortName: 'Oct'
  },
  {
    fullName: 'Noviembre',
    shortName: 'Nov'
  },
  {
    fullName: 'Diciembre',
    shortName: 'Dic'
  }
];
