import {Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FilterBy} from '../../../../../../shared/interfaces/filter-states.interface';

@Component({
  selector: 'rating-filter',
  templateUrl: './rating-filter.component.html',
  styleUrls: ['./rating-filter.component.scss']
})
export class RatingFilterComponent implements OnInit {
  public readonly OBJECT = 'object';
  public readonly BOOLEAN = 'boolean';

  @Input() ratingFilter;
  @Input('aliasIcon') set setAliasIcon(array) {
    this.aliasIcon = {...this.defaultIconAlias, ...array};
  }
  @Input('nameAlias') set setNameAlias(array) {
    this.nameAlias = {...this.defaultNameAlias, ...array};
  }
  @Input() aliasClass = {};
  @Output() ratingFilterChange = new EventEmitter<any>();
  @Input() isSelectAllShown = true;
  @Input() selectAllIndex = 0;
  @Input() showIconsByDefault = true;
  @Input() showLabelByDefault = false;
  @Input() placeholder = 'Search by Rating';
  @Input() ratingApplyRule: (rating: object) => boolean = this.defaultRatingApplyRule;

  @ViewChild('filterRating') filterRating;
  @ViewChild('option') filterHTMLRating;

  aliasIcon;
  nameAlias;
  public showFilterByRating = false;
  private defaultIconAlias = {C2cCertified : 'certification'};
  private defaultNameAlias = {};

  @HostListener('document:focusin', ['$event.target'])
  public onFocus(targetElement) {
    this.checkIfElementInsideComponent(targetElement);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    this.checkIfElementInsideComponent(targetElement);
  }

  ngOnInit() {
    this.nameAlias = this.nameAlias || {...this.defaultNameAlias, ...this.nameAlias};
    this.aliasIcon = this.aliasIcon || {...this.defaultIconAlias, ...this.aliasIcon};
  }

  private checkIfElementInsideComponent(targetElement) {
    if (this.filterRating && !this.filterRating.nativeElement.contains(targetElement)) {
      this.showFilterByRating = false;
    }
  }

  public onClickShowRating() {
    this.showFilterByRating = true;
    setTimeout(() => {
      if (this.filterRating) {
        this.filterRating.nativeElement   .focus();
      }
    });
  }

  public onEnterShowRating($event) {
    if ($event.key === 'Enter' || $event.key === ' ') {
      this.showFilterByRating = true;
      this.focusInOption();
    }
  }

  private focusInOption() {
    setTimeout(() => {
      if (this.filterHTMLRating) {
        this.filterHTMLRating.focus();
      }
    });
  }

  selectOrDeselectAllRatingFilters(event): void {
    const check = event.checked;
    this.ratingKeys.forEach(key => {
      if (this.getItemType(key) === this.OBJECT) {
        this.ratingFilter[key].color = check;
        this.ratingFilter[key].black = check;
      } else {
        this.ratingFilter[key] = check;
      }
    });
    this.ratingFilterChange.emit(this.ratingFilter);
  }

  get isRatingFilterApplied(): boolean {
    return this.ratingApplyRule(this.ratingFilter);
  }

  private defaultRatingApplyRule(filters: object): boolean {
    return this.ratingKeys.map(key => {
      return this.getItemType(key) === this.OBJECT ?
        (this.ratingFilter[key].color || this.ratingFilter[key].black) : this.ratingFilter[key];
    }).some(a => a);
  }

  get isAllFilterSelected(): boolean {
    return this.ratingKeys.map(key => {
        return this.getItemType(key) === this.OBJECT
          ? (this.ratingFilter[key].color && this.ratingFilter[key].black) : this.ratingFilter[key];
      }).every(a => a);
  }

  get ratingKeys() {
    return Object.keys(this.ratingFilter);
  }

  getItemType(key) {
    return typeof this.ratingFilter[key] === 'object' ? this.OBJECT : this.BOOLEAN;
  }

  getIcon(key: string) {
    return (key in this.aliasIcon) ? this.aliasIcon[key] : (this.showIconsByDefault ? key : null);
  }

  getName(key: string) {
    return (key in this.nameAlias) ? this.nameAlias[key] : (this.showLabelByDefault ? key : null);
  }
}
