/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ecosystem-file.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../shared/uploaders/documents-uploader/document-item/document-item.component.ngfactory";
import * as i4 from "../../../../shared/uploaders/documents-uploader/document-item/document-item.component";
import * as i5 from "../../../../shared/services/log-notification.service";
import * as i6 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i7 from "@angular/material";
import * as i8 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i9 from "../../../../shared/read-only/read-only.service";
import * as i10 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i11 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i12 from "@angular/common";
import * as i13 from "ng2-file-upload/file-upload/file-select.directive";
import * as i14 from "../../../../shared/read-only/form-elems-read-only.directive";
import * as i15 from "./ecosystem-file.component";
import * as i16 from "../../../../entities/ecosystem/services/ecosystem-file.service";
var styles_EcosystemFileDialogComponent = [i0.styles];
var RenderType_EcosystemFileDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EcosystemFileDialogComponent, data: {} });
export { RenderType_EcosystemFileDialogComponent as RenderType_EcosystemFileDialogComponent };
function View_EcosystemFileDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Add file")); _ck(_v, 1, 0, currVal_0); }); }
function View_EcosystemFileDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Attached files")); _ck(_v, 1, 0, currVal_0); }); }
function View_EcosystemFileDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ul", [["class", "ecosystem-file__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "li", [["class", "separator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadFiles() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("Files from Ecosystem")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("Upload new file")); _ck(_v, 8, 0, currVal_1); }); }
function View_EcosystemFileDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "document-item", [], null, [[null, "onClick"], [null, "remove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.selectFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("remove" === en)) {
        var pd_1 = (_co.removeFile(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_DocumentItemComponent_0, i3.RenderType_DocumentItemComponent)), i1.ɵdid(1, 49152, null, 0, i4.DocumentItemComponent, [i5.LogNotificationService], { file: [0, "file"], docHref: [1, "docHref"], noLink: [2, "noLink"], isRemoveFromFilesDisabled: [3, "isRemoveFromFilesDisabled"] }, { onClick: "onClick", remove: "remove" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = (!_co.isOpenFiles ? null : _v.context.$implicit.file); var currVal_2 = !_co.isOpenFiles; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_EcosystemFileDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "ecosystem-file__buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadFiles() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MdButton_0, i6.RenderType_MdButton)), i1.ɵdid(2, 16384, null, 0, i7.MdPrefixRejector, [[2, i7.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(3, 180224, null, 0, i7.MdButton, [i1.ElementRef, i1.Renderer, i7.FocusOriginMonitor], null, null), i1.ɵdid(4, 16384, null, 0, i7.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(5, 16384, null, 0, i8.FormElemsHiddenDirective, [[2, i9.ReadOnlyService]], null, null), (_l()(), i1.ɵted(6, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).disabled; var currVal_1 = true; var currVal_2 = ((i1.ɵnov(_v, 5).readOnlyService == null) ? null : i1.ɵnov(_v, 5).readOnlyService.readOnly); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("Upload files")); _ck(_v, 6, 0, currVal_3); }); }
export function View_EcosystemFileDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { fileInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "ecosystem-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_DialogCloseButtonComponent_0, i10.RenderType_DialogCloseButtonComponent)), i1.ɵdid(3, 49152, null, 0, i11.DialogCloseButtonComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "h2", [["md-dialog-title", ""]], [[2, "mat-dialog-title", null]], null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i7.MdPrefixRejector, [[2, i7.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(6, 16384, null, 0, i7.MdDialogTitle, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemFileDialogComponent_1)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemFileDialogComponent_2)), i1.ɵdid(10, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemFileDialogComponent_3)), i1.ɵdid(12, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "ul", [["class", "upload-files__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemFileDialogComponent_4)), i1.ɵdid(15, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EcosystemFileDialogComponent_5)), i1.ɵdid(17, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, [[1, 0], ["fileInput", 1]], null, 2, "input", [["multiple", ""], ["ng2FileSelect", ""], ["style", "display: none;"], ["type", "file"]], [[8, "readOnly", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i13.FileSelectDirective, [i1.ElementRef], { uploader: [0, "uploader"] }, null), i1.ɵdid(20, 16384, null, 0, i14.FormElemsReadOnlyDirective, [[2, i9.ReadOnlyService]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.stepId; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.stepId; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.stepId; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.uploadedDocs; _ck(_v, 15, 0, currVal_5); var currVal_6 = !_co.stepId; _ck(_v, 17, 0, currVal_6); var currVal_8 = _co.uploader; _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).applyClassPosition; _ck(_v, 2, 0, currVal_0); var currVal_1 = true; _ck(_v, 4, 0, currVal_1); var currVal_7 = ((i1.ɵnov(_v, 20).readOnlyService == null) ? null : i1.ɵnov(_v, 20).readOnlyService.readOnly); _ck(_v, 18, 0, currVal_7); }); }
export function View_EcosystemFileDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ecosystem-file", [], null, null, null, View_EcosystemFileDialogComponent_0, RenderType_EcosystemFileDialogComponent)), i1.ɵdid(1, 114688, null, 0, i15.EcosystemFileDialogComponent, [i7.MdDialogRef, i16.EcosystemFileService, i5.LogNotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EcosystemFileDialogComponentNgFactory = i1.ɵccf("ecosystem-file", i15.EcosystemFileDialogComponent, View_EcosystemFileDialogComponent_Host_0, {}, {}, []);
export { EcosystemFileDialogComponentNgFactory as EcosystemFileDialogComponentNgFactory };
