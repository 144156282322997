import { Subject } from 'rxjs/Subject';
import {
  ECustomListRecordStatus, ICustomListMenuOption,
  ICustomListRecord
} from '../../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import * as _ from 'lodash';
import { MdDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Component, DoCheck } from '@angular/core';
import { Searchable } from '../../../../../shared/models/searchable.class';
import { NeedService } from '../../../../../entities/need/services/need.service';
import { NeedClientListService } from '../../../../../entities/need/services/need-client-list.service';
import { NeedSalesListService } from '../../../../../entities/need/services/need-sales-list.service';
import { Need } from '../../../../../entities/need/models/need.class';
import { NeedDetailsDialogComponent } from '../../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import { IMapMarker } from '../../../../../shared/map-view/map-marker.interface';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { NEED_STATUSES } from '../../../../../entities/need/values/need-statuses.const';
import { CurrentUserService } from '../../../../../shared/services/current-user.service';
import {DIALOG_THEME} from '../../../../../shared/helpers/dialog-themes.const';
import * as moment from 'moment';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import {
  DepositDetailsDialogComponent
} from '../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'supervisor-data-need-catalog',
  templateUrl: 'supervisor-data-need-catalog.component.html',
})
export class SupervisorDataNeedCatalogComponent implements DoCheck {
  saleses: Searchable[];
  filters = [
    'all',
    'active',
    'not treated',
    'archive',
  ];
  currentSortValue = 'name';
  sortValue: {field: string, view: string }[] = [
    { field: 'name', view: 'Name' },
    { field: 'created_at', view: 'Creation date'},
    { field: 'sales_name', view: 'Need manager'},
    { field: 'delivery_date', view: 'Delivery date'}
  ];

  records: ICustomListRecord[];
  needs: Need[];
  searchSubject = new Subject<string>();

  filter = 'all';
  sortOrder = 'any';
  clientId: number;
  salesId: number;
  search: string;
  expiresFrom: string;
  expiresTo: string;

  oldQuery: any;
  markers: IMapMarker[] = [];
  mapIsReady: boolean;

  constructor(private needService: NeedService,
              private needSalesListService: NeedSalesListService,
              private mdDialog: MdDialog,
              private datePipe: DatePipe,
              private _globalEvents: GlobalEventsManager,
              private _currentUserService: CurrentUserService,
              private navBarState: NavbarStateService) {
    this.needSalesListService.get().subscribe((res: Searchable[]) => {
      this.saleses = res;
    });

    this.searchSubject
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(res => this.search = res);
  }

  ngDoCheck() {
    let query: any = {
      expand: 'client,passport,client_contact,client_location,ecosystems,sales_contact,sales_name,sales_email',
      supervisor: 1,
      delivery_from: this.expiresFrom || null,
      delivery_to: this.expiresTo || null,
      search: this.search || null,
      sales_contact: this.salesId || null,
      sort: this.currentSortValue,
      'status[]': [NEED_STATUSES.PUBLISHED.value] || null
    };

    if(this.navBarState.multilanguage) {
      query.english = true;
    }

    if (!_.isEqual(this.oldQuery, query)) {
      this
        .needService
        .get(query)
        .subscribe((res: Need[]) => {
          this.mapIsReady = false;
          this.needs = res;
          this.records = res.map(d => this.toRecord(d));
          this.markers = this.needs.map((need: Need) => this.toMarker(need));
          this.mapIsReady = true;
        })
      ;
      this.oldQuery = _.cloneDeep(query);
    }
  }

  downloadPdfReport():string {
    return this.needService.getPdfReportUrl({
      supervisor: 1,
      delivery_from: this.expiresFrom || null,
      delivery_to: this.expiresTo || null,
      search: this.search || null,
      client: this.clientId || null,
      sales_contact: this.salesId || null,
      esm_view: this.filter === 'not treated' || null,
      status: [NEED_STATUSES.PUBLISHED.value] || null,
      timezone_offset: moment().utcOffset()
    });
  }

  toMarker(need: Need): IMapMarker {
    return {
      lat: need.client_location.lat,
      lng: need.client_location.lng,
      name: need.name,
      independent: !!need.independent,
      lines: [
        `${need.quantity ? need.quantity : 0} ${need.passport.unit_text}`,
        `${need.delivery_date}`
      ]
    };
  }

  toRecord(data: Need) {
    let controls: ICustomListMenuOption[] = [{
      name: 'See details',
      fn: () => {
        this.mdDialog.open(NeedDetailsDialogComponent, {
          ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
          data: {
            readOnly: true,
            id: data.id,
          },
        });
      },
    }, ...(data.from_deposit && data.from_deposit > 0 ? [{
      name: 'See targeted deposit',
      fn: () => {
          this.mdDialog.open(DepositDetailsDialogComponent, {
            data: {
              id: data.from_deposit,
              readOnly: true
            }
          });
        }
    }] : []), {
      name: 'Send email to Need manager',
      fn: () => location.href = 'mailto:' + data.sales_email,
    }, {
      name: 'Send email to recipient',
      fn: () => location.href = 'mailto:' + data.client_contact.email,
    }];

    if(data.user_id !== this._currentUserService.infoData.id) {
      controls.push({
        name: 'Chat',
        fn: () => this.openChat(data.user_id),
      });
    }

    return CustomListRecord
      .build()
      .setPrimaryKey(data.id)
      .setIndependent(!!data.independent)
      .setStatus(ECustomListRecordStatus.NO_STATUS)
      .addTitleColumn(data.name, data.client.name)
      .addUnitColumn(data.quantity_text, data.passport.customListUnit)
      .addTitleColumn('', data.statusData.view.replace(/./, c => c.toUpperCase()))
      .addTextColumn(data.description)
      .addListColumn(
        [
          {
            key: 'Created', value: this.datePipe.transform(data.created_at, 'dd.MM.y')
          },
          data.delivery_date ? {
            key: 'Expires', value: this.datePipe.transform(data.delivery_date, 'dd.MM.y')
          } : { key: 'Expires', value: 'N/A' }
        ].filter(v => v)
      )
      .addAuthorColumn(data.sales_name)
      .addControlsColumn(controls);
  }

  export() {
    console.log('Export BTN');
  }
  openChat(targetId: number) {
    console.log('openChat', targetId);
    this._globalEvents.chatUserClicked$.emit(targetId);
  }
}
