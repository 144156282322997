<article class="ecosystem-dashboard  type--simple" test-id="supervisor-data">
  <main class="ecosystem-dashboard__content">
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--counters">
      <ul class="ecosystem-dashboard__counter-list">
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{ecosystemsInProcessCount}}</div>
          <span class="ecosystem-dashboard__counter-title">{{ 'Ecosystems in process' | translate }}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary ">{{needsUntreated}}</div>
          <span class="ecosystem-dashboard__counter-title">{{ 'Not treated needs' | translate }}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{depositsAvailable}}</div>
          <span class="ecosystem-dashboard__counter-title">{{ 'Deposits available' | translate }}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{transformationsAvailable}}</div>
          <span class="ecosystem-dashboard__counter-title">{{ 'Transformations available' | translate }}</span>
        </li>
      </ul>
    </section>

    <div class="admin-nomenclature-change-button">
      <span class="nomenclature--wrapper"><i class="icon icon--nomenclature"></i></span>
      <button (click)="askForNomenclatureChange()" class="esm-catalog__map-view">{{ 'Ask change in nomenclature' |
        translate }}
      </button>
    </div>

    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--tabs">
      <article class="ecosystem-catalog">
        <header class="ecosystem-catalog__header">
          <ul class="ecosystem-catalog__caption-list">
            <li class="ecosystem-catalog__caption-item" (click)="activeIndex = 0"
                [ngClass]="{'active': activeIndex === 0}">
              <span class="ecosystem-catalog__caption-title">{{ 'Needs' | translate }}</span>
            </li>
            <li class="ecosystem-catalog__caption-item" (click)="activeIndex = 1"
                [ngClass]="{'active': activeIndex === 1}">
              <span class="ecosystem-catalog__caption-title">{{ 'Transformations' | translate }}</span>
            </li>
            <li class="ecosystem-catalog__caption-item" (click)="activeIndex = 3"
                [ngClass]="{'active': activeIndex === 3}">
              <span class="ecosystem-catalog__caption-title">{{ 'Deposits' | translate }}</span>
            </li>
          </ul>
        </header>
        <section class="ecosystem-catalog__content">
          <ul class="ecosystem-catalog__content-list">
            <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 0">
              <supervisor-data-need-catalog></supervisor-data-need-catalog>
            </li>
            <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 1">
              <supervisor-data-transformations-catalog></supervisor-data-transformations-catalog>
            </li>
            <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 3">
              <supervisor-data-deposit-catalog></supervisor-data-deposit-catalog>
            </li>
          </ul>
        </section>
      </article>
    </section>
  </main>
</article>
