import { Component, Input } from '@angular/core';

/**
 * `TrashIconComponent` is designed to display a customizable trash icon within Angular applications.
 * It allows for adjusting the size, color, and a "lift" effect direction of the icon through input properties.
 * This component makes it easy to reuse the trash icon with different visual presentations across the application.
 */
@Component({
    selector: 'app-trash-icon',
    templateUrl: './trash-icon.component.html',
    styleUrls: ['./trash-icon.component.css']
})
export class TrashIconComponent {
    /** Multiplier for the icon size */
    @Input() sizeMultiplier: number = 1;
    /** Icon color */
    @Input() color: string = '#e03e3e';
    /** Direction of the "lift" effect for the icon ('left' or 'right') */
    @Input() liftDirection: string = 'left';
    
    constructor() { }
}
