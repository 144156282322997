import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { CategoryItem } from '../models/category-item.class';

@Injectable()
export class TransformationCategoryService extends CrudService {
  protected namespace = 'transformation-categories';
  protected namespaceSingular = 'transformation-category';
  protected ModelClass = CategoryItem;
}
