export enum ERoleIds {
  AGENT = 1,
  SUPERVISOR = 2,
  SALES_MANAGER = 3,
  TRANSFORMATION_SITE_MANAGER = 4,
  DEPOSIT_OWNER = 5,
  ECOSYSTEM_MANAGER = 6,
  ADMIN = 7,
  SUPERADMIN = 8,
  SPECIALADMIN = 9,
  MANUFACTURER = 10,
  TRANSFORMATION_SITE_MANAGER_INDEPENDENT = 11
}

export const ROLES_WITH_SPECIAL_ADMIN_SHORT: {
  text: string ,
  value: number ,
  } [] = [
  {
    text : 'TSMI',
    value : ERoleIds.TRANSFORMATION_SITE_MANAGER_INDEPENDENT
  } ,
  {
    text :  'manufacturer',
    value : ERoleIds.MANUFACTURER
  },
  {
    text : 'special_admin',
    value : ERoleIds.SPECIALADMIN
  }
]

export enum ADMIN_ESM_OPTIONS {
  M0 = 1,
  M1 = 2,
  M2 = 3,
  RESTRICTED_ADMIN = 4
}
export const ADMIN_CAN_CREATE_ESM: {
  text: string ,
  value: number ,
  } [] = [
  {
    text: 'Restricted admin',
    value : ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN
  } ,
  {
    text: 'M0',
    value : ADMIN_ESM_OPTIONS.M0
  } ,
  {
    text:  'M1',
    value: ADMIN_ESM_OPTIONS.M1
  },
  {
    text: 'M2',
    value: ADMIN_ESM_OPTIONS.M2
  }
]

export let ROLES = ERoleIds;


export const USER_ROLES: {
  view: string,
  text: string,
  value: number,
}[] = [
  {
    text: 'AGENT',
    view: 'Agent',
    value: ERoleIds.AGENT,
  },
  {
    text: 'SUPERVISOR',
    view: 'Supervisor',
    value: ERoleIds.SUPERVISOR,
  },
  {
    text: 'SALES_MANAGER',
    view: 'Need manager',
    value: ERoleIds.SALES_MANAGER,
  },
  {
    text: 'TRANSFORMATION_SITE_MANAGER',
    view: 'Transformation site manager',
    value: ERoleIds.TRANSFORMATION_SITE_MANAGER,
  },
  {
    text: 'DEPOSIT_OWNER',
    view: 'Deposit owner',
    value: ERoleIds.DEPOSIT_OWNER,
  },
  {
    text: 'ECOSYSTEM_MANAGER',
    view: 'Ecosystem manager',
    value: ERoleIds.ECOSYSTEM_MANAGER,

  // this is used in special admin need to be removed , leaving it commented just to make sur no other file use it
  /*{
    text: 'TRANSFORMATION_SITE_MANAGER_INDEPENDENT',
    view: 'Transformation site manager independent',
    value: ERoleIds.TRANSFORMATION_SITE_MANAGER_INDEPENDENT,
  }, */

  }

];

export const USER_ROLES_WITH_ADMIN: {
  view: string,
  text: string,
  value: number,
}[] = [...USER_ROLES,
  {
    text: 'ADMIN',
    view: 'Admin',
    value: ERoleIds.ADMIN,
  },
  {
    text: 'SUPER_ADMIN',
    view: 'Super Admin',
    value: ERoleIds.SUPERADMIN,
  },
  {
    text: 'SPECIAL_ADMIN',
    view: 'Special Admin',
    value: ERoleIds.SPECIALADMIN,
  }
];

export const USER_ROLES_FOR_ADMIN: {
  view: string,
  text: string,
  value: number,
}[] = [...USER_ROLES,
  {
    text: 'ADMIN',
    view: 'Admin',
    value: ERoleIds.ADMIN,
  },

];
export const USER_ROLES_FOR_SPECIAL_ADMIN: {
  view: string,
  text: string,
  value: number,
}[] = [
  {
    text: 'MANUFACTURER',
    view: 'Manufacturer',
    value: ERoleIds.MANUFACTURER,
  },
  {
    text: 'TRANSFORMATION_SITE_MANAGER_INDEPENDENT',
    view: 'Independent transformation manager',
    value: ERoleIds.TRANSFORMATION_SITE_MANAGER_INDEPENDENT,
  }
];
