<div class="trash-icon" [ngClass]="{'lift-left': liftDirection === 'left', 'lift-right': liftDirection === 'right'}">
    <svg [attr.width]="11 * sizeMultiplier" [attr.height]="18 * sizeMultiplier" viewBox="0 0 11 13" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <!-- Corps de la poubelle -->
        <rect x="3" y="4" width="1" height="7" [attr.fill]="color" />
        <rect width="1" height="7" transform="matrix(-1 0 0 1 6 4)" [attr.fill]="color" />
        <rect width="1" height="7" transform="matrix(-1 0 0 1 8 4)" [attr.fill]="color" />
        <mask id="path-4-inside-1_0_1" fill="white">
            <path d="M1 2H10V13H1V2Z" />
        </mask>
        <path d="M10 13V14H11V13H10ZM1 13H0V14H1V13ZM9 2V13H11V2H9ZM10 12H1V14H10V12ZM2 13V2H0V13H2Z"
            [attr.fill]="color" mask="url(#path-4-inside-1_0_1)" />

        <!-- Couvercle de la poubelle -->
        <g id="couvercle">
            <rect y="2" width="11" height="1" [attr.fill]="color" />
            <line x1="3.5" y1="2" x2="3.5" [attr.stroke]="color" />
            <line x1="4" y1="0.5" x2="7" y2="0.5" [attr.stroke]="color" />
            <line x1="7.5" y1="2" x2="7.5" [attr.stroke]="color" />
        </g>
    </svg>
</div>