import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { ProductSectorService } from '../services/product-sector.service';
var ProductSector = /** @class */ (function (_super) {
    tslib_1.__extends(ProductSector, _super);
    function ProductSector() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = ProductSectorService;
        return _this;
    }
    ProductSector.prototype.fields = function () {
        return [
            'id',
            'name',
            'virgin_product_id',
        ];
    };
    return ProductSector;
}(ActiveRecord));
export { ProductSector };
