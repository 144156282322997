/**
 * Created by kirill on 4.07.17.
 */
import { EcosystemFileJunctionService } from '../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../values/ecosystem-file-junction-item-type.const';
import { EcosystemFile } from './ecosystem-file.class';

export class EcosystemFileJunction extends ExtendedActiveRecord {
  protected provider = EcosystemFileJunctionService;
  public id: number;
  public file_id: number;
  public item_id: number;
  public item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE;
  public ecosystem_file: EcosystemFile;

  constructor(data: any) {
    super(data);
    this.ecosystem_file = data.ecosystem_file
      ? new EcosystemFile(data.ecosystem_file)
      : null;
  }

  protected fields() {
    return [
      'id',
      'file_id',
      'item_id',
      'item_type',
      'ecosystem_file'
    ];
  }
}
