import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SUPERADMIN_ROUTES } from './superadmin.routes';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { CompanyEditComponent } from './components/company-edit/company-edit.component';
import { SuperadminUsersComponent } from './components/superadmin-users/superadmin-users.component';
import { SuperadminDashboardComponent } from './components/superadmin-dashboard/superadmin-dashboard.component';
import {
  SuperadminChangesCatalogComponent
} from './components/superadmin-dashboard/superadmin-changes-catalog/superadmin-changes-catalog.component';
import { EcosystemModule } from '../../entities/ecosystem/ecosystem.module';
import { SuperAdminGuard } from './superadmin.guard';
import { RouterModule } from '@angular/router';
import { SUPERADMIN_CONTEXT } from './superadmin.context';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';
import { RankingFormulaComponent } from './components/ranking-formula/ranking-formula.component';
import { CalculationVariablesComponent } from './components/calculation-variables/calculation-variables.component';
import { LandfillVariablesService } from './components/landfill-variables/landfill-variables.service';
import { RankingFormulaService } from './components/ranking-formula/ranking-formula.service';
import { CalculationVariablesService } from './components/calculation-variables/calculation-variables.service';
import { TestDataGenerationService } from './test-data-generation.service';
import {CompanyDeletionDialogComponent} from './dialogs/company-deletion-dialog.component';
import { GenericPassportDashboardComponent } from './components/generic-passport/generic-passport-dashboard.component';
import { PassportGenericModule } from '../../entities/generic-passport/generic-passport.module';
import { ZonesComponent } from './components/zones/zones.component';
import { ZoneFormDialogComponent } from './dialogs/zone-form-dialog.component';
import { ZoneService } from '../../shared/services/zone.service';
import { CurrencyService } from '../../shared/services/currency.service';
import { CopyGenericPassportDialogComponent } from '../../entities/generic-passport/dialogs/copy-generic-passport-dialog.component';
import { PassportTagService } from '../../entities/passport/services/passport-tag.service';
import { SynonymService } from '../../shared/services/synonym.service';
import { CompanySitesComponent } from './components/company-details/company-sites/company-sites.component';
import { UsersListComponent } from './components/superadmin-users/users-list/users-list.component';
import {CompanyFileService} from './services/company-file.service';
import { SuperadminRawReportDialog } from './components/superadmin-dashboard/superadmin-raw-report-download/superadmin-raw-report-download-dialog.component';
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([{
      path: SUPERADMIN_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: SUPERADMIN_ROUTES
    }]),
    EcosystemModule,
    PassportGenericModule
  ],
  declarations: [
    SuperadminDashboardComponent,
    SuperadminUsersComponent,
    SuperadminChangesCatalogComponent,
    CompanyDetailsComponent,
    CompanyEditComponent,
    RankingFormulaComponent,
    CalculationVariablesComponent,
    CompanyDeletionDialogComponent,
    ZoneFormDialogComponent,
    CopyGenericPassportDialogComponent,
    GenericPassportDashboardComponent,
    ZonesComponent,
    CompanySitesComponent,
    UsersListComponent,
    SuperadminRawReportDialog
   // GenericPassportEdit
  ],
  exports: [],
  providers: [
    SuperAdminGuard,
    RankingFormulaService,
    CalculationVariablesService,
    LandfillVariablesService,
    TestDataGenerationService,
    ZoneService,
    CurrencyService,
    PassportTagService,
    SynonymService,
    CompanyFileService
  ],
  entryComponents: [
    CompanyDeletionDialogComponent,
    ZoneFormDialogComponent,
    CopyGenericPassportDialogComponent,
    SuperadminRawReportDialog
  ]
})
export class SuperadminModule {
}
