import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { TransformFile } from '../models/transform-file.class';

@Injectable()
export class TransformFileService extends CrudService {
  protected namespace = 'transformation-files';
  protected namespaceSingular = 'transformation-file';
  protected ModelClass = TransformFile;
}
