import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
import { IAgentStats } from '../interfaces/agent-stats.interface';

export class AgentStats extends BaseModel implements IAgentStats {
  deposit_locations_assigned: number;
  deposit_locations_published: number;
  deposits_assigned: number;
  deposits_characterized: number;
  deposits_published: number;
  deposits_rejected: number;
  deposits_validated: number;

  fields(): string[] {
    return [
      'deposit_locations_assigned',
      'deposit_locations_published',
      'deposits_assigned',
      'deposits_characterized',
      'deposits_published',
      'deposits_rejected',
      'deposits_validated'
    ];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    Utils.convertJsonStringToNumber(json, [
      'deposit_locations_assigned',
      'deposit_locations_published',
      'deposits_assigned',
      'deposits_characterized',
      'deposits_published',
      'deposits_rejected',
      'deposits_validated'
    ]);
    return json;
  }
}
