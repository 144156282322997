import {Component, HostBinding, Input} from '@angular/core';
@Component({
  moduleId: module.id,
  selector: 'dialog-close-button',
  template: `
    <i class="{{closeButtonClass}}"></i>
  `,
  styles: [`
    /deep/
    .dialog-close-button-container {
      display: block;
      position: relative;
    }

    :host {
      position: absolute;
      cursor: pointer;
    }
    :host.dialog-close-button--position {
      top: 16px;
      right: 18px;
    }
  `]
})
export class DialogCloseButtonComponent {
  @Input() public color: string;
  @Input() public defaultPosition = true;
  @HostBinding('class.dialog-close-button--position') get applyClassPosition() {
    return this.defaultPosition;
  }
  get closeButtonClass() {
    return 'icon icon--close_s' + (this.color ? '_' + this.color : '');
  }
}
