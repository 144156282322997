import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Passport, PassportFamily} from '../../../../passport/models/passport.class';
import {Deposit} from '../../../models/deposit.class';
import {MdDialog} from '@angular/material';
import {IParentEvent} from '../../../../../shared/interfaces/parent-event.interface';
import {DepositPhotoService} from '../../../services/deposit-photo.service';
import {DepositControlStates} from '../../../models/deposit-control-states.class';
import {PassportService} from '../../../../passport/services/passport.service';
import {NavigationService} from '../../../../../shared/services/navigation.service';

@Component({
  moduleId: module.id,
  selector: 'deposit-passport-step',
  templateUrl: 'product-passport-step.component.html',
})

export class DepositPassportStepComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() deposit: Deposit;
  @Input() controlStates: DepositControlStates;
  @Input() listener: EventEmitter<IParentEvent>;
  @Input() spinnerEnabled?: boolean = true;
  @Output() onChangeDeposit: EventEmitter<Deposit> = new EventEmitter();
  @Output() onInvalidField = new EventEmitter();
  @Output() public onSaveComplete: EventEmitter<any> = new EventEmitter();
  @Output() public onSaveError: EventEmitter<any> = new EventEmitter();
  eventStream$: EventEmitter<IParentEvent> = new EventEmitter();
  parentModule: string;

  constructor(private dialog: MdDialog,
              private depositPhotoService: DepositPhotoService,
              private passportService: PassportService,
              private cd: ChangeDetectorRef,
              private navigationService: NavigationService
  ) {
    this.parentModule = this.navigationService.getModuleName();
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.deposit.beforeSave$.subscribe(() => {
      if (this.deposit.passport && this.deposit.passport.family === PassportFamily.FAMILY_VOID) {
        return;
      }
      this.eventStream$.emit({
        key: 'savePassport',
        data: null,
      });
    });
    this.listener.subscribe((event: IParentEvent) => {
      if (event.key === 'changeDeposit') {
        this.deposit = event.data;
        this.eventStream$.emit({
          key: 'changePassport',
          data: this.deposit.passport,
        });
      }
    });
  }

  ngOnDestroy() {
  }

  onChangePassport(passport: Passport) {
    this.deposit.setPassport(passport);
  }

  onInvalidFieldHandler($event) {
    this.onInvalidField.emit($event);
  }

  onSaveCompleteHandler($event) {
    this.onSaveComplete.emit($event);
  }

  onSaveErrorHandler($event) {
    this.onSaveError.emit($event);
  }
}
