<form class="view-offer" [formGroup]="offerForm">
  <header class="view-offer__header">
    <div class="view-offer__title">{{ 'Offer summary' | translate }}</div>
    <div class="view-offer__status-badge view-offer__status-badge--waiting"
         *ngIf="offer"
    >
      {{offer.statusView.toLowerCase() | capitalize | translate}}
    </div>
    <div class="view-offer__contact">
      {{ 'Need manager' | translate }}
      <a href="javascript:void(0)">{{need?.sales_contact}}</a>
    </div>
    <div class="view-offer__close">
      <i class="icon icon--close_s" (click)="saveAndCloseDialog()"></i>
    </div>
  </header>
  <section class="view-offer__section view-offer__section--client-data"
           *ngIf="need && need.client"
  >
    <h3 class="view-offer__section-caption">{{ 'Recipient data' | translate }}</h3>
    <div class="view-offer__row view-offer__row--client">
      <div class="view-offer__column view-offer__column--company">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Company' | translate }}"
            [value]="need.client.name"
            readonly
          />
        </md-input-container>
      </div>
      <div class="view-offer__column view-offer__column--representative">
        <h4 class="view-offer__column-title">{{ 'Representative' | translate }}</h4>
        <ul class="view-offer__contact-options">
          <li class="view-offer__contact-option"><i class="icon icon--contact">
            <md-icon>person</md-icon>
          </i>{{need.client_contact.name}}
          </li>
          <li class="view-offer__contact-option"><i class="icon icon--phone">
            <md-icon>local_phone</md-icon>
          </i> {{need.client_contact.phone}}
          </li>
          <li class="view-offer__contact-option"><i class="icon icon--mail">
            <md-icon>mail</md-icon>
          </i> {{need.client_contact.email}}
          </li>
        </ul>
      </div>
      <div class="view-offer__column view-offer__column--logo">
        <img *ngIf="need.client.image" class="image" [src]="need.client.image">
      </div>
    </div>
  </section>
  <section class="view-offer__section view-offer__section--product-passport"
           *ngIf="need && need.passport"
  >
    <passport-details-view [passport]="need.passport" class="view-offer__passport-details">
    </passport-details-view>
  </section>
  <section class="view-offer__section view-offer__section--product-passport"
           *ngIf="offer && need && need.client_location"
  >
    <h3 class="view-offer__section-caption">{{ 'Delivery' | translate }}</h3>
    <div class="view-offer__row">
      <div class="view-offer__column view-offer__column--site--name">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Site name' | translate }}"
            readonly
            [value]="need.client_location.name"
          />
        </md-input-container>
      </div>
      <div class="view-offer__column view-offer__column--site--address">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Site address' | translate }}"
            readonly
            [value]="need.client_location.address"
          />
        </md-input-container>
      </div>
    </div>
    <div class="view-offer__row">
      <div class="view-offer__column view-offer__column--address-description">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Address description' | translate }}"
            readonly
            [value]="need.client_location.description"
          />
        </md-input-container>
      </div>
      <div class="view-offer__column view-offer__column--delivery-format">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            formControlName="deliveryFormat"
            placeholder="{{ 'Format of delivery' | translate }}"
            [(ngModel)]="offer.delivery_format"
          />
        </md-input-container>
      </div>
    </div>
  </section>
  <section class="view-offer__section view-offer__section--product-passport" *ngIf="offer && need">
    <h3 class="view-offer__section-caption">{{ 'Offer and price' | translate }}</h3>
    <div class="view-offer__row">
      <div class="view-offer__column view-offer__column--price">
        <div class="view-offer__row">
          <div class="view-offer__column view-offer__column--offer-price">
            <md-input-container>
              <input
                mdInput
                autocomplete="off" 
                formControlName="price"
                placeholder="{{ 'Price' | translate }}"
                [(ngModel)]="offer.price"
              />
            </md-input-container>
          </div>
          <div class="view-offer__column view-offer__column--offer-quantity">
            <md-input-container>
              <input
                mdInput
                autocomplete="off" 
                formControlName="quantity"
                placeholder="{{ 'Quantity offered' | translate }}"
                [(ngModel)]="offer.quantity"
              />
            </md-input-container>
          </div>
          <div class="view-offer__column view-offer__column--offer-quantity">
            <md-input-container>
              <input
                mdInput
                autocomplete="off" 
                placeholder="{{ 'Quantity wanted' | translate }}"
                readonly
                [value]="need.quantity"
              />
            </md-input-container>
          </div>
          <div class="view-offer__column view-offer__column--offer-unit">
            <md-input-container>
              <input
                mdInput
                autocomplete="off" 
                placeholder="{{ 'Units' | translate }}"
                readonly
                value="{{ need.passport.unit_text | translate }}"
              />
            </md-input-container>
          </div>
          <div class="view-offer__column view-offer__column--offer-delivery">
            <md-input-container>
              <input
                mdInput
                autocomplete="off" 
                formControlName="deliveryDate"
                placeholder="{{ 'Delivery date' | translate }}"
                ng2-datetime-picker
                date-only="true"
                date-format="DD-MM-YYYY"
                parse-format="YYYY-MM-DD"
                [(ngModel)]="offer.delivery_date"
              />
            </md-input-container>
          </div>
        </div>
      </div>
    </div>
    <div class="view-offer__row">
      <div class="view-offer__column view-offer__column--provenance">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            formControlName="origin"
            placeholder="{{ 'Provenance' | translate }}"
            [(ngModel)]="offer.origin"
          />
        </md-input-container>
      </div>
      <div class="view-offer__column view-offer__column--required">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            placeholder="{{ 'Required' | translate }}"
            readonly
            [value]="need.delivery_date"
          />
        </md-input-container>
      </div>
      <div class="view-offer__column view-offer__column--date-req">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Date requirement' | translate }}"
            readonly
            [value]="need.date_precision_text"
          />
        </md-input-container>
      </div>
    </div>
  </section>
  <section class="view-offer__section view-offer__section--product-passport" *ngIf="offer?.ecosystem_file_junction">
    <h3 class="view-offer__section-caption">{{ 'Documents' | translate }}</h3>
    <div class="view-offer__row">
      <!--<documents-uploader *ngIf="fileUploadeUrl"-->
        <!--[uploadUrl]="fileUploadeUrl"-->
        <!--[files]="need.files"-->
      <!--&gt;</documents-uploader>-->
      <div class="ecosystem-compiler__upload-files">
        <div class="ecosystem-compiler__upload-file" *ngFor="let junction of offer.ecosystem_file_junction">
          <i class="icon icon--document"></i>
          <span class="ecosystem-compiler__file-name">{{ junction.ecosystem_file.basename }}</span>
          <i class="icon icon--cross" (click)="removeJunctionFromOffer(junction)"></i>
        </div>
      </div>
      <div class="ecosystem-compiler__upload-href" (click)="openFiles()">{{ 'Upload files' | translate }}</div>
    </div>
  </section>
  <footer class="view-offer__controls">
    <button
      class="view-offer__button"
      *ngIf="offer?.canValidateManually"
      (click)="validate()"
    >{{ 'Validate manually' | translate }}
    </button>
    <button
      class="view-offer__button view-offer__button--confirm"
      *ngIf="offer?.canAskForValidation"
      (click)="requestValidation()"
    >{{ 'Request Validation' | translate }}
    </button>
    <button
      class="view-offer__button view-offer__button--confirm"
      *ngIf="offer?.canConfirm"
      (click)="confirm()"
    >{{ 'Confirm manually' | translate }}
    </button>
    <button
      class="view-offer__button view-offer__button--confirm"
      *ngIf="offer && !offer.wasValidated"
      (click)="saveAndCloseDialog()"
    >{{ 'Save' | translate }}
    </button>
  </footer>
</form>
