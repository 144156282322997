import {Component} from '@angular/core';
import {UAParser} from 'ua-parser-js';
import {TranslateService} from '@ngx-translate/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NavigationService } from '../services/navigation.service';

@Component({
    moduleId: module.id,
    selector: 'ie-notification',
    templateUrl: 'ie-notification.component.html',
    styleUrls: ['ie-notification.component.scss'],
    animations: [
        trigger('showOrHide', [
            state('show', style({ opacity: 1})),
            state('hide', style({ display: "none", transform: "translate(0px, -100%)"})),
            transition('show => hide', [
               animate('500ms ease-in-out'),
            ]),
         ]),
    ]
  })
  
  export class IeNotificationComponent{
    show: boolean = false;
    userBrowser: string = "";

    constructor(
        public translate: TranslateService,
        private _navigationService: NavigationService
    ) {}

    ngOnInit() {
        this.getUserBrowser();
        this.checkBrowser();
        this.checkRoleChange();
    }
    
    get stateName() {
        return this.show ? 'show' : 'hide'
      }
    
    getUserBrowser(){
        let parser = new UAParser();
        let result = parser.getResult();
        this.userBrowser = result.browser.name;
    }
      
    checkRoleChange(){
        if(this.userBrowser === "IE"){
            this._navigationService.changeModule.subscribe(() => {
                    this.show = true;});
        }
    }

    checkBrowser() {
        if(this.userBrowser === "IE"){
            this.show = true;}   
        }
    
    closeNotificationBar(){
        this.show = false;
     }

  }