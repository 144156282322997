/**
 * Created by aleksandr on 20.06.17.
 */
import { Component, EventEmitter, Output } from '@angular/core';
import { Need } from '../../../../entities/need/models/need.class';
import { Searchable } from '../../../../shared/models/searchable.class';
import { FormControl } from '@angular/forms';
import { NeedClientListService } from '../../../../entities/need/services/need-client-list.service';
import { NeedSalesListService } from '../../../../entities/need/services/need-sales-list.service';
import { NEED_STATUSES } from '../../../../entities/need/values/need-statuses.const';
import { NeedService } from '../../../../entities/need/services/need.service';
import { CurrentUser } from '../../../../shared/auth/current-user.class';

@Component({
  moduleId: module.id,
  selector: 'need-picker',
  templateUrl: 'esm-need-picker.component.html'
})
export class EsmNeedPickerComponent {
  records: Need[] = [];
  @Output() onSelect?: EventEmitter<Need> = new EventEmitter();
  public activeItem: Need = null;
  showIndependentFilter: boolean;

  filters: any = [
    'all',
    'new',
    'not treated',
  ];

  filter = 'all';
  clientId: number;
  salesId: number;
  search = new FormControl;
  expiresFrom: string;
  expiresTo: string;

  clients: Searchable[];
  salesContacts: Searchable[] = [new Searchable({id: null, name: 'any'})];
  includeIndependent: boolean;
  // records: ICustomListRecord[] = [];

  constructor(private _needService: NeedService,
              private needClientListService: NeedClientListService,
              private needSalesListService: NeedSalesListService) {
    this
      .search
      .valueChanges
      .debounceTime(400)
      .subscribe(() => this.getRecords())
    ;

    this.needClientListService.get().subscribe((res: Searchable[]) => {
      this.clients = res;
    });
    this.needSalesListService.get().subscribe((res: Searchable[]) => {
      this.salesContacts.push(...res);
    });

    this.showIndependentFilter = CurrentUser.infoData.has_need_acces;

    this.getRecords();
  }

  public selectItem(item: Need) {
    this.activeItem = item;
  }

  public createEcosystem() {
    this.onSelect.emit(this.activeItem);
  }

  public closeDialog() {
    this.onSelect.emit(null);
  }

  public getRecords() {
    const params: any = {
      expand: 'client,passport,client_contact',
      esm_view: true,
      supervisor: true,
      delivery_from: this.expiresFrom || null,
      delivery_to: this.expiresTo || null,
      search: this.search.value || null,
      client: this.clientId || null,
      independent: this.includeIndependent || null,
      sales_contact: this.salesId || null,
    };

    if (this.filter === 'not treated') {
      params.status = NEED_STATUSES.PUBLISHED.value;
    } else {
      params['status[]'] = [
        NEED_STATUSES.PUBLISHED,
        NEED_STATUSES.TREATED,
        NEED_STATUSES.WAITING_FOR_VALIDATION,
        NEED_STATUSES.IN_PROGRESS,
      ].map(s => s.value);
    }

    this._needService
      .get(params)
      .subscribe((needs: Need[]) => {
        this.records = needs;
      });
  }

  toggleIndependent(checked: boolean) {
    this.includeIndependent = checked;
    this.getRecords();
  }
}
