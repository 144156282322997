import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { dateDiffLargestPeriod } from '../helpers/date-diff-largest-period';
@Pipe({
  name: 'timeLeftText'
})
export class TimeLeftTextPipe implements PipeTransform {

  constructor(private _translate: TranslateService) {}

  transform(date: string): string {
    // let days = (new Date(date).getTime() - Date.now()) / 1000 / 60 / 60 / 24;
    // let months = days / 30.4;
    // let years = months / 12;

    // let isToday = new Date(date).toDateString() === new Date().toDateString();
    // if (isToday) {
    //   return 'today';
    // }

    // let tomorrow = new Date;
    // tomorrow.setDate(tomorrow.getDate() + 1);
    // let isTomorrow = new Date(date).toDateString() === tomorrow.toDateString();
    // if (isTomorrow) {
    //   return 'tomorrow';
    // }

    // let res = [
    //   {name: {singular: 'year', plural: 'years'}, value: years},
    //   {name: {singular: 'month', plural: 'months'}, value: months},
    //   {name: {singular: 'day', plural: 'days'}, value: days},
    // ].find((e) => e.value > 1);

    // if (res) {
    //   let value = Math.round(res.value);
    //   return value + ' ' + (value === 1 ? res.name.singular : res.name.plural);
    // } else {
    //   return 'today';
    // }
    const expirationDate = moment(date);
        const today = moment();
        return dateDiffLargestPeriod(today, expirationDate,
            {day: 'day'
            , oneDay: 'tomorrow'
            , month: 'month'
            , year: 'year',
            zeroDays: 'today'
        }).map(date => typeof date === 'string' ? this._translate.instant(date) : date).join(' ');
        }
}
