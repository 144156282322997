import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { GenericPassport } from '../models/generic-passport.class';
var GenericPassportService = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassportService, _super);
    function GenericPassportService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'generic-passports';
        _this.namespaceSingular = 'generic-passport';
        _this.ModelClass = GenericPassport;
        return _this;
    }
    GenericPassportService.prototype.setListPage = function (pagenr) {
        this.pagenr = pagenr;
    };
    GenericPassportService.prototype.getListPage = function () {
        return this.pagenr;
    };
    GenericPassportService.prototype.copyToZones = function (genericPassportIds, zoneIds) {
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + "/copy-to-zones"), { generic_passport_ids: genericPassportIds, zone_ids: zoneIds })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    return GenericPassportService;
}(ExtendedCrudService));
export { GenericPassportService };
