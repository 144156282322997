<ng-container *ngIf="order?.loaded">

  <section class="form-group background-primary-color">
    <header class="form-group__header">
      <label class="form-group__header-title">{{ 'Deposit order' | translate }}</label>
      <div class="form-group__header-status">
          <span>
            {{ getDepositOrderStatusText() | translate}}
          </span>
      </div>
    </header>
  </section>

  <section class="form-column">
    <div class="form-row form-row--manager">
      <span class="form-row-title">{{ 'Ecosystem manager' | translate }}:</span>
      <span class="form-row-name primary-color">{{ order.creator }}</span>
    </div>
  </section>
  <div class="sub-header">
    <span class="sub-header-title">
      {{ 'Deposit information' | translate }}
    </span>
  </div>
  <section class="form-column">
    <div class="form-row">
      <div class="flex-column">
        <span class="form-column-title primary-color">{{ 'Deposit Name' | translate }}</span>
        <md-input-container class="form-column-field">
          <textarea
            [rows]="setRowHeight(order.deposit.name)"
            mdInput
            [value]="order.deposit.name"
            disabled
          ></textarea>
        </md-input-container>
      </div>
      <img tabindex="1"  class="form-column-eye" src="../../../../assets/svg/deposit-order-eye--icon.svg" >
      <a class="form-column-link" (click)="seeDeposit(order.deposit.id)">{{ 'see the deposit' | translate }}</a>
      <div class="flex-column">
        <span class="form-column-title primary-color">{{ 'Passport name' | translate }}</span>
        <md-input-container class="form-column-field">
          <textarea
            [rows]="setRowHeight(order.deposit_passport.composite_name)"
            mdInput
            [value]="order.deposit_passport.composite_name"
            disabled
            ></textarea>
        </md-input-container>
      </div>
    </div>
  </section>
  <div class="sub-header">
    <span class="sub-header-title">
      {{ 'Quantity required & available' | translate }}
    </span>
  </div>
  <div class="form-column">
    <span class="form-column-title primary-color">
      {{ 'Required quantity' | translate }}
    </span>
    <conversion-field
      [setToggleColorActive]="true"
      [emptyQuantityHolder]="''"
      identifier="deposit-order-required-quantity"
      [volumeUnit]="order.order_passport.unit"
      [quantity]="order.order_passport.quantity"
      [conversionFactor]="order.deposit.getConversion()"
      [isDisabled]="true">
    </conversion-field>
    <span class="form-column-title primary-color">
      {{ 'Remaining available quantity' | translate }}
    </span>
    <conversion-field
      [setToggleColorActive]="true"
      [emptyQuantityHolder]="''"
      identifier="deposit-order-available-quantity"
      [volumeUnit]="order.order_passport.unit"
      [quantity]="(order.deposit.total - order.deposit.booked)"
      [conversionFactor]="order.deposit.getConversion()"
      [isDisabled]="true">
    </conversion-field>
  </div>
  <div class="sub-header">
    <span class="sub-header-title">
      {{ 'Order modalities' | translate }}
    </span>
  </div>
  <section class="form-column">
    <div class="form-row">
      <div class="flex-column">
        <span class="form-column-title primary-color">{{ 'Pickup date' | translate }}</span>
        <md-input-container class="form-column-field--date">
          <input
            mdInput
            date-only="true"
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            [value]="toDateString(order.order_passport.linked_datetime)"
            disabled
          />
        </md-input-container>
      </div>
      <div class="flex-column flex-column-pickup">
        <span class="form-column-title primary-color">{{ 'Pickup address' | translate }}</span>
        <md-input-container class="form-column-field--address">
          <input
            mdInput
            [value]="order?.address?.address"
            disabled
          />
        </md-input-container>
      </div>
    </div>
    <span class="form-column-title primary-color">{{ 'Price' | translate }}</span>
    <div class="form-row">
      <md-input-container class="form-column-field--price">
        <input
          mdInput
          [value]="order.price"
          disabled
        />
      </md-input-container>
      <span class="form-column-price">€</span>
      <i
      class="form-group__label--question"
      mdTooltip="{{'ESM_COMPILER_NEED_STEP_PRICE_TOOLTIP' | translate}}"
      mdTooltipPosition="above"
      >?</i>
    </div>
    <span class="form-column-title primary-color">{{ 'Description' | translate }}</span>
    <md-input-container class="form-column-field--description">
      <textarea
        rows="1"
        #orderDepositDescription
        [(ngModel)]="order.description"
        disabled
        mdInput
        autocomplete="off"
      ></textarea>
    </md-input-container>
    <span class="form-column-title primary-color" *ngIf="files.length > 0">{{ 'Documents' | translate }}</span>
  </section>

  <section class="form-group-documents" *ngIf="files.length > 0">
    <documents-uploader
      [files]="files"
    ></documents-uploader>
  </section>

  <footer class="controls">
    <ul class="button-group__list">
      <li class="button-group__item button-group__item--more-two">
        <button md-button md-raised-button (click)="reject()">{{ 'Reject' | translate }}</button>
      </li>
      <li class="button-group__item button-group__item--more-two">
        <button md-button md-raised-button
                (click)="checkStatusValidated() ? changeVisibilityOfReportProblemModal() : changeVisibilityOfModificationModal()">
          <ng-container *ngIf="checkStatusValidated()">{{ 'Report a problem' | translate }}</ng-container>
          <ng-container *ngIf="!checkStatusValidated()">{{ 'Ask for modification' | translate }}</ng-container>
        </button>
        <div class="small-modal" *ngIf="reportProblemModalIsVisible">
          <div class="small-modal__header">
            <div class="small-modal__info">
              {{ 'Message to Ecosystem Manager' | translate }}
              <span class="small-modal__data">{{order.problem?.length || 0}}/3000</span>
            </div>
            <div class="small-modal__button">
              <button md-button md-raised-button (click)="reportProblem(order.id, order.problem)">{{ 'Send' | translate
                }}
              </button>
            </div>
          </div>
          <div class="small-modal__body">
            <textarea [(ngModel)]="order.problem"
                      (ngModelChange)="problemChanged()"
                      [class.modification-field--error]="problemIsNull"
                      placeholder="{{ 'Type your problem here' | translate }}" maxlength="3000"></textarea>
          </div>
        </div>
        <div class="small-modal" *ngIf="modificationModalIsVisible">
          <div class="small-modal__header">
            <div class="small-modal__info">
              {{ 'Message to Ecosystem Manager' | translate }}
              <span class="small-modal__data">{{order.modification?.length || 0}}/3000</span>
            </div>
            <div class="small-modal__button">
              <button md-button md-raised-button (click)="askForModification()">{{ 'Send' |
                translate }}
              </button>
            </div>
          </div>
          <div class="small-modal__body">
            <textarea [(ngModel)]="order.modification"
                      (ngModelChange)="modificationChanged()"
                      [class.modification-field--error]="modificationIsNull"
                      placeholder="{{ 'Type your modification here' | translate }}" maxlength="3000"></textarea>
          </div>
        </div>
      </li>
      <li class="button-group__item button-group__item--more-two">
        <button md-button md-raised-button (click)="checkStatusValidated() ? unvalidate() : validate()">
          <ng-container *ngIf="checkStatusValidated()">{{ 'Unvalidate' | translate }}</ng-container>
          <ng-container *ngIf="!checkStatusValidated()">{{ 'Validate' | translate }}</ng-container>
        </button>
      </li>
      <li class="button-group__item " *ngIf="isOrderConfirmed">
        <button
          md-button
          md-raised-button
          (click)="confirm()">
          {{'Confirm' | translate}}
        </button>
      </li>
    </ul>
  </footer>
</ng-container>
