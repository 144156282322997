import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-duplicate-icon',
    templateUrl: './duplicate-icon.component.html',
})
export class DuplicateIconComponent {
    /** Multiplier for the icon size */
    @Input() sizeMultiplier: number = 1;
    /** Icon color */
    @Input() color: string = '#e03e3e';
}
