<div class="input-output">
  <div class="input-output__content">
    <div class="input-output__header">
      <h2 class="input-output__header-text">{{"Input quantity" | translate }}</h2>
      <h2 class="input-output__header-text-center">{{"become" | translate }}</h2>
      <h2 class="input-output__header-text">{{"Output quantity" | translate }}</h2>
    </div>
    <div [ngClass]="{'input-output__info': true, 'input-output__info--additional-field-water': isWaterIndicatorShown()}">

      <div class="input-output__info-left">
        <div class="input-output__info-row-data">
          <md-input-container
            class="input-output__info-number input-output__info-container">
            <input
              mdInput
              autocomplete="off"
              [value]="1"
              [disabled]="true"
              min="0"
              type="number">
<!--            <md-hint class="error-hint" align="start">{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>-->
          </md-input-container>
          <div class="input-output__info-unit">
            <md-select
              [floatPlaceholder]="'never'"
              [(ngModel)]="transform.input_unit"
              placeholder="{{ 'Unit' | translate }}"
              [disabled]="readOnly">
              <md-option
                *ngFor="let unit of units"
                [value]="unit.id">
                {{unit.title | translate }}
              </md-option>
            </md-select>
          </div>
        </div>

        <div class="input-output__info-row-data" *ngIf="isConversionShown(transform.input_unit)">
          <span class="input-output__info-block">1 {{transform.getUnitText(transform.input_unit)}} = &nbsp;</span>
          <md-input-container class="input-output__info-container">
            <input
              [disabled]="readOnly"
              mdInput
              autocomplete="off"
              [(ngModel)]="transform.in_conversion"
              min="0"
              type="number">
<!--              <md-hint class="error-hint" align="start">{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>-->
          </md-input-container>
          <span> &nbsp;{{"kg"| translate}}</span>
        </div>
      </div>


      <div class="input-output__info-dash"></div>
      <div class="input-output__info-right">
        <div class="input-output__info-row-data">
          <md-input-container class="input-output__info-number input-output__info-container">
            <input mdInput
                   [disabled]="readOnly"
                   autocomplete="off"
                   min="0"
                   type="number"
                   [(ngModel)]="transform.output_quantity"
            >
            <!--<md-hint class="error-hint" align="start">{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>-->
          </md-input-container>
          <div class="input-output__info-unit">
            <md-select
              placeholder="{{ 'Unit' | translate }}"
              [floatPlaceholder]="'never'"
              [(ngModel)]="transform.output_unit"
              [disabled]="readOnly">
              <md-option
                *ngFor="let unit of units"
                [value]="unit.id">
                {{unit.title | translate}}
              </md-option>
            </md-select>
          </div>
        </div>
        <div class="input-output__info-data" *ngIf="isConversionShown(transform.output_unit)">
          <span class="input-output__info-block">1 {{transform.getUnitText(transform.output_unit)}} =&nbsp;</span>
          <md-input-container class=" input-output__info-container">
            <input mdInput
                   autocomplete="off"
                   [disabled]="readOnly"
                   min="0"
                   type="number"
                   [(ngModel)]="transform.out_conversion"
            >
            <!--<md-hint class="error-hint" align="start">{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>-->
          </md-input-container>
          <span> &nbsp;{{"kg"| translate}}</span>
        </div>

        <div class="input-output__info-data input-output__info-data-co2">
          <md-input-container class=" input-output__info-container">
            <input mdInput
                   autocomplete="off"
                   type="number"
                   [disabled]="readOnly"
                   [(ngModel)]="transform.co2_generated"
                   [placeholder]="'CO2 generated' | translate">
          </md-input-container>
          <span> &nbsp;{{"kg CO2"| translate}}</span>
        </div>

        <div *ngIf="isWaterIndicatorShown()" class="input-output__info-data input-output__info-data-water">
          <md-input-container class=" input-output__info-container">
            <input mdInput
                   autocomplete="off"
                   type="number"
                   [disabled]="readOnly"
                   [(ngModel)]="transform.water_consumption"
                   [placeholder]="'Water consumption' | translate">
          </md-input-container>
          <span> &nbsp;{{"m3"| translate}}</span>
          <div class="input-output__helper">
            <label *ngIf="!readOnly"
                   class="form-group__label"
                   md-tooltip="{{ 'TRANSFORMATION_INCOME_OUTCOME_TOOLTIP' | translate }}"
                   mdTooltipPosition="above"
              >
              <i class="form-group__label--question">?</i>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
