import {Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BACKSPACE, DELETE, MD_DIALOG_DATA, MdDialog, MdDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {DepositImportWarningComponent} from '../deposit-import-warning/deposit-import-warning.component';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {FileUploader} from 'ng2-file-upload';
import {DepositFile} from '../../../../entities/deposit/models/deposit-file.class';
import {DepositImportService} from '../../../../entities/deposit/services/deposit-import.service';
import {DepositImport} from '../../../../entities/deposit/models/deposit-import.class';
import {DepositFileService} from '../../../../entities/deposit/services/deposit-file.service';
import {IMPORT_ERROR} from '../../../../entities/deposit/values/import-error.const';
import {DepositImportErrorsComponent} from '../../../../entities/deposit/dialogs/deposit-import-errors/deposit-import-errors.component';
import {DeepStreamService} from '../../../../shared/services/deepstream.service';
import {ImportSpinnerComponent} from '../../../../entities/deposit/dialogs/import-spinner/import-spinner.component';
import {
  IErrorImportErrorRecordContainer,
  IErrorReadyCompletionRecordContainer,
  ISpinnerCompletionRecordContainer
} from '../../../../shared/spinner/spinner-completion.interface';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {merge, Subscription} from 'rxjs';
import {ImportCheckedComponent} from '../../../../entities/deposit/dialogs/import-checked/import-checked.component';
import {ReportImport} from '../../../../entities/deposit/models/report-import.class';
import {DocumentsUploaderComponent} from '../../../../shared/uploaders/documents-uploader/documents-uploader.component';
import {ReportImportService} from '../../../../entities/deposit/services/report-import.service';
import {ReportFile} from '../../../../entities/deposit/models/report-file.class';
import {ReportFileService} from '../../../../entities/deposit/services/report-file.service';
import {EErrorStatus} from '../../../../shared/spinner/spinner-completion.enum';

@Component({
  moduleId: module.id,
  selector: 'deposit-import-dialog',
  templateUrl: 'deposit-import-dialog.component.html',
  styleUrls: ['deposit-import-dialog.component.scss']
})
export class DepositImportDialogComponent implements OnInit, OnDestroy {
  @ViewChild(DocumentsUploaderComponent) documentsUploader: DocumentsUploaderComponent;
  depositImportForm: FormGroup;
  depositFileUploader: FileUploader = new FileUploader({});
  uploadedDepositFile: DepositFile[] = [];
  uploadedReportFile: ReportFile[] = [];
  depositImport: DepositImport = new DepositImport();
  reportImport: ReportImport = new ReportImport();
  isReport = false;
  onDocumentSave$: EventEmitter<string> = new EventEmitter();
  onUpdateUploadUrl$: EventEmitter<string> = new EventEmitter();

  fields = [
    {id: 'tab_name', name: 'Tab name'},
    {id: 'first_row', name: 'No. of the first line to be imported'},
    {id: 'last_row', name: 'No. of the last line to be imported'},
    {id: 'passport_id_field', name: 'Passport ID'},
    {id: 'tags_field', name: 'Tag(s)'},
    {id: 'site_name_field', name: 'Site Name'},
    {id: 'quantity_field', name: 'Quantity'},
    {id: 'unit_field', name: 'Unit'},
    {id: 'availability_date_field', name: 'Availability date'},
    {id: 'availability_expiration_field', name: 'Expiration date'},
    {id: 'reference_date', name: 'Reference date'},
    {id: 'state_field', name: 'State'},
    {id: 'state_description_field', name: 'Description of the state'},
    {id: 'demountability_field', name: 'Demountability'},
    {id: 'comments_field', name: 'Comments'},
    {id: 'reused_field', name: 'Reused'},
  ];

  private importParsedRowsStreamSubscription: Subscription;
  private importErrorStreamSubscription: Subscription;
  private importCompletionStreamSubscription: Subscription;
  private importFatalErrorStreamSubscription: Subscription;

  private importSpinnerDialogRef: MdDialogRef<ImportSpinnerComponent>;
  private importErrorDialogRef: MdDialogRef<DepositImportErrorsComponent>;

  private spinnerPercentage = new BehaviorSubject<number>(0);
  private isImportInProcess = false;
  private _columnsWithSameStyle = [
    {id: 'availability_expiration_field', name: 'Expiration date', description: 'Format DD/MM/YYYYY', report: false},
    {id: 'state_field', name: 'State', description: 'PROCESSED_STATE_0_TO_4', report: true},
    {id: 'state_description_field', name: 'Description of the state'
      , description: 'Fields are limited to 65535 characters', report: false},
    {id: 'demountability_field', name: 'Demountability', description: 'PROCESSED_DEMOUNTABILITY_0_TO_4', report: true},
    {id: 'comments_field', name: 'Comments', description: 'Fields are limited to 65535 characters', report: false},
    {id: 'reused_field', name: 'Reused'
      , description: 'First character of the cell must be 0 if not reused and 1 if reused', report: true},
  ];
  private readonly DEPOSIT_IMPORT_STEP = {VALIDATION: 1, LOAD: 2};

  constructor(public notification: LogNotificationService,
              public mdDialogRef: MdDialogRef<void>,
              private _dialog: MdDialog,
              public _fb: FormBuilder,
              public depositImportService: DepositImportService,
              public reportImportService: ReportImportService,
              public depositFileService: DepositFileService,
              public reportFileService: ReportFileService,
              private logNotificationService: LogNotificationService,
              private _translate: TranslateService,
              private zone: NgZone,
              private ds: DeepStreamService,
              @Inject(MD_DIALOG_DATA) public mdDialogData: {isReport?: boolean}) {
                this.isReport = mdDialogData.isReport;
  }

  ngOnDestroy() {
    this.importParsedRowsStreamSubscription.unsubscribe();
    this.importErrorStreamSubscription.unsubscribe();
    if (!!this.isImportInProcess) {
      merge(this.ds.depositImportDeepStream$.depositImportFatalError$.filter(e => this.isOngoingImport(e))
          , this.ds.depositImportDeepStream$.depositCompletionStream$
          .filter((e: ISpinnerCompletionRecordContainer) => !!e.data.success && this.isOngoingImport(e)))
        .subscribe(() => this.unsubscribeObserversAfterLoad());
    } else {
      this.unsubscribeObserversAfterLoad();
    }
  }

  private unsubscribeObserversAfterLoad() {
    this.importCompletionStreamSubscription.unsubscribe();
    this.importFatalErrorStreamSubscription.unsubscribe();
  }

  ngOnInit() {
    this.depositImportForm = this._fb.group({
      id: null,
      tab_name: ['', Validators.required],
      first_row: ['', Validators.required],
      last_row: ['', Validators.required],
      passport_id_field: ['', Validators.required],
      site_name_field: !this.isReport ? ['', Validators.required] : [''],
      tags_field: [''],
      quantity_field: ['', Validators.required],
      unit_field: ['', Validators.required],
      availability_date_field: [''],
      availability_expiration_field: [''],
      reference_date: [''],
      reference_date_expire: [''],
      state_field: [''],
      state_description_field: [''],
      demountability_field: [''],
      comments_field: [''],
      reused_field: [''],
    });
    this.importParsedRowsStreamSubscription = this.ds.depositImportDeepStream$
      .depositImportParsedRowsStream$.filter(e => this.isOngoingImport(e))
      .subscribe((e: ISpinnerCompletionRecordContainer) => {
        const progress = this.toPercent(e.data.count, e.data.total, false);
        this.zone.run(() => this.spinnerPercentage.next(progress));
        if (!this.importSpinnerDialogRef) {
          this.showLoadingSpinner();
          this.importSpinnerInstance.showPercentage();
        }
      });

    this.importErrorStreamSubscription = this.ds.depositImportDeepStream$
      .depositImportErrorsStream$.filter(e => this.isOngoingImport(e))
      .subscribe((e: IErrorReadyCompletionRecordContainer) => {
        this.importSpinnerInstance.showPercentage(false);
        this.showErrorsComponent(e.data.import_id);
    });

    this.importCompletionStreamSubscription = this.ds.depositImportDeepStream$
      .depositCompletionStream$.filter(e => this.isOngoingImport(e))
      .subscribe((e: ISpinnerCompletionRecordContainer) => {
        const isCompletedOnSuccessMessage = this.isReport || (e.data.success !== undefined && !!e.data.success);
        const progress = this.toPercent(e.data.count, e.data.total, isCompletedOnSuccessMessage);
        this.zone.run(() => this.spinnerPercentage.next(progress));
        if (!this.importSpinnerDialogRef) {
          this.loadSpinner();
        }
        this.checkImportSuccess();
    });

    this.importFatalErrorStreamSubscription = this.ds.depositImportDeepStream$
      .depositImportFatalError$.filter(e => this.isOngoingImport(e))
      .subscribe((e: IErrorImportErrorRecordContainer) => {
        if (e.data.error_status === EErrorStatus.UNKNOWN) {
          this.logNotificationService.error('Failed to create the content for unknown reason', undefined, true);
          if (!!this.importSpinnerDialogRef) {
            this.destroyImportSpinner();
          }
        }
      });
  }

  get importSpinnerInstance(): ImportSpinnerComponent {
    return !!this.importSpinnerDialogRef && this.importSpinnerDialogRef.componentInstance;
  }

  get columnsWithSameStyle() {
    return this.isReport ? this._columnsWithSameStyle.filter(col => !!col.report) : this._columnsWithSameStyle;
  }

  private isOngoingImport(e: IErrorReadyCompletionRecordContainer | ISpinnerCompletionRecordContainer) {
    const importId = e.data.import_id;
    return importId === this.depositImport.id || importId === this.reportImport.id;
  }

  private toPercent(value: number, total: number, allowHundredPercent = true) {
     const progress = Math.trunc((value / total) * 100);
     return (progress === 100 && !allowHundredPercent) ? 99 : progress;
  }

  checkImportSuccess(): void {
    if (!!this.importSpinnerInstance && this.importSpinnerInstance.waitingForErrors) {
      this.importSpinnerInstance.waitForImport();
    }

    if (this.spinnerPercentage.getValue() === 100) {
      this.onImportSuccess();
    }
  }

  private loadSpinner(): void {
    this.showLoadingSpinner();
    this.importSpinnerInstance.waitForImport();
  }

  private showLoadingSpinner() {
    if (!!this.importSpinnerDialogRef) {
      return null;
    }
    this.importSpinnerDialogRef = this._dialog.open(ImportSpinnerComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
      disableClose: true,
      data: {percentage: this.spinnerPercentage}
    });
  }

  onImportSuccess(): void {
    this.destroyImportSpinner();

    this.zone.run(() => {
      if (this.isReport) {
        this._dialog.open(ImportCheckedComponent, {
          ...DIALOG_THEME.ALERT_PRIMARY
        }).afterClosed().subscribe(() => {
          this.mdDialogRef.close({
            reportFile: this.uploadedReportFile,
            reportImport: this.reportImport});
        });
      } else {
        this.mdDialogRef.close(true);
        this.notification.success('Import successful', 2000, true);
      }
    });
  }

  private destroyImportSpinner(): void {
    this.isImportInProcess = false;
    this.zone.run(() => {
      this.importSpinnerDialogRef.close();
      this.importSpinnerDialogRef.afterClosed().subscribe(() => {
        this.importSpinnerDialogRef = void 0;
        this.spinnerPercentage.next(0);
      });
    });
  }

  documentUploaded() {
    this.showLoadingSpinner();
    this.spinnerPercentage.next(0);
    this.importSpinnerInstance.showPercentage();
    this.importSpinnerInstance.showStep(true, Object.keys(this.DEPOSIT_IMPORT_STEP).length);

    const spreadsheetImport = this.isReport ? this.reportImport : this.depositImport;
    spreadsheetImport.startImport().subscribe(
      () => {
        this.isImportInProcess = true;
      },
      () => this.destroyImportSpinner()
    );
  }

  allowOnlyGreaterThanZeroNumbers(e: KeyboardEvent) {
    this.allowOnly(e, /[0-9]/i);
  }

  allowOnlyLetters(e: KeyboardEvent) {
    this.allowOnly(e, /[a-z]/i);
  }

  checkRequiredDates() {
    return !this.depositImportForm.controls['availability_date_field'].value &&
      !this.depositImportForm.controls['reference_date'].value;
  }

  datePickerKeydown(e: KeyboardEvent) {
    if (e.keyCode === BACKSPACE || e.keyCode === DELETE) {
      this.depositImportForm.controls['reference_date'].setValue(null);
    }
    e.preventDefault();
  }

  private allowOnly(e: KeyboardEvent, regex: RegExp) {
    const testChar = String.fromCharCode(e.keyCode >= 96 && e.keyCode <= 105 ? e.keyCode - 48 : e.keyCode);
    if (!(regex.test(testChar)) && ![8, 9, 37, 39, 46].some(k => k === e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  getCharOfColumnsWithSameStyle(index: number) {
    return String.fromCharCode((this.isReport ? 'D' : 'G').charCodeAt(0) + index);
  }

  updateImportAndShowWarning() {
    if (!this.validateAll()) {
      return;
    }
    this.depositImportForm.controls['id'].setValue(this.depositImport.id);
    this.depositImport = new DepositImport(this.depositImportForm.value);
    this.depositImport.reference_date = this.formatDateToMinUTCString(this.depositImport.reference_date);

    this._dialog.open(DepositImportWarningComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
      data: {depositImport: this.depositImport},
    }).afterClosed().subscribe((isConfirmed) => {
      if (!isConfirmed) {
        return;
      }

      this.depositImport.save().subscribe((depositImportInserted: DepositImport) => {
        this.depositImport = depositImportInserted;
        if (this.uploadedDepositFile.length === 1) {
          this.documentUploaded();
        } else {
          this.onUpdateUploadUrl$.emit(this.depositImport.getFileUploadUrl());
          this.onDocumentSave$.emit();
        }
      });
    });
  }

  private formatDateToMinUTCString(dateString: string): string {
    if (dateString === '') {
      return '';
    }
    const arrDate = dateString.split('-');
    return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
  }

  checkFile() {
    if (!this.validateAll()) {
      return;
    }
    this.depositImportForm.controls['id'].setValue(this.reportImport.id);
    this.reportImport = new ReportImport(this.depositImportForm.value);

    this.reportImport.save().subscribe((depositImportInserted: ReportImport) => {
      this.reportImport = depositImportInserted;
      this.onUpdateUploadUrl$.emit(this.reportImport.getFileUploadUrl());
      this.onDocumentSave$.emit();
      this.showLoadingSpinner();
      if (this.uploadedReportFile.length === 1) {
        this.documentUploaded();
      } else {
        this.onUpdateUploadUrl$.emit(this.reportImport.getFileUploadUrl());
        this.onDocumentSave$.emit();
      }

    });
  }

  private validateAll(): boolean {
    const duplicates = this.checkDuplicatedColumnNames();
    if (duplicates.length > 0) {
      this.logNotificationService.error(`${this._translate.instant(this.fields.find(f => f.id === duplicates[0]).name)}
                                        : ${this._translate.instant('This column is already used')}`);
      return false;
    }
    this.clearDuplicatedErrorsAndTouch();

    const requiredField = this.findControlNameWithRequiredError();
    if (requiredField) {
      this.logNotificationService.error(`${this._translate.instant(requiredField)} ${this._translate.instant('is required')}.`, null, true);
      return false;
    }

    if (!this.isReport && this.checkRequiredDates()) {
      this.logNotificationService.error(this._translate.instant('Date of reference is required if date of availability is empty'));
      return false;
    }

    if (this.documentsUploader.queueFiles !== 1 &&
      (this.isReport ?  this.uploadedReportFile.length !== 1 : this.uploadedDepositFile.length !== 1)) {
      this.logNotificationService.error(this._translate.instant('File has not been upload'));
      return false;
    }

    if (this.depositImportForm.get('first_row').value > this.depositImportForm.get('last_row').value) {
      this.depositImportForm.get('first_row').setErrors({required: true});
      this.depositImportForm.get('first_row').markAsTouched({onlySelf: true});
      this.logNotificationService.error(this._translate.instant('First row is greater than last row'));
      return false;
    }

    return true;
  }

  private checkDuplicatedColumnNames() {
    const spreadSheetColumns = Object.keys(this.depositImportForm.controls)
      .filter(element => !['tab_name', 'first_row', 'last_row', 'id', 'reference_date'].includes(element));

    const lookup = spreadSheetColumns.reduce((a, e) => {
      a[this.depositImportForm.get(e).value.toUpperCase()] = ++a[this.depositImportForm.get(e).value.toUpperCase()] || 0;
      return a;
    }, {});

    return spreadSheetColumns.filter(e => {
      const control = this.depositImportForm.get(e);
      if (!!control.value && lookup[control.value.toUpperCase()]) {
        control.setErrors({duplicate: true});
        control.markAsTouched({onlySelf: true});
        return e;
      }
    });
  }

  private clearDuplicatedErrorsAndTouch() {
    Object.keys(this.depositImportForm.controls).forEach(field => {
      const control = this.depositImportForm.get(field);
      if (control.getError('duplicate')) {
        control.setErrors(null);
      }
      control.markAsTouched({onlySelf: true});
    });
  }

  private findControlNameWithRequiredError(): string {
    const errorField = this.fields
      .filter(field => !['availability_date_field', 'reference_date'].includes(field.id))
      .find(field => this.depositImportForm.controls[field.id].getError('required'));
    return errorField ? errorField.name : null;
  }

  private showErrorsComponent(depositImportId: number) {
    const importService = this.isReport ? this.reportImportService : this.depositImportService;
    importService.view(depositImportId, {expand: 'errors'})
      .subscribe((data: DepositImport) => {
        const errors = !!data.errors.length ? data.errors.map(e => {
          const errorDescription = IMPORT_ERROR[e.error_type] ? this.translate(IMPORT_ERROR[e.error_type], e.value) : '';
          return {error: errorDescription, line: e.row, value: e.value};
        }) : [];
        if (!!errors.length) {
          this.isImportInProcess = false;
          if (this.importSpinnerDialogRef) {
            this.zone.run(() => {
              this.importSpinnerDialogRef.close();
              this.importSpinnerDialogRef = void 0;
            });
          }
          this.zone.run(() => {
            if (!!this.importErrorDialogRef && !!this.importErrorDialogRef.componentInstance) {
              return;
            }
            this.importErrorDialogRef = this._dialog.open(DepositImportErrorsComponent, {
              ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
              data: {color: this.isReport ? 'blue' : 'green', errors: errors}
            });
          });
        } else {
          if (!!this.importSpinnerDialogRef) {
            this.importSpinnerInstance.waitForImport();
            this.importSpinnerInstance.changeStep(this.DEPOSIT_IMPORT_STEP.LOAD);
            this.spinnerPercentage.next(0);
          }
        }
      });
  }

  private translate(key: string, x): string {
    let message = '';
    this._translate.get(key, {X: x}).subscribe((res: string) => {
      message += res;
    });
    return message;
  }
}
