import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MdDialogRef } from '@angular/material';
var RequestSiteDeletionConfirmDialogComponent = /** @class */ (function () {
    function RequestSiteDeletionConfirmDialogComponent(translate, dialog) {
        this.translate = translate;
        this.dialog = dialog;
    }
    RequestSiteDeletionConfirmDialogComponent.prototype.ngOnInit = function () {
        var data = this.dialog._containerInstance.dialogConfig.data;
        this.sitesForDeletion = data.sites || [];
        this.numberOfDeposits = data.depositsCount || 0;
    };
    RequestSiteDeletionConfirmDialogComponent.prototype.getSiteList = function () {
        return this.sitesForDeletion.map(function (site) { return site.name; }).join(' - ');
    };
    Object.defineProperty(RequestSiteDeletionConfirmDialogComponent.prototype, "mainInfoText", {
        get: function () {
            return this.translate.instant("The deletion will be permanent and the N associated deposits will be deleted.", { X: this.numberOfDeposits });
        },
        enumerable: true,
        configurable: true
    });
    return RequestSiteDeletionConfirmDialogComponent;
}());
export { RequestSiteDeletionConfirmDialogComponent };
