import {UploadedDocument} from '../../../shared/models/uploaders/document.class';
import {CompanyFileService} from '../services/company-file.service';

export class CompanyFile extends UploadedDocument {
  protected provider = CompanyFileService;

  basename: string;
  filepath: string;
  company_id: number;

  protected fields() {
    return [
      ...super.fields()
      , 'company_id'
    ];
  }
}
