import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { Observable } from 'rxjs';
import { ReportImportService } from '../services/report-import.service';

export class ReportImport extends ExtendedActiveRecord {

  protected provider = ReportImportService;

  public id: number;
  public tab_name: string;
  public first_row: number;
  public last_row: number;
  public passport_id_field: string;
  public site_name_field: string;
  public tags_field: string;
  public quantity_field: string;
  public unit_field: string;
  public availability_date_field: string;
  public reference_date: string;
  public state_field: string;
  public state_description_field: string;
  public demountability_field: string;
  public comments_field: string;
  public reused_field: string;
  public errors: {id: number, import_id: number, error_type: number, row: number, value: string}[];

  constructor(data?: any) {
    super(data);
  }

  protected fields() {
    return [
      'id',
      'tab_name',
      'first_row',
      'last_row',
      'passport_id_field',
      'site_name_field',
      'tags_field',
      'quantity_field',
      'unit_field',
      'availability_date_field',
      'reference_date',
      'state_field',
      'state_description_field',
      'demountability_field',
      'comments_field',
      'reused_field',
      'errors',
    ];
  }

  startImport(): Observable<any> {
    return this.simplePost('/' + this.id + '/start');
  }
}
