import { AfterViewInit, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { MdDialog } from '@angular/material';
import { Deposit } from '../../../models/deposit.class';
import { DepositPhotoService } from '../../../services/deposit-photo.service';
import { DepositPhoto } from '../../../models/deposit-photo.class';
import { DepositService } from '../../../services/deposit.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../../../shared/services/validation-service';
import { DepositControlStates } from '../../../models/deposit-control-states.class';
import { PERIOD_OF_USE_TYPES } from '../../../../passport/values/period-of-use.values';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DEMOUNTABILITY_STATES } from '../../../values/demountability-states.const';
import { setTextareaHeightToFitContent } from '../../../../../shared/helpers/set-textarea-height-to-fit-content';
import { DEPOSIT_REUSE_SOURCE_SELECT_ENTRIES, EDepositReuseSource } from '../../../values/deposit-reuse-source.const';
import { PASSPORT_UNIT_WITHOUT_CONVERSION } from '../../../../passport/values/passport-units.const';
import { DepositDocumentFile } from '../../../models/deposit-document-file.class';
import { DepositDocumentFileService } from '../../../services/deposit-document-file.service';
var OtherStepComponent = /** @class */ (function () {
    function OtherStepComponent(notification, dialog, _depositService, _depositPhotoService, fileService, fb, _translate) {
        this.notification = notification;
        this.dialog = dialog;
        this._depositService = _depositService;
        this._depositPhotoService = _depositPhotoService;
        this.fileService = fileService;
        this.fb = fb;
        this._translate = _translate;
        this.DEPOSIT_SOURCE_LIST = DEPOSIT_REUSE_SOURCE_SELECT_ENTRIES;
        this.URL_MAX_LENGTH = ValidationService.URL_MAX_LENGTH;
        this.hasBaseDropZoneOver = false;
        this.depositPhotoUploader = new FileUploader({ allowedFileType: ['image', 'pdf'] });
        this.uploadedFiles = [];
        this.uploadedDocs = [];
        this.demountabilityStates = DEMOUNTABILITY_STATES;
        this.subscriptions = [];
    }
    Object.defineProperty(OtherStepComponent.prototype, "depositSourceView", {
        get: function () {
            return this.deposit.reused ? this.deposit.deposit_source : EDepositReuseSource.NO_SOURCE;
        },
        enumerable: true,
        configurable: true
    });
    OtherStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.deposit.deposit_source === EDepositReuseSource.REUSED) {
            this.deposit.deposit_source = EDepositReuseSource.UNKNOWN;
        }
        this.initForm();
        this.preloadUploadedItems();
        if (!this.deposit.condition) {
            this.deposit.condition = 1;
        }
        if (this.listener) {
            this.listener.subscribe(function (event) {
                if (event.key === 'changeDeposit') {
                    _this.deposit = event.data;
                }
            });
        }
        this.depositPhotoUploader.onWhenAddingFileFailed = function (_, filter) {
            if (filter && filter.name === 'fileType') {
                _this.notification.error('This format is not supported', null, true);
            }
        };
    };
    OtherStepComponent.prototype.initForm = function () {
        var _this = this;
        this.unitInfoForm = this.fb.group({
            quantity: [this.deposit.quantity, [Validators.required, Validators.min(0), ValidationService.validateNumberOnBlur()]],
            unit: [{ value: '', disabled: true }, function (c) { return console.log(c.value); }],
            availability_date: [this.formatToDateView(this.deposit.availability_date), Validators.required],
            availability_expiration: [this.formatToDateView(this.deposit.availability_expiration), function (c) { }],
            use_period_of_use: [false, function (c) { return _this.usePeriodOfUse(c); }],
            reuse_source: [this.depositSourceView],
            potential_reuse: [this.deposit.potential_reuse],
            potential_reuse_source: [this.deposit.deposit_potential_source],
            price: [this.deposit.price, ValidationService.validateNumberOnBlur()],
            url_link: [this.deposit.url_link, [ValidationService.validateURLLength(), ValidationService.validateURL()]],
            conversion: [this.deposit.conversion, ValidationService.validateQuantity],
            product_comment: [this.deposit.product_comment, ValidationService.validateURLLength()],
            length: [this.deposit.length, ValidationService.validateQuantity],
            width: [this.deposit.width, ValidationService.validateQuantity],
            height: [this.deposit.height, ValidationService.validateQuantity],
            color: [this.deposit.color, ValidationService.validateURLLength()],
        });
        var updateModel = this.updateModel.bind(this);
        updateModel('availability_date', function (val) { return _this.onAvailabilityDateChanged(val); });
        updateModel('availability_expiration', function (val) { return _this.onAvailabilityExpireChanged(val); });
        updateModel('potential_reuse', function (val) {
            _this.deposit.potential_reuse = +val;
        });
        updateModel('reuse_source', function (val) {
            if (val < 0) {
                _this.deposit.reused = 0;
            }
            else {
                _this.deposit.reused = 1;
                _this.deposit.deposit_source = +val;
            }
        });
        updateModel('potential_reuse_source', function (val) { return _this.deposit.deposit_potential_source = +val; });
        ['url_link', 'product_comment', 'color',].forEach(function (name) { return _this.updateModelDefault(name); });
        ['conversion', 'length', 'width', 'height', 'price'].forEach(function (name) { return _this.updateNumberDefault(name); });
        this.deposit.setValidationStrategy(this, 3, function () { return _this.unitInfoForm.valid; });
        // this.subscriptions.push(this.deposit.beforeSave$.subscribe(() => touchEachFormControl(this.unitInfoForm)));
    };
    OtherStepComponent.prototype.updateModel = function (formName, change) {
        this.subscriptions.push(this.unitInfoForm.get(formName).valueChanges
            .subscribe(function (value) { return change(value); }));
    };
    OtherStepComponent.prototype.updateModelDefault = function (formName) {
        var _this = this;
        this.updateModel(formName, function (value) {
            _this.deposit[formName] = value;
        });
    };
    OtherStepComponent.prototype.updateNumberDefault = function (formName) {
        var _this = this;
        this.updateModel(formName, function (value) {
            if (typeof value === 'string') {
                value = Number(value.replace(',', '.'));
            }
            _this.deposit[formName] = value;
        });
    };
    OtherStepComponent.prototype.ngAfterViewInit = function () {
        var textAreas = [];
        var pushIfNotEmpty = function (modelField, textArea) {
            if (modelField) {
                textAreas.push(textArea);
            }
        };
        pushIfNotEmpty(this.deposit.comments, this.commentsTextarea);
        pushIfNotEmpty(this.deposit.description, this.descriptionTextarea);
        pushIfNotEmpty(this.deposit.product_comment, this.productCharacteristicsTextarea);
        pushIfNotEmpty(this.deposit.color, this.colorTextarea);
        if (textAreas.length) {
            setTimeout(function () { return setTextareaHeightToFitContent(textAreas); });
        }
    };
    OtherStepComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
        this.subscriptions = [];
    };
    OtherStepComponent.prototype.usePeriodOfUse = function (control) {
        var _this = this;
        if (control.value) {
            var newDate = this.getAvailabilityByPeriodOfUse();
            if (newDate) {
                if (!!this.deposit.availability_expiration
                    && !this.checkAvailabilityInterval(this.formatToDateView(newDate), this.formatToDateView(this.deposit.availability_expiration))) {
                    control.setValue(false);
                    return;
                }
                this.deposit.availability_date = newDate;
                this.unitInfoForm.get('availability_date').setValue(this.formatToDateView(newDate), { emitEvent: false });
            }
            else {
                this._translate.get('Period of use hasnt been filled').subscribe(function (text) {
                    _this.notification.error(text, null, true);
                });
                control.setValue(false);
            }
        }
    };
    OtherStepComponent.prototype.formatToDateView = function (date) {
        return !!date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '';
    };
    OtherStepComponent.prototype.checkAvailabilityInterval = function (availabilityStr, expirationStr) {
        var _this = this;
        var availability = moment(availabilityStr, 'DD-MM-YYYY');
        var expiration = moment(expirationStr, 'DD-MM-YYYY');
        if (expiration.diff(availability, 'days') < 0) {
            this._translate.get('Expiration date cannot be earlier than availability date')
                .subscribe(function (text) {
                _this.notification.error(text, null, true);
            });
            return false;
        }
        return true;
    };
    OtherStepComponent.prototype.getAvailabilityByPeriodOfUse = function () {
        var periodQuantity = this.deposit.passport.period;
        var periodType = this.getPeriodOfUseType();
        if (periodQuantity && periodType) {
            var duration = moment.duration(periodQuantity, periodType.momentUnit);
            return moment().add(duration).format('YYYY-MM-DD');
        }
        else {
            return null;
        }
    };
    OtherStepComponent.prototype.getPeriodOfUseType = function () {
        var _this = this;
        return PERIOD_OF_USE_TYPES.find(function (type) { return type.value === _this.deposit.passport.period_type; });
    };
    OtherStepComponent.prototype.onAvailabilityExpireChanged = function (availabilityDate) {
        if (this.deposit.availability_date) {
            if (!this.checkAvailabilityInterval(this.formatToDateView(this.deposit.availability_date), availabilityDate)) {
                this.unitInfoForm.get('availability_expiration').setValue(this.formatToDateView(this.deposit.availability_expiration), { emitEvent: false });
                return;
            }
        }
        this.deposit.availability_expiration = this.formatToDateSource(availabilityDate);
    };
    OtherStepComponent.prototype.formatToDateSource = function (date) {
        var parsedDate = moment(date, 'DD-MM-YYYY');
        return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : null;
    };
    OtherStepComponent.prototype.onAvailabilityDateChanged = function (availabilityDate) {
        if (this.deposit.availability_expiration) {
            if (!this.checkAvailabilityInterval(availabilityDate, this.formatToDateView(this.deposit.availability_expiration))) {
                this.unitInfoForm.get('availability_date').setValue(this.formatToDateView(this.deposit.availability_date), { emitEvent: false });
                return;
            }
        }
        this.deposit.availability_date = this.formatToDateSource(availabilityDate);
        if (availabilityDate !== this.getAvailabilityByPeriodOfUse()) {
            this.unitInfoForm.get('use_period_of_use').setValue(false);
        }
    };
    OtherStepComponent.prototype.getUsePeriodTooltipMessage = function () {
        var args = this.getUsePeriodTooltipArgs();
        var message = 'Period of use : [Period of use] + [unit]';
        return args ? this._translate.instant(message, args) : '';
    };
    OtherStepComponent.prototype.getUsePeriodTooltipArgs = function () {
        var type = this.getPeriodOfUseType();
        return this.deposit.passport.period && type ?
            { X: this.deposit.passport.period, Y: this._translate.instant(type.view.toLowerCase()) } : '';
    };
    OtherStepComponent.prototype.isConversionShown = function () {
        return this.deposit.passport.unit && !PASSPORT_UNIT_WITHOUT_CONVERSION.includes(this.deposit.passport.unit);
    };
    OtherStepComponent.prototype.fileOverBase = function (e) {
        this.hasBaseDropZoneOver = e;
    };
    OtherStepComponent.prototype.openDropZone = function () {
        var _this = this;
        if (!this.deposit.status || this.deposit.status <= 0) {
            this.deposit.status = 1;
        }
        this.deposit.save().subscribe(function (res) {
            console.log('RES', res);
            _this.depositPhotoUploader.setOptions({
                url: _this._depositService.getFileUploadUrl(res.id),
                itemAlias: 'file'
            });
            _this.depositPhotoUploader.onCompleteItem = function (item, response) {
                console.log('onCompleteItem');
                _this.uploadedFiles.push(new DepositPhoto(response));
                // this.onSave && this.onSave.emit(this.passport);
            };
        });
    };
    OtherStepComponent.prototype.preloadUploadedItems = function () {
        var _this = this;
        this._depositPhotoService
            .get({ deposit_id: this.deposit.id })
            .subscribe(function (result) {
            console.log('fetch uploaded files', result);
            for (var _i = 0, result_1 = result; _i < result_1.length; _i++) {
                var item = result_1[_i];
                _this.uploadedFiles.push(new DepositPhoto(item));
            }
        });
        this.fileService.get({ deposit_id: this.deposit.id }).subscribe(function (res) { return res.forEach(function (item) { return _this.uploadedDocs.push(new DepositDocumentFile(item)); }); });
    };
    return OtherStepComponent;
}());
export { OtherStepComponent };
