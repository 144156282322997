import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Passport} from '../../../models/passport.class';
import {PASSPORT_STATUS_CONST} from '../../../values/passport-status.const';
import {ModuleNames} from '../../../../../shared/values/module-names.const';
import {TranslateService} from '@ngx-translate/core';
import {PASSPORT_CERTIFICATION_LEVELS} from '../../../values/passport-certification-levels.const';
import {PERIOD_OF_USE_TYPES} from '../../../values/period-of-use.values';
import {PASSPORT_RATING} from '../../../values/passport-rating/passport-rating-values.const';
import {PASSPORT_RATING_LABEL} from '../../../values/passport-rating/passport-rating-label.const';
import {UsedEnergy} from '../../../models/used-energy.class';
import {PASSPORT_DEPENDENCY} from '../../../values/passport-dependency.const';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { Utils } from '../../../../../shared/utils/utils.class';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'passport-item',
  templateUrl: 'passport-item.component.html',
  styleUrls: ['passport-item.component.css']
})
export class PassportItemComponent implements OnInit {
  @Input()
  passport: Passport;
  @Input() parentModuleName: string;
  @Input() isForPublicAccess: boolean;
  @Output() onOpen?: EventEmitter<any> = new EventEmitter();
  @Output() onDelete?: EventEmitter<any> = new EventEmitter();
  @Output() onDuplicate?: EventEmitter<any> = new EventEmitter();
  @Output() onVersionFilter?: EventEmitter<any> = new EventEmitter();
  public moduleNames = ModuleNames;
  passportRatinglabel = PASSPORT_RATING_LABEL;
  @Input() isVersionFilterOn: boolean;

  constructor(private router: Router,
              private translateService: TranslateService,
              public notification: LogNotificationService,
              private filterState: NavbarStateService) {

  }
  ngOnInit() {
   // this.getVersionOnCla÷ss()
  }
  openPassport(record: Passport) {
    this.onOpen.emit(record);
  }
  copyProductId() {
    if (this.passport.product_passport_id) {
      const msg = 'Id copied';
      Utils.copyToClipBoard(this.passport.product_passport_id);
      this.notification.success(msg, 2000, true);
    } else {
      const msg = 'Id not yet set';
      this.notification.error(msg, 2000, true);
    }
  }
  filterByVersion(record: Passport) {
    if (this.onVersionFilter) {
     this.isVersionFilterOn = !this.isVersionFilterOn ;
     const data = { passport: record , filter: this.isVersionFilterOn};
     this.onVersionFilter.emit(data);
     this.getVersionOnClass();
    }
  }

  getVersionOnClass() {
    const isIndep = !this.isPassportIndependent() || this.isForPublicAccess;
    return {
      'passportlist__item-version-indep': isIndep,
      'passportlist__item-version': !isIndep,
      'background_selected': this.isVersionFilterOn
    };
  }

  duplicatePassport(record: Passport) {
    if (this.onDuplicate) {
      this.onDuplicate.emit(record);
    }
  }

  deletePassport(record: Passport) {
    if (this.onDelete) {
      this.onDelete.emit(record);
    }
  }


  createNew() {
    this.router.navigate(['passports/new']);
  }

  getStatusView(statusValue) {
   if ( this.parentModuleName === ModuleNames.SUPERVISOR || this.parentModuleName === ModuleNames.AGENT ) {
      if (statusValue === PASSPORT_STATUS_CONST.DRAFT.value) {return PASSPORT_STATUS_CONST.DRAFT.view; }
      return;
   }
    switch (statusValue) {
      case PASSPORT_STATUS_CONST.ASK_FOR_MODIFICATION.value:
        return PASSPORT_STATUS_CONST.ASK_FOR_MODIFICATION.view;
      case PASSPORT_STATUS_CONST.DRAFT.value:
        return PASSPORT_STATUS_CONST.DRAFT.view;
      case PASSPORT_STATUS_CONST.PUBLISHED.value:
        return PASSPORT_STATUS_CONST.PUBLISHED.view;
      case PASSPORT_STATUS_CONST.WAITING_FOR_PUBLICATION.value:
        return PASSPORT_STATUS_CONST.WAITING_FOR_PUBLICATION.view;
      default:
        break;
    }
  }

  isCompanyVisible(): boolean {
    return (this.parentModuleName === ModuleNames.MANUFACTURER || this.parentModuleName === ModuleNames.SPECIAL_ADMIN);
  }

  areRatingsVisible(): boolean {
    return !!(this.parentModuleName === ModuleNames.MANUFACTURER
      || this.parentModuleName === ModuleNames.SUPERVISOR
      || this.parentModuleName === ModuleNames.AGENT
      || this.parentModuleName === ModuleNames.SPECIAL_ADMIN || this.isForPublicAccess);
  }

  getRatingClass(rating: string, value: number) {
    const getPostfix = () => {
      switch (value) {
        case PASSPORT_RATING.THIRD_PARTY_VALID:
          return 'colored';
        case PASSPORT_RATING.DECLARATION_VALID:
          return 'dark';
      }
    };
    return {
      'passportlist__item-content-ratings-icon': true,
      [`passportlist__item-content-ratings-icon-${rating}-${getPostfix()}`]: true
    };
  }

  isRatingVisible(value: number): boolean {
    return !!value;
  }

  getC2cTooltipValue(): { X: string; Y: string; } {
    const certificationLevel = PASSPORT_CERTIFICATION_LEVELS.find(i => i.key === this.passport.certification_level);
    return {
      X: !!certificationLevel ? this.translateService.instant(certificationLevel.value) : '?',
      Y: this.passport.certification_expiration || '?'
    };
  }


  getCircularityTooltipValue(): { X: string; Y: string; Z: string; } {
    const isPeriodExist: boolean = !!this.passport.period && !!this.passport.period_type;
    const periodViewValue: string = isPeriodExist ? PERIOD_OF_USE_TYPES.find(i => i.value === this.passport.period_type).view : '';
    const periodOfUse = isPeriodExist ? `${this.passport.period} ${this.translateService.instant(periodViewValue) || ''}` : '?';
    const nextUsageViewValue = (): string => {
      if (!!this.passport.next_usages && !!this.passport.next_usages.length) {
        return this.passport.next_usages.map(i => i.getTypeText()).join(', ') || '?';
      }
      return '?';
    };
    return {
      X: !!this.passport.recycle_percentage && this.passport.recycle_percentage.toString() + '%' || '?',
      Y: periodOfUse,
      Z: nextUsageViewValue()
    };
  }

  getEnergyTooltipValues(): { energy_in_production: string; total_whole_life: string; product_stage: string; end_of_life: string } {
    const getRenewableEnergiesSum = (): string => {
      let energiesSum = null;
      if (!!this.passport.energies && !!this.passport.energies.length) {
        this.passport.energies.forEach((energy: UsedEnergy) => {
          if (energy.isRenewable()) {
            energiesSum += energy.percentage;
          }
        });
      }
      return !!energiesSum ? `${energiesSum}%` : '?';
    };

    const getCarbonValue = (value): string => !!value ? value : '?';
    return {
      energy_in_production: getRenewableEnergiesSum(),
      total_whole_life: getCarbonValue(this.passport.carbon_total),
      product_stage: getCarbonValue(this.passport.carbon_product),
      end_of_life: getCarbonValue(this.passport.carbon_end)
    };
  }

  isPassportIndependent(): boolean {
    return this.parentModuleName !== this.moduleNames.SPECIAL_ADMIN
      && !(this.passport.type === PASSPORT_DEPENDENCY.INDEPENDENT && (this.parentModuleName === this.moduleNames.SUPERVISOR || this.parentModuleName === this.moduleNames.AGENT) );
  }
  getCreatedBy(): string {
    if (!this.passport || !this.passport.user) {
      return;
    }
    if (this.isCompanyVisible()) {
      return this.passport.user.company_name;
    }
    return (this.passport.type === PASSPORT_DEPENDENCY.INDEPENDENT) ? this.passport.user.company_name : this.passport.user.username;
  }
  formatPassportModifiedDate() {
    return Utils.formatDate(this.passport.updated_at);
  }
  formatPassportCreationDate() {
    return Utils.formatDate(this.passport.created_at);
  }
  get passportName() {
    return this.passport.getProductName(this.filterState.multilanguage); // || this.passport.name;
  }
}
