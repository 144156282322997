import { EventEmitter } from '@angular/core';
import { Transform } from '../models/transform.class';
import { PASSPORT_UNITS_TYPE } from '../../passport/values/passport-units.const';
import { Passport } from '../../passport/models/passport.class';
var TransformConversionComponent = /** @class */ (function () {
    function TransformConversionComponent() {
        var _a;
        this.transform = new Transform();
        this.disabled = false;
        this.disabledEmptyPlaceholder = '';
        this.defaultUnit = PASSPORT_UNITS_TYPE.TONNE;
        this.quantityChanged = new EventEmitter();
        this.defaultUnitsToConversions = (_a = {},
            _a[PASSPORT_UNITS_TYPE.KG] = 1,
            _a[PASSPORT_UNITS_TYPE.TONNE] = 1000,
            _a);
    }
    Object.defineProperty(TransformConversionComponent.prototype, "conversionFactorField", {
        get: function () {
            return Transform.getConversion(this.transform, this._conversion, this._previousConversion);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformConversionComponent.prototype, "volumeUnitField", {
        get: function () {
            return this._unitFunc(this.transform, this._previousUnit, this._defaultUnit);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformConversionComponent.prototype, "quantityField", {
        get: function () {
            return this.isInheritUnit() ? this.quantity : Transform.transformQuantityToCustomDefault(this.quantity, this.transform, this.defaultUnitsToConversions[this._defaultUnit]);
        },
        enumerable: true,
        configurable: true
    });
    TransformConversionComponent.prototype.isInheritUnit = function () {
        if (this.isInputConversion) {
            if (this.isPreviousUnit()) {
                return true;
            }
            else if (this.previousEntity instanceof Passport) {
                return true;
            }
            else if (this.isPreviousEntityTransformWithUnit()) {
                return true;
            }
        }
        return false;
    };
    Object.defineProperty(TransformConversionComponent.prototype, "_defaultUnit", {
        get: function () {
            return this.defaultUnit || PASSPORT_UNITS_TYPE.KG;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformConversionComponent.prototype, "_conversion", {
        get: function () {
            if (this.isInputConversion) {
                return this.transform.in_conversion;
            }
            else {
                return this.transform.out_conversion;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformConversionComponent.prototype, "_unitFunc", {
        get: function () {
            if (this.isInputConversion) {
                return Transform.getConversionInUnit;
            }
            else {
                return Transform.getConversionOutUnit;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformConversionComponent.prototype, "_previousUnit", {
        get: function () {
            var defaultEntityUnit = PASSPORT_UNITS_TYPE.KG;
            if (this.isInputConversion) {
                if (this.isPreviousUnit()) {
                    return this.previousUnit;
                }
                else if (this.previousEntity instanceof Passport) {
                    return this.previousEntity.unit || defaultEntityUnit;
                }
                else if (this.isPreviousEntityTransformWithUnit()) {
                    return this.previousEntity.output_unit || defaultEntityUnit;
                }
            }
            return this.defaultUnitsToConversions[this._defaultUnit];
        },
        enumerable: true,
        configurable: true
    });
    TransformConversionComponent.prototype.isPreviousUnit = function () {
        return this.previousUnit || this.previousUnit === 0;
    };
    TransformConversionComponent.prototype.isPreviousEntityTransformWithUnit = function () {
        return this.previousEntity instanceof Transform
            && !Transform.isWithoutUnitByType(this.previousEntity.transformation_type);
    };
    Object.defineProperty(TransformConversionComponent.prototype, "_previousConversion", {
        get: function () {
            if (this.isInputConversion) {
                if (this.previousConversion || this.previousConversion === 0) {
                    return this.previousConversion;
                }
                else if (this.previousEntity instanceof Passport) {
                    return this.previousEntity.conversion;
                }
                else if (this.isPreviousEntityTransformWithUnit()) {
                    return this.previousEntity.out_conversion;
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    TransformConversionComponent.prototype.emitQuantityValue = function (value) {
        return this.quantityChanged.emit(this.isInheritUnit() ? value : (Transform.inverseQuantityToCustomDefault(value, this.transform, this.defaultUnitsToConversions[this._defaultUnit])));
    };
    return TransformConversionComponent;
}());
export { TransformConversionComponent };
