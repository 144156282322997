/**
 * Created by aleksandr on 18.05.17.
 */
import { IPlumbStepCategory } from '../interfaces/plumb-step-category.interface';
import { DepositService } from '../../../entities/deposit/services/deposit.service';
import { TransformService } from '../../../entities/transformation/services/transform.service';
import { StakeholderService } from '../../../entities/stakeholder/services/stakeholder.service';
import { VirginProductService } from '../../../entities/virgin-product/services/virgin-product.service';

export const PLUMB_STEP_CATEGORIES: {
  DEPOSITS: IPlumbStepCategory,
  TRANSFORMS: IPlumbStepCategory,
  VIRGIN_PRODUCTS: IPlumbStepCategory,
  STAKEHOLDERS: IPlumbStepCategory,
} = {
  DEPOSITS: {
    provider: DepositService,
    view: 'Deposits'
  },
  TRANSFORMS: {
    provider: TransformService,
    view: 'Transformations'
  },
  VIRGIN_PRODUCTS: {
    provider: VirginProductService,
    view: 'Virgin products'
  },
  STAKEHOLDERS: {
    provider: StakeholderService,
    view: 'Stakeholders'
  },
};
