
<ul class="control-group__container-flex"
*ngFor="let item of listDescription?.controls; let i = index"
[formGroup]="listDescription.controls[i]"
>
    <!-- ICI remove -->
    <li class="control-group__item control-group__item--margin">
      <div
        [hidden]="this.isDisabled"
        class="control-group__item-button"
        (click)="removeAffiliateBranch(i)"
      >
        <md-icon>remove</md-icon>
      </div>
    </li>
    <!-- Fin remove -->
    <li
      class="control-group__item control-group__item--large control-group__item--margin"
    >
      <md-input-container>
        <input
          mdInput
          autocomplete="off"
          formControlName="description"
          placeholder="{{ 'Description' | translate }}"
        />
      </md-input-container>
    </li>
    <li class="control-group__item control-group__item--medium">
      <md-input-container>
        <input
          mdInput
          autocomplete="off"
          formControlName="stakeholder"
          placeholder="{{ 'Stakeholder' | translate }}"
        />
      </md-input-container>
    </li>
  </ul>
  <div class="control-group__container-flex " (click)="addNew()" >
    <div [hidden]="this.isDisabled" class="control-group__item-button">
      <md-icon>add</md-icon>
    </div>
    <div [hidden]="this.isDisabled" class="control-group__item--subtext">
      {{ "ajouter une nouvelle filière" | translate }}
    </div>
  </div>