export const IMPORT_ERROR = {
  1: 'IMPORT_ERROR_MISSING_TAB',
  2: 'IMPORT_ERROR_MISSING_PASSPORT',
  3: 'IMPORT_ERROR_MISSING_LOCATION',
  4: 'IMPORT_ERROR_MISSING_QUANTITY',
  5: 'IMPORT_ERROR_WRONG_DATE_FORMAT',
  6: 'IMPORT_ERROR_UNIT_NOT_RECOGNIZED',
  7: 'IMPORT_ERROR_COMPULSORY_FIELD_EMPTY',
  8: 'IMPORT_ERROR_UNIT_MISMATCHX',
  9: 'IMPORT_ERROR_COMMENT_TOO_LONG',
  10: 'IMPORT_ERROR_WRONG_DEMOUNTABILITY_FORMAT',
  11: 'IMPORT_ERROR_WRONG_STATE_FORMAT',
  12: 'IMPORT_ERROR_NAME_TOO_LONG',
  13: 'IMPORT_ERROR_DESCRIPTION_TOO_LONG',
  14: 'IMPORT_ERROR_UNKNOWN_ERROR',
  15: 'IMPORT_ERROR_WRONG_REUSED_FORMAT',
  16: 'IMPORT_ERROR_QUANTITY_LESS_THAN_BOOKED',
  17: 'IMPORT_ERROR_EXPIRE_DATE_BEFORE_AVAILABILITY',
  18: 'IMPORT_ERROR_TAG_NOT_FOUND',
  19 : 'IMPORT_ERROR_WRONG_RESALE_FORMAT_URL',
  20 : 'IMPORT_ERROR_WRONG_FORMAT_PRICE',
  21 : 'IMPORT_ERROR_WRONG_FORMAT_LENGTH',
  22 : 'IMPORT_ERROR_WRONG_FORMAT_WIDTH',
  23 : 'IMPORT_ERROR_WRONG_FORMAT_HEIGHT',
  24 : 'IMPORT_ERROR_WRONG_PHOTO_FORMAT_URL',
  25 : 'IMPORT_ERROR_WRONG_DOCUMENT_FORMAT_URL',
  26 : 'IMPORT_ERROR_WRONG_DEPOSIT_ORIGIN',
  27 : 'IMPORT_ERROR_WRONG_POTENTIAL_BECOMING',
  28 : 'IMPORT_ERROR_NEGATIVE_QUANTITY',
  29 : 'IMPORT_ERROR_CONVERSION_MISSING_VOID',
  30 : 'IMPORT_ERROR_WRONG_FORMAT_CONVERSION'
};

export const IMPORT_LOCATION_ERROR = {
   1: 'IMPORT_ERROR_MISSING_TAB',
   2: 'IMPORT_LOCATION_ERROR_FILE_IS_EMPTY',
   3: 'IMPORT_LOCATION_ERROR_MISSING_SITE',
   4: 'IMPORT_LOCATION_ERROR_SITE_NAME_USED',
   5: 'IMPORT_LOCATION_ERROR_SITE_MISSING_ADDRESS',
   6: 'IMPORT_LOCATION_ERROR_ADDRESS_NOT_GOOGLE',
   7: 'IMPORT_ERROR_UNKNOWN_ERROR',
   8: 'IMPORT_LOCATION_ERROR_ADDRESS_UNKNOWN'
};
