import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActiveRecord } from '../../../../shared/models/active-record.class';
import { Observable } from 'rxjs/Observable';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
var PassportMultipleFieldInputComponent = /** @class */ (function () {
    function PassportMultipleFieldInputComponent(notificationService) {
        this.notificationService = notificationService;
        this.disabled = false;
        this.placeholder = '';
        this.recordNameField = 'name';
        this.recordNameFunc = this.getFieldNameDefault;
        this.recordDelete = this.deleteRecordDefault;
        this.addRecordObservable = this.addRecordDefault;
        this.showFirstRecordAsText = this.disabled;
        this.errorMessage = '';
        this.onValidationErrorFunction = this.errorHandleDefault;
        this.isOnAddErrorErrorMessageShown = false;
        this.onAddingErrorFunction = this.emptyFunction;
        this.onDeleteSuccess = new EventEmitter();
        this.isLoading = false;
        this.deletionSet = new Set();
    }
    Object.defineProperty(PassportMultipleFieldInputComponent.prototype, "setDisabled", {
        set: function (state) {
            this.disabled = state;
            if (this.control) {
                state ? this.control.disable() : this.control.enable();
            }
        },
        enumerable: true,
        configurable: true
    });
    PassportMultipleFieldInputComponent.prototype.ngOnInit = function () {
        if (!this.control) {
            this.control = new FormControl({ value: '', disabled: this.disabled });
        }
        if (!this.inputSize && this.inputSize !== 0) {
            this.inputSize = this.disabled ? 1 : 6;
        }
        if (this.isOnAddErrorErrorMessageShown) {
            this.onAddingErrorFunction = this.errorHandleDefault;
        }
    };
    PassportMultipleFieldInputComponent.prototype.ngOnDestroy = function () { };
    PassportMultipleFieldInputComponent.prototype.getFieldNameDefault = function (record) {
        if (this.isObject(record)) {
            return record[this.recordNameField];
        }
        else {
            return record;
        }
    };
    PassportMultipleFieldInputComponent.prototype.isObject = function (record) {
        return typeof record === 'object' && !!record;
    };
    PassportMultipleFieldInputComponent.prototype.deleteRecordDefault = function (model) {
        var _this = this;
        if (model instanceof ActiveRecord && model.id) {
            this.deletionSet.add(model.id);
            model.destroy().subscribe(function () {
                _this.records = _this.records.filter(function (item) { return !_this.isObject(item) || item.id !== model.id; });
                _this.deletionSet.delete(model.id);
                _this.onDeleteSuccess.emit(model);
            }, function () { return _this.deletionSet.delete(model.id); });
        }
        else {
            this.records = this.records.filter(function (item) { return item !== model; });
            this.onDeleteSuccess.emit(model);
        }
    };
    PassportMultipleFieldInputComponent.prototype.addRecordDefault = function (name) {
        return Observable.of(name);
    };
    PassportMultipleFieldInputComponent.prototype.changeRecord = function () {
        var value = this.control.value;
        if (!value) {
            return;
        }
        else if (!value.trim()) {
            this.resetInput();
        }
        else if (this.control.valid) {
            this.addRecordToList(value);
        }
        else {
            this.onValidationErrorFunction(this.control.errors);
        }
    };
    PassportMultipleFieldInputComponent.prototype.addRecordToList = function (name) {
        var _this = this;
        this.isLoading = true;
        this.addRecordObservable(name).subscribe(function (data) {
            _this.records.push(data);
            _this.resetInput();
            _this.isLoading = false;
        }, function (e) {
            _this.onAddingErrorFunction(e);
            _this.isLoading = false;
        });
    };
    PassportMultipleFieldInputComponent.prototype.errorHandleDefault = function (e) {
        this.notificationService.error(this.errorMessage);
    };
    PassportMultipleFieldInputComponent.prototype.resetInput = function () {
        this.control.setValue('', { emitEvent: false });
    };
    PassportMultipleFieldInputComponent.prototype.showValuesAsChip = function () {
        return !this.showFirstRecordAsText || this.records.length > 1;
    };
    PassportMultipleFieldInputComponent.prototype.focusInput = function () {
        if (this.input) {
            this.input.nativeElement.focus();
        }
    };
    PassportMultipleFieldInputComponent.prototype.preventOnEnterEventBubble = function (event) {
        if (event.code === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            if (this.input) {
                this.changeRecord();
            }
        }
    };
    PassportMultipleFieldInputComponent.prototype.emptyFunction = function () { };
    return PassportMultipleFieldInputComponent;
}());
export { PassportMultipleFieldInputComponent };
