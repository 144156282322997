/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/read-only/form-elems-hidden.directive";
import * as i2 from "../../../../../shared/read-only/read-only.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./passport-reset-button.component";
var styles_PassportResetButtonComponent = [];
var RenderType_PassportResetButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PassportResetButtonComponent, data: {} });
export { RenderType_PassportResetButtonComponent as RenderType_PassportResetButtonComponent };
export function View_PassportResetButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "passportlist__clear-filters"]], [[8, "disabled", 0], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearFilters() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.FormElemsHiddenDirective, [[2, i2.ReadOnlyService]], null, null), (_l()(), i0.ɵted(2, null, [" ", "\n"])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.canClearFilters() || _co.disabled); var currVal_1 = ((i0.ɵnov(_v, 1).readOnlyService == null) ? null : i0.ɵnov(_v, 1).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.buttonText)); _ck(_v, 2, 0, currVal_2); }); }
export function View_PassportResetButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "passport-reset-button", [], null, null, null, View_PassportResetButtonComponent_0, RenderType_PassportResetButtonComponent)), i0.ɵdid(1, 49152, null, 0, i4.PassportResetButtonComponent, [], null, null)], null, null); }
var PassportResetButtonComponentNgFactory = i0.ɵccf("passport-reset-button", i4.PassportResetButtonComponent, View_PassportResetButtonComponent_Host_0, { disabled: "disabled", buttonText: "buttonText", alternativeRules: "alternativeRules", resetFilterState: "resetFilterState", passportFilter: "passportFilter", controlAsFilter: "controlAsFilter" }, { onResetClick: "onResetClick" }, []);
export { PassportResetButtonComponentNgFactory as PassportResetButtonComponentNgFactory };
