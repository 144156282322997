/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/common-navbar/common-navbar.component.ngfactory";
import * as i2 from "../shared/common-navbar/common-navbar.component";
import * as i3 from "../shared/services/navigation.service";
import * as i4 from "../shared/services/permission.service";
import * as i5 from "../shared/services/current-user.service";
import * as i6 from "../shared/services/deepstream.service";
import * as i7 from "@angular/router";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../shared/datetime-translation/datetime-translation";
import * as i10 from "../shared/services/user.service";
import * as i11 from "../shared/services/auth.service";
import * as i12 from "../entities/client/service/language.service";
import * as i13 from "@angular/material";
import * as i14 from "../shared/services/filterState.service";
import * as i15 from "../shared/services/navbar.service";
import * as i16 from "../shared/services/global-events-manager.service";
import * as i17 from "../shared/ie-notification/ie-notification.component.ngfactory";
import * as i18 from "../shared/ie-notification/ie-notification.component";
import * as i19 from "@angular/common";
import * as i20 from "../shared/common-sidebar/common-sidebar.component.ngfactory";
import * as i21 from "../shared/common-sidebar/common-sidebar.component";
import * as i22 from "../shared/chat/chat.service";
import * as i23 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "need-navbar", [], null, null, null, i1.View_NeedNavbarComponent_0, i1.RenderType_NeedNavbarComponent)), i0.ɵdid(1, 245760, null, 0, i2.NeedNavbarComponent, [i3.NavigationService, i4.PermissionService, i5.CurrentUserService, i6.DeepStreamService, i0.NgZone, i7.Router, i8.TranslateService, i9.DateTimePickerTranslationService, i10.UserService, i11.AuthService, i12.LanguageService, i13.MdDialog, i14.FilterStateService, i15.NavbarStateService, i16.GlobalEventsManager], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ie-notification", [], null, null, null, i17.View_IeNotificationComponent_0, i17.RenderType_IeNotificationComponent)), i0.ɵdid(1, 114688, null, 0, i18.IeNotificationComponent, [i8.TranslateService, i3.NavigationService], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(3, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "common-sidebar", [], null, null, null, i20.View_CommonSidebarComponent_0, i20.RenderType_CommonSidebarComponent)), i0.ɵprd(131584, null, i8.TranslatePipe, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(6, 245760, null, 0, i21.CommonSidebarComponent, [i22.ChatService, i3.NavigationService, i16.GlobalEventsManager, i8.TranslatePipe], { show: [0, "show"] }, null), (_l()(), i0.ɵeld(7, 16777216, null, 0, 1, "router-outlet", [["class", "sd-app-router-outlet"]], null, null, null, null, null)), i0.ɵdid(8, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.navigation.showNavBar; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.navigation.showNavBar; _ck(_v, 6, 0, currVal_1); _ck(_v, 8, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i23.AppComponent, [i3.NavigationService, i4.PermissionService, i5.CurrentUserService, i7.Router, i7.ActivatedRoute, i0.ElementRef, i13.MdDialog, i8.TranslateService, i9.DateTimePickerTranslationService, i12.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i23.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
