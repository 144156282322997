import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { MdDialog } from '@angular/material';
import { ZoneFormDialogComponent } from '../../dialogs/zone-form-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { Zone } from '../../../../shared/models/zone.class';
import { Router } from '@angular/router';
import { ZoneService } from '../../../../shared/services/zone.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { LandfillVariables } from '../landfill-variables/landfill-variables.model';
import { LANDFILL_MAP } from '../../../../shared/values/landfill.map';
import { ZONE_STATUS } from '../../../../shared/values/zone-status.const';
import { DeepStreamService } from '../../../../shared/services/deepstream.service';
import { IZoneCopyingDataContainer, IZoneDeletionData, IZoneDeletionDataContainer } from '../../../../shared/interfaces/zone-deepstream-message.interface';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { PassportTagService } from '../../../../entities/passport/services/passport-tag.service';
import { SynonymService } from '../../../../shared/services/synonym.service';
import { PassportTag } from '../../../../entities/passport/models/passport-tag.class';
import { Synonym } from '../../../../shared/models/synonym.class';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
@Component({
  moduleId: module.id,
  selector: 'superadmin-zones',
  styleUrls: ['zones.component.scss'],
  templateUrl: 'zones.component.html'
})
export class ZonesComponent implements OnInit {

  zones: Zone[];

  ZONE_STATUS = ZONE_STATUS;

  private zoneDeletionSubscription: Subscription;
  private zoneCopyingSubscription: Subscription;
  tags: PassportTag[];
  searchControl = new FormControl();

  search: string;
  oldQuery: any;

  constructor(
    private mdDialog: MdDialog,
    private _zone: NgZone,
    private _router: Router,
    private zoneService: ZoneService,
    private passportTagService: PassportTagService,
    private synonymService: SynonymService,
    private _translate: TranslateService,
    private _ds: DeepStreamService,
    private _notificationService: LogNotificationService
  ) {
    this.searchControl
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(value => this.search = value);
  }


  ngDoCheck() {
    
    let query = {
      expand: 'synonyms',
      show_hidden: true,
      search: this.search || null,
      
    };

    if (!_.isEqual(this.oldQuery, query)) {
      this.passportTagService.get(query).subscribe((res: PassportTag[]) => {
        this.tags = res;
      });
      this.oldQuery = _.cloneDeep(query);
    }
  }
  ngOnInit() {
    this.zones = [];
    this.getZones();
    this.getTagsAndSynonyms();
  }

  ngOnDestroy() {
    this.destroyZoneCopyingSubscription();
    this.destroyZoneDeletionSubscription();
  }

  getZones() {
    this.zoneService.get(
      {expand: 'name,status,passport_count', nopaginate: 1}
    ).subscribe((zones: Zone[]) => this.zones = zones);
  }

  getTagsAndSynonyms() {
    this.passportTagService.get({expand: 'synonyms', show_hidden: true, search: this.search || null})
    .subscribe((result: PassportTag[]) => {
      this.tags = result;
    });
  }

  mapLandFields(landfillVariables: LandfillVariables[]) {

    const landfill_cost_variables = {
      inert_soils: null,
      gravats: null,
      ordinary_industrial_waste: null,
      demolition_wood: null,
      demolition_wood_max: null,
      clean_plaster: null,
      paper_and_cardboard: null,
      plants: null
    };

    landfillVariables.map((landfill: LandfillVariables) => {
      landfill_cost_variables[LANDFILL_MAP[landfill.id]] = landfill.value;
    });

    return landfill_cost_variables;
  }

  newZone() {
    this._zone.run(() => {
      this.mdDialog.open(ZoneFormDialogComponent, {
        ...DIALOG_THEME.ALERT_PRIMARY,
        data: {
          zone: new Zone({landfill_cost_variables: {}})
        }
      }).afterClosed().subscribe(() => {
        this.getZones();
      });
    });
  }

  editZone(zone: Zone) {
    this._zone.run(() => {
      this.zoneService.getLandfillVariables(zone.id, {expand: 'name,value'}).subscribe((landfill: LandfillVariables[]) => {
        zone.landfill_cost_variables = this.mapLandFields(landfill);
        this.mdDialog.open(ZoneFormDialogComponent, {
          ...DIALOG_THEME.ALERT_PRIMARY,
          data: { zone: zone }
        }).afterClosed().subscribe(() => {
          this.getZones();
        });
      });
    });
  }

  goToZone(zone: Zone) {
   this._zone.run(() => (
     this._router.navigate([`superadmin/zones/${zone.id}/generic-passports/`])
   ));
  }

  copyZone(zone: Zone) {
    this.zoneService.duplicate(zone.id)
      .subscribe(() => (
        this.subscribeOnZoneCopyingStream()
      ));
  }

  removeZone(zone: Zone) {
    this._translate.get('CONFIRM_DELETE_ZONE')
      .flatMap((translation) => {
        let dialogRef = this.mdDialog.open(ConfirmDialogComponent, {
          data: {
            text: translation
          }
        });

        return dialogRef.afterClosed();
      })
      .flatMap((confirmed) => {
        return confirmed
          ? zone.destroy().map(() => true)
          : Observable.of(false);
      })
      .subscribe((deleted) => {
        if (deleted) {
          this.subscribeOnZoneDeletionStream();
        }
      });
  }

  destroyZoneDeletionSubscription() {
    if (!!this.zoneDeletionSubscription) {
      this.zoneDeletionSubscription.unsubscribe();
      this.zoneDeletionSubscription = null;
    }
  }

  destroyZoneCopyingSubscription() {
    if (!!this.zoneCopyingSubscription) {
      this.zoneCopyingSubscription.unsubscribe();
      this.zoneCopyingSubscription = null;
    }
  }

  showNotification(isDone: boolean, message: string): void {
    return this._notificationService[isDone ? 'success' : 'error'](message)
  }

  subscribeOnZoneDeletionStream(): void {
    this.destroyZoneDeletionSubscription();

    this.zoneDeletionSubscription = this._ds.zoneDeepStream$.deletionStream$
      .subscribe(
        (container: IZoneDeletionDataContainer) => {
          this.showNotification(container.data.done, this._translate.instant(container.data.message))
          return this._zone.run(() => this.getZones());
        },
        () => {},
      )
  }

  subscribeOnZoneCopyingStream(): void {
    this.destroyZoneCopyingSubscription();

    this.zoneCopyingSubscription = this._ds.zoneDeepStream$.copyingStream$
      .subscribe(
        (container: IZoneCopyingDataContainer) => {
          this.showNotification(container.data.done, this._translate.instant(container.data.message))
          this._zone.run(() => this.getZones())
        },
        () => { },
      )
  }

  onEnter(tagName: string, tag_id: number) {
    this.synonymService.create(new Synonym({name: tagName, tag_id: tag_id})).subscribe(() => {
      this.getTagsAndSynonyms();
    });
  }

  removeSynonym(id: number) {
    this.synonymService.remove(id).subscribe(() => {
      this.getTagsAndSynonyms();
    });
  }
  
} 
