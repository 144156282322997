import * as tslib_1 from "tslib";
import { DepositImportService } from '../services/deposit-import.service';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
var DepositImport = /** @class */ (function (_super) {
    tslib_1.__extends(DepositImport, _super);
    function DepositImport(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = DepositImportService;
        return _this;
    }
    DepositImport.prototype.fields = function () {
        return [
            'id',
            'tab_name',
            'first_row',
            'last_row',
            'passport_id_field',
            'site_name_field',
            'tags_field',
            'quantity_field',
            'unit_field',
            'availability_date_field',
            'availability_expiration_field',
            'reference_date',
            'state_field',
            'state_description_field',
            'demountability_field',
            'comments_field',
            'reused_field',
            'errors',
            'deposit_name_field',
            'resale_url_field',
            'resale_price_field',
            'product_characteristics_field',
            'length_field',
            'width_field',
            'height_field',
            'apperance_field',
            'photo_field',
            'documents_field',
            'deposit_origin_field',
            'conversion_field',
            'potential_becoming_field',
            'status'
        ];
    };
    DepositImport.prototype.startImport = function () {
        return this.simplePost('/' + this.id + '/start');
    };
    return DepositImport;
}(ExtendedActiveRecord));
export { DepositImport };
