import { Component, DoCheck, OnInit, NgZone } from '@angular/core';
import { EcosystemService } from '../../../../../entities/ecosystem/services/ecosystem.service';
import { Ecosystem } from '../../../models/ecosystem.class';
import { ECOSYSTEM_STATUS, ECOSYSTEM_STATUS_FILTER_VIEW } from '../../../values/ecosystem-status.const';
import { Searchable } from '../../../../../shared/models/searchable.class';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { FilterStateService } from '../../../../../shared/services/filterState.service';
import { Subscription } from 'rxjs';
import { EcosystemFolderService } from '../../../../../entities/ecosystem/services/ecosystem-folder.service';
import { TranslateService } from '@ngx-translate/core';
import {Need} from '../../../../../entities/need/models/need.class';
import {NeedService} from '../../../../../entities/need/services/need.service';
import { MdDialog } from '@angular/material';
import {EsmNeedPickerDialogComponent} from '../../../dialogs/esm-need-picker-dialog/esm-need-picker-dialog.component';
import { Router } from '@angular/router';
import { CreateFolderModalComponent } from './dialogs/create-folder/create-folder-modal.component';
import { MoveFolderModalComponent } from './dialogs/move-folder/move-folder-modal.component';
import { DeleteFolderModalComponent } from './dialogs/delete-folder/delete-folder-modal.component';
@Component({
  moduleId: module.id,
  selector: 'ecosystem-ecosystems-navigator',
  templateUrl: 'ecosystem-ecosystems-navigator.component.html'
})
export class EcosystemEcosystemsNavigatorComponent implements OnInit {

  ecosystems: Ecosystem[] = [];
  clients: Searchable[] = [];
  ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
  searchSubject = new Subject<Event>();
  folderMode : boolean = false;
  public isSameAsDefault = this._isSameAsDefault.bind(this);
  folderFilter: any;
  ECOSYSTEM_STATUS_FILTER_VIEW = ECOSYSTEM_STATUS_FILTER_VIEW;
  filterStatuses: {value: ECOSYSTEM_STATUS, selected: boolean, view: string}[] = [];
  sortOptions: any = [
    { field: 'name', view: 'Name'},
    { field: 'need_name', view: 'Need name'},
    { field: 'sales_name', view: 'Need manager'},
    { field: 'delivery_date', view: 'Delivery date'},
    { field: 'client_name', view: 'Client'},
    { field: 'last_update', view: 'Last modification'}
  ];
  sortValue: string = 'last_update';

  search: string;
  deliveryDateFrom: string;
  deliveryDateTo: string;
  clientId: number;
  oldQuery: any;
  private sub: Subscription;

  constructor(private _ecosystemService: EcosystemService,
    public ecosystemFolderService: EcosystemFolderService,
    private _zone: NgZone,
    private _dialog: MdDialog,
    private translateService: TranslateService,
    private _needService: NeedService,
    private navBarState: NavbarStateService,
    private mdDialog: MdDialog,
    private filterStateService: FilterStateService,
    private _router: Router) {
    this.searchSubject
      .debounceTime(400)
      .subscribe(($event) => this.onFilterChange() )
    ;
  }

  ngOnInit(): void {

    // if(!this.filterStateService.filterList){
    //   this.filterStateService.filterList = {statusDone: false};
    // }
    // this.filterStatuses.find(status => status.value === ECOSYSTEM_STATUS.DONE).selected = this.filterStateService.filterList.statusDone;
    this._ecosystemService.getClientList({}).subscribe((data) => {
      this.clients = data;
    });

    // this.ecosystemFolderService.getFolders();
  }
  

  // ngDoCheck() {
  //   let statuses: number[] = this.filterStatuses.filter(item => {
  //     if (item.value === ECOSYSTEM_STATUS.DONE){
  //       this.filterStateService.filterList.statusDone = item.selected;
  //     }
  //     return item.selected;
  //    }).map(item => item.value);
  //   if(!!statuses.find(value => value === ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION)) {
  //     statuses.push(ECOSYSTEM_STATUS.WAITING_FOR_QUOTE, ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION);
  //   }

  //   let query: any = {
  //     expand: 'need,steps,steps_complete,steps_total,client_name,publication_date,need_id',
  //     admin: 1,
  //     esm_view: 1,
  //     delivery_from: this.deliveryDateFrom || null,
  //     delivery_to: this.deliveryDateTo || null,
  //     client: this.clientId || null,
  //     search: this.search || null,
  //     'status[]': statuses,
  //     sort: this.sortValue,
  //   };
  //   if (this.navBarState.multilanguage) {
  //     query.english = true;
  //   }

  //   if (!_.isEqual(this.oldQuery, query)) {
  //     if (this.sub) {
  //       this.sub.unsubscribe();
  //     }  
  //      this.sub = this
  //       ._ecosystemService
  //       .get(query)
  //       .subscribe((res: Ecosystem[]) => {
  //         this.ecosystems = res;
  //       })
  //     ;
  //     this.oldQuery = _.cloneDeep(query);
  //   }
  // }

  newFolder() {
      this.mdDialog.open(CreateFolderModalComponent,
        {
          data: {
            isUpdate: false,
            folderName: ""
          }
        }
        )
  }
  newEcosystem() {

  }

  deleteSelection() {
    this.mdDialog.open(DeleteFolderModalComponent, {
      data: {
        nbSelection: this.ecosystemFolderService.selection.length,
      }
    }).afterClosed().subscribe();
  }

  moveSelection() {
    this.mdDialog.open(MoveFolderModalComponent, 
      {
        data: {
          nbSelection: this.ecosystemFolderService.selection.length,
        }
      }
    ).afterClosed().subscribe();
  }

  toggleIndependent(e) {
    this.ecosystemFolderService.toggleFolderMode(e);
  }

  isRootFolder() {
    return this.ecosystemFolderService.isRoot;
  }

  goToParent() {
    this.ecosystemFolderService.leaveFolder();
  }

  onStatusSelectChange(e) {
    this.ecosystemFolderService.toggleStatus(e);
    this.onFilterChange();
  }

  clearFilters() {
    this.ecosystemFolderService.resetFilter();
    
  }

  canClearFilters() {
    return !this.ecosystemFolderService.isDefaultFilter();
  }

  isMovable() {
    return this.ecosystemFolderService.selection.length > 0;
  }

  isDeletable() {
    return this.ecosystemFolderService.selection.length > 0 || this.ecosystemFolderService.folderSelection.length > 0;
  }

  saveFolder() {
   

    this.mdDialog.open(CreateFolderModalComponent,{
      data: {
        isUpdate: true,
        folderName: this.ecosystemFolderService.currentFolder.name
      }
    })
  }

  openEcosystemPicker() {
    this._zone.run(() => {
      this._needService
        .get({
          expand: 'client,passport,client_contact',
          supervisor: true,
          esm_view: true,
        }).subscribe((records: Need[]) => {
          const dialogRef = this._dialog.open( EsmNeedPickerDialogComponent, {
            data: {
              records: records
            }
          });
          dialogRef.afterClosed().subscribe((result: Need) => {
            if (result) {
              this.createEcosystem(result);
            }
          });
        });
    });
  }

  private createEcosystem(record: Need) {
    new Ecosystem({
      name: `${this.translateService.instant('For')} ${record.name}`,
      need_id: record.id,
    }).save()
      .subscribe((ecosystem: Ecosystem) => {
          this.ecosystemFolderService.moveSingleEcosystem(ecosystem.id, this.ecosystemFolderService.currentFolder.id).subscribe(() => {
            this._router.navigate(['ecosystem/ecosystems', ecosystem.id])
          })
        });
  }

  _isSameAsDefault() {
    return this.ecosystemFolderService.filter.statuses.reduce((acc, curr) => {
      return acc && curr.selected === this.ecosystemFolderService.defaultStatuses[curr.value];
    }, true)
  }

  onFilterChange() {
    this.ecosystemFolderService.resetSelection();
    this.ecosystemFolderService.search();
   
  }

  onSelectAllChange() {
    this.ecosystemFolderService.toggleAll();
  }

  canDisplayFolderFilter() {
    return this.ecosystemFolderService.isRoot && !this.ecosystemFolderService.folderMode;
  }
}
