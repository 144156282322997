<agm-circle
  [latitude]="depositLocation.lat"
  [longitude]="depositLocation.lng"
  [radius]="radius"
  [fillColor]="fillColor"
  [fillOpacity]="fillOpacity"
  (mouseOver)="mouseOver()"
  (mouseOut)="mouseOut()"
  (circleClick)="select.emit()"
>
  <agm-info-window
    [isOpen]="isHovered"
    [latitude]="depositLocation.lat"
    [longitude]="depositLocation.lng"
  >
    {{ 'Name' | translate }}: {{depositLocation.name}}<br>
    {{ 'Address' | translate }}: {{depositLocation.address}}<br>
    {{ 'Description' | translate }}: {{depositLocation.description}}
  </agm-info-window>
</agm-circle>
