import * as tslib_1 from "tslib";
import { CrudService } from './crud.service';
import { Observable } from 'rxjs/Observable';
var ExtendedCrudService = /** @class */ (function (_super) {
    tslib_1.__extends(ExtendedCrudService, _super);
    function ExtendedCrudService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExtendedCrudService.prototype.getWithPaginationData = function (query) {
        return this
            .sendGet(this.getEndpoint(this.namespace), { search: query })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.getFileUploadUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/files');
    };
    ExtendedCrudService.prototype.getLogoUploadUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/logo');
    };
    ExtendedCrudService.prototype.getPublishUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/publish');
    };
    ExtendedCrudService.prototype.getUnpublishUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/unpublish');
    };
    ExtendedCrudService.prototype.getAskForModificationUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/formod');
    };
    ExtendedCrudService.prototype.getAskForRatingUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/forrating');
    };
    ExtendedCrudService.prototype.getRejectUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/reject');
    };
    ExtendedCrudService.prototype.getValidateUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/validate');
    };
    ExtendedCrudService.prototype.getValidateAllUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/validate_all');
    };
    ExtendedCrudService.prototype.getUnvalidateUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/unpublish');
    };
    ExtendedCrudService.prototype.getConfirmUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/confirm');
    };
    ExtendedCrudService.prototype.getRefuseUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/refuse');
    };
    ExtendedCrudService.prototype.publish = function (id, data) {
        data.context = this.navigation.getContext().roleId;
        return this
            .sendPost(this.getPublishUrl(id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.unpublish = function (id, data) {
        return this
            .sendPost(this.getUnpublishUrl(id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.askForModification = function (id, data) {
        return this
            .sendPost(this.getAskForModificationUrl(id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.askForRating = function (id, data) {
        return this
            .sendPost(this.getAskForRatingUrl(id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.reject = function (id, data) {
        return this
            .sendPost(this.getRejectUrl(id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.validate = function (id, data) {
        data.context = this.navigation.getContext().roleId;
        return this
            .sendPost(this.getValidateUrl(id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.validateAll = function (id, data) {
        return this
            .sendPost(this.getValidateAllUrl(id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.unvalidate = function (id, data) {
        return this
            .sendPost(this.getUnvalidateUrl(id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.confirm = function (id, data) {
        data.context = this.navigation.getContext().roleId;
        return this
            .sendPost(this.getConfirmUrl(id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.refuse = function (id) {
        return this
            .sendPost(this.getRefuseUrl(id))
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.canDelete = function (id) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + '/' + id + '/candelete'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.canUpdate = function (id) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + '/' + id + '/canupdate'))
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.get = function (query) {
        if (query && query['status[]'] && query['status[]'].length === 0) { // applying 0 status filters is equivalent to requesting nothing
            return Observable.of([]);
        }
        return _super.prototype.get.call(this, query);
    };
    ExtendedCrudService.prototype.getModificationMessage = function (id) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + "/" + id + "/modification"))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    ExtendedCrudService.prototype.askModification = function (id, data) {
        return this.sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/modification'), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    return ExtendedCrudService;
}(CrudService));
export { ExtendedCrudService };
