import {Component, DoCheck, HostBinding, OnDestroy} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Response } from '@angular/http';
import * as _ from 'lodash';
import { USER_ROLES_FOR_ADMIN } from '../../../../shared/values/role-ids.enum';
import { UserService } from '../../../../shared/services/user.service';
import { User } from '../../../../shared/models/user.class';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import {PaginationUnmarshallingService} from '../../../../shared/services/pagination-unmarshalling.service';
import {Subject} from 'rxjs';

@Component({
  moduleId: module.id,
  templateUrl: 'admin-users.component.html',
  styleUrls: ['admin-users.component.scss'],
})
export class AdminUsersComponent implements DoCheck, OnDestroy {
  @HostBinding('class.material-reset') readonly hostClass = true;
  USER_ROLES = USER_ROLES_FOR_ADMIN;
  users: User[];
  searchControl = new FormControl();
  pageControl = new FormControl();

  search: string;
  role: number;
  sort = 'name';

  oldQuery: any;
  pageInfo = {
    totalCount: 1,
    perPage: 20,
    pageCount: 1,
    currentPage: 1
  };
  private destroy$ = new Subject();

  constructor(public _currentUserService: CurrentUserService,
              private userService: UserService,
              private paginationMarshal: PaginationUnmarshallingService,
              private _globalEvents: GlobalEventsManager) {
    this.searchControl
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .takeUntil(this.destroy$)
      .subscribe(value => {
        this.search = value;
      });
    this.pageControl.valueChanges.distinctUntilChanged().takeUntil(this.destroy$)
      .subscribe(value => this.pageChange(value));
  }

  ngDoCheck() {
    const query = this.buildQuery();
    if (!_.isEqual(this.oldQuery, query)) {
      this.loadUsers(query);
      this.oldQuery = _.cloneDeep(query);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private buildQuery(page = 1) {
    return {
      expand: 'roles_with_parent',
      show_hidden: true,
      search: this.search || null,
      role: this.role || null,
      page: page,
      sort: this.sort
    };
  }

  private loadUsers(query) {
    this.userService.getResponse(query).subscribe((res: Response) => {
      this.pageInfo = this.paginationMarshal.unmarshall(res);
      this.users = res.json().map(item => new User(item));
    });
  }

  getAvatar(user: User) {
      return user.avatar_url ? {'background-image': 'url(' + user.avatar_url + ')'} : null;
  }

  openChat(targetId: number, event: Event ) {
    event.stopPropagation();
    this._globalEvents.chatUserClicked$.emit(targetId);
  }

  pageChange($event: number) {
    this.loadUsers(this.buildQuery($event));
  }
}
