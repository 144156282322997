import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnDestroy, OnInit, QueryList } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ADMIN_ESM_OPTIONS, ERoleIds, USER_ROLES, USER_ROLES_FOR_SPECIAL_ADMIN } from '../../../../shared/values/role-ids.enum';
import { Observable } from 'rxjs/Observable';
import { User } from '../../../../shared/models/user.class';
import { UserService } from '../../../../shared/services/user.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { AvatarUploaderComponent } from '../../../../shared/uploaders/avatar-uploader/avatar-uploader.component';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import { MdDialog } from '@angular/material';
import { UserDeletionDialogComponent } from '../../dialogs/user-deletion-dialog.component';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { USER_STATUS } from '../../../../shared/values/user-status.const';
import { LanguageService } from '../../../../entities/client/service/language.service';
import { DepositLocation } from '../../../../entities/deposit/models/deposit-location.class';
import { DepositLocationService } from '../../../../entities/deposit/services/deposit-location.service';
import { ESelectionListOpenMode } from '../../../../shared/custom-selection-list-with-chip/values/selection-list-open-mode.enum';
import { CustomSelectionListWithChipRecordModel } from '../../../../shared/custom-selection-list-with-chip/custom-selection-list-with-chip-record.model';
import { DepositLocationSalesJunctionService } from '../../../../entities/deposit/services/deposit-location-sales-junction.service';
var UserDetailsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserDetailsComponent, _super);
    function UserDetailsComponent(_fb, _userService, _navigationService, _route, _router, _currentUserService, _translate, _dialog, _languageService, notificationService, _depositLocation, _depositLocationSalesService) {
        var _this = _super.call(this, _route, _router) || this;
        _this._fb = _fb;
        _this._userService = _userService;
        _this._navigationService = _navigationService;
        _this._route = _route;
        _this._router = _router;
        _this._currentUserService = _currentUserService;
        _this._translate = _translate;
        _this._dialog = _dialog;
        _this._languageService = _languageService;
        _this.notificationService = notificationService;
        _this._depositLocation = _depositLocation;
        _this._depositLocationSalesService = _depositLocationSalesService;
        _this.onSaveAction = new EventEmitter();
        // Note: names must be same as in UserRole interface
        _this.ROLE_FORM_NAME = 'roleId';
        _this.ROLE_PARENT_FORM_NAME = 'parentId';
        _this.ROLES_FORM_NAME = 'roles';
        _this.LIST_OPEN_MODE = ESelectionListOpenMode;
        _this.ROLE_IDS = ERoleIds;
        _this.subscriptions = [];
        _this.supervisors = [];
        _this.depositOwners = [];
        _this.allLocationsRecords = [];
        _this.salesSitesRecords = [];
        _this.moduleNames = ModuleNames;
        _this.user = new User;
        _this.USER_ROLES = [];
        _this.maxRoleHeightPx = 38;
        _this.delimiterClassName = 'custom-selection-list--delimiter';
        _this.SITE_LIST_COMMAND_ALL = 'all';
        _this.siteSalesJunctionBefore = {};
        _this.saleSiteIds = new Set();
        _this._maxUsernameFieldLength = 64;
        _this._maxPasswordFieldLength = 32;
        _this._maxStringFieldLength = 30;
        _this._minNameFieldLength = 3;
        _this._minPasswordLength = 6;
        _this.isAllsites = false;
        _this.isSiteListItemCheck = _this.isSiteListItemCheck.bind(_this);
        _this.checkAllLocations = _this.checkAllLocations.bind(_this);
        return _this;
    }
    Object.defineProperty(UserDetailsComponent.prototype, "isInEditMode", {
        get: function () {
            return (this.user && this.user.id > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsComponent.prototype, "selectedRoles", {
        get: function () {
            return this.userForm.value[this.ROLES_FORM_NAME] || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsComponent.prototype, "selectedRolesForms", {
        get: function () {
            return this.userForm.get(this.ROLES_FORM_NAME);
        },
        enumerable: true,
        configurable: true
    });
    UserDetailsComponent.roleIsEnableForEditing = function (roleId) {
        return ![ERoleIds.ADMIN, ERoleIds.SUPERADMIN].includes(roleId);
    };
    UserDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this._currentUserService.info().subscribe(function (resp) {
            if (resp.json()) {
                _this.admin_able_create_esm = resp.json().plan_id;
            }
            _this.adjustUserRoles();
        });
        _super.prototype.ngOnInit.call(this);
        this.subscriptions.push(this._route.data.subscribe(function (data) {
            var userLists = data['userLists'];
            if (!!userLists) {
                _this.supervisors = userLists.supervisors.map(function (item) { return _this.setUsername(userLists, item); });
                _this.depositOwners = userLists.depositOwners.map(function (item) { return _this.setUsername(userLists, item); });
            }
            else {
                _this.supervisors = [];
                _this.depositOwners = [];
            }
        }));
        this._userService.getCompanyList().subscribe(function (companies) {
            var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
            _this.allCompanies = companies.sort(collator.compare);
            _this.onCompanyChange(_this.user.company_name);
        });
        this.parentModule = this._navigationService.getModuleName();
        if (this.parentModule !== ModuleNames.SPECIAL_ADMIN) {
            this.USER_ROLES = USER_ROLES;
            this._depositLocation.get({ supervisor: 1, assigned: 1 }).subscribe(function (sites) {
                _this.allLocationsRecords = _this.initAllLocationRecords(sites);
                _this.loadChosenLocationRecords();
            });
        }
        else {
            (_a = this.USER_ROLES).push.apply(_a, USER_ROLES_FOR_SPECIAL_ADMIN);
        }
    };
    UserDetailsComponent.prototype.setUsername = function (userLists, item) {
        if (userLists.hasOwnProperty('entity') && userLists['entity']['id'] === item.id) {
            item.name = userLists['entity']['username'];
        }
        return item;
    };
    UserDetailsComponent.prototype.adjustUserRoles = function () {
        if (this.admin_able_create_esm === ADMIN_ESM_OPTIONS.M0) {
            this.USER_ROLES = USER_ROLES.filter(function (obj) { return obj.value !== ERoleIds.ECOSYSTEM_MANAGER; });
        }
        if (this.admin_able_create_esm === ADMIN_ESM_OPTIONS.M1) {
            this.USER_ROLES = USER_ROLES.filter(function (obj) { return obj.value !== ERoleIds.ECOSYSTEM_MANAGER; });
        }
        // case M2 do nothing
    };
    UserDetailsComponent.prototype.onExistEntity = function (entity) {
        this.user = entity;
        this.initForm();
    };
    UserDetailsComponent.prototype.onNewEntity = function () {
        this.initForm();
        this.user.number_of_sites = 0;
        this.user.id = -1;
    };
    UserDetailsComponent.prototype.isPasswordFieldsVisible = function () {
        return this.user.status === USER_STATUS.ACTIVE && this.user.id > -1;
    };
    UserDetailsComponent.prototype.initForm = function () {
        var _this = this;
        var _a;
        var rolesGroups = [];
        if (this.isInEditMode) {
            this.user.getUserRoles().forEach(function (role) {
                if (UserDetailsComponent.roleIsEnableForEditing(role.roleId)) {
                    rolesGroups.push(_this.generateFormGroupForRole(role));
                }
            });
        }
        else {
            if (this._navigationService.getModuleName() !== ModuleNames.SPECIAL_ADMIN) {
                rolesGroups.push(this.generateFormGroupForRole({ roleId: ERoleIds.AGENT, parentId: null }));
            }
            else {
                // for special admin do not make with Agent ID
                rolesGroups.push(this.generateFormGroupForRole({ roleId: null, parentId: null }));
            }
        }
        this._rolesFormArray = this._fb.array(rolesGroups);
        this._passwordFormArray = this._fb.array([
            ['',
                [Validators.minLength(6),
                    Validators.maxLength(this._maxPasswordFieldLength)].concat(this.isInEditMode ? [] : [Validators.required])
            ],
            [''],
        ], function (c) {
            return !!c.value[0] && c.value[0] !== c.value[1] ? { confirm_failed: true } : null;
        });
        this.userForm = this._fb.group((_a = {},
            _a[this.ROLES_FORM_NAME] = this._rolesFormArray,
            _a.first_name = [this.user.first_name,
                [Validators.required, Validators.minLength(this._minNameFieldLength), Validators.maxLength(this._maxStringFieldLength)]],
            _a.last_name = [this.user.last_name,
                [Validators.required, Validators.minLength(this._minNameFieldLength), Validators.maxLength(this._maxStringFieldLength)]],
            _a.phone = [this.user.phone],
            _a.email = [this.user.email, [Validators.required, Validators.maxLength(254), Validators.email]],
            _a.description = [this.user.description],
            // , disabled: this.isInEditMode
            _a.username = [this.user.username,
                [Validators.required, Validators.minLength(this._minNameFieldLength), Validators.maxLength(this._maxUsernameFieldLength)]],
            _a.emailAsUsername = [{ value: false, disabled: this.isInEditMode }],
            _a.password = this._passwordFormArray,
            _a.company_name = [this.user.company_name, [Validators.required, Validators.pattern(/^\s*[^\s].*$/)]],
            _a.SIRET = this.user.SIRET,
            _a));
        this.subscribeForFormChanges();
    };
    UserDetailsComponent.prototype.subscribeForFormChanges = function () {
        var _this = this;
        var rolesFormArray = this.userForm.controls[this.ROLES_FORM_NAME];
        this.subscriptions.push(rolesFormArray.valueChanges.subscribe(function () {
            rolesFormArray.controls.filter(function (roleGroup) {
                if (roleGroup.dirty) {
                    _this.onRoleControlUpdated(roleGroup);
                }
            });
        }));
        if (!this.isInEditMode) {
            var isEmailAsUsernameSelected_1 = this.userForm.value['emailAsUsername'];
            this.subscriptions.push(this.userForm.controls['emailAsUsername'].valueChanges.subscribe(function (value) {
                isEmailAsUsernameSelected_1 = value;
                if (value) {
                    _this.userForm.controls['username'].disable();
                    _this.userForm.controls['username'].setValue(_this.userForm.get('email').value);
                }
                else {
                    _this.userForm.controls['username'].enable();
                    _this.userForm.controls['username'].setValue(_this.user.username);
                }
            }));
            this.subscriptions.push(this.userForm.controls['email']
                .valueChanges
                .debounceTime(400)
                .distinctUntilChanged()
                .subscribe(function (value) {
                if (isEmailAsUsernameSelected_1) {
                    _this.userForm.controls['username'].setValue(value);
                }
            }));
        }
    };
    UserDetailsComponent.prototype.getIdOfSelectedRoleAtIndex = function (index) {
        return +this.selectedRoles[index][this.ROLE_FORM_NAME];
    };
    UserDetailsComponent.prototype.getViewForRoleParent = function (roleIndex) {
        var roleParents = this.roleParents(this.getIdOfSelectedRoleAtIndex(roleIndex));
        if (!!roleParents && !!roleParents.view) {
            return roleParents.view;
        }
        else {
            return '';
        }
    };
    UserDetailsComponent.prototype.getUsersForRoleParent = function (roleIndex) {
        var roleParents = this.roleParents(this.getIdOfSelectedRoleAtIndex(roleIndex));
        if (!!roleParents && !!roleParents.users) {
            return roleParents.users;
        }
        else {
            return [];
        }
    };
    UserDetailsComponent.prototype.isSuperAdminMode = function () {
        var context = this._navigationService.getContext();
        return context && context.roleId === ERoleIds.SUPERADMIN;
    };
    UserDetailsComponent.prototype.roleParents = function (roleId) {
        if (roleId === ERoleIds.AGENT) {
            return {
                users: this.depositOwners,
                view: 'Deposit owner',
                parentRoleId: ERoleIds.DEPOSIT_OWNER,
            };
        }
        if ([ERoleIds.DEPOSIT_OWNER,
            ERoleIds.SALES_MANAGER,
            ERoleIds.TRANSFORMATION_SITE_MANAGER]
            .includes(roleId)) {
            return {
                users: this.supervisors,
                view: 'Supervisors',
                parentRoleId: ERoleIds.SUPERVISOR,
            };
        }
        return {
            users: [],
            view: 'Parent role',
        }; // roleId == ROLE_IDS.SUPERVISOR || roleId == ROLE_IDS.ECOSYSTEM_MANAGER
    };
    UserDetailsComponent.prototype.shouldAddHimselfAsRoleParent = function (role) {
        var _this = this;
        var roleParents = this.roleParents(role.roleId);
        return !roleParents.users.find(function (user) { return user.id === _this.user.id; }) && // is not already added
            this.selectedRoles.find(function (selectedRole) { return selectedRole.roleId === roleParents.parentRoleId; }); // is parent role selected
    };
    UserDetailsComponent.prototype.addRole = function (role) {
        var _this = this;
        if (!role) {
            if (this.parentModule === ModuleNames.SPECIAL_ADMIN) {
                this.USER_ROLES = USER_ROLES_FOR_SPECIAL_ADMIN;
            }
            var usedRoles = this.USER_ROLES.map(function (userR) { return userR.value; });
            var _loop_1 = function (item) {
                var contains = this_1.selectedRolesForms.controls.some(function (formRole) { return formRole.value[_this.ROLE_FORM_NAME] === item; });
                if (!contains) {
                    role = { roleId: +item, parentId: null };
                    return "break";
                }
            };
            var this_1 = this;
            // check for special admin , next Task
            // superAdmin add no roles
            for (var _i = 0, usedRoles_1 = usedRoles; _i < usedRoles_1.length; _i++) {
                var item = usedRoles_1[_i];
                var state_1 = _loop_1(item);
                if (state_1 === "break")
                    break;
            }
        }
        if (role) {
            setTimeout(function () { return _this.updateDelimiters(); });
            this.selectedRolesForms.push(this.generateFormGroupForRole(role));
        }
        else {
            this.notificationService.warn(this._translate.instant('All possibles roles are added'));
        }
    };
    UserDetailsComponent.prototype.removeRole = function (role) {
        var _this = this;
        this.selectedRolesForms.controls.forEach(function (formRole, index) {
            if (formRole.value[_this.ROLE_FORM_NAME] === role.value[_this.ROLE_FORM_NAME]) {
                // nothing to adjust for special admin
                if (_this.parentModule !== ModuleNames.SPECIAL_ADMIN) {
                    _this.adjustSubUsers(role);
                }
                // if the control is deposit owner and a supervisor for himself we delete the supervisor control
                // user can not be agent for himself and a supervisor
                _this.selectedRolesForms.removeAt(index);
                setTimeout(function () { return _this.updateDelimiters(); });
            }
        });
    };
    /// remove users that depends on each other
    // deposit owner remove the agent assigned
    /// supervisor remove all the users roles depending on it
    UserDetailsComponent.prototype.adjustSubUsers = function (role) {
        var _this = this;
        this.selectedRolesForms.controls.forEach(function (formR) {
            var _a, _b;
            if (role.value[_this.ROLE_FORM_NAME] === ERoleIds.SUPERVISOR) {
                if ((formR.value[_this.ROLE_FORM_NAME] === ERoleIds.SALES_MANAGER ||
                    formR.value[_this.ROLE_FORM_NAME] === ERoleIds.TRANSFORMATION_SITE_MANAGER ||
                    formR.value[_this.ROLE_FORM_NAME] === ERoleIds.DEPOSIT_OWNER)
                    && formR.value[_this.ROLE_PARENT_FORM_NAME] === -1) {
                    formR.setValue((_a = {},
                        _a[_this.ROLE_FORM_NAME] = formR.value[_this.ROLE_FORM_NAME],
                        _a[_this.ROLE_PARENT_FORM_NAME] = null,
                        _a));
                }
            }
            if (role.value[_this.ROLE_FORM_NAME] === ERoleIds.DEPOSIT_OWNER) {
                if (formR.value[_this.ROLE_FORM_NAME] === ERoleIds.AGENT && formR.value[_this.ROLE_PARENT_FORM_NAME] === -1) {
                    formR.setValue((_b = {},
                        _b[_this.ROLE_FORM_NAME] = formR.value[_this.ROLE_FORM_NAME],
                        _b[_this.ROLE_PARENT_FORM_NAME] = null,
                        _b));
                }
            }
        });
    };
    UserDetailsComponent.prototype.includeRoleArray = function (userId) {
        // roles_list.forEach((role) => {
        //   if (role.parentId === -1)
        //     role.parentId = userId;
        // });
        this.user.formRoles = this.selectedRoles.map(function (role) {
            return [role.roleId, role.parentId];
        });
    };
    UserDetailsComponent.prototype.showParentUserErrorHint = function (index) {
        var control = this.userForm.get([this.ROLES_FORM_NAME, index, this.ROLE_PARENT_FORM_NAME]);
        if (control) {
            return !control.valid && control.enabled && control.touched;
        }
        else {
            return false;
        }
    };
    UserDetailsComponent.prototype.isControlInvalid = function (path) {
        var control = this.userForm.get(path);
        if (control) {
            return !control.valid && control.enabled && control.touched;
        }
        else {
            return false;
        }
    };
    UserDetailsComponent.prototype.getControlErrorMessage = function (path) {
        var control = this.userForm.get(path);
        var errorCode = !!control && control.errors && Object.keys(control.errors).length > 0
            ? Object.keys(control.errors)[0]
            : null;
        if (errorCode) {
            var translation = void 0;
            var minLength = path.indexOf('password') === -1 ? this._minNameFieldLength : this._minPasswordLength;
            var maxLength = this._maxStringFieldLength;
            if (path.indexOf('username') > -1) {
                maxLength = this._maxUsernameFieldLength;
            }
            else if (path.indexOf('password') > -1) {
                maxLength = this._maxPasswordFieldLength;
            }
            switch (errorCode) {
                case 'required':
                    translation = this._translate.get('is required');
                    break;
                case 'minlength':
                    translation = this._translate
                        .get('length should be between', {
                        X: minLength,
                        Y: maxLength
                    });
                    break;
                case 'maxlength':
                    translation = this._translate
                        .get('length should be between', {
                        X: minLength,
                        Y: maxLength
                    });
                    break;
                case 'email':
                    translation = this._translate
                        .get('has wrong format');
                    break;
            }
            return translation;
        }
        else {
            return Observable.of('');
        }
    };
    // return false if no roles is duplicated
    // true if one role is duplicated
    UserDetailsComponent.prototype.checkForDuplicateOrNullRoles = function (rolesArray) {
        for (var i = 0; i < rolesArray.controls.length; i++) {
            for (var j = i + 1; j < rolesArray.controls.length; j++) {
                if (!rolesArray.controls[i].value[this.ROLE_FORM_NAME]) {
                    return true;
                }
                if (rolesArray.controls[i].value[this.ROLE_FORM_NAME] === rolesArray.controls[j].value[this.ROLE_FORM_NAME]) {
                    return true;
                }
            }
        }
        return false;
    };
    UserDetailsComponent.prototype.isDuplicateRoles = function () {
        // to be added for special admin : next task
        // to be removed super admin don't add roles !!
        if (this.parentModule === ModuleNames.SPECIAL_ADMIN) {
            if (this.selectedRolesForms.controls.length > USER_ROLES_FOR_SPECIAL_ADMIN.length) {
                return true;
            }
            else {
                return this.checkForDuplicateOrNullRoles(this.selectedRolesForms);
            }
        }
        else if (this.isSuperAdminMode()) {
            return false;
        }
        else {
            // for normal admin
            if (this.selectedRolesForms.controls.length > USER_ROLES.length) {
                return true;
            }
            else {
                return this.checkForDuplicateOrNullRoles(this.selectedRolesForms);
            }
        }
    };
    UserDetailsComponent.prototype.touchRoles = function () {
        var _this = this;
        this._rolesFormArray.controls.forEach(function (control) {
            var parent = control.get(_this.ROLE_PARENT_FORM_NAME);
            if (parent && !parent.valid) {
                parent.markAsTouched();
            }
        });
    };
    UserDetailsComponent.prototype.saveChanges = function () {
        var _this = this;
        if (!this.validateUserForm()) {
            return;
        }
        this.updatePersonalUserData();
        this.updateUserPassword();
        var newUser = this.user.id <= 0;
        if (newUser) {
            this.includeRoleArray(this.user.id);
        }
        var postUpdateFunction = this.parentModule !== ModuleNames.SPECIAL_ADMIN
            ? function (user) { return _this.updateSiteSalesJunction(user).subscribe(function () { return _this.afterUserUpdate(user, newUser); }); }
            : function (user) { return _this.afterUserUpdate(user, newUser); };
        this.user.save().subscribe(postUpdateFunction, function (err) {
            var errorMessage = err.json().message;
            if (errorMessage.indexOf('Role requires parent id to assign') > -1) {
                var roleControl = _this.userForm.get(_this.ROLE_FORM_NAME);
                if (roleControl) {
                    roleControl.markAsTouched();
                }
            }
        });
    };
    UserDetailsComponent.prototype.validateUserForm = function () {
        var _this = this;
        if (!this.checkRoleValidity()) {
            this.touchRoles();
            return false;
        }
        if (this.isDuplicateRoles()) {
            this.notificationService.error(this._translate.instant('Some Roles are Duplicate or empty'), null, true);
            return false;
        }
        if (!this.userForm.valid) {
            Object.values(this.userForm.controls).forEach(function (control) {
                if (!control.valid) {
                    control.markAsTouched();
                }
            });
            // Setting confirm_failed error to second password input
            if (!this._passwordFormArray.valid) {
                this._passwordFormArray.controls[1].setErrors(this._passwordFormArray.errors);
            }
            this._passwordFormArray.controls.forEach(function (control) {
                if (!control.valid || !_this._passwordFormArray.valid) {
                    control.markAsTouched();
                }
            });
            this.touchRoles();
        }
        return true;
    };
    UserDetailsComponent.prototype.afterUserUpdate = function (user, newUser) {
        var _this = this;
        if (newUser) {
            this.avatarUploader.upload(user.id);
            this.onSaveAction.emit();
        }
        else {
            var updateRolesObservable = this.updateUserRoles(user.id);
            var checkOwnRolesAndNavigate_1 = function () {
                _this._currentUserService.currentUserDidChanged.emit();
                _this.onSaveAction.emit();
            };
            if (updateRolesObservable) {
                updateRolesObservable.subscribe(function () { return checkOwnRolesAndNavigate_1(); });
            }
            else {
                checkOwnRolesAndNavigate_1();
            }
        }
    };
    UserDetailsComponent.prototype.updatePersonalUserData = function () {
        this.user.first_name = this.userForm.value['first_name'];
        this.user.last_name = this.userForm.value['last_name'];
        this.user.email = this.userForm.value['email'];
        this.user.phone = this.userForm.value['phone'];
        this.user.description = this.userForm.value['description'];
        this.user.company_name = this.userForm.value['company_name'];
        this.user.SIRET = this.userForm.value['SIRET'];
        this.user.username = this.userForm.get('username').value;
        this.user.language = this.user.language ? this.user.language : this._languageService.getUsedLanguage();
    };
    UserDetailsComponent.prototype.updateUserPassword = function () {
        if (this.userForm.value['password'][0].length > 0) {
            this.user.password = this.userForm.value['password'][0];
            this.user.passwordConfirm = this.userForm.value['password'][1];
        }
    };
    UserDetailsComponent.prototype.updateUserRoles = function (userId) {
        var _this = this;
        var rolesForRemoving = [];
        var rolesForUpdate = [];
        var rolesForAssigning = [];
        this.selectedRoles.forEach(function (formRole) {
            if (formRole.roleId === -1) {
                formRole.roleId = userId;
            }
            if (!_this.userHasRole(formRole)) {
                rolesForAssigning.push(formRole);
            }
        });
        this.user.getUserRoles().forEach(function (userRole) {
            if (!_this.formHasRoles(userRole) && UserDetailsComponent.roleIsEnableForEditing(userRole.roleId)) {
                rolesForRemoving.push(userRole);
            }
            else if (_this.roleNeedToUpdateParent(userRole)) {
                rolesForUpdate.push(_this.selectedRoles.find(function (formRole) { return formRole.roleId === userRole.roleId; }));
            }
        });
        if ((rolesForRemoving.length + rolesForUpdate.length + rolesForAssigning.length) > 0) {
            return Observable.merge.apply(Observable, rolesForAssigning.map(function (role) {
                return _this._userService.assignRole(userId, {
                    role: role.roleId,
                    parent_id: role.parentId
                });
            }).concat(rolesForRemoving.map(function (role) {
                return _this._userService.removeRole(userId, {
                    role: role.roleId,
                    parent_id: null
                });
            }), rolesForUpdate.map(function (role) {
                return _this._userService.updateRoleParent(userId, {
                    role: role.roleId,
                    parent_id: role.parentId
                });
            })));
        }
        else {
            return null;
        }
    };
    UserDetailsComponent.prototype.loginAsUser = function () {
        if (this.user.id) {
            this._userService.loginAs(this.user.id).subscribe(function () {
                location.href = '/';
            });
        }
    };
    UserDetailsComponent.prototype.deleteUser = function () {
        // if (this.user.id) {
        // }
        var _this = this;
        if (this.user.id && this.user.status === 0) {
            this._userService.deleteNoTransfer(this.user.id).subscribe(function () { return _this.onSaveAction.emit(); });
            return;
        }
        if (this.user.id) {
            var dialogRef = this._dialog.open(UserDeletionDialogComponent, {
                data: {
                    userId: this.user.id
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.onSaveAction.emit();
                }
            });
        }
        else {
            console.warn('Trying to delete a user while id is not set');
        }
    };
    UserDetailsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.subscriptions.forEach(function (ss) { return ss.unsubscribe(); });
    };
    UserDetailsComponent.prototype.onRoleControlUpdated = function (roleGroup) {
        var _this = this;
        roleGroup.markAsPristine();
        if (this.needsRoleParent(roleGroup.value[this.ROLE_FORM_NAME])) {
            roleGroup.get(this.ROLE_PARENT_FORM_NAME).enable();
        }
        else {
            roleGroup.get(this.ROLE_PARENT_FORM_NAME).disable();
        }
        if (roleGroup.value[this.ROLE_PARENT_FORM_NAME] === -1 || roleGroup.value[this.ROLE_PARENT_FORM_NAME] === this.user.id) {
            this.addParentRoleIfNeeded(roleGroup.value);
        }
        setTimeout(function () { return _this.updateDelimiters(); });
    };
    UserDetailsComponent.prototype.checkRoleValidity = function () {
        var _this = this;
        var valid = true;
        this.selectedRolesForms.controls.forEach(function (formR) {
            var _a, _b;
            var role = formR.value[_this.ROLE_FORM_NAME];
            var parentId = formR.value[_this.ROLE_PARENT_FORM_NAME];
            var roleWithSupervisor = [ERoleIds.SALES_MANAGER, ERoleIds.TRANSFORMATION_SITE_MANAGER, ERoleIds.DEPOSIT_OWNER];
            if ((roleWithSupervisor.includes(role)) && parentId === -1) {
                if (!_this.formHasRoles({ roleId: ERoleIds.SUPERVISOR, parentId: null })) {
                    formR.setValue((_a = {},
                        _a[_this.ROLE_FORM_NAME] = role,
                        _a[_this.ROLE_PARENT_FORM_NAME] = null,
                        _a));
                    valid = false;
                }
            }
            if (role === ERoleIds.AGENT && parentId === -1) {
                if (!_this.formHasRoles({ roleId: ERoleIds.DEPOSIT_OWNER, parentId: null })) {
                    formR.setValue((_b = {},
                        _b[_this.ROLE_FORM_NAME] = formR.value[_this.ROLE_FORM_NAME],
                        _b[_this.ROLE_PARENT_FORM_NAME] = null,
                        _b));
                    valid = false;
                }
            }
        });
        return valid;
    };
    UserDetailsComponent.prototype.roleNeedToUpdateParent = function (role) {
        var roleWithSameId = this.selectedRoles.find(function (formRole) { return formRole.roleId === role.roleId; });
        return (roleWithSameId && roleWithSameId.parentId !== role.parentId);
    };
    UserDetailsComponent.prototype.userHasRole = function (role) {
        return !!this.user.getUserRoles().find(function (userRole) { return userRole.roleId === role.roleId; });
    };
    UserDetailsComponent.prototype.formHasRoles = function (role) {
        return !!this.selectedRoles.find(function (formRole) { return formRole.roleId === role.roleId; });
    };
    UserDetailsComponent.prototype.generateFormGroupForRole = function (role) {
        var _a;
        return this._fb.group((_a = {},
            _a[this.ROLE_FORM_NAME] = role.roleId,
            _a[this.ROLE_PARENT_FORM_NAME] = [{ value: role.parentId, disabled: !this.needsRoleParent(role.roleId) }, [Validators.required]],
            _a));
    };
    UserDetailsComponent.prototype.needsRoleParent = function (roleId) {
        return Boolean(this.roleParents(roleId).parentRoleId);
    };
    UserDetailsComponent.prototype.addParentRoleIfNeeded = function (role) {
        var roleParent = this.roleParents(role.roleId);
        if (!!roleParent && !!roleParent.parentRoleId) {
            var userHasParentAsRole = !!this.selectedRoles.find(function (selectedRole) { return selectedRole.roleId === roleParent.parentRoleId; });
            if (!userHasParentAsRole) {
                this.addRole({ roleId: roleParent.parentRoleId, parentId: null });
            }
        }
    };
    UserDetailsComponent.prototype.onCompanyChange = function (value) {
        // if ( this.parentModule !== ModuleNames.SPECIAL_ADMIN && this.allCompanies) {
        //   this.filteredCompanies = value
        //     ? this.allCompanies.filter((category: string) =>
        //       ~category.toLowerCase().indexOf(value.toLowerCase()) && category !== value)
        //     : this.allCompanies;
        // }
        this.user.company_name = value;
    };
    UserDetailsComponent.prototype.resendActivation = function () {
        var _this = this;
        if (this.user.id) {
            this._userService.resendActivation(this.user.id).subscribe(function () {
                _this._router.navigate(['admin/users']);
            });
        }
    };
    UserDetailsComponent.prototype.isSiteListItemCheck = function (item) {
        if (item instanceof DepositLocation) {
            return this.findSite(item);
        }
        else if (item.id === this.SITE_LIST_COMMAND_ALL) {
            return this.isAllChecked();
        }
    };
    UserDetailsComponent.prototype.isAllChecked = function () {
        return this.isAllsites;
    };
    UserDetailsComponent.prototype.findSite = function (item) {
        return !!this.saleSiteIds.has(item.id);
    };
    UserDetailsComponent.prototype.changeSiteList = function (data) {
        if (data.value instanceof DepositLocation) {
            this.checkLocation(data.check, data.value);
        }
        else {
            data.value.command(data.check);
        }
    };
    UserDetailsComponent.prototype.checkLocation = function (check, location) {
        var _this = this;
        var findAllowedSites = function () { return _this.allLocationsRecords.filter(function (record) { return record.value && _this.saleSiteIds.has(record.value.id); }); };
        if (check) {
            if (!this.findSite(location)) {
                this.saleSiteIds.add(location.id);
                if ((this.allLocationsRecords.length - 1) === this.saleSiteIds.size) {
                    this.isAllsites = true;
                }
                this.updateChosenLocationRecords(findAllowedSites());
            }
        }
        else {
            this.saleSiteIds.delete(location.id);
            if ((this.salesSitesRecords.length) !== this.saleSiteIds.size) {
                this.isAllsites = false;
                this.updateChosenLocationRecords(findAllowedSites());
            }
            else {
                this.salesSitesRecords = this.salesSitesRecords.filter(function (item) { return item.value && item.value.id !== location.id; });
            }
        }
    };
    UserDetailsComponent.prototype.updateChosenLocationRecords = function (records) {
        var _this = this;
        setTimeout(function () { return _this.updateDelimiters(); });
        if (this.isAllsites) {
            return this.salesSitesRecords = [
                CustomSelectionListWithChipRecordModel.build().setNameFunc(function (value) { return _this._translate.instant('All sites'); })
                    .addClass(['text-value'])
            ];
        }
        return this.salesSitesRecords = records;
    };
    UserDetailsComponent.prototype.initAllLocationRecords = function (list) {
        var _this = this;
        return [CustomSelectionListWithChipRecordModel.build().setNameFunc(function (value) { return _this._translate.instant('All sites'); })
                .addValue({ command: this.checkAllLocations, id: this.SITE_LIST_COMMAND_ALL }).addClass(['command'])].concat(list.map(function (location) { return CustomSelectionListWithChipRecordModel.build()
            .setNameFunc(function (value) { return value.name; }).addValue(location); }));
    };
    UserDetailsComponent.prototype.checkAllLocations = function (check) {
        this.isAllsites = check;
        this.setChosenLocationRecords(check ? this.allLocationsRecords : []);
    };
    UserDetailsComponent.prototype.setChosenLocationRecords = function (records) {
        this.saleSiteIds = new Set(records.filter(function (record) { return record.value instanceof DepositLocation; })
            .map(function (record) { return record.value.id; }));
        return this.updateChosenLocationRecords(records);
    };
    UserDetailsComponent.prototype.loadChosenLocationRecords = function () {
        var _this = this;
        this._depositLocationSalesService.get({ user_id: this.user.id }).subscribe(function (siteSales) {
            _this.siteSalesJunctionBefore = siteSales.reduce(function (acamulator, item) {
                acamulator[item.site_id] = item;
                return acamulator;
            }, {});
            var siteChosen = new Set(Object.keys(_this.siteSalesJunctionBefore));
            var records = _this.allLocationsRecords.filter(function (record) {
                return record.value && siteChosen.has(record.value.id + '');
            });
            _this.isAllsites = (_this.allLocationsRecords.length - 1) === records.length;
            _this.setChosenLocationRecords(records);
        });
    };
    UserDetailsComponent.prototype.updateSiteSalesJunction = function (user) {
        var _this = this;
        var forDelete = [];
        var notChangedSiteIds = new Set();
        var forInsert = [];
        Object.values(this.siteSalesJunctionBefore).forEach(function (siteSales) {
            return _this.saleSiteIds.has(siteSales.site_id) ? notChangedSiteIds.add(siteSales.site_id) : forDelete.push(siteSales.site_id);
        });
        this.saleSiteIds.forEach(function (siteId) {
            if (!notChangedSiteIds.has(siteId)) {
                forInsert.push(siteId);
            }
        });
        return this._depositLocationSalesService.updateLocationList(forDelete, forInsert, user.id || this.user.id).do(function (junctions) {
            forDelete.forEach(function (id) { return delete _this.siteSalesJunctionBefore[id]; });
            junctions.forEach(function (data) { return _this.siteSalesJunctionBefore[data.site_id] = data; });
        });
    };
    UserDetailsComponent.prototype.updateDelimiters = function () {
        var _this = this;
        var delimiterActivation = this.maxRoleHeightPx * 1.5;
        if (this.roleListElem) {
            var activateNext_1 = false;
            var siteArrays_1 = this.sitesElem.toArray();
            var siteIndex_1 = 0;
            this.roleListElem.forEach(function (row, i, rows) {
                var delimiterElem = rows[i].nativeElement.querySelector('.' + _this.delimiterClassName);
                if (!!delimiterElem) {
                    var isActivated = delimiterElem.classList.contains('active');
                    if ((isActivated || activateNext_1) && !(isActivated && activateNext_1)) {
                        delimiterElem.classList.toggle('active');
                    }
                }
                activateNext_1 = false;
                if (siteArrays_1.length > siteIndex_1 && row.nativeElement.contains(siteArrays_1[siteIndex_1].nativeElement)) {
                    activateNext_1 = siteArrays_1[siteIndex_1++].nativeElement.children[0].clientHeight > delimiterActivation;
                }
            });
        }
    };
    UserDetailsComponent.prototype.isNextRoleItemSales = function (index, controls, nextStep) {
        if (nextStep === void 0) { nextStep = 1; }
        var isSales = function (i) { return controls[i].value.roleId === ERoleIds.SALES_MANAGER; };
        return controls.length > index + nextStep && isSales(index) && isSales(index + nextStep);
    };
    UserDetailsComponent.prototype.getParentRoleWithUsernameText = function (user) {
        var parentRoleIsSameUser = -1;
        if (user.id === parentRoleIsSameUser) {
            return this._translate.instant('him/herself');
        }
        else {
            return user.name + "(" + user.username + ")";
        }
    };
    return UserDetailsComponent;
}(EntityViewerComponent));
export { UserDetailsComponent };
