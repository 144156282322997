import { BaseModel } from 'ng2-adn-common';
import { ITransformationStats } from '../interfaces/transformation-stats.interface';
import { Utils } from '../../../shared/utils/utils.class';

export class TransformationStats extends BaseModel implements ITransformationStats {
  published: number;
  rejected: number;
  orders_rejected: number;
  order_accepted: number;
  m: number;
  y: number;

  fields(): string[] {
    return [
      'published',
      'rejected',
      'orders_rejected',
      'order_accepted',
      'm',
      'y'
    ];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    Utils.convertJsonStringToNumber(json, [
      'published',
      'rejected',
      'orders_rejected',
      'order_accepted',
      'm',
      'y'
    ]);
    return json;
  }
}
