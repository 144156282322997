<div class="deposit-import__header--label space-between">
    <label class="passport__tab--label">{{ 'LOOKUP_TABLE_TITLE' | translate }}</label>

    <div class="deposit-import__header--label">
        <div class="deposit-import__header--required">{{ 'LOOKUP_TABLE_DESCRIPTION' | translate}}</div>
    </div>

    <div *ngFor="let table of tables ">
        <import-deposit-table-item [table]="table"></import-deposit-table-item>
    </div>
</div>
