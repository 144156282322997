<md-dialog-content>
    <div class="delete-folder-modal">
      <div class="delete-folder-modal__heading">
        <div class="delete-folder-modal__image">
          <img src="assets/png/warning_blue_2.png" class="delete-folder-modal__triangle">
          <span class="delete-folder-modal__exclamation">!</span>
        </div>
      </div>
  
      <div>
        
        <div class="delete-folder-modal__text" >
          <b>{{ getTranslation() | async}}</b>
        </div>
        <div class="delete-folder-modal__text" >
          {{ 'DELETE_WARNING_SUBTITLE' | translate}}
        </div>
      </div>
  
      <div class="delete-folder-modal__footer">
        <button class="delete-folder-modal__button" md-raised-button
          (click)="confirm()">{{ 'Yes' | translate }}</button>
        <button class="delete-folder-modal__button" md-raised-button
          (click)="closeDialog()">{{ 'Cancel' | translate}}</button>
      </div>
    </div>
  </md-dialog-content>
  