const requestFixedWidthMin = '546px';
const requestFixedWidthMax = '800px';

export const DIALOG_THEME = {
  BELOW_NAVBAR: {
    position: {
      top: '59.5px'
    }
  },
  BELOW_NAVBAR_WIDE: {
    position: {
      top: '59.5px'
    },
    width: '80%'
  },
  ALERT_PRIMARY: {
    width: '40%'
  },
  WIDE_PRIMARY: {
    width: '80%'
  },
  NOMENCLATURE_PRIMARY: {
    width: '80%',
    height: '80%'
  },
  CHANGE_REQUEST_PRIMARY: {
    width:  `max(46%, ${requestFixedWidthMin})`
  },
  CHANGE_REQUEST_WIDTH_IN_INTERVAL: {
    width: `min(${requestFixedWidthMax}, max(46%, ${requestFixedWidthMin}))`
  },
  CHANGE_REQUEST_FIXED_WIDTH: {
    width: requestFixedWidthMin
  },
  TRANSACTION_ORDER_CONFIRMATION: {
    width: '806px'
  },
  PROBLEM_REPORT: {
    width: '619px'
  },
  SUCCESS_IMPORT_DEPOSIT: {
    width: '653px'
  }
};
