<md-dialog-content class="create-transform-site-dialog"
                   [formGroup]="transformSiteForm"
                   (ngSubmit)="save(transformSiteForm)">
  <header class="create-transform-site-dialog__header">
    <h3 class="create-transform-site-dialog__title">{{'Create transformation site' | translate}}</h3>
    <!--<i class="icon icon--close_s cretransformation-managerform-site-dialog__close" (click)="closeDialog()"></i>-->
  </header>

  <div class="create-transform-site-dialog__content">
    <md-input-container class="create-transform-site-dialog__content-name">
      <input mdInput formControlName="name" [placeholder]="'Site Name' | translate"
             [(ngModel)]="location.name">
    </md-input-container>
    <md-input-container class="create-transform-site-dialog__content-address">
      <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
        <input
          mdInput
          autocomplete="off" 
          formControlName="address"
          [placeholder]="'Address' | translate"
          [(ngModel)]="location.address"
          placeAutocomplete
          (placeChange)="onPlaceChange($event)"
          (change) = "onTextchange()"
        >
      </place-autocomplete>
    </md-input-container>
    <md-input-container class="create-transform-site-dialog__content-description">
      <input mdInput formControlName="description" autocomplete="off" [placeholder]="'Description' | translate"
              [(ngModel)]="location.description">
    </md-input-container>

    <div class="create-transform-site-dialog__content__button">
      <button class="create-transform-site-dialog__content__create"
              (click)="save(transformSiteForm)"
              [disabled]="!transformSiteForm.valid || !transformSiteForm.dirty"
              md-raised-button>
        {{'Create' | translate}}
      </button>
    </div>
  </div>

</md-dialog-content>
