/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report-site-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../services/report-site-tag-presenter.service";
import * as i5 from "./report-site-list.component";
var styles_ReportSiteListComponent = [i0.styles];
var RenderType_ReportSiteListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportSiteListComponent, data: {} });
export { RenderType_ReportSiteListComponent as RenderType_ReportSiteListComponent };
function View_ReportSiteListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "report-base__sites-chips"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("All sites")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReportSiteListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "report-base__sites-chips"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Generated by spreadsheet")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReportSiteListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "report-base__sites-chips-tag"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ReportSiteListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["siteReport", 1]], null, 4, "span", [["class", "report-base__sites-chips"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "report-base__sites-chips-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportSiteListComponent_5)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tagPresenter.getTags(_v.context.$implicit.id); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_ReportSiteListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "report-base__sites-chips-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportSiteListComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportSiteListComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportSiteListComponent_4)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.createdByFile && (((_co._sites == null) ? null : _co._sites.length) === 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !!_co.createdByFile; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co._sites; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ReportSiteListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { siteBoxViewList: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportSiteListComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co._sites; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ReportSiteListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "report-site-list", [], null, null, null, View_ReportSiteListComponent_0, RenderType_ReportSiteListComponent)), i1.ɵprd(512, null, i4.ReportSiteTagPresenterService, i4.ReportSiteTagPresenterService, []), i1.ɵdid(2, 2146304, null, 0, i5.ReportSiteListComponent, [i4.ReportSiteTagPresenterService, i1.ChangeDetectorRef], null, null)], null, null); }
var ReportSiteListComponentNgFactory = i1.ɵccf("report-site-list", i5.ReportSiteListComponent, View_ReportSiteListComponent_Host_0, { sites: "sites", tags: "tags", createdByFile: "createdByFile" }, {}, []);
export { ReportSiteListComponentNgFactory as ReportSiteListComponentNgFactory };
