import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { CompanyFile } from '../models/company-file.class';
var CompanyFileService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyFileService, _super);
    function CompanyFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ModelClass = CompanyFile;
        _this.namespace = 'company-file';
        return _this;
    }
    return CompanyFileService;
}(CrudService));
export { CompanyFileService };
