<ng-container [ngSwitch]="toxicityState">
    <!--None-->
    <svg *ngSwitchCase="ToxicityState.None" width="31" height="17" viewBox="0 0 31 17" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
            <path
                d="M15.5156 1.26465C14.5553 1.26465 13.685 1.65618 13.0548 2.28864C12.4246 2.9211 12.0345 3.7945 12.0345 4.75825C12.0345 6.68575 13.5949 8.25184 15.5156 8.25184C17.4362 8.25184 18.9967 6.68575 18.9967 4.75825C18.9967 2.83074 17.4362 1.26465 15.5156 1.26465ZM14.6153 9.15536C11.3742 9.15536 8.7334 11.7756 8.7334 15.0282V15.6306C10.8941 15.9016 13.1448 16.0221 15.5156 16.0221C17.8563 16.0221 20.137 15.9016 22.2977 15.6306V15.0282C22.2977 11.7756 19.6569 9.15536 16.4158 9.15536H14.6153Z"
                fill="#555C63" />
            <path opacity="0.8"
                d="M24.2184 0C22.2978 0 20.7373 1.5661 20.7373 3.4936C20.7373 5.4211 22.2978 6.98719 24.2184 6.98719C26.139 6.98719 27.6995 5.4211 27.6995 3.4936C27.6995 1.5661 26.139 0 24.2184 0Z"
                fill="#555C63" />
            <path opacity="0.8"
                d="M23.318 7.92017C21.8175 7.92017 20.4371 8.49239 19.3867 9.45614C21.3073 10.4801 22.6578 12.498 22.7478 14.817H24.4283C26.649 14.817 28.8697 14.5158 30.9704 13.793H31.0004C30.9704 10.5705 28.3596 7.95029 25.1185 7.95029H23.318V7.92017Z"
                fill="#555C63" />
            <path opacity="0.8"
                d="M6.7819 0C4.86129 0 3.30078 1.5661 3.30078 3.4936C3.30078 5.4211 4.86129 6.98719 6.7819 6.98719C8.70251 6.98719 10.263 5.4211 10.263 3.4936C10.293 1.5661 8.70251 0 6.7819 0Z"
                fill="#555C63" />
            <path opacity="0.8"
                d="M5.88189 7.89022C2.67086 7.89022 0.0300096 10.5104 0 13.733H0.0300206C2.13069 14.4558 4.3514 14.7569 6.57211 14.7569H8.25264C8.34267 12.4379 9.6931 10.4201 11.6137 9.39608C10.5634 8.43233 9.21296 7.86011 7.68247 7.86011H5.88189V7.89022Z"
                fill="#555C63" />
        </g>
    </svg>
    <!--Unverified-->
    <svg *ngSwitchCase="ToxicityState.Unverified" width="31" height="17" viewBox="0 0 31 17" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.5156 1.26465C14.5553 1.26465 13.685 1.65618 13.0548 2.28864C12.4246 2.9211 12.0345 3.7945 12.0345 4.75825C12.0345 6.68575 13.5949 8.25184 15.5156 8.25184C17.4362 8.25184 18.9967 6.68575 18.9967 4.75825C18.9967 2.83074 17.4362 1.26465 15.5156 1.26465ZM14.6153 9.15536C11.3742 9.15536 8.7334 11.7756 8.7334 15.0282V15.6306C10.8941 15.9016 13.1448 16.0221 15.5156 16.0221C17.8563 16.0221 20.137 15.9016 22.2977 15.6306V15.0282C22.2977 11.7756 19.6569 9.15536 16.4158 9.15536H14.6153Z"
            fill="black" fill-opacity="0.8" />
        <path opacity="0.8"
            d="M24.2184 0C22.2978 0 20.7373 1.5661 20.7373 3.4936C20.7373 5.4211 22.2978 6.98719 24.2184 6.98719C26.139 6.98719 27.6995 5.4211 27.6995 3.4936C27.6995 1.5661 26.139 0 24.2184 0Z"
            fill="black" fill-opacity="0.8" />
        <path opacity="0.8"
            d="M23.318 7.92017C21.8175 7.92017 20.4371 8.49239 19.3867 9.45614C21.3073 10.4801 22.6578 12.498 22.7478 14.817H24.4283C26.649 14.817 28.8697 14.5158 30.9704 13.793H31.0004C30.9704 10.5705 28.3596 7.95029 25.1185 7.95029H23.318V7.92017Z"
            fill="black" fill-opacity="0.8" />
        <path opacity="0.8"
            d="M6.7819 0C4.86129 0 3.30078 1.5661 3.30078 3.4936C3.30078 5.4211 4.86129 6.98719 6.7819 6.98719C8.70251 6.98719 10.263 5.4211 10.263 3.4936C10.293 1.5661 8.70251 0 6.7819 0Z"
            fill="black" fill-opacity="0.8" />
        <path opacity="0.8"
            d="M5.88189 7.89022C2.67086 7.89022 0.0300096 10.5104 0 13.733H0.0300206C2.13069 14.4558 4.3514 14.7569 6.57211 14.7569H8.25264C8.34267 12.4379 9.6931 10.4201 11.6137 9.39608C10.5634 8.43233 9.21296 7.86011 7.68247 7.86011H5.88189V7.89022Z"
            fill="black" fill-opacity="0.8" />
    </svg>
    <!--Verified-->
    <svg *ngSwitchCase="ToxicityState.Verified" width="31" height="17" viewBox="0 0 31 17" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.5156 1.26465C14.5553 1.26465 13.685 1.65618 13.0548 2.28864C12.4246 2.9211 12.0345 3.7945 12.0345 4.75825C12.0345 6.68575 13.5949 8.25184 15.5156 8.25184C17.4362 8.25184 18.9967 6.68575 18.9967 4.75825C18.9967 2.83074 17.4362 1.26465 15.5156 1.26465ZM14.6153 9.15536C11.3742 9.15536 8.7334 11.7756 8.7334 15.0282V15.6306C10.8941 15.9016 13.1448 16.0221 15.5156 16.0221C17.8563 16.0221 20.137 15.9016 22.2977 15.6306V15.0282C22.2977 11.7756 19.6569 9.15536 16.4158 9.15536H14.6153Z"
            fill="#8DC53F" />
        <path opacity="0.8"
            d="M24.2184 0C22.2978 0 20.7373 1.5661 20.7373 3.4936C20.7373 5.4211 22.2978 6.98719 24.2184 6.98719C26.139 6.98719 27.6995 5.4211 27.6995 3.4936C27.6995 1.5661 26.139 0 24.2184 0Z"
            fill="#8DC53F" />
        <path opacity="0.8"
            d="M23.318 7.92017C21.8175 7.92017 20.4371 8.49239 19.3867 9.45614C21.3073 10.4801 22.6578 12.498 22.7478 14.817H24.4283C26.649 14.817 28.8697 14.5158 30.9704 13.793H31.0004C30.9704 10.5705 28.3596 7.95029 25.1185 7.95029H23.318V7.92017Z"
            fill="#8DC53F" />
        <path opacity="0.8"
            d="M6.7819 0C4.86129 0 3.30078 1.5661 3.30078 3.4936C3.30078 5.4211 4.86129 6.98719 6.7819 6.98719C8.70251 6.98719 10.263 5.4211 10.263 3.4936C10.293 1.5661 8.70251 0 6.7819 0Z"
            fill="#8DC53F" />
        <path opacity="0.8"
            d="M5.88189 7.89022C2.67086 7.89022 0.0300096 10.5104 0 13.733H0.0300206C2.13069 14.4558 4.3514 14.7569 6.57211 14.7569H8.25264C8.34267 12.4379 9.6931 10.4201 11.6137 9.39608C10.5634 8.43233 9.21296 7.86011 7.68247 7.86011H5.88189V7.89022Z"
            fill="#8DC53F" />
    </svg>
</ng-container>