<div>
  <div class="deposit-warning">
    <div class="deposit-warning__heading">
      {{ 'warning' | translate | uppercase }}
    </div>

    <div class="flex">
      <div class="flex">
        <div class="deposit-warning__attention">!</div>
      </div>
      <span class="deposit-warning__text">
        {{'DEPOSIT_WARNING_0' | translate}}
      </span>
    </div>

    <div class="flex">
      <div class="flex">
        <div class="deposit-warning__attention">!</div>
      </div>
      <span class="deposit-warning__text">
        {{'DEPOSIT_WARNING_1' | translate}}
      </span>
    </div>
    <div class="flex">
      <div class="flex">
        <div class="deposit-warning__attention">!</div>
      </div>
      <span class="deposit-warning__text">
        {{'DEPOSIT_WARNING_2' | translate}}
      </span>
    </div>
    <div class="deposit-warning__footer">
      <button class="deposit-warning__button" md-button md-raised-button (click)="confirm()" >{{ 'Confirm' | translate | uppercase}}</button>
      <button class="deposit-warning__button gray" md-button md-raised-button (click)="closeDialog()" >{{ 'Cancel' | translate | uppercase}}</button>
    </div>
  </div>
</div>
