import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MdInputDirective} from '@angular/material';


@Component({
  moduleId: module.id,
  selector: 'deposit-import-column',
  templateUrl: 'deposit-import-column.component.html',
  styleUrls: ['./deposit-import-column.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DepositImportColumnComponent)
    }],
  encapsulation: ViewEncapsulation.None
})
export class DepositImportColumnComponent implements ControlValueAccessor {
  onChange: any;
  onTouch: any;
  value: any;
  disabled: boolean;
  required = false;
  @Input() isWideClass: boolean;
  @Input() label: string;
  @Input() columnName: string;
  @Input('required') set  serRequired(isRequired) {
    this.cd.detectChanges();
    this.required = isRequired;
  }
  @Output() onKeyDown = new EventEmitter<KeyboardEvent>();
  @Output() onBlur = new EventEmitter<KeyboardEvent>();
  @ViewChild(MdInputDirective) _mdInputChild:  MdInputDirective;

  constructor(private cd: ChangeDetectorRef) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.cd.detectChanges();
    this.value = obj;
  }

  changeValue() {
    this.onChange(this.value);
  }
  touchValue() {
    this.onBlur.emit();
    this.onTouch(this.value);
  }
}
