/**
 * Created by aleksandr on 14.06.17.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavbarStateService } from '../services/navbar.service';
import { ECustomListColumnType, ECustomListThemeClass, ICustomListRecord } from './interfaces/custom-list.interface';
import {Observable} from 'rxjs/Observable';

@Component({
  moduleId: module.id,
  selector: 'custom-list',
  templateUrl: 'custom-list.component.html',
  styleUrls: ['custom-list.component.scss'],
})
export class CustomListComponent  {

  @Input() records: ICustomListRecord[];
  @Input() columnKeys: string[] = [];
  @Input() cloudIcon: boolean = false;
  @Input() expandable: boolean = false;
  @Input() beforeExpandObserver: Observable<any>;
  @Input() beforeCollapseObserver: Observable<any>;
  @Input() bottomOffsetToEmitEndVisible: number = 50;
  @Input() parentModule: string;

  @Output() endVisible = new EventEmitter();
  @Output() onEndOfList = new EventEmitter();
  @Output() onExpand = new EventEmitter();

  expand: boolean = false;
  expandIsLoading = false;

  public types = ECustomListColumnType;
  public theme = ECustomListThemeClass;

  constructor(public filterState: NavbarStateService) {}

  toggleExpand() {
    const toggle = () => {
      if (!this.expand && this.records.length < 20) {
        this.onEndOfList.emit();
      }
      this.expand = !this.expand;
      this.onExpand.emit(this.expand);
    };
    const beforeToggleAction = (func: Observable<any>) => {
      this.expandIsLoading = true;
      func.subscribe(() => {
        toggle();
        this.expandIsLoading = false;
      }, () => this.expandIsLoading = false);
    };
    if (!this.expandIsLoading) {
      if (!this.expand && this.beforeExpandObserver) {
        beforeToggleAction(this.beforeExpandObserver);
      } else if (this.expand && this.beforeCollapseObserver) {
        beforeToggleAction(this.beforeCollapseObserver);
      } else {
        toggle();        
      }
    }
  }

  onScroll(event: any) {
    if (!event || !event.srcElement) {
      console.warn('no event on scroll!');
      return;
    }
    const maxHeight: number = event.srcElement.scrollHeight;
    const currentYOffset: number = event.srcElement.scrollTop + event.srcElement.clientHeight;
    if (!(maxHeight > 0 && currentYOffset > 0)) {
      return;
    }
    if (maxHeight - currentYOffset < this.bottomOffsetToEmitEndVisible) {
      this.onEndOfList.emit();
    }
  }

  getAvatar(url: string): any {
    return {'background-image': 'url(' + url + ')'};
  }

  getColumnKey(index: number) {
    return this.columnKeys[index] ? this.columnKeys[index] : String(index + 1);
  }
  getMultilangName(value) {
    if (value && value.english_name) {
      return this.filterState.multilanguage ? value.english_name : value.text;
    } else {
      return value;
    }
  }
}
