<confirm-with-header *ngIf="requestedESM"
  title="{{'Change the number of ecosystem manager(s)' | translate}}"
  [disabled]="isConfirmDisabled()"
  (onConfirm)="confirmRequest()"
  (onCancel)="closeDialog()">
  <section class="request-esm-change-dialog__header">
    <span class="request-esm-change-dialog__header-row">
      {{'You can currently distribute' | translate}}
    </span>
    <span class="request-esm-change-dialog__header-row">
      {{availableESM}}{{' '}}{{'Ecosystem Manager accounts' | translate}}
    </span>
  </section>
  <section class="request-esm-change-dialog__request">
    <div class="request-esm-change-dialog__request-label">
      {{'Specify the number of Ecosystem Managers accounts desired.' | translate}}
    </div>
    <md-input-container class="request-esm-change-dialog__request-input-container">
      <input
        mdInput
        type="number"
        (beforeinput)="prohibitMinusSign($event)"
        [formControl]="requestedESM"
      >
    </md-input-container>
    <div @errorMessage *ngIf="showErrorMessageAvailableLessThenUsed()"
         class="request-esm-change-dialog__request-input-error">
      <span class="form-group__label--question request-esm-change-dialog__request-input-error-sign">!</span>
      <span class="request-esm-change-dialog__request-input-error-message">
          {{'To make this request, you must first delete one ' +
      'or more Ecosystem Managers roles in your USERS tab.'| translate}}
        </span>
    </div>
  </section>

</confirm-with-header>
