import { Component, Inject } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { EcosystemFolderService } from '../../../../../../../entities/ecosystem/services/ecosystem-folder.service';

@Component({
  moduleId: module.id,
  selector: 'delete-folder-modal',
  templateUrl: 'delete-folder-modal.component.html',
  styleUrls: ['delete-folder-modal.component.scss']

})
export class DeleteFolderModalComponent {
  warningMessage = '';
  constructor(public mdDialogRef: MdDialogRef<void>,
    public folderService: EcosystemFolderService,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any,
    private _translate: TranslateService) { }


  confirm() {
      this.folderService.deleteFolder()
      .subscribe(() => {
        this.mdDialogRef.close();
        this.folderService.resetSelection();
        this.folderService.leaveFolder();
      })
  }

  closeDialog() {
    this.mdDialogRef.close();
  }

  getTranslation() {
    return this._translate.get('DELETE_WARNING', {
      X: this.mdDialogData.nbSelection,
    })
  }
}
