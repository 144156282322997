/**
 * Created by atat on 18/06/2017.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TransformOrder } from '../../../ecosystem-manager/models/transform-order.class';
import { TransformOrderService } from '../../../ecosystem-manager/services/transform-order.service';
import { ECOSYSTEM_STEP_STATUS } from '../../../ecosystem-manager/values/ecosystem-step-status.const';
import { TransformLocationService } from '../../../../entities/transformation/services/transform-location.service';
import { TransformLocation } from '../../../../entities/transformation/models/transform-location.class';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { UploadedDocument } from '../../../../shared/models/uploaders/document.class';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import { MdDialog } from '@angular/material';
import { TransformDetailsDialogComponent } from '../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { PASSPORT_UNITS_TYPE } from '../../../../entities/passport/values/passport-units.const';
import { TRANSFORM_TYPE } from '../../values/transform-types.const';
import { TRANSFORM_ORDER_STATUS } from '../../../../dashboards/transformation-manager/values/transform-order-statuses.const'
import moment from 'moment';
@Component({
  moduleId: module.id,
  selector: 'tsm-order-details',
  templateUrl: 'tsm-order-details.component.html',
  providers: [ReadOnlyService],
})
export class TsmOrderDetailsComponent implements OnInit {
  @Input() id: number;
  @Input() readOnly: boolean;

  @Output() validated = new EventEmitter();
  @Output() unvalidated = new EventEmitter();
  @Output() rejected = new EventEmitter();
  @Output() askedForModification = new EventEmitter;
  @Output() chatOpened = new EventEmitter;
  @Output() confirmed = new EventEmitter();

  reportProblemModalIsVisible = false;
  modificationModalIsVisible = false;
  modificationIsNull: boolean = false;
  order: TransformOrder;
  tsmOrders: TransformOrder [] = [];
  tsmList: any = new Object;
  location: TransformLocation;
  TRANSFORM_ORDER_STATUS: TRANSFORM_ORDER_STATUS;
  files: UploadedDocument[] = [];
  TRANSFORM_TYPE = TRANSFORM_TYPE;
  get isOrderConfirmed(): boolean {
    return this.order.status === ECOSYSTEM_STEP_STATUS.WAITING_FOR_CONFIRMATION;
  }

  constructor(
              
              private _translateService: TranslateService,
              public datePipe: DatePipe,
              private _transformOrderService: TransformOrderService,
              private _transfromLocationService: TransformLocationService,
              private readOnlyService: ReadOnlyService,
              private _globalEvents: GlobalEventsManager,
              private _currentUserService: CurrentUserService,
              private translate: TranslateService,
              private mdDialog: MdDialog) {
  }

  ngOnInit() {
    this.readOnlyService.readOnly = this.readOnly;

    
    this
      ._transformOrderService
      .view(this.id, {expand: 'transformation,in_order_passport,out_order_passport,creator_id,TSM_price,creator,orders_common_step'})
      .subscribe((res: TransformOrder) => {
        this.order = res;
        this._transformOrderService.getTsmList({'step_id': this.order.step_id, expand: 'orders,tf_orders,orders.default_unit,orders.default_conversion'}).subscribe((tsmList) => {
          this.tsmList = tsmList;
          this.tsmOrders = this.tsmList[0].orders.map((order: TransformOrder) => new TransformOrder({...order, transformation: this.order.transformation}));          
        });
        if (this.order.ecosystem_file_junction) {
          this.files = this.order.ecosystem_file_junction.map((junction) => junction.ecosystem_file);
        }
        if (this.order.site_id) {
          this._transfromLocationService.view(this.order.site_id).subscribe((location: TransformLocation) => {
            this.location = location;
            this.order.loaded = true;
          });
        } else {
          this.order.loaded = true;
        }
      });
  }

  updateDate(dateString: string, datePart: Date): string {
    let date = new Date(dateString);

    date.setFullYear(datePart.getFullYear());
    date.setMonth(datePart.getMonth());
    date.setDate(datePart.getDate());

    return this.toDateString(date);
  }

  updateTime(dateString: string, datePart: Date): string {
    let date = new Date(dateString);

    date.setHours(datePart.getHours());
    date.setMinutes(datePart.getMinutes());
    date.setSeconds(datePart.getSeconds());

    return this.toDateString(date);
  }

  toDateString(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  validate() {
    this.validated.emit();
    this.order.validateByStep().subscribe();
  }

  unvalidate() {
    this.unvalidated.emit();
    this.order.in_order_passport.save().subscribe(() => {
      this.order.unvalidate().subscribe();
    });
  }

  reject() {
    this.rejected.emit();
    this.order.rejectByStep().subscribe();
  }

  confirm() {
    this.confirmed.emit();
    this.order.confirm().subscribe();
  }

  changeVisibilityOfReportProblemModal() {
    this.order.problem = '';
    this.reportProblemModalIsVisible = !this.reportProblemModalIsVisible;
  }

  changeVisibilityOfModificationModal() {
    this.order.modification = '';
    this.modificationModalIsVisible = !this.modificationModalIsVisible;
  }

  checkStatusValidated() {
    return this.order.status !== ECOSYSTEM_STEP_STATUS.STAND_BY
      && this.order.status !== ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION
      && this.order.status !== ECOSYSTEM_STEP_STATUS.ASK_FOR_MODIFICATION;
  }

  correctDateFormat(date){
    if(date !== null){
      return moment(date).format('DD-MM-YYYY');
    } else {
      return "";
    }
  }

  modificationChanged() {
    this.modificationIsNull = !this.order.modification;
  }

  reportProblem(id: number, problem: string) {
    if (!problem) {
      return;
    }
    this._transformOrderService.sendReport(id, problem).subscribe(() => {
      this.changeVisibilityOfReportProblemModal();
    });
  }

  askForModification() {
    if (!this.order.modification) {
      this.modificationIsNull = true;
      return;
    }
    this.askedForModification.emit();
    this.order.askForModificationByStep().subscribe();
  }

  openChat(targetId: number) {
    this._globalEvents.chatUserClicked$.emit(targetId);
    this.chatOpened.emit();
  }

  seeTransformation(id: number) {
    this.mdDialog.open(TransformDetailsDialogComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
      data: {
            id: id,
            readOnly: true,
            showValidationButtons: false,
        },
    });
  }

  setRowHeight(inputString: string, rowLength: number){
    let rowSize = 1;
    if(!inputString){
      return rowSize;
    } else if(inputString.length < rowLength){
      return rowSize
    } else {
      rowSize = Math.ceil(inputString.length / rowLength);
      return rowSize;
    }
  }

  isTransformationTypeOfTransport(){
    return this.order.transformation.transformation_type === TRANSFORM_TYPE.TRANSPORT
  }

  isTransformationTypeOfStorage(){
    return this.order.transformation.transformation_type === TRANSFORM_TYPE.STORAGE
  }

  isTypeOfTransportOrStorage(){
    if(this.isTransformationTypeOfStorage()){
      return true
    } else if(this.isTransformationTypeOfTransport()){
      return true
    } else {
      return false
    }
  }

  getEndOrDeliveryDateTitle(){
    let currentTransformationType = this.order.transformation.transformation_type;
    if( currentTransformationType  === TRANSFORM_TYPE.TRANSPORT){
      return "Delivery date";
    } else if( currentTransformationType === TRANSFORM_TYPE.STORAGE) {
      return "End date of storage"
    } 
  }
  
  getInputConversion(index: number) {
    return this.tsmOrders[index].getConversionForInput();
  }

  getInputName(index: number){
    if(this.tsmOrders[index].in_order_passport.name){
      return this.tsmOrders[index].in_order_passport.name
    } else {
      return this.tsmOrders[index].child_step.title
    }
  }

  getInputOrDepositsToTransportTitle(){
    let currentTransformationType = this.order.transformation.transformation_type;
    if( currentTransformationType === TRANSFORM_TYPE.TRANSPORT){
      return "Deposits to transport";
    } else if( currentTransformationType === TRANSFORM_TYPE.STORAGE) {
      return "Deposits to be stored"
    } else {
      return "Input";
    }
  }

  getInputUnit(index: number) {
    if(this.order.transformation.input_unit){
      return this.order.transformation.input_unit;
    }
     else if (this.tsmOrders[index].isWithoutUnit()) {
      return this.tsmOrders[index].unitInView; 
    }
    else {
      return PASSPORT_UNITS_TYPE.KG
    }
  }

  getOutputConversion() {
    if(this.tsmOrders[0]){
      if (this.tsmOrders[0].isWithoutUnit()) {
        return this.tsmOrders[0].conversionInDefaultView;
      } else {
        return this.order.transformation.out_conversion;
      }
    }
  }

  getOutputName(){
    if(this.order.out_order_passport.name){
      return this.order.out_order_passport.name;
    } else {
      return this._translateService.instant("Unnamed material");
    }
  }

  getOutputUnit() {
    if(this.tsmOrders[0]){
      if(this.order.transformation.output_unit){
        return this.order.transformation.output_unit;
      }
       else if (this.tsmOrders[0]._isWithoutUnit(this.tsmList[0].transformation_type)) {
        return this.tsmOrders[0].unitOutView; 
      }
      else {
        return PASSPORT_UNITS_TYPE.KG
      }
    }
  }

  getPickupAddressOrDescriptionText(index: number){
    if(this.order.transformation.transformation_type === TRANSFORM_TYPE.TRANSPORT){
      return this.tsmOrders[index].pickup_address;
    } else {
      return this.tsmOrders[index].in_order_passport.description;
    }
  }

  getPickupAddressOrDescriptionTitle(){
    if(this.order.transformation.transformation_type === TRANSFORM_TYPE.TRANSPORT){
      return "Pickup address";
    } else {
      return "Description";
    }
  }
  
  getReceptionOrPickupDateText(){
    let currentTransformationType = this.order.transformation.transformation_type;
    if( currentTransformationType  === TRANSFORM_TYPE.TRANSPORT){
      return "Pickup date";
    } else if( currentTransformationType === TRANSFORM_TYPE.STORAGE) {
      return "Start date of storage"
    }  else {
      return "Reception date";
    }
  }

  getTransformationOrderStatusText() {
    return this._translateService.instant(ECOSYSTEM_STEP_STATUS[this.order.status]);
  }
  
  checkSpanishOrPortugueseLanguageAndUnitIsPieces(unit: number){
    if(unit === PASSPORT_UNITS_TYPE.PIECE){
      if(this.translate.currentLang === 'es' || this.translate.currentLang === 'pt'){
        return true;
      }
    }
  }
}
