import * as tslib_1 from "tslib";
import { Need } from '../models/need.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var NeedService = /** @class */ (function (_super) {
    tslib_1.__extends(NeedService, _super);
    function NeedService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'needs';
        _this.namespaceSingular = 'need';
        _this.ModelClass = Need;
        return _this;
    }
    NeedService.prototype.getFileUploadUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/files');
    };
    NeedService.prototype.getPdfReportUrl = function (query) {
        var q = this.formQueryString(query);
        return this.getEndpoint(this.namespaceSingular + "/report" + (q ? '?' + q : ''));
    };
    NeedService.prototype.getPdfReport = function (query) {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/report'), { search: query })
            .catch(this.onError.bind(this));
    };
    NeedService.prototype.getUntreatedCount = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/untreated'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    NeedService.prototype.getWaiting = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/waiting'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    NeedService.prototype.sendConsultDepositLinkNotificationToSales = function (needId, depositId) {
        return this
            .sendPost(this.getEndpoint(this.namespaceSingular + '/send_consult_link_message'), { need_id: needId, deposit_id: depositId })
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    return NeedService;
}(ExtendedCrudService));
export { NeedService };
