<div class="advanced-research-dialog">
  <div class="advanced-research-dialog__close background-primary-color">
    <div>{{'ADVANCED_RESEARCH_CAPTION' | translate | uppercase}}</div>
    <div class="advanced-research-dialog__close--icon" (click)="mdDialogRef.close()"></div>
  </div>

  <div class="advanced-research-dialog__content">

    <passport-list [advancedResearchTheme]="true"
                   [hasNoPagination]="true"
                   [includeDrafts]="data.includeDrafts"
                   (onSelected)="mdDialogRef.close($event)"
    >
    </passport-list>

  </div>
</div>
