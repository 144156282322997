import { StatsContainer } from './stats-container.class';
import { ILiteral } from 'ng2-adn-common';
import { ITSMStats } from '../interfaces/tsm-stats.interface';
import { TSMStats } from './tsm-stats.class';

export class TSMStatsContainer extends StatsContainer<ITSMStats> {

  extractData(data: any): any {
    let json = super.extractData(data);
    this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
    return json;
  }

  createModel(json: ILiteral, key: string[]) {
    key.forEach((k) => {
      if (!!json[k]) {
        json[k] = json[k].map((data: ILiteral) => new TSMStats(data));
      }
    });
  }

  getTransformations() {
    let result: any = {};
    let assigned = 0, published = 0, orders_accepted = 0, orders_rejected = 0;
    if (this.total) {
      this.total.forEach(item => {
        if (item.transformations_published) {
          published += item.transformations_published;
        }
        if (item.transformations_assigned) {
          assigned += item.transformations_assigned;
        }
        if (item.orders_accepted) {
          orders_accepted += item.orders_accepted;
        }
        if (item.orders_rejected) {
          orders_rejected += item.orders_rejected;
        }
      });
    }
    result.published = published;
    result.assigned = assigned;
    result.orders_rejected = orders_rejected;
    result.orders_accepted = orders_accepted;
    return result;
  }

  getSites() {
    let result: any = {};
    let assigned = 0, published = 0;
    if (this.total) {
      this.total.forEach(item => {
        if (item.transformation_locations_assigned) {
          assigned += item.transformation_locations_assigned;
        }
        if (item.transformation_locations_published) {
          published += item.transformation_locations_published;
        }
      });
    }
    result.published = published;
    result.assigned = assigned;
    return result;
  }

  calculatePercentage(value: number, total: number): string {
    if (total === 0) {
      return '0%';
    }
    return (value / total * 100).toFixed(1) + '%';
  }
}
