<dialog-close-button (click)="mdDialogRef.close()"></dialog-close-button>
<div class="request-sent-dialog">
  <i class="form-group__label--question request-sent-dialog__icon"><div></div></i>
  <div class="request-sent-dialog__message request-sent-dialog__message-block">
    <span class="request-sent-dialog__message--request-result">
      {{'Your request has been taken into account.' | translate}}
    </span>
    <span class="request-sent-dialog__message request-sent-dialog__message--promise">
      {{'we will process it as soon as possible.' | translate | capitalize}}
    </span>
  </div>
</div>
