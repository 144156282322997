export const NEXT_USAGE:  {
  value: number,
  viewValue: string
}[] = [
  {value: 5, viewValue: 'Recycling/Upcycling'},
  {value: 7, viewValue: 'Composting'},
  {value: 8, viewValue: 'Methanization'},
  {value: 1, viewValue: 'Reuse'},
  {value: 4, viewValue: 'Refurbishing'},
  {value: 6, viewValue: 'Energy recovery'}
];
