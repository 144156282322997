<div class="report-base__sites-chips-container" *ngIf="!!_sites">
  <span class="report-base__sites-chips" *ngIf="!createdByFile && _sites?.length === 0">
      {{'All sites' | translate}}
  </span>
  <span class="report-base__sites-chips" *ngIf="!!createdByFile">
      {{'Generated by spreadsheet' | translate}}
  </span>
  <ng-container>
    <span #siteReport class="report-base__sites-chips" *ngFor="let site of _sites">
        <span class="report-base__sites-chips-name">{{site.name}}</span>
        <span  class="report-base__sites-chips-tag" *ngFor="let tag of tagPresenter.getTags(site.id)">
          {{tag.name}}
        </span>
    </span>
  </ng-container>
</div>
