/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-passport-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/material";
import * as i5 from "./generic-passport-item.component";
import * as i6 from "@angular/router";
var styles_GenericPassportItemComponent = [i0.styles];
var RenderType_GenericPassportItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericPassportItemComponent, data: {} });
export { RenderType_GenericPassportItemComponent as RenderType_GenericPassportItemComponent };
export function View_GenericPassportItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "genericpassportlist__row-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "genericpassportlist__item-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openGenericPassport(_co.genericPassport) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "genericpassportlist__item-category"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "genericpassportlist__item-material"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 2, "label", [], null, [[null, "longpress"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).show() !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).hide(1500) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 212992, null, 0, i4.MdTooltip, [i4.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i1.Renderer, i4.Platform, [2, i4.Dir]], { _deprecatedMessage: [0, "_deprecatedMessage"] }, null), (_l()(), i1.ɵted(11, null, [" ", " "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "genericpassportlist__item-unit"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "genericpassportlist__item-delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteGenericPassport(_co.genericPassport) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "genericpassportlist__item-copy"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyPassport(_co.genericPassport) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.index == 1) ? "item-odd genericpassportlist__item-info" : "item-even genericpassportlist__item-info"); _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.getMaterials(_co.genericPassport, true), ""); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getNameFromTags(_co.genericPassport); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.genericPassport.category.name)); _ck(_v, 6, 0, currVal_2); var currVal_4 = _co.getMaterials(_co.genericPassport, false); _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.genericPassport.unit_text; _ck(_v, 13, 0, currVal_5); }); }
export function View_GenericPassportItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "generic-passport-item", [], null, null, null, View_GenericPassportItemComponent_0, RenderType_GenericPassportItemComponent)), i1.ɵdid(1, 49152, null, 0, i5.GenericPassportItemComponent, [i6.Router, i4.MdDialog, i1.NgZone, i3.TranslateService], null, null)], null, null); }
var GenericPassportItemComponentNgFactory = i1.ɵccf("generic-passport-item", i5.GenericPassportItemComponent, View_GenericPassportItemComponent_Host_0, { genericPassport: "genericPassport", index: "index", zoneId: "zoneId", parentModuleName: "parentModuleName" }, { onOpen: "onOpen", onDelete: "onDelete", onCopy: "onCopy" }, []);
export { GenericPassportItemComponentNgFactory as GenericPassportItemComponentNgFactory };
