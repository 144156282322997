<div class="control-group">
  <ul
    class="control-group__container-flex control-group__list--padding justify-start"
    *ngFor="let item of listCtrl?.controls; let i = index"
    [formGroup]="listCtrl.controls[i]"
  >
    <li class="control-group__item control-group__item--margin flex2">
      <div
        [hidden]="this.isDisabled"
        class="control-group__item-button"
        (click)="removeNextUsage(i)"
      >
        <md-icon>remove</md-icon>
      </div>
    </li>

    <li
      class="control-group__item control-group__item--medium control-group__item--margin flex10"
    >
      <md-select
        formControlName="type"
        class="condition"
        placeholder="{{ 'Type' | translate }}"
        (click)="disableValue()"
        (change)="disableValue()"
      >
        <md-option *ngFor="let type of nextUsageTypes" [value]="type.value" class="type-next-usage" [disabled]="optionLoaded.includes(type.value)">
          {{ type.viewValue | translate }}
        </md-option>
      </md-select>
    </li>
    <li
      class="control-group__item control-group__item--medium control-group__item--margin control-group__item--percentage-wrapper flex30"
    >
      <md-input-container>
        <input
          mdInput
          type="number"
          autocomplete="off"
          formControlName="percentage_product"
          placeholder="{{ 'PercentageProduct' | translate }}"
        >
      </md-input-container>
      <div class="passport-edit__cycle--life-recycle-symbol">%</div>
    </li>

    <!-- Column description style="display: flex; flex-direction: column" -->
    <li class="control-group__item control-group__item--margin control-group__wrapper-column flex60">
      <!-- Component affiliateBranch -->
      <passport-affiliate-branch
      [parentFormGroup]="listCtrl.controls[i]"
      [nextUsage]="nextUsages[i]"
      (onSave)="childEventSave($event)"
      [listener]="listener"
      [passport]="passport"
      [isDisabled]="isDisabled"
      [viewMode]="viewMode"
      [affiliateBranchListener]="affiliatebranchListener[i]"
      ></passport-affiliate-branch>
      <!-- Ici fin list description and intervenant  -->
    </li>
    <!-- Fin column -->
  </ul>
  <div 
    
    class="control-group__item flex align-center control-group__item-add"
    (click)="addNew()"
  >
    <div [hidden]="this.isDisabled" [hidden]="this.noValueType" class="control-group__item-button">
      <md-icon>add</md-icon>
    </div>
    <div [hidden]="this.isDisabled" [hidden]="this.noValueType" class="control-group__item--subtext">
      {{ "add a new life" | translate }}
    </div>
  </div>
</div>
