/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./passport-family-view-label.component";
var styles_PassportFamilyViewLabel = [];
var RenderType_PassportFamilyViewLabel = i0.ɵcrt({ encapsulation: 2, styles: styles_PassportFamilyViewLabel, data: {} });
export { RenderType_PassportFamilyViewLabel as RenderType_PassportFamilyViewLabel };
function View_PassportFamilyViewLabel_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getVoidClassName(); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getTextVoidClassName(); _ck(_v, 1, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.getUnit)); _ck(_v, 2, 0, currVal_2); }); }
function View_PassportFamilyViewLabel_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "passportlist__family_wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassportFamilyViewLabel_2)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isVoid; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getClassName(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getTextClassName(); _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.getFamilyName())); _ck(_v, 3, 0, currVal_2); }); }
export function View_PassportFamilyViewLabel_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassportFamilyViewLabel_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.familyType; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PassportFamilyViewLabel_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "passport-family-view-label", [], null, null, null, View_PassportFamilyViewLabel_0, RenderType_PassportFamilyViewLabel)), i0.ɵdid(1, 49152, null, 0, i3.PassportFamilyViewLabel, [], null, null)], null, null); }
var PassportFamilyViewLabelNgFactory = i0.ɵccf("passport-family-view-label", i3.PassportFamilyViewLabel, View_PassportFamilyViewLabel_Host_0, { familyType: "familyType", display: "display", unit: "unit" }, {}, []);
export { PassportFamilyViewLabelNgFactory as PassportFamilyViewLabelNgFactory };
