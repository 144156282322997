import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformQualityJunctionService } from '../services/transform-quality-junction.service';
var TransformQualityJunction = /** @class */ (function (_super) {
    tslib_1.__extends(TransformQualityJunction, _super);
    function TransformQualityJunction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformQualityJunctionService;
        return _this;
    }
    TransformQualityJunction.prototype.fields = function () {
        return [
            'id',
            'quality_id',
            'transformation_id',
            'is_input',
            'name',
        ];
    };
    return TransformQualityJunction;
}(ActiveRecord));
export { TransformQualityJunction };
