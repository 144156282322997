import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { Reports } from '../models/reports.class';
var ReportService = /** @class */ (function (_super) {
    tslib_1.__extends(ReportService, _super);
    function ReportService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'reports';
        _this.namespaceSingular = 'report';
        _this.ModelClass = Reports;
        return _this;
    }
    ReportService.prototype.getRequestReport = function (data) {
        return this.sendPost(this.getEndpoint(this.namespaceSingular + '/generate'), data, { search: { expand: 'sites.tags' } })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ReportService.prototype.getReport = function (id, query) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular) + '/' + id, { search: query })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ReportService.prototype.getActiveReport = function () {
        return this.sendGet(this.getEndpoint(this.namespaceSingular) + "/active")
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ReportService.prototype.acknowledgeReport = function () {
        return this.sendPost(this.getEndpoint(this.namespaceSingular) + "/acknowledge")
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    ReportService.prototype.downloadRawReport = function (report_id) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular) + "/raw_report?report_id=" + report_id)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    return ReportService;
}(ExtendedCrudService));
export { ReportService };
