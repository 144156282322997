import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {IParentEvent} from '../../../../shared/interfaces/parent-event.interface';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Passport} from '../../models/passport.class';
import {MaterialService} from '../../services/material.service';
import {Material} from '../../models/material.class';
import {MdDialog} from '@angular/material';
import {ViewNomenclatureDialogComponent} from '../../dialogs/view-nomenclature/view-nomenclature.component';
import {ReadOnlyService} from '../../../../shared/read-only/read-only.service';
import {VIEW_MODE} from '../../values/view-mode-values.const';
import {ValidationService} from '../../../../shared/services/validation-service';

@Component({
  moduleId: module.id,
  selector: 'passport-materials',
  templateUrl: 'passport-materials.component.html'
})
export class PassportMaterialsComponent implements OnInit {

  @Input() public listener?: EventEmitter<IParentEvent>;
  @Input() public passport?: Passport;
  @Input() public isDisabled?: boolean = false;
  @Input() public viewMode: number;
  @Input() parentFormGroup: FormGroup;
  @Input() listCtrl: FormArray;
  @Output() itemsChanged: EventEmitter<void> = new EventEmitter();

  disableStatus: boolean = false;

  VIEW_MODE = VIEW_MODE;

  constructor(public _fb: FormBuilder,
              public materialService: MaterialService,
              public dialog: MdDialog,
              @Optional() public readOnlyService: ReadOnlyService) {

    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if (event.key === 'disableStatus') {
          this.disableStatus = event.data.isDisabled;
          this.updateDisableStatus();
        }
      });
    }
  }

  ngOnInit() {
    this.disableStatus = this.isDisabled;

    this.updateDisableStatus();
  }

  addNew(): void {
    let material = new Material();
    this.addCtrl(material);
    this.itemsChanged.emit();
  }

  removeMaterial(index: number): void {
    const materialForm: FormArray = <FormArray>this.listCtrl.controls[index];
    if (!materialForm) {
      console.error('Failed to get material form by index (' + index + '):', this.listCtrl);
    }
    let idCtrl = materialForm.get('id');
    if (idCtrl && idCtrl.value) {
      this.deleteMaterial(idCtrl.value);
    }
    materialForm.reset();
    if (index == 0 && this.listCtrl.length == 1) {
      this.disableStatus = false;
      this.updateDisableStatus();
      return;
    }
    this.listCtrl.removeAt(index);
    this.listCtrl.markAsDirty();
    if (this.listCtrl.length === 0) {
      this.addNew();
    }
    this.itemsChanged.emit();
  }

  addCtrl(material: Material): void {
    this.listCtrl.push(this._fb.group({
      id: [material.id],
      name: [material.nomenclature ? material.nomenclature.name : ''],
      nomenclatureId: [material.nomenclature ? material.nomenclature.id : null],
      quantity: [material.quantity, ValidationService.validateQuantity],
      // price: [material.material_cost, ValidationService.validateQuantity],
      percentage: [material.percentage ? material.percentage : null, [Validators.min(0), Validators.max(100)]]
    }));
  }

  deleteMaterial(id: number): void {
    this.materialService.remove(id).subscribe();
  }

  openNomenclatureDialog(materialCtrl: FormGroup): void {
    if (this.isDisabled) {
      return;
    }
    materialCtrl.controls.name.disable();
    let controls: any = materialCtrl.controls;
    // MdDialogConfig
    let dialogRef = this.dialog.open(ViewNomenclatureDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        controls.nomenclatureId.setValue(result.id);
        controls.name.setValue(result.name);
        controls.name.disable();
        controls.nomenclatureId.markAsDirty();
      }
    });
  }

  updateDisableStatus(): void {
    if (!this.listCtrl) {
      this.listCtrl = this._fb.array([]);
    }
    if (this.disableStatus) {
      this.listCtrl.controls.forEach(function (control) {
        control.disable();
      });
    } else {
      this.listCtrl.controls.forEach(function (control) {
        control.enable();
      });
    }
  }

  isAddButtonVisible(): boolean {
    return !this.isDisabled && this.viewMode === VIEW_MODE.DEFAULT;
  }

  getLabelClasses(field) {
    return {
      'primary-color': field,
      'label-color': !field,
      'passport-edit__color-transition': true
    };
  }

  preventLettersAndNumberGreaterThanOneHundred(event: KeyboardEvent) {
    if ((isNaN(Number(event.key)) && event.key !== '.') || event.target['value'].length > 4) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
