import { Ecosystem } from '../../models/ecosystem.class';
import { SummaryDetailAbstract } from '../../models/summary-details.intercface';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { IEcosystemIndicatorReportSteps } from '../../values/ecosystem-indicator-report.const';
import {EcosystemStep} from '../../models/ecosystem-step.class';

export abstract class  AbstractReportStrategy {
    abstract unit: string;
    abstract otherAdd(ecosystem: Ecosystem, value?: number ): number;
    abstract otherSubtract(ecosystem: Ecosystem, value?: number): number;
    abstract otherDescription(ecosystem: Ecosystem, value?: string): string;
    abstract getTotalErrors(ecosystem: Ecosystem): Array<any>;
    public setOtherTotal(value: number, callback: (value) => void) {
      if (value === null || value === 0) {
        callback(0);
      }
      if (value) {
        callback(value);
      }
    }
    public setOtherDescription(value: string, callback: (value) => void) {
      if (value === null) {
        callback(null);
      }
      if (value) {
        callback(value);
      }
    }
}

export abstract class AbstractReportStrategyWithDetails extends AbstractReportStrategy {
    abstract fileType: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE;
    abstract getSteps(allSteps: IEcosystemIndicatorReportSteps): IEcosystemIndicatorReportSteps;
    abstract formatValue<T extends SummaryDetailAbstract>(step: T): string;
    abstract isDataMissing<T extends SummaryDetailAbstract>(errors: Array<any>, step: T): boolean;

    getOrders(step: EcosystemStep) {
      return step.summary_details ? (step.summary_details.orders || []) : [];
    }
}
