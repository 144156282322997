
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>

    <deposit-site-details
      [id]="mdDialogData.id"
      [type]="mdDialogData.type"
      [userId]="mdDialogData.userId"
      [readOnly]="mdDialogData.readOnly"
      (onSave)="mdDialogRef.close($event)"
    ></deposit-site-details>
  