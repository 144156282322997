import { ActiveRecord } from '../../../shared/models/active-record.class';
import { ComponentService } from '../services/component.service';
import { DEMOUNTABLE } from '../values/demountable-values.const';
import { Material } from './material.class';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
import { LocaleService } from '../../../shared/services/locale-service';
import { ValidationService } from '../../../shared/services/validation-service';

export class PassportComponent extends ActiveRecord {
  protected provider: any = ComponentService;

  id: number;
  name: string;
  passport_id: number;
  quantity: number;
  demountable: number;
  percentage_weight: number;

  materials: Material[] = [];
  nomenclature: NomenclatureItem[] = [];

  isDefault: boolean = false;

  fields() {
    return ['id', 'name', 'quantity', 'passport_id', 'demountable', 'materials', 'nomenclature', 'percentage_weight'];
  }

  get demountableView() {
    const foundItem = (DEMOUNTABLE.find(type => type.value === this.demountable));
    return !!foundItem && foundItem.viewValue;
  }

  constructor(data?: any) {
    super(data);
    if (data) {
      this.materials = data.materials || [];
      this.nomenclature = data.nomenclature || [];
      this.isDefault = data.isDefault || false;
      this.percentage_weight = data.percentage_weight || null;
      this.demountable = data.demountable || null;
    }
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    json.materials = (json.materials || []).map((mat: any) => {
      return new Material(mat);
    });

    json.nomenclature = (json.nomenclature || []).map((nom: any) => {
      return new NomenclatureItem(nom);
    });
    return json;
  }

  beforeSave(data: any): any {
    let dataValues = super.beforeSave(data);

    if(dataValues.quantity) {
      dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
    }

    // if(typeof dataValues.quantity === 'string') {
    //   let parsedQuantity = LocaleService.tryParseToNumber(dataValues.quantity);
    //   if(typeof parsedQuantity === 'number') {
    //     dataValues.quantity = parsedQuantity;
    //   }
    // }

    return dataValues;
  }
}
