import { Injectable } from '@angular/core';
import { IConversion } from '../interfaces/conversion-state.interface';

@Injectable()
export class ConversionStateService {
  conversions: {[key: string]: IConversion} = {};

  setConversionStateDisabled(key: string) {
    return this.conversions[key] = {active: false};
  }

  getOrCreateConversionState(key: string, setDefaultConversionOff?: boolean) {
    if (this.conversions[key]) {
      return this.conversions[key];
    } else if (setDefaultConversionOff) {
      return this.conversions[key] = {active: false};
    } else {
      return this.conversions[key] = {active: true};
    }
  }
}
