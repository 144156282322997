import {Component, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ESelectionListOpenMode} from './values/selection-list-open-mode.enum';
import {ICustomSelectionListWithChipRecord} from './interface/custom-selection-list-with-chip-record';

@Component({
  selector: 'custom-selection-list-with-chip',
  templateUrl: './custom-selection-list-with-chip.component.html',
  styleUrls: ['./custom-selection-list-with-chip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomSelectionListWithChipComponent {
  LIST_OPEN_MODE = ESelectionListOpenMode;
  @Input() allVariants: ICustomSelectionListWithChipRecord[] = [];
  @Input() chosenItems: ICustomSelectionListWithChipRecord[] = [];
  @Input() disabledChip = false;
  @Input() disabledList = false;
  @Input() isItemChecked: (item) => boolean = this.defaultCheck;
  @Input() listOpenMode: ESelectionListOpenMode = ESelectionListOpenMode.INPUT_CLICK;
  @Input() placeholder = '';
  @Input() floatPlaceholder = 'auto';
  @Input() set disabled(isDisabled: boolean)  {
    this.disabledChip = isDisabled;
    this.disabledList = isDisabled;
  }
  @Output() onChange = new EventEmitter<{value: any, check: boolean}>();
  @Output() onDelete = new EventEmitter<any>();
  listIsVisible = false;

  constructor(private translateService: TranslateService) { }

  @ViewChild('checkboxListWithChip') container;
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this.container.nativeElement.contains(targetElement)) {
      this.listIsVisible = false;
    }
  }
  private defaultCheck(item) {
    return false;
  }

  openList(openMode: ESelectionListOpenMode) {
    if (openMode === this.listOpenMode) {
      this.listIsVisible = !this.disabledList;
    }
  }

  removeItem(item) {
    if (!this.disabledChip) {
      this.onDelete.emit(item);
    }
  }

  onItemChange(event, item) {
      this.onChange.emit({value: item, check: !!event['checked']});
  }
}
