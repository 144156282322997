import { StatsContainer } from './stats-container.class';
import { ILiteral } from 'ng2-adn-common';
import { IAgentStats } from '../interfaces/agent-stats.interface';
import { AgentStats } from './agent-stats.class';

export class AgentStatsContainer extends StatsContainer<IAgentStats> {

  extractData(data: any): any {
    let json = super.extractData(data);
    this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
    return json;
  }

  createModel(json: ILiteral, key: string[]) {
    key.forEach((k) => {
      if (!!json[k]) {
        json[k] = json[k].map((data: ILiteral) => new AgentStats(data));
      }
    });
  }

  getDeposits() {
    let result: any = {};
    let deposits_assigned = 0, deposits_characterized = 0, deposits_published = 0, deposits_rejected = 0;
    if (this.total) {
      this.total.forEach(item => {
        if (item.deposits_assigned) {
          deposits_assigned += item.deposits_assigned;
        }
        if (item.deposits_characterized) {
          deposits_characterized += item.deposits_characterized;
        }
        if (item.deposits_published) {
          deposits_published += item.deposits_published;
        }
        if (item.deposits_rejected) {
          deposits_rejected += item.deposits_rejected;
        }
      });
    }
    result.deposits_assigned = deposits_assigned;
    result.deposits_published = deposits_published;
    result.deposits_characterized = deposits_characterized;
    result.deposits_characterized_pct = this.calculatePercentage(deposits_characterized,deposits_published+deposits_rejected);
    result.deposits_rejected = deposits_rejected;
    result.deposits_rejected_pct = this.calculatePercentage(deposits_rejected,deposits_published+deposits_rejected);
    return result;
  }

  getSites() {
    let result: any = {};
    let locations_assigned = 0, locations_published = 0;
    if (this.total) {
      this.total.forEach(item => {
        if (item.deposit_locations_assigned) {
          locations_assigned += item.deposit_locations_assigned;
        }
        if (item.deposit_locations_published) {
          locations_published += item.deposit_locations_published;
        }
      });
    }
    result.locations_assigned = locations_assigned;
    result.locations_published = locations_published;
    return result;
  }

  calculatePercentage(value: number, total: number): string {
    if (total === 0) {
      return '0%';
    }
    return (value / total * 100).toFixed(1) + '%';
  }
}
