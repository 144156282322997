/**
 * Created by aleksandr on 28.07.17.
 */
import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { CurrentUserService } from '../../shared/services/current-user.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { ModuleGuard } from '../../shared/navigation/module.guard';
import { RESTRICTED_ADMIN_ROUTES } from './restricted.admin.route.paths';
import { ADMIN_ESM_OPTIONS } from '../../shared/values/role-ids.enum';
var AdminGuard = /** @class */ (function (_super) {
    tslib_1.__extends(AdminGuard, _super);
    function AdminGuard(_navigationService, _userService, _router) {
        var _this = _super.call(this) || this;
        _this._navigationService = _navigationService;
        _this._userService = _userService;
        _this._router = _router;
        _this.context = 'admin';
        console.log('Launch AdminGuard');
        return _this;
    }
    AdminGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return _super.prototype.canActivate.call(this, route, state).do(function (activable) {
            return _this
                ._userService
                .loggedInChecked$
                .subscribe(function () {
                if (activable && _this._userService.infoData.plan_id === ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN &&
                    !RESTRICTED_ADMIN_ROUTES.includes(state.url)) {
                    _this._router.navigate(['/admin/reporting']);
                    return false;
                }
                return true;
            });
        });
    };
    return AdminGuard;
}(ModuleGuard));
export { AdminGuard };
