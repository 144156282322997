import { ActiveRecord } from '../../../shared/models/active-record.class';
import { ProductSectorService } from '../services/product-sector.service';

export class ProductSector extends ActiveRecord {
  protected provider = ProductSectorService;

  public id: number;
  public virgin_product_id: number;
  public name: string;

  protected fields() {
    return [
      'id',
      'name',
      'virgin_product_id',
    ];
  }
}
