<div class="import-spinner">
  <confirm-dialog-icon *ngIf="!!iconData"
                       class="import-spinner__close"
                       [iconData]="iconData"
                       (click)="dialogRef.close()"
                       [attr.aria-label]="'hide'"
  ></confirm-dialog-icon>
  <div class="import-spinner__caption">
    {{progressLabel | translate}}
    <div class="import-spinner__checking-error">{{stepCurrent}} / {{stepMax}} </div>
    <div [ngClass]="{'import-spinner__checking-error': true
      , 'import-spinner__checking-error--hidden': !stepLabel[stepCurrent]}">
        {{stepLabel[stepCurrent] | translate}}
    </div>
  </div>
  <div class="import-spinner__wrapper">
    <md-progress-spinner mode="indeterminate"></md-progress-spinner>
    <div class="import-spinner__percent" *ngIf="isPercentageShown">
      {{mdDialogData.percentage.getValue() || 0}}%
    </div>
  </div>
  <div class="import-spinner__footer">
    <div class="import-spinner__attention primary-color border-primary-color" *ngIf="!!footerText">!</div>
    <div *ngIf="!!footerText"
      class="import-spinner__attention--text">
      {{footerText | translate}}
    </div>
    <div class="import-spinner__footer-totals" *ngIf="isTotalsShown">
      {{currentTotalText | translate : {X: pseudoCurrentTotal,Y: mdDialogData.totals.getValue()} }}
    </div>
  </div>
</div>
