export enum ECOSYSTEM_STATUS {
  DRAFT = 1,
  CREATED = 2,
  WAITING_FOR_QUOTE = 3,
  WAITING_FOR_CLIENT_VALIDATION = 4,
  IN_PROCESS = 5,
  DONE = 6,
  REFUSED = 7,
  WAITING_FOR_ORDER_VALIDATION = 8
}

export const ECOSYSTEM_STATUS_VIEW = {
  1: 'Draft',
  2: 'Published',
  3: 'Waiting for offer validation',
  4: 'Waiting for offer validation',
  5: 'In progress',
  6: 'Done',
  7: 'Refused',
  8: 'Waiting for orders validation'
};

export const ECOSYSTEM_STATUS_FILTER_VIEW = {
  1: 'Draft',
  2: 'Published',
  3: 'Published',
  4: 'Waiting for validation',
  5: 'In progress',
  6: 'Done',
  7: 'Refused',
  8: 'Waiting for orders validation'
};

export const ECOSYSTEM_STATUS_COLOR = {
  1: 'grey',
  2: 'grey',
  3: 'blue',
  4: 'blue',
  5: 'green',
  6: 'green',
  7: 'red',
  8: 'blue'
}

export enum EEcosystemResend {
 ASK_FOR_VALIDATION_AGAIN = 1,
 ASK_FOR_OFFER_VALIDATION_AGAIN = 2,
 ASK_FOR_CONFIRMATION_AGAIN = 3,
 ASK_FOR_OFFER_CONFIRMATION_AGAIN = 4,
}
