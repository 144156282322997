import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformMaterial } from '../models/transform-material.class';
var TransformMaterialService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformMaterialService, _super);
    function TransformMaterialService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-materials';
        _this.ModelClass = TransformMaterial;
        return _this;
    }
    return TransformMaterialService;
}(CrudService));
export { TransformMaterialService };
