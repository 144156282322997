/**
 * Created by aleksandr on 9.08.17.
 */
import { ILiteral } from 'ng2-adn-common';

export class CustomLogger<T> {

  private _styles: ILiteral;

  constructor(private _reference: T,
              private _prefix: string,
              customStyles: ILiteral = {}) {

    this._styles = Object.assign({
      'background': 'white',
      'color': '#350061',
      'padding': '3px 5px 3px 5px',
      'border-radius': '3px'
    }, customStyles);
  }

  get styles() {
    return Object.keys(this._styles).map((key) => `${key}:${this._styles[key]}`).join(';');
  }

  log(...args: any[]): T {
    console.log('%c ' + this._prefix, this.styles, ...args);
    return this._reference;
  }
  warn(...args: any[]): T {
    console.warn('%c ' + this._prefix, this.styles, ...args);
    return this._reference;
  }
}
