import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 10.02.17.
 */
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepositService } from '../../services/deposit.service';
import { Deposit } from '../../models/deposit.class';
import { DEPOSIT_STATUSES } from '../../values/deposit-statuses.const';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MdDialog } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { DepositDeleteModalComponent } from '../../../../dashboards/agent/dialogs/deposit-delete-dialog/deposit-delete-modal.component';
import { DepositDeleteWarningComponent } from '../../../../dashboards/agent/dialogs/deposit-delete-warning/deposit-delete-warning.component';
// import {DepositImportDialogComponent} from '../../../../dashboards/agent/dialogs/deposit-import-dialog/deposit-import-dialog.component';
import { DepositImportService } from '../../services/deposit-import.service';
import { FilterStateService } from '../../../../shared/services/filterState.service';
import { ConditionEnum } from '../../../../shared/interfaces/filter-states.interface';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { DepositLocationService } from '../../services/deposit-location.service';
import { ViewCategoryDialogComponent } from '../../../passport/dialogs/view-category/view-category.component';
import { DepositFilter } from '../../models/deposit-filter';
import { PaginationUnmarshallingService } from '../../../../shared/services/pagination-unmarshalling.service';
import { DEPOSIT_SORT_NAME } from '../../values/deposit-sort.enum';
import { DepositDeleteAllModalComponent } from '../../../../dashboards/agent/dialogs/deposit-delete-all-dialog/deposit-delete-all-modal.component';
import { EDepositFilterStatusChangeType } from './deposit-filter-status/deposit-filter-status-change-type.const';
import { DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES } from '../../values/deposit-reuse-source.const';
import { cloneDeep, omit } from 'lodash';
import { DepositMassActionDialogComponent } from '../../dialogs/deposit-mass-action/deposit-mass-action-dialog.component';
// import {DepositCategoryFilter} from "../../pipes/deposit-category-filter.pipe";
var DepositListComponent = /** @class */ (function () {
    function DepositListComponent(_dialog, _router, _service, depositImportService, _translate, router, stateService, navBarStateService, sitesService, paginationService) {
        var _this = this;
        this._dialog = _dialog;
        this._router = _router;
        this._service = _service;
        this.depositImportService = depositImportService;
        this._translate = _translate;
        this.router = router;
        this.stateService = stateService;
        this.navBarStateService = navBarStateService;
        this.sitesService = sitesService;
        this.paginationService = paginationService;
        this.depositSourceList = DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES;
        this.filterStatuses = [
            DEPOSIT_STATUSES.DRAFT,
            DEPOSIT_STATUSES.WAITING_FOR_VALIDATION,
            DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION,
            DEPOSIT_STATUSES.ASK_FOR_MODIFICATION,
            DEPOSIT_STATUSES.PUBLISHED,
            DEPOSIT_STATUSES.REJECTED,
        ];
        this.statusChangeType = EDepositFilterStatusChangeType;
        this.sort_order = DEPOSIT_SORT_NAME;
        this.records = [];
        this.checkedItems = [];
        this.pageCheckCounter = 0;
        this.isAllPageMode = false;
        this.depositFilter = new DepositFilter();
        // public isDeletable = false;
        this.isFilterStateOfUseVisible = false;
        this.sites = [];
        this.searchSites = '';
        this.isAnyPassportRatingSelected = false;
        this.resetFilters = cloneDeep(omit(this.depositFilter, this.depositFilter.noneResetValues));
        this.isLoaded = false;
        // console.info('Launch  -> DepositList');
        this._translate.get('CONFIRM_DELETE_DEPOSIT').subscribe(function (translation) {
            _this._confirmDeletionText = translation;
        });
    }
    DepositListComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.stateService.depositList) {
            this.depositFilter = new DepositFilter(this.stateService.depositList);
        }
        else {
            this.startAllCheckboxes();
        }
        this.searchChange(false);
        this
            .depositFilter.searchControl
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function () {
            // const shouldBeDeletable = this.depositFilter.searchControl.value !== ""
            _this.searchChange();
        });
        this.sitesService
            .get({ expand: 'tags', sort: 'name', agent: 1, 'assigned': 1 })
            .subscribe(function (data) {
            _this.sites = data;
            _this.filteredSites = data;
        });
        this.startAllCheckboxes(this.resetFilters);
    };
    DepositListComponent.prototype.openRecord = function (record) {
        this._router.navigate(['agent/deposits', record.id]);
    };
    DepositListComponent.prototype.duplicateRecord = function (record) {
        var _this = this;
        record.duplicate().subscribe(function (res) {
            _this.openRecord(res);
        });
    };
    DepositListComponent.prototype.deleteRecord = function (record) {
        var _this = this;
        record.canDelete().flatMap(function (canDelete) {
            if (canDelete) {
                return _this._dialog.open(ConfirmDialogComponent, {
                    data: { text: _this._confirmDeletionText }
                }).afterClosed();
            }
            else {
                return Observable.of(false);
            }
        }).flatMap(function (confirmed) {
            return confirmed ? record.destroy().map(function () { return true; }) : Observable.of(false);
        }).subscribe(function (deleted) {
            if (deleted) {
                _this.records.splice(_this.records.indexOf(record), 1);
            }
        });
    };
    DepositListComponent.prototype.searchChange = function (deletable, isSortAction) {
        if (deletable === void 0) { deletable = true; }
        if (isSortAction === void 0) { isSortAction = false; }
        if (!isSortAction) {
            this.resetCheckedItems();
            this.resetAllPageMode();
        }
        this.updateRecords();
    };
    DepositListComponent.prototype.pageChange = function (page) {
        this.depositFilter.currentPage = page;
        this.updateRecords();
    };
    DepositListComponent.prototype.buildOptions = function () {
        var options = this.depositFilter.buildOptions();
        options = this.depositFilter.buildRating(options);
        if (this.depositFilter.searchStatus) {
            var draftIndex = this.depositFilter.searchStatus.indexOf(DEPOSIT_STATUSES.DRAFT.value);
            if ((!!this.depositFilter.searchStatus.length && (draftIndex === -1)) || this.depositFilter.isOutOfStockSelected) {
                options.push(['instock', Number(!this.depositFilter.isOutOfStockSelected)]);
            }
            this.depositFilter.searchStatus.forEach(function (stat) { return options.push(['status[]', stat]); });
        }
        if (this.depositFilter.isOutOfStockSelected) {
            options.push(['out_of_stock', true]);
        }
        if (this.navBarStateService.multilanguage) {
            options.push(['english', true]);
        }
        if (this.isDeletable) {
            this.stateService.depositList = this.depositFilter;
        }
        return options;
    };
    DepositListComponent.prototype.buildDeleteBodyRequest = function () {
        return {
            search: this.depositFilter.searchControl.value,
            status: this.depositFilter.searchStatus,
            condition: Object.entries(this.depositFilter.stateOfUse).filter(function (z) { return z[1]; }).map(function (s) { return ConditionEnum[s[0]]; }),
            sites: this.depositFilter.selectedSites.map(function (s) { return s.id; }),
            tags: this.depositFilter.selectedTags.map(function (s) { return s.id; }),
            categories: this.depositFilter.categoryTags.map(function (c) { return c.id; }),
            instock: Number(!this.depositFilter.isOutOfStockSelected),
            available_to: this.depositFilter.availabilityDateTo,
            available_from: this.depositFilter.availabilityDateFrom,
            all_selected: this.isAllPageMode,
            deposits_ids: this.checkedItems
        };
    };
    DepositListComponent.prototype.updateRecords = function () {
        var _this = this;
        var options = this.buildOptions();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.depositFilter.searchStatus.length > 0) {
            this.isLoaded = false;
            this.subscription = this._service.getWithArray(options).subscribe(function (res) {
                _this.depositFilter.updateRecords(res, _this.paginationService.unmarshall(res));
                _this.records = _this.depositFilter.records.map(function (item) { return new Deposit(item); });
                console.log('New records comes', _this.records.map(function (r) { return r.id; }).join(','));
                _this.pageCheckCounter = _this.countCheckedItems(_this.records);
                _this.isLoaded = true;
                // if (this.depositFilter.totalCount === 0) { this.isDeletable = false; }
            }, function () { return _this.isLoaded = true; });
        }
        else {
            this.isLoaded = true;
            this.records = [];
            this.depositFilter.currentPage = 1;
            this.depositFilter.totalCount = 0;
            this.depositFilter.perPage = 1;
            // this.isDeletable = false;
        }
    };
    DepositListComponent.prototype.isDeletableStatus = function ($event) {
        return $event === this.statusChangeType.ALL_SELECTED ? this.depositFilter.isAllSelected : undefined;
    };
    DepositListComponent.prototype.startAllCheckboxes = function (filter) {
        if (filter === void 0) { filter = this.depositFilter; }
        filter.searchStatus = this.filterStatuses.map(function (status) { return status.value; });
        filter.isOutOfStockSelected = false;
        filter.isAllSelected = false;
    };
    /**
     * Ask and open the popup to delete many deposits
     */
    DepositListComponent.prototype.askForDepositDeleteModal = function () {
        var _this = this;
        this._dialog.open(DepositDeleteModalComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: {
                count: this.getCheckedItemsNumber(),
                options: this.buildDeleteBodyRequest()
            } })).afterClosed().subscribe(function (data) {
            if (data.deposits && data.deposits.length > 0) {
                _this.depositsNotDeletedInfoModal(data.deposits);
            }
            _this.updateRecords();
            if (data.resetSelection) {
                _this.resetCheckedItems();
                _this.resetAllPageMode();
            }
        });
    };
    DepositListComponent.prototype.depositsNotDeletedInfoModal = function (deposits) {
        this._dialog.open(DepositDeleteWarningComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { data: { deposits: deposits } }));
    };
    DepositListComponent.prototype.importDeposits = function () {
        this._router.navigate(['agent/deposits/import']);
        // TODO delete this commented setion
        // this._dialog.open(DepositImportDialogComponent, {
        //   ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
        //   data: {},
        // }).afterClosed().subscribe((imported: boolean) => {
        //   if (imported) {
        //    setTimeout(() => {
        //      this.searchChange(this.isDeletable);
        //    }, 1000);
        //   }
        //   });
    };
    DepositListComponent.prototype.removeSite = function (site) {
        this.depositFilter.selectedSites = this.depositFilter.selectedSites.filter(function (d) { return d.id !== site.id; });
        this.filteredSites = this.filterSelectedSites(this.sites);
        this.searchChange();
    };
    Object.defineProperty(DepositListComponent.prototype, "canClearFiltersAlternativeRules", {
        get: function () {
            return {
                filterBy: {
                    C2cCertified: true,
                    composition: { color: true, black: true },
                    cycle: { color: true, black: true },
                    energy: { color: true, black: true },
                    water: { color: true, black: true },
                    social: { color: true, black: true },
                    passportWithoutRating: true,
                    selectRatingFilter: true,
                },
                searchControl: ''
            };
        },
        enumerable: true,
        configurable: true
    });
    DepositListComponent.prototype.clearFilters = function ($event) {
        console.log($event);
        this.depositFilter = Object.assign(this.depositFilter, $event);
        this.searchChange();
    };
    DepositListComponent.prototype.selectedSite = function (site) {
        this.searchSites = '';
        this.depositFilter.selectedSites.push(site);
        this.filteredSites = this.filterSelectedSites(this.sites);
        this.searchChange();
    };
    DepositListComponent.prototype.filterSelectedSites = function (list) {
        var _this = this;
        return list.filter(function (site) { return !_this.depositFilter.selectedSites.some(function (selected) { return site.id === selected.id; }); });
    };
    Object.defineProperty(DepositListComponent.prototype, "isAnyStateOfUseFilterApplied", {
        get: function () {
            var _this = this;
            return Object.keys(this.depositFilter.stateOfUse).some(function (key) { return !_this.depositFilter.stateOfUse[key]; });
        },
        enumerable: true,
        configurable: true
    });
    DepositListComponent.prototype.openCategoryDialog = function (categorie) {
        var _this = this;
        categorie.blur();
        var dialogRef = this._dialog.open(ViewCategoryDialogComponent, {
            height: '80%',
            width: '80%',
            position: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.depositFilter.categoryTags.push(result);
                _this.searchChange();
            }
        });
    };
    DepositListComponent.prototype.removeCategoryTag = function (tag, event) {
        event.stopPropagation();
        event.preventDefault();
        this.depositFilter.categoryTags.splice(this.depositFilter.categoryTags.indexOf(tag), 1);
        this.searchChange();
    };
    Object.defineProperty(DepositListComponent.prototype, "categoryPlaceholder", {
        get: function () {
            return this.depositFilter.categoryTags.length === 0 ? 'Product category' : '';
        },
        enumerable: true,
        configurable: true
    });
    DepositListComponent.prototype.newDeposit = function () {
        this._router.navigate(['agent/deposits/new']);
    };
    DepositListComponent.prototype.deleteAllDeposits = function () {
        this.openAllDepositDeletionModal();
    };
    DepositListComponent.prototype.openAllDepositDeletionModal = function () {
        var _this = this;
        this._dialog.open(DepositDeleteAllModalComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY)).afterClosed().subscribe(function (deposits) {
            if (deposits && deposits.length > 0) {
                _this.depositsNotDeletedInfoModal(deposits);
            }
            _this.updateRecords();
        });
    };
    Object.defineProperty(DepositListComponent.prototype, "isDeletable", {
        get: function () {
            var _this = this;
            return (this.depositFilter.searchControl.value && this.depositFilter.searchControl.value !== "")
                || this.depositFilter.availabilityDateTo !== ""
                || this.depositFilter.availabilityDateFrom !== ""
                || this.depositFilter.categoryTags.length !== 0
                || Object.values(this.depositFilter.stateOfUse).reduce(function (acc, curr) { return curr || acc; }, false)
                || this.depositFilter.selectedSites.length !== 0
                ||
                    !(this.depositFilter.searchStatus.length === this.filterStatuses.length
                        && this.filterStatuses.every(function (el) { return _this.depositFilter.searchStatus.includes(el.value); }))
                || this.depositFilter.isOutOfStockSelected
                || this.depositFilter.reuseSource.length
                || this.isAnyPassportRatingSelected;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Toggle the checked status of a deposit by adding it or removing it from the checked list
     * If we are in all page mode, we add it to the list if the item is getting unchecked
     * Else, we add it to the list if the item is getting checked
     * @param {Number} id - The id of the element to toggle
     */
    DepositListComponent.prototype.toggleChecked = function (id) {
        var isChecked = this.isChecked(id);
        if (!this.isAllPageMode && isChecked || this.isAllPageMode && !isChecked) {
            this.removeIdFromChecklist(id);
        }
        else {
            this.addIdToChecklist(id);
        }
    };
    /**
     * Check whether there is at least 1 element checked, but not all
     * @returns {Boolean} True if the amount of checked elements is between 1 and the number of elements on the page
     */
    DepositListComponent.prototype.isPartiallyChecked = function () {
        return this.pageCheckCounter > 0 && this.pageCheckCounter < this.records.length;
    };
    /**
     * Check whether all records are checked in the page or not
     * If we are in all page mode, check if no elements are in the list
     * Else, check if all elements of the page are in the list
     * @returns {Boolean}
     */
    DepositListComponent.prototype.isAllChecked = function () {
        return this.isAllPageMode
            ? this.pageCheckCounter === 0
            : this.pageCheckCounter === this.records.length && this.records.length !== 0;
    };
    /**
     * Add the id of a record to the checklist
     * @param {Number} id - The id of the deposit to add
     */
    DepositListComponent.prototype.addIdToChecklist = function (id) {
        this.checkedItems.push(id);
        this.pageCheckCounter++;
    };
    /**
     * Remove the id of a record from a checklist
     * @param {Number} id - The id of the deposit to remove from the list
     */
    DepositListComponent.prototype.removeIdFromChecklist = function (id) {
        this.checkedItems.splice(this.checkedItems.indexOf(id), 1);
        this.pageCheckCounter--;
    };
    /**
     * Handle the action when user clicks on the check all button
     * - If all the records have been checked on the page
     *   x If the all page mode is activated, add all the records present on the page from the checklist
     *   x If the all page mode is deactivated, remove all the records present on the page from the checklist
     * - If not all the records have been checked on the page
     *   x If the all page mode is activated, remove all the ids not yet checked to the checklist
     *   x If the all page mode is deactivated, add all the ids not yet checked to the checklist
     */
    DepositListComponent.prototype.toggleCheckAll = function () {
        var _this = this;
        if (this.isAllChecked()) {
            this.records.forEach(function (record) {
                _this.isAllPageMode ? _this.addIdToChecklist(record.id) : _this.removeIdFromChecklist(record.id);
            });
        }
        else {
            this.records.forEach(function (record) {
                if (!_this.isChecked(record.id)) {
                    _this.isAllPageMode ? _this.removeIdFromChecklist(record.id) : _this.addIdToChecklist(record.id);
                }
            });
        }
    };
    /**
     * Count how many items from the record list are already present in the checked list
     * @param {Array<>} records - The list of records to check
     * @return {number} The number of records which have already been checked
     */
    DepositListComponent.prototype.countCheckedItems = function (records) {
        var _this = this;
        return records.filter(function (record) { return _this.checkedItems.includes(record.id); }).length;
    };
    /**
     * Check whether a deposit with a given id is checked or not
     * If all page mode is activated, an item is checked if it is not part of the checkedItems list
     * If all page mode is deactivated, an item is checked if it is part of the checkedItems list
     * @param {Number} id - The id of the element to check
     * @returns {Boolean} true if the element is checked, false otherwise
     */
    DepositListComponent.prototype.isChecked = function (id) {
        var isItemInList = this.checkedItems.includes(id);
        return !this.isAllPageMode && isItemInList || this.isAllPageMode && !isItemInList;
    };
    /**
     * Reset the list of checked items by setting it to an empty list and thus resetting the counter
     */
    DepositListComponent.prototype.resetCheckedItems = function () {
        this.checkedItems = [];
        this.pageCheckCounter = 0;
    };
    /**
     * Reset all page mode and set it to false
     */
    DepositListComponent.prototype.resetAllPageMode = function () {
        this.toggleAllPageMode(false);
    };
    /**
     * Check if at least one item has been checked
     * @returns {Boolean}
     */
    DepositListComponent.prototype.areSomeItemChecked = function () {
        return !this.isAllPageMode && this.checkedItems.length !== 0 || this.isAllPageMode && this.checkedItems.length !== this.depositFilter.totalCount;
    };
    /**
     * Check whether we should display the check all header
     * We display the header if at least one element has been checked
     * @returns {Boolean}
     */
    DepositListComponent.prototype.shouldDisplayCheckAllHeader = function () {
        return this.areSomeItemChecked();
    };
    /**
     * Check whether we should display the check all message or not
     * We display the message if not all elements have been checked
     * @returns {Boolean}
     */
    DepositListComponent.prototype.shouldDisplayCheckAllMessage = function () {
        return !this.isAllPageMode && this.checkedItems.length !== this.depositFilter.totalCount || this.isAllPageMode && this.checkedItems.length !== 0;
    };
    /**
     * Get the first part of the check all message
     * This corresponds to the number of items currently selected
     * @returns {String}
     */
    DepositListComponent.prototype.getSelectedDepositsMessage = function () {
        var numberOfCheckedItems = this.getCheckedItemsNumber();
        return this._translate.instant("NUMBER_OF_DEPOSITS_MESSAGE", { X: numberOfCheckedItems, Y: numberOfCheckedItems > 1 ? "s" : "" });
    };
    /**
     * Get the amount of items checked
     * @returns {Number}
     */
    DepositListComponent.prototype.getCheckedItemsNumber = function () {
        return this.isAllPageMode
            ? this.depositFilter.totalCount - this.checkedItems.length
            : this.checkedItems.length;
    };
    /**
     * Get the second part of the check all message
     * This corresponds to the number of items available in the current search result
     * @returns {String}
     */
    DepositListComponent.prototype.getSelectAllMessage = function () {
        return this._translate.instant("SELECT_ALL_DEPOSITS_MESSAGE", { X: this.depositFilter.totalCount });
    };
    /**
     * Get the second part of the check all message
     * This corresponds to the number of items available in the current search result
     * @returns {String}
     */
    DepositListComponent.prototype.getDeselectAllMessage = function () {
        return this._translate.instant("DESELECT_ALL_DEPOSITS_MESSAGE", { X: this.depositFilter.totalCount });
    };
    /**
     * Activate the all page mode
     * Also reset the checked items
     */
    DepositListComponent.prototype.activateAllPageMode = function () {
        this.toggleAllPageMode(true);
        this.resetCheckedItems();
    };
    /**
     * Deactivate the all page mode
     * Also reset the checked items
     */
    DepositListComponent.prototype.deactivateAllPageMode = function () {
        this.toggleAllPageMode(false);
        this.resetCheckedItems();
    };
    /**
     * Toggle the all page mode
     * If the state parameter is passed, set the all page mode to the desired state
     * Else, toggle it (false becomes true, and true becomes false)
     * @param {Boolean} state - State we want to force the all page mode into
     * @returns {undefined}
     */
    DepositListComponent.prototype.toggleAllPageMode = function (state) {
        if (state !== null) {
            this.isAllPageMode = state;
            return;
        }
        this.isAllPageMode = this.isAllPageMode ? false : true;
    };
    /**
     * Check if we should deactivate mass modification buttons or not
     * We should deactivate mass modification buttons if there are less than TWO deposits selected
     * @returns {Boolean}
     */
    DepositListComponent.prototype.isMassModificationDisabled = function () {
        return this.isAllPageMode
            ? this.depositFilter.totalCount - this.checkedItems.length < 2
            : this.checkedItems.length < 2;
    };
    /**
     * Open the mass update modal
     */
    DepositListComponent.prototype.openMassUpdateModal = function () {
        this.openMassActionModal(true);
    };
    /**
     * Open the quick update modal
     */
    DepositListComponent.prototype.openQuickUpdateModal = function () {
        this.openMassActionModal(false);
    };
    /**
     * Open the mass action modal
     * @param {Boolean} isMassUpdate - True if we want the mass update modal, false if we want the quick update modal
     */
    DepositListComponent.prototype.openMassActionModal = function (isMassUpdate) {
        var _this = this;
        var dialogRef = this._dialog.open(DepositMassActionDialogComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { data: {
                filter: this.depositFilter,
                isMassUpdate: isMassUpdate,
                isAllSelected: this.isAllPageMode,
                depositsIds: this.checkedItems
            } }));
        dialogRef.afterClosed().subscribe(function (result) {
            _this.updateRecords();
        });
    };
    return DepositListComponent;
}());
export { DepositListComponent };
