
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>

    <deposit-details
      [id]="mdDialogData.id"
      [readOnly]="mdDialogData.readOnly"
      [isDepositOwner]="mdDialogData.isDo"
      [showValidationButtons]="mdDialogData.showValidationButtons"
      (action)="mdDialogRef.close()"
    ></deposit-details>
  