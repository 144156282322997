import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DepositLocation } from '../../../../entities/deposit/models/deposit-location.class';
import { DepositLocationService } from '../../../../entities/deposit/services/deposit-location.service';
import { BIMService } from '../../../../shared/services/bim.service';
import { DepositLocationFileService } from '../../../../entities/deposit/services/deposit-location-file.service';
import { UserService } from '../../../../shared/services/user.service';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';
import { AnimationEvent } from '@angular/animations';
import { MdDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AdminWarningConfirmDialogComponent } from '../../dialogs/admin-warning-confirm/admin-warning-confirm-dialog/admin-warning-confirm-dialog.component';
import moment from 'moment';
import { PermissionService } from '../../../../shared/services/permission.service';
import { EAdminPermission } from '../../values/admin-permission.enum';
import { DepositLocationStatusName, EDepositLocationStatus } from '../../../../entities/deposit/values/deposit-location-status.enum';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { RequestSiteCreationDialogComponent } from '../../dialogs/request-site-creation-dialog/request-site-creation-dialog.component';
import { AdminWarningWithHeaderDialogComponent } from '../../dialogs/admin-warning-confirm/admin-warning-with-header/admin-warning-with-header-dialog.component';
import { RequestSiteDeletionDialogComponent } from '../../dialogs/request-site-deletion-dialog/request-site-deletion-dialog.component';
import { RequestEsmNumberChangeDialogComponent } from '../../dialogs/request-esm-number-change-dialog/request-esm-number-change-dialog.component';
import { RequestSiteDeletionConfirmDialogComponent } from '../../dialogs/admin-warning-confirm/request-site-deletion-confirm-dialog/request-site-deletion-confirm-dialog.component';
import { RequestSentDialogComponent } from '../../dialogs/request-sent-dialog/request-sent-dialog.component';
import { RequestSiteExtendDateComponent } from '../../dialogs/request-site-extend-date/request-site-extend-date.component';
import { ESiteRequestType } from '../../values/site-request-type.enum';
var animationTime = '0.3s ease-in-out';
var AdminAdministrationComponent = /** @class */ (function () {
    function AdminAdministrationComponent(siteFileService, translate, siteService, bimService, userService, mdDialog, permission) {
        this.siteFileService = siteFileService;
        this.translate = translate;
        this.siteService = siteService;
        this.bimService = bimService;
        this.userService = userService;
        this.mdDialog = mdDialog;
        this.permission = permission;
        this.DEPOSIT_LOCATION_STATUS = EDepositLocationStatus;
        this.DEPOSIT_LOCATION_STATUS_NAME = DepositLocationStatusName;
        this.sites = [];
        this.bimTools = [];
        this.depositOwners = [];
        this.closeSites = {};
        this.maxEsm = 0;
        this.assignedEsm = 0;
        this.sitesPagination = {};
        this.sumFloorArea = 0;
        this.allowRequestButtonFocus = true;
        this.queryDepositLocationParams = { expand: 'tags,status,end-work,floor_area,files,bim_tool_id,bim_id,assigned,files.basename,files.file,files.type,use_count', supervisor: true, pagination: 1, page: 0 };
        this.isOutClick = this.isOutClick.bind(this);
    }
    AdminAdministrationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateRecords();
        this.bimService.get({ expand: 'name' }).subscribe(function (tools) { return _this.bimTools = tools; });
        this.userService.get({ role: ERoleIds.DEPOSIT_OWNER, sort: 'username' }).subscribe(function (users) { return _this.depositOwners = users; });
        this.userService.getRoleCounters({ role: ERoleIds.ADMIN }).subscribe(function (data) {
            if (data) {
                _this.maxEsm = data.max_esm || 0;
                _this.assignedEsm = data.esm_count || 0;
            }
        });
    };
    Object.defineProperty(AdminAdministrationComponent.prototype, "sitesCount", {
        get: function () {
            return this.sitesPagination.totalCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminAdministrationComponent.prototype, "isBuildAdmin", {
        get: function () {
            return this.permission.hasAppliedPermission(EAdminPermission.BUILDING_ADMIN);
        },
        enumerable: true,
        configurable: true
    });
    AdminAdministrationComponent.prototype.toggleSite = function (id) {
        this.closeSites[id] = !this.closeSites[id];
    };
    AdminAdministrationComponent.prototype.checkSpanishLanguage = function () {
        if (this.translate.currentLang === 'es') {
            return true;
        }
        ;
    };
    AdminAdministrationComponent.prototype.checkFrenchLanguage = function () {
        if (this.translate.currentLang === 'fr') {
            return true;
        }
        ;
    };
    AdminAdministrationComponent.prototype.checkPortugeseLanguage = function () {
        if (this.translate.currentLang === 'pt') {
            return true;
        }
        ;
    };
    AdminAdministrationComponent.prototype.checkIsPortugesOrSpanishConstructionSite = function (site_status) {
        if (this.checkSpanishLanguage() && site_status === EDepositLocationStatus.IN_OPERATION) {
            return 'admin-administration__site-status--width-spain-in-operation';
        }
        else if (this.checkSpanishLanguage() && site_status === EDepositLocationStatus.IN_CONSTRUCTION) {
            return 'admin-administration__site-status--width-spain-in-construction';
        }
        else if (this.checkSpanishLanguage() && site_status === EDepositLocationStatus.STORAGE) {
            return 'admin-administration__site-status--width-spain-storage';
        }
        else if (this.checkPortugeseLanguage() && site_status === EDepositLocationStatus.IN_OPERATION) {
            return 'admin-administration__site-status--width-portugese-in-operation';
        }
        else if (this.checkPortugeseLanguage() && site_status === EDepositLocationStatus.STORAGE) {
            return 'admin-administration__site-status--width-portugese-storage';
        }
        else {
            return 'admin-administration__site-status';
        }
    };
    AdminAdministrationComponent.prototype.checkSiteArea = function () {
        if (this.checkSpanishLanguage()) {
            return 'admin-administration__site-floor-area--width-spain';
        }
        else if (this.checkFrenchLanguage()) {
            return 'admin-administration__site-floor-area--width-france';
        }
        else {
            return 'admin-administration__site-floor-area';
        }
    };
    AdminAdministrationComponent.prototype.validateFocus = function ($event) {
        if (!this.allowRequestButtonFocus) {
            $event.preventDefault();
            $event.stopPropagation();
            if (event.target && 'blur' in $event.target) {
                $event.target.blur();
            }
        }
        return this.allowRequestButtonFocus;
    };
    AdminAdministrationComponent.prototype.openCreateSiteRequestDialog = function () {
        var _this = this;
        var toLang = this.toLang();
        this.mdDialog.open(RequestSiteCreationDialogComponent, tslib_1.__assign({}, DIALOG_THEME.CHANGE_REQUEST_WIDTH_IN_INTERVAL)).afterClosed()
            .do(this.isOutClick).filter(function (confirmedData) { return !!confirmedData; })
            .subscribe(function (confirmedData) {
            var data = {
                titleText: toLang('Request the creation of a new site'),
                confirmText: toLang('Yes, create site'),
                subHeaders: [toLang('Are you sure you want to create a new site?')],
                main: [toLang('This action will have an impact on your subscription invoice.')]
            };
            _this.mdDialog.open(AdminWarningWithHeaderDialogComponent, tslib_1.__assign({ data: data }, DIALOG_THEME.CHANGE_REQUEST_FIXED_WIDTH)).afterClosed()
                .subscribe(_this.sentRequestIfConfirm(_this.userService.sendRequest(confirmedData)));
        });
    };
    AdminAdministrationComponent.prototype.toLang = function () {
        var _this = this;
        return function (text) { return _this.translate.instant(text) + ''; };
    };
    AdminAdministrationComponent.prototype.sentRequestIfConfirm = function (request) {
        var _this = this;
        return function (confirm) {
            if (confirm) {
                request.subscribe(function () {
                    _this.mdDialog.open(RequestSentDialogComponent);
                });
            }
        };
    };
    AdminAdministrationComponent.prototype.isOutClick = function (data) {
        if (data === undefined) {
            this.prohibitDialogToFocusInitialEl();
        }
    };
    AdminAdministrationComponent.prototype.prohibitDialogToFocusInitialEl = function () {
        var _this = this;
        this.allowRequestButtonFocus = false;
        setTimeout(function () {
            _this.allowRequestButtonFocus = true;
        });
    };
    AdminAdministrationComponent.prototype.openDeleteSiteRequestDialog = function () {
        var _this = this;
        this.mdDialog.open(RequestSiteDeletionDialogComponent, tslib_1.__assign({ data: { sites: this.sites } }, DIALOG_THEME.CHANGE_REQUEST_PRIMARY)).afterClosed()
            .do(this.isOutClick).filter(function (confirmedSites) { return !!confirmedSites; })
            .subscribe(function (confirmedSites) {
            var numberOfDeposits = confirmedSites.reduce(function (sum, site) { return sum + (Number(site.use_count) || 0); }, 0);
            var request = {
                type: ESiteRequestType.SITE_DELETIONS,
                sites: confirmedSites.map(function (site) { return site.id; })
            };
            _this.mdDialog.open(RequestSiteDeletionConfirmDialogComponent, tslib_1.__assign({ data: { sites: confirmedSites, depositsCount: numberOfDeposits } }, DIALOG_THEME.CHANGE_REQUEST_PRIMARY)).afterClosed().subscribe(_this.sentRequestIfConfirm(_this.userService.sendRequest(request)));
        });
    };
    AdminAdministrationComponent.prototype.openEsmCountChangeDialog = function () {
        var _this = this;
        var toLang = this.toLang();
        this.mdDialog.open(RequestEsmNumberChangeDialogComponent, tslib_1.__assign({}, DIALOG_THEME.CHANGE_REQUEST_FIXED_WIDTH)).afterClosed()
            .do(this.isOutClick).filter(function (confirmedData) { return typeof confirmedData === 'number'; }).subscribe(function (confirmedData) {
            var data = {
                className: 'request-esm-count-change',
                titleText: toLang('Change the number of ecosystem manager(s)'),
                confirmText: toLang('Yes, change the number'),
                subHeaders: [toLang('Are you sure you want to change the number of Ecosystem Manager(s)?')],
                main: [toLang('This action will have an impact on the invoice for your subscription.')]
            };
            var request = { type: ESiteRequestType.ESM_NUMBER, new_number: confirmedData };
            _this.mdDialog.open(AdminWarningWithHeaderDialogComponent, tslib_1.__assign({ data: data }, DIALOG_THEME.CHANGE_REQUEST_FIXED_WIDTH)).afterClosed()
                .subscribe(_this.sentRequestIfConfirm(_this.userService.sendRequest(request)));
        });
    };
    AdminAdministrationComponent.prototype.saveSite = function (site) {
        var _this = this;
        var oldSite = new DepositLocation(tslib_1.__assign({}, site));
        setTimeout(function () {
            _this._saveSite(oldSite, site);
        });
    };
    AdminAdministrationComponent.prototype._saveSite = function (oldVersion, newVersion) {
        newVersion.save().subscribe(function () { }, function () { return Object.assign(newVersion, oldVersion); });
    };
    AdminAdministrationComponent.prototype._assignSite = function (newVersion) {
        newVersion.assign().subscribe(function () { newVersion.assigned = newVersion.user_id; }, function () { return newVersion.user_id = null; });
    };
    AdminAdministrationComponent.prototype.isSiteExpire = function (dateString) {
        return dateString ? moment().diff(moment(dateString, 'YYYY-MM-DD'), 'days') > 0 : false;
    };
    AdminAdministrationComponent.prototype.confirmChangeSite = function (site) {
        var _this = this;
        var label = this.translate.instant('Warning');
        var text = this.translate
            .instant('Once a site is assigned to a deposit owner, it will no longer be possible to change it');
        this.mdDialog.open(AdminWarningConfirmDialogComponent, { data: { text: text, warningLabel: label } })
            .afterClosed().subscribe(function (confirm) {
            confirm ? _this._assignSite(site) : site.user_id = null;
        });
    };
    AdminAdministrationComponent.prototype.animationResetTempStyles = function ($event) {
        $event.element.style.display = null;
    };
    AdminAdministrationComponent.prototype.correctDateFormat = function (date) {
        if (date !== null) {
            return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
        }
    };
    AdminAdministrationComponent.prototype.animationApplyInitStyles = function ($event) {
        $event.element.style.display = 'block';
    };
    AdminAdministrationComponent.prototype.openExtendEndOfWork = function (endDateBefore, id) {
        var _this = this;
        this.mdDialog.open(RequestSiteExtendDateComponent, tslib_1.__assign({ data: { date: endDateBefore, siteId: id } }, DIALOG_THEME.CHANGE_REQUEST_FIXED_WIDTH)).afterClosed()
            .filter(function (data) { return !!data; }).subscribe(function (request) {
            _this.userService.sendRequest(request).subscribe(function () { return _this.mdDialog.open(RequestSentDialogComponent); });
        });
    };
    AdminAdministrationComponent.prototype.log = function () {
        console.log('focus');
    };
    AdminAdministrationComponent.prototype.pageChange = function (page) {
        this.queryDepositLocationParams.page = page;
        this.sitesPagination.currentPage = page;
        this.updateRecords();
    };
    AdminAdministrationComponent.prototype.updateRecords = function () {
        var _this = this;
        this.siteService
            .getWithPaginationData(this.queryDepositLocationParams)
            .subscribe(function (res) {
            var headers = res.headers;
            _this.sitesPagination.currentPage = Number(headers.get('X-Pagination-Current-Page')) || 1,
                _this.sitesPagination.pageCount = Number(headers.get('X-Pagination-Page-Count')) || 1,
                _this.sitesPagination.perPage = Number(headers.get('X-Pagination-Per-Page')) || res.json().length,
                _this.sitesPagination.totalCount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length;
            _this.sumFloorArea = Number(headers.get('X-Pagination-Floor-Area')) || 0;
            _this.sites = res.json().map(function (dl) { return new DepositLocation(dl); });
            _this.sites.forEach(function (site) {
                if (!site.assigned) {
                    site.user_id = null;
                }
            });
        });
    };
    return AdminAdministrationComponent;
}());
export { AdminAdministrationComponent };
