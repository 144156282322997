import * as tslib_1 from "tslib";
import { StatsContainer } from './stats-container.class';
import { TSMStats } from './tsm-stats.class';
var TSMStatsContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TSMStatsContainer, _super);
    function TSMStatsContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TSMStatsContainer.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
        return json;
    };
    TSMStatsContainer.prototype.createModel = function (json, key) {
        key.forEach(function (k) {
            if (!!json[k]) {
                json[k] = json[k].map(function (data) { return new TSMStats(data); });
            }
        });
    };
    TSMStatsContainer.prototype.getTransformations = function () {
        var result = {};
        var assigned = 0, published = 0, orders_accepted = 0, orders_rejected = 0;
        if (this.total) {
            this.total.forEach(function (item) {
                if (item.transformations_published) {
                    published += item.transformations_published;
                }
                if (item.transformations_assigned) {
                    assigned += item.transformations_assigned;
                }
                if (item.orders_accepted) {
                    orders_accepted += item.orders_accepted;
                }
                if (item.orders_rejected) {
                    orders_rejected += item.orders_rejected;
                }
            });
        }
        result.published = published;
        result.assigned = assigned;
        result.orders_rejected = orders_rejected;
        result.orders_accepted = orders_accepted;
        return result;
    };
    TSMStatsContainer.prototype.getSites = function () {
        var result = {};
        var assigned = 0, published = 0;
        if (this.total) {
            this.total.forEach(function (item) {
                if (item.transformation_locations_assigned) {
                    assigned += item.transformation_locations_assigned;
                }
                if (item.transformation_locations_published) {
                    published += item.transformation_locations_published;
                }
            });
        }
        result.published = published;
        result.assigned = assigned;
        return result;
    };
    TSMStatsContainer.prototype.calculatePercentage = function (value, total) {
        if (total === 0) {
            return '0%';
        }
        return (value / total * 100).toFixed(1) + '%';
    };
    return TSMStatsContainer;
}(StatsContainer));
export { TSMStatsContainer };
