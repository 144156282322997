/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i3 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i4 from "../../components/esm-need-match/esm-need-match.component.ngfactory";
import * as i5 from "../../components/esm-need-match/esm-need-match.component";
import * as i6 from "@angular/material";
import * as i7 from "@angular/router";
import * as i8 from "../../../../entities/ecosystem/services/ecosystem.service";
import * as i9 from "../../../../entities/need/services/need.service";
import * as i10 from "../../../../entities/deposit/services/deposit.service";
import * as i11 from "../../../../shared/services/pagination-unmarshalling.service";
import * as i12 from "../../../../shared/services/log-notification.service";
import * as i13 from "@angular/common";
import * as i14 from "./esm-need-match-dialog.component";
var styles_EsmNeedMatchDialogComponent = [];
var RenderType_EsmNeedMatchDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EsmNeedMatchDialogComponent, data: {} });
export { RenderType_EsmNeedMatchDialogComponent as RenderType_EsmNeedMatchDialogComponent };
function View_EsmNeedMatchDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "h1", [["class", "md-dialog-title background-primary-color"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 1, "dialog-close-button", [["class", "md-dialog-content__close"]], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DialogCloseButtonComponent_0, i2.RenderType_DialogCloseButtonComponent)), i0.ɵdid(4, 49152, null, 0, i3.DialogCloseButtonComponent, [], { defaultPosition: [0, "defaultPosition"] }, null)], function (_ck, _v) { var currVal_2 = false; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Deposits meeting the declared needs")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 4).applyClassPosition; _ck(_v, 3, 0, currVal_1); }); }
function View_EsmNeedMatchDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["tabindex", "0"]], null, null, null, null, null))], null, null); }
function View_EsmNeedMatchDialogComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "esm-need-match", [], null, null, null, i4.View_EsmNeedMatchComponent_0, i4.RenderType_EsmNeedMatchComponent)), i0.ɵdid(1, 114688, null, 0, i5.EsmNeedMatchComponent, [i6.MdDialog, i7.Router, i0.NgZone, i1.TranslateService, i8.EcosystemService, i9.NeedService, i10.DepositService, i11.PaginationUnmarshallingService, i12.LogNotificationService], { needFilter: [0, "needFilter"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.needFilter; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EsmNeedMatchDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "md-dialog-content", [["class", "md-dialog-content--with-header md-dialog-content--with-header--proportion-l"]], [[2, "mat-dialog-content", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i6.MdPrefixRejector, [[2, i6.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i6.MdDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EsmNeedMatchDialogComponent_1)), i0.ɵdid(4, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EsmNeedMatchDialogComponent_2)), i0.ɵdid(6, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "md-dialog-content__sub-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EsmNeedMatchDialogComponent_3)), i0.ɵdid(9, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.needFilter.loaded; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.needFilter.loaded; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.needFilter.loaded; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_EsmNeedMatchDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_EsmNeedMatchDialogComponent_0, RenderType_EsmNeedMatchDialogComponent)), i0.ɵdid(1, 114688, null, 0, i14.EsmNeedMatchDialogComponent, [i9.NeedService, i6.MdDialogRef, i11.PaginationUnmarshallingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EsmNeedMatchDialogComponentNgFactory = i0.ɵccf("ng-component", i14.EsmNeedMatchDialogComponent, View_EsmNeedMatchDialogComponent_Host_0, {}, {}, []);
export { EsmNeedMatchDialogComponentNgFactory as EsmNeedMatchDialogComponentNgFactory };
