import {Component, Input} from '@angular/core';
import {Passport} from '../../models/passport.class';

@Component({
  moduleId: module.id,
  selector: 'passport-rating-display',
  templateUrl: 'passport-rating-display.component.html'
})
export class PassportRatingDisplayComponent {
  @Input() passport: Passport;
}
