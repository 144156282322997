<div class="esm-catalog">
  <section class="esm-catalog__choice type--flex">
    <ul class="esm-catalog__choice-list type--auto">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input mdInput
                 autocomplete="off" 
                 placeholder="{{'Search' | translate}}"
                 [(ngModel)]="search"
                 (ngModelChange)="getRecords()"
          >
          <!--(change)="getRecords()"-->

        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--gray-sort"></i>
        <md-select
          class="select-group"
          placeholder="{{ 'Sort by' | translate }}"
          [(ngModel)]="sortValue"
          (ngModelChange)="getRecords()"
        >
          <md-option
            *ngFor="let sortValue of sortOptions"
            [value]="sortValue.field"
          >{{ sortValue.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
    <div class="esm-catalog__right-content">
      <a (click)="openCreateDialog()" class="esm-catalog__create-new">+ {{'create new' | translate}}</a>
    </div>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
  ></custom-list>
</div>
