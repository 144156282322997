<article
  class="main-content"
>
  <section class="passport-edit__main-wrapper">
    <label class="passport__tab--label">{{ labelText | translate }}</label>
    <div class="passport-edit__tabs">

      <div class="flex">
        <button [ngClass]="getTabClasses(tab)"
             class="passport-edit__tabs--tab import-spreadsheet__tabs"
             *ngFor="let tab of ObjectValues(IMPORT_DEPOSITS_TAB)"
             (click)="onTabSelected(tab)"
             [attr.aria-label]="'Open tab ' + tab"
        >
        </button>
      </div>
    </div>

    <div class="passport__tab-wrapper import-spreadsheet__wrapper">
        <import-deposit-documents [hidden]="selectedTab !== IMPORT_DEPOSITS_TAB.DOCUMENTS"
                                  [form]="importForm"
                                  [uploadedDepositFile]="uploadedDepositFile"
                                  [documentSave]="onDocumentSave$"
                                  [updateUploadUrl]="onUpdateUploadUrl$"
                                  [depositImport]="importModel"
                                  (fileListChange)="fileAdded($event)"
                                  (onDocumentUploaded)="documentUploaded.emit($event)"
        >
          <ng-container *ngTemplateOutlet="importCheck"></ng-container>
        </import-deposit-documents>
        <import-deposit-columns [hidden]="selectedTab !== IMPORT_DEPOSITS_TAB.COLUMNS"
                                [form]="columnFieldForm"
                                [fields]="fields"
                                [userProhibitLetters]="userProhibitColumnLetters"
        >
          <ng-container *ngTemplateOutlet="importCheck; context: {column: true}"></ng-container>
        </import-deposit-columns>
        <import-deposit-table [hidden]="selectedTab !== IMPORT_DEPOSITS_TAB.TABLES"
                              [tables]="synonyms"></import-deposit-table>
      </div>
    <ng-template #importCheck let-column="column">
      <div class="import-spreadsheet__buttons"
           [ngClass]="{'import-spreadsheet__buttons--alternative-padding': !!column}">
           <button class="import-spreadsheet__buttons-item"
                  md-raised-button
                  [disabled]="isImportDisabled()"
                  (click)="onClickButton()" >
            {{ 'Import file' | translate}}
          </button>
      </div>
    </ng-template>
  </section>
</article>
