/**
 * Created by aleksandr on 29.05.17.
 */

export namespace ECOSYSTEM_STEP {
  export enum TYPE {
    NEED = 1,
    DEPOSIT = 2,
    TRANSFORM = 3,
    VIRGIN_PRODUCT = 4,
    STAKEHOLDER = 5,
  }

  export enum STATUS {
    STAND_BY = 1,
    WAITING_FOR_VALIDATION = 2,
    ASK_FOR_MODIFICATION = 3,
    PENDING = 4,
    VALIDATED = 5,
    CONFIRMED = 6,
    PROBLEM_REPORTED = 7,
    REJECTED = 8,
    DONE = 9,
  }

  export const VALIDATABLE_STEPS: TYPE[] = [
    TYPE.DEPOSIT,
    TYPE.TRANSFORM
  ];

  export const CONFIRMABLE_STEPS: TYPE[] = [
    TYPE.DEPOSIT,
    TYPE.TRANSFORM,
    TYPE.VIRGIN_PRODUCT,
    TYPE.STAKEHOLDER
  ];

  export function IS_VALIDATABLE_STEP(typeId: TYPE) {
    return VALIDATABLE_STEPS.includes(typeId);
  }

  export function IS_CONFIRMABLE_STEP(typeId: TYPE) {
    return CONFIRMABLE_STEPS.includes(typeId);
  }
}

//BACKWARD COMPATIBILITY
export enum ECOSYSTEM_STEP_TYPE {
  NEED = ECOSYSTEM_STEP.TYPE.NEED,
  DEPOSIT = ECOSYSTEM_STEP.TYPE.DEPOSIT,
  TRANSFORM = ECOSYSTEM_STEP.TYPE.TRANSFORM,
  VIRGIN_PRODUCT = ECOSYSTEM_STEP.TYPE.VIRGIN_PRODUCT,
  STAKEHOLDER = ECOSYSTEM_STEP.TYPE.STAKEHOLDER,
}

export const ECOSYSTEM_STEP_TYPE_MAP = {
  [ECOSYSTEM_STEP.TYPE.NEED]: 'Need',
  [ECOSYSTEM_STEP.TYPE.DEPOSIT]: 'Deposit',
  [ECOSYSTEM_STEP.TYPE.TRANSFORM]: 'Transform',
  [ECOSYSTEM_STEP.TYPE.VIRGIN_PRODUCT]: 'Virgin product',
  [ECOSYSTEM_STEP.TYPE.STAKEHOLDER]: 'Stakeholder'
};
