/**
 * Created by aleksandr on 30.06.17.
 */
import { IChatConnectResponse, IChatRoom, ISendMessageResponse } from '../interfaces/chat-room.interface';
import { IChatMessage } from '../interfaces/chat-message.interface';
import { Observable } from 'rxjs/Observable';
import { ChatMessage } from './chat-message.model';
import { ChatUser } from './chat-user.model';
import { IChatUser } from '../interfaces/chat-user.interface';
import { Thread } from './thread.model';
import { ChatData } from './chat-data.class';
import { Subscription } from 'rxjs/Subscription';

export class ChatRoom extends Thread implements IChatRoom {

  id: number;
  user_id: number;
  messages: ChatMessage[] = [];
  contacts: ChatUser[] = [];
  unreadMessages: number = 0;

  messageSubscription: Subscription;

  isConnected: boolean = false;

  isActiveRoom() {
    return ChatData.activeRoom && ChatData.activeRoom.id === this.id;
  }

  loadContacts(): Observable<ChatUser[]> {
    return super.loadContacts().do((contacts: ChatUser[]) => this.onLoadContacts(contacts));
  }

  readRoom(): any {
    return super.readThread().do(() => this.onReadThread());
  }

  loadMessages(): Observable<ChatMessage[]> {
    return super.loadMessages().do((messages) => this.onLoadMessages(messages));
  }

  sendMessage(text: string): Observable<ISendMessageResponse> {
    return super.sendMessage(text).do((response: ISendMessageResponse) => this.onSendMessage(response));
  }

  setContacts(contacts: ChatUser[]) {
    this.contacts = contacts;
  }

  getMessageAuthor(authorId: number): IChatUser {
    let author: IChatUser = this.contacts.find((contact: IChatUser) => contact.id === authorId);
    if (this.service.ownerId === authorId) {
      //console.log('Author not found', authorId, this.contacts);
      author = {
        id: this.service.ownerId,
        image: (author) ? author.image : this.service.ownerAvatar,
        name: 'Me',
        isMe: true,
        new_messages: 0
      };
    } else {
      if (author) {
        author.isMe = false;

      } else {
        console.log('Cant detect who is authorId:', authorId, this.contacts);
      }

    }
    return author;
  }

  connect(): Observable<IChatConnectResponse> {
    if(this.isConnected) {
      return null;
    }
    console.log('ChatRoom id #', this.id, 'connecting ...');
    this.build();
    this.onConnect({});
    return null;
    // return this
    //   .build()
    //   .service
    //   .joinRoom(this.id)
    //   .do((response: IChatConnectResponse) => this.onConnect(response));
  }

  disconnect() {
    console.log('ChatRoom id #', this.id, 'has been disconnected');
    if(this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    this.isConnected = true;
  }

  checkIfAuthorExist(message: ChatMessage): Observable<any> {
    let hasAuthor = Boolean(this.getMessageAuthor(message.authorId));
    return hasAuthor ? Observable.of(hasAuthor) : this.loadContacts();
  }

  onConnect(response: IChatConnectResponse): void {
    console.log('ChatRoom id #', this.id, 'has been connected');
    if(this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    this.messageSubscription = this
      .service
      .messageReceived$
      .filter((message: IChatMessage) => message.roomId === this.id)
      .do((message: ChatMessage) => console.log('Room id #', this.id, 'incoming message:', message))
      .flatMap((message: ChatMessage) => this.checkIfAuthorExist(message).map(() => message))
      .subscribe((message: ChatMessage) => this.onMessageReceive(message));
    this.isConnected = true;
  }

  onMessageReceive(message: ChatMessage): void {
    message.setAuthor(this.getMessageAuthor(message.authorId));

    //TODO temp, for test
    if(message.file && message.file.length > 0) {
      message.body = 'FILE: ' + message.file[0].basename;
    }

    this.messages.push(message);
    if (this.isActiveRoom()) {
      this.readThread().subscribe(
        ()=> this.scrollToBottom());
    } else if(!message.isOwner) {
      this.unreadMessages++;
    }
  }

  onReadThread(): void {
    this.unreadMessages = 0;
    this.scrollToBottom();
  }

  scrollToBottom():void{
    let messageListRef=document.getElementById("messageList");
    console.log("label", messageListRef);
    console.dir("label", messageListRef);
    messageListRef.scrollTop = messageListRef.scrollHeight + 200;
  }

  onLoadContacts(contacts: ChatUser[]): void {
    this.setContacts(contacts);
  }

  onLoadMessages(messages: ChatMessage[]): void {
    if (!messages) {
      messages = [];
    }
    this.messages.length = 0;
    messages.forEach((message) => {
      message.setAuthor(this.getMessageAuthor(message.authorId));

      //TODO temp, for test
      if(message.file && message.file.length > 0) {
        message.body = 'FILE: ' + message.file[0].basename;
      }
      this.messages.push(message);
      // if(this.messages.length  === messages.length) {
      //   this.scrollToBottom();
      // }
    });
    //this.messages = messages;
  }

  onSendMessage(response: ISendMessageResponse): void {
    // this
    //   .service
    //   .loadHistoryMessage(response.thread_id, response.id)
    //   .subscribe((message:IChatMessage) => {
    //     //this.messages.push(message);
    //   });
  }
}
