import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {Colors} from '../models/colors.class';

@Injectable()
export class ColorsService extends CrudService {
  protected namespace = 'passport-colors';
  protected namespaceSingular = 'passport-colors';
  protected ModelClass = Colors;
}
