import { Component, Inject, OnInit } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';


import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PassportService } from '../../services/passport.service';
import { Passport } from '../../models/passport.class';

import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {TranslateService} from '@ngx-translate/core';
import { PASSPORT_STATUS_CONST, PASSPORT_STATUS } from '../../values/passport-status.const';
@Component({
    moduleId: module.id,
    selector: 'new-passport-version-dialog',
    styleUrls: ['new-passport-version-dialog.component.scss'],
    templateUrl: 'new-passport-version-dialog.component.html'
})
export class NewPassportVersionDialog implements OnInit {



    public versionFormGgrp: FormGroup;
    public passport: Passport;

    constructor(@Inject(MD_DIALOG_DATA) public dialogData: any,
        public _dialogRef: MdDialogRef<any>,
        public _fb: FormBuilder,
        private translationService: TranslateService,
        private passportService: PassportService,
        private notification : LogNotificationService) {

            this.passport = new Passport({...this.dialogData.passport});

    }

    ngOnInit() {
        this.versionFormGgrp = this._fb.group({
            newVersion: ['',[Validators.pattern('^[0-9]{4}$'), Validators.required]]
        });
    }

    validate() {
        let version = this.versionFormGgrp.controls.newVersion.value;
        if (!this.versionFormGgrp.invalid) {
 
            this.passportService.duplicate(this.passport.id, {name : this.passport.name ,version: version,status:PASSPORT_STATUS.DRAFT}, true).subscribe((response: any) => {
                if (response.id) {
                    this._dialogRef.close({ passport: response, clearForm: false });
                }
            });
        }
        else {

            let emptyVersion = "Please enter a date of version";
            let wrontFormat = "Incorrect format : version must be a 4 digit number"
          //  let wrontFormat = "Incorrect format : version must be a 4 digit number"
          //  if (!this.passport.isPublished()) {

            //}
            if (version) {
                this.notification.error(this.translationService.instant(wrontFormat));
            }else {
                this.notification.error(this.translationService.instant(emptyVersion));
            }
        }
        
    }

    close() {
        this._dialogRef.close({ version: this.versionFormGgrp.controls.newVersion.value, clearForm: false });
    }





}
