import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { Response } from '@angular/http';


@Injectable()
export class EcosystemProcessCountService extends CrudService {
  protected namespace = 'ecosystem/process_count';
  protected ModelClass = String;

  getData() {
    return this
      .getResponse()
      .map((res: Response) => this.buildModel(res.json()))
      ;
  }
}
