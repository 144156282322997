<div class="confirm-c2c-dialog">
  <div class="confirm-c2c-dialog__title">
    {{ 'Caution' | translate | uppercase}}
  </div>
  <div class="confirm-c2c-dialog__content">
    <div>
      {{'PASSPORT_C2C_DIALOG_INFO' | translate}}
    </div>
    <br>

    <div *ngFor="let item of dialogItems; let index = index">
      <div *ngIf="index === 0">
        {{ 'PASSPORT_C2C_DIALOG_FIRST_CAPTION_BEFORE_LINK' | translate }}
        <span (click)="openBannedComponentsList()" class="confirm-c2c-dialog__link primary-color">
          {{'PASSPORT_C2C_DIALOG_FIRST_CAPTION_LINK' | translate}}
        </span>
        {{ 'PASSPORT_C2C_DIALOG_FIRST_CAPTION_AFTER_LINK' | translate }}
      </div>
      <div *ngIf="index > 0">
        {{item | translate}}
      </div>
      <div>
        <md-radio-group class="flex-column"
                        (change)="onRadioGroupValueChange($event, index)">
          <md-radio-button [value]="1">
            {{ 'Yes' | translate }}
          </md-radio-button>
          <md-radio-button [value]="0">
            {{ 'No' | translate }}
          </md-radio-button>
        </md-radio-group>
      </div>
      <br>
    </div>

    <footer class="passport-edit__controls flex confirm-c2c-dialog__footer">
      <ul class="passport-edit__controls--wrapper">
        <li class="button-group__item button-group__item confirm-c2c-dialog__footer-button">
          <button [disabled]="isConfirmButtonDisabled" md-button (click)="onConfirm()">{{ 'Confirm' | translate }}</button>
        </li>
        <li class="button-group__item button-group__item confirm-c2c-dialog__footer-button">
          <button md-button (click)="onCancel()">{{ 'Cancel' | translate }}</button>
        </li>
      </ul>
    </footer>
  </div>
