<div  class="passportlist__infobox">
    <div
      class="background-primary-color"
      [ngClass]="{'passport-edit__information': true,'passport-edit__information--active': isShown}">

      <div (click)="toggle()" class="passport-edit__information--header-wrapper">
           <div class="passport-edit__information--header">
            <i class="icon icon--question-white" style="margin-right: 5px;"></i>
            {{ ("FAMILY_AGENT_INFO_PART_1" | translate).toUpperCase() }}

          </div>
           <i class="passport-edit__information--arrow" [class]="isShown ? ' icon white-arrow-down--icon' : 'icon white-arrow-up--icon'"></i>
          </div>
      <div >
        <div *ngIf="isShown" style="padding-top: 5px;">
          <div class="passport-edit__information--text" *ngIf="translationType === 'AGENT'">

            <div class="passport-edit__information--text-bottom">

                  <ul >
                      <li>{{ "FAMILY_AGENT_INFO_PART_2" | translate}}</li>
                      <li>{{ "FAMILY_AGENT_INFO_PART_3" | translate }}</li>
                      <li>{{ "FAMILY_AGENT_INFO_PART_4" | translate }}</li>

                  </ul>

            </div>
          </div>

          <div class="passport-edit__information--text" *ngIf="translationType === 'MANUFACTURER'">
              <div class="passport-edit__information--text-top">
                  {{ "FAMILY_MANUFACTURER_INFO_PART_1" | translate }}

              </div>
              <div class="passport-edit__information--text-bottom">

                    <ul>
                      <li>{{ "FAMILY_MANUFACTURER_INFO_PART_2" | translate }}</li>
                      <li>{{ "FAMILY_MANUFACTURER_INFO_PART_3" | translate }}</li>
                    </ul>

              </div>
            </div>
        </div>

      </div>

    </div>
  </div>
