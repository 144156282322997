import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { MaterialService } from '../services/material.service';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
import { ValidationService } from '../../../shared/services/validation-service';
var Material = /** @class */ (function (_super) {
    tslib_1.__extends(Material, _super);
    function Material() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = MaterialService;
        return _this;
    }
    Material.prototype.fields = function () {
        return ['id', 'nomenclature_id', 'quantity', 'passport_id', 'nomenclature', 'component_id', 'percentage'];
    };
    Material.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        json.nomenclature = !!json.nomenclature ? new NomenclatureItem(json.nomenclature) : null;
        return json;
    };
    Material.prototype.beforeSave = function (data) {
        var dataValues = _super.prototype.beforeSave.call(this, data);
        if (dataValues.quantity) {
            dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
        }
        if (dataValues.material_cost) {
            dataValues.material_cost = ValidationService.normalizeQuantity(dataValues.material_cost);
        }
        return dataValues;
    };
    return Material;
}(ActiveRecord));
export { Material };
