import * as tslib_1 from "tslib";
import { Observable } from 'rxjs/Observable';
import { BeforeRouteActivatedHook } from './before-route-activated.guard';
import { GuardStates } from './guard-states.class';
var OnceBeforeRouteActivatedHook = /** @class */ (function (_super) {
    tslib_1.__extends(OnceBeforeRouteActivatedHook, _super);
    function OnceBeforeRouteActivatedHook() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(OnceBeforeRouteActivatedHook.prototype, "activated", {
        get: function () {
            return GuardStates.isGuardActivated(this);
        },
        set: function (value) {
            if (value) {
                GuardStates.activateGuard(this);
            }
        },
        enumerable: true,
        configurable: true
    });
    OnceBeforeRouteActivatedHook.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.activated ? Observable.of(true) : _super.prototype.canActivate.call(this, route, state).do(function () { return _this.activated = true; });
    };
    OnceBeforeRouteActivatedHook.prototype.canActivateChild = function (childRoute, state) {
        var _this = this;
        return this.activated ? Observable.of(true) : _super.prototype.canActivateChild.call(this, childRoute, state).do(function () { return _this.activated = true; });
    };
    return OnceBeforeRouteActivatedHook;
}(BeforeRouteActivatedHook));
export { OnceBeforeRouteActivatedHook };
