import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { PassportKeywordJunctionService } from '../services/passport-keyword-junction.service';
var PassportKeywordJunction = /** @class */ (function (_super) {
    tslib_1.__extends(PassportKeywordJunction, _super);
    function PassportKeywordJunction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = PassportKeywordJunctionService;
        return _this;
    }
    PassportKeywordJunction.prototype.fields = function () {
        return ['id', 'passport_id', 'passport_keyword_id', 'keyword'];
    };
    return PassportKeywordJunction;
}(ActiveRecord));
export { PassportKeywordJunction };
