import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TLanguage } from '../types/language-type';
import { LanguageService } from '../../entities/client/service/language.service';
import { CurrentUserService } from '../../shared/services/current-user.service';


@Component({
  moduleId: module.id,
  selector: 'language-selector',
  templateUrl: 'language-select.component.html',
  providers: [CurrentUserService]
})

export class LanguageSelectComponent implements OnInit {

  @Input() public language: TLanguage;

  @Input() public autosetLanguage = true;

  @Input() public showPlaceHolder = 'never';

  @Output() public languageChange = new EventEmitter();

  public availableLanguages: TLanguage[];

  constructor(private languageService: LanguageService) {
  }

  ngOnInit() {
    this.languageService.initLang();
    this.language = this.language ? this.language : this.languageService.getUsedLanguage() as TLanguage;
    this.availableLanguages = this.languageService.availableLanguages;
  }

  setLanguage(language: TLanguage): void {
    if(this.autosetLanguage) {
      this.languageService.setLang(language);
    }
    this.languageChange.emit(this.language);
  }

  getLanguageByKey(language: TLanguage) {
    return this.languageService.getLanguageByKey(language);
  }
}
