import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { AfterViewInit, ChangeDetectorRef, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Need } from '../../models/need.class';
import { NeedViewService } from '../../services/need-view.service';
import { NeedControlStates } from '../../models/need-control-states.class';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { NEED_STATUSES, ENeedStatusesView } from '../../values/need-statuses.const';
import { TranslateService } from '@ngx-translate/core';
import { PASSPORT_STATUS } from '../../../passport/values/passport-status.const';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ECOSYSTEM_OFFER_STATUS } from '../../../../dashboards/ecosystem-manager/values/ecosystem-offer-status.const';
import { PassportValidationNotificationService } from '../../../passport/services/passport-validation-notification.service';
import { Passport } from '../../../passport/models/passport.class';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { MdDialog } from '@angular/material';
/**
 * This class represents the lazy loaded NeedEditComponent.
 */
var NeedEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NeedEditComponent, _super);
    //private originIndependent: number;
    function NeedEditComponent(route, router, _passportValidationNotification, _translate, cd, notification, navigationService, zone, dialog, loadingSpinner, viewService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this._passportValidationNotification = _passportValidationNotification;
        _this._translate = _translate;
        _this.cd = cd;
        _this.notification = notification;
        _this.navigationService = navigationService;
        _this.zone = zone;
        _this.dialog = dialog;
        _this.loadingSpinner = loadingSpinner;
        _this.viewService = viewService;
        // TODO not used as an input
        _this.controlStates = new NeedControlStates;
        _this.validated = false;
        _this.stepId = 0;
        _this.showVoidPassportCreationMessage = false;
        _this.isIndependentControlDisabledByDefault = false;
        _this.savePassport = new EventEmitter();
        _this.passportSaveComplete = new EventEmitter();
        return _this;
    }
    NeedEditComponent.prototype.ngAfterViewInit = function () {
        this.cd.detectChanges();
    };
    NeedEditComponent.prototype.onExistEntity = function (entity) {
        this.need = entity;
        this.need.loaded = true;
        //active step?
        this.initialPrivacyState = this.need.isPublic();
        this.stepId = this.need.getProgress();
        if (this.need.passport && new Date(this.need.updated_at) < new Date(this.need.passport.updated_at)) {
            this.notification.warn('Passport has been updated since last edit', 7000);
        }
        if (this.need.from_deposit && !(this.need.passport || this.need.passport.id)) {
            this.showVoidPassportCreationMessage = true;
        }
        if (this.need.from_noah) {
            this.isIndependentControlDisabledByDefault = true;
        }
        var isPublished = this.isPublished();
        var isPublicityUnChangable = this.isFormDisabled();
        // TODO edit validation is not implemented on backend
        // Need can be saved as draft only it is not in process
        // this.controlStates.save = { disabled: this.need.getState() !== 1 };
        this.controlStates.publish = { disabled: this.need.getViewStatus() !== ENeedStatusesView.DRAFT };
        this.controlStates.save = { disabled: isPublished };
        // Editable during creation
        this.controlStates.quantity = { disabled: isPublicityUnChangable };
        this.controlStates.quantity_precision = { disabled: isPublicityUnChangable };
        this.controlStates.delivery_date = { disabled: isPublicityUnChangable };
        this.controlStates.date_precision = { disabled: isPublicityUnChangable };
        this.controlStates.client_location_id = { disabled: isPublished };
        // Passport can be changed only if need is not in process
        // this.controlStates.passport_id = { disabled: this.need.getState() !== 1 };
        // Name and client can be changed only if need is not published
        this.controlStates.name = {
            disabled: !(this.need.status === NEED_STATUSES.DRAFT.value
                || this.need.status === NEED_STATUSES.ASK_FOR_MODIFICATION.value
                || this.need.status === NEED_STATUSES.WAITING_FOR_PUBLICATION.value
                || !this.isNeedInArchive())
        };
        this.controlStates.passport_id = { disabled: isPublished };
        // Name and client can be changed only if need is not published
        /* this.controlStates.name = {
          disabled: isDeliveryPlanned
        this*/
        this.controlStates.client_id = {
            disabled: isPublicityUnChangable
        };
        // Can edit during both creation and in process
        //  this.controlStates.description =  { disabled: this.need.getState() !== 1 };
        // this.controlStates.client_contact_id = { disabled: false };
        // this.controlStates.files = { disabled: this.need.getState() !== 1 };
        this.controlStates.description = { disabled: isPublicityUnChangable };
        this.controlStates.client_contact_id = { disabled: isPublicityUnChangable };
        this.controlStates.files = { disabled: isPublicityUnChangable };
    };
    NeedEditComponent.prototype.isNeedInArchive = function () {
        // archived need are not editable at all
        return this.need.hasUnEditableStatus();
    };
    NeedEditComponent.prototype.onNewEntity = function () {
        this.need = new Need({});
        var passport = this.route.snapshot.data['passport'];
        var deposit = this.route.snapshot.data['deposit'];
        var independent = this.route.snapshot.params['independent'];
        independent = !!independent ? independent.trim() !== 'false' : null;
        if (deposit) {
            passport = deposit.passport;
            this.need.from_deposit = deposit.id;
        }
        if (!!passport) {
            this.need.passport = passport;
        }
        if (independent !== null) {
            this.need.independent = independent;
            this.initialPrivacyState = independent;
            this.need.from_noah = independent;
            this.isIndependentControlDisabledByDefault = true;
        }
        this.need.loaded = true;
    };
    NeedEditComponent.prototype.isPublished = function () {
        return this.need.status >= NEED_STATUSES.PUBLISHED.value;
    };
    NeedEditComponent.prototype.isDeliveryPlanned = function () {
        return this.need.offer && this.need.offer.some(function (needOffer) { return (needOffer.status === ECOSYSTEM_OFFER_STATUS.VALIDATED
            || needOffer.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION
            || needOffer.status === ECOSYSTEM_OFFER_STATUS.PROBLEM_REPORTED); });
    };
    NeedEditComponent.prototype.saveDraft = function (index, isRedirect) {
        var _this = this;
        if (this._passportValidationNotification.showChildNotificationErrors())
            return;
        this.need.status = 1;
        this.zone.run(function () {
            if (_this.savePassportIsNeeded()) {
                _this.savePassport.emit({ key: 'savePassport' });
                _this.passportSaveComplete.subscribe(function () {
                    _this.save(index, isRedirect);
                });
                return;
            }
            _this.save(index, isRedirect);
        });
    };
    NeedEditComponent.prototype.save = function (index, isRedirect) {
        var _this = this;
        this.need.save().subscribe(function (res) {
            if (isRedirect && res.id !== _this.needId) {
                _this.router.navigate(['sales/needs/', res.id, 'edit']);
            }
            if (index) {
                _this.stepId = index;
            }
            else {
                _this.router.navigate(['sales/needs']);
            }
        });
    };
    NeedEditComponent.prototype.isNextDisabled = function () {
        return this.need.getProgress() <= this.stepId;
    };
    NeedEditComponent.prototype.isPrevDisabled = function () {
        return this.stepId <= 0;
    };
    NeedEditComponent.prototype.changeTab = function (index) {
        var _this = this;
        if (index === this.stepId) {
            return;
        }
        if (this._passportValidationNotification.showChildNotificationErrors())
            return;
        var requiredFieldsError = this.getMiniumRequiredDataFirstError(index);
        if (requiredFieldsError) {
            this.validated = true;
            this.notification.error(requiredFieldsError, null, true);
            return;
        }
        if (index > 0 && !this.need.id && (!this.need.passport || index > 1)) {
            this.saveDraft(index, true);
            return;
        }
        this.zone.run(function () {
            _this.stepId = index;
        });
    };
    NeedEditComponent.prototype.nextStep = function () {
        if (!this.isNextDisabled()) {
            this.stepId++;
        }
    };
    NeedEditComponent.prototype.prevStep = function () {
        if (!this.isPrevDisabled()) {
            this.stepId--;
        }
    };
    NeedEditComponent.prototype.isConfirmWindowRequired = function () {
        if (this.need.isPublic()) {
            if (this.initialPrivacyState === false) {
                return true;
            }
            if (!this.need.from_noah && this.need.getViewStatus() === ENeedStatusesView.CREATED) {
                return true;
            }
        }
        else {
            return false;
        }
    };
    NeedEditComponent.prototype.confirmSubmit = function () {
        var _this = this;
        if (this.isConfirmWindowRequired()) {
            this._translate.get('CONFIRM_PUBLIC_NEED')
                .flatMap(function (translation) {
                var dialogRef = _this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        confirmText: 'Ok, submit',
                        text: translation,
                        cssClass: "need-edit-public-dialog__confirmation"
                    }
                });
                return dialogRef.afterClosed();
            })
                .subscribe(function (submittedPublically) {
                if (submittedPublically) {
                    _this.submit();
                }
                else
                    return;
            });
        }
        else {
            this.submit();
        }
    };
    NeedEditComponent.prototype.submit = function () {
        var _this = this;
        if (this._passportValidationNotification.showChildNotificationErrors())
            return;
        var requiredFieldsError = this.getMiniumRequiredDataFirstError();
        if (requiredFieldsError) {
            this.validated = true;
            this.notification.error(requiredFieldsError, null, true);
            return;
        }
        if (this.savePassportIsNeeded()) {
            this.savePassport.emit({ key: 'savePassport' });
            this.passportSaveComplete.subscribe(function () {
                _this.publish();
            });
            return;
        }
        this.publish();
    };
    NeedEditComponent.prototype.getMiniumRequiredDataFirstError = function (stepIndex) {
        if (stepIndex === void 0) { stepIndex = 3; }
        var progress = this.need.getProgress();
        if (progress < stepIndex) {
            var error = this.need.getValidationErrors().shift();
            if (error.progressStep < 3) {
                this.stepId = error.progressStep;
            }
            return error ? error.text : 'Unknown error';
        }
    };
    NeedEditComponent.prototype.savePassportIsNeeded = function () {
        return this.need.passport && !!this.need.passport.id
            && !(this.need.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor());
    };
    NeedEditComponent.prototype.publish = function () {
        var _this = this;
        this.loadingSpinner.show('need');
        this.need.publish().subscribe(function (res) {
            console.log('Submission Result:', res);
            _this.need = new Need(res);
            _this.viewService.setNeed(_this.need);
            _this.router.navigate(['/sales']);
        }, function () { return _this.loadingSpinner.destroy(); }, function () { return _this.loadingSpinner.destroy(); });
    };
    NeedEditComponent.prototype.askForModification = function () {
        var _this = this;
        this.need.askForModification().subscribe(function (res) {
            _this.router.navigate(['/sales']);
        });
    };
    NeedEditComponent.prototype.reject = function () {
        var _this = this;
        this.need.reject().subscribe(function (res) {
            _this.router.navigate(['/sales']);
        });
    };
    NeedEditComponent.prototype.onPassportSaveComplete = function ($event) {
        this.passportSaveComplete.emit();
    };
    NeedEditComponent.prototype.isFormDisabled = function () {
        return this.isNeedInArchive() || this.isDeliveryPlanned();
    };
    NeedEditComponent.prototype.isPublicityUnChangable = function () {
        return this.isFormDisabled() || this.isIndependentControlDisabledByDefault;
    };
    return NeedEditComponent;
}(EntityViewerComponent));
export { NeedEditComponent };
