export enum ENeedSort {
    CREATED_DESC = '-created_at',
    DELIVERY_DATE = 'delivery_date', 
    ADDRESS = 'address', //CHECK AND CHANGE
    RECIPIENT = 'client_name'
  }
  
export const NEED_SORT_NAME = [
    { key: ENeedSort.CREATED_DESC, value: 'Creation date' },
    { key: ENeedSort.DELIVERY_DATE, value: 'Delivery date' },
    { key: ENeedSort.ADDRESS, value: 'Delivery site' },
    { key: ENeedSort.RECIPIENT, value: 'Recipient' },
];