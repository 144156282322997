<div class="esm-catalog">
  <map-view
    [markers]="markers"
  ></map-view>
  <section class="esm-catalog__choice type--multi-column type--offset-bottom type--offset-top-xs">
    <ul class="esm-catalog__choice-list type--align-start">
      <li class="esm-catalog__choice-item search-long--xxl-responsive">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input test-id="search"
                 mdInput
                 autocomplete="off"
                 [formControl]="search"
                 [placeholder]="'Search' | translate"
          >
        </md-input-container>
      </li>
      <li *ngIf="showIndependentFilter" class="toggle-independent-container">
        <toggle-independent
          (toggle)="toggleIndependent($event)"
          [text]="'See external needs'"
          [img]="'assets/png/cloud_grey.png'">
        </toggle-independent>
      </li>
    </ul>
    <ul class="esm-catalog__choice-list type--left-indent type--align-start type--gap type--wrap">
      <li class="esm-catalog__choice-item select--len-fixed">
        <md-select
          class="select-group"
          placeholder="{{ 'Recipient' | translate }}"
          [(ngModel)]="clientId"
          (ngModelChange)="getRecords()"
        >
          <md-option>{{ 'Any' | translate }}</md-option>
          <md-option
            *ngFor="let client of filteredClients"
            [value]="client.id"
          >{{client.name}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item date">
        <i class="icon icon--calendar icon--gray"></i>
        <md-input-container class="date input-group">
          <input mdInput
                  autocomplete="off"
                  test-id="expires-date-from-filter"
                  ng2-datetime-picker
                  date-only="true"
                  date-format="DD-MM-YYYY"
                  placeholder="{{ 'From_' | translate }}"
                  [(ngModel)]="expiresFrom"
                  (ngModelChange)="getRecords()"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-dash">-</li>
      <li class="esm-catalog__choice-item date">
        <i class="icon icon--calendar icon--gray"></i>
        <md-input-container class="date input-group">
          <input mdInput
                  autocomplete="off"
                  ng2-datetime-picker
                  date-only="true"
                  date-format="DD-MM-YYYY"
                  placeholder="{{ 'To' | translate }}"
                  [(ngModel)]="expiresTo"
                  (ngModelChange)="getRecords()"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select--len-fixed">
        <md-select
          class="select-group"
          placeholder="{{ 'Need manager' | translate }}"
          [(ngModel)]="salesId"
          (ngModelChange)="getRecords()"
        >
          <md-option>{{ 'Any' | translate }}</md-option>
          <md-option
            *ngFor="let sales of salesContacts"
            [value]="sales.id"
          >{{sales.name}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select--len-fixed">
        <md-select
          class="select-group"
          placeholder="{{ 'Sort by' | translate }}"
          [(ngModel)]="sortValue"
          (ngModelChange)="getRecords()"
        >
          <md-option
            *ngFor="let sortValue of sortOptions"
            [value]="sortValue.field"
          >{{ sortValue.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
    [cloudIcon]="true"
  ></custom-list>
</div>
