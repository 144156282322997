/**
 * Created by aleksandr on 16.05.17.
 */
import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { ECOSYSTEM_NAVIGATION } from './ecosystem-manager.navigation';
import { ERoleIds } from '../../shared/values/role-ids.enum';

export const ECOSYSTEM_CONTEXT: IRoleContextConfig = {
  navigation: ECOSYSTEM_NAVIGATION,
  moduleName: 'ecosystem',
  apiModuleName: 'ESM',
  themeClass: 'b-module-ecosystem',
  roleId: ERoleIds.ECOSYSTEM_MANAGER,
  roleText: 'Ecosystem Manager',
  homeUrl: '/ecosystem',
  rootPath: 'ecosystem'
};

