import {ActiveRecord} from '../../../shared/models/active-record.class';
import {EcosystemProblemService} from '../services/ecosystem-problem.service';


export class EcosystemProblem extends ActiveRecord  {
  protected provider = EcosystemProblemService;
  id: number;
  description: string;
  step_id: number;

  protected fields(): string[] {
    return [
      'description',
      'step_id'
    ];
  }

}
