import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TranslateService } from '@ngx-translate/core';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { DistributionAreaService } from '../services/distribution-area.service';
var PassportDistributionArea = /** @class */ (function (_super) {
    tslib_1.__extends(PassportDistributionArea, _super);
    function PassportDistributionArea(country) {
        var _this = _super.call(this) || this;
        _this.provider = DistributionAreaService;
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        _this.country = country;
        return _this;
    }
    PassportDistributionArea.prototype.fields = function () {
        return ['country', 'country_iso', 'id'];
    };
    return PassportDistributionArea;
}(ActiveRecord));
export { PassportDistributionArea };
