/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../rating-filter.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../rating-filter.component";
import * as i4 from "./passport-rating-with-local-passport.component";
var styles_PassportRatingWithLocalPassportComponent = [];
var RenderType_PassportRatingWithLocalPassportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PassportRatingWithLocalPassportComponent, data: {} });
export { RenderType_PassportRatingWithLocalPassportComponent as RenderType_PassportRatingWithLocalPassportComponent };
export function View_PassportRatingWithLocalPassportComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "rating-filter", [["class", "passportlist__info-rating-filter--alternative"]], null, [[null, "ratingFilterChange"], ["document", "focusin"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:focusin" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onFocus($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onClick($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ratingFilterChange" === en)) {
        var pd_2 = (_co.updateRatingFilter($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_RatingFilterComponent_0, i1.RenderType_RatingFilterComponent)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(2, 114688, null, 0, i3.RatingFilterComponent, [], { ratingFilter: [0, "ratingFilter"], setNameAlias: [1, "setNameAlias"], aliasClass: [2, "aliasClass"], isSelectAllShown: [3, "isSelectAllShown"], placeholder: [4, "placeholder"], ratingApplyRule: [5, "ratingApplyRule"] }, { ratingFilterChange: "ratingFilterChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "passportlist__info-rating-filter--alternative"; var currVal_1 = _co.classList; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.ratingFilterView; var currVal_3 = _co.ratingNameAlias; var currVal_4 = _co.ratingClassAlias; var currVal_5 = false; var currVal_6 = "Product rating"; var currVal_7 = _co.ratingApplyRule; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_PassportRatingWithLocalPassportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "rating-with-local-passport-filter", [], null, null, null, View_PassportRatingWithLocalPassportComponent_0, RenderType_PassportRatingWithLocalPassportComponent)), i0.ɵdid(1, 49152, null, 0, i4.PassportRatingWithLocalPassportComponent, [], null, null)], null, null); }
var PassportRatingWithLocalPassportComponentNgFactory = i0.ɵccf("rating-with-local-passport-filter", i4.PassportRatingWithLocalPassportComponent, View_PassportRatingWithLocalPassportComponent_Host_0, { ratingNameAlias: "ratingNameAlias", ratingIconAlias: "ratingIconAlias", ratingClassAlias: "ratingClassAlias", classList: "classList", ratingApplyRule: "ratingApplyRule", defaultRatings: "defaultRatings", setRating: "ratingFilter" }, { ratingFilterChange: "ratingFilterChange", onAnySelected: "onAnySelected" }, []);
export { PassportRatingWithLocalPassportComponentNgFactory as PassportRatingWithLocalPassportComponentNgFactory };
