/*
 add ReadOnlyService as attribute to change it's child nodes:
 * input, textarea: readonly attribute
 * button: hidden attribute
 * md-checkbox, md-select, md-radio-button: disabled since no readonly attribute exists for them
 [ReadOnlyService]="null" diasbles me
 */

import { NgModule } from '@angular/core';
import { FormElemsReadOnlyDirective } from './form-elems-read-only.directive';
import { FormElemsHiddenDirective } from './form-elems-hidden.directive';
import { FormElemsDisabledDirective } from './form-elems-disabled.directive';
import { ReadOnlyDirective } from './read-only.directive';

@NgModule({
  declarations: [
    FormElemsReadOnlyDirective,
    FormElemsDisabledDirective,
    FormElemsHiddenDirective,
    ReadOnlyDirective,
  ],
  exports: [
    FormElemsReadOnlyDirective,
    FormElemsDisabledDirective,
    FormElemsHiddenDirective,
    ReadOnlyDirective,
  ],
})
export class ReadOnlyModule {
}
