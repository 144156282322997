import {Component, Input} from '@angular/core';




@Component({
  moduleId: module.id,
  selector: 'deposit-import-column-custom',
  templateUrl: 'deposit-import-column-custom.component.html',
  styleUrls: ['./deposit-import-column-custom.component.scss'],
})
export class DepositImportColumnCustomComponent {
  @Input() className: string;
  @Input() description: string;
  @Input() additionalDescription: string;
  @Input() custom: false;
  @Input() showWarningSign = true;

  constructor() {}

}
