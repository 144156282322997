<header>
  <h2 class="dialog-title">
    {{'Delivery_place' | translate}}
    <div class="close-container" (click)="closeDialog()" aria-label="Close Dialog">
      <div class="leftright"></div>
      <div class="rightleft"></div>
    </div>
  </h2>
</header>
<div>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <md-input-container>
      <input mdInput formControlName="name" placeholder="{{'Name'|translate}}*" aria-label="Site Name">
    </md-input-container>

    <md-input-container>
      <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
        <input mdInput class="client-edit" formControlName="address" placeholder="{{ 'Address' | translate }} *"
          placeAutocomplete (placeChange)="onPlaceChange($event)" aria-label="Site Address">
      </place-autocomplete>
    </md-input-container>
    <custom-search
    caption="{{ 'associated_recipients' | translate }}*"
    [preset]="selectedClient"
    [service]="clientService"
    (onSelect)="onSelectClient($event)"
    (onClear)="clearSelectedClient()"
    label="{{'Select recipient' | translate }}"
    [autoCreation]="false"
    >
    </custom-search>
    <md-input-container>
      <textarea mdInput formControlName="description" placeholder="{{'Description' | translate}}"
        aria-label="Site Description"></textarea>
    </md-input-container>

    <button md-raised-button type="submit" [disabled]="!formGroup.valid || isSubmitting">{{'Save' | translate}}</button>
  </form>
</div>