/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng2-file-upload/file-upload/file-select.directive";
import * as i2 from "../../read-only/form-elems-read-only.directive";
import * as i3 from "../../read-only/read-only.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../read-only/form-elems-hidden.directive";
import * as i6 from "@angular/common";
import * as i7 from "./avatar-uploader.component";
import * as i8 from "../../services/user.service";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../../services/current-user.service";
import * as i11 from "../../services/log-notification.service";
var styles_AvatarUploaderComponent = [];
var RenderType_AvatarUploaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AvatarUploaderComponent, data: {} });
export { RenderType_AvatarUploaderComponent as RenderType_AvatarUploaderComponent };
function View_AvatarUploaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["fileInput", 1]], null, 3, "input", [["class", "avatar-uploader__input"], ["id", "avatarUploader"], ["name", "preview"], ["ng2FileSelect", ""], ["type", "file"]], [[8, "placeholder", 0], [8, "readOnly", 0]], [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onChange() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.uploadImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = ((i0.ɵnov(_v, 0).value = null) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.FileSelectDirective, [i0.ElementRef], { uploader: [0, "uploader"] }, null), i0.ɵdid(2, 16384, null, 0, i2.FormElemsReadOnlyDirective, [[2, i3.ReadOnlyService]], null, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.uploader; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 3).transform("Avatar")), ""); var currVal_1 = ((i0.ɵnov(_v, 2).readOnlyService == null) ? null : i0.ɵnov(_v, 2).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_AvatarUploaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "avatar-uploader__delete-button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.deleteAvatar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i3.ReadOnlyService]], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/png/profile-delete.png"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((i0.ɵnov(_v, 1).readOnlyService == null) ? null : i0.ɵnov(_v, 1).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0); }); }
export function View_AvatarUploaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AvatarUploaderComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "avatar-uploader__avatar-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "label", [["class", "avatar-uploader__avatar"], ["for", "avatarUploader"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", " "])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AvatarUploaderComponent_2)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.readOnly; _ck(_v, 1, 0, currVal_0); var currVal_3 = (_co.preview && !_co.readOnly); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getBackgroundImage(); _ck(_v, 3, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("Upload")); _ck(_v, 4, 0, currVal_2); }); }
export function View_AvatarUploaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "avatar-uploader", [], null, null, null, View_AvatarUploaderComponent_0, RenderType_AvatarUploaderComponent)), i0.ɵdid(1, 114688, null, 0, i7.AvatarUploaderComponent, [i8.UserService, i9.DomSanitizer, i10.CurrentUserService, i11.LogNotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvatarUploaderComponentNgFactory = i0.ɵccf("avatar-uploader", i7.AvatarUploaderComponent, View_AvatarUploaderComponent_Host_0, { preview: "preview", userId: "userId", readOnly: "readOnly" }, { previewChange: "previewChange" }, []);
export { AvatarUploaderComponentNgFactory as AvatarUploaderComponentNgFactory };
