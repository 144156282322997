
    <md-dialog-content class="md-dialog-content--with-header md-dialog-content--with-header--proportion-l">
      <h1 *ngIf="needFilter.loaded" class="md-dialog-title background-primary-color">
        {{'Deposits meeting the declared needs' | translate}}
        <dialog-close-button
          [defaultPosition]="false"
          class="md-dialog-content__close"
          (click)="mdDialogRef.close()"
        ></dialog-close-button>
      </h1>
      <div *ngIf="!needFilter.loaded" tabindex="0" ></div>
      <div class="md-dialog-content__sub-content">
        <esm-need-match
          *ngIf="needFilter.loaded"
          [needFilter]="needFilter"
        ></esm-need-match>
      </div>
    </md-dialog-content>