import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {TransformFormat} from '../models/transform-format.class';

@Injectable()
export class TransformFormatService extends CrudService {
  protected namespace = 'transformation-formats';
  protected namespaceSingular = 'transformation-format';
  protected ModelClass = TransformFormat;
}
