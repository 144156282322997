<div class="new-passport-version__wrapper">
    <div class="name-generation-dialog__close" (click)="close()">
        <i class="icon icon--close_s"></i>
    </div>
    <div class="new-passport-version__heading"><h2>{{ 'Create new passport version' | translate }} </h2></div>

    <div class="new-passport-version__body" [formGroup]="versionFormGgrp">
        <div class="new-passport-version__firstp">
            <p>{{ 'NEW_VERSION_INTRO' | translate }}</p>

        </div>
        <div class="new-passport-version__secondp">
            <p><b>{{ 'NEW_PASSPORT_WILL_BE_CREATED' | translate }} </b> </p>
        </div>

        <div class="new-passport-version__paraf">
            <div class="new-passport-version__label">
                <label class="form-group__label" >
                    <i class="form-group__label--question">!</i>
                </label>
            </div>
            <div class="new-passport-version__text">
                <p> {{ 'IF_YOU_WISH_TO_SAVE' | translate }} </p>
            </div>
        </div>
        <div class="new-passport-version__paraf">
            <div class="new-passport-version__label">

                <label class="form-group__label" >
                    <i class="form-group__label--question">!</i>
                </label>
            </div>
            <div class="new-passport-version__text">
                <p> {{ 'TO_CREATE_A_NEW_VERSION' | translate }} </p>
            </div>
        </div>
        <div class="new-passport-version__input_container">
            <div class="new-passport-version__input_label">
                <p> {{ 'PLEASE_ENTER_THE_REFERENCE_DATE' | translate }} </p>
            </div>
            <div class="new-passport-version__field">
                &nbsp; &nbsp;
                <md-input-container>
                    <input autocomplete="off" mdInput formControlName="newVersion"
                        placeholder="{{ '' | translate }}">
                </md-input-container>
            </div>
        </div>
    </div>


    <footer class="controls new-passport-version__footer">
        <ul class="button-group__list new-passport-version__button-group">

            <li class="button-group__item new-passport-version__button">
                <button md-button md-raised-button (click)="validate()">{{ 'Ok, create a new version' | translate }}
                </button>
            </li>
        </ul>
    </footer>

</div>