import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DepositCustomMultiSelectComponent} from '../deposit-custom-multi-select.component';

@Directive({
  selector: 'deposit-custom-multi-select[appMultiSelectMdSelectInput]',
  providers: [{provide: NG_VALUE_ACCESSOR, multi: true, useExisting: MultiSelectMdSelectInputDirective}]
})
export class MultiSelectMdSelectInputDirective implements ControlValueAccessor, OnInit, OnDestroy {
  @Input('options') set options(pairs: {key: any, value: string} []) {
    this.createOptions(pairs);
    this.select.allOptions = this.selectOptions;
  }

  private onChange;
  private onTouch;
  private subscription;
  private chosenOptions = new Set();
  private selectOptionOrder: {[value: string]: number} = {};
  private selectOptions: {
    view?: string,
    selected: boolean
    value: any,
    text?: string
  }[] = [];

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private createOptions(keyValuePair: {key: any, value: string}[]) {
    this.selectOptions = keyValuePair.map(pair =>
      ({view: pair.value, value: pair.key, selected: this.chosenOptions.has(pair.key)}));
    this.selectOptionOrder = keyValuePair.reduce((acc, pair, i) => {
      acc[pair.key + ''] = i;
      return acc;
    }, {});
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    if (Array.isArray(obj)) {
      this.chosenOptions = new Set(obj);
      this.selectOptions.forEach(item => {item.selected = this.chosenOptions.has(item.value); });
    }
  }

  constructor(private select: DepositCustomMultiSelectComponent) { }

  ngOnInit(): void {
    this.subscription = this.select.changeOption.subscribe((value) => {
      const option = this.selectOptions.find((item, i) => item.value === value);
      if (option) {
        if (this.chosenOptions.has(value)) {
          this.chosenOptions.delete(value);
          option.selected = false;
        } else {
          this.chosenOptions.add(value);
          option.selected = true;
        }
        this.onChange(Array.from(this.chosenOptions).sort((previous, next) => this.sort(previous, next)));
      }
    });
  }

  private sort(previous, next) {
    return this.selectOptionOrder[previous + ''] - this.selectOptionOrder[next + ''];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
