/**
 * Created by Aleksandr C  on 24.03.17.
 */

import { EventEmitter, Injectable } from '@angular/core';
import { SUPERVISOR_CONTEXT } from '../../dashboards/supervisor/supervisor.context';
import { IRoleContextConfig } from '../interfaces/role-context-config.interface';
import { DEFAULT_CONTEXT } from '../navigation/default.context';
import { IModuleNavigation } from '../interfaces/module-navigation.interface';
import { GUEST_CONTEXT } from '../auth/guest.context';
import { ERoleIds } from '../values/role-ids.enum';
import { AGENT_CONTEXT } from '../../dashboards/agent/agent.context';
import { TRANSFORM_CONTEXT } from '../../dashboards/transformation-manager/transformation-manager.context';
import { ECOSYSTEM_CONTEXT } from '../../dashboards/ecosystem-manager/ecosystem-manager.context';
import { DEPOSIT_OWNER_CONTEXT } from '../../dashboards/deposit-owner/deposit-owner.context';
import { ADMIN_CONTEXT } from '../../dashboards/admin/admin.context';
import { SUPERADMIN_CONTEXT } from '../../dashboards/superadmin/superadmin.context';
import { SALES_CONTEXT } from '../../dashboards/sales/sales.context';
import { MANUFACTURER_CONTEXT } from '../../dashboards/manufacturer/manufacturer.context';
import { SPECIALADMIN_CONTEXT } from '../../dashboards/specialadmin/specialadmin.context';
import {
  TRANSFORM_INDEPENDENT_CONTEXT
} from '../../dashboards/transformation-manager-independent/transformation-manager-independent.context';
import { ModuleNames } from '../values/module-names.const';
import { LocalStorageService, STORAGEKEYMAP } from './localStorage.service';

@Injectable()
export class NavigationService {

  public changeModule: EventEmitter<string> = new EventEmitter<string>();
  public showNavBar = false;

  private contexts: IRoleContextConfig[] = [
    GUEST_CONTEXT,
    SUPERVISOR_CONTEXT,
    DEFAULT_CONTEXT,
    SALES_CONTEXT,
    AGENT_CONTEXT,
    TRANSFORM_CONTEXT,
    ECOSYSTEM_CONTEXT,
    DEPOSIT_OWNER_CONTEXT,
    ADMIN_CONTEXT,
    SUPERADMIN_CONTEXT,
    SPECIALADMIN_CONTEXT,
    MANUFACTURER_CONTEXT,
    TRANSFORM_INDEPENDENT_CONTEXT
  ];

  private context: IRoleContextConfig;

  constructor(private localStorageService: LocalStorageService) {}

  get roleId(): number {
    return this.context && this.context.roleId;
  }

  getContext(): IRoleContextConfig {
    return this.context;
  }

  setContext(moduleName: string): void {
    this.context = this.contexts
      .find((context: IRoleContextConfig) => context.moduleName === moduleName || context.apiModuleName === moduleName);
  }

  setModule(module: string) {
    if (this.context && this.context.moduleName) {
      this.cleanUpRoleSpecificData(this.context.moduleName);
    }
    this.setContext(module);
    this.changeModule.emit(this.context.moduleName);
  }

  public getNavigationMenu(): IModuleNavigation[] {
    return this.context ? (this.context.navigation || []) : [];
  }

  public getModuleClass(): string {
    return this.context ? this.context.themeClass : 'b-module-default';
  }

  public getModuleName(): string {
    return this.context ? this.context.moduleName : ModuleNames.GUEST;
  }

  public getRoleText(): string {
    return this.context ? this.context.roleText : '';
  }

  public getHomeUrl(): string {
    return this.context ? this.context.homeUrl : '';
  }

  public isSupervisor(): boolean {
    return this.context.roleId === ERoleIds.SUPERVISOR;
  }
  public isESM(): boolean {
    return this.context.roleId === ERoleIds.ECOSYSTEM_MANAGER;
  }

  private cleanUpRoleSpecificData(moduleName: string): void {
    const key = STORAGEKEYMAP[moduleName];
    if (key) {
      this.localStorageService.removeValue(key);
    }
  }
}
