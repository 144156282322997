  <md-dialog-content>
    <div class="folder-move-modal" *ngIf="!confirmationMode">
      <div>  
        <div class="folder-move-modal__text" >
          <b>{{ 'FOLDER_MOVE_MODAL_TEXT' | translate}}</b>
        </div>
        <div class="folder-move-modal__text">
          <div class="folder-move-modal__text-wrapper">
            <md-select
            [disabled]="moveToRoot"
            placeholder="{{ 'FOLDER_MOVE_MODAL_DROPDOWN' | translate }}"
            [(ngModel)]="targetFolder"
            class="folder-move-modal__select"
            >
              <md-option *ngFor="let folder of computeFolders()" [value]="folder">
                {{folder.name | translate}}
              </md-option>
            </md-select>
          </div>
          <div class="folder-move-modal__text-wrapper">
            <md-checkbox [(ngModel)]="moveToRoot" [disabled]="!folderService.canMoveSelectionToRoot()" (change)="onCheckbox($event)">
              {{'FOLDER_MOVE_MODAL_ROOT_CHECKBOX' | translate}}
            </md-checkbox>
          </div>
         
        </div>
      </div>
  
      <div class="folder-move-modal__footer">
        <button
          [ngClass]="{'ecosystem-navigator__disabled': !(targetFolder || moveToRoot )}"
          class="folder-move-modal__button" md-raised-button
          [disabled]="!(targetFolder.id || moveToRoot )"
          (click)="move()">{{ 'MOVE_EC_SELECTION' | translate }}</button>
        <button class="folder-move-modal__button" md-raised-button
          (click)="closeDialog()">{{ 'Cancel' | translate}}</button>
      </div>
    </div>

    <div class="folder-move-modal" *ngIf="confirmationMode">
      <div>  
        <div class="folder-move-modal__text" >
          <b>{{ getTranslation() | async }}</b>
        </div>
        <div class="folder-move-modal__text">
           {{ 'MOVE_WARNING_SUBTITLE' | translate }}
        </div>
      </div>
  
      <div class="folder-move-modal__footer">
        <button class="folder-move-modal__button" md-raised-button
          (click)="confirm()">{{ 'Yes' | translate }}</button>
        <button class="folder-move-modal__button" md-raised-button
          (click)="closeDialog()">{{ 'Cancel' | translate}}</button>
      </div>
    </div>
  </md-dialog-content>
  