<article class="ecosystem-catalog">
  <header class="ecosystem-catalog__header">
    <ul class="ecosystem-catalog__caption-list">
      <li class="ecosystem-catalog__caption-item" (click)="changeTab(0)" [ngClass]="{'active': activeIndex === 0}">
        <span class="ecosystem-catalog__caption-title">{{ 'Agents' | translate }}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>
      </li>
      <li class="ecosystem-catalog__caption-item" (click)="changeTab(1)" [ngClass]="{'active': activeIndex === 1}">
        <span class="ecosystem-catalog__caption-title">{{ 'Orders archive' | translate }}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>
      </li>
      <!--<li class="ecosystem-catalog__caption-item" (click)="changeTab(2)" [ngClass]="{'active': activeIndex === 2}">
        <span class="ecosystem-catalog__caption-title">{{ 'Sites list' | translate }}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>
      </li>-->
    </ul>
  </header>
  <section class="ecosystem-catalog__content">
    <ul class="ecosystem-catalog__content-list">
      <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 0">
        <do-agents-catalog></do-agents-catalog>
      </li>
      <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 1">
        <do-archive-catalog></do-archive-catalog>
      </li>
      <!--<li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 2">
        <do-sites-catalog></do-sites-catalog>
      </li>-->
    </ul>
  </section>
</article>
