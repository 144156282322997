<div class="deposit-import-column__label primary-color">
  {{label}}
  <span *ngIf="required" class="deposit-import-column__label-star">*</span>
</div>
<md-input-container
  class="deposit-import-column__input"
  [floatPlaceholder]="'never'"
>
  <input
    mdInput
    type="text"
    autocomplete="off"
    [required]="required"
    [(ngModel)]="value"
    [disabled]="this.disabled"
    (ngModelChange)="changeValue()"
    (blur)="touchValue()"
    (keydown)="onKeyDown.emit($event)"
    [placeholder]="columnName">
</md-input-container>
