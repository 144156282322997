import * as tslib_1 from "tslib";
import { EcosystemProblem } from '../models/ecosystem-problem.model';
import { CrudService } from '../../../shared/services/crud.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../../../shared/services/log-notification.service";
import * as i3 from "../../../shared/services/navigation.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/services/mock/mock-api.service";
var EcosystemProblemService = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemProblemService, _super);
    function EcosystemProblemService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'ecosystem-problem';
        _this.namespaceSingular = 'ecosystem-problem';
        _this.ModelClass = EcosystemProblem;
        return _this;
    }
    EcosystemProblemService.ngInjectableDef = i0.defineInjectable({ factory: function EcosystemProblemService_Factory() { return new EcosystemProblemService(i0.inject(i1.Http), i0.inject(i2.LogNotificationService), i0.inject(i3.NavigationService), i0.inject(i4.TranslateService), i0.inject(i5.MockApiService)); }, token: EcosystemProblemService, providedIn: "root" });
    return EcosystemProblemService;
}(CrudService));
export { EcosystemProblemService };
