<md-input-container
  class="conditions"
  [floatPlaceholder]="'never'"
>
  <md-chip-list class="conditions-chips">
    <md-chip *ngFor="let condition of transform.conditions">
      {{condition.description}}
      <button *ngIf="!readOnly" (click)="removeCondition(condition)" class="delete"></button>
    </md-chip>
  </md-chip-list>

  <input
    [disabled]="readOnly"
    mdInput
    autocomplete="off" 
    [(ngModel)]="inputText"
    (change)="addCondition()"
  >
</md-input-container>
