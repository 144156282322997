/**
 * Created by aleksandr on 8.08.17.
 */

import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
import { ILiteral } from 'ng2-adn-common';
import { Transform } from '../models/transform.class';
import { TransformService } from './transform.service';

@Injectable()
export class TransformationResolver extends EntityResolver<Transform> implements Resolve<Transform> {
  private extraFields = [
    'conditions',
    'tags',
    'materials',
    'locations',
    'files',
    'nomenclature',
    'sectors',
    'formats',
    'quantities',
    'qualities',
    'packagings',
    'categories',
    'transformation_categories'
  ];
  protected query: ILiteral = {
    expand: this.extraFields.join(',')
  };

  constructor(protected _service: TransformService) {
    super();
  }
}
