/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./suggestible-text-input.component";
var styles_SuggestibleTextInputComponent = [];
var RenderType_SuggestibleTextInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SuggestibleTextInputComponent, data: {} });
export { RenderType_SuggestibleTextInputComponent as RenderType_SuggestibleTextInputComponent };
function View_SuggestibleTextInputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [["class", "custom-search__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.suggest(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSuggestion(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_SuggestibleTextInputComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "control-group__badge--corner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "control-group__badge"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.itemsCount; _ck(_v, 2, 0, currVal_0); }); }
export function View_SuggestibleTextInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "custom-search suggestible-text-input"]], null, [[null, "focusin"], [null, "focusout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focusin" === en)) {
        var pd_0 = (_co.focusIn() !== false);
        ad = (pd_0 && ad);
    } if (("focusout" === en)) {
        var pd_1 = (_co.focusOut() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "custom-search--focus": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "custom-search__field ib-row--justify"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "custom-search__input"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "custom-search__overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "ul", [["class", "custom-search__results"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SuggestibleTextInputComponent_1)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SuggestibleTextInputComponent_2)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "custom-search suggestible-text-input"; var currVal_1 = _ck(_v, 2, 0, _co.isFocus); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.suggestions; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.itemsCount; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_SuggestibleTextInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "suggestible-text-input", [], null, null, null, View_SuggestibleTextInputComponent_0, RenderType_SuggestibleTextInputComponent)), i0.ɵdid(1, 49152, null, 0, i2.SuggestibleTextInputComponent, [], null, null)], null, null); }
var SuggestibleTextInputComponentNgFactory = i0.ɵccf("suggestible-text-input", i2.SuggestibleTextInputComponent, View_SuggestibleTextInputComponent_Host_0, { suggestions: "suggestions", value: "value", viewProperty: "viewProperty", placeholder: "placeholder", itemsCount: "itemsCount" }, { onFocusOut: "onFocusOut", onChange: "onChange" }, ["*"]);
export { SuggestibleTextInputComponentNgFactory as SuggestibleTextInputComponentNgFactory };
