/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Input } from '@angular/core';
import { NomenclatureItem } from '../../../nomenclature/models/nomenclature-item.class';
import { MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'view-nomenclature',
  templateUrl: 'view-nomenclature.component.html',
  styleUrls: ['view-nomenclature.component.scss'],
})
export class ViewNomenclatureDialogComponent {

  @Input() public item: NomenclatureItem;

  constructor(private dialog: MdDialogRef<any>) {
  }

  onSave(item: NomenclatureItem) {
    this.item = item;
    this.dialog.close(this.item);
  }
}
