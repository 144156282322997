import { Directive, OnInit, Optional } from '@angular/core';
import { ReadOnlyService } from './read-only.service';
import { MdCheckbox, MdRadioButton, MdSelect } from '@angular/material';
@Directive({
  selector: 'md-select,md-checkbox,md-radio-button',
  host: {
    // '[disabled]': 'readOnlyService?.readOnly',
    // Can't bind to 'disabled' since it isn't a known property of 'md-select'...
  }
})
export class FormElemsDisabledDirective implements OnInit {
  constructor(@Optional() private readOnlyService: ReadOnlyService,
              @Optional() private mdCheckBox: MdCheckbox,
              @Optional() private mdRadioButton: MdRadioButton,
              @Optional() private mdSelect: MdSelect,) {
  }

  ngOnInit() {
    if (this.readOnlyService && typeof this.readOnlyService.readOnly === 'boolean') {
      [
        this.mdCheckBox,
        this.mdRadioButton,
        this.mdSelect,
      ].find(v => !!v).disabled = this.readOnlyService.readOnly;
    }
  }
}
