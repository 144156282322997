import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PassportStatus } from '../../../entities/passport/models/passport-status.class';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnChanges {
  @Input() selectedStatuses: number[] = [];
  @Input() filterStatuses: PassportStatus[];
  @Output() filterChanged = new EventEmitter<number[]>();

  constructor(private _navigation: NavigationService){}
  
  selectedStatusValues: number[] = []; // Store selected values as an array of numbers

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedStatuses) {
      // Update local selectedStatusValues based on the input from the parent component
      this.selectedStatusValues = changes.selectedStatuses.currentValue;
    }
  }

  toggleState(value: number): void {
    const index = this.selectedStatusValues.indexOf(value);
    if (index > -1) {
      this.selectedStatusValues.splice(index, 1); // Remove if already selected
    } else {
      this.selectedStatusValues.push(value); // Add if not selected
    }
    this.filterChanged.emit(this.selectedStatusValues); // Emit the array of selected values
  }

  isStatusFilterChecked(value: number): boolean {
    return this.selectedStatusValues.includes(value);
  }

  /**
  * Determines the CSS class for coloring pagination based on the user's role.
  * 
  * @returns The CSS class corresponding to the user's role.
  */
  getClassColor(): string {
    let role = this._navigation.getContext().roleText;
    switch (role) {
      case 'Agent':
        return 'role-agent';
      case 'Manufacturer':
        return 'role-manufacturer';
      case 'Specialadmin':
        return 'role-special-admin';
      default:
        return '';
    }
  }
}
