import {Component, OnDestroy, OnInit} from '@angular/core';
import {MdDialogRef} from '@angular/material';
import {AbstractControl, FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {DepositLocationStatusName, EDepositLocationStatus} from '../../../../entities/deposit/values/deposit-location-status.enum';
import {ISiteRequestParamsStateNewSite} from '../../interfaces/site-request-params/site-request-params-new-site.interface';
import {ESiteRequestType} from '../../values/site-request-type.enum';
import {RequestFileService} from '../../services/request-file.service';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {RequestFileSenderService} from '../../services/request-file-sender.service';
import {RequestFile} from '../../models/request-file.class';
import {allowOnlyPositiveNumbers} from '../../../../shared/helpers/proceed-if-non-negative-int';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'request-site-creation-dialog',
  styleUrls: ['./request-site-creation-dialog.component.scss'],
  templateUrl: './request-site-creation-dialog.component.html',
  providers: [{provide: RequestFileSenderService, useClass: RequestFileSenderService}]
})
export class RequestSiteCreationDialogComponent implements OnInit, OnDestroy {
  DEPOSIT_LOCATION_STATUS_OPTIONS = Object.entries(DepositLocationStatusName).map(([key, name]) => ({value: key, name}));
  DEPOSIT_LOCATION_STATUS = EDepositLocationStatus;
  form;
  uploadUrl;
  private updateStatusObserver: Subject<any>;
  private updateAreaObserver: Subscription;
  private updateLivingAreaObserver: Subscription;
  private FRStatusTranslation = {
   [EDepositLocationStatus.IN_CONSTRUCTION]: 'en construction',
   [EDepositLocationStatus.IN_OPERATION]: 'en exploitation',
   [EDepositLocationStatus.STORAGE]: 'stockage'
  };

  constructor(public formBuilder: FormBuilder,
              public sender: RequestFileSenderService,
              private translate: TranslateService,
              public requestFileService: RequestFileService,
              private dialog: MdDialogRef<any>) {
    this.isExpireFilledRequired = this.isExpireFilledRequired.bind(this);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      area: ['', [Validators.required, Validators.min(0)]],
      living_area: ['', [Validators.required, Validators.min(0)]],
      status: ['', Validators.required],
      expire: ['', this.expireValidator()]
    });
    this.updateStatusObserver = this.form.controls['status'].valueChanges.subscribe(
      (value) => {
        this.form.controls['status'].setValue(value, {emitEvent: false});
        this.form.controls['expire'].updateValueAndValidity();
      });
    this.updateAreaObserver = allowOnlyPositiveNumbers(this.form.controls['area']);
    this.updateLivingAreaObserver = allowOnlyPositiveNumbers(this.form.controls['living_area']);
  }

  private expireValidator(): ValidatorFn {
    return (control: AbstractControl) =>
      this.isExpireFilledRequired(control) ? {message: 'Expire required'} : null;
  }

  private isExpireFilledRequired(control: AbstractControl) {
    const status = this.form ? Number(this.form.value['status']) : null;
    return status === EDepositLocationStatus.IN_CONSTRUCTION && !control.value;
  }

  ngOnDestroy() {
    if (this.updateStatusObserver) {
      this.updateStatusObserver.unsubscribe();
    }
    if (this.updateAreaObserver) {
      this.updateAreaObserver.unsubscribe();
    }
    if (this.updateLivingAreaObserver) {
      this.updateLivingAreaObserver.unsubscribe();
    }
  }

  get status() {
    return Number(this.form.value.status);
  }

  closeDialog() {
    this.dialog.close(null);
  }

  confirmRequest() {
      const callback = this.transmitRequestAndClose.bind(this);
      this.sender.uploadRequest(callback);
  }

  transmitRequestAndClose(files: RequestFile[]) {
    const status = Number(this.form.value['status']);
    const options: ISiteRequestParamsStateNewSite = {
      type: ESiteRequestType.NEW_SITE,
      site_name: this.form.value['name'],
      surface: this.form.value['area'],
      living_area: this.form.value['living_area'],
      address: this.form.value['address'],
      status: this.FRStatusTranslation[status],
      expiration_date: status === EDepositLocationStatus.IN_CONSTRUCTION ? this.form.value['expire'] : null,
      file_ids: files.map(file => file.id)
    };
    this.dialog.close(options);
  }
}
