import {ReportService} from '../../../../services/report.service';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {IReportResponse} from '../../../../interfaces/reportResponse.interface';
import {IReportPDFHeader} from '../types/report-pdf-header.interface';
import {TReportError} from '../types/report-error.type';
import {IReportError} from '../../../../interfaces/report-error.interface';
import {LanguageService} from '../../../../../../entities/client/service/language.service';
import {IReportPDFTable} from '../types/report-pdf-table.interface';
import {chunkAnArray} from '../../../../../../shared/helpers/chuch-an-array';
import {TReportName} from '../types/report-name.type';
import {AfterViewChecked} from '@angular/core';
import {isError, EXCEPTION} from '../types/errors.const';
import {TranslateService} from '@ngx-translate/core';
import { TLanguage } from '../../../../../../shared/types/language-type';
import * as _ from 'lodash';
import {ReportSiteTagPresenterService} from '../../../../services/report-site-tag-presenter.service';
import {ElRowCounterService} from '../../../../services/el-row-counter.service';
import {first} from 'rxjs/operators';

export abstract class ReportBase<T, U> implements AfterViewChecked {

  protected abstract reportName: TReportName;
  protected abstract categories: U[];

  protected abstract categoriesTable: IReportPDFTable[];
  protected abstract errorsTable: IReportPDFTable[];

  public reportHeader: IReportPDFHeader;
  public reportData: IReportResponse;
  public errors: IReportError[] = [];

  public readonly categoriesPerPage: number = 12;
  public firstPageCategoriesAmount: number;

  public readonly errorsPerPage: number = 15;
  public filteredProductCategory: object = {};

  private readonly reportId: number;
  private readonly accessKey: string;
  public showSitesInOnePage: boolean;

  protected constructor(
    public reportService: ReportService,
    public activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    public translateService: TranslateService,
    public reportPresenter?: ReportSiteTagPresenterService,
    public rowCounter?: ElRowCounterService
  ) {
    this.reportId = this.activatedRoute.snapshot.params.id;
    this.accessKey = this.activatedRoute.snapshot.params.key;
  }

  ngAfterViewChecked(): void {
    this.languageService.setLang(this.activatedRoute.snapshot.params.lang as TLanguage);
  }

  public get result(): T {
    return this.reportData.result[this.reportName] as any;
  }

  isEmptyProductCategory(): boolean {
    return Object.keys(this.filteredProductCategory).length === 0;
  }

  public reportRequest(): Observable<IReportResponse> {
    return this.reportService.getReport(this.reportId, {
      expand: 'sites,result,sites.tags,tags',
      type: this.reportName, key: this.accessKey
    })
      .map(res => {
      this.setReportDataValues(res);
      this.setErrors();
      return res;
    });
  }

  private setReportDataValues(response: IReportResponse): void {
    this.reportData = response;
    this.reportPresenter.updateTagsById(response.tags.map(tag => tag.id ? tag.id : Number(tag)));
    this.reportPresenter.updateSiteList(response.sites);
    this.reportData.sites.map((site) => site.tags = this.reportPresenter.getTags(site.id));
    this.showSitesInOnePage = true;
    this.afterSiteListRowsCounted();
    this.reportHeader = {
      title: this.reportName.split('_').join(' '),
      date: {
        created_at: response.created_at,
        timezone_offset: response.timezone_offset},
      number: response.result.report_count || response.id
    };
  }
  private setErrors(): void {
    const errors = (this.result as any).errors;
    const warnings = (this.result as any).warnings;

    if (errors) {
      this.errors.push(...errors);
    }
    if (warnings) {
      this.errors.push(...warnings);
    }
  }
  public getSortedProductsBy(sortBy: string, type?: string, filter: string = 'deposits_considered'): any {
    let categories = {};
    let data = (this.result as any).aggregate;

    if (data) {
      if (data.hasOwnProperty('category')) {
        categories = data.category;
      }
      categories = (this.result as any).aggregate.category;
    } else {
      data = (this.result as any)[type].aggregate;
      if (data.hasOwnProperty('category')) {
        categories = data.category;
      }
    }
    if (!!categories) {
      categories = this.filteredProductCategory = { ...Object.values(categories).filter(e => {
          if (e.hasOwnProperty(filter)) {
            return !!e[filter];
          }
        })
      };
      return Object.values(categories).sort((a, b) => a[sortBy] < b[sortBy] ? 1 : (b[sortBy] < a[sortBy] ? -1 : 0));
    }
  }
  public getAmountOf(key: TReportError): number {
    const type = this.reportData.result[this.reportName][key];
    return !!type ? type.length : 0;
  }
  

  public getAggregatedErrorMessage(item): string {
    if (item.hasOwnProperty('type')) {
      switch (item.type) {
        case EXCEPTION.RECYCLE_PRECENTAGE_NOT_SET:
          return this.translateService.instant('AGGREGATED_WARNING_TYPE_RECYCLE_PRECENTAGE_NOT_SET', { Y: item.counter});
        case EXCEPTION.CONVERSION_NOT_SET:
          return this.translateService.instant('AGGREGATED_ERROR_TYPE_CONVERSION_NOT_SET', { Y: item.counter});
        case EXCEPTION.CATEGORY_NULL:
          return this.translateService.instant('AGGREGATED_WARNING_TYPE_CATEGORY_NULL', { Y: item.counter});
        case EXCEPTION.CATEGORY_PARENT:
          return this.translateService.instant('AGGREGATED_WARNING_TYPE_CATEGORY_PARENT', { Y: item.counter});
        case EXCEPTION.CARBON_PRODUCT_NOT_SET:
          return this.translateService.instant('AGGREGATED_WARNING_CARBON_PRODUCT_NOT_SET', { Y: item.counter});
        case EXCEPTION.DEPOSIT_FAILLING_TO_MATCHE_WITH_GP:
          return this.translateService.instant('AGGREGATED_ERROR_DEPOSIT_FAILLING_TO_MATCHE_WITH_GP', { Y: item.counter});
        case EXCEPTION.CONVERSION_FACTOR_FUNCTIONAL_UNIT_NOT_SET:
          return this.translateService.instant('AGGREGATED_ERROR_TYPE_CONVERSION_FACTOR_FUNCTIONAL_UNIT_NOT_SET', { Y: item.counter});
        case EXCEPTION.LACK_FINANCIAL_DATA:
          return this.translateService.instant('AGGREGATED_ERROR_LACK_FINANCIAL_DATA', { Y: item.counter});
        case EXCEPTION.PURCHASE_PRICE_MISSING:
          return this.translateService.instant('AGGREGATED_WARNING_PURCHASE_PRICE_MISSING', { Y: item.counter});
        case EXCEPTION.CONVERSION_FACTOR_MISSING:
          return this.translateService.instant('AGGREGATED_WARNING_CONVERSION_FACTOR_MISSING', { Y: item.counter});
        case EXCEPTION.DEPOSIT_MATCH_NOT_RELEVANT:
          return this.translateService.instant('AGGREGATED_WARNING_DEPOSIT_MATCH_NOT_RELEVANT', {X: (item.message as string).slice(26, -37)});
        case EXCEPTION.CARBON_CONSTRUCTION_NOT_SET:
          return this.translateService.instant('AGGREGATED_WARNING_CARBON_CONSTRUCTION_NOT_SET', { Y: item.counter});
        case EXCEPTION.CARBON_END_NOT_SET:
          return this.translateService.instant('AGGREGATED_WARNING_CARBON_END_NOT_SET', { Y: item.counter});
        case EXCEPTION.CARBON_USE_NOT_SET:
          return this.translateService.instant('AGGREGATED_WARNING_CARBON_USE_NOT_SET', { Y: item.counter});
        case EXCEPTION.CARBON_RECOVERY_NOT_SET:
          return this.translateService.instant('AGGREGATED_WARNING_CARBON_RECOVERY_NOT_SET', { Y: item.counter});
        case EXCEPTION.NO_INFORMATION_PRODUCTION:
          return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_PRODUCTION', { Y: item.counter});
        case EXCEPTION.NO_INFORMATION_CONSTRUCTION:
          return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_CONSTRUCTION', { Y: item.counter});
        case EXCEPTION.NO_INFORMATION_EMBODIED:
          return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_EMBODIED', { Y: item.counter});
        case EXCEPTION.NO_INFORMATION_IC_COMPONENT:
          return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_IC_COMPONENT', { Y: item.counter});
        case EXCEPTION.NO_INFORMATION_IC_CONSTRUCTION:
          return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_IC_CONSTRUCTION', { Y: item.counter});
        case EXCEPTION.USE_PERIOD_NOT_SET:
          return this.translateService.instant('AGGREGATED_WARNING_USE_PERIOD_NOT_SET', { Y: item.counter});
        default:
          return this.translateService.instant(item.message);
      }
    }
    return '';
  }

  public getErrorMessage(item): string {
    if (item.hasOwnProperty('type')) {
      switch (item.type) {
        case EXCEPTION.RECYCLE_PRECENTAGE_NOT_SET:
          return this.translateService.instant('WARNING_TYPE_RECYCLE_PRECENTAGE_NOT_SET');
        case EXCEPTION.CONVERSION_NOT_SET:
          return this.translateService.instant('ERROR_TYPE_CONVERSION_NOT_SET');
        case EXCEPTION.CATEGORY_NULL:
          return this.translateService.instant('WARNING_TYPE_CATEGORY_NULL');
        case EXCEPTION.CATEGORY_PARENT:
          return this.translateService.instant('WARNING_TYPE_CATEGORY_PARENT');
        case EXCEPTION.CARBON_PRODUCT_NOT_SET:
          return this.translateService.instant('WARNING_CARBON_PRODUCT_NOT_SET');
        case EXCEPTION.DEPOSIT_FAILLING_TO_MATCHE_WITH_GP:
          return this.translateService.instant('ERROR_DEPOSIT_FAILLING_TO_MATCHE_WITH_GP');
        case EXCEPTION.CONVERSION_FACTOR_FUNCTIONAL_UNIT_NOT_SET:
          return this.translateService.instant('ERROR_TYPE_CONVERSION_FACTOR_FUNCTIONAL_UNIT_NOT_SET');
        case EXCEPTION.LACK_FINANCIAL_DATA:
          return this.translateService.instant('ERROR_LACK_FINANCIAL_DATA');
        case EXCEPTION.PURCHASE_PRICE_MISSING:
          return this.translateService.instant('WARNING_PURCHASE_PRICE_MISSING');
        case EXCEPTION.CONVERSION_FACTOR_MISSING:
          return this.translateService.instant('WARNING_CONVERSION_FACTOR_MISSING');
        case EXCEPTION.DEPOSIT_MATCH_NOT_RELEVANT:
          return this.translateService.instant('WARNING_DEPOSIT_MATCH_NOT_RELEVANT', {X: (item.message as string).slice(26, -37)});
        case EXCEPTION.CARBON_CONSTRUCTION_NOT_SET:
          return this.translateService.instant('WARNING_CARBON_CONSTRUCTION_NOT_SET');
        case EXCEPTION.CARBON_END_NOT_SET:
          return this.translateService.instant('WARNING_CARBON_END_NOT_SET');
        case EXCEPTION.CARBON_USE_NOT_SET:
          return this.translateService.instant('WARNING_CARBON_USE_NOT_SET');
        case EXCEPTION.CARBON_RECOVERY_NOT_SET:
          return this.translateService.instant('WARNING_CARBON_RECOVERY_NOT_SET');
        case EXCEPTION.NO_INFORMATION_PRODUCTION:
          return this.translateService.instant('ERROR_NO_INFORMATION_PRODUCTION');
        case EXCEPTION.NO_INFORMATION_CONSTRUCTION:
          return this.translateService.instant('ERROR_NO_INFORMATION_CONSTRUCTION');
        case EXCEPTION.NO_INFORMATION_EMBODIED:
          return this.translateService.instant('ERROR_NO_INFORMATION_EMBODIED');
        case EXCEPTION.NO_INFORMATION_IC_COMPONENT:
          return this.translateService.instant('ERROR_NO_INFORMATION_IC_COMPONENT');
        case EXCEPTION.NO_INFORMATION_IC_CONSTRUCTION:
          return this.translateService.instant('ERROR_NO_INFORMATION_IC_CONSTRUCTION');
        case EXCEPTION.USE_PERIOD_NOT_SET:
          return this.translateService.instant('WARNING_USE_PERIOD_NOT_SET');
        default:
          return this.translateService.instant(item.message);
      }
    }
    return '';
  }

  public onEachChunkedCategory(onEachChunk: Function): void {
    return chunkAnArray<U>(this.categories, this.categoriesPerPage, this.firstPageCategoriesAmount)
      .forEach((chunk: U[], index: number) => onEachChunk(chunk, index));
  }

  public onEachChunkedError(onEachChunk: Function): void {
    return chunkAnArray<IReportError>(this.errors, this.errorsPerPage)
      .forEach((chunk: IReportError[], index: number) => onEachChunk(chunk, index));
  }

  public isError(type: number): boolean {
    return isError(type);
  }

  numberFormat(value: number, greaterThen: string = '1.0-1', lessThen: string = '1.2-2'): string {
    return value > 1 ? greaterThen : (value === 0 ? '0.0-0' : lessThen);
  }

  private afterSiteListRowsCounted() {
    if (this.rowCounter) {
      this.rowCounter.updateRowCount$.pipe(first()).subscribe(
        () => this.showSitesInOnePage = this.rowCounter.isRowNumberBiggerThan(2)
      );
    }
  }
  public addTitlePagesNumber(index: number) {
    return (this.showSitesInOnePage ? 3 : 2) + index;
  }
}
