import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 8.02.17.
 */
import { PassportFileService } from '../services/passport-file.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
var PassportFile = /** @class */ (function (_super) {
    tslib_1.__extends(PassportFile, _super);
    function PassportFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = PassportFileService;
        return _this;
    }
    PassportFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'passport_id', 'type'
        ]);
    };
    PassportFile.prototype.isVisibleForAll = function () {
        return this.type == null;
    };
    return PassportFile;
}(UploadedDocument));
export { PassportFile };
