import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { DepositFile } from '../models/deposit-file.class';
var DepositFileService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositFileService, _super);
    function DepositFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'deposit-import-files';
        _this.namespaceSingular = 'deposit-import-file';
        _this.ModelClass = DepositFile;
        return _this;
    }
    return DepositFileService;
}(CrudService));
export { DepositFileService };
