<section test-id="superadmin-users-tabs"
         class="ecosystem-dashboard__section ecosystem-dashboard__section--tabs"
>

  <article class="ecosystem-catalog">
    <!--<header class="ecosystem-catalog__header">-->
    <!--<ul class="ecosystem-catalog__caption-list">-->
    <!--<li class="ecosystem-catalog__caption-item" (click)="changeTab(0)" [ngClass]="{'active': activeIndex === 0}">-->
    <!--<span class="ecosystem-catalog__caption-title">{{'Users' | translate}}</span>-->
    <!--<span class="ecosystem-catalog__caption-counter" *ngIf="false">4</span>-->
    <!--</li>-->
    <!--<li class="ecosystem-catalog__caption-item" (click)="changeTab(0)" [ngClass]="{'active': activeIndex === 1}">-->
    <!--<span class="ecosystem-catalog__caption-title">{{'Companies' | translate}}</span>-->
    <!--<span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>-->
    <!--</li>-->
    <!--</ul>-->
    <!--</header>-->
    <section test-id="content"
             class="ecosystem-catalog__content users-list"
    >
      <ul class="ecosystem-catalog__content-list">
        <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 0">
          <div class="row controls">
              <div class="filter-wrapper">
                <i class="icon icon--gray-person"></i>
                <md-select test-id="role-selector"
                           [placeholder]="'Role' | translate"
                           [formControl]="roleControl"
                           class="role"
                >
                  <md-option>
                    {{ 'Any' | translate }}
                  </md-option>
                  <md-option
                    *ngFor="let USER_ROLE of userRoles"
                    [value]="USER_ROLE.value"
                  >
                    {{USER_ROLE.view | translate}}
                  </md-option>
                </md-select>
              </div>
            <div class="filter-wrapper">
                <i class="icon icon--gray-sort"></i>
                <md-select [placeholder]="'Sort by' | translate" [formControl]="sortControl" class="sort">
                  <md-option value="name">{{'Name' | translate}}</md-option>
                  <md-option value="-created_at">{{'Creation date' | translate}}</md-option>
                </md-select>
              </div>

            <div class="filter-wrapper">
                <i class="icon icon--gray-search"></i>
                <md-input-container class="search">
                  <input test-id="search-input"
                         autocomplete="off"
                         mdInput
                         [placeholder]="'Search' | translate"
                         [formControl]="searchControl"
                  >
                </md-input-container>
              </div>
              
            <span class="left">
                <button routerLink="new" class="create-new-user">{{'Create new users' | translate}}</button>
              </span>
              
          </div>
          <div class="filter-row">
            <toggle-independent
              class="needlist__filters-toggle needlist__filters-margin-right"
              (toggle)="toggleNoahOnly($event)"
              [text]="'SEE_NOAH_ACCOUNT'"
              [isChecked]="noahOnly"
              [img]="'assets/png/cloud_grey.png'">
            </toggle-independent>
          </div>

          <ul test-id="user-list" class="users">
            <li test-id="user-item"
                *ngFor="let user of users | paginate: {id:'users'
                , itemsPerPage: pageInfo.perPage
                , currentPage: pageInfo.currentPage
                , totalItems: pageInfo.totalCount }"
                [routerLink]="user.id + ''"
            >
              <div class="profile-image">
                <div [ngStyle]="getAvatar(user)"></div>
              </div>
              <div *ngIf="user.status==0"><strong>{{ 'INACTIVE' | translate }}</strong><br></div>
              <strong class="name">{{user.viewName}}</strong><br>
              <div style="color:#C0C0C0">
                <span *ngFor="let role of user.roles; let index = index">
                  {{ index === 0 ? '' : (role | translate) }}{{ index === user.roles.length - 1 || index === 0 ? '' : ','}}
                </span>
                <span *ngIf="parentModule == moduleNames.SPECIAL_ADMIN;else elseBlock">
                - {{user?.company_name}}
              </span>
                <ng-template #elseBlock>- {{user?.company?.name}}</ng-template>
              </div>
              <span class="address"></span><br>
              <a class="email">
                <i class="icon icon--gray-mail"></i>
                {{user.email}}
              </a>
              <a class="phone-number" *ngIf="user.phone">
                <i class="icon icon--gray-phone"></i>
                {{user.phone}}
              </a><br>
              <a class="chat-with"
                 (click)="openChat(user.id, $event)"
                 *ngIf="user.id !== _currentUserService.infoData.id">
                <i class="icon icon--gray-chat"></i>
                {{'Chat' | translate}}
              </a>
            </li>
          </ul>
          <pagination-controls
            class="custom-pagination"
            id="users"
            (pageChange)="pageControl.setValue($event)"
            maxSize="8"
            directionLinks="true"
            autoHide="true"
            previousLabel=""
            nextLabel=""
            screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
            screenReaderPageLabel="{{ 'page' | translate }}"
            screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"
          ></pagination-controls>
        </li>
        <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 1">{{'Not implemented yet' | translate}}</li>
      </ul>
    </section>
  </article>
</section>
