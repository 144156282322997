<div class="accepted-materials__list">
  <label class="accepted-materials__label primary-color">
    {{'Type of materials' | translate}}
  </label>
  <md-checkbox class="accepted-materials__item" [ngModel]="transform.material_chemical" (ngModelChange)="transform.material_chemical = 0 + $event">
    {{'Dangerous chemical' | translate}}
  </md-checkbox>
  <md-checkbox class="accepted-materials__item" [ngModel]="transform.material_infectious" (ngModelChange)="transform.material_infectious = 0 + $event">
    {{'Infectious product' | translate}}
  </md-checkbox>
  <md-checkbox class="accepted-materials__item" [ngModel]="transform.material_asbesthos" (ngModelChange)="transform.material_asbesthos = 0 + $event">
    {{'Asbesthos product' | translate}}
  </md-checkbox>
  <md-checkbox class="accepted-materials__item" [ngModel]="transform.material_inert" (ngModelChange)="transform.material_inert = 0 + $event">
    {{'Inert product' | translate}}
  </md-checkbox>
  <md-checkbox class="accepted-materials__item" [ngModel]="transform.material_solid" (ngModelChange)="transform.material_solid = 0 + $event">
    {{'Solid non hazardous product' | translate}}
  </md-checkbox>
  <md-checkbox class="accepted-materials__item" [ngModel]="transform.material_liquid" (ngModelChange)="transform.material_liquid = 0 + $event">
    {{'Liquid or wet non hazardous product' | translate}}
  </md-checkbox>
  <md-checkbox class="accepted-materials__item accepted-materials__item-other" [checked]="!!transform.material_other">
    <md-input-container [floatPlaceholder]="'never'">
      <input
        mdInput
        autocomplete="off" 
        [placeholder]="'Other' | translate"
        [(ngModel)]="transform.material_other"
      >
    </md-input-container>
  </md-checkbox>
</div>


