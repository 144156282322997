/**
 * Created by Aleksandr C. on 8.02.17.
 */
import { PassportFileService } from '../services/passport-file.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';

export class PassportFile extends UploadedDocument {
  protected provider = PassportFileService;

  passport_id: number;
  type: number;

  protected fields() {
    return super.fields().concat([
      'passport_id', 'type'
    ]);
  }

  isVisibleForAll() {
    return this.type == null;
  }
}
