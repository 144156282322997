<div>
  <div *ngIf="isAnyReports(); else noDeposit">
    <residual-value-report
      *ngFor="let reportType of reportTypes"
      [type]="reportType"
      [report]="report"
      [changeTab]= "changeTab"
    >
    </residual-value-report>
  </div>
  <ng-template #noDeposit class="error-section">
    <h3 class="error-section__title">{{ 'No deposit considered' | translate }}</h3>
  </ng-template>
</div>

