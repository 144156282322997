import { Component, Inject } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
@Component({
  moduleId: module.id,
  template: `
    <img
      src="assets/png/ecosystem/icon--close_s.png"
      class="close-button"
      (click)="mdDialogRef.close()"
    >

    <stakeholder-details
      [id]="mdDialogData.id"
      [readOnly]="mdDialogData.readOnly"
      (saved)="mdDialogRef.close()"
    ></stakeholder-details>
  `,
  host: {
    'class': 'dialog-close-button-container',
  },
  styles: [`
    :host {
      display: block;
      position: relative;
    }

    .close-button {
      right: 16px;
      top: 14px;
      width: 16px;
      height: 16px;
      position: absolute;
      cursor: pointer;
    }
  `],
})
export class StakeholderDetailsDialogComponent {
  constructor(public mdDialogRef: MdDialogRef<any>,
              @Inject(MD_DIALOG_DATA) public mdDialogData: any) {
  }
}
