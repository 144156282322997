/**
 * Created by Aleksandr C. on 6.02.17.
 */
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Need } from '../../../models/need.class';
import { Passport } from '../../../../passport/models/passport.class';
import { MdDialog } from '@angular/material';
import {NavigationService} from '../../../../../shared/services/navigation.service';

@Component({
  moduleId: module.id,
  selector: 'product-passport-step',
  templateUrl: 'product-passport-step.component.html',
  styleUrls: ['product-passport-step.component.scss'],
})

export class ProductPassportStepComponent implements OnInit, OnDestroy {
  @Input() need: Need;
  @Input() showCatalogNameMenu: boolean;
  @Input() controlStates: any = {};
  @Input() public listener?: EventEmitter<any>;
  @Output() onSaveComplete = new EventEmitter();
  @Input() readOnly = false;
  parentModule: string;

  constructor(public dialog: MdDialog, private navigationService: NavigationService) {
    this.parentModule = this.navigationService.getModuleName();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  onChangePassport(passport: Passport) {
    this.need.setPassport(passport);
  }

  onSaveCompleteHandler($event) {
    this.onSaveComplete.emit($event);
  }
}
