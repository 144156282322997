/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-tab.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../app/components/icons/pen-icon.component.ngfactory";
import * as i3 from "../../../app/components/icons/pen-icon.component";
import * as i4 from "../../../shared/read-only/form-elems-hidden.directive";
import * as i5 from "../../../shared/read-only/read-only.service";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./filter-tab.component";
var styles_FilterTabComponent = [i0.styles];
var RenderType_FilterTabComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterTabComponent, data: {} });
export { RenderType_FilterTabComponent as RenderType_FilterTabComponent };
function View_FilterTabComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pen-icon", [], null, null, null, i2.View_PenIconComponent_0, i2.RenderType_PenIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.PenIconComponent, [], null, null)], null, null); }
function View_FilterTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "button", [["class", "tab"]], [[2, "active", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTab(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i6.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterTabComponent_2)), i1.ɵdid(5, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["(", ")"]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_2); var currVal_3 = "pen"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentTab === _v.context.$implicit.name); var currVal_1 = ((i1.ɵnov(_v, 1).readOnlyService == null) ? null : i1.ɵnov(_v, 1).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.name)); _ck(_v, 6, 0, currVal_4); var currVal_5 = ((_v.context.$implicit.count != null) ? _v.context.$implicit.count : "..."); _ck(_v, 9, 0, currVal_5); }); }
export function View_FilterTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "filter-tabs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterTabComponent_1)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FilterTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-tab", [], null, null, null, View_FilterTabComponent_0, RenderType_FilterTabComponent)), i1.ɵdid(1, 114688, null, 0, i8.FilterTabComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterTabComponentNgFactory = i1.ɵccf("app-filter-tab", i8.FilterTabComponent, View_FilterTabComponent_Host_0, { tabs: "tabs", initialTab: "initialTab" }, { tabSelected: "tabSelected" }, []);
export { FilterTabComponentNgFactory as FilterTabComponentNgFactory };
