<div class="residual-value-third-chart" *ngIf="report && type && hasCategory()">

  <div class="residual-value-third-chart__title" *ngIf="!isPDFReport">
    <h3>{{ 'All categories' | translate | uppercase}}</h3>
    <div class="residual-value-third-chart__title--hr"></div>
  </div>

  <div [ngClass]="{'residual-value-third-chart__charts': !isPDFReport,'residual-value-third-chart__charts--PDF': isPDFReport}">
    <div class="residual-value-third-chart__block">
      <div class="residual-value-third-chart-bar">
        <section [ngClass]="{'residual-value-third-chart-bar__chart': !isPDFReport,'residual-value-third-chart-bar__chart--PDF': isPDFReport}">
          <div class="residual-value-third-chart-bar__chart--wrapper">
            <div class="residual-value-third-chart-bar__chart--content">
              <div
                class="residual-value-third-chart-bar__chart--left-text-about-arrow residual-value-third-chart__description--text" *ngIf="showAxisLabel">
                <p [innerHTML]="'Percentage of residual value over the initial material cost (%)' | translate"></p>
              </div>

              <div class="residual-value-third-chart-bar__chart--left-number-wrapper ">
                <div *ngFor="let item of getVerticalAxisValues(); let i = index"
                     [ngClass]="{'residual-value-third-chart-bar__chart--left-number': !isPDFReport,'residual-value-third-chart-bar__chart--left-number--PDF': isPDFReport}">
                  <p>{{item}}%</p>
                </div>
              </div>
              <div class="residual-value-third-chart-bar__chart--columns">
                <div class="residual-value-third-chart-bar__chart--column-hr-wrapper">
                  <div *ngFor="let item of numberOfHrMarginTopValues(); let i = index"
                       class="residual-value-third-chart-bar__chart--column-hr-items">
                    <div [ngStyle]="{'margin-top': item}"
                         class="residual-value-third-chart-bar__chart--column-hr-item"></div>
                  </div>
                </div>
                <div *ngFor="let item of chartCategories"
                      [ngClass]="{'residual-value-third-chart-bar__chart--column': !isPDFReport,'residual-value-third-chart-bar__chart--column--PDF': isPDFReport}">
                  <div class="residual-value-third-chart-bar__chart--column-title-wrapper">
                  </div>

                  <div [ngStyle]="{'height': getGraphicCanvasHeight()}"
                       [ngClass]="{'residual-value-third-chart-bar__chart--column-pillar-wrapper': !isPDFReport,'residual-value-third-chart-bar__chart--column-pillar-wrapper--PDF': isPDFReport}">
                    <div
                      [ngStyle]="{
                        'height': getAmountForPillarInPixels(item.data.high, item.data.low),
                        'bottom': getBottomForPillarInPixels(item.data.high, item.data.low),
                        'min-height': isMaxMoreThanMinByOnePercent(item.data.high, item.data.low)
                          ? heightLimitForTwoCircles + 'px'
                          : heightLimitForOneCircle + 'px'
                       }"
                      [ngClass]="[
                        'residual-value-third-chart__value-wrapper',
                        'residual-value-third-chart-bar__chart--column-pillar-radius',
                        getModifiedClassName('residual-value-third-chart-bar__chart--column-pillar', item.type)
                      ]"
                    >
                      <div
                        [ngClass]="[
                          'residual-value-third-chart__high-value',
                          'residual-value-third-chart-bar__chart--column-pillar-circle',
                          getModifiedClassName('residual-value-third-chart-bar__chart--column-pillar-circle-up', item.type),
                          'residual-value-third-chart-bar__chart--column-pillar-circle-'+item.type
                        ]"
                      >
                        <p class="residual-value-third-chart-bar__chart--column-pillar-circle-value">
                          {{item.data.high | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}%
                        </p>
                      </div>

                      <div
                        *ngIf="item.data.high - item.data.low !== 0"
                        [ngClass]="[
                          'residual-value-third-chart__low-value',
                          'residual-value-third-chart-bar__chart--column-pillar-circle',
                          getModifiedClassName('residual-value-third-chart-bar__chart--column-pillar-circle-up', item.type),
                          'residual-value-third-chart-bar__chart--column-pillar-circle-'+item.type
                        ]"
                      >
                        <p class="residual-value-third-chart-bar__chart--column-pillar-circle-value">
                          {{getAverageOrMinValue(item.data.high, item.data.low) | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}%
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="residual-value-third-chart-bar__chart--column-footer" [ngStyle]="{color: item.color}">
                    <h4 [ngStyle]="{fontSize: item.type === 'total' ? '18px' : '12px'}">{{item.name | translate}}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </div>
  </div>

</div>
