<ul class="storing-sites">
  <!--class="storing-sites__item" currently is used only in tests to filter out li elements inside custom lists-->
  <li
    *ngFor="let location of transform.locations; let last = last; let index = index"
    class="storing-sites__item"
    id="{{ location.id }}"
  >
    <button
      class="delete storing-sites__icon primary-color"
      *ngIf="!readOnly"
      (click)="remove(location)"
    ></button>

    <custom-search
      caption="{{ 'Name' | translate }} *"
      class="site"
      [disabled]="readOnly"
      [service]="locationService"
      [preset]="location.site"
      [listener]="getListener(index)"
      [touchHandler]="getOnTouchListener(index)"
      [label]="'Create' | translate"
      (onCreate)="onCreate($event, location)"
      (onSelect)="onSelect($event, location, index)"
      (onChangeName)="onChangeName($event, location)"
      [required]="true"
    ></custom-search>
    <md-input-container class="address">
      <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
        <input
          mdInput
          name="address"
          placeholder="{{ 'Address' | translate }} *"
          [(ngModel)]="location.site.address"
          placeAutocomplete
          required
          [disabled]="this.readOnly || !location.site.id"
          (placeChange)="onPlaceChange($event, location)"
          (change)="onTextChange(location)"
        >
      </place-autocomplete>
    </md-input-container>
    <md-input-container class="description">
      <input
        mdInput
        autocomplete="off"
        [placeholder]="'Description' | translate"
        [(ngModel)]="location.site.description"
        [disabled]="readOnly || !location.site.id"
        (change)="save(location)"
      >
    </md-input-container>

    <button
      class="add storing-sites__icon primary-color"
      *ngIf="last && !readOnly"
      (click)="add()"
    ></button>
  </li>
</ul>
