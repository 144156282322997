import { LogNotificationService } from './../../../../shared/services/log-notification.service';
import { Need } from './../../models/need.class';
import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, QueryList, Renderer, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, } from '@angular/common';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs';
import { MdDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, map } from 'rxjs/operators';
import { NeedService } from '../../services/need.service';
import { SalesOfferDialogComponent } from '../../../../dashboards/sales/dialogs/sales-offer-dialog/sales-offer-dialog.component';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs/Observable';
import { ClientService } from '../../../client/service/client.service';
import { LocationService } from '../../../client/service/location.service';
import { DepositLocationService } from '../../../deposit/services/deposit-location.service';
import { StyleLoaderService } from '../../../../shared/services/style-loader.service';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { EcosystemOfferService } from '../../../ecosystem-offer/ecosystem-offer.service';
import { EcosystemOffer } from '../../../ecosystem-offer/ecosystem-offer.model';
import { TabState } from './need-list.const';
import { NguiInfiniteListDirective } from '@ngui/infinite-list';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { ReportProblemDialogComponent } from '../../../../shared/report-problem-dialog/report-problem-dialog.component';
import { IReportProblemDialogInput } from '../../../../shared/report-problem-dialog/values/report-problem-dialog-input.interface';
import { NotificationSuccessComponent } from '../../../../shared/notification-dialogs/notification-success/notification-success.component';
import { DepositDetailsDialogComponent } from '../../../deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { NEED_STATUSES } from '../../values/need-statuses.const';
import { GET_OFFER_STATUS_LABEL, ECOSYSTEM_OFFER_STATUS } from '../../../../../src/dashboards/ecosystem-manager/values/ecosystem-offer-status.const';
import { OfferTransactionConfirmDialogComponent } from '../../../../../src/entities/ecosystem-offer/dialogs/offer-transaction-confirm-dialog/offer-transaction-confirm-dialog.component';
import { NeedFilterComponent } from './need-filter/need-filter.component';

@Component({
  moduleId: module.id,
  selector: 'need-list',
  templateUrl: 'need-list.component.html'
})
export class NeedListComponent implements OnInit, OnDestroy {

  @Input() activeIndex: number;

  public needs: Need[] = [];
  public recipientList = [];
  public deliveryLocationList = [];
  public depositLocationList = [];
  public activeTab: number = TabState.CREATION.index;
  public page: number = 1;
  public searchKeyChange = new Subject();

  // Constant for sorting options
  public NEED_SORT_ORDER: { key: string, value: string }[] = [
    { key: 'need.created_at', value: 'Creation date' },
    { key: '-need.delivery_date', value: 'Delivery date' },
    { key: 'need.client_name', value: 'Recipient name' }
  ];

  public pageInfo = {
    totalCount: 1,
    perPage: 10,
    pageCount: 1,
    currentPage: 1
  };

  private _confirmDeletionText: string;
  private _confirmDeletionSubText: string;
  private _needStatus: number[] = [
    NEED_STATUSES.DRAFT.value,
    NEED_STATUSES.WAITING_FOR_PUBLICATION.value,
    NEED_STATUSES.ASK_FOR_MODIFICATION.value,
    NEED_STATUSES.PUBLISHED.value,
  ];
  private isActiveTab: boolean = true;
  private needSubscription: Subscription;

  @ViewChild(NeedFilterComponent) needFilter: NeedFilterComponent;
  @ViewChildren(NguiInfiniteListDirective) columnList: QueryList<NguiInfiniteListDirective>;

  constructor(
    private _translate: TranslateService,
    private _router: Router,
    private _clientService: ClientService,
    private _locationService: LocationService,
    private _depositLocationService: DepositLocationService,
    private _needService: NeedService,
    private _offerService: EcosystemOfferService,
    private route: ActivatedRoute,
    private _dialog: MdDialog,
    private styleLoader: StyleLoaderService,
    private navBarState: NavbarStateService,
    private location: Location,
    private renderer: Renderer,
    private notification: LogNotificationService
  ) {

    this.initTranslations();
  }

  updateFilters() {
    this.page = 1;
    this.reloadRecords();
  }

  ngOnInit() {
    this.getRecipientList();
    this.getDepositLocationList();
    this.reloadRecords();
  }

  /**
   * Get the list of clients available to the user from the backend
   */
  getRecipientList() {
    this._clientService.search().subscribe((res: any) => {
      this.recipientList = res.map(client => {
        return {
          selected: false,
          value: client.id,
          text: client.name,
          view: client.name
        }
      });
      this.getDeliveryLocationList();
    });
  }

  /**
   * Get the list of locations available to the user from the backend
   */
  getDeliveryLocationList() {
    let recipientIdList = this.recipientList.map(recipient => recipient.value);
    this._locationService.search({ 'client[]': recipientIdList }).subscribe((res: any) => {
      this.deliveryLocationList = res.map(location => {
        return {
          selected: false,
          value: location.id,
          text: location.name,
          view: location.name
        }
      });
    })
  }

  /**
   * Get the list of deposit locations available to the user from the backend
   */
  getDepositLocationList() {
    this._depositLocationService.getTargetedNeedLocations().subscribe((res: any) => {
      this.depositLocationList = res.map(location => {
        return {
          selected: false,
          value: location.id,
          text: location.name,
          view: location.name
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.needSubscription) {
      this.needSubscription.unsubscribe();
    }
    this.styleLoader.removeStyle('wide-tooltip');
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  private initTranslations(): void {
    this._translate.get('CONFIRM_DELETE_NEED').subscribe((translation) => {
      this._confirmDeletionText = translation;
    });

    this._translate.get('Confirm_delete_need_SubText').subscribe((translation) => {
      this._confirmDeletionSubText = translation;
    });

  }

  onNeedsOrArchivesChange() {
    this.reloadRecords();
  }

  openTab(index: number): void {
    this.activeTab = index;
    const newPath = this.activeTab === TabState.CREATION.index ? 'sales/needs' : `sales/needs#${TabState.TREATMENT.text}`;
    this.location.go(newPath);
    this.reloadRecords();
  }

  duplicateRecord(record: Need) {
    record.duplicate().subscribe((res: Need) => {
      this.reloadRecords();
    });
  }

  archiveNeed(record: Need): void {
    record.archive().subscribe((res: Need) => {
      this.reloadRecords();
    }
    );
  }

  deleteRecord(record: Need): void {
    record.canDelete()
      .pipe(
        switchMap(response => {
          const canDelete = response.canDelete;
          if (canDelete) {
            const dialogRef = this._dialog.open(ConfirmDialogComponent, {
              data: {
                text: this._confirmDeletionText,
                imgUrl: 'assets/png/warning_purple.png',
                subText: this._confirmDeletionSubText
              }
            });

            return dialogRef.afterClosed().pipe(
              switchMap(confirmed => confirmed ? record.destroy().pipe(map(() => true)) : Observable.of(false))
            );
          } else {
            if (this.notification)
              this.notification.error('need.canNotBeDeleted', null, true);
            return Observable.of(false);
          }
        })
      )
      .subscribe(deleted => {
        if (deleted) this.reloadRecords();
      });
  }

  reportRecord(offer: EcosystemOffer) {
    const dialogRef = this._dialog.open(ReportProblemDialogComponent, {
      ...DIALOG_THEME.PROBLEM_REPORT,
      data: {
        readOnly: false,
        title: 'Problem with offer',
        foreword: 'Your need has not been met?',
        onConfirm: description => this._offerService.sendReport(offer.id, description).map(() => true)
      } as IReportProblemDialogInput,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this._dialog.open(NotificationSuccessComponent, DIALOG_THEME.PROBLEM_REPORT);
        this.reloadRecords();
      }
    });
  }

  confirmRecord(offer: EcosystemOffer) {
    if (offer && offer.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION) {
      this._dialog.open(OfferTransactionConfirmDialogComponent, {
        ...DIALOG_THEME.TRANSACTION_ORDER_CONFIRMATION, data: { id: offer.id }
      })
        .afterClosed().subscribe((result) => {
          if (result) {
            this.reloadRecords();
          }
        });
    }
  }

  reloadRecords() {
    this.needs = [];
    this.loadNeedsAndOffers();
  }

  pageChange($event: number) {
    this.page = $event;
    this.reloadRecords();
  }

  loadNeedsAndOffers() {
    if (this.needSubscription) {
      this.needSubscription.unsubscribe();
    }
    const needOptions = this.buildQueryOptions(this.page);

    this.needSubscription = this._needService.getWithArray(needOptions).subscribe((res) => {
      const needs = res.json().map((item: any) => {
        const need = new Need(item);
        need.offer = item.ecosystem_offers.map((offer: any) => new EcosystemOffer(offer));
        return need;
      });
      this.pageInfo = {
        totalCount: Number(res.headers.get('X-Pagination-Total-Count')),
        perPage: Number(res.headers.get('X-Pagination-Per-Page')),
        pageCount: Number(res.headers.get('X-Pagination-Page-Count')),
        currentPage: Number(res.headers.get('X-Pagination-Current-Page'))
      };
      this.needs = needs;
    });
  }


  private buildQueryOptions(page: number): any[] {
    let options: any[] = this.needFilter.buildOptions();

    options.push(['expand', 'client,client_location,client_contact,client_name,nomenclature,passport,display_status,fromDepositEntity,fromDepositEntity.depositPhotos,fromDepositEntity.deposit_photos,ecosystem_offers.ecosystem,ecosystem_count,deposit_photos']);
    options.push(['paginate', true]);
    options.push(['page', page]);
    if (this.navBarState.multilanguage) options.push(['english', true]);
    return options;
  }

  viewDeposit(need: Need) {
    this._dialog.open(DepositDetailsDialogComponent, {
      data: {
        id: need.from_deposit,
        readOnly: true
      }
    });
  }

  viewNeed(need: Need) {
    this._router.navigate(['sales/needs', need.id, 'edit']);
  }

  viewOffer(offer: EcosystemOffer, need: Need) {
    this
      ._dialog
      .open(SalesOfferDialogComponent, {
        data: {
          offer: offer,
          need: need
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.reloadRecords();
      })
      ;
  }

  isListPage(): boolean {
    return true;
  }

  navigateToArchivePage() {
    this._router.navigate(['sales/needs/archive']);
  }

  getTabTooltipContent(): string {
    if (!this.isListPage()) {
      return this._translate.instant('ARCHIVE_TOOLTIP');
    }
    return this._translate.instant(this.activeTab === 1
      ? 'NEED_TABS_TREATMENT_TOOLTIP'
      : 'NEED_TABS_CREATION_TOOLTIP'
    );
  }
}
