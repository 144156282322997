import {Component, Inject} from '@angular/core';
import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';

@Component({
  template: `
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>

    <deposit-site-details-preview
      [location]="mdDialogData.location"
      [uploader]="mdDialogData.uploader"
      [readOnly]="mdDialogData.readOnly"
      [isValid]="mdDialogData.isValid || defaultValidation"
      (onSave)="mdDialogRef.close($event)"
    ></deposit-site-details-preview>
  `,
    host: {
      'class': 'dialog-close-button-container',
    },
})
export class DepositSiteDetailsPreviewDialogComponent {
  defaultValidation = () => true;
  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<void>) {}
}
