import * as tslib_1 from "tslib";
import { Stakeholder } from '../models/stakeholder.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var StakeholderService = /** @class */ (function (_super) {
    tslib_1.__extends(StakeholderService, _super);
    function StakeholderService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'stakeholders';
        _this.namespaceSingular = 'stakeholder';
        _this.ModelClass = Stakeholder;
        return _this;
    }
    StakeholderService.prototype.getStakeholders = function (searchParam) {
        var options = !!searchParam
            ? { search: { search: searchParam } }
            : {};
        return this.sendGet(this.getEndpoint(this.namespaceSingular + '/list'), options)
            .map(function (res) { return res.json(); });
    };
    return StakeholderService;
}(ExtendedCrudService));
export { StakeholderService };
