import { Component } from '@angular/core';
import { ReportService } from '../../../../../dashboards/admin/services/report.service';
@Component({
  moduleId: module.id,
  templateUrl: 'superadmin-raw-report-download-dialog.component.html',
  styleUrls: ['./superadmin-raw-report-download-dialog.component.scss']
})
export class SuperadminRawReportDialog {
    reportId: string;

    constructor(public reportService: ReportService) {}

    confirm() {
        this.reportService.downloadRawReport(this.reportId).subscribe((data) => {
            window.open(data, '_blank');
        });
    }
}

