import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { CountryService } from "../../../../../shared/services/country.service";
import { Country } from "../../../../../shared/models/country.class";
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'country-data-input-list',
    templateUrl: './country-data-input-list.component.html' 
})
export class CountryDataInputListComponent implements OnInit {
    @Input() selectedValue: string | null = null;  // The currently selected country's ISO code.
    @Input() disabled: boolean = false;  // Determines if the input should be disabled.
    
    // Output allows this component to emit events to parent components.
    @Output() selectionChange = new EventEmitter<string | null>();

    // BehaviorSubject to hold and emit changes in the list of countries.
    private countries: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);
    // BehaviorSubject to hold the loading status of country data.
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    // Constructor to inject services.
    constructor(private countryService: CountryService) { }

    ngOnInit() {
        this.countryService.get().pipe(
            map((countries: Country[]) => {
                this.loading$.next(false);  
                return countries; 
            })
        ).subscribe(this.countries);  // Subscribes and stores the countries in the BehaviorSubject.
    }

    // Handler for selection changes emitted by the UI.
    onSelectionChange(option: string): void {
        const iso = this.getIsoFromCountryName(option);  // Retrieves ISO code based on the country name.
        this.selectionChange.emit(iso);  // Emits the ISO code to the parent component.
    }

    // Utility method to get an array of country names for the UI.
    getListCountriesName(): string[] {
        return this.countries.getValue().map(country => country.name);
    }

    // Returns the selected country's name based on the stored ISO value.
    getCountriesSelectedValue(): string | null {
        if (this.selectedValue === null) return null;
        const country = this.countries.getValue().find(country => country.iso === this.selectedValue);
        return country ? country.name : null;
    }
    
    // Private helper to find a country's ISO code by its name.
    private getIsoFromCountryName(name: string): string | null {
        const country = this.countries.getValue().find(country => country.name === name);
        return country ? country.iso : null;
    }
}
