import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Passport} from '../../../models/passport.class';
import {ModuleNames} from '../../../../../shared/values/module-names.const';
import {TranslateService} from '@ngx-translate/core';
import {PASSPORT_DEPENDENCY} from '../../../values/passport-dependency.const';
import {getHighLightedText} from '../../../../../shared/helpers/get-hightlighted-text.helper';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { Utils } from '../../../../../shared/utils/utils.class';
import { Subscription } from 'rxjs';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'passport-preview-item',
  templateUrl: 'passport-preview-item.component.html',
  styleUrls: ['passport-preview-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PassportPreviewItemComponent implements OnInit, OnDestroy {
  @Input()
  passport: Passport;
  @Input() parentModuleName: string;
  @Output() onOpen: EventEmitter<any> = new EventEmitter();
  @Output() onPassportSelected: EventEmitter<any> = new EventEmitter();
  public moduleNames = ModuleNames;
  public isChooseHovered: boolean;
  public isCopyHovered2: boolean;
  @Input() searchString: string;
  @Input() isColored: boolean;
  imageSrc: string;
  imageTimeout;
  miltilanguageSubscription: Subscription;

  @Input() isVersionFilterOn: boolean  ;
  @Output() onVersionFilter?: EventEmitter<any> = new EventEmitter();

  constructor(private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public notification: LogNotificationService,
    public filterState: NavbarStateService) {
  }
  ngOnInit(): void {
    this.miltilanguageSubscription = this.filterState.multilanguageObservable$.subscribe(() => this.cdr.detectChanges());
    this.imageTimeout = setTimeout(() => this.setImageSrc(), 400);
  }
  ngOnDestroy(): void {
    this.miltilanguageSubscription.unsubscribe();
    clearTimeout(this.imageTimeout);
  }
  setImageSrc() {
    if (!!this.passport.thumbnail) {
      this.imageSrc = this.passport.thumbnail;
    }
    if (!this.passport.thumbnail) {
      this.imageSrc = this.passport.image;
    }

    this.detectChanges();
  }

  detectChanges() {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }
  getArrayItem(component, isLast: boolean): string {
    const name = component.name;
    if (!name) {
      return;
    }
    const translation = this.translateService.instant(name);
    return `${!!translation ? translation : ''}${isLast ? '' : ', '}`;
  }

  selectPassport(passport) {
    this.onPassportSelected.emit(passport);
  }
  copyPassportId(passport) {
    console.log(passport.product_passport_id);
    let msg = 'Id not yet set';
    if (passport.product_passport_id) {
      msg = 'Id copied';
      Utils.copyToClipBoard(passport.product_passport_id);
    }

    this.notification.success(msg, 2000, true);
  }
  public getValue(content: string): string {
    if (!content) {
      return;
    }
    return getHighLightedText(content, this.searchString);
  }
  getPassportName() {
    return this.filterState.multilanguage ? this.passport.english_name : this.passport.composite_name || this.passport.name;
  }
  isCompanyVisible(): boolean {
    return (this.parentModuleName === ModuleNames.MANUFACTURER || this.parentModuleName === ModuleNames.SPECIAL_ADMIN);
  }

  getCreatedBy(): string {
    if (!this.passport || !this.passport.user) {
      return;
    }
    if (this.isCompanyVisible()) {
      return this.passport.user.company_name;
    }
    return (this.passport.type === PASSPORT_DEPENDENCY.INDEPENDENT) ? this.passport.user.company_name : this.passport.user.username;
  }

  filterByVersion() {
    if (this.onVersionFilter) {
     this.isVersionFilterOn = !this.isVersionFilterOn ;
     const data = { passport: this.passport , filter: this.isVersionFilterOn};
     this.onVersionFilter.emit(data);
    }
  }
}
