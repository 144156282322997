import { Injectable } from '@angular/core';
import {CrudService} from '../../../shared/services/crud.service';
import { CompanyFile } from '../models/company-file.class';

@Injectable()
export class CompanyFileService  extends CrudService {
  protected ModelClass = CompanyFile;
  protected namespace = 'company-file';

}
