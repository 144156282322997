import {Component, Input, OnInit} from '@angular/core';
import {IReportResponse} from '../../../../../../interfaces/reportResponse.interface';
import {TResidualValueReport} from '../../types/residual-value-report.type';
import {IResidualValueAggregate, IResidualValueCategoryItem} from '../../types/residual-value.interface';
import {COLOR, TOTAL_COLOR} from '../../types/residual-value-colors.const';
import { MAIN_TAB_STEPS } from '../../../../admin-reporting.const';

@Component({
  moduleId: module.id,
  selector: 'residual-value-third-chart',
  templateUrl: 'residual-value-third-chart.component.html',
  styleUrls: ['residual-value-third-chart.component.scss']
})
export class ResidualValueThirdChartComponent implements OnInit {
  @Input() report: IReportResponse;
  @Input() type: TResidualValueReport;
  @Input() isPDFReport: boolean = false;
  @Input() showTotal: boolean = true;
  @Input() showAxisLabel: boolean = true;
  @Input() changeTab: Function;

  public numberOfChartRows = 7;
  public graphicCanvasHeight = 464;
  public heightLimitForTwoCircles: number = 100;
  public heightLimitForOneCircle: number = 47;
  private colors = Object.values(COLOR);
  public chartCategories: { data: { high: number; low: number }; name: string; color: string; type: string }[] = [];

  get data(): IResidualValueAggregate {
    if(this.report.report_result){
      return this.report.report_result.financial_residual_value[this.type].aggregate;
    }
    return this.report[this.type].aggregate;
  }


  ngOnInit(): void {
    this.prepareChartColumns();
  }
  hasCategory(): boolean {
    if(this.report.report_result){
      return !!this.report.report_result.financial_residual_value[this.type].aggregate.category;
    }
    return !!this.report[this.type].aggregate;
  }
  prepareChartColumns(): void {
    this.graphicCanvasHeight = this.isPDFReport ? 264 : 464;

    if (!this.hasCategory()) {
      return;
    }
    const amountOfCategories = Object.keys(this.data.category).length;
    if (!amountOfCategories) {
      return;
    }
    this.chartCategories = this.showTotal ? [
      this.getTotalCategory(),
      ...this.getChartCategories(),
    ] : [...this.getChartCategories()];
  }

  getTotalCategory(): { data: { high: number; low: number }; name: string; color: string; type: string } {
    return this.data ?
      {
        data: {
          high: this.getRoundValue(this.data.total_financial_recovery_percentage_up_if_economic),
          low: this.getRoundValue(this.data.total_financial_recovery_percentage_down_if_economic)
        },
        name: 'TOTAL',
        color: TOTAL_COLOR,
        type: 'total'
      }
      : null;
  }

  getChartCategories(): { data: { high: number; low: number }; name: string; color: string; type: string }[] {
    const categories = this.orderProductsBy('financial_recovery_percentage_up_if_economic');
    // const categories = [
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category),
    //   ...Object.values(this.report.report_result.financial_residual_value[this.type].aggregate.category)
    // ];
    let colorIndex = 0;
    return categories.map((i, index) => {
      if (index > 0) {
        colorIndex += 1;
      }
      if (colorIndex + 1 > this.colors.length) {
        colorIndex = 0;
      }
      return {
        data: {
          high: this.getRoundValue(i.financial_recovery_percentage_up_if_economic),
          low: this.getRoundValue(i.financial_recovery_percentage_down_if_economic)
        },
        name: i.category.name,
        color: this.colors[colorIndex],
        type: Object.keys(COLOR)[colorIndex].toLowerCase()
      };
    });
  }

  getModifiedClassName(className: string, itemNumber: string): string {
    return `${className} ${className}-${itemNumber}`;
  }

  getGraphicCanvasHeight(): string {
    return this.graphicCanvasHeight + 'px';
  }

  orderProductsBy(orderBy: string): any {
    const report = this.report.report_result ? this.report.report_result.financial_residual_value[this.type] : this.report[this.type];
    const categories = report.aggregate.category;
    if (!!categories) {
      const categoriesFiltered = { ...Object.values(categories).filter((e: any) => !!e.deposit_considered) };
      return Object.values(categoriesFiltered).sort((a, b) => a[orderBy] < b[orderBy] ? 1 : (b[orderBy] < a[orderBy] ? -1 : 0));
    }
  }

  getOnePointVerticalAxisValues() {
    const maxPointForVerticalAxisValues = Math.max.apply(Math, [
      this.orderProductsBy('financial_recovery_percentage_up_if_economic')[0].financial_recovery_percentage_up_if_economic,
      this.data.total_financial_recovery_percentage_up_if_economic,
    ]);
    return Math.ceil(maxPointForVerticalAxisValues / this.numberOfChartRows);
  }

  getNumberOfPixelsInOneRowVerticalAxis(): number {
    return this.graphicCanvasHeight / this.numberOfChartRows;
  }

  numberOfHrMarginTopValues(): any {
    return new Array(this.numberOfChartRows).fill((this.getNumberOfPixelsInOneRowVerticalAxis() - 1) + 'px');
  }

  getVerticalAxisValues(): any {
    let pointChartValue = 0;
    const pointChartValues = [];
    for (let i = 0; i <= this.numberOfChartRows; i++) {
      pointChartValues.push(pointChartValue);
      pointChartValue += this.getOnePointVerticalAxisValues();
    }
    return pointChartValues;
  }

  getAmountForOneChartRowInPixel(): number {
    return this.graphicCanvasHeight / (this.getOnePointVerticalAxisValues() * this.numberOfChartRows);
  }

  getAmountForPillarInPixels(maxValue: number, minValue: number): string {
    return (this.getAmountForOneChartRowInPixel() * (maxValue - minValue)) + 'px';
  }

  getRoundValue(value: number): number {
    return Math.round(value * 10) / 10;
  }
  getBottomForPillarInPixels(max: number, min: number): string {
    const bottomForPillar = this.getAmountForOneChartRowInPixel() * min;
    const heightLimitForTwoCircles = this.graphicCanvasHeight - this.heightLimitForTwoCircles;
    const heightLimitForOneCircle = this.graphicCanvasHeight - this.heightLimitForOneCircle;

    if (bottomForPillar > heightLimitForTwoCircles) {
      return this.isMaxMoreThanMinByOnePercent(max, min)
        ? (heightLimitForTwoCircles) + 'px'
        : (heightLimitForOneCircle) + 'px';
    } else {
      return bottomForPillar + 'px';
    }
  }
  isMaxMoreThanMinByOnePercent(max: number, min: number): boolean {
    return max - min > 1;
  }
  getAverageOrMinValue(max: number, min: number): number {
    const averageValue = (max + min) / 2;
    return this.isMaxMoreThanMinByOnePercent(max, min)
      ? min
      : this.getRoundValue(averageValue);
  }
  goToArchive() {
    this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
  }
}
