import {ActiveRecord} from '../../../shared/models/active-record.class';
import {DepositTagLocationJunctionService} from '../services/deposit-tag-location-junction.service';

export class DepositTagLocationJunction extends ActiveRecord {
  id: number;
  tag_id: number;
  deposit_id: number;
  name: string;
  protected provider = DepositTagLocationJunctionService;

  protected fields(): string[] {
    return ['id', 'tag_id', 'deposit_id', 'name'];
  }


}
