interface IEnergy {
  value: number;
  viewValue: string;
}

export enum EEnergyGroupType {
  RENEWABLE,
  NON_RENEWABLE
}

export const RENEWABLE_ENERGIES: IEnergy[] = [
  {value: 1, viewValue: 'Solar photovoltaic'},
  {value: 2, viewValue: 'Eolian'},
  {value: 3, viewValue: 'Geothermal'},
  {value: 4, viewValue: 'Agrofuel'},
  {value: 5, viewValue: 'Solar thermal'},
  {value: 6, viewValue: 'Hydraulic'},
  {value: 7, viewValue: 'Biomass'}
];

export const NON_RENEWABLE_ENERGIES: IEnergy[] = [
  {value: 8, viewValue: 'Nuclear'},
  {value: 9, viewValue: 'Gas'},
  {value: 10, viewValue: 'Coal'},
  {value: 11, viewValue: 'Oil'}
];

interface IEnergyGroup {
  type: EEnergyGroupType;
  energy: IEnergy;
  energies: IEnergy[];
}

export const USED_ENERGY_GROUPS: IEnergyGroup[] = [
  {
    type: EEnergyGroupType.RENEWABLE,
    energy: {value: 13, viewValue: 'Renewable energy'},
    energies: RENEWABLE_ENERGIES
  },
  {
    type: EEnergyGroupType.NON_RENEWABLE,
    energy: {value: 14, viewValue: 'Non renewable energy'},
    energies: NON_RENEWABLE_ENERGIES
  }
];
