<md-dialog-content class="esm-transformation-dialog">

  <header class="esm-transformation-dialog__header">
    <section class="esm-catalog__choice">
      <ul class="esm-catalog__choice-list">
        <li class="esm-catalog__choice-item search-long">
          <i class="icon icon--search"></i>
          <md-input-container class="input-group">
            <input mdInput autocomplete="off" [placeholder]="'Search' | translate" [formControl]="search" (change)="getRecords()">
          </md-input-container>
        </li>
        <li class="esm-catalog__choice-item select-long">
          <i class="icon icon--people-grey"></i>
          <md-select class="select-group" [placeholder]="'Type of transformation' | translate" (change)="getRecords()"
            [(ngModel)]="type">
            <md-option [value]="0">{{'Any' | translate}}</md-option>
            <md-option *ngFor="let type of TRANSFORM_TYPES" [value]="type.value">{{type.view | capitalize | translate}}
            </md-option>
          </md-select>
        </li>
        <li *ngIf="showIndependentTranformFilter" >
          <toggle-independent
          (toggle)="toggleIndependent($event)"
          [text]="'See external transformations' | translate"
          [img]="'assets/png/cloud_grey.png'">
          </toggle-independent>
        </li>
        <li class="esm-catalog__choice-item select-long">
          <i class="icon icon--people-grey"></i>
          <md-select class="select-group" [placeholder]="'Transformation Manager' | translate"  [(ngModel)]="tsmId" (change)="getRecords()">
            <md-option>{{ 'Any' | translate }}</md-option>
          <md-option *ngFor="let tsm of tsms" [value]="tsm.id">{{tsm.name}}
          </md-option>
          </md-select>
        </li>
      </ul>
    </section>
    <i class="icon icon--close_s esm-transformation-dialog__close" (click)="closeDialog()"></i>
  </header>

  <div class="esm-transformation-map">
    <agm-map (idle)="onMapReady()" (zoomChange)="mapZoom = $event" [zoom]="mapZoom" [fitBounds]="mapBounds" class="map"
      style="width: 100%; height: 400px;">

      <agm-marker-cluster *ngIf="mapZoom<=12" [minimumClusterSize]="2" imagePath="assets/png/map/cluster/m">
        <div *ngFor="let transform of transforms">
            <agm-marker
              iconUrl=" {{ transform.isIndependent() ? 'assets/png/map/localisation-blue.png' : 'assets/png/map/localisation-orange.png' }}"
              *ngFor="let location of transform.sites" [latitude]="location.lat" [longitude]="location.lng">
              <agm-info-window>
                <div class="info center-text">
                  <div><strong>{{ transform.name }}</strong></div>
                  <div>{{ transform.created_at }}</div>
                  <div>{{'by' | translate}} {{ isTransformIndependent(transform.independent) ? transform.tsm_org : transform.tsm_name }}</div>
                </div>
              </agm-info-window>
            </agm-marker>
          </div>
        </agm-marker-cluster>
        <agm-marker-spider *ngIf="mapZoom>12">
          <div *ngFor="let transform of transforms">
              <agm-marker
                iconUrl=" {{ transform.isIndependent() ? 'assets/png/map/localisation-blue.png' : 'assets/png/map/localisation-orange.png' }}"
                *ngFor="let location of transform.sites" [latitude]="location.lat" [longitude]="location.lng">
                <agm-info-window>
                  <div class="info center-text">
                    <div><strong>{{ transform.name }}</strong></div>
                    <div>{{ transform.created_at }}</div>
                    <div>{{'by' | translate}} {{ isTransformIndependent(transform.independent) ? transform.tsm_org : transform.tsm_name }}</div>
                  </div>
                </agm-info-window>
              </agm-marker>
            </div>
          </agm-marker-spider>

    </agm-map>
  </div>
</md-dialog-content>