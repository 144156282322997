import * as tslib_1 from "tslib";
import { DoCheck, OnInit, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MdDialog } from '@angular/material';
import { DepositOwnerListService } from '../../../../deposit-owner/services/deposit-owner-list.service';
import { DepositService } from '../../../../../entities/deposit/services/deposit.service';
import { Deposit } from '../../../../../entities/deposit/models/deposit.class';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import { DepositDetailsDialogComponent } from '../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { CurrentUserService } from '../../../../../shared/services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NEED_STATUSES } from '../../../../../entities/need/values/need-statuses.const';
import { NavigationService } from '../../../../../shared/services/navigation.service';
import { ModuleNames } from '../../../../../shared/values/module-names.const';
import { getSearchStatus } from '../../../../../shared/helpers/search-status-update';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { CustomListDateAvailabilityService } from '../../../../../shared/services/custom-list-date-availability.service';
import { DepositExportDialogComponent } from '../../../dialogs/deposit-export/deposit-export-dialog.component';
import { SiteService } from './../../../../../entities/depositLocation/services/SiteService';
import { DEPOSIT_STATUSES } from '../../../../../entities/deposit/values/deposit-statuses.const';
var AdminDepositCatalogComponent = /** @class */ (function () {
    function AdminDepositCatalogComponent(depositOwnerListService, _depositService, mdDialog, _globalEvents, _currentUserService, _navigationService, _translate, navBarState, customListDateAvailable, siteService) {
        var _this = this;
        this.depositOwnerListService = depositOwnerListService;
        this._depositService = _depositService;
        this.mdDialog = mdDialog;
        this._globalEvents = _globalEvents;
        this._currentUserService = _currentUserService;
        this._navigationService = _navigationService;
        this._translate = _translate;
        this.navBarState = navBarState;
        this.customListDateAvailable = customListDateAvailable;
        this.siteService = siteService;
        this.records = [];
        this.searchControl = new FormControl();
        this.beforeUpdateMap = new EventEmitter();
        this.updateMap = new EventEmitter();
        this.changeFilteredLocations = new EventEmitter();
        this.availableDepositLocations = [];
        this.chosenDepositLocations = [];
        this.chosenTags = [];
        this.sortOrder = 'name';
        this.sites = [];
        this.currentPage = 1;
        this.totalPagesCount = 0;
        this.totalCounts = 0;
        this.isLoadingNewPage = false;
        this.specialAdminFilterStatuses = [
            NEED_STATUSES.ASK_FOR_MODIFICATION,
            NEED_STATUSES.WAITING_FOR_PUBLICATION,
            NEED_STATUSES.PUBLISHED,
        ];
        this.searchStatus = this.specialAdminFilterStatuses.map(function (status) { return status.value; });
        this.depositRequestQueue = [];
        this.depositOwnerListService.get().subscribe(function (res) { return _this.depositOwners = res; });
        this
            .searchControl
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .do(function (keyword) { return _this.search = keyword; })
            .subscribe(function () { return _this.loadDeposits(); });
    }
    AdminDepositCatalogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadDeposits();
        this.initSites();
        this._translate.get('Available on').subscribe(function (translation) {
            _this.markerAvailableOnLabelText = translation;
        });
    };
    AdminDepositCatalogComponent.prototype.initSites = function () {
        var _this = this;
        this.beforeUpdateMap.emit();
        this.mapSubscription = this.siteService.loadSites(tslib_1.__assign({ admin_view: 1 }, this.getDepositQuery())).subscribe(function (sites) {
            _this.sites = sites;
            if (_this.chosenDepositLocations.length === 0)
                _this.availableDepositLocations = sites;
            _this.updateMap.emit(sites);
        });
    };
    AdminDepositCatalogComponent.prototype.getDepositQuery = function (forPage) {
        if (forPage === void 0) { forPage = null; }
        var statuses = [DEPOSIT_STATUSES.PUBLISHED.value];
        if (this.isSpecialAdminModule()) {
            statuses = this.searchStatus;
        }
        return {
            expand: 'passport,creator,owner_name,owner_id,creator_email,deposit_location,passport_materials,material_names',
            admin: 1,
            supervisor: 1,
            available_from: this.availableDateFrom || null,
            available_to: this.availableDateTo || null,
            search: this.search || null,
            sort: this.sortOrder || null,
            'status[]': statuses || null,
            'sites[]': this.chosenDepositLocations.map(function (location) { return location.id; }) || null,
            'tags[]': this.chosenTags.map(function (tag) { return tag.id; }) || null,
            page: forPage
        };
    };
    AdminDepositCatalogComponent.prototype.ngDoCheck = function () {
        // this.loadDeposits();
    };
    AdminDepositCatalogComponent.prototype.loadDeposits = function (forPage) {
        var _this = this;
        if (forPage === void 0) { forPage = 1; }
        var query = this.getDepositQuery(forPage);
        if (this.navBarState.multilanguage) {
            query.english = true;
        }
        if (forPage === 1) {
            this.initNewDepositLoad();
        }
        this.isLoadingNewPage = true;
        var request = this._depositService.getWithPaginationData(query)
            .subscribe(function (res) { return _this.handleResponse(res); });
        this.depositRequestQueue.push(request);
    };
    AdminDepositCatalogComponent.prototype.isSpecialAdminModule = function () {
        return this._navigationService.getModuleName() === ModuleNames.SPECIAL_ADMIN;
    };
    AdminDepositCatalogComponent.prototype.initNewDepositLoad = function () {
        this.records = [];
        this.beforeUpdateMap.emit();
        this.depositRequestQueue.forEach(function (request) { return request.unsubscribe(); });
    };
    AdminDepositCatalogComponent.prototype.handleResponse = function (res) {
        var _this = this;
        var headers = res.headers;
        this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
        this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
        this.totalCounts = Number(headers.get('X-Pagination-Total-Count'));
        res.json().map(function (data) {
            var deposit = new Deposit(data);
            _this.records.push(_this.toRecord(deposit));
        });
        this.initSites();
        this.isLoadingNewPage = false;
    };
    AdminDepositCatalogComponent.prototype.onEndOfListReached = function () {
        if (this.currentPage < this.totalPagesCount && !this.isLoadingNewPage) {
            this.loadDeposits(this.currentPage + 1);
        }
    };
    AdminDepositCatalogComponent.prototype.toRecord = function (data) {
        var _this = this;
        var availableInListItem = this.customListDateAvailable
            .getColumn(data.availability_date, data.availability_expiration);
        var passportName = '';
        if (data.passport) {
            passportName = data.passport.name;
        }
        var usagesString = Array.from(new Set(data.passport.next_usages.map(function (usage) { return 'test'; }))).join(', ');
        var materials = data.material_names;
        var materialsString = Array.from(new Set(materials.map(function (material) { return _this._translate.instant(material.name); }))).join(', ');
        var controls = [
            {
                name: 'See details',
                fn: function () {
                    _this.mdDialog.open(DepositDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                            readOnly: true,
                            id: data.id,
                        } }));
                },
            },
            {
                fn: function () { return location.href = 'mailto:' + data.creator_email; },
                name: 'Send Email'
            },
        ];
        if (data.owner_id !== this._currentUserService.infoData.id) {
            controls.push({
                fn: function () { return _this.openChat(data.owner_id); },
                name: 'Chat',
            });
        }
        var locationName = data.deposit_location.name || '';
        var valuePubDate = data.publication_date || data.updated_at;
        var pubDate = moment(valuePubDate).format('DD.MM.YYYY');
        return CustomListRecord
            .build()
            .setPrimaryKey(data.id)
            .addTitleColumn(data.name, passportName, null, data.passport.english_name)
            .addListColumn([{ key: '', value: data.passport.manufacturer }])
            .addListColumn([{ key: 'Materials', value: materialsString }])
            .addUnitColumn(data.quantity ? data.quantity : 0, data.passport.customListUnit)
            .addListColumn([{ key: 'Usages', value: usagesString }])
            .addListColumn([{ key: 'Site', value: locationName }])
            .addListColumn([{ key: 'Published', value: pubDate }, availableInListItem])
            .addAuthorColumn(data.owner_name)
            .addControlsColumn(controls);
    };
    AdminDepositCatalogComponent.prototype.exportDeposit = function () {
        this.mdDialog.open(DepositExportDialogComponent, {
            data: {
                data: {
                    supervisor: 1,
                    admin: 1,
                    available_from: this.availableDateFrom || null,
                    available_to: this.availableDateTo || null,
                    search: this.search || null,
                    sort: this.sortOrder || null,
                    'status[]': DEPOSIT_STATUSES.PUBLISHED.value || null,
                    timezone_offset: moment().utcOffset(),
                    'sites': this.chosenDepositLocations.map(function (location) { return location.id; }) || null,
                }
            }
        }).afterClosed().subscribe();
    };
    AdminDepositCatalogComponent.prototype.downloadPdfReport = function () {
        return this._depositService.getPdfReportUrl({
            supervisor: 1,
            available_from: this.availableDateFrom || null,
            available_to: this.availableDateTo || null,
            search: this.search || null,
            sort: this.sortOrder || null,
            owner: null,
            timezone_offset: moment().utcOffset()
        });
    };
    AdminDepositCatalogComponent.prototype.openChat = function (targetId) {
        console.log('openChat', targetId);
        this._globalEvents.chatUserClicked$.emit(targetId);
    };
    AdminDepositCatalogComponent.prototype.setChosenLocationsById = function (ids) {
        var _loop_1 = function (id) {
            var location_1 = this_1.availableDepositLocations.find(function (depositLocation) { return depositLocation.id === id; });
            if (location_1 && !this_1.chosenDepositLocations.some(function (chosenLocation) { return chosenLocation.id === id; })) {
                this_1.chosenDepositLocations.push(location_1);
            }
            else if (location_1) {
                return { value: void 0 };
            }
        };
        var this_1 = this;
        for (var _i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
            var id = ids_1[_i];
            var state_1 = _loop_1(id);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        this.loadDeposits();
    };
    AdminDepositCatalogComponent.prototype.toggleState = function (state) {
        this.searchStatus = getSearchStatus(state, this.searchStatus);
        this.loadDeposits();
    };
    AdminDepositCatalogComponent.prototype.setChosenLocation = function (locations) {
        this.chosenDepositLocations = locations;
        this.loadDeposits();
    };
    return AdminDepositCatalogComponent;
}());
export { AdminDepositCatalogComponent };
