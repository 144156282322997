import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { DepositLocationSalesJunction } from '../models/deposit-location-sales-junction.class';
var DepositLocationSalesJunctionService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationSalesJunctionService, _super);
    function DepositLocationSalesJunctionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'sales-site-junctions';
        _this.namespaceSingular = 'sales-site-junction';
        _this.ModelClass = DepositLocationSalesJunction;
        return _this;
    }
    DepositLocationSalesJunctionService.prototype.updateLocationList = function (deleteIds, insert, userId) {
        var _this = this;
        return this.sendPost(this.getEndpoint(this.namespaceSingular) + '/update_list', { delete_ids: deleteIds, insert_sites: insert }, { search: { user_id: userId } })
            .map(function (res) {
            return res.json().map(function (item) { return _this.buildModel(item); });
        })
            .catch(this.onError.bind(this));
    };
    return DepositLocationSalesJunctionService;
}(CrudService));
export { DepositLocationSalesJunctionService };
