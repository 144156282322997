import * as tslib_1 from "tslib";
import { Transform } from '../models/transform.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
var TransformService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformService, _super);
    function TransformService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformations';
        _this.namespaceSingular = 'transformation';
        _this.ModelClass = Transform;
        return _this;
    }
    TransformService.prototype.getPdfReportUrl = function (query) {
        var q = this.formQueryString(query);
        return this.getEndpoint(this.namespaceSingular + "/report" + (q ? '?' + q : ''));
    };
    TransformService.prototype.getPdfReport = function (query) {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/report'), { search: query })
            .catch(this.onError.bind(this));
    };
    TransformService.prototype.getWaiting = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/waiting'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    TransformService.prototype.getCount = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    TransformService.prototype.getInEcosystem = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/in_ecosystem'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    TransformService.prototype.getByRelevancy = function (needId, query) {
        if (query === void 0) { query = {}; }
        query.need_id = needId;
        return this.sendPost(this.getEndpoint(this.namespaceSingular + '/relevant'), query)
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    TransformService.prototype.get = function (query) {
        if (query && query['status[]'] && query['status[]'].length === 0) { // applying 0 status filters is equivalent to requesting nothing
            return Observable.of([]);
        }
        // if (this.isCurrentContextESM()) {
        // console.log(CurrentUser.infoData.has_transform_acces) ;
        // if (CurrentUser.infoData.has_transform_acces) {
        //   console.log('it has access') ;
        // query.independant = 1;
        //}
        // }
        //  console.log(query);
        return _super.prototype.get.call(this, query);
    };
    return TransformService;
}(ExtendedCrudService));
export { TransformService };
