import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { TransformLocation } from '../models/transform-location.class';

@Injectable()
export class TransformLocationService extends CrudService {
  protected namespace = 'transformation-locations';
  protected namespaceSingular = 'transformation-location';
  protected ModelClass = TransformLocation;
}
