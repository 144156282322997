import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformPackaging } from '../models/transform-packaging.class';
var TransformPackagingService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformPackagingService, _super);
    function TransformPackagingService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-packagings';
        _this.namespaceSingular = 'transformation-packaging';
        _this.ModelClass = TransformPackaging;
        return _this;
    }
    return TransformPackagingService;
}(CrudService));
export { TransformPackagingService };
