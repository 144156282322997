<div class="passport-dialog">
  <h2 md-dialog-title>{{ 'Create new passport?' | translate }}</h2>
  <div class="passport-dialog__explanation-text" *ngIf="isCreateNewPassportExplanationVisible()">
    {{ 'CREATE_NEW_PASSPORT_EXPLANATION' | translate: getTranslateParams() }}
  </div>
  <footer class="controls">
    <div class="ib-column passport__component-field">
      <md-input-container>
        <input
          mdInput
          autocomplete="off" 
          placeholder="{{ 'New name of passport' | translate }}"
          [(ngModel)]="name">
      </md-input-container>
    </div>
    <ul class="button-group__list">
      <li class="button-group__item button-group__item--short">
        <button md-button md-raised-button (click)="submit(name)">{{ 'Confirm' | translate }}</button>
      </li>
      <li class="button-group__item button-group__item--short" *ngIf="isSelectAnotherPassportShown">
        <button md-button md-raised-button (click)="onSelectAnotherPassport()">{{ 'Select another passport' | translate }}</button>
      </li>
      <li class="button-group__item button-group__item--short">
        <button md-button md-raised-button (click)="submit(false)">{{ 'Cancel' | translate }}</button>
      </li>
    </ul>
  </footer>
</div>
