import {Component} from '@angular/core';
import {MdDialogRef} from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'confirm-c2c-dialog',
  templateUrl: 'confirm-c2c-dialog.component.html'
})
export class ConfirmC2cDialogComponent {

  public isConfirmButtonDisabled = true;
  public dialogAnswers: { id: number; value: number }[] = [];
  public dialogItems = [
    'PASSPORT_C2C_DIALOG_FIRST_CAPTION',
    'PASSPORT_C2C_DIALOG_SECOND_CAPTION',
    'PASSPORT_C2C_DIALOG_THIRD_CAPTION'
  ];
  private openBannedComponentsPdf: Function;

  constructor(private _dialog: MdDialogRef<any>) {
    this.openBannedComponentsPdf = this._dialog._containerInstance.dialogConfig.data.openBannedComponentsPdf;
  }

  onConfirm() {
    this._dialog.close(true);
  }

  onCancel() {
    this._dialog.close();
  }

  openBannedComponentsList() {
    this.openBannedComponentsPdf();
  }

  updateConfirmButtonAvailability(): void {
    this.isConfirmButtonDisabled = this.dialogAnswers.reduce((acc, obj) => acc + obj.value, 0) < 3;
  }

  onRadioGroupValueChange(event, index: number) {
    const givenAnswer = this.dialogAnswers.find((i) => i.id === index);
    givenAnswer ? givenAnswer.value = event.value : this.dialogAnswers = [...this.dialogAnswers, {id: index, value: event.value}];
    this.updateConfirmButtonAvailability();
  }
}
