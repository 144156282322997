import {Injectable} from '@angular/core';
import { EcosystemPrefillService } from './ecosystem-prefill.service';
import { DepositOrder } from '../../../dashboards/ecosystem-manager/models/deposit-order.class';
import { IPrefillChange } from '../../../dashboards/ecosystem-manager/services/ecosystem-prefill.service';
import { EcosystemOffer } from '../../../entities/ecosystem-offer/ecosystem-offer.model';
import { TransformOrder } from '../models/transform-order.class';
import { EcosystemCompilerSharedActionsService } from './ecosystem-compiler-shared-actions.service';

@Injectable({
    providedIn: 'root'
  })
  export class EcosystemPrefillCommandService {
    constructor(
        private ecosystemCompilerSharedActionsService: EcosystemCompilerSharedActionsService,
        private prefillService: EcosystemPrefillService){
    }
    /**
     * Prefill service is used to create and manage prefill tree. 
     * Prefill tree is an tree of ecosystem orders and offer ids and corresponding in and out quantities.
     * It structure is similiar with order/offer relationship in ecosystem.
     * Initially calculated based on order/offer quantities already exist in ecosystem.
     * It will be automaticly created in on ecosystem edit after it loads ecosystem.
     * Initial creation will be skipped if ecosystem is not editable.
     * Change prefill tree and changes made while saving orders and offer right before ecosystem reload happens.
     * Changes will override order/offer values starting from change place until ecosystem offer. 
     * Input quantities are converted(based on the provided conversion factor) and added together to determine the output quantity.
     *  
     * @param order Transformation order what has siblings( deposit/s or transformation/s)
     * @param childOrder An deposit or an transformation which is connected to an parent transformation.
     * @returns An observable.
     */
    public addOrderToPrefillAndSaveOnReload(order: TransformOrder | DepositOrder | EcosystemOffer, childOrder: TransformOrder | DepositOrder) {
        order = this.updateOrderPrefillOutput(order, childOrder);
        const orderToSave = order ? [this.getQuantityContainsModel(order)] : [];
        let prefillCalculationParentsChange: IPrefillChange =  {};
        if (!this.prefillService.isOffer(order)) {
          prefillCalculationParentsChange = this.prefillService.prefillUpdateIfOrderChanged((order as TransformOrder | DepositOrder), false);
        }
        this.ecosystemCompilerSharedActionsService.prefillSetDirty(prefillCalculationParentsChange, orderToSave);
        return this.ecosystemCompilerSharedActionsService.beforeCompilerReload();
      }

    private getQuantityContainsModel(order){
        if (this.prefillService.isTransformOrder(order)) {
            return (order as TransformOrder).out_order_passport;
        } else {
            return order;
        }
     }

    private prefillCalculateTransformOrderOutputQuantity(order: TransformOrder, childOrder:  TransformOrder | DepositOrder, inputQuantity: number) {
        const subs = this.prefillService.getOrderSubstitution(childOrder);
            let quantity = this.prefillService.transformInToOut([{
              input: new TransformOrder({
                ...order,
                in_order_passport: {
                  quantity: inputQuantity
                }
              }),
              substitution: subs
            }]);
            quantity = quantity !== null ? quantity + this.prefillService.getOrderOut(order) : null;
            this.prefillService.setOrderOut(order, quantity);
            return order;
      }
    
    private updateOrderPrefillOutput(order: TransformOrder | DepositOrder | EcosystemOffer, childOrder:  TransformOrder | DepositOrder) {
        if (childOrder && order) {
          let inputQuantity = this.prefillService.getPrefillInput(order as any, childOrder);
          if (this.prefillService.isTransformOrder(order)) {
            order = this.prefillCalculateTransformOrderOutputQuantity(order as TransformOrder, childOrder, inputQuantity);
          } else {
            this.prefillService.setOrderOut(order, inputQuantity);
          } 
        } else if (order) {
          this.prefillService.setOrderOut(order, null);
        }
        return order;
      }
  }