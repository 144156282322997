var _a;
export var PASSPORT_STATUS;
(function (PASSPORT_STATUS) {
    PASSPORT_STATUS[PASSPORT_STATUS["DRAFT"] = 1] = "DRAFT";
    PASSPORT_STATUS[PASSPORT_STATUS["ASK_FOR_MODIFICATION"] = 2] = "ASK_FOR_MODIFICATION";
    PASSPORT_STATUS[PASSPORT_STATUS["PUBLISHED"] = 3] = "PUBLISHED";
    PASSPORT_STATUS[PASSPORT_STATUS["WAITING_FOR_PUBLICATION"] = 4] = "WAITING_FOR_PUBLICATION";
    PASSPORT_STATUS[PASSPORT_STATUS["FROZEN"] = 5] = "FROZEN";
    PASSPORT_STATUS[PASSPORT_STATUS["WAITING_FOR_REVISIONS"] = 6] = "WAITING_FOR_REVISIONS";
})(PASSPORT_STATUS || (PASSPORT_STATUS = {}));
var PassportStatusDefinitions = (_a = {},
    _a[PASSPORT_STATUS.DRAFT] = { value: PASSPORT_STATUS.DRAFT, text: 'DRAFT', view: 'Draft' },
    _a[PASSPORT_STATUS.ASK_FOR_MODIFICATION] = { value: PASSPORT_STATUS.ASK_FOR_MODIFICATION, text: 'ASK_FOR_MODIFICATION', view: 'Asked for modification' },
    _a[PASSPORT_STATUS.PUBLISHED] = { value: PASSPORT_STATUS.PUBLISHED, text: 'PUBLISHED', view: 'Published' },
    _a[PASSPORT_STATUS.WAITING_FOR_PUBLICATION] = { value: PASSPORT_STATUS.WAITING_FOR_PUBLICATION, text: 'WAITING_FOR_PUBLICATION', view: 'Waiting for publication' },
    _a[PASSPORT_STATUS.WAITING_FOR_REVISIONS] = { value: PASSPORT_STATUS.WAITING_FOR_REVISIONS, text: 'WAITING_FOR_REVISIONS', view: 'waitingForRevision' },
    _a[PASSPORT_STATUS.FROZEN] = { value: PASSPORT_STATUS.FROZEN, text: 'FROZEN', view: 'Frozen' },
    _a);
export var PASSPORT_STATUS_CONST = {
    DRAFT: PassportStatusDefinitions[PASSPORT_STATUS.DRAFT],
    ASK_FOR_MODIFICATION: PassportStatusDefinitions[PASSPORT_STATUS.ASK_FOR_MODIFICATION],
    PUBLISHED: PassportStatusDefinitions[PASSPORT_STATUS.PUBLISHED],
    WAITING_FOR_PUBLICATION: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_PUBLICATION],
    WAITING_FOR_REVISIONS: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_REVISIONS],
};
export var PASSPORT_STATUS_CONST_MANUFACTURER_TOBETREATED = {
    DRAFT: PassportStatusDefinitions[PASSPORT_STATUS.DRAFT],
    WAITING_FOR_REVISIONS: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_REVISIONS],
};
export var PASSPORT_STATUS_CONST_MANUFACTURER = {
    ASK_FOR_MODIFICATION: PassportStatusDefinitions[PASSPORT_STATUS.ASK_FOR_MODIFICATION],
    WAITING_FOR_PUBLICATION: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_PUBLICATION],
    DRAFT: PassportStatusDefinitions[PASSPORT_STATUS.DRAFT],
    PUBLISHED: PassportStatusDefinitions[PASSPORT_STATUS.PUBLISHED],
};
export var PASSPORT_STATUS_CONST_SPECIAL_ADMIN = {
    PUBLISHED: PassportStatusDefinitions[PASSPORT_STATUS.PUBLISHED],
    WAITING_FOR_PUBLICATION: PassportStatusDefinitions[PASSPORT_STATUS.WAITING_FOR_PUBLICATION],
    ASK_FOR_MODIFICATION: PassportStatusDefinitions[PASSPORT_STATUS.ASK_FOR_MODIFICATION],
};
