/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../client/client-detail/client-detail.component.ngfactory";
import * as i2 from "../../../client/client-detail/client-detail.component";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/router";
import * as i5 from "../../../client/service/client.service";
import * as i6 from "../../../client/service/location.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../client/service/contact.service";
import * as i9 from "@angular/material";
import * as i10 from "@angular/common";
import * as i11 from "./create-client.component";
var styles_CreateClientDialogComponent = [];
var RenderType_CreateClientDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateClientDialogComponent, data: {} });
export { RenderType_CreateClientDialogComponent as RenderType_CreateClientDialogComponent };
function View_CreateClientDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "client-detail", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ClientDetailComponent_0, i1.RenderType_ClientDetailComponent)), i0.ɵdid(1, 245760, null, 0, i2.ClientDetailComponent, [i3.FormBuilder, i4.Router, i5.ClientService, i6.LocationService, i7.TranslateService, i8.ContactService], { clientId: [0, "clientId"], asDialog: [1, "asDialog"] }, { onSave: "onSave" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clientId; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CreateClientDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "md-dialog-content", [], [[2, "mat-dialog-content", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i9.MdPrefixRejector, [[2, i9.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i9.MdDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateClientDialogComponent_1)), i0.ɵdid(4, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showDialog; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_CreateClientDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "create-client", [], null, null, null, View_CreateClientDialogComponent_0, RenderType_CreateClientDialogComponent)), i0.ɵdid(1, 245760, null, 0, i11.CreateClientDialogComponent, [i5.ClientService, i9.MdDialogRef, i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateClientDialogComponentNgFactory = i0.ɵccf("create-client", i11.CreateClientDialogComponent, View_CreateClientDialogComponent_Host_0, { name: "name" }, {}, []);
export { CreateClientDialogComponentNgFactory as CreateClientDialogComponentNgFactory };
