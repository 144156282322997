export const COMPOSITION_RADIOS: {key: number, value: string}[] = [
  {key: 1, value: 'Yes'},
  {key: null, value: 'I don\'t know'},
  {key: 0, value: 'No'},
];

export const COMPOSITION_RADIO = {
  YES: 1,
  NO: 0,
  DONT_KNOW: null
};
