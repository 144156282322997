<div class="ecosystem-file">
  <dialog-close-button
    (click)="mdDialogRef.close()"
  ></dialog-close-button>

  <h2 md-dialog-title>
    <ng-container> {{'Attach files' | translate}}</ng-container>
  </h2>
  <ul *ngIf="stepId"
      class="ecosystem-file__list"
  >
    <li><span>{{'Files from Ecosystem' | translate}}</span></li>
    <li class="separator">|</li>
    <li (click)="uploadFiles()">{{'Upload new file' | translate}}</li>
  </ul>
  <ul class="upload-files__list">
    <document-item
      *ngFor="let file of uploadedDocs"
      (onClick)="selectFile(file)"
      [noLink]="true"
      [file]="file">
    </document-item>
  </ul>
  <div class="ecosystem-file__buttons" *ngIf="!stepId">
    <button md-raised-button (click)="uploadFiles()">
      {{'Upload files' | translate}}
    </button>
  </div>
</div>

<input #fileInput
       type="file"
       ng2FileSelect
       [uploader]="uploader"
       multiple
       style="display: none;">
