import {Component, Inject} from '@angular/core';
import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';
import {ConfirmOrderDialogService} from '../../../../shared/services/confirm-order-dialog.service';
import {RequestFileSenderService} from '../../../admin/services/request-file-sender.service';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {Observable} from 'rxjs/Observable';
import {first, mergeMap, tap} from 'rxjs/operators';

import {IConfirmDialogData} from '../../../../shared/confirm-dialog/values/interface/confirm-dialog-data.interface';

@Component({
  selector: 'transformation-order-transaction-confirm-dialog',
  templateUrl: './transformation-order-transaction-confirm-dialog.component.html',
  providers: [{provide: RequestFileSenderService, useClass: RequestFileSenderService}]
})
export class TransformationOrderTransactionConfirmDialogComponent {

  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<void>,
              public confirmOrderDialogService: ConfirmOrderDialogService,
              public requestFileSenderService: RequestFileSenderService,
              private notification: LogNotificationService,
  ) {
  }

  openConfirmation(order: Observable<any>) {
    const callbackOnError = () => this.notification.error('One of the documents could not be uploaded', null, true);
    const observer = this.requestFileSenderService.getDocumentUploadObservable()
      .pipe(tap(() => {},
      () => callbackOnError()
      ))
      .pipe(first())
      .pipe(mergeMap(
        () => order.do(() => this.confirmOrderDialogService.afterConfirmationShowSuccessMessage())
      ));
    let overrideData: Partial<IConfirmDialogData> = {};
    overrideData = this.confirmOrderDialogService.getDefaultOverrideForESM(overrideData);
    this.confirmOrderDialogService.open(observer, this.mdDialogRef, overrideData);
  }

}
