import {Component} from '@angular/core';
import {DepositImportService} from '../../../entities/deposit/services/deposit-import.service';
import {DepositLocationImportService} from '../../../entities/deposit/services/deposit-location-import.service';

@Component({
  moduleId: module.id,
  selector: 'deposit-owner',
  templateUrl: 'deposit-owner.component.html',
  providers: [
  ]
})

export class DepositOwnerComponent {

}
