<div [ngClass]="'ecosystem-indicators__card ecosystem-indicators__card--' + cssStyleClass">
  <header [ngClass]="'ecosystem-indicators__cardheader ecosystem-indicators__cardheader--' + cssStyleClass">
    <label ><ng-content select="[title]"></ng-content>
    </label>
  </header>
  <div class="ecosystem-indicators__infoimage">
    <ng-content select="[logo]"></ng-content>
  </div>
  <div class="ecosystem-indicators__warning-message">
    <ng-container *ngIf="totalErrors.length > 0">
      <div class="ecosystem-indicators__exclamation">!</div>
      <label>{{'Indicator calculated with missing data' | translate}}</label>
    </ng-container>
  </div>


  <ng-content select="[report-details-tab]"></ng-content>

  <hr [ngClass]="'ecosystem-indicators__separator ecosystem-indicators__separator--' + cssStyleClass">

  <header class="ecosystem-indicators__toggle-header" (click)="toggleOther()">
    <h4 class="ecosystem-indicators__toggle-title" >
      <ng-content select="[other-title]"></ng-content>
    </h4>
    <button class="button-transparent ecosystem-indicators__toggle-button">
      <i class="icon" [ngClass]="isOtherOpen ? 'icon--fold-tab' : 'icon--unfold-tab' "></i>
    </button>
  </header>

  <div *ngIf="isOtherOpen" class="ecosystem-indicators__card-content">
    <div [ngClass]="'ecosystem-indicators__input-container ecosystem-indicators__label--' + cssStyleClass">
      <div class="ecosystem-indicators__sign">+</div>
      <ng-content select="[add]"></ng-content>
      <md-input-container add class="form-group__field ecosystem-indicators__number-input" floatPlaceholder="always">
        <input type="number" autocomplete="off" mdInput [placeholder]="addPlaceholder"
          [(ngModel)]="addOther" (change)="saveChanges()">
      </md-input-container>
      <div class="ecosystem-indicators__unit">
        {{unitOther}}
      </div>
    </div>

    <div [ngClass]="'ecosystem-indicators__input-container ecosystem-indicators__label--' + cssStyleClass">
      <div class="ecosystem-indicators__sign">-</div>
      <md-input-container subtract class="form-group__field ecosystem-indicators__number-input" floatPlaceholder="always">
        <input subtract type="number" autocomplete="off" mdInput [placeholder]="subtractPlaceholder"
          [(ngModel)]="subtractOther" (change)="saveChanges()">
      </md-input-container>
      <div class="ecosystem-indicators__unit">
        {{unitOther}}
      </div>
    </div>

    <div [ngClass]="'ecosystem-indicators__input-container ecosystem-indicators__label--' + cssStyleClass">
      <div class="ecosystem-indicators__sign"></div>
      <md-input-container class="form-group__field ecosystem-indicators__text-input" floatPlaceholder="always">
        <input description autocomplete="off" mdInput placeholder="{{ 'Description' | translate }}"
          [(ngModel)]="descriptionOther" (change)="saveChanges()">
      </md-input-container>
    </div>

    <div class="ecosystem-indicators__input-container">
      <div class="ecosystem-indicators__sign"></div>
      <label class="ecosystem-indicators__files">
        {{'Documents' | translate }}
      </label>
    </div>
    <div class="ecosystem-indicators__input-container">
      <div class="ecosystem-indicators__sign"></div>
      <input autocomplete="off" class="ecosystem-indicators__file-uploader"
             id="{{'indicator-upload-' + this.type}}" type="button"
        name="preview" (click)="openFiles()">
      <ul class="upload-files__list">
        <document-item *ngFor="let file of reportDocs"
          [noLink]="true"
          [isImage]="isImage(file.ecosystem_file.file)"
          [file]="file.ecosystem_file"
          [isRemoveFromFilesDisabled]="false"
          (remove)="removeJunctionFromOrder(file)">
        </document-item>

        <li class="ecosystem-indicators__file-uploader-icon">
          <label for="{{'indicator-upload-' + this.type}}">
            <i class="upload-files__download-dashed download--icon"></i>
          </label>
        </li>
      </ul>
    </div>
  </div>

</div>
