import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformSiteJunction } from '../models/transform-site-junction.class';
var TransformSiteJunctionService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformSiteJunctionService, _super);
    function TransformSiteJunctionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-site-junctions';
        //protected namespaceSingular = "transformation-site-junction";
        _this.ModelClass = TransformSiteJunction;
        return _this;
    }
    return TransformSiteJunctionService;
}(CrudService));
export { TransformSiteJunctionService };
