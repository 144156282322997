import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlContainer, FormControl, FormGroupDirective} from '@angular/forms';


@Component({
  moduleId: module.id,
  selector: 'deposit-import-column-row',
  templateUrl: 'deposit-import-column-row.component.html',
  styleUrls: ['deposit-import-column-row.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class DepositImportColumnRowComponent implements OnInit  {
    @Input() label: string;
    @Input() controlName: string;
    @Input() className: string;
    @Input() required: boolean;
    @Input() columnName: string;
    @Input() description: string;
    @Input() additionalDescription: string;
    @Input() custom: false;
    @Input() showWarningSign = true;
    @Output() onKeyDown = new EventEmitter<KeyboardEvent>();
    @Output() onBlurColumn = new EventEmitter<string>();

    control: AbstractControl;

    constructor(private parentFormGroupDir: FormGroupDirective) {

    }
    ngOnInit() {
      this.control = this.parentFormGroupDir.control.get(this.controlName);
      if (this.control.validator) {
        this.required = !!this.control.validator(new FormControl()).required;
      }
    }
}
