import * as tslib_1 from "tslib";
import { ImportConfigurationService } from './import-configuration.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../../shared/services/log-notification.service";
import * as i3 from "../../shared/services/navigation.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../shared/services/mock/mock-api.service";
var ImportSiteConfigurationService = /** @class */ (function (_super) {
    tslib_1.__extends(ImportSiteConfigurationService, _super);
    function ImportSiteConfigurationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ImportSiteConfigurationService.prototype.getUpdateColumnListEndpoint = function () {
        return this.getEndpoint('import-configuration/update_sites_columns_list');
    };
    ImportSiteConfigurationService.ngInjectableDef = i0.defineInjectable({ factory: function ImportSiteConfigurationService_Factory() { return new ImportSiteConfigurationService(i0.inject(i1.Http), i0.inject(i2.LogNotificationService), i0.inject(i3.NavigationService), i0.inject(i4.TranslateService), i0.inject(i5.MockApiService)); }, token: ImportSiteConfigurationService, providedIn: "root" });
    return ImportSiteConfigurationService;
}(ImportConfigurationService));
export { ImportSiteConfigurationService };
