import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TranslateService } from "@ngx-translate/core";
import { ServiceLocator } from "../../../shared/services/service-locator";
import { AffiliateBranchService } from '../services/affiliateBranch.service';
var AffiliateBranch = /** @class */ (function (_super) {
    tslib_1.__extends(AffiliateBranch, _super);
    function AffiliateBranch(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = AffiliateBranchService;
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        data = data || {};
        _this.id = data.id;
        _this.next_usage_id = data.next_usage_id;
        _this.description = data.description;
        _this.stakeholder = data.stakeholder;
        return _this;
    }
    AffiliateBranch.prototype.fields = function () {
        return ['id', 'next_usage_id', 'description', 'stakeholder'];
    };
    return AffiliateBranch;
}(ActiveRecord));
export { AffiliateBranch };
