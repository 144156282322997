import { Component, ElementRef, ViewChild, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { IPassportRatingWithLocalPassportFilter } from '../../../../../passport/values/passport-rating/passport-rating-filter.interface';
import { NavigationService } from '../../../../../../shared/services/navigation.service';

/**
 * Provides a component for filtering items based on various rating criteria.
 * This component allows users to select specific ratings to filter items, 
 * such as passports, based on their environmental and social impact ratings.
 */
@Component({
  selector: 'app-rating-filter',
  templateUrl: './new-rating-filter.component.html',
  styleUrls: ['./new-rating-filter.component.scss']
})
export class NewRatingFilterComponent {
  // References the filter wrapper element for detecting clicks outside to close the filter.
  @ViewChild('filterWrapper') filterWrapper: ElementRef;

  // Tracks whether the filter popup is currently open.
  isOpen: boolean = false;

  // Model representing the current state of rating filters selected by the user.
  filterModel: IPassportRatingWithLocalPassportFilter = {
    passportWithoutRating: false,
    selectRatingFilter: false,
    C2cCertified: false,
    composition: { color: false, black: false },
    cycle: { color: false, black: false },
    energy: { color: false, black: false },
    water: { color: false, black: false },
    social: { color: false, black: false },
  };

  // Emits the current filter model state when any changes occur.
  @Output() ratingChange = new EventEmitter<IPassportRatingWithLocalPassportFilter>();

  // Allows external entities to set the filter model, such as when resetting or initializing.
  @Input('ratingSelected') set setFilterModel(filterModel: IPassportRatingWithLocalPassportFilter) {
    this.filterModel = filterModel;
    this.updateSelectAllRatings();
  }

  constructor(private _navigation: NavigationService) {
    // Ensures that the "Select All" checkbox is correctly set on initialization.
    this.updateSelectAllRatings();
  }

  // Toggles the visibility of the filter dropdown.
  toggleView(): void {
    this.isOpen = !this.isOpen;
  }

  /**
   * Handles changes to individual filters, updating the model and emitting the change.
   * If a key and subKey are provided, it toggles the value for that specific filter.
   * This method also checks if all ratings are selected after any change.
   */
  onFilterChange(key?: string, subKey?: string): void {
    let value = subKey ? !this.filterModel[key][subKey] : !this.filterModel[key];
    if (key) {
      if (subKey) {
        this.filterModel[key][subKey] = value;
      } else {
        this.filterModel[key] = value;
      }
    }
    this.updateSelectAllRatings();
    this.ratingChange.emit(this.filterModel);
  }

  /**
   * Toggles the "Select All" filter, setting all rating filters to the same value.
   * It then calls `onFilterChange` to handle the update and emit the change.
   */
  onSelectAllRatingsChange(): void {
    const newValue = !this.filterModel.selectRatingFilter;
    Object.keys(this.filterModel).forEach(key => {
      if (typeof this.filterModel[key] === 'object' && key !== 'C2cCertified') {
        Object.keys(this.filterModel[key]).forEach(subKey => {
          this.filterModel[key][subKey] = newValue;
        });
      }
    });

    this.filterModel.selectRatingFilter = newValue;
    this.onFilterChange();
  }

  /**
   * Updates the `selectRatingFilter` property based on whether all rating filters are selected.
   * This ensures the "Select All" checkbox reflects the actual state of individual selections.
   */
  updateSelectAllRatings(): void {
    const allSelected = Object.keys(this.filterModel)
      .filter(key => typeof this.filterModel[key] === 'object' && key !== 'C2cCertified')
      .every(key => Object.values(this.filterModel[key]).every(value => value === true));

    this.filterModel.selectRatingFilter = allSelected;
  }

  removeRating(rating: string, type?: 'color' | 'black'): void {
    if (type) {
      this.filterModel[rating][type] = false;
    } else {
      this.filterModel[rating] = false;
    }
    this.ratingChange.emit(this.filterModel);
    this.updateSelectAllRatings();
  }

  removeC2cCertified(): void {
    this.filterModel.C2cCertified = false;
    this.ratingChange.emit(this.filterModel);
  }

  /**
   * Closes the filter dropdown if a click occurs outside the filter element.
   * This provides a better user experience by allowing users to click away to close.
   */
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (this.isOpen && !this.filterWrapper.nativeElement.contains(targetElement)) {
      this.isOpen = false;
    }
  }

    /**
 * Determines the CSS class for coloring pagination based on the user's role.
 * 
 * @returns The CSS class corresponding to the user's role.
 */
    getClassColor(): string {
      if(!this._navigation || !this._navigation.getContext()) return 'role-guest';
      let role = this._navigation.getContext().roleText;
      switch (role) {
        case 'Agent':
          return 'role-agent';
        case 'Manufacturer':
          return 'role-manufacturer';
        case 'Specialadmin':
          return 'role-specialadmin';
        default:
          return '';
      }
    }
}
