import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SORT_MODES } from '../../../../shared/values/sort-modes.const';
import { GoogleMapStyles } from '../../../../shared/config/google-maps-styles';
import { TRANSFORM_TYPES } from '../../../transformation-manager/values/transform-types.const';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { TransformService } from '../../../../entities/transformation/services/transform.service';

@Component({
  moduleId: module.id,
  templateUrl: 'supervisor-transforms.component.html',
  styleUrls: ['supervisor-transforms.component.scss'],
  host: {'class': 'supervisor-list'},
})
export class SupervisorTransformsComponent implements OnInit {
  public FILTER_TYPES = TRANSFORM_TYPES;
  public SORT_MODES = [
    SORT_MODES.NAME,
    SORT_MODES.DATE_OF_CREATION,
  ];
  public mapStyles = GoogleMapStyles;

  public records: Transform[];
  public results: Transform[];

  public selectedSortMode = this.SORT_MODES[0];
  public sortOrder = 1;
  public form = new FormGroup({
    searchText: new FormControl(),
    publicationDate: new FormControl(),
    filters: new FormArray(this.FILTER_TYPES.map(() => new FormControl())),
  });

  constructor(private transformService: TransformService) {
  }

  ngOnInit() {
    this.transformService.get<Transform>({supervisor: true, supervisor_view:true}).subscribe((res) => {
      this.records = res;
      this.form.valueChanges.subscribe(() => {
        this.update();
      });
      this.update();
    });
  }

  sort(sortMode: any) {
    if (this.selectedSortMode === sortMode) {
      this.sortOrder *= -1;
    } else {
      this.selectedSortMode = sortMode;
      this.sortOrder = 1;
    }
    this.update();
  }

  update() {
    this.results = this.getResults(this.records);
  }

  getResults(results: Transform[]) {
    let searchText = this.form.value.searchText;
    if (searchText) {
      results = results.filter(record =>
        record.includesText(searchText)
      );
    }

    let allowedTypes = this.FILTER_TYPES
      .map(TYPE => TYPE.value)
      .filter((_, i) => this.form.value.filters[i])
    ;
    if (allowedTypes.length) {
      results = results.filter(transform =>
        allowedTypes.includes(transform.transformation_type)
      );
    }

    let publicationDate = this.form.value.publicationDate;
    if (publicationDate) {
      results = results.filter(record =>
        new Date(record.created_at).toDateString() === new Date(publicationDate).toDateString()
      );
    }

    results.sort(this.selectedSortMode.comparator);

    if (this.sortOrder === -1) {
      results.reverse();
    }

    return results;
  }
}
