/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../import-spreadsheet/partials/progress/import-progress-sticky-footer/import-progress-sticky-footer.component.ngfactory";
import * as i2 from "../../../import-spreadsheet/partials/progress/import-progress-sticky-footer/import-progress-sticky-footer.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../import-spreadsheet/partials/progress/wrapper/import-progress-wrapper.component.ngfactory";
import * as i5 from "../../../import-spreadsheet/partials/progress/wrapper/import-progress-wrapper.component";
import * as i6 from "../../../import-spreadsheet/services/import-progress.service";
import * as i7 from "../../../entities/deposit/services/deposit-import.service";
import * as i8 from "../../../entities/deposit/services/deposit-location-import.service";
import * as i9 from "@angular/material";
import * as i10 from "../../../import-spreadsheet/services/import-deepstream.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../shared/services/log-notification.service";
import * as i13 from "../../../shared/services/import-success-summary.service";
import * as i14 from "../../../import-spreadsheet/services/import-worker.service";
import * as i15 from "../../../import-spreadsheet/services/active-import.service";
import * as i16 from "./agent.component";
var styles_AgentComponent = [];
var RenderType_AgentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgentComponent, data: {} });
export { RenderType_AgentComponent as RenderType_AgentComponent };
export function View_AgentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "import-progress-sticky-footer", [], null, null, null, i1.View_ImportProgressStickyFooterComponent_0, i1.RenderType_ImportProgressStickyFooterComponent)), i0.ɵdid(1, 49152, null, 0, i2.ImportProgressStickyFooterComponent, [], null, null), (_l()(), i0.ɵeld(2, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "import-progress-wrapper", [], null, null, null, i4.View_ImportProgressWrapperComponent_0, i4.RenderType_ImportProgressWrapperComponent)), i0.ɵdid(5, 245760, null, 0, i5.ImportProgressWrapperComponent, [i6.ImportProgressService, i7.DepositImportService, i8.DepositLocationImportService, i9.MdDialog, i10.ImportDeepstreamService, i11.TranslateService, i0.NgZone, i12.LogNotificationService, i13.ImportSuccessSummaryService, i14.ImportWorkerService, i15.ActiveImportService, i11.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AgentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "agent", [], null, null, null, View_AgentComponent_0, RenderType_AgentComponent)), i0.ɵdid(1, 49152, null, 0, i16.AgentComponent, [], null, null)], null, null); }
var AgentComponentNgFactory = i0.ɵccf("agent", i16.AgentComponent, View_AgentComponent_Host_0, {}, {}, []);
export { AgentComponentNgFactory as AgentComponentNgFactory };
