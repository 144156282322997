import * as tslib_1 from "tslib";
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { OrderMaterialService } from '../services/order-material.service';
import { NomenclatureItem } from '../../../entities/nomenclature/models/nomenclature-item.class';
var OrderMaterial = /** @class */ (function (_super) {
    tslib_1.__extends(OrderMaterial, _super);
    function OrderMaterial(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.provider = OrderMaterialService;
        _this.nomenclature = new NomenclatureItem(data.nomenclature);
        return _this;
    }
    OrderMaterial.prototype.fields = function () {
        return [
            'id',
            'quantity',
            'nomenclature_id',
            'order_passport_id',
        ];
    };
    return OrderMaterial;
}(ExtendedActiveRecord));
export { OrderMaterial };
