import { PASSPORT_UNITS_MAP } from '../../../values/passport-units.const';
var PassportFamilyViewLabel = /** @class */ (function () {
    function PassportFamilyViewLabel() {
    }
    PassportFamilyViewLabel.prototype.getFamilyName = function () {
        switch (this.familyType) {
            case 3 /* FAMILY_GENERIC */:
            case 1 /* FAMILY_LOCAL */:
            case 2 /* FAMILY_MANUFACTURER */:
            case 4 /* FAMILY_VOID */:
                return "FAMILY_VIEW_TYPE_" + this.familyType;
            default:
                return 'UNKNOWN';
        }
    };
    PassportFamilyViewLabel.prototype.getClassName = function () {
        var name = this.display === 'top' ? 'top' : 'view';
        switch (this.familyType) {
            case 3 /* FAMILY_GENERIC */:
            case 1 /* FAMILY_LOCAL */:
            case 2 /* FAMILY_MANUFACTURER */:
            case 4 /* FAMILY_VOID */:
                return "passportlist__family_" + name + "_label--" + this.familyType;
            default:
                return "passportlist__family_" + name + "_label--unknown";
        }
    };
    PassportFamilyViewLabel.prototype.getVoidClassName = function () {
        var name = this.display === 'top' ? 'top' : 'view';
        return "passportlist__family_" + name + "_label--unit";
    };
    PassportFamilyViewLabel.prototype.getTextClassName = function () {
        var name = this.display === 'top' ? 'top' : 'view';
        return "passportlist__family_" + name + "_text";
    };
    PassportFamilyViewLabel.prototype.getTextVoidClassName = function () {
        var name = this.display === 'top' ? 'top' : 'view';
        return "passportlist__family_" + name + "_void_text";
    };
    Object.defineProperty(PassportFamilyViewLabel.prototype, "isVoid", {
        get: function () {
            return this.familyType === 4 /* FAMILY_VOID */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassportFamilyViewLabel.prototype, "getUnit", {
        get: function () {
            return PASSPORT_UNITS_MAP[this.unit];
        },
        enumerable: true,
        configurable: true
    });
    return PassportFamilyViewLabel;
}());
export { PassportFamilyViewLabel };
