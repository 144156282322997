import { AfterContentChecked, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ReportSiteTagPresenterService } from '../../../../../services/report-site-tag-presenter.service';
var ReportSiteListComponent = /** @class */ (function () {
    function ReportSiteListComponent(tagPresenter, changeDetection) {
        this.tagPresenter = tagPresenter;
        this.changeDetection = changeDetection;
        this.createdByFile = false;
        this.siteTags = {};
        this._sites = [];
        this.isContentUpdate = false;
    }
    Object.defineProperty(ReportSiteListComponent.prototype, "sites", {
        set: function (sites) {
            this._sites = sites || [];
            this.isContentUpdate = true;
            this.tagPresenter.updateSiteList(this._sites);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportSiteListComponent.prototype, "tags", {
        set: function (tags) {
            this.isContentUpdate = true;
            this.tagPresenter.updateTagsById((tags || []).map(function (tag) { return tag.id ? tag.id : Number(tag); }));
        },
        enumerable: true,
        configurable: true
    });
    ReportSiteListComponent.prototype.ngAfterContentChecked = function () {
        var _this = this;
        if (this.siteBoxViewList && this.isContentUpdate) {
            try {
                setTimeout(function () { return _this.prettifyTagContainer(); });
            }
            catch (e) { }
        }
    };
    ReportSiteListComponent.prototype.prettifyTagContainer = function () {
        this.isContentUpdate = false;
        var parsePxToNumber = function (data) { return Number((data.match(/\d+/) || [0])[0]); };
        this.siteBoxViewList.forEach(function (el) {
            var styles = window.getComputedStyle(el.nativeElement);
            if (el.nativeElement.clientHeight > parsePxToNumber(styles.minHeight)) {
                var maxWidth = 0;
                for (var i = 0; i < el.nativeElement.children.length; i++) {
                    var child = el.nativeElement.children[i];
                    var x = child.offsetLeft + child.clientWidth - el.nativeElement.offsetLeft;
                    maxWidth = maxWidth < x ? x : maxWidth;
                }
                el.nativeElement.style.width = (maxWidth + parsePxToNumber(styles.paddingLeft)
                    + parsePxToNumber(styles.paddingRight)) + 'px';
            }
        });
    };
    return ReportSiteListComponent;
}());
export { ReportSiteListComponent };
