<li class="order-transaction-confirmation__form-list-row">
    <section [ngSwitch]="type">
      <ng-content select="[slot=startExpected]"></ng-content>
      <conversion-field
        [emptyQuantityHolder]="''"
        [setToggleColorActive]="true"
        *ngSwitchCase="FIELD_TYPE.CONVERSION"
        [isDisabled]="true"
        [quantity]="defaultValue"
        [volumeUnit]="this.conversionUnit"
        [conversionFactor]="conversionFactor || DEFAULT_CONVERSION"
        [placeholder]="placeholderExpected"
      ></conversion-field>

      <md-input-container
        *ngSwitchCase="FIELD_TYPE.DATE"
        class="order-transaction-confirmation__form-list-row-item-input
          order-transaction-confirmation__form-list-row-item-input--disabled"
        floatPlaceholder="always"
      >
        <input
          mdInput
          ng2-datetime-picker
          autocomplete="off"
          [date-only]="true"
          [parse-format]="'YYYY-MM-DD'"
          [date-format]="'DD-MM-YYYY'"
          [(ngModel)]="defaultValue"
          [placeholder]="placeholderExpected"
          [disabled]="true">
      </md-input-container>

      <md-input-container
        *ngSwitchCase="FIELD_TYPE.NUMBER"
        floatPlaceholder="always"
        class="order-transaction-confirmation__form-list-row-item-input">
        <input
          mdInput
          [value]="defaultValue"
          [placeholder]="placeholderExpected"
          [disabled]="true">
      </md-input-container>
      <ng-content select="[slot=endExpected]"></ng-content>
    </section>
    <button
      *ngIf="disabled; else active"
      class="order-transaction-confirmation__form-list-row-item-autofill-button"
      disabled
    >
    </button>
    <ng-template #active>
      <button
        [mdTooltip]="('Copy' | translate) + ' / ' + ('paste' | translate)"
        mdTooltipPosition="above"
        class="order-transaction-confirmation__form-list-row-item-autofill-button"
        (click)="autofillButtonClick()">
      </button>
    </ng-template>
    <section [ngSwitch]="type">
      <ng-content select="[slot=startConfirmed]"></ng-content>
      <conversion-field
        [setToggleColorActive]="true"
        *ngSwitchCase="FIELD_TYPE.CONVERSION"
        [isDisabled]="disabled"
        [ngClass]="{
          'order-transaction-confirmation__form-list-row-item-quantity--invalid': control.invalid && control.touched}"
        [quantity]="this.control.value"
        (quantityInTonsChanged)="changed($event)"
        [volumeUnit]="this.conversionUnit"
        [conversionFactor]="conversionFactor || DEFAULT_CONVERSION"
        [placeholder]="placeholderConfirmed"
        [emptyQuantityHolder]="''"
      ></conversion-field>

      <md-input-container
        *ngSwitchCase="FIELD_TYPE.DATE"
        class="order-transaction-confirmation__form-list-row-item-input"
        [ngClass]="{'order-transaction-confirmation__form-list-row-item-input--disabled': disabled}"
        floatPlaceholder="always">
        <input
          mdInput
          ng2-datetime-picker
          autocomplete="off"
          [date-only]="true"
          [ngModel]="control.value"
          (valueChanged)="changeDate($event)"
          [parse-format]="'YYYY-MM-DD'"
          [date-format]="'DD-MM-YYYY'"
          [placeholder]="placeholderConfirmed"
          [formControl]="control">
      </md-input-container>

      <md-input-container
        *ngSwitchCase="FIELD_TYPE.NUMBER"
        class="order-transaction-confirmation__form-list-row-item-input"
        floatPlaceholder="always"
      >
        <input
          mdInput
          type="number"
          [value]="control.value"
          (ngModelChange)="onChange($event)"
          [placeholder]="placeholderConfirmed"
          [formControl]="control">
      </md-input-container>

      <ng-content  select="[slot=endConfirmed]"></ng-content>
      <ng-content></ng-content>
    </section>
</li>
