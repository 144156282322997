import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {TransformFormatJunction} from '../models/transform-format-junction.class';

@Injectable()
export class TransformFormatJunctionService extends CrudService {
  protected namespace = 'transformation-formats-junctions';
  protected namespaceSingular = 'transformation-formats-junction';
  protected ModelClass = TransformFormatJunction;
}
