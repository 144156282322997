import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PassportSynchronizationData } from '../../../entities/passport/models/passport-synchronization-data.class';

@Component({
    selector: 'app-data-provider-filter',
    templateUrl: './data-provider-filter.component.html',
    styleUrls: ['./data-provider-filter.component.scss']
})
export class DataProviderFilterComponent {
    @Input() dataProviderSelected: number[] = [];
    @Output() dataProviderChange = new EventEmitter<number[]>();
    dataProviders: string[];

    constructor() {
        this.dataProviders = PassportSynchronizationData.getOperatorValueList();
    }

    getNamesDataProvider(): string[] {
        return this.dataProviderSelected.map(dataProvider => PassportSynchronizationData.getOperatorValue(dataProvider));
    }

    handleSelectionChange(selectedProviders: string[]) {
        this.dataProviderChange.emit(selectedProviders.map(dataProvider => PassportSynchronizationData.getOperatorKey(dataProvider)));
    }
}
