import {Component, Input, ViewEncapsulation, OnInit, ElementRef, ViewChild} from '@angular/core';
import {LogNotificationService} from '../services/log-notification.service';
import { setTextareaHeightToFitContent } from '../../shared/helpers/set-textarea-height-to-fit-content';
import {MdDialogRef} from '@angular/material';
import {
  IAdminWarningWithHeader
} from '../../dashboards/admin/dialogs/admin-warning-confirm/admin-warning-with-header/values/admin-warning-with-header.interface';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-report-problem-dialog',
  templateUrl: './report-problem-dialog.component.html',
  styleUrls: ['./report-problem-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportProblemDialogComponent implements OnInit {
  @Input() readOnly: boolean;
  @Input() title = 'Problem with order';
  @Input() foreword = 'The deposit has not been removed?';
  @Input() subForeword = 'Can you please explain the reason below?';
  @Input() confirmButtonText = 'OK, send';
  @Input() cancelButtonText = 'Cancel';
  @Input() problemDescription = '';
  @Input() onConfirm: (description: string) => Observable<boolean>;
  @Input() validationFunction = () => this.problemDescription && this.problemDescription.trim().length;
  @ViewChild('problemDescriptionTextarea', { read: ElementRef }) problemDescriptionTextarea: ElementRef;

  constructor(public notification: LogNotificationService,
              private dialog: MdDialogRef<IAdminWarningWithHeader>) {
  }

  ngOnInit() {
    Object.assign(this, this.dialog._containerInstance.dialogConfig.data);
    this.expandProblemDescriptionTextarea();
  }

  cancel() {
    this.dialog.close(false);
  }

  reportProblem() {
    if (!this.validationFunction || this.validationFunction()) {
      if (this.onConfirm) {
        this.onConfirm(this.problemDescription).subscribe((isSuccess) => {
          if (isSuccess) {
            this.dialog.close(true);
          }
        });
      } else {
        this.dialog.close(true);
      }
    } else {
      this.notification.error('You must enter a comment', null, true);
    }
  }
  expandProblemDescriptionTextarea(){
    setTimeout(() => setTextareaHeightToFitContent([
      this.problemDescriptionTextarea]));
  }
}
