/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NomenclatureService } from '../../service/nomenclature.service';
import { FormControl } from '@angular/forms';
import { TreeComponent, TreeNode } from 'angular-tree-component';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'nomenclature-tree-view',
  templateUrl: 'tree-view.component.html'
})
export class TreeViewComponent implements OnInit {
  @Input() viewId: number;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  events: Subscription;
  search = new FormControl();
  treeData: any[];
  nodes: any = [];
  openTag = '<em class=\'search-highlight\'>';
  closeTag = '<\/em>';

  @ViewChild('tree') private tree: TreeComponent;

  constructor(private nomenclatureService: NomenclatureService) {
  }

  getTree(view_id: number) {
    this.nomenclatureService.get<any>({view_id: view_id}).subscribe((res) => {
      this.treeData = res;
    });

    this.events = this
      .search
      .valueChanges
      .debounceTime(1000)
      .distinctUntilChanged()
      .subscribe((keyword: string) => {
          this.filterTree(keyword)
         // this.tree.treeModel.collapseAll();
      });
  }

  ngOnDestroy() {
    if(this.events) {
      this.events.unsubscribe();
    }
  }

  ngOnInit() {
    // This needs to get the view id from the user data
    if (!this.treeData) {
      this.getTree(1);
    }
  }

  appendSearchCount() {
    this.tree.treeModel.doForAll((node: TreeNode) => {
      let name = node.data.name.replace('(' + node.data.search_count + ')', '');
      node.data.name = name;
      node.data.search_count = 0;
      node.data.in_view = false;
    });
  }

  filterNodes(keyword) {
    if (keyword.trim() == "") {
      this.tree.treeModel.clearFilter();
    }else {


    keyword = keyword.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    this.tree.treeModel.filterNodes((node: TreeNode) => {
    
      let name = node.data.name.replace(this.openTag, '').replace(this.closeTag, '');

      if (node.data.search_count > 0) {
        if (node.parent.data.search_count > 0) {
          node.parent.data.search_count += node.data.search_count;
        } else {
          node.parent.data.search_count = node.data.search_count;
        }
      }

      let index = name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(keyword);
      let accept = index > -1;

      if (keyword && accept) {
        this.propagateSearchCount(node.parent);
        name = [name.slice(0, index),
          this.openTag,
          name.slice(index, index + keyword.length),
          this.closeTag,
          name.slice(index + keyword.length)].join('');
        // console.log('name', name)

        for (let child of node.children) {
          child.data.in_view = true;
        }
      } else {
        if (node.data.in_view === true) {
          for (let child of node.children) {
            child.data.in_view = true;
          }
        }
      }

      node.data.name = name;
   // }
      if (node.data.in_view === true) {
        return true;
      } else {
        return accept;
      }
    });
    }
  }

  updateSearchCount(keyword: string) {
    this.tree.treeModel.doForAll((node: TreeNode) => {
      if (keyword.trim() != "") {

        let name = node.data.name;
        name = [name, '(' + node.data.search_count + ')'].join(' ');
        node.data.name = name;
      }
      else {
        node.data.name = node.data.name.replace(this.openTag,"").replace(this.closeTag,"");
      }
    });
  }

  collapseAll() {
    this.tree.treeModel.collapseAll();
  }

  filterTree(keyword: string) {
    if(this.tree && this.tree.treeModel) {
      
      setTimeout(() => this.appendSearchCount());
      setTimeout(() => this.filterNodes(keyword));
      setTimeout(() => this.updateSearchCount(keyword));
      setTimeout(() => this.collapseAll());
    }
  }

  propagateSearchCount(item: TreeNode) {
    if (!!item) {
      if (item.data.search_count > 0) {
        item.data.search_count += 1;
      } else {
        item.data.search_count = 1;
      }
      this.propagateSearchCount(item.parent);
    }
  }

  closeDialog(treeItem: any) {
    //check if selectable
    const openTag = '<em class=\'search-highlight\'>';
    const closeTag = '<\/em>';
    let name = treeItem.node.data.name.replace(openTag, '').replace(closeTag, '').replace('(' + treeItem.node.data.search_count + ')', '');
    treeItem.node.data.name = name;
    this.onSave.emit(treeItem.node.data);
  }

  onTreeEvent(event: any) {
    if (event.eventName === 'toggleExpanded' && event.isExpanded) {
      this.tree.treeModel.doForAll((n: any) => {
        if (n !== event.node && n.parent === event.node.parent && n.isExpanded) {
          n.collapse();
        }
      });
    }
  }

}
