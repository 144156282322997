/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toggle-independent.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/material";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./toggle-independent.component";
var styles_ToggleIndependent = [i0.styles];
var RenderType_ToggleIndependent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToggleIndependent, data: {} });
export { RenderType_ToggleIndependent as RenderType_ToggleIndependent };
export function View_ToggleIndependent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "independent-toggle__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "independent-toggle__premium-toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-slide-toggle", [], [[2, "mat-slide-toggle", null], [2, "mat-checked", null], [2, "mat-disabled", null], [2, "mat-slide-toggle-label-before", null]], [[null, "change"], [null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._setMousedown() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.changeEmtier($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MdSlideToggle_0, i2.RenderType_MdSlideToggle)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MdSlideToggle]), i1.ɵdid(4, 1228800, null, 0, i4.MdSlideToggle, [i1.ElementRef, i1.Renderer, i4.FocusOriginMonitor], { checked: [0, "checked"] }, { change: "change" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", ""], ["class", "independent-toggle__premium-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "independent-toggle__premium-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isChecked; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 4).checked; var currVal_2 = i1.ɵnov(_v, 4).disabled; var currVal_3 = (i1.ɵnov(_v, 4).labelPosition == "before"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.img, ""); _ck(_v, 6, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.text)); _ck(_v, 9, 0, currVal_6); }); }
export function View_ToggleIndependent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "toggle-independent", [], null, null, null, View_ToggleIndependent_0, RenderType_ToggleIndependent)), i1.ɵdid(1, 49152, null, 0, i6.ToggleIndependent, [], null, null)], null, null); }
var ToggleIndependentNgFactory = i1.ɵccf("toggle-independent", i6.ToggleIndependent, View_ToggleIndependent_Host_0, { text: "text", img: "img", isChecked: "isChecked" }, { toggle: "toggle" }, []);
export { ToggleIndependentNgFactory as ToggleIndependentNgFactory };
