import { EventEmitter, OnInit } from '@angular/core';
import { NavigationService } from '../../../../shared/services/navigation.service';
/**
 * The `PaginationComponent` handles the rendering of pagination controls and emits events
 * for page changes. It uses OnPush change detection strategy for performance optimization
 * by reducing the number of checks Angular makes.
 */
var PaginationComponent = /** @class */ (function () {
    /**
     * Injects `NavigationService` to fetch user context like role.
     *
     * @param _navigation The navigation service for accessing application-wide navigational context.
     */
    function PaginationComponent(_navigation) {
        this._navigation = _navigation;
        /**
         * The current page number.
         */
        this.currentPage = 1;
        /**
         * Event emitter for page changes. Emits the new page number.
         */
        this.pageChange = new EventEmitter();
        /**
         * User role, fetched on component initialization for conditional styling or logic.
         */
        this.role = '';
    }
    /**
     * Fetches the user's role on component initialization from the navigation service context.
     */
    PaginationComponent.prototype.ngOnInit = function () {
        this.role = this._navigation.getContext().roleText;
    };
    /**
     * Navigates to a given page number if it's valid (not current, previous, or out of range).
     *
     * @param page The page number to navigate to.
     */
    PaginationComponent.prototype.navigateToPage = function (page) {
        if (page < 1 || page > this.totalPages || page === this.currentPage) {
            return;
        }
        this.currentPage = page;
        this.pageChange.emit(this.currentPage);
    };
    Object.defineProperty(PaginationComponent.prototype, "pages", {
        /**
         * Computes and returns an array representing the pages to be displayed by the pagination control,
         * including ellipses for skipped sections in the case of a large number of pages.
         *
         * @returns An array of numbers or strings representing pagination buttons.
         */
        get: function () {
            // Ensure currentPage and totalPages are treated as numbers
            var currentPage = Number(this.currentPage);
            var totalPages = Number(this.totalPages);
            var pages = [];
            var start = Math.max(currentPage - 2, 1);
            var end = Math.min(currentPage + 2, totalPages);
            // Adds the first page and ellipsis if currentPage is greater than 4
            if (currentPage > 4) {
                pages.push(1);
                pages.push('...');
            }
            // Adds the current, previous, and next pages
            for (var i = start; i <= end; i++) {
                pages.push(i);
            }
            // Adds ellipsis and last page if currentPage is far from the last page
            if (currentPage < totalPages - 3) {
                pages.push('...');
                pages.push(totalPages);
            }
            return pages;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Determines the CSS class for coloring pagination based on the user's role.
     *
     * @returns The CSS class corresponding to the user's role.
     */
    PaginationComponent.prototype.getClassColor = function () {
        var roleClassMap = {
            'supervisor': 'role-supervisor',
            'Specialadmin': 'role-special-admin',
            'Agent': 'role-agent',
            'admin': 'role-admin',
            'Manufacturer': 'role-manufacturer'
        };
        return roleClassMap[this.role] || '';
    };
    return PaginationComponent;
}());
export { PaginationComponent };
