import * as tslib_1 from "tslib";
import { EventEmitter, NgZone, OnInit, Renderer, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PassportComponent } from '../../models/component.class';
import { ComponentService } from '../../services/component.service';
import { Passport } from '../../models/passport.class';
import { PASSPORT_REQUEST } from '../../values/passport-request.const';
import { DEMOUNTABLE } from '../../values/demountable-values.const';
import { Material } from '../../models/material.class';
import { Observable } from 'rxjs/Observable';
import { MaterialService } from '../../services/material.service';
import { MdDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import { ValidationService } from '../../../../shared/services/validation-service';
import { PassportService } from '../../services/passport.service';
import { forkJoinSafe } from '../../../../shared/helpers/fork-join-safe.helper';
import { PassportCustomStoreService } from '../../services/passport-custom-store.service';
var PassportComponentsComponent = /** @class */ (function () {
    function PassportComponentsComponent(passportChildComponentsEventListenerService, _fb, componentService, materialService, passportService, dialog, renderer, _translate, ngZone) {
        this.passportChildComponentsEventListenerService = passportChildComponentsEventListenerService;
        this._fb = _fb;
        this.componentService = componentService;
        this.materialService = materialService;
        this.passportService = passportService;
        this.dialog = dialog;
        this.renderer = renderer;
        this._translate = _translate;
        this.ngZone = ngZone;
        this.isDisabled = false;
        this.isComponentNameMandatory = false;
        this.onSave = new EventEmitter();
        this.demountableTypes = DEMOUNTABLE;
        this.disableStatus = false;
        this.selectedCtrl = null;
        this.VIEW_MODE = VIEW_MODE;
        this.margin_bottom = 0;
        this.margin_bottom_initial = 0;
        this.controlGroupState = 'closed';
    }
    PassportComponentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.disableStatus = this.isDisabled;
        if (this.passport) {
            this.changePassport(this.passport).updateSubscription().getComponents();
            // if (this.passportChildComponentsEventListenerService && !this.loaded) {
            //   this.passportChildComponentsEventListenerService
            //     .addFormProgrammaticlyAction.emit({controlName: 'components', control: this.listCtrl});
            // }
        }
        if (this.listener) {
            this.listener.subscribe(function (event) {
                if (event.key === 'changePassport') {
                    _this.changePassport(event.data).updateSubscription().getComponents();
                }
                if (event.key === 'disableStatus') {
                    _this.disableStatus = event.data.isDisabled;
                    _this.updateDisableStatus();
                }
                if (event.key === 'savePassport') {
                    _this.onSave.emit({ type: PASSPORT_REQUEST.COMPONENTS, request: _this.save() });
                }
            });
        }
    };
    PassportComponentsComponent.prototype.changePassport = function (passport) {
        this.passport = passport;
        return this;
    };
    PassportComponentsComponent.prototype.updateSubscription = function () {
        var _this = this;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = this.passport.onSyncComponents$.subscribe(function (records) { return _this.updateComponents(records); });
        return this;
    };
    PassportComponentsComponent.prototype.getComponents = function () {
        this.passport.syncComponents();
    };
    PassportComponentsComponent.prototype.updateComponents = function (records) {
        var _this = this;
        console.log("edit");
        this.passport.components = records.slice();
        this.listCtrl = this._fb.array([]);
        if (this.passport.components.length === 0) {
            this.addNew();
        }
        var componentCounter = 0;
        this.passport.components.forEach(function (component) {
            _this.addCtrl(component);
            if (componentCounter === _this.passport.components.length) {
                _this.loaded = true;
            }
        });
        this.updateDisableStatus();
        if (this.passportChildComponentsEventListenerService && !this.loaded) {
            setTimeout(function () {
                _this.passportChildComponentsEventListenerService
                    .addFormProgrammaticlyAction.emit({ controlName: 'components', control: _this.listCtrl });
            });
        }
    };
    PassportComponentsComponent.prototype.addNew = function () {
        this.selectAndEnable(this.addCtrl(new PassportComponent({ passport_id: this.passport.id })));
        this.closeAllMaterialDropDowns();
    };
    PassportComponentsComponent.prototype.isDefault = function (component) {
        return component.get('componentName').parent.value.isDefault;
    };
    PassportComponentsComponent.prototype.getComponentValue = function (component) {
        return component.get('componentName').value || '';
    };
    PassportComponentsComponent.prototype.getcomponentDemountableValue = function (component) {
        return component.get('componentDemountable').value ?
            this.demountableTypes.find(function (t) { return t.value === component.get('componentDemountable').value; }).viewValue : ' ';
    };
    PassportComponentsComponent.prototype.selectAndEnable = function (newCtrl) {
        this.selectedCtrl = (newCtrl);
        newCtrl.get('componentName').enable();
        newCtrl.get('componentNumber').enable();
        newCtrl.get('componentDemountable').enable();
        newCtrl.get('percentageWeight').enable();
    };
    PassportComponentsComponent.prototype.removeComponent = function (index) {
        var _this = this;
        console.log('remove: ', index);
        console.log('component control: ', this.listCtrl);
        this._translate.get('CONFIRM_DELETE_PASSPORT_COMPONENT')
            .flatMap(function (translation) {
            var dialogRef = _this.dialog.open(ConfirmDialogComponent, {
                data: {
                    text: translation
                }
            });
            return dialogRef.afterClosed();
        })
            .subscribe(function (result) {
            if (result) {
                var componentForm = _this.listCtrl.controls[index];
                if (!componentForm) {
                    console.error('Failed to get component form by index (' + index + '):', _this.listCtrl);
                }
                var idCtrl = componentForm.get('componentId');
                if (!!idCtrl && idCtrl.value) {
                    _this.deleteComponent(idCtrl.value);
                }
                _this.listCtrl.removeAt(index);
                _this.listCtrl.markAsDirty();
                if (_this.listCtrl.length === 0) {
                    _this.addNew();
                }
            }
            else
                console.log('Delete canceled');
        });
    };
    PassportComponentsComponent.prototype.addCtrl = function (component) {
        var _this = this;
        var materialsFormArray = this._fb.array([]);
        if (component.materials.length === 0) {
            materialsFormArray.push(this.getMaterialCtrl(new Material()));
        }
        component.materials.forEach(function (material) {
            materialsFormArray.push(_this.getMaterialCtrl(material));
        });
        // inputs gets enabled as soon as control gets selected by first click
        var formGroup = this._fb.group({
            componentName: this.isComponentNameMandatory ? [{ value: component.name, disabled: true }, Validators.required] : [{ value: component.name, disabled: true }],
            // componentNumber: [{value: component.quantity, disabled: true}, Validators.pattern(/^\d+$/)],
            componentNumber: [{ value: component.quantity, disabled: true }, ValidationService.validateQuantity],
            componentDemountable: [{ value: component.demountable, disabled: true }],
            percentageWeight: [{ value: component.percentage_weight, disabled: true }, [Validators.min(0), Validators.max(100)]],
            componentId: [component.id],
            materials: materialsFormArray,
            isDefault: component.isDefault,
            isMaterialOpen: false
        });
        formGroup.value.isDefault ? this.listCtrl.push(formGroup) : this.listCtrl.insert(0, formGroup);
        if (component.isDefault) {
            this.selectedCtrl = formGroup;
        }
        return formGroup;
        // this.save();
    };
    PassportComponentsComponent.prototype.getMaterialCtrl = function (material) {
        return this._fb.group({
            id: [material.id],
            name: [material.nomenclature ? material.nomenclature.name : ''],
            nomenclatureId: [material.nomenclature ? material.nomenclature.id : null],
            quantity: [material.quantity, ValidationService.validateQuantity],
            // price: [material.material_cost, ValidationService.validateQuantity],
            percentage: [material.percentage ? material.percentage : null, [Validators.min(0), Validators.max(100)]]
        });
    };
    PassportComponentsComponent.prototype.updateDisableStatus = function () {
        if (!this.listCtrl) {
            this.listCtrl = this._fb.array([]);
        }
        if (this.disableStatus) {
            this.listCtrl.controls.forEach(function (control) { return control.disable(); });
        }
        else {
            this.listCtrl.controls.forEach(function (control) {
                control.enable();
                if (control.get('isDefault').value) {
                    control.get('componentName').disable();
                }
            });
        }
    };
    PassportComponentsComponent.prototype.save = function () {
        var _this = this;
        if (this.passport && this.passport.id) {
            if (!!this.listCtrl && !!this.listCtrl.controls.length) {
                this.changePassport(this.passport).updateSubscription().getComponents();
                var requests = this.listCtrl.controls.map(function (control) { return _this.saveItem(control); });
                return forkJoinSafe(requests);
            }
        }
    };
    PassportComponentsComponent.prototype.saveItem = function (componentCtrl) {
        var _this = this;
        var controls = componentCtrl.controls;
        var materials = controls.materials.controls
            .map(function (materialForm) { return _this.getMaterialFromForm(materialForm, controls.componentId.value); })
            .filter(function (material) { return !!material; });
        var parsedQuantity = ValidationService.normalizeQuantity(controls.componentNumber.value);
        var getComponentCrudData = function (data) {
            return tslib_1.__assign({ name: controls.componentName.value, quantity: parsedQuantity ? parsedQuantity : controls.componentNumber.value, demountable: controls.componentDemountable.value, percentage_weight: controls.percentageWeight.value }, data);
        };
        if (controls.componentId.value) {
            return this
                .componentService
                .update(controls.componentId.value, getComponentCrudData())
                .flatMap(function (result) { return _this.saveComponentMaterials(materials, result); });
        }
        else {
            return (!controls.isDefault.value
                ? this.componentService.create(getComponentCrudData({ passport_id: this.passport.id }))
                : Observable.of(new PassportComponent({}))).flatMap(function (result) {
                materials.map(function (mat) {
                    mat.component_id = result.id;
                    return mat;
                });
                var requests = [];
                if (controls.isDefault.value) {
                    _this.passport.demountability_default = controls.componentDemountable.value;
                    _this.passport.percentage_default = controls.percentageWeight.value;
                    // TODO: it should not be here
                    // if (!this.passport.id) 
                    requests.push(_this.passport.save());
                }
                requests.push(_this.saveComponentMaterials(materials, result).map(function (res) {
                    controls.componentId.setValue(res.id);
                }));
                return forkJoinSafe(requests);
            });
        }
    };
    PassportComponentsComponent.prototype.saveComponentMaterials = function (materials, component) {
        return materials.length > 0
            ? this.materialService.updateMaterials(materials)
                .map(function (mats) {
                component.materials = mats;
                return component;
            })
            : Observable.of(component);
    };
    PassportComponentsComponent.prototype.getMaterialFromForm = function (form, componentId) {
        if (!form) {
            return null;
        }
        var idCtrl = form.get('id');
        var nomenclatureIdCtrl = form.get('nomenclatureId');
        var quantityCtrl = form.get('quantity');
        var price = form.get('price');
        var percentage = form.get('percentage');
        if (!nomenclatureIdCtrl || !nomenclatureIdCtrl.value) {
            return null;
        }
        var material = new Material({
            nomenclature_id: nomenclatureIdCtrl ? nomenclatureIdCtrl.value : null,
            quantity: quantityCtrl ? quantityCtrl.value : null,
            material_cost: price ? price.value : null,
            passport_id: this.passport.id,
            percentage: percentage ? percentage.value : null
        });
        if (idCtrl && idCtrl.value) {
            material.id = idCtrl.value;
        }
        if (componentId) {
            material.component_id = componentId;
        }
        return material;
    };
    PassportComponentsComponent.prototype.deleteComponent = function (id) {
        this.componentService.remove(id).subscribe();
    };
    PassportComponentsComponent.prototype.selectCtrl = function (componentCtrl, $event, index) {
        this.toggleMaterialVisibility(index, false);
        if (!this.disableStatus) {
            if (this.selectedCtrl === componentCtrl) {
                return;
            }
            else {
                $event.preventDefault();
                $event.stopPropagation();
            }
            (!componentCtrl.get('isDefault').value)
                ? componentCtrl.get('componentName').enable()
                : componentCtrl.get('componentName').disable();
            componentCtrl.get('componentNumber').enable();
            componentCtrl.get('componentDemountable').enable();
            componentCtrl.get('percentageWeight').enable();
        }
        else {
            $event.preventDefault();
            $event.stopPropagation();
        }
        this.selectedCtrl = componentCtrl;
    };
    PassportComponentsComponent.prototype.isAddButtonVisible = function (index) {
        return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && index === this.listCtrl.length - 1;
    };
    PassportComponentsComponent.prototype.isRemoveButtonVisible = function (componentCtrl) {
        return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && !componentCtrl.get('isDefault').value;
    };
    PassportComponentsComponent.prototype.closeAllMaterialDropDowns = function () {
        this.listCtrl.controls.map(function (e) {
            e.patchValue({ isMaterialOpen: false });
        });
        this.dropDownChanged();
    };
    PassportComponentsComponent.prototype.toggleMaterialVisibility = function (index, value, event) {
        this.listCtrl.controls.map(function (e, i) {
            if (i !== index) {
                e.patchValue({ isMaterialOpen: false });
            }
        });
        this.listCtrl.at(index).patchValue({ isMaterialOpen: !value });
        this.dropDownChanged(index, !this.listCtrl.controls[index].get('isMaterialOpen').value);
        if (event && value) {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    PassportComponentsComponent.prototype.getLabelClasses = function (field) {
        return {
            'primary-color': field,
            'label-color': !field,
            'passport-edit__color-transition': true
        };
    };
    PassportComponentsComponent.prototype.dropDownChanged = function (index, toggled) {
        var _this = this;
        if (index === void 0) { index = 0; }
        if (toggled === void 0) { toggled = true; }
        setTimeout(function () {
            var materialsPanelHeight = _this.components._results[index].nativeElement.children[1].offsetHeight;
            var componentsRowHeight = _this.components._results[index].nativeElement.children[0].offsetHeight;
            var allRowsLength = _this.listCtrl.controls.length - index - 1;
            _this.margin_bottom = materialsPanelHeight - (allRowsLength * componentsRowHeight);
            _this.margin_bottom = _this.margin_bottom < 0 ? 0 : _this.margin_bottom;
            _this.margin_bottom_initial = toggled ? 0 : _this.margin_bottom;
            _this.controlGroupState = _this.controlGroupState === 'open' ? 'closed' : 'open';
        }, 100);
    };
    PassportComponentsComponent.prototype.preventLettersAndNumberGreaterThanOneHundred = function (event) {
        if ((isNaN(Number(event.key)) && event.key !== '.') || event.target['value'].length > 4) {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    /**
     * Get and format the current components and materials linked to these materials
     * Also isolates the attributes of the default component to be directly put in corresponding attributes of the passport
     * @returns {Object}
     */
    PassportComponentsComponent.prototype.getCurrentComponents = function () {
        var components = (this.listCtrl.getRawValue() || []).map(this.formatComponentForBackend, this);
        var defaultComponent = components.find(function (component) { return component.isDefault; });
        var otherComponents = components.filter(function (component) { return !component.isDefault; });
        return {
            components: otherComponents,
            percentage_default: defaultComponent.percentage_weight,
            materials: defaultComponent.materials,
            demountability_default: defaultComponent.demountable
        };
    };
    /**
     * Format the component object to be sent to the backend
     * @param {PassportComponent} component - The component to format
     * @returns {Object}
     */
    PassportComponentsComponent.prototype.formatComponentForBackend = function (component) {
        return {
            demountable: component.componentDemountable,
            id: component.componentId,
            name: component.componentName,
            percentage_weight: component.percentageWeight,
            materials: component.materials.map(this.formatMaterialForBackend, this),
            isDefault: component.isDefault
        };
    };
    /**
     * Format the material object to be sent to the backend
     * @param {PassportMaterial} material - The material to format
     * @returns {Object}
     */
    PassportComponentsComponent.prototype.formatMaterialForBackend = function (material) {
        return {
            nomenclature_id: material.nomenclatureId,
            percentage: material.percentage
        };
    };
    return PassportComponentsComponent;
}());
export { PassportComponentsComponent };
