import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from '../shared/services/current-user.service';
import { LogNotificationService } from '../shared/services/log-notification.service';
import { GuardStates } from '../shared/adn-common/guard-states.class';
import { LanguageService } from '../entities/client/service/language.service';

@Component({
  moduleId: module.id,
  selector: 'login-window',
  templateUrl: 'login.component.html',
  providers: [CurrentUserService]
})

export class LoginComponent implements OnInit {
  public username: string;
  public password: string;

  constructor(private userService: CurrentUserService,
              private router: Router,
              public notification: LogNotificationService,
              private languageService: LanguageService) {
  }
  ngOnInit() {
    this.languageService.initLang();
  }
  login() {
    console.log('herein login component ');
    console.log(this.userService.getApiUrl());
    this
      .userService
      .login({
        'username': this.username,
        'password': this.password
      })
      .do((data) => {
        this.userService.setLoginData(data);
        this.userService.markAsLoggedIn();
      })
      .subscribe(
        data => {
          this.handleUserData(data);
        },
        err => {
          this.notification.error(
            err.status !== 0
              ? err.json().message
              : 'Internet connection missing',
            null, true
          );
        }
      );
  }
  handleUserData(data: any) {
    const roleName = this.userService.roleName;
    const role = this.userService.userDefaultContext;
    if (!role) {
      throw new Error(`cannot find role '${roleName}'`);
    } else {
      GuardStates.clearActivatedGuards();
      this.router.navigate([role.homeUrl]);
    }
  }
  goToForgotPassword() {
    this.router.navigate(['login/forgot-password']);
  }
}
