/**
 * Created by atat on 18/06/2017.
 */

 import { Component, OnInit } from '@angular/core';
 import {
   ECustomListHintClass,
   ECustomListRecordStatus,
   ICustomListKeyPair,
   ICustomListRecord
 } from '../../../../shared/custom-list/interfaces/custom-list.interface';
 import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
 import { TransformOrderService } from '../../../ecosystem-manager/services/transform-order.service';
 import { TransformOrder } from '../../../ecosystem-manager/models/transform-order.class';
 import { DatePipe } from '@angular/common';
 import { TRANSFORM_ORDER_STATUS } from '../../values/transform-order-statuses.const';
 import { MdDialog } from '@angular/material';
 import { TsmOrderDetailsDialogComponent } from '../../dialogs/tsm-order-details-dialog/tsm-order-details-dialog.component';
 import { TRANSFORM_TYPES } from '../../values/transform-types.const';
 import * as moment from 'moment';
 import { OrderPassport } from '../../../ecosystem-manager/models/order-passport.class';
 import {TranslatePipe, TranslateService} from '@ngx-translate/core';
 import { isIndependent } from '../../values/transform-dependency.const';
 import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
 import { PASSPORT_UNITS_MAP } from '../../../../entities/passport/values/passport-units.const';
 import {
   TransformationOrderTransactionConfirmDialogComponent
 } from '../../dialogs/transformation-order-transaction-confirm-dialog/transformation-order-transaction-confirm-dialog.component';
 import {ReportProblemDialogComponent} from '../../../../shared/report-problem-dialog/report-problem-dialog.component';
 import {IReportProblemDialogInput} from '../../../../shared/report-problem-dialog/values/report-problem-dialog-input.interface';
 import {NotificationSuccessComponent} from '../../../../shared/notification-dialogs/notification-success/notification-success.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';

 @Component({
   moduleId: module.id,
   selector: 'tsm-orders-catalog',
   providers: [TranslatePipe],
   templateUrl: 'tsm-orders-catalog.component.html'
 })
 export class TsmOrdersCatalogComponent implements OnInit {
   records: ICustomListRecord[] = [];
   transformOrders: TransformOrder[] = [];
   esmContacts: any[];
   inputData: ICustomListKeyPair[];
 
   currentPage = 1;
   totalPagesCount = 0;
   isNextPageLoading = false;
   PASSPORT_UNIT_MAP = PASSPORT_UNITS_MAP;
   sortOptions: any = [
     { field: 'publication_date', view: 'Days since reception'},
     { field: 'creator', view: 'Ecosystem Manager'},
     { field: 'transformation_name', view: 'Transformation name'}
   ];
   sortValue = 'publication_date';
 
   // Filters
   statuses = [
     {
       text: 'Ask for modification',
       value: TRANSFORM_ORDER_STATUS.ASK_FOR_MODIFICATION,
       checked: false
     },
     {
       text: 'Validated',
       value: TRANSFORM_ORDER_STATUS.VALIDATED,
       checked: false
     },
     {
       text: 'Booked',
       value: TRANSFORM_ORDER_STATUS.PENDING,
       checked: false
     },
     // {
     //   text: 'Confirmed',
     //   value: TRANSFORM_ORDER_STATUS.CONFIRMED
     // },
     {
       text: 'Waiting for validation',
       value: TRANSFORM_ORDER_STATUS.WAITING_FOR_VALIDATION,
       checked: true
     },
     {
       text: 'Waiting for confirmation',
       value: TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION,
       checked: true
     }
   ];
 
   TRANSFORM_TYPES = TRANSFORM_TYPES;
   type: number = null;
 
   esmContact: number;
   search: string;
 
   constructor(private _ordersService: TransformOrderService,
               private mdDialog: MdDialog,
               public datepipe: DatePipe,
               public translate: TranslatePipe,
               public _translate: TranslateService,
               private _globalEvents: GlobalEventsManager,
   ) {
     const options = {
       'tsm_view': 1,
     };
     this._ordersService.getEsmList(options).subscribe((esmContacts) => {
       this.esmContacts = esmContacts;
     });
   }
 
   addRecord(order: TransformOrder) {
     const inputData = [];
     const orderWithCommonStep = [order, ...order.orders_common_step.map(
       item => {
         item.transformation = order.transformation;
         return  item;
       })];
     let arrivalAt = order.in_order_passport.linked_datetime;
     arrivalAt = arrivalAt ? this.datepipe.transform(arrivalAt, 'dd.MM.yyyy') : '';
     const arrivalIsToday = arrivalAt === this.datepipe.transform(new Date(), 'dd.MM.yyyy');
     let departureAt = order.out_order_passport.linked_datetime;
     departureAt = departureAt ? this.datepipe.transform(departureAt, 'dd.MM.yyyy') : '';
     orderWithCommonStep.forEach(filteredOrder => inputData.push(
       {key: this.getInputDataString(filteredOrder), seperator: ' ', value: this.getInputDataUnit(filteredOrder)})
     );
 
     const daysPassed = moment()
       .diff(moment(order.publication_date || order.confirmation_date), 'days')
       .toFixed() || '';
 
     // -blue hand for waiting for validation
     // -nothing for pending & ask for a modification & confirmed
     // -green check for validated
     // -blue ? mark for waiting for confirmation
 
     let status: ECustomListRecordStatus;
     switch (order.status) {
       case TRANSFORM_ORDER_STATUS.WAITING_FOR_VALIDATION:
         status = ECustomListRecordStatus.MANUAL;
         break;
       case TRANSFORM_ORDER_STATUS.PENDING:
         status = ECustomListRecordStatus.NO_STATUS;
         break;
       case TRANSFORM_ORDER_STATUS.ASK_FOR_MODIFICATION:
         status = ECustomListRecordStatus.NO_STATUS;
         break;
       case TRANSFORM_ORDER_STATUS.CONFIRMED:
         status = ECustomListRecordStatus.NO_STATUS;
         break;
       case TRANSFORM_ORDER_STATUS.VALIDATED:
         status = ECustomListRecordStatus.CONFIRM;
         break;
       case TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION:
         status = ECustomListRecordStatus.QUESTION;
         break;
       default:
         status = ECustomListRecordStatus.NO_STATUS;
     }
     this.records.push(
       this.createOrderRecord(
         status,
         arrivalIsToday ? ECustomListHintClass.CONFIRM : ECustomListHintClass.NO_CLASS,
         arrivalIsToday ? 'Arrival is today' : '',
         this.getTransformationType(order, false),
         order.transformation.name,
         this.getTransformationType(order, true),
         order.ecosystem_name,
         daysPassed,
         arrivalAt,
         departureAt,
         isIndependent(order.transformation.independent) ? order.creator_company : order.creator,
         order,
         inputData
       )
     );
   }
 
   getTransformationType(order: TransformOrder, capitalized: boolean) {
     let transformationType = TRANSFORM_TYPES.find((TYPE) => TYPE.value === order.transformation.transformation_type).view;
     transformationType = this.translate.transform(transformationType);
     if (capitalized === true) {
       return transformationType.charAt(0).toUpperCase() + transformationType.slice(1).toLowerCase();
     }
     return transformationType;
   }
 
   ngOnInit(): void {
     this.getRecords();
   }
 
   onEndOfListTriggered() {
     if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount)  {
       this.getRecords(this.currentPage + 1);
     }
   }
 
   getRecords(forPage: number = 0) {
     if (!this.statuses.find(status => status.checked)) {
       this.records.length = 0;
     } else {
       const commonStepOptions = ['in_order_passport', 'default_unit', 'default_conversion', 'child_step'];
       const options = {
         'expand': 'transformation,out_order_passport,creator,ecosystem_name,creator_company,'
           + commonStepOptions.join(',') + ','
           + commonStepOptions.map(option => `orders_common_step.${option}`).join(','),
         'supervisor': 1,
         'tsm_view': 1,
         'search': this.search || null,
         'esm': this.esmContact || null,
         'sort': this.sortValue || null,
         'status[]': this.statuses
           .filter(status => status.checked)
           .map(status => status.value),
         'group_by_step': 1,
         'transformation_type': this.type,
         'page': forPage,
       };
       this.isNextPageLoading = true;
       this._ordersService
         .getWithPaginationData(options)
         .subscribe((res) => {
           if (forPage === 0) {
             this.records = [];
           }
           const headers = res.headers;
           this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
           this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
           this.transformOrders = res.json().map((item: TransformOrder) => new TransformOrder(item));
           res.json().map((item: any) => this.addRecord(new TransformOrder(item)));
           this.isNextPageLoading = false;
         });
     }
   }
 
   createOrderRecord(status: ECustomListRecordStatus,
                     hintCssClass: ECustomListHintClass,
                     hintText: string,
                     icon: string,
                     title: string,
                     subtitle: string,
                     esmName: string,
                     daysPassed: string,
                     arrivalAt: string,
                     departureAt: string,
                     author: string,
                     entity: TransformOrder,
                     inputData: ICustomListKeyPair[]): CustomListRecord {
     const showValidationButtons = entity.status === TRANSFORM_ORDER_STATUS.WAITING_FOR_VALIDATION;
     const showConfirmationButton = entity.status === TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION;
     const showInvalidateButton = entity.status === TRANSFORM_ORDER_STATUS.PENDING;
     const controls = [
       {
         fn: (data: ICustomListRecord) => {
           const dialogRef = this.mdDialog.open(TsmOrderDetailsDialogComponent, {
             data: {
               id: entity.id,
               readOnly: !showValidationButtons,
             },
           });
           dialogRef.afterClosed().subscribe((result: any) => {
             this.getRecords();
           });
         },
         name: 'See details'
       },
      //  {
      //    fn: (customData: ICustomListRecord) => {
      //        this.openChat(entity.creator_id);
      //      },
      //    name: 'Chat with ESM'
      //  },
       ...(showValidationButtons ? [
         {
           fn: () => {
             entity.validateByStep().subscribe(() => {
               this.getRecords();
             });
           },
           name: 'Validate'
         },
         {
           fn: () => {
             entity.rejectByStep().subscribe(() => {
               this.getRecords();
             });
           },
           name: 'Reject'
         }
       ] : []),
       ...(showConfirmationButton ? [
         {
           fn: () => {
             this.mdDialog.open(TransformationOrderTransactionConfirmDialogComponent, {
               width: '653px',
               data: {
                 id: entity.id
               }
             }).afterClosed().subscribe(() => {
               this.getRecords();
             });
           },
           name: 'Confirm'
         }
         ] : []),          ...(showConfirmationButton ? [{ fn: (data: ICustomListRecord) => {
          const dialogRef = this.mdDialog.open(ReportProblemDialogComponent, {
            ...DIALOG_THEME.PROBLEM_REPORT,
            data: {
              foreword: 'The transformation could not be achieved?',
              readOnly: !showConfirmationButton,
              onConfirm: (description) => this._ordersService.sendReport(entity.id, description).map(() => true)
            } as IReportProblemDialogInput,
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.mdDialog.open(NotificationSuccessComponent, DIALOG_THEME.PROBLEM_REPORT);
              this.getRecords();
            }
          });
        },
        name: 'Problem with order'
      }] : []),
       ...(showInvalidateButton ? [
         {
           fn: () => {
             entity.unvalidate().subscribe(() => {
               this.getRecords();
             });
           },
           name: 'Invalidate'
         }
         ] : [])
     ];
     return CustomListRecord
       .build()
       .setStatus(status)
       .setHint(hintText, hintCssClass)
       .addTitleColumn(title, subtitle)
       .addCounterColumn(esmName, 'Ecosystem :')
       .addListColumn(inputData)
       .addListColumn(
         [
           { key: daysPassed, value: '', seperator: ' ' },
           { key: '', value: this._translate.instant('Days since'), seperator: '' },
           { key: '', value: this._translate.instant('reception'), seperator: '' }
         ]
       )
       .addCounterColumn(departureAt, 'End of transformation :')
       .addAuthorColumn(author)
       .addControlsColumn({options: controls, icon: 'menu'});
   }
 
   getInputDataString(order: TransformOrder) {
     return order.inputNameView;
   }
   getInputDataUnit(order: TransformOrder) {
       return (!!order.orderInQuantityView || order.orderInQuantityView === 0)
         ? ' (' + (order.orderInQuantityView) + ' ' +  this.PASSPORT_UNIT_MAP[order.unitInView]  + ')' : '';
   }
 
   openChat(targetId: number) {
     console.log('openChat', targetId);
     this._globalEvents.chatUserClicked$.emit(targetId);
   }
 }