import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ImportValidatorsService } from '../../../../import-spreadsheet/services/import-validators.service';
import { ImportProgressService } from '../../../../import-spreadsheet/services/import-progress.service';
import { ImportConfigurationService } from '../../../../import-spreadsheet/services/import-configuration.service';
import { IMPORT_DOCUMENT_CONTROLS } from '../../../../import-spreadsheet/values/import-document-controls.const';
import { DEPOSIT_LOCATION_IMPORT_COLUMNS, DEPOSIT_LOCATION_IMPORT_CONTROL_META, DEPOSIT_LOCATION_IMPORT_CONTROL_OPTIONS } from './values/deposit-location-controls';
import { TABLE_SYNONYM_AND_COLUMNS } from './values/deposit-location-columns.const';
import { MdDialog } from '@angular/material';
import { cloneDeep } from 'lodash';
import { TABLE_TYPES } from '../../../../import-spreadsheet/values/import-tables.const';
import { DepositLocationImport } from '../../../../entities/deposit/models/deposit-location-import.class';
import { ImportDeepstreamService } from '../../../../import-spreadsheet/services/import-deepstream.service';
var DoImportComponent = /** @class */ (function () {
    function DoImportComponent(_fb, importValidation, importConfigurationService, importProgressService, importDeepstreamService, _dialog) {
        this._fb = _fb;
        this.importValidation = importValidation;
        this.importConfigurationService = importConfigurationService;
        this.importProgressService = importProgressService;
        this.importDeepstreamService = importDeepstreamService;
        this._dialog = _dialog;
        this.DEPOSIT_IMPORT_CONTROL_META = DEPOSIT_LOCATION_IMPORT_CONTROL_META;
        this.tables = [
            TABLE_TYPES.DEPOSIT_LOCATION_MAPPING
        ];
        this.savePreferencesAfterNumberOfChanges = 6;
        this.savePreferencesAfterInInterval = -1;
        this.savePreferencesAfterSubmit = true;
        this.savePreferencesOnDestroy = true;
        this.submit = new EventEmitter();
        this.importPreferences = TABLE_SYNONYM_AND_COLUMNS;
        this.columns = {};
        this.uploadedDepositFile = [];
        this.depositImport = new DepositLocationImport();
        this.onUpdateUploadUrl$ = new EventEmitter();
        this.onDocumentSave$ = new EventEmitter();
        this.filesList = [];
        this.fields = {};
    }
    DoImportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columnFieldForm = this._fb.group(tslib_1.__assign({}, DEPOSIT_LOCATION_IMPORT_CONTROL_OPTIONS), {
            validators: [
            //      (control) => this.importValidation.validateColumnDuplication(control, this.fields),
            ]
        });
        this.depositImportForm = this._fb.group(tslib_1.__assign({}, IMPORT_DOCUMENT_CONTROLS, { id: null }), {
            validators: [
                function (control) { return _this.importValidation.validateNumberControlIsNotLess(control); },
            ]
        });
        this.depositImportForm.addControl('columnFields', this.columnFieldForm);
    };
    DoImportComponent.prototype.ngAfterViewInit = function () {
        this.fields = cloneDeep(DEPOSIT_LOCATION_IMPORT_COLUMNS);
    };
    DoImportComponent.prototype.updateImportAndShowWarning = function () {
        var _this = this;
        if (!this.validateAll()) {
            return;
        }
        this.createModel();
        this.importProgressService.startNewProcess();
        this.depositImport.save().subscribe(function (depositImportInserted) {
            _this.importProgressService.setCurrentId(depositImportInserted.id);
            _this.importDeepstreamService.setType(2 /* SITES */);
            _this.depositImport = depositImportInserted;
            if (_this.uploadedDepositFile.length === 1) {
                _this.documentUploaded();
            }
            else {
                _this.onUpdateUploadUrl$.emit(_this.depositImport.getFileUploadUrl());
                _this.onDocumentSave$.emit();
            }
        }, function () { return _this.importProgressService.resetProgressModal(); });
    };
    DoImportComponent.prototype.documentUploaded = function () {
        var _this = this;
        var spreadsheetImport = this.depositImport;
        spreadsheetImport.startImport().subscribe(function () { }, function () { return _this.importProgressService.resetProgressModal(); });
    };
    DoImportComponent.prototype.createModel = function () {
        this.depositImportForm.controls['id'].setValue(this.depositImport.id);
        var columns = this.importValidation.parseColumnsToModel(this.columnFieldForm, this.fields);
        this.depositImport = new DepositLocationImport(tslib_1.__assign({}, this.depositImportForm.value, columns));
    };
    DoImportComponent.prototype.validateAll = function () {
        return this.depositImportForm.valid;
    };
    DoImportComponent.prototype.fileAdded = function (files) {
        this.filesList = files;
    };
    return DoImportComponent;
}());
export { DoImportComponent };
