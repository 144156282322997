import * as tslib_1 from "tslib";
/**
 * Created by aleksandr on 13.06.17.
 */
import { EventEmitter, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ECustomListKeyPairClass } from '../../../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../../../shared/custom-list/custom-list-record.model';
import { TransformService } from '../../../../../../entities/transformation/services/transform.service';
import { Transform } from '../../../../../../entities/transformation/models/transform.class';
import { FormControl } from '@angular/forms';
import { MdDialog } from '@angular/material';
import { getTransformTypeLcView, TRANSFORM_TYPES } from '../../../../../transformation-manager/values/transform-types.const';
import { EsmTransformationDialogComponent } from '../../../../dialogs/esm-transformation-dialog/esm-transformation-dialog.component';
import { DatePipe } from '@angular/common';
import { TsmListService } from '../../../../../../entities/transformation/services/tsm-list.service';
import { TransformDetailsDialogComponent } from '../../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_THEME } from '../../../../../../shared/helpers/dialog-themes.const';
import { isIndependent } from '../../../../../transformation-manager/values/transform-dependency.const';
import { CurrentUser } from '../../../../../../shared/auth/current-user.class';
import { NavbarStateService } from '../../../../../../shared/services/navbar.service';
import { Observable } from 'rxjs/Observable';
var EsmTransformationCatalogComponent = /** @class */ (function () {
    function EsmTransformationCatalogComponent(_transformService, dialog, datePipe, tsmListService, mdDialog, translateService, changeDetectorRef, navBarState) {
        var _this = this;
        this._transformService = _transformService;
        this.dialog = dialog;
        this.datePipe = datePipe;
        this.tsmListService = tsmListService;
        this.mdDialog = mdDialog;
        this.translateService = translateService;
        this.changeDetectorRef = changeDetectorRef;
        this.navBarState = navBarState;
        this.onChange$ = new EventEmitter();
        this.TRANSFORM_TYPES = TRANSFORM_TYPES;
        this.search = new FormControl();
        this.isListExtended = false;
        this.isNextPageLoading = false;
        this.currentPage = 1;
        this.totalPagesCount = 0;
        this.records = [];
        this.sortOptions = [
            { field: 'name', view: 'Name' },
            { field: 'tsm_name', view: 'Transformation Manager' }
        ];
        this.sortValue = 'name';
        this.markers = [];
        this.searchStatus = [];
        this
            .tsmListService
            .get({ include_independents: 1 })
            .subscribe(function (tsms) { return _this.tsms = tsms; });
    }
    EsmTransformationCatalogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.miltilanguageSubscription = this.navBarState.multilanguageObservable$.subscribe(function () { return _this.changeDetectorRef.detectChanges(); });
        this.showIndependentTranformFilter = CurrentUser.infoData.has_transform_acces;
        this
            .search
            .valueChanges
            .debounceTime(300)
            .distinctUntilChanged()
            .subscribe(function () { return _this.getRecords(); });
        this.getRecords();
        this.getMarkers();
    };
    EsmTransformationCatalogComponent.prototype.ngOnDestroy = function () {
        this.miltilanguageSubscription.unsubscribe();
    };
    EsmTransformationCatalogComponent.prototype.openMapDialog = function ($event) {
        // console.log('openMapDialog', $event);
        var dialogRef = this.dialog.open(EsmTransformationDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR));
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('afterClosed', result);
        });
    };
    EsmTransformationCatalogComponent.prototype.onEndOfListTriggered = function () {
        if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount) {
            this.getRecords(this.currentPage + 1);
        }
    };
    EsmTransformationCatalogComponent.prototype.resetRecords = function () {
        this.getRecords();
        this.onChange$.emit();
    };
    EsmTransformationCatalogComponent.prototype.getRecords = function (forPage) {
        if (forPage === void 0) { forPage = 0; }
        this.isListExtended ? this.getRecordsWithoutPagination().subscribe() : this.getRecordsWithPagination(forPage);
    };
    EsmTransformationCatalogComponent.prototype.getRecordsWithPagination = function (forPage) {
        var _this = this;
        var options = tslib_1.__assign({}, this.queryParams, { page: forPage, paginate: 1 });
        this.isNextPageLoading = true;
        this.pageRequestObserver = this._transformService
            .getWithPaginationData(options)
            .subscribe(function (res) {
            if (forPage === 0) {
                _this.records = [];
            }
            var headers = res.headers;
            _this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
            _this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
            res.json().map(function (item) { return _this.addRecord(new Transform(item)); });
            if (!_this.changeDetectorRef['destroyed']) {
                _this.changeDetectorRef.detectChanges();
            }
            _this.isNextPageLoading = false;
        });
        this.recordsLoadingRequest = this._transformService
            .get(this.queryParams)
            .subscribe(function (data) {
            _this.markers = [];
            data.forEach(function (item) { return _this.addMarkers(item); });
            _this.recordsLoadingRequest = null;
            _this.changeDetectorRef.detectChanges();
        });
        // .subscribe((data: Transform[]) => data.forEach((item) => this.addRecord(item)))
        // ;
    };
    Object.defineProperty(EsmTransformationCatalogComponent.prototype, "queryParams", {
        get: function () {
            var options = {
                expand: 'files,materials,tsm_name,tsm_org,tsm_email,tags,sites,sectors',
                admin: 1,
                esm_view: 1,
                supervisor: 1,
                search: this.search.value ? this.search.value.trim() : null,
                transformation_type: this.type || null,
                tsm: this.tsmId || null,
                sort: this.sortValue,
                independent: this.showIndepentTransformation,
            };
            if (this.navBarState.multilanguage) {
                options.english = true;
            }
            return options;
        },
        enumerable: true,
        configurable: true
    });
    EsmTransformationCatalogComponent.prototype.updateRecordsOnListExpand = function () {
        if (this.currentPage === this.totalPagesCount) {
            return Observable.of(null);
        }
        else {
            return this.getRecordsWithoutPagination();
        }
    };
    EsmTransformationCatalogComponent.prototype.getRecordsWithoutPagination = function () {
        var _this = this;
        return this._transformService.get(this.queryParams)
            .map(function (data) {
            _this.syncWithGetWithPagination();
            _this.records = [];
            data.forEach(function (item) { return _this.addRecord(item); });
            if (!_this.changeDetectorRef['destroyed']) {
                _this.changeDetectorRef.markForCheck();
            }
        });
    };
    EsmTransformationCatalogComponent.prototype.syncWithGetWithPagination = function () {
        this.currentPage = this.totalPagesCount;
        this.isNextPageLoading = false;
        if (this.pageRequestObserver && !this.pageRequestObserver.closed) {
            this.pageRequestObserver.unsubscribe();
        }
    };
    EsmTransformationCatalogComponent.prototype.getMarkers = function () {
        var _this = this;
        this.recordsLoadingRequest = this._transformService
            .get(this.queryParams)
            .subscribe(function (data) {
            _this.markers = [];
            data.forEach(function (item) { return _this.addMarkers(item); });
            _this.recordsLoadingRequest = null;
            _this.changeDetectorRef.detectChanges();
        });
    };
    EsmTransformationCatalogComponent.prototype.addMarkers = function (data) {
        var _this = this;
        var _a;
        (_a = this.markers).push.apply(_a, data.sites.map(function (item) { return _this.toMarker(data.name, item, data.independent); }));
    };
    EsmTransformationCatalogComponent.prototype.toMarker = function (transformName, data, independent) {
        return {
            lat: data.lat,
            lng: data.lng,
            name: transformName,
            lines: [
                "" + data.name,
                "" + data.address
            ],
            icon: this.isSpecialAdmin ?
                (isIndependent(independent) ? 'assets/png/map/localisation-blue.png' : 'assets/png/map/localisation-orange.png')
                : null
        };
    };
    EsmTransformationCatalogComponent.prototype.addRecord = function (data) {
        var _this = this;
        var materialsList = [];
        // let keywordsOrControlType: ICustomListKeyPair[] = [];
        var reversedColumnEntries = { cssClass: ECustomListKeyPairClass.REVERSED_LIST_COLUMN, seperator: ' ' };
        if (data && data.transformation_type_data) {
            if (data.transformation_type_data.includes.incomeOutcome) {
                var transformMaterial_1 = function (material) {
                    material = _this.translateService.instant(material);
                    material = "<b>" + material + "</b>";
                    return material.replace(/[(]([^)]+)[)]/g, '</b>($1)<b>');
                };
                var incomeMaterials = data.inputMaterials
                    .map(function (material) { return transformMaterial_1(material.name); }).join(',');
                var outcomeMaterials = data.outputMaterials
                    .map(function (material) { return transformMaterial_1(material.name); }).join(',');
                // const incomeTags = data.inputTags.map((tag) => tag.name).join(',');
                // const outcomeTags = data.outputTags.map((tag) => tag.name).join(',');
                materialsList = [
                    { key: this.translateService.instant('Input') + ':',
                        seperator: ' ',
                        value: incomeMaterials, cssClass: 'material' },
                    { key: this.translateService.instant('Output') + ':',
                        seperator: ' ',
                        value: outcomeMaterials, cssClass: 'material' }
                ];
                // keywordsOrControlType = [
                //   { key: 'Keywords', value: incomeTags },
                //   { key: 'Keywords', value: outcomeTags }
                // ];
            }
            else if (data.transformation_type_data.includes.acceptedMaterials) {
                materialsList = [
                    tslib_1.__assign({ key: '', seperator: ' ', value: data.acceptedMaterialsView }, reversedColumnEntries, { cssClass: 'material-accepted' }),
                ];
                // if (data.transformation_type_data.includes.controlType) {
                //   keywordsOrControlType = [
                //     { key: 'Control type', value: data.controlTypeView, ...reversedColumnEntries },
                //   ];
                // }
            }
        }
        var listOfLocations = [];
        if (data.transformation_type_data.includes.serviceArea) {
            // listOfLocations = data.sectors.map(sector => ({ key: '', seperator: '', value: sector.name }));
            if (data.sectors.length) {
                data.sectors.forEach(function (sector) { return listOfLocations.push({ key: '', seperator: '', value: sector.name }); });
            }
        }
        else if (data.transformation_type_data.includes.storingSites) {
            // listOfLocations = data.sites.map((site) => ({ key: '', seperator: '', value: site.name }));
            if (data.sites.length) {
                data.sites.forEach(function (site) {
                    listOfLocations.push({ key: '', seperator: '', value: site.name, cssClass: 'title' });
                    if (data.sites[0].address) {
                        listOfLocations.push({ key: '', seperator: '', value: site.address });
                    }
                });
            }
        }
        this.records.push(this.createTransformationRecord(data.transformation_type_data.value ? getTransformTypeLcView(data.transformation_type_data.value) : '', data.viewName, data.transformation_type_data.view.replace(/./, function (c) { return c.toUpperCase(); }), materialsList, listOfLocations, data.tsm_name, data));
    };
    EsmTransformationCatalogComponent.prototype.createTransformationRecord = function (icon, title, subtitle, materialsList, listOfSites, author, transform) {
        var _this = this;
        return CustomListRecord
            .build()
            .setPrimaryKey(transform.id)
            .setIndependent(isIndependent(transform.independent))
            .addIconColumn(icon)
            .addTitleColumn(title, subtitle, transform.english_name)
            .addListColumn(materialsList)
            .addListColumn(listOfSites)
            .addAuthorColumn(isIndependent(transform.independent) ? transform.tsm_org : author)
            .addControlsColumn({ icon: 'menu', options: [{
                    fn: function (data) {
                        _this.mdDialog.open(TransformDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR, { data: {
                                id: transform.id,
                                readOnly: true,
                            } }));
                    },
                    name: 'See details'
                }, {
                    fn: function (data) {
                        location.href = 'mailto:' + transform.tsm_email;
                    },
                    name: 'Send Email'
                }] });
    };
    EsmTransformationCatalogComponent.prototype.toggleIndependent = function (checked) {
        this.showIndepentTransformation = +checked;
        this.getRecords();
    };
    return EsmTransformationCatalogComponent;
}());
export { EsmTransformationCatalogComponent };
