import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../../entities/stats/services/stats.service';
import {
  SALES_STATISTICS_SEGMENTS,
  SALES_STATISTICS_SEGMENTS_VIEW
} from '../../values/sales-statistics-segments.const';
import { SalesStatsContainer } from '../../../../entities/stats/models/sales-stats-container.class';

@Component({
  moduleId: module.id,
  templateUrl: 'sales-statistics.component.html'
})
export class SalesStatisticsComponent implements OnInit {
  SALES_STATISTICS_SEGMENTS = SALES_STATISTICS_SEGMENTS;
  SALES_STATISTICS_SEGMENTS_VIEW = SALES_STATISTICS_SEGMENTS_VIEW;
  segments: SALES_STATISTICS_SEGMENTS[] = [
    SALES_STATISTICS_SEGMENTS.NEEDS
  ];
  selectedSegment: number = SALES_STATISTICS_SEGMENTS.NEEDS;
  segmentStats: any = {};

  constructor(private statsService: StatsService) {
  }

  ngOnInit() {
    this.getSegmentData();
  }

  changeSegment(segment: number) {
    this.selectedSegment = segment;
    this.getSegmentData();
  }

  getSegmentData() {
    switch (this.selectedSegment) {
      case SALES_STATISTICS_SEGMENTS.NEEDS:
        this.getNeedsData();
        break;
      default:
        break;
    }
  }

  getNeedsData() {
    this.statsService.getSalesStats().subscribe((salesStats: SalesStatsContainer) => {
      this.segmentStats = salesStats.getNeeds();
    });
  }
}
