import { Component, Input } from '@angular/core';
import { Transform } from '../../../../../entities/transformation/models/transform.class';

@Component({
  moduleId: module.id,
  selector: 'accepted-materials',
  templateUrl: 'accepted-materials.component.html',
  styleUrls: ['accepted-materials.component.scss'],
})
export class AcceptedMaterialsComponent {
  @Input() transform: Transform;
  @Input() readOnly: boolean;
}
