/**
 * mohamed ouqas   on 12.07.19.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'copy-transformation-dialog',
  templateUrl: 'copy-transformation-dialog.component.html'
})
export class CopyTransformationDialogComponent implements OnInit, OnDestroy {

  public name: string;
  isSelectAnotherTransShown: boolean;
  constructor(private _dialog: MdDialogRef<any>) {

  }

  ngOnInit() {
    this.name = this._dialog._containerInstance.dialogConfig.data.name + ' (copy)';
    this.isSelectAnotherTransShown = this._dialog._containerInstance.dialogConfig.data.isSelectAnotherTransShown;
  }

  ngOnDestroy() {

  }

  onSelectAnotherTransformation() {
    this._dialog.close({resetForm: true});
  }

  submit(result: any) {
    this._dialog.close(result);
  }
}
