import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformMaterialService } from '../services/transform-material.service';
var TransformMaterial = /** @class */ (function (_super) {
    tslib_1.__extends(TransformMaterial, _super);
    function TransformMaterial() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformMaterialService;
        return _this;
    }
    TransformMaterial.prototype.fields = function () {
        return [
            'id',
            'nomenclature_id',
            'transformation_id',
            'is_input',
            'name',
        ];
    };
    return TransformMaterial;
}(ActiveRecord));
export { TransformMaterial };
