import * as tslib_1 from "tslib";
import { LandfillVariables } from './landfill-variables.model';
import { ExtendedCrudService } from '../../../../shared/services/extended-crud.service';
var LandfillVariablesService = /** @class */ (function (_super) {
    tslib_1.__extends(LandfillVariablesService, _super);
    function LandfillVariablesService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'landfill-variables';
        _this.namespaceSingular = '';
        _this.ModelClass = LandfillVariables;
        return _this;
    }
    LandfillVariablesService.prototype.getLandfillVariables = function () {
        return this.sendGet(this.getEndpoint(this.singularEndpoint))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    LandfillVariablesService.prototype.updateLandfillVariables = function (landfillValue, id) {
        return this.sendPut((this.getEndpoint(this.singularEndpoint) + '/' + id), { value: landfillValue })
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    return LandfillVariablesService;
}(ExtendedCrudService));
export { LandfillVariablesService };
