import * as tslib_1 from "tslib";
/**
 * Created by aleksandr on 30.06.17.
 */
import { EChatMessageStatus } from '../interfaces/chat-message.interface';
import { BaseModel } from 'ng2-adn-common';
var ChatMessage = /** @class */ (function (_super) {
    tslib_1.__extends(ChatMessage, _super);
    function ChatMessage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ChatMessage.prototype, "isOwner", {
        get: function () {
            return !!this.author && this.author.isMe;
        },
        enumerable: true,
        configurable: true
    });
    ChatMessage.create = function (record) {
        return new ChatMessage({
            id: Number(record.id),
            roomId: Number(record.thread_id),
            authorId: Number(record.user_id),
            type: Number(record.type),
            date: record.created_at,
            body: record.content ? record.content.content : '',
            isRead: record.status === EChatMessageStatus.READ,
            file: record.file
        });
    };
    ChatMessage.prototype.fields = function () {
        return ['id', 'roomId', 'authorId', 'author', 'type', 'isOwner', 'isRead', 'body', 'date', 'file'];
    };
    ChatMessage.prototype.read = function () {
        if (!this.isRead) {
            this.isRead = true;
        }
    };
    ChatMessage.prototype.setAuthor = function (author) {
        this.author = author;
    };
    return ChatMessage;
}(BaseModel));
export { ChatMessage };
