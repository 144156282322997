import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { ReportImport } from '../models/report-import.class';
var ReportImportService = /** @class */ (function (_super) {
    tslib_1.__extends(ReportImportService, _super);
    function ReportImportService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'report-imports';
        _this.namespaceSingular = 'report-import';
        _this.ModelClass = ReportImport;
        return _this;
    }
    return ReportImportService;
}(ExtendedCrudService));
export { ReportImportService };
