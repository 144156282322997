import { Component, Input } from '@angular/core';
import { MdDialog } from '@angular/material';
import { ViewNomenclatureDialogComponent } from '../../../../../entities/passport/dialogs/view-nomenclature/view-nomenclature.component';
import { Transform } from '../../../../../entities/transformation/models/transform.class';
import { TransformMaterial } from '../../../../../entities/transformation/models/transform-material.class';
import { TransformTag } from '../../../../../entities/transformation/models/transform-tag.class';
import { TransformTagJunction } from '../../../../../entities/transformation/models/transform-tag-junction.class';
import { TransformTagService } from '../../../../../entities/transformation/services/transform-tag.service';
import { Observable } from 'rxjs/Observable';
import {TransformFormatJunction} from '../../../../../entities/transformation/models/transform-format-junction.class';
import {TransformFormat} from '../../../../../entities/transformation/models/transform-format.class';
import {TransformFormatService} from '../../../../../entities/transformation/services/transform-format.service';
import {TransformPackagingJunction} from '../../../../../entities/transformation/models/transform-packaging-junction.class';
import {TransformPackaging} from '../../../../../entities/transformation/models/transform-packaging.class';
import {TransformPackagingService} from '../../../../../entities/transformation/services/transform-packaging.service';
import {TransformQualityJunction} from '../../../../../entities/transformation/models/transform-quality-junction.class';
import {TransformQuality} from '../../../../../entities/transformation/models/transform-quality.class';
import {TransformQualityService} from '../../../../../entities/transformation/services/transform-quality.service';

@Component({
  moduleId: module.id,
  selector: 'income-outcome',
  templateUrl: 'income-outcome.component.html'
})
export class IncomeOutcomeComponent {

  @Input() transform: Transform;
  @Input() readOnly: boolean;

  tagInputTexts: string[] = [];
  formatInputTexts: string[] = [];
  packagingInputTexts: string[] = [];
  qualityInputTexts: string[] = [];
  maxlength: number = 20;

  constructor(public dialog: MdDialog,
              private _transformTagService: TransformTagService,
              private transformFormatService: TransformFormatService,
              private transformPackagingService: TransformPackagingService,
              private transformQualityService: TransformQualityService) {
  }

  addMaterial(isInput: number) {
    const dialogRef = this.dialog.open(ViewNomenclatureDialogComponent, {
      height: '80%',
      width: '80%',
    });

    dialogRef.afterClosed().subscribe(nomenclature => {
      if (nomenclature) {
        const material = new TransformMaterial();

        material.is_input = isInput;
        material.transformation_id = this.transform.id;
        material.nomenclature_id = nomenclature.id;
        material.name = nomenclature.name;

        material.save().subscribe((res) => {
          material.id = res.id;
          this.transform.materials.push(material);
        });
      }
    });
  }

  removeMaterial(material: TransformMaterial) {
    this.removeItem(this.transform.materials, material);
  }

  addTag(isInput: number) {
    if(!this.tagInputTexts[isInput]) {
      return;
    }

    this._transformTagService
      .get({ name: this.tagInputTexts[isInput] })
      .flatMap((foundTags: TransformTag[]) => {
        return foundTags.length > 0
          ? Observable.of(foundTags[0])
          : new TransformTag({ name: this.tagInputTexts[isInput] }).save();
      })
      .flatMap((targetTag: TransformTag) => {
        this.tagInputTexts[isInput] = '';

        let junction = new TransformTagJunction({
          transformation_id: this.transform.id,
          tag_id: targetTag.id,
          is_input: isInput,
          name: targetTag.name
        });

        return junction.save();
      })
      .subscribe((createdJunction: TransformTagJunction) => {
        this.transform.tags.push(createdJunction);
      });
  }

  removeTag(tag: TransformTagJunction) {
    this.removeItem(this.transform.tags, tag);
  }
  addFormat(isInput: number) {
    if (!this.formatInputTexts[isInput]) {
      return;
    }

    this.transformFormatService
    .get({ name: this.formatInputTexts[isInput] })
    .flatMap((foundTags: TransformFormat[]) => {
      return foundTags.length > 0
        ? Observable.of(foundTags[0])
        : new TransformFormat({ name: this.formatInputTexts[isInput] }).save();
    })
    .flatMap((targetTag: TransformFormat) => {
      this.formatInputTexts[isInput] = '';

      const junction = new TransformFormatJunction({
        transformation_id: this.transform.id,
        format_id: targetTag.id,
        is_input: isInput,
        name: targetTag.name
      });

      return junction.save();
    })
    .subscribe((createdJunction: TransformFormatJunction) => {
      this.transform.formats.push(createdJunction);
    });
  }

  removeFormat(format: TransformFormatJunction) {
    this.removeItem(this.transform.formats, format);
  }
  addPackaging(isInput: number) {
    if (!this.packagingInputTexts[isInput]) {
      return;
    }

    this.transformPackagingService
    .get({ name: this.packagingInputTexts[isInput] })
    .flatMap((foundPackaging: TransformPackaging[]) => {
      return foundPackaging.length > 0
        ? Observable.of(foundPackaging[0])
        : new TransformPackaging({ name: this.packagingInputTexts[isInput] }).save();
    })
    .flatMap((targetTag: TransformPackaging) => {
      this.packagingInputTexts[isInput] = '';

      const junction = new TransformPackagingJunction({
        transformation_id: this.transform.id,
        packaging_id: targetTag.id,
        is_input: isInput,
        name: targetTag.name
      });

      return junction.save();
    })
    .subscribe((createdJunction: TransformPackagingJunction) => {
      this.transform.packagings.push(createdJunction);
    });
  }

  removePackaging(packaging: TransformPackagingJunction) {
    this.removeItem(this.transform.packagings, packaging);
  }
  addQuality(isInput: number) {
    if (!this.qualityInputTexts[isInput]) {
      return;
    }

    this.transformQualityService
    .get({ name: this.qualityInputTexts[isInput] })
    .flatMap((foundQuality: TransformQuality[]) => {
      return foundQuality.length > 0
        ? Observable.of(foundQuality[0])
        : new TransformQuality({ name: this.qualityInputTexts[isInput] }).save();
    })
    .flatMap((targetTag: TransformQuality) => {
      this.qualityInputTexts[isInput] = '';

      const junction = new TransformQualityJunction({
        transformation_id: this.transform.id,
        quality_id: targetTag.id,
        is_input: isInput,
        name: targetTag.name
      });

      return junction.save();
    })
    .subscribe((created: TransformQualityJunction) => {
      this.transform.qualities.push(created);
    });
  }

  removeQuality(quality: TransformQualityJunction) {
    this.removeItem(this.transform.qualities, quality);
  }
  removeItem(ref: any[], junction) {
    junction.destroy().subscribe(() => {
      const index = ref.indexOf(junction);
      if (index !== -1) {
        ref.splice(index, 1);
      }
    });
  }
  isInputShown(items: any[]): boolean {
    if (!this.readOnly || (!items || !items.length)) {
      return true;
    }
  }
}
