import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { EDeepStreamMessage } from '../../values/deepstream-message-type.enum';
import { BaseDeepStreamService } from './base-deepstream.service';
var DepositLocationDeepstreamService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationDeepstreamService, _super);
    function DepositLocationDeepstreamService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.messageTypes = [
            EDeepStreamMessage.SITE_IMPORT_ERRORS,
            EDeepStreamMessage.SITE_IMPORT_PROGRESS,
            EDeepStreamMessage.SITE_IMPORT_PROCESS_DOC,
            EDeepStreamMessage.SITE_IMPORT_PROGRESS_ERROR
        ];
        return _this;
    }
    DepositLocationDeepstreamService.prototype.emit = function (data) {
        var emittedValue = { target: this.receiver, data: data };
        switch (data.type) {
            case EDeepStreamMessage.SITE_IMPORT_ERRORS:
                return this.depositImportErrorsStream$.emit(emittedValue);
            case EDeepStreamMessage.SITE_IMPORT_PROGRESS:
                return this.depositCompletionStream$.emit(emittedValue);
            case EDeepStreamMessage.SITE_IMPORT_PROCESS_DOC:
                return this.depositImportParsedRowsStream$.emit(emittedValue);
            case EDeepStreamMessage.SITE_IMPORT_PROGRESS_ERROR:
                return this.depositImportFatalError$.emit(emittedValue);
        }
    };
    Object.defineProperty(DepositLocationDeepstreamService.prototype, "depositImportErrorsStream$", {
        get: function () {
            return DepositLocationDeepstreamService._depositLocationImportErrorsStream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositLocationDeepstreamService.prototype, "depositCompletionStream$", {
        get: function () {
            return DepositLocationDeepstreamService._depositLocationCompletionStream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositLocationDeepstreamService.prototype, "depositImportParsedRowsStream$", {
        get: function () {
            return DepositLocationDeepstreamService._depositLocationImportParsedRowsStream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositLocationDeepstreamService.prototype, "depositImportFatalError$", {
        get: function () {
            return DepositLocationDeepstreamService._depositLocationImportFatalError$;
        },
        enumerable: true,
        configurable: true
    });
    DepositLocationDeepstreamService._depositLocationImportErrorsStream$ = new EventEmitter();
    DepositLocationDeepstreamService._depositLocationCompletionStream$ = new EventEmitter();
    DepositLocationDeepstreamService._depositLocationImportParsedRowsStream$ = new EventEmitter();
    DepositLocationDeepstreamService._depositLocationImportFatalError$ = new EventEmitter();
    return DepositLocationDeepstreamService;
}(BaseDeepStreamService));
export { DepositLocationDeepstreamService };
