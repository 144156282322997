/**
 * Created by aleksandr on 29.08.17.
 */
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

export abstract class BeforeRouteActivatedHook implements CanActivate, CanActivateChild {

  abstract beforeRouteActivated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | void;

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let obs = this.beforeRouteActivated(route, state);
    return obs ? obs.map(() => true) : Observable.of(true);
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let obs = this.beforeRouteActivated(childRoute, state);
    return obs ? obs.map(() => true) : Observable.of(true);
  }

}
