<!-- [formGroupName]="contactForm" -->
<md-card class="location-form">
  <form [formGroup]="locationForm">
    <md-card-content>
      <div>
        <md-input-container>
          <input mdInput autocomplete="off" formControlName="name" placeholder="{{ 'Name' | translate }}" [(ngModel)]="location.name">
        </md-input-container>
      </div>
      <div>
        <md-input-container>
          <place-autocomplete
            [options]="{'types': ['geocode']}"
            class="place-autocomplete__full-width">
            <input
              mdInput
              formControlName="address"
              placeholder="{{ 'Address' | translate }}"
              [(ngModel)]="location.address"
              placeAutocomplete
              (placeChange)="onPlaceChange($event)"
            >
          </place-autocomplete>
        </md-input-container>
      </div>
      <div>
        <md-input-container>
          <input mdInput
                 autocomplete="off" 
                 formControlName="description"
                 placeholder="{{ 'Description' | translate }}"
                 [(ngModel)]="location.description">
        </md-input-container>
      </div>
    </md-card-content>
    <md-card-actions>
      <div class="location-form__controls">

          <button md-raised-button color="primary" type="submit"
                  (click)="save(locationForm)"
                  [disabled]="!locationForm.valid || !locationForm.dirty"
                  class="uppercase">{{ 'Save' | translate }}
          </button>

        <!-- <li class="controls-item" *ngIf="asDialog" >
           <button md-raised-button (click)="closeDialog()"><md-icon>check</md-icon></button>
           </li> -->
      </div>
    </md-card-actions>
  </form>
</md-card>
