import { Component, Input, OnInit } from '@angular/core';
import { EcosystemStep } from '../../../models/ecosystem-step.class';
import { DepositOrder } from '../../../models/deposit-order.class';
import { PASSPORT_UNITS_TYPE } from '../../../../../entities/passport/values/passport-units.const';
import { ECOSYSTEM_STEP_TYPE, ECOSYSTEM_STEP_TYPE_MAP } from '../../../values/ecosystem-step-type.const';
import { TRANSFORM_TYPE } from '../../../../transformation-manager/values/transform-types.const';
import {
  ECOSYSTEM_CALC_ERROR,
  EcosystemCalcErrorUtil
} from '../../../values/ecosystem-calc-error.const';
import { Ecosystem } from '../../../models/ecosystem.class';
import { EcosystemService } from '../../../../../entities/ecosystem/services/ecosystem.service';
import {SummaryDetailOrder} from '../../../models/summary-details.intercface';
import { TranslateService } from '@ngx-translate/core';

export interface previousStep {
  name: string;
  id: number;
}

@Component({
  moduleId: module.id,
  selector: 'summary-expander',
  templateUrl: 'ecosystem-indicators-summary-expander.component.html',
  styleUrls: ['../ecosystem-indicators.component.scss'],
})
export class EcosystemIndicatorsSummaryExpanderComponent implements OnInit {
  @Input() step: EcosystemStep;
  @Input() stepNames: [];
  ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP_TYPE;
  TRANSFORM_TYPE = TRANSFORM_TYPE;
  summaryItemExpanded = false;
  depositOrder: DepositOrder;
  previousStep: previousStep;
  ECOSYSTEM_CALC_ERROR = ECOSYSTEM_CALC_ERROR;
  errorUtil: EcosystemCalcErrorUtil = new EcosystemCalcErrorUtil();

  constructor(
    public ecosystemService: EcosystemService,
    public translate: TranslateService) {}

  ngOnInit() {
    this.step = new EcosystemStep(this.step);
  }

  toggle() {
    this.summaryItemExpanded = !this.summaryItemExpanded;
  }
 
  get subtitle(): string {
    if (this.isTransport()) {
      return `${this.step.summary_details.orders.reduce((total, current) => total + +current.confirmed_distance, 0)} Km`;
    } else if (this.step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT || this.step.type === ECOSYSTEM_STEP_TYPE.NEED) {
      return this.step.passport_name;
    } else {
      return this.step.site_name;
    }
  }

  private isTransport() {
    return this.step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM && this.step.transformation_type === TRANSFORM_TYPE.TRANSPORT;
  }

  get quantityLabel(): {expected: string, confirmed: string} {
    if (this.isTransport()) {
      return {expected: 'Input quantity (expected)', confirmed: 'Input quantity (confirmed)'};
    } else if (this.step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
      return {expected: 'Input quantity (expected)', confirmed: 'Input quantity (confirmed)'};
    } else {
      return {expected: 'Quantity (expected)', confirmed: 'Quantity (confirmed)'};
    }
  }

  get typeLabel() {
    if (this.isTransport()) {
      return 'TRANSPORT';
    } else {
      return ECOSYSTEM_STEP_TYPE_MAP[this.step.type];
    }
  }

  get firstOrder(): SummaryDetailOrder {
    const orderList = this.step.summary_details.orders;
    return orderList && orderList.length && orderList[0];
  }
 
  getPreviousStep(id: number){
    this.previousStep = this.stepNames.find(step => step['id'] === id);
    return this.previousStep.name;
  }

  getFirstOrderPriceOrNotFoundIndex(order: SummaryDetailOrder) {
    return this.firstOrder.id === order.id ? this.firstOrder.confirmed_price : -1;
  }

  showErrorMessage(): boolean {
    const step = this.step.summary_details.step;
    const orders = this.step.summary_details.orders;
    return (step.errors && this.visibleErrors(step.errors, step.confirmed_price, step.confirmed_quantity).length > 0) ||
           (orders &&
             orders.some(o => o.errors &&
               this.visibleErrors(o.errors, this.firstOrder.confirmed_price, o.confirmed_quantity, o.confirmed_distance).length > 0));
  }

  visibleErrors(errors: number[], confirmedPrice: number, confirmedQuantity: number, confirmedDistance?: number): number[] {
    return errors.filter(e => this.isVisibleError(e, confirmedPrice, confirmedQuantity, confirmedDistance));
  }

  getOrderTransformInputUnit(unit: number) {
   if (unit) {
     return unit;
   } else {
     return PASSPORT_UNITS_TYPE.KG;
   }
  }

  private isVisibleError(errorCode: number, confirmedPrice: number, confirmedQuantity: number, confirmedDistance?: number): boolean {
    return !(this.errorUtil.isPriceError(errorCode) && confirmedPrice != null)
          && !(this.errorUtil.isQuantityError(errorCode) && confirmedQuantity != null)
          && !(this.errorUtil.isDistanceError(errorCode) && confirmedDistance != null);
  }
  updateQuantity(amount: number, index: number) {
    this.step.summary_details.orders[index].confirmed_quantity = amount;
  }
}

