var CustomLogger = /** @class */ (function () {
    function CustomLogger(_reference, _prefix, customStyles) {
        if (customStyles === void 0) { customStyles = {}; }
        this._reference = _reference;
        this._prefix = _prefix;
        this._styles = Object.assign({
            'background': 'white',
            'color': '#350061',
            'padding': '3px 5px 3px 5px',
            'border-radius': '3px'
        }, customStyles);
    }
    Object.defineProperty(CustomLogger.prototype, "styles", {
        get: function () {
            var _this = this;
            return Object.keys(this._styles).map(function (key) { return key + ":" + _this._styles[key]; }).join(';');
        },
        enumerable: true,
        configurable: true
    });
    CustomLogger.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.log.apply(console, ['%c ' + this._prefix, this.styles].concat(args));
        return this._reference;
    };
    CustomLogger.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.warn.apply(console, ['%c ' + this._prefix, this.styles].concat(args));
        return this._reference;
    };
    return CustomLogger;
}());
export { CustomLogger };
