import { Component, Inject } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { DepositService } from '../../services/deposit.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'deposit-mass-update-warning',
  templateUrl: 'deposit-mass-update-warning.component.html',
  styleUrls: ['deposit-mass-update-warning.component.scss']
})

export class DepositMassUpdateWarningComponent {
  constructor (
    public mdDialogRef: MdDialogRef<void>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: {count: number, options: any}
  ) {}

  confirm() {
    this.mdDialogRef.close(true);
  }

  closeDialog() {
    this.mdDialogRef.close(false);
  }
}
