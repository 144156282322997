import { EventEmitter } from '@angular/core';
import { DeepStreamService } from '../../shared/services/deepstream.service';
import { Observable } from 'rxjs/Observable';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/deepstream.service";
var ImportDeepstreamService = /** @class */ (function () {
    function ImportDeepstreamService(ds) {
        var _a;
        this.ds = ds;
        this.type = 1 /* DEPOSIT */;
        this.imports = (_a = {},
            _a[1 /* DEPOSIT */] = this.ds.depositImportDeepStream$,
            _a[2 /* SITES */] = this.ds.depositLocationDeepStream$,
            _a);
        this.emitTypeChange = new EventEmitter();
    }
    ImportDeepstreamService.prototype.getStream = function () {
        return this.imports[this.type];
    };
    ImportDeepstreamService.prototype.getAllImportStream = function () {
        return this.commons ? this.commons : this.initAllImportStream();
    };
    ImportDeepstreamService.prototype.initAllImportStream = function () {
        var _this = this;
        var combineImportsAndReturnType = function (type) { return Observable.merge(_this.imports[1 /* DEPOSIT */][type].asObservable()
            .map(function (value) { return ({ value: value, type: 1 /* DEPOSIT */, stream: type }); }), _this.imports[2 /* SITES */][type].asObservable()
            .map(function (value) {
            return { value: value, type: 2 /* SITES */, stream: type };
        })); };
        var common = {
            depositImportParsedRowsStream$: combineImportsAndReturnType('depositImportErrorsStream$'),
            depositCompletionStream$: combineImportsAndReturnType('depositCompletionStream$'),
            depositImportErrorsStream$: combineImportsAndReturnType('depositImportParsedRowsStream$'),
            depositImportFatalError$: combineImportsAndReturnType('depositImportFatalError$')
        };
        console.log(common);
        return common;
    };
    ImportDeepstreamService.prototype.setType = function (type) {
        if (this.type !== type) {
            this.type = type;
            this.emitTypeChange.emit(type);
        }
    };
    ImportDeepstreamService.prototype.getType = function () {
        return this.type;
    };
    ImportDeepstreamService.ngInjectableDef = i0.defineInjectable({ factory: function ImportDeepstreamService_Factory() { return new ImportDeepstreamService(i0.inject(i1.DeepStreamService)); }, token: ImportDeepstreamService, providedIn: "root" });
    return ImportDeepstreamService;
}());
export { ImportDeepstreamService };
