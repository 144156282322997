import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {TransformOrder} from '../../../ecosystem-manager/models/transform-order.class';
import {TransformOrderService} from '../../../ecosystem-manager/services/transform-order.service';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE} from '../../../ecosystem-manager/values/ecosystem-file-junction-item-type.const';
import {EcosystemFileJunction} from '../../../ecosystem-manager/models/ecosystem-file-junction.class';
import {EcosystemFileJunctionService} from '../../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TRANSFORM_TYPE, TRANSFORM_TYPES} from '../../values/transform-types.const';
import {CapitalizePipe} from '../../../../shared/pipes/capitalize.pipe';
import {EcosystemFileService} from '../../../../entities/ecosystem/services/ecosystem-file.service';
import {EcosystemService} from '../../../../entities/ecosystem/services/ecosystem.service';
import {EcosystemFile} from '../../../ecosystem-manager/models/ecosystem-file.class';
import {OrderPassport} from '../../../ecosystem-manager/models/order-passport.class';
import {
  EOrderTransactionConfirmResultFieldType
} from '../../../../shared/convert-components/order-transaction-confirm-result-field/order-transaction-confirm-result-field-type.enum';
import {touchEachFormControl} from '../../../../shared/helpers/touch-each-form-control';
import {UploadedDocument} from '../../../../shared/models/uploaders/document.class';
import {EcosystemFileToJunctionService} from '../../../../entities/ecosystem/services/ecosystem-file-to-junction.service';
import {RequestFileSenderService} from '../../../admin/services/request-file-sender.service';
import moment from 'moment';
import {Observable} from 'rxjs/Observable';
import {mergeMap} from 'rxjs/operators';
import {Transform} from '../../../../entities/transformation/models/transform.class';

@Component({
  selector: 'transformation-order-transaction-confirm',
  templateUrl: './transformation-order-transaction-confirm.component.html',
  styleUrls: ['./transformation-order-transaction-confirm.component.scss']
})
export class TransformationOrderTransactionConfirmComponent implements OnInit {
  TRANSFORM_TYPE = TRANSFORM_TYPE;
  RESULT_FIELD_TYPE = EOrderTransactionConfirmResultFieldType;
  @Input() disabled = false;
  @Input() showESMText = false;
  @Input() id: number;
  @Input() step_id: number;
  @Input() orders: TransformOrder[];
  @Input() orderParent: TransformOrder;
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  allFiles = [];
  filesWithJunction = [];
  form: FormGroup;

  constructor(
    public ecosystemService: EcosystemService,
    public ecosystemFileService: EcosystemFileService,
    public requestFileSenderService: RequestFileSenderService,
    private ecosystemJunctionService: EcosystemFileJunctionService,
    private ecosystemFileToJunctionService: EcosystemFileToJunctionService,
    private transformationOrderService: TransformOrderService,
    private notifications: LogNotificationService,
    private translationService: TranslateService,
    private capitalizePipe: CapitalizePipe,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.loadTransformation();
  }

  private loadTransformation() {
    if (this.orders) {
      this.onAfterOrderLoaded();
    } else if (this.id) {
      this.loadTransformationById();
    } else if (this.step_id) {
      this.loadTransformationByStepId();
    } else  {
      this.onLoadErrorMessage();
    }
  }

  private loadTraceabilityFiles() {
    this.ecosystemJunctionService.get({
      item_id: this.orderParent.id, item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_TRANSFORMATION_ORDER_TRACEABILITY
    }).subscribe((junctions: EcosystemFileJunction[]) => {
      this.allFiles = junctions.map(junction => junction.ecosystem_file);
      this.filesWithJunction = [...this.allFiles];
      }
    );
  }

  private loadTransformationById() {
    this.transformationOrderService.view(this.id, {expand: this.getCommonRequestExpandAsString()})
      .subscribe((order) => {
        this.orderParent = order;
        this.orders = [order];
        this.onAfterOrderLoaded();
      });
  }

  private getCommonRequestExpandAsString() {
    return 'in_order_passport,out_order_passport,transformation,ecosystem,ecosystem_id,transaction_quantity' +
      ',transaction_price,transaction_distance,transaction_description,transaction_date,default_conversion,default_unit';
  }

  private onAfterOrderLoaded() {
    if (!this.disabled) {
      this.resetTransactionConfirmationRequiredFields();
    }
    this.initForm();
    this.loadTraceabilityFiles();
  }

  private loadTransformationByStepId() {
    this.transformationOrderService.get({expand: this.getCommonRequestExpandAsString(), step_id: this.step_id})
      .subscribe((orders: TransformOrder[]) => {
        if (orders.length) {
          this.orderParent = orders[0];
          this.orders = orders;
          this.onAfterOrderLoaded();
        } else {
          this.onLoadErrorMessage();
        }
      });
  }

  private onLoadErrorMessage() {
    this.notifications.error('No result found', null, true);
  }

  private initForm() {
    const getConfirmControlConfig = (value) => ([{disabled: this.disabled, value}, Validators.required]);
    const asControl = (name: string, confirmed: any) => ({[name]: getConfirmControlConfig(confirmed)});
    this.form = this.fb.group({
      inputs: this.fb.array(
          this.orders.map(order => this.fb.group({
                entity: [order],
                entityPassport: [order.in_order_passport],
                ...asControl('quantity', this.getTransactionQuantity(order.in_order_passport, order)),
                ...(this.transformType !== TRANSFORM_TYPE.STORAGE ?
                  asControl('date', this.getTransactionDate(order.in_order_passport)) : {})
              }))
        ),
      outputs: this.fb.array([
        this.fb.group({
          entity: [this.orderParent],
          entityPassport: [this.orderParent.out_order_passport],
          ...(this.isTransformTypeDefault() ?
            asControl('quantity'
              , this.getTransactionQuantity(this.orderParent.out_order_passport, this.orderParent, false)) : {}),
          ...asControl('date', this.getTransactionDate(this.orderParent.out_order_passport))
        })
      ]),
      modalities: this.fb.group({
        entity: [this.orderParent],
        entityPassport: [this.orderParent.out_order_passport],
        ...asControl('price', this.getTransactionPrice(this.orderParent.out_order_passport, this.orderParent)),
        ...(this.transformType === TRANSFORM_TYPE.TRANSPORT
          ? asControl('distance', this.getTransactionDistance()) : {}),
      }),
      description: [{disabled: this.disabled, value: this.orderParent.out_order_passport.transaction_description}]
      });
  }

  private resetTransactionConfirmationRequiredFields() {
    const reset = (transformOrder) =>
      [transformOrder.in_order_passport, transformOrder.out_order_passport].forEach(order => {
        order.transaction_distance = null;
        order.transaction_date = null;
        order.transaction_quantity = null;
        order.transaction_price = null;
      });
    this.orders.forEach(reset);
    reset(this.orderParent);
  }

  private getTransactionQuantity(passport: OrderPassport, order: TransformOrder, input = true) {
    if (input) {
      return this.transactionConfirmValue(
        order.getCustomInQuantityView(passport, passport.transaction_quantity), order.orderInQuantityView
      );
    } else {
      return this.transactionConfirmValue(
        Transform.transformQuantityToCustomDefault(passport.transaction_quantity, order.transformation)
        , order.orderOutQuantityView);
    }
  }

  private getTransactionDate(order: OrderPassport) {
    return this.transactionConfirmValue(order.transaction_date, order.linked_datetime);
  }

  private getTransactionPrice(order: OrderPassport, transformOrder: TransformOrder) {
    return this.transactionConfirmValue(order.transaction_price, transformOrder.price);
  }

  private getTransactionDistance() {
    return this.transactionConfirmValue(this.orderParent.out_order_passport.transaction_distance
      , this.orderParent.child_step["estimated_distance"]);
  }

  private transactionConfirmValue(confirm, defaultValue) {
    return this.disabled ? (confirm || (confirm === 0 ? 0 : defaultValue)) : '';
  }

  get transformType() {
    return this.orderParent.transformation.transformation_type;
  }
  getPlaceholderInputTitle() {
    switch (this.transformType) {
      case TRANSFORM_TYPE.TRANSPORT: {
        return this.translationService.instant('Deposits to transport');
      } case TRANSFORM_TYPE.STORAGE: {
        return this.translationService.instant('Deposits to be stored');
      } default: {
        return this.translationService.instant('Input');
      }
    }
  }

  getPlaceholderInputDateExpected() {
    switch (this.transformType) {
      case TRANSFORM_TYPE.TRANSPORT: {
        return this.translationService.instant('Pickup date (expected)');
      }
      default: {
        return this.translationService.instant('Reception date (expected)');
      }
    }
  }
  getPlaceholderInputDateConfirm() {
    switch (this.transformType) {
      case TRANSFORM_TYPE.TRANSPORT: {
        return this.translationService.instant('Pickup date (confirmed)');
      }
      default: {
        return this.translationService.instant('Reception date (confirmed)');
      }
    }
  }

  get outputQuantity() {
    return this.orders.reduce(
      (accumulator, item) => accumulator + item.out_order_passport.quantity, 0);
  }

  getPlaceholderOutputDateExpexted() {
    switch (this.transformType) {
      default: {
        return this.translationService.instant('End of transformation date (expected)');
      }
    }
  }
  getPlaceholderOutputDateConfirmed() {
    switch (this.transformType) {
      default: {
        return this.translationService.instant('End of transformation date (confirmed)');
      }
    }
  }
  getPlaceholderModalitiesDate() {
    switch (this.transformType) {
      case TRANSFORM_TYPE.TRANSPORT: {
        return 'Delivery date';
      }
      case TRANSFORM_TYPE.STORAGE: {
        return 'End date of storage';
      }
      default: {
        return '';
      }
    }
  }

  isTransformTypeDefault() {
    return !(this.transformType === TRANSFORM_TYPE.STORAGE
      || this.transformType === TRANSFORM_TYPE.TRANSPORT);
  }

  getControlEntity(control: FormGroup): TransformOrder {
    return this.getControlValueByName(control, 'entity');
  }

  getControlEntityPassport(control: FormGroup): OrderPassport {
    return this.getControlValueByName(control, 'entityPassport');
  }

  private getControlValueByName(control: FormGroup, name: string) {
    return control.controls[name] && control.controls[name].value;
  }

  get inputs(): FormGroup[] {
    return this.getFormArray('inputs');
  }

  private getFormArray(name: string) {
    return (this.form
      ? (this.form.controls[name] as FormArray).controls
      : this.fb.array([]).controls) as FormGroup[];
  }

  get outputs(): FormGroup[] {
    return this.getFormArray('outputs');
  }

  get title() {
    const typeName = TRANSFORM_TYPES.find(item => item.value ===
      (this.orderParent.transformation && this.transformType));
    if (this.disabled) {
      return this.translationService.instant('Transformation') + ' ' + this.translationService.instant('confirmation');
    } else {
      return `${this.translationService.instant('Confirm')} ${typeName ? this.translationService.instant(typeName.view) : ''}`;
    }
  }

  createFileJunctions(uploadedFiles) {
    const junctionService = this.ecosystemFileToJunctionService;
    const callback = (data) => {
      this.requestFileSenderService.documentUploadObservableNext(data.files, !!data.failed.length);
    };
    junctionService.updateFilesAndJunctions(
      {entity: this.orderParent, type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_TRANSFORMATION_ORDER_TRACEABILITY}
      , uploadedFiles, this.filesWithJunction, callback
    );
  }

  deleteOnlyLinkIfUploaded() {
    return (file: UploadedDocument) => {
      const reset = (junction) => {
        this.allFiles = this.allFiles.filter(item => item.id !== junction.file_id);
        this.filesWithJunction = this.filesWithJunction.filter(item => item.file_id !== file.id);
      };
      return !this.ecosystemFileToJunctionService.deleteLinkInsteadIfExist(file as EcosystemFile, this.filesWithJunction, reset);
    };
  }

  confirmOrder() {
    if (this.form && this.form.valid) {
      const {date, quantity} = this.getValuesFromControl(this.outputs[0]);
      const commons = {
        transaction_price: this.orderParent.out_order_passport.transaction_price,
        transaction_distance: this.orderParent.out_order_passport.transaction_distance,
        transaction_description: this.orderParent.out_order_passport.transaction_description,
      };
      this.orders.forEach(order => {
        order.out_order_passport.transaction_date = date;
        order.out_order_passport.transaction_quantity = this.orderParent.transformFromOutView(quantity);
        order.out_order_passport.transaction_price = commons.transaction_price;
        order.out_order_passport.transaction_distance = commons.transaction_distance;
        order.out_order_passport.transaction_description = commons.transaction_description;
        order.confirmed_distance = commons.transaction_distance;
      });
      this.inputs.forEach((item: FormGroup) => {
        const inputData = this.getValuesFromControl(item);
        const inOrder: TransformOrder = item.controls['entity'].value;
        inOrder.in_order_passport.transaction_quantity = inOrder.transformFromInView(inputData.quantity);
        inOrder.in_order_passport.transaction_date = inputData.date;
      });
      this.onConfirm.emit(Observable.zip(...this.orders.map(item =>
        Observable.combineLatest(item.in_order_passport.save(), item.out_order_passport.save()).pipe(
          mergeMap(() => item.confirm())
      ))
      ));
    } else {
      touchEachFormControl(this.form);
      this.notifications.error(
        'You must fill in all the fields on the right to confirm the data', null, true);
    }
  }

  private getValuesFromControl(control: FormGroup): {date: string, quantity: number} {
    const date = control.controls['date']
      && moment(control.controls['date'].value, [ 'DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
    const quantity = control.controls['quantity'] && control.controls['quantity'].value;
    return {date, quantity};
  }
}
