<article class="need-list">
  <need-filter
    (onFilterUpdate)="updateFilters()"
    [activeIndex]="activeIndex"
    [recipientList]="recipientList"
    [deliveryLocationList]="deliveryLocationList"
    [depositLocationList]="depositLocationList"
  ></need-filter>
  <header class="needslist__header">
    <div class="needslist__header-picture"></div>
    <div class="needslist__header-info">
      <div class="needslist__header-name">{{'Need_offer_designation' | translate}}</div>
      <div class="needslist__header-quantity">{{'Quantity' | translate}}</div>
      <div class="needslist__header-location">{{'Delivery date' | translate}}</div>
      <div class="needslist__header-condition">{{'Delivery_place' | translate}}</div>
    </div>
    <div class="needslist__header-condition">{{'Need_offer_status' | translate}}</div>
  </header>
  <div>
    <div *ngFor="let need of needs | paginate:{id:'needs'
    , itemsPerPage: pageInfo.perPage
    , currentPage: pageInfo.currentPage
    , totalItems: pageInfo.totalCount }">
      <need-item 
        [need]="need" 
        [offers]="need.offer"
        (onDuplicateRecord)="duplicateRecord($event)"
        (onViewOffer)="viewOffer($event.offer, $event.need)"
        (onreportRecord)="reportRecord($event)"
        (onconfirmRecord)="confirmRecord($event)"
        (onArchive)="archiveNeed($event)"
        (onDelete)="deleteRecord($event)">
        >
      </need-item>
    </div>
  </div>
  <div class="needslist__container-pagination" test-id="needslist-pagination">
    <pagination-controls 
      class="needslist__pagination" 
      id="needs" 
      (pageChange)="pageChange($event)" 
      maxSize="10"
      directionLinks="true" 
      autoHide="true" 
      previousLabel="" 
      nextLabel=""
      screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
      screenReaderPageLabel="{{ 'page' | translate }}"
      screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}">
    </pagination-controls>
  </div>
</article>