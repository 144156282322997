import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'agent',
  templateUrl: 'agent.component.html'
})

export class AgentComponent {

}
