import {ActiveRecord} from './active-record.class';
import {DataProvider} from './data-provider.class';
import {Response} from '@angular/http';

export abstract class DataProviderWithPagination<T extends ActiveRecord> extends DataProvider<T> {
  public totalCount = 0;
  public perPage = 0;
  public pageCount = 1;
  public currentPage = 1;

  updateRecords(response: Response
    , pagination: { totalCount: number, perPage: number, pageCount: number, currentPage: number }) {
    super.updateRecords(response);
    this.currentPage = pagination.currentPage;
    this.perPage = pagination.perPage;
    this.totalCount = pagination.totalCount;
    this.pageCount = pagination.pageCount;
  }
}
