import * as tslib_1 from "tslib";
/**
 * Created by aleksandr on 13.06.17.
 */
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ECustomListHintClass, ECustomListRecordStatus, ICustomListRecord } from '../../../../../../shared/custom-list/interfaces/custom-list.interface';
import { NeedService } from '../../../../../../entities/need/services/need.service';
import { DatePipe } from '@angular/common';
import { NeedClientListService } from '../../../../../../entities/need/services/need-client-list.service';
import { NeedSalesListService } from '../../../../../../entities/need/services/need-sales-list.service';
import { MdDialog } from '@angular/material';
import { Router } from '@angular/router';
import { EsmNeedDialogComponent } from '../../../../dialogs/esm-need-dialog/esm-need-dialog.component';
import { NEED_STATUSES } from '../../../../../../entities/need/values/need-statuses.const';
import { Need } from '../../../../../../entities/need/models/need.class';
import { CustomListRecord } from '../../../../../../shared/custom-list/custom-list-record.model';
import { Ecosystem } from '../../../../models/ecosystem.class';
import { NeedDetailsDialogComponent } from '../../../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import { DIALOG_THEME } from '../../../../../../shared/helpers/dialog-themes.const';
import { CurrentUser } from '../../../../../../shared/auth/current-user.class';
import { NavbarStateService } from '../../../../../../shared/services/navbar.service';
import { QUANTITY_PRECISION } from '../../../../../../entities/need/values/quantity-precision.const';
import { EDatePrecision } from '../../../../../../entities/need/values/date-precision.const';
import { TranslateService } from '@ngx-translate/core';
import { DepositDetailsDialogComponent } from '../../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
var EsmNeedCatalogComponent = /** @class */ (function () {
    function EsmNeedCatalogComponent(_needService, datePipe, router, needClientListService, needSalesListService, dialog, changeDetectorRef, navBarState, _translate, translateService) {
        var _this = this;
        this._needService = _needService;
        this.datePipe = datePipe;
        this.router = router;
        this.needClientListService = needClientListService;
        this.needSalesListService = needSalesListService;
        this.dialog = dialog;
        this.changeDetectorRef = changeDetectorRef;
        this.navBarState = navBarState;
        this._translate = _translate;
        this.translateService = translateService;
        this.sortOptions = [
            { field: 'delivery_date', view: 'Delivery date' },
            { field: 'client_name', view: 'Client' },
            { field: 'name', view: 'Name' },
            { field: 'sales_name', view: 'Need manager' }
        ];
        this.sortValue = 'delivery_date';
        this.search = new FormControl();
        this.clients = [];
        this.filteredClients = [];
        this.records = [];
        this.markers = [];
        this.filteredSales = [];
        this
            .search
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function () { return _this.getRecords(); });
        this.getSalesList();
        this.getNeedClientList();
        this._translate.get('Added').subscribe(function (translation) { return _this.markerAddedLabelText = translation; });
        this._translate.get('By').subscribe(function (translation) { return _this.markerByLabelText = translation; });
    }
    EsmNeedCatalogComponent.prototype.toMarker = function (need) {
        return {
            lat: need.client_location.lat,
            lng: need.client_location.lng,
            name: need.name,
            lines: [
                (need.quantity ? need.quantity : 0) + " " + need.passport.unit_text,
                "<br>",
                "" + (need.description ? need.description : ''),
                "<br>",
                this.markerAddedLabelText + ": " + this.datePipe.transform(need.created_at, 'dd.MM.y'),
                this.markerByLabelText + ": " + need.sales_contact,
            ]
        };
    };
    EsmNeedCatalogComponent.prototype.getSalesList = function () {
        var _this = this;
        this.needSalesListService.get({ independent: this.includeIndependent }).subscribe(function (res) {
            _this.salesContacts = res;
        });
    };
    EsmNeedCatalogComponent.prototype.getNeedClientList = function () {
        var _this = this;
        this.needClientListService.get({ independent: this.includeIndependent }).subscribe(function (res) {
            _this.clients = res;
        });
    };
    EsmNeedCatalogComponent.prototype.openMapDialog = function ($event) {
        var dialogRef = this.dialog.open(EsmNeedDialogComponent, {
            width: '65%',
            height: '500px',
            position: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('afterClosed', result);
        });
    };
    EsmNeedCatalogComponent.prototype.getRecords = function () {
        var _this = this;
        this.records = [];
        var params = {
            expand: 'client,passport,client_contact,client_location,sales_user,ecosystems,sales_contact,sales_email,sales_name,treatable',
            admin: 1,
            supervisor: 1,
            exclude_refused: 1,
            delivery_from: this.expiresFrom || null,
            delivery_to: this.expiresTo || null,
            search: this.search.value || null,
            client: this.clientId || null,
            independent: this.includeIndependent || null,
            sales_contact: this.salesId || null,
            'status[]': [NEED_STATUSES.PUBLISHED.value] || null,
            sort: this.sortValue,
        };
        if (this.navBarState.multilanguage) {
            params.english = true;
        }
        this._needService
            .get(params)
            .subscribe(function (data) {
            data.forEach(function (item) { return _this.addRecord(new Need(item)); });
            _this.markers = data.map(function (need) { return _this.toMarker(need); });
            _this.filteredClients = _this.clients
                .filter(function (item) { return data.find(function (need) { return need.client && need.client.id === item.id; }); });
            _this.changeDetectorRef.detectChanges();
        });
        this.getSalesList();
        this.getNeedClientList();
    };
    EsmNeedCatalogComponent.prototype.addRecord = function (data) {
        var _this = this;
        // Treated need : nothing;
        // not treated : blue hand
        // tslint:disable-next-line:prefer-const
        var status;
        var hintCssClass = ECustomListHintClass.NO_CLASS;
        var hintText = '';
        var isCloseToExpired = false;
        var needIsTreated = data.status === NEED_STATUSES.TREATED.value;
        var quantityPrecision = QUANTITY_PRECISION.find(function (item) { return data.quantity_precision === item.key; });
        if (data.delivery_date) {
            var deliveryTimeLeft = (new Date(data.delivery_date).getTime() - Date.now());
            if (!needIsTreated &&
                deliveryTimeLeft / 1000 / 60 / 60 / 24 < 7 &&
                deliveryTimeLeft > 0) {
                hintCssClass = ECustomListHintClass.WARNING;
                hintText = 'There is less than a week left, take action!';
                isCloseToExpired = true;
            }
        }
        var translate = function (text) { return _this.translateService.instant(text); };
        var isUnlimitedQuantity = quantityPrecision && quantityPrecision.value === 'unknown';
        var isDateUndefined = data.date_precision === EDatePrecision.UNKNOWN || data.date_precision === EDatePrecision.RECURRENT;
        var unit = data.passport.unit_text;
        var quantity = isUnlimitedQuantity
            ? translate('unlimited')
            : translate(data.quantity_text) + " " + (unit ? translate(unit) : '');
        var quantityPrecisionText = quantityPrecision && !isUnlimitedQuantity ? translate(quantityPrecision.value) : '';
        var getDateUndefinedText = function (record) { return record.date_precision === EDatePrecision.UNKNOWN ? translate('To be defined')
            : translate(record.datePrecisionViewValue); };
        var dateOfDelivery = isDateUndefined ? getDateUndefinedText(data)
            : (data.delivery_date ? this.datePipe.transform(data.delivery_date, 'dd.MM.y') : '');
        var dateOfDeliveryPrecision = (!!data.datePrecisionViewValue && !isDateUndefined)
            ? translate(data.datePrecisionViewValue) : '';
        this.records.push(this.createNeedRecord(data, status, hintCssClass, hintText, data.name, data.passport.name || '', data.passport.english_name, quantity, quantityPrecisionText, data.client_location.name, data.client_location.address, dateOfDelivery, dateOfDeliveryPrecision, isCloseToExpired, data.sales_name));
    };
    EsmNeedCatalogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.miltilanguageSubscription = this.navBarState.multilanguageObservable$.subscribe(function () { return _this.changeDetectorRef.detectChanges(); });
        this.getRecords();
        this.showIndependentFilter = CurrentUser.infoData.has_need_acces;
    };
    EsmNeedCatalogComponent.prototype.ngOnDestroy = function () {
        this.miltilanguageSubscription.unsubscribe();
    };
    /*
    Name of Need,
    Name of PP,
    Status of the need,
    client name,
    description,
    expected delivery date,
    surname and name of sale,
    quantity (with unit)
   */
    EsmNeedCatalogComponent.prototype.createNeedRecord = function (need, status, hintCssClass, hintText, title, subtitle, english_name, amount, quantityPrecision, locationName, locationAddress, expiredAt, expirePrecision, isCloseToExpired, author) {
        var _this = this;
        var controls = [{
                fn: function (data) {
                    _this.dialog.open(NeedDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                            readOnly: true,
                            id: need.id,
                        } }));
                },
                name: 'See details'
            }].concat((need.from_deposit && need.from_deposit > 0 ? [{
                name: 'See targeted deposit',
                fn: function () {
                    _this.dialog.open(DepositDetailsDialogComponent, {
                        data: {
                            id: need.from_deposit,
                            readOnly: true
                        }
                    });
                }
            }] : []), [{
                fn: function (data) { return location.href = 'mailto:' + need.sales_email; },
                name: 'Send email to Need manager'
            }]);
        controls.push({
            fn: function (data) { return _this.createEcosystemFromNeed(need); },
            name: 'Create ecosystem'
        });
        return CustomListRecord
            .build()
            .setPrimaryKey(need.id)
            .setIndependent(!!need.independent)
            .setStatus(status)
            .setHint(hintText, hintCssClass)
            .addTitleColumn(title, subtitle, null, english_name)
            .addListColumn((!!amount ? [{ value: amount, key: '', seperator: '', cssClass: 'title' }] : []).concat((!!quantityPrecision ? [{ value: "" + quantityPrecision, key: '', seperator: '' }] : [])))
            .addListColumn((!!expiredAt ? [{ value: "" + expiredAt, key: '', seperator: '', cssClass: 'title' }] : []).concat((!!expirePrecision ? [{ value: "" + expirePrecision, key: '', seperator: '' }] : [])))
            .addListColumn([
            { value: "" + locationName, key: '', seperator: '', cssClass: 'title' }
        ].concat((!!locationAddress ? [{ value: "" + locationAddress, key: '', seperator: '' }] : [])))
            .addAuthorColumn(need.independent ? need.sales_user.company_name : author)
            .addControlsColumn({ options: controls, icon: 'menu' });
    };
    EsmNeedCatalogComponent.prototype.createEcosystemFromNeed = function (need) {
        var _this = this;
        new Ecosystem({
            name: this.translateService.instant('For') + " " + need.name,
            need_id: need.id,
        }).save().subscribe(function (ecosystem) {
            _this.router.navigate(['ecosystem/ecosystems', ecosystem.id]);
        });
    };
    EsmNeedCatalogComponent.prototype.toggleIndependent = function (checked) {
        this.includeIndependent = +checked;
        this.getRecords();
    };
    return EsmNeedCatalogComponent;
}());
export { EsmNeedCatalogComponent };
/*
 this.records.push(
 this.createNeedRecord(
 new Need(),
 ECustomListRecordStatus.IMPORTANT, //is for new need
 ECustomListHintClass.NO_CLASS,
 '',
 'Plastic Granules',
 'Samsung inc',
 12,
 ECustomListUnit.TON,
 'Need for plastic granules. Cant be less than defined amount. Second line of description text',
 '20.04.2017',
 '27.04.2017',
 false,
 'Roland Lestman'
 ),
 this.createNeedRecord(
 new Need(),
 ECustomListRecordStatus.IMPORTANT, //is for new need
 ECustomListHintClass.WARNING,
 'There is less than a week left! Take action!',
 'Plastic Granules',
 'Samsung inc',
 12,
 ECustomListUnit.TON,
 'Need for plastic granules. Cant be less than defined amount. Second line of description text',
 '20.04.2017',
 '27.04.2017',
 true,
 'Roland Lestman'
 ),
 this.createNeedRecord(
 new Need(),
 ECustomListRecordStatus.MANUAL, //is for new need
 ECustomListHintClass.NO_CLASS,
 '',
 'Plastic Granules',
 'Samsung inc',
 12,
 ECustomListUnit.TON,
 'Need for plastic granules. Cant be less than defined amount. Second line of description text',
 '20.04.2017',
 '27.04.2017',
 true,
 'Roland Lestman'
 ),
 );
 */
