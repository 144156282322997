import * as tslib_1 from "tslib";
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { DepositLocationImportService } from '../services/deposit-location-import.service';
var DepositLocationImport = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationImport, _super);
    function DepositLocationImport(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = DepositLocationImportService;
        return _this;
    }
    DepositLocationImport.prototype.fields = function () {
        return [
            'id',
            'tab_name',
            'first_row',
            'last_row',
            'site_name_field',
            'description_field',
            'address_field',
            'errors',
            'files',
            'status'
        ];
    };
    DepositLocationImport.prototype.startImport = function () {
        return this.simplePost('/' + this.id + '/start');
    };
    return DepositLocationImport;
}(ExtendedActiveRecord));
export { DepositLocationImport };
