/**
 * Created by aleksandr on 13.06.17.
 */
import { Component, EventEmitter, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  ECustomListKeyPairClass,
  ICustomListKeyPair,
  ICustomListRecord
} from '../../../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../../../shared/custom-list/custom-list-record.model';
import { TransformService } from '../../../../../../entities/transformation/services/transform.service';
import { Transform } from '../../../../../../entities/transformation/models/transform.class';
import { FormControl } from '@angular/forms';
import { MdDialog } from '@angular/material';
import { getTransformTypeLcView, TRANSFORM_TYPES } from '../../../../../transformation-manager/values/transform-types.const';
import { EsmTransformationDialogComponent } from '../../../../dialogs/esm-transformation-dialog/esm-transformation-dialog.component';
import { DatePipe } from '@angular/common';
import { Searchable } from '../../../../../../shared/models/searchable.class';
import { TsmListService } from '../../../../../../entities/transformation/services/tsm-list.service';
import { TransformDetailsDialogComponent
  } from '../../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_THEME } from '../../../../../../shared/helpers/dialog-themes.const';
import {isIndependent} from '../../../../../transformation-manager/values/transform-dependency.const';
import { CurrentUser } from '../../../../../../shared/auth/current-user.class';
import { Subscription } from 'rxjs/Rx';
import { NavbarStateService } from '../../../../../../shared/services/navbar.service';
import { IMapMarker } from '../../../../../../shared/map-view/map-marker.interface';
import { TransformLocation } from '../../../../../../entities/transformation/models/transform-location.class';
import {Observable} from 'rxjs/Observable';

@Component({
  moduleId: module.id,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'esm-transformation-catalog',
  templateUrl: 'esm-transformation-catalog.component.html'
})
export class EsmTransformationCatalogComponent implements OnInit, OnDestroy {

  onChange$: EventEmitter<void> = new EventEmitter<void>();

  TRANSFORM_TYPES = TRANSFORM_TYPES;
  tsms: Searchable[];
  tsmId: number;
  search = new FormControl();
  type: number;

  isListExtended = false;
  isNextPageLoading = false;
  currentPage = 1;
  totalPagesCount = 0;
  showIndependentTranformFilter: boolean ;

  records: ICustomListRecord[] = [];
  showIndepentTransformation: number ;

  sortOptions: any = [
    { field: 'name', view: 'Name' },
    { field: 'tsm_name', view: 'Transformation Manager' }
  ];
  sortValue = 'name';
  miltilanguageSubscription: Subscription;

  markers: IMapMarker[] = [];
  isSpecialAdmin: boolean;
  private recordsLoadingRequest;
  private pageRequestObserver;
  searchStatus = [];

  constructor(private _transformService: TransformService,
    private dialog: MdDialog,
    private datePipe: DatePipe,
    private tsmListService: TsmListService,
    private mdDialog: MdDialog,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private navBarState: NavbarStateService) {
    this
      .tsmListService
      .get({include_independents: 1})
      .subscribe((tsms: Searchable[]) => this.tsms = tsms);
  }

  ngOnInit(): void {
    this.miltilanguageSubscription = this.navBarState.multilanguageObservable$.subscribe(() => this.changeDetectorRef.detectChanges());
    this.showIndependentTranformFilter = CurrentUser.infoData.has_transform_acces;
    this
      .search
      .valueChanges
      .debounceTime(300)
      .distinctUntilChanged()
      .subscribe(() => this.getRecords())
      ;
    this.getRecords();
    this.getMarkers();
  }

  ngOnDestroy() {
    this.miltilanguageSubscription.unsubscribe();
  }

  openMapDialog($event: any) {
   // console.log('openMapDialog', $event);
    const dialogRef = this.dialog.open(EsmTransformationDialogComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('afterClosed', result);
    });
  }

  onEndOfListTriggered() {
    if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount)  {
      this.getRecords(this.currentPage + 1);
    }
  }

  resetRecords() {
    this.getRecords();
    this.onChange$.emit();
  }

  getRecords(forPage: number = 0) {
    this.isListExtended ? this.getRecordsWithoutPagination().subscribe() : this.getRecordsWithPagination(forPage);
  }

  private getRecordsWithPagination(forPage: number) {
    const options: any = {
      ...this.queryParams,
      page: forPage,
      paginate: 1,
    };

    this.isNextPageLoading = true;
    this.pageRequestObserver = this._transformService
      .getWithPaginationData(options)
      .subscribe((res) => {
        if (forPage === 0) {
          this.records = [];
        }
        const headers = res.headers;
        this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
        this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
        res.json().map((item: any) => this.addRecord(new Transform(item)));
        if (!this.changeDetectorRef['destroyed']) {
          this.changeDetectorRef.detectChanges();
        }
        this.isNextPageLoading = false;
      });

      this.recordsLoadingRequest = this._transformService
      .get(this.queryParams)
      .subscribe((data: Transform[]) => {
          this.markers = [];
          data.forEach((item: Transform) => this.addMarkers(item));
          this.recordsLoadingRequest = null;
          this.changeDetectorRef.detectChanges();
      });


    // .subscribe((data: Transform[]) => data.forEach((item) => this.addRecord(item)))
    // ;
  }

  get queryParams() {
    const options: any = {
      expand: 'files,materials,tsm_name,tsm_org,tsm_email,tags,sites,sectors',
      admin: 1,
      esm_view: 1,
      supervisor: 1,
      search: this.search.value ? this.search.value.trim() : null,
      transformation_type: this.type || null,
      tsm: this.tsmId || null,
      sort: this.sortValue,
      independent: this.showIndepentTransformation,
    };

  if (this.navBarState.multilanguage) {
    options.english = true;
  }
    return options;
  }

  updateRecordsOnListExpand() {
    if (this.currentPage === this.totalPagesCount) {
      return Observable.of(null);
    } else {
      return this.getRecordsWithoutPagination();
    }
  }

  private getRecordsWithoutPagination() {
    return this._transformService.get(this.queryParams)
      .map((data: Transform[]) => {
        this.syncWithGetWithPagination();
        this.records = [];
        data.forEach(item => this.addRecord(item));
        if (!this.changeDetectorRef['destroyed']) {
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  private syncWithGetWithPagination() {
    this.currentPage = this.totalPagesCount;
    this.isNextPageLoading = false;
    if (this.pageRequestObserver && !this.pageRequestObserver.closed) {
      this.pageRequestObserver.unsubscribe();
    }
  }

  getMarkers() {
    this.recordsLoadingRequest = this._transformService
    .get(this.queryParams)
    .subscribe((data: Transform[]) => {
        this.markers = [];
        data.forEach((item: Transform) => this.addMarkers(item));
        this.recordsLoadingRequest = null;
        this.changeDetectorRef.detectChanges();
    });
  }

  addMarkers(data: Transform): void {
    this.markers.push(...data.sites.map((item: TransformLocation) => this.toMarker(data.name, item, data.independent)));
  }


  toMarker(transformName: string, data: TransformLocation, independent: number): IMapMarker {
    return {
      lat: data.lat,
      lng: data.lng,
      name: transformName,
      lines: [
        `${data.name}`,
        `${data.address}`
      ],
      icon: this.isSpecialAdmin ?
        (isIndependent(independent) ? 'assets/png/map/localisation-blue.png' : 'assets/png/map/localisation-orange.png')
        : null
    };
  }

  addRecord(data: Transform) {
    let materialsList: ICustomListKeyPair[] = [];
    // let keywordsOrControlType: ICustomListKeyPair[] = [];
    const reversedColumnEntries: Partial<ICustomListKeyPair> = { cssClass: ECustomListKeyPairClass.REVERSED_LIST_COLUMN, seperator: ' ' };
    if (data && data.transformation_type_data) {
      if (data.transformation_type_data.includes.incomeOutcome) {
        const transformMaterial = (material: string) => {
          material = this.translateService.instant(material);
          material = `<b>${material}</b>`;
          return material.replace(/[(]([^)]+)[)]/g, '</b>($1)<b>');
        };
        const incomeMaterials = data.inputMaterials
          .map((material) => transformMaterial(material.name)).join(',');
        const outcomeMaterials = data.outputMaterials
          .map((material) => transformMaterial(material.name)).join(',');

        // const incomeTags = data.inputTags.map((tag) => tag.name).join(',');
        // const outcomeTags = data.outputTags.map((tag) => tag.name).join(',');

        materialsList = [
          { key: this.translateService.instant('Input') + ':'
            , seperator: ' '
            , value: incomeMaterials, cssClass: 'material' },
          { key: this.translateService.instant('Output') + ':'
            , seperator: ' '
            , value: outcomeMaterials, cssClass: 'material' }
        ];

        // keywordsOrControlType = [
        //   { key: 'Keywords', value: incomeTags },
        //   { key: 'Keywords', value: outcomeTags }
        // ];
      } else if (data.transformation_type_data.includes.acceptedMaterials) {
        materialsList = [
          { key: '', seperator: ' ', value: data.acceptedMaterialsView, ...reversedColumnEntries, cssClass: 'material-accepted' },
        ];

        // if (data.transformation_type_data.includes.controlType) {
        //   keywordsOrControlType = [
        //     { key: 'Control type', value: data.controlTypeView, ...reversedColumnEntries },
        //   ];
        // }
      }
    }

    const listOfLocations: ICustomListKeyPair[] = [];
    if (data.transformation_type_data.includes.serviceArea) {
      // listOfLocations = data.sectors.map(sector => ({ key: '', seperator: '', value: sector.name }));
      if (data.sectors.length) {
        data.sectors.forEach(sector => listOfLocations.push({ key: '', seperator: '', value: sector.name }));
      }
    } else if (data.transformation_type_data.includes.storingSites) {
      // listOfLocations = data.sites.map((site) => ({ key: '', seperator: '', value: site.name }));
      if (data.sites.length) {
        data.sites.forEach( site => {
          listOfLocations.push({key: '', seperator: '', value: site.name, cssClass: 'title'});
          if (data.sites[0].address) {
            listOfLocations.push({key: '', seperator: '', value: site.address});
          }
        });
      }
    }

    this.records.push(
      this.createTransformationRecord(
        data.transformation_type_data.value ? getTransformTypeLcView(data.transformation_type_data.value) : '',
        data.viewName,
        data.transformation_type_data.view.replace(/./, (c: string) => c.toUpperCase()),
        materialsList,
        listOfLocations,
        data.tsm_name,
        data
      )
    );
  }

  createTransformationRecord(icon: string,
    title: string,
    subtitle: string,
    materialsList: ICustomListKeyPair[],
    listOfSites: ICustomListKeyPair[],
    author: string,
    transform: Transform, ): CustomListRecord {
    return CustomListRecord
      .build()
      .setPrimaryKey(transform.id)
      .setIndependent(isIndependent(transform.independent))
      .addIconColumn(icon)
      .addTitleColumn(title, subtitle, transform.english_name)
      .addListColumn(materialsList)
      .addListColumn(listOfSites)
      .addAuthorColumn(isIndependent(transform.independent) ? transform.tsm_org : author)
      .addControlsColumn({ icon: 'menu', options: [{
        fn: (data: ICustomListRecord) => {
          this.mdDialog.open(TransformDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR,
            data: {
              id: transform.id,
              readOnly: true,
            }
          });
        },
        name: 'See details'
      }, {
        fn: (data: ICustomListRecord) => {
          location.href = 'mailto:' + transform.tsm_email;
        },
        name: 'Send Email'
      }]});
  }

  toggleIndependent(checked: boolean) {
    this.showIndepentTransformation = +checked;
    this.getRecords();
  }
}
