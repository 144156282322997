<div class="passport-synchronization-information" *ngIf="isSynchronizationInformationShown()">
    <div class="passport-synchronization-toggle"
        *ngIf="isEditable"
         mdTooltip="{{ getTooltipMessage() | translate }}">
        <mat-slide-toggle #synchronizationToggle
                          (change)="onToggleChanged($event)"
                          class="passport-edit__toggle"
                          [checked]="synchronized"
                          [disabled]="isDisabled()"
        ></mat-slide-toggle>
        <span [ngClass]="{'passport-edit__toggle-text': true, 'primary-color': synchronized}">
            {{'databaseSynchronization' | translate}}
        </span>
    </div>

    <div class="passport-synchronization-synchronized primary-color" *ngIf="!isEditable">
        <span class="passport-synchronization-synchronized-text">Synchronized with </span><img [src]="logoPath" alt="Source logo" class="passport-synchronization-synchronized-logo">
    </div>
</div>