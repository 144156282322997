/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../partials/sales-offer/sales-offer.component.ngfactory";
import * as i2 from "../../partials/sales-offer/sales-offer.component";
import * as i3 from "../../../../entities/ecosystem-offer/ecosystem-offer.service";
import * as i4 from "@angular/material";
import * as i5 from "@angular/common";
import * as i6 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i7 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i8 from "./sales-offer-dialog.component";
import * as i9 from "../../../../entities/need/services/need.service";
var styles_SalesOfferDialogComponent = [];
var RenderType_SalesOfferDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SalesOfferDialogComponent, data: {} });
export { RenderType_SalesOfferDialogComponent as RenderType_SalesOfferDialogComponent };
function View_SalesOfferDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sales-offer", [], null, [[null, "refused"], [null, "validated"], [null, "unvalidated"], [null, "confirmed"], [null, "askedForModification"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("refused" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } if (("validated" === en)) {
        var pd_1 = (_co.mdDialogRef.close() !== false);
        ad = (pd_1 && ad);
    } if (("unvalidated" === en)) {
        var pd_2 = (_co.mdDialogRef.close() !== false);
        ad = (pd_2 && ad);
    } if (("confirmed" === en)) {
        var pd_3 = (_co.mdDialogRef.close() !== false);
        ad = (pd_3 && ad);
    } if (("askedForModification" === en)) {
        var pd_4 = (_co.mdDialogRef.close() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_SalesOfferComponent_0, i1.RenderType_SalesOfferComponent)), i0.ɵdid(1, 114688, null, 0, i2.SalesOfferComponent, [i3.EcosystemOfferService, i4.MdDialog, i5.DatePipe], { offer: [0, "offer"] }, { refused: "refused", validated: "validated", unvalidated: "unvalidated", askedForModification: "askedForModification", confirmed: "confirmed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.offer; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SalesOfferDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "md-dialog-content", [["class", "order-details__dialog"]], [[2, "mat-dialog-content", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i4.MdPrefixRejector, [[2, i4.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i4.MdDialogContent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DialogCloseButtonComponent_0, i6.RenderType_DialogCloseButtonComponent)), i0.ɵdid(4, 49152, null, 0, i7.DialogCloseButtonComponent, [], { color: [0, "color"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SalesOfferDialogComponent_1)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "white"; _ck(_v, 4, 0, currVal_2); var currVal_3 = ((_co.offer == null) ? null : _co.offer.loaded); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 4).applyClassPosition; _ck(_v, 3, 0, currVal_1); }); }
export function View_SalesOfferDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_SalesOfferDialogComponent_0, RenderType_SalesOfferDialogComponent)), i0.ɵdid(1, 49152, null, 0, i8.SalesOfferDialogComponent, [i4.MdDialogRef, i4.MD_DIALOG_DATA, i3.EcosystemOfferService, i9.NeedService], null, null)], null, null); }
var SalesOfferDialogComponentNgFactory = i0.ɵccf("ng-component", i8.SalesOfferDialogComponent, View_SalesOfferDialogComponent_Host_0, {}, {}, []);
export { SalesOfferDialogComponentNgFactory as SalesOfferDialogComponentNgFactory };
