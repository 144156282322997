import { Injectable } from '@angular/core';
import { ClientLocation } from '../model/location.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';

@Injectable()
export class LocationService extends ExtendedCrudService {
  protected namespace = 'client-locations';
  protected namespaceSingular = 'client-location';

  protected ModelClass = ClientLocation;
}
