/**
 * Created by natalja on 29/06/2017.
 */

import { Component, OnInit } from '@angular/core';
import {
  ECustomListHintClass,
  ECustomListRecordStatus,
  ECustomListUnit,
  ICustomListRecord,

} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { NeedSalesListService } from '../../../../entities/need/services/need-sales-list.service';
import { NeedService } from '../../../../entities/need/services/need.service';
import { Searchable } from '../../../../shared/models/searchable.class';
import { Need } from '../../../../entities/need/models/need.class';
import { NEED_STATUSES } from '../../../../entities/need/values/need-statuses.const';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { NeedStatus } from '../../../../entities/need/models/need-status.class';
import { NeedDetailsDialogComponent } from '../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import { MdDialog } from '@angular/material';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import {getSearchStatus} from '../../../../shared/helpers/search-status-update';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import {DepositDetailsDialogComponent} from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'supervisor-needs-catalog',
  templateUrl: 'supervisor-needs-catalog.component.html'
})
export class SupervisorNeedsCatalogComponent implements OnInit {

  records: ICustomListRecord[] = [];

  // Filters
  filterStatuses: NeedStatus[] = [
    NEED_STATUSES.WAITING_FOR_PUBLICATION,
    NEED_STATUSES.PUBLISHED,
    NEED_STATUSES.ASK_FOR_MODIFICATION
  ];

  sortOptions: any = [
    { field: 'client_name', view: 'Client'},
    { field: '-created_at', view: 'Creation date'},
    { field: 'name', view: 'Name'},
    { field: 'sales_name', view: 'Need manager'},
  ];
  sortValue = '-created_at';


  searchStatus: number[] = this.filterStatuses.map((status) => status.value);
  sale: number;
  search = new FormControl();
  salesList: Searchable[];
  saleId: number;

  constructor(private _needService: NeedService,
              private _needSalesListService: NeedSalesListService,
              private _globalEvents: GlobalEventsManager,
              private _datePipe: DatePipe,
              private dialog: MdDialog,
              private navBarState: NavbarStateService) {
    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => this.getRecords())
    ;
    this._needSalesListService.get().subscribe((res: Searchable[]) => this.salesList = res);
  }

  ngOnInit(): void {
    this.getRecords();
  }

  getRecords() {
    this.records.length = 0;

    const params: any = {
      expand: 'client,passport,client_contact,sales_contact,sales_name',
     supervisor: true,
      supervisor_view: 1,
      search: this.search.value || null,
      sales_contact: this.saleId || null,
      'status[]': [],
      sort: this.sortValue,
      nopaginate: 1,
    };
    for (const stat of this.searchStatus) {
      params['status[]'].push(stat);
    }
    if (this.navBarState.multilanguage) {
      params.english = true;
    }
    this._needService
      .get(params)
      .subscribe((data: Need[]) => {
        data.forEach((item) => this.addRecord(item));
      });
  }

  addRecord(data: Need) {

    // -blue hand for waiting for publication
    // -green check for published
    // -purple ! for ask for modification
    let status: ECustomListRecordStatus;
    switch (data.status) {
      case NEED_STATUSES.WAITING_FOR_PUBLICATION.value:
        status = ECustomListRecordStatus.MANUAL;
        break;
      case NEED_STATUSES.PUBLISHED.value:
        status = ECustomListRecordStatus.CONFIRM;
        break;
      case NEED_STATUSES.ASK_FOR_MODIFICATION.value:
        status = ECustomListRecordStatus.IMPORTANT;
        break;
      default:
        status = ECustomListRecordStatus.NO_STATUS;
    }

    let hintCssClass = ECustomListHintClass.NO_CLASS;
    let hintText = '';
    let isCloseToExpired = false;
    const needIsTreated = data.status === NEED_STATUSES.TREATED.value;

    if (data.delivery_date) {
      const deliveryTimeLeft = (new Date(data.delivery_date).getTime() - Date.now());

      if (
        !needIsTreated &&
        deliveryTimeLeft / 1000 / 60 / 60 / 24 < 7 &&
        deliveryTimeLeft > 0
      ) {
        hintCssClass = ECustomListHintClass.WARNING;
        hintText = 'There is less than a week left, take action!';
        isCloseToExpired = true;
      }
    }

    this.records.push(
      this.createNeedRecord(
        status,
        hintCssClass,
        hintText,
        data.name,
        data.passport.name || '',
        data.quantity_text,
        data.passport.customListUnit ,
        this._datePipe.transform(data.created_at, 'dd.MM.y'),
        data.delivery_date ? this._datePipe.transform(data.delivery_date, 'dd.MM.y') : 'N/A',
        isCloseToExpired,
        data.sales_name,
        data,
      )
    );
  }

  createNeedRecord(status: ECustomListRecordStatus,
                   hintCssClass: ECustomListHintClass,
                   hintText: string,
                   title: string,
                   subtitle: string,
                   amount: string,
                   unit: ECustomListUnit,
                   createdAt: string,
                   expiredAt: string,
                   isCloseToExpired: boolean,
                   author: string,
                   need: Need): CustomListRecord {
    const showValidationButtons = need.status === NEED_STATUSES.WAITING_FOR_PUBLICATION.value;

    return CustomListRecord
      .build()
      .setPrimaryKey(need.id)
      .setIndependent(!!need.independent)
      .setStatus(status)
      .setHint(hintText, hintCssClass)
      .addTitleColumn(title, subtitle, null, need.passport.english_name)
      .addUnitColumn(amount, unit)
      .addListColumn([{
        key: 'Created', value: createdAt
      }, {
        key: 'Expires', value: expiredAt, cssClass: (isCloseToExpired ? 'warning' : null)
      }])
      .addAuthorColumn(author)
      .addControlsColumn([{
        fn: (data: ICustomListRecord) => {
          this.dialog.open(NeedDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
            data: {
              readOnly: true,
              id: need.id,
              showValidationButtons: showValidationButtons,
            },
          }).afterClosed().subscribe(() => this.getRecords());
        },
        name: 'See details'
      }, ...(need.from_deposit && need.from_deposit > 0 ? [{
        name: 'See targeted deposit',
        fn: () => {
          this.dialog.open(DepositDetailsDialogComponent, {
            data: {
              id: need.from_deposit,
              readOnly: true
            }
          });
        }
      }] : []), {
        fn: (data: ICustomListRecord) =>  this.openChat(need.user_id),
        name: 'Chat with Need manager'
      },
        ...(showValidationButtons ? [
          {
            fn: (data: ICustomListRecord) => {
              need.publish().subscribe(() => {
                this.getRecords();
              });
            },
            name: 'Validate'
          },
          {
            fn: (data: ICustomListRecord) => {
              need.reject().subscribe((need: Need) => {
                this.getRecords();
              });
            },
            name: 'Reject'
          }
        ] : [])
      ])
      ;
  }

  toggleState(state: number) {
    this.searchStatus = getSearchStatus(state, this.searchStatus);
    this.getRecords();
  }

  openChat(targetId: number) {
    console.log('openChat', targetId);
    this._globalEvents.chatUserClicked$.emit(targetId);
  }
}
