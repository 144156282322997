import * as tslib_1 from "tslib";
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { AbstractReportStrategyWithDetails } from './abstract-report-strategy.service';
var EconomicProfitsStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(EconomicProfitsStrategy, _super);
    function EconomicProfitsStrategy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileType = ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_ECONOMIC;
        _this.unit = '€';
        return _this;
    }
    EconomicProfitsStrategy.prototype.getSteps = function (allSteps) {
        return {
            virginProducts: allSteps.virginProducts,
            deposits: allSteps.deposits,
            transforms: allSteps.transforms,
            transports: allSteps.transports,
            needs: allSteps.needs
        };
    };
    EconomicProfitsStrategy.prototype.getOrders = function (step) {
        return step.summary_details.orders.length > 1 ? [step.summary_details.orders[0]] : step.summary_details.orders;
    };
    EconomicProfitsStrategy.prototype.formatValue = function (step) {
        return step.confirmed_price.toString();
    };
    EconomicProfitsStrategy.prototype.isDataMissing = function (errors, step) {
        return step.confirmed_price === null;
    };
    EconomicProfitsStrategy.prototype.otherAdd = function (ecosystem, value) {
        this.setOtherTotal(value, function (val) { return ecosystem.add_other_profits = val; });
        return ecosystem.add_other_profits;
    };
    EconomicProfitsStrategy.prototype.otherSubtract = function (ecosystem, value) {
        this.setOtherTotal(value, function (val) { return ecosystem.subtract_other_profits = val; });
        return ecosystem.subtract_other_profits;
    };
    EconomicProfitsStrategy.prototype.otherDescription = function (ecosystem, value) {
        this.setOtherDescription(value, function (val) { return ecosystem.description_other_profits = val; });
        return ecosystem.description_other_profits;
    };
    EconomicProfitsStrategy.prototype.getTotalErrors = function (ecosystem) {
        return ecosystem.economic_total.errors;
    };
    return EconomicProfitsStrategy;
}(AbstractReportStrategyWithDetails));
export { EconomicProfitsStrategy };
