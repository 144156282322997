import { ActiveRecord } from '../../../shared/models/active-record.class';
import { ContactService } from '../service/contact.service';

export class Contact extends ActiveRecord {
  protected provider: any = ContactService;

  id: number;
  name: string;
  client_id: number;
  email: string;
  function: string;
  phone: string;

  fields() {
    return ['id', 'name', 'client_id', 'email', 'function', 'phone'];
  }
}
