/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-category.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../category/partials/tree-view/tree-view.component.ngfactory";
import * as i3 from "../../../category/service/category.service";
import * as i4 from "@angular/http";
import * as i5 from "../../../../shared/services/log-notification.service";
import * as i6 from "../../../../shared/services/navigation.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../shared/services/mock/mock-api.service";
import * as i9 from "../../../category/partials/tree-view/tree-view.component";
import * as i10 from "./view-category.component";
import * as i11 from "@angular/material";
var styles_ViewCategoryDialogComponent = [i0.styles];
var RenderType_ViewCategoryDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewCategoryDialogComponent, data: {} });
export { RenderType_ViewCategoryDialogComponent as RenderType_ViewCategoryDialogComponent };
export function View_ViewCategoryDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "category-tree-view", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TreeViewComponent_0, i2.RenderType_TreeViewComponent)), i1.ɵprd(512, null, i3.CategoryService, i3.CategoryService, [i4.Http, i5.LogNotificationService, i6.NavigationService, i7.TranslateService, i8.MockApiService]), i1.ɵdid(2, 245760, null, 0, i9.TreeViewComponent, [i3.CategoryService], null, { onSave: "onSave" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ViewCategoryDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "view-category", [], null, null, null, View_ViewCategoryDialogComponent_0, RenderType_ViewCategoryDialogComponent)), i1.ɵdid(1, 49152, null, 0, i10.ViewCategoryDialogComponent, [i11.MdDialogRef], null, null)], null, null); }
var ViewCategoryDialogComponentNgFactory = i1.ɵccf("view-category", i10.ViewCategoryDialogComponent, View_ViewCategoryDialogComponent_Host_0, { item: "item" }, {}, []);
export { ViewCategoryDialogComponentNgFactory as ViewCategoryDialogComponentNgFactory };
