<div class="upload-files__item">

  <div class="passport__main-image" [ngClass]="{'preview': !!preview, 'upload-files__main-image--preview': !!preview}" *ngIf="preview">
    <a class="upload-files__wrapper" [href]="preview" target="_blank">
      <img class="upload-files__icon" [src]="preview">
    </a>
    <button class="upload-files__delete" (click)="removeFile(file)" *ngIf="!isDisabled" type="button">
      <i class="icon icon--cross"></i>
    </button>
  </div>

  <div *ngIf="!preview" (click)="onUploadButtonClick($event)" class="upload-files__upload--logo-container">
    <input
      id="logoUploader"
      class="passport__file-control"
      type="file"
      name="preview"
      placeholder="{{ 'Logo' | translate }}"
      ng2FileSelect
      (change)="onSelected($event)"
      [disabled]="isDisabled"
      [uploader]="uploader"/>
    <label
      [ngStyle]="uploaderStyles()"
      [ngClass]="{'contain-image': !!preview}"
      for="logoUploader"
      class="passport__upload-link upload-files__upload--link">{{ 'Upload' | translate }}</label>
  </div>

</div>
