import { Injectable } from '@angular/core';
import { Transform } from '../models/transform.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { ILiteral } from 'ng2-adn-common';
import { Response, Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';


@Injectable()
export class TransformService extends ExtendedCrudService {
  protected namespace = 'transformations';
  protected namespaceSingular = 'transformation';
  protected ModelClass = Transform;

  getPdfReportUrl(query: ILiteral) {
    const q = this.formQueryString(query);
    return this.getEndpoint(`${this.namespaceSingular}/report${q ? '?' + q : ''}`);
  }

  getPdfReport(query: ILiteral) {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/report'), { search: query })
      .catch(this.onError.bind(this));
  }

  getWaiting(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/waiting'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getCount(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getInEcosystem(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/in_ecosystem'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getByRelevancy(needId: number, query: any = {}): Observable<any> {
    query.need_id = needId;

    return this.sendPost(this.getEndpoint(this.namespaceSingular + '/relevant'), query)
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  get<T>(query?: any): Observable<T[]> {
    if (query && query['status[]'] && query['status[]'].length === 0) { // applying 0 status filters is equivalent to requesting nothing
      return Observable.of([]);
    }
   // if (this.isCurrentContextESM()) {
     // console.log(CurrentUser.infoData.has_transform_acces) ;
     // if (CurrentUser.infoData.has_transform_acces) {
     //   console.log('it has access') ;
       // query.independant = 1;
      //}
   // }
  //  console.log(query);
    return super.get(query);
  }
}
