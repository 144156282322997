<div *ngIf="reportData">
  <div *ngFor="let type of resultKeys; let i = index">
    <report-pdf *ngIf="showSitesInOnePage" [pageNumber]="initPageNumber()" [reportHeader]="reportHeader" [subtitle]="type">
      <report-pdf-site-list [sites]="reportData.sites"></report-pdf-site-list>
      <div class="first-block__body">
        <div class="first-block__body--description" *ngIf="type !== RESIDUAL_VALUE_TYPE.empty">
          <p class="first-block__body--description-text" *ngIf="type == 'deconstruction'"
            [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.DECONSTRUCTION' | translate"></p>
          <p class="first-block__body--description-text" *ngIf="type == 'rehabilitation'"
            [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.REHABILITATION' | translate"></p>
          <p class="first-block__body--description-text" *ngIf="type == 'renewal_building_commercial'"
            [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.RENEWAL' | translate"></p>
          <p class="first-block__body--description-text" *ngIf="type == 'renewal_building_residantial'"
             [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.RENEWAL_RESIDANTIAL' | translate"></p>
          <p class="first-block__body--description-text" *ngIf="type == 'renewal_building_institutional'"
             [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.RENEWAL_INSTITUTIONAL' | translate"></p>
        </div>
    </div>
    </report-pdf>
    <report-pdf [pageNumber]="initOrGetPageNumber()"
                [reportHeader]="reportHeader" [subtitle]="type">
      <report-pdf-site-list *ngIf="!showSitesInOnePage"
                            [sites]="reportData.sites"
                            [isLongVersion]="true"
                            [measureRows]="false"
                            [isGenerateFromFile]="reportData.created_by_file"
      ></report-pdf-site-list>
      <div *ngIf="!isEmptyDepositCategory(type)" class="first-block">
        <div class="first-block__body">
          <div class="first-block__body--description">
            <p class="first-block__body--description-text" *ngIf="type == 'deconstruction'"
              [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.DECONSTRUCTION' | translate"></p>
            <p class="first-block__body--description-text" *ngIf="type == 'rehabilitation'"
              [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.REHABILITATION' | translate"></p>
            <p class="first-block__body--description-text" *ngIf="type == 'renewal_building_commercial'"
              [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.RENEWAL' | translate"></p>
            <p class="first-block__body--description-text" *ngIf="type == 'renewal_building_residantial'"
               [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.RENEWAL_RESIDANTIAL' | translate"></p>
            <p class="first-block__body--description-text" *ngIf="type == 'renewal_building_institutional'"
               [innerHTML]="'RESIDUAL_VALUE_DESCRIPTION.RENEWAL_INSTITUTIONAL' | translate"></p>
          </div>
        </div>
        <div class="first-block__title--wrapper">
          <h2 class="second-block__title--item">{{'two possible scenarios' | translate}}</h2>
        </div>

        <div class="first-block__body">
          <div class="first-block__body--main-charts">
            <residual-value-first-chart [type]="type" [report]="result" [isPDFReport]="true">
            </residual-value-first-chart>
          </div>
        </div>
      </div>
      <section *ngIf="isEmptyDepositCategory(type)" class="section-error">
        <h3 class="section-error__title">{{ 'No deposit considered' | translate }}</h3>
      </section>
    </report-pdf>

    <report-pdf *ngIf="!isEmptyDepositCategory(type)" [pageNumber]="getPageNumber()" [reportHeader]="reportHeader" [subtitle]="type">
      <div *ngIf="!isEmptyDepositCategory(type)" class="first-block">
        <div class="first-block__title--wrapper">
          <h2 class="second-block__title--item">{{'PROFIT_ORIENTED_SCENARIO' | translate}}</h2>
        </div>

        <div class="first-block__body">
          <div class="first-block__body--main-charts">
            <div class="residual-value-report__chart-container">
              <residual-value-second-chart [type]="type" [report]="result" [isPDFReport]="true">
              </residual-value-second-chart>
            </div>
          </div>
        </div>
      </div>
    </report-pdf>
<ng-container *ngIf="!isEmptyDepositCategory(type)">
    <report-pdf *ngFor="let chunk of allResultsChunked[type]; let i = index"
                [pageNumber]="getPageNumber()"
                [reportHeader]="reportHeader"
                [subtitle]="type">
      <div  class="first-block">
        <div class="first-block__title--wrapper">
          <h2 class="second-block__title--item">{{'all categories (profit oriented scenario)' | translate}}</h2>
        </div>

        <div class="first-block__body">
          <div class="first-block__body--main-charts">
            <div class="residual-value-report__chart-container">
              <div *ngFor="let results of chunk; let j = index">
                <residual-value-third-chart
                [type]="type"
                [report]="results"
                [isPDFReport]="true"
                [showTotal]="i == 0 && j == 0 ? true : false"
                [showAxisLabel]="i == 0 && j == 0 ? true : false">
                </residual-value-third-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </report-pdf>
</ng-container>

    <report-pdf *ngFor="let table of allCategories[type]; let i = index" [pageNumber]="getPageNumber()"
      [reportHeader]="reportHeader" [subtitle]="type">
      <div class="second-block">
        <div class="second-block__title--wrapper">
          <h2 class="second-block__title--item">{{'all categories (profit oriented scenario)' | translate}}</h2>
        </div>
        <div class="second-block__table">
          <div class="second-block__table--row second-block__table--row-default">
            <p class="second-block__table--row-title">{{'Category' | translate}}</p>
            <p class="second-block__table--row-title">{{'Number of deposits considered' | translate}}</p>
            <p class="second-block__table--row-title" [innerHTML]="'Weight (Tons)' | translate"></p>
            <p class="second-block__table--row-title">{{'Percentage of total weight' | translate}}</p>
            <p class="second-block__table--row-title">{{'Residual Value Low hypothesis' | translate}}</p>
            <p class="second-block__table--row-title">{{'Residual Value High hypothesis' | translate}}
            </p>
          </div>
          <div class="second-block__table--row-wrapper">
            <div class="second-block__table--row" *ngFor="let item of table.pageContent; let index = index;">
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['category'].name | translate}}
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['deposit_considered']}}
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['weight'] | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['mass_percentage'] | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}%
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['financial_recovery_percentage_down_if_economic'] | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['financial_recovery_percentage_up_if_economic'] | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </report-pdf>

  </div>

  <report-pdf *ngFor="let table of errorsTable; let i = index" [pageNumber]="getPageNumber()"
    [reportHeader]="reportHeader">
    <div class="third-block">
      <div class="third-block__title--wrapper">
        <h2 class="third-block__title--item">
          {{'Error report' | translate}} (<span class="third-block__title--item-span">{{getAmountOf('errors')}}
            {{'errors' | translate}}, {{getAmountOf('warnings')}} {{'warnings' | translate}}</span>)
        </h2>
      </div>
      <div class="third-block__body">
        <div class="third-block__body--description">
          <div class="third-block__body--description">
            <p [innerHTML]="'ERROR_REPORT_DESCRIPTION_INTRO' | translate"></p>
            <br>
            <p><i class="icon--admin-warning icon"></i>{{'ERROR_REPORT_DESCRIPTION_WARNING' | translate}}</p>
            <br>
            <p><i class="icon--admin-error icon"></i>{{'ERROR_REPORT_DESCRIPTION_ERROR' | translate}}</p>
            <br>
            <p [innerHTML]="'ERROR_REPORT_DESCRIPTION_OUTRO' | translate"></p>
          </div>
        </div>
        <div *ngIf="reportData.is_asynchronous_generated" class="third-block__body--table">
          <div class="third-block__body--table-row">
            <div class="third-block__body--table-row-title">
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'NUMBER_DEPOSITS_CONSIDERED' | translate}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'Error explanation' | translate}}</p>
            </div>
          </div>
          <div *ngFor="let item of table.pageContent; let index = index" class="third-block__body--table-row">
            <div class="third-block__body--table-row-item">
              <i [ngClass]="[isError(item['type']) ? 'icon--admin-error' : 'icon--admin-warning', 'icon']"></i>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{item.counter}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{getErrorMessage(item)}}</p>
            </div>
          </div>
        </div>
        
        <div *ngIf="!reportData.is_asynchronous_generated" class="third-block__body--table">
          <div class="third-block__body--table-row">
            <div class="third-block__body--table-row-title">
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'Deposit name' | translate}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'Error explanation' | translate}}</p>
            </div>
          </div>
          <div *ngFor="let item of table.pageContent; let index = index" class="third-block__body--table-row">
            <div class="third-block__body--table-row-item">
              <i [ngClass]="[isError(item['type']) ? 'icon--admin-error' : 'icon--admin-warning', 'icon']"></i>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{item.deposit_name}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{getErrorMessage(item)}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </report-pdf>
</div>
