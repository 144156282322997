export enum ECarbonFootprintReportType {
    EMBODIED_CARBON = "embodied_carbon",
    PRODUCT_STAGE = "product_stage",
    IC_COMPONENT = "ic_component",
    IC_CONSTRUCTION = "ic_construction",
    PRODUCT_AND_CONSTRUCTION = "product_and_construction"
  }
  export enum ERCarbonFootprintStatusEmpty {
    EMPTY = 'empty'
  }
  