import {Injectable} from '@angular/core';
import {TransformOrder} from '../models/transform-order.class';
import {DepositOrder} from '../models/deposit-order.class';
import {TRANSFORM_TYPE} from '../../transformation-manager/values/transform-types.const';
import {PASSPORT_UNITS_TYPE} from '../../../entities/passport/values/passport-units.const';
import {EcosystemOffer} from '../../../entities/ecosystem-offer/ecosystem-offer.model';
import {EcosystemStep} from '../models/ecosystem-step.class';
import {ECOSYSTEM_STEP_TYPE} from '../values/ecosystem-step-type.const';
import lodash from 'lodash';
import {Ecosystem} from '../models/ecosystem.class';
import {Transform} from '../../../entities/transformation/models/transform.class';
import {Passport} from '../../../entities/passport/models/passport.class';

export const PREFILL_OFFER_ID = -1;

export interface IPrefill {
  [id: number]: {in: number, out: number};
}

export interface IPrefillInput {
  prefillStore: IPrefill;
  junction: Map<number, any>;
  input: Map<number, any>;
  output: Map<number, any>;
}

export interface IAbstractPrefillMetadata {
  junction: Map<number, any>;
  input: Map<number, any>;
  output: Map<number, any>;
}

export interface IPrefillConectionsMetadata extends IAbstractPrefillMetadata {
  leafs: (DepositOrder | TransformOrder)[];
}

export interface IPrefillMetadata extends IAbstractPrefillMetadata {
  prefillStore: IPrefill;
}

export interface IPrefillChange {
  [id: number]: {in?: {before: number, after: number}, out?: {before: number, after: number}};
}

@Injectable({
  providedIn: 'root'
})
export class EcosystemPrefillService  {
  private junction: Map<number, any> = new Map();
  private input: Map<number, any> = new Map();
  private output: Map<number, any> = new Map();
  private prefillStore: IPrefill;
  private prefillStoreChange: IPrefillChange;

  constructor() { }

  public createPrefillDependencyTree(leafs: (DepositOrder | TransformOrder)[]
    , junction: Map<number, any>, input: Map<number, any>, output: Map<number, any>
    , prefillStore: IPrefill = {}, preferPrefill = true
  ): IPrefill {
    const nodes: (DepositOrder | TransformOrder)[] = [...leafs];
    let maxCount  = output.size + leafs.length + 1;
    // console.log(leafs, prefillStore, preferPrefill);
    const visited = {};
    while (!lodash.isEmpty(nodes)) {
      if (!maxCount) {
        // console.warn('prefill failed');
        return {};
      }
      maxCount -= 1;
      const node = nodes.shift();
      const {order} = this.getOrderForPrefill(node, output);
      const orderStepId = order && (order as TransformOrder).step_id;
      const orderList = junction.get(orderStepId) || [];
      const prefillIn = this.prefillOneInOrder(order, node, orderList, input, output, prefillStore, preferPrefill);
      if (!!prefillIn.skip) {
        continue;
      }
      this.putInStore(this.getPrefillStoreId(order), prefillIn, true, prefillStore);
      const prefillOut = this.prefillOneOrderOutput(orderList, input, prefillStore, preferPrefill);
      if (preferPrefill || !prefillOut.skip) {
        orderList.forEach(orderInList => this.putInStore(orderInList.id, prefillOut, false, prefillStore));
      }
      if (preferPrefill && !visited[order.id] || this.isNumber(prefillOut.out)) {
        nodes.push(order);
        visited[order.id] = order;
      }
    }
    // console.log(prefillStore, 'final');
    return prefillStore;
  }

  private putInStore(storeId: number, prefill: {in?: number, out?: number }, isIn: boolean, prefillStore) {
    if (!prefillStore[storeId]) {
      prefillStore[storeId] = {in: null, out: null};
    }
    if (isIn) {
      prefillStore[storeId].in = prefill.in || null;
    } else {
      prefillStore[storeId].out = prefill.out || null;
    }
  }

  private getPrefillStoreId(order: EcosystemOffer | TransformOrder) {
    return this.isOffer(order) ?  PREFILL_OFFER_ID : (order && order.id);
  }

  private getOrderForPrefill(node, output): {order, orderList} {
    const orderList: any[] = output.get(node.step_id) || [];
    let order = orderList.length ? orderList[0] : null;
    if (orderList.length > 1) {
      order = orderList.find(orderAlternative => orderAlternative.child_step.id === node.step_id);
    }
    return {order, orderList};
  }

  public prefillOneInOrder( order: TransformOrder | EcosystemOffer
                        , beforeOrder: TransformOrder | DepositOrder
                        , orderList: (TransformOrder | EcosystemOffer)[]
                        , input = this.input
                        , output = this.output
                        , prefillStore = this.prefillStore
                        , preferPrefill?: boolean): {in?: number, skip?: boolean} {
    // console.log('before', beforeOrder, 'current', order, 'inputs');
    if (!order) {
      // console.log('skip?');
      return {skip: true};
    }
    const prefill = this.getPrefillInput(order, beforeOrder, prefillStore, preferPrefill);
    // console.log(prefill, 'input prefill');
    return {in: prefill};
  }

  private prefillOneOrderOutput(orderList: (TransformOrder | EcosystemOffer)[]
    , input = this.input
    , prefillStore = this.prefillStore
    , preferPrefill?: boolean ): {skip?: boolean, out?: number} {
    const isOutPrefill = this.canOutPrefillBeCalculated(orderList, prefillStore, preferPrefill);
    // console.log(isOutPrefill, 'can out prefill');
    if (isOutPrefill) {
      if (!preferPrefill) {
        this.addMissingInput(orderList, prefillStore);
      }
      const inputWithSubstitution = this.createSubstitutionJunctionIfConversionInherits(orderList as any, input);
      // console.log(inputWithSubstitution, 'out substitution');
      const outPrefill = this.transformChange(inputWithSubstitution, prefillStore, preferPrefill);
      // console.log(outPrefill, 'out prefill');
      return {out: outPrefill};
    }
    return {skip: true};
  }

  private canOutPrefillBeCalculated(orderList
    , prefillStore, preferPrefill = true) {
    const isOutPrefill = orderList.every(orderInList => this.isInputExistsInStore(orderInList.id, prefillStore)
      || (!preferPrefill && this.isInputProvidedByUser(orderInList)));
    return isOutPrefill && this.isTransformOrder(orderList.length ? orderList[0] : {});
  }

  private isInputExistsInStore(id, prefillStore) {
    return prefillStore[id] && this.isNumber(prefillStore[id].in);
  }

  private isInputProvidedByUser(order) {
    return this.isNumber(this.getQuantity(order, true));
  }

  private addMissingInput(orderList: Array<any>, prefillStore) {
    orderList.forEach(order => {
      if (!this.isInputExistsInStore(order.id, prefillStore)) {
        this.putInStore(order.id, {in: this.getQuantity(order, true)}, true, prefillStore);
      }});
    // console.log(prefillStore, orderList, 'mis fix?');
  }

  private createSubstitutionJunctionIfConversionInherits(orderList: TransformOrder[], input) {
    return orderList.map((orderInList) => {
      const childStepId = !!orderInList && !!orderInList.child_step && this.isNumber(orderInList.child_step.id);
      const childOrder = childStepId ? (input.get(orderInList.step_id) || [])
          .find(substitution => substitution && substitution.step_id === orderInList.child_step.id) : null;
      return {
        input: orderInList,
        substitution: this.getOrderSubstitution(childOrder)
      };
    });
  }

  public getOrderSubstitution(childOrder) {
    if (this.isTransformOrder(childOrder)) {
     return  this.substitutionForTransformOrder((childOrder as TransformOrder).transformation);
    } else if (this.isDeposit(childOrder)) {
      return this.substitutionForDepositOrder((childOrder as DepositOrder).deposit_passport);
    } else if (this.isNoneOrder(childOrder)) {
      return  this.substitutionForNoneOrder();
    }
    return null;
  }

  private substitutionForNoneOrder(): {conversion: number, unit: number} {
    return {conversion: 1, unit: PASSPORT_UNITS_TYPE.KG};
  }

  private substitutionForTransformOrder(transform: Transform): {conversion: number, unit: number} {
    if (this.transformWithoutConversionWithOut(transform)) {
      return {conversion: 1, unit: PASSPORT_UNITS_TYPE.KG};
    }
    return {conversion: transform.out_conversion, unit: transform.output_unit};
  }

  private substitutionForDepositOrder(passport: Passport): {conversion: number, unit: number} {
    return {conversion: passport.conversion, unit: passport.unit};
  }


  public prefillAndChangeByEcosystem(ecosystem: Ecosystem, preferPrefill = true) {
    return this.prefillAndChangeStore(ecosystem.steps, ecosystem.deposit_orders
      , ecosystem.transformation_orders, ecosystem.ecosystem_offer, preferPrefill);
  }

  public prefillAndChangeStore(steps: EcosystemStep[]
                               , depositOrders: DepositOrder[]
                               , transforms: TransformOrder[]
                               , offer: EcosystemOffer, preferPrefill = true) {
    const data = this.prefill(steps, depositOrders, transforms, offer, preferPrefill);
    let compare: IPrefillChange = {};
    if (this.prefillStore) {
      compare = this.comparePrefills(data.prefillStore);
    }
    Object.assign(this, data);
    return compare;
  }

  public prefill(steps: EcosystemStep[]
                 , depositOrders: DepositOrder[]
                 , transforms: TransformOrder[]
                 , offer: EcosystemOffer
                  , preferPrefill = true
  ): IPrefillMetadata {
    const inputs = this.createConnection(steps, depositOrders, transforms, offer);
    const {leafs, junction, input, output} = inputs;
    return {
      prefillStore: this.createPrefillDependencyTree(leafs, junction, input, output, {}, preferPrefill),
      ...inputs
    };
  }

  private createConnection(
    steps: EcosystemStep[]
    , depositOrders: DepositOrder[]
    , transforms: TransformOrder[]
    , offer: EcosystemOffer): IPrefillConectionsMetadata {
    const stepChild = new Map();
    const notOrderSteps = [];
    const mapSteps: Map<number, EcosystemStep> = new Map();
    const mapInOrders = new Map();
    const mapStepToOrder = new Map();
    const mapOutOrders = new Map();
    const leafs: (TransformOrder | DepositOrder)[] = [...depositOrders];
    steps.forEach(step => {
      mapSteps.set(step.id, step as any);
      if (step.parent_id) {
        this.addToMap(stepChild, step.parent_id, step.id);
      }
      if (![ECOSYSTEM_STEP_TYPE.DEPOSIT, ECOSYSTEM_STEP_TYPE.TRANSFORM, ECOSYSTEM_STEP_TYPE.NEED].includes(step.type)) {
        notOrderSteps.push(step);
      }
    });
    notOrderSteps.forEach((step: EcosystemStep) => {
      this.updateStepToOrderJunction(step.parent_id, step.id, stepChild.get(step.id), null, mapInOrders, mapStepToOrder, mapOutOrders);
    });
    const getParentStep = (id) => mapSteps.get(id) && mapSteps.get(id).parent_id;
    for (const order of transforms) {
      const parentId = getParentStep(order.step_id);
      const childId = order.child_step ? order.child_step.id : null;
      this.updateStepToOrderJunction(parentId, order.step_id, childId, order, mapInOrders, mapStepToOrder, mapOutOrders);
    }
    depositOrders.forEach(order => {
      const parentId = getParentStep(order.step_id);
      this.updateStepToOrderJunction(parentId, order.step_id, null, order, mapInOrders, mapStepToOrder, mapOutOrders);
    });
    const offerStep = steps.find(step => step.type === ECOSYSTEM_STEP_TYPE.NEED);
    this.addToMap(mapStepToOrder, offerStep.id, offer);
    const offerConnection = mapInOrders.get(offerStep.id);
    if (offerConnection && offerConnection[0]) {
      this.addToMap(mapOutOrders, offerConnection[0].step_id, offer);
    }
    // console.log('connections in', mapInOrders, 'junction', mapSteps, 'out', mapOutOrders);
    // console.log('rawData:', steps);
    leafs.push(...this.addAllTransformOrdersWithoutOrderChild(transforms, mapInOrders, mapOutOrders));
    // console.log('----leafs', leafs);
    return {
      leafs: leafs,
      junction: mapStepToOrder,
      input: mapInOrders,
      output: mapOutOrders
    };
  }

  private addAllTransformOrdersWithoutOrderChild(transforms: TransformOrder[], input: Map<number, any>, output: Map<number, any>) {
    return transforms.filter(transform => {
      const inputs = input.get(transform.step_id);
      const outputs = output.get(transform.step_id);
      const inputExist = inputs && inputs.length && !!inputs.find(child => !!child);
      const outputExist = !outputs || !outputs.length || outputs.find(child => !!child);
      return !inputExist && outputExist;
    });
  }

  private updateStepToOrderJunction(parenStepId, stepId, childStepId,  order
    , input = this.input, junction = this.junction, output = this.output ) {
    if (this.isNumber(parenStepId)) {
      this.addToMap(input, parenStepId, order);
    }
    this.addToMap(junction, stepId, order);
    if (this.isNumber(childStepId)) {
      this.addToMap(output, childStepId, order);
    }
  }

  public prefillUpdateIfOrderChanged(order: DepositOrder | TransformOrder, isInputChanged: boolean): IPrefillChange {
    const newPrefill = this.recalculatePrefillIfOrderChanged(order, isInputChanged);
    if (newPrefill) {
      this.updateConnection(order);
      // console.log(newPrefill, this.prefillStore);
      const changes = this.comparePrefills(newPrefill);
      this.prefillStore = newPrefill;
      this.prefillStoreChange = changes;
      // console.log(changes, 'changes');
      return changes;
    } else {
      return {};
    }
  }

  private updateConnection(order: (DepositOrder | TransformOrder)) {
    const stepOrders = this.junction.get(order.step_id);
    if (stepOrders) {
      const junctionOrder = stepOrders.find(orderInJunction => orderInJunction.id === order.id);
      if (junctionOrder) {
        this.setOrderOut(junctionOrder, this.getQuantity(order, false));
        // console.log(order, this.junction, this.getQuantity(order, false), 'update', junctionOrder);
      }
    }
  }

  public isPrefillInited() {
    return !!this.prefillStore;
  }

  public getPrefilledValue(id) {
    const isExistChange = this.prefillStoreChange && this.prefillStoreChange[id];
    const input = isExistChange && this.prefillStoreChange[id].in && this.prefillStoreChange[id].in.after;
    const output = isExistChange && this.prefillStoreChange[id].out && this.prefillStoreChange[id].out.after;
    return this.prefillStore[id] || {in: input, out: output};
  }

  public getOrderInPrefill(order: TransformOrder | EcosystemOffer) {
    const id = this.getPrefillStoreId(order);
    return  (!!this.prefillStore && !!this.prefillStore[id]) ? this.prefillStore[id].in : null;
  }

  public getOrderOutPrefill(order: TransformOrder | EcosystemOffer) {
    const id = this.getPrefillStoreId(order);
    return (!!this.prefillStore && !!this.prefillStore[id]) ? this.prefillStore[id].out : null;
  }

  public getFirstOrderByStep(step: EcosystemStep): TransformOrder | DepositOrder {
    const orders = this.junction.get(step.id);
    return (orders && orders.length) ? orders[0] : null;
  }

  public getParentOrderByStepId(id): TransformOrder | DepositOrder {
    const orders = this.output.get(id);
    return (orders && orders.length) ? orders[0] : null;
  }

  public resetOrderOut(order) {
    if (this.isTransformOrder(order)) {
      (order as TransformOrder).out_order_passport.quantity = null;
    } else if (this.isDeposit(order)) {
      (order as DepositOrder).order_passport.quantity = null;
    }
  }

  public getStepByTargetId(step): TransformOrder | DepositOrder {
    const orders = this.output.get(step.id);
    return (orders && orders.length) ? orders[0] : null;
  }

  public setOrderInAsZero(order) {
    if (this.prefillStore[this.getPrefillStoreId(order)]) {
      this.prefillStore[this.getPrefillStoreId(order)].in = 0;
    }
    if (this.isTransformOrder(order)) {
      (order as TransformOrder).in_order_passport.quantity = 0;
    } else if (this.isOffer(order)) {
      (order as EcosystemOffer).quantity = 0;
    }
  }

  public setOrderOut(order, value: number) {
    value = this.isNumber(value) ? value : null;
    if (this.prefillStore[this.getPrefillStoreId(order)]) {
      this.prefillStore[this.getPrefillStoreId(order)].out = value;
    }
    if (this.isTransformOrder(order)) {
      (order as TransformOrder).out_order_passport.quantity = value;
    } else if (this.isOffer(order)) {
      (order as EcosystemOffer).quantity = value;
    } else if (this.isDeposit(order)) {
      (order as DepositOrder).order_passport.quantity = value;
    }
  }

  public getOrderOut(order) {
    if (this.prefillStore[this.getPrefillStoreId(order)]) {
      return this.prefillStore[this.getPrefillStoreId(order)].out;
    }
    if (this.isTransformOrder(order)) {
      return (order as TransformOrder).out_order_passport.quantity;
    } else if (this.isOffer(order)) {
      return (order as EcosystemOffer).quantity;
    }
  }

  public recalculatePrefillIfOrderChanged(order: DepositOrder | TransformOrder, isInputChanged: boolean) {
    if (!this.prefillStore || !order) {
      return null;
    }
    const copyOldPrefill = lodash.cloneDeep(this.prefillStore);
    if (this.isTransformOrder(order)) {
      const stepOrders = this.junction.get(order.step_id);
      if (!stepOrders) {
        return this.prefillStore;
      }
      let outputQuantity: number;
      if (isInputChanged) {
        if (!copyOldPrefill[order.id]) {
          copyOldPrefill[order.id] = {in: null, out: null};
        }
        copyOldPrefill[order.id].in = this.typecastNumber(this.getQuantity(order, true));
        outputQuantity = this.prefillOneOrderOutput(stepOrders, this.input, copyOldPrefill).out;
      } else {
        outputQuantity = this.typecastNumber(this.getQuantity(order, false));
      }
      stepOrders.filter(outputOrder => copyOldPrefill[outputOrder.id])
        .forEach((outputOrder) => copyOldPrefill[outputOrder.id].out = outputQuantity);
    } else  {
      const quantity = this.typecastNumber(this.getQuantity(order, false));
      if (this.isNumber(quantity)) {
        if (this.isDeposit(order)) {
          (order as DepositOrder).order_passport.quantity = quantity;
        } else if (this.isOffer(order)) {
          (order as any).quantity = quantity;
        }
      }
    }
    return this.createPrefillDependencyTree([order], this.junction, this.input, this.output, copyOldPrefill);
  }

  private typecastNumber(num: number | string): number {
    if (typeof num === 'string' && !!num) {
      return Number(num);
    } else if (this.isNumber(num)) {
      return Number(num);
    }
    return null;
  }

  private getQuantity(order, isInQuantity: boolean) {
    if (this.isTransformOrder(order)) {
      const passport = isInQuantity ? (order as TransformOrder).in_order_passport : (order as TransformOrder).out_order_passport;
      return passport ? passport.quantity : null;
    } else if (this.isDeposit(order)) {
      const passport = (order as DepositOrder).order_passport;
      return passport ? passport.quantity : null;
    } else {
      return order && (order as EcosystemOffer).quantity;
    }
  }

  public comparePrefills(newPrefill, oldPrefill = this.prefillStore): IPrefillChange  {
    const allPrefillIds = new Set([...Object.keys(newPrefill), ...Object.keys(oldPrefill)]);
    const orderPrefillChanged: IPrefillChange = {};
    allPrefillIds.forEach(orderId => {
      const oldIn = oldPrefill[orderId] && oldPrefill[orderId].in;
      const oldOut = oldPrefill[orderId] && oldPrefill[orderId].out;
      const newIn = newPrefill[orderId] && newPrefill[orderId].in;
      const newOut = newPrefill[orderId] && newPrefill[orderId].out;
      if (newIn !== oldIn || oldOut !== newOut) {
        orderPrefillChanged[orderId] = {
          ...(newIn !== oldIn ? {in : {before: oldIn, after: newIn}} : {})
          , ...(newIn !== oldIn ? {out: {before: oldOut, after: newOut}} : {})};
      }
    });
    return orderPrefillChanged;
  }

  private addToMap(map, stepId, order) {
    if (stepId && map.has(stepId)) {
      map.get(stepId).push(order);
    } else {
      map.set(stepId, [order]);
    }
  }

  public getPrefillInput(order: TransformOrder | EcosystemOffer, inOrder: TransformOrder | DepositOrder
               , prefillStore?, preferPrefill = true): number {
    if (!preferPrefill && this.isNumber(this.getQuantity(order, true))) {
      return this.getQuantity(order, true);
    }
    if (this.isOffer(order)) {
      if (this.isDeposit(inOrder)) {
        return this.connectionDepositToNeed(order as EcosystemOffer, inOrder as DepositOrder);
      } else {
        inOrder = this.getSubstituteInTransformation(inOrder as TransformOrder, prefillStore, preferPrefill);
        return this.connectionTransformToNeed(order as EcosystemOffer, inOrder as TransformOrder);
      }
    } else if (this.isTransformOrder(order)) {
      if (this.isDeposit(inOrder)) {
        return this.connectionDepositToTransform(order as TransformOrder, inOrder as DepositOrder);
      } else {
        inOrder = this.getSubstituteInTransformation(inOrder as TransformOrder, prefillStore, preferPrefill);
        return this.connectionTransformToTransform(order as TransformOrder, inOrder as TransformOrder);
      }
    }
    return null;
  }

  private getSubstituteInTransformation(inOrder: TransformOrder, prefillStore, preferPrefill): TransformOrder {
    if (!!prefillStore && prefillStore[inOrder.id] && (preferPrefill || prefillStore[inOrder.id].out)) {
      inOrder = lodash.clone((inOrder as TransformOrder));
      const passport =  lodash.clone(inOrder.out_order_passport);
      passport.quantity = prefillStore[inOrder.id].out;
      inOrder.out_order_passport = passport;
    }
    return inOrder;
  }

  public isDeposit(order) {
    return !!order && !!(order as DepositOrder).deposit_id;
  }

  public isOffer(order) {
    return !!order && !!(order as EcosystemOffer).need;
  }

  public isTransformOrder(order) {
    return !!order && !!(order  as TransformOrder).transformation_id;
  }

  private isNoneOrder(order) {
    return !order;
  }

  public connectionDepositToNeed(offer: EcosystemOffer, inOrder: DepositOrder) {
    const conversionOut = this.getConversion(inOrder.deposit_passport.conversion, inOrder.deposit_passport.unit);
    const conversion = this.getConversion(offer.need.passport.conversion, offer.need.passport.unit);
    const quantity = this.getQuantity(inOrder, false);
    if (inOrder.deposit_passport.unit === offer.need.passport.unit) {
      return quantity;
    } else {
      return this.calculateAsDefault(quantity, conversionOut, conversion);
    }
  }

  public connectionTransformToNeed(offer: EcosystemOffer, inOrder: TransformOrder) {
    const conversionOut = this.getConversion(inOrder.transformation.out_conversion, inOrder.transformation.output_unit);
    const conversion = this.getConversion(offer.need.passport.conversion, offer.need.passport.unit);
    const quantity = this.getQuantity(inOrder, false);
    if (inOrder.transformation.output_unit === offer.need.passport.unit) {
      return quantity;
    } else {
      return this.calculateAsDefault(quantity, conversionOut, conversion);
    }
  }

  public connectionDepositToTransform(order: TransformOrder, inOrder: DepositOrder) {
    const conversionOut = this.getConversion(inOrder.deposit_passport.conversion, inOrder.deposit_passport.unit);
    const conversion = this.getConversion(order.transformation.in_conversion, order.transformation.input_unit);
    const quantity = this.getQuantity(inOrder, false);
    if (order.transformation.input_unit === inOrder.deposit_passport.unit || order.isWithoutUnit()) {
      return quantity;
    } else {
      return this.calculateAsDefault(quantity, conversionOut, conversion);
    }
  }

  public connectionTransformToTransform(order: TransformOrder, inOrder: TransformOrder) {
    const conversionOut = this.getConversion(inOrder.transformation.out_conversion, inOrder.transformation.output_unit);
    const conversion = this.getConversion(order.transformation.in_conversion, order.transformation.input_unit);
    const quantity = this.getQuantity(inOrder, false);
    if (order.transformation.input_unit === inOrder.transformation.output_unit || order.isWithoutUnit()) {
      return quantity;
    } else {
      return this.calculateAsDefault(quantity, conversionOut, conversion);
    }
  }

  private calculateAsDefault(quantity: number , conversionOut: number, conversion: number) {
    if (this.isNumber(quantity) && conversion && conversionOut) {
      return quantity * conversionOut / conversion;
    }
    return null;
  }

  private transformChange(inOrder: {input: TransformOrder, substitution: {conversion: number, unit: number}}[]
                          , prefillStore?, preferPrefill = true) {
    if (!preferPrefill && this.isNumber(this.getQuantity(inOrder[0].input, false))) {
      return this.getQuantity(inOrder[0].input, false);
    }
    if (prefillStore) {
      inOrder = inOrder.map(data => ({
        input: this.getSubstituteOutTransformation(data.input, prefillStore), substitution: data.substitution}));
    }
    return this.transformInToOut(inOrder);
  }

  public transformInToOut(inOrder: {input: TransformOrder, substitution: {conversion: number, unit: number}}[]) {
    const isConvertedToDefaultUnit = this.transformOrderWithoutConversion(inOrder[0].input);
    if (isConvertedToDefaultUnit) {
      return this.transformInToOutWithoutConversion(inOrder);
    } else {
      return this.transformInToOutWithConversion(inOrder.map(item => item.input));
    }
  }

  private getSubstituteOutTransformation(inOrder: TransformOrder, prefillStore): TransformOrder {
    if (!!prefillStore && prefillStore[inOrder.id]) {
      inOrder = lodash.clone((inOrder as TransformOrder));
      const passport =  lodash.clone(inOrder.in_order_passport);
      passport.quantity = prefillStore[inOrder.id].in;
      inOrder.in_order_passport = passport;
    }
    return inOrder;
  }

  private transformInToOutWithConversion(inOrder: TransformOrder[]) {
    const conversion = inOrder[0].transformation.output_quantity;
    const conversionOut = this.getConversion(inOrder[0].transformation.out_conversion, inOrder[0].transformation.output_unit);
    const conversionIn = this.getConversion(inOrder[0].transformation.in_conversion,  inOrder[0].transformation.input_unit);
    if (!this.isNumber(conversion) || !this.isNumber(conversionOut) || !this.isNumber(conversionIn) || !conversionOut) {
      return null;
    }
    let out = 0;
    for (const order of inOrder) {
      const quantity = this.getQuantity(order, true);
      if (this.isNumber(quantity)) {
        if (this.inOutTonToKg(order)) {
          out += quantity * conversion;
        } else {
          out += quantity * conversion * conversionIn / conversionOut;
        }
      } else {
        return null;
      }
    }
    return out;
  }

  private inOutTonToKg(order: TransformOrder) {
    if (order.transformation.input_unit === PASSPORT_UNITS_TYPE.TONNE) {
      return order.transformation.output_unit === PASSPORT_UNITS_TYPE.KG || !order.transformation.output_unit;
    }
    return false;
  }

  private transformInToOutWithoutConversion(inOrder: {input: TransformOrder
    , substitution: {conversion: number, unit: number}}[]) {
    let out = 0;
    for (const order of inOrder) {
      const toDefaultConversion = this.getSubstitution(order.substitution);
      const quantity = this.getQuantity(order.input, true);
      if (this.isNumber(quantity) && this.isNumber(toDefaultConversion)) {
        out += quantity * toDefaultConversion;
      } else {
        return null;
      }
    }
    return out;
  }

  private getSubstitution(substitution: {conversion: number, unit: number}) {
    return this.getConversion(substitution.conversion, substitution.unit);
  }

  private isNumber(obj: any) {
    return typeof obj === 'number' && obj !== Number.NaN;
  }

  private getConversion(conversion: number, unit: number) {
    return this.unitToConversion(unit) || (this.isNumber(conversion) ? conversion : null);
  }

  private unitToConversion(unit: number) {
    if (!unit || PASSPORT_UNITS_TYPE.KG === unit) {
      return 1;
    } else if (PASSPORT_UNITS_TYPE.TONNE === unit) {
      return 1000;
    }
  }

  public transformWithoutConversionWithOut(order: Transform) {
    return [TRANSFORM_TYPE.TRANSPORT, TRANSFORM_TYPE.STORAGE].includes(order.transformation_type);
  }

  public transformOrderWithoutConversion(order: TransformOrder) {
    return this.transformWithoutConversionWithOut(order.transformation);
  }
}
