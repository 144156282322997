import { Component, ElementRef, ViewChild } from '@angular/core';
import { MdDialog } from '@angular/material';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';
import { UserService } from '../../../../shared/services/user.service';

@Component({
  moduleId: module.id,
  templateUrl: 'specialadmin-dashboard.component.html'
})
export class SpecialAdminDashboardComponent {
  activeIndex = 0;

  publishedNeeds: number;
  publishedTransformations: number;
  publishedDeposits: number;
  publishedProductPassports: number;

  @ViewChild('generateData') private generateDataInput: ElementRef;

  constructor(private mdDialog: MdDialog,
              private _userService: UserService) {
    this._userService.getRoleCounters({ role: ERoleIds.SPECIALADMIN }).subscribe(
      (res: any) => {
        this.publishedNeeds = Number(res.needs);
        this.publishedTransformations = Number(res.transformations);
        this.publishedDeposits = Number(res.deposits);
        this.publishedProductPassports = Number(res.passports);
      });
  }
}

