import {
  ConditionEnum,
  ENextLive,
  FILTER_BY_NO_JUNTION,
  FILTER_BY_RATING_JUNCTION,
  IDepositFilter,
  IStateOfUse
} from '../../../shared/interfaces/filter-states.interface';
import {FormControl} from '@angular/forms';
import {Deposit} from './deposit.class';
import {DepositLocation} from './deposit-location.class';
import {DepositTag} from './deposit-tag.class';
import {DataProviderWithPagination} from '../../../shared/models/data-provider-with-pagination.class';
import {PassportRating} from '../../passport/models/passport-rating.class';
import {PERFORMANCE_RADIOS_VALUES} from '../../passport/values/performance-radios.const';
import {EDepositReuseSource} from '../values/deposit-reuse-source.const';
import {PassportFamily} from '../../passport/models/passport.class';

export class DepositFilter extends DataProviderWithPagination<Deposit> implements IDepositFilter {
  public records: Deposit[];
  public expand = 'passport, nomenclature, deposit_location';
  public availabilityDateFrom = '';
  public availabilityDateTo = '';
  public orderBy = '-created_at';
  public searchKey = '';
  public searchControl = new FormControl();
  public searchStatus = [];
  public isAllSelected = true;
  public isOutOfStockSelected = true;
  public isOnlyActiveSites = false;
  public depositsIds = [];
   
  public stateOfUse: IStateOfUse = {
      irrelevant: false,
      as_new: false,
      slightly_used: false,
      used: false,
      deteriorated: false
    };
  public selectedSites = [];
  public categoryTags = [];
  public nextLives = {
    composting: false,
    energy_recovery: false,
    from_reuse: false,
    methanization: false,
    recycling_upcycling: false,
    refurbishing: false
  };
  public ratingFilter = {
    C2cCertified: false,
    selectRatingFilter: false,
    composition: {color: false, black: false},
    cycle: {color: false, black: false},
    energy: {color: false, black: false},
    water: {color: false, black: false},
    social: {color: false, black: false},
    passportWithoutRating: true,
  };
  public selectedTags = [];
  public noPaginate = false;
  public reuseSource: EDepositReuseSource[] = [];
  public noneResetValues = [
    'noneResetValues',
    'records',
    'orderBy',
    'currentPage',
    'pageCount',
    'totalCount',
    'perPage',
    'noPaginate',
    'expand',
    'buildRating',
    'buildOptions',
    'updateOptions',
    'updateRecords'
  ];
  public isNoahVisible = false;

  constructor(obj = {}) {
    super();
    Object.assign(this, obj);
  }

  buildOptions(): Array<any[]> {
    const options: any = [
      ['expand', this.expand]
    ];

    if ( this.searchControl.value) {
      options.push(['search',  this.searchControl.value]);
    }

    if ( this.stateOfUse) {
      Object.entries( this.stateOfUse).filter(z => z[1]).map(z => options.push(['condition[]', ConditionEnum[z[0]]]));
    }

    if ( this.nextLives) {
      Object.entries( this.nextLives).filter(z => z[1]).map(z => options.push(['next_lives[]', ENextLive[z[0]]]));
    }

    if (!! this.availabilityDateTo) {
      options.push(['available_to',  this.availabilityDateTo]);
    }

    if ( this.availabilityDateFrom) {
      options.push(['available_from',  this.availabilityDateFrom]);
    }
    if (this.isOnlyActiveSites) {
      options.push(['active_sites', true]);
    }
    if ( this.orderBy) {
      options.push(['sort',  this.orderBy]);
    }
    if ( this.categoryTags.length > 0) {
      this.categoryTags.forEach(tag => options.push(['categories[]', tag.id]));
    }
    if (this.noPaginate) {
      options.push(['nopaginate', true]);
    } else if ( this.currentPage > -1) {
      options.push(['page', this.currentPage]);
    }
    if ( this.selectedSites.length > 0) {
       this.selectedSites.forEach((site: DepositLocation) => options.push(['sites[]', site.id]));
    }
    if ( this.selectedTags.length > 0) {
       this.selectedTags.forEach((tag: DepositTag) => options.push(['tags[]', tag.id]));
    }
    if (this.reuseSource) {
      this.reuseSource.forEach(item => {
        if (EDepositReuseSource[EDepositReuseSource[item]] === Number(EDepositReuseSource.NO_SOURCE)) {
          options.push(['no_deposit_source', true]);
        } else {
          options.push(['deposit_source[]', item]);
        }
      });
    }
    if ( this.isNoahVisible) {
      options.push(['noah_view',  this.isNoahVisible]);
    }

    if (this.isAllSelected) {
      options.push(['all_selected',  true]);
    }

    if (this.depositsIds.length > 0) {
      this.depositsIds.forEach((id: any) => options.push(['deposits_ids[]', id]));
    }

    return options;
  }

  public buildRating(options: any[][]) {
    if (!this.ratingFilter.passportWithoutRating) {
      options.push(['only_rated', true]);
      options.push(['passport_family[]', PassportFamily.FAMILY_MANUFACTURER]);
    }
    if (this.ratingFilter.C2cCertified) {
      options.push([FILTER_BY_NO_JUNTION.C2cCertified, PERFORMANCE_RADIOS_VALUES.YES]);
    }
    Object.keys(this.ratingFilter)
      .filter(item => FILTER_BY_RATING_JUNCTION[item])
      .map(item => [`rating[${FILTER_BY_RATING_JUNCTION[item]}]`, PassportRating.getRatingStatus(this.ratingFilter[item])])
      .filter(item => item[1] !== null)
      .forEach((item) => options.push(item));
    return options;
  }

}
