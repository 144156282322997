import { EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
var DepositLocationFilterComponent = /** @class */ (function () {
    function DepositLocationFilterComponent() {
        this.iconClass = 'icon--people-grey';
        this.inputContainerClass = ['input-group'];
        this.filteredItems = [];
        this.chosenItems = [];
        this.disabled = false;
        this.filteredString = '';
        this._availableItems = [];
    }
    Object.defineProperty(DepositLocationFilterComponent.prototype, "availableItems", {
        set: function (data) {
            this._availableItems = data || [];
            this.filteredItems = this._availableItems;
            if (this.chosenItems.length > 0) {
                this.chosenItems = [];
                this.changeControl(this.chosenItems);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositLocationFilterComponent.prototype, "availableItemsWithoutReset", {
        set: function (data) {
            var _this = this;
            this._availableItems = data || [];
            this.filteredItems = this._availableItems;
            setTimeout(function () {
                return _this.filteredItems = _this.filteredItems.filter(function (item) { return !_this.chosenItems.some(function (chosenItem) { return chosenItem.id === item.id; }); });
            });
        },
        enumerable: true,
        configurable: true
    });
    DepositLocationFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.clearFilterForm.subscribe(function () {
            _this.chosenItems = [];
            _this.emitChange();
        });
    };
    Object.defineProperty(DepositLocationFilterComponent.prototype, "depositLocationFilterPlaceholder", {
        get: function () {
            return this.chosenItems && this.chosenItems.length > 0 ? '' : this.placeholder;
        },
        enumerable: true,
        configurable: true
    });
    DepositLocationFilterComponent.prototype.chooseLocationList = function (locationList) {
        var _this = this;
        this.chosenItems = locationList.filter(function (location) {
            return !!_this._availableItems.find(function (available) { return available.id === location.id; });
        });
        this.filterItem();
    };
    DepositLocationFilterComponent.prototype.filterItem = function () {
        var _this = this;
        var chosenIds = new Set(this.chosenItems.map(function (item) { return item.id; }));
        this.filteredItems = this._availableItems.filter(function (item) { return !chosenIds.has(item.id); })
            .filter(function (location) { return location[_this.field].toLowerCase().indexOf(_this.filteredString.toLowerCase()) > -1; });
    };
    DepositLocationFilterComponent.prototype.chooseItem = function (data) {
        if (!!this.chosenItems.find(function (location) { return data.id === location.id; })) {
            return;
        }
        this.filteredString = '';
        this.chosenItems.push(data);
        this.emitChange();
    };
    DepositLocationFilterComponent.prototype.declineOfLocation = function (data) {
        this.chosenItems = this.chosenItems.filter(function (location) { return data.id !== location.id; });
        this.emitChange();
    };
    DepositLocationFilterComponent.prototype.emitChange = function () {
        this.filterItem();
        this.changeControl(this.chosenItems);
    };
    DepositLocationFilterComponent.prototype.registerOnChange = function (fn) {
        this.changeControl = fn;
    };
    DepositLocationFilterComponent.prototype.registerOnTouched = function (fn) {
        this.touchControl = fn;
    };
    DepositLocationFilterComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    DepositLocationFilterComponent.prototype.writeValue = function (obj) {
        this.chosenItems = obj;
    };
    return DepositLocationFilterComponent;
}());
export { DepositLocationFilterComponent };
