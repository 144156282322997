
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>

    <deposit-site-details-preview
      [location]="mdDialogData.location"
      [uploader]="mdDialogData.uploader"
      [readOnly]="mdDialogData.readOnly"
      [isValid]="mdDialogData.isValid || defaultValidation"
      (onSave)="mdDialogRef.close($event)"
    ></deposit-site-details-preview>
  