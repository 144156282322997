import { ChangeDetectorRef, NgZone } from '@angular/core';
import { MdDialogRef } from '@angular/material';
var ImportSpinnerComponent = /** @class */ (function () {
    function ImportSpinnerComponent(mdDialogData, dialogRef, ngZone, detector) {
        var _a, _b;
        var _this = this;
        this.mdDialogData = mdDialogData;
        this.dialogRef = dialogRef;
        this.ngZone = ngZone;
        this.detector = detector;
        this.STEP_MIN = 1;
        this.isPercentageShownByDefault = false;
        this.progressLabel = 'Import of deposits ...';
        this.stepLabel = (_a = {},
            _a[this.STEP_MIN] = 'Checking for errors',
            _a);
        this.stepShowProgress = (_b = {},
            _b[this.STEP_MIN] = {
                percentage: false,
                totals: false,
            },
            _b);
        this.footerText = 'Please do not close myUpcyclea during the import process.';
        this.currentTotalText = '';
        this.showTotalsDefault = false;
        this.waitingForErrors = true;
        this.isStepShown = false;
        this.stepMax = 2;
        this.stepCurrent = this.STEP_MIN;
        var assignIfNotUndefined = function (property) {
            return property === undefined ? _this[property] : _this.mdDialogData[property];
        };
        this.stepLabel = assignIfNotUndefined('stepLabel');
        this.progressLabel = assignIfNotUndefined('progressLabel');
        this.stepShowProgress = assignIfNotUndefined('stepShowProgress');
        this.footerText = assignIfNotUndefined('footerText');
        this.currentTotalText = assignIfNotUndefined('currentTotalText');
        this.iconData = assignIfNotUndefined('iconData');
        console.log(this.iconData);
    }
    ImportSpinnerComponent.prototype.waitForImport = function () {
        var _this = this;
        this.ngZone.run(function () {
            _this.isPercentageShownByDefault = true;
        });
    };
    ImportSpinnerComponent.prototype.showPercentage = function (isShown) {
        var _this = this;
        if (isShown === void 0) { isShown = true; }
        this.ngZone.run(function () {
            _this.isPercentageShownByDefault = isShown;
            _this.detector.markForCheck();
        });
    };
    Object.defineProperty(ImportSpinnerComponent.prototype, "progressOfStep", {
        get: function () {
            return this.stepShowProgress[this.stepCurrent];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportSpinnerComponent.prototype, "isPercentageShown", {
        get: function () {
            return this.progressOfStep['percentage'] === undefined
                ? this.isPercentageShownByDefault : this.progressOfStep['percentage'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportSpinnerComponent.prototype, "isTotalsShown", {
        get: function () {
            return (this.progressOfStep['totals'] === undefined
                ? this.showTotalsDefault : this.progressOfStep['totals']) && this.mdDialogData.totals.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportSpinnerComponent.prototype, "pseudoCurrentTotal", {
        get: function () {
            return Math.trunc(this.mdDialogData.percentage.getValue() * this.mdDialogData.totals.getValue() / 100);
        },
        enumerable: true,
        configurable: true
    });
    ImportSpinnerComponent.prototype.changeStep = function (step) {
        var _this = this;
        if (step > this.stepMax) {
            this.stepMax = step;
        }
        if (this.isStepShown) {
            this.showStep();
        }
        this.ngZone.run(function () {
            _this.stepCurrent = step;
            _this.detector.markForCheck();
        });
    };
    ImportSpinnerComponent.prototype.showStep = function (isShown, stepMax) {
        var _this = this;
        if (isShown === void 0) { isShown = true; }
        if (stepMax === void 0) { stepMax = this.stepMax; }
        this.ngZone.run(function () {
            _this.isStepShown = isShown;
            _this.stepMax = stepMax;
            _this.detector.markForCheck();
        });
    };
    return ImportSpinnerComponent;
}());
export { ImportSpinnerComponent };
