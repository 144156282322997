/**
 * Created by aleksandr on 3.08.17.
 */

import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { DepositService } from '../services/deposit.service';
import { Deposit } from '../models/deposit.class';
import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
import { ILiteral } from 'ng2-adn-common';

@Injectable()
export class DepositResolver extends EntityResolver<Deposit> implements Resolve<Deposit> {

  protected query: ILiteral = {
    expand: 'nomenclature,tags,url_link,product_comment,color,' +
      'deposit_location,deposit_location.tags,' +
      'passport,passport_price,passport.category,passport.gtins'
  };

  constructor(protected _service: DepositService) {
    super();
  }
}
