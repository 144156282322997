import { TranslateService } from '@ngx-translate/core';
import { DateTimePickerTranslationService } from '../../../shared/datetime-translation/datetime-translation';
var LanguageService = /** @class */ (function () {
    function LanguageService(_translateService, dateTimePicker) {
        this._translateService = _translateService;
        this.dateTimePicker = dateTimePicker;
        this.languages = ['en', 'fr', 'es', 'pt'];
    }
    LanguageService.prototype.initLang = function () {
        var language = localStorage.getItem('language');
        language && this.languages.includes(language)
            ? this.setLang(language)
            : this.setLang(this._translateService.getDefaultLang());
    };
    LanguageService.prototype.getUsedLanguage = function () {
        return (localStorage.getItem('language') || '');
    };
    Object.defineProperty(LanguageService.prototype, "availableLanguages", {
        get: function () {
            return this.languages;
        },
        enumerable: true,
        configurable: true
    });
    LanguageService.prototype.getLanguageByKey = function (language) {
        switch (language) {
            case 'en':
                return 'English';
            case 'fr':
                return 'Français';
            case 'es':
                return 'Español';
            case 'pt':
                return 'Português';
        }
    };
    LanguageService.prototype.setLang = function (lang) {
        var _this = this;
        this.languages.forEach(function (item) {
            if (item === lang) {
                localStorage.setItem('language', lang);
                _this._translateService.use(lang);
                _this.dateTimePicker.setLocal(lang);
                if (_this.user) {
                    _this.user.language = lang;
                    _this.user.save();
                }
            }
        });
    };
    return LanguageService;
}());
export { LanguageService };
