<!-- <header class="ecosystem-dashboard__header">
  <ul class="ecosystem-dashboard__controls">
  <li class="ecosystem-dashboard__control">
    <button test-id="create-ecosystem" class="ecosystem-dashboard__button--create" (click)="openEcosystemPicker()">
      <i class="icon icon--refresh"></i>
      {{'+'}} {{ 'Create Ecosystem' | translate }}
    </button>
  </li>
</ul>
</header> -->
<section class="ecosystem-dashboard__header ecosystem-dashboard__section ecosystem-dashboard__section--two-column ecosystem-catalog" >
    <button   class="ecosystem-navigator__buttonFilled" (click)="openEcosystemPicker()" *ngIf="isMainPage">
      + {{ 'Create ecosystem' | translate }}
    </button>
    <section class="ecosystem-dashboard__section--counters" *ngIf="!isMainPage">
      <ul class="ecosystem-dashboard__counter-list">
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value">{{ecosystemsInProcessCount}}</div>
          <span class="ecosystem-dashboard__counter-title">{{ 'Ecosystems in process' | translate }}</span>
        </li>
       <!-- <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value">{{offerCount}}</div>
          <span class="ecosystem-dashboard__counter-title">{{ 'sssNew offers validated' | translate }}</span>
        </li> -->
      </ul>
    </section>
    <button class="ecosystem-dashboard__match-button" (click)="openNeedMatchDialogComponent()"
      [mdTooltip]="'Use intelligent algorithms to identify deposits that meet declared needs' | translate"
      [mdTooltipPosition]="'left'">
      <img [src]="getLangDependentImage()" /></button>
</section>

