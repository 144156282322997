import * as tslib_1 from "tslib";
import { TransformFileService } from '../services/transform-file.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
var TransformFile = /** @class */ (function (_super) {
    tslib_1.__extends(TransformFile, _super);
    function TransformFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformFileService;
        return _this;
    }
    TransformFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'transformation_id',
        ]);
    };
    return TransformFile;
}(UploadedDocument));
export { TransformFile };
