import * as tslib_1 from "tslib";
import { DataProvider } from './data-provider.class';
var DataProviderWithPagination = /** @class */ (function (_super) {
    tslib_1.__extends(DataProviderWithPagination, _super);
    function DataProviderWithPagination() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.totalCount = 0;
        _this.perPage = 0;
        _this.pageCount = 1;
        _this.currentPage = 1;
        return _this;
    }
    DataProviderWithPagination.prototype.updateRecords = function (response, pagination) {
        _super.prototype.updateRecords.call(this, response);
        this.currentPage = pagination.currentPage;
        this.perPage = pagination.perPage;
        this.totalCount = pagination.totalCount;
        this.pageCount = pagination.pageCount;
    };
    return DataProviderWithPagination;
}(DataProvider));
export { DataProviderWithPagination };
