<confirm-with-header class="admin-warning-with-header-dialog"
                     [ngClass]="className"
                     [title]="titleText"
                     [confirmButtonText]="confirmText"
                     (onConfirm)="confirm()"
                     (onCancel)="cancel()">
  <article class="admin-warning-with-header-dialog__container">
    <section class="admin-warning-with-header-dialog__header-container">
      <i class="form-group__label--question admin-warning-with-header-dialog__note-mark">!</i>
      <ul class="admin-warning-with-header-dialog__header-list">
        <li *ngFor="let header of subHeaders; index as index" [ngClass]="['admin-warning-with-header-dialog__header',
        'admin-warning-with-header-dialog__header-' + index]">{{header}}</li>
      </ul>
      <i class="form-group__label--question admin-warning-with-header-dialog__note-mark
      admin-warning-with-header-dialog__note-mark--placeholder">!</i>
      <ng-content select="[header]"></ng-content>
    </section>
    <section class="admin-warning-with-header-dialog__main-container">
      <ul class="admin-warning-with-header-dialog__main-list">
        <li *ngFor="let line of main; index as index" [ngClass]="['admin-warning-with-header-dialog__main',
          'admin-warning-with-header-dialog__main-' + index]">{{line}}</li>
      </ul>
      <ng-content select="[main]"></ng-content>
    </section>
  </article>
</confirm-with-header>


