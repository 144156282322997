import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { DepositDocumentFile } from '../models/deposit-document-file.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../../../shared/services/log-notification.service";
import * as i3 from "../../../shared/services/navigation.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/services/mock/mock-api.service";
var DepositDocumentFileService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositDocumentFileService, _super);
    function DepositDocumentFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ModelClass = DepositDocumentFile;
        _this.namespace = 'deposit-files';
        _this.namespaceSingular = 'deposit-file';
        return _this;
    }
    DepositDocumentFileService.ngInjectableDef = i0.defineInjectable({ factory: function DepositDocumentFileService_Factory() { return new DepositDocumentFileService(i0.inject(i1.Http), i0.inject(i2.LogNotificationService), i0.inject(i3.NavigationService), i0.inject(i4.TranslateService), i0.inject(i5.MockApiService)); }, token: DepositDocumentFileService, providedIn: "root" });
    return DepositDocumentFileService;
}(CrudService));
export { DepositDocumentFileService };
