import * as tslib_1 from "tslib";
import { ConditionEnum, ENextLive, FILTER_BY_NO_JUNTION, FILTER_BY_RATING_JUNCTION } from '../../../shared/interfaces/filter-states.interface';
import { FormControl } from '@angular/forms';
import { DataProviderWithPagination } from '../../../shared/models/data-provider-with-pagination.class';
import { PassportRating } from '../../passport/models/passport-rating.class';
import { PERFORMANCE_RADIOS_VALUES } from '../../passport/values/performance-radios.const';
import { EDepositReuseSource } from '../values/deposit-reuse-source.const';
var DepositFilter = /** @class */ (function (_super) {
    tslib_1.__extends(DepositFilter, _super);
    function DepositFilter(obj) {
        if (obj === void 0) { obj = {}; }
        var _this = _super.call(this) || this;
        _this.expand = 'passport, nomenclature, deposit_location';
        _this.availabilityDateFrom = '';
        _this.availabilityDateTo = '';
        _this.orderBy = '-created_at';
        _this.searchKey = '';
        _this.searchControl = new FormControl();
        _this.searchStatus = [];
        _this.isAllSelected = true;
        _this.isOutOfStockSelected = true;
        _this.isOnlyActiveSites = false;
        _this.depositsIds = [];
        _this.stateOfUse = {
            irrelevant: false,
            as_new: false,
            slightly_used: false,
            used: false,
            deteriorated: false
        };
        _this.selectedSites = [];
        _this.categoryTags = [];
        _this.nextLives = {
            composting: false,
            energy_recovery: false,
            from_reuse: false,
            methanization: false,
            recycling_upcycling: false,
            refurbishing: false
        };
        _this.ratingFilter = {
            C2cCertified: false,
            selectRatingFilter: false,
            composition: { color: false, black: false },
            cycle: { color: false, black: false },
            energy: { color: false, black: false },
            water: { color: false, black: false },
            social: { color: false, black: false },
            passportWithoutRating: true,
        };
        _this.selectedTags = [];
        _this.noPaginate = false;
        _this.reuseSource = [];
        _this.noneResetValues = [
            'noneResetValues',
            'records',
            'orderBy',
            'currentPage',
            'pageCount',
            'totalCount',
            'perPage',
            'noPaginate',
            'expand',
            'buildRating',
            'buildOptions',
            'updateOptions',
            'updateRecords'
        ];
        _this.isNoahVisible = false;
        Object.assign(_this, obj);
        return _this;
    }
    DepositFilter.prototype.buildOptions = function () {
        var options = [
            ['expand', this.expand]
        ];
        if (this.searchControl.value) {
            options.push(['search', this.searchControl.value]);
        }
        if (this.stateOfUse) {
            Object.entries(this.stateOfUse).filter(function (z) { return z[1]; }).map(function (z) { return options.push(['condition[]', ConditionEnum[z[0]]]); });
        }
        if (this.nextLives) {
            Object.entries(this.nextLives).filter(function (z) { return z[1]; }).map(function (z) { return options.push(['next_lives[]', ENextLive[z[0]]]); });
        }
        if (!!this.availabilityDateTo) {
            options.push(['available_to', this.availabilityDateTo]);
        }
        if (this.availabilityDateFrom) {
            options.push(['available_from', this.availabilityDateFrom]);
        }
        if (this.isOnlyActiveSites) {
            options.push(['active_sites', true]);
        }
        if (this.orderBy) {
            options.push(['sort', this.orderBy]);
        }
        if (this.categoryTags.length > 0) {
            this.categoryTags.forEach(function (tag) { return options.push(['categories[]', tag.id]); });
        }
        if (this.noPaginate) {
            options.push(['nopaginate', true]);
        }
        else if (this.currentPage > -1) {
            options.push(['page', this.currentPage]);
        }
        if (this.selectedSites.length > 0) {
            this.selectedSites.forEach(function (site) { return options.push(['sites[]', site.id]); });
        }
        if (this.selectedTags.length > 0) {
            this.selectedTags.forEach(function (tag) { return options.push(['tags[]', tag.id]); });
        }
        if (this.reuseSource) {
            this.reuseSource.forEach(function (item) {
                if (EDepositReuseSource[EDepositReuseSource[item]] === Number(EDepositReuseSource.NO_SOURCE)) {
                    options.push(['no_deposit_source', true]);
                }
                else {
                    options.push(['deposit_source[]', item]);
                }
            });
        }
        if (this.isNoahVisible) {
            options.push(['noah_view', this.isNoahVisible]);
        }
        if (this.isAllSelected) {
            options.push(['all_selected', true]);
        }
        if (this.depositsIds.length > 0) {
            this.depositsIds.forEach(function (id) { return options.push(['deposits_ids[]', id]); });
        }
        return options;
    };
    DepositFilter.prototype.buildRating = function (options) {
        var _this = this;
        if (!this.ratingFilter.passportWithoutRating) {
            options.push(['only_rated', true]);
            options.push(['passport_family[]', 2 /* FAMILY_MANUFACTURER */]);
        }
        if (this.ratingFilter.C2cCertified) {
            options.push([FILTER_BY_NO_JUNTION.C2cCertified, PERFORMANCE_RADIOS_VALUES.YES]);
        }
        Object.keys(this.ratingFilter)
            .filter(function (item) { return FILTER_BY_RATING_JUNCTION[item]; })
            .map(function (item) { return ["rating[" + FILTER_BY_RATING_JUNCTION[item] + "]", PassportRating.getRatingStatus(_this.ratingFilter[item])]; })
            .filter(function (item) { return item[1] !== null; })
            .forEach(function (item) { return options.push(item); });
        return options;
    };
    return DepositFilter;
}(DataProviderWithPagination));
export { DepositFilter };
