import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { DepositOrder } from '../models/deposit-order.class';
import { Searchable } from '../../../shared/models/searchable.class';
var DepositOrderService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositOrderService, _super);
    function DepositOrderService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'deposit-orders';
        _this.namespaceSingular = 'deposit-order';
        _this.ModelClass = DepositOrder;
        return _this;
    }
    DepositOrderService.prototype.getRejectUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/reject');
    };
    DepositOrderService.prototype.getValidateUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/validate');
    };
    DepositOrderService.prototype.getReportUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/problem');
    };
    DepositOrderService.prototype.getResendUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/resend');
    };
    DepositOrderService.prototype.sendReport = function (id, problem) {
        return this.sendPost(this.getReportUrl(id), { problem: problem });
    };
    DepositOrderService.prototype.getEcoSystemManagerList = function (query) {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/esm_list'), { search: query })
            .map(function (data) { return data.json().map(function (d) { return new Searchable(d); }); })
            .catch(this.onError.bind(this));
    };
    DepositOrderService.prototype.getCount = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositOrderService.prototype.resend = function (id, data) {
        return this.sendPost(this.getResendUrl(id), data)
            .map(function (res) { return new DepositOrder(res.json()); })
            .catch(this.onError.bind(this));
    };
    return DepositOrderService;
}(ExtendedCrudService));
export { DepositOrderService };
