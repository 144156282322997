/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i3 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i4 from "../../partials/deposit-order-details/deposit-order-details.component.ngfactory";
import * as i5 from "../../../../shared/read-only/read-only.service";
import * as i6 from "../../partials/deposit-order-details/deposit-order-details.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "../../../ecosystem-manager/services/deposit-order.service";
import * as i10 from "./deposit-order-details-dialog.component";
var styles_DepositOrderDetailsDialogComponent = [];
var RenderType_DepositOrderDetailsDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DepositOrderDetailsDialogComponent, data: {} });
export { RenderType_DepositOrderDetailsDialogComponent as RenderType_DepositOrderDetailsDialogComponent };
export function View_DepositOrderDetailsDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "md-dialog-content", [["class", "order-details__dialog"]], [[2, "mat-dialog-content", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MdPrefixRejector, [[2, i1.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i1.MdDialogContent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DialogCloseButtonComponent_0, i2.RenderType_DialogCloseButtonComponent)), i0.ɵdid(4, 49152, null, 0, i3.DialogCloseButtonComponent, [], { color: [0, "color"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "deposit-order-details", [], null, [[null, "rejected"], [null, "validated"], [null, "reportedProblem"], [null, "askedForModification"], [null, "confirmed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rejected" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } if (("validated" === en)) {
        var pd_1 = (_co.mdDialogRef.close() !== false);
        ad = (pd_1 && ad);
    } if (("reportedProblem" === en)) {
        var pd_2 = (_co.mdDialogRef.close() !== false);
        ad = (pd_2 && ad);
    } if (("askedForModification" === en)) {
        var pd_3 = (_co.mdDialogRef.close() !== false);
        ad = (pd_3 && ad);
    } if (("confirmed" === en)) {
        var pd_4 = (_co.mdDialogRef.close() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i4.View_DepositOrderDetailsComponent_0, i4.RenderType_DepositOrderDetailsComponent)), i0.ɵprd(512, null, i5.ReadOnlyService, i5.ReadOnlyService, []), i0.ɵdid(7, 114688, null, 0, i6.DepositOrderDetailsComponent, [i7.TranslateService, i8.DatePipe, i9.DepositOrderService, i5.ReadOnlyService, i1.MdDialog], { id: [0, "id"], readOnly: [1, "readOnly"] }, { validated: "validated", rejected: "rejected", reportedProblem: "reportedProblem", askedForModification: "askedForModification", confirmed: "confirmed" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = "white"; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.mdDialogData.id; var currVal_4 = _co.mdDialogData.readOnly; _ck(_v, 7, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 4).applyClassPosition; _ck(_v, 3, 0, currVal_1); }); }
export function View_DepositOrderDetailsDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DepositOrderDetailsDialogComponent_0, RenderType_DepositOrderDetailsDialogComponent)), i0.ɵdid(1, 49152, null, 0, i10.DepositOrderDetailsDialogComponent, [i1.MD_DIALOG_DATA, i1.MdDialogRef], null, null)], null, null); }
var DepositOrderDetailsDialogComponentNgFactory = i0.ɵccf("ng-component", i10.DepositOrderDetailsDialogComponent, View_DepositOrderDetailsDialogComponent_Host_0, {}, {}, []);
export { DepositOrderDetailsDialogComponentNgFactory as DepositOrderDetailsDialogComponentNgFactory };
