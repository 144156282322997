
export  enum REPORT_FORM_VALUES {
    RESIDUAL_VALUE_REPORT_TYPE = 4,
    DEPOSIT_TYPE_OTHER = 2,
    DEPOSIT_TYPE_BUILDING = 1 ,
    RENEWAL_BUILDING = 3,
    DECONSTRUCTION = 1,
    REHABILITATION = 2,

    COMMERCIAL = 4,
    RESIDENTIAL = 5,
    INSTINUTIONAL = 6,

    ALL_SITES = 7,
    SELECTED_SITES = 8,
    DOCUMENT = 9
}

export const DEPOSIT_TYPES_FOR_REPORTS = [
    {key: REPORT_FORM_VALUES.DEPOSIT_TYPE_BUILDING, value: 'My deposits are exclusively constituents of buildins(s)'},
    {key: REPORT_FORM_VALUES.DEPOSIT_TYPE_OTHER, value: 'Other resources'},
  ];

export const REPORT_SCOPE = [
    {key: REPORT_FORM_VALUES.ALL_SITES, value: 'All sites'},
    {key: REPORT_FORM_VALUES.SELECTED_SITES, value: 'Select sites/tags'},
];

export const REPORT_TYPES = [
    {key: 1, value: 'Carbon footprint'},
    {key: 2, value: 'Circularity'},
    {key: 3, value: 'Material Health'},
    {key: REPORT_FORM_VALUES.RESIDUAL_VALUE_REPORT_TYPE, value: 'Financial residual value'}
];


export const BUILDING_CATEGORIES = [
    {key: REPORT_FORM_VALUES.COMMERCIAL, value: 'Commercial', active: true},
    {key: 5, value: 'Residential', active: true},
    {key: 6, value: 'Instutional', active: true },
    {key: 7, value: 'Personalised' , active: false,  tooltip: 'PERSONALISED_TOOLTIP'}
   ];





