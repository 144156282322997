<div #filterFamily class="passportlist__family-wrapper">
  <div *ngIf="isOpen" class="passportlist__info-rating-filter">
    <div class="passportlist__info-rating-filter-row passportlist__info-rating-filter-row-white passportlist__info-rating-filter-row-select">
      <md-checkbox (ngModelChange)="onAllSelectedChange()" [(ngModel)]="allSelected">
        {{'select all' | translate}}
      </md-checkbox>
    </div>
    <ng-container *ngFor="let filterItem of filterList;">
      <div class="passportlist__info-rating-filter-row passportlist__info-rating-filter-row-white passportlist__info-rating-filter-row-select">
        <md-checkbox [(ngModel)]="filterItem.value" (change)="onChange()">
          <div>{{filterItem.name | translate }}</div>
        </md-checkbox>
      </div>
    </ng-container>
  </div>

  <div>
    
  </div>

  <md-input-container
    style="width: 100%;"
    class="passportlist__search-materials"
    [floatPlaceholder]="'never'"
    >
    <input mdInput
          autocomplete="off"
          class="passportlist__no-caret"
          placeholder="{{ 'SEARCH_BY_FAMILY' | translate }}"
          maxlength="0"
          (click)="toggleView()"
          style="margin-bottom: 4px;"
         >
          <md-chip-list>
            <md-basic-chip *ngFor="let tag of familySelected" (focus)="false">
              {{tag.short | translate | capitalize }}
              <button (click)="removeCategoryTag(tag, $event)" class="delete"></button>
            </md-basic-chip>
          </md-chip-list>
          <div class="passportlist__info-icons"  >
              <span class="mat-select-arrow"></span>
            </div>
  </md-input-container>
</div>