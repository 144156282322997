import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';
import { UserService } from '../../../../shared/services/user.service';
import { MdDialog } from '@angular/material';
import { EsmNeedMatchDialogComponent } from '../../dialogs/esm-need-match-dialog/esm-need-match-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { TranslateService } from '@ngx-translate/core';
import { NeedService } from '../../../../entities/need/services/need.service';
import { Ecosystem } from '../../models/ecosystem.class';
import { Router } from '@angular/router';
import { EsmNeedPickerDialogComponent } from '../../dialogs/esm-need-picker-dialog/esm-need-picker-dialog.component';
var EcosystemPrimaryHeaderComponent = /** @class */ (function () {
    function EcosystemPrimaryHeaderComponent(_userService, _zone, _dialog, translateService, _needService, _router) {
        var _this = this;
        this._userService = _userService;
        this._zone = _zone;
        this._dialog = _dialog;
        this.translateService = translateService;
        this._needService = _needService;
        this._router = _router;
        this._userService.getRoleCounters({ role: ERoleIds.ECOSYSTEM_MANAGER }).subscribe(function (res) {
            _this.ecosystemsInProcessCount = Number(res.in_process);
            _this.offerCount = Number(res.new_offers);
        });
    }
    EcosystemPrimaryHeaderComponent.prototype.openEcosystemPicker = function () {
        var _this = this;
        this._zone.run(function () {
            _this._needService
                .get({
                expand: 'client,passport,client_contact',
                supervisor: true,
                esm_view: true,
            }).subscribe(function (records) {
                var dialogRef = _this._dialog.open(EsmNeedPickerDialogComponent, {
                    data: {
                        records: records
                    }
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result) {
                        _this.createEcosystem(result);
                    }
                });
            });
        });
    };
    EcosystemPrimaryHeaderComponent.prototype.createEcosystem = function (record) {
        var _this = this;
        new Ecosystem({
            name: this.translateService.instant('For') + " " + record.name,
            need_id: record.id,
        }).save()
            .subscribe(function (ecosystem) { return _this._router.navigate(['ecosystem/ecosystems', ecosystem.id]); });
    };
    EcosystemPrimaryHeaderComponent.prototype.openNeedMatchDialogComponent = function () {
        var _this = this;
        this._zone.run(function () {
            return _this._dialog.open(EsmNeedMatchDialogComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY));
        });
    };
    EcosystemPrimaryHeaderComponent.prototype.getLangDependentImage = function () {
        switch (this.translateService.currentLang) {
            case 'en': {
                return 'assets/svg/intelligence-artificielle-button-flip-EN.svg';
            }
            case 'fr': {
                return 'assets/svg/intelligence-artificielle-button-flip-FR.svg';
            }
            case 'es': {
                return 'assets/svg/intelligence-artificielle-button-flip-ES.svg';
            }
            case 'pt': {
                return 'assets/svg/intelligence-artificielle-button-flip-PORT.svg';
            }
            default: {
                return 'assets/svg/intelligence-artificielle-button-flip-EN.svg';
            }
        }
    };
    return EcosystemPrimaryHeaderComponent;
}());
export { EcosystemPrimaryHeaderComponent };
