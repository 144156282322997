/**
 * Created by Aleksandr C. on 8.02.17.
 */

import {Passport} from '../../passport/models/passport.class';
import {DepositLocation} from './deposit-location.class';
import {DepositService} from '../services/deposit.service';
import {DEPOSIT_STATUSES} from '../values/deposit-statuses.const';
import {DatePipe} from '@angular/common';
import {EventEmitter} from '@angular/core';
import * as _ from 'lodash';
import {ExtendedActiveRecord} from '../../../shared/models/extended-active-record.class';
import {DEPOSIT_CONDITIONS} from '../values/deposit-conditions.const';
import {IPlumbStepSource} from '../../../dashboards/ecosystem-manager/interfaces/plumb-step-source.interface';
import {ECOSYSTEM_STEP_TYPE} from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import {DepositStatus} from './deposit-status.class';
import {DepositPhoto} from './deposit-photo.class';
import {Material} from '../../passport/models/material.class';
import {ValidationService} from '../../../shared/services/validation-service';
import {TranslateService} from '@ngx-translate/core';
import {ServiceLocator} from '../../../shared/services/service-locator';
import {DEMOUNTABILITY_STATES} from '../values/demountability-states.const';
import {DepositTag} from './deposit-tag.class';
import {DepositTagLocationJunction} from './deposit-tag-location-junction.class';
import {EDepositReuseSource} from '../values/deposit-reuse-source.const';
import {DepositFile} from './deposit-file.class';


export class Deposit extends ExtendedActiveRecord implements IPlumbStepSource {
  protected provider = DepositService;
  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  public id: number;
  public name: string;
  public passport_id: number;
  public deposit_location_id: number;
  public quantity: number;
  public booked: number;
  public in_stock: number;
  public availability_date: string;
  public availability_expiration: string;
  public publication_date: string;
  public status: number;
  public condition: number;
  public creator_id: number;
  public owner_id: number;
  public owner_name: string;
  public supervisor_id: number;
  public description: string;
  public comments: string;
  public created_at: string;
  public updated_at: string;
  public active_step: number;
  public creator: string;
  public creator_email: string;
  public owner_email: string;
  public modification?: string;
  public passport_materials: Material[] = [];
  public material_names: { name: string }[];
  public deposit_demountability: number;
  public reused: number;
  public image: string;
  public logo: string;
  public thumbnail: string;
  public unit: number;
  public passport: Passport;
  public deposit_location: DepositLocation;
  public deposit_photos: DepositPhoto[];
  public deposit_file: DepositFile[];
  public location: DepositLocation;
  public tags: DepositTag[] = [];
  public plumb_type = ECOSYSTEM_STEP_TYPE.DEPOSIT;
  public deposit_source: EDepositReuseSource;
  public deposit_potential_source: EDepositReuseSource;
  public potential_reuse: number;
  public price: number;
  public url_link: string;
  public conversion: number;
  public product_comment: string;
  public length: number;
  public width: number;
  public height: number;
  public color: string;
  public isNoah: boolean;

  public onChangePassport: EventEmitter<Passport> = new EventEmitter();
  public onChangeLocation: EventEmitter<DepositLocation> = new EventEmitter();

  private progressStep1StrategyStrategy: () => any = this.defaultProgressStep1Strategy;
  private progressStep2StrategyStrategy: () => any = this.defaultProgressStep2Strategy;
  private progressStep3StrategyStrategy: () => any = this.defaultProgressStep3Strategy;

  constructor(data?: any) {
    super(data);
    data = data || {};
    data.passport = data.passport || {};
    this.passport = new Passport(Object.assign(data.passport || {}, {
      materials: data.passport_materials,
      components: data.passport_components,
      nomenclature: data.nomenclature,
      price: data.passport_price,
    }));
    this.deposit_location = this.location = new DepositLocation(data.deposit_location);
    this.deposit_photos = (data.deposit_photos || []).map((d: any) => new DepositPhoto(d));
    this.deposit_file = (data.deposit_file || []).map((d: any) => new DepositPhoto(d));
    this.booked = this.booked || 0;

    this.passport_materials = data.passport_materials;
    this.material_names = data.material_names;
    this.tags = data.tags;
  }

  protected fields() {
    return [
      'id',
      'name',
      'status',
      'booked',
      'condition',
      'creator_id',
      'owner_id',
      'owner_name',
      'supervisor_id',
      'passport_id',
      'passport',
      'deposit_location_id',
      'quantity',
      'in_stock',
      'availability_date',
      'availability_expiration',
      'publication_date',
      'description',
      'comments',
      'created_at',
      'updated_at',
      'active_step',
      'creator',
      'creator_email',
      'owner_email',
      'modification',
      'passport_materials',
      'passport_price',
      'material_names',
      'deposit_demountability',
      'reused',
      'image',
      'logo',
      'thumbnail',
      'deposit_source',
      'unit',
      'deposit_potential_source',
      'potential_reuse',
      'url_link',
      'product_comment',
      'length',
      'width',
      'height',
      'color',
      'price',
      'isNoah',
      'conversion',
      'deposit_file'
    ];
  }

  get availableQuantity() {
    return this.total - (this.booked || 0);
  }

  get total(): number {
    return this.quantity ;
  }

  setValidationStrategy(_this, step: 1 | 2 | 3, strategy: () => any) {
    const func = strategy.bind(_this);
    switch (step) {
      case 1: this.progressStep1StrategyStrategy = func; break;
      case 2: this.progressStep2StrategyStrategy = func; break;
      case 3: this.progressStep3StrategyStrategy = func; break;
    }
  }

  getProgress(): number {
    if (this.progressStep1StrategyStrategy()) {
      if (this.progressStep2StrategyStrategy()) {
        if (this.progressStep3StrategyStrategy()) {
          return 3;
        }
        return 2;
      }
      return 1;
    }
    return 0;
  }

  private defaultProgressStep1Strategy() {
    return this.passport_id > 0 && this.name;
  }

  private defaultProgressStep2Strategy() {
    return this.deposit_location_id > 0;
  }

  private defaultProgressStep3Strategy() {
    return (this.quantity > 0 || /^\d+([,|.]?\d+)?$/g.test(String(this.quantity)))
      && this.condition && this.availability_date;
  }


  get statusData(): DepositStatus {
    return _.values(DEPOSIT_STATUSES).find((STATUS: any) => STATUS.value === this.status);
  }

  setPassport(value: Passport | null) {
    if (value instanceof Passport) {
      this.passport = value;
      this.passport_id = value.id;
    } else {
      this.passport = new Passport();
      this.passport_id = null;
    }
    this.onChangePassport.emit(this.passport);
  }

  setLocation(value: DepositLocation | null) {
    if (value instanceof DepositLocation) {
      this.deposit_location = value;
      this.deposit_location_id = value.id;
    } else {
      this.deposit_location = new DepositLocation();
      this.deposit_location_id = null;
    }
    this.onChangeLocation.emit(this.deposit_location);
  }

  beforeSave(data: any): any {
    const dataValues = super.beforeSave(data);
    if (!!dataValues.availability_date && typeof dataValues.availability_date === 'object') {
      try {
        // dataValues.availability_date = new DatePipe(dataValues.availability_date)
       // default
       dataValues.availability_date = new DatePipe('en-US')
        .transform(dataValues.availability_date, 'yyyy-MM-dd');
        if (!!dataValues.availability_expiration && typeof dataValues.availability_expiration === 'object') {
          dataValues.availability_expiration = new DatePipe('en-US')
          .transform(dataValues.availability_expiration, 'yyyy-MM-dd');
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (dataValues.quantity) {
      dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
    }

    // if(typeof dataValues.quantity === 'string') {
    //   let parsedQuantity = LocaleService.tryParseToNumber(dataValues.quantity);
    //   if(typeof parsedQuantity === 'number') {
    //     dataValues.quantity = parsedQuantity;
    //   }
    // }

    return dataValues;
  }

  includesText(searchText: string) {
    searchText = searchText.toLocaleLowerCase();
    return _.includes(this.name.toLocaleLowerCase(), searchText);
  }

  get conditionData() {
    return DEPOSIT_CONDITIONS.find(cond => cond.value === this.condition) || DEPOSIT_CONDITIONS[0];
  }
  get demountabilityData() {
    return DEMOUNTABILITY_STATES.find(state => state.value === this.deposit_demountability) || DEMOUNTABILITY_STATES[0];
  }

  conditionDataView() {
    return this.translateService.instant(this.conditionData.view);
  }
  
  getConversion() {
    return this.conversion || this.passport.conversion;
  }
}
