/**
 * Created by aleksandr on 30.06.17.
 */
import { IChatUser } from './chat-user.interface';
import { INotificationEntry } from '../../notification/interfaces/notification-entry.interface';

export interface IChatMessage {
  id: number | string;
  roomId: number | string;
  author: IChatUser;
  type: EThreadType;
  isOwner: boolean;
  isRead: boolean;
  body: string;
  date: string;
  //TODO put file to body ?
  file: IChatMessageFile[];
}

export interface IChatMessageFile {
  id: number;
  file: string;
  message_id: number;
  basename: string;
}

//REST API MODEL
export interface IChatHistoryRecordContainer {
  target: string;
  data: IChatHistoryRecord;
}
export interface IChatHistoryRecord {
  id: number;
  user_id: number;
  thread_id: number | string;
  status: number;
  type: number | string;
  created_at: string;
  updated_at: string;
  content: {
    content: string;
    message_id: number;
  };
  file: any;
}

export interface INotificationEvent {
  type: number | string;
  notification: INotificationEntry;
}

export type TChatHistoryEvent = IChatHistoryRecord | INotificationEvent;

export enum EChatMessageTypes {
  TEXT = 0,
  MESSAGE = 1,
  NOTIFICATION = 2,
  FILE = 4
}

export enum EChatMessageStatus {
  NEW = 1,
  READ = 2
}

export enum EThreadType {
  TYPE_USER = 1,
  TYPE_ECOSYSTEM_OFFER = 2,
  TYPE_DEPOSIT_ORDER = 3,
  TYPE_TRANSFORMATION_ORDER = 4,
}

