/**
 * Created by Aleksandr C. on 10.02.17.
 */
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DepositService } from '../../services/deposit.service';
import { Deposit } from '../../models/deposit.class';
import { DepositStatus } from '../../models/deposit-status.class';
import { DEPOSIT_STATUSES } from '../../values/deposit-statuses.const';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MdDialog } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { DepositDeleteModalComponent } from '../../../../dashboards/agent/dialogs/deposit-delete-dialog/deposit-delete-modal.component';
import { DepositDeleteWarningComponent } from '../../../../dashboards/agent/dialogs/deposit-delete-warning/deposit-delete-warning.component';
// import {DepositImportDialogComponent} from '../../../../dashboards/agent/dialogs/deposit-import-dialog/deposit-import-dialog.component';
import { DepositImportService } from '../../services/deposit-import.service';
import { FilterStateService } from '../../../../shared/services/filterState.service';
import { ConditionEnum, FilterBy } from '../../../../shared/interfaces/filter-states.interface';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { DepositLocationService } from '../../services/deposit-location.service';
import { DepositLocation } from '../../models/deposit-location.class';
import { ViewCategoryDialogComponent } from '../../../passport/dialogs/view-category/view-category.component';
import { DepositFilter } from '../../models/deposit-filter';
import { PaginationUnmarshallingService } from '../../../../shared/services/pagination-unmarshalling.service';
import { DEPOSIT_SORT_NAME } from '../../values/deposit-sort.enum';
import { DepositDeleteAllModalComponent } from '../../../../dashboards/agent/dialogs/deposit-delete-all-dialog/deposit-delete-all-modal.component';
import { EDepositFilterStatusChangeType } from './deposit-filter-status/deposit-filter-status-change-type.const';
import { DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES, DEPOSIT_REUSE_SOURCE_VIEW } from '../../values/deposit-reuse-source.const';
import { cloneDeep, omit, values } from 'lodash';
import { PassportFamily } from '../../../passport/models/passport.class';
import { Subscription } from 'rxjs/Subscription';
import { DepositMassActionDialogComponent } from '../../dialogs/deposit-mass-action/deposit-mass-action-dialog.component';
// import {DepositCategoryFilter} from "../../pipes/deposit-category-filter.pipe";
@Component({
  moduleId: module.id,
  selector: 'deposit-list',
  templateUrl: 'deposit-list.component.html',
  styleUrls: ['deposit-list.component.css'],
})
export class DepositListComponent implements OnInit {
  public depositSourceList = DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES;

  public filterStatuses: DepositStatus[] = [
    DEPOSIT_STATUSES.DRAFT,
    DEPOSIT_STATUSES.WAITING_FOR_VALIDATION,
    DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION,
    DEPOSIT_STATUSES.ASK_FOR_MODIFICATION,
    DEPOSIT_STATUSES.PUBLISHED,
    DEPOSIT_STATUSES.REJECTED,
  ];

  public statusChangeType = EDepositFilterStatusChangeType;
  public sort_order = DEPOSIT_SORT_NAME;
  public records: Deposit[] = [];
  public checkedItems: Number[] = [];
  pageCheckCounter: number = 0;
  isAllPageMode: Boolean = false;
  depositFilter = new DepositFilter();
  // public isDeletable = false;
  isFilterStateOfUseVisible = false;
  sites: DepositLocation[] = [];
  filteredSites: any;
  searchSites = '';
  isAnyPassportRatingSelected = false;
  resetFilters = cloneDeep(omit(this.depositFilter, this.depositFilter.noneResetValues));
  isLoaded = false;
  subscription: Subscription;
  private _confirmDeletionText: string;

  constructor(private _dialog: MdDialog,
    private _router: Router,
    private _service: DepositService,
    private depositImportService: DepositImportService,
    private _translate: TranslateService,
    private router: Router,
    public stateService: FilterStateService,
    private navBarStateService: NavbarStateService,
    private sitesService: DepositLocationService,
    private paginationService: PaginationUnmarshallingService) {
    // console.info('Launch  -> DepositList');
    this._translate.get('CONFIRM_DELETE_DEPOSIT').subscribe((translation) => {
      this._confirmDeletionText = translation;
    });
  }

  ngOnInit() {
    if (this.stateService.depositList) {
      this.depositFilter = new DepositFilter(this.stateService.depositList);
    } else {
      this.startAllCheckboxes();
    }
    this.searchChange(false);
    this
      .depositFilter.searchControl
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => {
        // const shouldBeDeletable = this.depositFilter.searchControl.value !== ""
        this.searchChange();
      });

    this.sitesService
      .get({ expand: 'tags', sort: 'name', agent: 1, 'assigned' : 1 })
      .subscribe((data: DepositLocation[]) => {
        this.sites = data;
        this.filteredSites = data;
      });
    this.startAllCheckboxes(this.resetFilters as DepositFilter);
  }

  openRecord(record: Deposit) {
    this._router.navigate(['agent/deposits', record.id]);
  }

  duplicateRecord(record: Deposit) {
    record.duplicate().subscribe((res: Deposit) => {
      this.openRecord(res);
    });
  }

  deleteRecord(record: Deposit) {
    record.canDelete().flatMap((canDelete) => {
      if (canDelete) {
        return this._dialog.open(ConfirmDialogComponent, {
          data: { text: this._confirmDeletionText }
        }).afterClosed();
      } else {
        return Observable.of(false);
      }
    }).flatMap((confirmed) => {
      return confirmed ? record.destroy().map(() => true) : Observable.of(false);
    }).subscribe((deleted) => {
      if (deleted) {
        this.records.splice(this.records.indexOf(record), 1);
      }
    });
  }

  searchChange(deletable = true, isSortAction = false) {
    if (!isSortAction) {
      this.resetCheckedItems();
      this.resetAllPageMode();
    }
    this.updateRecords();
  }
  

  pageChange(page: number) {
    this.depositFilter.currentPage = page;
    this.updateRecords();
  }

  buildOptions() {
    let options = this.depositFilter.buildOptions();
    options = this.depositFilter.buildRating(options);
    if (this.depositFilter.searchStatus) {
      const draftIndex = this.depositFilter.searchStatus.indexOf(DEPOSIT_STATUSES.DRAFT.value);
      if ((!!this.depositFilter.searchStatus.length && (draftIndex === -1)) || this.depositFilter.isOutOfStockSelected) {
        options.push(['instock', Number(!this.depositFilter.isOutOfStockSelected)]);
      }
      this.depositFilter.searchStatus.forEach(stat => options.push(['status[]', stat]));
    }
    if (this.depositFilter.isOutOfStockSelected) {
      options.push(['out_of_stock',true]);
    }
    if (this.navBarStateService.multilanguage) {
      options.push(['english', true]);
    }
    if (this.isDeletable) {
      this.stateService.depositList = this.depositFilter;
    }
    return options;
  }

  buildDeleteBodyRequest() {
    return {
      search: this.depositFilter.searchControl.value,
      status: this.depositFilter.searchStatus,
      condition: Object.entries(this.depositFilter.stateOfUse).filter(z => z[1]).map(s => ConditionEnum[s[0]]),
      sites: this.depositFilter.selectedSites.map(s => s.id),
      tags: this.depositFilter.selectedTags.map(s => s.id),
      categories: this.depositFilter.categoryTags.map(c => c.id),
      instock: Number(!this.depositFilter.isOutOfStockSelected),
      available_to: this.depositFilter.availabilityDateTo,
      available_from: this.depositFilter.availabilityDateFrom,
      all_selected: this.isAllPageMode,
      deposits_ids: this.checkedItems
    };
  }

  updateRecords() {
    const options = this.buildOptions();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.depositFilter.searchStatus.length > 0) {
      this.isLoaded = false;
      this.subscription = this._service.getWithArray(options).subscribe((res) => {
        this.depositFilter.updateRecords(res, this.paginationService.unmarshall(res));
        this.records = this.depositFilter.records.map((item: any) => new Deposit(item));
        console.log('New records comes', this.records.map((r) => r.id).join(','));
        this.pageCheckCounter = this.countCheckedItems(this.records);
        this.isLoaded = true;
        // if (this.depositFilter.totalCount === 0) { this.isDeletable = false; }
      }, () => this.isLoaded = true);
    } else {
      this.isLoaded = true;
      this.records = [];
      this.depositFilter.currentPage = 1;
      this.depositFilter.totalCount = 0;
      this.depositFilter.perPage = 1;
      // this.isDeletable = false;
    }
  }

  public isDeletableStatus($event: EDepositFilterStatusChangeType) {
    return $event === this.statusChangeType.ALL_SELECTED ? this.depositFilter.isAllSelected : undefined;
  }

  private startAllCheckboxes(filter: { searchStatus: number[], isOutOfStockSelected: boolean, isAllSelected: boolean } = this.depositFilter) {
    filter.searchStatus = this.filterStatuses.map((status) => status.value);
    filter.isOutOfStockSelected = false;
    filter.isAllSelected = false;
  }

  /**
   * Ask and open the popup to delete many deposits
   */
  askForDepositDeleteModal() {
    this._dialog.open(DepositDeleteModalComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
      data: {
        count: this.getCheckedItemsNumber(),
        options: this.buildDeleteBodyRequest()
      },
    }).afterClosed().subscribe((data) => {
      if (data.deposits && data.deposits.length > 0) {
        this.depositsNotDeletedInfoModal(data.deposits);
      }
      this.updateRecords();
      if (data.resetSelection) {
        this.resetCheckedItems();
        this.resetAllPageMode();
      }
    });
  }

  depositsNotDeletedInfoModal(deposits: Deposit[]) {
    this._dialog.open(DepositDeleteWarningComponent, {
      ...DIALOG_THEME.WIDE_PRIMARY,
      data: { deposits: deposits },
    });
  }

  importDeposits() {

    this._router.navigate(['agent/deposits/import']);

    // TODO delete this commented setion
    // this._dialog.open(DepositImportDialogComponent, {
    //   ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
    //   data: {},
    // }).afterClosed().subscribe((imported: boolean) => {
    //   if (imported) {
    //    setTimeout(() => {
    //      this.searchChange(this.isDeletable);
    //    }, 1000);
    //   }
    //   });
  }

  removeSite(site: DepositLocation) {
    this.depositFilter.selectedSites = this.depositFilter.selectedSites.filter(d => d.id !== site.id);
    this.filteredSites = this.filterSelectedSites(this.sites);
    this.searchChange();
  }

  get canClearFiltersAlternativeRules() {
    return {
      filterBy: {
        C2cCertified: true,
        composition: { color: true, black: true },
        cycle: { color: true, black: true },
        energy: { color: true, black: true },
        water: { color: true, black: true },
        social: { color: true, black: true },
        passportWithoutRating: true,
        selectRatingFilter: true,
      },
      searchControl: ''
    };
  }

  clearFilters($event) {
    console.log($event);
    this.depositFilter = Object.assign(this.depositFilter, $event);
    this.searchChange();
  }

  selectedSite(site: DepositLocation) {
    this.searchSites = '';
    this.depositFilter.selectedSites.push(site);
    this.filteredSites = this.filterSelectedSites(this.sites);
    this.searchChange();
  }

  filterSelectedSites(list) {
    return list.filter(site => !this.depositFilter.selectedSites.some(selected => site.id === selected.id));
  }

  get isAnyStateOfUseFilterApplied() {
    return Object.keys(this.depositFilter.stateOfUse).some(key => !this.depositFilter.stateOfUse[key]);
  }

  openCategoryDialog(categorie: any): void {
    categorie.blur();
    const dialogRef = this._dialog.open(ViewCategoryDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.depositFilter.categoryTags.push(result);
        this.searchChange();
      }
    });
  }
  removeCategoryTag(tag: any, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.depositFilter.categoryTags.splice(this.depositFilter.categoryTags.indexOf(tag), 1);
    this.searchChange();
  }
  get categoryPlaceholder() {
    return this.depositFilter.categoryTags.length === 0 ? 'Product category' : '';
  }

  newDeposit() {
    this._router.navigate(['agent/deposits/new']);
  }

  deleteAllDeposits() {
    this.openAllDepositDeletionModal();
  }

  openAllDepositDeletionModal() {
    this._dialog.open(DepositDeleteAllModalComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
    }).afterClosed().subscribe((deposits: Deposit[]) => {
      if (deposits && deposits.length > 0) {
        this.depositsNotDeletedInfoModal(deposits);
      }
      this.updateRecords();
    });
  }

  get isDeletable() {
    return (this.depositFilter.searchControl.value && this.depositFilter.searchControl.value !== "")
      || this.depositFilter.availabilityDateTo !== ""
      || this.depositFilter.availabilityDateFrom !== ""
      || this.depositFilter.categoryTags.length !== 0
      || Object.values(this.depositFilter.stateOfUse).reduce((acc, curr) => curr || acc, false)
      || this.depositFilter.selectedSites.length !== 0
      ||
      !(this.depositFilter.searchStatus.length === this.filterStatuses.length
        && this.filterStatuses.every(el => this.depositFilter.searchStatus.includes(el.value)))
      || this.depositFilter.isOutOfStockSelected
      || this.depositFilter.reuseSource.length
      || this.isAnyPassportRatingSelected;
  }

  /**
   * Toggle the checked status of a deposit by adding it or removing it from the checked list
   * If we are in all page mode, we add it to the list if the item is getting unchecked
   * Else, we add it to the list if the item is getting checked
   * @param {Number} id - The id of the element to toggle
   */
  toggleChecked(id: Number) {
    let isChecked = this.isChecked(id);
    if (!this.isAllPageMode && isChecked || this.isAllPageMode && !isChecked) {
      this.removeIdFromChecklist(id);
    } else {
      this.addIdToChecklist(id);
    }
  }

  /**
   * Check whether there is at least 1 element checked, but not all
   * @returns {Boolean} True if the amount of checked elements is between 1 and the number of elements on the page
   */
  isPartiallyChecked() {
    return this.pageCheckCounter > 0 && this.pageCheckCounter < this.records.length;
  }

  /**
   * Check whether all records are checked in the page or not
   * If we are in all page mode, check if no elements are in the list
   * Else, check if all elements of the page are in the list
   * @returns {Boolean}
   */
  isAllChecked() {
    return this.isAllPageMode
      ? this.pageCheckCounter === 0
      : this.pageCheckCounter === this.records.length && this.records.length !== 0;
  }

  /**
   * Add the id of a record to the checklist
   * @param {Number} id - The id of the deposit to add
   */
  addIdToChecklist(id) {
    this.checkedItems.push(id);
    this.pageCheckCounter++;
  }

  /**
   * Remove the id of a record from a checklist
   * @param {Number} id - The id of the deposit to remove from the list
   */
  removeIdFromChecklist(id) {
    this.checkedItems.splice(this.checkedItems.indexOf(id), 1);
    this.pageCheckCounter--;
  }

  /**
   * Handle the action when user clicks on the check all button
   * - If all the records have been checked on the page
   *   x If the all page mode is activated, add all the records present on the page from the checklist
   *   x If the all page mode is deactivated, remove all the records present on the page from the checklist
   * - If not all the records have been checked on the page
   *   x If the all page mode is activated, remove all the ids not yet checked to the checklist
   *   x If the all page mode is deactivated, add all the ids not yet checked to the checklist
   */
  toggleCheckAll() {
    if (this.isAllChecked()) {
      this.records.forEach(record => {
        this.isAllPageMode ? this.addIdToChecklist(record.id) : this.removeIdFromChecklist(record.id);
      })
    } else {
      this.records.forEach(record => {
        if (!this.isChecked(record.id)) {
          this.isAllPageMode ? this.removeIdFromChecklist(record.id) : this.addIdToChecklist(record.id);
        }
      })
    }
  }

  /**
   * Count how many items from the record list are already present in the checked list
   * @param {Array<>} records - The list of records to check
   * @return {number} The number of records which have already been checked
   */
  countCheckedItems(records) {
    return records.filter(record => this.checkedItems.includes(record.id)).length;
  }

  /**
   * Check whether a deposit with a given id is checked or not
   * If all page mode is activated, an item is checked if it is not part of the checkedItems list
   * If all page mode is deactivated, an item is checked if it is part of the checkedItems list
   * @param {Number} id - The id of the element to check
   * @returns {Boolean} true if the element is checked, false otherwise
   */
  isChecked(id: Number) {
    let isItemInList = this.checkedItems.includes(id);
    return !this.isAllPageMode && isItemInList || this.isAllPageMode && !isItemInList;
  }

  /**
   * Reset the list of checked items by setting it to an empty list and thus resetting the counter
   */
  resetCheckedItems() {
    this.checkedItems = [];
    this.pageCheckCounter = 0;
  }

  /**
   * Reset all page mode and set it to false
   */
  resetAllPageMode() {
    this.toggleAllPageMode(false);
  }

  /**
   * Check if at least one item has been checked
   * @returns {Boolean}
   */
  areSomeItemChecked() {
    return !this.isAllPageMode && this.checkedItems.length !== 0 || this.isAllPageMode && this.checkedItems.length !== this.depositFilter.totalCount;
  }

  /**
   * Check whether we should display the check all header
   * We display the header if at least one element has been checked
   * @returns {Boolean}
   */
  shouldDisplayCheckAllHeader() {
    return this.areSomeItemChecked();
  }

  /**
   * Check whether we should display the check all message or not
   * We display the message if not all elements have been checked
   * @returns {Boolean}
   */
  shouldDisplayCheckAllMessage() {
    return !this.isAllPageMode && this.checkedItems.length !== this.depositFilter.totalCount || this.isAllPageMode && this.checkedItems.length !== 0;
  }

  /**
   * Get the first part of the check all message
   * This corresponds to the number of items currently selected
   * @returns {String}
   */
  getSelectedDepositsMessage() {
    let numberOfCheckedItems = this.getCheckedItemsNumber();
    return this._translate.instant("NUMBER_OF_DEPOSITS_MESSAGE", { X: numberOfCheckedItems, Y: numberOfCheckedItems > 1 ? "s" : "" });
  }

  /**
   * Get the amount of items checked
   * @returns {Number}
   */
  getCheckedItemsNumber() {
    return this.isAllPageMode
      ? this.depositFilter.totalCount - this.checkedItems.length
      : this.checkedItems.length;
  }

  /**
   * Get the second part of the check all message
   * This corresponds to the number of items available in the current search result
   * @returns {String}
   */
  getSelectAllMessage() {
    return this._translate.instant("SELECT_ALL_DEPOSITS_MESSAGE", { X: this.depositFilter.totalCount });
  }

  /**
   * Get the second part of the check all message
   * This corresponds to the number of items available in the current search result
   * @returns {String}
   */
  getDeselectAllMessage() {
    return this._translate.instant("DESELECT_ALL_DEPOSITS_MESSAGE", { X: this.depositFilter.totalCount });
  }

  /**
   * Activate the all page mode
   * Also reset the checked items
   */
  activateAllPageMode() {
    this.toggleAllPageMode(true);
    this.resetCheckedItems();
  }

  /**
   * Deactivate the all page mode
   * Also reset the checked items
   */
  deactivateAllPageMode() {
    this.toggleAllPageMode(false);
    this.resetCheckedItems();
  }

  /**
   * Toggle the all page mode
   * If the state parameter is passed, set the all page mode to the desired state
   * Else, toggle it (false becomes true, and true becomes false)
   * @param {Boolean} state - State we want to force the all page mode into
   * @returns {undefined}
   */
  toggleAllPageMode(state) {
    if (state !== null) {
      this.isAllPageMode = state;
      return;
    }

    this.isAllPageMode = this.isAllPageMode ? false : true;
  }

  /**
   * Check if we should deactivate mass modification buttons or not
   * We should deactivate mass modification buttons if there are less than TWO deposits selected
   * @returns {Boolean}
   */
  isMassModificationDisabled() {
    return this.isAllPageMode
      ? this.depositFilter.totalCount - this.checkedItems.length < 2
      : this.checkedItems.length < 2;
  }

  /**
   * Open the mass update modal
   */
  openMassUpdateModal() {
    this.openMassActionModal(true);
  }

  /**
   * Open the quick update modal
   */
  openQuickUpdateModal() {
    this.openMassActionModal(false);
  }

  /**
   * Open the mass action modal
   * @param {Boolean} isMassUpdate - True if we want the mass update modal, false if we want the quick update modal
   */
  openMassActionModal(isMassUpdate) {

    const dialogRef = this._dialog.open(DepositMassActionDialogComponent, {
      ...DIALOG_THEME.WIDE_PRIMARY,
      data: {
        filter: this.depositFilter,
        isMassUpdate: isMassUpdate,
        isAllSelected: this.isAllPageMode,
        depositsIds: this.checkedItems
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updateRecords();
    });

  }

}
