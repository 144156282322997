import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { DepositTagLocationJunctionService } from '../services/deposit-tag-location-junction.service';
var DepositTagLocationJunction = /** @class */ (function (_super) {
    tslib_1.__extends(DepositTagLocationJunction, _super);
    function DepositTagLocationJunction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = DepositTagLocationJunctionService;
        return _this;
    }
    DepositTagLocationJunction.prototype.fields = function () {
        return ['id', 'tag_id', 'deposit_id', 'name'];
    };
    return DepositTagLocationJunction;
}(ActiveRecord));
export { DepositTagLocationJunction };
