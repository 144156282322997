import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { VirginProductService } from './services/virgin-product.service';
import { PassportModule } from '../passport/passport.module';
import { ProductSectorService } from './services/product-sector.service';
import { VPDetailsDialogComponent } from './dialogs/vp-details-dialog.component';
import { VirginProductDetailsComponent } from './partials/virgin-product-details/virgin-product-details.component';
import { VirginContactListService } from './services/virgin-contact-list.service';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    SharedModule,
    PassportModule,
    CommonModule
  ],
  declarations: [
    VPDetailsDialogComponent,
    VirginProductDetailsComponent,
  ],
  exports: [],
  entryComponents: [
    VPDetailsDialogComponent,
  ],
  providers: [
    VirginProductService,
    ProductSectorService,
    VirginContactListService,
  ],
})
export class VirginProductModule {
}
