/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewNomenclatureDialogComponent } from './dialogs/view-nomenclature/view-nomenclature.component';
import { CopyPassportDialogComponent } from './dialogs/copy-passport-dialog/copy-passport-dialog.component';

import { PassportService } from './services/passport.service';
import { PassportFileService } from './services/passport-file.service';
import { ComponentService } from './services/component.service';
import { MaterialService } from './services/material.service';
import { PassportFormatService } from './services/format.service';
import { SearchPassportComponent } from './partials/search-passport/search-passport.component';
import { ViewPassportComponent } from './partials/view-passport/view-passport.component';
import {SupervisorWarningDialogComponent} from './dialogs/supervisor-warning-dialog/supervisor-warning-dialog.component';

import { ClientModule } from '../client/client.module';
import { EditPassportComponent } from './partials/edit-passport/edit-passport.component';
import { NomenclatureModule } from '../nomenclature/nomenclature.module';
import { SharedModule } from '../../shared/shared.module';
import { PassportListComponent } from './partials/passport-list/passport-list.component';
import { PassportItemComponent } from './partials/passport-list/passport-item/passport-item.component';
import { PassportEditComponent } from './partials/passport-edit/passport-edit.component';
import { PassportComponentsComponent } from './partials/passport-components/passport-components.component';
import { PassportMaterialsComponent } from './partials/passport-materials/passport-materials.component';
import { Ng2PaginationModule } from 'ng2-pagination';
import { ViewPassportDialogComponent } from './dialogs/view-passport-dialog/view-passport-dialog.component';
import { PassportResolver } from './resolvers/passport.resolver';
import { PassportNextUsageComponent } from './partials/passport-next-usage/passport-next-usage.component';
import { NextUsageService } from './services/next-usage.service';
import { PassportKeywordsComponent } from './partials/passport-keywords/passport-keywords.component';
import { PassportKeywordJunctionService } from './services/passport-keyword-junction.service';
import { PassportKeywordService } from './services/keyword.service';
import { PassportDetailsViewComponent } from './partials/passport-details-view/passport-details-view.component';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import {PassportModificationDialogComponent} from './dialogs/passport-modification-dialog/passport-modification-dialog.component';
import {PassportEditV2Component} from './partials/passport-edit-v2/passport-edit-v2.component';
import {EditPassportV2Component} from './partials/edit-passport-v2/edit-passport-v2.component';
import {UsedEnergyService} from './services/used-energy.service';
import {PassportUsedEnergyComponent} from './partials/passport-used-energy/passport-used-energy.component';
import {ConfirmC2cDialogComponent} from './dialogs/confirm-c2c-dialog/confirm-c2c-dialog.component';
import {NameGenerationDialogComponent} from './dialogs/name-generation-dialog/name-generation-dialog.component';
import {ManufacturersService} from './services/manufacturers.service';
import {PassportManufacturersComponent} from './partials/passport-manufacturers/passport-manufacturers.component';
import {PassportColorsComponent} from './partials/passport-colors/passport-colors.component';
import {ColorsService} from './services/colors.service';
import {PassportRatingExplanationDialogComponent} from './dialogs/passport-rating-explanation-dialog/passport-rating-explanation-dialog';
import {PassportRatingComponent} from './partials/passport-rating/passport-rating.component';
import {AdvancedResearchDialogComponent} from './dialogs/advanced-research-dialog/advanced-research-dialog.component';
import {VirtualScrollModule} from 'angular2-virtual-scroll';
import {PassportPreviewItemComponent} from './partials/passport-list/passport-preview-item/passport-preview-item.component';
import { ViewCategoryDialogComponent } from './dialogs/view-category/view-category.component';
import { CategoryModule } from '../category/category.module';
import {DistributionAreaService} from './services/distribution-area.service';
import { PassportDistributionAreaComponent } from './partials/passport-distribution-area/passport-distribution-area.component';
import {TranslationService} from '../../shared/services/translation.service';
import { ValidationErrorsToMessage } from './pipes/validation-errors-to-message.pipe';
import { PassportValidationNotificationService } from './services/passport-validation-notification.service';
import { NewPassportVersionDialog } from './dialogs/new-passport-version-dialog/new-passport-version-dialog.component';
import { NewPassportPublicationComponent } from './dialogs/new-passport-publication-dialog/new-passport-publication.component';
import {
  PassportSocialPerformanceComponent
} from './partials/edit-passport-v2/passport-social-performance/passport-social-performance.component';
import { ManufacturerGenericComponent } from './dialogs/manufacturer-generic-dialog/manufacturer-generic-dialog.component';
import { PassportFamilyLabel } from './partials/edit-passport-v2/passport-family-label/passport-family-label.component';
import { PassportFamilyViewLabel } from './partials/edit-passport-v2/passport-family-view-label/passport-family-view-label.component';
import { PassportFamilyInfoComponent } from './partials/passport-list/passport-family-info/passport-family-info.component';
import { FamilyFilterComponent } from './partials/passport-list/filters/family-filter/family-filter.component';
import { PassportMultipleFieldInputComponent } from './partials/passport-multi-field-input/passport-multiple-field-input.component';
import { RatingFilterComponent } from './partials/passport-list/filters/rating-filter/rating-filter.component';
import { PassportRatingDisplayComponent } from './partials/passport-rating-display/passport-rating-display.component';
import { PassportInfoDisplayComponent } from './partials/passport-info-display/passport-info-display.component';
import { PassportCatalogNameComponent } from './partials/edit-passport-v2/passport-general/passport-catalog-name/passport-catalog-name.component';
import {PassportConversionComponent} from './partials/edit-passport-v2/passport-conversion/passport-conversion.component';
import { PassportRatingWithLocalPassportComponent }
  from './partials/passport-list/filters/rating-filter/passport-rating-with-local-passport/passport-rating-with-local-passport.component';
import { PassportResetButtonComponent } from './partials/passport-list/passport-reset-button/passport-reset-button.component';
import { PassportCustomStoreService } from './services/passport-custom-store.service';
import { PassportAffiliateBranchComponent } from './partials/passport-affiliate-branch/passport-affiliate-branch.component';
import { AffiliateBranchService } from './services/affiliateBranch.service';
import { NewPassportItemComponent } from '../../app/components/passport/passport-Item/new-passport-item.component';
import { IconsModule } from '../../app/icons.module';
import { PassportCollectionComponent } from '../../app/components/passport/passport-Collection/passport-collection.component';
import { BaseComponentsModule } from '../../app/components/base/base-components.module';
import { GenericListInputComponent } from './partials/edit-passport-v2/passport-social-performance/generic-list-input.component';
import { DocumentTypeInputListComponent } from './partials/edit-passport-v2/passport-social-performance/document-type-input-list.component';
import { SourceInputListComponent } from './partials/edit-passport-v2/passport-social-performance/source-input-list.component';
import { OperatorInputListComponent } from './partials/edit-passport-v2/passport-social-performance/operator-input-list.component';
import { BaseDialogComponent } from '../../app/components/base/dialog/base-dialog.component';
import { PassportSynchronizationInformationComponent } from './partials/passport-edit-v2/passport-synchronization-information.component';
import { EvaluatePassportRatingComponent } from './partials/passport-rating/evaluate-passport-rating.component';
import { FilterComponentsModule } from '../../dashboards/manufacturer/components/filter.module';
import { CountryDataInputListComponent } from './partials/edit-passport-v2/passport-social-performance/country-data-input-list.component';
@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ClientModule,
    NomenclatureModule,
    CategoryModule,
    Ng2PaginationModule,
    LazyLoadImagesModule,
    VirtualScrollModule,
    IconsModule,
    BaseComponentsModule,
    FilterComponentsModule
],
  declarations: [
   
    SupervisorWarningDialogComponent,
    PassportItemComponent,
    NewPassportItemComponent,
    PassportPreviewItemComponent,
    PassportListComponent,
    PassportEditComponent,
    PassportEditV2Component,
    PassportRatingComponent,
    EvaluatePassportRatingComponent,
    EditPassportV2Component,
    SearchPassportComponent,
    EditPassportComponent,
    ViewNomenclatureDialogComponent,
    ViewCategoryDialogComponent,
    CopyPassportDialogComponent,
    ViewPassportComponent,
    PassportComponentsComponent,
    PassportMaterialsComponent,
    PassportNextUsageComponent,
    PassportAffiliateBranchComponent,
    PassportManufacturersComponent,
    PassportColorsComponent,
    PassportDistributionAreaComponent,
    PassportUsedEnergyComponent,
    PassportKeywordsComponent,
    PassportDetailsViewComponent,
    ValidationErrorsToMessage,
    ViewPassportDialogComponent,
    PassportModificationDialogComponent,
    PassportRatingExplanationDialogComponent,
    NameGenerationDialogComponent,
    AdvancedResearchDialogComponent,
    NewPassportVersionDialog,
    NewPassportPublicationComponent,
    PassportSocialPerformanceComponent,
    ManufacturerGenericComponent,
    PassportFamilyLabel,
    PassportFamilyViewLabel,
    PassportFamilyInfoComponent,
    FamilyFilterComponent,
    PassportMultipleFieldInputComponent,
    RatingFilterComponent,
    PassportRatingDisplayComponent,
    PassportInfoDisplayComponent,
    PassportCatalogNameComponent,
    PassportConversionComponent,
    PassportRatingWithLocalPassportComponent,
    PassportResetButtonComponent,
    PassportCollectionComponent,
    GenericListInputComponent,
    DocumentTypeInputListComponent,
    SourceInputListComponent,
    OperatorInputListComponent,
    CountryDataInputListComponent,
    PassportSynchronizationInformationComponent
  ],
  exports: [
    
    EditPassportComponent,
    EditPassportV2Component,
    PassportItemComponent,
    NewPassportItemComponent,
    PassportPreviewItemComponent,
    ViewPassportComponent,
    SearchPassportComponent,
    PassportComponentsComponent,
    PassportManufacturersComponent,
    PassportColorsComponent,
    PassportDistributionAreaComponent,
    PassportMaterialsComponent,
    PassportNextUsageComponent,
    PassportAffiliateBranchComponent,
    PassportUsedEnergyComponent,
    PassportKeywordsComponent,
    PassportDetailsViewComponent,
    ViewPassportDialogComponent,
    PassportListComponent,
    RatingFilterComponent,
    PassportConversionComponent,
    PassportRatingWithLocalPassportComponent,
    PassportResetButtonComponent,
    PassportMultipleFieldInputComponent,
    FamilyFilterComponent,
    PassportFamilyInfoComponent,
    PassportCollectionComponent,
    PassportRatingComponent,
    EvaluatePassportRatingComponent
  ],
  providers: [
    ValidationErrorsToMessage,
    PassportService,
    ComponentService,
    MaterialService,
    ManufacturersService,
    NextUsageService,
    AffiliateBranchService,
    ColorsService,
    UsedEnergyService,
    PassportFileService,
    PassportFormatService,
    PassportResolver,
    PassportKeywordService,
    PassportKeywordJunctionService,
    DistributionAreaService,
    TranslationService,
    PassportValidationNotificationService,
    PassportCustomStoreService
  ],
  entryComponents: [
    ViewNomenclatureDialogComponent,
    ViewCategoryDialogComponent,
    CopyPassportDialogComponent,
    PassportModificationDialogComponent,
    PassportRatingExplanationDialogComponent,
    NameGenerationDialogComponent,
    NewPassportVersionDialog,
    NewPassportPublicationComponent,
    AdvancedResearchDialogComponent,
    ConfirmC2cDialogComponent,
    PassportListComponent,
    ManufacturerGenericComponent,
    PassportFamilyLabel,
    PassportFamilyViewLabel,
    PassportFamilyInfoComponent,
    FamilyFilterComponent,
    PassportRatingDisplayComponent,
    PassportInfoDisplayComponent,
    PassportAffiliateBranchComponent,
    BaseDialogComponent
  ]
})
export class PassportModule {
}
