<article class="ecosystem-dashboard" test-id="esm-dashboard">
  <main class="ecosystem-dashboard__content">
    <ecosystem-primary-header [isMainPage]="true"></ecosystem-primary-header>
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--tabs">
      <ecosystem-catalog></ecosystem-catalog>
    </section>
  </main>
</article>


<!--
<div class="stats">
  <div class="stat">
    <div class="value">
      14
    </div>
    <div class="label">
      Active needs
    </div>
  </div>

  <div class="stat">
    <div class="value">
      3
    </div>
    <div class="label">
      Deposits matching with needs
    </div>
  </div>

  <div class="stat">
    <div class="value">
      12
    </div>
    <div class="label">
      Ecosystems in process
    </div>
  </div>
</div>

<div class="bottom">
  <div class="search" [formGroup]="form">
    <div class="params">
      <div class="text-research-export">
        <md-input-container class="text">
          <input mdInput formControlName="searchText">
        </md-input-container>
        <button md-raised-button class="research">Research</button>
        <button md-raised-button class="export">Export</button>
      </div>
      <div class="filters" formGroupName="filters">
        <md-checkbox formControlName="deposits">Deposits</md-checkbox>
        <md-checkbox formControlName="transformSites">Transformation sites</md-checkbox>
        <md-checkbox formControlName="needs">Customer needs</md-checkbox>
        <md-checkbox formControlName="ecosystems">Ecosystems</md-checkbox>
      </div>
      <md-select formControlName="period" class="periods">
        <md-option value="all">
          All periods
        </md-option>
      </md-select>
    </div>

    <agm-map
      class="map"
      [fitBounds]="mapBounds"
    >
      <agm-marker
        *ngFor="let need of results.needs"
        iconUrl="assets/png/map/orange-marker.png"
        [latitude]="need.client_location.lat"
        [longitude]="need.client_location.lng"
      >
        <agm-info-window>
          <div class="info center-text need">
            <div>
              Need:<br>
              Need QTY:<br>
              Need date:<br>
              Company:
            </div>
            <div>
              {{need.name}}<br>
              {{need.quantity}}{{need.passport.unit_text}}<br>
              {{need.delivery_date | timeLeftText}}<br>
              {{need.client.name}}
            </div>
          </div>
          <button class="create-ecosystem">Create ecosystem</button>
        </agm-info-window>
      </agm-marker>

      <agm-marker
        *ngFor="let deposit of results.deposits"
        iconUrl="assets/png/map/orange-marker.png"
        [latitude]="deposit.deposit_location.lat"
        [longitude]="deposit.deposit_location.lng"
      >
        <agm-info-window>
          <div class="info center-text deposit">
            <div>
              Deposit:<br>
              Quantity:<br>
              Disposable in:
            </div>
            <div>
              {{deposit.name}}<br>
              {{deposit.quantity}} pcs<br>
              {{deposit.availability_date | timeLeftText}}
            </div>
          </div>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>

  <div class="results needs" *ngIf="form.value.filters.needs">
    <h2 class="title">
      <ng-container *ngIf="form.value.searchText">LIST OF RESULTS FOR <em>{{form.value.searchText}}</em></ng-container>
      <ng-container *ngIf="!form.value.searchText">LIST OF NEEDS</ng-container>
    </h2>
    <ul [perfect-scrollbar]>
      <li *ngFor="let need of results.needs">
        <div class="info">
          <div class="center-text">
            <div>
              Need:<br>
              Need QTY:<br>
              Need date:<br>
              Company:
            </div>
            <div>
              {{need.name}}<br>
              {{need.quantity}}{{need.passport.unit_text}}<br>
              {{need.delivery_date | timeLeftText}}<br>
              {{need.client.name}}
            </div>
          </div>
          <div>
            --><!--
            Need fillable: <em class="green">100%</em><br>
            Deposits: <em class="red">25m²</em>
            --><!--
          </div>
          <img class="logo" [src]="need.client.image">
        </div>
        <div class="buttons">
          <button class="create-ecosystem" (click)="createEcosystem({need_id: need.id})">Create ecosystem</button>
          <button class="send-to-another-manager">Send to another manager</button>
          <button class="contact-client">Contact client</button>
        </div>
      </li>
    </ul>
  </div>

  <div class="results deposits" *ngIf="form.value.filters.deposits">
    <h2 class="title">
      <ng-container *ngIf="form.value.searchText">LIST OF RESULTS FOR <em>{{form.value.searchText}}</em></ng-container>
      <ng-container *ngIf="!form.value.searchText">LIST OF DEPOSITS</ng-container>
    </h2>
    <ul [perfect-scrollbar]>
      <li *ngFor="let deposit of results.deposits">
        <div class="info">
          <div class="center-text">
            <div>
              Deposit:<br>
              Quantity:<br>
              Disposable in:<br>
              Address
            </div>
            <div>
              {{deposit.name}}<br>
              {{deposit.quantity}} pcs<br>
              {{deposit.availability_date | timeLeftText}}<br>
              {{deposit.deposit_location.address}}
            </div>
          </div>
          <img class="logo" src="http://api.upc.wount.com/storage/1a84b324997342b9dcf39fcabad21ea13aa664ab9150c4fa418b9c64f2a283c5.png">
        </div>
        <div class="buttons">
          <button class="see-details">See details</button>
          <button class="contact-agent">Contact agent</button>
        </div>
      </li>
    </ul>
  </div>

  <div class="results ecosystems" *ngIf="form.value.filters.ecosystems">
    <h2 class="title">
      <ng-container *ngIf="form.value.searchText">LIST OF RESULTS FOR <em>{{form.value.searchText}}</em></ng-container>
      <ng-container *ngIf="!form.value.searchText">LIST OF ECOSYSTEMS</ng-container>
    </h2>
    <ul [perfect-scrollbar]>
      <li *ngFor="let ecosystem of results.ecosystems">
        <div class="info">
          Name: {{ecosystem.name}}
        </div>
        <div class="buttons">
          <button md-raised-button [routerLink]="['/ecosystem', ecosystem.id]">View</button>
        </div>
      </li>
    </ul>
  </div>
</div>
-->
