<div class="search" [formGroup]="form">
  <div class="text-date-container">
    <md-input-container class="text">
      <input
        mdInput
        [placeholder]="'Search anything' | translate"
        formControlName="searchText"
      >
    </md-input-container>
    <md-input-container class="date">
      <input
        mdInput
        autocomplete="off" 
        ng2-datetime-picker
        date-format="DD-MM-YYYY"
        parse-format="YYYY-MM-DD"
        date-only="true"
        [placeholder]="'Date of publication' | translate"
        formControlName="publicationDate"
      >
    </md-input-container>
  </div>

  <div class="filters-container">
    <h2 class="filters-title">{{'Type' | translate}}</h2>

    <div class="filters" formArrayName="filters">
      <md-checkbox
        *ngFor="let TYPE of FILTER_TYPES; let i = index;"
        [formControlName]="i"
      >{{TYPE.view | translate}}
      </md-checkbox>
    </div>
  </div>
</div>

<div class="results">
  <agm-map
    class="map"
    [styles]="mapStyles"
    [latitude]="59.4338802"
    [longitude]="24.7586738"
    [zoom]="13"
  >
  </agm-map>

  <div
    class="list"
    [class.ascending]="sortOrder > 0"
    [class.descending]="sortOrder < 0"
  >
    <h2 class="sort-by-title">{{'Sort by' | translate}}</h2>

    <div class="sort-by">
      <button
        *ngFor="let SORT_MODE of SORT_MODES"
        (click)="sort(SORT_MODE)"
        [class.selected]="SORT_MODE === selectedSortMode"
      >{{SORT_MODE.view | translate}}
      </button>
    </div>

    <ul [perfectScrollbar]>
      <li *ngFor="let record of results">
        <div>
          <span class="name">
            <ng-container *ngIf="record.name">{{record.name}}</ng-container>
            <ng-container *ngIf="record.name">({{'Unnamed' | translate}})</ng-container>
          </span><br>
          <span class="type">{{record.transformation_type_data.view | translate}}</span>
        </div>
        <div>
          <span class="created-text">{{'created' | translate}}:</span> {{record.created_at | date:'dd.MM.y HH:mm'}}<br>
        </div>
        <div>
          <span class="published-text">{{'published' | translate}}:</span> {{record.updated_at | date:'dd.MM.y HH:mm'}}
        </div>
      </li>
    </ul>
  </div>
</div>
