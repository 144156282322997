import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {PassportDistributionArea} from '../models/passport-distribution-area.class';

@Injectable()
export class DistributionAreaService extends CrudService {
  protected namespace = 'passport-distribution-area';
  protected namespaceSingular = 'passport-distribution-area';
  protected ModelClass = PassportDistributionArea;
}
