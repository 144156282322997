import * as tslib_1 from "tslib";
import { Observable } from 'rxjs/Observable';
import { Thread } from './thread.model';
import { ChatData } from './chat-data.class';
var ChatRoom = /** @class */ (function (_super) {
    tslib_1.__extends(ChatRoom, _super);
    function ChatRoom() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.messages = [];
        _this.contacts = [];
        _this.unreadMessages = 0;
        _this.isConnected = false;
        return _this;
    }
    ChatRoom.prototype.isActiveRoom = function () {
        return ChatData.activeRoom && ChatData.activeRoom.id === this.id;
    };
    ChatRoom.prototype.loadContacts = function () {
        var _this = this;
        return _super.prototype.loadContacts.call(this).do(function (contacts) { return _this.onLoadContacts(contacts); });
    };
    ChatRoom.prototype.readRoom = function () {
        var _this = this;
        return _super.prototype.readThread.call(this).do(function () { return _this.onReadThread(); });
    };
    ChatRoom.prototype.loadMessages = function () {
        var _this = this;
        return _super.prototype.loadMessages.call(this).do(function (messages) { return _this.onLoadMessages(messages); });
    };
    ChatRoom.prototype.sendMessage = function (text) {
        var _this = this;
        return _super.prototype.sendMessage.call(this, text).do(function (response) { return _this.onSendMessage(response); });
    };
    ChatRoom.prototype.setContacts = function (contacts) {
        this.contacts = contacts;
    };
    ChatRoom.prototype.getMessageAuthor = function (authorId) {
        var author = this.contacts.find(function (contact) { return contact.id === authorId; });
        if (this.service.ownerId === authorId) {
            //console.log('Author not found', authorId, this.contacts);
            author = {
                id: this.service.ownerId,
                image: (author) ? author.image : this.service.ownerAvatar,
                name: 'Me',
                isMe: true,
                new_messages: 0
            };
        }
        else {
            if (author) {
                author.isMe = false;
            }
            else {
                console.log('Cant detect who is authorId:', authorId, this.contacts);
            }
        }
        return author;
    };
    ChatRoom.prototype.connect = function () {
        if (this.isConnected) {
            return null;
        }
        console.log('ChatRoom id #', this.id, 'connecting ...');
        this.build();
        this.onConnect({});
        return null;
        // return this
        //   .build()
        //   .service
        //   .joinRoom(this.id)
        //   .do((response: IChatConnectResponse) => this.onConnect(response));
    };
    ChatRoom.prototype.disconnect = function () {
        console.log('ChatRoom id #', this.id, 'has been disconnected');
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }
        this.isConnected = true;
    };
    ChatRoom.prototype.checkIfAuthorExist = function (message) {
        var hasAuthor = Boolean(this.getMessageAuthor(message.authorId));
        return hasAuthor ? Observable.of(hasAuthor) : this.loadContacts();
    };
    ChatRoom.prototype.onConnect = function (response) {
        var _this = this;
        console.log('ChatRoom id #', this.id, 'has been connected');
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }
        this.messageSubscription = this
            .service
            .messageReceived$
            .filter(function (message) { return message.roomId === _this.id; })
            .do(function (message) { return console.log('Room id #', _this.id, 'incoming message:', message); })
            .flatMap(function (message) { return _this.checkIfAuthorExist(message).map(function () { return message; }); })
            .subscribe(function (message) { return _this.onMessageReceive(message); });
        this.isConnected = true;
    };
    ChatRoom.prototype.onMessageReceive = function (message) {
        var _this = this;
        message.setAuthor(this.getMessageAuthor(message.authorId));
        //TODO temp, for test
        if (message.file && message.file.length > 0) {
            message.body = 'FILE: ' + message.file[0].basename;
        }
        this.messages.push(message);
        if (this.isActiveRoom()) {
            this.readThread().subscribe(function () { return _this.scrollToBottom(); });
        }
        else if (!message.isOwner) {
            this.unreadMessages++;
        }
    };
    ChatRoom.prototype.onReadThread = function () {
        this.unreadMessages = 0;
        this.scrollToBottom();
    };
    ChatRoom.prototype.scrollToBottom = function () {
        var messageListRef = document.getElementById("messageList");
        console.log("label", messageListRef);
        console.dir("label", messageListRef);
        messageListRef.scrollTop = messageListRef.scrollHeight + 200;
    };
    ChatRoom.prototype.onLoadContacts = function (contacts) {
        this.setContacts(contacts);
    };
    ChatRoom.prototype.onLoadMessages = function (messages) {
        var _this = this;
        if (!messages) {
            messages = [];
        }
        this.messages.length = 0;
        messages.forEach(function (message) {
            message.setAuthor(_this.getMessageAuthor(message.authorId));
            //TODO temp, for test
            if (message.file && message.file.length > 0) {
                message.body = 'FILE: ' + message.file[0].basename;
            }
            _this.messages.push(message);
            // if(this.messages.length  === messages.length) {
            //   this.scrollToBottom();
            // }
        });
        //this.messages = messages;
    };
    ChatRoom.prototype.onSendMessage = function (response) {
        // this
        //   .service
        //   .loadHistoryMessage(response.thread_id, response.id)
        //   .subscribe((message:IChatMessage) => {
        //     //this.messages.push(message);
        //   });
    };
    return ChatRoom;
}(Thread));
export { ChatRoom };
