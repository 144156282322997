/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-representativity-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./selectable-list-filter.component.ngfactory";
import * as i3 from "./selectable-list-filter.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/services/navigation.service";
import * as i6 from "./data-representativity-filter.component";
import * as i7 from "../../../shared/services/country.service";
var styles_DataRepresentativityFilterComponent = [i0.styles];
var RenderType_DataRepresentativityFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataRepresentativityFilterComponent, data: {} });
export { RenderType_DataRepresentativityFilterComponent as RenderType_DataRepresentativityFilterComponent };
export function View_DataRepresentativityFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "data-representativity-filter-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-selectable-list-filter", [], null, [[null, "selectionChange"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectionChange" === en)) {
        var pd_1 = (_co.handleSelectionChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SelectableListFilterComponent_0, i2.RenderType_SelectableListFilterComponent)), i1.ɵdid(2, 638976, null, 0, i3.SelectableListFilterComponent, [i4.TranslateService, i5.NavigationService], { selectedItems: [0, "selectedItems"], items: [1, "items"], placeholder: [2, "placeholder"] }, { selectionChange: "selectionChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.representativitySelected; var currVal_1 = _co.countries; var currVal_2 = "SEARCH_BY_REPRESENTATIVITY"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_DataRepresentativityFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-data-representativity-filter", [], null, null, null, View_DataRepresentativityFilterComponent_0, RenderType_DataRepresentativityFilterComponent)), i1.ɵdid(1, 114688, null, 0, i6.DataRepresentativityFilterComponent, [i7.CountryService, i5.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataRepresentativityFilterComponentNgFactory = i1.ɵccf("app-data-representativity-filter", i6.DataRepresentativityFilterComponent, View_DataRepresentativityFilterComponent_Host_0, { representativitySelected: "representativitySelected" }, { representativityChange: "representativityChange" }, []);
export { DataRepresentativityFilterComponentNgFactory as DataRepresentativityFilterComponentNgFactory };
