import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { NomenclatureService } from '../service/nomenclature.service';
import { TranslateService } from "@ngx-translate/core";
import { ServiceLocator } from '../../../shared/services/service-locator';
var NomenclatureItem = /** @class */ (function (_super) {
    tslib_1.__extends(NomenclatureItem, _super);
    function NomenclatureItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        _this.provider = NomenclatureService;
        _this.expanded = false;
        return _this;
    }
    NomenclatureItem.prototype.fields = function () {
        return ['id', 'name', 'parent_id', 'depth', 'selectable', 'has_custom', 'children'];
    };
    NomenclatureItem.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        if (json.name) {
            if (!this.translateService) {
                this.translateService = ServiceLocator.injector.get(TranslateService);
            }
            json.name = this.translateService.instant(json.name);
        }
        if (json.children) {
            var array = [];
            for (var _i = 0, _a = json.children; _i < _a.length; _i++) {
                var child = _a[_i];
                var newchild = new NomenclatureItem(child);
                array.push(newchild);
            }
            json.children = array;
        }
        return json;
    };
    NomenclatureItem.prototype.toggle = function () {
        this.expanded = !this.expanded;
    };
    return NomenclatureItem;
}(ActiveRecord));
export { NomenclatureItem };
