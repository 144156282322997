/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-passport-publication.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i4 from "@angular/material";
import * as i5 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i6 from "../../../../shared/read-only/read-only.service";
import * as i7 from "./new-passport-publication.component";
var styles_NewPassportPublicationComponent = [i0.styles];
var RenderType_NewPassportPublicationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewPassportPublicationComponent, data: {} });
export { RenderType_NewPassportPublicationComponent as RenderType_NewPassportPublicationComponent };
export function View_NewPassportPublicationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 35, "div", [["class", "new-passport-publication__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "new-passport-publication__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "name-generation-dialog__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icon icon--close_s"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "new-passport-publication__body flex justify-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "new-passport-publication__label flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "label", [["class", "form-group__label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "i", [["class", "form-group__label--question"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["!"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "new-passport-publication__text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 19, "div", [["class", "new-passport-publication__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 18, "div", [["class", "new-passport-publication__footer-button-group flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 8, "div", [["class", "new-passport-publication__footer-button-group-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 7, "button", [["md-button", ""], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close("new_version") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i1.ɵdid(20, 16384, null, 0, i4.MdPrefixRejector, [[2, i4.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(21, 180224, null, 0, i4.MdButton, [i1.ElementRef, i1.Renderer, i4.FocusOriginMonitor], null, null), i1.ɵdid(22, 16384, null, 0, i4.MdButtonCssMatStyler, [], null, null), i1.ɵdid(23, 16384, null, 0, i4.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(24, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null), (_l()(), i1.ɵted(25, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(27, 0, null, null, 8, "div", [["class", "new-passport-publication__footer-button-group-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 7, "button", [["md-button", ""], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close("edit") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i1.ɵdid(29, 16384, null, 0, i4.MdPrefixRejector, [[2, i4.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(30, 180224, null, 0, i4.MdButton, [i1.ElementRef, i1.Renderer, i4.FocusOriginMonitor], null, null), i1.ɵdid(31, 16384, null, 0, i4.MdButtonCssMatStyler, [], null, null), i1.ɵdid(32, 16384, null, 0, i4.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(33, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null), (_l()(), i1.ɵted(34, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("New publication")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("NEW_PUBLICATION_TEXT_INFO1")); _ck(_v, 14, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 21).disabled; var currVal_3 = true; var currVal_4 = true; var currVal_5 = ((i1.ɵnov(_v, 24).readOnlyService == null) ? null : i1.ɵnov(_v, 24).readOnlyService.readOnly); _ck(_v, 19, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 25, 0, i1.ɵnov(_v, 26).transform("Create a new version")); _ck(_v, 25, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 30).disabled; var currVal_8 = true; var currVal_9 = true; var currVal_10 = ((i1.ɵnov(_v, 33).readOnlyService == null) ? null : i1.ɵnov(_v, 33).readOnlyService.readOnly); _ck(_v, 28, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = i1.ɵunv(_v, 34, 0, i1.ɵnov(_v, 35).transform("Ok, edit the passport")); _ck(_v, 34, 0, currVal_11); }); }
export function View_NewPassportPublicationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "new-passport-publication-dialog", [["class", "new-passport-publication-dialog"]], null, null, null, View_NewPassportPublicationComponent_0, RenderType_NewPassportPublicationComponent)), i1.ɵdid(1, 49152, null, 0, i7.NewPassportPublicationComponent, [i4.MdDialogRef], null, null)], null, null); }
var NewPassportPublicationComponentNgFactory = i1.ɵccf("new-passport-publication-dialog", i7.NewPassportPublicationComponent, View_NewPassportPublicationComponent_Host_0, {}, {}, []);
export { NewPassportPublicationComponentNgFactory as NewPassportPublicationComponentNgFactory };
