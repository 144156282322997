/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  moduleId: module.id,
  selector: 'client-page',
  templateUrl: 'client-page.component.html'
})
export class ClientPageComponent implements OnInit, OnDestroy {

  clientId: number;

  constructor(private route: ActivatedRoute,
              private router: Router) {
    this.clientId = parseInt(this.route.snapshot.params['id'], 10);
  }

  ngOnInit() {
    console.log('ClientPageComponent -> onInit');
  }

  ngOnDestroy() {
    console.log('ClientPageComponent -> onDestroy');
  }

  onSave($event: any) {
    console.log('ClientPageComponent -> onSave');
  }
}
