
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

@Injectable()
export class MockApiService {

    MOCK_URL = "http://localhost:3001/"
    constructor(private http: Http) {

    }

    get(path) {
        return this.http.get(`${this.MOCK_URL}${path}`);
    }

    post(path, body) {
        return this.http.post(`${this.MOCK_URL}${path}`, body);
    }

    put(path, body) {
        return this.http.put(`${this.MOCK_URL}${path}`, body);
    }

}