
<div class="import-deposit-documents" [formGroup]="form">
  <div class="">
    <label class="import-deposit-documents__label">{{ 'FILE_SELECTION' | translate }}</label>
  </div>

    <div class="import-deposit-documents__label-sub">
        {{ 'Select the file to import' | translate}} :
    </div>


  <div class="">
    <div class="import-deposit-documents__import-container flex" tabindex="0">
      <documents-uploader
      class="import-deposit-documents__import"
      [files]="uploadedDepositFile"
      [uploadUrl]="depositImport.getFileUploadUrl()"
      [service]="depositFileService"
      [onUploadAll]="onUploadFile"
      [uploadUrlSet]="updateUploadUrl"
      (onAllItemsUploaded)="documentUploaded()"
      (onAfterAddingFile)="onFileAdded($event)"
      (onAfterQueueRemove)="onFileAdded($event)"
      [autoupload]="false"
      [isMultiple]="false"
      [acceptedFormats]="['xls', 'xlsx', 'ods']"
      [maxAllowedItems]="1"
      ></documents-uploader>

      <div class="deposit-import__attention primary-color border-primary-color">!</div>
      <span class="deposit-import__input--description import-deposit-documents__import-warning">
        {{'Format .xls, .xlsx, .ods' | translate}}
      </span>
    </div>
  </div>

  <div class=" deposit-import__row--top">
    <div class="inline-flex align-center deposit-import__input-wrapper">
      <md-input-container class="import-deposit-documents__input import-deposit-documents__input--large">
        <input
        autocomplete="off"
        formControlName="tab_name"
        placeholder="{{ 'Name of the tab to import' | translate }}"
        mdInput>
      </md-input-container>
    </div>
  </div>

  <div class="">
    <div class="inline-flex align-center deposit-import__input-wrapper">
      <md-input-container class="deposit-import__input--number import-deposit-documents__input">
        <input
        autocomplete="off"
        formControlName="first_row"
        placeholder="{{ 'No. of the first line to be imported' | translate }}"
        mdInput
        min="0"
        type="number"
        (keydown)="allowOnlyGreaterThanZeroNumbers($event)">
      </md-input-container>
    </div>
  </div>

  <div class="">
    <div class="inline-flex align-center deposit-import__input-wrapper">
      <md-input-container class="deposit-import__input--number import-deposit-documents__input">
        <input
        autocomplete="off"
        formControlName="last_row"
        placeholder="{{ 'No. of the last line to be imported' | translate }}"
        mdInput
        min="0"
        type="number"
        (keydown)="allowOnlyGreaterThanZeroNumbers($event)">
      </md-input-container>
    </div>
  </div>
  <ng-content></ng-content>
</div>
