<confirm-with-header
  title="{{'Request the creation of a new site' | translate}}"
  [disabled]="!form.valid || sender.isLoading || sender.isEmptyQueue"
  (onConfirm)="confirmRequest()"
  (onCancel)="closeDialog()">
  <section class="request-site-creation-dialog__description">
    <div class="request-site-creation-dialog__description-text">
      {{'Enter the details of the site you wish to create,' | translate}}
    </div>
    <div class="request-site-creation-dialog__description-text
      request-site-creation-dialog__description-text--sub">
        {{'For your application to be accepted' +
      ', you must attach supporting documents of the information entered' | translate}}
    </div>
  </section>
  <section class="request-site-creation-dialog__form" [formGroup]="form">
    <md-input-container class="request-site-creation-dialog__form-input">
      <input mdInput formControlName="name" placeholder="{{'Site name' | translate}}">
    </md-input-container>
    <md-input-container class="request-site-creation-dialog__form-input">
      <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
        <input
          mdInput
          formControlName="address"
          placeholder="{{ 'Address' | translate }}"
          placeAutocomplete
        >
      </place-autocomplete>
    </md-input-container>
    <div class="request-site-creation-dialog__form-container">
      <div class="request-site-creation-dialog__form-row-unit">
        <md-input-container class="request-site-creation-dialog__form-input
         request-site-creation-dialog__form-input--fixed-length">
          <input mdInput
                 formControlName="area"
                 placeholder="{{'Floor area' | translate}}">
        </md-input-container>
        <span class="request-site-creation-dialog__form-unit">{{'sqm' | translate}}</span>
      </div>
      <div class="request-site-creation-dialog__form-row-unit">
        <md-input-container class="request-site-creation-dialog__form-input
         request-site-creation-dialog__form-input--fixed-length">
          <input mdInput
                 formControlName="living_area"
                 placeholder="{{'Living area' | translate}}">
        </md-input-container>
        <span class="request-site-creation-dialog__form-unit">{{'sqm' | translate}}</span>
      </div>
    </div>
    <div class="request-site-creation-dialog__form-row">
      <md-select class="request-site-creation-dialog__form-select
       request-site-creation-dialog__form-input--fixed-length"
                 formControlName="status"
                 placeholder="{{'Status' | translate}}">
        <md-option *ngFor="let status of DEPOSIT_LOCATION_STATUS_OPTIONS"
                   [value]="status.value"
        >{{status.name | translate}}</md-option>
      </md-select>
      <md-input-container
        *ngIf="status === DEPOSIT_LOCATION_STATUS.IN_CONSTRUCTION"
        class="request-site-creation-dialog__form-input
       request-site-creation-dialog__form-input--fixed-length
       request-site-creation-dialog__form-input--sibling-select">
        <input mdInput
               autocomplete="off"
               ng2-datetime-picker
               formControlName="expire"
               date-format="DD-MM-YYYY"
               date-only="true"
               placeholder="{{'End of work' | translate}}">
      </md-input-container>
    </div>
    <div class="request-site-creation-dialog__form-uploader">
      <h2 class="request-site-creation-dialog__form-uploader-header">
        {{'Documents' | translate}}
        <i class="form-group__label--question request-site-creation-dialog__form-uploader-header-tooltip"
        md-tooltip="{{ 'Please send plans, plannings or signed certificates to justify the surface areas' +
         ', addresses and expiration dates of your building.' | translate}}">?</i></h2>
      <documents-uploader
        class="passport__uploader request-site-creation-dialog__form-uploader-list"
        [files]="sender.files"
        [service]="sender.service"
        [uploader]="sender.fileUploader"
        [enableQueueFileEdit]="true"
        [uploadUrl]="requestFileService.getUploadedFileUrl()"
        [autoupload]="false"
        [onUploadAll]="sender.onUploadAll"
        (onItemUploaded)="sender.onCompleteItem($event)"
        (onAllItemsUploaded)="sender.onAllItemsUploaded($event)"
      >
      </documents-uploader>
    </div>
  </section>
</confirm-with-header>
