import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { GenericPassportTag } from '../models/generic-passport-tag.class';
var GenericPassportTagService = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassportTagService, _super);
    function GenericPassportTagService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'generic-passport-tags';
        _this.namespaceSingular = 'generic-passport-tag';
        _this.ModelClass = GenericPassportTag;
        return _this;
    }
    GenericPassportTagService.prototype.deleteTag = function (tag_id) {
        return this.sendDelete(this.getEndpoint(this.singularEndpoint + '/' + tag_id)).map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    return GenericPassportTagService;
}(CrudService));
export { GenericPassportTagService };
