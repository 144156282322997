/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../passport-rating/passport-rating.component.ngfactory";
import * as i2 from "../passport-rating/passport-rating.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./passport-rating-display.component";
var styles_PassportRatingDisplayComponent = [];
var RenderType_PassportRatingDisplayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PassportRatingDisplayComponent, data: {} });
export { RenderType_PassportRatingDisplayComponent as RenderType_PassportRatingDisplayComponent };
export function View_PassportRatingDisplayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "passportlist__item-content-ratings"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "passport-rating", [], null, null, null, i1.View_PassportRatingComponent_0, i1.RenderType_PassportRatingComponent)), i0.ɵdid(2, 49152, null, 0, i2.PassportRatingComponent, [i3.TranslateService], { passport: [0, "passport"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.passport; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PassportRatingDisplayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "passport-rating-display", [], null, null, null, View_PassportRatingDisplayComponent_0, RenderType_PassportRatingDisplayComponent)), i0.ɵdid(1, 49152, null, 0, i4.PassportRatingDisplayComponent, [], null, null)], null, null); }
var PassportRatingDisplayComponentNgFactory = i0.ɵccf("passport-rating-display", i4.PassportRatingDisplayComponent, View_PassportRatingDisplayComponent_Host_0, { passport: "passport" }, {}, []);
export { PassportRatingDisplayComponentNgFactory as PassportRatingDisplayComponentNgFactory };
