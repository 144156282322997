import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AdminTransformCatalogComponent } from './components/admin-dashboard/admin-transform-catalog/admin-transform-catalog.component';
import { AdminNeedCatalogComponent } from './components/admin-dashboard/admin-need-catalog/admin-need-catalog.component';
import { AdminDepositCatalogComponent } from './components/admin-dashboard/admin-deposit-catalog/admin-deposit-catalog.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { EcosystemManagerModule } from '../ecosystem-manager/ecosystem-manager.module';
import { CommonModule } from '@angular/common';
import { NeedModule } from '../../entities/need/need.module';
import { AdminGuard } from './admin.guard';
import { RouterModule } from '@angular/router';
import { ADMIN_ROUTES } from './admin.routes';
import { ADMIN_CONTEXT } from './admin.context';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';
import { AdminStatisticsComponent } from './components/admin-statistics/admin-statistics.component';
import { AdminReportingComponent } from './components/admin-reporting/admin-reporting.component';
import { CircularityComponent } from './components/admin-reporting/new-report/circularity/circularity.component';
import { PieChartComponent } from './components/admin-reporting/new-report/circularity/pie-chart/pie-chart.component';
import {
  CircularityRatingScaleComponent
} from './components/admin-reporting/new-report/circularity/circularity-rating-scale/circularity-rating-scale.component';
// tslint:disable-next-line:max-line-length
import {CircularityProductCategoryBlockComponent} from './components/admin-reporting/new-report/circularity/circularity-product-category-block/circularity-product-category-block.component';
import { CarbonFootprintComponent } from './components/admin-reporting/new-report/carbon-footprint/carbon-footprint.component';
import { MaterialHealthComponent } from './components/admin-reporting/new-report/material-health/material-health.component';
import { ResidualValueComponent } from './components/admin-reporting/new-report/residual-value/residual-value.component';
import { StatsModule } from '../../entities/stats/stats.module';
import { EditUserResolver } from './components/user-details/EditUser.resolver';
import { SupervisorAndDepOwnerResolver } from './components/user-details/supervisor-and-depositowner-lists.resolver';
import { UserDeletionDialogComponent } from './dialogs/user-deletion-dialog.component';
import { AdminReportsFormComponent } from './components/admin-reporting/new-report/admin-reports-form/admin-reports-form.component';
import { ReportService } from './services/report.service';
import { CircularityReportComponent } from './components/admin-reporting/report-pdf/circularity/circularity-report.component';
import { ReportPdfComponent } from './components/admin-reporting/report-pdf/report-pdf.component';
import { BarChartComponent } from './components/admin-reporting/new-report/carbon-footprint/bar-chart/bar-chart.component';
import { SideReportComponent } from './components/admin-reporting/new-report/material-health/side-report-component/side-report-component';
import {
  MaterialPieChartComponent
} from './components/admin-reporting/new-report/material-health/material-pie-chart/material-pie-chart.component';
import { CarbonFootprintPdfReportComponent } from './components/admin-reporting/report-pdf/carbon-footprint/carbon-footprint-pdf-report.component';
import { MaterialHealthReportComponent } from './components/admin-reporting/report-pdf/material-health/material-health-report.component';
import {
  ResidualValueReportComponent
} from './components/admin-reporting/new-report/residual-value/residual-value-report/residual-value-report.component';
import {
  ResidualValueSecondChartComponent
} from './components/admin-reporting/new-report/residual-value/charts/residual-value-second-chart/residual-value-second-chart.component';
import {
  ResidualValueThirdChartComponent
} from './components/admin-reporting/new-report/residual-value/charts/residual-value-third-chart/residual-value-third-chart.component';
import {
  ResidualValueFirstChartComponent
} from './components/admin-reporting/new-report/residual-value/charts/residual-value-first-chart/residual-value-first-chart.component';
import {
  ResidualValuePdfReportComponent
} from './components/admin-reporting/report-pdf/residual-value/residual-value-pdf-report.component';
import {
  CarbonFootprintReportComponent
} from './components/admin-reporting/new-report/carbon-footprint/carbon-footprint-report/carbon-footprint-report.component';
import { FloatingDigitNumberAfterComma } from './pipes/floating-digit-number-after-comma.pipe';
import { MaterialPieCharData } from './services/material-pie-char-data.service';
import { DepositExportDialogComponent } from './dialogs/deposit-export/deposit-export-dialog.component';
import { DepositModule } from '../../entities/deposit/deposit.module';
import { AdminAdministrationComponent } from './components/admin-administration/admin-administration.component';
// tslint:disable-next-line:max-line-length
import { AdminWarningConfirmDialogComponent } from './dialogs/admin-warning-confirm/admin-warning-confirm-dialog/admin-warning-confirm-dialog.component';
// tslint:disable-next-line:max-line-length
import { ReportSiteListComponent } from './components/admin-reporting/new-report/report-base/report-site-list/report-site-list.component';
// tslint:disable-next-line:max-line-length
import {ReportPdfSiteListComponent} from './components/admin-reporting/report-pdf/report-base/report-pdf-site-list/report-pdf-site-list.component';
import { RequestSiteCreationDialogComponent } from './dialogs/request-site-creation-dialog/request-site-creation-dialog.component';
// tslint:disable-next-line:max-line-length
import { AdminWarningWithHeaderDialogComponent } from './dialogs/admin-warning-confirm/admin-warning-with-header/admin-warning-with-header-dialog.component';
import { RequestSiteDeletionDialogComponent } from './dialogs/request-site-deletion-dialog/request-site-deletion-dialog.component';
// tslint:disable-next-line:max-line-length
import { RequestEsmNumberChangeDialogComponent } from './dialogs/request-esm-number-change-dialog/request-esm-number-change-dialog.component';
import { RequestSiteDeletionConfirmDialogComponent } from './dialogs/admin-warning-confirm/request-site-deletion-confirm-dialog/request-site-deletion-confirm-dialog.component';
import { RequestSentDialogComponent } from './dialogs/request-sent-dialog/request-sent-dialog.component';
import { RequestSiteExtendDateComponent } from './dialogs/request-site-extend-date/request-site-extend-date.component';
import { RequestFileService } from './services/request-file.service';
import { CarbonFootprintFormComponent } from './components/admin-reporting/new-report/admin-reports-form/components/carbon-footprint-form/carbon-footprint-form.component';
import { AdminComponent } from './page/admin.component';
import {ImportSpreadsheetModule} from '../../import-spreadsheet/import-spreadsheet.module';
import { ReportProgressWrapperComponent } from './components/admin-reporting/progress/wrapper/report-progress-wrapper.component'; 
import { ReportProgressService } from './components/admin-reporting/progress/report-progress.service';
import { ReportProgressComponent } from './components/admin-reporting/progress/report-progress.component';
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([{
      path: ADMIN_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: ADMIN_ROUTES
    }]),
    EcosystemManagerModule,
    NeedModule,
    DepositModule,
    StatsModule,
    ImportSpreadsheetModule
  ],
  declarations: [
    AdminDashboardComponent,
    AdminUsersComponent,
    AdminTransformCatalogComponent,
    AdminNeedCatalogComponent,
    AdminDepositCatalogComponent,
    UserDetailsComponent,
    UserEditComponent,
    AdminStatisticsComponent,
    AdminReportingComponent,
    CircularityReportComponent,
    ReportPdfComponent,
    CircularityComponent,
    CircularityProductCategoryBlockComponent,
    PieChartComponent,
    CircularityRatingScaleComponent,
    BarChartComponent,
    MaterialHealthComponent,
    SideReportComponent,
    ResidualValueComponent,
    ResidualValueReportComponent,
    ResidualValueSecondChartComponent,
    ResidualValueThirdChartComponent,
    UserDeletionDialogComponent,
    DepositExportDialogComponent,
    AdminReportsFormComponent,
    MaterialPieChartComponent,
    CarbonFootprintComponent,
    CarbonFootprintPdfReportComponent,
    CarbonFootprintReportComponent,
    MaterialHealthReportComponent,
    ResidualValuePdfReportComponent,
    ResidualValueFirstChartComponent,
    FloatingDigitNumberAfterComma,
    AdminAdministrationComponent,
    AdminWarningConfirmDialogComponent,
    ReportSiteListComponent,
    ReportPdfSiteListComponent,
    RequestSiteCreationDialogComponent,
    AdminWarningWithHeaderDialogComponent,
    RequestSiteDeletionDialogComponent,
    RequestEsmNumberChangeDialogComponent,
    RequestSiteDeletionConfirmDialogComponent,
    RequestSentDialogComponent,
    RequestSiteExtendDateComponent,
    CarbonFootprintFormComponent,
    AdminComponent,
    ReportProgressWrapperComponent,
    ReportProgressComponent
  ],
  exports: [
    AdminTransformCatalogComponent,
    AdminNeedCatalogComponent,
    AdminDepositCatalogComponent,
    CircularityComponent,
    CircularityProductCategoryBlockComponent,
    PieChartComponent,
    CircularityRatingScaleComponent,
    CarbonFootprintComponent,
    CarbonFootprintReportComponent,
    BarChartComponent,
    MaterialHealthComponent,
    SideReportComponent,
    ResidualValueComponent,
    ResidualValueReportComponent,
    ResidualValueSecondChartComponent,
    ResidualValueThirdChartComponent,
    MaterialPieChartComponent
  ],
  providers: [
    AdminGuard,
    EditUserResolver,
    SupervisorAndDepOwnerResolver,
    ReportService,
    MaterialPieCharData,
    RequestFileService,
    ReportProgressService
  ],
  entryComponents: [
    UserDeletionDialogComponent,
    DepositExportDialogComponent,
    AdminWarningConfirmDialogComponent,
    RequestSiteCreationDialogComponent,
    RequestSiteDeletionDialogComponent,
    AdminWarningWithHeaderDialogComponent,
    RequestEsmNumberChangeDialogComponent,
    RequestSiteDeletionConfirmDialogComponent,
    RequestSentDialogComponent,
    RequestSiteExtendDateComponent,
    AdminComponent
  ]
})
export class AdminModule {
}
