import { DoCheck, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../../../../shared/models/user.class';
import { FormControl } from '@angular/forms';
import { ModuleNames } from '../../../../../shared/values/module-names.const';
import * as _ from 'lodash';
import { USER_ROLES_FOR_SPECIAL_ADMIN, USER_ROLES_WITH_ADMIN } from '../../../../../shared/values/role-ids.enum';
import { CurrentUserService } from '../../../../../shared/services/current-user.service';
import { UserService } from '../../../../../shared/services/user.service';
import { Router } from '@angular/router';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { NavigationService } from '../../../../../shared/services/navigation.service';
import { PaginationUnmarshallingService } from '../../../../../shared/services/pagination-unmarshalling.service';
import { Subject } from 'rxjs';
var UsersListComponent = /** @class */ (function () {
    function UsersListComponent(_currentUserService, userService, router, _globalEvents, _navigationService, paginationService) {
        this._currentUserService = _currentUserService;
        this.userService = userService;
        this.router = router;
        this._globalEvents = _globalEvents;
        this._navigationService = _navigationService;
        this.paginationService = paginationService;
        this.resetContainer = true;
        this.users = [];
        this.noahOnly = false;
        this.searchControl = new FormControl();
        this.roleControl = new FormControl();
        this.sortControl = new FormControl();
        this.pageControl = new FormControl();
        this.moduleNames = ModuleNames;
        this.isLoadingNewPage = false;
        this.pageInfo = { currentPage: 1, pageCount: 0, perPage: 0, totalCount: 0 };
        this.activeIndex = 0;
        this.destroy$ = new Subject();
    }
    Object.defineProperty(UsersListComponent.prototype, "hostClass", {
        get: function () { return this.resetContainer; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UsersListComponent.prototype, "hostInit", {
        get: function () { return !this.resetContainer; },
        enumerable: true,
        configurable: true
    });
    UsersListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchControl.valueChanges.debounceTime(400).takeUntil(this.destroy$).subscribe(function (value) {
            _this.pageInfo.currentPage = 1;
            _this.search = value;
        });
        [this.roleControl, this.sortControl].map(function (control) {
            control.valueChanges.debounceTime(400).takeUntil(_this.destroy$).subscribe(function () {
                _this.pageInfo.currentPage = 1;
            });
        });
        this.pageControl.valueChanges.distinctUntilChanged().takeUntil(this.destroy$)
            .subscribe(function (value) { return _this.changePage(value); });
        this.parentModule = this._navigationService.getModuleName();
    };
    UsersListComponent.prototype.ngDoCheck = function () {
        var query = this.queryOptions();
        if (!_.isEqual(this.oldQuery, query)) {
            this.getUsers(query);
            this.oldQuery = _.cloneDeep(query);
        }
    };
    UsersListComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    UsersListComponent.prototype.queryOptions = function (page) {
        if (page === void 0) { page = 1; }
        return {
            search: this.search || null,
            role: this.roleControl.value || null,
            show_hidden: true,
            sort: this.sortControl.value,
            expand: 'company,roles',
            page: page,
            noah: this.noahOnly || null
        };
    };
    UsersListComponent.prototype.getUsers = function (query) {
        var _this = this;
        this.isLoadingNewPage = true;
        this.userService.getWithPaginationData(query).subscribe(function (res) {
            _this.pageInfo = _this.paginationService.unmarshall(res);
            _this.users = [];
            res.json().map(function (user) { return _this.users.push(new User(user)); });
            _this.isLoadingNewPage = false;
        });
    };
    Object.defineProperty(UsersListComponent.prototype, "userRoles", {
        get: function () {
            return this.parentModule === this.moduleNames.SPECIAL_ADMIN ? USER_ROLES_FOR_SPECIAL_ADMIN : USER_ROLES_WITH_ADMIN;
        },
        enumerable: true,
        configurable: true
    });
    UsersListComponent.prototype.changeTab = function (index) {
        this.activeIndex = index;
    };
    UsersListComponent.prototype.getAvatar = function (user) {
        return user.avatar_url ? { 'background-image': 'url(' + user.avatar_url + ')' } : null;
    };
    UsersListComponent.prototype.openChat = function (targetId, event) {
        console.log('openChat', targetId);
        event.stopPropagation();
        this._globalEvents.chatUserClicked$.emit(targetId);
    };
    UsersListComponent.prototype.changePage = function (page) {
        this.getUsers(this.queryOptions(page));
    };
    UsersListComponent.prototype.toggleNoahOnly = function () {
        this.noahOnly = !this.noahOnly;
    };
    return UsersListComponent;
}());
export { UsersListComponent };
