<div #filterWrapper class="selectable-button-list-filter-wrapper" [ngClass]="getClassColor()">
    <div *ngIf="isOpen" class="selectable-list-filter">
        <div *ngIf="enableSearch" class="search-container">
            <md-input-container>
                <input #searchInput mdInput [(ngModel)]="searchTerm" (ngModelChange)="filterOptions()"
                    placeholder="{{'Search...' | translate}}" class="filter-search-input">
            </md-input-container>
            <span class="filter-result-count">{{ filteredOptionsCount }}</span>
        </div>
        <div class="result-filter-container">
            <ng-container *ngFor="let option of filteredOptions; trackBy: trackByFn">
                <div class="selectable-list-filter-row">
                    <button class="selectable-list-filter-button" [class.selected]="option.selected"
                        (click)="onOptionSelected(option)">
                        {{ option.name | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
    <md-input-container class="selectable-list-search-materials" [floatPlaceholder]="'never'" (click)="toggleView()">
        <input mdInput [ngClass]="{'selected' : selectedItem && selectedItem != ''}" [disabled]="disabled" autocomplete="off" placeholder="{{placeholder | translate}}" readonly>
        <md-chip-list>
            <md-basic-chip *ngIf="selectedItem && selectedItem != ''" (focus)="false">
                {{ selectedItem | translate}}
                <button *ngIf="!disabled" (click)="removeTag($event)" class="delete"></button>
            </md-basic-chip>
        </md-chip-list>
        <div class="selectable-list__info-icons">
            <span class="mat-select-arrow"></span>
        </div>
    </md-input-container>
</div>
