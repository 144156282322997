/**
 * Created by aleksandr on 16.05.17.
 */
import * as tslib_1 from "tslib";
import 'jsplumb';
import { NgZone, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Ecosystem } from '../../../models/ecosystem.class';
import { ActivatedRoute, Router } from '@angular/router';
import { ECOSYSTEM_STEP_TYPE } from '../../../values/ecosystem-step-type.const';
import { MdDialog } from '@angular/material';
import { ECOSYSTEM_STATUS, ECOSYSTEM_STATUS_VIEW, EEcosystemResend } from '../../../values/ecosystem-status.const';
import { EcosystemDescriptionDialogComponent } from '../../../dialogs/ecosystem-description/ecosystem-description.component';
import { EcosystemFileDialogComponent } from '../../../dialogs/ecosystem-file/ecosystem-file.component';
import { EcosystemOfferDialogComponent } from '../../../dialogs/ecosystem-offer-dialog/ecosystem-offer-dialog.component';
import { DepositDetailsDialogComponent } from '../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { TransformDetailsDialogComponent } from '../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { StakeholderDetailsDialogComponent } from '../../../../../entities/stakeholder/dialogs/stakeholder-details-dialog/stakeholder-details-dialog.component';
import { VPDetailsDialogComponent } from '../../../../../entities/virgin-product/dialogs/vp-details-dialog.component';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { EntityViewerComponent } from '../../../../../shared/base/enitity-viewer.component';
import { NeedDetailsDialogComponent } from '../../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import { EcosystemService } from '../../../../../entities/ecosystem/services/ecosystem.service';
import { EcosystemIndicatorsDialogComponent } from '../../../../../dashboards/ecosystem-manager/dialogs/ecosystem-indicators/ecosystem-indicators.component';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { EcosystemDeletionDialogComponent } from '../../../dialogs/ecosystem-deletion/ecosystem-deletion.component';
import { TranslateService } from '@ngx-translate/core';
import { EcosystemPrefillService } from '../../../services/ecosystem-prefill.service';
import { EcosystemCompilerSharedActionsService } from '../../../services/ecosystem-compiler-shared-actions.service';
var EcosystemEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemEditComponent, _super);
    function EcosystemEditComponent(route, router, dialog, translate, zone, ecosystemService, notificationService, ngZone, prefillService, ecosystemCompilerSharedActionsService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.dialog = dialog;
        _this.translate = translate;
        _this.zone = zone;
        _this.ecosystemService = ecosystemService;
        _this.notificationService = notificationService;
        _this.ngZone = ngZone;
        _this.prefillService = prefillService;
        _this.ecosystemCompilerSharedActionsService = ecosystemCompilerSharedActionsService;
        _this.ICON_GROUP_KEY = EcosystemEditComponent.ICON_GROUP_KEY;
        _this.ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
        _this.ECOSYSTEM_SUB_STATUS = EEcosystemResend;
        _this.ECOSYSTEM_STATUS_VIEW = ECOSYSTEM_STATUS_VIEW;
        _this.ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP_TYPE;
        _this.activeStep = null;
        _this.openChat = false;
        _this.isCompilerExpanded = true;
        _this._isCompilerExtended = false;
        _this.isNameInFocus = false;
        _this.buttonControlGroup = [
            {
                isExist: function () { return _this.ecosystem.canDeleteByStatus; },
                onClick: function () { return _this.openDeletionMessage(); },
                name: 'Delete ecosystem',
                iconClass: 'icon icon--deletion-bin'
            }, {
                isExist: function () { return [ECOSYSTEM_STATUS.IN_PROCESS, ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION].includes(_this.ecosystem.status); },
                onClick: function () { return _this.unvalidateEcosystem(); },
                name: 'Stop ecosystem',
                iconClass: 'icon--s icon--close-grey'
                /* }, {
                  isExist: () => this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS && !this.ecosystem.isOrdersConfirmed,
                  onClick: () => this.validateOrdersManually(),
                  name: 'Confirm all manually',
                  iconClass: 'icon--l icon--ok-grey ecosystem-edit__button-title-icon--offset-top' */
            }, {
                isExist: function () { return _this.ecosystem.canStopOrEdit; },
                onClick: function () { return _this.unpublishEcosystem(); },
                name: 'Stop ecosystem',
                iconClass: 'icon--s icon--close-grey'
            }, {
                isExist: function () { return _this.ecosystem.status === _this.ECOSYSTEM_STATUS.DRAFT; },
                onClick: function () { return _this.saveDraft(); },
                name: 'Save as draft',
                iconClass: 'icon icon--ok-grey'
            }, {
                isExist: function () { return _this.ecosystem.status === _this.ECOSYSTEM_STATUS.DRAFT; },
                onClick: function () { return _this.publishEcosystem(); },
                name: 'Publish ecosystem',
                iconClass: 'icon icon--ecosystem-publish-grey'
            }, {
                isExist: function () { return [ECOSYSTEM_STATUS.CREATED, , ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION].includes(_this.ecosystem.status); },
                onClick: function () { return _this.validateOrdersManually(); },
                name: 'Validate all manually',
                iconClass: 'icon--l icon--ok-grey ecosystem-edit__button-title-icon--offset-top'
            }, {
                isExist: function () { return [ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, ECOSYSTEM_STATUS.WAITING_FOR_QUOTE].includes(_this.ecosystem.status); },
                onClick: function () { return _this.validateOrdersManually(); },
                name: 'Validate offer manually',
                iconClass: 'icon--l icon--ok-grey ecosystem-edit__button-title-icon--offset-top'
            }, {
                isExist: function () { return [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(_this.ecosystem.status) && !_this.ecosystem.isAskForValidationAgain; },
                onClick: function () { return _this.askForValidation(); },
                name: 'Ask for validation',
                iconClass: 'icon--portrait icon--ecosystem-message'
            }, {
                isExist: function () { return [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(_this.ecosystem.status) && _this.ecosystem.isAskForValidationAgain; },
                onClick: function () { return _this.askForValidation(); },
                name: 'Ask for validation again',
                iconGroupKey: EcosystemEditComponent.ICON_GROUP_KEY.ADD_MESSAGE
            }, {
                isExist: function () { return [ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, ECOSYSTEM_STATUS.WAITING_FOR_QUOTE].includes(_this.ecosystem.status)
                    && _this.ecosystem.ordersBooked && !_this.ecosystem.isAskForOfferValidationAgain; },
                onClick: function () { return _this.askForOfferValidation(); },
                name: 'Ask for offer validation',
                iconClass: 'icon--portrait icon--ecosystem-message'
            }, {
                isExist: function () { return [ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, ECOSYSTEM_STATUS.WAITING_FOR_QUOTE].includes(_this.ecosystem.status)
                    && _this.ecosystem.isAskForOfferValidationAgain; },
                onClick: function () { return _this.askForOfferValidation(); },
                name: 'Ask for offer validation again',
                iconGroupKey: EcosystemEditComponent.ICON_GROUP_KEY.ADD_MESSAGE
            },
            {
                isExist: function () { return _this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS
                    && !_this.ecosystem.isOrdersConfirmed; },
                onClick: function () { return _this.askForValidation(); },
                name: 'Ask for confirmation again',
                iconGroupKey: EcosystemEditComponent.ICON_GROUP_KEY.ADD_MESSAGE
            }, {
                isExist: function () { return _this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS
                    && _this.ecosystem.ordersConfirmed() && _this.ecosystem.isAskForOfferConfirmationAgain; },
                onClick: function () { return _this.askForValidation(); },
                name: 'Ask for offer confirmation again',
                iconGroupKey: EcosystemEditComponent.ICON_GROUP_KEY.ADD_MESSAGE
            }
        ];
        return _this;
        // ReflectiveInjector.resolveAndCreate([{useValue: this, provide: EcosystemEditComponent}]);
    }
    EcosystemEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.ecosystemCompilerSharedActionsService.ecosystem = this.ecosystem;
        this.initPrefill();
        this.prefillSubscription = this.ecosystem.onFetch$.subscribe(function () {
            _this.ecosystemCompilerSharedActionsService.ecosystem = _this.ecosystem;
            _this.updatePrefill();
        });
    };
    EcosystemEditComponent.prototype.isEnableAutoPrefill = function () {
        return [ECOSYSTEM_STATUS.DRAFT, ECOSYSTEM_STATUS.CREATED, ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION].includes(this.ecosystem.status);
    };
    EcosystemEditComponent.prototype.initPrefill = function () {
        if (!this.prefillService.isPrefillInited() && this.isEnableAutoPrefill()) {
            this.ecosystem.reload();
        }
    };
    EcosystemEditComponent.prototype.updatePrefill = function () {
        if (this.isEnableAutoPrefill()) {
            this.prefillService.prefillAndChangeByEcosystem(this.ecosystem, false);
            this.ecosystemCompilerSharedActionsService.onPrefillFinished.next();
        }
    };
    EcosystemEditComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        if (this.prefillSubscription) {
            this.prefillSubscription.unsubscribe();
        }
    };
    EcosystemEditComponent.prototype.onExistEntity = function (entity) {
        this.ecosystem = entity;
        this.steps = this.ecosystem.steps;
    };
    EcosystemEditComponent.prototype.onNewEntity = function () {
        this.ecosystem = new Ecosystem({});
    };
    EcosystemEditComponent.prototype.onFocusChanged = function (focus) {
        this.isNameInFocus = focus;
        if (!focus) {
            this.saveDraft();
        }
    };
    EcosystemEditComponent.prototype.toggleExpand = function () {
        this.isCompilerExpanded = !this.isCompilerExpanded;
    };
    EcosystemEditComponent.prototype.trySaveActiveStep = function () {
        if (this.activeStepComponent && this.activeStepComponent.trySave) {
            return this.activeStepComponent.trySave();
        }
        else {
            return Observable.of(null);
        }
    };
    EcosystemEditComponent.prototype.tryProhibitActiveStepSaving = function () {
        if (this.activeStepComponent && this.activeStepComponent.prohibitSave) {
            this.activeStepComponent.prohibitSave(true);
        }
    };
    EcosystemEditComponent.prototype.tryLoadActiveStep = function () {
        if (this.activeStepComponent && this.activeStepComponent.load) {
            this.activeStepComponent.load();
        }
    };
    EcosystemEditComponent.prototype.reload = function (observable) {
        var _this = this;
        observable.subscribe(function () {
            // put active step back with possibly new data
            _this.tryLoadActiveStep();
            // put ecosystem back with possibly new data
            _this.ecosystem.fetch().subscribe();
        });
    };
    EcosystemEditComponent.prototype.saveDraft = function () {
        var _this = this;
        this.reload(this.ecosystem.save().do(function (result) {
            _this.ecosystem.id = result.id;
        }));
        this.notificationService.success('Ecosystem created as draft', 3000, true);
    };
    EcosystemEditComponent.prototype.publishEcosystem = function () {
        var _this = this;
        this.reload(this.ecosystem.publish().do(function (result) {
            _this.ecosystem.id = result.id;
        }));
    };
    EcosystemEditComponent.prototype.unpublishEcosystem = function () {
        this.reload(this.ecosystem.unpublish());
    };
    EcosystemEditComponent.prototype.deleteEcosystem = function () {
        var _this = this;
        this.tryProhibitActiveStepSaving();
        this.trySaveActiveStep()
            .flatMap(function () { return _this.ecosystem.destroy(); })
            .subscribe();
    };
    EcosystemEditComponent.prototype.navigateToIndex = function () {
        this.router.navigate(['ecosystem']);
    };
    EcosystemEditComponent.prototype.unvalidateEcosystem = function () {
        this.reload(this.ecosystem.unvalidate());
    };
    EcosystemEditComponent.prototype.validateOrdersManually = function () {
        if (this.validateLinkDateAndShowWarning()) {
            this.reload(this.ecosystem.validateAll());
        }
    };
    EcosystemEditComponent.prototype.validateLinkDateAndShowWarning = function () {
        if (!this.ecosystem.steps.every(function (step) { return !!step.linked_date; })) {
            this.notificationService.warn('not all dates are specified');
            return false;
        }
        return true;
    };
    EcosystemEditComponent.prototype.validateConfirmAndShowError = function () {
        if ([ECOSYSTEM_STATUS.IN_PROCESS].includes(this.ecosystem.status) && !this.ecosystem.ordersWaitingForConfirmation()) {
            this.notificationService.error('Some dates have not passed', null, true);
            return false;
        }
        return true;
    };
    EcosystemEditComponent.prototype.askForValidation = function () {
        if (this.validateConfirmAndShowError()) {
            this.validateLinkDateAndShowWarning();
            this.reload(this.ecosystemService.askForValidationAll(this.ecosystem.id));
        }
    };
    EcosystemEditComponent.prototype.askForOfferValidation = function () {
        this.reload(this.ecosystem.ecosystem_offer.publish());
    };
    EcosystemEditComponent.prototype.openIndicators = function () {
        var _this = this;
        this.ngZone.run(function () {
            _this.dialog.open(EcosystemIndicatorsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.NOMENCLATURE_PRIMARY, { data: {
                    ecosystem: _this.ecosystem,
                    updateSteps: function (steps) { return _this.saveConfirmIndicators(steps); }
                } }));
        });
    };
    EcosystemEditComponent.prototype.saveConfirmIndicators = function (steps) {
        var _this = this;
        steps.forEach(function (step) {
            var unconfirmedStep = _this.ecosystem.steps.find(function (unconfirmStep) { return unconfirmStep.id === step.step_id; });
            if (!!unconfirmedStep) {
                unconfirmedStep.confirmed_quantity = step.confirmed_quantity;
                unconfirmedStep.confirmed_price = step.confirmed_price;
            }
        });
    };
    EcosystemEditComponent.prototype.openDescription = function () {
        var _this = this;
        this.zone.run(function () {
            var dialogRef = _this.dialog.open(EcosystemDescriptionDialogComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: {
                    view_description: _this.ecosystem.description
                } }));
            dialogRef.afterClosed().subscribe(function (description) {
                if (description) {
                    _this.ecosystem.description = description;
                    _this.ecosystem.save().subscribe();
                }
            });
        });
    };
    EcosystemEditComponent.prototype.openFiles = function () {
        var _this = this;
        this.zone.run(function () {
            _this.dialog.open(EcosystemFileDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                    isOpenFiles: true,
                    ecosystem: _this.ecosystem
                } }));
        });
    };
    EcosystemEditComponent.prototype.openDeletionMessage = function () {
        var _this = this;
        this.zone.run(function () {
            _this.dialog.open(EcosystemDeletionDialogComponent, tslib_1.__assign({ disableClose: true }, DIALOG_THEME.BELOW_NAVBAR, { data: {
                    deleteEcosystem: _this.deleteEcosystem.bind(_this),
                    navigateToIndex: _this.navigateToIndex.bind(_this)
                } }));
        });
    };
    EcosystemEditComponent.prototype.viewOffer = function (step) {
        this.reload(this
            .dialog
            .open(EcosystemOfferDialogComponent, {
            position: {
                top: '123px',
            },
            height: '84%',
            data: {
                ecosystem: this.ecosystem,
            }
        })
            .afterClosed());
    };
    EcosystemEditComponent.prototype.viewDetails = function (type, id) {
        var _a;
        var dialogComponent = (_a = {},
            _a[ECOSYSTEM_STEP_TYPE.DEPOSIT] = DepositDetailsDialogComponent,
            _a[ECOSYSTEM_STEP_TYPE.TRANSFORM] = TransformDetailsDialogComponent,
            _a[ECOSYSTEM_STEP_TYPE.STAKEHOLDER] = StakeholderDetailsDialogComponent,
            _a[ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT] = VPDetailsDialogComponent,
            _a[ECOSYSTEM_STEP_TYPE.NEED] = NeedDetailsDialogComponent,
            _a)[type];
        if (dialogComponent) {
            this.dialog.open(dialogComponent, {
                position: {
                    top: '123px',
                },
                data: {
                    id: id,
                    readOnly: true,
                }
            });
        }
    };
    EcosystemEditComponent.prototype.toggleCompilerExtendedView = function (value) {
        this._isCompilerExtended = value;
    };
    EcosystemEditComponent.prototype.onStepChange = function (step) {
        var item = this.ecosystem.steps.find(function (itemStep) { return itemStep.id === step.id; });
        if (item) {
            Object.assign(item, step);
            item.updateOverlay();
        }
    };
    EcosystemEditComponent.prototype.substituteDefaultPrefix = function () {
        return !this.isNameInFocus && this.ecosystem.name.match(/^For\s/);
    };
    EcosystemEditComponent.ICON_GROUP_KEY = { ADD_MESSAGE: 1 };
    return EcosystemEditComponent;
}(EntityViewerComponent));
export { EcosystemEditComponent };
