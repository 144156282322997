import { Injectable } from '@angular/core';
import { IDepositFilter, IPassportFilter } from '../interfaces/filter-states.interface';
import {IDoSitesListFilterStorage} from '../../dashboards/deposit-owner/values/do-sites-list-filters.interface';

@Injectable()
export class FilterStateService {
  public depositList: IDepositFilter;
  public productPassportList: IPassportFilter;
  public passportListViewState: {showFamilyInfoBanner: boolean};
  public filterList: {statusDone: boolean};
  public sitesList: IDoSitesListFilterStorage;
}
