import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {TransformPackagingJunction} from '../models/transform-packaging-junction.class';

@Injectable()
export class TransformPackagingJunctionService extends CrudService {
  protected namespace = 'transformation-packagings-junctions';
  protected namespaceSingular = 'transformation-packagings-junction';
  protected ModelClass = TransformPackagingJunction;
}
