/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../partials/passport-list/passport-list.component.ngfactory";
import * as i4 from "../../partials/passport-list/passport-list.component";
import * as i5 from "@angular/material";
import * as i6 from "../../../../shared/services/navigation.service";
import * as i7 from "@angular/router";
import * as i8 from "../../services/passport.service";
import * as i9 from "../../../../shared/services/filterState.service";
import * as i10 from "../../../../shared/services/current-user.service";
import * as i11 from "../../../../shared/services/navbar.service";
import * as i12 from "./advanced-research-dialog.component";
var styles_AdvancedResearchDialogComponent = [];
var RenderType_AdvancedResearchDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdvancedResearchDialogComponent, data: {} });
export { RenderType_AdvancedResearchDialogComponent as RenderType_AdvancedResearchDialogComponent };
export function View_AdvancedResearchDialogComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.UpperCasePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "advanced-research-dialog"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "advanced-research-dialog__close background-primary-color"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), i0.ɵppd(6, 1), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "advanced-research-dialog__close--icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "advanced-research-dialog__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "passport-list", [], null, [[null, "onSelected"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("onSelected" === en)) {
        var pd_1 = (_co.mdDialogRef.close($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_PassportListComponent_0, i3.RenderType_PassportListComponent)), i0.ɵdid(10, 114688, null, 0, i4.PassportListComponent, [i5.MdDialog, i2.TranslateService, i6.NavigationService, i5.MdDialog, i7.Router, i1.DatePipe, i8.PassportService, i0.ChangeDetectorRef, i9.FilterStateService, i10.CurrentUserService, i11.NavbarStateService], { advancedResearchTheme: [0, "advancedResearchTheme"], hasNoPagination: [1, "hasNoPagination"], includeDrafts: [2, "includeDrafts"] }, { onSelected: "onSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = true; var currVal_3 = _co.data.includeDrafts; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("ADVANCED_RESEARCH_CAPTION")))); _ck(_v, 4, 0, currVal_0); }); }
export function View_AdvancedResearchDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "advanced-research-dialog", [], null, null, null, View_AdvancedResearchDialogComponent_0, RenderType_AdvancedResearchDialogComponent)), i0.ɵdid(1, 49152, null, 0, i12.AdvancedResearchDialogComponent, [i5.MdDialogRef, i7.Router, i2.TranslateService, i5.MD_DIALOG_DATA], null, null)], null, null); }
var AdvancedResearchDialogComponentNgFactory = i0.ɵccf("advanced-research-dialog", i12.AdvancedResearchDialogComponent, View_AdvancedResearchDialogComponent_Host_0, {}, {}, []);
export { AdvancedResearchDialogComponentNgFactory as AdvancedResearchDialogComponentNgFactory };
