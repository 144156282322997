import * as tslib_1 from "tslib";
import { TransformOrderService } from '../services/transform-order.service';
import { OrderPassport } from './order-passport.class';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { Transform } from '../../../entities/transformation/models/transform.class';
import { EcosystemFileJunction } from './ecosystem-file-junction.class';
import { ECOSYSTEM_STEP_STATUS } from '../values/ecosystem-step-status.const';
import { Ecosystem } from './ecosystem.class';
import { EcosystemStep } from '../../ecosystem-manager/models/ecosystem-step.class';
import { ECOSYSTEM_STEP_TYPE } from '../values/ecosystem-step-type.const';
import { SourceConnectorService } from '../../../../e2e/helpers/source-connector/source-connector.service';
var PASSPORT_UNIT_TYPE = SourceConnectorService.PASSPORT_UNIT_TYPE;
import { EcosystemProblem } from '../../../entities/ecosystem/models/ecosystem-problem.model';
import { TRANSFORM_ORDER_STATUS } from '../../../dashboards/transformation-manager/values/transform-order-statuses.const';
import { Observable } from 'rxjs';
var TransformOrder = /** @class */ (function (_super) {
    tslib_1.__extends(TransformOrder, _super);
    function TransformOrder(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.TON_CONVERSION = 1000;
        _this.provider = TransformOrderService;
        _this.child_step = new EcosystemStep(data.child_step);
        _this.in_order_passport = new OrderPassport(data.in_order_passport);
        _this.out_order_passport = new OrderPassport(data.out_order_passport);
        _this.transformation = new Transform(data.transformation);
        _this.ecosystem_file_junction = data.ecosystem_file_junction
            ? data.ecosystem_file_junction.map(function (item) { return new EcosystemFileJunction(item); })
            : [];
        _this.ecosystem = new Ecosystem(data.ecosystem);
        if (data.orders_common_step) {
            _this.orders_common_step = data.orders_common_step.map(function (item) { return new TransformOrder(item); });
        }
        _this.problem = data.problem ? new EcosystemProblem(data.problem) : null;
        return _this;
    }
    TransformOrder.prototype.fields = function () {
        return [
            'child_step',
            'confirmation_date',
            'created_at',
            'creator',
            'creator_company',
            'creator_id',
            'ecosystem_file_junction',
            'ecosystem_name',
            'id',
            'in_order_passport_id',
            'modification',
            'out_order_passport_id',
            'ecosystem_id',
            'price',
            'publication_date',
            'site_id',
            'status',
            'step_id',
            'transformation_id',
            'TSM_price',
            'updated_at',
            'default_unit',
            'default_conversion',
            'default_in_quantity',
            'linked_date',
            'pickup_address',
            'delivery_address',
            'description',
            'orders_common_step',
            'problem',
            'estimated_distance',
            'confirmed_distance',
        ];
    };
    TransformOrder.prototype.beforeSave = function (data) {
        if (data.status) {
            delete data.status; // avoid step.status !== order.status bug
            data.status = this.status;
        }
        return data;
    };
    Object.defineProperty(TransformOrder.prototype, "status_text", {
        get: function () {
            if (this.status) {
                return ECOSYSTEM_STEP_STATUS[this.status]
                    .replace(/_/g, ' ')
                    .toLowerCase();
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    TransformOrder.prototype.isChildStepWithoutUnit = function () {
        return !this.child_step
            || [ECOSYSTEM_STEP_TYPE.STAKEHOLDER].includes(this.child_step.type)
            || (this.child_step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM
                && this._isWithoutUnit(this.child_step.transformation_type));
    };
    TransformOrder.prototype.getConversionForInput = function () {
        return this.isWithoutUnit() ? this.conversionInDefaultView : this.transformation.in_conversion;
    };
    TransformOrder.prototype._isWithoutUnit = function (type) {
        return Transform.isWithoutUnitByType(type);
    };
    TransformOrder.prototype.isWithoutUnit = function () {
        return this._isWithoutUnit(this.transformation.transformation_type);
    };
    TransformOrder.prototype.getViewConversion = function (conversion, defaultConversion) {
        return Transform.getConversion(this.transformation, conversion, !this.isChildStepWithoutUnit() ? defaultConversion : 1);
    };
    Object.defineProperty(TransformOrder.prototype, "conversionOutDefaultView", {
        get: function () {
            return this.getViewConversion(this.transformation.out_conversion, 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformOrder.prototype, "conversionInDefaultView", {
        get: function () {
            return this.getViewConversion(this.transformation.in_conversion, this.default_conversion);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformOrder.prototype, "unitInView", {
        get: function () {
            var substitution = this.isChildStepWithoutUnit() ? null : (this.default_unit || PASSPORT_UNIT_TYPE.KG);
            return Transform.getConversionInUnit(this.transformation, substitution);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformOrder.prototype, "unitOutView", {
        get: function () {
            return Transform.getConversionOutUnit(this.transformation);
        },
        enumerable: true,
        configurable: true
    });
    TransformOrder.prototype.getCustomInQuantityView = function (orderPassport, quantity) {
        if (quantity === void 0) { quantity = orderPassport.quantity; }
        return this.isChildStepWithoutUnit()
            ? Transform.transformQuantityToCustomDefault(quantity, this.transformation) : quantity;
    };
    Object.defineProperty(TransformOrder.prototype, "orderInQuantityView", {
        get: function () {
            return this.getCustomInQuantityView(this.in_order_passport);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransformOrder.prototype, "orderOutQuantityView", {
        get: function () {
            return Transform.transformQuantityToCustomDefault(this.out_order_passport.quantity, this.transformation);
        },
        enumerable: true,
        configurable: true
    });
    TransformOrder.prototype.transformFromInView = function (quantity) {
        if (this.isChildStepWithoutUnit()) {
            return Transform.inverseQuantityToCustomDefault(quantity, this.transformation);
        }
        return quantity;
    };
    TransformOrder.prototype.transformFromOutView = function (quantity) {
        return Transform.inverseQuantityToCustomDefault(quantity, this.transformation);
    };
    Object.defineProperty(TransformOrder.prototype, "inputNameView", {
        get: function () {
            var getTextField = function (model, option) { return (model && model[option]) ? model[option] : false; };
            var passportName = getTextField(this.in_order_passport, 'name');
            var stepTittle = getTextField(this.child_step, 'title');
            return passportName || stepTittle || 'Unnamed';
        },
        enumerable: true,
        configurable: true
    });
    TransformOrder.prototype.rejectByStep = function () {
        var _this = this;
        return this.reject().mergeMap(function () {
            return Observable.forkJoin((_this.orders_common_step || []).map(function (order) {
                order.status = TRANSFORM_ORDER_STATUS.REJECTED;
                return order.save();
            }));
        });
    };
    TransformOrder.prototype.validateByStep = function () {
        var _this = this;
        return this.validate().mergeMap(function () {
            return Observable.forkJoin((_this.orders_common_step || []).map(function (order) {
                order.status = TRANSFORM_ORDER_STATUS.PENDING;
                return order.save();
            }));
        });
    };
    TransformOrder.prototype.askForModificationByStep = function () {
        var _this = this;
        return this.askForModification().mergeMap(function () {
            return Observable.forkJoin((_this.orders_common_step || []).map(function (order) {
                order.status = TRANSFORM_ORDER_STATUS.ASK_FOR_MODIFICATION;
                return order.save();
            }));
        });
    };
    return TransformOrder;
}(ExtendedActiveRecord));
export { TransformOrder };
