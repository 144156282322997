import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, DoCheck, QueryList, AfterViewChecked } from '@angular/core';
import { EcosystemStep } from '../../../models/ecosystem-step.class';
import { PASSPORT_UNITS, PASSPORT_UNITS_TYPE } from '../../../../../entities/passport/values/passport-units.const';
import { Transform } from '../../../../../entities/transformation/models/transform.class';
import { TransformService } from '../../../../../entities/transformation/services/transform.service';
import { TransformLocationService } from '../../../../../entities/transformation/services/transform-location.service';
import { ChatService } from '../../../../../shared/chat/chat.service';
import { EThreadType } from '../../../../../shared/chat/interfaces/chat-message.interface';
import { Observable } from 'rxjs/Observable';
import { EcosystemFileDialogComponent } from '../../../dialogs/ecosystem-file/ecosystem-file.component';
import { MdDialog } from '@angular/material';
import { EcosystemFileJunctionService } from '../../../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import { EcosystemFileJunction } from '../../../models/ecosystem-file-junction.class';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../../values/ecosystem-file-junction-item-type.const';
import { ECOSYSTEM_STEP_STATUS } from '../../../values/ecosystem-step-status.const';
import { TransformDetailsDialogComponent } from '../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { TRANSFORM_TYPE, TRANSFORM_TYPES } from '../../../../transformation-manager/values/transform-types.const';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from '../../../../../shared/auth/current-user.class';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { TransformFileService } from '../../../../../entities/transformation/services/transform-file.service';
import { setTextareaHeightToFitContent } from '../../../../../shared/helpers/set-textarea-height-to-fit-content';
import moment from 'moment';
import { EcosystemPrefillService } from '../../../services/ecosystem-prefill.service';
import { EcosystemCompilerSharedActionsService } from '../../../services/ecosystem-compiler-shared-actions.service';
import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators';
var TransformOrdersComponent = /** @class */ (function () {
    function TransformOrdersComponent(transformFileService, dialog, transformLocationService, transformService, _chatService, ecoFileJunctionService, _translate, globalEventsManager, filterState, prefillService, ecosystemCompilerSharedActionsService) {
        var _this = this;
        this.transformFileService = transformFileService;
        this.dialog = dialog;
        this.transformLocationService = transformLocationService;
        this.transformService = transformService;
        this._chatService = _chatService;
        this.ecoFileJunctionService = ecoFileJunctionService;
        this._translate = _translate;
        this.globalEventsManager = globalEventsManager;
        this.filterState = filterState;
        this.prefillService = prefillService;
        this.ecosystemCompilerSharedActionsService = ecosystemCompilerSharedActionsService;
        this.stepChange = new EventEmitter;
        this.action = new EventEmitter();
        this.tabStatus = {
            CLOSED: 0,
            OPENED: 1
        };
        this.tabType = {
            ORDER: 1,
            SUMMARY: 2,
            CHAT: 3
        };
        this.units = PASSPORT_UNITS;
        this.ECOSYSTEM_STEP_STATUS = ECOSYSTEM_STEP_STATUS;
        this.TRANSFORM_TYPE = TRANSFORM_TYPE;
        this.isTransformIndependent = false;
        this.rooms = [];
        this.activeTab = -1;
        this.arrSection = [this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED];
        this.transform = new Transform;
        this.dirty = false;
        this.orders = [];
        this.isOrdersSyncWithStep = false;
        this.onChange$ = new Subject();
        this.updateFields = this.onChange$.pipe(debounceTime(300)).subscribe(function () { return _this.saveStep(); });
        this.skipSave = false;
        this.isOrderTabOpen = this.arrSection[1];
    }
    TransformOrdersComponent.prototype.onChange = function () {
        this.dirty = true;
    };
    Object.defineProperty(TransformOrdersComponent.prototype, "types", {
        get: function () {
            return TRANSFORM_TYPES.filter(function (data) { return !data.includes.incomeOutcome; }).map(function (data) { return data.value; });
        },
        enumerable: true,
        configurable: true
    });
    TransformOrdersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.prefillUpdateObserver =
            this.ecosystemCompilerSharedActionsService.onPrefillFinished.subscribe(function () {
                _this.orders.forEach(function (order) {
                    if (_this.prefillService.getPrefilledValue(order.id)) {
                        order.in_order_passport.quantity = _this.prefillService.getPrefilledValue(order.id).in;
                        order.out_order_passport.quantity = _this.prefillService.getPrefilledValue(order.id).out;
                    }
                });
            });
    };
    TransformOrdersComponent.prototype.ngOnChanges = function () {
        this.load();
        this.closeAllTabs();
        this.hasTransformAccess = CurrentUser.infoData.has_transform_acces;
        if (this.openChat) {
            this.changeTab(3);
            this.changeTab(4);
        }
    };
    TransformOrdersComponent.prototype.ngDoCheck = function () {
        if (this.orders !== this.step.orders && this.isOrdersSyncWithStep) {
            this.isOrdersSyncWithStep = false;
            this.load();
            this.assignMissingAsOldValues();
        }
    };
    TransformOrdersComponent.prototype.ngAfterViewChecked = function () {
        if (this.isOrderTabOpen !== this.arrSection[1]) {
            if (this.arrSection[1]) {
                this.expandSectionArea();
            }
            this.isOrderTabOpen = this.arrSection[1];
        }
    };
    TransformOrdersComponent.prototype.assignMissingAsOldValues = function () {
        var setOfOldOrders = this.step.orders.reduce(function (accumulator, order) {
            accumulator[order.id] = order;
            return accumulator;
        }, {});
        var newOrders = [];
        var formatDate = function (date) { return moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('DD-MM-YYYY'); };
        this.orders.forEach(function (order, index) {
            if (setOfOldOrders[order.id]) {
                var item = Object.assign(setOfOldOrders[order.id], order);
                item.in_order_passport.linked_datetime = formatDate(item.in_order_passport.linked_datetime);
                item.out_order_passport.linked_datetime = formatDate(item.out_order_passport.linked_datetime);
                newOrders.push(item);
            }
        });
        this.orders = newOrders;
    };
    TransformOrdersComponent.prototype.ngOnDestroy = function () {
        this.prefillUpdateObserver.unsubscribe();
        this.updateFields.unsubscribe();
        this.saveStep();
    };
    TransformOrdersComponent.prototype.closeAllTabs = function () {
        this.arrSection = [this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED];
    };
    TransformOrdersComponent.prototype.saveStep = function (reload) {
        var _this = this;
        if (reload === void 0) { reload = false; }
        this.dirty = !this.skipSave;
        this.globalEventsManager.compilerDataBeenUpdated = true;
        this.trySave().subscribe(function () {
            _this.globalEventsManager.compilerDataBeenUpdated = false;
            _this.globalEventsManager.compilerDataUpdated.emit();
            if (reload) {
                _this.step.ecosystem.fetch().subscribe(function () {
                    _this.load();
                });
            }
        });
    };
    TransformOrdersComponent.prototype.load = function () {
        var _this = this;
        if (this.dirty) {
            console.warn('loaded new step order ontop of an unsaved step??');
        }
        CurrentUser.infoData.has_transform_acces;
        this.transformService
            .getSingular({
            id: this.step.entity_id,
            step_id: this.step.id,
            expand: 'conditions,materials,sites,files,orders,tsm_email,tsm_name,tsm_org,sectors,tsm_avatar,categories' +
                ',formats,quantities,qualities,packagings,tags,orders.default_unit,orders.default_conversion',
            supervisor: 1,
        })
            .subscribe(function (transforms) {
            if (transforms.length > 0) {
                _this.transform = transforms[0];
                _this.isTransformIndependent = _this.isIndependentTransformation();
                _this.showCommunication = (_this.isTransformIndependent && _this.hasTransformAccess) || !_this.isTransformIndependent;
                _this.orders = transforms[0].orders;
                _this.step.orders = _this.orders;
                _this.rooms = [];
                _this.transform.orders = [];
                _this.orders.forEach(function (order, index) {
                    order.transformation = _this.transform;
                    _this.prefillOrder(order);
                    _this._chatService
                        .getContextThreads(order.id, EThreadType.TYPE_TRANSFORMATION_ORDER)
                        .subscribe(function (rooms) { return _this.addChatroom(rooms[0], index); });
                });
            }
            _this.isOrdersSyncWithStep = true;
        }, function () { return _this.isOrdersSyncWithStep = true; });
        if (this.step.site_id) {
            this.transformLocationService
                .view(this.step.site_id)
                .subscribe(function (site) { return _this.site = site; });
        }
        else {
            this.site = null;
        }
    };
    TransformOrdersComponent.prototype.prefillOrder = function (order) {
        if (!order.delivery_address) {
            order.delivery_address = order.out_order_passport.address;
        }
        if (!order.pickup_address) {
            order.pickup_address = order.in_order_passport.address;
        }
        if (!order.in_order_passport.quantity) {
            order.in_order_passport.quantity = this.prefillService.getOrderInPrefill(order);
        }
        if (!order.out_order_passport.quantity) {
            order.out_order_passport.quantity = this.prefillService.getOrderOutPrefill(order);
        }
    };
    TransformOrdersComponent.prototype.trySave = function (step) {
        var _this = this;
        return new Observable(function (observer) {
            step = step || _this.step;
            // no need to save orders when we delete the step , because it deletes the order and we get parent id non valid
            if (step && step.canEditOrder && step.orders && step.orders.length && _this.dirty) {
                _this.dirty = false;
                for (var i = 0; i < step.orders.length; i++) {
                    if (step.orders[i].child_step.linked_date) {
                        step.orders[i].child_step.linked_date = moment(step.orders[i].child_step.linked_date).format('YYYY-MM-DD');
                        step.orders[i].in_order_passport.linked_datetime = step.orders[i].child_step.linked_date;
                    }
                }
                Observable.concat(Observable.zip(step.save()), Observable.zip.apply(Observable, step.orders.map(function (order) { return order.save(); }).concat([Observable.zip.apply(Observable, step.orders.map(function (order) { return order.in_order_passport.save(); }).concat([step.orders[0].out_order_passport.save()])), _this.ecosystemCompilerSharedActionsService.beforeCompilerReload()]))).subscribe(null, function (err) { return observer.error(err); }, function () {
                    observer.next(step);
                    observer.complete();
                });
            }
            else {
                observer.next(null);
                observer.complete();
            }
        });
    };
    TransformOrdersComponent.prototype.changeTab = function (index) {
        if (this.arrSection[index] === this.tabStatus.CLOSED) {
            this.arrSection[index] = this.tabStatus.OPENED;
        }
        else {
            this.arrSection[index] = this.tabStatus.CLOSED;
        }
    };
    TransformOrdersComponent.prototype.isOpen = function (index) {
        return this.arrSection[index] === this.tabStatus.OPENED;
    };
    TransformOrdersComponent.prototype.isReadOnly = function () {
        if (this.step.status === this.ECOSYSTEM_STEP_STATUS.BOOKED) {
            return true;
        }
        return !this.step.canEditOrder;
    };
    TransformOrdersComponent.prototype.isRoomLoaded = function (index) {
        return this.rooms.length > index && !!this.rooms[index].room;
    };
    TransformOrdersComponent.prototype.addChatroom = function (room, index) {
        var _this = this;
        var isRoomNotExist = function () { return !_this.rooms.map(function (junction) { return junction.room; })
            .some(function (activeRooms) { return activeRooms.id === room.id; }); };
        room.loadContacts().subscribe(function () {
            if (isRoomNotExist()) {
                _this.rooms.push({ room: room, index: index });
                _this.rooms.sort(function (a, b) { return a.index - b.index; });
            }
        });
    };
    TransformOrdersComponent.prototype.openFiles = function (order) {
        var _this = this;
        var dialogRef = this.dialog.open(EcosystemFileDialogComponent, {
            width: '800px',
            height: '600px',
            data: {
                ecosystem: this.step.ecosystem,
                stepId: this.step.id
            }
        });
        dialogRef.afterClosed().subscribe(function (file) {
            if (file) {
                _this.addFileToOrder(file, order);
            }
        });
    };
    TransformOrdersComponent.prototype.removeJunctionFromOrder = function (junction, order) {
        this.ecoFileJunctionService.remove(junction.id).subscribe(function () {
            order.ecosystem_file_junction.splice(order.ecosystem_file_junction.indexOf(junction), 1);
        });
    };
    TransformOrdersComponent.prototype.addFileToOrder = function (file, order) {
        var fileJunction = new EcosystemFileJunction({
            file_id: file.id,
            item_id: order.id,
            item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_TRANSFORMATION_ORDER
        });
        this.ecoFileJunctionService.create(fileJunction).subscribe(function (createdFileJunction) {
            if (createdFileJunction) {
                order.ecosystem_file_junction.push(createdFileJunction);
            }
        });
    };
    TransformOrdersComponent.prototype.seeFullTransformation = function () {
        this.dialog.open(TransformDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR, { data: {
                id: this.transform.id,
                readOnly: true,
            } }));
    };
    TransformOrdersComponent.prototype.getTransformationIcon = function () {
        if (!this.transform || !this.transform.transformation_type_data) {
            return;
        }
        var iconName = this.transform.transformation_type_data.view.split(' ')[0].toLowerCase();
        return "../../../../../assets/png/ecosystem/types/" + iconName + ".png";
    };
    TransformOrdersComponent.prototype.getTransformationIconStyles = function () {
        if (this.transform.transformation_type_data.value) {
            if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.OTHER) {
                return 'ecosystem-compiler__menu-item-header-container-icon--other';
            }
            else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.METHANIZATION) {
                return 'ecosystem-compiler__menu-item-header-container-icon--methanization';
            }
            else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.CONTROL) {
                return 'ecosystem-compiler__menu-item-header-container-icon--control';
            }
            else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
                return 'ecosystem-compiler__menu-item-header-container-icon--transport';
            }
            else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.UPCYCLING || this.transform.transformation_type_data.value === TRANSFORM_TYPE.RECYCLING) {
                return 'ecosystem-compiler__menu-item-header-container-icon--upcycling';
            }
            else {
                return 'ecosystem-compiler__menu-item-header-container-icon';
            }
        }
    };
    TransformOrdersComponent.prototype.isVisibleSite = function (site) {
        switch (site) {
            case 'site':
                return this.transform.is(TRANSFORM_TYPE.UPCYCLING)
                    || this.transform.is(TRANSFORM_TYPE.RECYCLING)
                    || this.transform.is(TRANSFORM_TYPE.SORTING)
                    || this.transform.is(TRANSFORM_TYPE.STORAGE)
                    || this.transform.is(TRANSFORM_TYPE.COMPOSTING);
            case 'geosite':
                return this.transform.is(TRANSFORM_TYPE.REPAIR) || this.transform.is(TRANSFORM_TYPE.OTHER);
            case 'service':
                return this.transform.is(TRANSFORM_TYPE.TRANSPORT);
        }
    };
    TransformOrdersComponent.prototype.getCategoryName = function (categoryName) {
        var translation = this._translate.instant(categoryName);
        return (categoryName.length > 7)
            ? translation.substr(0, 7) + '…'
            : translation;
    };
    TransformOrdersComponent.prototype.isIndependentTransformation = function () {
        return this.transform.isIndependent();
    };
    TransformOrdersComponent.prototype.filterUnits = function () {
        var _this = this;
        var filteredUnits = [this.units[PASSPORT_UNITS_TYPE.KG], this.units[PASSPORT_UNITS_TYPE.TONNE]];
        if (this.transform.is(TRANSFORM_TYPE.UPCYCLING) ||
            this.transform.is(TRANSFORM_TYPE.SORTING) ||
            this.transform.is(TRANSFORM_TYPE.COMPOSTING) ||
            this.transform.is(TRANSFORM_TYPE.METHANIZATION) ||
            this.transform.is(TRANSFORM_TYPE.TRANSPORT)) {
            if (!!this.transform.in_conversion && !this.transform.is(TRANSFORM_TYPE.TRANSPORT)) {
                var transform_unit_1 = this.units.find(function (x) { return x.id === _this.transform.input_unit; });
                if (transform_unit_1) {
                    var isTransformUnitPresentInUnitList = filteredUnits.find(function (x) { return x.id === transform_unit_1.id; });
                    if (!isTransformUnitPresentInUnitList) {
                        filteredUnits.push(transform_unit_1);
                    }
                }
            }
        }
        else {
            return this.units;
        }
        return filteredUnits;
    };
    TransformOrdersComponent.prototype.isTransformTypeOfTransport = function () {
        return this.transform && this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT;
    };
    TransformOrdersComponent.prototype.isTransformTypeOfStorage = function () {
        return this.transform && this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE;
    };
    TransformOrdersComponent.prototype.isInOutDisplayed = function () {
        return this.transform && this.transform.input_unit && this.transform.output_unit && this.transform.output_quantity;
    };
    TransformOrdersComponent.prototype.updateInputQuantity = function (amount, index) {
        amount = this.step.orders[index].transformFromInView(amount);
        this.step.orders[index].in_order_passport.quantity = amount;
        this.dirty = true;
        var prefill = this.prefillService.prefillUpdateIfOrderChanged(this.orders[index], true);
        if (prefill[this.orders[index].id]) {
            var newOut_1 = prefill[this.orders[index].id].out ? prefill[this.orders[index].id].out.after : null;
            this.step.orders.forEach(function (order) {
                order.out_order_passport.quantity = newOut_1;
                prefill[order.id] = {};
            });
        }
        this.ecosystemCompilerSharedActionsService.prefillSetDirty(prefill);
        this.onChange$.next();
    };
    TransformOrdersComponent.prototype.updateOutputQuantity = function (amount) {
        amount = this.orders[0].transformFromOutView(amount);
        this.orders[0].out_order_passport.quantity = amount;
        var prefill = this.prefillService.prefillUpdateIfOrderChanged(this.orders[0], false);
        if (prefill[this.orders[0].id]) {
            prefill[this.orders[0].id] = {};
        }
        this.ecosystemCompilerSharedActionsService.prefillSetDirty(prefill);
        this.dirty = true;
        this.onChange$.next();
    };
    TransformOrdersComponent.prototype.expandInputsTextArea = function () {
        // const inputsTextAreas: ElementRef [] = this.inputDescriptionTextareas.toArray();
        this.inputDescriptionTextareas.map(function (input) { return setTimeout(function () {
            setTextareaHeightToFitContent([input]);
        }); });
    };
    TransformOrdersComponent.prototype.expandInputTextArea = function (index) {
        var inputsTextareas = this.inputDescriptionTextareas.toArray();
        setTimeout(function () { return setTextareaHeightToFitContent([
            inputsTextareas[index]
        ]); });
    };
    TransformOrdersComponent.prototype.expandSectionArea = function () {
        this.expandInputsTextArea();
        this.expandOutputTextArea();
        this.expandStepTextArea();
    };
    TransformOrdersComponent.prototype.expandOutputTextArea = function () {
        var _this = this;
        setTimeout(function () { return setTextareaHeightToFitContent([
            _this.outputDescriptionTextarea
        ]); });
    };
    TransformOrdersComponent.prototype.expandSummaryTextAreas = function () {
        var _this = this;
        setTimeout(function () { return setTextareaHeightToFitContent([
            _this.transformDescriptionTextarea
        ]); });
    };
    TransformOrdersComponent.prototype.expandStepTextArea = function () {
        var _this = this;
        setTimeout(function () { return setTextareaHeightToFitContent([
            _this.stepDescriptionTextarea
        ]); });
    };
    TransformOrdersComponent.prototype.getInputUnit = function (index) {
        if (this.transform.input_unit) {
            return this.transform.input_unit;
        }
        else if (this.orders[index]._isWithoutUnit(this.transform.transformation_type)) {
            return this.orders[index].unitInView;
        }
        else {
            return PASSPORT_UNITS_TYPE.KG;
        }
    };
    TransformOrdersComponent.prototype.getOutputUnit = function () {
        if (this.transform.output_unit) {
            return this.transform.output_unit;
        }
        else if (this.orders[0]._isWithoutUnit(this.transform.transformation_type)) {
            return this.orders[0].unitOutView;
        }
        else {
            return PASSPORT_UNITS_TYPE.KG;
        }
    };
    // replace 1 and 1000 with checking of the previous child unit( if is KG or ton(s))?
    TransformOrdersComponent.prototype.getInputConversion = function (index) {
        return this.orders[index].getConversionForInput();
    };
    TransformOrdersComponent.prototype.getOutputConversion = function () {
        if (this.transform.out_conversion) {
            return this.transform.out_conversion;
        }
        else if (this.orders[0]._isWithoutUnit(this.transform.transformation_type)) {
            return this.orders[0].conversionOutDefaultView;
        }
        else {
            return PASSPORT_UNITS_TYPE.KG;
        }
    };
    TransformOrdersComponent.prototype.sameUnitButDifferentConversion = function (index) {
        return this.ecosystemCompilerSharedActionsService.sameUnitButDifferentConversion(this.orders[index], this.transform, null);
    };
    TransformOrdersComponent.prototype.isInputConversionDisabledByDefault = function (index) {
        return this.sameUnitButDifferentConversion(index) || this.orders[index].in_order_passport.isWeightExist();
    };
    TransformOrdersComponent.prototype.updateInputWeight = function (orderIndex, data) {
        return this.updateWeight(this.orders[orderIndex].in_order_passport, data);
    };
    TransformOrdersComponent.prototype.updateWeight = function (order, data) {
        order.setOrResetWeight(data.value, data.isActive);
        if (!data.isActive) {
            this.dirty = true;
            this.onChange$.next();
        }
    };
    TransformOrdersComponent.prototype.getPreviousStepText = function (value) {
        if (this.step.getTypeName(value) === 'TRANSFORM') {
            return this._translate.instant('TRANSFORMATION');
        }
        else {
            return this._translate.instant(this.step.getTypeName(value));
        }
    };
    TransformOrdersComponent.prototype.getUnitText = function (unit) {
        return this.transform.getUnitText(unit);
    };
    TransformOrdersComponent.prototype.getPreviousStepContainerTitle = function () {
        if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
            return this._translate.instant('Deposit to be stored');
        }
        else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
            return this._translate.instant('Deposits to be transported');
        }
        else {
            return this._translate.instant('Input');
        }
    };
    TransformOrdersComponent.prototype.getPreviousStepInputOrMaterialTitle = function () {
        if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
            return this._translate.instant('Material to store');
        }
        else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
            return this._translate.instant('Material to be transported');
        }
        else {
            return this._translate.instant('Input material');
        }
    };
    TransformOrdersComponent.prototype.getPreviousStepQuantityText = function () {
        if (this.isTransformTypeOfTransport() || this.isTransformTypeOfStorage()) {
            return this._translate.instant('Quantity');
        }
        else {
            return this._translate.instant('Input quantity');
        }
    };
    TransformOrdersComponent.prototype.getPreviousStepDateText = function () {
        if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
            return this._translate.instant('Start date of storage');
        }
        else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
            return this._translate.instant('Pickup date');
        }
        else {
            return this._translate.instant('Input date');
        }
    };
    TransformOrdersComponent.prototype.getStorageOrDeliveryText = function () {
        if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
            return this._translate.instant('End date of storage');
        }
        else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
            return this._translate.instant('Delivery date');
        }
    };
    TransformOrdersComponent.prototype.checkIsNotTransportOrStorageTransformation = function () {
        if (this.transform && this.transform.transformation_type_data) {
            if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
                return false;
            }
            else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
                return false;
            }
        }
        return true;
    };
    TransformOrdersComponent.prototype.onStepDescriptionChange = function (description) {
        this.step.description = description;
        this.saveStep();
    };
    TransformOrdersComponent.prototype.onOutputDescriptionChange = function (description) {
        this.orders[0].out_order_passport.description = description;
        this.saveStep();
    };
    TransformOrdersComponent.prototype.onInputDescriptionChange = function (description, index) {
        this.orders[index].in_order_passport.description = description;
        this.saveStep();
    };
    TransformOrdersComponent.prototype.isWithoutConversionFactor = function (index) {
        if (this.getInputConversion(index) === null) {
            return true;
        }
    };
    TransformOrdersComponent.prototype.onPickupPlaceChange = function (place, index) {
        this.orders[index].pickup_address = place.description;
        this.saveStep(true);
    };
    TransformOrdersComponent.prototype.onDeliveryPlaceChange = function (place) {
        for (var i = 0; i < this.orders.length; i++) {
            this.orders[i].delivery_address = place.description;
        }
        this.saveStep(true);
    };
    return TransformOrdersComponent;
}());
export { TransformOrdersComponent };
