<div *ngIf="passport" class="passport-item" [class.selected]="isSelected" [ngClass]="getClassColor()">
    <div class="checkbox-container" *ngIf="isSelectable">
        <app-base-checkbox (isCheckedChange)="toggleState()" [isChecked]="isSelected"></app-base-checkbox>
    </div>
    <passport-family-label [familyType]="passport.family"></passport-family-label>
    <div class="passport-item-img" (click)="navigateToPassport()">
        <img [src]="passport.thumbnail || passport.image" (error)="handleImageError($event)" alt="Passport Image">
        <div *ngIf="displayStatus" class="status-icon" [mdTooltipPosition]="'above'"
            mdTooltip="{{ getStatus() | translate }}" appCustomTooltip>
            <app-passports-status-icon [status]="passport.status"></app-passports-status-icon>
        </div>
    </div>
    <div class="passport-mainInfo-container" (click)="navigateToPassport()">
        <div>
            <div>
                <h3 [mdTooltip]="getPassportName() + '- V' + passport.version" [mdTooltipPosition]="'right'"
                    [validateOnlyScrolled]="true" appCustomTooltip
                    [tooltipClass]="['passportlist__name-tooltip', 'passportlist__name-tooltip--left', 'background-primary-color']">
                    {{getPassportName()}} - V{{passport.version}}
                </h3>
                <div>
                    <span>{{'Category' | translate}}:</span>
                    <span title="{{ (passport.category? passport.category.name : 'CategoryIsMissing') | translate }}">
                        {{ (passport.category? passport.category.name : 'CategoryIsMissing') | translate }}
                    </span>
                </div>
                <div>
                    <span>{{'Materials' | translate}}:</span>
                    <span class="troncate" title="{{getNomenclatureNames() | translate}}">{{ getNomenclatureNames() |
                        translate }}</span>
                </div>
                <div>
                    <span>{{'Unit' | translate}}:
                        <strong>{{(passport.unit_text ? passport.unit_text : 'Unit is missing') | translate }}</strong>
                    </span>
                    <span>/ {{"Version" | translate}}: {{passport.version}} {{passport.count_versions > 1 ? ('(' +
                        passport.count_versions + ' ' + ("Versions_available" | translate) + ')') : ''}}</span>
                </div>
            </div>
            <div>
                <div class="modification-details">
                    <span> {{'Modified' | translate}}:</span>
                    <span>{{formatPassportModifiedDate(passport.updated_at) | localizedDate}}</span>
                    <span>{{'by' | translate}}:</span>
                    <span [mdTooltipPosition]="'above'"
                        mdTooltip="{{ passport.family === 1 ? passport.user.username : passport.user.company_name | translate }}" appCustomTooltip>
                        {{passport.family === 1 ? passport.user.username : passport.user.company_name}}
                    </span>
                </div>
                <passport-rating [passport]="passport"></passport-rating>
            </div>
        </div>
        <div class="tag-container">
            <div>
                <div *ngIf="getTagText() !== ''" class="tagDatabase" [mdTooltipPosition]="'above'"
                    mdTooltip="{{ 'tagNomenclatureHoverPassportBanner' | translate }}" appCustomTooltip>
                    <img *ngIf="passport.synchronization_data.getLogoPath() && passport.haveCarbonData()"
                        [src]="passport.synchronization_data.getLogoPath()" alt="Logo DataBase">
                    <span>
                        {{getTagText()}}
                    </span>
                </div>
            </div>
            <div>
                <span *ngIf="isWaitingForRating()">{{'Waiting for rating' | translate}}</span>
            </div>
            <!--
                Pour la version 2
            <div class="tag">
                <span>NF Environnement</span>
            </div>-->
        </div>
    </div>
    <div class="action-grid">
        <button *ngIf="actionButtonsConfig.duplicate" class="icon-button" (click)="onDuplicatePassport()">
            <app-duplicate-icon [sizeMultiplier]="1.3" [color]="'#fff'"></app-duplicate-icon>
        </button>
        <button *ngIf="actionButtonsConfig.delete" [disabled]="user_id !== +passport.user.id" class="icon-button danger"
            (click)="onDeletePassport()" [class.selected]="isSelected">
            <app-trash-icon [sizeMultiplier]="1.3" [color]="'#fff'"></app-trash-icon>
        </button>
        <button *ngIf="actionButtonsConfig.copyId" class="icon-button" (click)="copyPassportIdToClipboard()">
            <p>Id</p>
        </button>
        <button *ngIf="actionButtonsConfig.version" class="icon-button version" (click)="onVersionClick()"
            [class.version-selected]="isVersionSelected" [mdTooltipPosition]="'above'"
            mdTooltip="{{ 'VERSION_HOVER_TOOLTIP' | translate }}" appCustomTooltip
            [tooltipClass]="['passportlist__version-tooltip']">
            <p>Vers.</p>
        </button>
    </div>

</div>