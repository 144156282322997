import { TransformService } from '../../../../../entities/transformation/services/transform.service';
import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Searchable } from '../../../../../shared/models/searchable.class';
import { FormControl } from '@angular/forms';
import {
  ECustomListKeyPairClass,
  ECustomListRecordStatus,
  ICustomListKeyPair,
  ICustomListRecord
} from '../../../../../shared/custom-list/interfaces/custom-list.interface';
import { MdDialog } from '@angular/material';
import { TsmListService } from '../../../../../entities/transformation/services/tsm-list.service';
import { Transform } from '../../../../../entities/transformation/models/transform.class';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import { TransformDetailsDialogComponent }
  from '../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { getTransformTypeLcView, TRANSFORM_TYPES } from '../../../../transformation-manager/values/transform-types.const';
import { IMapMarker } from '../../../../../shared/map-view/map-marker.interface';
import { TransformLocation } from '../../../../../entities/transformation/models/transform-location.class';
import { NavigationService } from '../../../../../shared/services/navigation.service';
import { ModuleNames } from '../../../../../shared/values/module-names.const';
import { getSearchStatus } from '../../../../../shared/helpers/search-status-update';
import { TRANSFORM_STATUSES } from '../../../../transformation-manager/values/transform-statuses.const';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { TransformStatus } from '../../../../../entities/transformation/models/transform-status.class';
import * as moment from 'moment';
import { isIndependent } from '../../../../transformation-manager/values/transform-dependency.const';
import { CurrentUserService } from '../../../../../shared/services/current-user.service';
import { ERoleIds } from '../../../../../shared/values/role-ids.enum';
import { Subscription } from 'rxjs/Rx';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'admin-transform-catalog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'admin-transform-catalog.component.html'
})
export class AdminTransformCatalogComponent implements OnInit, OnDestroy {
  sortOptions: any = [
    { field: 'name', view: 'Name' },
    { field: 'tsm_name', view: 'Transformation Manager' }
  ];

  @Input() cloudIcon: boolean = false;
  sortValue: string = 'name';

  TRANSFORM_TYPES = TRANSFORM_TYPES;
  tsms: Searchable[];
  tsmId: number;
  search = new FormControl();
  type: number;
  mapIsReady: boolean;
  markers: IMapMarker[] = [];
  records: ICustomListRecord[] = [];
  specialAdminFilterStatuses = [
    TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION,
    TRANSFORM_STATUSES.ASK_FOR_MODIFICATION,
    TRANSFORM_STATUSES.PUBLISHED,
  ];
  adminFilterStatuses = [...this.specialAdminFilterStatuses];
  searchStatus = [];
  private recordsLoadingRequest;
  isSpecialAdmin: boolean;

  isNextPageLoading = false;
  currentPage = 1;
  totalPagesCount = 0;
  miltilanguageSubscription: Subscription;

  constructor(private _transformService: TransformService,
    protected _navigationService: NavigationService,
    private tsmListService: TsmListService,
    private mdDialog: MdDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private currentUserService: CurrentUserService,
    private navBarState: NavbarStateService) {
    this
      .tsmListService
      .get()
      .subscribe((tsms: Searchable[]) =>
        this.tsms = tsms
      )
      ;
    this.isSpecialAdmin = this._navigationService.getContext().roleId === ERoleIds.SPECIALADMIN;
  }
  setSearchStatus(): void {
    const getStatusValues = (statuses: TransformStatus[]) => statuses.map(status => status.value);
    if (this._navigationService.getModuleName() === ModuleNames.SPECIAL_ADMIN) {
      this.searchStatus = getStatusValues(this.specialAdminFilterStatuses);
    }
    if (this._navigationService.getModuleName() === ModuleNames.ADMIN) {
      this.searchStatus = getStatusValues(this.adminFilterStatuses);
    }
  }
  ngOnInit(): void {
    this.miltilanguageSubscription = this.navBarState.multilanguageObservable$.subscribe(() => this.changeDetectorRef.detectChanges());
    this.setSearchStatus();
    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => this.getRecords());
    this.getRecords();
    this.getMarkers();
  }
  ngOnDestroy() {
    this.miltilanguageSubscription.unsubscribe();
  }
  downloadPdfReport(): string {
    return this._transformService.getPdfReportUrl({
      supervisor: true,
      search: this.search.value || null,
      transformation_type: this.type || null,
      tsm: this.tsmId || null,
      timezone_offset: moment().utcOffset()
    });
  }

  onEndOfListTriggered() {
    if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount) {
      this.getRecords(this.currentPage + 1);
    }
  }

  get queryParams() {
    let query: any = {
      expand: 'files,materials,tsm_name,sites,sectors,tags,status',
      admin: 1,
      supervisor: 1,
      search: this.search.value || null,
      transformation_type: this.type || null,
      tsm: this.tsmId || null,
      'status[]': this.searchStatus || null,
      sort: this.sortValue
    };
    if (this.navBarState.multilanguage) {
      query.english = true;
    }
    return query;
  }

  getRecords(forPage: number = 0) {
    if (!!this.recordsLoadingRequest) {
      this.recordsLoadingRequest.unsubscribe();
    }

    const params = {
      ...this.queryParams,
      page: forPage,
      paginate: 1,
    };

    this.isNextPageLoading = true;
    this.recordsLoadingRequest = this._transformService
      .getWithPaginationData(params)
      .subscribe((data) => {
        if (forPage === 0) {
          this.records = [];
        }
        const headers = data.headers;
        this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
        this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
        data.json().map((item: any) => this.addRecord(new Transform(item)));
        this.mapIsReady = true;
        this.recordsLoadingRequest = null;
        this.changeDetectorRef.detectChanges();
        this.isNextPageLoading = false;
      });
  }

  getMarkers() {
    this.recordsLoadingRequest = this._transformService
    .get(this.queryParams)
    .subscribe((data: Transform[]) => {
        this.mapIsReady = false;
        this.markers = [];
        data.forEach((item: Transform) => this.addMarkers(item));
        this.mapIsReady = true;
        this.recordsLoadingRequest = null;
        this.changeDetectorRef.detectChanges();
    });
  }

  addMarkers(data: Transform): void {
    this.markers.push(...data.sites.map((item: TransformLocation) => this.toMarker(data.name, item, data.independent)));
  }

  toMarker(transformName: string, data: TransformLocation, independent: number): IMapMarker {
    return {
      lat: data.lat,
      lng: data.lng,
      name: transformName,
      lines: [
        `${data.name}`,
        `${data.address}`
      ],
      icon: this.isSpecialAdmin ?
        (isIndependent(independent) ? 'assets/png/map/localisation-blue.png' : 'assets/png/map/localisation-orange.png')
        : null
    };
  }

  addRecord(data: Transform) {
    let materialsList: ICustomListKeyPair[] = [];
    let keywordsOrControlType: ICustomListKeyPair[] = [];
    const reversedColumnEntries: Partial<ICustomListKeyPair> = { cssClass: ECustomListKeyPairClass.REVERSED_LIST_COLUMN, seperator: ' ' };

    if (data.transformation_type_data.includes.incomeOutcome) {
      const incomeMaterials = data.inputMaterials
        .map((material) => material.name)
        .join(',');
      const outcomeMaterials = data.outputMaterials
        .map((material) => material.name)
        .join(',');

      const incomeTags = data.inputTags.map((tag) => tag.name).join(', ');
      const outcomeTags = data.outputTags.map((tag) => tag.name).join(', ');

      materialsList = [
        { key: 'Input', value: incomeMaterials },
        { key: 'Output', value: outcomeMaterials }
      ];

      keywordsOrControlType = [
        { key: 'Keywords', value: incomeTags },
        { key: 'Keywords', value: outcomeTags }
      ];
    } else if (data.transformation_type_data.includes.acceptedMaterials) {
      materialsList = [
        { key: 'Accepted materials', value: data.acceptedMaterialsView, ...reversedColumnEntries },
      ];

      if (data.transformation_type_data.includes.controlType) {
        keywordsOrControlType = [
          { key: 'Control type', value: data.controlTypeView, ...reversedColumnEntries },
        ];
      }
    }

    let listOfLocations: ICustomListKeyPair[] = [];
    if (data.transformation_type_data.includes.serviceArea) {
      listOfLocations = data.sectors.map(sector => {
        return { key: '', seperator: '', value: sector.name };
      });

    } else if (data.transformation_type_data.includes.storingSites) {
      listOfLocations = data.sites.map((site) => {
        return { key: '', seperator: '', value: site.name };
      });
    }

    let status: ECustomListRecordStatus;
    switch (data.status) {
      case TRANSFORM_STATUSES.DRAFT.value:
        status = ECustomListRecordStatus.NO_STATUS;
        break;
      case TRANSFORM_STATUSES.PUBLISHED.value:
        status = ECustomListRecordStatus.CONFIRM;
        break;
      case TRANSFORM_STATUSES.REJECTED.value:
        status = ECustomListRecordStatus.CANCELED;
        break;
      case TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.value:
        status = ECustomListRecordStatus.QUESTION;
        break;
      default:
        status = ECustomListRecordStatus.NO_STATUS;
    }

    this.records.push(
      this.createTransformationRecord(
        status,
        data.transformation_type_data.value ? getTransformTypeLcView(data.transformation_type_data.value) : '',
        data.viewName,
        data.transformation_type_data.view.replace(/./, (c: string) => c.toUpperCase()),
        materialsList,
        keywordsOrControlType,
        listOfLocations,
        data.tsm_name,
        data
      )
    );
  }

  createTransformationRecord(status: ECustomListRecordStatus,
    icon: string,
    title: string,
    subtitle: string,
    materials: ICustomListKeyPair[],
    keywordsOrControlType: ICustomListKeyPair[],
    sites: ICustomListKeyPair[],
    author: string,
    transform: Transform, ): CustomListRecord {
    return CustomListRecord
      .build()
      .setPrimaryKey(transform.id)
      .setStatus(status)
      .setIndependent(isIndependent(transform.independent))
      .addIconColumn(icon)
      .addTitleColumn(title, subtitle, transform.english_name)
      .addListColumn(materials)
      .addListColumn(keywordsOrControlType)
      .addListColumn(sites)
      .addAuthorColumn(author)
      .addControlsColumn([{
        fn: (data: ICustomListRecord) => {
          this.mdDialog.open(TransformDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR,
            data: {
              id: transform.id,
              readOnly: true,
            }
          });
        },
        name: 'See details'
      }, {
        fn: (data: ICustomListRecord) => {
          location.href = 'mailto:';
        },
        name: 'Send Email'
      }]);
  }

  toggleState(state: number) {
    this.searchStatus = getSearchStatus(state, this.searchStatus);
    this.getRecords();
  }

}
