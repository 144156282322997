/**
 * Created by Aleksandr C  on 24.03.17.
 */
import { EventEmitter } from '@angular/core';
import { SUPERVISOR_CONTEXT } from '../../dashboards/supervisor/supervisor.context';
import { DEFAULT_CONTEXT } from '../navigation/default.context';
import { GUEST_CONTEXT } from '../auth/guest.context';
import { ERoleIds } from '../values/role-ids.enum';
import { AGENT_CONTEXT } from '../../dashboards/agent/agent.context';
import { TRANSFORM_CONTEXT } from '../../dashboards/transformation-manager/transformation-manager.context';
import { ECOSYSTEM_CONTEXT } from '../../dashboards/ecosystem-manager/ecosystem-manager.context';
import { DEPOSIT_OWNER_CONTEXT } from '../../dashboards/deposit-owner/deposit-owner.context';
import { ADMIN_CONTEXT } from '../../dashboards/admin/admin.context';
import { SUPERADMIN_CONTEXT } from '../../dashboards/superadmin/superadmin.context';
import { SALES_CONTEXT } from '../../dashboards/sales/sales.context';
import { MANUFACTURER_CONTEXT } from '../../dashboards/manufacturer/manufacturer.context';
import { SPECIALADMIN_CONTEXT } from '../../dashboards/specialadmin/specialadmin.context';
import { TRANSFORM_INDEPENDENT_CONTEXT } from '../../dashboards/transformation-manager-independent/transformation-manager-independent.context';
import { ModuleNames } from '../values/module-names.const';
import { LocalStorageService, STORAGEKEYMAP } from './localStorage.service';
var NavigationService = /** @class */ (function () {
    function NavigationService(localStorageService) {
        this.localStorageService = localStorageService;
        this.changeModule = new EventEmitter();
        this.showNavBar = false;
        this.contexts = [
            GUEST_CONTEXT,
            SUPERVISOR_CONTEXT,
            DEFAULT_CONTEXT,
            SALES_CONTEXT,
            AGENT_CONTEXT,
            TRANSFORM_CONTEXT,
            ECOSYSTEM_CONTEXT,
            DEPOSIT_OWNER_CONTEXT,
            ADMIN_CONTEXT,
            SUPERADMIN_CONTEXT,
            SPECIALADMIN_CONTEXT,
            MANUFACTURER_CONTEXT,
            TRANSFORM_INDEPENDENT_CONTEXT
        ];
    }
    Object.defineProperty(NavigationService.prototype, "roleId", {
        get: function () {
            return this.context && this.context.roleId;
        },
        enumerable: true,
        configurable: true
    });
    NavigationService.prototype.getContext = function () {
        return this.context;
    };
    NavigationService.prototype.setContext = function (moduleName) {
        this.context = this.contexts
            .find(function (context) { return context.moduleName === moduleName || context.apiModuleName === moduleName; });
    };
    NavigationService.prototype.setModule = function (module) {
        if (this.context && this.context.moduleName) {
            this.cleanUpRoleSpecificData(this.context.moduleName);
        }
        this.setContext(module);
        this.changeModule.emit(this.context.moduleName);
    };
    NavigationService.prototype.getNavigationMenu = function () {
        return this.context ? (this.context.navigation || []) : [];
    };
    NavigationService.prototype.getModuleClass = function () {
        return this.context ? this.context.themeClass : 'b-module-default';
    };
    NavigationService.prototype.getModuleName = function () {
        return this.context ? this.context.moduleName : ModuleNames.GUEST;
    };
    NavigationService.prototype.getRoleText = function () {
        return this.context ? this.context.roleText : '';
    };
    NavigationService.prototype.getHomeUrl = function () {
        return this.context ? this.context.homeUrl : '';
    };
    NavigationService.prototype.isSupervisor = function () {
        return this.context.roleId === ERoleIds.SUPERVISOR;
    };
    NavigationService.prototype.isESM = function () {
        return this.context.roleId === ERoleIds.ECOSYSTEM_MANAGER;
    };
    NavigationService.prototype.cleanUpRoleSpecificData = function (moduleName) {
        var key = STORAGEKEYMAP[moduleName];
        if (key) {
            this.localStorageService.removeValue(key);
        }
    };
    return NavigationService;
}());
export { NavigationService };
