/**
 * Created by kirill on 13.07.17.
 */
import { ActiveRecord } from '../../models/active-record.class';
import { IChatUser } from '../interfaces/chat-user.interface';
import { ChatRoom } from './chat-room.model';
import { ChatService } from '../chat.service';
import { ServiceLocator } from '../../services/service-locator';
import { Observable } from 'rxjs/Observable';

export class ChatUser extends ActiveRecord implements IChatUser {

  provider: any;

  id: number;
  image: string;
  name: string;
  group: number;
  selected: boolean;
  status: number;
  new_messages: string | number;

  // context chat fields
  object_name: string;
  ecosystem_name: string;
  contact_name: string;
  contact_username: string;
  thread_id: number;

  visible:boolean = true;

  get unreadMessages() {
    return this._room ? this._room.unreadMessages : 0;
  }

  get thread(): ChatRoom {
    return this._room;
  }

  private _chatService: ChatService = ServiceLocator.injector.get(ChatService);
  private _room: ChatRoom;

  protected fields(): string[] {
    return [
      'id',
      'user_id',
      'image',
      'name',
      'group',
      'object_name',
      'ecosystem_name',
      'contact_name',
      'contact_username',
      'thread_id',
      'new_messages',
      'avatar_url'
    ];
  }

  extractData(data: any): any {
    //TODO temp
    if (data.hasOwnProperty('new_messages')) {
      data['new_messages'] = Number(data['new_messages']);
    }
    if (data.hasOwnProperty('user_id')) {
      data['id'] = data['user_id'];
    }
    if (data.hasOwnProperty('contact_name')) {
      data['name'] = data['contact_name'];
    }
    if (data.hasOwnProperty('contact_username')) {
      data['username'] = data['contact_username'];
    }
    if (data.hasOwnProperty('avatar_url')) {
      data['image'] = data['avatar_url'];
    }
    if (!data.hasOwnProperty('image')) {
      data['image'] = '../assets/png/profile.png';
    } else {
      if (data['image'] === null) {
        data['image'] = '../assets/png/profile.png';
      }
    }
    if (data.hasOwnProperty('role')) {
      data['group'] = data['role'];
    }

    // console.log(data);
    return super.extractData(data);
  }

  isContextChat(): boolean {
    return !!this.ecosystem_name && !!this.thread_id;
  }

  createRoom(): Observable<ChatRoom> {
    let roomSource = this.isContextChat()
      ? this._chatService.getContextRoom(this.thread_id)
      : this._chatService.getPrivateRoom(this.id);
    return roomSource
      .do((room) => this.setRoom(room))
      .flatMap((room) => room.loadContacts().map((contacts: ChatUser[]) => room))
      .do((room) => this.joinRoom());
  }

  setRoom(room: ChatRoom): this {
    // console.log('Chat SET ROOM', room);
    room.unreadMessages = Number(this.new_messages);
    this._room = room;
    return this;
  }

  getRoom() {
    return this._room;
  }

  joinRoom() {
    this._room.connect();
  }

  exitRoom() {
    if(this._room) {
      this._room.disconnect();
    }
  }

}
