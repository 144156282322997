<md-dialog-content>
  <div class="deposit-delete-modal">
    <div class="deposit-delete-modal__heading">
      <div class="deposit-delete-modal__image">
        <img src="assets/png/warning_green.png" class="deposit-delete-modal__triangle">
        <span class="deposit-delete-modal__exclamation">!</span>
      </div>
    </div>

    <div class="deposit-delete-modal__text-container">
      <span class="deposit-delete-modal__text" [innerHTML]="warningMessage"></span>
    </div>

    <div class="deposit-delete-modal__footer">
      <button class="deposit-delete-modal__button" md-raised-button
        (click)="confirm()">{{ 'Confirm' | translate | uppercase}}</button>
      <button class="deposit-delete-modal__button gray" md-raised-button
        (click)="closeDialog()">{{ 'Cancel' | translate | uppercase}}</button>
    </div>
  </div>
</md-dialog-content>
