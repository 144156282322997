import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
import { ISupervisorStats } from '../interfaces/supervisor-stats.interface';

export class SupervisorStats extends BaseModel implements ISupervisorStats {
  deposits_published: number;
  deposits_rejected: number;
  needs_published: number;
  needs_rejected: number;
  deposits_characterized: number;
  transformations_published: number;
  transformations_rejected: number;
  deposit_orders_processed: number;
  offers_processed: number;
  transformatin_orders_processed: number;

  fields(): string[] {
    return [
      'deposits_published',
      'deposits_rejected',
      'needs_published',
      'needs_rejected',
      'deposits_characterized',
      'transformations_published',
      'transformations_rejected',
      'deposit_orders_processed',
      'offers_processed',
      'transformatin_orders_processed'
    ];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    Utils.convertJsonStringToNumber(json, [
      'deposits_published',
      'deposits_rejected',
      'needs_published',
      'needs_rejected',
      'deposits_characterized',
      'transformations_published',
      'transformations_rejected',
      'deposit_orders_processed',
      'offers_processed',
      'transformatin_orders_processed'
    ]);
    return json;
  }
}
