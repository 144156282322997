/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../entities/generic-passport/partials/generic-passport-list/generic-passport-list.component.ngfactory";
import * as i2 from "../../../../entities/generic-passport/partials/generic-passport-list/generic-passport-list.component";
import * as i3 from "@angular/material";
import * as i4 from "../../../../shared/services/navigation.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../../shared/services/zone.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../shared/services/pagination-unmarshalling.service";
import * as i9 from "./generic-passport-dashboard.component";
import * as i10 from "../../../../entities/generic-passport/service/generic-passport.service";
var styles_GenericPassportDashboardComponent = [];
var RenderType_GenericPassportDashboardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericPassportDashboardComponent, data: {} });
export { RenderType_GenericPassportDashboardComponent as RenderType_GenericPassportDashboardComponent };
export function View_GenericPassportDashboardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "generic-passport-dashboard"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "generic-passport-dashboard__aside"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "generic-passport-dashboard__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "generic-passport-list", [], null, null, null, i1.View_GenericPassportListComponent_0, i1.RenderType_GenericPassportListComponent)), i0.ɵdid(4, 114688, null, 0, i2.GenericPassportListComponent, [i3.MdDialog, i4.NavigationService, i3.MdDialog, i5.Router, i6.ZoneService, i7.TranslateService, i8.PaginationUnmarshallingService], { zoneId: [0, "zoneId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.zoneId; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_GenericPassportDashboardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "generic-passport", [], null, null, null, View_GenericPassportDashboardComponent_0, RenderType_GenericPassportDashboardComponent)), i0.ɵdid(1, 114688, null, 0, i9.GenericPassportDashboardComponent, [i3.MdDialog, i10.GenericPassportService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericPassportDashboardComponentNgFactory = i0.ɵccf("generic-passport", i9.GenericPassportDashboardComponent, View_GenericPassportDashboardComponent_Host_0, {}, {}, []);
export { GenericPassportDashboardComponentNgFactory as GenericPassportDashboardComponentNgFactory };
