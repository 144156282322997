<div class="depositlist__filter-container" #filterStateOfUse>
  <div *ngIf="isFilterStateOfUseVisible" class="depositlist__checkbox-filter">
    <div class="depositlist__choose"
    *ngFor="let stateOfUseDescription of stateOfUseKeys; let i = index"
    test-id="checkbox-div">
      <md-checkbox
        (change)="selectionChanged.emit()"
        id="state{{i}}"
        [(ngModel)]="stateOfUse[stateOfUseDescription]">
      </md-checkbox>
        <label for="input-state{{i}}" class="depositlist__choose-name" test-id="checkbox-label">
          {{stateOfUseDescription.replace('_', ' ') | translate | titlecase}}
        </label>
    </div>
  </div>

  <md-input-container
  class="depositlist__form-item--no-width"
  [floatPlaceholder]="'never'"
  (click)="isFilterStateOfUseVisible = !isFilterStateOfUseVisible">
  <input mdInput
        autocomplete="off"
        class="passportlist__no-caret"
        placeholder="{{ 'State of use' | translate }}"
        maxlength="0">
        <div class="passportlist__info-icons">
          <span *ngIf="isAnyStateOfUseFilterApplied" class="{{color != '' ? 'icon--rating-filter-' + color : 'icon--rating-filter'}}"></span>
          <span class="mat-select-arrow"></span>
        </div>
  </md-input-container>
</div>
