import {EventEmitter, Injectable} from '@angular/core';
import {IRolePermission, IRolePermissionContext} from '../interfaces/role-permission.interface';
import {User} from '../models/user.class';
import {ERoleIds} from '../values/role-ids.enum';
import {ADMIN_PERMISSIONS} from '../../dashboards/admin/admin.permission';
import {AGENT_PERMISSIONS} from '../../dashboards/agent/agent.permission';
import {DO_PERMISSIONS} from '../../dashboards/deposit-owner/deposit-owner.permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  public updateAppliedPermissions = new EventEmitter<IRolePermission[]>();
  public isBeforeApplied$ = new EventEmitter();
  public isAfterApplied$ = new EventEmitter();

  private isAfterApplied = false;

  private permissionContexts: IRolePermissionContext[] = [
    ADMIN_PERMISSIONS,
    DO_PERMISSIONS,
    AGENT_PERMISSIONS
  ];

  private permissionContext: IRolePermissionContext = this.emptyPermissionContext;
  private appliedPermissions: IRolePermission[] = [];

  get permissionList() {
    return this.permissionContext.permissions;
  }

  get isOnUserExpandLoad() {
    return this.isAfterApplied;
  }

  get appliedPermissionIds() {
    return this.appliedPermissions.map(permission => permission.id);
  }

  public setPermissionContext(role: ERoleIds) {
    this.permissionContext = this.permissionContexts.find(context => context.roleId === role) || this.emptyPermissionContext;
  }

  hasAppliedPermission(permission: string) {
    return !!this.appliedPermissionIds.find(applied => applied === permission);
  }

  public addPermissions(user: User, permissionId: string) {
    if (!!this.appliedPermissions.filter(applied => applied.id === permissionId)) {
      return;
    }
    this.addListOfValidPermission(user, [this.permissionList[permissionId]]);
  }

  private addListOfValidPermission(user: User, newPermissions: IRolePermission[]) {
    if (!user) {
      return;
    }
    const newAppliedPermissions = newPermissions.filter((permission) => permission.isApplied(user));
    this.appliedPermissions = [...this.appliedPermissions, ...newAppliedPermissions];
    if (newAppliedPermissions.length > 0) {
      this.emitUpdate();
    }
  }

  private emitUpdate() {
    this.updateAppliedPermissions.emit(this.appliedPermissions);
  }

  public removePermission(user: User, permissionId: string) {
    const lengthBefore = this.appliedPermissions.length;
    this.appliedPermissions = this.appliedPermissions.filter(applied => applied.id === permissionId);
    if (lengthBefore > this.appliedPermissions.length) {
      this.emitUpdate();
    }
  }

  public setPermissions(user: User, permissionsIds: string[]) {
    const permissionLengthBefore = permissionsIds.length;
    this.appliedPermissions = [];
    this.addListOfValidPermission(user, this.getUniqAndValidPermissions(permissionsIds));
    if (permissionLengthBefore > 0 && !!this.appliedPermissions.length) {
      this.emitUpdate();
    }
  }

  private getUniqAndValidPermissions(permissionsIds: string[]) {
    const distinctIDs = this.getDistinctIds(permissionsIds);
    const usedPermissions = new Set(this.appliedPermissionIds);
    return distinctIDs.map(id => this.permissionList[id]).filter(permission => !!permission
      && !usedPermissions.has(permission.id));
  }

  private getDistinctIds(permissionsIds: string[]) {
    const visited = new Set();
    return permissionsIds.filter(id => {
      if (!visited.has(id)) {
        visited.add(id);
        return true;
      }
      return false;
    });
  }

  public patchPermissions(user: User, permissionsIds: string[]) {
    this.addListOfValidPermission(user, this.getUniqAndValidPermissions(permissionsIds));
  }

  public resetPermissions() {
    this.appliedPermissions = [];
    this.emitUpdate();
  }

  public setDefaultPermissionOnContextChange(user: User, roleId: ERoleIds) {
    this.setPermissionContext(roleId);
    this.setPermissions(user, this.permissionContext.defaultPermissionsIdsBefore);
    this.isAfterApplied = false;
    this.isBeforeApplied$.emit();
  }

  public setDefaultPermissionsOnUserExpandLoad(user: User) {
    this.patchPermissions(user, this.permissionContext.defaultPermissionsIdsAfter);
    this.isAfterApplied = true;
    this.isAfterApplied$.emit();
  }

  get emptyPermissionContext(): IRolePermissionContext {
    return {
      roleId: null,
      defaultPermissionsIdsBefore: [],
      defaultPermissionsIdsAfter: [],
      permissions: {}
    };
  }
}
