import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import {Component, ElementRef, HostListener, Inject, ViewChild} from '@angular/core';
@Component({
  template: `
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>

    <need-details
      [id]="mdDialogData.id"
      [readOnly]="mdDialogData.readOnly"
      [showValidationButtons]="mdDialogData.showValidationButtons"
      (action)="mdDialogRef.close()"
    ></need-details>
  `,
  host: {
    'class': 'dialog-close-button-container need-details-dialog-container',
  }
})
export class NeedDetailsDialogComponent {

  constructor(public mdDialogRef: MdDialogRef<any>,
              private el: ElementRef,
              @Inject(MD_DIALOG_DATA) public mdDialogData: any) {
  }

}
