import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { GenericPassportMaterial } from '../models/generic-passport-materials.class';


@Injectable()
export class GenericPassportMaterialService extends CrudService {
  protected namespace = 'generic-passport-materials';
  protected ModelClass = GenericPassportMaterial;
}
