import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ApiService } from '../../../../shared/services/api.service';
import { IRankingFormula } from './ranking-formula.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class RankingFormulaService extends ApiService {
  public getFormula(): Observable<IRankingFormula> {
    return this.sendGet(this.getEndpoint('ranking-formula'))
      .map((response: Response) => response.json() && response.json().length > 0
        ? response.json()[0]
        : null
      );
  }

  public updateFormula(formula: IRankingFormula): Observable<IRankingFormula> {
    return this.sendPut(this.getEndpoint('ranking-formula/1'), formula)
      .map((response: Response) => response.json());
  }
}
