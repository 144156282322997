/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../deposit-site-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i3 from "@angular/material";
import * as i4 from "../../../../../shared/read-only/form-elems-hidden.directive";
import * as i5 from "../../../../../shared/read-only/read-only.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../deposit-site-details-base/deposit-site-details-base.component.ngfactory";
import * as i8 from "../deposit-site-details-base/deposit-site-details-base.component";
import * as i9 from "../../../../../shared/services/log-notification.service";
import * as i10 from "../../../../../shared/services/navigation.service";
import * as i11 from "@angular/common";
import * as i12 from "./deposit-site-details-preview.component";
import * as i13 from "../../../../../shared/services/bim.service";
var styles_DepositSiteDetailsPreviewComponent = [i0.styles];
var RenderType_DepositSiteDetailsPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DepositSiteDetailsPreviewComponent, data: {} });
export { RenderType_DepositSiteDetailsPreviewComponent as RenderType_DepositSiteDetailsPreviewComponent };
function View_DepositSiteDetailsPreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "save-submit-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["class", "save"], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createPreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MdButton_0, i2.RenderType_MdButton)), i1.ɵdid(2, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(3, 180224, null, 0, i3.MdButton, [i1.ElementRef, i1.Renderer, i3.FocusOriginMonitor], null, null), i1.ɵdid(4, 16384, null, 0, i3.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(5, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i1.ɵted(6, 0, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).disabled; var currVal_1 = true; var currVal_2 = ((i1.ɵnov(_v, 5).readOnlyService == null) ? null : i1.ɵnov(_v, 5).readOnlyService.readOnly); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("Save")); _ck(_v, 6, 0, currVal_3); }); }
export function View_DepositSiteDetailsPreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "deposit-site-details-base", [], null, null, null, i7.View_DepositSiteDetailsBaseComponent_0, i7.RenderType_DepositSiteDetailsBaseComponent)), i1.ɵdid(1, 49152, null, 0, i8.DepositSiteDetailsBaseComponent, [i9.LogNotificationService, i10.NavigationService, i6.TranslateService], { location: [0, "location"], isCompanyBuilding: [1, "isCompanyBuilding"], BIMs: [2, "BIMs"], uploader: [3, "uploader"], uploaderQueueEdit: [4, "uploaderQueueEdit"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositSiteDetailsPreviewComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.location; var currVal_1 = _co.isCompanyBuilding; var currVal_2 = _co.BIMs; var currVal_3 = _co.uploader; var currVal_4 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = !_co.readOnlyService.readOnly; _ck(_v, 3, 0, currVal_5); }, null); }
export function View_DepositSiteDetailsPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "deposit-site-details-preview", [], null, null, null, View_DepositSiteDetailsPreviewComponent_0, RenderType_DepositSiteDetailsPreviewComponent)), i1.ɵprd(512, null, i5.ReadOnlyService, i5.ReadOnlyService, []), i1.ɵdid(2, 114688, null, 0, i12.DepositSiteDetailsPreviewComponent, [i13.BIMService, i5.ReadOnlyService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var DepositSiteDetailsPreviewComponentNgFactory = i1.ɵccf("deposit-site-details-preview", i12.DepositSiteDetailsPreviewComponent, View_DepositSiteDetailsPreviewComponent_Host_0, { location: "location", uploader: "uploader", readOnly: "readOnly", isCompanyBuilding: "isCompanyBuilding", isValid: "isValid" }, { onSave: "onSave" }, []);
export { DepositSiteDetailsPreviewComponentNgFactory as DepositSiteDetailsPreviewComponentNgFactory };
