<deposit-site-details-base
  [location]="location"
  [isCompanyBuilding]="isCompanyBuilding"
  [isViewModeRestricted]="!type && isCompanyBuilding"
  [BIMs]="BIMs"
  [onDocumentSave$]="onDocumentSave$"
  [onUpdateUploadUrl$]="onUpdateUploadUrl$"
  [files]="files"
  [depositLocationFileService]="depositLocationFileService"
>
</deposit-site-details-base>

<div class="save-submit-container" *ngIf="!readOnlyService.readOnly">
  <button md-raised-button [disabled]="!location.name || !location.address" class="save" (click)="save()">{{ 'Save' | translate }}</button>
</div>
