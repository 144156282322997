<ng-container *ngIf="need?.loaded">
  <div class="need-details">
    <div class="need-details__row need-details__row--heading">
      <div class="need-details__heading flex justify-between align-start">
        <div class="need-details__heading--label">
          {{ 'Need' | translate }}
        </div>
        <div class="need-details__heading--info-modified">
          {{ 'Modified by' | translate }}: <img src="/assets/png/user--icon.png"
                                                class="need-details__heading--info-modified-icon">
          <span class="need-details__heading--info-name primary-color">
          <a href="javascript:void(0)">{{need.sales_name}}</a>
          </span>
          {{need.updated_at | date: 'dd.MM.y HH:mm'}}
        </div>
      </div>

      <div class="flex need-details__name-row">
        <div class="flex-column">
          <div class="need-details__header--label primary-color">{{ 'Need name' | translate }}</div>
          <div class="need-details__icon-wrapper">
            <div class="need-details__name-wrapper">
              <div class="need-details__header--name">{{need.name}}
              </div>
            </div>
            <span *ngIf="!!need.independent" class="need-details__header--icon"><i class="icon x3 icon--cloud-arrow-down"></i></span>
          </div>
        </div>
      </div>

    </div>

    <div class="need-details__separator flex">
      <div class="need-details__separator--number primary-color">
        1
      </div>
      <div class="need-details__separator--content background-primary-color">
        {{ 'Recipient' | translate | uppercase }}
      </div>
    </div>

    <div class="need-details__row flex need-details__row--client">
      <md-input-container class="need-details__input need-details__row--client-item">
        <input mdInput placeholder="{{ 'Company' | translate }}" [value]="need.client.name">
      </md-input-container>
      <md-input-container class="need-details__input need-details__row--client-item">
        <input mdInput placeholder="{{ 'Representative' | translate }}" [value]="need.client_contact.name">
      </md-input-container>
    </div>

    <div class="need-details__separator flex">
      <div class="need-details__separator--number primary-color">
        2
      </div>
      <div class="need-details__separator--content background-primary-color">
        <div>
          {{ 'Circular passport' | translate | uppercase}}
        </div>
        <div class="need-details__full-passport" *ngIf="parentModule === moduleNames.SUPERVISOR && !passport.isVoid"
             (click)="onSeeFullPassport()">
          <div class="need-details__full-passport-icon eye--icon"></div>
          <div class="need-details__full-passport-text">{{ 'See the full passport' | translate}}</div>
        </div>
      </div>
    </div>

    <passport-details-view *ngIf="passport" [passport]="passport" class="need-details__row">
    </passport-details-view>


    <div class="need-details__separator flex">
      <div class="need-details__separator--number primary-color">
        3
      </div>
      <div class="need-details__separator--content background-primary-color">
        {{ 'Delivery' | translate | uppercase}}
      </div>
    </div>

    <div class="need-details__row--delivery">
      <div class="flex justify-between">
        <div class="need-details__delivery-input flex-column">
          <div class="need-details__custom-label need-details__input-label"
               [ngClass]="getLabelClasses(need.quantity)">{{'Quantity needed' | translate}}</div>
          <div class="passport-details-view__input-wrapper">
            <div class="need-details__custom-label-quantity passport-details-view__input-row">
              <md-input-container>
                <input
                  mdInput
                  [value]="need.quantity">
              </md-input-container>
            </div>
          </div>
        </div>

        <div class="need-details__delivery-input flex-column">
          <div class="need-details__custom-label need-details__input-label"
               [ngClass]="getLabelClasses(passport.unit)">{{'Unit' | translate}}</div>
          <div class="passport-details-view__input-wrapper">
            <div class="need-details__custom-label-quantity passport-details-view__input-row">
              <md-input-container>
                <input
                  mdInput
                  [value]="passport.unitView">
              </md-input-container>
            </div>
          </div>
        </div>

        <div class="need-details__delivery-input flex-column">
          <div class="need-details__custom-label need-details__input-label"
               [ngClass]="getLabelClasses(need.quantity_precision)">{{'QTY requirement' | translate}}</div>
          <div class="passport-details-view__input-wrapper">
            <div class="need-details__custom-label-quantity passport-details-view__input-row">
              <md-input-container>
                <input
                  mdInput
                  [value]="getQuantityPrecisionType(need.quantity_precision) | translate">
              </md-input-container>
            </div>
          </div>
        </div>

        <div class="need-details__delivery-input flex-column">
          <div class="need-details__custom-label need-details__input-label"
                [ngClass]="getLabelClasses(need.delivery_date)">{{'Delivery date' | translate}}</div>
          <div class="passport-details-view__input-wrapper">
            <div class="need-details__custom-label-quantity passport-details-view__input-row">
              <md-input-container>
                <input
                  mdInput
                  [value]="need.delivery_date">
              </md-input-container>
            </div>
          </div>
        </div>

        <div class="need-details__delivery-input flex-column">
          <div class="need-details__custom-label need-details__input-label"
              [ngClass]="getLabelClasses(need.datePrecisionViewValue)">{{'Date requirement' | translate}}</div>
          <div class="passport-details-view__input-wrapper">
            <div class="need-details__custom-label-quantity passport-details-view__input-row">
              <md-input-container>
                <input
                  mdInput
                  [value]="need.datePrecisionViewValue | translate">
              </md-input-container>
            </div>
          </div>
        </div>
      </div>

      <div class="flex">
        <md-input-container>
          <input
            mdInput
            readonly
            placeholder="{{ 'Address' | translate }}"
            [value]="need.client_location.address">
        </md-input-container>
      </div>
      <div class="flex">
        <md-input-container>
          <input
            mdInput
            readonly
            placeholder="{{ 'Address description' | translate }}"
            [value]="need.client_location.description">
        </md-input-container>
      </div>
      <div class="flex">
        <md-input-container>
          <input
            mdInput
            readonly
            placeholder="{{ 'Description' | translate }}"
            [value]="need.description">
        </md-input-container>
      </div>

      <div class="need-details__uploader-row" *ngIf="need.files?.length > 0">
        <div class="need-details__uploader-label">{{ 'Need' | translate }} {{ 'Documents' | translate }}</div>
        <documents-uploader
          class="flex need-details__uploader"
          [uploadUrl]="need.getFileUploadUrl()"
          [files]="need.files"
        ></documents-uploader>
      </div>
    </div>

    <div class="flex actions actions-row">
      <button
        md-button
        (click)="save()"
      >{{ 'Save' | translate }}
      </button>
      <div *ngIf="showValidationButtons">
        <ul class="button-group__list need-details__actions-group">
          <li class="button-group__item">
            <button
              [hidden]="null"
              md-button
              (click)="reject()"
            >{{ 'Reject' | translate }}
            </button>
          </li>
          <li class="button-group__item">
            <button
              [hidden]="null"
              md-button
              (click)="changeVisibilityOfModificationModal()"
              *ngIf="!checkStatusValidated()"
            >{{ 'Ask for modification' | translate }}
            </button>
          </li>
          <li class="button-group__item">
            <button
              [hidden]="null"
              md-button
              (click)="validate()"
            >{{ 'Validate' | translate }}
            </button>
          </li>
        </ul>
      </div>
      <div class="small-modal" *ngIf="modificationModalIsVisible">
        <div class="small-modal__header">
          <div class="small-modal__info">
            {{ 'Message to Need manager or Agent' | translate }}
            <span class="small-modal__data">{{need.modification?.length || 0}}/3000</span>
          </div>
          <div class="small-modal__button">
            <button md-button md-raised-button [hidden]="null"
                    (click)="askForModification()">{{ 'Send' | translate }}</button>
          </div>
        </div>
        <div class="small-modal__body">
          <textarea [(ngModel)]="need.modification"
                    (ngModelChange)="modificationChanged()"
                    [class.modification-field--error]="modificationIsNull"
                    [readonly]="false"
                    placeholder="{{ 'Type your modification here' | translate }}" maxlength="3000"></textarea>
        </div>
      </div>

    </div>
  </div>
</ng-container>
