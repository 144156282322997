/**
 * Created by simo on 31.10.19.
 */
import * as tslib_1 from "tslib";
import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
import { GenericPassportService } from '../service/generic-passport.service';
var GenericPassportResolver = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassportResolver, _super);
    function GenericPassportResolver(_service) {
        var _this = _super.call(this) || this;
        _this._service = _service;
        _this.query = {
            expand: 'category,materials,waste_category,tags'
        };
        return _this;
    }
    return GenericPassportResolver;
}(EntityResolver));
export { GenericPassportResolver };
