import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'validationErrorsToMessage',
  pure: false
})
@Injectable()
export class ValidationErrorsToMessage implements PipeTransform {

  constructor(private translator: TranslateService) { }

  transform(invalidControls: string[]): string {
    const messageBody = invalidControls
      .filter(error => error.trim() !== '')
      .map(error => this.translator.instant(error))
      .join(', ');

      let header = this.translator.instant('VALIDATOR_ERROR_PRIMARY_MESSAGE');
    return header + ': ' + messageBody;
  }
}
