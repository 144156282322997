import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Type } from '@angular/core';
import { PassportKeywordService } from '../services/keyword.service';

export class Keyword extends ActiveRecord {
  protected provider: Type<PassportKeywordService> = PassportKeywordService;

  id: number;
  keyword: string;

  fields() {
    return ['id', 'keyword'];
  }
}
