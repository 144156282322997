<div class="needlist__filters">
    <div class="form-group__content--row needlist__filters-info">
        <md-input-container class="needlist__filters-info-name-input needlist__filters-margin-right">
            <input
            test-id="search"
            mdInput
            autocomplete="off"
            class="needlist__filters-name-title"
            placeholder="{{ 'Search by name, materials, address' | translate }}... {{ 'etc' | translate }}."
            [formControl]="searchControl"
            >
        </md-input-container>
        <md-select
            test-id="sort-selector"
            class="needlist__filters-sort needlist__filters-margin-right"
            placeholder="{{ 'Sort by' | translate }}"
            [(ngModel)]=orderBy
            (change)="updateFilters()"
        >
            <md-option *ngFor="let sort of sort_order" [value]="sort.key">
                {{sort.value | translate}}
            </md-option>
        </md-select>
        <button 
            class="needlist__filters-clear-button" 
            (click)="clearFilters()"
            [disabled]="!hasChange()"
        >
            {{ 'Clear filters' | translate }}
        </button>
    </div>

    <div class="form-group__content--row needlist__filters-info">
        <md-input-container class="needlist__filters-date-input calendar--icon-purple">
            <input
              ng2-datetime-picker
              date-only="true"
              date-format="DD-MM-YYYY"
              mdInput
              autocomplete="off"
              class="needlist__filters-date-title"
              placeholder="{{ 'NEED_CREATED_FROM' | translate }}"
              [(ngModel)]="createdDateFrom"
              (popupClosed)="updateFilters()"
            >
        </md-input-container>
        <i class="material-icons span-icon needlist__filters-separator">remove</i>
        <md-input-container class="needlist__filters-date-input calendar--icon-purple needlist__filters-margin-right">
            <input
              ng2-datetime-picker
              date-only="true"
              date-format="DD-MM-YYYY"
              mdInput class="needlist__filters-date-title"
              placeholder="{{ 'NEED_CREATED_TO' | translate }}"
              autocomplete="off"
              [(ngModel)]="createdDateTo"
              (popupClosed)="updateFilters()"
            >
        </md-input-container>

        <md-input-container class="needlist__filters-date-input calendar--icon-purple">
            <input
              ng2-datetime-picker
              date-only="true"
              date-format="DD-MM-YYYY"
              mdInput
              autocomplete="off"
              class="needlist__filters-date-title"
              placeholder="{{ 'Delivery date FROM' | translate }}"
              [(ngModel)]="deliveryDateFrom"
              (popupClosed)="updateFilters()"
            >
        </md-input-container>
        <i class="material-icons span-icon needlist__filters-separator">remove</i>
        <md-input-container class="needlist__filters-date-input calendar--icon-purple needlist__filters-margin-right">
            <input
              ng2-datetime-picker
              date-only="true"
              date-format="DD-MM-YYYY"
              mdInput class="needlist__filters-date-title"
              placeholder="{{ 'Delivery date TO' | translate }}"
              autocomplete="off"
              [(ngModel)]="deliveryDateTo"
              (popupClosed)="updateFilters()"
            >
        </md-input-container>
    </div>
    
    <div class="form-group__content--row needlist__filters-info">
        <deposit-custom-multi-select
            class="needlist__filters-checklist needlist__filters-margin-right"
            appMultiSelectMdSelectInput
            [allOptions]="statusList"
            [placeholder]="'NEED_STATUS'"
            [isPurple]="true"
            [invertNeutral]="true"
            (changeOption) = "updateStatus($event)"
            >
        </deposit-custom-multi-select>
        <deposit-custom-multi-select
            class="needlist__filters-checklist needlist__filters-margin-right"
            appMultiSelectMdSelectInput
            [allOptions]="recipientList"
            [placeholder]="'NEED_RECIPIENT'"
            [isPurple]="true"
            (changeOption) = "updateRecipient($event)"
            >
        </deposit-custom-multi-select>
        <deposit-custom-multi-select
            class="needlist__filters-checklist"
            appMultiSelectMdSelectInput
            [allOptions]="deliveryLocationList"
            [placeholder]="'NEED_DELIVERY'"
            [isPurple]="true"
            (changeOption) = "updateLocation($event)"
            >
        </deposit-custom-multi-select>
    </div>
    
    <div class="form-group__content--row needlist__filters-info">
        <toggle-independent
            class="needlist__filters-toggle needlist__filters-margin-right"
            (toggle)="toggleTargetedOnly($event)"
            [text]="'SEE_TARGETED'"
            [isChecked]="isTargetedOnly"
            [img]="'assets/svg/target_icon.svg'">
        </toggle-independent>
        <deposit-custom-multi-select
            class="needlist__filters-checklist needlist__filters-margin-right"
            appMultiSelectMdSelectInput
            [allOptions]="depositLocationList"
            [placeholder]="'DEPOSIT_SITE'"
            [disableSelection]="!isTargetedOnly"
            [isPurple]="true"
            (changeOption) = "updateDepositLocation($event)"
            >
        </deposit-custom-multi-select>
    </div>
    <div class="form-group__content--row needlist__filters-info">
        <toggle-independent
            class="needlist__filters-toggle needlist__filters-margin-right"
            (toggle)="toggleNeedWithOffers($event)"
            [text]="'NEED_WITH_OFFER_ONLY_FILTER'"
            [isChecked]="isNeedWithOfferOnly"
            [img]="'assets/svg/need_offers_grey.svg'">
        </toggle-independent>
        <deposit-custom-multi-select
            class="needlist__filters-checklist"
            appMultiSelectMdSelectInput
            [allOptions]="offerStatusList"
            [placeholder]="'OFFER_STATUS'"
            [isPurple]="true"
            [invertNeutral]="true"
            [disableSelection]="!isNeedWithOfferOnly"
            (changeOption) = "updateOfferStatus($event)"
            >
        </deposit-custom-multi-select>
    </div>
</div>