<div class="close" (click)="mdDialogRef.close()">
  <i class="icon icon--close_s"></i>
</div>

<h3 class="title">{{'Confirm manually?' | translate}}</h3>
<p class="message">{{ confirmText() | translate }}</p>

<div class="actions">
  <button
    *ngIf="showChatButton()"
    class="chat-with-sales"
    (click)="mdDialogRef.close('chat')"
    md-button
  >
    <img
      class="icon"
      src="assets/png/ecosystem/message-white-icon.png"
    >
    <span class="text">
      {{ chatText() | translate }}
    </span>
  </button>
  <button
    class="confirm"
    (click)="mdDialogRef.close('confirmed')"
    md-button
  >
    <img
      class="icon"
      src="assets/png/ecosystem/ok-white--icon.png"
    >
    <span class="text">
      {{'Confirm' | translate}}
    </span>
  </button>
</div>
