import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 10.02.17.
 */
import { EventEmitter } from '@angular/core';
import { Deposit } from '../../../models/deposit.class';
import { Router } from '@angular/router';
import { DEPOSIT_STATUSES } from '../../../values/deposit-statuses.const';
import { Utils } from '../../../../../shared/utils/utils.class';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { MdDialog } from '@angular/material';
import { DepositDetailsDialogComponent } from '../../../dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { Condition } from '../../../../../shared/interfaces/filter-states.interface';
import { PASSPORT_UNITS_MAP } from '../../../../passport/values/passport-units.const';
import { EDepositItemType } from '../../../values/deposit-item-type.enum';
var DepositItemComponent = /** @class */ (function () {
    function DepositItemComponent(_dialog, router, state) {
        this._dialog = _dialog;
        this.router = router;
        this.state = state;
        this.DEPOSIT_ITEM_TYPE = EDepositItemType;
        this.itemView = EDepositItemType.AGENT_ITEM;
        this.onOpen = new EventEmitter();
        this.onDelete = new EventEmitter();
        this.onDuplicate = new EventEmitter();
        this.onCreateEcosystem = new EventEmitter();
        this.onCreateNeed = new EventEmitter();
        this.onNotifyReseller = new EventEmitter();
        this.DEPOSIT_STATUSES = DEPOSIT_STATUSES;
        this.PASSPORT_UNITS_MAP = PASSPORT_UNITS_MAP;
    }
    Object.defineProperty(DepositItemComponent.prototype, "isESMMatchType", {
        get: function () {
            return this.itemView === this.DEPOSIT_ITEM_TYPE.ESM_NEED_MATCH_ITEM;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositItemComponent.prototype, "isNeedType", {
        get: function () {
            return this.itemView === this.DEPOSIT_ITEM_TYPE.NEED_ITEM;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositItemComponent.prototype, "isAgentType", {
        get: function () {
            return this.itemView === this.DEPOSIT_ITEM_TYPE.AGENT_ITEM;
        },
        enumerable: true,
        configurable: true
    });
    DepositItemComponent.prototype.openDeposit = function (record) {
        this.onOpen.emit(record);
    };
    DepositItemComponent.prototype.createNeed = function (recordPassport) {
        this.onCreateNeed.emit(recordPassport);
    };
    DepositItemComponent.prototype.duplicateDeposit = function (record) {
        this.onDuplicate.emit(record);
    };
    DepositItemComponent.prototype.deleteDeposit = function (record) {
        this.onDelete.emit(record);
    };
    DepositItemComponent.prototype.statusText = function () {
        if (this.deposit.quantity <= 0 && this.deposit.status === DEPOSIT_STATUSES.PUBLISHED.value) {
            return 'Out of stock';
        }
        return this.deposit.statusData.view;
    };
    DepositItemComponent.prototype.formatDate = function ($dateString) {
        return $dateString ? Utils.formatDate($dateString) : '';
    };
    DepositItemComponent.prototype.getConditionName = function (conditionId) {
        return Condition[conditionId || 1];
    };
    DepositItemComponent.prototype.getLogoThumbnailStyle = function (url) {
        return {
            'background': "url(" + url + ") no-repeat center center",
            'height': '100%',
            'width': '100%',
            'background-size': '100% 100%'
        };
    };
    DepositItemComponent.prototype.openDepositDialog = function () {
        this._dialog.open(DepositDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { data: {
                id: this.deposit.id,
                readOnly: true,
                isDo: false,
                showValidationButtons: false
            } }));
    };
    return DepositItemComponent;
}());
export { DepositItemComponent };
