/**
 * Created by aleksandr on 14.06.17.
 */
import { CustomListHintClass, CustomListStatusClass, CustomListThemeClass, CustomListUnitCssClass, CustomListUnitValue, ECustomListColumnType, ECustomListHintClass, ECustomListRecordStatus, ECustomListThemeClass } from './interfaces/custom-list.interface';
var CustomListRecord = /** @class */ (function () {
    function CustomListRecord() {
        this.columns = [];
        this.status = ECustomListRecordStatus.NO_STATUS;
        this.hint = null;
        this.tooltype = null;
        this.recordStatus = '';
        this.theme = {
            cssClass: CustomListThemeClass[ECustomListThemeClass.LIGHT],
            value: ECustomListThemeClass.LIGHT
        };
    }
    Object.defineProperty(CustomListRecord.prototype, "statusCssClass", {
        get: function () {
            return CustomListStatusClass[this.status];
        },
        enumerable: true,
        configurable: true
    });
    CustomListRecord.build = function () {
        return new CustomListRecord();
    };
    CustomListRecord.prototype.setStatus = function (status) {
        this.status = status;
        return this;
    };
    CustomListRecord.prototype.setIndependent = function (value) {
        this.independent = value;
        return this;
    };
    CustomListRecord.prototype.setHint = function (value, cssClassId) {
        if (cssClassId === void 0) { cssClassId = ECustomListHintClass.WARNING; }
        this.hint = { value: value, cssClass: CustomListHintClass[cssClassId] };
        return this;
    };
    CustomListRecord.prototype.setTooltype = function (value, cssClass) {
        this.tooltype = { value: value, cssClass: cssClass };
        return this;
    };
    CustomListRecord.prototype.setTheme = function (cssClassId) {
        this.theme.value = cssClassId;
        this.theme.cssClass = CustomListThemeClass[cssClassId];
        return this;
    };
    CustomListRecord.prototype.setPrimaryKey = function (value) {
        this.primaryKey = String(value);
        return this;
    };
    CustomListRecord.prototype.addColumn = function (value, label, type) {
        if (type === void 0) { type = ECustomListColumnType.TEXT; }
        this.columns.push({ value: value, label: label, type: type });
        return this;
    };
    CustomListRecord.prototype.addTextColumn = function (value) {
        return this.addColumn(value, '', ECustomListColumnType.TEXT);
    };
    CustomListRecord.prototype.addTitleColumn = function (value, label, title_english_name, subtitle_english_name) {
        return this.addColumn(title_english_name ? { text: value, english_name: title_english_name } : value, subtitle_english_name ? { text: label, english_name: subtitle_english_name } : label, ECustomListColumnType.TITLE);
    };
    CustomListRecord.prototype.addUnitColumn = function (value, unit, label) {
        return this.addColumn({
            amount: String(value),
            unit: Number(value) ? CustomListUnitValue[unit] : CustomListUnitValue[''],
            cssClass: CustomListUnitCssClass[unit]
        }, label, ECustomListColumnType.UNIT);
    };
    CustomListRecord.prototype.addIconColumn = function (value) {
        return this.addColumn(value, '', ECustomListColumnType.ICON);
    };
    CustomListRecord.prototype.addListColumn = function (value) {
        return this.addColumn(value, '', ECustomListColumnType.LIST);
    };
    CustomListRecord.prototype.addCounterColumn = function (value, label) {
        return this.addColumn(String(value), label, ECustomListColumnType.COUNTER);
    };
    CustomListRecord.prototype.addDateColumn = function (value, label) {
        return this.addColumn(value, label, ECustomListColumnType.DATE);
    };
    CustomListRecord.prototype.addAuthorColumn = function (value) {
        return this.addColumn(value, '', ECustomListColumnType.AUTHOR);
    };
    CustomListRecord.prototype.addImageColumn = function (url) {
        return this.addColumn(url, '', ECustomListColumnType.IMAGE);
    };
    CustomListRecord.prototype.addControlsColumn = function (value) {
        if (Array.isArray(value)) {
            value = { options: value, icon: 'more_vert' };
        }
        return this.addColumn(value, '', ECustomListColumnType.CONTROLS);
    };
    CustomListRecord.prototype.setRecordStatus = function (value) {
        this.recordStatus = value;
        return this;
    };
    return CustomListRecord;
}());
export { CustomListRecord };
