<md-dialog-content>
  <dialog-close-button [color]="'white'" (click)="_dialog.close()"></dialog-close-button>
  
  <div class="zone-form-dialog">
    <div class="zone-form-dialog__heading flex justify-between align-start">
      <div class="zone-form-dialog__heading--label">
        {{ zone.name | uppercase }}
      </div>
    </div>

    <div class="zone-form-dialog__content">
      <div class="flex-column">
        <div class="zone-form-dialog__placeholder--label">{{ 'Zone name' | translate }}</div>
        <div class="zone-form-dialog__textinput">
          <md-input-container class="form-group__field" floatPlaceholder="never">
            <input
            [(ngModel)]="zone.name"
            mdInput
            autocomplete="off"
            type="text"
            >
          </md-input-container>
        </div>
      </div>

      <div class="zone-form-dialog__variables-label">
        {{'List of landfill cost of all waste variables' | translate}}
      </div>

      <div class="flex-column">
        <div class="zone-form-dialog__placeholder--label">{{ 'Inert soils' | translate }}  {{ '(€/T)' }}</div>
        <div class="zone-form-dialog__numberinput">
          <md-input-container class="form-group__field" floatPlaceholder="never">
            <input
            mdInput
            [(ngModel)]="zone.landfill_cost_variables.inert_soils"
            autocomplete="off"
            type="number"
            >
          </md-input-container>
        </div>
      </div>

      <div class="flex-column">
        <div class="zone-form-dialog__placeholder--label">{{ 'Gravats (bricks, tiles, excluding plaster)' | translate }} {{' €/T)'}}</div>
        <div class="zone-form-dialog__numberinput">
          <md-input-container class="form-group__field" floatPlaceholder="never">
            <input
            mdInput
            [(ngModel)]="zone.landfill_cost_variables.gravats"
            autocomplete="off"
            type="number"
            >
          </md-input-container>
        </div>
      </div>

      <div class="flex-column">
        <div class="zone-form-dialog__placeholder--label">{{ 'Ordinary Industrial Waste' | translate }} {{'(€/T)'}}</div>
        <div class="zone-form-dialog__numberinput">
          <md-input-container class="form-group__field" floatPlaceholder="never">
            <input
            mdInput
            [(ngModel)]="zone.landfill_cost_variables.ordinary_industrial_waste"
            autocomplete="off"
            type="number"
            >
          </md-input-container>
        </div>
      </div>

      <div class="flex-column">
        <div class="zone-form-dialog__placeholder--label">{{ 'Demolition Wood' | translate }} {{'(€/T)'}}</div>
        <div class="zone-form-dialog__numberinput">
          <md-input-container class="form-group__field" floatPlaceholder="never">
            <input
            mdInput
            [(ngModel)]="zone.landfill_cost_variables.demolition_wood"
            autocomplete="off"
            type="number"
            >
          </md-input-container>
        </div>
      </div>

      <div class="flex-column">
        <div class="zone-form-dialog__placeholder--label">{{ 'Demolition Wood (with max 10% OIW)' | translate }} {{'(€/T)'}}</div>
        <div class="zone-form-dialog__numberinput">
          <md-input-container class="form-group__field" floatPlaceholder="never">
            <input
            mdInput
            [(ngModel)]="zone.landfill_cost_variables.demolition_wood_max"
            autocomplete="off"
            type="number"
            >
          </md-input-container>
        </div>
      </div>

      <div class="flex-column">
        <div class="zone-form-dialog__placeholder--label">{{ 'Clean plaster' | translate }} {{'(€/T)'}}</div>
        <div class="zone-form-dialog__numberinput">
          <md-input-container class="form-group__field" floatPlaceholder="never">
            <input
            mdInput
            [(ngModel)]="zone.landfill_cost_variables.clean_plaster"
            autocomplete="off"
            type="number"
            >
          </md-input-container>
        </div>
      </div>

      <div class="flex-column">
        <div class="zone-form-dialog__placeholder--label">{{ 'Paper & Cardboard' | translate }} {{'(€/T)'}}</div>
        <div class="zone-form-dialog__numberinput">
          <md-input-container class="form-group__field" floatPlaceholder="never">
            <input
            mdInput
            [(ngModel)]="zone.landfill_cost_variables.paper_and_cardboard"
            autocomplete="off"
            type="number"
            >
          </md-input-container>
        </div>
      </div>
      
      <div class="flex-column">
        <div class="zone-form-dialog__placeholder--label">{{ 'Plants' | translate }} {{'(€/T)'}}</div>
        <div class="zone-form-dialog__numberinput">
          <md-input-container class="form-group__field" floatPlaceholder="never">
            <input
            mdInput
            [(ngModel)]="zone.landfill_cost_variables.plants"
            autocomplete="off"
            type="number"
            >
          </md-input-container>
        </div>
      </div>
      
      <div class="zone-form-dialog__button-group-list">
            <button 
              md-button
              (click)="save()"
              class="zone-form-dialog__left-button"
            >
              {{ 'Save as a draft' | translate | uppercase}}
            </button>
            <button
              md-button
              (click)="publish()"
              class="zone-form-dialog__right-button"
            >
              {{ 'Publish' | translate  | uppercase }}
            </button>
      </div>

    </div>
  </div>
  
</md-dialog-content>
