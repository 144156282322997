/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-success.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/angular-svg-icon/svg-icon.component.ngfactory";
import * as i3 from "angular-svg-icon/svg-icon.component";
import * as i4 from "angular-svg-icon/svg-icon-registry.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../read-only/form-elems-hidden.directive";
import * as i7 from "../../read-only/read-only.service";
import * as i8 from "./notification-success.component";
import * as i9 from "@angular/material";
var styles_NotificationSuccessComponent = [i0.styles];
var RenderType_NotificationSuccessComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NotificationSuccessComponent, data: {} });
export { RenderType_NotificationSuccessComponent as RenderType_NotificationSuccessComponent };
export function View_NotificationSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "article", [["class", "notification-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "section", [["class", "notification-success__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "svg-icon", [["class", "primary-color"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(3, 770048, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.Renderer, i4.SvgIconRegistryService], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "section", [["class", "notification-success__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 4, "section", [["class", "notification-success__button-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "notification-success__button-close background-primary-color"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i6.FormElemsHiddenDirective, [[2, i7.ReadOnlyService]], null, null), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getEndpoint("assets/svg/done_circle_icon.svg"); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.message)); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((i1.ɵnov(_v, 9).readOnlyService == null) ? null : i1.ɵnov(_v, 9).readOnlyService.readOnly); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.closeButtonText)); _ck(_v, 10, 0, currVal_3); }); }
export function View_NotificationSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-success", [], null, null, null, View_NotificationSuccessComponent_0, RenderType_NotificationSuccessComponent)), i1.ɵdid(1, 114688, null, 0, i8.NotificationSuccessComponent, [i9.MdDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationSuccessComponentNgFactory = i1.ɵccf("app-notification-success", i8.NotificationSuccessComponent, View_NotificationSuccessComponent_Host_0, { message: "message", closeButtonText: "closeButtonText" }, {}, []);
export { NotificationSuccessComponentNgFactory as NotificationSuccessComponentNgFactory };
