<div class="ecosystem-file">
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>
    <h2 md-dialog-title>
      <ng-container *ngIf="stepId"> {{'Add file' | translate}}</ng-container>
      <ng-container *ngIf="!stepId"> {{'Attached files' | translate}}</ng-container>
    </h2>
    <ul *ngIf="stepId"
        class="ecosystem-file__list"
    >
      <li><span>{{'Files from Ecosystem' | translate}}</span></li>
      <li class="separator">|</li>
      <li (click)="uploadFiles()">{{'Upload new file' | translate}}</li>
    </ul>
    <ul class="upload-files__list">
      <document-item
        *ngFor="let file of uploadedDocs"
        [file]="file"
        (onClick)="selectFile(file)"
        [docHref]="!isOpenFiles ? null : file.file"
        [noLink]="!isOpenFiles"
        [isRemoveFromFilesDisabled]="false"
        (remove)="removeFile(file)">
      </document-item>
    </ul>
    <div class="ecosystem-file__buttons" *ngIf="!stepId">
      <button md-raised-button (click)="uploadFiles()">
        {{'Upload files' | translate}}
      </button>
    </div>
  </div>

  <input #fileInput
         type="file"
         ng2FileSelect
         [uploader]="uploader"
         multiple
         style="display: none;">
