import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { GenericPassportTagJunction } from '../models/generic-passport-tag-junction.class';

@Injectable()
export class GenericPassportTagJunctionService extends CrudService {
  protected namespace = 'generic-passport-tag-junctions';
  //protected namespaceSingular = 'generic-passport-tag-junction';
  protected ModelClass = GenericPassportTagJunction;
}
