/**
 * Created by aleksandr on 28.07.17.
 */

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentUserService } from '../../shared/services/current-user.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { Injectable } from '@angular/core';
import { ModuleGuard } from '../../shared/navigation/module.guard';
import { Observable } from 'rxjs/Rx';
import { RESTRICTED_ADMIN_ROUTES } from './restricted.admin.route.paths';
import { ADMIN_ESM_OPTIONS } from '../../shared/values/role-ids.enum';

@Injectable()
export class AdminGuard extends ModuleGuard implements CanActivate {

  protected context: string = 'admin';

  constructor(protected _navigationService: NavigationService,
              protected _userService: CurrentUserService,
              protected _router: Router) {
    super();
    console.log('Launch AdminGuard');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return super.canActivate(route, state).do((activable) => {
      return this
      ._userService
      .loggedInChecked$
      .subscribe(() => {
        if (activable && this._userService.infoData.plan_id === ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN &&
        !RESTRICTED_ADMIN_ROUTES.includes(state.url)) {
          this._router.navigate(['/admin/reporting']);
          return false;
        }
        return true;
      });
    });
  }

}
