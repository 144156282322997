import * as tslib_1 from "tslib";
import { OrderPassport } from '../models/order-passport.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var OrderPassportService = /** @class */ (function (_super) {
    tslib_1.__extends(OrderPassportService, _super);
    function OrderPassportService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'order-passports';
        _this.namespaceSingular = 'order-passport';
        _this.ModelClass = OrderPassport;
        return _this;
    }
    return OrderPassportService;
}(ExtendedCrudService));
export { OrderPassportService };
