import { Injectable } from '@angular/core';
import { Http, Request, RequestOptions, RequestOptionsArgs, Response, XHRBackend } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { GlobalEventsManager } from './global-events-manager.service';
import { Router } from '@angular/router';


@Injectable()
export class AuthenticatedHttpService extends Http {

  constructor(backend: XHRBackend,
              defaultOptions: RequestOptions,
              private router: Router,
              private globalEventsManger: GlobalEventsManager) {
    super(backend, defaultOptions);
  }

  request(url: string | Request, options?: RequestOptionsArgs): Observable<any | Response> {
    return super.request(url, options).catch((error: Response) => {
      if ((error.status === 401 || error.status === 403)) {
        this.globalEventsManger.showNavBar(false);
        this.router.navigate(['']);
      }
      return Observable.throw(error);
    });
  }
}
