import * as tslib_1 from "tslib";
import { DepositFileService } from '../services/deposit-file.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
var DepositFile = /** @class */ (function (_super) {
    tslib_1.__extends(DepositFile, _super);
    function DepositFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = DepositFileService;
        return _this;
    }
    DepositFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'deposit_file_id',
        ]);
    };
    return DepositFile;
}(UploadedDocument));
export { DepositFile };
