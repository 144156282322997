import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ReportService } from '../../../../services/report.service';
import { ActivatedRoute } from '@angular/router';
import { ReportBase } from '../report-base/report-base.class';
import { REPORT_NAME } from '../types/report-name.const';
import { LanguageService } from '../../../../../../entities/client/service/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportSiteTagPresenterService } from '../../../../services/report-site-tag-presenter.service';
import { ElRowCounterService } from '../../../../services/el-row-counter.service';
import { EResidualValueStatusEmpty, EResidualValueStatus } from '../../../../values/residual-value-status.enum';
var ResidualValuePdfReportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResidualValuePdfReportComponent, _super);
    function ResidualValuePdfReportComponent(reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter) {
        var _this = _super.call(this, reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter) || this;
        _this.reportService = reportService;
        _this.activatedRoute = activatedRoute;
        _this.languageService = languageService;
        _this.translateService = translateService;
        _this.reportPresenter = reportPresenter;
        _this.rowCounter = rowCounter;
        _this.RESIDUAL_VALUE_TYPE = tslib_1.__assign({}, EResidualValueStatus, EResidualValueStatusEmpty);
        _this.pageCounter = 1;
        _this.reportName = REPORT_NAME.RESIDUAL_VALUE;
        _this.categoriesTable = [];
        _this.categoriesTable_renewal_building_commercial = [];
        _this.categoriesTable_renewal_building_institutional = [];
        _this.categoriesTable_renewal_building_residantial = [];
        _this.categoriesTable_rehabilitation = [];
        _this.categoriesTable_deconstruction = [];
        _this.errorsTable = [];
        _this.resultKeys = [];
        _this.categories = [];
        return _this;
    }
    ResidualValuePdfReportComponent.prototype.ngOnInit = function () {
        this.createReport();
    };
    ResidualValuePdfReportComponent.prototype.createReport = function () {
        var _this = this;
        this.reportRequest()
            .subscribe(function () {
            _this.resultKeys = Object.keys(_this.result).slice(0, -2);
            _this.resultKeys = !!_this.resultKeys.length ? _this.resultKeys : [EResidualValueStatusEmpty.empty];
            _this.prepareTablesDataForRender();
        });
    };
    ResidualValuePdfReportComponent.prototype.isEmptyDepositCategory = function (type) {
        if (this.result[type]) {
            // aggregate?.hasOwnProperty('total_number_of_deposit')
            this.filteredProductCategory = this.result[type].aggregate.total_number_of_deposit;
            console.log(this.result[type].aggregate.total_number_of_deposit);
            return this.result[type].aggregate.total_number_of_deposit === 0;
        }
        return true;
    };
    ResidualValuePdfReportComponent.prototype.setCategories = function () {
        this.categories_deconstruction = this.result.deconstruction ? [
            this.getDefaultCategory('deconstruction')
        ].concat(this.getSortedProductsBy('deposit_total_weight', 'deconstruction', 'deposit_considered')) : [];
        this.categories_rehabilitation = this.result.rehabilitation ? [
            this.getDefaultCategory('rehabilitation')
        ].concat(this.getSortedProductsBy('deposit_total_weight', 'rehabilitation', 'deposit_considered')) : [];
        this.categories_renewal_building_commercial = this.result.renewal_building_commercial ? [
            this.getDefaultCategory('renewal_building_commercial')
        ].concat(this.getSortedProductsBy('deposit_total_weight', 'renewal_building_commercial', 'deposit_considered')) : [];
        this.categories_renewal_building_residantial = this.result.renewal_building_residantial ? [
            this.getDefaultCategory('renewal_building_residantial')
        ].concat(this.getSortedProductsBy('deposit_total_weight', 'renewal_building_residantial', 'deposit_considered')) : [];
        this.categories_renewal_building_institutional = this.result.renewal_building_institutional ? [
            this.getDefaultCategory('renewal_building_institutional')
        ].concat(this.getSortedProductsBy('deposit_total_weight', 'renewal_building_institutional', 'deposit_considered')) : [];
    };
    ResidualValuePdfReportComponent.prototype.getDefaultCategory = function (type) {
        return {
            category: { name: 'Total', parent_id: void 0, id: void 0 },
            deposit_considered: this.result[type].aggregate.deposit_considered,
            weight: this.result[type].aggregate.deposit_total_weight,
            added_value_down_if_economic: this.result[type].aggregate.total_added_value_down_if_economic,
            added_value_up: this.result[type].aggregate.total_added_value_up,
            added_value_up_if_economic: this.result[type].aggregate.total_added_value_up_if_economic,
            added_value_down: this.result[type].aggregate.total_added_value_down,
            mass_percentage: 100,
            financial_recovery_percentage_down: this.result[type].aggregate.total_financial_recovery_percentage_down,
            financial_recovery_percentage_down_if_economic: this.result[type].aggregate.total_financial_recovery_percentage_down_if_economic,
            financial_recovery_percentage_up: this.result[type].aggregate.total_financial_recovery_percentage_up,
            financial_recovery_percentage_up_if_economic: this.result[type].aggregate.total_financial_recovery_percentage_up_if_economic,
            material_recovery_pourcentage_down: this.result[type].aggregate.total_material_recovery_percentage_down,
            material_recovery_percentage_down_if_economic: this.result[type].aggregate.total_material_recovery_percentage_down,
            material_recovery_pourcentage_up: this.result[type].aggregate.total_material_recovery_percentage_up,
            material_recovery_percentage_up_if_economic: this.result[type].aggregate.total_material_recovery_percentage_up_if_economic,
            purchase_value: this.result[type].aggregate.total_purchase_value,
            isDefault: true
        };
    };
    ResidualValuePdfReportComponent.prototype.prepareTablesDataForRender = function () {
        this.prepareCategoriesTable();
        this.prepareErrorsTable();
    };
    ResidualValuePdfReportComponent.prototype.prepareCategoriesTable = function () {
        this.setCategories();
        this.allResultsChunked = {
            deconstruction: this.prepareThirdChartChunks('deconstruction'),
            rehabilitation: this.prepareThirdChartChunks('rehabilitation'),
            renewal_building_institutional: this.prepareThirdChartChunks('renewal_building_institutional'),
            renewal_building_commercial: this.prepareThirdChartChunks('renewal_building_commercial'),
            renewal_building_residantial: this.prepareThirdChartChunks('renewal_building_residantial'),
        };
        this.pushCategoryByType(this.categoriesTable_renewal_building_commercial, this.categories_renewal_building_commercial);
        this.pushCategoryByType(this.categoriesTable_renewal_building_institutional, this.categories_renewal_building_institutional);
        this.pushCategoryByType(this.categoriesTable_renewal_building_residantial, this.categories_renewal_building_residantial);
        this.pushCategoryByType(this.categoriesTable_rehabilitation, this.categories_rehabilitation);
        this.pushCategoryByType(this.categoriesTable_deconstruction, this.categories_deconstruction);
        this.allCategories = {
            renewal_building_commercial: this.categoriesTable_renewal_building_commercial,
            renewal_building_institutional: this.categoriesTable_renewal_building_institutional,
            renewal_building_residantial: this.categoriesTable_renewal_building_residantial,
            rehabilitation: this.categoriesTable_rehabilitation,
            deconstruction: this.categoriesTable_deconstruction,
        };
    };
    ResidualValuePdfReportComponent.prototype.pushCategoryByType = function (categoryListToPush, categories) {
        this.categories = categories;
        this.onEachChunkedCategory(function (chuck, index) {
            categoryListToPush.push({
                pageNumber: index + 2,
                pageContent: chuck
            });
        });
    };
    ResidualValuePdfReportComponent.prototype.prepareErrorsTable = function () {
        var _this = this;
        this.onEachChunkedError(function (chuck, index) {
            _this.errorsTable.push({
                pageNumber: index + _this.categoriesTable.length + 2,
                pageContent: chuck
            });
        });
    };
    ResidualValuePdfReportComponent.prototype.prepareThirdChartChunks = function (type) {
        if (!this.result[type]) {
            return [];
        }
        var categories = Object.entries(this.getSortedProductsBy('financial_recovery_percentage_up_if_economic', type, 'deposit_considered'));
        var splicedCategories = [];
        var splicedCategoriesArray = [];
        while (categories.length > 0) {
            var resultCopy = JSON.parse(JSON.stringify(this.result[type]));
            resultCopy.aggregate.category = [];
            resultCopy.aggregate.category = this.ObjectFromEntries(categories.splice(0, 8));
            var result = {};
            result[type] = resultCopy;
            splicedCategories.push(result);
        }
        while (splicedCategories.length > 0) {
            splicedCategoriesArray.push(splicedCategories.splice(0, 2));
        }
        return splicedCategoriesArray;
    };
    ResidualValuePdfReportComponent.prototype.initPageNumber = function () {
        this.pageCounter = 1;
        return this.getPageNumber();
    };
    ResidualValuePdfReportComponent.prototype.initOrGetPageNumber = function () {
        return this.showSitesInOnePage ? this.getPageNumber() : this.initPageNumber();
    };
    ResidualValuePdfReportComponent.prototype.getPageNumber = function () {
        return this.pageCounter++;
    };
    ResidualValuePdfReportComponent.prototype.ObjectFromEntries = function (iter) {
        var obj = {};
        for (var _i = 0, iter_1 = iter; _i < iter_1.length; _i++) {
            var pair = iter_1[_i];
            if (Object(pair) !== pair) {
                throw new TypeError('iterable for fromEntries should yield objects');
            }
            var key = pair["0"], val = pair["1"];
            Object.defineProperty(obj, key, {
                configurable: true,
                enumerable: true,
                writable: true,
                value: val,
            });
        }
        return obj;
    };
    return ResidualValuePdfReportComponent;
}(ReportBase));
export { ResidualValuePdfReportComponent };
