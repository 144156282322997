import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
var Report = /** @class */ (function (_super) {
    tslib_1.__extends(Report, _super);
    function Report() {
        var _this = _super.call(this) || this;
        _this.circularity = false;
        _this.carbon_foot_print = false;
        _this.financial_residual_value = false;
        _this.material_health = false;
        _this.embodied_carbon = false;
        _this.product_stage = false;
        _this.ic_component = false;
        _this.ic_construction = false;
        return _this;
    }
    Report.prototype.fields = function () {
        return ['id', 'user_id', 'created_at',
            'carbon_foot_print', 'circularity', 'material_health', 'financial_residual_value',
            'embodied_carbon', 'embodied_carbon_total,', 'product_stage', 'ic_component', 'ic_construction', 'ic_construction_co2',
            'file_path',
            'filepath_for_residual_value',
            'filepath_for_circularity',
            'filepath_for_material_health',
            'filepath_for_carbon_foot_print'];
    };
    return Report;
}(ActiveRecord));
export { Report };
