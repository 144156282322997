import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {DepositFileService} from '../../../entities/deposit/services/deposit-file.service';
import {DocumentsUploaderComponent} from '../../../shared/uploaders/documents-uploader/documents-uploader.component';
import {ExtendedActiveRecord} from '../../../shared/models/extended-active-record.class';
import {UploadedDocument} from '../../../shared/models/uploaders/document.class';

@Component({
    moduleId: module.id,
    selector: 'import-deposit-documents',
    templateUrl: 'import-deposit-documents.component.html',
    styleUrls: ['./import-deposit-documents.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class ImportDepositDocumentsComponent implements OnInit, OnDestroy {
    @Input() uploadedDepositFile: UploadedDocument[];
    @Input() depositImport: ExtendedActiveRecord ;
    @Input() documentSave: EventEmitter<string>;
    @Input() updateUploadUrl: EventEmitter<string>;
    @Output() onDocumentUploaded = new EventEmitter();
    @Output() fileListChange = new EventEmitter();
    @Input() form: any;
    @ViewChild(DocumentsUploaderComponent) documentsUploader: DocumentsUploaderComponent;
    onUploadFile = new EventEmitter();
    private supscription;

    constructor(
      public depositFileService: DepositFileService,
    ) {
    }

    ngOnInit() {
      this.supscription = this.documentSave.subscribe((data) => {
        this.onUploadFile.emit();
      });
    }

    ngOnDestroy() {
      this.supscription.unsubscribe();
    }

  onFileAdded(e) {
      this.fileListChange.emit(!!e.length);
    }

    private allowOnly(e: KeyboardEvent, regex: RegExp) {
        const testChar = String.fromCharCode(e.keyCode >= 96 && e.keyCode <= 105 ? e.keyCode - 48 : e.keyCode);
        if (!(regex.test(testChar)) && ![8, 9, 37, 39, 46].some(k => k === e.keyCode)) {
          e.preventDefault();
          e.stopPropagation();
        }
    }

    allowOnlyGreaterThanZeroNumbers(e: KeyboardEvent) {
        this.allowOnly(e, /[0-9]/i);
    }

    documentUploaded() {
      this.onDocumentUploaded.emit();
    }
  }
