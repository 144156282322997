import * as tslib_1 from "tslib";
import { ActiveRecord } from '../active-record.class';
var UploadedDocument = /** @class */ (function (_super) {
    tslib_1.__extends(UploadedDocument, _super);
    function UploadedDocument(data) {
        var _this = _super.call(this, data) || this;
        _this.isImage = false;
        _this.extension = '';
        _this.isImage = !!_this.getThumbnailUrl();
        _this.extension = _this.getExtension();
        return _this;
    }
    UploadedDocument.prototype.fields = function () {
        return [
            'id',
            'basename',
            'file',
            'type'
        ];
    };
    UploadedDocument.prototype.getExtension = function () {
        var match = (this.file || '').match(/.+\.(.+)$/);
        return match && match[1] && match[1].toLowerCase();
    };
    UploadedDocument.prototype.getThumbnailUrl = function () {
        var extension = this.getExtension();
        if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
            return this.file;
        }
        else {
            return null;
        }
    };
    return UploadedDocument;
}(ActiveRecord));
export { UploadedDocument };
