import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { PassportTagService } from '../services/passport-tag.service';
var PassportTag = /** @class */ (function (_super) {
    tslib_1.__extends(PassportTag, _super);
    function PassportTag() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = PassportTagService;
        return _this;
    }
    PassportTag.prototype.fields = function () {
        return ['id', 'name', 'synonyms'];
    };
    return PassportTag;
}(ActiveRecord));
export { PassportTag };
