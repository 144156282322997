import { AfterViewChecked, OnInit, ChangeDetectorRef, Component, Input } from "@angular/core";
import { ReadOnlyService } from "../../../../shared/read-only/read-only.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GenericPassport } from "../../models/generic-passport.class";
import { ViewCategoryDialogComponent } from "../../../passport/dialogs/view-category/view-category.component";
import { MdDialog } from "@angular/material";
import { GENERIC_PASSPORT_UNITS, GENERIC_PASSPORT_UNITS_TYPE } from "../../models/values/generic-passport-units.const";
import { TranslateService } from "@ngx-translate/core";

import { ViewNomenclatureDialogComponent } from "../../../passport/dialogs/view-nomenclature/view-nomenclature.component";
import { GenericPassportMaterial } from "../../models/generic-passport-materials.class";
import { LandfillVariablesService } from "../../../../dashboards/superadmin/components/landfill-variables/landfill-variables.service";
import { LandfillVariables } from "../../../../dashboards/superadmin/components/landfill-variables/landfill-variables.model";
import { GenericPassportTagJunction } from "../../models/generic-passport-tag-junction.class";
import { GenericPassportTag } from "../../models/generic-passport-tag.class";
import { GenericPassportTagService } from "../../service/generic-passport-tag.service";
import { Observable } from "rxjs";
import { CONSTRUCTION_LAYERS } from "../../../../dashboards/superadmin/components/calculation-variables/calculation-variables.const";
import { ZoneService } from '../../../../shared/services/zone.service';
import { Zone } from '../../../../shared/models/zone.class';
import { Synonym } from "../../../../shared/models/synonym.class";



@Component({
  moduleId: module.id,
  selector: 'generic-passport-form',
  templateUrl: 'generic-passport-form.component.html',
  styleUrls: ['generic-passport-form.component.scss'],
  providers: [ReadOnlyService]
})
export class GenericPassportFormComponent implements OnInit, AfterViewChecked {
  @Input() genericPassport?: GenericPassport = new GenericPassport;
  units: any[] = GENERIC_PASSPORT_UNITS;
  public landfillVariables: LandfillVariables[] = [];
  tagInputTexts: string[] = [];
  @Input() parentFormGroup?: FormGroup;
  @Input() genericPassportForm?: FormGroup;
  tagsMaxlength = 15;
  invalidId = 0;
  constructionLayers = CONSTRUCTION_LAYERS;
  @Input() zoneId: number;

  constructor(
    public _fb: FormBuilder,
    private cd: ChangeDetectorRef,
    public dialog: MdDialog,
    private translate: TranslateService,
    private _landfillVariablesService: LandfillVariablesService,
    private _zoneService: ZoneService,
    private _genericPassportTagService: GenericPassportTagService,
  ) {
  }
  ngOnInit() {
    // console.log('init generic passport form ');
    // console.log(this.genericPassport);
    this.createForm();
    if (this.parentFormGroup) {
      this.parentFormGroup.setControl('genericpassport', this.genericPassportForm);
    }
    this._zoneService.getLandfillVariables(this.zoneId, {expand: 'name,value'})
      .subscribe(variables => {
        this.landfillVariables = variables;
        // this.genericPassportForm.controls['landfil_cost'].disable();
        this.landfillVariables.push(
          new LandfillVariables({
            'id': this.invalidId,
            'value': this.genericPassport.waste_category_id > 0 ? 0 : this.genericPassport.landfil_cost,
            'name': 'Other',
            'currency': '€'
          })
        );
        this.changWasteCategory();
        if (!this.genericPassport.waste_category_id) {
          this.genericPassportForm.controls['waste_category'].setValue(this.invalidId);
        }
      });
    // console.log()
  }
  createForm() {

    this.genericPassportForm = this._fb.group({
      comment: [''],
      category: ['', Validators.required],
      waste_category: [''],
      unit: ['', Validators.required],
      constructionLayer: ['', Validators.required],
      conversionFactor: ['', Validators.required],
      purchase_cost: [''],
      default_recoverability_average: [''],
      landfilCost: [''],

      //upcycling variables
      upcyclingExtraCostForCleanRemoval: [''],
      upcyclingResalePriceUp: [''],
      upcyclingResalePriceDown: [''],
      //generated
      upcyclingResidualValueUp: [''],
      upcyclingResidualValueDown: [''],
      upcyclingAddedValueUp: [''],
      upcyclingAddedValueDown: [''],
      // reuse variables
      reuseExtraCostForCleanRemoval: [''],
      reuseResalePriceUp: [''],
      reuseResalePriceDown: [''],

      //generated
      reuseAddedValueUp: [''],
      reuseAddedValueDown: [''],
      reuseResidualValueUp: [''],
      reuseResidualValueDown: [''],

      tags: [''],
    });
    if (this.genericPassport.id) {
      const categoryName = !!this.genericPassport.category && !!this.genericPassport.category.name && this.genericPassport.category.name;
      const translation = !!categoryName ? this.translate.instant(categoryName) : '';
      this.genericPassportForm.controls['category'].setValue(translation);
      this.genericPassportForm.controls['category'].disable();
    }
  }
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  openCategoryeDialog($event: Event): void {
    let dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.genericPassport.category_id = result.id;
        this.genericPassportForm.controls['category'].setValue(result.name ? this.translate.instant(result.name) : '');
        this.genericPassportForm.controls['category'].disable();
      }
    });
  }

  removeMaterial(material: GenericPassportMaterial) {
    // console.log(material);
    const index = this.genericPassport.materials.indexOf(material);
    if (material.id && material.id > 0) {
      material.destroy().subscribe(() => {
        this.delete(index);
      });
    } else {
      //console.log('not savec but deleted');
      this.delete(index);
    }
  }
  delete(index) {
    if (index !== -1) {
      this.genericPassport.materials.splice(index, 1);
    }
  }

  addTag(isInput: number) {
    if (!this.tagInputTexts[isInput] ) {
      return;
    }
    this._genericPassportTagService
      .get({ name: this.tagInputTexts[isInput] })
      .flatMap((foundTags: GenericPassportTag[]) => {
        return foundTags.length > 0
          ? Observable.of(foundTags[0])
          : new GenericPassportTag({ name: this.tagInputTexts[isInput] }).save();
      })
      .flatMap((targetTag: GenericPassportTag) => {
       
                this.tagInputTexts[isInput] = '';
        let junction = new GenericPassportTagJunction({
          // generic_passport_id: this.genericPassport.id,
          tag_id: targetTag.id,
          name: targetTag.name
        });
        this.genericPassport.tags.find(x => x.tag_id === junction.tag_id) ? console.log('already exist') : this.genericPassport.tags.push(junction)  ;
        return Observable.empty();
        // return junction.save();
      })
      .subscribe((createdJunction: GenericPassportTagJunction) => {
      });
  }

  removeTag(tag: GenericPassportTagJunction) {  
    if (this.genericPassport.tags.find(element => element.id == undefined)) {
     this._genericPassportTagService.deleteTag(tag.tag_id).subscribe(() => {
    });
    }
    
    this.removeItem(this.genericPassport.tags, tag);
  }
  removeItem(ref: any[], junction) {
    const index = ref.indexOf(junction);
    if (junction.id > 0) {
      junction.destroy().subscribe(() => {
        if (index !== -1) {
          ref.splice(index, 1);
        }
      });
    } else {
      ref.splice(index, 1);
    }
  }

  generateFields() {
    const purchase_cost = this.genericPassport.purchase_cost;
   // const landfilCost = this.genericPassportForm.controls['landfilCost'].value;
    let landfilCost = this.genericPassport.landfil_cost;
   
    const convFactor = this.genericPassport.conversion_factor;
    //
    const reuseExtraCostForCleanRemoval = this.genericPassport.reuse_extra_cost_for_clean_removal;
    const reuseResalePriceUp = this.genericPassport.reuse_resale_price_up;
    const reuseResalePriceDown = this.genericPassport.reuse_resale_price_down;
    //
    const upcyclingExtraCostForCleanRemoval = this.genericPassport.upcycling_extra_cost_for_clean_removal;
    const upcyclingResalePriceUp = this.genericPassport.upcycling_resale_price_up;
    const upcyclingResalePriceDown = this.genericPassport.upcycling_resale_price_down;
    //
    //
    // reuseResidualValueUp
    //reuseResalePriceUp / purchase_price
    if (purchase_cost && purchase_cost > 0) {
      if (reuseResalePriceUp != null) {
        const reuseResidualValueUp = (reuseResalePriceUp / purchase_cost) * 100;
        this.genericPassport.reuse_residual_value_up = this.roundNumber(reuseResidualValueUp);
      }else {
        this.genericPassport.reuse_residual_value_up = null ;
      }
      if (reuseResalePriceDown != null) {
        const reuseResidualValueDown = (reuseResalePriceDown / purchase_cost) * 100;
        this.genericPassport.reuse_residual_value_down = this.roundNumber(reuseResidualValueDown);
      } else {
        this.genericPassport.reuse_residual_value_down = null ;
      }
      if (upcyclingResalePriceUp != null) {
        const upcyclingResidualValueUp = (upcyclingResalePriceUp * convFactor / purchase_cost) * 100;
        this.genericPassport.upcycling_residual_value_up = this.roundNumber(upcyclingResidualValueUp);
      } else {
        this.genericPassport.upcycling_residual_value_up = null ;
      }
      if (upcyclingResalePriceDown != null) {
        const upcyclingResidualValueDown = (upcyclingResalePriceDown * convFactor / purchase_cost) * 100;
        this.genericPassport.upcycling_residual_value_down = this.roundNumber(upcyclingResidualValueDown);
      } else  {
        this.genericPassport.upcycling_residual_value_down = null;
      }
    } else {
      this.genericPassport.reuse_residual_value_up = null;
      this.genericPassport.reuse_residual_value_down = null ;
      this.genericPassport.upcycling_residual_value_up = null;
      this.genericPassport.upcycling_residual_value_down = null ;
    }
    // console.log('last if '+reuseResalePriceUp+' '+landfilCost+' '+convFactor+' '+reuseExtraCostForCleanRemoval);
    if (landfilCost != null && convFactor > 0) {
      if (reuseExtraCostForCleanRemoval != null) {

        if (reuseResalePriceUp != null) {
          const reuseAddedValueUp = reuseResalePriceUp + (landfilCost * convFactor) - reuseExtraCostForCleanRemoval;
          this.genericPassport.reuse_added_value_up = this.roundNumber(reuseAddedValueUp);
        } else {
          this.genericPassport.reuse_added_value_up = null;
        }
        if (reuseResalePriceDown != null) {
          const reuseAddedValueDown = reuseResalePriceDown + (landfilCost * convFactor) - reuseExtraCostForCleanRemoval;
          this.genericPassport.reuse_added_value_down = this.roundNumber(reuseAddedValueDown);
        } else {
          this.genericPassport.reuse_added_value_down = null ;
        }
      } else {
        this.genericPassport.reuse_added_value_down = null;
        this.genericPassport.reuse_added_value_up = null ;

      }
      if (upcyclingExtraCostForCleanRemoval != null) {

        if (upcyclingResalePriceDown != null) {
          const upcyclingAddedValueDown =
                (upcyclingResalePriceDown * convFactor) + (landfilCost * convFactor) - upcyclingExtraCostForCleanRemoval;
          this.genericPassport.upcycling_added_value_down = this.roundNumber(upcyclingAddedValueDown);
        } else {
          this.genericPassport.upcycling_added_value_down = null;
        }
        if (upcyclingResalePriceUp != null) {
          const upcyclingAddedValueUp =
                (upcyclingResalePriceUp * convFactor) + (landfilCost * convFactor) - upcyclingExtraCostForCleanRemoval;
          this.genericPassport.upcycling_added_value_up = this.roundNumber(upcyclingAddedValueUp);
        }else  {
          this.genericPassport.upcycling_added_value_up = null ;
        }
      }  else {
        this.genericPassport.upcycling_added_value_down = null ;
        this.genericPassport.upcycling_added_value_up = null ;
      }
    } else {
      this.genericPassport.upcycling_added_value_down = null ;
        this.genericPassport.upcycling_added_value_up = null ;
        this.genericPassport.reuse_added_value_down = null;
        this.genericPassport.reuse_added_value_up = null ;
    }
  }
  changWasteCategory() {
    const selectedwasteCategory = this.landfillVariables.find(x => x.id === this.genericPassport.waste_category_id);
    if (selectedwasteCategory) {
      if (selectedwasteCategory.id === this.invalidId) {
        this.genericPassportForm.controls['landfilCost'].enable();
      } else {
        this.genericPassportForm.controls['landfilCost'].disable();
      }
      this.genericPassportForm.controls['landfilCost'].setValue(selectedwasteCategory.value);
    }
  }

  unitChanged(){
    this.genericPassportForm.controls['conversionFactor'].enable();
    if (this.genericPassport.unit == GENERIC_PASSPORT_UNITS_TYPE.KG) {
      this.genericPassport.conversion_factor = 0.001;
      this.genericPassportForm.controls['conversionFactor'].disable();
    }
    else if (this.genericPassport.unit == GENERIC_PASSPORT_UNITS_TYPE.TONNE) {
      this.genericPassport.conversion_factor = 1 ;
      this.genericPassportForm.controls['conversionFactor'].disable();
    }
    else {
      this.genericPassport.conversion_factor = null ;
    }
  }

  roundNumber(num: number) {
    return Number(num.toFixed(2));
  }
  addMaterial(isInput: number) {
    const dialogRef = this.dialog.open(ViewNomenclatureDialogComponent, {
      height: '80%',
      width: '80%',
    });
    dialogRef.afterClosed().subscribe(nomenclature => {
      if (nomenclature) {
        const material = new GenericPassportMaterial();
        material.generic_passport_id = this.genericPassport.id;
        material.nomenclature_id = nomenclature.id;
        material.name = nomenclature.name;
        this.genericPassport.materials.push(material);
        /*material.save().subscribe((res) => {
          material.id = res.id;
          console.log(material);
        });*/
      }
    });
  }
  
  getSynonymNames(synonyms: Synonym[]) {
    return synonyms ? synonyms.map(synonym => synonym.name).join(', ') : '';
  }

}