import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LogNotificationService} from '../../shared/services/log-notification.service';
import {AbstractControl, FormGroup} from '@angular/forms';
import {IColumnMeta} from '../values/interfaces/column-meta.interface';
import {filter, pairwise, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class ImportValidatorsService {
  readonly DUPLICATION_ERROR = 'duplicate';
  readonly FIRST_ROW_IS_BIGGER_THAN_LAST = 'firstRowIsBiggerThenLast';

  constructor(
    private _translate: TranslateService,
    private logNotificationService: LogNotificationService,
  ) { }

  public validateDuplicatedColumnNames(controlGroup: FormGroup
    , skipValidationForColumns = ['tab_name', 'first_row', 'last_row', 'id', 'reference_date']) {
    const spreadSheetColumns = Object.keys(controlGroup.controls)
      .filter(element => !skipValidationForColumns.includes(element));

    const lookup = spreadSheetColumns.reduce((a, e) => {
      a[controlGroup.get(e).value.toUpperCase()] = ++a[controlGroup.get(e).value.toUpperCase()] || 0;
      return a;
    }, {});

    return spreadSheetColumns.filter(e => {
      const control = controlGroup.get(e);
      if (!!control.value && lookup[control.value.toUpperCase()]) {
        return e;
      }
    }).length ? {[this.DUPLICATION_ERROR]: true} : null;
  }

  public validateDuplicatedColumnNamesAndShowNotification(control: FormGroup, controlMessage: (controlName: string) => string) {
    const duplicates = this.validateDuplicatedColumnNames(control);
    if (duplicates) {
      this.logNotificationService.error(`${this._translate.instant(controlMessage(duplicates[0]))}` +
        `: ${this._translate.instant('This column is already used')}`);
    }
    return duplicates;
  }

  private clearDuplicatedErrorsAndTouch(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control.getError(this.DUPLICATION_ERROR)) {
        control.setErrors(null);
      }
      control.markAsTouched({onlySelf: true});
    });
  }

  private findFirstControlNameWithRequiredError(groupControl: FormGroup,
                                                skipControls = ['availability_date_field', 'reference_date']): string {
    return Object.keys(groupControl)
      .filter(field => !skipControls.includes(field))
      .find(field => groupControl.controls[field].getError('required'));
  }

  private findFirstRequiredErrorAndShowMessage(control, controlMessage: (controlName: string) => string ) {
    const controlName = this.findFirstControlNameWithRequiredError(control);
    if (controlName) {
      this.logNotificationService.error(
        `${this._translate.instant(controlMessage(controlName))} ${this._translate.instant('is required')}.`
        , null, true);
    }
    return controlName;
  }

  public validateColumnDuplication(control: FormGroup, fields: IColumnMeta) {
    const usedLetters = this.getColumnLettersWithControls(control, fields);
    return this.validateBaseOnLetterControl(usedLetters, control, fields);
  }

  private getValuesFromColumnControl(controlName: string, control: FormGroup, fields: IColumnMeta) {
    const valueRaw = control.value[controlName] || '';
    return  this.getValues(valueRaw, !!(fields[controlName] && fields[controlName].multiInput));
  }

  private getValues(valueRaw: string, multiInput: boolean) {
    if (multiInput) {
      return valueRaw.split(',').map((item) => item.toUpperCase().trim());
    } else {
      return [valueRaw.toUpperCase()];
    }
  }

  public getColumnLettersWithControls(controlGroup: FormGroup, fields: IColumnMeta): {[letter: string]: string[]} {
    const usedLetters: {[x: string]: string[]} = {};
    Object.keys(controlGroup.value).forEach(controlName => {
      const valueList = this.getValuesFromColumnControl(controlName, controlGroup, fields);
      valueList.filter(item => item).forEach(item => {
        if (!usedLetters[item]) {
          usedLetters[item] = [controlName];
        } else {
          usedLetters[item].push(controlName);
        }
      });
    });
    return usedLetters;
  }

  public prohibitForForm(group: FormGroup, fields: IColumnMeta
    , validators: ((controlName: string, next: any, prev: any, control: FormGroup, fields: IColumnMeta) => boolean)[])
    : { control: AbstractControl, name: string, observable: Observable<[any, any]> }[] {
    const validatorsForControl = (name: string, fn: Function) => (next, prev) => fn(name, next, prev, group, fields);
    return this.getFormGroupValueChangeObservables(group, (controlName => !!fields[controlName]))
      .map(({control, name}) => ({
        control
        , name
        , observable: this.prohibitForControl(control, name, validators.map((rule) => validatorsForControl(name, rule)))
      }));
  }

  prohibitForControl(control, name, validators: ((next: any, prev: any) => boolean)[]) {
    return control.valueChanges.pipe(startWith(''), pairwise(), filter(([prev, next]: [any, any]) => {
        if (!validators.every(fn => fn(next, prev))) {
          control.setValue(prev);
          return false;
        }
        return true;
      }));
  }

  public getFormGroupValueChangeObservables(group: FormGroup, skipControlNames = (controlName: string) => true) {
    return Object.keys(group.controls)
      .filter(controlName => skipControlNames(controlName))
      .map((controlName) => ({control: group.get(controlName), name: controlName}));
  }


  public prohibitLength(length = 3) {
    return (controlName: string, next: any, prev: any, control: FormGroup, fields: IColumnMeta) => {
      const isMulti = fields[controlName] && fields[controlName].multiInput;
      return this.getValues(next, isMulti).every(value => (value as string).length <= length);
    };
  }

  public prohibitNoneLetters(controlName: string, next: any, prev: any, control: FormGroup, fields: IColumnMeta) {
      const isMulti = fields[controlName] && fields[controlName].multiInput;
      return this.getValues(next, isMulti).every(value => !!(value as string).match(/^[A-Za-z]*$/));
  }

  public prohibitDuplicationInput(controlName: string, next: any, prev: any, control: FormGroup, fields: IColumnMeta) {
    return !this.findDuplication(controlName, next, control, fields);
  }

  public prohibitDuplicationInputAndShowWarning(controlName: string, next: any, prev: any, control: FormGroup, fields: IColumnMeta) {
    const duplication = this.findDuplication(controlName, next, control, fields);
    if (duplication) {
      const field = fields[duplication[0] === controlName ? duplication[1] : duplication[0]].name;
      this.logNotificationService.error(
        this._translate.instant('This column is already used') + ' : '
        + this._translate.instant(field));
    }
    return !duplication;
  }

  private findDuplication(controlName: string, next: any, control: FormGroup, fields: IColumnMeta) {
    const usedLetters = this.getColumnLettersWithControls(control, fields);
    const isMulti = fields[controlName] && fields[controlName].multiInput;
    const valueDuplication =  this.getValues(next, isMulti).find(value => usedLetters[value] &&
      (usedLetters[value].length > 1
        || usedLetters[value].length === 1 && usedLetters[value][0] !== controlName));
    return valueDuplication ? usedLetters[valueDuplication] : null;
  }


  private validateBaseOnLetterControl(usedLetters: {[letter: string]: string[]}, controlGroup: FormGroup, fields: IColumnMeta) {
    let duplication = false;
    Object.keys(controlGroup.value).forEach(controlName => {
      const duplications = this.getAllDuplicatedControlNameWithCurrentControl(usedLetters, controlName, controlGroup, fields);
      if (duplications.length) {
        this.setDuplicationError(controlName, duplications, controlGroup);
        duplication = true;
      } else {
        this.deleteDuplicationError(controlName, controlGroup);
      }
    });
    return duplication ? {[this.DUPLICATION_ERROR]: duplication} : null;
  }

  private getAllDuplicatedControlNameWithCurrentControl(usedLetters, controlName: string, controlGroup: FormGroup, fields: IColumnMeta) {
    const duplications = [];
    const valueList = this.getValuesFromColumnControl(controlName, controlGroup, fields);
    valueList.filter(item => usedLetters[item] && usedLetters[item].length > 1)
      .forEach(item => duplications.push(...usedLetters[item]));
    return Array.from(new Set(duplications));
  }

  private setDuplicationError(controlNameWithError: string, sameValueControls: string[], control: FormGroup) {
    this.setErrorTouched(controlNameWithError, {[this.DUPLICATION_ERROR]: sameValueControls}, control);
  }

  public setErrorTouched(controlNameWithError: string, error, control: FormGroup) {
    control.get(controlNameWithError).setErrors(error);
    control.get(controlNameWithError).markAsTouched({onlySelf: true});
  }
  private deleteDuplicationError(controlNameWithError: string, control: FormGroup) {
    this.deleteError(controlNameWithError, control, this.DUPLICATION_ERROR);
  }
  public deleteError(controlNameWithError: string, control: FormGroup, error: string) {
    if (control.get(controlNameWithError).hasError(error)) {
      const errors = control.get(controlNameWithError).errors;
      delete errors[error];
      control.get(controlNameWithError).setErrors(Object.keys(errors).length ? errors : null);
    }
  }

  public validateNumberControlIsNotLess(control: FormGroup, lessControlName =  'first_row', biggerControlName = 'last_row') {
    const getNumber = (controlName) => this.getNumberFromNumeric(control.value[controlName]);
    const [firstNumber, last] = [getNumber(lessControlName), getNumber(biggerControlName)];
    const isError = !Number.isNaN(firstNumber) && !Number.isNaN(last) && firstNumber > last;
    if (isError) {
      this.setErrorTouched(lessControlName, {[this.FIRST_ROW_IS_BIGGER_THAN_LAST]: true}, control);
      this.setErrorTouched(biggerControlName, {[this.FIRST_ROW_IS_BIGGER_THAN_LAST]: true}, control);
    } else {
      this.deleteError(lessControlName, control, this.FIRST_ROW_IS_BIGGER_THAN_LAST);
      this.deleteError(biggerControlName, control, this.FIRST_ROW_IS_BIGGER_THAN_LAST);
    }
    return isError ?  {[this.FIRST_ROW_IS_BIGGER_THAN_LAST]: true} : null;
  }

  private getNumberFromNumeric(value: any) {
    return  (value || value === 0) ? Number(value) : Number.NaN;
  }

  public validateRequireOneOfMany(control, listOfRequired = ['availability_date_field', 'reference_date']) {
    const isNotRequired = listOfRequired.some(item => !!control.value[item]);
    if (isNotRequired) {
      listOfRequired.forEach(item => this.deleteError(item, control, 'required'));
    } else {
      listOfRequired.forEach(item => control.get(item).setErrors({'required': true}));
    }
    return !isNotRequired ? {'requireOneOfMany': true} : null;
  }

  public preventKeypressIfNotLetter(e: KeyboardEvent, regex: RegExp) {
      const testChar = String.fromCharCode(e.keyCode >= 96 && e.keyCode <= 105 ? e.keyCode - 48 : e.keyCode);
      if (!(regex.test(testChar)) && ![8, 9, 37, 39, 46].some(k => k === e.keyCode)) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

  public preventKeypressIfNotLetterOrList(e: KeyboardEvent, multi = false) {
    if (multi && /[\s,]/.test(e.key)) {
      return;
    }
    this.preventKeypressIfNotLetter(e, /[a-z]/i);
  }

  public isEmptyField(control, defaultValues: {[name: string]: any} = {}) {
    return Object.keys(control.value).every((key) => {
        const val = (control.value[key] || control.value[key] === 0) ? control.value[key] : undefined;
        return defaultValues[key] === val;
      }
    );
  }
  public parseColumnsToModel(columnForm: FormGroup, metaFields: IColumnMeta) {
  return  Object.keys(columnForm.value).reduce((acc, key) => {
      acc[key] = metaFields[key] ? columnForm.value[key].toUpperCase() : columnForm.value[key];
      acc[key] = (metaFields[key] && metaFields[key].multiInput) ? acc[key].replace(/\s+/g, '') : acc[key];
      return acc;
    }, {});
  }
}
