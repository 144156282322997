import {Component, DoCheck, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../../shared/models/user.class';
import {FormControl} from '@angular/forms';
import {ModuleNames} from '../../../../../shared/values/module-names.const';
import * as _ from 'lodash';
import {USER_ROLES_FOR_SPECIAL_ADMIN, USER_ROLES_WITH_ADMIN} from '../../../../../shared/values/role-ids.enum';
import {CurrentUserService} from '../../../../../shared/services/current-user.service';
import {UserService} from '../../../../../shared/services/user.service';
import {Router} from '@angular/router';
import {GlobalEventsManager} from '../../../../../shared/services/global-events-manager.service';
import {NavigationService} from '../../../../../shared/services/navigation.service';
import {PaginationUnmarshallingService} from '../../../../../shared/services/pagination-unmarshalling.service';
import {IPagination} from '../../../../../shared/interfaces/pagination';
import {Subject} from 'rxjs';

@Component({
  selector: 'users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['../superadmin-users.component.scss', './users-list.component.scss']
})
export class UsersListComponent implements OnInit, DoCheck, OnDestroy {
  @Input() resetContainer = true;
  @HostBinding('class.material-reset') get hostClass() { return  this.resetContainer; }
  @HostBinding('class.host-users-list') get hostInit() { return !this.resetContainer; }

  users: User[] = [];
  search: string;
  noahOnly: boolean = false;
  searchControl = new FormControl();
  roleControl =  new FormControl();
  sortControl = new FormControl();
  pageControl = new FormControl();

  moduleNames = ModuleNames;
  isLoadingNewPage = false;
  pageInfo: IPagination = {currentPage: 1, pageCount: 0, perPage: 0, totalCount: 0};
  activeIndex = 0;
  oldQuery: any;
  parentModule: string;
  private destroy$ = new Subject();

  constructor(public _currentUserService: CurrentUserService,
              private userService: UserService,
              private router: Router,
              private _globalEvents: GlobalEventsManager,
              private _navigationService: NavigationService,
              private paginationService: PaginationUnmarshallingService) {}

  ngOnInit() {
    this.searchControl.valueChanges.debounceTime(400).takeUntil(this.destroy$).subscribe((value) => {
      this.pageInfo.currentPage = 1;
      this.search = value;
    });
    [this.roleControl, this.sortControl].map((control: FormControl) => {
      control.valueChanges.debounceTime(400).takeUntil(this.destroy$).subscribe(() => {
        this.pageInfo.currentPage = 1;
      });
    });
    this.pageControl.valueChanges.distinctUntilChanged().takeUntil(this.destroy$)
      .subscribe(value => this.changePage(value));
    this.parentModule = this._navigationService.getModuleName();
  }

  ngDoCheck() {
    const query = this.queryOptions();
    if (!_.isEqual(this.oldQuery, query)) {
      this.getUsers(query);
      this.oldQuery = _.cloneDeep(query);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private queryOptions(page = 1) {
    return {
      search: this.search || null,
      role: this.roleControl.value || null,
      show_hidden: true,
      sort: this.sortControl.value,
      expand: 'company,roles',
      page: page,
      noah: this.noahOnly || null
    };
  }

  private getUsers(query: any ) {
    this.isLoadingNewPage = true;
    this.userService.getWithPaginationData(query).subscribe((res) => {
      this.pageInfo = this.paginationService.unmarshall(res);
      this.users = [];
      res.json().map((user: User) => this.users.push(new User(user)));
      this.isLoadingNewPage = false;
    });
  }

  get userRoles() {
    return this.parentModule === this.moduleNames.SPECIAL_ADMIN ? USER_ROLES_FOR_SPECIAL_ADMIN : USER_ROLES_WITH_ADMIN;
  }

  changeTab(index: number) {
    this.activeIndex = index;
  }

  getAvatar(user: User) {
    return user.avatar_url ? {'background-image': 'url(' + user.avatar_url + ')'} : null;
  }

  openChat(targetId: number, event: Event) {
    console.log('openChat', targetId);
    event.stopPropagation();
    this._globalEvents.chatUserClicked$.emit(targetId);
  }

  changePage(page: number) {
      this.getUsers(this.queryOptions(page));
  }

  toggleNoahOnly() {
    this.noahOnly = !this.noahOnly;
  }
}
