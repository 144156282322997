// Angular imports
import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Angular Material imports
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
// Application-specific imports
import { ClientService } from './../../../../../client/service/client.service';
import { ClientLocation } from './../../../../../client/model/location.class';
import { LocationService } from './../../../../../client/service/location.service';
import { Client } from './../../../../../client/model/client.class';

@Component({
  selector: 'app-new-site-dialog',
  templateUrl: 'new-site-dialog.component.html',
  styleUrls: ['new-site-dialog.component.scss']
})
export class NewSiteDialogComponent {

  formGroup: FormGroup;
  isSubmitting: boolean = false;

  // Output event emitter
  @Output() siteCreated = new EventEmitter<boolean>();

  // Properties for form controls
  name: string;
  address: string;
  description: string;
  lat: number;
  lng: number;
  selectedClient: Client;
  
  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    public clientService: ClientService,
    private dialogRef: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) public data: ClientLocation
  ) {
    this.initializeFormGroup(data);
  }

  private initializeFormGroup(data: ClientLocation): void {
    this.formGroup = this.fb.group({
      name: [data.name || '', [Validators.required]],
      address: [data.address || '', [Validators.required]],
      description: [data.description || null],
      client_id: [data.client ? data.client.id : null, [Validators.required]],
      lat: [data.lat || '', [Validators.required]],
      lng: [data.lng || '', [Validators.required]]
    });
    if(data.client) {
      this.selectedClient = new Client(data.client);
    }
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.isSubmitting = true;
      const formData = this.formGroup.value;

      const isUpdate = this.data && this.data.id;
      const serviceMethod = isUpdate
        ? this.locationService.update<ClientLocation>(this.data.id, formData)
        : this.locationService.create<ClientLocation>(formData);

      serviceMethod.subscribe(response => {
        this.siteCreated.emit(response);
        this.closeDialog();
        this.isSubmitting = false;
      }, error => {
        const errorMsg = isUpdate
          ? "Site update error"
          : "Site creation error";
        console.error(errorMsg, error);
        this.isSubmitting = false;
      });
    } else {
      console.error("Mandatory fields are not filled in");
    }
  }

  onPlaceChange(place: google.maps.places.PlaceResult) {
    let lat = null;
    let lng = null;
    if (place.geometry) {
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
    }
    this.formGroup.patchValue({
      address: place.formatted_address,
      lat,
      lng
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSelectClient(client: Client) {
    this.selectedClient = new Client(client);
    this.formGroup.controls['client_id'].setValue(client.id);
  }

  clearSelectedClient() {
    this.selectedClient = null;
    this.formGroup.controls['client_id'].setValue(null);
  }

}
