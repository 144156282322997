import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormControlName, NgModel } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

@Directive({
  selector: '[placeAutocomplete]',
  host: {
    'autocomplete': 'off',
  },
  providers: [FormControlName]
})
export class PlaceAutocompleteDirective {
  isCreationMode: boolean = false;
  constructor(public elementRef: ElementRef,
              public ngModel: NgModel,
              private formControlName: FormControlName) {
    this.placeSelect.subscribe((result: google.maps.places.AutocompletePrediction) => {
      if (this.formControlName.control) {
        this.formControlName.control.setValue(result.description);
      }

      if (this.ngModel.control) {
        this.ngModel.control.setValue(result.description);
      }
    });

    Observable
      .merge(
        // Observable.fromEvent(this.elementRef.nativeElement, 'change'),
        this.placeSelect
      )
      .distinctUntilChanged()
      .debounceTime(100)
      .subscribe((result: any) => {
        if (this.isCreationMode) {
          return;
        }

        const placeDetailsService = new google.maps.places.PlacesService(this.elementRef.nativeElement);

        if (!result.place_id) {
          return;
        }

        placeDetailsService.getDetails({placeId: result.place_id}, (place: google.maps.places.PlaceResult) => {
          this.placeChange.emit({...place, description: result.description });
        });
      });
  }

  @Output() placeChange = new EventEmitter;
  @Output() placeSelect = new EventEmitter;
}
