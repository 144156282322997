/**
 * Created by aleksandr on 31.07.17.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CurrentUserService } from '../services/current-user.service';

@Injectable()
export class PlatformGuard implements CanActivate {
  constructor(private _userService: CurrentUserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('Platform guard start the check');
    return this._userService.isLoggedChecked
      ? Observable.of(true)
      : this.checkLogin().map((res) => true);
  }

  checkLogin(): Observable<boolean> {
    return this._userService.isLoggedIn
      ? Observable.of(true)
      : this
        ._userService
        .isAuth()
        .do((isAuth: boolean) => console.log('isAuth', isAuth));
    // .first();
  }
}
