<article class="admin-administration" *ngIf="isBuildAdmin">
  <section class="admin-administration__counters-container">
    <button class="admin-administration__counters-request" (click)="openCreateSiteRequestDialog()"
            (focus)="validateFocus($event)">
      {{'Request the creation of a new site' | translate}}
    </button>
    <button class="admin-administration__counters-request" (click)="openDeleteSiteRequestDialog()"
            (focus)="validateFocus($event)">
      {{'Request deletion of site(s)' | translate}}
    </button>
    <div></div>
    <button class="admin-administration__counters-request" (click)="openEsmCountChangeDialog()"
            (focus)="validateFocus($event)">
      {{'Change the number of Ecosystem M.' | translate}}
    </button>
    <ecosystem-section-counters class="admin-administration__counters--two-columns"
      [data]="[{counter: sitesCount, text: translate.instant('Sites')}, {counter: sumFloorArea, text: translate.instant('sqm')}]"
    ></ecosystem-section-counters>
    <div></div>
    <ecosystem-section-counters
      [data]="[{template: ecosystemManager, text: translate.instant('Ecosystem managers')}]"
    >
      <ng-template #ecosystemManager>
        <span class="admin-administration__counters-ecosystem-manager">
          <span class="admin-administration__counters-ecosystem-manager-active">{{assignedEsm}}</span>
          <span class="admin-administration__counters-ecosystem-manager-all">{{'/'}}{{maxEsm}}</span>
        </span>
      </ng-template>
    </ecosystem-section-counters>
    </section>

  <section class="admin-administration__site-list">
    <div class="admin-administration__site" *ngFor="let site of sites | paginate:{id:'depositsLocation'
    , itemsPerPage: sitesPagination.perPage
    , currentPage: sitesPagination.currentPage
    , totalItems: sitesPagination.totalCount }">
      <ul class="admin-administration__site-header background-primary-color"
          [ngClass]="{'admin-administration__site-header--open': !closeSites[site.id]
          ,'admin-administration__site-header--expire': isSiteExpire(site.end_of_work) || site.is_expired_by_superadmin}">
        <li class="admin-administration__site-header-name">{{site.name}}</li>
        <li class="admin-administration__site-header-toggle">
          <i (click)="toggleSite(site.id)"
             [ngClass]="{'icon': true, 'arrow-down--icon': !closeSites[site.id], 'arrow-up--icon': closeSites[site.id]}"
             aria-label="show/hide site details"
          >
          </i>
        </li>
        <li class="admin-administration__site-header-address">{{site.address}}</li>
      </ul>
      <div
               [ngClass]="closeSites[site.id] ? 'admin-administration__site-body-container--close'
               : 'admin-administration__site-body-container--open'"
               [@container]="closeSites[site.id]"
               (@container.start)="animationApplyInitStyles($event)"
               (@container.done)="animationResetTempStyles($event)"
               class="admin-administration__site-body-container"
      >
        <div class="admin-administration__site-body">
          <div class="admin-administration__site-owner-container">
            <md-select class="admin-administration__site-owner"
              placeholder="{{'Deposit owner' | translate}}"
              floatPlaceholder="always"
              (ngModelChange)="confirmChangeSite(site)"
              [(ngModel)]="site.user_id"
              [disabled]="site.assigned || isSiteExpire(site.end_of_work) || site.is_expired_by_superadmin"
              >
                <md-option [value]="null">{{'ChooseDepositOwner' | translate}}</md-option>
                <md-option *ngFor="let owner of depositOwners"
                            [value]="owner.id"
                >{{owner.username}}
                </md-option>
            </md-select>
 
            <span [ngClass]="{'admin-administration__site-owner-hint': true
            ,'admin-administration__site-owner-hint--empty': !site.user_id}">
              <i class="form-group__label--question">!</i>{{'No Deposit owner defined' | translate}}
            </span>
          </div>
          <div class="admin-administration__site-owner-container">
            <div class="admin-administration__site-floor-area-container">
              <md-input-container
              [ngClass]="checkSiteArea()">
                <input
                  mdInput
                  readonly
                  disabled
                  placeholder="{{'Floor area' | translate}}"
                  value="{{site.floor_area}}"
                >
              </md-input-container>
              <span>{{'sqm' | translate}}</span>
            </div>
            <div class="admin-administration__site-floor-area-container admin-administration__site-floor-area-gap">
              <md-input-container
              [ngClass]="checkSiteArea()">
                <input
                  mdInput
                  readonly
                  disabled
                  placeholder="{{'Living area' | translate}}"
                  value="{{site.living_area}}"
                >
              </md-input-container>
              <span>{{'sqm' | translate}}</span>
            </div>
          </div>
          <div class="admin-administration__site-status-container" [ngClass]="{'admin-administration__site-status-container--no-gap': site.site_status !== DEPOSIT_LOCATION_STATUS.IN_CONSTRUCTION}">
            <md-input-container
            [ngClass]="checkIsPortugesOrSpanishConstructionSite(site.site_status)">
              <input
                mdInput
                readonly
                disabled
                placeholder="{{'Status' | translate}}"
                value="{{DEPOSIT_LOCATION_STATUS_NAME[site.site_status] | translate}}"
              >
            </md-input-container>
            <div *ngIf="site.site_status === DEPOSIT_LOCATION_STATUS.IN_CONSTRUCTION"
                 class="admin-administration__site-end-work-container">
              <md-input-container class="admin-administration__site-end-work"
                                  [ngClass]="{
                                    'admin-administration__site-end-work--expire': isSiteExpire(site.end_of_work)
                                  }"
                                  floatPlaceholder="always"
              >
                <input
                  ng2-datetime-picker
                  parse-format="YYYY-MM-DD"
                  date-format="DD-MM-YYYY"
                  mdInput
                  disabled
                  date-only="true"
                  placeholder="{{'End of work' | translate}}"
                  [ngModel]="site.end_of_work"
                >
              </md-input-container>
              <span class="admin-administration__site-end-work-hint"
                    [ngClass]="{'admin-administration__site-end-work-hint--expire': isSiteExpire(site.end_of_work),
                    'admin-administration__site-end-work-hint--expire': site.expired === true} "
              ><i class="form-group__label--question">!</i>{{'expired' | translate}}</span>
              <button class="admin-administration__site-extend-date"
                      (click)="openExtendEndOfWork(site.end_of_work, site.id)">
                {{'Extend date' | translate}}
              </button>
            </div>
            <div *ngIf="site.site_status !== DEPOSIT_LOCATION_STATUS.IN_CONSTRUCTION"
                 class="admin-administration__site-end-work-container">
              <span class="admin-administration__site-end-work-hint"
                    [ngClass]="{'admin-administration__site-end-work-hint--expire': isSiteExpire(site.end_of_work) || site.is_expired_by_superadmin}"
              ><i class="form-group__label--question">!</i>{{'Expired since : '| translate}}{{
              correctDateFormat(site.end_of_work)
              ||
              correctDateFormat(site.expired_date)
            }}</span>
            </div>
          </div>
          <div class="admin-administration__site-tags">
            <div class="admin-administration__site-tags-label">{{'Tags' | translate}}</div>
            <div class="admin-administration__site-tags-item background-primary-color"
                 *ngFor="let tag of site.tags">{{tag.name}}
            </div>
          </div>
          <md-input-container class="admin-administration__site-description">
            <textarea
              mdInput
              md-autosize
              maxRows="2"
              minRows="1"
              rows="1"
              appTextareaAutocompletePolyfill
              placeholder="{{'Description' | translate}}"
              (ngModelChange)="saveSite(site)"
              [(ngModel)]="site.description"
              [ngModelOptions]="{updateOn: 'blur'}"
              [disabled]="isSiteExpire(site.end_of_work) || site.is_expired_by_superadmin"
            ></textarea>
          </md-input-container>
          <div class="admin-administration__site-bim-container">
            <md-select class="admin-administration__site-bim"
                       placeholder="{{'BIM Tool' | translate}}"
                       (ngModelChange)="saveSite(site)"
                       [(ngModel)]="site.bim_tool_id"
                       [disabled]="isSiteExpire(site.end_of_work) || site.is_expired_by_superadmin"
            >
              <md-option *ngFor="let tool of bimTools" [value]="tool.id">{{tool.name}}</md-option>
            </md-select>
            <md-input-container class="admin-administration__site-model">
              <input
                mdInput
                placeholder="{{'Model ID' | translate}}"
                (ngModelChange)="saveSite(site)"
                [ngModelOptions]="{updateOn: 'blur'}"
                [(ngModel)]="site.bim_id"
                [disabled]="isSiteExpire(site.end_of_work) || site.is_expired_by_superadmin"
              >
            </md-input-container>
          </div>
            <div class="admin-administration__site-documents">
              <div class="admin-administration__site-documents-label">{{'Documents' | translate}}</div>
              <documents-uploader
                class="passport__uploader admin-administration__site-documents-list"
                [uploadUrl]="siteService.getFileUploadUrlExpanded(site.id)"
                [files]="site.files || []"
                [service]="siteFileService"
                isRemoveDisabled="true"
                [hideUploadButton]="isSiteExpire(site.end_of_work) || site.is_expired_by_superadmin"
              >
              </documents-uploader>
          </div>
        </div>
      </div>
    </div>
    
  </section>
  <section>
    <div class="center">
      <pagination-controls
            class="depositlist__pagination-admin"
            id="depositsLocation"
            (pageChange)="pageChange($event)"
            maxSize="10"
            directionLinks="true"
            autoHide="true"
            previousLabel=""
            nextLabel=""
            screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
            screenReaderPageLabel="{{ 'page' | translate }}"
            screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"
      ></pagination-controls>
    </div>
  </section>
</article>
