<confirm-with-header
  title="{{'Request deletion of site(s)' | translate}}"
  [disabled]="isDisabled()"
  (onConfirm)="confirmRequest()"
  (onCancel)="cancel()">
  <article class="request-site-deletion-dialog">
    <div class="request-site-deletion-dialog__description">
      <span>{{'Select the site(s) you wish to delete,' | translate}}</span>
      <span>{{'we will process your request as soon as possible.' | translate}}</span>
    </div>
    <ul class="request-site-deletion-dialog__site-list">
      <li class="request-site-deletion-dialog__site"  *ngFor="let chosen of chosenSites; index as index">
        <md-select placeholder="{{'Site to delete' | translate}}"
                   [(ngModel)]="chosen"
                   floatPlaceholder="never"
                   (ngModelChange)="updateChosen($event, index)">
          <md-option *ngFor="let option of getAvailableList(chosen)" [value]="option">{{option.name}}</md-option>
        </md-select>
      </li>
    </ul>
    <button class="request-site-deletion-dialog__site-add" (click)="increaseChosenLength()">
      <md-icon class="request-site-deletion-dialog__site-add-icon">add</md-icon>
      <span class="request-site-deletion-dialog__site-add-text">{{'add a site' | translate}}</span>
    </button>
  </article>
</confirm-with-header>
