import { Observable } from 'rxjs/Observable';
import { EcosystemFileJunction } from '../../../dashboards/ecosystem-manager/models/ecosystem-file-junction.class';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as i0 from "@angular/core";
var EcosystemFileToJunctionService = /** @class */ (function () {
    function EcosystemFileToJunctionService() {
    }
    EcosystemFileToJunctionService.prototype.updateFilesAndJunctions = function (entityData, files, junctions, callback) {
        if (!callback) {
            callback = function () { };
        }
        var uploadFiles = this.filterFilesWithoutJunction(files, junctions);
        var copyFiles = this.filterFilesWithJunction(files, junctions);
        var assignFiles = function () {
            files.splice(0, files.length);
            files.push.apply(files, copyFiles);
        };
        if (!uploadFiles.length) {
            assignFiles();
            callback({ junctions: junctions, files: files, failed: [] });
            return { junctions: junctions, files: files, failed: [] };
        }
        this.createJunctionFromFiles(entityData.entity, entityData.type, uploadFiles)
            .subscribe(function (data) {
            junctions.push.apply(junctions, data.junctions);
            copyFiles.push.apply(copyFiles, data.files);
            assignFiles();
            callback(data);
        });
    };
    EcosystemFileToJunctionService.prototype.createJunctionFromFiles = function (entity, type, files, junctionUploaded) {
        var _this = this;
        if (junctionUploaded === void 0) { junctionUploaded = []; }
        var errors = [];
        return Observable.combineLatest(this.filterFilesWithoutJunction(files, junctionUploaded).map(function (file) {
            return _this.createSingleJunction(file, entity, type).pipe(catchError(function () {
                errors.push(file);
                return EMPTY;
            }));
        })).map(function (junctions) {
            junctions = junctionUploaded.concat(junctions);
            return ({ junctions: junctions, files: junctions.map(function (item) { return item.ecosystem_file; }), failed: errors });
        });
    };
    EcosystemFileToJunctionService.prototype.filterFilesWithJunction = function (files, junctions) {
        var fileUploaded = new Set(junctions.map(function (file) { return file.file_id; }));
        return files.filter(function (file) { return fileUploaded.has(file.id); });
    };
    EcosystemFileToJunctionService.prototype.filterFilesWithoutJunction = function (files, junctions) {
        var fileUploaded = new Set(junctions.map(function (file) { return file.file_id; }));
        return files.filter(function (file) { return !fileUploaded.has(file.id); });
    };
    EcosystemFileToJunctionService.prototype.createSingleJunction = function (file, entity, type) {
        var fileJunction = new EcosystemFileJunction({
            file_id: file.id,
            item_id: entity.id,
            item_type: type
        });
        return fileJunction.save();
    };
    EcosystemFileToJunctionService.prototype.deleteLinkInsteadIfExist = function (file, links, callback) {
        var junction = links.find(function (item) { return item.file_id === file.id; });
        if (junction) {
            junction.destroy().subscribe(function () {
                callback(junction);
            });
            return true;
        }
        return false;
    };
    EcosystemFileToJunctionService.prototype.removeJunction = function (junction, files, junctions) {
        files = files.filter(function (item) { return item.id !== junction.file_id; });
        junctions = junctions.filter(function (item) { return item.id !== junction.id; });
        return { junctions: junctions, files: files };
    };
    EcosystemFileToJunctionService.ngInjectableDef = i0.defineInjectable({ factory: function EcosystemFileToJunctionService_Factory() { return new EcosystemFileToJunctionService(); }, token: EcosystemFileToJunctionService, providedIn: "root" });
    return EcosystemFileToJunctionService;
}());
export { EcosystemFileToJunctionService };
