import { Component, OnInit } from '@angular/core';
import { MdDialogRef, } from '@angular/material';
import { NEED_STATUSES } from '../../../../entities/need/values/need-statuses.const';
import { Deposit } from '../../../../entities/deposit/models/deposit.class';
import { Need } from '../../../../entities/need/models/need.class';
import { NeedService } from '../../../../entities/need/services/need.service';
import {NeedEsmMatchDepositsFilter} from '../../../../entities/need/models/need-esm-match-deposits-filter';
import {PaginationUnmarshallingService} from '../../../../shared/services/pagination-unmarshalling.service';

@Component({
  moduleId: module.id,
  template: `
    <md-dialog-content class="md-dialog-content--with-header md-dialog-content--with-header--proportion-l">
      <h1 *ngIf="needFilter.loaded" class="md-dialog-title background-primary-color">
        {{'Deposits meeting the declared needs' | translate}}
        <dialog-close-button
          [defaultPosition]="false"
          class="md-dialog-content__close"
          (click)="mdDialogRef.close()"
        ></dialog-close-button>
      </h1>
      <div *ngIf="!needFilter.loaded" tabindex="0" ></div>
      <div class="md-dialog-content__sub-content">
        <esm-need-match
          *ngIf="needFilter.loaded"
          [needFilter]="needFilter"
        ></esm-need-match>
      </div>
    </md-dialog-content>`
})
export class EsmNeedMatchDialogComponent implements OnInit {
  needFilter = new NeedEsmMatchDepositsFilter();
  constructor(
    private _needService: NeedService
    , public mdDialogRef: MdDialogRef<any>
    , private paginationService: PaginationUnmarshallingService
    ) {}

  ngOnInit() {
    this._needService.getWithPaginationData(this.needFilter.buildOptions()).subscribe(
      (response) => this.needFilter.updateRecords(response, this.paginationService.unmarshall(response)));
  }
}
