/**
 * Created by Aleksandr C. on 8.02.17.
 */
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { NeedFileService } from '../services/need-file.service';

export class NeedFile extends UploadedDocument {
  protected provider = NeedFileService;

  public need_id: number;

  protected fields() {
    return super.fields().concat([
      'need_id',
    ]);
  }
}
