import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {TransformQualityJunction} from '../models/transform-quality-junction.class';

@Injectable()
export class TransformQualityJunctionService extends CrudService {
  protected namespace = 'transformation-qualities-junctions';
  protected namespaceSingular = 'transformation-qualities-junction';
  protected ModelClass = TransformQualityJunction;
}
