import * as tslib_1 from "tslib";
import { TransformService } from '../../../../../entities/transformation/services/transform.service';
import { OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ECustomListKeyPairClass, ECustomListRecordStatus } from '../../../../../shared/custom-list/interfaces/custom-list.interface';
import { MdDialog } from '@angular/material';
import { TsmListService } from '../../../../../entities/transformation/services/tsm-list.service';
import { Transform } from '../../../../../entities/transformation/models/transform.class';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import { TransformDetailsDialogComponent } from '../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { getTransformTypeLcView, TRANSFORM_TYPES } from '../../../../transformation-manager/values/transform-types.const';
import { NavigationService } from '../../../../../shared/services/navigation.service';
import { ModuleNames } from '../../../../../shared/values/module-names.const';
import { getSearchStatus } from '../../../../../shared/helpers/search-status-update';
import { TRANSFORM_STATUSES } from '../../../../transformation-manager/values/transform-statuses.const';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import * as moment from 'moment';
import { isIndependent } from '../../../../transformation-manager/values/transform-dependency.const';
import { CurrentUserService } from '../../../../../shared/services/current-user.service';
import { ERoleIds } from '../../../../../shared/values/role-ids.enum';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
var AdminTransformCatalogComponent = /** @class */ (function () {
    function AdminTransformCatalogComponent(_transformService, _navigationService, tsmListService, mdDialog, changeDetectorRef, currentUserService, navBarState) {
        var _this = this;
        this._transformService = _transformService;
        this._navigationService = _navigationService;
        this.tsmListService = tsmListService;
        this.mdDialog = mdDialog;
        this.changeDetectorRef = changeDetectorRef;
        this.currentUserService = currentUserService;
        this.navBarState = navBarState;
        this.sortOptions = [
            { field: 'name', view: 'Name' },
            { field: 'tsm_name', view: 'Transformation Manager' }
        ];
        this.cloudIcon = false;
        this.sortValue = 'name';
        this.TRANSFORM_TYPES = TRANSFORM_TYPES;
        this.search = new FormControl();
        this.markers = [];
        this.records = [];
        this.specialAdminFilterStatuses = [
            TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION,
            TRANSFORM_STATUSES.ASK_FOR_MODIFICATION,
            TRANSFORM_STATUSES.PUBLISHED,
        ];
        this.adminFilterStatuses = this.specialAdminFilterStatuses.slice();
        this.searchStatus = [];
        this.isNextPageLoading = false;
        this.currentPage = 1;
        this.totalPagesCount = 0;
        this
            .tsmListService
            .get()
            .subscribe(function (tsms) {
            return _this.tsms = tsms;
        });
        this.isSpecialAdmin = this._navigationService.getContext().roleId === ERoleIds.SPECIALADMIN;
    }
    AdminTransformCatalogComponent.prototype.setSearchStatus = function () {
        var getStatusValues = function (statuses) { return statuses.map(function (status) { return status.value; }); };
        if (this._navigationService.getModuleName() === ModuleNames.SPECIAL_ADMIN) {
            this.searchStatus = getStatusValues(this.specialAdminFilterStatuses);
        }
        if (this._navigationService.getModuleName() === ModuleNames.ADMIN) {
            this.searchStatus = getStatusValues(this.adminFilterStatuses);
        }
    };
    AdminTransformCatalogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.miltilanguageSubscription = this.navBarState.multilanguageObservable$.subscribe(function () { return _this.changeDetectorRef.detectChanges(); });
        this.setSearchStatus();
        this
            .search
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function () { return _this.getRecords(); });
        this.getRecords();
        this.getMarkers();
    };
    AdminTransformCatalogComponent.prototype.ngOnDestroy = function () {
        this.miltilanguageSubscription.unsubscribe();
    };
    AdminTransformCatalogComponent.prototype.downloadPdfReport = function () {
        return this._transformService.getPdfReportUrl({
            supervisor: true,
            search: this.search.value || null,
            transformation_type: this.type || null,
            tsm: this.tsmId || null,
            timezone_offset: moment().utcOffset()
        });
    };
    AdminTransformCatalogComponent.prototype.onEndOfListTriggered = function () {
        if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount) {
            this.getRecords(this.currentPage + 1);
        }
    };
    Object.defineProperty(AdminTransformCatalogComponent.prototype, "queryParams", {
        get: function () {
            var query = {
                expand: 'files,materials,tsm_name,sites,sectors,tags,status',
                admin: 1,
                supervisor: 1,
                search: this.search.value || null,
                transformation_type: this.type || null,
                tsm: this.tsmId || null,
                'status[]': this.searchStatus || null,
                sort: this.sortValue
            };
            if (this.navBarState.multilanguage) {
                query.english = true;
            }
            return query;
        },
        enumerable: true,
        configurable: true
    });
    AdminTransformCatalogComponent.prototype.getRecords = function (forPage) {
        var _this = this;
        if (forPage === void 0) { forPage = 0; }
        if (!!this.recordsLoadingRequest) {
            this.recordsLoadingRequest.unsubscribe();
        }
        var params = tslib_1.__assign({}, this.queryParams, { page: forPage, paginate: 1 });
        this.isNextPageLoading = true;
        this.recordsLoadingRequest = this._transformService
            .getWithPaginationData(params)
            .subscribe(function (data) {
            if (forPage === 0) {
                _this.records = [];
            }
            var headers = data.headers;
            _this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
            _this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
            data.json().map(function (item) { return _this.addRecord(new Transform(item)); });
            _this.mapIsReady = true;
            _this.recordsLoadingRequest = null;
            _this.changeDetectorRef.detectChanges();
            _this.isNextPageLoading = false;
        });
    };
    AdminTransformCatalogComponent.prototype.getMarkers = function () {
        var _this = this;
        this.recordsLoadingRequest = this._transformService
            .get(this.queryParams)
            .subscribe(function (data) {
            _this.mapIsReady = false;
            _this.markers = [];
            data.forEach(function (item) { return _this.addMarkers(item); });
            _this.mapIsReady = true;
            _this.recordsLoadingRequest = null;
            _this.changeDetectorRef.detectChanges();
        });
    };
    AdminTransformCatalogComponent.prototype.addMarkers = function (data) {
        var _this = this;
        var _a;
        (_a = this.markers).push.apply(_a, data.sites.map(function (item) { return _this.toMarker(data.name, item, data.independent); }));
    };
    AdminTransformCatalogComponent.prototype.toMarker = function (transformName, data, independent) {
        return {
            lat: data.lat,
            lng: data.lng,
            name: transformName,
            lines: [
                "" + data.name,
                "" + data.address
            ],
            icon: this.isSpecialAdmin ?
                (isIndependent(independent) ? 'assets/png/map/localisation-blue.png' : 'assets/png/map/localisation-orange.png')
                : null
        };
    };
    AdminTransformCatalogComponent.prototype.addRecord = function (data) {
        var materialsList = [];
        var keywordsOrControlType = [];
        var reversedColumnEntries = { cssClass: ECustomListKeyPairClass.REVERSED_LIST_COLUMN, seperator: ' ' };
        if (data.transformation_type_data.includes.incomeOutcome) {
            var incomeMaterials = data.inputMaterials
                .map(function (material) { return material.name; })
                .join(',');
            var outcomeMaterials = data.outputMaterials
                .map(function (material) { return material.name; })
                .join(',');
            var incomeTags = data.inputTags.map(function (tag) { return tag.name; }).join(', ');
            var outcomeTags = data.outputTags.map(function (tag) { return tag.name; }).join(', ');
            materialsList = [
                { key: 'Input', value: incomeMaterials },
                { key: 'Output', value: outcomeMaterials }
            ];
            keywordsOrControlType = [
                { key: 'Keywords', value: incomeTags },
                { key: 'Keywords', value: outcomeTags }
            ];
        }
        else if (data.transformation_type_data.includes.acceptedMaterials) {
            materialsList = [
                tslib_1.__assign({ key: 'Accepted materials', value: data.acceptedMaterialsView }, reversedColumnEntries),
            ];
            if (data.transformation_type_data.includes.controlType) {
                keywordsOrControlType = [
                    tslib_1.__assign({ key: 'Control type', value: data.controlTypeView }, reversedColumnEntries),
                ];
            }
        }
        var listOfLocations = [];
        if (data.transformation_type_data.includes.serviceArea) {
            listOfLocations = data.sectors.map(function (sector) {
                return { key: '', seperator: '', value: sector.name };
            });
        }
        else if (data.transformation_type_data.includes.storingSites) {
            listOfLocations = data.sites.map(function (site) {
                return { key: '', seperator: '', value: site.name };
            });
        }
        var status;
        switch (data.status) {
            case TRANSFORM_STATUSES.DRAFT.value:
                status = ECustomListRecordStatus.NO_STATUS;
                break;
            case TRANSFORM_STATUSES.PUBLISHED.value:
                status = ECustomListRecordStatus.CONFIRM;
                break;
            case TRANSFORM_STATUSES.REJECTED.value:
                status = ECustomListRecordStatus.CANCELED;
                break;
            case TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.value:
                status = ECustomListRecordStatus.QUESTION;
                break;
            default:
                status = ECustomListRecordStatus.NO_STATUS;
        }
        this.records.push(this.createTransformationRecord(status, data.transformation_type_data.value ? getTransformTypeLcView(data.transformation_type_data.value) : '', data.viewName, data.transformation_type_data.view.replace(/./, function (c) { return c.toUpperCase(); }), materialsList, keywordsOrControlType, listOfLocations, data.tsm_name, data));
    };
    AdminTransformCatalogComponent.prototype.createTransformationRecord = function (status, icon, title, subtitle, materials, keywordsOrControlType, sites, author, transform) {
        var _this = this;
        return CustomListRecord
            .build()
            .setPrimaryKey(transform.id)
            .setStatus(status)
            .setIndependent(isIndependent(transform.independent))
            .addIconColumn(icon)
            .addTitleColumn(title, subtitle, transform.english_name)
            .addListColumn(materials)
            .addListColumn(keywordsOrControlType)
            .addListColumn(sites)
            .addAuthorColumn(author)
            .addControlsColumn([{
                fn: function (data) {
                    _this.mdDialog.open(TransformDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR, { data: {
                            id: transform.id,
                            readOnly: true,
                        } }));
                },
                name: 'See details'
            }, {
                fn: function (data) {
                    location.href = 'mailto:';
                },
                name: 'Send Email'
            }]);
    };
    AdminTransformCatalogComponent.prototype.toggleState = function (state) {
        this.searchStatus = getSearchStatus(state, this.searchStatus);
        this.getRecords();
    };
    return AdminTransformCatalogComponent;
}());
export { AdminTransformCatalogComponent };
