import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { RequestFile } from '../models/request-file.class';
var RequestFileService = /** @class */ (function (_super) {
    tslib_1.__extends(RequestFileService, _super);
    function RequestFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ModelClass = RequestFile;
        _this.namespace = 'request-file';
        return _this;
    }
    RequestFileService.prototype.getUploadedFileUrl = function () {
        return this.getEndpoint(this.namespace + '/create');
    };
    return RequestFileService;
}(CrudService));
export { RequestFileService };
