import { EEcosystemIndicatorReportType } from '../../values/ecosystem-indicator-report.const';
import { AbstractReportStrategy } from './abstract-report-strategy.service';
import { WasteStrategy } from './waste-strategy.service';
import { CO2EmissionsStrategy } from './co2-emissions-strategy.service';
import { WaterReportStrategy } from './water-report-strategy.service';
import { EconomicProfitsStrategy } from './economic-profits.service';
export class ReportStrategyFactory {
    public static of(type: EEcosystemIndicatorReportType): AbstractReportStrategy {
      if (type === EEcosystemIndicatorReportType.WATER_CONSUMPTION) {
          return new WaterReportStrategy();
      }
      if (type === EEcosystemIndicatorReportType.WASTE) {
          return new WasteStrategy();
      }
      if (type === EEcosystemIndicatorReportType.CO2_EMISSION) {
          return new CO2EmissionsStrategy();
      }
      if (type === EEcosystemIndicatorReportType.ECONOMIC_PROFITS) {
          return new EconomicProfitsStrategy();
      }
      return null;
  }
}
