/**
 * Created by natalja on 29/06/2017.
 */
import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy } from '@angular/core';
import { ECustomListHintClass, ECustomListRecordStatus, ECustomListUnit } from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { DEPOSIT_STATUSES } from '../../../../entities/deposit/values/deposit-statuses.const';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { Searchable } from '../../../../shared/models/searchable.class';
import { FormControl } from '@angular/forms';
import { DepositDetailsDialogComponent } from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { MdDialog } from '@angular/material';
import { LazyLoadedList } from '../../../../shared/models/lazy-loaded-list.class';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { PASSPORT_STATUS } from '../../../../entities/passport/values/passport-status.const';
import * as moment from 'moment';
import { getSearchStatus } from '../../../../shared/helpers/search-status-update';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { PaginationUnmarshallingService } from '../../../../shared/services/pagination-unmarshalling.service';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
var SupervisorDepositsCatalogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SupervisorDepositsCatalogComponent, _super);
    function SupervisorDepositsCatalogComponent(_depositService, _globalEvents, dialog, navBarState, paginationUnmarshallingService) {
        var _this = _super.call(this, paginationUnmarshallingService) || this;
        _this._depositService = _depositService;
        _this._globalEvents = _globalEvents;
        _this.dialog = dialog;
        _this.navBarState = navBarState;
        _this.paginationUnmarshallingService = paginationUnmarshallingService;
        _this.onChange$ = new EventEmitter();
        _this.onScroll$ = new EventEmitter();
        _this.records = [];
        _this.filterStatuses = [
            DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION,
            DEPOSIT_STATUSES.PUBLISHED,
            DEPOSIT_STATUSES.ASK_FOR_MODIFICATION
        ];
        _this.sortOptions = [
            { field: '-created_at', view: 'Creation date' },
            { field: 'owner_name', view: 'Deposit owner' },
            { field: 'name', view: 'Name' },
        ];
        _this.sortValue = '-created_at';
        _this.search = new FormControl();
        _this.searchStatus = _this.filterStatuses.map(function (status) { return status.value; });
        _this.ownerList = [];
        _this.PASSPORT_STATUS = PASSPORT_STATUS;
        _this.onScroll$
            // .debounceTime(400)
            .pipe(filter(function () { return _this.canLoadNextPage(); }))
            .switchMap(function () { return _this.fetchRecords(_this.currentPage + 1); })
            .subscribe(function (deposits) { return _this.onRecordsLoaded(deposits, false); });
        _this
            .onChange$
            // .debounceTime(400)
            .do(function () { return _this.observerOnCollapse.subscribe(); })
            .switchMap(function () { return _this.fetchRecords(); })
            .do(function () { return _this.isExpanded ? _this.observerOnToggle.subscribe() : null; })
            .subscribe(function (deposits) { return _this.onRecordsLoaded(deposits); });
        _this
            .search
            .valueChanges
            // .debounceTime(300)
            .distinctUntilChanged()
            .subscribe(function () { return _this.resetRecords(); });
        _this.resetRecords();
        return _this;
    }
    SupervisorDepositsCatalogComponent.prototype.ngOnDestroy = function () {
        this.observerOnCollapse.subscribe();
    };
    SupervisorDepositsCatalogComponent.prototype.resetRecords = function () {
        // this.records.length = 0;
        // this.resetPages();
        // this.loadRecords();
        this.onChange$.emit();
    };
    SupervisorDepositsCatalogComponent.prototype.loadDataRecursive = function () {
        if (this.currentPage < this.pageCount) {
            return this.fetchRecords(this.currentPage + 1).pipe(map(function (deposits) {
                return deposits;
            }));
        }
        return Observable.of([]);
    };
    SupervisorDepositsCatalogComponent.prototype.afterLoadRecursive = function (data) {
        this.onRecordsLoaded(data, false);
    };
    SupervisorDepositsCatalogComponent.prototype.canLoadNextPage = function () {
        return !this.isLoadingPage && this.currentPage < this.pageCount;
    };
    SupervisorDepositsCatalogComponent.prototype.fetchRecords = function (page) {
        if (page === void 0) { page = 1; }
        var options = {
            expand: 'passport,owner_id,owner_name',
            supervisor: 1,
            supervisor_view: 1,
            search: this.search.value || null,
            // creator_id: this.agent,
            owner: this.owner,
            'status[]': this.searchStatus,
            sort: this.sortValue,
            page: page
        };
        if (this.navBarState.multilanguage) {
            options.english = true;
        }
        return this.loadWithPagination(this._depositService, options);
    };
    SupervisorDepositsCatalogComponent.prototype.onRecordsLoaded = function (deposits, reset) {
        var _this = this;
        if (reset === void 0) { reset = true; }
        if (reset) {
            this.records.length = 0;
            this.resetPages();
        }
        deposits.forEach(function (deposit) { return _this.addRecord(deposit); });
    };
    SupervisorDepositsCatalogComponent.prototype.addRecord = function (data) {
        // -blue hand for waiting for publication
        // -green check for published
        // -purple ! for ask for modification
        var status;
        switch (data.status) {
            case DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION.value:
                status = ECustomListRecordStatus.MANUAL;
                break;
            case DEPOSIT_STATUSES.PUBLISHED.value:
                status = ECustomListRecordStatus.CONFIRM;
                break;
            case DEPOSIT_STATUSES.ASK_FOR_MODIFICATION.value:
                status = ECustomListRecordStatus.IMPORTANT;
                break;
            default:
                status = ECustomListRecordStatus.NO_STATUS;
        }
        var hintCssClass = ECustomListHintClass.NO_CLASS;
        var hintText = '';
        // TODO figure out if this should be used as subtitle
        var units = ECustomListUnit.PCS;
        if (data.passport) {
            if (data.passport.status === PASSPORT_STATUS.DRAFT) {
                hintCssClass = ECustomListHintClass.INFO;
                hintText = 'New circular passport';
            }
            units = data.passport.customListUnit;
        }
        if (!this.ownerList.find(function (owner) { return owner.id === data.owner_id; })) {
            this.ownerList.push(new Searchable({ id: data.owner_id, name: data.owner_name }));
        }
        // let createdDate = data.created_at;
        // if (data.created_at && data.created_at.length === 19) {
        //   createdDate = data.created_at.slice(0, 10);
        // }
        var createdDate = moment(data.created_at).format('DD.MM.YYYY');
        var expirationDate = data.availability_expiration ? moment(data.availability_expiration)
            .format('DD/MM/YYYY') : null;
        var availabilityDate = moment(data.availability_date || data.created_at).format('DD/MM/YYYY');
        var availableTo = data.availability_date ? data.availability_date : data.created_at;
        var isAvailableRightNow = false;
        if (!!data.availability_expiration) {
            isAvailableRightNow = moment().isBetween(availableTo, data.availability_expiration, 'day', '[)');
        }
        else {
            isAvailableRightNow = moment().isAfter(availableTo, 'day');
        }
        this.records.push(this.createDepositRecord(status, hintCssClass, hintText, data.name, data.passport.name || '', data.quantity, units, data.conditionData ? data.conditionData.view.replace(/./, function (c) { return c.toUpperCase(); }) : '', createdDate, availabilityDate, expirationDate, isAvailableRightNow, data.owner_name, data));
    };
    SupervisorDepositsCatalogComponent.prototype.createDepositRecord = function (status, hintCssClass, hintText, title, subtitle, amount, unit, unitStatus, createdAt, availableAt, expiredAt, isAvailableAtTheMoment, author, deposit) {
        var _this = this;
        var listColumn = [{ key: 'Created', value: createdAt }];
        listColumn.push({ key: 'Availability', value: availableAt + (expiredAt ? ' - ' + expiredAt : ''),
            cssClass: isAvailableAtTheMoment ? 'important-value' : null });
        var showValidationButtons = deposit.status === DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION.value;
        return CustomListRecord
            .build()
            .setPrimaryKey(deposit.id)
            .setStatus(status)
            .setHint(hintText, hintCssClass)
            .addTitleColumn(title, subtitle, null, deposit.passport.english_name)
            .addUnitColumn(amount, unit, unitStatus)
            .addListColumn(listColumn)
            .addAuthorColumn(author)
            .addControlsColumn([{
                fn: function (data) {
                    _this.dialog.open(DepositDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                            readOnly: true,
                            id: deposit.id,
                            showValidationButtons: showValidationButtons,
                        } })).afterClosed().subscribe(function () { return _this.resetRecords(); });
                },
                name: 'See details'
            }, {
                fn: function (data) { return _this.openChat(deposit.owner_id); },
                name: 'Chat with deposit owner'
            }, {
                fn: function (data) { return _this.openChat(deposit.creator_id); },
                name: 'Chat with agent'
            }].concat((showValidationButtons ? [
            {
                name: 'Validate',
                fn: function () {
                    deposit.publish().subscribe(function () { return _this.resetRecords(); });
                }
            },
            {
                name: 'Reject',
                fn: function () {
                    deposit.reject().subscribe(function () { return _this.resetRecords(); });
                }
            }
        ] : [])));
    };
    SupervisorDepositsCatalogComponent.prototype.toggleState = function (state) {
        this.searchStatus = getSearchStatus(state, this.searchStatus);
        this.resetRecords();
    };
    SupervisorDepositsCatalogComponent.prototype.getStatusLabel = function (state) {
        var key = Object.keys(DEPOSIT_STATUSES).find(function (key) { return DEPOSIT_STATUSES[key].value === state; });
        return key || 'N/A';
    };
    SupervisorDepositsCatalogComponent.prototype.openChat = function (targetId) {
        console.log('openChat', targetId);
        this._globalEvents.chatUserClicked$.emit(targetId);
    };
    return SupervisorDepositsCatalogComponent;
}(LazyLoadedList));
export { SupervisorDepositsCatalogComponent };
