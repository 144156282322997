export const TRANSFORMATION_STATUSES = {
  DRAFT: 1,
  WAITING_FOR_PUBLICATION: 2,
  PUBLISHED: 3,
  REJECTED: 4,
};

export const TRANSFORMATION_TYPE = {
  1 : 'Sorting',
  2 : 'Upcycling',
  3 : 'Recycling',
  4 : 'Transport',
  5 : 'Controlling',
  6 : 'Storing',
  7 : 'Other'
};
