import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../../entities/stats/services/stats.service';
import { TSM_STATISTICS_SEGMENTS, TSM_STATISTICS_SEGMENTS_VIEW } from '../../values/tsm-statistics-segments.const';
import { TSMStatsContainer } from '../../../../entities/stats/models/tsm-stats-container.class';

@Component({
  moduleId: module.id,
  templateUrl: 'tsm-statistics.component.html'
})
export class TransformStatisticsComponent implements OnInit {
  TSM_STATISTICS_SEGMENTS = TSM_STATISTICS_SEGMENTS;
  TSM_STATISTICS_SEGMENTS_VIEW = TSM_STATISTICS_SEGMENTS_VIEW;
  segments: TSM_STATISTICS_SEGMENTS[] = [
    TSM_STATISTICS_SEGMENTS.TRANSFORMATIONS,
    TSM_STATISTICS_SEGMENTS.SITES
  ];
  selectedSegment: number = TSM_STATISTICS_SEGMENTS.TRANSFORMATIONS;
  segmentStats: any = {};

  constructor(private statsService: StatsService) {
  }

  ngOnInit() {
    this.getSegmentData();
  }

  changeSegment(segment: number) {
    this.selectedSegment = segment;
    this.getSegmentData();
  }

  getSegmentData() {
    switch (this.selectedSegment) {
      case TSM_STATISTICS_SEGMENTS.TRANSFORMATIONS:
        this.getTransformationsData();
        break;
      case TSM_STATISTICS_SEGMENTS.SITES:
        this.getSitesData();
        break;
      default:
        break;
    }
  }

  getTransformationsData() {
    this.statsService.getTSMStats().subscribe((tsmStats: TSMStatsContainer) => {
      this.segmentStats = tsmStats.getTransformations();
    });
  }

  getSitesData() {
    this.statsService.getTSMStats().subscribe((tsmStats: TSMStatsContainer) => {
      this.segmentStats = tsmStats.getSites();
    });
  }
}
