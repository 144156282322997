import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MdInputDirective } from '@angular/material';
var DepositImportColumnComponent = /** @class */ (function () {
    function DepositImportColumnComponent(cd) {
        this.cd = cd;
        this.required = false;
        this.onKeyDown = new EventEmitter();
        this.onBlur = new EventEmitter();
    }
    Object.defineProperty(DepositImportColumnComponent.prototype, "serRequired", {
        set: function (isRequired) {
            this.cd.detectChanges();
            this.required = isRequired;
        },
        enumerable: true,
        configurable: true
    });
    DepositImportColumnComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    DepositImportColumnComponent.prototype.registerOnTouched = function (fn) {
        this.onTouch = fn;
    };
    DepositImportColumnComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    DepositImportColumnComponent.prototype.writeValue = function (obj) {
        this.cd.detectChanges();
        this.value = obj;
    };
    DepositImportColumnComponent.prototype.changeValue = function () {
        this.onChange(this.value);
    };
    DepositImportColumnComponent.prototype.touchValue = function () {
        this.onBlur.emit();
        this.onTouch(this.value);
    };
    return DepositImportColumnComponent;
}());
export { DepositImportColumnComponent };
