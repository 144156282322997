

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../../../../shared/services/user.service';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { Searchable } from '../../../../shared/models/searchable.class';

export interface SupervisorsAndDepOwnersLists {
  depositOwners: Searchable[];
  supervisors: Searchable[];
}

@Injectable()
export class SupervisorAndDepOwnerResolver implements Resolve<SupervisorsAndDepOwnersLists> {

  constructor(private userService: UserService) {}

  resolve(routeShot: ActivatedRouteSnapshot, stateShot: RouterStateSnapshot) {
  return  Observable.forkJoin(
    ...[this.userService.getListDepositOwners().map((depositOwners: Searchable[]) => {
        depositOwners.forEach((user: Searchable) => {
          if (!user.id)
            user.id = -1;
        });
        return depositOwners;
      }),
    this.userService.getListSupervisors().map((supervisors: Searchable[]) => {
      supervisors.forEach((user: Searchable) => {
        if (!user.id)
          user.id = -1;
      });
      return supervisors;
      })]
  ).map((data: [Searchable[], Searchable[]]) => {
    return {
      depositOwners: data[0] || [],
      supervisors: data[1] || []
    };
  });
  }
}
