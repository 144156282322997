/**
 * Created by atat on 19/06/2017.
 */
import { Component, Inject } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
@Component({
  moduleId: module.id,
  selector: 'tsm-order-details-dialog',
  templateUrl: 'tsm-order-details-dialog.component.html'
})
export class TsmOrderDetailsDialogComponent {
  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<void>,) {
  }
}
