import { IModuleNavigation } from '../../shared/interfaces/module-navigation.interface';

export const SUPERADMIN_NAVIGATION: IModuleNavigation[] = [
  {
    url: 'superadmin/users',
    title: 'Users',
    icon: 'users--icon'
  },
  {
    url: 'superadmin/zones',
    title: 'System Passports',
    icon: 'cube--icon'
  },
];
