var CustomSelectionListWithChipRecordModel = /** @class */ (function () {
    function CustomSelectionListWithChipRecordModel() {
        this.classList = [];
    }
    Object.defineProperty(CustomSelectionListWithChipRecordModel.prototype, "name", {
        get: function () {
            return !!this.nameFunc ? this.nameFunc(this.value) : this.value;
        },
        enumerable: true,
        configurable: true
    });
    CustomSelectionListWithChipRecordModel.build = function () {
        return new CustomSelectionListWithChipRecordModel();
    };
    CustomSelectionListWithChipRecordModel.prototype.addClass = function (classNames) {
        this.classList = this.classList.concat(classNames);
        return this;
    };
    CustomSelectionListWithChipRecordModel.prototype.setNameFunc = function (fn) {
        this.nameFunc = fn;
        return this;
    };
    CustomSelectionListWithChipRecordModel.prototype.addValue = function (value) {
        this.value = value;
        return this;
    };
    return CustomSelectionListWithChipRecordModel;
}());
export { CustomSelectionListWithChipRecordModel };
