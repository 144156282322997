/**
 * Created by aleksandr on 14.02.17.
 */
export const QUANTITY_PRECISION: any[] = [{
  key: 1,
  value: 'precise'
}, {
  key: 2,
  value: 'flexible'
}, {
  key: 0,
  value: 'unknown'
}];
export const NEED_QUANTITY_PRECISION = QUANTITY_PRECISION;
