/**
 * Created by aleksandr on 31.03.17.
 */

import {NgModule} from '@angular/core';
import {AGENT_ROUTES} from './agent.routes';
import {DepositModule} from '../../entities/deposit/deposit.module';
import {AgentGuard} from './agent.guard';
import {RouterModule} from '@angular/router';
import {AGENT_CONTEXT} from './agent.context';
import {DASHBOARD_GUARDS} from '../../shared/auth/dashboard-guards.const';
import {AgentStatisticsComponent} from './partials/agent-statistics/agent-statistics.component';
import {SharedModule} from '../../shared/shared.module';
import {DepositDeleteModalComponent} from './dialogs/deposit-delete-dialog/deposit-delete-modal.component';
import {DepositDeleteWarningComponent} from './dialogs/deposit-delete-warning/deposit-delete-warning.component';
import {DepositImportDialogComponent} from './dialogs/deposit-import-dialog/deposit-import-dialog.component';
import {DepositImportWarningComponent} from './dialogs/deposit-import-warning/deposit-import-warning.component';
import {DepositImportService} from '../../entities/deposit/services/deposit-import.service';
import {DepositFileService} from '../../entities/deposit/services/deposit-file.service';
import {DepositLocationFileService} from '../../entities/deposit/services/deposit-location-file.service';
import {ReportFileService} from '../../entities/deposit/services/report-file.service';
import {DepositDeleteAllModalComponent} from './dialogs/deposit-delete-all-dialog/deposit-delete-all-modal.component';
import {ImportDepositComponent} from './partials/import-components/deposits/import-desposit.component';
import {ImportSpreadsheetModule} from '../../import-spreadsheet/import-spreadsheet.module';
import { AgentComponent } from './page/agent.component';
import { AgentPassportDashboardComponent } from './interfaces/agent-passport-dashboard.component';
import { BaseComponentsModule } from '../../app/components/base/base-components.module';
import { FilterComponentsModule } from '../manufacturer/components/filter.module';
import { PassportModule } from '../../entities/passport/passport.module';

@NgModule({
  imports: [
    SharedModule,
    ImportSpreadsheetModule,
    RouterModule.forChild([{
      path: AGENT_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: AGENT_ROUTES
    }]),
    PassportModule,
    DepositModule,
    BaseComponentsModule,
    FilterComponentsModule
  ],
  declarations: [
    AgentStatisticsComponent,
    DepositImportDialogComponent,
    DepositImportWarningComponent,
    DepositDeleteModalComponent,
    DepositDeleteWarningComponent,
    DepositDeleteAllModalComponent,
    ImportDepositComponent,
    AgentComponent,
    AgentPassportDashboardComponent,
  ],
  exports: [],
  entryComponents: [
    DepositImportDialogComponent,
    DepositImportWarningComponent,
    DepositDeleteModalComponent,
    DepositDeleteWarningComponent,
    DepositDeleteAllModalComponent,
    ImportDepositComponent,
    AgentComponent
  ],
  providers: [AgentGuard, DepositImportService, DepositFileService, ReportFileService, DepositLocationFileService],
})
export class AgentModule {

}
