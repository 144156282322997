<section class="ecosystem-compiler">
  <div class="ecosystem-compiler__menu-header">
    <h3 class="ecosystem-compiler__menu-header-title uppercase">
      {{ 'Need' | translate }}
    </h3>
    <div class="ecosystem-compiler__menu-header-vertical-line"></div>
    <h3 class="ecosystem-compiler__menu-header-description">{{ need.name }}</h3>
  </div>

  <ul class="ecosystem-compiler__tab-list" >

    <li class="ecosystem-compiler"  *ngIf="offer.id > 0 && isVisible">
      <header class="ecosystem-compiler__menu-item-header">
        <div class="ecosystem-compiler__menu-item-header-container">
          <img
            class="ecosystem-compiler__menu-item-header-container-icon"
            src="../../../../../assets/svg/ecosystem-compiler-offer--icon.svg"
          />
          <h3 class="ecosystem-compiler__menu-item-header-container-title">
            {{ 'Offer' | translate }}
          </h3>
        </div>
        <div class="ecosystem-compiler__menu-item-header-container">
          <div
            class="ecosystem-compiler__order-status--standby ecosystem-compiler__menu-item-header-container-status"
          >
            {{step.getStatusText() | capitalize | translate}}
          </div>
          <div class="ecosystem-compiler__expander" (click)="changeTab(this.tabType.OFFER); expandOfferTextAreas()">
            <button class="ecosystem-compiler__fold-tab button-transparent">
              <i
                class="icon"
                [ngClass]="isOpen(this.tabType.OFFER) ? 'icon--fold-tab-white' : 'icon--unfold-tab-white' "
              ></i>
            </button>
          </div>
        </div>
      </header>

      <section class="ecosystem-compiler__menu-item-section" *ngIf="isOpen(this.tabType.OFFER)">
        <div class="ecosystem-compiler-offer-section">
          <conversion-field
            [setToggleColorActive]="isReadOnly()"
            class="ecosystem-compiler-offer-section-conversion"
            [identifier]="'ecosystem-quantity-offered' + '_' + offer.id"
            [setDefaultConversionOff]="sameUnitButDifferentConversion()"
            placeholder="{{'Quantity offered' | translate}}"
            [volumeUnit]="need.passport.unit"
            [quantity]="offer.quantity"
            [conversionFactor]="need.passport.conversion"
            [isDisabled]="isReadOnly()"
            [emptyQuantityHolder]="''"
            [quantityInTonsOnInit]="offer?.weight"
            (quantityInTonsChanged)="updateQuantity($event)"
            (quantityChanged)="updateOfferWeight($event)"
            >
          </conversion-field>
          <div class="ecosystem-compiler-offer-section-needed-quantity">
            <conversion-field
              [setToggleColorActive]="true"
              class="ecosystem-compiler-offer-section-conversion"
              identifier="ecosystem-quantity-wanted"
              placeholder="{{'Quantity wanted' | translate}}"
              [volumeUnit]="need.passport.unit"
              [quantity]="need.quantity"
              [conversionFactor]="need.passport.conversion"
              [isDisabled]="true">
            </conversion-field>
            <span
            class="ecosystem-compiler-offer-section-needed-quantity-text"
            [ngClass]="getPrecisionTextStyles(need.passport.unit)">
              {{ getPrecisionText() }}
            </span>
          </div>
        </div>
        <div class="ecosystem-compiler-date-section">
          <div class="ecosystem-compiler-date-section-first-column">
            <span class="ecosystem-compiler-date-section-title"  [ngClass]="{'primary-color': !isReadOnly()}">
              {{ 'Delivery date' | translate }}
            </span>
            <md-input-container class="ecosystem-compiler-date-section-input">
              <input
                [readonly]="isReadOnly()"
                mdInput
                autocomplete="off"
                ng2-datetime-picker
                date-only="true"
                date-format="DD-MM-YYYY"
                parse-format="YYYY-MM-DD"
                [(ngModel)]="offer.delivery_date"
                (ngModelChange)="onDeliveryDateChange($event)"
                />
            </md-input-container>
          </div>
          <div class="ecosystem-compiler-date-section-column">
            <span class="ecosystem-compiler-date-section-title">
              {{ 'Required date' | translate }}
            </span>
            <md-input-container class="ecosystem-compiler-date-section-input">
              <input
                mdInput
                autocomplete="off"
                ng2-datetime-picker
                date-only="true"
                date-format="DD-MM-YYYY"
                parse-format="YYYY-MM-DD"
                disabled
                [value]="getRequiredDate()"
               />
            </md-input-container>
          </div>
          <div class="ecosystem-compiler-date-section-accuracy"  [ngClass]="getPrecisionTextStyles(need.passport.unit)">
            <span >
              {{getDatePrecisionText()}}
            </span>
          </div>
        </div>
        <div class="ecosystem-compiler-offer-section">
          <span class="ecosystem-compiler-offer-section-title ecosystem-compiler-offer-section-address">
            {{ 'Delivery address' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-offer-section-text-area">
            <textarea
              rows="1"
              #offerDeliveryAddress
              [value]="this.need.client_location.address"
              disabled
              mdInput
            ></textarea>
          </md-input-container>
            <span class="ecosystem-compiler-offer-section-title" [ngClass]="{'primary-color': !isReadOnly()}">
              {{ 'Price' | translate }}
            </span>
          <div class="ecosystem-compiler-offer-section-description-container">
            <md-input-container   class="ecosystem-compiler-offer-section-price">
              <input
                [readonly]="isReadOnly()"
                type="number"
                mdInput
                (input)="onPriceChange($event.target.value)"
                [(ngModel)]="offer.price"
                [value]="this.offer.price"
                autocomplete="off"
              />
            </md-input-container>
            <span class="ecosystem-compiler-offer-section-currency">€</span>
            <i
              class="form-group__label--question ecosystem-compiler-offer-section-description"
              mdTooltip="{{'ESM_COMPILER_NEED_STEP_PRICE_TOOLTIP' | translate}}"
              mdTooltipPosition="above"
              >?</i>
          </div>
          <span class="ecosystem-compiler-offer-section-title" [ngClass]="{'primary-color': !isReadOnly()}">
            {{ 'Description' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-offer-section-text-area">
            <textarea
              rows="1"
              [readonly]="isReadOnly()"
              #offerPassportDescription
              [value]="this.offer.description"
              (input)="onOfferDescriptionChange($event.target.value); expandOfferTextAreas()"
              mdInput
              autocomplete="off"
            ></textarea>
          </md-input-container>
          <div class="ecosystem-compiler-offer-section-title  primary-color">
            {{ 'Documents' | translate }}
          </div>
          <ul>
            <div class="ecosystem-compiler-offer-section-document-container">
              <li class="upload-files__download ecosystem-compiler-offer-section-icon">
                <input
                  class="upload-files__add ecosystem-compiler-offer-section-icon"
                  autocomplete="off"
                  type="file"
                  >
                  <label >
                    <i (click)="openFiles()" class="upload-files__camera documents--icon"></i>
                  </label>
              </li>
              <document-item *ngFor="let junction of offer.ecosystem_file_junction"
              [noLink]="true"
              [file]="junction.ecosystem_file"
              [isRemoveFromFilesDisabled]="false"
              (remove)="removeJunctionFromOffer(junction)">
              </document-item>
            </div>
          </ul>
        </div>
      </section>
    </li>

    <li class="ecosystem-compiler__menu-tab">
      <header class="ecosystem-compiler__menu-item-header">
        <div class="ecosystem-compiler__menu-item-header-container">
          <img
            class="ecosystem-compiler__menu-item-header-container-icon"
            src="../../../../../assets/svg/ecosystem-compiler-need-summary--icon.svg"
          />
          <h3 class="ecosystem-compiler__menu-item-header-container-title">
            {{ 'Need Summary' | translate }}
          </h3>
        </div>
        <div class="ecosystem-compiler-menu-tab-expander" (click)="changeTab(this.tabType.SUMMARY); expandNeedSummaryTextAreas()">
          <button class="button-transparent">
            <i
              class="icon"
              [ngClass]="isOpen(this.tabType.SUMMARY) ? 'icon--fold-tab-white' : 'icon--unfold-tab-white' "
            ></i>
          </button>
        </div>
      </header>
      <section class="ecosystem-compiler__tab-section" *ngIf="isOpen(this.tabType.SUMMARY)">
        <div class="ecosystem-compiler-need-summary">
          <span class="ecosystem-compiler-need-summary-data-title">
                {{ 'Need name' | translate }}
          </span>
          <div class="ecosystem-compiler-need-summary-name-row">
            <md-input-container class="ecosystem-compiler-need-summary-name-field">
              <input
                disabled
                mdInput
                [value]="this.need.name"/>
            </md-input-container>
              <div class="ecosystem-compiler-need-summary-link">
                <button (click)="viewDetails(step)">
                  <img src="../../../../../assets/svg/ecosystem-compiler-eye--icon.svg" class="ecosystem-compiler-need-summary-link-eye" >
                  <span  class="ecosystem-compiler-need-summary-link-text primary-color">{{ 'see the need' | translate }}</span>
                </button>
              </div>
          </div>
          <span class="ecosystem-compiler-need-summary-data-title">
              {{ 'Circular passport' | translate }}
          </span>
          <md-input-container   class="ecosystem-compiler-need-summary-name-field">
              <input
                mdInput
                [value]="this.need.passport.name"
                autocomplete="off"
                disabled
              />
          </md-input-container>
          <span class="ecosystem-compiler-need-summary-data-title">
              {{ 'Quantity reserved' | translate }}
          </span>
          <div class="ecosystem-compiler-need-summary-accuracy-container">
              <md-input-container class="ecosystem-compiler-need-summary-required-field">
                <input
                  mdInput
                  [value]="this.need.quantity"
                  autocomplete="off"
                  disabled
                />
              </md-input-container>
              <span>
                {{this.need.passport.unit_text | translate}}
              </span>
              <span class="ecosystem-compiler-need-summary-accuracy-container-text">
                {{this.need.quantity_precision_text | translate}}
              </span>
          </div>
          <span class="ecosystem-compiler-need-summary-data-title">
            {{ 'Required delivery date' | translate }}
          </span>
          <div class="ecosystem-compiler-need-summary-accuracy-container">
            <md-input-container class="ecosystem-compiler-need-summary-required-field">
              <input
                mdInput
                [value]="getRequiredDate()"
                autocomplete="off"
                disabled
              />
            </md-input-container>
            <span class="ecosystem-compiler-need-summary-accuracy-container-text">
              {{this.need.date_precision_text}}
            </span>
          </div>
          <span class="ecosystem-compiler-need-summary-data-title">
            {{ 'Delivery address' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-need-summary-text-area">
            <textarea
              rows="1"
              #needSummaryAddress
              [value]="this.need.client_location.address"
              mdInput
              disabled
            ></textarea>
          </md-input-container>
          <span class="ecosystem-compiler-need-summary-data-title">
            {{ 'Description' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-need-summary-text-area">
            <textarea
              [readonly]="isReadOnly()"
              rows="1"
              #needSummaryDescription
              [value]="this.need.description"
              mdInput
              disabled
            ></textarea>
          </md-input-container>
        </div>
      </section>
    </li>

    <li class="ecosystem-compiler__menu-tab" *ngIf="isVisible">
      <header class="ecosystem-compiler__menu-item-header">
        <div class="ecosystem-compiler__menu-item-header-container">
          <img
            class="ecosystem-compiler__menu-item-header-container-icon ecosystem-compiler__menu-item-header-container-chat-icon"
            src="../../../../../assets/svg/ecosystem-compiler-chat-with-need-manager--icon.svg"
          />
          <div>

          </div>
          <div>
            <h3 class="ecosystem-compiler__menu-item-header-container-title">
              {{ 'Chat with Need manager' | translate }}
            </h3>
            <div class="ecosystem-compiler__menu-item-header-container-title">
              -
              {{ !!need.independent ? need.sales_user.company_name : need.sales_contact }}
            </div>
          </div>
        </div>
        <div class="ecosystem-compiler__expander" (click)="changeTab(this.tabType.CHAT)">
          <button class="ecosystem-compiler__fold-tab button-transparent">
            <i
              class="icon"
              [ngClass]="isOpen(this.tabType.CHAT) ? 'icon--fold-tab-white' : 'icon--unfold-tab-white' "
            ></i>
          </button>
        </div>
      </header>

      <section class="ecosystem-compiler__tab-section" *ngIf="isOpen(this.tabType.CHAT)">
        <chat-form
          [room]="room"
          [isChatForNeedIndep]="(need.isPublic() && !need.isOwnedByCurrentcompany())"
          *ngIf="room"
        ></chat-form>
      </section>
    </li>
  </ul>
</section>
