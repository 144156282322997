import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'common-info-sign',
  templateUrl: './common-info-sign.component.html',
  styleUrls: ['./common-info-sign.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonInfoSignComponent {
  @Input() primaryFill = true;
  @Input() borderSizeClassModifier: '1' | '1-half' | '2' | '3' = '3';
  @Input() sizeClassModifier:  '16' | '20' | '18' = '20';
  constructor() { }

}
