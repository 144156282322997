import { ElementRef, QueryList } from '@angular/core';
import { ElRowCounterService } from '../../../../../services/el-row-counter.service';
var ReportPdfSiteListComponent = /** @class */ (function () {
    function ReportPdfSiteListComponent(rowCounter) {
        this.rowCounter = rowCounter;
        this.isLongVersion = false;
        this.measureRows = true;
        this.sites = [];
    }
    Object.defineProperty(ReportPdfSiteListComponent.prototype, "changeSites", {
        set: function (input) {
            this.sites = input;
            if (this.measureRows) {
                this.measureRowNumber();
            }
        },
        enumerable: true,
        configurable: true
    });
    ReportPdfSiteListComponent.prototype.measureRowNumber = function () {
        var _this = this;
        setTimeout(function () {
            _this.rowCounter.setSite(_this.sitesEl.map(function (item) { return item; }));
            _this.rowCounter.countRows();
        });
    };
    return ReportPdfSiteListComponent;
}());
export { ReportPdfSiteListComponent };
