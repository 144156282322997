import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MdDialog } from '@angular/material';
import { catchError, switchMap, map } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ConfirmDialogComponent } from './../../../../../shared/confirm-dialog/confirm-dialog.component';
import { Client } from './../../../../client/model/client.class';
import { ClientService } from './../../../../client/service/client.service';
import { NewRecipientsDialogComponent } from './PopupDialog/new-recipients-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'need-recipients',
  templateUrl: 'need-recipients.component.html',
  styleUrls: ['need-recipients.component.scss']
})
export class NeedRecipientsComponent implements OnInit {

  search: string;
  clientList: Client[];
  public page: number = 1;

  public pageInfo = {
    totalCount: 1,
    perPage: 10,
    pageCount: 1,
    currentPage: 1
  };

  constructor(
    private clientService: ClientService,
    private dialogService: MdDialog,
    private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.loadRecipients();
  }

  /**
   * Calls the ClientService to fetch the list of clients based on provided parameters.
   * Handles any errors encountered during the fetch.
   */
  public loadRecipients(): void {
    this.clientService.getWithArray(this.getLoadParams())
      .pipe(
        // Handle service call errors
        catchError(error => {
          console.error('Error loading containers', error);
          return throwError(error);
        })
      )
      .subscribe((res) => this.handleLoadResponse(res));
  }

  /**
 * Generates the set of parameters to be sent with the request to the ClientService.
 * 
 * @return any[] The array of parameters.
 */
  private getLoadParams(): any[] {
    const baseParams = [
      ['expand', 'published_needs,treated_needs,client_contacts'],
      ['sort', 'name'],
      ['paginate', true],
      ['page', this.page]
    ];
    if (this.search) {
      baseParams.push(['search', this.search]);
    }
    return baseParams;
  }

  /**
   * Handles the response received from the ClientService.
   * Populates the clientList and pageInfo properties based on the response.
   *
   * @param res The response from the ClientService.
   */
  private handleLoadResponse(res: any): void {
    if (!res || !res.json) {
      console.error('Invalid or empty response received.');
      return;
    }

    this.clientList = this.transformClients(res.json());

    this.pageInfo = {
      totalCount: Number(res.headers.get('X-Pagination-Total-Count')),
      perPage: Number(res.headers.get('X-Pagination-Per-Page')),
      pageCount: Number(res.headers.get('X-Pagination-Page-Count')),
      currentPage: Number(res.headers.get('X-Pagination-Current-Page'))
    };
  }

  /**
   * Transforms the raw client data into an array of Client instances.
   *
   * @param clientsData Raw client data.
   * @return Client[] An array of Client instances.
   */
  private transformClients(clientsData: any[]): Client[] {
    return clientsData.map(clientData => {
      const newClient = new Client(clientData);
      newClient.logo = clientData.image;
      return newClient;
    });
  }

  /**
   * Opens the recipient dialog. 
   * If a recipient (Client instance) is provided, it loads the dialog in edit mode. Otherwise, in creation mode.
   *
   * @param recipient An optional Client instance.
   */
  openRecipientDialog(recipient?: Client): void {
    const dialogConfig = {
      width: '900px',
      data: recipient || {}
    };

    const dialogRef = this.dialogService.open(NewRecipientsDialogComponent, dialogConfig);

    dialogRef.componentInstance.siteCreated.subscribe(() => this.loadRecipients());
  }

  viewEditRecipient(recipient: Client): void {
    this.openRecipientDialog(recipient);
  }

  /**
 * Placeholder function for setting default recipient.
 * To be implemented.
 */
  setdefaultRecipient(recipient: Client) {
  }

  /**
 * Placeholder function for duplicating recipient.
 * To be implemented.
 */
  duplicateRecipient(recipient: Client) {
  }

  /**
   * Triggers the deletion process for a given client.
   * Shows a confirmation dialog before proceeding.
   * If the deletion is confirmed and successful, reloads the clients.
   *
   * @param recipient The Client instance to be deleted.
   */
  deleteRecipient(recipient: Client): void {
    recipient.canDelete()
      .pipe(
        switchMap(canDelete => {
          if (!canDelete) return of(false);

          const dialogConfig = {
            data: {
              text: this._translate.instant('CONFIRM_DELETE_CLIENT'),
              imgUrl: 'assets/png/warning_purple.png',
              subText: this._translate.instant('This action cannot be undone.')
            }
          };

          const dialogRef = this.dialogService.open(ConfirmDialogComponent, dialogConfig);

          return dialogRef.afterClosed().pipe(
            switchMap(confirmed => confirmed ? recipient.destroy().pipe(map(() => true)) : of(false))
          );
        })
      )
      .subscribe(deleted => {
        if (deleted) this.loadRecipients();
      });
  }

  /**
  * Handles page change events, updating the current page and reloading the recipients.
  *
  * @param $event The new page number.
  */
  pageChange($event: number): void {
    this.page = $event;
    this.loadRecipients();
  }

  /**
   * Handles image loading errors by hiding the erroneous image.
   *
   * @param event The error event from the image element.
   */
  onImageError(event: any): void {
    event.target.style.display = 'none';
  }
}
