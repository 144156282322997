/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material";
import * as i4 from "../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i5 from "../read-only/form-elems-hidden.directive";
import * as i6 from "../read-only/read-only.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./confirm-dialog.component";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
function View_ConfirmDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "deposit-delete-modal__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subText; _ck(_v, 1, 0, currVal_0); }); }
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 30, "md-dialog-content", [], [[2, "mat-dialog-content", null]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(3, 16384, null, 0, i3.MdDialogContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 27, "div", [["class", "deposit-delete-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "deposit-delete-modal__heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "deposit-delete-modal__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["class", "deposit-delete-modal__triangle"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "deposit-delete-modal__exclamation"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["!"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "deposit-delete-modal__text-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "deposit-delete-modal__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_1)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 16, "div", [["class", "deposit-delete-modal__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 7, "button", [["class", "deposit-delete-modal__button"], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MdButton_0, i4.RenderType_MdButton)), i1.ɵdid(17, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(18, 180224, null, 0, i3.MdButton, [i1.ElementRef, i1.Renderer, i3.FocusOriginMonitor], null, null), i1.ɵdid(19, 16384, null, 0, i3.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(20, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null), (_l()(), i1.ɵted(21, 0, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(23, 1), (_l()(), i1.ɵeld(24, 0, null, null, 7, "button", [["class", "deposit-delete-modal__button"], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MdButton_0, i4.RenderType_MdButton)), i1.ɵdid(25, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(26, 180224, null, 0, i3.MdButton, [i1.ElementRef, i1.Renderer, i3.FocusOriginMonitor], null, null), i1.ɵdid(27, 16384, null, 0, i3.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(28, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null), (_l()(), i1.ɵted(29, 0, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(31, 1)], function (_ck, _v) { var _co = _v.component; var currVal_3 = !!_co.subText; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.imgUrl; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.text; _ck(_v, 12, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 18).disabled; var currVal_5 = true; var currVal_6 = ((i1.ɵnov(_v, 20).readOnlyService == null) ? null : i1.ɵnov(_v, 20).readOnlyService.readOnly); _ck(_v, 16, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵunv(_v, 21, 0, _ck(_v, 23, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("Yes")))); _ck(_v, 21, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 26).disabled; var currVal_9 = true; var currVal_10 = ((i1.ɵnov(_v, 28).readOnlyService == null) ? null : i1.ɵnov(_v, 28).readOnlyService.readOnly); _ck(_v, 24, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = i1.ɵunv(_v, 29, 0, _ck(_v, 31, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 29, 0, i1.ɵnov(_v, 30).transform("Cancel")))); _ck(_v, 29, 0, currVal_11); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 245760, null, 0, i8.ConfirmDialogComponent, [i3.MdDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("confirm-dialog", i8.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
