<md-dialog-content>
  <div class="folder-create-modal">
    <div>  
      <div class="folder-create-modal__text" >
        <b *ngIf="mdDialogData.isUpdate">{{ 'FOLDER_NAME' | translate}}</b>
        <b *ngIf="!mdDialogData.isUpdate">{{ 'CREATE_FOLDER' | translate}}</b>
      </div>
      <div class="folder-create-modal__text">
          <md-input-container class="input-group">
              <input mdInput
                     autocomplete="off" 
                     [(ngModel)]="name"
                     placeholder="{{ 'Name' | translate }}"
              >
            </md-input-container>
      </div>
    </div>

    <div class="folder-create-modal__footer">
      <button class="folder-create-modal__button" md-raised-button [disabled]="!canCreate()"
        (click)="confirm()">{{ (mdDialogData.isUpdate ? 'Validate' : 'Create') | translate }}</button>
      <button class="folder-create-modal__button" md-raised-button
        (click)="closeDialog()">{{ 'Cancel' | translate}}</button>
    </div>
  </div>
</md-dialog-content>
