<div class="esm-catalog">
  <map-view
    [markers]="markers"
  ></map-view>
  <section class="esm-catalog__choice type--multi-column type--offset-bottom-s type--offset-top-xs">
    <ul class="esm-catalog__choice-list type--align-start">
      <li class="esm-catalog__choice-item search-long--xxl-responsive">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input test-id="search" autocomplete="off" mdInput placeholder="{{ 'Search' | translate }}" [formControl]="search">
        </md-input-container>
      </li>
      <li *ngIf="showIndependentTranformFilter" class="toggle-independent-container">
        <toggle-independent
          (toggle)="toggleIndependent($event)"
          [text]="'See external transformations' | translate"
          [img]="'assets/png/cloud_grey.png'">
        </toggle-independent>
      </li>
    </ul>
    <ul class="esm-catalog__choice-list type--left-indent type--gap type--wrap type--fix-len">
      <ul class="esm-catalog__choice-list type--auto-no-right-offset type--gap-s type--wrap type--align-start">
        <li class="esm-catalog__choice-item select--len-fixed-l">
          <md-select test-id="transformation-type-selector" class="select-group"
                     placeholder="{{ 'Type of transformation' | translate }}" [(ngModel)]="type" (ngModelChange)="resetRecords()">
            <md-option>{{ 'Any' | translate }}</md-option>
            <md-option *ngFor="let type of TRANSFORM_TYPES" [value]="type.value">{{type.view | capitalize | translate}}
            </md-option>
          </md-select>
        </li>
        <li class="esm-catalog__choice-item select--len-fixed-l">
          <md-select class="select-group" test-id="tsm-selector" placeholder="{{ 'Transformation Manager' | translate }}"
            [(ngModel)]="tsmId" (ngModelChange)="resetRecords()">
            <md-option>{{ 'Any' | translate }}</md-option>
            <md-option *ngFor="let tsm of tsms" [value]="tsm.id">{{tsm.name}}
            </md-option>
          </md-select>
        </li>
      </ul>
      <li class="esm-catalog__choice-item select--len-fixed">
        <md-select test-id="sort-selector" class="select-group" placeholder="{{ 'Sort by' | translate }}"
          [(ngModel)]="sortValue" (ngModelChange)="resetRecords()">
          <md-option *ngFor="let sortValue of sortOptions" [value]="sortValue.field">{{ sortValue.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list [records]="records"
               [expandable]="true"
               [beforeExpandObserver]="updateRecordsOnListExpand()"
               [cloudIcon]="true"
               (onEndOfList)="onEndOfListTriggered()"
               (onExpand)="isListExtended = $event"
  >
  </custom-list>
</div>
