<md-dialog-content>
  <dialog-close-button
    (click)="mdDialogRef.close()">
  </dialog-close-button>
  <deposit-order-transaction-confirmation
    appNotificationAsWideDialogContent
    [ngClass]="{'deposit-order-transaction-confirm-dialog--hidden': confirmOrderDialogService.isConfirmDialogOpen}"
    [id]="mdDialogData.id"
    [step_id]="mdDialogData.step_id"
    [disabled]="mdDialogData.disabled"

    [showESMText]="mdDialogData.showESMText"
    (onConfirm)="openConfirmation($event)"
    (onClose)="mdDialogRef.close()"
    customNotificationStyles =
    "snack-bar-container.error {
      box-sizing: border-box;
      height: fit-content;
      padding: 15px 24px;
      text-align: center;
    }
    snack-bar-container.error .mat-simple-snackbar {
      justify-content: center;
    }
    .mat-simple-snackbar-message{
      white-space: unset !important;
      }"
  ></deposit-order-transaction-confirmation>
</md-dialog-content>
