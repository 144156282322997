import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {mergeMap} from 'rxjs/operators';
import {DepositResolver} from '../../deposit/resolvers/deposit.resolver';
import {Deposit} from '../../deposit/models/deposit.class';
import {PassportFamily} from '../../passport/models/passport.class';
import {PassportService} from '../../passport/services/passport.service';
import {DepositService} from '../../deposit/services/deposit.service';
import {ObservableInput} from 'rxjs/internal/types';

@Injectable()
export class NeedMatchWithDepositResolve extends DepositResolver implements Resolve<Deposit> {
  constructor(private passportService: PassportService,
              protected _service: DepositService) {
    super(_service);
    this.query.expand += ',isNoah';
  }
  resolve(route: ActivatedRouteSnapshot): Observable<Deposit> {
    return super.resolve(route).pipe(mergeMap<Deposit, ObservableInput<any>>(async (deposit: Deposit, index: number) => {
      if (deposit.isNoah) {
        if (deposit.passport.family === PassportFamily.FAMILY_LOCAL) {
          const p = await this.passportService.duplicateNoah(deposit.passport.id).toPromise();
          const passport =  await this.passportService.view(p.id, { expand: "category, gtins"}).toPromise();
          deposit.passport = passport;
          return deposit;          
        }
      }
      return deposit;
    }));
  }
}

