import { Component, Input } from '@angular/core';
import { EcosystemFolderService } from '../../../../../../entities/ecosystem/services/ecosystem-folder.service';
import { EcosystemFolder } from '../../../../models/ecosystem-folder.class';

 @Component({
   moduleId: module.id,
   selector: 'ecosystem-folder',
   templateUrl: 'ecosystem-folder.component.html'
 })
 export class EcosystemFolderComponent {
  
  @Input() folder: EcosystemFolder;

   constructor(public esFolderService: EcosystemFolderService) {
 
   }
   
   getFolderDisplayName(length) {
    return this.folder.name.length > length ? this.folder.name.substring(0,length - 1)+ '..' : this.folder.name
  }

   onFolderClick() {
    this.esFolderService.enterFolder(this.folder);
   }

   onCheckbox(e) {
    e.stopPropagation();
  }

  onCheckboxChange(checked) {
    if (this.esFolderService.allSelected) {
      this.esFolderService.allSelected = false;
    }
    return checked ?
    this.esFolderService.addFolderToSelection(this.folder.id) :
    this.esFolderService.removeFolderFromSelection(this.folder.id);
  }
 }
 