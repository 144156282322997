import { Component } from '@angular/core';
import { CategoryItem } from '../../models/category-item.class';
import { MdDialogRef } from '@angular/material';

@Component({
  template: `
    <category-tree-view
      [perfectScrollbar]
      (onSave)="mdDialogRef.close($event)"
    ></category-tree-view>
  `,
  styles: [
      `
      category-tree-view {
        height: 100%;
        display: block;
      }
    `,
  ]
})
export class SelectDialogComponent {
  constructor(public mdDialogRef: MdDialogRef<CategoryItem>) {
  }
}
