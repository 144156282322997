<article class="ecosystem-navigator">
  <!-- <header class="ecosystem-navigator__header">
    <h3 class="ecosystem-navigator__title">{{ 'Ecosystems' | translate }}</h3>
    <ul class="ecosystem-navigator__categories">
      <li class="ecosystem-navigator__category" *ngFor="let status of filterStatuses">
        <button class="ecosystem-navigator__button"
                (click)="status.checked = !status.checked"
                [ngClass]="{active: status.checked}">{{ ECOSYSTEM_STATUS_FILTER_VIEW[status.value] | translate }}
        </button>
      </li> 
    </ul>
  </header> -->
  <section class="ecosystem-navigator__toolbar">
    <ul class="ecosystem-navigator__control-list">
      <li class="ecosystem-navigator__control-item search">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input
            mdInput
            autocomplete="off"
            [(ngModel)]="ecosystemFolderService.filter.search"
            placeholder="{{ 'Search by anything' | translate }}"
            (ngModelChange)="searchSubject.next($event)"
          >
        </md-input-container>
      </li>
      <li class="ecosystem-navigator__control-item from" >
        <!-- <i class="icon icon--calendar-orange"></i> -->
        <md-input-container class="date input-group calendar--icon">
          <input
            mdInput
            autocomplete="off" 
            ng2-datetime-picker
            date-only="true"
            [(ngModel)]="ecosystemFolderService.filter.deliveryDateFrom"
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            placeholder="{{ 'Date of delivery FROM' | translate }}"
            (ngModelChange)="onFilterChange()"
            class="depositlist__info-disponable-title"
          >
        </md-input-container>
      </li>
      <li class="ecosystem-navigator__control-item to">
        <!-- <i class="icon icon--calendar-orange"></i> -->
        <md-input-container class="date input-group calendar--icon">
          <input
            mdInput
            autocomplete="off" 
            ng2-datetime-picker
            date-only="true"
            [(ngModel)]="ecosystemFolderService.filter.deliveryDateTo"
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            placeholder="{{ 'Date of delivery TO' | translate }}"
            (ngModelChange)="onFilterChange()"
            class="depositlist__info-disponable-title"
          >
        </md-input-container>
      </li>
      <li class="ecosystem-navigator__control-item reset">
        <button class="passportlist__clear-filters"
        [disabled]="!canClearFilters()"
        (click)="clearFilters()">
          {{"Clear filters" | translate}}
        </button>

      </li>
      
    </ul>
    <ul class="ecosystem-navigator__control-list">
      <li class="ecosystem-navigator__control-item select" *ngIf="canDisplayFolderFilter()">
        <!-- <i class="icon icon--gray-sort"></i>
        <md-select
          class="select-group"
          placeholder="{{ 'FOLDERS' | translate }}"
          [(ngModel)]="folderFiler"
        >
        <md-option
        *ngFor="let f of ecosystemFolderService.allFolders"
        [value]="f.id"
      >{{ f.name }}
      </md-option>
        </md-select> -->
        <deposit-location-filter
            field="name"
            placeholder="{{'FOLDER_FILTER_PLACEHOLDER' | translate}}"
            iconClass=""
            [inputContainerClass]="[]"
            [availableItems]="ecosystemFolderService.allFolders"
            [(ngModel)]="ecosystemFolderService.filter.folders"
            (ngModelChange)="onFilterChange()"
          >
          </deposit-location-filter>
      </li>
      <li  class="ecosystem-navigator__control-item status">
        <deposit-custom-multi-select
          [allOptions]="ecosystemFolderService.filter.statuses" 
          [isSelectEmpty]="isSameAsDefault"
          [placeholder]="'Status'"
          (changeOption)="onStatusSelectChange($event)"
        >
        </deposit-custom-multi-select>
      </li>
      <li class="ecosystem-navigator__control-item select">
        <md-select class="select-group"
                   placeholder="{{'Recipient' | translate}}"
                   [(ngModel)]="clientId"
                   (ngModelChange)="onFilterChange()">
          <md-option>{{ 'Any' | translate }}</md-option>
          <md-option *ngFor="let client of clients" [value]="client.id">
            {{client.name}}
          </md-option>
        </md-select>
      </li>
      <li class="ecosystem-navigator__control-item select">
        <md-select
          class="select-group"
          placeholder="{{ 'Sort by' | translate }}"
          [(ngModel)]="sortValue"
          (ngModelChange)="onFilterChange()"
        >
          <md-option
            *ngFor="let sortValue of sortOptions"
            [value]="sortValue.field"
          >{{ sortValue.view | translate }}
          </md-option>
        </md-select>
      </li>
      <li class="ecosystem-navigator__control-item reset"></li>
    </ul>
  </section>

  
  <section class="ecosystem-navigator__folderPathWrapper">
    <div *ngIf="!isRootFolder()" class="ecosystem-navigator__folderNameHeader">
      <div >
        <button (click)="goToParent()" class="ecosystem-navigator__backButton">
          <img src="assets/png/back-arrow.png" class="ecosystem-navigator__backButton-img">
        </button>
      </div>
      
      <div class="ecosystem-navigator__folderNameHeader-imgWrapper">
        <img src="assets/png/folder-mini.png" class="ecosystem-navigator__folderNameHeader-img">
      </div>
    
      <div class="ecosystem-navigator__folderNameHeader-name">
        <md-input-container class="input-group">
          <input autocomplete="off"
                (click)="saveFolder()"
                 mdInput
                 placeholder="{{ 'FOLDER_NAME' | translate }}"
                 readonly="true"
                 [(ngModel)]="ecosystemFolderService.newFolderName">
        </md-input-container>
        
        
      </div>
  </div>
    <div class="ecosystem-navigator__buttonWrapper">
      <button *ngIf="isRootFolder()"  class="ecosystem-navigator__buttonFilled" (click)="newFolder()" >
        +
        {{ 'CREATE_FOLDER' | translate }}
      </button>
      
      <button   class="ecosystem-navigator__buttonFilled" (click)="openEcosystemPicker()">
          + {{ 'Create ecosystem' | translate }}
      </button>
     
     
      <button 
              class="ecosystem-navigator__buttonFilled"
              (click)="deleteSelection()"
              [disabled]="!isDeletable()"
              [ngClass]="{'ecosystem-navigator__disabled': !isDeletable()}"
              >
              <i class="icon icon--white-deletion-bin x2"></i>
                {{ 'DELETE_EC_SELECTION' | translate }}
      </button>
      <button 
              [disabled]="!isMovable()"
              (click)="moveSelection()"
              class="ecosystem-navigator__buttonFilled"
              [ngClass]="{'ecosystem-navigator__disabled': !isMovable()}"
              >
              <i class="icon move-arrow--icon"></i>

        {{ 'MOVE_EC_SELECTION' | translate }}
      </button>
      <div class="ecosystem-navigator__checkbox">
        <input [(ngModel)]="ecosystemFolderService.allSelected" (ngModelChange)="onSelectAllChange()" type="checkbox" id="select_unselect_all">
        <label for="select_unselect_all">{{'SELECT_UNSELECT_ALL' | translate}}</label>
      </div>
          <!-- <md-checkbox class="ecosystem-navigator__checkbox">
        {{'SELECT_UNSELECT_ALL' | translate}}
      </md-checkbox> -->
       <div class="ecosystem-navigator__folder-mode-wrapper">
        <toggle-independent
          class="ecosystem-navigator__folder-mode"  
          *ngIf="isRootFolder()"
          (toggle)="toggleIndependent($event)"
          [text]="'VIEW_FOLDER'"
          [isChecked]="this.ecosystemFolderService.folderMode"
          [img]="'assets/png/folder-mini.png'">
        </toggle-independent>
       </div>
     
    </div>

    
  </section>
  <ecosystem-folder-navigator [folderMode]="folderMode"></ecosystem-folder-navigator>
  <!-- <section class="ecosystem-navigator__grid">
    <ul class="ecosystem-navigator__grid-list">
      <li class="ecosystem-navigator__grid-item" *ngFor="let item of ecosystems">
        <ecosystem-card [ecosystem]="item"></ecosystem-card>
      </li>
    </ul>
  </section> -->
</article>
