import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ECOSYSTEM_STEP, ECOSYSTEM_STEP_TYPE} from '../../../values/ecosystem-step-type.const';
import {ECOSYSTEM_STEP_STATUS} from '../../../values/ecosystem-step-status.const';
import {EcosystemStep} from '../../../models/ecosystem-step.class';
import { TRANSFORM_TYPE } from '../../../../transformation-manager/values/transform-types.const';
import * as PLUMB_STYLES from '../../../values/plumb-styles.const';
import {Ecosystem} from '../../../models/ecosystem.class';
import {DropEvent} from 'ng2-drag-drop/src/shared/drop-event.model';
import {ECOSYSTEM_STATUS} from '../../../values/ecosystem-status.const';
import {Observable} from 'rxjs/Observable';
import {LinkedDateDialogComponent} from '../../../dialogs/link-date/link-date.component';
import {ComponentType, MdDialog} from '@angular/material';
import {IOrder} from '../../../interfaces/order.interface';
import {DepositOrderService} from '../../../services/deposit-order.service';
import {TransformOrderService} from '../../../services/transform-order.service';
import 'rxjs/add/operator/mergeAll';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/concatAll';
import {EcosystemEditComponent} from '../ecosystem-edit/ecosystem-edit.component';
import {ECOSYSTEM_OFFER_STATUS} from '../../../values/ecosystem-offer-status.const';
import {Observer} from 'rxjs/Observer';
import {DatePipe} from '@angular/common';
import {TransformLocation} from '../../../../../entities/transformation/models/transform-location.class';
import {NavbarStateService} from '../../../../../shared/services/navbar.service';
import {LogNotificationService} from "../../../../../shared/services/log-notification.service";
import {
  DepositOrderTransactionConfirmDialogComponent
} from '../../../../deposit-owner/dialogs/deposit-order-transaction-confirm-dialog/deposit-order-transaction-confirm-dialog.component';
import {DIALOG_THEME} from '../../../../../shared/helpers/dialog-themes.const';
import {
  OfferTransactionConfirmDialogComponent
} from '../../../../../entities/ecosystem-offer/dialogs/offer-transaction-confirm-dialog/offer-transaction-confirm-dialog.component';
import {
  TransformationOrderTransactionConfirmDialogComponent
// tslint:disable-next-line:max-line-length
} from '../../../../transformation-manager/dialogs/transformation-order-transaction-confirm-dialog/transformation-order-transaction-confirm-dialog.component';
import {EcosystemCompilerSharedActionsService} from '../../../services/ecosystem-compiler-shared-actions.service';
import {EcosystemPrefillService, PREFILL_OFFER_ID} from '../../../services/ecosystem-prefill.service';
import {count, mergeMap} from 'rxjs/operators';
import {TransformOrder} from '../../../models/transform-order.class';
import {EcosystemOfferService} from '../../../../../entities/ecosystem-offer/ecosystem-offer.service';
import {TRANSFORM_ORDER_STATUS} from '../../../../transformation-manager/values/transform-order-statuses.const';
import {EcosystemProblemUtilsService} from '../../../../../entities/ecosystem/services/ecosystem-problem-utils.service';
import { DepositOrder } from '../../../models/deposit-order.class';
import {EcosystemOffer} from '../../../../../entities/ecosystem-offer/ecosystem-offer.model';
import { EcosystemPrefillCommandService } from '../../../../../dashboards/ecosystem-manager/services/ecosystem-prefill-command.service';
import {Need} from '../../../../../entities/need/models/need.class';
import {
  DepositDetailsDialogComponent
} from '../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';


declare let jsPlumb: any;

@Component({
  moduleId: module.id,
  selector: 'ecosystem-tree',
  templateUrl: 'ecosystem-tree.component.html',
})
export class EcosystemTreeComponent implements AfterViewInit, OnInit {
  @Input() ecosystem: Ecosystem;
  public ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
  public ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP_TYPE;
  public ECOSYSTEM_STEP_STATUS = ECOSYSTEM_STEP_STATUS;
  public ECOSYSTEM_OFFER_STATUS = ECOSYSTEM_OFFER_STATUS;
  public plumb: any; // jsPlumbInstance;
  public steps: EcosystemStep[];

  @Input() openChat = false;
  @Output() openChatChange = new EventEmitter();

  @Input() activeStep: EcosystemStep = null;
  @Output() activeStepChange = new EventEmitter();

  constructor(
    private ecosytemPrefillCommandService: EcosystemPrefillCommandService,
    private ecosystemCompilerSharedActionsService: EcosystemCompilerSharedActionsService,
    private prefillService: EcosystemPrefillService,
    private depositOrderService: DepositOrderService,
    private transformOrderService: TransformOrderService,
    private ecosystemOfferService: EcosystemOfferService,
    private ecosystemEditComponent: EcosystemEditComponent,
    private datePipe: DatePipe,
    private ecosystemProblemUtilsService: EcosystemProblemUtilsService,
    public dialog: MdDialog,
    public notification: LogNotificationService,
    public navBarState: NavbarStateService) {
  }

  reload(observable: Observable<any>) {
    this.ecosystemEditComponent.reload(observable);
  }

  trySaveActiveStep(): Observable<any> {
    return this.ecosystemEditComponent.trySaveActiveStep();
  }


  setActiveStep(step: EcosystemStep, forceAdditionalSave = true) {
    this.trySaveActiveStep().subscribe(() => {
      if (this.activeStep !== step && !forceAdditionalSave) {
        this.ecosystemEditComponent.tryProhibitActiveStepSaving();
      }
      this.activeStep = step;
      this.activeStepChange.emit(step);
    });
  }

  ngOnInit() {
    this.steps = this.ecosystem.steps;
    this.buildDeletedSteps();
    if (this.ecosystem.need_id === null) {

      this.buildDeletedNeedStep();
    }
  }

  ngAfterViewInit() {
    jsPlumb.ready(() =>
      this.initPlumb()
    );
  }

  getStepById(id: number): EcosystemStep {
    return this.steps.find((step: EcosystemStep) =>
      step.id === id
    );
  }

  getStepBySelectorId(selectorId: string): EcosystemStep {
    return this.getStepById(EcosystemStep.fromSelectorId(selectorId));
  }

  initPlumb() {
    this.plumb = jsPlumb.getInstance({
      Container: 'plumbRoot',
      Endpoint: PLUMB_STYLES.DEFAULT_ENDPOINT,
      Connector: PLUMB_STYLES.DEFAULT_CONNECTOR,
      Anchors: PLUMB_STYLES.DEFAULT_ANCHORS,
      PaintStyle: PLUMB_STYLES.DEFAULT_CONNECTION_STYLE,
      EndpointStyle: PLUMB_STYLES.DEFAULT_ENDPOINT_STYLE,
    });

    this.plumb.bind('beforeDrop', (info: any) => {
      const sourceStep = this.getStepBySelectorId(info.sourceId);
      const targetStep = this.getStepBySelectorId(info.targetId);

      const isSourceTransportStep = sourceStep.type === ECOSYSTEM_STEP_TYPE.TRANSFORM && 
        (sourceStep.transformation_type === TRANSFORM_TYPE.TRANSPORT || sourceStep.transform && sourceStep.transform.transformation_type === TRANSFORM_TYPE.TRANSPORT);
      const isTargetTransportStep = targetStep.type === ECOSYSTEM_STEP_TYPE.TRANSFORM && 
        (targetStep.transformation_type === TRANSFORM_TYPE.TRANSPORT || targetStep.transform && targetStep.transform.transformation_type === TRANSFORM_TYPE.TRANSPORT);
      
      if (isSourceTransportStep && isTargetTransportStep) {
        this.notification.error("ECOSYSTEM_ERROR_TRANSPORT_TO_TRANSPORT", null, true);
        return false;
      }
      
      return true;
    });

    this.plumb.bind('connectionDetached', (info: any) => {
      const sourceStep = this.getStepBySelectorId(info.sourceId);
      const targetStep = this.getStepBySelectorId(info.targetId);

      sourceStep.parent_id = 0;
      sourceStep.overlay = null;
      if (targetStep.type === ECOSYSTEM_STEP_TYPE.NEED) {
        targetStep.overlay = null;
        this.reload(Observable.zip(
          sourceStep.save(),
          targetStep.save()
        ).mergeMap(() => this.tryPrefillOnDetach(sourceStep)));
      } else {
        this.reload(sourceStep.save().pipe(mergeMap(() => this.tryPrefillOnDetach(sourceStep).pipe(count(() => true)))));
      }
    });
    this.plumb.bind('connection', (info: any) => {
      let removeOldSourceObservable = Observable.of({});
      if (!!info.connection && info.connection.suspendedElementId) {
        const suspendedStep = this.getStepBySelectorId(info.connection.suspendedElementId);
        if (!!suspendedStep && info.connection.suspendedElementType === "source" && info.sourceId !== info.connection.suspendedElementId) {
          suspendedStep.parent_id = 0;
          removeOldSourceObservable = suspendedStep.save().pipe(mergeMap(() => this.tryPrefillOnDetach(suspendedStep).pipe(count(() => true))));
        }
      }
      
      const sourceStep = this.getStepBySelectorId(info.sourceId);
      const targetStep = this.getStepBySelectorId(info.targetId);
      info.sourceStep = sourceStep;
      info.targetStep = targetStep;

      info.connection.removeAllOverlays();
      this.addStepOverlays(info);
      sourceStep.parent_id = targetStep.id;
      let prefillObserver = Observable.of({});
      try {
        prefillObserver = this.prefillOnConnect(targetStep, sourceStep);
      } catch (e) {
        console.warn(e);
      }
      this.reload(removeOldSourceObservable.pipe(mergeMap(() => sourceStep.save().pipe(mergeMap(() => prefillObserver.pipe(count(() => true)))))));
    });

    this.plumb.batch(() => {
      this.steps.forEach((step) => {
        this.addStepToTree(step);
      });
      this.steps.forEach((step) => {
        if (step.parent_id > 0) {
          this.addStepCon(step);
        }
      });
    });
  }

  private tryPrefillOnDetach(sourceStep) {
    let observer = Observable.of({});
    try {
      observer = this.prefillOnDetach(sourceStep);
    } catch (e) {
      console.warn(e);
    }
    return observer;
  }

  private prefillOnDetach(targetStep) {
    const order = this.prefillService.getStepByTargetId(targetStep);
    if (order) {
      this.prefillService.setOrderInAsZero(order);
      const change = this.prefillService.prefillUpdateIfOrderChanged(order, true);
      delete change[this.prefillService.isOffer(order) ? PREFILL_OFFER_ID : order.id];
      this.ecosystemCompilerSharedActionsService.prefillSetDirty(change, [
        ...(this.prefillService.isOffer(order) ? [order] : [])
      ]);
    }
    return this.ecosystemCompilerSharedActionsService.beforeCompilerReload();
  }

  private prefillOnConnect(targetStep, sourceStep) {
    const order: TransformOrder | DepositOrder | EcosystemOffer  = this.prefillService.getFirstOrderByStep(targetStep);
    const childOrder = this.prefillService.getFirstOrderByStep(sourceStep);
    return this.ecosytemPrefillCommandService.addOrderToPrefillAndSaveOnReload(order, childOrder);
  }

  addStepOverlays(info: any) {
    const sourceStep: EcosystemStep = info.sourceStep;
    const targetStep: EcosystemStep = info.targetStep;
    {
      const step = sourceStep;
      step.overlay = info.connection.addOverlay([

        'Label', {
          events: {
            click: () => {
              if (step.canEditOrder) {
                this
                  .openLinkedDate(step.linked_date, step)
                  .subscribe((linked_date: string) => {
                      step.linked_date = linked_date;
                      step.updateOverlay();
                      this.reload(step.save()); 
                  })
                  ;
              }
            }
          },
          location: 0,
          label: this.datePipe.transform(step.linked_date, 'dd-MM-y'),
          cssClass: step.overlayClass
        },
      ]);
    }

    {
      const step = targetStep;
      if (step.type === ECOSYSTEM_STEP_TYPE.NEED) {
        step.overlay = info.connection.addOverlay(['Label', {
          events: {
            click: () => {
              if(step.canEditOrder){
                this
                .openLinkedDate(step.linked_date, step)
                .subscribe((linked_date: string) => {
                    step.linked_date = linked_date;
                    step.updateOverlay();
                    this.reload(step.save());
                })
                ;
              }
            }
          },
          location: -Number.MIN_VALUE,
          label: this.datePipe.transform(step.linked_date, 'dd-MM-y'),
          cssClass: step.overlayClass,
        }]);
      }
    }

    info.connection.addOverlay(['Arrow', PLUMB_STYLES.OVERLAY_ARROW]);
  }

  addStepCon(step: EcosystemStep) {
    this.plumb.connect({
      source: step.endpoints.find(endpoint => endpoint.isSource),
      target: this.getStepById(step.parent_id).endpoints.find(endpoint => endpoint.isTarget),
      deleteEndpointsOnDetach: false,
    });
  }

  addStepToTree(step: EcosystemStep) {
    this.plumb.draggable(step.selectorId, {
      stop: (event: any, ui: any) => {
        if (event && event.el && event.el.offsetLeft && event.el.offsetTop) {
          this.updateStepPos(step, event.el.offsetLeft, event.el.offsetTop);
        }
      }, containment: true
    });
    step.endpoints = step.endpointsParams.map((endpointParams: any) =>
      this.plumb.addEndpoint(step.selectorId, endpointParams)
    );
    step.updateEndpoints(this.ecosystem.canEditSteps);
  }

  deleteStep(step: EcosystemStep) {
    this.trySaveActiveStep().subscribe(() => {
      if (this.activeStep === step) {
        this.setActiveStep(null, false);
      }
      step.destroy().subscribe(() => {
        this.ecosystemEditComponent.tryLoadActiveStep();
        this.ecosystem.steps.splice(this.steps.indexOf(step), 1);
        this.plumb.removeAllEndpoints(step.selectorId);
        this.reload(this.tryPrefillOnDetach(step).pipe(count(() => true)));
      });
    });
  }

  stepDrop(e: DropEvent) {

    if (e.dragData.isRelevant) {
      if (e.dragData.plumb_type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
        if (!!e.dragData.site_id) {
          const site = new TransformLocation({
            id: Number(e.dragData.site_id),
          });

          site.transformation_name = e.dragData.transformation_name;
          site.transformation_id = Number(e.dragData.transformation_id);
          e.dragData = site;
        } else {
          // For case when there is no site in transformation
          e.dragData.id = Number(e.dragData.transformation_id);
          e.dragData.name = e.dragData.transformation_name;
        }
      } else if (e.dragData.plumb_type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
        e.dragData.name = e.dragData.deposit_name;
      }
    } else {
      if (e.dragData.plumb_type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
        const site = new TransformLocation(e.dragData.site);
        site.transformation_name = e.dragData.name;
        site.transformation_id = e.dragData.id;
        e.dragData = site;
      }
    }
    const step = new EcosystemStep({
      source: e.dragData,
      ecosystem_id: this.ecosystem.id,
      x_pos: e.nativeEvent.layerX,
      y_pos: e.nativeEvent.layerY,
    });

    step.ecosystem = this.ecosystem;
    step.save().subscribe(res => {

      step.id = res.id;
      step.status = res.status;
      step.deposit = res.deposit;
      step.transform = res.transform;
      this.ecosystem.steps.push(step);

      // this.setActiveStep(step);
      setTimeout(() => { // needs to be in dom or will error
        this.addStepToTree(step);
        this.reload(Observable.of({}));
      });
    });
  }

  rootStationaryClick($event: MouseEvent) {
    if ($event.currentTarget === $event.target) {
      this.setActiveStep(null);
    }
  }

  stepStationaryClick($event: MouseEvent, step: EcosystemStep) {
    this.setActiveStep(step);
  }

  checkForStepAndEcosystemEditability(currentStep: EcosystemStep){
    if(this.ecosystem.status === ECOSYSTEM_STATUS.DRAFT){
      return true;
    } else if(this.ecosystem.status === ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION){
      return true;
    }  else if(this.ecosystem.status === ECOSYSTEM_STATUS.CREATED){
      return true;
    }  else if(currentStep.status === ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION){
      return true;
    } else if(currentStep.status === ECOSYSTEM_STEP_STATUS.STAND_BY){
      return true;
    } else if(currentStep.status === ECOSYSTEM_STEP_STATUS.BOOKED && 
      currentStep.type === ECOSYSTEM_STEP_TYPE.STAKEHOLDER || currentStep.type === ECOSYSTEM_STEP_TYPE.NEED){
        return true;
      } else {
        return false;
      }
  }

  openLinkedDate(linked_date: string, currentStep?: EcosystemStep): Observable<null | string> {
    if(this.checkForStepAndEcosystemEditability(currentStep)){
      const dialogRef = this.dialog.open(LinkedDateDialogComponent, {
        width: '232px',
        height: '310px',
        data: {
          input_date: linked_date
        }
      });
      return dialogRef.afterClosed();
    }
  }

  validateStep(step: EcosystemStep) {
    if (step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
      this.reload(new Observable((observer: Observer<any>) => {
        this.depositOrderService.get({
          step_id: step.id,
        }).subscribe((order: IOrder[]) => {
          if (order[0]) {
            order[0].validate().subscribe(observer);
          }
        });
      }));
    } else if (step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
      this.reload(new Observable((observer: Observer<any>) => {
        this
      .transformOrderService
      .get({
        step_id: step.id,
      })
      .subscribe((orders: IOrder[]) => {
        orders.map((order) => order.validate().subscribe(observer));

      });


      }));
    }
  }

  askForValidate(step: EcosystemStep) {
    if (step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
      this.reload(new Observable((observer: Observer<any>) => {
        this.depositOrderService.get({ step_id: step.id })
          .subscribe((order: IOrder[]) => {
            this.depositOrderService.publish(order[0].id, {}).subscribe(observer);
          })
          ;
      }));
    } else if (step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
      this.reload(new Observable((observer: Observer<any>) => {
        this.transformOrderService.get({ step_id: step.id }).subscribe((orders: IOrder[]) => {
            Observable.concat(
              ...orders.map((order: IOrder) => this.transformOrderService.publish(order.id, {}))
            ).subscribe(observer);
          });
      }));
    } else if (step.type === ECOSYSTEM_STEP_TYPE.NEED) {
      this.reload(this.ecosystem.ecosystem_offer.publish());
    }
  }

  askForValidationAgain(step: EcosystemStep) {
    if (step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
      this.reload(
          this.depositOrderService.get({ step_id: step.id }).mergeMap(
          (order: IOrder[]) => {
            return this.depositOrderService.resend(order[0].id, order);
          })
        );
    } else if (step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
      this.reload(
        this.transformOrderService.get({ step_id: step.id }).mergeMap((orders: IOrder[]) => {
          return Observable.concat(
            ...orders.map((order: IOrder) => this.transformOrderService.resend(order.id, {}))
          );
        })
      );
    } else if (step.type === ECOSYSTEM_STEP_TYPE.NEED) {
      this.reload(this.ecosystemOfferService.resend(this.ecosystem.ecosystem_offer.id, this.ecosystem.ecosystem_offer));
    }
  }

  isOrderInAskForConfirmation(step: EcosystemStep) {
    if ([ECOSYSTEM_STEP_TYPE.DEPOSIT, ECOSYSTEM_STEP_TYPE.TRANSFORM].includes(step.type)) {
      return step.order_aggregate === TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION;
    }
  }

  confirmStep(step: EcosystemStep) {
    this.reload(new Observable((observer: Observer<any>) => {

      this.seeConfirmation(step, false).afterClosed()
        .subscribe((result: any) => {
          // TODO refactor -> replace string by enum ?
          if (result === 'confirmed') {
            if (step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
              this.depositOrderService.get({
                step_id: step.id,
              }).subscribe((orders: IOrder[]) => {
                const order = orders[0];
                if (order) {
                  order.confirm().subscribe(observer);
                }
              });
            } else if (step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
              this.transformOrderService.get({
                step_id: step.id,
              }).subscribe((orders: IOrder[]) => {

                // Observable
                // .merge(orders.map((order: IOrder) => this.transformOrderService.publish(order.id, {}).subscribe(observer)));


                const saveStream = Observable.merge(orders.map((order: IOrder) => order.confirm().subscribe(observer)));
                Observable.combineLatest.apply(this, saveStream); // .subscribe(observer);
              });
            } else if (step.type === ECOSYSTEM_STEP_TYPE.STAKEHOLDER) {
              step.confirm().subscribe(observer);
            } else if (step.type === ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT) {
              step.confirm().subscribe(observer);
            } else if (step.type === ECOSYSTEM_STEP_TYPE.NEED) {
              this.ecosystem.ecosystem_offer.confirm().subscribe(observer);
            }

          } else if (result === 'chat') {

            this.setActiveStep(step);
            this.openChat = true;
            this.openChatChange.emit(true);

            observer.next(null);
            observer.complete();

          } else {
            observer.next(null);
            observer.complete();
          }
        })
        ;

    }));
  }

  seeConfirmation(step: EcosystemStep, disabled = true) {
    let theme: object = DIALOG_THEME.TRANSACTION_ORDER_CONFIRMATION;
    let dialog: ComponentType<any> = OfferTransactionConfirmDialogComponent;
    let data: object = {type: step.type};
    switch (step.type) {
      case ECOSYSTEM_STEP_TYPE.DEPOSIT:
        data = {step_id: step.id, disabled: disabled, showESMText: true};
        dialog = DepositOrderTransactionConfirmDialogComponent;
        break;
      case ECOSYSTEM_STEP_TYPE.TRANSFORM:
        data = {step_id: step.id, disabled: disabled, showESMText: true};
        dialog = TransformationOrderTransactionConfirmDialogComponent;
        break;
      case ECOSYSTEM_STEP_TYPE.NEED:
        data = {id: this.ecosystem.ecosystem_offer.id, disabled: disabled, showESMText: true};
        dialog = OfferTransactionConfirmDialogComponent;
        break;
      default:
        theme = {};
        if (disabled) {
          dialog = null;
        }
    }
    return dialog && this.dialog.open(dialog, {...theme, data});
  }

  updateStepPos(step: EcosystemStep, x_pos: number, y_pos: number) {
    const updatedFields = {
      x_pos: x_pos,
      y_pos: y_pos
    };
    step.partialUpdate(updatedFields).subscribe();
  }

  viewDetails(step: EcosystemStep) {
    this.ecosystemEditComponent.viewDetails(step.type, step.entity_id);
  }

  viewNeedDeposit(need: Need) {
      this.dialog.open(DepositDetailsDialogComponent, {
        data: {
          id: need.from_deposit,
          readOnly: true
        }
      });
  }

  acceptOffer(step: EcosystemStep) {
    // step.validate().subscribe(() => this.ecosystem.reload());
    this.reload(this.ecosystem.ecosystem_offer.validate());
  }

  seeOrderProblem(step: EcosystemStep) {
     step.load({expand: 'problem', ecosystem_id: step.ecosystem_id}).subscribe(
      (stepWithProblem: EcosystemStep) => {
        if (stepWithProblem.status === ECOSYSTEM_STEP_STATUS.PROBLEM_REPORTED) {
          this.ecosystemProblemUtilsService.openOrderProblemDialog(stepWithProblem.problem.description || ''
            , (stepWithProblem.type === ECOSYSTEM_STEP_TYPE.NEED ? 'Offer problem' : 'Order problem'));
        } else {
          this.reload(Observable.of({}));
        }
      });
  }

  buildDeletedNeedStep() {
    const need = this.steps.find(step => step.status === ECOSYSTEM_STEP.STATUS.REJECTED && step.parent_id === null);
      if (need) {
        need.title = 'Need deleted';
      }
  }
  buildDeletedSteps() {
    this.steps.map(s => {

      if (this.isDeletedStep(s)) {
        let deleted = '';
        deleted = 'Deposit deleted';
        if (s.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
          deleted = 'Transformation deleted';

        }
        if (s.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
          deleted = 'Deposit deleted';
        }
        if (s.type === ECOSYSTEM_STEP_TYPE.NEED) {
          deleted = 'Need deleted';
        }
        s.title = deleted;
      }
    });
  }

  isDeletedStep(step: EcosystemStep) {

    if (step.type === ECOSYSTEM_STEP_TYPE.NEED) {
      return this.ecosystem.need_id == null && step;
    }
    if (step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
      return step.deposit == null;
    }
    if (step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
      return step.transform == null;
    }
    return false;
  }

  getStepTitle(step: EcosystemStep) {
    if (this.navBarState.multilanguage && step.english_title) {
      return step.english_title;
    }
    return step.title;
  }
}
