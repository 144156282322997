<div class="control-group">
  <ul class="control-group__list control-group__list--padding justify-start"
      *ngFor="let item of listCtrl?.controls; let i=index;"
      [formGroup]="listCtrl?.controls[i]">

    <li class="control-group__item flex">
      <div [hidden]="this.isDisabled" class="control-group__item-button  control-group__item--margin"
           (click)="removeColor(i)">
        <md-icon>remove</md-icon>
      </div>

    <div class="passport-edit__info--color-input-block">
      <md-select
        formControlName="color_id"
        placeholder="{{ 'Color' | translate }}"
      >
        <md-option
          *ngFor="let type of colorTypes"
          [value]="type.key"
        >{{type.value | translate}}
        </md-option>
      </md-select>
    </div>
    <div class="passport-edit__info--color-input-block">
      <md-select
        formControlName="opacity"
        placeholder="{{ 'Transparence' | translate }}"
      >
        <md-option
          *ngFor="let type of transparenceTypes"
          [value]="type.key"
        >{{type.value | translate}}
        </md-option>
      </md-select>
    </div>
    </li>

  </ul>
  <div class="control-group__item flex align-center control-group__item-add"
       (click)="addNew()">
    <div [hidden]="this.isDisabled" class="control-group__item-button">
      <md-icon>add</md-icon>
    </div>
    <div [hidden]="this.isDisabled" class="control-group__item--subtext">
      {{'add a color' | translate}}
    </div>
  </div>

</div>
