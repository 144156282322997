import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { ReportService } from '../services/report.service';
var Reports = /** @class */ (function (_super) {
    tslib_1.__extends(Reports, _super);
    function Reports(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = ReportService;
        return _this;
        // this.id = null;
        // this.circularity = false ;
        // this.carbon_foot_print = false ;
        // this.financial_residual_value = false ;
        // this.material_health = false ;
    }
    Reports.prototype.getSnapshot = function () {
        if (!this.snapshotData) {
            try {
                var data = JSON.parse(this.snapshot);
                this.snapshotData = Object.values(data);
            }
            catch (e) {
                console.warn("Report snapshot is not defined for report nr " + this.report_nr);
            }
        }
        return this.snapshotData;
    };
    Reports.prototype.fields = function () {
        return ['id', 'building_category', 'deconstruction', 'rehabilitation', 'renewal_building_components', 'report_files',
            'carbon_foot_print', 'embodied_carbon', 'ic_construction', 'ic_component', 'product_and_construction', 'product_stage', 'circularity', 'financial_residual_value', 'material_health', 'report_result', 'created_at',
            'zipfile_link', 'report_nr', 'timezone_offset', 'sites', 'tags', 'created_by_file', 'result', 'snapshot'];
    };
    return Reports;
}(ActiveRecord));
export { Reports };
