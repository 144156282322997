import {COLUMNS_MAPPING} from '../../dashboards/agent/partials/import-components/deposits/values/deposit-import-synonym';
import {COLUMNS_MAPPING as SITE_MAPPING} from '../../dashboards/deposit-owner/partials/do-import/values/deposit-location-columns.const';
import {TABLE_TYPES} from './import-tables.const';
import {EDepositReuseSource} from '../../entities/deposit/values/deposit-reuse-source.const';

export const LEVELS_BY_TABLE = {
  [TABLE_TYPES.STATE_OF_USE] : 5,
  [TABLE_TYPES.LEVEL_OF_DEMONTABILITY] : 5,
  [TABLE_TYPES.DEPOSIT_ORIGIN] : 7,
  [TABLE_TYPES.POTENTIAL_BECOMING] : 4,
  [TABLE_TYPES.COLUMNS_MAPPING] : Object.keys(COLUMNS_MAPPING).length,
  [TABLE_TYPES.DEPOSIT_LOCATION_MAPPING]: Object.keys(SITE_MAPPING).length + 1,
};

export const ORDER_BY_TABLE: {[key: number]: (level: number[]) => number[]} = {
  [TABLE_TYPES.STATE_OF_USE] : (level) => level,
  [TABLE_TYPES.LEVEL_OF_DEMONTABILITY] : (level) => level,
  [TABLE_TYPES.DEPOSIT_ORIGIN] : (level) => levelToExistingOrder(level, DEPOSIT_ORIGIN_ORDER),
  [TABLE_TYPES.POTENTIAL_BECOMING] : (level) => levelToExistingOrder(level, DEPOSIT_POTENTIAL_ORIGIN_ORDER),
  [TABLE_TYPES.COLUMNS_MAPPING] : (level) => level,
  [TABLE_TYPES.DEPOSIT_LOCATION_MAPPING]: (level) => level,
};

export function levelToExistingOrder(level: number[], levelToOrder: {[level: number]: number}) {
  return level
    .filter(item => !!levelToOrder[item] || levelToOrder[item] === 0)
    .sort((previous, next) => levelToOrder[previous] - levelToOrder[next]);
}
export const enum EDepositOriginImport {
   NO_SOURCE = 0,
   DESTOCKED = 1,
   RECONDITIONED = 2,
   REUSED = 3,
   UNKNOWN = 4,
   SURPLUS = 5,
   REMOVAL = 6
}

export const DEPOSIT_ORIGIN_ORDER = {
  [EDepositOriginImport.NO_SOURCE]: 0,
  [EDepositOriginImport.DESTOCKED]: 1,
  [EDepositOriginImport.SURPLUS]: 2,
  [EDepositOriginImport.RECONDITIONED]: 3,
  [EDepositOriginImport.REMOVAL]: 4,
  [EDepositOriginImport.UNKNOWN]: 5
};

export const enum EDepositPotentialOriginImport {
  NO_SOURCE = 0,
  DESTOCKED = 1,
  RECONDITIONED = 2,
  REUSED = 3
}

export const DEPOSIT_POTENTIAL_ORIGIN_ORDER = {
  [EDepositOriginImport.NO_SOURCE]: 0,
  [EDepositOriginImport.REUSED]: 3,
};

