import { Injectable } from '@angular/core';
import {DepositLocationImportFile} from '../models/deposit-location-import-file.class';
import {CrudService} from '../../../shared/services/crud.service';

@Injectable({
  providedIn: 'root'
})
export class DepositLocationImportFileService  extends CrudService {
  protected namespace = 'deposit-location-import-files';
  protected namespaceSingular = 'deposit-location-import-file';
  protected ModelClass = DepositLocationImportFile;
}
