<ng-container *ngIf="order?.loaded && tsmOrders[0]">
  <section class="form-group background-primary-color">
    <header class="form-group__header">
      <label class="form-group__header-title">{{order.transformation.transformation_type_data.view | translate}} {{ 'Order' | translate }}</label>
      <div class="form-group__header-status">
          <span>
            {{ getTransformationOrderStatusText() | translate}}
          </span>  
      </div>
    </header>
  </section>
  <section class="form-column">
    <div class="form-row form-row--manager">    
      <span class="form-row-title">{{ 'Ecosystem manager' | translate }}:</span>
      <span class="form-row-name primary-color">{{ order.creator }}</span>
    </div>
  </section>
  <div class="sub-header">
    <span class="sub-header-title">
      {{ 'Transformation' | translate }}
    </span>
  </div>
  <section class="form-column">
    <div class="form-row">
      <div class="flex-column">
        <span class="form-column-title primary-color">{{ 'Transformation name' | translate }}</span>
        <md-input-container class="form-column-field">
          <textarea
            [rows]="setRowHeight(order.transformation.name, 45)" 
            mdInput
            [value]="order.transformation.name"
            disabled
          ></textarea>
        </md-input-container>
      </div>
      <div class="flex-column flex-column-english-version">
        <span class="form-column-title primary-color">{{ 'English version of the name' | translate }}</span>
        <md-input-container class="form-column-field">
          <textarea
            [rows]="setRowHeight(order.transformation.english_name, 45)" 
            mdInput
            [value]="order.transformation.english_name"
            disabled
            ></textarea>
        </md-input-container>
      </div>
      <img tabindex="1" class="form-column-eye" src="../../../../assets/svg/transformation-order-eye--icon.svg" >
      <a class="form-column-link" (click)="seeTransformation(order.transformation_id)">{{ 'see the transformation' | translate }}</a>    
    </div>
  </section>
  <div class="sub-header sub-header-input">
    <span class="sub-header-title">
      {{ getInputOrDepositsToTransportTitle() | translate }}
    </span>
  </div>
  <section class="input-flex-column" *ngFor="let tsmOrder of tsmOrders; let index = index">
    <div class="previous-step">
      <span class="previous-step-text">
        {{getInputName(index)}}
      </span>
    </div>
    <div class="flex-row">
      <div class="flex-column flex-column-conversion"
      [ngClass]="{'flex-column flex-column-conversion--spanish-and-portuguese': checkSpanishOrPortugueseLanguageAndUnitIsPieces(getInputUnit(index)) }">
        <span class="form-column-title primary-color">
          {{ 'Quantity' | translate }}
        </span>
        <conversion-field
        [setToggleColorActive]="true"
        [emptyQuantityHolder]="''"
        [identifier]="'tsm_quantity' + '_' + tsmOrder.id"
        [volumeUnit]="getInputUnit(index)"
        [quantity]="tsmOrder.orderInQuantityView"
        [conversionFactor]="getInputConversion(index)"
        [isDisabled]="true">
        </conversion-field>
      </div>
      <div class="flex-column flex-column-date">
        <span class="form-column-title primary-color">{{ getReceptionOrPickupDateText() | translate }}</span>
        <md-input-container class="form-column-field--date">
          <input
              mdInput
              date-only="true"
              [value]="datePipe.transform(tsmOrder.child_step.linked_date, 'dd-MM-yyyy')"
              disabled
            />
        </md-input-container>
      </div>
      <div class="flex-column flex-column-description">
        <span class="form-column-title primary-color">{{ getPickupAddressOrDescriptionTitle() | translate }}</span>
        <md-input-container class="flex-column--tsm-description">
            <textarea
              [rows]="setRowHeight(getPickupAddressOrDescriptionText(index), 72)" 
              [value]="getPickupAddressOrDescriptionText(index)"
              disabled
              mdInput
              autocomplete="off"
            ></textarea>
        </md-input-container>
      </div>
    </div>
    <div class="flex-column" *ngIf="isTransformationTypeOfTransport()">
      <span class="form-column-title primary-color">{{ 'Description' | translate }}</span>
      <md-input-container class="flex-column--tsm-description">
          <textarea
            rows="1"
            #orderDepositDescription
            [(ngModel)]="tsmOrder.in_order_passport.description"
            disabled
            mdInput
            autocomplete="off"
          ></textarea>
      </md-input-container>
    </div>
  </section>
  <div class="sub-header sub-header-input" *ngIf="!isTypeOfTransportOrStorage()">
    <span class="sub-header-title">
      {{ 'Output' | translate }}
    </span>
  </div>
  <div class="input-flex-column" *ngIf="!isTypeOfTransportOrStorage()">
    <div class="previous-step">
      <span class="previous-step-text">
      {{getOutputName()}}
      </span>
    </div>
    <div class="flex-row">
      <div class="flex-column">
        <span class="form-column-title primary-color">
          {{ 'Quantity' | translate }}
        </span>
        <conversion-field
        [setToggleColorActive]="true"
        [emptyQuantityHolder]="''"
        identifier="tsm_out_quantity"
        [volumeUnit]="getOutputUnit()"
        [quantity]="order.orderOutQuantityView"
        [conversionFactor]="getOutputConversion()"
        [isDisabled]="true">
        </conversion-field>
       </div> 
      <div class="flex-column flex-column-date">
        <span class="form-column-title primary-color">{{ 'End of' | translate }} <br> {{ 'transformation date' | translate }} </span>
        <md-input-container class="form-column-field--date form-column-field--end-date">
          <input
              mdInput
              date-only="true"
              [value]="datePipe.transform(order.out_order_passport.linked_datetime, 'dd-MM-yyyy')"
              disabled
            />
        </md-input-container>
      </div>
      <div class="flex-column flex-column-description">
        <span class="form-column-title primary-color">{{ 'Description' | translate }}</span>
        <md-input-container class="flex-column--tsm-description">
            <textarea
              [value]="order.out_order_passport.description"
              [rows]="setRowHeight(order.out_order_passport.description, 72)" 
              disabled
              mdInput
              autocomplete="off"
            ></textarea>
        </md-input-container>
      </div>
    </div>
  </div>
  <div class="sub-header">
    <span class="sub-header-title">
      {{ 'Order modalities' | translate }}
    </span>
  </div>
  <section class="form-column">
    <div class="form-row" *ngIf="!isTypeOfTransportOrStorage()">
      <div class="flex-column">
        <span class="form-column-title primary-color">{{ 'Site' | translate }}</span>
        <md-input-container class="form-column-field">
          <textarea
            [rows]="setRowHeight(location?.name, 45)" 
            mdInput
            [value]="location?.name"
            disabled
          ></textarea>
        </md-input-container>
      </div>
      <div class="flex-column flex-column-description">
        <span class="form-column-title form-column-address primary-color">{{ 'Address' | translate }}</span>
        <md-input-container class="form-column-field--tsm-description">
            <textarea
              rows="1"
              #transformationSiteAddress
              [value]="order.out_order_passport.address"
              disabled
              mdInput
              autocomplete="off"
            ></textarea>
        </md-input-container>
      </div>
    </div>
    <div class="form-row" *ngIf="isTypeOfTransportOrStorage()">
      <div class="flex-column">
        <span class="form-column-title primary-color">{{ getEndOrDeliveryDateTitle() | translate }}</span>
        <md-input-container class="form-column-field--date form-column-field--end-date">
          <input
              mdInput
              date-only="true"
              [value]="datePipe.transform(order.out_order_passport.linked_datetime, 'dd-MM-yyyy')"
              disabled
            />
        </md-input-container>
      </div>
      <div class="flex-column form-column-field--transport">
        <span class="form-column-title primary-color">{{ 'Delivery address' | translate }}</span>
        <md-input-container class="form-column-field--tsm-description">
          <textarea
            [rows]="setRowHeight(tsmOrders[0]?.out_order_passport.address, 72)" 
            mdInput
            [value]="tsmOrders[0]?.out_order_passport.address"
            disabled
          ></textarea>
        </md-input-container>
      </div>
      <div class="flex-column form-column-field--distance-to-travel" *ngIf="isTransformationTypeOfTransport()">
        <span class="form-column-title primary-color">{{ 'Distance to travel' | translate }}</span>
        <md-input-container class="form-column-field--date form-column-field--end-date">
          <input
              mdInput
              [value]="tsmList[0]?.estimated_distance"
              disabled
            />
        </md-input-container>
      </div>
    </div>
    <div class="flex-column">
      <span class="form-column-title primary-color">{{ 'Price' | translate }}</span>
      <div class="flex-row">
        <md-input-container class="form-column-field--price">
          <input
            mdInput
            [value]="tsmList[0]?.tf_orders[0]?.price"
            disabled
          />
        </md-input-container>
        <span class="form-column-price">€</span>
        <i
        class="form-group__label--question  form-column-field--price-tsm-order"
        mdTooltip="{{'ESM_COMPILER_NEED_STEP_PRICE_TOOLTIP' | translate}}"
        mdTooltipPosition="above"
        >?</i>
      </div>
    </div>
    <div class="flex-column">
      <span class="form-column-title primary-color">{{ 'Description' | translate }}</span>
      <md-input-container class="form-column-field--tsm-description">
          <textarea
            [value]="tsmList[0]?.description"
            [rows]="setRowHeight(tsmList[0]?.description, 72)" 
            disabled
            mdInput
            autocomplete="off"
          ></textarea>
      </md-input-container>
    </div>
    <span class="form-column-title primary-color" *ngIf="files.length > 0">{{ 'Documents' | translate }}</span>
  </section>
  <section class="form-group-documents" *ngIf="files.length > 0">
    <documents-uploader
      [files]="files"
    ></documents-uploader>
  </section>
  <footer class="controls">
    <ul class="button-group__list">
      <li class="button-group__item button-group__item--more-two">
        <button md-button md-raised-button (click)="reject()">{{'Reject' | translate}}</button>
      </li>
      <li class="button-group__item button-group__item--more-two">
        <button md-button md-raised-button
                (click)="checkStatusValidated() ? changeVisibilityOfReportProblemModal() : changeVisibilityOfModificationModal()">
          {{ (checkStatusValidated() ? "Report a problem" : "Ask for modification" ) | translate}}
        </button>
        <div class="small-modal" *ngIf="reportProblemModalIsVisible">
          <div class="small-modal__header">
            <div class="small-modal__info">
              {{'Message to Ecosystem Manager' | translate}}
              <span class="small-modal__data">{{order.problem?.length || 0}}/3000</span>
            </div>
            <div class="small-modal__button">
              <button md-button md-raised-button (click)="reportProblem(order.id, order.problem)">{{'Send' | translate}}</button>
            </div>
          </div>
          <div class="small-modal__body">
            <textarea [(ngModel)]="order.problem" [placeholder]="'Type your problem here' | translate"
                      maxlength="3000"></textarea>
          </div>
        </div>
        <div class="small-modal" *ngIf="modificationModalIsVisible">
          <div class="small-modal__header">
            <div class="small-modal__info">
              {{'Message to Ecosystem Manager' | translate}}
              <span class="small-modal__data">{{order.modification?.length || 0}}/3000</span>
            </div>
            <div class="small-modal__button">
              <button md-button md-raised-button (click)="askForModification()">{{'Send' | translate}}
              </button>
            </div>
          </div>
          <div class="small-modal__body">
            <textarea [(ngModel)]="order.modification" [placeholder]="'Type your modification here' | translate"
                      (ngModelChange)="modificationChanged()"
                      [class.modification-field--error]="modificationIsNull"
                      maxlength="3000"></textarea>
          </div>
        </div>
      </li>
      <li class="button-group__item button-group__item--more-two">
        <button md-button md-raised-button (click)="checkStatusValidated() ? unvalidate() : validate()">
          {{ (checkStatusValidated() ? "Unvalidate" : "Validate" )| translate }}
        </button>
      </li>
      <li class="button-group__item" *ngIf="isOrderConfirmed">
        <button md-button md-raised-button (click)="confirm()">
          {{'Confirm' | translate}}
        </button>
      </li>
    </ul>
  </footer>
</ng-container>
