import * as tslib_1 from "tslib";
import { CurrentUser } from '../../../shared/auth/current-user.class';
import { DepositTagService } from '../services/deposit-tag.service';
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Deposit } from './deposit.class';
import { DepositLocation } from './deposit-location.class';
var DepositTag = /** @class */ (function (_super) {
    tslib_1.__extends(DepositTag, _super);
    function DepositTag(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = DepositTagService;
        if (!!_this.deposits) {
            _this.deposits = _this.deposits.map(function (deposit) { return new Deposit(deposit); });
        }
        if (!!_this.sites) {
            _this.sites = new DepositLocation(_this.sites);
        }
        return _this;
    }
    DepositTag.prototype.fields = function () {
        return [
            'id',
            'site_id',
            'user_id',
            'name',
            'deposit_count',
            'junctions',
            'deposits',
            'sites'
        ];
    };
    Object.defineProperty(DepositTag.prototype, "editable", {
        get: function () {
            return CurrentUser.infoData.id === this.user_id;
        },
        enumerable: true,
        configurable: true
    });
    return DepositTag;
}(ActiveRecord));
export { DepositTag };
