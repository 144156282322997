import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-passport-id-search',
  templateUrl: './passport-id-search.component.html',
})
export class PassportIdSearchComponent {
  @Input() placeholder: string = 'SearchByPassportID';
  @Input() searchTerm: string = '';
  @Output() searchChange = new EventEmitter<string>();

  onSearchChange(): void {
    this.searchChange.emit(this.searchTerm);
  }
}
