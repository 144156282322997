/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./google-chart.component";
var styles_GoogleChartComponent = ["[_nghost-%COMP%] {\n      display: block;  \n      height: 100%;\n    }"];
var RenderType_GoogleChartComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_GoogleChartComponent, data: {} });
export { RenderType_GoogleChartComponent as RenderType_GoogleChartComponent };
export function View_GoogleChartComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_GoogleChartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "google-chart", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GoogleChartComponent_0, RenderType_GoogleChartComponent)), i0.ɵdid(1, 573440, null, 0, i1.GoogleChartComponent, [i0.ElementRef], null, null)], null, null); }
var GoogleChartComponentNgFactory = i0.ɵccf("google-chart", i1.GoogleChartComponent, View_GoogleChartComponent_Host_0, { chartType: "chartType", chartOptions: "chartOptions", loadingDelay: "loadingDelay", chartData: "chartData" }, {}, []);
export { GoogleChartComponentNgFactory as GoogleChartComponentNgFactory };
