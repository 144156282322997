import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Transform} from '../models/transform.class';
import {PASSPORT_UNITS_TYPE} from '../../passport/values/passport-units.const';
import {Passport} from '../../passport/models/passport.class';

@Component({
  selector: 'transform-conversion',
  templateUrl: './transform-conversion.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class TransformConversionComponent {
  @Input() placeholder: string;
  @Input() transform: Transform = new Transform();
  @Input() quantity: number;
  @Input() isInputConversion: boolean;
  @Input() disabled = false;
  @Input() disabledEmptyPlaceholder = '';
  @Input() defaultUnit: number = PASSPORT_UNITS_TYPE.TONNE;
  @Input() previousEntity?: Passport | Transform;
  @Input() previousUnit?: number;
  @Input() previousConversion?: number;
  @Input() identifier?: string;
  @Output() quantityChanged = new EventEmitter();
  defaultUnitsToConversions = {
    [PASSPORT_UNITS_TYPE.KG]: 1,
    [PASSPORT_UNITS_TYPE.TONNE]: 1000
  };

  get conversionFactorField(): number {
    return Transform.getConversion(this.transform, this._conversion, this._previousConversion);
  }

  get volumeUnitField(): number {
    return this._unitFunc(this.transform, this._previousUnit, this._defaultUnit);
  }

  get quantityField(): number {
    return this.isInheritUnit() ? this.quantity : Transform.transformQuantityToCustomDefault(
      this.quantity, this.transform, this.defaultUnitsToConversions[this._defaultUnit]);
  }

  private isInheritUnit() {
    if (this.isInputConversion) {
      if (this.isPreviousUnit()) {
        return true;
      } else if (this.previousEntity instanceof Passport) {
        return true;
      } else if (this.isPreviousEntityTransformWithUnit()) {
        return true;
      }
    }
    return false;
  }

  constructor() { }

  private get _defaultUnit() {
    return this.defaultUnit || PASSPORT_UNITS_TYPE.KG;
  }

  private get _conversion() {
    if (this.isInputConversion) {
      return this.transform.in_conversion;
    } else {
      return this.transform.out_conversion;
    }
  }

  private get _unitFunc() {
    if (this.isInputConversion) {
      return Transform.getConversionInUnit;
    } else {
      return Transform.getConversionOutUnit;
    }
  }

  private get _previousUnit() {
    const defaultEntityUnit = PASSPORT_UNITS_TYPE.KG;
    if (this.isInputConversion) {
      if (this.isPreviousUnit()) {
        return this.previousUnit;
      } else if (this.previousEntity instanceof Passport) {
        return this.previousEntity.unit || defaultEntityUnit;
      } else if (this.isPreviousEntityTransformWithUnit()) {
        return this.previousEntity.output_unit || defaultEntityUnit;
      }
    }
    return this.defaultUnitsToConversions[this._defaultUnit];
  }

  private isPreviousUnit() {
    return this.previousUnit || this.previousUnit === 0;
  }

  private isPreviousEntityTransformWithUnit() {
    return this.previousEntity instanceof Transform
      && !Transform.isWithoutUnitByType(this.previousEntity.transformation_type);
  }

  private get _previousConversion() {
    if (this.isInputConversion) {
      if (this.previousConversion || this.previousConversion === 0) {
        return this.previousConversion;
      } else if (this.previousEntity instanceof Passport) {
        return this.previousEntity.conversion;
      } else if (this.isPreviousEntityTransformWithUnit()) {
        return this.previousEntity.out_conversion;
      }
    }
    return null;
  }

  emitQuantityValue(value) {
    return this.quantityChanged.emit(this.isInheritUnit() ? value : (Transform.inverseQuantityToCustomDefault(
      value, this.transform, this.defaultUnitsToConversions[this._defaultUnit]))
    );
  }
}
