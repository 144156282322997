/**
 * Created simo C. on 31.03.17.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericPassportService } from '../../../../entities/generic-passport/service/generic-passport.service';
import { MdDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';


@Component({
  moduleId: module.id,
  selector: 'generic-passport',
  templateUrl: 'generic-passport-dashboard.component.html'
})
export class GenericPassportDashboardComponent implements OnInit {
    zoneId: string;

    constructor(private mdDialog: MdDialog,
        private _genericPassportService: GenericPassportService,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.zoneId = this.route.snapshot.paramMap.get('id');

        console.log('lunch dashboard generic passport');
    }
}
