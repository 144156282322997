import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReadOnlyService} from '../../../../shared/read-only/read-only.service';
import {Need} from '../../models/need.class';
import {NeedService} from '../../services/need.service';
import {PASSPORT_UNITS} from '../../../passport/values/passport-units.const';
import {Passport} from '../../../passport/models/passport.class';
import {QUANTITY_PRECISION} from '../../values/quantity-precision.const';
import {NEED_STATUSES} from '../../values/need-statuses.const';
import {Observable} from 'rxjs/Observable';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {Router} from '@angular/router';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {ConfirmDialogComponent} from '../../../../shared/confirm-dialog/confirm-dialog.component';
import {MdDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'need-details',
  templateUrl: 'need-details.component.html',
  styleUrls: ['need-details.component.scss'],
  providers: [ReadOnlyService]
})
export class NeedDetailsComponent implements OnInit {
  PASSPORT_UNITS = PASSPORT_UNITS;

  @Input() id: number;
  @Input() readOnly: boolean;
  @Input() showValidationButtons: boolean;
  @Output() action = new EventEmitter();
  @Output() onOpen = new EventEmitter();
  parentModule: string;
  moduleNames = ModuleNames;
  need: Need;

  get passport(): Passport {
    return this.need.passport;
  }

  modificationModalIsVisible: boolean = false;
  modificationIsNull: boolean = false;

  constructor(private readOnlyService: ReadOnlyService,
              private needService: NeedService,
              private _navigationService: NavigationService,
              private router: Router,
              private dialog: MdDialog,
              private translate: TranslateService) {
    this.parentModule = this._navigationService.getModuleName();
  }

  ngOnInit() {
    this.readOnlyService.readOnly = this.readOnly;

    this.needService.view(
      this.id,
      {expand: 'client,passport,nomenclature,passport_materials,client_location' +
          ',passport_components,client_contact,files,sales_name,passport.category,passport.gtins'}
    ).subscribe((res: Need) => {
      this.need = res;
      this.need.loaded = true;
      return Observable.of([]);
    });
  }

  save() {
    this.need.save().subscribe(() => {
      this.action.emit();
    });
  }

  validate() {
    this.need.publish().subscribe(() => {
      this.action.emit();
    });
  }

  reject() {
    this.need.reject().subscribe(() => {
      this.action.emit();
    });
  }

  askForModification() {
    if (!this.need.modification) {
      this.modificationIsNull = true;
      return;
    }
    this.need.askForModification().subscribe(() => {
      this.action.emit();
    });
  }

  checkStatusValidated() {
    return this.need.status !== NEED_STATUSES.WAITING_FOR_PUBLICATION.value;
  }

  changeVisibilityOfModificationModal() {
    this.need.modification = '';
    this.modificationModalIsVisible = !this.modificationModalIsVisible;
  }

  modificationChanged() {
    this.modificationIsNull = !this.need.modification;
  }

  getLabelClasses(field) {
    return {
      'primary-color': field,
      'label-color': !field,
      'passport-edit__color-transition': true
    };
  }

  onSeeFullPassport() {
    this.translate.get('CONFIRM_OPEN_PASSPORT_PAGE')
      .subscribe(( translation ) => {
        const confirmationDialog = this.dialog.open(ConfirmDialogComponent, {
          data: {
            text: translation
          }
        });
        confirmationDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.router.navigate([this.parentModule + '/passports', this.passport.id]);
          }
        });
      });
  }
  getQuantityPrecisionType(key: number): string {
    const type = QUANTITY_PRECISION.find((item) => item.key === key);
    return type ? type.value : '';
  }
}
