import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { AffiliateBranch } from '../models/affiliateBranch.class';

@Injectable()
export class AffiliateBranchService extends CrudService {
  protected namespace = 'affiliate-branches';
  protected namespaceSingular = 'affiliate-branch';
  protected ModelClass = AffiliateBranch;
}
