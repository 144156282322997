/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';


@Component({
  moduleId: module.id,
  selector: 'link-date',
  templateUrl: 'link-date.component.html',
  styleUrls: ['link-date.component.scss'],
})
export class LinkedDateDialogComponent implements OnInit, OnDestroy {

  public linked_date: string;

  constructor(public dialog: MdDialogRef<any>,
              @Inject(MD_DIALOG_DATA) private dialogData: any) {
  }

  ngOnInit() {
    this.linked_date = this.dialogData.input_date;
  }

  ngOnDestroy() {
    this.close();
  }
  
  close(){
    this.dialog.close(this.linked_date);
  }
  change(event){
    this.linked_date = event + '';
    this.close();
  }
  clearDate() {
    this.linked_date = null;
    this.close();
  }
}
