import { Component, Input } from '@angular/core';
import { PassportRating } from '../../models/passport-rating.class';
import { PASSPORT_CERTIFICATION_LEVELS } from '../../values/passport-certification-levels.const';
import { Passport } from '../../models/passport.class';
import { TranslateService } from '@ngx-translate/core';
import { PERIOD_OF_USE_TYPES } from '../../values/period-of-use.values';
import { UsedEnergy } from '../../models/used-energy.class';

export enum ToxicityState {
  None = 0,
  Unverified = 1,
  Verified = 2
}

@Component({
  moduleId: module.id,
  selector: 'passport-rating',
  styleUrls: ['passport-rating.component.scss'],
  templateUrl: 'passport-rating.component.html',
})
export class PassportRatingComponent {

  constructor(private translateService: TranslateService) { }

  @Input() passport: Passport;

  getC2cTooltipValue(): { X: string; Y: string; } {
    const certificationLevel = PASSPORT_CERTIFICATION_LEVELS.find(i => i.key === this.passport.certification_level);
    return {
      X: !!certificationLevel ? this.translateService.instant(certificationLevel.value) : '?',
      Y: this.passport.certification_expiration || '?'
    };
  }

  getCircularityTooltipValue(): { X: string; Y: string; Z: string; } {
    const isPeriodExist: boolean = !!this.passport.period && !!this.passport.period_type;
    const periodViewValue: string = isPeriodExist ? PERIOD_OF_USE_TYPES.find(i => i.value === this.passport.period_type).view : '';
    const periodOfUse = isPeriodExist ? `${this.passport.period} ${this.translateService.instant(periodViewValue) || ''}` : '?';
    const nextUsageViewValue = (): string => {
      if (!!this.passport.next_usages && !!this.passport.next_usages.length) {
        return this.passport.next_usages.map(i => i.getTypeText()).join(', ') || '?';
      }
      return '?';
    };
    return {
      X: !!this.passport.recycle_percentage && this.passport.recycle_percentage.toString() + '%' || '?',
      Y: periodOfUse,
      Z: nextUsageViewValue()
    };
  }

  getEnergyTooltipValues(): { energy_in_production: string; total_whole_life: string; product_stage: string; end_of_life: string } {
    const getRenewableEnergiesSum = (): string => {
      let energiesSum = null;
      if (!!this.passport.energies && !!this.passport.energies.length) {
        this.passport.energies.forEach((energy: UsedEnergy) => {
          if (energy.isRenewable()) {
            energiesSum += energy.percentage;
          }
        });
      }
      return !!energiesSum ? `${energiesSum}%` : '?';
    };

    const getCarbonValue = (value): string => !!value ? value : '?';
    return {
      energy_in_production: getRenewableEnergiesSum(),
      total_whole_life: getCarbonValue(this.passport.carbon_total),
      product_stage: getCarbonValue(this.passport.carbon_product),
      end_of_life: getCarbonValue(this.passport.carbon_end)
    };
  }
  
}
