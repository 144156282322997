
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MdDialog, MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { EcosystemFileService } from '../../../../entities/ecosystem/services/ecosystem-file.service';
import { TRANSFORM_TYPE } from '../../../transformation-manager/values/transform-types.const';
import { EcosystemService } from '../../../../entities/ecosystem/services/ecosystem.service';
import { EcosystemStep } from '../../models/ecosystem-step.class';
import { Ecosystem } from '../../models/ecosystem.class';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { ECOSYSTEM_STEP_TYPE } from '../../values/ecosystem-step-type.const';
import { ECOSYSTEM_STATUS } from '../../values/ecosystem-status.const';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { EcosystemIndicatorReportContext } from '../../services/ecosystem-indicator-report/ecosystem-indicator-report-context.service';
import { EEcosystemIndicatorReportType } from '../../values/ecosystem-indicator-report.const';
import { EcosystemIndicatorCalculator } from '../../services/ecosystem-indicator-calculator.service';
import { Subscription } from 'rxjs';
import {convertUnit} from '../../../../helper/Unithelper';

@Component({
  moduleId: module.id,
  selector: 'ecosystem-description',
  templateUrl: 'ecosystem-indicators.component.html',
  styleUrls: ['ecosystem-indicators.component.scss'],
  providers: [{provide: EcosystemIndicatorReportContext, useClass: EcosystemIndicatorReportContext}]
})
export class EcosystemIndicatorsDialogComponent implements OnInit, OnDestroy {
  ecosystem: Ecosystem;
  ecosystemSteps: EcosystemStep[];
  stepNames: {};
  ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE= ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE;
  ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP_TYPE;
  ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
  ECOSYSTEM_REPORT_TYPE = EEcosystemIndicatorReportType;
  private onReportChange: Subscription;
  public convertUnit = convertUnit;
  
  constructor(public mdDialogRef: MdDialogRef<any>,
    public notification: LogNotificationService,
    public ecosystemFileService: EcosystemFileService,
    public dialog: MdDialog,
    public ecosystemService: EcosystemService,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any,
    private indicatorCalculator: EcosystemIndicatorCalculator,
    public reportContext: EcosystemIndicatorReportContext,
    private globalEventsManager: GlobalEventsManager) {}

  ngOnInit() {
    this.ecosystem = this.mdDialogData.ecosystem;
    this.reportContext.setEcosystem(this.ecosystem);
    this.ecosystemService.view(this.mdDialogData.ecosystem.id,
      {expand: 'waste_total,economic_total,emissions_total,water_total'})
      .subscribe(ecosystem => {
        this.ecosystem = ecosystem;
    });
    this.ecosystemService.previewTotals(this.ecosystem.id, []).subscribe(() => {
      if (this.globalEventsManager.compilerDataBeenUpdated) {
        this.globalEventsManager.compilerDataUpdated.subscribe(() => {
          this.updateSummaryData();
        });
      } else {
        this.updateSummaryData();
      }
    });
    this.stepNames = this.ecosystem.steps.map(step => ({id: step.id, name: step.title }));
  }

  updateSummaryData() {
    this.ecosystemService.getSummary(this.ecosystem.id).subscribe(data => {
      this.ecosystemSteps = this.sortAndFilterSteps(data);
      this.updateReportContext();
      this.updateConfirmedData();
      this.updateReportValues();
    });
  }

  sortAndFilterSteps(data: EcosystemStep[]): EcosystemStep[] {
    const sortOrder = [
      ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT,
      ECOSYSTEM_STEP_TYPE.DEPOSIT,
      ECOSYSTEM_STEP_TYPE.TRANSFORM,
      ECOSYSTEM_STEP_TYPE.NEED
    ];
    return data
    .sort((a, b) => {
      const sorted = sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
      return sorted !== 0 ? sorted : (a.transformation_type === TRANSFORM_TYPE.TRANSPORT ? 1 : -1);
    })
    .filter(step => step.type !== ECOSYSTEM_STEP_TYPE.STAKEHOLDER)
    .filter(s => !!s.summary_details.step || s.summary_details.orders.length > 0);
  }

  updateReportContext() {
    const depositSteps = this.ecosystemSteps.filter(s => s.type === ECOSYSTEM_STEP_TYPE.DEPOSIT);
    const needSteps = this.ecosystemSteps.filter(s => s.type === ECOSYSTEM_STEP_TYPE.NEED);
    const transformSteps = this.ecosystemSteps.filter(s => s.type === ECOSYSTEM_STEP_TYPE.TRANSFORM &&
      s.transformation_type !== TRANSFORM_TYPE.TRANSPORT);
    const transportSteps = this.ecosystemSteps.filter(s => s.type === ECOSYSTEM_STEP_TYPE.TRANSFORM &&
        s.transformation_type === TRANSFORM_TYPE.TRANSPORT);
    const virginProductsSteps = this.ecosystemSteps.filter(s => s.type === ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT);
    this.reportContext.setSteps({
        virginProducts: virginProductsSteps
      , deposits: depositSteps
      , transforms: transformSteps
      , transports: transportSteps
      , needs: needSteps
    });
    this.reportContext.setEcosystem(this.ecosystem);
    if (!this.onReportChange) {
      this.onReportChange = this.reportContext.onOtherChange.subscribe(() => this.saveReportOtherChanges());
    }
  }

  updateConfirmedData() {
    this.ecosystemSteps.forEach(s => {
      const stepData = s.summary_details.step;
      s.summary_details.orders.forEach(o => {
        o.confirmed_distance = this.isNumber(o.confirmed_distance)
          ? o.confirmed_distance : o.estimated_distance;
        o.confirmed_quantity = this.isNumber(o.confirmed_quantity) ? o.confirmed_quantity : o.quantity;
        o.confirmed_price = this.isNumber(o.confirmed_price) ? o.confirmed_price : o.price;
      });
      stepData.confirmed_price = this.isNumber(stepData.confirmed_price)
        ? stepData.confirmed_price : stepData.price;
      stepData.confirmed_quantity = this.isNumber(stepData.confirmed_quantity)
        ? stepData.confirmed_quantity : stepData.quantity;
    });
  }

  private isNumber(field) {
    return typeof field === 'number';
  }

  updateReportValues() {
    const summarySteps = this.generateSummarySteps();
    this.ecosystemService.previewTotals(this.ecosystem.id, summarySteps)
      .subscribe((ecosystem: Ecosystem) => {
        this.ecosystem.waste_total = ecosystem.waste_total;
        this.ecosystem.economic_total = ecosystem.economic_total;
        this.ecosystem.emissions_total = ecosystem.emissions_total;
        this.ecosystem.water_total = ecosystem.water_total;
    });
    this.ecosystemSteps = this.indicatorCalculator.recalculate(this.ecosystemSteps, summarySteps);
  }

  generateSummarySteps(): SummaryStep[] {
    return this.ecosystemSteps.map(s => {
      let orders = [];
      if (s.summary_details.orders.length > 0) {
        orders = s.summary_details.orders.map(o => {
          return {order_id: o.id,
            confirmed_price: s.summary_details.orders[0].confirmed_price,
            confirmed_quantity: o.confirmed_quantity,
            confirmed_distance: o.confirmed_distance};
        });
      }
      return {
        step_id: s.id,
        confirmed_quantity: s.summary_details.step.confirmed_quantity,
        confirmed_price: s.summary_details.step.confirmed_price,
        orders: orders
      };
    });
  }

  ngOnDestroy() {
    if (this.onReportChange) {
      this.onReportChange.unsubscribe();
    }
  }

  saveReportOtherChanges() {
    this.ecosystem.save().subscribe(() => {
      this.updateReportValues();
    });
  }

  saveSummary() {
    const summarySteps = this.generateSummarySteps();
    this.ecosystemService.saveSummary(this.ecosystem.id, summarySteps).subscribe(() => {
      this.ecosystemService.view(this.ecosystem.id,
        {expand: 'waste_total,economic_total,emissions_total,water_total'})
      .subscribe((ecosystem: Ecosystem) => {
        this.ecosystem = ecosystem;
        this.mdDialogData.updateSteps(summarySteps);
        this.notification.success('UPDATED_SUCCESSFULY', 2000, true);
      });
    });
  }

  roundNumber(number) {
    return Math.round( number );
  }

  /**
   * Format the given number to fit the format of the total
   * If the number is inferior to -100 or superior to 100, round it to the nearest integer
   * If the number is superior to -100 and inferior to 100, show the number with 2 decimals
   * @param {Number} number - The number to format
   * @returns {Number}
   */
  formatTotal(number) {
    return Math.abs(number) >= 100 ? Math.round(number) : number.toFixed(2);
  }
}
