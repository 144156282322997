import { Injectable } from '@angular/core';
import {Eula} from '../../shared/models/eula.class';
import {ExtendedCrudService} from '../../shared/services/extended-crud.service';
import {Observable} from 'rxjs/Observable';
import {Response} from '@angular/http';
import {IAcceptEulaRequest} from '../../shared/interfaces/requests/user-service-requests.interface';

@Injectable()
export class EulaService extends ExtendedCrudService {
  protected namespace = 'eulas';
  protected namespaceSingular = 'eula';
  protected ModelClass = Eula;

  public getMostRecent(): Observable<any | Response> {
    return this.sendGet(this.getEndpoint(this.namespaceSingular + '/most-recent'))
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }
  public accept(data: IAcceptEulaRequest): Observable<any | Response> {
    return this.sendPost(this.getEndpoint(this.namespaceSingular + '/accept-last'), data)
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }
}
