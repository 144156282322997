/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./deposit-import-errors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i5 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i6 from "../../../../shared/confirm-dialog/components/confirm-dialog-icon/confirm-dialog-icon.component.ngfactory";
import * as i7 from "../../../../shared/confirm-dialog/components/confirm-dialog-icon/confirm-dialog-icon.component";
import * as i8 from "./deposit-import-errors.component";
import * as i9 from "@angular/material";
var styles_DepositImportErrorsComponent = [i0.styles];
var RenderType_DepositImportErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DepositImportErrorsComponent, data: {} });
export { RenderType_DepositImportErrorsComponent as RenderType_DepositImportErrorsComponent };
function View_DepositImportErrorsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "tr", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "deposit-import-errors__row": 0, "gray": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [["class", "deposit-import-errors__td--line"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [["class", "deposit-import-errors__td--error"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, true, _v.context.even); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.line; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.error; _ck(_v, 7, 0, currVal_2); }); }
function View_DepositImportErrorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "table", [["class", "deposit-import-errors__table-errors"], ["id", "table-errors"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "tr", [["class", "deposit-import-errors__row--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "th", [["class", "deposit-import-errors__lines-header primary-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "th", [["class", "deposit-import-errors__error-header primary-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositImportErrorsComponent_2)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.mdDialogData.errors; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Lines")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("Errors")); _ck(_v, 7, 0, currVal_1); }); }
export function View_DepositImportErrorsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DialogCloseButtonComponent_0, i4.RenderType_DialogCloseButtonComponent)), i1.ɵdid(3, 49152, null, 0, i5.DialogCloseButtonComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "deposit-import-errors"], ["tabindex", "1"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.onSelectAll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "deposit-import-errors__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "confirm-dialog-icon", [], null, null, null, i6.View_ConfirmDialogIconComponent_0, i6.RenderType_ConfirmDialogIconComponent)), i1.ɵdid(7, 114688, null, 0, i7.ConfirmDialogIconComponent, [], { iconData: [0, "iconData"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "deposit-import-errors__file-erors"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(11, 1), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "deposit-import-errors__file-erors--description"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " : "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "deposit-import-errors__table-errors-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositImportErrorsComponent_1)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.iconData; _ck(_v, 7, 0, currVal_1); var currVal_4 = _co.mdDialogData.errors; _ck(_v, 17, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).applyClassPosition; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("file errors")))); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("The import failed because of the following lines")); _ck(_v, 13, 0, currVal_3); }); }
export function View_DepositImportErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DepositImportErrorsComponent_0, RenderType_DepositImportErrorsComponent)), i1.ɵdid(1, 49152, null, 0, i8.DepositImportErrorsComponent, [i9.MdDialogRef, i9.MD_DIALOG_DATA], null, null)], null, null); }
var DepositImportErrorsComponentNgFactory = i1.ɵccf("ng-component", i8.DepositImportErrorsComponent, View_DepositImportErrorsComponent_Host_0, {}, {}, []);
export { DepositImportErrorsComponentNgFactory as DepositImportErrorsComponentNgFactory };
