/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dialog-close-button.component";
var styles_DialogCloseButtonComponent = [".dialog-close-button-container {\n      display: block;\n      position: relative;\n    }\n\n    [_nghost-%COMP%] {\n      position: absolute;\n      cursor: pointer;\n    }\n    .dialog-close-button--position[_nghost-%COMP%] {\n      top: 16px;\n      right: 18px;\n    }"];
var RenderType_DialogCloseButtonComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DialogCloseButtonComponent, data: {} });
export { RenderType_DialogCloseButtonComponent as RenderType_DialogCloseButtonComponent };
export function View_DialogCloseButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.closeButtonClass, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_DialogCloseButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], null, null, View_DialogCloseButtonComponent_0, RenderType_DialogCloseButtonComponent)), i0.ɵdid(1, 49152, null, 0, i1.DialogCloseButtonComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).applyClassPosition; _ck(_v, 0, 0, currVal_0); }); }
var DialogCloseButtonComponentNgFactory = i0.ɵccf("dialog-close-button", i1.DialogCloseButtonComponent, View_DialogCloseButtonComponent_Host_0, { color: "color", defaultPosition: "defaultPosition" }, {}, []);
export { DialogCloseButtonComponentNgFactory as DialogCloseButtonComponentNgFactory };
