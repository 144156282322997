import { Injectable } from '@angular/core';
import {CrudService} from '../../shared/services/crud.service';
import {ActiveImport} from '../models/active-import.class';

@Injectable({
  providedIn: 'root'
})
export class ActiveImportService extends CrudService {
  protected ModelClass = ActiveImport;
  protected namespace = 'active-imports';
  protected namespaceSingular = 'active-import';


  protected readEndpointUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/read');
  }

  public read(importId) {
    return this.sendPost(this.readEndpointUrl(importId));
  }
}
