<div class="nomenclature-change-dialog">
  <div class="close" (click)="mdDialogRef.close()">
    <i class="icon icon--close_s"></i>
  </div>

  <h3 class="title">{{ "Add change description" | translate }}</h3>
  <md-input-container class="description">
    <textarea mdInput placeholder="{{ 'Description' | translate }}" [(ngModel)]="transform.message"></textarea>
  </md-input-container>

  <div class="actions">
    <button
      class="save"
      (click)="send()"
      [disabled]="busy"
      md-button
    >
      <img
        class="icon"
        src="assets/png/check--white-icon.png"
      >
      <span class="text">
      {{ 'Send' | translate }}
    </span>
    </button>
  </div>
</div>
