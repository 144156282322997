import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function httpFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
  ],
  exports: [
    TranslateModule
  ],
  providers: [
  ],
  entryComponents: []
})
export class CustomTranslateModule {

}
//TranslateStaticLoader?
//https://github.com/angular/angular/issues/13702?
