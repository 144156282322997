import {TransformOrderService} from '../services/transform-order.service';
import {OrderPassport} from './order-passport.class';
import {IOrder} from '../interfaces/order.interface';
import {ExtendedActiveRecord} from '../../../shared/models/extended-active-record.class';
import {Transform} from '../../../entities/transformation/models/transform.class';
import {EcosystemFileJunction} from './ecosystem-file-junction.class';
import {ECOSYSTEM_STEP_STATUS} from '../values/ecosystem-step-status.const';
import {Ecosystem} from './ecosystem.class';
import {EcosystemStep} from '../../ecosystem-manager/models/ecosystem-step.class';
import {ECOSYSTEM_STEP_TYPE} from '../values/ecosystem-step-type.const';
import {TRANSFORM_TYPE, TRANSFORM_TYPES, TRANSFORM_TYPES_WITH_SITES} from '../../transformation-manager/values/transform-types.const';
import {SourceConnectorService} from '../../../../e2e/helpers/source-connector/source-connector.service';
import PASSPORT_UNIT_TYPE = SourceConnectorService.PASSPORT_UNIT_TYPE;
import lodash from 'lodash';
import {EcosystemProblem} from '../../../entities/ecosystem/models/ecosystem-problem.model';
import { TRANSFORM_ORDER_STATUS } from '../../../dashboards/transformation-manager/values/transform-order-statuses.const';
import { Observable } from 'rxjs';
import { TOUCHEND_HIDE_DELAY } from '@angular/material';

export class TransformOrder extends ExtendedActiveRecord implements IOrder {
  private TON_CONVERSION = 1000;
  protected provider = TransformOrderService;

  public id: number;
  public transformation_id: number;
  public site_id: number;
  public price: number;
  public status: number;
  public confirmation_date: any;
  public publication_date: any;
  public estimated_distance: string;
  public confirmed_distance: string;
  public step_id: number;
  public created_at: string;
  public updated_at: string;
  public in_order_passport_id: number;
  public in_order_passport: OrderPassport;
  public out_order_passport_id: number;
  public out_order_passport: OrderPassport;
  public ecosystem_name: string;
  public creator: string;
  public creator_company: string;
  public creator_id: number;
  public transformation: Transform;
  public ecosystem: Ecosystem;
  public ecosystem_id: number;
  public ecosystem_file_junction: EcosystemFileJunction[];
  public TSM_price: number;
  public default_conversion: number;
  public default_in_quantity: number;
  public default_unit: number;
  public problem?: any;
  public modification?: string;
  public linked_date: string;
  public child_step: EcosystemStep;
  public pickup_address: string;
  public delivery_address: string;
  public orders_common_step: TransformOrder[];
  constructor(data: any = {}) {
    super(data);
    this.child_step = new EcosystemStep(data.child_step);
    this.in_order_passport = new OrderPassport(data.in_order_passport);
    this.out_order_passport = new OrderPassport(data.out_order_passport);
    this.transformation = new Transform(data.transformation);
    this.ecosystem_file_junction = data.ecosystem_file_junction
      ? data.ecosystem_file_junction.map((item: any) => new EcosystemFileJunction(item))
      : [];
    this.ecosystem = new Ecosystem(data.ecosystem);
    if (data.orders_common_step) {
      this.orders_common_step = data.orders_common_step.map(item => new TransformOrder(item));
    }
    this.problem = data.problem ? new EcosystemProblem(data.problem) : null;
  }

  protected fields() {
    return [
      'child_step',
      'confirmation_date',
      'created_at',
      'creator',
      'creator_company',
      'creator_id',
      'ecosystem_file_junction',
      'ecosystem_name',
      'id',
      'in_order_passport_id',
      'modification',
      'out_order_passport_id',
      'ecosystem_id',
      'price',
      'publication_date',
      'site_id',
      'status',
      'step_id',
      'transformation_id',
      'TSM_price',
      'updated_at',
      'default_unit',
      'default_conversion',
      'default_in_quantity',
      'linked_date',
      'pickup_address',
      'delivery_address',
      'description',
      'orders_common_step',
      'problem',
      'estimated_distance',
      'confirmed_distance',
    ];
  }

  beforeSave(data: any) {
    if (data.status) {
      delete data.status; // avoid step.status !== order.status bug
      data.status = this.status;
    }
    return data;
  }

  get status_text() {
    if (this.status) {
      return ECOSYSTEM_STEP_STATUS[this.status]
        .replace(/_/g, ' ')
        .toLowerCase()
        ;
    } else {
      return '';
    }
  }

  public isChildStepWithoutUnit() {
    return !this.child_step
      || [ECOSYSTEM_STEP_TYPE.STAKEHOLDER].includes(this.child_step.type)
      || (this.child_step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM
        && this._isWithoutUnit(this.child_step.transformation_type));
  }

  public getConversionForInput() {
    return this.isWithoutUnit() ? this.conversionInDefaultView : this.transformation.in_conversion;
  }

  public _isWithoutUnit(type) {
    return Transform.isWithoutUnitByType(type);
  }

  public isWithoutUnit() {
    return this._isWithoutUnit(this.transformation.transformation_type);
  }

  private getViewConversion(conversion, defaultConversion) {
    return Transform.getConversion(this.transformation, conversion, !this.isChildStepWithoutUnit() ? defaultConversion : 1);
  }
  
  get conversionOutDefaultView() {
    return this.getViewConversion(this.transformation.out_conversion, 1);
  }

  get conversionInDefaultView() {
    return this.getViewConversion(this.transformation.in_conversion, this.default_conversion);
  }

  get unitInView() {
    const substitution = this.isChildStepWithoutUnit() ? null : (this.default_unit || PASSPORT_UNIT_TYPE.KG);
    return Transform.getConversionInUnit(this.transformation, substitution);
  }

  get unitOutView() {
    return Transform.getConversionOutUnit(this.transformation);
  }

  public getCustomInQuantityView(orderPassport: OrderPassport, quantity = orderPassport.quantity) {
    return this.isChildStepWithoutUnit()
      ? Transform.transformQuantityToCustomDefault(quantity, this.transformation) : quantity;
  }

  get orderInQuantityView() {
   return this.getCustomInQuantityView(this.in_order_passport);
  }

  get orderOutQuantityView() {
    return Transform.transformQuantityToCustomDefault(this.out_order_passport.quantity, this.transformation);
  }

  public transformFromInView(quantity: number) {
    if (this.isChildStepWithoutUnit()) {
      return Transform.inverseQuantityToCustomDefault(quantity, this.transformation);
    }
    return quantity;
  }

  public transformFromOutView(quantity: number) {
    return Transform.inverseQuantityToCustomDefault(quantity, this.transformation);
  }

  public get inputNameView() {
    const getTextField = <T>(model: T, option: keyof T) => (model && model[option]) ? model[option] : false;
    const passportName = getTextField<OrderPassport>(this.in_order_passport, 'name');
    const stepTittle = getTextField<EcosystemStep>(this.child_step, 'title');
    return passportName ||  stepTittle || 'Unnamed';
  }

  rejectByStep() {
    return this.reject().mergeMap(() =>
      Observable.forkJoin((this.orders_common_step || []).map(order => {
        order.status = TRANSFORM_ORDER_STATUS.REJECTED;
        return order.save();
      }))
    )
  }
  validateByStep() {
    return this.validate().mergeMap(() =>
      Observable.forkJoin((this.orders_common_step || []).map(order => {
        order.status = TRANSFORM_ORDER_STATUS.PENDING;
        return order.save();
      }))
    )
  }
  askForModificationByStep() {
    return this.askForModification().mergeMap(() =>
      Observable.forkJoin((this.orders_common_step || []).map(order => {
        order.status = TRANSFORM_ORDER_STATUS.ASK_FOR_MODIFICATION;
        return order.save();
      }))
    )
  }
}
