import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';
import { ApiService } from '../../../shared/services/api.service';
import { ILiteral } from 'ng2-adn-common';
import { NeedStatsContainer } from '../models/need-stats-container.class';
import { DepositStatsContainer } from '../models/deposit-stats-container.class';
import { ClientStatsContainer } from '../models/client-stats-container.class';
import { TransformationStatsContainer } from '../models/transformation-stats-container.class';
import { SupervisorStatsContainer } from '../models/supervisor-stats-container.class';
import { CurrentUser } from '../../../shared/auth/current-user.class';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { DepositOwnerStatsContainer } from '../models/deposit-owner-stats-container.class';
import { SalesStatsContainer } from '../models/sales-stats-container.class';
import { AgentStatsContainer } from '../models/agent-stats-container.class';
import { TSMStatsContainer } from '../models/tsm-stats-container.class';
import { EcosystemStatsContainer } from '../models/ecosystem-stats-container.class';

@Injectable()
export class StatsService extends ApiService {
  protected namespace = 'stats';
  protected navigationService = ServiceLocator.injector.get(NavigationService);

  public getNeedsUrl(): string {
    return this.getEndpoint(this.namespace + '/needs');
  }

  public getDepositsUrl(): string {
    return this.getEndpoint(this.namespace + '/deposits');
  }

  public getClientsUrl(): string {
    return this.getEndpoint(this.namespace + '/clients');
  }

  public getTransformationsUrl(): string {
    return this.getEndpoint(this.namespace + '/transformations');
  }

  public getUserUrl(): string {
    return this.getEndpoint(this.namespace + '/user');
  }

  public getNeeds(query?: any): Observable<any | NeedStatsContainer> {
    return this
      .sendGet(this.getNeedsUrl(), {search: query})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new NeedStatsContainer(data))
      .catch(this.onError.bind(this));
  }

  public getDeposits(query?: any): Observable<any | DepositStatsContainer> {
    return this
      .sendGet(this.getDepositsUrl(), {search: query})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new DepositStatsContainer(data))
      .catch(this.onError.bind(this));
  }

  public getClients(query?: any): Observable<any | ClientStatsContainer> {
    return this
      .sendGet(this.getClientsUrl(), {search: query})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new ClientStatsContainer(data))
      .catch(this.onError.bind(this));
  }

  public getTransformations(query?: any): Observable<any | TransformationStatsContainer> {
    return this
      .sendGet(this.getTransformationsUrl(), {search: query})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new TransformationStatsContainer(data))
      .catch(this.onError.bind(this));
  }

  public getSupervisorStats(): Observable<any | SupervisorStatsContainer> {
    let id = CurrentUser.infoData.id;
    let role = this.navigationService.roleId;
    return this
      .sendGet(this.getUserUrl(), {search: {'id[]': id, 'roles[]': role}})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new SupervisorStatsContainer(data[id][role]))
      .catch(this.onError.bind(this));
  }

  public getDepositOwnerStats(): Observable<any | DepositOwnerStatsContainer> {
    let id = CurrentUser.infoData.id;
    let role = this.navigationService.roleId;
    return this
      .sendGet(this.getUserUrl(), {search: {'id[]': id, 'roles[]': role}})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new DepositOwnerStatsContainer(data[id][role]))
      .catch(this.onError.bind(this));
  }

  public getSalesStats(): Observable<any | SalesStatsContainer> {
    let id = CurrentUser.infoData.id;
    let role = this.navigationService.roleId;
    return this
      .sendGet(this.getUserUrl(), {search: {'id[]': id, 'roles[]': role}})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new SalesStatsContainer(data[id][role]))
      .catch(this.onError.bind(this));
  }

  public getAgentStats(): Observable<any | AgentStatsContainer> {
    let id = CurrentUser.infoData.id;
    let role = this.navigationService.roleId;
    return this
      .sendGet(this.getUserUrl(), {search: {'id[]': id, 'roles[]': role}})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new AgentStatsContainer(data[id][role]))
      .catch(this.onError.bind(this));
  }

  public getTSMStats(): Observable<any | TSMStatsContainer> {
    let id = CurrentUser.infoData.id;
    let role = this.navigationService.roleId;
    return this
      .sendGet(this.getUserUrl(), {search: {'id[]': id, 'roles[]': role}})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new TSMStatsContainer(data[id][role]))
      .catch(this.onError.bind(this));
  }

  public getEcosystemStats(query?: any): Observable<any | EcosystemStatsContainer> {
    let id = CurrentUser.infoData.id;
    let role = this.navigationService.roleId;
    let searchQuery = Object.assign({'id[]': id, 'roles[]': role}, query);
    return this
      .sendGet(this.getUserUrl(), {search: searchQuery})
      .map((res: Response) => res.json())
      .map((data:ILiteral) => new EcosystemStatsContainer(data[id][role]))
      .catch(this.onError.bind(this));
  }
}
