import { ControlState } from '../../../shared/models/control-state.class';
export class PassportControlStates { // disabled state not implemented
  name?: ControlState;
  unit?: ControlState;
  manufacturer?: ControlState;
  logo?: ControlState;
  format?: ControlState;
  next_use?: ControlState;
  files?: ControlState;
  recommendation?: ControlState;
  description?: ControlState;
  // materials?: ControlState;
  components?: ControlState;
}
