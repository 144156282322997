import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { LandfillVariables } from '../../dashboards/superadmin/components/landfill-variables/landfill-variables.model';
import { ServiceLocator } from '../services/service-locator';
import { ZoneService } from '../services/zone.service';
import { LANDFILL_ENUM, LANDFILL_LABEL } from '../values/landfill.map';
import { ActiveRecord } from './active-record.class';
var Zone = /** @class */ (function (_super) {
    tslib_1.__extends(Zone, _super);
    function Zone(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = ZoneService;
        _this.zoneService = ServiceLocator.injector.get(ZoneService);
        _this.onSave$ = new EventEmitter();
        return _this;
    }
    Zone.prototype.fields = function () {
        return [
            'id',
            'name',
            'status',
            'currency_iso',
            'landfill_cost_variables',
            'passport_count'
        ];
    };
    Zone.prototype.publish = function () {
        var _this = this;
        this.currency_iso = 'EUR';
        this.save().subscribe(function (response) {
            _this.zoneService.publish(response.id, {}).subscribe(function () {
                _this.saveLandfillVariables(response.id);
            });
        });
    };
    Zone.prototype.saveZone = function () {
        var _this = this;
        this.currency_iso = 'EUR';
        this.save().subscribe(function (response) {
            _this.saveLandfillVariables(response.id);
        });
    };
    Zone.prototype.saveLandfillVariables = function (zoneId) {
        var _this = this;
        this.zoneService.saveLandfillVariables(zoneId, { landfill_cost_variables: this.mapLandfill(zoneId) })
            .subscribe(function () {
            _this.onSave$.emit();
        });
    };
    Zone.prototype.mapLandfill = function (zone_id) {
        return Object.entries(this.landfill_cost_variables).map(function (landfill) {
            return new LandfillVariables({
                id: LANDFILL_ENUM[landfill[0]],
                name: LANDFILL_LABEL[LANDFILL_ENUM[landfill[0]]],
                zone_id: zone_id,
                value: landfill[1]
            });
        });
    };
    return Zone;
}(ActiveRecord));
export { Zone };
