import { HostListener } from "@angular/core";
import { MdTooltip } from "@angular/material";


export abstract class AbstractTooltipPrefix {
    @HostListener('DOMMouseScroll')
    mozWheelTooltip() {
      this.onWheel();
    }
    @HostListener('mousewheel')
    iosWheelTooltip() {
      this.onWheel();
    }
    @HostListener('wheel')
    wheelTooltip() {
      this.onWheel();
    }
    materialTooltip: MdTooltip
 
    private isUpdatePosition = false;

    public getCdkContainer(materialTooltip: MdTooltip) {
        return materialTooltip._overlayRef ? materialTooltip._overlayRef['_pane'] : null;
    }
    public applyWorkAroundForChangeDetectionOnPush(materialTooltip: MdTooltip) {
        const func = materialTooltip.show.bind(materialTooltip);
        const _this = materialTooltip;
        materialTooltip.show = function(delay?: number) {
          func(delay);
          _this._tooltipInstance['_changeDetectorRef'].detectChanges();
        };
    }
    public onWheel() {
        if (!this.isUpdatePosition) {
          this.isUpdatePosition = true;
          setTimeout(() => {
            this.updatePosition(this.materialTooltip);
            this.isUpdatePosition = false;
          });
        }
    }
    public updatePosition(materialTooltip: MdTooltip) {
        if (materialTooltip['_overlayRef']) {
          materialTooltip['_overlayRef'].updatePosition();
        }
    }
}