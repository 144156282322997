import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICrud, ISearchable } from '../interfaces';
import { Observable, Subscription } from 'rxjs';
import { ReadOnlyService } from '../read-only/read-only.service';
import {ModuleNames} from '../values/module-names.const';
import {NavigationService} from '../services/navigation.service';
import { NavbarStateService } from '../services/navbar.service';
import { PASSPORT_STATUS_CONST } from '../../entities/passport/values/passport-status.const';
import { debounceTime, distinctUntilChanged, flatMap, tap } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'custom-search',
  templateUrl: 'custom-search.component.html'
})
export class CustomSearchComponent implements OnInit, OnDestroy {

  @Input() public preset: ISearchable;
  @Input() public service: ICrud;
  @Input() public label: string;
  @Input() public listener?: EventEmitter<any>;
  @Input() public touchHandler?: EventEmitter<any>;
  @Input() public filters?: {[key: string]: any};
  @Input() public autoCreation?: boolean;
  @Input() public hasAdvancedResearch: boolean;
  @Input() public caption: string = 'Name';
  @Input() public autoComplete: string = 'on';
  @Input() public CustomName: Function;
  @Input() public isCreateNewButtonShown: boolean = true;
  @Input() public showInitName = true;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onCreate: EventEmitter<any> = new EventEmitter();
  @Output() onChangeName: EventEmitter<any> = new EventEmitter();
  @Output() onOpenAdvancedResearch: EventEmitter<any> = new EventEmitter();
  @Output() onClear: EventEmitter<void> = new EventEmitter();

  @Input() public required = false;
  @Input() public disabled = false;

  @ViewChild('nameInput') nameInput: ElementRef;
  records: ISearchable[] = [];
  control: FormControl;
  selected: ISearchable;
  isFocus: boolean = false;
  events: Observable<any>;
  subscription: Subscription;
  parentModule: string;
  constructor(
              public stateService: NavbarStateService,
              @Optional() private readOnlyService: ReadOnlyService,
              private _navigationService: NavigationService) {
                this.parentModule = this._navigationService.getModuleName();
              }

  syncControlValidation() {
    this.control.setValue(this.control.value);
    // TODO: use an asyncgronous validator here for 'required' to work properly
  }

  ngOnInit() {
    const name = (this.preset && this.showInitName) ? this.preset.name : '';
    if (this.preset && this.preset.id > 0) {
      this.selected = {
        id: this.preset.id,
        name: name
      };
    } else {
      this.selected = {id: 0, name: ''};
    }

    this.control = new FormControl(
      {
        disabled: this.disabled,
        value: name
      },
      this.required ? () => this.checkIfExist() : () => null
    );

    if (this.readOnlyService && this.readOnlyService.readOnly) {
      return;
    }

    this.events = this.control.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(value => {
        if (!value) {
          this.onClear.emit();
        }
      }),
      flatMap(keyword => this.fetchResults(keyword))
    );
    

    this.subscription = new Subscription();
    this.subscription.add(this.events.subscribe((data: ISearchable[]) => this.onResultsReceived(data)));
    if (this.listener) {
      this.subscription.add(this.listener.subscribe((data: any) => this.onListen(data)));
    }
    if (this.touchHandler) {
      this.subscription.add(this.touchHandler.subscribe(() => {
        const touch = () => {
          this.control.markAsTouched();
          this.control.updateValueAndValidity();
        };
        if (!this.records.length && !!this.control.value) {
          this.updateRecordsSilently().subscribe(() => touch());
        } else {
          touch();
        }
      }));
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  fetchResults(keyword:string):Observable<ISearchable[]> {
    const defaultFilters = {search: keyword };
    let request: any = this.filters ? Object.assign(this.filters, defaultFilters) : defaultFilters;
    if (this.stateService.multilanguage) {
      request.english = true;
    }
    if (
      this.parentModule === ModuleNames.SALES) {
        //this.parentModule === ModuleNames.AGENT ||
      // request all manufacturers' passports as well
      request.supervisor = 1;
    }
    return this.service.search(request);
  }

  private updateRecordsSilently() {
    return this.fetchResults(this.control.value).map((data: ISearchable[]) => {
      this.records = data;
    });
  }


  selectItem(item: ISearchable) {
    this.selected = item;
    this.service.view(item.id, {expand: this.service.relations.join(',')}).subscribe((res: any) => {
      this.onSelect.emit(res);
    });
  }

  checkIfExist() {
    return this.isUnique() ? {validateRequireSelection: false} : null;
  }

  isUnique(): boolean {
    if (this.CustomName) {
      return !Boolean(this.records.find((item) => this.CustomName(item) === this.control.value));
    }
    return !Boolean(this.records.find((item) => item.name === this.control.value));
  }

  getName(item: ISearchable): string {
    if (this.CustomName) {
      return this.CustomName(item);
    }
    return item.name;
  }

  onListen(data: ISearchable) {
    console.log('CustomSearch -> OnListen', data);
    this.selected = {
      id: data.id || 0,
      name: data.name || ''
    };
    this.control.setValue(this.selected.name);

    if (this.selected.id) {
      let found = this.records.find((record) =>
        Number(record.id) === Number(this.selected.id)
      );
      if (!found) {
        this.records.push(this.selected);
        this.syncControlValidation();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preset && changes.preset.currentValue) {
        const name = changes.preset.currentValue.name;
        this.selected = {
            id: changes.preset.currentValue.id,
            name: name
        };
        if(this.control)
          this.control.setValue(name);
    }
  }


  onResultsReceived(data: ISearchable[]) {
    this.onChangeName.emit(this.control.value);
    this.records = data;
    this.syncControlValidation();
  }

  onBlur() {
    console.log('CustomSearch -> OnBlur');
    setTimeout(() => {
      if (this.isFocus && this.autoCreation && this.isUnique()) {
        console.log('CustomSearch -> OnBlur -> onCreate');
        this.onCreate.emit(this.control.value);
      }
      this.isFocus = false;
    }, 300);
  }

  onFocus() {
    if (this.readOnlyService && this.readOnlyService.readOnly) {
      return;
    }
    this.isFocus = true;
    this.fetchResults(this.control.value).subscribe((data: ISearchable[]) => this.onResultsReceived(data));
  }

  public focus() {
    if (!this.nameInput || !this.nameInput.nativeElement) {
      return;
    }
    this.nameInput.nativeElement.focus();
  }
}
