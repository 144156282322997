import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs/Observable';
import { Searchable } from '../models/searchable.class';
import { User } from '../models/user.class';
import {ISiteRequestParamsCommon} from '../../dashboards/admin/interfaces/site-request-params/site-request-params-commons.interface';

interface RoleData {
  role: number;
  parent_id: number;
}

@Injectable()
export class UserService extends CrudService {
  protected namespace = 'users';
  protected namespaceSingular = 'user';
  protected ModelClass = User;

  public getWithPaginationData(query?: any): Observable<any | Response> {
    return this
      .sendGet(this.getEndpoint(this.namespace), {search: query})
      .catch(this.onError.bind(this));
  }
  public create<T>(data: T): Observable<any | T> {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/new'), data)
      .map((res: Response) => this.buildModel<T>(res.json()))
      .catch(this.onError.bind(this));
  }

  public changePassword(userId: number, password: string, oldPassword?: string): Observable<any> {
    return this
      .sendPut(this.getEndpoint(this.singularEndpoint + '/' + userId + '/password'), {password: password, old_password: oldPassword})
      .catch(this.onError.bind(this));
  }

  public createCompany(data: any) {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/company'), data)
      .map((res: Response) => this.buildModel<User>(res.json()))
      .catch(this.onError.bind(this));
  }

  public updateCompany(companyId: number, data: any) {
    return this
      .sendPut(this.getEndpoint('company/' + companyId), data)
      .catch(this.onError.bind(this));
  }


  public loginAs<T>(userId: number): Observable<any | T> {
    console.log("Fichier de login 2");
    console.log(this.getEndpoint(this.singularEndpoint + '/' + userId + '/login_as'));
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/' + userId + '/login_as'))
      .catch(this.onError.bind(this));
  }

  public resendActivation<T>(userId: number): Observable<any | T> {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/' + userId + '/resend'))
      .catch(this.onError.bind(this));
  }

  public deleteNoTransfer<T>(userId: number): Observable<any | T> {
    return this.sendDelete(this.getEndpoint(this.singularEndpoint + '/' + userId)).map((res: Response) => res.ok)
  }


  public deleteAndTransferUser(userId: number, userIdForTransferring: number): Observable<any> {
    return this.transfer(userId, userIdForTransferring)
      .flatMap((successfull: boolean) => {
        return successfull
          ? this.sendDelete(this.getEndpoint(this.singularEndpoint + '/' + userId)).map((res: Response) => res.ok)
          : Observable.of(false);
      })
      .catch(this.onError.bind(this));
  }

  public assignRole<T>(userId: number, data: RoleData): Observable<any | Response> {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/' + userId + '/assign_role'), data)
      .catch(this.onError.bind(this));
  }

  public removeRole<T>(userId: number, data: RoleData): Observable<any | Response> {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/' + userId + '/revoke_role'), data)
      .catch(this.onError.bind(this));
  }

  public updateRoleParent<T>(userId: number, role: RoleData): Observable<any | T> {
    return this.removeRole(userId, role)
      .flatMap((data) => this.assignRole(userId, role))
      .catch(this.onError.bind(this));
  }


  public getListSupervisors<T>(query?: any): Observable<T[]> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '/list_supervisors'), {search: query})
      .map((res: Response) => res.json().map((item: T) => new Searchable(item)))
      .catch(this.onError.bind(this));
  }

  public getCompanyList<T>(query?: any): Observable<T[]> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '/company_names'), {search: query})
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }

  public getListDepositOwners<T>(query?: any): Observable<T[]> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '/list_deposit_owners'), {search: query})
      .map((res: Response) => res.json().map((item: T) => new Searchable(item)))
      .catch(this.onError.bind(this));
  }

  public getListAgents<T>(query?: any): Observable<T[]> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '/agents'), {search: query})
      .map((res: Response) => res.json().map((item: T) => new User(item)))
      .catch(this.onError.bind(this));
  }

  public getUsersForTransfer<T> (id: number): Observable<T[]> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '/' + id + '/transferable'))
      .map((res: Response) => res.json().map((item: T) => new User(item)))
      .catch(this.onError.bind(this));
  }

  public getAvatarUploadUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/avatar');
  }

  public deleteAvatar<T>(id: number): Observable<any | T> {
    return this
      .sendDelete(this.getEndpoint('user-avatar/' + id))
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public getRoleCounters<T>(query?: any): Observable<T[]> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '/counters'), {search: query})
      .map((response:Response) => response.json()).catch(this.onError.bind(this));
  }

  public transfer(transferFromId: number, transferToId: number) {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/transfer'), {
        transfer_from: transferFromId,
        transfer_to: transferToId,
      })
      .map((res: Response) => {
        if(res.status === 404) {
          console.error('TRANSFER METHOD IS NOT AVAILABLE!');
          return false;
        }

        return res.ok;
      })
      .catch(this.onError.bind(this));
  }

  public deleteWholeCompany(id: number, command: string): Observable<any | Response> {
    return this
      .sendPost(this.getEndpoint('company/remove/' + id), {
        command: command
      })
      .catch(this.onError.bind(this));
  }

  public getCompanyFileUploadUrl(id: number) {
    return this.getEndpoint('company-file/create?company_id=' + id);
  }

  public sendRequest(params: ISiteRequestParamsCommon) {
    return this.sendPost(this.getEndpoint(this.namespaceSingular + '/request'), params)
      .catch(this.onError.bind(this));
  }
}
