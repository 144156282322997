import * as tslib_1 from "tslib";
import { EntityResolver } from '../../../../shared/resolvers/entity.resolver';
import { UserService } from '../../../../shared/services/user.service';
import { Resolve } from '@angular/router';
var EditUserResolver = /** @class */ (function (_super) {
    tslib_1.__extends(EditUserResolver, _super);
    function EditUserResolver(_service) {
        var _this = _super.call(this) || this;
        _this._service = _service;
        _this.query = { 'expand': 'roles_with_parent' };
        return _this;
    }
    return EditUserResolver;
}(EntityResolver));
export { EditUserResolver };
