/**
 * Created by Aleksandr C. on 9.02.17.
 */

import { Injectable } from '@angular/core';
import { Passport } from '../models/passport.class';
import { FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { PassportDistributionArea } from '../models/passport-distribution-area.class';

@Injectable()
export class PassportService extends ExtendedCrudService {
  protected namespace = 'passports';
  protected namespaceSingular = 'passport';
  protected ModelClass = Passport;
  public relations = ['category'];

  private pagenr:number;


  getLogoUploader(id: number): FileUploader {
    return new FileUploader({url: this.getEndpoint(this.singularEndpoint + '/' + id + '/logo')});
  }

  getCreatorIds(query?: any): Observable<any> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '/creators'), {search: query})
      .catch(this.onError.bind(this));
  }

  public supercopy<T>(id: number, data?: any): Observable<any | T> {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/supercopy'), data)
      .map((res: Response) => this.buildModel<T>(res.json()))
      .catch(this.onError.bind(this));
  }

  public savePassportDistributionArea<T>(passportId: number, data?: any): Observable<any | T> {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/' + passportId + '/distribution-areas'), data)
      .map((res: Response) => this.buildModel<T>(res.json()))
      .catch(this.onError.bind(this));
  }

  getWaiting(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/unchecked'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getPublished(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/published'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getCategoriesList(): Observable<string[]> {
    return this.sendGet(this.getEndpoint(this.namespaceSingular + '/categories'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  removeLogo(id:number) {
    return this.sendDelete(this.getEndpoint(`${this.namespaceSingular}/${id}/logo`))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  setListPage(pagenr:number){
    this.pagenr=pagenr;
  }

  getListPage(){
    return this.pagenr;
  }

  openBannedComponentsPdf(language: string) {
    const url = 'https://storage.googleapis.com/upc-assets/Banned%20lists%20c2c' + language.toUpperCase() + '%20Banned%20list%20c2c.pdf';
    window.open(url);
  }

  createPassport(passport, parentModule, passportType?) {
    switch(parentModule) {
      case 'manufacturer':
        return this.checkSpecialAdmin(passport, passportType)
      default:
        return this.createLocal(passport);
    }
  }


  checkSpecialAdmin(data: any, type: any) : Observable<any> {
    switch(type) {
      case 'GENERIC':
        return this.createGeneric(data);
      case 'MANUFACTURER':
      default:
        return this.createForManufacturer(data);
    }
  }
  createForManufacturer(data: any) : Observable<any> {
    return this
      .sendPost(this.getEndpoint(this.namespaceSingular + '/create_manufacturer'), data)
      .map((res: Response) => this.buildModel<Passport>(res.json()))
      .catch(this.onError.bind(this));
  }

  createGeneric(data: any) : Observable<any> {
    return this
      .sendPost(this.getEndpoint(this.namespaceSingular + '/create_generic'), data)
      .map((res: Response) => this.buildModel<Passport>(res.json()))
      .catch(this.onError.bind(this));
  }

  createLocal(data:any) : Observable<any> {
    return this
      .sendPost(this.getEndpoint(this.namespaceSingular + '/create_local'), data)
      .map((res: Response) => this.buildModel<Passport>(res.json()))
      .catch(this.onError.bind(this));
  }

  public duplicateNoah(id: number): Observable<any> {
    return this.sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/copy_noah'))
      .map((res: Response) => this.buildModel<Passport>(res.json()))
      .catch(this.onError.bind(this));
  }

  /**
   * Check whether the passport has a corresponding external passport according to its synchronization data
   * If there is one, receive true
   * Else, receive false
   * @param {object} data - The data of the passport to check
   * @returns {boolean}
   */
  public checkExternalPassport(data: Object): Observable<any> {
    return this.sendPost(this.getEndpoint(this.singularEndpoint + '/check-external-passport'), data)
      .map((res: Response) => {
        return res.json();
      })
      .catch(this.onError.bind(this));
  }

  /**
   * Synchronize the current passport with the external passport corresponding to the data sent in the payload
   * @param {object} data - The necessary data to synchronize
   * @returns {number}
   */
  public synchronizePassport(data: Object): Observable<any> {
    return this.sendPost(this.getEndpoint(this.singularEndpoint + '/synchronize'), data)
      .map((res: Response) => {
        return res.json();
      })
      .catch(this.onError.bind(this));
  }

  /**
   * Desynchronize the current passport
   * @param {object} data - The necessary data to desynchronize
   * @returns {number}
   */
  public desynchronizePassport(data: Object): Observable<any> {
    return this.sendPost(this.getEndpoint(this.singularEndpoint + '/desynchronize'), data)
      .map((res: Response) => {
        return res.json();
      })
      .catch(this.onError.bind(this));
  }

  /**
   * Check if passport is the last version of its family
   * @param {object} data - The data of the passport
   * @returns {boolean}
   */
  public isLastVersion(data: object): Observable<any> {
    return this.sendPost(this.getEndpoint(this.singularEndpoint + '/is-last-version'), data)
      .map((res: Response) => {
        return res.json();
      })
  }

  /**
   * Fill the save the passport synchronization data
   * @param {Object} data - The data to fill the synchronization data with
   * @returns {string}
   */
  public savePassportSynchronizationData(data): Observable<any> {
    return this.sendPost(this.getEndpoint(this.singularEndpoint + '/save-synchronization-data'), data)
      .map((res: Response) => {
        return res.json();
      })
  }

  /**
   * Trigger the saving of the passport in the backend
   * /!\ Function temporarily created to avoid the flow of passport saving, should not remain for too long /!\
   * @param {Object} data - The passport to save
   */
  public savePassportManually(data: Object) {
    return this.sendPost(this.getEndpoint(this.singularEndpoint + '/save-passport-manually'), data)
    .map((res: Response) => {
      return res.json();
    })
  }
}
