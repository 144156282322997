import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {PERFORMANCE_RADIOS, PERFORMANCE_RADIOS_VALUES} from '../../../values/performance-radios.const';
import {Passport} from '../../../models/passport.class';
import {
  EPassportCertificationVersion,
  PASSPORT_CERTIFICATION_LEVELS,
  PASSPORT_CERTIFICATION_VERSION_VALUE
} from '../../../values/passport-certification-levels.const';
import moment from 'moment';
import {DEFAULT_RADIO_VALUES} from '../../../values/default-radio-values.const';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {VIEW_MODE} from '../../../values/view-mode-values.const';
import {Observable} from 'rxjs';
import {EPassportTabFormGroup, PASSPORT_CONTROLS_WITH_VALIDATION} from '../../../values/passport-validation-fields.const';
import {PassportGroupBuilderService} from '../../../services/passport-group-builder/passport-group-builder.service';
import {setTextareaHeightToFitContent} from '../../../../../shared/helpers/set-textarea-height-to-fit-content';
import {PassportCustomStoreService} from '../../../services/passport-custom-store.service';
import { DocumentTypeInputListComponent } from './document-type-input-list.component';
import { SourceInputListComponent } from './source-input-list.component';
import { OperatorInputListComponent } from './operator-input-list.component';

export interface  ITabType {
  MATERIAL_HEALTH: boolean;
  LIFE_CYCLE: boolean;
  CARBON_FOOTPRINT: boolean;
  WATER_STEWARDSHIP: boolean;
  ENERGY: boolean;
  SOCIAL_FAIRNESS: boolean;
}

@Component({
    moduleId: module.id,
    selector: 'passport-social-performance',
    templateUrl: 'passport-social-performance.component.html',
    styleUrls: ['./passport-social-performance.component.scss'],
  })
  export class PassportSocialPerformanceComponent implements OnInit, OnDestroy {
      @Input() passport: Passport;
      @Input() isLongVersionActive: boolean;
      @Input() asterisk: string;
      @Input() listener: EventEmitter<any>;
      @Input() areFormControlsDisabled: boolean;
      @Input() disabled: boolean;
      @Input() viewMode: number;
      @Input() parentFormGroup: FormGroup;

      @Output() onUpdatePassportData = new EventEmitter<{request: Observable<any>, type: string}>();
      @Output() onElementWhichNotifiesClick = new EventEmitter();
      @ViewChild('lifeCycleDescription', { read: ElementRef }) lifeCycleDescriptionTextarea: ElementRef;
      @ViewChild('effortsForRenewableEnergy', { read: ElementRef }) effortsForRenewableEnergyTextarea: ElementRef;

      tabTypes: ITabType = { MATERIAL_HEALTH: false,
        LIFE_CYCLE: false,
        CARBON_FOOTPRINT: false,
        WATER_STEWARDSHIP: false,
        ENERGY: false,
        SOCIAL_FAIRNESS: false};

      VIEW_MODE = VIEW_MODE;
      certificationLevels = PASSPORT_CERTIFICATION_LEVELS;
      certificationVersion = PASSPORT_CERTIFICATION_VERSION_VALUE;
      performanceRadios = PERFORMANCE_RADIOS;
      defaultRadioValues = DEFAULT_RADIO_VALUES;
      PASSPORT_CONTROLS_WITH_VALIDATION = PASSPORT_CONTROLS_WITH_VALIDATION;
      textTranslatedLevel;
      defaultControlsName = [
        'waterCycle', 'waterProduct', 'waterConstruction', 'waterUse', 'waterOperationalEnergy', 'waterOperationalWater', 'waterEndOfLife', 'waterResourceRecovery',
        'energyCycle', 'energyProduct', 'energyConstruction', 'energyUse', 'energyOperationalEnergy', 'energyOperationalWater', 'energyEndOfLife', 'energyResourceRecovery',
        'energyCycleNone', 'energyProductNone', 'energyConstructionNone', 'energyUseNone', 'energyOperationalEnergyNone', 'energyOperationalWaterNone', 'energyEndOfLifeNone', 'energyResourceRecoveryNone'
      ];
      controlNames: {[key in 
        'waterCycle' | 'waterProduct' | 'waterConstruction' | 'waterUse' | 'waterOperationalEnergy' | 'waterOperationalWater' | 'waterEndOfLife' | 'waterResourceRecovery' |
        'energyCycle' | 'energyProduct' | 'energyConstruction' | 'energyUse' | 'energyOperationalEnergy' | 'energyOperationalWater' | 'energyEndOfLife' | 'energyResourceRecovery' |
        'energyCycleNone' | 'energyProductNone' | 'energyConstructionNone' | 'energyUseNone' | 'energyOperationalEnergyNone' | 'energyOperationalWaterNone' | 'energyEndOfLifeNone' | 'energyResourceRecoveryNone'
      ]: string} = this.defaultControlsName.reduce((accumulator, item) => {
            accumulator[item] = item;
            return accumulator;
          }, {}) as any;
      certificationLevelInputs: {label: () => string, model?: keyof Passport , formControlName: string}[] = [
        {
          label: () => `${this.translate.instant(this.textTranslatedLevel)} « Material Health »`,
          formControlName: 'certificationLevelMaterialHealth',
          model: 'cert_material_health_level'
        }, {
          label: () => `${this.translate.instant(this.textTranslatedLevel)} « Material Reuse » / « Product Circularity »`,
          formControlName: 'certificationLevelMaterialReuse',
          model: 'cert_material_reuse_level'
        }, {
          label: () => `${this.translate.instant(this.textTranslatedLevel)} « Renewable Energy & Carbon Management » `
            + `/ « Clean Air & Climate Protection »`,
          formControlName: 'certificationLevelCarbonManagement',
          model: 'cert_carbon_level'
        }, {
          label: () => `${this.translate.instant(this.textTranslatedLevel)} « Water Stewardship » / « Water & Soil Stewarship »`,
          formControlName: 'certificationLevelWaterStewardship',
          model: 'cert_water_level'
        }, {
          label: () => `${this.translate.instant(this.textTranslatedLevel)} « Social Fairness »`,
          formControlName: 'certificationLevelSocialFairness',
          model: 'cert_socials_level'
        }
      ];
      private cycleText;
      private constructionText;
      private productText;
      private carbonProductText;
      private carbonConstructionText;
      private carbonEndOfLifeText;
      private endOfLifeText;
      private useStageText;
      private operationalEnergyUseText;
      private resourceRecoveryStateText;
      private operationalWaterUseText;
      carbonFootprintInput: { label: () => string, formControlName: string, column: 'left' | 'right' }[] = [
        {
          label: () => this.cycleText,
          formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_TOTAL,
          column: 'left'
        },
        {
          label: () => this.productText,
          formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_PRODUCT,
          column: 'left'
        },
        {
          label: () => this.useStageText,
          formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.USE_STAGE,
          column: 'left'
        },
        {
          label: () => this.operationalWaterUseText,
          formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.OPERATIONAL_WATER_USE,
          column: 'left'
        },
        {
          label: () => this.resourceRecoveryStateText,
          formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.RESOURCE_RECOVERY_STAGE,
          column: 'left'
        },
        {
          label: () => this.carbonConstructionText,
          formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_CONSTRUCTION,
          column: 'right'
        },
        {
          label: () => this.operationalEnergyUseText,
          formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.OPERATIONAL_ENERGY_USE,
          column: 'right'
        },
        {
          label: () => this.carbonEndOfLifeText,
          formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_END,
          column: 'right'
        }
      ];          
      private waterText;
      passportWaterStewardshipInputs: {
        label: () => string, 
        labelDescription: () => string, 
        formControlName: string, 
        model?: keyof Passport, 
        inShort: boolean, 
        column: 'left' | 'right'
      }[] = [
        {
          label: () => this.waterText,
          labelDescription: () => this.cycleText,
          formControlName: this.controlNames.waterCycle,
          model: 'water_cycle',
          inShort: false,
          column: 'left'
        }, {
          label: () => this.waterText,
          labelDescription: () => this.productText,
          formControlName: PASSPORT_CONTROLS_WITH_VALIDATION.WATER_CONVERSION,
          model: 'water_conversion',
          inShort: true,
          column: 'left'
        }, {
          label: () => this.waterText,
          labelDescription: () => this.constructionText,
          formControlName: this.controlNames.waterConstruction,
          model: 'water_construction',
          inShort: false,
          column: 'right'
        }, {
          label: () => this.waterText,
          labelDescription: () => this.useStageText,
          formControlName: this.controlNames.waterUse,
          model: 'water_use_stage',
          inShort: false,
          column: 'left'
        }, {
          label: () => this.waterText,
          labelDescription: () => this.operationalEnergyUseText,
          formControlName: this.controlNames.waterOperationalEnergy,
          model: 'water_operational_energy_use',
          inShort: false,
          column: 'right'
        }, {
          label: () => this.waterText,
          labelDescription: () => this.operationalWaterUseText,
          formControlName: this.controlNames.waterOperationalWater,
          model: 'water_operational_water_use',
          inShort: false,
          column: 'left'
        }, {
          label: () => this.waterText,
          labelDescription: () => this.endOfLifeText,
          formControlName: this.controlNames.waterEndOfLife,
          model: 'water_end_of_life',
          inShort: true,
          column: 'right'
        }, {
          label: () => this.waterText,
          labelDescription: () => this.resourceRecoveryStateText,
          formControlName: this.controlNames.waterResourceRecovery,
          model: 'water_resource_recovery',
          inShort: false,
          column: 'left'
        }
      ];
      
    private renewableEnergyText;
    private noneRenewableEnergyText;
    passportEnergyInputGroups: { label: () => string,
      inputs: {
        label: () => string
      , formControlName?: string
      , classList?: string[]
      , model?: keyof Passport }[] }[] = [
      {
         label: () => this.cycleText,
         inputs: [{
          label: () => this.renewableEnergyText,
          formControlName: this.controlNames.energyCycle,
          model: 'energy_cycle_renewable'
         }, {
          label: () => this.noneRenewableEnergyText,
          formControlName: this.controlNames.energyCycleNone,
          model: 'energy_cycle'
         }]
      },
      {
        label: () => this.productText,
        inputs: [{
          label:  () => this.renewableEnergyText,
          formControlName: this.controlNames.energyProduct,
          model: 'energy_product_renewable'
        }, {
          label: () => this.noneRenewableEnergyText,
          formControlName: this.controlNames.energyProductNone,
          model: 'energy_product'
        }]
      },
      {
        label: () => this.constructionText,
        inputs: [{
          label:  () => this.renewableEnergyText,
          formControlName: this.controlNames.energyConstruction,
          model: 'energy_construction_renewable'
         }, {
          label:  () => this.noneRenewableEnergyText,
          formControlName: this.controlNames.energyConstructionNone,
          model: 'energy_construction'
         }]
      },
      {
         label: () => this.useStageText,
         inputs: [{
           label: () => this.renewableEnergyText,
           formControlName: this.controlNames.energyUse,
           model: 'energy_use_stage_renewable'
         }, {
           label: () => this.noneRenewableEnergyText,
           formControlName: this.controlNames.energyUseNone,
           model: 'energy_use_stage'
         }]
      },
      {
         label: () => this.operationalEnergyUseText,
         inputs: [{
           label: () => this.renewableEnergyText,
           formControlName: this.controlNames.energyOperationalEnergy,
           model: 'energy_operational_energy_use_renewable'
         }, {
           label: () => this.noneRenewableEnergyText,
           formControlName: this.controlNames.energyOperationalEnergyNone,
           model: 'energy_operational_energy_use'
         }]
      },
      {
         label: () => this.operationalWaterUseText,
         inputs: [{
           label: () => this.renewableEnergyText,
           formControlName: this.controlNames.energyOperationalWater,
           model: 'energy_operational_water_use_renewable'
         }, {
           label: () => this.noneRenewableEnergyText,
           formControlName: this.controlNames.energyOperationalWaterNone,
           model: 'energy_operational_water_use'
         }]
      },
      {
         label: () => this.endOfLifeText,
         inputs: [{
           label: () => this.renewableEnergyText,
           formControlName: this.controlNames.energyEndOfLife,
           model: 'energy_end_of_life_renewable'
         }, {
           label: () => this.noneRenewableEnergyText,
           formControlName: this.controlNames.energyEndOfLifeNone,
           model: 'energy_end_of_life'
         }]
      },
      {
         label: () => this.resourceRecoveryStateText,
         inputs: [{
           label: () => this.renewableEnergyText,
           formControlName: this.controlNames.energyResourceRecovery,
           model: 'energy_resource_recovery_renewable'
         }, {
           label: () => this.noneRenewableEnergyText,
           formControlName: this.controlNames.energyResourceRecoveryNone,
           model: 'energy_resource_recovery'
         }]
      },
  ];
      PASSPORT_CERTIFICATE_TYPE:
        {[key in 'GLOBAL' | 'MATERIAL_HEALTH']: {additionalLevels: boolean, isGlobal: boolean}} = {
          GLOBAL: {
            additionalLevels: true, isGlobal: true
          },
          MATERIAL_HEALTH: {
            additionalLevels: false, isGlobal: false
          }
      };
      formGroup: FormGroup;


      constructor(private translate: TranslateService,
        public _fb: FormBuilder,
        private passportGroupBuilderService: PassportGroupBuilderService,
        @Optional() private passportChildComponentsEventListenerService: PassportCustomStoreService) {}

      ngOnInit() {
          // @ts-ignore
        this.formGroup = this.passportGroupBuilderService
          .buildSubGroup(EPassportTabFormGroup.PERFORMANCE, this.passport, this.parentFormGroup)
          .addControlOptions({
            certificationVersion: [this.passport.cert_version || EPassportCertificationVersion.VERSION_3],
            // TODO check if Validators.pattern of CARBON_TOTAL, CARBON_PRODUCT should work.
            // TODO Right now Validators.pattern not working.
            [PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_TOTAL]: [this.passport.carbon_total,
              [ Validators.pattern(/^-?\d+(([.,])\d+)?$/) ]],
            [PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_PRODUCT]: [this.passport.carbon_product,
              [ Validators.pattern(/^-?\d+(([.,])\d+)?$/) ]],
            [PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_END]: [this.passport.carbon_end,
              [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            [PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_CONSTRUCTION]: [this.passport.carbon_construction,
              [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            [PASSPORT_CONTROLS_WITH_VALIDATION.USE_STAGE]: [this.passport.use_stage,
              [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            [PASSPORT_CONTROLS_WITH_VALIDATION.OPERATIONAL_ENERGY_USE]: [this.passport.operational_energy_use,
              [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            [PASSPORT_CONTROLS_WITH_VALIDATION.RESOURCE_RECOVERY_STAGE]: [this.passport.resource_recovery_stage,
              [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
            [PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_CONVERSION]: [this.passport.carbon_conversion,
              [Validators.min(0), this.getValidatorIsNotZero()]],
            [PASSPORT_CONTROLS_WITH_VALIDATION.OPERATIONAL_WATER_USE]: [this.passport.carbon_operational_water_use, [Validators.pattern(/^-?\d+(([.,])\d+)?$/)]],
          }).build();
        console.log(this.formGroup);
        this.passportGroupBuilderService.addChildGroupToParent(
         this.parentFormGroup, this.formGroup, EPassportTabFormGroup.PERFORMANCE);
         if (this.passportChildComponentsEventListenerService) {
          this.passportChildComponentsEventListenerService
            .addFormProgrammaticlyAction.emit(
              {controlName: PASSPORT_CONTROLS_WITH_VALIDATION.SOCIAL_PERFORMANCE, control: this.formGroup, skip: true});
        }
        this.textTranslatedLevel = 'Level';
        this.waterText = 'Net use of fresh water';
        this.renewableEnergyText = `Use of renewable energy`;
        this.noneRenewableEnergyText = `Use of non-renewable energy`;
        this.cycleText = 'Total whole life cycle';
        this.productText = 'Production (A1-A3 module)';
        this.carbonProductText = 'Product stage (A1-A3 module)';
        this.constructionText = 'Construction (A4-A5 module)';
        this.carbonConstructionText = 'Construction process stage (A4-A5 module)';
        this.useStageText = 'Use stage (B1-B5 module)';
        this.operationalEnergyUseText = 'Operational energy use (B6 module)';
        this.operationalWaterUseText = 'Operational water use (B7 module)';
        this.endOfLifeText = 'End of life stage (C module)';
        this.carbonEndOfLifeText = 'End of life stage (C1-C4 module)';
        this.resourceRecoveryStateText = 'Reuse - recovery - recycling potential (D module)';
      }

      private getValidatorIsNotZero() {
        return (control: AbstractControl) => {
          let value = control.value;
          if (typeof value !== 'number') {
            value = Number(!!value ? value : undefined);
          }
          return !Number.isNaN(value) && value === 0 ? {message: 'zero value not allowed'} : null;
        };
      }

      private getEpdType() {
        return this.passport && this.passport.epdType;
      }

      private setEpdType(epdType) {
        if (this.passport) {
          this.passport.epdType = epdType;
        }
      }

      private getSource() {
        return this.passport && this.passport.source;
      }

      private setSource(source) {
        if (this.passport) {
          this.passport.source = source;
        }
      }

      private isSourceOther() {
        return this.passport && this.passport.source ? this.passport.source == 99 : false;
      }

      private getOperator() {
        return this.passport && this.passport.operator;
      }

      private setOperator(operator) {
        if (this.passport) {
          this.passport.operator = operator;
        }
      }

      private isOperatorOther() {
        return this.passport && this.passport.operator ? this.passport.operator == 99 : false;
      }

      private isSynchronized() {
        return this.passport && !!this.passport.external_passport_id;
      }

      private getCountry(){
        return this.passport && this.passport.language;
      }

      private setCountry(country){
        if(this.passport){
          this.passport.language = country;
        }
      }

      /**
       * Check whether the synchronized fields (indicators and document info) are editable or not
       * They are editable if the document is not synchronized and the user can edit the passport
       * @returns {boolean}
       */
      private isSynchronizedFieldDisabled() {
        return this.isSynchronized() || this.areFormControlsDisabled;
      }

      ngOnDestroy(): void {
        this.passportGroupBuilderService.unsubscribe(this.formGroup);
      }

      getOptimizationsStrategyPlaceholder() {
          return this.translate.instant('describe an optimization strategy');
      }

      areC2cAdditionalFieldsShown() {
          const certificateIncludesAdditionalInfo = [PERFORMANCE_RADIOS_VALUES.YES, PERFORMANCE_RADIOS_VALUES.MATERIAL_HEALTH];
          return this.passport ? certificateIncludesAdditionalInfo.includes(this.passport.c2c_certified) : false;
      }

      getCertificationType():  {additionalLevels: boolean, isGlobal: boolean} {
        if (this.passport.c2c_certified === PERFORMANCE_RADIOS_VALUES.YES) {
          return this.PASSPORT_CERTIFICATE_TYPE.GLOBAL;
        } else {
          return this.PASSPORT_CERTIFICATE_TYPE.MATERIAL_HEALTH;
        }
      }

      get certificateLevelPlaceholder() {
        return this.getCertificationType().isGlobal ?
          this.translate.instant('Global certification level') : this.translate.instant('Level of certification');
      }

      onInputWhichNotifiesClick($event) {
          this.onElementWhichNotifiesClick.emit($event);
      }
      setCertificationExpirationDate(event) {
          this.passport.certification_expiration = this.getPickerDate(event) || this.passport.certification_expiration;
      }

      private getPickerDate(event) {
        if (event) {
          return moment(event).format('YYYY-MM-DD');
        }
      }

      /**
       * Actually got no clue what isVisible exactly does, but this method was used in the html
       * Will figure out later
       * @returns {boolean}
       */
      isEnergyVisible() {
        return this.isVisible([
          'energyCycle', 'energyCycleNone',
          'energyProduct', 'energyProductNone',
          'energyConstruction', 'energyConstructionNone', 
          'energyUse', 'energyUseNone',
          'energyOperationalEnergy', 'energyOperationalEnergyNone',
          'energyOperationalWater', 'energyOperationalWaterNone',
          'energyEndOfLife','energyEndOfLifeNone',
          'energyResourceRecovery', 'energyResourceRecoveryNone'
        ]);
      }

      isVisible(name: string | string[]) {
        if (!Array.isArray(name)) {
          name = [name];
        }
        return this.passportGroupBuilderService.getVisibility(this.formGroup, name);
      }

      isLongVersion() {
        return !!this.passportGroupBuilderService.getVisibilityLongControlValue(this.formGroup);
      }

      setEndOfValidityForLCA(event) {
        this.passport.carbon_conversion_date = this.getPickerDate(event) || this.passport.carbon_conversion_date;
      }

      isOpen(tabName: keyof  ITabType) {
        return this.tabTypes[tabName];
      }

      changeTab(tabName: keyof  ITabType) {
        this.tabTypes[tabName] = !this.tabTypes[tabName];
      }

      isLongVersionAndTabOpened(tabName: keyof ITabType): boolean {
        return this.isOpen(tabName) && this.isLongVersion();
      }

      isTabContentShown(tabName: keyof ITabType): boolean {
        return this.isOpen(tabName) || !this.isLongVersion();
      }

      expandLifecycleTextArea() {
        setTimeout(() =>
          setTextareaHeightToFitContent([ this.lifeCycleDescriptionTextarea])
        );
      }
      expandEffortsForRenewableEnergyTextArea() {
        setTimeout(() => setTextareaHeightToFitContent([ this.effortsForRenewableEnergyTextarea
        ]));
      }
  }
