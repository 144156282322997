import * as tslib_1 from "tslib";
/**
 * Created by atat on 18/06/2017.
 */
import { EventEmitter, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DepositOrderService } from '../../../ecosystem-manager/services/deposit-order.service';
import { ECOSYSTEM_STEP_STATUS } from '../../../ecosystem-manager/values/ecosystem-step-status.const';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { DepositDetailsDialogComponent } from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { MdDialog } from '@angular/material';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { TranslateService } from '@ngx-translate/core';
var DepositOrderDetailsComponent = /** @class */ (function () {
    function DepositOrderDetailsComponent(_translateService, datePipe, depositOrderService, readOnlyService, mdDialog) {
        this._translateService = _translateService;
        this.datePipe = datePipe;
        this.depositOrderService = depositOrderService;
        this.readOnlyService = readOnlyService;
        this.mdDialog = mdDialog;
        this.validated = new EventEmitter();
        this.unvalidated = new EventEmitter();
        this.rejected = new EventEmitter();
        this.reportedProblem = new EventEmitter;
        this.askedForModification = new EventEmitter;
        this.confirmed = new EventEmitter();
        this.reportProblemModalIsVisible = false;
        this.problemIsNull = false;
        this.modificationModalIsVisible = false;
        this.modificationIsNull = false;
        this.files = [];
    }
    Object.defineProperty(DepositOrderDetailsComponent.prototype, "isOrderConfirmed", {
        get: function () {
            return this.order.status === ECOSYSTEM_STEP_STATUS.WAITING_FOR_CONFIRMATION;
        },
        enumerable: true,
        configurable: true
    });
    DepositOrderDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.readOnlyService.readOnly = this.readOnly;
        this
            .depositOrderService
            .view(this.id, { expand: 'deposit,address,order_passport,creator,ecosystem_file_junction,DO_price,deposit_location,deposit_passport,deposit_passport.composite_name' })
            .subscribe(function (order) {
            _this.order = order;
            if (_this.order.ecosystem_file_junction) {
                _this.files = _this.order.ecosystem_file_junction.map(function (junction) { return junction.ecosystem_file; });
            }
            _this.order.loaded = true;
        });
    };
    DepositOrderDetailsComponent.prototype.updateDate = function (dateString, datePart) {
        var date = new Date(dateString);
        date.setFullYear(datePart.getFullYear());
        date.setMonth(datePart.getMonth());
        date.setDate(datePart.getDate());
        return this.toDateString(date);
    };
    DepositOrderDetailsComponent.prototype.updateTime = function (dateString, datePart) {
        var date = new Date(dateString);
        date.setHours(datePart.getHours());
        date.setMinutes(datePart.getMinutes());
        date.setSeconds(datePart.getSeconds());
        return this.toDateString(date);
    };
    DepositOrderDetailsComponent.prototype.toDateString = function (date) {
        return this.datePipe.transform(date, 'dd-MM-yyyy');
    };
    DepositOrderDetailsComponent.prototype.validate = function () {
        var _this = this;
        this.validated.emit();
        this.order.order_passport.save().subscribe(function () {
            _this.order.validate().subscribe();
        });
    };
    DepositOrderDetailsComponent.prototype.confirm = function () {
        this.confirmed.emit();
        this.order.confirm().subscribe();
    };
    DepositOrderDetailsComponent.prototype.reject = function () {
        var _this = this;
        this.rejected.emit();
        this.order.order_passport.save().subscribe(function () {
            _this.order.reject().subscribe();
        });
    };
    DepositOrderDetailsComponent.prototype.changeVisibilityOfReportProblemModal = function () {
        this.order.problem = '';
        this.reportProblemModalIsVisible = !this.reportProblemModalIsVisible;
    };
    DepositOrderDetailsComponent.prototype.changeVisibilityOfModificationModal = function () {
        this.order.modification = '';
        this.modificationModalIsVisible = !this.modificationModalIsVisible;
    };
    DepositOrderDetailsComponent.prototype.problemChanged = function () {
        this.problemIsNull = !this.order.problem;
    };
    DepositOrderDetailsComponent.prototype.reportProblem = function (id, problem) {
        var _this = this;
        if (!problem) {
            this.problemIsNull = true;
            return;
        }
        this.reportedProblem.emit();
        this.depositOrderService.sendReport(id, problem).subscribe(function () {
            _this.changeVisibilityOfReportProblemModal();
        });
    };
    DepositOrderDetailsComponent.prototype.modificationChanged = function () {
        this.modificationIsNull = !this.order.modification;
    };
    DepositOrderDetailsComponent.prototype.askForModification = function () {
        var _this = this;
        if (!this.order.modification) {
            this.modificationIsNull = true;
            return;
        }
        this.order.askForModification().subscribe(function () {
            _this.askedForModification.emit();
        });
    };
    DepositOrderDetailsComponent.prototype.checkStatusValidated = function () {
        return this.order.status !== ECOSYSTEM_STEP_STATUS.STAND_BY
            && this.order.status !== ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION
            && this.order.status !== ECOSYSTEM_STEP_STATUS.ASK_FOR_MODIFICATION;
    };
    DepositOrderDetailsComponent.prototype.seeDeposit = function (id) {
        event.preventDefault();
        this.mdDialog.open(DepositDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                id: id,
                readOnly: true,
                showValidationButtons: false,
            } }));
    };
    DepositOrderDetailsComponent.prototype.getDepositOrderStatusText = function () {
        return this._translateService.instant(ECOSYSTEM_STEP_STATUS[this.order.status]);
    };
    DepositOrderDetailsComponent.prototype.setRowHeight = function (inputString) {
        var rowLength = 35;
        var rowSize = 1;
        if (!inputString) {
            return rowSize;
        }
        else if (inputString.length < rowLength) {
            return rowSize;
        }
        else {
            rowSize = Math.ceil(inputString.length / rowLength);
            return rowSize;
        }
    };
    return DepositOrderDetailsComponent;
}());
export { DepositOrderDetailsComponent };
