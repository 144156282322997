import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { PassportService } from '../../services/passport.service';
import { Passport } from '../../models/passport.class';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { PASSPORT_STATUS } from '../../values/passport-status.const';
var NewPassportVersionDialog = /** @class */ (function () {
    function NewPassportVersionDialog(dialogData, _dialogRef, _fb, translationService, passportService, notification) {
        this.dialogData = dialogData;
        this._dialogRef = _dialogRef;
        this._fb = _fb;
        this.translationService = translationService;
        this.passportService = passportService;
        this.notification = notification;
        this.passport = new Passport(tslib_1.__assign({}, this.dialogData.passport));
    }
    NewPassportVersionDialog.prototype.ngOnInit = function () {
        this.versionFormGgrp = this._fb.group({
            newVersion: ['', [Validators.pattern('^[0-9]{4}$'), Validators.required]]
        });
    };
    NewPassportVersionDialog.prototype.validate = function () {
        var _this = this;
        var version = this.versionFormGgrp.controls.newVersion.value;
        if (!this.versionFormGgrp.invalid) {
            this.passportService.duplicate(this.passport.id, { name: this.passport.name, version: version, status: PASSPORT_STATUS.DRAFT }, true).subscribe(function (response) {
                if (response.id) {
                    _this._dialogRef.close({ passport: response, clearForm: false });
                }
            });
        }
        else {
            var emptyVersion = "Please enter a date of version";
            var wrontFormat = "Incorrect format : version must be a 4 digit number";
            //  let wrontFormat = "Incorrect format : version must be a 4 digit number"
            //  if (!this.passport.isPublished()) {
            //}
            if (version) {
                this.notification.error(this.translationService.instant(wrontFormat));
            }
            else {
                this.notification.error(this.translationService.instant(emptyVersion));
            }
        }
    };
    NewPassportVersionDialog.prototype.close = function () {
        this._dialogRef.close({ version: this.versionFormGgrp.controls.newVersion.value, clearForm: false });
    };
    return NewPassportVersionDialog;
}());
export { NewPassportVersionDialog };
