<div class="esm-catalog">
  <deposit-need-map [beforeMapUpdate]="beforeUpdateMap" [updateMap]="updateMap"
    (onChangeDepositLocationIds)="setChosenLocationsById($event)">
  </deposit-need-map>
  <header class="esm-catalog__header alone-button">
    <div class="esm-catalog__map">
      <i class="icon icon--export"></i>
      <a [href]="downloadPdfReport()" class="pdf-link esm-catalog__map-view" target="_blank">{{ 'Export' | translate
        }}</a>
    </div>
  </header>
  <header class="esm-catalog__header checkbox header--title-group">
    <div *ngFor="let status of filterStatuses">
      <md-checkbox class="esm-catalog__group--checkbox checkbox-group" [name]="status.text" (change)="loadDeposits();"
        [(ngModel)]="status.checked">
        {{(status.depositStatus.view) | capitalize | translate}}
      </md-checkbox>
    </div>
    <md-checkbox class="esm-catalog__group--checkbox checkbox-group" name="OUT_OF_STOCK" [checked]="isOutOffStock"
      (change)="isOutOffStock = !isOutOffStock" (change)="loadDeposits()">
      {{'Out of stock' | translate}}
    </md-checkbox>
  </header>
  <header class="esm-catalog__header">
    <md-radio-group test-id="privacy-filter" class="esm-catalog__group" [(ngModel)]="currentPrivacyFilter"
      (change)="loadDeposits()">
      <md-radio-button class="esm-catalog__group--buttons" *ngFor="let filter of privacyFilterStatuses"
        [attr.name]="filter.view.toUpperCase().replace(' ', '_').replace(' ', '_')" [value]="filter">
        {{filter.view | capitalize | translate}}
      </md-radio-button>
    </md-radio-group>
  </header>
  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input test-id="search" mdInput autocomplete="off" placeholder="{{ 'Search' | translate }}"
            [formControl]="searchControl">
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--people-grey"></i>
        <md-select test-id="do-selector" class="select-group" placeholder="{{ 'Deposit owner' | translate }}"
          [(ngModel)]="depositOwnerId" (ngModelChange)="loadDeposits()">
          <md-option value="">{{'Any' | translate}}</md-option>
          <md-option *ngFor="let depositOwner of depositOwners" [value]="depositOwner.id">{{depositOwner.name}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input test-id="available-date-from-filter" mdInput autocomplete="off" ng2-datetime-picker date-only="true"
            date-format="DD-MM-YYYY" placeholder="{{ 'Available FROM' | translate }}" [(ngModel)]="availableDateFrom"
            (ngModelChange)="loadDeposits()">
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-dash">-</li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input test-id="available-date-to-filter" mdInput autocomplete="off" ng2-datetime-picker date-only="true"
            date-format="DD-MM-YYYY" placeholder="{{ 'Available TO' | translate }}" [(ngModel)]="availableDateTo"
            (ngModelChange)="loadDeposits()">
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--gray-sort"></i>
        <md-select test-id="sort-selector" class="select-group" [(ngModel)]="sortOrder"
          (ngModelChange)="loadDeposits()">
          <md-option value="-created_at">{{ 'By creation date' | translate }}</md-option>
          <md-option value="site_name">{{ 'By site name' | translate }}</md-option>
          <md-option value="name">{{ 'By name' | translate }}</md-option>
          <md-option value="owner_name">{{ 'By deposit owner' | translate }}</md-option>
          <md-option value="-availability_date">{{ 'By date of availability' | translate }}</md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list [records]="records" (onEndOfList)="onEndOfListReached()"></custom-list>
</div>