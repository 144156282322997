import { Component, Input } from '@angular/core';
import { TRANSFORM_WITH_WATER_CONSUMPTION } from '../../../values/transform-types.const';
import { PASSPORT_UNITS, PASSPORT_UNITS_TYPE } from '../../../../../entities/passport/values/passport-units.const';
import {Transform} from '../../../../../entities/transformation/models/transform.class';

@Component({
  moduleId: module.id,
  selector: 'input-output',
  templateUrl: 'input-output.component.html',
  styleUrls: ['input-output.component.scss'],
})
export class InputOutputComponent {
  @Input() readOnly: boolean;
  @Input() transform: Transform;

  public units = PASSPORT_UNITS;
  TRANSFORM_WITH_WATER_CONSUMPTION = TRANSFORM_WITH_WATER_CONSUMPTION;

  isConversionShown(type: number): boolean {
    if (!type) {
      return false;
    }
    return type !== PASSPORT_UNITS_TYPE.KG && type !== PASSPORT_UNITS_TYPE.TONNE;
  }

  isWaterIndicatorShown() {
    return this.TRANSFORM_WITH_WATER_CONSUMPTION.includes(this.transform.transformation_type);
  }
}
