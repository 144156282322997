import {EventEmitter, Injectable} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {RequestFile} from '../models/request-file.class';
import {RequestFileService} from './request-file.service';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {first, tap} from 'rxjs/operators';
import {defer, throwError} from 'rxjs';

@Injectable()
export class RequestFileSenderService {
  public onUploadAll = new EventEmitter();
  public onUploadError = new EventEmitter();
  public files = [];
  public fileUploader = new FileUploader({});
  private observable: Observable<any>;
  private _isLoading = false;
  private errorOnUpload = false;
  private callback: (files: RequestFile[]) => any;

  constructor(public service: RequestFileService) { }

  public get isLoading() {
    return this._isLoading;
  }
  public get isEmptyQueue() {
    return !this.fileUploader.queue.length && !this.files.length;
  }

  public uploadRequest(callback: (files: RequestFile[]) => any) {
    this.callback = callback;
    this._isLoading = true;
    if (!!this.fileUploader.getNotUploadedItems().length) {
      this.onUploadAll.emit();
    } else {
      this.callback(this.files);
      this.callback = null;
    }
  }

  public onCompleteItem(file) {
    this.checkIfFileError(file);
  }

  private checkIfFileError(file) {
    this.errorOnUpload = this.errorOnUpload || !file.id;
  }

  public onAllItemsUploaded(files) {
    this._isLoading = false;
    if (this.errorOnUpload) {
      this.errorOnUpload = false;
      this.onUploadError.emit();
      return;
    } else {
      this.callback(files);
      this.callback = null;
    }
  }

  public getDocumentUploadObservable() {
    if (!this.observable) {
      this.observable = this.documentUploadAsObservable();
    }
    return this.observable;
  }

  public documentUploadAsObservable(isCompleteAfterSuccess = true): Observable<any> {
    const observer = new Subject();
    const completeObserver = () => {
        if (isCompleteAfterSuccess) {
          this.observable = null;
          observer.complete();
        }
      };
      const next = () => {
        observer.next({});
        completeObserver();
      };
      const error = () => {
        observer.error(Observable.of({}));
        completeObserver();
      };
      return this.getObservable(observer, next, error);
    }

    private getObservable(observer, next, error) {
      this.onUploadError.pipe(first()).subscribe(error);
      return defer(() => {
        setTimeout(() => this.uploadRequest(next));
        return observer;
      });
    }

  public documentUploadObservableNext(files, isFailed?: boolean) {
    if (isFailed) {
      this.onCompleteItem({});
    }
    this.onAllItemsUploaded(files);
  }
}
