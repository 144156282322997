import { Component, OnInit } from '@angular/core';
import { GoogleMapStyles } from '../../../../shared/config/google-maps-styles';
import { NeedService } from '../../../../entities/need/services/need.service';
import { Need } from '../../../../entities/need/models/need.class';
import { LatLngBounds, LatLngBoundsLiteral, MapsAPILoader } from '@agm/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SORT_MODES } from '../../../../shared/values/sort-modes.const';
import { NEED_STATUSES } from '../../../../entities/need/values/need-statuses.const';

@Component({
  moduleId: module.id,
  templateUrl: 'supervisor-needs.component.html',
  styleUrls: ['supervisor-needs.component.scss'],
  host: {'class': 'supervisor-list'},
})
export class SupervisorNeedsComponent implements OnInit {
  public SORT_MODES = [
    SORT_MODES.NAME,
    SORT_MODES.DATE_OF_CREATION,
    SORT_MODES.DATE_OF_DELIVERY,
  ];
  public FILTER_STATUSES = [
    NEED_STATUSES.WAITING_FOR_VALIDATION,
    NEED_STATUSES.PUBLISHED,
    NEED_STATUSES.TREATED,
    NEED_STATUSES.IN_PROGRESS,
    NEED_STATUSES.DONE,
    NEED_STATUSES.EXPIRED,
    NEED_STATUSES.REJECTED,
  ];

  public mapStyles = GoogleMapStyles;
  public mapBounds: LatLngBounds | LatLngBoundsLiteral = {north: 0, south: 0, east: 0, west: 0};
  public mapsAPILoad: Promise<any>;

  public records: Need[] = [];
  public results: Need[] = [];

  public selectedSortMode = this.SORT_MODES[0];
  public sortOrder = 1;
  public form = new FormGroup({
    searchText: new FormControl(),
    publicationDate: new FormControl(),
    filters: new FormArray(this.FILTER_STATUSES.map(() => new FormControl())),
  });

  constructor(private needService: NeedService,
              private mapsAPILoader: MapsAPILoader) {
    this.mapsAPILoad = this.mapsAPILoader.load();
  }

  ngOnInit() {
    this.needService.get<Need>({
      expand: 'client,client_location,client_contact,nomenclature,passport',
      supervisor: true,
      supervisor_view: true
    }).subscribe((res) => {
      this.records = res.filter(need => need.status !== NEED_STATUSES.DRAFT.value);

      this.mapsAPILoad.then(() => {
        this.form.valueChanges.subscribe(() => {
          this.update();
        });

        this.update();
      });
    });
  }

  sort(sortMode: any) {
    if (this.selectedSortMode === sortMode) {
      this.sortOrder *= -1;
    } else {
      this.selectedSortMode = sortMode;
      this.sortOrder = 1;
    }
    this.update();
  }

  update() {
    this.results = this.getResults(this.records);
    this.mapBounds = this.getMapBounds(this.records);
  }

  getResults(results: Need[]) {
    let searchText = this.form.value.searchText;
    if (searchText) {
      results = results.filter(record =>
        record.includesText(searchText)
      );
    }

    let allowedStatuses = this.FILTER_STATUSES
      .map(STATUS => STATUS.value)
      .filter((_, i) => this.form.value.filters[i])
    ;
    if (allowedStatuses.length) {
      results = results.filter(need =>
        allowedStatuses.includes(need.status)
      );
    }

    let publicationDate = this.form.value.publicationDate;
    if (publicationDate) {
      results = results.filter(record =>
        new Date(record.created_at).toDateString() === new Date(publicationDate).toDateString()
      );
    }

    results.sort(this.selectedSortMode.comparator);

    if (this.sortOrder === -1) {
      results.reverse();
    }

    return results;
  }

  getMapBounds(needs: Need[]): LatLngBounds {
    let bounds: any = new google.maps.LatLngBounds();

    needs.forEach((need) => {
      if (need.client_location.lat && need.client_location.lng) {
        bounds.extend(need.client_location);
      }
    });

    return bounds;
  }
}
