import * as tslib_1 from "tslib";
import { VirginProduct } from '../models/virgin-product.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var VirginProductService = /** @class */ (function (_super) {
    tslib_1.__extends(VirginProductService, _super);
    function VirginProductService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'virgin-products';
        _this.namespaceSingular = 'virgin-product';
        _this.ModelClass = VirginProduct;
        return _this;
    }
    return VirginProductService;
}(ExtendedCrudService));
export { VirginProductService };
