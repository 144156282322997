import { Component, OnInit } from '@angular/core';
import {MdDialogRef} from '@angular/material';

@Component({
  selector: 'app-request-sent-dialog',
  templateUrl: './request-sent-dialog.component.html',
  styleUrls: ['./request-sent-dialog.component.scss']
})
export class RequestSentDialogComponent implements OnInit {

  constructor(public mdDialogRef: MdDialogRef<any>) { }

  ngOnInit() {
  }

}
