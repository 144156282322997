<div class="statistics" test-id="supervisor-statistics">
    <h1>{{'Segment statistics' | translate}}</h1>
    <div class="statistics__box widget--shadow widget--rounded-corners">
        <div class="statistics__header">
            <div class="statistics__tabs">
                <ul>
                    <li
                            *ngFor="let segment of segments"
                            (click)="changeSegment(segment)"
                            [ngClass]="{'active': segment === selectedSegment}"
                    >{{ SUPERVISOR_STATISTICS_SEGMENTS_VIEW[segment] | translate }}</li>
                </ul>
            </div>
        </div>
        <div class="statistics__body">
            <div class="statistics__total-stats-container">
                <div class="statistics__total-stats red" *ngIf="segmentStats.rejected">
                    <div class="group-title"><span>{{'Rejected' | translate}}</span></div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.rejected.total }}</span>
                        <span class="title">{{ 'Total' | translate }}</span>
                    </div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.rejected.last_year }}</span>
                        <span class="title">{{ 'This year' | translate }}</span>
                    </div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.rejected.last_month }}</span>
                        <span class="title">{{ 'This month' | translate }}</span>
                    </div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.rejected.last_week }}</span>
                        <span class="title">{{ 'This week' | translate }}</span>
                    </div>
                </div>
                <div class="statistics__total-stats" *ngIf="segmentStats.characterized">
                    <div class="group-title"><span>{{'Characterized' | translate}}</span></div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.characterized.total }}</span>
                        <span class="title">{{ 'Total' | translate }}</span>
                    </div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.characterized.last_year }}</span>
                        <span class="title">{{ 'This year' | translate }}</span>
                    </div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.characterized.last_month }}</span>
                        <span class="title">{{ 'This month' | translate }}</span>
                    </div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.characterized.last_week }}</span>
                        <span class="title">{{ 'This week' | translate }}</span>
                    </div>
                </div>
                <div class="statistics__total-stats" *ngIf="segmentStats.contracts">
                    <div class="group-title"><span>{{'Contracts' | translate}}</span></div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.contracts.total }}</span>
                        <span class="title">{{ 'Total' | translate }}</span>
                    </div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.contracts.last_year }}</span>
                        <span class="title">{{ 'This year' | translate }}</span>
                    </div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.contracts.last_month }}</span>
                        <span class="title">{{ 'This month' | translate }}</span>
                    </div>
                    <div class="stat">
                        <span class="value">{{ segmentStats.contracts.last_week }}</span>
                        <span class="title">{{ 'This week' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
