/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../read-only/form-elems-hidden.directive";
import * as i2 from "../../read-only/read-only.service";
import * as i3 from "@angular/common";
import * as i4 from "ng2-file-upload/file-upload/file-select.directive";
import * as i5 from "../../read-only/form-elems-read-only.directive";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./logo-uploader.component";
import * as i8 from "../../services/log-notification.service";
var styles_LogoUploaderComponent = [];
var RenderType_LogoUploaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoUploaderComponent, data: {} });
export { RenderType_LogoUploaderComponent as RenderType_LogoUploaderComponent };
function View_LogoUploaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "upload-files__delete"], ["type", "button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_co.file) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.FormElemsHiddenDirective, [[2, i2.ReadOnlyService]], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "icon icon--cross"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((i0.ɵnov(_v, 1).readOnlyService == null) ? null : i0.ɵnov(_v, 1).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0); }); }
function View_LogoUploaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "passport__main-image"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "preview": 0, "upload-files__main-image--preview": 1 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["class", "upload-files__wrapper"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["class", "upload-files__icon"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LogoUploaderComponent_2)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "passport__main-image"; var currVal_1 = _ck(_v, 2, 0, !!_co.preview, !!_co.preview); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = !_co.isDisabled; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.preview; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.preview; _ck(_v, 4, 0, currVal_3); }); }
function View_LogoUploaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "upload-files__upload--logo-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUploadButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "input", [["class", "passport__file-control"], ["id", "logoUploader"], ["name", "preview"], ["ng2FileSelect", ""], ["type", "file"]], [[8, "placeholder", 0], [8, "disabled", 0], [8, "readOnly", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onChange() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i4.FileSelectDirective, [i0.ElementRef], { uploader: [0, "uploader"] }, null), i0.ɵdid(3, 16384, null, 0, i5.FormElemsReadOnlyDirective, [[2, i2.ReadOnlyService]], null, null), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 5, "label", [["class", "passport__upload-link upload-files__upload--link"], ["for", "logoUploader"]], null, null, null, null, null)), i0.ɵdid(6, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(7, { "contain-image": 0 }), i0.ɵdid(8, 278528, null, 0, i3.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.uploader; _ck(_v, 2, 0, currVal_3); var currVal_4 = "passport__upload-link upload-files__upload--link"; var currVal_5 = _ck(_v, 7, 0, !!_co.preview); _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = _co.uploaderStyles(); _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 4).transform("Logo")), ""); var currVal_1 = _co.isDisabled; var currVal_2 = ((i0.ɵnov(_v, 3).readOnlyService == null) ? null : i0.ɵnov(_v, 3).readOnlyService.readOnly); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_7 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("Upload")); _ck(_v, 9, 0, currVal_7); }); }
export function View_LogoUploaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "upload-files__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LogoUploaderComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LogoUploaderComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.preview; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.preview; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LogoUploaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "logo-uploader", [], null, null, null, View_LogoUploaderComponent_0, RenderType_LogoUploaderComponent)), i0.ɵdid(1, 49152, null, 0, i7.LogoUploaderComponent, [i8.LogNotificationService], null, null)], null, null); }
var LogoUploaderComponentNgFactory = i0.ɵccf("logo-uploader", i7.LogoUploaderComponent, View_LogoUploaderComponent_Host_0, { preview: "preview", targetUrl: "targetUrl", notShowClickErrors: "notShowClickErrors", isDisabled: "isDisabled", uploadUrlMissingErrorText: "uploadUrlMissingErrorText", dontUpload: "dontUpload" }, { onLoad: "onLoad", onRemove: "onRemove", onClick: "onClick" }, []);
export { LogoUploaderComponentNgFactory as LogoUploaderComponentNgFactory };
