import { TranslateService } from '@ngx-translate/core';
import { LogNotificationService } from '../../shared/services/log-notification.service';
import { filter, pairwise, startWith } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../shared/services/log-notification.service";
var ImportValidatorsService = /** @class */ (function () {
    function ImportValidatorsService(_translate, logNotificationService) {
        this._translate = _translate;
        this.logNotificationService = logNotificationService;
        this.DUPLICATION_ERROR = 'duplicate';
        this.FIRST_ROW_IS_BIGGER_THAN_LAST = 'firstRowIsBiggerThenLast';
    }
    ImportValidatorsService.prototype.validateDuplicatedColumnNames = function (controlGroup, skipValidationForColumns) {
        if (skipValidationForColumns === void 0) { skipValidationForColumns = ['tab_name', 'first_row', 'last_row', 'id', 'reference_date']; }
        var _a;
        var spreadSheetColumns = Object.keys(controlGroup.controls)
            .filter(function (element) { return !skipValidationForColumns.includes(element); });
        var lookup = spreadSheetColumns.reduce(function (a, e) {
            a[controlGroup.get(e).value.toUpperCase()] = ++a[controlGroup.get(e).value.toUpperCase()] || 0;
            return a;
        }, {});
        return spreadSheetColumns.filter(function (e) {
            var control = controlGroup.get(e);
            if (!!control.value && lookup[control.value.toUpperCase()]) {
                return e;
            }
        }).length ? (_a = {}, _a[this.DUPLICATION_ERROR] = true, _a) : null;
    };
    ImportValidatorsService.prototype.validateDuplicatedColumnNamesAndShowNotification = function (control, controlMessage) {
        var duplicates = this.validateDuplicatedColumnNames(control);
        if (duplicates) {
            this.logNotificationService.error("" + this._translate.instant(controlMessage(duplicates[0])) +
                (": " + this._translate.instant('This column is already used')));
        }
        return duplicates;
    };
    ImportValidatorsService.prototype.clearDuplicatedErrorsAndTouch = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control.getError(_this.DUPLICATION_ERROR)) {
                control.setErrors(null);
            }
            control.markAsTouched({ onlySelf: true });
        });
    };
    ImportValidatorsService.prototype.findFirstControlNameWithRequiredError = function (groupControl, skipControls) {
        if (skipControls === void 0) { skipControls = ['availability_date_field', 'reference_date']; }
        return Object.keys(groupControl)
            .filter(function (field) { return !skipControls.includes(field); })
            .find(function (field) { return groupControl.controls[field].getError('required'); });
    };
    ImportValidatorsService.prototype.findFirstRequiredErrorAndShowMessage = function (control, controlMessage) {
        var controlName = this.findFirstControlNameWithRequiredError(control);
        if (controlName) {
            this.logNotificationService.error(this._translate.instant(controlMessage(controlName)) + " " + this._translate.instant('is required') + ".", null, true);
        }
        return controlName;
    };
    ImportValidatorsService.prototype.validateColumnDuplication = function (control, fields) {
        var usedLetters = this.getColumnLettersWithControls(control, fields);
        return this.validateBaseOnLetterControl(usedLetters, control, fields);
    };
    ImportValidatorsService.prototype.getValuesFromColumnControl = function (controlName, control, fields) {
        var valueRaw = control.value[controlName] || '';
        return this.getValues(valueRaw, !!(fields[controlName] && fields[controlName].multiInput));
    };
    ImportValidatorsService.prototype.getValues = function (valueRaw, multiInput) {
        if (multiInput) {
            return valueRaw.split(',').map(function (item) { return item.toUpperCase().trim(); });
        }
        else {
            return [valueRaw.toUpperCase()];
        }
    };
    ImportValidatorsService.prototype.getColumnLettersWithControls = function (controlGroup, fields) {
        var _this = this;
        var usedLetters = {};
        Object.keys(controlGroup.value).forEach(function (controlName) {
            var valueList = _this.getValuesFromColumnControl(controlName, controlGroup, fields);
            valueList.filter(function (item) { return item; }).forEach(function (item) {
                if (!usedLetters[item]) {
                    usedLetters[item] = [controlName];
                }
                else {
                    usedLetters[item].push(controlName);
                }
            });
        });
        return usedLetters;
    };
    ImportValidatorsService.prototype.prohibitForForm = function (group, fields, validators) {
        var _this = this;
        var validatorsForControl = function (name, fn) { return function (next, prev) { return fn(name, next, prev, group, fields); }; };
        return this.getFormGroupValueChangeObservables(group, (function (controlName) { return !!fields[controlName]; }))
            .map(function (_a) {
            var control = _a.control, name = _a.name;
            return ({
                control: control,
                name: name,
                observable: _this.prohibitForControl(control, name, validators.map(function (rule) { return validatorsForControl(name, rule); }))
            });
        });
    };
    ImportValidatorsService.prototype.prohibitForControl = function (control, name, validators) {
        return control.valueChanges.pipe(startWith(''), pairwise(), filter(function (_a) {
            var prev = _a[0], next = _a[1];
            if (!validators.every(function (fn) { return fn(next, prev); })) {
                control.setValue(prev);
                return false;
            }
            return true;
        }));
    };
    ImportValidatorsService.prototype.getFormGroupValueChangeObservables = function (group, skipControlNames) {
        if (skipControlNames === void 0) { skipControlNames = function (controlName) { return true; }; }
        return Object.keys(group.controls)
            .filter(function (controlName) { return skipControlNames(controlName); })
            .map(function (controlName) { return ({ control: group.get(controlName), name: controlName }); });
    };
    ImportValidatorsService.prototype.prohibitLength = function (length) {
        var _this = this;
        if (length === void 0) { length = 3; }
        return function (controlName, next, prev, control, fields) {
            var isMulti = fields[controlName] && fields[controlName].multiInput;
            return _this.getValues(next, isMulti).every(function (value) { return value.length <= length; });
        };
    };
    ImportValidatorsService.prototype.prohibitNoneLetters = function (controlName, next, prev, control, fields) {
        var isMulti = fields[controlName] && fields[controlName].multiInput;
        return this.getValues(next, isMulti).every(function (value) { return !!value.match(/^[A-Za-z]*$/); });
    };
    ImportValidatorsService.prototype.prohibitDuplicationInput = function (controlName, next, prev, control, fields) {
        return !this.findDuplication(controlName, next, control, fields);
    };
    ImportValidatorsService.prototype.prohibitDuplicationInputAndShowWarning = function (controlName, next, prev, control, fields) {
        var duplication = this.findDuplication(controlName, next, control, fields);
        if (duplication) {
            var field = fields[duplication[0] === controlName ? duplication[1] : duplication[0]].name;
            this.logNotificationService.error(this._translate.instant('This column is already used') + ' : '
                + this._translate.instant(field));
        }
        return !duplication;
    };
    ImportValidatorsService.prototype.findDuplication = function (controlName, next, control, fields) {
        var usedLetters = this.getColumnLettersWithControls(control, fields);
        var isMulti = fields[controlName] && fields[controlName].multiInput;
        var valueDuplication = this.getValues(next, isMulti).find(function (value) { return usedLetters[value] &&
            (usedLetters[value].length > 1
                || usedLetters[value].length === 1 && usedLetters[value][0] !== controlName); });
        return valueDuplication ? usedLetters[valueDuplication] : null;
    };
    ImportValidatorsService.prototype.validateBaseOnLetterControl = function (usedLetters, controlGroup, fields) {
        var _this = this;
        var _a;
        var duplication = false;
        Object.keys(controlGroup.value).forEach(function (controlName) {
            var duplications = _this.getAllDuplicatedControlNameWithCurrentControl(usedLetters, controlName, controlGroup, fields);
            if (duplications.length) {
                _this.setDuplicationError(controlName, duplications, controlGroup);
                duplication = true;
            }
            else {
                _this.deleteDuplicationError(controlName, controlGroup);
            }
        });
        return duplication ? (_a = {}, _a[this.DUPLICATION_ERROR] = duplication, _a) : null;
    };
    ImportValidatorsService.prototype.getAllDuplicatedControlNameWithCurrentControl = function (usedLetters, controlName, controlGroup, fields) {
        var duplications = [];
        var valueList = this.getValuesFromColumnControl(controlName, controlGroup, fields);
        valueList.filter(function (item) { return usedLetters[item] && usedLetters[item].length > 1; })
            .forEach(function (item) { return duplications.push.apply(duplications, usedLetters[item]); });
        return Array.from(new Set(duplications));
    };
    ImportValidatorsService.prototype.setDuplicationError = function (controlNameWithError, sameValueControls, control) {
        var _a;
        this.setErrorTouched(controlNameWithError, (_a = {}, _a[this.DUPLICATION_ERROR] = sameValueControls, _a), control);
    };
    ImportValidatorsService.prototype.setErrorTouched = function (controlNameWithError, error, control) {
        control.get(controlNameWithError).setErrors(error);
        control.get(controlNameWithError).markAsTouched({ onlySelf: true });
    };
    ImportValidatorsService.prototype.deleteDuplicationError = function (controlNameWithError, control) {
        this.deleteError(controlNameWithError, control, this.DUPLICATION_ERROR);
    };
    ImportValidatorsService.prototype.deleteError = function (controlNameWithError, control, error) {
        if (control.get(controlNameWithError).hasError(error)) {
            var errors = control.get(controlNameWithError).errors;
            delete errors[error];
            control.get(controlNameWithError).setErrors(Object.keys(errors).length ? errors : null);
        }
    };
    ImportValidatorsService.prototype.validateNumberControlIsNotLess = function (control, lessControlName, biggerControlName) {
        var _this = this;
        if (lessControlName === void 0) { lessControlName = 'first_row'; }
        if (biggerControlName === void 0) { biggerControlName = 'last_row'; }
        var _a, _b, _c;
        var getNumber = function (controlName) { return _this.getNumberFromNumeric(control.value[controlName]); };
        var _d = [getNumber(lessControlName), getNumber(biggerControlName)], firstNumber = _d[0], last = _d[1];
        var isError = !Number.isNaN(firstNumber) && !Number.isNaN(last) && firstNumber > last;
        if (isError) {
            this.setErrorTouched(lessControlName, (_a = {}, _a[this.FIRST_ROW_IS_BIGGER_THAN_LAST] = true, _a), control);
            this.setErrorTouched(biggerControlName, (_b = {}, _b[this.FIRST_ROW_IS_BIGGER_THAN_LAST] = true, _b), control);
        }
        else {
            this.deleteError(lessControlName, control, this.FIRST_ROW_IS_BIGGER_THAN_LAST);
            this.deleteError(biggerControlName, control, this.FIRST_ROW_IS_BIGGER_THAN_LAST);
        }
        return isError ? (_c = {}, _c[this.FIRST_ROW_IS_BIGGER_THAN_LAST] = true, _c) : null;
    };
    ImportValidatorsService.prototype.getNumberFromNumeric = function (value) {
        return (value || value === 0) ? Number(value) : Number.NaN;
    };
    ImportValidatorsService.prototype.validateRequireOneOfMany = function (control, listOfRequired) {
        var _this = this;
        if (listOfRequired === void 0) { listOfRequired = ['availability_date_field', 'reference_date']; }
        var isNotRequired = listOfRequired.some(function (item) { return !!control.value[item]; });
        if (isNotRequired) {
            listOfRequired.forEach(function (item) { return _this.deleteError(item, control, 'required'); });
        }
        else {
            listOfRequired.forEach(function (item) { return control.get(item).setErrors({ 'required': true }); });
        }
        return !isNotRequired ? { 'requireOneOfMany': true } : null;
    };
    ImportValidatorsService.prototype.preventKeypressIfNotLetter = function (e, regex) {
        var testChar = String.fromCharCode(e.keyCode >= 96 && e.keyCode <= 105 ? e.keyCode - 48 : e.keyCode);
        if (!(regex.test(testChar)) && ![8, 9, 37, 39, 46].some(function (k) { return k === e.keyCode; })) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    ImportValidatorsService.prototype.preventKeypressIfNotLetterOrList = function (e, multi) {
        if (multi === void 0) { multi = false; }
        if (multi && /[\s,]/.test(e.key)) {
            return;
        }
        this.preventKeypressIfNotLetter(e, /[a-z]/i);
    };
    ImportValidatorsService.prototype.isEmptyField = function (control, defaultValues) {
        if (defaultValues === void 0) { defaultValues = {}; }
        return Object.keys(control.value).every(function (key) {
            var val = (control.value[key] || control.value[key] === 0) ? control.value[key] : undefined;
            return defaultValues[key] === val;
        });
    };
    ImportValidatorsService.prototype.parseColumnsToModel = function (columnForm, metaFields) {
        return Object.keys(columnForm.value).reduce(function (acc, key) {
            acc[key] = metaFields[key] ? columnForm.value[key].toUpperCase() : columnForm.value[key];
            acc[key] = (metaFields[key] && metaFields[key].multiInput) ? acc[key].replace(/\s+/g, '') : acc[key];
            return acc;
        }, {});
    };
    ImportValidatorsService.ngInjectableDef = i0.defineInjectable({ factory: function ImportValidatorsService_Factory() { return new ImportValidatorsService(i0.inject(i1.TranslateService), i0.inject(i2.LogNotificationService)); }, token: ImportValidatorsService, providedIn: "root" });
    return ImportValidatorsService;
}());
export { ImportValidatorsService };
