<div class="esm-catalog">
  <map-view
    *ngIf="mapIsReady"
    [markers]="markers"
  ></map-view>
  <header class="esm-catalog__header alone-button">
    <div class="esm-catalog__map">
      <i class="icon icon--export"></i>
      <a [href]="downloadPdfReport()" class="pdf-link esm-catalog__map-view" target="_blank">{{ 'Export' | translate }}</a>
    </div>
  </header>
  <header class="esm-catalog__header checkbox header--title-group">
    <div *ngFor="let status of filterStatuses">
      <md-checkbox class="esm-catalog__group--checkbox checkbox-group"
                   [name]="status.text"
                   (change)="toggleStatus(status.value);"
                   checked="true"
                   (change)="getRecords()">
        {{(status.view) | capitalize | translate}}
      </md-checkbox>
    </div>
  </header>
  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input test-id="search"
                 mdInput
                 autocomplete="off" 
                 placeholder="{{ 'Search' | translate }}"
                 [formControl]="search"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select-long">
        <i class="icon icon--people-grey"></i>
        <md-select
          class="select-group"
          placeholder="{{ 'Type of transformation' | translate }}"
          [(ngModel)]="type"
          (ngModelChange)="getRecords()"
        >
          <md-option>{{ 'Any' | translate }}</md-option>
          <md-option
            *ngFor="let type of TRANSFORM_TYPES"
            [value]="type.value"
          >{{type.view | capitalize | translate}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select-long">
        <i class="icon icon--people-grey"></i>
        <md-select class="select-group"
                   placeholder="{{ 'Transformation Manager' | translate }}"
                   [(ngModel)]="tsmId"
                   (ngModelChange)="getRecords()"
        >
          <md-option>{{ 'Any' | translate }}</md-option>
          <md-option
            *ngFor="let tsm of tsms"
            [value]="tsm.id"
          >{{tsm.name}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select search-long">
        <i class="icon icon--gray-sort"></i>
        <md-select class="select-group"
                   placeholder="{{'Sort By' | translate}}"
                   [(ngModel)]="sortValue"
                   (change)="getRecords()"
        >
          <md-option *ngFor="let sortOption of sortOptions"
                     [value]="sortOption.field">
            {{ sortOption.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
  ></custom-list>
</div>
