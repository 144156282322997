/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagination.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../icons/jump-page-icon.component.ngfactory";
import * as i3 from "../../icons/jump-page-icon.component";
import * as i4 from "@angular/common";
import * as i5 from "../../icons/left-arrow-icon.component.ngfactory";
import * as i6 from "../../icons/left-arrow-icon.component";
import * as i7 from "../../icons/right-arrow-icon.component.ngfactory";
import * as i8 from "../../icons/right-arrow-icon.component";
import * as i9 from "./pagination.component";
import * as i10 from "../../../../shared/services/navigation.service";
var styles_PaginationComponent = [i0.styles];
var RenderType_PaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
function View_PaginationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "page"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToPage(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit === (_co.currentPage - 0)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
function View_PaginationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "jump"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-jump-page-icon", [], null, null, null, i2.View_JumpPageIconComponent_0, i2.RenderType_JumpPageIconComponent)), i1.ɵdid(2, 49152, null, 0, i3.JumpPageIconComponent, [], null, null)], null, null); }
function View_PaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit !== "..."); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit === "..."); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PaginationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "pagination-container"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "arrow left"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToPage((_co.currentPage - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-left-arrow-icon", [], null, null, null, i5.View_LeftArrowIconComponent_0, i5.RenderType_LeftArrowIconComponent)), i1.ɵdid(4, 49152, null, 0, i6.LeftArrowIconComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_1)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "arrow right"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToPage((_co.currentPage + 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-right-arrow-icon", [], null, null, null, i7.View_RightArrowIconComponent_0, i7.RenderType_RightArrowIconComponent)), i1.ɵdid(9, 49152, null, 0, i8.RightArrowIconComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pagination-container"; var currVal_1 = _co.getClassColor(); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.pages; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.currentPage === 1); _ck(_v, 2, 0, currVal_2); var currVal_4 = (_co.currentPage === _co.totalPages); _ck(_v, 7, 0, currVal_4); }); }
export function View_PaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i1.ɵdid(1, 114688, null, 0, i9.PaginationComponent, [i10.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginationComponentNgFactory = i1.ɵccf("app-pagination", i9.PaginationComponent, View_PaginationComponent_Host_0, { currentPage: "currentPage", totalPages: "totalPages" }, { pageChange: "pageChange" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
