<md-dialog-content class="esm-need-dialog">

  <header class="esm-catalog__header esm-catalog__header--end">
    <i class="icon icon--close_s esm-need-dialog__close" (click)="closeDialog()"></i>
  </header>

  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list">
      <li class="esm-catalog__choice-item search">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input mdInput
                 autocomplete="off" 
                 (change)="getRecords()"
                 [(ngModel)]="search"
                 [placeholder]="'Search' | translate"
                 (input)="searchSubject.next()"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--people-grey"></i>
        <md-select
          class="select-group"
          placeholder="{{'Recipient' | translate}}"
          [(ngModel)]="clientId"
          (change)="getRecords()"
        >
          <md-option
            [value]=""
          >{{'Any' | translate}}
          </md-option>
          <md-option
            *ngFor="let client of clients"
            [value]="client.id"
          >{{client.name}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item esm-catalog__picker">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput
                 autocomplete="off" 
                 ng2-datetime-picker
                 date-only="true"
                 date-format="DD-MM-YYYY"
                 [placeholder]="'Expires FROM' | translate"
                 [(ngModel)]="expiresFrom"
                 (ngModelChange)="getRecords()"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-dash">-</li>
      <li class="esm-catalog__choice-item esm-catalog__picker">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput
                 autocomplete="off" 
                 ng2-datetime-picker
                 date-only="true"
                 date-format="DD-MM-YYYY"
                 [placeholder]="'Expires TO' | translate"
                 [(ngModel)]="expiresTo"
                 (ngModelChange)="getRecords()"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--people-grey"></i>
        <md-select
          class="select-group"
          [placeholder]="'Need manager' | translate"
          [(ngModel)]="salesId"
          (change)="getRecords()"
        >
          <md-option
            [value]=""
          >{{'Any' | translate}}
          </md-option>
          <md-option
            *ngFor="let sales of salesContacts"
            [value]="sales.id"
          >{{sales.name}}
          </md-option>
        </md-select>
      </li>
      <toggle-independent
        *ngIf="showIndependentFilter"
        (toggle)="toggleIndependent($event)"
        [text]="'See external needs'"
        [img]="'assets/png/cloud_grey.png'">
      </toggle-independent>    </ul>
  </section>

  <div class="esm-need-map">
    <agm-map
      (idle)="onMapReady()"
      (zoomChange)="mapZoom = $event"
      [zoom]="mapZoom"
      [fitBounds]="mapBounds"
      class="map" style="width: 100%; height: 400px;"
    >
    <agm-marker-cluster *ngIf="mapZoom<=12" [minimumClusterSize]="2" imagePath="assets/png/map/cluster/m">
      
      <div *ngFor="let need of needs">
        <agm-marker
          [iconUrl]="!!need.independent ? 'assets/png/map/localisation-blue.png' : need.status_data.mapIconUrl"
          [latitude]="need.client_location.lat"
          [longitude]="need.client_location.lng"
        >
          <agm-info-window>
            <div class="info center-text">
              <div><strong>{{ need.name }}</strong></div>
              <div>{{ need.quantity ? need.quantity : 0 }} {{ need.passport.unit_text | translate }}</div>
              <div>{{ need.delivery_date  | date:'dd.MM.y'}}</div>
            </div>
          </agm-info-window>
        </agm-marker>
      </div>
    </agm-marker-cluster>
    <agm-marker-spider *ngIf="mapZoom>12">
      <div *ngFor="let need of needs">
        <agm-marker
          [iconUrl]="!!need.independent ? 'assets/png/map/localisation-blue.png' : need.status_data.mapIconUrl"
          [latitude]="need.client_location.lat"
          [longitude]="need.client_location.lng"
        >
          <agm-info-window>
            <div class="info center-text">
              <div><strong>{{ need.name }}</strong></div>
              <div>{{ need.quantity ? need.quantity : 0 }} {{ need.passport.unit_text | translate }}</div>
              <div>{{ need.delivery_date  | date:'dd.MM.y'}}</div>
            </div>
          </agm-info-window>
        </agm-marker>
      </div>
      </agm-marker-spider>

    </agm-map>
  </div>
</md-dialog-content>
