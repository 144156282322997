/**
 * Created by Aleksandr C. on 15.03.17.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import {Passport} from '../../models/passport.class';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {TranslateService} from '@ngx-translate/core';
@Component({
  moduleId: module.id,
  selector: 'copy-passport-dialog',
  templateUrl: 'copy-passport-dialog.component.html'
})
export class CopyPassportDialogComponent implements OnInit, OnDestroy {

  public name: string;
  isSelectAnotherPassportShown: boolean;
  parentModule: string;
  constructor(private _dialog: MdDialogRef<any>, private translate: TranslateService) {

  }

  ngOnInit() {
    this.name = this._dialog._containerInstance.dialogConfig.data.name + ' (copy)';
    this.isSelectAnotherPassportShown = this._dialog._containerInstance.dialogConfig.data.isSelectAnotherPassportShown;
    this.parentModule = this._dialog._containerInstance.dialogConfig.data.parentModule;
  }

  ngOnDestroy() {

  }

  onSelectAnotherPassport() {
    this._dialog.close({resetForm: true});
  }

  submit(result: any) {
    this._dialog.close(result);
  }
  isCreateNewPassportExplanationVisible() {
    return this.parentModule === ModuleNames.AGENT || this.parentModule === ModuleNames.SALES;
  }
  getTranslateParams(): {X: string} {
    const role: { label: string, prefix: string } = {label: null, prefix: null};
    const setRole = (label: string, prefix: string) => { role.label = label; role.prefix = prefix; };
    switch (this.parentModule) {
      case ModuleNames.AGENT:
        setRole('Agent', 'An');
        break;
      case ModuleNames.SALES:
        setRole('Need manager', 'A');
        break;
    }
    const translatedRole = this.translate.instant(role.label);
    const isEng = this.translate.currentLang.toUpperCase() === 'EN';
    return {
      X: `${isEng ? role.prefix : 'Un'} ${translatedRole}`
    };
  }
}
