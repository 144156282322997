import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MD_DIALOG_DATA, MdDialog, MdDialogRef} from '@angular/material';
import {ConfirmOrderDialogService} from '../../../../shared/services/confirm-order-dialog.service';
import {StyleLoaderService} from '../../../../shared/services/style-loader.service';
import {Observable} from 'rxjs/Observable';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {RequestFileSenderService} from '../../../../dashboards/admin/services/request-file-sender.service';
import {first, mergeMap, tap} from 'rxjs/operators';
import {IConfirmDialogData} from '../../../../shared/confirm-dialog/values/interface/confirm-dialog-data.interface';
import {NotificationSuccessComponent} from '../../../../shared/notification-dialogs/notification-success/notification-success.component';


@Component({
  templateUrl: './offer-transaction-confirm-dialog.component.html',
  providers: [{provide: RequestFileSenderService, useClass: RequestFileSenderService}]
})
export class OfferTransactionConfirmDialogComponent implements OnInit, OnDestroy {
  private resetWidth = 'resetWidth';

  constructor(
    public dialogRef: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any,
    public confirmOrderDialogService: ConfirmOrderDialogService,
    public requestFileSenderService: RequestFileSenderService,
    private styleLoader: StyleLoaderService,
    private notification: LogNotificationService,
  ) { }

  confirmOrder(order) {
    const observer = this.documentLoadObservable().pipe(mergeMap(
      () => order.do(() => this.confirmOrderDialogService.afterConfirmationShowSuccessMessage())));
    let overrideData: Partial<IConfirmDialogData> = {};
    overrideData = this.confirmOrderDialogService.getDefaultOverrideForESM(overrideData);
    this.confirmOrderDialogService.open(observer, this.dialogRef, overrideData);
  }

  private documentLoadObservable(): Observable<any> {
    const callbackOnError = () => this.notification.error('One of the documents could not be uploaded', null, true);
    return this.requestFileSenderService.getDocumentUploadObservable().pipe(tap(() => {},
      () => callbackOnError()
    )).pipe(first());
  }



  ngOnInit() {
    this.styleLoader.addStyle(this.resetWidth, `.mat-tooltip {width: auto !important; max-width: 360px !important; `);
  }

  ngOnDestroy() {
    this.styleLoader.removeStyle(this.resetWidth);
  }
}
