import {ActiveRecord} from '../../../shared/models/active-record.class';
import { ICrud } from '../../../shared/interfaces';
import { REPORT_FORM_VALUES } from '../values/deposit-types-for-reports.const';
import { IReportRequest } from '../interfaces/reportRequest.interface';

export class Report extends ActiveRecord {
  id: number;
  user_id: number;
  carbon_foot_print: boolean ;
  circularity: boolean ;
  material_health: boolean;
  product_stage: boolean;
  embodied_carbon: boolean;
  embodied_carbon_total: number;
  ic_component: boolean;
  ic_construction: boolean;
  ic_construction_co2: number;
  financial_residual_value: boolean ;
  filepath_for_residual_value: string;
  filepath_for_circularity: string ;
  filepath_for_material_health: string ;
  filepath_for_carbon_foot_print: string ;
  created_at: string;
  file_path: string;

  protected service: ICrud;
  protected provider: any;

    constructor() {
    super();
    this.circularity = false;
    this.carbon_foot_print = false;
    this.financial_residual_value = false;
    this.material_health = false;
    this.embodied_carbon = false;
    this.product_stage = false;
    this.ic_component = false;
    this.ic_construction = false;
    }

  fields() {
    return ['id', 'user_id', 'created_at',
    'carbon_foot_print', 'circularity', 'material_health', 'financial_residual_value',
    'embodied_carbon', 'embodied_carbon_total,', 'product_stage', 'ic_component', 'ic_construction', 'ic_construction_co2', 
    'file_path',
    'filepath_for_residual_value',
    'filepath_for_circularity' ,
    'filepath_for_material_health' ,
    'filepath_for_carbon_foot_print'];
  }
}
