import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { CategoryItem } from '../models/category-item.class';

@Injectable()
export class CategoryService extends CrudService {
  protected namespace = 'categories';
  protected namespaceSingular = 'category';
  protected ModelClass = CategoryItem;

  getChangeCategoryUrl(): string {
    return this.getEndpoint(this.singularEndpoint + '/request');
  }

  public sendChangeCategory(material: string, description: string) {
    return this.sendPost(this.getChangeCategoryUrl(), {name: material, description: description})
      .catch(this.onError.bind(this));
  }
}
