<div class="category-change-dialog">
  <div class="close" (click)="mdDialogRef.close()">
    <i class="icon icon--close_s"></i>
  </div>

  <h3 class="title">{{ "What do you want to add in Upcyclea's category?" | translate }}</h3>
  <md-input-container class="material">
    <input mdInput placeholder="{{ 'Material' | translate }}" [(ngModel)]="material">
  </md-input-container>
  <md-input-container class="description">
    <input mdInput placeholder="{{ 'Description' | translate }}" autocomplete="off" [(ngModel)]="description">
  </md-input-container>

  <div class="actions">
    <button
            class="save"
            (click)="send()"
            [disabled]="busy"
            md-button
    >
      <img
              class="icon"
              src="assets/png/check--white-icon.png"
      >
      <span class="text">
      {{ 'Send' | translate }}
    </span>
    </button>
  </div>
</div>
