export class Searchable {

  public id: number;
  public name: string;
  public username?: string;

  constructor(data: any = {}) {
    this.id = data.id || data[0];
    this.name = data.name || data[1];
    this.username = data.user_name || data[2];
  }
}
