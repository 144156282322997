/**
 * Created by aleksandr on 26.05.17.
 */
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EcosystemService } from '../../../entities/ecosystem/services/ecosystem.service';
import { Ecosystem } from '../models/ecosystem.class';
import { EntityResolver } from '../../../shared/resolvers/entity.resolver';

@Injectable()
export class EcosystemResolver extends EntityResolver<Ecosystem> implements Resolve<Ecosystem> {

  protected query = {expand: 'need,steps.deposit,steps.transformation,file_count,creator_name,updater_name,ecosystem_offer,orders,waste_total,economic_total,emissions_total'};

  constructor(protected _service: EcosystemService) {
    super();
  }
}
