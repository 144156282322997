import * as tslib_1 from "tslib";
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { AbstractReportStrategy } from './abstract-report-strategy.service';
var WasteStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(WasteStrategy, _super);
    function WasteStrategy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileType = ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_WASTE;
        _this.unit = 'Kg';
        return _this;
    }
    WasteStrategy.prototype.otherAdd = function (ecosystem, value) {
        this.setOtherTotal(value, function (val) { return ecosystem.add_other_waste = val; });
        return ecosystem.add_other_waste;
    };
    WasteStrategy.prototype.otherSubtract = function (ecosystem, value) {
        this.setOtherTotal(value, function (val) { return ecosystem.subtract_other_waste = val; });
        return ecosystem.subtract_other_waste;
    };
    WasteStrategy.prototype.otherDescription = function (ecosystem, value) {
        this.setOtherDescription(value, function (val) { return ecosystem.description_other_waste = val; });
        return ecosystem.description_other_waste;
    };
    WasteStrategy.prototype.getTotalErrors = function (ecosystem) {
        return ecosystem.waste_total.errors;
    };
    return WasteStrategy;
}(AbstractReportStrategy));
export { WasteStrategy };
