/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i2 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i3 from "../../partials/deposit-site-details/deposit-site-details.component.ngfactory";
import * as i4 from "../../../../shared/read-only/read-only.service";
import * as i5 from "../../partials/deposit-site-details/deposit-site-details.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../../shared/services/navigation.service";
import * as i8 from "@angular/material";
import * as i9 from "../../../../shared/services/bim.service";
import * as i10 from "../../../../shared/services/user.service";
import * as i11 from "../../../../shared/services/current-user.service";
import * as i12 from "../../services/deposit-location-file.service";
import * as i13 from "./deposit-site-details-dialog.component";
var styles_DepositSiteDetailsDialogComponent = [];
var RenderType_DepositSiteDetailsDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DepositSiteDetailsDialogComponent, data: {} });
export { RenderType_DepositSiteDetailsDialogComponent as RenderType_DepositSiteDetailsDialogComponent };
export function View_DepositSiteDetailsDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DialogCloseButtonComponent_0, i1.RenderType_DialogCloseButtonComponent)), i0.ɵdid(1, 49152, null, 0, i2.DialogCloseButtonComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "deposit-site-details", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.mdDialogRef.close($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DepositSiteDetailsComponent_0, i3.RenderType_DepositSiteDetailsComponent)), i0.ɵprd(512, null, i4.ReadOnlyService, i4.ReadOnlyService, []), i0.ɵdid(4, 114688, null, 0, i5.DepositSiteDetailsComponent, [i6.Router, i7.NavigationService, i4.ReadOnlyService, i8.MdDialogRef, i9.BIMService, i10.UserService, i11.CurrentUserService, i12.DepositLocationFileService], { id: [0, "id"], readOnly: [1, "readOnly"], type: [2, "type"], userId: [3, "userId"] }, { onSave: "onSave" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.mdDialogData.id; var currVal_2 = _co.mdDialogData.readOnly; var currVal_3 = _co.mdDialogData.type; var currVal_4 = _co.mdDialogData.userId; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).applyClassPosition; _ck(_v, 0, 0, currVal_0); }); }
export function View_DepositSiteDetailsDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [["class", "dialog-close-button-container"]], null, null, null, View_DepositSiteDetailsDialogComponent_0, RenderType_DepositSiteDetailsDialogComponent)), i0.ɵdid(1, 49152, null, 0, i13.DepositSiteDetailsDialogComponent, [i8.MD_DIALOG_DATA, i8.MdDialogRef], null, null)], null, null); }
var DepositSiteDetailsDialogComponentNgFactory = i0.ɵccf("ng-component", i13.DepositSiteDetailsDialogComponent, View_DepositSiteDetailsDialogComponent_Host_0, {}, {}, []);
export { DepositSiteDetailsDialogComponentNgFactory as DepositSiteDetailsDialogComponentNgFactory };
