<div class="specialAdmin-dashboard">
    <div class="filter">
        <div class="filter-container">
            <button class="clearButton" (click)="resetFiltersToDefault()" [disabled]="filtersAreDefault()">
                {{'Clear filters' | translate}}
            </button>
            <div class="stateContainer">
                <app-filter-group [selectedStatuses]="filterStates[currentTab].status" [filterStatuses]="getStatusFilter()"
                    (filterChanged)="onStatusChanged($event)"></app-filter-group>
                <app-base-toggle [(isChecked)]="filterStates[currentTab].seeOnlyWaitingForRating"
                    (isCheckedChange)="onSeeOnlyWaitingForRating($event)" label="{{'Waiting for rating' | translate}}"
                    fontSize="14px" sliderColor="#adb5bd" sliderColorChecked="#72788D"
                    backgroundColor="#fff"></app-base-toggle>
            </div>
            <div class="main-wrapper">
                <div class="column">
                    <app-search [searchTerm]="filterStates[currentTab].search"
                        (searchChanged)="onSearchFilterChange($event)"></app-search>
                    <div class="row">
                        <app-material-search [materials]="filterStates[currentTab].materials"
                            (materialsChange)="onMaterialsFilterChange($event)"></app-material-search>
                        <app-category-search [categories]="filterStates[currentTab].categories"
                            (categoriesChange)="onCategoriesFilterChange($event)"></app-category-search>
                    </div>
                    <div class="row">
                        <app-database-filter [databaseSelected]="filterStates[currentTab].databases"
                            (databaseChange)="onDatabaseFilterChange($event)"></app-database-filter>
                        <app-data-provider-filter [dataProviderSelected]="filterStates[currentTab].dataProviders"
                            (dataProviderChange)="onDataProviderFilterChange($event)"></app-data-provider-filter>
                    </div>
                    <app-sort-by [defaultSort]="filterStates[currentTab].sortBy"
                        (sortChanged)="onSortChange($event)"></app-sort-by>
                </div>
                <div class="column">
                    <app-family-filter [familySelected]="filterStates[currentTab].families" [userRole]="'specialadmin'"
                        (familyChange)="onFamilyFilterChange($event)"></app-family-filter>
                    <app-passport-id-search [searchTerm]="filterStates[currentTab].searchId"
                        (searchChange)="onSearchIdFilterChange($event)"></app-passport-id-search>
                    <app-epd-type-filter [epdTypeSelected]="filterStates[currentTab].epdType"
                        (epdTypeChange)="onEpdTypeFilterChange($event)"></app-epd-type-filter>
                </div>
                <div class="column ">
                    <app-rating-filter [ratingSelected]="filterStates[currentTab].rating"
                        (ratingChange)="onRatingFilterChange($event)"></app-rating-filter>
                    <app-data-representativity-filter
                        [representativitySelected]="filterStates[currentTab].dataRepresentativity"
                        (representativityChange)="onDataRepresentativityFilterChange($event)"></app-data-representativity-filter>
                    <app-creator-filter [creatorSelected]="filterStates[currentTab].company_creators"
                        (creatorChange)="onCreatorFilterChange($event)"></app-creator-filter>
                </div>
            </div>
        </div>
    </div>
    <div class="passport-container">
        <app-passport-collection #passportCollectionRef [currentPage]="pagination.current_page"
            [totalPages]="pagination.page_count" (pageChange)="onPageChange($event)" [passports]="passports"
            [canSelect]="false" (versionClicked)="onVersionClicked($event)"
            [totalPassportCount]="pagination.total_count" [actionButtonsConfig]="actionButtonsConfig"
            (selectAllPages)="onSelectAllPagesChange($event)" [isLoading]="isLoading">
        </app-passport-collection>
    </div>
</div>