import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ReportService } from '../../../../services/report.service';
import { ActivatedRoute } from '@angular/router';
import { ReportBase } from '../report-base/report-base.class';
import { REPORT_NAME } from '../types/report-name.const';
import { LanguageService } from '../../../../../../entities/client/service/language.service';
import { CHART_CATEGORY } from '../../new-report/material-health/values/chart-data.const';
import { TranslateService } from '@ngx-translate/core';
import { MaterialPieCharData } from '../../../../services/material-pie-char-data.service';
import { ReportSiteTagPresenterService } from '../../../../services/report-site-tag-presenter.service';
import { ElRowCounterService } from '../../../../services/el-row-counter.service';
var MaterialHealthReportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MaterialHealthReportComponent, _super);
    function MaterialHealthReportComponent(reportService, activatedRoute, languageService, translateService, materialPieCharDate, reportPresenter, rowCounter) {
        var _this = _super.call(this, reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter) || this;
        _this.reportService = reportService;
        _this.activatedRoute = activatedRoute;
        _this.languageService = languageService;
        _this.translateService = translateService;
        _this.materialPieCharDate = materialPieCharDate;
        _this.reportPresenter = reportPresenter;
        _this.rowCounter = rowCounter;
        _this.reportName = REPORT_NAME.MATERIAL_HEALTH;
        _this.categoriesTable = [];
        _this.errorsTable = [];
        _this.categories = [];
        _this.sortByValueDesc = function (object) { return Object.entries(object)
            .sort(function (_a, _b) {
            var v1 = _a[1];
            var v2 = _b[1];
            return +v1 - +v2;
        })
            .reduce(function (r, _a) {
            var k = _a[0], v = _a[1];
            var _b;
            return (tslib_1.__assign({}, r, (_b = {}, _b[k] = v, _b)));
        }, {}); };
        return _this;
    }
    MaterialHealthReportComponent.prototype.ngOnInit = function () {
        this.createReport();
    };
    MaterialHealthReportComponent.prototype.createReport = function () {
        var _this = this;
        this.reportRequest()
            .subscribe(function () {
            _this.prepareMainChartData();
            _this.prepareTablesDataForRender();
        });
    };
    MaterialHealthReportComponent.prototype.setCategories = function () {
        this.categories = [
            this.getDefaultCategory()
        ].concat(this.getSortedProductsBy(CHART_CATEGORY.healthiness)
            .filter(function (product) { return !!product.deposits_considered; }));
    };
    MaterialHealthReportComponent.prototype.getDefaultCategory = function () {
        return {
            category: {
                name: 'Total', parent_id: void 0, id: void 0
            },
            category_mass_percentage: 100,
            isDefault: true,
            deposit_count: this.result.aggregate.total_deposit_count,
            deposits_considered: this.result.aggregate.total_deposit_considered,
            percentage_of_controlled_toxicity: this.result.aggregate.percentage_weight_of_controlled_toxicity,
            percentage_of_controlled_verified_toxicity: this.result.aggregate.percentage_weight_of_controlled_verified_toxicity,
            percentage_of_healthy_product: this.result.aggregate.percentage_weight_of_healthy_product,
            percentage_of_unknown_toxicity: this.result.aggregate.percentage_weight_of_unknown_toxicity,
            percentage_of_known_toxicity: this.result.aggregate.percentage_weight_of_known_toxicity,
            weight: this.result.aggregate.total_weight,
            weight_of_controlled_toxicity: this.result.aggregate.total_weight_of_controlled_toxicity,
            weight_of_controlled_verified_toxicity: this.result.aggregate.total_weight_of_controlled_verified_toxicity,
            weight_of_healthy_product: this.result.aggregate.total_weight_of_healthy_product,
            weight_of_unknown_toxicity: this.result.aggregate.total_weight_of_unknown_toxicity,
            weight_of_known_toxicity: this.result.aggregate.total_weight_of_known_toxicity,
            healthiness: 0
        };
    };
    MaterialHealthReportComponent.prototype.getProductByHealthiness = function (type) {
        var products = this.getSortedProductsBy(CHART_CATEGORY.healthiness);
        if (type === 'MOST') {
            return products[0];
        }
        else if (type === 'LEAST') {
            return products[products.length - 1];
        }
    };
    MaterialHealthReportComponent.prototype.setHealthiestProduct = function () {
        var product = this.getProductByHealthiness('MOST');
        this.healthiestProductChartData = this.materialPieCharDate.getRoundPieChartData({ product: product });
    };
    MaterialHealthReportComponent.prototype.setMostToxicProduct = function () {
        var product = this.getProductByHealthiness('LEAST');
        this.mostToxicProductChartData = this.materialPieCharDate.getRoundPieChartData({ product: product });
    };
    MaterialHealthReportComponent.prototype.setMostImportantProduct = function () {
        var product = this.getSortedProductsBy(CHART_CATEGORY.weight)[0];
        this.mostImportantProductChartData = this.materialPieCharDate.getRoundPieChartData({ product: product });
    };
    MaterialHealthReportComponent.prototype.prepareMainChartData = function () {
        this.mainChartData = this.materialPieCharDate.getRoundPieChartData({ mainData: this.result.aggregate });
        this.minDepositsConsidered = this.result.aggregate.total_deposit_considered;
        this.maxDepositsConsidered = this.result.aggregate.total_deposit_count;
        this.tonsOfDeposits = this.result.aggregate.total_weight;
    };
    MaterialHealthReportComponent.prototype.prepareTablesDataForRender = function () {
        this.prepareCategoriesTable();
        this.prepareErrorsTable();
        this.setHealthiestProduct();
        this.setMostToxicProduct();
        this.setMostImportantProduct();
    };
    MaterialHealthReportComponent.prototype.prepareCategoriesTable = function () {
        var _this = this;
        this.firstPageCategoriesAmount = 8;
        this.setCategories();
        this.onEachChunkedCategory(function (chuck, index) {
            _this.categoriesTable.push({
                pageNumber: index,
                pageContent: chuck
            });
        });
    };
    MaterialHealthReportComponent.prototype.prepareErrorsTable = function () {
        var _this = this;
        this.onEachChunkedError(function (chuck, index) {
            _this.errorsTable.push({
                pageNumber: index + _this.categoriesTable.length,
                pageContent: chuck
            });
        });
    };
    return MaterialHealthReportComponent;
}(ReportBase));
export { MaterialHealthReportComponent };
