import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LOGIN_ROUTES } from './login.routes';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { GuestGuard } from '../shared/auth/guest.guard';
import { PlatformGuard } from '../shared/auth/platform.guard';
import { CheckAuthHook } from '../shared/auth/check-auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmationPasswordComponent } from './confirmation-pasword/confirmation-password.component';
import {ActivatingAccountComponent} from './activating-account/activating-account.component';
import {LoginRoutingModule} from "./login-module.routing";
import {EulaService} from './services/eula.service';

@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    SharedModule
  ],
  declarations: [LoginComponent,
    ForgotPasswordComponent,
    ConfirmationPasswordComponent,
    ActivatingAccountComponent
  ],
  providers: [
    EulaService
  ],
  exports: [LoginComponent,
    ForgotPasswordComponent,
    ConfirmationPasswordComponent,
    ActivatingAccountComponent
   ],
})
export class LoginModule {
}
