/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i2 from "../../../shared/dialog-close-button/dialog-close-button.component";
import * as i3 from "../partials/virgin-product-details/virgin-product-details.component.ngfactory";
import * as i4 from "../../../shared/read-only/read-only.service";
import * as i5 from "../partials/virgin-product-details/virgin-product-details.component";
import * as i6 from "../services/virgin-product.service";
import * as i7 from "./vp-details-dialog.component";
import * as i8 from "@angular/material";
var styles_VPDetailsDialogComponent = [];
var RenderType_VPDetailsDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VPDetailsDialogComponent, data: {} });
export { RenderType_VPDetailsDialogComponent as RenderType_VPDetailsDialogComponent };
export function View_VPDetailsDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DialogCloseButtonComponent_0, i1.RenderType_DialogCloseButtonComponent)), i0.ɵdid(1, 49152, null, 0, i2.DialogCloseButtonComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "virgin-product-details", [], null, [[null, "saved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saved" === en)) {
        var pd_0 = (_co.mdDialogRef.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_VirginProductDetailsComponent_0, i3.RenderType_VirginProductDetailsComponent)), i0.ɵprd(512, null, i4.ReadOnlyService, i4.ReadOnlyService, []), i0.ɵdid(4, 114688, null, 0, i5.VirginProductDetailsComponent, [i6.VirginProductService, i4.ReadOnlyService], { id: [0, "id"], readOnly: [1, "readOnly"] }, { saved: "saved" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.mdDialogData.id; var currVal_2 = _co.mdDialogData.readOnly; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).applyClassPosition; _ck(_v, 0, 0, currVal_0); }); }
export function View_VPDetailsDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [["class", "dialog-close-button-container"]], null, null, null, View_VPDetailsDialogComponent_0, RenderType_VPDetailsDialogComponent)), i0.ɵdid(1, 49152, null, 0, i7.VPDetailsDialogComponent, [i8.MdDialogRef, i8.MD_DIALOG_DATA], null, null)], null, null); }
var VPDetailsDialogComponentNgFactory = i0.ɵccf("ng-component", i7.VPDetailsDialogComponent, View_VPDetailsDialogComponent_Host_0, {}, {}, []);
export { VPDetailsDialogComponentNgFactory as VPDetailsDialogComponentNgFactory };
