/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClientLocation } from '../../../client/model/location.class';
import { MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'create-location',
  templateUrl: 'create-location.component.html',
  styleUrls: ['create-location.component.scss'],
})
export class CreateLocationDialogComponent implements OnInit, OnDestroy {
  @Input() public clientId: number;
  public location: ClientLocation;
  @Input() public deliveryName : string ;
 

  constructor(private dialog: MdDialogRef<any>) {

  }

  ngOnInit() {
    let location = new ClientLocation;

    
   
   // this.dialog.componentInstance.deleveryname;
    location.name = this.deliveryName || '' ;
    location.client.id = this.clientId;
    this.location = location;
  }

  onSave(location: ClientLocation) {
    this.location = location;
    this.dialog.close(this.location);
  }

  ngOnDestroy() {
  }

}
