import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { PassportKeywordJunction } from '../models/passport-keyword-junction.class';
var PassportKeywordJunctionService = /** @class */ (function (_super) {
    tslib_1.__extends(PassportKeywordJunctionService, _super);
    function PassportKeywordJunctionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'passport-keyword-junctions';
        _this.namespaceSingular = 'passport-keyword-junction';
        _this.ModelClass = PassportKeywordJunction;
        return _this;
    }
    return PassportKeywordJunctionService;
}(CrudService));
export { PassportKeywordJunctionService };
