import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { PassportGtin } from '../models/passport-gtin.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../../../shared/services/log-notification.service";
import * as i3 from "../../../shared/services/navigation.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/services/mock/mock-api.service";
var PassportGtinService = /** @class */ (function (_super) {
    tslib_1.__extends(PassportGtinService, _super);
    function PassportGtinService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ModelClass = PassportGtin;
        _this.namespace = 'passport-gtins';
        _this.namespaceSingular = 'passport-gtin';
        return _this;
    }
    Object.defineProperty(PassportGtinService.prototype, "saveMultipleEndpoint", {
        get: function () {
            return this.getEndpoint(this.singularEndpoint + '/create_multiple');
        },
        enumerable: true,
        configurable: true
    });
    PassportGtinService.prototype.saveMultipleWithSamePassports = function (passportId, codes) {
        return this.sendPost(this.saveMultipleEndpoint, { passport_id: passportId, codes: codes })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    PassportGtinService.ngInjectableDef = i0.defineInjectable({ factory: function PassportGtinService_Factory() { return new PassportGtinService(i0.inject(i1.Http), i0.inject(i2.LogNotificationService), i0.inject(i3.NavigationService), i0.inject(i4.TranslateService), i0.inject(i5.MockApiService)); }, token: PassportGtinService, providedIn: "root" });
    return PassportGtinService;
}(CrudService));
export { PassportGtinService };
