import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { GenericPassportMaterial } from '../models/generic-passport-materials.class';
var GenericPassportMaterialService = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassportMaterialService, _super);
    function GenericPassportMaterialService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'generic-passport-materials';
        _this.ModelClass = GenericPassportMaterial;
        return _this;
    }
    return GenericPassportMaterialService;
}(CrudService));
export { GenericPassportMaterialService };
