/**
 * Created by aleksandr on 29.08.17.
 */
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BeforeRouteActivatedHook } from './before-route-activated.guard';
import { GuardStates } from './guard-states.class';

export abstract class OnceBeforeRouteActivatedHook extends  BeforeRouteActivatedHook implements CanActivate, CanActivateChild {

  get activated(): boolean{
    return GuardStates.isGuardActivated(this);
  }

  set activated(value: boolean){
    if(value) {
      GuardStates.activateGuard(this);
    }
  }

  abstract beforeRouteActivated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | void;

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.activated ? Observable.of(true) : super.canActivate(route, state).do(() => this.activated = true);
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.activated ? Observable.of(true) : super.canActivateChild(childRoute, state).do(() => this.activated = true);
  }

}
