import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
import { DEPOSIT_STATUSES } from '../../../../entities/deposit/values/deposit-statuses.const';
import { ECustomListHintClass, ECustomListRecordStatus } from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { FormControl } from '@angular/forms';
import { Deposit } from '../../../../entities/deposit/models/deposit.class';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DatePipe } from '@angular/common';
import { MdDialog } from '@angular/material';
import { DepositDetailsDialogComponent } from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { DepositLocationService } from '../../../../entities/deposit/services/deposit-location.service';
var DoDepositsListComponent = /** @class */ (function () {
    function DoDepositsListComponent(depositService, mdDialog, datePipe, navBarState, siteService) {
        this.depositService = depositService;
        this.mdDialog = mdDialog;
        this.datePipe = datePipe;
        this.navBarState = navBarState;
        this.siteService = siteService;
        this.records = [];
        this.statuses = DEPOSIT_STATUSES;
        this.availableDepositLocations = [];
        this.filterStatuses = [
            { depositStatus: DEPOSIT_STATUSES.WAITING_FOR_VALIDATION, checked: true, text: 'WAITING_FOR_VALIDATION' },
            { depositStatus: DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION, checked: true, text: 'WAITING_FOR_PUBLICATION' },
            { depositStatus: DEPOSIT_STATUSES.PUBLISHED, checked: true, text: 'PUBLISHED' },
            { depositStatus: DEPOSIT_STATUSES.ASK_FOR_MODIFICATION, checked: true, text: 'ASK_FOR_MODIFICATION' },
            { depositStatus: DEPOSIT_STATUSES.REJECTED, checked: false, text: 'REJECTED' },
        ];
        this.outOfStock = false;
        this.search = new FormControl;
        this.agentContacts = [];
        this.sortOrders = [
            { value: 'name', view: 'Name' },
            { value: '-created_at', view: 'Creation date' },
            { value: 'availability_date', view: 'Availability date' },
            { value: 'agent', view: 'Agent' }
        ];
        this.sortOrder = '-created_at';
        this.isLoadingNewPage = false;
        this.currentPage = 1;
        this.totalPagesCount = 0;
        this.chosenDepositLocations = [];
        this.chosenTags = [];
    }
    DoDepositsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this
            .search
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function () { return _this.loadDeposits(); });
        this.loadDeposits();
        this.siteService.get({ expand: 'tags' }).subscribe(function (sites) { return _this.availableDepositLocations = sites; });
    };
    // TODO need to block it somehow to avoid spamming requests
    DoDepositsListComponent.prototype.loadDeposits = function (forPage) {
        var _this = this;
        if (forPage === void 0) { forPage = 1; }
        if (!this.filterStatuses.find(function (status) { return status.checked; })) {
            this.records = [];
            return;
        }
        var query = {
            expand: 'creator,creator_email,passport,deposit_location',
            deposit_owner: 1,
            supervisor: 1,
            available_from: this.availableFrom || null,
            available_to: this.availableTo || null,
            search: this.search.value || null,
            sort: this.sortOrder || null,
            creator_id: this.agentContactId || null,
            instock: Number(!this.outOfStock),
            'sites[]': this.chosenDepositLocations.map(function (location) { return location.id; }) || null,
            'tags[]': this.chosenTags.map(function (tag) { return tag.id; }) || null,
            page: forPage
        };
        if (this.navBarState.multilanguage) {
            query.english = true;
        }
        query['status[]'] = this.filterStatuses
            .filter(function (status) { return status.checked; })
            .map(function (status) { return status.depositStatus.value; });
        if (forPage === 1) {
            this.records = [];
        }
        this.isLoadingNewPage = true;
        this.depositService.getWithPaginationData(query).subscribe(function (res) {
            var headers = res.headers;
            _this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
            _this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
            res.json().map(function (deposit) {
                _this.addRecord(new Deposit(deposit));
            });
            _this.isLoadingNewPage = false;
        });
    };
    DoDepositsListComponent.prototype.onEndOfListReached = function () {
        if (this.currentPage < this.totalPagesCount && !this.isLoadingNewPage) {
            var newValue = this.currentPage + 1;
            this.loadDeposits(newValue);
        }
    };
    DoDepositsListComponent.prototype.addRecord = function (deposit) {
        var hintCssClass = ECustomListRecordStatus.NO_STATUS;
        var passportName = deposit.passport !== null ? deposit.passport.name : '';
        var site = deposit.deposit_location !== null ? deposit.deposit_location.name : '';
        if (deposit.status === DEPOSIT_STATUSES.PUBLISHED.value) {
            hintCssClass = ECustomListRecordStatus.CONFIRM;
        }
        else if (deposit.status === DEPOSIT_STATUSES.WAITING_FOR_VALIDATION.value) {
            hintCssClass = ECustomListRecordStatus.MANUAL;
        }
        if (!this.agentContacts.find(function (creator) { return creator.id === deposit.creator_id; })) {
            this.agentContacts.push({ id: deposit.creator_id, name: deposit.creator });
        }
        this.records.push(this.createRecord(hintCssClass, ECustomListHintClass.NO_CLASS, '', deposit.name, passportName, deposit.quantity || 0, deposit.passport.customListUnit, this.datePipe.transform(deposit.created_at, 'dd.MM.y'), this.datePipe.transform(deposit.availability_date, 'dd/MM/y') + (deposit.availability_expiration
            ? ' - ' + this.datePipe.transform(deposit.availability_expiration, 'dd/MM/y') : ''), site, deposit.creator, deposit));
    };
    DoDepositsListComponent.prototype.createRecord = function (status, hintCssClass, hintText, title, subtitle, amount, unit, createdAt, availableIn, site, author, deposit) {
        var _this = this;
        var waitingValidationState = deposit.status === DEPOSIT_STATUSES.WAITING_FOR_VALIDATION.value;
        return CustomListRecord
            .build()
            .setPrimaryKey(deposit.id)
            .setStatus(status)
            .setHint(hintText, hintCssClass)
            .addTitleColumn(title, subtitle, null, deposit.passport.english_name)
            .addUnitColumn(amount, unit)
            .addListColumn([{ key: '', value: site }])
            .addListColumn([{
                key: 'Created', value: createdAt
            },
            availableIn ? { key: 'Availability', value: availableIn } : null
        ])
            .addAuthorColumn(author)
            .addControlsColumn([{
                fn: function () {
                    _this.mdDialog.open(DepositDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                            id: deposit.id,
                            readOnly: waitingValidationState,
                            showValidationButtons: waitingValidationState,
                        } })).afterClosed().subscribe(function () { return _this.loadDeposits(); });
                },
                name: 'See details'
            }]);
    };
    return DoDepositsListComponent;
}());
export { DoDepositsListComponent };
