<section class="ecosystem-compiler">
  <div class="ecosystem-compiler__menu-header">
    <h3 class="ecosystem-compiler__menu-header-title uppercase">
      {{ 'Transformation' | translate }}
    </h3>
    <div class="ecosystem-compiler__menu-header-vertical-line"></div>
    <h3 class="ecosystem-compiler__menu-header-description">
      {{ filterState.multilanguage ? transform?.english_name : transform?.name}}
    </h3>
  </div>
  <ul class="ecosystem-compiler__tab-list">
    <li class="ecosystem-compiler">
      <header class="ecosystem-compiler__menu-item-header">
        <div class="ecosystem-compiler__menu-item-header-container">
          <img class="ecosystem-compiler__menu-item-header-container-icon"
          src="../../../../../assets/svg/ecosystem-compiler-offer--icon.svg"/>
          <h3 class="ecosystem-compiler__menu-item-header-container-title">
            {{ 'Order' | translate }}
          </h3>
        </div>
        <div class="ecosystem-compiler__menu-item-header-container">
          <div class="ecosystem-compiler__order-status--standby ecosystem-compiler__menu-item-header-container-status">
            {{step.getStatusText() | capitalize | translate}}
          </div>
          <div class="ecosystem-compiler__expander" (click)="changeTab(tabType.ORDER)">
            <button class="ecosystem-compiler__fold-tab button-transparent">
              <i class="icon" [ngClass]="isOpen(tabType.ORDER) ? 'icon--fold-tab-white' : 'icon--unfold-tab-white' ">
              </i>
            </button>
          </div>
        </div>
      </header>
      <section class="ecosystem-compiler__menu-item-section" *ngIf="isOpen(tabType.ORDER) && orders[0]">
        <div class="ecosystem-compiler__sub-header">
          <span class="ecosystem-compiler__sub-header-title">{{ getPreviousStepContainerTitle() }}</span>
        </div>
        <li *ngFor="let order of orders; let index = index;" class="ecosystem-compiler-transformation-order">
          <div class="ecosystem-compiler-transformation-order-previous-step-container">
            <span class="ecosystem-compiler-transformation-order-previous-step">
              {{ 'Previous step' | translate }} :
            </span>
            <span class="ecosystem-compiler-transformation-order-previous-step-type-and-title">
              {{ getPreviousStepText(order.child_step.type) }} -
              {{ order.child_step.title }}
            </span>
          </div>
          <div class="ecosystem-compiler-transformation-order-row">
            <hr />
            <div class="ecosystem-compiler-transformation-order">
              <span
                class="ecosystem-compiler-transformation-order-data-title--input-material ecosystem-compiler-transformation-order-title" [ngClass]="{'primary-color': !isReadOnly()}">
                {{ getPreviousStepInputOrMaterialTitle()}}
              </span>
              <md-input-container class="ecosystem-compiler-transformation-order-input-wide">
                <input
                  [readonly]="isReadOnly()"
                  mdInput
                  [(ngModel)]="order.in_order_passport.name"
                  [value]="order.in_order_passport.name"
                  autocomplete="off"
                  (change)="saveStep()"/>
              </md-input-container>
              <conversion-field
                [setToggleColorActive]="isReadOnly()"
                [isDisabled]="isReadOnly()"
                class="ecosystem-compiler-offer-section-conversion"
                [identifier]="'in_transform_order' + '_' + order.id + '_' + order.step_id"
                [setDefaultConversionOff]="isInputConversionDisabledByDefault(index) || isWithoutConversionFactor(index)"
                [placeholder]="getPreviousStepQuantityText()"
                [volumeUnit]="getInputUnit(index)"
                [emptyQuantityHolder]="''"
                [quantity]="order.orderInQuantityView"
                [quantityInTonsOnInit]="order.in_order_passport?.weight"
                [conversionFactor]="getInputConversion(index)"
                [isConversionDisabled]="isWithoutConversionFactor(index)"
                (quantityInTonsChanged)="updateInputQuantity($event, index)"
                (quantityChanged)="updateInputWeight(index, $event)"
              >
              </conversion-field>
              <span
                class="ecosystem-compiler-transformation-order-title" [ngClass]="{'primary-color': !isReadOnly()}">
                {{ getPreviousStepDateText() }}
              </span>
              <div class="ecosystem-compiler-transformation-order-date">
                <md-input-container>
                  <input
                    [readonly]="isReadOnly()"
                    mdInput
                    autocomplete="off"
                    ng2-datetime-picker
                    date-only="true"
                    date-format="DD-MM-YYYY"
                    parse-format="YYYY-MM-DD"
                    [(ngModel)]="orders[index].child_step.linked_date"
                    (valueChanged)="saveStep(true)"/>
                </md-input-container>
              </div>
              <div *ngIf="isTransformTypeOfTransport()">
                <span
                class="ecosystem-compiler-transformation-order-data-title" [ngClass]="{'primary-color': !isReadOnly()}">
                {{ 'Pickup address' | translate }}
              </span>
              <md-input-container class="ecosystem-compiler-transformation-order-input-wide">
                <place-autocomplete [options]="{'types': ['geocode']}">
                <input
                  [readonly]="isReadOnly()"
                  mdInput
                  placeAutocomplete
                  [(ngModel)]="orders[index].pickup_address"
                  (placeChange)="onPickupPlaceChange($event, index)"
                  autocomplete="off"/>
                </place-autocomplete>
              </md-input-container>
              </div>
              <span
                class="ecosystem-compiler-transformation-order-title" [ngClass]="{'primary-color': !isReadOnly()}">
                {{ 'Description' | translate }}
              </span>
              <md-input-container
                class="ecosystem-compiler-transformation-order-input-wide">
                <textarea
                  rows="1"
                  [readonly]="isReadOnly()"
                  #inputDescription
                  mdInput
                  [value]="orders[index].in_order_passport.description"
                  (input)="onInputDescriptionChange($event.target.value, index); expandInputTextArea(index)"
                  autocomplete="off"
                ></textarea>
              </md-input-container>
            </div>
          </div>
        </li>
        <div *ngIf="checkIsNotTransportOrStorageTransformation()">
          <div class="ecosystem-compiler__sub-header" >
            <span class="ecosystem-compiler__sub-header-title">{{ 'Output' | translate }}</span>
          </div>
          <div  class="ecosystem-compiler-transformation-order-output-info">
            <span
              class="ecosystem-compiler-transformation-order-title" [ngClass]="{'primary-color': !isReadOnly()}">
              {{ 'Output material' | translate }}
            </span>
            <md-input-container class="ecosystem-compiler-transformation-order-input-wide">
              <input
                mdInput
                autocomplete="off"
                [(ngModel)]="orders[0].out_order_passport.name"
                [readonly]="isReadOnly()"
                (change)="saveStep()"/>
            </md-input-container>
            <conversion-field
              [identifier]="'out_transform_order' + '_' + orders[0].id + '_' + orders[0].step_id"
              [setToggleColorActive]="isReadOnly()"
              [isDisabled]="isReadOnly()"
              [emptyQuantityHolder]="''"
              [setDefaultConversionOff]="orders[0].out_order_passport.isWeightExist()"
              class="ecosystem-compiler-offer-section-conversion"
              placeholder="{{'Output quantity' | translate}}"
              [volumeUnit]="getOutputUnit()"
              [quantity]="orders[0].orderOutQuantityView"
              [quantityInTonsOnInit]="orders[0].out_order_passport?.weight"
              [conversionFactor]="getOutputConversion()"
              (quantityInTonsChanged)="updateOutputQuantity($event)"
              (quantityChanged)="updateWeight(orders[0].out_order_passport, $event)"
            >
            </conversion-field>
            <span
              class="ecosystem-compiler-transformation-order-title" [ngClass]="{'primary-color': !isReadOnly()}"
            >
              {{ 'Output date' | translate }}
            </span>
            <div class="ecosystem-compiler-transformation-order-date">
              <md-input-container class="ecosystem-compiler-transformation-date-input">
                <input
                  [readonly]="isReadOnly()"
                  mdInput
                  autocomplete="off"
                  ng2-datetime-picker
                  date-only="true"
                  date-format="DD-MM-YYYY"
                  parse-format="YYYY-MM-DD"
                  [(ngModel)]="orders[0].out_order_passport.linked_datetime"
                  (valueChanged)="saveStep(true)"/>
              </md-input-container>
            </div>
            <span class="ecosystem-compiler-transformation-order-title" [ngClass]="{'primary-color': !isReadOnly()}">
              {{ 'Description' | translate }}
            </span>
            <md-input-container class="ecosystem-compiler-transformation-order-input-wide">
              <textarea
                rows="1"
                [readonly]="isReadOnly()"
                #outputDescription
                mdInput
                [value]="orders[0].out_order_passport.description"
                (input)="onOutputDescriptionChange($event.target.value); expandOutputTextArea()"
                autocomplete="off"
              ></textarea>
            </md-input-container>
          </div>
        </div>
        <div class="ecosystem-compiler__sub-header">
          <span class="ecosystem-compiler__sub-header-title">{{ 'Order Modalities' | translate }}</span>
        </div>
        <div class="ecosystem-compiler-transformation-order-modalities">
          <span
          *ngIf="isTransformTypeOfStorage() || isTransformTypeOfTransport()"
          class="ecosystem-compiler-transformation-order-title" [ngClass]="{'primary-color': !isReadOnly()}">
          {{ getStorageOrDeliveryText() }}
          </span>
          <div *ngIf="isTransformTypeOfStorage() || isTransformTypeOfTransport()" class="ecosystem-compiler-transformation-order-date">
            <md-input-container class="ecosystem-compiler-transformation-date-input">
              <input
                [readonly]="isReadOnly()"
                mdInput
                autocomplete="off"
                ng2-datetime-picker
                date-only="true"
                date-format="DD-MM-YYYY"
                parse-format="YYYY-MM-DD"
                [(ngModel)]="orders[0].out_order_passport.linked_datetime"
                (valueChanged)="saveStep(true)"/>
            </md-input-container>
          </div>
          <div *ngIf="isTransformTypeOfTransport()">
            <span class="ecosystem-compiler-transformation-order-title" [ngClass]="{'primary-color': !isReadOnly()}">
              {{ 'Delivery address' | translate }}
            </span>
            <md-input-container class="ecosystem-compiler-transformation-order-input-wide">
              <place-autocomplete [options]="{'types': ['geocode']}">
                <input
                [readonly]="isReadOnly()"
                mdInput
                placeAutocomplete
                [(ngModel)]="orders[0].delivery_address"
                (placeChange)="onDeliveryPlaceChange($event)"
                autocomplete="off"/>
              </place-autocomplete>
            </md-input-container>
            <br />
            <span class="ecosystem-compiler-offer-section-title">
              {{ 'Distance to travel' | translate }}
            </span>
            <div class="ecosystem-compiler-offer-section-description-container">
              <md-input-container class="ecosystem-compiler-offer-section-price">
                <input
                  mdInput
                  autocomplete="off"
                  [(ngModel)]="step.estimated_distance"
                  disabled/>
              </md-input-container>
              <span class="ecosystem-compiler-offer-section-currency">{{ 'km' | translate}}</span>
            </div>

          </div>
          <div *ngIf="!isTransformTypeOfTransport()">
            <span class="ecosystem-compiler-offer-section-title" [ngClass]="{'primary-color': !isReadOnly()}">
              {{ 'Price' | translate }}
            </span>
            <div class="ecosystem-compiler-offer-section-description-container">
              <md-input-container class="ecosystem-compiler-offer-section-price">
                <input
                  type="number"
                  mdInput
                  autocomplete="off"
                  [(ngModel)]="orders[0].price"
                  [readonly]="isReadOnly()"
                  (change)="saveStep()"/>
              </md-input-container>
              <span class="ecosystem-compiler-offer-section-currency">€</span>
              <i
                class="form-group__label--question ecosystem-compiler-offer-section-description"
                mdTooltip="{{'ESM_COMPILER_NEED_STEP_PRICE_TOOLTIP' | translate}}"
                mdTooltipPosition="above"
                >?</i
              >
            </div>
          </div>

          <span class="ecosystem-compiler-transformation-order-title" [ngClass]="{'primary-color': !isReadOnly()}">
            {{ 'Description' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-transformation-order-input-wide">
            <textarea
              [readonly]="isReadOnly()"
              rows="1"
              #stepDescription
              mdInput
              [value]="step.description ? step.description : ''"
              (input)="onStepDescriptionChange($event.target.value); expandStepTextArea()"
              autocomplete="off"
            ></textarea>
          </md-input-container>
          <div *ngIf="isTransformTypeOfTransport()">
            <span class="ecosystem-compiler-offer-section-title" [ngClass]="{'primary-color': !isReadOnly()}">
              {{ 'Price' | translate }}
            </span>
            <div class="ecosystem-compiler-offer-section-description-container">
              <md-input-container class="ecosystem-compiler-offer-section-price">
                <input
                  type="number"
                  mdInput
                  autocomplete="off"
                  [(ngModel)]="orders[0].price"
                  [readonly]="isReadOnly()"
                  (change)="saveStep()"/>
              </md-input-container>
              <span class="ecosystem-compiler-offer-section-currency">€</span>
              <i
                class="form-group__label--question ecosystem-compiler-offer-section-description"
                mdTooltip="{{'ESM_COMPILER_NEED_STEP_PRICE_TOOLTIP' | translate}}"
                mdTooltipPosition="above"
                >?</i
              >
            </div>
          </div>
          <ul>
            <div class="ecosystem-compiler-offer-section-document-container">
              <li class="upload-files__download ecosystem-compiler-offer-section-icon">
                <input
                  class="upload-files__add ecosystem-compiler-offer-section-icon"
                  autocomplete="off"
                  type="file"/>
                <label>
                  <i
                    (click)="openFiles(orders[0])"
                    class="upload-files__camera documents--icon"
                  ></i>
                </label>
              </li>
              <document-item
                *ngFor="let junction of orders[0].ecosystem_file_junction"
                [noLink]="true"
                [file]="junction.ecosystem_file"
                [isRemoveFromFilesDisabled]="false"
                (remove)="removeJunctionFromOrder(junction, orders[0])">
              </document-item>
            </div>
          </ul>
        </div>
      </section>
    </li>

    <li class="ecosystem-compiler__menu-tab">
      <header class="ecosystem-compiler__menu-item-header">
        <div class="ecosystem-compiler__menu-item-header-container">
          <div *ngIf="this.transform.transformation_type_data" class="ecosystem-compiler__menu-item-header-container-icon">
            <img
            [ngClass]="getTransformationIconStyles()"
            [src]="getTransformationIcon()"/>
          </div>
          <div *ngIf="!this.transform.transformation_type_data" class="ecosystem-compiler__menu-item-header-spinner-container">
            <div class="ecosystem-compiler__menu-item-header-spinner"></div>
          </div>
          <h3 class="ecosystem-compiler__menu-item-header-container-title">
            {{ 'Transformation summary' | translate }}
          </h3>
        </div>
        <div class="ecosystem-compiler-menu-tab-expander" (click)="changeTab(tabType.SUMMARY); expandSummaryTextAreas()">
          <button class="button-transparent">
            <i class="icon" [ngClass]="isOpen(tabType.SUMMARY) ? 'icon--fold-tab-white' : 'icon--unfold-tab-white' ">
            </i>
          </button>
        </div>
      </header>
      <section class="ecosystem-compiler__tab-section" *ngIf="isOpen(tabType.SUMMARY)">
        <div class="ecosystem-compiler-transformation-order-summary">
          <div class="ecosystem-compiler__row-link">
            <img src="../../../../../assets/svg/ecosystem-compiler-eye--icon.svg" class="ecosystem-compiler-need-summary-link-eye"/>
            <div class="ecosystem-compiler__confirm-button primary-color" (click)="seeFullTransformation()">
              {{ 'See the full transformation' | translate }}
            </div>
          </div>
          <span class="ecosystem-compiler-transformation-order-summary-title">
            {{ 'Transformation name' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-transformation-order-summary-input-field">
            <input
              mdInput
              readonly
              disabled
              autocomplete="off"
              [(ngModel)]="filterState.multilanguage ? transform.english_name : transform.name"
            />
          </md-input-container>
          <span class="ecosystem-compiler-transformation-order-summary-title">
            {{ 'Description' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-transformation-order-summary-input-field">
            <textarea
              autocomplete="off"
              disabled
              [(ngModel)]="transform.description"
              mdInput
              readonly
              rows="1"
              #transformDescription></textarea>
          </md-input-container>
          <div *ngIf="isTransformTypeOfTransport()"
          class="ecosystem-compiler-tranformation-order">
            <span class="ecosystem-compiler-transformation-order-summary-title">
            {{'Type of materials transported' | translate}}
            </span>
            <div class="ecosystem-compiler__transformation-materials ecosystem-compiler-transformation-order-modalities-chip"
              *ngIf="transform.transformation_type_data.includes.acceptedMaterials">
                <md-chip-list class="ecosystem-compiler__chips-list">
                  <ng-container *ngFor="let viewValue of transform.acceptedMaterialsViewValues">
                    <md-basic-chip>
                      <div>{{ viewValue }}</div>
                    </md-basic-chip>
                  </ng-container>
                </md-chip-list>
            </div>
            <span class="ecosystem-compiler-transformation-order-summary-title">
                {{'Details of the materials transported' | translate}}
            </span>
            <div class="ecosystem-compiler__transformation-materials ecosystem-compiler-transformation-order-modalities-chip"
                *ngIf="transform.transformation_type_data.includes.transportedDetails">
                  <md-chip-list class="ecosystem-compiler__chips-list">
                    <ng-container *ngFor="let material of transform.inputMaterials">
                      <md-basic-chip >
                        <div *ngIf="material?.name?.length > 17; else fullName" [mdTooltip]="material.name | translate">
                          {{ material.name | translate }}</div>
                        <ng-template #fullName>
                          <div>{{ material.name | translate }}</div>
                        </ng-template>
                      </md-basic-chip>
                    </ng-container>
                  </md-chip-list>
            </div>
            <span class="ecosystem-compiler-transformation-order-summary-title">
                  {{ 'Minimum quantity accepted' | translate }}
            </span>
            <div class="ecosystem-compiler-transformation-order-quantity-accepted">
            <md-input-container class="ecosystem-compiler-transformation-order-quantity-accepted-input">
                    <input
                      mdInput
                      [(ngModel)]="transform.min_quantity"
                      readonly/>
            </md-input-container>
            <span>{{ getUnitText(transform.min_unit) }}</span>
            </div>
            <span class="ecosystem-compiler-transformation-order-summary-title">
                  {{ 'Maximum quantity accepted' | translate }}
            </span>
            <div class="ecosystem-compiler-transformation-order-quantity-accepted">
                  <md-input-container class="ecosystem-compiler-transformation-order-quantity-accepted-input">
                    <input
                      mdInput
                      [(ngModel)]="transform.max_quantity"
                      readonly />
                  </md-input-container>
                  <span>{{ getUnitText(transform.max_unit) }}</span>
            </div>
          </div>
          <div *ngIf="isVisibleSite('site')">
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Site' | translate }}</span>
            <div>
              <span class="ecosystem-compiler-transformation-order-summary-site-name">
                {{ site?.name }}</span>
            </div>
            <div>
              <i class="icon icon--places ecosystem-compiler-transformation-order-summary-site-icon">
              </i>
              <span class="ecosystem-compiler-transformation-order-summary-site-address">
                {{ site?.address }}</span>
            </div>
          </div>
          <div *ngIf="isVisibleSite('service')">
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Service area' | translate }}</span>
            <div>
              <i class="icon icon--places ecosystem-compiler-transformation-order-summary-site-icon">
              </i>
              <span class="ecosystem-compiler-transformation-order-summary-site-name">
                {{ transform.sectors[0].name }}</span>
            </div>
            <div>
              <span class="ecosystem-compiler-transformation-order-summary-site-address">
                {{ site?.address }}</span>
            </div>
          </div>
          <div *ngIf="isVisibleSite('geosite')">
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Geographical area or site' | translate }}</span>
            <div>
              <i class="icon icon--places ecosystem-compiler-transformation-order-summary-site-icon">
              </i>
              <span class="ecosystem-compiler-transformation-order-summary-site-name">
                {{ site?.name }}</span>
            </div>
            <div>
              <span class="ecosystem-compiler-transformation-order-summary-site-address">
                {{ site?.address }}</span>
            </div>
          </div>
          <span class="ecosystem-compiler-transformation-order-summary-title">
            {{ 'Documents' | translate }}
          </span>
          <documents-uploader
          class="ecosystem-compiler-transformation-order-modalities-documents"
          isDisabled="true"
          [files]="transform.files"
          [uploadUrl]="transform.getFileUploadUrl()"
          [service]="transformFileService"
        ></documents-uploader>
        </div>
        <div *ngIf="checkIsNotTransportOrStorageTransformation()">
          <div class="ecosystem-compiler__sub-header">
            <span class="ecosystem-compiler__sub-header-title">{{ 'Input' | translate }}</span>
          </div>
          <div class="ecosystem-compiler-transformation-order-summary">
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Materials' | translate }}
            </span>
            <div class="ecosystem-compiler__transformation-materials ecosystem-compiler__transformation-materials--income"
            *ngIf="transform.transformation_type_data.includes.incomeOutcome">
              <md-chip-list class="ecosystem-compiler__chips-list">
                <ng-container *ngFor="let material of transform.inputMaterials">
                  <md-basic-chip>
                    <div *ngIf="material?.name?.length > 17; else fullName" [mdTooltip]="material.name | translate">
                      {{ material.name | translate }}
                    </div>
                    <ng-template #fullName>
                      <div>{{ material.name | translate }}</div>
                    </ng-template>
                  </md-basic-chip>
                </ng-container>
              </md-chip-list>
            </div>
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Keywords' | translate }}
            </span>
            <div class="ecosystem-compiler__transformation-materials ecosystem-compiler-transformation-order-modalities-chip"
            *ngIf="transform.transformation_type_data.includes.incomeOutcome">
              <md-chip-list class="ecosystem-compiler__chips-list">
                <ng-container *ngFor="let material of transform.inputTags">
                  <md-basic-chip>
                    <div
                      *ngIf="material?.name?.length > 17; else fullName"
                      [mdTooltip]="material.name | translate"
                    >
                      {{ material.name  | translate}}
                    </div>
                    <ng-template #fullName>
                      <div>{{ material.name | translate }}</div>
                    </ng-template>
                  </md-basic-chip>
                </ng-container>
              </md-chip-list>
            </div>
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Input quality' | translate }}
            </span>
            <div class="ecosystem-compiler__transformation-materials ecosystem-compiler-transformation-order-modalities-chip"
            *ngIf="transform.transformation_type_data.includes.incomeOutcome">
              <md-chip-list class="ecosystem-compiler__chips-list">
                <ng-container *ngFor="let material of transform.inputQualities">
                  <md-basic-chip>
                    <div
                      *ngIf="material?.name?.length > 17; else fullName"
                      [mdTooltip]="material.name | translate"
                    >
                      {{ material.name | translate }}
                    </div>
                    <ng-template #fullName>
                      <div>{{ material.name | translate }}</div>
                    </ng-template>
                  </md-basic-chip>
                </ng-container>
              </md-chip-list>
            </div>
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Minimum quantity accepted' | translate }}
            </span>
            <div class="ecosystem-compiler-transformation-order-quantity-accepted">
              <md-input-container class="ecosystem-compiler-transformation-order-quantity-accepted-input">
                <input
                  mdInput
                  [(ngModel)]="transform.min_quantity"
                  readonly/>
              </md-input-container>
              <span>{{ getUnitText(transform.min_unit) }}</span>
            </div>
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Maximum quantity accepted' | translate }}
            </span>
            <div class="ecosystem-compiler-transformation-order-quantity-accepted">
              <md-input-container class="ecosystem-compiler-transformation-order-quantity-accepted-input">
                <input
                  mdInput
                  [(ngModel)]="transform.max_quantity"
                  readonly />
              </md-input-container>
              <span>{{ getUnitText(transform.max_unit) }}</span>
            </div>
          </div>
          <div class="ecosystem-compiler__sub-header">
            <span class="ecosystem-compiler__sub-header-title">{{ 'Output' | translate }}</span>
          </div>
          <div class="ecosystem-compiler-transformation-order-summary">
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Materials' | translate }}
            </span>
            <div class="ecosystem-compiler__transformation-materials ecosystem-compiler__transformation-materials--outcome"
            *ngIf="transform.transformation_type_data.includes.incomeOutcome">
              <md-chip-list class="ecosystem-compiler__chips-list">
                <ng-container *ngFor="let material of transform.outputMaterials">
                  <md-basic-chip>
                    <div *ngIf="material?.name?.length > 17; else fullName" [mdTooltip]="material.name | translate">
                      {{ material.name | translate }}
                    </div>
                    <ng-template #fullName>
                      <div>{{ material.name | translate }}</div>
                    </ng-template>
                  </md-basic-chip>
                </ng-container>
              </md-chip-list>
            </div>
            <span class="ecosystem-compiler-transformation-order-summary-title">
              {{ 'Keywords' | translate }}
            </span>
            <div class="ecosystem-compiler__transformation-materials ecosystem-compiler-transformation-order-modalities-chip"
              *ngIf="transform.transformation_type_data.includes.incomeOutcome">
              <md-chip-list class="ecosystem-compiler__chips-list">
                <ng-container *ngFor="let material of transform.outputTags">
                  <md-basic-chip>
                    <div
                      *ngIf="material?.name?.length > 17; else fullName"
                      [mdTooltip]="material.name | translate"
                    >
                      {{ material.name  | translate}}
                    </div>
                    <ng-template #fullName>
                      <div>{{ material.name | translate }}</div>
                    </ng-template>
                  </md-basic-chip>
                </ng-container>
              </md-chip-list>
            </div>
          </div>
        </div>
      </section>
    </li>

    <li
      class="ecosystem-compiler__menu-tab ecosystem-compiler__small-container"
      *ngFor="let order of step.orders; let index = index"
    >
      <header class="ecosystem-compiler__menu-item-header">
        <div class="ecosystem-compiler__menu-item-header-container">
          <img *ngIf="isRoomLoaded(index)"
            class="ecosystem-compiler__menu-item-header-container-icon"
            src="../../../../../assets/svg/ecosystem-compiler-chat-with-need-manager--icon.svg"
          />
          <div *ngIf="!isRoomLoaded(index)" class="ecosystem-compiler__menu-item-header-spinner-container">
            <div class="ecosystem-compiler__menu-item-header-spinner"></div>
          </div>
          <h3 class="ecosystem-compiler__menu-item-header-container-title">
            {{ 'Chat with Transformation manager' | translate }}
          </h3>
          <div class="ecosystem-compiler__menu-item-header-container-chat">
            -
            {{isTransformIndependent ? transform.tsm_org : transform.tsm_name }}
          </div>
        </div>
        <div
          class="ecosystem-compiler__expander"
          (click)="changeTab(tabType.CHAT + index)"
        >
          <button class="ecosystem-compiler__fold-tab button-transparent">
            <i
              class="icon"
              [ngClass]="isOpen(tabType.CHAT + index) ? 'icon--fold-tab-white' : 'icon--unfold-tab-white' "
            ></i>
          </button>
        </div>
      </header>
      <section
        class="ecosystem-compiler__tab-section"
        *ngIf="isOpen(tabType.CHAT + index) && isRoomLoaded(index)"
      >
        <chat-form
          [isChatForTransformIndep]="isTransformIndependent"
          [room]="rooms[index].room"
          *ngIf="isRoomLoaded(index)"
        ></chat-form>
      </section>
    </li>
  </ul>
</section>
