import {Injectable} from '@angular/core';
import {
  EPassportTabFormGroup,
  PASSPORT_ADDITIONAL_TAB_MODEL_FORM_JUNCTION, PASSPORT_FORM_MODEL_JUNCTION,
  PASSPORT_GENERAL_TAB_MODEL_FORM_JUNCTION, PASSPORT_LONG,
  PASSPORT_MATERIAL_TAB_MODEL_FORM_JUNCTION,
  PASSPORT_NEXT_USAGE_TAB_MODEL_FORM_JUNCTION, PASSPORT_SHORT,
  PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION
} from '../../values/passport-validation-fields.const';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Passport} from '../../models/passport.class';
import {PassportTabFormBuilderModel} from './passport-tab-form-builder.model';
import lodash from 'lodash';
import {Subscription} from 'rxjs/Subscription';

@Injectable({
  providedIn: 'root'
})
export class PassportGroupBuilderService {
  public static readonly VISIBILITY_CONTROL = 'long';
  public static readonly MODEL_CONTROL = '__model__';

  public static defaultChange(control: FormControl) {
    return (controlName: string | Array<string>) => {
      const property = PassportGroupBuilderService.getModelJunction(controlName);
      if (PassportGroupBuilderService.isStringKey(property)) {
        return (val) => control.value[property as string] = val;
      }
      if (PassportGroupBuilderService.isArrayPath(property)) {
        const {lastKey, lastObj} = PassportGroupBuilderService.getArrayModelValueFromArray(control, property as Array<string>);
        return (val) => lastObj[lastKey] = val;
      }
      return null;
    };
  }

  private static getModelJunction(name) {
    return PASSPORT_FORM_MODEL_JUNCTION[name];
  }

  private static isStringKey(property) {
    return typeof property === 'string' && !!property.length;
  }

  private static isArrayPath(property) {
    return property && Array.isArray(property) && !!property.length;
  }

  private static getArrayModelValueFromArray(control: FormControl, array: string[]) {
    let lastObj = control.value;
    let lastKey;
    array.forEach(item => {
      lastObj = lastKey ? lastObj[lastKey] : lastObj;
      lastKey =  item;
    });
    return {lastObj, lastKey};
  }

  public static defaultOnChangeVisibility(name) {
    return (observableName, value) => value;
  }

  public static defaultValue(control) {
    return (controlName) => {
      const modelProperty = PassportGroupBuilderService.getModelJunction(controlName);
      if (PassportGroupBuilderService.isStringKey(modelProperty)) {
        return control.value[modelProperty as string];
      }
      if (PassportGroupBuilderService.isArrayPath(modelProperty)) {
        const {lastKey, lastObj} = PassportGroupBuilderService.getArrayModelValueFromArray(control, modelProperty as Array<string>);
        return lastObj[lastKey];
      }
      return '';
    };
  }

  public static defaultVisibilityValue(property) {
    return true;
  }

  constructor(private fb: FormBuilder) { }

  /**
   * Create reactive form with 3 additional functionalities.
   * 1. When form control is changed update model field.
   * <ul>
   *  <li> model will be stored in control __model__</li>
   *  <li> to update passport field on control change used control name model junction constant {@link  PASSPORT_FORM_MODEL_JUNCTION}.</li>
   *  <li> each observable subscription used to update fields will be stored in control __subscription___</li>
   * </ul>
   * 2. Store visibility variable inside form, which can be used to show or hide corresponding to form control DOM node element.
   *  <ul>
   *  <li> visibility is stored in subgroup __visibility__</li>
   *  </ul>
   * 3. Add visibility change rule as control (visibility change trigger).
   * <ul>
   *  <li> visibility trigger control name is like __visibility__<<trigger_name>> </li>
   *  </ul>
   * @param group enum corresponding to different tab in edit-passport-v2 component template,
   * @param model Passport model
   * @param parentGroup parent of subgroup
   * @param isLongVersion show passport log or short version(default = long version)
   */
  buildSubGroup(group: EPassportTabFormGroup, model: Passport, parentGroup?: FormGroup, isLongVersion = true) {
    const formMeta = this.getMetaData(group);
    const isLongControl = parentGroup ? this.getVisibilityLongControl(parentGroup) : null;
    const isLong = isLongControl !== null ? isLongControl.value : isLongVersion;
    const control =  new FormControl(model);
    return  new PassportTabFormBuilderModel(this.fb, formMeta.long, ...this.defaults(control))
      .addControl(PassportGroupBuilderService.MODEL_CONTROL, control)
      .createDefault()
      .addVisibilityObservable(PassportGroupBuilderService.VISIBILITY_CONTROL, isLong)
      .subscribeToVisibilityObservable(PassportGroupBuilderService.VISIBILITY_CONTROL,
        lodash.difference(formMeta.long, formMeta.short));
  }

  private getMetaData(group: EPassportTabFormGroup) {
    switch (group) {
      case EPassportTabFormGroup.GENERAL:
        return PASSPORT_GENERAL_TAB_MODEL_FORM_JUNCTION;
      case EPassportTabFormGroup.COMPOSITE:
        return PASSPORT_MATERIAL_TAB_MODEL_FORM_JUNCTION;
      case EPassportTabFormGroup.CYCLE:
        return PASSPORT_NEXT_USAGE_TAB_MODEL_FORM_JUNCTION;
      case EPassportTabFormGroup.PERFORMANCE:
        return PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION;
      case EPassportTabFormGroup.ADDITIONAL:
        return PASSPORT_ADDITIONAL_TAB_MODEL_FORM_JUNCTION;
      default:
        throw new Error('Not valid passport tab type: ' + group + ', should be of type EPassportTabFormGroup');
    }
  }

  public addChildGroupToParent(parentGroup: FormGroup
                               , newGroup: FormGroup
                               , groupType: EPassportTabFormGroup
                               , connectToParentVisibility = true
                               , changeStatusIfControlExistInParent = true
  ) {
    const controlName = this.getMetaData(groupType).name;
    if (parentGroup) {
      if (changeStatusIfControlExistInParent) {
        if (parentGroup.get(controlName) && parentGroup.get(controlName).disabled) {
          newGroup.disable();
        }
      }
      parentGroup.setControl(controlName, newGroup);
      if (connectToParentVisibility) {
        this.subscribeToParentVisibility(parentGroup, newGroup);
      }
    }
  }

  private subscribeToParentVisibility(parentGroup: FormGroup, newGroup: FormGroup) {
    if (this.getVisibilityLongControl(parentGroup)) {
      this.getVisibilityLongControl(parentGroup).valueChanges.subscribe(
        (value) => {
          this.getVisibilityLongControl(newGroup).setValue(value);
        }
      );
    }
  }

  getVisibility(group: FormGroup, name: string[]) {
    if (this.getVisibilityLongControl(group)) {
        return name.some(key => !!group.get(PassportTabFormBuilderModel.VISIBILITY_PREFIX).get(key).value);
    }
    return true;
  }

  getVisibilityLongControlValue(group: FormGroup) {
    const control = this.getVisibilityLongControl(group);
    return control && control.value;
  }

  getVisibilityLongControl(group: FormGroup) {
    const visibility = group.get(PassportTabFormBuilderModel.VISIBILITY_PREFIX);
    if (visibility) {
      const control = visibility.get(PassportTabFormBuilderModel.VISIBILITY_PREFIX + PassportGroupBuilderService.VISIBILITY_CONTROL);
      return control ? control : null;
    }
    return null;
  }

  changePassport(group: FormGroup, passport: Passport, updateValues = true) {
    this.updateGroupIfPassportChanged(group, passport, updateValues);
    this.getSubControlsNames().forEach(name => {
      const subGroup = group.get(name);
      if (subGroup instanceof FormGroup) {
        this.updateGroupIfPassportChanged(subGroup, passport, updateValues);
      }
    });
  }

  reset(group: FormGroup, excludedKeys = []) {
    this.resetGroup(group, excludedKeys);
    this.getSubControlsNames().forEach(name => {
      const subGroup = group.get(name);
      if (subGroup instanceof FormGroup) {
        this.resetGroup(subGroup, excludedKeys);
      }
    });
  }

  public get internalUseControls() {
    return [PassportTabFormBuilderModel.VISIBILITY_PREFIX
      , PassportTabFormBuilderModel.SUBSCRIPTION_CONTROL_NAME
      , ...this.getSubControlsNames()
    ];
  }

  private resetGroup(group: FormGroup, excludedKeys) {
    Object.keys(group.controls).filter(
      (key) => ![...this.internalUseControls, ...excludedKeys].includes(key)
    ).forEach((key) => {
        group.get(key).reset();
      }
    );
    group.get(PassportGroupBuilderService.MODEL_CONTROL).setValue(new Passport());
  }

  private getSubControlsNames() {
    const children = [PASSPORT_GENERAL_TAB_MODEL_FORM_JUNCTION
      , PASSPORT_MATERIAL_TAB_MODEL_FORM_JUNCTION
      , PASSPORT_NEXT_USAGE_TAB_MODEL_FORM_JUNCTION
      , PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION
      , PASSPORT_ADDITIONAL_TAB_MODEL_FORM_JUNCTION
    ];
    return children.map(meta => meta.name);
  }

  private updateGroupIfPassportChanged(group: FormGroup, passport: Passport, updateValues = true) {
    const modelControl = group.get(PassportGroupBuilderService.MODEL_CONTROL);
    if (modelControl) {
      modelControl.setValue(passport);
      if (updateValues) {
        Object.keys(group.controls).forEach(controlName => {
          if (PASSPORT_FORM_MODEL_JUNCTION[controlName]) {
            group.get(controlName).setValue(PassportGroupBuilderService.defaultValue(modelControl)(controlName));
          }
        });
      }
    }
  }
  unsubscribe(group: FormGroup) {
    const unsubscribeForm = (formGroup: FormGroup) => {
      const subscriptions = formGroup.get(PassportTabFormBuilderModel.SUBSCRIPTION_CONTROL_NAME).value as Array<Subscription>;
      subscriptions.forEach(sub => sub.unsubscribe());
    };
    unsubscribeForm(group);
    this.getSubControlsNames().forEach(
      (name) => {
        const subGroup = group.get(name);
        if (subGroup) {
          unsubscribeForm(subGroup as FormGroup);
        }
      }
    );
  }

  /**
   * Create reactive form with 3 additional functionalities.
   * 1. When form control is changed update model field.
   * <ul>
   *  <li> model will be stored in control __model__</li>
   *  <li> to update passport field on control change used control name model junction constant
   *   {@link PASSPORT_FORM_MODEL_JUNCTION}
   *  </li>
   *  <li> each observable subscription used to update fields will be stored in control __subscription___</li>
   * </ul>
   * 2. Store visibility variable inside form, which can be used to show or hide corresponding to form control DOM node element.
   *  <ul>
   *  <li> visibility is stored in subgroup __visibility__</li>
   *  </ul>
   * 3. Add visibility change rule as control (visibility change trigger).
   * <ul>
   *  <li> visibility trigger control name is like __visibility__<<trigger_name>> </li>
   * </ul>
   * @param model Passport model
   */
  buildGroupShort(model: Passport) {
    const control =  new FormControl(model);
    return new PassportTabFormBuilderModel(this.fb, PASSPORT_SHORT, ...this.defaults(control))
      .addControl(PassportGroupBuilderService.MODEL_CONTROL, control)
      .createDefault();
  }

  /**
   * Create reactive form with 3 additional functionalities.
   * 1. When form control is changed update model field.
   * <ul>
   *  <li> model will be stored in control __model__</li>
   *  <li> to update passport field on control change used control name model junction constant
   *  {@link  PASSPORT_FORM_MODEL_JUNCTION}.</li>
   *  <li> each observable subscription used to update fields will be stored in control __subscription___</li>
   * </ul>
   * 2. Store visibility variable inside form, which can be used to show or hide corresponding to form control DOM node element.
   *  <ul>
   *  <li> visibility is stored in subgroup __visibility__</li>
   *  </ul>
   * 3. Add visibility change rule as control (visibility change trigger).
   * <ul>
   *  <li> visibility trigger control name is like __visibility__<<trigger_name>> </li>
   *  </ul>
   * @param model Passport model
   * @param isLongVersion show passport log or short version(default = long version)
   */
  buildGroupLong(model: Passport, isLongVersion = true) {
    const control =  new FormControl(model);
    return new PassportTabFormBuilderModel(this.fb, PASSPORT_LONG, ...this.defaults(control))
      .addControl(PassportGroupBuilderService.MODEL_CONTROL, control)
      .createDefault()
      .addVisibilityObservable('long', isLongVersion)
      .subscribeToVisibilityObservable('long', lodash.difference(PASSPORT_LONG, PASSPORT_SHORT));
  }

  private defaults(control: FormControl): [
    (controlName: string) => string
    , (controlName: string) => (val) => any
    , (controlName: string) => boolean
    , (controlName: string) =>  (observableName, value) => boolean
    , (controlName: string) => boolean] {
    return [PassportGroupBuilderService.defaultValue(control)
      , PassportGroupBuilderService.defaultChange(control)
      , PassportGroupBuilderService.defaultVisibilityValue
      , PassportGroupBuilderService.defaultOnChangeVisibility
      , PassportGroupBuilderService.getModelJunction
    ];
  }
}
