import * as tslib_1 from "tslib";
import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
var NeedStats = /** @class */ (function (_super) {
    tslib_1.__extends(NeedStats, _super);
    function NeedStats() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NeedStats.prototype.fields = function () {
        return [
            'published',
            'rejected',
            'done',
            'refused',
            'revenue',
            'in_process',
            'm',
            'y'
        ];
    };
    NeedStats.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        Utils.convertJsonStringToNumber(json, [
            'published',
            'rejected',
            'done',
            'refused',
            'revenue',
            'in_process',
            'm',
            'y'
        ]);
        return json;
    };
    return NeedStats;
}(BaseModel));
export { NeedStats };
