<div class="report-pdf" *ngIf="reportHeader">
  <div class="report-pdf__page">
    <div class="report-pdf__page--header">
      <div class="report-pdf__page--header-texts">
        <p class="report-pdf__page--header-text-first">{{'Report'| translate}} n°{{reportHeader.number}}</p>
        <p class="report-pdf__page--header-text-second">{{getUsersDate() | date: datePattern | lowercase}}</p>
      </div>

      <h1 class="report-pdf__page--header-title" *ngIf="subtitle == null">{{reportHeader.title | translate}}</h1>

      <div class="report-pdf__page--title-wrapper" *ngIf="subtitle != null">
        <h1 class='report-pdf__page--header-title-subtitle'>{{reportHeader.title | translate}}</h1>
        <h3 class="report-pdf__page--header-subtitle" [ngClass]="{'report-pdf__page--header-subtitle--non-italic': noneItalicSubtitle}"> 
          <ng-container *ngIf="!noneItalicSubtitle">
            {{'in case of' | translate}}
          </ng-container>
          {{subtitle | translate}}
        </h3>
      </div>

    </div>
    <div class="report-pdf__page--body">
      <ng-content></ng-content>
    </div>
    <div class="report-pdf__page--footer">
      <p class="report-pdf__page--footer-text">-{{pageNumber}}-</p>
    </div>
  </div>
</div>
