/**
 * Created by atat on 19/06/2017.
 */
import { Component, Inject } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
@Component({
  moduleId: module.id,
  templateUrl: 'deposit-order-details-dialog.component.html'
})
export class DepositOrderDetailsDialogComponent {
  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<void>,) {
  }
}
