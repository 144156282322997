import { EventEmitter, Type } from '@angular/core';
import { LandfillVariables } from '../../dashboards/superadmin/components/landfill-variables/landfill-variables.model';
import { ServiceLocator } from '../services/service-locator';
import { ZoneService } from '../services/zone.service';
import { LANDFILL_ENUM, LANDFILL_LABEL } from '../values/landfill.map';
import { ActiveRecord } from './active-record.class';

export class Zone extends ActiveRecord {
  protected provider: Type<ZoneService> = ZoneService;
  protected zoneService: ZoneService = ServiceLocator.injector.get(ZoneService);

  constructor(data) {
    super(data);
  }

  id: number;
  name: string;
  status: number;
  currency_iso: string;
  passport_count: number;
  landfill_cost_variables: {
    inert_soils: number;
    gravats: number;
    ordinary_industrial_waste: number;
    demolition_wood: number;
    demolition_wood_max: number;
    clean_plaster: number;
    paper_and_cardboard: number;
    plants: number;
  };

  onSave$: EventEmitter<Zone> = new EventEmitter();

  fields() {
    return [
      'id',
      'name',
      'status',
      'currency_iso',
      'landfill_cost_variables',
      'passport_count'
    ];
  }

  publish() {
    this.currency_iso = 'EUR';
    this.save().subscribe((response) => {
      this.zoneService.publish(response.id, {}).subscribe(() => {
        this.saveLandfillVariables(response.id);
      });
    });
  }

  saveZone() {
    this.currency_iso = 'EUR';
    this.save().subscribe((response) => {
      this.saveLandfillVariables(response.id);
    });
  }

  saveLandfillVariables(zoneId: number) {
    this.zoneService.saveLandfillVariables(zoneId, {landfill_cost_variables: this.mapLandfill(zoneId)})
    .subscribe(() => {
      this.onSave$.emit();
    });
  }

  mapLandfill(zone_id: number) {
    return Object.entries(this.landfill_cost_variables).map( landfill => {
      return new LandfillVariables({
        id: LANDFILL_ENUM[landfill[0]],
        name: LANDFILL_LABEL[LANDFILL_ENUM[landfill[0]]],
        zone_id: zone_id,
        value: landfill[1]
      });
    });
  }
}
