import 'rxjs/add/operator/switchMap';
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocationService } from '../service/location.service';
import { ContactService } from '../service/contact.service';
import { ClientService } from '../service/client.service';
import { Client } from '../model/client.class';
import { ClientLocation } from '../model/location.class';
import { Contact } from '../model/contact.class';
import { FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs/Observable';
import { merge as mergeStatic } from 'rxjs';

// mo
import { TranslateService } from '@ngx-translate/core';
import {subscribeToIterable} from 'rxjs/internal-compatibility';
import {catchError} from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'client-detail',
  templateUrl: 'client-detail.component.html'
})
export class ClientDetailComponent implements OnInit, OnDestroy {
  @Input() public clientId: number;
  @Input() public asDialog = false;
  @Output() onSave: EventEmitter<any> = new EventEmitter();



  client: Client;
  clientForm: FormGroup;
  locationCtrl: FormArray;
  contactCtrl: FormArray;
  uploader: FileUploader = new FileUploader({});
  validated = false;

  busy = false;

  public files: FileList;
    // mo
  private _maxStringFieldLength = 32;
  private _minNameFieldLength = 2;
    //

  constructor(public _fb: FormBuilder,
              private router: Router,
              private clientService: ClientService,
              private locationService: LocationService,
              private _translate: TranslateService,
              private contactService: ContactService) {
  }

  ngOnInit() {
    if (this.clientId) {
      this.getClient(this.clientId);
    } else {
      this.setClient(new Client({client_contacts: [], client_locations: []}));
    }
  }
  ngOnDestroy() {
    if (this.clientForm
      && this.clientForm.invalid
      && this.client.id
      && !this.asDialog) {
      this.clientService.remove(this.client.id).subscribe(
        () => {// this.clientList=this.clientList.filter(item=>item!==client)
          console.log('deleting unused client');
        }
        );
      }
   }

  onPlaceChange(place: google.maps.places.PlaceResult, location: FormGroup) {
    let lat = null;
    let lng = null;
    if (place.geometry) {
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
    }

    location.controls['lat'].setValue(lat);
    location.controls['lng'].setValue(lng);
  }

  getClient(id: number) {
    this.clientService
      .view<Client>(id, { expand: this.clientService.relations.join(',') })
      .subscribe((result: Client) => this.setClient(result));
  }

  setClient(client: Client) {
    console.log('SET CLIENT', client);
    this.client = client;
    this.createForm();
  }

  createForm() {
    this.clientForm = this._fb.group({
      name: [this.client.name, [Validators.required, Validators.minLength(this._minNameFieldLength)]],
      address: [this.client.address, [Validators.minLength(this._minNameFieldLength)]],
      logo: [''],
      locations: this._fb.array([]),
      contacts: this._fb.array([])
    });
    this.locationCtrl = <FormArray>this.clientForm.controls['locations'];
    this.contactCtrl = <FormArray>this.clientForm.controls['contacts'];
    this.client.client_contacts.forEach((contact: Contact) => this.addContact(contact));
    this.client.client_locations.forEach((location: ClientLocation) => this.addLocation(location));
    if (this.client.client_contacts.length === 0) {
      this.newContact();
    }
    if (this.client.client_locations.length === 0) {
      this.newLocation();
    }
  }

   //// mohamed ouqas
  isControlInvalid(path: string[] , originForm: string = 'client', i: number = 0): boolean {
    let control = this.clientForm.get(path);

    if ( originForm === 'contact') {
      control = this.contactCtrl.controls[i].get(path[0]) ;
    }
    if ( originForm === 'location') {
      control = this.locationCtrl.controls[i].get(path[0]) ;
    }
    if (control) {
      return !control.valid && control.enabled && control.touched;
    }else {
      return false;
    }
  }

  getControlErrorMessage(path: string[] , originForm: string = 'client' , i: number = 0 ): Observable<any> {
    // i have to know which control and with form to be able to get the right control , that's why i added originform
    // I added the i variable to know which contact or location am i on
    // because they all have the same controls but different on different iteration hens the 'i'
    // console.log(this.contactCtrl.controls);
    let control = this.clientForm.get(path);
    if ( originForm === 'contact') {
      control = this.contactCtrl.controls[i].get(path[0]) ;
    }
    if ( originForm === 'location') {
      control = this.locationCtrl.controls[i].get(path[0]) ;
    }
    const errorCode = !!control && control.errors && Object.keys(control.errors).length > 0
      ? Object.keys(control.errors)[0]
      : null;

    if (errorCode) {

      let translation;
      // console.log(errorCode);
      switch (errorCode) {
        case 'required':
          translation = this._translate.get('is required');
        break;
        case 'minlength':
          translation = this._translate
            .get('length should be between', {
              X: this._minNameFieldLength,
              Y: this._maxStringFieldLength });
        break;
        case 'email':
          translation = this._translate
            .get('has wrong format');
        break;
        case 'pattern':
          translation = this._translate
            .get('has wrong format');
      }
      return translation;
    } else {
      return Observable.of('');
    }
  }
  ///

  addContact(contact: Contact) {
    this.contactCtrl.push(this._fb.group({
      id: [contact.id],
      name: [contact.name, [Validators.required, Validators.minLength(this._minNameFieldLength)] ],
      function: [contact.function],
      phone: [contact.phone, [Validators.pattern(/^\+?[\d\s]+$/) ] ],
      email: [contact.email, [Validators.required, Validators.email ] ],
    }));

  }

  addLocation(location: ClientLocation) {
    this.locationCtrl.push(this._fb.group({
      id: [location.id],
      name: [location.name, [Validators.required, Validators.minLength(this._minNameFieldLength)]],
      address: [location.address, [Validators.required, Validators.minLength(this._minNameFieldLength)]],
      lat: [location.lat, [Validators.required]],
      lng: [location.lng, [Validators.required]],
      description: [location.description],
    }));
  }

  removeContact(index: number) {
    this.contactCtrl.removeAt(index);
    this.contactCtrl.markAsDirty();
    if (this.contactCtrl.length === 0) {
      this.newContact();
    }
  }


  removeLocation(index: number) {
    this.locationCtrl.removeAt(index);
    this.locationCtrl.markAsDirty();
    if (this.locationCtrl.length === 0) {
      this.newLocation();
    }
  }

  newContact() {
    this.addContact(new Contact({ client_id: this.client.id }));
  }

  newLocation() {
    this.addLocation(new ClientLocation({ client_id: this.client.id }));
  }

  getLogoUploaderUrl(): string {
    return this.clientService.getLogoUploadUrl(this.client.id);
  }

  onLogoUpload(response: any) {
    console.log('onLogoUpload', response);
    let data: any = null;
    try {
      data = typeof response === 'string' ? JSON.parse(response) : response;
    } catch (e) {
      console.log(e);
    }
    if (data) {
      this.client.set({ image: data.image });
    }
  }


  disabled(clientForm: FormGroup): boolean {
    if (this.files || clientForm.dirty) {
      if (clientForm.valid) {
        return null;
      }
    }
    return true;
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  save(clientForm: FormGroup): void {

    this.markFormGroupTouched(clientForm);

    if (clientForm.invalid) {
      this.validated = true;
      return;
    }

    if (this.busy) {
      console.warn('Save button is clicked while saving is already in progress');
      return;
    }

    if (clientForm.dirty === true || this.files) {
      this.busy = true;
      this.client.save().subscribe(client => {
        this.client = Object.assign(this.client, client);
        this.updateClientData(clientForm).subscribe(() => {
          this.busy = false;
          this.onSave.emit(this.client);
          this.navigate();
        }, () => this.busy = false);
      }, () => this.busy = false);
    }
  }

  private updateClientData(clientForm) {
    const actions = [];

    if (clientForm.controls['locations'].dirty) {
      actions.push(...this.locationCtrl.controls
        .map((control) => this.saveLocation(<FormArray> control))
      );
    }

    if (clientForm.controls['contacts'].dirty) {
      actions.push(...this.contactCtrl.controls
        .map((control) => this.saveContact(<FormArray> control))
      );
    }
    actions.push(...this.deleteRemovedLocations());
    actions.push(...this.deleteRemovedContacts());
    const observable =  mergeStatic(...actions).map((result) => {
        console.log('check action result:', result);
    });
    console.log(observable);
    return observable;
  }

  private navigate() {
    // Nothing got changed, just return to dashboard
    if (!this.asDialog) {
      this.router.navigate(['sales']);
    }
  }

  saveLocation(locationCtrl: FormArray): Observable<boolean> {
    if (locationCtrl.dirty && locationCtrl.valid) {
      const controls: any = locationCtrl.controls;
      if (controls.id.value) {
        if (!controls.lat.value || !controls.lng.value) {
          console.error('Can\'t save location without coordinates:'
            + '\n\tAddress:' + controls.address.value
            + '\n\tlat:' + controls.lat.value
            + '\tlng:' + controls.lng.value);

          return Observable.of(false);
        } else {
          return this
            .locationService
            .update(controls.id.value, {
              name: controls.name.value,
              address: controls.address.value,
              lat: controls.lat.value,
              lng: controls.lng.value,
              description: controls.description.value
            })
            .map(result => {
              locationCtrl.markAsPristine();
              return true;
            });
        }
      } else {
        if (!controls.lat.value || !controls.lng.value) {
          console.error('Can\'t save location without coordinates:'
            + '\n\tAddress:' + controls.address.value
            + '\n\tlat:' + controls.lat.value
            + '\tlng:' + controls.lng.value);

          return Observable.of(false);
        } else {
          return this.locationService
            .create(new ClientLocation({
              client_id: this.client.id,
              name: controls.name.value,
              address: controls.address.value,
              lat: controls.lat.value,
              lng: controls.lng.value,
              description: controls.description.value
            }))
            .map((result: ClientLocation) => {
              controls.id.setValue(result.id);
              locationCtrl.markAsPristine();
              this.client.client_locations.push(result);

              return true;
            });
        }
      }
    } else {
      return Observable.of(false);
    }
  }

  saveContact(contactCtrl: FormArray): Observable<boolean> {
    if (contactCtrl.dirty && contactCtrl.valid) {
      const controls: any = contactCtrl.controls;
      if (controls.id.value) {
        return this
          .contactService
          .update(controls.id.value, {
            name: controls.name.value,
            function: controls.function.value,
            phone: controls.phone.value,
            email: controls.email.value
          })
          .map(result => {
            contactCtrl.markAsPristine();
            return true;
          });
      } else {
        return this.contactService
          .create(new Contact({
            client_id: this.client.id,
            name: controls.name.value,
            function: controls.function.value,
            phone: controls.phone.value,
            email: controls.email.value
          }))
          .map((result: Contact) => {
            controls.id.setValue(result.id);
            contactCtrl.markAsPristine();
            this.client.client_contacts.push(result);
            return true;
          });
      }
    } else {
      return Observable.of(false);
    }
  }

  deleteRemovedLocations(): Observable<boolean>[] {
    const locationIds: number[] = [];
    this.locationCtrl.controls.forEach((control) => {
      const c = <FormArray>control;
      const controls: any = c.controls;
      if (controls.id.value) {
        locationIds.push(controls.id.value);
      }
    });

    const locationsToDelete: ClientLocation[] = [];
    this.client.client_locations.forEach((location) => {
      if (locationIds.indexOf(location.id) === -1) {
        locationsToDelete.push(location);
      }
    });

    return locationsToDelete
      .map((location) => this.clientService
        .removeLocation(this.clientId, location.id)
        .do((ok) => {
          if (ok) {
            this.client.client_locations
              .splice(this.client.client_locations.indexOf(location), 1);
          }
        })
      );
  }

  deleteRemovedContacts(): Observable<boolean>[] {
    const contactIds: number[] = [];
    this.contactCtrl.controls.forEach((control) => {
      const c = <FormArray>control;
      const controls: any = c.controls;
      if (controls.id.value) {
        contactIds.push(controls.id.value);
      }
    });

    const contactsToDelete: Contact[] = [];
    this.client.client_contacts.forEach((contact) => {
      if (contactIds.indexOf(contact.id) === -1) {
        contactsToDelete.push(contact);
      }
    });

    return contactsToDelete
      .map((contact) => this.clientService
        .removeContact(this.clientId, contact.id)
        .do((ok) => {
          if (ok) {
            this.client.client_contacts
              .splice(this.client.client_contacts.indexOf(contact), 1);
          }
        })
      );
  }

  closeDialog() {
    this.onSave.emit(this.client);
  }

  removeClientLogo() {
    this.clientService.removeLogo(this.client.id).subscribe(() => {
      this.client.image = null;
      console.log('Deleted');
    });
  }
}
