import { StatsContainer } from './stats-container.class';
import { ILiteral } from 'ng2-adn-common';
import { IEcosystemStats } from '../interfaces/ecosystem-stats.interface';
import { EcosystemStats } from './ecosystem-stats.class';

export class EcosystemStatsContainer extends StatsContainer<IEcosystemStats> {

  extractData(data: any): any {
    let json = super.extractData(data);
    this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
    return json;
  }

  createModel(json: ILiteral, key: string[]) {
    key.forEach((k) => {
      if (!!json[k]) {
        json[k] = json[k].map((data: ILiteral) => new EcosystemStats(data));
      }
    });
  }

  getCreatedTotals() {
    let result: any = {};
    let keys = ['total','last_year','last_month','last_week'];
    keys.forEach(key => {
      let created = 0;
      if ((<any>this)[key]) {
        (<any>this)[key].forEach((item: EcosystemStats) => {
          if (item.ecosystems_created) {
            created += item.ecosystems_created;
          }
        });
      }
      result[key] = created;
    });
    return result;
  }

  getDeletedTotals() {
    let result: any = {percentage:{}};
    let keys = ['total','last_year','last_month','last_week'];
    keys.forEach(key => {
      let deleted = 0, created = 0;
      if ((<any>this)[key]) {
        (<any>this)[key].forEach((item: EcosystemStats) => {
          if (item.ecosystems_deleted) {
            deleted += item.ecosystems_deleted;
          }
          if (item.ecosystems_created) {
            created += item.ecosystems_created;
          }
        });
      }
      result[key] = deleted;
      result.percentage[key] = this.calculatePercentage(deleted,created);
    });
    return result;
  }

  getModifiedTotals() {
    let result: any = {percentage:{}};
    let keys = ['total','last_year','last_month','last_week'];
    keys.forEach(key => {
      let modified = 0, created = 0;
      if ((<any>this)[key]) {
        (<any>this)[key].forEach((item: EcosystemStats) => {
          if (item.ecosystems_modified) {
            modified += item.ecosystems_modified;
          }
          if (item.ecosystems_created) {
            created += item.ecosystems_created;
          }
        });
      }
      result[key] = modified;
      result.percentage[key] = this.calculatePercentage(modified,created);
    });
    return result;
  }

  getDoneTotals() {
    let result: any = {};
    let keys = ['total','last_year','last_month','last_week'];
    keys.forEach(key => {
      let done = 0;
      if ((<any>this)[key]) {
        (<any>this)[key].forEach((item: EcosystemStats) => {
          if (item.ecosystems_done) {
            done += item.ecosystems_done;
          }
        });
      }
      result[key] = done;
    });
    return result;
  }

  getInProcessTotals() {
    let result: any = {};
    let keys = ['total','last_year','last_month','last_week'];
    keys.forEach(key => {
      let done = 0;
      if ((<any>this)[key]) {
        (<any>this)[key].forEach((item: EcosystemStats) => {
          if (item.ecosystems_in_process) {
            done += item.ecosystems_in_process;
          }
        });
      }
      result[key] = done;
    });
    return result;
  }

  getInProcessEvolution() {
    let result: any[] = [];
    if (this.total.length) {
      result.push(['Date','In process']);
    }
    this.total.forEach(item => {
      result.push([new Date(item.y, item.m - 1, 1), item.ecosystems_in_process || 0]);
    });
    return result;
  }

  getCanceledTotals() {
    let result: any = {};
    let keys = ['total','last_year','last_month','last_week'];
    keys.forEach(key => {
      let done = 0;
      if ((<any>this)[key]) {
        (<any>this)[key].forEach((item: EcosystemStats) => {
          if (item.ecosystems_cancelled) {
            done += item.ecosystems_cancelled;
          }
        });
      }
      result[key] = done;
    });
    return result;
  }

  getCanceledEvolution() {
    let result: any[] = [];
    if (this.total.length) {
      result.push(['Date','Canceled']);
    }
    this.total.forEach(item => {
      result.push([new Date(item.y, item.m - 1, 1), item.ecosystems_cancelled || 0]);
    });
    return result;
  }

  calculatePercentage(value: number, total: number): string {
    if (total === 0) {
      return '0%';
    }
    return (value / total * 100).toFixed(1) + '%';
  }
}
