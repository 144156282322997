<div [ngClass]="['pie-chart', 'pie-chart--'+pieChartSize]">
  <div class="pie-chart__body">
    <div class="pie-chart__body--label">
      <p class="pie-chart__body--label-first">{{mainValue | number: '1.0-0':'fr' | decimalSeparator:','}}</p>
      <p class="pie-chart__body--label-second">/ {{maxPercent | number: numberFormat(maxPercent, '1.0-0', '1.2-2'):'fr' | decimalSeparator:','}}</p>
    </div>
    <div [ngClass]="{'pie-chart__body--circle-wrapper': true, 'pie-chart__body--circle-wrapper-clip': isMoreThenHalf()}">
      <div
          #leftSide
          [ngStyle]="{'border-color': mainColor}"
          class="pie-chart__body--half-circle"
      ></div>
      <div
          #rightSide
          [ngStyle]="{'border-color': mainColor}"
          class="pie-chart__body--half-circle"
          [ngClass]="{'pie-chart__body--half-circle': true, 'pie-chart__body--right-side': isMoreThenHalf()}"
      ></div>
    </div>
    <div class="pie-chart__body--shadow"></div>
  </div>
</div>
