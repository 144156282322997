import * as tslib_1 from "tslib";
import { LEVELS_BY_TABLE } from '../values/level-by-type.const';
import { ActiveRecord } from '../../shared/models/active-record.class';
import { ImportConfigurationService } from '../services/import-configuration.service';
var ImportField = /** @class */ (function () {
    function ImportField(data) {
        if (data === void 0) { data = {}; }
        var _this = this;
        this.fields()
            .filter(function (item) { return data[item] !== undefined; })
            .forEach(function (item) { return _this[item] = data[item]; });
    }
    ImportField.prototype.fields = function () {
        return [
            'id', 'field_order', 'field_value', 'lookup_table_id'
        ];
    };
    return ImportField;
}());
export { ImportField };
var ImportTable = /** @class */ (function () {
    function ImportTable(data) {
        if (data === void 0) { data = {}; }
        var _this = this;
        this.nbLevels = [];
        this.fields()
            .filter(function (item) { return data[item] !== undefined; })
            .forEach(function (item) { return _this[item] = data[item]; });
        if (this.type || this.type === 0) {
            var numberOfFields = LEVELS_BY_TABLE[this.type];
            this.nbLevels = Array(numberOfFields).fill(null).map(function (item, i) { return i; });
            this.valuesMap = this.nbLevels.reduce(function (acc, item) {
                acc[item] = [];
                return acc;
            }, {});
            this.synonymModelMap = this.nbLevels.reduce(function (acc, item) {
                acc[item] = '';
                return acc;
            }, {});
        }
        if (this.values_list) {
            this.values_list = this.values_list.map(function (item) { return new ImportField(item); });
            this.values_list.reduce(function (acc, curr) {
                acc[curr.field_order] = (acc[curr.field_order] || []);
                acc[curr.field_order].push(curr);
                return acc;
            }, this.valuesMap || {});
        }
    }
    ImportTable.prototype.fields = function () {
        return [
            'id', 'is_active', 'import_configuration_id', 'values_list', 'type'
        ];
    };
    return ImportTable;
}());
export { ImportTable };
var ImportConfiguration = /** @class */ (function (_super) {
    tslib_1.__extends(ImportConfiguration, _super);
    function ImportConfiguration(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.provider = ImportConfigurationService;
        if (_this.tables_list) {
            _this.tables_list = _this.tables_list.map(function (item) { return new ImportTable(item); });
            _this.tableMap = _this.tables_list.reduce(function (acc, key) {
                acc[key.type] = key;
                return acc;
            }, {});
        }
        return _this;
    }
    ImportConfiguration.prototype.fields = function () {
        return [
            'id', 'is_active', 'tables_list', 'user_id', 'used_workers', 'last_import_id', 'last_import_type'
        ];
    };
    return ImportConfiguration;
}(ActiveRecord));
export { ImportConfiguration };
