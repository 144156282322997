import { Component, Input } from '@angular/core';
import { Transform } from '../../../../../entities/transformation/models/transform.class';

@Component({
  moduleId: module.id,
  selector: 'control-type',
  templateUrl: 'control-type.component.html',
  styleUrls: ['control-type.component.scss']
})
export class ControlTypeComponent {
  @Input() transform: Transform;
}
