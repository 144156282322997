import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {IParentEvent} from '../../../../shared/interfaces/parent-event.interface';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Passport} from '../../models/passport.class';
import { PASSPORT_REQUEST } from '../../values/passport-request.const';
import {MdDialog} from '@angular/material';
import {ReadOnlyService} from '../../../../shared/read-only/read-only.service';
import {VIEW_MODE} from '../../values/view-mode-values.const';
import {USED_ENERGY_GROUPS} from '../../values/used-energy-values.const';
import {UsedEnergy} from '../../models/used-energy.class';
import {UsedEnergyService} from '../../services/used-energy.service';
import {forkJoinSafe} from '../../../../shared/helpers/fork-join-safe.helper';
import { Subscription } from 'rxjs';
import {PassportCustomStoreService} from '../../services/passport-custom-store.service';

@Component({
  moduleId: module.id,
  selector: 'passport-used-energy',
  templateUrl: 'passport-used-energy.component.html'
})
export class PassportUsedEnergyComponent implements OnInit {

  @Input() public listener?: EventEmitter<IParentEvent>;
  @Input() public passport?: Passport;
  @Input() public isDisabled?: boolean = false;
  @Input() public viewMode: number;
  @Input() parentFormGroup: FormGroup;
  @Output() onSave = new EventEmitter();

  listCtrl: FormArray;
  usedEnergyGroups = USED_ENERGY_GROUPS;
  subscription: Subscription;
  disableStatus: boolean = false;

  VIEW_MODE = VIEW_MODE;

  constructor(public _fb: FormBuilder,
              public dialog: MdDialog,
              public usedEnergyService: UsedEnergyService,
              @Optional() private passportChildComponentsEventListenerService: PassportCustomStoreService,
              @Optional() public readOnlyService: ReadOnlyService) {
  }

  ngOnInit() {
    this.disableStatus = this.isDisabled;
    if (this.passport) {
      this.changePassport(this.passport).updateSubscription().getUsedEnergy();
    }
    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if (event.key === 'changePassport') {
          this.changePassport(event.data).updateSubscription().getUsedEnergy();
        }
        if (event.key === 'disableStatus') {
          this.disableStatus = event.data.isDisabled;
          this.updateDisableStatus();
        }
        if (event.key === 'savePassport') {
          this.onSave.emit({type: PASSPORT_REQUEST.USED_ENERGY, request: this.save()});
        }
      });
    }
  }

  getUsedEnergy() {
    this.passport.syncUsedEnergy();
  }
  updateSubscription(): PassportUsedEnergyComponent {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.passport.onSyncUsedEnergy$.subscribe((records: any) => this.updateUsedEnergy());
    return this;
  }

  changePassport(passport: Passport): this {
    this.passport = passport;
    return this;
  }

  updateUsedEnergy(): void {
    this.listCtrl = this._fb.array([]);
    if (this.passport.used_energy.length === 0) {
      this.addNew();
    }
    this.passport.used_energy.forEach((usage) => this.addCtrl(usage));
    this.updateDisableStatus();

    if (this.passportChildComponentsEventListenerService) {
      this.passportChildComponentsEventListenerService
      .addFormProgrammaticlyAction
      .emit({controlName: 'usedEnergy', control: this.listCtrl});
    }
  }

  addNew(): void {
    if (this.isDisabled) {
      return;
    }
    const usedEnergy = new UsedEnergy({});
    this.addCtrl(usedEnergy);
  }

  removeUsedEnergy(index: number): void {
    if (this.isDisabled) {
      return;
    }
    const usedEnergyForm: FormArray = <FormArray>this.listCtrl.controls[index];
    if (!usedEnergyForm) {
      console.error('Failed to get used energy form by index (' + index + '):', this.listCtrl);
    }

    const idCtrl = usedEnergyForm.get('id');
    if (!!idCtrl && idCtrl.value) {
      this.deleteUsedEnergy(idCtrl.value);
    }

    this.listCtrl.removeAt(index);
    this.listCtrl.markAsDirty();
    if (this.listCtrl.length === 0) {
      this.addNew();
    }
  }

  addCtrl(usedEnergy: UsedEnergy): void {
    const formGroup = this._fb.group({
      id: [usedEnergy.id],
      energy_id: [usedEnergy.energy_id],
      percentage: [usedEnergy.percentage, [Validators.max(100), Validators.min(0)]],
      optimization_strategy: [usedEnergy.optimization_strategy]
    });
    formGroup.valueChanges.subscribe((newValue) => {
      this.passport.used_energy = this.listCtrl.controls.map((control) => this.getUsedEnergyFromForm(<FormArray>control));
    });
    this.listCtrl.push(formGroup);
  }

  updateDisableStatus(): void {
    if (!this.listCtrl) {
      this.listCtrl = this._fb.array([]);
    }
    if (this.disableStatus) {
      this.listCtrl.controls.forEach((control) => control.disable());
    } else {
      this.listCtrl.controls.forEach((control) => control.enable());
    }
  }

  save() {
    if (this.passport && this.passport.id) {
      const requests = this.listCtrl.controls.map((control) => this.getUsedEnergies(<FormArray>control));
      return forkJoinSafe<UsedEnergy>(requests);
    }
  }

  getUsedEnergies(usedEnergyCtrl: FormArray) {
    const usedEnergy = this.getUsedEnergyFromForm(usedEnergyCtrl);
    if (!!usedEnergy) {
      return usedEnergy.save().map(energy => {
        if (usedEnergyCtrl.get('id') && !!energy.id) {
          usedEnergyCtrl.get('id').setValue(energy.id);
        }
        return energy;
      });
    }
  }

  getUsedEnergyFromForm(form: FormArray): UsedEnergy {
    if (!form) {
      return null;
    }

    const id = form.get('id') ? form.get('id').value : null;
    const energy_id = form.get('energy_id') ? form.get('energy_id').value : null;
    const percentage = form.get('percentage') ? form.get('percentage').value : null;
    const optimization_strategy = form.get('optimization_strategy') ? form.get('optimization_strategy').value : null;

    if (!energy_id && !id && !percentage) {
      return null;
    }

    return new UsedEnergy({
      id: id,
      energy_id: energy_id,
      percentage: percentage,
      passport_id: this.passport ? this.passport.id : null,
      optimization_strategy: optimization_strategy
    });
  }

  deleteUsedEnergy(id: number): void {
    this.usedEnergyService.remove(id).subscribe();
  }

  isRemoveButtonVisible(): boolean {
    return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && this.passport.used_energy.length > 0;
  }
  isPercentagesInvalid(control: AbstractControl): boolean {
    const percentages: AbstractControl = control.get('percentage');
    return (!!percentages && percentages.invalid);
  }

}
