<section class="user-deletion-dialog">
  <dialog-close-button
    (click)="_dialog.close()"
  ></dialog-close-button>
  <h2 md-dialog-title class="user-deletion-dialog__title">
    <ng-container>
      {{ 'USER_DELETION_DIALOG_TITLE' | translate }}
    </ng-container>
  </h2>

  <ul class="user-deletion-dialog__content">
    <li class="user-deletion-dialog__content-item"
        *ngFor="let user of _userList"
        (click)="selectUser(user)"
        [ngClass]="{'user-deletion-dialog__content-item--selected': user === _selectedUser}"
    >
      <ul class="user-deletion-dialog__list">
        <li class="user-deletion-dialog__item">
          <md-input-container>
            <input
              class="user-deletion-dialog__input"
              mdInput
              [(ngModel)]="user.last_name"
              placeholder="{{ 'Last name' | translate }}"
              disabled
            />
          </md-input-container>
        </li>
        <li class="user-deletion-dialog__item">
          <md-input-container>
            <input
              class="user-deletion-dialog__input"
              mdInput
              [(ngModel)]="user.first_name"
              placeholder="{{ 'First name' | translate }}"
              disabled
            />
          </md-input-container>
        </li>
        <li class="user-deletion-dialog__item">
          <md-input-container>
            <input
              class="user-deletion-dialog__input"
              mdInput
              [(ngModel)]="user.username"
              placeholder="{{ 'Login' | translate }}"
              disabled
            />
          </md-input-container>
        </li>
      </ul>
    </li>
  </ul>

  <footer class="user-deletion-dialog__footer">
    <button (click)="transferDataAndDeleteUser()"
            [disabled]="!this._selectedUser"
            class="user-deletion-dialog__delete-button"
    >
      {{ 'Delete and transfer data' | translate }}
    </button>
  </footer>
</section>
