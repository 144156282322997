import { Component, Inject, Optional } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { EcosystemFolderService } from '../../../../../../../entities/ecosystem/services/ecosystem-folder.service';
@Component({
  moduleId: module.id,
  selector: 'create-folder-modal',
  templateUrl: 'create-folder-modal.component.html',
  styleUrls: ['create-folder-modal.component.scss']

})
export class CreateFolderModalComponent {
  warningMessage = '';

  public name = "";
  
  constructor(public mdDialogRef: MdDialogRef<void>,
    public folderService: EcosystemFolderService,
    private _translate: TranslateService,
    @Optional() @Inject(MD_DIALOG_DATA) public mdDialogData: any) { 
      this.name = this.mdDialogData.folderName;
    }

  canCreate() {
    return this.name !== "";
  }
  confirm() {
    this.mdDialogData.isUpdate ?

    this.folderService.updateFolder(this.folderService.currentFolder.id, this.name)
        .subscribe((f) => {
          this.mdDialogRef.close();
          this.folderService.enterFolder(f);
        })

    :
    this.folderService.createFolder(this.name)
        .subscribe((data: any) => {
            // this.ecosystemFolderService.getFolders();
            this.mdDialogRef.close();
            this.folderService.enterFolder(data);
        })
    
  }

  closeDialog() {
    this.mdDialogRef.close();
  }
}
