/**
 * Created by aleksandr on 28.07.17.
 */
import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { CurrentUserService } from '../../shared/services/current-user.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { ModuleGuard } from '../../shared/navigation/module.guard';
var TransformationManagerGuard = /** @class */ (function (_super) {
    tslib_1.__extends(TransformationManagerGuard, _super);
    function TransformationManagerGuard(_navigationService, _userService, _router) {
        var _this = _super.call(this) || this;
        _this._navigationService = _navigationService;
        _this._userService = _userService;
        _this._router = _router;
        _this.context = 'TSM';
        console.log('Launch TransformationManagerGuard');
        return _this;
    }
    return TransformationManagerGuard;
}(ModuleGuard));
export { TransformationManagerGuard };
