<section>
  <ng-content select="[before]"></ng-content>
  <passport-family-view-label [familyType]="passport.family"
                              [unit]="passport.unit + ''"></passport-family-view-label>

  <h2 class="passport-edit__header--name-submenu">
    <div class="passport-edit__header--name-wrapper" [ngClass]="{
    'passport-edit__header--name-wrapper--no-id-button': !showCopyId}">
      <div class="passport-edit__header--name">{{passport.getProductName(multiLanguage)}}
        <span *ngIf="passport.version" style="display: inline-block;">
          {{ ' - V' +passport.version }}
        </span>
      </div>
    </div>
    <button *ngIf="showCopyId"
            class="passport-edit__header--name-id-button background-primary-color"
            read-only-disabled
            (click)="copyId()">
      Id
    </button>
    <ng-container *ngIf="showMenu">
      <button  class="passport-edit__header--name-menu" [mdMenuTriggerFor]="menu">
        <md-icon>menu</md-icon>
      </button>
      <md-menu  #menu x-position="after" y-position="below" [class]="'passport-edit__header--name-menu--wider'">
       <ng-content select="[passport-name-menu]"
       ></ng-content>
      </md-menu>
    </ng-container>
    <ng-content select="[options]"></ng-content>
  </h2>
  <ng-content select="[after]"></ng-content>
</section>
