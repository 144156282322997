<div class="statistics">
    <h1>{{'Segment statistics' | translate}}</h1>
    <div class="statistics__box widget--shadow widget--rounded-corners">
        <div class="statistics__header">
            <div class="statistics__tabs">
                <ul>
                    <li
                            *ngFor="let segment of segments"
                            (click)="changeSegment(segment)"
                            [ngClass]="{'active': segment === selectedSegment}"
                    >{{ AGENT_STATISTICS_SEGMENTS_VIEW[segment] | translate }}</li>
                </ul>
            </div>
        </div>
        <div class="statistics__body" *ngIf="selectedSegment === AGENT_STATISTICS_SEGMENTS.DEPOSITS">
            <div class="statistics__total-stats">
                <div class="stat">
                    <span class="value">{{ segmentStats.deposits_assigned }}</span>
                    <span class="title">{{ 'Assigned' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.deposits_published }}</span>
                    <span class="title">{{ 'Published' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.deposits_characterized }}</span>
                    <span class="title">{{ 'Characterized' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.deposits_characterized_pct }}</span>
                    <span class="title">{{ 'Characterized' | translate }}(%)</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.deposits_rejected }}</span>
                    <span class="title">{{ 'Rejected' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.deposits_rejected_pct }}</span>
                    <span class="title">{{ 'Rejected' | translate }}(%)</span>
                </div>
            </div>
        </div>
        <div class="statistics__body" *ngIf="selectedSegment === AGENT_STATISTICS_SEGMENTS.SITES">
            <div class="statistics__total-stats">
                <div class="stat">
                    <span class="value">{{ segmentStats.locations_assigned }}</span>
                    <span class="title">{{ 'Assigned' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.locations_published }}</span>
                    <span class="title">{{ 'Published' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
