import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
import { ISalesStats } from '../interfaces/sales-stats.interface';

export class SalesStats extends BaseModel implements ISalesStats {
  needs_done: number;
  needs_published: number;
  needs_refused: number;
  needs_treated: number;
  offers_accepted: number;
  offers_rejected: number;
  revenue: number;

  fields(): string[] {
    return [
      'needs_done',
      'needs_published',
      'needs_refused',
      'needs_treated',
      'offers_accepted',
      'offers_rejected',
      'revenue'
    ];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    Utils.convertJsonStringToNumber(json, [
      'needs_done',
      'needs_published',
      'needs_refused',
      'needs_treated',
      'offers_accepted',
      'offers_rejected',
      'revenue'
    ]);
    return json;
  }
}
