import * as tslib_1 from "tslib";
import { NgZone, OnDestroy, OnInit } from '@angular/core';
import { TRANSFORM_TYPES } from '../../values/transform-types.const';
import { ActivatedRoute, Router } from '@angular/router';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { TRANSFORM_DEPENDENCY } from '../../values/transform-dependency.const';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { TranslateService } from '@ngx-translate/core';
var TransformEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TransformEditComponent, _super);
    function TransformEditComponent(route, _navigationService, router, _translate, ngZone) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this._navigationService = _navigationService;
        _this.router = router;
        _this._translate = _translate;
        _this.ngZone = ngZone;
        _this.TRANSFORM_TYPES = TRANSFORM_TYPES;
        _this.parentModule = _this._navigationService.getModuleName();
        return _this;
    }
    TransformEditComponent.prototype.onExistEntity = function (entity) {
        this.transformation = entity;
        this.step = 1;
        this.id = this.transformation.id;
    };
    TransformEditComponent.prototype.onNewEntity = function () {
        this.step = 0;
    };
    TransformEditComponent.prototype.goToProcessStep = function () {
        var _this = this;
        var dependency_type = TRANSFORM_DEPENDENCY.COMPANY;
        if (this.parentModule === ModuleNames.TSMI) {
            dependency_type = TRANSFORM_DEPENDENCY.INDEPENDENT;
        }
        new Transform({
            transformation_type: this.type,
            independent: dependency_type
        }).save().subscribe(function (res) {
            _this.ngZone.run(function () { return _this.router.navigate([_this.parentModule, 'transformations', res.id]); });
        });
    };
    TransformEditComponent.prototype.onAction = function () {
        this.router.navigate([this.parentModule]);
    };
    TransformEditComponent.prototype.getTypeView = function (type) {
        return this._translate.instant(type)
            .replace('/', '\n/').toUpperCase();
    };
    TransformEditComponent.prototype.getImageClass = function () {
        var className = 'transform-edit__item-img';
        if (this.parentModule === ModuleNames.TSMI) {
            className += " " + className + "--tsmi";
        }
        return className;
    };
    return TransformEditComponent;
}(EntityViewerComponent));
export { TransformEditComponent };
