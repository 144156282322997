import { Component, Input, OnInit } from '@angular/core';
import { ToxicityState } from '../../../entities/passport/partials/passport-rating/passport-rating.component';


@Component({
    selector: 'app-cradle-to-cradle-icon',
    templateUrl: './cradle-to-cradle-icon.component.html',
})
export class CradleToCradleIconComponent {
    
}
