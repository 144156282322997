import {ECOSYSTEM_NEED_STEP_STATUS_LABELS, ECOSYSTEM_OFFER_STATUS} from "./ecosystem-offer-status.const";

export const ECOSYSTEM_CALC_ERROR = {
  1: 'ERROR_TYPE_NO_CONVERSION_IN_PASSPORT',
  2: 'ERROR_TYPE_NO_CONVERSION_IN_NEED',
  3: 'ERROR_TYPE_NO_QUANTITY_IN_NEED',
  4: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_NEED',
  5: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_VIRGIN_PRODUCT',
  6: 'ERROR_TYPE_MISSING_PRICE_DATA',
  7: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_DEPOSIT',
  8: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_TRANSFORMATION',
  9: 'ERROR_TYPE_MISSING_CO2_GENERATED_IN_TRANSFORMATION',
  10: 'ERROR_TYPE_MISSING_CO2_GENERATED_IN_TRANSPORT_TRANSFORMATION',
  11: 'ERROR_TYPE_MISSING_INPUT_CONVERSION_FACTOR_IN_TRANSFORMATION',
  12: 'ERROR_TYPE_UNIT_OF_ORDER_DIFFERENT_UNIT_OF_TRANSFORMATION',
  13: 'ERROR_TYPE_MISSING_CO2_EMISSION_IN_PASSPORT_DEPOSIT',
  14: 'ERROR_TYPE_MISSING_CO2_EMISSION_IN_PASSPORT_NEED',
  15: 'ERROR_TYPE_MISSING_MASS_OF_FUNCTIONAL_UNIT_IN_PASSPORT',
  16: 'ERROR_TYPE_MISSING_LINK_BETWEEN_STEPS',
  17: 'ERROR_TYPE_DISTANCE_CANNOT_BE_EVALUATED',
  18: 'ERROR_TYPE_MISSING_CO2_EMISSION_IN_PASSPORT_VIRGIN_PRODUCT',
  19: 'ERROR_TYPE_MISSING_WATER_NET_USAGE_IN_PASSPORT_NEED',
  20: 'ERROR_TYPE_MISSING_WATER_NET_USAGE_IN_TRANSFORMATION'
};


export const ECOSYSTEM_CALC_WASTE_ERRORS: any[] = [
  {id: 1, title: 'ERROR_TYPE_NO_CONVERSION_IN_PASSPORT'},
  {id: 2, title: 'ERROR_TYPE_NO_CONVERSION_IN_NEED'},
  {id: 3, title: 'ERROR_TYPE_NO_QUANTITY_IN_NEED'},
  {id: 4, title: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_NEED'},
  {id: 5, title: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_VIRGIN_PRODUCT'},
];
export const ECOSYSTEM_CALC_ECONOMICS_ERRORS: any[] = [
  {id: 6, title: 'ERROR_TYPE_MISSING_PRICE_DATA'},
];

export const ECOSYSTEM_CALC_CO2_ERRORS: any[] = [
  {id: 1, title: 'ERROR_TYPE_NO_CONVERSION_IN_PASSPORT'},
  {id: 4, title: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_NEED'},
  {id: 5, title: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_VIRGIN_PRODUCT'},

  {id: 7, title: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_DEPOSIT'},
  {id: 8, title: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_TRANSFORMATION'},
  {id: 9, title: 'ERROR_TYPE_MISSING_CO2_GENERATED_IN_TRANSFORMATION'},
  {id: 10, title: 'ERROR_TYPE_MISSING_CO2_GENERATED_IN_TRANSPORT_TRANSFORMATION'},
  {id: 11, title: 'ERROR_TYPE_MISSING_INPUT_CONVERSION_FACTOR_IN_TRANSFORMATION'},
  {id: 12, title: 'ERROR_TYPE_UNIT_OF_ORDER_DIFFERENT_UNIT_OF_TRANSFORMATION'},
  {id: 13, title: 'ERROR_TYPE_MISSING_CO2_EMISSION_IN_PASSPORT_DEPOSIT'},
  {id: 14, title: 'ERROR_TYPE_MISSING_CO2_EMISSION_IN_PASSPORT_NEED'},
  {id: 15, title: 'ERROR_TYPE_MISSING_MASS_OF_FUNCTIONAL_UNIT_IN_PASSPORT'},
  {id: 17, title: 'ERROR_TYPE_DISTANCE_CANNOT_BE_EVALUATED'},
  {id: 18, title: 'ERROR_TYPE_MISSING_CO2_EMISSION_IN_PASSPORT_VIRGIN_PRODUCT'},
];

export const ECOSYSTEM_CALC_CO2_WARNINGS: any[] = [
  {id: 23, title: 'WARNING_TYPE_CO2_EMISSION_NEGATIVE_IN_PASSPORT_NEED'},
];

export const ECOSYSTEM_CALC_WATER_ERRORS: any[] = [
  {id: 1, title: 'ERROR_TYPE_NO_CONVERSION_IN_PASSPORT'},
  {id: 4, title: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_NEED'},
  {id: 8, title: 'ERROR_TYPE_NO_CONFIRMED_QUANTITY_TRANSFORMATION'},
  {id: 12, title: 'ERROR_TYPE_UNIT_OF_ORDER_DIFFERENT_UNIT_OF_TRANSFORMATION'},
  {id: 19, title:'ERROR_TYPE_MISSING_WATER_NET_USAGE_IN_PASSPORT_NEED'},
  {id: 20, title:'ERROR_TYPE_MISSING_WATER_NET_USAGE_IN_TRANSFORMATION'}
]
export const ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_VIRGIN_PRODUCT = 1 ;
export const ERROR_TYPE_NO_CONFIRMED_QUANTITY_DEPOSIT = 7 ;
export const ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_NEED = 4 ;
export const ERROR_TYPE_DISTANCE_CANNOT_BE_EVALUATED = 17;

export const ERROR_TYPE_NO_CONFIRMED_QUANTITY_TRANSFORMATION = 8 ;



export class EcosystemCalcErrorUtil {

  quantityErrors: number[] = [4, 5, 7, 8];
  priceErrors: number[] = [6];
  distanceErrors: number[] = [17];

  isPriceError(error: number): boolean {
    return this.priceErrors.includes(error);
  }

  isQuantityError(error: number): boolean {
    return this.quantityErrors.includes(error);
  }

  isDistanceError(error: number): boolean {
    return this.distanceErrors.includes(error);
  }
}

