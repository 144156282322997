import * as tslib_1 from "tslib";
import { EcosystemPrefillService } from './ecosystem-prefill.service';
import { TransformOrder } from '../models/transform-order.class';
import { EcosystemCompilerSharedActionsService } from './ecosystem-compiler-shared-actions.service';
import * as i0 from "@angular/core";
import * as i1 from "./ecosystem-compiler-shared-actions.service";
import * as i2 from "./ecosystem-prefill.service";
var EcosystemPrefillCommandService = /** @class */ (function () {
    function EcosystemPrefillCommandService(ecosystemCompilerSharedActionsService, prefillService) {
        this.ecosystemCompilerSharedActionsService = ecosystemCompilerSharedActionsService;
        this.prefillService = prefillService;
    }
    /**
     * Prefill service is used to create and manage prefill tree.
     * Prefill tree is an tree of ecosystem orders and offer ids and corresponding in and out quantities.
     * It structure is similiar with order/offer relationship in ecosystem.
     * Initially calculated based on order/offer quantities already exist in ecosystem.
     * It will be automaticly created in on ecosystem edit after it loads ecosystem.
     * Initial creation will be skipped if ecosystem is not editable.
     * Change prefill tree and changes made while saving orders and offer right before ecosystem reload happens.
     * Changes will override order/offer values starting from change place until ecosystem offer.
     * Input quantities are converted(based on the provided conversion factor) and added together to determine the output quantity.
     *
     * @param order Transformation order what has siblings( deposit/s or transformation/s)
     * @param childOrder An deposit or an transformation which is connected to an parent transformation.
     * @returns An observable.
     */
    EcosystemPrefillCommandService.prototype.addOrderToPrefillAndSaveOnReload = function (order, childOrder) {
        order = this.updateOrderPrefillOutput(order, childOrder);
        var orderToSave = order ? [this.getQuantityContainsModel(order)] : [];
        var prefillCalculationParentsChange = {};
        if (!this.prefillService.isOffer(order)) {
            prefillCalculationParentsChange = this.prefillService.prefillUpdateIfOrderChanged(order, false);
        }
        this.ecosystemCompilerSharedActionsService.prefillSetDirty(prefillCalculationParentsChange, orderToSave);
        return this.ecosystemCompilerSharedActionsService.beforeCompilerReload();
    };
    EcosystemPrefillCommandService.prototype.getQuantityContainsModel = function (order) {
        if (this.prefillService.isTransformOrder(order)) {
            return order.out_order_passport;
        }
        else {
            return order;
        }
    };
    EcosystemPrefillCommandService.prototype.prefillCalculateTransformOrderOutputQuantity = function (order, childOrder, inputQuantity) {
        var subs = this.prefillService.getOrderSubstitution(childOrder);
        var quantity = this.prefillService.transformInToOut([{
                input: new TransformOrder(tslib_1.__assign({}, order, { in_order_passport: {
                        quantity: inputQuantity
                    } })),
                substitution: subs
            }]);
        quantity = quantity !== null ? quantity + this.prefillService.getOrderOut(order) : null;
        this.prefillService.setOrderOut(order, quantity);
        return order;
    };
    EcosystemPrefillCommandService.prototype.updateOrderPrefillOutput = function (order, childOrder) {
        if (childOrder && order) {
            var inputQuantity = this.prefillService.getPrefillInput(order, childOrder);
            if (this.prefillService.isTransformOrder(order)) {
                order = this.prefillCalculateTransformOrderOutputQuantity(order, childOrder, inputQuantity);
            }
            else {
                this.prefillService.setOrderOut(order, inputQuantity);
            }
        }
        else if (order) {
            this.prefillService.setOrderOut(order, null);
        }
        return order;
    };
    EcosystemPrefillCommandService.ngInjectableDef = i0.defineInjectable({ factory: function EcosystemPrefillCommandService_Factory() { return new EcosystemPrefillCommandService(i0.inject(i1.EcosystemCompilerSharedActionsService), i0.inject(i2.EcosystemPrefillService)); }, token: EcosystemPrefillCommandService, providedIn: "root" });
    return EcosystemPrefillCommandService;
}());
export { EcosystemPrefillCommandService };
