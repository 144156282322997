<section class="company-deletion-dialog">
  <dialog-close-button
    (click)="_dialog.close()"
  ></dialog-close-button>
  <h2 md-dialog-title class="company-deletion-dialog__title">
    <ng-container>
      {{ 'Do you really want to delete this company? Need to write command: DELETE [COMPANY-ID]' }}
    </ng-container>
  </h2>

  <div>
    <md-input-container class="wide">
      <input mdInput [placeholder]="'Command'"
             autocomplete="off" 
             [(ngModel)]="command">
    </md-input-container>
  </div>

  <footer class="company-deletion-dialog__footer">
    <button (click)="deleteCompany()"
            [disabled]="!this.command || buttonDisabled"
            class="company-deletion-dialog__delete-button"
    >
      {{ 'Delete the whole company'}}
    </button>
  </footer>
</section>
