import * as tslib_1 from "tslib";
import { ReportProblemDialogComponent } from '../../../shared/report-problem-dialog/report-problem-dialog.component';
import { DIALOG_THEME } from '../../../shared/helpers/dialog-themes.const';
import { MdDialog } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
var EcosystemProblemUtilsService = /** @class */ (function () {
    function EcosystemProblemUtilsService(dialog) {
        this.dialog = dialog;
    }
    EcosystemProblemUtilsService.prototype.openOrderProblemDialog = function (description, titleText) {
        if (titleText === void 0) { titleText = 'Order problem'; }
        return this.dialog.open(ReportProblemDialogComponent, tslib_1.__assign({}, DIALOG_THEME.PROBLEM_REPORT, { data: {
                readOnly: true,
                problemDescription: description,
                title: titleText,
            } }));
    };
    EcosystemProblemUtilsService.ngInjectableDef = i0.defineInjectable({ factory: function EcosystemProblemUtilsService_Factory() { return new EcosystemProblemUtilsService(i0.inject(i1.MdDialog)); }, token: EcosystemProblemUtilsService, providedIn: "root" });
    return EcosystemProblemUtilsService;
}());
export { EcosystemProblemUtilsService };
