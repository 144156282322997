import {MD_DIALOG_DATA, MdDialog, MdDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {TransformService} from '../services/transform.service';
import {Transform} from '../models/transform.class';

@Component({
  moduleId: module.id,
  templateUrl: 'transformation-modification-dialog.component.html'
})
export class TransformationModificationDialogComponent {

  public transform: Transform;

  busy: boolean = false;

  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<any>,
              private mdDialog: MdDialog,
              protected _router: Router,
              private transformService: TransformService) {
    this.transform = this.mdDialogData.transform;
  }

  send() {
    if (this.busy) {
      // Don't spam requests if one is already in process
      return;
    }

    this.busy = true;
    this.transformService
      .askForModification(this.transform.id, this.transform)
      .subscribe(() => {
        this.mdDialog.closeAll();
        this._router.navigate(['special_admin']);
      });
  }
}
