import {LEVELS_BY_TABLE} from '../values/level-by-type.const';
import {ActiveRecord} from '../../shared/models/active-record.class';
import {ImportConfigurationService} from '../services/import-configuration.service';
import {DepositLocationImport} from '../../entities/deposit/models/deposit-location-import.class';

export class ImportField {
  field_order: number;
  field_value: string;
  id: number;
  lookup_table_id: number;

  fields(): string[] {
    return [
      'id', 'field_order', 'field_value', 'lookup_table_id'
    ];
  }
  constructor(data = {}) {
    this.fields()
      .filter(item => data[item] !== undefined)
      .forEach(item => this[item] = data[item]);
  }
}

export class ImportTable {
  id: number;
  import_configuration_id: number;
  is_active: boolean;
  type: number;
  values_list: ImportField[];
  nbLevels: number[] = [];
  valuesMap: {[order: number]: ImportField[]};
  synonymModelMap: {[order: number]: string};

  fields(): string[] {
    return [
      'id', 'is_active', 'import_configuration_id', 'values_list', 'type'
    ];
  }
  constructor(data = {}) {
    this.fields()
      .filter(item => data[item] !== undefined)
      .forEach(item => this[item] = data[item]);
    if (this.type || this.type === 0) {
      const numberOfFields = LEVELS_BY_TABLE[this.type];
      this.nbLevels = Array(numberOfFields).fill(null).map((item, i) => i);
      this.valuesMap = this.nbLevels.reduce((acc, item) => {
        acc[item] = [];
        return acc;
      }, {});
      this.synonymModelMap = this.nbLevels.reduce((acc, item) => {
        acc[item] = '';
        return acc;
      }, {});
    }
    if (this.values_list) {
      this.values_list = this.values_list.map(item => new ImportField(item));
      this.values_list.reduce((acc, curr) => {
        acc[curr.field_order] = (acc[curr.field_order] || []);
        acc[curr.field_order].push(curr);
        return acc;
      }, this.valuesMap || {});
    }
  }
}

export class ImportConfiguration extends ActiveRecord {
    id: number;
    is_active: boolean;
    tables_list: ImportTable[];
    tableMap: {[type: number]: ImportTable};
    user_id: number;
    used_workers: number;
    last_import_id: number;
    last_import_type: number;
    last_import_entity: ActiveRecord;
    protected provider = ImportConfigurationService;

    fields(): string[] {
      return [
        'id', 'is_active', 'tables_list', 'user_id', 'used_workers', 'last_import_id', 'last_import_type'
      ];
    }

    constructor(data = {}) {
      super(data);
      if (this.tables_list) {
        this.tables_list = this.tables_list.map((item) => new ImportTable(item));
        this.tableMap = this.tables_list.reduce((acc, key) => {
          acc[key.type] = key;
          return acc;
        }, {});
      }
    }
}

