import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { EDeepStreamMessage } from '../../values/deepstream-message-type.enum';
import { BaseDeepStreamService } from './base-deepstream.service';
var ReportImportDeepStream = /** @class */ (function (_super) {
    tslib_1.__extends(ReportImportDeepStream, _super);
    function ReportImportDeepStream() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.messageTypes = [
            EDeepStreamMessage.DEEPSTREAM_EVENT_DEPOSIT_IMPORT_RESULT
        ];
        return _this;
    }
    ReportImportDeepStream.prototype.emit = function (data) {
        switch (data.type) {
            case EDeepStreamMessage.DEEPSTREAM_EVENT_DEPOSIT_IMPORT_RESULT:
                return ReportImportDeepStream._reportCompletionStream$.emit({ target: this.receiver, data: data });
        }
    };
    Object.defineProperty(ReportImportDeepStream.prototype, "reportCompletionStream$", {
        get: function () {
            return ReportImportDeepStream._reportCompletionStream$;
        },
        enumerable: true,
        configurable: true
    });
    ReportImportDeepStream._reportCompletionStream$ = new EventEmitter();
    return ReportImportDeepStream;
}(BaseDeepStreamService));
export { ReportImportDeepStream };
