import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ReportService } from '../../../../services/report.service';
import { ActivatedRoute } from '@angular/router';
import { ReportBase } from '../report-base/report-base.class';
import { REPORT_NAME } from '../types/report-name.const';
import { LanguageService } from '../../../../../../entities/client/service/language.service';
import { chunkAnArray } from '../../../../../../shared/helpers/chuch-an-array';
import { TranslateService } from '@ngx-translate/core';
import { ReportSiteTagPresenterService } from '../../../../services/report-site-tag-presenter.service';
import { ElRowCounterService } from '../../../../services/el-row-counter.service';
import { ECarbonFootprintReportType } from '../../../../../../dashboards/admin/values/carbon-footprint-status.enum';
import { convertUnit } from '../../../../../../../src/helper/Unithelper';
var CarbonFootprintPdfReportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CarbonFootprintPdfReportComponent, _super);
    function CarbonFootprintPdfReportComponent(reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter) {
        var _this = _super.call(this, reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter) || this;
        _this.reportService = reportService;
        _this.activatedRoute = activatedRoute;
        _this.languageService = languageService;
        _this.translateService = translateService;
        _this.reportPresenter = reportPresenter;
        _this.rowCounter = rowCounter;
        _this.reportName = REPORT_NAME.CARBON_FOOTPRINT;
        _this.pageCounter = 0;
        _this.categoriesTable = []; // Deprecated
        _this.productCategoriesTable = [];
        _this.constructionCategoriesTable = [];
        _this.embodiedCategoriesTable = [];
        _this.icComponentCategoriesTable = [];
        _this.errorsTable = [];
        _this.reportTypes = [];
        _this.categories = []; // Deprecated
        _this.productCategories = [];
        _this.constructionCategories = [];
        _this.embodiedCategories = [];
        _this.icComponentCategories = [];
        return _this;
    }
    CarbonFootprintPdfReportComponent.prototype.ngOnInit = function () {
        this.createReport();
    };
    /**
     * Check whether the list of categories corresponding to the report type is empty or not
     * It is considered empty if only the default category exists, so the length of the categories is 1
     * Just in case, we accept the case where it is equal to 0 too
     * @param {String} reportType - String describing the type of report
     * @returns {Boolean} True if the list of categories is empty, false otherwise
     */
    CarbonFootprintPdfReportComponent.prototype.isEmptySpecificProductCategory = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return Object.keys(this.productCategories).length <= 1;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return Object.keys(this.constructionCategories).length <= 1;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return Object.keys(this.embodiedCategories).length <= 1;
            case ECarbonFootprintReportType.IC_COMPONENT:
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return Object.keys(this.icComponentCategories).length <= 1;
        }
    };
    CarbonFootprintPdfReportComponent.prototype.createReport = function () {
        var _this = this;
        this.reportRequest()
            .subscribe(function () {
            _this.filteredAndSortedProductCategory = _this.getSortedProductsBy('footprint');
            if (_this.result.is_product_stage) {
                _this.reportTypes.push(ECarbonFootprintReportType.PRODUCT_STAGE);
                _this.prepareCategoriesTable(ECarbonFootprintReportType.PRODUCT_STAGE);
            }
            if (_this.result.is_product_and_construction) {
                _this.reportTypes.push(ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION);
                _this.prepareCategoriesTable(ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION);
            }
            if (_this.result.is_embodied_carbon) {
                _this.reportTypes.push(ECarbonFootprintReportType.EMBODIED_CARBON);
                _this.prepareCategoriesTable(ECarbonFootprintReportType.EMBODIED_CARBON);
            }
            if (_this.result.is_ic_component) {
                _this.reportTypes.push(ECarbonFootprintReportType.IC_COMPONENT);
                _this.prepareCategoriesTable(ECarbonFootprintReportType.IC_COMPONENT);
            }
            if (_this.result.is_ic_construction) {
                _this.reportTypes.push(ECarbonFootprintReportType.IC_CONSTRUCTION);
            }
            _this.prepareErrorsTable();
        });
    };
    CarbonFootprintPdfReportComponent.prototype.getCarbonFootPrintCircleAmount = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return convertUnit({ value: this.result.aggregate.total_carbon_footprint, unit: 'tons' });
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return convertUnit({ value: this.result.aggregate.embodied_carbon_total, unit: 'tons' });
            case ECarbonFootprintReportType.IC_COMPONENT:
                return convertUnit({ value: this.result.aggregate.ic_component_total, unit: 'kgs' });
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return convertUnit({ value: this.result.aggregate.ic_construction_total, unit: 'kgs' });
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return convertUnit({ value: this.result.aggregate.product_and_construction_total, unit: 'tons' });
        }
    };
    CarbonFootprintPdfReportComponent.prototype.getCarbonFootprintCircleUnitText = function (reportType) {
        if (this.isReportTypeIcComponentOrConstruction(reportType)) {
            return 'KG_CO2_EQ_SQM';
        }
        else {
            return 'TONS_CO2_EQ';
        }
    };
    CarbonFootprintPdfReportComponent.prototype.getCarbonFootprintDescriptionText = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'PRODUCT_STAGE_DESCRIPTION_TEXT_PDF';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'EMBODIED_CARBON_DESCRIPTION_TEXT_PDF';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'IC_COMPONENT_DESCRIPTION_TEXT_PDF';
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return 'IC_CONSTRUCTION_DESCRIPTION_TEXT_PDF';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'PRODUCT_AND_CONSTRUCTION_DESCRIPTION_TEXT_PDF';
        }
    };
    CarbonFootprintPdfReportComponent.prototype.getCategorySubtitleTextForCarbonFootprint = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'Product stage (A1-A3) (Tons CO2 eq.)';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'Embodied carbon (Tons CO2 eq.)';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'ICcomposant (kg Co2 eq. / sqm)';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'Product & Construction process stage (A1-A5) (Tons CO2 eq.)';
        }
    };
    /**
     * Create the object corresponding to the summary of all the deposits of a report type
     * @param {String} reportType - String describing the type of report
     * @returns {Object}
     */
    CarbonFootprintPdfReportComponent.prototype.getDefaultCategory = function (reportType) {
        var weightProperty = this.getWeightProperty(reportType);
        var depositProperty = this.getDepositProperty(reportType);
        var footprintProperty = this.getFootprintProperty(reportType);
        var emissionProperty = this.getEmissionProperty(reportType);
        return {
            category: { name: 'Total', parent_id: void 0, id: void 0 },
            isDefault: true,
            weight: this.result.aggregate[weightProperty],
            deposit_count: this.result.aggregate.total_deposit_count,
            deposits_considered: this.result.aggregate[depositProperty],
            emission_intensity: this.result.aggregate[emissionProperty],
            footprint: this.result.aggregate[footprintProperty],
            mass_percentage: 100,
            mass_percentage_considered: 100,
            percentage_of_total_footprint: 100,
            product_and_construction_total: this.result.aggregate.product_and_construction_total,
            embodied_carbon_total: this.result.aggregate.embodied_carbon_total,
            ic_component_total: this.result.aggregate.ic_component_total,
            product_and_construction_emission_intensity: this.result.aggregate.total_product_and_construction_emission_intensity,
            embodied_emission_intensity: this.result.aggregate.total_embodied_emission_intensity
        };
    };
    /**
     * Get the property name for the weight depending on the report type
     * @param {String} reportType - String describing the type of report
     * @returns {String}
     */
    CarbonFootprintPdfReportComponent.prototype.getWeightProperty = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'total_weight_considered';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'total_weight_considered_product';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'total_weight_considered_embodied';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'total_weight_considered_ic_comp_const';
        }
    };
    /**
     * Get the property name for the deposit amount depending on the report type
     * @param {String} reportType - String describing the type of report
     * @returns {String}
     */
    CarbonFootprintPdfReportComponent.prototype.getDepositProperty = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'total_deposit_considered';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'total_deposits_considered_product';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'total_deposits_considered_embodied';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'total_deposits_considered_ic_comp_const';
        }
    };
    /**
     * Get the property name for the carbon footprint depending on the report type
     * @param {String} reportType - String describing the type of report
     * @returns {String}
     */
    CarbonFootprintPdfReportComponent.prototype.getFootprintProperty = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'total_carbon_footprint';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'product_and_construction_total';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'embodied_carbon_total';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'ic_component_total';
        }
    };
    /**
     * Get the property name for the category carbon footprint depending on the report type
     * @param {String} reportType - String describing the type of report
     * @returns {String}
     */
    CarbonFootprintPdfReportComponent.prototype.getCategoryFootprintProperty = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'footprint';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'product_and_construction_total';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'embodied_carbon_total';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'ic_component_total';
        }
    };
    /**
     * Get the property name for the emission intensity depending on the report type
     * @param {String} reportType - String describing the type of report
     * @returns {String}
     */
    CarbonFootprintPdfReportComponent.prototype.getEmissionProperty = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'total_emission_intensity';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'total_product_and_construction_emission_intensity';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'total_embodied_emission_intensity';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return null;
        }
    };
    CarbonFootprintPdfReportComponent.prototype.getMainChartHeadlineText = function (reportType) {
        if (this.isReportTypeIcConstruction(reportType)) {
            return 'Ic construction';
        }
        else if (this.isReportTypeIcComponent(reportType)) {
            return 'Ic composant';
        }
        else {
            return this.translateService.instant("Carbon footprint");
        }
    };
    CarbonFootprintPdfReportComponent.prototype.getSubTitleText = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return this.translateService.instant('Production (A1-A3)');
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return this.translateService.instant('Embodied (A1-A5 + B1-B5 + C)');
            case ECarbonFootprintReportType.IC_COMPONENT:
                return this.translateService.instant('Ic composant');
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return 'Ic construction';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return this.translateService.instant('Production & Construction (A1-A5)');
        }
    };
    CarbonFootprintPdfReportComponent.prototype.getTableRenderingBreakPoint = function (tableContentsArray) {
        if (this.reportTypes.length === 1 && this.reportTypes[0] === ECarbonFootprintReportType.IC_CONSTRUCTION) {
            return 1;
        }
        else {
            return tableContentsArray.length;
        }
    };
    CarbonFootprintPdfReportComponent.prototype.getTotalConsideredDepositsByReportType = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return this.result.aggregate.total_deposit_considered;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return this.result.aggregate.total_deposits_considered_embodied;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return this.result.aggregate.total_deposits_considered_ic_comp_const;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return this.result.aggregate.total_deposits_considered_ic_comp_const;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return this.result.aggregate.total_deposits_considered_product;
        }
    };
    CarbonFootprintPdfReportComponent.prototype.getTotalConsideredWeightByReportType = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return this.result.aggregate.total_weight_considered;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return this.result.aggregate.total_weight_considered_embodied;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return this.result.aggregate.total_weight_considered_ic_comp_const;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return this.result.aggregate.total_weight_considered_ic_comp_const;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return this.result.aggregate.total_weight_considered_product;
        }
    };
    CarbonFootprintPdfReportComponent.prototype.getTypeTotalValueParameter = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'footprint';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'embodied_carbon_total';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'ic_component_total';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'product_and_construction_total';
        }
    };
    CarbonFootprintPdfReportComponent.prototype.isReportTypeIcConstruction = function (reportType) {
        return reportType === ECarbonFootprintReportType.IC_CONSTRUCTION;
    };
    CarbonFootprintPdfReportComponent.prototype.isReportTypeIcComponent = function (reportType) {
        return reportType === ECarbonFootprintReportType.IC_COMPONENT;
    };
    CarbonFootprintPdfReportComponent.prototype.isReportTypeIcComponentOrConstruction = function (reportType) {
        return this.isReportTypeIcComponent(reportType) || this.isReportTypeIcConstruction(reportType);
    };
    /**
     * Set the appropriate category list and category table according to the report type
     * @param {String} reportType - String describing the type of report
     */
    CarbonFootprintPdfReportComponent.prototype.prepareCategoriesTable = function (reportType) {
        var _this = this;
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                this.productCategories = this.getCategoryList(reportType);
                this.onEachSpecificChunkedCategory(function (chuck, index) {
                    _this.productCategoriesTable.push({
                        pageNumber: index,
                        pageContent: chuck
                    });
                }, this.productCategories);
                break;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                this.constructionCategories = this.getCategoryList(reportType);
                this.onEachSpecificChunkedCategory(function (chuck, index) {
                    _this.constructionCategoriesTable.push({
                        pageNumber: index,
                        pageContent: chuck
                    });
                }, this.constructionCategories);
                break;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                this.embodiedCategories = this.getCategoryList(reportType);
                this.onEachSpecificChunkedCategory(function (chuck, index) {
                    _this.embodiedCategoriesTable.push({
                        pageNumber: index,
                        pageContent: chuck
                    });
                }, this.embodiedCategories);
                break;
            case ECarbonFootprintReportType.IC_COMPONENT:
                this.icComponentCategories = this.getCategoryList(reportType);
                this.onEachSpecificChunkedCategory(function (chuck, index) {
                    _this.icComponentCategoriesTable.push({
                        pageNumber: index,
                        pageContent: chuck
                    });
                }, this.icComponentCategories);
                break;
        }
    };
    CarbonFootprintPdfReportComponent.prototype.prepareErrorsTable = function () {
        var _this = this;
        this.onEachChunkedError(function (chuck, index) {
            _this.errorsTable.push({
                pageNumber: index + _this.errorsTable.length,
                pageContent: chuck
            });
        });
    };
    /**
     * Get the list of categories depending on the type of report
     * @param {String} reportType - String describing the type of report
     * @returns {ICarbonFootprintCategory[]}
     */
    CarbonFootprintPdfReportComponent.prototype.getCategoryList = function (reportType) {
        var discriminant = this.getDiscriminantProperty(reportType);
        var sortingProperty = this.getCategoryFootprintProperty(reportType);
        return [
            this.getDefaultCategory(reportType)
        ].concat(this.getSortedProductsBy(sortingProperty, null, discriminant));
    };
    /**
     * Get the property name which discriminates which categories should be kept depending on the report type
     * @param {String} reportType - String describing the type of report
     * @returns {String}
     */
    CarbonFootprintPdfReportComponent.prototype.getDiscriminantProperty = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'deposits_considered';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'deposits_considered_product';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'deposits_considered_embodied';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'deposits_considered_ic_comp_const';
        }
    };
    CarbonFootprintPdfReportComponent.prototype.onEachSpecificChunkedCategory = function (onEachChunk, categories) {
        return chunkAnArray(categories, this.categoriesPerPage, this.firstPageCategoriesAmount)
            .forEach(function (chunk, index) { return onEachChunk(chunk, index); });
    };
    /**
     * Get the correct PDF table depending on the type of report
     * @param {String} reportType - String describing the type of report
     * @returns {IReportPDFTable}
     */
    CarbonFootprintPdfReportComponent.prototype.getCategoriesTable = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return this.productCategoriesTable;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return this.embodiedCategoriesTable;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return this.icComponentCategoriesTable;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return this.constructionCategoriesTable;
        }
    };
    /**
     * Get the number of deposits of the category
     * If the category is the default one, get the "deposits_considered" property of the category
     * Else, get the property depending on the type of report
     * @param {ICarbonFootprintCategory} category - Category to get the number of deposits from
     * @param {String} reportType - String describing the type of report
     * @returns {Number} The amount of deposits of the category
     */
    CarbonFootprintPdfReportComponent.prototype.getDepositAmount = function (category, reportType) {
        if (category.isDefault) {
            return category["deposits_considered"];
        }
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return category["deposits_considered"];
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return category["deposits_considered_product"];
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return category["deposits_considered_embodied"];
            case ECarbonFootprintReportType.IC_COMPONENT:
                return category["deposits_considered_ic_comp_const"];
        }
    };
    /**
     * Get the weight of the category
     * If the category is the default one, get the "weight" property of the category
     * Else, get the property depending on the type of report
     * @param {ICarbonFootprintCategory} category - Category to get the number of deposits from
     * @param {String} reportType - String describing the type of report
     * @returns {Number} The weight of the category
     */
    CarbonFootprintPdfReportComponent.prototype.getWeight = function (category, reportType) {
        if (category.isDefault) {
            return category["weight"];
        }
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return category["weight"];
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return category["weight_considered_product"];
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return category["weight_considered_embodied"];
            case ECarbonFootprintReportType.IC_COMPONENT:
                return category["weight_considered_ic_comp_const"];
        }
    };
    /**
     * Get the mass percentage of the category
     * If the category is the default one, get the "mass_percentage_considered" property of the category
     * Else, get the property depending on the type of report
     * @param {ICarbonFootprintCategory} category - Category to get the number of deposits from
     * @param {String} reportType - String describing the type of report
     * @returns {Number} The mass percentage of the category
     */
    CarbonFootprintPdfReportComponent.prototype.getMassPercentage = function (category, reportType) {
        if (category.isDefault) {
            return category["mass_percentage_considered"];
        }
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return category["mass_percentage_considered"];
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return category["mass_percentage_considered_construction"];
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return category["mass_percentage_considered_embodied"];
            case ECarbonFootprintReportType.IC_COMPONENT:
                return category["mass_percentage_considered_ic_comp"];
        }
    };
    CarbonFootprintPdfReportComponent.prototype.initPageNumber = function () {
        this.pageCounter = this.reportTypes.length + this.getStartingNumber();
        return this.pageCounter;
    };
    CarbonFootprintPdfReportComponent.prototype.getPageNumber = function () {
        this.pageCounter += 1;
        return this.pageCounter;
    };
    CarbonFootprintPdfReportComponent.prototype.getStartingNumber = function () {
        return this.showSitesInOnePage ? 2 : 1;
    };
    CarbonFootprintPdfReportComponent.prototype.getTranslation = function (reportType) {
        var x = 0;
        var y = 0;
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                x = this.result.aggregate.partial_deposit_considered;
                y = this.result.aggregate.total_deposit_considered;
                break;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                x = this.result.aggregate.partial_deposits_considered_embodied;
                y = this.result.aggregate.total_deposits_considered_embodied;
                break;
            case ECarbonFootprintReportType.IC_COMPONENT:
                x = this.result.aggregate.partial_deposits_considered_ic_comp_const;
                y = this.result.aggregate.total_deposits_considered_ic_comp_const;
                break;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                x = this.result.aggregate.partial_deposits_considered_ic_comp_const;
                y = this.result.aggregate.total_deposits_considered_ic_comp_const;
                break;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                x = this.result.aggregate.partial_deposits_considered_product;
                y = this.result.aggregate.total_deposits_considered_product;
                break;
        }
        return this.translateService.instant('CS_CARBON_MISSING_DATA', {
            X: x,
            Y: y
        });
    };
    /**
     * Evaluate whether partial data should be displayed or not
     * Partial data should be displayed if there is at least one partial data in the report
     * @param {String} reportType - String describing the type of report
     * @returns {Boolean} Return true if partial data should be displayed, else return false
     */
    CarbonFootprintPdfReportComponent.prototype.shouldDisplayPartialData = function (reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return this.result.aggregate.partial_deposit_considered > 0;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return this.result.aggregate.partial_deposits_considered_embodied > 0;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return this.result.aggregate.partial_deposits_considered_ic_comp_const > 0;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return this.result.aggregate.partial_deposits_considered_ic_comp_const > 0;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return this.result.aggregate.partial_deposits_considered_product > 0;
        }
    };
    CarbonFootprintPdfReportComponent.prototype.shouldDisplayTable = function (reportType) {
        return reportType !== ECarbonFootprintReportType.IC_CONSTRUCTION;
    };
    CarbonFootprintPdfReportComponent.prototype.shoudDisplayEmissionColumn = function (reportType) {
        return reportType !== ECarbonFootprintReportType.IC_CONSTRUCTION && reportType !== ECarbonFootprintReportType.IC_COMPONENT;
    };
    CarbonFootprintPdfReportComponent.prototype.getCarbonValue = function (item, reportType) {
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return item['footprint'];
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return item['embodied_carbon_total'];
            case ECarbonFootprintReportType.IC_COMPONENT:
                return item['ic_component_total'];
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return item['product_and_construction_total'];
        }
    };
    /**
     * Get the emission intensity of the category
     * If the category is the default one, get the "emission_intensity" property of the category
     * Else, get the property depending on the type of report
     * @param {ICarbonFootprintCategory} category - Category to get the number of deposits from
     * @param {String} reportType - String describing the type of report
     * @returns {Number} The emission intensity of the category
     */
    CarbonFootprintPdfReportComponent.prototype.getEmissionValue = function (category, reportType) {
        if (category.isDefault) {
            return category['emission_intensity'];
        }
        switch (reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return category['emission_intensity'];
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return category['product_and_construction_emission_intensity'];
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return category['embodied_emission_intensity'];
            case ECarbonFootprintReportType.IC_COMPONENT:
                return this.translateService.instant('Unknown');
        }
    };
    return CarbonFootprintPdfReportComponent;
}(ReportBase));
export { CarbonFootprintPdfReportComponent };
