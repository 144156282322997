import * as tslib_1 from "tslib";
import { NgZone, OnInit } from '@angular/core';
import { NEED_STATUSES } from '../../../../entities/need/values/need-statuses.const';
import { NeedService } from '../../../../entities/need/services/need.service';
import { FormControl } from '@angular/forms';
import { ECustomListHintClass, ECustomListRecordStatus } from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { Need } from '../../../../entities/need/models/need.class';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { EcosystemOfferService } from '../../../../entities/ecosystem-offer/ecosystem-offer.service';
import { MdDialog } from '@angular/material';
import { SalesOfferDialogComponent } from '../../dialogs/sales-offer-dialog/sales-offer-dialog.component';
import { TabState } from '../../../../entities/need/partials/need-list/need-list.const';
import { OfferTransactionConfirmDialogComponent } from '../../../../entities/ecosystem-offer/dialogs/offer-transaction-confirm-dialog/offer-transaction-confirm-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { EcosystemProblemUtilsService } from '../../../../entities/ecosystem/services/ecosystem-problem-utils.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { EcosystemOffer } from '../../../../entities/ecosystem-offer/ecosystem-offer.model';
import { ECOSYSTEM_NEED_STEP_STATUS_LABELS, ECOSYSTEM_OFFER_STATUS } from '../../../ecosystem-manager/values/ecosystem-offer-status.const';
import { DepositDetailsDialogComponent } from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
var SalesNeedsArchiveComponent = /** @class */ (function () {
    function SalesNeedsArchiveComponent(needService, datePipe, router, _globalEvents, _offerService, _dialog, zone, ecosystemProblemUtilsService, notificationService) {
        var _this = this;
        this.needService = needService;
        this.datePipe = datePipe;
        this.router = router;
        this._globalEvents = _globalEvents;
        this._offerService = _offerService;
        this._dialog = _dialog;
        this.zone = zone;
        this.ecosystemProblemUtilsService = ecosystemProblemUtilsService;
        this.notificationService = notificationService;
        this.filterStatuses = [
            { needStatus: NEED_STATUSES.DONE, checked: true, text: 'CONFIRMED' },
            { needStatus: NEED_STATUSES.REJECTED, checked: false, text: 'REJECTED' },
            { needStatus: NEED_STATUSES.REFUSED, checked: false, text: 'REFUSED' },
            { needStatus: NEED_STATUSES.SUSPENDED, checked: false, text: 'NOT_ACHIEVED' }
        ];
        this.search = new FormControl;
        this.orderBy = '-created_at';
        this.isLoadingNewPage = false;
        this.currentPage = 1;
        this.totalPagesCount = 0;
        this.records = [];
        this.NEED_SORT_ORDER = [
            { key: '-created_at', value: 'Creation date' },
            { key: '-delivery_date', value: 'Delivery date' },
            { key: 'client_name', value: 'Recipient name' },
            { key: 'name', value: 'Name' }
        ];
        this
            .search
            .valueChanges
            .debounceTime(400)
            .subscribe(function () { return _this.getRecords(); });
    }
    SalesNeedsArchiveComponent.prototype.ngOnInit = function () {
        this.getRecords();
    };
    SalesNeedsArchiveComponent.prototype.getRecords = function (forPage) {
        var _this = this;
        if (forPage === void 0) { forPage = 1; }
        if (forPage === 1) {
            this.records = [];
        }
        var statuses = this.getStatusParams();
        var offerStatuses = this.getOfferStatus();
        if (statuses.length <= 0 && !offerStatuses.length) {
            this.records = [];
            return;
        }
        var params = {
            expand: 'client,client_name,sales_contact,esm_name,passport,esm_id,client_location,client_contact,offer',
            search: this.search.value || null,
            delivery_from: this.deliveryDateFrom || null,
            delivery_to: this.deliveryDateTo || null,
            created_from: this.creationDateFrom || null,
            created_to: this.creationDateTo || null,
            'status[]': statuses,
            'sort': this.orderBy,
            paginate: true,
            page: forPage
        };
        this.isLoadingNewPage = true;
        this.needService.getWithPaginationData(params).subscribe(function (res) {
            var headers = res.headers;
            _this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
            _this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
            res.json().map(function (need) {
                var tempNeed = new Need(need);
                tempNeed.offer = need.offer.map(function (item) { return new EcosystemOffer(item); });
                _this.addNeedOrOffer(tempNeed, statuses, offerStatuses);
            });
            _this.isLoadingNewPage = false;
        });
    };
    SalesNeedsArchiveComponent.prototype.addNeedOrOffer = function (need, statuses, offerStatus) {
        var _this = this;
        if (!statuses.includes(need.status) && need.offer && need.offer.length) {
            need.offer.filter(function (item) { return offerStatus.includes(item.status); })
                .forEach(function (item) { return _this.addOfferRecord(need, item); });
        }
        else if (statuses.includes(need.status)) {
            this.addRecord(need);
        }
    };
    SalesNeedsArchiveComponent.prototype.addOfferRecord = function (need, offer) {
        this.addRecord(new Need(tslib_1.__assign({}, need, { offer: [offer] })), true);
    };
    SalesNeedsArchiveComponent.prototype.getStatusParams = function () {
        var status = [];
        this.filterStatuses.forEach(function (st) {
            if (st.checked && !st.offerStatus) {
                status.push(st.needStatus.value);
            }
        });
        return status;
    };
    SalesNeedsArchiveComponent.prototype.getOfferStatus = function () {
        return this.filterStatuses.filter(function (option) { return !!option.offerStatus && option.checked; }).map(function (option) { return option.offerStatus; });
    };
    SalesNeedsArchiveComponent.prototype.addRecord = function (need, isActiveNeed) {
        if (isActiveNeed === void 0) { isActiveNeed = false; }
        this.records.push(this.createNeedRecord(need, ECustomListRecordStatus.NO_STATUS, ECustomListHintClass.NO_CLASS, '', need.name, need.client.name, need.quantity, need.passport.customListUnit, this.datePipe.transform(need.created_at, 'dd.MM.y'), this.datePipe.transform(need.delivery_date, 'dd.MM.y'), need.client_location.name, need.client_contact.name, need.client_contact.email, isActiveNeed));
    };
    SalesNeedsArchiveComponent.prototype.onEndOfListReached = function () {
        if (this.currentPage < this.totalPagesCount && !this.isLoadingNewPage) {
            var newValue = this.currentPage + 1;
            this.getRecords(newValue);
        }
    };
    SalesNeedsArchiveComponent.prototype.createNeedRecord = function (need, status, hintCssClass, hintText, title, subtitle, amount, unit, createdAt, archivedAt, deliverySite, salesName, salesEmail, isActiveNeed) {
        var _this = this;
        var controls = [
            {
                fn: function (data) {
                    _this.goToEditNeed(need);
                },
                name: 'See details'
            }
        ].concat((need.from_deposit && need.from_deposit > 0 ? [{
                name: 'See targeted deposit',
                fn: function () {
                    _this._dialog.open(DepositDetailsDialogComponent, {
                        data: {
                            id: need.from_deposit,
                            readOnly: true
                        }
                    });
                }
            }] : []));
        if (Array.isArray(need.offer) && need.offer.length > 0) {
            controls.push({
                fn: function (data) {
                    _this._dialog
                        .open(SalesOfferDialogComponent, {
                        data: {
                            offer: need.offer[0],
                            need: need
                        },
                    });
                },
                name: 'See offer'
            });
            if (need.status === NEED_STATUSES.DONE.value) {
                controls.push({
                    fn: function (data) {
                        _this._dialog.open(OfferTransactionConfirmDialogComponent, tslib_1.__assign({}, DIALOG_THEME.TRANSACTION_ORDER_CONFIRMATION, { data: {
                                id: need.offer[0].id,
                                disabled: true
                            } }));
                    },
                    name: 'See confirmation'
                });
            }
            if (need.offer[0].status === ECOSYSTEM_OFFER_STATUS.PROBLEM_REPORTED) {
                controls.push({
                    fn: function (data) {
                        return need.offer[0].load({ expand: 'problem' }).subscribe(function (orderWithProblem) {
                            if (orderWithProblem.status === ECOSYSTEM_OFFER_STATUS.PROBLEM_REPORTED) {
                                _this.ecosystemProblemUtilsService.openOrderProblemDialog(orderWithProblem.problem.description || '', "Offer problem");
                            }
                            else {
                                _this.notificationService.error('No result found', null, true);
                                _this.getRecords();
                            }
                        });
                    },
                    name: 'See offer problem'
                });
            }
        }
        if (!isActiveNeed) {
            controls.push({
                fn: function (data) {
                    need.destroy().subscribe(function () { return _this.getRecords(); });
                },
                name: 'Delete'
            });
        }
        return CustomListRecord
            .build()
            .setPrimaryKey(need.id)
            .addImageColumn(need.client.image)
            .setStatus(status)
            .setHint(hintText, hintCssClass)
            .addTitleColumn(title, subtitle)
            .addUnitColumn(amount, unit)
            .addListColumn([
            { key: 'Delivered', value: archivedAt },
            { key: 'Site', value: deliverySite }
        ])
            .addListColumn([{
                key: 'Created', value: createdAt
            }, {
                key: 'Archived', value: archivedAt
            }])
            .addListColumn([{
                key: '', value: salesName
            }, {
                key: '', value: salesEmail
            }])
            .setRecordStatus(isActiveNeed ? this.getOfferStatusText(need.offer[0]) : need.statusData.view)
            .addControlsColumn(controls);
    };
    SalesNeedsArchiveComponent.prototype.getOfferStatusText = function (offer) {
        return ECOSYSTEM_NEED_STEP_STATUS_LABELS[ECOSYSTEM_OFFER_STATUS[offer.status]];
    };
    SalesNeedsArchiveComponent.prototype.openSalesChat = function (esmId) {
        if (esmId) {
            this._globalEvents.chatUserClicked$.emit(esmId);
        }
    };
    SalesNeedsArchiveComponent.prototype.goToEditNeed = function (need) {
        var _this = this;
        if (need.status_data.text === 'PUBLISHED') {
            this.zone.run(function () { return _this.router.navigate(['sales/needs', need.id]); });
        }
        else {
            this.zone.run(function () { return _this.router.navigate(['sales/needs', need.id, 'edit']); });
        }
    };
    SalesNeedsArchiveComponent.prototype.goToNeeds = function () {
        var _this = this;
        this.zone.run(function () { return _this.router.navigate(['sales/needs']); });
    };
    SalesNeedsArchiveComponent.prototype.goToTreatment = function () {
        var _this = this;
        this.zone.run(function () { return _this.router.navigate(['sales/needs'], { fragment: TabState.TREATMENT.text }); });
    };
    return SalesNeedsArchiveComponent;
}());
export { SalesNeedsArchiveComponent };
