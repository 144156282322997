import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './app.component';
import { ApiConfig } from '../shared/config/api.config';
import { CurrentUserService } from '../shared/services/current-user.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthenticatedHttpService } from '../shared/services/authenticated-http.service';
import { LogNotificationService } from '../shared/services/log-notification.service';
import { GlobalEventsManager } from '../shared/services/global-events-manager.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ServiceLocator } from '../shared/services/service-locator';
import { LoginModule } from '../login/login.module';
import { CheckAuthHook } from '../shared/auth/check-auth.guard';

import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2DragDropModule } from 'ng2-drag-drop';


import { AdminModule } from '../dashboards/admin/admin.module';
import { SalesModule } from '../dashboards/sales/sales.module';
import { SuperVisorModule } from '../dashboards/supervisor/supervisor.module';
import { DepositOwnerModule } from '../dashboards/deposit-owner/deposit-owner.module';
import { AgentModule } from '../dashboards/agent/agent.module';
import { SuperadminModule } from '../dashboards/superadmin/superadmin.module';
import { EcosystemManagerModule } from '../dashboards/ecosystem-manager/ecosystem-manager.module';
import { TransformationManagerModule } from '../dashboards/transformation-manager/transformation-manager.module';

import { DeepStreamService } from '../shared/services/deepstream.service';
import { ChatModule } from '../shared/chat/chat.module';
import { DeepStreamInitHook } from '../shared/auth/deepstream.guard';
import {ManufacturerModule} from '../dashboards/manufacturer/manufacturer.module';
import {SpecialAdminModule} from '../dashboards/specialadmin/specialadmin.module';
import {
  TransformationManagerIndependentModule
} from '../dashboards/transformation-manager-independent/transformation-manager-independent.module';
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';

import { PublicPassportListComponent } from './public-passport-list/public-passport-list.component';
import { PassportModule } from '../entities/passport/passport.module';
import { CountryService } from '../shared/services/country.service';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt');
const COMMON_MODULES = [
  LoginModule
];

const APP_ROUTE_HOOKS = [
  DeepStreamInitHook,
  CheckAuthHook
];

const DASHBOARD_MODULES = [
  SuperadminModule,
  SpecialAdminModule,
  AdminModule,
  SalesModule,
  SuperVisorModule,
  DepositOwnerModule,
  AgentModule,
  EcosystemManagerModule,
  TransformationManagerModule,
  TransformationManagerIndependentModule,
  ManufacturerModule
];

@NgModule({
  declarations: [
    AppComponent,
    PublicPassportListComponent,
  ],
  imports: [
    PassportModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    SharedModule.forRoot(ApiConfig),
    FormsModule,
    ReactiveFormsModule,
    Ng2DragDropModule,
    BrowserAnimationsModule,
    VirtualScrollModule,
    ...COMMON_MODULES,
    ...DASHBOARD_MODULES,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {
     provide: APP_BASE_HREF,
     useValue: '/'
    },
    GlobalEventsManager,
    {
      provide: CurrentUserService,
      useClass: CurrentUserService
    },
    {
      provide: HttpClient,
      useClass: AuthenticatedHttpService
    },
    LogNotificationService,
    CountryService,
    DeepStreamService,
    ...APP_ROUTE_HOOKS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}




