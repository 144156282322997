import {Component, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { ReportBase } from '../../report-base/report-base.class';

@Component({
  moduleId: module.id,
  selector: 'pie-chart',
  templateUrl: 'pie-chart.component.html',
  styleUrls: ['pie-chart.component.scss']
})
export class PieChartComponent extends ReportBase implements OnInit {
  @Input() mainValue: number;
  @Input() mainColor: string = '#777';
  @Input() pieChartSize: string;
  @ViewChild('leftSide') leftSide;
  @ViewChild('rightSide') rightSide;
  public degreesInCircle: number = 360;
  public maxPercent:number = 100;
  constructor(private _renderer: Renderer2) {
    super();
  }

  ngOnInit() {
    this.setStyleForCircle();
  }
  isMoreThenHalf(): boolean {
    return this.mainValue >= this.maxPercent / 2;
  }
  setStyleForCircle(): void {
    const amountRotateDeg = this.mainValue * (this.degreesInCircle / this.maxPercent);
    this._renderer.setStyle(this.leftSide.nativeElement, 'transform', 'rotate(' + amountRotateDeg + 'deg)');
  }
}
