import {Directive, HostBinding, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appReadonlyWithoutFocus]',
})
export class ReadonlyWithoutFocusDirective {

  private _isReadOnly = false;

  @HostBinding('readonly') get isReadOnly() {
    return this._isReadOnly;
  }
  @HostBinding() get tabindex() {
    return this._isReadOnly ? -1 : 0;
  }

  @HostBinding('class.form-group__input--without-focus') get style() {
    return this._isReadOnly;
  }

  @Input() set appReadonlyWithoutFocus(isDisabled: boolean) {
    this._isReadOnly = isDisabled;
  }

  @HostListener('focus', ['$event']) undoFocus($event: FocusEvent) {
    $event.target.dispatchEvent(new Event('blur'));
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    return false;
  }

  @HostListener('blur', ['$event']) undoBlur($event: FocusEvent) {
    $event.stopPropagation();
    return false;
  }
}
