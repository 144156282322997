import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Zone } from '../models/zone.class';
import { ExtendedCrudService } from './extended-crud.service';
import { Response } from '@angular/http';
import { GenericPassport } from '../../entities/generic-passport/models/generic-passport.class';

@Injectable()
export class ZoneService extends ExtendedCrudService {
  protected namespace = 'zones';
  protected namespaceSingular = 'zone';
  protected ModelClass = Zone;

  private pagenr: number;

  setListPage(pagenr: number) {
    this.pagenr = pagenr;
  }
  getListPage() {
    return this.pagenr;
  }

  getGenericPassports(id: number, query?: any): Observable<any> {
    return this
      .sendGetWithArray(this.getEndpoint(this.singularEndpoint + `/${id}/generic-passports`), query)
      .catch(this.onError.bind(this));
  }

  public savePassport<T>(zoneId: number, passport: GenericPassport): Observable<any | T> {
    let request = null;
    request = passport.id ?
      this.sendPut(this.getEndpoint(this.singularEndpoint + `/${zoneId}/generic-passports/${passport.id}`), passport) :
      this.sendPost(this.getEndpoint(this.singularEndpoint + `/${zoneId}/generic-passports`), passport);
    return request
      .map((res: Response) => this.buildModel<T>(res.json()))
      .catch(this.onError.bind(this));
  }

  public getLandfillVariables<T>(zoneId: number, query?: any): Observable<any | T> {
    return this.sendGet(this.getEndpoint(this.singularEndpoint + `/${zoneId}/landfill-variables`), {search: query})
    .map(res => res.json())
    .catch(this.onError.bind(this));
  }

  public saveLandfillVariables<T>(zoneId: number, query?: any): Observable<any> {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + `/${zoneId}/landfill-variables/upsert-bulk`), query)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

}
