import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { PassportComponent } from '../models/component.class';

@Injectable()
export class ComponentService extends CrudService {
  protected namespace = 'passport-components';
  protected namespaceSingular = 'passport-component';
  protected ModelClass = PassportComponent;
}
