import { Injectable } from '@angular/core';
import {DepositTag} from '../../../entities/deposit/models/deposit-tag.class';
import {DepositLocation} from '../../../entities/deposit/models/deposit-location.class';

@Injectable({
  providedIn: 'root'
})
export class ReportSiteTagPresenterService {
  private tagsIds: number[] = [];
  private sites: DepositLocation[] = [];
  private siteTags: {[id: number]: DepositTag[]} = {};

  public updateTagsById(tagsIds: number[]) {
    this.tagsIds = tagsIds;
    this.updateSiteTags();
  }

  private updateSiteTags() {
    const tagsIdSet = new Set(this.tagsIds);
    this.sites.forEach(site => this.siteTags[site.id] = site.tags.filter(tag => tagsIdSet.has(tag.id)));
  }

  public updateSiteList(sites: DepositLocation[]) {
    this.sites = sites;
    this.updateSiteTags();
  }

  public getTags(id: number) {
    return this.siteTags[id];
  }
}
