<div class="sidereport">
    <div class="sidereport--title-container">
        <h3 class="sidereport--title" [ngStyle]="{'color': color}" [innerHTML]="title | translate"></h3>
        <div class="sidereport--title-icons">
            <div *ngFor='let leafIndex of leafsCount'>
                <div *ngIf="leafsToDisplay > leafIndex">
                    <i [ngClass]="['sidereport--leaf-icon', imgClassName]"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="sidereport--hr" [ngStyle]="{'border-top-color': color}"></div>
    <div class="sidereport--container">
        <div class="sidereport--toggle" *ngIf="showToggle">
            <i (click)="toggleText()" class="sidereport--toggle-icon" [innerHTML]="isVisible ? '-' : '+'"></i>
        </div>
        <div class="sidereport--description">
            <p [hidden]="!isVisible" [innerHTML]="content">
            </p>
        </div>
    </div>
</div>
