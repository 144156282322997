import * as tslib_1 from "tslib";
import { DepositOrderService } from '../services/deposit-order.service';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { OrderPassport } from './order-passport.class';
import { Deposit } from '../../../entities/deposit/models/deposit.class';
import { DepositLocation } from '../../../entities/deposit/models/deposit-location.class';
import { NomenclatureItem } from '../../../entities/nomenclature/models/nomenclature-item.class';
import { ECOSYSTEM_STEP_STATUS } from '../values/ecosystem-step-status.const';
import { Passport } from '../../../entities/passport/models/passport.class';
import { EcosystemFileJunction } from './ecosystem-file-junction.class';
import { Ecosystem } from './ecosystem.class';
import { User } from '../../../shared/models/user.class';
import { EcosystemStep } from './ecosystem-step.class';
import { EcosystemProblem } from '../../../entities/ecosystem/models/ecosystem-problem.model';
var DepositOrder = /** @class */ (function (_super) {
    tslib_1.__extends(DepositOrder, _super);
    function DepositOrder(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.provider = DepositOrderService;
        _this.order_passport = new OrderPassport(data.order_passport);
        _this.deposit_location = new DepositLocation(data.deposit_location);
        _this.deposit = new Deposit(Object.assign(data.deposit || {}, {
            passport: data.deposit_passport
        }));
        if (data.ecosystem_step) {
            _this.ecosystem_step = new EcosystemStep(data.ecosystem_step);
        }
        _this.nomenclature = (data.nomenclature || []).map(function (nomenclatureItem) { return new NomenclatureItem(nomenclatureItem); });
        _this.ecosystem = data.ecosystem && new Ecosystem(data.ecosystem);
        _this.ecosystem_file_junction = data.ecosystem_file_junction
            ? data.ecosystem_file_junction.map(function (item) { return new EcosystemFileJunction(item); })
            : [];
        _this.owner = new User(data.owner);
        _this.deposit_passport = data.deposit_passport ? new Passport(data.deposit_passport) : null;
        _this.problem = data.problem ? new EcosystemProblem(data.problem) : null;
        return _this;
    }
    DepositOrder.prototype.fields = function () {
        return [
            'id',
            'deposit_id',
            'order_passport_id',
            'step_id',
            'transaction_price',
            'price',
            'status',
            'transaction_date',
            'transaction_description',
            'confirmation_date',
            'publication_date',
            'created_at',
            'updated_at',
            'description',
            'creator',
            'deposit_passport',
            'deposit_photos',
            'ecosystem_file_junction',
            'owner',
            'modification',
            'DO_price',
            'ecosystem_step',
            'DO_price',
            'problem',
            'address'
        ];
    };
    Object.defineProperty(DepositOrder.prototype, "status_text", {
        //TODO ECOSYSTEM_STEP_STATUS shouldn't be used here
        get: function () {
            if (this.status) {
                return ECOSYSTEM_STEP_STATUS[this.status]
                    .replace(/_/g, ' ')
                    .toLowerCase();
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    DepositOrder.prototype.beforeSave = function (data) {
        if (data.status) {
            delete data.status; // avoid step.status !== order.status bug
        }
        return data;
    };
    return DepositOrder;
}(ExtendedActiveRecord));
export { DepositOrder };
