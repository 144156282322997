<passport-edit-form
  [passport]="need.passport"
  [listener]="listener"
  (onChange)="onChangePassport($event)"
  [parentModule]="parentModule"
  [disabled]="controlStates?.passport_id?.disabled"
  [class.highlighted]="controlStates?.passport_id?.highlighted"
  [controlStates]="controlStates?.passport"
  [showCatalogNameMenu]="showCatalogNameMenu"
  (onSaveComplete)="onSaveCompleteHandler($event)"
  [readOnly]="readOnly"
>
  <ng-content passport-search-message select="[passport-search-message]"></ng-content>
</passport-edit-form>
