import { NeedStatus } from '../models/need-status.class';
/**
 * Created by aleksandr on 10.02.17.
 */
 export enum ENeedStatusesView {
  CREATED = 0,
  DRAFT = 1,
  WAITING_FOR_PUBLICATION = 2,
  ASK_FOR_MODIFICATION = 3,
  PUBLISHED = 4,
  TREATED = 5,
  WAITING_FOR_VALIDATION = 6,
  IN_PROGRESS = 7,
  DONE = 8,
  SUSPENDED = 9,
  EXPIRED = 10,
  REFUSED = 11,
  REJECTED = 12,
  WAITING_FOR_CONFIRMATION = 13,
  WAITING_FOR_QUOTE = 14,
  ARCHIVED = 15
};

export const NEED_STATUSES: {
  DRAFT: NeedStatus,
  WAITING_FOR_PUBLICATION: NeedStatus,
  ASK_FOR_MODIFICATION: NeedStatus,
  PUBLISHED: NeedStatus,
  TREATED: NeedStatus,
  WAITING_FOR_VALIDATION: NeedStatus,
  IN_PROGRESS: NeedStatus,
  DONE: NeedStatus,
  SUSPENDED: NeedStatus,
  EXPIRED: NeedStatus,
  REFUSED: NeedStatus,
  REJECTED: NeedStatus,
  WAITING_FOR_CONFIRMATION: NeedStatus,
  WAITING_FOR_QUOTE: NeedStatus,
  ARCHIVED : NeedStatus
} = {
  DRAFT: {
    value: 1,
    text: 'DRAFT',
    view: 'draft',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  WAITING_FOR_PUBLICATION: {
    value: 2,
    text: 'WAITING_FOR_PUBLICATION',
    view: 'Waiting for publication',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  ASK_FOR_MODIFICATION: {
    value: 3,
    text: 'ASK_FOR_MODIFICATION',
    view: 'Asked for modification',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  PUBLISHED: {
    value: 4,
    text: 'PUBLISHED',
    view: 'published',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  TREATED: {
    value: 5,
    text: 'TREATED',
    view: 'Considered',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  WAITING_FOR_QUOTE: {
    value: 6,
    text: 'WAITING_FOR_QUOTE',
    view: 'waiting for quote',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  WAITING_FOR_VALIDATION: {
    value: 7,
    text: 'WAITING_FOR_VALIDATION',
    view: 'Waiting for offer validation',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  IN_PROGRESS: {
    value: 8,
    text: 'IN_PROGRESS',
    view: 'Delivery planned',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  DONE: {
    value: 9,
    text: 'DONE',
    view: 'confirmed',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  SUSPENDED: {
    value: 10,
    text: 'SUSPENDED',
    view: 'not achieved',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  EXPIRED: {
    value: 12,
    text: 'EXPIRED',
    view: 'expired',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  REFUSED: {
    value: 11,
    text: 'REFUSED',
    view: 'refused',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  REJECTED: {
    value: 13,
    text: 'REJECTED',
    view: 'rejected',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  WAITING_FOR_CONFIRMATION: {
    value: 14,
    text: 'WAITING_FOR_CONFIRMATION',
    view: 'waiting for confirmation',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  },
  ARCHIVED: {
    value: 15,
    text: 'ARCHIVED',
    view: 'Archived',
    mapIconUrl: 'assets/png/map/localisation-orange.png',
  }
};

export const ACTIVE_NEED_STATUSES = [
  NEED_STATUSES.DRAFT,
  NEED_STATUSES.WAITING_FOR_PUBLICATION,
  NEED_STATUSES.ASK_FOR_MODIFICATION,
  NEED_STATUSES.PUBLISHED
];

export const ARCHIVED_NEED_STATUSES = [
  NEED_STATUSES.PUBLISHED,
  NEED_STATUSES.REJECTED,
  NEED_STATUSES.ARCHIVED
]