import { Type } from '@angular/core';
import { BIMService } from '../services/bim.service';
import { ActiveRecord } from './active-record.class';

export class BIM extends ActiveRecord {
  protected provider: Type<BIMService> = BIMService;

  id: number;
  name: string;

  fields() {
    return ['name', 'id'];
  }
}
