import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {TransformQuality} from '../models/transform-quality.class';

@Injectable()
export class TransformQualityService extends CrudService {
  protected namespace = 'transformation-qualities';
  protected namespaceSingular = 'transformation-quality';
  protected ModelClass = TransformQuality;
}
