import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { PassportKeywordService } from '../services/keyword.service';
var Keyword = /** @class */ (function (_super) {
    tslib_1.__extends(Keyword, _super);
    function Keyword() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = PassportKeywordService;
        return _this;
    }
    Keyword.prototype.fields = function () {
        return ['id', 'keyword'];
    };
    return Keyword;
}(ActiveRecord));
export { Keyword };
