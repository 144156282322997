import { ElementRef, Input, HostListener, OnInit, Component, ViewChild, SimpleChanges, OnChanges } from '@angular/core';

declare const google: any;

@Component({
  moduleId: module.id,
  selector: 'google-chart',
  template: '',
  styles: [`
    :host {
      display: block;  
      height: 100%;
    }
  `]
})
export class GoogleChartComponent implements OnChanges {
  public _element: any;
  @Input() public chartType: string;
  @Input() public chartOptions: Object;
  @Input() public loadingDelay = 0;
  @Input() public chartData: Object;

  private defaultOptions = {
    vAxis: {
      gridlines: {
        color: 'transparent'
      },
      baselineColor: '#ebebeb',
      minValue: 0
    },
    hAxis: {
      gridlines: {
        color: 'transparent'
      }
    },
    legend: {
      position: 'none'
    },
    pointSize: 8,
    colors: ['#363636']
  };

  constructor(public element: ElementRef) {
    this._element = this.element.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartData']) {
      if(changes['chartData'].previousValue) {
        this.redraw();
      }else {
        google.charts.load('current', {'packages': ['corechart']});
        setTimeout(() => this.redraw());
      }
      this.drawGraph(this.chartOptions, this.chartType, this.chartData, this._element);
    }
  }

  @HostListener('window:resize') onResize(event: Event) {
    this.redraw();
  }

  redraw() {
    this.drawGraph(this.chartOptions, this.chartType, this.chartData, this._element);
  }

  drawGraph(chartOptions: Object, chartType: string, chartData: Object, element: any) {
    google.charts.setOnLoadCallback(drawChart);
    let options = Object.assign(this.defaultOptions, chartOptions) || {};
    function drawChart() {
      const wrapper = new google.visualization.ChartWrapper({
        chartType: chartType,
        dataTable: chartData,
        options: options
      });
      wrapper.draw(element);
    }
  }
}
