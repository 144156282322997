import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import { IParentEvent } from '../../../../shared/interfaces/parent-event.interface';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Passport } from '../../models/passport.class';
import { PASSPORT_REQUEST } from '../../values/passport-request.const';
import { MdDialog } from '@angular/material';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import {ManufacturersService} from '../../services/manufacturers.service';
import {Manufacturers} from '../../models/manufacturers.class';
import {Subscriber} from 'rxjs/Subscriber';
import {forkJoinSafe} from '../../../../shared/helpers/fork-join-safe.helper';
import { Subscription } from 'rxjs';
import {PassportCustomStoreService} from '../../services/passport-custom-store.service';

@Component({
  moduleId: module.id,
  selector: 'passport-manufacturers',
  templateUrl: 'passport-manufacturers.component.html'
})
export class PassportManufacturersComponent implements OnInit {

  @Input() public listener?: EventEmitter<IParentEvent>;
  @Input() public passport?: Passport;
  @Input() public isDisabled?: boolean = false;
  @Input() public viewMode: number;
  @Input() parentFormGroup: FormGroup;
  @Output() onSave = new EventEmitter();

  subscription: Subscription;
  listCtrl: FormArray;

  disableStatus: boolean = false;

  VIEW_MODE = VIEW_MODE;

  constructor(public _fb: FormBuilder,
              public dialog: MdDialog,
              public manufacturersService: ManufacturersService,
              @Optional() private passportChildComponentsEventListenerService: PassportCustomStoreService,
              @Optional() public readOnlyService: ReadOnlyService
  ) {
  }
  getManufacturers() {
    this.passport.syncManufacturers();
  }
  updateSubscription(): PassportManufacturersComponent {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.passport.onSyncManufacturers$.subscribe((records: any) => this.updateManufacturers());
    return this;
  }
  ngOnInit() {
    this.disableStatus = this.isDisabled;
    if (this.passport) {
      this.changePassport(this.passport).updateSubscription().getManufacturers();
    }

    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if(event.key === 'changePassport') {
          this.changePassport(event.data).updateSubscription().getManufacturers();
        }
        if(event.key === 'disableStatus') {
          this.disableStatus = event.data.isDisabled;
          this.updateDisableStatus();
        }
        if (event.key === 'savePassport') {
          this.onSave.emit({type: PASSPORT_REQUEST.MANUFACTURES, request: this.save()});
        }
      });
    }

  }

  changePassport(passport: Passport): this {
    this.passport = passport;
    return this;
  }

  updateManufacturers(): void {
    this.listCtrl = this._fb.array([]);
    if (this.passport.manufacturers.length === 0) {
      this.addNew();
    }
    this.passport.manufacturers.forEach((usage) => this.addCtrl(usage));
    this.updateDisableStatus();

    if (this.passportChildComponentsEventListenerService) {
      this.passportChildComponentsEventListenerService
        .addFormProgrammaticlyAction
        .emit({controlName: 'manufacturers', control: this.listCtrl});
    }
  }

  addNew(): void {
    if (this.isDisabled) {
      return;
    }
    this.addCtrl(new Manufacturers({}));
  }

  removeManufacturer(index: number): void {
    if (this.isDisabled) {
      return;
    }
    const manufacturersForm: FormArray = <FormArray>this.listCtrl.controls[index];
    if (!manufacturersForm) {
      console.error('Failed to get next usage form by index (' + index + '):', this.listCtrl);
    }

    const idCtrl = manufacturersForm.get('id');
    if (!!idCtrl && idCtrl.value) {
      this.manufacturersService.remove(idCtrl.value).subscribe();
    }

    this.listCtrl.removeAt(index);
    this.listCtrl.markAsDirty();
    if (this.listCtrl.length === 0) {
      this.addNew();
    }
  }

  addCtrl(manufacturers: Manufacturers): void {
    const formGroup = this._fb.group({
      id: [manufacturers.id],
      name: [manufacturers.name],
    });
    this.listCtrl.push(formGroup);
  }

  updateDisableStatus(): void {
    if (!this.listCtrl) {
      this.listCtrl = this._fb.array([]);
    }
    if (this.disableStatus) {
      this.listCtrl.controls.forEach((control) => control.disable());
    } else {
      this.listCtrl.controls.forEach((control) => control.enable());
    }
  }

  save() {
    if (this.passport && this.passport.id) {
      const requests = this.listCtrl.controls.map((control) => this.getUsedManufacturers(<FormArray>control));
      return forkJoinSafe<Manufacturers>(requests);
    }
  }

  getUsedManufacturers(usedManufacturersCtrl: FormArray) {
    const manufacturers = this.getManufacturersFromForm(usedManufacturersCtrl);
    if (!!manufacturers) {
      return manufacturers.save().map(manufacturer => {
        if (usedManufacturersCtrl.get('id') && !!manufacturer.id) {
          usedManufacturersCtrl.get('id').setValue(manufacturer.id);
        }
        return manufacturer;
      });
    }
  }

  getManufacturersFromForm(form: FormArray): Manufacturers {
    if (!form) {
      return null;
    }

    const id = form.get('id') ? form.get('id').value : null;
    const name = form.get('name') ? form.get('name').value : null;
    const passport_id = form.get('passport_id') ? form.get('passport_id').value : null;

    if (!name && !id && !passport_id) {
      return null;
    }

    const manufacturers: Manufacturers = new Manufacturers({
      id: id,
      name: name,
      passport_id: this.passport ? this.passport.id : null
    });

    return manufacturers;
  }

  isAddButtonVisible(index: number): boolean {
    return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && index === this.listCtrl.length - 1;
  }

  isRemoveButtonVisible(): boolean {
    return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && this.passport.next_usages.length > 0;
  }

  /**
   * Get the current list of manufacturers
   * @returns {Array<PassportManufacturer>}
   */
  getCurrentManufacturers() {
    return this.listCtrl.getRawValue() || [];
  }
}
