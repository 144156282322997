import { Component, Inject } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { EcosystemFolderService } from '../../../../../../../entities/ecosystem/services/ecosystem-folder.service';
@Component({
  moduleId: module.id,
  selector: 'move-folder-modal',
  templateUrl: 'move-folder-modal.component.html',
  styleUrls: ['move-folder-modal.component.scss']

})
export class MoveFolderModalComponent {
  warningMessage = '';
  confirmationMode : boolean = false;
  public name = "";
  public targetFolder : any = {};
  public moveToRoot = false;

  constructor(public mdDialogRef: MdDialogRef<void>,
    public folderService: EcosystemFolderService,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any,
    private _translate: TranslateService) { }

  move() {
    this.confirmationMode = true;
  }
  confirm() {
    this.folderService.moveFolder(this.targetFolder.id, this.moveToRoot)
    .subscribe((data: any) => {
        console.log(data);
        this.mdDialogRef.close();
        this.folderService.resetSelection();
        
        this.moveToRoot ? 
        this.folderService.leaveFolder() :
        this.folderService.enterFolder(this.targetFolder);
    })
    
  }

  closeDialog() {
    this.mdDialogRef.close();
  }

  getTranslation() {
    return this.moveToRoot ?
    this.getTranslationRoot() :
    this.getTranslationFolder();
  }

  getTranslationFolder() {
    return this._translate.get('MOVE_WARNING_IF_FOLDER', {
      X: this.mdDialogData.nbSelection,
      Y: this.targetFolder.name
    })
  }

  getTranslationRoot() {
    return this._translate.get('MOVE_WARNING_IF_ROOT', {
      X: this.mdDialogData.nbSelection,
    })
  }

  onCheckbox(e){
    this.targetFolder = {};
  } 

  computeFolders() {
    const isTheSame = this.folderService.selection.every( (val, i, arr) => val.parentId === arr[0].parentId );
    return isTheSame ?  this.folderService.allFolders.filter((v) => v.id !== this.folderService.selection[0].parentId)
    : this.folderService.allFolders;
  }
}
