import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import { Passport } from '../../models/passport.class';


@Component({
  moduleId: module.id,
  selector: 'view-passport-dialog',
  templateUrl: 'view-passport-dialog.component.html'
})

/**
 * @deprecated
 */
export class ViewPassportDialogComponent{

  public passportId: number;
  private passport: Passport;

  constructor(private dialog: MdDialogRef<any>,
              @Inject(MD_DIALOG_DATA) public mdDialogData: any) {

  }

  onSave(passport: Passport) {
    console.log('onSave', passport);
  }

}
