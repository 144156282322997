import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DepositFilter} from '../../../../../../deposit/models/deposit-filter';
import {IPassportRatingWithLocalPassportFilter} from '../../../../../values/passport-rating/passport-rating-filter.interface';

@Component({
  selector: 'rating-with-local-passport-filter',
  templateUrl: './passport-rating-with-local-passport.component.html'
})
export class PassportRatingWithLocalPassportComponent {
  @Input() public ratingNameAlias: Partial<{[x in  keyof IPassportRatingWithLocalPassportFilter]: string}> = {
    passportWithoutRating: 'Passports without rating',
    selectRatingFilter: 'Select all rating'
  };
  @Input() public ratingIconAlias: Partial<{[x in  keyof IPassportRatingWithLocalPassportFilter]: string}> = {
    passportWithoutRating: '',
    selectRatingFilter: ''
  };

  @Input() public ratingClassAlias: Partial<{[x in  keyof IPassportRatingWithLocalPassportFilter]: string}> = {
    passportWithoutRating: 'passportlist__info-rating-filter-row-select',
    C2cCertified: 'passportlist__info-rating-filter-row-select'
  };
  @Input() classList = ['depositlist__info-rating'];
  @Input() ratingApplyRule: (rating: object) => boolean = this.defaultRatingApplyRule.bind(this);
  @Input() defaultRatings = {};
  @Output() ratingFilterChange = new EventEmitter<IPassportRatingWithLocalPassportFilter>();
  @Output() onAnySelected = new EventEmitter<boolean>();

  ratingFilterView: IPassportRatingWithLocalPassportFilter;
   ratingFilter: IPassportRatingWithLocalPassportFilter;

  @Input('ratingFilter') set setRating(rating: IPassportRatingWithLocalPassportFilter) {
    this.ratingFilter = rating;
    this.ratingFilterView = {...rating};
  }

  constructor() { }

  updateRatingFilter($event) {
    const isOnAllRatingClicked = $event.selectRatingFilter !== this.ratingFilter.selectRatingFilter;
    this.ratingFilterView = {...this.ratingFilter};
    this.ratingFilter = $event;
    this.updateSelectAllRatingFilter(isOnAllRatingClicked);
    this.ratingFilterChange.emit(this.ratingFilter);
  }

  private updateSelectAllRatingFilter(isOnAllRatingClicked: boolean) {
    if (isOnAllRatingClicked) {
      this.runOnSelectAll(this.ratingFilter.selectRatingFilter);
    }
    const isAllRatedSelected = this.isAllRatingSelected();
    this.ratingFilter.selectRatingFilter = isAllRatedSelected;
    this.ratingFilter.selectRatingFilter = isAllRatedSelected;
    this.ratingNameAlias = {
      ...this.ratingNameAlias,
      selectRatingFilter: (isAllRatedSelected ? 'Unselect all rating' : 'Select all rating')
    };
    this.onAnySelected.emit((isAllRatedSelected ? true : this.isAnyRatingSelected()) || this.ratingFilter.C2cCertified);
  }

  private runOnSelectAll(isChecked: boolean) {
    const filters = this.ratingFilter;
    const {simpleFilterKeyList, customFilterList} = this.getRatingSelectConnectedField();
    simpleFilterKeyList.forEach(item => {
      this.ratingFilter[item] = isChecked;
      filters[item] = isChecked;
    });
    customFilterList.forEach(item => {
      item.color = isChecked;
      item.black = isChecked;
    });
  }

  private isAllRatingSelected() {
    const filters = this.ratingFilter;
    const {simpleFilterKeyList, customFilterList} = this.getRatingSelectConnectedField();
    return simpleFilterKeyList.every(item => !!filters[item]) && customFilterList.every(item => item.color && item.black);
  }

  private isAnyRatingSelected() {
    const filters = this.ratingFilter;
    const {simpleFilterKeyList, customFilterList} = this.getRatingSelectConnectedField();
    return simpleFilterKeyList.some(item => !!filters[item]) || customFilterList.some(item => item.color || item.black);
  }

  private getRatingSelectConnectedField() {
    const filters = this.ratingFilter;
    const simpleFilterKeyList = [];
    const customFilterList = [filters.composition, filters.cycle, filters.energy, filters.social, filters.water];
    return {simpleFilterKeyList, customFilterList};
  }

  public defaultRatingApplyRule() {
    const assertField = (actualKey: string, actualObj = this.ratingFilter, expectedObj = this.defaultRatings) => {
      if (!actualObj || !expectedObj) return false;
  
      return !!actualObj[actualKey] !== !!expectedObj[actualKey];
    };
  
    const isObject = (data) => typeof data === 'object' && data !== null;
    if (!isObject(this.ratingFilter)) return false;
  
    return Object.keys(this.ratingFilter).some(ratingKey => {
      if (isObject(this.ratingFilter[ratingKey])) {
        const expected = isObject(this.defaultRatings[ratingKey]) ? this.defaultRatings[ratingKey] : {};
        const actual = this.ratingFilter[ratingKey] ? this.ratingFilter[ratingKey] : {};  
        return Object.keys(actual).some((subRatingKey) => assertField(subRatingKey, actual, expected));
      }
      return assertField(ratingKey);
    });
  }
  
}
