<div class="client">

  <div class="client__change">
    <button md-raised-button class="client__change-client uppercase"  (click)="addClient()">{{ 'New recipient' | translate }}</button>
  </div>

  <div class="client__search-wrapper">
    <md-input-container class="client__search-anything">
      <input mdInput autocomplete="off" placeholder="{{ 'Search for recipient' | translate }}" [formControl]="searchControl">
    </md-input-container>
  </div>

  <article class="client__list" [perfectScrollbar]>
    <div class="client__list-item"
         *ngFor="let record of clientList | clientSearch:searchTerm">
      <client-item
        [client]="record"
        (onDelete)="deleteRecord($event)"
        (onDuplicate)="duplicateRecord($event)"
        (onOpen)="openRecord($event)"
      ></client-item>

    </div>
  </article>

</div>
<!--<need-list></need-list>-->


<!--<div>-->
<!--<md-card class="card">-->
<!--<md-card-title>Clients</md-card-title>-->
<!--</md-card>-->
<!--<form>-->
<!--<md-card class="card">-->
<!--<md-toolbar>-->
<!--<md-icon>search</md-icon>-->
<!--<md-input-container>-->
<!--<input mdInput placeholder="Search">-->
<!--</md-input-container>-->
<!--<span class="spacer"></span>-->
<!--<md-icon class="example-icon" (click)="addClient()">person_add</md-icon>-->
<!--</md-toolbar>-->
<!--</md-card>-->
<!--</form>-->

<!--<div *ngIf="clientList">-->
<!--<md-card class="card" *ngIf="clientList.length">-->
<!--<md-toolbar *ngFor="let client of clientList">-->
<!--&lt;!&ndash; <div md-card-avatar><img [src]="client.logo" [alt]="client.name"></div> &ndash;&gt;-->
<!--<md-card-title (click)="onSelect(client)">{{client.name}}</md-card-title>-->
<!--<md-card-subtitle>{{client.address}}</md-card-subtitle>-->
<!--<md-card-subtitle>{{client.contact_name}}</md-card-subtitle>-->
<!--<span class="spacer"></span>-->
<!--<md-icon class="example-icon" (click)="copyClient(client)">content_copy</md-icon>-->
<!--<md-icon class="example-icon" (click)="onSelect(client)">edit</md-icon>-->
<!--<md-icon class="example-icon" (click)="deleteClient(client)">delete</md-icon>-->
<!--</md-toolbar>-->
<!--</md-card>-->
<!--</div>-->
<!--</div>-->

<!--<client-form></client-form>-->
