import {environment} from '../../../src/environments/environment';
import {ERoleIds} from '../../../src/shared/values/role-ids.enum';
import {USER_CONTEXTS as contexts} from '../../../src/shared/auth/user-contexts.const';
import {UserRole} from '../../../src/shared/models/user.class';
import {
  CustomListStatusClass,
  ECustomListColumnType,
  ECustomListRecordStatus
} from '../../../src/shared/custom-list/interfaces/custom-list.interface';
import {OrganisationTIds} from '../../../src/shared/values/company-values.enum';
import {PASSPORT_UNITS_TYPE} from '../../../src/entities/passport/values/passport-units.const';
import {DEPOSIT_STATUSES as DEPOSIT_STATUSES_SOURCE} from '../../../src/entities/deposit/values/deposit-statuses.const';
import {NEED_STATUSES} from '../../../src/entities/need/values/need-statuses.const';
import {PASSPORT_STATUS_CONST} from '../../../src/entities/passport/values/passport-status.const';

export  namespace SourceConnectorService {
  export const ENV = environment;
  export import ERoles = ERoleIds;
  export const USER_CONTEXTS = contexts;
  export type IUserRole = UserRole;
  export import ECustomListColumn = ECustomListColumnType;
  export enum EEntityGETEndpoint {
    DEPOSITS =  'deposits',
  }
  export const NEED_STATUS = NEED_STATUSES;
  export const PASSPORT_STATUS = PASSPORT_STATUS_CONST;
  export import ECompanyOrganization  = OrganisationTIds;
  export const PASSPORT_UNIT_TYPE = PASSPORT_UNITS_TYPE;
  export const DEPOSIT_STATUSES = DEPOSIT_STATUSES_SOURCE;
  export const CUSTOM_LIST_STATUS_VALUES =  CustomListStatusClass
    .reduce((accumulator, statusClass, i) => {
      accumulator[i] = CustomListStatusClass[i];
      return accumulator;
    }, {});
  export import ECustomListStatus = ECustomListRecordStatus;
}

