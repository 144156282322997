<section class="login">
  <div class="login__login-content">
    <div class="login__image-container">
      <img md-card-image src="../assets/png/login_logo.png" id="login_png">
    </div>

    <div *ngIf="sent">
      {{ 'Email has been sent' | translate}}
    </div>
    <div class="login__error">
      <p *ngIf="failed">
        {{ 'No user with this username' | translate}}
      </p>
    </div>
    <div class="login__button-group" *ngIf="!sent">
      <div class="login__send">
        <md-input-container>
            <input
              mdInput
              autocomplete="off" 
              [(ngModel)]="username"
              name="username"
              placeholder="{{ 'Username'| translate }}"
              autofocus
            >
        </md-input-container>
      </div>
      <div class="login__button-group">
        <md-card-actions>
          <button
            md-raised-button
            color="primary"
            (click)="send()"
          >{{ 'Send email' | translate}}
          </button>
        </md-card-actions>
        <div class="login__forgot-pas"
             (click)="goToLoginPage()">
          <input type="button"
                 value="{{'Sign in' | translate}}"/>
        </div>
      </div>
    </div>
  </div>

  <div class="login__lang">
    {{'Language' | translate}}:
    <language-selector></language-selector>
  </div>

</section>
