import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { TransformFileService } from '../../../../entities/transformation/services/transform-file.service';
import { Router } from '@angular/router';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { TRANSFORM_STATUSES } from '../../values/transform-statuses.const';
import { TransformService } from '../../../../entities/transformation/services/transform.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { MdDialog } from '@angular/material';
import { TransformationModificationDialogComponent } from '../../../../entities/transformation/transformation-modification-dialog/transformation-modification-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { TRANSFORM_TYPES } from '../../values/transform-types.const';
import { TAKE_BACK_MODALITIES } from '../../values/take-back-modalities.const';
import { PASSPORT_UNITS } from '../../../../entities/passport/values/passport-units.const';
import { TransformMaterial } from '../../../../entities/transformation/models/transform-material.class';
import { ViewNomenclatureDialogComponent } from '../../../../entities/passport/dialogs/view-nomenclature/view-nomenclature.component';
import { ViewCategoryDialogComponent } from '../../../../entities/passport/dialogs/view-category/view-category.component';
import { CategoryItem } from '../../../../entities/category/models/category-item.class';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { TransformationCategoryService } from '../../../../entities/category/service/transformation-category.service';
import { CategoryService } from '../../../../entities/category/service/category.service';
import { TranslationService } from '../../../../shared/services/translation.service';
import { FormBuilder, Validators } from '@angular/forms';
var TransformDetailsComponent = /** @class */ (function () {
    function TransformDetailsComponent(transformFileService, readOnlyService, transformService, _navigationService, fb, logNotificationService, router, _mdDialog, _translate, transformationCategoryService, categoryService, translationService) {
        this.transformFileService = transformFileService;
        this.readOnlyService = readOnlyService;
        this.transformService = transformService;
        this._navigationService = _navigationService;
        this.fb = fb;
        this.logNotificationService = logNotificationService;
        this.router = router;
        this._mdDialog = _mdDialog;
        this._translate = _translate;
        this.transformationCategoryService = transformationCategoryService;
        this.categoryService = categoryService;
        this.translationService = translationService;
        this.readOnly = false;
        this.action = new EventEmitter();
        this.TRANSFORM_STATUSES = TRANSFORM_STATUSES;
        this.moduleNames = ModuleNames;
        this.filters = TAKE_BACK_MODALITIES;
        this.units = PASSPORT_UNITS;
        this.validateChild$ = new EventEmitter();
    }
    TransformDetailsComponent.prototype.removeMaterial = function (material) {
        var _this = this;
        material.destroy().subscribe(function () {
            var index = _this.transform.materials.indexOf(material);
            if (index !== -1) {
                _this.transform.materials.splice(index, 1);
            }
        });
    };
    TransformDetailsComponent.prototype.addMaterial = function (isInput) {
        var _this = this;
        console.log('addMaterial', isInput);
        var dialogRef = this._mdDialog.open(ViewNomenclatureDialogComponent, {
            height: '80%',
            width: '80%',
        });
        dialogRef.afterClosed().subscribe(function (nomenclature) {
            if (nomenclature) {
                var material_1 = new TransformMaterial();
                material_1.is_input = isInput;
                material_1.transformation_id = _this.transform.id;
                material_1.nomenclature_id = nomenclature.id;
                material_1.name = nomenclature.name;
                material_1.save().subscribe(function (res) {
                    material_1.id = res.id;
                    _this.transform.materials.push(material_1);
                });
            }
        });
    };
    TransformDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({
            name: ['', Validators.required],
            englishName: ['', Validators.required]
        });
        this.readOnlyService.readOnly = this.readOnly;
        this.parentModule = this._navigationService.getModuleName();
        if (!this.transform && this.id) { // fix for dialogs (dialogs don't send object, only id)
            this.transformService.view(this.id, { 'expand': 'conditions,tags,materials,locations,files,nomenclature,sectors,formats,quantities,qualities,packagings,categories' }).subscribe(function (res) {
                _this.transform = res;
                _this.transform.loaded = true;
                if (_this.parentModule === ModuleNames.SPECIAL_ADMIN
                    && _this.transform.status === TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.value) {
                    _this.showValidationButtons = true;
                }
                _this.fetchAndSetModificationMessage();
            });
        }
        else {
            this.transform.loaded = true;
            this.fetchAndSetModificationMessage();
        }
    };
    TransformDetailsComponent.prototype.fetchAndSetModificationMessage = function () {
        var _this = this;
        if (this.transform.status !== this.TRANSFORM_STATUSES.ASK_FOR_MODIFICATION.value) {
            return;
        }
        this.transformService.getModificationMessage(this.transform.id).subscribe(function (resp) {
            _this.modificationMessage = resp[0].message;
        });
    };
    TransformDetailsComponent.prototype.save = function () {
        var _this = this;
        this.transform.save().subscribe(function () {
            _this.action.emit();
        });
    };
    TransformDetailsComponent.prototype.remove = function () {
        var _this = this;
        this.transform.destroy().subscribe(function () {
            _this.action.emit();
        });
    };
    // if services area false then storing sites true
    // if services area true then storing sites false
    TransformDetailsComponent.prototype.checkIfServicesArea = function (transformationType) {
        var trans_type = TRANSFORM_TYPES.find(function (element) {
            return element.value === transformationType;
        });
        if (trans_type.includes.serviceArea) {
            return true;
        }
        else if (trans_type.includes.storingSites) {
            return false;
        }
        return false;
    };
    TransformDetailsComponent.prototype.submit = function () {
        var _this = this;
        this.form.markAsTouched();
        Object.values(this.form.controls).forEach(function (control) { return control.markAsTouched(); });
        this.validateChild$.emit();
        if (this.form.invalid) {
            if (this.form.controls['name'].invalid) {
                this.logNotificationService.error("Cannot submit without name", null, true);
            }
            else if (this.form.controls['englishName'].invalid) {
                this.logNotificationService.error("English name cannot be blank.", null, true);
            }
            return;
        }
        if (this.checkIfServicesArea(this.transform.transformation_type)) {
            if (this.transform.sectors.some(function (sector) { return sector.id == null; })) {
                this.logNotificationService.error(this._translate.instant('Please select your address from suggestions'), null, true);
                return;
            }
        }
        // not services area then it's storing sites
        if (!this.checkIfServicesArea(this.transform.transformation_type)) {
            if (this.transform.locations.some(function (location) { return !location.site.lat || !location.site.lng; })) {
                this.logNotificationService.error(this._translate.instant('Please select your address from suggestions'), null, true);
                return;
            }
        }
        this.transform.publish().subscribe(function () {
            _this.action.emit();
        });
    };
    TransformDetailsComponent.prototype.reject = function () {
        var _this = this;
        this.transform.reject().subscribe(function () {
            _this.action.emit();
        });
    };
    TransformDetailsComponent.prototype.validate = function () {
        var _this = this;
        this.transform.publish().subscribe(function () {
            _this.action.emit();
        });
    };
    TransformDetailsComponent.prototype.askForModification = function () {
        this._mdDialog.open(TransformationModificationDialogComponent, {
            data: {
                transform: this.transform
            }
        });
    };
    TransformDetailsComponent.prototype.isPublished = function () {
        return this.transform.status === TRANSFORM_STATUSES.PUBLISHED.value;
    };
    TransformDetailsComponent.prototype.getImageClass = function () {
        var className = 'transform-details__image';
        switch (this.parentModule) {
            case ModuleNames.TSMI:
                className += " " + className + "--tsmi";
                break;
            case ModuleNames.SUPERVISOR:
                className += " " + className + "--svisor";
                break;
            case ModuleNames.ADMIN:
                className += " " + className + "--admin";
                break;
            case ModuleNames.ESM:
                className += " " + className + "--esm";
                break;
            case ModuleNames.SPECIAL_ADMIN:
                className += " " + className + "--sa";
                break;
        }
        return className;
    };
    TransformDetailsComponent.prototype.removeCategory = function (category, index) {
        var _this = this;
        var foundCategory = this.transform.transformation_categories.find(function (cat) { return cat.category_id === category.id; });
        if (!!foundCategory) {
            this.transformationCategoryService.remove(foundCategory.id)
                .subscribe(function () { return _this.transform.categories.splice(index, 1); });
        }
    };
    TransformDetailsComponent.prototype.openCategoryDialog = function () {
        var _this = this;
        this._mdDialog.open(ViewCategoryDialogComponent, tslib_1.__assign({}, DIALOG_THEME.NOMENCLATURE_PRIMARY)).afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            }
            var category = new CategoryItem({
                parent_id: result.parent_id,
                name: result.name,
                id: result.id
            });
            _this.transformationCategoryService.create({
                transformation_id: _this.transform.id,
                category_id: result.id
            }).subscribe(function (newCat) {
                _this.transform.transformation_categories.push(newCat);
                _this.transform.categories.push(category);
            });
        });
    };
    TransformDetailsComponent.prototype.getCategoryName = function (categoryName) {
        var translation = this._translate.instant(categoryName);
        return (categoryName.length > 7)
            ? translation.substr(0, 7) + '…'
            : translation;
    };
    TransformDetailsComponent.prototype.isRejectButtonShown = function () {
        return this.parentModule !== this.moduleNames.SPECIAL_ADMIN;
    };
    TransformDetailsComponent.prototype.translateNameToEnglish = function () {
        var _this = this;
        if (this.transform.name && this.transform.name.length > 0) {
            var payload = { 'q': [this.transform.name], 'target': 'en' };
            this.translationService.translate(payload).subscribe(function (response) {
                if (response.length === 1) {
                    _this.transform.english_name = response[0].translatedText;
                }
            });
        }
    };
    TransformDetailsComponent.prototype.translationIsPossible = function () {
        return this.transform.name && this.transform.name.length > 0;
    };
    return TransformDetailsComponent;
}());
export { TransformDetailsComponent };
