import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import {DepositLocationImportFileService} from '../services/deposit-location-import-file.service';

export class DepositLocationImportFile extends UploadedDocument {
  protected provider = DepositLocationImportFileService;

  import_id: number;

  protected fields() {
    return super.fields().concat([
      'import_id',
    ]);
  }
}
