/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { MdDialog } from '@angular/material';
import { Need } from '../../../models/need.class';
import { NeedFile } from '../../../models/need-file.class';
import { DATE_PRECISION } from '../../../values/date-precision.const';
import { QUANTITY_PRECISION } from '../../../values/quantity-precision.const';
import { ClientLocation } from '../../../../client/model/location.class';
import { LocationService } from '../../../../client/service/location.service';
import { NeedService } from '../../../services/need.service';
import { NeedFileService } from '../../../services/need-file.service';
import { Client } from '../../../../../shared/detail-view/client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { ValidationService } from '../../../../../shared/services/validation-service';
import { Utils } from '../../../../../shared/utils/utils.class';
import { TranslatePipe } from "@ngx-translate/core";
import { NewSiteDialogComponent } from '../../need-directory/need-sites/PopupDialog/new-site-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'other-step',
  templateUrl: 'other-step.component.html',
  styleUrls: ['other-step.component.scss'],
})
export class OtherStepComponent implements OnInit, OnDestroy {

  @Input() controlStates: any;
  @Input() need: Need;
  clientId: number;
  deliveries: ClientLocation[];
  editForm: FormGroup;
  newDeliveryname: string;

  // filter={client_id:this.need.client_id};


  DATE_PRECISION_TYPES: any[] = DATE_PRECISION;
  QUANTITY_PRECISION_TYPES: any[] = QUANTITY_PRECISION;

  dropZone: boolean = false;
  public hasBaseDropZoneOver: boolean = false;
  uploader: FileUploader = new FileUploader({});
  needFileUploader: FileUploader = new FileUploader({});
  uploadedfiles: NeedFile[] = [];

  constructor(public fb: FormBuilder,
    private needFileService: NeedFileService,
    public locationService: LocationService,
    private needService: NeedService,
    public dialog: MdDialog,
    public notification: LogNotificationService,
    private translatePipe: TranslatePipe) {
  }

  //TODO seems to be enabling controls if their value is not equal to 'unknown' despite having controlState disabled
  isControlDisabled(field: string): boolean {
    let disablingValues = [this.translatePipe.transform('unknown')
      , this.translatePipe.transform('recurrent')];
    // console.log("QUANTITY PRECISION"+ this.need.quantity_precision_text);

    switch (field) {
      case 'quantity':
        if (this.controlStates.quantity && this.controlStates.quantity.disabled) {
          return true;
        }
        if (disablingValues.some(option => this.need.quantity_precision_text === option)) {
          return true;
        } else {
          return false;
        }
      case 'delivery_date':
        if (this.controlStates.delivery_date && this.controlStates.delivery_date.disabled) {
          return true;
        }
        if (disablingValues.some(option => this.need.date_precision_text === option)) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }


  ngOnInit() {
    //this.isControlDisabled('quantity')
    this.editForm = this.fb.group({
      quantity: [
        {
          value: '',
          disabled: this.isControlDisabled('quantity')
        },
        // Validators.required
        ValidationService.validateQuantity
      ],
    });

    this.clientId = this.need.client_id;

    this.need.onChangeClient
      .debounceTime(400)
      .distinctUntilChanged().subscribe(
        (client: Client) => this.clientId = client.id
      );

    this
      .locationService
      .get<ClientLocation>({ client_id: this.need.client.id })
      .subscribe((locations: ClientLocation[]) => this.onLocationsLoaded(locations));

    this.preloadUploadedItems();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  onLocationsLoaded(locations: ClientLocation[]) {
    // this.deliveries = locations;
    if (!!this.need.client_location) {
      this.need.setLocation(locations.find((item) => item.id === this.need.client_location.id));
    } else {
      this.need.setLocation(new ClientLocation());
    }
  }

  onNeedFileSelected($event: any) {
    //  console.log('onNeedFileSelected');

    this.needFileUploader.setOptions({
      url: this.needService.getFileUploadUrl(this.need.id),
      itemAlias: 'file'
    });
    this.needFileUploader.onSuccessItem = (item, response) => {
      //console.log('onSuccessItem');
      this.uploadedfiles.push(new NeedFile(response));
    };
    this.needFileUploader.uploadAll();
    this.needFileUploader.onErrorItem = Utils.onUploadError((event: string) => this.notification.error(event, null, true));
  }

  onSelectLocation(location: ClientLocation) {
    // console.log('selected');
    this.need.setLocation(location);
  }

  onQuantityPrecisionChange() {
    let quantityForm = this.editForm.get('quantity');
    this.isControlDisabled('quantity') === true ? quantityForm.disable() : quantityForm.enable();
  }

  onCreateLocation() {
    this.openCreateLocationDialog();
  }

  openCreateLocationDialog() {

    const dialogConfig = {
      width: '900px',
      data: {client : {id: this.need.client.id, name: this.need.client.name}}
    };
    const dialogRef = this.dialog.open(NewSiteDialogComponent, dialogConfig);

    dialogRef.componentInstance.siteCreated.subscribe((location) => {
      this.need.setLocation(location);
    });
  }

  preloadUploadedItems() {
    this.needFileService
      .get<File>({ need_id: this.need.id })
      .subscribe(
        result => {
          //  console.log('fetch uploaded files', result);
          for (let item of result) {
            this.uploadedfiles.push(new NeedFile(item));
          }
        }
      );
  }

  removeFile(i: number) {
    let item = this.uploadedfiles[i];
    item.destroy().subscribe(() => {
      this.uploadedfiles.splice(i, 1);
    });
  }

  onChangeName(name: string) {
    this.newDeliveryname = name;
    console.log('Name changed: ', name);
    if (name) {
      // console.log('reset forms');
      //  this.need.setClient(new Client({name: name}));
    }
  }

  ngOnDestroy() {

  }
}
