import { AfterViewInit, DoCheck, OnDestroy, ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { GoogleMapStyles } from '../config/google-maps-styles';
import { NavbarStateService } from '../services/navbar.service';
import { EMapType } from './map-type.enum';
import { AgmInfoWindow } from '@agm/core';
var MapViewComponent = /** @class */ (function () {
    function MapViewComponent(_mapsAPILoader, changeDetectorRef, filterState) {
        this._mapsAPILoader = _mapsAPILoader;
        this.changeDetectorRef = changeDetectorRef;
        this.filterState = filterState;
        this.MAP_TYPE = EMapType;
        this._markers = []; // Initialize markers array
        this.mapZoom = 8;
        this.initialZoomApplied = false;
        this.mapType = EMapType.ALL;
        this.maxZoom = 22;
        this.isMapTypeChangePolicySoft = false;
        this.disableDefaultOnMarkerChangeAction = false;
        this.markerClick = new EventEmitter();
        this.onMarkerClose = new EventEmitter();
        this.mapBounds = { north: 0, south: 0, east: 0, west: 0 };
        this.mapStyles = GoogleMapStyles;
        this.mapApiReady = false;
        this.isSpiderActive = false;
    }
    Object.defineProperty(MapViewComponent.prototype, "markers", {
        get: function () {
            return this._markers;
        },
        // Use setter to handle marker changes
        set: function (markers) {
            this._markers = markers;
            if (this.markers.length > 0 && !this.disableDefaultOnMarkerChangeAction) {
                this.updateMapCenter();
            }
        },
        enumerable: true,
        configurable: true
    });
    // Override method to fix library error
    MapViewComponent.prototype.overrideSpiderManagerDestroyMarkers = function () {
        // Check if spider and its manager are defined
        if (this.spider && this.spider._spiderManager && this.spider._spiderManager._markerManager) {
            // Implement deleteMarkers if not existing
            if (!this.spider._spiderManager._markerManager.deleteMarkers) {
                this.spider._spiderManager._markerManager.deleteMarkers = function (marker) { };
            }
        }
    };
    MapViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Subscribe to input event emitters
        if (this.onWindowClose) {
            this.onWindowClose.subscribe(function () { return _this.closeInfoWindow(); });
        }
        if (this.onUpdateMapCenter) {
            this.onUpdateMapCenter.subscribe(function () { return _this.updateMapCenter(); });
        }
        if (this.onZoom) {
            this.onZoom.subscribe(function (zoom) { return _this.unZoom(-zoom); });
        }
    };
    MapViewComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // Load maps API and set bounds after view initialization
        this._mapsAPILoader.load().then(function () {
            _this.mapApiReady = true;
            _this.mapBounds = _this.getMapBounds(_this.markers || []);
        });
    };
    // Close info window
    MapViewComponent.prototype.closeInfoWindow = function () {
        if (this.infoWindow) {
            this.infoWindow.close();
        }
    };
    // Handle map readiness
    MapViewComponent.prototype.onMapReady = function () {
        if (!this.initialZoomApplied) {
            this.mapZoom = 1;
            this.changeDetectorRef.detectChanges();
            this.initialZoomApplied = true;
        }
    };
    // Check for spider activity
    MapViewComponent.prototype.ngDoCheck = function () {
        this.isSpiderActive = this.spider ? true : false;
        if (this.isSpiderActive) {
            this.overrideSpiderManagerDestroyMarkers();
        }
    };
    // Unsubscribe from event emitters on destroy
    MapViewComponent.prototype.ngOnDestroy = function () {
        if (this.onWindowClose) {
            this.onWindowClose.unsubscribe();
        }
        if (this.onUpdateMapCenter) {
            this.onUpdateMapCenter.unsubscribe();
        }
        if (this.onZoom) {
            this.onZoom.unsubscribe();
        }
    };
    // Update map center based on markers
    MapViewComponent.prototype.updateMapCenter = function () {
        var _this = this;
        if (this.mapApiReady) {
            setTimeout(function () {
                _this.mapBounds = _this.getMapBounds(_this.markers);
                _this.changeDetectorRef.detectChanges();
            });
        }
    };
    // Calculate bounds based on markers
    MapViewComponent.prototype.getMapBounds = function (markers) {
        var bounds = new google.maps.LatLngBounds();
        markers.forEach(function (marker) {
            if (marker.lat && marker.lng) {
                // Use a new instance of LatLng to be compatible with google.maps.LatLngBounds
                bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
            }
        });
        // Check that the terminals contain a single point and extend them if necessary
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            bounds.extend(new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01));
        }
        // Convert bounds to LatLngBoundsLiteral before returning it
        return {
            north: bounds.getNorthEast().lat(),
            south: bounds.getSouthWest().lat(),
            east: bounds.getNorthEast().lng(),
            west: bounds.getSouthWest().lng()
        };
    };
    // Emit marker close event
    MapViewComponent.prototype.onInfoWindowClose = function (marker) {
        if (this.isMapTypeChangePolicySoft) {
            this.mapType = this.MAP_TYPE.ALL;
        }
        this.onMarkerClose.emit(marker);
    };
    // Adjust zoom level
    MapViewComponent.prototype.unZoom = function (zoom) {
        if (zoom === void 0) { zoom = 1; }
        this.mapZoom -= zoom;
    };
    // Emit marker click event
    MapViewComponent.prototype.onMarkerClickEvent = function (marker) {
        if (this.isMapTypeChangePolicySoft) {
            this.lockMapType();
        }
        this.markerClick.emit(marker);
    };
    // Lock map type based on zoom level
    MapViewComponent.prototype.lockMapType = function () {
        if (this.mapType === this.MAP_TYPE.ALL) {
            this.mapType = this.mapZoom > 12 ? this.MAP_TYPE.SPIDER : this.MAP_TYPE.CLUSTER;
        }
    };
    return MapViewComponent;
}());
export { MapViewComponent };
