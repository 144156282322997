<div class="esm-catalog">
  <header class="esm-catalog__header checkbox header--title-group">
    <h3 class="esm-catalog__inline-title">{{ 'Orders' | translate }}</h3>
    <div *ngFor="let status of filterStatuses">
      <md-checkbox class="esm-catalog__group--checkbox checkbox-group"
                   [name]="status.text"
                   [(ngModel)]="status.checked"
                   (change)="searchChange()">{{status.view | lowercase | capitalize | translate}}
      </md-checkbox>
    </div>
  </header>

  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input mdInput
                 autocomplete="off" 
                 placeholder="{{ 'Search' | translate }}"
                 [formControl]="search"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--people-grey"></i>
        <md-select class="select-group"
                   placeholder="{{ 'Ecosystem manager' | translate }}"
                   [(ngModel)]="ecosystemManager"
                   (change)="getRecords()"
        >
          <md-option>{{'Any' | translate}}</md-option>
          <md-option *ngFor="let manager of ecosystemManagerList" [value]="manager.id">{{manager.name}}</md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--gray-sort"></i>
        <md-select class="select-group"
                   placeholder="{{'Sort By' | translate}}"
                   [(ngModel)]="sortValue"
                   (change)="getRecords()"
        >
          <md-option *ngFor="let sortOption of sortOptions"
                     [value]="sortOption.field">
            {{ sortOption.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
    (onEndOfList)="onEndOfListTriggered()"
  ></custom-list>
</div>
