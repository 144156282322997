export const PASSPORT_COLOR_TYPES: {key: number, value: string}[] = [
  {key: 4, value: 'Yellow'},
  {key: 3, value: 'Green'},
  {key: 1, value: 'Blue'},
  {key: 5, value: 'Purple'},
  {key: 6, value: 'Pink'},
  {key: 2, value: 'Red'},
  {key: 7, value: 'Orange'},
  {key: 8, value: 'Brown'},
  {key: 9, value: 'Grey'},
  {key: 10, value: 'Black'},
  {key: 11, value: 'White'},
];
