import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DepositLocation} from '../../../../../entities/deposit/models/deposit-location.class';
import {MdDialogRef} from '@angular/material';

@Component({
  selector: 'request-site-deletion-confirm-dialog',
  templateUrl: './request-site-deletion-confirm-dialog.component.html',
  styleUrls: ['./request-site-deletion-confirm-dialog.component.scss']
})
export class RequestSiteDeletionConfirmDialogComponent implements OnInit {
  @Input() sitesForDeletion: DepositLocation[];
  @Input() numberOfDeposits: number;
  constructor(private translate: TranslateService, private dialog: MdDialogRef<any>) {}

  ngOnInit() {
    const data = this.dialog._containerInstance.dialogConfig.data;
    this.sitesForDeletion = data.sites || [];
    this.numberOfDeposits = data.depositsCount || 0;
  }

  getSiteList() {
    return this.sitesForDeletion.map(site => site.name).join(' - ');
  }

  get mainInfoText() {
    return this.translate.instant(
      `The deletion will be permanent and the N associated deposits will be deleted.`, {X: this.numberOfDeposits});
  }

}
