import * as tslib_1 from "tslib";
import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
var EcosystemStats = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemStats, _super);
    function EcosystemStats() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EcosystemStats.prototype.fields = function () {
        return [
            'ecosystems_created',
            'ecosystems_deleted',
            'ecosystems_done',
            'ecosystems_modified',
            'ecosystems_cancelled',
            'ecosystems_in_process',
            'offers_accepted',
            'offers_rejected',
            'revenue',
            'm',
            'y'
        ];
    };
    EcosystemStats.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        Utils.convertJsonStringToNumber(json, [
            'ecosystems_created',
            'ecosystems_deleted',
            'ecosystems_done',
            'ecosystems_modified',
            'ecosystems_cancelled',
            'ecosystems_in_process',
            'offers_accepted',
            'offers_rejected',
            'revenue',
            'm',
            'y'
        ]);
        return json;
    };
    return EcosystemStats;
}(BaseModel));
export { EcosystemStats };
