<section class="deposit-export-dialog">
    <dialog-close-button
      (click)="_dialog.close()"
    ></dialog-close-button>
    <h2 md-dialog-title class="deposit-export-dialog__title">
      <ng-container>
        {{ 'DEPOSIT_EXPORT_DIALOG_TITLE' | translate }}
      </ng-container>
    </h2>

    <h5 md-dialog-title class="deposit-export-dialog__label">
        <ng-container>
          {{ 'DEPOSIT_EXPORT_DIALOG_LABEL' | translate }}
        </ng-container>
      </h5>
  
    
  <div class="deposit-export-dialog__container">
    <img  class="deposit-export-dialog__button_pdf image-disabled"
        src="../assets/png/pdf.png"/>
    
    <a [href]="getExcelReport()" target="_blank" >
        <img   class="deposit-export-dialog__button_xls"
        src="../assets/png/xls.png"/>
    </a>
    
  </div>
   
  </section>