import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MdDialogRef} from '@angular/material';

@Component({
  selector: 'app-admin-warning-confirm',
  templateUrl: './admin-warning-confirm-dialog.component.html',
  styleUrls: ['./admin-warning-confirm-dialog.component.scss']
})
export class AdminWarningConfirmDialogComponent implements OnInit {
  @Input() text: string;
  @Output() confirm = new EventEmitter<boolean>();
  @Input() warningLabel = 'Warning';

  constructor(private dialog: MdDialogRef<any>) {
  }

  ngOnInit() {
    this.text = this.dialog._containerInstance.dialogConfig.data.text;
    this.warningLabel = this.dialog._containerInstance.dialogConfig.data.warningLabel;
    this.confirm.subscribe(confirm => this.dialog.close(confirm));
  }
}
