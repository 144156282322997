import { Component } from '@angular/core';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';
import { UserService } from '../../../../shared/services/user.service';

@Component({
  moduleId: module.id,
  templateUrl: 'superadmin-users.component.html',
  styleUrls: ['superadmin-users.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'material-reset',
  }
})
export class SuperadminUsersComponent {
  nomenclatureChangeRequests: number;
  companies: number;
  usersRegistered: number;

  constructor(private userService: UserService) {
    this.userService.getRoleCounters({role: ERoleIds.SUPERADMIN}).subscribe((res: any) => {
      console.log('RES IS ' + JSON.stringify(res));
      this.nomenclatureChangeRequests = Number(res.nomenclature_request);
      this.companies = Number(res.companies);
      this.usersRegistered = Number(res.users_registered);
    });
  }
}
