/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/map-view/map-view.component.ngfactory";
import * as i2 from "../../../../shared/map-view/map-view.component";
import * as i3 from "@agm/core/services/maps-api-loader/maps-api-loader";
import * as i4 from "../../../../shared/services/navbar.service";
import * as i5 from "@angular/common";
import * as i6 from "./deposit-map.component";
import * as i7 from "../../../../shared/services/deposit-map-marker.service.class";
var styles_DepositMapComponent = [];
var RenderType_DepositMapComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DepositMapComponent, data: {} });
export { RenderType_DepositMapComponent as RenderType_DepositMapComponent };
function View_DepositMapComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-view", [], null, [[null, "markerClick"], [null, "onMarkerClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("markerClick" === en)) {
        var pd_0 = (_co.handelMarkerClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("onMarkerClose" === en)) {
        var pd_1 = (_co.handleMarkerClose($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_MapViewComponent_0, i1.RenderType_MapViewComponent)), i0.ɵdid(1, 4702208, null, 0, i2.MapViewComponent, [i3.MapsAPILoader, i0.ChangeDetectorRef, i4.NavbarStateService], { onWindowClose: [0, "onWindowClose"], onZoom: [1, "onZoom"], onUpdateMapCenter: [2, "onUpdateMapCenter"], maxZoom: [3, "maxZoom"], isMapTypeChangePolicySoft: [4, "isMapTypeChangePolicySoft"], disableDefaultOnMarkerChangeAction: [5, "disableDefaultOnMarkerChangeAction"], markers: [6, "markers"] }, { markerClick: "markerClick", onMarkerClose: "onMarkerClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.closeWindowAction; var currVal_1 = _co.zoomAction; var currVal_2 = _co.updateMapBoundsAction; var currVal_3 = 15; var currVal_4 = true; var currVal_5 = true; var currVal_6 = _co.markers; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_DepositMapComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DepositMapComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mapIsReady; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DepositMapComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "deposit-map", [], null, null, null, View_DepositMapComponent_0, RenderType_DepositMapComponent)), i0.ɵdid(1, 245760, null, 0, i6.DepositMapComponent, [i7.DepositMapMarkersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DepositMapComponentNgFactory = i0.ɵccf("deposit-map", i6.DepositMapComponent, View_DepositMapComponent_Host_0, { updateMap: "updateMap", beforeMapUpdate: "beforeMapUpdate", markerInfoWindowCloseUnZoom: "markerInfoWindowCloseUnZoom" }, { onChangeDepositLocationIds: "onChangeDepositLocationIds" }, []);
export { DepositMapComponentNgFactory as DepositMapComponentNgFactory };
