<header>
  <h2 class="dialog-title">
    {{'Recipient' | translate}}
    <div class="close-container" (click)="closeDialog(null)">
      <div class="leftright"></div>
      <div class="rightleft"></div>
    </div>
  </h2>
</header>
<div>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div>
      <md-input-container>
        <input mdInput formControlName="name" placeholder="{{'Company name'|translate}}*">
      </md-input-container>
      <div class="passport-edit__main-photo">
        <label class="passport-edit__main-photo--label">{{ 'Company Logo' | translate }}</label>
        <logo-uploader class="passport-edit__main-photo--uploader" 
          #logoUploader
          [preview]="client.logo"
          [dontUpload]="true"
          (onLoad)="onLogoUpload($event)"
          (onRemove)="removeClientLogo()"
          (onClick)="onClickLogoUpload()"
        >
        </logo-uploader>
      </div>
    </div>
    <div>
      <span>{{'Contacts' | translate}}</span>
    </div>
    <div class="table-container">
      <div class="table-header">
        <span style="width: 32px;"></span>
        <div>{{'Name' | translate}}*</div>
        <div>{{'First name' | translate}}*</div>
        <div>{{'Function' | translate}}</div>
        <div>{{'Phone' | translate}}</div>
        <div>{{'Mail' | translate}}</div>
        <div></div>
      </div>
      <div class="table-row" *ngFor="let contact of contactsArray.controls; let i = index" [formGroup]="contact">
        <div class="icon-container" (click)="removeContact(i)">
          <svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.5H16" stroke="#B4299C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <md-input-container>
          <input mdInput placeholder="{{'Name' | translate}}*" formControlName="name">
        </md-input-container>
        <md-input-container>
          <input mdInput placeholder="{{'First name' | translate}}*" formControlName="firstName">
        </md-input-container>
        <md-input-container>
          <input mdInput placeholder="{{'Function' | translate}}" formControlName="function">
        </md-input-container>
        <md-input-container>
          <input mdInput placeholder="{{'Phone' | translate}}" formControlName="phone">
        </md-input-container>
        <md-input-container>
          <input mdInput placeholder="{{'Mail' | translate}}" formControlName="email">
        </md-input-container>
        <md-radio-button class="deposit-types-details__radio-group--buttons" [checked]="i === 0">
          {{ 'Default contact' | translate}}
        </md-radio-button>
      </div>
    </div>
    <div>
      <div (click)="addContact()" class="addContact">
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.3374 8.33643V10.2676H0.281738V8.33643H17.3374ZM9.7666 0.389648V18.5049H7.71582V0.389648H9.7666Z" fill="#B4299C"/>
        </svg>
        <span>{{'Add a contact' | translate}}</span>
      </div>
    </div>
    <button md-raised-button type="submit" [disabled]="formGroup.invalid || isUploading">{{'Save' | translate}}</button>
  </form>
</div>