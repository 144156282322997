<div class="material-search-container">
    <md-input-container class="passportlist__search-materials" [floatPlaceholder]="materials.length ? 'always' : ''"
        (click)="openNomenclatureDialog()">
        <md-chip-list>
            <md-basic-chip *ngFor="let material of materials" (focus)="false">
                {{material.name}}
                <button (click)="removeMaterial(material, $event)" class="delete"></button>
            </md-basic-chip>
        </md-chip-list>
        <input test-id="nomenclature-input" mdInput autocomplete="off" placeholder="{{'Materials' | translate}}" readonly>
    </md-input-container>
</div>