import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DashboardComponentBase } from '../../../app/components/base/dashboard/dashboard-component-base.directive';
import { PassportCollectionComponent } from '../../../app/components/passport/passport-Collection/passport-collection.component';
import { MdDialog } from '@angular/material';
import { Passport } from '../../../entities/passport/models/passport.class';
import { SpecialAdminService } from '../../../entities/passport/services/specialAdmin.service';
import { PASSPORT_STATUS_CONST_SPECIAL_ADMIN } from '../../../entities/passport/values/passport-status.const';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
var SpecialAdminPassportDashboardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SpecialAdminPassportDashboardComponent, _super);
    function SpecialAdminPassportDashboardComponent(specialAdminService, dialog, localStorageService) {
        var _this = _super.call(this) || this;
        _this.specialAdminService = specialAdminService;
        _this.dialog = dialog;
        _this.localStorageService = localStorageService;
        _this.currentTab = 'default';
        _this.actionButtonsConfig = {
            duplicate: false,
            copyId: true,
            delete: false,
            version: true
        };
        return _this;
    }
    SpecialAdminPassportDashboardComponent.prototype.ngOnInit = function () {
        this.initializeFilterStates();
        this.loadDataForTab();
    };
    SpecialAdminPassportDashboardComponent.prototype.initializeFilterStates = function () {
        this.pagination = {
            current_page: 1,
            page_count: null,
            total_count: null,
            pagination_per_page: null,
        };
        var stored = this.localStorageService.getStoredObject('specialAdminFilters');
        if (stored) {
            this.filterStates = stored.states;
            this.currentTab = stored.currentTab;
        }
        else {
            // Default state setup
            this.filterStates['default'] = this.getDefaultFilterState();
        }
    };
    SpecialAdminPassportDashboardComponent.prototype.loadDataForTab = function (resetPage) {
        var _this = this;
        if (resetPage === void 0) { resetPage = true; }
        if (resetPage) {
            this.pagination.current_page = 1;
            this.passportCollection.clearSelection();
        }
        this.isLoading = true;
        this.passports = []; // Clear current data for safety
        if (this.passportsRequestSubscription) {
            this.passportsRequestSubscription.unsubscribe();
        }
        var currentFilters = this.filterStates[this.currentTab] || this.getDefaultFilterState();
        var requestPayload = this.convertFilterStateToObj(currentFilters);
        // Store the filters in local storage including the current tab
        this.localStorageService.storeObject('specialAdminFilters', { states: this.filterStates, currentTab: this.currentTab });
        this.passportsRequestSubscription = this.specialAdminService.getPassports(this.pagination.current_page, 10, requestPayload).subscribe({
            next: function (response) {
                _this.handleResponse(response);
                _this.isLoading = false;
            },
            error: function () {
                _this.isLoading = false;
            },
        });
    };
    SpecialAdminPassportDashboardComponent.prototype.handleResponse = function (response) {
        var body = JSON.parse(response._body);
        var headers = response.headers;
        this.pagination = {
            current_page: headers.get('X-Pagination-Current-Page'),
            page_count: headers.get('X-Pagination-Page-Count'),
            total_count: headers.get('X-Pagination-Total-Count'),
            pagination_per_page: headers.get('X-Pagination-Per-Page'),
        };
        this.passports = body.map(function (item) { return new Passport(item); });
    };
    SpecialAdminPassportDashboardComponent.prototype.getDefaultFilterState = function () {
        return {
            sortBy: 'performance',
            search: '',
            families: [],
            materials: [],
            categories: [],
            searchId: [],
            selectedOptions: [],
            databases: [],
            dataProviders: [],
            dataRepresentativity: [],
            epdType: [],
            seeOnlyWaitingForRating: false,
            rating: {
                passportWithoutRating: false,
                selectRatingFilter: false,
                C2cCertified: false,
                composition: { color: false, black: false },
                cycle: { color: false, black: false },
                energy: { color: false, black: false },
                water: { color: false, black: false },
                social: { color: false, black: false },
            },
            status: [3, 4, 2],
            company_creators: [],
        };
    };
    SpecialAdminPassportDashboardComponent.prototype.resetFiltersToDefault = function () {
        this.filterStates[this.currentTab] = this.getDefaultFilterState();
        this.loadDataForTab();
    };
    SpecialAdminPassportDashboardComponent.prototype.getStatusFilter = function () {
        return Object.values(PASSPORT_STATUS_CONST_SPECIAL_ADMIN);
    };
    return SpecialAdminPassportDashboardComponent;
}(DashboardComponentBase));
export { SpecialAdminPassportDashboardComponent };
