import { DepositCondition } from '../models/deposit.condition.class';
export const DEPOSIT_CONDITIONS: DepositCondition[] = [
  {
    value: 1,
    view: 'irrelevant',
  },
  {
    value: 2,
    view: 'as new',
  },
  {
    value: 3,
    view: 'slightly used',
  },
  {
    value: 4,
    view: 'used',
  },
  {
    value: 5,
    view: 'deteriorated',
  },
];

export const DEPOSIT_CONDITION_MAP = {
  1: 'irrelevant',
  2: 'as new',
  3: 'slightly used',
  4: 'used',
  5: 'deteriorated',
}
