import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";

@Component({
    selector: 'passport-synchronization-information',
    templateUrl: './passport-synchronization-information.component.html',
    styleUrls: ['./passport-synchronization-information.component.scss']
})

export class PassportSynchronizationInformationComponent {
    @Input() synchronized: boolean = false;
    @Input() synchronizable: boolean = false;
    @Input() isLastVersion: boolean = false;
    @Input() isEditable: boolean = false;
    @Input() logoPath: string = null;
    @Output() synchronize = new EventEmitter<void>();
    @Output() desynchronize = new EventEmitter<void>();
    @ViewChild('synchronizationToggle') synchronizationToggle;
    
    onToggleChanged(state) {
        if (state.checked) {
            this.synchronize.emit();
        } else {
            this.desynchronize.emit();
        }
    }

    /**
     * Return whether the toggle button is disabled or not
     * @returns {boolean}
     */
    isDisabled() {
        return !this.isLastVersion || !this.synchronized && !this.synchronizable;
    }

    /**
     * Get the correct tooltip message to display
     * @returns {string|null}
     */
    getTooltipMessage() {
        if (this.synchronized) {
            return null;
        }

        if (!this.isLastVersion) {
            return 'PASSPORT_SYNCHRONIZATION_IMPOSSIBLE_OLD_VERSION_TOOLTIP';
        }

        if (this.synchronizable) {
            return 'PASSPORT_SYNCHRONIZATION_TOOLTIP';
        } else {
            return 'PASSPORT_SYNCHRONIZATION_IMPOSSIBLE_MISSING_FIELDS_TOOLTIP';
        }
    }

    /**
     * Return whether the synchronization section is shown or not
     * @returns {boolean}
     */
    isSynchronizationInformationShown() {
        return this.synchronized || this.isEditable;
    }

    /**
     * Force the state of the toggle to true or false
     * @param {boolean} state - The state the toggle should be in
     */
    forceToggleState(state) {
        this.synchronizationToggle.checked = state;
    }
}