/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { CrudService } from '../../services/crud.service';
import { ReadOnlyService } from '../../read-only/read-only.service';
import { LogNotificationService } from '../../services/log-notification.service';
import { Utils } from '../../utils/utils.class';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
var DocumentsUploaderComponent = /** @class */ (function () {
    function DocumentsUploaderComponent(readOnlyService, _translate, notification, sanitizer) {
        this.readOnlyService = readOnlyService;
        this._translate = _translate;
        this.notification = notification;
        this.sanitizer = sanitizer;
        this.files = [];
        this.uploader = new FileUploader({});
        this.isRemoveFromFilesDisabled = false;
        this.isRemoveFromQueueDisabled = false;
        this.isDownLoadDisabled = false;
        this.hideUploadButton = false;
        this.isMultiple = true;
        this.onItemUploaded = new EventEmitter();
        this.onAllItemsUploaded = new EventEmitter();
        this.onAfterAddingFile = new EventEmitter();
        this.onAfterRemove = new EventEmitter();
        this.onAfterQueueRemove = new EventEmitter();
        this.onClick = new EventEmitter();
        this.isUploading = new EventEmitter();
        this.autoupload = true;
        this.maxAllowedItems = 999;
        this.enableQueueFileEdit = false;
    }
    Object.defineProperty(DocumentsUploaderComponent.prototype, "customUploader", {
        set: function (uploader) {
            if (uploader) {
                this.uploader = uploader;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentsUploaderComponent.prototype, "isDisabled", {
        set: function (state) {
            this.isRemoveDisabled = state;
            this.isDownLoadDisabled = state;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentsUploaderComponent.prototype, "isRemoveDisabled", {
        set: function (state) {
            this.isRemoveFromFilesDisabled = state;
            this.isRemoveFromQueueDisabled = state;
        },
        enumerable: true,
        configurable: true
    });
    DocumentsUploaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.uploadUrl || !this.autoupload) {
            this.initUploader();
        }
        if (this.uploadUrlSet) {
            this.subscription = this.uploadUrlSet.subscribe(function (url) {
                _this.uploadUrl = url;
                _this.initUploader();
            });
        }
        if (!this.autoupload) {
            this.onUploadAllSubscription = this.onUploadAll.subscribe(function (url) {
                _this.uploader.uploadAll();
            });
        }
    };
    DocumentsUploaderComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
        if (this.onUploadAllSubscription) {
            this.onUploadAllSubscription.unsubscribe();
            this.onUploadAllSubscription = null;
        }
    };
    DocumentsUploaderComponent.prototype.initUploader = function () {
        var _this = this;
        this.uploader.setOptions({
            url: this.uploadUrl,
            itemAlias: 'file',
            autoUpload: this.autoupload,
        });
        if (!!this.type) {
            this.uploader.onBuildItemForm = function (fileItem, form) {
                form.append('type', _this.type);
            };
        }
        this.uploader.onSuccessItem = (function (item, file) {
            _this.files.push(_this.service.buildModel(file));
        });
        this.uploader.onBeforeUploadItem = function (item) {
            _this.isUploading.emit(true);
        };
        this.uploader.onCompleteAll = function () {
            _this.onAllItemsUploaded.emit(_this.files);
            _this.uploader.clearQueue();
            _this.isUploading.emit(false);
        };
        this.uploader.onCompleteItem = (function (_, file) {
            _this.onItemUploaded.emit(_this.service.buildModel(file));
        });
        this.uploader.onAfterAddingFile = function (fileItem) {
            if (_this.acceptedFormats && !_this.acceptedFormats.includes(fileItem._file.name.split('.').reverse()[0])) {
                _this.notification.error(_this._translate.instant("Accepted formats : " + _this.acceptedFormats.map(function (ext) { return "." + ext; }).join(', ')), null, true);
                _this.uploader.queue.pop();
                return;
            }
            if (_this.maxFileSize && fileItem._file.size > _this.maxFileSize) {
                _this.notification.error(_this._translate.instant('File size exceeds the allowed limit'), null, true);
                _this.uploader.queue.pop();
                return;
            }
            fileItem.file['previewPath'] = _this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
            _this.onAfterAddingFile.emit(_this.uploader.queue);
        };
        this.uploader.onErrorItem = Utils.onUploadError(function (event) { return _this.notification.error(event, null, true); });
    };
    DocumentsUploaderComponent.prototype.remove = function (index) {
        var _this = this;
        var file = this.files[index];
        if (file && this.onBeforeRemove(file)) {
            file.destroy().subscribe(function () {
                _this.files.splice(index, 1);
                _this.onAfterRemove.emit(file);
            });
        }
    };
    DocumentsUploaderComponent.prototype.onBeforeRemove = function (file) {
        var isCancelled = false;
        if (this.beforeRemoveAction) {
            isCancelled = this.beforeRemoveAction(file);
            isCancelled = isCancelled === undefined ? false : !isCancelled;
        }
        return !isCancelled;
    };
    DocumentsUploaderComponent.prototype.removeFromQueue = function (index) {
        this.uploader.queue.splice(index, 1);
        this.onAfterQueueRemove.emit(this.uploader.queue);
    };
    DocumentsUploaderComponent.prototype.onUploadButtonClick = function (event) {
        this.onClick.emit(event);
        if (this.notShowClickErrors) {
            if (!this.uploadUrl && this.autoupload) {
                if (this.uploadUrlMissingErrorText) {
                    this.notification.error(this.uploadUrlMissingErrorText);
                }
                event.preventDefault();
                event.stopPropagation();
                return;
            }
        }
        if (!!this.uploaderElem) {
            this.uploaderElem.nativeElement.click();
        }
    };
    DocumentsUploaderComponent.prototype.getExtensionName = function (fileName) {
        var fileNameParts = fileName.split('.');
        return fileNameParts.length > 1 ? fileNameParts.reverse()[0] : '';
    };
    DocumentsUploaderComponent.prototype.isImage = function (filename) {
        return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(this.getExtensionName(filename));
    };
    Object.defineProperty(DocumentsUploaderComponent.prototype, "queueFiles", {
        get: function () {
            return this.uploader.queue.length;
        },
        enumerable: true,
        configurable: true
    });
    DocumentsUploaderComponent.prototype.isDownloadEnable = function () {
        return !this.hideUploadButton
            && !(this.readOnlyService && this.readOnlyService.readOnly)
            && this.uploader.queue.length + this.files.length < this.maxAllowedItems;
    };
    DocumentsUploaderComponent.prototype.queueNameChange = function (newName, file) {
        if (this.enableQueueFileEdit) {
            file.file.name = newName + '.' + this.getExtensionName(file.file.name);
        }
    };
    DocumentsUploaderComponent.prototype.queueNameTransform = function (name) {
        var extLen = this.getExtensionName(name).length;
        var baseNameEndIndex = !!extLen ? -(extLen + 1) : undefined;
        return name.slice(0, baseNameEndIndex);
    };
    return DocumentsUploaderComponent;
}());
export { DocumentsUploaderComponent };
