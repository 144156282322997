/**
 * Created by aleksandr on 30.03.17.
 */
import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { SALES_NAVIGATION } from './sales.navigation';
import { ERoleIds } from '../../shared/values/role-ids.enum';

export const SALES_CONTEXT: IRoleContextConfig = {
  navigation: SALES_NAVIGATION,
  moduleName: 'sales',
  themeClass: 'b-module-needs',
  roleId: ERoleIds.SALES_MANAGER,
  roleText: 'Need manager',
  homeUrl: '/sales',
  apiModuleName: 'sale',
  rootPath: 'sales'
};

