/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./need-directory.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./need-recipients/need-recipients.component.ngfactory";
import * as i4 from "./need-recipients/need-recipients.component";
import * as i5 from "../../../client/service/client.service";
import * as i6 from "@angular/material";
import * as i7 from "./need-sites/need-sites.component.ngfactory";
import * as i8 from "./need-sites/need-sites.component";
import * as i9 from "../../../client/service/location.service";
import * as i10 from "@angular/common";
import * as i11 from "../../../../shared/read-only/read-only.service";
import * as i12 from "./need-directory.component";
import * as i13 from "../../services/need.service";
import * as i14 from "../../../../shared/services/localStorage.service";
var styles_NeedDirectoryComponent = [i0.styles];
var RenderType_NeedDirectoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NeedDirectoryComponent, data: {} });
export { RenderType_NeedDirectoryComponent as RenderType_NeedDirectoryComponent };
function View_NeedDirectoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "need-information"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "information-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "close-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeInformation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "leftright"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "rightleft"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("NEED_DIRECTORY_INFORMATION_TEXT")); _ck(_v, 2, 0, currVal_0); }); }
function View_NeedDirectoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "need-catalog__content-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "need-recipients", [], null, null, null, i3.View_NeedRecipientsComponent_0, i3.RenderType_NeedRecipientsComponent)), i1.ɵdid(2, 114688, null, 0, i4.NeedRecipientsComponent, [i5.ClientService, i6.MdDialog, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_NeedDirectoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "need-catalog__content-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "need-sites", [], null, null, null, i7.View_NeedSitesComponent_0, i7.RenderType_NeedSitesComponent)), i1.ɵdid(2, 114688, null, 0, i8.NeedSitesComponent, [i9.LocationService, i6.MdDialog, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_NeedDirectoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "article", [["class", "need-catalog"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NeedDirectoryComponent_1)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 13, "header", [["class", "need-catalog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 12, "ul", [["class", "need-catalog__caption-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "li", [["class", "need-catalog__caption-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTab(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i10.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "active": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "need-catalog__caption-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 5, "li", [["class", "need-catalog__caption-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTab(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 278528, null, 0, i10.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(13, { "active": 0 }), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [["class", "need-catalog__caption-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 5, "section", [["class", "need-catalog__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "ul", [["class", "need-catalog__content-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NeedDirectoryComponent_2)), i1.ɵdid(20, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NeedDirectoryComponent_3)), i1.ɵdid(22, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayInformation; _ck(_v, 2, 0, currVal_0); var currVal_1 = "need-catalog__caption-item"; var currVal_2 = _ck(_v, 7, 0, (_co.activeIndex === 0)); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = "need-catalog__caption-item"; var currVal_5 = _ck(_v, 13, 0, (_co.activeIndex === 1)); _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_7 = (_co.activeIndex === 0); _ck(_v, 20, 0, currVal_7); var currVal_8 = (_co.activeIndex === 1); _ck(_v, 22, 0, currVal_8); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("Recipients")); _ck(_v, 9, 0, currVal_3); var currVal_6 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("Sites")); _ck(_v, 15, 0, currVal_6); }); }
export function View_NeedDirectoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "need-directory", [], null, null, null, View_NeedDirectoryComponent_0, RenderType_NeedDirectoryComponent)), i1.ɵprd(4608, null, i11.ReadOnlyService, i11.ReadOnlyService, []), i1.ɵdid(2, 114688, null, 0, i12.NeedDirectoryComponent, [i13.NeedService, i1.NgZone, i14.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var NeedDirectoryComponentNgFactory = i1.ɵccf("need-directory", i12.NeedDirectoryComponent, View_NeedDirectoryComponent_Host_0, {}, {}, []);
export { NeedDirectoryComponentNgFactory as NeedDirectoryComponentNgFactory };
