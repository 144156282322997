import * as tslib_1 from "tslib";
import { ECOSYSTEM_CALC_CO2_ERRORS, ECOSYSTEM_CALC_CO2_WARNINGS, ERROR_TYPE_DISTANCE_CANNOT_BE_EVALUATED } from '../../values/ecosystem-calc-error.const';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { AbstractReportStrategyWithDetails } from './abstract-report-strategy.service';
import { TRANSFORM_TYPE } from '../../../transformation-manager/values/transform-types.const';
var CO2EmissionsStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(CO2EmissionsStrategy, _super);
    function CO2EmissionsStrategy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileType = ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_CO2;
        _this.unit = 'kg eq. CO₂';
        return _this;
    }
    CO2EmissionsStrategy.prototype.getSteps = function (allSteps) {
        return {
            virginProducts: allSteps.virginProducts,
            deposits: allSteps.deposits,
            transforms: this.emissionTransformSteps(allSteps.transforms),
            transports: allSteps.transports,
            needs: allSteps.needs
        };
    };
    CO2EmissionsStrategy.prototype.emissionTransformSteps = function (transforms) {
        return transforms.filter(function (step) { return [TRANSFORM_TYPE.SORTING,
            TRANSFORM_TYPE.UPCYCLING,
            TRANSFORM_TYPE.METHANIZATION,
            TRANSFORM_TYPE.COMPOSTING].includes(step.transformation_type); });
    };
    CO2EmissionsStrategy.prototype.formatValue = function (step) {
        return step.co2_emission.toFixed(2).toString();
    };
    CO2EmissionsStrategy.prototype.isDataMissing = function (errors, step) {
        if (!errors || !Array.isArray(errors)) {
            return true;
        }
        var found = null;
        var _loop_1 = function (i) {
            found = ECOSYSTEM_CALC_CO2_ERRORS.find(function (element) { return element.id === errors[i]; });
            if (found && this_1.ifDistanceErrorHaveConfirmedDistance(found, step)) {
                found = null;
            }
            if (found) {
                return "break";
            }
        };
        var this_1 = this;
        for (var i = 0; i < errors.length; i++) {
            var state_1 = _loop_1(i);
            if (state_1 === "break")
                break;
        }
        return !!found;
    };
    CO2EmissionsStrategy.prototype.hasWarnings = function (warnings) {
        if (!warnings || !Array.isArray(warnings)) {
            return false;
        }
        return warnings.some(function (warning) { return !!ECOSYSTEM_CALC_CO2_WARNINGS.find(function (warningData) { return warningData.id === warning; }); });
    };
    CO2EmissionsStrategy.prototype.getWarnings = function (warnings) {
        if (!warnings || !Array.isArray(warnings)) {
            return [];
        }
        return warnings.map(function (warning) { return ECOSYSTEM_CALC_CO2_WARNINGS.find(function (warningData) { return warningData.id === warning; }).title; });
    };
    CO2EmissionsStrategy.prototype.ifDistanceErrorHaveConfirmedDistance = function (error, step) {
        return error.id === ERROR_TYPE_DISTANCE_CANNOT_BE_EVALUATED && !!step.confirmed_distance;
    };
    CO2EmissionsStrategy.prototype.otherAdd = function (ecosystem, value) {
        this.setOtherTotal(value, function (val) { return ecosystem.add_other_CO2 = val; });
        return ecosystem.add_other_CO2;
    };
    CO2EmissionsStrategy.prototype.otherSubtract = function (ecosystem, value) {
        this.setOtherTotal(value, function (val) { return ecosystem.subtract_other_CO2 = val; });
        return ecosystem.subtract_other_CO2;
    };
    CO2EmissionsStrategy.prototype.otherDescription = function (ecosystem, value) {
        this.setOtherDescription(value, function (val) { return ecosystem.description_other_CO2 = val; });
        return ecosystem.description_other_CO2;
    };
    CO2EmissionsStrategy.prototype.getTotalErrors = function (ecosystem) {
        return ecosystem.emissions_total.errors;
    };
    return CO2EmissionsStrategy;
}(AbstractReportStrategyWithDetails));
export { CO2EmissionsStrategy };
