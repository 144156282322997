import {
    Component, EventEmitter, OnInit, Output, ViewChild, ElementRef,
    HostListener, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
    selector: 'app-selectable-button-list-filter',
    templateUrl: './selectable-button-list-filter.component.html',
    styleUrls: ['./selectable-button-list-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectableButtonListFilterComponent implements OnInit, OnChanges {
    @ViewChild('filterWrapper') filterWrapper: ElementRef;
    @ViewChild('searchInput') searchInput: ElementRef;

    @Input() selectedItem: string = '';
    @Input() items: string[] = [];
    @Input() placeholder: string = '';
    @Input() enableSearch: boolean = true;
    @Input() disabled = false;

    @Output() selectionChange = new EventEmitter<string>();

    filteredOptions = [];
    searchTerm: string = '';
    isOpen = false;
    filteredOptionsCount: number = 0;

    constructor(private translateService: TranslateService, private _navigation: NavigationService) { }

    ngOnInit() {
        this.updateFilteredOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedItem && this.searchTerm === '') {
            this.updateFilteredOptions();
        }
    }

    updateFilteredOptions() {
        if (!this.items) {
            return;
        }
        this.filteredOptions = this.items
            .map(item => ({
                name: item,
                translatedName: this.translateService.instant(item),
                selected: item === this.selectedItem,
                sortOrder: this.getSortOrder(item)
            }))
            .sort((a, b) => {
                if (a.sortOrder !== b.sortOrder) {
                    return a.sortOrder - b.sortOrder;
                }
                return a.translatedName.localeCompare(b.translatedName);
            });
        this.filteredOptionsCount = this.filteredOptions.length;
    }

    getSortOrder(item: string): number {
        switch (item) {
            case 'Other':
                return 2;
            case 'unknownSource':
            case 'unknownOperator':
            case 'Unknown_epd_type':
                return 3;
            default:
                return 1;
        }
    }   

    onOptionSelected(option) {
        if(this.disabled) return;
        this.selectedItem = option.name === this.selectedItem ? '' : option.name;
        this.selectionChange.emit(this.selectedItem);
        this.updateFilteredOptions();
    }

    removeTag(event: Event) {
        if(this.disabled) return;
        event.stopPropagation();
        this.selectedItem = '';
        this.selectionChange.emit(this.selectedItem);
        this.updateFilteredOptions();
    }

    toggleView() {
        if(this.disabled) return;
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            setTimeout(() => {
                if (this.searchInput && this.searchInput.nativeElement) {
                    this.searchInput.nativeElement.focus();
                }
            }, 0);
        }
    }

    filterOptions() {
        if (!this.searchTerm.trim()) {
            this.updateFilteredOptions();
            return;
        }

        this.filteredOptions = this.items
            .filter(item => item.toLowerCase().includes(this.searchTerm.toLowerCase()))
            .map(item => ({
                name: item,
                translatedName: this.translateService.instant(item),
                selected: item === this.selectedItem,
                sortOrder: this.getSortOrder(item)
            }));

        this.filteredOptionsCount = this.filteredOptions.length;
    }

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        const targetElement = event.target as HTMLElement;
        if (this.isOpen && !this.filterWrapper.nativeElement.contains(targetElement)) {
            this.isOpen = false;
        }
    }

    /**
     * Determines the CSS class for coloring pagination based on the user's role.
     * 
     * @returns The CSS class corresponding to the user's role.
     */
    getClassColor(): string {
        if(!this._navigation  || !this._navigation.getContext()) return 'role-guest';
        let role = this._navigation.getContext().roleText;
        switch (role) {
        case 'Agent':
            return 'role-agent';
        case 'Manufacturer':
            return 'role-manufacturer';
        case 'Specialadmin':
            return 'role-specialadmin';
        default:
            return '';
        }
    }
}
