import { EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AffiliateBranchService } from '../../services/affiliateBranch.service';
import { AffiliateBranch } from '../../models/affiliateBranch.class';
import { Passport } from '../../models/passport.class';
import { MdDialog } from '@angular/material';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { PassportCustomStoreService } from '../../services/passport-custom-store.service';
import { forkJoinSafe } from '../../../../shared/helpers/fork-join-safe.helper';
import { NextUsage } from '../../models/next-usage.class';
import { PASSPORT_REQUEST } from '../../values/passport-request.const';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import { NextUsageService } from '../../services/next-usage.service';
var PassportAffiliateBranchComponent = /** @class */ (function () {
    function PassportAffiliateBranchComponent(_fb, dialog, passportChildComponentsEventListenerService, readOnlyService, affiliateBranchService, usageService) {
        this._fb = _fb;
        this.dialog = dialog;
        this.passportChildComponentsEventListenerService = passportChildComponentsEventListenerService;
        this.readOnlyService = readOnlyService;
        this.affiliateBranchService = affiliateBranchService;
        this.usageService = usageService;
        this.isDisabled = false;
        this.onSave = new EventEmitter();
        this.disableStatus = false;
        this.subscriptions = [];
        this.VIEW_MODE = VIEW_MODE;
    }
    PassportAffiliateBranchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.disableStatus = this.isDisabled;
        this.affiliateBranchListener.subscribe(function (_a) {
            var data = _a.data, key = _a.key;
            switch (key) {
                case _this.usageService.AFFILIATE_BRANCH_EVENT.SAVE:
                    _this.nextUsage = data;
                    _this.save().subscribe(function (e) {
                        console.log(e);
                    });
                    break;
            }
        });
        if (this.passport) {
            this.changePassport(this.passport).updateAffiliateBranch();
        }
        if (this.listener) {
            this.listener.subscribe(function (event) {
                if (event.key === 'changePassport') {
                    _this.changePassport(event.data).updateAffiliateBranch();
                }
                if (event.key === 'disableStatus') {
                    _this.disableStatus = event.data.isDisabled;
                    _this.updateDisableStatus();
                }
                if (event.key === 'saveNextUsage') {
                    _this.nextUsage = event.data;
                    _this.onSave.emit({ type: PASSPORT_REQUEST.AFFILIATE_BRANCH, request: _this.save() });
                }
            });
        }
    };
    PassportAffiliateBranchComponent.prototype.save = function () {
        var _this = this;
        if (this.nextUsage && this.nextUsage.id) {
            var requests = this.listDescription.controls.map(function (control) { return _this.getAffiliateBranch(control); });
            return forkJoinSafe(requests);
        }
    };
    PassportAffiliateBranchComponent.prototype.getAffiliateBranch = function (affiliateBranchCtrl) {
        var affiliateBranch = this.getAffiliateBranchFromForm(affiliateBranchCtrl);
        if (!!affiliateBranch) {
            return affiliateBranch.save().map(function (v) {
                if (affiliateBranchCtrl.get('id') && !!v.id) {
                    affiliateBranchCtrl.get('id').setValue(v.id);
                }
                return v;
            });
        }
    };
    PassportAffiliateBranchComponent.prototype.getAffiliateBranchFromForm = function (form) {
        if (!form) {
            return null;
        }
        var id = form.get('id') ? form.get('id').value : null;
        var description = form.get('description') ? form.get('description').value : null;
        var stakeholder = form.get('stakeholder') ? form.get('stakeholder').value : null;
        var affiliateBranch = new AffiliateBranch({
            id: id,
            description: description,
            stakeholder: stakeholder,
            next_usage_id: this.nextUsage.id ? this.nextUsage.id : null
        });
        return affiliateBranch;
    };
    PassportAffiliateBranchComponent.prototype.updateDisableStatus = function () {
        if (!this.listDescription) {
            this.listDescription = this._fb.array([]);
        }
        if (this.disableStatus) {
            this.listDescription.controls.forEach(function (control) { return control.disable(); });
        }
        else {
            this.listDescription.controls.forEach(function (control) { return control.enable(); });
        }
    };
    PassportAffiliateBranchComponent.prototype.updateAffiliateBranch = function () {
        var _this = this;
        this.listDescription = this._fb.array([]);
        if (this.nextUsage.affiliate_branch.length === 0) {
            this.addNew();
        }
        var Counter = 0;
        this.nextUsage.affiliate_branch.forEach(function (usage) {
            _this.addForm(usage);
            Counter++;
            if (Counter === _this.nextUsage.affiliate_branch.length) {
                _this.loaded = true;
            }
        });
        this.updateDisableStatus();
        if (this.parentFormGroup) {
            this.parentFormGroup.setControl('nextUsages', this.listDescription);
        }
        if (this.passportChildComponentsEventListenerService && !this.loaded) {
            setTimeout(function () {
                _this.passportChildComponentsEventListenerService
                    .addFormProgrammaticlyAction.emit({ controlName: 'affiliate_branch', control: _this.listDescription });
            });
        }
    };
    PassportAffiliateBranchComponent.prototype.changePassport = function (passport) {
        this.passport = passport;
        return this;
    };
    PassportAffiliateBranchComponent.prototype.addNew = function () {
        if (this.isDisabled) {
            return;
        }
        var affiliate_branch = new AffiliateBranch({});
        this.addForm(affiliate_branch);
    };
    PassportAffiliateBranchComponent.prototype.addForm = function (affiliateBranch) {
        var formGroup = this._fb.group({
            id: [affiliateBranch.id],
            description: [affiliateBranch.description],
            stakeholder: [affiliateBranch.stakeholder],
        });
        this.listDescription.push(formGroup);
    };
    PassportAffiliateBranchComponent.prototype.removeAffiliateBranch = function (index) {
        if (this.isDisabled) {
            return;
        }
        var affiliateBranchForm = this.listDescription.controls[index];
        var idFormAffiliateBranch = affiliateBranchForm.get('id');
        if (!!idFormAffiliateBranch && idFormAffiliateBranch.value) {
            this.deleteAffiliateBranch(idFormAffiliateBranch.value);
        }
        this.listDescription.removeAt(index);
        this.listDescription.markAsDirty();
        if (this.listDescription.length === 0) {
            this.addNew();
        }
    };
    PassportAffiliateBranchComponent.prototype.deleteAffiliateBranch = function (id) {
        this.affiliateBranchService.remove(id).subscribe();
    };
    PassportAffiliateBranchComponent.prototype.isAddButtonVisible = function (index) {
        return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && index === this.listDescription.length - 1;
    };
    PassportAffiliateBranchComponent.prototype.isRemoveButtonVisible = function () {
        return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && this.nextUsage.affiliate_branch.length > 0;
    };
    return PassportAffiliateBranchComponent;
}());
export { PassportAffiliateBranchComponent };
