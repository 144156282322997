import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import {
  ImportSuccessSummaryDeposit
  , ImportSuccessSummaryDocuments
  , ACTION_MAP
  , VOID_TYPE
  , PASSPORT_COPY_TYPE
  , TYPE_DOCUMENT, ImportSuccessSummary, ImportSuccessReport
} from '../../entities/deposit/models/import-success-summary.class';


@Injectable()
export class ImportSuccessSummaryService extends CrudService {
  protected namespace = 'import-success-summary';
  protected ModelClass = ImportSuccessSummaryDeposit;


  public getImportSummarySuccess(id) {
        return this.sendGet(`${this.getEndpoint('deposit-success-summary/get_by_import')}/${id}`)
        .map((data) => data.json())
        .catch(this.onError.bind(this))
        .map((res: ImportSuccessSummary) => this.buildSuccessReportData(res));
    }

    private buildSuccessReportData(data: ImportSuccessSummary): ImportSuccessReport {
        const {
            depositArray,
            documentsArray,
            linesCount,
            creationDate,
            username
        } = data;

        const depositsCreated = [];
        const depositsUpdated = [];
        const depositsVoided = [];
        const passportsCreated = [];
        const picturesNotFound = [];
        const documentsNotFound = [];

        for (let i = 0 ; i < depositArray.length; i++) {

            const {
                deposit_id,
                row_number,
                action_status,
                void_creation,
                name,
                local_passport_copy
            } = depositArray[i];

            const successSummary = new ImportSuccessSummaryDeposit(
                deposit_id,
                Number(row_number),
                Number(action_status),
                Number(void_creation),
                name
            );

            if (Number(action_status) === ACTION_MAP.CREATION_ACTION ) {
                depositsCreated.push(successSummary);
            }

            if (Number(action_status) === ACTION_MAP.UPDATE_ACTION ) {
                depositsUpdated.push(successSummary);
            }

            if (Number(void_creation) === VOID_TYPE.VOID) {
                depositsVoided.push(successSummary);
            }

            if (Number(local_passport_copy) === PASSPORT_COPY_TYPE.LOCAL_PASSPORT_COPY) {
                passportsCreated.push(successSummary);
            }
        }

        for (let i = 0 ; i < documentsArray.length; i++) {
            const {
                row_number,
                url,
                type_document
            } = documentsArray[i];

            const summaryDocument = new ImportSuccessSummaryDocuments(row_number, url, type_document);

            switch (Number(type_document)) {
                case TYPE_DOCUMENT.DOCUMENT:
                    documentsNotFound.push(summaryDocument);
                    break;
                case TYPE_DOCUMENT.PHOTO:
                    picturesNotFound.push(summaryDocument);
                    break;
            }
        }
        return {
            depositsCreated,
            depositsUpdated,
            depositsVoided,
            passportsCreated,
            picturesNotFound,
            documentsNotFound,
            linesCount,
            creationDate,
            username
        };
    }
}
