import { Type } from '@angular/core';
import { CurrencyService } from '../services/currency.service';
import { ActiveRecord } from './active-record.class';

export class Currency extends ActiveRecord {
  protected provider: Type<CurrencyService> = CurrencyService;

  id: number;
  iso: string;
  symbol: string;

  fields() {
    return ['iso', 'symbol', 'id'];
  }
}
