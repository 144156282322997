import { IPlumbStep } from '../interfaces/plumb-step.interface';
import { EcosystemStepService } from '../../../entities/ecosystem/services/ecosystem-step.service';
import { ECOSYSTEM_STEP } from '../values/ecosystem-step-type.const';
import { ECOSYSTEM_STEP_STATUS } from '../values/ecosystem-step-status.const';
import * as PLUMB from '../values/plumb-styles.const';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { NeedStatus } from '../../../entities/need/models/need-status.class';
import { Ecosystem } from './ecosystem.class';
import { TransformLocation } from '../../../entities/transformation/models/transform-location.class';
import { DepositOrder } from './deposit-order.class';
import { TransformOrder } from './transform-order.class';
import { ECOSYSTEM_OFFER_STATUS, GET_NEED_STEP_STATUS_LABEL } from '../values/ecosystem-offer-status.const';
import { ECOSYSTEM_STATUS } from '../values/ecosystem-status.const';
import { OrderPassport } from './order-passport.class';
import { VirginProduct } from '../../../entities/virgin-product/models/virgin-product.class';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { DatePipe } from '@angular/common';
import {
  GET_TRANSFORM_ORDER_STEP_STATUS_LABEL,
  TRANSFORM_ORDER_STATUS
} from '../../transformation-manager/values/transform-order-statuses.const';
import IS_VALIDATABLE_STEP = ECOSYSTEM_STEP.IS_VALIDATABLE_STEP;
import ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP.TYPE;
import IS_CONFIRMABLE_STEP = ECOSYSTEM_STEP.IS_CONFIRMABLE_STEP;
import { SummaryDetails } from './summary-details.intercface';
import { Deposit } from '../../../entities/deposit/models/deposit.class';
import { Transform } from '../../../entities/transformation/models/transform.class';
import moment from 'moment';
import {EcosystemProblem} from '../../../entities/ecosystem/models/ecosystem-problem.model';

export class EcosystemStep extends ExtendedActiveRecord implements IPlumbStep {


  protected provider = EcosystemStepService;

  id: number;
  parent_id: number;
  ecosystem_id: number;
  title: string;
  type: number;
  order_aggregate: TRANSFORM_ORDER_STATUS;
  entity_id: number;
  x_pos: number;
  y_pos: number;
  linked_date: string;
  status: number;
  site_id: number;

  order: DepositOrder;
  orders: TransformOrder[] = [];  //TODO check if it is necessary to have an array here
  orderPassport: OrderPassport;
  deposit: Deposit ;
  transform: Transform ;

  confirmed_distance: number;
  confirmed_price: number;
  confirmed_quantity: number;
  site_name: string;
  transformation_type: number;
  passport_name: string;
  english_title: string;
  virgin_product_price: number;
  description: string;
  summary_details: SummaryDetails;
  problem: EcosystemProblem;

  //Link to parent
  public ecosystem: Ecosystem;
  public endpoints: any[];
  public overlay: any;

  protected datePipe: DatePipe = ServiceLocator.injector.get(DatePipe);

  static toSelectorId(id: number): string {
    return `item_${id}`;
  }

  static fromSelectorId(selectorId: string): number {
    return Number(selectorId.replace('item_', ''));
  }

  constructor(data?: any) {
    super(Object.assign({
      linked_date: '',
    }, data));
    data = !!data ? data : {};
    if (data.source) {
      let source = data.source;
      this.title = source.name;
      this.entity_id = source.id;
      this.type = source.plumb_type;

      if (source instanceof TransformLocation) {
        this.title = source.transformation_name;
        this.entity_id = source.transformation_id;
        this.site_id = source.id;
      }
      if (source instanceof VirginProduct) {
        this.title = source.passport.name;
      }
    }
    if (data.deposit) {
      this.deposit = new Deposit(data.deposit);
    }
    if (data.transformation) {
      this.transform = new Transform(data.transformation);
    }
    if (data.orders) {
      this.orders = data.orders.map(item => new TransformOrder());
    }
    if (data.problem) {
      this.problem = new EcosystemProblem(data.problem);
    }
    if (data.estimated_distance || data.estimated_distance === 0) {
      this["estimated_distance"] = data.estimated_distance;
    }
    this.x_pos = this.x_pos || 200;
    this.y_pos = this.y_pos || 100;
  }

  get endpointsParams() {
    let TYPE_ENDPOINTS = {
      [ECOSYSTEM_STEP_TYPE.NEED]: [PLUMB.SINGLE_TARGET_ENDPOINT],
      [ECOSYSTEM_STEP_TYPE.DEPOSIT]: [PLUMB.SOURCE_ENDPOINT],
      [ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT]: [PLUMB.SOURCE_ENDPOINT],
      [ECOSYSTEM_STEP_TYPE.STAKEHOLDER]: [PLUMB.SOURCE_ENDPOINT, PLUMB.MULTI_TARGET_ENDPOINT],
      [ECOSYSTEM_STEP_TYPE.TRANSFORM]: [PLUMB.SOURCE_ENDPOINT, PLUMB.MULTI_TARGET_ENDPOINT],
    };

    return (TYPE_ENDPOINTS[this.type] || [])
      .map(endpoint => Object.assign({}, endpoint))
      ;
  }

  updateEndpoints(isEnabled: boolean) {
    this.endpoints.forEach((endpoint) => {
      endpoint.setEnabled(isEnabled);
      endpoint.canvas.className = '';
      endpoint.canvas.classList.add(...this.endpointClass);
    });
  }


  updateOverlay() {
    try {
      this.overlay && this.overlay.setLabel(this.datePipe.transform(this.linked_date, 'dd-MM-y') || '');
    } catch (err) {
      console.warn(err);
    }
  }

  get selectorId(): string {
    return EcosystemStep.toSelectorId(this.id);
  }

  get selectorParentId(): string {
    return EcosystemStep.toSelectorId(this.parent_id);
  }

  get extraClass(): string {
    return [
      this.typeClass,
      this.getStatusCssClass(),
    ].map(s => `plumb__item--` + s).join(' ');
  }

  get overlayClass(): string {
    let classes = [
      'ecosystem-edit__linked-date',
    ];

    if (this.type === ECOSYSTEM_STEP_TYPE.NEED) {
      classes.push('ecosystem-edit__linked-date-top');
    } else {
      classes.push('ecosystem-edit__linked-date-bottom');
    }

    return classes.join(' ');
  }

  get endpointClass(): string[] {
    return [
      'plumb__endpoint',
      'plumb__endpoint--' + this.getStatusCssClass(),
    ];
  }

  get cardClass(): string {
    let statusClass = '';
    if (this.type !== ECOSYSTEM_STEP_TYPE.NEED) {
      statusClass = 'ecosystem-card__step-status--' + ECOSYSTEM_STEP_STATUS[this.status].toLowerCase();
    } else {
      let status: NeedStatus = this.ecosystem.need.statusData;
      statusClass = 'ecosystem-card__step-status--' + status.text.toLowerCase();
    }
    return [
      statusClass,
    ].join(' ');
  }

  //TODO Both deposit and transform order statuses are defined in GET_TRANSFORM_ORDER_STEP_STATUS_LABEL
  getStatusText() {
    let statusText = '';
    switch (this.type) {
      //this.order_agregate is one used before
      case ECOSYSTEM_STEP_TYPE.DEPOSIT:

        statusText =   GET_TRANSFORM_ORDER_STEP_STATUS_LABEL(this.order_aggregate)
        break;
        case ECOSYSTEM_STEP_TYPE.TRANSFORM:

          statusText =   GET_TRANSFORM_ORDER_STEP_STATUS_LABEL(this.order_aggregate)
          break;
      case ECOSYSTEM_STEP_TYPE.NEED:
        if(this.ecosystem.ecosystem_offer) {
          statusText = GET_NEED_STEP_STATUS_LABEL(this.ecosystem.ecosystem_offer.status);
        } else {
          statusText = GET_NEED_STEP_STATUS_LABEL(ECOSYSTEM_OFFER_STATUS.DRAFT);
        }
        break;
      default:
        statusText = ECOSYSTEM_STEP_STATUS[this.status];
    }
    return statusText.replace(/_/g, ' ');
  }

  getStatusCssClass() {
    let cssClass = '';
    switch(this.type) {
      case ECOSYSTEM_STEP_TYPE.NEED:
        cssClass = this.ecosystem.ecosystem_offer
          ? ECOSYSTEM_OFFER_STATUS[this.ecosystem.ecosystem_offer.status]
          : 'draft';
        break;

      //TODO double check if it breaks anything
      case ECOSYSTEM_STEP_TYPE.DEPOSIT:
        cssClass = this.order
          ? ECOSYSTEM_STEP_STATUS[this.order.status]
          : ECOSYSTEM_STEP_STATUS[this.status];
          if (this.status != ECOSYSTEM_OFFER_STATUS.DRAFT)
          cssClass = this.deposit  ?   cssClass : ECOSYSTEM_STEP_STATUS[ECOSYSTEM_STEP_STATUS.REFUSED]
        break;

      case ECOSYSTEM_STEP_TYPE.TRANSFORM:

        cssClass =   this.orders && this.orders.length > 0
          ? ECOSYSTEM_STEP_STATUS[this.orders
            .map((order: TransformOrder) => order.status)
            .reduce((lowestStatus: number, status: number) => status < lowestStatus ? status : lowestStatus)
            ]
          : ECOSYSTEM_STEP_STATUS[this.status];
            if  (this.status != ECOSYSTEM_OFFER_STATUS.DRAFT)
          cssClass = this.transform ?   cssClass : ECOSYSTEM_STEP_STATUS[ECOSYSTEM_STEP_STATUS.REFUSED]
        break;
      default:
        cssClass = ECOSYSTEM_STEP_STATUS[this.status];
    }
    return cssClass.toLowerCase();
  }

  // get statusView() {
  //   let cssClass = this.statusClass;
  //   if (this.type === ECOSYSTEM_STEP_TYPE.NEED) {
  //     cssClass = (<any>{
  //         draft: 'stand by',
  //         waiting_for_validation: 'offer sent',
  //         ask_for_modification: 'ask for counter proposal',
  //       })[cssClass] || cssClass;
  //   }
  //   return cssClass.replace(/_/g, ' ');
  // }

  get typeView() {
    return this.typeClass.replace(/_/g, ' ');
  }

  // get statusClass(): string {
  //   let cssClass;
  //   if (this.type !== ECOSYSTEM_STEP_TYPE.NEED) {
  //     cssClass = ECOSYSTEM_STEP_STATUS[this.getCurrentStatus()];
  //   } else {
  //     cssClass = this.ecosystem.ecosystem_offer && ECOSYSTEM_OFFER_STATUS[this.ecosystem.ecosystem_offer.status] || 'DRAFT';
  //   }
  //   return cssClass.toLowerCase();
  // }

  get typeClass(): string {
    return ECOSYSTEM_STEP_TYPE[this.type].toLowerCase();
  }

  get canEditOrder() {
    return [
      ECOSYSTEM_STEP_STATUS.BOOKED,
      ECOSYSTEM_STEP_STATUS.STAND_BY,
      ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION,
      ECOSYSTEM_STEP_STATUS.ASK_FOR_MODIFICATION,
    ].includes(this.status);
  }

  get canEdit() {
    return this.ecosystem.canEditSteps;
  }

  get canValidateManually() {
    let typeMatched = IS_VALIDATABLE_STEP(this.type);
    let ecosystemStatusMatched = [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(this.ecosystem.status);
    let stepStatusMatched = this.status !== ECOSYSTEM_STEP_STATUS.BOOKED;
    return typeMatched && ecosystemStatusMatched && stepStatusMatched;
  }
  get canAskValidateAgain() {
    let typeMatched = IS_VALIDATABLE_STEP(this.type);
    let ecosystemStatusMatched = [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(this.ecosystem.status);
    let stepStatusMatched = this.status === ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION;
    return typeMatched && ecosystemStatusMatched && stepStatusMatched;
  }

  get canUnvalidate() {
    let typeMatched = IS_VALIDATABLE_STEP(this.type);
    let ecosystemStatusMatched = this.ecosystem.status === ECOSYSTEM_STATUS.CREATED;
    let stepStatusMatched = this.status === ECOSYSTEM_STEP_STATUS.BOOKED;
    return typeMatched && ecosystemStatusMatched && stepStatusMatched;
  }

  get canAskForValidation() {
    let typeMatched = IS_VALIDATABLE_STEP(this.type);
     let ecosystemStatusMatched = [ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.CREATED].includes(this.ecosystem.status);
    let stepStatusMatched = ![
              ECOSYSTEM_STEP_STATUS.BOOKED
      ].includes(this.status);
    return typeMatched && ecosystemStatusMatched && stepStatusMatched;
  }

  get canConfirmManually() {
    let ecosystemStatusMatched = this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS;
    if (this.type === ECOSYSTEM_STEP_TYPE.NEED) {
     /* let hasUnconfirmedSteps = Boolean(~this
        .ecosystem
        .steps
        .filter((step: EcosystemStep) => step.type !== ECOSYSTEM_STEP_TYPE.NEED) //Skip need steps
        .findIndex((step: EcosystemStep) => step.status !== ECOSYSTEM_STEP_STATUS.CONFIRMED));*/
      let offerStatusMatched = this.ecosystem.ecosystem_offer
        && [ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION,
          ECOSYSTEM_OFFER_STATUS.PROBLEM_REPORTED,
          ECOSYSTEM_OFFER_STATUS.VALIDATED].includes(this.ecosystem.ecosystem_offer.status);
      return ecosystemStatusMatched && offerStatusMatched;
    } else {
      let typeMatched = IS_CONFIRMABLE_STEP(this.type);
      let stepStatusMatched = this.status !== ECOSYSTEM_STEP_STATUS.CONFIRMED;
      // const dateIsPassed = !moment(this.linked_date, ['YYYY-MM-DD', 'DD-MM-YYYY']).isAfter(moment());
      return typeMatched && ecosystemStatusMatched && stepStatusMatched;
    }
  }
 
  get canNeedConfirmManually() {
    if(this.type === ECOSYSTEM_STEP_TYPE.NEED && this.status !== ECOSYSTEM_STEP_STATUS.CONFIRMED){
      return this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS;
    }
  }

  get canConfirmWhenProblemReported() {
    if(this.type === ECOSYSTEM_STEP_TYPE.NEED && this.status === ECOSYSTEM_STEP_STATUS.PROBLEM_REPORTED){
      return this.ecosystem.status === ECOSYSTEM_STATUS.DONE;
    }
  }

  getTypeName(value: number): string {
    return ECOSYSTEM_STEP_TYPE[value];
  }
  // getCurrentStatus() : number {
  //   let currentStatus = this.status;
  //   if (this.orders && this.orders.length > 0) {
  //     currentStatus = this.orders[0].status;
  //     this.orders.forEach((order) => {
  //       // console.log(ECOSYSTEM_ORDER_STATUS[order.status]);
  //       if (this.getStatusPriority(order.status) < this.getStatusPriority(currentStatus)) {
  //         currentStatus = order.status;
  //       }
  //     });
  //   }else if (this.order) {
  //     currentStatus = this.order.status;
  //   }
  //   return currentStatus;
  // }
  //
  // getStatusPriority(statusValue: number) : number {
  //   let statusPriorities: number[] = [
  //     ECOSYSTEM_ORDER_STATUS.REJECTED,
  //     ECOSYSTEM_ORDER_STATUS.ASK_FOR_MODIFICATION,
  //     ECOSYSTEM_ORDER_STATUS.STAND_BY,
  //     ECOSYSTEM_ORDER_STATUS.PENDING,
  //     ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED,
  //     ECOSYSTEM_ORDER_STATUS.WAITING_FOR_CONFIRMATION,
  //     ECOSYSTEM_ORDER_STATUS.VALIDATED,
  //     ECOSYSTEM_ORDER_STATUS.CONFIRMED
  //   ];
  //   let priority = statusPriorities.indexOf(statusValue);
  //   if (priority === -1) {
  //     return statusPriorities.length;
  //   }else {
  //     return priority;
  //   }
  // }

  public fields(): string[] {
    return [
      'id',
      'title',
      'entity_id',
      'parent_id',
      'order_aggregate',
      'type',
      'x_pos',
      'y_pos',
      'linked_date',
      'ecosystem_id',
      'status',
      'site_id',
      'summary_details',
      'confirmed_distance',
      'confirmed_price',
      'confirmed_quantity',
      'site_name',
      'transformation_type',
      'passport_name',
      'english_title',
      'virgin_product_price',
      'description',
      'problem'
    ];
  }
  isStepValid() {
    // add check for need if needed
   // if (this.type === ECOSYSTEM_STEP_TYPE.NEED)  return  this.ecosystem.need_id
    if (this.type === ECOSYSTEM_STEP_TYPE.TRANSFORM)  return  this.transform != null
    if (this.type === ECOSYSTEM_STEP_TYPE.DEPOSIT)  return  this.deposit != null
    return true
  }

  getInvalidTitle() {
    let title = "Step is no longer valid";
    if (this.type === ECOSYSTEM_STEP_TYPE.NEED)  title = 'The need is no longer valid' ;
    if (this.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) title = 'The transformation is no longer valid' ;
    if (this.type === ECOSYSTEM_STEP_TYPE.DEPOSIT)  title = 'The deposit is no longer valid' ;
    return title;
  }

}
