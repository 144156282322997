import * as tslib_1 from "tslib";
import { CrudService } from './crud.service';
import { Currency } from '../models/currency.class';
var CurrencyService = /** @class */ (function (_super) {
    tslib_1.__extends(CurrencyService, _super);
    function CurrencyService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'currency';
        _this.ModelClass = Currency;
        return _this;
    }
    return CurrencyService;
}(CrudService));
export { CurrencyService };
