import * as tslib_1 from "tslib";
/**
 * Created by aleksandr on 30.06.17.
 */
import { EventEmitter, NgZone } from '@angular/core';
import { ChatRoom } from './models/chat-room.model';
import { Observable } from 'rxjs/Observable';
import { EChatMessageTypes } from './interfaces/chat-message.interface';
import { ChatMessage } from './models/chat-message.model';
import { ServiceLocator } from '../services/service-locator';
import { DeepStreamService } from '../services/deepstream.service';
import { NotificationEntry } from '../notification/models/notification-entry.model';
import { ThreadApiService } from './thread-api.service';
import { ChatData } from './models/chat-data.class';
var ChatService = /** @class */ (function (_super) {
    tslib_1.__extends(ChatService, _super);
    function ChatService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.roomChanged$ = new EventEmitter();
        _this.messageReceived$ = new EventEmitter();
        _this.notificationReceived$ = new EventEmitter();
        return _this;
    }
    Object.defineProperty(ChatService.prototype, "contacts", {
        get: function () {
            return ChatData.contacts;
        },
        set: function (value) {
            ChatData.contacts = value;
        },
        enumerable: true,
        configurable: true
    });
    ;
    ;
    Object.defineProperty(ChatService.prototype, "activeRoom", {
        get: function () {
            return ChatData.activeRoom;
        },
        set: function (value) {
            ChatData.activeRoom = value;
        },
        enumerable: true,
        configurable: true
    });
    ;
    ;
    Object.defineProperty(ChatService.prototype, "rooms", {
        get: function () {
            return ChatData.rooms;
        },
        set: function (value) {
            ChatData.rooms = value;
        },
        enumerable: true,
        configurable: true
    });
    ;
    ;
    Object.defineProperty(ChatService.prototype, "ownerId", {
        get: function () {
            return ChatData.ownerId;
        },
        set: function (value) {
            ChatData.ownerId = value;
        },
        enumerable: true,
        configurable: true
    });
    ;
    ;
    Object.defineProperty(ChatService.prototype, "ownerAvatar", {
        get: function () {
            return ChatData.ownerAvatar;
        },
        set: function (value) {
            ChatData.ownerAvatar = value;
        },
        enumerable: true,
        configurable: true
    });
    ;
    ;
    ChatService.prototype.connectToStream = function () {
        var _this = this;
        var deepstream = ServiceLocator.injector.get(DeepStreamService);
        var ngZone = ServiceLocator.injector.get(NgZone);
        // if(this.messageSubscription) {
        //   this.messageSubscription.unsubscribe();
        // }
        // if(this.notificationSubscription) {
        //   this.notificationSubscription.unsubscribe();
        // }
        ngZone.runOutsideAngular(function () {
            //this.messageSubscription =
            deepstream
                .listenMessages(function (message) { return Number(message.type) === EChatMessageTypes.MESSAGE
                || Number(message.type) === EChatMessageTypes.FILE; })
                .subscribe(function (message) { return ngZone.run(function () { return _this.onMessageReceived(message); }); });
            ///this.notificationSubscription =
            deepstream
                .listenMessages(function (message) { return Number(message.type) === EChatMessageTypes.NOTIFICATION; })
                .subscribe(function (event) { return ngZone.run(function () { return _this.onNotificationReceived(event); }); });
        });
    };
    ChatService.prototype.getContacts = function (roleId) {
        var _this = this;
        return _super.prototype.getContacts.call(this, roleId)
            .do(function (contacts) { return _this.onContactsLoaded(contacts); });
    };
    ChatService.prototype.getMyThreads = function () {
        var _this = this;
        return this
            .get({ expand: 'object,ecosystem,users' })
            .do(function (threads) { return _this.onThreadsLoaded(threads); });
    };
    ChatService.prototype.createRoom = function (id) {
        //TODO: implement it
        var room = new ChatRoom({ id: id, messages: [] });
        return Observable.of(room);
    };
    ChatService.prototype.setOwnerId = function (id) {
        ChatData.ownerId = id;
        return this;
    };
    ChatService.prototype.setOwnerAvatar = function (imageUrl) {
        ChatData.ownerAvatar = imageUrl;
        return this;
    };
    ChatService.prototype.changeActiveRoom = function (room) {
        var _this = this;
        return room
            .loadMessages()
            .do(function () { return _this.onRoomChanged(room); })
            .map(function () { return room; });
        //TODO: implement it, search in current list of rooms
        // return this
        //   .createRoom(id)
        //   .do((room) => room.connect())
        //   .do((room) => room.loadMessages())
        //   .do((room) => this.onRoomChanged(room));
        // return this
        //   .joinRoom(userId)
        //   //.do((room) => room.connect().subscribe())
        //   .do((room) => room.loadMessages().subscribe())
        //   .do((room) => this.onRoomChanged(room));
    };
    ChatService.prototype.onContactsLoaded = function (contacts) {
        var _this = this;
        this.contacts.length = 0;
        contacts.forEach(function (contact) { return _this.contacts.push(contact); });
    };
    ChatService.prototype.onThreadsLoaded = function (threads) {
        ChatData.threads = threads;
    };
    ChatService.prototype.onRoomChanged = function (room) {
        this.activeRoom = room;
        this.roomChanged$.emit(room);
    };
    ChatService.prototype.onMessageSent = function (response) {
        console.log('onMessageSent', response);
    };
    ChatService.prototype.onMessageReceived = function (message) {
        console.log('Chat service onMessageReceived', message, ChatMessage.create(message));
        this.messageReceived$.emit(ChatMessage.create(message));
    };
    ChatService.prototype.onNotificationReceived = function (event) {
        console.log('Chat service onMessageReceived', new NotificationEntry(event.notification));
        this.notificationReceived$.emit(new NotificationEntry(event.notification));
    };
    ChatService.prototype.onHistoryLoaded = function (history) {
        console.log('onHistoryLoaded', history);
    };
    return ChatService;
}(ThreadApiService));
export { ChatService };
