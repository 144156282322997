import {Component, EventEmitter, Input, OnInit, OnDestroy, ViewChildren, QueryList, ViewChild, AfterViewInit} from '@angular/core';
import { TransformLocationService } from '../../../../../entities/transformation/services/transform-location.service';
import { Transform } from '../../../../../entities/transformation/models/transform.class';
import { TransformSiteJunction } from '../../../../../entities/transformation/models/transform-site-junction.class';
import { TransformLocation } from '../../../../../entities/transformation/models/transform-location.class';
import { ISearchable } from '../../../../../shared/interfaces/searchable.interface';
import { Observable } from 'rxjs/Observable';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';
import {FormBuilder, FormGroup, NgModel} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'storing-sites',
  templateUrl: 'storing-sites.component.html',
  styleUrls: ['storing-sites.component.scss']
})
export class StoringSitesComponent implements OnInit, OnDestroy {
  @Input() transform: Transform;
  @Input() form: FormGroup;
  @Input() readOnly: boolean;
  @Input() validationListener;
  @ViewChildren(NgModel) inputs: QueryList<NgModel>;
  public onSiteChange: EventEmitter<{index: number, site: TransformLocation}> = new EventEmitter();
  public onSiteTouch$: EventEmitter<{index: number, site: TransformLocation}> = new EventEmitter();
  validAddress = true;
  private subscription = new Subscription();
  constructor(
    public locationService: TransformLocationService,
    private logNotificationService: LogNotificationService,
    private _translate: TranslateService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.guaranteeAtLeastOneLocation();
    this.form = this.form || this.fb.group({});
    if (!!this.transform.id && !this.readOnly) {
      this.transform.locations.forEach((location: TransformSiteJunction) => {
        if (location.id) {
          location.site.save().subscribe(savedLocation => {
            location.site.lat = savedLocation.lat;
            location.site.lng = savedLocation.lng;
          });
        }
      });
    }
    if (this.validationListener) {
      this.subscription.add(this.validationListener.subscribe(() => this.formMarkAsChecked()));
    }
  }

  private formMarkAsChecked() {
    this.transform.locations.forEach(
      (site, index) => this.onSiteTouch$.emit({index, site: site.site})
    );
    this.inputs.forEach((item) => {
      if (item.name === 'address') {
        item.control.markAsTouched();
      }
    });
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

  guaranteeAtLeastOneLocation() {
    if (this.transform.locations.length === 0) {
      this.add();
    }
  }

  add() {
    const siteJunction = new TransformSiteJunction();
    siteJunction.transformation_id = this.transform.id;
    this.transform.locations.push(siteJunction);
  }

  remove(siteJunction: TransformSiteJunction) {
    const deleteLocation = () => {
        const index = this.transform.locations.indexOf(siteJunction);
        if (index !== -1) {
          this.transform.locations.splice(index, 1);
          this.guaranteeAtLeastOneLocation();
        }
      };
    if (siteJunction.id) {
      siteJunction.destroy().subscribe(() => deleteLocation());
    } else {
     deleteLocation();
    }
  }

  onChangeName(inputText: string, siteJunction: TransformSiteJunction) {
    if (siteJunction.site.name !== inputText) {
      siteJunction.site.name = inputText;
      siteJunction.site.address = null;
      siteJunction.site.description = null;
      siteJunction.site.id = siteJunction.site_id = null;

      if (siteJunction.id) {
        siteJunction.destroy().subscribe();
        siteJunction.id = null;
      }
      this.validAddress = false;
    }
  }

  getListener(index: number): Observable<TransformLocation> {
    return this.onSiteChange.asObservable().filter(
      (item) => item.index === index)
      .map((item) => item.site);
  }

  getOnTouchListener(index: number): Observable<any> {
    return this.onSiteTouch$.asObservable().filter((item) => item.index === index);
  }

  onSelect(searchable: ISearchable, siteJunction: TransformSiteJunction, index: number) {
    this.locationService.view(searchable.id).subscribe((site: TransformLocation) => {
      siteJunction.site = site;
      this.onSiteChange.emit({index, site});
    });

    siteJunction.site_id = searchable.id;
    siteJunction.save().subscribe(res => {
      if (res.id) {
        siteJunction.id = res.id;
      }
    });
  }

  onCreate($event: any, siteJunction: TransformSiteJunction) {
    siteJunction.site.save().subscribe(res => {
      siteJunction.site_id = siteJunction.site.id = res.id;

      siteJunction.save().subscribe(responseJunction => {
        if (responseJunction.id) {
          siteJunction.id = responseJunction.id;
        }
      });
    });
  }

  onTextChange (siteJunction: TransformSiteJunction) {
    siteJunction.site.lat = null;
    siteJunction.site.lng = null;
    this.validAddress = false;
    this.save(siteJunction) ;
  }

  onPlaceChange(place: google.maps.places.PlaceResult, siteJunction: TransformSiteJunction) {
    let lat = null;
    let lng = null;
    if (place.geometry) {
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
      this.validAddress =  true;
    } else {
      this.validAddress =  false;
    }

    siteJunction.site.lat = lat;
    siteJunction.site.lng = lng;
    this.save(siteJunction);
  }

  save(siteJunction: TransformSiteJunction) {
    if (this.validAddress) {
      siteJunction.site.save().subscribe();
    }
  }
}
