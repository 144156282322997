import {
  ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, QueryList, ViewChildren
} from '@angular/core';
import {MdCheckboxChange, MdDialog, MdRadioChange} from '@angular/material';
import {Router} from '@angular/router';
import {Report} from '../../../../models/report.class';
import {
  BUILDING_CATEGORIES,
  DEPOSIT_TYPES_FOR_REPORTS,
  REPORT_FORM_VALUES,
  REPORT_SCOPE
} from '../../../../values/deposit-types-for-reports.const';
import {LogNotificationService} from '../../../../../../shared/services/log-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {ReportService} from '../../../../services/report.service';
import {CurrentUserService} from '../../../../../../shared/services/current-user.service';
import {IReportRequest} from '../../../../interfaces/reportRequest.interface';
import {SpinnerDialogComponent} from '../../../../../../shared/spinner/dialogs/spinner-dialog/spinner-dialog.component';
import {CalculationVariablesService} from '../../../../../superadmin/components/calculation-variables/calculation-variables.service';
import {
  ICalculationVariableRenewalRatesItem,
  ICalculationVariables
} from '../../../../../superadmin/components/calculation-variables/calculation-variables.interface';
import {CONSTRUCTION_LAYERS} from '../../../../../superadmin/components/calculation-variables/calculation-variables.const';
import * as moment from 'moment';
import {DepositLocationService} from '../../../../../../entities/deposit/services/deposit-location.service';
import {DepositLocation} from '../../../../../../entities/deposit/models/deposit-location.class';
import {FormArray, FormBuilder} from '@angular/forms';
import {ZoneService} from '../../../../../../shared/services/zone.service';
import {Zone} from '../../../../../../shared/models/zone.class';
import {ZONE_STATUS} from '../../../../../../shared/values/zone-status.const';
import {DepositImportDialogComponent} from '../../../../../agent/dialogs/deposit-import-dialog/deposit-import-dialog.component';
import {DIALOG_THEME} from '../../../../../../shared/helpers/dialog-themes.const';
import {DepositFile} from '../../../../../../entities/deposit/models/deposit-file.class';
import {Subscription} from 'rxjs/Rx';
import {DeepStreamService} from '../../../../../../shared/services/deepstream.service';
import {ReportImport} from '../../../../../../entities/deposit/models/report-import.class';
import {animate, style, transition, trigger} from '@angular/animations';
import {DepositTag} from '../../../../../../entities/deposit/models/deposit-tag.class';
import { ICarbonFootprint, ICarbonFootprintForm } from '../../../../../admin/interfaces/carbon-footprint.interface';
import {UserService} from '../../../../../../shared/services/user.service';
import { ReportProgressService } from '../../progress/report-progress.service';

@Component({
  moduleId: module.id,
  selector: 'admin-reports-form',
  templateUrl: 'admin-reports-form.component.html',
  styleUrls: ['admin-reports-form.component.scss'],
  animations: [
    trigger('popClose', [
        transition(':leave', [
          style({opacity: 1, height: '*'}),
          animate('0.1s linear', style({opacity: 0})),
          animate('0.1s linear', style({height: 0}) )
        ])
      ])
  ]
})
export class AdminReportsFormComponent implements OnInit {

  @Output() onReports: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('tagInput') tagInputs: QueryList<ElementRef>;

  private _spinnerDialogRef;
  private _depositType = REPORT_FORM_VALUES.DEPOSIT_TYPE_BUILDING;
  public renewalVariableRate: ICalculationVariableRenewalRatesItem[];
  public filteredSites: DepositLocation[] = [];

  deposit_types = DEPOSIT_TYPES_FOR_REPORTS;
  deposit_scopes = REPORT_SCOPE;
  constructionLayers = CONSTRUCTION_LAYERS;
  report_form_values = REPORT_FORM_VALUES;

  deposit_scope: number;
  buildingCategory;
  sitesForm: FormArray;
  sites: DepositLocation[];
  zones: Zone[];
  zoneId: number;
  reportCompletionStreamSubscription: Subscription;
  result: any;
  reportImport: ReportImport;

  report = new Report();
  building_categories = [];
  deconstruction = false;
  rehabilitation = false;
  renewalBuildingComponents = false;
  isRenewalRatesAble = false;
  renewalRateVisible = true;
  isCarbonReportOptionSelected = false;
  isTableVisible = false;
  disableSites = true;

  loadData: any;
  selectOption: any;
  companyType: number;
  fileItem: DepositFile[] = [];
  disableImportFile = true;
  tagOpenSuggestIndex = -1;
  carbonFootprintForm: ICarbonFootprintForm = {
    productAndConstruction: false,
    embodiedCarbon: false,
    icComponent: false,
    icConstruction: false,
    productStage: false,
    icConstructionCo2: null
    };

  constructor (private snackBarNotification: LogNotificationService,
               private changeDetectorRef: ChangeDetectorRef,
               private sitesService: DepositLocationService,
               private _translate: TranslateService,
               private reportService: ReportService,
               private _currentUserService: CurrentUserService,
               private _router: Router,
               private _dialog: MdDialog,
               private _calculationVariablesService: CalculationVariablesService,
               private _fb: FormBuilder,
               private zone: NgZone,
               private ds: DeepStreamService,
               private userService: UserService,
               private zoneService: ZoneService,
               public reportProgressService: ReportProgressService) {

  }
   async  ngOnInit() {
      this.loadData = await this.getCompany()

      this.sitesForm = this._fb.array([]);
      this.deposit_scope = this.isRestrictedAdmin ? this.report_form_values.DOCUMENT : this.report_form_values.ALL_SITES;
      this.disableImportFile = !this.isRestrictedAdmin;
      this.zoneService.get({expand: 'name, status', nopaginate: 1, status: ZONE_STATUS.PUBLISHED}).subscribe((zones: Zone[]) =>
        this.zones = zones
      );
      this._calculationVariablesService.getCalculationVariables().subscribe((response: ICalculationVariables) => {
        this.renewalVariableRate = response.renewal_rates_variables;
      });

      this.reportCompletionStreamSubscription = this.ds.reportImportDeepStream$.reportCompletionStream$.subscribe((ds_result) => {
        this.zone.run(() => {
          if (this.result && ds_result.data.import_id === this.result.import_id) {
            this.reportService.view(this.result.report_id,
              {expand: 'result,circularity,financial_residual_value,carbon_foot_print,material_health'})
            .subscribe(report => {
              this.onReports.emit(report);
              this._spinnerDialogRef.close();
            });
          }
        });
      });
     
      this.sitesService
      .get({expand: 'tags', sort: 'name', supervisor: 1, active: 1, report: 1})
      .subscribe((data: DepositLocation[]) => {
        this.sites = data;
        this.filteredSites = data;
      });
     
    }

   
    getCompany(): Promise<REPORT_FORM_VALUES> {
      
      return new Promise((resolve, reject) => {

        this.userService.view(this._currentUserService.infoData.id, {expand: 'company'})
        .subscribe(async res => {
          this.companyType = res.company.organisation_type
          this.selectOption = this.companyType != 2  ?  this.report_form_values.DEPOSIT_TYPE_OTHER : this.report_form_values.DEPOSIT_TYPE_BUILDING
          
          resolve(this.selectOption)
          
          },err =>{
            reject(err)
          })
   
     })

     

    }


    get isRestrictedAdmin() {
      return this._currentUserService.isRestrictedAdmin;
    }
    get depositType () {
      return this._depositType;
    }
    set depositType(type: number) {
      this._depositType = type;
      // @ts-ignore
      if (this._depositType === this.report_form_values.DEPOSIT_TYPE_OTHER) {
        this.clearAll();
      } else {
        this.renewalRateVisible = true;
      }
    }

    private clearAll() {
      this.clearResidualValueTypesFields();
      this.isRenewalRatesAble = false ;
      this.building_categories = [];
      this.isTableVisible = false;
      this.zoneId = null;
      this.report.financial_residual_value = false;
      this.renewalRateVisible = false;
      this.carbonFootprintForm.icComponent = false;
      this.carbonFootprintForm.icConstruction = false;
      this.carbonFootprintForm.icConstructionCo2 = null;
      this.carbonFootprintForm.productAndConstruction = false;
    }
    private clearResidualValueTypesFields() {
      this.deconstruction = false;
      this.rehabilitation = false;
      this.renewalBuildingComponents = false;
    }
    depositScopeChange () {
      if (this.deposit_scope === this.report_form_values.SELECTED_SITES) {
        this.disableSites = false;
        this.disableImportFile = true;
        this.sitesForm.controls.map(control => control.enable());
      } else if (this.deposit_scope === this.report_form_values.ALL_SITES) {
        this.disableSites = true;
        this.disableImportFile = true;
        this.sitesForm.controls.map(control => control.disable());
      } else {
        this.disableSites = true;
        this.disableImportFile = false;
      }
    }
    addSite() {
      this.sitesForm.push(this._fb.group({
        name: '',
        id: '',
        tags: [[]],
        availableTags: [[]],
        tagSuggestion: ''
      }));
    }
    removeSite(i) {
      this.sitesForm.removeAt(i);
      this.filteredSites = this.filterSelectedSites(this.sites);
    }
    selectedSite(site: DepositLocation, i: number) {
      this.sitesForm.at(i).patchValue({
        name: site.nameAddress,
        id: site.id,
        tags: [],
        availableTags: [...site.tags]
      });
      this.filteredSites = this.filterSelectedSites(this.sites);
      // this.changeDetectorRef.detectChanges();
    }
    private filterSelectedSites(list) {
      return list.filter(site => !this.sitesForm.value.some(form => site.id === form.id));
    }
    filterSiteSuggestion(value) {
      this.filteredSites = this.filterSelectedSites(this.sites).filter(this.filterByName(value));
    }
    private filterByName(value: string) {
      return s => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    }

  siteFocusOut(i: number) {
      this.filteredSites = this.filterSelectedSites(this.sites);
      setTimeout(() => {
       const formValues =  this.sitesForm.at(i).value;
       const siteBefore = this.sites.find(site => site.id === formValues.id);
       const nameAddress = siteBefore && siteBefore.nameAddress;
       if (!!nameAddress && nameAddress !== formValues.name) {
         this.sitesForm.at(i).patchValue({
           name: nameAddress
         });
       }
      });
    }
    openTagSuggest(i: number) {
      const el = this.tagInputs.find((_, j) => j === i);
      if (!!el) {
        this.tagOpenSuggestIndex = i;
        this.changeDetectorRef.detectChanges();
        el.nativeElement.focus();
      }
      this.filterTagSuggestion('',i);
    }
    tagSuggestBlur(i: number) {
      this.tagOpenSuggestIndex = this.tagOpenSuggestIndex === i ? -1 : this.tagOpenSuggestIndex;
      this.sitesForm.at(i).patchValue({
        tagSuggestion: ''
      });
    }
    selectTag(tag: DepositTag, locationIndex: number) {
      this.sitesForm.at(locationIndex).patchValue({
          tags: [...this.sitesForm.at(locationIndex).value.tags, tag]
        });
    }
    removeTag(locationIndex: number, tagIndex: number) {
      this.sitesForm.at(locationIndex).patchValue({
        tags: this.sitesForm.at(locationIndex).value.tags.filter((_, i) => i !== tagIndex)
      });
    }
    filterTagSuggestion(value: string, siteIndex: number) {
      const formValues = this.sitesForm.at(siteIndex).value;
      const location = this.sites.find(site => site.id === formValues.id);
      let allTags = [];
      if (location && location.tags) {
        allTags = location.tags;
      } else {
        return;
      }
      const chosenTagIds = new Set(formValues.tags.map(tag => tag.id));
      const availableTags = allTags.filter(tag => !chosenTagIds.has(tag.id)).filter(this.filterByName(value));
      this.sitesForm.at(siteIndex).patchValue({availableTags: availableTags});
      return availableTags;
    }
    reportTypeSelect (event: MdCheckboxChange ) {
      if (event.checked) {
        this.isRenewalRatesAble = true ;
      } else {
        this.clearAll();
      }
    }
    carbonReportTypeSelect () {
      this.isCarbonReportOptionSelected  = !this.isCarbonReportOptionSelected;
      this.carbonFootprintForm.productStage = false;
      this.carbonFootprintForm.embodiedCarbon = false;
      this.carbonFootprintForm.icComponent = false;
      this.carbonFootprintForm.icConstruction = false;
      this.carbonFootprintForm.productAndConstruction = false;
    }
    checkResidualValueChecked (event: MdCheckboxChange ) {
      this.isTableVisible = true;
        if (event.checked) {
          this.updateTableValue('over_30_years_commercial');
          this.buildingCategory = this.report_form_values.COMMERCIAL;
          this.building_categories = BUILDING_CATEGORIES;
        } else {
          this.isTableVisible = false;
          this.building_categories = [];
          this.buildingCategory = 0;
        }
    }
    buildingCategoryChanged(event: MdRadioChange) {
      let field: string;
      if (event.value === REPORT_FORM_VALUES.COMMERCIAL) {
        field = 'over_30_years_commercial';
      }
      if (event.value === REPORT_FORM_VALUES.RESIDENTIAL) {
        field = 'over_30_years_residential';
      }
      if (event.value === REPORT_FORM_VALUES.INSTINUTIONAL) {
        field = 'over_30_years_institutional';
      }
      this.updateTableValue(field);
    }
    updateTableValue(field) {
      this.constructionLayers.forEach((constructionLayer, i) => {
        constructionLayer.value = this.renewalVariableRate[i][field];
      });
    }
    getNotification (message: string) {
      this.snackBarNotification.error(`${
        this._translate.instant(message)}`);
    }
    validateData() {
      if (this.report.financial_residual_value === false &&
        this.report.circularity === false &&
        this.report.carbon_foot_print === false &&
        this.report.material_health === false ) {
          this.getNotification('At least one report need to be checked');
          return false;
      }
      // @ts-ignore
      if (this._depositType === this.report_form_values.DEPOSIT_TYPE_OTHER && this.report.financial_residual_value) {
        this.getNotification('No residual value for other deposit type');
        return false;
      }
      if (this.report.carbon_foot_print) {
        if ( this.carbonFootprintForm.productStage === false
          && this.carbonFootprintForm.embodiedCarbon === false
          && this.carbonFootprintForm.icComponent === false
          && this.carbonFootprintForm.icConstruction === false
          && this.carbonFootprintForm.productAndConstruction === false) {
            this.getNotification('You must select at least one type of carbon report');
            return false ;
        }
      }
      if (this.carbonFootprintForm.icConstruction && !this.carbonFootprintForm.icConstructionCo2){
        this.getNotification('Building site kg CO2 eq/ sqm quantity is required');
        return false ;
      }
      if (this.report.financial_residual_value) {
        if ( this.deconstruction === false && this.rehabilitation === false && this.renewalBuildingComponents === false ) {
            this.getNotification('You must select at least one type of financial report');
            return false ;
        }
        if (!this.zoneId) {
          this.getNotification('Please select a zone');
          return false;
        }
      }
      if (this.deposit_scope !== this.report_form_values.ALL_SITES &&
        this.deposit_scope !== this.report_form_values.DOCUMENT &&
        !this.sitesForm.value.some(site => typeof site.id === 'number')) {
          this.getNotification('You need to specify at least one site');
          return false;
      }
      if (this.deposit_scope === this.report_form_values.DOCUMENT && this.fileItem.length !== 1) {
          this.getNotification('File has not been upload');
          return false;
      }
      return true ;
    }
    generateReport() {
      if (!this.validateData()) {
        return ;
      }
      const data: IReportRequest = {
        zone_id: this.zoneId,
        material_health : this.report.material_health,
        carbon_foot_print : this.report.carbon_foot_print,
        embodied_carbon: this.carbonFootprintForm.embodiedCarbon,
        ic_component: this.carbonFootprintForm.icComponent,
        ic_construction: this.carbonFootprintForm.icConstruction,
        ic_construction_co2: this.carbonFootprintForm.icConstructionCo2,
        product_and_construction: this.carbonFootprintForm.productAndConstruction,
        product_stage: this.carbonFootprintForm.productStage,
        circularity : this.report.circularity,
        financial_residual_value : this.report.financial_residual_value,
        user_id : this._currentUserService.infoData.id,
        timezone_offset: moment().utcOffset(),
        import_id: this.deposit_scope === this.report_form_values.DOCUMENT ? this.reportImport.id : null,
        sites:  this.isSiteListEmpty() ? [] : this.sitesForm.value.map(s => s.id).filter(s => typeof s === 'number'),
        tags: this.isSiteListEmpty() ? [] : this.sitesForm.value.flatMap(s => s.tags.map(tag => tag.id)).filter(s => typeof s === 'number')
    };
      if (this.report.financial_residual_value) {
        data['deconstruction'] = this.deconstruction;
        data['rehabilitation'] = this.rehabilitation;
        data['renewal_building_components'] = this.renewalBuildingComponents;
        if (this.renewalBuildingComponents) {
          data['building_category']  = this.buildingCategory;
        }
      }
     this._spinnerDialogRef = this._dialog.open(SpinnerDialogComponent, {
        disableClose: true,
        data: {
          caption: this._translate.instant('SPINNER_SAVING_CAPTION'), isLoadingDotsShown: true
        }
      });
      this.reportService.getRequestReport(data).subscribe((result: any) => {
          if (this.deposit_scope !== this.report_form_values.DOCUMENT) {
            this.onReports.emit(result);
            this._spinnerDialogRef.close();
          } else {
            this.result = result;
          }
        },
        () => {
          this._spinnerDialogRef.close();
        }
      );
    }
    private isSiteListEmpty() {
      return this.deposit_scope === this.report_form_values.ALL_SITES || (this.filteredSites.length === 0
        && !this.sitesForm.value.find(siteData => (siteData.tags || []).length > 0 &&  (siteData.availableTags || []).length > 0));
    }
    importDeposits() {
      this.zone.run(() => {
        this._dialog.open(DepositImportDialogComponent, {
          ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
          data: {isReport: true},
        }).afterClosed().subscribe(doc => {
          if (doc) {
            this.fileItem = doc.reportFile;
            this.reportImport = doc.reportImport;
          }
        });
      });
    }
    removeDocument() {
      this.fileItem = [];
    }
    getCustomTooltipMessage(message: string){
      return this._translate.instant(message);
    }
    getEmbodiedCarbonOptionText(){
      if(this.depositType === this.report_form_values.DEPOSIT_TYPE_BUILDING){
        return "Embodied (A1-A5 + B1-B5 + C)";
      } else {
        return "Embodied carbon (A1-A3 + B1-B5 + C)";
      }
    }
}
