import { EventEmitter } from '@angular/core';
import { ADMIN_PERMISSIONS } from '../../dashboards/admin/admin.permission';
import { AGENT_PERMISSIONS } from '../../dashboards/agent/agent.permission';
import { DO_PERMISSIONS } from '../../dashboards/deposit-owner/deposit-owner.permission';
import * as i0 from "@angular/core";
var PermissionService = /** @class */ (function () {
    function PermissionService() {
        this.updateAppliedPermissions = new EventEmitter();
        this.isBeforeApplied$ = new EventEmitter();
        this.isAfterApplied$ = new EventEmitter();
        this.isAfterApplied = false;
        this.permissionContexts = [
            ADMIN_PERMISSIONS,
            DO_PERMISSIONS,
            AGENT_PERMISSIONS
        ];
        this.permissionContext = this.emptyPermissionContext;
        this.appliedPermissions = [];
    }
    Object.defineProperty(PermissionService.prototype, "permissionList", {
        get: function () {
            return this.permissionContext.permissions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PermissionService.prototype, "isOnUserExpandLoad", {
        get: function () {
            return this.isAfterApplied;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PermissionService.prototype, "appliedPermissionIds", {
        get: function () {
            return this.appliedPermissions.map(function (permission) { return permission.id; });
        },
        enumerable: true,
        configurable: true
    });
    PermissionService.prototype.setPermissionContext = function (role) {
        this.permissionContext = this.permissionContexts.find(function (context) { return context.roleId === role; }) || this.emptyPermissionContext;
    };
    PermissionService.prototype.hasAppliedPermission = function (permission) {
        return !!this.appliedPermissionIds.find(function (applied) { return applied === permission; });
    };
    PermissionService.prototype.addPermissions = function (user, permissionId) {
        if (!!this.appliedPermissions.filter(function (applied) { return applied.id === permissionId; })) {
            return;
        }
        this.addListOfValidPermission(user, [this.permissionList[permissionId]]);
    };
    PermissionService.prototype.addListOfValidPermission = function (user, newPermissions) {
        if (!user) {
            return;
        }
        var newAppliedPermissions = newPermissions.filter(function (permission) { return permission.isApplied(user); });
        this.appliedPermissions = this.appliedPermissions.concat(newAppliedPermissions);
        if (newAppliedPermissions.length > 0) {
            this.emitUpdate();
        }
    };
    PermissionService.prototype.emitUpdate = function () {
        this.updateAppliedPermissions.emit(this.appliedPermissions);
    };
    PermissionService.prototype.removePermission = function (user, permissionId) {
        var lengthBefore = this.appliedPermissions.length;
        this.appliedPermissions = this.appliedPermissions.filter(function (applied) { return applied.id === permissionId; });
        if (lengthBefore > this.appliedPermissions.length) {
            this.emitUpdate();
        }
    };
    PermissionService.prototype.setPermissions = function (user, permissionsIds) {
        var permissionLengthBefore = permissionsIds.length;
        this.appliedPermissions = [];
        this.addListOfValidPermission(user, this.getUniqAndValidPermissions(permissionsIds));
        if (permissionLengthBefore > 0 && !!this.appliedPermissions.length) {
            this.emitUpdate();
        }
    };
    PermissionService.prototype.getUniqAndValidPermissions = function (permissionsIds) {
        var _this = this;
        var distinctIDs = this.getDistinctIds(permissionsIds);
        var usedPermissions = new Set(this.appliedPermissionIds);
        return distinctIDs.map(function (id) { return _this.permissionList[id]; }).filter(function (permission) { return !!permission
            && !usedPermissions.has(permission.id); });
    };
    PermissionService.prototype.getDistinctIds = function (permissionsIds) {
        var visited = new Set();
        return permissionsIds.filter(function (id) {
            if (!visited.has(id)) {
                visited.add(id);
                return true;
            }
            return false;
        });
    };
    PermissionService.prototype.patchPermissions = function (user, permissionsIds) {
        this.addListOfValidPermission(user, this.getUniqAndValidPermissions(permissionsIds));
    };
    PermissionService.prototype.resetPermissions = function () {
        this.appliedPermissions = [];
        this.emitUpdate();
    };
    PermissionService.prototype.setDefaultPermissionOnContextChange = function (user, roleId) {
        this.setPermissionContext(roleId);
        this.setPermissions(user, this.permissionContext.defaultPermissionsIdsBefore);
        this.isAfterApplied = false;
        this.isBeforeApplied$.emit();
    };
    PermissionService.prototype.setDefaultPermissionsOnUserExpandLoad = function (user) {
        this.patchPermissions(user, this.permissionContext.defaultPermissionsIdsAfter);
        this.isAfterApplied = true;
        this.isAfterApplied$.emit();
    };
    Object.defineProperty(PermissionService.prototype, "emptyPermissionContext", {
        get: function () {
            return {
                roleId: null,
                defaultPermissionsIdsBefore: [],
                defaultPermissionsIdsAfter: [],
                permissions: {}
            };
        },
        enumerable: true,
        configurable: true
    });
    PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
export { PermissionService };
