import { Component, NgZone, Input } from '@angular/core';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';
import { UserService } from '../../../../shared/services/user.service';
import { MdDialog } from '@angular/material';
import { EsmNeedMatchDialogComponent } from '../../dialogs/esm-need-match-dialog/esm-need-match-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { TranslateService } from '@ngx-translate/core';
import {Need} from '../../../../entities/need/models/need.class';
import {NeedService} from '../../../../entities/need/services/need.service';
import {Ecosystem} from '../../models/ecosystem.class';
import { Router } from '@angular/router';
import {EsmNeedPickerDialogComponent} from '../../dialogs/esm-need-picker-dialog/esm-need-picker-dialog.component';

@Component({
    moduleId: module.id,
    selector: 'ecosystem-primary-header',
    templateUrl: 'ecosystem-primary-header.component.html',
})
export class EcosystemPrimaryHeaderComponent {
    @Input() isMainPage: boolean;
    ecosystemsInProcessCount: number;
    offerCount: number;

    constructor(private _userService: UserService
                , private _zone: NgZone
                , private _dialog: MdDialog
                , private translateService: TranslateService
                , private _needService: NeedService
                , private _router: Router
    ) {
        this._userService.getRoleCounters({ role: ERoleIds.ECOSYSTEM_MANAGER }).subscribe(
            (res: any) => {
                this.ecosystemsInProcessCount = Number(res.in_process);
                this.offerCount = Number(res.new_offers);
            });
    }

  openEcosystemPicker() {
    this._zone.run(() => {
      this._needService
        .get({
          expand: 'client,passport,client_contact',
          supervisor: true,
          esm_view: true,
        }).subscribe((records: Need[]) => {
          const dialogRef = this._dialog.open( EsmNeedPickerDialogComponent, {
            data: {
              records: records
            }
          });
          dialogRef.afterClosed().subscribe((result: Need) => {
            if (result) {
              this.createEcosystem(result);
            }
          });
        });
    });
  }

  private createEcosystem(record: Need) {
    new Ecosystem({
      name: `${this.translateService.instant('For')} ${record.name}`,
      need_id: record.id,
    }).save()
      .subscribe((ecosystem: Ecosystem) => this._router.navigate(['ecosystem/ecosystems', ecosystem.id]));
  }

    openNeedMatchDialogComponent() {
        this._zone.run(() =>
        this._dialog.open(EsmNeedMatchDialogComponent,
        {
          ...DIALOG_THEME.WIDE_PRIMARY,
        }));
      }

    getLangDependentImage() {
       switch (this.translateService.currentLang) {
           case 'en': {
               return 'assets/svg/intelligence-artificielle-button-flip-EN.svg';
           } case 'fr': {
                return 'assets/svg/intelligence-artificielle-button-flip-FR.svg';
            } case 'es': {
                return 'assets/svg/intelligence-artificielle-button-flip-ES.svg';
            } case 'pt': {
                return 'assets/svg/intelligence-artificielle-button-flip-PORT.svg';
            }
            default: {
                return 'assets/svg/intelligence-artificielle-button-flip-EN.svg';
            }
           }
       }
}
