import {Component, Input} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'circularity-rating-scale',
  templateUrl: 'circularity-rating-scale.component.html',
  styleUrls: ['circularity-rating-scale.component.scss']
})
export class CircularityRatingScaleComponent {
  @Input() gradeValue: number;
  @Input() mainColor: string;
  @Input() ratingPointsAmount: number;

  numberOfRatingValues() {
    return new Array(this.ratingPointsAmount).fill(null);
  }
  isItemActive(iteration: number): boolean {
    return Math.floor( this.gradeValue / 15 ) >= iteration + 1;
  }
}
