import * as tslib_1 from "tslib";
import { CrudService } from './crud.service';
import { ImportSuccessSummaryDeposit, ImportSuccessSummaryDocuments, ACTION_MAP, VOID_TYPE, PASSPORT_COPY_TYPE, TYPE_DOCUMENT } from '../../entities/deposit/models/import-success-summary.class';
var ImportSuccessSummaryService = /** @class */ (function (_super) {
    tslib_1.__extends(ImportSuccessSummaryService, _super);
    function ImportSuccessSummaryService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'import-success-summary';
        _this.ModelClass = ImportSuccessSummaryDeposit;
        return _this;
    }
    ImportSuccessSummaryService.prototype.getImportSummarySuccess = function (id) {
        var _this = this;
        return this.sendGet(this.getEndpoint('deposit-success-summary/get_by_import') + "/" + id)
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this))
            .map(function (res) { return _this.buildSuccessReportData(res); });
    };
    ImportSuccessSummaryService.prototype.buildSuccessReportData = function (data) {
        var depositArray = data.depositArray, documentsArray = data.documentsArray, linesCount = data.linesCount, creationDate = data.creationDate, username = data.username;
        var depositsCreated = [];
        var depositsUpdated = [];
        var depositsVoided = [];
        var passportsCreated = [];
        var picturesNotFound = [];
        var documentsNotFound = [];
        for (var i = 0; i < depositArray.length; i++) {
            var _a = depositArray[i], deposit_id = _a.deposit_id, row_number = _a.row_number, action_status = _a.action_status, void_creation = _a.void_creation, name_1 = _a.name, local_passport_copy = _a.local_passport_copy;
            var successSummary = new ImportSuccessSummaryDeposit(deposit_id, Number(row_number), Number(action_status), Number(void_creation), name_1);
            if (Number(action_status) === ACTION_MAP.CREATION_ACTION) {
                depositsCreated.push(successSummary);
            }
            if (Number(action_status) === ACTION_MAP.UPDATE_ACTION) {
                depositsUpdated.push(successSummary);
            }
            if (Number(void_creation) === VOID_TYPE.VOID) {
                depositsVoided.push(successSummary);
            }
            if (Number(local_passport_copy) === PASSPORT_COPY_TYPE.LOCAL_PASSPORT_COPY) {
                passportsCreated.push(successSummary);
            }
        }
        for (var i = 0; i < documentsArray.length; i++) {
            var _b = documentsArray[i], row_number = _b.row_number, url = _b.url, type_document = _b.type_document;
            var summaryDocument = new ImportSuccessSummaryDocuments(row_number, url, type_document);
            switch (Number(type_document)) {
                case TYPE_DOCUMENT.DOCUMENT:
                    documentsNotFound.push(summaryDocument);
                    break;
                case TYPE_DOCUMENT.PHOTO:
                    picturesNotFound.push(summaryDocument);
                    break;
            }
        }
        return {
            depositsCreated: depositsCreated,
            depositsUpdated: depositsUpdated,
            depositsVoided: depositsVoided,
            passportsCreated: passportsCreated,
            picturesNotFound: picturesNotFound,
            documentsNotFound: documentsNotFound,
            linesCount: linesCount,
            creationDate: creationDate,
            username: username
        };
    };
    return ImportSuccessSummaryService;
}(CrudService));
export { ImportSuccessSummaryService };
