import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MAP_IMPORT_PROGRESS_STATUS} from '../../values/import-progres.const';
import {ImportProgressService} from '../../services/import-progress.service';

@Component({
    moduleId: module.id,
    selector: 'import-progress',
    templateUrl: './import-progress.component.html',
    styleUrls: ['./import-progress.component.scss']
  })
  export class DepositImportProgressComponent {
    MAP_IMPORT_PROGRESS_STATUS = MAP_IMPORT_PROGRESS_STATUS;
    @Input() tables: Array<any>;
    @Output() onMaximize = new EventEmitter<string>();

    constructor(
      public importProgressService: ImportProgressService
    ) {
    }

    close() {
        this.importProgressService.resetProgressModal();
    }

    maximize() {
        this.onMaximize.emit();
    }

    getText() {

        switch (this.importProgressService.statusImportProgress) {
            case MAP_IMPORT_PROGRESS_STATUS.CHECKING:
                return 'Checking file';
            case MAP_IMPORT_PROGRESS_STATUS.PROGRESS:
                return 'Import in progress';
            case MAP_IMPORT_PROGRESS_STATUS.ERROR:
                return 'Error in file';
            case MAP_IMPORT_PROGRESS_STATUS.SUCCESS:
                return 'Import succesful';
        }
    }

    get isSuccess() {
      return MAP_IMPORT_PROGRESS_STATUS.SUCCESS === this.importProgressService.statusImportProgress;
    }

    get isError() {
      return MAP_IMPORT_PROGRESS_STATUS.ERROR === this.importProgressService.statusImportProgress;
    }
  }

