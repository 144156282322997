export const DEFAULT_RADIO_VALUES: {key: number, value: string}[] = [
  {key: 1, value: 'Yes'},
  {key: 0, value: 'No'},
  {key: 2, value: 'I don\'t know'},
];

export const DEFAULT_RADIO_VALUES_REVERSE: {key: number, value: string}[] = [
  {key: 0, value: 'Yes'},
  {key: 1, value: 'No'},
];

export const DEFAULT_RADIO_VALUE = {
  YES: 1,
  NO: 0,
  DONTKNOW:2
};
