/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i3 from "@angular/material";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/read-only/form-elems-hidden.directive";
import * as i6 from "../../../shared/read-only/read-only.service";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "./category-search.component";
var styles_CategorySearchComponent = [i0.styles];
var RenderType_CategorySearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategorySearchComponent, data: {} });
export { RenderType_CategorySearchComponent as RenderType_CategorySearchComponent };
function View_CategorySearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "md-basic-chip", [["role", "option"], ["tabindex", "-1"]], [[2, "mat-chip", null], [2, "mat-chip-selected", null], [1, "disabled", 0], [1, "aria-disabled", 0]], [[null, "focus"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (false !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MdChip_0, i2.RenderType_MdChip)), i1.ɵdid(1, 245760, [[7, 4]], 0, i3.MdChip, [i1.Renderer, i1.ElementRef], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "button", [["class", "delete"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeCategory(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i5.FormElemsHiddenDirective, [[2, i6.ReadOnlyService]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).selected; var currVal_2 = i1.ɵnov(_v, 1).disabled; var currVal_3 = i1.ɵnov(_v, 1)._isAriaDisabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.name)); _ck(_v, 2, 0, currVal_4); var currVal_5 = ((i1.ɵnov(_v, 5).readOnlyService == null) ? null : i1.ɵnov(_v, 5).readOnlyService.readOnly); _ck(_v, 4, 0, currVal_5); }); }
export function View_CategorySearchComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "category-search-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "md-input-container", [["class", "passportlist__search-categories"]], [[1, "align", 0], [2, "mat-input-container", null], [2, "mat-input-invalid", null], [2, "mat-focused", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._focusInput() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openCategoryDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MdInputContainer_0, i2.RenderType_MdInputContainer)), i1.ɵdid(2, 5292032, null, 6, i3.MdInputContainer, [i1.ChangeDetectorRef, [2, i7.NgForm], [2, i7.FormGroupDirective]], { floatPlaceholder: [0, "floatPlaceholder"] }, null), i1.ɵqud(335544320, 1, { _mdInputChild: 0 }), i1.ɵqud(335544320, 2, { _placeholderChild: 0 }), i1.ɵqud(603979776, 3, { _errorChildren: 1 }), i1.ɵqud(603979776, 4, { _hintChildren: 1 }), i1.ɵqud(603979776, 5, { _prefixChildren: 1 }), i1.ɵqud(603979776, 6, { _suffixChildren: 1 }), (_l()(), i1.ɵeld(9, 0, null, 1, 4, "md-chip-list", [["role", "listbox"], ["tabindex", "0"]], [[2, "mat-chip-list", null]], [[null, "focus"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).focus() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10)._keydown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MdChipList_0, i2.RenderType_MdChipList)), i1.ɵdid(10, 1097728, null, 1, i3.MdChipList, [i1.ElementRef], null, null), i1.ɵqud(603979776, 7, { chips: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CategorySearchComponent_1)), i1.ɵdid(13, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, 1, 2, "input", [["autocomplete", "off"], ["class", "passportlist__info-by"], ["mdInput", ""], ["readonly", ""], ["test-id", "category-selector"]], [[2, "mat-input-element", null], [8, "id", 0], [8, "placeholder", 0], [8, "disabled", 0], [8, "required", 0], [1, "aria-describedby", 0]], [[null, "blur"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._onBlur() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15)._onFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15)._onInput() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, [[1, 4]], 0, i3.MdInputDirective, [i1.ElementRef, i1.Renderer, [8, null]], { placeholder: [0, "placeholder"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_11 = (_co.categories.length ? "always" : ""); _ck(_v, 2, 0, currVal_11); var currVal_13 = _co.categories; _ck(_v, 13, 0, currVal_13); var currVal_20 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("Category")), ""); _ck(_v, 15, 0, currVal_20); }, function (_ck, _v) { var currVal_0 = null; var currVal_1 = true; var currVal_2 = i1.ɵnov(_v, 2)._isErrorState(); var currVal_3 = i1.ɵnov(_v, 2)._mdInputChild.focused; var currVal_4 = i1.ɵnov(_v, 2)._shouldForward("untouched"); var currVal_5 = i1.ɵnov(_v, 2)._shouldForward("touched"); var currVal_6 = i1.ɵnov(_v, 2)._shouldForward("pristine"); var currVal_7 = i1.ɵnov(_v, 2)._shouldForward("dirty"); var currVal_8 = i1.ɵnov(_v, 2)._shouldForward("valid"); var currVal_9 = i1.ɵnov(_v, 2)._shouldForward("invalid"); var currVal_10 = i1.ɵnov(_v, 2)._shouldForward("pending"); _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_12 = true; _ck(_v, 9, 0, currVal_12); var currVal_14 = true; var currVal_15 = i1.ɵnov(_v, 15).id; var currVal_16 = i1.ɵnov(_v, 15).placeholder; var currVal_17 = i1.ɵnov(_v, 15).disabled; var currVal_18 = i1.ɵnov(_v, 15).required; var currVal_19 = (i1.ɵnov(_v, 15).ariaDescribedby || null); _ck(_v, 14, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); }); }
export function View_CategorySearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-category-search", [], null, null, null, View_CategorySearchComponent_0, RenderType_CategorySearchComponent)), i1.ɵdid(1, 49152, null, 0, i9.CategorySearchComponent, [i3.MdDialog], null, null)], null, null); }
var CategorySearchComponentNgFactory = i1.ɵccf("app-category-search", i9.CategorySearchComponent, View_CategorySearchComponent_Host_0, { categories: "categories" }, { categoriesChange: "categoriesChange" }, []);
export { CategorySearchComponentNgFactory as CategorySearchComponentNgFactory };
