<article class="need-recipients">
  <div>
    <div>
      <button md-raised-button (click)="openRecipientDialog()">+ {{'New recipient' | translate}}</button>
    </div>
    <div>
      <li class="need-recipients-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group mat-focused">
          <input mdInput autocomplete="off" placeholder="{{'Search recipient' | translate}}" [(ngModel)]="search"
            (ngModelChange)="loadRecipients()">
        </md-input-container>
      </li>
    </div>
    <div>
      <div class="RecipientsListContainer">
        <div *ngFor="let client of clientList | paginate:{id:'needs'
        , itemsPerPage: pageInfo.perPage
        , currentPage: pageInfo.currentPage
        , totalItems: pageInfo.totalCount }" class="RecipientsListItems">
          <div>
            <img *ngIf="client.image" [src]="client.image" alt="client image" (error)="onImageError($event)">
          </div>
          <div>
            <span>{{ client.name }}</span>
          </div>
          <div>
            <svg viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="5.00021" cy="3.73684" rx="2.89474" ry="3.73684" fill="#B4299C" />
              <path
                d="M9.95273 9.89572C9.38565 7.76415 8.33488 7.69128 8.11805 7.63662C7.66772 7.52731 7.134 7.56375 6.85046 7.47266C6.80042 7.92812 5.98316 8.29249 4.9991 8.29249C4.01505 8.29249 3.19778 7.92812 3.14774 7.47266C2.8642 7.56375 2.33048 7.52731 1.88015 7.63662C1.64664 7.69128 0.612549 7.76415 0.0454661 9.89572C-0.0379284 10.1872 -0.00457064 10.4969 0.128861 10.752C0.59587 11.7176 3.03099 12.0091 5.01578 12.0091C6.98389 12.0091 9.43569 11.7176 9.9027 10.752C10.0028 10.4969 10.0361 10.1872 9.95273 9.89572Z"
                fill="#B4299C" />
            </svg>
            <span>{{client.client_contacts.length}}</span>
            <span>{{ (client.client_contacts.length == 1 ? 'Contact' : 'Contacts') | translate }}</span>
          </div>
          <div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#35485F">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z" />
              </svg>
              <span>{{ client.published_needs }}</span>
              <span>{{ (client.published_needs == 1 ? 'needsPublishedSingular' : 'needsPublishedPlural') | translate }}</span>
            </div>
            <div class="hamburgerMenu" [mdMenuTriggerFor]="menu" id="needItemMenu#{{client.id}}">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <md-menu #menu="mdMenu" x-position="before">
              <!--
                  Pour un prochain sprint
                <button md-menu-item (click)="setdefaultRecipient(client)">
                  {{ 'Set as default' | translate }}
                </button>
                <button md-menu-item (click)="duplicateRecipient(client)">
                    {{ 'Duplicate' | translate }}
                </button>
              -->
              <button md-menu-item (click)="viewEditRecipient(client)">
                {{ 'Edit / View' | translate }}
              </button>
              <button md-menu-item (click)="deleteRecipient(client)">
                {{ 'Delete' | translate }}
              </button>
            </md-menu>
          </div>
        </div>
      </div>
      <div class="needslist__container-pagination" test-id="needslist-pagination">
        <pagination-controls class="needslist__pagination" id="needs" (pageChange)="pageChange($event)" maxSize="10"
          directionLinks="true" autoHide="true" previousLabel="" nextLabel=""
          screenReaderPaginationLabel="{{ 'Pagination' | translate }}" screenReaderPageLabel="{{ 'page' | translate }}"
          screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}">
        </pagination-controls>
      </div>
    </div>
  </div>
</article>
