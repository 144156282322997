/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import-deposit-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./table-item/import-deposit-table-item.component.ngfactory";
import * as i3 from "./table-item/import-deposit-table-item.component";
import * as i4 from "../../services/import-configuration.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./import-deposit-table.component";
var styles_ImportDepositTableComponent = [i0.styles];
var RenderType_ImportDepositTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImportDepositTableComponent, data: {} });
export { RenderType_ImportDepositTableComponent as RenderType_ImportDepositTableComponent };
function View_ImportDepositTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "import-deposit-table-item", [], null, null, null, i2.View_ImportDepositTableItemComponent_0, i2.RenderType_ImportDepositTableItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.ImportDepositTableItemComponent, [i4.ImportConfigurationService], { table: [0, "table"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ImportDepositTableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "deposit-import__header--label space-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "passport__tab--label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "deposit-import__header--label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "deposit-import__header--required"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportDepositTableComponent_1)), i1.ɵdid(9, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.tables; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("LOOKUP_TABLE_TITLE")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("LOOKUP_TABLE_DESCRIPTION")); _ck(_v, 6, 0, currVal_1); }); }
export function View_ImportDepositTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "import-deposit-table", [], null, null, null, View_ImportDepositTableComponent_0, RenderType_ImportDepositTableComponent)), i1.ɵdid(1, 49152, null, 0, i7.ImportDepositTableComponent, [i4.ImportConfigurationService], null, null)], null, null); }
var ImportDepositTableComponentNgFactory = i1.ɵccf("import-deposit-table", i7.ImportDepositTableComponent, View_ImportDepositTableComponent_Host_0, { tables: "tables" }, {}, []);
export { ImportDepositTableComponentNgFactory as ImportDepositTableComponentNgFactory };
