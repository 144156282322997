<article class="ecosystem-catalog">
  <header class="ecosystem-catalog__header">
    <ul class="ecosystem-catalog__caption-list">
      <li (click)="changeTab(0)" [ngClass]="{'active': activeIndex === 0, 'ecosystem-catalog__caption-item': true}">
        <span class="ecosystem-catalog__caption-title">{{'Transformations' | translate}}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>
      </li>
      <li (click)="changeTab(1)" [ngClass]="{'active': activeIndex === 1, 'ecosystem-catalog__caption-item': true}">
        <span class="ecosystem-catalog__caption-title">{{'Sites' | translate }}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">4</span>
      </li>
      <li (click)="changeTab(2)" [ngClass]="{'active': activeIndex === 2, 'ecosystem-catalog__caption-item': true}">
        <span class="ecosystem-catalog__caption-title">{{'Order archive' | translate}}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>
      </li>
    </ul>
  </header>
  <section class="ecosystem-catalog__content">
    <ul class="ecosystem-catalog__content-list">
      <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 0">
        <tsm-transformations-catalog></tsm-transformations-catalog>
      </li>
      <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 1">
        <tsm-sites-catalog></tsm-sites-catalog>
      </li>
      <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 2">
        <tsm-order-archive-catalog></tsm-order-archive-catalog>
      </li>
    </ul>
  </section>
</article>
