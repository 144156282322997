import {Injectable} from '@angular/core';
import {ImportConfiguration} from '../models/import-configuration.class';
import {CrudService} from '../../shared/services/crud.service';
import {Observable} from 'rxjs/Observable';


@Injectable()
export class ImportConfigurationService extends CrudService {

    protected namespace = 'import-configuration';
    protected namespaceSingular = 'import-configurations';
    protected ModelClass = ImportConfiguration;



    public getOrCreate(tables): Observable<any> {
        return this.sendPost(this.getEndpoint('import-configuration/get_or_create'), {tables: tables})
        .map((data) => data.json())
        .map((res) => new ImportConfiguration(res))
          .catch(this.onError.bind(this));
    }


    public addSynonym(lookUpTableId, fieldOrder, fieldValue) {
        return this.sendPost(this.getEndpoint('import-configuration/add_synonym'),
        {
            lookUpTableId,
            fieldOrder,
            fieldValue
        })
        .map((data) => data.json())
        .catch(this.onErrorTranslation.bind(this));
    }

    public deleteSynonym(id) {
        return this.sendDelete(
            this.getEndpoint(`import-configuration/delete_synonym/${id}`)
        )
        .map((data) => data.json())
        .catch(this.onError.bind(this));
    }

    public getUpdateColumnListEndpoint() {
      return this.getEndpoint('import-configuration/update_columns_list');
    }

    public updateColumnsList(columnsList) {
        return this.sendPost(this.getUpdateColumnListEndpoint(), {
            columnsList,
        }).map((data) => data.json())
        .catch(this.onErrorTranslation.bind(this));
    }


}
