import { Injectable } from '@angular/core';
import { TransformOrder } from '../models/transform-order.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';

@Injectable()
export class TransformOrderService extends ExtendedCrudService {
  protected namespace = 'transformation-orders';
  protected namespaceSingular = 'transformation-order';
  protected ModelClass = TransformOrder;

  getEsmList(query?: any): Observable<any> {
    return this
      .sendGet(this.getEndpoint('transformation-order/esm_list'), {search: query})
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }

  getTsmList(query?: any): Observable<any> {
    return this
      .sendGet(this.getEndpoint('transformation-order/tsm_list'), {search: query})
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }

  protected getReportUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/problem');
  }

  public sendReport(id: number, problem: string) {
    return this.sendPost(this.getReportUrl(id), {problem});
  }

  protected getResendUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/resend');
  }

  getCount(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  public resend(id: number, data): Observable<any> {
    return this.sendPost(this.getResendUrl(id), data)
      .map((res: Response) =>  new TransformOrder(res.json()))
      .catch(this.onError.bind(this));
  }
}
