<article class="ecosystem-dashboard  type--simple" test-id="tsm-dashboard">
  <main class="ecosystem-dashboard__content">
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--counters">
      <ul class="ecosystem-dashboard__counter-list">
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{newOrders}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'New orders' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary ">{{validatedTransformations}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Validated transformations' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{transformationsInEcosystem}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Transformations in Ecosystems' | translate}}</span>
        </li>
      </ul>
    </section>
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--auto">
      <ul class="ecosystem-catalog__content-list type--single">
        <li class="ecosystem-catalog__content-item">
          <tsm-orders-catalog></tsm-orders-catalog>
        </li>
      </ul>
    </section>
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--tabs">
      <tsm-catalog></tsm-catalog>
    </section>
  </main>
</article>
