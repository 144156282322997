import { EcosystemPrefillService, PREFILL_OFFER_ID } from './ecosystem-prefill.service';
import { Observable } from 'rxjs/Observable';
import { OrderPassport } from '../models/order-passport.class';
import { count } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { TransformOrder } from '../models/transform-order.class';
import { EcosystemOffer } from '../../../entities/ecosystem-offer/ecosystem-offer.model';
import { ECOSYSTEM_STEP_TYPE } from '../values/ecosystem-step-type.const';
import * as i0 from "@angular/core";
import * as i1 from "./ecosystem-prefill.service";
var EcosystemCompilerSharedActionsService = /** @class */ (function () {
    function EcosystemCompilerSharedActionsService(prefillService) {
        this.prefillService = prefillService;
        this.prefillObserver = Observable.of({});
        this.runningObserver = [];
        this.onPrefillFinished = new Subject();
    }
    EcosystemCompilerSharedActionsService.prototype._prefillUpdate = function (change) {
        var _this = this;
        return Object.keys(change).map(function (id) {
            if (Number(id) === PREFILL_OFFER_ID) {
                var offer = _this._ecosystem.ecosystem_offer;
                offer.quantity = change[PREFILL_OFFER_ID].in.after;
                return offer.save();
            }
            else {
                var order = _this._ecosystem.transformation_orders.find(function (transformOrder) { return transformOrder.id === Number(id); });
                var observers = [];
                if (change[id].in) {
                    var orderPassport = order.in_order_passport;
                    if (!(order.in_order_passport instanceof OrderPassport)) {
                        orderPassport = new OrderPassport(orderPassport);
                    }
                    orderPassport.quantity = change[id].in.after;
                    observers.push(orderPassport.save());
                }
                if (change[id].out) {
                    var orderPassport = order.out_order_passport;
                    if (!(order.out_order_passport instanceof OrderPassport)) {
                        orderPassport = new OrderPassport(orderPassport);
                    }
                    orderPassport.quantity = change[id].out.after;
                    observers.push(orderPassport.save());
                }
                return Observable.zip.apply(Observable, observers);
            }
        });
    };
    EcosystemCompilerSharedActionsService.prototype.prefillSetDirty = function (change, orders) {
        if (orders === void 0) { orders = []; }
        this.prefillObserver = Observable.concat.apply(Observable, [Observable.of({})].concat(this._prefillUpdate(change), orders.map(function (order) {
            return order.save();
        }))).pipe(count(function () { return true; }));
        return this.prefillObserver;
    };
    EcosystemCompilerSharedActionsService.prototype.beforeCompilerReload = function () {
        var observers = this.prefillObserver;
        if (observers) {
            this.prefillObserver = null;
        }
        return observers || Observable.of({});
    };
    Object.defineProperty(EcosystemCompilerSharedActionsService.prototype, "ecosystem", {
        get: function () {
            return this._ecosystem;
        },
        set: function (ecosystem) {
            this._ecosystem = ecosystem;
        },
        enumerable: true,
        configurable: true
    });
    EcosystemCompilerSharedActionsService.prototype.sameUnitButDifferentConversion = function (order, transform, passport) {
        if (!order || !this.ecosystem) {
            return false;
        }
        var conversion;
        var unit;
        if (transform) {
            unit = transform.input_unit;
            conversion = transform.in_conversion;
        }
        else if (passport) {
            unit = passport.unit;
            conversion = passport.conversion;
        }
        else {
            return false;
        }
        var child;
        if (order instanceof TransformOrder) {
            child = order.child_step;
        }
        else if (order instanceof EcosystemOffer) {
            var needStep_1 = this.ecosystem.steps.find(function (step) { return step.type === ECOSYSTEM_STEP_TYPE.NEED; });
            child = this.ecosystem.steps.find(function (step) { return needStep_1.id === step.parent_id; });
        }
        if (child) {
            var transformOrder = this.ecosystem.transformation_orders.find(function (childOrder) { return childOrder.step_id === child.id; });
            if (transformOrder) {
                return conversion && transformOrder.transformation && transformOrder.transformation.out_conversion
                    && conversion !== transformOrder.transformation.out_conversion
                    && unit === transformOrder.transformation.output_unit;
            }
            var depositOrder = this.ecosystem.deposit_orders.find(function (childOrder) { return childOrder.step_id === child.id; });
            if (depositOrder) {
                return conversion && depositOrder.deposit_passport && depositOrder.deposit_passport.conversion
                    && conversion !== depositOrder.deposit_passport.conversion
                    && unit === depositOrder.deposit_passport.unit;
            }
        }
        return false;
    };
    EcosystemCompilerSharedActionsService.ngInjectableDef = i0.defineInjectable({ factory: function EcosystemCompilerSharedActionsService_Factory() { return new EcosystemCompilerSharedActionsService(i0.inject(i1.EcosystemPrefillService)); }, token: EcosystemCompilerSharedActionsService, providedIn: "root" });
    return EcosystemCompilerSharedActionsService;
}());
export { EcosystemCompilerSharedActionsService };
