import { Component, OnInit } from '@angular/core';
import { User } from '../models/user.class';
import { UserService } from '../services/user.service';
import { CurrentUser } from '../auth/current-user.class';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LogNotificationService } from '../services/log-notification.service';
import { CurrentUserService } from '../services/current-user.service';
import { NavigationService } from '../services/navigation.service';
import { ERoleIds } from '../values/role-ids.enum';
import { ModuleNames } from '../values/module-names.const';

@Component({
  moduleId: module.id,
  templateUrl: 'profile.component.html'
})

export class ProfileComponent implements OnInit {
  user: User;
  passwordForm: FormGroup;

  isAdmin: boolean = false;
  moduleName: string;

  parentModule: string;
  moduleNames = ModuleNames;

  constructor(private _fb: FormBuilder,
              private userService: UserService,
              private router: Router,
              private _currentUserService: CurrentUserService,
              private notification: LogNotificationService,
              private _navigationService: NavigationService,
              ) {
    this.parentModule = this._navigationService.getModuleName();

    this.passwordForm = this._fb.group({
      oldPassword: [''],
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    },{
      validator: this.checkIfMatchingPasswords('password', 'passwordConfirm')
    });

    //Only admin should be able to edit organization name and SIRET
    let context = this._navigationService.getContext();
    this.isAdmin = !!context && (context.roleId === ERoleIds.ADMIN || context.roleId === ERoleIds.SUPERADMIN);

    this.moduleName = this._navigationService.getModuleName();
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  ngOnInit() {
    if (CurrentUser.infoData.id) {
      this.userService.view(CurrentUser.infoData.id, { expand: 'company' }).subscribe((user: User) => {
        this.user = user;
        this.user.loaded = true;
      });
    }
  }

  save() {
    if (!this.passwordForm.controls['passwordConfirm'].hasError('notEquivalent')) {
      this.user.save().subscribe((user: User) => {
        this.router.navigate([this._navigationService.getHomeUrl()]);
        this._currentUserService.currentUserDidChanged.emit('changed');
      });
    } else {
      this.notification.error('Passwords do not match',null, true);
    }
  }

  isAdministratorEntityVisible(): boolean {
    const moduleNameIs = (module: string) => this.moduleName === module;
    return !moduleNameIs(ModuleNames.MANUFACTURER) && !moduleNameIs(ModuleNames.TSMI) && !moduleNameIs(ModuleNames.SPECIAL_ADMIN)
  }
}
