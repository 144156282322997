/**
 * Created by aleksandr on 13.06.17.
 */
import {Component, NgZone} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'ecosystem-catalog',
  templateUrl: 'ecosystem-catalog.component.html'
})
export class EcosystemCatalogComponent {

  activeIndex: number = 0;

  constructor(private _zone: NgZone) { }

  changeTab(index: number) {
    this._zone.run(() => this.activeIndex = index);
  }
}
