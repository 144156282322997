import { Component, NgZone } from '@angular/core';
import { MdDialog } from '@angular/material';
import { EcosystemService } from '../../../../entities/ecosystem/services/ecosystem.service';
import {NeedService} from "../../../../entities/need/services/need.service";
import {DepositService} from "../../../../entities/deposit/services/deposit.service";
import { NomenclatureChangeDialogComponent } from '../../../../entities/nomenclature/dialogs/nomenclature-change-dialog/nomenclature-change-dialog.component';
import {TransformService} from '../../../../entities/transformation/services/transform.service';
@Component({
  moduleId: module.id,
  templateUrl: 'admin-dashboard.component.html',
  styleUrls: ['admin-dashboard.component.scss']
})
export class AdminDashboardComponent {
  activeIndex = 0;
  ecosystemsInProcessCount: number;
  needsNotTreatedCount: number;
  depositsAvailableCount: number;
  transformationsCount: number;


  constructor(private mdDialog: MdDialog,
              private ecosystemService: EcosystemService,
              private needService: NeedService,
              private depositService: DepositService,
              private transformationService: TransformService,
              private _dialog: MdDialog,
              private zone: NgZone,
  ) {
    this.ecosystemService.getProcessCount().subscribe(c => this.ecosystemsInProcessCount = c);
    this.needService.getUntreatedCount().subscribe(c => this.needsNotTreatedCount = c);
    this.transformationService.getCount().subscribe(c => this.transformationsCount = c);
    this.depositService.getAvailableCount().subscribe(c => this.depositsAvailableCount = c);


    console.info('Launch  -> AdminDashboardComponent');
  }

  askForNomenclatureChange() {
    this.mdDialog.open(NomenclatureChangeDialogComponent);
  }
}
