import { BaseModel } from 'ng2-adn-common';
import { INeedStats } from '../interfaces/need-stats.interface';
import { Utils } from '../../../shared/utils/utils.class';

export class NeedStats extends BaseModel implements INeedStats {
  published: number;
  rejected: number;
  done: number;
  refused: number;
  revenue: number;
  in_process: number;
  m: number;
  y: number;

  fields(): string[] {
    return [
      'published',
      'rejected',
      'done',
      'refused',
      'revenue',
      'in_process',
      'm',
      'y'
    ];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    Utils.convertJsonStringToNumber(json, [
      'published',
      'rejected',
      'done',
      'refused',
      'revenue',
      'in_process',
      'm',
      'y'
    ]);
    return json;
  }
}
