import { EventEmitter } from "@angular/core";
var FilterStateOfUseComponent = /** @class */ (function () {
    function FilterStateOfUseComponent() {
        this.color = '';
        this.selectionChanged = new EventEmitter();
        this.isFilterStateOfUseVisible = false;
    }
    FilterStateOfUseComponent.prototype.onClick = function (targetElement) {
        if (!this.filterStateOfUse.nativeElement.contains(targetElement)) {
            this.isFilterStateOfUseVisible = false;
        }
    };
    Object.defineProperty(FilterStateOfUseComponent.prototype, "isAnyStateOfUseFilterApplied", {
        get: function () {
            var _this = this;
            return Object.keys(this.stateOfUse).some(function (key) { return _this.stateOfUse[key]; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterStateOfUseComponent.prototype, "stateOfUseKeys", {
        get: function () {
            return Object.keys(this.stateOfUse);
        },
        enumerable: true,
        configurable: true
    });
    return FilterStateOfUseComponent;
}());
export { FilterStateOfUseComponent };
