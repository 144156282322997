import * as tslib_1 from "tslib";
var MaterialPieCharData = /** @class */ (function () {
    function MaterialPieCharData() {
        this.sortByValueDesc = function (object) { return Object.entries(object)
            .sort(function (_a, _b) {
            var v1 = _a[1];
            var v2 = _b[1];
            return +v1 - +v2;
        })
            .reduce(function (r, _a) {
            var k = _a[0], v = _a[1];
            var _b;
            return (tslib_1.__assign({}, r, (_b = {}, _b[k] = v, _b)));
        }, {}); };
    }
    MaterialPieCharData.prototype.getCharData = function (product, mainData) {
        if (product) {
            return { controlled: product.percentage_of_controlled_toxicity,
                verify: product.percentage_of_controlled_verified_toxicity,
                known: product.percentage_of_known_toxicity,
                unknown: product.percentage_of_unknown_toxicity,
                healthy: product.percentage_of_healthy_product };
        }
        else if (mainData) {
            return { controlled: mainData.percentage_weight_of_controlled_toxicity,
                verify: mainData.percentage_weight_of_controlled_verified_toxicity,
                known: mainData.percentage_weight_of_known_toxicity,
                unknown: mainData.percentage_weight_of_unknown_toxicity,
                healthy: mainData.percentage_weight_of_healthy_product };
        }
        return null;
    };
    MaterialPieCharData.prototype.getRoundPieChartData = function (data) {
        var product = data.product;
        var mainData = data.mainData;
        var pieCharData = this.getCharData(product, mainData);
        return pieCharData ? {
            data: this.sortByValueDesc({
                percentage_weight_of_controlled_toxicity: Math.round(pieCharData.controlled * 10) / 10,
                percentage_weight_of_controlled_verified_toxicity: Math.round(pieCharData.verify * 10) / 10,
                percentage_weight_of_unknown_toxicity: Math.round(pieCharData.unknown * 10) / 10,
                percentage_weight_of_known_toxicity: Math.round(pieCharData.known * 10) / 10,
                percentage_weight_of_healthy_product: Math.round(pieCharData.healthy * 10) / 10
            }),
            category: product ? {
                name: product.category.name,
                percentTotalWeight: product.category_mass_percentage,
                weight: product.weight
            } : undefined
        }
            : null;
    };
    return MaterialPieCharData;
}());
export { MaterialPieCharData };
