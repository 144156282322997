<div class="custom-search" [ngClass]="{'custom-search--focus': isFocus}" style="position:relative;">
  <div class="custom-search__field ib-row--justify">
    <md-input-container class="custom-search__input">
      <input class="site-name"
        [autocomplete]="autoComplete"
        mdInput
         #nameInput
        [formControl]="control"
        [disabled]="disabled"
        placeholder="{{ caption | translate }}"
        (blur)="onBlur()"
        (focus)="onFocus()"
        name="password"
      >
    </md-input-container>
    <div class="custom-search__counter">{{records.length}}</div>
  </div>
  <div class="custom-search__overlay">
    <ul class="custom-search__results">
      <li class="custom-search__item"
          *ngFor="let record of records"
          (click)="selectItem(record)">
         {{ getName(record)}}
      </li>
    </ul>
    <ul class="custom-search__footer button-group__list">
      <li class="button-group__item button-group__item--short" *ngIf="isCreateNewButtonShown">
        <button md-button md-raised-button 
        [disabled]="!isUnique()"
        (click)="onCreate?.emit(selected.name)">
          {{label}}
        </button>
      </li>
      <li class="button-group__item button-group__item--short" *ngIf="hasAdvancedResearch">
        <button md-button md-raised-button (click)="onOpenAdvancedResearch.emit()">
          {{'Advanced search' | translate | uppercase}}
        </button>
      </li>
    </ul>
  </div>
</div>
