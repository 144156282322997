import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { IStateOfUse } from "../../../../../shared/interfaces/filter-states.interface";

@Component({
  moduleId: module.id,
  selector: 'filter-state-of-use',
  templateUrl: 'filter-state-of-use.component.html',
})
export class FilterStateOfUseComponent {
  @Input() stateOfUse: IStateOfUse;
  @Input() color = '';
  @Output() selectionChanged = new EventEmitter();


  isFilterStateOfUseVisible = false;

  @ViewChild('filterStateOfUse') filterStateOfUse;
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this.filterStateOfUse.nativeElement.contains(targetElement)) {
      this.isFilterStateOfUseVisible = false;
    }
  }

  get isAnyStateOfUseFilterApplied() {
    return Object.keys(this.stateOfUse).some(key => this.stateOfUse[key]);
  }

  get stateOfUseKeys() {
    return Object.keys(this.stateOfUse);
  }

}