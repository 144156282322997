import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { UsedEnergyService } from '../services/used-energy.service';
import { EEnergyGroupType, USED_ENERGY_GROUPS } from '../values/used-energy-values.const';
var UsedEnergy = /** @class */ (function (_super) {
    tslib_1.__extends(UsedEnergy, _super);
    function UsedEnergy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = UsedEnergyService;
        return _this;
    }
    UsedEnergy.prototype.isRenewable = function () {
        var _this = this;
        var renewableEnergyGroup = USED_ENERGY_GROUPS.find(function (group) { return group.type === EEnergyGroupType.RENEWABLE; });
        return renewableEnergyGroup.energy.value === this.energy_id
            || !!renewableEnergyGroup.energies.find(function (i) { return i.value === _this.energy_id; });
    };
    UsedEnergy.prototype.fields = function () {
        return ['id', 'energy_id', 'percentage', 'passport_id', 'optimization_strategy'];
    };
    return UsedEnergy;
}(ActiveRecord));
export { UsedEnergy };
