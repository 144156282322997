import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Deposit } from '../../models/deposit.class';
import { DepositService } from '../../services/deposit.service';
import { MdDialogRef } from '@angular/material';
import { DuplicateListRecord } from '../../models/duplicate-list-record.interface';

@Component({
  moduleId: module.id,
  selector: 'duplicate-list',
  templateUrl: 'duplicate-list.component.html',
  styleUrls: ['duplicate-list.component.scss'],
})
/**
 * Remove this
 * @deprecated
 */
export class DuplicateListDialogComponent implements OnInit, OnDestroy {
  @Output() onSelect?: EventEmitter<any> = new EventEmitter();

  public records: Deposit[] = [];
  public nameSearchTerm: string = '';
  public searchResults: DuplicateListRecord[];

  public currentpage: number;
  public count: number;
  public perpage: number;
  public totalcount: number;


  constructor(private service: DepositService,
              public dialog: MdDialogRef<any>) {
  }

  ngOnInit() {
    this.search();
  }

  ngOnDestroy() {

  }

  pageChanged(page: number) {
    this.search([
      ['page', page]
    ]);
  }

  search(options: any = []) {
    options.push(
      ['expand', 'material_names, passport_components, image'],
    );

    if (this.nameSearchTerm) {
      options.push(['search', this.nameSearchTerm]);
    }

    this.service.getWithArray(options).subscribe((res) => {
      let headers = res.headers;

      this.currentpage = Number(headers.get('X-Pagination-Current-Page'));
      this.count = Number(headers.get('X-Pagination-Page-Count'));
      this.perpage = Number(headers.get('X-Pagination-Per-Page'));
      this.totalcount = Number(headers.get('X-Pagination-Total-Count'));
      this.records = res.json().map((item: any) => {
        let record: DuplicateListRecord = {
          image: item.image,
          passport_components: item.passport_components,
          material_names: item.material_names,
          deposit: new Deposit(item),
        };

        return record;
      });
    });
  }

  select(deposit: Deposit) {
    this.dialog.close(deposit);
  }

}
