import { Injectable } from '@angular/core';
import { EcosystemService } from './ecosystem.service';
import { ECOSYSTEM_STATUS, ECOSYSTEM_STATUS_FILTER_VIEW } from '../../../dashboards/ecosystem-manager/values/ecosystem-status.const';
import { first } from 'rxjs/operators'

@Injectable()
export class EcosystemFolderService {

    public currentFolder: any = {
        name: "",
        parentId: null
    };

    public defaultFolder: any = {
        name: "",
        parentId: null
    };
    public allFolders : Array<any>;

    public newFolderName = "";
    public selection : Array<any> = [];
    public folderSelection : Array<any> = [];

    public folderViewResults = {
        ecosystems: [],
        folders: []
    }

    public flatViewResults = {
        ecosystems: [],
        folders: []
    }

    public isRoot = true;

    public defaultFilter = {
        search: '',
        deliveryDateFrom: null,
        deliveryDateTo: null,
        clientId: null,
        sortValue: 'last_update',
        folders: [],
        statuses : [
            {value: ECOSYSTEM_STATUS.DRAFT, selected: true, view : ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.DRAFT] },
            {value: ECOSYSTEM_STATUS.CREATED, selected: true, view : ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.CREATED] },
            {value: ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, selected: true, view :ECOSYSTEM_STATUS_FILTER_VIEW[ ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION]  },
            {value: ECOSYSTEM_STATUS.IN_PROCESS, selected: true, view : ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.IN_PROCESS]  },
            {value: ECOSYSTEM_STATUS.DONE, selected: false, view : ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.DONE]  },
            {value: ECOSYSTEM_STATUS.REFUSED, selected: false, view :ECOSYSTEM_STATUS_FILTER_VIEW[ ECOSYSTEM_STATUS.REFUSED]  } 
          ]
    }

    public filter = {
        search: '',
        deliveryDateFrom: null,
        deliveryDateTo: null,
        clientId: null,
        sortValue: 'last_update',
        folders: [],
        statuses : [
            {value: ECOSYSTEM_STATUS.DRAFT, selected: true, view : ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.DRAFT] },
            {value: ECOSYSTEM_STATUS.CREATED, selected: true, view : ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.CREATED] },
            {value: ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, selected: true, view :ECOSYSTEM_STATUS_FILTER_VIEW[ ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION]  },
            {value: ECOSYSTEM_STATUS.IN_PROCESS, selected: true, view : ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.IN_PROCESS]  },
            {value: ECOSYSTEM_STATUS.DONE, selected: false, view : ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.DONE]  },
            {value: ECOSYSTEM_STATUS.REFUSED, selected: false, view :ECOSYSTEM_STATUS_FILTER_VIEW[ ECOSYSTEM_STATUS.REFUSED]  } 
          ]
    }

    public defaultStatuses = {
        [ECOSYSTEM_STATUS.DRAFT]: true,
        [ECOSYSTEM_STATUS.CREATED]: true,
        [ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION]: true,
        [ECOSYSTEM_STATUS.IN_PROCESS]: true,
        [ECOSYSTEM_STATUS.DONE]: false,
        [ECOSYSTEM_STATUS.REFUSED]: false,
    }

    public folderMode = true;
    public insideFolder = false;
    public allSelected = false;
    public isLoading = false;
    constructor(public ecosystemService: EcosystemService ) {

    }

    enterFolder(folder) {
        this.isRoot = false;
        this.filter.folders = [folder];
        this.currentFolder = {...folder}
        this.newFolderName = folder.name;

        this.search();
    }

    leaveFolder() {
        this.isRoot = true;
        this.filter.folders = [];
        this.search();
    }

    search() {
        this.isDefaultFilter() && this.folderMode ?
        this.getEcosystemFolderRoot() :
        this.searchEcosystems() ;
    }

    getFolders() {
        this.ecosystemService.getEcosystemFolderRoot()
        .subscribe((data: any) => {

            this.allFolders = data.folders;
            
          });
    }

    getEcosystemFolderRoot() {
        const query = this.buildParams();
        this.ecosystemService.getEcosystemFolderRoot({search: query})
        .subscribe((data: any) => {
            this.resetSelection();
            this.isRoot = true;
            this.folderViewResults.ecosystems = data.ecosystems.map(( es )=> this.prepareEcosystemForDisplay(es));
            this.folderViewResults.folders = data.folders.map((f) => this.prepareFolderForDisplay(f));
            this.currentFolder = this.defaultFolder;
            this.newFolderName = this.defaultFolder.name;
            
            this.allFolders = data.folders;
            
          });
    }

    searchEcosystems() {
        this.isLoading = true;
        const query = this.buildParams();
        this
        .ecosystemService
        .get(query)
        .pipe(first())
        .subscribe((res: any) => {
            console.log(res)
            // this.isRoot = true;
            const {folders, ecosystems } = this.prepareSearchForDisplay(res);
            this.folderViewResults.ecosystems = ecosystems.map(( es )=> this.prepareEcosystemForDisplay(es));
            this.folderViewResults.folders = folders.map((f) => this.prepareFolderForDisplay(f));
            // this.currentFolder = this.defaultFolder;
            this.isLoading = false;
            
        })

    }
    

    toggleFolderMode(e) {
        this.folderMode = e;
        this.search();
    }

    buildParams(nested?) {

        let params = 'ecosystems,ecosystems.folder,folder,'
        
        let statuses: number[] = this.filter.statuses.filter(item => {
            // if (item.value === ECOSYSTEM_STATUS.DONE){
            //   this.filter.statuses.filterList.statusDone = item.selected;
            // }
            return item.selected;
           }).map(item => item.value);
          if(!!statuses.find(value => value === ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION)) {
            statuses.push(ECOSYSTEM_STATUS.WAITING_FOR_QUOTE, ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION);
          }

        const expandedArr = ["need","steps","steps_complete","steps_total","client_name","publication_date","need_id","ecosystem_offer"]
        const expand = expandedArr.reduce((acc, curr) => {
            return `${acc},${nested? 'ecosystems.':''}${curr}`
        }, params);
        return {
            expand,
            admin: 1,
            esm_view: 1,
            delivery_from: this.filter.deliveryDateFrom || null,
            delivery_to: this.filter.deliveryDateTo || null,
            client: this.filter.clientId || null,
            search: this.filter.search || null,
            'status[]': statuses,
            sort: this.filter.sortValue,
            'folder_id[]': this.filter.folders.map((f) =>f.id) || null
          };
    }
    
    public get ecosystems() {
        return this.folderViewResults.ecosystems;
    }

    public get folders() {
        return this.folderViewResults.folders;
    }

    toggleStatus(v) {
        const index = this.filter.statuses.findIndex((a) => a.value === v );
        if (index >= 0) {
            this.filter.statuses[index].selected = !this.filter.statuses[index].selected;
        }
    }

    addToSelection(ecosystemId, parentId) {
        const found = !!this.selection.find((e) => e.ecosystemId === ecosystemId );
        !found && this.selection.push({ ecosystemId, parentId});
    }

    selectFolder(folderId) {
        const found = !!this.folderSelection.find((f) => f === folderId );
        return !found && this.folderSelection.push(folderId);
    }

    unSelectFolder(folderId) {
        const index = this.folderSelection.findIndex((f) => f === folderId);
        if (index < 0) {
            return;
        }
        this.folderSelection = [
            ...this.folderSelection.slice(0, index),
            ...this.folderSelection.slice(index+1),
          ];
    }

    removeFromSelection(ecosystemId) {
        const index = this.selection.findIndex((a) => a.ecosystemId === ecosystemId);
        if (index < 0) {
            return;
        }
        this.selection = [
            ...this.selection.slice(0, index),
            ...this.selection.slice(index+1),
          ];
    }

    addFolderToSelection(folderId) {
        this.selectFolder(folderId);
        this.ecosystemService.getEcosystemFolderById(folderId, {search : { expand: 'ecosystems'} })
        .pipe(first())
        .subscribe((data: any) => {
            data.ecosystems.forEach(({ id }) => {
                this.addToSelection(id, folderId);
            })
        })
         
    }

    removeFolderFromSelection(folderId) {
       this.unSelectFolder(folderId);
       this.selection = this.selection.filter(( { ecosytemId, parentId} ) => parentId !== folderId) ;
    }

    resetSelection() {
        this.selection = [];
        this.folderSelection = [];
    }

    prepareEcosystemForDisplay(ecosystem) {
        return {
            ...ecosystem,
            checked: !!this.selection.find(({ ecosystemId }) => ecosystemId === ecosystem.id)
        }
    }

    prepareFolderForDisplay(folder) {
        const selectedCount = this.selection.reduce((acc, { parentId, ecosystemId}) => (acc + (parentId === folder.id ? 1 : 0)), 0);
        return {
            ...folder,
            es_count_display: (folder.es_count_search && (folder.es_count_search !== folder.es_count)) ? `${folder.es_count_search} / ${folder.es_count}` : 
            ( Number(folder.es_count) ? `${folder.root_count} / ${folder.es_count}` : folder.es_count ),
            checked: ( ( folder.es_count !== 0 ) && ( selectedCount === folder.es_count ) ) || !!this.folderSelection.find((f) => f === folder.id)
        }
    }

    createFolder(name) {
        return this.ecosystemService.createFolder({ name }).pipe(first());
        
    }

    updateFolder(id, name) {
        return this.ecosystemService.updateFolder(id, { name }).pipe(first());
    }

    moveSingleEcosystem(id, folderId) {
        return this.ecosystemService.moveFolder({ecosystem_ids : [id], move_to: folderId }).pipe(first());
    }

    moveFolder(folderid, isRoot) {
        return this.ecosystemService.moveFolder({ ecosystem_ids : this.selection.map(({ ecosystemId }) => ecosystemId), move_to: folderid, is_root: isRoot }).pipe(first());
    }

    deleteFolder() {
        return this.ecosystemService.deleteFolder(
            {
                folder_ids: this.folderSelection,
                ecosystem_ids: this.selection.map(({ ecosystemId }) => ecosystemId)
            }
        ).pipe(first())
    }

    canMoveSelectionToRoot() {
        return !!this.selection.find((v) => v.parentId !== null);
    }

    prepareSearchForDisplay(res) {
        const shouldDisplayFolder = this.folderMode && this.isRoot ;

        return shouldDisplayFolder ?
        res.reduce((acc, curr) => {
            let newFolders = [...acc.folders];
            const newEcosystems = [...acc.ecosystems]
            if (curr.folder ) {

                const foundIndex = acc.folders.findIndex((f => f.id === curr.folder.id ));
                

                if (foundIndex < 0) {
                    const newFolder = {
                        ...curr.folder,
                        es_count_search: 1
                    }
                    newFolders.push(newFolder);
                    
                } else {
                    const newFolder = { ...acc.folders[foundIndex] };
                    newFolder.es_count_search++;
                    newFolders = [
                        ...newFolders.slice(0, foundIndex),
                        newFolder,
                        ...newFolders.slice(foundIndex+1)
                    ]
                }


                
                
            } else {
                newEcosystems.push(curr)
            }

            return {
                folders: newFolders,
                ecosystems: newEcosystems

            }
        }, {folders: [], ecosystems: []})

        :
        {
            folders: [],
            ecosystems: res
        }
    }
    resetFilter() {

        this.filter.search= this.defaultFilter.search;
        this.filter.deliveryDateFrom= this.defaultFilter.deliveryDateFrom;
        this.filter.deliveryDateTo= this.defaultFilter.deliveryDateTo;
        this.filter.clientId= this.defaultFilter.clientId;
        this.filter.sortValue= this.defaultFilter.sortValue;
        this.filter.folders= [...this.defaultFilter.folders];
        this.filter.statuses.forEach((item) => {
            item.selected = this.defaultStatuses[item.value]
        })

        if(this.isRoot) {
            this.search();
        }
        
    }
    isDefaultFilter() {
        const f =  this.filter.search === this.defaultFilter.search &&
        this.filter.deliveryDateFrom === this.defaultFilter.deliveryDateTo && 
        this.filter.sortValue === this.defaultFilter.sortValue && 
        this.isStatusesSameAsDefault() &&
        this.filter.clientId === this.defaultFilter.clientId &&
        this.filter.folders.length === 0;
        return f;
    }

    isStatusesSameAsDefault() {
        return this.filter.statuses.reduce((acc, curr) => {
          return acc && curr.selected === this.defaultStatuses[curr.value];
        }, true)
    }
    toggleAll() {
        this.allSelected ? this.selectAll() : this.unSelectAll() ;
    }
    selectAll() {
        this.folderViewResults.ecosystems.forEach((e) => {
            const folderId = e.folder && e.folder.id;
            this.addToSelection(e.id, folderId);
        })
        this.folderViewResults.folders.forEach((f) => {
            this.addFolderToSelection(f.id);
        })
        this.folderViewResults.ecosystems = this.folderViewResults.ecosystems.map(( es )=> this.prepareEcosystemForDisplay(es));
        this.folderViewResults.folders = this.folderViewResults.folders.map((f) => this.prepareFolderForDisplay(f));
    }

    unSelectAll() {
        this.resetSelection();
        this.folderViewResults.ecosystems = this.folderViewResults.ecosystems.map(( es )=> this.prepareEcosystemForDisplay(es));
        this.folderViewResults.folders = this.folderViewResults.folders.map((f) => this.prepareFolderForDisplay(f));

    }
}