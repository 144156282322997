/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * Created by aleksandr on 30.06.17.

 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./chat-history/chat-history.component.ngfactory";
import * as i4 from "./chat-history/chat-history.component";
import * as i5 from "@angular/common";
import * as i6 from "ng2-file-upload/file-upload/file-select.directive";
import * as i7 from "./chat-form.component";
import * as i8 from "../../chat.service";
import * as i9 from "../../../services/current-user.service";
var styles_ChatFormComponent = [];
var RenderType_ChatFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChatFormComponent, data: {} });
export { RenderType_ChatFormComponent as RenderType_ChatFormComponent };
function View_ChatFormComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "chat__button--attachment"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadFiles() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "icon icon--attachment"]], null, null, null, null, null))], null, null); }
function View_ChatFormComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "textarea", [["class", "chat__new-message"], ["cols", "30"], ["id", ""], ["name", ""], ["rows", "4"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keydown.enter"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.messageText = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.enter" === en)) {
        var pd_5 = (_co.sendMessage($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(3, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_8 = ""; var currVal_9 = _co.messageText; _ck(_v, 3, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 6).transform("Type your message here")); var currVal_1 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 5).ngClassValid; var currVal_6 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_ChatFormComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "restrict_content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "restrict_icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "icon"], ["src", "assets/png/!.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "restrict_msg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.message)); _ck(_v, 5, 0, currVal_0); }); }
function View_ChatFormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "article", [["class", "chat"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "section", [["class", "chat__wrapper"], ["id", "messageList"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "chat-history", [], null, null, null, i3.View_ChatHistoryComponent_0, i3.RenderType_ChatHistoryComponent)), i0.ɵdid(3, 49152, null, 0, i4.ChatHistoryComponent, [], { history: [0, "history"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 6, "footer", [["class", "chat__form"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_3)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_4)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chatRoom.messages; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.uploader && !_co.isChatRestricted); _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.isChatRestricted; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isChatRestricted; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_ChatFormComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { fileInput: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["fileInput", 1]], null, 1, "input", [["ng2FileSelect", ""], ["style", "display: none;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i6.FileSelectDirective, [i0.ElementRef], { uploader: [0, "uploader"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chatRoom; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.uploader; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ChatFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "chat-form", [], null, null, null, View_ChatFormComponent_0, RenderType_ChatFormComponent)), i0.ɵdid(1, 245760, null, 0, i7.ChatFormComponent, [i8.ChatService, i9.CurrentUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatFormComponentNgFactory = i0.ɵccf("chat-form", i7.ChatFormComponent, View_ChatFormComponent_Host_0, { roomId: "roomId", room: "room", isChatForTransformIndep: "isChatForTransformIndep", isChatForNeedIndep: "isChatForNeedIndep" }, {}, []);
export { ChatFormComponentNgFactory as ChatFormComponentNgFactory };
