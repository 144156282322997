import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConversionStateService } from '../../services/conversionState.service';
import { WeightAndVolumeConversionService } from '../../services/weightAndVolumeConversion.service';
import { PASSPORT_UNITS_MAP, PASSPORT_UNITS_TYPE } from '../../../entities/passport/values/passport-units.const';
import lodash from 'lodash';
import {IConversion} from '../../interfaces/conversion-state.interface';


@Component({
  moduleId: module.id,
  selector: 'conversion-field',
  templateUrl: 'conversion-field.component.html',
  styleUrls: ['conversion-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ConversionFieldComponent implements OnInit {
PASSPORT_UNIT_MAP = PASSPORT_UNITS_MAP;
@Input() identifier: string;
@Input() placeholder: string;
@Input() volumeUnit: number;
@Input() conversionFactor: number;
@Input() isDisabled: boolean;
@Input() isConversionDisabled: boolean;
@Input() setToggleColorActive: boolean;
@Input() setDefaultConversionOff: boolean;
@Input() emptyQuantityHolder = 'unlimited';
@Input() defaultUnit = PASSPORT_UNITS_TYPE.KG;
@Input() isConvertOnWriteIfOffConversion = false;
@Output() quantityChanged = new EventEmitter<{value: number, isActive: boolean}>();
@Output() quantityInTonsChanged: any = new EventEmitter<any>();
public disableConversion: boolean;
public volumeUnitText: string;
quantity: any;
quantityInTons: any;
quantityInView: string;
private stateOfFiled: IConversion;
@Input ('quantity') set setQuantity(quantity: any) {
  if (this.quantity !== quantity) {
    this.quantity = quantity;
    this.transformQuantityToView();
    if (this.isWeightUnchangeable() && !this.prohibitInitialConvert()) {
      this.quantityInTons = this.conversionService.conversionToWeighUnits(this.conversionFactor, quantity);
    }
  }
}

  @Input('quantityInTonsOnInit') set setWeightOnInit(weight) {
    if (!this.stateOfFiled) {
    this.setInputWeightIfValid(weight);
    }
  }
 @Input('quantityInTons') set setWeight(weight) {
     weight = this.setInputWeightIfValid(weight);
     if (!this.prohibitInitialConvert() && !this.disableConversion && weight !== null) {
       this.assignQuantityToTons(weight, false);
     }
 }
   constructor(
       public translate: TranslateService,
       public conversionService: WeightAndVolumeConversionService,
       public conversionStateService: ConversionStateService
   ) {}
   private setInputWeightIfValid(weight) {
     weight = this.transformToValidNumeric(weight);
     if (this.quantityInTons !== weight && !this.isDefaultUnits()) {
       this.quantityInTons = weight;
       return weight;
     }
     return null;
   }

  ngOnInit() {
    this.stateOfFiled = this.conversionStateService.getOrCreateConversionState(this.identifier, this.setDefaultConversionOff);
    this.disableConversion = !this.stateOfFiled.active;
    if (!this.isDefaultUnits() && !this.prohibitInitialConvert(this.disableConversion)) {
      this.quantityInTons = this.conversionService.conversionToWeighUnits(this.conversionFactor, this.quantity);
    }
    this.transformQuantityToView();
    if (this.isDisabled && !this.quantity) {
      this.quantityInView =  !!this.emptyQuantityHolder ? this.translate.instant(this.emptyQuantityHolder) : this.quantityInView;
      this.quantityInTons =  !!this.emptyQuantityHolder ? this.translate.instant(this.emptyQuantityHolder) : this.quantityInView;
    }
    this.volumeUnit = this.volumeUnit || this.defaultUnit;
    this.volumeUnitText = this.PASSPORT_UNIT_MAP[this.volumeUnit];
  }

  private prohibitInitialConvert(initState = false) {
      return !this.isDisabled && (!this.stateOfFiled || initState) && !this.isConvertOnWriteIfOffConversion;
  }

  onWeightChange(text: string) {
    const weightInTons = this.transformToValidNumeric(text);
    if (lodash.isFinite(Number(weightInTons.replace(',', '.')))) {
      this.quantityInTons = weightInTons;
      this.assignWeightIfValid(this.quantityInTons);
      if (!this.disableConversion) {
        this.assignQuantityToTons(weightInTons);
      }
    }
  }

  private assignWeightIfValid(text) {
    const number = this.castToNumber(text);
    if (this.isValidNumber(number)) {
      this.quantityChanged.emit({value: number, isActive: !this.disableConversion});
    }
  }

  onQuantityChange(quantity: string) {
    quantity = this.transformToValidNumeric(quantity);
    this.quantityInView = quantity;
    this.changeTonsOnly(quantity);
    this.assignQuantityIfValid(quantity);
  }

  private changeTonsOnly(quantity = this.quantity, isChangedForced = false) {
    if (this.isWeightUnchangeable() || isChangedForced) {
      this.quantityInTons = this.conversionService.conversionToWeighUnits(this.conversionFactor, quantity);
      this.assignWeightIfValid(this.quantityInTons);
    }
  }

  private isWeightUnchangeable() {
    return !this.disableConversion && !this.isDefaultUnits();
  }

  private assignQuantityToTons(weightInTons = this.quantityInTons, emit  = true) {
    if (this.assignQuantityIfValid(this.conversionService.conversionToVolumeUnits(this.conversionFactor, weightInTons), emit)) {
      this.transformQuantityToView();
    }
  }

  private transformQuantityToView() {
    const isFixedRequired =
      this.quantity && this.quantity.toString().length > this.quantity.toFixed(this.conversionService.NUMBERS_AFTER_COMMA).length;
    this.quantityInView = typeof this.quantity === 'number'
      ? (isFixedRequired ? this.quantity.toFixed(this.conversionService.NUMBERS_AFTER_COMMA) : this.quantity.toString()) : '';
  }

  private assignQuantityIfValid(text, emit  = true) {
    const number = this.castToNumber(text);
    if (this.isValidNumber(number)) {
      this.quantity = number;
      if (emit) {
        this.quantityInTonsChanged.emit(number);
      }
      return true;
    }
    return false;
  }

  private castToNumber(text: any) {
    return (!!text || text === 0) ? this.conversionService.transformToNumber(text) : null;
  }

  private isValidNumber(number) {
    return!Number.isNaN(number) || number === null;
  }

  activateToggleConversion(isActive: boolean) {
    if (isActive) {
      this.onConvertButton();
    }
    this.disableConversion = !isActive;
    this.assignWeightIfValid(this.quantityInTons);
  }

  private onConvertButton() {
    if (this.quantityInView) {
      this.changeTonsOnly(this.quantity, true);
    } else {
      this.assignQuantityToTons();
    }
  }

  isDefaultUnits() {
    if (this.volumeUnit === PASSPORT_UNITS_TYPE.KG || this.volumeUnit === PASSPORT_UNITS_TYPE.TONNE) {
      return true;
    }
  }

  transformToValidNumeric(number) {
    const removeNotPositiveNumber = (num: string) => num.replace(/[^0-9.,]/g, '');
    const removeSecondCommaAndEverythingAfterIt = (num: string) => num.replace(/([.,].*?)[.,].*/, '$1');
    const removeLeadingZerosExpectFirstOne = (num: string) => num.replace(/^0*0/, '0');
    const chainFunctions = (data, functions: Function[]) => {
      functions.forEach(f => {
        data = f(data);
      });
      return data;
    };
    number = typeof number === 'number' ?  number.toString() : number;
    return  number && chainFunctions(number, [
      removeNotPositiveNumber
      , removeSecondCommaAndEverythingAfterIt
      , removeLeadingZerosExpectFirstOne]);
  }

  preventInputUI(e: Event, quantity) {
    let start = ((e as any).target as HTMLInputElement).selectionStart;
    let end = ((e as any).target as HTMLInputElement).selectionEnd;
    const value = ((e as any).target as HTMLInputElement).value;
    if (quantity && value !== quantity) {
      start  = start - (value.length - String(quantity).length);
      end  = end - (value.length - String(quantity).length);
    }
    ((e as any).target as HTMLInputElement).value = quantity;
    ((e as any).target as HTMLInputElement).selectionStart = start;
    ((e as any).target as HTMLInputElement).selectionEnd = end;
    e.preventDefault();
    e.stopPropagation();
  }
}
