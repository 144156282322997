import { NgModule } from '@angular/core';
import { StakeholderService } from './services/stakeholder.service';
import { StakeholderDetailsComponent } from './partials/stakeholder-details/stakeholder-details.component';
import { StakeholderDetailsDialogComponent } from './dialogs/stakeholder-details-dialog/stakeholder-details-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    SharedModule,
    CommonModule
  ],
  declarations: [
    StakeholderDetailsComponent,
    StakeholderDetailsDialogComponent,
  ],
  exports: [
    StakeholderDetailsDialogComponent,
  ],
  entryComponents: [
    StakeholderDetailsDialogComponent,
  ],
  providers: [
    StakeholderService,
  ],
})
export class StakeholderModule {
}
