<div class="esm-catalog">
  <header class="esm-catalog__header checkbox header--title-group">
    <h3 class="esm-catalog__inline-title">{{'Material transformation' | translate}}</h3>
    <div *ngFor="let status of filterStatuses">
      <md-checkbox class="esm-catalog__group--checkbox checkbox-group"
                   [name]="status.text"
                   (change)="toggleState(status.value);"
                   checked="true">
        {{status.view | capitalize | translate}}
      </md-checkbox>
    </div>
  </header>
  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input mdInput test-id="search"
                 autocomplete="off" 
                 placeholder="{{'Search' | translate}}"
                 [formControl]="search"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select search-long">
        <i class="icon icon--people-grey"></i>
        <md-select class="select-group"
                   test-id="transformation-type-selector"
                   placeholder="{{'Type of transformation' | translate}}"
                   [(ngModel)]="transformType"
                   (change)="getRecords()"
        >
          <md-option>{{'Any' | translate}}</md-option>
          <md-option *ngFor="let type of TRANSFORM_TYPES" [value]="type.value">{{type.view | capitalize | translate}}</md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select search-long">
        <i class="icon icon--people-grey"></i>
        <md-select class="select-group"
                   test-id="tsm-selector"
                   placeholder="{{'Transformation Manager' | translate}}"
                   [(ngModel)]="tsmId"
                   (change)="getRecords()"
        >
          <md-option>{{'Any' | translate}}</md-option>
          <md-option *ngFor="let tsm of tsms" [value]="tsm.id">{{tsm.name}}</md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select search-long">
        <i class="icon icon--gray-sort"></i>
        <md-select class="select-group"
                   test-id="sort-selector"
                   placeholder="{{'Sort By' | translate}}"
                   [(ngModel)]="sortValue"
                   (change)="getRecords()"
        >
          <md-option *ngFor="let sortOption of sortOptions"
                     [value]="sortOption.field">
            {{ sortOption.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
  ></custom-list>
</div>
