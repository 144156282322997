import { ActivatedRouteSnapshot, Resolve, RouteReuseStrategy, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Need } from '../models/need.class';
import { Observable } from 'rxjs';
import { TabState} from '../partials/need-list/need-list.const'

@Injectable()
export class NeedListResolver implements Resolve<Number> {
    resolve(route: ActivatedRouteSnapshot): Observable<Number> {
       const allowedValues = Object.values(TabState);
       const anchor = route.fragment;
       const choosenState = allowedValues.find(value => value.text === anchor);
       return anchor &&  choosenState
       ? Observable.of(choosenState.index) : Observable.of(TabState.CREATION.index);
    }
}