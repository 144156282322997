import { Injectable } from '@angular/core';
import { TRANSFORM_CONVERNED_FOR_CALCULATIONS, TRANSFORM_TYPE } from '../../transformation-manager/values/transform-types.const';
import { PASSPORT_UNITS_TYPE } from '../../../entities/passport/values/passport-units.const';
import { EcosystemStep } from '../models/ecosystem-step.class';
import { ERROR_TYPE_NO_CONFIRMED_QUANTITY_DEPOSIT
  , ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_NEED
  , ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_VIRGIN_PRODUCT
  , ERROR_TYPE_NO_CONFIRMED_QUANTITY_TRANSFORMATION } from '../values/ecosystem-calc-error.const';
import { ECOSYSTEM_STEP_TYPE } from '../values/ecosystem-step-type.const';

@Injectable()
export class  EcosystemIndicatorCalculator {

    recalculate(steps: EcosystemStep[], summary: SummaryStep[]) {
        this.recalculateCo2(steps, summary);
        this.recalculateWaterConsumption(steps, summary);
        return steps;
    }

    private recalculateCo2(steps: EcosystemStep[], summary: SummaryStep[]) {
        steps.forEach(step => {
            const replace = summary.find(element => element.step_id === step.id);
            this.recalculateCO2Step(step, replace);
            if (this.isTransformConcerned(step.transformation_type)) {
                if (step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
                    step.summary_details.orders = step.summary_details.orders.map(order => {
                        const replace_order = replace.orders.find(el => el.order_id === order.id);
                        return this.recalculateCO2Order(order, replace_order, step);
                    });
                }
            }
        });
    }

      private recalculateCO2Step(step, replace) {
        const stepData = step.summary_details.step;
        const confirmedQuantity = replace.confirmed_quantity;
        const validate = (error) =>  {
            return this.addRemoveError(stepData.errors, error, confirmedQuantity); };
        if (step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT ) {
            stepData.errors = validate(ERROR_TYPE_NO_CONFIRMED_QUANTITY_DEPOSIT);
            stepData.co2_emission = -(stepData.carbon_end / stepData.carbon_conversion )
                * confirmedQuantity * stepData.conversion ;
        }
        if (step.type === ECOSYSTEM_STEP_TYPE.NEED ) {
            stepData.errors = validate(ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_NEED);
            stepData.co2_emission = -(stepData.carbon_product / stepData.carbon_conversion )
                * confirmedQuantity * stepData.conversion ;
        }
        if (step.type === ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT ) {
            stepData.errors = validate(ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_VIRGIN_PRODUCT);
            stepData.co2_emission = (stepData.carbon_product / stepData.carbon_conversion )
                * confirmedQuantity * stepData.conversion ;
        }
        return step;
      }

      private isTransformConcerned(type) {
        return TRANSFORM_CONVERNED_FOR_CALCULATIONS.includes(type);
      }

      private recalculateCO2Order(order, replace_order, step) {
            order.errors = this.addRemoveError(order.errors
                , ERROR_TYPE_NO_CONFIRMED_QUANTITY_TRANSFORMATION, replace_order.confirmed_quantity);
            if (step.transformation_type === TRANSFORM_TYPE.TRANSPORT) {
              order = this.recalculateCO2OrderTransport(order, replace_order);
            } else {
                order = this.recalculateCO2Transform(order, replace_order);
            }
            return order;
      }

      private recalculateCO2OrderTransport(order, replace_order) {
        order.co2_emission = replace_order.confirmed_quantity * order.transform_co2_generated * replace_order.confirmed_distance * order.default_conversion / 1000;
        return order;
      }

      private recalculateCO2Transform(order, replace_order) {
        let quantity = replace_order.confirmed_quantity !== null
          ? replace_order.confirmed_quantity 
          : replace_order.quantity;
        let defaultResult = quantity * order.transform_co2_generated;

        if (!order.unit && !order.transform_input_unit) {
          order.co2_emission = defaultResult;
        } else if (order.unit === order.transform_input_unit) {
          order.co2_emission = defaultResult;
        } else if (order.unit === PASSPORT_UNITS_TYPE.KG) {
          order.co2_emission = quantity * order.transform_co2_generated;
        } else if (order.unit === PASSPORT_UNITS_TYPE.TONNE) {
          order.co2_emission = quantity * order.transform_co2_generated;
        }
        return order;
      }

    private recalculateWaterConsumption(steps: EcosystemStep[], summary: SummaryStep[]) {
        steps.forEach(step => {
            if (step.type === ECOSYSTEM_STEP_TYPE.NEED || step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
                const replace = summary.find(element => element.step_id === step.id);
                this.recalculateStepWaterConsumption(step, replace);
            }
            if (step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM
                && this.waterTransformSteps(step.transformation_type)) {
                    const replace = summary.find(element => element.step_id === step.id);
                    step.summary_details.orders.forEach(order => {
                        const replaceOrder = replace.orders.find(element => element.order_id === order.id);
                        this.recalculateOrderWaterConsumption(order, replaceOrder);
                    });
                }
            });
          
     }

      private waterTransformSteps(type): boolean {
        return [TRANSFORM_TYPE.SORTING
          , TRANSFORM_TYPE.UPCYCLING
          , TRANSFORM_TYPE.METHANIZATION
          , TRANSFORM_TYPE.COMPOSTING].includes(type);
      }

      private recalculateStepWaterConsumption(step, replace) {
        const stepData = step.summary_details.step;
        const confirmedQuantity = replace.confirmed_quantity;
        stepData.errors = this.addRemoveError(stepData.errors
          , ERROR_TYPE_NO_CONFIRMED_QUANTITY_IN_NEED, confirmedQuantity);
        if(step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
          if(stepData.water_end_of_life != null)
            stepData.water_consumption = -( stepData.water_end_of_life)
              * confirmedQuantity * stepData.conversion * 1000  / (stepData.carbon_conversion || 1);
          else
            stepData.water_consumption = null;
        }
        else{
          stepData.water_consumption = -( stepData.water_product)
          * confirmedQuantity * stepData.conversion * 1000  / (stepData.carbon_conversion || 1);
        }
      }

  private recalculateOrderWaterConsumption(order, replaceOrder) {
    order.errors = this.addRemoveError(order.errors, ERROR_TYPE_NO_CONFIRMED_QUANTITY_TRANSFORMATION, replaceOrder.confirmed_quantity);
    order.water_consumption = replaceOrder.confirmed_quantity * order.transform_water_consumption * 1000;
  }



    private addRemoveError(errors, error, value) {
        if (value || value === 0) {
          errors =  errors.filter(function(item) {
            return item !== error;
          });
        } else {
          if (errors.indexOf(error) === -1 ) {
            errors.push(error);
          }
        }
        return errors ;
      }
}
