import {Directive, ElementRef, HostListener, Renderer2, Input } from '@angular/core';
import {MdTooltip, TOUCHEND_HIDE_DELAY} from '@angular/material';
import { AbstractTooltipPrefix } from './abstract-tooltip-prefix';


/**
 * This directive is work around to support customization tooltips for @angular/material library version 2.0.0-beta.3.
 * This directive makes possible to add html content to the tooltip
 * Please remove or rewrite this directive if another library is used for tooltips or if @angular/material library is upgraded.
 *
 */
@Directive({
  selector: '[appCustomTemplateTooltip]'
})
export class CustomTemplateTooltipDirective extends AbstractTooltipPrefix {
  @Input() customTemplate: string;
  @HostListener('mouseenter')
  addMessageToTooltip(){
    setTimeout(() => this.attachCustomTemplateMessage());
  }

  constructor (
    public materialTooltip: MdTooltip
    ) {
      super();
      this.materialTooltip.hideDelay = 0;
    }

  private getElement() {
    const elem = this.getCdkContainer(this.materialTooltip);
    return elem ? elem.querySelector('md-tooltip-component') : [];
  }
  private getInnerDivider() {
    return this.getElement().querySelector('div');
  }
  private attachCustomTemplateMessage(){
    let childElement = document.createElement("div");
    childElement.classList.add('custom-template');
    childElement.innerHTML = this.customTemplate;
    let parentElement = this.getInnerDivider();
    if(!parentElement.children[0]){
      parentElement.insertBefore(childElement, parentElement.children[1]);
    }
  }

}
