/**
 * Created by aleksandr on 27.07.17.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CurrentUserService } from '../services/current-user.service';
import { NavigationService } from '../services/navigation.service';
import { DeepStreamService } from '../services/deepstream.service';


@Injectable()
export class GuestGuard implements CanActivate {

  constructor(private _navigationService: NavigationService,
              private _userService: CurrentUserService,
              private _deepStreamService: DeepStreamService,
              private _router: Router) {
    console.log('Construct guest guard');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log('Try to activate', state.url);
    console.log('Launch LoginGuard -> Try to activate', state.url);
    return this
            ._userService
            .loggedInChecked$
            .map((isChecked) => this._userService.isLoggedIn)
            .map((isLoggedIn) => {
              console.log('LoginGuard this.isLoggedInCheck()', isLoggedIn);
              if (isLoggedIn) {
                this._navigationService.showNavBar = true;
                let context = this._navigationService.getContext();
                this._router.navigate([context ? context.homeUrl : '']);
                return false;
              } else {
                this._navigationService.setModule('guest');
                this._navigationService.showNavBar = false;
                this._deepStreamService.logout();
                return true;
              }
            })
            .do((result:boolean) => {
              console.log('Launch LoginGuard completed');
            });
  }


}
