var ɵ0 = function (days) { return days / 12 / 30.4; }, ɵ1 = function (days) { return days / 30.4; }, ɵ2 = function (days) { return days / 7; };
var PERIOD_DAY_DESC = {
    year: ɵ0,
    month: ɵ1,
    week: ɵ2,
    oneDay: 1,
    zeroDays: 0
};
var ɵ3 = function (key) { return [key, PERIOD_DAY_DESC[key]]; };
var periodDescEntries = Object.keys(PERIOD_DAY_DESC).map(ɵ3);
export function dateDiffLargestPeriod(dateFrom, dateTo, periodsNames) {
    var validPeriod = periodDescEntries.find(function (period) {
        return !!periodsNames[period[0]] && isValidPeriod(dateFrom, dateTo, period[1]);
    });
    // console.log(dateFrom.toString(), dateTo.toString(), validPeriod);
    if (validPeriod) {
        if (typeof validPeriod[1] === 'number')
            return [periodsNames[validPeriod[0]]];
    }
    else {
        validPeriod = ['day', null];
    }
    var period = Math.round(getDiff(dateFrom, dateTo, validPeriod[1]));
    return [period, periodsNames[validPeriod[0]] + (Math.abs(period) > 1 ? 's' : '')];
}
function isValidPeriod(dateFrom, dateTo, periodOrFunc) {
    if (typeof periodOrFunc === 'number') {
        return getDiff(dateFrom, dateTo) === Number(periodOrFunc);
    }
    else {
        return Math.abs(getDiff(dateFrom, dateTo, periodOrFunc)) >= 1;
    }
}
function getDiff(dateFrom, dateTo, period) {
    dateTo = dateTo.startOf('day');
    dateFrom = dateFrom.startOf('day');
    if (!!period) {
        return period(dateTo
            .diff(dateFrom, 'days'));
    }
    else {
        return dateTo.diff(dateFrom, 'days');
    }
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
