<section class="deposit-edit">
  <article class="main-content">
  <section class="main-wrapper">
    <header class="deposit-edit__top">
      <section class="deposit-edit__top--left-half">
        <div>
          <h1>{{ 'New deposit' | translate | uppercase}}</h1>
        </div>
        <div class="deposit-edit__top--name">
          <md-input-container>
            <input mdInput [(ngModel)]="deposit.name" placeholder="{{ 'Deposit name' | translate }} *" autocomplete="off" required="true"
              [class.highlighted]="controlStates?.name?.highlighted">
          </md-input-container>
          <label class="form-group__label"
               mdTooltip="{{ 'DEPOSIT_NAME_TOOLTIP' | translate }}">
            <i class="form-group__label--question">?</i>
          </label>
        </div>
      </section>
      <div class="deposit-edit__top__upload">
        <span class="deposit-edit__top__upload--name">{{ 'Main photo' | translate }}</span>
        <div class="deposit-edit__top__upload--frame"  >
          <div class="deposit-edit__top__upload--logo" *ngIf="deposit.logo || tempLogo; else depositLogo">
            <img [src]="deposit.logo || tempLogo" alt="">
            <button class="upload-files__delete" (click)="removeLogo()" type="button">
              <i class="icon icon--cross"></i>
            </button>
          </div>
          <ng-template #depositLogo>
            <input type="file" style="display: none;" id="logoLoad" ng2FileSelect (change)="saveTempLogo()" [uploader]="uploader">
            <label for="logoLoad" class="deposit-edit__top__upload--icon camera--icon"></label>
          </ng-template>
        </div>
      </div>
    </header>
    <ul class="collapsible__list">
      <li class="collapsible__item">
        <header class="collapsible__item-header" (click)="onTabChanged(0)">
          <span class="collapsible__item-step ">1</span>
          <h2 class="collapsible__item-title ">{{ 'Circular passport' | translate }}</h2>
        </header>
        <article class="collapsible__item-content" [ngClass]="{'active': stepId===0}">
          <div
            [hidden]="stepId !== 0">
            <deposit-passport-step
              class="collapsible__item-data deposit-edit__collapsible-item"
              [deposit]="deposit"
              [listener]="eventStream$"
              (onChangeDeposit)="changeDeposit($event)"
              (onSaveComplete)="onSaveCompleteHandler($event)"
              (onSaveError)="onPassportSaveFailedHandler($event)"
              [controlStates]="controlStates"
              *ngIf="deposit.loaded"
              [spinnerEnabled]="spinnerEnabled"
            ></deposit-passport-step>
          </div>

        </article>
      </li>
      <li class="collapsible__item">
        <header class="collapsible__item-header" (click)="onTabChanged(1)">
          <span class="collapsible__item-step">2</span>
          <h2 class="collapsible__item-title">{{ 'site & tags' | translate }}</h2>
        </header>

        <article class="collapsible__item-content" [ngClass]="{'active': stepId===1}">
          <div
            [hidden]="stepId !== 1">
          <location-step
            class="collapsible__item-data"
            [deposit]="deposit"
            [listener]="eventStream$"
            [controlStates]="controlStates"
            *ngIf="deposit.loaded && deposit.getProgress() > 0"
            [hidden]="stepId !== 1"
          ></location-step>
          </div>
        </article>
      </li>
      <li class="collapsible__item">
        <header class="collapsible__item-header" (click)="onTabChanged(2)">
          <span class="collapsible__item-step">3</span>
          <h2 class="collapsible__item-title">{{ 'Deposit Information' | translate }}</h2>
        </header>
        <article class="collapsible__item-content" [ngClass]="{'active': stepId>=2}">
          <div
            [hidden]="stepId < 2">
          <other-step
            class="collapsible__item-data"
            [deposit]="deposit"
            [listener]="eventStream$"
            [controlStates]="controlStates"
            *ngIf="deposit.loaded && deposit.getProgress() > 1"
            [hidden]="stepId !== 2"
          ></other-step>
          </div>
        </article>
      </li>
    </ul>
    <footer class="controls">
      <ul class="button-group__list">
        <ng-container *ngIf="!isPublished()">
          <li class="button-group__item button-group__item--wide">
            <button md-button md-raised-button
                    (click)="saveDraft()"
            >{{ 'Save' | translate }}
            </button>
          </li>
        </ng-container>
          <li class="button-group__item button-group__item--wide">
            <button md-button md-raised-button
                    [disabled]="isButtonDisabled()"
                    (click)="submit()"
            >{{ 'Publish' | translate }}
            </button>
          </li>
      </ul>
    </footer>
  </section>
  </article>
</section>
