/**
 * Created by atat on 18/06/2017.
 */

import { Component, OnInit } from '@angular/core';
import { ICustomListRecord } from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { USER_ROLES } from '../../../../shared/values/role-ids.enum';
import { Router } from '@angular/router';
import { User } from '../../../../shared/models/user.class';
import { UserService } from '../../../../shared/services/user.service';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import { FormControl } from "@angular/forms";

@Component({
  moduleId: module.id,
  selector: 'do-agents-catalog',
  templateUrl: 'do-agents-catalog.component.html',
  styleUrls: ['do-agents-catalog.component.scss']
})
export class DoAgentsCatalogComponent implements OnInit {
  records: ICustomListRecord[] = [];

  //Filters
  USER_ROLES = USER_ROLES;
  users: User[];
  region: string;

  search = new FormControl();

  constructor(private userService: UserService,
              private _globalEvents: GlobalEventsManager,
              private router: Router,
              private _currentUserService: CurrentUserService) {
    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => this.getRecords())
    ;
  }

  ngOnInit() {
    this.getRecords();
  }

  getRecords() {
    let query = {
      search: this.search.value || null,
      //region: this.region || null,
    };
    this.userService
      .getListAgents(query)
      .subscribe((res: User[]) => this.users = res);
  }

  getAvatar(user: User) {
    if (user.avatar_url) {
      return {'background-image': 'url(' + user.avatar_url + ')'};
    }
    return null;
  }

  openChat(targetId: number) {
    this._globalEvents.chatUserClicked$.emit(targetId);
  }
}
