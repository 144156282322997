import { ActiveRecord } from '../../../shared/models/active-record.class';
import { GenericPassportTagService } from '../service/generic-passport-tag.service';


export class GenericPassportTag extends ActiveRecord {
  protected provider = GenericPassportTagService;

  public id: number;
  public name: string;

  protected fields() {
    return [
      'id',
      'name',
    ];
  }
}


