import * as tslib_1 from "tslib";
import { EcosystemOfferService } from './ecosystem-offer.service';
import { ExtendedActiveRecord } from '../../shared/models/extended-active-record.class';
import { DatePipe } from '@angular/common';
import { Need } from '../need/models/need.class';
import { Client } from '../client/model/client.class';
import { ECOSYSTEM_OFFER_STATUS } from '../../dashboards/ecosystem-manager/values/ecosystem-offer-status.const';
import { Contact } from '../client/model/contact.class';
import { ECOSYSTEM_STATUS } from '../../dashboards/ecosystem-manager/values/ecosystem-status.const';
import { EcosystemFileJunction } from '../../dashboards/ecosystem-manager/models/ecosystem-file-junction.class';
import { ValidationService } from '../../shared/services/validation-service';
import { EcosystemProblem } from '../ecosystem/models/ecosystem-problem.model';
var EcosystemOffer = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemOffer, _super);
    function EcosystemOffer(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = EcosystemOfferService;
        _this.status = _this.status || ECOSYSTEM_OFFER_STATUS.DRAFT;
        data = data || {};
        _this.need = new Need(Object.assign(data.need || {}, {
            client: new Client({ name: data.client_name }),
            client_contact: new Contact({ email: data.client_email }),
            passport: data.passport,
        }));
        _this.ecosystem = data.ecosystem;
        _this.ecosystem_file_junction = data.ecosystem_file_junction
            ? data.ecosystem_file_junction.map(function (item) { return new EcosystemFileJunction(item); })
            : [];
        _this.problem = data.problem ? new EcosystemProblem(data.problem) : null;
        return _this;
        // console.log('this.ecosystem_file_junction', this.ecosystem_file_junction);
    }
    Object.defineProperty(EcosystemOffer.prototype, "statusText", {
        // get statusText() {
        //   return ECOSYSTEM_OFFER_STATUS[this.status > 0 ? this.status : 1]
        //     .replace(/_/g, ' ')
        //     .toLowerCase();
        // }
        get: function () {
            var status = ECOSYSTEM_OFFER_STATUS[this.status > 0 ? this.status : 1]
                .replace(/_/g, ' ');
            return status[0].toUpperCase() + status.substr(1).toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemOffer.prototype, "statusView", {
        get: function () {
            var status = ECOSYSTEM_OFFER_STATUS[this.status > 0 ? this.status : 1];
            status = {
                DRAFT: 'STAND BY',
                WAITING_FOR_VALIDATION: 'OFFER SENT',
                ASK_FOR_MODIFICATION: 'ASK FOR COUNTER PROPOSAL',
            }[status] || status;
            return status.replace(/_/g, ' ');
        },
        enumerable: true,
        configurable: true
    });
    EcosystemOffer.prototype.fields = function () {
        return [
            'id',
            'ecosystem_id',
            'status',
            'price',
            'quantity',
            'transaction_quantity',
            'delivery_date',
            'transaction_date',
            'transaction_description',
            'transaction_price',
            'date_precision',
            'description',
            'created_at',
            'updated_at',
            'creator',
            'is_new',
            'sale_name',
            'publication_date',
            'confirmation_date',
            'origin',
            'delivery_format',
            'ecosystem_file_junction',
            'sales_price',
            'default_quantity',
            'default_unit',
            'weight',
            'problem'
        ];
    };
    EcosystemOffer.prototype.beforeSave = function (data) {
        var dataValues = _super.prototype.beforeSave.call(this, data);
        if (dataValues.delivery_date) {
            try {
                dataValues.delivery_date = new DatePipe('en-US').transform(dataValues.delivery_date, 'yyyy-MM-dd');
            }
            catch (e) {
                console.warn(e);
            }
        }
        if (dataValues.quantity) {
            dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
        }
        // if(typeof dataValues.quantity === 'string') {
        //   let parsedQuantity = LocaleService.tryParseToNumber(dataValues.quantity);
        //   if(typeof parsedQuantity === 'number') {
        //     dataValues.quantity = parsedQuantity;
        //   }
        // }
        return dataValues;
    };
    Object.defineProperty(EcosystemOffer.prototype, "canValidateManually", {
        get: function () {
            if (!this.ecosystem) {
                if (!this.ecosystem_id) {
                    return false;
                }
                else {
                    console.trace();
                    console.error('connect me up');
                }
            }
            return [
                ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION,
                ECOSYSTEM_STATUS.WAITING_FOR_QUOTE,
            ].includes(this.ecosystem.status) && [
                ECOSYSTEM_OFFER_STATUS.DRAFT,
                ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION,
                ECOSYSTEM_OFFER_STATUS.ASK_FOR_MODIFICATION,
                ECOSYSTEM_OFFER_STATUS.WAITING_FOR_VALIDATION,
                ECOSYSTEM_OFFER_STATUS.CONSIDERED,
            ].includes(this.status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemOffer.prototype, "canAskForValidation", {
        get: function () {
            if (!this.ecosystem) {
                if (!this.ecosystem_id) {
                    return false;
                }
                else {
                    console.trace();
                    console.error('connect me up');
                }
            }
            return [
                ECOSYSTEM_OFFER_STATUS.DRAFT,
                ECOSYSTEM_OFFER_STATUS.ASK_FOR_MODIFICATION,
                ECOSYSTEM_OFFER_STATUS.CONSIDERED
            ].indexOf(this.status) > -1;
        },
        enumerable: true,
        configurable: true
    });
    EcosystemOffer.prototype.canUseAfterValidationActions = function () {
        if (!this.ecosystem) {
            if (!this.ecosystem_id) {
                return false;
            }
            else {
                console.trace();
                console.error('connect me up');
            }
        }
        return this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS;
    };
    Object.defineProperty(EcosystemOffer.prototype, "canConfirm", {
        get: function () {
            return this.canUseAfterValidationActions()
                && (this.status === ECOSYSTEM_OFFER_STATUS.VALIDATED
                    || this.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION);
            // return this.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemOffer.prototype, "canAskConfirmationAgain", {
        get: function () {
            return this.canUseAfterValidationActions() && this.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemOffer.prototype, "wasValidated", {
        get: function () {
            return this.status === ECOSYSTEM_OFFER_STATUS.VALIDATED ||
                this.status === ECOSYSTEM_OFFER_STATUS.CONFIRMED ||
                this.status === ECOSYSTEM_OFFER_STATUS.REFUSED ||
                this.status === ECOSYSTEM_OFFER_STATUS.CANCELLED ||
                this.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION;
        },
        enumerable: true,
        configurable: true
    });
    EcosystemOffer.prototype.setOrResetWeight = function (data, reset) {
        console.log(data);
        console.log(reset);
        if (!reset) {
            this.weight = data;
        }
        else {
            this.weight = null;
        }
    };
    EcosystemOffer.prototype.isWeightExist = function () {
        return !!this.weight || this.weight === 0;
    };
    return EcosystemOffer;
}(ExtendedActiveRecord));
export { EcosystemOffer };
