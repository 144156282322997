import { Component } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'deposit-delete-all-modal',
  templateUrl: 'deposit-delete-all-modal.component.html',
  styleUrls: ['deposit-delete-all-modal.component.scss']

})
export class DepositDeleteAllModalComponent {
  warningMessage = '';

  constructor(public mdDialogRef: MdDialogRef<void>,
    private _service: DepositService,
    private _translate: TranslateService) { }


  confirm() {
    this._service.deleteAllDeposits().subscribe(data => {
      this.mdDialogRef.close(data);
    });
  }

  closeDialog() {
    this.mdDialogRef.close();
  }
}
