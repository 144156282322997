import { ActiveRecord } from './active-record.class';
import { ExtendedCrudService } from '../services/extended-crud.service';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';


export abstract class ExtendedActiveRecord extends ActiveRecord {
  protected service: ExtendedCrudService;

  publish(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.publish(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  update(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.update(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  /**
 * Effectue une mise à jour partielle de l'instance en cours en se basant sur les champs spécifiés.
 * Cette méthode est conçue pour envoyer uniquement les champs modifiés à la couche de service pour la mise à jour,
 * plutôt que de transmettre l'ensemble de l'objet. Ceci est utile pour les scénarios où seule une partie
 * des données de l'objet nécessite une mise à jour, permettant ainsi d'éviter des requêtes lourdes
 * et de minimiser le trafic réseau.
 *
 * @param updates Un objet contenant les champs à mettre à jour avec leurs nouvelles valeurs.
 *                Seuls ces champs seront inclus dans la requête de mise à jour.
 * @returns Observable<any> Un Observable qui émet la réponse du service de mise à jour.
 *                          L'objet actuel est également mis à jour avec les nouvelles valeurs.
 *
 * Utilisation :
 * Supposons qu'un objet `step` de type `EcosystemStep` nécessite une mise à jour des champs `x_pos` et `y_pos`,
 * cette méthode peut être appelée comme suit : `step.partialUpdate({ x_pos: newX, y_pos: newY }).subscribe();`
 */
  partialUpdate(updates: any): Observable<any> {
    this.build();
    const dataToUpdate = this.beforeSave(updates);
    this.beforeSave$.emit(this);
    return this.service.update(this.id, dataToUpdate).map((res: any) => {
      Object.assign(this, updates);
      this.afterSave$.emit(this);
      return res;
    });
  }

  unpublish(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.unpublish(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  reject(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.reject(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  askForModification(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.askForModification(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  askForRating(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.askForRating(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  validate(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.validate(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  validateAll(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.validateAll(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  unvalidate(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.unvalidate(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  confirm(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    return this.service.confirm(this.id, this.beforeSave(this.extractData(this))).map((res: any) => {
      this.afterSave$.emit(this);
      return res;
    });
  }

  simplePost(endpointPrefix: string): Observable<any> {
    this.build();
    let service = this.service;
    let endpoint = service.getEndpoint(service.singularEndpoint + endpointPrefix);

    this.beforeSave$.emit(this);
    return service
      .sendPost(endpoint, this.beforeSave(this.extractData(this)))
      .map((res: Response) => {
        let text = res.text();
        return text ? JSON.parse(text) : null;
      })
      .catch((error) => service.onError(error))
      .do((res: any) => this.afterSave$.emit(this))
      ;
  }

  canDelete() {
    this.build();
    return this.service.canDelete(this.id);
  }

  canUpdate() {
    this.build();
    return this.service.canUpdate(this.id);
  }

  public getFileUploadUrl() {
    this.build();
    return this.service.getFileUploadUrl(this.id);
  }
  /*
   protected uploadedDocService: CrudService;
   protected uploadedDocProvider: any;
   protected getUploadedDocsQuery: (id: number) => any;

   public getUploadedDocs():Observable<any[]> {
   if (!this.uploadedDocService) {
   if (!this.uploadedDocProvider) {
   throw new Error('uploaded document provider not found');
   }
   this.uploadedDocService = ServiceLocator.injector.get(this.uploadedDocProvider);
   }

   return this
   .uploadedDocService
   .get(this.getUploadedDocsQuery())
   ;
   }
   */
}
