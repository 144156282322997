import { Component, Input, OnInit } from '@angular/core';
import {SpinnerDialogComponent} from '../../../../shared/spinner/dialogs/spinner-dialog/spinner-dialog.component';
import {MdDialog} from '@angular/material';
import { LandfillVariables } from './landfill-variables.model';
import {ILandfillVariable} from './landfill-variables.interface';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/Subject';
import { ZoneService } from '../../../../shared/services/zone.service';
import { LANDFILL_LABEL } from '../../../../shared/values/landfill.map';
import { Zone } from '../../../../shared/models/zone.class';
import { CurrencyService } from '../../../../shared/services/currency.service';
import { Currency } from '../../../../shared/models/currency.class';

@Component({
  moduleId: module.id,
  selector: 'landfill-variables',
  templateUrl: 'landfill-variables.component.html',
  styleUrls: ['landfill-variables.component.scss'],
})
export class LandfillVariablesComponent implements OnInit {
  private _spinnerDialogRef;
  public landfillVariables: LandfillVariables[] = [];
  public inputObservable = new Subject<LandfillVariables>();
  @Input() zoneId: number;
  currency = new Currency();
  constructor(private _zoneService: ZoneService,
              private _dialog: MdDialog,
              private _translate: TranslateService,
              private _currencyService: CurrencyService) {
  }

  ngOnInit() {
    this.inputObservable.debounceTime(1000).subscribe((data) => this.save(data));

    this._currencyService.get({expand: 'symbol'}).subscribe((currencies: Currency[]) => {
      this._zoneService.view(this.zoneId, {expand: 'currency_iso'}).subscribe((zone: Zone) => {
        this.currency = currencies.find(c => c.iso === zone.currency_iso);
      });
    });

    this._zoneService.getLandfillVariables(this.zoneId, {expand: 'name,value'})
      .subscribe(variables => this.landfillVariables = variables);
  }
  changeInputValue($event) {
    this.inputObservable.next($event);
  }
  save(landfillVariable): void {
    this._spinnerDialogRef = this._dialog.open(SpinnerDialogComponent, {
      disableClose: true,
      data: {
        caption: this._translate.instant('Saving landfill variables'), isLoadingDotsShown: true
      }
    });
    this._zoneService.saveLandfillVariables(this.zoneId, {landfill_cost_variables:
      [{
        id: landfillVariable.id,
        value: landfillVariable.value,
        zone_id: this.zoneId,
        name: LANDFILL_LABEL[landfillVariable.id]
      }]
    })
    .subscribe(
      (response: ILandfillVariable) => {
        this._spinnerDialogRef.close();
      },
      () => {
        this._spinnerDialogRef.close();
      }
    );
  }
}
