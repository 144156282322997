import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IRoleContextConfig } from '../../../interfaces/role-context-config.interface';
import { NotificationService } from '../../notification.service';
import { NotificationEntry } from '../../models/notification-entry.model';
import { NotificationHandlerFactory } from '../../handler/notification-handler-factory';
import { Router } from '@angular/router';

@Component({
  selector: 'notification-wrapper',
  templateUrl: 'notification-wrapper.component.html'
})
export class NotificationWrapperComponent implements OnChanges {

  nbrNotifications: number | null = null;
  isLoading: boolean = true;
  isBarShown: boolean = false;
  notifications: NotificationEntry[] = [];
  currentPage: number = 0;
  totalPage: number | null = null;
  @Input() public context: IRoleContextConfig;

  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.context && changes.context.currentValue.roleId) {
      this.fetchNotificationsCount();
      this.closeNotifications();
    }
  }

  private resetStateOnClose = (): void => {
    this.notifications = [];
    this.currentPage = 0;
  };

  public fetchNotificationsCount(): void {
    this.isLoading = true;
    this.notificationService.getUnreadNotificationsCount(this.context.roleId)
      .subscribe((count: number) => {
        this.nbrNotifications = count;
        this.isLoading = false;
      });
  }

  public closeNotifications(): void {
    this.isBarShown = false;
    this.resetStateOnClose();
  }

  public toggleNotifications(): void {
    this.isBarShown = !this.isBarShown;
    if (this.isBarShown) {
      this.loadNotifications();
    }
    else if (!this.isBarShown) {
      this.resetStateOnClose();
    }
  }

  private loadNotifications(): void {
    this.isLoading = true;
    this.notificationService.getNotifications(this.context.roleId, this.currentPage + 1)
      .subscribe((res: any) => {
        const headers = res.headers;
        let totalCount = Number(headers.get('X-Pagination-Total-Count'));
        let currentPage = Number(headers.get('X-Pagination-Current-Page'));
        this.totalPage = Number(headers.get('X-Pagination-Page-Count'));
        let notif = res.json().map((notification: any) => new NotificationEntry(notification));
        if(Number(this.nbrNotifications) === totalCount) {
          this.notifications = [...this.notifications, ...notif];
          this.currentPage = currentPage;
        }
        else {
          this.resetStateOnClose();
          this.loadNotifications();
        }
        this.isLoading = false;
      }
      );
  }

  public onScroll(): void {
    if (this.totalPage === this.currentPage) {
      return;
    }

    const el = document.querySelector('#notificationList');
    const threshold = 300; // Seuil en pixels
    if (el && (el.scrollHeight - (el.scrollTop + el.clientHeight)) <= threshold && !this.isLoading) {
      this.loadNotifications();
    }
  }
  

  public callToAction(notification: NotificationEntry): void {
    const handler = NotificationHandlerFactory.createHandler(notification, this.router);
    handler.handle(notification);
  }

  public formatTotalCount(): string {
    if(this.nbrNotifications === null || this.nbrNotifications === undefined) {
      return '';
    }
    return this.nbrNotifications > 99 ? '99+' :this.nbrNotifications.toString();
  }
}
