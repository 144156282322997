var AbstractPermission = /** @class */ (function () {
    function AbstractPermission() {
    }
    Object.defineProperty(AbstractPermission.prototype, "id", {
        get: function () {
            return this.constructor && this.constructor['id'];
        },
        enumerable: true,
        configurable: true
    });
    AbstractPermission.prototype.isApplied = function (user) {
        try {
            return this.isValid(user);
        }
        catch (e) {
            console.error(e);
            return false;
        }
    };
    return AbstractPermission;
}());
export { AbstractPermission };
