import { 
  AfterViewInit, Component, DoCheck, Input, OnDestroy, ChangeDetectorRef, 
  Output, EventEmitter, ViewChild, OnInit 
} from '@angular/core';
import { LatLngBounds, LatLngBoundsLiteral, MapsAPILoader } from '@agm/core';
import { GoogleMapStyles } from '../config/google-maps-styles';
import { IMapMarker } from './map-marker.interface';
import { NavbarStateService } from '../services/navbar.service';
import { EMapType } from './map-type.enum';
import { AgmMarkerSpider } from 'agm-oms';
import { AgmInfoWindow } from '@agm/core';

@Component({
  moduleId: module.id,
  selector: 'map-view',
  templateUrl: 'map-view.component.html'
})
export class MapViewComponent implements DoCheck, OnInit, AfterViewInit, OnDestroy {
  MAP_TYPE = EMapType;
  private _markers: IMapMarker[] = []; // Initialize markers array

  mapZoom = 8;
  initialZoomApplied = false;
  mapType: EMapType = EMapType.ALL;
  @ViewChild(AgmMarkerSpider) spider: any;
  @ViewChild(AgmInfoWindow) infoWindow: AgmInfoWindow;

  @Input() onWindowClose?: EventEmitter<void>;
  @Input() onZoom?: EventEmitter<number>;
  @Input() onUpdateMapCenter?: EventEmitter<void>;
  @Input() maxZoom = 22;
  @Input() isMapTypeChangePolicySoft = false;
  @Input() disableDefaultOnMarkerChangeAction = false;
  @Output() markerClick = new EventEmitter<IMapMarker>();
  @Output() onMarkerClose = new EventEmitter<IMapMarker>();

  // Use setter to handle marker changes
  @Input('markers')
  set markers(markers: IMapMarker[]) {
    this._markers = markers;
    if (this.markers.length > 0 && !this.disableDefaultOnMarkerChangeAction) {
      this.updateMapCenter();
    }
  }
  get markers(): IMapMarker[] {
    return this._markers;
  }

  public mapBounds: LatLngBounds | LatLngBoundsLiteral = { north: 0, south: 0, east: 0, west: 0 };
  public mapStyles = GoogleMapStyles;
  public mapApiReady = false;
  private isSpiderActive = false;

  constructor(
    private _mapsAPILoader: MapsAPILoader,
    private changeDetectorRef: ChangeDetectorRef,
    public filterState: NavbarStateService
  ) {}

  // Override method to fix library error
  overrideSpiderManagerDestroyMarkers() {
    // Check if spider and its manager are defined
    if (this.spider && this.spider._spiderManager && this.spider._spiderManager._markerManager) {
      // Implement deleteMarkers if not existing
      if (!this.spider._spiderManager._markerManager.deleteMarkers) {
        this.spider._spiderManager._markerManager.deleteMarkers = (marker: any) => {};
      }
    }
  }

  ngOnInit() {
    // Subscribe to input event emitters
    if (this.onWindowClose) {
      this.onWindowClose.subscribe(() => this.closeInfoWindow());
    }
    if (this.onUpdateMapCenter) {
      this.onUpdateMapCenter.subscribe(() => this.updateMapCenter());
    }
    if (this.onZoom) {
      this.onZoom.subscribe((zoom: number) => this.unZoom(-zoom));
    }
  }

  ngAfterViewInit() {
    // Load maps API and set bounds after view initialization
    this._mapsAPILoader.load().then(() => {
      this.mapApiReady = true;
      this.mapBounds = this.getMapBounds(this.markers || []);
    });
  }

  // Close info window
  private closeInfoWindow() {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
  }

  // Handle map readiness
  onMapReady() {
    if (!this.initialZoomApplied) {
      this.mapZoom = 1;
      this.changeDetectorRef.detectChanges();
      this.initialZoomApplied = true;
    }
  }

  // Check for spider activity
  ngDoCheck() {
    this.isSpiderActive = this.spider ? true : false;
    if (this.isSpiderActive) {
      this.overrideSpiderManagerDestroyMarkers();
    }
  }

  // Unsubscribe from event emitters on destroy
  ngOnDestroy() {
    if (this.onWindowClose) {
      this.onWindowClose.unsubscribe();
    }
    if (this.onUpdateMapCenter) {
      this.onUpdateMapCenter.unsubscribe();
    }
    if (this.onZoom) {
      this.onZoom.unsubscribe();
    }
  }

  // Update map center based on markers
  updateMapCenter() {
    if (this.mapApiReady) {
      setTimeout(() => {
        this.mapBounds = this.getMapBounds(this.markers);
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  // Calculate bounds based on markers
  getMapBounds(markers: IMapMarker[]): LatLngBounds | LatLngBoundsLiteral {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach((marker) => {
      if (marker.lat && marker.lng) {
        // Use a new instance of LatLng to be compatible with google.maps.LatLngBounds
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      }
    });

    // Check that the terminals contain a single point and extend them if necessary
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      bounds.extend(new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01));
    }

    // Convert bounds to LatLngBoundsLiteral before returning it
    return {
      north: bounds.getNorthEast().lat(),
      south: bounds.getSouthWest().lat(),
      east: bounds.getNorthEast().lng(),
      west: bounds.getSouthWest().lng()
    };
  }


  // Emit marker close event
  onInfoWindowClose(marker: IMapMarker) {
    if (this.isMapTypeChangePolicySoft) {
      this.mapType = this.MAP_TYPE.ALL;
    }
    this.onMarkerClose.emit(marker);
  }

  // Adjust zoom level
  unZoom(zoom = 1) {
    this.mapZoom -= zoom;
  }

  // Emit marker click event
  onMarkerClickEvent(marker: IMapMarker) {
    if (this.isMapTypeChangePolicySoft) {
      this.lockMapType();
    }
    this.markerClick.emit(marker);
  }

  // Lock map type based on zoom level
  lockMapType() {
    if (this.mapType === this.MAP_TYPE.ALL) {
      this.mapType = this.mapZoom > 12 ? this.MAP_TYPE.SPIDER : this.MAP_TYPE.CLUSTER;
    }
  }
}
