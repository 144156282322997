<ng-container *ngIf="transform?.loaded">
  <div class="process-data">
    <h1 class="transform-details__primary-title">
      <img
        *ngIf="transform.transformation_type_data.iconUrl"
        [src]="transform.transformation_type_data.iconUrl"
        [ngClass]="getImageClass()"
      >{{transform.transformation_type_data.view | translate}}
    </h1>

    <div class="transform-details__wrapper">
      <div *ngIf="transform.status === TRANSFORM_STATUSES.ASK_FOR_MODIFICATION.value && modificationMessage"
           class="transform-details__message">
        {{modificationMessage | translate}}
      </div>

      <div class="transform-details__input-name">
        <md-input-container class="name" [formGroup]="form">
          <input mdInput
                 autocomplete="off"
                 [disabled]="readOnly"
                 name="transformation-name"
                 placeholder="{{ 'Name' | translate }} *"
                 formControlName="name"
                 [(ngModel)]="transform.name">
        </md-input-container>

        <label *ngIf="!readOnly"
               class="form-group__label"
               md-tooltip="{{ 'TRANSFORMATION_NAME_TOOLTIP' | translate }}">
          <i class="form-group__label--question">?</i>
        </label>
      </div>

      <div class="transform-details__input-name">
        <md-input-container class="name" [formGroup]="form">
          <input mdInput
                 autocomplete="off"
                 [disabled]="readOnly"
                 name="transformation-name"
                 placeholder="{{ 'English version of the name' | translate }} *"
                 formControlName="englishName"
                 [(ngModel)]="transform.english_name">
        </md-input-container>

        <div *ngIf="!readOnly" class="transform-details__translate-button-wrapper" (click)="translateNameToEnglish()"
             [ngClass]="{'background-primary-color': translationIsPossible()}">
          <i class="transform-details__translate-button-wrapper--earth-icon icon icon--terra-blanc"></i>
          <a class="transform-details__translate-button-wrapper--translate-link">{{ 'Translate' | translate }}</a>
        </div>
      </div>

      <div class="transform-details__input-textarea">
        <label class="transform-details__label">
          {{transform.transformation_type_data.includes.transformationDescription ? ('Transformation description' | translate) : 'Description' | translate }}
        </label>
        <md-input-container class="transform-details__description">
          <textarea mdInput [disabled]="readOnly" [(ngModel)]="transform.description"></textarea>
        </md-input-container>
      </div>


      <div *ngIf="transform.transformation_type_data.includes.incomeOutcome">
      <income-outcome
        [transform]="transform"
        [readOnly]="readOnly"
      >
      </income-outcome>

      <input-output
        [transform]="transform"
        [readOnly]="readOnly">
      </input-output>
      </div>

      <div class="transform-details__block-modalities"
           *ngIf="transform.transformation_type_data.includes.takeBack">
        <h2 class="transform-details__title">
          {{'Take back modalities' | translate}}
          <label *ngIf="!readOnly"
                 class="form-group__label"
                 md-tooltip="{{ 'TRANSFORMATION_CONDITIONS_TOOLTIP' | translate }}">
            <i class="form-group__label--question">?</i>
          </label>
        </h2>
        <md-radio-group class="transform-details__radio-group"
                        [disabled]="readOnly"
                        [(ngModel)]="transform.take_back"
        >
        <md-radio-button
            class="transform-details__radio-group--buttons"
            *ngFor="let filter of filters"
            [value]="filter.key">
            {{filter.value | capitalize | translate}}
          </md-radio-button>
        </md-radio-group>
      </div>

      <div class="transform-details__block-materials-accepted"
           *ngIf="transform.transformation_type_data.includes.acceptedMaterials">
        <h2 class="transform-details__title">{{'Materials accepted' | translate}}</h2>
        <accepted-materials
          *ngIf="transform.transformation_type_data.includes.acceptedMaterials"
          [transform]="transform"
          [readOnly]="readOnly"
        >
        </accepted-materials>
        <control-type
          *ngIf="transform.transformation_type_data.includes.controlType"
          [transform]="transform">
        </control-type>
      </div>

      <div class="transform-details__block-details-materials"
           *ngIf="transform.transformation_type_data.includes.transportedDetails">
        <h6 class="transform-details__label">{{'Details of the materials transported' | translate}}</h6>
        <md-chip-list class="transform-details__materials-list">
          <md-chip class="transform-details__materials-item"
                   *ngFor="let material of transform.inputMaterials">
            <span *ngIf="material.name.length > 20 ;else elseBlock" md-tooltip="{{material.name | translate}}">
           {{material.name | translate | slice:0:20 }}..
          <button class="transform-details__materials-delete"
                  *ngIf="!readOnly"
                  (click)="removeMaterial(material)"
          ></button>
          </span>
            <ng-template #elseBlock>
            <span md-tooltip="{{material.name | translate}}">
              {{material.name | translate }}
              <button class="transform-details__materials-delete"
                      *ngIf="!readOnly"
                      (click)="removeMaterial(material)"
              ></button>
              </span>
            </ng-template>
          </md-chip>
        </md-chip-list>
        <div class="transform-details__add primary-color" (click)="addMaterial(1)" *ngIf="!readOnly">
          + <span>{{'select a material' | translate}}</span>
        </div>
      </div>


      <h6 *ngIf="transform.transformation_type_data.includes.co2_generated" class="transform-details__label transform-details__co2-emissions-input-block-label">{{'CO2 emission' | translate}}</h6>
      <div  *ngIf="transform.transformation_type_data.includes.co2_generated" class="transform-details__co2-emissions-input-block">
      <div>
        <md-input-container style="width:94px;" class="transform-details__info-number">
          <input
            mdInput
            autocomplete="off"
                         type="number"
            min="0"
            [disabled]="readOnly"
            [(ngModel)]="transform.co2_generated"

>


        </md-input-container>
      </div>

      <div class="transform-details__co2-emissions-input-unit">
        &ensp;{{'kg CO2 eq / km / transported ton' | translate}}
      </div>
        <label
          md-tooltip="{{ 'TRANSFORMATION_CO2_EMISSION_TOOLTIP' | translate }}">

          <i class="form-group__label--question">?</i>
        </label>

      </div>

      <div *ngIf="transform.transformation_type_data.includes.concerned" class="transform-details__search-materials">
      <h6 class="transform-details__title">{{'Categories concerned' | translate}}</h6>
        <md-chip-list class="transform-details__materials-list">
          <md-basic-chip
            class="transform-details__materials-item"
            *ngFor="let tag of transform.categories; let i = index;" (focus)="false" md-tooltip="{{ tag.name | translate }}">
            {{ getCategoryName(tag.name) }}

            <button (click)="removeCategory(tag, i)" class="transform-details__materials-delete"></button>
          </md-basic-chip>
        </md-chip-list>
        <div class="transform-details__add primary-color" (click)="openCategoryDialog()" *ngIf="!readOnly">
          + <span>{{'select a category' | translate}}</span>
        </div>
      </div>

      <div class="transform-details__block-quantities" *ngIf="transform.transformation_type_data.includes.quantities">
        <h2 class="transform-details__title"
            *ngIf="transform.transformation_type_data.includes.incomeOutcome">
          {{'Quantities accepted for input to carry out this transformation' | translate}}
        </h2>
        <h2 class="transform-details__title"
            *ngIf="transform.transformation_type_data.includes.serviceArea">
          {{'Quantities accepted' | translate}}
        </h2>

        <h6 class="transform-details__label transform-details__quantities-input-block-label">{{'Minimum quantity accepted' | translate}}</h6>
        <div class="transform-details__quantities-input-block">
          <md-input-container class="transform-details__info-number">
            <input
              mdInput
              autocomplete="off"
              min="0"
              [disabled]="readOnly"
              [(ngModel)]="transform.min_quantity"
              type="number">
          </md-input-container>
          <div class="transform-details__info-unit">
            <md-select
              placeholder="{{ 'Unit' | translate }}"
              [floatPlaceholder]="'never'"
              [disabled]="readOnly"
              [(ngModel)]="transform.min_unit"
            >
              <md-option
                *ngFor="let unit of units"
                [value]="unit.id">
                {{unit.title | translate}}
              </md-option>
            </md-select>
          </div>
        </div>

        <h6 class="transform-details__label transform-details__quantities-input-block-label">
          {{'Maximum quantity accepted' | translate}}
        </h6>
        <div class="transform-details__quantities-input-block">
          <md-input-container class="transform-details__info-number">
            <input
              mdInput
              [disabled]="readOnly"
              autocomplete="off"
              min="0"
              type="number"
              [(ngModel)]="transform.max_quantity"
            >
          </md-input-container>
          <div class="transform-details__info-unit">
            <md-select
              placeholder="{{ 'Unit' | translate }}"
              [disabled]="readOnly"
              [floatPlaceholder]="'never'"
              [(ngModel)]="transform.max_unit"
            >
              <md-option
                *ngFor="let unit of units"
                [value]="unit.id">
                {{unit.title | translate}}
              </md-option>
            </md-select>
          </div>
        </div>
      </div>

      <!--<div class="accepted-materials-control-type-container">
        <accepted-materials
          *ngIf="transform.transformation_type_data.includes.acceptedMaterials"
          [transform]="transform">
        </accepted-materials>
        <control-type
          *ngIf="transform.transformation_type_data.includes.controlType"
          [transform]="transform">
        </control-type>
      </div>-->

      <div class="transform-details__block-conditions"
           *ngIf="transform.transformation_type_data.includes.conditions">
        <h2 class="transform-details__title">{{'Conditions' | translate}}</h2>
        <transform-conditions
          class="transform-details__block-conditions-transform"
          [transform]="transform"
          [readOnly]="readOnly">
        </transform-conditions>
        <label *ngIf="!readOnly"
               class="form-group__label"
               md-tooltip="{{ 'TRANSFORMATION_CONDITIONS_TOOLTIP' | translate }}">
          <i class="form-group__label--question">?</i>
        </label>
      </div>

      <div class="transform-details__block-technical">
        <h2 class="transform-details__title">{{'Technical details' | translate}}</h2>
        <md-input-container class="transform-details__description">
          <textarea mdInput [disabled]="readOnly" [(ngModel)]="transform.details"></textarea>
        </md-input-container>
      </div>

      <div class="transform-details__block-service"
           *ngIf="transform.transformation_type_data.includes.serviceArea">
        <h2 class="transform-details__title">{{transform.transformation_type_data.includes.geoServiceArea
          ? ('Geographical area or site' | translate) : 'Service area' | translate}}</h2>
        <service-area [disabled]="readOnly" [transform]="transform" [validationListener]="validateChild$"></service-area>
      </div>


      <div class="transform-details__block-storing"
           *ngIf="transform.transformation_type_data.includes.storingSites">
        <h2 class="transform-details__title">{{'Site of the transformation' | translate}}</h2>
        <storing-sites [formControl]="control" [readOnly]="readOnly" [transform]="transform"></storing-sites>
      </div>

      <h2 class="transform-details__title">{{'Documents' | translate}}</h2>
      <documents-uploader
        [isDisabled]="readOnly"
        class="documents"
        [files]="transform.files"
        [uploadUrl]="transform.getFileUploadUrl()"
        [service]="transformFileService"
      ></documents-uploader>
    </div>

    <div class="save-submit-container" *ngIf="transform.status !== TRANSFORM_STATUSES.REJECTED.value">
      <div *ngIf="!readOnly" class="">
        <button md-button md-raised-button (click)="save()"
                *ngIf="!isPublished() && transform.status !== TRANSFORM_STATUSES.ASK_FOR_MODIFICATION.value">
          {{'Save as draft' | translate}}
        </button>
          <button md-button md-raised-button id="submit-button" (click)="submit()" [hidden]="null">
            <ng-container *ngIf="isPublished()">{{ 'Save' | translate }}</ng-container>
            <ng-container *ngIf="!isPublished()">{{ 'Submit' | translate }}</ng-container>
          </button>
      </div>
      <div *ngIf="showValidationButtons && readOnly">
        <button [hidden]="null" md-raised-button (click)="reject()" *ngIf="isRejectButtonShown()">{{'Reject' | translate}}</button>
        <button id="validate-transf" [hidden]="null" md-raised-button (click)="validate()">{{'Validate' | translate}}</button>
        <button [hidden]="null" md-raised-button (click)="askForModification()">
          {{'Ask for modification' | translate}}
        </button>
      </div>
    </div>

    <div class="save-submit-container"
         *ngIf="transform.status === TRANSFORM_STATUSES.REJECTED.value">
      <button md-raised-button
              class="save"
              (click)="remove()">
        {{'Delete' | translate}}
      </button>
    </div>

  </div>
</ng-container>
