import {escapeRegExp} from 'tslint/lib/utils';

export function getHighLightedText(text: string, searchValue: string): string {
  if (!searchValue) {
    return text;
  }
    return text.replace(new RegExp(escapeRegExp(searchValue), 'i'), match => {
      return '<span class="background-secondary-color">' + match + '</span>';
    });

}
