<div class="esm-catalog">
  <header class="esm-catalog__header alone-button--create">
    <div class="esm-catalog__create">
      <button class="esm-catalog__map-view" (click)="createStakeholder()">{{ 'Create' | translate }}</button>
    </div>
  </header>
  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input test-id="search"
                 mdInput
                 autocomplete="off" 
                 placeholder="{{ 'Search' | translate }}"
                 [formControl]="search"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select-long">
        <md-select
          class="select-group"
          [(ngModel)]="type"
          (ngModelChange)="getRecords()"
          placeholder="{{ 'Stakeholder type' | translate }}"
        >
          <md-option>{{ 'Any' | translate }}</md-option>
          <md-option
            *ngFor="let TYPE of STAKEHOLDER_TYPES_ARRAY"
            [value]="TYPE.value"
          >{{TYPE.view | lowercase | capitalize |translate}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select-long">
        <i class="icon icon--gray-sort"></i>
        <md-select
          class="select-group"
          [(ngModel)]="sortOrder"
          (change)="getRecords()"
        >
          <md-option value="company_name">{{ 'By company' | translate }}</md-option>
          <md-option value="name">{{ 'By representative' | translate }}</md-option>
          <md-option value="type">{{ 'By type' | translate }}</md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
    (onEndOfList)="onEndOfListTriggered()"
  ></custom-list>
</div>
