import * as tslib_1 from "tslib";
import { CrudService } from '../services/crud.service';
import { ChatRoom } from './models/chat-room.model';
import { Observable } from 'rxjs/Observable';
import { ChatUser } from './models/chat-user.model';
import { ChatMessage } from './models/chat-message.model';
import { ERoleIds } from '../values/role-ids.enum';
import 'rxjs/add/observable/empty';
var ThreadApiService = /** @class */ (function (_super) {
    tslib_1.__extends(ThreadApiService, _super);
    function ThreadApiService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'thread';
        _this.namespaceSingular = 'thread';
        _this.ModelClass = ChatRoom;
        return _this;
    }
    ThreadApiService.prototype.getContextThreads = function (object_id, object_type) {
        return this.get({ object_id: object_id, object_type: object_type });
    };
    ThreadApiService.prototype.getRoomParticipants = function (threadId) {
        return this
            .sendGet(this.getEndpoint(this.namespace + '/' + threadId + '/contacts'))
            .map(function (response) { return response.json(); })
            .map(function (contacs) { return contacs.map(function (contact) { return new ChatUser({
            id: contact.id,
            status: contact.status,
            name: contact.username,
            image: contact.avatar_url
        }); }); });
    };
    ThreadApiService.prototype.getContacts = function (roleId) {
        return this
            .sendGet(this.getEndpoint('contact'), { search: { role: roleId } })
            .map(function (response) { return response.json(); })
            .map(function (contacs) { return contacs.map(function (contact) { return new ChatUser(contact); }); });
    };
    ThreadApiService.prototype.getPrivateRoom = function (userId) {
        return this.get({ user_id: userId }).map(function (records) {
            if (records.length > 0) {
                console.log('RECEIVED ROOM:', records[0]);
                return new ChatRoom(records[0]);
            }
            else {
                console.log('RECEIVED ROOM OF NOTHING');
                return null;
            }
        });
    };
    ThreadApiService.prototype.getContextRoom = function (threadId) {
        return this.view(threadId);
    };
    ThreadApiService.prototype.loadHistory = function (id) {
        return this
            .sendGet(this.getEndpoint('thread/' + id + '/history'), { search: { expand: 'content,file' } })
            .map(function (response) {
            console.log(response.json());
            return response.json().map(function (item) { return ChatMessage.create(item); });
        });
    };
    ThreadApiService.prototype.loadHistoryMessage = function (threadId, messageId) {
        return this
            .sendGet(this.getEndpoint("thread/" + threadId + "/history/" + messageId), { search: { expand: 'content' } })
            .map(function (response) { return ChatMessage.create(response.json()); });
    };
    ThreadApiService.prototype.sendMessage = function (id, text) {
        var data = {
            content: text,
            type: 1
        };
        return this.sendPost(this.getEndpoint('thread/' + id + '/message'), data)
            .map(function (result) {
            return result.json();
        })
            .catch(this.onError.bind(this));
    };
    ThreadApiService.prototype.readThread = function (id) {
        return this.sendPut(this.getEndpoint('thread/' + id + '/read'));
        //.map((result: any) => result.json());
    };
    ThreadApiService.prototype.getFileUploadUrl = function (threadId) {
        return this.getEndpoint(this.singularEndpoint + '/' + threadId + '/files');
    };
    ThreadApiService.prototype.getSupportContact = function (roleId) {
        if (roleId === ERoleIds.SUPERADMIN) {
            return Observable.empty();
        }
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '?support'))
            .map(function (response) { return response.json(); })
            .map(function (contacts) {
            var support = new ChatUser(contacts.shift());
            support.name = 'Upcyclea representative';
            support.image = '../assets/png/representative.png';
            return support;
        });
    };
    return ThreadApiService;
}(CrudService));
export { ThreadApiService };
