/**
 * Created by aleksandr on 16.05.17.
 */
import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'ecosystem-wrapper',
  templateUrl: 'ecosystem-wrapper.component.html'
})
export class EcosystemWrapperComponent implements OnInit {
  ngOnInit(): void {

  }
}
