import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MdCheckbox} from '@angular/material';

@Component({
  selector: 'deposit-custom-multi-select',
  templateUrl: './deposit-custom-multi-select.component.html',
  styleUrls: ['./deposit-custom-multi-select.component.scss']
})
export class DepositCustomMultiSelectComponent {

  @Input() placeholder: string;
  @Input() isPurple: Boolean = false;
  @Input() invertNeutral = false;
  @Input() idKey = 'status';
  @Input() allOptions: {
    view?: string,
    selected: boolean
    value: any,
    text?: string
  }[] = [];
  @Input() isSelectOpen = false;
  @Input() selectLike = true;
  @Input() isSelectEmpty: () => boolean = this.isEmpty;
  @Input() disableSelection = false;
  @Output() openSelect = new EventEmitter();
  @Output() changeOption = new EventEmitter();
  @ViewChild('selectBody') filterStatus: ElementRef;
  @ViewChildren('optionEl') checkboxes: QueryList<MdCheckbox>;
  private checkBoxFocused = 0;


  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this.filterStatus.nativeElement.contains(targetElement)) {
      this.isSelectOpen = false;
      this.openSelect.emit(this.isSelectOpen);
    }
  }

  @HostListener('document:focusin', ['$event.target'])
  public onFocus(targetElement) {
    if (!this.filterStatus.nativeElement.contains(targetElement)) {
      this.isSelectOpen = false;
      this.openSelect.emit(this.isSelectOpen);
    }
  }

  public openSelectByClick() {
    if (this.disableSelection) {
      return;
    }
    
    this.isSelectOpen = true;
    if (!this.selectLike) {
      this.filterStatus.nativeElement.focus();
    }
    this.openSelect.emit(this.isSelectOpen);
  }

  public onEnterShowRating($event) {
    if ($event.key === 'Enter' || $event.key === ' ') {
      this.openSelectByClick();
    }
  }

  handleKeyup($event) {
    if (this.selectLike && this.isSelectOpen) {
      if ($event.key === 'ArrowUp') {
        this.nextActiveFocus(true);
        event.preventDefault();
      } else if ($event.key === 'ArrowDown') {
        this.nextActiveFocus();
        event.preventDefault();
      }
    }
  }

  public isEmpty() {
    return this.invertNeutral 
        ? !this.allOptions.some(item => item.selected) || this.allOptions.every(item => item.selected)
        : !this.allOptions.some(item => item.selected);
  }
  
  public focusVisibilityInput() {
    return this.selectLike && this.isSelectOpen ? -1 : 0;
  }

  public focusVisibilityCheckbox() {
    return this.selectLike ? -1 : 0;
  }

  public nextActiveFocus(reverseOrder = false) {
    const elements = this.checkboxes.toArray();
    const beforeIndex = this.checkBoxFocused;
    for (let i = beforeIndex + 1; i < elements.length + beforeIndex; i++) {
      const index = reverseOrder ? this.reverseOrder(elements.length, i, beforeIndex) : (i  % elements.length);
      if (this.focusCheckbox(elements[index])) {
        this.checkBoxFocused = index;
        return true;
      }
    }
  }

  private reverseOrder(maxPosition, currentPosition, positionOfStart) {
    return (maxPosition - currentPosition + 2 * positionOfStart) % maxPosition;
  }

  private focusCheckbox(checkbox: MdCheckbox) {
    if (!checkbox.disabled) {
      checkbox.focus();
      return true;
    } else {
      return false;
    }
  }

  private getIconClassName() {
    return this.isPurple ? "icon--rating-filter-purple" : "icon--rating-filter";
  }
}
