import * as tslib_1 from "tslib";
import { DoCheck } from '@angular/core';
import { NeedService } from '../../../../../entities/need/services/need.service';
import { Subject } from 'rxjs/Subject';
import { NeedSalesListService } from '../../../../../entities/need/services/need-sales-list.service';
import { NeedClientListService } from '../../../../../entities/need/services/need-client-list.service';
import { ECustomListRecordStatus } from '../../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import * as _ from 'lodash';
import { NeedDetailsDialogComponent } from '../../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import { MdDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { NEED_STATUSES } from '../../../../../entities/need/values/need-statuses.const';
import { CurrentUserService } from '../../../../../shared/services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../../../../shared/services/navigation.service';
import { ModuleNames } from '../../../../../shared/values/module-names.const';
import { getSearchStatus } from '../../../../../shared/helpers/search-status-update';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import * as moment from 'moment';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
var AdminNeedCatalogComponent = /** @class */ (function () {
    function AdminNeedCatalogComponent(needService, needClientListService, needSalesListService, mdDialog, datePipe, _navigationService, _globalEvents, _currentUserService, _translate, navBarState) {
        var _this = this;
        this.needService = needService;
        this.needClientListService = needClientListService;
        this.needSalesListService = needSalesListService;
        this.mdDialog = mdDialog;
        this.datePipe = datePipe;
        this._navigationService = _navigationService;
        this._globalEvents = _globalEvents;
        this._currentUserService = _currentUserService;
        this._translate = _translate;
        this.navBarState = navBarState;
        this.sortOptions = [
            { field: 'delivery_date', view: 'Delivery date' },
            { field: 'client_name', view: 'Client' },
            { field: 'name', view: 'Name' },
            { field: 'sales_name', view: 'Need manager' }
        ];
        this.sortValue = 'delivery_date';
        this.filteredClients = [];
        this.filteredSales = [];
        this.specialAdminFilterStatuses = [];
        this.markers = [];
        this.searchSubject = new Subject();
        this.searchStatus = [];
        this.filter = 'active';
        this.NEED_STATUSES = NEED_STATUSES;
        this.needClientListService.get().subscribe(function (res) {
            _this.clients = res;
            if (_this.needs && _this.needs.length > 0) {
                _this.filteredClients = _this.clients
                    .filter(function (item) { return _this.needs.find(function (need) { return need.client && need.client.id === item.id; }); });
            }
        });
        this.needSalesListService.get().subscribe(function (res) {
            _this.saleses = res;
            if (_this.needs && _this.needs.length > 0) {
                _this.filteredSales = _this.saleses
                    .filter(function (item) { return _this.needs.find(function (need) { return need.sales_name === item.name; }); });
            }
        });
        this.searchSubject
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function (res) { return _this.search = res; });
        this._translate.get('Added').subscribe(function (translation) { return _this.markerAddedLabelText = translation; });
        this._translate.get('By').subscribe(function (translation) { return _this.markerByLabelText = translation; });
        if (this._navigationService.getModuleName() === ModuleNames.SPECIAL_ADMIN) {
            this.specialAdminFilterStatuses = [
                NEED_STATUSES.ASK_FOR_MODIFICATION,
                NEED_STATUSES.WAITING_FOR_PUBLICATION,
                NEED_STATUSES.PUBLISHED,
            ];
            this.searchStatus = this.specialAdminFilterStatuses.map(function (status) { return status.value; });
            //FIXING FILTER TO PUBLISHED NEEDS /boards/179405292/pulses/469015130
            this.searchStatus = [NEED_STATUSES.PUBLISHED.value];
        }
        this.parentModule = this._navigationService.getModuleName();
    }
    AdminNeedCatalogComponent.prototype.ngDoCheck = function () {
        var _this = this;
        var statuses = [];
        if (this._navigationService.getModuleName() === ModuleNames.SPECIAL_ADMIN) {
            statuses = this.searchStatus;
        }
        else {
            statuses = [NEED_STATUSES.PUBLISHED.value];
        }
        var query = {
            expand: 'client,passport,client_contact,client_location,ecosystems,sales_contact,sales_email,sales_name',
            admin: 1,
            supervisor: 1,
            independent: 1,
            delivery_from: this.expiresFrom || null,
            delivery_to: this.expiresTo || null,
            search: this.search || null,
            client: this.clientId || null,
            sales_contact: this.salesId || null,
            'status[]': statuses || null,
            sort: this.sortValue
        };
        if (this.navBarState.multilanguage) {
            query.english = true;
        }
        if (!_.isEqual(this.oldQuery, query)) {
            this
                .needService
                .get(query)
                .subscribe(function (res) {
                _this.mapIsReady = false;
                _this.needs = res;
                _this.records = res.map(function (d) { return _this.toRecord(d); });
                _this.markers = _this.needs.map(function (need) { return _this.toMarker(need); });
                _this.mapIsReady = true;
                _this.filteredClients = _this.clients
                    .filter(function (item) { return _this.needs.find(function (need) { return need.client && need.client.id === item.id; }); });
                _this.filteredSales = _this.saleses
                    .filter(function (item) { return _this.needs.find(function (need) { return need.sales_name === item.name; }); });
            });
            this.oldQuery = _.cloneDeep(query);
        }
    };
    AdminNeedCatalogComponent.prototype.downloadPdfReport = function () {
        return this.needService.getPdfReportUrl({
            supervisor: 1,
            delivery_from: this.expiresFrom || null,
            delivery_to: this.expiresTo || null,
            search: this.search || null,
            client: this.clientId || null,
            sales_contact: this.salesId || null,
            timezone_offset: moment().utcOffset(),
            status: [NEED_STATUSES.PUBLISHED.value]
        });
    };
    AdminNeedCatalogComponent.prototype.toMarker = function (need) {
        return {
            lat: need.client_location.lat,
            lng: need.client_location.lng,
            name: need.name,
            lines: [
                (need.quantity ? need.quantity : 0) + " " + need.passport.unit_text,
                "<br>",
                "" + (need.description ? need.description : ''),
                "<br>",
                this.markerAddedLabelText + ": " + this.datePipe.transform(need.created_at, 'dd.MM.y'),
                this.markerByLabelText + ": " + need.sales_contact,
            ]
        };
    };
    AdminNeedCatalogComponent.prototype.toRecord = function (data) {
        var _this = this;
        var passportName = '';
        if (data.passport) {
            passportName = data.passport.name;
        }
        var controls = [{
                name: 'See details',
                fn: function () {
                    _this.mdDialog.open(NeedDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                            readOnly: true,
                            id: data.id,
                        } }));
                },
            }, {
                name: 'Send email to Need manager',
                fn: function () { return location.href = 'mailto:' + data.sales_email; },
            }, {
                name: 'Send email to recipient',
                fn: function () { return location.href = 'mailto:' + data.client_contact.email; },
            }];
        if (data.user_id !== this._currentUserService.infoData.id) {
            controls.push({
                name: 'Chat',
                fn: function () { return _this.openChat(data.user_id); },
            });
        }
        if (this._navigationService.getModuleName() === ModuleNames.SUPER_ADMIN) {
            // TODO: retrieve company name
            this.authorColumn = 'Company name';
        }
        else {
            this.authorColumn = data.sales_name;
        }
        return CustomListRecord
            .build()
            .setPrimaryKey(data.id)
            .setStatus(ECustomListRecordStatus.NO_STATUS)
            .setIndependent(!!data.independent)
            .addTitleColumn(data.name, passportName, null, data.passport.english_name)
            .addListColumn([{ key: '', value: data.client.name }])
            .addUnitColumn(data.quantity ? data.quantity : data.quantity, data.passport.customListUnit)
            .addTitleColumn('', data.statusData.view.replace(/./, function (c) { return c.toUpperCase(); }))
            .addTextColumn(data.description)
            .addListColumn([
            data.delivery_date ? {
                key: 'Expires', value: this.datePipe.transform(data.delivery_date, 'dd.MM.y')
            } : { key: 'Expires', value: 'N/A' }
        ].filter(function (v) { return v; }))
            .addAuthorColumn(this.authorColumn)
            .addControlsColumn(controls);
    };
    AdminNeedCatalogComponent.prototype.openChat = function (targetId) {
        console.log('openChat', targetId);
        this._globalEvents.chatUserClicked$.emit(targetId);
    };
    AdminNeedCatalogComponent.prototype.toggleState = function (state) {
        this.searchStatus = getSearchStatus(state, this.searchStatus);
    };
    return AdminNeedCatalogComponent;
}());
export { AdminNeedCatalogComponent };
