import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReadOnlyService} from '../../../../../shared/read-only/read-only.service';
import {BIM} from '../../../../../shared/models/bim.class';
import {BIMService} from '../../../../../shared/services/bim.service';
import {FileUploader} from 'ng2-file-upload';
import {DepositLocation} from '../../../models/deposit-location.class';
import {Observable} from 'rxjs/Observable';


@Component({
  selector: 'deposit-site-details-preview',
  templateUrl: './deposit-site-details-preview.component.html',
  styleUrls: ['../deposit-site-details.component.scss'],
  providers: [ReadOnlyService]
})
export class DepositSiteDetailsPreviewComponent implements OnInit {
  BIMs = [];
  @Output() onSave = new EventEmitter<{location: DepositLocation, uploader: FileUploader}>();
  @Input() location: DepositLocation;
  @Input() uploader: FileUploader;
  @Input() readOnly = false;
  @Input() isCompanyBuilding = true;
  @Input() isValid: (location: DepositLocation) => Observable<boolean> = () => Observable.of(true);

  constructor(
    public BIMServiceInstance: BIMService,
    public readOnlyService: ReadOnlyService
  ) { }

  ngOnInit() {
    this.BIMServiceInstance.get({expand: 'name'}).subscribe((BIMs: BIM[]) => this.BIMs = BIMs);
  }

  createPreview() {
    this.isValid(this.location).subscribe((valid) => {
      if (valid) {
        this.onSave.emit({location: this.location, uploader: this.uploader});
      }
    });
  }
}
