<md-dialog-content class="esm-deposit-dialog">

  <header class="esm-deposit-dialog__header">
    <section class="esm-catalog__choice">
      <ul class="esm-catalog__choice-list">
        <li class="esm-catalog__choice-item search-long">
          <i class="icon icon--search"></i>
          <md-input-container class="input-group">
            <input mdInput
                   autocomplete="off" 
                   [placeholder]="'Search' | translate"
                   [formControl]="search"
                   (change)="getRecords()"
            >
          </md-input-container>
        </li>
        <li class="esm-catalog__choice-item select">
          <i class="icon icon--people-grey"></i>
          <md-select class="select-group"
                     [placeholder]="'Deposit owner' | translate"
                     [(ngModel)]="depositOwner"
                     (change)="getRecords()"
          >
          <md-option>{{'Any' | translate}}</md-option>
          <md-option
            *ngFor="let depositOwner of depositOwners"
            [value]="depositOwner.id"
          >{{depositOwner.name}}
          </md-option>
        </md-select>
        </li>
        <li class="esm-catalog__choice-item">
          <i class="icon icon--calendar"></i>
          <md-input-container class="date input-group">
            <input mdInput
                   autocomplete="off" 
                   ng2-datetime-picker
                   date-only="true"
                   date-format="DD-MM-YYYY"
                   [placeholder]="'Available FROM' | translate"
                   [(ngModel)]="availableDateFrom"
                   (change)="getRecords()"
            >
          </md-input-container>
        </li>
        <li class="esm-catalog__choice-dash">-</li>
        <li class="esm-catalog__choice-item">
          <i class="icon icon--calendar"></i>
          <md-input-container class="date input-group">
            <input mdInput
                   ng2-datetime-picker
                   autocomplete="off" 
                   date-only="true"
                   date-format="DD-MM-YYYY"
                   placeholder="{{'Available TO' | translate}}"
                   [(ngModel)]="availableDateTo"
                   (change)="getRecords()"
            >
          </md-input-container>
        </li>
      </ul>
    </section>
    <i class="icon icon--close_s esm-deposit-dialog__close" (click)="closeDialog()"></i>
  </header>

  <div class="esm-need-map">
    <deposit-map
        [beforeMapUpdate]="beforeUpdateMap"
        [updateMap]="updateMap"
        (onChangeDepositLocationIds)="setChosenLocationsById($event)"
        [markerInfoWindowCloseUnZoom]="false"
    ></deposit-map>
  </div>
</md-dialog-content>
