import { ActiveRecord } from '../active-record.class';

export abstract class UploadedDocument extends ActiveRecord {
  public id: number;
  public basename: string;
  public file: string;
  public type: number;

  public isImage: boolean = false;
  public extension: string = '';

  constructor(data?: any) {
    super(data);

    this.isImage = !!this.getThumbnailUrl();
    this.extension = this.getExtension();
  }

  protected fields() {
    return [
      'id',
      'basename',
      'file',
      'type'
    ];
  }

  getExtension() {
    let match = (this.file || '').match(/.+\.(.+)$/);
    return match && match[1] && match[1].toLowerCase();
  }

  getThumbnailUrl() {
    let extension = this.getExtension();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return this.file;
    } else {
      return null;
    }
  }
}
