<div class="search" [formGroup]="form">
  <div class="text-date-container">
    <md-input-container class="text">
      <input
        mdInput
        [placeholder]="'Search anything' | translate"
        formControlName="searchText"
      >
    </md-input-container>
    <md-input-container class="date">
      <input
        mdInput
        autocomplete="off" 
        ng2-datetime-picker
        date-format="DD-MM-YYYY"
        parse-format="YYYY-MM-DD"
        date-only="true"
        [placeholder]="'Date of publication' | translate"
        formControlName="publicationDate"
      >
    </md-input-container>
  </div>

  <div class="filters-container">
    <h2 class="filters-title">{{'State' | translate}}</h2>

    <div class="filters" formArrayName="filters">
      <md-checkbox
        *ngFor="let STATUS of FILTER_STATUSES; let i = index;"
        [formControlName]="i"
      >{{STATUS.view | translate}}
      </md-checkbox>
    </div>
  </div>
</div>

<div class="results">
  <agm-map
    class="map"
    [styles]="mapStyles"
    [fitBounds]="mapBounds"
  >
    <agm-marker
      *ngFor="let need of results"
      [iconUrl]="need.status_data.mapIconUrl"
      [latitude]="need.client_location.lat"
      [longitude]="need.client_location.lng"
    ></agm-marker>
  </agm-map>

  <div
    class="list"
    [class.ascending]="sortOrder > 0"
    [class.descending]="sortOrder < 0"
  >
    <h2 class="sort-by-title">{{'Sort by' | translate}}</h2>

    <div class="sort-by">
      <button
        *ngFor="let SORT_MODE of SORT_MODES"
        (click)="sort(SORT_MODE)"
        [class.selected]="SORT_MODE === selectedSortMode"
      >{{SORT_MODE.view | translate}}
      </button>
    </div>

    <ul [perfectScrollbar]>
      <li *ngFor="let need of results" class="{{need.status_data.text.toLowerCase()}}">
        <div>
          <span class="name">{{need.name}}</span> <span
          class="amount">{{need.quantity_text}} {{need.passport.unit_text | translate}}</span><br>
          {{need.client.name}}<br>
          <br>
          <span class="client-address">{{need.client.address}}</span><br>
          {{need.client_contact.name}} <span class="client-contact-address">{{need.client_contact.function}}</span><br>
          {{need.client_contact.email}}<br>
          {{need.client_contact.phone}}
        </div>
        <div>
          <span class="created-text">{{'created' | translate}}:</span> {{need.created_at | date:'dd.MM.y HH:mm'}}<br>
          <span class="delivery-text">{{'delivery' | translate}}:</span> {{need.delivery_date | date:'dd.MM.y HH:mm'}}
        </div>
        <div>
          <span class="published-text">{{'published' | translate}}:</span> {{need.updated_at | date:'dd.MM.y HH:mm'}}
          <img class="client-image" *ngIf="need.client.image" [src]="need.client.image">
          <span class="status-text">{{need.status_data.view | translate}}</span>
        </div>
      </li>
    </ul>
  </div>
</div>
