import { EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NEED_SORT_NAME } from '../../../values/need-sort.enum';
import { ACTIVE_NEED_STATUSES, ARCHIVED_NEED_STATUSES } from '../../../values/need-statuses.const';
import { ECOSYSTEM_OFFER_ACTIVE_STATUS, ECOSYSTEM_OFFER_ARCHIVED_STATUS } from '../../../../../dashboards/ecosystem-manager/values/ecosystem-offer-status.const';
var NeedFilterComponent = /** @class */ (function () {
    function NeedFilterComponent() {
        var _this = this;
        this.onFilterUpdate = new EventEmitter();
        this.sort_order = NEED_SORT_NAME;
        this.searchControl = new FormControl();
        this.searchKey = '';
        this.orderBy = '-created_at';
        this.createdDateFrom = '';
        this.createdDateTo = '';
        this.deliveryDateFrom = '';
        this.deliveryDateTo = '';
        this.isTargetedOnly = false;
        this.isNeedWithOfferOnly = false;
        this.statusList = [];
        this.offerStatusList = [];
        this.recipientList = [];
        this.deliveryLocationList = [];
        this.depositLocationList = [];
        this.recipientListLoaded = false;
        this.deliveryLocationListLoaded = false;
        this.depositLocationListLoaded = false;
        this.activeStatusList = ACTIVE_NEED_STATUSES;
        this.archivedStatusList = ARCHIVED_NEED_STATUSES;
        this.activeOfferStatusList = ECOSYSTEM_OFFER_ACTIVE_STATUS;
        this.archivedOfferStatusList = ECOSYSTEM_OFFER_ARCHIVED_STATUS;
        this.searchControl
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function (value) {
            _this.searchKey = value;
            _this.updateFilters();
        });
    }
    Object.defineProperty(NeedFilterComponent.prototype, "setRecipientList", {
        set: function (data) {
            this.recipientList = this.populateFilterList(data, true, 'recipientList');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedFilterComponent.prototype, "setDeliveryLocationList", {
        set: function (data) {
            this.deliveryLocationList = this.populateFilterList(data, true, 'deliveryLocationList');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedFilterComponent.prototype, "setDepositLocationList", {
        set: function (data) {
            this.depositLocationList = this.populateFilterList(data, true, 'depositLocationList');
        },
        enumerable: true,
        configurable: true
    });
    NeedFilterComponent.prototype.ngOnInit = function () {
        this.statusList = this.activeStatusList.map(function (status) { return Object.assign({ selected: true }, status); });
        this.offerStatusList = this.activeOfferStatusList.map(function (status) { return Object.assign({ selected: true }, status); });
        this.loadFilterState();
    };
    NeedFilterComponent.prototype.ngOnChanges = function (changes) {
        var filterState = this.getCorrectFilterState();
        if (changes['setRecipientList'] && !changes['setRecipientList'].firstChange) {
            this.loadSavedList('recipientList', filterState);
            this.recipientListLoaded = true;
        }
        if (changes['setDeliveryLocationList'] && !changes['setDeliveryLocationList'].firstChange) {
            this.loadSavedList('deliveryLocationList', filterState);
            this.deliveryLocationListLoaded = true;
        }
        if (changes['setDepositLocationList'] && !changes['setDepositLocationList'].firstChange) {
            this.loadSavedList('depositLocationList', filterState);
            this.depositLocationListLoaded = true;
        }
        if (changes['activeIndex'] && changes['activeIndex'].currentValue !== changes['activeIndex'].previousValue) {
            this.statusList = this.getCorrectStatusList().map(function (status) { return Object.assign({ selected: false }, status); });
            this.offerStatusList = this.getCorrectOfferStatusList().map(function (status) { return Object.assign({ selected: true }, status); });
            this.loadFilterState();
            this.updateFilters();
        }
    };
    /**
     * Get the default values for the filter
     * @returns {Object}
     */
    NeedFilterComponent.prototype.getDefaultFilter = function () {
        return {
            searchKey: "",
            orderBy: "-created_at",
            createdDateFrom: "",
            createdDateTo: "",
            deliveryDateFrom: "",
            deliveryDateTo: "",
            isTargetedOnly: false,
        };
    };
    NeedFilterComponent.prototype.populateFilterList = function (optionList, defaultSelection, tableName) {
        return [
            this.createOptionSelectAll(tableName)
        ].concat(optionList.map(function (option) { return Object.assign({ selected: defaultSelection }, option); }));
    };
    NeedFilterComponent.prototype.createOptionSelectAll = function (tableName) {
        var self = this;
        return {
            linkToSelectedFunc: this.isAllOptionsSelected.bind(this, tableName),
            value: 99,
            _selected: false,
            get selected() {
                return this._selected;
            },
            set selected(value) {
                this._selected = value;
                self[tableName].forEach(function (option) {
                    if (option.value !== 99) {
                        option.selected = value;
                    }
                });
            },
            text: 'SELECT_ALL',
            view: 'Select all'
        };
    };
    NeedFilterComponent.prototype.isAllOptionsSelected = function (tableName) {
        return this[tableName].every(function (option) { return option.selected || option.value === 99; });
    };
    /**
     * Get the list of need status corresponding to the current tab
     * @returns {Array}
     */
    NeedFilterComponent.prototype.getCorrectStatusList = function () {
        return this.activeIndex === 0 ? this.activeStatusList : this.archivedStatusList;
    };
    /**
     * Get the list of need status corresponding to the current tab
     * @returns {Array}
     */
    NeedFilterComponent.prototype.getCorrectOfferStatusList = function () {
        return this.activeIndex === 0 ? this.activeOfferStatusList : this.archivedOfferStatusList;
    };
    NeedFilterComponent.prototype.clearFilters = function () {
        this.resetFilters();
        this.updateFilters();
    };
    NeedFilterComponent.prototype.resetFilters = function () {
        this.searchControl.reset();
        this.searchKey = '';
        this.createdDateFrom = '';
        this.createdDateTo = '';
        this.deliveryDateFrom = '';
        this.deliveryDateTo = '';
        this.resetStatus();
        this.resetClient();
        this.resetLocation();
        this.resetDepositLocation();
        this.resetOfferStatus();
        this.resetToggleTargetedOnly();
        this.resetToggleNeedWithOfferOnly();
    };
    /**
     * Reset status list so that everything is unchecked
     */
    NeedFilterComponent.prototype.resetStatus = function () {
        this.statusList.forEach(function (status) { return status.selected = true; });
    };
    /**
     * Reset client list so that everything is unchecked
     */
    NeedFilterComponent.prototype.resetClient = function () {
        this.recipientList.forEach(function (recipient) { return recipient.selected = false; });
    };
    NeedFilterComponent.prototype.resetToggleTargetedOnly = function () {
        this.isTargetedOnly = false;
    };
    /**
     * Reset toggle that display need with offers only
     */
    NeedFilterComponent.prototype.resetToggleNeedWithOfferOnly = function () {
        this.isNeedWithOfferOnly = false;
    };
    /**
     * Reset location list so that everything is unchecked
     */
    NeedFilterComponent.prototype.resetLocation = function () {
        this.deliveryLocationList.forEach(function (location) { return location.selected = false; });
    };
    NeedFilterComponent.prototype.resetDepositLocation = function () {
        this.depositLocationList.forEach(function (location) { return location.selected = false; });
    };
    /**
     * Reset offer status list so that everything is unchecked
     */
    NeedFilterComponent.prototype.resetOfferStatus = function () {
        this.offerStatusList.forEach(function (status) { return status.selected = true; });
    };
    /**
     * Called when filters have changed
     * Emit an event which prevents the site that filters have changed
     */
    NeedFilterComponent.prototype.updateFilters = function () {
        this.saveFilterState();
        this.onFilterUpdate.emit();
    };
    /**
     * Called when a status has been checked or unchecked
     * Changed the selected attribute of the corresponding status
     * @param {String} event - The value of the status which has been clicked
     */
    NeedFilterComponent.prototype.updateStatus = function (event) {
        var updatedStatus = this.statusList.find(function (status) { return status.value === event; });
        updatedStatus.selected = !updatedStatus.selected;
        this.updateFilters();
    };
    /**
     * Called when a recipient has been checked or unchecked
     * Changed the selected attribute of the corresponding recipient
     * @param {String} event - The value of the recipient which has been clicked
     */
    NeedFilterComponent.prototype.updateRecipient = function (event) {
        var updatedRecipient = this.recipientList.find(function (recipient) { return recipient.value === event; });
        updatedRecipient.selected = !updatedRecipient.selected;
        this.updateFilters();
    };
    /**
     * Called when a location has been checked or unchecked
     * Changed the selected attribute of the corresponding location
     * @param {String} event - The value of the location which has been clicked
     */
    NeedFilterComponent.prototype.updateLocation = function (event) {
        var updatedLocation = this.deliveryLocationList.find(function (location) { return location.value === event; });
        updatedLocation.selected = !updatedLocation.selected;
        this.updateFilters();
    };
    /**
     * Toggle the targeted button
     * @param {Boolean} event - The state the targeted button has been toggled into
     */
    NeedFilterComponent.prototype.toggleTargetedOnly = function (event) {
        this.isTargetedOnly = event;
        if (!this.isTargetedOnly) {
            this.resetDepositLocation();
        }
        this.updateFilters();
    };
    NeedFilterComponent.prototype.toggleNeedWithOffers = function (event) {
        this.isNeedWithOfferOnly = event;
        if (!this.isNeedWithOfferOnly) {
            this.resetOfferStatus();
        }
        this.updateFilters();
    };
    NeedFilterComponent.prototype.updateDepositLocation = function (event) {
        var updatedLocation = this.depositLocationList.find(function (location) { return location.value === event; });
        updatedLocation.selected = !updatedLocation.selected;
        this.updateFilters();
    };
    /**
     * Get the name of the storage key for the current filter
     * @param {Number} filterIndex - The index of the filter
     * @returns
     */
    NeedFilterComponent.prototype.getFilterStorageKey = function (filterIndex) {
        return filterIndex === 0 ? "activeNeedFilter" : "archivedNeedList";
    };
    /**
     * Get the filter state corresponding to the active tab
     * @param {Number} filterIndex - The index of the tab we want to retrieve the filter for
     * @returns {Object}
     */
    NeedFilterComponent.prototype.getCorrectFilterState = function (filterIndex) {
        var storageKey = this.getFilterStorageKey(filterIndex === undefined ? this.activeIndex : filterIndex);
        return JSON.parse(localStorage.getItem(storageKey)) || {};
    };
    /**
     * Load the saved state of the filter
     */
    NeedFilterComponent.prototype.loadFilterState = function () {
        var savedFilter = this.getCorrectFilterState();
        var defaultFilter = this.getDefaultFilter();
        for (var attribute in defaultFilter) {
            this[attribute] = savedFilter[attribute] ? savedFilter[attribute] : defaultFilter[attribute];
        }
        this.searchControl.setValue(this.searchKey);
        this.loadSavedStatusList('statusList', savedFilter);
        this.loadSavedStatusList('offerStatusList', savedFilter);
        this.loadSavedList('recipientList', savedFilter);
        this.loadSavedList('deliveryLocationList', savedFilter);
        this.loadSavedList('depositLocationList', savedFilter);
    };
    /**
     * Load the data for lists
     * @param {String} listName - The attribute name of the list to load
     * @param {Object} savedState - The save object from which we want to load data
     */
    NeedFilterComponent.prototype.loadSavedList = function (listName, savedState) {
        this[listName].forEach(function (element) { return element.selected = (savedState[listName] || []).includes(element.value); });
    };
    /**
     * Load the data for lists
     * @param {String} listName - The attribute name of the list to load
     * @param {Object} savedState - The save object from which we want to load data
     */
    NeedFilterComponent.prototype.loadSavedStatusList = function (listName, savedState) {
        this[listName].forEach(function (element) { return element.selected = !(savedState[listName] || []).includes(element.value); });
    };
    /**
     * Save the current state of the filter to be loaded later
     * @param {Number} filterIndex - Number indicating the current index we are on (0 = Active, 1 = Archive)
     */
    NeedFilterComponent.prototype.saveFilterState = function (filterIndex) {
        if (filterIndex === undefined) {
            filterIndex = this.activeIndex;
        }
        var filterStorageKey = this.getFilterStorageKey(filterIndex);
        var currentStoredFilter = this.getCorrectFilterState(filterIndex);
        var filterToSave = {
            searchKey: this.searchKey,
            orderBy: this.orderBy,
            createdDateFrom: this.createdDateFrom,
            createdDateTo: this.createdDateTo,
            deliveryDateFrom: this.deliveryDateFrom,
            deliveryDateTo: this.deliveryDateTo,
            isTargetedOnly: this.isTargetedOnly,
            isNeedWithOfferOnly: this.isNeedWithOfferOnly,
            statusList: this.statusList.reduce(function (savedList, status) { return !status.selected ? savedList.concat(status.value) : savedList; }, []),
            offerStatusList: this.offerStatusList.reduce(function (savedList, status) { return !status.selected ? savedList.concat(status.value) : savedList; }, []),
            recipientList: this.recipientListLoaded
                ? this.recipientList.reduce(function (savedList, status) { return status.selected ? savedList.concat(status.value) : savedList; }, [])
                : (currentStoredFilter.recipientList || []),
            deliveryLocationList: this.deliveryLocationListLoaded
                ? this.deliveryLocationList.reduce(function (savedList, status) { return status.selected ? savedList.concat(status.value) : savedList; }, [])
                : (currentStoredFilter.deliveryLocationList || []),
            depositLocationList: this.depositLocationListLoaded
                ? this.depositLocationList.reduce(function (savedList, status) { return status.selected ? savedList.concat(status.value) : savedList; }, [])
                : (currentStoredFilter.depositLocationList || []),
        };
        var jsonFilter = JSON.stringify(filterToSave);
        localStorage.setItem(filterStorageKey, jsonFilter);
    };
    /**
     * Called when an offer status has been checked or unchecked
     * Changed the selected attribute of the corresponding offer status
     * @param {String} event - The value of the offer status which has been clicked
     */
    NeedFilterComponent.prototype.updateOfferStatus = function (event) {
        var updatedOfferStatus = this.offerStatusList.find(function (status) { return status.value === event; });
        updatedOfferStatus.selected = !updatedOfferStatus.selected;
        this.updateFilters();
    };
    /**
     * Check whether there has been changes in the filter or not
     * @returns {Boolean} True if there has been some change in the filter
     */
    NeedFilterComponent.prototype.hasChange = function () {
        return !!this.searchKey
            || !!this.createdDateFrom
            || !!this.createdDateTo
            || !!this.deliveryDateFrom
            || !!this.deliveryDateTo
            || this.statusList.some(function (status) { return !status.selected; })
            || this.recipientList.some(function (recipient) { return recipient.selected; })
            || this.deliveryLocationList.some(function (location) { return location.selected; })
            || this.isTargetedOnly
            || this.offerStatusList.some(function (status) { return !status.selected; });
    };
    /**
     * Gather information from the filter to create the query
     * @returns {Array}
     */
    NeedFilterComponent.prototype.buildOptions = function () {
        var options = [];
        options.push(['need_list', true]);
        if (this.activeIndex === 1) {
            options.push(['archived', true]);
            options.push(['ecosystemOfferArchived', true]);
        }
        if (this.searchKey) {
            options.push(['search', this.searchKey]);
        }
        if (this.orderBy) {
            options.push(['sort', this.orderBy]);
        }
        if (!!this.createdDateFrom) {
            options.push(['created_from', this.createdDateFrom]);
        }
        if (!!this.createdDateTo) {
            options.push(['created_to', this.createdDateTo]);
        }
        if (!!this.deliveryDateFrom) {
            options.push(['delivery_from', this.deliveryDateFrom]);
        }
        if (!!this.deliveryDateTo) {
            options.push(['delivery_to', this.deliveryDateTo]);
        }
        if (this.statusList.some(function (status) { return status.selected; })) {
            this.statusList.forEach(function (status) {
                if (status.selected) {
                    options.push(['status[]', status.value]);
                }
            });
        }
        else {
            this.getCorrectStatusList().forEach(function (status) {
                options.push(['status[]', status.value]);
            });
        }
        this.recipientList.forEach(function (recipient) {
            if (recipient.selected) {
                options.push(['client[]', recipient.value]);
            }
        });
        this.deliveryLocationList.forEach(function (location) {
            if (location.selected) {
                options.push(['location[]', location.value]);
            }
        });
        if (this.isTargetedOnly) {
            options.push(['from_any_deposit', true]);
        }
        if (this.isNeedWithOfferOnly) {
            options.push(['with_offer_only', true]);
        }
        this.depositLocationList.forEach(function (location) {
            if (location.selected) {
                options.push(['from_deposit', true]);
                options.push(['deposit_location[]', location.value]);
            }
        });
        if (!this.offerStatusList.every(function (status) { return status.selected; }) && this.offerStatusList.some(function (status) { return status.selected; })) {
            this.offerStatusList.forEach(function (status) {
                if (status.selected) {
                    options.push(['need_offer_status[]', status.value]);
                }
            });
        }
        else {
            this.getCorrectOfferStatusList().forEach(function (status) {
                options.push(['need_offer_status[]', status.value]);
            });
        }
        return options;
    };
    return NeedFilterComponent;
}());
export { NeedFilterComponent };
