import { Component, EventEmitter, Output, Input } from '@angular/core';
import { CountryService } from '../../../shared/services/country.service';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
  selector: 'app-data-representativity-filter',
  templateUrl: './data-representativity-filter.component.html',
  styleUrls: ['./data-representativity-filter.component.scss']
})
export class DataRepresentativityFilterComponent {

  @Input() representativitySelected: string[] = [];
  @Output() representativityChange = new EventEmitter<string[]>();
  countries: string[];

  constructor(public countryService: CountryService, private _navigation: NavigationService) { }

  ngOnInit() {
    let role = this._navigation.getContext().roleText;
    const requestObservable = this.countryService.getlistCountriesByUser(role);

    requestObservable.subscribe({
      next: (response: any) => {
        const body = JSON.parse(response._body);
        this.countries = body.map(country => country.name + ' - ' + country.iso);
        this.countries.push('Unknown');
      },
      error: (error) => {
        console.error('Error fetching countries', error);
      }
    });
  }

  handleSelectionChange(selectedProviders: string[]) {
    this.representativityChange.emit(selectedProviders);
  }
}
