

import { EntityResolver } from '../../../../shared/resolvers/entity.resolver';
import { UserService } from '../../../../shared/services/user.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { User } from '../../../../shared/models/user.class';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { ICrud } from '../../../../shared/interfaces/crud.interface';
import { ILiteral } from 'ng2-adn-common';

@Injectable()
export class EditUserResolver extends EntityResolver<User> implements Resolve<User> {

  protected query: ILiteral = {'expand': 'roles_with_parent'};

  constructor(protected _service: UserService) {
    super();
  }
}
