/**
 * Created by aleksandr on 24.03.17.
 */

import { IModuleNavigation } from '../../shared/interfaces/module-navigation.interface';

export const SALES_NAVIGATION: IModuleNavigation[] = [{
  url: '/sales/needs',
  title: 'Needs',
  icon:  'list-of-needs--icon'
},
{
  url: '/sales/deposits',
  title: 'Deposits',
  icon: 'my-deposits--icon'
}, {
  url: '/sales/directory',
  title: 'Directory',
  icon: 'directory--icon'
},
/*
{
  url: '/sales/circular-passports',
  title: 'Circular passports',
  icon: 'circular-passports--icon'
}*/

];
