import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MAIN_TAB_STEPS } from '../../../admin-reporting.const';
import { ReportBase } from '../../report-base/report-base.class';
import { TranslateService } from '@ngx-translate/core';
import { ECarbonFootprintReportType } from '../../../../../../../dashboards/admin/values/carbon-footprint-status.enum';
import { convertUnit } from '../../../../../../../helper/Unithelper';
var CarbonFootprintReportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CarbonFootprintReportComponent, _super);
    function CarbonFootprintReportComponent(_translate) {
        var _this = _super.call(this) || this;
        _this._translate = _translate;
        _this.filteredProductCategory = {};
        _this.isFootprintDescriptionVisible = false;
        return _this;
    }
    CarbonFootprintReportComponent.prototype.ngOnInit = function () {
        this.aggregateData = this.reports.report_result.carbon_foot_print.aggregate;
        this.setCarbonFootprintProductCategories();
    };
    CarbonFootprintReportComponent.prototype.toggleDescriptionVisibility = function () {
        this.isFootprintDescriptionVisible = !this.isFootprintDescriptionVisible;
    };
    /**
     * Check whether the category list is empty or not
     * @returns {Boolean} True if there is no category, false otherwise
     */
    CarbonFootprintReportComponent.prototype.isEmptyProductCategory = function () {
        return Object.keys(this.filteredProductCategory).length === 0;
    };
    /**
     * Set the filtered and sorted product category table
     * @todo Maybe it is not really useful to have two categories for the same thing, remove one of them for the next refacto
     */
    CarbonFootprintReportComponent.prototype.setCarbonFootprintProductCategories = function () {
        this.filteredAndSortedProductCategory = this.filteredProductCategory = this.getAndSortFootprintCategories();
    };
    /**
     * Get the sorted categories
     * Among the categories, only keep those that have at least one deposit taken into account in the report type
     * Then, sort the remaining categories by the highest footprint
     * @returns {ICarbonFootprintCategory[]} The category list sorted by the highest carbon value
     */
    CarbonFootprintReportComponent.prototype.getAndSortFootprintCategories = function () {
        var _this = this;
        var categories = Object.values(this.aggregateData.category);
        var discriminant = this.getDiscriminantProperty();
        return categories
            .filter(function (category) { return category[discriminant] > 0; })
            .sort(function (catA, catB) { return _this.getCarbonFootPrintFilterUnit(catB) - _this.getCarbonFootPrintFilterUnit(catA); });
    };
    /**
     * Get the property name which discriminates which categories should be kept depending on the report type
     * @returns {String}
     */
    CarbonFootprintReportComponent.prototype.getDiscriminantProperty = function () {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'deposits_considered';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'deposits_considered_product';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'deposits_considered_embodied';
            case ECarbonFootprintReportType.IC_COMPONENT:
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return 'deposits_considered_ic_comp_const';
        }
    };
    CarbonFootprintReportComponent.prototype.goToArchive = function () {
        this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
    };
    CarbonFootprintReportComponent.prototype.getCarbonFootPrintFilterUnit = function (deposit) {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return deposit.footprint;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return deposit.embodied_carbon_total;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return deposit.ic_component_total;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return deposit.ic_construction_total;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return deposit.product_and_construction_total;
        }
    };
    CarbonFootprintReportComponent.prototype.getCarbonFootprintCircleIndicatorData = function () {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return convertUnit({ value: this.aggregateData.total_carbon_footprint, unit: 'tons' });
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return convertUnit({ value: this.aggregateData.embodied_carbon_total, unit: 'tons' });
            case ECarbonFootprintReportType.IC_COMPONENT:
                return convertUnit({ value: this.aggregateData.ic_component_total, unit: 'kgs' });
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return convertUnit({ value: this.aggregateData.ic_construction_total, unit: 'kgs' });
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return convertUnit({ value: this.aggregateData.product_and_construction_total, unit: 'tons' });
        }
    };
    CarbonFootprintReportComponent.prototype.getCarbonFootprintDescriptionText = function () {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'PRODUCT_STAGE_DESCRIPTION_TEXT';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'EMBODIED_CARBON_DESCRIPTION_TEXT';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'IC_COMPONENT_DESCRIPTION_TEXT';
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return 'IC_CONSTRUCTION_DESCRIPTION_TEXT';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'PRODUCT_AND_CONSTRUCTION_DESCRIPTION_TEXT';
        }
    };
    CarbonFootprintReportComponent.prototype.getTotalConsideredDepositsByReportType = function () {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return this.aggregateData.total_deposit_considered;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return this.aggregateData.total_deposits_considered_embodied;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return this.aggregateData.total_deposits_considered_ic_comp_const;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return this.aggregateData.total_deposits_considered_ic_comp_const;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return this.aggregateData.total_deposits_considered_product;
        }
    };
    CarbonFootprintReportComponent.prototype.shouldDisplayPartialData = function () {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return this.aggregateData.partial_deposit_considered > 0;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return this.aggregateData.partial_deposits_considered_embodied > 0;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return this.aggregateData.partial_deposits_considered_ic_comp_const > 0;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return this.aggregateData.partial_deposits_considered_ic_comp_const > 0;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return this.aggregateData.partial_deposits_considered_product > 0;
        }
    };
    CarbonFootprintReportComponent.prototype.getTotalConsideredWeightByReportType = function () {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return this.aggregateData.total_weight_considered;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return this.aggregateData.total_weight_considered_embodied;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return this.aggregateData.total_weight_considered_ic_comp_const;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return this.aggregateData.total_weight_considered_ic_comp_const;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return this.aggregateData.total_weight_considered_product;
        }
    };
    CarbonFootprintReportComponent.prototype.getTypeTitleText = function () {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'Production (A1-A3)';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'Embodied (A1-A5 + B1-B5 + C)';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'Ic';
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return 'Ic';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'Production & Construction (A1-A5)';
        }
    };
    CarbonFootprintReportComponent.prototype.getIcSubItem = function () {
        switch (this.reportType) {
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'composant';
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return 'construction';
        }
    };
    CarbonFootprintReportComponent.prototype.getTypeOfAllDepositsText = function () {
        if (this.isReportTypeIcComponent()) {
            return 'Ic composant';
        }
        else if (this.isReportTypeIcConstruction()) {
            return 'Ic construction';
        }
        else {
            return this._translate.instant('Carbon footprint');
        }
    };
    CarbonFootprintReportComponent.prototype.getTypeDescriptionTitleText = function () {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return 'What does the Product stage carbon footprint measure?';
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return 'What does the Embodied carbon footprint measure?';
            case ECarbonFootprintReportType.IC_COMPONENT:
                return 'What does the Ic component measure?';
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return 'What does the Ic construction measure?';
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return 'What does the Product and construction process stage carbon footprint measure?';
        }
    };
    CarbonFootprintReportComponent.prototype.getDescriptionToggleImg = function () {
        if (this.isFootprintDescriptionVisible) {
            return "../assets/svg/carbon-footprint-report-arrow-down--icon.svg";
        }
        else {
            return "../assets/svg/carbon-footprint-report-arrow-up--icon.svg";
        }
    };
    CarbonFootprintReportComponent.prototype.isReportTypeIcComponent = function () {
        return this.reportType === ECarbonFootprintReportType.IC_COMPONENT;
    };
    CarbonFootprintReportComponent.prototype.isReportTypeIcConstruction = function () {
        return this.reportType === ECarbonFootprintReportType.IC_CONSTRUCTION;
    };
    CarbonFootprintReportComponent.prototype.isReportTypeOfIcConstructionOrComponent = function () {
        return this.isReportTypeIcComponent() || this.isReportTypeIcConstruction();
    };
    CarbonFootprintReportComponent.prototype.isCurrentLanguageEnglish = function () {
        return this._translate.currentLang === 'en';
    };
    CarbonFootprintReportComponent.prototype.isCurrentLanguageSpanish = function () {
        return this._translate.currentLang === 'es';
    };
    CarbonFootprintReportComponent.prototype.getTranslation = function () {
        var x = 0;
        var y = 0;
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                x = this.aggregateData.partial_deposit_considered;
                y = this.aggregateData.total_deposit_considered;
                break;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                x = this.aggregateData.partial_deposits_considered_embodied;
                y = this.aggregateData.total_deposits_considered_embodied;
                break;
            case ECarbonFootprintReportType.IC_COMPONENT:
                x = this.aggregateData.partial_deposits_considered_ic_comp_const;
                y = this.aggregateData.total_deposits_considered_ic_comp_const;
                break;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                x = this.aggregateData.partial_deposits_considered_ic_comp_const;
                y = this.aggregateData.total_deposits_considered_ic_comp_const;
                break;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                x = this.aggregateData.partial_deposits_considered_product;
                y = this.aggregateData.total_deposits_considered_product;
                break;
        }
        return this._translate.instant('CS_CARBON_MISSING_DATA', {
            X: x,
            Y: y
        }) + ". " + this._translate.instant('See pdf for more details');
    };
    return CarbonFootprintReportComponent;
}(ReportBase));
export { CarbonFootprintReportComponent };
