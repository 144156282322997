import { MdDialog, MdDialogRef } from '@angular/material';
import { Injectable } from '@angular/core';
import { SelectDialogComponent } from '../dialog/select-dialog/select-dialog.component';

@Injectable()
export class CategorySelect {
  constructor(private mdDialog: MdDialog) {
  }

  open(): MdDialogRef<any> {
    return this.mdDialog.open(SelectDialogComponent, {
      height: '80%',
      width: '80%',
    });
  }
}
