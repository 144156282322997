import { Injectable } from '@angular/core';
import {EcosystemProblem} from '../models/ecosystem-problem.model';
import {ReportProblemDialogComponent} from '../../../shared/report-problem-dialog/report-problem-dialog.component';
import {DIALOG_THEME} from '../../../shared/helpers/dialog-themes.const';
import {IReportProblemDialogInput} from '../../../shared/report-problem-dialog/values/report-problem-dialog-input.interface';
import {MdDialog, MdDialogRef} from '@angular/material';
import {EcosystemProblemService} from './ecosystem-problem.service';
import {EcosystemStep} from '../../../dashboards/ecosystem-manager/models/ecosystem-step.class';
import {EcosystemStepService} from './ecosystem-step.service';
import {ECOSYSTEM_STEP_TYPE} from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import {Observable} from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class EcosystemProblemUtilsService {

  constructor(private dialog: MdDialog) {
  }

  public openOrderProblemDialog(description: string, titleText = 'Order problem') {
    return this.dialog.open(ReportProblemDialogComponent, {
      ...DIALOG_THEME.PROBLEM_REPORT,
      data: {
        readOnly: true,
        problemDescription: description,
        title: titleText,
      } as IReportProblemDialogInput
    });
  }
}
