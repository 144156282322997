import * as tslib_1 from "tslib";
/**
 * Created by kirill on 13.07.17.
 */
import { ActiveRecord } from '../../models/active-record.class';
import { ChatService } from '../chat.service';
import { ServiceLocator } from '../../services/service-locator';
var ChatUser = /** @class */ (function (_super) {
    tslib_1.__extends(ChatUser, _super);
    function ChatUser() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.visible = true;
        _this._chatService = ServiceLocator.injector.get(ChatService);
        return _this;
    }
    Object.defineProperty(ChatUser.prototype, "unreadMessages", {
        get: function () {
            return this._room ? this._room.unreadMessages : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatUser.prototype, "thread", {
        get: function () {
            return this._room;
        },
        enumerable: true,
        configurable: true
    });
    ChatUser.prototype.fields = function () {
        return [
            'id',
            'user_id',
            'image',
            'name',
            'group',
            'object_name',
            'ecosystem_name',
            'contact_name',
            'contact_username',
            'thread_id',
            'new_messages',
            'avatar_url'
        ];
    };
    ChatUser.prototype.extractData = function (data) {
        //TODO temp
        if (data.hasOwnProperty('new_messages')) {
            data['new_messages'] = Number(data['new_messages']);
        }
        if (data.hasOwnProperty('user_id')) {
            data['id'] = data['user_id'];
        }
        if (data.hasOwnProperty('contact_name')) {
            data['name'] = data['contact_name'];
        }
        if (data.hasOwnProperty('contact_username')) {
            data['username'] = data['contact_username'];
        }
        if (data.hasOwnProperty('avatar_url')) {
            data['image'] = data['avatar_url'];
        }
        if (!data.hasOwnProperty('image')) {
            data['image'] = '../assets/png/profile.png';
        }
        else {
            if (data['image'] === null) {
                data['image'] = '../assets/png/profile.png';
            }
        }
        if (data.hasOwnProperty('role')) {
            data['group'] = data['role'];
        }
        // console.log(data);
        return _super.prototype.extractData.call(this, data);
    };
    ChatUser.prototype.isContextChat = function () {
        return !!this.ecosystem_name && !!this.thread_id;
    };
    ChatUser.prototype.createRoom = function () {
        var _this = this;
        var roomSource = this.isContextChat()
            ? this._chatService.getContextRoom(this.thread_id)
            : this._chatService.getPrivateRoom(this.id);
        return roomSource
            .do(function (room) { return _this.setRoom(room); })
            .flatMap(function (room) { return room.loadContacts().map(function (contacts) { return room; }); })
            .do(function (room) { return _this.joinRoom(); });
    };
    ChatUser.prototype.setRoom = function (room) {
        // console.log('Chat SET ROOM', room);
        room.unreadMessages = Number(this.new_messages);
        this._room = room;
        return this;
    };
    ChatUser.prototype.getRoom = function () {
        return this._room;
    };
    ChatUser.prototype.joinRoom = function () {
        this._room.connect();
    };
    ChatUser.prototype.exitRoom = function () {
        if (this._room) {
            this._room.disconnect();
        }
    };
    return ChatUser;
}(ActiveRecord));
export { ChatUser };
