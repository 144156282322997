/**
 * Created by aleksandr on 3.08.17.
 */
import { ILiteral } from 'ng2-adn-common';
import { FileItem } from 'ng2-file-upload/file-upload/file-item.class';
import { ParsedResponseHeaders } from 'ng2-file-upload';

export namespace Utils {
  export interface IHasCreatedAt {
    created_at: string;
  }
  export function dateComparator(a: IHasCreatedAt, b: IHasCreatedAt) {
    return (new Date(b.created_at).getTime()) - (new Date(a.created_at).getTime());
  }

  export function getTimeLeftString(date: string, translate: any): string {
    let str = '';
    let result = (+new Date()) - new Date(date).getTime() - 3 * 60 * 60 * 1000; //TODO: UNHARDCODE TIMEZONE

    let days = result / 1000 / 60 / 60 / 24;
    let hours = result / 1000 / 60 / 60;
    let minutes = result / 1000 / 60;
    let seconds = result / 1000;

    //console.log(this.created_at, result, days);

    if (days > 1) {
      str = days < 2 ?
        translate.instant('Yesterday') :
        translate.instant('days ago', {X: Math.floor(days)});

    } else if (hours > 1) {
      str = hours < 2 ?
        translate.instant('hour', {X: Math.floor(hours)}) :
        translate.instant('hours', {X: Math.floor(hours)});

    } else if (minutes > 1) {
      str = minutes < 2 ?
        translate.instant('minute', {X: Math.floor(minutes)}) :
        translate.instant('minutes', {X: Math.floor(minutes)});

    } else if (seconds > 1) {
      str = seconds < 2 ?
        translate.instant('second', {X: Math.floor(seconds)}) :
        translate.instant('seconds', {X: Math.floor(seconds)});

    } else {
      str = translate.instant('Just now');
    }

    return str;
  }

  export function convertJsonStringToNumber(json: ILiteral, key: string[]) {
    key.forEach((k) => {
      if(!!json[k] && typeof json[k] === 'string') {
        json[k] = Number(json[k]);
      }
    });
  }

  export function onUploadError(onError: Function) {
    return ((item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      console.log('file upload error:', response);
      let error: any = null;
      try {
        error = typeof response === 'string' ? JSON.parse(response) : response;
      } catch (e) {
        console.log(e);
      }
      if (error) {
        let message = error.message || error[0] && error[0].message;
        onError(message);
      }
    });
  }
  export function copyToClipBoard (text : string) {

      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';

      selBox.value = text;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);


  }

  export function formatDate($dateString) {
    return $dateString.replace(/\s/, 'T');
  }
}
