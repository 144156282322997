import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[stationaryclick]'
})
export class StationaryClickDirective {
  mousedownEvent: MouseEvent = new MouseEvent('click');

  @Output() stationaryclick = new EventEmitter();

  @HostListener('mousedown', ['$event']) mousedown($event: MouseEvent) {
    this.mousedownEvent = $event;
  }

  @HostListener('click', ['$event']) click($event: MouseEvent) {
    if (
      this.mousedownEvent.clientX === $event.clientX &&
      this.mousedownEvent.clientY === $event.clientY
    ) {
      this.stationaryclick.emit($event);
    }
  }

}
