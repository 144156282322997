<div class="row">
  <h2>{{ 'Upcyclea users' | translate }}</h2>
  <button routerLink="new" class="create-new-user">{{ 'Create new user' | translate }}</button>
</div>
<div class="row controls">
  <span>
    <i class="icon icon--gray-person"></i>
    <md-select placeholder="{{ 'Role' | translate }}" [(ngModel)]="role" class="role">
      <md-option>
        {{ 'Any' | translate }}
      </md-option>
      <md-option
        *ngFor="let USER_ROLE of USER_ROLES"
        [value]="USER_ROLE.value"
      >{{ USER_ROLE.view + 's' | translate }}</md-option>
    </md-select>
  </span>

  <span>
    <i class="icon icon--gray-sort"></i>
    <md-select placeholder="{{'Sort by' | translate}}" [(ngModel)]="sort" class="sort">
      <md-option value="name">{{ 'Name' | translate }}</md-option>
      <md-option value="-created_at">{{ 'Creation date' | translate }}</md-option>
    </md-select>
  </span>

  <span>
    <i class="icon icon--gray-search"></i>
    <md-input-container class="search">
      <input mdInput placeholder="{{ 'Search' | translate }}" autocomplete="off" [formControl]="searchControl">
    </md-input-container>
  </span>
</div>
<ul class="users">
  <li
    *ngFor="let user of users | paginate:{id:'users'
    , itemsPerPage: pageInfo.perPage
    , currentPage: pageInfo.currentPage
    , totalItems: pageInfo.totalCount }"
    [routerLink]="user.id + ''"
  >
    <div class="profile-image">
      <div [ngStyle]="getAvatar(user)"></div>
    </div>
    <div *ngIf="user.status==0"><strong>{{ 'INACTIVE' | translate }}</strong><br></div>
    <strong class="name">{{user.viewName}}</strong><br>
    <strong class="name">{{user.username}}</strong><br>
    <span class="address"></span><br>
    <a class="email">
      <i class="icon icon--gray-mail"></i>
      {{user.email}}
    </a>
    <a class="phone-number" *ngIf="user.phone">
      <i class="icon icon--gray-phone"></i>
      {{user.phone}}
    </a>
    <br>
    <a class="chat-with"
       (click)="openChat(user.id, $event)"
       *ngIf="user.id !== _currentUserService.infoData.id">
      <i class="icon icon--gray-chat"></i>
      {{ 'Chat'  | translate }}
    </a>
    <br>
    <hr>
    <div class="container">
      <a>{{ 'Roles' | translate }}:
        <span *ngFor="let role of user.roleText; let index = index">
          {{ role | translate }}{{ index === user.roleText.length - 1 ? '' : ','}}
        </span>
      </a>
    </div>
  </li>
</ul>
<pagination-controls
  class="custom-pagination"
  id="users"
  (pageChange)="pageControl.setValue($event)"
  maxSize="8"
  directionLinks="true"
  autoHide="true"
  previousLabel=""
  nextLabel=""
  screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
  screenReaderPageLabel="{{ 'page' | translate }}"
  screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"
></pagination-controls>
