<div class="lookup-table-item__wrapper">

    <div class="lookup-table-item__header-title">
        {{ getTableTitle() | translate }}
    </div>
    <div class="lookup-table-item__body-content ">
        <div *ngFor="let level of table.nbLevels; let i = index" class=" lookup-table-item__row lookup-table-item__odd-stripe">
            <div class="lookup-table-item__firstColumn">
                <div>
                    {{getRowNumber(level, i)}} - {{getRowTitle(level) | translate}}
                </div>

            </div>
            <div class="lookup-table-item__arrowColumn">
                <i class="icon x2 arrow-right--icon"></i>
            </div>
            <div class="lookup-table-item__secondColumn">
                <div class="lookup-table-item__tag_container">
                    <div *ngFor="let tag of table.valuesMap[level]">
                      <div class="lookup-table-item__tag-display background-primary-color deposit-tag__tag--active">
                        <span class="label">
                          {{tag.field_value}}
                        </span>
                        <button
                                class="options"
                                aria-label="tag options"
                                (click)="deleteSynonym(level, tag.id)"
                        >
                          <i class="icon close--icon"></i>
                        </button>
                      </div>
                    </div>
                      <md-input-container
                        [floatPlaceholder]="'never'"
                        class="lookup-table-item__tag-new"
                      >
                        <input
                          mdInput
                          autocomplete="off"
                          placeholder="{{'LOOKUP_TABLE_NEW_EQUIVALENCE' | translate}}"
                          [(ngModel)]="table.synonymModelMap[level]"
                          (keyup.enter)="addSynonym(level)"
                          (focusout)="onUnfocus(level)"
                        >
                      </md-input-container>
                  </div>
            </div>
        <div>
    </div>
</div>
