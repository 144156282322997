import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService extends ApiService {
  private namespace = 'agent';

  getPassports(page: number = 1, pageSize: number = 20, payload: any): Observable<any> {
    let queryString = `?page=${page}&pageSize=${pageSize}`;

    const transformPayload = (payload: any): any => {
      const transformKeys = ['materials', 'categories'];
      return Object.entries(payload).reduce((acc, [key, value]) => {
        if (transformKeys.includes(key) && Array.isArray(value)) {
          return { ...acc, [key]: value.map((item: any) => item.id) };
        }
        else if (typeof value === 'boolean') {
          return { ...acc, [key]: value ? 1 : 0 };
        }
        return { ...acc, [key]: value };
      }, {});
    };

    const transformedPayload = transformPayload(payload);

    const filteredPayload = Object.entries(transformedPayload)
      .filter(([key, value]) => value !== null && value !== '' && !(Array.isArray(value) && value.length === 0))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    const payloadQueryString = Object.keys(filteredPayload)
      .map(key => {
        const value = filteredPayload[key];
        return Array.isArray(value) ? value.map(item => `${key}[]=${encodeURIComponent(item)}`).join('&') : `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');

    if (payloadQueryString) {
      queryString += `&${payloadQueryString}`;
    }

    return this.sendGet(this.getEndpoint(`${this.namespace}/get-passports${queryString}`), { withCredentials: true })._catch(this.onError.bind(this));
  }

  getPassportCreator(): Observable<any> {
    return this.sendGet(this.getEndpoint(`${this.namespace}/get-list-passport-creators-by-user`), { withCredentials: true })._catch(this.onError.bind(this));
  }

  duplicatePassports(id: number, new_name: string): Observable<any> {
    let payload = {
      passportId: id,
      newPassportName: new_name
    };
    return this.sendPost(this.getEndpoint(`${this.namespace}/copy-passport`), payload, { withCredentials: true })
      .catch(this.onError.bind(this));
  }

  deletePassports(payload): Observable<any> {
    return this.sendPost(this.getEndpoint(`${this.namespace}/delete-passports`), payload, {withCredentials: true})
      .catch(this.onError.bind(this));
  }

}
