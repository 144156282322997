import { FormControl } from '@angular/forms';
import { CategoryItem } from '../../entities/category/models/category-item.class';
import { DepositLocation } from '../../entities/deposit/models/deposit-location.class';
import { NomenclatureItem } from '../../entities/nomenclature/models/nomenclature-item.class';
import {DepositTag} from '../../entities/deposit/models/deposit-tag.class';
import {PassportRating} from '../../entities/passport/models/passport-rating.class';
import {string} from 'yargs';
import {
  IPassportRatingWithLocalPassportFilter,
  TPassportFilter
} from '../../entities/passport/values/passport-rating/passport-rating-filter.interface';

export interface IPassportFilter {
  categoryTags: CategoryItem[];
  tags: NomenclatureItem[];
  createdBy: string;
  createdDateFrom: string;
  createdDateTo: string;
  orderBy: string;
  searchStatus: number[];
  search: FormControl;
  filterBy: TPassportFilter;
  loadSelfPassports: boolean;
  loadDraftPassports: boolean;
  version_filter: number;
  family: number[];
}

export interface FilterBy {
  C2cCertified: boolean;
  composition: { color: boolean, black: boolean };
  cycle: { color: boolean, black: boolean };
  energy: { color: boolean, black: boolean };
  water: { color: boolean, black: boolean };
  social: { color: boolean, black: boolean };
}

export const FILTER_BY_RATING_JUNCTION: {[key in keyof Partial<FilterBy>]: Partial<keyof PassportRating>} = {
    composition: 'rating_composition',
    cycle: 'rating_recyclability',
    water: 'rating_water_stewardship',
    social: 'rating_social_fairness',
    energy: 'rating_energy'
};

export const FILTER_BY_NO_JUNTION: {[key in keyof Partial<FilterBy>]: string} = {
  C2cCertified: 'c2c_certified'
};

export interface INextLivesOption {
  recycling_upcycling: boolean;
  composting: boolean;
  methanization: boolean;
  from_reuse: boolean;
  refurbishing: boolean;
  energy_recovery: boolean;
}

export enum ENextLive {
  recycling_upcycling = 5,
  composting = 7,
  methanization = 8,
  from_reuse = 1,
  refurbishing = 4,
  energy_recovery = 6,
}

export const NextLives = {
  [ENextLive.recycling_upcycling]: 'Recycling/Upcycling',
  [ENextLive.composting]: 'Composting',
  [ENextLive.methanization]: 'Methanization',
  [ENextLive.from_reuse]: 'From Reuse',
  [ENextLive.refurbishing]: 'Refurbishing',
  [ENextLive.energy_recovery]: 'Energy recovery',
};

export interface IStateOfUse {
  irrelevant: boolean;
  as_new: boolean;
  slightly_used: boolean;
  used: boolean;
  deteriorated: boolean;
}

export enum ConditionEnum {
  irrelevant = 1,
  as_new = 2,
  slightly_used = 3,
  used = 4,
  deteriorated = 5
}

export const Condition = {
  [ConditionEnum.irrelevant]: 'Irrelevant',
  [ConditionEnum.as_new]: 'As new',
  [ConditionEnum.slightly_used]: 'Slightly used',
  [ConditionEnum.used]: 'Used',
  [ConditionEnum.deteriorated]: 'Deteriorated'
};
export interface IDepositFilter {
  availabilityDateFrom: string;
  availabilityDateTo: string;
  orderBy: string;
  searchKey: string;
  searchControl: FormControl;
  searchStatus: number[];
  currentPage: number;
  isAllSelected: boolean;
  isOutOfStockSelected: boolean;
  stateOfUse: IStateOfUse;
  selectedSites: DepositLocation[];
  selectedTags?: DepositTag[];
  categoryTags: CategoryItem[];
  nextLives: INextLivesOption;
  isNoahVisible: boolean;
}
