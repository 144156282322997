export enum EResidualValueStatus {
  'deconstruction' = 'deconstruction',
  'rehabilitation' =  'rehabilitation',
  'renewal_building_commercial' = 'renewal_building_commercial',
  'renewal_building_residantial' = 'renewal_building_residantial',
  'renewal_building_institutional' = 'renewal_building_institutional'
}
export enum EResidualValueStatusEmpty {
  'empty' = 'empty'
}
