import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { UsedEnergy } from '../models/used-energy.class';
var UsedEnergyService = /** @class */ (function (_super) {
    tslib_1.__extends(UsedEnergyService, _super);
    function UsedEnergyService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'passport-energies';
        _this.namespaceSingular = 'passport-energies';
        _this.ModelClass = UsedEnergy;
        return _this;
    }
    return UsedEnergyService;
}(CrudService));
export { UsedEnergyService };
