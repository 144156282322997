import { Ecosystem } from '../../models/ecosystem.class';
import { SummaryDetailAbstract } from '../../models/summary-details.intercface';
import { ECOSYSTEM_CALC_CO2_ERRORS, ECOSYSTEM_CALC_CO2_WARNINGS, ERROR_TYPE_DISTANCE_CANNOT_BE_EVALUATED } from '../../values/ecosystem-calc-error.const';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { IEcosystemIndicatorReportSteps } from '../../values/ecosystem-indicator-report.const';
import { AbstractReportStrategyWithDetails } from './abstract-report-strategy.service';
import {EcosystemStep} from '../../models/ecosystem-step.class';
import {TRANSFORM_TYPE} from '../../../transformation-manager/values/transform-types.const';

export class CO2EmissionsStrategy extends AbstractReportStrategyWithDetails {
    fileType = ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_CO2;
    unit = 'kg eq. CO₂';

    getSteps(allSteps: IEcosystemIndicatorReportSteps): IEcosystemIndicatorReportSteps {
        return {
            virginProducts: allSteps.virginProducts,
            deposits: allSteps.deposits,
            transforms: this.emissionTransformSteps(allSteps.transforms),
            transports: allSteps.transports,
            needs: allSteps.needs
        };
    }

  private emissionTransformSteps(transforms: EcosystemStep[]): EcosystemStep[] {
    return transforms.filter(step => [TRANSFORM_TYPE.SORTING
      , TRANSFORM_TYPE.UPCYCLING
      , TRANSFORM_TYPE.METHANIZATION
      , TRANSFORM_TYPE.COMPOSTING].includes(step.transformation_type));
  }


    formatValue<T extends SummaryDetailAbstract>(step: T): string {
        return step.co2_emission.toFixed(2).toString();
    }
    isDataMissing<T extends SummaryDetailAbstract>(errors: Array<any>, step: T): boolean {
        if (!errors || !Array.isArray(errors) ) {
              return true;
            }
          let found = null ;
          for (let i = 0; i < errors.length; i++) {
              found = ECOSYSTEM_CALC_CO2_ERRORS.find(element => element.id === errors[i]);
              if (found && this.ifDistanceErrorHaveConfirmedDistance(found, step)) {
                  found = null;
              }
            if (found) {
              break;
            }
          }
          return !!found;
    }

    hasWarnings(warnings: Array<any>): boolean {
      if (!warnings || !Array.isArray(warnings)) {
        return false;
      }

      return warnings.some(warning => !!ECOSYSTEM_CALC_CO2_WARNINGS.find(warningData => warningData.id === warning));
    }

    getWarnings(warnings: Array<any>): Array<string> {
      if (!warnings || !Array.isArray(warnings) ) {
        return [];
      }

      return warnings.map(warning => ECOSYSTEM_CALC_CO2_WARNINGS.find(warningData => warningData.id === warning).title);
    }

    private ifDistanceErrorHaveConfirmedDistance(error, step) {
        return error.id === ERROR_TYPE_DISTANCE_CANNOT_BE_EVALUATED && !!step.confirmed_distance;
    }

    otherAdd(ecosystem: Ecosystem, value?: number) {
      this.setOtherTotal(value, (val) => ecosystem.add_other_CO2 = val);
      return ecosystem.add_other_CO2;
    }

    otherSubtract(ecosystem: Ecosystem, value?: number) {
      this.setOtherTotal(value, (val) => ecosystem.subtract_other_CO2 = val);
      return ecosystem.subtract_other_CO2;
    }

    otherDescription(ecosystem: Ecosystem, value?: string) {
      this.setOtherDescription(value, (val) => ecosystem.description_other_CO2 = val);
      return ecosystem.description_other_CO2;
    }

    getTotalErrors(ecosystem: Ecosystem) {
        return ecosystem.emissions_total.errors;
    }
}

