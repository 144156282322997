/**
 * Created by atat on 18/06/2017.
 */

import { Component, OnInit } from '@angular/core';
import {
  ECustomListHintClass,
  ECustomListRecordStatus,
  ICustomListRecord
} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { TransformLocationService } from '../../../../entities/transformation/services/transform-location.service';
import { TransformLocation } from '../../../../entities/transformation/models/transform-location.class';
import { MdDialog } from '@angular/material';
import { CreateTransformSiteDialogComponent } from '../../dialogs/create-transform-site-dialog/create-transform-site-dialog.component';
import { TransformSiteDetailsDialogComponent } from '../../dialogs/transform-site-details-dialog/transform-site-details-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'tsm-sites-catalog',
  templateUrl: 'tsm-sites-catalog.component.html'
})
export class TsmSitesCatalogComponent implements OnInit {
  records: ICustomListRecord[] = [];

  sortOptions: any = [
    { field: 'name', view: 'Name'}
  ];
  sortValue: string = 'name';

  // Filters
  search: string;

  constructor(private _sitesService: TransformLocationService,
              private _mdDialog: MdDialog,) {

  }

  getRecords() {
    this._sitesService
      .get({
        expand: 'creator,use_count',
        supervisor: false,
        search: this.search || null,
        sort: this.sortValue
      })
      .subscribe((data: TransformLocation[]) => {
        this.records = [];
        data.forEach((item) => this.addRecord(item));
      });
  }

  addRecord(site: TransformLocation) {
    //TSM / site list : no icons
    let status:ECustomListRecordStatus = ECustomListRecordStatus.NO_STATUS;
    this.records.push(
      this.createDepositRecord(
        status,
        ECustomListHintClass.NO_CLASS,
        '',
        site.name,
        site.address,
        'Transformation',
        site.use_count,
        site,
      )
    );
  }

  ngOnInit(): void {
    this.getRecords();
  }

  createDepositRecord(status: ECustomListRecordStatus,
                      hintCssClass: ECustomListHintClass,
                      hintText: string,
                      title: string,
                      subtitle: string,
                      amountLabel: string,
                      amount: number,
                      site: TransformLocation): CustomListRecord {
    return CustomListRecord
      .build()
      .setPrimaryKey(site.id)
      .setStatus(status)
      .setHint(hintText, hintCssClass)
      .addTitleColumn(title, subtitle)
      .addCounterColumn(amount, amountLabel)
      .addControlsColumn([{
        fn: (data: ICustomListRecord) => {
          this._mdDialog.open(TransformSiteDetailsDialogComponent, {
            data: {
              id: site.id,
              readOnly: false
            }
          }).afterClosed().subscribe(() => this.getRecords());
        },
        name: 'See details'
      }, {
        fn: (data: ICustomListRecord) => {
            site.build().destroy().subscribe(site => this.getRecords());
        },
        name: 'Delete'
      }]);
  }

  openCreateDialog() {
    let dialogRef = this._mdDialog.open(CreateTransformSiteDialogComponent, {
      width: '25%',
      height: '295px',
      data: {}
    });
    dialogRef.afterClosed().subscribe((site: TransformLocation) => {
      console.log('afterClosed', site);
      if (site instanceof TransformLocation) {
        this._sitesService
          .view(site.id, {
            expand: 'creator,use_count',
            supervisor: false,
          })
          .subscribe((site: TransformLocation) => {
            this.addRecord(site);
          });
      }
    });
  }

}
