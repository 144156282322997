import {User} from './user.class';

export abstract class AbstractPermission {
  static readonly id: string;

  get id() {
    return this.constructor && this.constructor['id'];
  }

  abstract isValid(user: User);

  isApplied(user: User) {
    try {
      return this.isValid(user);
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
