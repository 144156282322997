/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../deposit-custom-multi-select/deposit-custom-multi-select.component.ngfactory";
import * as i2 from "../deposit-custom-multi-select/deposit-custom-multi-select.component";
import * as i3 from "./deposit-filter-status.component";
var styles_DepositFilterStatusComponent = [];
var RenderType_DepositFilterStatusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DepositFilterStatusComponent, data: {} });
export { RenderType_DepositFilterStatusComponent as RenderType_DepositFilterStatusComponent };
export function View_DepositFilterStatusComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "deposit-custom-multi-select", [], null, [[null, "changeOption"], ["document", "click"], ["document", "focusin"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("document:focusin" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onFocus($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("changeOption" === en)) {
        var pd_2 = (_co.onSelectChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_DepositCustomMultiSelectComponent_0, i1.RenderType_DepositCustomMultiSelectComponent)), i0.ɵdid(1, 49152, null, 0, i2.DepositCustomMultiSelectComponent, [], { placeholder: [0, "placeholder"], allOptions: [1, "allOptions"], isSelectEmpty: [2, "isSelectEmpty"] }, { changeOption: "changeOption" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Status"; var currVal_1 = _co.filterStatuses; var currVal_2 = _co.isSelectEmpty; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_DepositFilterStatusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "deposit-filter-status", [], [[2, "depositlist__form-item-list-sub-item", null]], null, null, View_DepositFilterStatusComponent_0, RenderType_DepositFilterStatusComponent)), i0.ɵdid(1, 114688, null, 0, i3.DepositFilterStatusComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).justifyContent; _ck(_v, 0, 0, currVal_0); }); }
var DepositFilterStatusComponentNgFactory = i0.ɵccf("deposit-filter-status", i3.DepositFilterStatusComponent, View_DepositFilterStatusComponent_Host_0, { setFilterStatuses: "filterStatuses", searchStatus: "searchStatus", isOutOfStockSelected: "isOutOfStockSelected", isAllSelected: "isAllSelected", isSelectEmpty: "isSelectEmpty", defaultStatusValue: "defaultStatusValue" }, { searchStatusChange: "searchStatusChange", isOutOfStockSelectedChange: "isOutOfStockSelectedChange", isAllSelectedChange: "isAllSelectedChange", change: "change" }, []);
export { DepositFilterStatusComponentNgFactory as DepositFilterStatusComponentNgFactory };
