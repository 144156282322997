<div class="name-generation-dialog__wrapper">
  <div class="name-generation-dialog__close" (click)="close()">
    <i class="icon icon--close_s"></i>
  </div>
  <div class="name-generation-dialog__heading">{{ 'Generation of the product name' | translate }}</div>

  <div class="name-generation-dialog__content">

    <div class="name-generation-dialog__input"
         [formGroup]="nameGenerationFormGroup">

      <div class="name-generation-dialog__radio-group">
        <div class="name-generation-dialog__label primary-color">{{'Type of product' | translate}}</div>
        <div class="flex align-end">
          <md-radio-group
            formControlName="nameType"
            [(ngModel)]="passport.name_type"
          >
            <md-radio-button
              *ngFor="let type of ObjectValues(nameTypes);"
              [value]="type"
            >
              {{nameTypesView[type] | capitalize | translate}}
            </md-radio-button>
          </md-radio-group>
          <label class="form-group__label"
                 *ngIf="viewMode == VIEW_MODE.DEFAULT"
                 md-tooltip="{{ 'PASSPORT_NAME_CREATION_TOOLTIP_TOP' | translate }}{{'\n\n'}}{{ 'PASSPORT_NAME_CREATION_TOOLTIP_BOTTOM' | translate }}"
          >
            <i class="form-group__label--question">?</i>
          </label>
        </div>

      </div>

      <!--      mandatory field for both states-->
      <div class="name-generation-dialog__generic-names"
           (click)="onControlClick($event);">
        <md-input-container class="name-generation-dialog__field">
          <input
            autocomplete="off"
            mdInput
            [(ngModel)]="passport.name"
            [value]="passport.name"
            formControlName="passportName"
            placeholder="{{ 'Generic name' | translate }} *"
          >
        </md-input-container>

        <md-input-container class="name-generation-dialog__field">
          <input
            autocomplete="off"
            mdInput
            [(ngModel)]="passport.english_name"
            formControlName="passportEnglishName"
            placeholder="{{ 'English version of the generic name' | translate }} *"
          >
        </md-input-container>

        <div class="name-generation-dialog__translate-button-wrapper" (click)="translateNameToEnglish()"
             [ngClass]="{'background-primary-color': translationIsPossible()}">
          <i class="name-generation-dialog__translate-button-wrapper--earth-icon icon icon--terra-blanc"></i>
          <a class="name-generation-dialog__translate-button-wrapper--translate-link">{{ 'Translate' | translate }}</a>
        </div>

      </div>

      <!--      Catalogue products-->
      <section *ngIf="passport.isCatalogueProduct()">
        <div class="name-generation-dialog__field"
             (click)="onControlClick($event);">
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              [(ngModel)]="passport.manufacturer"
              formControlName="manufacturer"
              [value]="passport.manufacturer || ''"
              placeholder="{{ 'Name of the manufacturer' | translate }}"
            >
          </md-input-container>
        </div>
        <div class="name-generation-dialog__field"
             (click)="onControlClick($event);">
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              formControlName="tradeName"
              [(ngModel)]="passport.trade_name"
              [value]="passport.trade_name || ''"
              placeholder="{{ 'Trade name of the product' | translate }}"
            >
          </md-input-container>
        </div>

        <div class="name-generation-dialog__field"
             (click)="onControlClick($event);">
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              formControlName="productRefrence"
              [value]="passport.product_refrence || ''"
              [(ngModel)]="passport.product_refrence"
              placeholder="{{ 'Product reference (if different from the trade name)' | translate }}"
            >
          </md-input-container>
        </div>
      </section>

      <section *ngIf="passport.isCustomProduct()">
        <div class="name-generation-dialog__field"
             (click)="onControlClick($event);">
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              formControlName="typology"
              [value]="passport.typology || ''"
              [(ngModel)]="passport.typology"
              placeholder="{{ 'Typology' | translate }}"
            >
          </md-input-container>
        </div>
        <div class="name-generation-dialog__field"
             (click)="onControlClick($event);">
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              formControlName="passportFormat"
              [value]="passport.format || ''"
              [(ngModel)]="passport.format"
              placeholder="{{ 'Format/ Dimensions' | translate }}"
            >
          </md-input-container>
        </div>

        <div class="name-generation-dialog__field"
             (click)="onControlClick($event);">
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              formControlName="additional"
              [value]="passport.additional || ''"
              [(ngModel)]="passport.additional"
              placeholder="{{ 'Additional information' | translate }}"
            >
          </md-input-container>
        </div>
      </section>

      <ul class="button-group__list name-generation-dialog__button-group">
        <li class="button-group__item name-generation-dialog__button">
          <div class="name-generation-dialog__field" (click)="onControlClick($event);">
            <md-input-container>

              <input mdInput autocomplete="off" formControlName="version" [value]="passport.version"
                [(ngModel)]="passport.version" placeholder="{{ 'Product version' | translate }} *">
            </md-input-container>
          </div>
        </li>
        <li class="button-group__item name-generation-dialog__button">
          <label class="form-group__label" mdTooltip="{{ 'PASSPORT_VERSION_TOOLTIP' | translate }}">
            <i class="form-group__label--question">?</i>
          </label>
        </li>
      </ul>

    </div>

  </div>

  <footer class="controls name-generation-dialog__footer">
    <ul class="button-group__list name-generation-dialog__button-group">
      <li class="button-group__item name-generation-dialog__button" *ngIf="selectExistingPassportAvailable">
        <button
          md-button md-raised-button
          (click)="onSelectAnExistingPassport($event)">{{ 'Select an existing passport' | translate }}
        </button>
      </li>
      <li class="button-group__item name-generation-dialog__button">
        <button
                md-button md-raised-button
                (click)="validate()">{{ 'Validate' | translate }}
        </button>
      </li>
    </ul>
  </footer>

</div>
