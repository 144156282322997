import * as tslib_1 from "tslib";
import { LandfillVariablesService } from './landfill-variables.service';
import { ActiveRecord } from '../../../../shared/models/active-record.class';
var LandfillVariables = /** @class */ (function (_super) {
    tslib_1.__extends(LandfillVariables, _super);
    function LandfillVariables() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = LandfillVariablesService;
        return _this;
    }
    LandfillVariables.prototype.fields = function () {
        return ['id', 'value', 'name', 'currency', 'zone_id'];
    };
    return LandfillVariables;
}(ActiveRecord));
export { LandfillVariables };
