<div class="esm-catalog">
  <map-view
    *ngIf="mapIsReady"
    [markers]="markers"
  ></map-view>
  <header class="esm-catalog__header esm-catalog__header--end">
    <div class="esm-catalog__map">
      <i class="icon icon--export"></i>
      <a [href]="downloadPdfReport()" class="pdf-link esm-catalog__map-view" target="_blank">{{ 'Export' | translate }}</a>
    </div>
  </header>
  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list">
      <li class="esm-catalog__choice-item search">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input test-id="search"
                 autocomplete="off" 
                 mdInput
                 (input)="searchSubject.next($event.target.value)"
                 placeholder="{{ 'Search' | translate }}"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput
                 autocomplete="off" 
                 ng2-datetime-picker
                 date-only="true"
                 date-format="DD-MM-YYYY"
                 placeholder="{{'Expires FROM' | translate}}"
                 [(ngModel)]="expiresFrom"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-dash">-</li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput
                 autocomplete="off" 
                 ng2-datetime-picker
                 date-only="true"
                 date-format="DD-MM-YYYY"
                 placeholder="{{ 'Expires TO' | translate }}"
                 [(ngModel)]="expiresTo"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--people-grey"></i>
        <md-select
          class="select-group"
          placeholder="{{ 'Need manager' | translate }}"
          [(ngModel)]="salesId"
        >
          <md-option>{{'Any' | translate}}</md-option>
          <md-option
            *ngFor="let sales of saleses"
            [value]="sales.id"
          >{{sales.name}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--gray-sort"></i>
        <md-select class="select-group"
                   placeholder="{{ 'Sort by' | translate }}"
                   [(ngModel)]="currentSortValue"
        >
          <md-option
            *ngFor="let sValue of sortValue"
            [value]="sValue.field"
          >{{ sValue.view | translate }}
          </md-option>
        </md-select>
        <!--(ngModelChange)="loadDeposits()"-->
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [cloudIcon]="true"
  ></custom-list>
</div>
