import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BaseCheckboxComponent } from './checkbox/base-checkbox.component';
import { PaginationComponent } from './Page/pagination.component';
import { BaseDialogComponent } from './dialog/base-dialog.component';
import { IconsModule } from '../icons/../../icons.module';
import { CreateButtonComponent } from '../../../dashboards/manufacturer/components/create-button.component';
import { BaseToggleComponent } from './toggle/base-toggle.component';
import { TranslationService } from '../../../shared/services/translation.service';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    BaseCheckboxComponent,
    PaginationComponent,
    BaseDialogComponent,
    CreateButtonComponent,
    BaseToggleComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    IconsModule
  ],
  providers: [
    TranslationService
  ],
  exports: [
    BaseCheckboxComponent,
    PaginationComponent,
    BaseDialogComponent,
    CreateButtonComponent,
    BaseToggleComponent
  ]
})
export class BaseComponentsModule { }
