/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i3 from "@angular/material";
import * as i4 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i5 from "../../../../shared/read-only/read-only.service";
import * as i6 from "./nomenclature-change-confirmation-dialog.component";
import * as i7 from "../../service/nomenclature.service";
import * as i8 from "../../../../shared/services/log-notification.service";
var styles_NomenclatureChangeConfirmationDialogComponent = [];
var RenderType_NomenclatureChangeConfirmationDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NomenclatureChangeConfirmationDialogComponent, data: {} });
export { RenderType_NomenclatureChangeConfirmationDialogComponent as RenderType_NomenclatureChangeConfirmationDialogComponent };
export function View_NomenclatureChangeConfirmationDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "nomenclature-change-confirmation-dialog"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h3", [["class", "title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "button", [["class", "ok"], ["md-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MdButton_0, i2.RenderType_MdButton)), i0.ɵdid(6, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(7, 180224, null, 0, i3.MdButton, [i0.ElementRef, i0.Renderer, i3.FocusOriginMonitor], null, null), i0.ɵdid(8, 16384, null, 0, i3.MdButtonCssMatStyler, [], null, null), i0.ɵdid(9, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i0.ɵeld(10, 0, null, 0, 2, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Your request for a change in the nomenclature has been taken into account. It will be processed shortly.")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 7).disabled; var currVal_2 = true; var currVal_3 = ((i0.ɵnov(_v, 9).readOnlyService == null) ? null : i0.ɵnov(_v, 9).readOnlyService.readOnly); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("Ok")); _ck(_v, 11, 0, currVal_4); }); }
export function View_NomenclatureChangeConfirmationDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NomenclatureChangeConfirmationDialogComponent_0, RenderType_NomenclatureChangeConfirmationDialogComponent)), i0.ɵdid(1, 49152, null, 0, i6.NomenclatureChangeConfirmationDialogComponent, [i3.MdDialogRef, i7.NomenclatureService, i8.LogNotificationService], null, null)], null, null); }
var NomenclatureChangeConfirmationDialogComponentNgFactory = i0.ɵccf("ng-component", i6.NomenclatureChangeConfirmationDialogComponent, View_NomenclatureChangeConfirmationDialogComponent_Host_0, {}, {}, []);
export { NomenclatureChangeConfirmationDialogComponentNgFactory as NomenclatureChangeConfirmationDialogComponentNgFactory };
