var _a, _b;
export var FILTER_BY_RATING_JUNCTION = {
    composition: 'rating_composition',
    cycle: 'rating_recyclability',
    water: 'rating_water_stewardship',
    social: 'rating_social_fairness',
    energy: 'rating_energy'
};
export var FILTER_BY_NO_JUNTION = {
    C2cCertified: 'c2c_certified'
};
export var ENextLive;
(function (ENextLive) {
    ENextLive[ENextLive["recycling_upcycling"] = 5] = "recycling_upcycling";
    ENextLive[ENextLive["composting"] = 7] = "composting";
    ENextLive[ENextLive["methanization"] = 8] = "methanization";
    ENextLive[ENextLive["from_reuse"] = 1] = "from_reuse";
    ENextLive[ENextLive["refurbishing"] = 4] = "refurbishing";
    ENextLive[ENextLive["energy_recovery"] = 6] = "energy_recovery";
})(ENextLive || (ENextLive = {}));
export var NextLives = (_a = {},
    _a[ENextLive.recycling_upcycling] = 'Recycling/Upcycling',
    _a[ENextLive.composting] = 'Composting',
    _a[ENextLive.methanization] = 'Methanization',
    _a[ENextLive.from_reuse] = 'From Reuse',
    _a[ENextLive.refurbishing] = 'Refurbishing',
    _a[ENextLive.energy_recovery] = 'Energy recovery',
    _a);
export var ConditionEnum;
(function (ConditionEnum) {
    ConditionEnum[ConditionEnum["irrelevant"] = 1] = "irrelevant";
    ConditionEnum[ConditionEnum["as_new"] = 2] = "as_new";
    ConditionEnum[ConditionEnum["slightly_used"] = 3] = "slightly_used";
    ConditionEnum[ConditionEnum["used"] = 4] = "used";
    ConditionEnum[ConditionEnum["deteriorated"] = 5] = "deteriorated";
})(ConditionEnum || (ConditionEnum = {}));
export var Condition = (_b = {},
    _b[ConditionEnum.irrelevant] = 'Irrelevant',
    _b[ConditionEnum.as_new] = 'As new',
    _b[ConditionEnum.slightly_used] = 'Slightly used',
    _b[ConditionEnum.used] = 'Used',
    _b[ConditionEnum.deteriorated] = 'Deteriorated',
    _b);
