import * as tslib_1 from "tslib";
import { ApiService } from '../../../shared/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../../../shared/services/log-notification.service";
import * as i3 from "../../../shared/services/navigation.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/services/mock/mock-api.service";
var SpecialAdminService = /** @class */ (function (_super) {
    tslib_1.__extends(SpecialAdminService, _super);
    function SpecialAdminService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'special-admin';
        return _this;
    }
    SpecialAdminService.prototype.getPassports = function (page, pageSize, payload) {
        if (page === void 0) { page = 1; }
        if (pageSize === void 0) { pageSize = 20; }
        var queryString = "?page=" + page + "&pageSize=" + pageSize;
        // Transformer les champs spécifiques (NomenclatureItem et CategoryItem) en tableaux d'ID
        var transformPayload = function (payload) {
            var transformKeys = ['materials', 'categories']; // Clés à transformer
            return Object.entries(payload).reduce(function (acc, _a) {
                var key = _a[0], value = _a[1];
                var _b, _c, _d;
                if (transformKeys.includes(key) && Array.isArray(value)) {
                    // Transformer chaque élément du tableau en son ID
                    return tslib_1.__assign({}, acc, (_b = {}, _b[key] = value.map(function (item) { return item.id; }), _b));
                }
                else if (typeof value === 'boolean') {
                    return tslib_1.__assign({}, acc, (_c = {}, _c[key] = value ? 1 : 0, _c));
                }
                return tslib_1.__assign({}, acc, (_d = {}, _d[key] = value, _d));
            }, {});
        };
        var transformedPayload = transformPayload(payload);
        // Filtrer les valeurs nulles, les chaînes vides et les tableaux vides
        var filteredPayload = Object.entries(transformedPayload)
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            return value !== null && value !== '' && !(Array.isArray(value) && value.length === 0);
        })
            .reduce(function (acc, _a) {
            var key = _a[0], value = _a[1];
            var _b;
            return (tslib_1.__assign({}, acc, (_b = {}, _b[key] = value, _b)));
        }, {});
        // Construire la chaîne de requête avec les valeurs filtrées
        var payloadQueryString = Object.keys(filteredPayload)
            .map(function (key) {
            var value = filteredPayload[key];
            return Array.isArray(value) ? value.map(function (item) { return key + "[]=" + encodeURIComponent(item); }).join('&') : key + "=" + encodeURIComponent(value);
        })
            .join('&');
        if (payloadQueryString) {
            queryString += "&" + payloadQueryString;
        }
        return this.sendGet(this.getEndpoint(this.namespace + "/get-passports" + queryString), { withCredentials: true });
    };
    SpecialAdminService.prototype.getPassportCreator = function () {
        return this.sendGet(this.getEndpoint(this.namespace + "/get-list-passport-creators-by-user"), { withCredentials: true })._catch(this.onError.bind(this));
    };
    SpecialAdminService.ngInjectableDef = i0.defineInjectable({ factory: function SpecialAdminService_Factory() { return new SpecialAdminService(i0.inject(i1.Http), i0.inject(i2.LogNotificationService), i0.inject(i3.NavigationService), i0.inject(i4.TranslateService), i0.inject(i5.MockApiService)); }, token: SpecialAdminService, providedIn: "root" });
    return SpecialAdminService;
}(ApiService));
export { SpecialAdminService };
