import {CurrentUser} from '../../../shared/auth/current-user.class';
import {DepositTagService} from '../services/deposit-tag.service';
import {ActiveRecord} from '../../../shared/models/active-record.class';
import {Deposit} from './deposit.class';
import {DepositLocation} from './deposit-location.class';


export class DepositTag extends ActiveRecord {
  id: number;
  site_id: number;
  user_id: number;
  name: string;
  deposit_count: number;
  junctions: any;
  deposits: Deposit[];
  sites: DepositLocation;

  protected provider = DepositTagService;

  protected fields(): string[] {
    return [
      'id',
      'site_id',
      'user_id',
      'name',
      'deposit_count',
      'junctions',
      'deposits',
      'sites'
    ];
  }

  constructor(data?: any) {
    super(data);
    if (!!this.deposits) {
      this.deposits = this.deposits.map(deposit => new Deposit(deposit));
    }
    if (!!this.sites) {
      this.sites = new DepositLocation(this.sites);
    }
  }

  get editable() {
    return CurrentUser.infoData.id === this.user_id;
  }
}
