/**
 * Created by atat on 18/06/2017.
 */

import { Component, OnInit } from '@angular/core';
import {
  ECustomListHintClass,
  ECustomListRecordStatus,
  ECustomListUnit,
  ICustomListKeyPair,
  ICustomListRecord
} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
import { Deposit } from '../../../../entities/deposit/models/deposit.class';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DEPOSIT_STATUSES } from '../../../../entities/deposit/values/deposit-statuses.const';
import { MdDialog } from '@angular/material';
import { DepositDetailsDialogComponent } from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import { PASSPORT_STATUS } from '../../../../entities/passport/values/passport-status.const';
import { Searchable } from '../../../../shared/models/searchable.class';
import { IDepositStatusCheckbox } from '../../../../entities/deposit/models/deposit-status-checkbox.interface';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import {DatePipe} from "@angular/common";
import {TranslatePipe} from '@ngx-translate/core';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'do-deposits-catalog',
  templateUrl: 'do-deposits-catalog.component.html'
})
export class DoDepositsCatalogComponent implements OnInit {
  records: ICustomListRecord[] = [];

  filterStatuses: IDepositStatusCheckbox[] = [
    {depositStatus: DEPOSIT_STATUSES.WAITING_FOR_VALIDATION, checked: true, text: 'WAITING_FOR_VALIDATION'},
    {depositStatus: DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION, checked: false, text: 'WAITING_FOR_PUBLICATION'},
    {depositStatus: DEPOSIT_STATUSES.PUBLISHED, checked: true, text: 'PUBLISHED'},
    {depositStatus: DEPOSIT_STATUSES.ASK_FOR_MODIFICATION, checked: true, text: 'ASK_FOR_MODIFICATION'},
  ];

  sortOptions: any = [
    { field: '-created_at', view: 'Creation date'},
    { field: 'agent', view: 'Agent'},
    { field: 'availability_date', view: 'Availability date'},
    { field: 'name', view: 'Name'},
  ];
  sortValue: string = '-created_at';

  //Filters
  agentContactList: Searchable[] = [];
  agentContact: number;
  search = new FormControl();
  // searchStatus: number[] = this.filterStatuses.map((status) => status.value);

  isNextPageLoading = false;
  currentPage = 1;
  totalPagesCount = 0;

  PASSPORT_STATUS = PASSPORT_STATUS;

  constructor(private _depositService: DepositService,
              private mdDialog: MdDialog,
              private _globalEvents: GlobalEventsManager,
              private _currentUserService: CurrentUserService,
              private datePipe: DatePipe,
              private translatePipe: TranslatePipe,
              private navBarState: NavbarStateService) {

    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => this.getRecords())
    ;
  }

  getRecords(forPage: number = 0) {
    if (!this.filterStatuses.find((status: IDepositStatusCheckbox) => status.checked)) {
      this.records = [];
      return;
    }

    let options: any = {
      'deposit_owner': 1,
      'supervisor': 1,
      'expand': 'passport,creator,deposit_location',
      // 'nopaginate': 1,
      'page': forPage,
      'search': this.search.value || null
    };

    if (this.sortValue) {
      options.sort = this.sortValue;
    }

    if (this.agentContact) {
      options.creator_id = this.agentContact;
    }

    if(this.navBarState.multilanguage) {
      options.english = true;
    }

    options['status[]'] = this.filterStatuses
      .filter((status: IDepositStatusCheckbox) => status.checked)
      .map((status: IDepositStatusCheckbox) => status.depositStatus.value);

    this.isNextPageLoading = true;
    this._depositService
      .getWithPaginationData(options)
      .subscribe((res) => {
        if (forPage === 0) {
          this.records = [];
        }
        let headers = res.headers;
        this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
        this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
        res.json()
          .map((item: any) => this._depositService.buildModel(item))
          .forEach((deposit: Deposit) => this.addRecord(deposit));
        this.isNextPageLoading = false;
      });
  }

  onEndOfListTriggered() {
    if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount)  {
      this.getRecords(this.currentPage + 1);
    }
  }

  addRecord(data: Deposit) {

    // -blue hand for waiting for validation
    // -nothing for waiting for publication
    // -green check for published
    // -purple ! for ask for a modification
    let status:ECustomListRecordStatus;
    switch (data.status) {
      case DEPOSIT_STATUSES.WAITING_FOR_VALIDATION.value:
        status = ECustomListRecordStatus.MANUAL;
        break;
      case DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION.value:
        status = ECustomListRecordStatus.NO_STATUS;
        break;
      case DEPOSIT_STATUSES.PUBLISHED.value:
        status = ECustomListRecordStatus.CONFIRM;
        break;
      case DEPOSIT_STATUSES.ASK_FOR_MODIFICATION.value:
        status = ECustomListRecordStatus.IMPORTANT;
        break;
      default:
        status = ECustomListRecordStatus.NO_STATUS;
    }


    let hintCssClass = ECustomListHintClass.NO_CLASS;
    let hintText = '';
    //TODO figure out if this should be used as subtitle
    let passportName = '';
    let units = ECustomListUnit.PCS;

    if (data.passport) {
      // if (data.passport.updated_at) {
      //   let passportDate = new Date(data.passport.updated_at);
      //   let depositDate = new Date(data.updated_at);
      //
      //   if (passportDate > depositDate) {
      //     hintCssClass = ECustomListHintClass.INFO;
      //     hintText = 'New circular passport';
      //   }
      // }
      if (data.passport.status === PASSPORT_STATUS.DRAFT) {
        hintCssClass = ECustomListHintClass.INFO;
        hintText = 'New circular passport';
      }

      passportName = data.passport.name;
      units = data.passport.customListUnit;
    }

    if (!this.agentContactList.find((creator: any) => creator.id === data.creator_id)) {
      this.agentContactList.push({id: data.creator_id, name: data.creator});
    }

    let createdDate = this.datePipe.transform(data.created_at,'dd-MM-yyyy');
    // let availabilityDate= this.datePipe.transform(data.availability_date,'DD-MM-YYYY');
    // if (data.created_at && data.created_at.length === 19) {
    //   createdDate = data.created_at.slice(0, 10);
    // }

    this.records.push(
      this.createDepositRecord(
        status,
        hintCssClass,
        hintText,
        data.name,
        passportName,
        data.quantity,
        units,
        data.conditionData ? data.conditionData.view.replace(/./, c => c.toUpperCase()) : '',
        createdDate,
        data.availability_date,
        data.location !== null ? data.location.name : '',
        data.creator,
        data,
      )
    );
  }

  ngOnInit(): void {
    this.getRecords();
  }

  searchChange() {
    this.getRecords();
  }

  createDepositRecord(status: ECustomListRecordStatus,
                      hintCssClass: ECustomListHintClass,
                      hintText: string,
                      title: string,
                      subtitle: string,
                      amount: number,
                      unit: ECustomListUnit,
                      unitStatus: string,
                      createdAt: string,
                      expiredAt: string,
                      sitename: string,
                      author: string,
                      deposit: Deposit,): CustomListRecord {

    let listColumn: ICustomListKeyPair[] = [{key: 'Created', value: createdAt}];
    let availableDate = expiredAt ? moment(expiredAt) : null;
    listColumn.push(!!availableDate && availableDate > moment()
      ? {
        key: 'Available in',
        value: availableDate.diff(moment(), 'days').toFixed() + ' ' + this.translatePipe.transform('days'),
        cssClass: 'important-value-secondary do-deposit-catalog column--availability'
      }
      : {key: '', value: 'AVAILABLE', cssClass: 'important-value'});

    let waitingValidationState = deposit.status === DEPOSIT_STATUSES.WAITING_FOR_VALIDATION.value;
    return CustomListRecord
      .build()
      .setPrimaryKey(deposit.id)
      .setStatus(status)
      .setHint(hintText, hintCssClass)
      .addTitleColumn(title, subtitle, null, deposit.passport.english_name)
      .addUnitColumn(amount, unit, unitStatus)
      .addListColumn(listColumn)
      .addListColumn([{key: 'Site', value: sitename}])
      .addAuthorColumn(author)
      .addControlsColumn([{
        fn: () => {
          this.mdDialog.open(DepositDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
            data: {
              id: deposit.id,
              readOnly: waitingValidationState,
              showValidationButtons: waitingValidationState,
            },
          }).afterClosed().subscribe(() => this.getRecords());
        },
        name: 'See details'
      },
        ... (this.isAgentMyself(deposit.creator_id) ? [{
        fn: (data: ICustomListRecord) => this.openChat(deposit.creator_id),
        name: 'Chat with agent'
      }] : []),
        ...(waitingValidationState ? [
          {
            fn: () => {
              deposit.publish().subscribe(() => {
                this.getRecords();
              });
            },
            name: 'Validate',
          },
          {
            fn: () => {
              deposit.reject().subscribe(() => {
                this.getRecords();
              });
            },
            name: 'Reject',
          }
        ] : [])
      ]);
  }

  isAgentMyself(agentId: number): boolean {
    return (this._currentUserService.infoData.id !== agentId);
  }

  openChat(targetId: number) {
    console.log('openChat', targetId);
    this._globalEvents.chatUserClicked$.emit(targetId);
  }
}
