export enum MAIN_TAB_STEPS {
  NEW_REPORT = 1,
  ARCHIVE = 2,
}

export enum SECONDARY_TAB_STEPS {
  CARBON_FOOT_PRINT = 1,
  CIRCULARITY = 2,
  MATERIAL_HEALTH = 3,
  FINANCIAL_RESIDE_VALUE = 4,
}
