import * as tslib_1 from "tslib";
import { Deposit } from '../models/deposit.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var DepositService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositService, _super);
    function DepositService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'deposits';
        _this.namespaceSingular = 'deposit';
        _this.ModelClass = Deposit;
        return _this;
    }
    DepositService.prototype.getFileUploadUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/photos');
    };
    DepositService.prototype.getDocUploadUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/files');
    };
    DepositService.prototype.getLogoUploadUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + "/" + id + "/logo");
    };
    DepositService.prototype.getPdfReportUrl = function (query) {
        var q = this.formQueryString(query);
        return this.getEndpoint(this.namespaceSingular + "/report" + (q ? '?' + q : ''));
    };
    DepositService.prototype.getXlsReportUrl = function (query) {
        var q = this.formQueryString(query);
        return this.getEndpoint(this.namespaceSingular + "/report_xls" + (q ? '?' + q : ''));
    };
    DepositService.prototype.getPdfReport = function (query) {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/report'), { search: query })
            .catch(this.onError.bind(this));
    };
    DepositService.prototype.getAvailableCount = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositService.prototype.getWaiting = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/waiting'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositService.prototype.getInEcosystem = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/in_ecosystem'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositService.prototype.getByRelevancy = function (needId, query) {
        if (query === void 0) { query = {}; }
        query.need_id = needId;
        return this.sendPost(this.getEndpoint(this.namespaceSingular + '/relevant'), query)
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositService.prototype.deleteFiltereds = function (query) {
        if (query === void 0) { query = {}; }
        return this.sendPost(this.getEndpoint(this.namespaceSingular + '/deleteall'), query)
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositService.prototype.removeLogo = function (id) {
        return this.sendDelete(this.getEndpoint(this.namespaceSingular + "/" + id + "/logo"))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositService.prototype.deleteAllDeposits = function () {
        return this.sendPost(this.getEndpoint(this.namespaceSingular + "/delete_all_by_agent"))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositService.prototype.massUpdate = function (data, query) {
        return this.sendPostWithArray(this.getEndpoint(this.namespaceSingular + "/mass_update"), data, query)
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositService.prototype.quickUpdate = function (data) {
        return this.sendPost(this.getEndpoint(this.namespaceSingular + "/quick_update"), data)
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    return DepositService;
}(ExtendedCrudService));
export { DepositService };
