import { EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { RequestFileService } from './request-file.service';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { first } from 'rxjs/operators';
import { defer } from 'rxjs';
var RequestFileSenderService = /** @class */ (function () {
    function RequestFileSenderService(service) {
        this.service = service;
        this.onUploadAll = new EventEmitter();
        this.onUploadError = new EventEmitter();
        this.files = [];
        this.fileUploader = new FileUploader({});
        this._isLoading = false;
        this.errorOnUpload = false;
    }
    Object.defineProperty(RequestFileSenderService.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RequestFileSenderService.prototype, "isEmptyQueue", {
        get: function () {
            return !this.fileUploader.queue.length && !this.files.length;
        },
        enumerable: true,
        configurable: true
    });
    RequestFileSenderService.prototype.uploadRequest = function (callback) {
        this.callback = callback;
        this._isLoading = true;
        if (!!this.fileUploader.getNotUploadedItems().length) {
            this.onUploadAll.emit();
        }
        else {
            this.callback(this.files);
            this.callback = null;
        }
    };
    RequestFileSenderService.prototype.onCompleteItem = function (file) {
        this.checkIfFileError(file);
    };
    RequestFileSenderService.prototype.checkIfFileError = function (file) {
        this.errorOnUpload = this.errorOnUpload || !file.id;
    };
    RequestFileSenderService.prototype.onAllItemsUploaded = function (files) {
        this._isLoading = false;
        if (this.errorOnUpload) {
            this.errorOnUpload = false;
            this.onUploadError.emit();
            return;
        }
        else {
            this.callback(files);
            this.callback = null;
        }
    };
    RequestFileSenderService.prototype.getDocumentUploadObservable = function () {
        if (!this.observable) {
            this.observable = this.documentUploadAsObservable();
        }
        return this.observable;
    };
    RequestFileSenderService.prototype.documentUploadAsObservable = function (isCompleteAfterSuccess) {
        var _this = this;
        if (isCompleteAfterSuccess === void 0) { isCompleteAfterSuccess = true; }
        var observer = new Subject();
        var completeObserver = function () {
            if (isCompleteAfterSuccess) {
                _this.observable = null;
                observer.complete();
            }
        };
        var next = function () {
            observer.next({});
            completeObserver();
        };
        var error = function () {
            observer.error(Observable.of({}));
            completeObserver();
        };
        return this.getObservable(observer, next, error);
    };
    RequestFileSenderService.prototype.getObservable = function (observer, next, error) {
        var _this = this;
        this.onUploadError.pipe(first()).subscribe(error);
        return defer(function () {
            setTimeout(function () { return _this.uploadRequest(next); });
            return observer;
        });
    };
    RequestFileSenderService.prototype.documentUploadObservableNext = function (files, isFailed) {
        if (isFailed) {
            this.onCompleteItem({});
        }
        this.onAllItemsUploaded(files);
    };
    return RequestFileSenderService;
}());
export { RequestFileSenderService };
