import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ImportValidatorsService} from '../../../../import-spreadsheet/services/import-validators.service';
import {ImportProgressService} from '../../../../import-spreadsheet/services/import-progress.service';
import {ImportConfigurationService} from '../../../../import-spreadsheet/services/import-configuration.service';
import {IMPORT_DOCUMENT_CONTROLS} from '../../../../import-spreadsheet/values/import-document-controls.const';
import {
  DEPOSIT_LOCATION_IMPORT_COLUMNS,
  DEPOSIT_LOCATION_IMPORT_CONTROL_META,
  DEPOSIT_LOCATION_IMPORT_CONTROL_OPTIONS
} from './values/deposit-location-controls';
import {TABLE_SYNONYM_AND_COLUMNS} from './values/deposit-location-columns.const';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {MdDialog} from '@angular/material';
import {cloneDeep} from 'lodash';
import {DepositImportWarningComponent} from '../../../agent/dialogs/deposit-import-warning/deposit-import-warning.component';
import {ImportSiteConfigurationService} from '../../../../import-spreadsheet/services/import-site-configuration.service';
import {TABLE_TYPES} from '../../../../import-spreadsheet/values/import-tables.const';
import {DepositLocationImportFileService} from '../../../../entities/deposit/services/deposit-location-import-file.service';
import {DepositFileService} from '../../../../entities/deposit/services/deposit-file.service';
import {DepositLocationImportFile} from '../../../../entities/deposit/models/deposit-location-import-file.class';
import {DepositLocationImport} from '../../../../entities/deposit/models/deposit-location-import.class';
import {ImportDeepstreamService} from '../../../../import-spreadsheet/services/import-deepstream.service';
import {ELastImportType} from '../../../../import-spreadsheet/values/enums/last-import-type.enum';

@Component({
  selector: 'app-do-import',
  templateUrl: './do-import.component.html',
  styleUrls: ['./do-import.component.scss'],
  providers: [
    {provide: ImportConfigurationService, useExisting: ImportSiteConfigurationService},
    {provide: DepositFileService, useExisting: DepositLocationImportFileService},
  ]
})
export class DoImportComponent implements OnInit, AfterViewInit {
  DEPOSIT_IMPORT_CONTROL_META = DEPOSIT_LOCATION_IMPORT_CONTROL_META;
  @Input() tables:  number[] =  [
    TABLE_TYPES.DEPOSIT_LOCATION_MAPPING
  ];
  @Input() savePreferencesAfterNumberOfChanges = 6;
  @Input() savePreferencesAfterInInterval = -1;
  @Input() savePreferencesAfterSubmit = true;
  @Input() savePreferencesOnDestroy = true;
  @Output() submit = new EventEmitter();
  public importPreferences = TABLE_SYNONYM_AND_COLUMNS;
  public columns: any = {};
  public uploadedDepositFile: DepositLocationImportFile[] = [];
  public depositImportForm: FormGroup;
  public depositImport: DepositLocationImport = new DepositLocationImport();
  public onUpdateUploadUrl$: EventEmitter<string> = new EventEmitter();
  public onDocumentSave$: EventEmitter<string> = new EventEmitter();
  public filesList = [];
  fields: any = {};
  columnFieldForm: FormGroup;

  constructor(
    public _fb: FormBuilder,
    public importValidation: ImportValidatorsService,
    public importConfigurationService: ImportConfigurationService,
    public importProgressService: ImportProgressService,
    public importDeepstreamService: ImportDeepstreamService,
    private _dialog: MdDialog
  ) {}

  ngOnInit() {
    this.columnFieldForm = this._fb.group({
      ...DEPOSIT_LOCATION_IMPORT_CONTROL_OPTIONS,
    }, {
      validators: [
        //      (control) => this.importValidation.validateColumnDuplication(control, this.fields),
      ]
    });
    this.depositImportForm = this._fb.group({
      ...IMPORT_DOCUMENT_CONTROLS,
      id: null,
    }, {
      validators: [
        (control) => this.importValidation.validateNumberControlIsNotLess(control),
      ]
    });
    this.depositImportForm.addControl('columnFields', this.columnFieldForm);
  }

  ngAfterViewInit() {
    this.fields = cloneDeep(DEPOSIT_LOCATION_IMPORT_COLUMNS);
  }

  updateImportAndShowWarning() {
    if (!this.validateAll()) {
      return;
    }
    this.createModel();
      this.importProgressService.startNewProcess();
      this.depositImport.save().subscribe((depositImportInserted: DepositLocationImport) => {
        this.importProgressService.setCurrentId(depositImportInserted.id);
        this.importDeepstreamService.setType(ELastImportType.SITES);
        this.depositImport = depositImportInserted;
        if (this.uploadedDepositFile.length === 1) {
          this.documentUploaded();
        } else {
          this.onUpdateUploadUrl$.emit(this.depositImport.getFileUploadUrl());
          this.onDocumentSave$.emit();
        }
      }, () => this.importProgressService.resetProgressModal());
  }

  documentUploaded() {
    const spreadsheetImport = this.depositImport;
    spreadsheetImport.startImport().subscribe(
      () => {},
      () => this.importProgressService.resetProgressModal()
    );
  }

  private createModel() {
    this.depositImportForm.controls['id'].setValue(this.depositImport.id);
    const columns = this.importValidation.parseColumnsToModel(this.columnFieldForm, this.fields);
    this.depositImport = new DepositLocationImport({...this.depositImportForm.value, ...columns});
  }

  private validateAll() {
    return this.depositImportForm.valid;
  }

  fileAdded(files) {
    this.filesList = files;
  }
}
