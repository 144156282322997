/**
 * Created by aleksandr on 30.06.17.
 */
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { ChatRoom } from './models/chat-room.model';
import { IChatService } from './interfaces/chat-service.interface';
import { IChatRoom, ISendMessageResponse } from './interfaces/chat-room.interface';
import { Observable } from 'rxjs/Observable';
import {
  EChatMessageTypes,
  IChatHistoryRecord,
  IChatMessage,
  INotificationEvent,
  TChatHistoryEvent
} from './interfaces/chat-message.interface';
import { ChatUser } from './models/chat-user.model';
import { ChatMessage } from './models/chat-message.model';
import { ServiceLocator } from '../services/service-locator';
import { DeepStreamService } from '../services/deepstream.service';
import { NotificationEntry } from '../notification/models/notification-entry.model';
import { ThreadApiService } from './thread-api.service';
import { ChatData } from './models/chat-data.class';
import { Subscription } from 'rxjs/Subscription';



@Injectable()
export class ChatService extends ThreadApiService implements IChatService {

  roomChanged$: EventEmitter<ChatRoom> = new EventEmitter();
  messageReceived$: EventEmitter<IChatMessage> = new EventEmitter();
  notificationReceived$: EventEmitter<NotificationEntry> = new EventEmitter();

  messageSubscription: Subscription;
  notificationSubscription: Subscription;


  get contacts(): ChatUser[] {
    return ChatData.contacts;
  };

  set contacts(value: ChatUser[]) {
    ChatData.contacts = value;
  };

  get activeRoom(): ChatRoom {
    return ChatData.activeRoom;
  };

  set activeRoom(value: ChatRoom) {
    ChatData.activeRoom = value;
  };

  get rooms(): ChatRoom[] {
    return ChatData.rooms;
  };

  set rooms(value: ChatRoom[]) {
    ChatData.rooms = value;
  };

  get ownerId(): number {
    return ChatData.ownerId;
  };

  set ownerId(value: number) {
    ChatData.ownerId = value;
  };

  get ownerAvatar(): string {
    return ChatData.ownerAvatar;
  };

  set ownerAvatar(value: string) {
    ChatData.ownerAvatar = value;
  };

  connectToStream() {
    let deepstream = ServiceLocator.injector.get(DeepStreamService);
    let ngZone = ServiceLocator.injector.get(NgZone);

    // if(this.messageSubscription) {
    //   this.messageSubscription.unsubscribe();
    // }
    // if(this.notificationSubscription) {
    //   this.notificationSubscription.unsubscribe();
    // }

    ngZone.runOutsideAngular(() => {
      //this.messageSubscription =
        deepstream
          .listenMessages((message: TChatHistoryEvent) => Number(message.type) === EChatMessageTypes.MESSAGE
          || Number(message.type) === EChatMessageTypes.FILE)
          .subscribe((message: IChatHistoryRecord) => ngZone.run(() => this.onMessageReceived(message)));

      ///this.notificationSubscription =
        deepstream
          .listenMessages((message: TChatHistoryEvent) => Number(message.type) === EChatMessageTypes.NOTIFICATION)
          .subscribe((event: INotificationEvent) => ngZone.run(() => this.onNotificationReceived(event)));
    });


  }


  getContacts(roleId: number): Observable<ChatUser[]> {
    return super
      .getContacts(roleId)
      .do((contacts: ChatUser[]) => this.onContactsLoaded(contacts));
  }

  getMyThreads() {
      return this
        .get({expand:'object,ecosystem,users'})
        .do((threads:ChatRoom[]) => this.onThreadsLoaded(threads));
  }

  createRoom(id: number | string): Observable<IChatRoom> {
    //TODO: implement it
    let room = new ChatRoom({id, messages: []});
    return Observable.of(room);
  }

  setOwnerId(id: number): this {
    ChatData.ownerId= id;
    return this;
  }

  setOwnerAvatar(imageUrl: string): this {
    ChatData.ownerAvatar= imageUrl;
    return this;
  }

  changeActiveRoom(room: ChatRoom): Observable<ChatRoom> {
    return room
      .loadMessages()
      .do(() => this.onRoomChanged(room))
      .map(() => room);
    //TODO: implement it, search in current list of rooms
    // return this
    //   .createRoom(id)
    //   .do((room) => room.connect())
    //   .do((room) => room.loadMessages())
    //   .do((room) => this.onRoomChanged(room));

    // return this
    //   .joinRoom(userId)
    //   //.do((room) => room.connect().subscribe())
    //   .do((room) => room.loadMessages().subscribe())
    //   .do((room) => this.onRoomChanged(room));
  }


  onContactsLoaded(contacts: ChatUser[]): void {
    this.contacts.length = 0;
    contacts.forEach((contact: ChatUser) => this.contacts.push(contact));
  }

  onThreadsLoaded(threads:ChatRoom[]): void  {
    ChatData.threads = threads;
  }

  onRoomChanged(room: ChatRoom): void {
    this.activeRoom = room;
    this.roomChanged$.emit(room);
  }

  onMessageSent(response: ISendMessageResponse): void {
    console.log('onMessageSent', response);
  }

  onMessageReceived(message: IChatHistoryRecord): void {
    console.log('Chat service onMessageReceived', message, ChatMessage.create(message));
    this.messageReceived$.emit(ChatMessage.create(message));
  }

  onNotificationReceived(event: INotificationEvent): void {
    console.log('Chat service onMessageReceived', new NotificationEntry(event.notification));
    this.notificationReceived$.emit(new NotificationEntry(event.notification));
  }

  onHistoryLoaded(history: IChatMessage[]): void {
    console.log('onHistoryLoaded', history);
  }
}
