var _a, _b, _c, _d;
import { COLUMNS_MAPPING } from '../../dashboards/agent/partials/import-components/deposits/values/deposit-import-synonym';
import { COLUMNS_MAPPING as SITE_MAPPING } from '../../dashboards/deposit-owner/partials/do-import/values/deposit-location-columns.const';
import { TABLE_TYPES } from './import-tables.const';
export var LEVELS_BY_TABLE = (_a = {},
    _a[TABLE_TYPES.STATE_OF_USE] = 5,
    _a[TABLE_TYPES.LEVEL_OF_DEMONTABILITY] = 5,
    _a[TABLE_TYPES.DEPOSIT_ORIGIN] = 7,
    _a[TABLE_TYPES.POTENTIAL_BECOMING] = 4,
    _a[TABLE_TYPES.COLUMNS_MAPPING] = Object.keys(COLUMNS_MAPPING).length,
    _a[TABLE_TYPES.DEPOSIT_LOCATION_MAPPING] = Object.keys(SITE_MAPPING).length + 1,
    _a);
export var ORDER_BY_TABLE = (_b = {},
    _b[TABLE_TYPES.STATE_OF_USE] = function (level) { return level; },
    _b[TABLE_TYPES.LEVEL_OF_DEMONTABILITY] = function (level) { return level; },
    _b[TABLE_TYPES.DEPOSIT_ORIGIN] = function (level) { return levelToExistingOrder(level, DEPOSIT_ORIGIN_ORDER); },
    _b[TABLE_TYPES.POTENTIAL_BECOMING] = function (level) { return levelToExistingOrder(level, DEPOSIT_POTENTIAL_ORIGIN_ORDER); },
    _b[TABLE_TYPES.COLUMNS_MAPPING] = function (level) { return level; },
    _b[TABLE_TYPES.DEPOSIT_LOCATION_MAPPING] = function (level) { return level; },
    _b);
export function levelToExistingOrder(level, levelToOrder) {
    return level
        .filter(function (item) { return !!levelToOrder[item] || levelToOrder[item] === 0; })
        .sort(function (previous, next) { return levelToOrder[previous] - levelToOrder[next]; });
}
export var DEPOSIT_ORIGIN_ORDER = (_c = {},
    _c[0 /* NO_SOURCE */] = 0,
    _c[1 /* DESTOCKED */] = 1,
    _c[5 /* SURPLUS */] = 2,
    _c[2 /* RECONDITIONED */] = 3,
    _c[6 /* REMOVAL */] = 4,
    _c[4 /* UNKNOWN */] = 5,
    _c);
export var DEPOSIT_POTENTIAL_ORIGIN_ORDER = (_d = {},
    _d[0 /* NO_SOURCE */] = 0,
    _d[3 /* REUSED */] = 3,
    _d);
