import * as tslib_1 from "tslib";
/**
 * Created by atat on 18/06/2017.
 */
import { EventEmitter, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TransformOrder } from '../../../ecosystem-manager/models/transform-order.class';
import { TransformOrderService } from '../../../ecosystem-manager/services/transform-order.service';
import { ECOSYSTEM_STEP_STATUS } from '../../../ecosystem-manager/values/ecosystem-step-status.const';
import { TransformLocationService } from '../../../../entities/transformation/services/transform-location.service';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import { MdDialog } from '@angular/material';
import { TransformDetailsDialogComponent } from '../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { PASSPORT_UNITS_TYPE } from '../../../../entities/passport/values/passport-units.const';
import { TRANSFORM_TYPE } from '../../values/transform-types.const';
import moment from 'moment';
var TsmOrderDetailsComponent = /** @class */ (function () {
    function TsmOrderDetailsComponent(_translateService, datePipe, _transformOrderService, _transfromLocationService, readOnlyService, _globalEvents, _currentUserService, translate, mdDialog) {
        this._translateService = _translateService;
        this.datePipe = datePipe;
        this._transformOrderService = _transformOrderService;
        this._transfromLocationService = _transfromLocationService;
        this.readOnlyService = readOnlyService;
        this._globalEvents = _globalEvents;
        this._currentUserService = _currentUserService;
        this.translate = translate;
        this.mdDialog = mdDialog;
        this.validated = new EventEmitter();
        this.unvalidated = new EventEmitter();
        this.rejected = new EventEmitter();
        this.askedForModification = new EventEmitter;
        this.chatOpened = new EventEmitter;
        this.confirmed = new EventEmitter();
        this.reportProblemModalIsVisible = false;
        this.modificationModalIsVisible = false;
        this.modificationIsNull = false;
        this.tsmOrders = [];
        this.tsmList = new Object;
        this.files = [];
        this.TRANSFORM_TYPE = TRANSFORM_TYPE;
    }
    Object.defineProperty(TsmOrderDetailsComponent.prototype, "isOrderConfirmed", {
        get: function () {
            return this.order.status === ECOSYSTEM_STEP_STATUS.WAITING_FOR_CONFIRMATION;
        },
        enumerable: true,
        configurable: true
    });
    TsmOrderDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.readOnlyService.readOnly = this.readOnly;
        this
            ._transformOrderService
            .view(this.id, { expand: 'transformation,in_order_passport,out_order_passport,creator_id,TSM_price,creator,orders_common_step' })
            .subscribe(function (res) {
            _this.order = res;
            _this._transformOrderService.getTsmList({ 'step_id': _this.order.step_id, expand: 'orders,tf_orders,orders.default_unit,orders.default_conversion' }).subscribe(function (tsmList) {
                _this.tsmList = tsmList;
                _this.tsmOrders = _this.tsmList[0].orders.map(function (order) { return new TransformOrder(tslib_1.__assign({}, order, { transformation: _this.order.transformation })); });
            });
            if (_this.order.ecosystem_file_junction) {
                _this.files = _this.order.ecosystem_file_junction.map(function (junction) { return junction.ecosystem_file; });
            }
            if (_this.order.site_id) {
                _this._transfromLocationService.view(_this.order.site_id).subscribe(function (location) {
                    _this.location = location;
                    _this.order.loaded = true;
                });
            }
            else {
                _this.order.loaded = true;
            }
        });
    };
    TsmOrderDetailsComponent.prototype.updateDate = function (dateString, datePart) {
        var date = new Date(dateString);
        date.setFullYear(datePart.getFullYear());
        date.setMonth(datePart.getMonth());
        date.setDate(datePart.getDate());
        return this.toDateString(date);
    };
    TsmOrderDetailsComponent.prototype.updateTime = function (dateString, datePart) {
        var date = new Date(dateString);
        date.setHours(datePart.getHours());
        date.setMinutes(datePart.getMinutes());
        date.setSeconds(datePart.getSeconds());
        return this.toDateString(date);
    };
    TsmOrderDetailsComponent.prototype.toDateString = function (date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    };
    TsmOrderDetailsComponent.prototype.validate = function () {
        this.validated.emit();
        this.order.validateByStep().subscribe();
    };
    TsmOrderDetailsComponent.prototype.unvalidate = function () {
        var _this = this;
        this.unvalidated.emit();
        this.order.in_order_passport.save().subscribe(function () {
            _this.order.unvalidate().subscribe();
        });
    };
    TsmOrderDetailsComponent.prototype.reject = function () {
        this.rejected.emit();
        this.order.rejectByStep().subscribe();
    };
    TsmOrderDetailsComponent.prototype.confirm = function () {
        this.confirmed.emit();
        this.order.confirm().subscribe();
    };
    TsmOrderDetailsComponent.prototype.changeVisibilityOfReportProblemModal = function () {
        this.order.problem = '';
        this.reportProblemModalIsVisible = !this.reportProblemModalIsVisible;
    };
    TsmOrderDetailsComponent.prototype.changeVisibilityOfModificationModal = function () {
        this.order.modification = '';
        this.modificationModalIsVisible = !this.modificationModalIsVisible;
    };
    TsmOrderDetailsComponent.prototype.checkStatusValidated = function () {
        return this.order.status !== ECOSYSTEM_STEP_STATUS.STAND_BY
            && this.order.status !== ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION
            && this.order.status !== ECOSYSTEM_STEP_STATUS.ASK_FOR_MODIFICATION;
    };
    TsmOrderDetailsComponent.prototype.correctDateFormat = function (date) {
        if (date !== null) {
            return moment(date).format('DD-MM-YYYY');
        }
        else {
            return "";
        }
    };
    TsmOrderDetailsComponent.prototype.modificationChanged = function () {
        this.modificationIsNull = !this.order.modification;
    };
    TsmOrderDetailsComponent.prototype.reportProblem = function (id, problem) {
        var _this = this;
        if (!problem) {
            return;
        }
        this._transformOrderService.sendReport(id, problem).subscribe(function () {
            _this.changeVisibilityOfReportProblemModal();
        });
    };
    TsmOrderDetailsComponent.prototype.askForModification = function () {
        if (!this.order.modification) {
            this.modificationIsNull = true;
            return;
        }
        this.askedForModification.emit();
        this.order.askForModificationByStep().subscribe();
    };
    TsmOrderDetailsComponent.prototype.openChat = function (targetId) {
        this._globalEvents.chatUserClicked$.emit(targetId);
        this.chatOpened.emit();
    };
    TsmOrderDetailsComponent.prototype.seeTransformation = function (id) {
        this.mdDialog.open(TransformDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                id: id,
                readOnly: true,
                showValidationButtons: false,
            } }));
    };
    TsmOrderDetailsComponent.prototype.setRowHeight = function (inputString, rowLength) {
        var rowSize = 1;
        if (!inputString) {
            return rowSize;
        }
        else if (inputString.length < rowLength) {
            return rowSize;
        }
        else {
            rowSize = Math.ceil(inputString.length / rowLength);
            return rowSize;
        }
    };
    TsmOrderDetailsComponent.prototype.isTransformationTypeOfTransport = function () {
        return this.order.transformation.transformation_type === TRANSFORM_TYPE.TRANSPORT;
    };
    TsmOrderDetailsComponent.prototype.isTransformationTypeOfStorage = function () {
        return this.order.transformation.transformation_type === TRANSFORM_TYPE.STORAGE;
    };
    TsmOrderDetailsComponent.prototype.isTypeOfTransportOrStorage = function () {
        if (this.isTransformationTypeOfStorage()) {
            return true;
        }
        else if (this.isTransformationTypeOfTransport()) {
            return true;
        }
        else {
            return false;
        }
    };
    TsmOrderDetailsComponent.prototype.getEndOrDeliveryDateTitle = function () {
        var currentTransformationType = this.order.transformation.transformation_type;
        if (currentTransformationType === TRANSFORM_TYPE.TRANSPORT) {
            return "Delivery date";
        }
        else if (currentTransformationType === TRANSFORM_TYPE.STORAGE) {
            return "End date of storage";
        }
    };
    TsmOrderDetailsComponent.prototype.getInputConversion = function (index) {
        return this.tsmOrders[index].getConversionForInput();
    };
    TsmOrderDetailsComponent.prototype.getInputName = function (index) {
        if (this.tsmOrders[index].in_order_passport.name) {
            return this.tsmOrders[index].in_order_passport.name;
        }
        else {
            return this.tsmOrders[index].child_step.title;
        }
    };
    TsmOrderDetailsComponent.prototype.getInputOrDepositsToTransportTitle = function () {
        var currentTransformationType = this.order.transformation.transformation_type;
        if (currentTransformationType === TRANSFORM_TYPE.TRANSPORT) {
            return "Deposits to transport";
        }
        else if (currentTransformationType === TRANSFORM_TYPE.STORAGE) {
            return "Deposits to be stored";
        }
        else {
            return "Input";
        }
    };
    TsmOrderDetailsComponent.prototype.getInputUnit = function (index) {
        if (this.order.transformation.input_unit) {
            return this.order.transformation.input_unit;
        }
        else if (this.tsmOrders[index].isWithoutUnit()) {
            return this.tsmOrders[index].unitInView;
        }
        else {
            return PASSPORT_UNITS_TYPE.KG;
        }
    };
    TsmOrderDetailsComponent.prototype.getOutputConversion = function () {
        if (this.tsmOrders[0]) {
            if (this.tsmOrders[0].isWithoutUnit()) {
                return this.tsmOrders[0].conversionInDefaultView;
            }
            else {
                return this.order.transformation.out_conversion;
            }
        }
    };
    TsmOrderDetailsComponent.prototype.getOutputName = function () {
        if (this.order.out_order_passport.name) {
            return this.order.out_order_passport.name;
        }
        else {
            return this._translateService.instant("Unnamed material");
        }
    };
    TsmOrderDetailsComponent.prototype.getOutputUnit = function () {
        if (this.tsmOrders[0]) {
            if (this.order.transformation.output_unit) {
                return this.order.transformation.output_unit;
            }
            else if (this.tsmOrders[0]._isWithoutUnit(this.tsmList[0].transformation_type)) {
                return this.tsmOrders[0].unitOutView;
            }
            else {
                return PASSPORT_UNITS_TYPE.KG;
            }
        }
    };
    TsmOrderDetailsComponent.prototype.getPickupAddressOrDescriptionText = function (index) {
        if (this.order.transformation.transformation_type === TRANSFORM_TYPE.TRANSPORT) {
            return this.tsmOrders[index].pickup_address;
        }
        else {
            return this.tsmOrders[index].in_order_passport.description;
        }
    };
    TsmOrderDetailsComponent.prototype.getPickupAddressOrDescriptionTitle = function () {
        if (this.order.transformation.transformation_type === TRANSFORM_TYPE.TRANSPORT) {
            return "Pickup address";
        }
        else {
            return "Description";
        }
    };
    TsmOrderDetailsComponent.prototype.getReceptionOrPickupDateText = function () {
        var currentTransformationType = this.order.transformation.transformation_type;
        if (currentTransformationType === TRANSFORM_TYPE.TRANSPORT) {
            return "Pickup date";
        }
        else if (currentTransformationType === TRANSFORM_TYPE.STORAGE) {
            return "Start date of storage";
        }
        else {
            return "Reception date";
        }
    };
    TsmOrderDetailsComponent.prototype.getTransformationOrderStatusText = function () {
        return this._translateService.instant(ECOSYSTEM_STEP_STATUS[this.order.status]);
    };
    TsmOrderDetailsComponent.prototype.checkSpanishOrPortugueseLanguageAndUnitIsPieces = function (unit) {
        if (unit === PASSPORT_UNITS_TYPE.PIECE) {
            if (this.translate.currentLang === 'es' || this.translate.currentLang === 'pt') {
                return true;
            }
        }
    };
    return TsmOrderDetailsComponent;
}());
export { TsmOrderDetailsComponent };
