<h2 class="control-type-title">{{'Type of Control' | translate}}</h2>
<md-radio-group
  class="control-type"
  [(ngModel)]="transform.controls_types"
  (ngModelChange)="transform.controls_other = ''"
>
  <md-radio-button value="1">
    {{'Quality' | translate}}
  </md-radio-button>
  <md-radio-button value="2">
    {{'Security' | translate}}
  </md-radio-button>
  <md-radio-button value="3">
    {{'Condition' | translate}}
  </md-radio-button>
  <md-radio-button value="4" #other>
    <md-input-container>
      <input
        mdInput
        autocomplete="off" 
        [placeholder]="'Other' | translate"
        (focus)="other.checked = true"
        [(ngModel)]="transform.controls_other"
      >
    </md-input-container>
  </md-radio-button>
</md-radio-group>
