import { Component, Input, OnInit } from '@angular/core';
import { MATERIAL_HEALTH_COLOR } from '../values/colors.enum';

@Component({
  moduleId: module.id,
  selector: 'side-report-component',
  templateUrl: 'side-report-component.html',
  styleUrls: ['side-report-component.scss']
})
export class SideReportComponent implements OnInit {
  constructor() {
    this.leafsCount = Array(4).fill(0).map((x, i) => i);
  }

  color = '';
  imgClassName: string = '';
  @Input() isVisible = true;
  leafsCount: Array<number> = [];

  @Input() title = '';
  @Input() content = '';
  @Input() leafsToDisplay = 0;
  @Input() showToggle = true;

  ngOnInit() {
    this.setColor();
  }

  toggleText() {
    this.isVisible = !this.isVisible;
  }

  setColor() {
    switch (this.leafsToDisplay) {
      case 0:
        this.color = MATERIAL_HEALTH_COLOR.KNOW_TOXICITY_PRODUCTS;
        break;
      case 1:
        this.color = MATERIAL_HEALTH_COLOR.CONTROLLED_TOCICITY_PRODUCTS;
        this.imgClassName = 'leaf-orange--icon';
        break;
      case 2:
        this.color = MATERIAL_HEALTH_COLOR.CONTROLLED_AND_VERIFIED_PRODUCTS;
        this.imgClassName = 'leaf-light-green--icon';
        break;
      case 3:
        this.color = MATERIAL_HEALTH_COLOR.HEALTHY_PRODUCTS;
        this.imgClassName = 'leaf-light-green2--icon';
        break;
      case 4:
        this.color = MATERIAL_HEALTH_COLOR.UNKNOW_TOXICITY_PRODUCTS;
        break;
      default:
        break;
    }
  }
}
