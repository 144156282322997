import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { TRANSFORM_TYPES, TRANSFORM_TYPE } from '../../../../transformation-manager/values/transform-types.const';
import { PLUMB_STEP_CATEGORIES } from '../../../values/plumb-step-categories.const';
import { FormControl } from '@angular/forms';
import { IPlumbStepSource } from '../../../interfaces/plumb-step-source.interface';
import { ECOSYSTEM_STEP_TYPE } from '../../../values/ecosystem-step-type.const';
import { NomenclatureItem } from '../../../../../entities/nomenclature/models/nomenclature-item.class';
import { MdDialog } from '@angular/material';
import { ViewNomenclatureDialogComponent }
  from '../../../../../entities/passport/dialogs/view-nomenclature/view-nomenclature.component';
import { EcosystemEditComponent } from '../ecosystem-edit/ecosystem-edit.component';
import { StakeholderDetailsDialogComponent }
  from '../../../../../entities/stakeholder/dialogs/stakeholder-details-dialog/stakeholder-details-dialog.component';
import { DEPOSIT_STATUSES } from '../../../../../entities/deposit/values/deposit-statuses.const';
import { DatePipe } from '@angular/common';
import { DepositLocationService } from '../../../../../entities/deposit/services/deposit-location.service';
import { DepositLocation } from '../../../../../entities/deposit/models/deposit-location.class';
import { TranslateService } from '@ngx-translate/core';
import { Passport } from '../../../../../entities/passport/models/passport.class';
import { isIndependent} from '../../../../transformation-manager/values/transform-dependency.const';
import { CurrentUser } from '../../../../../shared/auth/current-user.class';
import { Observable } from 'rxjs';
import { ChangeEvent } from 'angular2-virtual-scroll';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { CustomListDateAvailabilityService } from '../../../../../shared/services/custom-list-date-availability.service';
import {EDepositAvailableStatus} from '../../../../../shared/values/deposit-available-status.enum';

@Component({
  moduleId: module.id,
  selector: 'ecosystem-step-search',
  templateUrl: 'ecosystem-step-search.component.html',
  host: {'class': 'ecosystem-edit__search-control'},
})
export class EcosystemStepSearchComponent implements OnInit {
  @Input() public ecosystemNeedId: number;
  @Output() public onExtendCompiler: EventEmitter<boolean> = new EventEmitter();
  public relevancySearchInProgress: boolean = false;

  public ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP_TYPE;
  public TRANSFORM_TYPES = TRANSFORM_TYPES;
  public PLUMB_STEP_CATEGORIES = PLUMB_STEP_CATEGORIES;
  public PLUMB_STEP_CATEGORY_VALUES = [
    PLUMB_STEP_CATEGORIES.DEPOSITS,
    PLUMB_STEP_CATEGORIES.TRANSFORMS,
    PLUMB_STEP_CATEGORIES.STAKEHOLDERS,
    // PLUMB_STEP_CATEGORIES.VIRGIN_PRODUCTS,  #TODO fix bugs in virgin products in future releases
  ];

  public availableOnly: boolean = false;
  public distanceSource: number = 1;
  showIndependentTransformationFilter = false ;
  public isLoading: boolean = false;
  public categoryControl = new FormControl(this.PLUMB_STEP_CATEGORY_VALUES[0]);
  public searchControl = new FormControl('');
  public typeControl = new FormControl(0);
  public siteControl = new FormControl(null);
  public showIndependentTransformControl = new FormControl(false);
  public distanceControl = new FormControl(1);
  public sites: DepositLocation[] = [];
  public results: IPlumbStepSource[] = [];
  public relevantResults: any[] = [];
  public filteredResults: any[] = [];
  public totalRecords: number = 0;
  public foundRelevantRecords: number = 0;

  private tags: NomenclatureItem[] = [];
  private tagsRelevance: NomenclatureItem[] = [];

  private _availableStr = 'Available';
  private _expireDateStr  = 'Expired';
  private _flyingDistanceStr = 'Flying distance';
  private _unknownDistanceStr = 'unknown distance';

  public _extendedCompilerView = false;
  private totalcount: number;
  private page: number;
  private retrievedItems: number;
  private freeToLoadData = true;

  constructor(private injector: Injector,
              public dialog: MdDialog,
              private translateService: TranslateService,
              private ecosystemStepComponent: EcosystemEditComponent,
              private sitesService: DepositLocationService,
              private datePipe: DatePipe,
              private translate: TranslateService,
              public navBarState: NavbarStateService) {
    this.PLUMB_STEP_CATEGORY_VALUES.forEach((CATEGORY) => {
      CATEGORY.service = this.injector.get(CATEGORY.provider);
    });

    this.translateService
      .get(this._availableStr)
      .subscribe((text) => this._availableStr = text);
    this.translateService
      .get(this._expireDateStr)
      .subscribe((text) => this._expireDateStr = text);
    this.translateService
      .get(this._flyingDistanceStr)
      .subscribe((text) => this._flyingDistanceStr = text);

    this.translateService
      .get(this._unknownDistanceStr)
      .subscribe((text) => this._unknownDistanceStr = text);
  }

  ngOnInit() {
    this.showIndependentTransformationFilter = CurrentUser.infoData.has_transform_acces;
    this
      .searchControl
      .valueChanges
      .debounceTime(400)
      .subscribe(() =>
        this._extendedCompilerView
          ? this.searchByRelevancy()
          : this.updateSearchResults()
      )
    ;

    this
      .categoryControl
      .valueChanges
      .subscribe((item) => {
        if (this._extendedCompilerView &&
          (item.view === PLUMB_STEP_CATEGORIES.DEPOSITS.view || item.view === PLUMB_STEP_CATEGORIES.TRANSFORMS.view)) {
          this.searchByRelevancy(true);
        } else {
          this.filteredResults = [];
          this.toggleCompilerView(false);
          this.updateSearchResults();
        }
      });

    this
      .typeControl
      .valueChanges
      .subscribe(() =>
        this._extendedCompilerView
          ? this.searchByRelevancy()
          : this.updateSearchResults()
      )
    ;

    this
      .siteControl
      .valueChanges
      .subscribe(() =>
        this._extendedCompilerView
          ? this.searchByRelevancy()
          : this.updateSearchResults()
      )
    ;

    this
      .distanceControl
      .valueChanges
      .subscribe(() =>
        this.searchByRelevancy(true)
      );

    this.showIndependentTransformControl
    .valueChanges
    .subscribe(() =>
    this._extendedCompilerView
      ? this.searchByRelevancy()
      : this.updateSearchResults()
    );

    this.sitesService
      .search({supervisor: 1, active: 1, esm_compiler: 1})
      .subscribe((data: DepositLocation[]) => {
        this.sites = data;
      });
  }

  isIndependent(value: number): boolean {
    return isIndependent(value);
  }

  updateSearchResults(paginate = true, page = 1) {
    this.isLoading = true;
    const query = this.categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS ?
    this.buildQuery(paginate, page) : this.buildQuery();
    this
      .categoryControl.value.service
      .getWithArray(query)
      .subscribe((res) => {
        const headers = res.headers;
        this.page = Number(headers.get('X-Pagination-Current-Page')) || 1;
        this.totalcount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length;

        this.results = this.getResults(res);
        this.retrievedItems = res.json().length;
      })
    ;
  }

  getResults(res: any) {
    const results = res.json().map(item => new this.categoryControl.value.service.ModelClass(item));
    this.isLoading = false;
    let finalResults = [];
    results.map((result: IPlumbStepSource | any) => {
      if (result.plumb_type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
        let income_materials : string = ""
        let outcome_materials : string = "";
        let income_tags : string = ""
        let outcome_tags : string = "";

       let  lastMIndex = result.materials.length - 1
       let  lastTIndex = result.tags.length - 1
        result.materials.map( (mat: any ,index) => {
          if (mat.is_input == 1) {

            income_materials = index == lastMIndex ? income_materials + this.translate.instant(mat.name.trim()) : income_materials + this.translate.instant(mat.name.trim()) + ","  ;
          }
          else {
            outcome_materials = lastMIndex ? outcome_materials + this.translate.instant(mat.name.trim()) : outcome_materials + this.translate.instant(mat.name.trim())  + ",";
          }
        })
        result.tags.map( (tag: any,index) => {
          if (tag.is_input == 1) {
            income_tags = index == lastTIndex ? income_tags + this.translate.instant(tag.name.trim())  :income_tags + this.translate.instant(tag.name.trim())  + ",";
          }
          else {
            outcome_tags = index == lastTIndex ? outcome_tags + this.translate.instant(tag.name.trim())  : outcome_tags + this.translate.instant(tag.name.trim())  + ",";
          }
        })
        result.income_materials = income_materials;
        result.outcome_materials = outcome_materials;
        result.income_tags = income_tags;
        result.outcome_tags = outcome_tags;

        const transformResult = [];
        (!result.sites.length)
          ? transformResult.push(result)
          : result.sites.map((site: any) => transformResult.push(
              {
                ...result, site: {
                  ...site,
                },
                id: result.id, name: result.name,
                hasMultipleSites: true
              }
            )
          );
        finalResults = [...finalResults, ...transformResult];

      } else {
        finalResults.push(result);
      }
    });
    return finalResults;
  }

  loadNextPage(event: ChangeEvent) {
      if (this.totalcount <= 8
        || this.totalcount <= this.retrievedItems
        || event.end !== this.results.length - 1
        || !this.freeToLoadData) {
        return;
      }
        this.freeToLoadData = false;
        const query = this.buildQuery(true, ++this.page);

        this
        .categoryControl.value.service
        .getWithArray(query)
        .subscribe((res) => {
          this.freeToLoadData = true;
          const headers = res.headers;
          this.totalcount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length;
          this.retrievedItems += res.json().length;
          this.results = [...this.results, ...this.getResults(res)];
        })
        ;
  }

  buildQuery(paginate?: boolean, page?: number) {
    const options: any = [['expand', 'passport,deposit_location,owner_name,creator,sites,tags, materials']];

    options.push(['sort', 'name']);
    options.push(['site_for_esm_compiler', 1]);

    if (this.searchControl.value) {
      options.push(['search', this.searchControl.value.trim()]);
    }

    if (this.categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS && this.typeControl.value) {
      options.push(['transformation_type', this.typeControl.value]);
    }

    if (this.tags.length > 0) {
      for (const tag of this.tags) {
        options.push(['materials[]', tag.id]);
      }
    }

    if (paginate) {
      options.push(['paginate', paginate]);
    }

    if (page) {
      options.push(['page', page]);
    }

    if (this.navBarState.multilanguage) {
      options.push(['english', true]);
    }

    if (this.categoryControl.value === this.PLUMB_STEP_CATEGORIES.DEPOSITS) {
      options.push(['status[]', [DEPOSIT_STATUSES.PUBLISHED.value,
        DEPOSIT_STATUSES.ASK_FOR_MODIFICATION.value]]);
      options.push(['instock', 1]);
      options.push(['admin', 1]);
      options.push(['esm_view', 1]);
      options.push(['active_sites', 1]);
      if (this.availableOnly) {
        options.push(['available_from', this.datePipe.transform(new Date, 'dd-MM-yyyy')]);
        options.push(['available_to', this.datePipe.transform(new Date, 'dd-MM-yyyy')]);
      }
      if (this.siteControl.value) {
        options.push(['deposit_location_id', this.siteControl.value.id]);
      }
    } else if (this.categoryControl.value === this.PLUMB_STEP_CATEGORIES.TRANSFORMS) {
      options[0][1] += ', tsm_name, tsm_org';
      options.push(['esm_view', 1]);
      options.push(['supervisor', 1]);
      if (this.showIndependentTransformControl.value) {
        options.push(['independent', 1]);
      }
    }
    return options;
  }

  onAddNewStakeholder() {
    this
      .dialog
      .open(StakeholderDetailsDialogComponent, {data: {}})
      .afterClosed()
      .subscribe(() =>
        this.updateSearchResults()
      );
  }

  openNomenclatureDialog(materiallist: any) {
    // MdDialogConfig
    if (!this.relevancySearchInProgress) {
      let dialogRef = this.dialog.open(ViewNomenclatureDialogComponent, {
        height: '80%',
        width: '80%',
        position: {}
      });
      materiallist.blur();
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.tags.push(result);
          this._extendedCompilerView
            ? this.searchByRelevancy()
            : this.updateSearchResults();
        }
      });
    }
  }

  backToMainView() {
    this.toggleCompilerView(false);
    this.updateSearchResults();
  }

  filterAndUpdateResults() {
    this.filteredResults = [...this.relevantResults];
    if (this.tags.length > 0) {
      const filtredByTag = [];
      this.relevantResults.forEach(res => {

        let foundTags = 0;

        this.tags.map(tag => {
          const namesToSearch: string = res.income_materials || res.nomenclature_names;
          if (namesToSearch) {
            const translatednames = namesToSearch.split(',').map(n => this.translate.get(n.trim()));
            Observable.forkJoin(translatednames).subscribe(translateds => {
              translateds.map(translated => {
                if (translated.trim() === tag.name.trim()) {
                  foundTags++;
                }
              });
            });
          }
        });
        if (foundTags === this.tags.length) {
          filtredByTag.push(res);
        }
      });
      this.filteredResults = filtredByTag;
    }

    if (this.typeControl.value > 0) {
      this.filteredResults = this.filteredResults.filter(result =>
        this.typeControl.value === TRANSFORM_TYPE.UPCYCLING ?
        (result.transformation_type === TRANSFORM_TYPE.UPCYCLING || result.transformation_type === TRANSFORM_TYPE.RECYCLING) :
        result.transformation_type === this.typeControl.value
        );
      }

    if (this.searchControl.value) {
      this.filteredResults = this.filteredResults.filter(result =>
         ['name', 'deposit_name', 'deposit_passport_name', 'transformation_name', 'income_tags', 'outcome_tags']
         .some(property => result[property] ? result[property].toLowerCase().indexOf(this.searchControl.value.toLowerCase()) !== -1 : false)
      );
    }

    if (this.siteControl.value) {
      this.filteredResults = this.filteredResults.filter(result => result.name === this.siteControl.value.name);
    }

    if (!this.showIndependentTransformControl.value) {
      this.filteredResults = this.filteredResults.filter(result => !isIndependent(result.transformation_independent));
    }

    if (this.availableOnly && this.categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS) {
      this.filteredResults = this.filteredResults.filter(result =>
        (!result.deposit_availability_date || new Date(result.deposit_availability_date) <= new Date())
          && (!result.deposit_availability_expiration || new Date(result.deposit_availability_expiration) >= new Date())                                                              );
    }
  }

  removeTag(tag: any, event: Event) {
    event.stopPropagation();
    this.tags.splice(this.tags.indexOf(tag), 1);
    if (this._extendedCompilerView) {
      this.filterAndUpdateResults();
    } else {
      this.updateSearchResults();
    }
  }

  viewDetails(source: IPlumbStepSource) {
    if(source) {
      this.ecosystemStepComponent.viewDetails(source.plumb_type, source.id);
    } else {
      console.warn('IPlumbStepSource is null');
    }
  }

  getAvailable(startDateStr: string, endDateStr?: string) {
    const status = CustomListDateAvailabilityService.getDepositAvailableStatus(startDateStr, endDateStr);
    if (status === EDepositAvailableStatus.AVAILABLE) {
        return this._availableStr;
    } else if (status === EDepositAvailableStatus.AVAILABLE_IN) {
      return startDateStr;
    } else {
      return this._expireDateStr;
    }
  }

  toggleCompilerView(value: boolean) {
    this._extendedCompilerView = value;
    this.onExtendCompiler.emit(value);
  }

  searchByRelevancy(updateResultsFromBackend = false) {
    if (!updateResultsFromBackend) {
      this.toggleCompilerView(true);
      this.filterAndUpdateResults();
      return;
    }

    if(!this.relevancySearchInProgress) {
      this.relevancySearchInProgress = true;
      this.toggleCompilerView(true);

      let query: any = {
        esm_view: 1,
        use_travel_distance: this.distanceSource,
        materials: []
      };

      if (this.searchControl.value) {
        query.search = this.searchControl.value;
      }

      if (this.categoryControl.value === this.PLUMB_STEP_CATEGORIES.TRANSFORMS) {
        query['transformation_type'] = this.typeControl.value;

        if (this.tags.length > 0) {
          for (const tag of this.tags) {
            query['materials'].push(tag.id);
          }
        }
      }

      this.categoryControl.value.service.getByRelevancy(this.ecosystemNeedId, query)
        .subscribe((results: any) => {
          let records = results['records'];
          this.totalRecords = results['total'];
          this.foundRelevantRecords = results['found'];

          this.relevantResults = !!records
            ? records.map((r: any) => {
              if(this.categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS) {
                r.transform = this.results.find((result) => result.id === +r.transformation_id);
                r.plumb_type = ECOSYSTEM_STEP_TYPE.TRANSFORM;
                r.id = +r.transformation_id;
              } else if(this.categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS) {
                r.deposit = this.results.find((result) => result.id === +r.deposit_id);
                r.plumb_type = ECOSYSTEM_STEP_TYPE.DEPOSIT;
                r.id = +r.deposit_id;

                let passport = !!r.deposit ? r.deposit.passport : new Passport({unit: +r.passport_unit});
                r.passport_unit_text = passport.unit_text;
                r.passport_unit_css = passport.unitIconCss;
              }

              r.isRelevant = true;
              r.distance_str = this.getDistanceString(r);
              return r;
            })
            : [];
          this.filteredResults = [...this.relevantResults];
          this.filterAndUpdateResults();
          this.relevancySearchInProgress = false;
        }, (err: any) => {
          this.toggleCompilerView(false);
          this.relevancySearchInProgress = false;
        });
    }
  }

  private getDistanceString(relevantResult: any): string {
    let distanceStr = this._unknownDistanceStr;

    if(!!relevantResult.flying_distance || relevantResult.flying_distance === 0) {
      distanceStr = this._flyingDistanceStr + ': ' + (relevantResult.flying_distance / 1000).toFixed(2) + ' km';
    } else if(!!relevantResult.distance || relevantResult.distance === 0) {
      distanceStr = (relevantResult.distance / 1000).toFixed(2) + ' km';
    }

    return distanceStr;
  }

  getStepName(step) {
    if (this.navBarState.multilanguage && step.english_name) {
      return step.english_name;
    }
    return step.name  || "(Unnamed)";
  }

  resetFilters() {
    this.typeControl.setValue(0);
    this.siteControl.setValue(null);
    this.searchControl.setValue('');
    this.tags = [];

  }

  preventDoubleDialog(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
