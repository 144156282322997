import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientListComponent } from './client-list/client-list.component';
import { ClientDetailComponent } from './client-detail/client-detail.component';
import { ContactFormComponent } from './forms/contact-form/contact-form.component';
import { LocationFormComponent } from './forms/location-form/location-form.component';

import { DetailService } from './service/detail.service';
import { ClientService } from './service/client.service';
import { LocationService } from './service/location.service';
import { ContactService } from './service/contact.service';
import { LanguageService } from './service/language.service';

import { ClientSearchPipe } from './pipes/client-search.pipe';

import { SharedModule } from '../../shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ClientPageComponent } from './partials/client-page/client-page.component';
import { ClientItemComponent } from './client-list/clientitem/client-item.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FileUploadModule
  ],
  declarations: [
    ClientListComponent,
    ClientItemComponent,
    ClientDetailComponent,
    ContactFormComponent,
    ClientPageComponent,
    LocationFormComponent,
    ClientSearchPipe
  ],
  providers: [LocationService, ClientService, ContactService, DetailService, LanguageService],
  exports: [
    ClientListComponent,
    ClientDetailComponent,
    ContactFormComponent,
    LocationFormComponent
  ]
})
export class ClientModule {
}
