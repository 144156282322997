<div class="esm-catalog">
  <section class="esm-catalog__choice type--flex">
    <ul class="esm-catalog__choice-list type--auto">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input mdInput
                 autocomplete="off" 
                 placeholder="{{ 'Search' | translate }}"
                 [formControl]="search"
          >
        </md-input-container>
      </li>
    </ul>
  </section>
  <section class="esm-catalog__body">
    <ul class="users" [perfectScrollbar]>
      <li
        *ngFor="let user of users"
      >
        <div class="profile-image">
          <div [ngStyle]="getAvatar(user)"></div>
        </div>

        <strong class="name">{{user.viewName}}</strong><br>
        <span class="address"></span><br>
        <a class="email">
          <i class="icon icon--gray-mail"></i>
          {{user.email}}
        </a>
        <a class="phone-number" *ngIf="user.phone">
          <i class="icon icon--gray-phone"></i>
          {{user.phone}}
        </a><br>
        <a class="chat-with" (click)="openChat(user.id)" *ngIf="user.id !== _currentUserService.infoData.id">
          <i class="icon icon--gray-chat"></i> {{ 'Chat' | translate }}
        </a>
      </li>
    </ul>
  </section>
</div>
