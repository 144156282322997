import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {TransformPackaging} from '../models/transform-packaging.class';

@Injectable()
export class TransformPackagingService extends CrudService {
  protected namespace = 'transformation-packagings';
  protected namespaceSingular = 'transformation-packaging';
  protected ModelClass = TransformPackaging;
}
