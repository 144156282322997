<md-card>
  <div class="ib-row">
    <div class="ib-column ib-column-5-12">
      <div class="ib-row">
        <div class="ib-column ib-column-3-5"><b>{{ 'Name' | translate }}</b></div>
        <div class="ib-column ib-column-2-5"><b>{{ 'Unit' | translate }}</b></div>
        <div class="ib-column ib-column-3-5">{{passport.name}}</div>
        <div class="ib-column ib-column-2-5">{{passport.unit_text | translate}}</div>
      </div>
    </div>
    <div class="ib-column ib-column-7-12">
      <div class="ib-row">
        <div class="ib-column ib-column-4-5"><b>{{ 'Name and main photo' | translate }}</b></div>
        <div class="ib-column ib-column-4-8">
          <img class="passport-logo" *ngIf="passport.image" [src]="passport.image"/>
          <span><sup class="pass_size">{{passport.manufacturer}}</sup> </span>
        </div>
      </div>
    </div>
  </div>
  <div class="ib-row section">
    <div class="ib-column ib-column-2-12"><b><i>{{ 'Component' | translate }}</i></b></div>
    <div class="ib-column ib-column-2-12"><b><i>{{ 'Number/unit' | translate }}</i></b></div>
    <div class="ib-column ib-column-3-12"></div>
    <div class="ib-column ib-column-3-12"><b><i>{{ 'Materials' | translate }}</i></b></div>
    <div class="ib-column ib-column-2-12"><b><i>{{ 'Quantity (kg/unit)' | translate }}</i></b></div>
  </div>
  <div class="ib-row section">
    <div class="ib-column ib-column-7-12" id="justify">
      <div class="ib-row " *ngFor="let item of passport.components">
        <div class="ib-column ib-column-1-12">{{item.name}}</div>
        <div class="ib-column ib-column-2-12">{{item.quantity}}</div>
        <div class="ib-column ib-column-3-12">{{item.demountable}}</div>
      </div>
    </div>
    <div class="ib-column ib-column-5-12" id="justify">
      <div class="ib-row" *ngFor="let item of passport.materials">
        <div *ngIf="item.nomenclature" class="ib-column ib-column-3-12" id="nomen">{{item.nomenclature.name}}</div>
        <div class="ib-column ib-column-2-12 tab">{{item.quantity}}</div>
      </div>
    </div>
  </div>
  <div class="ib-row section">
    <div class="ib-column ib-column-11-12 description">
      <b>{{ 'Description' | translate }}</b>
      <md-card-content class="tab">
        {{passport.description}}
      </md-card-content>
    </div>
    <!--<div class="ib-column ib-column-11-12 description_pad">-->
      <!--<b> {{ 'Next usages' | translate }}</b>-->
      <!--<md-card-content class="tab">-->
        <!--{{passport.next_use}}-->
      <!--</md-card-content>-->
    <!--</div>-->
    <div class="ib-column ib-column-11-12 description_pad">
      <b>{{ 'Recommendation of employment' | translate }}</b>
      <md-card-content class="tab">
        <div>
          {{passport.recommendation}}
        </div>
      </md-card-content>
    </div>
  </div>
  <!--<div class="ib-row section">-->
    <!--<div class="ib-column ib-column-2-4 ta-right"><b>{{ 'Format' | translate }}</b></div>-->
    <!--<div class="ib-column ib-column-2-4">{{passport.format}}</div>-->
  <!--</div>-->
  <div class="ib-row" *ngIf="!isViewMode">
    <div class="ib-column ib-column-2-4 ta-right">
      <button md-raised-button (click)="onSelect.emit(passport)">{{ 'Choose this Passport' | translate }}</button>
    </div>
    <div class="ib-column ib-column-2-4">
      <button md-raised-button (click)="onEdit.emit(passport)">{{ 'Edit this Passport' | translate }}</button>
    </div>
  </div>
</md-card>
