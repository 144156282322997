import { DepositStatus } from '../models/deposit-status.class';
/**
 * Created by aleksandr on 10.02.17.
 */

export const DEPOSIT_STATUSES: {
  DRAFT: DepositStatus,
  WAITING_FOR_VALIDATION: DepositStatus,//UNV
  WAITING_FOR_PUBLICATION: DepositStatus, //V
  ASK_FOR_MODIFICATION: DepositStatus,
  PUBLISHED: DepositStatus,
  REJECTED: DepositStatus
} = {
  DRAFT: {
    value: 1,
    view: 'draft',
    text: 'DRAFT'
  },
  WAITING_FOR_VALIDATION: {
    value: 2,
    view: 'Waiting for validation',
    text: 'WAITING_FOR_VALIDATION'
  },
  WAITING_FOR_PUBLICATION: {
    value: 3,
    view: 'Waiting for publication',
    text: 'WAITING_FOR_PUBLICATION'
  },
  ASK_FOR_MODIFICATION: {
    value: 4,
    view: 'asked for modification',
    text: 'ASKED_FOR_MODIFICATION'
  },
  PUBLISHED: {
    value: 5,
    view: 'published',
    text: 'PUBLISHED'
  },
  REJECTED: {
    value: 6,
    view: 'rejected',
    text: 'REJECTED'
  }
};
