import {Component, OnInit} from '@angular/core';
import {ReportService} from '../../../../services/report.service';
import {ActivatedRoute} from '@angular/router';
import {ReportBase} from '../report-base/report-base.class';
import {REPORT_NAME} from '../types/report-name.const';
import {LanguageService} from '../../../../../../entities/client/service/language.service';
import {IReportPDFTable} from '../types/report-pdf-table.interface';
import {IReportError} from '../../../../interfaces/report-error.interface';
import {TReportName} from '../types/report-name.type';
import {chunkAnArray} from '../../../../../../shared/helpers/chuch-an-array';
import { ICarbonFootprint, ICarbonFootprintCategory } from '../../../../interfaces/carbon-footprint.interface';
import {TranslateService} from '@ngx-translate/core';
import {ReportSiteTagPresenterService} from '../../../../services/report-site-tag-presenter.service';
import {ElRowCounterService} from '../../../../services/el-row-counter.service';
import { ECarbonFootprintReportType } from '../../../../../../dashboards/admin/values/carbon-footprint-status.enum';
import { convertUnit } from '../../../../../../../src/helper/Unithelper';

@Component({
  moduleId: module.id,
  selector: 'carbon-footprint-pdf-report',
  templateUrl: 'carbon-footprint-pdf-report.component.html',
  styleUrls: ['carbon-footprint-pdf-report.component.scss'],
  providers: [ReportSiteTagPresenterService, ElRowCounterService]
})
export class CarbonFootprintPdfReportComponent extends ReportBase<ICarbonFootprint, ICarbonFootprintCategory> implements OnInit {
  protected reportName: TReportName = REPORT_NAME.CARBON_FOOTPRINT;
  private pageCounter: number = 0;
  public categoriesTable: IReportPDFTable[]= []; // Deprecated
  public productCategoriesTable: IReportPDFTable[]= [];
  public constructionCategoriesTable: IReportPDFTable[]= [];
  public embodiedCategoriesTable: IReportPDFTable[]= [];
  public icComponentCategoriesTable: IReportPDFTable[]= [];
  public errorsTable: IReportPDFTable[] = [];
  public reportTypes: ECarbonFootprintReportType[] = [];
  public categories: ICarbonFootprintCategory[] = []; // Deprecated
  public productCategories: ICarbonFootprintCategory[] = [];
  public constructionCategories: ICarbonFootprintCategory[] = [];
  public embodiedCategories: ICarbonFootprintCategory[] = [];
  public icComponentCategories: ICarbonFootprintCategory[] = [];
  filteredAndSortedProductCategory: ICarbonFootprintCategory[];

  constructor(
    public reportService: ReportService,
    public activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    public translateService: TranslateService,
    public reportPresenter: ReportSiteTagPresenterService,
    public rowCounter: ElRowCounterService) {
      super(reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter);
  }

  ngOnInit(): void {
    this.createReport();
  }
  
  /**
   * Check whether the list of categories corresponding to the report type is empty or not
   * It is considered empty if only the default category exists, so the length of the categories is 1
   * Just in case, we accept the case where it is equal to 0 too
   * @param {String} reportType - String describing the type of report
   * @returns {Boolean} True if the list of categories is empty, false otherwise 
   */
  isEmptySpecificProductCategory(reportType): boolean {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return Object.keys(this.productCategories).length <= 1;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return Object.keys(this.constructionCategories).length <= 1;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return Object.keys(this.embodiedCategories).length <= 1;
      case ECarbonFootprintReportType.IC_COMPONENT:
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return Object.keys(this.icComponentCategories).length <= 1;
    }
  }

  createReport(): void {
    this.reportRequest()
      .subscribe(() => {
        this.filteredAndSortedProductCategory = this.getSortedProductsBy('footprint');
        if(this.result.is_product_stage){
          this.reportTypes.push(ECarbonFootprintReportType.PRODUCT_STAGE);
          this.prepareCategoriesTable(ECarbonFootprintReportType.PRODUCT_STAGE);
        }
        if(this.result.is_product_and_construction){
          this.reportTypes.push(ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION);
          this.prepareCategoriesTable(ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION);
        }
        if(this.result.is_embodied_carbon){
          this.reportTypes.push(ECarbonFootprintReportType.EMBODIED_CARBON);
          this.prepareCategoriesTable(ECarbonFootprintReportType.EMBODIED_CARBON);
        }
        if(this.result.is_ic_component){
          this.reportTypes.push(ECarbonFootprintReportType.IC_COMPONENT);
          this.prepareCategoriesTable(ECarbonFootprintReportType.IC_COMPONENT);
        }
        if(this.result.is_ic_construction){
          this.reportTypes.push(ECarbonFootprintReportType.IC_CONSTRUCTION);
        }
        this.prepareErrorsTable();
    });
  }

  getCarbonFootPrintCircleAmount(reportType: ECarbonFootprintReportType): {value: number, unit: string} {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return convertUnit({value : this.result.aggregate.total_carbon_footprint, unit :'tons'});
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return convertUnit({value : this.result.aggregate.embodied_carbon_total, unit :'tons'});
      case ECarbonFootprintReportType.IC_COMPONENT:
        return convertUnit({value : this.result.aggregate.ic_component_total, unit :'kgs'});
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return convertUnit({value : this.result.aggregate.ic_construction_total, unit :'kgs'});
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return convertUnit({value : this.result.aggregate.product_and_construction_total, unit :'tons'});
    }
  }
  getCarbonFootprintCircleUnitText(reportType: ECarbonFootprintReportType): string {
    if(this.isReportTypeIcComponentOrConstruction(reportType)){
      return 'KG_CO2_EQ_SQM';
    } else {
      return 'TONS_CO2_EQ';
    }
  }
  getCarbonFootprintDescriptionText(reportType: ECarbonFootprintReportType): string {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'PRODUCT_STAGE_DESCRIPTION_TEXT_PDF';
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'EMBODIED_CARBON_DESCRIPTION_TEXT_PDF';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'IC_COMPONENT_DESCRIPTION_TEXT_PDF';
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return 'IC_CONSTRUCTION_DESCRIPTION_TEXT_PDF';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'PRODUCT_AND_CONSTRUCTION_DESCRIPTION_TEXT_PDF'
    }
  }
  getCategorySubtitleTextForCarbonFootprint(reportType): string{

    switch(reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'Product stage (A1-A3) (Tons CO2 eq.)';
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'Embodied carbon (Tons CO2 eq.)';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'ICcomposant (kg Co2 eq. / sqm)'; 
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'Product & Construction process stage (A1-A5) (Tons CO2 eq.)'
    }
  }

  /**
   * Create the object corresponding to the summary of all the deposits of a report type
   * @param {String} reportType - String describing the type of report
   * @returns {Object}
   */
  getDefaultCategory(reportType): ICarbonFootprintCategory {
    let weightProperty = this.getWeightProperty(reportType);
    let depositProperty = this.getDepositProperty(reportType);
    let footprintProperty = this.getFootprintProperty(reportType);
    let emissionProperty = this.getEmissionProperty(reportType);

    return {
      category: {name: 'Total', parent_id: void 0, id: void 0},
      isDefault: true,
      weight: this.result.aggregate[weightProperty],
      deposit_count: this.result.aggregate.total_deposit_count,
      deposits_considered: this.result.aggregate[depositProperty],
      emission_intensity: this.result.aggregate[emissionProperty],
      footprint: this.result.aggregate[footprintProperty],
      mass_percentage: 100,
      mass_percentage_considered: 100,
      percentage_of_total_footprint: 100,
      product_and_construction_total: this.result.aggregate.product_and_construction_total,
      embodied_carbon_total: this.result.aggregate.embodied_carbon_total,
      ic_component_total: this.result.aggregate.ic_component_total,
      product_and_construction_emission_intensity : this.result.aggregate.total_product_and_construction_emission_intensity,
      embodied_emission_intensity: this.result.aggregate.total_embodied_emission_intensity
    };
  }

  /**
   * Get the property name for the weight depending on the report type
   * @param {String} reportType - String describing the type of report
   * @returns {String}
   */
  getWeightProperty(reportType) {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'total_weight_considered';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'total_weight_considered_product'
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'total_weight_considered_embodied';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'total_weight_considered_ic_comp_const';
    }
  }

  /**
   * Get the property name for the deposit amount depending on the report type
   * @param {String} reportType - String describing the type of report
   * @returns {String}
   */
  getDepositProperty(reportType) {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'total_deposit_considered';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'total_deposits_considered_product'
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'total_deposits_considered_embodied';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'total_deposits_considered_ic_comp_const';
    }
  }

  /**
   * Get the property name for the carbon footprint depending on the report type
   * @param {String} reportType - String describing the type of report
   * @returns {String}
   */
  getFootprintProperty(reportType) {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'total_carbon_footprint';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'product_and_construction_total'
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'embodied_carbon_total';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'ic_component_total';
    }
  }

  /**
   * Get the property name for the category carbon footprint depending on the report type
   * @param {String} reportType - String describing the type of report
   * @returns {String}
   */
  getCategoryFootprintProperty(reportType) {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'footprint';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'product_and_construction_total'
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'embodied_carbon_total';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'ic_component_total';
    }
  }

  /**
   * Get the property name for the emission intensity depending on the report type
   * @param {String} reportType - String describing the type of report
   * @returns {String}
   */
  getEmissionProperty(reportType) {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'total_emission_intensity';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'total_product_and_construction_emission_intensity'
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'total_embodied_emission_intensity';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return null;
    }
  }

  getMainChartHeadlineText(reportType: ECarbonFootprintReportType): string{
    if(this.isReportTypeIcConstruction(reportType)){
      return 'Ic construction';
    } else if(this.isReportTypeIcComponent(reportType)){
      return 'Ic composant';
    } else {
      return this.translateService.instant("Carbon footprint");
    }
  }
  getSubTitleText(reportType: ECarbonFootprintReportType): string {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return this.translateService.instant('Production (A1-A3)');
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return this.translateService.instant('Embodied (A1-A5 + B1-B5 + C)');
      case ECarbonFootprintReportType.IC_COMPONENT:
        return this.translateService.instant('Ic composant');
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return 'Ic construction';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return this.translateService.instant('Production & Construction (A1-A5)');
    }
  }
  getTableRenderingBreakPoint(tableContentsArray: []): number{
    if(this.reportTypes.length === 1 && this.reportTypes[0] === ECarbonFootprintReportType.IC_CONSTRUCTION){
     return 1;
    } else{
     return tableContentsArray.length;
    }
  }
  getTotalConsideredDepositsByReportType(reportType): number {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return this.result.aggregate.total_deposit_considered;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return this.result.aggregate.total_deposits_considered_embodied;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return this.result.aggregate.total_deposits_considered_ic_comp_const;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return this.result.aggregate.total_deposits_considered_ic_comp_const;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
          return this.result.aggregate.total_deposits_considered_product;
    }
  }
  getTotalConsideredWeightByReportType(reportType): number {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return this.result.aggregate.total_weight_considered;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return this.result.aggregate.total_weight_considered_embodied;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return this.result.aggregate.total_weight_considered_ic_comp_const;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return this.result.aggregate.total_weight_considered_ic_comp_const;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return this.result.aggregate.total_weight_considered_product;
    }
  }
  getTypeTotalValueParameter(reportType: ECarbonFootprintReportType): string {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'footprint';
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'embodied_carbon_total';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'ic_component_total';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'product_and_construction_total'
    }
  }
  isReportTypeIcConstruction(reportType: ECarbonFootprintReportType): boolean {
    return reportType === ECarbonFootprintReportType.IC_CONSTRUCTION;
  }
  isReportTypeIcComponent(reportType: ECarbonFootprintReportType): boolean {
    return reportType === ECarbonFootprintReportType.IC_COMPONENT;
  }
  isReportTypeIcComponentOrConstruction(reportType: ECarbonFootprintReportType): boolean{
    return this.isReportTypeIcComponent(reportType) || this.isReportTypeIcConstruction(reportType);
  }

  /**
   * Set the appropriate category list and category table according to the report type
   * @param {String} reportType - String describing the type of report
   */
  prepareCategoriesTable(reportType): void {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        this.productCategories = this.getCategoryList(reportType);
        this.onEachSpecificChunkedCategory((chuck: ICarbonFootprintCategory[], index: number) => {
          this.productCategoriesTable.push({
            pageNumber: index,
            pageContent: chuck
          });
        }, this.productCategories);
        break;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        this.constructionCategories = this.getCategoryList(reportType);
        this.onEachSpecificChunkedCategory((chuck: ICarbonFootprintCategory[], index: number) => {
          this.constructionCategoriesTable.push({
            pageNumber: index,
            pageContent: chuck
          });
        }, this.constructionCategories);
        break;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        this.embodiedCategories = this.getCategoryList(reportType);
        this.onEachSpecificChunkedCategory((chuck: ICarbonFootprintCategory[], index: number) => {
          this.embodiedCategoriesTable.push({
            pageNumber: index,
            pageContent: chuck
          });
        }, this.embodiedCategories);
        break;
      case ECarbonFootprintReportType.IC_COMPONENT:
        this.icComponentCategories = this.getCategoryList(reportType);
        this.onEachSpecificChunkedCategory((chuck: ICarbonFootprintCategory[], index: number) => {
          this.icComponentCategoriesTable.push({
            pageNumber: index,
            pageContent: chuck
          });
        }, this.icComponentCategories);
        break;
    }
  }
  prepareErrorsTable(): void {
    this.onEachChunkedError((chuck: IReportError[], index: number) => {
      this.errorsTable.push({
        pageNumber: index + this.errorsTable.length,
        pageContent: chuck
      });
    });
  }

  /**
   * Get the list of categories depending on the type of report
   * @param {String} reportType - String describing the type of report
   * @returns {ICarbonFootprintCategory[]}
   */
  getCategoryList(reportType): ICarbonFootprintCategory[] {
    let discriminant = this.getDiscriminantProperty(reportType);
    let sortingProperty = this.getCategoryFootprintProperty(reportType);
    return [
      this.getDefaultCategory(reportType),
      ...this.getSortedProductsBy(sortingProperty, null, discriminant)
    ];
  }

  /**
   * Get the property name which discriminates which categories should be kept depending on the report type
   * @param {String} reportType - String describing the type of report
   * @returns {String}
   */
  getDiscriminantProperty(reportType) {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'deposits_considered';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'deposits_considered_product'
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'deposits_considered_embodied';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'deposits_considered_ic_comp_const';
    }
  }
  
  public onEachSpecificChunkedCategory(onEachChunk: Function, categories): void {
    return chunkAnArray<ICarbonFootprintCategory>(categories, this.categoriesPerPage, this.firstPageCategoriesAmount)
      .forEach((chunk: ICarbonFootprintCategory[], index: number) => onEachChunk(chunk, index));
  }

  /**
   * Get the correct PDF table depending on the type of report
   * @param {String} reportType - String describing the type of report
   * @returns {IReportPDFTable}
   */
  getCategoriesTable(reportType) {
    switch(reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return this.productCategoriesTable;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return this.embodiedCategoriesTable;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return this.icComponentCategoriesTable;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
          return this.constructionCategoriesTable;
    }
  }

  /**
   * Get the number of deposits of the category
   * If the category is the default one, get the "deposits_considered" property of the category
   * Else, get the property depending on the type of report
   * @param {ICarbonFootprintCategory} category - Category to get the number of deposits from
   * @param {String} reportType - String describing the type of report
   * @returns {Number} The amount of deposits of the category
   */
  getDepositAmount(category, reportType) {
    if (category.isDefault) {
      return category["deposits_considered"];
    }

    switch(reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return category["deposits_considered"];
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return category["deposits_considered_product"];
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return category["deposits_considered_embodied"];
      case ECarbonFootprintReportType.IC_COMPONENT:
        return category["deposits_considered_ic_comp_const"];
    }
  }

  /**
   * Get the weight of the category
   * If the category is the default one, get the "weight" property of the category
   * Else, get the property depending on the type of report
   * @param {ICarbonFootprintCategory} category - Category to get the number of deposits from
   * @param {String} reportType - String describing the type of report
   * @returns {Number} The weight of the category
   */
  getWeight(category, reportType) {
    if (category.isDefault) {
      return category["weight"];
    }

    switch(reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return category["weight"];
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return category["weight_considered_product"];
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return category["weight_considered_embodied"];
      case ECarbonFootprintReportType.IC_COMPONENT:
        return category["weight_considered_ic_comp_const"];
    }
  }

  /**
   * Get the mass percentage of the category
   * If the category is the default one, get the "mass_percentage_considered" property of the category
   * Else, get the property depending on the type of report
   * @param {ICarbonFootprintCategory} category - Category to get the number of deposits from
   * @param {String} reportType - String describing the type of report
   * @returns {Number} The mass percentage of the category
   */
  getMassPercentage(category, reportType) {
    if (category.isDefault) {
      return category["mass_percentage_considered"];
    }

    switch(reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return category["mass_percentage_considered"];
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return category["mass_percentage_considered_construction"];
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return category["mass_percentage_considered_embodied"];
      case ECarbonFootprintReportType.IC_COMPONENT:
        return category["mass_percentage_considered_ic_comp"];
    }
  }

  initPageNumber(): number {
    this.pageCounter =  this.reportTypes.length + this.getStartingNumber();
    return this.pageCounter;
  }

  getPageNumber(): number {
    this.pageCounter += 1;
    return this.pageCounter;
  }

  getStartingNumber(): number {
    return this.showSitesInOnePage ? 2 : 1;
  }

  getTranslation(reportType) {
    let x = 0;
    let y = 0;
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        x = this.result.aggregate.partial_deposit_considered;
        y = this.result.aggregate.total_deposit_considered;

        break;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        x = this.result.aggregate.partial_deposits_considered_embodied;
        y = this.result.aggregate.total_deposits_considered_embodied;

        break;
      case ECarbonFootprintReportType.IC_COMPONENT:
        x = this.result.aggregate.partial_deposits_considered_ic_comp_const;
        y = this.result.aggregate.total_deposits_considered_ic_comp_const;

        break;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        x = this.result.aggregate.partial_deposits_considered_ic_comp_const;
        y = this.result.aggregate.total_deposits_considered_ic_comp_const;

        break;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        x = this.result.aggregate.partial_deposits_considered_product;
        y = this.result.aggregate.total_deposits_considered_product;

        break;
    }
    return this.translateService.instant('CS_CARBON_MISSING_DATA', {
      X: x,
      Y: y
    })
  }

  /**
   * Evaluate whether partial data should be displayed or not
   * Partial data should be displayed if there is at least one partial data in the report
   * @param {String} reportType - String describing the type of report
   * @returns {Boolean} Return true if partial data should be displayed, else return false
   */
  shouldDisplayPartialData(reportType) {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return this.result.aggregate.partial_deposit_considered > 0;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return this.result.aggregate.partial_deposits_considered_embodied > 0;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return this.result.aggregate.partial_deposits_considered_ic_comp_const > 0;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return this.result.aggregate.partial_deposits_considered_ic_comp_const > 0;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return this.result.aggregate.partial_deposits_considered_product > 0;
    }
  }

  shouldDisplayTable(reportType) {
    return reportType !== ECarbonFootprintReportType.IC_CONSTRUCTION;
  }

  shoudDisplayEmissionColumn(reportType) {
    return reportType !== ECarbonFootprintReportType.IC_CONSTRUCTION && reportType !== ECarbonFootprintReportType.IC_COMPONENT;
  }

  getCarbonValue(item, reportType) {
    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return item['footprint'] 
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return item['embodied_carbon_total'];
      case ECarbonFootprintReportType.IC_COMPONENT:
        return item['ic_component_total'];
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
          return item['product_and_construction_total'];
    }
  }

  /**
   * Get the emission intensity of the category
   * If the category is the default one, get the "emission_intensity" property of the category
   * Else, get the property depending on the type of report
   * @param {ICarbonFootprintCategory} category - Category to get the number of deposits from
   * @param {String} reportType - String describing the type of report
   * @returns {Number} The emission intensity of the category
   */
  getEmissionValue(category, reportType) {
    if (category.isDefault) {
      return category['emission_intensity'];
    }

    switch (reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return category['emission_intensity'];
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return category['product_and_construction_emission_intensity'];
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return category['embodied_emission_intensity'];
      case ECarbonFootprintReportType.IC_COMPONENT:
        return this.translateService.instant('Unknown');
    }
  }
}
