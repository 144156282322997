/**
 * Created by Aleksandr C. on 10.02.17.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Deposit } from '../../../models/deposit.class';
import { PASSPORT_UNITS } from '../../../../passport/values/passport-units.const';
import { DuplicateListRecord } from '../../../models/duplicate-list-record.interface';
import { PassportComponent } from '../../../../passport/models/component.class';
import * as _ from 'lodash';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'duplicate-list-item',
  templateUrl: 'duplicate-list-item.component.html',
})
export class DuplicateListItemComponent implements OnInit {
  @Input() record: DuplicateListRecord;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  public deposit: Deposit;
  public materialsAndComponents: {
    passport_component?: PassportComponent,
    material_name?: { name: string },
  }[];

  constructor(private translatePipe: TranslatePipe) {
  }

  ngOnInit() {
    this.deposit = this.record.deposit;
    this.materialsAndComponents = _.zip(
      this.record.material_names || [],
      this.record.passport_components || []
    ).map((a: any[]) => {
      return {
        material_name: a[0],
        passport_component: a[1],
      };
    });
  }

  units: any[] = PASSPORT_UNITS;

  select(record: Deposit) {
    this.onSelect.emit(record);
  }

  getUnitText(id: number) {
    let found = this.units.find((item) => item.id === id);
    return found ? this.translatePipe.transform(found.title) : id;
  }
}
