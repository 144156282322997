import {NgModule} from '@angular/core';
import {ImportSpreadsheetComponent} from './partials/import-spreadsheet/import-spreadsheet.component';
import {ImportDepositDocumentsComponent} from './partials/documents/import-deposit-documents.component';
import {ImportDepositColumnsComponent} from './partials/columns/import-deposit-columns.component';
import {
  DepositImportColumnCustomComponent
} from './partials/columns/components/deposit-import-column-custom/deposit-import-column-custom.component';
import {
  DepositImportColumnDescriptionComponent
} from './partials/columns/components/deposit-import-column-description/deposit-import-column-description.component';
import {DepositImportColumnComponent} from './partials/columns/components/deposit-import-column-item/deposit-import-column.component';
import {DepositImportColumnRowComponent} from './partials/columns/components/deposit-import-column-row/deposit-import-column-row.component';
import {ImportDepositTableComponent} from './partials/table/import-deposit-table.component';
import {ImportDepositTableItemComponent} from './partials/table/table-item/import-deposit-table-item.component';
import {ImportValidatorsService} from './services/import-validators.service';
import {SharedModule} from '../shared/shared.module';
import {ImportConfigurationService} from './services/import-configuration.service';
import {DepositImportProgressComponent} from './partials/progress/import-progress.component';
import {ImportProgressWrapperComponent} from './partials/progress/wrapper/import-progress-wrapper.component';
import {ImportProgressService} from './services/import-progress.service';
import {DepositLocationImportService} from '../entities/deposit/services/deposit-location-import.service';
import { ImportSuccessSummaryService } from '../shared/services/import-success-summary.service';
import { ImportProgressStickyFooterComponent } from './partials/progress/import-progress-sticky-footer/import-progress-sticky-footer.component';


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ImportSpreadsheetComponent,
    ImportDepositDocumentsComponent,
    ImportDepositColumnsComponent,
    ImportDepositTableItemComponent,
    ImportDepositTableComponent,
    DepositImportColumnRowComponent,
    DepositImportColumnCustomComponent,
    DepositImportColumnDescriptionComponent,
    DepositImportColumnComponent,
    DepositImportProgressComponent,
    ImportProgressWrapperComponent,
    ImportProgressStickyFooterComponent
  ],
  providers: [ImportValidatorsService
    , ImportConfigurationService
    , ImportProgressService
    , DepositLocationImportService
    , ImportSuccessSummaryService
  ],
  exports: [
    ImportSpreadsheetComponent,
    DepositImportColumnRowComponent,
    DepositImportColumnCustomComponent,
    DepositImportColumnDescriptionComponent,
    DepositImportColumnComponent,
    ImportProgressWrapperComponent,
    ImportProgressStickyFooterComponent
  ]
})
export class ImportSpreadsheetModule { }
