import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { PassportKeywordJunction } from '../models/passport-keyword-junction.class';

@Injectable()
export class PassportKeywordJunctionService extends CrudService {
  protected namespace = 'passport-keyword-junctions';
  protected namespaceSingular = 'passport-keyword-junction';
  protected ModelClass = PassportKeywordJunction;
}
