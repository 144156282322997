import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformTag } from '../models/transform-tag.class';
var TransformTagService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformTagService, _super);
    function TransformTagService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-tags';
        _this.namespaceSingular = 'transformation-tag';
        _this.ModelClass = TransformTag;
        return _this;
    }
    return TransformTagService;
}(CrudService));
export { TransformTagService };
