import * as tslib_1 from "tslib";
import { Http, RequestOptions, XHRBackend } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { GlobalEventsManager } from './global-events-manager.service';
import { Router } from '@angular/router';
var AuthenticatedHttpService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthenticatedHttpService, _super);
    function AuthenticatedHttpService(backend, defaultOptions, router, globalEventsManger) {
        var _this = _super.call(this, backend, defaultOptions) || this;
        _this.router = router;
        _this.globalEventsManger = globalEventsManger;
        return _this;
    }
    AuthenticatedHttpService.prototype.request = function (url, options) {
        var _this = this;
        return _super.prototype.request.call(this, url, options).catch(function (error) {
            if ((error.status === 401 || error.status === 403)) {
                _this.globalEventsManger.showNavBar(false);
                _this.router.navigate(['']);
            }
            return Observable.throw(error);
        });
    };
    return AuthenticatedHttpService;
}(Http));
export { AuthenticatedHttpService };
