<section class="ecosystem-compiler">
  <div class="ecosystem-compiler__menu-header">
    <h3 class="ecosystem-compiler__menu-header-title uppercase">
      {{ 'Deposit' | translate }}
    </h3>
    <div class="ecosystem-compiler__menu-header-vertical-line"></div>
    <h3 class="ecosystem-compiler__menu-header-description">{{ order.deposit.name }}</h3>
  </div>

  <ul class="ecosystem-compiler__tab-list">

    <li class="ecosystem-compiler">
      <header class="ecosystem-compiler__menu-item-header">
        <div class="ecosystem-compiler__menu-item-header-container">
          <img
            class="ecosystem-compiler__menu-item-header-container-icon"
            src="../../../../../assets/svg/ecosystem-compiler-offer--icon.svg"
          />
          <h3 class="ecosystem-compiler__menu-item-header-container-title">
            {{ 'Order' | translate }}
          </h3>
        </div>
        <div class="ecosystem-compiler__menu-item-header-container">
          <div
            class="ecosystem-compiler__order-status--standby ecosystem-compiler__menu-item-header-container-status"
          >
            {{step.getStatusText() | capitalize | translate}}
          </div>
          <div class="ecosystem-compiler__expander" (click)="changeTab(this.tabType.ORDER); expandTextAreas()">
            <button class="ecosystem-compiler__fold-tab button-transparent">
              <i
                class="icon"
                [ngClass]="isOpen(this.tabType.ORDER) ? 'icon--fold-tab-white' : 'icon--unfold-tab-white' "
              ></i>
            </button>
          </div>
        </div>
      </header>

      <section class="ecosystem-compiler__menu-item-section" *ngIf="isOpen(this.tabType.ORDER)">
        <div class="ecosystem-compiler-offer-section">
          <conversion-field
            [emptyQuantityHolder]="''"
            [setToggleColorActive]="isReadOnly()"
            [setDefaultConversionOff]="order.order_passport.isWeightExist()"
            class="ecosystem-compiler-offer-section-conversion"
            [identifier]="'deposit_order' + '_'  + step.id"
            [placeholder]="getOrderQuantityText()"
            [quantity]="order.order_passport?.quantity"
            [quantityInTonsOnInit]="order.order_passport?.weight"
            [volumeUnit]="order.order_passport?.unit"
            [conversionFactor]="order.deposit.getConversion()"
            [isDisabled]="isReadOnly()"
            (quantityInTonsChanged)="updateQuantity($event)"
            (quantityChanged)="updateWeight($event)"
            >
          </conversion-field>

          <div class="ecosystem-compiler-offer-section-needed-quantity">
            <conversion-field
              [emptyQuantityHolder]="''"
              [identifier]="'deposit_order_remaining'"
              [setToggleColorActive]="true"
              class="ecosystem-compiler-offer-section-conversion"
              placeholder="{{ 'Remaining available quantity' | translate }}"
              [volumeUnit]="order.order_passport?.unit"
              [conversionFactor]="order.deposit.getConversion()"
              [quantity]="calculateAvailableAmount()"
              [isDisabled]="true">
            </conversion-field>
          </div>
        </div>
        <div class="ecosystem-compiler-date-section">
          <div class="ecosystem-compiler-date-section-column">
            <span class="ecosystem-compiler-date-section-title" [ngClass]="{'primary-color': !isReadOnly()}">
              {{ 'Pickup date' | translate }}
            </span>
            <md-input-container class="ecosystem-compiler-date-section-input">
              <input
                [readonly]="isReadOnly()"
                mdInput
                autocomplete="off"
                ng2-datetime-picker
                date-only="true"
                date-format="DD-MM-YYYY"
                parse-format="YYYY-MM-DD"
                [(ngModel)]="order.order_passport.linked_datetime"
                (valueChanged)="saveStep(true);"
                />
            </md-input-container>
          </div>
        </div>
        <div class="ecosystem-compiler-offer-section">
          <span class="ecosystem-compiler-offer-section-title ecosystem-compiler-offer-section-address">
            {{ 'Pickup address' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-offer-section-text-area">
            <textarea
              rows="1"
              #orderDeliveryAddress
              [value]="order.address.address"
              disabled
              mdInput
            ></textarea>
          </md-input-container>
            <span class="ecosystem-compiler-offer-section-title" [ngClass]="{'primary-color': !isReadOnly()}">
              {{ 'Price' | translate }}
            </span>
          <div class="ecosystem-compiler-offer-section-description-container">
            <md-input-container   class="ecosystem-compiler-offer-section-price">
              <input
                type="number"
                mdInput
                [(ngModel)]="order.price"
                [readonly]="isReadOnly()"
                (change)="saveStep()"
                autocomplete="off"/>
            </md-input-container>
            <span class="ecosystem-compiler-offer-section-currency">€</span>
            <i
              class="form-group__label--question ecosystem-compiler-offer-section-description"
              mdTooltip="{{'ESM_COMPILER_NEED_STEP_PRICE_TOOLTIP' | translate}}"
              mdTooltipPosition="above"
              >?</i>
          </div>
          <span class="ecosystem-compiler-offer-section-title" [ngClass]="{'primary-color': !isReadOnly()}">
            {{ 'Description' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-offer-section-text-area">
            <textarea
              rows="1"
              [readonly]="isReadOnly()"
              #orderDepositDescription
              [value]="this.order.description"
              (input)="onOrderDescriptionChange($event.target.value); expandTextAreas()"
              mdInput
              autocomplete="off"
            ></textarea>
          </md-input-container>
          <div class="ecosystem-compiler-offer-section-title  primary-color">
            {{ 'Documents' | translate }}
          </div>
          <ul>
            <div class="ecosystem-compiler-offer-section-document-container">
              <li class="upload-files__download ecosystem-compiler-offer-section-icon">
                <input
                  class="upload-files__add ecosystem-compiler-offer-section-icon"
                  autocomplete="off"
                  type="file"
                  >
                  <label >
                    <i (click)="openFiles()" class="upload-files__camera documents--icon"></i>
                  </label>
              </li>
              <document-item *ngFor="let junction of order.ecosystem_file_junction"
              [noLink]="true"
              [file]="junction.ecosystem_file"
              [isRemoveFromFilesDisabled]="false"
              (remove)="removeJunctionFromOrder(junction)">
              </document-item>
            </div>
          </ul>
        </div>
      </section>
    </li>

    <li class="ecosystem-compiler__menu-tab">
      <header class="ecosystem-compiler__menu-item-header">
        <div class="ecosystem-compiler__menu-item-header-container">
          <img
            class="ecosystem-compiler__menu-item-header-container-icon"
            src="../../../../../assets/svg/ecosystem-compiler-deposit-summary--icon.svg"
          />
          <h3 class="ecosystem-compiler__menu-item-header-container-title">
            {{ 'Deposit Summary' | translate }}
          </h3>
        </div>
        <div class="ecosystem-compiler-menu-tab-expander" (click)="changeTab(this.tabType.SUMMARY)">
          <button class="button-transparent">
            <i
              class="icon"
              [ngClass]="isOpen(this.tabType.SUMMARY) ? 'icon--fold-tab-white' : 'icon--unfold-tab-white' "
            ></i>
          </button>
        </div>
      </header>
      <section class="ecosystem-compiler__tab-section" *ngIf="isOpen(this.tabType.SUMMARY)">
        <div class="ecosystem-compiler-need-summary">
          <span class="ecosystem-compiler-need-summary-data-title">
                {{ 'Deposit name' | translate }}
          </span>
          <div class="ecosystem-compiler-need-summary-name-row">
            <md-input-container class="ecosystem-compiler-need-summary-name-field">
              <input
                disabled
                mdInput
                [value]="order.deposit.name"/>
            </md-input-container>
              <div class="ecosystem-compiler-need-summary-link">
                <img src="../../../../../assets/svg/ecosystem-compiler-eye--icon.svg" class="ecosystem-compiler-need-summary-link-eye" >
                <button (click)="viewDetails(step)">
                  <span  class="ecosystem-compiler-need-summary-link-text primary-color">{{ 'see the deposit' | translate }}</span>
                </button>
              </div>
          </div>
          <span class="ecosystem-compiler-need-summary-data-title">
              {{ 'Circular passport' | translate }}
          </span>
          <md-input-container   class="ecosystem-compiler-need-summary-name-field">
              <input
                mdInput
                [value]="order.order_passport.name"
                autocomplete="off"
                disabled
              />
          </md-input-container>
          <span class="ecosystem-compiler-need-summary-data-title">
              {{ 'Total quantity' | translate }}
          </span>
          <div class="ecosystem-compiler-need-summary-accuracy-container">
              <md-input-container class="ecosystem-compiler-need-summary-required-field">
                <input
                  mdInput
                  [value]="order.deposit.quantity"
                  autocomplete="off"
                  disabled
                />
              </md-input-container>
              <span>
                {{ order.deposit.passport.unit_text | translate}}
              </span>
          </div>
          <span class="ecosystem-compiler-need-summary-data-title">
            {{ 'Availability date' | translate }}
          </span>
          <div class="ecosystem-compiler-need-summary-accuracy-container">
            <md-input-container class="ecosystem-compiler-need-summary-required-field">
              <input
                mdInput
                [value]="getDepositAvabilityDate()"
                autocomplete="off"
                disabled
              />
            </md-input-container>
          </div>
          <span class="ecosystem-compiler-need-summary-data-title">
            {{ 'Delivery address' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-need-summary-text-area">
            <textarea
              rows="1"
              #depositSummaryAddress
              [value]="order.deposit_location.name"
              mdInput
              disabled
            ></textarea>
          </md-input-container>
          <span class="ecosystem-compiler-need-summary-data-title">
            {{ 'Description' | translate }}
          </span>
          <md-input-container class="ecosystem-compiler-need-summary-text-area">
            <textarea
              rows="1"
              #depositSummaryDescription
              [value]="order.deposit.description"
              mdInput
              disabled
            ></textarea>
          </md-input-container>
        </div>
      </section>
    </li>

    <li class="ecosystem-compiler__menu-tab" >
      <header class="ecosystem-compiler__menu-item-header">
        <div class="ecosystem-compiler__menu-item-header-container">
          <img
            class="ecosystem-compiler__menu-item-header-container-icon ecosystem-compiler__menu-item-header-container-chat-icon"
            src="../../../../../assets/svg/ecosystem-compiler-chat-with-need-manager--icon.svg"
          />
          <div>
            <h3 class="ecosystem-compiler__menu-item-header-container-title">
              {{ 'Chat with deposit owner' | translate }}
            </h3>
            <div class="ecosystem-compiler__menu-item-header-container-title">
              -
              {{ order.owner.username }}
            </div>
          </div>
        </div>
        <div class="ecosystem-compiler__expander" (click)="changeTab(this.tabType.CHAT)">
          <button class="ecosystem-compiler__fold-tab button-transparent">
            <i
              class="icon"
              [ngClass]="isOpen(this.tabType.CHAT) ? 'icon--fold-tab-white' : 'icon--unfold-tab-white' "
            ></i>
          </button>
        </div>
      </header>

      <section class="ecosystem-compiler__tab-section" *ngIf="isOpen(this.tabType.CHAT) && room">
        <chat-form
          [room]="room"
          *ngIf="room"
        ></chat-form>
      </section>
    </li>
  </ul>
</section>
