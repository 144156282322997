import { FormGroup } from '@angular/forms';
/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { CrudService } from '../../services/crud.service';
import { UploadedDocument } from '../../models/uploaders/document.class';
import { ReadOnlyService } from '../../read-only/read-only.service';
import { LogNotificationService } from '../../services/log-notification.service';
import { Subscription } from 'rxjs/Subscription';
import { Utils } from '../../utils/utils.class';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { FileItem } from 'ng2-file-upload/file-upload/file-item.class';

@Component({
  moduleId: module.id,
  selector: 'documents-uploader',
  templateUrl: 'documents-uploader.component.html',
  styleUrls: ['documents-uploader.component.scss'],
})
export class DocumentsUploaderComponent implements OnInit, OnDestroy {

  @Input() uploadUrl: string;
  @Input() notShowClickErrors: boolean;
  @Input() service: CrudService;
  @Input() files: UploadedDocument[] = [];
  @Input() uploadUrlMissingErrorText: string;
  @Input() public uploadUrlSet?: EventEmitter<string>;
  @Input('uploader') set customUploader(uploader: FileUploader) {
    if (uploader) {
      this.uploader = uploader;
    }
  }
  uploader: FileUploader = new FileUploader({});
  @Input() type: number;
  @Input() isRemoveFromFilesDisabled = false;
  @Input() isRemoveFromQueueDisabled = false;
  @Input() isDownLoadDisabled = false;
  @Input() hideUploadButton = false;
  @Input() isMultiple = true;
  @Input() beforeRemoveAction: (file: UploadedDocument) => any;
  @Output() onItemUploaded = new EventEmitter();
  @Output() onAllItemsUploaded = new EventEmitter();
  @Output() onAfterAddingFile = new EventEmitter();
  @Output() onAfterRemove = new EventEmitter();
  @Output() onAfterQueueRemove = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() isUploading = new EventEmitter<boolean>();
  subscription: Subscription;
  @Input() autoupload = true;
  onUploadAllSubscription: Subscription;
  @Input() public onUploadAll?: EventEmitter<string>;
  @Input() public acceptedFormats: string[];
  @Input() maxFileSize: number; // Size in bytes. For example, for 5MB: 5 * 1024 * 1024
  @Input() public maxAllowedItems = 999;

  @Input() set isDisabled(state: boolean) {
    this.isRemoveDisabled = state;
    this.isDownLoadDisabled = state;
  }
  @Input() set isRemoveDisabled(state: boolean) {
    this.isRemoveFromFilesDisabled = state;
    this.isRemoveFromQueueDisabled = state;
  }
  @Input() enableQueueFileEdit = false;
  @ViewChild('fileInput') uploaderElem;

  constructor(
    @Optional() public readOnlyService: ReadOnlyService,
    private _translate: TranslateService,
    public notification: LogNotificationService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    if (this.uploadUrl || !this.autoupload) {
      this.initUploader();
    }

    if (this.uploadUrlSet) {
      this.subscription = this.uploadUrlSet.subscribe((url: string) => {
        this.uploadUrl = url;
        this.initUploader();
      });
    }

    if (!this.autoupload) {
      this.onUploadAllSubscription = this.onUploadAll.subscribe((url: string) => {
        this.uploader.uploadAll();
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    if (this.onUploadAllSubscription) {
      this.onUploadAllSubscription.unsubscribe();
      this.onUploadAllSubscription = null;
    }
  }

  initUploader() {
    this.uploader.setOptions({
      url: this.uploadUrl,
      itemAlias: 'file',
      autoUpload: this.autoupload,
    });
    if (!!this.type) {
      this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
        form.append('type', this.type);
      };
    }
    this.uploader.onSuccessItem = ((item, file) => {
      this.files.push(this.service.buildModel<UploadedDocument>(file));
    });
    this.uploader.onBeforeUploadItem = (item) => {
      this.isUploading.emit(true);
    };
    this.uploader.onCompleteAll = () => {
      this.onAllItemsUploaded.emit(this.files);
      this.uploader.clearQueue();
      this.isUploading.emit(false);
    };
    this.uploader.onCompleteItem = ((_, file) => {
      this.onItemUploaded.emit(this.service.buildModel<UploadedDocument>(file));
    });
    this.uploader.onAfterAddingFile = (fileItem) => {
      if (this.acceptedFormats && !this.acceptedFormats.includes(fileItem._file.name.split('.').reverse()[0])) {
        this.notification.error(this._translate.instant(`Accepted formats : ${this.acceptedFormats.map(ext => `.${ext}`).join(', ')}`), null, true);
        this.uploader.queue.pop();
        return;
      }

      if (this.maxFileSize && fileItem._file.size > this.maxFileSize) {
        this.notification.error(this._translate.instant('File size exceeds the allowed limit'), null, true);
        this.uploader.queue.pop();
        return;
      }

      fileItem.file['previewPath'] = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
      this.onAfterAddingFile.emit(this.uploader.queue);
    };

    this.uploader.onErrorItem = Utils.onUploadError((event: string) => this.notification.error(event, null, true));
  }

  remove(index: number) {
    const file = this.files[index];
    if (file && this.onBeforeRemove(file)) {
      file.destroy().subscribe(() => {
        this.files.splice(index, 1);
        this.onAfterRemove.emit(file);
      });
    }
  }

  private onBeforeRemove(file: UploadedDocument) {
    let isCancelled = false;
    if (this.beforeRemoveAction) {
      isCancelled = this.beforeRemoveAction(file);
      isCancelled = isCancelled === undefined ? false : !isCancelled;
    }
    return !isCancelled;
  }

  removeFromQueue(index: number) {
    this.uploader.queue.splice(index, 1);
    this.onAfterQueueRemove.emit(this.uploader.queue);
  }

  onUploadButtonClick(event: Event) {
    this.onClick.emit(event);
    if (this.notShowClickErrors) {

      if (!this.uploadUrl && this.autoupload) {
        if (this.uploadUrlMissingErrorText) {
          this.notification.error(this.uploadUrlMissingErrorText);
        }
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }
    if (!!this.uploaderElem) {
      this.uploaderElem.nativeElement.click();
    }
  }

  getExtensionName(fileName: string) {
    const fileNameParts = fileName.split('.');
    return fileNameParts.length > 1 ? fileNameParts.reverse()[0] : '';
  }

  isImage(filename: string) {
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(this.getExtensionName(filename));
  }

  get queueFiles() {
    return this.uploader.queue.length;
  }

  isDownloadEnable() {
    return !this.hideUploadButton
      && !(this.readOnlyService && this.readOnlyService.readOnly)
      && this.uploader.queue.length + this.files.length < this.maxAllowedItems;
  }

  queueNameChange(newName: string, file: FileItem) {
    if (this.enableQueueFileEdit) {
      file.file.name = newName + '.' + this.getExtensionName(file.file.name);
    }
  }

  queueNameTransform(name: string) {
    const extLen = this.getExtensionName(name).length;
    const baseNameEndIndex = !!extLen ? -(extLen + 1) : undefined;
    return name.slice(0, baseNameEndIndex);
  }
}
