/**
 * Created by Ken P on today
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Deposit } from '../../../models/deposit.class';
import { Router } from '@angular/router';
import { DEPOSIT_STATUSES } from '../../../values/deposit-statuses.const';
import { Utils } from '../../../../../shared/utils/utils.class';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { MdDialog } from '@angular/material';
import { DepositDetailsDialogComponent } from '../../../dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { Condition } from '../../../../../shared/interfaces/filter-states.interface';
import { PASSPORT_UNITS_MAP } from '../../../../passport/values/passport-units.const';
import { EDepositItemType } from '../../../values/deposit-item-type.enum';
import { Passport } from '../../../../passport/models/passport.class';

@Component({
  moduleId: module.id,
  selector: 'deposit-checkable-item',
  templateUrl: 'deposit-checkable-item.component.html',
  styleUrls: ['deposit-checkable-item.component.css'],
})
export class DepositCheckableItemComponent {
  DEPOSIT_ITEM_TYPE = EDepositItemType;
  @Input() deposit: Deposit;
  @Input() isChecked: Boolean;
  @Input() itemView: EDepositItemType = EDepositItemType.AGENT_ITEM;
  @Output() onOpen: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onDuplicate: EventEmitter<any> = new EventEmitter();
  @Output() onCheck: EventEmitter<Number> = new EventEmitter();
  @Output() onCreateEcosystem: EventEmitter<Deposit> = new EventEmitter();
  @Output() onCreateNeed: EventEmitter<Deposit> = new EventEmitter();
  @Output() onNotifyReseller: EventEmitter<Deposit> = new EventEmitter();

  DEPOSIT_STATUSES = DEPOSIT_STATUSES;
  PASSPORT_UNITS_MAP = PASSPORT_UNITS_MAP;
  constructor(private _dialog: MdDialog,
    private router: Router,
    public state: NavbarStateService) {

  }

  get isESMMatchType() {
    return this.itemView === this.DEPOSIT_ITEM_TYPE.ESM_NEED_MATCH_ITEM;
  }
  get isNeedType() {
    return this.itemView === this.DEPOSIT_ITEM_TYPE.NEED_ITEM;
  }
  get isAgentType() {
    return this.itemView === this.DEPOSIT_ITEM_TYPE.AGENT_ITEM;
  }

  openDeposit(record: Deposit) {
    this.onOpen.emit(record);
  }

  createNeed(recordPassport: Deposit) {
    this.onCreateNeed.emit(recordPassport);
  }

  duplicateDeposit(record: Deposit) {
    this.onDuplicate.emit(record);
  }

  toggleChecked(id: Number) {
    this.onCheck.emit(id);
  }

  deleteDeposit(record: Deposit) {
    this.onDelete.emit(record);
  }

  statusText() {
    if (this.deposit.quantity <= 0 && this.deposit.status === DEPOSIT_STATUSES.PUBLISHED.value) {
      return 'Out of stock';
    }
    return this.deposit.statusData.view;
  }

  formatDate($dateString) {
    return $dateString ? Utils.formatDate($dateString) : '';
  }

  getConditionName(conditionId) {
    return Condition[conditionId || 1];
  }

  getLogoThumbnailStyle(url: string): {[klass: string]: any} {
     return {
        'background': `url(${url}) no-repeat center center`,
        'height': '100%',
        'width': '100%',
        'background-size': '100% 100%'
    };
  }

  openDepositDialog() {
    this._dialog.open(DepositDetailsDialogComponent, {
      ...DIALOG_THEME.WIDE_PRIMARY,
      data: {
        id: this.deposit.id,
        readOnly: true,
        isDo: false,
        showValidationButtons: false
      }
    });
  }
}