import { EPassportTabFormGroup, PASSPORT_ADDITIONAL_TAB_MODEL_FORM_JUNCTION, PASSPORT_FORM_MODEL_JUNCTION, PASSPORT_GENERAL_TAB_MODEL_FORM_JUNCTION, PASSPORT_LONG, PASSPORT_MATERIAL_TAB_MODEL_FORM_JUNCTION, PASSPORT_NEXT_USAGE_TAB_MODEL_FORM_JUNCTION, PASSPORT_SHORT, PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION } from '../../values/passport-validation-fields.const';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Passport } from '../../models/passport.class';
import { PassportTabFormBuilderModel } from './passport-tab-form-builder.model';
import lodash from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var PassportGroupBuilderService = /** @class */ (function () {
    function PassportGroupBuilderService(fb) {
        this.fb = fb;
    }
    PassportGroupBuilderService.defaultChange = function (control) {
        return function (controlName) {
            var property = PassportGroupBuilderService.getModelJunction(controlName);
            if (PassportGroupBuilderService.isStringKey(property)) {
                return function (val) { return control.value[property] = val; };
            }
            if (PassportGroupBuilderService.isArrayPath(property)) {
                var _a = PassportGroupBuilderService.getArrayModelValueFromArray(control, property), lastKey_1 = _a.lastKey, lastObj_1 = _a.lastObj;
                return function (val) { return lastObj_1[lastKey_1] = val; };
            }
            return null;
        };
    };
    PassportGroupBuilderService.getModelJunction = function (name) {
        return PASSPORT_FORM_MODEL_JUNCTION[name];
    };
    PassportGroupBuilderService.isStringKey = function (property) {
        return typeof property === 'string' && !!property.length;
    };
    PassportGroupBuilderService.isArrayPath = function (property) {
        return property && Array.isArray(property) && !!property.length;
    };
    PassportGroupBuilderService.getArrayModelValueFromArray = function (control, array) {
        var lastObj = control.value;
        var lastKey;
        array.forEach(function (item) {
            lastObj = lastKey ? lastObj[lastKey] : lastObj;
            lastKey = item;
        });
        return { lastObj: lastObj, lastKey: lastKey };
    };
    PassportGroupBuilderService.defaultOnChangeVisibility = function (name) {
        return function (observableName, value) { return value; };
    };
    PassportGroupBuilderService.defaultValue = function (control) {
        return function (controlName) {
            var modelProperty = PassportGroupBuilderService.getModelJunction(controlName);
            if (PassportGroupBuilderService.isStringKey(modelProperty)) {
                return control.value[modelProperty];
            }
            if (PassportGroupBuilderService.isArrayPath(modelProperty)) {
                var _a = PassportGroupBuilderService.getArrayModelValueFromArray(control, modelProperty), lastKey = _a.lastKey, lastObj = _a.lastObj;
                return lastObj[lastKey];
            }
            return '';
        };
    };
    PassportGroupBuilderService.defaultVisibilityValue = function (property) {
        return true;
    };
    /**
     * Create reactive form with 3 additional functionalities.
     * 1. When form control is changed update model field.
     * <ul>
     *  <li> model will be stored in control __model__</li>
     *  <li> to update passport field on control change used control name model junction constant {@link  PASSPORT_FORM_MODEL_JUNCTION}.</li>
     *  <li> each observable subscription used to update fields will be stored in control __subscription___</li>
     * </ul>
     * 2. Store visibility variable inside form, which can be used to show or hide corresponding to form control DOM node element.
     *  <ul>
     *  <li> visibility is stored in subgroup __visibility__</li>
     *  </ul>
     * 3. Add visibility change rule as control (visibility change trigger).
     * <ul>
     *  <li> visibility trigger control name is like __visibility__<<trigger_name>> </li>
     *  </ul>
     * @param group enum corresponding to different tab in edit-passport-v2 component template,
     * @param model Passport model
     * @param parentGroup parent of subgroup
     * @param isLongVersion show passport log or short version(default = long version)
     */
    PassportGroupBuilderService.prototype.buildSubGroup = function (group, model, parentGroup, isLongVersion) {
        if (isLongVersion === void 0) { isLongVersion = true; }
        var formMeta = this.getMetaData(group);
        var isLongControl = parentGroup ? this.getVisibilityLongControl(parentGroup) : null;
        var isLong = isLongControl !== null ? isLongControl.value : isLongVersion;
        var control = new FormControl(model);
        return new (PassportTabFormBuilderModel.bind.apply(PassportTabFormBuilderModel, [void 0, this.fb, formMeta.long].concat(this.defaults(control))))().addControl(PassportGroupBuilderService.MODEL_CONTROL, control)
            .createDefault()
            .addVisibilityObservable(PassportGroupBuilderService.VISIBILITY_CONTROL, isLong)
            .subscribeToVisibilityObservable(PassportGroupBuilderService.VISIBILITY_CONTROL, lodash.difference(formMeta.long, formMeta.short));
    };
    PassportGroupBuilderService.prototype.getMetaData = function (group) {
        switch (group) {
            case EPassportTabFormGroup.GENERAL:
                return PASSPORT_GENERAL_TAB_MODEL_FORM_JUNCTION;
            case EPassportTabFormGroup.COMPOSITE:
                return PASSPORT_MATERIAL_TAB_MODEL_FORM_JUNCTION;
            case EPassportTabFormGroup.CYCLE:
                return PASSPORT_NEXT_USAGE_TAB_MODEL_FORM_JUNCTION;
            case EPassportTabFormGroup.PERFORMANCE:
                return PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION;
            case EPassportTabFormGroup.ADDITIONAL:
                return PASSPORT_ADDITIONAL_TAB_MODEL_FORM_JUNCTION;
            default:
                throw new Error('Not valid passport tab type: ' + group + ', should be of type EPassportTabFormGroup');
        }
    };
    PassportGroupBuilderService.prototype.addChildGroupToParent = function (parentGroup, newGroup, groupType, connectToParentVisibility, changeStatusIfControlExistInParent) {
        if (connectToParentVisibility === void 0) { connectToParentVisibility = true; }
        if (changeStatusIfControlExistInParent === void 0) { changeStatusIfControlExistInParent = true; }
        var controlName = this.getMetaData(groupType).name;
        if (parentGroup) {
            if (changeStatusIfControlExistInParent) {
                if (parentGroup.get(controlName) && parentGroup.get(controlName).disabled) {
                    newGroup.disable();
                }
            }
            parentGroup.setControl(controlName, newGroup);
            if (connectToParentVisibility) {
                this.subscribeToParentVisibility(parentGroup, newGroup);
            }
        }
    };
    PassportGroupBuilderService.prototype.subscribeToParentVisibility = function (parentGroup, newGroup) {
        var _this = this;
        if (this.getVisibilityLongControl(parentGroup)) {
            this.getVisibilityLongControl(parentGroup).valueChanges.subscribe(function (value) {
                _this.getVisibilityLongControl(newGroup).setValue(value);
            });
        }
    };
    PassportGroupBuilderService.prototype.getVisibility = function (group, name) {
        if (this.getVisibilityLongControl(group)) {
            return name.some(function (key) { return !!group.get(PassportTabFormBuilderModel.VISIBILITY_PREFIX).get(key).value; });
        }
        return true;
    };
    PassportGroupBuilderService.prototype.getVisibilityLongControlValue = function (group) {
        var control = this.getVisibilityLongControl(group);
        return control && control.value;
    };
    PassportGroupBuilderService.prototype.getVisibilityLongControl = function (group) {
        var visibility = group.get(PassportTabFormBuilderModel.VISIBILITY_PREFIX);
        if (visibility) {
            var control = visibility.get(PassportTabFormBuilderModel.VISIBILITY_PREFIX + PassportGroupBuilderService.VISIBILITY_CONTROL);
            return control ? control : null;
        }
        return null;
    };
    PassportGroupBuilderService.prototype.changePassport = function (group, passport, updateValues) {
        var _this = this;
        if (updateValues === void 0) { updateValues = true; }
        this.updateGroupIfPassportChanged(group, passport, updateValues);
        this.getSubControlsNames().forEach(function (name) {
            var subGroup = group.get(name);
            if (subGroup instanceof FormGroup) {
                _this.updateGroupIfPassportChanged(subGroup, passport, updateValues);
            }
        });
    };
    PassportGroupBuilderService.prototype.reset = function (group, excludedKeys) {
        var _this = this;
        if (excludedKeys === void 0) { excludedKeys = []; }
        this.resetGroup(group, excludedKeys);
        this.getSubControlsNames().forEach(function (name) {
            var subGroup = group.get(name);
            if (subGroup instanceof FormGroup) {
                _this.resetGroup(subGroup, excludedKeys);
            }
        });
    };
    Object.defineProperty(PassportGroupBuilderService.prototype, "internalUseControls", {
        get: function () {
            return [PassportTabFormBuilderModel.VISIBILITY_PREFIX,
                PassportTabFormBuilderModel.SUBSCRIPTION_CONTROL_NAME].concat(this.getSubControlsNames());
        },
        enumerable: true,
        configurable: true
    });
    PassportGroupBuilderService.prototype.resetGroup = function (group, excludedKeys) {
        var _this = this;
        Object.keys(group.controls).filter(function (key) { return !_this.internalUseControls.concat(excludedKeys).includes(key); }).forEach(function (key) {
            group.get(key).reset();
        });
        group.get(PassportGroupBuilderService.MODEL_CONTROL).setValue(new Passport());
    };
    PassportGroupBuilderService.prototype.getSubControlsNames = function () {
        var children = [PASSPORT_GENERAL_TAB_MODEL_FORM_JUNCTION,
            PASSPORT_MATERIAL_TAB_MODEL_FORM_JUNCTION,
            PASSPORT_NEXT_USAGE_TAB_MODEL_FORM_JUNCTION,
            PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION,
            PASSPORT_ADDITIONAL_TAB_MODEL_FORM_JUNCTION
        ];
        return children.map(function (meta) { return meta.name; });
    };
    PassportGroupBuilderService.prototype.updateGroupIfPassportChanged = function (group, passport, updateValues) {
        if (updateValues === void 0) { updateValues = true; }
        var modelControl = group.get(PassportGroupBuilderService.MODEL_CONTROL);
        if (modelControl) {
            modelControl.setValue(passport);
            if (updateValues) {
                Object.keys(group.controls).forEach(function (controlName) {
                    if (PASSPORT_FORM_MODEL_JUNCTION[controlName]) {
                        group.get(controlName).setValue(PassportGroupBuilderService.defaultValue(modelControl)(controlName));
                    }
                });
            }
        }
    };
    PassportGroupBuilderService.prototype.unsubscribe = function (group) {
        var unsubscribeForm = function (formGroup) {
            var subscriptions = formGroup.get(PassportTabFormBuilderModel.SUBSCRIPTION_CONTROL_NAME).value;
            subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
        };
        unsubscribeForm(group);
        this.getSubControlsNames().forEach(function (name) {
            var subGroup = group.get(name);
            if (subGroup) {
                unsubscribeForm(subGroup);
            }
        });
    };
    /**
     * Create reactive form with 3 additional functionalities.
     * 1. When form control is changed update model field.
     * <ul>
     *  <li> model will be stored in control __model__</li>
     *  <li> to update passport field on control change used control name model junction constant
     *   {@link PASSPORT_FORM_MODEL_JUNCTION}
     *  </li>
     *  <li> each observable subscription used to update fields will be stored in control __subscription___</li>
     * </ul>
     * 2. Store visibility variable inside form, which can be used to show or hide corresponding to form control DOM node element.
     *  <ul>
     *  <li> visibility is stored in subgroup __visibility__</li>
     *  </ul>
     * 3. Add visibility change rule as control (visibility change trigger).
     * <ul>
     *  <li> visibility trigger control name is like __visibility__<<trigger_name>> </li>
     * </ul>
     * @param model Passport model
     */
    PassportGroupBuilderService.prototype.buildGroupShort = function (model) {
        var control = new FormControl(model);
        return new (PassportTabFormBuilderModel.bind.apply(PassportTabFormBuilderModel, [void 0, this.fb, PASSPORT_SHORT].concat(this.defaults(control))))().addControl(PassportGroupBuilderService.MODEL_CONTROL, control)
            .createDefault();
    };
    /**
     * Create reactive form with 3 additional functionalities.
     * 1. When form control is changed update model field.
     * <ul>
     *  <li> model will be stored in control __model__</li>
     *  <li> to update passport field on control change used control name model junction constant
     *  {@link  PASSPORT_FORM_MODEL_JUNCTION}.</li>
     *  <li> each observable subscription used to update fields will be stored in control __subscription___</li>
     * </ul>
     * 2. Store visibility variable inside form, which can be used to show or hide corresponding to form control DOM node element.
     *  <ul>
     *  <li> visibility is stored in subgroup __visibility__</li>
     *  </ul>
     * 3. Add visibility change rule as control (visibility change trigger).
     * <ul>
     *  <li> visibility trigger control name is like __visibility__<<trigger_name>> </li>
     *  </ul>
     * @param model Passport model
     * @param isLongVersion show passport log or short version(default = long version)
     */
    PassportGroupBuilderService.prototype.buildGroupLong = function (model, isLongVersion) {
        if (isLongVersion === void 0) { isLongVersion = true; }
        var control = new FormControl(model);
        return new (PassportTabFormBuilderModel.bind.apply(PassportTabFormBuilderModel, [void 0, this.fb, PASSPORT_LONG].concat(this.defaults(control))))().addControl(PassportGroupBuilderService.MODEL_CONTROL, control)
            .createDefault()
            .addVisibilityObservable('long', isLongVersion)
            .subscribeToVisibilityObservable('long', lodash.difference(PASSPORT_LONG, PASSPORT_SHORT));
    };
    PassportGroupBuilderService.prototype.defaults = function (control) {
        return [PassportGroupBuilderService.defaultValue(control),
            PassportGroupBuilderService.defaultChange(control),
            PassportGroupBuilderService.defaultVisibilityValue,
            PassportGroupBuilderService.defaultOnChangeVisibility,
            PassportGroupBuilderService.getModelJunction
        ];
    };
    PassportGroupBuilderService.VISIBILITY_CONTROL = 'long';
    PassportGroupBuilderService.MODEL_CONTROL = '__model__';
    PassportGroupBuilderService.ngInjectableDef = i0.defineInjectable({ factory: function PassportGroupBuilderService_Factory() { return new PassportGroupBuilderService(i0.inject(i1.FormBuilder)); }, token: PassportGroupBuilderService, providedIn: "root" });
    return PassportGroupBuilderService;
}());
export { PassportGroupBuilderService };
