import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PIE_CHART_SIZE} from '../../../../values/pie-chart-size.const';
import {ReportService} from '../../../../services/report.service';
import {ICircularity, ICircularityCategory} from '../../../../interfaces/circularity.interface';
import {ActivatedRoute} from '@angular/router';
import {ReportBase} from '../report-base/report-base.class';
import {REPORT_NAME} from '../types/report-name.const';
import {
  getCircularityProductCategory,
  ICircularityProductCategory
} from '../../new-report/circularity/helpers/get-main-product-categories.helper';
import {LanguageService} from '../../../../../../entities/client/service/language.service';
import {IReportPDFTable} from '../types/report-pdf-table.interface';
import {IReportError} from '../../../../interfaces/report-error.interface';
import {TReportName} from '../types/report-name.type';
import {TranslateService} from '@ngx-translate/core';
import {ReportSiteTagPresenterService} from '../../../../services/report-site-tag-presenter.service';
import {ElRowCounterService} from '../../../../services/el-row-counter.service';

@Component({
  moduleId: module.id,
  selector: 'circularity-report',
  templateUrl: 'circularity-report.component.html',
  styleUrls: ['circularity-report.component.scss'],
  providers: [ReportSiteTagPresenterService, ElRowCounterService]
})
export class CircularityReportComponent extends ReportBase<ICircularity, ICircularityCategory> implements OnInit {
  protected reportName: TReportName = REPORT_NAME.CIRCULARITY;

  public categoriesTable: IReportPDFTable[]= [];
  public errorsTable: IReportPDFTable[] = [];

  public categories: ICircularityCategory[] = [];

  public mainProductCategory: ICircularityProductCategory;

  pieChartSize = PIE_CHART_SIZE;

  constructor(
    public reportService: ReportService,
    public activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    public translateService: TranslateService,
    public reportPresenter: ReportSiteTagPresenterService, public rowCounter: ElRowCounterService
  ) {
    super(reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter);
  }

  ngOnInit(): void {
    this.createReport();
  }

  createReport(): void {
    this.reportRequest()
      .subscribe(() => {
        this.loadMainProductCategory();
        this.prepareTablesDataForRender();
    });
  }

  loadMainProductCategory(): void {
    const sortedByGrade = this.getSortedProductsBy('circularity_grade');
    const getProductCategoryBlockData = (key): ICircularityCategory => {
      return key ?
        {
          ...key,
          titleForFooter: key.category.name,
          mainValue: Math.ceil(key.circularity_grade)
        }
        : null;
    };
    this.mainProductCategory = {
      mostCircular: getProductCategoryBlockData(sortedByGrade[0]),
      leastCircular: getProductCategoryBlockData(sortedByGrade[sortedByGrade.length - 1]),
      mostPresent: getProductCategoryBlockData(this.getSortedProductsBy('weight')[0])
    };
  }

  setCategories(): void {
    this.categories = [
      this.getDefaultCategory(),
      ...this.getSortedProductsBy('circularity_grade')
        .filter(product => !!product.deposits_considered)
    ];
  }
  getDefaultCategory(): ICircularityCategory {
    return {
      category: {name: 'Total', parent_id: void 0, id: void 0},
      isDefault: true,
      weight: this.result.aggregate.total_weight,
      deposit_count: this.result.aggregate.deposit_count,
      deposits_considered: this.result.aggregate.deposits_considered,
      demountability_grade: this.result.aggregate.demountability_grade,
      next_usage_potential_grade: this.result.aggregate.next_usage_potential_grade,
      information_characterization_and_rating_grade: this.result.aggregate.information_characterization_and_rating_grade,
      past_life_grade: this.result.aggregate.past_life_grade,
      future_life_grade: this.result.aggregate.future_life_grade,
      circularity_grade: this.result.aggregate.circularity_grade,
      percentage_by_weight: 100,
      reused_percentage_by_weight: this.result.aggregate.reused_percentage,
      percentage_of_recycled_material: this.result.aggregate.recycled_percentage,
    };
  }

  prepareTablesDataForRender(): void {
    this.prepareCategoriesTable();
    this.prepareErrorsTable();
  }

  prepareCategoriesTable(): void {
    this.setCategories();

    this.onEachChunkedCategory((chuck: ICircularityCategory[], index: number) => {
      this.categoriesTable.push({
        pageNumber: index,
        pageContent: chuck
      });
  });
  }

  prepareErrorsTable(): void {
    this.onEachChunkedError((chuck: IReportError[], index: number) => {
      this.errorsTable.push({
        pageNumber: index + this.categoriesTable.length,
        pageContent: chuck
      });
    });
  }

}
