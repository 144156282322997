/**
 * Created by aleksandr on 30.06.17.
 */
import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { EChatMessageTypes, IChatMessage } from '../../../interfaces/chat-message.interface';

@Component({
  moduleId: module.id,
  selector: 'chat-history',
  templateUrl: 'chat-history.component.html'
})

export class ChatHistoryComponent {
  public messageTypes = EChatMessageTypes;
  @Input() public history: IChatMessage[];
  //It could be useful for viewport reading
  @ViewChild('messageList') private _messageList: ElementRef;
  @ViewChildren('messagesElement') private _messagesElement: QueryList<ElementRef>;

  isSecondaryMessage(index: number): boolean {
    let currentMessage = this.history[index];
    let previousMessage = this.history[index - 1];
    if (currentMessage && previousMessage) {
      if (previousMessage.author.id === currentMessage.author.id) {
        return true;
      }
    }
    return false;
  }

  onMessageClick(message: IChatMessage) {
    if(message.file && message.file.length > 0) {
      window.open(message.file[0].file);
    }
  }
}
