/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passport-synchronization-information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./passport-synchronization-information.component";
var styles_PassportSynchronizationInformationComponent = [i0.styles];
var RenderType_PassportSynchronizationInformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PassportSynchronizationInformationComponent, data: {} });
export { RenderType_PassportSynchronizationInformationComponent as RenderType_PassportSynchronizationInformationComponent };
function View_PassportSynchronizationInformationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 11, "div", [["class", "passport-synchronization-toggle"]], null, [[null, "longpress"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).hide(1500) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MdPrefixRejector, [[2, i2.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(2, 212992, null, 0, i2.MdTooltip, [i2.Overlay, i1.ElementRef, i2.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i1.Renderer, i2.Platform, [2, i2.Dir]], { message: [0, "message"] }, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-slide-toggle", [["class", "passport-edit__toggle"]], [[2, "mat-slide-toggle", null], [2, "mat-checked", null], [2, "mat-disabled", null], [2, "mat-slide-toggle-label-before", null]], [[null, "change"], [null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._setMousedown() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onToggleChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MdSlideToggle_0, i4.RenderType_MdSlideToggle)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.MdSlideToggle]), i1.ɵdid(6, 1228800, [[1, 4], ["synchronizationToggle", 4]], 0, i2.MdSlideToggle, [i1.ElementRef, i1.Renderer, i2.FocusOriginMonitor], { disabled: [0, "disabled"], checked: [1, "checked"] }, { change: "change" }), (_l()(), i1.ɵeld(7, 0, null, null, 4, "span", [], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(9, { "passport-edit__toggle-text": 0, "primary-color": 1 }), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.getTooltipMessage())), ""); _ck(_v, 2, 0, currVal_0); var currVal_5 = _co.isDisabled(); var currVal_6 = _co.synchronized; _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_7 = _ck(_v, 9, 0, true, _co.synchronized); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_1 = true; var currVal_2 = i1.ɵnov(_v, 6).checked; var currVal_3 = i1.ɵnov(_v, 6).disabled; var currVal_4 = (i1.ɵnov(_v, 6).labelPosition == "before"); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_8 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("databaseSynchronization")); _ck(_v, 10, 0, currVal_8); }); }
function View_PassportSynchronizationInformationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "passport-synchronization-synchronized primary-color"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "passport-synchronization-synchronized-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Synchronized with "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Source logo"], ["class", "passport-synchronization-synchronized-logo"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoPath; _ck(_v, 3, 0, currVal_0); }); }
function View_PassportSynchronizationInformationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "passport-synchronization-information"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassportSynchronizationInformationComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassportSynchronizationInformationComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEditable; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isEditable; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PassportSynchronizationInformationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { synchronizationToggle: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassportSynchronizationInformationComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSynchronizationInformationShown(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PassportSynchronizationInformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "passport-synchronization-information", [], null, null, null, View_PassportSynchronizationInformationComponent_0, RenderType_PassportSynchronizationInformationComponent)), i1.ɵdid(1, 49152, null, 0, i7.PassportSynchronizationInformationComponent, [], null, null)], null, null); }
var PassportSynchronizationInformationComponentNgFactory = i1.ɵccf("passport-synchronization-information", i7.PassportSynchronizationInformationComponent, View_PassportSynchronizationInformationComponent_Host_0, { synchronized: "synchronized", synchronizable: "synchronizable", isLastVersion: "isLastVersion", isEditable: "isEditable", logoPath: "logoPath" }, { synchronize: "synchronize", desynchronize: "desynchronize" }, []);
export { PassportSynchronizationInformationComponentNgFactory as PassportSynchronizationInformationComponentNgFactory };
