import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import { User } from '../../../shared/models/user.class';
import { UserService } from '../../../shared/services/user.service';

@Component({
  moduleId: module.id,
  selector: 'user-deletion-dialog',
  templateUrl: 'user-deletion-dialog.component.html'
})
export class UserDeletionDialogComponent implements OnInit {

  public userId: number;

  public _userList: User[] = [];
  public _selectedUser: User = null;

  constructor(public _dialog: MdDialogRef<any>,
              private _userService: UserService) {
    this.userId = this._dialog._containerInstance.dialogConfig.data.userId;
  }

  ngOnInit() {
    this._userService.getUsersForTransfer(this.userId).subscribe((res: User[]) => {
      this._userList = res;
    });
  }

  selectUser(user: User) {
    this._selectedUser = user;
    console.log('this.selectedUser: ', this._selectedUser);
  }

  transferDataAndDeleteUser() {
    console.log('transfer data to: ', this._selectedUser);
    if(!!this._selectedUser) {
      this._userService.deleteAndTransferUser(this.userId, this._selectedUser.id)
        .subscribe((successfull) => {
          this._dialog.close(successfull);
        });
    }
  }
}
