import {Response} from '@angular/http';
import {ActiveRecord} from './active-record.class';

export abstract class DataProvider<T extends ActiveRecord> {
  public records: T[];
  abstract buildOptions(): Array<any[]>;
  updateRecords(response: Response, params = {}) {
    this.records = response.json();
  }
}
