import {Injectable} from '@angular/core';
import {LoginInterface} from '../auth/login.interface';
import {Http, Response} from '@angular/http';
import {DeepStreamService} from './deepstream.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {CurrentUserService} from './current-user.service';

@Injectable()
export class AuthService {

  constructor(
    private deepStreamService: DeepStreamService,
    private router: Router,
    private currentUserService: CurrentUserService) {
  }

  login(data: LoginInterface): Observable<any | Response> {
    return this.currentUserService.login(data);
  }

  logout(): Observable<any | Response> {
    return this.currentUserService.logout()
      .do(() => {
        this.deepStreamService.logout();
        this.router.navigate(['login']);
      });
  }

}
