import { BaseModel } from 'ng2-adn-common';
import { IStatsGroup } from '../interfaces/stats-group.interface';

export class StatsContainer<StatsType> extends BaseModel implements IStatsGroup<StatsType> {

  total: StatsType[];
  last_year: StatsType[];
  last_month: StatsType[];
  last_week: StatsType[];

  fields() {
    return [
      'total',
      'last_year',
      'last_month',
      'last_week'
    ];
  }
}
