import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter } from '@angular/core';
import { FormControlName, NgModel } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
var PlaceAutocompleteDirective = /** @class */ (function () {
    function PlaceAutocompleteDirective(elementRef, ngModel, formControlName) {
        var _this = this;
        this.elementRef = elementRef;
        this.ngModel = ngModel;
        this.formControlName = formControlName;
        this.isCreationMode = false;
        this.placeChange = new EventEmitter;
        this.placeSelect = new EventEmitter;
        this.placeSelect.subscribe(function (result) {
            if (_this.formControlName.control) {
                _this.formControlName.control.setValue(result.description);
            }
            if (_this.ngModel.control) {
                _this.ngModel.control.setValue(result.description);
            }
        });
        Observable
            .merge(
        // Observable.fromEvent(this.elementRef.nativeElement, 'change'),
        this.placeSelect)
            .distinctUntilChanged()
            .debounceTime(100)
            .subscribe(function (result) {
            if (_this.isCreationMode) {
                return;
            }
            var placeDetailsService = new google.maps.places.PlacesService(_this.elementRef.nativeElement);
            if (!result.place_id) {
                return;
            }
            placeDetailsService.getDetails({ placeId: result.place_id }, function (place) {
                _this.placeChange.emit(tslib_1.__assign({}, place, { description: result.description }));
            });
        });
    }
    return PlaceAutocompleteDirective;
}());
export { PlaceAutocompleteDirective };
