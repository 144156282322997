import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MdDialog } from '@angular/material';
import { Passport, PASSPORT_DEFAULT_CATEGORY } from '../../models/passport.class';
import 'rxjs/add/operator/switchMap';
import {PASSPORT_UNITS, PASSPORT_UNITS_TYPE} from '../../values/passport-units.const';
import { PassportService } from '../../services/passport.service';
import { PassportFileService } from '../../services/passport-file.service';
import { PassportFile } from '../../models/passport-file.class';
import { CopyPassportDialogComponent } from '../../dialogs/copy-passport-dialog/copy-passport-dialog.component';
import { PassportFormatService } from '../../services/format.service';
import { PassportControlStates } from '../../models/passport-control-states.class';
import { PASSPORT_STATUS } from '../../values/passport-status.const';
import { IParentEvent } from '../../../../shared/interfaces/parent-event.interface';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import { IPeriodOfUseType } from '../../models/period-of-use.interface';
import { PERIOD_OF_USE_TYPES } from '../../values/period-of-use.values';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';
import {UserService} from '../../../../shared/services/user.service';
import {User} from '../../../../shared/models/user.class';
import {CurrentUser} from '../../../../shared/auth/current-user.class';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {PASSPORT_TYPES} from '../../values/passport-types.const';
import {Router} from '@angular/router';
import {PERFORMANCE_RADIOS, PERFORMANCE_RADIOS_VALUES} from '../../values/performance-radios.const';
import {PASSPORT_CERTIFICATION_LEVELS} from '../../values/passport-certification-levels.const';
import {NameGenerationDialogComponent} from '../../dialogs/name-generation-dialog/name-generation-dialog.component';
import {PassportComponent} from '../../models/component.class';
import {Material} from '../../models/material.class';
import {MaterialService} from '../../services/material.service';
import {CustomSearchComponent} from '../../../../shared/custom-search/custom-search.component';
import {AdvancedResearchDialogComponent} from '../../dialogs/advanced-research-dialog/advanced-research-dialog.component';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import { ViewCategoryDialogComponent } from '../../dialogs/view-category/view-category.component';
import {Observable} from 'rxjs/Observable';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Response } from '@angular/http';
import * as moment from 'moment';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { PassportValidationNotificationService } from '../../services/passport-validation-notification.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import {PassportGtinBufferService} from '../../services/passport-gtin-buffer.service';
import {PassportGroupBuilderService} from '../../services/passport-group-builder/passport-group-builder.service';
import {PassportGtinService} from '../../services/passport-gtin.service';
import {PassportGtin} from '../../models/passport-gtin.class';

import { PASSPORT_STATUS_CONST } from '../../values/passport-status.const';
@Component({
  moduleId: module.id,
  selector: 'passport-edit-form',
  templateUrl: 'edit-passport.component.html',
  providers: [ReadOnlyService, {provide: PassportGtinBufferService, useClass: PassportGtinBufferService}]
})
export class EditPassportComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() public listener?: EventEmitter<any>;
  @Input() public showCatalogNameMenu: boolean;
  @Input() public asDialog = false;
  @Input() public disabled = false;
  @Input() public parentModule: string;
  @Input() public enableSearch = true;
  @Input() public allowNameChange = true;
  @Input() public viewMode = 1;
  @Input() parentFormGroup: FormGroup;
  @Input() passport: Passport = new Passport;
  @Input() readOnly = false;
  @Input() spinnerEnabled = true;
  @Input() controlStates = new PassportControlStates;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  @Output() public passportChange: EventEmitter<Passport> = new EventEmitter();
  /**
   * Replaced by onSaveComplete
   * @deprecated
   */
  @Output() public onSave: EventEmitter<any> = new EventEmitter();
  @Output() public onSaveError: EventEmitter<any> = new EventEmitter();
  @Output() public onSaveComplete: EventEmitter<any> = new EventEmitter();
  @Output() public onInvalidField = new EventEmitter();
  @ViewChild('customSearch') customSearch: CustomSearchComponent;
  public files: FileList;
  passportForm: FormGroup;
  createdPassportId: number;
  uploadErrorText: string;
  searchValue: string;
  isPassportChanged: boolean;
  passportSyncSubscription: Subscription;

  units: any[] = PASSPORT_UNITS;
  period_types: IPeriodOfUseType[] = PERIOD_OF_USE_TYPES;
  performanceRadios = PERFORMANCE_RADIOS;
  certificationLevels = PASSPORT_CERTIFICATION_LEVELS;
  VIEW_MODE = VIEW_MODE;
  moduleNames = ModuleNames;
  invalidFields = [];
  eventStream$: EventEmitter<IParentEvent> = new EventEmitter();
  uploadedFiles: PassportFile[] = [];
  onUpdateUploadUrl$: EventEmitter<string> = new EventEmitter();
  formatSuggestions: string[] = [];
  areFormControlsDisabled = true;
  supervisorWarning = false;
  PASSPORT_STATUS_CONST = PASSPORT_STATUS_CONST;
  private advancedReasearchDialog;

  private advancedResearchDialog;
  private requestsSubject = new BehaviorSubject([]);
  private requestSubscription;

  constructor(public dialog: MdDialog,
              public _fb: FormBuilder,
              public gtinBuffer: PassportGtinBufferService,
              public passportService: PassportService,
              public passportFileService: PassportFileService,
              public passportFormatService: PassportFormatService,
              public stateService: NavbarStateService,
              public loadingSpinnerService: LoadingSpinnerService,
              protected router: Router,
              private readOnlyService: ReadOnlyService,
              private navigationService: NavigationService,
              private _userService: UserService,
              private materialService: MaterialService,
              private renderer: Renderer,
              private translate: TranslateService,
              private cd: ChangeDetectorRef,
              private notification: LogNotificationService,
              private _passportValidationNotification: PassportValidationNotificationService,
              private _changeDetectionRef: ChangeDetectorRef,
              private passportFormBuilder: PassportGroupBuilderService,
              private passportGtinService: PassportGtinService

  ) {
    this.saveGtinsInBuffer = this.saveGtinsInBuffer.bind(this);
  }
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }
  onLogoUpload(response: any) {
    let data: any = null;
    try {
      data = typeof response === 'string' ? JSON.parse(response) : response;
    } catch (e) {
      console.log(e);
    }
    if (data) {
      this.passport.set({image: data.image});
      this.passport.set({thumbnail: data.thumbnail});
    }
  }
  getLogoUploaderUrl(): string {
    return this.passportService.getLogoUploadUrl(this.passport.id);
  }
  canEditDocuments(): boolean {
    return this.navigationService.getContext().roleId === ERoleIds.SUPERVISOR
      || this.createdPassportId > 0;
  }
  isSelectAnotherPassportShown(): boolean {
    return this.parentModule === this.moduleNames.AGENT || this.parentModule === this.moduleNames.SALES;
  }
  ngOnInit() {
    this.isPassportChanged = true;
    // this.passport.family = 4;
    this.onChange.subscribe((passport: Passport) => {
      this.passportChange.emit(passport);
      if (this.isPassportChanged) {
        this.isPassportChanged = false;
        this.eventStream$.emit({ key: 'changePassport', data: passport });
      }
      this.enableSearch = (!this.passportExist);
    });

    this.createForm();
    this.setConversionValidator();
    this.gtinBuffer.setNotifier(this.eventStream$, this.saveGtinsInBuffer);

    if (this.passport && this.passport.id > 0) {
      this.setPassport(this.passport);
      this.updateFormControlsDisabled();
    } else {
      this.disableFormControlsWithUpdate();
    }
    this.statusChanged();
    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if (event.key === 'changePassport') {
          this.setPassport(event.data);
        } else if (event.key === 'updatePassport') {
          this.updatePassport(this.passportForm);
        } else if (event.key === 'publishPassport') {
          this.publishPassport(this.passportForm);
        } else if (event.key === 'validatePassport') {
          this.validatePassport();
        } else if (!!this.passport.id) {
          this.savePassport(this.passportForm);
        }
      });
    }
    if (this.parentFormGroup) {
      this.parentFormGroup.setControl('passport', this.passportForm);
    }
    this.translate.get('Cant upload without passport created').subscribe((text) => {
      this.uploadErrorText = text;
    });
    this.requestsSubject
      .skip(1)
      .debounceTime(100)
      .subscribe(() => this.subscribeOnRequests());
    this._passportValidationNotification.passportForm = this.passportForm;
  }

  ngOnDestroy() {
    this._passportValidationNotification.clearPassportForm();
    this.passportFormBuilder.unsubscribe(this.passportForm);
    this.gtinBuffer.unsubcribe();
  }

  // TODO remove save logic in bufferService, it removes edit-passport and edit-passport-v2 duplication code.
  private saveGtinsInBuffer(e: { key: string, data: any }, observable: Observable<any>) {
    if (e.key === 'savePassport') {
      this.onUpdatePassportData(
        {request: observable, type: 'gtins save'}
      );
    }
  }

  setCorrectData(event) {
    if (event) {
      this.passport.certification_expiration = moment(event).format('YYYY-MM-DD');
    }
  }
  unitChange() {
   // this.passportForm.get('conversion').updateValueAndValidity();
    /*console.log(this.passportForm.get('passportUnit').value);
    console.log(this.passportForm.get('conversion').value);
    console.log('hereedsds');
    if (this.isPassportConversionShown() ) {
      console.log('is shown')
      this.passportForm.controls['conversion'].setValidators([Validators.required,Validators.min(0)]);
      this.passportForm.controls['conversion'].updateValueAndValidity();
    }
    else
    {
      console.log('is not shown')
      //this.passportForm.controls['conversion'].updateValueAndValidity();
      //   this.passportForm.controls['conversion'].clearAsyncValidators();
    //  this.passportForm.reset();
      this.passportForm.controls['conversion'].clearValidators()
     // this.passportForm.controls['conversion'].setValidators([Validators.min(0)]);
     // this.passportForm.controls['conversion'].setErrors(null);
      this.passportForm.updateValueAndValidity();
    }
    this._changeDetectionRef.detectChanges(); */
  }
  onLockedPassportInteract($event: Event) {
    if (this.isPassportFrozen()) {
      return ;
    }

    const dialogRef = this.dialog.open(CopyPassportDialogComponent, {
      data: {
        name: this.passport.name,
        isSelectAnotherPassportShown: this.isSelectAnotherPassportShown(),
        parentModule: this.parentModule
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if ($event && !result) {
        this.renderer.invokeElementMethod($event.target, 'blur', []);
      }
      if (result) {
        if (!!result.resetForm) {
            this.changePassport();
            return;
        }
        this.passportService.duplicate(this.passport.id, {
              name: result
            , status: PASSPORT_STATUS.DRAFT
            , family: this.passport.getPassportFamilyBaseOnModule(this.parentModule)
          }, true)
          .subscribe((response: any) => {
          this.passportService.view(response.id, {expand: 'passport_components,passport_materials,nomenclature,english_name,category'})
          .subscribe((duplicatedPassport) => {

           this.setPassport(<Passport>duplicatedPassport);
           // this.updatePassport(this.passportForm);
           this.eventStream$.emit({ key: 'changePassport', data: this.passport });
            this.onSave.emit();

            this.enableFormControlsWithUpdate();
          });
        }, (err: any) => console.log(err));
      } else {
        this.disableFormControlsWithUpdate();
      }
    });
  }

  public changePassport() {
    this.clearForm();
    this.eventStream$.emit({ key: 'changePassport', data: this.passport });
    this.focusOnPassportSelect();
  }

  // If the publication is not published and role === SUPERVISOR -> can be edited.
  updateFormControlsDisabled(): void {
     this.areFormControlsDisabled = this.isPassportFrozen()
       || (this.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor());
  }
  showErrors() {
   return  ! (this.passport.status === PASSPORT_STATUS.PUBLISHED);
  }
  statusChanged() {
    this.updateFormControlsDisabled();
    if (this.areFormControlsDisabled) {
      this.disableFormControls();
    } else {
      this.enableFormControls();
    }
  }
  disableFormControls(): void {
    setTimeout(() => this.passportForm.disable());
    this.eventStream$.emit({key: 'disableStatus', data: {isDisabled: true}});
  }
  enableFormControls(): void {
    Object.keys(this.passportForm.controls).forEach((key) => this.passportForm.controls[key].enable());
    this.eventStream$.emit({key: 'disableStatus', data: {isDisabled: false}});
  }
  disableFormControlsWithUpdate(): void {
    this.disableFormControls();
    this.updateFormControlsDisabled();
  }
  enableFormControlsWithUpdate(): void {
    this.enableFormControls();
    this.updateFormControlsDisabled();
  }
  createForm() {
    this.passportForm = this.passportFormBuilder.buildGroupShort(this.passport).addControlOptions({
      passportName: [this.passport.name, Validators.required],
      passportEnglishName: [this.passport.english_name, Validators.required],
      category: ['', Validators.required],
      passportUnit: [this.passport.unit, Validators.required],
      quantity: [this.passport.period, [Validators.pattern(/^\d+$/), Validators.min(0)]],
      recyclePercentage: [this.passport.recycle_percentage, [Validators.min(0), Validators.max(100)]],
    }).build();
  }
  setConversionValidator () {
    const conversionControl = this.passportForm.get('conversion');
    // const unitControl = this.passportForm.get('passportUnit');
    this.passportForm.get('passportUnit').valueChanges
    .subscribe(unit => {
      // tslint:disable-next-line:triple-equals
      if (unit != PASSPORT_UNITS_TYPE.TONNE && unit != PASSPORT_UNITS_TYPE.KG ) {
        conversionControl.setValidators([Validators.required, Validators.min(0)]);
      } else {
        conversionControl.clearValidators();
        conversionControl.updateValueAndValidity();
      }
    });

  }
  getPassportCustomName(record: Passport) {
    if (record) {
      return record.getProductName(this.stateService.multilanguage);
    }
  }
  onSelect(passport: Passport) {
    this.setPassport(passport);
    if (this.createdPassportId > 0) {
      this.passportService.remove(this.createdPassportId).subscribe(() => {
        this.createdPassportId = -1;
        // this.readOnlyService.readOnly = !this.canEditDocuments();
      });
    }
    this.enableSearch = false;
    this.disableFormControlsWithUpdate();
  }
  setPassport(passport: Passport) {
    this.onChange.emit(passport);
    this.passport = passport;
    this.passportFormBuilder.changePassport(this.passportForm, this.passport);
  //  this.unitChange()
    this.onUpdateUploadUrl$.emit(this.passport.getFileUploadUrl());
    this.passportForm.controls['passportName'].setValue(passport.name);
    this.passportForm.controls['passportEnglishName'].setValue(passport.english_name);
    if (this.passport.id) {
      const categoryName = !!this.passport.category && !!this.passport.category.name && this.passport.category.name;
      const translation = !!categoryName ? this.translate.instant(categoryName) : '';
      this.passportForm.controls['category'].setValue(translation);
    }
    if (!!this.passportSyncSubscription) {
      this.passportSyncSubscription.unsubscribe();
    }
    this.gtinBuffer.setOwnerObject(this.passport);
    this.statusChanged();
    this.preloadUploadedItems();
    this.preloadComponents();
    this.preloadMaterials();
    if (!this.passport.gtins.length) {
      this.preloadGTINs();
    }
    this
      .passportFormatService
      .get()
      .subscribe(
        (res: string[]) => this.formatSuggestions = res
      );
  }
  onNameEdit(name: string) {
    this.passport.name = name;
  }
  onCreate(passport) {
    this.passport = new Passport({name: this.searchValue});
    this.passport.materials = [];
    this.passport.components = [];
    this.passport.category_id = null;
    this.passport.category = null;
    this.passport.version = String(new Date().getFullYear());
    this.uploadedFiles = [];

    if (CurrentUser.infoData.id && this.parentModule === ModuleNames.MANUFACTURER) {
      this._userService.view(CurrentUser.infoData.id, { expand: 'company' })
      .subscribe((user: User) => {
        this.passport.manufacturer = user.company_name;
      });
      this.passport.type = PASSPORT_TYPES.MANUFACTURER;
    }
    if (this.searchValue.trim() !== '') {
      this.passportService.createPassport(this.passport, this.parentModule).subscribe((result) => {
        const {id, name, family, english_name} = result;
        this.createdPassportId = id;
        this.passport = Object.assign(this.passport, {
          id, name, english_name, family, user: new User({...CurrentUser.infoData})
        });
        this.setPassport(this.passport);
        this.enableFormControlsWithUpdate();
        this.onOpenGenerationNameDialog(null, true);
      });
    } else {
      this.enableFormControlsWithUpdate();
      this.onOpenGenerationNameDialog(null, true);
    }
  }
  preloadComponents() {
    if (!this.passport.id) {
      return;
    }
    this.passport.components = [];
    this.passport.onSyncComponents$.subscribe((res: PassportComponent[]) => {
      this.passport.components = res;
    });
  }
  preloadMaterials() {
    if (!this.passport.id) {
      return;
    }
    this.passport.materials = [];
    this.passport.onSyncMaterials$.subscribe((res: Material[]) => {
      this.passport.materials = res;
    });
  }
  preloadUploadedItems() {
    if (!this.passport.id) {
      return;
    }
    this.uploadedFiles = [];
    this.passportFileService
      .get<PassportFile>({passport_id: this.passport.id})
      .subscribe(
        results => {
          for (const item of results) {
            if (item.isVisibleForAll()) {
              this.uploadedFiles.push(new PassportFile(item));
            }
          }
        }
      );
  }

  preloadGTINs() {
    this.passportGtinService.get({passport_id: this.passport.id}).subscribe(
      (result: PassportGtin[]) => this.passport.gtins = result
    );
  }
  savePassport(passportForm: FormGroup): boolean {
    console.log('save passport ');
    if (!passportForm.valid) {
      return false;
    }
    if (this.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor()) {
      return false;
    }
    this.passport.category = this.passportForm.get('category').value
      ? this.passportForm.get('category').value
      : PASSPORT_DEFAULT_CATEGORY;
    this.passport.save().subscribe((res) => {
      this.eventStream$.emit({key: 'savePassport', data: this.passport});
      this.onSave.emit();
    });
    return true;
  }
  publishPassport(passportForm: FormGroup): boolean {
    if (!passportForm.valid) {
      return false;
    }
    this.passport.category = this.passportForm.get('category').value
      ? this.passportForm.get('category').value
      : PASSPORT_DEFAULT_CATEGORY;
    this.passport.publish().subscribe((res) => {
      this.eventStream$.emit({key: 'savePassport', data: this.passport});
      this.onSave.emit();
    });
    return true;
  }
  updatePassport(passportForm: FormGroup) {

        this.passport.update().subscribe( (res) => {
          console.log('update passport:', res);
          this.eventStream$.emit({key: 'savePassport', data: this.passport});
          this.onSave.emit();
        });

    return true;
  }
  validatePassport(): boolean {
    this.passport.publish().subscribe( (res) => {
      console.log('Validate passport:', res);
      this.router.navigate([this.parentModule + '/passports']);
    });
    return true;
  }
  removePassportPreview() {
    this.passportService.removeLogo(this.passport.id).subscribe(() => {
      this.passport.image = null;
      console.log('Deleted');
    });
  }

  public onOpenCopyDialog($event) {
    if (this.validateCopyPassport()) {
      this.onLockedPassportInteract($event);
    }
  }
  public onOpenGenerationNameDialog($event, force?: boolean) {
    if (!this.validateNameGeneration(force)) {
      return;
    }
    const categoryId = this.passport.category_id;
    this.dialog.open(NameGenerationDialogComponent, {
      data: {
        passport: this.passport,
        viewMode: this.viewMode,
        controlStates: this.controlStates,
        enableSearch: this.enableSearch,
        parentModule: this.parentModule,
        selectExistingPassportAvailable: true
      }
    }).afterClosed().subscribe((data: {passport: Passport, clearForm: boolean}) => {
      console.log('afterClosed NameGenerationDialogComponent v1', data);
      if (!data) {
        return;
      }
      if (!!data.clearForm) {
        this.clearForm();
        this.focusOnPassportSelect();
      }
      if (!!data.passport) {
        if (!data.passport.id) {
          this.passportService.createPassport(data.passport, this.moduleNames).subscribe(
            (passport) => {
              this.passport.category_id = categoryId;
              this.setPassport(passport);
              this.enableSearch = false;
            }
          );
        } else {
          this.enableSearch = false;
          data.passport.category_id = categoryId;
          return this.setPassport(data.passport);
        }
      }
    });
  }
  private validatePassportAction() {
    return !this.isPassportFrozen()
    && this.parentModule !== this.moduleNames.SPECIAL_ADMIN;
  }
  public validateCopyPassport() {
    return this.validatePassportAction()
      && !this.readOnly && this.areFormControlsDisabled;
  }
  private validateNameGeneration(force: boolean) {
    return this.validatePassportAction()
      && (force || !this.areFormControlsDisabled);
  }
  public isShownCatalogNameMenu() {
    return (this.showCatalogNameMenu ===  null || this.showCatalogNameMenu ===  undefined)
      ? this.validatePassportAction() : !!this.showCatalogNameMenu;
  }
  private clearForm() {
    this.uploadedFiles = [];
    this.passportFormBuilder.reset(this.passportForm);
    this.enableSearch = true;
    this.passport = new Passport();
  }
  private focusOnPassportSelect() {
    setTimeout(() => { // wait until component is loaded
      if (!!this.customSearch) {
        this.customSearch.focus();
      }
    });
  }
  getLabelClasses(field) {
    return {
      'primary-color': field,
      'label-color': !field,
      'passport-edit__color-transition': true
    };
  }
  isPassportFrozen() {
   return  this.passport.status === PASSPORT_STATUS.FROZEN;
  }
  areC2cAdditionalFieldsShown(): boolean {
    return this.passport.c2c_certified === PERFORMANCE_RADIOS_VALUES.YES;
  }

  openAdvancedResearch($event) {
    this.advancedResearchDialog = this.dialog.open(AdvancedResearchDialogComponent, {
      ...DIALOG_THEME.WIDE_PRIMARY,
      data: {
        includeDrafts: false
      }
    }).afterClosed().subscribe((data) => {
      if (!data) {
        return;
      }
      this.setPassport(data);
      this.enableSearch = false;
      this.disableFormControlsWithUpdate();
    });
  }
  openCategoryeDialog($event: Event): void {
    if ( this.readOnlyService && this.readOnlyService.readOnly) {
      return;
    }
    if ( !!this.areFormControlsDisabled) {
      return;
    }
    const dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.passport.category_id = result.id;
        this.passport.category = result ;
        this.passportForm.controls['category'].setValue(result.name ? this.translate.instant(result.name)   : '');
        this.passportForm.controls['category'].disable();
      }
    });
  }
  isPassportConversionShown(): boolean {
    if (!this.passport || !this.passport.unit) {
      return false;
    }
    return this.passport.unit !== PASSPORT_UNITS_TYPE.KG && this.passport.unit !== PASSPORT_UNITS_TYPE.TONNE;
  }

  presentLoadingSpinner(): void {
    if (!this.spinnerEnabled) {
      return;
    }
    this.cd.detach();
    this.loadingSpinnerService.show('circular passport');
  }
  destroyLoadingSpinner(): void {
    this.loadingSpinnerService.destroy();
    this.cd.reattach();

  }
  handlePassportDataRequest(type?: string, responseError?: Response): {success: () => void, error: () => void} {
    this.requestsSubject.next([]);
    this.destroyLoadingSpinner();
    return {
      success: () => {
        this.onSave.emit();
        this.onSaveComplete.emit();
      },
      error: () => {
        this.onSaveError.emit();
        const duration = 3000;
        this.notification.error(this.translate.instant('CANNOT_BE_SAVED_ERROR', {X: type}), duration);
        setTimeout(() => {
          this.passportService.onError(responseError);
        }, duration);
      }
    };
  }
  onUpdatePassportData(event: {request: Observable<any>, type: string}): void {
    this.presentLoadingSpinner();
    this.requestsSubject.next([
      ...this.requestsSubject.getValue(),
      event.request.catch((err: Response) => Observable.throw({error: err, field: event.type}))
    ]);
  }

  subscribeOnRequests() {
    this.requestSubscription = forkJoin(this.requestsSubject.getValue())
      .subscribe(
        () => this.handlePassportDataRequest().success(),
        (err: {error, field}) => this.handlePassportDataRequest(err.field, err.error).error()
      );
  }

  get passportExist () {
    return this.passport.getProductName();
  }

  get isPassportVoid () {
    return this.passport.isVoid;
  }
 }
