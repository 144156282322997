import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { NextUsageService } from '../services/next-usage.service';
import { NEXT_USAGE } from '../values/next-usage-values.const';
import { TranslateService } from "@ngx-translate/core";
import { ServiceLocator } from "../../../shared/services/service-locator";
import { AffiliateBranch } from './affiliateBranch.class';
var NextUsage = /** @class */ (function (_super) {
    tslib_1.__extends(NextUsage, _super);
    function NextUsage(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = NextUsageService;
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        _this.affiliate_branch = [];
        data = data || {};
        _this.id = data.id;
        _this.passport_id = data.passport_id;
        _this.type = data.type;
        _this.percentage_product = data.percentage_product;
        _this.affiliate_branch = data.affiliate_branch ? data.affiliate_branch.map(function (e) { return new AffiliateBranch(e); }) : [];
        return _this;
    }
    NextUsage.prototype.fields = function () {
        return ['id', 'passport_id', 'type', 'percentage_product', 'affiliate_branch'];
    };
    NextUsage.prototype.getTypeText = function () {
        var _this = this;
        var type = NEXT_USAGE.find(function (type) { return type.value === _this.type; });
        return type ? this.translateService.instant(type.viewValue) : '';
    };
    return NextUsage;
}(ActiveRecord));
export { NextUsage };
