export const LANDFILL_MAP = {
    4: 'inert_soils',
    5: 'gravats',
    6: 'ordinary_industrial_waste',
    8: 'demolition_wood',
    9: 'demolition_wood_max',
    10: 'clean_plaster',
    11: 'paper_and_cardboard',
    12: 'plants'
};

export const LANDFILL_LABEL = {
    4: 'Inert soils',
    5: 'Gravats (bricks, tiles, excluding plaster)',
    6: 'Ordinary Industrial Waste',
    8: 'Demolition Wood',
    9: 'Demolition Wood (with max 10% OIW)',
    10: 'Clean plaster',
    11: 'Paper & Cardboard',
    12: 'Plants'
};

export enum LANDFILL_ENUM {
    inert_soils = 4,
    gravats = 5,
    ordinary_industrial_waste = 6,
    demolition_wood = 8,
    demolition_wood_max = 9,
    clean_plaster = 10,
    paper_and_cardboard = 11,
    plants = 12
}
