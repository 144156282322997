
    <md-dialog-content class="order-details__dialog">
      <dialog-close-button
        (click)="mdDialogRef.close()"
        [color]="'white'"
    ></dialog-close-button>
    
      <sales-offer
        *ngIf="offer?.loaded"
        [offer]="offer"
        (refused)="mdDialogRef.close()"
        (validated)="mdDialogRef.close()"
        (unvalidated)="mdDialogRef.close()"
        (confirmed)="mdDialogRef.close()"
        (askedForModification)="mdDialogRef.close()"
      ></sales-offer>
    </md-dialog-content>
  