<deposit-site-details-base
  [location]="location"
  [isCompanyBuilding]="isCompanyBuilding"
  [uploader]="uploader"
  [uploaderQueueEdit]="true"
  [BIMs]="BIMs"
>
</deposit-site-details-base>

<div class="save-submit-container" *ngIf="!readOnlyService.readOnly">
  <button md-raised-button class="save" (click)="createPreview()">{{ 'Save' | translate }}</button>
</div>
