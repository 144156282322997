<div class="nomenclature-browser">
  <div class="nomenclature-browser__filter">
    <h2 class="nomenclature-browser__filter-title">{{ 'Nomenclature' | translate }}</h2>
    <!--<button class="nomenclature-browser__filter-button active">{{ 'Upcyclea Database' | translate }}</button>-->
    <!--<button class="nomenclature-browser__filter-button">Company Database</button>-->
  </div>
  <div class="nomenclature-browser__search">
    <div class="need-list__search-wrapper">
      <md-input-container>
        <input mdInput
               autocomplete="off"
               name="nomenclature-search"
               placeholder="{{ 'Search in nomenclature' | translate }}"
               [formControl]="search">
      </md-input-container>
    </div>
  </div>
  <div class="nomenclature-browser__tree">
    <tree-root
      *ngIf="treeData"
      #tree
      [nodes]="treeData[0].children"
      (activate)="closeDialog($event)"
      (toggleExpanded)="onTreeEvent($event)">
      <ng-template #treeNodeTemplate let-node="node" let-index="index">
        <span [innerHtml]="node.data.name"></span>
      </ng-template>
    </tree-root>
  </div>
</div>
{{nodes}}
