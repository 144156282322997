import { IModuleNavigation } from '../../shared/interfaces/module-navigation.interface';

export const TRANSFORM_NAVIGATION: IModuleNavigation[] = [
  {
    url: 'transform/transformations/new',
    title: 'New transformation',
    icon: 'plus--icon'
  },
  // {
  //   url: 'transform/statistics',
  //   title: 'Statistics',
  //   icon: 'statistic--icon'
  // },
];
