import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { GenericPassportTagService } from '../service/generic-passport-tag.service';
var GenericPassportTag = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassportTag, _super);
    function GenericPassportTag() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = GenericPassportTagService;
        return _this;
    }
    GenericPassportTag.prototype.fields = function () {
        return [
            'id',
            'name',
        ];
    };
    return GenericPassportTag;
}(ActiveRecord));
export { GenericPassportTag };
