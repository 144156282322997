import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '../../../app/icons.module';
import { BaseComponentsModule } from '../../../app/components/base/base-components.module';
import { FilterTabComponent } from './filter-tab.component';
import { FilterGroupComponent } from './filter-group.component';
import { SearchComponent } from './search.component';
import { MaterialSearchComponent } from './material-search.component';
import { CategorySearchComponent } from './category-search.component';
import { PassportIdSearchComponent } from './passport-id-search.component';
import { DataRepresentativityFilterComponent } from './data-representativity-filter.component';
import { DatabaseFilterComponent } from './database-filter.component';
import { NewFamilyFilterComponent } from './new-family-filter.component';
import { DataProviderFilterComponent } from './data-provider-filter.component';
import { SortByComponent } from './sort-by.component';
import { SelectableListFilterComponent } from './selectable-list-filter.component';
import { SelectableButtonListFilterComponent } from './selectable-button-list-filter.component';
import { NewRatingFilterComponent } from '../../../entities/passport/partials/passport-list/filters/rating-filter/new-rating-filter.component';
import { GenericDeleteWarningComponent } from '../../../app/components/base/dialog/delete-warning/generic-delete-warning.component';
import { EpdTypeFilterComponent } from './epd-type-filter.component';
import { SharedModule } from '../../../shared/shared.module';
import { CreatorFilterComponent } from './creator-filter.component';


@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        FormsModule,
        IconsModule,
        BaseComponentsModule
    ],
    declarations: [
        FilterTabComponent,
        FilterGroupComponent,
        SearchComponent,
        MaterialSearchComponent,
        CategorySearchComponent,
        PassportIdSearchComponent,
        DataRepresentativityFilterComponent,
        DatabaseFilterComponent,
        NewFamilyFilterComponent,
        DataProviderFilterComponent,
        SortByComponent,
        SelectableListFilterComponent,
        SelectableButtonListFilterComponent,
        NewRatingFilterComponent,
        GenericDeleteWarningComponent,
        EpdTypeFilterComponent,
        CreatorFilterComponent
    ],
    exports: [
        FilterTabComponent,
        FilterGroupComponent,
        SearchComponent,
        MaterialSearchComponent,
        CategorySearchComponent,
        PassportIdSearchComponent,
        DataRepresentativityFilterComponent,
        DatabaseFilterComponent,
        NewFamilyFilterComponent,
        DataProviderFilterComponent,
        SortByComponent,
        SelectableListFilterComponent,
        SelectableButtonListFilterComponent,
        NewRatingFilterComponent,
        GenericDeleteWarningComponent,
        EpdTypeFilterComponent,
        CreatorFilterComponent
    ]
})
export class FilterComponentsModule { }
