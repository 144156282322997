<confirm-with-header *ngIf="orderParent"
                     class="order-transaction-confirmation
         order-transaction-confirmation--textarea-no-horizontal-resize
         order-transaction-confirmation--input-disabled-colored
         order-transaction-confirmation--input-placeholder-disabled-colored
         order-transaction-confirmation--input-placeholder-text
         order-transaction-confirmation--input-placeholder-float-no-overflow
         transformation-order-transaction-confirm"
                     [ngClass]="{'order-transaction-confirmation--disabled': disabled}"
                     [title]="title"
                     [confirmButtonText]="'Confirm data' | translate"
                     (onConfirm)="this.confirmOrder()"
                     (onCancel)="this.onCancel.emit()"
>
  <article [formGroup]="form" class="transformation-order-transaction-confirm__main">
    <header *ngIf="!disabled; else entityInfo" class="transformation-order-transaction-confirm__header">
      <h3 class="order-transaction-confirmation__foreword-title
        transformation-order-transaction-confirm__header-title">
          {{'Your transformation has been completed?' | translate}}
      </h3>
      <h4 class="order-transaction-confirmation__foreword-main
        transformation-order-transaction-confirm__header-title-sub">
        <span>
          <span>{{'Please confirm or update the following values' | translate}}</span>
          <span *ngIf="!showESMText">-</span>
        </span>
        <span *ngIf="!showESMText">{{'they will be sent to the ecosystem manager' | translate}}</span>
      </h4>
    </header>
    <ng-template #entityInfo>
      <header class="transformation-order-transaction-confirm__entity">
        <md-input-container
          floatPlaceholder="always"
          class="transformation-order-transaction-confirm__entity-name">
          <input
            mdInput
            [disabled]="true"
            [value]="orderParent.transformation.name"
            [placeholder]="'Transformation name' | translate"
          >
        </md-input-container>
        <md-input-container
          floatPlaceholder="always"
          class="transformation-order-transaction-confirm__entity-name-eng"
        >
          <input
            mdInput
            [disabled]="true"
            [value]="orderParent.transformation.english_name"
            [placeholder]="'English version of the name' | translate"
          >
        </md-input-container>
      </header>
    </ng-template>

    <ng-template #resultField let-orderControl='control' let-datePlaceholder='datePlaceholder'>
      <ng-container [formGroup]="orderControl">
        <order-transaction-confirm-result-field
          [formControlName]="'quantity'"
          [type]="RESULT_FIELD_TYPE.CONVERSION"
          [placeholderExpected]="'Quantity (expected)' | translate"
          [placeholderConfirmed]="'Quantity (confirmed)' | translate"
          [defaultValue]="getControlEntity(orderControl).orderInQuantityView"
          [conversionFactor]="getControlEntity(orderControl).conversionInDefaultView"
          [conversionUnit]="getControlEntity(orderControl).unitInView"
        >
        </order-transaction-confirm-result-field>
        <order-transaction-confirm-result-field *ngIf="transformType !== TRANSFORM_TYPE.STORAGE"
          [formControlName]="'date'"
          [placeholderExpected]=" datePlaceholder || getPlaceholderInputDateExpected()"
          [placeholderConfirmed]=" datePlaceholder || getPlaceholderInputDateConfirm()"
          [type]="RESULT_FIELD_TYPE.DATE"
          [defaultValue]="getControlEntityPassport(orderControl).linked_datetime">
        </order-transaction-confirm-result-field>
      </ng-container>
    </ng-template>

    <section class="transformation-order-transaction-confirm__section-container">
      <h4 class="transformation-order-transaction-confirm__section-title">
        {{getPlaceholderInputTitle()}}
      </h4>
      <ul formArrayName="inputs"
          class="transformation-order-transaction-confirm__section
                 transformation-order-transaction-confirm__section--input"
          [ngClass]="{
             'transformation-order-transaction-confirm__section--input-store': transformType === TRANSFORM_TYPE.STORAGE
              }">
        <li *ngFor="let orderControl of inputs">
          <h5 class="transformation-order-transaction-confirm__section-title-sub">
            {{getControlEntityPassport(orderControl).name || (getControlEntity(orderControl)?.child_step?.title)}}
          </h5>
          <ul [formGroup]="orderControl" class="order-transaction-confirmation__form-list">
            <ng-container *ngTemplateOutlet="resultField; context: {control: orderControl}"></ng-container>
          </ul>
        </li>
      </ul>
    </section>
    <section *ngIf="isTransformTypeDefault()"
             class="transformation-order-transaction-confirm__section-container">
      <h4 class="transformation-order-transaction-confirm__section-title">{{'Output' | translate}}</h4>
      <ul formArrayName="outputs" class="transformation-order-transaction-confirm__section
                                         transformation-order-transaction-confirm__section--output">
        <li *ngFor="let orderControl of outputs">
          <h5 class="transformation-order-transaction-confirm__section-title-sub">
            {{getControlEntityPassport(orderControl).name || ("Unnamed material" | translate)}}
          </h5>
          <ul [formGroup]="orderControl" class="order-transaction-confirmation__form-list">
              <order-transaction-confirm-result-field
                [formControlName]="'quantity'"
                [type]="RESULT_FIELD_TYPE.CONVERSION"
                [placeholderExpected]="'Quantity (expected)' | translate"
                [placeholderConfirmed]="'Quantity (confirmed)' | translate"
                [defaultValue]="getControlEntity(orderControl).orderOutQuantityView"
                [conversionFactor]="getControlEntity(orderControl).conversionOutDefaultView"
                [conversionUnit]="getControlEntity(orderControl).unitOutView"
              >
              </order-transaction-confirm-result-field>
              <order-transaction-confirm-result-field
                *ngIf="transformType !== TRANSFORM_TYPE.STORAGE"
                [formControlName]="'date'"
                [placeholderExpected]="getPlaceholderOutputDateExpexted()"
                [placeholderConfirmed]="getPlaceholderOutputDateConfirmed()"
                [type]="RESULT_FIELD_TYPE.DATE"
                [defaultValue]="getControlEntityPassport(orderControl).linked_datetime">
              </order-transaction-confirm-result-field>

          </ul>
        </li>
      </ul>
    </section>
    <section class="transformation-order-transaction-confirm__section-container">
      <h4 class="transformation-order-transaction-confirm__section-title ">
        {{'Order modalities' | translate}}</h4>
      <div class="transformation-order-transaction-confirm__section
                  transformation-order-transaction-confirm__section--modalities"
           [ngClass]="{
             'transformation-order-transaction-confirm__section--modalities-store'
                : transformType === TRANSFORM_TYPE.STORAGE,
              'transformation-order-transaction-confirm__section--modalities-transport'
                : transformType === TRANSFORM_TYPE.TRANSPORT
              }">
        <ul class="order-transaction-confirmation__form-list" >
          <ng-container *ngIf="!isTransformTypeDefault()" formArrayName="outputs">
            <ng-container *ngFor="let orderControl of outputs"
                          [formGroup]="orderControl">
              <order-transaction-confirm-result-field
                [formControlName]="'date'"
                [placeholder]="getPlaceholderModalitiesDate()"
                [type]="RESULT_FIELD_TYPE.DATE"
                [defaultValue]="orderParent.out_order_passport.linked_datetime">
              </order-transaction-confirm-result-field>
            </ng-container>
          </ng-container>
          <ng-container [formGroupName]="'modalities'">
            <order-transaction-confirm-result-field
              [formControlName]="'price'"
              [(ngModel)]="orderParent.out_order_passport.transaction_price"
              [placeholderExpected]="'Price (expected)' | translate"
              [placeholderConfirmed]="'Price (confirmed)' | translate"
              [type]="RESULT_FIELD_TYPE.NUMBER"
              [defaultValue]="orderParent.price">

              <span slot="endExpected"><ng-container *ngTemplateOutlet="priceUnit"></ng-container></span>
              <span slot="endConfirmed"><ng-container *ngTemplateOutlet="priceUnit"></ng-container></span>
              <ng-template #priceUnit>
                <span class="order-transaction-confirmation__form-list-row-item-input-unit">€</span>
                <label
                  class="form-group__label order-transaction-confirmation__form-list-tooltip"
                  md-tooltip="{{ 'DO_ORDER_PRICE_TOOLTIP' | translate }}">
                  <i class="form-group__label--question">?</i>
                </label>
              </ng-template>
            </order-transaction-confirm-result-field>
            <order-transaction-confirm-result-field
              *ngIf="orderParent.transformation.transformation_type === TRANSFORM_TYPE.TRANSPORT"
              [formControlName]="'distance'"
              [(ngModel)]="orderParent.out_order_passport.transaction_distance"
              [placeholder]="'Distance to travel'"
              [type]="RESULT_FIELD_TYPE.NUMBER"
              [defaultValue]="orderParent.child_step.estimated_distance">
              <span slot="endExpected"><ng-container *ngTemplateOutlet="distanceUnit"></ng-container></span>
              <span slot="endConfirmed"><ng-container *ngTemplateOutlet="distanceUnit"></ng-container></span>
              <ng-template #distanceUnit>
                <span class="order-transaction-confirmation__form-list-row-item-input-unit">
                  {{'km' | translate}}
                </span>
              </ng-template>
            </order-transaction-confirm-result-field>
          </ng-container>
        </ul>
      </div>
    </section>
    <section>
      <h4 class="transformation-order-transaction-confirm__section-title">{{'Comments and documents' | translate}}</h4>
      <div class="transformation-order-transaction-confirm__section">
        <md-input-container
          class="transformation-order-transaction-confirm__description" floatPlaceholder="always">
          <textarea
            mdInput
            [(ngModel)]="orderParent.out_order_passport.transaction_description"
            [formControlName]="'description'"
            [placeholder]="'Description' | translate"></textarea>
        </md-input-container>
        <label class="order-transaction-confirmation__document-label
          transformation-order-transaction-confirm__documents-label"
               [ngClass]="{'primary-color': !disabled}">
          {{'Traceability documents' | translate}}
        </label>
        <documents-uploader
          class="passport__uploader deposit-order-transaction-confirmation__additional-documents
           transformation-order-transaction-confirm__documents"
          [files]="allFiles"
          [isDisabled]="disabled"
          [autoupload]="false"
          [onUploadAll]="requestFileSenderService.onUploadAll"
          [hideUploadButton]="disabled"
          [service]="ecosystemFileService"
          [uploader]="requestFileSenderService.fileUploader"
          [uploadUrl]="ecosystemService.getFileUploadUrl(orderParent.ecosystem_id)"
          [beforeRemoveAction]="deleteOnlyLinkIfUploaded"
          (onItemUploaded)="requestFileSenderService.onCompleteItem($event)"
          (onAllItemsUploaded)="createFileJunctions($event)"
        >
        </documents-uploader>
      </div>
    </section>
  </article>

</confirm-with-header>
