/**
 * Created by aleksandr on 8.08.17.
 */

import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
import { ILiteral } from 'ng2-adn-common';
import { Need } from '../models/need.class';
import { NeedService } from '../services/need.service';

@Injectable()
export class NeedResolver extends EntityResolver<Need> implements Resolve<Need> {

  protected query:ILiteral = {
    expand: 'client,client_location,client_contact,passport,passport_price,passport.category,offer,passport.gtins,from_noah'
  };

  constructor(protected _service: NeedService) {
    super();
  }
}
