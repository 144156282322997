import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { Colors } from '../models/colors.class';
var ColorsService = /** @class */ (function (_super) {
    tslib_1.__extends(ColorsService, _super);
    function ColorsService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'passport-colors';
        _this.namespaceSingular = 'passport-colors';
        _this.ModelClass = Colors;
        return _this;
    }
    return ColorsService;
}(CrudService));
export { ColorsService };
