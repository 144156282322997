import * as tslib_1 from "tslib";
import { BaseModel } from 'ng2-adn-common';
var StatsContainer = /** @class */ (function (_super) {
    tslib_1.__extends(StatsContainer, _super);
    function StatsContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StatsContainer.prototype.fields = function () {
        return [
            'total',
            'last_year',
            'last_month',
            'last_week'
        ];
    };
    return StatsContainer;
}(BaseModel));
export { StatsContainer };
