import { Component, Inject, OnInit } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef, MdDialog } from '@angular/material';
import { DepositLocation } from '../../../../entities/deposit/models/deposit-location.class';
import { DepositLocationService } from '../../../../entities/deposit/services/deposit-location.service';
import { BIM } from '../../../../shared/models/bim.class';
import { BIMService } from '../../../../shared/services/bim.service';
@Component({
  moduleId: module.id,
  templateUrl: 'BIM-model-dialog.component.html',
  styleUrls: ['BIM-model-dialog.component.scss'],
})
export class BIMModelDialogComponent implements OnInit{
  locations: DepositLocation[] = [];
  locationsGroup: any[][];
  expanded = [];
  group: {};
  BIMs: BIM[] = [];
  groupKeys: any;

  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
    public mdDialogRef: MdDialogRef<void>,
    public BIMService: BIMService,
    public depositLocationService: DepositLocationService,
    private _dialog: MdDialog) {
  }

  ngOnInit(): void {
    this.depositLocationService.get({exist:'bim_tool_id'}).subscribe((locations: DepositLocation[]) => {
      this.locations = locations
      this.groupLocations();
    });
    this.BIMService.get({expand: 'name'}).subscribe((BIMs: BIM[]) => this.BIMs = BIMs);
  }

  getBIMName(location: DepositLocation) {
    const loc = this.BIMs.find(bim => bim.id === location.bim_tool_id);
    return loc ? loc.name : '';
  }

  groupLocations() {
    this.group = this.locations.reduce((r, a) => {
      r[a.bim_tool_id] = [...r[a.bim_tool_id] || [], a];
      return r;
      }, {});

    this.groupKeys = Object.keys(this.group);
  }

  isExpanded(id): boolean {
    return this.expanded.some(a => a === id);
  }

  toggle(id) {
    if(!this.expanded.some(a => a === id)) {
      this.expanded.push(id);
    } else {
      this.expanded = this.expanded.filter(a => a != id);
    }
  }

  openBIM(id) {
    window.open(`https://wizzyou.wizzcad.com:8180/apps/wizzbim/?page=Project&poid=${id}&tab=threedview`, 'popup', 'width=600,height=600');
    return false;
  }

}
