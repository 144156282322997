<!-- <div class="die-second" *ngIf="deposit.deposit_location">
    <div class="block-location">
        <div class="header">
            <span class="number-two">2</span><b class="string-location"> LOCATION</b>
        </div>-->
<div class="location">
  <section class="form-group">
    <label class="form-group__label">{{ 'Site info' | translate }}</label>

    <div [class.highlighted]="controlStates?.deposit_location_id?.highlighted">
      <div class="form-group__content--row location__info">
        <div class="location__site-input">
          <!--class="location__site-input&#45;&#45;first">-->
          <custom-search
            caption="{{ 'Name' | translate }} *"
            [preset]="depositLocation"
            [service]="service"
            [listener]="deposit.onChangeLocation"
            (onSelect)="onSelect($event)"
            (onCreate)="onCreate()"
            (onChangeName)="onChangeName($event)"
            [isCreateNewButtonShown]="!isRestrictedAgent"
            [label]="'Create New Site' | translate"
            [autoComplete]= "'new-password'"
            [required]="true"
          ></custom-search>
        </div>
      </div>
      <div class="form-group__content--row location__address">
        <div class="location__address-input">
          <md-input-container>
            <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
              <input
                mdInput
                placeholder="{{ 'Address' | translate }}"
                [disabled]="depositLocation.lock"
                [(ngModel)]="depositLocation.address"
                placeAutocomplete
                (placeChange)="onPlaceChange($event)"
                autocomplete="false"
              >
            </place-autocomplete>
          </md-input-container>
        </div>
        <div class="location__buttons">
          <button md-raised-button class="location__locate-button" (click)="locateMe()">
            <i class="ib-icon locate--icon"></i>{{ 'Locate me' | translate }}
          </button>
          <button md-raised-button class="location__locate-button" (click)="viewMap()">
            <i class="ib-icon map--icon"></i>{{ 'View map' | translate }}
          </button>
        </div>
      </div>
      <div class="location__description">{{ 'Description' | translate }}</div>
      <div class="form-group__content--row location__description-input">
        <md-input-container class="location__description-input--container">
          <input mdInput autocomplete="off" placeholder="{{ 'Description' | translate }}" [disabled]="depositLocation.lock"
                 [(ngModel)]="depositLocation.description">
        </md-input-container>
      </div>
    </div>

    <section *ngIf="!!depositLocation.id">
      <div class="form-group__label location__tag-label">{{ 'Tag(s)' | translate }}</div>
      <div class="location__tag-item b-module background-primary-color" *ngFor="let tag of chosenTagJunctions">
        <span>{{tag.name}}</span>
        <md-icon (click)="closeTag(tag)">close</md-icon>
      </div>
      <div [ngClass]="{'location__tag-add': true, 'location__tag-add--disabled': isTagsLoading}"
           (click)="openSuggestion()">
        <md-icon class="location__tag-add-icon b-module primary-color">add</md-icon>
        <span class="location__tag-add-name">{{ 'add a tag' | translate}}</span>
      </div>
      <div [hidden]="!isSearchActive">
        <md-input-container class="location__tag-search">
          <suggestible-text-input
            [suggestions]="filteredTags"
            viewProperty="name"
            [itemsCount]="filteredTags.length"
            (onChange)="addTag($event)"
            (onFocusOut)="resetTagSearch()"
          >
            <input #search
                   mdInput
                   autocomplete="false"
                   [(ngModel)]="tagSearch"
                   (ngModelChange)="filterTagSuggestion()">
          </suggestible-text-input>
        </md-input-container>
      </div>
    </section>
  </section>
</div>

<!--</div>-->
<!--</div>-->
