/**
 * Created by Aleksandr C. on 9.02.17.
 */
import { Injectable } from '@angular/core';
import { Need } from '../models/need.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { ILiteral } from 'ng2-adn-common';
import {Observable} from "rxjs";
import {Response} from "@angular/http";

@Injectable()
export class NeedService extends ExtendedCrudService {
  protected namespace = 'needs';
  protected namespaceSingular = 'need';
  protected ModelClass = Need;

  getFileUploadUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/files');
  }

  getPdfReportUrl(query: ILiteral) {
    const q = this.formQueryString(query);
    return this.getEndpoint(`${this.namespaceSingular}/report${q ? '?' + q : ''}`);
  }

  getPdfReport(query: ILiteral) {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/report'), {search: query})
      .catch(this.onError.bind(this));
  }

  getUntreatedCount(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/untreated'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  getWaiting(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/waiting'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  sendConsultDepositLinkNotificationToSales(needId: number, depositId: number) {
    return this
      .sendPost(this.getEndpoint(this.namespaceSingular + '/send_consult_link_message'),
        {need_id: needId, deposit_id: depositId})
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }
}
