<md-dialog-content
  class="order-transaction-confirmation__dialog"
  [ngClass]="{'order-transaction-confirmation__dialog--hidden': this.confirmOrderDialogService.isConfirmDialogOpen}"
>
  <dialog-close-button class="order-transaction-confirmation__dialog-close-button"
                       (click)="dialogRef.close()"
  ></dialog-close-button>
  <offer-transaction-confirm
    appNotificationAsWideDialogContent
    [id]="mdDialogData.id"
    [disabled]="mdDialogData.disabled"
    [showESMText]="mdDialogData.showESMText"
    (onConfirm)="confirmOrder($event)"
    (onCancel)="dialogRef.close()"
  >
  </offer-transaction-confirm>
</md-dialog-content>
