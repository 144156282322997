<article class="need-sites">
  <div>
    <div>
      <button md-raised-button (click)="openSiteDialog()">+ {{'New site' | translate}}</button>
    </div>
    <div>
      <li class="need-sites-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group mat-focused">
          <input mdInput
                 autocomplete="off" 
                 placeholder="{{'Search site' | translate}}"
                 [(ngModel)]="search"
                 (ngModelChange)="fetchSites()"
          >
        </md-input-container>
      </li>
    </div>
    <div>
      <div class="SitesListContainer">
        <div *ngFor="let location of clientLocations | paginate:{id:'needs'
        , itemsPerPage: pageInfo.perPage
        , currentPage: pageInfo.currentPage
        , totalItems: pageInfo.totalCount }" class="SitesListItems">
          <div>
            <svg viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.983 12.3515L12.4474 11.8527C14.9787 9.14489 14.9787 4.75059 12.4474 2.04276C11.2166 0.736342 9.5678 0 7.82609 0C6.08438 0 4.43556 0.736342 3.18153 2.04276C0.650239 4.75059 0.650239 9.14489 3.18153 11.8527L3.64598 12.3515C1.37015 13.0879 0 14.3943 0 15.867C0 18.171 3.43698 20 7.82609 20C12.2152 20 15.6522 18.1948 15.6522 15.867C15.6522 14.3943 14.2588 13.0879 11.983 12.3515ZM7.82609 3.56295C9.28912 3.56295 10.4967 4.77435 10.4967 6.29454C10.4967 7.81473 9.28912 9.00237 7.82609 9.00237C6.36305 9.00237 5.15546 7.79097 5.15546 6.27078C5.15546 4.75059 6.36305 3.56295 7.82609 3.56295ZM7.82609 18.8124C3.90143 18.8124 1.16114 17.2684 1.16114 15.867C1.16114 14.8694 2.50806 13.848 4.55167 13.3254L7.38485 16.342C7.50097 16.4608 7.64031 16.5321 7.80287 16.5321C7.96543 16.5321 8.10476 16.4608 8.22088 16.342L11.0773 13.3254C13.1209 13.848 14.4678 14.8694 14.4678 15.867C14.491 17.601 10.9844 18.8124 7.82609 18.8124Z" fill="white"/>
            </svg>
          </div>
          <div>
            <span>{{ location.name }}</span>
            <span>{{ location.address }}</span>
          </div>
          <div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#35485F"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z"/></svg>
              <span>{{ location.needCount }}</span> 
              <span>{{ (location.needCount == 1 ? 'needsPublishedSingular' : 'needsPublishedPlural') | translate }}</span>
            </div>
            <div class="hamburgerMenu" [mdMenuTriggerFor]="menu" id="needItemMenu#{{location.id}}">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <md-menu #menu="mdMenu" x-position="before">
              <!--
              Pour un prochain sprint
              <button md-menu-item (click)="setdefaultLocation(location)">
                {{ 'Set as default' | translate }}
              </button>
            
              <button md-menu-item (click)="duplicateLocation(location)">
                  {{ 'Duplicate' | translate }}
              </button>
                -->
              <button md-menu-item (click)="viewEditLocation(location)">
                {{ 'Edit / View' | translate }}
              </button>
              <button md-menu-item (click)="deleteLocation(location)">
                {{ 'Delete' | translate }}
              </button>
            </md-menu>
          </div>
        </div>
        <pagination-controls 
          class="needslist__pagination" 
          id="needs" 
          (pageChange)="pageChange($event)" 
          maxSize="10"
          directionLinks="true" 
          autoHide="true" 
          previousLabel="" 
          nextLabel=""
          screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
          screenReaderPageLabel="{{ 'page' | translate }}"
          screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}">
        </pagination-controls>
      </div>
    </div>
  </div>
</article>
