import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { OrderMaterialService } from '../services/order-material.service';
import { NomenclatureItem } from '../../../entities/nomenclature/models/nomenclature-item.class';

export class OrderMaterial extends ExtendedActiveRecord {
  protected provider = OrderMaterialService;

  public id: number;
  public quantity: number;
  public nomenclature_id: number;
  public order_passport_id: number;
  public nomenclature: NomenclatureItem;

  constructor(data: any = {}) {
    super(data);
    this.nomenclature = new NomenclatureItem(data.nomenclature);
  }

  protected fields() {
    return [
      'id',
      'quantity',
      'nomenclature_id',
      'order_passport_id',
    ];
  }

}
