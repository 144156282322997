<ng-container *ngIf="isLoading; else content">
    <app-loader [width]="'45px'" [height]="'45px'" style="width: 15px; height: 15px;"></app-loader>
</ng-container>

<ng-template #content>
    <!-- Display passports if available -->
    <div *ngIf="passports.length; else noPassports" class="passport-collection">
        <div *ngIf="canSelect" class="select-all-checkbox">
            <app-base-checkbox [isChecked]="selectAllChecked" (isCheckedChange)="toggleSelectAll()"
                [label]="'selectDeselectAll' | translate">
            </app-base-checkbox>
            <span *ngIf="isAllPagesSelected || selectedPassports.length">{{isAllPagesSelected ? totalPassportCount -
                selectedPassports.length : selectedPassports.length}} {{'Passports_selected' | translate}}.
                <span (click)="handleSelectAllPages()">{{'Select_passports_all_pages' | translate : {X:
                    totalPassportCount} }}</span>
                <span (click)="handleDeselectAllPages()">{{'Deselect_all' | translate}}</span>
            </span>
        </div>
        <div *ngFor="let passport of passports" class="passport-item-container">
            <new-passport-item [passport]="passport" [isSelected]="isSelected(passport)"
                [isVersionSelected]="isVersionSelected" [isSelectable]="canSelect"
                [actionButtonsConfig]="actionButtonsConfig" (isSelectedChange)="onSelectionChange(passport, $event)"
                (deleteRequest)="handleDeleteRequest($event)" (duplicateRequest)="handleDuplicateRequest($event)"
                (versionClicked)="handleVersionClicked($event)" [displayStatus]="showStatus">
                >
            </new-passport-item>
        </div>
    </div>
    <!-- No passports available -->
    <ng-template #noPassports>
        <p>{{'No_Passport_Available' | translate}}</p>
    </ng-template>
</ng-template>

<!-- Pagination is only shown if there are passports -->
<app-pagination *ngIf="totalPages > 1" [currentPage]="currentPage" [totalPages]="totalPages"
    (pageChange)="onPageChange($event)"></app-pagination>