import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {cloneDeep, isEqual, omit} from 'lodash';

@Component({
  selector: 'passport-reset-button',
  templateUrl: './passport-reset-button.component.html',
})
export class PassportResetButtonComponent {
  @Input() disabled = false;
  @Input() buttonText = 'Clear filters';
  @Input() alternativeRules = {};
  @Input() resetFilterState = {};
  @Input() passportFilter = {};
  @Input() controlAsFilter = ['search'];
  @Output() onResetClick = new EventEmitter();

  canClearFilters() {
    const valReset = (key) => this.resetFilterState[key];
    const valActual = (key) => this.passportFilter[key];
    const valSkip = (key) => this.alternativeRules[key];
    const isChanged = (key) => !isEqual(valReset(key), valActual(key)) && !isEqual(valActual(key), valSkip(key));
    const isChangedSearch = (key) => !isEqual(valReset(key).value, valActual(key).value)
      && !isEqual(valActual(key).value, valSkip(key));
    for (const key in this.resetFilterState) {
      if (this.controlAsFilter.includes(key)) {
        if (isChangedSearch(key)) {
          return true;
        }
      } else if (isChanged(key)) {
        return true;
      }
    }
    return false;
  }

  clearFilters() {
    const newPassportFilter = {...cloneDeep(omit(this.resetFilterState, this.controlAsFilter))};
    this.controlAsFilter.forEach(
      (control) => {
        this.passportFilter[control].setValue(this.resetFilterState[control].value);
        newPassportFilter[control] = this.passportFilter[control];
      });
    Object.keys(this.passportFilter).forEach(key => {
      if (!(key in newPassportFilter)) {
        newPassportFilter[key] = this.passportFilter[key];
      }
    });
    this.onResetClick.emit(newPassportFilter);
  }
}
