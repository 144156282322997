import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'alert-dialog',
  templateUrl: 'alert-dialog.component.html'
})

export class AlertDialogComponent implements OnInit {
  public text: string;
  public title: string;

  constructor(private dialog: MdDialogRef<any>) {
  }

  ngOnInit() {
    this.text = this.dialog._containerInstance.dialogConfig.data.text;
    this.title = this.dialog._containerInstance.dialogConfig.data.title;
  }

  onClose() {
    this.dialog.close();
  }
}
