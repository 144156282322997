/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "../../partials/view-passport/view-passport.component.ngfactory";
import * as i3 from "../../partials/view-passport/view-passport.component";
import * as i4 from "../../services/material.service";
import * as i5 from "../../services/component.service";
import * as i6 from "./view-passport-dialog.component";
var styles_ViewPassportDialogComponent = [];
var RenderType_ViewPassportDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewPassportDialogComponent, data: {} });
export { RenderType_ViewPassportDialogComponent as RenderType_ViewPassportDialogComponent };
export function View_ViewPassportDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "md-dialog-content", [], [[2, "mat-dialog-content", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MdPrefixRejector, [[2, i1.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i1.MdDialogContent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "view-passport", [], null, null, null, i2.View_ViewPassportComponent_0, i2.RenderType_ViewPassportComponent)), i0.ɵdid(4, 114688, null, 0, i3.ViewPassportComponent, [i4.MaterialService, i5.ComponentService], { passport: [0, "passport"], isViewMode: [1, "isViewMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.mdDialogData.passport; var currVal_2 = true; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_ViewPassportDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "view-passport-dialog", [], null, null, null, View_ViewPassportDialogComponent_0, RenderType_ViewPassportDialogComponent)), i0.ɵdid(1, 49152, null, 0, i6.ViewPassportDialogComponent, [i1.MdDialogRef, i1.MD_DIALOG_DATA], null, null)], null, null); }
var ViewPassportDialogComponentNgFactory = i0.ɵccf("view-passport-dialog", i6.ViewPassportDialogComponent, View_ViewPassportDialogComponent_Host_0, {}, {}, []);
export { ViewPassportDialogComponentNgFactory as ViewPassportDialogComponentNgFactory };
