import {Component, Input, OnInit} from '@angular/core';
import {IReportResponse} from '../../../../interfaces/reportResponse.interface';
import {TResidualValueReport} from './types/residual-value-report.type';
import { MAIN_TAB_STEPS } from '../../admin-reporting.const';

@Component({
  moduleId: module.id,
  selector: 'residual-value',
  templateUrl: 'residual-value.component.html',
  styleUrls: ['residual-value.component.scss']
})
export class ResidualValueComponent implements OnInit {
  @Input() report: IReportResponse;
  @Input() changeTab: Function;

  public reportTypes: TResidualValueReport[] = [
    'deconstruction', 'rehabilitation', 'renewal_building_commercial', 'renewal_building_residantial', 'renewal_building_institutional'
  ];

  ngOnInit(): void {
  }

  isAnyReports() {
    return this.reportTypes.filter(item => {
      return this.report.report_result.financial_residual_value.hasOwnProperty(item);
    }).length !== 0;
  }
}
