import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss']
})
export class SortByComponent implements OnInit, OnChanges {
   
  @Input() defaultSort: string;
  @Output() sortChanged = new EventEmitter<string>();

  sortOptions = [
    { label: 'Modification date', value: '-updated_at' },
    { label: 'Creation date', value: '-created_at' },
    { label: 'Creator', value: 'username' },
    { label: 'Name', value: 'name' },
    { label: 'CircularPerformance', value: 'performance' }
  ];

  currentSort: string;

  ngOnInit() {
    // Set the default sort value if provided, otherwise use a fallback
    this.currentSort = this.defaultSort || this.sortOptions.find(option => option.value === 'performance').value;
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check if defaultSort input has changed
    if (changes.defaultSort) {
      this.currentSort = this.defaultSort || this.sortOptions.find(option => option.value === 'performance').value;
    }
  }

  applySort(sortValue: string) {
    this.currentSort = sortValue;
    this.sortChanged.emit(sortValue);
  }
}
