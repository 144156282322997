<div class="genericpassportlist__row-wrapper">
    <div [ngClass]="(index==1)?'item-odd genericpassportlist__item-info':'item-even genericpassportlist__item-info'">
        <div class="genericpassportlist__item-name" (click)="openGenericPassport(genericPassport)">
            {{ getNameFromTags(genericPassport) }} </div>
        <div class="genericpassportlist__item-category">{{ genericPassport.category.name | translate }}</div>
        <div class="genericpassportlist__item-material">
            <label md-tooltip="{{ getMaterials(genericPassport,true)}}">

                {{ getMaterials(genericPassport,false) }}
            </label>
        </div>
        <div class="genericpassportlist__item-unit"> {{genericPassport.unit_text }}</div>
        <div class="genericpassportlist__item-delete" (click)="deleteGenericPassport(genericPassport)"> </div>
        <div class="genericpassportlist__item-copy" (click)="copyPassport(genericPassport)"> </div>
    </div>
</div>