import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { ReportFile } from '../models/report-file.class';
var ReportFileService = /** @class */ (function (_super) {
    tslib_1.__extends(ReportFileService, _super);
    function ReportFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'report-import-files';
        _this.namespaceSingular = 'report-import-file';
        _this.ModelClass = ReportFile;
        return _this;
    }
    return ReportFileService;
}(CrudService));
export { ReportFileService };
