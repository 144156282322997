<article class="need-picker">
  <header class="need-picker__header">
    <h3 class="need-picker__title">{{ 'Choose need for new ecosystem' | translate }}</h3>
    <i class="icon icon--close_s need-picker__close" (click)="closeDialog()"></i>
  </header>
  <toggle-independent
    *ngIf="showIndependentFilter"
    (toggle)="toggleIndependent($event)"
    [text]="'See external needs'"
    [img]="'assets/png/cloud_grey.png'">
</toggle-independent>
  <section class="need-picker__items">
    <section class="esm-catalog__choice">
      <ul class="esm-catalog__choice-list">
        <li class="esm-catalog__choice-item search">
          <i class="icon icon--search"></i>
          <md-input-container class="input-group">
            <input mdInput
                   autocomplete="off" 
                   [formControl]="search"
                   placeholder="{{ 'Search' | translate }}"
            >
          </md-input-container>
        </li>
        <li class="esm-catalog__choice-item select">
          <i class="icon icon--people-grey"></i>
          <md-select
            class="select-group"
            placeholder="{{ 'Recipient' | translate }}"
            [(ngModel)]="clientId"
            (ngModelChange)="getRecords()"
          >
            <md-option [value]="">{{ 'Any' | translate }}</md-option>
            <md-option
              *ngFor="let client of clients"
              [value]="client.id"
            >{{client.name}}
            </md-option>
          </md-select>
        </li>
        <li class="esm-catalog__choice-item">
          <i class="icon icon--calendar"></i>
          <md-input-container class="date input-group">
            <input mdInput
                   autocomplete="off" 
                   ng2-datetime-picker
                   date-only="true"
                   date-format="DD-MM-YYYY"
                   placeholder="{{ 'Expires FROM' | translate }}"
                   [(ngModel)]="expiresFrom"
                   (ngModelChange)="getRecords()"
            >
          </md-input-container>
        </li>
        <li class="esm-catalog__choice-dash">-</li>
        <li class="esm-catalog__choice-item">
          <i class="icon icon--calendar"></i>
          <md-input-container class="date input-group">
            <input mdInput
                   autocomplete="off" 
                   ng2-datetime-picker
                   date-only="true"
                   date-format="DD-MM-YYYY"
                   placeholder="{{ 'Expires TO' | translate }}"
                   [(ngModel)]="expiresTo"
                   (ngModelChange)="getRecords()"
            >
          </md-input-container>
        </li>
        <li class="esm-catalog__choice-item select">
          <i class="icon icon--people-grey"></i>
          <md-select
            class="select-group"
            placeholder="{{ 'Need manager' | translate }}"
            [(ngModel)]="salesId"
            (ngModelChange)="getRecords()"
          >
            <md-option
              *ngFor="let sales of salesContacts"
              [value]="sales.id"
            >{{sales.name}}
            </md-option>
          </md-select>
        </li>
      </ul>
    </section>
    <div class="need-picker__no-items" *ngIf="records.length <= 0">
      {{ 'There are no needs' | translate }}.
    </div>
    <ul class="need-picker__list">
      <li class="need-picker__item"
          *ngFor="let item of records"
          [ngClass]="{'need-picker__item--active': item === activeItem}"
          (click)="selectItem(item)"
      >
        <div class="need-picker__item-body">
          <div class="need-picker__item-name">
            <span *ngIf="!!item.independent" class="need-picker__item-icon">
              <i class="icon icon--cloud-arrow-down"></i>
            </span>
            <h4 class="need-picker__item-title">{{item.name}}</h4>
            <h5 class="need-picker__item-subtitle">{{item.client?.name}}</h5>
          </div>
          <div class="need-picker__item-info">
            <div class="need-picker__item-amount">
              <i class="icon" [ngClass]="'icon--'+item.passport.unit_text"></i>
              {{item.quantity_text}}
              {{item.passport.unit_text}}
            </div>
            <div class="need-picker__item-dates">{{ 'Created' | translate }}: {{item.created_at | date:'dd.MM.yyyy'}}
              <span class="need-picker__item-expires" *ngIf="item.delivery_date">{{ 'Expires' | translate }}: {{item.delivery_date | date:'dd.MM.yyyy'}}</span>
            </div>
          </div>
        </div>
        <div class="need-picker__item-description">
          {{item.description}}
        </div>
      </li>
    </ul>
  </section>
  <footer class="need-picker__footer">
    <button class="need-picker__button--create" (click)="createEcosystem()" [disabled]="records.length <= 0">
      <i class="icon icon--ecosystem_w"></i> {{ 'Create' | translate }}
    </button>
  </footer>
</article>
