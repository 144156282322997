import { ClientService } from '../service/client.service';
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Contact } from './contact.class';
import { ClientLocation } from './location.class';
import { Observable } from 'rxjs/Observable';
import { ContactService } from '../service/contact.service';
import { ServiceLocator } from '../../../shared/services/service-locator';

export class Client extends ActiveRecord {
  protected provider = ClientService;
  protected contactService: ContactService = ServiceLocator.injector.get(ContactService);

  public id: number;
  public name: string;
  public verified: boolean;
  public address: string;
  public logo: string;
  public image: string;
  public contact_name: string;
  public description: string;
  public sirent: string;
  public client_contacts: Contact[];
  public client_locations: ClientLocation[];
  public user_id: number;

  public published_needs_count: number = 120;
  public treated_needs_count: number = 2;
  public created_at: string;
  public updated_at: string;
  public published_needs: string;
  public treated_needs: string;

  protected fields() {
    return [
      'id',
      'name',
      'verified',
      'address',
      'logo',
      'image',
      'contact_name',
      'description',
      'sirent',
      'client_contacts',
      'client_locations',
      'created_at',
      'updated_at',
      'published_needs',
      'treated_needs',
      'user_id'
    ];
  };

  getContacts(): Observable<Contact[]> {
    if (this.client_contacts) {
      return Observable.of(this.client_contacts);
    } else {
      return this.contactService.get({client_id: this.id}).map((contacts: Contact[]) => {
        this.client_contacts = contacts;
        return contacts;
      });
    }
  }

  canDelete(): Observable<any> {
    this.build();

    return (this.service as ClientService).canDelete(this.id);
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    try {
      if (json.client_contacts) {
        json.client_contacts = <Contact[]>json.client_contacts.map((item: any) => new Contact(item));
      }
      if (json.client_locations) {
        json.client_locations = <ClientLocation[]>json.client_locations.map((item: any) => new ClientLocation(item));
      }
    } catch (error) {
      console.error(error);
    }
    return json;
  }
}
