<div class="manufacturer-dashboard">
    <div class="header">
        <div>
            <app-create-button (create)="onCreateNewPassport()"
                [label]="'Create new Circular Passport'"></app-create-button>
            <button *ngIf="!isSpecialAdmin" md-raised-button (click)="onAskForChangeInNomenclatureOpenDialog()">
                {{'Ask for change in nomenclature' | translate }}
            </button>
        </div>
        <div (click)="openRatingExplanationDialog()" class="passportlist__rating-explanation ng-star-inserted"></div>
    </div>
    <div class="filter">
        <app-filter-tab *ngIf="isSpecialAdmin" (tabSelected)="onTabSelected($event)" [tabs]="tabs" [initialTab]="currentTab"></app-filter-tab>
        <div class="filter-container">
            <button class="clearButton" (click)="resetFiltersToDefault()" [disabled]="filtersAreDefault()">
                {{'Clear filters' | translate}}
            </button>
            <div *ngIf="currentTab === 'published' && isSpecialAdmin">
                <!-- Show 'Published' tab is selected -->
                <div style="height: 25px;"></div>
            </div>
            <div *ngIf="currentTab === 'toBeTreated' || !isSpecialAdmin">
                <!-- Show 'To be treated' tab is selected -->
                <app-filter-group [selectedStatuses]="filterStates[currentTab].status"
                    [filterStatuses]="getStatusFilter()" (filterChanged)="onStatusChanged($event)"></app-filter-group>
            </div>
            <div class="main-wrapper">
                <div class="column">
                    <app-search [searchTerm]="filterStates[currentTab].search"
                        (searchChanged)="onSearchFilterChange($event)"></app-search>
                    <div class="row">
                        <app-material-search [materials]="filterStates[currentTab].materials"
                            (materialsChange)="onMaterialsFilterChange($event)"></app-material-search>
                        <app-category-search [categories]="filterStates[currentTab].categories"
                            (categoriesChange)="onCategoriesFilterChange($event)"></app-category-search>
                    </div>
                    <div class="row">
                        <app-database-filter [databaseSelected]="filterStates[currentTab].databases"
                            (databaseChange)="onDatabaseFilterChange($event)"></app-database-filter>
                        <app-data-provider-filter [dataProviderSelected]="filterStates[currentTab].dataProviders"
                            (dataProviderChange)="onDataProviderFilterChange($event)"></app-data-provider-filter>
                    </div>
                </div>
                <div class="column">
                    <app-family-filter [familySelected]="filterStates[currentTab].families" [userRole]="'manufacturer'"
                        (familyChange)="onFamilyFilterChange($event)"></app-family-filter>
                    <app-passport-id-search [searchTerm]="filterStates[currentTab].searchId"
                        (searchChange)="onSearchIdFilterChange($event)"></app-passport-id-search>
                    <app-epd-type-filter [epdTypeSelected]="filterStates[currentTab].epdType"
                        (epdTypeChange)="onEpdTypeFilterChange($event)"></app-epd-type-filter>
                </div>
                <div class="column ">
                    <app-rating-filter [ratingSelected]="filterStates[currentTab].rating"
                        (ratingChange)="onRatingFilterChange($event)"></app-rating-filter>
                    <app-data-representativity-filter
                        [representativitySelected]="filterStates[currentTab].dataRepresentativity"
                        (representativityChange)="onDataRepresentativityFilterChange($event)">
                    </app-data-representativity-filter>
                    <app-sort-by [defaultSort]="filterStates[currentTab].sortBy"
                        (sortChanged)="onSortChange($event)"></app-sort-by>
                </div>
            </div>
        </div>
    </div>
    <passport-family-info [isShown]="false"></passport-family-info>
    <div class="action-container">
        <div class="actionButton-container">
            <button [disabled]="!havePassportsSelected()" (click)="deleteSelectedPassports()">
                <app-trash-icon [sizeMultiplier]="1.3" [color]="!havePassportsSelected() ? '#a1a1a1' : '#fff'"
                    [liftDirection]="'right'"></app-trash-icon>
                {{ 'Delete' | translate }}
            </button>
            <button *ngIf="currentTab === 'toBeTreated'" (click)="publishSelectedPassports()" [disabled]="!havePassportsSelected()">
                {{ 'Publish' | translate}}
            </button>
        </div>
    </div>
    <div class="passport-container">
        <app-passport-collection #passportCollectionRef [currentPage]="pagination.current_page"
            [totalPages]="pagination.page_count" (pageChange)="onPageChange($event)" [passports]="passports"
            (deletePassportEvent)="deleteOnePassport($event)" (duplicateRequest)="duplicatePassport($event)"
            (versionClicked)="onVersionClicked($event)" [totalPassportCount]="pagination.total_count"
            (selectAllPages)="onSelectAllPagesChange($event)" [isLoading]="isLoading">
        </app-passport-collection>
    </div>
</div>