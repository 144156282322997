<section test-id="superadmin-users-counters"
         class="ecosystem-dashboard__section ecosystem-dashboard__section--counters"
>
  <ul class="ecosystem-dashboard__counter-list">
    <li class="ecosystem-dashboard__counter-item">
      <div class="ecosystem-dashboard__counter-value theme-color--primary">{{nomenclatureChangeRequests}}</div>
      <span class="ecosystem-dashboard__counter-title">{{'New nomenclature change requests' | translate}}</span>
    </li>
    <li class="ecosystem-dashboard__counter-item">
      <div class="ecosystem-dashboard__counter-value theme-color--primary ">{{companies}}</div>
      <span class="ecosystem-dashboard__counter-title">{{'Registered companies' | translate}}</span>
    </li>
    <li class="ecosystem-dashboard__counter-item">
      <div class="ecosystem-dashboard__counter-value theme-color--primary ">{{usersRegistered}}</div>
      <span class="ecosystem-dashboard__counter-title">{{'Registered users' | translate}}</span>
    </li>
  </ul>
</section>

<div class="ecosystem-dashboard__section"></div>
<users-list [resetContainer]="false"></users-list>
