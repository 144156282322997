import * as tslib_1 from "tslib";
import { DataProviderWithPagination } from '../../../shared/models/data-provider-with-pagination.class';
import { NEED_STATUSES } from '../values/need-statuses.const';
import { Need } from './need.class';
import moment from 'moment';
var NeedEsmMatchDepositsFilter = /** @class */ (function (_super) {
    tslib_1.__extends(NeedEsmMatchDepositsFilter, _super);
    function NeedEsmMatchDepositsFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.expand = 'passport,client,match_deposits';
        _this.sort = '-created_at';
        _this['status[]'] = NEED_STATUSES.PUBLISHED.value;
        _this.admin = 1;
        _this.supervisor = 1;
        _this.independent = 1;
        _this.exclude_refused = 1;
        _this.records = [];
        _this.loaded = false;
        _this.has_deposit_match = true;
        _this.paginate = true;
        return _this;
    }
    NeedEsmMatchDepositsFilter.prototype.fields = function () {
        return ['expand', 'status[]', 'admin', 'supervisor', 'independent', 'exclude_refused', 'from_deposit', 'paginate', 'sort'];
    };
    NeedEsmMatchDepositsFilter.prototype.buildOptions = function () {
        var _this = this;
        var options = this.fields().reduce(function (acc, item) {
            if (_this[item]) {
                acc[item] = _this[item];
            }
            return acc;
        }, {});
        if (!options['from_deposit'] && this.has_deposit_match) {
            options['has_deposit_match'] = true;
        }
        if (options['from_deposit']) {
            options['targeted_match_esm'] = true;
        }
        if (this.paginate) {
            options['page'] = this.currentPage;
        }
        ['delivery_from', 'delivery_to'].filter(function (item) { return _this.parseDate(item); })
            .forEach(function (item) { return options[item] = _this.parseDate(item); });
        return options;
    };
    NeedEsmMatchDepositsFilter.prototype.parseDate = function (field) {
        var date = moment(this[field], ['DD-MM-YYYY', 'YYYY-MM-DD']);
        if (date.isValid()) {
            return date.format('YYYY-MM-DD');
        }
        return null;
    };
    NeedEsmMatchDepositsFilter.prototype.updateRecords = function (response, pagination) {
        _super.prototype.updateRecords.call(this, response, pagination);
        this.records = this.records
            .filter(function (need) { return need.match_deposits && need.match_deposits.data.length > 0; })
            .map(function (data) { return new Need(data); });
        this.loaded = true;
    };
    NeedEsmMatchDepositsFilter.prototype.updateRecordEntities = function (needs) {
        this.records = needs.filter(function (need) { return need.match_deposits && need.match_deposits.data.length > 0; });
        this.loaded = true;
    };
    return NeedEsmMatchDepositsFilter;
}(DataProviderWithPagination));
export { NeedEsmMatchDepositsFilter };
