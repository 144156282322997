/**
 * Created by aleksandr on 29.05.17.
 */
var _a;
export var ECOSYSTEM_STEP;
(function (ECOSYSTEM_STEP) {
    var TYPE;
    (function (TYPE) {
        TYPE[TYPE["NEED"] = 1] = "NEED";
        TYPE[TYPE["DEPOSIT"] = 2] = "DEPOSIT";
        TYPE[TYPE["TRANSFORM"] = 3] = "TRANSFORM";
        TYPE[TYPE["VIRGIN_PRODUCT"] = 4] = "VIRGIN_PRODUCT";
        TYPE[TYPE["STAKEHOLDER"] = 5] = "STAKEHOLDER";
    })(TYPE = ECOSYSTEM_STEP.TYPE || (ECOSYSTEM_STEP.TYPE = {}));
    var STATUS;
    (function (STATUS) {
        STATUS[STATUS["STAND_BY"] = 1] = "STAND_BY";
        STATUS[STATUS["WAITING_FOR_VALIDATION"] = 2] = "WAITING_FOR_VALIDATION";
        STATUS[STATUS["ASK_FOR_MODIFICATION"] = 3] = "ASK_FOR_MODIFICATION";
        STATUS[STATUS["PENDING"] = 4] = "PENDING";
        STATUS[STATUS["VALIDATED"] = 5] = "VALIDATED";
        STATUS[STATUS["CONFIRMED"] = 6] = "CONFIRMED";
        STATUS[STATUS["PROBLEM_REPORTED"] = 7] = "PROBLEM_REPORTED";
        STATUS[STATUS["REJECTED"] = 8] = "REJECTED";
        STATUS[STATUS["DONE"] = 9] = "DONE";
    })(STATUS = ECOSYSTEM_STEP.STATUS || (ECOSYSTEM_STEP.STATUS = {}));
    ECOSYSTEM_STEP.VALIDATABLE_STEPS = [
        TYPE.DEPOSIT,
        TYPE.TRANSFORM
    ];
    ECOSYSTEM_STEP.CONFIRMABLE_STEPS = [
        TYPE.DEPOSIT,
        TYPE.TRANSFORM,
        TYPE.VIRGIN_PRODUCT,
        TYPE.STAKEHOLDER
    ];
    function IS_VALIDATABLE_STEP(typeId) {
        return ECOSYSTEM_STEP.VALIDATABLE_STEPS.includes(typeId);
    }
    ECOSYSTEM_STEP.IS_VALIDATABLE_STEP = IS_VALIDATABLE_STEP;
    function IS_CONFIRMABLE_STEP(typeId) {
        return ECOSYSTEM_STEP.CONFIRMABLE_STEPS.includes(typeId);
    }
    ECOSYSTEM_STEP.IS_CONFIRMABLE_STEP = IS_CONFIRMABLE_STEP;
})(ECOSYSTEM_STEP || (ECOSYSTEM_STEP = {}));
//BACKWARD COMPATIBILITY
export var ECOSYSTEM_STEP_TYPE;
(function (ECOSYSTEM_STEP_TYPE) {
    ECOSYSTEM_STEP_TYPE[ECOSYSTEM_STEP_TYPE["NEED"] = 1] = "NEED";
    ECOSYSTEM_STEP_TYPE[ECOSYSTEM_STEP_TYPE["DEPOSIT"] = 2] = "DEPOSIT";
    ECOSYSTEM_STEP_TYPE[ECOSYSTEM_STEP_TYPE["TRANSFORM"] = 3] = "TRANSFORM";
    ECOSYSTEM_STEP_TYPE[ECOSYSTEM_STEP_TYPE["VIRGIN_PRODUCT"] = 4] = "VIRGIN_PRODUCT";
    ECOSYSTEM_STEP_TYPE[ECOSYSTEM_STEP_TYPE["STAKEHOLDER"] = 5] = "STAKEHOLDER";
})(ECOSYSTEM_STEP_TYPE || (ECOSYSTEM_STEP_TYPE = {}));
export var ECOSYSTEM_STEP_TYPE_MAP = (_a = {},
    _a[ECOSYSTEM_STEP.TYPE.NEED] = 'Need',
    _a[ECOSYSTEM_STEP.TYPE.DEPOSIT] = 'Deposit',
    _a[ECOSYSTEM_STEP.TYPE.TRANSFORM] = 'Transform',
    _a[ECOSYSTEM_STEP.TYPE.VIRGIN_PRODUCT] = 'Virgin product',
    _a[ECOSYSTEM_STEP.TYPE.STAKEHOLDER] = 'Stakeholder',
    _a);
