import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { PassportSynchronizationData } from "../../../models/passport-synchronization-data.class";

@Component({
    selector: 'source-input-list',
    templateUrl: './source-input-list.component.html'
})

export class SourceInputListComponent implements OnInit{
    @Input() selectedValue: number = null;
    @Input() disabled: boolean = false;
    @Output() selectionChange = new EventEmitter<number | null>();

    sources = [];
    
    ngOnInit() {
        this.sources = PassportSynchronizationData.getSourceKeyList();
    }
    
    onSelectionChange(option : string) {
        this.selectionChange.emit(option ? PassportSynchronizationData.getSourceKey(option) : null);
    }

    getListSourceName():string[] {
        return PassportSynchronizationData.getSourceValueList();
    }

    getSourceSelectedValue(): string | null {
        return this.selectedValue != null ? PassportSynchronizationData.getSourceValue(this.selectedValue) : null;
    }    
}