import {Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {UploadedDocument} from '../../../models/uploaders/document.class';
import {LogNotificationService} from '../../../services/log-notification.service';

@Component({
  selector: 'document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentItemComponent {
  @Input() set file(
    fileInput: {file: any, isImage?: boolean, basename?: string, extension?: string}) {
    this.inputFile = fileInput;
    if (!!fileInput) {
        this.docHref = this.docHref === undefined ? fileInput.file : this.docHref;
        this.srcImg = this.srcImg === undefined ?  fileInput.file : this.srcImg;
        this.isImage = this.isImage === undefined ? fileInput.isImage : this.isImage;
        this.extension = this.extension === undefined ? fileInput.extension : this.extension;
        this.fileName = this.fileName === undefined ? fileInput.basename : this.fileName;
      }
  }
  @Input() docHref;
  @Input() noLink = false;
  @Input() srcImg;
  @Input() isImage;
  @Input() extension;
  @Input() fileName;
  @Input() transformFileNameBeforeEdit: (name: string) => string = this.defaultTransformFileNameBeforeEdit;
  @Input() isRemoveFromFilesDisabled = true;
  @Input() disableNameChangeDefault = false;
  @Input() tooltipPosition = 'above';
  @Input() itemClass: string | string[] | Set<string> | { [klass: string]: any; } = [];
  @Output() onClick = new EventEmitter();
  @Output() onNameChange = new EventEmitter<string>();
  @Output() remove = new EventEmitter();
  editMode = false;
  editName: string;
  @ViewChild('edit') editInput: ElementRef;
  private inputFile: any;
  private toolTipPositionCustomClassBinding = {
    'left': 'right',
    'right': 'left',
    'above': 'top',
    'bellow': 'bottom'
  };

  constructor(private notification: LogNotificationService) {}

  get customClassPosition(): string {
    const classModifier = this.toolTipPositionCustomClassBinding[this.tooltipPosition];
    return classModifier ?
      `passportlist__name-tooltip--${classModifier}` : 'passportlist__name-tooltip--left';
  }

  private defaultTransformFileNameBeforeEdit(name: string) {
    return name;
  }

  clickOnLink() {
    this.onClick.emit();
    if (this.noLink) {
      return false;
    }
  }

  changeName($event: Event) {
    if (!this.isRemoveFromFilesDisabled) {
      if (this.disableNameChangeDefault || this.validateFileDefault()) {
        this.showEditName(this.transformFileNameBeforeEdit(this.fileName));
        $event.stopPropagation();
      }
      return false;
    }
  }

  private validateFileDefault() {
    const uploadedFile = this.getUploadedFile();
    return uploadedFile instanceof UploadedDocument;
  }

  private getUploadedFile() {
    try {
      return (this.inputFile as UploadedDocument);
    } catch {
      return null;
    }
  }

  private showEditName(name: string) {
    this.editMode = true;
    this.editName = name;
    setTimeout(() => {
      if (this.editInput) {
        this.editInput.nativeElement.focus();
      }
    });
  }
  editSend() {
    if (this.editMode) {
      if (!this.disableNameChangeDefault) {
        this.defaultFileRename();
      } else {
        this.onNameChange.emit(this.editName);
      }
      this.editName = '';
      this.editMode = false;
    }
  }

  private defaultFileRename() {
    const uploadedFile = this.getUploadedFile();
    if (uploadedFile) {
      if (this.editName.trim().length > 0 && this.editName !== uploadedFile.basename) {
        uploadedFile.basename = this.editName;
        uploadedFile.save().subscribe(file => {
          this.file = file;
          this.fileName = file.basename;
        });
      }
    } else {
      this.notification.error('IMPORT_ERROR_UNKNOWN_ERROR'
        , undefined, true);
    }
  }
}
