export enum ADMIN_STATISTICS_SEGMENTS {
  DEPOSITS = 1,
  NEEDS = 2,
  CLIENTS = 3,
  TRANSFORMATIONS = 4
}

export const ADMIN_STATISTICS_SEGMENTS_VIEW = {
  1: 'Deposits',
  2: 'Needs',
  3: 'Clients',
  4: 'Transformation sites'
};
