/**
 * Created by atat on 19/06/2017.
 */
import { Component, OnInit } from '@angular/core';
import {PermissionService} from '../../../../shared/services/permission.service';
import {EDepositOwnerPermission} from '../../values/deposit-owner-permission.enum';
import {take} from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'do-catalog',
  templateUrl: 'do-catalog.component.html'
})
export class DoCatalogComponent implements OnInit {
  activeIndex = 0;

  constructor(private permission: PermissionService) {
  }

  ngOnInit(): void {
    if (this.permission.isOnUserExpandLoad) {
      this.setActiveIndexResult();
    } else {
      this.permission.isAfterApplied$.pipe(take(1)).subscribe(() => this.setActiveIndexResult());
    }
  }

  private setActiveIndexResult() {
    if (this.permission.hasAppliedPermission(EDepositOwnerPermission.BUILDING_DO)) {
      this.activeIndex = 0;
    }
  }

  changeTab(index: number) {
    this.activeIndex = index;
  }


}
