/**
 * Created by kirill on 3.04.17.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import {IConfirmDialogData, IConfirmDialogIconData} from './values/interface/confirm-dialog-data.interface';

@Component({
  moduleId: module.id,
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit, OnDestroy, IConfirmDialogData {
  public text: string;
  public imgUrl: string;
  confirmText: string;
  cancelText: string;
  cssClass: string;
  showCross: boolean;
  showIcon: boolean;
  subText: string;
  iconData: IConfirmDialogIconData;

  constructor(private dialog: MdDialogRef<any> ) {
  }

  ngOnInit() {
    const data = this.dialog._containerInstance.dialogConfig.data;
    this.text = data.text;
    this.confirmText =  data.confirmText || 'Ok';
    this.cancelText =  data.cancelText || 'Cancel';
    this.cssClass =  data.cssClass || '';
    this.showCross =  data.showCross || false;
    this.showIcon =  data.showIcon || false;
    this.iconData =  data.iconData || false;
    this.subText =  data.subText || '';
    this.imgUrl = data.imgUrl || 'assets/png/warning_green.png';
  }

  ngOnDestroy() {

  }

  confirm() {
    this.dialog.close(true);
  }

  cancel() {
    this.dialog.close(false);
  }
}
