import { Type } from '@angular/core';
import { CountryService } from '../services/country.service';
import { ActiveRecord } from './active-record.class';

export class Country extends ActiveRecord {
  protected provider: Type<CountryService> = CountryService;

  id: number;
  iso: string;
  name: string;

  fields() {
    return ['iso', 'name', 'id'];
  }
}
