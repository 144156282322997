import { ChatUser } from '../models/chat-user.model';
/**
 * Created by aleksandr on 30.06.17.
 */
export interface IChatContact {
  id: number;
  status: number;
  username: string;
  description: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  avatar_url: string;
}


export interface IChatUser {
  id: number;
  image: string;
  name: string;
  new_messages: string | number;
  group?: number;
  status?: number;
  unreadMessages?: number;
  selected?: boolean;
  isMe?: boolean;
}

export enum EChatUserStatuses {
  AVAILABLE = 0,
  BUSY = 1,
  AWAY = 2
}

export interface IChatUserGroup {
  id?: number;
  name: string;
  isOpen?: boolean;
  contacts: ChatUser[];
}
