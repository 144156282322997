<div class="control-group">
  <ul class="control-group__list control-group__list--padding justify-start"
      *ngFor="let item of listCtrl?.controls; let i=index;"
      [formGroup]="listCtrl.controls[i]">

    <li class="control-group__item control-group__item--margin">
      <div [hidden]="this.isDisabled" class="control-group__item-button"
           (click)="removeManufacturer(i)">
        <md-icon>remove</md-icon>
      </div>
    </li>

    <md-input-container class="address flex  passport-edit__info--group-places-item"
    >
    <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
      <input
        mdInput
        formControlName="name"
        [placeholder]="'Address' | translate"
        placeAutocomplete
      >
    </place-autocomplete>
  </md-input-container>


  </ul>
  <div class="control-group__item flex align-center control-group__item-add"
       (click)="addNew()">
    <div [hidden]="this.isDisabled" class="control-group__item-button">
      <md-icon>{{'add' | translate}}</md-icon>
    </div>
    <div [hidden]="this.isDisabled" class="control-group__item--subtext">
      {{'add a place of manufacture' | translate}}
    </div>
  </div>

</div>
