import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { Synonym } from '../models/synonym.class';

@Injectable()
export class SynonymService extends CrudService {
  protected namespace = 'synonyms';
  protected namespaceSingular = 'synonym';
  protected ModelClass = Synonym;
}
