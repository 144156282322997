import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PIE_CHART_SIZE } from '../../../../values/pie-chart-size.const';
import { MAIN_TAB_STEPS } from '../../admin-reporting.const';
import { ReportBase } from '../report-base/report-base.class';
var CircularityComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CircularityComponent, _super);
    function CircularityComponent(_translateService) {
        var _this = _super.call(this) || this;
        _this._translateService = _translateService;
        _this.pieChartSize = PIE_CHART_SIZE;
        _this.isDescriptionBlock = true;
        _this.filteredProductCategory = {};
        return _this;
    }
    CircularityComponent.prototype.ngOnInit = function () {
        this.setReportAggregateVariables();
        this.loadMainProductCategory();
    };
    CircularityComponent.prototype.getSortedProductsBy = function (sortBy) {
        var categories = this.reports.report_result.circularity.aggregate.category;
        if (!!categories) {
            var categoriesFiltered = this.filteredProductCategory = tslib_1.__assign({}, Object.values(categories).filter(function (e) { return !!e.deposits_considered; }));
            return Object.values(categoriesFiltered).sort(function (a, b) { return a[sortBy] < b[sortBy] ? 1 : (b[sortBy] < a[sortBy] ? -1 : 0); });
        }
    };
    CircularityComponent.prototype.isEmptyProductCategory = function () {
        return Object.keys(this.filteredProductCategory).length === 0;
    };
    CircularityComponent.prototype.loadMainProductCategory = function () {
        var sortedByGrade = this.getSortedProductsBy('circularity_grade');
        if (!this.isEmptyProductCategory()) {
            var getProductCategoryBlockData = function (key) {
                return tslib_1.__assign({}, key, { titleForFooter: key.category.name, mainValue: Math.ceil(key.circularity_grade) });
            };
            this.mostCircularProductCategory = getProductCategoryBlockData(sortedByGrade[0]);
            this.leastCircularProductCategory = getProductCategoryBlockData(sortedByGrade[sortedByGrade.length - 1]);
            this.mostPresentProductCategory = getProductCategoryBlockData(this.getSortedProductsBy('weight')[0]);
        }
    };
    CircularityComponent.prototype.setReportAggregateVariables = function () {
        var _a;
        var reportAggregate = this.reports.report_result.circularity.aggregate;
        for (var item in reportAggregate) {
            if (typeof reportAggregate[item] === 'number') {
                this.aggregateData = tslib_1.__assign({}, this.aggregateData, (_a = {}, _a[item] = reportAggregate[item], _a));
            }
        }
        this.mainValue = this.aggregateData.circularity_grade;
    };
    CircularityComponent.prototype.hideDescriptionBlock = function () {
        this.isDescriptionBlock = false;
    };
    CircularityComponent.prototype.goToArchive = function () {
        this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
    };
    return CircularityComponent;
}(ReportBase));
export { CircularityComponent };
