<div *ngIf="report?.report_result?.financial_residual_value[type]" class="residual-value-report">
  <div class="residual-value-report__title">
    {{'Financial residual value in case of' | translate | uppercase}} {{getType() | translate | uppercase}}
  </div>
  <report-site-list [sites]="report.sites" [tags]="report.tags"  [createdByFile]="report.created_by_file"></report-site-list>
  <div #descriptionBlock class="residual-value-report__description">
    <p class="residual-value-report__description--text" [innerHTML]="getDescriptionKey() | translate"></p>
    <i (click)="removeDescription()" class="ib-icon icon--close_s residual-value-report__description--icon"></i>
  </div>
  <div class="residual-value-report__chart-container">
   <residual-value-first-chart
     [type]="type"
     [report]="report"
     [changeTab]="changeTab"
   >
</residual-value-first-chart>
  </div>

  <div class="residual-value-report__chart-container">
    <residual-value-second-chart
      [type]="type"
      [report]="report"
      [changeTab]="changeTab"
    ></residual-value-second-chart>
  </div>

  <div class="residual-value-report__chart-container">
    <residual-value-third-chart
      [type]="type"
      [report]="report"
      [changeTab]="changeTab"
    ></residual-value-third-chart>
  </div>
</div>
