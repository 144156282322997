import * as tslib_1 from "tslib";
import { chunkAnArray } from '../../../../../../shared/helpers/chuch-an-array';
import { isError, EXCEPTION } from '../types/errors.const';
import { first } from 'rxjs/operators';
var ReportBase = /** @class */ (function () {
    function ReportBase(reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter) {
        this.reportService = reportService;
        this.activatedRoute = activatedRoute;
        this.languageService = languageService;
        this.translateService = translateService;
        this.reportPresenter = reportPresenter;
        this.rowCounter = rowCounter;
        this.errors = [];
        this.categoriesPerPage = 12;
        this.errorsPerPage = 15;
        this.filteredProductCategory = {};
        this.reportId = this.activatedRoute.snapshot.params.id;
        this.accessKey = this.activatedRoute.snapshot.params.key;
    }
    ReportBase.prototype.ngAfterViewChecked = function () {
        this.languageService.setLang(this.activatedRoute.snapshot.params.lang);
    };
    Object.defineProperty(ReportBase.prototype, "result", {
        get: function () {
            return this.reportData.result[this.reportName];
        },
        enumerable: true,
        configurable: true
    });
    ReportBase.prototype.isEmptyProductCategory = function () {
        return Object.keys(this.filteredProductCategory).length === 0;
    };
    ReportBase.prototype.reportRequest = function () {
        var _this = this;
        return this.reportService.getReport(this.reportId, {
            expand: 'sites,result,sites.tags,tags',
            type: this.reportName, key: this.accessKey
        })
            .map(function (res) {
            _this.setReportDataValues(res);
            _this.setErrors();
            return res;
        });
    };
    ReportBase.prototype.setReportDataValues = function (response) {
        var _this = this;
        this.reportData = response;
        this.reportPresenter.updateTagsById(response.tags.map(function (tag) { return tag.id ? tag.id : Number(tag); }));
        this.reportPresenter.updateSiteList(response.sites);
        this.reportData.sites.map(function (site) { return site.tags = _this.reportPresenter.getTags(site.id); });
        this.showSitesInOnePage = true;
        this.afterSiteListRowsCounted();
        this.reportHeader = {
            title: this.reportName.split('_').join(' '),
            date: {
                created_at: response.created_at,
                timezone_offset: response.timezone_offset
            },
            number: response.result.report_count || response.id
        };
    };
    ReportBase.prototype.setErrors = function () {
        var _a, _b;
        var errors = this.result.errors;
        var warnings = this.result.warnings;
        if (errors) {
            (_a = this.errors).push.apply(_a, errors);
        }
        if (warnings) {
            (_b = this.errors).push.apply(_b, warnings);
        }
    };
    ReportBase.prototype.getSortedProductsBy = function (sortBy, type, filter) {
        if (filter === void 0) { filter = 'deposits_considered'; }
        var categories = {};
        var data = this.result.aggregate;
        if (data) {
            if (data.hasOwnProperty('category')) {
                categories = data.category;
            }
            categories = this.result.aggregate.category;
        }
        else {
            data = this.result[type].aggregate;
            if (data.hasOwnProperty('category')) {
                categories = data.category;
            }
        }
        if (!!categories) {
            categories = this.filteredProductCategory = tslib_1.__assign({}, Object.values(categories).filter(function (e) {
                if (e.hasOwnProperty(filter)) {
                    return !!e[filter];
                }
            }));
            return Object.values(categories).sort(function (a, b) { return a[sortBy] < b[sortBy] ? 1 : (b[sortBy] < a[sortBy] ? -1 : 0); });
        }
    };
    ReportBase.prototype.getAmountOf = function (key) {
        var type = this.reportData.result[this.reportName][key];
        return !!type ? type.length : 0;
    };
    ReportBase.prototype.getAggregatedErrorMessage = function (item) {
        if (item.hasOwnProperty('type')) {
            switch (item.type) {
                case EXCEPTION.RECYCLE_PRECENTAGE_NOT_SET:
                    return this.translateService.instant('AGGREGATED_WARNING_TYPE_RECYCLE_PRECENTAGE_NOT_SET', { Y: item.counter });
                case EXCEPTION.CONVERSION_NOT_SET:
                    return this.translateService.instant('AGGREGATED_ERROR_TYPE_CONVERSION_NOT_SET', { Y: item.counter });
                case EXCEPTION.CATEGORY_NULL:
                    return this.translateService.instant('AGGREGATED_WARNING_TYPE_CATEGORY_NULL', { Y: item.counter });
                case EXCEPTION.CATEGORY_PARENT:
                    return this.translateService.instant('AGGREGATED_WARNING_TYPE_CATEGORY_PARENT', { Y: item.counter });
                case EXCEPTION.CARBON_PRODUCT_NOT_SET:
                    return this.translateService.instant('AGGREGATED_WARNING_CARBON_PRODUCT_NOT_SET', { Y: item.counter });
                case EXCEPTION.DEPOSIT_FAILLING_TO_MATCHE_WITH_GP:
                    return this.translateService.instant('AGGREGATED_ERROR_DEPOSIT_FAILLING_TO_MATCHE_WITH_GP', { Y: item.counter });
                case EXCEPTION.CONVERSION_FACTOR_FUNCTIONAL_UNIT_NOT_SET:
                    return this.translateService.instant('AGGREGATED_ERROR_TYPE_CONVERSION_FACTOR_FUNCTIONAL_UNIT_NOT_SET', { Y: item.counter });
                case EXCEPTION.LACK_FINANCIAL_DATA:
                    return this.translateService.instant('AGGREGATED_ERROR_LACK_FINANCIAL_DATA', { Y: item.counter });
                case EXCEPTION.PURCHASE_PRICE_MISSING:
                    return this.translateService.instant('AGGREGATED_WARNING_PURCHASE_PRICE_MISSING', { Y: item.counter });
                case EXCEPTION.CONVERSION_FACTOR_MISSING:
                    return this.translateService.instant('AGGREGATED_WARNING_CONVERSION_FACTOR_MISSING', { Y: item.counter });
                case EXCEPTION.DEPOSIT_MATCH_NOT_RELEVANT:
                    return this.translateService.instant('AGGREGATED_WARNING_DEPOSIT_MATCH_NOT_RELEVANT', { X: item.message.slice(26, -37) });
                case EXCEPTION.CARBON_CONSTRUCTION_NOT_SET:
                    return this.translateService.instant('AGGREGATED_WARNING_CARBON_CONSTRUCTION_NOT_SET', { Y: item.counter });
                case EXCEPTION.CARBON_END_NOT_SET:
                    return this.translateService.instant('AGGREGATED_WARNING_CARBON_END_NOT_SET', { Y: item.counter });
                case EXCEPTION.CARBON_USE_NOT_SET:
                    return this.translateService.instant('AGGREGATED_WARNING_CARBON_USE_NOT_SET', { Y: item.counter });
                case EXCEPTION.CARBON_RECOVERY_NOT_SET:
                    return this.translateService.instant('AGGREGATED_WARNING_CARBON_RECOVERY_NOT_SET', { Y: item.counter });
                case EXCEPTION.NO_INFORMATION_PRODUCTION:
                    return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_PRODUCTION', { Y: item.counter });
                case EXCEPTION.NO_INFORMATION_CONSTRUCTION:
                    return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_CONSTRUCTION', { Y: item.counter });
                case EXCEPTION.NO_INFORMATION_EMBODIED:
                    return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_EMBODIED', { Y: item.counter });
                case EXCEPTION.NO_INFORMATION_IC_COMPONENT:
                    return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_IC_COMPONENT', { Y: item.counter });
                case EXCEPTION.NO_INFORMATION_IC_CONSTRUCTION:
                    return this.translateService.instant('AGGREGATED_ERROR_NO_INFORMATION_IC_CONSTRUCTION', { Y: item.counter });
                case EXCEPTION.USE_PERIOD_NOT_SET:
                    return this.translateService.instant('AGGREGATED_WARNING_USE_PERIOD_NOT_SET', { Y: item.counter });
                default:
                    return this.translateService.instant(item.message);
            }
        }
        return '';
    };
    ReportBase.prototype.getErrorMessage = function (item) {
        if (item.hasOwnProperty('type')) {
            switch (item.type) {
                case EXCEPTION.RECYCLE_PRECENTAGE_NOT_SET:
                    return this.translateService.instant('WARNING_TYPE_RECYCLE_PRECENTAGE_NOT_SET');
                case EXCEPTION.CONVERSION_NOT_SET:
                    return this.translateService.instant('ERROR_TYPE_CONVERSION_NOT_SET');
                case EXCEPTION.CATEGORY_NULL:
                    return this.translateService.instant('WARNING_TYPE_CATEGORY_NULL');
                case EXCEPTION.CATEGORY_PARENT:
                    return this.translateService.instant('WARNING_TYPE_CATEGORY_PARENT');
                case EXCEPTION.CARBON_PRODUCT_NOT_SET:
                    return this.translateService.instant('WARNING_CARBON_PRODUCT_NOT_SET');
                case EXCEPTION.DEPOSIT_FAILLING_TO_MATCHE_WITH_GP:
                    return this.translateService.instant('ERROR_DEPOSIT_FAILLING_TO_MATCHE_WITH_GP');
                case EXCEPTION.CONVERSION_FACTOR_FUNCTIONAL_UNIT_NOT_SET:
                    return this.translateService.instant('ERROR_TYPE_CONVERSION_FACTOR_FUNCTIONAL_UNIT_NOT_SET');
                case EXCEPTION.LACK_FINANCIAL_DATA:
                    return this.translateService.instant('ERROR_LACK_FINANCIAL_DATA');
                case EXCEPTION.PURCHASE_PRICE_MISSING:
                    return this.translateService.instant('WARNING_PURCHASE_PRICE_MISSING');
                case EXCEPTION.CONVERSION_FACTOR_MISSING:
                    return this.translateService.instant('WARNING_CONVERSION_FACTOR_MISSING');
                case EXCEPTION.DEPOSIT_MATCH_NOT_RELEVANT:
                    return this.translateService.instant('WARNING_DEPOSIT_MATCH_NOT_RELEVANT', { X: item.message.slice(26, -37) });
                case EXCEPTION.CARBON_CONSTRUCTION_NOT_SET:
                    return this.translateService.instant('WARNING_CARBON_CONSTRUCTION_NOT_SET');
                case EXCEPTION.CARBON_END_NOT_SET:
                    return this.translateService.instant('WARNING_CARBON_END_NOT_SET');
                case EXCEPTION.CARBON_USE_NOT_SET:
                    return this.translateService.instant('WARNING_CARBON_USE_NOT_SET');
                case EXCEPTION.CARBON_RECOVERY_NOT_SET:
                    return this.translateService.instant('WARNING_CARBON_RECOVERY_NOT_SET');
                case EXCEPTION.NO_INFORMATION_PRODUCTION:
                    return this.translateService.instant('ERROR_NO_INFORMATION_PRODUCTION');
                case EXCEPTION.NO_INFORMATION_CONSTRUCTION:
                    return this.translateService.instant('ERROR_NO_INFORMATION_CONSTRUCTION');
                case EXCEPTION.NO_INFORMATION_EMBODIED:
                    return this.translateService.instant('ERROR_NO_INFORMATION_EMBODIED');
                case EXCEPTION.NO_INFORMATION_IC_COMPONENT:
                    return this.translateService.instant('ERROR_NO_INFORMATION_IC_COMPONENT');
                case EXCEPTION.NO_INFORMATION_IC_CONSTRUCTION:
                    return this.translateService.instant('ERROR_NO_INFORMATION_IC_CONSTRUCTION');
                case EXCEPTION.USE_PERIOD_NOT_SET:
                    return this.translateService.instant('WARNING_USE_PERIOD_NOT_SET');
                default:
                    return this.translateService.instant(item.message);
            }
        }
        return '';
    };
    ReportBase.prototype.onEachChunkedCategory = function (onEachChunk) {
        return chunkAnArray(this.categories, this.categoriesPerPage, this.firstPageCategoriesAmount)
            .forEach(function (chunk, index) { return onEachChunk(chunk, index); });
    };
    ReportBase.prototype.onEachChunkedError = function (onEachChunk) {
        return chunkAnArray(this.errors, this.errorsPerPage)
            .forEach(function (chunk, index) { return onEachChunk(chunk, index); });
    };
    ReportBase.prototype.isError = function (type) {
        return isError(type);
    };
    ReportBase.prototype.numberFormat = function (value, greaterThen, lessThen) {
        if (greaterThen === void 0) { greaterThen = '1.0-1'; }
        if (lessThen === void 0) { lessThen = '1.2-2'; }
        return value > 1 ? greaterThen : (value === 0 ? '0.0-0' : lessThen);
    };
    ReportBase.prototype.afterSiteListRowsCounted = function () {
        var _this = this;
        if (this.rowCounter) {
            this.rowCounter.updateRowCount$.pipe(first()).subscribe(function () { return _this.showSitesInOnePage = _this.rowCounter.isRowNumberBiggerThan(2); });
        }
    };
    ReportBase.prototype.addTitlePagesNumber = function (index) {
        return (this.showSitesInOnePage ? 3 : 2) + index;
    };
    return ReportBase;
}());
export { ReportBase };
