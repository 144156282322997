import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DASHBOARD_GUARDS } from '../shared/auth/dashboard-guards.const';
import { ForgotPasswordComponent } from '../login/forgot-password/forgot-password.component';
import { ConfirmationPasswordComponent } from '../login/confirmation-pasword/confirmation-password.component';
import { ActivatingAccountComponent } from '../login/activating-account/activating-account.component';
import { CircularityReportComponent } from '../dashboards/admin/components/admin-reporting/report-pdf/circularity/circularity-report.component';
import { CarbonFootprintPdfReportComponent } from "../dashboards/admin/components/admin-reporting/report-pdf/carbon-footprint/carbon-footprint-pdf-report.component";
import { MaterialHealthReportComponent } from '../dashboards/admin/components/admin-reporting/report-pdf/material-health/material-health-report.component';
import { ResidualValuePdfReportComponent } from "../dashboards/admin/components/admin-reporting/report-pdf/residual-value/residual-value-pdf-report.component";
import { PublicPassportListComponent } from './public-passport-list/public-passport-list.component';
import { PassportEditV2Component } from '../entities/passport/partials/passport-edit-v2/passport-edit-v2.component';
import { PassportResolver } from '../entities/passport/resolvers/passport.resolver';
var ɵ0 = { isPublicAccess: true };
var appRoutes = [
    {
        path: '',
        canActivate: DASHBOARD_GUARDS.slice(),
        component: AppComponent
    },
    {
        path: 'login/forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'passports/:id',
        component: PassportEditV2Component,
        resolve: {
            entity: PassportResolver
        },
        data: ɵ0
    },
    {
        path: 'login/confirmation-password',
        component: ConfirmationPasswordComponent,
    },
    {
        path: 'login/user-activation',
        component: ActivatingAccountComponent,
    },
    {
        path: 'cloud',
        component: PublicPassportListComponent,
    },
    {
        path: 'login',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../login/login.module#LoginModule'
    },
    {
        path: 'admin',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/admin/admin.module#AdminModule',
    },
    {
        path: 'superadmin',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/superadmin/superadmin.module#SuperadminModule',
    },
    {
        path: 'specialadmin',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/specialadmin/specialadmin.module#SpecialAdminModule',
    },
    {
        path: 'sales',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/sales/sales.module#SalesModule',
    },
    {
        path: 'agent',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/agent/agent.module#AgentModule',
    },
    {
        path: 'supervisor',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/supervisor/supervisor.module#SuperVisorModule',
    },
    {
        path: 'manufacturer',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/manufacturer/manufacturer.module#ManufacturerModule',
    },
    {
        path: 'deposit-owner',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/deposit-owner/deposit-owner.module#DepositOwnerModule',
    },
    {
        path: 'ecosystem',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/ecosystem-manager/ecosystem-manager.module#EcosystemManagerModule',
    },
    {
        path: 'transform',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/transformation-manager/transformation-manager.module#TransformationManagerModule',
    },
    {
        path: 'transform-independent',
        canActivate: DASHBOARD_GUARDS.slice(),
        loadChildren: '../dashboards/transformation-manager-independent/transformation-manager-independent.module#TransformationManagerIndependentModule',
    },
    // TODO somewhere routes related to report-pdf
    {
        path: 'report-pdf/circularity/:lang/:id/:key',
        component: CircularityReportComponent,
    },
    {
        path: 'report-pdf/carbon-footprint/:lang/:id/:key',
        component: CarbonFootprintPdfReportComponent,
    },
    {
        path: 'report-pdf/material-health/:lang/:id/:key',
        component: MaterialHealthReportComponent,
    },
    {
        path: 'report-pdf/residual-value/:lang/:id/:key',
        component: ResidualValuePdfReportComponent,
    },
];
export var AppRoutingModule = RouterModule.forRoot(appRoutes);
export { ɵ0 };
