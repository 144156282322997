import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';

@Injectable()
export class TestDataGenerationService extends ApiService {

  public getNewEntityWithGeneratedData(): Observable<any> {
    return this.sendPost(this.getEndpoint('company/test'))
      .map((data: Response) => data.text());

    // return Observable.of({check : true});
  }
}
