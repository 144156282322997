/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import-checked.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i5 from "@angular/material";
import * as i6 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i7 from "../../../../shared/read-only/read-only.service";
import * as i8 from "./import-checked.component";
var styles_ImportCheckedComponent = [i0.styles];
var RenderType_ImportCheckedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImportCheckedComponent, data: {} });
export { RenderType_ImportCheckedComponent as RenderType_ImportCheckedComponent };
export function View_ImportCheckedComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "import-checked"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "import-checked__caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "import-checked__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "import-checked__check-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "import-checked__check-mark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "icon icon--check-import"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "import-checked__atention-message-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "import-checked__attention"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["!"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "import-checked__attention--text"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 9, "div", [["class", "import-checked__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 8, "button", [["class", "deposit-warning__button"], ["md-button", ""], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MdButton_0, i4.RenderType_MdButton)), i1.ɵdid(17, 16384, null, 0, i5.MdPrefixRejector, [[2, i5.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(18, 180224, null, 0, i5.MdButton, [i1.ElementRef, i1.Renderer, i5.FocusOriginMonitor], null, null), i1.ɵdid(19, 16384, null, 0, i5.MdButtonCssMatStyler, [], null, null), i1.ɵdid(20, 16384, null, 0, i5.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(21, 16384, null, 0, i6.FormElemsHiddenDirective, [[2, i7.ReadOnlyService]], null, null), (_l()(), i1.ɵted(22, 0, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(24, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("File checked !")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("IMPORT_CHECK_CONFIRMATION")); _ck(_v, 13, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 18).disabled; var currVal_3 = true; var currVal_4 = true; var currVal_5 = ((i1.ɵnov(_v, 21).readOnlyService == null) ? null : i1.ɵnov(_v, 21).readOnlyService.readOnly); _ck(_v, 16, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 22, 0, _ck(_v, 24, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("OK !")))); _ck(_v, 22, 0, currVal_6); }); }
export function View_ImportCheckedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "import-checked", [], null, null, null, View_ImportCheckedComponent_0, RenderType_ImportCheckedComponent)), i1.ɵdid(1, 49152, null, 0, i8.ImportCheckedComponent, [i5.MdDialogRef], null, null)], null, null); }
var ImportCheckedComponentNgFactory = i1.ɵccf("import-checked", i8.ImportCheckedComponent, View_ImportCheckedComponent_Host_0, {}, {}, []);
export { ImportCheckedComponentNgFactory as ImportCheckedComponentNgFactory };
