/**
 * Created by Aleksandr C. on 13.02.17.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import { Passport } from '../../../passport/models/passport.class';


@Component({
  moduleId: module.id,
  selector: 'create-passport',
  templateUrl: 'create-passport.component.html'
})
export class CreatePassportDialogComponent implements OnInit, OnDestroy {

  constructor(private dialog: MdDialogRef<any>) {

  }

  ngOnInit() {

  }

  onSave(passport: Passport) {
    this.dialog.close(passport);
  }

  ngOnDestroy() {

  }
}
