<article class="ecosystem-dashboard">
  <main class="ecosystem-dashboard__content">
    <ecosystem-primary-header [isMainPage]="false"></ecosystem-primary-header>
    <!--<section class="ecosystem-dashboard__section ecosystem-dashboard__section&#45;&#45;counters">-->
    <!--<ul class="ecosystem-dashboard__counter-list">-->
    <!--<li class="ecosystem-dashboard__counter-item">-->
    <!--<div class="ecosystem-dashboard__counter-value"><i class='icon icon&#45;&#45;ecosystem_ok'></i></div>-->
    <!--<span class="ecosystem-dashboard__counter-title">{{ 'Not reported problems' | translate }}</span>-->
    <!--</li>-->
    <!--<li class="ecosystem-dashboard__counter-item">-->
    <!--<div class="ecosystem-dashboard__counter-value">4</div>-->
    <!--<span class="ecosystem-dashboard__counter-title">{{ 'New offers validated' | translate }}</span>-->
    <!--</li>-->
    <!--<li class="ecosystem-dashboard__counter-item">-->
    <!--<div class="ecosystem-dashboard__counter-value">1</div>-->
    <!--<span class="ecosystem-dashboard__counter-title">{{ 'Ecosystem waiting for quote' | translate }}</span>-->
    <!--</li>-->
    <!--</ul>-->
    <!--</section>-->
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--ecosystems">
      <ecosystem-ecosystems-navigator></ecosystem-ecosystems-navigator>
    </section>
  </main>
</article>
