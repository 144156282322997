import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../../entities/stats/services/stats.service';
import {
  AGENT_STATISTICS_SEGMENTS,
  AGENT_STATISTICS_SEGMENTS_VIEW
} from '../../values/agent-statistics-segments.const';
import { AgentStatsContainer } from '../../../../entities/stats/models/agent-stats-container.class';

@Component({
  moduleId: module.id,
  templateUrl: 'agent-statistics.component.html'
})

export class AgentStatisticsComponent implements OnInit {

  AGENT_STATISTICS_SEGMENTS = AGENT_STATISTICS_SEGMENTS;
  AGENT_STATISTICS_SEGMENTS_VIEW = AGENT_STATISTICS_SEGMENTS_VIEW;
  segments: AGENT_STATISTICS_SEGMENTS[] = [
    AGENT_STATISTICS_SEGMENTS.DEPOSITS,
    AGENT_STATISTICS_SEGMENTS.SITES
  ];
  selectedSegment: number = AGENT_STATISTICS_SEGMENTS.DEPOSITS;
  segmentStats: any = {};

  constructor(private statsService: StatsService) {
  }

  ngOnInit() {
    this.getSegmentData();
  }

  changeSegment(segment: number) {
    this.selectedSegment = segment;
    this.getSegmentData();
  }

  getSegmentData() {
    switch (this.selectedSegment) {
      case AGENT_STATISTICS_SEGMENTS.DEPOSITS:
        this.getDepositsData();
        break;
      case AGENT_STATISTICS_SEGMENTS.SITES:
        this.getSitesData();
        break;
      default:
        break;
    }
  }

  getDepositsData() {
    this.statsService.getAgentStats().subscribe((agentStats: AgentStatsContainer) => {
      this.segmentStats = agentStats.getDeposits();
    });
  }

  getSitesData() {
    this.statsService.getAgentStats().subscribe((agentStats: AgentStatsContainer) => {
      this.segmentStats = agentStats.getSites();
    });
  }
}
