import { EnvConfig } from '../../../tools/env/env-config.interface';
import { environment } from '../../environments/environment';
// export const Config: EnvConfig = {
//   API:"//api.beta.upcyclea.com",
//   ENV:"PROD",
//   DEEPSTREAM_API: "wss://ds.beta.upcyclea.com/deepstream",
// };

export const Config: EnvConfig = {
  API:environment.API,
  ENV:environment.ENV,
  DEEPSTREAM_API: environment.DEEPSTREAM_API,
};
export const GoogleConfig = {
  API_KEY: 'AIzaSyBQ4lPI854HhW7Q2ny9eEF4nJ6fj7KD6dA',
};
