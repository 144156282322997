/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i2 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i3 from "../../partials/deposit-site-details/deposit-site-details-preview/deposit-site-details-preview.component.ngfactory";
import * as i4 from "../../../../shared/read-only/read-only.service";
import * as i5 from "../../partials/deposit-site-details/deposit-site-details-preview/deposit-site-details-preview.component";
import * as i6 from "../../../../shared/services/bim.service";
import * as i7 from "./deposit-site-details-preview-dialog.component";
import * as i8 from "@angular/material";
var styles_DepositSiteDetailsPreviewDialogComponent = [];
var RenderType_DepositSiteDetailsPreviewDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DepositSiteDetailsPreviewDialogComponent, data: {} });
export { RenderType_DepositSiteDetailsPreviewDialogComponent as RenderType_DepositSiteDetailsPreviewDialogComponent };
export function View_DepositSiteDetailsPreviewDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DialogCloseButtonComponent_0, i1.RenderType_DialogCloseButtonComponent)), i0.ɵdid(1, 49152, null, 0, i2.DialogCloseButtonComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "deposit-site-details-preview", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.mdDialogRef.close($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DepositSiteDetailsPreviewComponent_0, i3.RenderType_DepositSiteDetailsPreviewComponent)), i0.ɵprd(512, null, i4.ReadOnlyService, i4.ReadOnlyService, []), i0.ɵdid(4, 114688, null, 0, i5.DepositSiteDetailsPreviewComponent, [i6.BIMService, i4.ReadOnlyService], { location: [0, "location"], uploader: [1, "uploader"], readOnly: [2, "readOnly"], isValid: [3, "isValid"] }, { onSave: "onSave" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.mdDialogData.location; var currVal_2 = _co.mdDialogData.uploader; var currVal_3 = _co.mdDialogData.readOnly; var currVal_4 = (_co.mdDialogData.isValid || _co.defaultValidation); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).applyClassPosition; _ck(_v, 0, 0, currVal_0); }); }
export function View_DepositSiteDetailsPreviewDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [["class", "dialog-close-button-container"]], null, null, null, View_DepositSiteDetailsPreviewDialogComponent_0, RenderType_DepositSiteDetailsPreviewDialogComponent)), i0.ɵdid(1, 49152, null, 0, i7.DepositSiteDetailsPreviewDialogComponent, [i8.MD_DIALOG_DATA, i8.MdDialogRef], null, null)], null, null); }
var DepositSiteDetailsPreviewDialogComponentNgFactory = i0.ɵccf("ng-component", i7.DepositSiteDetailsPreviewDialogComponent, View_DepositSiteDetailsPreviewDialogComponent_Host_0, {}, {}, []);
export { DepositSiteDetailsPreviewDialogComponentNgFactory as DepositSiteDetailsPreviewDialogComponentNgFactory };
