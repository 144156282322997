import { Component } from '@angular/core';
import { NomenclatureItem } from '../../models/nomenclature-item.class';
import { MdDialogRef } from '@angular/material';

@Component({
  template: `
    <nomenclature-tree-view
      [perfectScrollbar]
      (onSave)="mdDialogRef.close($event)"
    ></nomenclature-tree-view>
  `,
  styles: [
      `
      nomenclature-tree-view {
        height: 100%;
        display: block;
      }
    `,
  ]
})
export class SelectDialogComponent {
  constructor(public mdDialogRef: MdDialogRef<NomenclatureItem>) {
  }
}
