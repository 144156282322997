import {Component, EventEmitter, Inject, Injector, Input, OnInit, OnDestroy, Output} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel
} from '@angular/forms';

@Component({
  selector: 'passport-conversion',
  templateUrl: './passport-conversion.component.html',
  providers: [{provide: NG_VALUE_ACCESSOR, multi: true, useExisting: PassportConversionComponent}]
})
export class PassportConversionComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() errorText =  'VALIDATOR_ERROR_PRIMARY_MESSAGE';
  @Input() unitConvertTo =  'kg';
  @Input() showValidationError = this.defaultShowError;
  @Input() unitView: string;
  @Input() disabled: boolean;
  @Output() ngModelChange = new EventEmitter();
  @Input() set ngModel(item: number) {
    this.value = item;
  }

  control = new FormControl();
  private onTouch;
  private onChange;
  private subscription;
  private value;

  constructor (@Inject(Injector) private injector: Injector) { }

  ngOnInit() {
    const _control = this.injector.get(NgControl);
    switch (_control.constructor) {
      case NgModel: {
       this.control = (_control as any as NgModel).control;
       this.control.setValue(this.value);
       break;
      }
      case FormControlName: {
        const group = this.injector.get(FormGroupDirective);
        setTimeout(() => {
           this.control = group.getControl((_control as any as FormControlName));
           this.control.setValue(this.value);
           this.subscription = this.control.valueChanges.subscribe(event => this.ngModelChange.emit(event));
        });
        break;
      }
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }

  defaultShowError() {
    const errors = (this.control && this.control.errors) || [];
    return !!Object.keys(errors).length;
  }
}
