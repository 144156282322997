import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardComponentBase, IFilterState } from '../../../app/components/base/dashboard/dashboard-component-base.directive';
import { PassportCollectionComponent } from '../../../app/components/passport/passport-Collection/passport-collection.component';
import { MdDialog } from '@angular/material';
import { Passport } from '../../../entities/passport/models/passport.class';
import { PassportStatus } from '../../../entities/passport/models/passport-status.class';
import { SpecialAdminService } from '../../../entities/passport/services/specialAdmin.service';
import { PASSPORT_STATUS_CONST_SPECIAL_ADMIN } from '../../../entities/passport/values/passport-status.const';
import { LocalStorageService } from '../../../shared/services/localStorage.service';

export interface ISpecialAdminFilterState extends IFilterState {
  status: number[];
  company_creators: string[];
  seeOnlyWaitingForRating: boolean;
}

@Component({
  selector: 'app-special-admin-passport-dashboard',
  templateUrl: './specialAdmin-passport-dashboard.component.html',
  styleUrls: ['./specialAdmin-passport-dashboard.component.scss']
})
export class SpecialAdminPassportDashboardComponent extends DashboardComponentBase<ISpecialAdminFilterState> implements OnInit {
  currentTab: string = 'default';
  actionButtonsConfig: { duplicate: boolean, copyId: boolean, delete: boolean, version: boolean } = {
    duplicate: false,
    copyId: true,
    delete: false,
    version: true
  };
  @ViewChild('passportCollectionRef') passportCollection: PassportCollectionComponent;

  constructor(
    private specialAdminService: SpecialAdminService,
    public dialog: MdDialog,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  ngOnInit() {
    this.initializeFilterStates();
    this.loadDataForTab();
  }

  private initializeFilterStates() {
    this.pagination = {
      current_page: 1,
      page_count: null,
      total_count: null,
      pagination_per_page: null,
    };
    const stored = this.localStorageService.getStoredObject<{ states: { [key: string]: ISpecialAdminFilterState }, currentTab: string }>('specialAdminFilters');
    if (stored) {
      this.filterStates = stored.states;
      this.currentTab = stored.currentTab;
    } else {
      // Default state setup
      this.filterStates['default'] = this.getDefaultFilterState();
    }
  }

  public loadDataForTab(resetPage: boolean = true): void {
    if (resetPage) {
      this.pagination.current_page = 1;
      this.passportCollection.clearSelection();
    }
    this.isLoading = true;
    this.passports = []; // Clear current data for safety

    if (this.passportsRequestSubscription) {
      this.passportsRequestSubscription.unsubscribe();
    }

    let currentFilters = this.filterStates[this.currentTab] || this.getDefaultFilterState();
    const requestPayload = this.convertFilterStateToObj(currentFilters);

    // Store the filters in local storage including the current tab
    this.localStorageService.storeObject('specialAdminFilters', { states: this.filterStates, currentTab: this.currentTab });

    this.passportsRequestSubscription = this.specialAdminService.getPassports(this.pagination.current_page, 10, requestPayload).subscribe({
      next: (response: any) => {
        this.handleResponse(response);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  private handleResponse(response: any): void {
    const body = JSON.parse(response._body);
    const headers = response.headers;
    this.pagination = {
      current_page: headers.get('X-Pagination-Current-Page'),
      page_count: headers.get('X-Pagination-Page-Count'),
      total_count: headers.get('X-Pagination-Total-Count'),
      pagination_per_page: headers.get('X-Pagination-Per-Page'),
    };
    this.passports = body.map((item: any) => new Passport(item));
  }

  public getDefaultFilterState(): ISpecialAdminFilterState {
    return {
      sortBy: 'performance',
      search: '',
      families: [],
      materials: [],
      categories: [],
      searchId: [],
      selectedOptions: [],
      databases: [],
      dataProviders: [],
      dataRepresentativity: [],
      epdType: [],
      seeOnlyWaitingForRating: false,
      rating: {
        passportWithoutRating: false,
        selectRatingFilter: false,
        C2cCertified: false,
        composition: { color: false, black: false },
        cycle: { color: false, black: false },
        energy: { color: false, black: false },
        water: { color: false, black: false },
        social: { color: false, black: false },
      },
      status: [3, 4, 2],
      company_creators: [],
    };
  }

  public resetFiltersToDefault() {
    this.filterStates[this.currentTab] = this.getDefaultFilterState();
    this.loadDataForTab();
  }

  getStatusFilter(): PassportStatus[] {
    return Object.values(PASSPORT_STATUS_CONST_SPECIAL_ADMIN);
  }
}
