import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { DepositLocationImport } from '../models/deposit-location-import.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../../../shared/services/log-notification.service";
import * as i3 from "../../../shared/services/navigation.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/services/mock/mock-api.service";
var DepositLocationImportService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationImportService, _super);
    function DepositLocationImportService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'deposit-location-imports';
        _this.namespaceSingular = 'deposit-location-import';
        _this.ModelClass = DepositLocationImport;
        return _this;
    }
    DepositLocationImportService.prototype.getSuccessSummaryUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/success-summary');
    };
    DepositLocationImportService.prototype.getSuccessSummary = function (id, query) {
        if (query === void 0) { query = {}; }
        return this.sendGet(this.getSuccessSummaryUrl(id), { search: query })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    DepositLocationImportService.ngInjectableDef = i0.defineInjectable({ factory: function DepositLocationImportService_Factory() { return new DepositLocationImportService(i0.inject(i1.Http), i0.inject(i2.LogNotificationService), i0.inject(i3.NavigationService), i0.inject(i4.TranslateService), i0.inject(i5.MockApiService)); }, token: DepositLocationImportService, providedIn: "root" });
    return DepositLocationImportService;
}(ExtendedCrudService));
export { DepositLocationImportService };
