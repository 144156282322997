import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {EcosystemFileJunction} from '../../../dashboards/ecosystem-manager/models/ecosystem-file-junction.class';
import {EcosystemFile} from '../../../dashboards/ecosystem-manager/models/ecosystem-file.class';
import {ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE} from '../../../dashboards/ecosystem-manager/values/ecosystem-file-junction-item-type.const';
import {catchError} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EcosystemFileToJunctionService {

  constructor() { }

  public updateFilesAndJunctions(
    entityData: {entity, type}, files: EcosystemFile[], junctions
    , callback?: (data: {junctions: EcosystemFileJunction[], files: EcosystemFile[], failed: EcosystemFile[]}) => any
  ) {
    if (!callback) {
      callback = () => {};
    }
    const uploadFiles = this.filterFilesWithoutJunction(files, junctions);
    const copyFiles = this.filterFilesWithJunction(files, junctions);
    const assignFiles = () => {
      files.splice(0, files.length);
      files.push(...copyFiles);
    };
    if (!uploadFiles.length) {
      assignFiles();
      callback({junctions, files, failed: []});
      return {junctions, files, failed: []};
    }
    this.createJunctionFromFiles(
      entityData.entity, entityData.type, uploadFiles)
      .subscribe((data) => {
          junctions.push(...data.junctions);
          copyFiles.push(...data.files);
          assignFiles();
          callback(data);
        });
  }

  public createJunctionFromFiles(entity: {id: number}, type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE, files: EcosystemFile[]
                                 , junctionUploaded: EcosystemFileJunction[] = [])
    : Observable<{junctions: EcosystemFileJunction[], files: EcosystemFile[], failed: EcosystemFile[]}> {
    const errors = [];
    return Observable.combineLatest(
      this.filterFilesWithoutJunction(files, junctionUploaded).map(file =>
          this.createSingleJunction(file, entity, type).pipe(catchError(() => {
            errors.push(file);
            return EMPTY;
          }))
        )
    ).map((junctions: EcosystemFileJunction[])  => {
        junctions = [...junctionUploaded, ...junctions];
        return ({junctions, files: junctions.map(item => item.ecosystem_file), failed: errors});
      }
    );
  }
  public filterFilesWithJunction(files, junctions: EcosystemFileJunction[]): EcosystemFile[] {
    const fileUploaded = new Set(junctions.map(file => file.file_id ));
    return files.filter(file => fileUploaded.has(file.id));
  }

  public filterFilesWithoutJunction(files, junctions: EcosystemFileJunction[]): EcosystemFile[] {
    const fileUploaded = new Set(junctions.map(file => file.file_id ));
    return files.filter(file => !fileUploaded.has(file.id));
  }

  private createSingleJunction(file: EcosystemFile, entity, type): Observable<EcosystemFileJunction> {
    const fileJunction = new EcosystemFileJunction({
      file_id: file.id,
      item_id: entity.id,
      item_type: type
    });
    return fileJunction.save();
  }

  public deleteLinkInsteadIfExist(file: EcosystemFile, links: EcosystemFileJunction[], callback?: Function) {
    const junction: EcosystemFileJunction = links.find(item => item.file_id === file.id);
    if (junction) {
      junction.destroy().subscribe(() => {
        callback(junction);
      });
      return true;
    }
    return false;
  }

  public removeJunction(junction: EcosystemFileJunction, files: EcosystemFile[], junctions: EcosystemFileJunction[]) {
    files = files.filter(item => item.id !== junction.file_id);
    junctions = junctions.filter(item => item.id !== junction.id);
    return {junctions, files};
  }
}
