import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { Response } from '@angular/http';
import {ITranslationRequest, ITranslationResponse} from '../../entities/passport/services/translation.interface';
import {ApiService} from './api.service';


@Injectable()
export class TranslationService extends ApiService {
  protected namespace = 'translation';
  protected ModelClass = '';

  public translate(translationRequest: ITranslationRequest): Observable<ITranslationResponse[]> {
    return this.sendPost(this.getEndpoint(this.namespace + '/translate'), translationRequest)
      .map((response: Response) => response.json())
      .catch(this.onError.bind(this));
  }

}
