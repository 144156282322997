import * as tslib_1 from "tslib";
import { CrudService } from './crud.service';
import { Country } from '../models/country.class';
var CountryService = /** @class */ (function (_super) {
    tslib_1.__extends(CountryService, _super);
    function CountryService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'countries';
        _this.ModelClass = Country;
        return _this;
    }
    CountryService.prototype.getlistCountriesByUser = function (role) {
        return this.sendGet(this.getEndpoint(this.namespace + "/list-countries-by-user"
            + '?role=' + role), { withCredentials: true });
    };
    return CountryService;
}(CrudService));
export { CountryService };
