import {User} from '../../shared/models/user.class';
import {OrganisationTIds} from '../../shared/values/company-values.enum';
import {ERoleIds} from '../../shared/values/role-ids.enum';
import {IRolePermission, IRolePermissionContext} from '../../shared/interfaces/role-permission.interface';
import {AbstractPermission} from '../../shared/models/abstract-permission.class';
import {EAgentPermission} from './values/agent-permission.enum';

export class RestrictedBuildingPermission extends AbstractPermission implements IRolePermission {
  public static readonly id = EAgentPermission.RESTRICTED_AGENT;
  name = 'Agent without privileges to create new sites';
  
  isValid(user: User){
    return user.company.organisation_type === OrganisationTIds.Building;
  }
}

export const AGENT_PERMISSIONS: IRolePermissionContext = {
  roleId: ERoleIds.AGENT,
  permissions: {
    [RestrictedBuildingPermission.id]: new RestrictedBuildingPermission(),
  },
  defaultPermissionsIdsBefore: [],
  defaultPermissionsIdsAfter: [RestrictedBuildingPermission.id]
};