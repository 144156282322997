import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import {UsedEnergy} from '../models/used-energy.class';

@Injectable()
export class UsedEnergyService extends CrudService {
  protected namespace = 'passport-energies';
  protected namespaceSingular = 'passport-energies';
  protected ModelClass = UsedEnergy;
}
