import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import { EcosystemFile } from '../../models/ecosystem-file.class';
import { EcosystemFileService } from '../../../../entities/ecosystem/services/ecosystem-file.service';
import { Ecosystem } from '../../models/ecosystem.class';
import { FileUploader } from 'ng2-file-upload';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { Utils } from '../../../../shared/utils/utils.class';

@Component({
  moduleId: module.id,
  selector: 'ecosystem-file',
  templateUrl: 'ecosystem-file.component.html',
  styleUrls: ['ecosystem-file.component.scss']
})
export class EcosystemFileDialogComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  uploadedDocs: EcosystemFile[] = [];

  public ecosystem: Ecosystem;
  public isOpenFiles: boolean = false;
  uploader: FileUploader;
  stepId: number = null;

  constructor(public mdDialogRef: MdDialogRef<any>,
              private ecoFileService: EcosystemFileService,
              public notification: LogNotificationService) {
  }

  preloadUploadedItems() {
    this.ecoFileService
      .get<EcosystemFile>({
        ecosystem_id: this.ecosystem.id
      })
      .subscribe(
        docs => {
          this.uploadedDocs.push(...docs);
        }
      )
    ;
  }

  ngOnInit() {
    this.ecosystem = this.mdDialogRef._containerInstance.dialogConfig.data.ecosystem;
    this.isOpenFiles = this.mdDialogRef._containerInstance.dialogConfig.data.isOpenFiles;
    this.stepId = this.mdDialogRef._containerInstance.dialogConfig.data.stepId;
    console.log('step id ' , this.stepId);
    this.preloadUploadedItems();
    this.uploader = new FileUploader({
      url: this.ecosystem.getFileUploadUrl(),
      itemAlias: 'file',
      autoUpload: true
    });
    this.uploader.onSuccessItem = ((_, file) => {
      console.log('file uploaded:', file);
      this.uploadedDocs.push(this.ecoFileService.buildModel<EcosystemFile>(file));
    });
    this.uploader.onErrorItem = Utils.onUploadError((event: string) => this.notification.error(event,null,true));
  }

  uploadFiles() {
    this.fileInput.nativeElement.click();
  }

  removeFile(file: EcosystemFile) {
    this.ecoFileService.remove(file.id).subscribe((result) => {
      console.log('file was deleted:', file);
      this.uploadedDocs.splice(this.uploadedDocs.indexOf(file),1);
    });
  }

  selectFile(file: EcosystemFile) {
    console.log('file was selected:', file);
    this.mdDialogRef.close(file);
  }
}
