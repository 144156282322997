<div
     [ngClass]="{'passport-preview-item': true, 'passport-preview-item--odd': this.isColored}"
     [attr.data-primary-key]="passport.id">
  <passport-family-label class="passport-preview-item__family-label"
  [familyType]="passport.family"></passport-family-label>
  <div class="passport-preview-item__img">
    <img *ngIf="!!imageSrc" [src]="imageSrc" class="passport-preview-item__imglogo">
  </div>
  <div class="passport-preview-item__info">

    <div class="passport-preview-item__created-at">
      <div class="passport-preview-item__created">
        {{'Modified' | translate}}:
        <span class="passport-preview-item__created--date"
        >{{passport.updated_at | localizedDate:'MMMM dd, y'}}</span>
      </div>
      <div class="passport-preview-item__created--by" *ngIf="!!getCreatedBy()">
        &nbsp;{{'by' | translate}}:
        <span class="passport-preview-item__created--name primary-color" [innerHTML]="getCreatedBy()"></span>
      </div>
    </div>

    <div class="passport-preview-item__top">
        <div class="passport-preview-item__logoname"
             [mdTooltip]="getPassportName()"
             [mdTooltipPosition]="'right'"
             appCustomTooltip
             [validateOnlyScrolled]="true"
             [tooltipClass]="['passportlist__name-tooltip'
             , 'passportlist__name-tooltip--left'
             , 'background-primary-color']"
             [innerHTML]="getValue(getPassportName())">
        </div>
    </div>

    <div class="passport-preview-item__content">

      <div class="passport-preview-item__labels">
        <div class="passport-preview-item__label">
          {{'Category' | translate}}:
          <span class="passport-preview-item__label--value" [innerHTML]="getValue(passport.category?.name | translate)"></span>
          /
          {{'Unit' | translate}}:
          <span class="passport-preview-item__label--value" [innerHTML]="getValue(passport.unit_text | translate)"></span>
        </div>
        <div class="passport-preview-item__label passport-preview-item__components">
          {{'Component' | translate}}:
          <div [ngStyle]="{'display': 'inline-block'}">
             <span class="passport-preview-item__label--value"
                   *ngFor="let component of passport.components;let isLast=last"  [innerHTML]="getValue(getArrayItem(component, isLast))">
          </span>
          </div>
        </div>
        <div class="passport-preview-item__label passport-preview-item__materials">
          {{'Material' | translate}}:
          <div [ngStyle]="{'display': 'inline-block'}">
            <span class="passport-preview-item__label--value"
                  *ngFor="let nomenclature of passport.nomenclature;let isLast=last" [innerHTML]="getValue(getArrayItem(nomenclature, isLast))">
            </span>
          </div>
          &nbsp; / &nbsp;{{'Version' | translate}}:
          <div [ngStyle]="{'display': 'inline-block'}">
            <span class="passport-preview-item__label--value"
                   [innerHTML]="passport.version">
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div>

  </div>

  <div [ngClass]="{'passport-preview-item__choose': true}">

    <div [ngClass]="{'passport-preview-item__choose-button': true ,'primary-color background-secondary-color': isChooseHovered}"
    (mouseenter)="isChooseHovered = (true)"
    (mouseleave)="isChooseHovered = (false)"
    (click)="selectPassport(passport)"

    >{{'Choose' | translate | uppercase}}
  </div>

  <div class= "passport-preview-item__wrapper" >
    <div [ngClass]="{'passport-preview-item__copy-id': true ,'primary-color background-secondary-color': isCopyHovered2}"
      (mouseenter)="isCopyHovered2 = (true)" (mouseleave)="isCopyHovered2 = (false)" (click)="copyPassportId(passport)">
      {{'ID' | translate | uppercase}}
    </div>
    <div [ngClass]="{'passport-preview-item__version': true ,'primary-color background-secondary-color': isVersionFilterOn}"
      (click)="filterByVersion()">
      {{'V' | translate | uppercase}}
    </div>
  </div>

</div>

</div>

