import {Component, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DepositFile} from '../../../entities/deposit/models/deposit-file.class';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DepositImport} from '../../../entities/deposit/models/deposit-import.class';
import {IMPORT_DEPOSITS_TAB} from '../../values/import-deposit-tab.const';
import {MdDialog} from '@angular/material';
import {LogNotificationService} from '../../../shared/services/log-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {DepositImportService} from '../../../entities/deposit/services/deposit-import.service';
import {ImportConfigurationService} from '../../services/import-configuration.service';
import {IColumnMeta, IControlMeta} from '../../values/interfaces/column-meta.interface';
import {IImportPreferences} from '../../values/interfaces/import-preferences.interface';
import {ImportConfiguration, ImportTable} from '../../models/import-configuration.class';
import {IMPORT_DOCUMENT_CONTROLS} from '../../values/import-document-controls.const';
import {omit} from 'lodash';
import {ExtendedActiveRecord} from '../../../shared/models/extended-active-record.class';
import {UploadedDocument} from '../../../shared/models/uploaders/document.class';
import {ImportProgressService} from '../../services/import-progress.service';

@Component({
  selector: 'app-import-spreadsheet',
  templateUrl: './import-spreadsheet.component.html',
  styleUrls: ['./import-spreadsheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportSpreadsheetComponent implements OnInit, OnDestroy {
  IMPORT_DEPOSITS_TAB = IMPORT_DEPOSITS_TAB;
  @Input() public importModel: ExtendedActiveRecord;
  @Input() labelText: string;
  @Input() public importForm: FormGroup = this.getImportGeneralDefaultFormGroup();
  @Input() importFormAdditionalData: {[x: string]: IControlMeta};
  @Input() fields: IColumnMeta;
  @Input() importPreferences: IImportPreferences;
  @Input() onDocumentSave$: EventEmitter<string>;
  @Input() onUpdateUploadUrl$: EventEmitter<string>;
  @Input() savePreferencesAfterSubmit = true;
  @Input() savePreferencesOnDestroy = true;
  @Input() userProhibitColumnLetters = [];
  @Input() uploadedDepositFile: UploadedDocument[] = [];
  @Output() submit = new EventEmitter();
  @Output() documentUploaded = new EventEmitter();
  public importColumnUserPreferences: ImportTable = new ImportTable();
  public ObjectValues = Object.values;
  selectedTab = IMPORT_DEPOSITS_TAB.DOCUMENTS;
  synonyms: ImportTable[] = [];
  columnFieldForm;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event): void {
    if (this.savePreferencesOnDestroy) {
      this.importConfigurationService.updateColumnsList(this.buildColumnOptions()).subscribe();
    }
  }

  @Input() validateForm: () => boolean = () => true;

  constructor(
    public _fb: FormBuilder,
    private _dialog: MdDialog,
    private zone: NgZone,
    public notification: LogNotificationService,
    private _translate: TranslateService,
    public depositImportService: DepositImportService,
    public importConfigurationService: ImportConfigurationService,
    public importProgressService: ImportProgressService
  ) {}

  ngOnInit() {
    if (!this.importPreferences || !this.importPreferences.synonymTable.length) {
      this.IMPORT_DEPOSITS_TAB = omit(this.IMPORT_DEPOSITS_TAB, ['TABLES']);
    }
    this.columnFieldForm = this.importForm.get('columnFields');
    this.loadUserImportPreferences();
  }

  private getImportGeneralDefaultFormGroup() {
    return this._fb.group(IMPORT_DOCUMENT_CONTROLS);
  }


  private loadUserImportPreferences() {
    const {columnMapping, columnTable, synonymTable} = this.importPreferences;
    this.importConfigurationService.getOrCreate([...synonymTable, columnTable]).subscribe((
      preferences: ImportConfiguration) => {
      this.synonyms = synonymTable.map(item => preferences.tableMap[item]);
      this.importColumnUserPreferences = preferences.tableMap[columnTable];
      const formPreferenceJunction = this.getPreferenceControlJunction();
      const codeToForm = (code) => {
        const name = formPreferenceJunction[code];
        const general = this.importForm.get(name);
        return general ? general : this.columnFieldForm.get(name);
      };
      this.updateFormWithPreferences(this.importColumnUserPreferences, codeToForm);
    });
  }

  private getPreferenceControlJunction() {
    if (this.importFormAdditionalData) {
      return Object.keys(this.importFormAdditionalData).reduce(
        (acc, key) => {
          acc[this.importFormAdditionalData[key].type] = key;
          return acc;
        }, {});
    } else {
      return this.importColumnUserPreferences.nbLevels.reduce((acc, item) => {
        acc[item] = item;
        return acc;
      }, {});
    }
  }

  private updateFormWithPreferences(preferences: ImportTable, getControl: (code: number) => FormControl) {
    preferences.nbLevels.forEach((code) => {
      const control = getControl(Number(code));
      if (control) {
        control.setValue(preferences.valuesMap[code].map(({field_value}) => field_value).join(','));
      }
    });
  }

  getTabClasses(tab: string) {
    return {
      ['passport-edit__tabs--' + (tab.toLocaleLowerCase())]: true,
      ['passport-edit__tabs--tab-active']: tab === this.selectedTab,
      ['background-primary-color']: tab === this.selectedTab
    };
  }

  onTabSelected(tab): void {
    this.selectedTab = tab;
  }

  fileAdded(files) {
    this.importForm.get('isFileLoaded').setValue(files ? true : null);
  }

  isImportDisabled() {
    return this.importForm.invalid || !this.importProgressService.canStartImport;
  }

  // Prepare columns for api save ( see updateColumnsList )
  buildColumnOptions() {
    const getType = (item) => this.importFormAdditionalData[item] && this.importFormAdditionalData[item].type;
    const getValue = (item) => this.columnFieldForm.controls[item]
      ? this.columnFieldForm.controls[item].value : this.importForm.value[item];
    return Object.keys({...this.importForm.controls, ...this.columnFieldForm.controls})
      .filter(item => getValue(item) && (getType(item) || getType(item) === 0))
      .reduce((acc, controlName) => {
        acc.push({order: getType(controlName), value: getValue(controlName)});
        return acc;
      }, []);
  }

  ngOnDestroy(): void {
    if (this.savePreferencesOnDestroy) {
      this.importConfigurationService.updateColumnsList(this.buildColumnOptions()).subscribe();
    }
  }

  onClickButton() {
    this.submit.emit();
  }
  
}


