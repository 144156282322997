/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./rating-recyclability-icon.component";
var styles_RatingRecyclabilityCompositionIconComponent = [];
var RenderType_RatingRecyclabilityCompositionIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RatingRecyclabilityCompositionIconComponent, data: {} });
export { RenderType_RatingRecyclabilityCompositionIconComponent as RenderType_RatingRecyclabilityCompositionIconComponent };
function View_RatingRecyclabilityCompositionIconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, ":svg:svg", [["fill", "none"], ["height", "25"], ["viewBox", "0 0 24 25"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, ":svg:g", [["opacity", "0.2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M22.4956 12.9463C22.5238 12.8331 22.5238 12.7199 22.5238 12.5784C22.5238 7.93738 19.5066 4.00383 15.3616 2.61719L17.1098 4.42832L14.9668 5.47538C17.702 6.60733 19.6476 9.29573 19.704 12.4652L18.3787 12.2388L20.3526 16.3705L23.6235 13.1727L22.4956 12.9463Z"], ["fill", "#555C63"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["d", "M20.2463 17.6722L19.2312 15.5781C18.075 18.3231 15.3962 20.2474 12.2663 20.2757L12.4919 18.9457L8.375 20.9266L11.5614 24.2093L11.7587 23.049C11.8997 23.049 12.0125 23.0773 12.1535 23.0773C16.8061 23.0773 20.7256 20.0211 22.1073 15.8328L20.2463 17.6722Z"], ["fill", "#555C63"], ["opacity", "0.9"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M15.516 4.1733L12.3297 0.890625L12.1323 2.07918C12.0195 2.07918 11.9067 2.05088 11.7939 2.05088C7.19767 2.05088 3.30637 5.02227 1.89648 9.18221L3.67295 7.45597L4.71626 9.63499C5.84417 6.89 8.52297 4.96567 11.6529 4.90907L11.4273 6.21082L15.516 4.1733Z"], ["fill", "#555C63"], ["opacity", "0.6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M4.09503 12.6631L5.36393 12.8895L3.39008 8.75781L0.119141 11.9556L1.30345 12.1537C1.30345 12.2952 1.27525 12.4084 1.27525 12.5499C1.27525 17.1909 4.26422 21.1244 8.40929 22.5111L6.63283 20.6717L8.74766 19.6529C6.06887 18.4926 4.15142 15.8042 4.09503 12.6631Z"], ["fill", "#555C63"], ["opacity", "0.7"]], null, null, null, null, null))], null, null); }
function View_RatingRecyclabilityCompositionIconComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, ":svg:svg", [["fill", "none"], ["height", "27"], ["viewBox", "0 0 27 27"], ["width", "27"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M25.0687 13.5056C25.1003 13.3788 25.1003 13.252 25.1003 13.0934C25.1003 7.89385 21.72 3.48688 17.0761 1.93335L19.0347 3.96245L16.6338 5.13553C19.6982 6.40372 21.878 9.41567 21.9412 12.9666L20.4564 12.713L22.6678 17.3419L26.3324 13.7592L25.0687 13.5056Z"], ["fill", "black"], ["fill-opacity", "0.8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M22.549 18.8007L21.4117 16.4546C20.1165 19.53 17.1153 21.6859 13.6086 21.7176L13.8614 20.2275L9.24902 22.4468L12.8189 26.1245L13.04 24.8246C13.198 24.8246 13.3243 24.8563 13.4823 24.8563C18.6949 24.8563 23.0861 21.4322 24.6341 16.7399L22.549 18.8007Z"], ["fill", "black"], ["fill-opacity", "0.8"], ["opacity", "0.9"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["d", "M17.2489 3.67775L13.6791 0L13.4579 1.3316C13.3316 1.3316 13.2052 1.29989 13.0788 1.29989C7.92943 1.29989 3.56981 4.6289 1.99023 9.2895L3.9805 7.3555L5.14938 9.79677C6.41304 6.72141 9.41423 4.56549 12.9209 4.50208L12.6681 5.9605L17.2489 3.67775Z"], ["fill", "black"], ["fill-opacity", "0.8"], ["opacity", "0.6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M4.4544 13.1897L5.87601 13.4433L3.66461 8.81445L0 12.3971L1.32684 12.619C1.32684 12.7775 1.29525 12.9044 1.29525 13.0629C1.29525 18.2625 4.64395 22.6694 9.28789 24.223L7.29763 22.1622L9.66699 21.0208C6.6658 19.7209 4.51758 16.7089 4.4544 13.1897Z"], ["fill", "black"], ["fill-opacity", "0.8"], ["opacity", "0.7"]], null, null, null, null, null))], null, null); }
function View_RatingRecyclabilityCompositionIconComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, ":svg:svg", [["fill", "none"], ["height", "27"], ["viewBox", "0 0 27 27"], ["width", "27"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M25.0687 13.5056C25.1003 13.3788 25.1003 13.252 25.1003 13.0934C25.1003 7.89385 21.72 3.48688 17.0761 1.93335L19.0347 3.96245L16.6338 5.13553C19.6982 6.40372 21.878 9.41567 21.9412 12.9666L20.4564 12.713L22.6678 17.3419L26.3324 13.7592L25.0687 13.5056Z"], ["fill", "#00ACEE"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M22.549 18.8007L21.4117 16.4546C20.1165 19.53 17.1153 21.6859 13.6086 21.7176L13.8614 20.2275L9.24902 22.4468L12.8189 26.1245L13.04 24.8246C13.198 24.8246 13.3243 24.8563 13.4823 24.8563C18.6949 24.8563 23.0861 21.4322 24.6341 16.7399L22.549 18.8007Z"], ["fill", "#8DC53F"], ["opacity", "0.9"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["d", "M17.2489 3.67775L13.6791 0L13.4579 1.3316C13.3316 1.3316 13.2052 1.29989 13.0788 1.29989C7.92943 1.29989 3.56981 4.6289 1.99023 9.2895L3.9805 7.3555L5.14938 9.79677C6.41304 6.72141 9.41423 4.56549 12.9209 4.50208L12.6681 5.9605L17.2489 3.67775Z"], ["fill", "#8DC53F"], ["opacity", "0.6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M4.4544 13.1897L5.87601 13.4433L3.66461 8.81445L0 12.3971L1.32684 12.619C1.32684 12.7775 1.29525 12.9044 1.29525 13.0629C1.29525 18.2625 4.64395 22.6694 9.28789 24.223L7.29763 22.1622L9.66699 21.0208C6.6658 19.7209 4.51758 16.7089 4.4544 13.1897Z"], ["fill", "#00ACEE"], ["opacity", "0.7"]], null, null, null, null, null))], null, null); }
export function View_RatingRecyclabilityCompositionIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RatingRecyclabilityCompositionIconComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RatingRecyclabilityCompositionIconComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RatingRecyclabilityCompositionIconComponent_3)), i0.ɵdid(7, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toxicityState; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.ToxicityState.None; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.ToxicityState.Unverified; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.ToxicityState.Verified; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_RatingRecyclabilityCompositionIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-rating-recyclability-icon", [], null, null, null, View_RatingRecyclabilityCompositionIconComponent_0, RenderType_RatingRecyclabilityCompositionIconComponent)), i0.ɵdid(1, 49152, null, 0, i2.RatingRecyclabilityCompositionIconComponent, [], null, null)], null, null); }
var RatingRecyclabilityCompositionIconComponentNgFactory = i0.ɵccf("app-rating-recyclability-icon", i2.RatingRecyclabilityCompositionIconComponent, View_RatingRecyclabilityCompositionIconComponent_Host_0, { toxicityState: "toxicityState" }, {}, []);
export { RatingRecyclabilityCompositionIconComponentNgFactory as RatingRecyclabilityCompositionIconComponentNgFactory };
