import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 8.02.17.
 */
import { DepositPhotoService } from '../services/deposit-photo.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
var DepositPhoto = /** @class */ (function (_super) {
    tslib_1.__extends(DepositPhoto, _super);
    function DepositPhoto() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = DepositPhotoService;
        return _this;
    }
    DepositPhoto.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'deposit_id',
        ]);
    };
    return DepositPhoto;
}(UploadedDocument));
export { DepositPhoto };
