/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ie-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../read-only/form-elems-hidden.directive";
import * as i4 from "../read-only/read-only.service";
import * as i5 from "./ie-notification.component";
import * as i6 from "../services/navigation.service";
var styles_IeNotificationComponent = [i0.styles];
var RenderType_IeNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IeNotificationComponent, data: { "animation": [{ type: 7, name: "showOrHide", definitions: [{ type: 0, name: "show", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "hide", styles: { type: 6, styles: { display: "none", transform: "translate(0px, -100%)" }, offset: null }, options: undefined }, { type: 1, expr: "show => hide", animation: [{ type: 4, styles: null, timings: "500ms ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_IeNotificationComponent as RenderType_IeNotificationComponent };
export function View_IeNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "notification-bar"]], [[24, "@showOrHide", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "notification-bar-content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "notification-bar-text-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "notification-bar-text-wrapper-headline-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["class", "notification-bar-cta notification-bar-button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeNotificationBar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.FormElemsHiddenDirective, [[2, i4.ReadOnlyService]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "notification-bar-text-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok, continue"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stateName; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("MyUpcyclea is not yet developed to run on Internet Explorer, we recommend you use Chrome.")); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((i1.ɵnov(_v, 9).readOnlyService == null) ? null : i1.ɵnov(_v, 9).readOnlyService.readOnly); _ck(_v, 8, 0, currVal_2); }); }
export function View_IeNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ie-notification", [], null, null, null, View_IeNotificationComponent_0, RenderType_IeNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i5.IeNotificationComponent, [i2.TranslateService, i6.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IeNotificationComponentNgFactory = i1.ɵccf("ie-notification", i5.IeNotificationComponent, View_IeNotificationComponent_Host_0, {}, {}, []);
export { IeNotificationComponentNgFactory as IeNotificationComponentNgFactory };
