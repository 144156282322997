import * as tslib_1 from "tslib";
import { EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IMPORT_DEPOSITS_TAB } from '../../values/import-deposit-tab.const';
import { MdDialog } from '@angular/material';
import { LogNotificationService } from '../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DepositImportService } from '../../../entities/deposit/services/deposit-import.service';
import { ImportConfigurationService } from '../../services/import-configuration.service';
import { ImportTable } from '../../models/import-configuration.class';
import { IMPORT_DOCUMENT_CONTROLS } from '../../values/import-document-controls.const';
import { omit } from 'lodash';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { ImportProgressService } from '../../services/import-progress.service';
var ImportSpreadsheetComponent = /** @class */ (function () {
    function ImportSpreadsheetComponent(_fb, _dialog, zone, notification, _translate, depositImportService, importConfigurationService, importProgressService) {
        this._fb = _fb;
        this._dialog = _dialog;
        this.zone = zone;
        this.notification = notification;
        this._translate = _translate;
        this.depositImportService = depositImportService;
        this.importConfigurationService = importConfigurationService;
        this.importProgressService = importProgressService;
        this.IMPORT_DEPOSITS_TAB = IMPORT_DEPOSITS_TAB;
        this.importForm = this.getImportGeneralDefaultFormGroup();
        this.savePreferencesAfterSubmit = true;
        this.savePreferencesOnDestroy = true;
        this.userProhibitColumnLetters = [];
        this.uploadedDepositFile = [];
        this.submit = new EventEmitter();
        this.documentUploaded = new EventEmitter();
        this.importColumnUserPreferences = new ImportTable();
        this.ObjectValues = Object.values;
        this.selectedTab = IMPORT_DEPOSITS_TAB.DOCUMENTS;
        this.synonyms = [];
        this.validateForm = function () { return true; };
    }
    ImportSpreadsheetComponent.prototype.beforeunloadHandler = function (event) {
        if (this.savePreferencesOnDestroy) {
            this.importConfigurationService.updateColumnsList(this.buildColumnOptions()).subscribe();
        }
    };
    ImportSpreadsheetComponent.prototype.ngOnInit = function () {
        if (!this.importPreferences || !this.importPreferences.synonymTable.length) {
            this.IMPORT_DEPOSITS_TAB = omit(this.IMPORT_DEPOSITS_TAB, ['TABLES']);
        }
        this.columnFieldForm = this.importForm.get('columnFields');
        this.loadUserImportPreferences();
    };
    ImportSpreadsheetComponent.prototype.getImportGeneralDefaultFormGroup = function () {
        return this._fb.group(IMPORT_DOCUMENT_CONTROLS);
    };
    ImportSpreadsheetComponent.prototype.loadUserImportPreferences = function () {
        var _this = this;
        var _a = this.importPreferences, columnMapping = _a.columnMapping, columnTable = _a.columnTable, synonymTable = _a.synonymTable;
        this.importConfigurationService.getOrCreate(synonymTable.concat([columnTable])).subscribe(function (preferences) {
            _this.synonyms = synonymTable.map(function (item) { return preferences.tableMap[item]; });
            _this.importColumnUserPreferences = preferences.tableMap[columnTable];
            var formPreferenceJunction = _this.getPreferenceControlJunction();
            var codeToForm = function (code) {
                var name = formPreferenceJunction[code];
                var general = _this.importForm.get(name);
                return general ? general : _this.columnFieldForm.get(name);
            };
            _this.updateFormWithPreferences(_this.importColumnUserPreferences, codeToForm);
        });
    };
    ImportSpreadsheetComponent.prototype.getPreferenceControlJunction = function () {
        var _this = this;
        if (this.importFormAdditionalData) {
            return Object.keys(this.importFormAdditionalData).reduce(function (acc, key) {
                acc[_this.importFormAdditionalData[key].type] = key;
                return acc;
            }, {});
        }
        else {
            return this.importColumnUserPreferences.nbLevels.reduce(function (acc, item) {
                acc[item] = item;
                return acc;
            }, {});
        }
    };
    ImportSpreadsheetComponent.prototype.updateFormWithPreferences = function (preferences, getControl) {
        preferences.nbLevels.forEach(function (code) {
            var control = getControl(Number(code));
            if (control) {
                control.setValue(preferences.valuesMap[code].map(function (_a) {
                    var field_value = _a.field_value;
                    return field_value;
                }).join(','));
            }
        });
    };
    ImportSpreadsheetComponent.prototype.getTabClasses = function (tab) {
        var _a;
        return _a = {},
            _a['passport-edit__tabs--' + (tab.toLocaleLowerCase())] = true,
            _a['passport-edit__tabs--tab-active'] = tab === this.selectedTab,
            _a['background-primary-color'] = tab === this.selectedTab,
            _a;
    };
    ImportSpreadsheetComponent.prototype.onTabSelected = function (tab) {
        this.selectedTab = tab;
    };
    ImportSpreadsheetComponent.prototype.fileAdded = function (files) {
        this.importForm.get('isFileLoaded').setValue(files ? true : null);
    };
    ImportSpreadsheetComponent.prototype.isImportDisabled = function () {
        return this.importForm.invalid || !this.importProgressService.canStartImport;
    };
    // Prepare columns for api save ( see updateColumnsList )
    ImportSpreadsheetComponent.prototype.buildColumnOptions = function () {
        var _this = this;
        var getType = function (item) { return _this.importFormAdditionalData[item] && _this.importFormAdditionalData[item].type; };
        var getValue = function (item) { return _this.columnFieldForm.controls[item]
            ? _this.columnFieldForm.controls[item].value : _this.importForm.value[item]; };
        return Object.keys(tslib_1.__assign({}, this.importForm.controls, this.columnFieldForm.controls))
            .filter(function (item) { return getValue(item) && (getType(item) || getType(item) === 0); })
            .reduce(function (acc, controlName) {
            acc.push({ order: getType(controlName), value: getValue(controlName) });
            return acc;
        }, []);
    };
    ImportSpreadsheetComponent.prototype.ngOnDestroy = function () {
        if (this.savePreferencesOnDestroy) {
            this.importConfigurationService.updateColumnsList(this.buildColumnOptions()).subscribe();
        }
    };
    ImportSpreadsheetComponent.prototype.onClickButton = function () {
        this.submit.emit();
    };
    return ImportSpreadsheetComponent;
}());
export { ImportSpreadsheetComponent };
