import * as tslib_1 from "tslib";
import { CurrencyService } from '../services/currency.service';
import { ActiveRecord } from './active-record.class';
var Currency = /** @class */ (function (_super) {
    tslib_1.__extends(Currency, _super);
    function Currency() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = CurrencyService;
        return _this;
    }
    Currency.prototype.fields = function () {
        return ['iso', 'symbol', 'id'];
    };
    return Currency;
}(ActiveRecord));
export { Currency };
