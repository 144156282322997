<div class="clear-linked-date"> <label (click)= "clearDate()" >clear</label></div>
<div id="datediv">
  <md-input-container>
  <input
    ng2-datetime-picker
    mdInput
    autocomplete="off" 
    [ngModel]="linked_date"
    (valueChanged)="change($event + '')"
    date-only="true"  
  />
</md-input-container>
</div>