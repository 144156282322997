export const PASSPORT_TAB = {
  GENERAL: 'general',
  COMPOSITION: 'composition',
  CYCLE: 'cycle',
  PERFORMANCE: 'performance',
  INFO: 'info',
};

export const PASSPORT_TAB_TYPE = {
  GENERAL: 1,
  COMPOSITION: 2,
  CYCLE: 3,
  PERFORMANCE: 4,
  INFO: 5
};

export const PASSPORT_TAB_TYPE_NUMERIC = {
  1: 'general',
  2: 'composition',
  3: 'cycle',
  4: 'performance',
  5: 'info'
};


