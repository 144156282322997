<div class="new-passport-publication__wrapper">

    <div class="new-passport-publication__header">
        <h2>{{ 'New publication' | translate }} </h2>
        <div class="name-generation-dialog__close" (click)="close(false)">
          <i class="icon icon--close_s"></i>
      </div>
    </div>

    <div class="new-passport-publication__body flex justify-between">
        <div class="new-passport-publication__label flex">
            <label class="form-group__label" >
                <i class="form-group__label--question">!</i>
            </label>
        </div>
        <div class="new-passport-publication__text">
            <p> {{ 'NEW_PUBLICATION_TEXT_INFO1' | translate }} </p>
        </div>
    </div>

    <div class="new-passport-publication__footer">
        <div class="new-passport-publication__footer-button-group flex">
          <div class="new-passport-publication__footer-button-group-button">
            <button md-button md-raised-button (click)="close('new_version')">
              {{ 'Create a new version' | translate }}
            </button>
          </div>
          <div class="new-passport-publication__footer-button-group-button">
            <button md-button md-raised-button (click)="close('edit')">
              {{ 'Ok, edit the passport' | translate }}
            </button>
          </div>
        </div>
    </div>

</div>