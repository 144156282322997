import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {PassportService} from '../../services/passport.service';
import {Router} from '@angular/router';
import {Passport} from '../../models/passport.class';

@Component({
  moduleId: module.id,
  templateUrl: 'passport-modification-dialog.component.html'
})
export class PassportModificationDialogComponent {

  public passport: Passport;

  busy: boolean = false;

  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<any>,
              protected _router: Router,
              private passportService: PassportService) {
    this.passport = this.mdDialogData.passport;
  }

  send() {
    if (this.busy) {
      // Don't spam requests if one is already in process
      return;
    }

    this.busy = true;
    this.passportService
      .askForModification(this.passport.id, this.passport)
      .subscribe(() => {
        this.mdDialogRef.close(true);
        this._router.navigate(['special_admin/passports']);
      },
        () => {
          this.busy = false;
        });
  }
}
