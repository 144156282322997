import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { AfterViewInit, ChangeDetectorRef, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Deposit } from '../../models/deposit.class';
import { MdDialog } from '@angular/material';
import { DepositControlStates } from '../../models/deposit-control-states.class';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { TranslateService } from '@ngx-translate/core';
import { PASSPORT_STATUS } from '../../../../entities/passport/values/passport-status.const';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { Utils } from '../../../../shared/utils/utils.class';
import { FileUploader } from 'ng2-file-upload';
import { DepositService } from '../../services/deposit.service';
import { combineLatest } from 'rxjs';
import { PassportValidationNotificationService } from '../../../../entities/passport/services/passport-validation-notification.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { DEPOSIT_STATUSES } from '../../../../entities/deposit/values/deposit-statuses.const';
var DepositEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DepositEditComponent, _super);
    function DepositEditComponent(route, router, dialog, _notificationService, _translate, zone, cd, navigationService, _depositService, _passportValidationNotification, notification, loadingSpinner) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this.dialog = dialog;
        _this._notificationService = _notificationService;
        _this._translate = _translate;
        _this.zone = zone;
        _this.cd = cd;
        _this.navigationService = navigationService;
        _this._depositService = _depositService;
        _this._passportValidationNotification = _passportValidationNotification;
        _this.notification = notification;
        _this.loadingSpinner = loadingSpinner;
        _this.controlStates = new DepositControlStates;
        _this.eventStream$ = new EventEmitter();
        _this.onPassportSaved = new EventEmitter();
        _this.stepId = 0;
        _this.invalidFields = [];
        _this.navigateAway = false;
        _this.spinnerEnabled = false;
        _this.maxLogoSize = 5 * 1024 * 1024;
        _this.uploader = new FileUploader({ allowedMimeType: ['image/jpeg', 'image/png'],
            maxFileSize: _this.maxLogoSize });
        _this.onLogoSave = new EventEmitter();
        _this.fileUploaderStream$ = new EventEmitter();
        _this.tempLogo = null;
        return _this;
    }
    DepositEditComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.uploaderSpecifyUrl();
        this.uploader.onCompleteItem = function (_, response) {
            try {
                var data = JSON.parse(response);
                if (data.logo && data.thumbnail) {
                    _this.fileUploaderStream$.next(data);
                }
                else {
                    throw new Error('Thumbnail and Logo should be specify in response data');
                }
            }
            catch (e) {
                _this.fileUploaderStream$.error(e);
            }
            _this.tempLogo = null;
        };
        this.uploader.onErrorItem =
            Utils.onUploadError(function (event) {
                _this.fileUploaderStream$.error(event);
                _this.notification.error(event, null, true);
            });
        this.uploader.onWhenAddingFileFailed = function (_, filter) {
            console.log(filter);
            if (filter && filter.name === 'mimeType') {
                _this.notification.error('Formats accepted: ' + _this.uploader.options.allowedMimeType
                    .map(function (type) { return type.replace(/.+\//, '.'); })
                    .join(', '), null, true);
            }
            else if (filter && filter.name === 'fileSize') {
                _this.notification.error('Filesize limit is 5MB', null, true);
            }
        };
        this.cd.detectChanges();
    };
    DepositEditComponent.prototype.onExistEntity = function (entity) {
        this.deposit = entity;
        this.deposit.loaded = true;
        // active step?
        this.stepId = this.deposit.getProgress();
    };
    DepositEditComponent.prototype.onNewEntity = function () {
        this.deposit = new Deposit();
        this.deposit.loaded = true;
    };
    DepositEditComponent.prototype.changeDeposit = function (deposit) {
        this.deposit.set(deposit);
        deposit.loaded = true;
        this.eventStream$.emit({
            key: 'changeDeposit',
            data: this.deposit
        });
    };
    DepositEditComponent.prototype.isButtonDisabled = function () {
        return this.deposit.getProgress() < 3 || this.deposit.status == DEPOSIT_STATUSES.REJECTED.value;
    };
    DepositEditComponent.prototype.isPublished = function () {
        return this.deposit.status === DEPOSIT_STATUSES.PUBLISHED.value;
    };
    DepositEditComponent.prototype.savePassportIsNeeded = function () {
        return !!this.deposit.passport.id
            && !(this.deposit.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor());
    };
    DepositEditComponent.prototype.saveDraft = function (index) {
        var _this = this;
        if (index === void 0) { index = null; }
        if (index === null) {
            this.spinnerEnabled = true;
        }
        if (this._passportValidationNotification.showChildNotificationErrors())
            return;
        if (!this.deposit.status) {
            this.deposit.status = 1;
        }
        if (!this.isUploaderQueueEmpty()) {
            this.tempLogo = this.deposit.logo;
            this.deposit.logo = null;
            this.deposit.thumbnail = null;
        }
        this.zone.run(function () {
            var saveObserver = _this.deposit.save().map(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    if (!this.deposit.id) {
                        this.uploaderSpecifyUrl(res.id);
                    }
                    this.uploader.uploadAll();
                    this.deposit.id = (res.id);
                    return [2 /*return*/];
                });
            }); });
            _this.uploadLogoChanges(saveObserver, function () {
                if (index !== null) {
                    _this.stepId = index;
                    return;
                }
                _this.navigateAfterPassportResourceSave();
            });
        });
    };
    DepositEditComponent.prototype.navigateAfterPassportResourceSave = function () {
        this.navigateAway = true;
        if (!this.savePassportIsNeeded()) {
            this.onSaveCompleteHandler();
        }
    };
    DepositEditComponent.prototype.uploadLogoChanges = function (saveObserver, afterUpload, onError, onComplete) {
        var _this = this;
        if (onError === void 0) { onError = function () { }; }
        if (onComplete === void 0) { onComplete = function () { }; }
        if (!this.isUploaderQueueEmpty()) {
            var logoObserver = this.fileUploaderStream$.map(function (res) { return _this.onSaveCompleteLogo(res); });
            combineLatest(saveObserver, logoObserver).subscribe(function () {
                afterUpload();
                _this.uploader.clearQueue();
            }, function () { _this.uploader.clearQueue(); onError(); }, function () { return onComplete(); });
        }
        else {
            if (this.deposit.id && !this.deposit.logo) {
                var deleteLogoObserver = this._depositService.removeLogo(this.deposit.id);
                combineLatest(saveObserver, deleteLogoObserver).subscribe(function () {
                    afterUpload();
                }, function () { return onError(); }, function () { return onComplete(); });
            }
            else {
                combineLatest(saveObserver).subscribe(function () { return afterUpload(); }, function () { return onError(); }, function () { return onComplete(); });
            }
        }
    };
    DepositEditComponent.prototype.onSaveCompleteLogo = function (data) {
        this.deposit.set({ logo: data.logo });
        this.deposit.set({ thumbnail: data.thumbnail });
    };
    DepositEditComponent.prototype.onSaveCompleteHandler = function ($event) {
        var _this = this;
        this.destroySpinnerIfShown();
        if (this.navigateAway) {
            this.zone.run(function () { return _this.router.navigate(['agent/deposits/']); });
        }
    };
    DepositEditComponent.prototype.destroySpinnerIfShown = function () {
        if (this.loadingSpinner) {
            this.loadingSpinner.destroy();
        }
    };
    DepositEditComponent.prototype.onPassportSaveFailedHandler = function ($event) {
        this.destroySpinnerIfShown();
    };
    DepositEditComponent.prototype.isNextDisabled = function () {
        return this.deposit.getProgress() <= this.stepId;
    };
    DepositEditComponent.prototype.isPrevDisabled = function () {
        return this.stepId <= 0;
    };
    DepositEditComponent.prototype.onTabChanged = function (index) {
        var errors = ['Name and Passport must be filled', 'Site name must be filled'];
        if (index > this.deposit.getProgress()) {
            this._notificationService.error(errors[index - 1], null, true);
            return;
        }
        if (this.stepId !== index) {
            this.saveDraft(index);
        }
    };
    DepositEditComponent.prototype.submit = function () {
        var _this = this;
        if (this._passportValidationNotification.showChildNotificationErrors())
            return;
        this.loadingSpinner.show('deposit');
        this.uploader.uploadAll();
        var publishObserver = this.isPublished() ? this.deposit.save() : this.deposit.publish();
        this.uploadLogoChanges(publishObserver, function () { return _this.navigateAfterPassportResourceSave(); }, function () { return _this.loadingSpinner.destroy(); });
    };
    DepositEditComponent.prototype.uploaderSpecifyUrl = function (id) {
        if (id === void 0) { id = this.deposit.id; }
        if (!id) {
            return;
        }
        this.uploader.setOptions({
            url: this._depositService.getLogoUploadUrl(id || this.deposit.id),
            itemAlias: 'file'
        });
    };
    DepositEditComponent.prototype.saveTempLogo = function () {
        var _this = this;
        if (!this.isUploaderQueueEmpty()) {
            var logo = this.uploader.queue[this.uploader.queue.length - 1]._file;
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                _this.deposit.logo = reader_1.result;
            };
            reader_1.onerror = function () {
                _this.notification.error(reader_1.error.message, null, true);
            };
            if (logo) {
                reader_1.readAsDataURL(logo);
            }
            ;
        }
    };
    DepositEditComponent.prototype.removeLogo = function () {
        this.uploader.clearQueue();
        this.deposit.logo = null;
        this.deposit.thumbnail = null;
    };
    DepositEditComponent.prototype.isUploaderQueueEmpty = function () {
        return this.uploader.queue.length === 0;
    };
    return DepositEditComponent;
}(EntityViewerComponent));
export { DepositEditComponent };
