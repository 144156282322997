/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./ecosystem-section-counters.component";
var styles_EcosystemSectionCountersComponent = [];
var RenderType_EcosystemSectionCountersComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EcosystemSectionCountersComponent, data: {} });
export { RenderType_EcosystemSectionCountersComponent as RenderType_EcosystemSectionCountersComponent };
function View_EcosystemSectionCountersComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_EcosystemSectionCountersComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [["class", "ecosystem-dashboard__counter-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "ecosystem-dashboard__counter-value primary-color"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcosystemSectionCountersComponent_2)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "ecosystem-dashboard__counter-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.template; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.counter; _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.$implicit.text; _ck(_v, 6, 0, currVal_2); }); }
export function View_EcosystemSectionCountersComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "ecosystem-dashboard__header ecosystem-dashboard__section\n  ecosystem-dashboard__section--two-column ecosystem-catalog"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "section", [["class", "ecosystem-dashboard__section--counters"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "ul", [["class", "ecosystem-dashboard__counter-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcosystemSectionCountersComponent_1)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_EcosystemSectionCountersComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ecosystem-section-counters", [], null, null, null, View_EcosystemSectionCountersComponent_0, RenderType_EcosystemSectionCountersComponent)), i0.ɵdid(1, 49152, null, 0, i2.EcosystemSectionCountersComponent, [], null, null)], null, null); }
var EcosystemSectionCountersComponentNgFactory = i0.ɵccf("ecosystem-section-counters", i2.EcosystemSectionCountersComponent, View_EcosystemSectionCountersComponent_Host_0, { data: "data" }, {}, []);
export { EcosystemSectionCountersComponentNgFactory as EcosystemSectionCountersComponentNgFactory };
