/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../ecosystem-dashboard/ecosystem-navigator/ecosystem-folder/ecosystem-folder.component.ngfactory";
import * as i2 from "../../../ecosystem-dashboard/ecosystem-navigator/ecosystem-folder/ecosystem-folder.component";
import * as i3 from "../../../../../../entities/ecosystem/services/ecosystem-folder.service";
import * as i4 from "../../../ecosystem-dashboard/ecosystem-navigator/ecosystem-card/ecosystem-card.component.ngfactory";
import * as i5 from "../../../ecosystem-dashboard/ecosystem-navigator/ecosystem-card/ecosystem-card.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../../../../shared/services/navbar.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i10 from "@angular/material";
import * as i11 from "./ecosystem-folder-navigator.component";
var styles_EcosystemFolderNavigatorComponent = [];
var RenderType_EcosystemFolderNavigatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EcosystemFolderNavigatorComponent, data: {} });
export { RenderType_EcosystemFolderNavigatorComponent as RenderType_EcosystemFolderNavigatorComponent };
function View_EcosystemFolderNavigatorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "ecosystem-navigator__grid-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "ecosystem-folder", [], null, null, null, i1.View_EcosystemFolderComponent_0, i1.RenderType_EcosystemFolderComponent)), i0.ɵdid(2, 49152, null, 0, i2.EcosystemFolderComponent, [i3.EcosystemFolderService], { folder: [0, "folder"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EcosystemFolderNavigatorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "ecosystem-navigator__grid-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "ecosystem-card", [], null, null, null, i4.View_EcosystemCardComponent_0, i4.RenderType_EcosystemCardComponent)), i0.ɵdid(2, 114688, null, 0, i5.EcosystemCardComponent, [i6.Router, i7.NavbarStateService, i3.EcosystemFolderService], { ecosystem: [0, "ecosystem"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EcosystemFolderNavigatorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "ul", [["class", "ecosystem-navigator__grid-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcosystemFolderNavigatorComponent_2)), i0.ɵdid(2, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcosystemFolderNavigatorComponent_3)), i0.ɵdid(4, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ecoFolderService.folders; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.ecoFolderService.ecosystems; _ck(_v, 4, 0, currVal_1); }, null); }
function View_EcosystemFolderNavigatorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ul", [["class", "ecosystem-navigator__grid-loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "md-progress-spinner", [["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0], [2, "mat-progress-spinner", null]], null, null, i9.View_MdProgressSpinner_0, i9.RenderType_MdProgressSpinner)), i0.ɵdid(2, 180224, null, 0, i10.MdProgressSpinner, [i0.NgZone, i0.ElementRef, i0.Renderer], { mode: [0, "mode"] }, null), i0.ɵdid(3, 16384, null, 0, i10.MdProgressSpinnerCssMatStyler, [], null, null)], function (_ck, _v) { var currVal_5 = "indeterminate"; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2)._ariaValueMin; var currVal_1 = i0.ɵnov(_v, 2)._ariaValueMax; var currVal_2 = i0.ɵnov(_v, 2).value; var currVal_3 = i0.ɵnov(_v, 2).mode; var currVal_4 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_EcosystemFolderNavigatorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "ecosystem-navigator__grid"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcosystemFolderNavigatorComponent_1)), i0.ɵdid(2, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcosystemFolderNavigatorComponent_4)), i0.ɵdid(4, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.ecoFolderService.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.ecoFolderService.isLoading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EcosystemFolderNavigatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ecosystem-folder-navigator", [], null, null, null, View_EcosystemFolderNavigatorComponent_0, RenderType_EcosystemFolderNavigatorComponent)), i0.ɵdid(1, 114688, null, 0, i11.EcosystemFolderNavigatorComponent, [i3.EcosystemFolderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EcosystemFolderNavigatorComponentNgFactory = i0.ɵccf("ecosystem-folder-navigator", i11.EcosystemFolderNavigatorComponent, View_EcosystemFolderNavigatorComponent_Host_0, { folderMode: "folderMode" }, {}, []);
export { EcosystemFolderNavigatorComponentNgFactory as EcosystemFolderNavigatorComponentNgFactory };
