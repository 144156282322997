import * as tslib_1 from "tslib";
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { DepositLocationFileService } from '../services/deposit-location-file.service';
var DepositLocationFile = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationFile, _super);
    function DepositLocationFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = DepositLocationFileService;
        return _this;
    }
    DepositLocationFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'deposit_location_id'
        ]);
    };
    return DepositLocationFile;
}(UploadedDocument));
export { DepositLocationFile };
