import { NgModule } from '@angular/core';
import { PlaceAutocompleteComponent } from './place-autocomplete.component';
import { PlaceAutocompleteDirective } from './place-autocomplete.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PlaceAutocompleteComponent,
    PlaceAutocompleteDirective,
  ],
  exports: [
    PlaceAutocompleteComponent,
    PlaceAutocompleteDirective,
  ],
})
export class PlaceAutocompleteModule {
}
