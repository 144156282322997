<section class="ecosystem-compiler" *ngIf="virginProduct?.loaded && orderPassport?.loaded">
  <ul class="ecosystem-compiler__tab-list">
    <li class="ecosystem-compiler__tab-item">
      <header class="ecosystem-compiler__tab-header">
        <div class="ecosystem-compiler__tab-caption--virgin">
          <div class="ecosystem-compiler__tab-title--virgin">
            <i class="icon icon--order-in"></i>
            <h3 class="ecosystem-compiler__tab-title">{{virginProduct.passport.name}}</h3>
          </div>
          <div class="ecosystem-compiler__tab-badge ecosystem-compiler__tab-badge--pink">{{ 'Virgin product' | translate
            }}
          </div>
        </div>
        <div class="ecosystem-compiler__expander" (click)="changeTab(0)">
          <button class="ecosystem-compiler__fold-tab button-transparent">
            <i class="icon" [ngClass]="isOpen(0) ? 'icon--unfold-tab' : 'icon--fold-tab' "></i>
          </button>
        </div>
      </header>
      <section class="ecosystem-compiler__tab-section" *ngIf="isOpen(0)">
        <div class="ecosystem-compiler__transformation-description">
          <md-input-container
            class="passportlist__search-materials"
            floatPlaceholder="always"
          >
            <md-chip-list>
              <md-basic-chip *ngFor="let material of virginProduct?.passport?.nomenclature" (focus)="false">
                {{material.name}}
              </md-basic-chip>
            </md-chip-list>

            <input
              readonly
              mdInput
              autocomplete="off"
              placeholder="{{ 'Materials' | translate }}"
            >
          </md-input-container>

          <!--<md-input-container>-->
          <!--<input-->
          <!--mdInput-->
          <!--placeholder="TODO Materials"-->
          <!--&gt;-->
          <!--</md-input-container>-->
        </div>
        <div class="ecosystem-compiler__transformation-description">
          <md-input-container>
            <input
              mdInput
              readonly
              autocomplete="off"
              [(ngModel)]="virginProduct.passport.description"
              placeholder="{{ 'Description' | translate }}"
            >
          </md-input-container>
        </div>
        <!--<div class="ecosystem-compiler__transformation-description">-->
          <!--<md-input-container>-->
            <!--<input-->
              <!--mdInput-->
              <!--readonly-->
              <!--[(ngModel)]="virginProduct.passport.next_use"-->
              <!--placeholder="{{ 'Next usage' | translate }}"-->
            <!--&gt;-->
          <!--</md-input-container>-->
        <!--</div>-->
        <h4 class="ecosystem-compiler__documents-order-caption">{{ 'Next usages' | translate }}</h4>
        <br>
        <div class="ecosystem-compiler__transformation-next-usages"
             *ngFor="let usage of virginProduct.passport.next_usages">
          <md-input-container class="name">
            <input mdInput placeholder="{{ 'Stakeholder' | translate }}" autocomplete="off" readonly [value]="usage.stakeholder">
          </md-input-container>
          <md-input-container class="quantity">
            <input mdInput placeholder="{{ 'Next usage type' | translate }}" autocomplete="off" readonly [value]="usage.getTypeText()">
          </md-input-container>
          <md-input-container class="demountability">
            <input mdInput placeholder="{{ 'Description' | translate }}" autocomplete="off" readonly [value]="usage.description">
          </md-input-container>
        </div>
        <div class="ecosystem-compiler__transformation-description">
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              readonly
              [(ngModel)]="virginProduct.passport.recommendation"
              placeholder="{{ 'Recommendation of employment' | translate }}"
            >
          </md-input-container>
        </div>
        <div class="ecosystem-compiler__quantity-block--virgin">
          <md-input-container class="ecosystem-compiler__virgin-quantity">
            <input
              mdInput
              autocomplete="off"
              placeholder="{{ 'Quantity' | translate }}"
              [readonly]="!step.canEditOrder"
              [(ngModel)]="orderPassport.quantity"
              (change)="saveStep()"
            >
          </md-input-container>
          <span mdSuffix>{{virginProduct.passport.unit_text | translate}}</span>
        </div>

        <div class="ecosystem-compiler__price-block--virgin">
          <md-input-container class="ecosystem-compiler__virgin-price">
            <input
              mdInput
              autocomplete="off"
              placeholder="{{ 'Price' | translate }}"
              [readonly]="!step.canEditOrder"
              [(ngModel)]="step.virgin_product_price"
              (change)="saveStep()"
            >
          </md-input-container>
          <span mdSuffix>€</span>
        </div>
        <div class="ecosystem-compiler__certification-block">
          <div class="ecosystem-compiler__certification-title">{{ 'Certification' | translate }}</div>
          <div class="ecosystem-compiler__certification">
            <md-checkbox [ngModel]="virginProduct.passport.c2c_certified" disabled>
              {{ 'C2C certification' | translate }}
            </md-checkbox>
          </div>
        </div>
        <div class="ecosystem-compiler__transformation-description--places">
          <i class="icon icon--places"></i>
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              placeholder="{{ 'Address of delivery' | translate }}"
              [(ngModel)]="orderPassport.address"
              (change)="saveStep()"
            >
          </md-input-container>
        </div>
        <div class="ecosystem-compiler__transformation-description--virgin">
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              placeholder="{{ 'Description' | translate }}"
              [(ngModel)]="orderPassport.description"
              (change)="saveStep()"
            >
          </md-input-container>
        </div>
        <div class="ecosystem-compiler__need-delivery">
          <i class="icon icon--calendar"></i>
          <md-input-container>
            <input
              mdInput
              autocomplete="off"
              ng2-datetime-picker
              date-format="DD-MM-YYYY"
              parse-format="YYYY-MM-DD"
              date-only="true"
              placeholder="{{ 'Date of delivery' | translate }}"
              [(ngModel)]="orderPassport.linked_datetime"
              (change)="saveStep()"
            >
          </md-input-container>
        </div>
        <div class="ecosystem-compiler__need-documents">
          <documents-uploader [files]="[]"></documents-uploader>
        </div>
        <div class="ecosystem-compiler__contacts">{{ 'Contacts' | translate }}</div>
        <div class="ecosystem-compiler__row--contacts--virgin">
          <div class="ecosystem-compiler__column">
            <i class="icon icon--people-grey"></i> {{virginProduct ? virginProduct.contact_name: ''}}
          </div>
          <div class="ecosystem-compiler__column">
            <i class="icon icon--phone"></i> {{virginProduct ? virginProduct.contact_phone: ''}}
          </div>
          <div class="ecosystem-compiler__column">
            <button class="ecosystem-compiler__confirm-button" (click)="sendEmail()">{{ 'Send Email' | translate }}
            </button>
          </div>
        </div>
      </section>
    </li>
  </ul>
</section>
