import { Component } from '@angular/core';
import { MdDialogRef } from '@angular/material';

@Component({
    moduleId: module.id,
    selector: 'new-passport-publication-dialog',
    styleUrls: ['new-passport-publication.component.scss'],
    templateUrl: 'new-passport-publication.component.html',
    host: {'class': 'new-passport-publication-dialog'}
})
export class NewPassportPublicationComponent {



    constructor(
        private _dialog: MdDialogRef<any>
    ) {

    }

    close(result: any) {
        return this._dialog.close(result)
    }


}