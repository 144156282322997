import { EventEmitter, OnInit } from '@angular/core';
import { EDepositFilterStatusChangeType } from './deposit-filter-status-change-type.const';
import { isEqual } from 'lodash';
var DepositFilterStatusComponent = /** @class */ (function () {
    function DepositFilterStatusComponent() {
        this.justifyContent = true;
        this.searchStatus = [];
        this.searchStatusChange = new EventEmitter();
        this.isOutOfStockSelectedChange = new EventEmitter();
        this.isSelectEmpty = this.isDefaultStatus.bind(this);
        this.defaultStatusValue = { searchStatus: [], isOutOfStockSelected: false, isAllSelected: false };
        this.isAllSelectedChange = new EventEmitter();
        this.change = new EventEmitter();
        this.isOutOfStockNotSelected = false;
        this.isFilterStateOfUseVisible = false;
        this.isFilterStatusVisible = false;
    }
    Object.defineProperty(DepositFilterStatusComponent.prototype, "setFilterStatuses", {
        set: function (data) {
            var _this = this;
            this.filterStatuses = data.map(function (item) { return _this.createSelectOption(item); }).concat([
                this.createOptionSelect()
            ]);
        },
        enumerable: true,
        configurable: true
    });
    DepositFilterStatusComponent.prototype.ngOnInit = function () {
        this.checkAllStatusesSelected();
    };
    DepositFilterStatusComponent.prototype.createSelectOption = function (item) {
        return {
            text: item.text,
            view: item.view,
            linkToSelectedFunc: this.isOptionSelected.bind(this),
            value: { type: EDepositFilterStatusChangeType.OPTION, value: item.value },
            get selected() {
                return this.linkToSelectedFunc(this.value.value);
            }
        };
    };
    DepositFilterStatusComponent.prototype.createOutOfStock = function () {
        return {
            linkToSelectedFunc: this.isOutOptionSelected.bind(this),
            value: { type: EDepositFilterStatusChangeType.OUT_OF_STOCK },
            get selected() {
                return this.linkToSelectedFunc();
            },
            text: 'OUT_OF_STOCK',
            view: 'Out of stock'
        };
    };
    DepositFilterStatusComponent.prototype.createOptionSelect = function () {
        return {
            linkToSelectedFunc: this.isAllOptionSelected.bind(this),
            value: { type: EDepositFilterStatusChangeType.ALL_SELECTED },
            get selected() {
                return this.linkToSelectedFunc();
            },
            text: 'SELECT_ALL',
            view: 'Select all'
        };
    };
    DepositFilterStatusComponent.prototype.onSelectChange = function (optionValue) {
        switch (optionValue.type) {
            case EDepositFilterStatusChangeType.OPTION:
                this.addState(optionValue.value);
                break;
            case EDepositFilterStatusChangeType.OUT_OF_STOCK:
                this.toggleOutOfStock();
                break;
            case EDepositFilterStatusChangeType.ALL_SELECTED:
                this.toggleAllSelected();
                break;
        }
        this.change.emit();
    };
    DepositFilterStatusComponent.prototype.addState = function (state) {
        var index = this.searchStatus.indexOf(state);
        if (index > -1) {
            this.searchStatus.splice(index, 1);
        }
        else {
            this.searchStatus.push(state);
            this.searchStatus.sort(function (previous, next) { return previous - next; });
        }
        this.checkAllStatusesSelected();
        this.searchStatusChange.emit(this.searchStatus);
        this.isAllSelectedChange.emit(this.isAllSelected);
    };
    DepositFilterStatusComponent.prototype.toggleAllSelected = function () {
        this.isAllSelected = !this.isAllSelected;
        if (this.isAllSelected) {
            this.selectAllCheckboxes();
        }
        else {
            this.searchStatus = [];
            this.isOutOfStockSelected = false;
        }
        this.isOutOfStockSelectedChange.emit(this.isOutOfStockSelected);
        this.searchStatusChange.emit(this.searchStatus);
        this.isAllSelectedChange.emit(this.isAllSelected);
    };
    DepositFilterStatusComponent.prototype.toggleOutOfStock = function () {
        this.isOutOfStockSelected = !this.isOutOfStockSelected;
        this.checkAllStatusesSelected();
        this.isOutOfStockSelectedChange.emit(this.isOutOfStockSelected);
        this.isAllSelectedChange.emit(this.isAllSelected);
    };
    DepositFilterStatusComponent.prototype.selectAllCheckboxes = function () {
        this.searchStatus = this.filterStatuses
            .filter(function (status) { return status.value.type === EDepositFilterStatusChangeType.OPTION; })
            .map(function (status) { return status.value.value; });
        this.isOutOfStockSelected = true;
    };
    DepositFilterStatusComponent.prototype.isOptionSelected = function (value) {
        return (this.searchStatus || []).find(function (option) { return option === value; });
    };
    DepositFilterStatusComponent.prototype.isOutOptionSelected = function () {
        return !!this.isOutOfStockSelected;
    };
    DepositFilterStatusComponent.prototype.isAllOptionSelected = function () {
        return !!this.isAllSelected;
    };
    DepositFilterStatusComponent.prototype.checkAllStatusesSelected = function () {
        this.isAllSelected = this.isOutOfStockSelected
            && this.filterStatuses.every(function (status) {
                return status.selected;
            });
    };
    DepositFilterStatusComponent.prototype.isDefaultStatus = function () {
        // tslint:disable-next-line:triple-equals
        return this.isAllSelected == this.defaultStatusValue.isAllSelected
            // tslint:disable-next-line:triple-equals
            && this.isOutOfStockSelected == this.defaultStatusValue.isOutOfStockSelected
            && isEqual(this.searchStatus, this.defaultStatusValue.searchStatus || []);
    };
    return DepositFilterStatusComponent;
}());
export { DepositFilterStatusComponent };
