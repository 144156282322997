import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs';

import { DeepStreamService } from '../../../../../../shared/services/deepstream.service';
import { ReportProgressService } from '../report-progress.service';
import { ReportService } from '../../../../../../dashboards/admin/services/report.service';


@Component({
  moduleId: module.id,
  selector: 'report-progress-wrapper',
  styleUrls: ['../report-progress.component.scss'],
  templateUrl: 'report-progress-wrapper.component.html'
})

export class ReportProgressWrapperComponent implements OnInit, OnDestroy {

  private spinnerPercentage = new BehaviorSubject<number>(0);
  private spinnerTotal = new BehaviorSubject<number>(0);

  private errorStreamSubscription: Subscription;
  private progressStreamSubscription: Subscription;
  private successStreamSubscription: Subscription;

 
  private readSubscription;
  
  private typeCheckSubscription: Subscription;
  private errorCheckSubscription: Subscription;

  constructor(
    private ds: DeepStreamService,
    private reportService: ReportProgressService,
    private reportApiService: ReportService,
    private zone: NgZone,

  ) {

  }

  ngOnInit(): void {
    this.subscribe();
    this.reportApiService.getActiveReport().subscribe((res) => {
      if (!res) {
        return;
      }
      switch(Number(res.status)) {
        case 1:
          this.zone.run(() => this.reportService.setProgressModeProgressModal());
          break;
        case 2:
          this.zone.run(() => this.reportService.setSuccessModeProgressModal(res.zipfile_link));
          break;
      }
    })
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    const stream$ = this.ds.reportPdfDeepStream$;
    this.errorStreamSubscription = stream$.reportPdfErrorsStream$
      .subscribe((e: any) => {
        this.reportService.setErrorModeProgressModal();
      });

    this.progressStreamSubscription = stream$
      .reportPdfProgressStream$
      .subscribe((e: any) => {
        this.zone.run(() => this.reportService.setProgressModeProgressModal());
      });

    this.successStreamSubscription = stream$
      .reportPdfSuccessStream$
      .subscribe((e: any) => {
        this.zone.run(() => this.reportService.setSuccessModeProgressModal(e.zipfile_link));
        
      });

  }

  unsubscribe() {
    if (this.errorStreamSubscription) {
      this.errorStreamSubscription.unsubscribe();
    }
    if (this.progressStreamSubscription) {
      this.progressStreamSubscription.unsubscribe();
    }
    if (this.successStreamSubscription) {
      this.successStreamSubscription.unsubscribe();
    }
  }

 
}
