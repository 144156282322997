import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MdDialogRef} from '@angular/material';

@Component({
  selector: 'app-notification-success',
  templateUrl: './notification-success.component.html',
  styleUrls: ['./notification-success.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationSuccessComponent implements OnInit {

  @Input() message = 'Your problem has been reported on to the ecosystem manager';
  @Input() closeButtonText = 'OK';

  constructor(public mdDialogRef: MdDialogRef<any>) { }

  ngOnInit() {
    Object.assign(this, this.mdDialogRef._containerInstance.dialogConfig.data);
  }

  getEndpoint(src: string) {
    return window.location.origin + '/' + src;
  }
}
