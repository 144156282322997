import {CrudService} from '../../../shared/services/crud.service';
import {Injectable} from '@angular/core';
import {DepositTagLocationJunction} from '../models/deposit-tag-location-junction.class';

@Injectable()
export class DepositTagLocationJunctionService extends CrudService {
  protected namespace = 'site-tag-deposit-junctions';
  protected namespaceSingular = 'site-tag-deposit-junctions';
  protected ModelClass = DepositTagLocationJunction;

}
