import { Component, OnInit } from '@angular/core';
import { TransformService } from '../../../../entities/transformation/services/transform.service';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { FormControl, FormGroup } from '@angular/forms';
import { ORDER_STATUSES } from '../../values/order-statuses.const';
import { SORT_MODES } from '../../../../shared/values/sort-modes.const';

@Component({
  moduleId: module.id,
  templateUrl: 'order-list.component.html',
  styleUrls: ['order-list.component.scss'],
})
export class OrderListComponent implements OnInit {
  public ORDER_STATUSES = ORDER_STATUSES;
  public SORT_MODES = [
    SORT_MODES.NAME,
    SORT_MODES.DATE_OF_CREATION,
  ];
  public records: Transform[];
  public results: { [key: string]: Transform[] } = {};

  public form = new FormGroup({
    arrivalDateFrom: new FormControl(),
    arrivalDateTo: new FormControl(),
  });

  constructor(private transformService: TransformService) {
  }

  ngOnInit() {
    this.transformService.get<Transform>({}).subscribe((res) => {
      this.records = res;
      this.form.valueChanges.subscribe(() => {
        this.update();
      });
      this.update();
    });
  }

  update() {
    this.results = this.getResults(this.records);
  }

  getResults(records: Transform[]) {
    let results: any = {};

    for (let STATUS of ORDER_STATUSES) {
      results[STATUS.text] = records;
    }

    return results;
  }
}
