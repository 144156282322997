import { Injectable, EventEmitter } from '@angular/core';
import { Ecosystem } from '../../models/ecosystem.class';
import { SummaryDetailAbstract } from '../../models/summary-details.intercface';
import { EEcosystemIndicatorReportType, IEcosystemIndicatorReportSteps } from '../../values/ecosystem-indicator-report.const';
import { ReportStrategyFactory } from './report-strategy-factory.service';
import {EcosystemStep} from '../../models/ecosystem-step.class';

@Injectable()
export class  EcosystemIndicatorReportContext {

    private ecosystem: Ecosystem;

    private steps: IEcosystemIndicatorReportSteps = {};

    private strategy = {};

    public onOtherChange = new EventEmitter<void>();

    setSteps(steps: IEcosystemIndicatorReportSteps) {
        this.steps = steps;
    }

    getEcosystem() {
        return this.ecosystem;
    }

    setEcosystem(ecosystem: Ecosystem) {
        this.ecosystem = ecosystem;
    }

    setContextByType(type: EEcosystemIndicatorReportType) {
        if (!this.strategy[type]) {
            this.strategy[type] = ReportStrategyFactory.of(type);
        }
    }

    getStepsStrategy(type: EEcosystemIndicatorReportType): IEcosystemIndicatorReportSteps {
        if (!this.strategy[type] || !this.strategy[type].getSteps) {return {}; }
        return this.strategy[type].getSteps(this.steps);
    }

    getOrdersByStep(type: EEcosystemIndicatorReportType, step: EcosystemStep) {
      return this.strategy[type].getOrders(step);
    }

    formatValueStrategy<T extends SummaryDetailAbstract>(type: EEcosystemIndicatorReportType, step: T) {
       if (!this.strategy[type] || !this.strategy[type].formatValue) {return null; }
       return this.strategy[type].formatValue(step);
    }

    isDataMissingStrategy<T extends SummaryDetailAbstract>(type: EEcosystemIndicatorReportType
        , errors: Array<any>, step: T) {
        if (!this.strategy[type] || !this.strategy[type].isDataMissing) {return true; }
        return this.strategy[type].isDataMissing(errors, step);
    }

    hasWarningsStrategy(type: EEcosystemIndicatorReportType, errors: Array<any>) {
        if (!this.strategy[type] || !this.strategy[type].getWarnings) {return false; }
        return this.strategy[type].hasWarnings(errors);
    }

    getWarningsStrategy(type: EEcosystemIndicatorReportType, errors: Array<any>) {
        if (!this.strategy[type] || !this.strategy[type].getWarnings) {return []; }
        return this.strategy[type].getWarnings(errors);
    }

    getUnit(type: EEcosystemIndicatorReportType) {
        if (!this.strategy[type]) {return null; }
        return this.strategy[type].unit;
    }

    otherFileJunctionType(type: EEcosystemIndicatorReportType) {
        if (!this.strategy[type]) {return null; }
        return this.strategy[type].fileType;
    }

    otherAdd(type: EEcosystemIndicatorReportType, value?: number) {
        if (this.isValidOtherField(type)) {return null; }
        return this.strategy[type].otherAdd(this.ecosystem, value);
    }

    otherSubtract(type: EEcosystemIndicatorReportType, value?: number) {
        if (this.isValidOtherField(type)) {return null; }
        return this.strategy[type].otherSubtract(this.ecosystem, value);
    }

    otherDescription(type: EEcosystemIndicatorReportType, value?: string) {
        if (this.isValidOtherField(type)) {return null; }
        return this.strategy[type].otherDescription(this.ecosystem, value);
    }

    getTotalsErrors(type: EEcosystemIndicatorReportType) {
        if (this.isValidOtherField(type)) {return [null]; }
        return this.strategy[type].getTotalErrors(this.ecosystem);
    }

    private isValidOtherField(type: EEcosystemIndicatorReportType) {
        return !this.strategy[type] || !this.ecosystem;
    }
}
