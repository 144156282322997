import { Component, Inject } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import { EcosystemOfferService } from '../../../../entities/ecosystem-offer/ecosystem-offer.service';
import { EcosystemOffer } from '../../../../entities/ecosystem-offer/ecosystem-offer.model';
import { NeedService } from '../../../../entities/need/services/need.service';
import { Need } from '../../../../entities/need/models/need.class';
import { combineLatest } from 'rxjs';

@Component({
  moduleId: module.id,
  template: `
    <md-dialog-content class="order-details__dialog">
      <dialog-close-button
        (click)="mdDialogRef.close()"
        [color]="'white'"
    ></dialog-close-button>
    
      <sales-offer
        *ngIf="offer?.loaded"
        [offer]="offer"
        (refused)="mdDialogRef.close()"
        (validated)="mdDialogRef.close()"
        (unvalidated)="mdDialogRef.close()"
        (confirmed)="mdDialogRef.close()"
        (askedForModification)="mdDialogRef.close()"
      ></sales-offer>
    </md-dialog-content>
  `,
})
export class SalesOfferDialogComponent {

  offer: EcosystemOffer;

  constructor(public mdDialogRef: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) private mdDialogData: any,
    private ecosystemOfferService: EcosystemOfferService,
    private needService: NeedService) {
    let need = this.mdDialogData.need;
    this.offer = this.mdDialogData.offer;
    let offerSubscription = this.ecosystemOfferService.view(this.offer.id, {
        expand: 'creator,ecosystem_file_junction,sales_price',
      }).map((res: EcosystemOffer) => {
        this.offer = res;
      });
    let needSubscription = this.needService.view(need.id, {expand: 'client,client_location,client_contact,client_name,passport,passport.composite_name'}).map((res: Need) => {
      need = res;
    })
    combineLatest(offerSubscription, needSubscription).subscribe(() => {
      this.offer.loaded = true;
      this.offer.need = need; 
    });
    }
}
