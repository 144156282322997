<ng-container [ngSwitch]="toxicityState">
    <!--None-->
    <svg *ngSwitchCase="ToxicityState.None" width="25" height="25" viewBox="0 0 25 25" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
            <path
                d="M12.1078 5.92725C8.69492 5.92725 5.90527 8.7269 5.90527 12.152C5.90527 15.5771 8.69492 18.3768 12.1078 18.3768C15.5207 18.3768 18.3103 15.5771 18.3103 12.152C18.3103 8.7269 15.5503 5.92725 12.1078 5.92725Z"
                fill="#555C63" />
            <path opacity="0.8"
                d="M10.8622 4.67602H13.3847C13.5925 4.67602 13.7409 4.58667 13.8596 4.43775C13.9486 4.28883 13.9783 4.11013 13.8892 3.90164L12.4944 0.327619C12.3757 0.0297831 12.1976 0 12.1086 0C12.0196 0 11.8415 0.0297831 11.7228 0.327619L10.328 3.90164C10.2686 4.08035 10.2686 4.28883 10.3577 4.43775C10.4764 4.58667 10.6544 4.67602 10.8622 4.67602Z"
                fill="#555C63" />
            <path opacity="0.8"
                d="M13.3836 19.657H10.861C10.6533 19.657 10.5049 19.7463 10.3862 19.8953C10.2971 20.0442 10.2675 20.2229 10.3565 20.4314L11.7513 24.0054C11.87 24.3032 12.0481 24.333 12.1371 24.333C12.2262 24.333 12.4042 24.3032 12.5229 24.0054L13.9178 20.4314C13.9771 20.2527 13.9771 20.0442 13.8881 19.8953C13.7397 19.7463 13.5913 19.657 13.3836 19.657Z"
                fill="#555C63" />
            <path
                d="M5.04482 7.59475C5.13385 7.80323 5.31192 7.95215 5.51966 7.95215C5.66804 7.95215 5.81643 7.89258 5.93513 7.77345L7.71576 5.98644C7.86414 5.83752 7.9235 5.65882 7.89382 5.48012C7.86415 5.30142 7.74544 5.1525 7.56737 5.09293L4.06548 3.54419C3.97645 3.5144 3.88741 3.48462 3.82806 3.48462C3.70935 3.48462 3.59064 3.54419 3.53129 3.63354C3.47193 3.72289 3.44226 3.8718 3.53129 4.08029L5.04482 7.59475Z"
                fill="#555C63" />
            <path
                d="M19.2016 16.7385C19.1126 16.53 18.9345 16.3811 18.7268 16.3811C18.5784 16.3811 18.43 16.4407 18.3113 16.5598L16.5307 18.3468C16.3823 18.4957 16.323 18.6744 16.3526 18.8531C16.3823 19.0318 16.501 19.1808 16.6791 19.2403L20.181 20.7891C20.27 20.8189 20.359 20.8486 20.4184 20.8486C20.5371 20.8486 20.6558 20.7891 20.7152 20.6997C20.7745 20.6104 20.8042 20.4614 20.7152 20.253L19.2016 16.7385Z"
                fill="#555C63" />
            <path
                d="M18.2816 7.74391C18.4004 7.86304 18.5487 7.92261 18.6971 7.92261C18.9049 7.92261 19.0829 7.80348 19.172 7.56521L20.7152 4.05075C20.8042 3.81248 20.7745 3.69335 20.7152 3.604C20.6558 3.51465 20.5371 3.45508 20.4184 3.45508C20.3294 3.45508 20.27 3.48486 20.181 3.51465L16.6791 5.06339C16.501 5.15274 16.3823 5.30166 16.3526 5.45058C16.323 5.62928 16.3823 5.80798 16.5307 5.9569L18.2816 7.74391Z"
                fill="#555C63" />
            <path
                d="M5.93552 16.5893C5.81681 16.4702 5.66842 16.4106 5.52004 16.4106C5.3123 16.4106 5.13423 16.5298 5.0452 16.768L3.50199 20.2825C3.41296 20.5208 3.44264 20.6399 3.50199 20.7293C3.56135 20.8186 3.68005 20.8782 3.79876 20.8782C3.88779 20.8782 3.94715 20.8484 4.03618 20.8186L7.53808 19.2699C7.71614 19.1805 7.83485 19.0316 7.86453 18.8827C7.8942 18.704 7.83485 18.5253 7.68646 18.3764L5.93552 16.5893Z"
                fill="#555C63" />
            <path opacity="0.8"
                d="M23.9189 11.7944L20.3576 10.3945C20.2686 10.3647 20.2093 10.335 20.1202 10.335C19.8235 10.335 19.6157 10.5732 19.6157 10.9008V13.4325C19.6157 13.7601 19.8235 13.9983 20.1202 13.9983C20.2093 13.9983 20.2686 13.9686 20.3576 13.9388L23.9189 12.5389C24.2157 12.4198 24.2454 12.2411 24.2454 12.1518C24.2454 12.0624 24.186 11.8837 23.9189 11.7944Z"
                fill="#555C63" />
            <path opacity="0.8"
                d="M4.12511 13.9983C4.42188 13.9983 4.62963 13.7601 4.62963 13.4325V10.9008C4.62963 10.5732 4.42188 10.335 4.12511 10.335C4.03608 10.335 3.97673 10.3647 3.8877 10.3945L0.32645 11.7944C0.0296789 11.9135 0 12.0922 0 12.1815C0 12.2709 0.0296789 12.4496 0.32645 12.5687L3.8877 13.9686C3.97673 13.9686 4.03608 13.9983 4.12511 13.9983Z"
                fill="#555C63" />
        </g>
    </svg>
    <!--Unverified-->
    <svg *ngSwitchCase="ToxicityState.Unverified" width="25" height="25" viewBox="0 0 25 25" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.1078 5.92725C8.69492 5.92725 5.90527 8.7269 5.90527 12.152C5.90527 15.5771 8.69492 18.3768 12.1078 18.3768C15.5207 18.3768 18.3103 15.5771 18.3103 12.152C18.3103 8.7269 15.5503 5.92725 12.1078 5.92725Z"
            fill="black" fill-opacity="0.8" />
        <path opacity="0.8"
            d="M10.8622 4.67602H13.3847C13.5925 4.67602 13.7409 4.58667 13.8596 4.43775C13.9486 4.28883 13.9783 4.11013 13.8892 3.90164L12.4944 0.327619C12.3757 0.0297831 12.1976 0 12.1086 0C12.0196 0 11.8415 0.0297831 11.7228 0.327619L10.328 3.90164C10.2686 4.08035 10.2686 4.28883 10.3577 4.43775C10.4764 4.58667 10.6544 4.67602 10.8622 4.67602Z"
            fill="black" fill-opacity="0.8" />
        <path opacity="0.8"
            d="M13.3836 19.657H10.861C10.6533 19.657 10.5049 19.7463 10.3862 19.8953C10.2971 20.0442 10.2675 20.2229 10.3565 20.4314L11.7513 24.0054C11.87 24.3032 12.0481 24.333 12.1371 24.333C12.2262 24.333 12.4042 24.3032 12.5229 24.0054L13.9178 20.4314C13.9771 20.2527 13.9771 20.0442 13.8881 19.8953C13.7397 19.7463 13.5913 19.657 13.3836 19.657Z"
            fill="black" fill-opacity="0.8" />
        <path
            d="M5.04482 7.59475C5.13385 7.80323 5.31192 7.95215 5.51966 7.95215C5.66804 7.95215 5.81643 7.89258 5.93513 7.77345L7.71576 5.98644C7.86414 5.83752 7.9235 5.65882 7.89382 5.48012C7.86415 5.30142 7.74544 5.1525 7.56737 5.09293L4.06548 3.54419C3.97645 3.5144 3.88741 3.48462 3.82806 3.48462C3.70935 3.48462 3.59064 3.54419 3.53129 3.63354C3.47193 3.72289 3.44226 3.8718 3.53129 4.08029L5.04482 7.59475Z"
            fill="black" fill-opacity="0.8" />
        <path
            d="M19.2016 16.7385C19.1126 16.53 18.9345 16.3811 18.7268 16.3811C18.5784 16.3811 18.43 16.4407 18.3113 16.5598L16.5307 18.3468C16.3823 18.4957 16.323 18.6744 16.3526 18.8531C16.3823 19.0318 16.501 19.1808 16.6791 19.2403L20.181 20.7891C20.27 20.8189 20.359 20.8486 20.4184 20.8486C20.5371 20.8486 20.6558 20.7891 20.7152 20.6997C20.7745 20.6104 20.8042 20.4614 20.7152 20.253L19.2016 16.7385Z"
            fill="black" fill-opacity="0.8" />
        <path
            d="M18.2816 7.74391C18.4004 7.86304 18.5487 7.92261 18.6971 7.92261C18.9049 7.92261 19.0829 7.80348 19.172 7.56521L20.7152 4.05075C20.8042 3.81248 20.7745 3.69335 20.7152 3.604C20.6558 3.51465 20.5371 3.45508 20.4184 3.45508C20.3294 3.45508 20.27 3.48486 20.181 3.51465L16.6791 5.06339C16.501 5.15274 16.3823 5.30166 16.3526 5.45058C16.323 5.62928 16.3823 5.80798 16.5307 5.9569L18.2816 7.74391Z"
            fill="black" fill-opacity="0.8" />
        <path
            d="M5.93552 16.5893C5.81681 16.4702 5.66842 16.4106 5.52004 16.4106C5.3123 16.4106 5.13423 16.5298 5.0452 16.768L3.50199 20.2825C3.41296 20.5208 3.44264 20.6399 3.50199 20.7293C3.56135 20.8186 3.68005 20.8782 3.79876 20.8782C3.88779 20.8782 3.94715 20.8484 4.03618 20.8186L7.53808 19.2699C7.71614 19.1805 7.83485 19.0316 7.86453 18.8827C7.8942 18.704 7.83485 18.5253 7.68646 18.3764L5.93552 16.5893Z"
            fill="black" fill-opacity="0.8" />
        <path opacity="0.8"
            d="M23.9189 11.7944L20.3576 10.3945C20.2686 10.3647 20.2093 10.335 20.1202 10.335C19.8235 10.335 19.6157 10.5732 19.6157 10.9008V13.4325C19.6157 13.7601 19.8235 13.9983 20.1202 13.9983C20.2093 13.9983 20.2686 13.9686 20.3576 13.9388L23.9189 12.5389C24.2157 12.4198 24.2454 12.2411 24.2454 12.1518C24.2454 12.0624 24.186 11.8837 23.9189 11.7944Z"
            fill="black" fill-opacity="0.8" />
        <path opacity="0.8"
            d="M4.12511 13.9983C4.42188 13.9983 4.62963 13.7601 4.62963 13.4325V10.9008C4.62963 10.5732 4.42188 10.335 4.12511 10.335C4.03608 10.335 3.97673 10.3647 3.8877 10.3945L0.32645 11.7944C0.0296789 11.9135 0 12.0922 0 12.1815C0 12.2709 0.0296789 12.4496 0.32645 12.5687L3.8877 13.9686C3.97673 13.9686 4.03608 13.9983 4.12511 13.9983Z"
            fill="black" fill-opacity="0.8" />
    </svg>

    <!--Verified-->
    <svg *ngSwitchCase="ToxicityState.Verified" width="25" height="25" viewBox="0 0 25 25" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.1078 5.92725C8.69492 5.92725 5.90527 8.7269 5.90527 12.152C5.90527 15.5771 8.69492 18.3768 12.1078 18.3768C15.5207 18.3768 18.3103 15.5771 18.3103 12.152C18.3103 8.7269 15.5503 5.92725 12.1078 5.92725Z"
            fill="#8DC53F" />
        <path opacity="0.8"
            d="M10.8622 4.67602H13.3847C13.5925 4.67602 13.7409 4.58667 13.8596 4.43775C13.9486 4.28883 13.9783 4.11013 13.8892 3.90164L12.4944 0.327619C12.3757 0.0297831 12.1976 0 12.1086 0C12.0196 0 11.8415 0.0297831 11.7228 0.327619L10.328 3.90164C10.2686 4.08035 10.2686 4.28883 10.3577 4.43775C10.4764 4.58667 10.6544 4.67602 10.8622 4.67602Z"
            fill="#8DC53F" />
        <path opacity="0.8"
            d="M13.3836 19.657H10.861C10.6533 19.657 10.5049 19.7463 10.3862 19.8953C10.2971 20.0442 10.2675 20.2229 10.3565 20.4314L11.7513 24.0054C11.87 24.3032 12.0481 24.333 12.1371 24.333C12.2262 24.333 12.4042 24.3032 12.5229 24.0054L13.9178 20.4314C13.9771 20.2527 13.9771 20.0442 13.8881 19.8953C13.7397 19.7463 13.5913 19.657 13.3836 19.657Z"
            fill="#8DC53F" />
        <path
            d="M5.04482 7.59475C5.13385 7.80323 5.31192 7.95215 5.51966 7.95215C5.66804 7.95215 5.81643 7.89258 5.93513 7.77345L7.71576 5.98644C7.86414 5.83752 7.9235 5.65882 7.89382 5.48012C7.86415 5.30142 7.74544 5.1525 7.56737 5.09293L4.06548 3.54419C3.97645 3.5144 3.88741 3.48462 3.82806 3.48462C3.70935 3.48462 3.59064 3.54419 3.53129 3.63354C3.47193 3.72289 3.44226 3.8718 3.53129 4.08029L5.04482 7.59475Z"
            fill="#8DC53F" />
        <path
            d="M19.2016 16.7385C19.1126 16.53 18.9345 16.3811 18.7268 16.3811C18.5784 16.3811 18.43 16.4407 18.3113 16.5598L16.5307 18.3468C16.3823 18.4957 16.323 18.6744 16.3526 18.8531C16.3823 19.0318 16.501 19.1808 16.6791 19.2403L20.181 20.7891C20.27 20.8189 20.359 20.8486 20.4184 20.8486C20.5371 20.8486 20.6558 20.7891 20.7152 20.6997C20.7745 20.6104 20.8042 20.4614 20.7152 20.253L19.2016 16.7385Z"
            fill="#8DC53F" />
        <path
            d="M18.2816 7.74391C18.4004 7.86304 18.5487 7.92261 18.6971 7.92261C18.9049 7.92261 19.0829 7.80348 19.172 7.56521L20.7152 4.05075C20.8042 3.81248 20.7745 3.69335 20.7152 3.604C20.6558 3.51465 20.5371 3.45508 20.4184 3.45508C20.3294 3.45508 20.27 3.48486 20.181 3.51465L16.6791 5.06339C16.501 5.15274 16.3823 5.30166 16.3526 5.45058C16.323 5.62928 16.3823 5.80798 16.5307 5.9569L18.2816 7.74391Z"
            fill="#8DC53F" />
        <path
            d="M5.93552 16.5893C5.81681 16.4702 5.66842 16.4106 5.52004 16.4106C5.3123 16.4106 5.13423 16.5298 5.0452 16.768L3.50199 20.2825C3.41296 20.5208 3.44264 20.6399 3.50199 20.7293C3.56135 20.8186 3.68005 20.8782 3.79876 20.8782C3.88779 20.8782 3.94715 20.8484 4.03618 20.8186L7.53808 19.2699C7.71614 19.1805 7.83485 19.0316 7.86453 18.8827C7.8942 18.704 7.83485 18.5253 7.68646 18.3764L5.93552 16.5893Z"
            fill="#8DC53F" />
        <path opacity="0.8"
            d="M23.9189 11.7944L20.3576 10.3945C20.2686 10.3647 20.2093 10.335 20.1202 10.335C19.8235 10.335 19.6157 10.5732 19.6157 10.9008V13.4325C19.6157 13.7601 19.8235 13.9983 20.1202 13.9983C20.2093 13.9983 20.2686 13.9686 20.3576 13.9388L23.9189 12.5389C24.2157 12.4198 24.2454 12.2411 24.2454 12.1518C24.2454 12.0624 24.186 11.8837 23.9189 11.7944Z"
            fill="#8DC53F" />
        <path opacity="0.8"
            d="M4.12511 13.9983C4.42188 13.9983 4.62963 13.7601 4.62963 13.4325V10.9008C4.62963 10.5732 4.42188 10.335 4.12511 10.335C4.03608 10.335 3.97673 10.3647 3.8877 10.3945L0.32645 11.7944C0.0296789 11.9135 0 12.0922 0 12.1815C0 12.2709 0.0296789 12.4496 0.32645 12.5687L3.8877 13.9686C3.97673 13.9686 4.03608 13.9983 4.12511 13.9983Z"
            fill="#8DC53F" />
    </svg>

</ng-container>