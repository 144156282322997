import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
var PassportFormatService = /** @class */ (function (_super) {
    tslib_1.__extends(PassportFormatService, _super);
    function PassportFormatService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'passport/format';
        _this.namespaceSingular = 'passport/format';
        _this.ModelClass = String;
        return _this;
    }
    return PassportFormatService;
}(CrudService));
export { PassportFormatService };
