<conversion-field
  [placeholder]="placeholder"
  [identifier]="identifier"
  [quantity]="quantityField"
  [conversionFactor]="conversionFactorField"
  [volumeUnit]="volumeUnitField"
  [isDisabled]="disabled"
  [emptyQuantityHolder]="''"
  (quantityInTonsChanged)="emitQuantityValue($event)"
>
</conversion-field>
