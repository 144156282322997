import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransformLocation } from '../../../../entities/transformation/models/transform-location.class';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'transform-site-details',
  templateUrl: 'transform-site-details.component.html',
  styleUrls: ['transform-site-details.component.scss'],
  providers: [ReadOnlyService],
})
export class TransformSiteDetailsComponent implements OnInit {
  @Input() id: number;
  @Input() readOnly = false;
  validAdress : boolean = true ;
  location: TransformLocation = new TransformLocation;
  @Output() saved = new EventEmitter;

  constructor(public readOnlyService: ReadOnlyService,
    private logNotificationService: LogNotificationService,
    private _translate: TranslateService,) {
  }

  ngOnInit() {
    this.readOnlyService.readOnly = this.readOnly;
   
    this.location.id = this.id;
    this.location
      .load()
      .subscribe((location: TransformLocation) => {
          this.location = location;
          this.location.loaded = true;
        }
      )
    ;
  }

  onPlaceChange(place: google.maps.places.PlaceResult) {
    if (place.geometry) {
      this.location.lat = place.geometry.location.lat();
      this.location.lng = place.geometry.location.lng();
      this.validAdress =  true ; 
    } else {
      this.validAdress =false ;
      this.location.lat = null;
      this.location.lng = null;
    }
  }
  onTextChange (){
   
    this.validAdress =false
  }

  save() {
    if (!this.validAdress) {
      this.logNotificationService.error(this._translate.instant('Please select your address from suggestions'),null, true);
      return ;
    }
    this.location.save().subscribe((location) => {
      this.saved.emit(location);
      //this.router.navigate(['transformation-manager']);
    });
  }
}
