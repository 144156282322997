/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./common-info-sign.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./common-info-sign.component";
var styles_CommonInfoSignComponent = [i0.styles];
var RenderType_CommonInfoSignComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CommonInfoSignComponent, data: {} });
export { RenderType_CommonInfoSignComponent as RenderType_CommonInfoSignComponent };
export function View_CommonInfoSignComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "primary-color": 0 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((("common-info-sign common-info-sign--x" + _co.borderSizeClassModifier) + " common-info-sign--size") + _co.sizeClassModifier); var currVal_1 = _ck(_v, 2, 0, _co.primaryFill); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CommonInfoSignComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "common-info-sign", [], null, null, null, View_CommonInfoSignComponent_0, RenderType_CommonInfoSignComponent)), i1.ɵdid(1, 49152, null, 0, i3.CommonInfoSignComponent, [], null, null)], null, null); }
var CommonInfoSignComponentNgFactory = i1.ɵccf("common-info-sign", i3.CommonInfoSignComponent, View_CommonInfoSignComponent_Host_0, { primaryFill: "primaryFill", borderSizeClassModifier: "borderSizeClassModifier", sizeClassModifier: "sizeClassModifier" }, {}, ["*"]);
export { CommonInfoSignComponentNgFactory as CommonInfoSignComponentNgFactory };
