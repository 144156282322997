<div class="search" [formGroup]="form">
  <md-input-container class="date">
    <input
      mdInput
      autocomplete="off" 
      ng2-datetime-picker
      date-format="DD-MM-YYYY"
      parse-format="YYYY-MM-DD"
      date-only="true"
      [placeholder]="'Arrival date FROM' | translate"
      formControlName="arrivalDateFrom"
    >
  </md-input-container>

  <span class="span"></span>

  <md-input-container class="date">
    <input
      mdInput
      autocomplete="off" 
      ng2-datetime-picker
      date-format="DD-MM-YYYY"
      parse-format="YYYY-MM-DD"
      date-only="true"
      [placeholder]="'Arrival date TO' | translate"
      formControlName="arrivalDateTo"
    >
  </md-input-container>

  <md-select placeholder="{{'Sort by' | translate}}" class="sort-by">
    <md-option *ngFor="let SORT_MODE of SORT_MODES">
      {{SORT_MODE.view | translate}}
    </md-option>
  </md-select>
</div>
<div class="results">
  <div *ngFor="let STATUS of ORDER_STATUSES" class="tab {{STATUS.text.toLowerCase()}}">
    <h2 class="title">
      {{STATUS.view | translate}}

      <svg viewBox="-1 -1 2 2" class="dot">
        <circle cx="0" cy="0" r="0.25"></circle>
      </svg>

      <svg viewBox="-1 -1 2 2" preserveAspectRatio="none" class="tip">
        <path d="M -1 -1 L 1 0 L -1 1 Z"></path>
      </svg>
    </h2>
    <ul>
      <li *ngFor="let transform of results[STATUS.text]">
        <span class="name">{{transform.name || '(Unnamed)'}}</span>

        <md-menu #appMenu="mdMenu">
          <button md-menu-item>{{'Settings' | translate}}</button>
          <button md-menu-item>{{'Help' | translate}}</button>
        </md-menu>

        <button md-icon-button [mdMenuTriggerFor]="appMenu" class="menu">
          <md-icon>menu</md-icon>
        </button>
      </li>
    </ul>
  </div>
</div>
