import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { cloneDeep, isEqual, omit } from 'lodash';
var PassportResetButtonComponent = /** @class */ (function () {
    function PassportResetButtonComponent() {
        this.disabled = false;
        this.buttonText = 'Clear filters';
        this.alternativeRules = {};
        this.resetFilterState = {};
        this.passportFilter = {};
        this.controlAsFilter = ['search'];
        this.onResetClick = new EventEmitter();
    }
    PassportResetButtonComponent.prototype.canClearFilters = function () {
        var _this = this;
        var valReset = function (key) { return _this.resetFilterState[key]; };
        var valActual = function (key) { return _this.passportFilter[key]; };
        var valSkip = function (key) { return _this.alternativeRules[key]; };
        var isChanged = function (key) { return !isEqual(valReset(key), valActual(key)) && !isEqual(valActual(key), valSkip(key)); };
        var isChangedSearch = function (key) { return !isEqual(valReset(key).value, valActual(key).value)
            && !isEqual(valActual(key).value, valSkip(key)); };
        for (var key in this.resetFilterState) {
            if (this.controlAsFilter.includes(key)) {
                if (isChangedSearch(key)) {
                    return true;
                }
            }
            else if (isChanged(key)) {
                return true;
            }
        }
        return false;
    };
    PassportResetButtonComponent.prototype.clearFilters = function () {
        var _this = this;
        var newPassportFilter = tslib_1.__assign({}, cloneDeep(omit(this.resetFilterState, this.controlAsFilter)));
        this.controlAsFilter.forEach(function (control) {
            _this.passportFilter[control].setValue(_this.resetFilterState[control].value);
            newPassportFilter[control] = _this.passportFilter[control];
        });
        Object.keys(this.passportFilter).forEach(function (key) {
            if (!(key in newPassportFilter)) {
                newPassportFilter[key] = _this.passportFilter[key];
            }
        });
        this.onResetClick.emit(newPassportFilter);
    };
    return PassportResetButtonComponent;
}());
export { PassportResetButtonComponent };
