<section class="form-group need-edit">
  <label for="needQuantity" class="form-group__label">{{ 'Unit' | translate }}</label>
  <div class="form-group__content--row va--bottom">
    <div class="form-group__field need-edit__units ib-row--justify">
      <div class="form-group__field need-edit__quantity-field" [formGroup]="editForm">
        <md-input-container
          [class.highlighted]="controlStates?.quantity?.highlighted"
          floatPlaceholder="always"
        >
          <input
            mdInput
            id="needQuantity"
            placeholder="{{ 'Quantity needed' | translate }} *"
            [(ngModel)]="need.quantity"
            autocomplete="doNotAutocompleteplease"
            formControlName="quantity"
            [disabled]="controlStates?.quantity?.disabled"
            required
          >
        </md-input-container>
      </div>
      <div class="form-group__field need-edit__unit-field">
        <md-input-container>
          <input mdInput placeholder="{{ 'Unit' | translate }}" autocomplete="off" disabled="disabled" [value]="need.passport.unit_text | translate">
        </md-input-container>
      </div>
      <div class="form-group__field need-edit__qprecision-field">
        <md-select
          placeholder="{{ 'QTY requirement' | translate }}"
          [(ngModel)]="need.quantity_precision"
          [disabled]="controlStates.quantity_precision?.disabled"
          [class.highlighted]="controlStates?.quantity_precision?.highlighted"
          (change)="onQuantityPrecisionChange()"
        >
          <md-option *ngFor="let item of QUANTITY_PRECISION_TYPES" [value]="item.key">{{item.value | translate}}</md-option>
        </md-select>
      </div>
    </div>
    <div class="form-group__field need-edit__dates ib-row--justify">
      <div class="form-group__field need-edit__date-field">
        <md-input-container
          [class.highlighted]="controlStates?.delivery_date?.highlighted"
          floatPlaceholder="always"
        >
          <input
            ng2-datetime-picker
            mdInput
            autocomplete="off"
            date-only="true"
            placeholder="{{ 'Date of delivery' | translate }} *"
            [(ngModel)]="this.need.delivery_date"
            required
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            [disabled]="isControlDisabled('delivery_date')"
          ></md-input-container>
      </div>
      <div class="form-group__field need-edit__dprecision-field">
        <md-select
          placeholder="{{ 'Date requirement' | translate }}"
          [(ngModel)]="need.date_precision"
          [disabled]="controlStates.date_precision?.disabled"
          [class.highlighted]="controlStates?.date_precision?.highlighted"
        >
          <md-option *ngFor="let item of DATE_PRECISION_TYPES" [value]="item.key">{{item.value | translate}}</md-option>
        </md-select>
      </div>
    </div>
  </div>
</section>

<section class="form-group need-edit">
  <label for="needQuantity" class="form-group__label">{{ 'Delivery location' | translate }}</label>
  <div
    class="form-group__content--row need-edit__delivery"
    [class.highlighted]="controlStates?.client_location_id?.highlighted"
  >
    <div class="form-group__field need-edit__delivery-field">
      <custom-search
        caption="{{ 'Name' | translate }} *"
        [preset]="need?.client_location"
        [service]="locationService"
        [listener]="need.onChangeLocation"
        (onSelect)="onSelectLocation($event)"
        (onCreate)="onCreateLocation()"
        (onChangeName)="onChangeName($event)"
        [filters]="{client_id: clientId}"
        [label]="'New Delivery Location' | translate"
        [required]="true"
        [disabled]="controlStates.client_location_id?.disabled"
      ></custom-search>

      <!--<md-select placeholder="Site name" [(ngModel)]="need.client_location" selected="need.client_location" name="delivery">
      <md-option *ngFor="let delivery of deliveries" [value]="delivery" (onSelect)="need.setLocation(delivery)">
      {{delivery.name}}
      </md-option>
      </md-select>-->
    </div>
    <div class="form-group__field need-edit__address-field">
      <md-input-container class="textarea-50">
        <textarea name="next" mdInput placeholder="{{ 'Site address' | translate }}" [disabled]="true"
                  [(ngModel)]="need?.client_location.address"></textarea>
      </md-input-container>
    </div>
    <div class="form-group__field need-edit__description-field">
      <md-input-container>
        <textarea name="text" mdInput placeholder="{{ 'Description' | translate }}" [disabled]="true"
                  [(ngModel)]="need?.client_location.description"></textarea>
      </md-input-container>
    </div>
  </div>
</section>

<section class="form-group">
  <label class="form-group__label">{{ 'Info' | translate }}</label>
  <div class="form-group__content--row need-edit__info">
    <div class="form-group__field need-edit__description-field">
      <md-input-container
        [class.highlighted]="controlStates?.description?.highlighted"
      >
        <textarea
          mdInput
          placeholder="{{ 'Description' | translate }}"
          [(ngModel)]="need.description"
          [disabled]="controlStates.description?.disabled"
        ></textarea>
      </md-input-container>
    </div>
  </div>
  <label class="form-group__label">{{ 'Documents' | translate }}</label>
  <ul class="upload-files__list">
    <document-item
      *ngFor="let item of uploadedfiles; let i=index"
      [file]="item"
      [itemClass]="{'highlighted': controlStates?.photos?.highlighted}"
      [isRemoveFromFilesDisabled]="false"
      (remove)="removeFile(i)">
    </document-item>
    <li class="upload-files__download"
        *ngIf="!controlStates.files?.disabled">
      <input
        class="upload-files__add"
        autocomplete="off"
        id="needFileDoc"
        type="file"
        name="preview"
        ng2FileSelect
        (change)="onNeedFileSelected($event)"
        [uploader]="needFileUploader">
      <label for="needFileDoc">
        <i class="upload-files__camera camera--icon">{{ 'Upload' | translate }}</i>
      </label>
    </li>
  </ul>
</section>
