import {ICustomSelectionListWithChipRecord} from './interface/custom-selection-list-with-chip-record';

export class CustomSelectionListWithChipRecordModel implements  ICustomSelectionListWithChipRecord {
  classList: string[] = [];
  value: any;
  nameFunc: (value) => string;

  get name() {
    return !!this.nameFunc ? this.nameFunc(this.value) : this.value;
  }

  static build() {
    return new CustomSelectionListWithChipRecordModel();
  }

  public addClass(classNames: string[]) {
    this.classList = this.classList.concat(classNames);
    return this;
  }
  public setNameFunc(fn: (value) => string) {
    this.nameFunc = fn;
    return this;
  }

  public addValue(value: any) {
    this.value = value;
    return this;
  }
}
