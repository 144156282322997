import { EcosystemOffer } from './ecosystem-offer.model';
import { ExtendedCrudService } from '../../shared/services/extended-crud.service';
import {Observable} from 'rxjs';
import {Response} from '@angular/http';

export class EcosystemOfferService extends ExtendedCrudService {
  protected namespace = 'ecosystem-offers';
  protected namespaceSingular = 'ecosystem-offer';
  protected ModelClass = EcosystemOffer;

  protected getResendUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/resend');
  }

  public sendModification(id: number, modification: string) {
    return this.sendPost(this.getAskForModificationUrl(id), {modification});
  }

  getCount(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }


  sendReport(id: number, problem: string) {
    return this.sendPost(this.getReportUrl(id), {problem});
  }

  protected getReportUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/problem');
  }

  public resend(id: number, data): Observable<any> {
    return this.sendPost(this.getResendUrl(id), data)
      .map((res: Response) =>  new EcosystemOffer(res.json()))
      .catch(this.onError.bind(this));
  }
}
