/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./base-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i3 from "../../../../shared/read-only/read-only.service";
import * as i4 from "@angular/common";
import * as i5 from "./base-dialog.component";
import * as i6 from "@angular/material";
import * as i7 from "@angular/router";
import * as i8 from "../../../../shared/services/navigation.service";
import * as i9 from "@ngx-translate/core";
var styles_BaseDialogComponent = [i0.styles];
var RenderType_BaseDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BaseDialogComponent, data: {} });
export { RenderType_BaseDialogComponent as RenderType_BaseDialogComponent };
function View_BaseDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTranslatedText(_co.data.title); _ck(_v, 1, 0, currVal_0); }); }
function View_BaseDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "dialog-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTranslatedText(_co.data.subtitle); _ck(_v, 1, 0, currVal_0); }); }
function View_BaseDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "dialog-paragraph"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTranslatedText(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_BaseDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "dialog-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "dialog-button"]], [[8, "disabled", 0], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.executeAction(_v.context.$implicit.action) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.FormElemsHiddenDirective, [[2, i3.ReadOnlyService]], null, null), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.isDisabled ? _v.context.$implicit.isDisabled() : false); var currVal_1 = ((i1.ɵnov(_v, 2).readOnlyService == null) ? null : i1.ɵnov(_v, 2).readOnlyService.readOnly); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.getTranslatedText(_v.context.$implicit.content); _ck(_v, 3, 0, currVal_2); }); }
export function View_BaseDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { baseDialog: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["baseDialog", 1]], null, 15, "div", [["class", "app-base-dialog"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon icon--close_s"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "dialog-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "dialog-header-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaseDialogComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaseDialogComponent_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "dialog-body-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaseDialogComponent_3)), i1.ɵdid(13, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "dialog-button-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaseDialogComponent_4)), i1.ɵdid(16, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "app-base-dialog"; var currVal_1 = _co.getClassColor(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.data.title; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.data.subtitle; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.data.paragraphs; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.buttons; _ck(_v, 16, 0, currVal_5); }, null); }
export function View_BaseDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-base-dialog", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BaseDialogComponent_0, RenderType_BaseDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.BaseDialogComponent, [i6.MD_DIALOG_DATA, i6.MdDialogRef, i7.Router, i8.NavigationService, i9.TranslateService], null, null)], null, null); }
var BaseDialogComponentNgFactory = i1.ɵccf("app-base-dialog", i5.BaseDialogComponent, View_BaseDialogComponent_Host_0, {}, {}, []);
export { BaseDialogComponentNgFactory as BaseDialogComponentNgFactory };
