<div class="superadmin-zones">
  <div class="superadmin-zones__area">

    <button class="superadmin-zones__button" (click)="newZone()">
      <span>+ {{ 'Create new zone' | translate }}</span>
    </button>

    <div class="superadmin-zones__cards-wrapper">
      <div class="superadmin-zones__card-wrapper" *ngFor="let zone of zones">
        <div class="superadmin-zones__card-description-wrapper" (click)="goToZone(zone)">
          <div class="superadmin-zones__card-description">
            <h4>{{zone.name}}</h4>
            <span>{{zone.passport_count}} {{'System Passports' | translate | lowercase}}</span>
          </div>
          <div class="superadmin-zones__card-description">
            <span *ngIf="zone.status == ZONE_STATUS.DRAFT"><i>{{ 'Draft' | translate }}</i></span>
          </div>
        </div>
        <div class="superadmin-zones__card-button superadmin-zones__card-button-delete" (click)="removeZone(zone)">
        </div>
        <div class="superadmin-zones__card-button-group">
          <div class="superadmin-zones__card-button superadmin-zones__card-button-edit" (click)="editZone(zone)"></div>
          <div class="superadmin-zones__card-button superadmin-zones__card-button-copy" (click)="copyZone(zone)"></div>
        </div>
      </div>
    </div>

  </div>
  <div class="superadmin-zones__tags">
    <div class="superadmin-zones__tags--container">
      <h3 class="superadmin-zones__tags--header">{{'Tags' | translate}}</h3>
      <div class="superadmin-zones__tags--search-field">
          <i class="icon icon--search x2"></i>
          <md-input-container class="search">
            <input autocomplete="off" 
                   mdInput
                   placeholder="{{ 'Search by anything' | translate }}"
                   [formControl]="searchControl"
                   >
          </md-input-container>
      </div>
      <div class="superadmin-zones__tags--table-header">
        <span>{{'Tags' | translate}}</span>
        <span>{{'Synonyms' | translate}}</span>
      </div>
      <div class="superadmin-zones__tags--table-body">
        <div class="superadmin-zones__tags--table-row" *ngFor="let tag of tags">
          <div class="superadmin-zones__tags--tag">
            {{tag.name}}
          </div>
          <div class="superadmin-zones__tags--synonyms">
            <div class="superadmin-zones__tags--synonyms-tags">
              <div class="superadmin-zones__tags--synonyms-tag" *ngFor="let synonym of tag.synonyms">
                <span>{{synonym.name}}</span>
                <button (click)="removeSynonym(synonym.id)">X</button>
              </div>
            </div>
            <div class="superadmin-zones__tags--synonyms-input">
              <md-input-container class="superadmin-zones__tags--input-tag" floatPlaceholder="never">
                <input #tagName (keyup.enter)="onEnter(tagName.value, tag.id)"
                        autocomplete="off" 
                        mdInput
                        placeholder="{{ 'New synonym tag' | translate }}">
              </md-input-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>