<ng-container [ngSwitch]="status">
    <!--Draft-->
    <svg *ngSwitchCase="1" style="transform: translate(1px,-1px);" width="16" height="16" viewBox="0 0 16 16"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.666491 12.9399C0.666864 12.9363 0.667687 12.9326 0.668137 12.929C0.66851 12.9265 0.667763 12.9241 0.668137 12.9216L1.12272 9.02892C1.13852 8.8948 1.19899 8.7696 1.29426 8.6741L8.61533 1.3361C8.7463 1.20483 8.93573 1.15239 9.11578 1.17392L9.30273 0.986537C9.61137 0.67719 10.0266 0.5 10.4427 0.5C10.8024 0.5 11.1353 0.634948 11.3799 0.880016L15.0861 4.59434C15.6465 5.15565 15.6364 6.18889 15.0646 6.76163L14.8833 6.94332C14.9085 7.12335 14.8541 7.31239 14.7158 7.45102L7.39514 14.789C7.29657 14.8882 7.16597 14.9492 7.02692 14.9621L3.05792 15.3329H3.05508H3.05388L1.15033 15.4975C1.13326 15.4992 1.11583 15.5 1.09884 15.5C0.940701 15.5 0.788174 15.437 0.675461 15.324C0.550178 15.1988 0.486863 15.0244 0.50228 14.8476L0.666491 12.9399ZM6.4215 11.688L11.7502 6.34694L9.5473 4.13893L4.27625 9.42217L4.63961 11.351L6.4215 11.688ZM9.0392 2.27006L8.86969 2.43996L13.6144 7.19607L13.784 7.02617L9.0392 2.27006ZM3.65454 9.02601L9.03882 3.62928L8.53028 3.11956L2.79884 8.86426L3.65454 9.02601ZM14.239 5.4438L10.5328 1.7291C10.526 1.7222 10.5049 1.70104 10.4428 1.70104C10.3467 1.70104 10.2319 1.75385 10.15 1.83599L10.0555 1.93073L14.123 6.00723L14.2175 5.91249C14.3331 5.79659 14.329 5.53404 14.239 5.4438ZM12.9368 7.53602L12.2592 6.85677L6.93255 12.1957L7.14786 13.3387L12.9368 7.53602ZM3.22758 14.1107L6.43501 13.8111L6.17749 12.4457L4.22939 12.0771C4.06998 12.047 3.94553 11.9218 3.91514 11.7621L3.54731 9.80987L2.26664 9.5672L1.89237 12.7726L3.22758 14.1107Z"
            fill="white" />
    </svg>
    <!--Asked for modifications-->
    <svg *ngSwitchCase="2" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.50018 0.500081C12.918 0.500081 16.5 4.08205 16.5 8.50039C16.5 12.9182 12.918 16.5002 8.50018 16.5002C7.32189 16.5002 6.20335 16.2451 5.19587 15.788L1.69418 17.1602L2.54723 13.8439C1.27423 12.4271 0.5 10.5542 0.5 8.50031C0.5 4.08197 4.08197 0.5 8.50031 0.5L8.50018 0.500081ZM6.54458 3.84996C7.48879 3.46807 8.56732 3.30868 9.52063 3.7517C10.3872 4.15367 10.9245 4.96408 10.9675 5.91733C11.0187 7.045 10.3696 8.04938 9.49257 8.6999C9.34572 8.82217 9.24847 8.96902 9.18683 9.14895C9.12671 9.32685 9.19082 9.50778 9.18733 9.69125C9.17732 10.1649 8.78587 10.5418 8.31174 10.5328C7.83812 10.5238 7.46022 10.1313 7.47023 9.65718C7.47871 9.24824 7.4191 9.00363 7.55841 8.59565C7.74784 8.04036 8.01898 7.70706 8.46505 7.32314C8.87652 7.01792 9.27546 6.53479 9.25043 5.99248C9.23687 5.68928 9.07602 5.44069 8.79987 5.31291C8.29717 5.07983 7.68822 5.24271 7.20161 5.44373C6.18221 5.8637 5.51605 4.26544 6.54458 3.84996ZM8.22512 11.4038C8.80897 11.4038 9.28107 11.8769 9.28107 12.4603C9.28107 13.0441 8.80897 13.5167 8.22512 13.5167C7.64126 13.5167 7.16864 13.0441 7.16864 12.4603C7.16864 11.8769 7.64126 11.4038 8.22512 11.4038Z"
            fill="white" />
    </svg>
    <!--Published-->
    <svg *ngSwitchCase="3" width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.4407 8.63418L3.21015 5.37488C2.72199 4.88237 1.92126 4.88238 1.43308 5.37487L1.43308 5.37488L1.21557 5.59432C0.728142 6.08609 0.728146 6.89164 1.21557 7.38343L1.21557 7.38343L5.49794 11.7039C6.01602 12.2266 6.86527 12.2266 7.38331 11.7039L15.7844 3.22801C16.2719 2.73625 16.2719 1.93069 15.7844 1.4389L15.6782 1.54424L15.7844 1.4389L15.5669 1.21946C15.3227 0.973082 15.0002 0.85 14.6784 0.85C14.3566 0.85 14.0341 0.973081 13.7899 1.21946L6.4407 8.63418Z"
            fill="white" stroke="white" stroke-width="0.3" />
    </svg>
    <!--Waiting for validation-->
    <div *ngSwitchCase="4" style="display: flex; align-items: center; justify-content: center;">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.25 7.5C0.25 3.3579 3.6079 0 7.75 0C11.8921 0 15.25 3.3579 15.25 7.5C15.25 11.6421 11.8921 15 7.75 15C3.6079 15 0.25 11.6421 0.25 7.5ZM8.5 3.75C8.5 3.3358 8.1642 3 7.75 3C7.3358 3 7 3.3358 7 3.75V6.7221C7 6.897 6.99994 7.0753 7.0143 7.22976C7.03082 7.4069 7.06967 7.60506 7.17754 7.80644C7.2853 8.00788 7.42855 8.15008 7.56684 8.26212C7.68736 8.35979 7.83573 8.45865 7.98133 8.55567L9.58393 9.62412C9.92853 9.85386 10.3942 9.7607 10.624 9.41605C10.8538 9.07146 10.7606 8.60575 10.416 8.37595L8.83392 7.32132C8.65902 7.20466 8.57059 7.14483 8.51112 7.09667L8.50819 7.09426L8.50778 7.09045C8.50069 7.01422 8.49993 6.90747 8.49993 6.69723L8.5 3.75Z"
                fill="white" />
        </svg>
        <svg style="position: absolute;top: 4px;right: 2px; width: 8px; height: auto;" width="9" height="8"
            viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.39455 1.70088L6.83845 2.14086L6.39455 1.70088L3.60763 4.51265L2.60546 3.50156C2.17483 3.0671 1.47057 3.06711 1.03995 3.50155L1.03994 3.50156L0.945683 3.59665C0.945683 3.59665 0.945683 3.59665 0.945682 3.59665C0.518098 4.02804 0.518118 4.72898 0.94567 5.16036L0.945683 5.16038L2.80138 7.03259C3.24494 7.48011 3.97024 7.48015 4.41383 7.03259C4.41384 7.03258 4.41384 7.03257 4.41385 7.03256L8.05432 3.35969C8.4819 2.9283 8.48188 2.22737 8.05433 1.79598L8.05432 1.79597L7.96006 1.70088C7.74424 1.48313 7.4591 1.375 7.17731 1.375C6.89548 1.375 6.61036 1.48314 6.39455 1.70088Z"
                fill="white" stroke="#614A66" stroke-width="1.25" />
        </svg>
    </div>
    <!--Wainting for revision-->
    <div *ngSwitchCase="6" style="display: flex; align-items: center; justify-content: center;">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.25 7.5C0.25 3.3579 3.6079 0 7.75 0C11.8921 0 15.25 3.3579 15.25 7.5C15.25 11.6421 11.8921 15 7.75 15C3.6079 15 0.25 11.6421 0.25 7.5ZM8.5 3.75C8.5 3.3358 8.1642 3 7.75 3C7.3358 3 7 3.3358 7 3.75V6.7221C7 6.897 6.99994 7.0753 7.0143 7.22976C7.03082 7.4069 7.06967 7.60506 7.17754 7.80644C7.2853 8.00788 7.42855 8.15008 7.56684 8.26212C7.68736 8.35979 7.83573 8.45865 7.98133 8.55567L9.58393 9.62412C9.92853 9.85386 10.3942 9.7607 10.624 9.41605C10.8538 9.07146 10.7606 8.60575 10.416 8.37595L8.83392 7.32132C8.65902 7.20466 8.57059 7.14483 8.51112 7.09667L8.50819 7.09426L8.50778 7.09045C8.50069 7.01422 8.49993 6.90747 8.49993 6.69723L8.5 3.75Z"
                fill="white" />
        </svg>
        <svg style="position: absolute;top: 5px;right: 2px; width: 9px; height: auto;" viewBox="0 0 8 5" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.61159 1.96545L7.61165 1.96541L7.60788 1.96056C7.30102 1.566 6.14857 0.25 4.25264 0.25C2.35718 0.25 1.20485 1.56535 0.897639 1.96025C0.700877 2.21194 0.700777 2.5561 0.897403 2.80785C1.20426 3.20241 2.35671 4.51841 4.25264 4.51841C6.14858 4.51841 7.30101 3.2024 7.60787 2.80786L7.60836 2.80723C7.79872 2.56088 7.79464 2.21314 7.61159 1.96545ZM2.1357 2.68396C2.01525 2.57678 1.91503 2.4742 1.83561 2.38615C1.91404 2.29877 2.01462 2.19636 2.13604 2.08911C2.12213 2.18675 2.11491 2.28646 2.11491 2.38772C2.11491 2.48817 2.12201 2.58708 2.1357 2.68396ZM4.25264 3.5272C3.6258 3.5272 3.11317 3.01457 3.11317 2.38772C3.11317 1.76088 3.6258 1.24825 4.25264 1.24825C4.87948 1.24825 5.39211 1.76088 5.39211 2.38772C5.39211 3.01457 4.87948 3.5272 4.25264 3.5272ZM6.67003 2.38573C6.59055 2.47386 6.49021 2.57659 6.36958 2.68394C6.38327 2.58706 6.39037 2.48815 6.39037 2.38771C6.39037 2.28531 6.38299 2.1845 6.36877 2.08581C6.49044 2.1937 6.59131 2.29731 6.67003 2.38573Z"
                fill="white" stroke="#614A66" stroke-width="0.5" />
            <path
                d="M4.25401 3.58419C4.91529 3.58419 5.45138 3.0481 5.45138 2.38682C5.45138 1.72554 4.91529 1.18945 4.25401 1.18945C3.59273 1.18945 3.05664 1.72554 3.05664 2.38682C3.05664 3.0481 3.59273 3.58419 4.25401 3.58419Z"
                fill="white" stroke="#614A66" stroke-width="0.5" />
        </svg>
    </div>
    <!--Status unknown-->
    <svg *ngSwitchDefault width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 11V10.9929M3.99999 8.85714C3.99999 5.64286 6.99999 6.35714 6.99999 3.85714C6.99999 2.27919 5.65684 1 3.99999 1C2.6567 1 1.51961 1.84083 1.13733 3"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</ng-container>