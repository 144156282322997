import * as tslib_1 from "tslib";
/**
 * Created by aleksandr on 7.08.17.
 */
import { ActiveRecord } from '../../models/active-record.class';
import { ChatService } from '../chat.service';
import { ServiceLocator } from '../../services/service-locator';
var Thread = /** @class */ (function (_super) {
    tslib_1.__extends(Thread, _super);
    function Thread() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.messages = [];
        _this.contacts = [];
        _this.provider = ChatService;
        _this.service = ServiceLocator.injector.get(ChatService);
        return _this;
    }
    Thread.prototype.loadContacts = function () {
        return this.service.getRoomParticipants(this.id);
    };
    Thread.prototype.readThread = function () {
        return this.service.readThread(this.id);
    };
    Thread.prototype.loadMessages = function () {
        return this.service.loadHistory(this.id);
    };
    Thread.prototype.sendMessage = function (text) {
        return this.service.sendMessage(this.id, text);
    };
    Thread.prototype.fields = function () {
        return ['id', 'user_id'];
    };
    Thread.prototype.extractData = function (data) {
        //TODO temp
        if (data.hasOwnProperty('thread_id')) {
            data['id'] = data['thread_id'];
        }
        return _super.prototype.extractData.call(this, data);
    };
    return Thread;
}(ActiveRecord));
export { Thread };
