export enum SUPERVISOR_STATISTICS_SEGMENTS {
  DEPOSITS = 1,
  NEEDS = 2,
  TRANSFORMATIONS = 3
}

export const SUPERVISOR_STATISTICS_SEGMENTS_VIEW = {
  1: 'Deposits',
  2: 'Needs',
  3: 'Transformations'
};
