import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformPackagingJunction } from '../models/transform-packaging-junction.class';
var TransformPackagingJunctionService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformPackagingJunctionService, _super);
    function TransformPackagingJunctionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-packagings-junctions';
        _this.namespaceSingular = 'transformation-packagings-junction';
        _this.ModelClass = TransformPackagingJunction;
        return _this;
    }
    return TransformPackagingJunctionService;
}(CrudService));
export { TransformPackagingJunctionService };
