/**
 * Created by atat on 25/06/2017.
 */
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ViewOfferComponent } from './components/view-offer/view-offer.component';
import { EcosystemOfferService } from './ecosystem-offer.service';
import { CommonModule } from '@angular/common';
import { PassportModule } from '../passport/passport.module';
import { OfferTransactionConfirmComponent } from './components/offer-transaction-confirm/offer-transaction-confirm.component';
import { OfferTransactionConfirmDialogComponent
  } from './dialogs/offer-transaction-confirm-dialog/offer-transaction-confirm-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    PassportModule
  ],
  declarations: [
    ViewOfferComponent,
    OfferTransactionConfirmComponent,
    OfferTransactionConfirmDialogComponent
  ],
  exports: [ViewOfferComponent],
  providers: [
    EcosystemOfferService
  ],
  entryComponents: [OfferTransactionConfirmDialogComponent],
})
export class EcosystemOfferModule {
}
