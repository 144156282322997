/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contact } from '../../model/contact.class';
import { ContactService } from '../../service/contact.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'client-contact-form',
  templateUrl: 'contact-form.component.html',
  styleUrls: ['contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  @Input() public clientId?: number;
  @Input() public asDialog?: boolean = false;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  contactForm: FormGroup;
  contact: Contact;
  created: boolean;

  constructor(private _fb: FormBuilder,
              private _contactService: ContactService,
              private _translate: TranslateService) {
  }

  ngOnInit() {
    this.created = false;
    this.contact = new Contact;
    this.contact.client_id = this.clientId;
    this.contactForm = this._fb.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      function: [''],
      phone: [''],
      email: [''],
    });

    this._translate.get('New Contact').subscribe((translation) => {
      this.contact.name = translation;
    });
  }

  save(contactForm: FormGroup): boolean {
    if (this.created === true) {
      console.log(this.created);
      this._contactService.update(this.contact.id, this.contact).subscribe(
        result => {
          this.contact = result;
          this.contactForm.markAsPristine();
          console.log(result);
        }
      );
    } else {
      console.log(this.created);
      this._contactService.create(this.contact).subscribe(
        result => {
          this.contact.set(result);
          this.created = true;
          this.contactForm.markAsPristine();
          console.log(result);
          this.closeDialog();
        },
        err => console.log(err)
      );
    }
    return false;
  }

  closeDialog() {
    this.onSave.emit(this.contact);
  }

}
