import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';
import {MdTooltip, TooltipPosition} from '@angular/material';
import {Subject} from 'rxjs';
import { AbstractTooltipPrefix } from './abstract-tooltip-prefix';

/**
 * This directive is work around to support customization for tooltips for @angular/material library version 2.0.0-beta.3.
 * Please remove or rewrite this directive if another library is used for tooltips or if @angular/material library is upgraded.
 *
 */
@Directive({
  selector: '[appCustomTooltip]'
})
export class CustomTooltipDirective extends AbstractTooltipPrefix {
  @Input() tooltipClass: string[];
  @Input() validateOnlyScrolled = false;

  @HostListener('mouseenter')
  addStyleToTooltip() {
    setTimeout(() => this.addStyle());
  }

  constructor (
    private parentElem: ElementRef,
    private renderer: Renderer2,
    public materialTooltip: MdTooltip) {
      super();
      this.materialTooltip.hideDelay = 0;
    }

  private addStyle() {
    const nodeList = this.getElements();
    if (this.validate() && !!nodeList.length) {
      this.tooltipClass.forEach(className =>
        this.renderer.addClass(nodeList[nodeList.length - 1], className));
      this.onInitTooltipPositionBugWorkaround();
    } else {
      this.materialTooltip.hide(0);
    }
  }
  private getElements() {
    const elem = this.getCdkContainer(this.materialTooltip);
    return elem ? elem.querySelectorAll('.mat-tooltip') : [];
  }
  private validate() {
    const parent = this.parentElem.nativeElement;
    if (this.validateOnlyScrolled) {
      return parent.scrollWidth > parent['offsetWidth'];
    }
    return true;
  }
  private onInitTooltipPositionBugWorkaround() {
    setTimeout(() => {
      this.updatePosition(this.materialTooltip);
    });
  }

}
