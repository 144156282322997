import {Component, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import { Transform } from '../../../../../entities/transformation/models/transform.class';
import { TransformServiceArea } from '../../../../../entities/transformation/models/transform-service-area.class';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';
import {NgModel} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'service-area',
  templateUrl: 'service-area.component.html',
  styleUrls: ['service-area.component.scss']
})
export class ServiceAreaComponent implements OnInit, OnDestroy {
  @Input() transform: Transform;
  @Input() disabled: boolean;
  @Input() validationListener;
  @ViewChildren(NgModel) inputs: QueryList<NgModel>;
  private subscription = new Subscription();
  validAdress = true ;
  constructor(  private logNotificationService: LogNotificationService, private _translate: TranslateService) {
  }
  ngOnInit() {
    this.guaranteeAtLeastOne();
    if (this.validationListener) {
      this.subscription.add(this.validationListener.subscribe(() => {
          this.inputs.filter(item => {
            return item.name === 'site';
          }).forEach(item => {
            item.control.markAsTouched();
          });
        }
      ));
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  guaranteeAtLeastOne() {
    if (this.transform.sectors.length === 0) {
      this.append();
    }
  }

  append() {
    let sector = new TransformServiceArea({
      transformation_id: this.transform.id,
    });
    this.transform.sectors.push(sector);
  }

  remove(sector: TransformServiceArea) {
    if (sector.id) {
      sector.destroy().subscribe(() => {
      });
    }

    let index = this.transform.sectors.indexOf(sector);
    this.transform.sectors.splice(index, 1);

    this.guaranteeAtLeastOne();
  }

  onTextChange (sector){

    this.validAdress =false;
    this.save(null , sector);
  }

  save(place: google.maps.places.PlaceResult, sector: TransformServiceArea) {


    if (place && place.geometry) {
      this.validAdress =true ;
    }
    else {
      this.validAdress =false
    }



    if (this.validAdress  ) {
      sector.save().subscribe((res) => {
        if (res.id) {
          sector.id = res.id;
        }
      });
    } else {
      if (sector.id) {
        sector.destroy().subscribe(() => {
          sector.id = null;
        });
      }

    }
  }
}
