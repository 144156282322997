/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MdDialog } from '@angular/material';
import { Deposit } from '../../../models/deposit.class';
import { DepositLocation } from '../../../models/deposit-location.class';
import 'rxjs/add/operator/merge';
import { MapDialogComponent } from '../../../dialogs/view-map/view-map.component';
import { DepositLocationService } from '../../../services/deposit-location.service';
import { MapsAPILoader } from '@agm/core';
import { LOCATE_ME_INACCURATE_WARNING_DISTANCE } from '../../../values/locate-me-inaccurate-warning-distance.const';
import { DepositSiteDetailsDialogComponent } from '../../../../../entities/deposit/dialogs/deposit-site-details-dialog/deposit-site-details-dialog.component';
import { OrganisationTIds } from '../../../../../shared/values/company-values.enum';
import { DepositControlStates } from '../../../models/deposit-control-states.class';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { TranslatePipe } from '@ngx-translate/core';
import { DepositTagLocationJunctionService } from '../../../services/deposit-tag-location-junction.service';
import { DepositTagLocationJunction } from '../../../models/deposit-tag-location-junction.class';
import { PermissionService } from '../../../../../shared/services/permission.service';
import { EAgentPermission } from '../../../../../dashboards/agent/values/agent-permission.enum';
var LocationStepComponent = /** @class */ (function () {
    function LocationStepComponent(dialog, _permission, locationService, mapsAPILoader, translatePipe, notification, tagLocationJunctionService) {
        this.dialog = dialog;
        this._permission = _permission;
        this.locationService = locationService;
        this.mapsAPILoader = mapsAPILoader;
        this.translatePipe = translatePipe;
        this.notification = notification;
        this.tagLocationJunctionService = tagLocationJunctionService;
        this.filteredTags = [];
        this.chosenTagJunctions = [];
        this.isSearchActive = false;
        this.isTagsLoading = false;
        this.availableTags = [];
        this.service = locationService;
    }
    LocationStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.deposit.onChangeLocation
            // .switchMap((client:Client) => this.locationService.get<ClientLocation>({client_id: client.id}))
            .subscribe(function (location) {
            _this.depositLocation = location;
            _this.updateAvailableSites();
        });
        if (this.deposit.deposit_location_id > 0) {
            if (this.deposit.deposit_location && this.deposit.deposit_location.tags) {
                this.depositLocation = this.deposit.deposit_location;
                this.updateAvailableSites();
            }
            else {
                this.depositLocation = new DepositLocation({ id: this.deposit.deposit_location_id });
                this.depositLocation.load({ expand: 'tags' }).subscribe(function (res) {
                    _this.deposit.setLocation(new DepositLocation(res));
                });
            }
            this.tagLocationJunctionService.get({ deposit_id: this.deposit.id }).subscribe(function (tagJunctions) {
                _this.chosenTagJunctions = tagJunctions;
                _this.updateAvailableSites();
            });
        }
        else {
            this.deposit.setLocation(new DepositLocation());
        }
        /*this.deposit.beforeSave$.subscribe(() => {
          if (this.depositLocation.name) {
            this.saveDepositLocation();
          }
        });*/
        if (this.listener) {
            this.listener.subscribe(function (event) {
                if (event.key === 'changeDeposit') {
                    _this.deposit = event.data;
                }
            });
        }
        this.initGeocoder();
    };
    LocationStepComponent.prototype.ngOnDestroy = function () {
        if (!!this.depositLocation.name) {
            this.saveDepositLocation();
        }
    };
    LocationStepComponent.prototype.updateAvailableSites = function () {
        var chosenIds = new Set(this.chosenTagJunctions.map(function (item) { return item.tag_id; }));
        this.availableTags = this.depositLocation.tags ? this.depositLocation.tags.filter(function (item) { return !chosenIds.has(item.id); }) : [];
        this.filteredTags = this.availableTags;
    };
    LocationStepComponent.prototype.saveDepositLocation = function () {
        // if(locationForm.dirty===true){}
        // this.deposit.deposit_location=this.depositLocation;
        this.deposit.deposit_location.save().subscribe(
        // (res) => {
        //  console.log('RES', res, 'Deposit Location', this.deposit.deposit_location);
        //  this.onSaveLocation(res);
        //  this.deposit.setLocation(res);
        //  this.onSave.emit(new DepositLocation(res));
        // }
        );
    };
    LocationStepComponent.prototype.initGeocoder = function () {
        var _this = this;
        this.mapsAPILoader.load().then(function () {
            _this.geocoder = new google.maps.Geocoder;
        }, console.error);
    };
    Object.defineProperty(LocationStepComponent.prototype, "isRestrictedAgent", {
        get: function () {
            return this._permission.hasAppliedPermission(EAgentPermission.RESTRICTED_AGENT);
        },
        enumerable: true,
        configurable: true
    });
    LocationStepComponent.prototype.onSelect = function (location) {
        this.onSaveLocation(location);
        // this.onSave.emit(this.depositLocation);
    };
    LocationStepComponent.prototype.onCreate = function () {
        var _this = this;
        this.dialog.open(DepositSiteDetailsDialogComponent, { data: { type: OrganisationTIds.Territory } }).afterClosed().subscribe(function (location) { return _this.onSaveLocation(location); });
    };
    LocationStepComponent.prototype.onSaveLocation = function (depositLocation, backup) {
        //  console.log('depositLocation', depositLocation);
        this.chosenTagJunctions = this.deposit.deposit_location.id === depositLocation.id ? this.chosenTagJunctions : [];
        this.deposit.setLocation(depositLocation);
        this.depositLocation = depositLocation;
        this.tryToUpdateDepositIfTagsChanged(backup);
        // this.mode = 'view';
    };
    LocationStepComponent.prototype.tryToUpdateDepositIfTagsChanged = function (backup) {
        var _this = this;
        if (!this.isTagsLoading) {
            this.isTagsLoading = true;
            backup = backup || this.createBackup();
            this.deposit.save().subscribe(function () {
                _this.isTagsLoading = false;
            }, function () {
                _this.isTagsLoading = false;
                _this.restoreLocation(backup);
            });
        }
    };
    LocationStepComponent.prototype.createBackup = function () {
        return {
            location: this.depositLocation,
            junction: this.chosenTagJunctions
        };
    };
    LocationStepComponent.prototype.restoreLocation = function (backup) {
        this.depositLocation = backup.location;
        this.chosenTagJunctions = backup.junction;
    };
    LocationStepComponent.prototype.locateMe = function () {
        var _this = this;
        navigator.geolocation.getCurrentPosition(function (position) {
            var coords = position.coords;
            if (coords.accuracy > LOCATE_ME_INACCURATE_WARNING_DISTANCE) {
                _this.notification.error(_this.translatePipe.transform('YOUR_LOCATION', { X: coords.accuracy }));
            }
            _this.depositLocation.lat = coords.latitude;
            _this.depositLocation.lng = coords.longitude;
            if (!_this.geocoder) {
                return;
            }
            _this.geocoder.geocode({
                'location': {
                    lat: coords.latitude,
                    lng: coords.longitude,
                }
            }, function (results, status) {
                if (status === 'OK') {
                    var result = results[0];
                    if (result) {
                        _this.depositLocation.address = result.formatted_address;
                    }
                }
                else {
                    console.error('Geocoder failed due to: ' + status);
                }
            });
        }, function (error) {
            console.error(error);
            _this.notification.error(error.message, null, true);
        }, {
            enableHighAccuracy: true
        });
    };
    LocationStepComponent.prototype.viewMap = function () {
        var _this = this;
        var dialogRef = this.dialog.open(MapDialogComponent, {
            data: {
                coords: {
                    lat: this.depositLocation.lat,
                    lng: this.depositLocation.lng,
                },
                address: this.depositLocation.address,
            }
        });
        dialogRef.afterClosed().subscribe(function (data) {
            if (!data) {
                return;
            }
            if (data.depositLocation) {
                _this.deposit.setLocation(data.depositLocation);
            }
            else if (data.coords && data.coords.lat && data.coords.lng && data.address !== undefined) {
                _this.depositLocation.lat = data.coords.lat;
                _this.depositLocation.lng = data.coords.lng;
                _this.depositLocation.address = data.address;
            }
        });
    };
    LocationStepComponent.prototype.onChangeName = function (name) {
        if (this.depositLocation && this.depositLocation.name !== name) {
            if (this.isRestrictedAgent) {
                this.onResetLocation(name);
            }
            else {
                this.onEditLocationName(name);
            }
        }
    };
    LocationStepComponent.prototype.onEditLocationName = function (name) {
        this.depositLocation.name = name;
        this.deposit.onChangeLocation.emit(this.depositLocation);
    };
    LocationStepComponent.prototype.onResetLocation = function (name) {
        this.deposit.onChangeLocation.emit(new DepositLocation({ name: name }));
    };
    LocationStepComponent.prototype.onPlaceChange = function (place) {
        if (place.geometry) {
            this.depositLocation.lat = place.geometry.location.lat();
            this.depositLocation.lng = place.geometry.location.lng();
        }
        else {
            this.depositLocation.lat = null;
            this.depositLocation.lng = null;
        }
    };
    LocationStepComponent.prototype.closeTag = function (tag) {
        var _this = this;
        tag.destroy().subscribe(function () {
            _this.chosenTagJunctions = _this.chosenTagJunctions.filter(function (item) { return tag.id !== item.id; });
            _this.updateAvailableSites();
        });
    };
    LocationStepComponent.prototype.openSuggestion = function () {
        var _this = this;
        this.isSearchActive = true;
        setTimeout(function () { return _this.tagInput.nativeElement.focus(); });
    };
    LocationStepComponent.prototype.filterTagSuggestion = function () {
        var _this = this;
        this.filteredTags = this.availableTags.filter(function (item) { return item['name'].toLocaleLowerCase()
            .indexOf(_this.tagSearch.toLocaleLowerCase()) !== -1; });
    };
    LocationStepComponent.prototype.resetTagSearch = function () {
        this.tagSearch = '';
        this.isSearchActive = false;
        this.filteredTags = this.availableTags;
    };
    LocationStepComponent.prototype.addTag = function (tag) {
        var _this = this;
        if (!this.isTagsLoading) {
            var current_1 = this.depositLocation;
            new DepositTagLocationJunction({ tag_id: tag.id, deposit_id: this.deposit.id }).save().subscribe(function (savedJunction) {
                if (!_this.isTagsLoading && _this.depositLocation.id === current_1.id) {
                    _this.chosenTagJunctions.push(savedJunction);
                    _this.updateAvailableSites();
                }
            });
            this.resetTagSearch();
        }
    };
    return LocationStepComponent;
}());
export { LocationStepComponent };
