import { Injectable } from '@angular/core';
import { IMaterialHealthPieChart } from '../components/admin-reporting/new-report/material-health/values/material-heath-pie-chart-data.interface';
import { IMaterialHealthCategory, IMaterialHealthAggregate } from '../interfaces/material-health.interface'

@Injectable()
export class MaterialPieCharData {

private sortByValueDesc = (object: {[key: string]: number}): any => Object.entries(object)
  .sort(([, v1], [, v2]) => +v1 - +v2)
  .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});  

private getCharData(product: IMaterialHealthCategory, mainData: IMaterialHealthAggregate): {controlled: number, verify: number, unknown: number, known: number, healthy: number} {
  if (product) {
    return {controlled: product.percentage_of_controlled_toxicity
      , verify: product.percentage_of_controlled_verified_toxicity
      , known: product.percentage_of_known_toxicity
      , unknown: product.percentage_of_unknown_toxicity
      , healthy: product.percentage_of_healthy_product}
  } else if (mainData) {
    return {controlled: mainData.percentage_weight_of_controlled_toxicity
      , verify: mainData.percentage_weight_of_controlled_verified_toxicity
      , known: mainData.percentage_weight_of_known_toxicity
      , unknown: mainData.percentage_weight_of_unknown_toxicity
      , healthy: mainData.percentage_weight_of_healthy_product}
  }
  return null;
}  

getRoundPieChartData( data: {product?: IMaterialHealthCategory, mainData?: IMaterialHealthAggregate}): IMaterialHealthPieChart {
    const product = data.product;
    const mainData = data.mainData;
    const pieCharData = this.getCharData(product, mainData);
    return pieCharData ? {
        data: this.sortByValueDesc({
          percentage_weight_of_controlled_toxicity: Math.round(pieCharData.controlled * 10) / 10,
          percentage_weight_of_controlled_verified_toxicity: Math.round(pieCharData.verify * 10) / 10,
          percentage_weight_of_unknown_toxicity: Math.round(pieCharData.unknown * 10) / 10,
          percentage_weight_of_known_toxicity: Math.round(pieCharData.known * 10) / 10,
          percentage_weight_of_healthy_product: Math.round(pieCharData.healthy * 10) / 10 }),
        category: product ? {
          name: product.category.name,
          percentTotalWeight: product.category_mass_percentage,
          weight: product.weight
        } : undefined
      }
      : null;
  }
}