import { DepositOrderService } from '../services/deposit-order.service';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { OrderPassport } from './order-passport.class';
import { IOrder } from '../interfaces/order.interface';
import { Deposit } from '../../../entities/deposit/models/deposit.class';
import { DepositLocation } from '../../../entities/deposit/models/deposit-location.class';
import { NomenclatureItem } from '../../../entities/nomenclature/models/nomenclature-item.class';
import { ECOSYSTEM_STEP_STATUS } from '../values/ecosystem-step-status.const';
import { Passport } from '../../../entities/passport/models/passport.class';
import { DepositPhoto } from '../../../entities/deposit/models/deposit-photo.class';
import { EcosystemFileJunction } from './ecosystem-file-junction.class';
import { Ecosystem } from './ecosystem.class';
import { User } from '../../../shared/models/user.class';
import {EcosystemStep} from './ecosystem-step.class';
import {EcosystemProblem} from '../../../entities/ecosystem/models/ecosystem-problem.model';

export class DepositOrder extends ExtendedActiveRecord implements IOrder {
  protected provider = DepositOrderService;

  public id: number;
  public deposit_id: number;
  public order_passport_id: number;
  public step_id: number;
  public transaction_price: number;
  public transaction_description: string;
  public price: number;
  public status: number;
  public transaction_date: string;
  public transaction_quantity: string;
  public confirmation_date: string;
  public publication_date: string;
  public created_at: string;
  public updated_at: string;
  public order_passport: OrderPassport;
  public description: string;
  public creator: string;
  public deposit: Deposit;
  public deposit_passport: Passport;
  public deposit_location: DepositLocation;
  public nomenclature: NomenclatureItem[];
  public deposit_photos: DepositPhoto[];
  public ecosystem_file_junction: EcosystemFileJunction[];
  public ecosystem_step: EcosystemStep;
  public ecosystem: Ecosystem;
  public owner: User;
  public DO_price: number;

  public problem?: any;
  public modification?: string;
  public address: {address: string, lng: number, lat: number};

  constructor(data: any = {}) {
    super(data);
    this.order_passport = new OrderPassport(data.order_passport);
    this.deposit_location = new DepositLocation(data.deposit_location);
    this.deposit = new Deposit(Object.assign(data.deposit || {}, {
      passport: data.deposit_passport
    }));
    if (data.ecosystem_step) {
      this.ecosystem_step = new EcosystemStep(data.ecosystem_step);
    }
    this.nomenclature = (data.nomenclature || []).map(
      (nomenclatureItem: any) => new NomenclatureItem(nomenclatureItem)
    );
    this.ecosystem = data.ecosystem && new Ecosystem(data.ecosystem);
    this.ecosystem_file_junction = data.ecosystem_file_junction
      ? data.ecosystem_file_junction.map((item: any) => new EcosystemFileJunction(item))
      : [];
    this.owner = new User(data.owner);
    this.deposit_passport = data.deposit_passport ? new Passport(data.deposit_passport) : null;
    this.problem = data.problem ? new EcosystemProblem(data.problem) : null;
  }

  protected fields() {
    return [
      'id',
      'deposit_id',
      'order_passport_id',
      'step_id',
      'transaction_price',
      'price',
      'status',
      'transaction_date',
      'transaction_description',
      'confirmation_date',
      'publication_date',
      'created_at',
      'updated_at',
      'description',
      'creator',
      'deposit_passport',
      'deposit_photos',
      'ecosystem_file_junction',
      'owner',
      'modification',
      'DO_price',
      'ecosystem_step',
      'DO_price',
      'problem',
      'address'
    ];
  }

  //TODO ECOSYSTEM_STEP_STATUS shouldn't be used here
  get status_text() {
    if (this.status) {
      return ECOSYSTEM_STEP_STATUS[this.status]
        .replace(/_/g, ' ')
        .toLowerCase()
        ;
    } else {
      return '';
    }
  }

  beforeSave(data: any) {
    if (data.status) {
      delete data.status; // avoid step.status !== order.status bug
    }
    return data;
  }
}
