<md-dialog-content>
    <div class="deposit-mass-update-warning">
        <div class="deposit-mass-update-warning__heading">
            <div class="deposit-mass-update-warning__image">
                <img src="assets/png/warning_green.png" class="deposit-mass-update-warning__triangle">
                <span class="deposit-mass-update-warning__exclamation">!</span>
            </div>
        </div>

        <div class="deposit-mass-update-warning__text-container">
            <span class="deposit-mass-update-warning__title">{{ 'MASS_UPDATE_CONFIRM_TITLE' | translate }}</span>
            <br>
            <span class="deposit-mass-update-warning__subtitle">{{ 'MASS_UPDATE_CONFIRM_SUBTITLE' | translate }}</span>
        </div>

        <div class="deposit-mass-update-warning__footer">
            <button class="deposit-mass-update-warning__button" md-raised-button
                (click)="confirm()">{{ 'Confirm' | translate }}</button>
            <button class="deposit-mass-update-warning__button gray" md-raised-button
                (click)="closeDialog()">{{ 'Cancel' | translate }}</button>
        </div>
    </div>
</md-dialog-content>
  