<article class="notification-warning-dialog" [ngClass]="cssClass">
  <dialog-close-button *ngIf="showCross"
                       class="notification-warning-dialog__close"
                       (click)="dialog.close()"
  >
  </dialog-close-button>
  <section *ngIf="iconData" class="notification-warning-dialog__icon">
    <confirm-dialog-icon
      [iconData]="iconData"
    >
    </confirm-dialog-icon>
  </section>
  <section class="notification-warning-dialog__message-wrapper">
    <h2 class="notification-warning-dialog__message-title">{{title | translate}}</h2>
    <h3 class="notification-warning-dialog__message-body">{{body}}</h3>
    <div class="notification-warning-dialog__message-body-sub" *ngIf="bodyHint">
      <common-info-sign
        class="notification-warning-dialog__message-body-sub-sign"
        [borderSizeClassModifier]="'1-half'">!</common-info-sign>
      <span class="notification-warning-dialog__message-body-sub-text primary-color">{{bodyHint | translate}}</span>
    </div>
    <ul class="notification-warning-dialog__message-warning-wrapper">
      <li *ngFor="let item of warnings" class="notification-warning-dialog__message-warning">
        <span class="notification-warning-dialog__message-warning-title">{{item.titleText}}</span>
        <span class="notification-warning-dialog__message-warning-text">{{item.text}}</span>
      </li>
    </ul>
  </section>
</article>
