import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {MdDialogRef} from '@angular/material';
import {FormControl, Validators} from '@angular/forms';
import {animate, style, transition, trigger} from '@angular/animations';
import {UserService} from '../../../../shared/services/user.service';
import {ERoleIds} from '../../../../shared/values/role-ids.enum';
import {Subscription} from 'rxjs/Subscription';
import {allowOnlyPositiveNumbers} from '../../../../shared/helpers/proceed-if-non-negative-int';

@Component({
  selector: 'request-esm-number-change-dialog',
  templateUrl: './request-esm-number-change-dialog.component.html',
  styleUrls: ['./request-esm-number-change-dialog.component.scss'],
  animations: [
    trigger('errorMessage', [
      transition(':enter', [
      style({opacity: 0, height: 0, marginTop: 0, marginBottom: 0}),
      animate('300ms cubic-bezier(0.55, 0, 0.55, 0.2)',
        style({opacity: 1, height: 1, marginTop: '*', marginBottom: '*'}))
      ])
    ])
  ]
})
export class RequestEsmNumberChangeDialogComponent implements OnInit, OnDestroy {

  availableESM: number;
  requestedESM: FormControl;
  usedESM: number;
  private requestObserver: Subscription;

  constructor(private dialog: MdDialogRef<any>, private userService: UserService) { }

  ngOnInit() {
    this.loadEsmCount().subscribe(
      (esmCount) => {
        this.availableESM = esmCount.max_esm || 0;
        this.usedESM = esmCount.esm_count || 0;
        this.requestedESM = new FormControl( this.availableESM, [
          Validators.required, Validators.min(this.usedESM)]);
        this.allowOnlyPositive();
      }, () => this.dialog.close());
  }

  private allowOnlyPositive() {
    this.requestObserver = allowOnlyPositiveNumbers(this.requestedESM,
      {onlyInt: true});
  }

  ngOnDestroy(): void {
    if (this.requestObserver) {
      this.requestObserver.unsubscribe();
    }
  }

  loadEsmCount(): Observable<any> {
    return this.userService.getRoleCounters({role: ERoleIds.ADMIN});
  }

  isSameValue() {
   return this.requestedESM.value === this.availableESM;
  }

  showErrorMessageAvailableLessThenUsed() {
    return this.requestedESM.errors && this.requestedESM.errors.min && this.requestedESM.value >= 0;
  }

  isConfirmDisabled() {
    return !!this.requestedESM.errors || this.isSameValue();
  }

  confirmRequest() {
    this.dialog.close(this.requestedESM.value);
  }

  closeDialog() {
    this.dialog.close(null);
  }

  prohibitMinusSign($event) {
    if ($event.data && $event.data.includes('-')) {
      return false;
    }
  }
}
