import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Optional, Output, ViewChild} from '@angular/core';
import { IParentEvent } from '../../../../shared/interfaces/parent-event.interface';
import { Passport } from '../../models/passport.class';
import { PASSPORT_REQUEST } from '../../values/passport-request.const';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import { PASSPORT_TRANSPARENCE_TYPES } from '../../values/passport-trasparence-types.const';
import { PASSPORT_COLOR_TYPES } from '../../values/passport-color-types.const';
import { forkJoinSafe } from '../../../../shared/helpers/fork-join-safe.helper';
import { Subscription } from 'rxjs';
import { PassportService } from '../../services/passport.service';
import { Country } from '../../../../shared/models/country.class';
import { PassportDistributionArea } from '../../models/passport-distribution-area.class';
import { CountryService } from '../../../../shared/services/country.service';
import { DistributionAreaService } from '../../services/distribution-area.service';
import {
  CustomSelectionListWithChipRecordModel
} from '../../../../shared/custom-selection-list-with-chip/custom-selection-list-with-chip-record.model';
import {take} from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'passport-distribution-areas',
  templateUrl: 'passport-distribution-area.component.html'
})
export class PassportDistributionAreaComponent implements OnInit, OnDestroy {

  @Input() public listener?: EventEmitter<IParentEvent>;
  @Input() public passport?: Passport;
  @Input() public isDisabled? = false;
  @Input() public viewMode: number;
  @Output() onSave = new EventEmitter();
  transparenceTypes = PASSPORT_TRANSPARENCE_TYPES;
  colorTypes = PASSPORT_COLOR_TYPES;
  subscription: Subscription;

  disableStatus = false;

  VIEW_MODE = VIEW_MODE;

  countries = [];
  countyRecords = [];

  distributionAreasNotSavedYet = [];
  areaRecords = [];

  constructor(public passportService: PassportService,
              public countryService: CountryService,
              public distributionAreaService: DistributionAreaService,
              @Optional() public readOnlyService: ReadOnlyService
  ) {
    this.checkedPassportDistributionAreas = this.checkedPassportDistributionAreas.bind(this);
  }

  initCountyRecords() {
    this.countyRecords = this.countries.map(
      country => CustomSelectionListWithChipRecordModel.build().setNameFunc(value => value.name).addValue(country));
  }

   updateAreaRecords() {
    this.areaRecords = this.passport.distribution_areas.map(
      area => CustomSelectionListWithChipRecordModel.build().setNameFunc(value => value.country.name).addValue(area));
  }

    getDistributionAreas() {
    this.passport.syncDistributionArea();
  }

  updateSubscription(): PassportDistributionAreaComponent {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.passport.onSyncDistributionAreas$.subscribe((records: any) => {
      this.passport.distribution_areas = [...this.passport.distribution_areas, ...this.distributionAreasNotSavedYet];
      this.updateAreaRecords();
    });

    return this;
  }

  ngOnInit() {
    this.countryService.get().subscribe((countries: Country[]) => {
      this.countries = countries;
      this.initCountyRecords();
    });
    this.disableStatus = this.isDisabled;
    if (this.passport) {
      this.changePassport(this.passport).updateSubscription().getDistributionAreas();
    }

    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if (event.key === 'changePassport') {
          this.changePassport(event.data).updateSubscription().getDistributionAreas();
        }
        if (event.key === 'disableStatus') {
          this.disableStatus = event.data.isDisabled;
        }
        if (event.key === 'savePassport') {
          this.onSave.emit({type: PASSPORT_REQUEST.DISTRIBUTION_AREAS, request: this.save()});
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  changePassport(passport: Passport): this {
    this.passport = passport;
    return this;
  }

  checkedPassportDistributionAreas(country: Country) {
    return (!!this.passport.distribution_areas &&
        !!this.passport.distribution_areas.find(a => a.country.iso === country.iso));
  }

  onPassportDistributionAreasDeleted(event: Event, distributionArea: PassportDistributionArea) {
    if (this.isDisabled) {
      return;
    }
    if (distributionArea.id) {
      this.distributionAreaService.remove(distributionArea.id).subscribe(() => {
        this.loadPassportCountries();
      });
    } else {
      this.passport.distribution_areas = this.passport.distribution_areas.filter(da => da.country.iso !== distributionArea.country.iso);
      this.updateAreaRecords();
    }
  }

  onPassportDistributionAreasChange(event: boolean, item: Country) {
    if (!!event) {
        this.passport.distribution_areas.push(new PassportDistributionArea(item));
        this.updateAreaRecords();
      } else {
        const distributionArea = this.passport.distribution_areas.find(da => da.country.iso === item.iso);
        if (distributionArea && distributionArea.id) {
          this.distributionAreaService.remove(distributionArea.id).subscribe(() => {
            this.loadPassportCountries();
          });
        } else {
          this.passport.distribution_areas = this.passport.distribution_areas.filter(da => da.country.iso !== item.iso);
          this.updateAreaRecords();
        }
    }
  }

  loadPassportCountries() {
    if (!this.passport.id) {
      return;
    }
    this.distributionAreasNotSavedYet = this.passport.distribution_areas.filter(da => !da.id);
    this.getDistributionAreas();
  }

  save() {
    if (this.passport && this.passport.id) {
      const requests = this.passport.distribution_areas.map((distribution_area) => {
        this.saveDistributionAreas(distribution_area);
      });
      return forkJoinSafe<PassportDistributionArea>(requests);
    }
  }

  saveDistributionAreas(distribution_area: PassportDistributionArea) {
    if (!distribution_area.id) {
      this.passportService.savePassportDistributionArea(this.passport.id,
      {country_iso: distribution_area.country.iso})
      .subscribe((country) => {
        return country;
      });
    }
  }
}
