import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { TransformFileService } from '../../../../entities/transformation/services/transform-file.service';
import { Router } from '@angular/router';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { TRANSFORM_STATUSES } from '../../values/transform-statuses.const';
import { TransformService } from '../../../../entities/transformation/services/transform.service';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {MdDialog} from '@angular/material';
import {TransformationModificationDialogComponent
} from '../../../../entities/transformation/transformation-modification-dialog/transformation-modification-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { TRANSFORM_TYPES } from '../../values/transform-types.const';
import {TAKE_BACK_MODALITIES} from '../../values/take-back-modalities.const';
import {PASSPORT_UNITS} from '../../../../entities/passport/values/passport-units.const';
import {TransformMaterial} from '../../../../entities/transformation/models/transform-material.class';
import {ViewNomenclatureDialogComponent} from '../../../../entities/passport/dialogs/view-nomenclature/view-nomenclature.component';
import {ViewCategoryDialogComponent} from '../../../../entities/passport/dialogs/view-category/view-category.component';
import {CategoryItem} from '../../../../entities/category/models/category-item.class';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {TransformationCategoryService} from '../../../../entities/category/service/transformation-category.service';
import {CategoryService} from '../../../../entities/category/service/category.service';
import {ITranslationRequest, ITranslationResponse} from '../../../../entities/passport/services/translation.interface';
import {TranslationService} from '../../../../shared/services/translation.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'transform-details',
  templateUrl: 'transform-details.component.html',
  styleUrls: ['transform-details.component.scss'],
  providers: [ReadOnlyService],
})
export class TransformDetailsComponent implements OnInit {

  @Input() id: number;
  @Input() transform: Transform;
  @Input() readOnly = false;
  @Input() showValidationButtons: boolean;
  @Output() action = new EventEmitter();

  @ViewChild('englishNameInput') englishNameInput: ElementRef;
  public modificationMessage: string;
  public form: FormGroup;

  TRANSFORM_STATUSES = TRANSFORM_STATUSES;
  moduleNames = ModuleNames;
  parentModule: string;
  filters = TAKE_BACK_MODALITIES;
  units: any[] = PASSPORT_UNITS;
  validateChild$ = new EventEmitter();

  constructor(
              public transformFileService: TransformFileService,
              public readOnlyService: ReadOnlyService,
              public transformService: TransformService,
              protected _navigationService: NavigationService,
              private fb: FormBuilder,
              private logNotificationService: LogNotificationService,
              private router: Router,
              private _mdDialog: MdDialog,
              private _translate: TranslateService,
              private transformationCategoryService: TransformationCategoryService,
              private categoryService: CategoryService,
              private translationService: TranslationService) {}

  removeMaterial(material: TransformMaterial) {
    material.destroy().subscribe(() => {
      const index = this.transform.materials.indexOf(material);
      if (index !== -1) {
        this.transform.materials.splice(index, 1);
      }
    });
  }
  addMaterial(isInput: number) {
    console.log('addMaterial', isInput);
    const dialogRef = this._mdDialog.open(ViewNomenclatureDialogComponent, {
      height: '80%',
      width: '80%',
    });

    dialogRef.afterClosed().subscribe(nomenclature => {
      if (nomenclature) {
        const material = new TransformMaterial();

        material.is_input = isInput;
        material.transformation_id = this.transform.id;
        material.nomenclature_id = nomenclature.id;
        material.name = nomenclature.name;

        material.save().subscribe((res) => {
          material.id = res.id;
          this.transform.materials.push(material);
        });
      }
    });
  }
  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      englishName: ['', Validators.required]
    });
    this.readOnlyService.readOnly = this.readOnly;
    this.parentModule = this._navigationService.getModuleName();

    if (!this.transform && this.id) { // fix for dialogs (dialogs don't send object, only id)
      this.transformService.view(
            this.id,
            {'expand': 'conditions,tags,materials,locations,files,nomenclature,sectors,formats,quantities,qualities,packagings,categories'}
            ).subscribe((res: Transform) => {
            this.transform = res;
            this.transform.loaded = true;
            if (this.parentModule === ModuleNames.SPECIAL_ADMIN
              && this.transform.status === TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.value) {
              this.showValidationButtons = true;
            }
            this.fetchAndSetModificationMessage();
        });
    }else {
      this.transform.loaded = true;
      this.fetchAndSetModificationMessage();
    }
  }

  fetchAndSetModificationMessage(): void {
    if (this.transform.status !== this.TRANSFORM_STATUSES.ASK_FOR_MODIFICATION.value) {
      return;
    }
    this.transformService.getModificationMessage(this.transform.id).subscribe((resp: any) => {
      this.modificationMessage = resp[0].message;
    });
  }

  save() {
    this.transform.save().subscribe(() => {
      this.action.emit();
    });
  }

  remove() {
    this.transform.destroy().subscribe(() => {
      this.action.emit();
    });
  }
  // if services area false then storing sites true
  // if services area true then storing sites false
  checkIfServicesArea (transformationType: number ) {
    const trans_type = TRANSFORM_TYPES.find(function(element) {
      return element.value === transformationType;
    });
    if (trans_type.includes.serviceArea) {
      return true;
    } else if (trans_type.includes.storingSites) {
      return false;
    }
    return false ;
  }
  submit() {
    this.form.markAsTouched();
    Object.values(this.form.controls).forEach(control => control.markAsTouched());
    this.validateChild$.emit();

    if (this.form.invalid) {
      if (this.form.controls['name'].invalid) {
        this.logNotificationService.error(`Cannot submit without name`, null, true);
      } else if (this.form.controls['englishName'].invalid) {
        this.logNotificationService.error(`English name cannot be blank.`, null, true);
      }
      return;
    }

  if ( this.checkIfServicesArea(this.transform.transformation_type) ) {
    if (this.transform.sectors.some(sector => sector.id == null ) ) {

      this.logNotificationService.error(
        this._translate.instant('Please select your address from suggestions'), null, true);
      return ;
    }
  }
  // not services area then it's storing sites
  if (! this.checkIfServicesArea(this.transform.transformation_type) ) {

    if (this.transform.locations.some(location => !location.site.lat || !location.site.lng )) {

      this.logNotificationService.error(
        this._translate.instant('Please select your address from suggestions'), null, true);
      return ;
    }
  }
    this.transform.publish().subscribe(() => {
      this.action.emit();
    });
  }

  reject() {
    this.transform.reject().subscribe(() => {
      this.action.emit();
    });
  }

  validate() {
    this.transform.publish().subscribe(() => {
      this.action.emit();
    });
  }

  askForModification() {
    this._mdDialog.open(TransformationModificationDialogComponent, {
      data: {
        transform: this.transform
      }
    });
  }

  isPublished() {
    return this.transform.status === TRANSFORM_STATUSES.PUBLISHED.value;
  }
  getImageClass(): string {
    let className = 'transform-details__image';
    switch (this.parentModule) {
      case ModuleNames.TSMI:
        className += ` ${className}--tsmi`;
        break;
      case ModuleNames.SUPERVISOR:
        className += ` ${className}--svisor`;
        break;
      case ModuleNames.ADMIN:
        className += ` ${className}--admin`;
        break;
      case ModuleNames.ESM:
        className += ` ${className}--esm`;
        break;
      case ModuleNames.SPECIAL_ADMIN:
        className += ` ${className}--sa`;
        break;
    }
    return className;
  }
  removeCategory(category: CategoryItem, index: number) {
    const foundCategory = this.transform.transformation_categories.find(cat => cat.category_id === category.id);
    if (!!foundCategory) {
      this.transformationCategoryService.remove(foundCategory.id)
      .subscribe(() => this.transform.categories.splice(index, 1));
    }
  }
  openCategoryDialog() {
    this._mdDialog.open(ViewCategoryDialogComponent, {
      ...DIALOG_THEME.NOMENCLATURE_PRIMARY
    }).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const category = new CategoryItem({
        parent_id: result.parent_id,
        name: result.name,
        id: result.id
      });
      this.transformationCategoryService.create({
        transformation_id: this.transform.id,
        category_id: result.id
      }).subscribe( (newCat) => {
        this.transform.transformation_categories.push(newCat);
        this.transform.categories.push(category);
       });
    });
  }
  getCategoryName(categoryName: string): string {
    const translation = this._translate.instant(categoryName);
    return (categoryName.length > 7)
      ? translation.substr(0, 7) + '…'
      : translation;
  }
  isRejectButtonShown(): boolean {
    return this.parentModule !== this.moduleNames.SPECIAL_ADMIN;
  }

  translateNameToEnglish() {
    if (this.transform.name && this.transform.name.length > 0) {
      const payload: ITranslationRequest = {'q' : [this.transform.name ], 'target' : 'en'};
      this.translationService.translate(payload).subscribe((response: ITranslationResponse[]) => {
        if (response.length === 1) {
          this.transform.english_name = response[0].translatedText;
        }
      });
    }
  }

  translationIsPossible() {
    return this.transform.name && this.transform.name.length > 0;
  }
}
