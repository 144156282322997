import {DataProviderWithPagination} from '../../../shared/models/data-provider-with-pagination.class';
import {NEED_STATUSES} from '../values/need-statuses.const';
import {Need} from './need.class';
import moment from 'moment';
import {Response} from '@angular/http';

export class NeedEsmMatchDepositsFilter extends DataProviderWithPagination<Need> {
  expand =  'passport,client,match_deposits';
  sort = '-created_at';
  'status[]' = NEED_STATUSES.PUBLISHED.value;
  admin = 1;
  supervisor = 1;
  independent = 1;
  exclude_refused = 1;
  delivery_from: string;
  delivery_to: string;
  from_deposit: false;
  records: Need[] = [];
  loaded = false;
  has_deposit_match = true;
  paginate = true;

  fields() {
    return ['expand', 'status[]', 'admin', 'supervisor', 'independent', 'exclude_refused', 'from_deposit', 'paginate','sort'];
  }

  buildOptions(): Array<any[]> {
    const options: any = this.fields().reduce((acc, item) => {
      if (this[item]) {
        acc[item] = this[item];
      }
      return acc;
    }, {});
    if (!options['from_deposit'] && this.has_deposit_match) {
      options['has_deposit_match'] = true;
    }
    if (options['from_deposit']) {
      options['targeted_match_esm'] = true;
    }
    if (this.paginate) {
      options['page'] = this.currentPage;
    }
    ['delivery_from', 'delivery_to'].filter(item => this.parseDate(item as any))
      .forEach(item => options[item] = this.parseDate(item as any));
    return options;
  }

  private parseDate(field: keyof NeedEsmMatchDepositsFilter) {
    const date = moment((this[field] as string), ['DD-MM-YYYY', 'YYYY-MM-DD']);
    if (date.isValid()) {
      return date.format('YYYY-MM-DD');
    }
    return null;
  }

  public updateRecords(response: Response
                       , pagination: { totalCount: number; perPage: number; pageCount: number; currentPage: number }) {
    super.updateRecords(response, pagination);
    this.records = this.records
      .filter((need: Need) => need.match_deposits && need.match_deposits.data.length > 0)
      .map(data => new Need(data));
    this.loaded = true;
  }

  public updateRecordEntities(needs: Need[]) {
    this.records = needs.filter((need: Need) => need.match_deposits && need.match_deposits.data.length > 0);
    this.loaded = true;
  }
}
