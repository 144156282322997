/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carbon-footprint.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./carbon-footprint-report/carbon-footprint-report.component.ngfactory";
import * as i3 from "./carbon-footprint-report/carbon-footprint-report.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./carbon-footprint.component";
var styles_CarbonFootprintComponent = [i0.styles];
var RenderType_CarbonFootprintComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarbonFootprintComponent, data: {} });
export { RenderType_CarbonFootprintComponent as RenderType_CarbonFootprintComponent };
function View_CarbonFootprintComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "carbon-footprint-report", [], null, null, null, i2.View_CarbonFootprintReportComponent_0, i2.RenderType_CarbonFootprintReportComponent)), i1.ɵdid(1, 114688, null, 0, i3.CarbonFootprintReportComponent, [i4.TranslateService], { reports: [0, "reports"], changeTab: [1, "changeTab"], reportType: [2, "reportType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reports; var currVal_1 = _co.changeTab; var currVal_2 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CarbonFootprintComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarbonFootprintComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportTypes; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CarbonFootprintComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "carbon-footprint", [], null, null, null, View_CarbonFootprintComponent_0, RenderType_CarbonFootprintComponent)), i1.ɵdid(1, 114688, null, 0, i6.CarbonFootprintComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarbonFootprintComponentNgFactory = i1.ɵccf("carbon-footprint", i6.CarbonFootprintComponent, View_CarbonFootprintComponent_Host_0, { reports: "reports", changeTab: "changeTab" }, {}, []);
export { CarbonFootprintComponentNgFactory as CarbonFootprintComponentNgFactory };
