/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import-progress-sticky-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./import-progress-sticky-footer.component";
var styles_ImportProgressStickyFooterComponent = [i0.styles];
var RenderType_ImportProgressStickyFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImportProgressStickyFooterComponent, data: {} });
export { RenderType_ImportProgressStickyFooterComponent as RenderType_ImportProgressStickyFooterComponent };
export function View_ImportProgressStickyFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "import-progress-sticky-footer"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "import-progress-sticky-footer__push"]], null, null, null, null, null))], null, null); }
export function View_ImportProgressStickyFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "import-progress-sticky-footer", [], null, null, null, View_ImportProgressStickyFooterComponent_0, RenderType_ImportProgressStickyFooterComponent)), i1.ɵdid(1, 49152, null, 0, i2.ImportProgressStickyFooterComponent, [], null, null)], null, null); }
var ImportProgressStickyFooterComponentNgFactory = i1.ɵccf("import-progress-sticky-footer", i2.ImportProgressStickyFooterComponent, View_ImportProgressStickyFooterComponent_Host_0, {}, {}, ["*"]);
export { ImportProgressStickyFooterComponentNgFactory as ImportProgressStickyFooterComponentNgFactory };
