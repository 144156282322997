import {User} from '../../shared/models/user.class';
import {OrganisationTIds} from '../../shared/values/company-values.enum';
import {ADMIN_ESM_OPTIONS, ERoleIds} from '../../shared/values/role-ids.enum';
import {IRolePermission, IRolePermissionContext} from '../../shared/interfaces/role-permission.interface';
import {EDepositOwnerPermission} from './values/deposit-owner-permission.enum';
import {AbstractPermission} from '../../shared/models/abstract-permission.class';

export class BuildingPermission extends AbstractPermission implements IRolePermission {
  public static readonly id = EDepositOwnerPermission.BUILDING_DO;
  name = 'Building deposit owner';

  isValid(user: User) {
    return user.company.organisation_type === OrganisationTIds.Building
      && user.plan_id !== ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN;
  }
}


export const DO_PERMISSIONS: IRolePermissionContext = {
  roleId: ERoleIds.DEPOSIT_OWNER,
  permissions: {
    [BuildingPermission.id]: new BuildingPermission()
  },
  defaultPermissionsIdsBefore: [],
  defaultPermissionsIdsAfter: [BuildingPermission.id]
};
