import { BehaviorSubject } from 'rxjs/Rx';
var NavbarStateService = /** @class */ (function () {
    function NavbarStateService() {
        this.multilanguageValue = new BehaviorSubject(false);
        this.multilanguageObservable$ = this.multilanguageValue.asObservable();
        this.multilanguage = localStorage.getItem('multilanguage') === 'true';
    }
    Object.defineProperty(NavbarStateService.prototype, "multilanguage", {
        get: function () {
            return this.multilanguageValue.getValue();
        },
        set: function (value) {
            localStorage.setItem('multilanguage', value.toString());
            this.multilanguageValue.next(value);
        },
        enumerable: true,
        configurable: true
    });
    return NavbarStateService;
}());
export { NavbarStateService };
