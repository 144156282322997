import * as tslib_1 from "tslib";
import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
var ClientStats = /** @class */ (function (_super) {
    tslib_1.__extends(ClientStats, _super);
    function ClientStats() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientStats.prototype.fields = function () {
        return [
            'created',
            'm',
            'y'
        ];
    };
    ClientStats.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        Utils.convertJsonStringToNumber(json, [
            'created',
            'm',
            'y'
        ]);
        return json;
    };
    return ClientStats;
}(BaseModel));
export { ClientStats };
