<article [formGroup]="editForm">

  <section class="form-group">
    <label for="needName" class="form-group__label">{{ 'Need' | translate }}</label>
    <div class="form-group__wrapper">
      <div class="form-group__content">
        <md-input-container
        >
          <input
          [class.highlighted]="controlStates?.name?.highlighted"
          formControlName="name"
          autocomplete="off" 
          placeholder="{{ 'Need name' | translate }} *"
          #needName
          [disabled]="controlStates?.name?.disabled"
          autocomplete="!off"
          [(ngModel)]="need.name"
            mdInput
          />
          <md-hint
            *ngIf="editForm.controls['name'].hasError('required')"
            class="error-hint"
            align="start">{{ 'Need name is required' | translate }}
          </md-hint>
        </md-input-container>
      </div>
      <label class="form-group__label"
             md-tooltip="{{ 'NEED_NAME_TOOLTIP' | translate }}"
      >
        <i class="form-group__label--question">?</i>
      </label>
    </div>
  </section>

  <section class="form-group">
    <label class="form-group__label">{{ 'Recipient data' | translate }}</label>
    <div
      class="form-group__content"
      [class.highlighted]="controlStates?.client_id?.highlighted"
    >
      <div class="form-group__item">
        <custom-search
          caption="{{ 'Name' | translate }} *"
          [preset]="need?.client"
          [service]="clientService"
          [listener]="need.onChangeClient"
          (onSelect)="onSelectClient($event)"
          (onCreate)="onCreateClient($event)"
          (onChangeName)="onChangeName($event)"
          label="{{'New recipient' | translate }}"
          [required]="true"
          [autoCreation]="false"
          [disabled]="controlStates?.client_location_id?.disabled"
          [autoComplete]= "'new-password'"
        ></custom-search>
      </div>
      <div class="form-group__item" *ngIf="isClientValid()">
        <md-input-container>
          <input
            mdInput
            placeholder="{{ 'Address' | translate }}"
            disabled
            autocomplete="donotdoit"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="need.client.address">
        </md-input-container>
      </div>
    </div>
    <div class="form-group__aside" *ngIf="isClientValid()">
      <img class="client-logo" *ngIf="need.client.image" src="" [src]="need.client.image"/>
    </div>
  </section>

  <section class="form-group" *ngIf="isClientValid()">
    <label class="form-group__label">{{ 'Representative' | translate }}</label>

    <div class="form-group__content--full" *ngIf="!controlStates?.client_contact?.disabled">
      <md-radio-group
        *ngIf="need.client.client_contacts"
        [(ngModel)]="need.client_contact"
        (change)="onChangeContact($event)"
        formControlName="client_contact">
        <table>
          <thead>
          <tr>
            <td></td>
            <td>{{ 'NAME' | translate }}</td>
            <td>{{ 'TELEPHONE' | translate }}</td>
            <td>{{ 'MAIL' | translate }}</td>
            <td>{{ 'OCCUPATION' | translate }}</td>
          </tr>
          </thead>
          <tfoot>
          </tfoot>
          <tbody class="need-contacts">
          <tr
            *ngFor="let contact of need.client.client_contacts"
            [class.checked]="contact?.id === need.client_contact?.id"
            [class.highlighted]="controlStates?.client_contact_id?.highlighted && contact?.id === need.client_contact?.id"
          >
            <td>
              <md-radio-button [value]="contact" [disabled]="controlStates?.client_location_id?.disabled" [checked]="contact?.id === need.client_contact?.id"></md-radio-button>
            </td>
            <td>{{contact.name}}</td>
            <td>{{contact.phone}}</td>
            <td>{{contact.email}}</td>
            <td>{{contact.function}}</td>
          </tr>
          <tr *ngIf="need.client.client_contacts.length === 0">
            <td colspan="5" class="empty-results">{{ 'No Representative found' | translate }}</td>
          </tr>
          </tbody>
        </table>
      </md-radio-group>
    </div>

    <div class="form-group__content" *ngIf="controlStates?.client_contact?.disabled">
      <div
        class="form-group__item"
        *ngIf="need.client_contact.name"
        [class.highlighted]="controlStates?.client_contact_id?.highlighted"
      >
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Name' | translate }}"
            [value]="need.client_contact.name"
            disabled
          />
        </md-input-container>
      </div>
      <div class="form-group__item" *ngIf="need.client_contact.phone">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Telephone' | translate }}"
            [value]="need.client_contact.phone"
            disabled
          />
        </md-input-container>
      </div>
      <div class="form-group__item" *ngIf="need.client_contact.email">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Mail' | translate }}"
            [value]="need.client_contact.email"
            disabled
          />
        </md-input-container>
      </div>
      <div class="form-group__item" *ngIf="need.client_contact.function">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Occupation' | translate }}"
            [value]="need.client_contact.function"
            disabled
          />
        </md-input-container>
      </div>
    </div>
  </section>
</article>

