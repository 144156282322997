import {ExtendedActiveRecord} from '../../../shared/models/extended-active-record.class';
import {EcosystemService} from '../../../entities/ecosystem/services/ecosystem.service';
import * as _ from 'lodash';
import {Need} from '../../../entities/need/models/need.class';
import {EcosystemStep} from './ecosystem-step.class';
import {Observable} from 'rxjs/Observable';
import {EcosystemOffer} from '../../../entities/ecosystem-offer/ecosystem-offer.model';
import {DepositOrder} from './deposit-order.class';
import {TransformOrder} from './transform-order.class';
import {ECOSYSTEM_STATUS, EEcosystemResend} from '../values/ecosystem-status.const';
import {ECOSYSTEM_STEP, ECOSYSTEM_STEP_TYPE} from '../values/ecosystem-step-type.const';
import {EventEmitter} from '@angular/core';
import {TRANSFORM_ORDER_STATUS} from '../../transformation-manager/values/transform-order-statuses.const';
import STATUS = ECOSYSTEM_STEP.STATUS;
import moment from 'moment';
import {ECOSYSTEM_OFFER_STATUS} from '../values/ecosystem-offer-status.const';
import { TRANSFORMATION_TYPE } from '../../../../e2e/_values/transformation-statuses.const';

export class Ecosystem extends ExtendedActiveRecord {
  protected provider = EcosystemService;

  public id: number;
  public name: string;
  public steps: EcosystemStep[];
  public need: Need;
  public ecosystem_offer: EcosystemOffer;
  public description: string;
  public created_at: string;
  public updated_at: string;
  public status: number;
  public need_id: number;
  public file_count: number;
  public creator_name: string;
  public updater_name: string;
  public steps_complete: number;
  public steps_total: number;
  public delivery_date: string;
  public publication_date: string;
  public client_name: string;
  public deposit_orders: DepositOrder[];
  public transformation_orders: TransformOrder[];
  public waste_total: {total: number, errors: number[]};
  public economic_total: {total: number, errors: number[]};
  public emissions_total: {total: number, errors: number[]};
  public water_total: {total: number, errors: number[]};
  public add_other_waste: number;
  public add_other_profits: number;
  public add_other_CO2: number;
  public add_other_water: number;
  public subtract_other_waste: number;
  public subtract_other_CO2: number;
  public subtract_other_water: number;
  public subtract_other_profits: number;
  public description_other_waste: string;
  public description_other_CO2: string;
  public description_other_water: string;
  public description_other_profits: string;
  public user_id: number;
  public onFetch$ = new EventEmitter();
  public validation_resend: number;
  public folder: any;
  public checked: boolean;
  protected fields() {
    return [
      'id',
      'name',
      'steps',
      'need',
      'description',
      'created_at',
      'updated_at',
      'status',
      'need_id',
      'file_count',
      'creator_name',
      'updater_name',
      'steps_complete',
      'steps_total',
      'delivery_date',
      'publication_date',
      'client_name',
      'ecosystem_offer',
      'deposit_orders',
      'transformation_orders',
      'waste_total',
      'economic_total',
      'emissions_total',
      'water_total',
      'add_other_waste',
      'subtract_other_waste',
      'description_other_waste',
      'description_other_CO2',
      'description_other_water',
      'description_other_profits',
      'summary_steps',
      'add_other_profits',
      'add_other_CO2',
      'add_other_water',
      'subtract_other_CO2',
      'subtract_other_water',
      'subtract_other_profits',
      'user_id',
      'validation_resend',
      'folder'
    ];
  }

  includesText(searchText: string) {
    searchText = searchText.toLocaleLowerCase();

    let doesNameInclude = _.includes(this.name.toLocaleLowerCase(), searchText);

    return doesNameInclude;
  }

  get progress() {
    return Math.ceil(this.steps_complete / this.steps_total * 100);
  }

  get canEditSteps() {
    return this.status === ECOSYSTEM_STATUS.DRAFT;
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    try {
      if (json.steps) {
        json.steps = <EcosystemStep[]>json.steps.map((item: any) => {
          let step = new EcosystemStep(item);
          step.ecosystem = this;
          return step;
        });
      }
    } catch (error) {
      console.error(error);
    }
    json.need = new Need(json.need);
    json.ecosystem_offer = json.ecosystem_offer ?
      new EcosystemOffer(Object.assign(json.ecosystem_offer, {ecosystem: this})) :
      null
    ;
    if (json.deposit_orders) {
      json.deposit_orders.map((item: any) => new DepositOrder((item)));
    }
    if (json.transformation_orders) {
      json.transformation_orders.map((item: any) => new TransformOrder((item)));
    }
    return json;
  }

  fetch(): Observable<any> {
    return this
      .load({expand: 'steps.transformation,steps.deposit,need,need.passport.unit,ecosystem_offer,deposit_orders,transformation_orders' +
          ',deposit_orders.order_passport,deposit_orders.deposit_passport,transformation_orders.transformation,validation_resend'})
      .do((res: Ecosystem) => {
        this.status = res.status;
        this.validation_resend = res.validation_resend;
        this.deposit_orders = res.deposit_orders.map(data => new DepositOrder(data)) || [];
        this.transformation_orders = res.transformation_orders.map(data => new TransformOrder(data)) || [];
        this.steps.forEach((step, i) => {
          step.status = res.steps[i].status;
          step.order_aggregate = res.steps[i].order_aggregate;
          step.linked_date = res.steps[i].linked_date;
          if (step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
            step.order = new DepositOrder(this.deposit_orders.filter((order) => order.step_id === step.id).shift());
          }
          if (step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
            step.orders = this.transformation_orders.filter((order) => order.step_id === step.id);
            if (TRANSFORMATION_TYPE[step.transformation_type] === "Transport" || !!step.transform && TRANSFORMATION_TYPE[step.transform.transformation_type] === "Transport") {
              step["estimated_distance"] = res.steps[i]["estimated_distance"];
            }
          }
          step.updateEndpoints(this.canEditSteps);
          step.updateOverlay();
        });
        this.need = new Need(res.need);
        if (!('id' in this.need)) {
          this.need_id = null;
        }
        this.ecosystem_offer = new EcosystemOffer(res.ecosystem_offer
          || {ecosystem_id: this.id, ecosystem: this, status: ECOSYSTEM_OFFER_STATUS.DRAFT});
        this.ecosystem_offer.need = this.need;
        this.folder = res.folder;
        this.onFetch$.emit();
      });
  }

  reload() {
    this
      .fetch()
      .subscribe();
  }

  isNeedDeleted() {
    return this.load({expand: 'need'})
      .map((res: Ecosystem) =>
         !('id' in res.need)
          );
  }

  destroy(): Observable<any> {
    return this.simplePost('/' + this.id + '/delete_all');
  }

  get canDeleteByStatus() {
    return [
      ECOSYSTEM_STATUS.DONE,
      ECOSYSTEM_STATUS.REFUSED,
      ECOSYSTEM_STATUS.DRAFT,
    ].includes(this.status);
  }

  get canStopOrEdit() {
    return [
      ECOSYSTEM_STATUS.CREATED,
      ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION,
      ECOSYSTEM_STATUS.WAITING_FOR_QUOTE
    ].includes(this.status);
  }

  get isOrdersConfirmed() {
    return this.steps.every(step =>  [STATUS.CONFIRMED, STATUS.DONE].includes(step.status)
      || [ECOSYSTEM_STEP_TYPE.NEED, ECOSYSTEM_STEP_TYPE.STAKEHOLDER].includes(step.type));
  }

  get isAskForOfferValidationAgain(){
    return [ECOSYSTEM_STATUS.WAITING_FOR_QUOTE, ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION].includes(this.status)
      && this.validation_resend === EEcosystemResend.ASK_FOR_OFFER_VALIDATION_AGAIN
    }
  
  get isAskForOfferConfirmationAgain(){
    return this.validation_resend === EEcosystemResend.ASK_FOR_OFFER_CONFIRMATION_AGAIN
  }

  get isAskForValidationAgain()  {
    if ([ECOSYSTEM_STATUS.CREATED, ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.WAITING_FOR_QUOTE].includes(this.status)
      && this.validation_resend === EEcosystemResend.ASK_FOR_VALIDATION_AGAIN) {
      return true;
    } else if ([ECOSYSTEM_STATUS.IN_PROCESS].includes(this.status)
      && !this.isOrdersConfirmed && this.validation_resend === EEcosystemResend.ASK_FOR_CONFIRMATION_AGAIN) {
      return true;
    } else if ([ECOSYSTEM_STATUS.IN_PROCESS].includes(this.status)
      && this.isOrdersConfirmed && this.validation_resend === EEcosystemResend.ASK_FOR_OFFER_CONFIRMATION_AGAIN) {
      return true;
    }
    return false;
  }

  ordersValidated() {
    return this.filterOffer().every(step => step.status === STATUS.VALIDATED);
  }

  ordersConfirmed() {
    return this.filterOffer().every(step => step.status === STATUS.CONFIRMED);
  }
  ordersBooked() {
    return this.filterOffer().every(step => step.status === STATUS.PENDING);
  }

  private filterOffer(): EcosystemStep[] {
    return (this.steps || []).filter(step => step.type !== ECOSYSTEM_STEP_TYPE.NEED && step.type !== ECOSYSTEM_STEP_TYPE.STAKEHOLDER );
  }

  ordersWaitingForConfirmation() {
    return this.filterOffer().every(step => moment(step.linked_date, ['YYYY-MM-DD', 'DD-MM-YYYY']).isBefore(moment()));
  }
}
