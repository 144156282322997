import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
var TranslationService = /** @class */ (function (_super) {
    tslib_1.__extends(TranslationService, _super);
    function TranslationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'translation';
        _this.ModelClass = '';
        return _this;
    }
    TranslationService.prototype.translate = function (translationRequest) {
        return this.sendPost(this.getEndpoint(this.namespace + '/translate'), translationRequest)
            .map(function (response) { return response.json(); })
            .catch(this.onError.bind(this));
    };
    return TranslationService;
}(ApiService));
export { TranslationService };
