import * as tslib_1 from "tslib";
import { EcosystemService } from './ecosystem.service';
import { ECOSYSTEM_STATUS, ECOSYSTEM_STATUS_FILTER_VIEW } from '../../../dashboards/ecosystem-manager/values/ecosystem-status.const';
import { first } from 'rxjs/operators';
var EcosystemFolderService = /** @class */ (function () {
    function EcosystemFolderService(ecosystemService) {
        var _a;
        this.ecosystemService = ecosystemService;
        this.currentFolder = {
            name: "",
            parentId: null
        };
        this.defaultFolder = {
            name: "",
            parentId: null
        };
        this.newFolderName = "";
        this.selection = [];
        this.folderSelection = [];
        this.folderViewResults = {
            ecosystems: [],
            folders: []
        };
        this.flatViewResults = {
            ecosystems: [],
            folders: []
        };
        this.isRoot = true;
        this.defaultFilter = {
            search: '',
            deliveryDateFrom: null,
            deliveryDateTo: null,
            clientId: null,
            sortValue: 'last_update',
            folders: [],
            statuses: [
                { value: ECOSYSTEM_STATUS.DRAFT, selected: true, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.DRAFT] },
                { value: ECOSYSTEM_STATUS.CREATED, selected: true, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.CREATED] },
                { value: ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, selected: true, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION] },
                { value: ECOSYSTEM_STATUS.IN_PROCESS, selected: true, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.IN_PROCESS] },
                { value: ECOSYSTEM_STATUS.DONE, selected: false, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.DONE] },
                { value: ECOSYSTEM_STATUS.REFUSED, selected: false, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.REFUSED] }
            ]
        };
        this.filter = {
            search: '',
            deliveryDateFrom: null,
            deliveryDateTo: null,
            clientId: null,
            sortValue: 'last_update',
            folders: [],
            statuses: [
                { value: ECOSYSTEM_STATUS.DRAFT, selected: true, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.DRAFT] },
                { value: ECOSYSTEM_STATUS.CREATED, selected: true, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.CREATED] },
                { value: ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION, selected: true, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION] },
                { value: ECOSYSTEM_STATUS.IN_PROCESS, selected: true, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.IN_PROCESS] },
                { value: ECOSYSTEM_STATUS.DONE, selected: false, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.DONE] },
                { value: ECOSYSTEM_STATUS.REFUSED, selected: false, view: ECOSYSTEM_STATUS_FILTER_VIEW[ECOSYSTEM_STATUS.REFUSED] }
            ]
        };
        this.defaultStatuses = (_a = {},
            _a[ECOSYSTEM_STATUS.DRAFT] = true,
            _a[ECOSYSTEM_STATUS.CREATED] = true,
            _a[ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION] = true,
            _a[ECOSYSTEM_STATUS.IN_PROCESS] = true,
            _a[ECOSYSTEM_STATUS.DONE] = false,
            _a[ECOSYSTEM_STATUS.REFUSED] = false,
            _a);
        this.folderMode = true;
        this.insideFolder = false;
        this.allSelected = false;
        this.isLoading = false;
    }
    EcosystemFolderService.prototype.enterFolder = function (folder) {
        this.isRoot = false;
        this.filter.folders = [folder];
        this.currentFolder = tslib_1.__assign({}, folder);
        this.newFolderName = folder.name;
        this.search();
    };
    EcosystemFolderService.prototype.leaveFolder = function () {
        this.isRoot = true;
        this.filter.folders = [];
        this.search();
    };
    EcosystemFolderService.prototype.search = function () {
        this.isDefaultFilter() && this.folderMode ?
            this.getEcosystemFolderRoot() :
            this.searchEcosystems();
    };
    EcosystemFolderService.prototype.getFolders = function () {
        var _this = this;
        this.ecosystemService.getEcosystemFolderRoot()
            .subscribe(function (data) {
            _this.allFolders = data.folders;
        });
    };
    EcosystemFolderService.prototype.getEcosystemFolderRoot = function () {
        var _this = this;
        var query = this.buildParams();
        this.ecosystemService.getEcosystemFolderRoot({ search: query })
            .subscribe(function (data) {
            _this.resetSelection();
            _this.isRoot = true;
            _this.folderViewResults.ecosystems = data.ecosystems.map(function (es) { return _this.prepareEcosystemForDisplay(es); });
            _this.folderViewResults.folders = data.folders.map(function (f) { return _this.prepareFolderForDisplay(f); });
            _this.currentFolder = _this.defaultFolder;
            _this.newFolderName = _this.defaultFolder.name;
            _this.allFolders = data.folders;
        });
    };
    EcosystemFolderService.prototype.searchEcosystems = function () {
        var _this = this;
        this.isLoading = true;
        var query = this.buildParams();
        this
            .ecosystemService
            .get(query)
            .pipe(first())
            .subscribe(function (res) {
            console.log(res);
            // this.isRoot = true;
            var _a = _this.prepareSearchForDisplay(res), folders = _a.folders, ecosystems = _a.ecosystems;
            _this.folderViewResults.ecosystems = ecosystems.map(function (es) { return _this.prepareEcosystemForDisplay(es); });
            _this.folderViewResults.folders = folders.map(function (f) { return _this.prepareFolderForDisplay(f); });
            // this.currentFolder = this.defaultFolder;
            _this.isLoading = false;
        });
    };
    EcosystemFolderService.prototype.toggleFolderMode = function (e) {
        this.folderMode = e;
        this.search();
    };
    EcosystemFolderService.prototype.buildParams = function (nested) {
        var params = 'ecosystems,ecosystems.folder,folder,';
        var statuses = this.filter.statuses.filter(function (item) {
            // if (item.value === ECOSYSTEM_STATUS.DONE){
            //   this.filter.statuses.filterList.statusDone = item.selected;
            // }
            return item.selected;
        }).map(function (item) { return item.value; });
        if (!!statuses.find(function (value) { return value === ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION; })) {
            statuses.push(ECOSYSTEM_STATUS.WAITING_FOR_QUOTE, ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION);
        }
        var expandedArr = ["need", "steps", "steps_complete", "steps_total", "client_name", "publication_date", "need_id", "ecosystem_offer"];
        var expand = expandedArr.reduce(function (acc, curr) {
            return acc + "," + (nested ? 'ecosystems.' : '') + curr;
        }, params);
        return {
            expand: expand,
            admin: 1,
            esm_view: 1,
            delivery_from: this.filter.deliveryDateFrom || null,
            delivery_to: this.filter.deliveryDateTo || null,
            client: this.filter.clientId || null,
            search: this.filter.search || null,
            'status[]': statuses,
            sort: this.filter.sortValue,
            'folder_id[]': this.filter.folders.map(function (f) { return f.id; }) || null
        };
    };
    Object.defineProperty(EcosystemFolderService.prototype, "ecosystems", {
        get: function () {
            return this.folderViewResults.ecosystems;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemFolderService.prototype, "folders", {
        get: function () {
            return this.folderViewResults.folders;
        },
        enumerable: true,
        configurable: true
    });
    EcosystemFolderService.prototype.toggleStatus = function (v) {
        var index = this.filter.statuses.findIndex(function (a) { return a.value === v; });
        if (index >= 0) {
            this.filter.statuses[index].selected = !this.filter.statuses[index].selected;
        }
    };
    EcosystemFolderService.prototype.addToSelection = function (ecosystemId, parentId) {
        var found = !!this.selection.find(function (e) { return e.ecosystemId === ecosystemId; });
        !found && this.selection.push({ ecosystemId: ecosystemId, parentId: parentId });
    };
    EcosystemFolderService.prototype.selectFolder = function (folderId) {
        var found = !!this.folderSelection.find(function (f) { return f === folderId; });
        return !found && this.folderSelection.push(folderId);
    };
    EcosystemFolderService.prototype.unSelectFolder = function (folderId) {
        var index = this.folderSelection.findIndex(function (f) { return f === folderId; });
        if (index < 0) {
            return;
        }
        this.folderSelection = this.folderSelection.slice(0, index).concat(this.folderSelection.slice(index + 1));
    };
    EcosystemFolderService.prototype.removeFromSelection = function (ecosystemId) {
        var index = this.selection.findIndex(function (a) { return a.ecosystemId === ecosystemId; });
        if (index < 0) {
            return;
        }
        this.selection = this.selection.slice(0, index).concat(this.selection.slice(index + 1));
    };
    EcosystemFolderService.prototype.addFolderToSelection = function (folderId) {
        var _this = this;
        this.selectFolder(folderId);
        this.ecosystemService.getEcosystemFolderById(folderId, { search: { expand: 'ecosystems' } })
            .pipe(first())
            .subscribe(function (data) {
            data.ecosystems.forEach(function (_a) {
                var id = _a.id;
                _this.addToSelection(id, folderId);
            });
        });
    };
    EcosystemFolderService.prototype.removeFolderFromSelection = function (folderId) {
        this.unSelectFolder(folderId);
        this.selection = this.selection.filter(function (_a) {
            var ecosytemId = _a.ecosytemId, parentId = _a.parentId;
            return parentId !== folderId;
        });
    };
    EcosystemFolderService.prototype.resetSelection = function () {
        this.selection = [];
        this.folderSelection = [];
    };
    EcosystemFolderService.prototype.prepareEcosystemForDisplay = function (ecosystem) {
        return tslib_1.__assign({}, ecosystem, { checked: !!this.selection.find(function (_a) {
                var ecosystemId = _a.ecosystemId;
                return ecosystemId === ecosystem.id;
            }) });
    };
    EcosystemFolderService.prototype.prepareFolderForDisplay = function (folder) {
        var selectedCount = this.selection.reduce(function (acc, _a) {
            var parentId = _a.parentId, ecosystemId = _a.ecosystemId;
            return (acc + (parentId === folder.id ? 1 : 0));
        }, 0);
        return tslib_1.__assign({}, folder, { es_count_display: (folder.es_count_search && (folder.es_count_search !== folder.es_count)) ? folder.es_count_search + " / " + folder.es_count :
                (Number(folder.es_count) ? folder.root_count + " / " + folder.es_count : folder.es_count), checked: ((folder.es_count !== 0) && (selectedCount === folder.es_count)) || !!this.folderSelection.find(function (f) { return f === folder.id; }) });
    };
    EcosystemFolderService.prototype.createFolder = function (name) {
        return this.ecosystemService.createFolder({ name: name }).pipe(first());
    };
    EcosystemFolderService.prototype.updateFolder = function (id, name) {
        return this.ecosystemService.updateFolder(id, { name: name }).pipe(first());
    };
    EcosystemFolderService.prototype.moveSingleEcosystem = function (id, folderId) {
        return this.ecosystemService.moveFolder({ ecosystem_ids: [id], move_to: folderId }).pipe(first());
    };
    EcosystemFolderService.prototype.moveFolder = function (folderid, isRoot) {
        return this.ecosystemService.moveFolder({ ecosystem_ids: this.selection.map(function (_a) {
                var ecosystemId = _a.ecosystemId;
                return ecosystemId;
            }), move_to: folderid, is_root: isRoot }).pipe(first());
    };
    EcosystemFolderService.prototype.deleteFolder = function () {
        return this.ecosystemService.deleteFolder({
            folder_ids: this.folderSelection,
            ecosystem_ids: this.selection.map(function (_a) {
                var ecosystemId = _a.ecosystemId;
                return ecosystemId;
            })
        }).pipe(first());
    };
    EcosystemFolderService.prototype.canMoveSelectionToRoot = function () {
        return !!this.selection.find(function (v) { return v.parentId !== null; });
    };
    EcosystemFolderService.prototype.prepareSearchForDisplay = function (res) {
        var shouldDisplayFolder = this.folderMode && this.isRoot;
        return shouldDisplayFolder ?
            res.reduce(function (acc, curr) {
                var newFolders = acc.folders.slice();
                var newEcosystems = acc.ecosystems.slice();
                if (curr.folder) {
                    var foundIndex = acc.folders.findIndex((function (f) { return f.id === curr.folder.id; }));
                    if (foundIndex < 0) {
                        var newFolder = tslib_1.__assign({}, curr.folder, { es_count_search: 1 });
                        newFolders.push(newFolder);
                    }
                    else {
                        var newFolder = tslib_1.__assign({}, acc.folders[foundIndex]);
                        newFolder.es_count_search++;
                        newFolders = newFolders.slice(0, foundIndex).concat([
                            newFolder
                        ], newFolders.slice(foundIndex + 1));
                    }
                }
                else {
                    newEcosystems.push(curr);
                }
                return {
                    folders: newFolders,
                    ecosystems: newEcosystems
                };
            }, { folders: [], ecosystems: [] })
            :
                {
                    folders: [],
                    ecosystems: res
                };
    };
    EcosystemFolderService.prototype.resetFilter = function () {
        var _this = this;
        this.filter.search = this.defaultFilter.search;
        this.filter.deliveryDateFrom = this.defaultFilter.deliveryDateFrom;
        this.filter.deliveryDateTo = this.defaultFilter.deliveryDateTo;
        this.filter.clientId = this.defaultFilter.clientId;
        this.filter.sortValue = this.defaultFilter.sortValue;
        this.filter.folders = this.defaultFilter.folders.slice();
        this.filter.statuses.forEach(function (item) {
            item.selected = _this.defaultStatuses[item.value];
        });
        if (this.isRoot) {
            this.search();
        }
    };
    EcosystemFolderService.prototype.isDefaultFilter = function () {
        var f = this.filter.search === this.defaultFilter.search &&
            this.filter.deliveryDateFrom === this.defaultFilter.deliveryDateTo &&
            this.filter.sortValue === this.defaultFilter.sortValue &&
            this.isStatusesSameAsDefault() &&
            this.filter.clientId === this.defaultFilter.clientId &&
            this.filter.folders.length === 0;
        return f;
    };
    EcosystemFolderService.prototype.isStatusesSameAsDefault = function () {
        var _this = this;
        return this.filter.statuses.reduce(function (acc, curr) {
            return acc && curr.selected === _this.defaultStatuses[curr.value];
        }, true);
    };
    EcosystemFolderService.prototype.toggleAll = function () {
        this.allSelected ? this.selectAll() : this.unSelectAll();
    };
    EcosystemFolderService.prototype.selectAll = function () {
        var _this = this;
        this.folderViewResults.ecosystems.forEach(function (e) {
            var folderId = e.folder && e.folder.id;
            _this.addToSelection(e.id, folderId);
        });
        this.folderViewResults.folders.forEach(function (f) {
            _this.addFolderToSelection(f.id);
        });
        this.folderViewResults.ecosystems = this.folderViewResults.ecosystems.map(function (es) { return _this.prepareEcosystemForDisplay(es); });
        this.folderViewResults.folders = this.folderViewResults.folders.map(function (f) { return _this.prepareFolderForDisplay(f); });
    };
    EcosystemFolderService.prototype.unSelectAll = function () {
        var _this = this;
        this.resetSelection();
        this.folderViewResults.ecosystems = this.folderViewResults.ecosystems.map(function (es) { return _this.prepareEcosystemForDisplay(es); });
        this.folderViewResults.folders = this.folderViewResults.folders.map(function (f) { return _this.prepareFolderForDisplay(f); });
    };
    return EcosystemFolderService;
}());
export { EcosystemFolderService };
