import {Component, EventEmitter, Input, OnDestroy, OnInit, SkipSelf} from '@angular/core';
import {MdDialog} from '@angular/material';
import {
  DepositSiteDetailsDialogComponent
} from '../../../../../entities/deposit/dialogs/deposit-site-details-dialog/deposit-site-details-dialog.component';
import {OrganisationTIds} from '../../../../../shared/values/company-values.enum';
import {DepositLocation} from '../../../../../entities/deposit/models/deposit-location.class';
import {DepositLocationService} from '../../../../../entities/deposit/services/deposit-location.service';
import {ConfirmDialogComponent} from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {
  DepositSiteDetailsPreviewDialogComponent
} from '../../../../../entities/deposit/dialogs/deposit-site-details-preview-dialog/deposit-site-details-preview-dialog.component';
import * as _ from 'lodash';
import {LogNotificationService} from '../../../../../shared/services/log-notification.service';
import {DepositLocationTemporaryStorageService} from '../../../../../entities/deposit/services/deposit-location-temporary-storage.service';
import {FileUploader} from 'ng2-file-upload';
import {DepositLocationStatusName, EDepositLocationStatus} from '../../../../../entities/deposit/values/deposit-location-status.enum';
import {UserService} from '../../../../../shared/services/user.service';
import {User} from '../../../../../shared/models/user.class';
import moment from 'moment';
import {Observable} from 'rxjs/Observable';

@Component({
  moduleId: module.id,
  selector: 'company-sites',
  templateUrl: './company-sites.component.html',
  styleUrls: ['./company-sites.component.scss']
})
export class CompanySitesComponent implements OnInit, OnDestroy {
  @Input() set userId(id: number) {
    this._userId = id;
    if (this._userId) {
      this.loadLocations();
      // setTimeout(() => this.loadUserExpanded());
    }
  }
  @Input() set companyId(id: number) {
    this._companyId = id;
    if (this._companyId) {
      this.loadLocations();
    }
  }

  DEPOSIT_LOCATION_STATUS_ID = EDepositLocationStatus;
  DEPOSIT_LOCATION_STATUS_NAME = DepositLocationStatusName;
  records: DepositLocation[] = [];
  search = '';
  searchChanged$ = new EventEmitter();
  private _userId: number;
  private _companyId: number;

  constructor(
    private mdDialog: MdDialog,
    private translate: TranslateService,
    private siteService: DepositLocationService,
    private notification: LogNotificationService,
    private userService: UserService,
    @SkipSelf() private storage: DepositLocationTemporaryStorageService
  ) {
    this.validatePreview = this.validatePreview.bind(this);
  }

  ngOnInit() {
    this.searchChanged$.debounceTime(300)
      .distinctUntilChanged().subscribe(() => {
      if (this._userId) {
          this.loadLocations();
        } else {
          this.searchUnsafeLocation();
        }
    });
    this.storage.setResetObservers(true);
  }

  ngOnDestroy() {
    if (this.searchChanged$) {
      this.searchChanged$.unsubscribe();
    }
  }

  private loadLocations() {
    if (!this._companyId) {
      return;
    }
    this.siteService.get(
      {expand: 'floor_area,end_of_work,expired', company_id: this._companyId, search: this.search, superadmin: 1})
      .subscribe((locations: DepositLocation[]) => this.records = locations);
  }

  private loadUserExpanded() {
    if (!this._companyId) {
      this.userService.view(this._userId, {expand: 'company'})
        .subscribe((user: User) => {
          this._companyId = user.company.id;
          this.loadLocations();
        });
    }
  }

  private searchUnsafeLocation() {
    this.records = this.storage.getDepositLocations().filter(site => {
      return (site.name || '').toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) > -1
        || (site.address || '').toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) > -1; });
  }

  createNewSite() {
    if (this._userId) {
      this.mdDialog.open(DepositSiteDetailsDialogComponent,
        {data: {type: OrganisationTIds.Building, userId: this._userId}}).afterClosed().subscribe(() => this.loadLocations());
    } else {
      this.createNewTemporarySite();
    }
  }

  private createNewTemporarySite() {
    if (this.storage.isLoading()) {
      return;
    }
    const {location, fileUploader} = this.storage.addLocation();
    this.mdDialog.open(DepositSiteDetailsPreviewDialogComponent
      , {data: {location, uploader: fileUploader, isValid: this.validatePreview}}).afterClosed()
    .subscribe((data: {location: DepositLocation, uploader: FileUploader}) => {
      if (data) {
        location.loaded = true;
      } else {
        this.storage.remove(location);
      }
      this.searchUnsafeLocation();
    });
  }

  private validatePreview(data: DepositLocation): Observable<boolean> {
    const duplication = this.storage.getDepositLocations().find(site => site !== data && site.name.trim() === data.name.trim());
    if (duplication) {
      this.notification.error('The site name already exists, choose another one or delete the existing one.', null, true);
      return Observable.of(false);
    }
    return Observable.of(this.syncPreviewValidation(data));
  }

  private syncPreviewValidation(data: DepositLocation) {
    /* if (data.end_of_work && data.isExpire) {
      this.notification.error('Deposit location has expired', null, true);
      return false;
    }*/
    if (data.bim_id && typeof data.bim_id !== 'number') {
      this.notification.error('Bim Id must be an integer.', null, true);
      return false;
    }
    if (!data.name) {
      this.notification.error('Name cannot be blank.', null, true);
      return false;
    }
    if (!data.address) {
      this.notification.error('The address field must be filled.', null, true);
      return false;
    }
    if (!data.site_status) {
      this.notification.error('Status cannot be blank.', null, true);
      return false;
    }
    if (data.site_status === this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION && !data.end_of_work) {
      this.notification.error('End of work cannot be blank.', null, true);
      return false;
    }
    if (data.site_status === this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION && data.active) {
      this.notification.error('Some deposits of this site are being used in ecosystems!', null, true);
      return false;
    }
    return true;
  }


  getSiteExpireDate(location: DepositLocation) {
    if (location.is_expired_by_superadmin) {
      return location.expired_date;
    }
    if (this.isEndOfWorkExpire(location)) {
      return location.end_of_work;
    }
    return null;
  }

  private isEndOfWorkExpire(location: DepositLocation) {
    return location.site_status === this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION && location.isExpire;
  }

  showExpireOrEndOfWorkText(item: DepositLocation) {
    if (!!this.getSiteExpireDate(item)) {
      return this.translate.instant('Expired since') + ' : ';
    } else {
      return this.translate.instant('End of work') + ' : ' + item.end_of_work;
    }
  }

  openSiteView(item: DepositLocation) {
    if (this._userId) {
      this.mdDialog.open(DepositSiteDetailsDialogComponent,
        {data: {id: item.id, type: OrganisationTIds.Building}}).afterClosed().subscribe(() => this.loadLocations());
    } else {
      this.openTemporarySite(item);
    }
  }

  private openTemporarySite(item: DepositLocation) {
    if (this.storage.isLoading()) {
      return;
    }
    const uploader = this.storage.getUploader(item);
    const options = {data: {location: _.cloneDeep(_.omit(item, ['service']))
        , uploader: _.cloneDeep(uploader), isValid: this.validatePreview}};
    this.mdDialog.open(DepositSiteDetailsPreviewDialogComponent, options).afterClosed().subscribe(
      (data: {location: DepositLocation, uploader: FileUploader}) => {
        if (data) {
          _.assign(item, data.location);
          Object.assign(uploader, data.uploader);
        }
        this.searchUnsafeLocation();
      });
  }

  removeSite(site: DepositLocation) {
    if (this._userId) {
      site.load({expand: 'deposits,active'}).subscribe((expandSite: DepositLocation) => {
        if (!expandSite.active) {
          this.openRemoveDialog(site);
        } else {
          this.notification.error('There is one deposit in an active ecosystem', null, true);
        }
      });
    } else {
      this.openRemoveDialog(site);
    }
  }

  private openRemoveDialog(site: DepositLocation) {
    if (this.storage.isLoading() && !this._userId) {
      return;
    }
    this.mdDialog.open(ConfirmDialogComponent,
      {data: {text: this.translate.instant(
        'Are you sure you want to delete this site ? It contains N deposits',
            {X: Array.isArray(site.deposits) ? site.deposits.length : 0})}}
    ).afterClosed().subscribe(confirm => {
      if (confirm) {if (this._userId) {
        this.siteService.remove(site.id).subscribe(() => this.loadLocations());
      } else {
        this.storage.remove(site);
        this.searchUnsafeLocation();
      }}
    });
  }

  totalSqm() {
    return this.records.reduce((reduce, item) =>  reduce + (Number(item.floor_area) || 0), 0);
  }
}
