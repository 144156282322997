import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, ElementRef } from '@angular/core';
import { MdDialogRef, MdDialog } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Deposit } from '../../models/deposit.class';
import { DepositService } from '../../services/deposit.service';
import { PaginationUnmarshallingService } from '../../../../shared/services/pagination-unmarshalling.service';
import { DepositFilter } from '../../models/deposit-filter';
import { DEPOSIT_STATUSES } from '../../values/deposit-statuses.const';
import { AdvancedResearchDialogComponent } from '../../../../entities/passport/dialogs/advanced-research-dialog/advanced-research-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { DEMOUNTABILITY_STATES, DEMOUNTABILITY_MAP } from '../../values/demountability-states.const';
import { DEPOSIT_CONDITIONS, DEPOSIT_CONDITION_MAP } from '../../values/deposit-conditions.const';
import { DEPOSIT_REUSE, DEPOSIT_REUSE_SOURCE_VIEW } from '../../values/deposit-reuse-source.const';
import { TranslateService } from '@ngx-translate/core';
import { DepositLocationService } from '../../services/deposit-location.service';
import * as moment from 'moment';
import { DepositMassUpdateWarningComponent } from '../deposit-mass-update-warning/deposit-mass-update-warning.component';
import { GENERIC_PASSPORT_UNITS_MAP } from '../../../..//entities/generic-passport/models/values/generic-passport-units.const';
var DepositMassActionDialogComponent = /** @class */ (function () {
    function DepositMassActionDialogComponent(_fb, dialog, dialogService, paginationService, depositService, translate, locationService, mdDialogData) {
        this._fb = _fb;
        this.dialog = dialog;
        this.dialogService = dialogService;
        this.paginationService = paginationService;
        this.depositService = depositService;
        this.translate = translate;
        this.locationService = locationService;
        this.mdDialogData = mdDialogData;
        this.isLoaded = false;
        this.records = [];
        this.transformedRecords = [];
        this.demountabilityStates = DEMOUNTABILITY_STATES;
        this.depositConditions = DEPOSIT_CONDITIONS;
        this.depositReuse = DEPOSIT_REUSE;
        this.isMassUpdate = true;
        this.queryDepositLocationParams = { expand: 'tags', agent: 1 };
        this.depositLocation = [];
        this.MAX_LENGTH = 10;
        this.currentTags = {};
        this.applyArrowMap = {
            quantity: false,
            passport: false,
            availability_date: false,
            availability_expiration: true,
            potential_reuse: false,
            deposit_demountability: false,
            condition: false,
            deposit_source: false,
            conversion: false,
            price: true,
            deposit_location: false,
        };
        this.quickUpdateMap = {};
        this.unvalidDateStateMap = {};
    }
    DepositMassActionDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMassUpdate = this.mdDialogData.isMassUpdate;
        this.locationService.get(this.queryDepositLocationParams).subscribe(function (res) {
            _this.depositLocation = res;
        });
        this.filter = new DepositFilter(tslib_1.__assign({}, this.mdDialogData.filter, { depositsIds: this.mdDialogData.depositsIds, isAllSelected: this.mdDialogData.isAllSelected }));
        this.filter.expand += ",tags,deposit_location.tags";
        this.updateRecordsForSelection();
        this.form = this.getDefaultHeaderForm();
        this.overrideForm = this.getDefaultOverrideForm();
        this.massUpdateForm = this._fb.group({
            quantity: new FormControl(this.overrideForm.quantity, [
                Validators.min(0),
            ]),
            conversion: new FormControl(this.overrideForm.conversion, [
                Validators.min(0.00001),
            ]),
            price: new FormControl(this.overrideForm.price, [
                Validators.min(0),
            ]),
        });
    };
    DepositMassActionDialogComponent.prototype.ngOnDestroy = function () {
    };
    /**
     * Get the default header form object
     * @returns {Object}
     */
    DepositMassActionDialogComponent.prototype.getDefaultHeaderForm = function () {
        return {
            passport: {},
            deposit_demountability: null,
            potential_reuse: false,
            deposit_conditon: null,
            deposit_source: null,
            deposit_location: {
                tags: []
            },
            tags: {},
        };
    };
    /**
     * Get the default override form object
     * @returns {Object}
     */
    DepositMassActionDialogComponent.prototype.getDefaultOverrideForm = function () {
        return {
            quantity: null,
            passport: null,
            availability_date: null,
            availability_expiration: null,
            potential_reuse: null,
            deposit_demountability: null,
            condition: null,
            deposit_source: null,
            conversion: null,
            price: null,
            deposit_location: null,
            displayedTags: [],
            tags_to_add: [],
            tags_to_remove: [],
        };
    };
    DepositMassActionDialogComponent.prototype.prepareForm = function (record) {
        var _this = this;
        this.quickUpdateFormArray = this._fb.array([]);
        record.forEach(function (r, i) {
            var g = _this._fb.group({
                quantity: new FormControl(r.quantity, [
                    Validators.min(0),
                    _this.createQuantityReservedValidator(r)
                ]),
                conversion: new FormControl(r.conversion, [
                    Validators.min(0.00001),
                    _this.createConversionFactorVoidValidator(r)
                ]),
                price: new FormControl(r.price, [
                    Validators.min(0.00001),
                ]),
            });
            g.get("quantity").valueChanges.subscribe(function (v) {
                _this.updateRecord(v, record[i], "quantity", i);
            });
            g.get("conversion").valueChanges.subscribe(function (v) {
                _this.updateRecord(v, record[i], "conversion", i);
            });
            g.get("price").valueChanges.subscribe(function (v) {
                _this.updateRecord(v, record[i], "price", i);
            });
            _this.quickUpdateFormArray.push(g);
        });
    };
    DepositMassActionDialogComponent.prototype.canConfirm = function () {
        return this.isMassUpdate ? this.canConfirmMassUpdate() : this.canConfirmQuickUpdate();
    };
    DepositMassActionDialogComponent.prototype.canConfirmMassUpdate = function () {
        return !this.checkMassUpdateDateIsUnvalid() && this.hasMassUpdateChanges();
    };
    DepositMassActionDialogComponent.prototype.canConfirmQuickUpdate = function () {
        return this.quickUpdateFormArray && this.quickUpdateFormArray.valid && this.checkAllDateAreValid();
    };
    DepositMassActionDialogComponent.prototype.confirm = function () {
        this.isMassUpdate ? this.confirmMassUpdate() : this.confirmQuickUpdate();
    };
    DepositMassActionDialogComponent.prototype.confirmMassUpdate = function () {
        var _this = this;
        var options = this.buildOptions();
        var fields = {};
        if (this.overrideForm.quantity !== null) {
            fields.quantity = this.overrideForm.quantity;
        }
        if (this.overrideForm.passport !== null) {
            fields.passport_id = this.overrideForm.passport.id;
        }
        if (this.overrideForm.availability_date !== null) {
            fields.availability_date = moment(this.overrideForm.availability_date).format('YYYY-MM-DD');
        }
        if (this.overrideForm.availability_expiration !== null) {
            fields.availability_expiration = moment(this.overrideForm.availability_expiration).format('YYYY-MM-DD');
        }
        if (this.overrideForm.potential_reuse !== null) {
            fields.potential_reuse = this.overrideForm.potential_reuse;
        }
        if (this.overrideForm.deposit_demountability !== null) {
            fields.deposit_demountability = this.overrideForm.deposit_demountability;
        }
        if (this.overrideForm.condition !== null) {
            fields.condition = this.overrideForm.condition;
        }
        if (this.overrideForm.deposit_source !== null) {
            fields.deposit_source = this.overrideForm.deposit_source;
            fields.reused = fields.deposit_source === 0 ? 0 : 1;
        }
        if (this.overrideForm.conversion !== null) {
            fields.conversion = this.overrideForm.conversion;
        }
        if (this.overrideForm.price !== null) {
            fields.price = this.overrideForm.price;
        }
        if (this.overrideForm.deposit_location !== null) {
            fields.deposit_location_id = this.overrideForm.deposit_location.id;
        }
        if (this.overrideForm.tags_to_add.length !== 0) {
            fields.tags_to_add = this.overrideForm.tags_to_add.map(function (_a) {
                var id = _a.id;
                return id;
            });
        }
        if (this.overrideForm.tags_to_remove.length !== 0) {
            fields.tags_to_remove = this.overrideForm.tags_to_remove.map(function (_a) {
                var id = _a.id;
                return id;
            });
        }
        this.depositService.massUpdate({
            fields: fields,
            deposits_ids: this.mdDialogData.depositsIds,
            all_selected: this.mdDialogData.isAllSelected,
        }, options).subscribe(function (r) {
            _this.dialog.close();
        });
    };
    DepositMassActionDialogComponent.prototype.confirmQuickUpdate = function () {
        var _this = this;
        var depositsList = Object.keys(this.quickUpdateMap).map(function (id) {
            var data = tslib_1.__assign({ id: id }, _this.quickUpdateMap[id]);
            if (data.tags) {
                data.tags_ids = data.tags.map(function (_a) {
                    var id = _a.id;
                    return id;
                });
                delete data.tags;
            }
            if (data.deposit_location) {
                data.deposit_location_id = data.deposit_location.id;
                delete data.deposit_location;
            }
            return data;
        });
        this.depositService.quickUpdate({ depositsList: depositsList }).subscribe(function (r) {
            _this.dialog.close();
        });
    };
    /**
     * Check if there has been any changes in the popup
     * Call the corresponding method depending on the popup mode
     * @returns {Boolean}
     */
    DepositMassActionDialogComponent.prototype.hasChanges = function () {
        return this.isMassUpdate ? this.hasMassUpdateChanges() : this.hasQuickUpdateChanges();
    };
    /**
     * Check whether the mass update popup has any changes or not
     * @returns {Boolean}
     */
    DepositMassActionDialogComponent.prototype.hasMassUpdateChanges = function () {
        var defaultOverrideForm = this.getDefaultOverrideForm();
        for (var _i = 0, _a = Object.keys(this.overrideForm); _i < _a.length; _i++) {
            var key = _a[_i];
            var defaultValue = defaultOverrideForm[key];
            var currentValue = this.overrideForm[key];
            if (defaultValue === undefined) {
                return true;
            }
            if (Array.isArray(defaultValue)) {
                // If the current override form value has any element inside it, this means that an override element has been added
                // Thus, the popup has changes
                if (currentValue.length) {
                    return true;
                }
            }
            else {
                if (currentValue !== defaultValue) {
                    return true;
                }
            }
        }
        // If no difference has been found, that means that the current ovveride form is still the default one
        return false;
    };
    /**
     * Check whether the quick update popup has any changes or not
     * @returns {Boolean}
     */
    DepositMassActionDialogComponent.prototype.hasQuickUpdateChanges = function () {
        return Object.keys(this.quickUpdateMap).length !== 0;
    };
    /**
     * Reset the mass action popup to its original values
     * Depending on the mode of the popup, call the corresponding method
     */
    DepositMassActionDialogComponent.prototype.reset = function () {
        this.isMassUpdate ? this.resetMassUpdate() : this.resetQuickUpdate();
    };
    /**
     * Reset the mass update popup
     */
    DepositMassActionDialogComponent.prototype.resetMassUpdate = function () {
        this.form = this.getDefaultHeaderForm();
        this.massUpdateForm.reset();
        this.overrideForm = this.getDefaultOverrideForm();
    };
    /**
     * Reset the quick update popup
     */
    DepositMassActionDialogComponent.prototype.resetQuickUpdate = function () {
        var _this = this;
        this.quickUpdateMap = {};
        this.transformRecords(this.records);
        setTimeout(function () { return _this.depositNameInput.nativeElement.focus(); }, 0);
    };
    DepositMassActionDialogComponent.prototype.closeDialog = function () {
        this.dialog.close();
    };
    DepositMassActionDialogComponent.prototype.updateRecordsForSelection = function () {
        var _this = this;
        var options = this.buildOptions();
        if (this.subscriptionSelection) {
            this.subscriptionSelection.unsubscribe();
        }
        if (this.filter.searchStatus.length > 0) {
            this.isLoaded = false;
            this.subscriptionSelection = this.depositService.getWithArray(options).subscribe(function (res) {
                _this.filter.updateRecords(res, _this.paginationService.unmarshall(res));
                _this.records = _this.filter.records.map(function (item) { return new Deposit(item); });
                _this.transformRecords(_this.records);
                _this.isLoaded = true;
                // if (this.depositFilter.totalCount === 0) { this.isDeletable = false; }
            }, function () { return _this.isLoaded = true; });
        }
        else {
            this.isLoaded = true;
            this.records = [];
            this.filter.currentPage = 1;
            this.filter.totalCount = 0;
            this.filter.perPage = 1;
            // this.isDeletable = false;
        }
    };
    DepositMassActionDialogComponent.prototype.searchChangeForSelection = function (deletable) {
        if (deletable === void 0) { deletable = true; }
        this.filter.currentPage = deletable ? 0 : this.filter.currentPage;
        // this.isDeletable = deletable;
        this.updateRecordsForSelection();
    };
    DepositMassActionDialogComponent.prototype.pageChange = function (page) {
        this.filter.currentPage = page;
        this.updateRecordsForSelection();
    };
    DepositMassActionDialogComponent.prototype.buildOptions = function () {
        var options = this.filter.buildOptions();
        options = this.filter.buildRating(options);
        if (this.filter.searchStatus) {
            var draftIndex = this.filter.searchStatus.indexOf(DEPOSIT_STATUSES.DRAFT.value);
            if ((!!this.filter.searchStatus.length && (draftIndex === -1)) || this.filter.isOutOfStockSelected) {
                options.push(['instock', Number(!this.filter.isOutOfStockSelected)]);
            }
            this.filter.searchStatus.forEach(function (stat) { return options.push(['status[]', stat]); });
        }
        // if (this.navBarStateService.multilanguage) {
        //   options.push(['english', true]);
        // }
        // if (this.isDeletable) {
        //   this.stateService.depositList = this.depositFilter;
        // }
        return options;
    };
    DepositMassActionDialogComponent.prototype.openAdvancedResearch = function (canOpen, record, i) {
        var _this = this;
        if (!canOpen) {
            return;
        }
        this.dialogService.open(AdvancedResearchDialogComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { data: {} })).afterClosed().subscribe(function (data) {
            if (!data) {
                return;
            }
            if (record) {
                var index = _this.records.findIndex(function (_a) {
                    var id = _a.id;
                    return id === record.id;
                });
                _this.records[index].passport = data;
                _this.updateRecord(data, _this.records[index], 'passport', index);
                return;
            }
            _this.form.passport = data;
        });
    };
    DepositMassActionDialogComponent.prototype.applyToAllColumn = function (field) {
        if (!this.canApplyArrow(field)) {
            return;
        }
        this.overrideForm[field] = this.form[field];
        if (field === "deposit_location") {
            this.currentTags = this.form.deposit_location.tags;
            this.overrideForm.displayedTags = this.form.deposit_location.tags;
            this.overrideForm.tags_to_add = [];
        }
    };
    /**
     * Apply a value to all the lines if the value is positive
     * @param {String} field - The name of the field
     * @returns {undefined}
     */
    DepositMassActionDialogComponent.prototype.applyPositiveValue = function (field) {
        if (!this.isValuePositive(field)) {
            return;
        }
        this.overrideForm[field] = this.massUpdateForm.get(field).value;
    };
    /**
     * Apply a value to all the lines if the value is strictly positive
     * @param {String} field - The name of the field
     * @returns {undefined}
     */
    DepositMassActionDialogComponent.prototype.applyStrictlyPositiveValue = function (field) {
        if (!this.isValueStrictlyPositive(field)) {
            return;
        }
        this.overrideForm[field] = this.massUpdateForm.get(field).value;
    };
    DepositMassActionDialogComponent.prototype.displayDemountability = function (v) {
        if (!v) {
            return this.translate.instant(DEMOUNTABILITY_MAP[0]);
        }
        return this.translate.instant(DEMOUNTABILITY_MAP[v]);
    };
    DepositMassActionDialogComponent.prototype.displayDepositCondition = function (v) {
        return this.translate.instant(DEPOSIT_CONDITION_MAP[v]);
    };
    DepositMassActionDialogComponent.prototype.displayDepositSource = function (v) {
        if (v === null || v === undefined) {
            return this.translate.instant(DEPOSIT_REUSE_SOURCE_VIEW[4]);
        }
        return this.translate.instant(DEPOSIT_REUSE_SOURCE_VIEW[v]);
    };
    DepositMassActionDialogComponent.prototype.getTagName = function (name) {
        return name.length > this.MAX_LENGTH ? name.substring(0, this.MAX_LENGTH) + "..." : name;
    };
    DepositMassActionDialogComponent.prototype.addTags = function (tag) {
        this.overrideForm.tags_to_add.push(tag);
        this.overrideForm.displayedTags = this.overrideForm.displayedTags.filter(function (t) {
            return t.id !== tag.id;
        });
        this.form.tags = {};
    };
    DepositMassActionDialogComponent.prototype.removeTags = function (tag) {
        this.overrideForm.tags_to_remove.push(tag);
        this.overrideForm.displayedTags = this.overrideForm.displayedTags.filter(function (t) {
            return t.id !== tag.id;
        });
        this.form.tags = {};
    };
    DepositMassActionDialogComponent.prototype.computeTags = function (record) {
        return this.isMassUpdate ? this.computeTagsMassUpdate(record) : this.computeTagsQuickUpdate(record);
    };
    DepositMassActionDialogComponent.prototype.computeTagsMassUpdate = function (record) {
        var _this = this;
        var tmp = (this.overrideForm.deposit_location !== null && this.overrideForm.deposit_location.id !== record.deposit_location.id) ? [] : record.tags.slice();
        var _loop_1 = function (i) {
            var found = tmp.find(function (_a) {
                var id = _a.id;
                return id === _this.overrideForm.tags_to_add[i].id;
            });
            if (!found) {
                tmp.push(this_1.overrideForm.tags_to_add[i]);
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.overrideForm.tags_to_add.length; i++) {
            _loop_1(i);
        }
        var _loop_2 = function (i) {
            tmp = tmp.filter(function (item) {
                return item.id !== _this.overrideForm.tags_to_remove[i].id;
            });
        };
        for (var i = 0; i < this.overrideForm.tags_to_remove.length; i++) {
            _loop_2(i);
        }
        return tmp;
    };
    DepositMassActionDialogComponent.prototype.computeTagsQuickUpdate = function (record) {
        return !!this.quickUpdateMap[record.id] && !!this.quickUpdateMap[record.id].tags ? this.quickUpdateMap[record.id].tags : record.tags;
    };
    DepositMassActionDialogComponent.prototype.canApplyArrow = function (name) {
        if (name === "passport") {
            return !!this.form.passport.id;
        }
        if (name === "deposit_location") {
            return !!this.form.deposit_location.id;
        }
        if (name === "tags") {
            return !!this.form.tags.id;
        }
        if (name === "deposit_source") {
            return this.form.deposit_source != null && this.form.deposit_source >= 0;
        }
        return !!this.form[name];
    };
    /**
     * Check whether a value is positive and valid
     * @param {String} name - Name of the field
     * @returns {undefined}
     */
    DepositMassActionDialogComponent.prototype.isValuePositive = function (name) {
        var d = this.massUpdateForm.get(name);
        return (!!d.value || d.value === 0) && !d.invalid;
    };
    /**
     * Check whether a value is strictly positive and valid
     * @param {String} name - Name of the field
     * @returns {undefined}
     */
    DepositMassActionDialogComponent.prototype.isValueStrictlyPositive = function (name) {
        var d = this.massUpdateForm.get(name);
        return !!d.value && !d.invalid;
    };
    DepositMassActionDialogComponent.prototype.updateRecord = function (event, record, field, i) {
        if (field === 'availability_date') {
            event = moment(event).format('YYYY-MM-DD');
        }
        if (field === 'availability_expiration') {
            event = moment(event).format('YYYY-MM-DD');
        }
        if (!this.quickUpdateMap[record.id]) {
            this.quickUpdateMap[record.id] = {};
        }
        this.quickUpdateMap[record.id][field] = event;
        if (field === 'deposit_location') {
            this.quickUpdateMap[record.id].tags = [];
        }
    };
    DepositMassActionDialogComponent.prototype.transformRecords = function (records) {
        var _this = this;
        var re = records.map(function (r) {
            return _this.quickUpdateMap[r.id] ? tslib_1.__assign({}, r, { deposit_location: _this.depositLocation.find(function (_a) {
                    var id = _a.id;
                    return id === r.deposit_location_id;
                }) || {} }, _this.quickUpdateMap[r.id]) : tslib_1.__assign({}, r, { deposit_location: _this.depositLocation.find(function (_a) {
                    var id = _a.id;
                    return id === r.deposit_location_id;
                }) || {} });
        });
        this.prepareForm(re);
        this.transformedRecords = re;
    };
    /**
     * Open the confirmation warning popup
     */
    DepositMassActionDialogComponent.prototype.openConfirmationWarning = function () {
        var _this = this;
        this.dialogService.open(DepositMassUpdateWarningComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { width: '50%', data: {} })).afterClosed().subscribe(function (data) {
            if (!data) {
                return;
            }
            _this.confirm();
        });
    };
    DepositMassActionDialogComponent.prototype.onTagsSelected = function (tag, record) {
        if (!this.quickUpdateMap[record.id]) {
            this.quickUpdateMap[record.id] = {};
        }
        if (!this.quickUpdateMap[record.id].tags) {
            this.quickUpdateMap[record.id].tags = record.tags.slice();
        }
        var found = this.quickUpdateMap[record.id].tags.find(function (_a) {
            var id = _a.id;
            return id === tag.id;
        });
        if (!found) {
            this.quickUpdateMap[record.id].tags.push(tag);
        }
        record.tmp_tag = null;
    };
    DepositMassActionDialogComponent.prototype.getUnit = function (record) {
        return this.overrideForm.passport ? GENERIC_PASSPORT_UNITS_MAP[this.overrideForm.passport.unit] : GENERIC_PASSPORT_UNITS_MAP[record.passport.unit];
    };
    Object.defineProperty(DepositMassActionDialogComponent.prototype, "quantity", {
        get: function () {
            return this.massUpdateForm.get('quantity');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositMassActionDialogComponent.prototype, "conversion", {
        get: function () {
            return this.massUpdateForm.get('conversion');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositMassActionDialogComponent.prototype, "price", {
        get: function () {
            return this.massUpdateForm.get('price');
        },
        enumerable: true,
        configurable: true
    });
    DepositMassActionDialogComponent.prototype.getFormGroup = function (i) {
        return this.isMassUpdate ? this._fb.group({}) : this.quickUpdateFormArray.at(i);
    };
    DepositMassActionDialogComponent.prototype.getFormControl = function (name, i) {
        return this.quickUpdateFormArray.at(i).get(name);
    };
    DepositMassActionDialogComponent.prototype.checkMassUpdateDateIsUnvalid = function () {
        if (!this.overrideForm.availability_date || !this.overrideForm.availability_expiration) {
            return false;
        }
        return moment(this.overrideForm.availability_date).isAfter(moment(this.overrideForm.availability_expiration));
    };
    DepositMassActionDialogComponent.prototype.checkQuickUpdateDateIsUnvalid = function (record) {
        var newValue = this.quickUpdateMap[record.id];
        var d = newValue && newValue.availability_date ? newValue.availability_date : record.availability_date;
        var exp = newValue && newValue.availability_expiration ? newValue.availability_expiration : record.availability_expiration;
        var unvalid = moment(d).isAfter(moment(exp));
        this.unvalidDateStateMap[record.id] = unvalid;
        return unvalid;
    };
    DepositMassActionDialogComponent.prototype.checkAllDateAreValid = function () {
        var _this = this;
        return !Object.keys(this.unvalidDateStateMap).reduce(function (acc, curr) {
            return acc || _this.unvalidDateStateMap[curr];
        }, false);
    };
    DepositMassActionDialogComponent.prototype.createConversionFactorVoidValidator = function (r) {
        return function (control) {
            var value = control.value;
            if (r.passport.family === 4 /* FAMILY_VOID */ && value === null) {
                return { voidPassport: true };
            }
            return null;
        };
    };
    DepositMassActionDialogComponent.prototype.createQuantityReservedValidator = function (r) {
        return function (control) {
            var value = control.value;
            if (value === null || r.booked === null) {
                return null;
            }
            if (value < r.booked) {
                return { quantityBooked: true };
            }
        };
    };
    return DepositMassActionDialogComponent;
}());
export { DepositMassActionDialogComponent };
