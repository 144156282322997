import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { GenericPassport } from '../../models/generic-passport.class';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { ZoneService } from '../../../../shared/services/zone.service';
var GenericPassportEdit = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassportEdit, _super);
    function GenericPassportEdit(route, router, _fb, cd, _translate, notificationService, _zone, zoneService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this._fb = _fb;
        _this.cd = cd;
        _this._translate = _translate;
        _this.notificationService = notificationService;
        _this._zone = _zone;
        _this.zoneService = zoneService;
        return _this;
    }
    GenericPassportEdit.prototype.ngOnInit = function () {
        this.form = this._fb.group({});
        //this.formValid = false;
        _super.prototype.ngOnInit.call(this);
        this.zoneId = +this.route.snapshot.paramMap.get('zoneId');
    };
    GenericPassportEdit.prototype.ngAfterViewInit = function () {
        this.cd.detectChanges();
    };
    GenericPassportEdit.prototype.onNewEntity = function () {
        this.zoneId = +this.route.snapshot.paramMap.get('zoneId');
        this.genericPassport = new GenericPassport();
        this.genericPassport.loaded = true;
        this.isNewGenericPassport = true;
        this.genericPassport.zone_id = this.zoneId;
    };
    GenericPassportEdit.prototype.clickSave = function () {
        var _this = this;
        if (this.form.valid) {
            if (this.genericPassport.materials.length < 1) {
                this.notificationService.error(this._translate.instant('At least one material is required'));
                return;
            }
            if (this.genericPassport.tags.length < 1) {
                this.notificationService.error(this._translate.instant('At least one tag is required'));
                return;
            }
            if (this.genericPassport.waste_category_id === 0) {
                this.genericPassport.waste_category_id = null;
            }
            // else {
            //   console.log('here landfulle cost');
            //   this.genericPassport.landfil_cost = null;
            // }
            //  console.log(this.genericPassport.construction_layer);
            this.zoneService.savePassport(this.zoneId, this.genericPassport)
                .subscribe(function (res) {
                if (res) {
                    _this.genericPassport.id = res.id;
                    _this.saveMaterials();
                    _this.saveTags();
                    _this.notificationService.success(_this._translate.instant('Generic passport created successfully'));
                    _this._zone.run(function () { return _this.router.navigate(["superadmin/zones/" + _this.zoneId + "/generic-passports"]); });
                }
            });
        }
        else {
            this.notificationService.error(this._translate.instant('All field with * are required'));
        }
    };
    GenericPassportEdit.prototype.saveMaterials = function () {
        var _this = this;
        if (this.genericPassport.materials.length > 0) {
            this.genericPassport.materials.forEach(function (mat) {
                if (!mat.id || mat.id < 1) {
                    mat.generic_passport_id = _this.genericPassport.id;
                    mat.save().subscribe(function (res) {
                        mat.id = res.id;
                    });
                }
            });
        }
    };
    GenericPassportEdit.prototype.saveTags = function () {
        var _this = this;
        if (this.genericPassport.tags.length > 0) {
            this.genericPassport.tags.forEach(function (tag) {
                if (!tag.id || tag.id < 1) {
                    tag.generic_passport_id = _this.genericPassport.id;
                    tag.save().subscribe(function (res) {
                        tag.id = res.id;
                        //console.log(tag);
                    });
                }
            });
        }
    };
    GenericPassportEdit.prototype.onExistEntity = function (entity) {
        this.genericPassport = entity;
        this.genericPassport.loaded = true;
        this.isNewGenericPassport = false;
    };
    return GenericPassportEdit;
}(EntityViewerComponent));
export { GenericPassportEdit };
