<article class="deposit-tag__container">
  <section *ngIf="!!isDescriptionShown"
    class="deposit-tag__info-block b-module-deposits background-primary-color">
    <button (click)="closeDescription()" class="deposit-tag__info-block-close" aria-label="close description">
      <span>+</span>
    </button>
    <div class="deposit-tag__info-block-row deposit-tag__info-block-row--first">
      {{"DEPOSIT_TAG_INFO_DESCRIPTION_FIRST_ROW" | translate}}
    </div>
  </section>

  <section class="deposit-tag__location-list">
    <div *ngFor="let location of depositLocation | paginate:{id:'depositsLocation'
    , itemsPerPage: sitesPagination.perPage
    , currentPage: sitesPagination.currentPage
    , totalItems: sitesPagination.totalCount }"
         class="deposit-tag__location-container"
    >
      <ul  class="deposit-tag__location-header"
           [ngClass]="{'deposit-tag__location-header--open': !!openDepositLocations[location.id]}"
      >
        <li class="deposit-tag__location-header-name">{{location.name}}</li>
        <li class="deposit-tag__location-header-address">
          <span class="deposit-tag__location-header-address-part">
            {{getAddressWithoutCountryPart(location.address)}}
          </span>
          <span class="deposit-tag__location-header-address-part">
            {{getCountryPart(location.address)}}
          </span>
        </li>
        <li class="deposit-tag__location-header-deposit-number">
          {{location.use_count}}{{' '}}{{'Deposits'| translate}}
        </li>
        <li class="deposit-tag__location-header-tag-number">
          {{location.tags?.length || 0}}{{' '}}{{'Tags' | translate}}
        </li>
        <li class="deposit-tag__location-header-toggle">
          <button (click)="toggleLocation(location.id)"
                  class="deposit-tag__location-header-toggle-button"
                  aria-label="show site's tags"
          >
            <i [ngClass]="'icon '
              + (!!openDepositLocations[location.id] ? 'arrow-up--icon' : 'arrow-down--icon')">
            </i>
          </button>
        </li>
      </ul>
      <div *ngIf="!!openDepositLocations[location.id]" class="deposit-tag__location-body"
           [@toggleBodyAnimation]>
        <div class="deposit-tag__location-body-container" @toggleBodyContainerAnimation>
          <div *ngFor="let tag of getTagsSorted(location)">
            <div *ngIf="!isTagEdited(tag.id, location.id); else editTagBlock"
                 [ngClass]="{'deposit-tag__tag':true
               , 'deposit-tag__tag--active': tag.editable
               , 'deposit-tag__tag--deactive': !tag.editable
               , 'b-module-deposits': true
               , 'background-primary-color': tag.editable}"
            >
              <span class="label">
                {{tag.name}}
              </span>
              <button *ngIf="tag.editable"
                      [mdMenuTriggerFor]="menu"
                      class="options"
                      aria-label="tag options"
              >
                <i class="icon list--icon"></i>
              </button>
            </div>
            <ng-template #editTagBlock>
              <md-input-container class="deposit-tag__tag-edit">
                <input
                  #editTagInput
                  class="deposit-tag__tag-edit-input"
                  mdInput
                  [size]="editInputSize(tag.name)"
                  [(ngModel)]="newEditTagName"
                  (change)="updateEditTag(tag)"
                  (blur)="resetEdit(tag)"
                >
              </md-input-container>
            </ng-template>
            <md-menu #menu="mdMenu" xPosition="after" yPosition="below">
              <button md-menu-item (click)="removeTag(tag, location.id)">{{'Delete' | translate}}</button>
              <button md-menu-item (click)="updateTag(tag)">{{'Edit' | translate}}</button>
              <button md-menu-item (click)="copyTagName(tag.name)">{{'Copy' | translate}}</button>
            </md-menu>
          </div>
            <md-input-container
              [floatPlaceholder]="'never'"
              class="deposit-tag__tag-new"
            >
              <input
                mdInput
                autocomplete="off"
                placeholder="{{'New tag' | translate}}"
                (change)="createNewTag($event.target, location.id)"
              >
            </md-input-container>
        </div>
      </div>
    </div>
    <div class="depositlist__container-pagination">
      <pagination-controls
            class="depositlist__pagination"
            id="depositsLocation"
            (pageChange)="pageChange($event)"
            maxSize="10"
            directionLinks="true"
            autoHide="true"
            previousLabel=""
            nextLabel=""
            screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
            screenReaderPageLabel="{{ 'page' | translate }}"
            screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"
      ></pagination-controls>
    </div>
    
  </section>
</article>

