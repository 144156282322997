<form class="contact-form" [formGroup]="contactForm" (ngSubmit)="save(contactForm)">
  <md-card-content>
    <div>
      <md-input-container>
        <input mdInput autocomplete="off" formControlName="name" placeholder="{{ 'Name' | translate }}" [(ngModel)]="contact.name">
      </md-input-container>
    </div>
    <div>
      <md-input-container>
        <input mdInput autocomplete="off" formControlName="function" placeholder="{{ 'Function' | translate }}"
               [(ngModel)]="contact.function">
      </md-input-container>
    </div>
    <div>
      <md-input-container>
        <input mdInput autocomplete="off" formControlName="phone" placeholder="{{ 'Phone' | translate }}" [(ngModel)]="contact.phone">
      </md-input-container>
    </div>
    <div>
      <md-input-container>
        <input mdInput autocomplete="off" formControlName="email" placeholder="{{ 'Email' | translate }}" [(ngModel)]="contact.email">
      </md-input-container>
    </div>
  </md-card-content>
  <md-card-actions>
    <div class="contact-form__controls">
        <button md-raised-button
                color="primary"
                type="submit"
                [disabled]="!contactForm.valid || !contactForm.dirty"
                class="uppercase">{{ 'Save' | translate }}
        </button>
      <!-- <li class="controls-item" *ngIf="asDialog" >
         <button md-raised-button (click)="closeDialog()"><md-icon>check</md-icon></button>
         </li> -->
    </div>
  </md-card-actions>
</form>
