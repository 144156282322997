/**
 * Created by aleksandr on 20.06.17.
 */
import { EventEmitter } from '@angular/core';
import { Searchable } from '../../../../shared/models/searchable.class';
import { FormControl } from '@angular/forms';
import { NeedClientListService } from '../../../../entities/need/services/need-client-list.service';
import { NeedSalesListService } from '../../../../entities/need/services/need-sales-list.service';
import { NEED_STATUSES } from '../../../../entities/need/values/need-statuses.const';
import { NeedService } from '../../../../entities/need/services/need.service';
import { CurrentUser } from '../../../../shared/auth/current-user.class';
var EsmNeedPickerComponent = /** @class */ (function () {
    // records: ICustomListRecord[] = [];
    function EsmNeedPickerComponent(_needService, needClientListService, needSalesListService) {
        var _this = this;
        this._needService = _needService;
        this.needClientListService = needClientListService;
        this.needSalesListService = needSalesListService;
        this.records = [];
        this.onSelect = new EventEmitter();
        this.activeItem = null;
        this.filters = [
            'all',
            'new',
            'not treated',
        ];
        this.filter = 'all';
        this.search = new FormControl;
        this.salesContacts = [new Searchable({ id: null, name: 'any' })];
        this
            .search
            .valueChanges
            .debounceTime(400)
            .subscribe(function () { return _this.getRecords(); });
        this.needClientListService.get().subscribe(function (res) {
            _this.clients = res;
        });
        this.needSalesListService.get().subscribe(function (res) {
            var _a;
            (_a = _this.salesContacts).push.apply(_a, res);
        });
        this.showIndependentFilter = CurrentUser.infoData.has_need_acces;
        this.getRecords();
    }
    EsmNeedPickerComponent.prototype.selectItem = function (item) {
        this.activeItem = item;
    };
    EsmNeedPickerComponent.prototype.createEcosystem = function () {
        this.onSelect.emit(this.activeItem);
    };
    EsmNeedPickerComponent.prototype.closeDialog = function () {
        this.onSelect.emit(null);
    };
    EsmNeedPickerComponent.prototype.getRecords = function () {
        var _this = this;
        var params = {
            expand: 'client,passport,client_contact',
            esm_view: true,
            supervisor: true,
            delivery_from: this.expiresFrom || null,
            delivery_to: this.expiresTo || null,
            search: this.search.value || null,
            client: this.clientId || null,
            independent: this.includeIndependent || null,
            sales_contact: this.salesId || null,
        };
        if (this.filter === 'not treated') {
            params.status = NEED_STATUSES.PUBLISHED.value;
        }
        else {
            params['status[]'] = [
                NEED_STATUSES.PUBLISHED,
                NEED_STATUSES.TREATED,
                NEED_STATUSES.WAITING_FOR_VALIDATION,
                NEED_STATUSES.IN_PROGRESS,
            ].map(function (s) { return s.value; });
        }
        this._needService
            .get(params)
            .subscribe(function (needs) {
            _this.records = needs;
        });
    };
    EsmNeedPickerComponent.prototype.toggleIndependent = function (checked) {
        this.includeIndependent = checked;
        this.getRecords();
    };
    return EsmNeedPickerComponent;
}());
export { EsmNeedPickerComponent };
