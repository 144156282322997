var ReadonlyWithoutFocusDirective = /** @class */ (function () {
    function ReadonlyWithoutFocusDirective() {
        this._isReadOnly = false;
    }
    Object.defineProperty(ReadonlyWithoutFocusDirective.prototype, "isReadOnly", {
        get: function () {
            return this._isReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReadonlyWithoutFocusDirective.prototype, "tabindex", {
        get: function () {
            return this._isReadOnly ? -1 : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReadonlyWithoutFocusDirective.prototype, "style", {
        get: function () {
            return this._isReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReadonlyWithoutFocusDirective.prototype, "appReadonlyWithoutFocus", {
        set: function (isDisabled) {
            this._isReadOnly = isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    ReadonlyWithoutFocusDirective.prototype.undoFocus = function ($event) {
        $event.target.dispatchEvent(new Event('blur'));
        $event.stopImmediatePropagation();
        $event.stopPropagation();
        return false;
    };
    ReadonlyWithoutFocusDirective.prototype.undoBlur = function ($event) {
        $event.stopPropagation();
        return false;
    };
    return ReadonlyWithoutFocusDirective;
}());
export { ReadonlyWithoutFocusDirective };
