import * as tslib_1 from "tslib";
import { GenericPassportService } from '../service/generic-passport.service';
import { ECustomListUnit } from '../../../shared/custom-list/interfaces/custom-list.interface';
import { GENERIC_PASSPORT_UNITS } from './values/generic-passport-units.const';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { GenericPassportMaterial } from './generic-passport-materials.class';
import { GenericPassportTagJunction } from './generic-passport-tag-junction.class';
var GenericPassport = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassport, _super);
    function GenericPassport(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = GenericPassportService;
        _this.materials = [];
        _this.tags = [];
        if (data) {
            _this.category = data.category;
            _this.waste_category = data.waste_category;
            if (data.materials) {
                _this.materials = data.materials.map(function (material) { return new GenericPassportMaterial(material); });
            }
            if (data.tags) {
                _this.tags = data.tags.map(function (tag) { return new GenericPassportTagJunction(tag); });
            }
        }
        return _this;
    }
    GenericPassport.prototype.fields = function () {
        return [
            'id',
            'comment',
            'category_id',
            'waste_category_id',
            'construction_layer',
            'unit',
            'conversion_factor',
            'purchase_cost',
            'default_recoverability_average',
            'upcycling_extra_cost_for_clean_removal',
            'upcycling_residual_value_up',
            'upcycling_residual_value_down',
            'reuse_extra_cost_for_clean_removal',
            'reuse_residual_value_up',
            'reuse_residual_value_down',
            'reuse_resale_price_up',
            'reuse_resale_price_down',
            'upcycling_resale_price_up',
            'upcycling_resale_price_down',
            'unit_text',
            'landfil_cost',
            'zone_id'
        ];
    };
    Object.defineProperty(GenericPassport.prototype, "customListUnit", {
        get: function () {
            return {
                'kg': ECustomListUnit.KG,
                'tonne': ECustomListUnit.TON,
                'm2': ECustomListUnit.M2,
                'm3': ECustomListUnit.M3,
                'unit': ECustomListUnit.UNIT,
                'piece': ECustomListUnit.PCS,
                'm': ECustomListUnit.M,
                'L': ECustomListUnit.L
            }[this.unit_text];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericPassport.prototype, "unit_text", {
        get: function () {
            var _this = this;
            var unit = GENERIC_PASSPORT_UNITS.find(function (item) { return item.id === _this.unit; });
            return unit ? unit.title : this.unit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericPassport.prototype, "unitView", {
        get: function () {
            return this.unit_text;
        },
        enumerable: true,
        configurable: true
    });
    return GenericPassport;
}(ExtendedActiveRecord));
export { GenericPassport };
