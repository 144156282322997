import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DepositLocationService } from './../../deposit/services/deposit-location.service';
import { DepositLocation } from './../../deposit/models/deposit-location.class';

@Injectable({ providedIn: 'root' })
export class SiteService {
  constructor(private depositLocationService: DepositLocationService) { }
 
  loadSites(filterParams: any, isNoah: boolean | null = null): Observable<DepositLocation[]> {
    const params = {
      ...this.getDefaultMapParams(),
      ...filterParams,
      noah_view: isNoah ? 1 : 0
    };

    return this.depositLocationService.getMapLocations(params).pipe(
      map((data: DepositLocation[]) => data.map((datum) => new DepositLocation(datum)))
    );
  }

  getDefaultMapParams() {
    return {
      active_sites: 1,
    };
  }
}
