import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { OrderMaterial } from '../models/order-material.class';
var OrderMaterialService = /** @class */ (function (_super) {
    tslib_1.__extends(OrderMaterialService, _super);
    function OrderMaterialService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'order-materials';
        _this.namespaceSingular = 'order-material';
        _this.ModelClass = OrderMaterial;
        return _this;
    }
    return OrderMaterialService;
}(ExtendedCrudService));
export { OrderMaterialService };
