import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { UserService } from '../../services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LogNotificationService } from '../../services/log-notification.service';
import { Utils } from '../../utils/utils.class';
import {CurrentUserService} from '../../services/current-user.service';


@Component({
  moduleId: module.id,
  selector: 'avatar-uploader',
  templateUrl: 'avatar-uploader.component.html'
})
export class AvatarUploaderComponent implements OnInit {
  uploader: FileUploader = new FileUploader({});
  targetUrl: string;
  avatarExists: boolean;

  @Input() preview: string;
  @Input() userId: number;
  @Input() readOnly: boolean = false;
  @Output() previewChange: EventEmitter<any> = new EventEmitter();
  public imageSrc;

  constructor(private userService: UserService,
              private sanitizer: DomSanitizer,
              private _currentUserService: CurrentUserService,
              public notification: LogNotificationService) {
  }

  ngOnInit() {
    if (this.preview) {
      this.avatarExists = true;
    }
    this.uploader.onAfterAddingFile = (fileItem) => {
      if (this.userId > 0) {
        this.upload();
      }
    };
  }

  upload(userId?: number) {
    if (!!userId) {
      this.userId = userId;
    }
    this.targetUrl = this.userService.getAvatarUploadUrl(this.userId);
    if (this.avatarExists) {
      this.userService.deleteAvatar(this.userId).subscribe(() => {
        this.uploadAvatar();
      });
    } else {
      this.uploadAvatar();
    }
  }

  uploadImg(event: Event) {
    if (event.target['files'] && event.target['files'][0]) {
      const file = event.target['files'][0];

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(file);
    }
  }

  getBackgroundImage() {
    if (this.preview) {
      return this.sanitizer.bypassSecurityTrustStyle(`url(${this.preview})`);
    } else {
      if (this.imageSrc) {
        return `url(${this.imageSrc})`;
      }
    }
  }

  deleteAvatar() {
    this.preview = null;
    this.previewChange.emit(this.preview);
    if (this.userId > 0) {
      this.userService.deleteAvatar(this.userId).subscribe(() => {
        this.imageSrc = null;
        this.avatarExists = false;
        this._currentUserService.currentUserDidChanged.emit();
      });
    }
  }

  private uploadAvatar() {
    this.uploader.setOptions({
      url: this.targetUrl,
      itemAlias: 'file',
      autoUpload: true,
    });
    this.uploader.uploadAll();
    this.uploader.onSuccessItem = ((_, response) => {
      let data: any = null;
      try {
        data = typeof response === 'string' ? JSON.parse(response) : response;
      } catch (e) {
        console.log(e);
      }
      if (data) {
        this.preview = data.file;
        this.previewChange.emit(this.preview);
        this.avatarExists = true;
        this._currentUserService.currentUserDidChanged.emit();
      }
    });
    this.uploader.onErrorItem = Utils.onUploadError((event: string) => this.notification.error(event,null,true));
  }
}
