import { EventEmitter } from '@angular/core';
import { ISpinnerCompletionRecord, ISpinnerCompletionRecordContainer, ISpinnerErrorReadyRecord } from '../../spinner/spinner-completion.interface';
import { EDeepStreamMessage } from '../../values/deepstream-message-type.enum';
import { BaseDeepStreamService } from './base-deepstream.service';

export class ReportImportDeepStream extends BaseDeepStreamService {

    private static _reportCompletionStream$: EventEmitter<ISpinnerCompletionRecordContainer> = new EventEmitter<ISpinnerCompletionRecordContainer>();

    protected messageTypes: EDeepStreamMessage[] = [
        EDeepStreamMessage.DEEPSTREAM_EVENT_DEPOSIT_IMPORT_RESULT
    ];

    public receiver: string

    public emit(data: ISpinnerCompletionRecord | ISpinnerErrorReadyRecord) {
        switch (data.type) {
            case EDeepStreamMessage.DEEPSTREAM_EVENT_DEPOSIT_IMPORT_RESULT:
                return ReportImportDeepStream._reportCompletionStream$.emit({ target: this.receiver, data } as { target: string, data: ISpinnerCompletionRecord });
        }
    }

    public get reportCompletionStream$(): EventEmitter<ISpinnerCompletionRecordContainer> {
        return ReportImportDeepStream._reportCompletionStream$;
    }

}