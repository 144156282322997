import * as tslib_1 from "tslib";
import { MdTooltip } from '@angular/material';
import { AbstractTooltipPrefix } from './abstract-tooltip-prefix';
/**
 * This directive is work around to support customization tooltips for @angular/material library version 2.0.0-beta.3.
 * This directive makes possible to add html content to the tooltip
 * Please remove or rewrite this directive if another library is used for tooltips or if @angular/material library is upgraded.
 *
 */
var CustomTemplateTooltipDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CustomTemplateTooltipDirective, _super);
    function CustomTemplateTooltipDirective(materialTooltip) {
        var _this = _super.call(this) || this;
        _this.materialTooltip = materialTooltip;
        _this.materialTooltip.hideDelay = 0;
        return _this;
    }
    CustomTemplateTooltipDirective.prototype.addMessageToTooltip = function () {
        var _this = this;
        setTimeout(function () { return _this.attachCustomTemplateMessage(); });
    };
    CustomTemplateTooltipDirective.prototype.getElement = function () {
        var elem = this.getCdkContainer(this.materialTooltip);
        return elem ? elem.querySelector('md-tooltip-component') : [];
    };
    CustomTemplateTooltipDirective.prototype.getInnerDivider = function () {
        return this.getElement().querySelector('div');
    };
    CustomTemplateTooltipDirective.prototype.attachCustomTemplateMessage = function () {
        var childElement = document.createElement("div");
        childElement.classList.add('custom-template');
        childElement.innerHTML = this.customTemplate;
        var parentElement = this.getInnerDivider();
        if (!parentElement.children[0]) {
            parentElement.insertBefore(childElement, parentElement.children[1]);
        }
    };
    return CustomTemplateTooltipDirective;
}(AbstractTooltipPrefix));
export { CustomTemplateTooltipDirective };
