import { DepositLocation } from '../../../models/deposit-location.class';
import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  moduleId: module.id,
  selector: 'view-map-deposit-location-circle',
  templateUrl: 'circle.component.html'
})
export class MapDialogCircleComponent {
  @Input() depositLocation: DepositLocation;
  @Output() select = new EventEmitter();

  isHovered = false;
  fillColor = 'hsl(15, 100%, 50%)';
  defaultFillOpacity = 0.5;
  fillOpacity = this.defaultFillOpacity;
  radius = 150;

  mouseOver() {
    this.isHovered = true;
    this.fillOpacity = 0.95;
  }

  mouseOut() {
    this.isHovered = false;
    this.fillOpacity = this.defaultFillOpacity;
  }
}
