<md-dialog-content>
  <div class="deposit-delete-modal">
    <div class="deposit-delete-modal__heading">
      <div class="deposit-delete-modal__image">
        <img [src]="imgUrl" class="deposit-delete-modal__triangle">
        <span class="deposit-delete-modal__exclamation">!</span>
      </div>
    </div>

    <div class="deposit-delete-modal__text-container">
      <span class="deposit-delete-modal__text" >{{text}}</span>
      <span class="deposit-delete-modal__text" *ngIf="!!subText">{{subText}}</span>
    </div>
    <div class="deposit-delete-modal__footer">
      <button class="deposit-delete-modal__button" md-raised-button
        (click)="confirm()">{{ 'Yes' | translate | uppercase}}</button>
      <button class="deposit-delete-modal__button" md-raised-button
        (click)="cancel()">{{ 'Cancel' | translate | uppercase}}</button>
    </div>
  </div>
</md-dialog-content>