<article class="main-content need-edit__main" [ngClass]="{'validated': validated}">
  <section class="main-wrapper">
    <ul class="collapsible__list">
      <li class="collapsible__item">
        <header class="collapsible__item-header" (click)="changeTab(0)">
          <span class="collapsible__item-step">1</span>
          <h2 class="collapsible__item-title">{{ 'Name & Recipient' | translate }}</h2>
        </header>
        <article class="collapsible__item-content" [ngClass]="{'active': stepId===0}">
          <div [hidden]="stepId !== 0">
            <name-client-step
              class="collapsible__item-data"
              [need]="need"
              [controlStates]="controlStates"
              *ngIf="need.loaded"
            ></name-client-step>
          </div>
        </article>
      </li>
      <li class="collapsible__item">
        <header class="collapsible__item-header" (click)="changeTab(1)">
          <span class="collapsible__item-step">2</span>
          <h2 class="collapsible__item-title">{{ 'Circular Passport' | translate }}</h2>
        </header>
        <article class="collapsible__item-content pullToTopLayer" [ngClass]="{'active': stepId===1}">
          <div [hidden]="stepId !== 1">
            <product-passport-step
            class="collapsible__item-data collapsible__item-data--fit-content"
            [need]="need"
            [listener]="savePassport"
            *ngIf="need.loaded && need.getProgress() > 0"
            [controlStates]="controlStates"
            (onSaveComplete)="onPassportSaveComplete($event)"
            [readOnly]="isPublished()"
            [showCatalogNameMenu]="!need.from_deposit && !isPublished()"
          >
              <div passport-search-message class="need-edit__passport-catalog-message primary-color"
                   *ngIf="showVoidPassportCreationMessage">
                <span class="icon primary-color">!</span>
                {{'No passport has been associated to this deposit' | translate}}
              </div>
            </product-passport-step>
          </div>
        </article>
      </li>
      <li class="collapsible__item">
        <header class="collapsible__item-header" (click)="changeTab(2)">
          <span class="collapsible__item-step">3</span>
          <h2 class="collapsible__item-title">{{ 'Other info' | translate }}</h2>
        </header>
        <article class="collapsible__item-content" [ngClass]="{'active': stepId>=2}">
          <div [hidden]="stepId < 2">
            <other-step
              class="collapsible__item-data"
              [need]="need"
              [controlStates]="controlStates"
              *ngIf="need.loaded && need.getProgress() > 1 && !!need.id"
            ></other-step>
          </div>
        </article>
      </li>
    </ul>

    <div class="need-view-publicity" *ngIf="need.loaded">
      <section class="ecosystem-dashboard__section ecosystem-dashboard__section--counters section-relative">
        <md-radio-group [(ngModel)]="!!need.independent">
          <fieldset [disabled]="isPublicityUnChangable()">
            <ul class="ecosystem-dashboard__counter-list">
              <li [class]="'ecosystem-dashboard__counter-item ' +
                ((isIndependentControlDisabledByDefault && !!need.independent) ? 'ecosystem-dashboard__counter-item--disabled': '')">

                <div class="ecosystem-dashboard__counter-value theme-color--primary">
                  <span>
                    <i class="icon x4 icon--pad-lock"></i>
                  </span>
                </div>
                <md-radio-button class="esm-catalog__group--buttons" [value]="false">
                  {{ 'Private need' | translate }}
                </md-radio-button>
              </li>
              <li [class]="'ecosystem-dashboard__counter-item ' +
                ((isIndependentControlDisabledByDefault && !need.independent) ? 'ecosystem-dashboard__counter-item--disabled': '')">
                <div class="ecosystem-dashboard__counter-value theme-color--primary ">
                  <span>
                    <i class="icon x4 icon--cloud-arrow-up"></i>
                  </span>
                </div>
                <md-radio-button class="esm-catalog__group--buttons" [value]="true">
                  {{ 'Public need' | translate }}
                </md-radio-button>
              </li>
            </ul>
          </fieldset>
        </md-radio-group>
        <label class="form-group__label tooltip"
        [mdTooltipPosition]="'right'"
        md-tooltip="{{ 'NEED_TYPE_TOOLTIP' | translate }}">
          <i class="form-group__label--question scale">?</i>
        </label>
      </section>
    </div>

    <footer class="controls">
      <ul class="button-group__list">
        <li class="button-group__item button-group__item--wide"
            *ngIf="!controlStates.reject?.disabled">
          <button md-button md-raised-button (click)="reject()">{{ 'Reject' | translate }}</button>
        </li>
        <li class="button-group__item button-group__item--wide"
            *ngIf="!controlStates.askForModification?.disabled">
          <button md-button md-raised-button (click)="askForModification()">{{ 'Ask for modification' | translate }}</button>
        </li>
        <li class="button-group__item button-group__item--wide"
            *ngIf="!controlStates.save?.disabled">
          <button md-button md-raised-button (click)="saveDraft(null, true)">{{ 'Save as Draft' | translate }}</button>
        </li>
        <li class="button-group__item button-group__item--wide"
            *ngIf="!controlStates.publish?.disabled">
          <div mdTooltipPosition="above" [mdTooltip]="'You can\'t edit this need since it has at least one validated offer, meaning a delivery is planned' | translate" >
            <button *ngIf="isDeliveryPlanned()" md-button md-raised-button disabled>{{ 'Submit' | translate }}</button>
          </div>
          <button *ngIf="!isDeliveryPlanned()" md-button md-raised-button (click)="confirmSubmit()">{{ 'Submit' | translate }}</button>
        </li>
      </ul>
    </footer>
  </section>
</article>
