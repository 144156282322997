import {ElementRef, EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElRowCounterService {
  updateRowCount$ = new EventEmitter<number>();
  private rows = -1;
  private sites: ElementRef[];
  constructor() { }

  countRows() {
    let rowCounter = 0, rowTopY = 0, rowHeight = 0;
    if (this.sites) {
      this.sites.forEach((el) => {
        const siteChild = el.nativeElement.children;
        for (let i = 0; i < siteChild.length; i++) {
          const childEl = siteChild[i];
          if (!rowHeight) {
            rowHeight = childEl.getBoundingClientRect().height - 1;
          }
          const nextRowY = this.getNextRowTopY(rowHeight, childEl, rowTopY)
          if (nextRowY) {
            rowTopY = nextRowY;
            rowCounter += 1;
          }
        }
      });
    } else {
      rowCounter = -1;
    }
    this.rows = rowCounter;
    this.updateRowCount$.emit(rowCounter);
  }

  private getNextRowTopY(rowHeight: number, childEl: any, rowTopY?: number) {
    if (!rowTopY && rowTopY !== 0) {
      rowTopY = childEl.getBoundingClientRect().top;
    }    
    if (rowTopY + rowHeight <= childEl.getBoundingClientRect().top) {
      rowTopY = childEl.getBoundingClientRect().top;
      return rowTopY;   
    }
    return null;
  }

  public getRows() {
    return this.rows;
  }

  public setSite(sites: ElementRef[]) {
    this.sites = sites;
  }

  isRowNumberBiggerThan(rowNumber = 1) {
    return this.rows > rowNumber;
  }
}
