/**
 * Created by aleksandr on 18.05.17.
 */

export const DEFAULT_ENDPOINT = [
  'Dot', {
    radius: 16,
    cssClass: 'plumb__endpoint',
  },
];
export const DEFAULT_ENDPOINT_STYLE = {
  fill: 'transparent',
};

export const DEFAULT_CONNECTOR = [
  'Bezier', {curviness: 75}
];
export const DEFAULT_ANCHORS = [
  'Bottom', 'Top'
];

export const SOURCE_ENDPOINT = {
  anchor: 'Bottom',
  isSource: true,
  isTarget: false,
};

export const DEFAULT_CONNECTION_STYLE = {
  stroke: '#656565',
  strokeWidth: 2,
};

const TARGET_ENDPOINT = {
  anchor: 'Top',
  isSource: false,
  isTarget: true,
};
export const SINGLE_TARGET_ENDPOINT = TARGET_ENDPOINT;
export const MULTI_TARGET_ENDPOINT = Object.assign({
  maxConnections: -1,
}, TARGET_ENDPOINT);

export const OVERLAY_ARROW = {
  width: 10,
  length: 12,
  location: -6,
  foldback: 0.8,
  paintStyle: {
    fill: '#231F20',
  },
};
