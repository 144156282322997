/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i4 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i5 from "../../partials/transformation-order-transaction-confirm/transformation-order-transaction-confirm.component.ngfactory";
import * as i6 from "../../../../shared/directives/notification-as-wide-dialog-content.directive";
import * as i7 from "../../../../shared/services/style-loader.service";
import * as i8 from "../../partials/transformation-order-transaction-confirm/transformation-order-transaction-confirm.component";
import * as i9 from "../../../../entities/ecosystem/services/ecosystem.service";
import * as i10 from "../../../../entities/ecosystem/services/ecosystem-file.service";
import * as i11 from "../../../admin/services/request-file-sender.service";
import * as i12 from "../../../../entities/ecosystem/services/ecosystem-file-junctions.service";
import * as i13 from "../../../../entities/ecosystem/services/ecosystem-file-to-junction.service";
import * as i14 from "../../../ecosystem-manager/services/transform-order.service";
import * as i15 from "../../../../shared/services/log-notification.service";
import * as i16 from "@ngx-translate/core";
import * as i17 from "../../../../shared/pipes/capitalize.pipe";
import * as i18 from "@angular/forms";
import * as i19 from "../../../admin/services/request-file.service";
import * as i20 from "./transformation-order-transaction-confirm-dialog.component";
import * as i21 from "../../../../shared/services/confirm-order-dialog.service";
var styles_TransformationOrderTransactionConfirmDialogComponent = [];
var RenderType_TransformationOrderTransactionConfirmDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TransformationOrderTransactionConfirmDialogComponent, data: {} });
export { RenderType_TransformationOrderTransactionConfirmDialogComponent as RenderType_TransformationOrderTransactionConfirmDialogComponent };
export function View_TransformationOrderTransactionConfirmDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "md-dialog-content", [["class", "order-transaction-confirmation__dialog"]], [[2, "mat-dialog-content", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MdPrefixRejector, [[2, i1.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i1.MdDialogContent, [], null, null), i0.ɵdid(3, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "order-transaction-confirmation__dialog--hidden": 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "dialog-close-button", [["class", "order-transaction-confirmation__dialog-close-button"]], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DialogCloseButtonComponent_0, i3.RenderType_DialogCloseButtonComponent)), i0.ɵdid(6, 49152, null, 0, i4.DialogCloseButtonComponent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "transformation-order-transaction-confirm", [["appNotificationAsWideDialogContent", ""]], null, [[null, "onConfirm"], [null, "onCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onConfirm" === en)) {
        var pd_0 = (_co.openConfirmation($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCancel" === en)) {
        var pd_1 = (_co.mdDialogRef.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_TransformationOrderTransactionConfirmComponent_0, i5.RenderType_TransformationOrderTransactionConfirmComponent)), i0.ɵdid(8, 212992, null, 0, i6.NotificationAsWideDialogContentDirective, [i1.MdSnackBar, i1.MdDialogRef, i7.StyleLoaderService], null, null), i0.ɵdid(9, 114688, null, 0, i8.TransformationOrderTransactionConfirmComponent, [i9.EcosystemService, i10.EcosystemFileService, i11.RequestFileSenderService, i12.EcosystemFileJunctionService, i13.EcosystemFileToJunctionService, i14.TransformOrderService, i15.LogNotificationService, i16.TranslateService, i17.CapitalizePipe, i18.FormBuilder], { disabled: [0, "disabled"], showESMText: [1, "showESMText"], id: [2, "id"], step_id: [3, "step_id"] }, { onConfirm: "onConfirm", onCancel: "onCancel" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "order-transaction-confirmation__dialog"; var currVal_2 = _ck(_v, 4, 0, _co.confirmOrderDialogService.isConfirmDialogOpen); _ck(_v, 3, 0, currVal_1, currVal_2); _ck(_v, 8, 0); var currVal_4 = _co.mdDialogData.disabled; var currVal_5 = _co.mdDialogData.showESMText; var currVal_6 = _co.mdDialogData.id; var currVal_7 = _co.mdDialogData.step_id; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_3 = i0.ɵnov(_v, 6).applyClassPosition; _ck(_v, 5, 0, currVal_3); }); }
export function View_TransformationOrderTransactionConfirmDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "transformation-order-transaction-confirm-dialog", [], null, null, null, View_TransformationOrderTransactionConfirmDialogComponent_0, RenderType_TransformationOrderTransactionConfirmDialogComponent)), i0.ɵprd(512, null, i11.RequestFileSenderService, i11.RequestFileSenderService, [i19.RequestFileService]), i0.ɵdid(2, 49152, null, 0, i20.TransformationOrderTransactionConfirmDialogComponent, [i1.MD_DIALOG_DATA, i1.MdDialogRef, i21.ConfirmOrderDialogService, i11.RequestFileSenderService, i15.LogNotificationService], null, null)], null, null); }
var TransformationOrderTransactionConfirmDialogComponentNgFactory = i0.ɵccf("transformation-order-transaction-confirm-dialog", i20.TransformationOrderTransactionConfirmDialogComponent, View_TransformationOrderTransactionConfirmDialogComponent_Host_0, {}, {}, []);
export { TransformationOrderTransactionConfirmDialogComponentNgFactory as TransformationOrderTransactionConfirmDialogComponentNgFactory };
