<passport-catalog-name *ngIf="passport.getProductName()"
                       [passport]="passport"
                       [showCopyId] = "showCopyId"
                       [multiLanguage]="stateService.multilanguage"
                       [showMenu]="showPassportNameMenu"
                       class="form-group__content--row
                              passport-edit__header--name-container
                              passport-edit__header--name
                              passport-edit__header--name-short
                              passport-edit__header--name-container-fixed-width
">
  <ng-content passport-name-menu select="[passport-name-menu]"></ng-content>
</passport-catalog-name>
<div class="passport-edit__header--input-group" (click)="openGenerationNameDialog($event)">
    <md-input-container class="form-group__field passport-edit__header--input-disabled">
    <input
        mdInput
        autocomplete="off"
        [value]="passport.name"
        placeholder="{{ 'Generic name' | translate }}"
        [disabled]="true"
    >
    </md-input-container>
    <md-input-container class="form-group__field passport-edit__header--input-disabled">
    <input
        mdInput
        autocomplete="off"
        [value]="passport.english_name || ''"
        placeholder="{{ 'English version of the generic name' | translate }}"
        [disabled]="true"
    >
    </md-input-container>
    <md-input-container class="form-group__field passport-edit__header--input-disabled">
    <input
        mdInput
        autocomplete="off"
        [value]="passport.isCatalogueProduct() ? passport.manufacturer || '' : passport.typology || ''"
        placeholder="{{ (passport.isCatalogueProduct() ? 'Name of the manufacturer' : 'Typology') | translate }}"
        [disabled]="true"
    >
    </md-input-container>
    <md-input-container class="form-group__field passport-edit__header--input-disabled">
    <input
        mdInput
        autocomplete="off"
        [value]="passport.isCatalogueProduct() ? passport.trade_name || '' : passport.format || ''"
        placeholder="{{ (passport.isCatalogueProduct() ? 'Trade name of the product' : 'Format/ Dimensions' )| translate }}"
        [disabled]="true"
    >
    </md-input-container>
    <md-input-container class="form-group__field passport-edit__header--input-disabled">
    <input
        mdInput
        autocomplete="off"
        [value]="passport.isCatalogueProduct() ? passport.product_refrence || '' : passport.additional || ''"
        placeholder="{{ (passport.isCatalogueProduct() ? 'Product reference (if different from the trade name)' : 'Additional information') | translate }}"
        [disabled]="true"
    >
    </md-input-container>
    <md-input-container class="form-group__field passport-edit__header--input-disabled">
    <input
        mdInput
        autocomplete="off"
        [value]="passport.product_passport_id ? passport.product_passport_id : '' "
        placeholder="{{ 'Circular Passport ID' | translate }}"
        [disabled]="true"
    >
    </md-input-container>
    <div style="margin-top:5%">
    <ul class="button-group__list name-generation-dialog__button-group">
    <li class="button-group__item name-generation-dialog__button">
        <div class="name-generation-dialog__field" >
        <md-input-container>
            <input mdInput autocomplete="off" [value]="passport.version || ''"
                   placeholder="{{ 'Product version' | translate }}"
            [disabled]="true">
        </md-input-container>
        </div>
    </li>

    </ul>
</div>
</div>
