import { OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DepositCustomMultiSelectComponent } from '../deposit-custom-multi-select.component';
var MultiSelectMdSelectInputDirective = /** @class */ (function () {
    function MultiSelectMdSelectInputDirective(select) {
        this.select = select;
        this.chosenOptions = new Set();
        this.selectOptionOrder = {};
        this.selectOptions = [];
    }
    Object.defineProperty(MultiSelectMdSelectInputDirective.prototype, "options", {
        set: function (pairs) {
            this.createOptions(pairs);
            this.select.allOptions = this.selectOptions;
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectMdSelectInputDirective.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    MultiSelectMdSelectInputDirective.prototype.registerOnTouched = function (fn) {
        this.onTouch = fn;
    };
    MultiSelectMdSelectInputDirective.prototype.createOptions = function (keyValuePair) {
        var _this = this;
        this.selectOptions = keyValuePair.map(function (pair) {
            return ({ view: pair.value, value: pair.key, selected: _this.chosenOptions.has(pair.key) });
        });
        this.selectOptionOrder = keyValuePair.reduce(function (acc, pair, i) {
            acc[pair.key + ''] = i;
            return acc;
        }, {});
    };
    MultiSelectMdSelectInputDirective.prototype.setDisabledState = function (isDisabled) {
    };
    MultiSelectMdSelectInputDirective.prototype.writeValue = function (obj) {
        var _this = this;
        if (Array.isArray(obj)) {
            this.chosenOptions = new Set(obj);
            this.selectOptions.forEach(function (item) { item.selected = _this.chosenOptions.has(item.value); });
        }
    };
    MultiSelectMdSelectInputDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.select.changeOption.subscribe(function (value) {
            var option = _this.selectOptions.find(function (item, i) { return item.value === value; });
            if (option) {
                if (_this.chosenOptions.has(value)) {
                    _this.chosenOptions.delete(value);
                    option.selected = false;
                }
                else {
                    _this.chosenOptions.add(value);
                    option.selected = true;
                }
                _this.onChange(Array.from(_this.chosenOptions).sort(function (previous, next) { return _this.sort(previous, next); }));
            }
        });
    };
    MultiSelectMdSelectInputDirective.prototype.sort = function (previous, next) {
        return this.selectOptionOrder[previous + ''] - this.selectOptionOrder[next + ''];
    };
    MultiSelectMdSelectInputDirective.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return MultiSelectMdSelectInputDirective;
}());
export { MultiSelectMdSelectInputDirective };
