import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ReportService } from '../../../../../dashboards/admin/services/report.service';
import { MAP_IMPORT_PROGRESS_STATUS } from './report-progress.const';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/report.service";
var ReportProgressService = /** @class */ (function () {
    function ReportProgressService(reportApiService) {
        this.reportApiService = reportApiService;
        this.resetProgress$ = new EventEmitter();
        this.showImportProgress = false;
        this.showImportProgressSubscription = new Subject();
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.NOT_STARTED;
        this.zipfile_link = null;
    }
    ReportProgressService.prototype.setProgressModeProgressModal = function () {
        this.showImportProgress = true;
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.PROGRESS;
    };
    ReportProgressService.prototype.setErrorModeProgressModal = function () {
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.ERROR;
    };
    ReportProgressService.prototype.setSuccessModeProgressModal = function (zipfile_link) {
        this.showImportProgress = true;
        this.zipfile_link = zipfile_link;
        this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.SUCCESS;
    };
    ReportProgressService.prototype.canProgressModalBeClosed = function () {
        this.statusImportProgress === MAP_IMPORT_PROGRESS_STATUS.SUCCESS;
    };
    ReportProgressService.prototype.closeImport = function () {
        this.reportApiService.acknowledgeReport().subscribe();
    };
    ReportProgressService.prototype.canGenerateReport = function () {
        return this.statusImportProgress !== MAP_IMPORT_PROGRESS_STATUS.PROGRESS;
    };
    ReportProgressService.ngInjectableDef = i0.defineInjectable({ factory: function ReportProgressService_Factory() { return new ReportProgressService(i0.inject(i1.ReportService)); }, token: ReportProgressService, providedIn: "root" });
    return ReportProgressService;
}());
export { ReportProgressService };
