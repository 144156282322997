import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { Keyword } from '../models/keyword.class';

@Injectable()
export class PassportKeywordService extends CrudService {
  protected namespace = 'passport-keywords';
  protected namespaceSingular = 'passport-keyword';
  protected ModelClass = Keyword;
}
