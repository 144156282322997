<div class="esm-catalog">
  <deposit-need-map [beforeMapUpdate]="beforeUpdateMap" [updateMap]="updateMap"
    (onChangeDepositLocationIds)="setChosenLocationsById($event)"></deposit-need-map>
  <section class="esm-catalog__choice type--multi-column type--offset-bottom-s type--offset-top-xs">
    <ul class="esm-catalog__choice-list type--align-start">
      <li class="esm-catalog__choice-item search-long--xxl-responsive search-long--xxl-responsive-l">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input test-id="search" mdInput autocomplete="off" placeholder="{{ 'Search' | translate }}"
            [formControl]="search">
        </md-input-container>
      </li>
    </ul>
    <ul class="esm-catalog__choice-list type--left-indent type--align-start type--gap type--wrap">
      <li class="esm-catalog__choice-item  select--len-fixed">
        <md-select class="select-group" placeholder="{{ 'Deposit owner' | translate }}" [(ngModel)]="depositOwnerId"
          (ngModelChange)="getRecords()">
          <md-option>{{'Any' | translate}}</md-option>
          <md-option *ngFor="let depositOwner of depositOwners" [value]="depositOwner.id">{{depositOwner.name}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item">
        <md-select class="select-group" placeholder="{{ 'Site' | translate }}" [(ngModel)]="depositLocationId"
          (ngModelChange)="getRecords()">
          <md-option>{{'Any' | translate}}</md-option>
          <md-option *ngFor="let location of depositLocations" [value]="location.id">{{location.name}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput test-id="available-date-from-filter" ng2-datetime-picker autocomplete="off" date-only="true"
            date-format="DD-MM-YYYY" placeholder="{{ 'Available FROM' | translate }}" [(ngModel)]="availableDateFrom"
            (ngModelChange)="getRecords()">
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-dash">-</li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput ng2-datetime-picker autocomplete="off" date-only="true" date-format="DD-MM-YYYY"
            placeholder="{{ 'Available TO' | translate }}" [(ngModel)]="availableDateTo" (ngModelChange)="getRecords()">
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--gray-sort"></i>
        <md-select class="select-group" [(ngModel)]="sortOrder" (change)="getRecords()"
          placeholder="{{ 'Sort by' | translate }}">
          <md-option value="site_name">{{ 'By site name' | translate }}</md-option>
          <md-option value="name">{{ 'By name' | translate }}</md-option>
          <md-option value="deposit_owner">{{ 'By deposit owner' | translate }}</md-option>
          <md-option value="-availability_date">{{ 'By date of availability' | translate }}</md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list [records]="records" [expandable]="true" [beforeExpandObserver]="updateRecordsOnListExpand()"
    (onEndOfList)="onEndOfListTriggered()" (onExpand)="isListExpanded = $event"></custom-list>
</div>