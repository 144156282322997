import { OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Passport } from '../../../../entities/passport/models/passport.class';
import { IPassportRatingWithLocalPassportFilter } from '../../../../entities/passport/values/passport-rating/passport-rating-filter.interface';
import { NomenclatureItem } from '../../../../entities/nomenclature/models/nomenclature-item.class';
import { CategoryItem } from '../../../../entities/category/models/category-item.class';
import { IAgentFilterState } from '../../../../dashboards/agent/interfaces/agent-passport-dashboard.component';
import { IManufacturerFilterState } from '../../../../dashboards/manufacturer/interfaces/manufacturer-dashboard.component';
import { ISpecialAdminFilterState } from '../../../../dashboards/specialadmin/dashboard/specialAdmin-passport-dashboard.component';

export interface IFilterState {
    sortBy: string;
    search: string;
    families: number[];
    materials: NomenclatureItem[];
    categories: CategoryItem[];
    searchId: string[];
    selectedOptions: any[];
    databases: number[];
    dataProviders: number[];
    dataRepresentativity: string[];
    epdType: number[];
    rating: IPassportRatingWithLocalPassportFilter;
}

export abstract class DashboardComponentBase<T extends IFilterState> implements OnDestroy {

    public filterStates: { [key: string]: T } = {};
    public currentTab: string = '';
    public searchTermsSubject = new Subject<string>();
    public searchSubscription: Subscription;
    public passports: Passport[] = [];
    public isLoading: boolean = false;
    public pagination: any;
    public passportsRequestSubscription: Subscription | null = null;
    public searchPassportIdSubject = new Subject<string>();
    public searchPassportIdSubscription: Subscription;
    public versionReference: number | null = null;
    public allPagesSelected = false;

    // The constructor initializes subscriptions for handling search input with debouncing
    constructor() {
        this.searchSubscription = this.searchTermsSubject.pipe(
            debounceTime(300)
        ).subscribe(searchTerm => {
            this.filterStates[this.currentTab].search = searchTerm;
            this.loadDataForTab(true);
        });

        this.searchPassportIdSubscription = this.searchPassportIdSubject.pipe(
            debounceTime(300)
        ).subscribe(passportIdString => {
            const passportIds = passportIdString.split(',').map(id => id.trim());
            this.filterStates[this.currentTab].searchId = passportIds.length ? passportIds : [];
            this.loadDataForTab(true);
        });
    }

    ngOnDestroy(): void {
        this.searchSubscription.unsubscribe();
        this.searchPassportIdSubscription.unsubscribe();
        this.passportsRequestSubscription.unsubscribe();
    }

    // Checks if a given filter state belongs to an agent by looking for a specific property.
    private isAgentFilterState(state: any): state is IAgentFilterState {
        return 'showMyPassports' in state;
    }

    // Checks if a given filter state belongs to a manufacturer by looking for a specific property.
    private isManufacturerFilterState(state: any): state is IManufacturerFilterState {
        return 'status' in state;
    }

    // Checks if a given filter state belongs to a specialAdmin by looking for a specific property.
    private isSpecialAdminFilterState(state: any): state is ISpecialAdminFilterState {
        return 'seeOnlyWaitingForRating' in state;
    }


    // Abstract methods that subclasses must implement to load data based on the current filter state
    // and to return the default state of filters.
    public abstract loadDataForTab(resetPage: boolean): void;
    public abstract getDefaultFilterState(): T;

    public onSearchFilterChange(searchTerm: string): void {
        this.searchTermsSubject.next(searchTerm);
    }

    public onSearchIdFilterChange(searchId: string): void {
        this.searchPassportIdSubject.next(searchId);
    }

    public onFamilyFilterChange(families: number[]): void {
        this.filterStates[this.currentTab].families = families;
        this.loadDataForTab(true);
    }

    public onRatingFilterChange(rating: IPassportRatingWithLocalPassportFilter): void {
        this.filterStates[this.currentTab].rating = rating;
        this.loadDataForTab(true);
    }

    public onMyPassportsToggleChange(showMyPassports: boolean): void {
        const state = this.filterStates[this.currentTab];
        if (this.isAgentFilterState(state)) {
            state.showMyPassports = showMyPassports;
            this.loadDataForTab(true);
        }
    }

    public onSeeOnlyWaitingForRating(showOnlyWaitingForRating: boolean): void {
        const state = this.filterStates[this.currentTab];
        if (this.isSpecialAdminFilterState(state)) {
            state.seeOnlyWaitingForRating = showOnlyWaitingForRating;
            this.loadDataForTab(true);
        }
    }

    public onRatingChange(rating: IPassportRatingWithLocalPassportFilter): void {
        this.filterStates[this.currentTab].rating = rating;
        this.loadDataForTab(true);
    }

    public onMaterialsFilterChange(materials: NomenclatureItem[]): void {
        this.filterStates[this.currentTab].materials = materials;
        this.loadDataForTab(true);
    }

    public onCategoriesFilterChange(categories: CategoryItem[]) {
        this.filterStates[this.currentTab].categories = categories;
        this.loadDataForTab(true);
    }

    public onDataRepresentativityFilterChange(dataRepresentativity: string[]): void {
        this.filterStates[this.currentTab].dataRepresentativity = dataRepresentativity;
        this.loadDataForTab(true);
    }

    public onDatabaseFilterChange(databases: number[]): void {
        this.filterStates[this.currentTab].databases = databases;
        this.loadDataForTab(true);
    }

    public onDataProviderFilterChange(dataProviders: number[]): void {
        this.filterStates[this.currentTab].dataProviders = dataProviders;
        this.loadDataForTab(true);
    }

    public onEpdTypeFilterChange(epdType: number[]): void {
        this.filterStates[this.currentTab].epdType = epdType;
        this.loadDataForTab(true);
    }

    public onCreatorFilterChange(creators: string[]): void {
        const state = this.filterStates[this.currentTab];
        if (this.isAgentFilterState(state) || this.isSpecialAdminFilterState(state)) {
            state.company_creators = creators;
            this.loadDataForTab(true);
        }
    }

    public onSortChange(sortBy: string): void {
        this.filterStates[this.currentTab].sortBy = sortBy;
        this.loadDataForTab(true);
    }

    public onVersionClicked(passport: Passport): void {
        this.versionReference = this.versionReference ? null : (passport.link_reference ? passport.link_reference : passport.id);
        this.loadDataForTab(true);
    }

    public onStatusChanged(status: number[]): void {
        const state = this.filterStates[this.currentTab];
        if (this.isAgentFilterState(state) || this.isManufacturerFilterState(state)) {
            state.status = status;
            this.loadDataForTab(true);
        }
    }
    
    public onSelectAllPagesChange(allPagesSelected: boolean): void {
        this.allPagesSelected = allPagesSelected;
    }

    public onPageChange(page: number): void {
        this.pagination.current_page = page;
        this.loadDataForTab(false);
    }

    public filtersAreDefault(): boolean {
        const currentFilters = this.filterStates[this.currentTab];
        const defaultFilters = this.getDefaultFilterState();

        // Obtenir toutes les clés de l'état du filtre
        // WARNING (suppose que les clés de currentFilters et defaultFilters sont identiques)
        const allKeys = Object.keys(currentFilters);

        return allKeys.every(key => {
            const currentValue = currentFilters[key];
            const defaultValue = defaultFilters[key];

            // Si les deux valeurs sont des tableaux, utiliser la méthode arraysEqual pour comparer
            if (Array.isArray(currentValue) && Array.isArray(defaultValue)) {
                return this.arraysEqual(currentValue, defaultValue);
            }

            //Comparaison pour les objets
            if (typeof currentValue === 'object' && currentValue !== null) {
                return JSON.stringify(currentValue) === JSON.stringify(defaultValue);
            }

            // Comparaison directe pour les autres types
            return currentValue === defaultValue;
        });
    }

    // Converts the current state of filters into an object format suitable for making API requests.
    // Special handling is done for the rating filter to convert it into a format expected by the API.
    public convertFilterStateToObj(filterState: IAgentFilterState | IManufacturerFilterState): { [key: string]: any } {
        const requestPayload = {};
        Object.entries(filterState).forEach(([key, value]) => {
            if (value !== undefined) {
                if (key === 'rating') {
                    let apiFormat = this.convertRatingToApiFormat(value);
                    for (let [ratingKey, ratingValue] of Object.entries(apiFormat)) {
                        if (ratingValue !== 0)
                            requestPayload[ratingKey] = ratingValue;
                    }
                }
                if (key === 'dataRepresentativity') {
                    let iso = this.filterStates[this.currentTab].dataRepresentativity.map(provider => provider === "Unknown" ? "Unknown" : provider.split(' - ')[1]);
                    requestPayload[key] = iso;
                }
                else {
                    requestPayload[key] = value;
                }
            }
        });
        if (this.versionReference)
            requestPayload['versionReference'] = this.versionReference;
        if (this.allPagesSelected)
            requestPayload['allPagesSelected'] = this.allPagesSelected;
        return requestPayload;
    }

    /**
      * Converts the current rating filter state into a format expected by the API.
      * Each rating state (e.g., black, color) is associated with a numeric value indicating its selection state:
      * - 1 for black
      * - 2 for color
      * - 3 for both selected
      * 
      * @param ratingState The current rating state to convert.
      * @return An object formatted for the API, with numeric values representing the selection state of each rating.
     */
    private convertRatingToApiFormat(ratingState: IPassportRatingWithLocalPassportFilter): any {
        const apiRatingFormat: { [key: string]: number } = {};

        Object.entries(ratingState).forEach(([key, value]) => {
            if (typeof value === 'boolean') {
                apiRatingFormat[key] = value ? 1 : 0;
            } else if (typeof value === 'object' && value !== null) {
                const subValues: boolean[] = Object.values(value).map(val => !!val);
                const bothSelected = subValues.every(val => val);
                const noneSelected = subValues.every(val => !val);

                if (!noneSelected) {
                    apiRatingFormat['rating_' + key] = bothSelected ? 3 : (subValues[0] ? 2 : 1);
                }
            }
        });
        return apiRatingFormat;
    }

    // Helper pour comparer deux tableaux indépendamment de l'ordre
    private arraysEqual(arr1: number[], arr2: number[]): boolean {
        if (!arr1 || !arr2) return arr1 === arr2; // Gère null/undefined
        if (arr1.length !== arr2.length) return false;

        let sortedArr1 = arr1.slice().sort();
        let sortedArr2 = arr2.slice().sort();
        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }
        return true;
    }
}
