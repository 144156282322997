import {Component, NgZone, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import { MAIN_TAB_STEPS, SECONDARY_TAB_STEPS } from './admin-reporting.const';
import {Reports} from '../../models/reports.class';
import {ReportService} from '../../services/report.service';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MdDialog, MdDialogConfig } from '@angular/material';
import * as moment from 'moment';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import {DepositLocation} from '../../../../entities/deposit/models/deposit-location.class';
import {DepositLocationService} from '../../../../entities/deposit/services/deposit-location.service';
import {DepositTag} from '../../../../entities/deposit/models/deposit-tag.class';
import { Utils } from '../../../../shared/utils/utils.class';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';

@Component({
  moduleId: module.id,
  selector: 'admin-reporting',
  templateUrl: 'admin-reporting.component.html',
  styleUrls: ['admin-reporting.component.scss']
})
export class AdminReportingComponent implements OnInit {
  public mainTabSteps = MAIN_TAB_STEPS;
  public secondaryTabSteps = SECONDARY_TAB_STEPS;
  public mainTabStep: number = MAIN_TAB_STEPS.NEW_REPORT;
  public idSecondaryTabStep: number = SECONDARY_TAB_STEPS.CARBON_FOOT_PRINT;
  public reports = new Reports();
  public records: Reports[] = [];
  public setMainTabCallback: Function;

  constructor(private _zone: NgZone,
              private _mdDialog: MdDialog,
              private _translate: TranslateService,
              public reportService: ReportService,
              private siteService: DepositLocationService,
              private datePipe: DatePipe,
              private globalEventsManager: GlobalEventsManager,
              public notification: LogNotificationService
  ) {}

  ngOnInit() {
    this.setMainTabCallback = this.setMainTab.bind(this);


    // getRecords has
    // long loading times for hundreds of records, move this somewhere better?
    // when adding 400 reports this pops up for each one
    // [Violation] Added non-passive event listener to a scroll-blocking <some> event.
    // Consider marking event handler as 'passive' to make the page more responsive. See <URL>
    // lazy loading?

    this.globalEventsManager.circularSignatureClick.subscribe(() => {
      this.reports.report_result = false;
    });
    this.getRecords();
  }

  setReports(date: any) {
    this.reports = date;
    this.reports.report_result = date.withPrevisualisation ? 
      ( date.result ? date.result : date.report_result) :
      {};
    if (!!this.reports.carbon_foot_print === true) {
      this.idSecondaryTabStep = SECONDARY_TAB_STEPS.CARBON_FOOT_PRINT;
    } else if (!!this.reports.circularity === true) {
      this.idSecondaryTabStep = SECONDARY_TAB_STEPS.CIRCULARITY;
    } else if (!!this.reports.material_health === true) {
      this.idSecondaryTabStep = SECONDARY_TAB_STEPS.MATERIAL_HEALTH;
    } else if (!!this.reports.financial_residual_value === true) {
      this.idSecondaryTabStep = SECONDARY_TAB_STEPS.FINANCIAL_RESIDE_VALUE;
    }
  }
  setMainTab(number: number): void {
    this._zone.run(() => this.mainTabStep = number);
  }
  setSecondaryTabStep(number: number): void {
    this._zone.run(() => this.idSecondaryTabStep = number);
  }


  getRecords() {
    this.records = [];

    const params: any = {expand: 'zipfile_link,sites,sites.tags,tags,snapshot', sort: '-created_at', processed: 1};

    this.reportService
      .get(params)
      .subscribe((reports: Reports[]) => {
        reports.forEach((report) => this.addRecord(report));
      });
  }

  addRecord(report: Reports) {
    this.records = [...this.records, new Reports(report)];
  }

  getTypeAsString(report) {
    const types = [
      report.carbon_foot_print ? this._translate.instant('Carbon footprint') : null,
      report.circularity ? this._translate.instant('Circularity') : null,
      report.material_health ? this._translate.instant('MATERIAL_HEALTH') : null,
      report.financial_residual_value ? this._translate.instant('Financial residual value') : null
    ].filter(str => !!str);
    if (types.length > 2) {
      types[2] = '\n' + types[2];
    }
    return types.join(' - ');
  }
  getTypesForTooltips(report: Reports) {
    const types: string[] = [
      report.carbon_foot_print ? `${this._translate.instant('Carbon footprint')}<br>` : null,
      report.product_stage ? `<li>${this._translate.instant('Product stage (A1-A3)')}</li>` : null,
      report.product_and_construction ? `<li>${this._translate.instant('Product & Construction Process stage (A1-A5)')}</li>` : null,
      report.embodied_carbon ? `<li>${this._translate.instant('Embodied (A1-A5 + B1-B5 + C)')}</li>` : null,
      report.ic_component ? `<li>${this._translate.instant('IC_COMPONENT_SUB')}</li>` : null,
      report.ic_construction ? `<li>${this._translate.instant('IC_CONSTRUCTION_SUB')}</li>` : null,
      report.circularity ? `<br>${this._translate.instant('Circularity')}<br><br>` : null,
      report.material_health ? `${this._translate.instant('Material health')}<br><br>` : null,
      report.financial_residual_value ? `${this._translate.instant('Financial residual value')}<br>` : null,
      report.deconstruction ? `<li>${this._translate.instant('Deconstruction')}</li>` : null,
      report.rehabilitation ? `<li>${this._translate.instant('Rehabilitation')}</li>` : null,
      report.renewal_building_components ? `<li>${this._translate.instant('Renewal of building components over 30 years')}</li>` : null
    ].filter(str => !!str);
    return types.join('');
  }
  uploadRecord(record: Reports) {
    window.open(record.zipfile_link, '_blank');
  }

  deleteRecord(record: Reports) {
    this._mdDialog.open(ConfirmDialogComponent, <MdDialogConfig>{
      data: { text: this._translate.instant('CONFIRM_DELETE_REPORT') }
    }).afterClosed()
      .subscribe((result: any) => {
        if (result) {
          record.destroy().subscribe(() => this.records.splice(this.records.indexOf(record), 1));
        }
      });
  }

  public getDateAsString(report: Reports): string {
    const localePattern = this._translate.instant('DATE_PATTERN');
    const date = moment(report.created_at);
    const hasTimeZone = !!(report.timezone_offset);
    const dateLocaleInsensitive =  hasTimeZone ? date.set({minutes: date.minutes() + report.timezone_offset}).format() : date.format();
    return this.datePipe.transform(dateLocaleInsensitive, localePattern).replace(/\s+(\S)/, '\n$1');
  }

  getTitle(sites) {
    return sites.map(site => site.name).join(', ');
  }

  getTagsAsString(tags: string[]) {
    return  (tags || []).length ? '-' + tags.join(', ') : '';
  }

  copyId(id) {
    Utils.copyToClipBoard(id);
    this.notification.success("Identifant rapport copié", 2000, true);
  }

}
