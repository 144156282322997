import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IReportResponse} from '../../../../interfaces/reportResponse.interface';
import {PIE_CHART_SIZE} from '../../../../values/pie-chart-size.const';
import {ICircularityAggregate, ICircularityCategory} from '../../../../interfaces/circularity.interface';
import { MAIN_TAB_STEPS } from '../../admin-reporting.const';
import { ReportBase } from '../report-base/report-base.class';

@Component({
  moduleId: module.id,
  selector: 'circularity',
  templateUrl: 'circularity.component.html',
  styleUrls: ['circularity.component.scss']
})
export class CircularityComponent extends ReportBase implements OnInit {
  public mostPresentProductCategory: ICircularityCategory;
  public mostCircularProductCategory: ICircularityCategory;
  public leastCircularProductCategory: ICircularityCategory;
  public aggregateData: ICircularityAggregate;
  public mainValue: number;
  public pieChartSize = PIE_CHART_SIZE;
  public isDescriptionBlock: boolean = true;
  public filteredProductCategory: object = {};
  @Input() reports: IReportResponse;
  @Input() changeTab: Function;

  constructor(private _translateService: TranslateService) {
    super();
  }
  ngOnInit() {
    this.setReportAggregateVariables();
    this.loadMainProductCategory();
  }
  public getSortedProductsBy(sortBy: string): any {
    const categories = this.reports.report_result.circularity.aggregate.category;
    if (!!categories) {
      const categoriesFiltered = this.filteredProductCategory = { ...Object.values(categories).filter(e => !!e.deposits_considered) };
      return Object.values(categoriesFiltered).sort((a, b) => a[sortBy] < b[sortBy] ? 1 : (b[sortBy] < a[sortBy] ? -1 : 0));
    }
  }
  isEmptyProductCategory(): boolean {
    return Object.keys(this.filteredProductCategory).length === 0;
  }
  loadMainProductCategory(): void {
    const sortedByGrade = this.getSortedProductsBy('circularity_grade');
    if (!this.isEmptyProductCategory()) {
      const getProductCategoryBlockData = (key): ICircularityCategory => {
        return {
          ...key,
          titleForFooter: key.category.name,
          mainValue: Math.ceil(key.circularity_grade)
        };
      };
      this.mostCircularProductCategory = getProductCategoryBlockData(sortedByGrade[0]);
      this.leastCircularProductCategory = getProductCategoryBlockData(sortedByGrade[sortedByGrade.length - 1]);
      this.mostPresentProductCategory = getProductCategoryBlockData(this.getSortedProductsBy('weight')[0]);
    }
  }

  setReportAggregateVariables() {
    const reportAggregate = this.reports.report_result.circularity.aggregate;
    for (const item in reportAggregate) {
      if (typeof reportAggregate[item] === 'number') {
        this.aggregateData = {
          ...this.aggregateData,
          [item]: reportAggregate[item]
        };
      }
    }
    this.mainValue = this.aggregateData.circularity_grade;
  }

  hideDescriptionBlock() {
    this.isDescriptionBlock = false;
  }
  goToArchive() {
    this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
  }
}
