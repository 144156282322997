/**
 * Created by aleksandr on 20.07.17.
 */
import { NotificationService } from '../notification.service';
import {
  INotificationEntry,
  INotificationEntryAdminType,
  INotificationEntryAgentType,
  INotificationEntryDoType,
  INotificationEntryEsmType,
  INotificationEntryRole,
  INotificationEntrySalesType,
  INotificationEntrySuperadminType,
  INotificationEntrySupervisorType,
  INotificationEntryTsmType,
  INotificationEntrySpecialAdminType,
  INotificationEntryManufacturerType,
  INotificationEntryTsmiType
} from '../interfaces/notification-entry.interface';
import { ActiveRecord } from '../../models/active-record.class';
import { ENotificationParamObjectType, INotificationParam } from '../interfaces/notification-param.interface';
import { Utils } from '../../utils/utils.class';
import { PASSPORT_UNITS } from '../../../entities/passport/values/passport-units.const';
import { TranslateService } from '@ngx-translate/core';
import { ServiceLocator } from '../../services/service-locator';
import moment from 'moment';

export class NotificationEntry extends ActiveRecord implements INotificationEntry, Utils.IHasCreatedAt {

  public id: number;
  public user_id: number;
  public message_id: number;
  public type: number;
  public role: number;
  public status: number;
  public created_at: string;
  public updated_at: string;
  public params: INotificationParam[];
  public successful = 'You activated your account successfully. Welcome to Upcyclea !';
  public PASSPORT_UNITS = PASSPORT_UNITS;

  protected provider = NotificationService;
  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  private _cachedMessage: string;

  fields(): string[] {
    return ['id', 'user_id', 'message_id', 'type', 'status', 'params', 'role', 'created_at', 'updated_at'];
  }

  public getAvatar(): string {
    return null;
  }

  public getTimeSpanText(): string {
    return Utils.getTimeLeftString(this.created_at, this.translateService);
  }

  public getNotificationText(): string {
    // console.log('nottigdjsqdjsq');
    if (!this._cachedMessage) {
      this._cachedMessage = this.castNotificationText();
    }
    return this._cachedMessage;
  }

  public castNotificationText(): string {
    // console.log('ROLEIS', this.role);
    switch (this.role) {
      case INotificationEntryRole.ROLE_ADMIN :
        return this.getAdminNotificationText();
      case INotificationEntryRole.ROLE_AGENT :
        return this.getAgentNotificationText();
      case INotificationEntryRole.ROLE_DEPOSITOWNER :
        return this.getDoNotificationText();
      case INotificationEntryRole.ROLE_ESM :
        return this.getEsmNotificationText();
      case INotificationEntryRole.ROLE_SALE :
        return this.getSaleNotificationText();
      case INotificationEntryRole.ROLE_SUPERVISOR :
        return this.getSupervisorNotificationText();
      case INotificationEntryRole.ROLE_TSM :
        return this.getTsmNotificationText();
      case INotificationEntryRole.ROLE_SUPERADMIN :
        return this.getSuperadminNotificationText();
      case INotificationEntryRole.ROLE_SPECIALADMIN :
        return this.getSpecialAdminNotificationText();
      case INotificationEntryRole.ROLE_MANUFACTURER:
        return this.getManufacturerNotificationText();
      case INotificationEntryRole.ROLE_TSMI:
        return this.getTsmiNotificationText();
      default:
        return 'N/A ROLE NOT FOUND';
    }
  }

  public getAdminNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    switch (this.type) {
      case INotificationEntryAdminType.TYPE_ADMIN_ECOSYSTEM_IN_PROCESS :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        return this.translate('NOTIFICATION_TYPE_ADMIN_ECOSYSTEM_IN_PROCESS', xField.name, yField.name);
      case INotificationEntryAdminType.TYPE_ADMIN_ECOSYSTEM_DONE :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ADMIN_ECOSYSTEM_DONE', xField.name);
      case INotificationEntryAdminType.TYPE_ADMIN_USER_ACTIVATED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_LOGIN);
        return this.translate('NOTIFICATION_TYPE_ADMIN_USER_ACTIVATED', xField.name);
      case INotificationEntryAdminType.TYPE_ADMIN_USER_INSCRIPTION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ADMIN);
        return this.translate('NOTIFICATION_TYPE_ADMIN_USER_INSCRIPTION', xField.name);
      case INotificationEntryAdminType.TYPE_ADMIN_ID_SITE_DELETED:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DELETED_LOCATION_NAME);
        return this.translate('NOTIFICATION_TYPE_ADMIN_ID_SITE_DELETED', yField.value_string);
      case INotificationEntryAdminType.TYPE_ADMIN_ID_SITE_EXPIRED_TWO_MONTHS:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SITE_EXPIRED_TWO_MONTHS', xField.name);
      case INotificationEntryAdminType.TYPE_ADMIN_ID_SITE_EXPIRED_ONE_WEEK:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SITE_EXPIRED_ONE_WEEK', xField.name);
      case INotificationEntryAdminType.TYPE_ADMIN_ID_SITE_EXPIRED_TOMORROW:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SITE_EXPIRED_TOMORROW', xField.name);
      case INotificationEntryAdminType.TYPE_ADMIN_ID_SITE_EXPIRED:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SITE_EXPIRED', xField.name);
      case INotificationEntryAdminType.TYPE_ADMIN_ID_SITE_CREATED_BY_SUPERADMIN:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_ADMIN_SITE_CREATED_BY_SUPERADMIN', xField.name);
      case INotificationEntryAdminType.TYPE_ADMIN_ID_SITE_DELETE_BY_SUPERADMIN:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DELETED_LOCATION_NAME);
        return this.translate('NOTIFICATION_TYPE_ADMIN_SITE_DELETE_BY_SUPERADMIN', xField.value_string);
      case INotificationEntryAdminType.TYPE_ADMIN_ID_NUMBER_OF_ESM:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_CHANGED_MAX_ESM);
        return this.translate('NOTIFICATION_TYPE_ADMIN_NUMBER_OF_ESM', xField.name || '0');
      case INotificationEntryAdminType.TYPE_ADMIN_ID_CHANGE_END_OF_WORK:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_EXPIRATION_DATE_CHANGE);
        return this.translate('NOTIFICATION_TYPE_ADMIN_CHANGE_END_OF_WORK'
          , xField.name, this.parseDate(yField.name));
      default:
        return 'N/A';
    }
  }

  public getEsmNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    let zField = <INotificationParam> {name: 'Z'};
    switch (this.type) {
      case INotificationEntryEsmType.TYPE_ESM_NEED_PUBLISHED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SALE);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_ESM_NEED_PUBLISHED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_NEED_EDITED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_ESM_NEED_EDITED', xField.name);
      case INotificationEntryEsmType.TYPE_ESM_TF_NEW :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_UNSEEN_TRANSFORMATIONS);
        return this.translate('NOTIFICATION_TYPE_ESM_TF_NEW', xField.name);
      case INotificationEntryEsmType.TYPE_ESM_TF_EDITED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_TSM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_ESM_TF_EDITED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_REJECTED_BY_TSM :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_TSM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_REJECTED_BY_TSM', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_REJECTED_BY_DO :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_REJECTED_BY_DO', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_ACCEPTED_BY_TSM :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_TSM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_ACCEPTED_BY_TSM', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_ACCEPTED_BY_DO :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_ACCEPTED_BY_DO', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_ASK_FOR_MODIFICATION_BY_TSM :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_TSM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION_ORDER);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_ASK_FOR_MODIFICATION_BY_TSM', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_ASK_FOR_MODIFICATION_BY_DO :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_ORDER);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_ASK_FOR_MODIFICATION_BY_DO', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_CONFIRMED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_TSM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_CONFIRMED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_OFFER_ACCEPTED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SALE);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_OFFER_ACCEPTED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_OFFER_ACCEPTED_PUBLIC :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_OFFER_ACCEPTED_PUBLIC', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ASK_FOR_COUNTER_PROPOSAL :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SALE);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_ESM_ASK_FOR_COUNTER_PROPOSAL', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ASK_FOR_COUNTER_PROPOSAL_PUBLIC :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_ESM_ASK_FOR_COUNTER_PROPOSAL_PUBLIC', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ECOSYSTEM_DONE :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_ECOSYSTEM_DONE', xField.name);
      case INotificationEntryEsmType.TYPE_ESM_NEW_MESSAGE :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_NEW_MESSAGE', xField.name);
      case INotificationEntryEsmType.TYPE_ESM_OFFER_REFUSED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SALE);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_OFFER_REFUSED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_SUCCESSFUL_ACTIVATION :
        return this.translate('NOTIFICATION_TYPE_ESM_SUCCESSFUL_ACTIVATION');
      case INotificationEntryEsmType.TYPE_ESM_DEPOSIT_PUBLISHED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_ESM_DEPOSIT_PUBLISHED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ECOSYSTEM_OFFER_CONFIRMED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SALE);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_ESM_ECOSYSTEM_OFFER_CONFIRMED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ECOSYSTEM_ORDER_CONFIRMED_BY_DO :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_ECOSYSTEM_ORDER_CONFIRMED_BY_DO', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ECOSYSTEM_ORDER_CONFIRMED_BY_TSM :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_TSM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_ECOSYSTEM_ORDER_CONFIRMED_BY_TSM', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_ACCEPTED_BY_TSMI :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_ACCEPTED_BY_TSM', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_ASK_FOR_MODIFICATION_BY_TSMI :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_ASK_FOR_MODIFICATION_BY_TSMI', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_REJECTED_BY_TSMI :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_ESM_ORDER_REJECTED_BY_TSM', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_NEED_DELETED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_NEED_DELETED);
        return this.translate('NOTIFICATION_TYPE_NEED_DELETED', xField.name);
      case INotificationEntryEsmType.TYPE_ESM_PASSPORT_CHANGED_UNIT_BY_MANUFACTURER :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_PASSPORT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('TYPE_ESM_PASSPORT_CHANGED_UNIT_BY_MANUFACTURER', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_INVALIDATED_BY_TSMI :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('TYPE_ESM_ORDER_INVALIDATED_BY_TSMI', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_INVALIDATED_BY_TSM :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_TSM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('TYPE_ESM_ORDER_INVALIDATED_BY_TSM', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_INVALIDATED_BY_DO :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('TYPE_ESM_ORDER_INVALIDATED_BY_DO', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_PROBLEM_BY_DO :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('TYPE_ESM_ORDER_PROBLEM_BY_DO', xField.name, yField.name, zField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_PROBLEM_BY_TSM :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_TSM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('TYPE_ESM_ORDER_PROBLEM_BY_TSM', xField.name, yField.name, zField.name);
      case INotificationEntryEsmType.TYPE_ESM_ORDER_PROBLEM_BY_SALE :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SALE);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('TYPE_ESM_ORDER_PROBLEM_BY_SALE', xField.name, yField.name, zField.name);
      case INotificationEntryEsmType.TYPE_ESM_DEPOSIT_ORDER_DEPOSIT_PASSPORT_CHANGED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_DEPOSIT_ORDER_DEPOSIT_PASSPORT_CHANGED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_DEPOSIT_ORDER_INDEPENDENT_PASSPORT_CHANGED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_DEPOSIT_ORDER_INDEPENDENT_PASSPORT_CHANGED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_DEPOSIT_ORDER_ENTITY_GENERAL_CHANGED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_DEPOSIT_ORDER_ENTITY_GENERAL_CHANGED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_DEPOSIT_ORDER_ENTITY_OTHER_CHANGED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_DEPOSIT_ORDER_ENTITY_OTHER_CHANGED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_DEPOSIT_ORDER_ENTITY_PASSPORT_CHANGED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_ESM_DEPOSIT_ORDER_ENTITY_PASSPORT_CHANGED', xField.name, yField.name);
      case INotificationEntryEsmType.TYPE_ESM_NEW_MATCHES :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_MATCHES_COUNT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_PASSPORT_MATCHES_COUNT);
        let depositMatches, passportMatches;
        try { depositMatches = JSON.parse(xField.value_string) } catch { depositMatches = [] };
        try { passportMatches = JSON.parse(yField.value_string) } catch { passportMatches = [] };
        let depositMatchesCount = depositMatches.length;
        passportMatches = Array.isArray(passportMatches) ? passportMatches : Object.values(passportMatches);
        let passportMatchesCount = passportMatches.reduce((totalCount, passportMatch) => totalCount + Number(passportMatch.deposit_count), 0)
        depositMatchesCount = depositMatchesCount > 99 ? "99+" : depositMatchesCount;
        passportMatchesCount = passportMatchesCount > 99 ? "99+" : passportMatchesCount;
        return this.translate('NOTIFICATION_TYPE_ESM_NEW_MATCHES', depositMatchesCount, passportMatchesCount);

      default:
        return 'N/A';
    }
  }

  public getSupervisorNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    switch (this.type) {
      case INotificationEntrySupervisorType.TYPE_SUPERVISOR_DEPOSIT_NEW :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        return this.translate('NOTIFICATION_TYPE_SUPERVISOR_DEPOSIT_NEW', xField.name, yField.name);
      case INotificationEntrySupervisorType.TYPE_SUPERVISOR_DEPOSIT_NEW_PASSPORT :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        return this.translate('NOTIFICATION_TYPE_SUPERVISOR_DEPOSIT_NEW_PASSPORT', xField.name, yField.name);
      case INotificationEntrySupervisorType.TYPE_SUPERVISOR_NEED_NEW :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SALE);
        return this.translate('NOTIFICATION_TYPE_SUPERVISOR_NEED_NEW', xField.name, yField.name);
      case INotificationEntrySupervisorType.TYPE_SUPERVISOR_TRANSFORM_NEW :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_USER_TSM);
        return this.translate('NOTIFICATION_TYPE_SUPERVISOR_TRANSFORM_NEW', xField.name, yField.name);
      case INotificationEntrySupervisorType.TYPE_SUPERVISOR_SUCCESSFUL_ACTIVATION :
        return this.translate('NOTIFICATION_TYPE_SUPERVISOR_SUCCESSFUL_ACTIVATION');
      case INotificationEntrySupervisorType.TYPE_SUPERVISOR_AGENT_IMPORT :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_AGENT);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_AGENT_IMPORT', xField.name);
      case INotificationEntrySupervisorType.TYPE_SUPERVISOR_DEPOSIT_EDITED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_AGENT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_SUPERVISOR_DEPOSIT_EDITED', xField.name, yField.name);
      default:
        return 'N/A';
    }
  }

  public getTsmiNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    let zField = <INotificationParam> {name: 'Z'};
    // console.log(this);
    switch (this.type) {
      case INotificationEntryTsmiType.TYPE_TSMI_TF_ASK_FOR_MODIFICATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_TSMI_TF_ASK_FOR_MODIFICATION', xField.name);
      case INotificationEntryTsmiType.TYPE_TSMI_NEW_TF_PUBLISHED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_TSMI_NEW_TF_PUBLISHED', xField.name);
      case INotificationEntryTsmiType.TYPE_TSMI_ORDER_WAITING_FOR_VALIDATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
       // zField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION_SITE);
          return this.translate('NOTIFICATION_TYPE_TSM_ORDER_WAITING_FOR_VALIDATION', xField.name, yField.name);
      case INotificationEntryTsmiType.TYPE_TSMI_ORDER_VALIDATED_MANUALLY :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION_SITE); // Z : name of the site
          return this.translate('NOTIFICATION_TYPE_TSM_ORDER_VALIDATED_MANUALLY', xField.name, yField.name, zField.name);
      case INotificationEntryTsmiType.TYPE_TSMI_ORDER_ENDS_TODAY :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_TSM_ENDS_TODAY', xField.name, yField.name);
      case INotificationEntryTsmiType.TYPE_TSMI_ORDER_CANCELLED :
        return this.translate('NOTIFICATION_TYPE_TSM_ORDER_CANCELLED');
      case INotificationEntryTsmiType.TYPE_TSMI_ORDER_VALIDATED_MANUALLY_WITHOUT_SITE :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_TSMI_ORDER_VALIDATED_MANUALLY_WITHOUT_SITE', xField.name, yField.name);
      case INotificationEntryTsmiType.TYPE_TSMI_ORDER_WAITING_FOR_CONFIRMATION:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_TSM_ORDER_WAITING_FOR_CONFIRMATION', xField.name, yField.name, zField.name);
      default:
        return 'N/A';
    }
  }

  public getTsmNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    let zField = <INotificationParam> {name: 'Z'};
    switch (this.type) {
      case INotificationEntryTsmType.TYPE_TSM_TF_ASK_FOR_MODIFICATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SUPERVISOR);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_TSM_TF_ASK_FOR_MODIFICATION', xField.name, yField.name);
      case INotificationEntryTsmType.TYPE_TSM_NEW_TF_PUBLISHED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_TSM_NEW_TF_PUBLISHED', xField.name);
      case INotificationEntryTsmType.TYPE_TSM_TF_REJECTED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SUPERVISOR);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_TSM_TF_REJECTED', xField.name, yField.name);
      case INotificationEntryTsmType.TYPE_TSM_ORDER_NEW :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION_SITE); // Z : name of the site
        return this.translate('NOTIFICATION_TYPE_TSM_ORDER_NEW', xField.name, yField.name, zField.name);
      case INotificationEntryTsmType.TYPE_TSM_ORDER_VALIDATED_MANUALLY :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION_SITE); // Z : name of the site
      //  console.log(xField);
      //  console.log(yField);
      //  console.log(zField);
        return this.translate('NOTIFICATION_TYPE_TSM_ORDER_VALIDATED_MANUALLY', xField.name, yField.name, zField.name);
      case INotificationEntryTsmType.TYPE_TSM_ORDER_IN_PROCESS :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ARRIVAL_DATE);
        return this.translate('NOTIFICATION_TYPE_TSM_ORDER_IN_PROCESS', xField.name, yField.name);
      case INotificationEntryTsmType.TYPE_TSM_ORDER_CANCELLED :
        return this.translate('NOTIFICATION_TYPE_TSM_ORDER_CANCELLED');
      case INotificationEntryTsmType.TYPE_TSM_ORDER_IS_TODAY :
        return this.translate('NOTIFICATION_TYPE_TSM_ORDER_IS_TODAY');
      case INotificationEntryTsmType.TYPE_TSM_SUCCESSFUL_ACTIVATION :
        return this.translate('NOTIFICATION_TYPE_TSM_SUCCESSFUL_ACTIVATION');
      case INotificationEntryTsmType.TYPE_TSM_ORDER_WAITING_FOR_VALIDATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
     //   zField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION_SITE);
        return this.translate('NOTIFICATION_TYPE_TSM_ORDER_WAITING_FOR_VALIDATION', xField.name, yField.name);
      case INotificationEntryTsmType.TYPE_TSM_ORDER_WAITING_FOR_CONFIRMATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_TSM_ORDER_WAITING_FOR_CONFIRMATION', xField.name, yField.name, zField.name);
      case INotificationEntryTsmType.TYPE_TSM_ENDS_TODAY :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_TSM_ENDS_TODAY', xField.name, yField.name);
      default:
        return 'N/A';
    }
  }

  public getAgentNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    switch (this.type) {
      case INotificationEntryAgentType.TYPE_AGENT_NEW_DEPOSIT_PUBLISHED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_AGENT_NEW_DEPOSIT_PUBLISHED', xField.name);
      case INotificationEntryAgentType.TYPE_AGENT_DEPOSIT_ASK_FOR_MODIFICATION_BV_SUPERVISOR :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SUPERVISOR);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_AGENT_DEPOSIT_ASK_FOR_MODIFICATION_BV_SUPERVISOR', xField.name, yField.name);
      case INotificationEntryAgentType.TYPE_AGENT_DEPOSIT_REJECTED_BY_DEPO_OWNER :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_AGENT_DEPOSIT_REJECTED_BY_DEPO_OWNER', xField.name, yField.name);
      case INotificationEntryAgentType.TYPE_AGENT_DEPOSIT_REJECTED_BY_SUPERVISOR :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SUPERVISOR);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_AGENT_DEPOSIT_REJECTED_BY_SUPERVISOR', xField.name, yField.name);
      case INotificationEntryAgentType.TYPE_AGENT_SUCCESSFUL_ACTIVATION :
        return this.translate('NOTIFICATION_TYPE_AGENT_SUCCESSFUL_ACTIVATION');
      case INotificationEntryAgentType.TYPE_AGENT_DEPOSIT_ASK_FOR_MODIFICATION_BV_DO :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_DEPOSITOWNER);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_AGENT_DEPOSIT_ASK_FOR_MODIFICATION_BV_DO', xField.name, yField.name);
      case INotificationEntryAgentType.TYPE_AGENT_PASSPORT_CHANGED_UNIT_BY_MANUFACTURER :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_PASSPORT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_AGENT_PASSPORT_CHANGED_UNIT_BY_MANUFACTURER', xField.name, yField.name);
      case INotificationEntryAgentType.TYPE_AGENT_AGENT_PASSPORT_IS_MODIFIED:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_AGENT_AGENT_PASSPORT_IS_MODIFIED', xField.name);
      default:
        return 'N/A';
    }
  }

  public getDoNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    let zField = <INotificationParam> {name: 'Z'};
    let dField = <INotificationParam> {name: 'D'};
    switch (this.type) {
      case INotificationEntryDoType.TYPE_DEPO_OWNER_NEW_DEPOSIT_PUBLISHED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_USER_AGENT);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_NEW_DEPOSIT_PUBLISHED', xField.name, yField.name);
      case INotificationEntryDoType.TYPE_DEPO_OWNER_DEPOSIT_ASK_FOR_MODIFICATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SUPERVISOR);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_DEPOSIT_ASK_FOR_MODIFICATION', xField.name, yField.name);
      case INotificationEntryDoType.TYPE_DEPO_OWNER_DEPOSIT_REJECTED_BY_SUPERVISOR:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SUPERVISOR);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_DEPOSIT_REJECTED_BY_SUPERVISOR', xField.name, yField.name);
      case INotificationEntryDoType.TYPE_DEPO_OWNER_DEPOSIT_WAITING_FOR_VALIDATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_USER_AGENT);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_DEPOSIT_WAITING_FOR_VALIDATION', xField.name, yField.name);
      case INotificationEntryDoType.TYPE_DEPO_OWNER_DEPOSIT_EDITED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_AGENT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_DEPOSIT_EDITED', xField.name, yField.name);
      case INotificationEntryDoType.TYPE_DEPO_OWNER_ORDER_WAITING_FOR_VALIDATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ORDER_PASSPORT_QUANTITY);
        dField = this.extractOption(ENotificationParamObjectType.TYPE_ORDER_PASSPORT_UNIT);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
       // console.log(yField);
       // console.log(dField);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_ORDER_WAITING_FOR_VALIDATION',
          xField.name,
          String(yField.object_id),
          this.unitText(dField.object_id),
          zField.name
        );
      case INotificationEntryDoType.TYPE_DEPO_OWNER_ORDER_VALIDATED_MANUALLY :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM); // Y: quantity (with unit);

        zField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_ORDER_VALIDATED_MANUALLY',
         xField.name,

          yField.name
          ,

          zField.name);
      case INotificationEntryDoType.TYPE_DEPO_OWNER_ORDER_IN_PROCESS :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ORDER_PASSPORT_PICKUP_DATE);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_ORDER_IN_PROCESS', xField.name, yField.name);
      case INotificationEntryDoType.TYPE_DEPO_OWNER_ORDER_IS_TODAY :
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_ORDER_IS_TODAY');
      case INotificationEntryDoType.TYPE_DEPO_OWNER_ORDER_ECOSYSTEM_CANCELLED :
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_ORDER_ECOSYSTEM_CANCELLED');
      case INotificationEntryDoType.TYPE_DEPO_OWNER_NEW_MESSAGE :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_NEW_MESSAGE', xField.name);
      case INotificationEntryDoType.TYPE_DEPO_OWNER_SUCCESSFUL_ACTIVATION :
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SUCCESSFUL_ACTIVATION');
      case INotificationEntryDoType.TYPE_DEPO_OWNER_PICKUP_TODAY :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_PICKUP_TODAY', xField.name, yField.name);
        case INotificationEntryDoType.TYPE_DEPO_OWNER_AGENT_IMPORT :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_AGENT);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_AGENT_IMPORT', xField.name);
      case INotificationEntryDoType.TYPE_ID_DEPO_OWNER_SITE_DELETED:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DELETED_LOCATION_NAME);
        return this.translate('NOTIFICATION_TYPE_ID_DEPO_OWNER_SITE_DELETED', yField.value_string);
      case INotificationEntryDoType.TYPE_ID_DEPO_OWNER_SITE_ADDED:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SITE_ADDED', xField.name);
      case INotificationEntryDoType.TYPE_ID_DEPO_OWNER_SITE_EXPIRED_TWO_MONTHS:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SITE_EXPIRED_TWO_MONTHS', xField.name);
      case INotificationEntryDoType.TYPE_ID_DEPO_OWNER_SITE_EXPIRED_ONE_WEEK:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SITE_EXPIRED_ONE_WEEK', xField.name);
      case INotificationEntryDoType.TYPE_ID_DEPO_OWNER_SITE_EXPIRED_TOMORROW:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SITE_EXPIRED_TOMORROW', xField.name);
      case INotificationEntryDoType.TYPE_ID_DEPO_OWNER_SITE_EXPIRED:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_SITE_EXPIRED', xField.name);
      case INotificationEntryDoType.TYPE_ID_DEPO_OWNER_SITE_DELETE_BY_SUPERADMIN:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DELETED_LOCATION_NAME);
        return this.translate('NOTIFICATION_TYPE_ADMIN_SITE_DELETE_BY_SUPERADMIN', xField.value_string);
      case INotificationEntryDoType.TYPE_ID_DEPO_OWNER_CHANGE_END_OF_WORK:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_EXPIRATION_DATE_CHANGE);
        return this.translate('NOTIFICATION_TYPE_ADMIN_CHANGE_END_OF_WORK', xField.name, this.parseDate(yField.name));
      case INotificationEntryDoType.TYPE_DEPO_OWNER_ORDER_WAITING_FOR_CONFIRMATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_DEPO_OWNER_ORDER_WAITING_FOR_CONFIRMATION', xField.name, yField.name, zField.name);
      default:
        return 'N/A';
    }
  }

  public getSaleNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    let zField = <INotificationParam> {name: 'Z'};
    switch (this.type) {
      case INotificationEntrySalesType.TYPE_SALE_NEW_NEED_PUBLISHED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_SALE_NEW_NEED_PUBLISHED', xField.name);
      case INotificationEntrySalesType.TYPE_SALE_ASK_FOR_MODIFICATION :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SUPERVISOR);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_SALE_ASK_FOR_MODIFICATION', xField.name, yField.name);
      case INotificationEntrySalesType.TYPE_SALE_DELIVERY_DATE_TODAY :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_SALE_DELIVERY_DATE_TODAY', xField.name);
      case INotificationEntrySalesType.TYPE_SALE_DEPOSIT_REJECTED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_SUPERVISOR);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_SALE_DEPOSIT_REJECTED', xField.name, yField.name);
      case INotificationEntrySalesType.TYPE_SALE_ECOSYSTEM_CANCELLED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_SALE_ECOSYSTEM_CANCELLED', xField.name, yField.name);
      case INotificationEntrySalesType.TYPE_SALE_NEW_ECOSYSTEM :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_SALE_NEW_ECOSYSTEM', xField.name, yField.name);
      case INotificationEntrySalesType.TYPE_SALE_NEW_ECOSYSTEM_PUBLIC :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_SALE_NEW_ECOSYSTEM_PUBLIC', xField.name, yField.name);
      case INotificationEntrySalesType.TYPE_SALE_NEW_OFFER :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_SALE_NEW_OFFER', xField.name, yField.name);
      case INotificationEntrySalesType.TYPE_SALE_NEW_OFFER_PUBLIC :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        return this.translate('NOTIFICATION_TYPE_SALE_NEW_OFFER_PUBLIC', xField.name, yField.name);
      case INotificationEntrySalesType.TYPE_SALE_SUCCESSFUL_ACTIVATION :
        return this.translate('NOTIFICATION_TYPE_SALE_SUCCESSFUL_ACTIVATION');
      case INotificationEntrySalesType.TYPE_SALE_OFFER_WAITING_FOR_CONFIRMATION:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_ECOSYSTEM);
        return this.translate('NOTIFICATION_TYPE_SALE_OFFER_WAITING_FOR_CONFIRMATION', xField.name, yField.name, zField.name);
      case INotificationEntrySalesType.TYPE_SALE_CONSULT_DEPOSIT_LINK:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ESM);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_NEED);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LINK_URL);
        return this.translate('NOTIFICATION_TYPE_SALE_CONSULT_DEPOSIT_LINK', xField.name, yField.name, zField.name);
      case INotificationEntrySalesType.TYPE_ID_SALE_NEW_SITE:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_NEW_SITE);
        return this.translate('NOTIFICATION_TYPE_SALE_NEW_SITE', xField.value_string);
      case INotificationEntrySalesType.TYPE_ID_SALE_NEW_SITES:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_NEW_SITE);
        return this.translate('NOTIFICATION_TYPE_SALE_NEW_SITES', xField.value_string);
      default:
        return 'N/A';
    }
  }

  public getSuperadminNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    switch (this.type) {
      case INotificationEntrySuperadminType.TYPE_SUPERADMIN_CHANGE_NOMENCLATURE_SUPERVISOR:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_LOGIN);
        console.log(this.translate('NOTIFICATION_TYPE_SUPERADMIN_CHANGE_NOMENCLATURE_SUPERVISOR', xField.name));
        return this.translate('NOTIFICATION_TYPE_SUPERADMIN_CHANGE_NOMENCLATURE_SUPERVISOR', xField.name);
      case INotificationEntrySuperadminType.TYPE_SUPERADMIN_CHANGE_NOMENCLATURE_MANUFACTURER:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        return this.translate('NOTIFICATION_TYPE_SUPERADMIN_CHANGE_NOMENCLATURE_MANUFACTURER', xField.name);
      case INotificationEntrySuperadminType.TYPE_SUPERADMIN_SITE_CAN_DELETE:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_DEPOSIT_LOCATION_ID);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_USER_ADMIN);
        return this.translate('NOTIFICATION_TYPE_SUPERADMIN_SITE_CAN_DELETE', xField.name, yField.name);
      default:
        return 'N/A';
    }
  }

  public getSpecialAdminNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    let yField = <INotificationParam> {name: 'Y'};
    let zField = <INotificationParam> {name: 'Z'};

    switch (this.type) {
      case INotificationEntrySpecialAdminType.TYPE_SPECIALADMIN_USER_ACTIVATED :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_USER_LOGIN);
        return this.translate('NOTIFICATION_TYPE_SPECIALADMIN_USER_ACTIVATED', xField.name);
      case INotificationEntrySpecialAdminType.TYPE_SPECIALADMIN_NEW_PASSPORT :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_PASSPORT);
        return this.translate('NOTIFICATION_TYPE_SPECIALADMIN_NEW_PASSPORT', xField.name, yField.name);
      case INotificationEntrySpecialAdminType.TYPE_SPECIALADMIN_NEW_TF :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_TRANSFORMATION);
        return this.translate('NOTIFICATION_TYPE_SPECIALADMIN_NEW_TF', xField.name, yField.name);
      case INotificationEntrySpecialAdminType.TYPE_ID_SPECIALADMIN_FOR_RATING :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_PASSPORT);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_RATING_TOKEN);
        if (zField.name.length > 0) {
          return this.translate('NOTIFICATION_TYPE_ID_SPECIALADMIN_FOR_RATING_WITH_TOKEN', xField.name, yField.name, zField.name);
        } else {
          return this.translate('NOTIFICATION_TYPE_ID_SPECIALADMIN_FOR_RATING', xField.name, yField.name);
        }
      case INotificationEntrySpecialAdminType.TYPE_ID_SPECIALADMIN_FOR_RATING_WITH_TOKEN :
        xField = this.extractOption(ENotificationParamObjectType.TYPE_COMPANY_NAME);
        yField = this.extractOption(ENotificationParamObjectType.TYPE_PASSPORT);
        zField = this.extractOption(ENotificationParamObjectType.TYPE_RATING_TOKEN);
        return this.translate('NOTIFICATION_TYPE_ID_SPECIALADMIN_FOR_RATING_WITH_TOKEN', xField.name, yField.name, zField.name);
      default:
        return 'N/A';
    }
  }

  public getManufacturerNotificationText(): string {
    let xField = <INotificationParam> {name: 'X'};
    switch (this.type) {
      case INotificationEntryManufacturerType.TYPE_MANUFACTURER_PASSPORT_FOR_MOD:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_PASSPORT);
        return this.translate('NOTIFICATION_TYPE_MANUFACTURER_PASSPORT_MODIFICATION', xField.name);
      case INotificationEntryManufacturerType.TYPE_MANUFACTURER_PASSPORT_PUBLISHED:
        xField = this.extractOption(ENotificationParamObjectType.TYPE_PASSPORT);
        return this.translate('NOTIFICATION_TYPE_MANUFACTURER_PASSPORT_PUBLISHED', xField.name);
      default:
        return 'N/A';
    }
  }

  public extractOption(type: ENotificationParamObjectType) {
    const target = this.params.find((param) => param.object_type === type);
    // console.log(target);
    return target || <INotificationParam> {
        object_id: 0,
        object_type: 0,
        object_position: 0,
        name: ''
      };

  }

  /**
   * Function which retrieves parameters of type "type" "times" times from this.params
   * If there are not enough parameters of type "type" in this.params, fill the response with default params
   * @param {ENotificationParamObjectType} type - Type of the options we want to retrieve
   * @param {Number} times - Number of options of the same type that we want to retrieve
   * @returns {INotificationParam[]}
   */
  public extractOptionMultipleTimes(type: ENotificationParamObjectType, times: Number = 1) {
    const targets = this.params.filter((param) => param.object_type === type);

    const options = [];
    for (let i = 0; i < times; i++) {
      const option = targets[i] || <INotificationParam> {
        object_id: 0,
        object_type: 0,
        object_position: 0,
        name: ''
      };
      options.push(option);
    }

    return options;
  }

  public unitText(unit: number) {
    const result = PASSPORT_UNITS.find((item) => item.id === unit);
    return result ? this.translateService.instant(result.title) : unit;
  }

  public translate(key: string, x?: string, y?: string, z?: string, d?: string): string {
    let message = '';
    this.translateService.get(key, {X: x, Y: y, Z: z, D: d}).subscribe((res: string) => {
      message += res;
    });
    return message;
  }

  private parseDate(date: string) {
    return moment(date, 'YYYY-MM-DD').format(this.translateService.currentLang === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY');
  }
}
