import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
var PassportRatingWithLocalPassportComponent = /** @class */ (function () {
    function PassportRatingWithLocalPassportComponent() {
        this.ratingNameAlias = {
            passportWithoutRating: 'Passports without rating',
            selectRatingFilter: 'Select all rating'
        };
        this.ratingIconAlias = {
            passportWithoutRating: '',
            selectRatingFilter: ''
        };
        this.ratingClassAlias = {
            passportWithoutRating: 'passportlist__info-rating-filter-row-select',
            C2cCertified: 'passportlist__info-rating-filter-row-select'
        };
        this.classList = ['depositlist__info-rating'];
        this.ratingApplyRule = this.defaultRatingApplyRule.bind(this);
        this.defaultRatings = {};
        this.ratingFilterChange = new EventEmitter();
        this.onAnySelected = new EventEmitter();
    }
    Object.defineProperty(PassportRatingWithLocalPassportComponent.prototype, "setRating", {
        set: function (rating) {
            this.ratingFilter = rating;
            this.ratingFilterView = tslib_1.__assign({}, rating);
        },
        enumerable: true,
        configurable: true
    });
    PassportRatingWithLocalPassportComponent.prototype.updateRatingFilter = function ($event) {
        var isOnAllRatingClicked = $event.selectRatingFilter !== this.ratingFilter.selectRatingFilter;
        this.ratingFilterView = tslib_1.__assign({}, this.ratingFilter);
        this.ratingFilter = $event;
        this.updateSelectAllRatingFilter(isOnAllRatingClicked);
        this.ratingFilterChange.emit(this.ratingFilter);
    };
    PassportRatingWithLocalPassportComponent.prototype.updateSelectAllRatingFilter = function (isOnAllRatingClicked) {
        if (isOnAllRatingClicked) {
            this.runOnSelectAll(this.ratingFilter.selectRatingFilter);
        }
        var isAllRatedSelected = this.isAllRatingSelected();
        this.ratingFilter.selectRatingFilter = isAllRatedSelected;
        this.ratingFilter.selectRatingFilter = isAllRatedSelected;
        this.ratingNameAlias = tslib_1.__assign({}, this.ratingNameAlias, { selectRatingFilter: (isAllRatedSelected ? 'Unselect all rating' : 'Select all rating') });
        this.onAnySelected.emit((isAllRatedSelected ? true : this.isAnyRatingSelected()) || this.ratingFilter.C2cCertified);
    };
    PassportRatingWithLocalPassportComponent.prototype.runOnSelectAll = function (isChecked) {
        var _this = this;
        var filters = this.ratingFilter;
        var _a = this.getRatingSelectConnectedField(), simpleFilterKeyList = _a.simpleFilterKeyList, customFilterList = _a.customFilterList;
        simpleFilterKeyList.forEach(function (item) {
            _this.ratingFilter[item] = isChecked;
            filters[item] = isChecked;
        });
        customFilterList.forEach(function (item) {
            item.color = isChecked;
            item.black = isChecked;
        });
    };
    PassportRatingWithLocalPassportComponent.prototype.isAllRatingSelected = function () {
        var filters = this.ratingFilter;
        var _a = this.getRatingSelectConnectedField(), simpleFilterKeyList = _a.simpleFilterKeyList, customFilterList = _a.customFilterList;
        return simpleFilterKeyList.every(function (item) { return !!filters[item]; }) && customFilterList.every(function (item) { return item.color && item.black; });
    };
    PassportRatingWithLocalPassportComponent.prototype.isAnyRatingSelected = function () {
        var filters = this.ratingFilter;
        var _a = this.getRatingSelectConnectedField(), simpleFilterKeyList = _a.simpleFilterKeyList, customFilterList = _a.customFilterList;
        return simpleFilterKeyList.some(function (item) { return !!filters[item]; }) || customFilterList.some(function (item) { return item.color || item.black; });
    };
    PassportRatingWithLocalPassportComponent.prototype.getRatingSelectConnectedField = function () {
        var filters = this.ratingFilter;
        var simpleFilterKeyList = [];
        var customFilterList = [filters.composition, filters.cycle, filters.energy, filters.social, filters.water];
        return { simpleFilterKeyList: simpleFilterKeyList, customFilterList: customFilterList };
    };
    PassportRatingWithLocalPassportComponent.prototype.defaultRatingApplyRule = function () {
        var _this = this;
        var assertField = function (actualKey, actualObj, expectedObj) {
            if (actualObj === void 0) { actualObj = _this.ratingFilter; }
            if (expectedObj === void 0) { expectedObj = _this.defaultRatings; }
            if (!actualObj || !expectedObj)
                return false;
            return !!actualObj[actualKey] !== !!expectedObj[actualKey];
        };
        var isObject = function (data) { return typeof data === 'object' && data !== null; };
        if (!isObject(this.ratingFilter))
            return false;
        return Object.keys(this.ratingFilter).some(function (ratingKey) {
            if (isObject(_this.ratingFilter[ratingKey])) {
                var expected_1 = isObject(_this.defaultRatings[ratingKey]) ? _this.defaultRatings[ratingKey] : {};
                var actual_1 = _this.ratingFilter[ratingKey] ? _this.ratingFilter[ratingKey] : {};
                return Object.keys(actual_1).some(function (subRatingKey) { return assertField(subRatingKey, actual_1, expected_1); });
            }
            return assertField(ratingKey);
        });
    };
    return PassportRatingWithLocalPassportComponent;
}());
export { PassportRatingWithLocalPassportComponent };
