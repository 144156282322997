import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IReportResponse} from '../../../../../interfaces/reportResponse.interface';
import {ICarbonFootprintAggregate, ICarbonFootprintCategory} from '../../../../../interfaces/carbon-footprint.interface';
import { MAIN_TAB_STEPS } from '../../../admin-reporting.const';
import { ReportBase } from '../../report-base/report-base.class';
import { TranslateService } from '@ngx-translate/core';
import { ECarbonFootprintReportType } from '../../../../../../../dashboards/admin/values/carbon-footprint-status.enum';
import {convertUnit} from '../../../../../../../helper/Unithelper';

@Component({
  moduleId: module.id,
  selector: 'carbon-footprint-report',
  templateUrl: 'carbon-footprint-report.component.html',
  styleUrls: ['carbon-footprint-report.component.scss']
})
export class CarbonFootprintReportComponent extends ReportBase implements OnInit {
  public aggregateData: ICarbonFootprintAggregate;
  public filteredAndSortedProductCategory: ICarbonFootprintCategory[];
  public filteredProductCategory: object = {};
  @Input() reports: IReportResponse;
  @Input() changeTab: Function;
  @Input() reportType: ECarbonFootprintReportType;
  @ViewChild('descriptionBlock') public descriptionBlock: ElementRef;
  isFootprintDescriptionVisible: boolean = false;
  constructor(private _translate: TranslateService){
    super();
  }
  ngOnInit() {
    this.aggregateData = this.reports.report_result.carbon_foot_print.aggregate;
    this.setCarbonFootprintProductCategories();
  }
  toggleDescriptionVisibility() {
    this.isFootprintDescriptionVisible = !this.isFootprintDescriptionVisible;
  }

  /**
   * Check whether the category list is empty or not
   * @returns {Boolean} True if there is no category, false otherwise
   */
  isEmptyProductCategory(): boolean {
    return Object.keys(this.filteredProductCategory).length === 0;
  }

  /**
   * Set the filtered and sorted product category table
   * @todo Maybe it is not really useful to have two categories for the same thing, remove one of them for the next refacto
   */
  setCarbonFootprintProductCategories(): void {
    this.filteredAndSortedProductCategory = this.filteredProductCategory = this.getAndSortFootprintCategories();
  }

  /**
   * Get the sorted categories
   * Among the categories, only keep those that have at least one deposit taken into account in the report type
   * Then, sort the remaining categories by the highest footprint
   * @returns {ICarbonFootprintCategory[]} The category list sorted by the highest carbon value
   */
  getAndSortFootprintCategories(): ICarbonFootprintCategory[] {
    let categories = Object.values(this.aggregateData.category);
    let discriminant = this.getDiscriminantProperty();
    return categories
      .filter(category => category[discriminant] > 0)
      .sort((catA, catB) => this.getCarbonFootPrintFilterUnit(catB) - this.getCarbonFootPrintFilterUnit(catA));
  }

  /**
   * Get the property name which discriminates which categories should be kept depending on the report type
   * @returns {String}
   */
  getDiscriminantProperty() {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'deposits_considered';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'deposits_considered_product'
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'deposits_considered_embodied';
      case ECarbonFootprintReportType.IC_COMPONENT:
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return 'deposits_considered_ic_comp_const';
    }
  }

  goToArchive() {
    this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
  }
  getCarbonFootPrintFilterUnit(deposit) {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return deposit.footprint;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return deposit.embodied_carbon_total;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return deposit.ic_component_total;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return deposit.ic_construction_total;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return deposit.product_and_construction_total;
    }
  }
  getCarbonFootprintCircleIndicatorData(): {value: number, unit: string} {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return convertUnit({value : this.aggregateData.total_carbon_footprint, unit :'tons'});
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return convertUnit({value : this.aggregateData.embodied_carbon_total, unit :'tons'});
      case ECarbonFootprintReportType.IC_COMPONENT:
        return convertUnit({value : this.aggregateData.ic_component_total, unit :'kgs'});
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return convertUnit({value : this.aggregateData.ic_construction_total, unit :'kgs'});
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return convertUnit({value : this.aggregateData.product_and_construction_total, unit :'tons'});
    }
  }
  getCarbonFootprintDescriptionText(): string {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'PRODUCT_STAGE_DESCRIPTION_TEXT';
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'EMBODIED_CARBON_DESCRIPTION_TEXT';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'IC_COMPONENT_DESCRIPTION_TEXT';
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return 'IC_CONSTRUCTION_DESCRIPTION_TEXT';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'PRODUCT_AND_CONSTRUCTION_DESCRIPTION_TEXT'
    }
  }
  getTotalConsideredDepositsByReportType(): number {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return this.aggregateData.total_deposit_considered;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return this.aggregateData.total_deposits_considered_embodied;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return this.aggregateData.total_deposits_considered_ic_comp_const;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return this.aggregateData.total_deposits_considered_ic_comp_const;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
          return this.aggregateData.total_deposits_considered_product;
    }
  }

  shouldDisplayPartialData() {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return this.aggregateData.partial_deposit_considered > 0;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return this.aggregateData.partial_deposits_considered_embodied > 0;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return this.aggregateData.partial_deposits_considered_ic_comp_const > 0;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return this.aggregateData.partial_deposits_considered_ic_comp_const > 0;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
          return this.aggregateData.partial_deposits_considered_product > 0;
    }
  }
  getTotalConsideredWeightByReportType(): number {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return this.aggregateData.total_weight_considered;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return this.aggregateData.total_weight_considered_embodied;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return this.aggregateData.total_weight_considered_ic_comp_const;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return this.aggregateData.total_weight_considered_ic_comp_const;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
          return this.aggregateData.total_weight_considered_product;
    }
  }
  getTypeTitleText(): string {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'Production (A1-A3)';
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'Embodied (A1-A5 + B1-B5 + C)';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'Ic';
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return 'Ic';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'Production & Construction (A1-A5)';
    }
  }
  getIcSubItem(): string {
    switch (this.reportType) {
        case ECarbonFootprintReportType.IC_COMPONENT:
            return 'composant';
        case ECarbonFootprintReportType.IC_CONSTRUCTION:
            return 'construction';
    }
  }
  getTypeOfAllDepositsText(): string {
    if(this.isReportTypeIcComponent()){
      return 'Ic composant';
    } else if(this.isReportTypeIcConstruction()){
      return 'Ic construction'
    } else {
      return this._translate.instant('Carbon footprint');
    }
  }
  getTypeDescriptionTitleText(): string {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return 'What does the Product stage carbon footprint measure?';
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return 'What does the Embodied carbon footprint measure?';
      case ECarbonFootprintReportType.IC_COMPONENT:
        return 'What does the Ic component measure?';
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return 'What does the Ic construction measure?';
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return 'What does the Product and construction process stage carbon footprint measure?';
    }
  }
  getDescriptionToggleImg(): string{
    if(this.isFootprintDescriptionVisible){
      return "../assets/svg/carbon-footprint-report-arrow-down--icon.svg";
    } else {
      return "../assets/svg/carbon-footprint-report-arrow-up--icon.svg";
    }
  }
  isReportTypeIcComponent(){
    return this.reportType === ECarbonFootprintReportType.IC_COMPONENT;
  }
  isReportTypeIcConstruction(){
    return this.reportType === ECarbonFootprintReportType.IC_CONSTRUCTION;
  }
  isReportTypeOfIcConstructionOrComponent(){
    return this.isReportTypeIcComponent() || this.isReportTypeIcConstruction();
  }
  isCurrentLanguageEnglish(){
    return this._translate.currentLang ===  'en';
  }
  isCurrentLanguageSpanish(){
    return this._translate.currentLang === 'es';
  }

  getTranslation() {
    let x = 0;
    let y = 0;
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        x = this.aggregateData.partial_deposit_considered;
        y = this.aggregateData.total_deposit_considered;

        break;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        x = this.aggregateData.partial_deposits_considered_embodied;
        y = this.aggregateData.total_deposits_considered_embodied;

        break;
      case ECarbonFootprintReportType.IC_COMPONENT:
        x = this.aggregateData.partial_deposits_considered_ic_comp_const;
        y = this.aggregateData.total_deposits_considered_ic_comp_const;

        break;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        x = this.aggregateData.partial_deposits_considered_ic_comp_const;
        y = this.aggregateData.total_deposits_considered_ic_comp_const;

        break;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        x = this.aggregateData.partial_deposits_considered_product;
        y = this.aggregateData.total_deposits_considered_product;

        break;
    }
    return `${this._translate.instant('CS_CARBON_MISSING_DATA', {
      X: x,
      Y: y
    })}. ${this._translate.instant('See pdf for more details')}`
  }
}
