<md-dialog-content>
    <div class="deposit-delete-modal">
      <div class="deposit-delete-modal__heading">
        <div class="deposit-delete-modal__image">
          <img src="assets/png/warning_green.png" class="deposit-delete-modal__triangle">
          <span class="deposit-delete-modal__exclamation">!</span>
        </div>
      </div>
  
      <div>
        
        <div class="deposit-delete-modal__text" >
          <b>{{ 'DELETE_ALL_DEPOSITS_MODAL_TITLE' | translate}}</b>
        </div>
        <div class="deposit-delete-modal__text" >
          {{ 'DELETE_ALL_DEPOSITS_MODAL_BODY' | translate}}
        </div>
      </div>
  
      <div class="deposit-delete-modal__footer">
        <button class="deposit-delete-modal__button" md-raised-button
          (click)="confirm()">{{ 'Yes' | translate }}</button>
        <button class="deposit-delete-modal__button" md-raised-button
          (click)="closeDialog()">{{ 'Cancel' | translate}}</button>
      </div>
    </div>
  </md-dialog-content>
  