type UnitConversionDetails = {
    threshold: number;
    conversionFactor: number;
    convertedUnit: string;
};

type UnitConversion = {
    [unit: string]: {
        upper?: UnitConversionDetails;
        lower?: UnitConversionDetails;
    };
};

type Unit = {
    value: number;
    unit: string;
};

const conversions: UnitConversion = {
    "cm2": {
        upper: { threshold: 1000, conversionFactor: 0.0001, convertedUnit: "M2" }
    },
    "M2": {
        lower: { threshold: 0.1, conversionFactor: 10000, convertedUnit: "cm2" }
    },
    "ml": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "L" }
    },
    "L": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "M3" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "ml" }
    },
    "M3": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "dam3" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "L" }
    },
    "dam3": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "hm3" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "M3" }
    },
    "hm3": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "km3" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "dam3" }
    },
    "km3": {
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "hm3" }
    },
    "mg": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "g" }
    },
    "g": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "kgs" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "mg" }
    },
    "kgs": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "tons" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "g" }
    },
    "tons": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "kg tons" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "kgs" }
    },
    "kg tons": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "mega tons" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "tons" }
    },
    "mega tons": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "giga tons" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "kg tons" }
    },
    "giga tons": {
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "tera tons" },
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "mega tons" }
    },
    "tera tons": {
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "giga tons" },
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "peta tons" }
    },
    "peta tons": {
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "tera tons" },
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "exa tons" }
    },
    "exa tons": {
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "peta tons" },
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "zetta tons" }
    },
    "zetta tons": {
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "exa tons" },
        upper: { threshold: 1000, conversionFactor: 0.001, convertedUnit: "yotta tons" }
    },
    "yotta tons": {
        lower: { threshold: 1, conversionFactor: 1000, convertedUnit: "zetta tons" }
    },
    "cm": {
        upper: { threshold: 100, conversionFactor: 0.01, convertedUnit: "M" }
    },
    "M": {
        lower: { threshold: 0.1, conversionFactor: 100, convertedUnit: "cm" }
    },
};

export function convertUnit(input: Unit): Unit {
    const { value, unit } = input;

    const conversion = conversions[unit];
    let newValue = value;

    if (!conversion) {
        return { value: newValue, unit: unit };
    }

    const details = getConversionDetail(conversion, newValue);
    
    if (details) {
        newValue *= details.conversionFactor;
        return convertUnit({ value: newValue, unit: details.convertedUnit });
    }

    return { value: newValue, unit: unit };
}

export function convertUnitTarget(input: Unit, targetUnit: string, visitedUnits: string[] = []): Unit {
    const { value, unit } = input;

    if (unit === targetUnit) {
        return input;
    }

    visitedUnits.push(unit);

    const conversion = conversions[unit];
    let newValue = value;

    if (!conversion) {
        return { value: newValue, unit: unit };
    }

    const directConversionDetail = Object.values(conversion).find(detail => 
        detail && detail.convertedUnit === targetUnit
    );

    if (directConversionDetail) {
        newValue *= directConversionDetail.conversionFactor;
        return { value: newValue, unit: targetUnit };
    }

    let newConversion;

    if (conversion.upper && !visitedUnits.includes(conversion.upper.convertedUnit)) {
        newConversion = convertUnitTarget({ value: newValue * conversion.upper.conversionFactor, unit: conversion.upper.convertedUnit }, targetUnit, visitedUnits);
        if (newConversion.unit === targetUnit) {
            return newConversion;
        }
    }

    if (conversion.lower && !visitedUnits.includes(conversion.lower.convertedUnit)) {
        newConversion = convertUnitTarget({ value: newValue * conversion.lower.conversionFactor, unit: conversion.lower.convertedUnit }, targetUnit, visitedUnits);
        if (newConversion.unit === targetUnit) {
            return newConversion;
        }
    }

    return input; 
}

function getConversionDetail(conversion: any, value: number): UnitConversionDetails | null {
    value = Math.abs(value);
    const shouldConvertToUpper = conversion.upper && value >= conversion.upper.threshold;
    const shouldConvertToLower = conversion.lower && value <= conversion.lower.threshold;

    if (shouldConvertToUpper) return conversion.upper;
    if (shouldConvertToLower) return conversion.lower;

    return null;
}

export function runTests() {
    let passed = 0;
    let failed = 0;

    function assertEqual(actual: Unit, expected: Unit, message: string) {
        if (actual.value === expected.value && actual.unit === expected.unit) {
            console.log(`✅ ${message}`);
            passed++;
        } else {
            console.log(`❌ ${message} - Expected: ${expected.value} ${expected.unit}, but got: ${actual.value} ${actual.unit}`);
            failed++;
        }
    }

    // Test simple conversion without target unit
    assertEqual(convertUnit({ value: 5000, unit: 'g' }), { value: 5, unit: 'kg' }, "5000g should convert to 5 kg");
    assertEqual(convertUnit({ value: 0.005, unit: 'kg' }), { value: 5, unit: 'g' }, "0.005kg should convert to 5 g");
    assertEqual(convertUnit({ value: 0.0000005, unit: 'kg' }), { value: 0.5, unit: 'mg' }, "0.0000005kg should convert to 0.5 mg");

    // Test conversion with target unit using convertUnitTarget
    assertEqual(convertUnitTarget({ value: 1500, unit: 'g' }, 'tons'), { value: 0.0015, unit: 'tons' }, "1500g should convert to 0.0015 tons");
    assertEqual(convertUnitTarget({ value: 1500, unit: 'kg' }, 'mg'), { value: 1500000000, unit: 'mg' }, "1500kg should convert to 1500000 mg"); 
    assertEqual(convertUnitTarget({ value: 50, unit: 'cm' }, 'M'), { value: 0.5, unit: 'M' }, "50cm should convert to 0.5 M");

    // Test recursive conversion (g to kg to tons) using convertUnit
    assertEqual(convertUnit({ value: 1500000, unit: 'g' }), { value: 1.5, unit: 'tons' }, "1500000g should convert to 1.5 tons");
    
    // Test other units
    assertEqual(convertUnit({ value: 9000, unit: 'cm2' }), { value: 0.9, unit: 'M2' }, "9000cm2 should convert to 0.9 M2");
    
    // Attempt to convert without valid mapping 
    assertEqual(convertUnit({ value: 9000, unit: 'XY' }), { value: 9000, unit: 'XY' }, "9000XY should not convert to anything");

    // Summary
    console.log(`\nTotal tests: ${passed + failed}, Passed: ${passed}, Failed: ${failed}`);
}
