import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { Ecosystem } from '../../../dashboards/ecosystem-manager/models/ecosystem.class';
import { Searchable } from '../../../shared/models/searchable.class';
import { DepositOrder } from '../../../dashboards/ecosystem-manager/models/deposit-order.class';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
var EcosystemService = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemService, _super);
    function EcosystemService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'ecosystems';
        _this.namespaceSingular = 'ecosystem';
        _this.ModelClass = Ecosystem;
        return _this;
    }
    EcosystemService.prototype.getClientList = function (query) {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/client_list'), { search: query })
            .map(function (data) { return data.json().map(function (d) { return new Searchable(d); }); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.getManagerList = function (query) {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/managers'), { search: query })
            .map(function (data) { return data.json().map(function (d) { return new Searchable(d); }); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.getSummary = function (ecosystemId) {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + ("/" + ecosystemId + "/steps")), { search: { expand: 'summary_details,deposit,transformation' } })
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.saveSummary = function (ecosystemId, summary) {
        return this
            .sendPost(this.getEndpoint(this.namespaceSingular + ("/" + ecosystemId + "/summary")), { steps: summary })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.previewTotals = function (ecosystemId, summary) {
        return this
            .sendPost(this.getEndpoint(this.namespaceSingular + ("/" + ecosystemId + "/preview_totals")), { steps: summary })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.getProcessCount = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/process_count'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.getStepOrders = function (step, ecosystemID) {
        return this.sendGet(this.getEndpoint('ecosystem-step/' + String(step.id)), { search: { expand: 'orders', ecosystem_id: ecosystemID } })
            .map(function (data) {
            var ordersContent = data.json()['orders'];
            if (ordersContent && step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
                return new DepositOrder(ordersContent);
            }
            else if (ordersContent && step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
                if (ordersContent instanceof Array && ordersContent.length > 0) {
                    return ordersContent;
                }
            }
            return [];
        })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.publishWithDeposit = function (data) {
        return this.sendPost(this.getEndpoint(this.namespaceSingular + "/with_deposit"), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.askForValidationAll = function (id) {
        return this.sendPost(this.getEndpoint(this.namespaceSingular + ("/ask_all/" + id)))
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.getEcosystemFolderRoot = function (query) {
        if (query === void 0) { query = {}; }
        return this.sendGet(this.getEndpoint('ecosystem-folder/root'), query)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.getEcosystemFolderById = function (id, query) {
        return this.sendGet(this.getEndpoint("ecosystem-folder/" + id), query)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.getAllFolders = function () {
        return this.sendGet(this.getEndpoint("ecosystem-folder/all"))
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.createFolder = function (data) {
        return this.sendPost(this.getEndpoint("ecosystem-folders"), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.updateFolder = function (id, data) {
        return this.sendPut(this.getEndpoint("ecosystem-folder/" + id), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.moveFolder = function (data) {
        return this.sendPost(this.getEndpoint("ecosystem-folder/move"), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EcosystemService.prototype.deleteFolder = function (data) {
        console.log(data);
        return this.sendPost(this.getEndpoint("ecosystem-folder/delete_all"), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    return EcosystemService;
}(ExtendedCrudService));
export { EcosystemService };
