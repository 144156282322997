<div class="deposit-owner-deposits-list" test-id="do-deposit-list">
  <div class="deposit-owner-deposits-list__well">
    <header class="deposit-owner-deposits-list__header">
      <h3 class="deposit-owner-deposits-list__inline-title">{{ 'Deposits list' | translate }}</h3>
      <div class="checkbox">
        <md-checkbox *ngFor="let status of filterStatuses"
                     [name] ="status.text"
                     class="esm-catalog__group--checkbox checkbox-group"
                     [(ngModel)]="status.checked"
                     (change)="loadDeposits()"
        >
          {{status.depositStatus.view | lowercase | capitalize | translate}}
        </md-checkbox>
        <md-checkbox
          class="deposit-owner-deposits-list__group--checkbox checkbox-group"
          [(ngModel)]="outOfStock"
          name="OUT_OF_STOCK"
          (change)="loadDeposits()"
        >
          {{ 'Out of stock' | translate }}
        </md-checkbox>
      </div>
    </header>
    <section class="deposit-owner-deposits-list__choice">
      <ul class="deposit-owner-deposits-list__choice-list">
        <li class="deposit-owner-deposits-list__choice-item search">
          <i class="icon icon--search"></i>
          <md-input-container class="input-group">
            <input test-id="search"
                   mdInput
                   autocomplete="off"
                   [formControl]="search"
                   placeholder="{{ 'Search' | translate }}">
          </md-input-container>
        </li>
        <li class="deposit-owner-deposits-list__choice-item">
          <i class="icon icon--calendar"></i>
          <md-input-container class="date input-group">
            <input test-id="available-date-from-filter"
                   mdInput
                   autocomplete="off"
                   ng2-datetime-picker
                   date-only="true"
                   date-format="DD-MM-YYYY"
                   placeholder="{{ 'Available FROM' | translate }}"
                   [(ngModel)]="availableFrom"
                   (ngModelChange)="loadDeposits()"
            >
          </md-input-container>
        </li>
        <li class="deposit-owner-deposits-list__choice-dash">-</li>
        <li class="deposit-owner-deposits-list__choice-item">
          <i class="icon icon--calendar"></i>
          <md-input-container class="date input-group">
            <input test-id="available-date-to-filter"
                   mdInput
                   autocomplete="off"
                   ng2-datetime-picker
                   date-only="true"
                   date-format="DD-MM-YYYY"
                   placeholder="{{ 'Available TO' | translate }}"
                   [(ngModel)]="availableTo"
                   (ngModelChange)="loadDeposits()"
            >
          </md-input-container>
        </li>
        <li class="esm-catalog__choice-item
        suggest
        deposit-owner-deposits-list__choice-item
        deposit-owner-deposits-list__choice-item--multi-line">
          <deposit-location-filter
            class="deposit-owner-deposits-list__choice-item-location"
            test-id="sites"
            field="name"
            placeholder="{{'Site(s)' | translate}}"
            [availableItems]="availableDepositLocations"
            [(ngModel)]="chosenDepositLocations"
            (ngModelChange)="loadDeposits()"
          >
          </deposit-location-filter>
        </li>
        <li class="esm-catalog__choice-item
        suggest
        deposit-owner-deposits-list__choice-item
        deposit-owner-deposits-list__choice-item--multi-line">
          <deposit-location-filter
            class="deposit-owner-deposits-list__choice-item-location"
            test-id="tags"
            field="name"
            placeholder="{{'Tag(s)' | translate}}"
            [disabled]="chosenDepositLocations.length !== 1"
            [availableItems]="chosenDepositLocations.length === 1 ? chosenDepositLocations[0]?.tags : []"
            [(ngModel)]="chosenTags"
            (ngModelChange)="loadDeposits()"
          >
          </deposit-location-filter>
        </li>
        <li class="deposit-owner-deposits-list__choice-item select">
          <i class="icon icon--people-grey"></i>
          <md-select test-id="agent-selector"
                     class="select-group"
                     placeholder="{{ 'Agent contact' | translate }}"
                     [(ngModel)]="agentContactId"
                     (change)="loadDeposits()"
          >
            <md-option [value]="">{{ 'Any' | translate }}</md-option>
            <md-option
              *ngFor="let contact of agentContacts"
              [value]="contact.id"
            >
              {{ contact.name }}
            </md-option>
          </md-select>
        </li>
        <li class="deposit-owner-deposits-list__choice-item select">
          <i class="icon icon--gray-sort"></i>
          <md-select test-id="sort-selector"
                     class="select-group"
                     placeholder="{{ 'Sort by' | translate }}"
                     [(ngModel)]="sortOrder"
                     (change)="loadDeposits()"
          >
            <md-option
              *ngFor="let sort of sortOrders"
              [value]="sort.value"
            >
              {{ sort.view | translate}}
            </md-option>
          </md-select>
        </li>
      </ul>
    </section>
  </div>
  <custom-list
    [records]="records"
    [expandable]="true"
    (onEndOfList)="onEndOfListReached()"
  ></custom-list>
</div>
