import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CurrentUserService } from '../services/current-user.service';
import { NavigationService } from '../services/navigation.service';
import {EulaService} from '../../login/services/eula.service';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private _navigationService: NavigationService,
              private _userService: CurrentUserService,
              private eulaCrudService: EulaService,
              private eulaService: EulaService,
              private router: Router) {
    console.log('Construct LoginGuard');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log('Launch LoginGuard -> Try to activate', state.url);
    return this
      ._userService
      .loggedInChecked$
      .map((isChecked) => this._userService.isLoggedIn)
      .do((isLoggedIn) => {
        console.log('LoginGuard  -> onResult', isLoggedIn, this._userService);
        if (!isLoggedIn) {
          this.redirectToLogin(state);
        } else {
          this._navigationService.showNavBar = true;
          this.handleRedirectToDashboard(state);
        }
      });
  }

  redirectToLogin(state: RouterStateSnapshot) {
    this._navigationService.showNavBar = false;
    this._userService.redirectUrl = state.url;
    this.router.navigate(['/login']);
  }
  handleRedirectToDashboard(state) {
    this._userService.isAuth().subscribe(() => {
      this.handleEulaStatus(state);
    });
  }
  handleEulaStatus(state) {
    if (this._userService.infoData.has_accepted_eula) {
      if (state.url === '/') {
        this.onRedirectedFromMainUserUrl();
      } else {
        return;
      }
    } else {
      this._navigationService.showNavBar = false;
      this.router.navigate(['login/user-activation']);
    }
  }
  onRedirectedFromMainUserUrl() {
    const context = this._userService.userDefaultContext;
    if (!context) {
      throw new Error(`Cannot find role '${this._userService.roleName}'`);
    } else if (context.homeUrl && context.homeUrl !== '/') {
      this.router.navigate([context.homeUrl]);
    } else {
      throw new Error(`Invalid homepage '${context.homeUrl}'`);
    }
  }
}
