import * as tslib_1 from "tslib";
import { Searchable } from '../../../shared/models/searchable.class';
import { CrudService } from '../../../shared/services/crud.service';
var VirginContactListService = /** @class */ (function (_super) {
    tslib_1.__extends(VirginContactListService, _super);
    function VirginContactListService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'virgin-product/virgin_contact';
        _this.ModelClass = Searchable;
        return _this;
    }
    return VirginContactListService;
}(CrudService));
export { VirginContactListService };
