import {PASSPORT_RATING} from '../values/passport-rating/passport-rating-values.const';

export class PassportRating {

  rating_composition: number;
  rating_recyclability: number;
  rating_social_fairness: number;
  rating_energy: number;
  rating_water_stewardship: number;

  // TODO move to passport filter
  static getRatingStatus(rating: {color: boolean, black: boolean}) {
    const {color, black} = rating;
    if (!!color && !!black) {
      return PASSPORT_RATING.DECLARATION_OR_THIRD_PARTY_VALID;
    } else if (!!color) {
      return PASSPORT_RATING.DECLARATION_VALID;
    } else if (!!black) {
      return PASSPORT_RATING.THIRD_PARTY_VALID;
    }
    return null;
  }

  constructor(rating) {
    this.setDefaultValues(rating);

  }

  setDefaultValues(rating): void {
    const getRatingValue = (field) => !!rating && rating[field] || 0;

    this.getFields().forEach(field => {
      return this[field] = getRatingValue(field);
    });
  }

  getFields(): string[] {
    return [
      'rating_composition',
      'rating_recyclability',
      'rating_social_fairness',
      'rating_energy',
      'rating_water_stewardship'
    ];
  }

}
