<confirm-with-header class="
  order-transaction-confirmation
  order-transaction-confirmation--textarea-no-horizontal-resize
  order-transaction-confirmation--input-disabled-colored
  order-transaction-confirmation--input-placeholder-disabled-colored
  order-transaction-confirmation--input-placeholder-text
  order-transaction-confirmation--input-placeholder-float-no-overflow
  deposit-order-transaction-confirmation"
  *ngIf="order"
  [title]="disabled ? ('Deposit removal confirmation' | translate) :('Confirm deposit removal' | translate)"
  [ngClass]="{'order-transaction-confirmation--disabled': disabled}"
  [confirmButtonText]="'Confirm data' | translate"
  (onConfirm)="confirmOrderTransaction()"
  (onCancel)="onClose.emit()"
>
  <article class="deposit-order-transaction-confirmation__main"
           [ngClass]="{'deposit-order-transaction-confirmation__main--disabled': disabled}">
    <header class="deposit-order-transaction-confirmation__foreword" *ngIf="!disabled; else info">
      <h3 class="order-transaction-confirmation__foreword-title deposit-order-transaction-confirmation__foreword-title">
        {{'Your deposit has been removed?' | translate}}</h3>
      <span class="order-transaction-confirmation__foreword-main deposit-order-transaction-confirmation__foreword-main">
        {{'Please confirm or update the following values' | translate}}
      </span>
      <span *ngIf="!showESMText" class="order-transaction-confirmation__foreword-main deposit-order-transaction-confirmation__foreword-main">
        <span>{{'- '}}</span>{{'they will be sent to the ecosystem manager' | translate}}
      </span>
    </header>
    <ng-template #info>
      <header class="deposit-order-transaction-confirmation__info">
        <md-input-container class="deposit-order-transaction-confirmation__info-item">
          <input
            mdInput
            placeholder="{{ 'Deposit name' | translate}}"
            [disabled]="true"
            [(ngModel)]="order.deposit.name">
        </md-input-container>
        <md-input-container class="deposit-order-transaction-confirmation__info-item">
          <input
            mdInput
            placeholder="{{ 'Passport name' | translate}}"
            [disabled]="true"
            [(ngModel)]="order.deposit_passport.name">
        </md-input-container>
      </header>
    </ng-template>
    <main class="deposit-order-transaction-confirmation__confirm">
        <ul class="deposit-order-transaction-confirmation__confirm-container" [formGroup]="form">
            <order-transaction-confirm-result-field
              [formControlName]="'quantity'"
              [placeholderExpected]="'Quantity (expected)' | translate"
              [placeholderConfirmed]="'Quantity (confirmed)' | translate"
              [(ngModel)]="order.order_passport.transaction_quantity"
              [type]="RESULT_FIELD_TYPE.CONVERSION"
              [defaultValue]="order.order_passport.quantity"
              [conversionFactor]="order.deposit.passport.conversion"
              [conversionUnit]="order.deposit.passport.unit"
            >
            </order-transaction-confirm-result-field>
            <order-transaction-confirm-result-field
              [formControlName]="'date'"
              [placeholderExpected]="'Pickup date (expected)' | translate"
              [placeholderConfirmed]="'Pickup date (confirmed)' | translate"
              [type]="RESULT_FIELD_TYPE.DATE"
              [defaultValue]="order.ecosystem_step.linked_date">
            </order-transaction-confirm-result-field>
            <order-transaction-confirm-result-field
              [formControlName]="'price'"
              [(ngModel)]="order.order_passport.transaction_price"
              [placeholderExpected]="'Price (expected)' | translate"
              [placeholderConfirmed]="'Price (confirmed)' | translate"
              [type]="RESULT_FIELD_TYPE.NUMBER"
              [defaultValue]="order.price">

              <span slot="endExpected"><ng-container *ngTemplateOutlet="priceUnit"></ng-container></span>
              <span slot="endConfirmed"><ng-container *ngTemplateOutlet="priceUnit"></ng-container></span>
              <ng-template #priceUnit>
                <span class="order-transaction-confirmation__form-list-row-item-input-unit">€</span>
                <label
                  class="form-group__label order-transaction-confirmation__form-list-tooltip"
                  md-tooltip="{{ 'DO_ORDER_PRICE_TOOLTIP' | translate }}">
                  <i class="form-group__label--question">?</i>
                </label>
              </ng-template>
            </order-transaction-confirm-result-field>
        </ul>
      <section>
        <md-input-container floatPlaceholder="always"
                            class="deposit-order-transaction-confirmation__additional-description">
          <textarea
            #description
            mdInput
            placeholder="{{ 'Description' | translate}}"
            [(ngModel)]="order.order_passport.transaction_description"
            [disabled]="disabled"
          >
          </textarea>
        </md-input-container>
        <section  class="deposit-order-transaction-confirmation__additional-documents-container">
        <h4 class="order-transaction-confirmation__document-label
          deposit-order-transaction-confirmation__additional-documents-title"
            [ngClass]="{'primary-color': !disabled}">
          {{'Traceability documents' | translate}}</h4>
        <documents-uploader
          class="passport__uploader deposit-order-transaction-confirmation__additional-documents"
          [files]="allFiles"
          [autoupload]="false"
          [onUploadAll]="requestFileSenderService.onUploadAll"
          [isDisabled]="disabled"
          [hideUploadButton]="disabled"
          [service]="ecosystemFileService"
          [uploader]="requestFileSenderService.fileUploader"
          [uploadUrl]="ecosystemService.getFileUploadUrl(order.ecosystem.id)"
          [beforeRemoveAction]="deleteOnlyLinkIfUploaded()"
          (onItemUploaded)="requestFileSenderService.onCompleteItem($event)"
          (onAllItemsUploaded)="createFileJunctions($event)"
        >
        </documents-uploader>
        </section>
      </section>
    </main>
  </article>
</confirm-with-header>
