<div>
  <dialog-close-button (click)="mdDialogRef.close()"></dialog-close-button>
  <div class="deposit-import-errors" tabindex="1" (keydown)="onSelectAll($event)">
    <div class="deposit-import-errors__image">
      <confirm-dialog-icon
        [iconData]="iconData"
      >
      </confirm-dialog-icon>
    </div>
    <div class="deposit-import-errors__file-erors">
      {{'file errors' | translate | uppercase}}
    </div>
    <div class="deposit-import-errors__file-erors--description">
      {{'The import failed because of the following lines' | translate}} :
    </div>
    <div class="deposit-import-errors__table-errors-container">
      <table class="deposit-import-errors__table-errors" id="table-errors" *ngIf="mdDialogData.errors">
        <thead>
        <tr class="deposit-import-errors__row--header">
          <th class="deposit-import-errors__lines-header primary-color">{{'Lines' | translate}}</th>
          <th class="deposit-import-errors__error-header primary-color">{{'Errors' | translate}}</th>
        </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let err of mdDialogData.errors; let even = even">
            <tr [ngClass]="{ 'deposit-import-errors__row': true, 'gray': even } ">
              <td class="deposit-import-errors__td--line">{{err.line}}</td>
              <td class="deposit-import-errors__td--error">{{err.error}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
