<md-dialog-content class="order-details__dialog">
  <dialog-close-button
    (click)="mdDialogRef.close()"
    [color]="'white'"
  ></dialog-close-button>

  <deposit-order-details
    [id]="mdDialogData.id"
    [readOnly]="mdDialogData.readOnly"
    (rejected)="mdDialogRef.close()"
    (validated)="mdDialogRef.close()"
    (reportedProblem)="mdDialogRef.close()"
    (askedForModification)="mdDialogRef.close()"
    (confirmed)="mdDialogRef.close()"
  ></deposit-order-details>
</md-dialog-content>
