import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformFile } from '../models/transform-file.class';
var TransformFileService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformFileService, _super);
    function TransformFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-files';
        _this.namespaceSingular = 'transformation-file';
        _this.ModelClass = TransformFile;
        return _this;
    }
    return TransformFileService;
}(CrudService));
export { TransformFileService };
