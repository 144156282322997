<div class="confirm-dialog-icon" [ngSwitch]="iconType">
  <svg-icon *ngSwitchCase="CONFIRM_DIALOG_ICON.SVG"
            [src]="getEndpoint(showIconURL)"
            [ngClass]="{'primary-fill': primaryFill}"
            class="confirm-dialog-icon__icon--svg {{iconClass}}"
  >
  </svg-icon>
  <md-icon
    *ngSwitchCase="CONFIRM_DIALOG_ICON.MD"
    [ngClass]="{'primary-color': primaryFill}"
    class="confirm-dialog-icon__icon--md {{iconClass}}">{{showIconText}}</md-icon>
  <i *ngSwitchCase="CONFIRM_DIALOG_ICON.USUAL"
      class="confirm-dialog-icon__icon" [ngClass]="iconClass"></i>
  <span *ngSwitchCase="CONFIRM_DIALOG_ICON.TEXT"
        [ngClass]="{'primary-color': primaryFill}"
        class="confirm-dialog-icon__icon--text">{{showIconText}}</span>
  <img *ngSwitchCase="CONFIRM_DIALOG_ICON.IMG"
        [ngClass]="{'background-primary-color': primaryFill}"
        src="{{showIconURL}}"
        class="confirm-dialog-icon__icon--img"/>
</div>
