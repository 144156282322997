/**
 * Created by kirill on 7.08.17.
 */
export enum ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE {
  TYPE_DEPOSIT_ORDER = 1,
  TYPE_TRANSFORMATION_ORDER = 2,
  TYPE_ECOSYSTEM_OFFER = 3,
  TYPE_ECOSYSTEM_WASTE = 4,
  TYPE_ECOSYSTEM_CO2 = 5,
  TYPE_ECOSYSTEM_ECONOMIC = 6,
  TYPE_ECOSYSTEM_WATER = 7,
  TYPE_DEPOSIT_ORDER_TRACEABILITY = 8,
  TYPE_TRANSFORMATION_ORDER_TRACEABILITY = 9,
  TYPE_OFFER_TRACEABILITY = 10
}
