/**
 * Created by aleksandr on 8.08.17.
 */

import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
import { ILiteral } from 'ng2-adn-common';
import { Passport } from '../models/passport.class';
import { PassportService } from '../services/passport.service';

@Injectable()
export class PassportResolver extends EntityResolver<Passport> implements Resolve<Passport> {

  protected query: ILiteral = {
    expand: 'price,custom_made_name,composite_name,catalogue_product_name,rating,user.roles,user.id,category,distribution_areas.country,gtins,passport_synchronization_data'
  };

  constructor(protected _service: PassportService) {
    super();
  }
}
