export const DAY_NAMES_EN:  {
  fullName: string,
  shortName: string
}[] =[
    {fullName:'Sunday',shortName:'Su'},
    {fullName:'Monday', shortName:'Mo'},
    {fullName:'Tuesday',shortName:'Tu'},
    {fullName:'Wednesday',shortName:'We'},
    {fullName:'Thursday',shortName:'Th'},
    {fullName:'Friday',shortName:'Fr'},
    {fullName:'Saturday',shortName:'Sa'},
  ];

export const DAY_NAMES_FR:  {
  fullName: string,
  shortName: string
}[] =[
  {fullName:'Dimanche',shortName:'Di'},
  {fullName:'Lundi', shortName:'Lu'},
  {fullName:'Mardi',shortName:'Ma'},
  {fullName:'Mercredi',shortName:'Me'},
  {fullName:'Jeudi',shortName:'Je'},
  {fullName:'Vendredi',shortName:'Ve'},
  {fullName:'Samedi',shortName:'Sa'},
];

export const DAY_NAMES_ES:  {
  fullName: string,
  shortName: string
}[] = [
  {fullName: 'Domingo', shortName: 'Do'},
  {fullName: 'Lunes',  shortName: 'Lu'},
  {fullName: 'Martes', shortName: 'Ma'},
  {fullName: 'Miércoles', shortName: 'Mi'},
  {fullName: 'Jueves', shortName: 'Ju'},
  {fullName: 'Viernes', shortName: 'Vi'},
  {fullName: 'Sábado', shortName: 'Sa'},
];
