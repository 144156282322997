import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';
import {ILiteral} from 'ng2-adn-common';



@Injectable()
export abstract class ExtendedCrudService extends CrudService {

  public getWithPaginationData(query?: any): Observable<any | Response> {
    return this
      .sendGet(this.getEndpoint(this.namespace), {search: query})
      .catch(this.onError.bind(this));
  }

  public getFileUploadUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/files');
  }

  public getLogoUploadUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/logo');
  }

  protected getPublishUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/publish');
  }

  protected getUnpublishUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/unpublish');
  }

  protected getAskForModificationUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/formod');
  }

  protected getAskForRatingUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/forrating');
  }

  protected getRejectUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/reject');
  }

  protected getValidateUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/validate');
  }

  protected getValidateAllUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/validate_all');
  }

  protected getUnvalidateUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/unpublish');
  }

  protected getConfirmUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/confirm');
  }

  protected getRefuseUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/refuse');
  }

  public publish<T>(id: number, data): Observable<any > {
    data.context = this.navigation.getContext().roleId;
    return this
      .sendPost(this.getPublishUrl(id), data)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public unpublish<T>(id: number, data: T): Observable<any | T> {
    return this
      .sendPost(this.getUnpublishUrl(id), data)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public askForModification<T>(id: number, data: T): Observable<any | T> {
    return this
      .sendPost(this.getAskForModificationUrl(id), data)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public askForRating<T>(id: number, data: T): Observable<any | T> {
    return this
      .sendPost(this.getAskForRatingUrl(id), data)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public reject<T>(id: number, data: T): Observable<any | T> {
    return this
      .sendPost(this.getRejectUrl(id), data)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public validate<T>(id: number, data: any): Observable<any | T> {
    data.context = this.navigation.getContext().roleId;
    return this
      .sendPost(this.getValidateUrl(id), data)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public validateAll<T>(id: number, data: T): Observable<any | T> {
    return this
      .sendPost(this.getValidateAllUrl(id), data)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public unvalidate<T>(id: number, data: T): Observable<any | T> {
    return this
      .sendPost(this.getUnvalidateUrl(id), data)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public confirm<T>(id: number, data: any): Observable<any | T> {
    data.context = this.navigation.getContext().roleId;
    return this
      .sendPost(this.getConfirmUrl(id), data)
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public refuse<T>(id: number): Observable<any | T> {
    return this
      .sendPost(this.getRefuseUrl(id))
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public canDelete(id: number) {
    return this.sendGet(this.getEndpoint(this.namespaceSingular + '/' + id + '/candelete'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  public canUpdate(id: number) {
    return this.sendGet(this.getEndpoint(this.namespaceSingular + '/' + id + '/canupdate'))
      .catch(this.onError.bind(this));
  }

  public get<T>(query?: any): Observable<T[]> {
    if (query && query['status[]'] && query['status[]'].length === 0) { // applying 0 status filters is equivalent to requesting nothing
      return Observable.of([]);
    }
    return super.get(query);
  }

  public getModificationMessage(id: number) {
    return this.sendGet(this.getEndpoint(`${this.namespaceSingular}/${id}/modification`))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  askModification(id: number, data?: any): Observable<any> {
    return this.sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/modification'), data)
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }
}
