import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Optional, Output, ViewChild} from '@angular/core';
import { PassportFamily } from '../../../models/passport.class';

@Component({
  moduleId: module.id,
  selector: 'passport-family-label',
  templateUrl: 'passport-family-label.component.html'
})
export class PassportFamilyLabel {

    @Input() familyType: PassportFamily;
    constructor() {}

    getFamilyName() {
        switch(this.familyType) {
            case PassportFamily.FAMILY_GENERIC:
            case PassportFamily.FAMILY_LOCAL:
            case PassportFamily.FAMILY_MANUFACTURER:
                return `FAMILY_TYPE_${this.familyType}`;
            default:
                return 'UNKNOWN';
        }
        
    }

    getClassName() {
        switch(this.familyType) {
            case PassportFamily.FAMILY_GENERIC:
            case PassportFamily.FAMILY_LOCAL:
            case PassportFamily.FAMILY_MANUFACTURER:
                return `passportlist__family_label--${this.familyType}`;
            default:
                return `passportlist__family_label--unknown`;
        }
        
    }

}
