import {EventEmitter, Injectable} from '@angular/core';
import {EcosystemPrefillService, IPrefill, IPrefillChange, PREFILL_OFFER_ID} from './ecosystem-prefill.service';
import {Observable} from 'rxjs/Observable';
import {Ecosystem} from '../models/ecosystem.class';
import {OrderPassport} from '../models/order-passport.class';
import {count, delayWhen, tap, first, concatMap, mergeMap} from 'rxjs/operators';
import {ActiveRecord} from '../../../shared/models/active-record.class';
import {Subject} from 'rxjs/Subject';
import {Transform} from '../../../entities/transformation/models/transform.class';
import {Passport} from '../../../entities/passport/models/passport.class';
import {EcosystemStep} from '../models/ecosystem-step.class';
import {TransformOrder} from '../models/transform-order.class';
import {EcosystemOffer} from '../../../entities/ecosystem-offer/ecosystem-offer.model';
import {ECOSYSTEM_STEP_TYPE} from '../values/ecosystem-step-type.const';

@Injectable({
  providedIn: 'root'
})
export class EcosystemCompilerSharedActionsService {

  private _ecosystem: Ecosystem;
  private prefillObserver = Observable.of({});
  private runningObserver = [];
  private loadEnded$: Subject<any>;
  public onPrefillFinished = new Subject<IPrefill>();

  constructor(private prefillService: EcosystemPrefillService) { }

  private _prefillUpdate(change: IPrefillChange): Observable<any>[] {
    return  Object.keys(change).map(id => {
      if (Number(id) === PREFILL_OFFER_ID) {
        const offer = this._ecosystem.ecosystem_offer;
        offer.quantity = change[PREFILL_OFFER_ID].in.after;
        return offer.save();
      } else {
        const order = this._ecosystem.transformation_orders.find(transformOrder => transformOrder.id === Number(id));
        const observers = [];
        if (change[id].in) {
          let orderPassport = order.in_order_passport;
          if (!(order.in_order_passport instanceof OrderPassport)) {
            orderPassport = new OrderPassport(orderPassport);
          }
          orderPassport.quantity = change[id].in.after;
          observers.push(orderPassport.save());
        }
        if (change[id].out) {
          let orderPassport = order.out_order_passport;
          if (!(order.out_order_passport instanceof OrderPassport)) {
            orderPassport = new OrderPassport(orderPassport);
          }
          orderPassport.quantity = change[id].out.after;
          observers.push(orderPassport.save());
        }
        return Observable.zip(...observers);
      }
    });
  }

  public prefillSetDirty(change: IPrefillChange, orders: ActiveRecord[] = []) {
    this.prefillObserver = Observable.concat(Observable.of({}), ...this._prefillUpdate(change), ...orders.map(
            order => {
              return order.save();
            })).pipe(count(() => true));
    return this.prefillObserver;
  }

  public beforeCompilerReload() {
    const observers = this.prefillObserver;
    if (observers) {
      this.prefillObserver = null;
    }
    return observers || Observable.of({});
  }

  public set ecosystem(ecosystem: Ecosystem) {
    this._ecosystem = ecosystem;
  }

  public get ecosystem() {
    return this._ecosystem;
  }


  public sameUnitButDifferentConversion(order: TransformOrder | EcosystemOffer, transform: Transform, passport: Passport) {
    if (!order || !this.ecosystem) {
      return false;
    }
    let conversion;
    let unit;
    if (transform) {
      unit = transform.input_unit;
      conversion = transform.in_conversion;
    } else if (passport) {
      unit = passport.unit;
      conversion = passport.conversion;
    } else {
      return false;
    }
    let child;
    if (order instanceof TransformOrder) {
      child = order.child_step;
    } else if (order instanceof EcosystemOffer) {
      const needStep = this.ecosystem.steps.find(step => step.type  === ECOSYSTEM_STEP_TYPE.NEED);
      child = this.ecosystem.steps.find(step => needStep.id  === step.parent_id);
    }
    if (child) {
      const transformOrder = this.ecosystem.transformation_orders.find(childOrder => childOrder.step_id === child.id);
      if (transformOrder) {
        return conversion && transformOrder.transformation && transformOrder.transformation.out_conversion
          && conversion !== transformOrder.transformation.out_conversion
          && unit === transformOrder.transformation.output_unit;
      }
      const depositOrder = this.ecosystem.deposit_orders.find(childOrder => childOrder.step_id === child.id);
      if (depositOrder) {
        return conversion && depositOrder.deposit_passport && depositOrder.deposit_passport.conversion
          && conversion !== depositOrder.deposit_passport.conversion
          && unit === depositOrder.deposit_passport.unit;
      }
    }
    return false;
  }
}
