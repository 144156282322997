import { OnInit } from '@angular/core';
import { EcosystemStep } from '../../../models/ecosystem-step.class';
import { PASSPORT_UNITS_TYPE } from '../../../../../entities/passport/values/passport-units.const';
import { ECOSYSTEM_STEP_TYPE, ECOSYSTEM_STEP_TYPE_MAP } from '../../../values/ecosystem-step-type.const';
import { TRANSFORM_TYPE } from '../../../../transformation-manager/values/transform-types.const';
import { ECOSYSTEM_CALC_ERROR, EcosystemCalcErrorUtil } from '../../../values/ecosystem-calc-error.const';
import { EcosystemService } from '../../../../../entities/ecosystem/services/ecosystem.service';
import { TranslateService } from '@ngx-translate/core';
var EcosystemIndicatorsSummaryExpanderComponent = /** @class */ (function () {
    function EcosystemIndicatorsSummaryExpanderComponent(ecosystemService, translate) {
        this.ecosystemService = ecosystemService;
        this.translate = translate;
        this.ECOSYSTEM_STEP_TYPE = ECOSYSTEM_STEP_TYPE;
        this.TRANSFORM_TYPE = TRANSFORM_TYPE;
        this.summaryItemExpanded = false;
        this.ECOSYSTEM_CALC_ERROR = ECOSYSTEM_CALC_ERROR;
        this.errorUtil = new EcosystemCalcErrorUtil();
    }
    EcosystemIndicatorsSummaryExpanderComponent.prototype.ngOnInit = function () {
        this.step = new EcosystemStep(this.step);
    };
    EcosystemIndicatorsSummaryExpanderComponent.prototype.toggle = function () {
        this.summaryItemExpanded = !this.summaryItemExpanded;
    };
    Object.defineProperty(EcosystemIndicatorsSummaryExpanderComponent.prototype, "subtitle", {
        get: function () {
            if (this.isTransport()) {
                return this.step.summary_details.orders.reduce(function (total, current) { return total + +current.confirmed_distance; }, 0) + " Km";
            }
            else if (this.step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT || this.step.type === ECOSYSTEM_STEP_TYPE.NEED) {
                return this.step.passport_name;
            }
            else {
                return this.step.site_name;
            }
        },
        enumerable: true,
        configurable: true
    });
    EcosystemIndicatorsSummaryExpanderComponent.prototype.isTransport = function () {
        return this.step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM && this.step.transformation_type === TRANSFORM_TYPE.TRANSPORT;
    };
    Object.defineProperty(EcosystemIndicatorsSummaryExpanderComponent.prototype, "quantityLabel", {
        get: function () {
            if (this.isTransport()) {
                return { expected: 'Input quantity (expected)', confirmed: 'Input quantity (confirmed)' };
            }
            else if (this.step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
                return { expected: 'Input quantity (expected)', confirmed: 'Input quantity (confirmed)' };
            }
            else {
                return { expected: 'Quantity (expected)', confirmed: 'Quantity (confirmed)' };
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemIndicatorsSummaryExpanderComponent.prototype, "typeLabel", {
        get: function () {
            if (this.isTransport()) {
                return 'TRANSPORT';
            }
            else {
                return ECOSYSTEM_STEP_TYPE_MAP[this.step.type];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemIndicatorsSummaryExpanderComponent.prototype, "firstOrder", {
        get: function () {
            var orderList = this.step.summary_details.orders;
            return orderList && orderList.length && orderList[0];
        },
        enumerable: true,
        configurable: true
    });
    EcosystemIndicatorsSummaryExpanderComponent.prototype.getPreviousStep = function (id) {
        this.previousStep = this.stepNames.find(function (step) { return step['id'] === id; });
        return this.previousStep.name;
    };
    EcosystemIndicatorsSummaryExpanderComponent.prototype.getFirstOrderPriceOrNotFoundIndex = function (order) {
        return this.firstOrder.id === order.id ? this.firstOrder.confirmed_price : -1;
    };
    EcosystemIndicatorsSummaryExpanderComponent.prototype.showErrorMessage = function () {
        var _this = this;
        var step = this.step.summary_details.step;
        var orders = this.step.summary_details.orders;
        return (step.errors && this.visibleErrors(step.errors, step.confirmed_price, step.confirmed_quantity).length > 0) ||
            (orders &&
                orders.some(function (o) { return o.errors &&
                    _this.visibleErrors(o.errors, _this.firstOrder.confirmed_price, o.confirmed_quantity, o.confirmed_distance).length > 0; }));
    };
    EcosystemIndicatorsSummaryExpanderComponent.prototype.visibleErrors = function (errors, confirmedPrice, confirmedQuantity, confirmedDistance) {
        var _this = this;
        return errors.filter(function (e) { return _this.isVisibleError(e, confirmedPrice, confirmedQuantity, confirmedDistance); });
    };
    EcosystemIndicatorsSummaryExpanderComponent.prototype.getOrderTransformInputUnit = function (unit) {
        if (unit) {
            return unit;
        }
        else {
            return PASSPORT_UNITS_TYPE.KG;
        }
    };
    EcosystemIndicatorsSummaryExpanderComponent.prototype.isVisibleError = function (errorCode, confirmedPrice, confirmedQuantity, confirmedDistance) {
        return !(this.errorUtil.isPriceError(errorCode) && confirmedPrice != null)
            && !(this.errorUtil.isQuantityError(errorCode) && confirmedQuantity != null)
            && !(this.errorUtil.isDistanceError(errorCode) && confirmedDistance != null);
    };
    EcosystemIndicatorsSummaryExpanderComponent.prototype.updateQuantity = function (amount, index) {
        this.step.summary_details.orders[index].confirmed_quantity = amount;
    };
    return EcosystemIndicatorsSummaryExpanderComponent;
}());
export { EcosystemIndicatorsSummaryExpanderComponent };
