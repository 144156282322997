import { Type } from '@angular/core';
import { SynonymService } from '../services/synonym.service';
import { ActiveRecord } from './active-record.class';

export class Synonym extends ActiveRecord {
  protected provider: Type<SynonymService> = SynonymService;

  constructor(data) {
    super(data);
  }

  id: number;
  name: string;
  tag_id: number;

  fields() {
    return [
      'id',
      'name',
      'tag_id',
    ];
  }
}
