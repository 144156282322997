import {Injectable} from '@angular/core';
import {ImportConfigurationService} from './import-configuration.service';
import {CurrentUserService} from '../../shared/services/current-user.service';
import {ImportProgressService} from './import-progress.service';
import {DepositImportService} from '../../entities/deposit/services/deposit-import.service';
import {DepositLocationImportService} from '../../entities/deposit/services/deposit-location-import.service';
import {Observable} from 'rxjs/Observable';
import {first} from 'rxjs/operators';
import {ImportDeepstreamService} from './import-deepstream.service';
import {ActiveImportService} from './active-import.service';
import {ActiveImport} from '../models/active-import.class';
import {ACTIVE_IMPORT_STREAM_JUNCTION} from '../values/active-import-stream-junction.const';

@Injectable({
  providedIn: 'root'
})
export class ImportWorkerService {

  constructor(
    private importConfigurationService: ImportConfigurationService,
    private currentUserService: CurrentUserService,
    private importProgressService: ImportProgressService,
    private depositImportService: DepositImportService,
    private depositLocationImportService: DepositLocationImportService,
    private importDeepstreamService: ImportDeepstreamService,
    private activeImportService: ActiveImportService
  ) {
  }


  public getImportIfExist() {
    const prefObserver = this.checkIsImportWorkerRunning();
    const events = this.importDeepstreamService.getAllImportStream();
    const skipLoad = Observable.merge(...Object.values(events) as any)
      .pipe(first())
      .map((data: any, index) => {
        return this.createActiveImportFromStream(data);
      });
    return Observable.merge(prefObserver, skipLoad).pipe(first());
  }

  private checkIsImportWorkerRunning() {
    const user_id = this.currentUserService.infoData.id;
    return this.activeImportService.get({user_id});
  }

  private createActiveImportFromStream({value, type, stream}) {
      return [new ActiveImport({
        import_id: value.data.import_id,
        import_type: type,
        status: ACTIVE_IMPORT_STREAM_JUNCTION[stream]
      } as ActiveImport)];
  }
}
