import { ActiveRecord } from '../../../shared/models/active-record.class';
import {PassportGtinService} from '../services/passport-gtin.service';

export class PassportGtin extends ActiveRecord {
  protected provider = PassportGtinService;
  public code: string;
  public id: number;
  public passport_id: number;

  protected fields(): string[] {
    return [
      'id',
      'code',
      'passport_id'
    ];
  }
}
