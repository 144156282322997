/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./selectable-list-filter.component.ngfactory";
import * as i2 from "./selectable-list-filter.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../shared/services/navigation.service";
import * as i5 from "./creator-filter.component";
import * as i6 from "../../../entities/passport/services/agent.service";
import * as i7 from "../../../entities/passport/services/specialAdmin.service";
var styles_CreatorFilterComponent = [];
var RenderType_CreatorFilterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreatorFilterComponent, data: {} });
export { RenderType_CreatorFilterComponent as RenderType_CreatorFilterComponent };
export function View_CreatorFilterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "creators-filter-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-selectable-list-filter", [], null, [[null, "selectionChange"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectionChange" === en)) {
        var pd_1 = (_co.handleSelectionChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectableListFilterComponent_0, i1.RenderType_SelectableListFilterComponent)), i0.ɵdid(2, 638976, null, 0, i2.SelectableListFilterComponent, [i3.TranslateService, i4.NavigationService], { selectedItems: [0, "selectedItems"], items: [1, "items"], placeholder: [2, "placeholder"] }, { selectionChange: "selectionChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.creatorSelected; var currVal_1 = _co.creators; var currVal_2 = "SEARCH_BY_CREATOR"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CreatorFilterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-creator-filter", [], null, null, null, View_CreatorFilterComponent_0, RenderType_CreatorFilterComponent)), i0.ɵdid(1, 114688, null, 0, i5.CreatorFilterComponent, [i4.NavigationService, i6.AgentService, i7.SpecialAdminService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreatorFilterComponentNgFactory = i0.ɵccf("app-creator-filter", i5.CreatorFilterComponent, View_CreatorFilterComponent_Host_0, { creatorSelected: "creatorSelected" }, { creatorChange: "creatorChange" }, []);
export { CreatorFilterComponentNgFactory as CreatorFilterComponentNgFactory };
