import { Component, OnInit } from '@angular/core';
import { TransformLocation } from '../../../../entities/transformation/models/transform-location.class';
import { TransformLocationService } from '../../../../entities/transformation/services/transform-location.service';
import { ORDER_STATUSES } from '../../values/order-statuses.const';
import { TransformOrderService } from '../../../ecosystem-manager/services/transform-order.service';
import { TransformOrder } from '../../../ecosystem-manager/models/transform-order.class';
import { TransformService } from '../../../../entities/transformation/services/transform.service';
import { TRANSFORM_TYPES } from '../../values/transform-types.const';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { UserService } from '../../../../shared/services/user.service';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';

@Component({
  moduleId: module.id,
  templateUrl: 'transform-dashboard.component.html',
  styleUrls: ['transform-dashboard.component.scss'],
})
export class TransformDashboardComponent implements OnInit {
  locations: TransformLocation[];
  orders: TransformOrder[];
  transforms: Transform[];
  ORDER_STATUSES = ORDER_STATUSES;
  TRANSFORM_TYPES = TRANSFORM_TYPES;

  newOrders:number;
  validatedTransformations:number;
  transformationsInEcosystem:number;

  constructor(private locationService: TransformLocationService,
              private orderService: TransformOrderService,
              private transformService: TransformService,
              private userService: UserService
  ) {
    console.info('Launch  -> TransformationDashboardComponent');
    this.userService.getRoleCounters({role: ERoleIds.TRANSFORMATION_SITE_MANAGER}).subscribe(
      (res:any) => {
        this.newOrders=Number(res.new_orders);
        this.validatedTransformations = Number(res.new_transformations);
        this.transformationsInEcosystem = Number(res.transformations_in_ecosystems);
      });
    // this.orderService.getCount().subscribe(c => this.newOrders = c);
    // this.transformService.getCount().subscribe(c => this.validatedTransformations = c);
    // this.transformService.getInEcosystem().subscribe(c => this.transformationsInEcosystem = c);
  }

  ngOnInit() {
    this.locationService.get().subscribe((locations: TransformLocation[]) => {
      this.locations = locations;
    });
    this.orderService.get().subscribe((orders: TransformOrder[]) => {
      this.orders = orders;
    });
    this.transformService.get({supervisor: true}).subscribe((transforms: Transform[]) => {
      this.transforms = transforms;
    });
  }
}
