import { EventEmitter, Injector, OnInit, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlName, FormGroupDirective, NgControl, NgModel } from '@angular/forms';
var PassportConversionComponent = /** @class */ (function () {
    function PassportConversionComponent(injector) {
        this.injector = injector;
        this.errorText = 'VALIDATOR_ERROR_PRIMARY_MESSAGE';
        this.unitConvertTo = 'kg';
        this.showValidationError = this.defaultShowError;
        this.ngModelChange = new EventEmitter();
        this.control = new FormControl();
    }
    Object.defineProperty(PassportConversionComponent.prototype, "ngModel", {
        set: function (item) {
            this.value = item;
        },
        enumerable: true,
        configurable: true
    });
    PassportConversionComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _control = this.injector.get(NgControl);
        switch (_control.constructor) {
            case NgModel: {
                this.control = _control.control;
                this.control.setValue(this.value);
                break;
            }
            case FormControlName: {
                var group_1 = this.injector.get(FormGroupDirective);
                setTimeout(function () {
                    _this.control = group_1.getControl(_control);
                    _this.control.setValue(_this.value);
                    _this.subscription = _this.control.valueChanges.subscribe(function (event) { return _this.ngModelChange.emit(event); });
                });
                break;
            }
        }
    };
    PassportConversionComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    PassportConversionComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    PassportConversionComponent.prototype.registerOnTouched = function (fn) {
        this.onTouch = fn;
    };
    PassportConversionComponent.prototype.setDisabledState = function (isDisabled) {
    };
    PassportConversionComponent.prototype.writeValue = function (obj) {
    };
    PassportConversionComponent.prototype.defaultShowError = function () {
        var errors = (this.control && this.control.errors) || [];
        return !!Object.keys(errors).length;
    };
    return PassportConversionComponent;
}());
export { PassportConversionComponent };
