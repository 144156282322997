import { ElementRef, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';
import { EcosystemFileService } from '../../../../entities/ecosystem/services/ecosystem-file.service';
import { FileUploader } from 'ng2-file-upload';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { Utils } from '../../../../shared/utils/utils.class';
var EcosystemFileDialogComponent = /** @class */ (function () {
    function EcosystemFileDialogComponent(mdDialogRef, ecoFileService, notification) {
        this.mdDialogRef = mdDialogRef;
        this.ecoFileService = ecoFileService;
        this.notification = notification;
        this.uploadedDocs = [];
        this.isOpenFiles = false;
        this.stepId = null;
    }
    EcosystemFileDialogComponent.prototype.preloadUploadedItems = function () {
        var _this = this;
        this.ecoFileService
            .get({
            ecosystem_id: this.ecosystem.id
        })
            .subscribe(function (docs) {
            var _a;
            (_a = _this.uploadedDocs).push.apply(_a, docs);
        });
    };
    EcosystemFileDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ecosystem = this.mdDialogRef._containerInstance.dialogConfig.data.ecosystem;
        this.isOpenFiles = this.mdDialogRef._containerInstance.dialogConfig.data.isOpenFiles;
        this.stepId = this.mdDialogRef._containerInstance.dialogConfig.data.stepId;
        console.log('step id ', this.stepId);
        this.preloadUploadedItems();
        this.uploader = new FileUploader({
            url: this.ecosystem.getFileUploadUrl(),
            itemAlias: 'file',
            autoUpload: true
        });
        this.uploader.onSuccessItem = (function (_, file) {
            console.log('file uploaded:', file);
            _this.uploadedDocs.push(_this.ecoFileService.buildModel(file));
        });
        this.uploader.onErrorItem = Utils.onUploadError(function (event) { return _this.notification.error(event, null, true); });
    };
    EcosystemFileDialogComponent.prototype.uploadFiles = function () {
        this.fileInput.nativeElement.click();
    };
    EcosystemFileDialogComponent.prototype.removeFile = function (file) {
        var _this = this;
        this.ecoFileService.remove(file.id).subscribe(function (result) {
            console.log('file was deleted:', file);
            _this.uploadedDocs.splice(_this.uploadedDocs.indexOf(file), 1);
        });
    };
    EcosystemFileDialogComponent.prototype.selectFile = function (file) {
        console.log('file was selected:', file);
        this.mdDialogRef.close(file);
    };
    return EcosystemFileDialogComponent;
}());
export { EcosystemFileDialogComponent };
