import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import { Passport } from '../../passport/models/passport.class';
import { VirginProductService } from '../services/virgin-product.service';
import { ProductSector } from './product-sector.class';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
var VirginProduct = /** @class */ (function (_super) {
    tslib_1.__extends(VirginProduct, _super);
    function VirginProduct(data) {
        var _this = _super.call(this, data) || this;
        _this.plumb_type = ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT;
        _this.provider = VirginProductService;
        data = data || {};
        _this.nomenclature = (data.nomenclature || []).map(function (data) { return new NomenclatureItem(data); });
        _this.sectors = (data.sectors || []).map(function (data) { return new ProductSector(data); });
        _this.passport = new Passport(Object.assign(data.passport || {}, { nomenclature: _this.nomenclature }));
        return _this;
    }
    VirginProduct.prototype.fields = function () {
        return [
            'id',
            'name',
            'passport_id',
            'sector_id',
            'c2c_certified',
            'address',
            'description',
            'contact_name',
            'contact_email',
            'contact_phone',
            'contact_title',
            'created_at',
            'updated_at',
        ];
    };
    VirginProduct.prototype.includesText = function (searchText) {
        searchText = searchText.toLocaleLowerCase();
        var doesNameInclude = _.includes(this.name.toLocaleLowerCase(), searchText);
        return doesNameInclude;
    };
    return VirginProduct;
}(ExtendedActiveRecord));
export { VirginProduct };
