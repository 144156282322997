import { Injectable } from '@angular/core';
import { Client } from '../model/client.class';

@Injectable()
export class DetailService {

  detialedClient: Client;

  public setDetailClient(client: Client) {
    this.detialedClient = client;
  }

  public getDetailClient(): Client {
    return this.detialedClient;
  }

}
