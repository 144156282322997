/**
 * Created by aleksandr on 3.08.17.
 */
import * as tslib_1 from "tslib";
import { DepositService } from '../services/deposit.service';
import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
var DepositResolver = /** @class */ (function (_super) {
    tslib_1.__extends(DepositResolver, _super);
    function DepositResolver(_service) {
        var _this = _super.call(this) || this;
        _this._service = _service;
        _this.query = {
            expand: 'nomenclature,tags,url_link,product_comment,color,' +
                'deposit_location,deposit_location.tags,' +
                'passport,passport_price,passport.category,passport.gtins'
        };
        return _this;
    }
    return DepositResolver;
}(EntityResolver));
export { DepositResolver };
