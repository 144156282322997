import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  template: `
    <user-details
      (onSaveAction)="router.navigate(['admin/users'])"
      [id]="activatedRoute.snapshot.params['id'] * 1"
    ></user-details>
  `,
})
export class UserEditComponent {
  constructor(public activatedRoute: ActivatedRoute,
              public router: Router) {
  }
}
