
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>

    <virgin-product-details
      [id]="mdDialogData.id"
      (saved)="mdDialogRef.close(true)"
      [readOnly]="mdDialogData.readOnly"
    ></virgin-product-details>
  