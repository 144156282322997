/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public-passport-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../entities/passport/partials/passport-list/passport-list.component.ngfactory";
import * as i3 from "../../entities/passport/partials/passport-list/passport-list.component";
import * as i4 from "@angular/material";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../shared/services/navigation.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "../../entities/passport/services/passport.service";
import * as i10 from "../../shared/services/filterState.service";
import * as i11 from "../../shared/services/current-user.service";
import * as i12 from "../../shared/services/navbar.service";
import * as i13 from "./public-passport-list.component";
import * as i14 from "../../entities/client/service/language.service";
var styles_PublicPassportListComponent = [i0.styles];
var RenderType_PublicPassportListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicPassportListComponent, data: {} });
export { RenderType_PublicPassportListComponent as RenderType_PublicPassportListComponent };
export function View_PublicPassportListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "passport-list", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PassportListComponent_0, i2.RenderType_PassportListComponent)), i1.ɵdid(2, 114688, null, 0, i3.PassportListComponent, [i4.MdDialog, i5.TranslateService, i6.NavigationService, i4.MdDialog, i7.Router, i8.DatePipe, i9.PassportService, i1.ChangeDetectorRef, i10.FilterStateService, i11.CurrentUserService, i12.NavbarStateService], { isForPublicAccess: [0, "isForPublicAccess"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PublicPassportListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-public-passport-list", [], null, null, null, View_PublicPassportListComponent_0, RenderType_PublicPassportListComponent)), i1.ɵdid(1, 114688, null, 0, i13.PublicPassportListComponent, [i14.LanguageService, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PublicPassportListComponentNgFactory = i1.ɵccf("app-public-passport-list", i13.PublicPassportListComponent, View_PublicPassportListComponent_Host_0, {}, {}, []);
export { PublicPassportListComponentNgFactory as PublicPassportListComponentNgFactory };
