<div class="passport-dialog">
    <h2 md-dialog-title>{{ 'Duplicate Transformation' | translate }}</h2>
    
    <footer class="controls">
      <div class="ib-column passport__component-field">
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            placeholder="{{ 'New transformation name' | translate }}"
            [(ngModel)]="name">
        </md-input-container>
      </div>
      <ul class="button-group__list">
        <li class="button-group__item button-group__item--short">
          <button md-button md-raised-button (click)="submit(name)">{{ 'Confirm' | translate }}</button>
        </li>
        <li class="button-group__item button-group__item--short" *ngIf="isSelectAnotherTransShown">
          <button md-button md-raised-button (click)="onSelectAnotherTransformation()">{{ 'Select another transformation' | translate }}</button>
        </li>
        <li class="button-group__item button-group__item--short">
          <button md-button md-raised-button (click)="submit(false)">{{ 'Cancel' | translate }}</button>
        </li>
      </ul>
    </footer>
  </div>
  