/**
 * Created by natalja on 30/06/2017.
 */

import { TransformStatus } from '../../../entities/transformation/models/transform-status.class';

export const TRANSFORM_STATUSES: {
  DRAFT: TransformStatus,
  WAITING_FOR_PUBLICATION: TransformStatus,
  ASK_FOR_MODIFICATION: TransformStatus,
  PUBLISHED: TransformStatus,
  REJECTED: TransformStatus,
} = {
  DRAFT: {
    value: 1,
    text: 'DRAFT',
    view: 'draft'
  },
  WAITING_FOR_PUBLICATION: {
    value: 2,
    text: 'WAITING_FOR_PUBLICATION',
    view: 'waiting for publication'
  },
  PUBLISHED: {
    value: 3,
    text: 'PUBLISHED',
    view: 'published'
  },
  REJECTED: {
    value: 4,
    text: 'REJECTED',
    view: 'rejected'
  },
  ASK_FOR_MODIFICATION: {
    value: 5,
    text: 'ASK_FOR_MODIFICATION',
    view: 'asked for modification'
  }
};
