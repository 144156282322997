/**
 * Created by Aleksandr C. on 6.02.17.
 */
import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Deposit} from '../../models/deposit.class';
import {IParentEvent} from '../../../../shared/interfaces/parent-event.interface';
import {MdDialog, MdSnackBar} from '@angular/material';
import {DepositControlStates} from '../../models/deposit-control-states.class';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {EntityViewerComponent} from '../../../../shared/base/enitity-viewer.component';
import {TranslateService} from '@ngx-translate/core';
import {PASSPORT_STATUS} from '../../../../entities/passport/values/passport-status.const';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {Utils} from '../../../../shared/utils/utils.class';
import {FileUploader} from 'ng2-file-upload';
import {DepositService} from '../../services/deposit.service'
import { combineLatest, Subject } from 'rxjs';
import { PassportValidationNotificationService } from '../../../../entities/passport/services/passport-validation-notification.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { DEPOSIT_STATUSES } from '../../../../entities/deposit/values/deposit-statuses.const';

@Component({
  moduleId: module.id,
  selector: 'deposit-edit',
  templateUrl: 'deposit-edit.component.html'
})

export class DepositEditComponent extends EntityViewerComponent<Deposit> implements OnInit, OnDestroy, AfterViewInit {
  @Input() controlStates = new DepositControlStates;
  eventStream$: EventEmitter<IParentEvent> = new EventEmitter();
  onPassportSaved: EventEmitter<any> = new EventEmitter();
  public deposit: Deposit;
  public stepId: number = 0;
  public invalidFields = [];
  public navigateAway = false;
  @Output() spinnerEnabled = false;
  private maxLogoSize = 5 * 1024 * 1024;
  uploader: FileUploader = new FileUploader({ allowedMimeType: ['image/jpeg', 'image/png']
  , maxFileSize: this.maxLogoSize});
  @Output() onLogoSave:EventEmitter<void> = new EventEmitter<void>();
  private fileUploaderStream$ = new EventEmitter();
  public tempLogo: string = null;



  constructor(protected route: ActivatedRoute,
              protected router: Router,
              public dialog: MdDialog,
              private _notificationService: LogNotificationService,
              private _translate: TranslateService,
              private zone: NgZone,
              private cd: ChangeDetectorRef,
              private navigationService: NavigationService,
              private _depositService: DepositService,
              private _passportValidationNotification: PassportValidationNotificationService,
              public notification: LogNotificationService,
              public loadingSpinner: LoadingSpinnerService
  ) {
    super(route, router);
  }

  ngAfterViewInit(): void {
    this.uploaderSpecifyUrl();
    this.uploader.onCompleteItem = (_, response) => {
      try {
        const data = JSON.parse(response);
        if (data.logo && data.thumbnail) {
          this.fileUploaderStream$.next(data);
        } else {
          throw new Error('Thumbnail and Logo should be specify in response data');
        }
      } catch (e) {
        this.fileUploaderStream$.error(e);
      }
      this.tempLogo = null;
    }
    this.uploader.onErrorItem =
    Utils.onUploadError((event: string) => {
      this.fileUploaderStream$.error(event);
      this.notification.error(event, null, true)
    });

    this.uploader.onWhenAddingFileFailed  = (_, filter) => {
      console.log(filter);
      if (filter && filter.name === 'mimeType') {
      this.notification.error('Formats accepted: ' + this.uploader.options.allowedMimeType
      .map(type => type.replace(/.+\//, '.'))
      .join(', ')
      , null, true);
      } else if (filter && filter.name === 'fileSize'){
        this.notification.error('Filesize limit is 5MB', null, true);
      }
    }
      this.cd.detectChanges();

  }

  onExistEntity(entity: Deposit): void {
    this.deposit = entity;
    this.deposit.loaded = true;
    // active step?
    this.stepId = this.deposit.getProgress();
  }

  onNewEntity(): void {
    this.deposit = new Deposit();
    this.deposit.loaded = true;
  }

  changeDeposit(deposit: Deposit) {
    this.deposit.set(deposit);
    deposit.loaded = true;
    this.eventStream$.emit({
      key: 'changeDeposit',
      data: this.deposit
    });
  }
  isButtonDisabled () {
   return  this.deposit.getProgress() < 3 || this.deposit.status == DEPOSIT_STATUSES.REJECTED.value ;
  }

  isPublished() {
    return this.deposit.status === DEPOSIT_STATUSES.PUBLISHED.value ;
  }
  savePassportIsNeeded(): boolean {
    return !!this.deposit.passport.id
      && !(this.deposit.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor());
  }

  saveDraft(index: number = null) {

    if (index === null) {
      this.spinnerEnabled = true;
    }
    if (this._passportValidationNotification.showChildNotificationErrors()) return;
    if (!this.deposit.status) {
      this.deposit.status = 1;
    }
    if (!this.isUploaderQueueEmpty()) {
      this.tempLogo = this.deposit.logo;
      this.deposit.logo = null;
      this.deposit.thumbnail = null;
    }
    this.zone.run(() => {
      const saveObserver = this.deposit.save().map(async (res) => {
        if (!this.deposit.id) {
          this.uploaderSpecifyUrl(res.id);
        }
        this.uploader.uploadAll();
        this.deposit.id = (res.id);

      });
      this.uploadLogoChanges(saveObserver, () => {
        if (index !== null) {
          this.stepId = index;
          return;
        }

        this.navigateAfterPassportResourceSave();
      });
    });
  }

  private navigateAfterPassportResourceSave() {
    this.navigateAway = true;
    if (!this.savePassportIsNeeded()) {
      this.onSaveCompleteHandler();
    }
  }

  uploadLogoChanges(saveObserver, afterUpload, onError = () => {}, onComplete = () => {}) {
    if (!this.isUploaderQueueEmpty()) {
      const logoObserver = this.fileUploaderStream$.map((res) => this.onSaveCompleteLogo(res));
      combineLatest(saveObserver, logoObserver).subscribe(() => {
          afterUpload();
          this.uploader.clearQueue();
        }, () => { this.uploader.clearQueue(); onError()}, () => onComplete());
    } else {
      if (this.deposit.id && !this.deposit.logo) {
        const deleteLogoObserver = this._depositService.removeLogo(this.deposit.id);
        combineLatest(saveObserver, deleteLogoObserver).subscribe(() => {
          afterUpload();
        }, () => onError(), () => onComplete());
      } else {
      combineLatest(saveObserver).subscribe( () => afterUpload(), () => onError(), () => onComplete());
    }
  }
  }

  onSaveCompleteLogo(data) {
    this.deposit.set({ logo: data.logo });
    this.deposit.set({ thumbnail: data.thumbnail });
   }

  onSaveCompleteHandler($event?) {
    this.destroySpinnerIfShown();
    if (this.navigateAway) {
      this.zone.run(() => this.router.navigate(['agent/deposits/']));
    }
  }

  private destroySpinnerIfShown() {
    if (this.loadingSpinner) {
      this.loadingSpinner.destroy();
    }
  }

  onPassportSaveFailedHandler($event?) {
    this.destroySpinnerIfShown();
  }

  isNextDisabled(): boolean {
    return this.deposit.getProgress() <= this.stepId;
  }

  isPrevDisabled(): boolean {
    return this.stepId <= 0;
  }

  onTabChanged(index: number): void {
    const errors = ['Name and Passport must be filled', 'Site name must be filled'];
    if (index > this.deposit.getProgress()) {
      this._notificationService.error(errors[index - 1], null, true);
      return;
    }
    if (this.stepId !== index) {
      this.saveDraft(index);
    }
  }

  submit(): void {
    if (this._passportValidationNotification.showChildNotificationErrors()) return;
    this.loadingSpinner.show('deposit');
    this.uploader.uploadAll();
    const publishObserver = this.isPublished() ? this.deposit.save() : this.deposit.publish();
    this.uploadLogoChanges(publishObserver, () => this.navigateAfterPassportResourceSave()
    , () => this.loadingSpinner.destroy());
  }

  uploaderSpecifyUrl(id: number = this.deposit.id) {
    if (!id) { return; }
      this.uploader.setOptions({
        url: this._depositService.getLogoUploadUrl(id || this.deposit.id),
        itemAlias: 'file'
      });
  }

  saveTempLogo() {
    if (!this.isUploaderQueueEmpty()) {
      const logo: File = this.uploader.queue[this.uploader.queue.length - 1]._file;
      let reader = new FileReader();
      reader.onload = () => {
        this.deposit.logo = <string>reader.result;
      }
      reader.onerror = () => {
        this.notification.error(reader.error.message, null, true);
      }
      if (logo) { reader.readAsDataURL(logo) };
    }
  }

  removeLogo() {
    this.uploader.clearQueue();
    this.deposit.logo = null;
    this.deposit.thumbnail = null;
  }

  isUploaderQueueEmpty() {
    return this.uploader.queue.length === 0;
  }
}
