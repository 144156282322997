import { Injectable } from '@angular/core';
import { Need } from '../models/need.class';

@Injectable()
export class NeedViewService {

  viewNeed: Need;

  public setNeed(need: Need) {
    this.viewNeed = need;
  }

  public getNeed(): Need {
    return this.viewNeed;
  }

}
