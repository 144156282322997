<div class="passport-edit__wrapper"  #checkboxListWithChip >
  <div class="passportlist__info-button">
    <md-input-container class="passportlist__search-materials"
                        [floatPlaceholder]="floatPlaceholder"
                        (click)="openList(LIST_OPEN_MODE.CONTAINER_CLICK)">
      <md-chip-list>
        <md-basic-chip *ngFor="let item of chosenItems" (focus)="false" [ngClass]="item.classList">
          {{item.name}}
          <button (click)="removeItem(item.value)" [hidden]="disabledChip" class="delete"></button>
        </md-basic-chip>
      </md-chip-list>
      <div class="flex cursor-pointer" (click)="openList(LIST_OPEN_MODE.INPUT_CLICK)">
        <input
          [disabled]="disabledList"
          mdInput
          maxlength="0"
          [placeholder]="placeholder"
          class="passportlist__no-caret"
          autocomplete="off">
        <span class="mat-select-arrow"></span>
      </div>
    </md-input-container>
  </div>
  <div class="passport-edit__countries-list" *ngIf="listIsVisible">
    <div class="row" *ngFor="let item of allVariants" [ngClass]="item.classList">
      <md-checkbox
        (change)="onItemChange($event, item.value)"
        [checked]="isItemChecked(item.value)">
        {{item.name}}
      </md-checkbox>
    </div>
  </div>
</div>
