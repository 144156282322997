import { EcosystemOfferService } from './ecosystem-offer.service';
import { Ecosystem } from '../../dashboards/ecosystem-manager/models/ecosystem.class';
import { ExtendedActiveRecord } from '../../shared/models/extended-active-record.class';
import { DatePipe } from '@angular/common';
import { Need } from '../need/models/need.class';
import { Client } from '../client/model/client.class';
import { ECOSYSTEM_OFFER_STATUS } from '../../dashboards/ecosystem-manager/values/ecosystem-offer-status.const';
import { Contact } from '../client/model/contact.class';
import { ECOSYSTEM_STATUS } from '../../dashboards/ecosystem-manager/values/ecosystem-status.const';
import { EcosystemFileJunction } from '../../dashboards/ecosystem-manager/models/ecosystem-file-junction.class';
import { LocaleService } from '../../shared/services/locale-service';
import { ValidationService } from '../../shared/services/validation-service';
import {EcosystemProblem} from '../ecosystem/models/ecosystem-problem.model';

export class EcosystemOffer extends ExtendedActiveRecord {
  protected provider = EcosystemOfferService;

  public id: number;
  public ecosystem_id: number;
  public status: number;
  public price: number;
  public quantity: number;
  public delivery_date: string;
  public date_precision: number;
  public description: string;
  public created_at: string;
  public updated_at: string;
  public need: Need;
  public ecosystem: Ecosystem;
  public creator: string;
  public is_new: number;
  public modification?: string;
  public sale_name: string;
  public publication_date: string;
  public confirmation_date: string;
  public ecosystem_file_junction: EcosystemFileJunction[];
  public sales_price: number;
  public transaction_price: number;
  public transaction_date: string;
  public transaction_quantity: number;
  public transaction_description: number;

  public origin: string;
  public delivery_format: string;
  public default_quantity: number;
  public weight: number;
  public problem: EcosystemProblem;

  // get statusText() {
  //   return ECOSYSTEM_OFFER_STATUS[this.status > 0 ? this.status : 1]
  //     .replace(/_/g, ' ')
  //     .toLowerCase();
  // }

  get statusText() {
    let status: string = ECOSYSTEM_OFFER_STATUS[this.status > 0 ? this.status : 1]
    .replace(/_/g, ' ')
    return status[0].toUpperCase() + status.substr(1).toLowerCase();
  }

  get statusView() {
    let status = ECOSYSTEM_OFFER_STATUS[this.status > 0 ? this.status : 1];
    status = (<any>{
      DRAFT: 'STAND BY',
      WAITING_FOR_VALIDATION: 'OFFER SENT',
      ASK_FOR_MODIFICATION: 'ASK FOR COUNTER PROPOSAL',
    })[status] || status;
    return status.replace(/_/g, ' ');
  }

  constructor(data?: any) {
    super(data);

    this.status = this.status || ECOSYSTEM_OFFER_STATUS.DRAFT;
    data = data || {};
    this.need = new Need(Object.assign(data.need || {}, {
      client: new Client({name: data.client_name}),
      client_contact: new Contact({email: data.client_email}),
      passport: data.passport,
    }));
    this.ecosystem = data.ecosystem;
    this.ecosystem_file_junction = data.ecosystem_file_junction
      ? data.ecosystem_file_junction.map((item: any) => new EcosystemFileJunction(item))
      : [];
    this.problem = data.problem ? new EcosystemProblem(data.problem) : null;

    // console.log('this.ecosystem_file_junction', this.ecosystem_file_junction);
  }

  protected fields() {
    return [
      'id',
      'ecosystem_id',
      'status',
      'price',
      'quantity',
      'transaction_quantity',
      'delivery_date',
      'transaction_date',
      'transaction_description',
      'transaction_price',
      'date_precision',
      'description',
      'created_at',
      'updated_at',
      'creator',
      'is_new',
      'sale_name',
      'publication_date',
      'confirmation_date',
      'origin',
      'delivery_format',
      'ecosystem_file_junction',
      'sales_price',
      'default_quantity',
      'default_unit',
      'weight',
      'problem'
    ];
  }

  beforeSave(data: any): any {
    let dataValues = super.beforeSave(data);
    if (dataValues.delivery_date) {
      try {
        dataValues.delivery_date = new DatePipe('en-US').transform(dataValues.delivery_date, 'yyyy-MM-dd');
      } catch (e) {
        console.warn(e);
      }      
    }
    if(dataValues.quantity) {
      dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
    }
    // if(typeof dataValues.quantity === 'string') {
    //   let parsedQuantity = LocaleService.tryParseToNumber(dataValues.quantity);
    //   if(typeof parsedQuantity === 'number') {
    //     dataValues.quantity = parsedQuantity;
    //   }
    // }

    return dataValues;
  }

  get canValidateManually() {
    if (!this.ecosystem) {
      if (!this.ecosystem_id) {
        return false;
      } else {
        console.trace();
        console.error('connect me up');
      }
    }
    return [
        ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION,
        ECOSYSTEM_STATUS.WAITING_FOR_QUOTE,
      ].includes(this.ecosystem.status) && [
        ECOSYSTEM_OFFER_STATUS.DRAFT,
        ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION,
        ECOSYSTEM_OFFER_STATUS.ASK_FOR_MODIFICATION,
        ECOSYSTEM_OFFER_STATUS.WAITING_FOR_VALIDATION,
        ECOSYSTEM_OFFER_STATUS.CONSIDERED,
      ].includes(this.status);
  }

  get canAskForValidation() {
    if (!this.ecosystem) {
      if (!this.ecosystem_id) {
        return false;
      } else {
        console.trace();
        console.error('connect me up');
      }
    }
    return [
      ECOSYSTEM_OFFER_STATUS.DRAFT,
      ECOSYSTEM_OFFER_STATUS.ASK_FOR_MODIFICATION,
      ECOSYSTEM_OFFER_STATUS.CONSIDERED
    ].indexOf(this.status) > -1;
  }

  private canUseAfterValidationActions() {
    if (!this.ecosystem) {
      if (!this.ecosystem_id) {
        return false;
      } else {
        console.trace();
        console.error('connect me up');
      }
    }
    return this.ecosystem.status === ECOSYSTEM_STATUS.IN_PROCESS;
  }

  get canConfirm() {
     return this.canUseAfterValidationActions()
       && (this.status === ECOSYSTEM_OFFER_STATUS.VALIDATED
       || this.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION);
    // return this.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION;
  }

  get canAskConfirmationAgain() {
    return this.canUseAfterValidationActions() && this.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION;
  }

  get wasValidated() {
    return this.status === ECOSYSTEM_OFFER_STATUS.VALIDATED ||
           this.status === ECOSYSTEM_OFFER_STATUS.CONFIRMED ||
           this.status === ECOSYSTEM_OFFER_STATUS.REFUSED ||
           this.status === ECOSYSTEM_OFFER_STATUS.CANCELLED ||
           this.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION;
  }

  setOrResetWeight(data: number, reset: boolean) {
    console.log(data);
    console.log(reset);
    if (!reset) {
      this.weight = data;
    } else {
      this.weight = null;
    }
  }

  isWeightExist() {
    return !!this.weight || this.weight === 0;
  }
}
