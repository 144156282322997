/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../ecosystem-primary-header/ecosystem-primary-header.component.ngfactory";
import * as i2 from "../ecosystem-primary-header/ecosystem-primary-header.component";
import * as i3 from "../../../../shared/services/user.service";
import * as i4 from "@angular/material";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../entities/need/services/need.service";
import * as i7 from "@angular/router";
import * as i8 from "./ecosystem-ecosystems-navigator/ecosystem-ecosystems-navigator.component.ngfactory";
import * as i9 from "./ecosystem-ecosystems-navigator/ecosystem-ecosystems-navigator.component";
import * as i10 from "../../../../entities/ecosystem/services/ecosystem.service";
import * as i11 from "../../../../entities/ecosystem/services/ecosystem-folder.service";
import * as i12 from "../../../../shared/services/navbar.service";
import * as i13 from "../../../../shared/services/filterState.service";
import * as i14 from "./ecosystem-ecosystems.component";
var styles_EcosystemEcosystemsComponent = [];
var RenderType_EcosystemEcosystemsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EcosystemEcosystemsComponent, data: {} });
export { RenderType_EcosystemEcosystemsComponent as RenderType_EcosystemEcosystemsComponent };
export function View_EcosystemEcosystemsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "article", [["class", "ecosystem-dashboard"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "main", [["class", "ecosystem-dashboard__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "ecosystem-primary-header", [], null, null, null, i1.View_EcosystemPrimaryHeaderComponent_0, i1.RenderType_EcosystemPrimaryHeaderComponent)), i0.ɵdid(3, 49152, null, 0, i2.EcosystemPrimaryHeaderComponent, [i3.UserService, i0.NgZone, i4.MdDialog, i5.TranslateService, i6.NeedService, i7.Router], { isMainPage: [0, "isMainPage"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "section", [["class", "ecosystem-dashboard__section ecosystem-dashboard__section--ecosystems"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "ecosystem-ecosystems-navigator", [], null, null, null, i8.View_EcosystemEcosystemsNavigatorComponent_0, i8.RenderType_EcosystemEcosystemsNavigatorComponent)), i0.ɵdid(6, 114688, null, 0, i9.EcosystemEcosystemsNavigatorComponent, [i10.EcosystemService, i11.EcosystemFolderService, i0.NgZone, i4.MdDialog, i5.TranslateService, i6.NeedService, i12.NavbarStateService, i4.MdDialog, i13.FilterStateService, i7.Router], null, null)], function (_ck, _v) { var currVal_0 = false; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); }, null); }
export function View_EcosystemEcosystemsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_EcosystemEcosystemsComponent_0, RenderType_EcosystemEcosystemsComponent)), i0.ɵdid(1, 49152, null, 0, i14.EcosystemEcosystemsComponent, [], null, null)], null, null); }
var EcosystemEcosystemsComponentNgFactory = i0.ɵccf("ng-component", i14.EcosystemEcosystemsComponent, View_EcosystemEcosystemsComponent_Host_0, {}, {}, []);
export { EcosystemEcosystemsComponentNgFactory as EcosystemEcosystemsComponentNgFactory };
