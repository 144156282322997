/**
 * Created by aleksandr on 20.07.17.
 */
export interface INotificationParam {
  object_id: number;
  object_type: ENotificationParamObjectType;
  object_position: number;
  name: string;
  value_string?: string;
  quantity?: number;
  unit?: number;
}

export enum ENotificationParamObjectType {
  TYPE_USER_AGENT = 1,
  TYPE_USER_SUPERVISOR = 2,
  TYPE_USER_SALE = 3,
  TYPE_USER_TSM = 4,
  TYPE_USER_DEPOSITOWNER = 5,
  TYPE_USER_ESM = 6,
  TYPE_USER_ADMIN = 7,
  TYPE_NEED = 8,
  TYPE_DEPOSIT = 9,
  TYPE_TRANSFORMATION = 10,
  TYPE_ECOSYSTEM = 11,
  TYPE_ECOSYSTEM_OFFER = 12,
  TYPE_DEPOSIT_ORDER = 13,
  TYPE_TRANSFORMATION_ORDER = 14,
  TYPE_STAKEHOLDER = 15,
  TYPE_VIRGIN_PRODUCT = 16,
  TYPE_ORDER_PASSPORT_QUANTITY = 17,
  TYPE_ORDER_PASSPORT_UNIT = 18,
  TYPE_ORDER_PASSPORT_PICKUP_DATE = 19,
  TYPE_TRANSFORMATION_SITE = 20,
  TYPE_ARRIVAL_DATE = 21,
  TYPE_UNSEEN_TRANSFORMATIONS = 22,
  TYPE_USER_LOGIN = 23,
  TYPE_ORDER_PASSPORT_QUANTITY_AND_UNIT = 24,
  TYPE_COMPANY_NAME = 25,
  TYPE_PASSPORT = 26,
  TYPE_NEED_DELETED = 27,
  TYPE_RATING_TOKEN = 28,
  TYPE_DEPOSIT_LOCATION_ID = 29,
  TYPE_DELETED_LOCATION_NAME = 30,
  TYPE_CHANGED_MAX_ESM = 31,
  TYPE_EXPIRATION_DATE_CHANGE = 32,
  TYPE_DEPOSIT_LINK_URL = 33,
  TYPE_NEW_MATCH_VALUE = 35,
  TYPE_NEW_SITE = 36,
  TYPE_DEPOSIT_MATCHES_COUNT = 37,
  TYPE_PASSPORT_MATCHES_COUNT = 38
}
