import {Injectable, Optional} from '@angular/core';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MdDialog, MdDialogRef} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/Observable';
import {IConfirmDialogData} from '../confirm-dialog/values/interface/confirm-dialog-data.interface';
import {NavigationService} from './navigation.service';
import {ERoleIds} from '../values/role-ids.enum';
import {NotificationSuccessComponent} from '../notification-dialogs/notification-success/notification-success.component';
import {DIALOG_THEME} from '../helpers/dialog-themes.const';

@Injectable({
  providedIn: 'root'
})
export class ConfirmOrderDialogService {
  public isConfirmDialogOpen = false;
  private get defaultOptions() {
    return {
      text:
        `${this.translate('Do you confirm the values entered?')}\n${this.translate('They will be sent to the ecosystem manager.')}`,
      confirmText: 'Yes, send',
      cancelText: 'No, return to values',
      cssClass: 'order-transaction-confirmation-dialog__confirmation',
      showCross: true,
      imgUrl: 'assets/png/warning_orange.png',
    };
  }

  constructor(private mdDialog: MdDialog,
              private translateService: TranslateService,
              private navigation: NavigationService) {
  }

  private translate(text: string) {
    return this.translateService.instant(text);
  }

  open(callback: Observable<any>, mdDialogRef: MdDialogRef<any>, data?: Partial<IConfirmDialogData>) {
    this.isConfirmDialogOpen = true;
    const dialog = this.mdDialog.open(
      ConfirmDialogComponent, {data: {...this.defaultOptions, ...(data || {})}}
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        callback.subscribe(
          (orderConfirmed) => {
            this.showContentAfterCloseAnimation();
            return mdDialogRef && mdDialogRef.close(orderConfirmed);
          },
          () => {
            this.isConfirmDialogOpen = false;
          });
      } else {
        this.isConfirmDialogOpen = false;
      }
    }, () => {
      this.isConfirmDialogOpen = false;
    });
    return dialog;
  }

  private showContentAfterCloseAnimation() {
    const dialogAnimationTime = 410;
    setTimeout(() => this.isConfirmDialogOpen = false, dialogAnimationTime);
  }

  public getDefaultOverrideForESM(overrideData) {
    if (this.navigation.roleId === ERoleIds.ECOSYSTEM_MANAGER) {
      const translate = (translation) => this.translateService.instant(translation);
      const text =
        `${translate('Do you confirm the values entered?')}\n${translate('You will not be able to change them afterwards.')}`;
      overrideData.text = text;
    }
    return overrideData;
  }

  public afterConfirmationShowSuccessMessage(message = 'Thanks for confirmation') {
    this.mdDialog.open(NotificationSuccessComponent, {...DIALOG_THEME.PROBLEM_REPORT, data: {message}});
  }
}
