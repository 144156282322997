import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '../../model/client.class';


@Component({
  moduleId: module.id,
  selector: 'client-item',
  templateUrl: 'client-item.component.html',
})
export class ClientItemComponent implements OnInit, OnDestroy {
  @Input()
  client: Client;
  @Output() onOpen?: EventEmitter<any> = new EventEmitter();
  @Output() onDelete?: EventEmitter<any> = new EventEmitter();
  @Output() onDuplicate?: EventEmitter<any> = new EventEmitter();
  createdDate: string;
  updatedDate: string;
  deliveryDate: string;

  constructor(private router: Router) {
  }

  ngOnInit() {
    // if(this.client){
    //   let cDate = new Date(this.client.created_at);
    //   let uDate =  new Date(this.client.updated_at);
    //   this.createdDate=cDate.toDateString();
    //   this.updatedDate=uDate.toDateString();
    //
    //   if(this.client.delivery_date){
    //     let dDate = new Date(this.client.delivery_date);
    //     this.deliveryDate=dDate.toDateString();
    //   }
    //
    // }
    //
    // console.log("ITEM INIT WITH:", this.client);
    //

  }

  ngOnDestroy() {

  }

  openClient(record: Client) {
    this.onOpen.emit(record);
  }

  duplicateClient(record: Client) {
    this.onDuplicate && this.onDuplicate.emit(record);
  }

  deleteClient(record: Client) {
    this.onDelete && this.onDelete.emit(record);
  }

  createNew() {
    this.router.navigate(['sales/clients/new']);
  }


  viewClient($event: any) {
    if ($event.target.id && $event.target.id.indexOf('clientItemMenu#') !== -1) {
      console.log('MENU');
    } else {
      this.router.navigate(['sales/clients', this.client.id]);
    }
  }
}
