<div class="confirm-with-header-dialog">
  <h2 md-dialog-title class="confirm-with-header-dialog__header background-primary-color">{{title}}</h2>
  <ng-content></ng-content>
  <ul class="confirm-with-header-dialog__button-group">
    <li class="confirm-with-header-dialog__button confirm-with-header-dialog__button--confirm">
      <button md-button md-raised-button
              [disabled]="disabled"
              (click)="confirm()">{{ confirmButtonText || ('Confirm' | translate) }}</button>
    </li>
    <li class="confirm-with-header-dialog__button ">
      <button md-button md-raised-button
              (click)="cancel()">{{ cancelButtonText || ('Cancel' | translate) }}</button>
    </li>
  </ul>
</div>

