/**
 * Remove this
 * @deprecated
 */
export class LocaleService {
  public static locale: string = 'en';

  private static floatRegexByLocale = [
    {
      locale: 'en',
      regex: /^\d+([.]?\d+)?$/g,
    },
    {
      locale: 'fr',
      regex: /^\d+([,|.]?\d+)?$/g,
    },
    {
      locale: 'es',
      regex: /^\d+([,|.]?\d+)?$/g,
    }
  ];

  /**
   * Try parse string in locale to float
   * @param value
   * @returns {number} or given value if parse was not successful
   */
  public static tryParseToNumber(value: any) {
    if(typeof value === 'string' && this.locale === 'fr') {
      value = value.replace(',', '.');
    }

    let valueAsNumber = Number(value);
    return isNaN(valueAsNumber) ? valueAsNumber : value;
  }

  public static matchFloat(value: any) {
    let localeRegex = this.floatRegexByLocale.find((item) => item.locale === this.locale);
    return String(value).match(!!localeRegex ? localeRegex.regex : this.floatRegexByLocale[0].regex);
  }
}
