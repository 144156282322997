import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class PaginationUnmarshallingService {

  public unmarshall(response: Response): { totalCount: number, perPage: number, pageCount: number, currentPage: number } {
    const headers = response.headers;
    return {
      currentPage: Number(headers.get('X-Pagination-Current-Page')) || 1,
      pageCount: Number(headers.get('X-Pagination-Page-Count')) || 1,
      perPage: Number(headers.get('X-Pagination-Per-Page')) || response.json().length,
      totalCount: Number(headers.get('X-Pagination-Total-Count')) || response.json().length
    };
  }
}
