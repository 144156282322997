/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../app/components/base/checkbox/base-checkbox.component.ngfactory";
import * as i3 from "../../../app/components/base/checkbox/base-checkbox.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/pipes/capitalize.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./filter-group.component";
import * as i8 from "../../../shared/services/navigation.service";
var styles_FilterGroupComponent = [i0.styles];
var RenderType_FilterGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterGroupComponent, data: {} });
export { RenderType_FilterGroupComponent as RenderType_FilterGroupComponent };
function View_FilterGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "checkbox-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-base-checkbox", [], null, [[null, "isCheckedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isCheckedChange" === en)) {
        var pd_0 = (_co.toggleState(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BaseCheckboxComponent_0, i2.RenderType_BaseCheckboxComponent)), i1.ɵdid(2, 49152, null, 0, i3.BaseCheckboxComponent, [], { isChecked: [0, "isChecked"], label: [1, "label"] }, { isCheckedChange: "isCheckedChange" }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isStatusFilterChecked(_v.context.$implicit.value); var currVal_1 = i1.ɵunv(_v, 2, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_v.context.$implicit.view)))); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_FilterGroupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.CapitalizePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "filter-group"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterGroupComponent_1)), i1.ɵdid(4, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "filter-group"; var currVal_1 = _co.getClassColor(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.filterStatuses; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_FilterGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-group", [], null, null, null, View_FilterGroupComponent_0, RenderType_FilterGroupComponent)), i1.ɵdid(1, 573440, null, 0, i7.FilterGroupComponent, [i8.NavigationService], null, null)], null, null); }
var FilterGroupComponentNgFactory = i1.ɵccf("app-filter-group", i7.FilterGroupComponent, View_FilterGroupComponent_Host_0, { selectedStatuses: "selectedStatuses", filterStatuses: "filterStatuses" }, { filterChanged: "filterChanged" }, []);
export { FilterGroupComponentNgFactory as FilterGroupComponentNgFactory };
