import * as tslib_1 from "tslib";
import { CrudService } from './crud.service';
import { Observable } from 'rxjs/Observable';
import { Searchable } from '../models/searchable.class';
import { User } from '../models/user.class';
var UserService = /** @class */ (function (_super) {
    tslib_1.__extends(UserService, _super);
    function UserService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'users';
        _this.namespaceSingular = 'user';
        _this.ModelClass = User;
        return _this;
    }
    UserService.prototype.getWithPaginationData = function (query) {
        return this
            .sendGet(this.getEndpoint(this.namespace), { search: query })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.create = function (data) {
        var _this = this;
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/new'), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.changePassword = function (userId, password, oldPassword) {
        return this
            .sendPut(this.getEndpoint(this.singularEndpoint + '/' + userId + '/password'), { password: password, old_password: oldPassword })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.createCompany = function (data) {
        var _this = this;
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/company'), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.updateCompany = function (companyId, data) {
        return this
            .sendPut(this.getEndpoint('company/' + companyId), data)
            .catch(this.onError.bind(this));
    };
    UserService.prototype.loginAs = function (userId) {
        console.log("Fichier de login 2");
        console.log(this.getEndpoint(this.singularEndpoint + '/' + userId + '/login_as'));
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/' + userId + '/login_as'))
            .catch(this.onError.bind(this));
    };
    UserService.prototype.resendActivation = function (userId) {
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/' + userId + '/resend'))
            .catch(this.onError.bind(this));
    };
    UserService.prototype.deleteNoTransfer = function (userId) {
        return this.sendDelete(this.getEndpoint(this.singularEndpoint + '/' + userId)).map(function (res) { return res.ok; });
    };
    UserService.prototype.deleteAndTransferUser = function (userId, userIdForTransferring) {
        var _this = this;
        return this.transfer(userId, userIdForTransferring)
            .flatMap(function (successfull) {
            return successfull
                ? _this.sendDelete(_this.getEndpoint(_this.singularEndpoint + '/' + userId)).map(function (res) { return res.ok; })
                : Observable.of(false);
        })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.assignRole = function (userId, data) {
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/' + userId + '/assign_role'), data)
            .catch(this.onError.bind(this));
    };
    UserService.prototype.removeRole = function (userId, data) {
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/' + userId + '/revoke_role'), data)
            .catch(this.onError.bind(this));
    };
    UserService.prototype.updateRoleParent = function (userId, role) {
        var _this = this;
        return this.removeRole(userId, role)
            .flatMap(function (data) { return _this.assignRole(userId, role); })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.getListSupervisors = function (query) {
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '/list_supervisors'), { search: query })
            .map(function (res) { return res.json().map(function (item) { return new Searchable(item); }); })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.getCompanyList = function (query) {
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '/company_names'), { search: query })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.getListDepositOwners = function (query) {
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '/list_deposit_owners'), { search: query })
            .map(function (res) { return res.json().map(function (item) { return new Searchable(item); }); })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.getListAgents = function (query) {
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '/agents'), { search: query })
            .map(function (res) { return res.json().map(function (item) { return new User(item); }); })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.getUsersForTransfer = function (id) {
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '/' + id + '/transferable'))
            .map(function (res) { return res.json().map(function (item) { return new User(item); }); })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.getAvatarUploadUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/avatar');
    };
    UserService.prototype.deleteAvatar = function (id) {
        return this
            .sendDelete(this.getEndpoint('user-avatar/' + id))
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.getRoleCounters = function (query) {
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '/counters'), { search: query })
            .map(function (response) { return response.json(); }).catch(this.onError.bind(this));
    };
    UserService.prototype.transfer = function (transferFromId, transferToId) {
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/transfer'), {
            transfer_from: transferFromId,
            transfer_to: transferToId,
        })
            .map(function (res) {
            if (res.status === 404) {
                console.error('TRANSFER METHOD IS NOT AVAILABLE!');
                return false;
            }
            return res.ok;
        })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.deleteWholeCompany = function (id, command) {
        return this
            .sendPost(this.getEndpoint('company/remove/' + id), {
            command: command
        })
            .catch(this.onError.bind(this));
    };
    UserService.prototype.getCompanyFileUploadUrl = function (id) {
        return this.getEndpoint('company-file/create?company_id=' + id);
    };
    UserService.prototype.sendRequest = function (params) {
        return this.sendPost(this.getEndpoint(this.namespaceSingular + '/request'), params)
            .catch(this.onError.bind(this));
    };
    return UserService;
}(CrudService));
export { UserService };
