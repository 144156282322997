import {FormControl} from '@angular/forms';
import {pairwise, startWith} from 'rxjs/operators';

export function proceedIfNonNegativeInt(event: KeyboardEvent) {
    const specialKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    const clipboardSpecialKeyCodes = [86, 67, 88];
    if (specialKeys.some(key => key === event.key) || !!event.key.match(/[0-9]/)
    || (clipboardSpecialKeyCodes.some(keyCode => keyCode === event.keyCode) && (event.ctrlKey || event.metaKey))) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  export function  pasteOnlyIfNonNegativeInt(event: ClipboardEvent) {
    if (event.clipboardData.getData('text').match(/^[0-9]*$/)) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  interface IOnlyPositiveNumbersOptions {
    onlyInt?: boolean;
  }

  export function allowOnlyPositiveNumbers(form: FormControl, options?: IOnlyPositiveNumbersOptions) {
    const onlyInt = (options && options.onlyInt) || false;
    return form.valueChanges.pipe(startWith(form.value), pairwise())
        .subscribe(([before, value]: number[]) => {
          if (checkIfInvalid(value) && !checkIfInvalid(before) ) {
            form.setValue(before);
          } else if (onlyInt && !Number.isInteger(Number(value))) {
            form.setValue(Math.round(Number(value)));
          }
        });
  }

  function checkIfInvalid(val) {
    const numericVal = Number(val);
    const isInvalidVal = val && !val.toString().match(/^\d*[,.]?\d*$/);
    return isInvalidVal ||  numericVal < 0;
  }
