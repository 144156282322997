<md-dialog-content>
  <dialog-close-button (click)="mdDialogRef.close()"></dialog-close-button>

  <div class="deposit-import" [formGroup]="depositImportForm">
    <div class="deposit-import__row deposit-import__row--heading">
      <div class="deposit-import__heading flex justify-between align-start">
        <div class="deposit-import__heading--label">
          {{ 'import deposits from a spreadsheet' | translate }}
        </div>
      </div>
    </div>

    <div class="deposit-import__separator flex">
      <div class="deposit-import__separator--number primary-color">
        1
      </div>
      <div class="deposit-import__separator--content background-primary-color">
        <div>
          {{ 'import a file' | translate | uppercase}}
        </div>
      </div>
    </div>

    <div class="deposit-import__row deposit-import__header--label">
      {{ 'Select the file to import' | translate}} :
    </div>

    <div class="deposit-import__row">
      <div class="deposit-import__import-description flex" tabindex="0">
        <documents-uploader
        class="passport__uploader"
        [files]="isReport ? uploadedReportFile : uploadedDepositFile"
        [uploadUrl]="isReport ? reportImport.getFileUploadUrl() : depositImport.getFileUploadUrl()"
        [service]="isReport ? reportFileService : depositFileService"
        [onUploadAll]="onDocumentSave$"
        [uploadUrlSet]="onUpdateUploadUrl$"
        (onAllItemsUploaded)="documentUploaded()"
        [autoupload]="false"
        [acceptedFormats]="['xls', 'xlsx', 'ods']"
        [maxAllowedItems]="1"
        ></documents-uploader>

        <div class="deposit-import__attention primary-color border-primary-color">!</div>
        <span class="deposit-import__input--description">
          Format .xls, xlsx, .ods
        </span>
      </div>
    </div>

    <div class="deposit-import__row deposit-import__row--top">
      <div class="inline-flex align-center deposit-import__input-wrapper">
        <div class="deposit-import__input--label primary-color">{{ 'Name of the tab to import' | translate }}</div>
        <md-input-container class="deposit-import__input--text">
          <input
          autocomplete="off"
          formControlName="tab_name"
          mdInput>
        </md-input-container>
      </div>
    </div>

    <div class="deposit-import__row">
      <div class="inline-flex align-center deposit-import__input-wrapper">
        <div class="deposit-import__input--label primary-color">{{ 'No. of the first line to be imported' | translate }}</div>
        <md-input-container class="deposit-import__input--number">
          <input
          autocomplete="off"
          formControlName="first_row"
          mdInput
          min="0"
          type="number"
          (keydown)="allowOnlyGreaterThanZeroNumbers($event)">
        </md-input-container>
      </div>
    </div>

    <div class="deposit-import__row">
      <div class="inline-flex align-center deposit-import__input-wrapper">
        <div class="deposit-import__input--label primary-color">{{ 'No. of the last line to be imported' | translate }}</div>
        <md-input-container class="deposit-import__input--number">
          <input
          autocomplete="off"
          formControlName="last_row"
          mdInput
          min="0"
          type="number"
          (keydown)="allowOnlyGreaterThanZeroNumbers($event)">
        </md-input-container>
      </div>
    </div>

    <div class="deposit-import__separator deposit-import__separator--top flex">
      <div class="deposit-import__separator--number primary-color">
        2
      </div>
      <div class="deposit-import__separator--content background-primary-color">
        <div class="deposit-import__separator--description">
          {{ 'select columns' | translate | uppercase}}
        </div>
        <span class="deposit-import__separator--description-info">
          {{ 'Check the points marked with a' | translate }}
        </span>
        <span class="deposit-import__attention deposit-import__attention--white">!</span>
        <span class="deposit-import__separator--description-info">
          {{ 'before importing the file' | translate }}.
        </span>
      </div>
    </div>

    <div class="deposit-import__row deposit-import__header--label flex-col-start">
      {{ 'SPECIFY_XLS_COLUMNS' | translate}} :
      <div class="deposit-import__header--required">{{ '* required columns' | translate}}</div>
    </div>

    <div class="deposit-import__row--odd-stripe">
      <deposit-import-column-row
        label="{{ 'Passport ID' | translate }}"
        controlName="passport_id_field"
        [required]="true"
        columnName="A"
        description="{{'The passport and its ID must previously exist in myUpcyclea' | translate}}"
        (onKeyDown)="allowOnlyLetters($event)"
      >
      </deposit-import-column-row>

      <deposit-import-column-row *ngIf="!isReport"
        label="{{ 'Site name' | translate }}"
        controlName="site_name_field"
        [required]="true"
        columnName="B"
        (onKeyDown)="allowOnlyLetters($event)"
      >
        <div class="deposit-import__input--description deposit-import__input--description-fixed-width inline-flex">
          {{'The site name must previously exist in myUpcyclea' | translate}}</div>
        <span class="deposit-import__attention inline-flex primary-color border-primary-color">!</span>
        <div class="deposit-import__input--description inline-flex">
          {{'The name of the site must be exact' | translate}}
        </div>
      </deposit-import-column-row>

      <deposit-import-column-row
        *ngIf="!isReport"
        label="{{ 'Tag(s)' | translate }}"
        controlName="tags_field"
        columnName="C"
        (onKeyDown)="allowOnlyLetters($event)"
      >
        <div class="deposit-import__input--description deposit-import__input--description-fixed-width inline-flex">
          {{'Tags must belong to the site you selected.'| translate}}</div>
        <span class="deposit-import__attention inline-flex primary-color border-primary-color">!</span>
        <div class="deposit-import__input--description inline-flex">
          {{'Tags must be separated by commas.' | translate}}
        </div>
      </deposit-import-column-row>

      <deposit-import-column-row
        label="{{ 'Quantity' | translate }}"
        controlName="quantity_field"
        [required]="true"
        [columnName]="isReport ? 'B' : 'D'"
        description="{{'The field must be a number' | translate}}"
        (onKeyDown)="allowOnlyLetters($event)"
      >
      </deposit-import-column-row>

      <deposit-import-column-row
        label="{{ 'Unit' | translate }}"
        controlName="unit_field"
        [required]="true"
        [columnName]="isReport ? 'C' : 'E'"
        (onKeyDown)="allowOnlyLetters($event)"
      >
        <div class="deposit-import__input--description deposit-import__input--description-fixed-width inline-flex">
          {{'Unit sizes : piece / kg / ton / L / m / m2 / m3' | translate}}
        </div>
        <span class="deposit-import__attention inline-flex primary-color border-primary-color">!</span>
        <div class="deposit-import__input--description inline-flex">
          {{'The unit must be identical to that of the associated passport' | translate}}
        </div>
      </deposit-import-column-row>

      <deposit-import-column-row *ngIf="!isReport"
        label="{{ 'Availability date' | translate }}"
        controlName="availability_date_field"
        [required]="checkRequiredDates()"
        [columnName]="'F'"
        (onKeyDown)="allowOnlyLetters($event)"
       >
        <div class="deposit-import__input--description inline-flex">
          {{'Format DD/MM/YYYYY' | translate}}
        </div>

        <div class="inline-flex align-center deposit-import__input-wrapper deposit-import__input-wrapper--margin">
          <div class="deposit-import__input--label primary-color">{{ 'Reference date' | translate }}
            <span class="deposit-import__input--star" *ngIf="checkRequiredDates()">*</span>
          </div>
          <md-input-container class="deposit-import__input--date calendar--icon inline-flex">
            <input
              autocomplete="off"
              ng2-datetime-picker
              date-only="true"
              [required]="checkRequiredDates()"
              date-format="DD-MM-YYYY"
              parse-format="YYYY-MM-DD"
              formControlName="reference_date"
              mdInput
              (keydown)="datePickerKeydown($event)"
              class="depositlist__info-disponable-title"
            >
          </md-input-container>
        </div>

        <label class="form-group__label deposit-import__input-wrapper--margin" mdTooltip="{{ 'REFERENCE_DATE_TOOLTIP' | translate }}">
          <i class="form-group__label--question">?</i>
        </label>
      </deposit-import-column-row>

      <deposit-import-column-row
        *ngFor="let col of columnsWithSameStyle, index as index"
        label="{{ col.name | translate }}"
        [controlName]="col.id"
        [columnName]="getCharOfColumnsWithSameStyle(index)"
        description="{{col.description | translate}}"
        (onKeyDown)="allowOnlyLetters($event)"
      ></deposit-import-column-row>
    </div>

    <div class="deposit-import__buttons">
      <button *ngIf="!isReport" class="deposit-import__buttons--button" md-button md-raised-button (click)="updateImportAndShowWarning()" >{{ 'import deposits' | translate | uppercase}}</button>
      <button *ngIf="isReport" class="deposit-import__buttons--button" md-button md-raised-button (click)="checkFile()" >{{ 'check the file' | translate | uppercase}}</button>
    </div>
  </div>
</md-dialog-content>
