import {IImportPreferences} from '../../../../../../import-spreadsheet/values/interfaces/import-preferences.interface';
import {TABLE_TYPES} from '../../../../../../import-spreadsheet/values/import-tables.const';

export const COLUMNS_MAPPING = {
  DEPOSIT_NAME : 0,
  PASSPORT_ID: 1,
  SITE_NAME: 2,
  TAG: 3,
  QUANTITY: 4,
  UNIT: 5,
  AVAILABLITY_DATE: 6,
  EXPIRATION_DATE: 7,
  SOURCE: 8,
  DEMOUNTABILITY: 9,
  STATE_OF_USE: 10,
  STATE_DESCRIPTION: 11,
  RESALE_PRICE: 12,
  LINK: 13,
  ADDITIONAL_PRODUCT: 14,
  LENGTH: 15,
  WIDTH: 16,
  HEIGHT: 17,
  PRODUCT_APPEARANCE: 18,
  CONVERSION_FACTOR: 19,
  COMMENTS: 20,
  PHOTOS: 21,
  DOCUMENTS: 22,
  POTENTIAL_BECOMING: 23,
  REFERENCE_DATE: 30,
  TAB_IMPORT: 24,
  FIRST_ROW: 25,
  LAST_ROW: 26,
  REUSED_FIELD: 27,
  RESALE_URL: 28,
  PRODUCT_CHARACTERISTICS_FIELD: 29,
};

export const TABLE_SYNONYM_AND_COLUMNS: IImportPreferences = {
  columnTable: TABLE_TYPES.COLUMNS_MAPPING,
  columnMapping: COLUMNS_MAPPING,
  synonymTable: [TABLE_TYPES.STATE_OF_USE,
    TABLE_TYPES.LEVEL_OF_DEMONTABILITY,
    TABLE_TYPES.DEPOSIT_ORIGIN,
    TABLE_TYPES.POTENTIAL_BECOMING]
};

