import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {MdDialog} from '@angular/material';
import {Deposit} from '../../../models/deposit.class';
import {DepositPhotoService} from '../../../services/deposit-photo.service';
import {DepositPhoto} from '../../../models/deposit-photo.class';
import {DepositService} from '../../../services/deposit.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidationService} from '../../../../../shared/services/validation-service';
import {IParentEvent} from '../../../../../shared/interfaces/parent-event.interface';
import {DepositControlStates} from '../../../models/deposit-control-states.class';
import {PERIOD_OF_USE_TYPES} from '../../../../passport/values/period-of-use.values';
import {LogNotificationService} from '../../../../../shared/services/log-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs/Subscription';
import * as moment from 'moment';
import {DEMOUNTABILITY_STATES} from '../../../values/demountability-states.const';
import {setTextareaHeightToFitContent} from '../../../../../shared/helpers/set-textarea-height-to-fit-content';
import {DEPOSIT_REUSE_SOURCE_SELECT_ENTRIES, EDepositReuseSource} from '../../../values/deposit-reuse-source.const';
import {IPeriodOfUseType} from '../../../../passport/models/period-of-use.interface';
import {PASSPORT_UNIT_WITHOUT_CONVERSION} from '../../../../passport/values/passport-units.const';
import {DepositDocumentFile} from '../../../models/deposit-document-file.class';
import {DepositDocumentFileService} from '../../../services/deposit-document-file.service';


@Component({
  moduleId: module.id,
  selector: 'other-step',
  templateUrl: 'other-step.component.html',
})
export class OtherStepComponent implements OnInit, OnDestroy, AfterViewInit {
  DEPOSIT_SOURCE_LIST = DEPOSIT_REUSE_SOURCE_SELECT_ENTRIES;
  URL_MAX_LENGTH = ValidationService.URL_MAX_LENGTH;

  @Input() deposit: Deposit;
  @Input() controlStates: DepositControlStates;
  @Input() listener: EventEmitter<IParentEvent>;
  @ViewChild('otherComment', { read: ElementRef }) commentsTextarea: ElementRef;
  @ViewChild('otherDescription', { read: ElementRef }) descriptionTextarea: ElementRef;
  @ViewChild('productCharacteristics', { read: ElementRef }) productCharacteristicsTextarea: ElementRef;
  @ViewChild('color', { read: ElementRef }) colorTextarea: ElementRef;

  public hasBaseDropZoneOver = false;
  depositPhotoUploader: FileUploader = new FileUploader({allowedFileType: ['image', 'pdf']});
  uploadedFiles: DepositPhoto[] = [];
  uploadedDocs: DepositDocumentFile[] = [];
  unitInfoForm: FormGroup;
  demountabilityStates = DEMOUNTABILITY_STATES;

  subscriptions: Subscription[] = [];

  constructor(public notification: LogNotificationService,
              public dialog: MdDialog,
              public _depositService: DepositService,
              public _depositPhotoService: DepositPhotoService,
              public fileService: DepositDocumentFileService,
              public fb: FormBuilder,
              private _translate: TranslateService) {
  }

  private get depositSourceView() {
    return this.deposit.reused ? this.deposit.deposit_source : EDepositReuseSource.NO_SOURCE;
  }

  ngOnInit() {
    if (this.deposit.deposit_source === EDepositReuseSource.REUSED) {
      this.deposit.deposit_source = EDepositReuseSource.UNKNOWN;
    }
    this.initForm();
    this.preloadUploadedItems();
    if (!this.deposit.condition) {
      this.deposit.condition = 1;
    }
    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if (event.key === 'changeDeposit') {
          this.deposit = event.data;
        }
      });
    }
    this.depositPhotoUploader.onWhenAddingFileFailed  = (_, filter) => {
      if (filter && filter.name === 'fileType') {
        this.notification.error('This format is not supported', null, true);
    } };
  }

  private initForm() {
    this.unitInfoForm = this.fb.group({
      quantity: [this.deposit.quantity, [Validators.required, Validators.min(0), ValidationService.validateNumberOnBlur()]],
      unit: [{value: '', disabled: true}, (c: FormControl) => console.log(c.value)],
      availability_date: [this.formatToDateView(this.deposit.availability_date), Validators.required],
      availability_expiration: [this.formatToDateView(this.deposit.availability_expiration), (c: FormControl) => {}],
      use_period_of_use: [false, (c: FormControl) => this.usePeriodOfUse(c)],
      reuse_source: [this.depositSourceView],
      potential_reuse: [this.deposit.potential_reuse],
      potential_reuse_source: [this.deposit.deposit_potential_source],
      price: [this.deposit.price, ValidationService.validateNumberOnBlur()],
      url_link: [this.deposit.url_link, [ValidationService.validateURLLength(), ValidationService.validateURL()]],
      conversion: [this.deposit.conversion, ValidationService.validateQuantity],
      product_comment: [this.deposit.product_comment, ValidationService.validateURLLength()],
      length: [this.deposit.length, ValidationService.validateQuantity],
      width: [this.deposit.width, ValidationService.validateQuantity],
      height: [this.deposit.height, ValidationService.validateQuantity],
      color: [this.deposit.color, ValidationService.validateURLLength()],
    });
    const updateModel = this.updateModel.bind(this);
    updateModel('availability_date', (val) => this.onAvailabilityDateChanged(val));
    updateModel('availability_expiration', (val) => this.onAvailabilityExpireChanged(val));
    updateModel('potential_reuse', (val) => {
      this.deposit.potential_reuse = +val;
    });
    updateModel('reuse_source', (val) => {
      if (val < 0) {
        this.deposit.reused = 0;
      } else {
        this.deposit.reused = 1;
        this.deposit.deposit_source = +val;
      }
    });
    updateModel('potential_reuse_source', (val) => this.deposit.deposit_potential_source = +val);
    ['url_link', 'product_comment', 'color', ].forEach(name => this.updateModelDefault(name));
    ['conversion', 'length', 'width', 'height', 'price'].forEach(name => this.updateNumberDefault(name));
    this.deposit.setValidationStrategy(this, 3, () => this.unitInfoForm.valid);
    // this.subscriptions.push(this.deposit.beforeSave$.subscribe(() => touchEachFormControl(this.unitInfoForm)));
  }

  private updateModel(formName: string, change: (value) => void) {
      this.subscriptions.push(this.unitInfoForm.get(formName).valueChanges
        .subscribe((value) => change(value)));
  }

  private updateModelDefault(formName: string) {
    this.updateModel(formName, (value) => {
      this.deposit[formName] = value;
    });
  }

  private updateNumberDefault(formName: string) {
    this.updateModel(formName, (value) => {
      if (typeof value === 'string') {
        value = Number(value.replace(',', '.'));
      }
      this.deposit[formName] = value;
    });
  }

  ngAfterViewInit() {
    const textAreas = [];
    const pushIfNotEmpty =  (modelField, textArea) => {
      if (modelField) {
         textAreas.push(textArea);
      }
    };
    pushIfNotEmpty(this.deposit.comments, this.commentsTextarea);
    pushIfNotEmpty(this.deposit.description, this.descriptionTextarea);
    pushIfNotEmpty(this.deposit.product_comment, this.productCharacteristicsTextarea);
    pushIfNotEmpty(this.deposit.color, this.colorTextarea);
    if (textAreas.length) {
      setTimeout(() => setTextareaHeightToFitContent(textAreas));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  usePeriodOfUse(control: FormControl): void {
    if (control.value) {
      const newDate = this.getAvailabilityByPeriodOfUse();
      if (newDate) {
        if (!!this.deposit.availability_expiration
          && !this.checkAvailabilityInterval(
            this.formatToDateView(newDate)
            , this.formatToDateView(this.deposit.availability_expiration))
            ) {
              control.setValue(false);
              return;
            }
            this.deposit.availability_date = newDate;
            this.unitInfoForm.get('availability_date').setValue(this.formatToDateView(newDate), { emitEvent: false });
          } else {
            this._translate.get('Period of use hasnt been filled').subscribe((text) => {
              this.notification.error(text, null, true);
            });
            control.setValue(false);
          }
        }
      }

  private formatToDateView(date: string) {
        return !!date ? moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '';
  }

  private checkAvailabilityInterval(availabilityStr: string,  expirationStr: string) {
          const availability = moment(availabilityStr, 'DD-MM-YYYY');
          const expiration = moment(expirationStr, 'DD-MM-YYYY');
          if (expiration.diff(availability, 'days') < 0) {
            this._translate.get('Expiration date cannot be earlier than availability date')
              .subscribe((text) => {
                this.notification.error(text, null, true);
              });
            return false;
          }
          return true;
  }

  getAvailabilityByPeriodOfUse(): string {
    const periodQuantity = this.deposit.passport.period;
    const periodType = this.getPeriodOfUseType();

    if (periodQuantity && periodType) {
      const duration = moment.duration(periodQuantity, periodType.momentUnit);
      return moment().add(duration).format('YYYY-MM-DD');
    } else {
      return null;
    }
  }

  private getPeriodOfUseType(): IPeriodOfUseType {
    return PERIOD_OF_USE_TYPES.find((type) => type.value === this.deposit.passport.period_type);
  }

  onAvailabilityExpireChanged(availabilityDate: string) {
    if (this.deposit.availability_date) {
      if (!this.checkAvailabilityInterval(this.formatToDateView(this.deposit.availability_date), availabilityDate)) {
        this.unitInfoForm.get('availability_expiration').setValue(
          this.formatToDateView(this.deposit.availability_expiration), { emitEvent: false });
        return;
      }
    }

    this.deposit.availability_expiration = this.formatToDateSource(availabilityDate);
  }

  private formatToDateSource(date: string) {
    const parsedDate = moment(date, 'DD-MM-YYYY');
    return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : null;
  }

  onAvailabilityDateChanged(availabilityDate: string) {
    if (this.deposit.availability_expiration) {
      if (!this.checkAvailabilityInterval(availabilityDate, this.formatToDateView(this.deposit.availability_expiration))) {
        this.unitInfoForm.get('availability_date').setValue(this.formatToDateView(this.deposit.availability_date), { emitEvent: false });
        return;
      }
    }
    this.deposit.availability_date = this.formatToDateSource(availabilityDate);
    if (availabilityDate !== this.getAvailabilityByPeriodOfUse()) {
      this.unitInfoForm.get('use_period_of_use').setValue(false);
    }
  }

  getUsePeriodTooltipMessage() {
    const args = this.getUsePeriodTooltipArgs();
    const message = 'Period of use : [Period of use] + [unit]';
    return args ? this._translate.instant(message, args) : '';
  }

  private getUsePeriodTooltipArgs() {
    const type = this.getPeriodOfUseType();
    return this.deposit.passport.period && type ?
      {X: this.deposit.passport.period, Y: this._translate.instant(type.view.toLowerCase())} : '';
  }

  isConversionShown() {
    return this.deposit.passport.unit && !PASSPORT_UNIT_WITHOUT_CONVERSION.includes(this.deposit.passport.unit);
  }


  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  openDropZone() {
    if (!this.deposit.status || this.deposit.status <= 0) {
      this.deposit.status = 1;
    }
    this.deposit.save().subscribe((res) => {
      console.log('RES', res);
      this.depositPhotoUploader.setOptions({
        url: this._depositService.getFileUploadUrl(res.id),
        itemAlias: 'file'
      });
      this.depositPhotoUploader.onCompleteItem = (item, response) => {
        console.log('onCompleteItem');
        this.uploadedFiles.push(new DepositPhoto(response));
        // this.onSave && this.onSave.emit(this.passport);
      };
    });
  }

  private preloadUploadedItems() {
    this._depositPhotoService
      .get<DepositPhoto>({deposit_id: this.deposit.id})
      .subscribe(
        result => {
          console.log('fetch uploaded files', result);
          for (const item of result) {
            this.uploadedFiles.push(new DepositPhoto(item));
          }
        }
      );
    this.fileService.get({deposit_id: this.deposit.id}).subscribe(
      res => res.forEach(item => this.uploadedDocs.push(new DepositDocumentFile(item)))
    );
  }
}
