import * as i0 from "@angular/core";
var ReportSiteTagPresenterService = /** @class */ (function () {
    function ReportSiteTagPresenterService() {
        this.tagsIds = [];
        this.sites = [];
        this.siteTags = {};
    }
    ReportSiteTagPresenterService.prototype.updateTagsById = function (tagsIds) {
        this.tagsIds = tagsIds;
        this.updateSiteTags();
    };
    ReportSiteTagPresenterService.prototype.updateSiteTags = function () {
        var _this = this;
        var tagsIdSet = new Set(this.tagsIds);
        this.sites.forEach(function (site) { return _this.siteTags[site.id] = site.tags.filter(function (tag) { return tagsIdSet.has(tag.id); }); });
    };
    ReportSiteTagPresenterService.prototype.updateSiteList = function (sites) {
        this.sites = sites;
        this.updateSiteTags();
    };
    ReportSiteTagPresenterService.prototype.getTags = function (id) {
        return this.siteTags[id];
    };
    ReportSiteTagPresenterService.ngInjectableDef = i0.defineInjectable({ factory: function ReportSiteTagPresenterService_Factory() { return new ReportSiteTagPresenterService(); }, token: ReportSiteTagPresenterService, providedIn: "root" });
    return ReportSiteTagPresenterService;
}());
export { ReportSiteTagPresenterService };
