<div  class="wrapper" *ngIf="data">

    <div class="residual_value_descriptions_pdf" *ngIf="isPDFReport">
        <div class="scenario_pdf">
            <img src="assets/png/Profit_Scenario.png" alt="">
            <div class="scenario-description-profit_pdf">
                <p class="scenario-description-title_pdf">
                    {{'PROFIT_ORIENTED_SCENARIO' | translate}}
                </p>
                <p class="scenario-description-text_pdf">
                    {{'PROFIT_ORIENTED_SCENARIO_DESCRIPTION_PDF' | translate}}
                </p>
            </div>
        </div>
        <hr>
        <div class="scenario_pdf">
            <img src="assets/png/Environment_Scenario.png" alt="">
            <div class="scenario-description-environment_pdf">
                <p class="scenario-description-title_pdf">
                    {{'ENVIRONMENT_ORIENTED_SCENARIO' | translate}}
                </p>
                <p class="scenario-description-text_pdf">
                    {{'ENVIRONMENT_ORIENTED_SCENARIO_DESCRIPTION_PDF' | translate}}
                </p>
            </div>
        </div>
    </div>

    <div *ngIf="!isEmptyProductCategory()">
      <div class="residual_value_descriptions" *ngIf="!isPDFReport">
          <div class="scenario">
              <img src="assets/png/Profit_Scenario.png" alt="">
              <div class="scenario-description-profit">
                  <p class="scenario-description-title">
                      {{'PROFIT_ORIENTED_SCENARIO' | translate}}
                  </p>
                  <p class="scenario-description-text">
                      {{'PROFIT_ORIENTED_SCENARIO_DESCRIPTION' | translate}}
                  </p>
              </div>
          </div>
          <div class="scenario">
              <img src="assets/png/Environment_Scenario.png" alt="">
              <div class="scenario-description-environment">
                  <p class="scenario-description-title">
                      {{'ENVIRONMENT_ORIENTED_SCENARIO' | translate}}
                  </p>
                  <p class="scenario-description-text">
                      {{'ENVIRONMENT_ORIENTED_SCENARIO_DESCRIPTION' | translate}}
                  </p>
              </div>
          </div>
      </div>
    </div>
    <div *ngIf="isEmptyProductCategory()" class="error-section">
      <h3 *ngIf="!isPDFReport" class="error-section__title">{{ 'No deposit considered' | translate }}</h3>
    </div>

    <div class="label-chart-wrapper" *ngIf="data">
        <div class="label y">
            <p [innerHTML]="'DECONSTRUCTION_Y_LABEL' | translate"></p>
        </div>
        <div class="chart-wrapper">
            <div #containerChart></div>
        </div>
        <div class="label x">
            <p [innerHTML]="'DECONSTRUCTION_X_LABEL' | translate"></p>
        </div>
    </div>

    <div *ngIf="!isEmptyProductCategory()">
      <div class="deposits" *ngIf="!isPDFReport">
          <p><span class="deposits-text-value">{{data.deposit_considered}} /
                  {{data.total_number_of_deposit}}</span> {{ 'deposits considered' | translate }}</p>
          <p><span class="deposits-text-value">
            {{data.deposit_total_weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
            </span>
              {{ 'tons of deposits in total' | translate }}</p>
          <div class="deposits-block-link">
              <i class="icon blue-eye--icon"></i>
              <a class="deposits-link-item" (click)="goToArchive()">{{ 'See pdf for more details' | translate }}</a>
          </div>
      </div>
    </div>
    <div *ngIf="isEmptyProductCategory()" class="error-section">
      <h3 *ngIf="!isPDFReport" class="error-section__title">{{ 'No deposit considered' | translate }}</h3>
    </div>

    <div class="deposits-pdf" *ngIf="isPDFReport">
        <p><span class="deposits-text-value-pdf">{{data.deposit_considered}} /
                {{data.total_number_of_deposit}}</span> {{ 'deposits considered' | translate }}</p>
        <p><span class="deposits-text-value-pdf">
            {{data.deposit_total_weight | floatingDigitNumberAfterComma: 'fr' | decimalSeparator:','}}
        </span>
            {{ 'tons of deposits in total' | translate }}</p>
    </div>

</div>
<div *ngIf="!data" class="error-section">
  <h3 class="error-section__title">{{ 'No deposit considered' | translate }}</h3>
</div>
