import * as tslib_1 from "tslib";
import { map } from "rxjs/operators";
import { DepositLocationService } from './../../deposit/services/deposit-location.service';
import { DepositLocation } from './../../deposit/models/deposit-location.class';
import * as i0 from "@angular/core";
import * as i1 from "../../deposit/services/deposit-location.service";
var SiteService = /** @class */ (function () {
    function SiteService(depositLocationService) {
        this.depositLocationService = depositLocationService;
    }
    SiteService.prototype.loadSites = function (filterParams, isNoah) {
        if (isNoah === void 0) { isNoah = null; }
        var params = tslib_1.__assign({}, this.getDefaultMapParams(), filterParams, { noah_view: isNoah ? 1 : 0 });
        return this.depositLocationService.getMapLocations(params).pipe(map(function (data) { return data.map(function (datum) { return new DepositLocation(datum); }); }));
    };
    SiteService.prototype.getDefaultMapParams = function () {
        return {
            active_sites: 1,
        };
    };
    SiteService.ngInjectableDef = i0.defineInjectable({ factory: function SiteService_Factory() { return new SiteService(i0.inject(i1.DepositLocationService)); }, token: SiteService, providedIn: "root" });
    return SiteService;
}());
export { SiteService };
