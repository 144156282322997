import { IModuleNavigation } from '../../shared/interfaces/module-navigation.interface';

export const SPECIALADMIN_NAVIGATION: IModuleNavigation[] = [
  {
    url: 'special_admin/users',
    title: 'Users',
    icon: 'users--icon'
  },
  {
    url: 'special_admin/passports',
    title: 'Circular passports',
    icon: 'cube--icon'
  }
];
