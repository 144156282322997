import { TranslateService } from '@ngx-translate/core';
import { EcosystemIndicatorReportContext } from '../../../services/ecosystem-indicator-report/ecosystem-indicator-report-context.service';
import { EEcosystemIndicatorReportType } from '../../../values/ecosystem-indicator-report.const';
var EcosystemIndicatorReportDetailsTabComponent = /** @class */ (function () {
    function EcosystemIndicatorReportDetailsTabComponent(translate) {
        this.translate = translate;
        this.isDetailsOpen = false;
    }
    Object.defineProperty(EcosystemIndicatorReportDetailsTabComponent.prototype, "virginProductsSteps", {
        get: function () {
            return this.reportContext.getStepsStrategy(this.type).virginProducts || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemIndicatorReportDetailsTabComponent.prototype, "depositSteps", {
        get: function () {
            return this.reportContext.getStepsStrategy(this.type).deposits || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemIndicatorReportDetailsTabComponent.prototype, "transformSteps", {
        get: function () {
            return this.reportContext.getStepsStrategy(this.type).transforms || [];
        },
        enumerable: true,
        configurable: true
    });
    EcosystemIndicatorReportDetailsTabComponent.prototype.getOrders = function (step) {
        return this.reportContext.getOrdersByStep(this.type, step);
    };
    Object.defineProperty(EcosystemIndicatorReportDetailsTabComponent.prototype, "transportSteps", {
        get: function () {
            return this.reportContext.getStepsStrategy(this.type).transports || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemIndicatorReportDetailsTabComponent.prototype, "needSteps", {
        get: function () {
            return this.reportContext.getStepsStrategy(this.type).needs || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemIndicatorReportDetailsTabComponent.prototype, "stepUnit", {
        get: function () {
            return this.reportContext.getUnit(this.type);
        },
        enumerable: true,
        configurable: true
    });
    EcosystemIndicatorReportDetailsTabComponent.prototype.toggleDetails = function () {
        this.isDetailsOpen = !this.isDetailsOpen;
    };
    EcosystemIndicatorReportDetailsTabComponent.prototype.getFormattedValue = function (step) {
        return this.isDataMissing(step) ? this.translate.instant('Missing data')
            : this.reportContext.formatValueStrategy(this.type, step);
    };
    EcosystemIndicatorReportDetailsTabComponent.prototype.isDataMissing = function (step) {
        return this.reportContext.isDataMissingStrategy(this.type, step.errors, step);
    };
    EcosystemIndicatorReportDetailsTabComponent.prototype.hasWarnings = function (step) {
        return this.reportContext.hasWarningsStrategy(this.type, step.warnings);
    };
    EcosystemIndicatorReportDetailsTabComponent.prototype.getWarnings = function (step) {
        return this.reportContext.getWarningsStrategy(this.type, step.warnings);
    };
    return EcosystemIndicatorReportDetailsTabComponent;
}());
export { EcosystemIndicatorReportDetailsTabComponent };
