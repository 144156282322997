import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
var CrudService = /** @class */ (function (_super) {
    tslib_1.__extends(CrudService, _super);
    function CrudService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.relations = [];
        return _this;
    }
    Object.defineProperty(CrudService.prototype, "singularEndpoint", {
        get: function () {
            return this.namespaceSingular ? this.namespaceSingular : this.namespace;
        },
        enumerable: true,
        configurable: true
    });
    CrudService.prototype.get = function (query) {
        var _this = this;
        return this
            .sendGet(this.getEndpoint(this.namespace), { search: query })
            .map(function (res) { return res.json().map(function (item) { return _this.buildModel(item); }); })
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.getSingular = function (query) {
        var _this = this;
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint), { search: query })
            .map(function (res) { return res.json().map(function (item) { return _this.buildModel(item); }); })
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.getResponse = function (query) {
        return this
            .sendGet(this.getEndpoint(this.namespace), { search: query })
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.getWithArray = function (query) {
        return this
            .sendGetWithArray(this.getEndpoint(this.namespace), query)
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.postWithArray = function (data, query) {
        return this
            .sendPostWithArray(this.getEndpoint(this.namespace), data, query)
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.view = function (id, query) {
        var _this = this;
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '/' + id), { search: query })
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.create = function (data) {
        var _this = this;
        return this
            .sendPost(this.getEndpoint(this.namespace), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onErrorTranslation.bind(this));
    };
    CrudService.prototype.update = function (id, data) {
        var _this = this;
        return this
            .sendPut(this.getEndpoint(this.namespace + '/' + id), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.remove = function (id) {
        return this
            .sendDelete(this.getEndpoint(this.singularEndpoint + '/' + id))
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.duplicate = function (id, data, isDraftNeed) {
        var _this = this;
        var state = isDraftNeed ? '?status=1' : '';
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/copy' + state), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.archive = function (id) {
        var _this = this;
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/archive'), {})
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.search = function (query) {
        var _this = this;
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '/list'), { search: query })
            .map(function (res) { return res.json().map(function (item) { return _this.buildModel(item); }); })
            .catch(this.onError.bind(this));
    };
    CrudService.prototype.buildModel = function (data) {
        return new this.ModelClass(data);
    };
    CrudService.prototype.formQueryString = function (query) {
        return Object.keys(query).filter(function (key) { return query[key] !== null; }).map(function (key) {
            if (Array.isArray(query[key])) {
                return query[key].map(function (value) { return key + '[]=' + value; }).join('&');
            }
            else {
                return key + '=' + query[key];
            }
        }).join('&');
    };
    return CrudService;
}(ApiService));
export { CrudService };
