/**
 * Created by aleksandr on 7.08.17.
 */
import { ActiveRecord } from '../../models/active-record.class';
import { ChatService } from '../chat.service';
import { ISendMessageResponse } from '../interfaces/chat-room.interface';
import { Observable } from 'rxjs/Observable';
import { ServiceLocator } from '../../services/service-locator';
import { ChatMessage } from './chat-message.model';
import { ChatUser } from './chat-user.model';

export class Thread extends ActiveRecord {

  id: number;
  user_id: number;
  messages: ChatMessage[] = [];
  contacts: ChatUser[] = [];

  protected provider: any = ChatService;
  public service: ChatService = ServiceLocator.injector.get(ChatService);


  loadContacts(): Observable<ChatUser[]> {
    return this.service.getRoomParticipants(this.id);
  }

  readThread() {
    return this.service.readThread(this.id);
  }

  loadMessages(): Observable<ChatMessage[]> {
    return this.service.loadHistory(this.id);
  }

  sendMessage(text: string): Observable<ISendMessageResponse> {
    return this.service.sendMessage(this.id, text);
  }

  fields() {
    return ['id', 'user_id'];
  }

  extractData(data: any) {
    //TODO temp
    if (data.hasOwnProperty('thread_id')) {
      data['id'] = data['thread_id'];
    }
    return super.extractData(data);
  }
}
