import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { EDeepStreamMessage } from '../../values/deepstream-message-type.enum';
import { BaseDeepStreamService } from './base-deepstream.service';
var ZoneDeepStream = /** @class */ (function (_super) {
    tslib_1.__extends(ZoneDeepStream, _super);
    function ZoneDeepStream() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.messageTypes = [
            EDeepStreamMessage.ZONE_COPIED,
            EDeepStreamMessage.ZONE_DELETED
        ];
        return _this;
    }
    ZoneDeepStream.prototype.emit = function (data) {
        switch (data.type) {
            case EDeepStreamMessage.ZONE_COPIED:
                return this.copyingStream$.emit({ target: this.receiver, data: data });
            case EDeepStreamMessage.ZONE_DELETED:
                return this.deletionStream$.emit({ target: this.receiver, data: data });
        }
    };
    Object.defineProperty(ZoneDeepStream.prototype, "copyingStream$", {
        get: function () {
            return ZoneDeepStream._copyingStream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ZoneDeepStream.prototype, "deletionStream$", {
        get: function () {
            return ZoneDeepStream._deletionStream$;
        },
        enumerable: true,
        configurable: true
    });
    ZoneDeepStream._copyingStream$ = new EventEmitter();
    ZoneDeepStream._deletionStream$ = new EventEmitter();
    return ZoneDeepStream;
}(BaseDeepStreamService));
export { ZoneDeepStream };
