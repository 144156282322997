import { Component, NgZone, OnInit } from '@angular/core';
import { NEED_STATUSES } from '../../../../entities/need/values/need-statuses.const';
import { NeedService } from '../../../../entities/need/services/need.service';
import { FormControl } from '@angular/forms';
import {
  ECustomListHintClass, ECustomListRecordStatus, ECustomListUnit, ICustomListRecord
} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { Need } from '../../../../entities/need/models/need.class';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
import { EcosystemOfferService } from '../../../../entities/ecosystem-offer/ecosystem-offer.service';
import {MdDialog} from '@angular/material';
import {SalesOfferDialogComponent} from '../../dialogs/sales-offer-dialog/sales-offer-dialog.component';
import {EOfferStatusActive, EOfferStatusArchive} from '../../../../entities/ecosystem-offer/values/ecosystem-offer-filters.const';
import { TabState } from '../../../../entities/need/partials/need-list/need-list.const';
import {
  OfferTransactionConfirmDialogComponent
} from '../../../../entities/ecosystem-offer/dialogs/offer-transaction-confirm-dialog/offer-transaction-confirm-dialog.component';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {EcosystemProblemUtilsService} from '../../../../entities/ecosystem/services/ecosystem-problem-utils.service';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {EcosystemOffer} from '../../../../entities/ecosystem-offer/ecosystem-offer.model';
import { ECOSYSTEM_NEED_STEP_STATUS_LABELS, ECOSYSTEM_OFFER_STATUS} from '../../../ecosystem-manager/values/ecosystem-offer-status.const';
import {DepositDetailsDialogComponent} from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';


@Component({
  moduleId: module.id,
  selector: 'sales-needs-archive',
  templateUrl: 'sales-needs-archive.component.html'
})
export class SalesNeedsArchiveComponent implements OnInit {

  filterStatuses: {needStatus: {view?: string, value: number}, checked: boolean, offerStatus?: number, text: string}[] = [
    {needStatus: NEED_STATUSES.DONE, checked: true, text: 'CONFIRMED'},
    {needStatus: NEED_STATUSES.REJECTED, checked: false, text: 'REJECTED'},
    {needStatus: NEED_STATUSES.REFUSED, checked: false, text: 'REFUSED'},
    {needStatus: NEED_STATUSES.SUSPENDED, checked: false, text: 'NOT_ACHIEVED'}
  ];

  search = new FormControl;

  creationDateFrom: string;
  creationDateTo: string;

  deliveryDateFrom: string;
  deliveryDateTo: string;

  orderBy = '-created_at';
  isLoadingNewPage = false;
  currentPage = 1;
  totalPagesCount = 0;

  records: ICustomListRecord[] = [];

  public NEED_SORT_ORDER: any[] = [
    {key: '-created_at', value: 'Creation date'},
    {key: '-delivery_date', value: 'Delivery date'},
    {key: 'client_name', value: 'Recipient name'},
    {key: 'name', value: 'Name'}
  ];

  constructor(private needService: NeedService,
              private datePipe: DatePipe,
              private router: Router,
              private _globalEvents: GlobalEventsManager,
              private _offerService: EcosystemOfferService,
              private _dialog: MdDialog,
              private zone: NgZone,
              private ecosystemProblemUtilsService: EcosystemProblemUtilsService,
              private notificationService: LogNotificationService
              ) {
    this
      .search
      .valueChanges
      .debounceTime(400)
      .subscribe(() => this.getRecords());
  }

  ngOnInit() {
    this.getRecords();
  }

  getRecords(forPage: number = 1) {

   if (forPage === 1) {
     this.records = [];
    }


    const statuses = this.getStatusParams();
    const offerStatuses = this.getOfferStatus();
    if (statuses.length <= 0 && !offerStatuses.length) {
      this.records = [];
      return ;
    }


    const params: any = {
      expand: 'client,client_name,sales_contact,esm_name,passport,esm_id,client_location,client_contact,offer',
      search: this.search.value || null,
      delivery_from: this.deliveryDateFrom || null,
      delivery_to: this.deliveryDateTo || null,
      created_from: this.creationDateFrom || null,
      created_to: this.creationDateTo || null,
      'status[]' : statuses,
      'sort': this.orderBy ,
      paginate: true ,
      page: forPage
    };


    this.isLoadingNewPage = true;
    this.needService.getWithPaginationData(params).subscribe((res) => {

      const headers = res.headers;
      this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
      this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
      res.json().map((need: Need) => {
        const tempNeed = new Need(need);
        tempNeed.offer = need.offer.map(item => new EcosystemOffer(item));
        this.addNeedOrOffer(tempNeed, statuses, offerStatuses);
      });
      this.isLoadingNewPage = false;
    });

  }

  private addNeedOrOffer(need: Need, statuses: number[], offerStatus: number[]) {
    if (!statuses.includes(need.status) && need.offer && need.offer.length) {
      need.offer.filter(item => offerStatus.includes(item.status))
        .forEach(item => this.addOfferRecord(need, item));
    } else if (statuses.includes(need.status)) {
      this.addRecord(need);
    }
  }

  private addOfferRecord(need, offer: EcosystemOffer) {
    this.addRecord(new Need({...need, offer: [offer]}), true);
  }

  getStatusParams () {
    const status = [] ;
    this.filterStatuses.forEach(st => {
     if (st.checked && !st.offerStatus) {
       status.push(st.needStatus.value);
     }
    });
    return status ;
  }

  private getOfferStatus() {
    return this.filterStatuses.filter(option => !!option.offerStatus && option.checked).map(option => option.offerStatus);
  }

  addRecord(need: Need, isActiveNeed = false) {
    this.records.push(
      this.createNeedRecord(
        need,
        ECustomListRecordStatus.NO_STATUS,
        ECustomListHintClass.NO_CLASS,
        '',
        need.name,
        need.client.name,
        need.quantity,
        need.passport.customListUnit,
        this.datePipe.transform(need.created_at, 'dd.MM.y'),
        this.datePipe.transform(need.delivery_date, 'dd.MM.y'),
        need.client_location.name,
        need.client_contact.name,
        need.client_contact.email,
        isActiveNeed
      )
    );
  }
  onEndOfListReached() {

    if (this.currentPage < this.totalPagesCount && !this.isLoadingNewPage) {
      const newValue = this.currentPage + 1;
      this.getRecords(newValue);
    }
  }
  createNeedRecord(need: Need,
                   status: ECustomListRecordStatus,
                   hintCssClass: ECustomListHintClass,
                   hintText: string,
                   title: string,
                   subtitle: string,
                   amount: number,
                   unit: ECustomListUnit,
                   createdAt: string,
                   archivedAt: string,
                   deliverySite: string,
                   salesName: string,
                   salesEmail: string,
                   isActiveNeed: boolean
                   ): CustomListRecord {
    const controls = [
      {
        fn: (data: ICustomListRecord) => {
          this.goToEditNeed(need);
        },
        name: 'See details'
      }, ...(need.from_deposit && need.from_deposit > 0 ? [{
        name: 'See targeted deposit',
        fn: () => {
          this._dialog.open(DepositDetailsDialogComponent, {
            data: {
              id: need.from_deposit,
              readOnly: true
            }
          });
        }
      }] : []),
     /* {
        fn: (data: ICustomListRecord) => {
          need.duplicate().subscribe((newNeed: Need) => this.goToEditNeed(newNeed));
        },
        name: 'Duplicate'
      },*/
    ];
    if (Array.isArray(need.offer) && need.offer.length > 0) {
      controls.push({
        fn: (data: ICustomListRecord) => {
          this._dialog
            .open(SalesOfferDialogComponent, {
              data: {
                offer: need.offer[0],
                need: need
              },
            });
        },
        name: 'See offer'
      });
      if (need.status === NEED_STATUSES.DONE.value) {
        controls.push({
          fn: (data: ICustomListRecord) => {
            this._dialog.open(OfferTransactionConfirmDialogComponent, {
              ...DIALOG_THEME.TRANSACTION_ORDER_CONFIRMATION,
              data: {
                id: need.offer[0].id,
                disabled: true
              },
            });
          },
          name: 'See confirmation'
        });
      }
      if (need.offer[0].status === ECOSYSTEM_OFFER_STATUS.PROBLEM_REPORTED) {
        controls.push({
          fn: (data: ICustomListRecord) =>
            need.offer[0].load({expand: 'problem'}).subscribe((orderWithProblem: EcosystemOffer) => {
              if (orderWithProblem.status === ECOSYSTEM_OFFER_STATUS.PROBLEM_REPORTED) {
                this.ecosystemProblemUtilsService.openOrderProblemDialog(orderWithProblem.problem.description || '', "Offer problem");
              } else {
                this.notificationService.error('No result found', null, true);
                this.getRecords();
              }
            }),
            name: 'See offer problem'
        });
      }
    }
    if (!isActiveNeed) {
      controls.push({
        fn: (data: ICustomListRecord) => {
          need.destroy().subscribe(() => this.getRecords());
        },
        name: 'Delete'
      });
    }
    return CustomListRecord
      .build()
      .setPrimaryKey(need.id)
      .addImageColumn(need.client.image)
      .setStatus(status)
      .setHint(hintText, hintCssClass)
      .addTitleColumn(title, subtitle)
      .addUnitColumn(amount, unit)
      .addListColumn([
        {key: 'Delivered', value: archivedAt},
        {key: 'Site', value: deliverySite}
        ])
      .addListColumn([{
        key: 'Created', value: createdAt
      }, {
        key: 'Archived', value: archivedAt
      }])
      .addListColumn([{
        key: '', value: salesName
      }, {
        key: '', value: salesEmail
      }])
      .setRecordStatus(isActiveNeed ?  this.getOfferStatusText(need.offer[0]) : need.statusData.view)
      .addControlsColumn(controls);
  }

  private getOfferStatusText(offer: EcosystemOffer) {
    return ECOSYSTEM_NEED_STEP_STATUS_LABELS[ECOSYSTEM_OFFER_STATUS[offer.status]];
  }

  openSalesChat(esmId: number) {
    if (esmId) {
      this._globalEvents.chatUserClicked$.emit(esmId);
    }
  }


  goToEditNeed(need: Need) {
    if (need.status_data.text === 'PUBLISHED'  ) {
      this.zone.run(() => this.router.navigate(['sales/needs', need.id]));
    } else {
      this.zone.run(() => this.router.navigate(['sales/needs', need.id, 'edit']));
    }
  }

  goToNeeds() {
    this.zone.run(() => this.router.navigate(['sales/needs']));
  }
  goToTreatment() {
    this.zone.run(() => this.router.navigate(['sales/needs'], {fragment: TabState.TREATMENT.text}));
  }
}
