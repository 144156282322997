import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer, ViewChild, ElementRef} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MdDialog} from '@angular/material';
import {Passport, PassportFamily} from '../../models/passport.class';
import 'rxjs/add/operator/switchMap';
import {PASSPORT_UNITS, PASSPORT_UNITS_TYPE} from '../../values/passport-units.const';
import {PassportService} from '../../services/passport.service';
import {PassportFileService} from '../../services/passport-file.service';
import {PassportFile} from '../../models/passport-file.class';
import {CopyPassportDialogComponent} from '../../dialogs/copy-passport-dialog/copy-passport-dialog.component';
import {PassportFormatService} from '../../services/format.service';
import {PassportControlStates} from '../../models/passport-control-states.class';
import {PASSPORT_STATUS, PASSPORT_STATUS_CONST} from '../../values/passport-status.const';
import {IParentEvent} from '../../../../shared/interfaces/parent-event.interface';
import {VIEW_MODE} from '../../values/view-mode-values.const';
import {IPeriodOfUseType} from '../../models/period-of-use.interface';
import {PERIOD_OF_USE_TYPES} from '../../values/period-of-use.values';
import {ERoleIds} from '../../../../shared/values/role-ids.enum';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {ReadOnlyService} from '../../../../shared/read-only/read-only.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs/Subscription';
import {UserService} from '../../../../shared/services/user.service';
import {User} from '../../../../shared/models/user.class';
import {CurrentUser} from '../../../../shared/auth/current-user.class';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {ActivatedRoute, Router} from '@angular/router';
import {PASSPORT_TAB, PASSPORT_TAB_TYPE, PASSPORT_TAB_TYPE_NUMERIC} from '../../values/passport-tab.const';
import {COMPOSITION_RADIO, COMPOSITION_RADIOS} from '../../values/composition-radios.const';
import {PERFORMANCE_RADIOS} from '../../values/performance-radios.const';
import {DEFAULT_RADIO_VALUES} from '../../values/default-radio-values.const';
import {PASSPORT_CERTIFICATION_LEVELS} from '../../values/passport-certification-levels.const';
import {ConfirmC2cDialogComponent} from '../../dialogs/confirm-c2c-dialog/confirm-c2c-dialog.component';
import {NameGenerationDialogComponent} from '../../dialogs/name-generation-dialog/name-generation-dialog.component';
import {Manufacturers} from '../../models/manufacturers.class';
import {ManufacturersService} from '../../services/manufacturers.service';
import {ColorsService} from '../../services/colors.service';
import {Colors} from '../../models/colors.class';
import {ViewCategoryDialogComponent} from '../../dialogs/view-category/view-category.component';
import {SpinnerDialogComponent} from '../../../../shared/spinner/dialogs/spinner-dialog/spinner-dialog.component';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {Observable} from 'rxjs/Observable';
import {Response} from '@angular/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {DistributionAreaService} from '../../services/distribution-area.service';
import {NavbarStateService} from '../../../../shared/services/navbar.service';
import {NewPassportVersionDialog} from '../../dialogs/new-passport-version-dialog/new-passport-version-dialog.component';
import {Location} from '@angular/common';
import {
  PASSPORT_CONTROLS_WITH_VALIDATION,
  PASSPORT_LONG, PASSPORT_SHORT,
  PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION
} from '../../values/passport-validation-fields.const';
import {PassportGtin} from '../../models/passport-gtin.class';
import {forkJoinSafe} from '../../../../shared/helpers/fork-join-safe.helper';
import {PassportGtinBufferService} from '../../services/passport-gtin-buffer.service';
import {PassportGroupBuilderService} from '../../services/passport-group-builder/passport-group-builder.service';
import { PassportCustomStoreService } from '../../services/passport-custom-store.service';
import { NewPassportPublicationComponent } from '../../dialogs/new-passport-publication-dialog/new-passport-publication.component';

@Component({
  moduleId: module.id,
  selector: 'passport-edit-form-v2',
  templateUrl: 'edit-passport-v2.component.html',
  providers: [ReadOnlyService, 
    {provide: PassportGtinBufferService, useClass: PassportGtinBufferService}, 
    {provide: PassportCustomStoreService, useClass: PassportCustomStoreService}]
})
export class EditPassportV2Component implements OnInit, OnDestroy, AfterViewInit {
  @Input() passport?: Passport = new Passport;
  @Input() public asDialog = false;
  @Input() public listener?: EventEmitter<any>;
  @Input() public disabled = false;
  @Input() public parentModule: string;

  @Input() public enableSearch = true;
  @Input() public allowNameChange = true;
  @Input() public viewMode = 1;
  @Input() public askForRatingMode = false;
  @Input() parentFormGroup: FormGroup;
  @Input() controlStates = new PassportControlStates;
  @Input() lockPublished = false;
  @Input() selectedTab;
  isLongVersionActive: boolean;
  @Input() isDetailsView: boolean;
  @Input() spinnerEnabled = true;

  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  @Output() public passportChange: EventEmitter<Passport> = new EventEmitter();
  @Output() isUploading = new EventEmitter<boolean>();
  /**
   * Replaced by onSaveComplete
   * @deprecated
   */
  @Output() public onSave: EventEmitter<any> = new EventEmitter();
  @Output() public onSaveComplete: EventEmitter<any> = new EventEmitter();
  @Output() public onNextTabClick: EventEmitter<any> = new EventEmitter();
  @Output() public onEditabilityChanged: EventEmitter<any> = new EventEmitter();

  @ViewChild('passportComponents') public passportComponents;
  @ViewChild('passportColors') public passportColors;
  @ViewChild('passportNextUsages') public passportNextUsages;
  @ViewChild('passportManufacturers') public passportManufacturers;
  @ViewChild('passportDistributionAreas') public passportDistributionAreas;

  public files: FileList;

  units: any[] = PASSPORT_UNITS;
  period_types: IPeriodOfUseType[] = PERIOD_OF_USE_TYPES;

  private spinnerDialogRef;
  private requestsSubject = new BehaviorSubject([]);
  private spinnerTimeout;
  private requestSubscription;

  eventStream$: EventEmitter<IParentEvent> = new EventEmitter();

  uploadedfiles: PassportFile[] = [];
  onUpdateUploadUrl$: EventEmitter<string> = new EventEmitter();

  formatSuggestions: string[] = [];
  allCategories: string[] = [];
  filteredCategories: string[] = [];
  offerNewVersion: boolean;
  passportForm: FormGroup;
  areFormControlsDisabled = true;
  supervisorWarning = false;
  createdPassportId: number;
  uploadErrorText: string;
  commodityPrice: string | number = 0;
  passportSyncSubscription: Subscription;
  isPassportChanged: boolean ;
  isPublicAccess: boolean;
  VIEW_MODE = VIEW_MODE;
  PASSPORT_TAB = PASSPORT_TAB;
  moduleNames = ModuleNames;
  passportStatuses = PASSPORT_STATUS_CONST;

  compositionRadios = COMPOSITION_RADIOS;
  performanceRadios = PERFORMANCE_RADIOS;
  defaultRadioValues = DEFAULT_RADIO_VALUES;
  certificationLevels = PASSPORT_CERTIFICATION_LEVELS;

  passportType = 'MANUFACTURER';
  @Input('isLongVersionActive') set setIsLongVersionActive(isShown: boolean) {
    this.isLongVersionActive = isShown;
    if (this.passportForm && this.passportFormBuilder.getVisibilityLongControl(this.passportForm)) {
      this.passportFormBuilder.getVisibilityLongControl(this.passportForm).setValue(isShown);
    }
  }

  @Input() public destroyAction = () => {
    // this.savePassport(this.passportForm);
  }

  constructor(private passportStore: PassportCustomStoreService,
              public dialog: MdDialog,
              public _fb: FormBuilder,
              public gtinBuffer: PassportGtinBufferService,
              private _userService: UserService,
              public passportService: PassportService,
              public distributionAreaService: DistributionAreaService,
              public passportFileService: PassportFileService,
              public manufacturersService: ManufacturersService,
              public colorsService: ColorsService,
              public passportFormatService: PassportFormatService,
              private readOnlyService: ReadOnlyService,
              private navigationService: NavigationService,
              protected router: Router,
              private renderer: Renderer,
              private translate: TranslateService,
              private cd: ChangeDetectorRef,
              private notification: LogNotificationService,
              private _changeDetectionRef: ChangeDetectorRef,
              public filterState: NavbarStateService,
              private route: ActivatedRoute,
              public location: Location,
              private passportFormBuilder: PassportGroupBuilderService
              ) {
    this.saveGtinsInBuffer = this.saveGtinsInBuffer.bind(this);
  }


  public isVisible(name: string | string[]) {
    return this.passportFormBuilder.getVisibility(this.passportForm, Array.isArray(name) ? name : [name]);
  }

  isLocked(): boolean {
    return this.lockPublished && (this.passport.status === PASSPORT_STATUS.PUBLISHED);
  }

  openBannedComponentsList(): void {
    this.passportService.openBannedComponentsPdf(this.translate.currentLang);
  }

  getActiveType(): number {
    if (this.selectedTab === PASSPORT_TAB.INFO) {
      return null;
    }
    return (PASSPORT_TAB_TYPE[this.selectedTab.toUpperCase()]);
  }

  getUploadFiles(): PassportFile[] {
    return this.uploadedfiles.filter(file => file.type == this.getActiveType());
  }

  onLogoUpload(response: any) {
    let data: any = null;
    try {
      data = typeof response === 'string' ? JSON.parse(response) : response;
    } catch (e) {
      console.log(e);
    }

    if (data) {
      this.passport.set({image: data.image});
      this.passport.set({thumbnail: data.thumbnail});
    }
  }

  getLogoUploaderUrl(): string {
    return this.passportService.getLogoUploadUrl(this.passport.id);
  }

  canEditDocuments(): boolean {
    return this.navigationService.getContext().roleId === ERoleIds.SUPERVISOR
      || this.createdPassportId > 0;
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.offerNewVersion = true;
    this.passportType = this.route.snapshot.paramMap.get('type') || 'MANUFACTURER';
    this.isPublicAccess = (ModuleNames.GUEST == this.parentModule);
    this.isPassportChanged = true ;
    this.onChange.subscribe((passport: Passport) => {
      this.passportChange.emit(passport);
      if (this.isPassportChanged){
        this.isPassportChanged =  false ;
        this.eventStream$.emit({key: 'changePassport', data: passport});
      }
    });

    this.createForm();
    this.passportStore.setForm(this.passportForm);
    this.gtinBuffer.setRecordTransform(this.gtinBuffer.gtinMultiSaveTransform);
    this.gtinBuffer.setNotifier(this.eventStream$, this.saveGtinsInBuffer);

    if (this.passport && this.passport.id > 0) {
      this.setPassport(this.passport);

      if (this.passport.supervisor_warning) {
        // get count
        this.supervisorWarning = false;
      } else {
        this.supervisorWarning = false;
      }
    } else {
      this.disableFormControls();
    }

    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if (event.key === 'changePassport') {
          this.setPassport(event.data);
        } else if (event.key === 'updatePassport') {
          this.updatePassport(this.passportForm, event.data ? !!event.data.redirection : true);
        } else if (event.key === 'publishPassport') {
          this.publishPassport(this.passportForm);
        } else if (event.key === 'validatePassport') {
          this.validatePassport();
        } else if (event.key === 'askForRating') {
          this.askForRating(this.passportForm);
        } else if (!!this.passport.id) {
          this.savePassport(this.passportForm);
        }
      });
    }

    if (this.parentFormGroup) {
      this.parentFormGroup.setControl('passport', this.passportForm);
    }
    
    if (this.isNewVersionCreationAllowed() && this.offerNewVersion){
      this.passportStore.formChangedByUserAction.subscribe(() => {
        if(this.offerNewVersion){
          this.openPopup();
          this.offerNewVersion = false; 
        }
      });
    }
   /* this.passportService.getCategoriesList().subscribe((categories: string[]) => {
      let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
      this.allCategories = categories.sort(collator.compare);
      this.onCategoryChange(this.passport.category);
    });*/

    this.translate.get('Cant upload without passport created').subscribe((text) => {
      this.uploadErrorText = text;
    });

    this.requestsSubject
      .skip(1)
      .debounceTime(100)
      .subscribe(() => this.subscribeOnRequests());
  }

  ngOnDestroy(): void {
    if (this.destroyAction !== null) {
      this.destroyAction();
    }
    this.passportStore.unSubscribeStore();
    this.passportFormBuilder.unsubscribe(this.passportForm);
    this.gtinBuffer.unsubcribe();
  }

  private saveGtinsInBuffer(e: { key: string, data: any }, observable: Observable<any>) {
      if (e.key === 'savePassport') {
        this.onUpdatePassportData(
          {request: observable, type: 'gtins save'}
        );
      }
  }

  onAfterRemove($event): void {
    const passportFile: PassportFile = $event;
    this.uploadedfiles = this.uploadedfiles.filter(e => e !== passportFile);
  }

  onItemUploaded($event): void {
    const passportFile: PassportFile[] = $event;
    this.uploadedfiles = [...this.uploadedfiles, ...passportFile];
  }

  onControlClick($event: Event) {
    if (!this.disabled) {
      if (this.isLocked()) {
        $event.preventDefault();
        $event.stopPropagation();
        this.renderer.invokeElementMethod($event.target, 'blur', []);
        this.onLockedPassportInteract($event);
      }
    }
  }

  onLockedPassportInteract($event: Event) {
    const dialogRef = this.dialog.open(CopyPassportDialogComponent, {
      data: {
        name: this.passport.name,
        parentModule: this.parentModule
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if ($event && !result) {
        this.renderer.invokeElementMethod($event.target, 'blur', []);
      }
      if (result) {
        this.passportService.duplicate(this.passport.id, {
          name: result,
          family: this.passport.getPassportFamilyBaseOnModule(this.parentModule)
        }, true).subscribe((response: any) => {
          this.setPassport(<Passport>response);
          this.enableFormControls();
        }, (err: any) => console.log(err));
      } else {
        this.disableFormControls();
      }
    });
  }
  getPassportCustomName(record) {
    if (record) {
      return record.getProductName();
    }
  }
  disableFormControls(): void {
    const ratingFields = [
      'ratingComposition', 'ratingRecyclability', 'ratingSocialFairness', 'ratingEnergy', 'ratingWaterStewardship'
    ];
    Object.keys(this.passportForm.controls)
    .filter(e => e !== ratingFields.find(i => e === i))
    .forEach((key) => this.passportForm.controls[key].disable());
    this.eventStream$.emit({key: 'disableStatus', data: {isDisabled: true}});
    this.areFormControlsDisabled = true;
    this.onFormControlsDisabledChanged(this.areFormControlsDisabled);
  }

  enableFormControls(): void {
    Object.keys(this.passportForm.controls).forEach((key) => this.passportForm.controls[key].enable());
    this.eventStream$.emit({key: 'disableStatus', data: {isDisabled: false}});
    this.areFormControlsDisabled = false;
    this.onFormControlsDisabledChanged(this.areFormControlsDisabled);
  }

  /**
   * Event triggered when the form controls disability is changed
   * @param {boolean} state - true if the controls are disabled, false else
   */
  onFormControlsDisabledChanged(state) {
    this.onEditabilityChanged.emit(!state);
  }

  createForm() {
    this.passportForm =  this.passportFormBuilder.buildGroupLong(this.passport)
      .omitMultiControls(PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION.long)
      .addControlOptions({
        passportName: [this.passport.name, Validators.required],
        passportEnglishName: [this.passport.english_name, Validators.required],
        quantity: [this.passport.period, [Validators.pattern(/^\d+$/), Validators.min(0)]],
        category: ['', Validators.required],
        passportUnit: [this.passport.unit, Validators.required],
        recyclePercentage: [this.passport.recycle_percentage, [Validators.min(0), Validators.max(100)]],
        conversion: [this.passport.conversion, [Validators.min(0)]],
        dimensionLength: [this.passport.dimension_length, [Validators.min(0)]],
        dimensionWidth: [this.passport.dimension_width, [Validators.min(0)]],
        dimensionHeight: [this.passport.dimension_height, [Validators.min(0)]],
        purchasePrice: [this.passport.purchase_price, [Validators.min(0)]],
        [PASSPORT_CONTROLS_WITH_VALIDATION.SOCIAL_PERFORMANCE]: []
      }).build();

   // this.passportForm.get('category').valueChanges.subscribe((value) => this.onCategoryChange(value));
    // this.passportForm.statusChanges.subscribe((value) => {
    //   if (this.passportForm.dirty) {
    //     this.commodityPrice = this.calculatePrice().toFixed(2);
    //   }
    // });
  }


  unitChange(emitEvent = false) {
    if (this.isPassportConversionShown() ) {
      this.passportForm.controls['conversion'].setValidators([Validators.required, Validators.min(0)]);
    } else {
     this.passportForm.controls['conversion'].setValidators([Validators.min(0)]);
    }
    this.passportForm.controls['conversion'].updateValueAndValidity({emitEvent: false});
    this._changeDetectionRef.detectChanges();
  }
  onSelect(passport: Passport) {
    this.setPassport(passport);
    if (this.passport.supervisor_warning) {
      // get count
      this.supervisorWarning = false;
    } else {
      this.supervisorWarning = false;
    }
    if (this.createdPassportId > 0) {
      this.passportService.remove(this.createdPassportId).subscribe(() => {
        this.createdPassportId = -1;
        this.readOnlyService.readOnly = !this.canEditDocuments();
      });
    }
  }

  setCategory(emitEvent = false ){
    const categoryName = !!this.passport.category && !!this.passport.category.name && this.passport.category.name;
      const translation = !!categoryName ? this.translate.instant(categoryName) : '';
      this.passportForm.controls['category'].setValue(translation, {emitEvent: emitEvent});
  }
  setPassport(passport: Passport) {
    this.onChange.emit(passport);
    this.passport = passport;
    this.passportStore.setModel(this.passport);
    this.passportFormBuilder.changePassport(this.passportForm, passport);
    this.unitChange();
    this.passportStore.changeModelProgramaticly.emit(this.passport);
    this.onUpdateUploadUrl$.emit(this.passport.getFileUploadUrl());
    // this.readOnlyService.readOnly = !this.canEditDocuments();
    this.passportForm.controls['passportName'].setValue(passport.name, {emitEvent: false});
    this.passportForm.controls['passportEnglishName'].setValue(passport.english_name, {emitEvent: false});
    if (this.passport.id) {

      this.setCategory();
    }
    // this.commodityPrice = this.passport.price;
    // this.commodityPrice = !!this.commodityPrice ? this.commodityPrice.toFixed(2) : 0;

    if (!!this.passportSyncSubscription) {
      this.passportSyncSubscription.unsubscribe();
    }
    if (this.passport && !this.passport.materials) {
      this.passport.materials = [];
    }
    if (this.passport && !this.passport.components) {
      this.passport.components = [];
    }
    this.gtinBuffer.setOwnerObject(this.passport);
    // this.passportSyncSubscription = this.passport.onSyncComponents$.subscribe(() => {
    //   if (!this.commodityPrice) {
    //     this.commodityPrice = this.calculatePrice().toFixed(2);
    //   }
    //
    //   this.passportSyncSubscription.unsubscribe();
    // });

    if (this.isLocked() || this.disabled) {
      this.disableFormControls();
    } else {
      this.enableFormControls();
    }
    this.preloadUploadedItems();
    this.preloadManufacturers();
    this.preloadColors();
    this.preloadDistributionAreas();

    this
    .passportFormatService
    .get()
    .subscribe(
      (res: string[]) => this.formatSuggestions = res
    );
  }

  onNameEdit(name: string) {
    this.passport.name = name;
  }

  onCreate(passport: Passport) {
    this.passport = new Passport({...passport, unit: 0});
    this.passport.materials = [];
    this.passport.components = [];
    this.uploadedfiles = [];
    this.passport.manufacturers = [];
    this.passport.colors = [];
    if (! this.passport.id)
    this.passport.category_id = null;
    this.passport.category = null;

    this.passportService.createPassport(this.passport, this.parentModule, this.passportType).subscribe((result: Passport) => {
      const {id, name, family, english_name} = result;
      this.createdPassportId = id;
      this.passport = Object.assign(this.passport, {
          id, name, english_name, family, user: new User({...CurrentUser.infoData})
        });
      this.setPassport(this.passport);
      this.enableFormControls();
      // this.validatePassport();
    });
  }

  preloadColors() {
    if (!this.passport.id) {
      return;
    }
    this.passport.colors = [];
    this.colorsService
    .get<Colors>({passport_id: this.passport.id})
    .subscribe(
      results => {
        for (const item of results) {
          this.passport.colors.push(new Colors(item));
        }
      }
    );
  }

  preloadDistributionAreas() {
    if (!this.passport.id) {
      return;
    }
    this.passportService.view(this.passport.id, {expand: 'distribution_areas.country,category,gtins,passport_synchronization_data'})
    .subscribe((results: Passport) => {
      this.passport.distribution_areas = results.distribution_areas;
      this.passport.gtins = results.gtins;
      this.passport.category = this.passport.category || results.category;
      this.passportStore.changeModelProgramaticly.emit(this.passport);
      this.setCategory();
    });
  }

  preloadManufacturers() {
    if (!this.passport.id) {
      return;
    }
    this.passport.manufacturers = [];
    this.manufacturersService
    .get<Manufacturers>({passport_id: this.passport.id})
    .subscribe(
      results => {
        for (const item of results) {
          this.passport.manufacturers.push(new Manufacturers(item));
          this.passportStore.changeModelProgramaticly.emit(this.passport);
        }
      }
    );
  }

  preloadUploadedItems() {
    if (!this.passport.id) {
      return;
    }
    this.uploadedfiles = [];
    this.passportFileService
    .get<PassportFile>({passport_id: this.passport.id})
    .subscribe(
      passportFiles => {
        for (const file of passportFiles) {
          this.uploadedfiles.push(new PassportFile(file));
        }
      }
    );
  }

  handleUploadStatus(status: boolean) {
    this.isUploading.emit(status);
  }

  savePassport(passportForm: FormGroup): boolean {
    if (!passportForm.valid) {
      return false;
    }
    if (this.passport.status === PASSPORT_STATUS.PUBLISHED && !this.navigationService.isSupervisor()) {
      return false;
    }

 //   this.passport.category = this.passportForm.get('category').value
  //    ? this.passportForm.get('category').value
  //    : PASSPORT_DEFAULT_CATEGORY;
  //   this.onSaveTest({type: 'savePassport', request: mergeStatic(this.passport.save())});

  const sub = this.onSaveComplete
  .subscribe( () => {
        sub.unsubscribe();
        this.passport.save().subscribe((res) => {
          this.onSave.emit();
        });
      });
    this.eventStream$.emit({key: 'savePassport', data: this.passport});
    return true;
  }

  publishPassport(passportForm: FormGroup): boolean {
    if (!passportForm.valid) {
      return false;
    }

    if ( ! this.passportForm.controls['conversion'].value) {
      this.passport.conversion = null ;
    }
 //   this.passport.category = this.passportForm.get('category').value
  //    ? this.passportForm.get('category').value
   //   : PASSPORT_DEFAULT_CATEGORY;

    const sub = this.onSaveComplete
      .subscribe( () => {
        sub.unsubscribe();
        this.presentLoadingSpinner();
        this.passport.publish().subscribe((res) => {
          this.passportService.savePassportSynchronizationData(this.passport.aggregateSynchronizationData()).subscribe((result) => {});
          this.destroyLoadingSpinner();
          this.onSave.emit();
        }, () => this.destroyLoadingSpinner());
      });

    this.eventStream$.emit({key: 'savePassport', data: this.passport});
    return true;
  }

  updatePassport(passportForm: FormGroup,redirection = true) {
    if ( ! this.passportForm.controls['conversion'].value) {
      this.passport.conversion = null ;
    }
    const sub = this.onSaveComplete
      .subscribe( () => {
        sub.unsubscribe();
        this.passport.update().subscribe((res) => {
          this.passportService.savePassportSynchronizationData(this.passport.aggregateSynchronizationData()).subscribe((result) => {});
          if(redirection)
            this.onSave.emit();
        });
      });
    this.eventStream$.emit({key: 'savePassport', data: this.passport});
    return true;
  }

  validatePassport(): boolean {
    this.passport.publish().subscribe((res) => {
      console.log('Validated passport:', res);
    });
    return true;
  }

  askForRating(passportForm: FormGroup) {
    if (!passportForm.valid) {
      return false;
    }
    const sub = this.onSaveComplete
      .subscribe( () => {
          sub.unsubscribe();
          this.passport.askForRating().subscribe((res) => {
            this.onSave.emit();
          });
      });

    this.eventStream$.emit({key: 'savePassport', data: this.passport});
    return true;
  }

  onChangeName(name: string) {

    if (this.passport.name !== name) {
      this.passportFormBuilder.reset(this.passportForm, ['passportName']);
      this.passport.id = null;
      this.uploadedfiles = [];
      this.passport = new Passport({name: name});
      this.passport.set({image: null});
      this.onChange.emit(this.passport);
    }
  }

  onCategoryChange(value: string, setControlValue?: boolean) {
    this.filteredCategories = value
      ? this.allCategories.filter((category: string) =>
        ~category.toLowerCase().indexOf(value.toLowerCase()) && category !== value)
      : this.allCategories;

    if (setControlValue) {
      this.passportForm.get('category').setValue(value);
    }
  }

  isProductPassportIdVisible(): boolean {
    return this.navigationService.getContext().roleId === ERoleIds.SUPERVISOR
      && !!this.passport.product_passport_id;
  }

  // calculatePrice(): number {
  //   let componentsForm = <FormArray>this.passportForm.get('components');
  //   if (componentsForm) {
  //     return componentsForm.controls
  //     .map((control) => <FormGroup>control ? this.calculateComponentPrice(<FormGroup>control) : 0)
  //     .reduce((sum, price) => sum + price) || 0;
  //   }
  //
  //   return 0;
  // }

  // calculateComponentPrice(componentForm: FormGroup): number {
  //   let quantity = componentForm.get('componentNumber').value || Number(componentForm.get('isDefault').value) || 0;
  //   let materialsFormArray = <FormArray>componentForm.get('materials');
  //   if (materialsFormArray) {
  //     let materialsCost = materialsFormArray.controls
  //     .map((control) => <FormGroup>control ? this.calculateMaterialPrice(<FormGroup>control) : 0)
  //     .reduce((sum, price) => sum + price) || 0;
  //
  //     return quantity * materialsCost;
  //   }
  //
  //   return 0;
  // }

  // calculateMaterialPrice(materialForm: FormGroup): number {
  //   let quantity = materialForm.get('quantity').value || 0;
  //   let price = materialForm.get('price').value || 0;
  //
  //   let parsedQuantity: number = ValidationService.normalizeQuantity(quantity);
  //   let parsedPrice: number = ValidationService.normalizeQuantity(price);
  //
  //   return (parsedQuantity * parsedPrice) / 1000;
  // }

  removePassportPreview() {
    this.passportService.removeLogo(this.passport.id).subscribe(() => {
      this.passport.image = null;
      console.log('Deleted');
    });
  }

  handleC2cRadioClick(key: number) {
    if (this.disabled) {
      return;
    }
    if (key === COMPOSITION_RADIO.YES) {
      this.dialog.open(ConfirmC2cDialogComponent, {
        data: {
          openBannedComponentsPdf: () => this.openBannedComponentsList()
        }
      }).afterClosed().subscribe((data: number) => {
        return this.passport.no_banned_materials = !!data ? COMPOSITION_RADIO.YES : COMPOSITION_RADIO.NO;
      });
    } else {
      return this.passport.no_banned_materials = key;
    }
  }

  onOpenGenerationNameDialog() {

    // this.isDetailsView not used

    if (this.disabled || this.parentModule === this.moduleNames.SPECIAL_ADMIN) {
      return;
    }
    let dialog = this.dialog.open(NameGenerationDialogComponent, {
      data: {
        passport: this.passport,
        viewMode: this.viewMode,
        controlStates: this.controlStates,
        askForRatingMode: this.askForRatingMode,
        parentModule: this.parentModule
      }
    });
    dialog.afterClosed().subscribe((data: { passport: Passport, clearForm: boolean }) => {
      dialog = null;
      if (!data || !data.passport) {
        return;
      }
      if (!data.passport.id) {
        return this.onCreate(data.passport);
      }
      if (!!data.passport) {
        this.setPassport(data.passport);
      }
    });
  }

  getLabelClasses(field) {
    return {
      'primary-color': field,
      'label-color': !field,
      'passport-edit__color-transition': true
    };
  }

  setAsterisk(): string {
    return this.askForRatingMode ? '*' : '';
  }

  isRatingBlockVisible(): boolean {
    const availableValues = [this.passportStatuses.PUBLISHED.value, this.passportStatuses.WAITING_FOR_PUBLICATION.value];
    return this.parentModule === this.moduleNames.SPECIAL_ADMIN && !!availableValues.find(i => i === this.passport.status);
  }

  isFieldInvalid(field: string): boolean {
    if (!this.passportForm) {
      return;
    }
    const selectedControl: AbstractControl = this.passportForm.get(field);
    return (!!selectedControl && !!selectedControl.invalid);
  }

  isConversionInvalid(): boolean {
    return this.isFieldInvalid('conversion');
  }

  isSupportingDocumentsVisible(): boolean {
    const passportUserId: number = (!!this.passport && this.passport.user_id);
    if (this.selectedTab === PASSPORT_TAB.GENERAL || this.selectedTab === PASSPORT_TAB.INFO || !this.isLongVersionActive) {
      return false;
    }
    if (this.parentModule === ModuleNames.SPECIAL_ADMIN
      || this.parentModule === ModuleNames.MANUFACTURER
      && CurrentUser.infoData.id === passportUserId) {
        return true;
    }
  }

  openCategoryeDialog($event: Event): void {
    if ( this.readOnlyService && this.readOnlyService.readOnly) {
      return;
    }
    if ( !!this.areFormControlsDisabled) {
      return this.onControlClick($event);
    }
    const dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.passport.category = result;
        this.passport.category_id = result.id;
        this.passportForm.controls['category'].setValue(result.name ? result.name : '');
        this.passportForm.controls['category'].disable();
      }
    });
  }
  nextPassportStep(): void {
    const currentTab = PASSPORT_TAB_TYPE[this.selectedTab.toUpperCase()];
    const nextTab = PASSPORT_TAB_TYPE_NUMERIC[currentTab + 1];
    this.onNextTabClick.emit(nextTab);
  }
  isPassportConversionShown(): boolean {
    if (!this.passport || !this.passport.unit) {
      return false;
    }
    return this.passport.unit !== PASSPORT_UNITS_TYPE.KG && this.passport.unit !== PASSPORT_UNITS_TYPE.TONNE;
  }
  initSelfDestroySpinnerTimeout(): void {
    this.spinnerTimeout = setTimeout(() => {
      if (!this.spinnerDialogRef) { return; }
      this.destroyLoadingSpinner();
      this.notification.error(this.translate.instant('CANNOT_BE_SAVED_ERROR', {X: 'Circular passport'}), null);
      this.requestsSubject.next([]);
      this.requestSubscription.unsubscribe();
    }, 45000);
  }
  presentLoadingSpinner(): void {
    if (!this.spinnerEnabled || !!this.spinnerDialogRef) {
      return;
    }
    this.cd.detach();
    this.spinnerDialogRef = this.dialog.open(SpinnerDialogComponent, {
      disableClose: true,
      data: {
        caption: 'Saving circular passport', isLoadingDotsShown: true
      }
    });
    this.navigationService.showNavBar = false;
    this.initSelfDestroySpinnerTimeout();
  }
  destroyLoadingSpinner(): void {
    if (!this.spinnerDialogRef) {
      return;
    }
    this.cd.reattach();
    clearTimeout(this.spinnerTimeout);
    this.navigationService.showNavBar = true;
    this.spinnerDialogRef.close();
    this.spinnerDialogRef = null;
  }
  handlePassportDataRequest(type?: string, responseError?: Response): {success: () => void, error: () => void} {
    this.requestsSubject.next([]);
    this.destroyLoadingSpinner();
    return {
      success: () => {
        this.onSaveComplete.emit();
      },
      error: () => {
        const duration = 3000;
        this.notification.error(this.translate.instant('CANNOT_BE_SAVED_ERROR', {X: type}), duration);
        setTimeout(() => {
          this.passportService.onError(responseError);
        }, duration);
      }
    };
  }
  onUpdatePassportData(event: {request: Observable<any>, type: string}): void {
    this.presentLoadingSpinner();
    this.requestsSubject.next([
      ...this.requestsSubject.getValue(),
      event.request.catch((err: Response) => Observable.throw({error: err, field: event.type}))
    ]);
  }

  subscribeOnRequests() {
    this.requestSubscription = forkJoin(this.requestsSubject.getValue())
        .subscribe(
          () => this.handlePassportDataRequest().success(),
          (err: {error, field}) => this.handlePassportDataRequest(err.field, err.error).error()
        );
  }

  getCommetariesPlaceholder() {
    return this.disabled ? this.translate.instant('Comments') : this.translate.instant('add comments');
  }

  getRecommendationsForUsePlaceholder() {
    return this.disabled ? this.translate.instant('Recommendations for use') : this.translate.instant('add recommendations for use');
  }

  createAnotherVersion() {

    const dialogRef = this.dialog.open(NewPassportVersionDialog, {
      data: {
        passport: this.passport
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.passport) {
        this.offerNewVersion = false; 
        this.passport.id = result.passport.id;
        this.passport.status = result.passport.status;
        this.passport.version = result.passport.version;
        this.passport.created_at = result.passport.created_at;
        this.passport.updated_at = result.passport.updated_at;
        this.passport.product_passport_id = result.passport.product_passport_id;
        this.eventStream$.emit({key: 'changePassport', data: this.passport});
        this.enableFormControls();
        this.setCategory();
        this.updateUrl();
      }
    });
  }

  updateUrl() {
    this.location.go( this.navigationService.getContext().moduleName + '/passports/' + this.passport.id) ;
  }

  isNewVersionCreationAllowed(): boolean {
    const isNewVersion = (this.passport.isPublished() || this.passport.isStatus(PASSPORT_STATUS.ASK_FOR_MODIFICATION));
    if (!this.isPublicAccess && isNewVersion) {
      if ( this.parentModule === this.moduleNames.MANUFACTURER && this.passport.isIndependent()) {
        return this.passport.user.id === CurrentUser.infoData.id;
      }
      if (this.parentModule === this.moduleNames.SUPERVISOR) {
        return !this.passport.isIndependent();
      }
      if (this.parentModule === this.moduleNames.AGENT) {
        return this.passport.user.id === CurrentUser.infoData.id;
      }
    }
    return false;
  }

  openPopup() {
    const mdDialog = this.dialog.open(NewPassportPublicationComponent, {

     });
     mdDialog.afterClosed().subscribe(result => {
       if (result === 'edit') {
         return;
       } else if (result == 'new_version'){
         this.createAnotherVersion();
       }
     });
  }

  /**
   * Get all the objects associated to the passport (next usages, colors, etc...)
   * /!\ For now, this views and directly calls functions in the child components, which seems horrible to do. Think about a way of making this better /!\ 
   * @returns {Object}
   */
  getAssociatedObjects() {
    return {
      colors: this.getColors(),
      next_usages: this.getNextUsages(),
      ...this.getComponents(),
      manufacturers: this.getManufacturers(),
      distribution_areas: this.getDistributionAreas(),
    };
  }

  /**
   * Get the colors of the passport
   * @returns {Array<PassportColor>}
   */
  getColors() {
    return this.passportColors.getCurrentColors();
  }

  /**
   * Get the components of the passport
   * Also includes materials and attributes corresponding to the default component
   * @returns {Object}
   */
  getComponents() {
    return this.passportComponents.getCurrentComponents();
  }

  /**
   * Get the next usages of the passport
   * Also includes the affiliate branches corresponding to each next usage
   * @returns {Array<NextUsage>}
   */
  getNextUsages() {
    return this.passportNextUsages.getCurrentNextUsages();
  }

  /**
   * Get the manufacturers of the passport
   * @returns {Array<PassportManufacturer>}
   */
  getManufacturers() {
    return this.passportManufacturers.getCurrentManufacturers();
  }

  /**
   * Get the distribution areas of the passport
   * @returns {Array<Object>} The list of distribution areas
   */
  getDistributionAreas() {
    return this.passport.distribution_areas.map(this.formatDistributionArea);
  }

  /**
   * Format a distribution area to be understood by the backend
   * Mainly flatten it and get the country_iso available easily, even for new areas
   * @param {PassportDistributionArea} distributionArea - The distribution area to format
   * @returns {Object}
   */
  formatDistributionArea(distributionArea) {
    return {
      id: distributionArea.id,
      country_iso: distributionArea.country_id || distributionArea.country && distributionArea.country.iso || null
    };
  }
}
