<ng-container *ngIf="stakeholder?.loaded">
  <h2 *ngIf="!stakeholder.id">{{ 'Create new stakeholder' | translate }}</h2>

  <div class="row">
    <md-input-container>
      <input mdInput autocomplete="off" placeholder="{{ 'Company name' | translate }}" [(ngModel)]="stakeholder.company_name">
    </md-input-container>

    <md-select placeholder="{{ 'Type' | translate }}" [(ngModel)]="stakeholder.type">
      <md-option
        *ngFor="let TYPE of TYPES"
        [value]="TYPE"
      >{{ STAKEHOLDER_TYPES[TYPE] | lowercase | capitalize | translate}}
      </md-option>
    </md-select>
  </div>

  <div class="row">
    <md-input-container>
      <input mdInput autocomplete="off" placeholder="{{ 'Address' | translate }}" [(ngModel)]="stakeholder.address">
    </md-input-container>
  </div>

  <div class="row representative-row">
    <md-input-container>
      <input mdInput autocomplete="off" placeholder="{{ 'Representative' | translate }}" [(ngModel)]="stakeholder.name">
    </md-input-container>
  </div>

  <div class="row">
    <md-input-container>
      <input mdInput autocomplete="off" placeholder="{{ 'Telephone' | translate }}" [(ngModel)]="stakeholder.phone">
    </md-input-container>

    <md-input-container>
      <input mdInput autocomplete="off" placeholder="{{ 'Email' | translate }}" [(ngModel)]="stakeholder.email">
    </md-input-container>
  </div>

  <div class="row">
    <md-input-container class="description">
      <textarea mdInput placeholder="{{ 'Description' | translate }}" [(ngModel)]="stakeholder.description"></textarea>
    </md-input-container>
  </div>

  <button md-button class="save" (click)="save()">
    <img src="/assets/png/small-ok--icon.png" class="icon">
    {{ 'Save' | translate }}
  </button>
</ng-container>
