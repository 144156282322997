<div class="passport-item-content-ratings">
    <div mdTooltipPosition="above" mdTooltip="{{'PASSPORT_LIST_ITEM_C2C_TOOLTIP' | translate: getC2cTooltipValue() }}"
        *ngIf="passport.c2c_certified">
        <app-cradle-to-cradle-icon></app-cradle-to-cradle-icon>
    </div>
    <div>
        <app-rating-composition-icon mdTooltipPosition="above"
            [mdTooltip]="passport.rating.rating_composition > 0 ? ('PASSPORT_LIST_ITEM_TOXICITY_TOOLTIP' | translate) : ''"
            [toxicityState]="passport.rating.rating_composition"></app-rating-composition-icon>
    </div>
    <div>
        <app-rating-recyclability-icon mdTooltipPosition="above"
            [mdTooltip]="passport.rating.rating_recyclability > 0 ? ('PASSPORT_LIST_ITEM_CIRCULARITY_TOOLTIP' | translate: getCircularityTooltipValue()) : ''"
            [toxicityState]="passport.rating.rating_recyclability"></app-rating-recyclability-icon>
    </div>
    <div>
        <app-rating-energy-icon mdTooltipPosition="above"
            [mdTooltip]="passport.rating.rating_recyclability > 0 ? ('PASSPORT_LIST_ITEM_ENERGY_TOOLTIP' | translate: getEnergyTooltipValues()) : ''"
            [toxicityState]="passport.rating.rating_energy"></app-rating-energy-icon>
    </div>
    <div>
        <app-rating-water-stewardship-icon mdTooltipPosition="above"
            [mdTooltip]="passport.rating.rating_water_stewardship > 0 ? ('PASSPORT_LIST_ITEM_WATER_TOOLTIP' | translate) : ''"
            [toxicityState]="passport.rating.rating_water_stewardship"></app-rating-water-stewardship-icon>
    </div>
    <div>
        <app-rating-social-fairness-icon mdTooltipPosition="above"
            [mdTooltip]="passport.rating.rating_social_fairness > 0 ? ('PASSPORT_LIST_ITEM_SOCIAL_TOOLTIP' | translate) : ''"
            [toxicityState]="passport.rating.rating_social_fairness"></app-rating-social-fairness-icon>
    </div>
</div>