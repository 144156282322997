/**
 * Created by aleksandr on 7.08.17.
 */
import { IRoleContextConfig } from '../interfaces/role-context-config.interface';

export const GUEST_CONTEXT: IRoleContextConfig = {
  navigation: [],
  moduleName: 'guest',
  themeClass: 'b-module-default',
  roleId: 0,
  roleText: 'Guest',
  homeUrl: '/login',
  apiModuleName: 'guest',
  rootPath: 'login'
};
