import * as tslib_1 from "tslib";
import { DepositImport } from '../models/deposit-import.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var DepositImportService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositImportService, _super);
    function DepositImportService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'deposit-imports';
        _this.namespaceSingular = 'deposit-import';
        _this.ModelClass = DepositImport;
        return _this;
    }
    return DepositImportService;
}(ExtendedCrudService));
export { DepositImportService };
