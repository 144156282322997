/**
 * Created by Aleksandr C. on 7.02.17.
 */
import { EventEmitter, Injectable } from '@angular/core';
import {Response} from '@angular/http';
import { LoginInterface } from '../auth/login.interface';
import { Observable } from 'rxjs/Observable';
import { IUserInfo } from '../auth/user-info.interface';
import { USER_CONTEXTS } from '../auth/user-contexts.const';
import { IRoleContextConfig } from '../interfaces/role-context-config.interface';
import { DEFAULT_CONTEXT } from '../navigation/default.context';
import { CurrentUser } from '../auth/current-user.class';
import {ApiService} from './api.service';
import { ADMIN_ESM_OPTIONS } from '../values/role-ids.enum';

@Injectable()
export class CurrentUserService extends ApiService {

  redirectUrl: string;
  onLoggedInChecked$: EventEmitter<boolean> = new EventEmitter();
  currentUserDidChanged: EventEmitter<any> = new EventEmitter();

  get isLoggedIn(): boolean {
    return CurrentUser.isLoggedIn;
  }

  set isLoggedIn(value: boolean) {
    CurrentUser.isLoggedIn = value;
  }

  get isLoggedChecked(): boolean {
    return CurrentUser.isLoggedChecked;
  }

  set isLoggedChecked(value: boolean) {
    CurrentUser.isLoggedChecked = value;
  }

  get infoData(): IUserInfo {
    return CurrentUser.infoData;
  }

  set infoData(value: IUserInfo) {
    CurrentUser.infoData = value;
  }


  get loggedInChecked$(): Observable<boolean> {
    return this.isLoggedChecked
      ? Observable.of(this.isLoggedChecked)
      : this.onLoggedInChecked$.asObservable().first();
  }

  get roleName() {
    if (this.infoData && this.infoData.roles && this.infoData.roles.length > 0) {
      const special_index = this.infoData.roles.indexOf('special_admin');
      if (special_index !== -1) {
        return this.infoData.roles[special_index];
      }
      return this.infoData.roles[0];
    } else {
      return 'guest';
    }
  }
  
  get isSpecialAdmin() {
    return this.roleName === 'special_admin';
  }

  get userDefaultContext(): IRoleContextConfig {
    let roleName = this.roleName;
    return USER_CONTEXTS.find(
        context => context.apiModuleName === roleName || context.moduleName === roleName
      ) || DEFAULT_CONTEXT;
  }

  get isRestrictedAdmin() {
    return this.infoData.plan_id === ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN;
  }


  hasRoles(): boolean {
    return this.infoData && this.infoData.roles && this.infoData.roles.length > 0;
  }

  hasContext(roleName: string): boolean {
    return this.hasRoles() && this.infoData.roles.indexOf(roleName) > -1;
  }

  getContext(roleName: string) {
    return USER_CONTEXTS.find(
      context => context.apiModuleName === roleName || context.moduleName === roleName
    );
  }

  getAvailableContexts() {
    return this.hasRoles()
      ? USER_CONTEXTS.filter((context) => {
        return this.infoData.roles.indexOf(context.moduleName) > -1
          || this.infoData.roles.indexOf(context.apiModuleName) > -1;
      })
      : [];
  }

  login(data: LoginInterface) {
    console.log("Fichier de login 1");
    console.log(this.getEndpoint('user/login'));
    return this
      .sendPost(this.getEndpoint('user/login'), data)
      .do(() => {
        this.isLoggedIn = true;
      });
  }

  resetPassword(password:string, token:string) {
    return this
      .sendPost(this.getEndpoint('user/reset-password'), {password:password, token:token})
      .do(() => {
        console.log('email sent');
      });
  }

  requestPassword(username :string) {
    return this
      .sendPost(this.getEndpoint('user/request-password-reset'), { username: username })
      .do(() => {
        console.log('email sent');
      });
  }

  logout() {
    return this
      .sendPost(this.getEndpoint('user/logout'))
      .do(() => {
        localStorage.clear();
        this.isLoggedIn = false;
      });
  }

  roles() {
    return this.sendGet(this.getEndpoint('user/roles'));
  }

  info() {
    return this.sendGet(this.getEndpoint('user/info'));
  }

  isAuth(): Observable<boolean> {
    return this.info()
      .do((data) => this.setLoginData(data))
      .map((data) => true)
      .catch((err: any, caught: Observable<any>) => {
        console.log(err);
        return Observable.of(false);
      })
      .do((result: boolean) => {
        if (!result) {
          localStorage.removeItem('username');
        }
        this.isLoggedIn = result;
        this.isLoggedChecked = true;
        this.onLoggedInChecked$.emit(this.isLoggedChecked);
      });
  }

  setLoginData(data: Response) {
    this.infoData = data.json();
    console.log('setLoginData ', this.infoData);
    localStorage.setItem('username', this.infoData.username);
    localStorage.setItem('roles', JSON.stringify(this.infoData.roles));
    const language = this.infoData.language;
    if (language) {
      localStorage.setItem('language', language);
     }
    localStorage.setItem('avatar_url', JSON.stringify(this.infoData.avatar_url));
  }

  markAsLoggedIn() {
    this.isLoggedIn = true;
    this.isLoggedChecked = true;
    this.onLoggedInChecked$.emit(this.isLoggedChecked);
  }
  activate(password: string, token: string) {
    return this
      .sendPost(this.getEndpoint('user/newpw'), {password: password, token: token});
  }

  isUsernameStored(): boolean {
    return !!localStorage.getItem('username');
  }

}
