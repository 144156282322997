import { CanActivate, Router } from '@angular/router';
import { CurrentUserService } from '../../shared/services/current-user.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { Injectable } from '@angular/core';
import { ModuleGuard } from '../../shared/navigation/module.guard';

@Injectable()
export class SpecialAdminGuard extends ModuleGuard implements CanActivate {

  protected context: string = 'special_admin';

  constructor(protected _navigationService: NavigationService,
              protected _userService: CurrentUserService,
              protected _router: Router) {
    super();
    console.log('Launch SpecialadminGuard');
  }


}
