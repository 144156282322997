import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryService } from './service/category.service';
import { TreeViewComponent } from './partials/tree-view/tree-view.component';
import { TreeItemComponent } from './partials/tree-item/tree-item.component';
import { SharedModule } from '../../shared/shared.module';
import { TreeModule } from 'angular-tree-component';
import { CategorySelect } from './service/category-select.service';
import { SelectDialogComponent } from './dialog/select-dialog/select-dialog.component';
import { CategoryChangeDialogComponent } from './dialog/category-change-dialog/category-change-dialog.component';
import {TransformationCategoryService} from './service/transformation-category.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TreeModule
  ],
  declarations: [
    TreeViewComponent,
    TreeItemComponent,
    SelectDialogComponent,
    CategoryChangeDialogComponent
  ],
  providers: [
    CategoryService,
    CategorySelect,
    TransformationCategoryService
  ],
  exports: [
    TreeViewComponent
  ],
  entryComponents: [
    SelectDialogComponent,
    CategoryChangeDialogComponent
  ]
})
export class CategoryModule {
}
