import * as tslib_1 from "tslib";
import { TransformFormatJunctionService } from '../services/transform-format-junction.service';
import { ActiveRecord } from '../../../shared/models/active-record.class';
var TransformFormatJunction = /** @class */ (function (_super) {
    tslib_1.__extends(TransformFormatJunction, _super);
    function TransformFormatJunction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformFormatJunctionService;
        return _this;
    }
    TransformFormatJunction.prototype.fields = function () {
        return [
            'id',
            'format_id',
            'transformation_id',
            'is_input',
            'name',
        ];
    };
    return TransformFormatJunction;
}(ActiveRecord));
export { TransformFormatJunction };
