<article class="notification-success">
  <section class="notification-success__image">
    <svg-icon [src]="getEndpoint('assets/svg/done_circle_icon.svg')" class="primary-color">
    </svg-icon>
  </section>
  <section class="notification-success__text">
    {{message | translate}}
  </section>
  <section class="notification-success__button-group">
    <button (click)="mdDialogRef.close()"
      class="notification-success__button-close background-primary-color">
      {{closeButtonText | translate}}
    </button>
  </section>
</article>
