import { IModuleNavigation } from '../../shared/interfaces/module-navigation.interface';

export const DEPOSIT_OWNER_NAVIGATION: IModuleNavigation[] = [
  // {
  //   url: '/deposit-owner/statistics',
  //   title: 'Statistics',
  //   icon: 'statistic--icon'
  // },
  {
    url: '/deposit-owner/deposits',
    title: 'Deposits list',
    icon: 'my-deposits--icon'
  },
  {
    url: '/deposit-owner/sites',
    title: 'Sites',
    icon: 'icon--sites'
  },
];
