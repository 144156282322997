import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Passport } from "../../../../entities/passport/models/passport.class";
import { NavbarStateService } from "../../../../shared/services/navbar.service";
import { Utils } from "../../../../shared/utils/utils.class";
import { NavigationService } from "../../../../shared/services/navigation.service";
import { Router } from '@angular/router';
import { LogNotificationService } from "../../../../shared/services/log-notification.service";
import { CurrentUserService } from "../../../../shared/services/current-user.service";
import { PASSPORT_STATUS_CONST } from "../../../../entities/passport/values/passport-status.const";
import { TranslateService } from "@ngx-translate/core";

/**
 * `NewPassportItemComponent` is responsible for displaying individual passport items.
 * It handles the display logic, selection, and navigation for a single passport. The component
 * allows for conditional selection based on `isSelectable` property, emits changes in selection,
 * and supports multi-language through dynamic name retrieval.
 */
@Component({
    moduleId: module.id,
    selector: 'new-passport-item',
    templateUrl: 'new-passport-item.component.html',
    styleUrls: ['new-passport-item.component.scss']
})
export class NewPassportItemComponent {

    @Input() passport: Passport; // Passport data for display.
    @Input() isSelected: boolean = false; // Selection state of the passport item.
    @Input() isSelectable: boolean = true; // Whether the passport item can be selected.
    @Input() isVersionSelected: boolean = false; // Whether the passport item is selected for version filter.
    @Input() displayStatus: boolean = true; // Whether to display the status of the passport.
    @Input() actionButtonsConfig: { duplicate: boolean, copyId: boolean, delete: boolean, version: boolean } = { duplicate: true, copyId: true, delete: true, version: true };
    @Output() isSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>(); // Event emitter for selection state changes.
    @Output() deleteRequest: EventEmitter<Passport> = new EventEmitter<Passport>(); // Event emitter for delete passport.
    @Output() versionClicked: EventEmitter<Passport> = new EventEmitter<Passport>(); // Event emitter for version filter.
    @Output() duplicateRequest: EventEmitter<Passport> = new EventEmitter<Passport>(); // Event emitter for duplicate passport.
    user_id: number = null;
    constructor(private notification: LogNotificationService, private filterState: NavbarStateService,
        private _router: Router, private _currentUserService: CurrentUserService,
        private _navigation: NavigationService, private translateService: TranslateService) {
        this.user_id = this._currentUserService.infoData.id;
    }

    /**
     * Retrieves the passport's name, considering multi-language settings.
     * @returns The name of the passport.
     */
    getPassportName(): string {
        return this.passport.getProductName(this.filterState.multilanguage);
    }

    /**
     * Concatenates all nomenclature names associated with the passport into a single string.
     * @returns A string of nomenclature names, separated by commas.
     */
    getNomenclatureNames(): string {
        return this.passport.nomenclature.map(n => n.name).join(', ');
    }

    getStatus(): string {
        const statusKey = Object.keys(PASSPORT_STATUS_CONST).find(key =>
            PASSPORT_STATUS_CONST[key].value === this.passport.status
        );
        return statusKey ? PASSPORT_STATUS_CONST[statusKey].view : 'Status not found';
    }

    /**
     * Formats the modified date of the passport for display.
     * @param date The date string to format.
     * @returns A formatted date string or an empty string if the date is null.
     */
    formatPassportModifiedDate(date: string): string {
        if (date === null) return '';
        return Utils.formatDate(date);
    }

    /**
     * Toggles the selection state of the passport item if selectable.
     */
    toggleState(): void {
        if (!this.isSelectable) return;
        this.isSelected = !this.isSelected;
        this.isSelectedChange.emit(this.isSelected);
    }

    /**
     * Emits a delete request for the passport.
     */
    onDeletePassport(): void {
        this.deleteRequest.emit(this.passport);
    }

    /**
     * Emits a duplicate request for the passport.
     */
    onDuplicatePassport(): void {
        this.duplicateRequest.emit(this.passport);
    }

    /**
     * Fallback for image load errors, setting a default image.
     * @param event The DOM event for the error.
     */
    handleImageError(event: any): void {
        event.target.src = 'assets/png/ImageNotFound.png';
    }

    /**
     * Navigates to the detailed view of the passport.
     */
    navigateToPassport(): void {
        if (this.passport && this.passport.id) {
            let parentModuleName = this._navigation.getModuleName();
            this._router.navigate([`${parentModuleName}/passports`, this.passport.id]);
        }
    }

    /**
     * Copies the passport's id to the clipboard and displays a notification.
     * If the id is not set, an error notification is displayed.
     */
    copyPassportIdToClipboard(): void {
        if (this.passport.product_passport_id) {
            const msg = 'Id copied';
            Utils.copyToClipBoard(this.passport.product_passport_id);
            this.notification.success(msg, 2000, true);
        } else {
            const msg = 'Id not yet set';
            this.notification.error(msg, 2000, true);
        }
    }

    onVersionClick(): void {
        this.versionClicked.emit(this.passport);
    }

    /**
     * Determines the CSS class for coloring pagination based on the user's role.
     * 
     * @returns The CSS class corresponding to the user's role.
     */
    getClassColor(): string {
        let role = this._navigation.getContext().roleText;
        switch (role) {
            case 'Agent':
                return 'role-agent';
            case 'Manufacturer':
                return 'role-manufacturer';
            case 'Specialadmin':
                return 'role-specialadmin';
            default:
                return '';
        }
    }

    getTagText(): string {
        if (!this.passport.synchronization_data) {
            return '';
        }
        const { synchronization_data } = this.passport;
        const parts = [];
        const hasRelevantData = synchronization_data.epdType || synchronization_data.source || synchronization_data.operator;
        if (hasRelevantData && this.passport.haveCarbonData()) {
            const epdTypeName = this.translateService.instant(synchronization_data.getShortEpdTypeName());
            const sourceName = this.translateService.instant(synchronization_data.getSourceName());
            const operatorName = this.translateService.instant(synchronization_data.getOperatorName());
            const operatorSourceMismatch = operatorName.toUpperCase() !== sourceName.toUpperCase();
            parts.push(epdTypeName);
            parts.push(sourceName);
            if (operatorSourceMismatch) {
                parts.push(operatorName);
            }
        }

        if (synchronization_data.getLanguage() && this.passport.haveCarbonData()) {
            parts.push(synchronization_data.getLanguage());
        }

        if (!this.passport.haveCarbonData()) {
            parts.push(this.translateService.instant('noCarbonData'));
        }

        return parts.join(' - ');
    }

    isWaitingForRating(): boolean {
        return this.passport.for_rating === 1;
    }

}
