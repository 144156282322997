import { MdDialogRef } from '@angular/material';
import { ImportSuccessReport } from '../../models/import-success-summary.class';
import { EConfirmDialogIcon } from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import { TranslateService } from '@ngx-translate/core';
var DepositImportSuccessComponent = /** @class */ (function () {
    function DepositImportSuccessComponent(mdDialogRef, mdDialogData, translateService) {
        this.mdDialogRef = mdDialogRef;
        this.mdDialogData = mdDialogData;
        this.translateService = translateService;
        this.iconLogo = {
            iconType: EConfirmDialogIcon.SVG,
            showIconURL: 'assets/svg/done_import_circle_icon.svg',
            iconClass: 'deposit-import-success__logo'
        };
        this.iconCreate = {
            iconType: EConfirmDialogIcon.MD,
            showIconText: 'add',
            primaryFill: false,
            iconClass: 'deposit-import-success__row-left-part background-primary-color'
        };
        this.iconUpdated = {
            iconType: EConfirmDialogIcon.MD,
            showIconText: 'cached',
            primaryFill: false,
            iconClass: 'deposit-import-success__row-left-part deposit-import-success__row-left-part--rotate-s background-primary-color'
        };
        this.iconWarning = {
            iconType: EConfirmDialogIcon.SVG,
            showIconURL: 'assets/svg/warning--icon.svg',
            iconClass: 'deposit-import-success__row-left-part'
        };
        this.report = mdDialogData.report || new ImportSuccessReport();
        console.log(this.report);
    }
    DepositImportSuccessComponent.prototype.getNumber = function (text) {
        return Number(text);
    };
    DepositImportSuccessComponent.prototype.translate = function (text, data) {
        if (data === void 0) { data = {}; }
        return this.translateService.instant(text, data);
    };
    Object.defineProperty(DepositImportSuccessComponent.prototype, "passportCreateText", {
        get: function () {
            switch (this.report.passportsCreated.length) {
                case 0: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_PASSPORT_CREATED_NONE');
                case 1: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_PASSPORT_CREATED_ONE');
                default: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_PASSPORT_CREATED', this.report.passportsCreated.length);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositImportSuccessComponent.prototype, "depositCreateText", {
        get: function () {
            switch (this.report.depositsCreated.length) {
                case 0: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_CREATED_NONE');
                case 1: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_CREATED_ONE');
                default: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_CREATED', { X: this.report.depositsCreated.length });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositImportSuccessComponent.prototype, "depositUpdatedText", {
        get: function () {
            switch (this.report.depositsUpdated.length) {
                case 0: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_UPDATED_NONE');
                case 1: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_UPDATED_ONE');
                default: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_UPDATED', { X: this.report.depositsUpdated.length });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositImportSuccessComponent.prototype, "depositWithVoidCreatedText", {
        get: function () {
            switch (this.report.depositsVoided.length) {
                case 1: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_VOIDED_ONE');
                default: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_VOIDED', { X: this.report.depositsVoided.length });
            }
        },
        enumerable: true,
        configurable: true
    });
    return DepositImportSuccessComponent;
}());
export { DepositImportSuccessComponent };
