import { pairwise, startWith } from 'rxjs/operators';
export function proceedIfNonNegativeInt(event) {
    var specialKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    var clipboardSpecialKeyCodes = [86, 67, 88];
    if (specialKeys.some(function (key) { return key === event.key; }) || !!event.key.match(/[0-9]/)
        || (clipboardSpecialKeyCodes.some(function (keyCode) { return keyCode === event.keyCode; }) && (event.ctrlKey || event.metaKey))) {
        return;
    }
    event.preventDefault();
    event.stopPropagation();
}
export function pasteOnlyIfNonNegativeInt(event) {
    if (event.clipboardData.getData('text').match(/^[0-9]*$/)) {
        return;
    }
    event.preventDefault();
    event.stopPropagation();
}
export function allowOnlyPositiveNumbers(form, options) {
    var onlyInt = (options && options.onlyInt) || false;
    return form.valueChanges.pipe(startWith(form.value), pairwise())
        .subscribe(function (_a) {
        var before = _a[0], value = _a[1];
        if (checkIfInvalid(value) && !checkIfInvalid(before)) {
            form.setValue(before);
        }
        else if (onlyInt && !Number.isInteger(Number(value))) {
            form.setValue(Math.round(Number(value)));
        }
    });
}
function checkIfInvalid(val) {
    var numericVal = Number(val);
    var isInvalidVal = val && !val.toString().match(/^\d*[,.]?\d*$/);
    return isInvalidVal || numericVal < 0;
}
