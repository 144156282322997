/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { ICrud } from '../interfaces/crud.interface';
import {ILiteral} from 'ng2-adn-common';

@Injectable()
export abstract class CrudService extends ApiService implements ICrud {
  protected abstract namespace: string;
  protected namespaceSingular: string;
  protected abstract ModelClass: any;

  relations: string[] = [];

  public get singularEndpoint(): string {
    return this.namespaceSingular ? this.namespaceSingular : this.namespace;
  }

  public get<T>(query?: any): Observable<T[]> {
    return this
      .sendGet(this.getEndpoint(this.namespace), {search: query})
      .map((res: Response) => res.json().map((item: T) => this.buildModel<T>(item)))
      .catch(this.onError.bind(this));
  }

  public getSingular<T>(query?: any): Observable<T[]> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint), {search: query})
      .map((res: Response) => res.json().map((item: T) => this.buildModel<T>(item)))
      .catch(this.onError.bind(this));
  }

  public getResponse(query?: any): Observable<any | Response> {
    return this
      .sendGet(this.getEndpoint(this.namespace), {search: query})
      .catch(this.onError.bind(this));
  }

  public getWithArray(query?: any): Observable<any | Response> {
    return this
      .sendGetWithArray(this.getEndpoint(this.namespace), query)
      .catch(this.onError.bind(this));
  }

  public postWithArray(data, query?: any): Observable<any | Response> {
    return this
      .sendPostWithArray(this.getEndpoint(this.namespace),data, query)
      .catch(this.onError.bind(this));
  }

  public view<T>(id: number, query?: any): Observable<any | T> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '/' + id), {search: query})
      .map((res: Response) => this.buildModel<T>(res.json()))
      .catch(this.onError.bind(this));
  }

  public create<T>(data: T): Observable<any | T> {
    return this
      .sendPost(this.getEndpoint(this.namespace), data)
      .map((res: Response) => this.buildModel<T>(res.json()))
      .catch(this.onErrorTranslation.bind(this));
  }

  public update<T>(id: number, data: T): Observable<any | T> {
    return this
      .sendPut(this.getEndpoint(this.namespace + '/' + id), data)
      .map((res: Response) => this.buildModel<T>(res.json()))
      .catch(this.onError.bind(this));
  }

  public remove<T>(id: number): Observable<any | T> {
    return this
      .sendDelete(this.getEndpoint(this.singularEndpoint + '/' + id))
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

  public duplicate<T>(id: number, data?: any, isDraftNeed?: boolean): Observable<any | T> {
    const state = isDraftNeed ? '?status=1' : '';
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/copy' + state), data)
      .map((res: Response) => this.buildModel<T>(res.json()))
      .catch(this.onError.bind(this));
  }

  public archive<T>(id: number): Observable<any | T> {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/archive'), {})
      .map((res: Response) => this.buildModel<T>(res.json()))
      .catch(this.onError.bind(this));
  }

  public search<T>(query?: any): Observable<T[]> {
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '/list'), {search: query})
      .map((res: Response) => res.json().map((item: T) => this.buildModel<T>(item)))
      .catch(this.onError.bind(this));
  }

  public buildModel<T>(data: any): T {
    return new this.ModelClass(data);
  }

  public formQueryString(query: ILiteral): string {
    return Object.keys(query).filter((key) => query[key] !== null).map((key) => {
      if (Array.isArray(query[key])) {
        return query[key].map((value) => key+'[]='+value).join('&');
      } else {
        return key+'='+query[key];
      }}).join('&');
  }
}
