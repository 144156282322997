/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i2 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i3 from "../../partials/transform-details/transform-details.component.ngfactory";
import * as i4 from "../../../../shared/read-only/read-only.service";
import * as i5 from "../../partials/transform-details/transform-details.component";
import * as i6 from "../../../../entities/transformation/services/transform-file.service";
import * as i7 from "../../../../entities/transformation/services/transform.service";
import * as i8 from "../../../../shared/services/navigation.service";
import * as i9 from "@angular/forms";
import * as i10 from "../../../../shared/services/log-notification.service";
import * as i11 from "@angular/router";
import * as i12 from "@angular/material";
import * as i13 from "@ngx-translate/core";
import * as i14 from "../../../../entities/category/service/transformation-category.service";
import * as i15 from "../../../../entities/category/service/category.service";
import * as i16 from "../../../../shared/services/translation.service";
import * as i17 from "./transform-details-dialog.component";
var styles_TransformDetailsDialogComponent = [];
var RenderType_TransformDetailsDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TransformDetailsDialogComponent, data: {} });
export { RenderType_TransformDetailsDialogComponent as RenderType_TransformDetailsDialogComponent };
export function View_TransformDetailsDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DialogCloseButtonComponent_0, i1.RenderType_DialogCloseButtonComponent)), i0.ɵdid(1, 49152, null, 0, i2.DialogCloseButtonComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "transform-details", [], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TransformDetailsComponent_0, i3.RenderType_TransformDetailsComponent)), i0.ɵprd(512, null, i4.ReadOnlyService, i4.ReadOnlyService, []), i0.ɵdid(4, 114688, null, 0, i5.TransformDetailsComponent, [i6.TransformFileService, i4.ReadOnlyService, i7.TransformService, i8.NavigationService, i9.FormBuilder, i10.LogNotificationService, i11.Router, i12.MdDialog, i13.TranslateService, i14.TransformationCategoryService, i15.CategoryService, i16.TranslationService], { id: [0, "id"], readOnly: [1, "readOnly"], showValidationButtons: [2, "showValidationButtons"] }, { action: "action" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.mdDialogData.id; var currVal_2 = _co.mdDialogData.readOnly; var currVal_3 = _co.mdDialogData.showValidationButtons; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).applyClassPosition; _ck(_v, 0, 0, currVal_0); }); }
export function View_TransformDetailsDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [["class", "dialog-close-button-container"]], null, null, null, View_TransformDetailsDialogComponent_0, RenderType_TransformDetailsDialogComponent)), i0.ɵdid(1, 49152, null, 0, i17.TransformDetailsDialogComponent, [i12.MD_DIALOG_DATA, i12.MdDialogRef], null, null)], null, null); }
var TransformDetailsDialogComponentNgFactory = i0.ɵccf("ng-component", i17.TransformDetailsDialogComponent, View_TransformDetailsDialogComponent_Host_0, {}, {}, []);
export { TransformDetailsDialogComponentNgFactory as TransformDetailsDialogComponentNgFactory };
