import {Component, OnInit, Input, ViewChild, EventEmitter, Output, AfterViewChecked} from '@angular/core';
import {DepositOrderService} from '../../../ecosystem-manager/services/deposit-order.service';
import {DepositOrder} from '../../../ecosystem-manager/models/deposit-order.class';
import {EcosystemFileJunction} from '../../../ecosystem-manager/models/ecosystem-file-junction.class';
import {EcosystemFileJunctionService} from '../../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import {EcosystemFile} from '../../../ecosystem-manager/models/ecosystem-file.class';
import {ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE} from '../../../ecosystem-manager/values/ecosystem-file-junction-item-type.const';
import {EcosystemFileService} from '../../../../entities/ecosystem/services/ecosystem-file.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {EcosystemService} from '../../../../entities/ecosystem/services/ecosystem.service';
import {
  EOrderTransactionConfirmResultFieldType
} from '../../../../shared/convert-components/order-transaction-confirm-result-field/order-transaction-confirm-result-field-type.enum';
import {EcosystemFileToJunctionService} from '../../../../entities/ecosystem/services/ecosystem-file-to-junction.service';
import {UploadedDocument} from '../../../../shared/models/uploaders/document.class';
import {RequestFileSenderService} from '../../../admin/services/request-file-sender.service';
import {Observable} from 'rxjs/Observable';
import {mergeAll, mergeMap} from 'rxjs/operators';


@Component({
  selector: 'deposit-order-transaction-confirmation',
  templateUrl: './deposit-order-transaction-confirmation.component.html',
  styleUrls: ['./deposit-order-transaction-confirmation.component.scss']
})
export class DepositOrderTransactionConfirmationComponent implements OnInit, AfterViewChecked {
  RESULT_FIELD_TYPE = EOrderTransactionConfirmResultFieldType;
  @Input() id: number;
  @Input() step_id: number;
  @Input() disabled = false;
  @Input() sendDocumentsListener;
  @Input() showESMText = false;
  @Output() onConfirm = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onFileUploadingFail = new EventEmitter();
  @Output() onFileUploadingComplete = new EventEmitter();
  @ViewChild('description') descriptionTextarea;
  order: DepositOrder;
  allFiles: EcosystemFile[] = [];
  filesWithJunction: EcosystemFileJunction[] = [];
  form: FormGroup;
  private textAreaIsAutosize = false;

  constructor(
    public ecosystemService: EcosystemService
    , public ecosystemFileService: EcosystemFileService
    , public requestFileSenderService: RequestFileSenderService
    , private ecosystemJunctionService: EcosystemFileJunctionService
    , private depositOrderService: DepositOrderService
    , private fb: FormBuilder
    , private notification: LogNotificationService
    , private translateService: TranslateService
    , private ecosystemFileToJunctionService: EcosystemFileToJunctionService
  ) {}

  ngOnInit() {
    if (this.id) {
      this.loadById();
    } else if (this.step_id) {
      this.loadByStep();
    }
  }

  private loadById() {
    this.depositOrderService.view(this.id, {expand: this.commonRequestExpand()})
      .subscribe((order: DepositOrder) => {
        this.initOrder(order);
        this.loadFiles();
      });
  }

  private commonRequestExpand() {
    return 'order_passport,deposit_passport,ecosystem,ecosystem_step,ecosystem_file_junction' + (this.disabled ? ',deposit' : '');
  }

  private initOrder(order: DepositOrder) {
    this.order = order;
    if (!this.disabled) {
      this.resetTransactionConfirmation();
    }
    this.initForm();
  }

  private loadFiles() {
    this.ecosystemJunctionService.get(
    {item_id: this.order.id, item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_DEPOSIT_ORDER_TRACEABILITY}
    ).subscribe((junctions: EcosystemFileJunction[]) => {
      this.filesWithJunction = junctions;
      this.allFiles = junctions.map(junction => junction.ecosystem_file);
    });
  }

  private loadByStep() {
    this.depositOrderService.get({step_id: this.step_id, expand: this.commonRequestExpand()}).subscribe(
      (orders: DepositOrder[]) => {
        if (orders)  {
          this.initOrder(orders[0]);
          this.loadFiles();
        } else {
          this.notification.error('No result found', null, true);
        }
      });
  }

  private initForm() {
    this.form = this.fb.group({
      quantity: [{value:
          this.transactionConfirmValue(this.order.order_passport.transaction_quantity, this.order.order_passport.quantity)
        , disabled: this.disabled}
        , this.requireWithFieldName('Quantity')],
      date: [{value:
          this.transactionConfirmValue(this.order.order_passport.transaction_date, this.order.order_passport.linked_datetime)
        , disabled: this.disabled}
        , [this.requireWithFieldName('Pickup date'),
        (control) => moment(control.value, 'DD-MM-YYYY').isValid() ? null : {invalidDateFormat: 'Invalid reception date'}
      ]],
      price: [{value: this.transactionConfirmValue(this.order.order_passport.transaction_price, this.order.price), disabled: this.disabled}
        , this.requireWithFieldName('Price')],
    });
  }

  private transactionConfirmValue(confirm, defaultValue) {
    return this.disabled ? (confirm || (confirm === 0 ? 0 : defaultValue)) : '';
  }

  private resetTransactionConfirmation() {
    this.order.order_passport.transaction_quantity = null;
    this.order.order_passport.transaction_date = null;
    this.order.order_passport.transaction_price = null;
  }

  private requireWithFieldName(message) {
    return (control) => {
      if (Validators.required(control)) {
        return {required: `${this.translateService.instant(message)} ${this.translateService.instant('is required')}`};
      }
      return null;
    };
  }

  ngAfterViewChecked() {
    if (!this.textAreaIsAutosize && this.order && this.order.description) {
      this.textAreaIsAutosize = true;
      // setTimeout(() => setTextareaHeightToFitContent([this.descriptionTextarea]));
    }
  }

  createFileJunctions(uploadedFiles: EcosystemFile[]) {
    const junctionService = this.ecosystemFileToJunctionService;
    const callback = (data) => this.requestFileSenderService.documentUploadObservableNext(data.files, !!data.failed.length);
    junctionService.updateFilesAndJunctions(
      {entity: this.order, type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_DEPOSIT_ORDER_TRACEABILITY}
      , uploadedFiles, this.filesWithJunction, callback
    );
  }

  deleteOnlyLinkIfUploaded() {
    return (file: UploadedDocument) => {
      return !this.ecosystemFileToJunctionService
        .deleteLinkInsteadIfExist(file as EcosystemFile, this.filesWithJunction, this.resetFiles());
    };
  }

  private resetFiles() {
    return (junction: EcosystemFileJunction) => {
      const {files, junctions} = this.ecosystemFileToJunctionService.removeJunction(junction, this.allFiles, this.filesWithJunction);
      this.allFiles = files;
      this.filesWithJunction = junctions;
    };
}

  confirmOrderTransaction() {
    if (this.form.enabled) {
      Object.values(this.form.controls).forEach(control => control.markAsTouched());
      if (this.form.valid) {
        this.order.order_passport.transaction_date =
          moment(this.form.controls['date'].value, ['YYYY-DD-MM', 'DD-MM-YYYY']).format('YYYY-MM-DD');
        this.onConfirm.emit(this.order.order_passport.save().pipe(mergeMap(() => this.order.confirm())));
      } else {
        this.notification.error(this.getFirstErrorMessage(), null, true);
      }
    }
  }

  private getFirstErrorMessage() {
    return 'You must fill in all the fields on the right to confirm the data';
  }
}
