import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import { EChatUserStatuses, IChatUser, IChatUserGroup } from '../chat/interfaces/chat-user.interface';
import { ChatService } from '../chat/chat.service';
import { IChatRoom } from '../chat/interfaces/chat-room.interface';
import { NavigationService } from '../services/navigation.service';
import { ChatUser } from '../chat/models/chat-user.model';
import { ERoleIds } from '../values/role-ids.enum';
import { ChatRoom } from '../chat/models/chat-room.model';
import { ChatMessage } from '../chat/models/chat-message.model';
import { GlobalEventsManager } from '../services/global-events-manager.service';
import { ChatData } from '../chat/models/chat-data.class';
import { Subscription } from 'rxjs/Subscription';
import { CustomLogger } from '../custom-logger.class';
import {FormControl} from "@angular/forms";
import {TranslatePipe} from "@ngx-translate/core";

@Component({
  moduleId: module.id,
  providers:[TranslatePipe],
  selector: 'common-sidebar',
  templateUrl: 'common-sidebar.component.html'
})


export class CommonSidebarComponent implements OnInit, OnDestroy {
  get contacts(): ChatUser[] {
    return ChatData.contacts;
  }

  set contacts(value: ChatUser[]) {
    ChatData.contacts = value;
  }

  get groups(): IChatUserGroup[] {
    return ChatData.groups;
  }

  set groups(value: IChatUserGroup[]) {
    ChatData.groups = value;
  }

  get selectedContact(): IChatUser {
    return ChatData.selectedContact;
  }

  set selectedContact(value: IChatUser) {
    ChatData.selectedContact = value;
  }

  get selectedChatroom(): IChatRoom {
    return ChatData.selectedChatroom;
  }

  set selectedChatroom(value: IChatRoom) {
    ChatData.selectedChatroom = value;
  }

  @Input() show: boolean;
  public isOpen: boolean = true;
  public contactStatuses = EChatUserStatuses;

  public listenSubscription: Subscription;

  public supportContact: ChatUser;

  private logger = new CustomLogger<this>(this, 'CommonSidebar');
  search = new FormControl();


  constructor(private _chatService: ChatService,
              private _navigationService: NavigationService,
              private _globalEvents: GlobalEventsManager,
              private translatePipe: TranslatePipe,
              ) {

  }

  ngOnInit() {
    let context = this._navigationService.getContext();
    if (context) {
      this.setActiveContextData(context.moduleName);
    }
    this._navigationService
      .changeModule
      .distinctUntilChanged()
      .subscribe((moduleKey: string) => {
        this.setActiveContextData(moduleKey);
      });

    this._globalEvents
      .chatUserClicked$
      .subscribe((userId: number) => this.onExternalUserSelected(userId));

    this.search.valueChanges.debounceTime(400).distinctUntilChanged().do((value:string)=>console.log(value)).subscribe((value:string)=>this.setVisibility(value));
  }

  setVisibility(value:string){
    this.contacts.forEach((contact:ChatUser)=>{
     let isUsernameMatched = ~contact.contact_username.toLowerCase().indexOf(value.toLowerCase());
     let isContactNameMatched = ~contact.contact_name.toLowerCase().indexOf(value.toLowerCase());
     contact.visible = !!(!value || isUsernameMatched || isContactNameMatched);
    })
  }

  setActiveContextData(moduleKey: string) {
    this.closeChat();
    let isGuest = !Boolean(this._navigationService.roleId);

    this.logger.log('GuestMode', isGuest, this._navigationService.roleId);
    if (isGuest) {
      this.onContactsLoaded([]);
    } else {
      this
        ._chatService
        .getMyThreads()
        .flatMap(() => this._chatService.getContacts(this._navigationService.roleId)
          .do((contacts) => this.onContactsLoaded(contacts)))
        .flatMap(() => this._chatService.getSupportContact(this._navigationService.roleId)
          .do((contact) => this.onSupportContactLoaded(contact)))
        .subscribe();
    }
  }

  onExternalUserSelected(userId: number) {
    let user = this._chatService.contacts.find((user: ChatUser) => Number(user.id) === Number(userId));
    if (user) {
      this.openChat(user);
    } else {
      this.logger.warn('User id:', userId, 'not found', this._chatService.contacts);
    }
  }

  openChat(contact: ChatUser) {
    this.logger.log('openChat', contact);
    this.closeChat();
    if (contact) {
      contact.selected = true;
      this.selectedContact = contact;
      let room = contact.getRoom();
      if (room) {
        this.activateRoom(room);
      } else {
        contact.createRoom()
          .subscribe((room) => this.activateRoom(room));
      }
    }
  }

  activateRoom(room: ChatRoom) {
    room
      .loadContacts()
      .flatMap(() => room.loadMessages())
      .subscribe(() => {
        this._chatService.onRoomChanged(room);
        this.selectedChatroom = room;
        room.readRoom();
        // setTimeout(()  => {
        //   let messageListRef=document.getElementById("messageList");
        //   console.log("label", messageListRef);
        //   console.dir("label", messageListRef);
        //   messageListRef.scrollTop = messageListRef.scrollHeight;
        // }, 200);
    });
  }

  closeChat() {
    if (this.selectedContact) this.selectedContact.selected = false;
    this.selectedContact = null;
  }

  groupToggle(key: string) {
    let target = this.getGroup(key);
    if (target) {
      target.isOpen = !target.isOpen;
    }
  }

  getGroup(key: string): IChatUserGroup {
    return this.groups.find((group: IChatUserGroup) => group.name === key);
  }

  listenForNewChats() {
    if (this.listenSubscription) {
      this.listenSubscription.unsubscribe();
    }
    this.listenSubscription = this._chatService
      .messageReceived$
      .subscribe((message: ChatMessage) => this.onNewMessageReceived(message));
  }

  ngOnDestroy(): void {
    if (this.listenSubscription) {
      this.listenSubscription.unsubscribe();
    }
  }

  getGroupKey(groupId: number) {
    let key: string = 'Unknown';
    switch (groupId) {
      case ERoleIds.ADMIN:
        key = 'Admins';
        break;
      case ERoleIds.AGENT:
        key = 'Agents';
        break;
      case ERoleIds.DEPOSIT_OWNER:
        key = 'Deposit owners';
        break;
      case ERoleIds.ECOSYSTEM_MANAGER:
        key = 'Ecosystem managers';
        break;
      case ERoleIds.SALES_MANAGER:
        key = 'Need managers';
        break;
      case ERoleIds.SUPERVISOR:
        key = 'Supervisors';
        break;
      case ERoleIds.TRANSFORMATION_SITE_MANAGER:
        key = 'Transformation managers';
        break;
      case ERoleIds.TRANSFORMATION_SITE_MANAGER_INDEPENDENT:
        key = 'Transformation managers independent';
        break;
      case ERoleIds.MANUFACTURER:
        key = 'Manufacturers';
        break;
      case ERoleIds.SPECIALADMIN:
        key = 'Special admins';
        break;
    }
    return key;
  }

  addNewContact(contact: ChatUser) {
    this.contacts.push(contact);
    let target = ChatData.threads.find((thread: ChatRoom) => thread.id === contact.thread_id);
    if (!target && contact.getRoom()) {
      ChatData.threads.push(contact.getRoom());
    }
    if (contact.isContextChat()) {
      this.addContextChat(contact);
    } else {
      this.addRegularChat(contact);
    }
  }

  addRegularChat(contact: ChatUser) {
    let key = this.getGroupKey(contact.group);
    let target = this.getGroup(key);
    if (target) {
      target.contacts.push(contact);
    } else {
      this.groups.push({name: key, isOpen: true, contacts: [contact]});
    }
  }

  addContextChat(contact: ChatUser) {
    let target = this.getGroup(contact.ecosystem_name);
    if (target) {
      target.contacts.push(contact);
    } else {
      this.groups.push({name: contact.ecosystem_name, isOpen: true, contacts: [contact]});
    }
  }

  onNewMessageReceived(message: ChatMessage) {

    let findContactPredicate = (contact: ChatUser) => {
      return contact.thread && (Number(contact.thread.id) === Number(message.roomId));
    };

    let target = this.contacts.find(findContactPredicate);
    if (!target) {
      this.logger.log(
        'Cant find thread #', Number(message.roomId),
        'for message', message.body,
        'data', message,
        this.contacts.map((contact) => `${contact.name} [${contact.thread_id}]`).join(',')
      );
      this._chatService
        .getContacts(this._navigationService.roleId)
        .subscribe((contacts: ChatUser[]) => {
          let target = contacts.find(findContactPredicate);
          if (target) {
            this.logger.log('Found new chat for message', message, target);
            this.addNewContact(target);
          }
        });
    }
  }

  onContactsLoaded(contacts: ChatUser[]): void {
    this.contacts.forEach((contact) => contact.exitRoom());
    this.contacts.length = 0;
    this.groups.length = 0;

    contacts
      .filter((contact: ChatUser) => contact.id !== ChatData.ownerId)
      .forEach((contact: ChatUser) => {
        this.setRoom(contact);
        this.contacts.push(contact);
      });

    let regularChats = this.contacts.filter((contact) => !contact.isContextChat());
    let contextChats = this.contacts.filter((contact) => contact.isContextChat());
    regularChats.forEach((contact) => this.addRegularChat(contact));
    contextChats.forEach((contact) => this.addContextChat(contact));
    this.logger.log('Chat CONTACTS IS', this.contacts);
    this.logger.log('Chat GROUPS IS', this.groups);
    this.listenForNewChats();
  }

  onSupportContactLoaded(contact: ChatUser): void {
    if (!!contact) {
      contact.exitRoom();
      this.setRoom(contact);
      contact.name=this.translatePipe.transform(contact.name);

      this.supportContact = contact;
      this.logger.log('Chat SUPPORT', this.supportContact);
      this.listenForNewChats();
    }
  }

  setRoom(contact: ChatUser): void {
    if (contact.thread_id) {
      let target = ChatData.threads.find((thread: ChatRoom) => thread.id === contact.thread_id);
      if (target) {
        contact.setRoom(target).joinRoom();
      } else {
        this.logger.warn(contact, '- Thread #', contact.thread_id, 'not found');
      }
    } else {
      this.logger.warn(contact, 'has no any threads yet');
    }
  }



  isVisible(contact:ChatUser):boolean{
    return contact.visible;
  }
}
