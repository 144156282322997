import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MdDialogRef} from '@angular/material';
import moment from 'moment';
import {FileUploader} from 'ng2-file-upload';
import {RequestFileService} from '../../services/request-file.service';
import {RequestFileSenderService} from '../../services/request-file-sender.service';
import {ISiteRequestParamsDateChanged} from '../../interfaces/site-request-params/site-request-params-date-change.interface';
import {ESiteRequestType} from '../../values/site-request-type.enum';

@Component({
  selector: 'request-site-extend-date',
  templateUrl: './request-site-extend-date.component.html',
  styleUrls: ['./request-site-extend-date.component.scss'],
  providers: [{provide: RequestFileSenderService, useClass: RequestFileSenderService}]

})
export class RequestSiteExtendDateComponent implements OnInit, AfterViewInit {
  public endDateAtTheMoment: moment.Moment;
  endDateRequest: string;
  fileUploader = new FileUploader({});
  private siteId: number;
  @ViewChild('datePicker') datePicker: ElementRef;

  constructor(public mdDialogRef: MdDialogRef<any>,
              public uploadFileService: RequestFileService,
              public sender: RequestFileSenderService) { }

  ngOnInit() {
    this.siteId = this.mdDialogRef._containerInstance.dialogConfig.data.siteId;
    const dateBeforeString = this.mdDialogRef._containerInstance.dialogConfig.data.date;
    this.endDateAtTheMoment =  dateBeforeString ? moment(dateBeforeString, 'YYYY-MM-DD') : null;
  }

  ngAfterViewInit(): void {
    if (this.datePicker) {
      this.datePicker.nativeElement.blur();
    }
  }

  isValidEndDate() {
    return !!this.endDateRequest
      && (!this.endDateAtTheMoment || !!moment(this.endDateRequest, 'YYYY-MM-DD').diff(this.endDateAtTheMoment, 'days'));
  }
  catchFocusEventOnInit(focus: Event) {
    (<any>focus.currentTarget).setAttribute('tabindex', '-1');
  }

  onConfirm() {
    const callback = this.transmitRequestAndClose.bind(this);
    this.sender.uploadRequest(callback);
  }

  private transmitRequestAndClose(files) {
    const request: ISiteRequestParamsDateChanged = {
      type: ESiteRequestType.DATE_CHANGE,
      new_date: moment(this.endDateRequest, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      file_ids: files.map(file => file.id),
      site_id: this.siteId
    };
    this.mdDialogRef.close(request);
  }
}
