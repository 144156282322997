import {EventEmitter} from '@angular/core';
import {
  IErrorImportErrorRecordContainer,
  IErrorReadyCompletionRecordContainer,
  ISpinnerCompletionRecord,
  ISpinnerCompletionRecordContainer,
  ISpinnerErrorReadyRecord, ISpinnerImportErrorRecord
} from '../../spinner/spinner-completion.interface';
import {EDeepStreamMessage} from '../../values/deepstream-message-type.enum';
import {BaseDeepStreamService} from './base-deepstream.service';

export class DepositImportDeepStream extends BaseDeepStreamService {

    private static _depositImportErrorsStream$: EventEmitter<IErrorReadyCompletionRecordContainer> = new EventEmitter<IErrorReadyCompletionRecordContainer>();
    private static _depositCompletionStream$: EventEmitter<ISpinnerCompletionRecordContainer> = new EventEmitter<ISpinnerCompletionRecordContainer>();
    private static _depositImportParsedRowsStream$: EventEmitter<ISpinnerCompletionRecordContainer> = new EventEmitter<ISpinnerCompletionRecordContainer>();
    private static _depositImportFatalError$: EventEmitter<IErrorImportErrorRecordContainer> = new EventEmitter<IErrorImportErrorRecordContainer>();

    protected messageTypes: EDeepStreamMessage[] = [
        EDeepStreamMessage.ERRORS_READY,
        EDeepStreamMessage.IMPORT_COMPLETION,
        EDeepStreamMessage.DEPOSIT_IMPORT_PROCESS_DOC,
        EDeepStreamMessage.DEPOSIT_IMPORT_PROGRESS_ERROR
    ];

    public receiver: string

    public emit(data: ISpinnerCompletionRecord | ISpinnerErrorReadyRecord) {
        switch (data.type) {
            case EDeepStreamMessage.ERRORS_READY:
                return DepositImportDeepStream._depositImportErrorsStream$.emit({ target: this.receiver, data } as { target: string, data: ISpinnerErrorReadyRecord });
            case EDeepStreamMessage.IMPORT_COMPLETION:
                return DepositImportDeepStream._depositCompletionStream$.emit({ target: this.receiver, data } as { target: string, data: ISpinnerCompletionRecord });
            case EDeepStreamMessage.DEPOSIT_IMPORT_PROCESS_DOC:
                return DepositImportDeepStream._depositImportParsedRowsStream$
                  .emit({ target: this.receiver, data} as { target: string, data: ISpinnerCompletionRecord });
            case EDeepStreamMessage.DEPOSIT_IMPORT_PROGRESS_ERROR:
                return DepositImportDeepStream._depositImportFatalError$
                  .emit({ target: this.receiver, data } as { target: string, data: ISpinnerImportErrorRecord });
        }
    }

    public get depositImportErrorsStream$(): EventEmitter<IErrorReadyCompletionRecordContainer> {
        return DepositImportDeepStream._depositImportErrorsStream$;
    }
    public get depositCompletionStream$(): EventEmitter<ISpinnerCompletionRecordContainer> {
        return DepositImportDeepStream._depositCompletionStream$;
    }
    public get depositImportParsedRowsStream$(): EventEmitter<ISpinnerCompletionRecordContainer> {
        return DepositImportDeepStream._depositImportParsedRowsStream$;
    }
    public get depositImportFatalError$(): EventEmitter<IErrorImportErrorRecordContainer> {
        return DepositImportDeepStream._depositImportFatalError$;
    }

}
