<div class="ecosystem-indicators__summary-item">
  <header class="ecosystem-indicators__summary-item--header" (click)="toggle()">
    <div class="ecosystem-indicators__summary-item--header-type">
      <label>{{typeLabel | translate | uppercase}}</label>
    </div>
    <hr class="ecosystem-indicators__summary-item--header-separator">
    <label class="ecosystem-indicators__summary-item--header-description">
      <b>{{ step.title}} </b> {{subtitle && subtitle !== '' ? '- ' + subtitle : ''}}</label>
    <div class="ecosystem-indicators__warning-message">
      <ng-container *ngIf="showErrorMessage() || !step.isStepValid()">
        <div class="ecosystem-indicators__exclamation">!</div>
        <label>{{ (step.isStepValid() ? 'Missing data' : step.getInvalidTitle()) | translate}}</label>
      </ng-container>
    </div>
    <button class="button-transparent ecosystem-indicators__toggle-button">
      <i class="icon" [ngClass]="summaryItemExpanded ? 'icon--fold-tab' : 'icon--unfold-tab' "></i>
    </button>
  </header>
  <div *ngIf="summaryItemExpanded"
       [ngClass]="step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM
        ? 'ecosystem-indicators__summary-transformation-item--content'
        : 'ecosystem-indicators__summary-item--content'">
    <ng-container *ngIf="step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM">
      <section class="ecosystem-indicators__summary--transform-container">
      <div class="ecosystem-indicators__summary-order"
           *ngFor="let order of step.summary_details.orders; let index = index; let last = last;">
        <section class="ecosystem-indicators__summary--transform">
          <div class="ecosystem-indicators__container ecosystem-indicators__input-name-container">
            <div class="flex-column ecosystem-indicators__input-name">
              <span class="ecosystem-indicators__input-name-text">
                {{order.name || getPreviousStep(order.in_passport.child_step_id)}}
              </span>
            </div>
          </div>

          <transform-conversion
              class="ecosystem-indicators__conversion-field ecosystem-indicators__summary--transform-field"
              placeholder="{{ quantityLabel.expected | translate }}"
              [quantity]="order.quantity"
              [isInputConversion]="true"
              [transform]="step.transform"
              [previousConversion]="order.default_conversion"
              [previousUnit]="order.default_unit"
              [disabled]="true">
          </transform-conversion>

          <transform-conversion
            class="ecosystem-indicators__conversion-field ecosystem-indicators__summary--transform-field"
            [identifier]="'ecosystem-indicator_order_' + order.id"
            placeholder="{{ quantityLabel.confirmed | translate }}"
            [quantity]="order.confirmed_quantity"
            [isInputConversion]="true"
            [transform]="step.transform"
            [previousConversion]="order.default_conversion"
            [previousUnit]="order.default_unit"
            (quantityChanged)="updateQuantity($event, index)">
          </transform-conversion>
        </section>

        <section class="ecosystem-indicators__summary--transform"
                 *ngIf="step.transformation_type === TRANSFORM_TYPE.TRANSPORT">
          <div class="flex-column ecosystem-indicators__summary--transform-field">
            <div class="ecosystem-indicators__placeholder--label ecosystem-indicators__disabled">
              {{ 'Distance to travel' | translate }}{{' (' + ('expected' | translate) +  ')'}}
            </div>
            <div class="ecosystem-indicators__input-container">
              <md-input-container class="form-group__field ecosystem-indicators__summary-number-input"
                                  floatPlaceholder="never">
                <input
                  autocomplete="off"
                  mdInput
                  disabled="true"
                  [(ngModel)]="order.estimated_distance">
              </md-input-container>
              <div class="ecosystem-indicators__unit">Km</div>
            </div>
          </div>
          <div class="flex-column ecosystem-indicators__summary--transform-field">
            <div class="ecosystem-indicators__placeholder--label">
              {{ 'Distance to travel' | translate }}{{' (' + ('Confirmed' | translate | lowercase) +  ')'}}
            </div>
            <div class="ecosystem-indicators__input-container">
              <md-input-container class="form-group__field ecosystem-indicators__summary-number-input"
                                  floatPlaceholder="never">
                <input
                  autocomplete="off"
                  mdInput
                  [(ngModel)]="order.confirmed_distance">
              </md-input-container>
              <div class="ecosystem-indicators__unit">Km</div>
            </div>
          </div>
        </section>
      </div>

      <section class="ecosystem-indicators__summary--transform">

        <div class="flex-row">
          <div class="flex-column ecosystem-indicators__summary--transform-field">
            <div class="ecosystem-indicators__placeholder--label ecosystem-indicators__disabled">
              {{ 'Price (expected)' | translate }}
            </div>
            <div class="ecosystem-indicators__input-container">
              <md-input-container
                class="form-group__field ecosystem-indicators__summary-number-input"
                floatPlaceholder="never">
                <input
                  autocomplete="off"
                  mdInput
                  disabled="true"
                  [value]="firstOrder.price"
                >
              </md-input-container>
              <div class="ecosystem-indicators__unit">
                €
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row ecosystem-indicators__summary--transform-field">
          <div class="flex-column">
            <div class="ecosystem-indicators__placeholder--label">{{ 'Price (confirmed)' | translate }}</div>
            <div class="ecosystem-indicators__input-container">
              <md-input-container class="form-group__field ecosystem-indicators__summary-number-input" floatPlaceholder="never">
                <input
                  autocomplete="off"
                  mdInput
                  [(ngModel)]="firstOrder.confirmed_price"
                >
              </md-input-container>
              <div class="ecosystem-indicators__unit">
                €
              </div>
              <i
                class="form-group__label--question ecosystem-indicators__question"
                mdTooltip="{{'ESM_COMPILER_NEED_STEP_PRICE_TOOLTIP' | translate}}"
                mdTooltipPosition="above"
              >?</i>
            </div>
          </div>
        </div>
      </section>
      </section>
    </ng-container>
    <ng-container *ngIf="(step.type !== ECOSYSTEM_STEP_TYPE.TRANSFORM) && step.isStepValid()">
      <div class="ecosystem-indicators__summary--deposit">
        <div class="flex-column">
          <conversion-field
          class="ecosystem-indicators__conversion-field ecosystem-indicators__summary--transform-field"   
          identifier="ecosystem-transformation-indicator"
          emptyQuantityHolder=""
          placeholder="{{ quantityLabel.expected | translate }}"
          [quantity]="step.summary_details.step.quantity"
          [volumeUnit]="step.summary_details.step.passport_unit"
          [conversionFactor]="step.summary_details.step.conversion"
          [isDisabled]="true">
        </conversion-field>
        <div class="flex-column">
          <div class="ecosystem-indicators__placeholder--label ecosystem-indicators__disabled">{{ 'Price (expected)' | translate }}</div>
          <div class="ecosystem-indicators__input-container">
            <md-input-container class="form-group__field ecosystem-indicators__summary-number-input" floatPlaceholder="never">
              <input
              autocomplete="off"
              mdInput
              disabled="true"
              [value]="step.summary_details.step.price"
              >
            </md-input-container>
            <div class="ecosystem-indicators__unit">
              €
            </div>
          </div>
        </div>
        </div>
        <div class="flex-column">
        <conversion-field
          class="ecosystem-indicators__conversion-field ecosystem-indicators__summary--transform-field"
          [identifier]="'ecosystem-indicator_step_' + step.id"
          placeholder="{{ quantityLabel.confirmed | translate }}"
          [quantity]="step.summary_details.step.confirmed_quantity"
          [volumeUnit]="step.summary_details.step.passport_unit"
          [conversionFactor]="step.summary_details.step.conversion"
          (quantityInTonsChanged)="step.summary_details.step.confirmed_quantity = $event"
          [isDisabled]="false">
        </conversion-field>
        <div class="flex-column">
          <div class="ecosystem-indicators__placeholder--label">{{ 'Price (confirmed)' | translate }}</div>
          <div class="ecosystem-indicators__input-container">
            <md-input-container class="form-group__field ecosystem-indicators__summary-number-input" floatPlaceholder="never">
              <input
              autocomplete="off"
              mdInput
              [(ngModel)]="step.summary_details.step.confirmed_price"
              >
            </md-input-container>
            <div class="ecosystem-indicators__unit">
              €
            </div>
            <i
            class="form-group__label--question ecosystem-indicators__question"
            mdTooltip="{{'ESM_COMPILER_NEED_STEP_PRICE_TOOLTIP' | translate}}"
            mdTooltipPosition="above"
          >?</i>
          </div>
        </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(step.type !== ECOSYSTEM_STEP_TYPE.TRANSFORM )&& step.isStepValid()">
      <div class="ecosystem-indicators__warning-message"
           *ngFor="let error of visibleErrors(this.step.summary_details.step.errors,
                                              this.step.summary_details.step.confirmed_price,
                                              this.step.summary_details.step.confirmed_quantity)">
        <div class="ecosystem-indicators__exclamation">!</div>
        <label>{{ECOSYSTEM_CALC_ERROR[error] | translate:{X: step.passport_name} }}</label>
      </div>
    </ng-container>
    <ng-container *ngIf="step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM" >
      <ng-container *ngFor="let order of this.step.summary_details.orders">
        <div class="ecosystem-indicators__warning-message ecosystem-indicators__transformation-warnings"
             *ngFor="let error of visibleErrors(order.errors
             , getFirstOrderPriceOrNotFoundIndex(order) , order.confirmed_quantity, order.confirmed_distance)">
          <div class="ecosystem-indicators__exclamation">!</div>
          <label>{{ECOSYSTEM_CALC_ERROR[error] | translate:{X: order.origin} }}</label>
        </div>
      </ng-container>
    </ng-container>
</div>
</div>
