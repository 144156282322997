import { Component, NgZone } from '@angular/core';
import { NeedListComponent } from '../need-list/need-list.component';
import { Router } from '@angular/router';
import { UserService } from '../../../../shared/services/user.service';
import { ERoleIds } from '../../../../shared/values/role-ids.enum';

@Component({
  moduleId: module.id,
  selector: 'need-catalog',
  templateUrl: 'need-catalog.component.html'
})
export class NeedCatalogComponent {

  activeIndex: number = 0;

  pendingModificationsCount: number;
  pendingValidationCount: number;
  pendingConfirmationCount: number;

  constructor(private _zone: NgZone, private _router: Router, private userService: UserService) {
    this.updateCounters();
  }

  /**
   * Method which updates the counters of the need manager
   */
  updateCounters() {
    this.userService.getRoleCounters({role: ERoleIds.SALES_MANAGER}).subscribe(
      (res:any) => {
        this.pendingModificationsCount=Number(res.pendingModificationsCount);
        this.pendingValidationCount = Number(res.pendingValidationCount);
        this.pendingConfirmationCount = Number(res.pendingConfirmationCount);
      });
  }

  changeTab(index: number) {
    this._zone.run(() => this.activeIndex = index);
  }

  newNeed() {
    this._router.navigate(['sales/needs/new']);
  }
}
