/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../dashboards/manufacturer/components/selectable-button-list-filter.component.ngfactory";
import * as i2 from "../../../../../dashboards/manufacturer/components/selectable-button-list-filter.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../shared/services/navigation.service";
import * as i5 from "./document-type-input-list.component";
var styles_DocumentTypeInputListComponent = [];
var RenderType_DocumentTypeInputListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DocumentTypeInputListComponent, data: {} });
export { RenderType_DocumentTypeInputListComponent as RenderType_DocumentTypeInputListComponent };
export function View_DocumentTypeInputListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-selectable-button-list-filter", [], null, [[null, "selectionChange"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectionChange" === en)) {
        var pd_1 = (_co.onSelectionChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectableButtonListFilterComponent_0, i1.RenderType_SelectableButtonListFilterComponent)), i0.ɵdid(1, 638976, null, 0, i2.SelectableButtonListFilterComponent, [i3.TranslateService, i4.NavigationService], { selectedItem: [0, "selectedItem"], items: [1, "items"], placeholder: [2, "placeholder"], disabled: [3, "disabled"] }, { selectionChange: "selectionChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getEpdSelectedValue(); var currVal_1 = _co.getListEpdTypeName(); var currVal_2 = "documentType"; var currVal_3 = _co.disabled; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_DocumentTypeInputListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "document-type-input-list", [], null, null, null, View_DocumentTypeInputListComponent_0, RenderType_DocumentTypeInputListComponent)), i0.ɵdid(1, 114688, null, 0, i5.DocumentTypeInputListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentTypeInputListComponentNgFactory = i0.ɵccf("document-type-input-list", i5.DocumentTypeInputListComponent, View_DocumentTypeInputListComponent_Host_0, { selectedValue: "selectedValue", disabled: "disabled" }, { selectionChange: "selectionChange" }, []);
export { DocumentTypeInputListComponentNgFactory as DocumentTypeInputListComponentNgFactory };
