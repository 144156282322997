/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "../../../passport/partials/edit-passport/edit-passport.component.ngfactory";
import * as i3 from "../../../passport/services/passport-gtin-buffer.service";
import * as i4 from "../../../passport/services/passport-gtin.service";
import * as i5 from "../../../../shared/read-only/read-only.service";
import * as i6 from "../../../passport/partials/edit-passport/edit-passport.component";
import * as i7 from "@angular/forms";
import * as i8 from "../../../passport/services/passport.service";
import * as i9 from "../../../passport/services/passport-file.service";
import * as i10 from "../../../passport/services/format.service";
import * as i11 from "../../../../shared/services/navbar.service";
import * as i12 from "../../../../shared/services/loading-spinner.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../../shared/services/navigation.service";
import * as i15 from "../../../../shared/services/user.service";
import * as i16 from "../../../passport/services/material.service";
import * as i17 from "@ngx-translate/core";
import * as i18 from "../../../../shared/services/log-notification.service";
import * as i19 from "../../../passport/services/passport-validation-notification.service";
import * as i20 from "../../../passport/services/passport-group-builder/passport-group-builder.service";
import * as i21 from "./create-passport.component";
var styles_CreatePassportDialogComponent = [];
var RenderType_CreatePassportDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreatePassportDialogComponent, data: {} });
export { RenderType_CreatePassportDialogComponent as RenderType_CreatePassportDialogComponent };
export function View_CreatePassportDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "md-dialog-content", [], [[2, "mat-dialog-content", null]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MdPrefixRejector, [[2, i1.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(2, 16384, null, 0, i1.MdDialogContent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 3, "passport-edit-form", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_EditPassportComponent_0, i2.RenderType_EditPassportComponent)), i0.ɵprd(512, null, i3.PassportGtinBufferService, i3.PassportGtinBufferService, [i4.PassportGtinService]), i0.ɵprd(512, null, i5.ReadOnlyService, i5.ReadOnlyService, []), i0.ɵdid(6, 8634368, null, 0, i6.EditPassportComponent, [i1.MdDialog, i7.FormBuilder, i3.PassportGtinBufferService, i8.PassportService, i9.PassportFileService, i10.PassportFormatService, i11.NavbarStateService, i12.LoadingSpinnerService, i13.Router, i5.ReadOnlyService, i14.NavigationService, i15.UserService, i16.MaterialService, i0.Renderer, i17.TranslateService, i0.ChangeDetectorRef, i18.LogNotificationService, i19.PassportValidationNotificationService, i0.ChangeDetectorRef, i20.PassportGroupBuilderService, i4.PassportGtinService], { asDialog: [0, "asDialog"] }, { onSave: "onSave" })], function (_ck, _v) { var currVal_1 = true; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_CreatePassportDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "create-passport", [], null, null, null, View_CreatePassportDialogComponent_0, RenderType_CreatePassportDialogComponent)), i0.ɵdid(1, 245760, null, 0, i21.CreatePassportDialogComponent, [i1.MdDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreatePassportDialogComponentNgFactory = i0.ɵccf("create-passport", i21.CreatePassportDialogComponent, View_CreatePassportDialogComponent_Host_0, {}, {}, []);
export { CreatePassportDialogComponentNgFactory as CreatePassportDialogComponentNgFactory };
