import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { DepositFile } from '../models/deposit-file.class';

@Injectable()
export class DepositFileService extends CrudService {
  protected namespace = 'deposit-import-files';
  protected namespaceSingular = 'deposit-import-file';
  protected ModelClass = DepositFile;

}
