import * as tslib_1 from "tslib";
import { EventEmitter, NgZone, OnInit } from '@angular/core';
import { MdDialog } from '@angular/material';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { ENextLive, NextLives } from '../../../../shared/interfaces/filter-states.interface';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { DepositDetailsDialogComponent } from '../../../deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { Deposit } from '../../../deposit/models/deposit.class';
import { DepositService } from '../../../deposit/services/deposit.service';
import { ViewCategoryDialogComponent } from '../../../passport/dialogs/view-category/view-category.component';
import { DepositLocation } from '../../../deposit/models/deposit-location.class';
import { EDepositItemType } from '../../../deposit/values/deposit-item-type.enum';
import { Router } from '@angular/router';
import { DepositLocationService } from '../../../deposit/services/deposit-location.service';
import { DepositFilter } from '../../../deposit/models/deposit-filter';
import { PaginationUnmarshallingService } from '../../../../shared/services/pagination-unmarshalling.service';
import { DEPOSIT_SORT_NAME } from '../../../deposit/values/deposit-sort.enum';
import { DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES } from '../../../deposit/values/deposit-reuse-source.const';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, omit } from 'lodash';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import { UserService } from '../../../../shared/services/user.service';
var NeedDepositListComponent = /** @class */ (function () {
    function NeedDepositListComponent(_dialog, depositService, navBarStateService, router, zone, sitesService, translate, paginationService, currentUser, userService) {
        this._dialog = _dialog;
        this.depositService = depositService;
        this.navBarStateService = navBarStateService;
        this.router = router;
        this.zone = zone;
        this.sitesService = sitesService;
        this.translate = translate;
        this.paginationService = paginationService;
        this.currentUser = currentUser;
        this.userService = userService;
        this.depositSourceList = DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES;
        this.DEPOSIT_ITEM_TYPE = EDepositItemType;
        this.sites = [];
        this.siteWithoutNoah = [];
        this.sitesWithNoah = [];
        this.sort_order = DEPOSIT_SORT_NAME;
        this.depositFilter = new DepositFilter({ isOnlyActiveSites: true, expand: 'passport,nomenclature,deposit_location,isNoah' });
        this.records = [];
        this.ratingNameAlias = {
            passportWithoutRating: 'Passports without rating'
        };
        this.ratingIconAlias = {
            passportWithoutRating: ''
        };
        this.NextLives = NextLives;
        this.NextLive = ENextLive;
        this.isFilterNextLivesVisible = false;
        this.clearFilterForm = new EventEmitter();
        this.beforeMapUpdate = new EventEmitter();
        this.updateMap = new EventEmitter();
        this.resetFilters = cloneDeep(omit(this.depositFilter, this.depositFilter.noneResetValues));
        this.noahAccess = false;
    }
    NeedDepositListComponent.prototype.onClick = function (targetElement) {
        if (!this.filterNextLives.nativeElement.contains(targetElement)) {
            this.isFilterNextLivesVisible = false;
        }
    };
    NeedDepositListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateList();
        this.initSites();
        this.depositFilter.searchControl
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function () {
            _this.updateList();
            _this.mapChanges();
        });
    };
    /**
     * Initialize the map section
     * Get all the available non-Noah sites
     * And, if the user has access to the noah database, get all the Noah sites
     */
    NeedDepositListComponent.prototype.initSites = function () {
        var _this = this;
        this.beforeMapUpdate.emit();
        this.mapSubscription = this.loadSites(false).subscribe(function (sites) {
            _this.sites = sites;
            _this.updateMap.emit(sites);
        });
        this.userService.view(this.currentUser.infoData.id, { expand: 'company' }).subscribe(function (user) {
            _this.noahAccess = user.company.noah_access;
            if (_this.noahAccess) {
                _this.loadSites(true).subscribe();
            }
        });
    };
    /**
     * Function triggered when the filters are updated
     */
    NeedDepositListComponent.prototype.updateFilters = function () {
        this.updateList();
        this.mapChanges();
    };
    /**
     * Function called to update the list of deposits
     */
    NeedDepositListComponent.prototype.updateList = function () {
        var _this = this;
        this.depositService.getWithArray(this.buildOptions()).subscribe(function (deposits) {
            var pagination = _this.paginationService.unmarshall(deposits);
            _this.depositFilter.updateRecords(deposits, pagination);
            _this.records = _this.depositFilter.records.map(function (data) { return new Deposit(data); });
        });
    };
    /**
     * Function called to update the map
     */
    NeedDepositListComponent.prototype.mapChanges = function () {
        var _this = this;
        this.beforeMapUpdate.emit();
        if (this.mapSubscription) {
            this.mapSubscription.unsubscribe();
        }
        this.mapSubscription = this.loadSites(null, this.buildMapOptions()).subscribe(function (locations) { return _this.updateMap.emit(locations); });
    };
    /**
     * Load sites to show on the map
     * Return the sites which have at least one deposit that corresponds to the criteria
     * @param {Boolean|null} isNoah - True if we want to include the Noah sites, False if we want to exclude the Noah sites, null else
     * @param {Object} filterParams - The list of params to add to the site search
     * @returns {DepositLocation[]}
     */
    NeedDepositListComponent.prototype.loadSites = function (isNoah, filterParams) {
        var _this = this;
        if (isNoah === void 0) { isNoah = null; }
        if (filterParams === void 0) { filterParams = {}; }
        var params = Object.assign(filterParams, this.getDefaultMapParams());
        if (isNoah) {
            Object.assign(params, { noah_view: 1 });
        }
        return this.sitesService.getMapLocations(params)
            .map(function (data) {
            var locations = data.map(function (datum) { return new DepositLocation(datum); });
            isNoah === null
                ? params['noah_view'] ? _this.sitesWithNoah = locations : _this.siteWithoutNoah = locations
                : isNoah ? _this.sitesWithNoah = locations : _this.siteWithoutNoah = locations;
            return locations;
        });
    };
    /**
     * Get the default params needed to send the location request for the need manager view
     * @returns {Object}
     */
    NeedDepositListComponent.prototype.getDefaultMapParams = function () {
        return {
            active_sites: true,
            sales_view: 1,
        };
    };
    /**
     * Build the options array to send the request for the deposits
     * @param {DepositFilter} filter - The filter containing the info about the deposits to search for
     * @returns {Array<Array<String|Number|Boolean>>}
     */
    NeedDepositListComponent.prototype.buildOptions = function (filter) {
        if (filter === void 0) { filter = this.depositFilter; }
        var options = filter.buildOptions();
        options = filter.buildRating(options);
        options.push(['sales_view', 1]);
        return options;
    };
    /**
     * Build the options object to use in the request for the locations
     * @returns {Object}
     */
    NeedDepositListComponent.prototype.buildMapOptions = function () {
        var optionArray = this.depositFilter.buildOptions();
        optionArray = this.depositFilter.buildRating(optionArray);
        var options = optionArray.reduce(function (result, option) {
            if (!Object.keys(result).includes(option[0])) {
                result[option[0]] = [option[1]];
            }
            else {
                result[option[0]].push(option[1]);
            }
            return result;
        }, {});
        return options;
    };
    NeedDepositListComponent.prototype.toMarker = function (data) {
        return {
            lat: data.deposit_location.lat || null,
            lng: data.deposit_location.lng || null,
            name: data.name || null,
            lines: [
                (data.quantity ? data.quantity : 0) + " " + data.passport.unit_text,
                this.markerAvailableOnLabelText + ": " + (data.availability_date ? data.availability_date : '')
            ]
        };
    };
    NeedDepositListComponent.prototype.openCategoryDialog = function (category) {
        var _this = this;
        category.blur();
        this._dialog.open(ViewCategoryDialogComponent, {
            height: '80%',
            width: '80%',
            position: {}
        }).afterClosed().subscribe(function (result) {
            if (result && !_this.depositFilter.categoryTags.some(function (category) { return category.id === result.id; })) {
                _this.depositFilter.categoryTags.push(result);
                _this.updateFilters();
            }
        });
    };
    NeedDepositListComponent.prototype.removeCategoryTag = function (tag, event) {
        event.stopPropagation();
        event.preventDefault();
        this.depositFilter.categoryTags.splice(this.depositFilter.categoryTags.indexOf(tag), 1);
        this.updateFilters();
    };
    Object.defineProperty(NeedDepositListComponent.prototype, "canClearFiltersAlternativeRules", {
        get: function () {
            return {
                filterBy: {
                    C2cCertified: true,
                    composition: { color: true, black: true },
                    cycle: { color: true, black: true },
                    energy: { color: true, black: true },
                    water: { color: true, black: true },
                    social: { color: true, black: true },
                    passportWithoutRating: true,
                    selectRatingFilter: true,
                },
                searchControl: ''
            };
        },
        enumerable: true,
        configurable: true
    });
    NeedDepositListComponent.prototype.clearFilters = function ($event) {
        this.depositFilter = Object.assign(this.depositFilter, $event);
        this.clearFilterForm.emit();
        this.updateFilters();
    };
    Object.defineProperty(NeedDepositListComponent.prototype, "nextLivesKeys", {
        get: function () {
            return Object.keys(this.depositFilter.nextLives);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedDepositListComponent.prototype, "isAnyNextLivesFilterApplied", {
        get: function () {
            var _this = this;
            return Object.keys(this.depositFilter.nextLives).some(function (key) { return _this.depositFilter.nextLives[key]; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NeedDepositListComponent.prototype, "categoryLabel", {
        get: function () {
            return this.depositFilter.categoryTags.length === 0 ? 'Product category' : '';
        },
        enumerable: true,
        configurable: true
    });
    NeedDepositListComponent.prototype.openDetailView = function (deposit) {
        this._dialog.open(DepositDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                readOnly: true,
                id: deposit.id,
                showValidationButtons: false,
            } }));
    };
    NeedDepositListComponent.prototype.createNewNeed = function (deposit) {
        var _this = this;
        var passport = deposit.passport;
        var navigate = function () { return _this.zone.run(function () { return _this.router.navigate(['/sales/needs/new/deposits', deposit.id, 'independent', deposit.isNoah || '']); }); };
        navigate();
    };
    NeedDepositListComponent.prototype.setChosenLocationsById = function (locationIds) {
        var siteMap = new Map();
        this.sites.forEach(function (site) { return siteMap.set(site.id, site); });
        this.depositFilter.selectedSites = locationIds.map(function (id) { return siteMap.has(id) ? siteMap.get(id) : new DepositLocation({ id: id }); });
        this.updateFilters();
    };
    NeedDepositListComponent.prototype.pageChange = function (page) {
        this.depositFilter.currentPage = page;
        this.updateList();
    };
    NeedDepositListComponent.prototype.onToggleChanged = function (event) {
        this.sites = event.checked ? this.sitesWithNoah : this.siteWithoutNoah;
        this.updateFilters();
    };
    return NeedDepositListComponent;
}());
export { NeedDepositListComponent };
