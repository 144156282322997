<md-card class="login">
  <div class="login__login-content">
    <div class="login__image-container">
      <img md-card-image src="./assets/png/login_logo.png" id="login_png">
    </div>
    <div class="login__error"></div>
    <div class="login__username-input">
      <div class="login__pic"><img src="./assets/png/login_user.png" id="login_user"></div>
      <div class="login__username">
        <md-input-container>
          <div class="login__input">
            <input
              mdInput
              test-id="username-input"
              [(ngModel)]="username"
              name="username"
              autocomplete="off"
              placeholder="{{ 'username' | translate}}"
              autofocus
              (keydown)="$event.which == 13 && passwordInput.focus() || true"
            >
          </div>
        </md-input-container>
      </div>
    </div>

    <div class="login__password-input">
      <div class="login__pic"><img src="./assets/png/login_pass.png" id="login_pass"></div>
      <div class="login__password">
        <md-input-container>
          <div class="login__input">
            <input
              #passwordInput
              test-id="password-input"
              mdInput
              autocomplete="off"
              [(ngModel)]="password"
              type="password"
              name="password"
              placeholder="{{ 'password' | translate}}"
              (keydown)="$event.which == 13 && loginButton._elementRef.nativeElement.click() || true"
            >
          </div>
        </md-input-container>
      </div>
    </div>
    <div class="login__button-group">
      <md-card-actions>
        <button
          #loginButton
          test-id="login-button"
          name="login-button"
          md-raised-button
          color="primary"
          (click)="login()"
        >{{ 'Login' | translate}}
        </button>
      </md-card-actions>
        <div class="login__forgot-pas"
            (click)="goToForgotPassword()">
          <input type="button" autocomplete="off" class="login__button"
                value="{{'Forgot your password?'| translate }}"/>
        </div>
    </div>
  </div>
  <div class="login__lang">
    {{'Language' | translate}}:
    <language-selector></language-selector>
  </div>

</md-card>
