import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
var DashboardComponentBase = /** @class */ (function () {
    // The constructor initializes subscriptions for handling search input with debouncing
    function DashboardComponentBase() {
        var _this = this;
        this.filterStates = {};
        this.currentTab = '';
        this.searchTermsSubject = new Subject();
        this.passports = [];
        this.isLoading = false;
        this.passportsRequestSubscription = null;
        this.searchPassportIdSubject = new Subject();
        this.versionReference = null;
        this.allPagesSelected = false;
        this.searchSubscription = this.searchTermsSubject.pipe(debounceTime(300)).subscribe(function (searchTerm) {
            _this.filterStates[_this.currentTab].search = searchTerm;
            _this.loadDataForTab(true);
        });
        this.searchPassportIdSubscription = this.searchPassportIdSubject.pipe(debounceTime(300)).subscribe(function (passportIdString) {
            var passportIds = passportIdString.split(',').map(function (id) { return id.trim(); });
            _this.filterStates[_this.currentTab].searchId = passportIds.length ? passportIds : [];
            _this.loadDataForTab(true);
        });
    }
    DashboardComponentBase.prototype.ngOnDestroy = function () {
        this.searchSubscription.unsubscribe();
        this.searchPassportIdSubscription.unsubscribe();
        this.passportsRequestSubscription.unsubscribe();
    };
    // Checks if a given filter state belongs to an agent by looking for a specific property.
    DashboardComponentBase.prototype.isAgentFilterState = function (state) {
        return 'showMyPassports' in state;
    };
    // Checks if a given filter state belongs to a manufacturer by looking for a specific property.
    DashboardComponentBase.prototype.isManufacturerFilterState = function (state) {
        return 'status' in state;
    };
    // Checks if a given filter state belongs to a specialAdmin by looking for a specific property.
    DashboardComponentBase.prototype.isSpecialAdminFilterState = function (state) {
        return 'seeOnlyWaitingForRating' in state;
    };
    DashboardComponentBase.prototype.onSearchFilterChange = function (searchTerm) {
        this.searchTermsSubject.next(searchTerm);
    };
    DashboardComponentBase.prototype.onSearchIdFilterChange = function (searchId) {
        this.searchPassportIdSubject.next(searchId);
    };
    DashboardComponentBase.prototype.onFamilyFilterChange = function (families) {
        this.filterStates[this.currentTab].families = families;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onRatingFilterChange = function (rating) {
        this.filterStates[this.currentTab].rating = rating;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onMyPassportsToggleChange = function (showMyPassports) {
        var state = this.filterStates[this.currentTab];
        if (this.isAgentFilterState(state)) {
            state.showMyPassports = showMyPassports;
            this.loadDataForTab(true);
        }
    };
    DashboardComponentBase.prototype.onSeeOnlyWaitingForRating = function (showOnlyWaitingForRating) {
        var state = this.filterStates[this.currentTab];
        if (this.isSpecialAdminFilterState(state)) {
            state.seeOnlyWaitingForRating = showOnlyWaitingForRating;
            this.loadDataForTab(true);
        }
    };
    DashboardComponentBase.prototype.onRatingChange = function (rating) {
        this.filterStates[this.currentTab].rating = rating;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onMaterialsFilterChange = function (materials) {
        this.filterStates[this.currentTab].materials = materials;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onCategoriesFilterChange = function (categories) {
        this.filterStates[this.currentTab].categories = categories;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onDataRepresentativityFilterChange = function (dataRepresentativity) {
        this.filterStates[this.currentTab].dataRepresentativity = dataRepresentativity;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onDatabaseFilterChange = function (databases) {
        this.filterStates[this.currentTab].databases = databases;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onDataProviderFilterChange = function (dataProviders) {
        this.filterStates[this.currentTab].dataProviders = dataProviders;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onEpdTypeFilterChange = function (epdType) {
        this.filterStates[this.currentTab].epdType = epdType;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onCreatorFilterChange = function (creators) {
        var state = this.filterStates[this.currentTab];
        if (this.isAgentFilterState(state) || this.isSpecialAdminFilterState(state)) {
            state.company_creators = creators;
            this.loadDataForTab(true);
        }
    };
    DashboardComponentBase.prototype.onSortChange = function (sortBy) {
        this.filterStates[this.currentTab].sortBy = sortBy;
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onVersionClicked = function (passport) {
        this.versionReference = this.versionReference ? null : (passport.link_reference ? passport.link_reference : passport.id);
        this.loadDataForTab(true);
    };
    DashboardComponentBase.prototype.onStatusChanged = function (status) {
        var state = this.filterStates[this.currentTab];
        if (this.isAgentFilterState(state) || this.isManufacturerFilterState(state)) {
            state.status = status;
            this.loadDataForTab(true);
        }
    };
    DashboardComponentBase.prototype.onSelectAllPagesChange = function (allPagesSelected) {
        this.allPagesSelected = allPagesSelected;
    };
    DashboardComponentBase.prototype.onPageChange = function (page) {
        this.pagination.current_page = page;
        this.loadDataForTab(false);
    };
    DashboardComponentBase.prototype.filtersAreDefault = function () {
        var _this = this;
        var currentFilters = this.filterStates[this.currentTab];
        var defaultFilters = this.getDefaultFilterState();
        // Obtenir toutes les clés de l'état du filtre
        // WARNING (suppose que les clés de currentFilters et defaultFilters sont identiques)
        var allKeys = Object.keys(currentFilters);
        return allKeys.every(function (key) {
            var currentValue = currentFilters[key];
            var defaultValue = defaultFilters[key];
            // Si les deux valeurs sont des tableaux, utiliser la méthode arraysEqual pour comparer
            if (Array.isArray(currentValue) && Array.isArray(defaultValue)) {
                return _this.arraysEqual(currentValue, defaultValue);
            }
            //Comparaison pour les objets
            if (typeof currentValue === 'object' && currentValue !== null) {
                return JSON.stringify(currentValue) === JSON.stringify(defaultValue);
            }
            // Comparaison directe pour les autres types
            return currentValue === defaultValue;
        });
    };
    // Converts the current state of filters into an object format suitable for making API requests.
    // Special handling is done for the rating filter to convert it into a format expected by the API.
    DashboardComponentBase.prototype.convertFilterStateToObj = function (filterState) {
        var _this = this;
        var requestPayload = {};
        Object.entries(filterState).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (value !== undefined) {
                if (key === 'rating') {
                    var apiFormat = _this.convertRatingToApiFormat(value);
                    for (var _i = 0, _b = Object.entries(apiFormat); _i < _b.length; _i++) {
                        var _c = _b[_i], ratingKey = _c[0], ratingValue = _c[1];
                        if (ratingValue !== 0)
                            requestPayload[ratingKey] = ratingValue;
                    }
                }
                if (key === 'dataRepresentativity') {
                    var iso = _this.filterStates[_this.currentTab].dataRepresentativity.map(function (provider) { return provider === "Unknown" ? "Unknown" : provider.split(' - ')[1]; });
                    requestPayload[key] = iso;
                }
                else {
                    requestPayload[key] = value;
                }
            }
        });
        if (this.versionReference)
            requestPayload['versionReference'] = this.versionReference;
        if (this.allPagesSelected)
            requestPayload['allPagesSelected'] = this.allPagesSelected;
        return requestPayload;
    };
    /**
      * Converts the current rating filter state into a format expected by the API.
      * Each rating state (e.g., black, color) is associated with a numeric value indicating its selection state:
      * - 1 for black
      * - 2 for color
      * - 3 for both selected
      *
      * @param ratingState The current rating state to convert.
      * @return An object formatted for the API, with numeric values representing the selection state of each rating.
     */
    DashboardComponentBase.prototype.convertRatingToApiFormat = function (ratingState) {
        var apiRatingFormat = {};
        Object.entries(ratingState).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (typeof value === 'boolean') {
                apiRatingFormat[key] = value ? 1 : 0;
            }
            else if (typeof value === 'object' && value !== null) {
                var subValues = Object.values(value).map(function (val) { return !!val; });
                var bothSelected = subValues.every(function (val) { return val; });
                var noneSelected = subValues.every(function (val) { return !val; });
                if (!noneSelected) {
                    apiRatingFormat['rating_' + key] = bothSelected ? 3 : (subValues[0] ? 2 : 1);
                }
            }
        });
        return apiRatingFormat;
    };
    // Helper pour comparer deux tableaux indépendamment de l'ordre
    DashboardComponentBase.prototype.arraysEqual = function (arr1, arr2) {
        if (!arr1 || !arr2)
            return arr1 === arr2; // Gère null/undefined
        if (arr1.length !== arr2.length)
            return false;
        var sortedArr1 = arr1.slice().sort();
        var sortedArr2 = arr2.slice().sort();
        for (var i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }
        return true;
    };
    return DashboardComponentBase;
}());
export { DashboardComponentBase };
