import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { DepositOrder } from '../models/deposit-order.class';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';
import { Searchable } from '../../../shared/models/searchable.class';

@Injectable()
export class DepositOrderService extends ExtendedCrudService {
  protected namespace = 'deposit-orders';
  protected namespaceSingular = 'deposit-order';
  protected ModelClass = DepositOrder;

  protected getRejectUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/reject');
  }

  protected getValidateUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/validate');
  }

  protected getReportUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/problem');
  }

  protected getResendUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/resend');
  }

  public sendReport(id: number, problem: string) {
    return this.sendPost(this.getReportUrl(id), {problem});
  }

  getEcoSystemManagerList(query?: any): Observable<Searchable[]> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/esm_list'), {search: query})
      .map((data: Response) => data.json().map((d: any) => new Searchable(d)))
      .catch(this.onError.bind(this));
  }

  getCount(): Observable<number> {
    return this
      .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  public resend(id: number, data): Observable<any> {
    return this.sendPost(this.getResendUrl(id), data)
      .map((res: Response) =>  new DepositOrder(res.json()))
      .catch(this.onError.bind(this));
  }
}
