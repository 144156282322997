import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudService } from './crud.service';
import { Country } from '../models/country.class';

@Injectable()
export class CountryService extends CrudService {
  protected namespace = 'countries';
  protected ModelClass = Country;

  getlistCountriesByUser(role: string): Observable<any> {
    return this.sendGet(this.getEndpoint(`${this.namespace}/list-countries-by-user`
      + '?role=' + role), { withCredentials: true });
  }
}
