import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ECustomListKeyPairClass } from '../../../../../shared/custom-list/interfaces/custom-list.interface';
import { MdDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { TransformService } from '../../../../../entities/transformation/services/transform.service';
import { TsmListService } from '../../../../../entities/transformation/services/tsm-list.service';
import { getTransformTypeLcView, TRANSFORM_TYPES } from '../../../../transformation-manager/values/transform-types.const';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import { TransformDetailsDialogComponent } from '../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { TRANSFORM_STATUSES } from '../../../../transformation-manager/values/transform-statuses.const';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import * as moment from 'moment';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
var SupervisorDataTransformationsCatalogComponent = /** @class */ (function () {
    function SupervisorDataTransformationsCatalogComponent(_transformService, dialog, datePipe, tsmListService, mdDialog, navBarState) {
        var _this = this;
        this._transformService = _transformService;
        this.dialog = dialog;
        this.datePipe = datePipe;
        this.tsmListService = tsmListService;
        this.mdDialog = mdDialog;
        this.navBarState = navBarState;
        this.TRANSFORM_TYPES = TRANSFORM_TYPES;
        this.search = new FormControl();
        this.markers = [];
        this.records = [];
        this.filterStatuses = [
            TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION,
            TRANSFORM_STATUSES.PUBLISHED
        ];
        this.sortOptions = [
            { field: 'name', view: 'Name' },
            { field: '-created_at', view: 'Creation date' },
            { field: 'tsm_name', view: 'Transformation Manager' },
            { field: 'transformation_type', view: 'Type' }
        ];
        this.sortValue = 'name';
        this.searchStatus = this.filterStatuses.map(function (status) { return status.value; });
        this
            .tsmListService
            .get()
            .subscribe(function (tsms) {
            return _this.tsms = tsms;
        });
    }
    SupervisorDataTransformationsCatalogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this
            .search
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function () { return _this.getRecords(); });
        this.getRecords();
    };
    SupervisorDataTransformationsCatalogComponent.prototype.downloadPdfReport = function () {
        return this._transformService.getPdfReportUrl({
            supervisor: true,
            search: this.search.value || null,
            transformation_type: this.type || null,
            tsm: this.tsmId || null,
            status: this.searchStatus,
            timezone_offset: moment().utcOffset()
        });
    };
    SupervisorDataTransformationsCatalogComponent.prototype.getRecords = function () {
        var _this = this;
        if (this.searchStatus.length <= 0) {
            this.resetResults();
            return;
        }
        this.records.length = 0;
        var params = {
            expand: 'files,materials,tsm_name,sites,tags',
            supervisor: true,
            'status[]': this.searchStatus,
            search: this.search.value || null,
            transformation_type: this.type || null,
            tsm: this.tsmId || null,
            sort: this.sortValue,
        };
        if (this.navBarState.multilanguage) {
            params.english = true;
        }
        this._transformService
            .get(params)
            .subscribe(function (data) {
            _this.mapIsReady = false;
            _this.markers = [];
            data.forEach(function (item) { return _this.addRecord(item); });
            data.forEach(function (item) { return _this.addMarkers(item); });
            _this.mapIsReady = true;
        });
    };
    SupervisorDataTransformationsCatalogComponent.prototype.addMarkers = function (data) {
        var _this = this;
        var _a;
        (_a = this.markers).push.apply(_a, data.sites
            .map(function (item) { return _this.toMarker(data.name, item, data.english_name); }));
    };
    SupervisorDataTransformationsCatalogComponent.prototype.toMarker = function (transformName, data, english_name) {
        return {
            lat: data.lat,
            lng: data.lng,
            name: transformName,
            english_name: english_name,
            lines: [
                "" + data.name,
                "" + data.address
            ]
        };
    };
    SupervisorDataTransformationsCatalogComponent.prototype.addRecord = function (data) {
        var incomeMaterials = data.materials.filter(function (material) { return material.is_input === 1; });
        var incomeMaterialsString = (incomeMaterials.map(function (material) { return material.name; })).join(', ');
        var outcomeMaterials = data.materials.filter(function (material) { return material.is_input !== 1; });
        var outcomeMaterialsString = (outcomeMaterials.map(function (material) { return material.name; })).join(', ');
        var incomeTags = data.tags.filter(function (tag) { return tag.is_input === 1; }).map(function (tag) { return tag.name; }).join(', ');
        var outcomeTags = data.tags.filter(function (tag) { return tag.is_input !== 1; }).map(function (tag) { return tag.name; }).join(', ');
        this.records.push(this.createTransformationRecord(data.transformation_type_data.value ? getTransformTypeLcView(data.transformation_type_data.value) : '', data.english_name, data.viewName, data.transformation_type_data.view.replace(/./, function (c) { return c.toUpperCase(); }), incomeMaterialsString, outcomeMaterialsString, incomeTags, outcomeTags, (data.materials.map(function (material) { return material.name; })).join(', '), data.transformation_type_data.includes.storingSites ? data.sites.length : data.sectors.length, this.datePipe.transform(data.created_at, 'dd.MM.y'), data.tsm_name, data));
    };
    SupervisorDataTransformationsCatalogComponent.prototype.toggleStatus = function (statusId) {
        var index = this.searchStatus.indexOf(statusId);
        if (index > -1) {
            this.searchStatus.splice(index, 1);
        }
        else {
            this.searchStatus.push(statusId);
        }
    };
    SupervisorDataTransformationsCatalogComponent.prototype.createTransformationRecord = function (icon, english_name, title, subtitle, inputMaterials, outputMaterials, inputTags, outputTags, text, totalSitesCount, addedDate, author, transform) {
        var _this = this;
        var materials = [];
        var keywordsOrControlType = [];
        var reversedColumnEntries = { cssClass: ECustomListKeyPairClass.REVERSED_LIST_COLUMN, seperator: ' ' };
        if (transform.transformation_type_data.includes.incomeOutcome) {
            materials = [{
                    key: 'Input', value: inputMaterials
                }, {
                    key: 'Output', value: outputMaterials
                }];
            keywordsOrControlType = [{
                    key: 'Keywords', value: inputTags
                }, {
                    key: 'Keywords', value: outputTags
                }];
        }
        else if (transform.transformation_type_data.includes.acceptedMaterials) {
            materials = [tslib_1.__assign({ key: 'Accepted materials', value: transform.acceptedMaterialsView }, reversedColumnEntries)];
            if (transform.transformation_type_data.includes.controlType) {
                keywordsOrControlType = [
                    tslib_1.__assign({ key: 'Control type', value: transform.controlTypeView }, reversedColumnEntries),
                ];
            }
        }
        return CustomListRecord
            .build()
            .setPrimaryKey(transform.id)
            .addIconColumn(icon)
            .addTitleColumn(title, subtitle, english_name)
            .addListColumn(materials)
            .addListColumn(keywordsOrControlType)
            .addCounterColumn(totalSitesCount, transform.transformation_type_data.includes.storingSites
            ? 'Sites attached'
            : 'Sectors attached')
            .addDateColumn(addedDate, 'Added')
            .addAuthorColumn(author)
            .addControlsColumn([{
                fn: function (data) {
                    _this.mdDialog.open(TransformDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR, { data: {
                            id: transform.id,
                            readOnly: true,
                        } }));
                },
                name: 'See details'
            }, {
                fn: function (data) {
                    location.href = 'mailto:';
                },
                name: 'Send Email'
            }]);
    };
    SupervisorDataTransformationsCatalogComponent.prototype.resetResults = function () {
        this.markers = [];
        this.records = [];
    };
    return SupervisorDataTransformationsCatalogComponent;
}());
export { SupervisorDataTransformationsCatalogComponent };
