/**
 * Created by aleksandr on 20.07.17.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationEntry } from '../../models/notification-entry.model';
@Component({
  moduleId: module.id,
  selector: 'notification-list',
  templateUrl: 'notification-list.component.html'
})
export class NotificationListComponent {
  @Input() records: NotificationEntry[];
  @Input() isLoading: boolean;
  @Output() public onRead = new EventEmitter<NotificationEntry>();
  @Output() public onAction = new EventEmitter<NotificationEntry>();
}
