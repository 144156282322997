import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { DepositTag } from '../models/deposit-tag.class';
var DepositTagService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositTagService, _super);
    function DepositTagService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'site-tags';
        _this.namespaceSingular = 'site-tag';
        _this.ModelClass = DepositTag;
        return _this;
    }
    return DepositTagService;
}(CrudService));
export { DepositTagService };
