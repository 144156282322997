import * as tslib_1 from "tslib";
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { OrderPassportService } from '../services/order-passport.service';
import { OrderMaterial } from './order-material.class';
import { PASSPORT_UNITS } from '../../../entities/passport/values/passport-units.const';
import { ECustomListUnit } from '../../../shared/custom-list/interfaces/custom-list.interface';
import { ValidationService } from '../../../shared/services/validation-service';
import { TranslateService } from '@ngx-translate/core';
import { ServiceLocator } from '../../../shared/services/service-locator';
import moment from 'moment';
var OrderPassport = /** @class */ (function (_super) {
    tslib_1.__extends(OrderPassport, _super);
    function OrderPassport(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = OrderPassportService;
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        data = data || {};
        _this.materials = (data.materials || []).map(function (material) { return new OrderMaterial(material); });
        return _this;
    }
    Object.defineProperty(OrderPassport.prototype, "unit_text", {
        get: function () {
            var _this = this;
            var unit = PASSPORT_UNITS.find(function (item) { return item.id === _this.unit; });
            return unit ? this.translateService.instant(unit.title) : this.unit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderPassport.prototype, "customListUnit", {
        get: function () {
            return {
                'kg': ECustomListUnit.KG,
                'tonne': ECustomListUnit.TON,
                'm2': ECustomListUnit.M2,
                'm3': ECustomListUnit.M3,
                'unit': ECustomListUnit.UNIT,
            }[this.unit_text];
        },
        enumerable: true,
        configurable: true
    });
    OrderPassport.prototype.fields = function () {
        return [
            'id',
            'name',
            'english_name',
            'quantity',
            'transaction_quantity',
            'transaction_date',
            'transaction_price',
            'transaction_distance',
            'transaction_description',
            'linked_datetime',
            'parent_step_id',
            'child_step_id',
            'unit',
            'entity',
            'description',
            'address',
            'weight'
        ];
    };
    OrderPassport.prototype.beforeSave = function (data) {
        var dataValues = _super.prototype.beforeSave.call(this, data);
        var getMoment = function (date) { return moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']); };
        var formatDate = function (date) { return getMoment(date).isValid() ? getMoment(date).format('YYYY-MM-DD') : ''; };
        if (dataValues.linked_datetime) {
            dataValues.linked_datetime = formatDate(dataValues.linked_datetime);
        }
        if (dataValues.quantity) {
            dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
        }
        return dataValues;
    };
    OrderPassport.prototype.setOrResetWeight = function (data, reset) {
        if (!reset) {
            this.weight = data;
        }
        else {
            this.weight = null;
        }
    };
    OrderPassport.prototype.isWeightExist = function () {
        return !!this.weight || this.weight === 0;
    };
    return OrderPassport;
}(ExtendedActiveRecord));
export { OrderPassport };
