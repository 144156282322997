import { Component, Inject, OnInit } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'ecosystem-deletion',
  templateUrl: 'ecosystem-deletion.component.html',
  styleUrls: ['ecosystem-deletion.component.scss'],
})
export class EcosystemDeletionDialogComponent implements OnInit{

  public deleteConfirmed:boolean = false;
  
  constructor(
    private _dialog: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any,
    ) {}

  ngOnInit() {
  }

  confirmDelete() {
    this.deleteConfirmed = true;
    this.mdDialogData.deleteEcosystem();
  }

  navigateToIndex() {
    this._dialog.close();
    this.mdDialogData.navigateToIndex();
  }
  cancel() {
    this._dialog.close();
  }

}
