/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./circularity-rating-scale.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./circularity-rating-scale.component";
var styles_CircularityRatingScaleComponent = [i0.styles];
var RenderType_CircularityRatingScaleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CircularityRatingScaleComponent, data: {} });
export { RenderType_CircularityRatingScaleComponent as RenderType_CircularityRatingScaleComponent };
function View_CircularityRatingScaleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "rating__item"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0, "border": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isItemActive(_v.context.index) ? _ck(_v, 2, 0, _co.mainColor, "none") : null); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CircularityRatingScaleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "rating"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CircularityRatingScaleComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.numberOfRatingValues(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CircularityRatingScaleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "circularity-rating-scale", [], null, null, null, View_CircularityRatingScaleComponent_0, RenderType_CircularityRatingScaleComponent)), i1.ɵdid(1, 49152, null, 0, i3.CircularityRatingScaleComponent, [], null, null)], null, null); }
var CircularityRatingScaleComponentNgFactory = i1.ɵccf("circularity-rating-scale", i3.CircularityRatingScaleComponent, View_CircularityRatingScaleComponent_Host_0, { gradeValue: "gradeValue", mainColor: "mainColor", ratingPointsAmount: "ratingPointsAmount" }, {}, []);
export { CircularityRatingScaleComponentNgFactory as CircularityRatingScaleComponentNgFactory };
