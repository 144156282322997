/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./step-confirm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i3 from "@angular/material";
import * as i4 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i5 from "../../../../shared/read-only/read-only.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "./step-confirm.component";
var styles_StepConfirmDialogComponent = [i0.styles];
var RenderType_StepConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StepConfirmDialogComponent, data: {} });
export { RenderType_StepConfirmDialogComponent as RenderType_StepConfirmDialogComponent };
function View_StepConfirmDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "button", [["class", "chat-with-sales"], ["md-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close("chat") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MdButton_0, i2.RenderType_MdButton)), i1.ɵdid(1, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(2, 180224, null, 0, i3.MdButton, [i1.ElementRef, i1.Renderer, i3.FocusOriginMonitor], null, null), i1.ɵdid(3, 16384, null, 0, i3.MdButtonCssMatStyler, [], null, null), i1.ɵdid(4, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "img", [["class", "icon"], ["src", "assets/png/ecosystem/message-white-icon.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = true; var currVal_2 = ((i1.ɵnov(_v, 4).readOnlyService == null) ? null : i1.ɵnov(_v, 4).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.chatText())); _ck(_v, 7, 0, currVal_3); }); }
export function View_StepConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon icon--close_s"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h3", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepConfirmDialogComponent_1)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 8, "button", [["class", "confirm"], ["md-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close("confirmed") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MdButton_0, i2.RenderType_MdButton)), i1.ɵdid(12, 16384, null, 0, i3.MdPrefixRejector, [[2, i3.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(13, 180224, null, 0, i3.MdButton, [i1.ElementRef, i1.Renderer, i3.FocusOriginMonitor], null, null), i1.ɵdid(14, 16384, null, 0, i3.MdButtonCssMatStyler, [], null, null), i1.ɵdid(15, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i1.ɵeld(16, 0, null, 0, 0, "img", [["class", "icon"], ["src", "assets/png/ecosystem/ok-white--icon.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showChatButton(); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("Confirm manually?")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.confirmText())); _ck(_v, 6, 0, currVal_1); var currVal_3 = i1.ɵnov(_v, 13).disabled; var currVal_4 = true; var currVal_5 = ((i1.ɵnov(_v, 15).readOnlyService == null) ? null : i1.ɵnov(_v, 15).readOnlyService.readOnly); _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("Confirm")); _ck(_v, 18, 0, currVal_6); }); }
export function View_StepConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_StepConfirmDialogComponent_0, RenderType_StepConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.StepConfirmDialogComponent, [i3.MD_DIALOG_DATA, i3.MdDialogRef], null, null)], null, null); }
var StepConfirmDialogComponentNgFactory = i1.ɵccf("ng-component", i8.StepConfirmDialogComponent, View_StepConfirmDialogComponent_Host_0, {}, {}, []);
export { StepConfirmDialogComponentNgFactory as StepConfirmDialogComponentNgFactory };
