import {Component, DoCheck, ElementRef, HostListener, Input, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import { DepositOrder } from '../../../models/deposit-order.class';
import { EcosystemStep } from '../../../models/ecosystem-step.class';
import { DepositOrderService } from '../../../services/deposit-order.service';
import { MdDialog } from '@angular/material';
import { DEPOSIT_CONDITIONS } from '../../../../../entities/deposit/values/deposit-conditions.const';
import { ECOSYSTEM_STEP_STATUS } from '../../../values/ecosystem-step-status.const';
import { EcosystemFile } from '../../../models/ecosystem-file.class';
import { EcosystemFileDialogComponent } from '../../../dialogs/ecosystem-file/ecosystem-file.component';
import { EcosystemFileJunctionService } from '../../../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import { EcosystemFileJunction } from '../../../models/ecosystem-file-junction.class';
import { ECOSYSTEM_STATUS } from '../../../values/ecosystem-status.const';
import { ChatService } from '../../../../../shared/chat/chat.service';
import { EThreadType } from '../../../../../shared/chat/interfaces/chat-message.interface';
import { ChatRoom } from '../../../../../shared/chat/models/chat-room.model';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../../values/ecosystem-file-junction-item-type.const';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { setTextareaHeightToFitContent } from '../../../../../shared/helpers/set-textarea-height-to-fit-content';
import { EcosystemEditComponent } from '../ecosystem-edit/ecosystem-edit.component';
import { TranslateService } from '@ngx-translate/core';
import {EcosystemPrefillService} from '../../../services/ecosystem-prefill.service';
import {EcosystemCompilerSharedActionsService} from '../../../services/ecosystem-compiler-shared-actions.service';
import {Subject} from 'rxjs/Subject';
import {debounceTime} from 'rxjs/operators';
import * as moment from 'moment';
import { runInThisContext } from 'vm';

@Component({
  moduleId: module.id,
  selector: 'esm-compiler-deposit',
  templateUrl: 'esm-compiler-deposit.component.html',
})
export class DepositOrderComponent implements OnDestroy, OnChanges, DoCheck {
  @Input() step: EcosystemStep;
  @Input() openChat?: boolean;
  @ViewChild('depositSummaryAddress', { read: ElementRef }) depositSummaryAddressTextarea: ElementRef;
  @ViewChild('depositSummaryDescription', { read: ElementRef }) depositDescriptionTextarea: ElementRef;
  @ViewChild('orderDepositDescription', { read: ElementRef }) orderDepositDescriptionTextarea: ElementRef;
  tabStatus = {
    CLOSED: 0,
    OPENED: 1
  }
  tabType = {
    ORDER: 1,
    SUMMARY: 2,
    CHAT: 3
  }
  public conditions = DEPOSIT_CONDITIONS;
  public ECOSYSTEM_STEP_STATUS = ECOSYSTEM_STEP_STATUS;
  public ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
  public files: EcosystemFile[] = [];
  public room: ChatRoom;
  order = new DepositOrder();
  dirty = false;
  arrSection = [this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED];
  private onChange$ = new Subject();
  private quantityUpdate = this.onChange$.pipe(debounceTime(300 )).subscribe(() => this.saveStep());
  private skipSave = false;

  constructor(
              private ecosystemEditComponent: EcosystemEditComponent,
              private _chatService: ChatService,
              private ecoFileJunctionService: EcosystemFileJunctionService,
              private globalEventsManager: GlobalEventsManager,
              public depositOrderService: DepositOrderService,
              public dialog: MdDialog,
              public translate: TranslateService,
              public navBarState: NavbarStateService,
              private prefillService: EcosystemPrefillService,
              private ecosystemCompilerSharedActionsService: EcosystemCompilerSharedActionsService
              ) {
  }

  @HostListener('focusin') onChange() {
    this.dirty = true;
  }

  ngOnChanges() {
    this.load();
    this.closeAllTabs();
    if (this.openChat) {
      this.changeTab(this.tabType.CHAT);
    }
  }
  ngDoCheck() {
    if (this.order && this.order !== this.step.order && this.order.id > 0) {
      this.load();
      this.order = Object.assign(this.step.order, this.order);
    }
  }

  ngOnDestroy() {
    this.saveStep();
    this.quantityUpdate.unsubscribe();
  }

  prohibitSave(isProhibit: boolean) {
    this.skipSave = true;
  }

  closeAllTabs() {
    this.arrSection = [this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED];
  }
  saveStep(reload= false) {
    this.dirty = !this.skipSave;
    this.globalEventsManager.compilerDataBeenUpdated = true;
    this.trySave().subscribe(() => {
      this.globalEventsManager.compilerDataBeenUpdated = false;
      this.globalEventsManager.compilerDataUpdated.emit();
      if (reload) {
        this.step.ecosystem.fetch().subscribe(()=>{
          this.load();
        });
      }
    });
  }

  load() {
    if (this.dirty) {
      console.warn('loaded new step order ontop of an unsaved step??');
    }
    this.depositOrderService.get({
      step_id: this.step.id,
      expand: 'order_passport,address,deposit_location,deposit,deposit_passport,nomenclature,deposit_photos,ecosystem_file_junction,owner',
    })
      .do(() => {
        this.order = null;
      })
      .filter((res: DepositOrder[]) => res[0] instanceof DepositOrder)
      .subscribe((res: DepositOrder[]) => {
        this.order = res[0];
        this.step.order = this.order;

        if(this.order.deposit_passport) {
          this.order.deposit_passport.syncComponents();
        }

        this.room = null;
        this._chatService
          .getContextThreads(this.step.order.id, EThreadType.TYPE_DEPOSIT_ORDER)
          .subscribe((rooms: ChatRoom[]) => this.setChatroom(rooms[0]));
    });
  }

  trySave(step?: EcosystemStep): Observable<EcosystemStep> {
    return new Observable((observer: Observer<EcosystemStep>) => {
      step = step || this.step;
      if (step.canEditOrder && step.order && this.dirty) {
        this.dirty = false;

        Observable.concat(
          step.order.save(), // have to be saved in correct order otherwise order will overwrite it's status
          step.order.order_passport.save(),
          this.ecosystemCompilerSharedActionsService.beforeCompilerReload()
        ).subscribe(
          null,
          err => observer.error(err),
          () => {
            observer.next(step);
            observer.complete();
          }
        );
      } else {
        observer.next(null);
        observer.complete();
      }
    });
    //this.arrSection[index] =+ !this.arrSection[index];
  }

  changeTab(index: number) {
    if(this.arrSection[index] === this.tabStatus.CLOSED){
      this.arrSection[index] = this.tabStatus.OPENED;
    } else {
      this.arrSection[index] = this.tabStatus.CLOSED
    }
  }

  isOpen(index: number) {
    return this.arrSection[index] === this.tabStatus.OPENED;
  }

  askForValidation() {
    this.order.publish().subscribe((res) => {
      if (res.status) {
        this.order.status = res.status;
        this.saveStep(true);
      }
    });
  }

  isReadOnly(): boolean {
    if(this.step.status === this.ECOSYSTEM_STEP_STATUS.BOOKED){
      return true
    }
    return !this.step.canEditOrder;
  }

  isQuantityReadOnly(): boolean {
    return this.isReadOnly() || this.order.status === ECOSYSTEM_STEP_STATUS.BOOKED;
  }

  openFiles() {
    let dialogRef = this.dialog.open(EcosystemFileDialogComponent, {
      width: '800px',
      height: '600px',
      data: {
        ecosystem: this.step.ecosystem,
        stepId: this.step.id
      }
    });
    dialogRef.afterClosed().subscribe((file: EcosystemFile) => {
      if (file) {
        this.addFileToOrder(file);
      }
    });
  }

  removeJunctionFromOrder(junction: EcosystemFileJunction) {
    this.ecoFileJunctionService.remove(junction.id).subscribe(() => {
      this.order.ecosystem_file_junction.splice(this.order.ecosystem_file_junction.indexOf(junction), 1);
    });
  }

  addFileToOrder(file: EcosystemFile) {
    let fileJunction = new EcosystemFileJunction({
      file_id: file.id,
      item_id: this.order.id,
      item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_DEPOSIT_ORDER
    });
    this.ecoFileJunctionService.create(fileJunction).subscribe((createdFileJunction: EcosystemFileJunction) => {
      if (createdFileJunction) {
        this.order.ecosystem_file_junction.push(createdFileJunction);
      }
    });
  }

  setChatroom(room: ChatRoom) {
    room.loadContacts().subscribe(() => {
      this.room = room;
    });
  }

  sendEmail() {
    location.href = 'mailto:' + this.order.owner.email;
  }

  getAvatar() {
    if (this.order && this.order.owner && this.order.owner.avatar_url) {
      return {'background-image': 'url(' + this.order.owner.avatar_url + ')'};
    }
    return null;
  }
  expandTextAreas() {
    setTimeout(() => setTextareaHeightToFitContent([ this.depositDescriptionTextarea, this.depositSummaryAddressTextarea,  this.orderDepositDescriptionTextarea ]));
  }


  viewDetails(step: EcosystemStep) {
    this.ecosystemEditComponent.viewDetails(step.type, step.entity_id);
  }

  calculateAvailableAmount() {
    return this.order.deposit.quantity - this.order.deposit.booked
  }

  updateQuantity(amount: number){
    this.order.order_passport.quantity = amount;
    this.dirty = true;
    this.ecosystemCompilerSharedActionsService.prefillSetDirty(
      this.prefillService.prefillUpdateIfOrderChanged(this.order, false));
    this.onChange$.next();
  }

  updateWeight(data: {value: number, isActive: boolean}) {
    this.order.order_passport.setOrResetWeight(data.value, data.isActive);
    if (!data.isActive) {
      this.dirty = true;
      this.onChange$.next();
    }
  }

  getOrderQuantityText() {
    const checkableStatuses = [ECOSYSTEM_STEP_STATUS.WAITING_FOR_CONFIRMATION
      , ECOSYSTEM_STEP_STATUS.CONFIRMED, ECOSYSTEM_STEP_STATUS.BOOKED];
    if (checkableStatuses.includes(this.step.status)) {
      return this.translate.instant('Quantity reserved');
    } else {
      return this.translate.instant('Required quantity');
    }
  }
  getDepositAvabilityDate(){
    if(this.order.deposit.availability_date){
      return moment(this.order.deposit.availability_date).format('DD-MM-YYYY')
    } else {
      return this.translate.instant('');
    }
  }
  onOrderDescriptionChange(description: string){
    this.order.description = description;
    this.order.save().subscribe();
  }

}
