<div [ngClass]="{'depositlist__item': true, 'depositlist__need-item': isNeedType || isESMMatchType}"
     [attr.data-primary-key]="deposit.id">
  <div class="deposit-item__checkbox-container">
    <md-checkbox class="deposit-item__checkbox" (change)="toggleChecked(deposit.id);" [(ngModel)]="isChecked"></md-checkbox>
  </div>
  <div class="deposit-item__logo depositlist__item-picture"
       [ngClass]="{'depositlist__item-picture-link--esm': isESMMatchType}">
    <a *ngIf="deposit.logo || (!deposit.thumbnail && deposit.passport.image); else noLogo"
       [href]="deposit.logo || deposit.passport.image"
       [tabindex]="(deposit.thumbnail || deposit.passport.thumbnail) ? 0 : -1"
       target="_blank">
      <div *ngIf="deposit.thumbnail || deposit.passport.thumbnail"
           [ngStyle]="getLogoThumbnailStyle(deposit.thumbnail || deposit.passport.thumbnail)"></div>
    </a>
    <ng-template #noLogo>
      <div *ngIf="deposit.thumbnail || deposit.passport.thumbnail"
           [ngStyle]="getLogoThumbnailStyle(deposit.thumbnail || deposit.passport.thumbnail)"></div>
    </ng-template>
    <img *ngIf="deposit.isNoah" class="depositlist__item-noah-icon" src="assets/png/cloud-filled.png"/>
  </div>
  <div [ngClass]="{'depositlist__item-info': true, 'depositlist__item-need-info': isNeedType || isESMMatchType}">
    <div [ngClass]="{'depositlist__item-top': !isESMMatchType || !isNeedType
       , 'depositlist__item-top-esm ': isESMMatchType, 'depositlist__item-top-need ': isNeedType}">
      <div class="depositlist__item-name depositlist__item-bold">
        {{deposit.name}}
      </div>
      <div class="depositlist__item-appellation">
        <ng-container *ngIf="!isNeedType">
           <span class="depositlist__item-appellation-quantity depositlist__item-bold">
             {{deposit.quantity ? deposit.quantity : 0}}
           </span>
          <span class="depositlist__item-appellation-unit depositlist__item-bold">
             {{PASSPORT_UNITS_MAP[deposit.passport.unit] | translate}}
           </span>
        </ng-container>
        <ng-container *ngIf="isNeedType">
           <span class="depositlist__item-appellation-quantity">
             {{deposit.booked ? deposit.booked : 0}}
           </span>
          <span class="depositlist__item-appellation-unit">
             {{PASSPORT_UNITS_MAP[deposit.passport.unit] | translate}}
           </span>
          <span class="depositlist__item-appellation-unit depositlist__item--bold-sm
           depositlist__item--quantity-status-reserved">
             {{ 'Booked' | translate | uppercase}}
           </span>

          <span class="depositlist__item-appellation-separator">/</span>

          <span class="depositlist__item-appellation-quantity">
             {{deposit.availableQuantity ? deposit.availableQuantity : 0}}
           </span>
          <span class="depositlist__item-appellation-unit">
             {{PASSPORT_UNITS_MAP[deposit.passport.unit] | translate}}
           </span>
          <span class="depositlist__item-appellation-unit depositlist__item--bold-sm
             depositlist__item--quantity-status-available">
             {{'Available' | translate | uppercase}}
           </span>
        </ng-container>
      </div>
      <div class="depositlist__item-appellation">
        <ng-container *ngIf="!isESMMatchType">
          <span class="depositlist__item-appellation-text">{{ 'Site' | translate }}: </span>
          <span class="depositlist__item-appellation-location">
               {{isNeedType ? deposit.deposit_location?.address : deposit.deposit_location?.name}}
          </span>
        </ng-container>
        <ng-container *ngIf="isESMMatchType">
           <span class="depositlist__item-appellation-location depositlist__item--bold-sm">
               {{deposit.deposit_location?.name}},&nbsp;
           </span>
          <span class="depositlist__item-appellation-location">
               {{ deposit.deposit_location?.address}}
           </span>
        </ng-container>
      </div>
    </div>
    <div [ngClass]="{'depositlist__item-bottom': !isESMMatchType || !isNeedType
       , 'depositlist__item-bottom-esm ': isESMMatchType, 'depositlist__item-bottom-need ': isNeedType}">
      <div class="depositlist__item-name depositlist__item-pp-name">
        {{state.multilanguage ? deposit.passport.english_name : deposit.passport.name}}
      </div>
      <div class="depositlist__item-appellation">
        <span class="depositlist__item-appellation-location">{{getConditionName(deposit.condition) | translate}}</span>
      </div>
      <div class="depositlist__item-date">
        <span>{{'Availability' | translate }}: </span>
        <span>
          {{formatDate(deposit.availability_date) | localizedDate: " dd/MM/y"}}
          <span *ngIf="deposit.availability_expiration">{{' - '}}{{formatDate(deposit.availability_expiration)
            | localizedDate: "dd/MM/y"}}
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="isAgentType">
    <div class="depositlist__item-conditions">
      <i *ngIf="this.deposit.status === DEPOSIT_STATUSES.PUBLISHED.value" class="small-ok--icon--gray"></i>
      <span class="depositlist__item-condition-status">{{statusText() | capitalize | translate}}</span>
    </div>
    <div class="depositlist__item-controls">
      <div class="depositlist__item-check-copy" (click)="duplicateDeposit(deposit)"></div>
      <div class="depositlist__item-edit" (click)="openDeposit(deposit)"></div>
    </div>
  </div>
  <div *ngIf="isNeedType" class="depositlist__item-need-controls">
    <div class="depositlist__item-need-view" (click)="openDeposit(deposit)"></div>
    <div class="depositlist__item-need-create" (click)="createNeed(deposit)"></div>
  </div>

  <div *ngIf="isESMMatchType" class="depositlist__item__esm-need-match-menu">
    <button md-icon-button [mdMenuTriggerFor]="menu">
      <md-icon>menu</md-icon>
    </button>
    <md-menu #menu="mdMenu" x-position="after">
      <button md-menu-item (click)="openDepositDialog()">{{'See details' | translate}}</button>
      <button md-menu-item (click)="onCreateEcosystem.emit(deposit)">{{'Create ecosystem' | translate}}</button>
      <button *ngIf="deposit.url_link"
              md-menu-item
              (click)="onNotifyReseller.emit(deposit)">
        {{'Send link of the reseller’s website' | translate}}
      </button>
    </md-menu>
  </div>
</div>