import { CrudService } from '../services/crud.service';
import { Observable } from 'rxjs/Observable';
import {ExtendedCrudService} from '../services/extended-crud.service';
import {PaginationUnmarshallingService} from '../services/pagination-unmarshalling.service';
import {map} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';

export class LazyLoadedList<T> {
  public currentPage: number;
  public pageCount: number;
  public isLoadingPage = false;
  public isExpanded = false;

  public toggleSubscriber: Subject<any>;
  public observerOnToggle = new Observable<any[]>((sub) => {
    this.toggleSubscriber = new Subject();
    this.loadRecursiveToTheEnd(this.toggleSubscriber);
    this.expandAfterNLoads(sub);
  });


  public observerOnCollapse = new Observable<any[]>((sub) => {
    if (this.toggleSubscriber) {
      this.toggleSubscriber.unsubscribe();
      this.toggleSubscriber = null;
    }
    sub.next();
    sub.complete();
  });

  constructor(public paginationUnmarshallingService: PaginationUnmarshallingService) {
    this.resetPages();
  }

  resetPages() {
    this.currentPage = 1;
  }

  private expandAfterNLoads(sub, n = 1) {
    this.toggleSubscriber.take(n).subscribe(() => {
        sub.next();
        sub.complete();
      }, () => {
        sub.error();
        sub.complete();
      }
    );
  }

  loadNextPage(service: CrudService, query: any): Observable<T[]> {
    if (this.isLoadingPage || this.pageCount === this.currentPage) {
      return Observable.empty();
    }
    this.isLoadingPage = true;

    return service
      .getResponse(Object.assign({page: this.currentPage + 1}, query || {}))
      .do((response) => {
        this.currentPage = Number(response.headers.get('X-Pagination-Current-Page'));
        this.pageCount = Number(response.headers.get('X-Pagination-Page-Count'));
        this.isLoadingPage = false;
      })
      .map((response) =>
        response.json().map((record: any) => service.buildModel(record))
      )
      ;
  }

  loadWithPagination(service: ExtendedCrudService, query) {
    this.isLoadingPage = true;
    return service.getWithPaginationData(query).pipe(map((response) => {
        this.isLoadingPage = false;
        const {currentPage, pageCount} = this.paginationUnmarshallingService.unmarshall(response);
        this.currentPage = currentPage;
        this.pageCount = pageCount;
        return response.json().map(item => service.buildModel(item));
      })).catch((error) => {
        this.isLoadingPage = false;
        return Observable.throw(error);
      });
  }

  loadAllDepositsRecursiveObserver() {
    return this.observerOnToggle;
  }

  stopLoadingAllDepositsObserver() {
    return this.observerOnCollapse;
  }

  private loadRecursiveToTheEnd(subscriber) {
    this.loadDataRecursive().subscribe((data) => {
      subscriber.next();
      if (!data.length) {
        subscriber.complete();
      } else if (!subscriber.closed) {
        this.afterLoadRecursive(data);
        this.loadRecursiveToTheEnd(subscriber);
      }
    }, () => {
      subscriber.error();
      subscriber.complete();
    });
  }

  public loadDataRecursive() {
    return Observable.of([]);
  }

  public afterLoadRecursive(data) {
  }
}
