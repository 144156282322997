import * as tslib_1 from "tslib";
import { TRANSFORM_TYPE } from '../../transformation-manager/values/transform-types.const';
import { PASSPORT_UNITS_TYPE } from '../../../entities/passport/values/passport-units.const';
import { ECOSYSTEM_STEP_TYPE } from '../values/ecosystem-step-type.const';
import lodash from 'lodash';
import * as i0 from "@angular/core";
export var PREFILL_OFFER_ID = -1;
var EcosystemPrefillService = /** @class */ (function () {
    function EcosystemPrefillService() {
        this.junction = new Map();
        this.input = new Map();
        this.output = new Map();
    }
    EcosystemPrefillService.prototype.createPrefillDependencyTree = function (leafs, junction, input, output, prefillStore, preferPrefill) {
        var _this = this;
        if (prefillStore === void 0) { prefillStore = {}; }
        if (preferPrefill === void 0) { preferPrefill = true; }
        var nodes = leafs.slice();
        var maxCount = output.size + leafs.length + 1;
        // console.log(leafs, prefillStore, preferPrefill);
        var visited = {};
        var _loop_1 = function () {
            if (!maxCount) {
                return { value: {} };
            }
            maxCount -= 1;
            var node = nodes.shift();
            var order = this_1.getOrderForPrefill(node, output).order;
            var orderStepId = order && order.step_id;
            var orderList = junction.get(orderStepId) || [];
            var prefillIn = this_1.prefillOneInOrder(order, node, orderList, input, output, prefillStore, preferPrefill);
            if (!!prefillIn.skip) {
                return "continue";
            }
            this_1.putInStore(this_1.getPrefillStoreId(order), prefillIn, true, prefillStore);
            var prefillOut = this_1.prefillOneOrderOutput(orderList, input, prefillStore, preferPrefill);
            if (preferPrefill || !prefillOut.skip) {
                orderList.forEach(function (orderInList) { return _this.putInStore(orderInList.id, prefillOut, false, prefillStore); });
            }
            if (preferPrefill && !visited[order.id] || this_1.isNumber(prefillOut.out)) {
                nodes.push(order);
                visited[order.id] = order;
            }
        };
        var this_1 = this;
        while (!lodash.isEmpty(nodes)) {
            var state_1 = _loop_1();
            if (typeof state_1 === "object")
                return state_1.value;
        }
        // console.log(prefillStore, 'final');
        return prefillStore;
    };
    EcosystemPrefillService.prototype.putInStore = function (storeId, prefill, isIn, prefillStore) {
        if (!prefillStore[storeId]) {
            prefillStore[storeId] = { in: null, out: null };
        }
        if (isIn) {
            prefillStore[storeId].in = prefill.in || null;
        }
        else {
            prefillStore[storeId].out = prefill.out || null;
        }
    };
    EcosystemPrefillService.prototype.getPrefillStoreId = function (order) {
        return this.isOffer(order) ? PREFILL_OFFER_ID : (order && order.id);
    };
    EcosystemPrefillService.prototype.getOrderForPrefill = function (node, output) {
        var orderList = output.get(node.step_id) || [];
        var order = orderList.length ? orderList[0] : null;
        if (orderList.length > 1) {
            order = orderList.find(function (orderAlternative) { return orderAlternative.child_step.id === node.step_id; });
        }
        return { order: order, orderList: orderList };
    };
    EcosystemPrefillService.prototype.prefillOneInOrder = function (order, beforeOrder, orderList, input, output, prefillStore, preferPrefill) {
        if (input === void 0) { input = this.input; }
        if (output === void 0) { output = this.output; }
        if (prefillStore === void 0) { prefillStore = this.prefillStore; }
        // console.log('before', beforeOrder, 'current', order, 'inputs');
        if (!order) {
            // console.log('skip?');
            return { skip: true };
        }
        var prefill = this.getPrefillInput(order, beforeOrder, prefillStore, preferPrefill);
        // console.log(prefill, 'input prefill');
        return { in: prefill };
    };
    EcosystemPrefillService.prototype.prefillOneOrderOutput = function (orderList, input, prefillStore, preferPrefill) {
        if (input === void 0) { input = this.input; }
        if (prefillStore === void 0) { prefillStore = this.prefillStore; }
        var isOutPrefill = this.canOutPrefillBeCalculated(orderList, prefillStore, preferPrefill);
        // console.log(isOutPrefill, 'can out prefill');
        if (isOutPrefill) {
            if (!preferPrefill) {
                this.addMissingInput(orderList, prefillStore);
            }
            var inputWithSubstitution = this.createSubstitutionJunctionIfConversionInherits(orderList, input);
            // console.log(inputWithSubstitution, 'out substitution');
            var outPrefill = this.transformChange(inputWithSubstitution, prefillStore, preferPrefill);
            // console.log(outPrefill, 'out prefill');
            return { out: outPrefill };
        }
        return { skip: true };
    };
    EcosystemPrefillService.prototype.canOutPrefillBeCalculated = function (orderList, prefillStore, preferPrefill) {
        var _this = this;
        if (preferPrefill === void 0) { preferPrefill = true; }
        var isOutPrefill = orderList.every(function (orderInList) { return _this.isInputExistsInStore(orderInList.id, prefillStore)
            || (!preferPrefill && _this.isInputProvidedByUser(orderInList)); });
        return isOutPrefill && this.isTransformOrder(orderList.length ? orderList[0] : {});
    };
    EcosystemPrefillService.prototype.isInputExistsInStore = function (id, prefillStore) {
        return prefillStore[id] && this.isNumber(prefillStore[id].in);
    };
    EcosystemPrefillService.prototype.isInputProvidedByUser = function (order) {
        return this.isNumber(this.getQuantity(order, true));
    };
    EcosystemPrefillService.prototype.addMissingInput = function (orderList, prefillStore) {
        var _this = this;
        orderList.forEach(function (order) {
            if (!_this.isInputExistsInStore(order.id, prefillStore)) {
                _this.putInStore(order.id, { in: _this.getQuantity(order, true) }, true, prefillStore);
            }
        });
        // console.log(prefillStore, orderList, 'mis fix?');
    };
    EcosystemPrefillService.prototype.createSubstitutionJunctionIfConversionInherits = function (orderList, input) {
        var _this = this;
        return orderList.map(function (orderInList) {
            var childStepId = !!orderInList && !!orderInList.child_step && _this.isNumber(orderInList.child_step.id);
            var childOrder = childStepId ? (input.get(orderInList.step_id) || [])
                .find(function (substitution) { return substitution && substitution.step_id === orderInList.child_step.id; }) : null;
            return {
                input: orderInList,
                substitution: _this.getOrderSubstitution(childOrder)
            };
        });
    };
    EcosystemPrefillService.prototype.getOrderSubstitution = function (childOrder) {
        if (this.isTransformOrder(childOrder)) {
            return this.substitutionForTransformOrder(childOrder.transformation);
        }
        else if (this.isDeposit(childOrder)) {
            return this.substitutionForDepositOrder(childOrder.deposit_passport);
        }
        else if (this.isNoneOrder(childOrder)) {
            return this.substitutionForNoneOrder();
        }
        return null;
    };
    EcosystemPrefillService.prototype.substitutionForNoneOrder = function () {
        return { conversion: 1, unit: PASSPORT_UNITS_TYPE.KG };
    };
    EcosystemPrefillService.prototype.substitutionForTransformOrder = function (transform) {
        if (this.transformWithoutConversionWithOut(transform)) {
            return { conversion: 1, unit: PASSPORT_UNITS_TYPE.KG };
        }
        return { conversion: transform.out_conversion, unit: transform.output_unit };
    };
    EcosystemPrefillService.prototype.substitutionForDepositOrder = function (passport) {
        return { conversion: passport.conversion, unit: passport.unit };
    };
    EcosystemPrefillService.prototype.prefillAndChangeByEcosystem = function (ecosystem, preferPrefill) {
        if (preferPrefill === void 0) { preferPrefill = true; }
        return this.prefillAndChangeStore(ecosystem.steps, ecosystem.deposit_orders, ecosystem.transformation_orders, ecosystem.ecosystem_offer, preferPrefill);
    };
    EcosystemPrefillService.prototype.prefillAndChangeStore = function (steps, depositOrders, transforms, offer, preferPrefill) {
        if (preferPrefill === void 0) { preferPrefill = true; }
        var data = this.prefill(steps, depositOrders, transforms, offer, preferPrefill);
        var compare = {};
        if (this.prefillStore) {
            compare = this.comparePrefills(data.prefillStore);
        }
        Object.assign(this, data);
        return compare;
    };
    EcosystemPrefillService.prototype.prefill = function (steps, depositOrders, transforms, offer, preferPrefill) {
        if (preferPrefill === void 0) { preferPrefill = true; }
        var inputs = this.createConnection(steps, depositOrders, transforms, offer);
        var leafs = inputs.leafs, junction = inputs.junction, input = inputs.input, output = inputs.output;
        return tslib_1.__assign({ prefillStore: this.createPrefillDependencyTree(leafs, junction, input, output, {}, preferPrefill) }, inputs);
    };
    EcosystemPrefillService.prototype.createConnection = function (steps, depositOrders, transforms, offer) {
        var _this = this;
        var stepChild = new Map();
        var notOrderSteps = [];
        var mapSteps = new Map();
        var mapInOrders = new Map();
        var mapStepToOrder = new Map();
        var mapOutOrders = new Map();
        var leafs = depositOrders.slice();
        steps.forEach(function (step) {
            mapSteps.set(step.id, step);
            if (step.parent_id) {
                _this.addToMap(stepChild, step.parent_id, step.id);
            }
            if (![ECOSYSTEM_STEP_TYPE.DEPOSIT, ECOSYSTEM_STEP_TYPE.TRANSFORM, ECOSYSTEM_STEP_TYPE.NEED].includes(step.type)) {
                notOrderSteps.push(step);
            }
        });
        notOrderSteps.forEach(function (step) {
            _this.updateStepToOrderJunction(step.parent_id, step.id, stepChild.get(step.id), null, mapInOrders, mapStepToOrder, mapOutOrders);
        });
        var getParentStep = function (id) { return mapSteps.get(id) && mapSteps.get(id).parent_id; };
        for (var _i = 0, transforms_1 = transforms; _i < transforms_1.length; _i++) {
            var order = transforms_1[_i];
            var parentId = getParentStep(order.step_id);
            var childId = order.child_step ? order.child_step.id : null;
            this.updateStepToOrderJunction(parentId, order.step_id, childId, order, mapInOrders, mapStepToOrder, mapOutOrders);
        }
        depositOrders.forEach(function (order) {
            var parentId = getParentStep(order.step_id);
            _this.updateStepToOrderJunction(parentId, order.step_id, null, order, mapInOrders, mapStepToOrder, mapOutOrders);
        });
        var offerStep = steps.find(function (step) { return step.type === ECOSYSTEM_STEP_TYPE.NEED; });
        this.addToMap(mapStepToOrder, offerStep.id, offer);
        var offerConnection = mapInOrders.get(offerStep.id);
        if (offerConnection && offerConnection[0]) {
            this.addToMap(mapOutOrders, offerConnection[0].step_id, offer);
        }
        // console.log('connections in', mapInOrders, 'junction', mapSteps, 'out', mapOutOrders);
        // console.log('rawData:', steps);
        leafs.push.apply(leafs, this.addAllTransformOrdersWithoutOrderChild(transforms, mapInOrders, mapOutOrders));
        // console.log('----leafs', leafs);
        return {
            leafs: leafs,
            junction: mapStepToOrder,
            input: mapInOrders,
            output: mapOutOrders
        };
    };
    EcosystemPrefillService.prototype.addAllTransformOrdersWithoutOrderChild = function (transforms, input, output) {
        return transforms.filter(function (transform) {
            var inputs = input.get(transform.step_id);
            var outputs = output.get(transform.step_id);
            var inputExist = inputs && inputs.length && !!inputs.find(function (child) { return !!child; });
            var outputExist = !outputs || !outputs.length || outputs.find(function (child) { return !!child; });
            return !inputExist && outputExist;
        });
    };
    EcosystemPrefillService.prototype.updateStepToOrderJunction = function (parenStepId, stepId, childStepId, order, input, junction, output) {
        if (input === void 0) { input = this.input; }
        if (junction === void 0) { junction = this.junction; }
        if (output === void 0) { output = this.output; }
        if (this.isNumber(parenStepId)) {
            this.addToMap(input, parenStepId, order);
        }
        this.addToMap(junction, stepId, order);
        if (this.isNumber(childStepId)) {
            this.addToMap(output, childStepId, order);
        }
    };
    EcosystemPrefillService.prototype.prefillUpdateIfOrderChanged = function (order, isInputChanged) {
        var newPrefill = this.recalculatePrefillIfOrderChanged(order, isInputChanged);
        if (newPrefill) {
            this.updateConnection(order);
            // console.log(newPrefill, this.prefillStore);
            var changes = this.comparePrefills(newPrefill);
            this.prefillStore = newPrefill;
            this.prefillStoreChange = changes;
            // console.log(changes, 'changes');
            return changes;
        }
        else {
            return {};
        }
    };
    EcosystemPrefillService.prototype.updateConnection = function (order) {
        var stepOrders = this.junction.get(order.step_id);
        if (stepOrders) {
            var junctionOrder = stepOrders.find(function (orderInJunction) { return orderInJunction.id === order.id; });
            if (junctionOrder) {
                this.setOrderOut(junctionOrder, this.getQuantity(order, false));
                // console.log(order, this.junction, this.getQuantity(order, false), 'update', junctionOrder);
            }
        }
    };
    EcosystemPrefillService.prototype.isPrefillInited = function () {
        return !!this.prefillStore;
    };
    EcosystemPrefillService.prototype.getPrefilledValue = function (id) {
        var isExistChange = this.prefillStoreChange && this.prefillStoreChange[id];
        var input = isExistChange && this.prefillStoreChange[id].in && this.prefillStoreChange[id].in.after;
        var output = isExistChange && this.prefillStoreChange[id].out && this.prefillStoreChange[id].out.after;
        return this.prefillStore[id] || { in: input, out: output };
    };
    EcosystemPrefillService.prototype.getOrderInPrefill = function (order) {
        var id = this.getPrefillStoreId(order);
        return (!!this.prefillStore && !!this.prefillStore[id]) ? this.prefillStore[id].in : null;
    };
    EcosystemPrefillService.prototype.getOrderOutPrefill = function (order) {
        var id = this.getPrefillStoreId(order);
        return (!!this.prefillStore && !!this.prefillStore[id]) ? this.prefillStore[id].out : null;
    };
    EcosystemPrefillService.prototype.getFirstOrderByStep = function (step) {
        var orders = this.junction.get(step.id);
        return (orders && orders.length) ? orders[0] : null;
    };
    EcosystemPrefillService.prototype.getParentOrderByStepId = function (id) {
        var orders = this.output.get(id);
        return (orders && orders.length) ? orders[0] : null;
    };
    EcosystemPrefillService.prototype.resetOrderOut = function (order) {
        if (this.isTransformOrder(order)) {
            order.out_order_passport.quantity = null;
        }
        else if (this.isDeposit(order)) {
            order.order_passport.quantity = null;
        }
    };
    EcosystemPrefillService.prototype.getStepByTargetId = function (step) {
        var orders = this.output.get(step.id);
        return (orders && orders.length) ? orders[0] : null;
    };
    EcosystemPrefillService.prototype.setOrderInAsZero = function (order) {
        if (this.prefillStore[this.getPrefillStoreId(order)]) {
            this.prefillStore[this.getPrefillStoreId(order)].in = 0;
        }
        if (this.isTransformOrder(order)) {
            order.in_order_passport.quantity = 0;
        }
        else if (this.isOffer(order)) {
            order.quantity = 0;
        }
    };
    EcosystemPrefillService.prototype.setOrderOut = function (order, value) {
        value = this.isNumber(value) ? value : null;
        if (this.prefillStore[this.getPrefillStoreId(order)]) {
            this.prefillStore[this.getPrefillStoreId(order)].out = value;
        }
        if (this.isTransformOrder(order)) {
            order.out_order_passport.quantity = value;
        }
        else if (this.isOffer(order)) {
            order.quantity = value;
        }
        else if (this.isDeposit(order)) {
            order.order_passport.quantity = value;
        }
    };
    EcosystemPrefillService.prototype.getOrderOut = function (order) {
        if (this.prefillStore[this.getPrefillStoreId(order)]) {
            return this.prefillStore[this.getPrefillStoreId(order)].out;
        }
        if (this.isTransformOrder(order)) {
            return order.out_order_passport.quantity;
        }
        else if (this.isOffer(order)) {
            return order.quantity;
        }
    };
    EcosystemPrefillService.prototype.recalculatePrefillIfOrderChanged = function (order, isInputChanged) {
        if (!this.prefillStore || !order) {
            return null;
        }
        var copyOldPrefill = lodash.cloneDeep(this.prefillStore);
        if (this.isTransformOrder(order)) {
            var stepOrders = this.junction.get(order.step_id);
            if (!stepOrders) {
                return this.prefillStore;
            }
            var outputQuantity_1;
            if (isInputChanged) {
                if (!copyOldPrefill[order.id]) {
                    copyOldPrefill[order.id] = { in: null, out: null };
                }
                copyOldPrefill[order.id].in = this.typecastNumber(this.getQuantity(order, true));
                outputQuantity_1 = this.prefillOneOrderOutput(stepOrders, this.input, copyOldPrefill).out;
            }
            else {
                outputQuantity_1 = this.typecastNumber(this.getQuantity(order, false));
            }
            stepOrders.filter(function (outputOrder) { return copyOldPrefill[outputOrder.id]; })
                .forEach(function (outputOrder) { return copyOldPrefill[outputOrder.id].out = outputQuantity_1; });
        }
        else {
            var quantity = this.typecastNumber(this.getQuantity(order, false));
            if (this.isNumber(quantity)) {
                if (this.isDeposit(order)) {
                    order.order_passport.quantity = quantity;
                }
                else if (this.isOffer(order)) {
                    order.quantity = quantity;
                }
            }
        }
        return this.createPrefillDependencyTree([order], this.junction, this.input, this.output, copyOldPrefill);
    };
    EcosystemPrefillService.prototype.typecastNumber = function (num) {
        if (typeof num === 'string' && !!num) {
            return Number(num);
        }
        else if (this.isNumber(num)) {
            return Number(num);
        }
        return null;
    };
    EcosystemPrefillService.prototype.getQuantity = function (order, isInQuantity) {
        if (this.isTransformOrder(order)) {
            var passport = isInQuantity ? order.in_order_passport : order.out_order_passport;
            return passport ? passport.quantity : null;
        }
        else if (this.isDeposit(order)) {
            var passport = order.order_passport;
            return passport ? passport.quantity : null;
        }
        else {
            return order && order.quantity;
        }
    };
    EcosystemPrefillService.prototype.comparePrefills = function (newPrefill, oldPrefill) {
        if (oldPrefill === void 0) { oldPrefill = this.prefillStore; }
        var allPrefillIds = new Set(Object.keys(newPrefill).concat(Object.keys(oldPrefill)));
        var orderPrefillChanged = {};
        allPrefillIds.forEach(function (orderId) {
            var oldIn = oldPrefill[orderId] && oldPrefill[orderId].in;
            var oldOut = oldPrefill[orderId] && oldPrefill[orderId].out;
            var newIn = newPrefill[orderId] && newPrefill[orderId].in;
            var newOut = newPrefill[orderId] && newPrefill[orderId].out;
            if (newIn !== oldIn || oldOut !== newOut) {
                orderPrefillChanged[orderId] = tslib_1.__assign({}, (newIn !== oldIn ? { in: { before: oldIn, after: newIn } } : {}), (newIn !== oldIn ? { out: { before: oldOut, after: newOut } } : {}));
            }
        });
        return orderPrefillChanged;
    };
    EcosystemPrefillService.prototype.addToMap = function (map, stepId, order) {
        if (stepId && map.has(stepId)) {
            map.get(stepId).push(order);
        }
        else {
            map.set(stepId, [order]);
        }
    };
    EcosystemPrefillService.prototype.getPrefillInput = function (order, inOrder, prefillStore, preferPrefill) {
        if (preferPrefill === void 0) { preferPrefill = true; }
        if (!preferPrefill && this.isNumber(this.getQuantity(order, true))) {
            return this.getQuantity(order, true);
        }
        if (this.isOffer(order)) {
            if (this.isDeposit(inOrder)) {
                return this.connectionDepositToNeed(order, inOrder);
            }
            else {
                inOrder = this.getSubstituteInTransformation(inOrder, prefillStore, preferPrefill);
                return this.connectionTransformToNeed(order, inOrder);
            }
        }
        else if (this.isTransformOrder(order)) {
            if (this.isDeposit(inOrder)) {
                return this.connectionDepositToTransform(order, inOrder);
            }
            else {
                inOrder = this.getSubstituteInTransformation(inOrder, prefillStore, preferPrefill);
                return this.connectionTransformToTransform(order, inOrder);
            }
        }
        return null;
    };
    EcosystemPrefillService.prototype.getSubstituteInTransformation = function (inOrder, prefillStore, preferPrefill) {
        if (!!prefillStore && prefillStore[inOrder.id] && (preferPrefill || prefillStore[inOrder.id].out)) {
            inOrder = lodash.clone(inOrder);
            var passport = lodash.clone(inOrder.out_order_passport);
            passport.quantity = prefillStore[inOrder.id].out;
            inOrder.out_order_passport = passport;
        }
        return inOrder;
    };
    EcosystemPrefillService.prototype.isDeposit = function (order) {
        return !!order && !!order.deposit_id;
    };
    EcosystemPrefillService.prototype.isOffer = function (order) {
        return !!order && !!order.need;
    };
    EcosystemPrefillService.prototype.isTransformOrder = function (order) {
        return !!order && !!order.transformation_id;
    };
    EcosystemPrefillService.prototype.isNoneOrder = function (order) {
        return !order;
    };
    EcosystemPrefillService.prototype.connectionDepositToNeed = function (offer, inOrder) {
        var conversionOut = this.getConversion(inOrder.deposit_passport.conversion, inOrder.deposit_passport.unit);
        var conversion = this.getConversion(offer.need.passport.conversion, offer.need.passport.unit);
        var quantity = this.getQuantity(inOrder, false);
        if (inOrder.deposit_passport.unit === offer.need.passport.unit) {
            return quantity;
        }
        else {
            return this.calculateAsDefault(quantity, conversionOut, conversion);
        }
    };
    EcosystemPrefillService.prototype.connectionTransformToNeed = function (offer, inOrder) {
        var conversionOut = this.getConversion(inOrder.transformation.out_conversion, inOrder.transformation.output_unit);
        var conversion = this.getConversion(offer.need.passport.conversion, offer.need.passport.unit);
        var quantity = this.getQuantity(inOrder, false);
        if (inOrder.transformation.output_unit === offer.need.passport.unit) {
            return quantity;
        }
        else {
            return this.calculateAsDefault(quantity, conversionOut, conversion);
        }
    };
    EcosystemPrefillService.prototype.connectionDepositToTransform = function (order, inOrder) {
        var conversionOut = this.getConversion(inOrder.deposit_passport.conversion, inOrder.deposit_passport.unit);
        var conversion = this.getConversion(order.transformation.in_conversion, order.transformation.input_unit);
        var quantity = this.getQuantity(inOrder, false);
        if (order.transformation.input_unit === inOrder.deposit_passport.unit || order.isWithoutUnit()) {
            return quantity;
        }
        else {
            return this.calculateAsDefault(quantity, conversionOut, conversion);
        }
    };
    EcosystemPrefillService.prototype.connectionTransformToTransform = function (order, inOrder) {
        var conversionOut = this.getConversion(inOrder.transformation.out_conversion, inOrder.transformation.output_unit);
        var conversion = this.getConversion(order.transformation.in_conversion, order.transformation.input_unit);
        var quantity = this.getQuantity(inOrder, false);
        if (order.transformation.input_unit === inOrder.transformation.output_unit || order.isWithoutUnit()) {
            return quantity;
        }
        else {
            return this.calculateAsDefault(quantity, conversionOut, conversion);
        }
    };
    EcosystemPrefillService.prototype.calculateAsDefault = function (quantity, conversionOut, conversion) {
        if (this.isNumber(quantity) && conversion && conversionOut) {
            return quantity * conversionOut / conversion;
        }
        return null;
    };
    EcosystemPrefillService.prototype.transformChange = function (inOrder, prefillStore, preferPrefill) {
        var _this = this;
        if (preferPrefill === void 0) { preferPrefill = true; }
        if (!preferPrefill && this.isNumber(this.getQuantity(inOrder[0].input, false))) {
            return this.getQuantity(inOrder[0].input, false);
        }
        if (prefillStore) {
            inOrder = inOrder.map(function (data) { return ({
                input: _this.getSubstituteOutTransformation(data.input, prefillStore), substitution: data.substitution
            }); });
        }
        return this.transformInToOut(inOrder);
    };
    EcosystemPrefillService.prototype.transformInToOut = function (inOrder) {
        var isConvertedToDefaultUnit = this.transformOrderWithoutConversion(inOrder[0].input);
        if (isConvertedToDefaultUnit) {
            return this.transformInToOutWithoutConversion(inOrder);
        }
        else {
            return this.transformInToOutWithConversion(inOrder.map(function (item) { return item.input; }));
        }
    };
    EcosystemPrefillService.prototype.getSubstituteOutTransformation = function (inOrder, prefillStore) {
        if (!!prefillStore && prefillStore[inOrder.id]) {
            inOrder = lodash.clone(inOrder);
            var passport = lodash.clone(inOrder.in_order_passport);
            passport.quantity = prefillStore[inOrder.id].in;
            inOrder.in_order_passport = passport;
        }
        return inOrder;
    };
    EcosystemPrefillService.prototype.transformInToOutWithConversion = function (inOrder) {
        var conversion = inOrder[0].transformation.output_quantity;
        var conversionOut = this.getConversion(inOrder[0].transformation.out_conversion, inOrder[0].transformation.output_unit);
        var conversionIn = this.getConversion(inOrder[0].transformation.in_conversion, inOrder[0].transformation.input_unit);
        if (!this.isNumber(conversion) || !this.isNumber(conversionOut) || !this.isNumber(conversionIn) || !conversionOut) {
            return null;
        }
        var out = 0;
        for (var _i = 0, inOrder_1 = inOrder; _i < inOrder_1.length; _i++) {
            var order = inOrder_1[_i];
            var quantity = this.getQuantity(order, true);
            if (this.isNumber(quantity)) {
                if (this.inOutTonToKg(order)) {
                    out += quantity * conversion;
                }
                else {
                    out += quantity * conversion * conversionIn / conversionOut;
                }
            }
            else {
                return null;
            }
        }
        return out;
    };
    EcosystemPrefillService.prototype.inOutTonToKg = function (order) {
        if (order.transformation.input_unit === PASSPORT_UNITS_TYPE.TONNE) {
            return order.transformation.output_unit === PASSPORT_UNITS_TYPE.KG || !order.transformation.output_unit;
        }
        return false;
    };
    EcosystemPrefillService.prototype.transformInToOutWithoutConversion = function (inOrder) {
        var out = 0;
        for (var _i = 0, inOrder_2 = inOrder; _i < inOrder_2.length; _i++) {
            var order = inOrder_2[_i];
            var toDefaultConversion = this.getSubstitution(order.substitution);
            var quantity = this.getQuantity(order.input, true);
            if (this.isNumber(quantity) && this.isNumber(toDefaultConversion)) {
                out += quantity * toDefaultConversion;
            }
            else {
                return null;
            }
        }
        return out;
    };
    EcosystemPrefillService.prototype.getSubstitution = function (substitution) {
        return this.getConversion(substitution.conversion, substitution.unit);
    };
    EcosystemPrefillService.prototype.isNumber = function (obj) {
        return typeof obj === 'number' && obj !== Number.NaN;
    };
    EcosystemPrefillService.prototype.getConversion = function (conversion, unit) {
        return this.unitToConversion(unit) || (this.isNumber(conversion) ? conversion : null);
    };
    EcosystemPrefillService.prototype.unitToConversion = function (unit) {
        if (!unit || PASSPORT_UNITS_TYPE.KG === unit) {
            return 1;
        }
        else if (PASSPORT_UNITS_TYPE.TONNE === unit) {
            return 1000;
        }
    };
    EcosystemPrefillService.prototype.transformWithoutConversionWithOut = function (order) {
        return [TRANSFORM_TYPE.TRANSPORT, TRANSFORM_TYPE.STORAGE].includes(order.transformation_type);
    };
    EcosystemPrefillService.prototype.transformOrderWithoutConversion = function (order) {
        return this.transformWithoutConversionWithOut(order.transformation);
    };
    EcosystemPrefillService.ngInjectableDef = i0.defineInjectable({ factory: function EcosystemPrefillService_Factory() { return new EcosystemPrefillService(); }, token: EcosystemPrefillService, providedIn: "root" });
    return EcosystemPrefillService;
}());
export { EcosystemPrefillService };
