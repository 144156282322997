import * as tslib_1 from "tslib";
import { SynonymService } from '../services/synonym.service';
import { ActiveRecord } from './active-record.class';
var Synonym = /** @class */ (function (_super) {
    tslib_1.__extends(Synonym, _super);
    function Synonym(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = SynonymService;
        return _this;
    }
    Synonym.prototype.fields = function () {
        return [
            'id',
            'name',
            'tag_id',
        ];
    };
    return Synonym;
}(ActiveRecord));
export { Synonym };
