<div class="pagination-container" [ngClass]="getClassColor()">
    <div class="arrow left" (click)="navigateToPage(currentPage - 1)" [class.disabled]="currentPage === 1">
        <app-left-arrow-icon></app-left-arrow-icon>
    </div>
    <ng-container *ngFor="let page of pages">
        <div *ngIf="page !== '...'" class="page" [class.selected]="page === +currentPage" (click)="navigateToPage(page)">
            <span>{{ page }}</span>
        </div>
        <div *ngIf="page === '...'" class="jump">
            <app-jump-page-icon></app-jump-page-icon>
        </div>
    </ng-container>
    <div class="arrow right" (click)="navigateToPage(currentPage + 1)" [class.disabled]="currentPage === totalPages">
        <app-right-arrow-icon></app-right-arrow-icon>
    </div>
</div>