<div class="statistics">
    <h1>{{'Segment statistics' | translate}}</h1>
    <div class="statistics__box widget--shadow widget--rounded-corners">
        <div class="statistics__header">
            <div class="statistics__tabs">
                <ul>
                    <li
                            *ngFor="let segment of segments"
                            (click)="changeSegment(segment)"
                            [ngClass]="{'active': segment === selectedSegment}"
                    >{{ ADMIN_STATISTICS_SEGMENTS_VIEW[segment] | translate }}</li>
                </ul>
            </div>
            <div class="statistics__date-filter">
                <div class="statistics__date-filter--datepicker">
                    <i class="icon icon--calendar"></i>
                    <md-input-container class="date input-group">
                        <input mdInput
                               ng2-datetime-picker
                               autocomplete="off" 
                               date-only="true"
                               date-format="DD-MM-YYYY"
                               parse-format="YYYY-MM-DD"
                               placeholder="{{ 'from' | translate }}"
                               [(ngModel)]="segmentsDateFrom"
                               (ngModelChange)="getSegmentData()"
                        >
                    </md-input-container>
                </div>
                <span class="statistics__date-filter--dash">-</span>
                <div class="statistics__date-filter--datepicker">
                    <i class="icon icon--calendar"></i>
                    <md-input-container class="date input-group">
                        <input mdInput
                               ng2-datetime-picker
                               autocomplete="off" 
                               date-only="true"
                               date-format="DD-MM-YYYY"
                               parse-format="YYYY-MM-DD"
                               placeholder="{{ 'to' | translate }}"
                               [(ngModel)]="segmentsDateTo"
                               (ngModelChange)="getSegmentData()"
                        >
                    </md-input-container>
                </div>
            </div>
        </div>
        <div class="statistics__body">
            <div class="statistics__total-stats">
                <div class="stat">
                    <span class="value">{{ segmentStats.total }}</span>
                    <span class="title">{{ 'Total' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.last_year }}</span>
                    <span class="title">{{ 'Created this year' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.last_month }}</span>
                    <span class="title">{{ 'Created this month' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.last_week }}</span>
                    <span class="title">{{ 'Created this week' | translate }}</span>
                </div>
            </div>
        </div>
        <div class="statistics__tabs underlined">
            <ul>
                <li class="active">{{ 'Evolution' | translate }}</li>
            </ul>
        </div>
        <div class="statistics__chart">
            <google-chart
                    *ngIf="segmentStats.evolution && segmentStats.evolution.length"
                    id="segmentsLineChart"
                    [chartData]="segmentStats.evolution"
                    chartType="LineChart">
            </google-chart>
            <div class="noData" *ngIf="!segmentStats.evolution || !segmentStats.evolution.length">{{ 'No data' | translate }}</div>
        </div>
    </div>
</div>
