import * as tslib_1 from "tslib";
import { OnChanges, OnInit, EventEmitter, SimpleChanges } from '@angular/core';
var FamilyFilterComponent = /** @class */ (function () {
    function FamilyFilterComponent() {
        this.FamilyFilterChange = new EventEmitter();
        this.userRole = 'AGENT';
        this.ownPassportOnly = false;
        this.isOpen = false;
        this.filterList = [];
        this.selectMap = {};
        this.familyFilter = [];
        this.familySelected = [];
        this.skipOnChange = false;
    }
    Object.defineProperty(FamilyFilterComponent.prototype, "setFamilyFilter", {
        set: function (items) {
            var _this = this;
            var itemSet = new Set(items);
            this.allSelected = true;
            this.filterList.forEach(function (item) {
                item.value = itemSet.has(item.family);
                _this.allSelected = _this.allSelected && itemSet.has(item.family);
            });
            this.skipOnChange = true;
        },
        enumerable: true,
        configurable: true
    });
    FamilyFilterComponent.prototype.onClick = function (targetElement) {
        if (this.isOpen && !this.filterFamily.nativeElement.contains(targetElement)) {
            this.isOpen = false;
            // this.ref.detectChanges();
        }
    };
    FamilyFilterComponent.prototype.ngOnChanges = function (changes) {
        if (changes.ownPassportOnly && !changes.ownPassportOnly.previousValue && changes.ownPassportOnly.currentValue) {
            this.filterList = [
                {
                    name: 'FAMILY_VIEW_TYPE_1',
                    short: 'FAMILY_SHORT_VIEW_TYPE_1',
                    value: true,
                    family: 1 /* FAMILY_LOCAL */
                }
            ];
            this.onChange();
        }
        if (changes.ownPassportOnly && changes.ownPassportOnly.previousValue && !changes.ownPassportOnly.currentValue) {
            this.initFilters();
        }
    };
    FamilyFilterComponent.prototype.ngOnInit = function () {
        this.initFilters();
    };
    FamilyFilterComponent.prototype.initFilters = function () {
        switch (this.userRole) {
            case 'supervisor':
            case 'sales':
            case 'agent':
                this.filterList = [
                    {
                        name: 'FAMILY_VIEW_TYPE_1',
                        short: 'FAMILY_SHORT_VIEW_TYPE_1',
                        value: true,
                        family: 1 /* FAMILY_LOCAL */
                    },
                    {
                        name: 'FAMILY_VIEW_TYPE_2',
                        short: 'FAMILY_SHORT_VIEW_TYPE_2',
                        value: true,
                        family: 2 /* FAMILY_MANUFACTURER */
                    },
                    {
                        name: 'FAMILY_VIEW_TYPE_3',
                        short: 'FAMILY_SHORT_VIEW_TYPE_3',
                        value: true,
                        family: 3 /* FAMILY_GENERIC */
                    }
                ];
                break;
            default:
                this.filterList = [
                    {
                        name: 'FAMILY_VIEW_TYPE_2',
                        short: 'FAMILY_SHORT_VIEW_TYPE_2',
                        value: true,
                        family: 2 /* FAMILY_MANUFACTURER */
                    },
                    {
                        name: 'FAMILY_VIEW_TYPE_3',
                        short: 'FAMILY_SHORT_VIEW_TYPE_3',
                        value: true,
                        family: 3 /* FAMILY_GENERIC */
                    }
                ];
        }
        this.onChange();
    };
    FamilyFilterComponent.prototype.toggleView = function () {
        this.isOpen = !this.isOpen;
    };
    FamilyFilterComponent.prototype.onChange = function () {
        this.allSelected = this.filterList.reduce(function (acc, curr) { return acc && curr.value; }, true);
        this.familyFilter = this.filterList.reduce(function (acc, curr) { return (curr.value ? acc.concat([curr.family]) : acc); }, []);
        this.familySelected = this.filterList.reduce(function (acc, curr) { return (curr.value ? acc.concat([curr]) : acc); }, []);
        this.updateParent(this.familyFilter);
    };
    FamilyFilterComponent.prototype.onAllSelectedChange = function () {
        var _this = this;
        if (this.skipOnChange) {
            this.filterList = this.filterList.map(function (d) { return (tslib_1.__assign({}, d, { value: !_this.allSelected })); });
            this.familyFilter = this.filterList.reduce(function (acc, curr) { return (curr.value ? acc.concat([curr.family]) : acc); }, []);
            this.familySelected = this.filterList.reduce(function (acc, curr) { return (curr.value ? acc.concat([curr]) : acc); }, []);
            this.updateParent(this.familyFilter);
        }
        this.skipOnChange = false;
    };
    FamilyFilterComponent.prototype.updateParent = function (d) {
        this.FamilyFilterChange.emit(d);
    };
    Object.defineProperty(FamilyFilterComponent.prototype, "shouldDisplayFilterLogo", {
        get: function () {
            return this.familyFilter.length > 0 && !this.allSelected;
        },
        enumerable: true,
        configurable: true
    });
    FamilyFilterComponent.prototype.removeCategoryTag = function (_a, event) {
        var family = _a.family;
        var index = this.filterList.findIndex(function (d) { return d.family === family; });
        this.filterList[index].value = false;
        this.onChange();
    };
    return FamilyFilterComponent;
}());
export { FamilyFilterComponent };
