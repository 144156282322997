import { EventEmitter, OnInit } from '@angular/core';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { NeedService } from '../../services/need.service';
import { PASSPORT_UNITS } from '../../../passport/values/passport-units.const';
import { QUANTITY_PRECISION } from '../../values/quantity-precision.const';
import { NEED_STATUSES } from '../../values/need-statuses.const';
import { Observable } from 'rxjs/Observable';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { Router } from '@angular/router';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { MdDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
var NeedDetailsComponent = /** @class */ (function () {
    function NeedDetailsComponent(readOnlyService, needService, _navigationService, router, dialog, translate) {
        this.readOnlyService = readOnlyService;
        this.needService = needService;
        this._navigationService = _navigationService;
        this.router = router;
        this.dialog = dialog;
        this.translate = translate;
        this.PASSPORT_UNITS = PASSPORT_UNITS;
        this.action = new EventEmitter();
        this.onOpen = new EventEmitter();
        this.moduleNames = ModuleNames;
        this.modificationModalIsVisible = false;
        this.modificationIsNull = false;
        this.parentModule = this._navigationService.getModuleName();
    }
    Object.defineProperty(NeedDetailsComponent.prototype, "passport", {
        get: function () {
            return this.need.passport;
        },
        enumerable: true,
        configurable: true
    });
    NeedDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.readOnlyService.readOnly = this.readOnly;
        this.needService.view(this.id, { expand: 'client,passport,nomenclature,passport_materials,client_location' +
                ',passport_components,client_contact,files,sales_name,passport.category,passport.gtins' }).subscribe(function (res) {
            _this.need = res;
            _this.need.loaded = true;
            return Observable.of([]);
        });
    };
    NeedDetailsComponent.prototype.save = function () {
        var _this = this;
        this.need.save().subscribe(function () {
            _this.action.emit();
        });
    };
    NeedDetailsComponent.prototype.validate = function () {
        var _this = this;
        this.need.publish().subscribe(function () {
            _this.action.emit();
        });
    };
    NeedDetailsComponent.prototype.reject = function () {
        var _this = this;
        this.need.reject().subscribe(function () {
            _this.action.emit();
        });
    };
    NeedDetailsComponent.prototype.askForModification = function () {
        var _this = this;
        if (!this.need.modification) {
            this.modificationIsNull = true;
            return;
        }
        this.need.askForModification().subscribe(function () {
            _this.action.emit();
        });
    };
    NeedDetailsComponent.prototype.checkStatusValidated = function () {
        return this.need.status !== NEED_STATUSES.WAITING_FOR_PUBLICATION.value;
    };
    NeedDetailsComponent.prototype.changeVisibilityOfModificationModal = function () {
        this.need.modification = '';
        this.modificationModalIsVisible = !this.modificationModalIsVisible;
    };
    NeedDetailsComponent.prototype.modificationChanged = function () {
        this.modificationIsNull = !this.need.modification;
    };
    NeedDetailsComponent.prototype.getLabelClasses = function (field) {
        return {
            'primary-color': field,
            'label-color': !field,
            'passport-edit__color-transition': true
        };
    };
    NeedDetailsComponent.prototype.onSeeFullPassport = function () {
        var _this = this;
        this.translate.get('CONFIRM_OPEN_PASSPORT_PAGE')
            .subscribe(function (translation) {
            var confirmationDialog = _this.dialog.open(ConfirmDialogComponent, {
                data: {
                    text: translation
                }
            });
            confirmationDialog.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.router.navigate([_this.parentModule + '/passports', _this.passport.id]);
                }
            });
        });
    };
    NeedDetailsComponent.prototype.getQuantityPrecisionType = function (key) {
        var type = QUANTITY_PRECISION.find(function (item) { return item.key === key; });
        return type ? type.value : '';
    };
    return NeedDetailsComponent;
}());
export { NeedDetailsComponent };
