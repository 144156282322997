import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Type } from '@angular/core';
import { PassportKeywordJunctionService } from '../services/passport-keyword-junction.service';

export class PassportKeywordJunction extends ActiveRecord {
  protected provider: Type<PassportKeywordJunctionService> = PassportKeywordJunctionService;

  id: number;
  passport_id: number;
  passport_keyword_id: number;
  keyword: string;

  fields() {
    return ['id', 'passport_id', 'passport_keyword_id', 'keyword'];
  }
}
