import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformCondition } from '../models/transform-condition.class';
var TransformConditionService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformConditionService, _super);
    function TransformConditionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-conditions';
        _this.ModelClass = TransformCondition;
        return _this;
    }
    return TransformConditionService;
}(CrudService));
export { TransformConditionService };
