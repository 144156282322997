/**
 * Created by aleksandr on 30.03.17.
 */
import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { DEPOSIT_OWNER_NAVIGATION } from './deposit-owner.navigation';
import { ERoleIds } from '../../shared/values/role-ids.enum';

export const DEPOSIT_OWNER_CONTEXT: IRoleContextConfig = {
  navigation: DEPOSIT_OWNER_NAVIGATION,
  moduleName: 'deposit-owner',
  apiModuleName: 'depositOwner',
  themeClass: 'b-module-deposit-owner',
  roleId: ERoleIds.DEPOSIT_OWNER,
  roleText: 'Deposit owner',
  homeUrl: '/deposit-owner',
  rootPath: 'deposit-owner'
};

