import { Component, DoCheck } from '@angular/core';
import { NeedService } from '../../../../../entities/need/services/need.service';
import { Subject } from 'rxjs/Subject';
import { NeedSalesListService } from '../../../../../entities/need/services/need-sales-list.service';
import { NeedClientListService } from '../../../../../entities/need/services/need-client-list.service';
import { Searchable } from '../../../../../shared/models/searchable.class';
import { Need } from '../../../../../entities/need/models/need.class';
import {
  ECustomListRecordStatus, ICustomListMenuOption,
  ICustomListRecord
} from '../../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import * as _ from 'lodash';
import { NeedDetailsDialogComponent } from '../../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import { MdDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { IMapMarker } from '../../../../../shared/map-view/map-marker.interface';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { NEED_STATUSES } from '../../../../../entities/need/values/need-statuses.const';
import { CurrentUserService } from '../../../../../shared/services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import {NeedStatus} from '../../../../../entities/need/models/need-status.class';
import {NavigationService} from '../../../../../shared/services/navigation.service';
import {ModuleNames} from '../../../../../shared/values/module-names.const';
import {getSearchStatus} from '../../../../../shared/helpers/search-status-update';
import {DIALOG_THEME} from '../../../../../shared/helpers/dialog-themes.const';
import * as moment from 'moment';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';

@Component({
  moduleId: module.id,
  selector: 'admin-need-catalog',
  templateUrl: 'admin-need-catalog.component.html'
})
export class AdminNeedCatalogComponent implements DoCheck {
  sortOptions: any = [
    { field: 'delivery_date', view: 'Delivery date'},
    { field: 'client_name', view: 'Client'},
    { field: 'name', view: 'Name'},
    { field: 'sales_name', view: 'Need manager'}
  ];
  sortValue: string = 'delivery_date';

  clients: Searchable[];
  filteredClients: Searchable[] = [];
  saleses: Searchable[];
  filteredSales: Searchable[] = [];

  specialAdminFilterStatuses: NeedStatus[] = [];
  records: ICustomListRecord[];
  needs: Need[];
  markers: IMapMarker[] = [];
  mapIsReady: boolean;
  searchSubject = new Subject<string>();
  searchStatus: number[] = [];
  authorColumn: string;
  parentModule: string;

  filter = 'active';
  clientId: number;
  salesId: number;
  search: string;
  expiresFrom: string;
  expiresTo: string;
  oldQuery: any;

  markerAddedLabelText: string;
  markerByLabelText: string;

  readonly NEED_STATUSES = NEED_STATUSES;

  constructor(private needService: NeedService,
              private needClientListService: NeedClientListService,
              private needSalesListService: NeedSalesListService,
              private mdDialog: MdDialog,
              private datePipe: DatePipe,
              private _navigationService: NavigationService,
              private _globalEvents: GlobalEventsManager,
              private _currentUserService: CurrentUserService,
              private _translate: TranslateService,
              private navBarState: NavbarStateService) {

    this.needClientListService.get().subscribe((res: Searchable[]) => {
      this.clients = res;
      if(this.needs && this.needs.length > 0) {
        this.filteredClients = this.clients
          .filter((item) => this.needs.find((need: Need) => need.client && need.client.id === item.id));
      }
    });

    this.needSalesListService.get().subscribe((res: Searchable[]) => {
      this.saleses = res;
      if(this.needs && this.needs.length > 0) {
        this.filteredSales = this.saleses
          .filter((item) => this.needs.find((need: Need) => need.sales_name === item.name));
      }
    });

    this.searchSubject
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(res => this.search = res);

    this._translate.get('Added').subscribe((translation) => this.markerAddedLabelText = translation);
    this._translate.get('By').subscribe((translation) => this.markerByLabelText = translation);

    if(this._navigationService.getModuleName() === ModuleNames.SPECIAL_ADMIN) {
      this.specialAdminFilterStatuses = [
        NEED_STATUSES.ASK_FOR_MODIFICATION,
        NEED_STATUSES.WAITING_FOR_PUBLICATION,
        NEED_STATUSES.PUBLISHED,
      ];
      this.searchStatus = this.specialAdminFilterStatuses.map((status) => status.value);
      //FIXING FILTER TO PUBLISHED NEEDS /boards/179405292/pulses/469015130
      this.searchStatus = [NEED_STATUSES.PUBLISHED.value];
    }

    this.parentModule = this._navigationService.getModuleName();
  }

  ngDoCheck() {
    let statuses = [];

    if (this._navigationService.getModuleName() === ModuleNames.SPECIAL_ADMIN) {
      statuses = this.searchStatus;
    } else  {
      statuses = [NEED_STATUSES.PUBLISHED.value];
    }

    let query: any = {
      expand: 'client,passport,client_contact,client_location,ecosystems,sales_contact,sales_email,sales_name',
      admin: 1,
      supervisor: 1,
      independent: 1,
      delivery_from: this.expiresFrom || null,
      delivery_to: this.expiresTo || null,
      search: this.search || null,
      client: this.clientId || null,
      sales_contact: this.salesId || null,
      'status[]': statuses || null,
      sort: this.sortValue
    };

    if (this.navBarState.multilanguage) {
      query.english = true;
    }

    if (!_.isEqual(this.oldQuery, query)) {
      this
        .needService
        .get(query)
        .subscribe((res: Need[]) => {
          this.mapIsReady = false;
          this.needs = res;
          this.records = res.map(d => this.toRecord(d));
          this.markers = this.needs.map((need: Need) => this.toMarker(need));
          this.mapIsReady = true;

          this.filteredClients = this.clients
            .filter((item) => this.needs.find((need: Need) => need.client && need.client.id === item.id));

          this.filteredSales = this.saleses
            .filter((item) => this.needs.find((need: Need) => need.sales_name === item.name));
        })
      ;
      this.oldQuery = _.cloneDeep(query);
    }
  }

  downloadPdfReport():string {
    return this.needService.getPdfReportUrl({
        supervisor: 1,
        delivery_from: this.expiresFrom || null,
        delivery_to: this.expiresTo || null,
        search: this.search || null,
        client: this.clientId || null,
        sales_contact: this.salesId || null,
        timezone_offset: moment().utcOffset(),
        status: [NEED_STATUSES.PUBLISHED.value]
    });
  }


  toMarker(need: Need): IMapMarker {
    return {
      lat: need.client_location.lat,
      lng: need.client_location.lng,
      name: need.name,
      lines: [
        `${need.quantity ? need.quantity : 0} ${need.passport.unit_text}`,
        `<br>`,
        `${need.description ? need.description : ''}`,
        `<br>`,
        `${this.markerAddedLabelText}: ${this.datePipe.transform(need.created_at, 'dd.MM.y')}`,
        `${this.markerByLabelText}: ${need.sales_contact}`,
      ]
    };
  }

  toRecord(data: Need) {
    let passportName = '';
    if (data.passport) {
      passportName = data.passport.name;
    }
    let controls: ICustomListMenuOption[] = [{
      name: 'See details',
      fn: () => {
        this.mdDialog.open(NeedDetailsDialogComponent, {
          ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
          data: {
            readOnly: true,
            id: data.id,
          },
        });
      },
    }, {
      name: 'Send email to Need manager',
      fn: () => location.href = 'mailto:' + data.sales_email,
    }, {
      name: 'Send email to recipient',
      fn: () => location.href = 'mailto:' + data.client_contact.email,
    }];

    if(data.user_id !== this._currentUserService.infoData.id) {
      controls.push({
        name: 'Chat',
        fn: () => this.openChat(data.user_id),
      });
    }

    if (this._navigationService.getModuleName() === ModuleNames.SUPER_ADMIN) {
      // TODO: retrieve company name
      this.authorColumn = 'Company name';
    }else {
      this.authorColumn = data.sales_name;
    }

    return CustomListRecord
      .build()
      .setPrimaryKey(data.id)
      .setStatus(ECustomListRecordStatus.NO_STATUS)
      .setIndependent(!!data.independent)
      .addTitleColumn(data.name, passportName, null, data.passport.english_name)
      .addListColumn([{key: '', value: data.client.name}])
      .addUnitColumn(data.quantity ? data.quantity : data.quantity, data.passport.customListUnit)
      .addTitleColumn('',data.statusData.view.replace(/./, c => c.toUpperCase()))
      .addTextColumn(data.description)
      .addListColumn(
        [
          data.delivery_date ? {
            key: 'Expires', value: this.datePipe.transform(data.delivery_date, 'dd.MM.y')
          } : { key: 'Expires', value: 'N/A' }
        ].filter(v => v)
      )
      .addAuthorColumn(this.authorColumn)
      .addControlsColumn(controls);
  }

  openChat(targetId: number) {
    console.log('openChat', targetId);
    this._globalEvents.chatUserClicked$.emit(targetId);
  }

  toggleState(state: number) {
    this.searchStatus = getSearchStatus(state, this.searchStatus);
  }
}
