/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i2 from "../../../../shared/read-only/read-only.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "@angular/material";
import * as i6 from "./ecosystem-primary-header.component";
import * as i7 from "../../../../shared/services/user.service";
import * as i8 from "../../../../entities/need/services/need.service";
import * as i9 from "@angular/router";
var styles_EcosystemPrimaryHeaderComponent = [];
var RenderType_EcosystemPrimaryHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EcosystemPrimaryHeaderComponent, data: {} });
export { RenderType_EcosystemPrimaryHeaderComponent as RenderType_EcosystemPrimaryHeaderComponent };
function View_EcosystemPrimaryHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "ecosystem-navigator__buttonFilled"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openEcosystemPicker() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.FormElemsHiddenDirective, [[2, i2.ReadOnlyService]], null, null), (_l()(), i0.ɵted(2, null, [" + ", " "])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = ((i0.ɵnov(_v, 1).readOnlyService == null) ? null : i0.ɵnov(_v, 1).readOnlyService.readOnly); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Create ecosystem")); _ck(_v, 2, 0, currVal_1); }); }
function View_EcosystemPrimaryHeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "section", [["class", "ecosystem-dashboard__section--counters"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "ul", [["class", "ecosystem-dashboard__counter-list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "li", [["class", "ecosystem-dashboard__counter-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "ecosystem-dashboard__counter-value"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "ecosystem-dashboard__counter-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ecosystemsInProcessCount; _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("Ecosystems in process")); _ck(_v, 6, 0, currVal_1); }); }
export function View_EcosystemPrimaryHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "section", [["class", "ecosystem-dashboard__header ecosystem-dashboard__section ecosystem-dashboard__section--two-column ecosystem-catalog"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcosystemPrimaryHeaderComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcosystemPrimaryHeaderComponent_2)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 16777216, null, null, 5, "button", [["class", "ecosystem-dashboard__match-button"]], [[8, "hidden", 0]], [[null, "click"], [null, "longpress"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).show() !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).hide(1500) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.openNeedMatchDialogComponent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i5.MdPrefixRejector, [[2, i5.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(7, 212992, null, 0, i5.MdTooltip, [i5.Overlay, i0.ElementRef, i5.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i0.Renderer, i5.Platform, [2, i5.Dir]], { position: [0, "position"], message: [1, "message"] }, null), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(9, 16384, null, 0, i1.FormElemsHiddenDirective, [[2, i2.ReadOnlyService]], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMainPage; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isMainPage; _ck(_v, 4, 0, currVal_1); var currVal_3 = "left"; var currVal_4 = i0.ɵunv(_v, 7, 1, i0.ɵnov(_v, 8).transform("Use intelligent algorithms to identify deposits that meet declared needs")); _ck(_v, 7, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((i0.ɵnov(_v, 9).readOnlyService == null) ? null : i0.ɵnov(_v, 9).readOnlyService.readOnly); _ck(_v, 5, 0, currVal_2); var currVal_5 = _co.getLangDependentImage(); _ck(_v, 10, 0, currVal_5); }); }
export function View_EcosystemPrimaryHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ecosystem-primary-header", [], null, null, null, View_EcosystemPrimaryHeaderComponent_0, RenderType_EcosystemPrimaryHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i6.EcosystemPrimaryHeaderComponent, [i7.UserService, i0.NgZone, i5.MdDialog, i3.TranslateService, i8.NeedService, i9.Router], null, null)], null, null); }
var EcosystemPrimaryHeaderComponentNgFactory = i0.ɵccf("ecosystem-primary-header", i6.EcosystemPrimaryHeaderComponent, View_EcosystemPrimaryHeaderComponent_Host_0, { isMainPage: "isMainPage" }, {}, []);
export { EcosystemPrimaryHeaderComponentNgFactory as EcosystemPrimaryHeaderComponentNgFactory };
