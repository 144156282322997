<admin-warning-with-header-dialog
  titleText="{{'Request deletion of site(s)' | translate}}"
  confirmText="{{'Yes, delete' | translate}}"
  className="request-site-delete"
>
  <section header class="request-site-delete__site-container">
    <div class="request-site-delete__site-hint">
      <i class="form-group__label--question request-site-delete__site-hint-sign">!</i>
      <span>{{'Are you sure you want to delete the site(s) :' | translate}}</span>
    </div>
    <div class="request-site-delete__site-list">
          {{getSiteList()}}
    </div>
  </section>
  <section main class="request-site-delete__outcome">
    <div [innerHTML]="mainInfoText" class="request-site-delete__outcome-info"></div>
    <div class="request-site-delete__outcome-billing">
      {{'This action will have an impact on the billing of your subscription.' | translate}}
    </div>
  </section>
</admin-warning-with-header-dialog>
