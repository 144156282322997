<div *ngIf="passport?.loaded">
  <article
  class="passport-edit"
  [formGroup]="form"
  [ngClass]="{validated: validated}"
>
  <div *ngIf="passport.status === passportStatuses.ASK_FOR_MODIFICATION.value" class="passport__message">
    {{modificationMessage | translate}}
  </div>

  <div>
    <button *ngIf="isPublicAccess" class="back-button"  (click)="backToPassportList()">
      {{'Back to passports liste' | translate}}
    </button>
  </div>
  <section class="passport-edit__main-wrapper">

    <div class="passport-edit__header passport-edit__header--name-title">
      <div class="passport-edit__header--heading" *ngIf="!passport.getProductName()">
        {{ 'New circular passport' | translate }}
      </div>

      <div class="passport-edit__header--name-wrapper" *ngIf="passport.getProductName()">
        <div class="passport-edit__header--name">{{ passport.getProductName(filterState.multilanguage)  }}
          <span *ngIf="passport.version" style="display: inline-block">{{ ' - V' +passport.version }}</span>
        </div>
      </div>

      <div class="passport-edit__header--family">
        <passport-family-view-label [familyType]="passport.family" display="top"
                                    [unit]="passport.unit + ''"></passport-family-view-label>
      </div>

      <div class="passport-edit__toggle">
        <span [ngClass]="{'passport-edit__toggle-text': true, 'primary-color': !isToggleChecked}">
          {{'Short version' | translate}}
        </span>
        <mat-slide-toggle (change)="onToggleChanged($event)"
                          class="passport-edit__toggle"
                          [checked]="isToggleChecked"
        ></mat-slide-toggle>
        <span [ngClass]="{'passport-edit__toggle-text': true, 'primary-color': isToggleChecked}">
          {{'Long version' | translate}}
        </span>
      </div>
    </div>

    <div *ngIf="isInformationShown">
      <div
        class="background-primary-color"
        [ngClass]="{'passport-edit__information': true,'passport-edit__information--active': isInformationShown}">

        <div (click)="isInformationShown = !isInformationShown"
             class="passport-edit__information--arrow
             passport-edit__information--arrow-rotate">+</div>
        <div>
          <div class="passport-edit__information--text">
            <div class="passport-edit__information--text-top">
              {{'What is a circular passport' | translate}} ?
            </div>
            <div class="passport-edit__information--text-bot">
              {{'PASSPORT_INFO_SHORT_VER_DESCRIPTION' | translate}} <br>
              {{ 'PASSPORT_INFO_LONG_VER_DESCRIPTION' | translate }}
            </div>
          </div>
          <br>
          <div class="passport-edit__information--text">
            <div class="passport-edit__information--text-top">
              {{'How to fill in a circular passport' | translate}} ?
            </div>
            <div class="passport-edit__information--text-bot">
              {{'PASSPORT_INFO_FIRST_STEP' | translate}} <br>
              {{'PASSPORT_INFO_SECOND_STEP' | translate}} <br>
              {{'PASSPORT_INFO_THIRD_STEP' | translate}} <br>
              {{'The information provided is binding on you and may be subject to verification' | translate}} <br>
              <span *ngIf="askForRatingMode()">
              * : {{'compulsory field to be able to ask for a rating' | translate}}
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="passport-edit__tabs">
      <div class="flex">
        <div [ngClass]="getTabClasses(tab)"
             class="passport-edit__tabs--tab"
             *ngFor="let tab of ObjectValues(tabs)"
             (click)="onTabSelected(tab)"
             [mdTooltip]="getPassportTabTooltip(tab)"
        >
        </div>
      </div>

      <div *ngIf="parentModule === moduleNames.MANUFACTURER"
           class="passport-edit__tabs--tab passport-edit__tabs--rating-explanation"
           (click)="openRatingExplanationDialog(false)"
      >
      </div>

      <passport-synchronization-information 
        #passportSynchronizationInformation
        [synchronized]="isSynchronized()" 
        (synchronize)="onSynchronize()" 
        (desynchronize)="onDesynchronize()" 
        [synchronizable]="checkSynchronability()"
        [isLastVersion]="isLastVersion"
        [isEditable]="isFormEditable"
        [logoPath]="passport.synchronization_data.getLogoPath()"
        style="max-width: 30%;"
      ></passport-synchronization-information>

    </div>

    <passport-edit-form-v2
      #passportForm
      [isLongVersionActive]="isToggleChecked"
      [selectedTab]="selectedTab"
      [passport]="passport"
      (onChange)="onChangePassport($event)"
      (onSave)="onSavePassport()"
      (onNextTabClick)="onNextTabClick($event)"
      (isUploading)="handleUploadStatus($event)"
      [destroyAction]="null"
      [listener]="save"
      [allowNameChange]="isNewPassport && !isPublicAccess"
      [enableSearch]="false"
      [parentFormGroup]="form"
      [parentModule]="parentModule"
      [disabled]="this.isStatic || this.isPublicAccess"
      [askForRatingMode]="askForRatingMode()"
      (onEditabilityChanged)="changeEditable($event)"
    ></passport-edit-form-v2>

    <footer *ngIf="!isPublicAccess " class="passport-edit__controls">
      <ul class="passport-edit__controls--wrapper">
        <li *ngIf="!isPassportPublished()" class="button-group__item button-group__item--wide">
          <button md-button md-raised-button *ngIf="!supervisorView_premium_passports"
                  (click)="clickSave()"
                  [disabled]="!passport.id"
          >{{ 'Save as draft' | translate }}</button>
        </li>
        <li class="button-group__item button-group__item--wide"
            *ngIf="parentModule === moduleNames.SPECIAL_ADMIN  && !isPassportPublished()">
          <button md-button md-raised-button
                  (click)="clickAskModification()"
                  [disabled]="!passport.id"
          >{{'Request a modification' | translate}}</button>
        </li>
        <li class="button-group__item button-group__item--wide">
          <button md-button md-raised-button *ngIf="!supervisorView_premium_passports"
                  (click)="clickPublish()"
                  [disabled]="!passport.id || isUploading"
          >{{ 'Publish' | translate }}</button>
        </li>
      </ul>
      <div class="button-group__item button-group__item--wide passport-edit__controls--wrapper
        passport-edit__rating-ask--wrapper"
           *ngIf="askForRatingMode() && !isSpecialAdmin">
        <button md-button md-raised-button
                (click)="clickAskForRating()"
                [disabled]="!isAskForRatingEnabled()"
        >{{ 'Publish and ask Upcyclea to rate my passport' | translate }}</button>
        <div class="passport-edit__rating-ask--icon"></div>
      </div>
    </footer>
  </section>
</article>
</div>
