import {Component, Input, OnInit} from '@angular/core';
import {IReportPDFHeader} from './types/report-pdf-header.interface';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {DepositLocation} from '../../../../../entities/deposit/models/deposit-location.class';

@Component({
  moduleId: module.id,
  selector: 'report-pdf',
  templateUrl: 'report-pdf.component.html',
  styleUrls: ['report-pdf.component.scss']
})
export class ReportPdfComponent implements OnInit {
  @Input() pageNumber: number;
  @Input() reportHeader: IReportPDFHeader;
  @Input() subtitle: string = null;
  @Input() noneItalicSubtitle: boolean;
  public readonly datePattern: string;

  constructor(private activatedRoute: ActivatedRoute) {
    switch (this.activatedRoute.snapshot.params.lang) {
      case 'fr':
        this.datePattern = '\'du\' dd/MM/yyyy \'à\' HH\'h\'mm'; break;
      default:
        this.datePattern = 'dd/MM/yyyy \'at\' hh.mma'; break;
    }
  }
  ngOnInit(): void {
    if (this.subtitle) {
      this.subtitle = this.subtitle.toUpperCase();
    }
  }

  public getUsersDate(): string {
    const date = moment(this.reportHeader.date.created_at);
    const hasTimeZone = !!(this.reportHeader.date.timezone_offset);
    return hasTimeZone
      ? date.set({minutes: date.minutes() + this.reportHeader.date.timezone_offset}).format()
      : date.format();
  }
}
