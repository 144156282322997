<div>
    <map-view
        *ngIf="mapIsReady"
        [markers]="markers"
        (markerClick)="handleMarkerClick($event)"
        (onMarkerClose)="handleMarkerClose($event)"
        [onWindowClose]="closeWindowAction"
        [onUpdateMapCenter]="updateMapBoundsAction"
        [onZoom]="zoomAction"
        [maxZoom]="15"
        [isMapTypeChangePolicySoft]="true"
        [disableDefaultOnMarkerChangeAction]="true"
    >
    </map-view>
</div>
