import * as tslib_1 from "tslib";
import { NgZone, OnDestroy, OnInit } from '@angular/core';
import { MdDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ImportSpinnerComponent } from '../../../../entities/deposit/dialogs/import-spinner/import-spinner.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { DepositImportService } from '../../../../entities/deposit/services/deposit-import.service';
import { IMPORT_ERROR, IMPORT_LOCATION_ERROR } from '../../../../entities/deposit/values/import-error.const';
import { DepositImportErrorsComponent } from '../../../../entities/deposit/dialogs/deposit-import-errors/deposit-import-errors.component';
import { EErrorStatus } from '../../../../shared/spinner/spinner-completion.enum';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { ImportProgressService } from '../../../services/import-progress.service';
import { MAP_IMPORT_PROGRESS_STATUS } from '../../../values/import-progres.const';
import { ImportSuccessSummaryService } from '../../../../shared/services/import-success-summary.service';
import { DepositImportSuccessComponent } from '../../../../entities/deposit/dialogs/deposit-import-success/deposit-import-success.component';
import { ImportDeepstreamService } from '../../../services/import-deepstream.service';
import { ImportWorkerService } from '../../../services/import-worker.service';
import { ActiveImport } from '../../../models/active-import.class';
import { ActiveImportService } from '../../../services/active-import.service';
import { EConfirmDialogIcon } from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import { ImportSuccessReport } from '../../../../entities/deposit/models/import-success-summary.class';
import { DepositLocationImportService } from '../../../../entities/deposit/services/deposit-location-import.service';
import { NotificationWarningDialogComponent } from '../../../../shared/notification-dialogs/notification-warning/notification-warning-dialog.component';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { delay, first } from 'rxjs/operators';
var ImportProgressWrapperComponent = /** @class */ (function () {
    function ImportProgressWrapperComponent(importProgressService, depositImportService, depositLocationImportService, _dialog, ds, _translate, zone, logNotificationService, importSuccessSummaryService, importWorkerService, activeImportService, translateService) {
        this.importProgressService = importProgressService;
        this.depositImportService = depositImportService;
        this.depositLocationImportService = depositLocationImportService;
        this._dialog = _dialog;
        this.ds = ds;
        this._translate = _translate;
        this.zone = zone;
        this.logNotificationService = logNotificationService;
        this.importSuccessSummaryService = importSuccessSummaryService;
        this.importWorkerService = importWorkerService;
        this.activeImportService = activeImportService;
        this.translateService = translateService;
        this.spinnerPercentage = new BehaviorSubject(0);
        this.spinnerTotal = new BehaviorSubject(0);
        this.errors = [];
    }
    ImportProgressWrapperComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscribe();
        this.importWorkerService.getImportIfExist().subscribe(function (activeImports) {
            var activeImport = activeImports.length ? activeImports[0] : new ActiveImport();
            if (activeImport.id) {
                _this.ds.setType(activeImport.import_type);
                _this.importProgressService.currentDepositId = activeImport.import_id;
                _this.changeProgress(activeImport.status);
            }
        });
        this.typeCheckSubscription = this.ds.emitTypeChange.subscribe(function () {
            _this.unsubscribe();
            _this.subscribe();
        });
        this.readSubscription = this.importProgressService.resetProgress$
            .subscribe(function (isNew) {
            if (!isNew) {
                _this.activeImportService.read(_this.importProgressService.currentDepositId).subscribe();
            }
            else {
                _this.onMaximize();
            }
            _this.resetProgress();
        });
    };
    ImportProgressWrapperComponent.prototype.subscribe = function () {
        var _this = this;
        var stream$ = this.ds.getStream();
        this.importParsedRowsStreamSubscription = stream$.depositImportParsedRowsStream$
            .filter(function (e) { return _this.isOngoingImport(e); })
            .subscribe(function (e) {
            var progress = _this.toPercent(e.data.count, e.data.total, false);
            _this.importProgressService.setCheckingmodeProgressModal();
            _this.importProgressService.setImportPercentageValue(progress);
            _this.zone.run(function () { return _this.spinnerPercentage.next(progress); });
            _this.zone.run(function () { return _this.spinnerTotal.next(e.data.total + 1); });
        });
        this.importErrorStreamSubscription = stream$
            .depositImportErrorsStream$.filter(function (e) { return _this.isOngoingImport(e); })
            .subscribe(function (e) {
            _this.showErrorsComponent(e.data.import_id);
            _this.importProgressService.setErrorModeProgressModal();
        });
        this.importCompletionStreamSubscription = stream$
            .depositCompletionStream$.filter(function (e) { return _this.isOngoingImport(e); })
            .subscribe(function (e) {
            if (_this.errorCheckSubscription) {
                _this.errorCheckSubscription.unsubscribe();
                if (!!_this.importSpinnerDialogRef) {
                    _this.importSpinnerInstance.changeStep(_this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD);
                }
            }
            var isCompletedOnSuccessMessage = e.data.success !== undefined && !!e.data.success;
            var progress = _this.toPercent(e.data.count, e.data.total, isCompletedOnSuccessMessage);
            _this.importProgressService.setImportPercentageValue(progress);
            _this.importProgressService.setProgressModeProgressModal();
            _this.zone.run(function () { return _this.spinnerPercentage.next(progress); });
            _this.zone.run(function () { return _this.spinnerTotal.next(e.data.total + 1); });
            _this.checkImportSuccess();
        });
        this.importFatalErrorStreamSubscription = stream$
            .depositImportFatalError$.filter(function (e) { return _this.isOngoingImport(e); })
            .subscribe(function (e) {
            if (e.data.error_status === EErrorStatus.UNKNOWN) {
                _this.logNotificationService.error('Failed to create the content for unknown reason', undefined, true);
                if (!!_this.importSpinnerDialogRef) {
                    _this.destroyImportSpinner();
                }
                _this.zone.run(function () { return _this.importProgressService.setErrorModeProgressModal(); });
                _this.resetProgress();
            }
        });
    };
    ImportProgressWrapperComponent.prototype.changeProgress = function (activeImportStatus) {
        switch (activeImportStatus) {
            case 3 /* CHECKED_UNREAD */:
                this.importProgressService.showImportProgressModal();
                this.showErrorsComponent(this.importProgressService.currentDepositId);
                this.importProgressService.setErrorModeProgressModal();
                break;
            case 2 /* WORKING */:
                this.importProgressService.showImportProgressModal();
                break;
            case 1 /* QUEUED */:
                this.importProgressService.showImportProgressModal();
                this.importProgressService.setCheckingmodeProgressModal();
                break;
            case 7 /* ERROR_UNREAD */:
                this.importProgressService.showImportProgressModal();
                this.showErrorsComponent(this.importProgressService.currentDepositId);
                this.importProgressService.setErrorModeProgressModal();
                break;
            case 5 /* SUCCESS_UNREAD */:
                this.importProgressService.showImportProgressModal();
                this.onImportSuccess();
        }
    };
    ImportProgressWrapperComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe();
        this.typeCheckSubscription.unsubscribe();
        this.readSubscription.unsubscribe();
    };
    ImportProgressWrapperComponent.prototype.unsubscribe = function () {
        if (this.importParsedRowsStreamSubscription) {
            this.importParsedRowsStreamSubscription.unsubscribe();
        }
        if (this.importErrorStreamSubscription) {
            this.importErrorStreamSubscription.unsubscribe();
        }
        if (this.importCompletionStreamSubscription) {
            this.importCompletionStreamSubscription.unsubscribe();
        }
        if (this.importFatalErrorStreamSubscription) {
            this.importFatalErrorStreamSubscription.unsubscribe();
        }
    };
    ImportProgressWrapperComponent.prototype.resetProgress = function () {
        var _this = this;
        this.zone.run(function () { return _this.spinnerPercentage.next(0); });
        this.zone.run(function () { return _this.spinnerTotal.next(0); });
        this.importProgressService.setImportPercentageValue(0);
    };
    Object.defineProperty(ImportProgressWrapperComponent.prototype, "importService", {
        get: function () {
            if (this.ds.getType() === 1 /* DEPOSIT */) {
                return this.depositImportService;
            }
            else if (this.ds.getType() === 2 /* SITES */) {
                return this.depositLocationImportService;
            }
            return this.depositImportService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportProgressWrapperComponent.prototype, "importSpinnerInstance", {
        get: function () {
            return !!this.importSpinnerDialogRef && this.importSpinnerDialogRef.componentInstance;
        },
        enumerable: true,
        configurable: true
    });
    ImportProgressWrapperComponent.prototype.onMaximize = function () {
        switch (this.importProgressService.statusImportProgress) {
            case MAP_IMPORT_PROGRESS_STATUS.ERROR:
                this.toggleErrorDialog();
                break;
            case MAP_IMPORT_PROGRESS_STATUS.PROGRESS:
                this.toggleLoadingSpinner();
                break;
            case MAP_IMPORT_PROGRESS_STATUS.CHECKING:
                this.toggleLoadingSpinner();
                break;
            case MAP_IMPORT_PROGRESS_STATUS.SUCCESS:
                this.toggleSuccessDialog();
                break;
        }
    };
    ImportProgressWrapperComponent.prototype.checkImportSuccess = function () {
        //   if (!!this.importSpinnerInstance && this.importSpinnerInstance.waitingForErrors) {
        //     this.importSpinnerInstance.waitForImport();
        //   }
        console.log('Check import success');
        if (this.spinnerPercentage.getValue() === 100) {
            this.onImportSuccess();
        }
    };
    ImportProgressWrapperComponent.prototype.onImportSuccess = function () {
        var _this = this;
        this.zone.run(function () {
            _this.importProgressService.setSuccessModeProgressModal();
            _this.destroyImportSpinner();
        });
        this.resetProgress();
        this.preloadSuccessReport();
        // this.unsubscribe();
        // this.logNotificationService.success('Import successful', 2000, true);
    };
    ImportProgressWrapperComponent.prototype.preloadSuccessReport = function () {
        var _this = this;
        this.successSummary = new Subject();
        var request;
        var fallbackValue;
        if (this.ds.getType() === 1 /* DEPOSIT */) {
            fallbackValue = new ImportSuccessReport();
            request = this.importSuccessSummaryService.getImportSummarySuccess(this.importProgressService.currentDepositId);
        }
        else if (this.ds.getType() === 2 /* SITES */) {
            fallbackValue = [];
            request = this.importService
                .getSuccessSummary(this.importProgressService.currentDepositId, { expand: 'deposit_location' });
        }
        request.pipe(delay(1000)).subscribe(function (value) {
            _this.successSummary.next(value);
            _this.successSummary = Observable.of(value);
        }, function () {
            _this.successSummary.next(fallbackValue);
            _this.successSummary = Observable.of(fallbackValue);
        });
    };
    ImportProgressWrapperComponent.prototype.destroyImportSpinner = function () {
        var _this = this;
        // this.isImportInProcess = false;
        // tslint:disable-next-line:no-unused-expression
        this.importSpinnerDialogRef && this.zone.run(function () {
            _this.importSpinnerDialogRef.close();
            _this.importSpinnerDialogRef.afterClosed().subscribe(function () {
                _this.importSpinnerDialogRef = void 0;
                _this.spinnerPercentage.next(0);
            });
        });
    };
    ImportProgressWrapperComponent.prototype.isOngoingImport = function (e) {
        var importId = e.data.import_id;
        return importId === this.importProgressService.currentDepositId;
    };
    ImportProgressWrapperComponent.prototype.toPercent = function (value, total, allowHundredPercent) {
        if (allowHundredPercent === void 0) { allowHundredPercent = true; }
        var progress = Math.trunc((value / total) * 100);
        return (progress === 100 && !allowHundredPercent) ? 99 : progress;
    };
    ImportProgressWrapperComponent.prototype.showErrorsComponent = function (depositImportId) {
        var _this = this;
        this.errorCheckSubscription = this.importService.view(depositImportId, { expand: 'errors' })
            .subscribe(function (data) {
            _this.errors = _this.getErrors(data.errors);
            if (!!_this.errors.length) {
                // this.isImportInProcess = false;
                if (_this.importSpinnerDialogRef) {
                    _this.zone.run(function () {
                        _this.importSpinnerDialogRef.close();
                        _this.importSpinnerDialogRef = void 0;
                    });
                }
                _this.resetProgress();
                _this.zone.run(function () {
                    if (!!_this.importErrorDialogRef && !!_this.importErrorDialogRef.componentInstance) {
                        return;
                    }
                    _this.toggleErrorDialog();
                });
            }
            else {
                // this.importProgressService.setCurrentStep(this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD);
                _this.importProgressService.setProgressModeProgressModal();
                if (!!_this.importSpinnerDialogRef) {
                    _this.importSpinnerInstance.waitForImport();
                    _this.importSpinnerInstance.changeStep(_this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD);
                    _this.spinnerPercentage.next(0);
                }
            }
        });
    };
    ImportProgressWrapperComponent.prototype.getErrors = function (errors) {
        var _this = this;
        var importErrors = this.ds.getType() === 2 /* SITES */ ? IMPORT_LOCATION_ERROR : IMPORT_ERROR;
        return !!errors.length ? errors.map(function (e) {
            var errorDescription = importErrors[e.error_type] ? _this.translate(importErrors[e.error_type], e.value) : '';
            return { error: errorDescription, line: e.row, value: e.value };
        }) : [];
    };
    ImportProgressWrapperComponent.prototype.translate = function (key, x) {
        var message = '';
        this._translate.get(key, { X: x }).subscribe(function (res) {
            message += res;
        });
        return message;
    };
    ImportProgressWrapperComponent.prototype.toggleErrorDialog = function () {
        var _this = this;
        if (!!this.importErrorDialogRef) {
            this.importErrorDialogRef.close();
            return;
        }
        this.importErrorDialogRef = this._dialog.open(DepositImportErrorsComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: { color: 'green', errors: this.errors } }));
        this.importErrorDialogRef.afterClosed().subscribe(function () {
            _this.importErrorDialogRef = void 0;
            // this.spinnerPercentage.next(0);
        });
    };
    ImportProgressWrapperComponent.prototype.toggleLoadingSpinner = function () {
        var _this = this;
        var _a, _b;
        if (!!this.importSpinnerDialogRef) {
            this.importSpinnerDialogRef.close();
            return;
        }
        this.importSpinnerDialogRef = this._dialog.open(ImportSpinnerComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: {
                percentage: this.spinnerPercentage,
                totals: this.spinnerTotal,
                progressLabel: this.ds.getType() === 1 /* DEPOSIT */ ? 'Import of deposits ...' : 'Import of sites ...',
                stepLabel: (_a = {},
                    _a[this.importProgressService.DEPOSIT_IMPORT_STEP.VALIDATION] = 'Checking for errors',
                    _a),
                stepShowProgress: (_b = {},
                    _b[this.importProgressService.DEPOSIT_IMPORT_STEP.VALIDATION] = {
                        percentage: false,
                        totals: true,
                    },
                    _b[this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD] = {
                        percentage: true,
                        totals: true,
                    },
                    _b),
                footerText: '',
                currentTotalText: this.ds.getType() === 1 /* DEPOSIT */ ?
                    '[X] of [Y] imported deposits' : '[X] of [Y] imported sites',
                iconData: {
                    iconType: EConfirmDialogIcon.USUAL,
                    iconClass: 'icon arrow-down--icon--l'
                }
            } }));
        this.importSpinnerDialogRef.afterClosed().subscribe(function () {
            _this.importSpinnerDialogRef = void 0;
            // this.spinnerPercentage.next(0);
        });
        var step = this.importProgressService.DEPOSIT_IMPORT_STEP.VALIDATION;
        switch (this.importProgressService.statusImportProgress) {
            case MAP_IMPORT_PROGRESS_STATUS.PROGRESS:
                step = this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD;
                break;
        }
        this.importSpinnerInstance.changeStep(step);
        switch (step) {
            case this.importProgressService.DEPOSIT_IMPORT_STEP.VALIDATION:
                this.importSpinnerInstance.showPercentage(false);
                break;
            case this.importProgressService.DEPOSIT_IMPORT_STEP.LOAD:
                this.importSpinnerInstance.showPercentage(true);
                break;
        }
    };
    ImportProgressWrapperComponent.prototype.toggleSuccessDialog = function () {
        var _this = this;
        if (!!this.importSuccessDialogRef) {
            this.importSuccessDialogRef.close();
            return;
        }
        this.successSummary.pipe(first()).subscribe(function (data) {
            if (_this.ds.getType() === 1 /* DEPOSIT */) {
                _this.importSuccessDialogRef = _this._dialog.open(DepositImportSuccessComponent, tslib_1.__assign({}, DIALOG_THEME.SUCCESS_IMPORT_DEPOSIT, { data: { report: data } }));
            }
            else if (_this.ds.getType() === 2 /* SITES */) {
                _this.importSuccessDialogRef = _this._dialog.open(NotificationWarningDialogComponent, {
                    data: {
                        cssClass: 'import-progress-site-success',
                        iconData: {
                            iconType: EConfirmDialogIcon.SVG,
                            primaryFill: true,
                            showIconURL: 'assets/svg/done_import_circle_icon.svg'
                        },
                        showCross: true,
                        title: 'IMPORT_SITE_SUCCESS_SUMMARY_TITLE',
                        body: _this.translateService.instant('IMPORT_SITE_NUMBER_CREATED_PLURAL', { X: data.length }),
                        bodyHint: 'DEPOSIT_LOCATION_IMPORT_SUCCESS_HINT',
                        warnings: data.map(function (item) { return ({
                            titleText: item.deposit_location.name, text: item.deposit_location.address
                        }); })
                    }
                });
            }
            if (_this.importSuccessDialogRef) {
                _this.importSuccessDialogRef.afterClosed().subscribe(function () {
                    _this.importSuccessDialogRef = void 0;
                    // this.spinnerPercentage.next(0);
                });
            }
        });
    };
    return ImportProgressWrapperComponent;
}());
export { ImportProgressWrapperComponent };
