/**
 * Created by aleksandr on 28.07.17.
 */
import { IRoleContextConfig } from '../interfaces/role-context-config.interface';
import { SALES_CONTEXT } from '../../dashboards/sales/sales.context';
import { SUPERADMIN_CONTEXT } from '../../dashboards/superadmin/superadmin.context';
import { DEPOSIT_OWNER_CONTEXT } from '../../dashboards/deposit-owner/deposit-owner.context';
import { TRANSFORM_CONTEXT } from '../../dashboards/transformation-manager/transformation-manager.context';
import { SUPERVISOR_CONTEXT } from '../../dashboards/supervisor/supervisor.context';
import { AGENT_CONTEXT } from '../../dashboards/agent/agent.context';
import { ECOSYSTEM_CONTEXT } from '../../dashboards/ecosystem-manager/ecosystem-manager.context';
import { ADMIN_CONTEXT } from '../../dashboards/admin/admin.context';
import {MANUFACTURER_CONTEXT} from '../../dashboards/manufacturer/manufacturer.context';
import {SPECIALADMIN_CONTEXT} from '../../dashboards/specialadmin/specialadmin.context';
import {
  TRANSFORM_INDEPENDENT_CONTEXT
} from '../../dashboards/transformation-manager-independent/transformation-manager-independent.context';
import { GUEST_CONTEXT } from './guest.context';



export const USER_CONTEXTS: IRoleContextConfig[] = [
  SALES_CONTEXT,
  AGENT_CONTEXT,
  SUPERVISOR_CONTEXT,
  TRANSFORM_CONTEXT,
  DEPOSIT_OWNER_CONTEXT,
  ECOSYSTEM_CONTEXT,
  ADMIN_CONTEXT,
  SUPERADMIN_CONTEXT,
  MANUFACTURER_CONTEXT,
  SPECIALADMIN_CONTEXT,
  TRANSFORM_INDEPENDENT_CONTEXT,
  GUEST_CONTEXT
];
