<div class="notifier_group" (click)="toggleNotifications()">
  <i class="ib-icon notifier--icon"></i>
  <span *ngIf="isLoading" class="notifier--count"><app-loader [width]="'15px'" [height]="'15px'"
      style="width: 15px;height: 15px;"></app-loader> </span>
  <span *ngIf="!isLoading" class="notifier--count">{{formatTotalCount(nbrNotifications)}}</span>
</div>
<div class="notification-list-bar" [ngClass]="{'opened': isBarShown}">
  <notification-list *ngIf="isBarShown" [records]="notifications" [isLoading]="isLoading" (onRead)="readNotification($event)"
    (onAction)="callToAction($event)" (scroll)="onScroll()" id="notificationList"></notification-list>
  <button class="toggle-button" (click)="toggleNotifications()">
    <i class="icon icon--toggle_arrow"></i>
  </button>
</div>
