/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../entities/need/partials/need-edit/need-edit.component.ngfactory";
import * as i2 from "../../../../entities/need/partials/need-edit/need-edit.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../../entities/passport/services/passport-validation-notification.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../shared/services/log-notification.service";
import * as i7 from "../../../../shared/services/navigation.service";
import * as i8 from "@angular/material";
import * as i9 from "../../../../shared/services/loading-spinner.service";
import * as i10 from "../../../../entities/need/services/need-view.service";
import * as i11 from "./supervisor-need.component";
import * as i12 from "../../../../shared/services/modfication.service";
var styles_SupervisorNeedComponent = [];
var RenderType_SupervisorNeedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SupervisorNeedComponent, data: {} });
export { RenderType_SupervisorNeedComponent as RenderType_SupervisorNeedComponent };
export function View_SupervisorNeedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "need-edit", [], null, null, null, i1.View_NeedEditComponent_0, i1.RenderType_NeedEditComponent)), i0.ɵdid(1, 4440064, null, 0, i2.NeedEditComponent, [i3.ActivatedRoute, i3.Router, i4.PassportValidationNotificationService, i5.TranslateService, i0.ChangeDetectorRef, i6.LogNotificationService, i7.NavigationService, i0.NgZone, i8.MdDialog, i9.LoadingSpinnerService, i10.NeedViewService], { controlStates: [0, "controlStates"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.controlStates; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SupervisorNeedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_SupervisorNeedComponent_0, RenderType_SupervisorNeedComponent)), i0.ɵdid(1, 114688, null, 0, i11.SupervisorNeedComponent, [i12.ModificationService, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupervisorNeedComponentNgFactory = i0.ɵccf("ng-component", i11.SupervisorNeedComponent, View_SupervisorNeedComponent_Host_0, {}, {}, []);
export { SupervisorNeedComponentNgFactory as SupervisorNeedComponentNgFactory };
