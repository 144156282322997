/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../user-details/user-details.component.ngfactory";
import * as i2 from "../user-details/user-details.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../shared/services/user.service";
import * as i5 from "../../../../shared/services/navigation.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../../shared/services/current-user.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/material";
import * as i10 from "../../../../entities/client/service/language.service";
import * as i11 from "../../../../shared/services/log-notification.service";
import * as i12 from "../../../../entities/deposit/services/deposit-location.service";
import * as i13 from "../../../../entities/deposit/services/deposit-location-sales-junction.service";
import * as i14 from "./user-edit.component";
var styles_UserEditComponent = [];
var RenderType_UserEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserEditComponent, data: {} });
export { RenderType_UserEditComponent as RenderType_UserEditComponent };
export function View_UserEditComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "user-details", [["class", "material-reset"]], [[8, "id", 0]], [[null, "onSaveAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSaveAction" === en)) {
        var pd_0 = (_co.router.navigate(["admin/users"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_UserDetailsComponent_0, i1.RenderType_UserDetailsComponent)), i0.ɵdid(1, 245760, null, 0, i2.UserDetailsComponent, [i3.FormBuilder, i4.UserService, i5.NavigationService, i6.ActivatedRoute, i6.Router, i7.CurrentUserService, i8.TranslateService, i9.MdDialog, i10.LanguageService, i11.LogNotificationService, i12.DepositLocationService, i13.DepositLocationSalesJunctionService], null, { onSaveAction: "onSaveAction" })], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activatedRoute.snapshot.params["id"] * 1); _ck(_v, 0, 0, currVal_0); }); }
export function View_UserEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_UserEditComponent_0, RenderType_UserEditComponent)), i0.ɵdid(1, 49152, null, 0, i14.UserEditComponent, [i6.ActivatedRoute, i6.Router], null, null)], null, null); }
var UserEditComponentNgFactory = i0.ɵccf("ng-component", i14.UserEditComponent, View_UserEditComponent_Host_0, {}, {}, []);
export { UserEditComponentNgFactory as UserEditComponentNgFactory };
