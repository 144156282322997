import { Component, EventEmitter, Input, NgZone, OnInit, Output, Renderer, ViewChild, ElementRef, ViewChildren, Optional} from '@angular/core';
import { IParentEvent } from '../../../../shared/interfaces/parent-event.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PassportComponent } from '../../models/component.class';
import { ComponentService } from '../../services/component.service';
import { Passport} from '../../models/passport.class';
import { PASSPORT_REQUEST } from '../../values/passport-request.const';
import { DEMOUNTABLE } from '../../values/demountable-values.const';
import { Material } from '../../models/material.class';
import { Observable } from 'rxjs/Observable';
import { MaterialService } from '../../services/material.service';
import { MdDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import { ValidationService } from '../../../../shared/services/validation-service';
import {PassportService} from '../../services/passport.service';
import {forkJoinSafe} from '../../../../shared/helpers/fork-join-safe.helper';
import { trigger, transition, style, animate, state } from '@angular/animations';
import {PassportCustomStoreService} from '../../services/passport-custom-store.service';

@Component({
  moduleId: module.id,
  selector: 'passport-components',
  templateUrl: 'passport-components.component.html',
  animations: [
    trigger('openClose', [
      state('open', style({
        marginBottom: '{{margin_bottom_initial}}px'
      }), {params: {margin_bottom_initial: 0}}),
      state('closed', style({
        marginBottom: '{{margin_bottom}}px',
      }), {params: {margin_bottom: 0}}),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class PassportComponentsComponent implements OnInit {

  @Input() public listener?: EventEmitter<IParentEvent>;
  @Input() public passport?: Passport;
  @Input() public isDisabled = false;
  @Input() public viewMode: number;
  @Input() parentFormGroup: FormGroup;
  @Input() isComponentNameMandatory: boolean = false;
  @Output() onSave = new EventEmitter();

  subscription: Subscription;
  listCtrl: FormArray;
  demountableTypes: {value: number, viewValue: string}[] = DEMOUNTABLE;

  disableStatus = false;
  selectedCtrl: FormGroup = null;

  VIEW_MODE = VIEW_MODE;
  @ViewChildren('components') components;
  @ViewChild('controlGroup') controlGroup: ElementRef;
  margin_bottom = 0;
  margin_bottom_initial = 0;
  loaded: boolean;
  controlGroupState = 'closed';

  constructor(@Optional() private passportChildComponentsEventListenerService: PassportCustomStoreService,
              public _fb: FormBuilder,
              public componentService: ComponentService,
              public materialService: MaterialService,
              public passportService: PassportService,
              public dialog: MdDialog,
              private renderer: Renderer,
              private _translate: TranslateService,
              private ngZone: NgZone) {
  }

  ngOnInit() {
    this.disableStatus = this.isDisabled;
    if (this.passport) {
      this.changePassport(this.passport).updateSubscription().getComponents();
      // if (this.passportChildComponentsEventListenerService && !this.loaded) {
      //   this.passportChildComponentsEventListenerService
      //     .addFormProgrammaticlyAction.emit({controlName: 'components', control: this.listCtrl});
      // }
    }

    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if (event.key === 'changePassport') {
          this.changePassport(event.data).updateSubscription().getComponents();
        }
        if (event.key === 'disableStatus') {
          this.disableStatus = event.data.isDisabled;
          this.updateDisableStatus();
        }
        if (event.key === 'savePassport') {
          this.onSave.emit({type: PASSPORT_REQUEST.COMPONENTS, request: this.save()});
        }
      });
    }
  }

  changePassport(passport: Passport): PassportComponentsComponent {
    this.passport = passport;
    return this;
  }

  updateSubscription(): PassportComponentsComponent {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.passport.onSyncComponents$.subscribe((records: any) => this.updateComponents(records));
    return this;
  }

  getComponents(): void {
    this.passport.syncComponents();
  }

  updateComponents(records: PassportComponent[]): void {
    console.log("edit");

    this.passport.components = [...records];
    this.listCtrl = this._fb.array([]);

    if (this.passport.components.length === 0) {
      this.addNew();
    }

    let componentCounter = 0;
    this.passport.components.forEach((component) => {
      this.addCtrl(component);
    if(componentCounter === this.passport.components.length){
      this.loaded = true;
    }});
    this.updateDisableStatus();
    if (this.passportChildComponentsEventListenerService && !this.loaded) {
      setTimeout(() => {
        this.passportChildComponentsEventListenerService
        .addFormProgrammaticlyAction.emit({controlName: 'components', control: this.listCtrl});
      }
      );
    }
  }


  addNew(): void {
    this.selectAndEnable(this.addCtrl(new PassportComponent({ passport_id: this.passport.id })));
    this.closeAllMaterialDropDowns();
  }

  isDefault(component) {
    return component.get('componentName').parent.value.isDefault;
  }

  getComponentValue(component) {
    return component.get('componentName').value || '';
  }

  getcomponentDemountableValue(component) {
    return component.get('componentDemountable').value ?
    this.demountableTypes.find(t => t.value === component.get('componentDemountable').value).viewValue : ' ';
  }

  selectAndEnable(newCtrl): void {
    this.selectedCtrl = (newCtrl);
    newCtrl.get('componentName').enable();
    newCtrl.get('componentNumber').enable();
    newCtrl.get('componentDemountable').enable();
    newCtrl.get('percentageWeight').enable();
  }

  removeComponent(index: number): void {
    console.log('remove: ', index);
    console.log('component control: ', this.listCtrl);

    this._translate.get('CONFIRM_DELETE_PASSPORT_COMPONENT')
      .flatMap((translation) => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            text: translation
          }
        });

        return dialogRef.afterClosed();
      })
      .subscribe((result) => {
        if (result) {
          const componentForm: FormArray = <FormArray>this.listCtrl.controls[index];
          if (!componentForm) {
            console.error('Failed to get component form by index (' + index + '):', this.listCtrl);
          }

          const idCtrl = componentForm.get('componentId');
          if (!!idCtrl && idCtrl.value) {
            this.deleteComponent(idCtrl.value);
          }

          this.listCtrl.removeAt(index);
          this.listCtrl.markAsDirty();
          if (this.listCtrl.length === 0) {
            this.addNew();
          }
        } else console.log('Delete canceled');
      });
  }

  addCtrl(component: PassportComponent): FormGroup {
    const materialsFormArray: FormArray = this._fb.array([]);

    if (component.materials.length === 0) {
      materialsFormArray.push(this.getMaterialCtrl(new Material()));
    }

    component.materials.forEach((material: Material) => {
      materialsFormArray.push(this.getMaterialCtrl(material));
    });

    // inputs gets enabled as soon as control gets selected by first click
    const formGroup: FormGroup = this._fb.group({
      componentName: this.isComponentNameMandatory ? [{value: component.name, disabled: true}, Validators.required]: [{value: component.name, disabled: true}],
      // componentNumber: [{value: component.quantity, disabled: true}, Validators.pattern(/^\d+$/)],
      componentNumber: [{value: component.quantity, disabled: true}, ValidationService.validateQuantity],
      componentDemountable: [{value: component.demountable, disabled: true}],
      percentageWeight: [{value: component.percentage_weight, disabled: true}, [Validators.min(0), Validators.max(100)]],
      componentId: [component.id],
      materials: materialsFormArray,
      isDefault: component.isDefault,
      isMaterialOpen: false
    });

    formGroup.value.isDefault ? this.listCtrl.push(formGroup) : this.listCtrl.insert(0, formGroup);

    if (component.isDefault) {
      this.selectedCtrl = formGroup;
    }
    return formGroup;
    // this.save();
  }

  getMaterialCtrl(material: Material): FormGroup {
    return this._fb.group({
      id: [material.id],
      name: [material.nomenclature ? material.nomenclature.name : ''],
      nomenclatureId: [material.nomenclature ? material.nomenclature.id : null],
      quantity: [material.quantity, ValidationService.validateQuantity],
      // price: [material.material_cost, ValidationService.validateQuantity],
      percentage: [material.percentage ? material.percentage : null, [Validators.min(0), Validators.max(100)]]

  });
  }

  updateDisableStatus(): void {
    if (!this.listCtrl) {
      this.listCtrl = this._fb.array([]);
    }
    if (this.disableStatus) {
      this.listCtrl.controls.forEach((control) => control.disable());
    } else {
      this.listCtrl.controls.forEach((control) => {
        control.enable();
        if (control.get('isDefault').value) {
          control.get('componentName').disable();
        }
      });
    }
  }

  save() {
    if (this.passport && this.passport.id) {
      if (!!this.listCtrl && !!this.listCtrl.controls.length) {
        this.changePassport(this.passport).updateSubscription().getComponents();
        const requests = this.listCtrl.controls.map((control) => this.saveItem(<FormArray>control));
        return forkJoinSafe<any>(requests);
      }
    }
  }

  saveItem(componentCtrl: FormArray) {
      const controls: any = componentCtrl.controls;
      const materials = controls.materials.controls
        .map((materialForm: FormGroup) => this.getMaterialFromForm(materialForm, controls.componentId.value))
        .filter((material) => !!material);

      const parsedQuantity = ValidationService.normalizeQuantity(controls.componentNumber.value);
      const getComponentCrudData = (data?: Partial<PassportComponent>) => {
        return {
          name: controls.componentName.value,
          quantity: parsedQuantity ? parsedQuantity : controls.componentNumber.value,
          demountable: controls.componentDemountable.value,
          percentage_weight: controls.percentageWeight.value,
          ...data
        };
      };
      if (controls.componentId.value) {
        return this
          .componentService
          .update(controls.componentId.value, getComponentCrudData())
          .flatMap((result: PassportComponent) => this.saveComponentMaterials(materials, result));
      } else {
        return (!controls.isDefault.value
            ? this.componentService.create(getComponentCrudData({passport_id: this.passport.id}))
            : Observable.of(new PassportComponent({}))
        ).flatMap((result: PassportComponent) => {
          materials.map((mat: Material) => {
            mat.component_id = result.id;
            return mat;
          });
          const requests = [];
          if (controls.isDefault.value) {
            this.passport.demountability_default = controls.componentDemountable.value;
            this.passport.percentage_default = controls.percentageWeight.value;
            // TODO: it should not be here
           
           // if (!this.passport.id) 
              requests.push(this.passport.save());
          }
          requests.push(this.saveComponentMaterials(materials, result).map((res: PassportComponent) => {
            controls.componentId.setValue(res.id);
          }));
          return forkJoinSafe<any>(requests);
        });
      }
  }

  saveComponentMaterials(materials: Material[], component: PassportComponent): Observable<PassportComponent> {
    return materials.length > 0
      ? this.materialService.updateMaterials(materials)
        .map((mats: Material[]) => {
          component.materials = mats;
          return component;
        })
      : Observable.of(component);
  }

  getMaterialFromForm(form: FormGroup, componentId?: number): Material {
    if (!form) {
      return null;
    }

    const idCtrl = form.get('id');
    const nomenclatureIdCtrl = form.get('nomenclatureId');
    const quantityCtrl = form.get('quantity');
    const price = form.get('price');
    const percentage = form.get('percentage');

    if (!nomenclatureIdCtrl || !nomenclatureIdCtrl.value) {
      return null;
    }

    const material: Material = new Material({
      nomenclature_id: nomenclatureIdCtrl ? nomenclatureIdCtrl.value : null,
      quantity: quantityCtrl ? quantityCtrl.value : null,
      material_cost: price ? price.value : null,
      passport_id: this.passport.id,
      percentage: percentage ? percentage.value : null
    });

    if (idCtrl && idCtrl.value) {
      material.id = idCtrl.value;
    }

    if (componentId) {
      material.component_id = componentId;
    }
    return material;
  }

  deleteComponent(id: number): void {
    this.componentService.remove(id).subscribe();
  }

  selectCtrl(componentCtrl: FormGroup, $event: Event, index: number) {
    this.toggleMaterialVisibility(index, false);
    if (!this.disableStatus) {
      if (this.selectedCtrl === componentCtrl) {
        return;
      } else {
        $event.preventDefault();
        $event.stopPropagation();
      }
      (!componentCtrl.get('isDefault').value)
        ? componentCtrl.get('componentName').enable()
        : componentCtrl.get('componentName').disable();
      componentCtrl.get('componentNumber').enable();
      componentCtrl.get('componentDemountable').enable();
      componentCtrl.get('percentageWeight').enable();
    } else {
      $event.preventDefault();
      $event.stopPropagation();
    }

    this.selectedCtrl = componentCtrl;
  }

  isAddButtonVisible(index: number): boolean {
    return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && index === this.listCtrl.length - 1;
  }

  isRemoveButtonVisible(componentCtrl: FormGroup): boolean {
    return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && !componentCtrl.get('isDefault').value;
  }

  closeAllMaterialDropDowns() {
    this.listCtrl.controls.map((e) => {
        e.patchValue({isMaterialOpen: false});
    });
    this.dropDownChanged();
  }

  toggleMaterialVisibility(index: number, value: boolean, event?: Event) {
    this.listCtrl.controls.map((e, i) => {
      if (i !== index) {
        e.patchValue({isMaterialOpen: false});
      }
    });

    this.listCtrl.at(index).patchValue({isMaterialOpen: !value});

    this.dropDownChanged(index, !this.listCtrl.controls[index].get('isMaterialOpen').value);

    if (event && value) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  getLabelClasses(field) {
    return {
      'primary-color': field,
      'label-color': !field,
      'passport-edit__color-transition': true
    };
  }

  dropDownChanged(index = 0, toggled = true) {
    setTimeout(() => {
      const materialsPanelHeight = this.components._results[index].nativeElement.children[1].offsetHeight;
      const componentsRowHeight = this.components._results[index].nativeElement.children[0].offsetHeight;
      const allRowsLength = this.listCtrl.controls.length - index - 1;
      this.margin_bottom = materialsPanelHeight - (allRowsLength * componentsRowHeight);
      this.margin_bottom = this.margin_bottom < 0 ? 0 : this.margin_bottom;
      this.margin_bottom_initial = toggled ? 0 : this.margin_bottom;
      this.controlGroupState = this.controlGroupState === 'open' ? 'closed' : 'open';
    }, 100);
  }

  preventLettersAndNumberGreaterThanOneHundred(event: KeyboardEvent) {
    if ((isNaN(Number(event.key)) && event.key !== '.') || event.target['value'].length > 4) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Get and format the current components and materials linked to these materials
   * Also isolates the attributes of the default component to be directly put in corresponding attributes of the passport
   * @returns {Object}
   */
  getCurrentComponents() {
    let components = (this.listCtrl.getRawValue() || []).map(this.formatComponentForBackend, this);
    let defaultComponent = components.find(component => component.isDefault);
    let otherComponents = components.filter(component => !component.isDefault);

    return {
      components: otherComponents,
      percentage_default: defaultComponent.percentage_weight,
      materials: defaultComponent.materials,
      demountability_default: defaultComponent.demountable
    }
  }

  /**
   * Format the component object to be sent to the backend
   * @param {PassportComponent} component - The component to format
   * @returns {Object}
   */
  formatComponentForBackend(component) {
    return {
      demountable: component.componentDemountable,
      id: component.componentId,
      name: component.componentName,
      percentage_weight: component.percentageWeight,
      materials: component.materials.map(this.formatMaterialForBackend, this),
      isDefault: component.isDefault
    }
  }

  /**
   * Format the material object to be sent to the backend
   * @param {PassportMaterial} material - The material to format
   * @returns {Object}
   */
  formatMaterialForBackend(material) {
    return {
      nomenclature_id: material.nomenclatureId,
      percentage: material.percentage
    }
  }
}
