import { Injectable } from '@angular/core';
import { Stakeholder } from '../models/stakeholder.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { Response } from '@angular/http';

@Injectable()
export class StakeholderService extends ExtendedCrudService {
  protected namespace = 'stakeholders';
  protected namespaceSingular = 'stakeholder';
  protected ModelClass = Stakeholder;

  public getStakeholders(searchParam?: string) {
    let options = !!searchParam
      ? { search: { search: searchParam }}
      : {};

    return this.sendGet(this.getEndpoint(this.namespaceSingular + '/list'), options)
      .map((res: Response) => res.json());
  }
}
