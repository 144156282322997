import { ChangeDetectorRef, ElementRef, Injectable } from "@angular/core";
import { MdDialog, MdDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { SpinnerDialogComponent } from "../spinner/dialogs/spinner-dialog/spinner-dialog.component";
import { LogNotificationService } from "./log-notification.service";
import { NavigationService } from "./navigation.service";

@Injectable()
export class LoadingSpinnerService {
    readonly AUTO_HIDE_TIME = 45000;
    private spinnerDialogRef: MdDialogRef<SpinnerDialogComponent>;
    private activeTimeout: NodeJS.Timer;
    private label: string;

    constructor(//public cd: ChangeDetectorRef, 
                public dialog: MdDialog,
                public navigationService: NavigationService,
                private notification: LogNotificationService, 
                private translate: TranslateService) {}

    show(label: string) {
        if (!!this.spinnerDialogRef) {
            return false;
          }
          this.label = label;
          this.spinnerDialogRef = this.dialog.open(SpinnerDialogComponent, {
            disableClose: true,
            data: {
              caption: this.translate.instant(`Saving ${this.label}`), isLoadingDotsShown: true
            }
          });
          //this.cd.detach();
          this.navigationService.showNavBar = false;
          this.startAutoDestroy();
          return true;
    }

    private startAutoDestroy() {
        this.activeTimeout = setTimeout(() => {
            if (!this.spinnerDialogRef) { return; }
            this.destroy();
            this.notification.error(this.translate.instant('CANNOT_BE_SAVED_ERROR', {X: this.label}), null);
          }, this.AUTO_HIDE_TIME);
    }

    destroy() {
        if (!this.spinnerDialogRef) {
            return;
          }
          //this.cd.reattach();
          clearTimeout(this.activeTimeout);
          this.navigationService.showNavBar = true;
          this.spinnerDialogRef.close();
          this.spinnerDialogRef = null;
    }
}