<div class="circularity">
  <section class="circularity__head">
    <h2 class="circularity__head--title">{{ 'Circularity' | translate }}</h2>
    <report-site-list [sites]="reports.sites" [tags]="reports.tags" [createdByFile]="reports.created_by_file"></report-site-list>
    <div *ngIf="isDescriptionBlock" class="circularity__head--description-block">
      <p class="circularity__head--description-text" [innerHTML]="'DESCRIPTION_TEXT_FOR_HEAD_IN_CIRCULARITY' | translate"></p>
      <i (click)="hideDescriptionBlock()" class="ib-icon icon--close_s circularity__head--description-icon"></i>
    </div>
  </section>
  <section *ngIf="!isEmptyProductCategory()" class="circularity__body">
    <div class="circularity__body--left-block">
      <pie-chart
        [mainColor]="'#34495E'"
        [mainValue]="mainValue"
        [pieChartSize]="pieChartSize.big"
      >
      </pie-chart>
      <div class="circularity__body--left-block-text">
        <p><span class="circularity__body--left-block-text-value">{{aggregateData.deposits_considered}} / {{aggregateData.deposit_count}}</span> {{ 'deposits considered' | translate }}</p>
        <p><span class="circularity__body--left-block-text-value">
          {{aggregateData.total_weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
        </span> {{ 'tons of deposits in total' | translate }}</p>
        <div class="circularity__body--left-block-link">
          <i class="icon blue-eye--icon"></i>
          <a class="circularity__body--left-block-link-item" (click)="goToArchive()">{{ 'See error report for more details' | translate }}</a>
        </div>
      </div>
    </div>
    <div class="circularity__body--right-block">
      <div class="circularity__body--right-block-contents">
        <h3 class="circularity__body--right-block-contents-title">{{ 'Previous lives' | translate }}</h3>
        <div class="circularity__body--right-block-contents-hr"></div>
        <div class="circularity__body--right-block-first-content">
          <p class="circularity__body--right-block-first-content-value">
            {{aggregateData.recycled_percentage | number: numberFormat(aggregateData.past_life_grade, '1.0-0', '1.2-2'):'fr' | decimalSeparator:','}}%
          </p>
          <div class="circularity__body--right-block-first-content-text">
            <p>{{ 'of recycled or rapidly' | translate }}</p>
            <p>{{ 'renewable materials' | translate }}</p>
            <p>{{ 'incorporated into products' | translate }}</p>
          </div>
          <p class="circularity__body--right-block-first-content-value">
            {{aggregateData.reused_percentage | number: numberFormat(aggregateData.past_life_grade, '1.0-0', '1.2-2'):'fr' | decimalSeparator:','}}%
          </p>
          <div class="circularity__body--right-block-first-content-text">
            <p>{{ 'of deposits' | translate }}</p>
            <p>{{ 'resulting' | translate }}</p>
            <p>{{ 'from reuse' | translate }}</p>
          </div>
        </div>
        <h3 class="circularity__body--right-block-contents-title">{{ 'Next usages' | translate }}</h3>
        <div class="circularity__body--right-block-contents-hr"></div>
        <div class="circularity__body--right-block-second-contents">
          <div class="circularity__body--right-block-second-content">
            <div class="circularity__body--right-block-second-content-items">
              <p [ngStyle]="aggregateData.demountability_grade == null && {'color': 'gray'}">{{ 'Disassemblability of products and deposits' | translate }}</p>
              <div class="circularity__body--right-block-second-content-wrapper">
                <circularity-rating-scale
                  [gradeValue]="aggregateData.demountability_grade"
                  [ratingPointsAmount]="6"
                  [mainColor]="'#132C83'"
                  *ngIf="aggregateData.demountability_grade != null"
                >
                </circularity-rating-scale>
                <div class="circularity__body--missing-data" *ngIf="aggregateData.demountability_grade == null">
                  <i class="icon icon--exclamation-point-red"></i>&nbsp;<span>{{ 'Missing data' | translate }}</span>
                </div>
                <label class="form-group__label"
                       [mdTooltipPosition]="'left'"
                       md-tooltip="{{ 'DISASSEMBLABILITY_TOOLTIP' | translate }}"
                >
                  <i class="form-group__label--question">?</i>
                </label>
              </div>
            </div>
          </div>
          <div class="circularity__body--right-block-second-content">
            <div class="circularity__body--right-block-second-content-items circularity__body--right-block-second-content-item-second">
              <p>{{ 'Identifcation and potential of future uses' | translate }}</p>
              <div class="circularity__body--right-block-second-content-wrapper">
                <circularity-rating-scale
                  [gradeValue]="aggregateData.next_usage_potential_grade"
                  [ratingPointsAmount]="6"
                  [mainColor]="'#132C83'"
                >
                </circularity-rating-scale>
                <label class="form-group__label"
                       [mdTooltipPosition]="'left'"
                       md-tooltip="{{ 'FUTURE_USE_TOOLTIP' | translate }}"
                >
                  <i class="form-group__label--question">?</i>
                </label>
              </div>
            </div>
          </div>
          <div class="circularity__body--right-block-second-content">
            <div class="circularity__body--right-block-second-content-items">
              <p>{{ 'Presence and reliability of information' | translate }}</p>
              <div class="circularity__body--right-block-second-content-wrapper">
                <circularity-rating-scale
                  [gradeValue]="aggregateData.information_characterization_and_rating_grade"
                  [ratingPointsAmount]="6"
                  [mainColor]="'#132C83'"
                >
                </circularity-rating-scale>
                <label class="form-group__label"
                       [mdTooltipPosition]="'left'"
                       md-tooltip="{{ 'PRESENCE_RELIABILITY_TOOLTIP' | translate }}"
                >
                  <i class="form-group__label--question">?</i>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="!isEmptyProductCategory()" class="circularity__footer">
    <div class="circularity__footer--circularity-product-category-blocks">
      <circularity-product-category-block
        [data]="mostPresentProductCategory"
        [title]="'MOST_PRESENT_PRODUCT_CATEGORY' | translate"
        [mainColor]="'#693293'"
      >
      </circularity-product-category-block>
      <circularity-product-category-block
        [data]="mostCircularProductCategory"
        [title]="'MOST_CIRCULAR_PRODUCT_CATEGORY' | translate"
        [mainColor]="'#72C15B'"
      >
      </circularity-product-category-block>
      <circularity-product-category-block
        [data]="leastCircularProductCategory"
        [title]="'LEAST_CIRCULAR_PRODUCT_CATEGORY' | translate"
        [mainColor]="'#EF3B6C'"
      >
      </circularity-product-category-block>
    </div>
  </section>
  <section *ngIf="isEmptyProductCategory()" class="circularity__body section">
    <h3 class="section__error-title">{{ 'No deposit considered' | translate }}</h3>
  </section>
</div>
