export enum EDepositSort {
  CREATED_DESC = '-created_at',
  AVAILABILITY = 'availability_date',
  NAME = 'name',
  ADDRESS = 'address'
}

export const DEPOSIT_SORT_NAME = [
  { key: EDepositSort.CREATED_DESC, value: 'Creation date' },
  { key: EDepositSort.AVAILABILITY, value: 'Availability date' },
  { key: EDepositSort.NAME, value: 'Name' },
  { key: EDepositSort.ADDRESS, value: 'Site' }
];
