/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report-pdf-site-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./report-pdf-site-list.component";
import * as i5 from "../../../../../services/el-row-counter.service";
var styles_ReportPdfSiteListComponent = [i0.styles];
var RenderType_ReportPdfSiteListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportPdfSiteListComponent, data: {} });
export { RenderType_ReportPdfSiteListComponent as RenderType_ReportPdfSiteListComponent };
function View_ReportPdfSiteListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "report-pdf__sites-chips"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Generated by spreadsheet")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReportPdfSiteListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "report-base__sites-chips"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("All sites")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReportPdfSiteListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportPdfSiteListComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportPdfSiteListComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isGenerateFromFile === true); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.isGenerateFromFile === false); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ReportPdfSiteListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "report-pdf__sites-chips-tag"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ReportPdfSiteListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["sites", 1]], null, 4, "div", [["class", "report-pdf__sites-chips"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "report-pdf__sites-chips-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportPdfSiteListComponent_5)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.tags; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
export function View_ReportPdfSiteListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { sitesEl: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "report-pdf__sites-chips-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportPdfSiteListComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportPdfSiteListComponent_4)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isLongVersion && (_co.sites.length === 0)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.sites; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ReportPdfSiteListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "report-pdf-site-list", [], null, null, null, View_ReportPdfSiteListComponent_0, RenderType_ReportPdfSiteListComponent)), i1.ɵdid(1, 49152, null, 0, i4.ReportPdfSiteListComponent, [i5.ElRowCounterService], null, null)], null, null); }
var ReportPdfSiteListComponentNgFactory = i1.ɵccf("report-pdf-site-list", i4.ReportPdfSiteListComponent, View_ReportPdfSiteListComponent_Host_0, { isLongVersion: "isLongVersion", isGenerateFromFile: "isGenerateFromFile", measureRows: "measureRows", changeSites: "sites" }, {}, []);
export { ReportPdfSiteListComponentNgFactory as ReportPdfSiteListComponentNgFactory };
