import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import { ImportConfigurationService } from '../../../services/import-configuration.service';
import {ImportTable} from '../../../models/import-configuration.class';
import {ORDER_BY_TABLE} from '../../../values/level-by-type.const';
@Component({
    moduleId: module.id,
    selector: 'import-deposit-table-item',
    templateUrl: 'import-deposit-table-item.component.html',
    styleUrls: ['./import-deposit-table-item.component.scss']
  })
  export class ImportDepositTableItemComponent implements OnInit {

    @Input() table: ImportTable;

    constructor(
      public importConfigService: ImportConfigurationService
    ) {}

    ngOnInit(): void {
      const orderFn = ORDER_BY_TABLE[this.table.type];
      this.table.nbLevels = orderFn(this.table.nbLevels);
    }

    getTableTitle() {
      return `LOOKUP_TABLE_TYPE_${this.table.type}_TITLE`;
    }

    getRowNumber(level, index) {
      return index;
    }

    getRowTitle(i) {
      return `LOOKUP_TABLE_TYPE_${this.table.type}_ORDER_${i}`;
    }

    addSynonym(fieldOrder) {

      const toAdd = this.table.synonymModelMap[fieldOrder];
      if (toAdd.trim()) {
        this.importConfigService.addSynonym(this.table.id, fieldOrder, toAdd).subscribe((r) => {
          this.table.valuesMap[fieldOrder].push(r);
          this.table.synonymModelMap[fieldOrder] = '';
        });
      }
    }

    deleteSynonym(fieldOrder, idSynonym) {
      this.importConfigService.deleteSynonym(idSynonym).subscribe(() => {
        const index = this.table.valuesMap[fieldOrder].findIndex(({id}) => id === idSynonym);
        this.table.valuesMap[fieldOrder] = [
          ...this.table.valuesMap[fieldOrder].slice(0, index),
          ...this.table.valuesMap[fieldOrder].slice(index + 1)
        ];
      });
    }

    onUnfocus(fieldOrder) {
      console.log('bonjour')
      this.addSynonym(fieldOrder);
    }
  }
