import {Component, Inject} from '@angular/core';
import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';
import {DepositOrder} from '../../../ecosystem-manager/models/deposit-order.class';
import {ConfirmOrderDialogService} from '../../../../shared/services/confirm-order-dialog.service';
import {RequestFileSenderService} from '../../../admin/services/request-file-sender.service';
import {concat} from 'rxjs';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {first, mergeMap, tap} from 'rxjs/operators';
import {ERoleIds} from '../../../../shared/values/role-ids.enum';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {IConfirmDialogData} from '../../../../shared/confirm-dialog/values/interface/confirm-dialog-data.interface';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'deposit-order-transaction-confirm-dialog',
  templateUrl: './deposit-order-transaction-confirm-dialog.component.html',
  styleUrls: ['./deposit-order-transaction-confirm-dialog.component.scss'],
  providers: [{provide: RequestFileSenderService, useClass: RequestFileSenderService}]
})
export class DepositOrderTransactionConfirmDialogComponent {

  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<void>,
              public confirmOrderDialogService: ConfirmOrderDialogService,
              public requestFileSenderService: RequestFileSenderService,
              private notification: LogNotificationService
              ) {
  }

  openConfirmation(order: Observable<any>) {
    const callbackOnError = () => this.notification.error('One of the documents could not be uploaded', null, true);
    const observer =
      this.requestFileSenderService.getDocumentUploadObservable()
        .pipe(tap(() => {},
        () => callbackOnError()))
        .pipe(first())
        .pipe(mergeMap(
        () => order.do(() => this.confirmOrderDialogService.afterConfirmationShowSuccessMessage())
      ));
    let overrideData: Partial<IConfirmDialogData> = {};
    overrideData = this.confirmOrderDialogService.getDefaultOverrideForESM(overrideData);
    this.confirmOrderDialogService.open(observer, this.mdDialogRef, overrideData);
  }
}
