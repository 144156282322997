import { Component, Inject } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
@Component({
  moduleId: module.id,
  template: `
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>

    <virgin-product-details
      [id]="mdDialogData.id"
      (saved)="mdDialogRef.close(true)"
      [readOnly]="mdDialogData.readOnly"
    ></virgin-product-details>
  `,
  host: {
    'class': 'dialog-close-button-container',
  },
})
export class VPDetailsDialogComponent {
  constructor(public mdDialogRef: MdDialogRef<any>,
              @Inject(MD_DIALOG_DATA) public mdDialogData: any) {
  }
}
