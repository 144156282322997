/**
 * Created by atat on 26/06/2017.
 */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EcosystemStep } from '../../../models/ecosystem-step.class';
import { Stakeholder } from '../../../../../entities/stakeholder/models/stakeholder.class';
import { StakeholderService } from '../../../../../entities/stakeholder/services/stakeholder.service';

@Component({
  moduleId: module.id,
  selector: 'esm-compiler-stakeholder',
  templateUrl: 'esm-compiler-stakeholder.component.html'
})
export class EsmCompilerStakeholderComponent implements OnChanges {
  @Input() step: EcosystemStep;
  public stakeHolder: Stakeholder = new Stakeholder;
  arrSection = [1, 1];

  constructor(public stakeholderService: StakeholderService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['step']) {
      this.stakeholderService
        .view(this.step.entity_id, {
          //expand: 'creator',
        })
        .subscribe((stakeHolder: Stakeholder) => {
          this.stakeHolder = stakeHolder;
        });
    }
  }

  changeTab(index: number) {
    this.arrSection[index] = +!this.arrSection[index];
  }

  isOpen(index: number) {
    return this.arrSection[index];
  }

  sendEmail() {
    location.href = 'mailto:' + this.stakeHolder.email;
  }
}
