import {Component, EventEmitter, Input} from '@angular/core';
import { ImportConfigurationService } from '../../services/import-configuration.service';
import {ImportTable} from '../../models/import-configuration.class';

@Component({
    moduleId: module.id,
    selector: 'import-deposit-table',
    templateUrl: 'import-deposit-table.component.html',
    styleUrls: ['./import-deposit-table.component.scss']
  })
  export class ImportDepositTableComponent {

    @Input() tables: Array<ImportTable>;
    constructor(
      public importConfigurationService: ImportConfigurationService
    ) { }
  }

