<div class="esm-catalog">
  <header class="esm-catalog__header checkbox header--title-group">
    <h3 class="esm-catalog__inline-title">{{'Needs' | translate}}</h3>
    <div *ngFor="let status of filterStatuses">
      <md-checkbox class="esm-catalog__group--checkbox checkbox-group"
                   [name]="status.text"
                   (change)="toggleState(status.value);"
                   checked="true">
        {{status.view | capitalize | translate}}
      </md-checkbox>
    </div>
  </header>
  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input test-id="search"
                 mdInput
                 autocomplete="off" 
                 placeholder="{{'Search' | translate}}"
                 [formControl]="search"
          >
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--people-grey"></i>
        <md-select test-id="sales-manager-selector"
                   class="select-group"
                   placeholder="{{'Need manager' | translate}}"
                   [(ngModel)]="saleId"
                   (change)="getRecords()">
          <md-option>{{'Any' | translate}}</md-option>
          <md-option *ngFor="let sale of salesList" [value]="sale.id">{{sale.name}}</md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select">
        <md-select
          class="select-group"
          placeholder="{{ 'Sort by' | translate }}"
          [(ngModel)]="sortValue"
          (ngModelChange)="getRecords()"
        >
          <md-option
            *ngFor="let sortValue of sortOptions"
            [value]="sortValue.field"
          >{{ sortValue.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
    [cloudIcon]="true"
  ></custom-list>
</div>
