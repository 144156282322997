import {Component, Input} from '@angular/core';
import {ReadOnlyService} from '../../../../shared/read-only/read-only.service';
import {Passport} from '../../models/passport.class';
import {PASSPORT_RATING} from '../../values/passport-rating/passport-rating-values.const';


@Component({
  moduleId: module.id,
  selector: 'evaluate-passport-rating',
  styleUrls: ['evaluate-passport-rating.component.scss'],
  templateUrl: 'evaluate-passport-rating.component.html',
  providers: [ReadOnlyService]
})
export class EvaluatePassportRatingComponent {
  @Input() parentForm;
  @Input() passport: Passport;

  public ratingValues = PASSPORT_RATING;
  public objectValues = Object.values;

  constructor() {
    // this property is only used when sending requests to filter passports,
    // not when setting rating for the passport
    delete this.ratingValues.DECLARATION_OR_THIRD_PARTY_VALID;
  }
}