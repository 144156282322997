import {Component, EventEmitter, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {IColumnMeta, IColumnOptions} from '../../values/interfaces/column-meta.interface';
import {ImportValidatorsService} from '../../services/import-validators.service';
import {Subscription} from 'rxjs/Subscription';
import {auditTime, filter, pairwise, startWith} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';


@Component({
  moduleId: module.id,
  selector: 'import-deposit-columns',
  templateUrl: 'import-deposit-columns.component.html',
  styleUrls: ['./import-deposit-columns.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportDepositColumnsComponent implements OnDestroy {
  @Input('form') set setForm(form: FormGroup) {
    this.form = form;
    this.formChangeSubscribe();
  }
  @Input() userProhibitLetters: string[] = [];
  @Input() letterGenerator: () => string = this.defaultLetterGenerator;
  list: (IColumnOptions | { controlName: string })[];
  metaObject: IColumnMeta = {};
  form: FormGroup;
  columnBlur$ = new EventEmitter<string>();
  private nextLetterIndex = 0;
  private columnProhibitLetters: string[] = [];
  private allProhibit: Set<string> = new Set<string>();
  private subscriptions: Subscription[] = [];

  @Input('fields') set fields(fields: IColumnMeta) {
    this.list = this.transformColumnObjectToList(fields);
    this.metaObject = fields;
    this.formChangeSubscribe();
    this.generateMissingColumnLetters();
  }


  constructor(
    public importValidatorsService: ImportValidatorsService
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private unsubscribe() {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  private formChangeSubscribe() {
    this.unsubscribe();
    if (this.form) {
      this.subscriptions = [];
      const service = this.importValidatorsService;
      service.prohibitForForm(this.form, this.metaObject, [
        service.prohibitLength(3).bind(service),
        service.prohibitNoneLetters.bind(service)
      ]).map(meta => this.prohibitWithDelay(900, meta, [
          (next, prev) => !!service.prohibitDuplicationInputAndShowWarning(meta.name, next, prev, this.form, this.metaObject)
        ])
      ).forEach(subscription => this.subscriptions.push(subscription));
      this.form.setValidators(() => service.validateDuplicatedColumnNames(this.form));
    }
  }

  private prohibitWithDelay(delay: number, controlMeta: {control, name, observable}, validators: ((next, prev) => boolean)[]) {
    const {control, name, observable} = controlMeta;
    const prohibit = new Subject();
    const listener = new Subject();
    const subProhibit = prohibit.pipe(startWith(''), pairwise()).subscribe(([prev, next]) => {
      const isValid = validators.every(fn => fn(next, prev));
      if (!isValid) {
        control.setValue(prev);
        prohibit.next(prev);
      }
    });
    const subListener = listener.pipe(auditTime(delay)).subscribe((value) => prohibit.next(value));
    const subBlur = this.columnBlur$.pipe(filter(controlName => name === controlName))
      .subscribe(() => prohibit.next(control.value));
    this.subscriptions.push(subProhibit, subListener, subBlur);
    return observable.subscribe(([prev, next]) => listener.next(next));
  }

  private transformColumnObjectToList(fields) {
    return Object.keys(fields).map((key) => {
        return {...fields[key], controlName: key};
      }
    );
      // .sort((previous, next) => previous.order - next.order);
  }

  private generateMissingColumnLetters() {
    this.nextLetterIndex = 0;
    this.list.forEach((item: IColumnOptions) => {
      if (item.columnLetter) {
        this.columnProhibitLetters.push(item.columnLetter);
      }
    });
    this.allProhibit = new Set([...this.columnProhibitLetters, ...this.userProhibitLetters]);
    this.list.forEach((item: IColumnOptions) => {
      if (!item.columnLetter) {
        item.columnLetter = this.letterGenerator();
      }
    });
  }

  private defaultLetterGenerator() {
    const lettersFirst = 'A'.charCodeAt(0);
    const lettersLast = 'Z'.charCodeAt(0);
    const letterInterval = lettersLast + 1 - lettersFirst;
    return this.findNextValidLetter(letterInterval);
  }

  findNextValidLetter(letterInterval) {
    const nextExelColumn = this.createLetter(this.nextLetterIndex, letterInterval);
    this.nextLetterIndex += 1;
    if (this.allProhibit.has(nextExelColumn)) {
      return this.findNextValidLetter(letterInterval);
    } else {
      return nextExelColumn;
    }
  }

  private createLetter(orderInList, letterInterval) {
    const lettersFirst = 'A'.charCodeAt(0);
    const letter = String.fromCharCode(lettersFirst + (orderInList % letterInterval));
    if (letterInterval > orderInList) {
      return letter;
    } else {
      return this.createLetter(Math.floor(orderInList / letterInterval), letterInterval) + letter;
    }
  }

  clearFilters() {
    Object.keys(this.form.controls).forEach(item => this.form.get(item).setValue(''));
  }

  allowOnlyLetters(e: KeyboardEvent, multi = false) {
    this.importValidatorsService.preventKeypressIfNotLetterOrList(e, multi);
  }
}
