import { TransformFileService } from '../services/transform-file.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';

export class TransformFile extends UploadedDocument {
  protected provider = TransformFileService;

  transformation_id: number;

  protected fields() {
    return super.fields().concat([
      'transformation_id',
    ]);
  }
}

