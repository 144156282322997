import {ActiveRecord} from '../../../shared/models/active-record.class';
import {TransformQualityJunctionService} from '../services/transform-quality-junction.service';

export class TransformQualityJunction extends ActiveRecord {
  protected provider = TransformQualityJunctionService;

  public id: number;
  public name: string;
  public transformation_id: number;
  public quality_id: number;
  public is_input: number;

  protected fields() {
    return [
      'id',
      'quality_id',
      'transformation_id',
      'is_input',

      'name',
    ];
  }
}
