
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>

    <transform-site-details
      [id]="mdDialogData.id"
      [readOnly]="mdDialogData.readOnly"
      (saved)="mdDialogRef.close()"
    ></transform-site-details>
  