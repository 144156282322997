import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

/**
 * `BaseCheckboxComponent` acts as a reusable UI control for checkbox functionality within the application. 
 * It supports binding for both the checkbox's checked state and an optional label text. By emitting an 
 * event upon state change, it facilitates two-way data binding. The choice of `ViewEncapsulation.None` 
 * allows for global styling, making it easier to maintain consistent styling across the application.
 */
@Component({
  selector: 'app-base-checkbox',
  templateUrl: './base-checkbox.component.html',
  styleUrls: ['./base-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None, // Allows styles to be applied globally.
})
export class BaseCheckboxComponent {
  /**
   * Tracks whether the checkbox is checked.
   */
  @Input() isChecked: boolean = false;

  @Input() label?: string; // Optional label for the checkbox.
  @Output() isCheckedChange = new EventEmitter<boolean>(); // Emits changes to the checked state.

  /**
   * Toggles the checked state of the checkbox and emits an event to notify of the change.
   * This method ensures the checkbox can interact reactively with form data or other components.
   */
  onChange() {
    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
  }
}
