import { MdDialogRef } from '@angular/material';
import { Component } from '@angular/core';
import { NomenclatureService } from '../../service/nomenclature.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';

@Component({
  moduleId: module.id,
  templateUrl: 'nomenclature-change-confirmation-dialog.component.html'
})
export class NomenclatureChangeConfirmationDialogComponent {


  constructor(public mdDialogRef: MdDialogRef<any>,
              private nomenclatureService: NomenclatureService,
              private notification: LogNotificationService) {
  }

  close() {
    this.mdDialogRef.close(true)
  }
}
