import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { GenericPassportTagJunctionService } from '../service/generic-passport-tag-junction.service';
var GenericPassportTagJunction = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassportTagJunction, _super);
    function GenericPassportTagJunction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = GenericPassportTagJunctionService;
        return _this;
    }
    GenericPassportTagJunction.prototype.fields = function () {
        return [
            'id',
            'tag_id',
            'generic_passport_id',
            'name',
            'synonyms',
        ];
    };
    return GenericPassportTagJunction;
}(ActiveRecord));
export { GenericPassportTagJunction };
