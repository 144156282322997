import * as moment from 'moment';

export type DatePeriodNames = {
    day: string,
    zeroDays?: string,
    oneDay?: string,
    week?: string,
    month?: string,
    year?: string
}

type DatePeriodDayIdentifier = {
    [Property in keyof Partial<DatePeriodNames>]: number | ((days: number) => number)
}

const PERIOD_DAY_DESC: DatePeriodDayIdentifier = {
    year: (days) => days / 12 / 30.4,
    month: (days) => days / 30.4,
    week: (days) => days / 7,
    oneDay: 1,
    zeroDays: 0
}

const periodDescEntries = Object.keys(PERIOD_DAY_DESC).map(key => [key, PERIOD_DAY_DESC[key]]);

export function dateDiffLargestPeriod(dateFrom: moment.Moment, dateTo: moment.Moment
    , periodsNames: DatePeriodNames) {

    let validPeriod = periodDescEntries.find(period =>
        !!periodsNames[period[0]] && isValidPeriod(dateFrom, dateTo, period[1]));
    // console.log(dateFrom.toString(), dateTo.toString(), validPeriod);
    if (validPeriod) {
        if (typeof validPeriod[1] === 'number') return [periodsNames[validPeriod[0]]];
    } else {
        validPeriod = ['day', null];
    }

    const period = Math.round(getDiff(dateFrom, dateTo, validPeriod[1]));
    return [period, periodsNames[validPeriod[0]] + (Math.abs(period) > 1 ? 's' : '')];
}

function isValidPeriod(dateFrom: moment.Moment, dateTo: moment.Moment, periodOrFunc: ((days: number) => number) | number) {
    if (typeof periodOrFunc === 'number') {
        return getDiff(dateFrom, dateTo) === Number(periodOrFunc);
    } else {
        return Math.abs(getDiff(dateFrom, dateTo, periodOrFunc)) >= 1;
    }
}

function getDiff(dateFrom: moment.Moment, dateTo: moment.Moment, period ?: ((days:number)=> number)) {
    dateTo = dateTo.startOf('day');
    dateFrom = dateFrom.startOf('day');
    if (!!period) {
        return period(dateTo
        .diff(dateFrom, 'days'));
    } else {
        return dateTo.diff(dateFrom, 'days');
    }
}