export enum EDepositLocationStatus {
  IN_CONSTRUCTION = 1,
  IN_OPERATION = 2,
  STORAGE = 3
}

export const DepositLocationStatusName = {
  [EDepositLocationStatus.IN_CONSTRUCTION]: 'in construction',
  [EDepositLocationStatus.IN_OPERATION]: 'in operation',
  [EDepositLocationStatus.STORAGE]: 'storage',
}

