import {TransformService} from '../services/transform.service';
import {TRANSFORM_TYPE, TRANSFORM_TYPES} from '../../../dashboards/transformation-manager/values/transform-types.const';
import {TransformCondition} from './transform-condition.class';
import {TransformFile} from './transform-file.class';
import * as _ from 'lodash';
import {TransformType} from './transform-type.interface';
import {ExtendedActiveRecord} from '../../../shared/models/extended-active-record.class';
import {IPlumbStepSource} from '../../../dashboards/ecosystem-manager/interfaces/plumb-step-source.interface';
import {ECOSYSTEM_STEP_TYPE} from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';

import {TransformLocation} from './transform-location.class';
import {TransformOrder} from '../../../dashboards/ecosystem-manager/models/transform-order.class';
import {TransformTagJunction} from './transform-tag-junction.class';
import {TransformServiceArea} from './transform-service-area.class';
import {TransformMaterial} from './transform-material.class';
import {TransformSiteJunction} from './transform-site-junction.class';

import {
  GET_TRANSFORM_CONTROL_TYPE_LABEL,
  TRANSFORM_CONTROL_TYPE
} from '../../../dashboards/transformation-manager/values/transform-control-types.const';
import {TranslateService} from '@ngx-translate/core';
import {ServiceLocator} from '../../../shared/services/service-locator';
import {PASSPORT_UNITS} from '../../passport/values/passport-units.const';
import {TransformFormatJunction} from './transform-format-junction.class';
import {TransformPackagingJunction} from './transform-packaging-junction.class';
import {TransformQualityJunction} from './transform-quality-junction.class';
import {PASSPORT_DEFAULT_CATEGORY} from '../../passport/models/passport.class';
import {CategoryItem} from '../../category/models/category-item.class';
import {TRANSFORM_DEPENDENCY} from '../../../dashboards/transformation-manager/values/transform-dependency.const';
import {SourceConnectorService} from '../../../../e2e/helpers/source-connector/source-connector.service';
import PASSPORT_UNIT_TYPE = SourceConnectorService.PASSPORT_UNIT_TYPE;
import lodash from 'lodash';

export class Transform extends ExtendedActiveRecord implements IPlumbStepSource {
  private static readonly TON_CONVERSION = 1000;

  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  public id: number;
  public status: number;
  public transformation_type: number;
  public independent: number;

  public name: string;
  public english_name: string;
  public description: string;
  public details: string;

  public material_chemical: number;
  public material_infectious: number;
  public material_asbesthos: number;
  public material_inert: number;
  public material_solid: number;
  public material_liquid: number;
  public material_other: string;

  public controls_types: number;
  public controls_other: string;

  public conditions: TransformCondition[] = [];

  public locations: TransformSiteJunction[] = [];

  public materials: TransformMaterial[] = [];
  public tags: TransformTagJunction[] = [];
  public formats: TransformFormatJunction[] = [];
  public packagings: TransformPackagingJunction[] = [];
  public qualities: TransformQualityJunction[] = [];

  public files: TransformFile[] = [];

  public created_at: string;
  public updated_at: string;

  public sectors: TransformServiceArea[] = [];

  public sites: TransformLocation[] = [];
  public tsm_name: string;
  public tsm_email: string;
  public tsm_avatar: string;
  public tsm_org: string;
  public user_id: number;
  public creator: string;
  public message: string;

  public plumb_type = ECOSYSTEM_STEP_TYPE.TRANSFORM;

  public step_id: number;
  public orders: TransformOrder[] = [];


  public output_quantity: number;
  public input_unit: number;
  public output_unit: number;
  public in_conversion: number;
  public out_conversion: number;
  public min_quantity: number;
  public min_unit: number;
  public max_quantity: number;
  public max_unit: number;
  public co2_generated: number;
  public water_consumption: number;
  public take_back: number;
  public categories: CategoryItem[] = [];
  public transformation_categories = [];

  protected provider = TransformService;

  public static isWithoutUnitByType(transformType: number) {
    return [
      ...TRANSFORM_TYPES.filter(data => !data.includes.incomeOutcome).map(data => data.value)
    ].includes(transformType);
  }

  public static getConversionInUnit(transform: Transform, substitution?: number, defaultUnit = PASSPORT_UNIT_TYPE.TONNE) {
    return Transform.getUnit(transform.input_unit, transform, substitution, defaultUnit);
  }
  public static getConversionOutUnit(transform: Transform, substitution?: number, defaultUnit = PASSPORT_UNIT_TYPE.TONNE) {
    return Transform.getUnit(transform.output_unit, transform, substitution, defaultUnit);
  }

  private static getUnit(unit: number , transform: Transform, substitution: number, defaultUnit: number) {
    if (Transform.isWithoutUnitByType(transform.transformation_type)) {
      const isUnitValue = typeof substitution === 'number'
        && Object.values(PASSPORT_UNIT_TYPE).some((unitInList) => unitInList === substitution);
      return isUnitValue ? substitution : defaultUnit;
    }
    return unit || PASSPORT_UNIT_TYPE.KG;
  }

  public static transformQuantityToCustomDefault(input: any, transform: Transform, defaultConversion = Transform.TON_CONVERSION) {
    if (Transform.isTransformNumberToDefault(input, transform)) {
      return input / defaultConversion;
    }
    return input;
  }
  public static inverseQuantityToCustomDefault(input: any, transform: Transform, defaultConversion = Transform.TON_CONVERSION) {
    if (Transform.isTransformNumberToDefault(input, transform)) {
      return input * defaultConversion;
    }
    return input;
  }

  private static isTransformNumberToDefault(input: any, transform: Transform) {
    const isNumeric = (quantity) => typeof quantity === 'string' && !!quantity;
    const isFiniteNumber = (num) => {
      const transformFromString = isNumeric(num) ? Number(num) : num;
      return lodash.isFinite(transformFromString) ? num : null;
    };
    return Transform.isWithoutUnitByType(transform.transformation_type) && isFiniteNumber(input) !== null;
  }

  public static getConversion(transform: Transform, conversion: number, defaultConversion = 1) {
    if (Transform.isWithoutUnitByType(transform.transformation_type))  {
      return defaultConversion;
    }
    return conversion;
  }

  constructor(data = {}) {
    super();
    this.set(data);

    this.conditions = this.conditions.map((condition) => new TransformCondition(condition));
    this.locations = this.locations.map((location) => new TransformSiteJunction(location));
    this.files = this.files.map((file) => new TransformFile(file));
    this.materials = this.materials.map((material) => new TransformMaterial(material));
    this.tags = this.tags.map((tag) => new TransformTagJunction(tag));
    this.sectors = this.sectors.map((service_area) => new TransformServiceArea(service_area));
    this.sites = this.sites.map((site) => new TransformLocation(site));
    this.orders = this.orders.map((order) => new TransformOrder(order));
    this.formats = this.formats.map((format) => new TransformFormatJunction(format));
    this.packagings = this.packagings.map((packaging) => new TransformPackagingJunction(packaging));
    this.qualities = this.qualities.map((quality) => new TransformQualityJunction(quality));
    this.categories = this.categories.map((category) => new CategoryItem(category));
  }

  protected fields() {
    return [
      'id',
      'status',
      'transformation_type',
      'independent',

      'name',
      'english_name',
      'description',
      'details',

      'material_chemical',
      'material_infectious',
      'material_asbesthos',
      'material_inert',
      'material_solid',
      'material_liquid',
      'material_other',
      'message',

      'controls_types',
      'controls_other',

      'conditions',

      'locations',

      'files',

      'materials',
      'tags',
      'formats',
      'packagings',
      'qualities',

      'created_at',
      'updated_at',

      'sectors',
      'sites',
      'tsm_name',
      'tsm_email',
      'tsm_avatar',
      'tsm_org',
      'user_id',
      'creator',

      'orders',

      'output_quantity',
      'input_unit',
      'output_unit',
      'in_conversion',
      'out_conversion',
      'min_quantity',
      'min_unit',
      'max_quantity',
      'max_unit',
      'co2_generated',
      'water_consumption',
      'take_back',
      'categories',
      'transformation_categories',
    ];
  }

  get transformation_type_data(): TransformType {
    const transformationType =
      this.transformation_type === TRANSFORM_TYPE.RECYCLING
        ? TRANSFORM_TYPE.UPCYCLING
        : this.transformation_type;
    return TRANSFORM_TYPES.find(
      (TYPE) => TYPE.value === transformationType
    );
  }

  get inputMaterials() {
    return this.materials.filter(material => material.is_input);
  }

  get outputMaterials() {
    return this.materials.filter(material => !material.is_input);
  }

  get inputTags() {
    return this.tags.filter(tag => tag.is_input);
  }

  get outputTags() {
    return this.tags.filter(tag => !tag.is_input);
  }
  get inputFormats() {
    return this.formats.filter(format => format.is_input);
  }
  get outputFormats() {
    return this.formats.filter(format => !format.is_input);
  }
  get inputPackagings() {
    return this.packagings.filter(packaging => packaging.is_input);
  }
  get outputPackagings() {
    return this.packagings.filter(packaging => !packaging.is_input);
  }
  get inputQualities() {
    return this.qualities.filter(quality => quality.is_input);
  }
  get outputQualities() {
    return this.qualities.filter(quality => !quality.is_input);
  }
  get viewName() {
    return this.name || this.translateService.instant('(Unnamed)');
  }

  get controlTypeView() {
    if (this.controls_types === TRANSFORM_CONTROL_TYPE.OTHER) {
      return this.controls_other;
    } else {
      return this.translateService.instant(GET_TRANSFORM_CONTROL_TYPE_LABEL(this.controls_types));
    }
  }

  get acceptedMaterialsViewValues() {
    const acceptedMaterials = [];

    if (this.material_chemical) {
      acceptedMaterials.push( this.translateService.instant('Dangerous chemical'));
    }

    if (this.material_infectious) {
      acceptedMaterials.push(this.translateService.instant('Infectious product'));
    }

    if (this.material_asbesthos) {
      acceptedMaterials.push(this.translateService.instant('Asbesthos product'));
    }

    if (this.material_inert) {
      acceptedMaterials.push(this.translateService.instant('Inert product'));
    }

    if (this.material_solid) {
      acceptedMaterials.push(this.translateService.instant('Solid non hazardous product'));
    }

    if (this.material_liquid) {
      acceptedMaterials.push(this.translateService.instant('Liquid or wet non hazardous product'));
    }

    if (this.material_other) {
      acceptedMaterials.push(this.material_other);
    }
    return acceptedMaterials;
  }
  get acceptedMaterialsView() {
    return this.acceptedMaterialsViewValues.join(', ');
  }

  includesText(text: string) {
    text = text.toLocaleLowerCase();

    const doesNameInclude = this.name && _.includes(this.name.toLocaleLowerCase(), text);
    const isInTags = this.tags.some(item => item.name.toLocaleLowerCase() === text);
    const isInMaterials = this.materials.some(item => item.name.toLocaleLowerCase() === text);
    const isInFormats = this.formats.some(item => item.name.toLocaleLowerCase() === text);

    return doesNameInclude || isInTags || isInMaterials || isInFormats;
  }
  getUnitText(unit) {
    const foundUnit = PASSPORT_UNITS.find((item) => item.id === unit);
    return foundUnit ? this.translateService.instant(foundUnit.title) : unit;
  }
  getUnitTextWithoutTranslation(unit) {
    const foundUnit = PASSPORT_UNITS.find((item) => item.id === unit);
    return foundUnit ? foundUnit.title : unit;
  }
  is(type: TRANSFORM_TYPE): boolean {
    return this.transformation_type === type;
  }
  isIndependent() {
    return this.independent === TRANSFORM_DEPENDENCY.INDEPENDENT;
  }

}
