import { Injectable } from '@angular/core';
import {EcosystemProblem} from '../models/ecosystem-problem.model';
import {CrudService} from '../../../shared/services/crud.service';

@Injectable({
  providedIn: 'root'
})
export class EcosystemProblemService extends CrudService {
  protected namespace = 'ecosystem-problem';
  protected namespaceSingular = 'ecosystem-problem';
  protected ModelClass = EcosystemProblem;
}
