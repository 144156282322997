import {Passport} from '../models/passport.class';
import {PassportRating} from '../models/passport-rating.class';

export const PASSPORT_FORM_MODEL_JUNCTION = {
  category: '',
  gtins: '',
  passportUnit: 'unit',
  conversion: 'conversion',
  ratingC2C: '',
  ratingComposition: Array<keyof Passport | keyof PassportRating>('rating', 'rating_composition'),
  ratingRecyclability: Array<keyof Passport | keyof PassportRating>('rating', 'rating_recyclability'),
  ratingSocialFairness: Array<keyof Passport | keyof PassportRating>('rating', 'rating_social_fairness'),
  ratingEnergy: Array<keyof Passport | keyof PassportRating>('rating', 'rating_energy'),
  ratingWaterStewardship: Array<keyof Passport | keyof PassportRating>('rating', 'rating_water_stewardship'),

  components: '',

  noBannedMaterials: 'no_banned_materials',

  quantity: 'period',
  period: 'period_type',
  nextUsages: '',

  recyclePercentage: 'recycle_percentage',
  firstLife: 'first_life',

  carbonConversion: 'carbon_conversion',
  carbonProduct: 'carbon_product',
  carbonEnd: 'carbon_end',
  descriptionOfUnit: 'carbon_conversion_description',

  c2cCertified: 'c2c_certified',
  certificationLevel: 'certification_level',
  certificationExpiration: 'certification_expiration',
  certificationVersion: 'cert_version',
  certificationLevelMaterialHealth: 'cert_material_health_level',
  certificationLevelMaterialReuse: 'cert_material_reuse_level',
  certificationLevelCarbonManagement: 'cert_carbon_level',
  certificationLevelWaterStewardship: 'cert_water_level',
  certificationLevelSocialFairness: 'cert_socials_level',
  endDateOfLCA: 'carbon_conversion_date',
  carbonTotal: 'carbon_total',
  useStage: 'use_stage',
  carbonConstruction: 'carbon_construction',
  operationalEnergyUse: 'operational_energy_use',
  carbon_operational_water_use: 'carbon_operational_water_use',
  resourceRecoveryState: 'resource_recovery_stage',
  waterCycle: 'water_cycle',
  waterConversion: 'water_conversion',
  waterConstruction: 'water_construction',
  waterUse: 'water_use_stage',
  waterOperationalEnergy: 'water_operational_energy_use',
  waterOperationalWater: 'water_operational_water_use',
  waterEndOfLife: 'water_end_of_life',
  waterResourceRecovery: 'water_resource_recovery',
  waterStewardshipCertified: 'water_stewardship_certified',
  energyCycle: 'energy_cycle_renewable',
  energyCycleNone: 'energy_cycle',
  energyProduct: 'energy_product_renewable',
  energyProductNone: 'energy_product',
  energyConstruction: 'energy_construction_renewable',
  energyConstructionNone: 'energy_construction',
  energyUse: 'energy_use_stage_renewable',
  energyUseNone: 'energy_use_stage',
  energyOperationalEnergy: 'energy_operational_energy_use_renewable',
  energyOperationalEnergyNone: 'energy_operational_energy_use',
  energyOperationalWater: 'energy_operational_water_use_renewable',
  energyOperationalWaterNone: 'energy_operational_water_use',
  energyEndOfLife: 'energy_end_of_life_renewable',
  energyEndOfLifeNone: 'energy_end_of_life',
  energyResourceRecovery: 'energy_resource_recovery_renewable',
  energyResourceRecoveryNone: 'energy_resource_recovery',
  optimizationStrategy: 'optimization_strategy',
  usedEnergy: '',
  socialFairnessCertified: 'social_fairness_certified',

  passportDescription: 'description',

  colors: '',
  appearance: 'appearance',
  dimensionLength: 'dimension_length',
  dimensionWidth: 'dimension_width',
  dimensionHeight: 'dimension_height',
  purchasePrice: 'purchase_price',
  manufacturers: '',
  distributionAreas: '',
  passportR: 'recommendation',

  language: 'language',
  source: 'source',
  otherSource: 'otherSource',
  epdType: 'epdType',
  documentId: 'documentId',
  operator: 'operator',
  otherOperator: 'otherOperator',
  synchronized: 'synchronized',
};

export interface IPassportShort {
  'category': any;
  'gtins': any;
  'passportUnit': any;
  'conversion': any;
  'ratingC2C': any;
  'ratingComposition': any;
  'ratingRecyclability': any;
  'ratingSocialFairness': any;
  'ratingEnergy': any;
  'ratingWaterStewardship': any;
  'components': any;
  'quantity': any;
  'period': any;
  'nextUsages': any;
  'carbonConversion': any;
  'carbonProduct': any;
  'carbonEnd': any;
  'waterConversion': any;
  'waterEndOfLife': any;
  'descriptionOfUnit': any;
  'passportDescription': any;
}

export interface IPassportFormGroup {
  'socialPerformance': any;
  'general_info': any;
  'composition': any;
  'useAndCycle': any;
  'additionalInfo': any;
}

export const PASSPORT_SHORT: Exclude<keyof IPassportShort, keyof {}>[] =  [
  'category',
  'gtins',
  'passportUnit',
  'conversion',
  'ratingC2C',
  'ratingComposition',
  'ratingRecyclability',
  'ratingSocialFairness',
  'ratingEnergy',
  'ratingWaterStewardship',
  'components',
  'quantity',
  'period',
  'nextUsages',
  'carbonConversion',
  'descriptionOfUnit',
  'carbonProduct',
  'carbonEnd',
  'waterConversion',
  'waterEndOfLife',
  'passportDescription'
  ];

export const PASSPORT_LONG: Exclude<keyof typeof PASSPORT_FORM_MODEL_JUNCTION, keyof {}>[] =  [
  ...PASSPORT_SHORT,
  'noBannedMaterials',
  'recyclePercentage',
  'firstLife',
  'c2cCertified',
  'certificationLevel',
  'certificationLevelMaterialHealth',
  'certificationLevelMaterialReuse',
  'certificationLevelCarbonManagement',
  'certificationLevelWaterStewardship',
  'certificationLevelSocialFairness',
  'certificationVersion',
  'certificationExpiration',
  'descriptionOfUnit',
  'endDateOfLCA',
  'carbonTotal',
  'carbonConstruction',
  'useStage',
  'resourceRecoveryState',
  'operationalEnergyUse',
  'waterStewardshipCertified',
  'usedEnergy',
  'optimizationStrategy',
  'socialFairnessCertified',
  'waterCycle',
  'waterConversion',
  'waterConstruction',
  'waterUse',
  'waterOperationalEnergy',
  'waterOperationalWater',
  'waterEndOfLife',
  'waterResourceRecovery',
  'energyCycle',
  'energyCycleNone',
  'energyProduct',
  'energyProductNone',
  'energyConstruction',
  'energyConstructionNone',
  'energyUse',
  'energyUseNone',
  'energyOperationalEnergy',
  'energyOperationalEnergyNone',
  'energyOperationalWater',
  'energyOperationalWaterNone',
  'energyEndOfLife',
  'energyEndOfLifeNone',
  'energyResourceRecovery',
  'energyResourceRecoveryNone',
  'colors',
  'appearance',
  'dimensionLength',
  'dimensionWidth',
  'dimensionHeight',
  'purchasePrice',
  'manufacturers',
  'distributionAreas',
  'passportR'
];

export enum EPassportTabFormGroup {
  GENERAL,
  COMPOSITE ,
  CYCLE ,
  PERFORMANCE  ,
  ADDITIONAL
}

export type TPassportShortModelFormJunction = Exclude<keyof IPassportShort, keyof {}>[];
export type TPassportLongModelFormJunction =  Exclude<keyof typeof PASSPORT_FORM_MODEL_JUNCTION, keyof {}>[];
export interface IPassportModelFormJunction {
  readonly nameType: EPassportTabFormGroup;
  short: TPassportShortModelFormJunction;
  long: TPassportLongModelFormJunction;
  name: Exclude<keyof IPassportFormGroup, keyof {}>;
}

export const PASSPORT_GENERAL_SHORT_TAB_MODEL_FORM_JUNCTION: TPassportShortModelFormJunction = [
  'category',
  'gtins',
  'passportUnit',
  'conversion',
  'ratingC2C',
  'ratingComposition',
  'ratingRecyclability',
  'ratingSocialFairness',
  'ratingEnergy',
  'ratingWaterStewardship',
];

export const PASSPORT_GENERAL_TAB_MODEL_FORM_JUNCTION: IPassportModelFormJunction =  {
  short: PASSPORT_GENERAL_SHORT_TAB_MODEL_FORM_JUNCTION,
  long: [...PASSPORT_GENERAL_SHORT_TAB_MODEL_FORM_JUNCTION],
  nameType: EPassportTabFormGroup.GENERAL,
  name: 'general_info'
};


export const PASSPORT_MATERIAL_SHORT_TAB_MODEL_FORM_JUNCTION: TPassportShortModelFormJunction = [
  'components'
];

export const PASSPORT_MATERIAL_TAB_MODEL_FORM_JUNCTION: IPassportModelFormJunction =  {
  short: PASSPORT_MATERIAL_SHORT_TAB_MODEL_FORM_JUNCTION,
  long: [
    ...PASSPORT_MATERIAL_SHORT_TAB_MODEL_FORM_JUNCTION,
    'noBannedMaterials'
  ],
  nameType: EPassportTabFormGroup.COMPOSITE,
  name: 'composition'
};


export const PASSPORT_NEXT_USAGE_SHORT_TAB_MODEL_FORM_JUNCTION: TPassportShortModelFormJunction = [
  'quantity',
  'period',
  'nextUsages'
];

export const PASSPORT_NEXT_USAGE_TAB_MODEL_FORM_JUNCTION: IPassportModelFormJunction =  {
  short: PASSPORT_NEXT_USAGE_SHORT_TAB_MODEL_FORM_JUNCTION,
  long: [
    ...PASSPORT_NEXT_USAGE_SHORT_TAB_MODEL_FORM_JUNCTION,
    'recyclePercentage',
    'firstLife'
  ],
  nameType: EPassportTabFormGroup.CYCLE,
  name: 'useAndCycle'
};


export const PASSPORT_SOCIAL_SHORT_TAB_MODEL_FORM_JUNCTION: TPassportShortModelFormJunction = [
  'carbonConversion',
  'descriptionOfUnit',
  'carbonProduct',
  'carbonEnd',
  'waterConversion',
  'waterEndOfLife'
];

export const PASSPORT_SOCIAL_TAB_MODEL_FORM_JUNCTION: IPassportModelFormJunction =  {
  short: PASSPORT_SOCIAL_SHORT_TAB_MODEL_FORM_JUNCTION,
  long: [
    ...PASSPORT_SOCIAL_SHORT_TAB_MODEL_FORM_JUNCTION,
    'language',
    'source',
    'otherSource',
    'epdType',
    'documentId',
    'operator',
    'otherOperator',
    'synchronized',
    'c2cCertified',
    'certificationLevel',
    'certificationExpiration',
    'certificationVersion',
    'certificationLevelMaterialHealth',
    'certificationLevelMaterialReuse',
    'certificationLevelCarbonManagement',
    'certificationLevelWaterStewardship',
    'certificationLevelSocialFairness',
    'endDateOfLCA',
    'carbonTotal',
    'carbonConstruction',
    'useStage',
    'resourceRecoveryState',
    'operationalEnergyUse',
    'waterCycle',
    'waterConstruction',
    'waterUse',
    'waterOperationalEnergy',
    'waterOperationalWater',
    'waterResourceRecovery',
    'waterStewardshipCertified',
    'energyCycle',
    'energyCycleNone',
    'energyProduct',
    'energyProductNone',
    'energyConstruction',
    'energyConstructionNone',
    'energyUse',
    'energyUseNone',
    'energyOperationalEnergy',
    'energyOperationalEnergyNone',
    'energyOperationalWater',
    'energyOperationalWaterNone',
    'energyEndOfLife',
    'energyEndOfLifeNone',
    'energyResourceRecovery',
    'energyResourceRecoveryNone',
    'optimizationStrategy',
    'usedEnergy',
    'socialFairnessCertified'
  ],
  nameType: EPassportTabFormGroup.PERFORMANCE,
  name: 'socialPerformance'
};

export const PASSPORT_ADDITIONAL_SHORT_TAB_MODEL_FORM_JUNCTION: TPassportShortModelFormJunction = [
  'passportDescription'
];

export const PASSPORT_ADDITIONAL_TAB_MODEL_FORM_JUNCTION: IPassportModelFormJunction =  {
  short: PASSPORT_GENERAL_SHORT_TAB_MODEL_FORM_JUNCTION,
  long: [
    ...PASSPORT_GENERAL_SHORT_TAB_MODEL_FORM_JUNCTION,
    'colors',
    'appearance',
    'dimensionLength',
    'dimensionWidth',
    'dimensionHeight',
    'purchasePrice',
    'manufacturers',
    'distributionAreas',
    'passportR'
  ],
  nameType: EPassportTabFormGroup.ADDITIONAL,
  name: 'additionalInfo'
};

type PassportFormKeys = keyof typeof PASSPORT_FORM_MODEL_JUNCTION | Exclude<keyof IPassportFormGroup, keyof {}>;

export const PASSPORT_CONTROLS_WITH_VALIDATION: {[key: string]: PassportFormKeys} = {
  CATEGORY: 'category',
  RECYCLE_PERCENTAGE: 'recyclePercentage',
  QUANTITY: 'quantity',
  CARBON_TOTAL: 'carbonTotal',
  CARBON_PRODUCT: 'carbonProduct',
  CARBON_END: 'carbonEnd',
  CARBON_CONVERSION: 'carbonConversion',
  LENGTH: 'dimensionLength',
  WIDTH: 'dimensionWidth',
  HEIGHT: 'dimensionHeight',
  CONVERSION: 'conversion',
  PRICE: 'purchasePrice',
  USED_ENERGY: 'usedEnergy',
  WATER_CONVERSION: 'waterConversion',
  SOCIAL_PERFORMANCE: 'socialPerformance',
  COMPONENTS: 'components',
  CARBON_CONSTRUCTION: 'carbonConstruction',
  RESOURCE_RECOVERY_STAGE: 'resourceRecoveryState',
  OPERATIONAL_ENERGY_USE: 'operationalEnergyUse',
  USE_STAGE: 'useStage',
  OPERATIONAL_WATER_USE: 'carbon_operational_water_use',
};

export const PASSPORT_VALIDATION_FIELDS = [
  {
    key: PASSPORT_CONTROLS_WITH_VALIDATION.CATEGORY,
    value: 'Category'
  },
  {
    key: 'recyclePercentage',
    value: 'Share of recycled or rapidly renewable materials'
  },
  {
    key: 'passportUnit',
    value: 'Circular passport unit'
  },
  {
    key: 'quantity',
    value: 'Period of use'
  },
  {
    key: 'components',
    value: '% weight of product',
  },
  {
    key: 'percentageWeight',
    value : '% weight of product',
  },
  {
    key: 'materials',
    value : '% by weight of the component'
  },
  {
    key: 'componentName',
    value: 'Component'
  },
  {
    key: 'carbonTotal',
    value: 'Total whole life cycle'
  },
  {
    key: 'carbonProduct',
    value: 'Production (A1-A3 module)'
  },
  {
    key: PASSPORT_CONTROLS_WITH_VALIDATION.CARBON_CONSTRUCTION,
    value: 'Construction (A4-A5 module)'
  },
  {
    key: 'carbonEnd',
    value: 'End of life stage (C module)'
  },
  {
    key: 'carbonConversion',
    value: 'Conversion factor'
  },
  {
    key: 'dimensionLength',
    value: 'Length'
  },
  {
    key: 'dimensionWidth',
    value: 'Width'
  },
  {
    key: 'dimensionHeight',
    value: 'Height'
  },
  {
    key: 'conversion',
    value: 'Conversion'
  },
  {
    key: 'purchasePrice',
    value: 'List price'
  },
  {
    key: 'usedEnergy',
    value: 'Energy used'
  },
  {
   key: 'waterConversion',
   value: 'Net use of fresh water'
  },
  {
    key: PASSPORT_CONTROLS_WITH_VALIDATION.RESOURCE_RECOVERY_STAGE,
    value: 'Resource recovery state'
  },
  {
    key: PASSPORT_CONTROLS_WITH_VALIDATION.OPERATIONAL_ENERGY_USE,
    value: 'Operational energy use'
  },
  {
    key: PASSPORT_CONTROLS_WITH_VALIDATION.USE_STAGE,
    value: 'Use stage'
  },
  {
    key: 'passportEnglishName',
    value: 'English version of the name'
  },
];

export function getPassportValidationFieldValue(field: string) {
  const validationField = PASSPORT_VALIDATION_FIELDS.find(i => i.key === field);
  return !!validationField ? validationField.value : '';
}


