/**
 * Created by aleksandr on 13.06.17.
 */
import {Component, OnInit} from '@angular/core';
import { ICustomListRecord } from '../../../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../../../shared/custom-list/custom-list-record.model';
import { Stakeholder } from '../../../../../../entities/stakeholder/models/stakeholder.class';
import { StakeholderService } from '../../../../../../entities/stakeholder/services/stakeholder.service';
import { FormControl } from '@angular/forms';
import { MdDialog } from '@angular/material';
import { StakeholderDetailsDialogComponent }
  from '../../../../../../entities/stakeholder/dialogs/stakeholder-details-dialog/stakeholder-details-dialog.component';
import { STAKEHOLDER_TYPES } from '../../../../../../entities/stakeholder/values/stakeholder-types.const';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../../../../shared/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs/Observable';

@Component({
  moduleId: module.id,
  selector: 'esm-stakeholder-catalog',
  templateUrl: 'esm-stakeholder-catalog.component.html'
})
export class EsmStakeholderCatalogComponent implements OnInit {
  STAKEHOLDER_TYPES_ARRAY =
    Object.entries(STAKEHOLDER_TYPES)
      .filter((v) => Number(v[1]))
      .map((v) => ({view: v[0], value: v[1]}))
  ;
  records: ICustomListRecord[] = [];

  search = new FormControl();
  type: number;
  sortOrder = 'company_name';

  isNextPageLoading = false;
  currentPage = 1;
  totalPagesCount = 0;

  constructor(private _dialog: MdDialog,
              private _stakeholderService: StakeholderService,
              private _translate: TranslateService) {

  }

  onEndOfListTriggered() {
    if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount)  {
      this.getRecords(this.currentPage + 1);
    }
  }

  getRecords(forPage: number = 0) {
    let options = {
      expand: 'ecosystem',
      admin: 1,
      supervisor: 1,
      search: this.search.value || null,
      type: this.type || null,
      sort: this.sortOrder || null,
      page: forPage,
    };

    this.isNextPageLoading = true;
    this._stakeholderService
      .getWithPaginationData(options)
      .subscribe((res) => {
        if (forPage === 0) {
          this.records = [];
        }
        let headers = res.headers;
        this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
        this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
        res.json().map((item: any) => this.addRecord(new Stakeholder(item)));
        this.isNextPageLoading = false;
      });
  }

  addRecord(data: Stakeholder) {
    let statusView = (STAKEHOLDER_TYPES[data.type] || '').toLowerCase().replace(/./, s => s.toUpperCase());
    this.records.push(
      this.createStakeholderRecord(
        data.company_name,
        statusView,
        data.name,
        data.phone,
        data.email,
        data.address,
        data
      )
    );
  }

  ngOnInit(): void {
    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => this.getRecords())
    ;
    this.getRecords();
  }

  createStakeholderRecord(title: string,
                          subtitle: string,
                          contact: string,
                          phone: string,
                          email: string,
                          address: string,
                          stakeholder: Stakeholder): CustomListRecord {
    return CustomListRecord
      .build()
      .addTitleColumn(title, subtitle)
      .addListColumn([{
          key: '<i class="icon icon--people-dark"></i>',
          seperator: '',
          value: contact
        },
        {
          key: 'Phone', value: phone
        }
      ])
      .addListColumn([{
        key: 'Email', value: email
      }, {key: 'Address', value: address}
      ])
      .addControlsColumn([{
        fn: (data: ICustomListRecord) => {
          this._dialog.open(StakeholderDetailsDialogComponent, {
            data: {
              id: stakeholder.id,
            }
          }).afterClosed().subscribe(() => this.getRecords());
        },
        name: 'See details'
      }, {
        fn: (data: ICustomListRecord) => {
          this.deleteStakeholder(stakeholder);
        },
        name: 'Delete'
      }, {
        fn: (data: ICustomListRecord) => {
          location.href = 'mailto:' + email;
        },
        name: 'Send Email'
      }]);
  }

  createStakeholder() {
    this
      ._dialog
      .open(StakeholderDetailsDialogComponent, {data: {}})
      .afterClosed()
      .subscribe(() =>
        this.getRecords()
      )
    ;
  }

  deleteStakeholder(stakeholder: Stakeholder) {
    this._translate.get('CONFIRM_DELETE_STAKEHOLDER')
      .flatMap((translation) => {
        let dialogRef = this._dialog.open(ConfirmDialogComponent, {
          data: {
            text: translation,
            imgUrl: 'assets/png/warning_orange.png',
          }
        });

        return dialogRef.afterClosed();
      })
      .flatMap((confirmed) => {
        return confirmed
          ? stakeholder.destroy().map(() => true)
          : Observable.of(false);
      })
      .subscribe((deleted) => {
        if(deleted) {
          this.getRecords();
        }
      });
  }
}
