/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../partials/stakeholder-details/stakeholder-details.component.ngfactory";
import * as i2 from "../../../../shared/read-only/read-only.service";
import * as i3 from "../../partials/stakeholder-details/stakeholder-details.component";
import * as i4 from "../../services/stakeholder.service";
import * as i5 from "./stakeholder-details-dialog.component";
import * as i6 from "@angular/material";
var styles_StakeholderDetailsDialogComponent = ["[_nghost-%COMP%] {\n      display: block;\n      position: relative;\n    }\n\n    .close-button[_ngcontent-%COMP%] {\n      right: 16px;\n      top: 14px;\n      width: 16px;\n      height: 16px;\n      position: absolute;\n      cursor: pointer;\n    }"];
var RenderType_StakeholderDetailsDialogComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_StakeholderDetailsDialogComponent, data: {} });
export { RenderType_StakeholderDetailsDialogComponent as RenderType_StakeholderDetailsDialogComponent };
export function View_StakeholderDetailsDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "close-button"], ["src", "assets/png/ecosystem/icon--close_s.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "stakeholder-details", [], null, [[null, "saved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saved" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_StakeholderDetailsComponent_0, i1.RenderType_StakeholderDetailsComponent)), i0.ɵprd(512, null, i2.ReadOnlyService, i2.ReadOnlyService, []), i0.ɵdid(3, 114688, null, 0, i3.StakeholderDetailsComponent, [i2.ReadOnlyService, i4.StakeholderService], { readOnly: [0, "readOnly"], id: [1, "id"] }, { saved: "saved" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mdDialogData.readOnly; var currVal_1 = _co.mdDialogData.id; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_StakeholderDetailsDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [["class", "dialog-close-button-container"]], null, null, null, View_StakeholderDetailsDialogComponent_0, RenderType_StakeholderDetailsDialogComponent)), i0.ɵdid(1, 49152, null, 0, i5.StakeholderDetailsDialogComponent, [i6.MdDialogRef, i6.MD_DIALOG_DATA], null, null)], null, null); }
var StakeholderDetailsDialogComponentNgFactory = i0.ɵccf("ng-component", i5.StakeholderDetailsDialogComponent, View_StakeholderDetailsDialogComponent_Host_0, {}, {}, []);
export { StakeholderDetailsDialogComponentNgFactory as StakeholderDetailsDialogComponentNgFactory };
