/**
 * Created by aleksandr on 30.06.17.
 */

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChatService } from '../../chat.service';
import { ChatRoom } from '../../models/chat-room.model';
import { Subscription } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';
import { ChatMessage } from '../../models/chat-message.model';
import { IChatMessageFile } from '../../interfaces/chat-message.interface';
import { CurrentUserService } from '../../../../shared/services/current-user.service';

@Component({
  moduleId: module.id,
  selector: 'chat-form',
  templateUrl: 'chat-form.component.html'
})

export class ChatFormComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() public roomId: number;
  @Input() public room: ChatRoom;
  @Input() public isChatForTransformIndep: boolean = false;
  @Input() public isChatForNeedIndep: boolean = false;

  public isChatRestricted : boolean = false ;
  public message : string = "";
  public roomChangedSub: Subscription;
  public chatRoom: ChatRoom;
  public messageText: string;
  uploader: FileUploader;


  constructor(private _chatService: ChatService,private currentUserService: CurrentUserService) {

  }

  ngOnInit() {
    if (this.isChatForTransformIndep) {
      if (this.currentUserService.isCurrentContextESM() ) {
        this.currentUserService.info().subscribe((res : any) => {
          if (res.json()) {
           // this.admin_able_create_esm = resp.json().plan_id ;
            if ( res.json().has_transform_acces != 1) {
                this.isChatRestricted= true ;
                this.message = "YOU_HAVE_LOST_ACCESS";
            }
          }
        });
      }
    }
    if (this.isChatForNeedIndep) {
      if (this.currentUserService.isCurrentContextESM() ) {
        this.currentUserService.info().subscribe((res : any) => {
          if (res.json()) {
           // this.admin_able_create_esm = resp.json().plan_id ;
          // console.log(res.json());
            if ( ! res.json().has_need_acces ) {
              console.log('here not equal');
                this.isChatRestricted= true ;
                this.message = "YOU_HAVE_LOST_PUBLIC_NEED_ACCESS";
            }
          }
        });
      }
    }

    if (this.roomId) {
      // this
      //   ._chatService
      //   .createRoom(this.roomId)
      //   .flatMap((room: IChatRoom) => {
      //     this.chatRoom = room;
      //     return room.connect();
      //   })
      //   .subscribe((res) => console.log('onConnected to ', this.roomId));
    } else if (this.room) {
      this.onChatRoomChanged(this.room);
      this.room.connect();
      this.room
          .loadContacts()
          .flatMap(() => this.room.loadMessages()).subscribe();
    } else {
      this.onChatRoomChanged(this._chatService.activeRoom);
      this.roomChangedSub = this._chatService
                                .roomChanged$
                                .subscribe((room: ChatRoom) => this.onChatRoomChanged(room));
    }
  }

  initFileUploader() {
    if(!this.chatRoom) {
      return;
    }

    this.uploader = new FileUploader({
      url: this._chatService.getFileUploadUrl(this.chatRoom.id),
      itemAlias: 'file',
      autoUpload: true
    });

    this.uploader.onCompleteItem = ((_, file: any) => {
      let newMessage = this.chatRoom.messages.find((message: ChatMessage) => file.message_id);
      let chatFile: IChatMessageFile = file;

      if(newMessage) {
        if(!newMessage.file) {
          newMessage.file = [];
        }

        newMessage.file.push(chatFile);
      }

      this.uploader.clearQueue();
    });
  }

  ngOnDestroy() {
    if (this.roomChangedSub) {
      this.roomChangedSub.unsubscribe();
      this.roomChangedSub = null;
    }
  }

  sendMessage(event: any) {
    if (this.isChatRestricted) return ;
    event.preventDefault();
    this.chatRoom.sendMessage(this.messageText).subscribe((res: any) => console.log('message sent'));
    this.messageText = '';
  }

  onChatRoomChanged (room: ChatRoom) {
    console.log('ChatRoom has been changed');
    this.chatRoom = room;
    if(room) {
      this.chatRoom.readRoom().subscribe(() => this.initFileUploader());
    }
  }

  uploadFiles() {
    if(!this.uploader) {
      return;
    }

    this.fileInput.nativeElement.click();
  }
}
