<ul class="message-list"
    *ngIf="history"
    #messageList>
  <li class="message-list__message"
      #messagesElement
      *ngFor="let message of history; let index = index;"
      [ngClass]="{
        'received': !message.isOwner,
        'sent': message.isOwner,
        'secondary': isSecondaryMessage(index)
      }"
      [attr.data-message-id]="message.id"
      (click)="onMessageClick(message)">
    <div class="message__author">
      <div class="message__author-preview"><div [ngStyle]="{'background-image': 'url(' + message.author.image + ')'}" class="message__author-image"></div></div>
      <div class="message__author-name">{{message.author.name}}</div>
    </div>
    <div class="message__data" [ngClass]="{
              'message__data--confirmation': message.type === messageTypes.CONFIRMATION,
              'message__data--notification': message.type === messageTypes.NOTIFICATION
            }">
      <div class="message__bubble">
        <div class="message__text">{{message.body}}</div>
        <div class="message__icon" *ngIf="message.type !== messageTypes.TEXT">
          <i class="icon" [ngClass]="{
              'small-ok--icon': message.type === messageTypes.CONFIRMATION || message.type === messageTypes.NOTIFICATION
            }"></i>
        </div>
      </div>
      <div class="message__date">{{message.date}}</div>
    </div>
  </li>
</ul>

