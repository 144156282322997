/**
 * Created by Aleksandr C. on 9.02.17.
 */
import { Injectable } from '@angular/core';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { ILiteral } from 'ng2-adn-common';
import {Observable} from "rxjs";
import {Response} from "@angular/http";
import { GenericPassport } from '../models/generic-passport.class';

@Injectable()
export class GenericPassportService extends ExtendedCrudService {
  protected namespace = 'generic-passports';
  protected namespaceSingular = 'generic-passport';
  protected ModelClass = GenericPassport;

  private pagenr: number;

  setListPage(pagenr: number) {
    this.pagenr = pagenr;
  }
  getListPage() {
    return this.pagenr;
  }

  public copyToZones<T>(genericPassportIds: number[], zoneIds: number[]): Observable<any> {
    return this
      .sendPost(this.getEndpoint(this.singularEndpoint + `/copy-to-zones`), {generic_passport_ids: genericPassportIds, zone_ids: zoneIds})
      .map((res: Response) => <T> res.json())
      .catch(this.onError.bind(this));
  }

}
