import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardComponentBase, IFilterState } from '../../../app/components/base/dashboard/dashboard-component-base.directive';
import { PassportCollectionComponent } from '../../../app/components/passport/passport-Collection/passport-collection.component';
import { ManufacturerService } from '../../../entities/passport/services/manufacturer.service';
import { Tab } from '../components/filter-tab.component';
import { Router } from '@angular/router';
import { MdDialog } from '@angular/material';
import { Passport } from '../../../entities/passport/models/passport.class';
import { ManufacturerGenericComponent } from '../../../entities/passport/dialogs/manufacturer-generic-dialog/manufacturer-generic-dialog.component';
import { PassportRatingExplanationDialogComponent } from '../../../entities/passport/dialogs/passport-rating-explanation-dialog/passport-rating-explanation-dialog';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { CopyPassportDialogComponent } from '../../../entities/passport/dialogs/copy-passport-dialog/copy-passport-dialog.component';
import { GenericDeleteWarningComponent } from '../../../app/components/base/dialog/delete-warning/generic-delete-warning.component';
import { NavbarStateService } from '../../../shared/services/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_THEME } from '../../../shared/helpers/dialog-themes.const';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { PassportStatus } from '../../../entities/passport/models/passport-status.class';
import { PASSPORT_STATUS_CONST_MANUFACTURER, PASSPORT_STATUS_CONST_MANUFACTURER_TOBETREATED } from '../../../entities/passport/values/passport-status.const';
import { NomenclatureChangeDialogComponent } from '../../../entities/nomenclature/dialogs/nomenclature-change-dialog/nomenclature-change-dialog.component';
import { LocalStorageService } from '../../../shared/services/localStorage.service';

enum DashboardTabs {
  Published = 'published',
  toBeTreated = 'toBeTreated',
}

export interface IManufacturerFilterState extends IFilterState {
  status: number[];
}

@Component({
  selector: 'app-manufacturer-dashboard',
  templateUrl: './manufacturer-dashboard.component.html',
  styleUrls: ['./manufacturer-dashboard.component.scss'],
})
export class ManufacturerDashboardComponent extends DashboardComponentBase<IManufacturerFilterState> implements OnInit {
  currentTab: DashboardTabs = DashboardTabs.Published;
  isSpecialAdmin: boolean = false;
  @ViewChild('passportCollectionRef') passportCollection: PassportCollectionComponent;

  tabs: Tab[] = [
    { name: 'Published', count: 0, icon: null },
    { name: 'toBeTreated', count: 0, icon: 'pen' },
  ];

  constructor(
    private manufacturerService: ManufacturerService,
    private router: Router,
    public dialog: MdDialog,
    private navbarService: NavbarStateService,
    private translate: TranslateService,
    private currentUserService: CurrentUserService,
    private localStorageService: LocalStorageService
  ) {
    super();
    this.isSpecialAdmin = this.currentUserService.isSpecialAdmin;
  }

  ngOnInit() {
    this.initializeFilterStates();
    this.loadDataForTab();
  }

  private initializeFilterStates() {
    this.pagination = {
      current_page: 1,
      page_count: null,
      total_count: null,
      pagination_per_page: null,
      published_count: null,
      to_be_treated_count: null,
    };
    const stored = this.localStorageService.getStoredObject<{ states: { [key: string]: IManufacturerFilterState }, currentTab: DashboardTabs }>('manufacturerFilters');
    if (stored) {
      this.filterStates = stored.states;
      this.currentTab = stored.currentTab;
    } else {
      this.filterStates[DashboardTabs.Published] = this.getDefaultFilterState();
      this.filterStates[DashboardTabs.toBeTreated] = this.getDefaultFilterState();
      this.filterStates[DashboardTabs.toBeTreated].status = [1, 6];
    }
  }

  onStatusChanged(status: number[]): void {
    super.onStatusChanged(status);
  }

  public loadDataForTab(resetPage: boolean = true): void {
    if (resetPage) {
      this.pagination.current_page = 1;
      this.passportCollection.clearSelection();
    }
    this.isLoading = true;
    this.passports = []; // Clear current data for safety
    this.updateTabCounts();

    if (this.passportsRequestSubscription) {
      this.passportsRequestSubscription.unsubscribe();
    }

    let currentFilters = this.filterStates[this.currentTab] || this.getDefaultFilterState();
    const requestPayload = this.convertFilterStateToObj(currentFilters);
    if (requestPayload.status.length === 0) {
      requestPayload.status = this.getDefaultStatusFilter();
    }

    // Store the filters in local storage
    this.localStorageService.storeObject('manufacturerFilters', { states: this.filterStates, currentTab: this.currentTab });

    this.passportsRequestSubscription = this.manufacturerService.getPassports(this.pagination.current_page, 10, requestPayload).subscribe({
      next: (response: any) => {
        this.handleResponse(response);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  private handleResponse(response: any): void {
    const body = JSON.parse(response._body);
    const headers = response.headers;
    this.pagination = {
      current_page: headers.get('X-Pagination-Current-Page'),
      page_count: headers.get('X-Pagination-Page-Count'),
      total_count: headers.get('X-Pagination-Total-Count'),
      pagination_per_page: headers.get('X-Pagination-Per-Page'),
      published_count: headers.get('X-Total-Published'),
      to_be_treated_count: headers.get('X-Total-Drafts'),
    };
    this.updateTabCounts();
    this.passports = body.map((item: any) => new Passport(item));
  }

  public getDefaultFilterState(): IManufacturerFilterState {
    return {
      sortBy: 'performance',
      search: '',
      families: [],
      materials: [],
      categories: [],
      searchId: [],
      selectedOptions: [],
      databases: [],
      dataProviders: [],
      dataRepresentativity: [],
      epdType: [],
      rating: {
        passportWithoutRating: false,
        selectRatingFilter: false,
        C2cCertified: false,
        composition: { color: false, black: false },
        cycle: { color: false, black: false },
        energy: { color: false, black: false },
        water: { color: false, black: false },
        social: { color: false, black: false },
      },
      status: this.getDefaultStatusFilter()
    };
  }

  private getDefaultStatusFilter(): number[] {
    if (this.isSpecialAdmin) {
      if (this.currentTab === DashboardTabs.Published) {
        return [3];
      }
      return [1, 6];
    }
    return [2, 4, 1, 3];
  }

  onTabSelected(selectedTabName: string): void {
    this.pagination.current_page = 1;
    this.versionReference = null;
    this.passportCollection.clearVersionSelection();
    this.currentTab = DashboardTabs[selectedTabName];
    this.loadDataForTab();
  }

  onCreateNewPassport(): void {
    this.navigateToCreatePassport();
  }

  havePassportsSelected(): boolean {
    return this.passportCollection.selectedPassports.length > 0 || this.allPagesSelected;
  }

  private navigateToCreatePassport(): void {
    if (this.isSpecialAdmin) {
      this.openManufacturerGenericDialog((data) => {
        if (data && data.isValidated) {
          const { value } = data;
          this.router.navigate(['manufacturer/passports/new'], { queryParams: { type: value } });
        }
      });
    } else {
      this.router.navigate(['manufacturer/passports/new']);
    }
  }

  private openManufacturerGenericDialog(callback: (result: any) => void): void {
    const dialogRef = this.dialog.open(ManufacturerGenericComponent, {
      width: '650px',
      height: '310px',
    });

    dialogRef.afterClosed().subscribe(result => {
      callback(result);
    });
  }

  openRatingExplanationDialog(): void {
    this.dialog.open(PassportRatingExplanationDialogComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
      data: {
        isActionButtonsShown: false,
      }
    });
  }

  deleteOnePassport(passport: Passport): void {
    this.processSelectedPassports('delete', [passport], false);
  }

  public publishSelectedPassports(): void {
    if (this.passportCollection) {
      const selectedPassports = this.passportCollection.selectedPassports;
      if (selectedPassports && (selectedPassports.length > 0 || this.allPagesSelected)) {
        this.processSelectedPassports('publish', selectedPassports, this.allPagesSelected);
      }
    }
  }

  public deleteSelectedPassports(): void {
    if (this.passportCollection) {
      const selectedPassports = this.passportCollection.selectedPassports;
      if (selectedPassports && (selectedPassports.length > 0 || this.allPagesSelected)) {
        this.processSelectedPassports('delete', selectedPassports, this.allPagesSelected);
      }
    }
  }

  private showConfirmationDialog(confirmationMessage: string, onConfirm: () => void): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '880px',
      data: {
        text: confirmationMessage,
        imgUrl: 'assets/png/warning_darkpurple.png',
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        onConfirm();
      }
    });
  }

  private processSelectedPassports(action: 'publish' | 'delete', selectedPassports: Passport[], isAllSelected: boolean): void {
    const operationName = action === 'publish' ? 'confirmationPassportPublish' : 'confirmationPassportDeletion';
    const multipleOperationName = action === 'publish' ? 'multiplePublishConfirm' : 'multipleDeletionConfirmation';

    let confirmationMessage: string;
    if (selectedPassports.length === 1) {
      let passportName =  selectedPassports[0].getProductName(this.navbarService.multilanguage) + " - V" + selectedPassports[0].version;
      confirmationMessage = this.translate.instant(operationName, { passportName: passportName });
    } else {
      confirmationMessage = this.translate.instant(multipleOperationName, { X: selectedPassports.length });
    }

    this.showConfirmationDialog(confirmationMessage, () => {
      this.isLoading = true;
      const passportIds = selectedPassports.map(passport => passport.id.toString());

      const tab = this.currentTab;
      let currentFilters = Object.assign({}, this.filterStates[tab]);
      if (tab === DashboardTabs.toBeTreated && this.filterStates[tab].status.length === 0) {
        currentFilters.status = [1, 6, 4, 2];
      }
      const requestPayload = this.convertFilterStateToObj(currentFilters);
      let payload = {
        passportIds: passportIds,
        isAllPagesSelected: isAllSelected,
        ...isAllSelected && requestPayload
      };

      const serviceMethod = action === 'publish' ? this.manufacturerService.publishPassports : this.manufacturerService.deletePassports;

      serviceMethod.call(this.manufacturerService, payload).subscribe({
        next: (response) => {
          response = JSON.parse(response._body);
          if (response.status === 'success') {
          } else if (response.status === "error") {
            this.openDeleteWarningDialog(response.errors, action === 'publish' ? "passportPublicationNotWorking" : "passportDeletionNotWorking");
          }
        },
        error: (error) => {
          console.error(`Error ${action}ing passports:`, error);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.loadDataForTab();
        }
      });
    });
  }

  public resetFiltersToDefault() {
    this.filterStates[this.currentTab] = this.getDefaultFilterState();
    this.loadDataForTab();
  }

  duplicatePassport(record: Passport) {
    const dialogRef = this.dialog.open(CopyPassportDialogComponent, {
      data: {
        name: record.name,
        parentModule: 'manufacturer'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.manufacturerService.duplicatePassports(record.id, result).subscribe({
          next: (response) => {
            const body = JSON.parse(response._body);
            this.isLoading = false;
            this.router.navigate(['agent/passports', body.id]);
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error duplicating passport:', error);
          }
        });
      }
    });
  }

  private openDeleteWarningDialog(errors: any[], title: string): void {
    const errorItems = errors.map(error => ({
      name: error.name,
      error: this.translate.instant(error.error) +
        (error.missingFields ? error.missingFields.map((field: string) => this.translate.instant(field)).join(', ') : '')
    }));
    this.dialog.open(GenericDeleteWarningComponent, {
      ...DIALOG_THEME.WIDE_PRIMARY,
      data: {
        title: title,
        category: this.translate.instant("Passport name"),
        items: errorItems,
      },
      width: '880px',
      height: '400px'
    });
  }

  updateTabCounts(): void {
    this.tabs = [
      { ...this.tabs[0], count: this.pagination.published_count },
      { ...this.tabs[1], count: this.pagination.to_be_treated_count },
    ];
  }

  getStatusFilter(): PassportStatus[] {
    if (this.isSpecialAdmin) {
      return Object.values(PASSPORT_STATUS_CONST_MANUFACTURER_TOBETREATED);
    }
    return Object.values(PASSPORT_STATUS_CONST_MANUFACTURER);
  }

  onAskForChangeInNomenclatureOpenDialog(): void {
    this.dialog.open(NomenclatureChangeDialogComponent);
  }
}