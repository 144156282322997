import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  OnInit, DoCheck, ViewChildren, QueryList, ChangeDetectionStrategy, AfterViewChecked, SimpleChanges

} from '@angular/core';
import { TransformOrder } from '../../../models/transform-order.class';
import { EcosystemStep } from '../../../models/ecosystem-step.class';
import { PASSPORT_UNITS, PASSPORT_UNITS_TYPE } from '../../../../../entities/passport/values/passport-units.const';
import { Transform } from '../../../../../entities/transformation/models/transform.class';
import { TransformService } from '../../../../../entities/transformation/services/transform.service';
import { TransformLocationService } from '../../../../../entities/transformation/services/transform-location.service';
import { TransformLocation } from '../../../../../entities/transformation/models/transform-location.class';
import { ChatRoom } from '../../../../../shared/chat/models/chat-room.model';
import { ChatService } from '../../../../../shared/chat/chat.service';
import { EThreadType } from '../../../../../shared/chat/interfaces/chat-message.interface';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { EcosystemFileDialogComponent } from '../../../dialogs/ecosystem-file/ecosystem-file.component';
import { EcosystemFile } from '../../../models/ecosystem-file.class';
import { MdDialog } from '@angular/material';
import { EcosystemFileJunctionService } from '../../../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import { EcosystemFileJunction } from '../../../models/ecosystem-file-junction.class';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../../values/ecosystem-file-junction-item-type.const';
import { ECOSYSTEM_STEP_STATUS } from '../../../values/ecosystem-step-status.const';
import {
  TransformDetailsDialogComponent
} from '../../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import {DIALOG_THEME} from '../../../../../shared/helpers/dialog-themes.const';
import {TRANSFORM_TYPE, TRANSFORM_TYPES} from '../../../../transformation-manager/values/transform-types.const';
import {TranslateService} from '@ngx-translate/core';
import { CurrentUser } from '../../../../../shared/auth/current-user.class';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { TransformFileService } from '../../../../../entities/transformation/services/transform-file.service';
import { setTextareaHeightToFitContent } from '../../../../../shared/helpers/set-textarea-height-to-fit-content';
import moment from 'moment';
import {EcosystemPrefillService} from '../../../services/ecosystem-prefill.service';
import {EcosystemCompilerSharedActionsService} from '../../../services/ecosystem-compiler-shared-actions.service';
import {Subject} from 'rxjs/Subject';
import {debounceTime} from 'rxjs/operators';
import { forEach } from 'lodash';
import {OrderPassport} from '../../../models/order-passport.class';
@Component({
  moduleId: module.id,
  selector: 'esm-compiler-transformation',
  templateUrl: 'esm-compiler-transformation.component.html',
  styleUrls: ['esm-compiler-transformation.component.css']
})


export class TransformOrdersComponent implements OnInit, OnDestroy, OnChanges, DoCheck , AfterViewChecked{
  @Input() step: EcosystemStep;
  @Input() openChat?: boolean;
  @Output() stepChange = new EventEmitter;
  @Output() action = new EventEmitter();
  @ViewChild('outputDescription', { read: ElementRef }) outputDescriptionTextarea: ElementRef;
  @ViewChildren('inputDescription', { read: ElementRef }) inputDescriptionTextareas: QueryList<ElementRef>;
  @ViewChild('stepDescription', { read: ElementRef }) stepDescriptionTextarea: ElementRef;
  @ViewChild('transformDescription', { read: ElementRef }) transformDescriptionTextarea: ElementRef;
  tabStatus = {
    CLOSED: 0,
    OPENED: 1
  }
  tabType = {
    ORDER: 1,
    SUMMARY: 2,
    CHAT: 3
  }
  units: any[] = PASSPORT_UNITS;
  ECOSYSTEM_STEP_STATUS: any = ECOSYSTEM_STEP_STATUS;
  TRANSFORM_TYPE = TRANSFORM_TYPE;
  isTransformIndependent = false;
  hasTransformAccess: boolean;
  showCommunication: boolean;

  public rooms: {index: number, room: ChatRoom}[] = [];

  activeTab = -1;
  arrSection = [this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED];
  transform: Transform = new Transform;
  site: TransformLocation;
  dirty = false;
  orders: TransformOrder[] = [];
  order: TransformOrder;

  private isOrdersSyncWithStep = false;
  private onChange$ = new Subject();
  private prefillUpdateObserver;
  private updateFields = this.onChange$.pipe(debounceTime(300 )).subscribe(() => this.saveStep());
 
  private skipSave = false;
  private isOrderTabOpen = this.arrSection[1];

  constructor(public transformFileService: TransformFileService,
              public dialog: MdDialog,
              private transformLocationService: TransformLocationService,
              private transformService: TransformService,
              private _chatService: ChatService,
              private ecoFileJunctionService: EcosystemFileJunctionService,
              private _translate: TranslateService,
              private globalEventsManager: GlobalEventsManager,
              public filterState: NavbarStateService,
              private prefillService: EcosystemPrefillService,
              private ecosystemCompilerSharedActionsService: EcosystemCompilerSharedActionsService
              ) {
  }

  @HostListener('focusin') onChange() {
    this.dirty = true;
  }

  get types() {
    return TRANSFORM_TYPES.filter(data => !data.includes.incomeOutcome).map(data => data.value);
  }

  ngOnInit() {
    this.prefillUpdateObserver =
      this.ecosystemCompilerSharedActionsService.onPrefillFinished.subscribe(() => {
      this.orders.forEach(order => {
        if (this.prefillService.getPrefilledValue(order.id)) {
          order.in_order_passport.quantity = this.prefillService.getPrefilledValue(order.id).in;
          order.out_order_passport.quantity = this.prefillService.getPrefilledValue(order.id).out;
        }
      });
    });
  }

  ngOnChanges(): void {
    this.load();
    this.closeAllTabs();
    this.hasTransformAccess = CurrentUser.infoData.has_transform_acces;
    if (this.openChat) {
      this.changeTab(3);
      this.changeTab(4);
    }
  }

  ngDoCheck() {
    if (this.orders !== this.step.orders && this.isOrdersSyncWithStep) {
      this.isOrdersSyncWithStep = false;
      this.load();
      this.assignMissingAsOldValues();
    }
  }
  ngAfterViewChecked(){
    if (this.isOrderTabOpen !== this.arrSection[1]) {
      if (this.arrSection[1]) {
        this.expandSectionArea();
        
      }
      this.isOrderTabOpen = this.arrSection[1];
    }
  }

  private assignMissingAsOldValues() {
    const setOfOldOrders = this.step.orders.reduce((accumulator, order) => {
        accumulator[order.id] =  order;
        return accumulator;
      }, {});
    const newOrders = [];
    const formatDate = (date) => moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('DD-MM-YYYY');
    this.orders.forEach((order, index) => {
      if (setOfOldOrders[order.id]) {
        const item = Object.assign(setOfOldOrders[order.id], order) as TransformOrder;
        item.in_order_passport.linked_datetime = formatDate(item.in_order_passport.linked_datetime);
        item.out_order_passport.linked_datetime = formatDate(item.out_order_passport.linked_datetime);
        newOrders.push(item);
      }
    });
    this.orders = newOrders;
  }

  ngOnDestroy() {
    this.prefillUpdateObserver.unsubscribe();
    this.updateFields.unsubscribe();
    this.saveStep();
  }

  closeAllTabs() {
    this.arrSection = [this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED];
  }

  saveStep(reload= false) {
    this.dirty = !this.skipSave;
    this.globalEventsManager.compilerDataBeenUpdated = true;
    this.trySave().subscribe(() => {
      this.globalEventsManager.compilerDataBeenUpdated = false;
      this.globalEventsManager.compilerDataUpdated.emit();
      if (reload) {
        this.step.ecosystem.fetch().subscribe(() => {
          this.load();
        });
      }
    });
  }

  load() {
    if (this.dirty) {
      console.warn('loaded new step order ontop of an unsaved step??');
    }
    CurrentUser.infoData.has_transform_acces;
    this.transformService
      .getSingular({
        id: this.step.entity_id,
        step_id: this.step.id,
        expand: 'conditions,materials,sites,files,orders,tsm_email,tsm_name,tsm_org,sectors,tsm_avatar,categories' +
          ',formats,quantities,qualities,packagings,tags,orders.default_unit,orders.default_conversion',
        supervisor: 1,
      })
      .subscribe((transforms: Transform[]) => {
        if (transforms.length > 0) {
          this.transform = transforms[0];
          this.isTransformIndependent = this.isIndependentTransformation();
          this.showCommunication = (this.isTransformIndependent && this.hasTransformAccess) || !this.isTransformIndependent ;
          this.orders = transforms[0].orders;
          this.step.orders = this.orders;
          this.rooms = [];
          this.transform.orders = [];
          this.orders.forEach((order, index) => {
            order.transformation = this.transform;
            this.prefillOrder(order);
            this._chatService
              .getContextThreads(order.id, EThreadType.TYPE_TRANSFORMATION_ORDER)
              .subscribe((rooms: ChatRoom[]) => this.addChatroom(rooms[0], index));
          });
        }
        this.isOrdersSyncWithStep = true;
      }, () => this.isOrdersSyncWithStep = true);

    if (this.step.site_id) {
      this.transformLocationService
        .view(this.step.site_id)
        .subscribe((site: TransformLocation) => this.site = site);
    } else {
      this.site = null;
    }
  }

  private prefillOrder(order: TransformOrder) {
    if (!order.delivery_address) {
      order.delivery_address = order.out_order_passport.address;
    }
    if (!order.pickup_address) {
      order.pickup_address = order.in_order_passport.address;
    }
    if (!order.in_order_passport.quantity) {
      order.in_order_passport.quantity = this.prefillService.getOrderInPrefill(order);
    }
    if (!order.out_order_passport.quantity) {
      order.out_order_passport.quantity = this.prefillService.getOrderOutPrefill(order);
    }
  }

  trySave(step?: EcosystemStep): Observable<EcosystemStep> {
    return new Observable((observer: Observer<EcosystemStep>) => {
      step = step || this.step;
      // no need to save orders when we delete the step , because it deletes the order and we get parent id non valid
      if (step && step.canEditOrder && step.orders && step.orders.length && this.dirty) {
        this.dirty = false;
        for (let i = 0; i < step.orders.length; i++) {
          if (step.orders[i].child_step.linked_date) {
            step.orders[i].child_step.linked_date = moment(step.orders[i].child_step.linked_date).format('YYYY-MM-DD');
            step.orders[i].in_order_passport.linked_datetime = step.orders[i].child_step.linked_date;
          }
        }
        Observable.concat(
          Observable.zip(step.save()),
          Observable.zip(...step.orders.map(order => order.save()),
          Observable.zip(
            ...step.orders.map(order => order.in_order_passport.save()),
            step.orders[0].out_order_passport.save(),
          ),
          this.ecosystemCompilerSharedActionsService.beforeCompilerReload()),
        ).subscribe(
          null,
          err => observer.error(err),
          () => {
            observer.next(step);
            observer.complete();
          },
        );
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  changeTab(index: number) {
    if(this.arrSection[index] === this.tabStatus.CLOSED){
      this.arrSection[index] = this.tabStatus.OPENED;
    } else {
      this.arrSection[index] = this.tabStatus.CLOSED;
    }
  }
  isOpen(index: number) {
    return this.arrSection[index] === this.tabStatus.OPENED;
  }
  isReadOnly(): boolean {
    if (this.step.status === this.ECOSYSTEM_STEP_STATUS.BOOKED) {
      return true;
    }
    return !this.step.canEditOrder;
  }
  isRoomLoaded(index: number) {
    return this.rooms.length > index && !!this.rooms[index].room;
  }
  addChatroom(room: ChatRoom, index: number) {
    const isRoomNotExist = () => !this.rooms.map(junction => junction.room)
      .some(activeRooms => activeRooms.id === room.id);
    room.loadContacts().subscribe(() => {
      if (isRoomNotExist()) {
        this.rooms.push({room, index});
        this.rooms.sort((a, b) => a.index - b.index);
      }
    });
  }
  openFiles(order: TransformOrder) {
    const dialogRef = this.dialog.open(EcosystemFileDialogComponent, {
      width: '800px',
      height: '600px',
      data: {
        ecosystem: this.step.ecosystem,
        stepId: this.step.id
      }
    });
    dialogRef.afterClosed().subscribe((file: EcosystemFile) => {
      if (file) {
        this.addFileToOrder(file, order);
      }
    });
  }
  removeJunctionFromOrder(junction: EcosystemFileJunction, order: TransformOrder) {
    this.ecoFileJunctionService.remove(junction.id).subscribe(() => {
      order.ecosystem_file_junction.splice(order.ecosystem_file_junction.indexOf(junction), 1);
    });
  }
  addFileToOrder(file: EcosystemFile, order: TransformOrder) {
    const fileJunction = new EcosystemFileJunction({
      file_id: file.id,
      item_id: order.id,
      item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_TRANSFORMATION_ORDER
    });
    this.ecoFileJunctionService.create(fileJunction).subscribe((createdFileJunction: EcosystemFileJunction) => {
      if (createdFileJunction) {
        order.ecosystem_file_junction.push(createdFileJunction);
      }
    });
  }
  seeFullTransformation() {
    this.dialog.open(TransformDetailsDialogComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR,
      data: {
        id: this.transform.id,
        readOnly: true,
      }
    });
  }
  getTransformationIcon() {
    if (!this.transform || !this.transform.transformation_type_data) {
      return;
    }
    const iconName = this.transform.transformation_type_data.view.split(' ')[0].toLowerCase();
    return `../../../../../assets/png/ecosystem/types/${iconName}.png`;
  }

  getTransformationIconStyles(){
    if(this.transform.transformation_type_data.value){
      if(this.transform.transformation_type_data.value === TRANSFORM_TYPE.OTHER){
        return 'ecosystem-compiler__menu-item-header-container-icon--other';
      } else if(this.transform.transformation_type_data.value === TRANSFORM_TYPE.METHANIZATION) {
        return 'ecosystem-compiler__menu-item-header-container-icon--methanization'
      } else if(this.transform.transformation_type_data.value === TRANSFORM_TYPE.CONTROL) {
        return 'ecosystem-compiler__menu-item-header-container-icon--control'
      } else if(this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
        return 'ecosystem-compiler__menu-item-header-container-icon--transport'
      } else if(this.transform.transformation_type_data.value === TRANSFORM_TYPE.UPCYCLING || this.transform.transformation_type_data.value === TRANSFORM_TYPE.RECYCLING) {
        return 'ecosystem-compiler__menu-item-header-container-icon--upcycling'
      } else {
        return 'ecosystem-compiler__menu-item-header-container-icon';
      }
    }
  }

  isVisibleSite(site: string) {
    switch (site) {
      case 'site':
        return this.transform.is(TRANSFORM_TYPE.UPCYCLING)
          || this.transform.is(TRANSFORM_TYPE.RECYCLING)
          || this.transform.is(TRANSFORM_TYPE.SORTING)
          || this.transform.is(TRANSFORM_TYPE.STORAGE)
          || this.transform.is(TRANSFORM_TYPE.COMPOSTING);
      case 'geosite':
        return this.transform.is(TRANSFORM_TYPE.REPAIR) || this.transform.is(TRANSFORM_TYPE.OTHER);
      case 'service':
        return this.transform.is(TRANSFORM_TYPE.TRANSPORT);
    }
  }
  getCategoryName(categoryName: string): string {
    const translation = this._translate.instant(categoryName);
    return (categoryName.length > 7)
      ? translation.substr(0, 7) + '…'
      : translation;
  }
  isIndependentTransformation(): boolean {
    return this.transform.isIndependent();
  }
  filterUnits() {
    const filteredUnits = [this.units[PASSPORT_UNITS_TYPE.KG], this.units[PASSPORT_UNITS_TYPE.TONNE]];

    if (this.transform.is(TRANSFORM_TYPE.UPCYCLING) ||
        this.transform.is(TRANSFORM_TYPE.SORTING) ||
        this.transform.is(TRANSFORM_TYPE.COMPOSTING) ||
        this.transform.is(TRANSFORM_TYPE.METHANIZATION) ||
        this.transform.is(TRANSFORM_TYPE.TRANSPORT) ) {
          if (!!this.transform.in_conversion && !this.transform.is(TRANSFORM_TYPE.TRANSPORT) ) {
           const transform_unit = this.units.find(x => x.id === this.transform.input_unit);
           if (transform_unit) {
            const isTransformUnitPresentInUnitList = filteredUnits.find(x => x.id === transform_unit.id);
            if (! isTransformUnitPresentInUnitList) {
              filteredUnits.push(transform_unit);
            }
           }
        }
    } else {
      return this.units;
    }
    return filteredUnits;
  }
  isTransformTypeOfTransport() {
   return this.transform && this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT;
  }
  isTransformTypeOfStorage() {
    return this.transform &&  this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE;
  }
  isInOutDisplayed () {
    return this.transform &&   this.transform.input_unit && this.transform.output_unit && this.transform.output_quantity;
  }
  updateInputQuantity(amount: number, index: number) {
    amount = this.step.orders[index].transformFromInView(amount);
    this.step.orders[index].in_order_passport.quantity = amount;
    this.dirty = true;
    const prefill = this.prefillService.prefillUpdateIfOrderChanged(this.orders[index], true);
    if (prefill[this.orders[index].id]) {
      const newOut = prefill[this.orders[index].id].out ? prefill[this.orders[index].id].out.after : null;
      this.step.orders.forEach(order => {
        order.out_order_passport.quantity = newOut;
        prefill[order.id] = {};
      });
    }
    this.ecosystemCompilerSharedActionsService.prefillSetDirty(prefill);
    this.onChange$.next();
  }

  updateOutputQuantity(amount: number) {
    amount = this.orders[0].transformFromOutView(amount);
    this.orders[0].out_order_passport.quantity = amount;
    const prefill = this.prefillService.prefillUpdateIfOrderChanged(this.orders[0], false);
    if (prefill[this.orders[0].id]) {
       prefill[this.orders[0].id] = {};
    }
    this.ecosystemCompilerSharedActionsService.prefillSetDirty(prefill);
    this.dirty = true;
    this.onChange$.next();
  }

  expandInputsTextArea(){   
    // const inputsTextAreas: ElementRef [] = this.inputDescriptionTextareas.toArray();
    this.inputDescriptionTextareas.map((input) => setTimeout(() => {
      setTextareaHeightToFitContent([input]);
  }));
  }

  expandInputTextArea(index: number){
    const inputsTextareas = this.inputDescriptionTextareas.toArray();
    setTimeout(()=> setTextareaHeightToFitContent([
      inputsTextareas[index]
    ]));
  }

  expandSectionArea() {
    this.expandInputsTextArea();
    this.expandOutputTextArea();
    this.expandStepTextArea();
    }

  expandOutputTextArea(){
    setTimeout(() => setTextareaHeightToFitContent([
      this.outputDescriptionTextarea]));
  }
  expandSummaryTextAreas() {
    setTimeout(() => setTextareaHeightToFitContent([
      this.transformDescriptionTextarea]));
    }

  expandStepTextArea() {
    setTimeout(() => setTextareaHeightToFitContent([
      this.stepDescriptionTextarea]));
    }

  getInputUnit(index: number) {
    if (this.transform.input_unit) {
      return this.transform.input_unit;
    } else if (this.orders[index]._isWithoutUnit(this.transform.transformation_type)) {
      return this.orders[index].unitInView;
    } else {
      return PASSPORT_UNITS_TYPE.KG;
    }
  }
  getOutputUnit() {
    if (this.transform.output_unit) {
      return this.transform.output_unit;
    } else if (this.orders[0]._isWithoutUnit(this.transform.transformation_type)) {
      return this.orders[0].unitOutView;
    }  else {
      return PASSPORT_UNITS_TYPE.KG;
    }
  }

  // replace 1 and 1000 with checking of the previous child unit( if is KG or ton(s))?
 
  getInputConversion(index) {
    return this.orders[index].getConversionForInput();
  }

  getOutputConversion() {
    if (this.transform.out_conversion) {
      return this.transform.out_conversion;
    } else if (this.orders[0]._isWithoutUnit(this.transform.transformation_type)) {
      return this.orders[0].conversionOutDefaultView;
    } else {
      return PASSPORT_UNITS_TYPE.KG;
    }
  }

  sameUnitButDifferentConversion(index) {
    return this.ecosystemCompilerSharedActionsService.sameUnitButDifferentConversion(
      this.orders[index], this.transform, null);
  }

  isInputConversionDisabledByDefault(index) {
    return this.sameUnitButDifferentConversion(index) || this.orders[index].in_order_passport.isWeightExist();
  }

  updateInputWeight(orderIndex: number, data: {value: number, isActive: boolean}) {
    return this.updateWeight(this.orders[orderIndex].in_order_passport, data);
  }

  updateWeight(order: OrderPassport, data: {value: number, isActive: boolean}) {
    order.setOrResetWeight(data.value, data.isActive);
    if (!data.isActive) {
      this.dirty = true;
      this.onChange$.next();
    }
  }

  getPreviousStepText(value: number) {
      if (this.step.getTypeName(value) === 'TRANSFORM') {
        return this._translate.instant('TRANSFORMATION');
      } else {
        return this._translate.instant(this.step.getTypeName(value));
      }
  }
  getUnitText(unit: number) {
      return this.transform.getUnitText(unit);
  }
  getPreviousStepContainerTitle() {
      if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
        return this._translate.instant('Deposit to be stored');
      } else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
        return this._translate.instant('Deposits to be transported');
      } else {
        return this._translate.instant('Input');
      }
  }
  getPreviousStepInputOrMaterialTitle() {
      if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
        return this._translate.instant('Material to store');
      } else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
        return this._translate.instant('Material to be transported');
      } else {
        return this._translate.instant('Input material');
      }
  }
  getPreviousStepQuantityText() {
      if (this.isTransformTypeOfTransport() || this.isTransformTypeOfStorage()) {
        return this._translate.instant('Quantity');
      } else {
        return this._translate.instant('Input quantity');
      }
  }
  getPreviousStepDateText() {
      if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
        return this._translate.instant('Start date of storage');
      } else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
        return this._translate.instant('Pickup date');
      } else {
        return this._translate.instant('Input date');
      }
  }
  getStorageOrDeliveryText() {
      if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
        return this._translate.instant('End date of storage');
      } else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
        return this._translate.instant('Delivery date');
      }
  }
  checkIsNotTransportOrStorageTransformation() {
    if (this.transform && this.transform.transformation_type_data) {
      if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.STORAGE) {
        return false;
      } else if (this.transform.transformation_type_data.value === TRANSFORM_TYPE.TRANSPORT) {
        return false;
      }
    }
    return true;
  }
  onStepDescriptionChange(description: string){
    this.step.description = description;
    this.saveStep();
  }
  onOutputDescriptionChange(description: string){
    this.orders[0].out_order_passport.description = description;
    this.saveStep();    
  }
  onInputDescriptionChange(description: string, index: number){
    this.orders[index].in_order_passport.description = description;
    this.saveStep(); 
  }
  isWithoutConversionFactor(index: number){
    if(this.getInputConversion(index) === null){
      return true;
    }
  }

  onPickupPlaceChange(place: any, index: number) {
    this.orders[index].pickup_address = place.description;
    this.saveStep(true);
  }

  onDeliveryPlaceChange(place: any) {
    
    for(let i = 0; i < this.orders.length; i++) {
      this.orders[i].delivery_address = place.description;
    }
    this.saveStep(true);
  }
}
