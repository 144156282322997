import {ActiveRecord} from './active-record.class';
import {ICrud} from '../interfaces';
import {IEula} from '../interfaces/eula.interface';

export class Eula extends ActiveRecord implements IEula {
  id: number;
  name: string;
  files: {language: string, filepath: string}[];
  created_at: string;

  protected service: ICrud;
  protected provider: any;

  hasFiles(): boolean {
    return !!this.files.length;
  }

  fields() {
    return ['id', 'name', 'files', 'created_at'];
  }
  getPdfUrl(language: string): string {
    const file = this.files.find(lang => lang.language === language);
    if (file) {
      return file.filepath + '#toolbar=0&view=FitH';
    }
  }
}
