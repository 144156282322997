
    <dialog-close-button
      (click)="mdDialogRef.close()">
    </dialog-close-button>

    <transform-details
      [id]="mdDialogData.id"
      [readOnly]="mdDialogData.readOnly"
      [showValidationButtons]="mdDialogData.showValidationButtons"
      (action)="mdDialogRef.close()"
    ></transform-details>
  