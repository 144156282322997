import {IImportPreferences} from '../../../../../import-spreadsheet/values/interfaces/import-preferences.interface';
import {TABLE_TYPES} from '../../../../../import-spreadsheet/values/import-tables.const';

export const COLUMNS_MAPPING = {
  SITE_NAME: 1,
  ADDRESS: 2,
  DESCRIPTION: 3,
  TAB_IMPORT: 4,
  FIRST_ROW: 5,
  LAST_ROW: 6
};

export const TABLE_SYNONYM_AND_COLUMNS: IImportPreferences = {
  columnTable: TABLE_TYPES.DEPOSIT_LOCATION_MAPPING,
  columnMapping: COLUMNS_MAPPING,
  synonymTable: []
};
