import { ActiveRecord } from '../../../shared/models/active-record.class';
import { NextUsageService } from '../services/next-usage.service';
import { Type } from '@angular/core';
import { NEXT_USAGE } from '../values/next-usage-values.const';
import {TranslateService} from "@ngx-translate/core";
import {ServiceLocator} from "../../../shared/services/service-locator";
import { AffiliateBranch } from './affiliateBranch.class';

export class NextUsage extends ActiveRecord {
  protected provider: Type<NextUsageService> = NextUsageService;

  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  id: number;
  passport_id: number;
  type: number;
  percentage_product: number;
  affiliate_branch: AffiliateBranch[] = [];

  constructor(data: any){
    super(data);
    data = data||{};
    this.id = data.id;
    this.passport_id = data.passport_id;
    this.type = data.type;
    this.percentage_product = data.percentage_product;
    this.affiliate_branch = data.affiliate_branch ? data.affiliate_branch.map((e)=>new AffiliateBranch(e)) : [];
  }

  fields() {
    return ['id',  'passport_id', 'type', 'percentage_product','affiliate_branch'];
  }
  
  getTypeText() {
    let type = NEXT_USAGE.find((type) => type.value === this.type);
    return type ?  this.translateService.instant(type.viewValue) : '';
  }
}
