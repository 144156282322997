<confirm-with-header *ngIf="offer"
                     class="order-transaction-confirmation
         order-transaction-confirmation--textarea-no-horizontal-resize
         order-transaction-confirmation--input-disabled-colored
         order-transaction-confirmation--input-placeholder-disabled-colored
         order-transaction-confirmation--input-placeholder-text
         order-transaction-confirmation--input-placeholder-float-no-overflow
         offer-transaction-confirm"
                     [ngClass]="{'order-transaction-confirmation--disabled': disabled}"
                     [title]="title"
                     [confirmButtonText]="'Confirm data'| translate"
                     (onConfirm)="this.confirmOrder()"
                     (onCancel)="this.onCancel.emit()"
>
  <article [formGroup]="form" class="offer-transaction-confirm__main">
    <header *ngIf="!disabled; else entityInfo" class="offer-transaction-confirm__header">
      <h3 class="order-transaction-confirmation__foreword-title
        offer-transaction-confirm__header-title">
        {{'Has your need been met?' | translate}}
      </h3>
      <h4 class="order-transaction-confirmation__foreword-main
        offer-transaction-confirm__header-title-sub">
          <span>{{'Please confirm or update the following values'| translate}}</span>
          <span *ngIf="!showESMText">
            <span>-</span>
            <span>{{' ' + ('they will be sent to the ecosystem manager' | translate)}}</span>
          </span>
      </h4>
    </header>
    <ng-template #entityInfo>
      <header class="offer-transaction-confirm__entity">
        <md-input-container
          floatPlaceholder="always"
          class="offer-transaction-confirm__entity-name"
        >
          <input
            mdInput
            [disabled]="true"
            [value]="offer.need.name"
            [placeholder]="'Need name' | translate"
          >
        </md-input-container>
        <div></div>
        <md-input-container
          floatPlaceholder="always"
          class="offer-transaction-confirm__entity-passport-name"
        >
          <input
            mdInput
            [disabled]="true"
            [value]="offer.need.passport.name"
            [placeholder]="'Passport name' | translate"
          >
        </md-input-container>
      </header>
    </ng-template>
    <section class="offer-transaction-confirm__confirmation">
        <order-transaction-confirm-result-field
          [formControlName]="'quantity'"
          [type]="RESULT_FIELD_TYPE.CONVERSION"
          [placeholderExpected]="'Quantity (expected)' | translate"
          [placeholderConfirmed]="'Quantity (confirmed)' | translate"
          [defaultValue]="offer.quantity"
          [conversionFactor]="offer.need.passport.conversion"
          [conversionUnit]="offer.need.passport.unit"
          [(ngModel)]="offer.transaction_quantity"
        >
        </order-transaction-confirm-result-field>
        <order-transaction-confirm-result-field
          [formControlName]="'date'"
          [placeholderExpected]="'Delivery date (expected)' | translate"
          [placeholderConfirmed]="'Delivery date (confirmed)' | translate"
          [type]="RESULT_FIELD_TYPE.DATE"
          [defaultValue]="offer.delivery_date"
        >
        </order-transaction-confirm-result-field>
        <order-transaction-confirm-result-field

              [formControlName]="'price'"
              [placeholderExpected]="'Price (expected)' | translate"
              [placeholderConfirmed]="'Price (confirmed)' | translate"
              [type]="RESULT_FIELD_TYPE.NUMBER"
              [defaultValue]="offer.price"
              [(ngModel)]="offer.transaction_price">

              <span slot="endExpected"><ng-container *ngTemplateOutlet="priceUnit"></ng-container></span>
              <span slot="endConfirmed"><ng-container *ngTemplateOutlet="priceUnit"></ng-container></span>
              <ng-template #priceUnit>
                <span class="order-transaction-confirmation__form-list-row-item-input-unit">€</span>
                <label
                  class="form-group__label order-transaction-confirmation__form-list-tooltip"
                  md-tooltip="{{ 'DO_ORDER_PRICE_TOOLTIP' | translate }}">
                  <i class="form-group__label--question">?</i>
                </label>
              </ng-template>
        </order-transaction-confirm-result-field>
    </section>
    <section class="offer-transaction-confirm__additionals">
        <md-input-container
          class="offer-transaction-confirm__description" floatPlaceholder="always">
          <textarea
            mdInput
            [(ngModel)]="offer.transaction_description"
            [formControlName]="'description'"
            [placeholder]="'Description' | translate"></textarea>
        </md-input-container>
        <label class="order-transaction-confirmation__document-label
          offer-transaction-confirm__documents-label"
               [ngClass]="{'primary-color': !disabled}">
          {{'Traceability documents' | translate}}
        </label>
        <documents-uploader
          class="passport__uploader deposit-order-transaction-confirmation__additional-documents
           offer-transaction-confirm__documents"
          [ngClass]="{'offer-transaction-confirm__documents--disabled': disabled}"
          [files]="allFiles"
          [autoupload]="false"
          [uploader]="requestFileSenderService.fileUploader"
          [onUploadAll]="requestFileSenderService.onUploadAll"
          [isDisabled]="disabled"
          [hideUploadButton]="disabled"
          [service]="ecosystemFileService"
          [uploadUrl]="ecosystemService.getFileUploadUrl(offer.ecosystem.id)"
          [beforeRemoveAction]="deleteOnlyLinkIfUploaded()"
          (onItemUploaded)="requestFileSenderService.onCompleteItem($event)"
          (onAllItemsUploaded)="createFileJunctions($event)"
        >
        </documents-uploader>
    </section>
  </article>

</confirm-with-header>
