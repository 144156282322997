import { UAParser } from 'ua-parser-js';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../services/navigation.service';
var IeNotificationComponent = /** @class */ (function () {
    function IeNotificationComponent(translate, _navigationService) {
        this.translate = translate;
        this._navigationService = _navigationService;
        this.show = false;
        this.userBrowser = "";
    }
    IeNotificationComponent.prototype.ngOnInit = function () {
        this.getUserBrowser();
        this.checkBrowser();
        this.checkRoleChange();
    };
    Object.defineProperty(IeNotificationComponent.prototype, "stateName", {
        get: function () {
            return this.show ? 'show' : 'hide';
        },
        enumerable: true,
        configurable: true
    });
    IeNotificationComponent.prototype.getUserBrowser = function () {
        var parser = new UAParser();
        var result = parser.getResult();
        this.userBrowser = result.browser.name;
    };
    IeNotificationComponent.prototype.checkRoleChange = function () {
        var _this = this;
        if (this.userBrowser === "IE") {
            this._navigationService.changeModule.subscribe(function () {
                _this.show = true;
            });
        }
    };
    IeNotificationComponent.prototype.checkBrowser = function () {
        if (this.userBrowser === "IE") {
            this.show = true;
        }
    };
    IeNotificationComponent.prototype.closeNotificationBar = function () {
        this.show = false;
    };
    return IeNotificationComponent;
}());
export { IeNotificationComponent };
