import * as tslib_1 from "tslib";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MdDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from './navigation.service';
import { ERoleIds } from '../values/role-ids.enum';
import { NotificationSuccessComponent } from '../notification-dialogs/notification-success/notification-success.component';
import { DIALOG_THEME } from '../helpers/dialog-themes.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./navigation.service";
var ConfirmOrderDialogService = /** @class */ (function () {
    function ConfirmOrderDialogService(mdDialog, translateService, navigation) {
        this.mdDialog = mdDialog;
        this.translateService = translateService;
        this.navigation = navigation;
        this.isConfirmDialogOpen = false;
    }
    Object.defineProperty(ConfirmOrderDialogService.prototype, "defaultOptions", {
        get: function () {
            return {
                text: this.translate('Do you confirm the values entered?') + "\n" + this.translate('They will be sent to the ecosystem manager.'),
                confirmText: 'Yes, send',
                cancelText: 'No, return to values',
                cssClass: 'order-transaction-confirmation-dialog__confirmation',
                showCross: true,
                imgUrl: 'assets/png/warning_orange.png',
            };
        },
        enumerable: true,
        configurable: true
    });
    ConfirmOrderDialogService.prototype.translate = function (text) {
        return this.translateService.instant(text);
    };
    ConfirmOrderDialogService.prototype.open = function (callback, mdDialogRef, data) {
        var _this = this;
        this.isConfirmDialogOpen = true;
        var dialog = this.mdDialog.open(ConfirmDialogComponent, { data: tslib_1.__assign({}, this.defaultOptions, (data || {})) });
        dialog.afterClosed().subscribe(function (result) {
            if (result) {
                callback.subscribe(function (orderConfirmed) {
                    _this.showContentAfterCloseAnimation();
                    return mdDialogRef && mdDialogRef.close(orderConfirmed);
                }, function () {
                    _this.isConfirmDialogOpen = false;
                });
            }
            else {
                _this.isConfirmDialogOpen = false;
            }
        }, function () {
            _this.isConfirmDialogOpen = false;
        });
        return dialog;
    };
    ConfirmOrderDialogService.prototype.showContentAfterCloseAnimation = function () {
        var _this = this;
        var dialogAnimationTime = 410;
        setTimeout(function () { return _this.isConfirmDialogOpen = false; }, dialogAnimationTime);
    };
    ConfirmOrderDialogService.prototype.getDefaultOverrideForESM = function (overrideData) {
        var _this = this;
        if (this.navigation.roleId === ERoleIds.ECOSYSTEM_MANAGER) {
            var translate = function (translation) { return _this.translateService.instant(translation); };
            var text = translate('Do you confirm the values entered?') + "\n" + translate('You will not be able to change them afterwards.');
            overrideData.text = text;
        }
        return overrideData;
    };
    ConfirmOrderDialogService.prototype.afterConfirmationShowSuccessMessage = function (message) {
        if (message === void 0) { message = 'Thanks for confirmation'; }
        this.mdDialog.open(NotificationSuccessComponent, tslib_1.__assign({}, DIALOG_THEME.PROBLEM_REPORT, { data: { message: message } }));
    };
    ConfirmOrderDialogService.ngInjectableDef = i0.defineInjectable({ factory: function ConfirmOrderDialogService_Factory() { return new ConfirmOrderDialogService(i0.inject(i1.MdDialog), i0.inject(i2.TranslateService), i0.inject(i3.NavigationService)); }, token: ConfirmOrderDialogService, providedIn: "root" });
    return ConfirmOrderDialogService;
}());
export { ConfirmOrderDialogService };
