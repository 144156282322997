<div class="ecosystem-edit__progress-bar" *ngIf="relevancySearchInProgress">
  <md-progress-bar mode="indeterminate"></md-progress-bar>
</div>

<section class="ecosystem-edit__search-category-role">
  <div class="ecosystem-edit__search-wrapper">
    <md-radio-group class="flex-column" [disabled]="relevancySearchInProgress" (change)="resetFilters()"
    [formControl]="categoryControl">
      <md-radio-button
        class="esm-catalog__group--buttons"
        [value]="category"
        *ngFor="let category of PLUMB_STEP_CATEGORY_VALUES">
        {{ category.view | translate}}
      </md-radio-button>
    </md-radio-group>
  </div>
  <!-- The button is commented out due to the fact that Relevancy algorithm is not working as intended. -->
  <!--<div class="ecosystem-step-search__button-groups">
    <button class="ecosystem-step-search__button"
            *ngIf="(categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS
                  || categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS) && !_extendedCompilerView"
            md-tooltip="{{ 'Use Upcyclea algorithms' | translate }}"
            (click)="searchByRelevancy(filteredResults.length === 0)">
      <img class="ecosystem-step-search__image"
           src="../assets/png/transform/intelligence-artificielle-button.png"/>
    </button>
    <button class="ecosystem-step-search__button"
            *ngIf="(categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS
                || categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS) && _extendedCompilerView"
            md-tooltip="{{ 'View all' | translate }}"
            (click)="backToMainView()">
      <img class="ecosystem-step-search__image"
           src="../assets/png/transform/arrow-reset-orange.png"/>
    </button>
  </div> -->
</section>

<section *ngIf="(categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS
             || categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS)
             && _extendedCompilerView
             && !relevancySearchInProgress"
         class="ecosystem-edit__type-field">
  {{ 'Found' | translate }}: {{ filteredResults.length }}
  {{ 'Total' | translate }}: {{ totalRecords }}
</section>

<section *ngIf="(categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS
             || categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS) && _extendedCompilerView"
         class="ecosystem-edit__type-field">
  <md-radio-group
    class="esm-catalog__group"
    [(ngModel)]="distanceSource"
    [formControl]="distanceControl"
  >
    <md-radio-button
      class="esm-catalog__group--buttons"
      [value]="1"
    >
      {{ 'Quickest path' | translate }}
    </md-radio-button>
    <md-radio-button
      class="esm-catalog__group--buttons"
      [value]="0"
    >
      {{ 'Flying distance' | translate }}
    </md-radio-button>
  </md-radio-group>
</section>

<section *ngIf="categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS && showIndependentTransformationFilter" class="ecosystem-edit__type-field indep-section"
>
  <span class="mat-select-placeholder premium-text" > {{ 'See public transformations' | translate}} </span>
  <br>
  <div>
    <mat-slide-toggle class="esm-catalog__group--checkbox checkbox-group"
    [formControl]="showIndependentTransformControl">
  </mat-slide-toggle>
  <img src="assets/png/cloud_grey.png"  alt="">
</div>
</section>

<!-- <section *ngIf="categoryControl.value === PLUMB_STEP_CATEGORIES.VIRGIN_PRODUCTS" class="ecosystem-edit__search-materials">
  <md-input-container floatPlaceholder="always">
    <md-chip-list>
      <md-basic-chip *ngFor="let tag of tags" (focus)="false">
        <button (click)="removeTag(tag, $event)" class="delete"></button>
        {{tag.name}}
      </md-basic-chip>
    </md-chip-list>
    <input
      #materiallist
      mdInput
      autocomplete="off"
      placeholder="{{ 'Materials nomenclature' | translate }}"
      (focus)="openNomenclatureDialog(materiallist)"
    >
  </md-input-container>
</section>
#TODO fix bugs in virgin products in future releases -->

<section
  *ngIf="(categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS || categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS); else other"
   class="ecosystem-edit__search-field"
>
  <span class="ecosystem-edit__icon-container">
    <i class="icon icon--search"></i>
  </span>
  <md-input-container floatPlaceholder="always">
    <input test-id="compiler-search"
           type="text"
           mdInput
           autocomplete="off"
           [formControl]="searchControl"
           value="Transport"
           placeholder="{{ 'Search' | translate }}"
           [(ngModel)]="searchquery"
           [attr.disabled]="relevancySearchInProgress ? '' : null"
    >
    <!-- <input *ngIf="isRelevancySearch" test-id="compiler-search"
           type="text"
           mdInput
           value="Transport"
           placeholder="{{ 'Search' | translate }}"
           [(ngModel)]="searchquery"
    > -->
    <!--TODO temporarily hidden, uncomment when filters for relevant results are ready-->
    <!--<span class="ecosystem-edit__result-count">{{results.length}}</span>-->
  </md-input-container>
</section>
<ng-template #other>
  <section class="ecosystem-edit__search-field">
   <span class="ecosystem-edit__icon-container">
    <i class="icon icon--search"></i>
  </span>
  <md-input-container floatPlaceholder="always">
    <input test-id="compiler-search"
           type="text"
           mdInput
           autocomplete="off"
           [formControl]="searchControl"
           value="Transport"
           placeholder="{{ 'Search' | translate }}"
    >
  </md-input-container>
  </section>
</ng-template>

<section *ngIf="categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS" class="ecosystem-edit__type-field">
  <md-select
    placeholder="{{'Filter by type' | translate}}"
    [formControl]="typeControl"
    [disabled]="relevancySearchInProgress"
  >
    <md-option [value]="0">{{ 'Any' | translate }}</md-option>
    <md-option
      *ngFor="let TYPE of TRANSFORM_TYPES"
      [value]="TYPE.value"
    >
      {{ TYPE.view | capitalize | translate}}
    </md-option>
  </md-select>
</section>

<section *ngIf="categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS" class="ecosystem-edit__search-category">
  <md-select [disabled]="relevancySearchInProgress" placeholder="{{ 'Filter by site' | translate }}" [formControl]="siteControl">
    <md-option
      [value]="null">{{ 'Any' | translate }}
    </md-option>
    <md-option
      *ngFor="let site of sites"
      [value]="site"
    >
      {{ site.name }}
    </md-option>
  </md-select>
</section>

<section
  *ngIf="(categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS || categoryControl.value === PLUMB_STEP_CATEGORIES.TRANSFORMS)"
  class="ecosystem-edit__search-materials"
>
  <md-input-container floatPlaceholder="always">
    <md-chip-list>
      <md-basic-chip *ngFor="let tag of tags" (focus)="false">
        <button [disabled]="relevancySearchInProgress" (click)="removeTag(tag, $event)" class="delete"></button>
        {{tag.name}}
      </md-basic-chip>
    </md-chip-list>
    <input
        #materiallist
        mdInput
        autocomplete="off"
        placeholder="{{ 'Filter by materials' | translate }}"
        (focus)="openNomenclatureDialog(materiallist)"
        (click)="preventDoubleDialog($event)"
        [disabled]="relevancySearchInProgress"
    >
  </md-input-container>
</section>

<section *ngIf="categoryControl.value === PLUMB_STEP_CATEGORIES.DEPOSITS" class="ecosystem-edit__search-available">
    <md-checkbox
      [(ngModel)]="availableOnly"
      [disabled]="relevancySearchInProgress"
      (change)="_extendedCompilerView ? searchByRelevancy() : updateSearchResults()">
      {{'Available only' | translate}}
    </md-checkbox>
</section>


<section class="ecosystem-edit__search-results" *ngIf="!isLoading; else loading">

  <div *ngIf="categoryControl.value === PLUMB_STEP_CATEGORIES.STAKEHOLDERS" class="ecosystem-edit__add-new">
    <!--<a routerLink="/ecosystem/stakeholders/new">+ {{ 'Add new stakeholder' | translate }}</a>-->
    <button (click)="onAddNewStakeholder()">+{{'Create stakeholder' | translate}}</button>
  </div>

  <div *ngIf="(filteredResults.length>0 && _extendedCompilerView && !relevancySearchInProgress); else norelevantresults" [perfectScrollbar]>
    <virtual-scroll
      #scroll
      [items]="filteredResults"
      [ngClass]="{'extendedcompiler': _extendedCompilerView}"
    >
    <div *ngFor="let relevantResult of scroll.viewPortItems">
      <div *ngIf="relevantResult.plumb_type == ECOSYSTEM_STEP_TYPE.TRANSFORM && _extendedCompilerView">
        <ul class="ecosystem-edit__tpl-list">
          <li class="ecosystem-edit__tpl-item" [attr.data-primary-key]="relevantResult.id">
            <div
              class="ecosystem-edit__tpl-container"
              draggable
              [dragData]="relevantResult"
            >
              <div class="ecosystem-edit__tpl-info">
                <div class="ecosystem-edit__tpl-wrapper">
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                      <span class="ecosystem-edit__tpl-name">{{ relevantResult.transformation_name || "(Unnamed)" }}</span>
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-name">{{ relevantResult.transform?.tsm_name }}</span>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                      <span class="ecosystem-edit__tpl-name">
                        <span *ngIf="isIndependent(relevantResult.transformation_independent)">
                          <i class="icon icon--cloud-arrow-down"></i>
                        </span>
                        {{ relevantResult.tsm_org }}
                      </span>
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-address">{{ relevantResult.name }}</span>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                     <span class="ecosystem-edit__tpl-name">
                        {{ relevantResult.distance_str }}
                      </span>
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-address">
                        {{ 'Use count' | translate }}
                        {{ ': ' + relevantResult.ecosystem_count }}
                      </span>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                     <span class="ecosystem-edit__tpl-name">
                        {{ 'Input' | translate }} :
                        <span *ngIf="relevantResult.income_materials">
                          <span *ngFor="let material of relevantResult.income_materials.split(','); let last = last;">
                            {{material | translate}}<span *ngIf='!last'>,</span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-address">
                        {{ 'Keywords' | translate }}
                        {{ ': ' + relevantResult.income_tags }}
                      </span>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                     <span class="ecosystem-edit__tpl-name">
                        {{ 'Output' | translate }} :
                        <span *ngIf="relevantResult.outcome_materials">
                          <span *ngFor="let material of relevantResult.outcome_materials.split(','); let last = last;">
                            {{material | translate}}<span *ngIf='!last'>,</span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-address">
                        {{ 'Keywords' | translate }}
                        {{ ': ' + relevantResult.outcome_tags }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ecosystem-edit__tpl-controls">
                <button md-icon-button [mdMenuTriggerFor]="menu">
                  <md-icon>more_vert</md-icon>
                </button>
              </div>
            </div>
          </li>
        </ul>

        <md-menu #menu="mdMenu" x-position="before">
          <button md-menu-item (click)="viewDetails(relevantResult)">
            {{ 'See details' | translate }}
          </button>
        </md-menu>
      </div>

      <div *ngIf="relevantResult.plumb_type == ECOSYSTEM_STEP_TYPE.DEPOSIT && _extendedCompilerView">
        <ul class="ecosystem-edit__tpl-list">
          <li class="ecosystem-edit__tpl-item"  [attr.data-primary-key]="relevantResult.id">
            <div
              class="ecosystem-edit__tpl-container"
              draggable
              [dragData]="relevantResult"
            >
              <div class="ecosystem-edit__tpl-info">
                <div class="ecosystem-edit__tpl-wrapper">
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                     <span class="ecosystem-edit__tpl-name">{{ relevantResult.deposit_name || "(Unnamed)" }}</span>
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-address">{{ relevantResult.name }}</span>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                      <span class="ecosystem-edit__tpl-quantity">
                        <i class="icon"
                           [ngClass]="relevantResult.passport_unit_css">
                        </i> {{relevantResult.deposit_quantity
                                ? relevantResult.deposit_quantity
                                : 0}} {{relevantResult.passport_unit_text}}
                      </span>
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-address">
                        {{ getAvailable(relevantResult.deposit_availability_date, relevantResult.deposit_availability_expiration) }}
                      </span>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                      <span class="ecosystem-edit__tpl-name">
                        {{ relevantResult.distance_str }}
                      </span>
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-address">
                        {{ 'Use count' | translate }}
                        {{ ': ' + relevantResult.ecosystem_count }}
                      </span>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                     <span class="ecosystem-edit__tpl-name">{{ relevantResult.deposit_passport_name }}</span>
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-address">
                        {{ 'Materials' | translate }} :
                        <span *ngIf="relevantResult.nomenclature_names">
                          <span *ngFor="let material of relevantResult.nomenclature_names.split(','); let last = last;">
                            {{material | translate}}<span *ngIf='!last'>,</span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-row">
                    <div class="ecosystem-edit__tpl-column">
                      <!--<span class="ecosystem-edit__tpl-name">{{ 'Materials: ' + relevantResult.nomenclature_names }}</span>-->
                    </div>
                    <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-address">
                        {{ relevantResult.keywords ? 'Keywords' : '' | translate }}
                        {{ relevantResult.keywords ? ': ' + relevantResult.keywords : '' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ecosystem-edit__tpl-controls">
                <button md-icon-button [mdMenuTriggerFor]="menu">
                  <md-icon>more_vert</md-icon>
                </button>
              </div>
            </div>
          </li>
        </ul>

        <md-menu #menu="mdMenu" x-position="before">
          <button md-menu-item (click)="viewDetails(relevantResult)">
            {{ 'See details' | translate }}
          </button>
        </md-menu>
      </div>
    </div>
  </virtual-scroll>
  </div>

  <div *ngIf="(results.length>0 && !_extendedCompilerView); else noresults" [perfectScrollbar]>
    <virtual-scroll
      #scroll
      [items]="results"
      (vsEnd)="loadNextPage($event)"
      [ngClass]="{'stakeholder': categoryControl.value === PLUMB_STEP_CATEGORIES.STAKEHOLDERS}"
    >
    <div *ngFor="let result of scroll.viewPortItems">

          <div *ngIf="result.plumb_type == ECOSYSTEM_STEP_TYPE.TRANSFORM && !_extendedCompilerView">
            <!-- Many sites -->
            <ul class="ecosystem-edit__tpl-list" *ngIf="result.hasMultipleSites">
              <li class="ecosystem-edit__tpl-item" [attr.data-primary-key]="result.id">
                <div
                  class="ecosystem-edit__tpl-container"
                  draggable
                  [dragData]="result"
                >
                  <div class="ecosystem-edit__tpl-info">
                    <div class="ecosystem-edit__tpl-wrapper">
                      <div class="ecosystem-edit__tpl-row">
                        <div class="ecosystem-edit__tpl-column">
                          <span class="ecosystem-edit__tpl-name">{{getStepName(result)}}</span>
                        </div>
                        <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-name">
                        <span>
                          <i *ngIf="isIndependent(result.independent)" class="icon icon--cloud-arrow-down"></i>
                        </span>
                        {{ isIndependent(result.independent) ? result.tsm_org : result.tsm_name }}
                      </span>
                        </div>
                      </div>
                      <div class="ecosystem-edit__tpl-row">
                        <div class="ecosystem-edit__tpl-column">
                          <span class="ecosystem-edit__tpl-name">{{ isIndependent(result.independent) ? "" : result.tsm_org }}</span>
                        </div>
                        <div class="ecosystem-edit__tpl-column align-right">
                          <span class="ecosystem-edit__tpl-address">  {{ result.site.name }}</span>
                        </div>
                      </div>
                      <div class="ecosystem-edit__tpl-row">
                        <div class="ecosystem-edit__tpl-column">
                          <span class="ecosystem-edit__tpl-name">

                            {{ 'Input' | translate }} :
                            <span *ngIf="result.income_materials">

                                {{ result.income_materials }}

                            </span>

                          </span>
                        </div>
                        <div class="ecosystem-edit__tpl-column align-right">
                          <span class="ecosystem-edit__tpl-address">
                            {{ 'Keywords' | translate }}
                        {{ ': ' + result.income_tags }}
                          </span>
                        </div>
                      </div>
                      <div class="ecosystem-edit__tpl-row">
                        <div class="ecosystem-edit__tpl-column">
                          <span class="ecosystem-edit__tpl-name">

                            {{ 'Output' | translate }} :
                            <span *ngIf="result.outcome_materials">
                             {{ result.outcome_materials }}
                              </span>


                          </span>
                        </div>
                        <div class="ecosystem-edit__tpl-column align-right">
                          <span class="ecosystem-edit__tpl-address">
                            {{ 'Keywords' | translate }}
                        {{ ': ' + result.outcome_tags }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-controls">
                    <button md-icon-button [mdMenuTriggerFor]="menu">
                      <md-icon>more_vert</md-icon>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <!-- One site -->
            <ul class="ecosystem-edit__tpl-list" *ngIf="!result.hasMultipleSites">
              <li class="ecosystem-edit__tpl-item"  [attr.data-primary-key]="result.id">
                <div
                  class="ecosystem-edit__tpl-container"
                  draggable
                  [dragData]="result"
                >
                  <div class="ecosystem-edit__tpl-info">
                    <div class="ecosystem-edit__tpl-wrapper">
                      <div class="ecosystem-edit__tpl-row">
                        <div class="ecosystem-edit__tpl-column">
                     <span class="ecosystem-edit__tpl-name">
                       {{getStepName(result)}}
                     </span>
                        </div>
                      </div>
                      <div class="ecosystem-edit__tpl-row">
                        <div class="ecosystem-edit__tpl-column">
                          <span class="ecosystem-edit__tpl-address">{{result.sites[0]?.address}}</span>
                        </div>
                        <div class="ecosystem-edit__tpl-column align-right">
                      <span class="ecosystem-edit__tpl-phone"><!--TODO: phone-->
                        <span *ngIf="isIndependent(result.independent)">
                          <i class="icon icon--cloud-arrow-down"></i>
                        </span>
                        {{result.creator}}
                      </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ecosystem-edit__tpl-controls">
                    <button md-icon-button [mdMenuTriggerFor]="menu">
                      <md-icon>more_vert</md-icon>
                    </button>
                  </div>
                </div>
              </li>
            </ul>

            <md-menu #menu="mdMenu" x-position="before">
              <button md-menu-item (click)="viewDetails(result)">
                {{ 'See details' | translate }}
              </button>
            </md-menu>

          </div>
          <div
            class="ecosystem-edit__tpl-container ecosystem-edit__tpl-list-item-deposit"
            draggable
            [dragData]="result"
            *ngIf="result.plumb_type != ECOSYSTEM_STEP_TYPE.TRANSFORM && !_extendedCompilerView"
          >
            <div class="ecosystem-edit__tpl-info" [ngSwitch]="result.plumb_type">
              <div class="ecosystem-edit__tpl-wrapper" *ngSwitchCase="ECOSYSTEM_STEP_TYPE.DEPOSIT">
                <div class="ecosystem-edit__tpl-row">
                  <div class="ecosystem-edit__tpl-column">
                    <span class="ecosystem-edit__tpl-name">{{result.name || "(Unnamed)"}}</span>
                  </div>
                  <div class="ecosystem-edit__tpl-column align-right">
                    <span class="ecosystem-edit__tpl-address">{{result.deposit_location?.name}}</span>
                  </div>
                </div>
                <div class="ecosystem-edit__tpl-row">
                  <div class="ecosystem-edit__tpl-column">
                <span class="ecosystem-edit__tpl-quantity">
                  <i class="icon"
                     [ngClass]="result.passport.unitIconCss">
                  </i>  {{result.quantity - result.booked >= 0 ? result.quantity - result.booked : 0}} {{result.passport.unit_text}}
                </span>
                  </div>
                  <div class="ecosystem-edit__tpl-column align-right">
                    <span class="ecosystem-edit__tpl-availability">
                      {{ getAvailable(result.availability_date, result.availability_expiration) }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="ecosystem-edit__tpl-wrapper" *ngSwitchCase="ECOSYSTEM_STEP_TYPE.STAKEHOLDER">
                <div class="ecosystem-edit__tpl-row">
                  <div class="ecosystem-edit__tpl-column">
                    <span class="ecosystem-edit__tpl-name">{{result.company_name || "(Unnamed)"}}</span>
                  </div>
                  <div class="ecosystem-edit__tpl-column align-right">
                    <span class="ecosystem-edit__tpl-name">{{result.typeView | translate}}</span>
                  </div>
                </div>
                <div class="ecosystem-edit__tpl-row">
                  <div class="ecosystem-edit__tpl-column">
                    <span class="ecosystem-edit__tpl-address">{{result.email}}</span>
                  </div>
                  <div class="ecosystem-edit__tpl-column align-right">
                    <span class="ecosystem-edit__tpl-phone">{{result.phone}} {{result.name}} {{result.surname}}</span>
                  </div>
                </div>
              </div>

              <div class="ecosystem-edit__tpl-wrapper" *ngSwitchCase="ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT">
                <div class="ecosystem-edit__tpl-row">
                  <div class="ecosystem-edit__tpl-column">
                    <span class="ecosystem-edit__tpl-name">{{result.passport.name || "(Unnamed)"}}</span>
                  </div>
                  <div class="ecosystem-edit__tpl-column align-right">
                    <span class="ecosystem-edit__tpl-address">{{result.contact_email}}</span>
                  </div>
                </div>
                <div class="ecosystem-edit__tpl-row">
                  <div class="ecosystem-edit__tpl-column">
                    <span class="ecosystem-edit__tpl-name">{{result.passport?.manufacturer}}</span>
                  </div>
                  <div class="ecosystem-edit__tpl-column align-right">
                    <span class="ecosystem-edit__tpl-phone">{{result.contact_phone}} {{result.contact_name}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="ecosystem-edit__tpl-controls">
              <button md-icon-button [mdMenuTriggerFor]="menu">
                <md-icon>more_vert</md-icon>
              </button>
              <md-menu #menu="mdMenu" x-position="before">
                <button md-menu-item (click)="viewDetails(result)">
                  {{ 'See details' | translate }}
                </button>
              </md-menu>
            </div>
          </div>
      </div>
    </virtual-scroll>
  </div>
</section>
<ng-template class="ecosystem-edit__search-results" #loading>
  <div class="ecosystem-edit__loading">{{ 'Loading' | translate }}...</div>
</ng-template>
<ng-template class="ecosystem-edit__search-results" #noresults>
  <div *ngIf="!_extendedCompilerView" class="ecosystem-edit__no-results">{{ 'No results' | translate }}</div>
</ng-template>
<ng-template class="ecosystem-edit__search-results" #norelevantresults>
  <div *ngIf="_extendedCompilerView" class="ecosystem-edit__no-results">{{ 'No results' | translate }}</div>
</ng-template>
