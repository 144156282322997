import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
var RatingFilterComponent = /** @class */ (function () {
    function RatingFilterComponent() {
        this.OBJECT = 'object';
        this.BOOLEAN = 'boolean';
        this.aliasClass = {};
        this.ratingFilterChange = new EventEmitter();
        this.isSelectAllShown = true;
        this.selectAllIndex = 0;
        this.showIconsByDefault = true;
        this.showLabelByDefault = false;
        this.placeholder = 'Search by Rating';
        this.ratingApplyRule = this.defaultRatingApplyRule;
        this.showFilterByRating = false;
        this.defaultIconAlias = { C2cCertified: 'certification' };
        this.defaultNameAlias = {};
    }
    Object.defineProperty(RatingFilterComponent.prototype, "setAliasIcon", {
        set: function (array) {
            this.aliasIcon = tslib_1.__assign({}, this.defaultIconAlias, array);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RatingFilterComponent.prototype, "setNameAlias", {
        set: function (array) {
            this.nameAlias = tslib_1.__assign({}, this.defaultNameAlias, array);
        },
        enumerable: true,
        configurable: true
    });
    RatingFilterComponent.prototype.onFocus = function (targetElement) {
        this.checkIfElementInsideComponent(targetElement);
    };
    RatingFilterComponent.prototype.onClick = function (targetElement) {
        this.checkIfElementInsideComponent(targetElement);
    };
    RatingFilterComponent.prototype.ngOnInit = function () {
        this.nameAlias = this.nameAlias || tslib_1.__assign({}, this.defaultNameAlias, this.nameAlias);
        this.aliasIcon = this.aliasIcon || tslib_1.__assign({}, this.defaultIconAlias, this.aliasIcon);
    };
    RatingFilterComponent.prototype.checkIfElementInsideComponent = function (targetElement) {
        if (this.filterRating && !this.filterRating.nativeElement.contains(targetElement)) {
            this.showFilterByRating = false;
        }
    };
    RatingFilterComponent.prototype.onClickShowRating = function () {
        var _this = this;
        this.showFilterByRating = true;
        setTimeout(function () {
            if (_this.filterRating) {
                _this.filterRating.nativeElement.focus();
            }
        });
    };
    RatingFilterComponent.prototype.onEnterShowRating = function ($event) {
        if ($event.key === 'Enter' || $event.key === ' ') {
            this.showFilterByRating = true;
            this.focusInOption();
        }
    };
    RatingFilterComponent.prototype.focusInOption = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.filterHTMLRating) {
                _this.filterHTMLRating.focus();
            }
        });
    };
    RatingFilterComponent.prototype.selectOrDeselectAllRatingFilters = function (event) {
        var _this = this;
        var check = event.checked;
        this.ratingKeys.forEach(function (key) {
            if (_this.getItemType(key) === _this.OBJECT) {
                _this.ratingFilter[key].color = check;
                _this.ratingFilter[key].black = check;
            }
            else {
                _this.ratingFilter[key] = check;
            }
        });
        this.ratingFilterChange.emit(this.ratingFilter);
    };
    Object.defineProperty(RatingFilterComponent.prototype, "isRatingFilterApplied", {
        get: function () {
            return this.ratingApplyRule(this.ratingFilter);
        },
        enumerable: true,
        configurable: true
    });
    RatingFilterComponent.prototype.defaultRatingApplyRule = function (filters) {
        var _this = this;
        return this.ratingKeys.map(function (key) {
            return _this.getItemType(key) === _this.OBJECT ?
                (_this.ratingFilter[key].color || _this.ratingFilter[key].black) : _this.ratingFilter[key];
        }).some(function (a) { return a; });
    };
    Object.defineProperty(RatingFilterComponent.prototype, "isAllFilterSelected", {
        get: function () {
            var _this = this;
            return this.ratingKeys.map(function (key) {
                return _this.getItemType(key) === _this.OBJECT
                    ? (_this.ratingFilter[key].color && _this.ratingFilter[key].black) : _this.ratingFilter[key];
            }).every(function (a) { return a; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RatingFilterComponent.prototype, "ratingKeys", {
        get: function () {
            return Object.keys(this.ratingFilter);
        },
        enumerable: true,
        configurable: true
    });
    RatingFilterComponent.prototype.getItemType = function (key) {
        return typeof this.ratingFilter[key] === 'object' ? this.OBJECT : this.BOOLEAN;
    };
    RatingFilterComponent.prototype.getIcon = function (key) {
        return (key in this.aliasIcon) ? this.aliasIcon[key] : (this.showIconsByDefault ? key : null);
    };
    RatingFilterComponent.prototype.getName = function (key) {
        return (key in this.nameAlias) ? this.nameAlias[key] : (this.showLabelByDefault ? key : null);
    };
    return RatingFilterComponent;
}());
export { RatingFilterComponent };
