import { ActiveRecord } from '../../../shared/models/active-record.class';
import {TransformFormatService} from '../services/transform-format.service';

export class TransformFormat extends ActiveRecord {
  protected provider = TransformFormatService;

  public id: number;
  public name: string;

  protected fields() {
    return [
      'id',
      'name',
    ];
  }
}


