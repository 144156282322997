import {EventEmitter, Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {MAP_IMPORT_PROGRESS_STATUS} from '../values/import-progres.const';

@Injectable({
  providedIn: 'root'
})
export class ImportProgressService {

  public resetProgress$ = new EventEmitter();
  public importPercentageValue = 0;
  public showImportProgress = false;
  public showImportProgressSubscription = new Subject();
  public currentDepositId = null;
  public currentStep = 1;
  public DEPOSIT_IMPORT_STEP = {VALIDATION: 1, LOAD: 2};
  public statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.NOT_STARTED;


  constructor() { }

  public setImportPercentageValue(percentage) {
    this.importPercentageValue = percentage;
  }

  public showImportProgressModal() {
    this.showImportProgress = true;
  }

  public hideImportProgressModal() {
    this.showImportProgress = false;
  }

  public setWarningModeProgressModal() {
    this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.WARNING;
  }

  public setErrorModeProgressModal() {
    this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.ERROR;
  }

  public setSuccessModeProgressModal() {
    this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.SUCCESS;
  }

  public setProgressModeProgressModal() {
    this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.PROGRESS;
  }

  public setCheckingmodeProgressModal() {
    this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.CHECKING;
  }
  public setNotStartedModeProgressModal() {
    this.statusImportProgress = MAP_IMPORT_PROGRESS_STATUS.NOT_STARTED;
  }

  public canProgressModalBeClosed() {
    return ![MAP_IMPORT_PROGRESS_STATUS.PROGRESS, MAP_IMPORT_PROGRESS_STATUS.CHECKING].includes(this.statusImportProgress);
  }

  public get canStartImport() {
    return this.canProgressModalBeClosed();
  }

  public resetProgressModal() {
    this.setNotStartedModeProgressModal();
    this.hideImportProgressModal();
    this.resetProgress$.emit(false);
  }

  public startNewProcess() {
    this.setCheckingmodeProgressModal();
    this.showImportProgressModal();
    this.resetProgress$.emit(true);
  }

  public setCurrentId(id) {
    this.currentDepositId = id;
  }

  setCurrentStep(v) {
    this.currentStep = v;
  }
}
