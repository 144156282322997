import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NomenclatureService } from './service/nomenclature.service';
import { TreeViewComponent } from './partials/tree-view/tree-view.component';
import { TreeItemComponent } from './partials/tree-item/tree-item.component';
import { SharedModule } from '../../shared/shared.module';
import { TreeModule } from 'angular-tree-component';
import { NomenclatureSelect } from './service/nomenclature-select.service';
import { SelectDialogComponent } from './dialogs/select-dialog/select-dialog.component';
import { NomenclatureChangeDialogComponent } from './dialogs/nomenclature-change-dialog/nomenclature-change-dialog.component';
import { NomenclatureChangeConfirmationDialogComponent } from './dialogs/nomenclature-change-confirmation-dialog/nomenclature-change-confirmation-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TreeModule
  ],
  declarations: [
    TreeViewComponent,
    TreeItemComponent,
    SelectDialogComponent,
    NomenclatureChangeDialogComponent,
    NomenclatureChangeConfirmationDialogComponent
  ],
  providers: [
    NomenclatureService,
    NomenclatureSelect,
  ],
  exports: [
    TreeViewComponent
  ],
  entryComponents: [
    SelectDialogComponent,
    NomenclatureChangeDialogComponent,
    NomenclatureChangeConfirmationDialogComponent
  ]
})
export class NomenclatureModule {
}
