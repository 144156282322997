import * as tslib_1 from "tslib";
import { DecimalPipe } from '@angular/common';
import { DecimalSeparator } from '../../../../../../../shared/pipes/decimalSeparator';
import { ReportBase } from '../../report-base/report-base.class';
import { ECarbonFootprintReportType } from '../../../../../values/carbon-footprint-status.enum';
import { convertUnit, convertUnitTarget } from '../../../../../../../helper/Unithelper';
import { TranslateService } from '@ngx-translate/core';
var BarChartComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BarChartComponent, _super);
    function BarChartComponent(decimalPipe, _translate, decimalSeparator) {
        var _this = _super.call(this) || this;
        _this.decimalPipe = decimalPipe;
        _this._translate = _translate;
        _this.decimalSeparator = decimalSeparator;
        _this.isReportPdf = false;
        _this.graphicCanvasHeight = 400;
        _this.numberOfChartRows = 7;
        _this.numberOfHrElements = 8;
        return _this;
    }
    BarChartComponent.prototype.getMainDataForChart = function (carbonFootprint) {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return carbonFootprint.footprint;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return carbonFootprint.embodied_carbon_total;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return carbonFootprint.ic_component_total;
            case ECarbonFootprintReportType.IC_CONSTRUCTION:
                return carbonFootprint.ic_construction_total;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return carbonFootprint.product_and_construction_total;
        }
    };
    /**
     * Get the weight of the item depending on the report type
     * @param {ICarbonFootprintCategory} category - The category to get the weight from
     * @returns {Number} - Weight of the category
     */
    BarChartComponent.prototype.getWeight = function (category) {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return category.weight;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return category.weight_considered_product;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return category.weight_considered_embodied;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return category.weight_considered_ic_comp_const;
        }
    };
    /**
     * Get the mass percentage of the item depending on the report type
     * @param {ICarbonFootprintCategory} category - The category to get the mass percentage from
     * @returns {Number} - Weight of the category
     */
    BarChartComponent.prototype.getMassPercentage = function (category) {
        switch (this.reportType) {
            case ECarbonFootprintReportType.PRODUCT_STAGE:
                return category.mass_percentage_considered;
            case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
                return category.mass_percentage_considered_construction;
            case ECarbonFootprintReportType.EMBODIED_CARBON:
                return category.mass_percentage_considered_embodied;
            case ECarbonFootprintReportType.IC_COMPONENT:
                return category.mass_percentage_considered_ic_comp;
        }
    };
    BarChartComponent.prototype.isReportTypeIcComponent = function () {
        return this.reportType === ECarbonFootprintReportType.IC_COMPONENT;
    };
    BarChartComponent.prototype.findMaxValue = function () {
        var maxvalue = this.getDataForChartColumns().reduce(function (max, item) {
            return Math.abs(item.mainDataForChart) > max ? Math.abs(item.mainDataForChart) : max;
        }, 0);
        var unit = this.reportType === ECarbonFootprintReportType.IC_COMPONENT || this.reportType === ECarbonFootprintReportType.IC_CONSTRUCTION ? 'kgs' : 'tons';
        this.maxUnit = convertUnit({ value: maxvalue, unit: unit }).unit;
    };
    BarChartComponent.prototype.getUnit = function () {
        if (!this.maxUnit)
            this.findMaxValue();
        if (this.isReportTypeIcComponent()) {
            return this.maxUnit + ' Co<sub>2</sub> eq. / sqm';
        }
        return this._translate.instant(this.maxUnit) + ' Co<sub>2</sub>  eq.';
    };
    BarChartComponent.prototype.getUnitValue = function (value) {
        if (!this.maxUnit)
            this.findMaxValue();
        var unit = this.reportType === ECarbonFootprintReportType.IC_COMPONENT || this.reportType === ECarbonFootprintReportType.IC_CONSTRUCTION ? 'kgs' : 'tons';
        return convertUnitTarget({ value: value, unit: unit }, this.maxUnit).value;
    };
    BarChartComponent.prototype.getDataForChartColumns = function () {
        return [
            {
                mainDataForChart: this.firstFootprint ? this.getMainDataForChart(this.firstFootprint) : null,
                categoryName: this.firstFootprint ? this.firstFootprint.category.name : null,
                weight: this.firstFootprint ? this.getWeight(this.firstFootprint) : null,
                percentageOfTotalWeight: this.firstFootprint ? this.getMassPercentage(this.firstFootprint) : null,
                itemNumber: 'first'
            },
            {
                mainDataForChart: this.secondFootprint ? this.getMainDataForChart(this.secondFootprint) : null,
                categoryName: this.secondFootprint ? this.secondFootprint.category.name : null,
                weight: this.secondFootprint ? this.getWeight(this.secondFootprint) : null,
                percentageOfTotalWeight: this.secondFootprint ? this.getMassPercentage(this.secondFootprint) : null,
                itemNumber: 'second'
            },
            {
                mainDataForChart: this.thirdFootprint ? this.getMainDataForChart(this.thirdFootprint) : null,
                categoryName: this.thirdFootprint ? this.thirdFootprint.category.name : null,
                weight: this.thirdFootprint ? this.getWeight(this.thirdFootprint) : null,
                percentageOfTotalWeight: this.thirdFootprint ? this.getMassPercentage(this.thirdFootprint) : null,
                itemNumber: 'third'
            }
        ];
    };
    BarChartComponent.prototype.getModifiedClassName = function (className, itemNumber) {
        return className + " " + className + "-" + itemNumber;
    };
    BarChartComponent.prototype.getGraphicCanvasHeight = function () {
        return this.graphicCanvasHeight + 'px';
    };
    BarChartComponent.prototype.getOnePointVerticalAxisValues = function () {
        var maxPointForVerticalAxisValues = Math.max.apply(Math, [
            this.firstFootprint ? this.getMainDataForChart(this.firstFootprint) : null,
            this.secondFootprint ? this.getMainDataForChart(this.secondFootprint) : null,
            this.thirdFootprint ? this.getMainDataForChart(this.thirdFootprint) : null
        ]);
        var step;
        if (maxPointForVerticalAxisValues === 0) {
            step = 1;
        }
        else {
            var _a = maxPointForVerticalAxisValues.toExponential(2).split('e'), shortValue = _a[0], exposant = _a[1];
            var stepBase = Math.ceil(10 * shortValue / 7);
            step = stepBase * Math.pow(10, exposant - 1);
        }
        return step;
    };
    BarChartComponent.prototype.getNumberOfPixelsInOneRowVerticalAxis = function () {
        return this.graphicCanvasHeight / this.numberOfChartRows;
    };
    BarChartComponent.prototype.isCurrentValueLessThanOneRowVerticalAxis = function (currentValue) {
        return this.getNumberOfPixelsInOneRowVerticalAxis() < (this.getAmountForOneChartRowInPixel() * currentValue);
    };
    BarChartComponent.prototype.numberOfHrMarginTopValues = function () {
        // -1px is minus the height of the border hr
        return new Array(this.numberOfHrElements).fill((this.getNumberOfPixelsInOneRowVerticalAxis() - 1) + 'px');
    };
    BarChartComponent.prototype.getVerticalAxisValues = function () {
        var step = this.getOnePointVerticalAxisValues();
        var pointChartValues = [];
        for (var i = 0; i <= this.getNumberOfChartRowsInTable() - 1; i++) {
            pointChartValues.push(i * step);
        }
        return pointChartValues;
    };
    BarChartComponent.prototype.getNumberOfChartRowsInTable = function () {
        var numberOfChartRowsInTable = 8;
        return numberOfChartRowsInTable;
    };
    BarChartComponent.prototype.getAmountForOneChartRowInPixel = function () {
        return this.graphicCanvasHeight / (this.getOnePointVerticalAxisValues() * (this.numberOfChartRows - 1));
    };
    BarChartComponent.prototype.getAmountForPillarInPixels = function (currentValue) {
        return (this.getAmountForOneChartRowInPixel() * currentValue) + 'px';
    };
    BarChartComponent.prototype.getAmountForPillar = function (currentValue) {
        return (this.getAmountForOneChartRowInPixel() * currentValue);
    };
    return BarChartComponent;
}(ReportBase));
export { BarChartComponent };
