import { Component, Inject, OnInit } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransformLocation } from '../../../../entities/transformation/models/transform-location.class';
import { TransformLocationService } from '../../../../entities/transformation/services/transform-location.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'create-transform-site-dialog',
  templateUrl: 'create-transform-site-dialog.component.html'
})
export class CreateTransformSiteDialogComponent implements OnInit {
  transformSiteForm: FormGroup;
  location: TransformLocation = new TransformLocation;
  validAdress : boolean = true ;

  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
              public mdDialogRef: MdDialogRef<void>,
              public _fb: FormBuilder,
              private transformLocationService: TransformLocationService,
              private logNotificationService: LogNotificationService,
              private _translate: TranslateService,) {

  }

  ngOnInit() {
    this.transformSiteForm = this._fb.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      address: ['', Validators.required],
      description: [''],
    });
  }

  onPlaceChange(place: google.maps.places.PlaceResult) {
    if (place.geometry) {
      this.location.lat = place.geometry.location.lat();
      this.location.lng = place.geometry.location.lng();
      this.validAdress =true ;
    } else {
      this.validAdress=false;
      this.location.lat = null;
      this.location.lng = null;
    }
  }

  onTextchange() {
    this.validAdress = false ;
  }

  save(transformSiteForm: FormGroup) {
    

    if ( !this.location.lat || !this.location.lng || !this.validAdress) {
      this.logNotificationService.error(this._translate.instant('Please select your address from suggestions'),null, true);
      return ;
    }
    this.transformLocationService
      .create(this.location)
      .subscribe((location: TransformLocation) => {
        this.location = location;
        this.closeDialog();
      });
  }

  closeDialog() {
    this.mdDialogRef.close(this.location);
  }
}
