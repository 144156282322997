<div class="genericpassportlist">
  <div class="genericpassportlist__create">
    <button class="genericpassportlist__create-button" (click)="navigateToCreateGenericPassport()">
      <i class="genericpassportlist__plus">+</i>
      <span>{{ 'Create new System Passport' | translate }}</span>
    </button>
    <div class="genericpassportlist__zone-name">
      {{zone?.name}}
    </div>
  </div>
  <md-input-container class="passportlist__info-search">
    <input test-id="search"
           mdInput
           autocomplete="off"
           class="passportlist__info-search-any"
           placeholder="{{ 'Search by name' | translate }}"
           [formControl]="search">
  </md-input-container>


  <div class="genericpassportlist__results" test-id="passport-list-results">
    <div class="genericpassportlist__items-header">
      <div class="genericpassportlist__item-name-header"> {{ 'System passport name tags' | translate }}</div>
      <div class="genericpassportlist__item-category-header">{{ 'Category' | translate }}</div>
      <div class="genericpassportlist__item-material-header">{{ 'Material(s)' | translate }}</div>
      <div class="genericpassportlist__item-unit-header"> {{ 'Unit'| translate }}</div>
    </div>

    <generic-passport-item
      [zoneId]="zoneId"
      *ngFor="let record of records | paginate:
      {id:'passports', itemsPerPage: perPage, currentPage: currentPage, totalItems: totalCount };let i = index; "
      [index]="i%2" [genericPassport]="record" [parentModuleName]="parentModuleName"
      (onDelete)="deleteRecord($event)"
      (onCopy)="loadRecordsAtStoredPage()"
      (onOpen)="openRecord($event)">
    </generic-passport-item>
  </div>

  <div class="passportlist__container-pagination">
    <pagination-controls class="passportlist__pagination" id="passports" (pageChange)="pageChanged($event)" maxSize="5"
      directionLinks="true" autoHide="true" previousLabel="" nextLabel=""
      screenReaderPaginationLabel="{{ 'Pagination' | translate }}" screenReaderPageLabel="{{ 'page' | translate }}"
      screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}">
    </pagination-controls>
  </div>


  <section class="ecosystem-dashboard__section ecosystem-dashboard__section--auto">
    <ul class="ecosystem-catalog__content-list  widget--shadow widget--rounded-corners">
      <li class="ecosystem-catalog__content-item">
        <landfill-variables [zoneId]="zoneId"></landfill-variables>
      </li>
    </ul>
  </section>

</div>
