<section class="ecosystem-navigator__grid">
    <ul class="ecosystem-navigator__grid-list" *ngIf="!ecoFolderService.isLoading">
        <li class="ecosystem-navigator__grid-item" *ngFor="let item of ecoFolderService.folders">
            <ecosystem-folder [folder]="item"></ecosystem-folder>
        </li>
        <li class="ecosystem-navigator__grid-item" *ngFor="let item of ecoFolderService.ecosystems">
            <ecosystem-card [ecosystem]="item" ></ecosystem-card>
        </li>
    </ul>
    <ul class="ecosystem-navigator__grid-loading" *ngIf="ecoFolderService.isLoading">
        <md-progress-spinner
            
            mode="indeterminate">
        </md-progress-spinner>
    </ul>
    
</section>