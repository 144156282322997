/**
 * Created by atat on 18/06/2017.
 */
import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DepositOrderService } from '../../../ecosystem-manager/services/deposit-order.service';
import { ECOSYSTEM_ORDER_STATUS, ECOSYSTEM_ORDER_STATUSES } from '../../../ecosystem-manager/values/ecosystem-order-statuses.const';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MdDialog } from '@angular/material';
import { DepositOrderDetailsDialogComponent } from '../../dialogs/deposit-order-details-dialog/deposit-order-details-dialog.component';
import { DepositOrderTransactionConfirmDialogComponent } from '../../dialogs/deposit-order-transaction-confirm-dialog/deposit-order-transaction-confirm-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { EcosystemProblemUtilsService } from '../../../../entities/ecosystem/services/ecosystem-problem-utils.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
var DoArchiveCatalogComponent = /** @class */ (function () {
    function DoArchiveCatalogComponent(_orderService, datePipe, dialog, translateService, ecosystemProblemUtilsService, notifications) {
        var _this = this;
        this._orderService = _orderService;
        this.datePipe = datePipe;
        this.dialog = dialog;
        this.translateService = translateService;
        this.ecosystemProblemUtilsService = ecosystemProblemUtilsService;
        this.notifications = notifications;
        this.ECOSYSTEM_ORDER_STATUS = ECOSYSTEM_ORDER_STATUS;
        this.records = [];
        this.sortOptions = [
            { field: 'confirmation_date', view: 'Date of archiving' },
            { field: 'esm_name', view: 'Ecosystem Manager' },
            { field: 'deposit_name', view: 'Deposit name' }
        ];
        this.sortValue = 'confirmation_date';
        this.search = new FormControl();
        this.filterStatuses = [
            // {
            //   status: ECOSYSTEM_ORDER_STATUS.CONFIRMED,
            //   value: true,
            // },
            {
                status: ECOSYSTEM_ORDER_STATUS.DONE,
                statuses: [ECOSYSTEM_ORDER_STATUS.DONE, ECOSYSTEM_ORDER_STATUS.CONFIRMED],
                value: true,
            },
            {
                status: ECOSYSTEM_ORDER_STATUS.REJECTED,
                statuses: [ECOSYSTEM_ORDER_STATUS.REJECTED],
                value: true,
            },
            {
                status: ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED,
                statuses: [ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED],
                value: true,
            }
        ];
        this._orderService.getEcoSystemManagerList({ is_archive: true }).subscribe(function (managers) { return _this.ecosystemManagerList = managers; });
        this
            .search
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function () { return _this.getRecords(); });
    }
    DoArchiveCatalogComponent.prototype.getStatusAsText = function (orderStatus) {
        var metadata = ECOSYSTEM_ORDER_STATUSES.find(function (item) { return item.value === orderStatus; });
        return metadata ? metadata.view : '';
    };
    DoArchiveCatalogComponent.prototype.getRecords = function () {
        var _this = this;
        var statuses = this.filterStatuses.filter(function (d) { return d.value; }).map(function (d) { return d.statuses; });
        var inlinestatuses = statuses.reduce(function (a, b) { return a.concat(b); }, []);
        this._orderService
            .get({
            expand: 'deposit, creator, ecosystem, deposit_passport,order_passport',
            supervisor: true,
            deposit_owner: true,
            search: this.search.value || null,
            sort: this.sortValue || null,
            esm: this.ecoManagerId || null,
            'status[]': inlinestatuses
        })
            .subscribe(function (data) {
            var _a;
            _this.records = [];
            (_a = _this.records).push.apply(_a, data.map(function (d) { return _this.toRecord(d); }));
        });
    };
    DoArchiveCatalogComponent.prototype.toRecord = function (order) {
        var _this = this;
        var archiveString = '';
        if ([ECOSYSTEM_ORDER_STATUS.CONFIRMED, ECOSYSTEM_ORDER_STATUS.DONE].includes(+order.status)) {
            archiveString = 'Confirmed';
        }
        else {
            archiveString = 'Rejected';
        }
        return CustomListRecord
            .build()
            .addTitleColumn(order.deposit.name)
            .addTextColumn(order.ecosystem.name)
            .addUnitColumn(order.order_passport.quantity ? order.order_passport.quantity : this.translateService.instant('Unknown'), order.order_passport.quantity ? order.deposit.passport.customListUnit : null)
            .addDateColumn(order.confirmation_date, archiveString)
            .addAuthorColumn(order.creator).addControlsColumn([{
                fn: function (data) {
                    _this.dialog.open(DepositOrderDetailsDialogComponent, {
                        data: {
                            id: order.id,
                            readOnly: true,
                        }
                    }).afterClosed().subscribe(function () {
                        console.log('closed');
                    });
                },
                name: 'See details'
            }].concat(([ECOSYSTEM_ORDER_STATUS.DONE, ECOSYSTEM_ORDER_STATUS.CONFIRMED].includes(order.status) ? [{
                fn: function (data) {
                    _this.dialog.open(DepositOrderTransactionConfirmDialogComponent, tslib_1.__assign({}, DIALOG_THEME.TRANSACTION_ORDER_CONFIRMATION, { data: {
                            id: order.id,
                            disabled: true,
                        } }));
                },
                name: 'See confirmation'
            }] : []), ([ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED].includes(order.status) ? [{
                fn: function (data) { return _this._orderService
                    .view(order.id, { expand: 'problem' }).subscribe(function (orderWithProblem) {
                    if (orderWithProblem.status === ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED) {
                        console.log(orderWithProblem.problem);
                        _this.ecosystemProblemUtilsService.openOrderProblemDialog(orderWithProblem.problem.description || '');
                    }
                    else {
                        _this.notifications.error('No result found', null, true);
                        _this.getRecords();
                    }
                }); },
                name: 'See order problem'
            }] : [])));
    };
    DoArchiveCatalogComponent.prototype.ngOnInit = function () {
        this.getRecords();
    };
    return DoArchiveCatalogComponent;
}());
export { DoArchiveCatalogComponent };
