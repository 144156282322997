/**
 * Created by aleksandr on 30.06.17.
 */
import {
  EChatMessageStatus, EThreadType, IChatHistoryRecord,
  IChatMessage, IChatMessageFile
} from '../interfaces/chat-message.interface';
import { IChatUser } from '../interfaces/chat-user.interface';
import { BaseModel } from 'ng2-adn-common';

export class ChatMessage extends BaseModel implements IChatMessage {
  id: number | string;
  roomId: number | string;
  authorId: number;
  author: IChatUser;
  type: EThreadType;
  body: string;
  date: string;
  file: IChatMessageFile[];
  isRead: boolean;

  get isOwner(): boolean {
    return !!this.author && this.author.isMe;
  }

  public static create(record: IChatHistoryRecord): ChatMessage {
    return new ChatMessage({
      id: Number(record.id),
      roomId: Number(record.thread_id),
      authorId: Number(record.user_id),
      type: Number(record.type),
      date: record.created_at,
      body: record.content ? record.content.content : '',
      isRead: record.status === EChatMessageStatus.READ,
      file: record.file
    });
  }

  fields(): string[] {
    return ['id', 'roomId', 'authorId', 'author', 'type', 'isOwner', 'isRead', 'body', 'date', 'file'];
  }

  read() {
    if (!this.isRead) {
      this.isRead = true;
    }
  }

  setAuthor(author: IChatUser) {
    this.author = author;
  }
}
