import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import 'rxjs/add/operator/switchMap';
import { Passport } from '../../models/passport.class';
import { Material } from '../../models/material.class';
import { PassportComponent } from '../../models/component.class';
import { MaterialService } from '../../services/material.service';
import { ComponentService } from '../../services/component.service';
import { Observable, Subscriber } from 'rxjs/Rx';
import { NomenclatureItem } from '../../../nomenclature/models/nomenclature-item.class';


@Component({
  moduleId: module.id,
  selector: 'view-passport',
  templateUrl: 'view-passport.component.html',
  styleUrls: ['view-passport.component.scss'],
})
export class ViewPassportComponent implements OnInit {
  @Input() public passport: Passport;
  @Input() public asDialog?: boolean = false;
  @Input() public isViewMode?: boolean = false;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  constructor(private materialService: MaterialService, private componentService: ComponentService) {

  }

  ngOnInit() {
    console.log('GET ME STUFF');
    //check if arrays are full to see if this is necessary
    this.preloadMaterials().subscribe();
    this.preloadComponents().subscribe();
  }

  preloadMaterials() {

    if (!this.passport.id) {
      return Observable.of([]);
    }

    return new Observable((observer: Subscriber<Material[]>) => {
      this.materialService
        .get<Material>({passport_id: this.passport.id, expand: 'nomenclature'})
        .subscribe(
          result => {
            console.log('fetch material', result);
            this.passport.materials = [];
            for (let material of result) {
              material.nomenclature = new NomenclatureItem(material.nomenclature);
              this.passport.materials.push(material);
            }
            observer.next();
          }
        );
    });

  }

  preloadComponents() {

    if (!this.passport.id) {
      return Observable.of([]);
    }

    return new Observable((observer: Subscriber<PassportComponent[]>) => {
      this.componentService
        .get<PassportComponent>({passport_id: this.passport.id})
        .subscribe(
          result => {
            console.log('fetch component', result);
            this.passport.components = result;
            observer.next();
          }
        );
    });
  }

}
