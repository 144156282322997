<article class="ecosystem-catalog">
  <header class="ecosystem-catalog__header">
    <ul class="ecosystem-catalog__caption-list">
      <li class="ecosystem-catalog__caption-item" (click)="changeTab(0)" [ngClass]="{'active': activeIndex === 0}">
        <span class="ecosystem-catalog__caption-title">{{ 'Needs' | translate }}</span>
        <!--<span class="ecosystem-catalog__caption-counter" *ngIf="offerCount>0">4</span>-->
      </li>
      <li class="ecosystem-catalog__caption-item" (click)="changeTab(3)" [ngClass]="{'active': activeIndex === 3}">
        <span class="ecosystem-catalog__caption-title">{{ 'Transformations' | translate }}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>
      </li>
      <li class="ecosystem-catalog__caption-item" (click)="changeTab(4)" [ngClass]="{'active': activeIndex === 4}">
        <span class="ecosystem-catalog__caption-title">{{ 'Deposits' | translate }}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>
      </li>
      <li class="ecosystem-catalog__caption-item" (click)="changeTab(5)" [ngClass]="{'active': activeIndex === 5}">
        <span class="ecosystem-catalog__caption-title">{{ 'Stakeholders' | translate }}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>
      </li>
      <!-- <li class="ecosystem-catalog__caption-item" (click)="changeTab(6)" [ngClass]="{'active': activeIndex === 6}">
        <span class="ecosystem-catalog__caption-title">{{ 'Virgin products' | translate }}</span>
        <span class="ecosystem-catalog__caption-counter" *ngIf="false">0</span>
      </li> #TODO fix bugs in virgin products in future releases-->
    </ul>
  </header>
  <section class="ecosystem-catalog__content" >
    <ul class="ecosystem-catalog__content-list">
      <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 0">
        <esm-need-catalog></esm-need-catalog>
      </li>
      <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 3">
        <esm-transformation-catalog></esm-transformation-catalog>
      </li>
      <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 4">
        <esm-deposit-catalog></esm-deposit-catalog>
      </li>
      <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 5">
        <esm-stakeholder-catalog></esm-stakeholder-catalog>
      </li>
      <!-- <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 6">
        <esm-virgin-catalog></esm-virgin-catalog>
      </li> #TODO fix bugs in virgin products in future releases-->
    </ul>
  </section>
</article>
