import {AfterViewInit, Directive, ElementRef, HostBinding, HostListener} from '@angular/core';

@Directive({
  selector: '[appTextareaAutocompletePolyfill]'
})
export class TextareaAutocompletePolyfillDirective implements AfterViewInit {

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
   setTimeout(() => {
     this.el.nativeElement.dispatchEvent(new Event('input'));
   });
  }
}
