import * as tslib_1 from "tslib";
import { DataProviderWithPagination } from '../../../shared/models/data-provider-with-pagination.class';
import { FormControl } from '@angular/forms';
import { EDepositLocationStatus } from '../values/deposit-location-status.enum';
var DepositLocationFilter = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationFilter, _super);
    function DepositLocationFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.expand = 'use_count,floor_area';
        _this.sort = 'name';
        _this.search = new FormControl('');
        _this.site_status = [EDepositLocationStatus.STORAGE, EDepositLocationStatus.IN_CONSTRUCTION,
            EDepositLocationStatus.IN_OPERATION];
        _this.is_not_used = true;
        _this.is_used = true;
        _this.active = false;
        _this.pagination = false;
        _this.filterEqualOption = [
            'expand',
            'is_not_used',
            'is_used',
            'sort'
        ];
        _this.extraFilters = [
            'site_status', 'active'
        ];
        return _this;
    }
    DepositLocationFilter.prototype.addExtraFilterAsDefault = function (keys) {
        var _a;
        var keysSet = new Set(this.extraFilters);
        (_a = this.filterEqualOption).push.apply(_a, keys.filter(function (key) { return keysSet.has(key); }));
    };
    DepositLocationFilter.prototype.buildOptions = function () {
        var _this = this;
        var options = tslib_1.__assign({}, this.filterEqualOption.reduce(function (acc, key) {
            var addIfNotEmpty = function () { return _this[key] ? acc[key] = _this[key] : null; };
            if (typeof _this[key] === 'object') {
                if (Array.isArray(_this[key]) && _this[key].length) {
                    acc[key] = _this[key];
                }
            }
            else {
                addIfNotEmpty();
            }
            return acc;
        }, {}));
        if (this.assigned != null) {
            options.assigned = this.assigned;
        }
        if (this.search.value) {
            options.search = this.search.value;
        }
        if (this.pagination) {
            options.pagination = true;
            if (this.page) {
                options.page = this.page;
            }
        }
        // This is needed due to PHP needing brackets to understand that the 
        // If you don't put the brackets, only one of the values will be read
        if (options.site_status) {
            options["site_status[]"] = [].concat(options.site_status);
            delete options.site_status;
        }
        return options;
    };
    return DepositLocationFilter;
}(DataProviderWithPagination));
export { DepositLocationFilter };
