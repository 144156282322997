import * as tslib_1 from "tslib";
import { BaseModel } from 'ng2-adn-common';
import { Utils } from '../../../shared/utils/utils.class';
var SupervisorStats = /** @class */ (function (_super) {
    tslib_1.__extends(SupervisorStats, _super);
    function SupervisorStats() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SupervisorStats.prototype.fields = function () {
        return [
            'deposits_published',
            'deposits_rejected',
            'needs_published',
            'needs_rejected',
            'deposits_characterized',
            'transformations_published',
            'transformations_rejected',
            'deposit_orders_processed',
            'offers_processed',
            'transformatin_orders_processed'
        ];
    };
    SupervisorStats.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        Utils.convertJsonStringToNumber(json, [
            'deposits_published',
            'deposits_rejected',
            'needs_published',
            'needs_rejected',
            'deposits_characterized',
            'transformations_published',
            'transformations_rejected',
            'deposit_orders_processed',
            'offers_processed',
            'transformatin_orders_processed'
        ]);
        return json;
    };
    return SupervisorStats;
}(BaseModel));
export { SupervisorStats };
