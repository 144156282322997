<div class="notification-entry__picture">
  <div class="notification-entry__profile-image" [ngStyle]="record.getAvatar()"></div>
</div>
<div class="notification-entry__data" (click)="callToAction()">
  <div class="notification-entry__message"><span [innerHtml]="record.getNotificationText()"></span></div>
  <div class="notification-entry__date">{{record.getTimeSpanText()}}</div>
</div>
<div class="notification-entry__control">
  <md-checkbox
    (click)="readNotification()"
    [disabled]="record.status!=1"
    [checked]="record.status!==1"
  ></md-checkbox>
</div>
