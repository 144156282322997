import { Router } from "@angular/router";
import { NotificationHandler } from "../interfaces/notification-handler.interface";
import { NotificationEntry } from "../models/notification-entry.model";
import { INotificationEntryAdminType } from "../interfaces/notification-entry.interface";


export class AdminNotificationHandler implements NotificationHandler {
  constructor(private router: Router) { }

  handle(notification: NotificationEntry) {
    switch (notification.type) {
      case INotificationEntryAdminType.TYPE_ADMIN_ECOSYSTEM_IN_PROCESS:
        console.log('Traitement pour TYPE_ADMIN_ECOSYSTEM_IN_PROCESS', notification);
        break;
      case INotificationEntryAdminType.TYPE_ADMIN_ECOSYSTEM_DONE:
        console.log('Traitement pour TYPE_ADMIN_ECOSYSTEM_DONE', notification);
        break;
      default:
        console.log('Traitement par défaut pour une notification admin', notification);
    }
  }
}
