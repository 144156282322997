import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Type } from '@angular/core';
import {UsedEnergyService} from '../services/used-energy.service';
import {EEnergyGroupType, USED_ENERGY_GROUPS} from '../values/used-energy-values.const';

export class UsedEnergy extends ActiveRecord {
  protected provider: Type<UsedEnergyService> = UsedEnergyService;

  id: number;
  energy_id: number;
  percentage: number;
  passport_id: number;
  optimization_strategy: string;

  isRenewable(): boolean {
    const renewableEnergyGroup = USED_ENERGY_GROUPS.find(group => group.type === EEnergyGroupType.RENEWABLE);
    return renewableEnergyGroup.energy.value === this.energy_id
      || !!renewableEnergyGroup.energies.find(i => i.value === this.energy_id);
  }

  fields() {
    return ['id', 'energy_id', 'percentage', 'passport_id', 'optimization_strategy'];
  }
}
