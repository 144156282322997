import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { PassportTag } from '../models/passport-tag.class';

@Injectable()
export class PassportTagService extends CrudService {
  protected namespace = 'generic-passport-tags';
  protected namespaceSingular = 'generic-passport-tag';
  protected ModelClass = PassportTag;
}
