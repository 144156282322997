/**
 * Created by Aleksandr C. on 9.02.17.
 */
import * as tslib_1 from "tslib";
import { Passport } from '../models/passport.class';
import { FileUploader } from 'ng2-file-upload';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var PassportService = /** @class */ (function (_super) {
    tslib_1.__extends(PassportService, _super);
    function PassportService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'passports';
        _this.namespaceSingular = 'passport';
        _this.ModelClass = Passport;
        _this.relations = ['category'];
        return _this;
    }
    PassportService.prototype.getLogoUploader = function (id) {
        return new FileUploader({ url: this.getEndpoint(this.singularEndpoint + '/' + id + '/logo') });
    };
    PassportService.prototype.getCreatorIds = function (query) {
        return this
            .sendGet(this.getEndpoint(this.singularEndpoint + '/creators'), { search: query })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.supercopy = function (id, data) {
        var _this = this;
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/supercopy'), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.savePassportDistributionArea = function (passportId, data) {
        var _this = this;
        return this
            .sendPost(this.getEndpoint(this.singularEndpoint + '/' + passportId + '/distribution-areas'), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.getWaiting = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/unchecked'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.getPublished = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/published'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.getCategoriesList = function () {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + '/categories'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.removeLogo = function (id) {
        return this.sendDelete(this.getEndpoint(this.namespaceSingular + "/" + id + "/logo"))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.setListPage = function (pagenr) {
        this.pagenr = pagenr;
    };
    PassportService.prototype.getListPage = function () {
        return this.pagenr;
    };
    PassportService.prototype.openBannedComponentsPdf = function (language) {
        var url = 'https://storage.googleapis.com/upc-assets/Banned%20lists%20c2c' + language.toUpperCase() + '%20Banned%20list%20c2c.pdf';
        window.open(url);
    };
    PassportService.prototype.createPassport = function (passport, parentModule, passportType) {
        switch (parentModule) {
            case 'manufacturer':
                return this.checkSpecialAdmin(passport, passportType);
            default:
                return this.createLocal(passport);
        }
    };
    PassportService.prototype.checkSpecialAdmin = function (data, type) {
        switch (type) {
            case 'GENERIC':
                return this.createGeneric(data);
            case 'MANUFACTURER':
            default:
                return this.createForManufacturer(data);
        }
    };
    PassportService.prototype.createForManufacturer = function (data) {
        var _this = this;
        return this
            .sendPost(this.getEndpoint(this.namespaceSingular + '/create_manufacturer'), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.createGeneric = function (data) {
        var _this = this;
        return this
            .sendPost(this.getEndpoint(this.namespaceSingular + '/create_generic'), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.createLocal = function (data) {
        var _this = this;
        return this
            .sendPost(this.getEndpoint(this.namespaceSingular + '/create_local'), data)
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    PassportService.prototype.duplicateNoah = function (id) {
        var _this = this;
        return this.sendPost(this.getEndpoint(this.singularEndpoint + '/' + id + '/copy_noah'))
            .map(function (res) { return _this.buildModel(res.json()); })
            .catch(this.onError.bind(this));
    };
    /**
     * Check whether the passport has a corresponding external passport according to its synchronization data
     * If there is one, receive true
     * Else, receive false
     * @param {object} data - The data of the passport to check
     * @returns {boolean}
     */
    PassportService.prototype.checkExternalPassport = function (data) {
        return this.sendPost(this.getEndpoint(this.singularEndpoint + '/check-external-passport'), data)
            .map(function (res) {
            return res.json();
        })
            .catch(this.onError.bind(this));
    };
    /**
     * Synchronize the current passport with the external passport corresponding to the data sent in the payload
     * @param {object} data - The necessary data to synchronize
     * @returns {number}
     */
    PassportService.prototype.synchronizePassport = function (data) {
        return this.sendPost(this.getEndpoint(this.singularEndpoint + '/synchronize'), data)
            .map(function (res) {
            return res.json();
        })
            .catch(this.onError.bind(this));
    };
    /**
     * Desynchronize the current passport
     * @param {object} data - The necessary data to desynchronize
     * @returns {number}
     */
    PassportService.prototype.desynchronizePassport = function (data) {
        return this.sendPost(this.getEndpoint(this.singularEndpoint + '/desynchronize'), data)
            .map(function (res) {
            return res.json();
        })
            .catch(this.onError.bind(this));
    };
    /**
     * Check if passport is the last version of its family
     * @param {object} data - The data of the passport
     * @returns {boolean}
     */
    PassportService.prototype.isLastVersion = function (data) {
        return this.sendPost(this.getEndpoint(this.singularEndpoint + '/is-last-version'), data)
            .map(function (res) {
            return res.json();
        });
    };
    /**
     * Fill the save the passport synchronization data
     * @param {Object} data - The data to fill the synchronization data with
     * @returns {string}
     */
    PassportService.prototype.savePassportSynchronizationData = function (data) {
        return this.sendPost(this.getEndpoint(this.singularEndpoint + '/save-synchronization-data'), data)
            .map(function (res) {
            return res.json();
        });
    };
    /**
     * Trigger the saving of the passport in the backend
     * /!\ Function temporarily created to avoid the flow of passport saving, should not remain for too long /!\
     * @param {Object} data - The passport to save
     */
    PassportService.prototype.savePassportManually = function (data) {
        return this.sendPost(this.getEndpoint(this.singularEndpoint + '/save-passport-manually'), data)
            .map(function (res) {
            return res.json();
        });
    };
    return PassportService;
}(ExtendedCrudService));
export { PassportService };
