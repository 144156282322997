import { Component } from '@angular/core';
import {DepositOrderService} from "../../../ecosystem-manager/services/deposit-order.service";
import {DepositService} from "../../../../entities/deposit/services/deposit.service";

@Component({
  moduleId: module.id,
  templateUrl: 'deposit-owner-dashboard.component.html',
  styleUrls: ['deposit-owner-dashboard.component.scss'],
})


export class DepositOwnerDashboardComponent {

  newDeposit:number;
  newOrders:number;
  depositsInEcosystem:number;


  constructor(private orderService: DepositOrderService,
              private depositService: DepositService) {
    console.info('Launch  -> DepositOwnerDashboardComponent');
    this.orderService.getCount().subscribe(c => this.newOrders = c);
    this.depositService.getWaiting().subscribe(c => this.newDeposit = c);
    this.depositService.getInEcosystem().subscribe(c => this.depositsInEcosystem = c);

  }
}
