import { NewRecipientsDialogComponent } from './../../need-directory/need-recipients/PopupDialog/new-recipients-dialog.component';
/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MdDialog, MdRadioChange } from '@angular/material';
import { Need } from '../../../models/need.class';
import { Contact } from '../../../../client/model/contact.class';
import { ClientService } from '../../../../client/service/client.service';
import { Client } from '../../../../client/model/client.class';
import { ContactService } from '../../../../client/service/contact.service';
import { CreateClientDialogComponent } from '../../../dialogs/create-client/create-client.component';
import { CreateContactDialogComponent } from '../../../dialogs/create-contact/create-contact.component';
import { ViewContactDialogComponent } from '../../../dialogs/view-contact/view-contact.component';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/merge';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NEED_STATUSES } from '../../../values/need-statuses.const';


@Component({
  moduleId: module.id,
  selector: 'name-client-step',
  templateUrl: 'name-client-step.component.html'
})
export class NameClientStepComponent implements OnInit, OnDestroy {
  @Input() controlStates: any = {};
  @Input() need: Need;

  clients: Client[] = [];
  clientId: number;
  defaultName: string;
  editForm: FormGroup;
  invalidClient: boolean = false;
  newClientName: string;
  constructor(public fb: FormBuilder,
              public clientService: ClientService,
              public dialog: MdDialog,
              private contactService: ContactService) {
    // this.needControl = new FormControl("", (c: FormControl) => console.log(c.value));
  }

  ngOnInit() {
  
    this.editForm = this.fb.group({
      need: ['', (c: FormControl) => console.log(c.value)],
      name: [{value: this.need.name, disabled: this.isControlDisabled('name')}, Validators.required],
      company: [this.need.client ? this.need.client.name : '', Validators.required],
      client: [true, (c: FormControl) => c.value ? null : ({invalidClient: true})],
      client_contact: ['', Validators.required],
    });

    if (!!this.need.client) {
      this.defaultName = this.need.client.name;
      this.editForm.controls['company'].setValue(this.need.client.name);

    } else {
      this.defaultName = '';
    }

    this.editForm.controls['need'].setValue(this.need);


    Observable
      .merge(
        this.need.getClient(),
        this.need.getContact()
      )
      .subscribe((data: any) => console.log('RESULTS', data));

    setTimeout(
      ()  => {
        this.need.onChangeClient.emit(this.need.client);
        this.need.onChangeClient.subscribe((client: Client) => {
          console.info('--------------->', 'need.onChangeClient', client, '<-----------------');
          console.info('--------------->', 'need', this.need, '<-----------------');
          let currentValue = this.editForm.controls['company'].value;
          let result = !(this.need && this.need.client_id > 0);
          this.editForm.controls['client'].setErrors({invalidClient: result ? true : null});
    
          if (this.need.client.client_contacts) {
            this.need.setClientContact(
              this.need.client.client_contacts.find((contact: Contact) =>
                contact.id === this.need.client_contact_id
              ) || this.need.client.client_contacts[0]
            );
          } else {
            this.need.setClientContact(null);
          }
        });
      }
    )
  }

  isControlDisabled(field: string) {
    return this.controlStates[field] && this.controlStates[field].disabled;
  }

  ngOnDestroy() {
    //TODO: destroy all subscription
  }

  isClientValid(): boolean {
    let clientIsValid = this.need && this.need.client && this.need.client.id > 0;
    return clientIsValid && true;
  }

  onSelectClient(client: Client) {
    this.need.setClient(client);
  }

  onCreateClient(clientName: string) {
    if (clientName.trim() !== '') {
      if(clientName && this.newClientName !== clientName){
        this.newClientName = clientName;
        this.openCreateClientDialog(clientName);
      }
      }else{
        this.openCreateClientDialog(null);
      }
    //let client = new Client({name: clientName});
    //client.build().save().subscribe((client:Client) => this.onSelectClient(client));
  }

  onChangeContact(event: MdRadioChange) {
    if (event.value && event.value.id) {
      this.need.setClientContact(event.value);
    }
  }

  openCreateClientDialog(clientName: string) {
    let dialogRef = this.dialog.open(NewRecipientsDialogComponent, {
      width: '900px',
      data: {
        name: clientName,
        need: this.need
      }
    });

    dialogRef.afterClosed().subscribe((client: Client) => {
      console.log('afterClosed', client);
      if (client) {
        console.log('Recipient is valid');
        this.clientId = client.id;
        this.need.setClient(client);
      }
    });
  }

  openCreateContactDialog(clientId: number) {
    let dialogRef = this.dialog.open(CreateContactDialogComponent);
    dialogRef.componentInstance.clientId = clientId;
    dialogRef.afterClosed().subscribe((contact: Contact) => {
      if (contact) {
        this.need.client.client_contacts.push(contact);
        this.need.setClientContact(contact);
        console.info('Contact', this.need.client_contact);
      }
    });
  }

  openContactDetailsDialog(contact: Contact) {
    let dialogRef = this.dialog.open(ViewContactDialogComponent);
    dialogRef.componentInstance.contact = contact;
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log("afterClosed", result);
    // if(result){
    //   // this.clientId = result.id
    //   // this.preloadClientContacts();
    // }
    // });
  }

  onChangeName(name: string) {
    console.log('Name changed: ', name);
    if (this.need.client && this.need.client.name !== name) {
      console.log('reset forms');
      this.need.setClient(new Client({name: name}));
      Object
        .keys(this.editForm.controls)
        .filter((key) => key !== 'name')
        .forEach((key) => this.editForm.controls[key].reset());
    }
  }


  observableSource(keyword: any) {
    return Observable.of(this.clients);
  }
}
