<div class="carbon-footprint-details__list">
    <md-checkbox
      class="carbon-footprint-details__list-item"
      (ngModelChange)="updateCarbonFootprintForm()"
      [(ngModel)]="carbonFootprintForm.productStage"
      >
      {{'Production (A1-A3)'| translate}}
      <label class="form-group__label" md-tooltip="{{ 'PRODUCT_STAGE_TOOLTIP' | translate }}">
        <i class="form-group__label--question">?</i>
      </label>
    </md-checkbox>
    <md-checkbox
    *ngIf="isDepositTypeOfBuilding()"
    class="carbon-footprint-details__list-item"
    (ngModelChange)="updateCarbonFootprintForm()"
    [(ngModel)]="carbonFootprintForm.productAndConstruction"
    >
    {{'Production & Construction (A1-A5)'| translate}}
    <label class="form-group__label" md-tooltip="{{ 'PRODUCT_AND_CONSTRUCTION_TOOLTIP' | translate }}">
      <i class="form-group__label--question">?</i>
    </label>
    </md-checkbox>
    <md-checkbox
      class="carbon-footprint-details__list-item"
      (ngModelChange)="updateCarbonFootprintForm()"
      [(ngModel)]="carbonFootprintForm.embodiedCarbon" >
      {{ getEmbodiedCarbonOptionText() | translate}}
      <label
        class="form-group__label"
        md-tooltip="{{ 'EMBODIED_CARBON_TOOLTIP' | translate }}">
        <i class="form-group__label--question">?</i>
      </label>
    </md-checkbox>
    <div *ngIf="isDepositTypeOfBuilding()">
      <div class="carbon-footprint-details__list-divider">
        <span class="carbon-footprint-details__list-divider-title">
          {{ 'French RE2020 indicators' | translate}}:
        </span>
        <label
          class="form-group__label carbon-footprint-details__list-item-tooltip"
          md-tooltip="{{ 'FRENCH_RE2020_INDICATORS_TOOLTIP' | translate }}">
        <i class="form-group__label--question">!</i>
        </label>
      </div>
      <div class="carbon-footprint-details__list-row-container">
        <md-checkbox
        class="carbon-footprint-details__list-item"
        [(ngModel)]="carbonFootprintForm.icComponent"
        [disabled]="this.companyType != 2 ? true : false "
        (ngModelChange)="updateCarbonFootprintForm()">
        {{'Ic'| translate}}
        </md-checkbox>
        <span class="carbon-footprint-details__list-item-text">
          {{'composant'| translate}}
        </span>
        <label
          appCustomTooltip
          [tooltipClass]="['passportlist__version-tooltip']"
          class="form-group__label carbon-footprint-details__list-item-tooltip"
          appCustomTemplateTooltip
          [customTemplate]="getCustomTooltipMessage('IC_COMPONENT_TOOLTIP')"
          md-tooltip="{{'⁣'}}">
          <i class="form-group__label--question">?</i>
        </label>
      </div>
      <div class="carbon-footprint-details__list-row-container">
        <md-checkbox class="carbon-footprint-details__list-item" [(ngModel)]="carbonFootprintForm.icConstruction"
          (ngModelChange)="updateCarbonFootprintForm()"
          [disabled]="this.companyType != 2 ? true : false "
          >
          {{'Ic' | translate}}
        </md-checkbox>
        <span class="carbon-footprint-details__list-item-text">
          {{'construction'}}
        </span>
        <label [ngClass]="{'carbon-footprint-details__list-item-tooltip--active': carbonFootprintForm.icConstruction}"
          appCustomTooltip [tooltipClass]="['carbon-footprint-details__list-item-tooltip--display']"
          class="form-group__label carbon-footprint-details__list-item-tooltip" appCustomTemplateTooltip
          [customTemplate]="getCustomTooltipMessage('IC_CONSTRUCTION_TOOLTIP')" md-tooltip="{{'⁣'}}">
          <i class="form-group__label--question">?</i>
        </label>
        <span [hidden]="!carbonFootprintForm.icConstruction">{{'Please enter the Ic' | translate}}&nbsp;</span>
        <span [hidden]="!carbonFootprintForm.icConstruction" class="carbon-footprint-details__list-item-text">
          {{'chantier'| translate}}
        </span>
        <md-input-container *ngIf="carbonFootprintForm.icConstruction"
          class="carbon-footprint-details__list-item-text carbon-footprint-details__list-item-input">
          <input type="number" mdInput min="0" (ngModelChange)="updateCarbonFootprintForm()"
          onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
            [(ngModel)]="carbonFootprintForm.icConstructionCo2">
        </md-input-container>
        <span [hidden]="!carbonFootprintForm.icConstruction" class="carbon-footprint-details__list-item-text">
          {{'kg CO2 eq/ sqm'| translate}}
        </span>
        <label *ngIf="carbonFootprintForm.icConstruction"
          class="form-group__label carbon-footprint-details__list-item-tooltip carbon-footprint-details__list-item-tooltip--building"
          appCustomTemplateTooltip [customTemplate]="getCustomTooltipMessage('IC_BUILDING_SITE_TOOLTIP')" appCustomTooltip
          md-tooltip="{{'⁣'}}" [tooltipClass]="['carbon-footprint-details__list-item-tooltip--display']">
          <i class="form-group__label--question">?</i>
        </label>
      </div>
    </div>
  </div>
