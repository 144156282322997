var DepositNeedMapMarkersService = /** @class */ (function () {
    function DepositNeedMapMarkersService() {
        this.markers = [];
    }
    Object.defineProperty(DepositNeedMapMarkersService.prototype, "isOpenPopUpOpen", {
        get: function () {
            return !!this.site;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Update the markers present on the map by placing the locations received
     * If a pop up is opened, ignore this
     * Else, reset the markers and create a marker for each new location
     * @param {DepositLocation[]} locations - The locations to set on the map
     */
    DepositNeedMapMarkersService.prototype.updateMarkers = function (locations) {
        var _this = this;
        if (this.isOpenPopUpOpen) {
            var locationWithSiteId = locations.find(function (location) { return location.id === _this.site; });
            if (locationWithSiteId) {
                var markerToUpdate = this.markers.find(function (marker) { return Number(marker.id) === Number(_this.site); });
                if (markerToUpdate) {
                    markerToUpdate.deposits = locationWithSiteId.count;
                    markerToUpdate.totalCount = locationWithSiteId.count;
                }
                return;
            }
        }
        this.reset();
        locations.forEach(function (location) {
            if (location.lat && location.lng && !_this.markers.some(function (marker) { return marker.id === Number(location.id); })) {
                _this.markers.push(_this.convertLocationToMapMarker(location));
            }
        });
    };
    /**
     * Handler of the click action on a marker
     * Set the site attribute to the marker id and remove all markers except the one focused
     * @param {IMapMarker} marker - The marker which was clicked on
     */
    DepositNeedMapMarkersService.prototype.handleMarkerClick = function (marker) {
        this.site = marker.id;
        this.markers = [marker];
    };
    /**
     * Handler of the pop up being closed
     * Empty the site value
     */
    DepositNeedMapMarkersService.prototype.handlePopUpClosed = function () {
        this.site = null;
    };
    /**
     * Convert a location into a marker to be represented on the map
     * @param {DepositLocation} location - The location to convert
     * @returns {IMapMarker}
     */
    DepositNeedMapMarkersService.prototype.convertLocationToMapMarker = function (location) {
        return {
            lat: location.lat || null,
            lng: location.lng || null,
            name: location.name || null,
            deposits: location.count || 0,
            id: location.id || null,
            address: location.address || null,
            totalCount: location.count || 0,
        };
    };
    /**
     * Reset the markers
     */
    DepositNeedMapMarkersService.prototype.resetMarkers = function () {
        if (this.isOpenPopUpOpen) {
            this.markers[0].deposits = 0;
        }
        else {
            this.markers = [];
        }
    };
    /**
     * Reset the markers and the current site
     */
    DepositNeedMapMarkersService.prototype.reset = function () {
        this.markers = [];
        this.site = null;
    };
    return DepositNeedMapMarkersService;
}());
export { DepositNeedMapMarkersService };
