import {Component, ElementRef, Input, QueryList, ViewChildren} from '@angular/core';
import {DepositLocation} from '../../../../../../../entities/deposit/models/deposit-location.class';
import {ElRowCounterService} from '../../../../../services/el-row-counter.service';

@Component({
  selector: 'report-pdf-site-list',
  templateUrl: './report-pdf-site-list.component.html',
  styleUrls: ['./report-pdf-site-list.component.scss'],
})
export class ReportPdfSiteListComponent {
  @ViewChildren('sites') sitesEl: QueryList<ElementRef>;
  @Input() isLongVersion = false;
  @Input() isGenerateFromFile: boolean;
  @Input() measureRows = true;
  @Input('sites') set changeSites(input: DepositLocation[]) {
    this.sites = input;
    if (this.measureRows) {
      this.measureRowNumber();
    }
  }
  sites: DepositLocation[] = [];
  constructor(public rowCounter: ElRowCounterService) {
  }

  measureRowNumber() {
      setTimeout(() => {
        this.rowCounter.setSite(this.sitesEl.map(item => item));
        this.rowCounter.countRows();
      });
    }

}
