import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { IParentEvent } from '../../../../shared/interfaces/parent-event.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Passport } from '../../models/passport.class';
import { PASSPORT_REQUEST } from '../../values/passport-request.const';
import { MdDialog } from '@angular/material';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { NEXT_USAGE } from '../../values/next-usage-values.const';
import { NextUsageService } from '../../services/next-usage.service';
import { NextUsage } from '../../models/next-usage.class';
import { StakeholderService } from '../../../stakeholder/services/stakeholder.service';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import { Subscription } from 'rxjs/Subscription';
import {Observable} from 'rxjs/Observable';
import {forkJoinSafe} from '../../../../shared/helpers/fork-join-safe.helper';
import {PassportCustomStoreService} from '../../services/passport-custom-store.service';

@Component({
  moduleId: module.id,
  selector: 'passport-next-usage',
  templateUrl: 'passport-next-usage.component.html',
  styleUrls: ['passport-next-usage.component.scss']
})
export class PassportNextUsageComponent implements OnInit {

  @Input() public listener?: EventEmitter<IParentEvent>;
  @Input() public passport?: Passport;
  @Input() public isDisabled?: boolean = false;
  @Input() public viewMode: number;
  @Input() parentFormGroup: FormGroup;
  @Output() onSave = new EventEmitter();
  
  optionLoaded: Array<any> = [];
  nextUsageForm: FormGroup;
  listCtrl: FormArray;
  nextUsageTypes = NEXT_USAGE;
  subscriptions: Subscription[] = [];
  loaded: boolean;
  noValueType : boolean = false
  disableStatus: boolean = false;
  VIEW_MODE = VIEW_MODE;
  affiliatebranchListener : Array<EventEmitter<any>>;
  nextUsages :  Array<NextUsage> = [];
  constructor(public _fb: FormBuilder,
              public dialog: MdDialog,
              public usageService: NextUsageService,
              public stakeholderService: StakeholderService,
              @Optional() private passportChildComponentsEventListenerService: PassportCustomStoreService,
              @Optional() public readOnlyService: ReadOnlyService,) {
  }

  ngOnInit() {
    this.affiliatebranchListener =[];
    this.disableStatus = this.isDisabled;
    if (this.passport) {
      this.changePassport(this.passport).updateNextUsages();
    }
    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if (event.key === 'changePassport') {
          this.changePassport(event.data).updateNextUsages();
        }
        if (event.key === 'disableStatus') {
          this.disableStatus = event.data.isDisabled;
          this.updateDisableStatus();
        }
        if (event.key === 'savePassport') {
          this.onSave.emit({type: PASSPORT_REQUEST.NEXT_USAGE, request: this.save()});
        }
      });
    }
  }

  childEventSave(event){
    this.onSave.emit(event);
  }

  changePassport(passport: Passport): this {
    this.passport = passport;
    return this;
  }

  updateNextUsages(): void {
    this.listCtrl = this._fb.array([]);
    this.nextUsages = this.passport.next_usages;
    if(this.nextUsages.length === 0) {
      this.addNew();
    }
    let nextUsageCounter = 0
    
    this.nextUsages.forEach((usage) =>{
      this.addCtrl(usage)
      nextUsageCounter++;
      if(nextUsageCounter === this.nextUsages.length){
        this.loaded = true;
      }
    } );
    this.updateDisableStatus();

    if(this.parentFormGroup) {
      this.parentFormGroup.setControl('nextUsages', this.listCtrl);
    }

    if (this.passportChildComponentsEventListenerService && !this.loaded) {
      setTimeout(() => {
        this.passportChildComponentsEventListenerService
        .addFormProgrammaticlyAction.emit({controlName: 'next_usages', control: this.listCtrl});
      }
      );
    }
  }

  addNew(): void {
    if (this.isDisabled) {
      return;
    }
    
    let nextUsage = new NextUsage({});
    this.nextUsages.push(nextUsage)
    this.addCtrl(nextUsage);
    let arrayNextUsageTypes =  this.valuesToArray(this.nextUsageTypes)
    this.listCtrl.controls.length == arrayNextUsageTypes.length ? this.noValueType = true : this.noValueType = false
  }
  disableValue(): void {
    if(this.optionLoaded.length){
      this.optionLoaded = []
    }
    let arrayNextUsageTypes =  this.valuesToArray(this.nextUsageTypes)
    this.nextUsages.length == arrayNextUsageTypes.length ? this.noValueType = true : this.noValueType = false
    this.listCtrl.controls.map((value) =>{
      this.optionLoaded.push(value['controls']['type']['value'])  
    }) 
    
    this.listCtrl.controls.length == arrayNextUsageTypes.length ? this.noValueType = true : this.noValueType = false
  }
  valuesToArray(obj) {
    return Object.values(obj).map(function (key) { return key['value']; });
  }
  removeNextUsage(index: number): void {
    if (this.isDisabled) {
      return;
    }
   
    let nextUsageForm: FormArray = <FormArray>this.listCtrl.controls[index];
    if(!nextUsageForm) {
      console.error('Failed to get next usage form by index (' + index + '):', this.listCtrl);
    }

    let idCtrl = nextUsageForm.get('id');
    if(!!idCtrl && idCtrl.value) {
      this.deleteNextUsage(idCtrl.value, index);
    }

    this.listCtrl.removeAt(index);
    this.listCtrl.markAsDirty();
    if(this.listCtrl.length === 0) {
      this.addNew();
    }
    this.disableValue()
  }

  
  addCtrl(nextUsage: NextUsage): number {
    let formGroup = this._fb.group({
      id: [nextUsage.id],
      type: [nextUsage.type],
      percentage_product: [nextUsage.percentage_product],
      affiliate_branch: [nextUsage.affiliate_branch],
    });   
    this.listCtrl.push(formGroup);
    return this.affiliatebranchListener.push(new EventEmitter());

  }

  updateDisableStatus(): void {
    if (!this.listCtrl) {
      this.listCtrl = this._fb.array([]);
    }
    if (this.disableStatus) {
      this.listCtrl.controls.forEach((control) => control.disable());
    } else {
      this.listCtrl.controls.forEach((control) => control.enable());
    }
  }

  save() {
    if (this.passport && this.passport.id) {
      const requests = this.listCtrl.controls.map((control, index) => this.getNextUsage(<FormArray>control, index));
      return forkJoinSafe<NextUsage>(requests);
    }
  }

  getNextUsage(nextUsageCtrl: FormArray, index: number): Observable<any> {
    const nextUsage = this.getNextUsageFromForm(nextUsageCtrl);
    if (!!nextUsage) {
      return nextUsage.save().map(v => {
        if (nextUsageCtrl.get('id') && !!v.id) {
          nextUsageCtrl.get('id').setValue(v.id);
          
        }
        this.affiliatebranchListener[index].emit({key: this.usageService.AFFILIATE_BRANCH_EVENT.SAVE, data: v});
        return v;
      });
    }
  }

  getNextUsageFromForm(form: FormArray): NextUsage {
    if(!form) {
      return null;
    }

    let id = form.get('id') ? form.get('id').value : null;
    let type = form.get('type') ? form.get('type').value : null;
    let percentage_product = form.get('percentage_product') ? form.get('percentage_product').value : null;
    let nextUsage: NextUsage = new NextUsage({
      id: id,
      type: type,
      percentage_product: percentage_product,
      passport_id: this.passport ? this.passport.id : null
    });
    
    return nextUsage;
  }

  deleteNextUsage(id: number, index: number): void {
    this.usageService.remove(id).subscribe();
    this.nextUsages = [...this.nextUsages.slice(0, index), ...this.nextUsages.slice(index + 1)];
    this.affiliatebranchListener = [...this.affiliatebranchListener.slice(0, index), ...this.affiliatebranchListener.slice(index + 1)];
  }
  isAddButtonVisible(index: number): boolean {
    return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && index === this.listCtrl.length - 1;
  }

  isRemoveButtonVisible(): boolean {
    return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && this.passport.next_usages.length > 0;
  }

  /**
   * Get the current list of next usages
   * Also add the affiliate branches in the next usage objects
   * @returns {Array<Object>}
   */
  getCurrentNextUsages() {
    return (this.listCtrl.getRawValue() || []).map(this.formatNextUsageForBackend, this);
  }

  /**
   * Format the next usage for backend
   * Mainly exists because of the affiliate branch being named "nextUsage"
   * @param {NextUsage} nextUsage - The next usage to format
   * @returns {Object}
   */
  formatNextUsageForBackend(nextUsage) {
    return {
      id: nextUsage.id,
      percentage_product: nextUsage.percentage_product,
      type: nextUsage.type,
      affiliate_branch: nextUsage.nextUsages
    }
  }
}
