<article class="ecosystem-dashboard type--simple" test-id="supervisor-dashboard">
  <main class="ecosystem-dashboard__content">
    <section class="ecosystem-dashboard__section ecosystem-dashboard">
      <div class="ecosystem-dashboard__button--wrapper">
        <div class="ecosystem-dashboard__button--left">
          <button md-raised-button (click)="navigateToCreatePassport()">
            {{'Create new Circular Passport' | translate }}
          </button>
        </div>
        <div class="ecosystem-dashboard__button--right">
          <button md-raised-button class="" (click)="askForChangeInNomenclatureOpenDialog()">
            {{'Ask for change in nomenclature' | translate }}
          </button>
        </div>
      </div>
    </section>
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--counters">
      <ul class="ecosystem-dashboard__counter-list">
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">
            <i *ngIf="uncheckedPassports == 0;" class="icon icon--check"></i>
            <span *ngIf="uncheckedPassports > 0;">{{uncheckedPassports}}</span>
          </div>
          <span *ngIf="uncheckedPassports == 0;" class="ecosystem-dashboard__counter-title">{{'Nice! All circular passports checked' | translate }}</span>
          <span *ngIf="uncheckedPassports > 0;" class="ecosystem-dashboard__counter-title">{{'New passports' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{needsWaiting}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Needs waiting for publication' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{depositsWaiting}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Deposits waiting for publication' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{transformationsWaiting}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Transformations waiting for publication' | translate}}</span>
        </li>
      </ul>
    </section>
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--auto">
      <ul class="ecosystem-catalog__content-list  type--single type--double widget--shadow widget--rounded-corners">
        <li class="ecosystem-catalog__content-item">
          <supervisor-needs-catalog></supervisor-needs-catalog>
        </li>
        <li class="ecosystem-catalog__content-item">
          <supervisor-deposits-catalog></supervisor-deposits-catalog>
        </li>
      </ul>
    </section>
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--auto">
      <ul class="ecosystem-catalog__content-list  widget--shadow widget--rounded-corners">
        <li class="ecosystem-catalog__content-item">
          <supervisor-transformations-catalog></supervisor-transformations-catalog>
        </li>
      </ul>
    </section>
  </main>
</article>
