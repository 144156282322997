import {CrudService} from '../../../shared/services/crud.service';
import {Injectable} from '@angular/core';
import {DepositTag} from '../models/deposit-tag.class';

@Injectable()
export class DepositTagService extends CrudService {
  protected namespace = 'site-tags';
  protected namespaceSingular = 'site-tag';
  protected ModelClass = DepositTag;
}
