export const IMPORT_DEPOSITS_TAB = {
    DOCUMENTS: 'documents',
    COLUMNS: 'columns',
    TABLES: 'table'
  };

  export const IMMPORT_DEPOSITS_TAB_TYPE = {
    DOCUMENTS: 1,
    COLUMNS: 2,
    TABLES: 3
  };

  export const IMMPORT_DEPOSITS_TAB_TYPE_NUMERIC = {
    1: 'documents',
    2: 'columns',
    3: 'table'
  };


