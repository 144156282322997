import { Injectable } from '@angular/core';
import {ExtendedCrudService} from '../../../shared/services/extended-crud.service';
import {DepositLocationImport} from '../models/deposit-location-import.class';
import {Response} from '@angular/http';
import {Observable} from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class DepositLocationImportService extends ExtendedCrudService {
  protected namespace = 'deposit-location-imports';
  protected namespaceSingular = 'deposit-location-import';
  protected ModelClass = DepositLocationImport;

  public getSuccessSummaryUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/success-summary');
  }

  public getSuccessSummary(id: number,  query = {}): Observable<any> {
    return this.sendGet(this.getSuccessSummaryUrl(id), {search: query})
      .map((res: Response) => res.json())
      .catch(this.onError.bind(this));
  }
}
