<ul class="notification__list">
  <li class="notification__item" *ngFor="let record of records">
    <notification-entry
      [record]="record"
      (onAction)="onAction.emit($event)"
      (onRead)="onRead.emit($event)"
      class="notification-entry"
      [ngClass]="{'active':record.status !== 1}"
    ></notification-entry>
  </li>
  <li class="notification__item--empty" *ngIf="isLoading">{{ 'Loading' | translate }}</li>
  <li class="notification__item--empty" *ngIf="records && records.length === 0 && !isLoading">{{ 'No Notifications' | translate }}</li>
</ul>
