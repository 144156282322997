import {current} from 'codelyzer/util/syntaxKind';

export enum EDepositReuseSource {
  NO_SOURCE = 0,
  DESTOCKED = 1,
  RECONDITIONED = 2,
  REUSED = 3,
  UNKNOWN = 4,
  SURPLUS = 5,
  REMOVAL = 6,
}

export const DEPOSIT_REUSE_SOURCE_VIEW: {[key in EDepositReuseSource]: string} = {
  [EDepositReuseSource.NO_SOURCE]: 'New product',
  [EDepositReuseSource.DESTOCKED]: 'From destocking',
  [EDepositReuseSource.SURPLUS]: 'From a construction site surplus',
  [EDepositReuseSource.RECONDITIONED]: 'From reconditioning',
  [EDepositReuseSource.REUSED]: 'Re-used',
  [EDepositReuseSource.REMOVAL]: 'From a construction site removal',
  [EDepositReuseSource.UNKNOWN]: 'Other'
};

export const DEPOSIT_REUSE_SOURCE_VIEW_ORDER: {[key in EDepositReuseSource]: number} = {
  [EDepositReuseSource.NO_SOURCE]: 1,
  [EDepositReuseSource.DESTOCKED]: 2,
  [EDepositReuseSource.SURPLUS]: 3,
  [EDepositReuseSource.RECONDITIONED]: 4,
  [EDepositReuseSource.REMOVAL]: 5,
  [EDepositReuseSource.REUSED]: 6,
  [EDepositReuseSource.UNKNOWN]: 7
};

export const DEPOSIT_REUSE = [
  { value: EDepositReuseSource.NO_SOURCE, view:  'New product'}, 
  { value: EDepositReuseSource.DESTOCKED, view:  'From destocking'}, 
  { value: EDepositReuseSource.SURPLUS, view:  'From a construction site surplus'}, 
  { value: EDepositReuseSource.RECONDITIONED, view:  'From reconditioning'}, 
  { value: EDepositReuseSource.REUSED, view:  'Re-used'}, 
  { value: EDepositReuseSource.REMOVAL, view:  'From a construction site removal'}, 
  { value: EDepositReuseSource.UNKNOWN, view:  'Other' } 
]

export const DEPOSIT_REUSE_SOURCE_FILTER_ENTRIES = [
  ...Object.keys(DEPOSIT_REUSE_SOURCE_VIEW)
    .filter(key => ![EDepositReuseSource.REUSED].includes(EDepositReuseSource[EDepositReuseSource[key] + '']))
    .sort((previous, next) => DEPOSIT_REUSE_SOURCE_VIEW_ORDER[previous] - DEPOSIT_REUSE_SOURCE_VIEW_ORDER[next])
    .map(key => ({key, value: DEPOSIT_REUSE_SOURCE_VIEW[key]}))
];

export const DEPOSIT_REUSE_SOURCE_SELECT_ENTRIES = [
  ...Object.keys(DEPOSIT_REUSE_SOURCE_VIEW)
    .filter(key => ![EDepositReuseSource.REUSED].includes(EDepositReuseSource[EDepositReuseSource[key] + '']))
    .sort((previous, next) => DEPOSIT_REUSE_SOURCE_VIEW_ORDER[previous] - DEPOSIT_REUSE_SOURCE_VIEW_ORDER[next])
    .map(key => ({key: +key, value: DEPOSIT_REUSE_SOURCE_VIEW[key]}))
];
