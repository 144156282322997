import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ISearchable } from '../../../../shared/interfaces';
import { ADMIN_ESM_OPTIONS, ERoleIds, USER_ROLES, USER_ROLES_FOR_SPECIAL_ADMIN } from '../../../../shared/values/role-ids.enum';
import { Observable } from 'rxjs/Observable';
import { User, UserRole } from '../../../../shared/models/user.class';
import { UserService } from '../../../../shared/services/user.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { Subscription } from 'rxjs/Subscription';
import { SupervisorsAndDepOwnersLists } from './supervisor-and-depositowner-lists.resolver';
import { Searchable } from '../../../../shared/models/searchable.class';
import { AvatarUploaderComponent } from '../../../../shared/uploaders/avatar-uploader/avatar-uploader.component';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import { MdDialog } from '@angular/material';
import { UserDeletionDialogComponent } from '../../dialogs/user-deletion-dialog.component';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { USER_STATUS } from '../../../../shared/values/user-status.const';
import { LanguageService } from '../../../../entities/client/service/language.service';
import { DepositLocation } from '../../../../entities/deposit/models/deposit-location.class';
import { DepositLocationService } from '../../../../entities/deposit/services/deposit-location.service';
import { ESelectionListOpenMode } from '../../../../shared/custom-selection-list-with-chip/values/selection-list-open-mode.enum';
import {
  CustomSelectionListWithChipRecordModel
} from '../../../../shared/custom-selection-list-with-chip/custom-selection-list-with-chip-record.model';
import { AbstractControl } from '@angular/forms/src/model';
import { DepositLocationSalesJunctionService } from '../../../../entities/deposit/services/deposit-location-sales-junction.service';
import { DepositLocationSalesJunction } from '../../../../entities/deposit/models/deposit-location-sales-junction.class';
import { concat, forkJoin, of } from 'rxjs';

interface IParents {
  users: ISearchable[];
  view: string;
  parentRoleId?: ERoleIds;
}

@Component({
  moduleId: module.id,
  selector: 'user-details',
  templateUrl: 'user-details.component.html',
  styleUrls: ['user-details.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'material-reset',
  }
})
// TODO: refactor logic bellow
export class UserDetailsComponent extends EntityViewerComponent<User> implements OnInit, OnDestroy {
  @Output() onSaveAction = new EventEmitter();
  @ViewChild('avatarUploader') avatarUploader: AvatarUploaderComponent;
  @ViewChildren('roleList') roleListElem: QueryList<any>;
  @ViewChildren('sites', { read: ElementRef }) sitesElem: QueryList<any>;

  // Note: names must be same as in UserRole interface
  readonly ROLE_FORM_NAME = 'roleId';
  readonly ROLE_PARENT_FORM_NAME = 'parentId';
  public readonly ROLES_FORM_NAME = 'roles';
  public filteredCompanies: string[];
  public _passwordFormArray: FormArray;

  LIST_OPEN_MODE = ESelectionListOpenMode;
  ROLE_IDS = ERoleIds;

  subscriptions: Subscription[] = [];
  supervisors: ISearchable[] = [];
  depositOwners: ISearchable[] = [];
  allLocationsRecords: CustomSelectionListWithChipRecordModel[] = [];
  salesSitesRecords: CustomSelectionListWithChipRecordModel[] = [];
  parentModule: string;
  moduleNames = ModuleNames;

  userForm: FormGroup;
  user: User = new User;

  USER_ROLES: {
    view: string,
    text: string,
    value: number,
  }[] = [];
  maxRoleHeightPx = 38;
  delimiterClassName = 'custom-selection-list--delimiter';

  private readonly SITE_LIST_COMMAND_ALL = 'all';
  private siteSalesJunctionBefore: { [key: number]: DepositLocationSalesJunction } = {};
  private saleSiteIds = new Set();
  private _rolesFormArray: FormArray;
  private _maxUsernameFieldLength = 64;
  private _maxPasswordFieldLength = 32;
  private _maxStringFieldLength = 30;
  private _minNameFieldLength = 3;
  private _minPasswordLength = 6;
  private isAllsites = false;

  get isInEditMode(): boolean {
    return (this.user && this.user.id > 0);
  }
  get selectedRoles(): UserRole[] {
    return this.userForm.value[this.ROLES_FORM_NAME] || [];
  }
  get selectedRolesForms() {
    return <FormArray>this.userForm.get(this.ROLES_FORM_NAME);
  }

  private allCompanies: string[];
  private admin_able_create_esm;


  static roleIsEnableForEditing(roleId: number): boolean {
    return ![ERoleIds.ADMIN, ERoleIds.SUPERADMIN].includes(roleId);
  }

  constructor(private _fb: FormBuilder,
    private _userService: UserService,
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _currentUserService: CurrentUserService,
    private _translate: TranslateService,
    private _dialog: MdDialog,
    private _languageService: LanguageService,
    private notificationService: LogNotificationService,
    private _depositLocation: DepositLocationService,
    private _depositLocationSalesService: DepositLocationSalesJunctionService
  ) {
    super(_route, _router);
    this.isSiteListItemCheck = this.isSiteListItemCheck.bind(this);
    this.checkAllLocations = this.checkAllLocations.bind(this);
  }
  ngOnInit() {
    this._currentUserService.info().subscribe((resp) => {
      if (resp.json()) {
        this.admin_able_create_esm = resp.json().plan_id;
      }
      this.adjustUserRoles();
    });
    super.ngOnInit();
    this.subscriptions.push(this._route.data.subscribe((data) => {
      const userLists: SupervisorsAndDepOwnersLists = data['userLists'];
      if (!!userLists) {
        this.supervisors = userLists.supervisors.map((item) => this.setUsername(userLists, item));
        this.depositOwners = userLists.depositOwners.map((item) => this.setUsername(userLists, item));
      } else {
        this.supervisors = [];
        this.depositOwners = [];
      }
    }));

    this._userService.getCompanyList().subscribe((companies: string[]) => {
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      this.allCompanies = companies.sort(collator.compare);

      this.onCompanyChange(this.user.company_name);
    });

    this.parentModule = this._navigationService.getModuleName();
    if (this.parentModule !== ModuleNames.SPECIAL_ADMIN) {
      this.USER_ROLES = USER_ROLES;
      this._depositLocation.get({ supervisor: 1, assigned: 1 }).subscribe(
        (sites: DepositLocation[]) => {
          this.allLocationsRecords = this.initAllLocationRecords(sites);
          this.loadChosenLocationRecords();
        }
      );
    } else {
      this.USER_ROLES.push(...USER_ROLES_FOR_SPECIAL_ADMIN);
    }
  }

  setUsername(userLists, item) {
    if (userLists.hasOwnProperty('entity') && userLists['entity']['id'] === item.id) {
      item.name = userLists['entity']['username'];
    }
    return item;
  }

  private adjustUserRoles() {
    if (this.admin_able_create_esm === ADMIN_ESM_OPTIONS.M0) {
      this.USER_ROLES = USER_ROLES.filter(obj => obj.value !== ERoleIds.ECOSYSTEM_MANAGER);
    }
    if (this.admin_able_create_esm === ADMIN_ESM_OPTIONS.M1) {
      this.USER_ROLES = USER_ROLES.filter(obj => obj.value !== ERoleIds.ECOSYSTEM_MANAGER);
    }
    // case M2 do nothing
  }
  onExistEntity(entity: User) {
    this.user = entity;
    this.initForm();
  }

  onNewEntity() {
    this.initForm();
    this.user.number_of_sites = 0;
    this.user.id = -1;
  }

  isPasswordFieldsVisible(): boolean {
    return this.user.status === USER_STATUS.ACTIVE && this.user.id > -1;
  }

  initForm() {
    const rolesGroups: FormGroup[] = [];
    if (this.isInEditMode) {
      this.user.getUserRoles().forEach((role: UserRole) => {
        if (UserDetailsComponent.roleIsEnableForEditing(role.roleId)) {
          rolesGroups.push(this.generateFormGroupForRole(role));
        }
      });
    } else {
      if (this._navigationService.getModuleName() !== ModuleNames.SPECIAL_ADMIN) {
        rolesGroups.push(this.generateFormGroupForRole({ roleId: ERoleIds.AGENT, parentId: null }));
      } else {
        // for special admin do not make with Agent ID
        rolesGroups.push(this.generateFormGroupForRole({ roleId: null, parentId: null }));
      }
    }

    this._rolesFormArray = this._fb.array(rolesGroups);

    this._passwordFormArray = this._fb.array(
      [
        ['',
          [Validators.minLength(6)
            , Validators.maxLength(this._maxPasswordFieldLength)].concat(this.isInEditMode ? [] : [Validators.required])
        ],
        [''],
      ], (c: FormArray) =>
      !!c.value[0] && c.value[0] !== c.value[1] ? { confirm_failed: true } : null
    );

    this.userForm = this._fb.group({
      [this.ROLES_FORM_NAME]: this._rolesFormArray,
      first_name: [this.user.first_name,
      [Validators.required, Validators.minLength(this._minNameFieldLength), Validators.maxLength(this._maxStringFieldLength)]],
      last_name: [this.user.last_name,
      [Validators.required, Validators.minLength(this._minNameFieldLength), Validators.maxLength(this._maxStringFieldLength)]],
      phone: [this.user.phone],
      email: [this.user.email, [Validators.required, Validators.maxLength(254), Validators.email]],
      description: [this.user.description],
      // , disabled: this.isInEditMode
      username: [this.user.username,
      [Validators.required, Validators.minLength(this._minNameFieldLength), Validators.maxLength(this._maxUsernameFieldLength)]],
      emailAsUsername: [{ value: false, disabled: this.isInEditMode }],
      password: this._passwordFormArray,
      company_name: [this.user.company_name, [Validators.required, Validators.pattern(/^\s*[^\s].*$/)]],
      SIRET: this.user.SIRET,
    });
    this.subscribeForFormChanges();
  }

  subscribeForFormChanges() {
    const rolesFormArray: FormArray = <FormArray>this.userForm.controls[this.ROLES_FORM_NAME];
    this.subscriptions.push(rolesFormArray.valueChanges.subscribe(() => {
      rolesFormArray.controls.filter((roleGroup: FormGroup) => {
        if (roleGroup.dirty) {
          this.onRoleControlUpdated(roleGroup);
        }
      });
    }));
    if (!this.isInEditMode) {
      let isEmailAsUsernameSelected = this.userForm.value['emailAsUsername'];
      this.subscriptions.push(this.userForm.controls['emailAsUsername'].valueChanges.subscribe((value: boolean) => {
        isEmailAsUsernameSelected = value;
        if (value) {
          this.userForm.controls['username'].disable();
          this.userForm.controls['username'].setValue(this.userForm.get('email').value);
        } else {
          this.userForm.controls['username'].enable();
          this.userForm.controls['username'].setValue(this.user.username);
        }
      }));
      this.subscriptions.push(this.userForm.controls['email']
        .valueChanges
        .debounceTime(400)
        .distinctUntilChanged()
        .subscribe((value: string) => {
          if (isEmailAsUsernameSelected) {
            this.userForm.controls['username'].setValue(value);
          }
        }));
    }
  }

  getIdOfSelectedRoleAtIndex(index: number): number {
    return +this.selectedRoles[index][this.ROLE_FORM_NAME];
  }

  getViewForRoleParent(roleIndex: number): string {
    const roleParents = this.roleParents(this.getIdOfSelectedRoleAtIndex(roleIndex));
    if (!!roleParents && !!roleParents.view) {
      return roleParents.view;
    } else {
      return '';
    }
  }

  getUsersForRoleParent(roleIndex: number): Searchable[] {
    const roleParents = this.roleParents(this.getIdOfSelectedRoleAtIndex(roleIndex));
    if (!!roleParents && !!roleParents.users) {
      return roleParents.users;
    } else {
      return [];
    }
  }

  isSuperAdminMode() {
    const context = this._navigationService.getContext();
    return context && context.roleId === ERoleIds.SUPERADMIN;
  }

  roleParents(roleId: ERoleIds): IParents {
    if (roleId === ERoleIds.AGENT) {
      return {
        users: this.depositOwners,
        view: 'Deposit owner',
        parentRoleId: ERoleIds.DEPOSIT_OWNER,
      };
    }
    if (
      [ERoleIds.DEPOSIT_OWNER,
      ERoleIds.SALES_MANAGER,
      ERoleIds.TRANSFORMATION_SITE_MANAGER]
        .includes(roleId)
    ) {
      return {
        users: this.supervisors,
        view: 'Supervisors',
        parentRoleId: ERoleIds.SUPERVISOR,
      };
    }
    return {
      users: [],
      view: 'Parent role',
    }; // roleId == ROLE_IDS.SUPERVISOR || roleId == ROLE_IDS.ECOSYSTEM_MANAGER
  }

  shouldAddHimselfAsRoleParent(role: UserRole) {
    const roleParents = this.roleParents(role.roleId);
    return !roleParents.users.find(user => user.id === this.user.id) && // is not already added
      this.selectedRoles.find(selectedRole => selectedRole.roleId === roleParents.parentRoleId); // is parent role selected
  }

  addRole(role?: UserRole): void {
    if (!role) {
      if (this.parentModule === ModuleNames.SPECIAL_ADMIN) {
        this.USER_ROLES = USER_ROLES_FOR_SPECIAL_ADMIN;
      }
      const usedRoles = this.USER_ROLES.map(userR => userR.value);
      // check for special admin , next Task
      // superAdmin add no roles
      for (const item of usedRoles) {
        const contains = this.selectedRolesForms.controls.some((formRole) => formRole.value[this.ROLE_FORM_NAME] === item);
        if (!contains) {
          role = { roleId: +item, parentId: null };
          break;
        }
      }
    }
    if (role) {
      setTimeout(() => this.updateDelimiters());
      this.selectedRolesForms.push(this.generateFormGroupForRole(role));
    } else {
      this.notificationService.warn(this._translate.instant('All possibles roles are added'));
    }
  }

  removeRole(role: FormControl) {
    this.selectedRolesForms.controls.forEach((formRole, index) => {
      if (formRole.value[this.ROLE_FORM_NAME] === role.value[this.ROLE_FORM_NAME]) {
        // nothing to adjust for special admin
        if (this.parentModule !== ModuleNames.SPECIAL_ADMIN) {
          this.adjustSubUsers(role);
        }

        // if the control is deposit owner and a supervisor for himself we delete the supervisor control
        // user can not be agent for himself and a supervisor
        this.selectedRolesForms.removeAt(index);
        setTimeout(() => this.updateDelimiters());
      }
    });
  }
  /// remove users that depends on each other
  // deposit owner remove the agent assigned
  /// supervisor remove all the users roles depending on it
  adjustSubUsers(role: FormControl) {
    this.selectedRolesForms.controls.forEach((formR) => {

      if (role.value[this.ROLE_FORM_NAME] === ERoleIds.SUPERVISOR) {
        if (
          (formR.value[this.ROLE_FORM_NAME] === ERoleIds.SALES_MANAGER ||
            formR.value[this.ROLE_FORM_NAME] === ERoleIds.TRANSFORMATION_SITE_MANAGER ||
            formR.value[this.ROLE_FORM_NAME] === ERoleIds.DEPOSIT_OWNER)
          && formR.value[this.ROLE_PARENT_FORM_NAME] === -1) {

          formR.setValue({
            [this.ROLE_FORM_NAME]: formR.value[this.ROLE_FORM_NAME],
            [this.ROLE_PARENT_FORM_NAME]: null
          });
        }
      }
      if (role.value[this.ROLE_FORM_NAME] === ERoleIds.DEPOSIT_OWNER) {
        if (formR.value[this.ROLE_FORM_NAME] === ERoleIds.AGENT && formR.value[this.ROLE_PARENT_FORM_NAME] === -1) {
          formR.setValue({
            [this.ROLE_FORM_NAME]: formR.value[this.ROLE_FORM_NAME],
            [this.ROLE_PARENT_FORM_NAME]: null
          });
        }
      }

    });

  }

  includeRoleArray(userId: number) {
    // roles_list.forEach((role) => {
    //   if (role.parentId === -1)
    //     role.parentId = userId;
    // });
    this.user.formRoles = this.selectedRoles.map((role) => {
      return [role.roleId, role.parentId];
    });
  }

  showParentUserErrorHint(index: number) {
    const control = this.userForm.get([this.ROLES_FORM_NAME, index, this.ROLE_PARENT_FORM_NAME]);

    if (control) {
      return !control.valid && control.enabled && control.touched;
    } else {
      return false;
    }
  }

  isControlInvalid(path: string[]): boolean {
    const control = this.userForm.get(path);
    if (control) {
      return !control.valid && control.enabled && control.touched;
    } else {
      return false;
    }
  }

  getControlErrorMessage(path: string[]): Observable<any> {
    const control = this.userForm.get(path);
    const errorCode = !!control && control.errors && Object.keys(control.errors).length > 0
      ? Object.keys(control.errors)[0]
      : null;

    if (errorCode) {

      let translation;
      const minLength = path.indexOf('password') === -1 ? this._minNameFieldLength : this._minPasswordLength;
      let maxLength = this._maxStringFieldLength;
      if (path.indexOf('username') > -1) {
        maxLength = this._maxUsernameFieldLength;
      } else if (path.indexOf('password') > -1) {
        maxLength = this._maxPasswordFieldLength;
      }

      switch (errorCode) {
        case 'required':
          translation = this._translate.get('is required');
          break;
        case 'minlength':
          translation = this._translate
            .get('length should be between', {
              X: minLength,
              Y: maxLength
            });
          break;
        case 'maxlength':
          translation = this._translate
            .get('length should be between', {
              X: minLength,
              Y: maxLength
            });
          break;
        case 'email':
          translation = this._translate
            .get('has wrong format');
          break;
      }

      return translation;
    } else {
      return Observable.of('');
    }
  }
  // return false if no roles is duplicated
  // true if one role is duplicated
  private checkForDuplicateOrNullRoles(rolesArray: FormArray) {

    for (let i = 0; i < rolesArray.controls.length; i++) {
      for (let j = i + 1; j < rolesArray.controls.length; j++) {
        if (!rolesArray.controls[i].value[this.ROLE_FORM_NAME]) {
          return true;
        }
        if (rolesArray.controls[i].value[this.ROLE_FORM_NAME] === rolesArray.controls[j].value[this.ROLE_FORM_NAME]) {
          return true;
        }
      }
    }
    return false;
  }
  private isDuplicateRoles() {
    // to be added for special admin : next task
    // to be removed super admin don't add roles !!
    if (this.parentModule === ModuleNames.SPECIAL_ADMIN) {
      if (this.selectedRolesForms.controls.length > USER_ROLES_FOR_SPECIAL_ADMIN.length) {
        return true;
      } else {
        return this.checkForDuplicateOrNullRoles(this.selectedRolesForms);
      }
    } else if (this.isSuperAdminMode()) {
      return false;
    } else {
      // for normal admin
      if (this.selectedRolesForms.controls.length > USER_ROLES.length) {
        return true;
      } else {
        return this.checkForDuplicateOrNullRoles(this.selectedRolesForms);
      }
    }

  }
  touchRoles() {
    this._rolesFormArray.controls.forEach((control) => {
      const parent = control.get(this.ROLE_PARENT_FORM_NAME);
      if (parent && !parent.valid) {
        parent.markAsTouched();
      }
    });
  }
  saveChanges() {
    if (!this.validateUserForm()) {
      return;
    }
    this.updatePersonalUserData();
    this.updateUserPassword();
    const newUser = this.user.id <= 0;
    if (newUser) {
      this.includeRoleArray(this.user.id);
    }
    let postUpdateFunction = this.parentModule !== ModuleNames.SPECIAL_ADMIN
      ? (user: User) => this.updateSiteSalesJunction(user).subscribe(() => this.afterUserUpdate(user, newUser))
      : (user: User) => this.afterUserUpdate(user, newUser);
    this.user.save().subscribe(postUpdateFunction, (err) => {
        const errorMessage = err.json().message;

        if (errorMessage.indexOf('Role requires parent id to assign') > -1) {
          const roleControl = this.userForm.get(this.ROLE_FORM_NAME);

          if (roleControl) {
            roleControl.markAsTouched();
          }
        }
      });
  }

  private validateUserForm() {
    if (!this.checkRoleValidity()) {
      this.touchRoles();
      return false;
    }

    if (this.isDuplicateRoles()) {
      this.notificationService.error(this._translate.instant('Some Roles are Duplicate or empty'), null, true);
      return false;
    }
    if (!this.userForm.valid) {
      Object.values(this.userForm.controls).forEach((control: AbstractControl) => {
        if (!control.valid) {
          control.markAsTouched();
        }
      });

      // Setting confirm_failed error to second password input
      if (!this._passwordFormArray.valid) {
        this._passwordFormArray.controls[1].setErrors(this._passwordFormArray.errors);
      }

      this._passwordFormArray.controls.forEach((control) => {
        if (!control.valid || !this._passwordFormArray.valid) {
          control.markAsTouched();
        }
      });
      this.touchRoles();
    }
    return true;
  }

  private afterUserUpdate(user: User, newUser: boolean) {
    if (newUser) {
      this.avatarUploader.upload(user.id);
      this.onSaveAction.emit();
    } else {
      const updateRolesObservable = this.updateUserRoles(user.id);
      const checkOwnRolesAndNavigate = () => {
        this._currentUserService.currentUserDidChanged.emit();
        this.onSaveAction.emit();
      };
      if (updateRolesObservable) {
        updateRolesObservable.subscribe(() => checkOwnRolesAndNavigate());
      } else {
        checkOwnRolesAndNavigate();
      }
    }
  }

  updatePersonalUserData() {
    this.user.first_name = this.userForm.value['first_name'];
    this.user.last_name = this.userForm.value['last_name'];
    this.user.email = this.userForm.value['email'];
    this.user.phone = this.userForm.value['phone'];
    this.user.description = this.userForm.value['description'];
    this.user.company_name = this.userForm.value['company_name'];
    this.user.SIRET = this.userForm.value['SIRET'];
    this.user.username = this.userForm.get('username').value;
    this.user.language = this.user.language ? this.user.language : this._languageService.getUsedLanguage();
  }

  updateUserPassword() {
    if (this.userForm.value['password'][0].length > 0) {

      this.user.password = this.userForm.value['password'][0];
      this.user.passwordConfirm = this.userForm.value['password'][1];
    }
  }

  updateUserRoles(userId: number): Observable<any> {
    const rolesForRemoving: UserRole[] = [];
    const rolesForUpdate: UserRole[] = [];
    const rolesForAssigning: UserRole[] = [];

    this.selectedRoles.forEach((formRole) => {
      if (formRole.roleId === -1) {
        formRole.roleId = userId;
      }
      if (!this.userHasRole(formRole)) {
        rolesForAssigning.push(formRole);
      }
    });
    this.user.getUserRoles().forEach((userRole: UserRole) => {
      if (!this.formHasRoles(userRole) && UserDetailsComponent.roleIsEnableForEditing(userRole.roleId)) {
        rolesForRemoving.push(userRole);
      } else if (this.roleNeedToUpdateParent(userRole)) {
        rolesForUpdate.push(this.selectedRoles.find((formRole) => formRole.roleId === userRole.roleId));
      }
    });

    if ((rolesForRemoving.length + rolesForUpdate.length + rolesForAssigning.length) > 0) {
      return Observable.merge(
        ...rolesForAssigning.map(
          (role: UserRole) =>
            this._userService.assignRole(userId, {
              role: role.roleId,
              parent_id: role.parentId
            })
        ),
        ...rolesForRemoving.map((role) =>
          this._userService.removeRole(userId, {
            role: role.roleId,
            parent_id: null
          })
        ),
        ...rolesForUpdate.map((role) =>
          this._userService.updateRoleParent(userId, {
            role: role.roleId,
            parent_id: role.parentId
          })
        )
      );
    } else {
      return null;
    }
  }

  loginAsUser() {
    if (this.user.id) {
      this._userService.loginAs(this.user.id).subscribe(() => {
        location.href = '/';
      });
    }
  }

  deleteUser() {
    // if (this.user.id) {
    // }

    if (this.user.id && this.user.status === 0) {
      this._userService.deleteNoTransfer(this.user.id).subscribe(() => this.onSaveAction.emit());
      return;
    }

    if (this.user.id) {
      const dialogRef = this._dialog.open(UserDeletionDialogComponent, {
        data: {
          userId: this.user.id
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onSaveAction.emit();
        }
      });
    } else {
      console.warn('Trying to delete a user while id is not set');
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach((ss) => ss.unsubscribe());
  }

  private onRoleControlUpdated(roleGroup: FormGroup) {
    roleGroup.markAsPristine();
    if (this.needsRoleParent(roleGroup.value[this.ROLE_FORM_NAME])) {
      roleGroup.get(this.ROLE_PARENT_FORM_NAME).enable();
    } else {
      roleGroup.get(this.ROLE_PARENT_FORM_NAME).disable();
    }
    if (roleGroup.value[this.ROLE_PARENT_FORM_NAME] === -1 || roleGroup.value[this.ROLE_PARENT_FORM_NAME] === this.user.id) {
      this.addParentRoleIfNeeded(roleGroup.value);
    }
    setTimeout(() => this.updateDelimiters());
  }

  private checkRoleValidity() {
    let valid = true;

    this.selectedRolesForms.controls.forEach((formR) => {
      const role = formR.value[this.ROLE_FORM_NAME];
      const parentId = formR.value[this.ROLE_PARENT_FORM_NAME];

      const roleWithSupervisor = [ERoleIds.SALES_MANAGER, ERoleIds.TRANSFORMATION_SITE_MANAGER, ERoleIds.DEPOSIT_OWNER];
      if ((roleWithSupervisor.includes(role)) && parentId === -1) {
        if (!this.formHasRoles({ roleId: ERoleIds.SUPERVISOR, parentId: null })) {
          formR.setValue({
            [this.ROLE_FORM_NAME]: role,
            [this.ROLE_PARENT_FORM_NAME]: null
          });
          valid = false;
        }
      }

      if (role === ERoleIds.AGENT && parentId === -1) {
        if (!this.formHasRoles({ roleId: ERoleIds.DEPOSIT_OWNER, parentId: null })) {
          formR.setValue({
            [this.ROLE_FORM_NAME]: formR.value[this.ROLE_FORM_NAME],
            [this.ROLE_PARENT_FORM_NAME]: null
          });
          valid = false;
        }
      }
    });

    return valid;
  }

  private roleNeedToUpdateParent(role: UserRole): boolean {
    const roleWithSameId = this.selectedRoles.find((formRole) => formRole.roleId === role.roleId);
    return (roleWithSameId && roleWithSameId.parentId !== role.parentId);
  }

  private userHasRole(role: UserRole): boolean {
    return !!this.user.getUserRoles().find((userRole: UserRole) => userRole.roleId === role.roleId);
  }

  private formHasRoles(role: UserRole): boolean {
    return !!this.selectedRoles.find((formRole: UserRole) => formRole.roleId === role.roleId);
  }

  private generateFormGroupForRole(role: UserRole): FormGroup {
    return this._fb.group({
      [this.ROLE_FORM_NAME]: role.roleId,
      [this.ROLE_PARENT_FORM_NAME]: [{ value: role.parentId, disabled: !this.needsRoleParent(role.roleId) }, [Validators.required]]
    });
  }

  private needsRoleParent(roleId: ERoleIds) {
    return Boolean(this.roleParents(roleId).parentRoleId);
  }

  private addParentRoleIfNeeded(role: UserRole) {
    const roleParent = this.roleParents(role.roleId);
    if (!!roleParent && !!roleParent.parentRoleId) {
      const userHasParentAsRole = !!this.selectedRoles.find((selectedRole) => selectedRole.roleId === roleParent.parentRoleId);
      if (!userHasParentAsRole) {
        this.addRole({ roleId: roleParent.parentRoleId, parentId: null });
      }
    }
  }

  public onCompanyChange(value: string) {
    // if ( this.parentModule !== ModuleNames.SPECIAL_ADMIN && this.allCompanies) {
    //   this.filteredCompanies = value
    //     ? this.allCompanies.filter((category: string) =>
    //       ~category.toLowerCase().indexOf(value.toLowerCase()) && category !== value)
    //     : this.allCompanies;
    // }
    this.user.company_name = value;
  }

  resendActivation() {
    if (this.user.id) {
      this._userService.resendActivation(this.user.id).subscribe(() => {
        this._router.navigate(['admin/users']);
      });
    }
  }


  isSiteListItemCheck(item: DepositLocation | { command: Function, id: string }) {
    if (item instanceof DepositLocation) {
      return this.findSite(item as DepositLocation);
    } else if (item.id === this.SITE_LIST_COMMAND_ALL) {
      return this.isAllChecked();
    }
  }

  private isAllChecked() {
    return this.isAllsites;
  }

  private findSite(item: DepositLocation) {
    return !!this.saleSiteIds.has(item.id);
  }

  changeSiteList(data: { check: boolean, value: DepositLocation | { command: Function } }) {
    if (data.value instanceof DepositLocation) {
      this.checkLocation(data.check, (data.value as DepositLocation));
    } else {
      data.value.command(data.check);
    }
  }

  private checkLocation(check: boolean, location: DepositLocation) {
    const findAllowedSites = () => this.allLocationsRecords.filter(
      record => record.value && this.saleSiteIds.has(record.value.id));
    if (check) {
      if (!this.findSite(location)) {
        this.saleSiteIds.add(location.id);
        if ((this.allLocationsRecords.length - 1) === this.saleSiteIds.size) {
          this.isAllsites = true;
        }
        this.updateChosenLocationRecords(findAllowedSites());
      }
    } else {
      this.saleSiteIds.delete(location.id);
      if ((this.salesSitesRecords.length) !== this.saleSiteIds.size) {
        this.isAllsites = false;
        this.updateChosenLocationRecords(findAllowedSites());
      } else {
        this.salesSitesRecords = this.salesSitesRecords.filter(item => item.value && item.value.id !== location.id);
      }
    }
  }

  private updateChosenLocationRecords(records: CustomSelectionListWithChipRecordModel[]) {
    setTimeout(() => this.updateDelimiters());
    if (this.isAllsites) {
      return this.salesSitesRecords = [
        CustomSelectionListWithChipRecordModel.build().setNameFunc(value => this._translate.instant('All sites'))
          .addClass(['text-value'])];
    }
    return this.salesSitesRecords = records;
  }

  initAllLocationRecords(list: DepositLocation[]) {
    return [CustomSelectionListWithChipRecordModel.build().setNameFunc(value => this._translate.instant('All sites'))
      .addValue({ command: this.checkAllLocations, id: this.SITE_LIST_COMMAND_ALL }).addClass(['command']),
    ...list.map(location => CustomSelectionListWithChipRecordModel.build()
      .setNameFunc(value => value.name).addValue(location))];
  }

  checkAllLocations(check: boolean) {
    this.isAllsites = check;
    this.setChosenLocationRecords(check ? this.allLocationsRecords : []);
  }

  private setChosenLocationRecords(records: CustomSelectionListWithChipRecordModel[]) {
    this.saleSiteIds = new Set<number>(records.filter(record => record.value instanceof DepositLocation)
      .map(record => record.value.id));
    return this.updateChosenLocationRecords(records);
  }

  private loadChosenLocationRecords() {
    this._depositLocationSalesService.get({ user_id: this.user.id }).subscribe(
      (siteSales: DepositLocationSalesJunction[]) => {
        this.siteSalesJunctionBefore = siteSales.reduce(
          (acamulator, item) => {
            acamulator[item.site_id] = item;
            return acamulator;
          }, {});
        const siteChosen = new Set(Object.keys(this.siteSalesJunctionBefore));
        const records = this.allLocationsRecords.filter(record => {
          return record.value && siteChosen.has(record.value.id + '');
        });
        this.isAllsites = (this.allLocationsRecords.length - 1) === records.length;
        this.setChosenLocationRecords(records);

      }
    );
  }

  private updateSiteSalesJunction(user: User) {
    const forDelete = [];
    const notChangedSiteIds = new Set();
    const forInsert = [];
    Object.values(this.siteSalesJunctionBefore).forEach((siteSales: DepositLocationSalesJunction) =>
      this.saleSiteIds.has(siteSales.site_id) ? notChangedSiteIds.add(siteSales.site_id) : forDelete.push(siteSales.site_id)
    );
    this.saleSiteIds.forEach((siteId: number) => {
      if (!notChangedSiteIds.has(siteId)) {
        forInsert.push(siteId);
      }
    });
    return this._depositLocationSalesService.updateLocationList(forDelete, forInsert, user.id || this.user.id).do(
      (junctions: DepositLocationSalesJunction[]) => {
        forDelete.forEach((id: number) => delete this.siteSalesJunctionBefore[id]);
        junctions.forEach((data: DepositLocationSalesJunction) => this.siteSalesJunctionBefore[data.site_id] = data);
      });
  }

  private updateDelimiters() {
    const delimiterActivation = this.maxRoleHeightPx * 1.5;
    if (this.roleListElem) {
      let activateNext = false;
      const siteArrays = this.sitesElem.toArray();
      let siteIndex = 0;
      this.roleListElem.forEach((row: ElementRef, i, rows) => {
        const delimiterElem = rows[i].nativeElement.querySelector('.' + this.delimiterClassName);
        if (!!delimiterElem) {
          const isActivated = delimiterElem.classList.contains('active');
          if ((isActivated || activateNext) && !(isActivated && activateNext)) {
            delimiterElem.classList.toggle('active');
          }
        }
        activateNext = false;
        if (siteArrays.length > siteIndex && row.nativeElement.contains(siteArrays[siteIndex].nativeElement)) {
          activateNext = siteArrays[siteIndex++].nativeElement.children[0].clientHeight > delimiterActivation;
        }
      });
    }
  }
  isNextRoleItemSales(index: number, controls: AbstractControl[], nextStep = 1) {
    const isSales = (i: number) => controls[i].value.roleId === ERoleIds.SALES_MANAGER;
    return controls.length > index + nextStep && isSales(index) && isSales(index + nextStep);
  }

  getParentRoleWithUsernameText(user: User) {
    const parentRoleIsSameUser = -1;
    if (user.id === parentRoleIsSameUser) {
      return this._translate.instant('him/herself')
    } else {
      return `${user.name}(${user.username})`
    }
  }
}
