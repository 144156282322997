/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/material";
import * as i5 from "../../../read-only/form-elems-disabled.directive";
import * as i6 from "../../../read-only/read-only.service";
import * as i7 from "./notification-entry.component";
var styles_NotificationEntryComponent = [];
var RenderType_NotificationEntryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationEntryComponent, data: {} });
export { RenderType_NotificationEntryComponent as RenderType_NotificationEntryComponent };
export function View_NotificationEntryComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "notification-entry__picture"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "notification-entry__profile-image"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "notification-entry__data"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callToAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "notification-entry__message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "notification-entry__date"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "notification-entry__control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "md-checkbox", [], [[2, "mat-checkbox", null], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.readNotification() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MdCheckbox_0, i2.RenderType_MdCheckbox)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MdCheckbox]), i0.ɵdid(11, 16384, null, 0, i4.MdPrefixRejector, [[2, i4.MATERIAL_COMPATIBILITY_MODE]], null, null), i0.ɵdid(12, 4374528, null, 0, i4.MdCheckbox, [i0.Renderer, i0.ElementRef, i0.ChangeDetectorRef, i4.FocusOriginMonitor], { disabled: [0, "disabled"], checked: [1, "checked"] }, null), i0.ɵdid(13, 81920, null, 0, i5.FormElemsDisabledDirective, [[2, i6.ReadOnlyService], [2, i4.MdCheckbox], [2, i4.MdRadioButton], [2, i4.MdSelect]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.record.getAvatar(); _ck(_v, 2, 0, currVal_0); var currVal_8 = (_co.record.status != 1); var currVal_9 = (_co.record.status !== 1); _ck(_v, 12, 0, currVal_8, currVal_9); _ck(_v, 13, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.record.getNotificationText(); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.record.getTimeSpanText(); _ck(_v, 7, 0, currVal_2); var currVal_3 = true; var currVal_4 = i0.ɵnov(_v, 12).indeterminate; var currVal_5 = i0.ɵnov(_v, 12).checked; var currVal_6 = i0.ɵnov(_v, 12).disabled; var currVal_7 = (i0.ɵnov(_v, 12).labelPosition == "before"); _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_NotificationEntryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notification-entry", [], null, null, null, View_NotificationEntryComponent_0, RenderType_NotificationEntryComponent)), i0.ɵdid(1, 49152, null, 0, i7.NotificationEntryComponent, [], null, null)], null, null); }
var NotificationEntryComponentNgFactory = i0.ɵccf("notification-entry", i7.NotificationEntryComponent, View_NotificationEntryComponent_Host_0, { record: "record" }, { onRead: "onRead", onAction: "onAction" }, []);
export { NotificationEntryComponentNgFactory as NotificationEntryComponentNgFactory };
