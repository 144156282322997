<main class="ecosystem-dashboard__content">
  <article class="need-deposit depositlist ecosystem-catalog">
    <div class="need-deposit__map">
      <deposit-need-map
        [beforeMapUpdate]="beforeMapUpdate"
        [updateMap]="updateMap"
        (onChangeDepositLocationIds)="setChosenLocationsById($event)"
    ></deposit-need-map>
    </div>
    <div class="need-deposit__filters">
      <section class="flex depositlist__filters-button-group">
        <div *ngIf="noahAccess" class="passport-edit__toggle">
          
          <mat-slide-toggle (change)="onToggleChanged($event)"
                            class="passport-edit__toggle"
                            [(ngModel)]="depositFilter.isNoahVisible"
          ></mat-slide-toggle>
          <img src="assets/png/cloud_grey.png" class="need-deposit__noah-icon">
          <span [ngClass]="{'passport-edit__toggle-text': true, 'primary-color': depositFilter.isNoahVisible}">
            {{'SEE_NOAH_DEPOSITS' | translate}}
          </span>
        </div>
        <passport-reset-button
          class="passportlist__clear-filters--offset-right"
          [alternativeRules]="canClearFiltersAlternativeRules"
          [resetFilterState]="resetFilters"
          [controlAsFilter]="['searchControl']"
          [passportFilter]="depositFilter"
          (onResetClick)="clearFilters($event)"
        >
        </passport-reset-button>
      </section>
      <div class="form-group__content--row depositlist__info">
        <md-input-container class="depositlist__info-name-input">
          <input
            test-id="search"
            mdInput
            autocomplete="off"
            class="depositlist__info-name-title"
            placeholder="{{ 'Search by name, materials, address' | translate }}... {{ 'etc' | translate }}."
            [formControl]="depositFilter.searchControl"
          >
        </md-input-container>

        <md-input-container class="depositlist__info-disponable-input calendar--icon-purple">
          <input
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            mdInput
            autocomplete="off"
            class="depositlist__info-disponable-title"
            placeholder="{{ 'Available FROM' | translate }}"
            [(ngModel)]="depositFilter.availabilityDateFrom"
            (popupClosed)="updateFilters()"
          >
        </md-input-container>
        <i class="material-icons span-icon">remove</i>
        <md-input-container class="depositlist__info-disponable-input calendar--icon-purple">
          <input
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            mdInput class="depositlist__info-disponable-title"
            placeholder="{{ 'Available TO' | translate }}"
            autocomplete="off"
            [(ngModel)]="depositFilter.availabilityDateTo"
            (popupClosed)="updateFilters()"
          >
        </md-input-container>
      </div>
      <div class="form-group__content--row depositlist__info">
        <section class="depositlist__form-item-list depositlist__info-name-input--width">

          <deposit-custom-multi-select
            class="depositlist__form-item-list-sub-item"
            appMultiSelectMdSelectInput
            [options]="depositSourceList"
            [placeholder]="'Product source'"
            [ngModel]="this.depositFilter.reuseSource"
            (ngModelChange) = "this.depositFilter.reuseSource = $event; updateFilters()"
          >
          </deposit-custom-multi-select>



          <filter-state-of-use class="depositlist__form-item-list-sub-item"
          [stateOfUse]="depositFilter.stateOfUse"
          (selectionChanged)="updateFilters()"
          [color]="'purple'"
          ></filter-state-of-use>

          <rating-with-local-passport-filter
            class="depositlist__form-item-list-sub-item"
            [classList]="['depositlist__info-rating']"
            [defaultRatings]="resetFilters.ratingFilter"
            [ratingFilter]="depositFilter.ratingFilter"
            (ratingFilterChange)="depositFilter.ratingFilter = $event; updateFilters()"
          >
          </rating-with-local-passport-filter>

          <div class="control-group__item depositlist__form-item depositlist__form-item-list-sub-item">
            <md-input-container (click)="openCategoryDialog(categorie)"
                                class="passportlist__search-materials"
            >
              <md-chip-list>
                <md-basic-chip *ngFor="let tag of depositFilter.categoryTags" (focus)="false" md-tooltip="{{ tag.name | translate }}">
                  {{(tag.name.length > 7 ? tag.name.substr(0,7)+'..' : tag.name ) | translate }}

                  <button (click)="removeCategoryTag(tag, $event)" class="delete"></button>
                </md-basic-chip>
              </md-chip-list>
              <input
                class="passportlist__info-by"
                #categorie
                autocomplete="off"
                placeholder="{{ categoryLabel | translate }}"
                mdInput
              >
            </md-input-container>
          </div>

        </section>

        <div class="control-group__item depositlist__form-item--no-width
         depositlist__info-disponable-input--width
         depositlist__form-item--site-and-tag
         need-deposit__site">
          <deposit-location-filter
            field="nameAddress"
            placeholder="{{'Site(s)' | translate}}"
            iconClass=""
            [inputContainerClass]="[]"
            [availableItems]="sites"
            [clearFilterForm]="clearFilterForm"
            [(ngModel)]="depositFilter.selectedSites"
            (ngModelChange)="updateFilters()"
          >
          </deposit-location-filter>

          <div class="depositlist__filter-container depositlist__form-item-list-sub-item" #filterNextLives>
            <div *ngIf="isFilterNextLivesVisible"
                 class="depositlist__checkbox-filter depositlist__form-item-list-sub-item">
              <div class="depositlist__choose"
                   *ngFor="let nextLivesDescription of nextLivesKeys; let i = index"
                   test-id="checkbox-div">
                <md-checkbox
                  (change)="updateFilters()"
                  id="state{{i}}"
                  [(ngModel)]="depositFilter.nextLives[nextLivesDescription]">
                </md-checkbox>
                <label for="input-state{{i}}" class="depositlist__choose-name" test-id="checkbox-label">
                  {{NextLives[NextLive[nextLivesDescription]] | translate }}
                </label>
              </div>
            </div>
            <md-input-container
              class="depositlist__form-item--no-width"
              [floatPlaceholder]="'never'"
              (click)="isFilterNextLivesVisible = !isFilterNextLivesVisible">
              <input mdInput
                     autocomplete="off"
                     class="passportlist__no-caret"
                     placeholder="{{ 'Next lives' | translate }}"
                     maxlength="0">
              <div class="passportlist__info-icons">
                <span *ngIf="isAnyNextLivesFilterApplied" class="icon--rating-filter-purple"></span>
                <span class="mat-select-arrow"></span>
              </div>
            </md-input-container>
          </div>
        </div>
        <div class="depositlist__form-item-delimiter"></div>
        <div class="control-group__item depositlist__form-item--no-width
         depositlist__form-item--site-and-tag
         depositlist__info-disponable-input--width need-deposit__site">
          <deposit-location-filter
            field="name"
            placeholder="{{'Tag(s)' | translate}}"
            iconClass=""
            [inputContainerClass]="[]"
            [disabled]="depositFilter.selectedSites.length !== 1"
            [availableItems]="depositFilter.selectedSites.length === 1 ? depositFilter.selectedSites[0]?.tags : []"
            [(ngModel)]="depositFilter.selectedTags"
            (ngModelChange)="updateFilters()"
          >
          </deposit-location-filter>

          <md-select
            test-id="sort-selector"
            class="depositlist__info-sort
             depositlist__info-sort--width-whole"
            placeholder="{{ 'Sort by' | translate }}"
            [(ngModel)]=depositFilter.orderBy
            (change)="updateFilters()"
          >
            <md-option *ngFor="let sort of sort_order" [value]="sort.key">
              {{sort.value | translate}}
            </md-option>
          </md-select>
        </div>

      </div>

    </div>
    <div class="need-deposit__filtered-list">
      <div class="depositlist__results" test-id="deposit-list-results">
        <deposit-item
        *ngFor="let record of records | paginate:{id:'deposits'
        , itemsPerPage: depositFilter.perPage
        , currentPage: depositFilter.currentPage
        , totalItems: depositFilter.totalCount }"
        [deposit]="record"
        (onOpen)="openDetailView($event)"
        (onCreateNeed)="createNewNeed($event)"
        [itemView]="DEPOSIT_ITEM_TYPE.NEED_ITEM"
        ></deposit-item>
      </div>
      <div class="depositlist__container-pagination" test-id="deposit-list-pagination">
        <pagination-controls
          class="depositlist__pagination need-deposit__pagination"
          id="deposits"
          (pageChange)="pageChange($event)"
          maxSize="8"
          directionLinks="true"
          autoHide="true"
          previousLabel=""
          nextLabel=""
          screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
          screenReaderPageLabel="{{ 'page' | translate }}"
          screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"
        ></pagination-controls>
      </div>
    </div>
  </article>
</main>
