import { ActiveRecord } from '../../../shared/models/active-record.class';
import { MaterialService } from '../services/material.service';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
import { LocaleService } from '../../../shared/services/locale-service';
import { ValidationService } from '../../../shared/services/validation-service';

export class Material extends ActiveRecord {
  protected provider: any = MaterialService;

  id: number;
  passport_id: number;
  component_id: number;
  nomenclature_id: number;
  quantity: number;
  nomenclature: NomenclatureItem;
  percentage: number;

  fields() {
    return ['id', 'nomenclature_id', 'quantity', 'passport_id', 'nomenclature', 'component_id', 'percentage'];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    json.nomenclature = !!json.nomenclature ? new NomenclatureItem(json.nomenclature) : null;
    return json;
  }

  beforeSave(data: any): any {
    let dataValues = super.beforeSave(data);

    if(dataValues.quantity) {
      dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
    }

    if(dataValues.material_cost) {
      dataValues.material_cost = ValidationService.normalizeQuantity(dataValues.material_cost);
    }

    return dataValues;
  }
}
