import * as _ from 'lodash';
import { IPlumbStepSource } from '../../../dashboards/ecosystem-manager/interfaces/plumb-step-source.interface';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import { Passport } from '../../passport/models/passport.class';
import { VirginProductService } from '../services/virgin-product.service';
import { ProductSector } from './product-sector.class';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';

export class VirginProduct extends ExtendedActiveRecord implements IPlumbStepSource {

  public plumb_type = ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT;


  public id: number;
  public name: string;

  public sector_id: number;
  public passport_id: number;

  public c2c_certified: number;
  public address: string;
  public description: string;

  public contact_name: string;
  public contact_email: string;
  public contact_phone: string;
  public contact_title: string;

  public created_at: string;
  public updated_at: string;

  public passport: Passport;
  public sectors: ProductSector[];
  public nomenclature: NomenclatureItem[];

  protected provider = VirginProductService;

  constructor(data?: any) {
    super(data);
    data = data || {};
    this.nomenclature = (data.nomenclature || []).map((data: any) => new NomenclatureItem(data));
    this.sectors = (data.sectors || []).map((data: any) => new ProductSector(data));
    this.passport = new Passport(Object.assign(data.passport || {}, {nomenclature: this.nomenclature}));
  }

  protected fields() {
    return [
      'id',
      'name',

      'passport_id',
      'sector_id',

      'c2c_certified',
      'address',
      'description',

      'contact_name',
      'contact_email',
      'contact_phone',
      'contact_title',

      'created_at',
      'updated_at',
    ];
  }

  includesText(searchText: string) {
    searchText = searchText.toLocaleLowerCase();

    let doesNameInclude = _.includes(this.name.toLocaleLowerCase(), searchText);

    return doesNameInclude;
  }
}
