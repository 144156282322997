export const PERFORMANCE_RADIOS: {key: number, value: string}[] = [
  {key: 2, value: 'Yes'},
  {key: 1, value: 'Material health certificate'},
  {key: 0, value: 'No'},
];

export const PERFORMANCE_RADIOS_VALUES = {
  YES: 2,
  MATERIAL_HEALTH: 1,
  NO: 0
};
