import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepositListComponent } from './partials/deposit-list/deposit-list.component';
import { DepositListFilter } from './pipes/deposit-list-filter.pipe';
import { DepositService } from './services/deposit.service';
import { PassportModule } from '../passport/passport.module';
import { DepositPassportStepComponent } from './partials/deposit-edit/product-passport/product-passport-step.component';
import { SharedModule } from '../../shared/shared.module';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { DepositEditComponent } from './partials/deposit-edit/deposit-edit.component';
import { DepositSiteDetailsDialogComponent } from './dialogs/deposit-site-details-dialog/deposit-site-details-dialog.component';
import { DepositSiteDetailsComponent } from './partials/deposit-site-details/deposit-site-details.component';
// import { Ng2CollapsableModule } from 'ng2-collapsable';
import { LocationStepComponent } from './partials/deposit-edit/location-step/location-step.component';
import { OtherStepComponent } from './partials/deposit-edit/other-step/other-step.component';
import { EditDepositLocationComponent } from './partials/deposit-location/edit-location/edit-location.component';
import { DepositLocationService } from './services/deposit-location.service';
import { DepositPhotoService } from './services/deposit-photo.service';
import { DuplicateListDialogComponent } from './dialogs/duplicate-list/duplicate-list.component';
import { DuplicateListItemComponent } from './dialogs/duplicate-list/duplicate-list-item/duplicate-list-item.component';
import { Ng2PaginationModule } from 'ng2-pagination';
import { MapDialogComponent } from './dialogs/view-map/view-map.component';
import { MapDialogCircleComponent } from './dialogs/view-map/circle.component/circle.component';
import { DepositDetailsDialogComponent } from './dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { DepositDetailsComponent } from './partials/deposit-details/deposit-details.component';
import { RouterModule } from '@angular/router';
import { DepositResolver } from './resolvers/deposit.resolver';
import { DepositImportErrorsComponent } from './dialogs/deposit-import-errors/deposit-import-errors.component';
import { DepositImportService } from './services/deposit-import.service';
import { ImportSpinnerComponent } from './dialogs/import-spinner/import-spinner.component';
import { ImportCheckedComponent } from './dialogs/import-checked/import-checked.component';
import { ReportImportService } from './services/report-import.service';
import { DepositMapComponent } from './partials/deposit-map/deposit-map.component';
import { DepositNeedMapComponent } from './partials/deposit-need-map/deposit-need-map.component';
import { DepositTagListComponent } from './partials/deposit-tag-list/deposit-tag-list.component';
import { DepositTagService } from './services/deposit-tag.service';
import { DepositTagLocationJunctionService } from './services/deposit-tag-location-junction.service';
import {
  DepositLocationFilterComponent } from './partials/deposit-list/deposit-location-filter/deposit-location-filter.component';
import {
  DepositSiteDetailsBaseComponent } from './partials/deposit-site-details/deposit-site-details-base/deposit-site-details-base.component';
import {
  DepositSiteDetailsPreviewComponent
} from './partials/deposit-site-details/deposit-site-details-preview/deposit-site-details-preview.component';
import {
  DepositSiteDetailsPreviewDialogComponent
} from './dialogs/deposit-site-details-preview-dialog/deposit-site-details-preview-dialog.component';
import {DepositLocationSalesJunctionService} from './services/deposit-location-sales-junction.service';
import { DepositFilterStatusComponent } from './partials/deposit-list/deposit-filter-status/deposit-filter-status.component';
import { DepositCustomMultiSelectComponent }
  from './partials/deposit-list/deposit-custom-multi-select/deposit-custom-multi-select.component';
import { MultiSelectMdSelectInputDirective }
  from './partials/deposit-list/deposit-custom-multi-select/directives/multi-select-md-select-input.directive';
  import { DepositImportSuccessComponent } from './dialogs/deposit-import-success/deposit-import-success.component';
import {PassportResetButtonComponent} from '../passport/partials/passport-list/passport-reset-button/passport-reset-button.component';
import { DepositMassActionDialogComponent } from './dialogs/deposit-mass-action/deposit-mass-action-dialog.component';
import { DepositMassUpdateWarningComponent } from './dialogs/deposit-mass-update-warning/deposit-mass-update-warning.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    Ng2AutoCompleteModule,
    // Ng2CollapsableModule,
    PassportModule,
    Ng2PaginationModule,
    RouterModule
  ],
  providers: [
    DepositService,
    DepositLocationService,
    DepositPhotoService,
    DepositResolver,
    DepositImportService,
    ReportImportService,
    DepositTagService,
    DepositTagLocationJunctionService,
    DepositLocationSalesJunctionService
  ],
  declarations: [
    DepositPassportStepComponent,
    OtherStepComponent,
    LocationStepComponent,
    DepositListComponent,
    DepositListFilter,
    DepositEditComponent,
    EditDepositLocationComponent,
    DuplicateListDialogComponent,
    DuplicateListItemComponent,
    MapDialogComponent,
    MapDialogCircleComponent,
    DuplicateListItemComponent,
    DepositDetailsDialogComponent,
    DepositDetailsComponent,
    DepositImportErrorsComponent,
    ImportSpinnerComponent,
    ImportCheckedComponent,
    DepositMapComponent,
    DepositNeedMapComponent,
    DepositTagListComponent,
    DepositTagListComponent,
    DepositSiteDetailsDialogComponent,
    DepositSiteDetailsComponent,
    DepositLocationFilterComponent,
    DepositSiteDetailsComponent,
    DepositSiteDetailsBaseComponent,
    DepositSiteDetailsPreviewComponent,
    DepositSiteDetailsPreviewDialogComponent,
    DepositFilterStatusComponent,
    DepositCustomMultiSelectComponent,
    MultiSelectMdSelectInputDirective,
    DepositImportSuccessComponent,
    DepositMassActionDialogComponent,
    DepositMassUpdateWarningComponent
  ],
  entryComponents: [
    DuplicateListDialogComponent,
    DuplicateListItemComponent,
    MapDialogComponent,
    DepositDetailsDialogComponent,
    DepositImportErrorsComponent,
    ImportSpinnerComponent,
    ImportCheckedComponent,
    DepositSiteDetailsDialogComponent,
    DepositSiteDetailsPreviewDialogComponent,
    DepositImportSuccessComponent,
    DepositMassActionDialogComponent,
    DepositMassUpdateWarningComponent
  ],
  exports: [
    DepositSiteDetailsDialogComponent,
    DepositPassportStepComponent,
    DepositMapComponent,
    DepositNeedMapComponent,
    DepositLocationFilterComponent,
    DepositCustomMultiSelectComponent,
    MultiSelectMdSelectInputDirective
  ]
})
export class DepositModule {
}
