<form
  *ngIf="passport"
  [formGroup]="passportForm"
  class="passport view-{{viewMode}}"
  [ngClass]="{'form-controls-disabled': areFormControlsDisabled}"
>
  <section class="form-group`">
    <div *ngIf="supervisorWarning" class="">
      {{ 'Warning!' | translate }}
      {{ 'Be careful when editing published passports' | translate }}.
      {{ 'Changes will carry over to all needs and deposits' | translate }}.
    </div>


    <ng-content></ng-content>


    <div class="passport__tab flex justify-between">
      <section class="passport-edit__name-field flex-column">
        <!--<label class="passport-edit-form__info-label-block">
          <span class="passport-edit-form__info-label">{{ 'General informations' | translate }}</span>&nbsp;
        </label> -->
        <div class="passport__name-info-wrapper">
          <div class="passport__void-label" *ngIf="isPassportVoid">
            <passport-family-view-label [familyType]="passport.family"
                                        [unit]="passport.unit + ''"></passport-family-view-label>
            <div class="passport__void-warning primary-color">⚠ {{'WARNING_VOID_PASSPORT' | translate}}</div>
          </div>
          <div [ngClass]="{'passport__custom-list-wrapper': enableSearch, 'passport__name-wrapper': !enableSearch}">
            <ng-content select="[passport-search-message]"></ng-content>

            <!-- composite name doesnt exist-->
            <div *ngIf="!passportExist" class="passport__name-content">

              <div [ngClass]="{'passport__name-field': true, 'form-group__field': true}">
                <custom-search
                  #customSearch
                  class="passport__custom-list"
                  *ngIf="enableSearch"
                  [preset]="passport"
                  [hasAdvancedResearch]="true"
                  [service]="passportService"
                  [showInitName]="!isPassportVoid"
                  caption="{{ 'Passport name' | translate }} *"
                  [autoComplete]="'off'"
                  (onOpenAdvancedResearch)="openAdvancedResearch($event)"
                  [CustomName]="getPassportCustomName"
                  (onChangeName)="searchValue = $event"
                  (onSelect)="onSelect($event)"
                  (onCreate)="onCreate($event)"
                  [autoCreation]="false"
                  label="{{ 'Create New Passport' | translate }}"
                  [required]="true"
                  [class.highlighted]="controlStates?.name?.highlighted"
                  [filters]="{'status[]': PASSPORT_STATUS_CONST.PUBLISHED.value}"
                ></custom-search>

                <md-input-container *ngIf="!enableSearch && allowNameChange"
                                    (click)="onOpenGenerationNameDialog($event)"
                >
                  <input
                    mdInput
                    autocomplete="off"
                    [value]="passport.composite_name +' V'+passport.version"
                    placeholder="{{ ('Circular passport name' | translate) + '*'}}"
                    [readonly]="!allowNameChange"
                    (change)="onCreate($event.target.value)"
                    [class.highlighted]="controlStates?.name?.highlighted"
                    [disabled]="areFormControlsDisabled"
                  >
                </md-input-container>

                <md-input-container *ngIf="!enableSearch && !allowNameChange"
                                    (click)="onOpenGenerationNameDialog($event)"
                >
                  <input
                    mdInput
                    autocomplete="off"
                    [value]="passport.composite_name +' V'+passport.version"
                    (change)="onNameEdit($event.target.value)"
                    placeholder="{{ 'Product name' | translate }}"
                    [class.highlighted]="controlStates?.name?.highlighted"
                    [disabled]="areFormControlsDisabled"
                  >
                </md-input-container>
              </div>
              <div class="passport__advanced-research-button background-primary-color"
                   (click)="openAdvancedResearch($event)">
                {{'Advanced search' | translate | uppercase}}
              </div>

            </div>

            <!-- when composite name exist-->
            <div *ngIf="passportExist && !isPassportVoid" class="flex-column">
              
              <passport-info-display [passport]="passport"
                                     [showCopyId]="passport.hasProductPassportId()"
                                     [showPassportNameMenu]="isShownCatalogNameMenu()"
                                     (onOpenGenerationNameDialog)="onOpenGenerationNameDialog($event)"
              >
                  <button passport-name-menu md-menu-item
                          *ngIf="validateCopyPassport()"
                          (click)="onOpenCopyDialog($event)">
                    {{'Create a local passport copy' | translate}}</button>
                  <button passport-name-menu md-menu-item (click)="changePassport()">
                    {{'Select another passport' | translate}}</button>
              </passport-info-display>
            </div>

          </div>
          <div class="passport-edit__category-block" *ngIf="!enableSearch && !isPassportVoid">
            <div class="edit-passport-edit__item-field"
                 [ngClass]="{'form-group__content--row passport-edit__item-field': viewMode == VIEW_MODE.DEFAULT}">
              <div class="form-group__field passport__manufacturer-field">

                  <md-input-container (click)="openCategoryeDialog($event)">
                    <input
                      mdInput
                      autocomplete="off"
                      formControlName="category"
                      placeholder="{{ ('Product category' | translate) + '*'}}"
                      [disabled]="areFormControlsDisabled"
                    >
                  </md-input-container>

              </div>
              <label class="form-group__label"
                     *ngIf="viewMode == VIEW_MODE.DEFAULT"
                     md-tooltip="{{ 'PASSPORT_CATEGORY_TOOLTIP' | translate }}"
              >
                <i class="form-group__label--question">?</i>
              </label>
            </div>

            <passport-multiple-field-input
              *ngIf="passport.isGtinVisible"
              [records]="passport.gtins"
              [recordNameField]="'code'"
              [placeholder]="'GTIN'"
              [disabled]="areFormControlsDisabled"
              [isOnAddErrorErrorMessageShown]="true"
              [addRecordObservable]="gtinBuffer.emitSourceGtin"
              (onDeleteSuccess)="gtinBuffer.emitSourceDelete($event)"
              [errorMessage]="'Invalid GTIN format: 8 to 14-digit string requested' | translate"
            ></passport-multiple-field-input>

            <div class="passport-edit__item-unit"
                 [ngClass]="{'form-group__content--row': viewMode == VIEW_MODE.DEFAULT}">
              <div class="form-group__field input-small">
                <md-select
                  formControlName="passportUnit"
                  placeholder="{{ ('Circular passport unit' | translate) + '*' }}"
                  [disabled]="areFormControlsDisabled"
                  [class.highlighted]="controlStates?.unit?.highlighted"
                >
                  <md-option
                    *ngFor="let unit of units"
                    [value]="unit.id"
                  >{{unit.title | translate}}
                  </md-option>
                </md-select>
              </div>
              <div class="flex flex-column" *ngIf="isPassportConversionShown()">
                <div class="flex passport-edit__info--group-conversion"
                     [ngClass]="getLabelClasses(passport.conversion)"
                >
                  {{'Passport conversion factor' | translate}}
                </div>
                <passport-conversion
                  [disabled]="areFormControlsDisabled"
                  [unitView]="passport.unit_text"
                  formControlName="conversion"
                  [(ngModel)]="passport.conversion"
                >
                </passport-conversion>
              </div>
            </div>

          </div>

          <ng-container *ngIf="!enableSearch && passport.isRatingVisible">
            <div class="flex flex-column" >
              <div class="flex passport-edit__info--group-rating"
                   [ngClass]="getLabelClasses(passport.rating)"
              >
                {{'Product rating' | translate}}
              </div>
            </div>
            <passport-rating-display [passport]="passport"></passport-rating-display>
          </ng-container>
        </div>
      </section>

      <div class="passport__logo-field passport__main-photo"  *ngIf="!enableSearch"  >
        <label class="passport-edit__main-photo--label">{{ 'Main photo' | translate }}</label>
        <logo-uploader
          [isDisabled]="areFormControlsDisabled"
          [preview]="passport.image"
          [targetUrl]="passport?.id && !areFormControlsDisabled ? getLogoUploaderUrl() : null"
          (onLoad)="onLogoUpload($event)"
          [notShowClickErrors] = "showErrors()"
          (onRemove)="removePassportPreview()"
          [class.highlighted]="controlStates?.logo?.highlighted"
          [uploadUrlMissingErrorText]="areFormControlsDisabled  ? uploadErrorText: ''"
        ></logo-uploader>
      </div>
    </div>

    <div *ngIf="!isPassportVoid">
      <section class="passport__components-materials-field passport-edit-form__material-section"
              [ngClass]="{'form-group__content--row': viewMode != VIEW_MODE.DEFAULT}"
              *ngIf="!enableSearch">
        <div class="flex align-center">
          <label>
            <span class="passport-edit-form__info-label">{{ 'Materials and components' | translate }}</span>&nbsp;
            <span
              class="passport-edit-form__info-label-explanation">{{ 'PASSPORT_COMPONENT_EXPLANATION' | translate }}</span>
          </label>
          <label class="form-group__label"
                *ngIf="viewMode == VIEW_MODE.DEFAULT"
                md-tooltip="{{ 'PASSPORT_COMPONENTS_INFO_TOOLTIP_TOP' | translate}}{{'\n\n'}}{{'PASSPORT_COMPONENTS_INFO_TOOLTIP_BOT' | translate}}">
            <i class="form-group__label--question">?</i>
          </label>
        </div>

        <div [ngClass]="{'form-group__content--row': viewMode == VIEW_MODE.DEFAULT}">
          <div
            class="form-group__field passport__components-field"
          >
            <passport-components
              (onSave)="onUpdatePassportData($event)"
              [listener]="eventStream$"
              [passport]="passport"
              [isDisabled]="areFormControlsDisabled"
              [viewMode]="viewMode"
              [parentFormGroup]="passportForm"
              [class.highlighted]="controlStates?.components?.highlighted"
              [isComponentNameMandatory]="true"
            ></passport-components>
          </div>
        </div>

        <!-- next life -->
        <div class="passport-edit-form__label-caption">
          {{ 'First life' | translate }}
        </div>
        <div>
          <div class="passport-edit__cycle--life-recycle-wrapper flex-column">
            <div class="passport-edit__cycle--life-label"
                [ngClass]="getLabelClasses(passport.period)">{{'Period of use' | translate}}</div>

            <div class="passport-edit__cycle--life-inputs-container">
              <div class="passport-edit__cycle--life-quantity">
                <md-input-container class="passport-edit__cycle--life-quantity-input passport-edit__input">
                  <input
                    mdInput
                    autocomplete="off"
                    type="number"
                    formControlName="quantity"
                    min="0"
                    [disabled]="areFormControlsDisabled">
                  <md-hint
                    *ngIf="passportForm?.controls?.quantity.invalid"
                    class="error-hint"
                    align="start"
                  >{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>
                </md-input-container>
              </div>
              <div class="form-group__field passport-edit__cycle--life-period">
                <md-select
                  [disabled]="areFormControlsDisabled"
                  formControlName="period"
                  placeholder="{{ 'Period' | translate }}"
                >
                  <md-option
                    *ngFor="let periodType of period_types"
                    [value]="periodType.value"
                  >{{ periodType.view | translate }}
                  </md-option>
                </md-select>
              </div>

              <label class="form-group__label"
                    *ngIf="viewMode == VIEW_MODE.DEFAULT"
                    md-tooltip="{{ 'PASSPORT_PERIOD_OF_USE_TOOLTIP' | translate }}"
              ><i class="form-group__label--question">?</i></label>
            </div>
          </div>
        </div>

        <div class="passport-edit__cycle--next-wrapper">
          <div class="passport-edit-form__label-caption">
            {{ 'Next lives' | translate }}
            <label class="form-group__label"
                  *ngIf="viewMode == VIEW_MODE.DEFAULT"
                  md-tooltip="{{ 'PASSPORT_NEXT_USAGES_TOOLTIP' | translate }}"
            ><i class="form-group__label--question">?</i>
            </label>
          </div>

          <div class="passport-edit__next-usage">
            <div [ngClass]="{'form-group__content--row': viewMode == VIEW_MODE.DEFAULT}">
              <div class="form-group__field passport__materials-field"
              >
                <passport-next-usage
                  (onSave)="onUpdatePassportData($event)"
                  [listener]="eventStream$"
                  [passport]="passport"
                  [isDisabled]="areFormControlsDisabled"
                  [viewMode]="viewMode"
                  [parentFormGroup]="passportForm"
                ></passport-next-usage>
              </div>
            </div>
          </div>
        </div>
      </section>

      <article class="passport-edit__performance-short-version" *ngIf="!enableSearch">
        <div class="passport-edit-form__label-caption">
          {{ 'Life cycle analysis' | translate }}
        </div>
        <section class="passport-edit__performance-short-version-main">
        <h2 class="passport-edit__performance-short-version-caption">
          <span>{{'functional unit' | translate | capitalize }}</span>
          <label class="form-group__label  passport-edit__performance-short-version-caption-question"
                *ngIf="viewMode == VIEW_MODE.DEFAULT" mdTooltip="{{ 'MASS_OF_FUNCTIONAL_UNIT_TOOLTIP' | translate }}">
            <i class="form-group__label--question">?</i>
          </label>
        </h2>
          <ul class="passport-edit__performance-short-version-lifecycle-list">
            <li class="passport-edit__performance-short-version-lifecycle" >
              <h3 class="passport-edit__performance-short-version-lifecycle-additional-label primary-color">{{'Value' | translate}}</h3>
              <passport-conversion
                [ngModel]="this.passport.carbon_conversion"
                [unitView]="'functional unit'| translate"
                formControlName="carbonConversion"
              >
              </passport-conversion>
              </li>
            <li>
              <md-input-container
                class="passport-edit__performance-short-version-lifecycle-description"
              >
                  <textarea
                    formControlName="descriptionOfUnit"
                    mdInput
                    [placeholder]="'Description of the functional unit' | translate"
                  ></textarea>
              </md-input-container>
            </li>
          </ul>
            <h2 class="passport-edit__performance-short-version-caption
            passport-edit__performance-short-version-section-gap">
              <span>{{'Carbon footprint' | translate}} ({{"in kg CO2 eq"| translate}})</span>
              <label class="form-group__label  passport-edit__performance-short-version-caption-question"
                    *ngIf="viewMode == VIEW_MODE.DEFAULT" mdTooltip="{{ 'PASSPORT_C2C_FOOTPRINT_TOOLTIP' | translate }}">
                <i class="form-group__label--question">?</i>
              </label>
            </h2>
          <ul class="passport-edit__performance-short-version-lifecycle-list">
            <li>
                <md-input-container floatPlaceholder="always"
                                    class="passport-edit__performance-short-version-lifecycle-footprint">
                  <input
                        formControlName="carbonProduct"
                        mdInput
                        autocomplete="off"
                        [placeholder]="'Production (A1-A3 module)' | translate"
                        type="number">
                  <md-hint
                          class="error-hint" align="start">{{
                    'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>
                </md-input-container>
                <div class="passport-edit__performance-short-version-lifecycle-footprint-unit">kg CO2 eq
                  / {{'functional unit' | translate}}</div>
            </li>
            <li>
              <md-input-container floatPlaceholder="always"
                                  class="passport-edit__performance-short-version-lifecycle-footprint">
                <input
                  formControlName="carbonEnd"
                  placeholder="{{'End of life stage (C module)' | translate}}"
                  mdInput
                  autocomplete="off"
                  type="number">
                <md-hint
                        class="error-hint" align="start">{{
                  'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>
              </md-input-container>
              <div class="passport-edit__performance-short-version-lifecycle-footprint-unit">kg CO2 eq
                / {{'functional unit' | translate}}</div>
            </li>
          </ul>
        <h2 class="passport-edit__performance-short-version-caption
            passport-edit__performance-short-version-section-gap">
          <span>{{'Water Stewardship' | translate}}</span>
          <label class="form-group__label  passport-edit__performance-short-version-caption-question"
                *ngIf="viewMode == VIEW_MODE.DEFAULT"
                mdTooltipPosition="above"
                mdTooltip="{{ 'PASSPORT_C2C_STEWARDSHIP_TOOLTIP' | translate }}">
            <i class="form-group__label--question">?</i>
          </label>
        </h2>

        <ul class="passport-edit__performance-short-version-lifecycle-list">
            <li>
                <md-input-container
                  floatPlaceholder="always"
                  class="passport-edit__performance-short-version-lifecycle-footprint">
                  <input
                    mdInput
                    type="number"
                    [placeholder]="'Production (A1-A3 module)' | translate"
                    autocomplete="off"
                    formControlName="waterConversion">
                  <md-hint class="error-hint" align="start">
                    {{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}
                  </md-hint>
                </md-input-container>
                <div class="passport-edit__performance-short-version-lifecycle-footprint-unit"> m3
                  / {{'functional unit' | translate}}</div>
              </li>
              <li>
                <md-input-container
                  floatPlaceholder="always"
                  class="passport-edit__performance-short-version-lifecycle-footprint">
                  <input
                    mdInput
                    type="number"
                    autocomplete="off"
                    placeholder="{{'End of life stage (C module)' | translate}}"
                    formControlName="waterEndOfLife">
                  <md-hint class="error-hint" align="start">
                    {{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}
                  </md-hint>
                </md-input-container>
                <div class="passport-edit__performance-short-version-lifecycle-footprint-unit"> m3
                  / {{'functional unit' | translate}}</div>
              </li>
            </ul>
        </section>
      </article>
    </div>
  </section>
  <div *ngIf="!isPassportVoid">
    <div class="passport-edit-form__commentaries"  *ngIf="!enableSearch">
      <div class="flex align-center">
        <span class="passport-edit__info--group-label">{{'Commentaries' | translate}}</span>
      </div>
  
      <div class="passport-edit__info--area-fields">
        <div>
          <md-input-container class="textarea-field" floatPlaceholder="never">
                      <textarea
                        id="passportDescriptiom"
                        name="description"
                        mdInput
                        formControlName="passportDescription"
                        placeholder="{{ 'add comments' | translate }}"
                        [disabled]="areFormControlsDisabled"
                        [class.highlighted]="controlStates?.description?.highlighted"
                      ></textarea>
          </md-input-container>
        </div>
      </div>
    </div>
    <section class="form-group passport-edit-form__documents"  *ngIf="!enableSearch" >
      <div class="flex align-center">
  
        <span class="passport-edit__info--group-label">{{'Documents' | translate}}</span>
      </div>
      <documents-uploader
  
        class="form-group__content--row passport__files"
        [isDisabled]="areFormControlsDisabled"
        [files]="uploadedFiles"
        [uploadUrl]="passport?.id && !areFormControlsDisabled ? passport.getFileUploadUrl() : null"
        [service]="passportFileService"
        [uploadUrlSet]="onUpdateUploadUrl$"
        [notShowClickErrors] = "showErrors()"
        [uploadUrlMissingErrorText]="areFormControlsDisabled ? uploadErrorText: ''"
      ></documents-uploader>
    </section>
  </div>
 

  
</form>

