import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformFormatService } from '../services/transform-format.service';
var TransformFormat = /** @class */ (function (_super) {
    tslib_1.__extends(TransformFormat, _super);
    function TransformFormat() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformFormatService;
        return _this;
    }
    TransformFormat.prototype.fields = function () {
        return [
            'id',
            'name',
        ];
    };
    return TransformFormat;
}(ActiveRecord));
export { TransformFormat };
