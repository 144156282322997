import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';

@Injectable()
export class NavbarStateService {
  private multilanguageValue = new BehaviorSubject<boolean>(false);
  public multilanguageObservable$ = this.multilanguageValue.asObservable();

  set multilanguage(value: boolean) {
    localStorage.setItem('multilanguage', value.toString());
    this.multilanguageValue.next(value);
  }

  get multilanguage(): boolean {
    return this.multilanguageValue.getValue();
  }

  constructor() {
    this.multilanguage = localStorage.getItem('multilanguage') === 'true';
  }
}
