/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./deposit-order-transaction-confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material";
import * as i3 from "../../../../shared/dialog-close-button/dialog-close-button.component.ngfactory";
import * as i4 from "../../../../shared/dialog-close-button/dialog-close-button.component";
import * as i5 from "../../partials/deposit-order-confirmation/deposit-order-transaction-confirmation.component.ngfactory";
import * as i6 from "@angular/common";
import * as i7 from "../../../../shared/directives/notification-as-wide-dialog-content.directive";
import * as i8 from "../../../../shared/services/style-loader.service";
import * as i9 from "../../partials/deposit-order-confirmation/deposit-order-transaction-confirmation.component";
import * as i10 from "../../../../entities/ecosystem/services/ecosystem.service";
import * as i11 from "../../../../entities/ecosystem/services/ecosystem-file.service";
import * as i12 from "../../../admin/services/request-file-sender.service";
import * as i13 from "../../../../entities/ecosystem/services/ecosystem-file-junctions.service";
import * as i14 from "../../../ecosystem-manager/services/deposit-order.service";
import * as i15 from "@angular/forms";
import * as i16 from "../../../../shared/services/log-notification.service";
import * as i17 from "@ngx-translate/core";
import * as i18 from "../../../../entities/ecosystem/services/ecosystem-file-to-junction.service";
import * as i19 from "../../../admin/services/request-file.service";
import * as i20 from "./deposit-order-transaction-confirm-dialog.component";
import * as i21 from "../../../../shared/services/confirm-order-dialog.service";
var styles_DepositOrderTransactionConfirmDialogComponent = [i0.styles];
var RenderType_DepositOrderTransactionConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DepositOrderTransactionConfirmDialogComponent, data: {} });
export { RenderType_DepositOrderTransactionConfirmDialogComponent as RenderType_DepositOrderTransactionConfirmDialogComponent };
export function View_DepositOrderTransactionConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "md-dialog-content", [], [[2, "mat-dialog-content", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MdPrefixRejector, [[2, i2.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(2, 16384, null, 0, i2.MdDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "dialog-close-button", [], [[2, "dialog-close-button--position", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mdDialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DialogCloseButtonComponent_0, i3.RenderType_DialogCloseButtonComponent)), i1.ɵdid(4, 49152, null, 0, i4.DialogCloseButtonComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "deposit-order-transaction-confirmation", [["appNotificationAsWideDialogContent", ""], ["customNotificationStyles", "snack-bar-container.error {\n      box-sizing: border-box;\n      height: fit-content;\n      padding: 15px 24px;\n      text-align: center;\n    }\n    snack-bar-container.error .mat-simple-snackbar {\n      justify-content: center;\n    }\n    .mat-simple-snackbar-message{\n      white-space: unset !important;\n      }"]], null, [[null, "onConfirm"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onConfirm" === en)) {
        var pd_0 = (_co.openConfirmation($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.mdDialogRef.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DepositOrderTransactionConfirmationComponent_0, i5.RenderType_DepositOrderTransactionConfirmationComponent)), i1.ɵdid(6, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(7, { "deposit-order-transaction-confirm-dialog--hidden": 0 }), i1.ɵdid(8, 212992, null, 0, i7.NotificationAsWideDialogContentDirective, [i2.MdSnackBar, i2.MdDialogRef, i8.StyleLoaderService], { customNotificationStyles: [0, "customNotificationStyles"] }, null), i1.ɵdid(9, 8503296, null, 0, i9.DepositOrderTransactionConfirmationComponent, [i10.EcosystemService, i11.EcosystemFileService, i12.RequestFileSenderService, i13.EcosystemFileJunctionService, i14.DepositOrderService, i15.FormBuilder, i16.LogNotificationService, i17.TranslateService, i18.EcosystemFileToJunctionService], { id: [0, "id"], step_id: [1, "step_id"], disabled: [2, "disabled"], showESMText: [3, "showESMText"] }, { onConfirm: "onConfirm", onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 7, 0, _co.confirmOrderDialogService.isConfirmDialogOpen); _ck(_v, 6, 0, currVal_2); var currVal_3 = "snack-bar-container.error {\n      box-sizing: border-box;\n      height: fit-content;\n      padding: 15px 24px;\n      text-align: center;\n    }\n    snack-bar-container.error .mat-simple-snackbar {\n      justify-content: center;\n    }\n    .mat-simple-snackbar-message{\n      white-space: unset !important;\n      }"; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.mdDialogData.id; var currVal_5 = _co.mdDialogData.step_id; var currVal_6 = _co.mdDialogData.disabled; var currVal_7 = _co.mdDialogData.showESMText; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).applyClassPosition; _ck(_v, 3, 0, currVal_1); }); }
export function View_DepositOrderTransactionConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "deposit-order-transaction-confirm-dialog", [], null, null, null, View_DepositOrderTransactionConfirmDialogComponent_0, RenderType_DepositOrderTransactionConfirmDialogComponent)), i1.ɵprd(512, null, i12.RequestFileSenderService, i12.RequestFileSenderService, [i19.RequestFileService]), i1.ɵdid(2, 49152, null, 0, i20.DepositOrderTransactionConfirmDialogComponent, [i2.MD_DIALOG_DATA, i2.MdDialogRef, i21.ConfirmOrderDialogService, i12.RequestFileSenderService, i16.LogNotificationService], null, null)], null, null); }
var DepositOrderTransactionConfirmDialogComponentNgFactory = i1.ɵccf("deposit-order-transaction-confirm-dialog", i20.DepositOrderTransactionConfirmDialogComponent, View_DepositOrderTransactionConfirmDialogComponent_Host_0, {}, {}, []);
export { DepositOrderTransactionConfirmDialogComponentNgFactory as DepositOrderTransactionConfirmDialogComponentNgFactory };
