import { Injectable } from '@angular/core';
import { NotificationEntry } from './models/notification-entry.model';
import { CrudService } from '../services/crud.service';
import { Observable } from 'rxjs';
import { Response } from '@angular/http';

@Injectable()
export class NotificationService extends CrudService {
  protected namespace = 'notification';
  protected namespaceSingular = 'notification';
  protected ModelClass = NotificationEntry;


  protected readEndpointUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/read');
  }

  public read(id: number) {
    return this.sendPost(this.readEndpointUrl(id));
  }

  public getUnreadNotificationsCount(role_id : number): Observable<number> {
    return this.sendGet(this.getEndpoint(this.namespaceSingular + '/get-unread-count?role=' + role_id))
      .map((response: Response) => response.json())
      .catch(this.onError.bind(this));
  }

  public getNotifications(role_id: number, page: number):  Observable<any | Response> {
    return this.sendGet(this.getEndpoint(this.namespaceSingular + '?expand=params&role=' + role_id + '&page=' + page))
      .catch(this.onError.bind(this));
  }
  
}
