export const WARNING = {
  RECYCLE_PRECENTAGE_NOT_SET: 1,
  CATEGORY_NULL: 3,
  CATEGORY_PARENT: 4,
  CARBON_PRODUCT_NOT_SET: 5,
  PURCHASE_PRICE_MISSING: 9,
  CONVERSION_FACTOR_MISSING: 10,
  DEPOSIT_MATCH_NOT_RELEVANT: 11,
  CARBON_CONSTRUCTION_NOT_SET : 12,
  CARBON_END_NOT_SET : 13,
  CARBON_USE_NOT_SET : 14,
  CARBON_RECOVERY_NOT_SET : 15,
  USE_PERIOD_NOT_SET : 21
};
export const ERROR = {
  CONVERSION_NOT_SET: 2,
  CONVERSION_FACTOR_FUNCTIONAL_UNIT_NOT_SET: 6,
  DEPOSIT_FAILLING_TO_MATCHE_WITH_GP: 7,
  LACK_FINANCIAL_DATA: 8,
  NO_INFORMATION_PRODUCTION : 16,
  NO_INFORMATION_CONSTRUCTION : 17,
  NO_INFORMATION_EMBODIED : 18,
  NO_INFORMATION_IC_COMPONENT : 19,
  NO_INFORMATION_IC_CONSTRUCTION : 20
};

export const isWarning = (type: number): boolean => !!Object.values(WARNING).includes(type);
export const isError = (type: number): boolean => !!Object.values(ERROR).includes(type);

export const EXCEPTION = {
  ...WARNING,
  ...ERROR
};
