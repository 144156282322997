import { Injectable } from '@angular/core';
import { DepositImport } from '../models/deposit-import.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';

@Injectable()
export class DepositImportService extends ExtendedCrudService {
  protected namespace = 'deposit-imports';
  protected namespaceSingular = 'deposit-import';
  protected ModelClass = DepositImport;
}
