import { Component, OnInit } from '@angular/core';
import { RankingFormulaService } from './ranking-formula.service';
import { IRankingFormula } from './ranking-formula.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'ranking-formula',
  templateUrl: 'ranking-formula.component.html'
})
export class RankingFormulaComponent implements OnInit {
  public formula: IRankingFormula;
  private formulaForm: FormGroup;

  constructor(private _fb: FormBuilder,
              private _rankingFormulaService: RankingFormulaService,) {
  }

  ngOnInit(): void {
    this._rankingFormulaService.getFormula().subscribe((formula: IRankingFormula) => {
      this.formula = formula ? formula : {
        coefficient1: 3,
        coefficient2: 2,
        coefficient3: 2,
        coefficient4: 1,
        exponent1: 3,
        exponent2: 3,
        exponent3: 3,
        exponent4: 2
      };

      this.formulaForm = this._fb.group({
        coefficient1: ['', [Validators.required]],
        coefficient2: ['', [Validators.required]],
        coefficient3: ['', [Validators.required]],
        coefficient4: ['', [Validators.required]],
        exponent1: ['', [Validators.required]],
        exponent2: ['', [Validators.required]],
        exponent3: ['', [Validators.required]],
        exponent4: ['', [Validators.required]],
      });
    });
  }

  save(): void {
    this._rankingFormulaService.updateFormula(this.formula).subscribe(() => {
      this.formulaForm.markAsPristine();
    });
  }
}
