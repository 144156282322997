import {Component, OnInit, ViewChild} from '@angular/core';
import {DepositTag} from '../../models/deposit-tag.class';
import {DepositLocation} from '../../models/deposit-location.class';
import {DepositLocationService} from '../../services/deposit-location.service';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {DepositTagService} from '../../services/deposit-tag.service';
import {Observable} from 'rxjs/Observable';
import {MdDialog} from '@angular/material';
import {ConfirmDialogComponent} from '../../../../shared/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {Utils} from '../../../../shared/utils/utils.class';
import { locationBodyTabToggleAnimation } from './animations/location-body-tab-toggle.animation';

@Component({
  moduleId: module.id,
  selector: 'deposit-tag',
  styleUrls: ['./deposit-tag-list.component.scss'],
  templateUrl: 'deposit-tag-list.component.html',
  animations: [...locationBodyTabToggleAnimation('toggleBodyAnimation', 'toggleBodyContainerAnimation')]
})
export class DepositTagListComponent implements OnInit {
  depositLocation: DepositLocation[] = [];
  isDescriptionShown = true;
  openDepositLocations = {};
  editTag: DepositTag;
  newEditTagName: string;
  sitesPagination = {} as any;
  private queryDepositLocationParams = {expand: 'use_count,tags,tags.deposit_count', agent: 1, pagination: 1, page: 0, assigned: 1 };
  @ViewChild('editTagInput') input;

  constructor(private locationService: DepositLocationService,
              private notification: LogNotificationService,
              private depositTagService: DepositTagService,
              private tagLocationJunctionService: DepositTagService,
              private mdDialog: MdDialog,
              private translate: TranslateService
              ) {}

  ngOnInit() {
    this.updateRecords();
    
  }

  getTagsSorted(location: DepositLocation) {
    return  location.tags ? location.tags.sort(
      (next: DepositTag, previous: DepositTag) => next.editable && !previous.editable ? -1 : 1
    ) : [];
  }

  closeDescription() {
    this.isDescriptionShown = false;
  }

  getAddressWithoutCountryPart(address: string) {
    return this.getCountryAddressAndCountry(address).countryAddress;
  }

  getCountryPart(address: string) {
    return this.getCountryAddressAndCountry(address).country;
  }

  private getCountryAddressAndCountry(address: string): {countryAddress: string, country: string} {
    const obligeCommasInValidAddress = 2;
    if (!address) {
      return {countryAddress: '', country: ''};
    }
    const addressParts = address.split(',');
    if (addressParts.length >= obligeCommasInValidAddress) {
      return {countryAddress: addressParts.slice(0, -1).join(',') + ','
        , country: addressParts[addressParts.length - 1]};
    }
    return {countryAddress: address, country: ''};
  }

  toggleLocation(id: number) {
    this.openDepositLocations[id] = !this.openDepositLocations[id];
  }

  isTagEdited(tagId: number, siteId: number) {
    return this.editTag && this.editTag.id === tagId
      && this.editTag.site_id === siteId;
  }

  removeTag(tag: DepositTag, siteId: number) {
    const message = this.translate.instant(`Are you sure you want delete this tag ?`);
    this.confirmTagChange(message, tag.deposit_count).subscribe(
      (result) => result ? tag.destroy().flatMap(() => this.updateLocation(siteId)).subscribe() : null);
  }

  private confirmTagChange(mainMessage: string, usageNumber: number): Observable<any> {
    const main =  mainMessage;
    const additional = !!usageNumber && !!Number(usageNumber) ?
      this.translate.instant(`It is used N in deposits`, {X: usageNumber}) : '';
    return this.mdDialog.open(ConfirmDialogComponent, { data: {text: `${main} ${additional}`} }
    ).afterClosed();
  }

  updateTag(tag: DepositTag) {
    const message = this.translate.instant(`Are you sure you want edit this tag ?`);
    this.confirmTagChange(message, tag.deposit_count).subscribe((result) => {
      if (result) {
        this.editTag = tag;
        this.newEditTagName = tag.name;
        setTimeout(() => {
            if (!!this.input) {
              this.input.nativeElement.focus();
            }
          }
        ); }
    });
  }

  editInputSize(name: string) {
    const additionalChars = 4;
    return Math.max(this.newEditTagName.length, name.length + additionalChars);
  }
  updateEditTag(oldTag: DepositTag) {
    const copyTag = new DepositTag({...oldTag});
    copyTag.name = this.newEditTagName;
    copyTag.save().do((tag: DepositTag) => {
        Object.assign(oldTag, tag);
    }).subscribe(() => this.editTag = null, () => this.editTag = null);
  }

  resetEdit(oldTag: any) {
    if (oldTag.name === this.newEditTagName) {
      this.editTag = null;
      return;
    }
  }

  copyTagName(name: string) {
    try {
      Utils.copyToClipBoard(name);
      this.notification.success('Tag name copied', 2000, true);
    } catch (e) {
      this.notification.error('Tag name not yet set', 2000, true);
    }
  }

  createNewTag(targetInput: HTMLInputElement, siteId: number) {
    const value = targetInput.value;
    if (!value) {
      return;
    }
    this.isLoadingNewTag(true, targetInput);
    this.createDepositTag(value, siteId)
      .flatMap(() => this.updateLocation(siteId))
      .subscribe(() => this.isLoadingNewTag(false, targetInput),
        () => {
          targetInput.disabled = false;
          targetInput.focus();
        });
  }

  private isLoadingNewTag(loading: boolean, input: HTMLInputElement) {
    if (loading) {
      input.disabled = true;
    } else {
      input.value = '';
      input.disabled = false;
      input.focus();
    }
  }

  private createDepositTag(name: string, site_id: number): Observable<DepositTag> {
    const depositTag = new DepositTag({name, site_id});
    return depositTag.save();
  }

  private updateLocation(siteId: number) {
    const index = this.depositLocation.findIndex(location => location.id === siteId);
    return this.depositLocation[index].load(this.queryDepositLocationParams)
      .do((location: DepositLocation) => Object.assign(this.depositLocation[index], location));
  }

  pageChange(page: number) {
    this.queryDepositLocationParams.page = page;
    this.sitesPagination.currentPage = page;
    this.updateRecords();
  }

  updateRecords() {
    this.locationService
    .getWithPaginationData(this.queryDepositLocationParams)
    .subscribe((res) => {
      const headers = res.headers;
      this.sitesPagination.currentPage = Number(headers.get('X-Pagination-Current-Page')) || 1,
      this.sitesPagination.pageCount = Number(headers.get('X-Pagination-Page-Count')) || 1,
      this.sitesPagination.perPage = Number(headers.get('X-Pagination-Per-Page')) || res.json().length,
      this.sitesPagination.totalCount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length
      this.depositLocation = res.json().map((dl) => new DepositLocation(dl));

    })
  }
}
