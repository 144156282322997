import {Component, Inject} from '@angular/core';
import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';
import {ImportSuccessReport} from '../../models/import-success-summary.class';
import {IConfirmDialogIconData} from '../../../../shared/confirm-dialog/values/interface/confirm-dialog-data.interface';
import {EConfirmDialogIcon} from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import {TranslateService} from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  templateUrl: 'deposit-import-success.component.html',
  styleUrls: ['deposit-import-success.component.scss'],
})
export class DepositImportSuccessComponent {

  report: ImportSuccessReport;
  iconLogo: IConfirmDialogIconData = {
    iconType: EConfirmDialogIcon.SVG,
    showIconURL: 'assets/svg/done_import_circle_icon.svg',
    iconClass: 'deposit-import-success__logo'
  };
  iconCreate: IConfirmDialogIconData = {
    iconType: EConfirmDialogIcon.MD,
    showIconText: 'add',
    primaryFill: false,
    iconClass: 'deposit-import-success__row-left-part background-primary-color'
  };
  iconUpdated: IConfirmDialogIconData = {
    iconType: EConfirmDialogIcon.MD,
    showIconText: 'cached',
    primaryFill: false,
    iconClass: 'deposit-import-success__row-left-part deposit-import-success__row-left-part--rotate-s background-primary-color'
  };
  iconWarning: IConfirmDialogIconData = {
    iconType: EConfirmDialogIcon.SVG,
    showIconURL: 'assets/svg/warning--icon.svg',
    iconClass: 'deposit-import-success__row-left-part'
  };
  constructor(public mdDialogRef: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any,
    private translateService: TranslateService
  ) {
    this.report = mdDialogData.report || new ImportSuccessReport();
    console.log(this.report);
  }

  public getNumber(text: any) {
    return Number(text);
  }

  private translate(text, data= {}) {
    return this.translateService.instant(text, data);
  }

  get passportCreateText() {
    switch (this.report.passportsCreated.length) {
      case 0: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_PASSPORT_CREATED_NONE');
      case 1: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_PASSPORT_CREATED_ONE');
      default: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_PASSPORT_CREATED', this.report.passportsCreated.length);
    }
  }

  get depositCreateText() {
    switch (this.report.depositsCreated.length) {
      case 0: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_CREATED_NONE');
      case 1: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_CREATED_ONE');
      default: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_CREATED', {X: this.report.depositsCreated.length});
    }
  }

  get depositUpdatedText() {
    switch (this.report.depositsUpdated.length) {
      case 0: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_UPDATED_NONE');
      case 1: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_UPDATED_ONE');
      default: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_UPDATED', {X: this.report.depositsUpdated.length});
    }
  }

  get depositWithVoidCreatedText() {
    switch (this.report.depositsVoided.length) {
      case 1: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_VOIDED_ONE');
      default: return this.translate('IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_VOIDED', {X: this.report.depositsVoided.length});
    }
  }
}
