import * as tslib_1 from "tslib";
import { TRANSFORM_TYPE } from '../../../transformation-manager/values/transform-types.const';
import { ECOSYSTEM_CALC_WATER_ERRORS } from '../../values/ecosystem-calc-error.const';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { AbstractReportStrategyWithDetails } from './abstract-report-strategy.service';
var WaterReportStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(WaterReportStrategy, _super);
    function WaterReportStrategy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileType = ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_WATER;
        _this.unit = 'L';
        return _this;
    }
    WaterReportStrategy.prototype.getSteps = function (allSteps) {
        return {
            transforms: this.waterTransformSteps(allSteps.transforms),
            needs: allSteps.needs,
            deposits: allSteps.deposits,
        };
    };
    WaterReportStrategy.prototype.waterTransformSteps = function (transforms) {
        return transforms.filter(function (step) { return [TRANSFORM_TYPE.SORTING,
            TRANSFORM_TYPE.UPCYCLING,
            TRANSFORM_TYPE.METHANIZATION,
            TRANSFORM_TYPE.COMPOSTING].includes(step.transformation_type); });
    };
    WaterReportStrategy.prototype.formatValue = function (step) {
        if (Math.abs(step.water_consumption) < 1000) {
            return step.water_consumption.toPrecision(8);
        }
        else {
            return Math.round(step.water_consumption).toString();
        }
    };
    WaterReportStrategy.prototype.isDataMissing = function (errors, step) {
        if (!errors || !Array.isArray(errors) || this.isWaterFieldNumber(step)) {
            return true;
        }
        var found = null;
        var _loop_1 = function (i) {
            found = ECOSYSTEM_CALC_WATER_ERRORS.find(function (element) { return element.id === errors[i]; });
            if (found) {
                return "break";
            }
        };
        for (var i = 0; i < errors.length; i++) {
            var state_1 = _loop_1(i);
            if (state_1 === "break")
                break;
        }
        return !!found;
    };
    WaterReportStrategy.prototype.isWaterFieldNumber = function (step) {
        return typeof step.water_consumption !== 'number' || Number.isNaN(step.water_consumption);
    };
    WaterReportStrategy.prototype.otherAdd = function (ecosystem, value) {
        this.setOtherTotal(value, function (val) { return ecosystem.add_other_water = val; });
        return ecosystem.add_other_water;
    };
    WaterReportStrategy.prototype.otherSubtract = function (ecosystem, value) {
        this.setOtherTotal(value, function (val) { return ecosystem.subtract_other_water = val; });
        return ecosystem.subtract_other_water;
    };
    WaterReportStrategy.prototype.otherDescription = function (ecosystem, value) {
        this.setOtherDescription(value, function (val) { return ecosystem.description_other_water = val; });
        return ecosystem.description_other_water;
    };
    WaterReportStrategy.prototype.getTotalErrors = function (ecosystem) {
        return ecosystem.water_total ? ecosystem.water_total.errors : [null];
    };
    return WaterReportStrategy;
}(AbstractReportStrategyWithDetails));
export { WaterReportStrategy };
