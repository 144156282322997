import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ConversionStateService} from '../../services/conversionState.service';
import {IConversion} from '../../interfaces/conversion-state.interface';

@Component({
  moduleId: module.id,
  selector: 'convert-button',
  templateUrl: 'convert-button.component.html',
  styleUrls: ['convert-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ConvertButtonComponent implements OnInit {
  @Input() identifier = "";
  @Input() isDefaultActive = true;
  @Input() isDisabled: boolean;
  @Input() setToggleColorActive: boolean;
  @Output() toggle = new EventEmitter;
  private conversion: IConversion;

  constructor(
    private _translate: TranslateService,
    private conversionStateService: ConversionStateService,
  ) {}

  ngOnInit() {
    if (this.identifier) {
        this.conversion = this.conversionStateService.getOrCreateConversionState(this.identifier);
    } else {
      this.conversion = {active: this.isDisabled ? false : this.isDefaultActive};
    }
  }

  getConversionTooltip(): string{
    if(!this.conversion.active){
      return this._translate.instant('Activate the conversion factor');
    }
    if(this.conversion.active && !this.isDisabled){
      if(this._translate.currentLang === 'en'){
        return 'Deactivate the conversion factor';
      } else {
        return this._translate.instant('Deactivate the conversion factor');
      }
    }
  }

  getConversionStatus(){
    return this.conversion.active || this.setToggleColorActive;
  }

  toggleConversion() {
    if(!this.isDisabled){
      this.conversion.active = !this.conversion.active;
      this.toggle.emit(this.conversion.active);
    }
   }
}
