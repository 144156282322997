/**
 * Created by Aleksandr C. on 6.02.17.
 */
import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MdDialog } from '@angular/material';
import { Deposit } from '../../../models/deposit.class';
import { DepositLocation } from '../../../models/deposit-location.class';
import 'rxjs/add/operator/merge';
import { MapDialogComponent } from '../../../dialogs/view-map/view-map.component';
import { DepositLocationService } from '../../../services/deposit-location.service';
import { ICrud } from '../../../../../shared/interfaces';
import { IParentEvent } from '../../../../../shared/interfaces/parent-event.interface';
import { MapsAPILoader } from '@agm/core';
import { LOCATE_ME_INACCURATE_WARNING_DISTANCE } from '../../../values/locate-me-inaccurate-warning-distance.const';
import { DepositSiteDetailsDialogComponent } from '../../../../../entities/deposit/dialogs/deposit-site-details-dialog/deposit-site-details-dialog.component';
import { OrganisationTIds } from '../../../../../shared/values/company-values.enum';
import { DepositControlStates } from '../../../models/deposit-control-states.class';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import {TranslatePipe} from '@ngx-translate/core';
import {DepositTagLocationJunctionService} from '../../../services/deposit-tag-location-junction.service';
import {DepositTagLocationJunction} from '../../../models/deposit-tag-location-junction.class';
import {DepositTag} from '../../../models/deposit-tag.class';
import { PermissionService } from '../../../../../shared/services/permission.service';
import { EAgentPermission } from '../../../../../dashboards/agent/values/agent-permission.enum';

@Component({
  moduleId: module.id,
  selector: 'location-step',
  templateUrl: 'location-step.component.html',
  styleUrls: ['location-step.component.scss'],
})

export class LocationStepComponent implements OnInit, OnDestroy {
  // @Input() public asDialog?: boolean = false;
  @Input() deposit: Deposit;
  @Input() controlStates: DepositControlStates;
  @Input() listener: EventEmitter<IParentEvent>;
  //
  // @Output() onSave: EventEmitter<any> = new EventEmitter();
  // @Output() onMap: EventEmitter<any> = new EventEmitter();
  // @Output() public onChange: EventEmitter<any> = new EventEmitter();
  // @Output() onFinish: EventEmitter<any> = new EventEmitter();

  service: ICrud;
  // locations: any[];
  depositLocation: DepositLocation;
  // depositLocationForm:FormGroup;
  tagSearch: string;
  filteredTags = [];
  chosenTagJunctions = [];
  isSearchActive = false;
  isTagsLoading = false;


  private geocoder: any;
  private availableTags = [];
  @ViewChild('search') tagInput: ElementRef;

  constructor(public dialog: MdDialog,
              private _permission: PermissionService,
              private locationService: DepositLocationService,
              private mapsAPILoader: MapsAPILoader,
              private translatePipe: TranslatePipe,
              public notification: LogNotificationService,
              private tagLocationJunctionService: DepositTagLocationJunctionService) {
    this.service = locationService;
  }

  ngOnInit() {
    this.deposit.onChangeLocation
    // .switchMap((client:Client) => this.locationService.get<ClientLocation>({client_id: client.id}))
      .subscribe((location: DepositLocation) => {
        this.depositLocation = location;
        this.updateAvailableSites();
      });

    if (this.deposit.deposit_location_id > 0) {
      if (this.deposit.deposit_location && this.deposit.deposit_location.tags) {
        this.depositLocation = this.deposit.deposit_location;
        this.updateAvailableSites();
      } else {
        this.depositLocation = new DepositLocation({id: this.deposit.deposit_location_id});
        this.depositLocation.load({expand: 'tags'}).subscribe((res) => {
          this.deposit.setLocation(new DepositLocation(res));
        });
      }
      this.tagLocationJunctionService.get({deposit_id: this.deposit.id}).subscribe(
        (tagJunctions: DepositTagLocationJunction[]) => {
          this.chosenTagJunctions = tagJunctions;
          this.updateAvailableSites();
        }
      );
    } else {
      this.deposit.setLocation(new DepositLocation());
    }
    /*this.deposit.beforeSave$.subscribe(() => {
      if (this.depositLocation.name) {
        this.saveDepositLocation();
      }
    });*/
  
    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if (event.key === 'changeDeposit') {
          this.deposit = event.data;
        }
      });
    }
    this.initGeocoder();
  }

  ngOnDestroy(): void {
    if (!!this.depositLocation.name) {
      this.saveDepositLocation();
    }
  }

  private updateAvailableSites() {
    const chosenIds = new Set(this.chosenTagJunctions.map(item => item.tag_id));
    this.availableTags = this.depositLocation.tags ? this.depositLocation.tags.filter(item => !chosenIds.has(item.id)) : [];
    this.filteredTags = this.availableTags;
  }

  private saveDepositLocation() {
    // if(locationForm.dirty===true){}
    // this.deposit.deposit_location=this.depositLocation;
    this.deposit.deposit_location.save().subscribe(
      // (res) => {
      //  console.log('RES', res, 'Deposit Location', this.deposit.deposit_location);
      //  this.onSaveLocation(res);
      //  this.deposit.setLocation(res);
      //  this.onSave.emit(new DepositLocation(res));
      // }
    );
  }

  private initGeocoder() {
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder;
    }, console.error);
  }
  get isRestrictedAgent() {
    return this._permission.hasAppliedPermission(EAgentPermission.RESTRICTED_AGENT);
  }


  onSelect(location: DepositLocation) {
    this.onSaveLocation(location);
    // this.onSave.emit(this.depositLocation);
  }

  onCreate() {
    this.dialog.open(
      DepositSiteDetailsDialogComponent,
      {data: {type: OrganisationTIds.Territory}}
    ).afterClosed().subscribe((location) => this.onSaveLocation(location));
  }

  private onSaveLocation(depositLocation: DepositLocation
                 , backup?: {location: DepositLocation, junction: DepositTagLocationJunction[]}) {
  //  console.log('depositLocation', depositLocation);
    this.chosenTagJunctions = this.deposit.deposit_location.id === depositLocation.id ? this.chosenTagJunctions : [];
    this.deposit.setLocation(depositLocation);
    this.depositLocation = depositLocation;
    this.tryToUpdateDepositIfTagsChanged(backup);
    // this.mode = 'view';
  }

  private tryToUpdateDepositIfTagsChanged(backup?: {location: DepositLocation, junction: DepositTagLocationJunction[]}) {
    if (!this.isTagsLoading) {
      this.isTagsLoading = true;
      backup = backup || this.createBackup();
      this.deposit.save().subscribe(() => {
        this.isTagsLoading = false;
      }, () => {
        this.isTagsLoading = false;
        this.restoreLocation(backup);
      });
    }
  }

  private createBackup() {
    return {
      location: this.depositLocation,
      junction: this.chosenTagJunctions
    };
  }

  private restoreLocation(backup: {location: DepositLocation, junction: DepositTagLocationJunction[]}) {
    this.depositLocation = backup.location;
    this.chosenTagJunctions = backup.junction;
  }

  locateMe() {
    navigator.geolocation.getCurrentPosition((position) => {
        const coords = position.coords;
        if (coords.accuracy > LOCATE_ME_INACCURATE_WARNING_DISTANCE) {
          this.notification.error(this.translatePipe.transform('YOUR_LOCATION', {X: coords.accuracy}));
        }

        this.depositLocation.lat = coords.latitude;
        this.depositLocation.lng = coords.longitude;

        if (!this.geocoder) {
          return;
        }

        this.geocoder.geocode({
          'location': {
            lat: coords.latitude,
            lng: coords.longitude,
          }
        }, (results: any[], status: string) => {
          if (status === 'OK') {
            const result = results[0];
            if (result) {
              this.depositLocation.address = result.formatted_address;
            }
          } else {
            console.error('Geocoder failed due to: ' + status);
          }
        });
      },
      (error) => {
        console.error(error);
        this.notification.error(error.message, null, true);
      },
      {
        enableHighAccuracy: true
      }
    );
  }

  viewMap() {
    const dialogRef = this.dialog.open(MapDialogComponent, {
      data: {
        coords: {
          lat: this.depositLocation.lat,
          lng: this.depositLocation.lng,
        },
        address: this.depositLocation.address,
      }
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (!data) {
        return;
      }

      if (data.depositLocation) {
        this.deposit.setLocation(data.depositLocation);
      } else if (data.coords && data.coords.lat && data.coords.lng && data.address !== undefined) {
        this.depositLocation.lat = data.coords.lat;
        this.depositLocation.lng = data.coords.lng;
        this.depositLocation.address = data.address;
      }
    });
  }

  onChangeName(name: string) {
    if (this.depositLocation && this.depositLocation.name !== name) {
      if (this.isRestrictedAgent) {
        this.onResetLocation(name);
      } else {
        this.onEditLocationName(name);
      }
    }
  }

  private onEditLocationName(name: string) {
      this.depositLocation.name = name;
      this.deposit.onChangeLocation.emit(this.depositLocation);
  }

  private onResetLocation(name: string) {
    this.deposit.onChangeLocation.emit(new DepositLocation({name}));
  }

  onPlaceChange(place: google.maps.places.PlaceResult) {
    if (place.geometry) {
      this.depositLocation.lat = place.geometry.location.lat();
      this.depositLocation.lng = place.geometry.location.lng();
    } else {
      this.depositLocation.lat = null;
      this.depositLocation.lng = null;
    }
  }

  closeTag(tag: DepositTagLocationJunction) {
    tag.destroy().subscribe(() => {
        this.chosenTagJunctions = this.chosenTagJunctions.filter(item => tag.id !== item.id);
        this.updateAvailableSites();
      }
    );
  }

  openSuggestion() {
    this.isSearchActive = true;
    setTimeout(() => this.tagInput.nativeElement.focus());
  }

  filterTagSuggestion() {
    this.filteredTags = this.availableTags.filter((item) => item['name'].toLocaleLowerCase()
      .indexOf(this.tagSearch.toLocaleLowerCase()) !== -1);
  }

  resetTagSearch() {
    this.tagSearch = '';
    this.isSearchActive = false;
    this.filteredTags = this.availableTags;
  }

  addTag(tag: DepositTag) {
    if (!this.isTagsLoading) {
      const current = this.depositLocation;
      new DepositTagLocationJunction({tag_id: tag.id, deposit_id: this.deposit.id}).save().subscribe(
        (savedJunction: DepositTagLocationJunction) => {
          if (!this.isTagsLoading && this.depositLocation.id === current.id) {
            this.chosenTagJunctions.push(savedJunction);
            this.updateAvailableSites();
          }
        });
      this.resetTagSearch();
    }
  }
}
