import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PassportSynchronizationData } from '../../../entities/passport/models/passport-synchronization-data.class';

/**
 * A component for filtering by EPD (Environmental Product Declarations) type.
 * It allows users to select one or more EPD types from a predefined list and emits the selection.
 */
@Component({
  selector: 'app-epd-type-filter',
  templateUrl: './epd-type-filter.component.html',
  styleUrls: ['./epd-type-filter.component.scss']
})
export class EpdTypeFilterComponent {
  @Input() epdTypeSelected: number[] = [];
  @Output() epdTypeChange = new EventEmitter<number[]>();
  epdTypes: string[];

  constructor() {
    // Initialize the list of EPD types using a static method from the PassportSynchronizationData class.
    this.epdTypes = PassportSynchronizationData.getEpdTypesValueList();
  }

  transformEpdTypeSelected(): string[] {
    return this.epdTypeSelected.map((epdType) => PassportSynchronizationData.getEpdTypeValue(epdType));
  }

  /**
   * Handles the change in EPD type selection.
   * @param selectedEpdTypes The array of selected EPD types.
   */
  handleSelectionChange(selectedEpdTypes: string[]) {
    let _epdTypes = selectedEpdTypes.map(epdType => PassportSynchronizationData.getEpdTypeKey(epdType));
    this.epdTypeChange.emit(_epdTypes);
  }
}
