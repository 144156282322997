import * as tslib_1 from "tslib";
import { Eula } from '../../shared/models/eula.class';
import { ExtendedCrudService } from '../../shared/services/extended-crud.service';
var EulaService = /** @class */ (function (_super) {
    tslib_1.__extends(EulaService, _super);
    function EulaService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'eulas';
        _this.namespaceSingular = 'eula';
        _this.ModelClass = Eula;
        return _this;
    }
    EulaService.prototype.getMostRecent = function () {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + '/most-recent'))
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    EulaService.prototype.accept = function (data) {
        return this.sendPost(this.getEndpoint(this.namespaceSingular + '/accept-last'), data)
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    return EulaService;
}(ExtendedCrudService));
export { EulaService };
