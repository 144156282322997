/**
 * Created by aleksandr on 20.06.17.
 */
import { Component } from '@angular/core';
import { Need } from '../../../../entities/need/models/need.class';
import { MdDialogRef } from '@angular/material';
@Component({
  moduleId: module.id,
  selector: 'need-picker-dialog',
  templateUrl: 'esm-need-picker-dialog.component.html'
})
export class EsmNeedPickerDialogComponent {
  public records: Need[] = [];

  constructor(private _dialog: MdDialogRef<any>) {

  }

  selectValue(result: Need) {
    this._dialog.close(result);
  }
}
