import { ActiveRecord } from '../../../shared/models/active-record.class';
import { CategoryService } from '../service/category.service';
import {TranslateService} from "@ngx-translate/core";
import { ServiceLocator } from '../../../shared/services/service-locator';

export class CategoryItem extends ActiveRecord {

  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  protected provider: any = CategoryService;
  id: number;
  name: string;
  parent_id: number;
  search_count: number;
  children: CategoryItem[];
  expanded: boolean = false;

  fields() {
    return ['id', 'name', 'parent_id', 'depth', 'selectable', 'has_custom', 'children'];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    if(json.name){
      if(!this.translateService){
      this.translateService= ServiceLocator.injector.get(TranslateService);
      }
      json.name=this.translateService.instant(json.name);
     }

    if (json.children) {
      let array: CategoryItem[] = [];
      for (let child of json.children) {
        let newchild = new CategoryItem(child);
        array.push(newchild);
      }
      json.children = array;
    }
    return json;
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
