/**
 * Created by aleksandr on 7.08.17.
 */
import { Injectable } from '@angular/core';
import { CrudService } from '../services/crud.service';
import { ChatRoom } from './models/chat-room.model';
import { ISendMessageResponse } from './interfaces/chat-room.interface';
import { Observable } from 'rxjs/Observable';
import { IChatHistoryRecord } from './interfaces/chat-message.interface';
import { Response } from '@angular/http';
import { ChatUser } from './models/chat-user.model';
import { ChatMessage } from './models/chat-message.model';
import { ILiteral } from 'ng2-adn-common';
import { IChatContact } from './interfaces/chat-user.interface';
import { ERoleIds } from '../values/role-ids.enum';
import 'rxjs/add/observable/empty'


@Injectable()
export class ThreadApiService extends CrudService {

  protected namespace = 'thread';
  protected namespaceSingular = 'thread';
  protected ModelClass = ChatRoom;

  getContextThreads(object_id: number, object_type: number): Observable<ChatRoom[]> {
    return this.get<ChatRoom>({object_id, object_type});
  }

  getRoomParticipants(threadId: number): Observable<ChatUser[]> {
    return this
      .sendGet(this.getEndpoint(this.namespace + '/' + threadId + '/contacts'))
      .map((response: Response) => response.json())
      .map((contacs: IChatContact[]) => contacs.map((contact: IChatContact) => new ChatUser({
        id: contact.id,
        status: contact.status,
        name: contact.username,
        image: contact.avatar_url
      })));
  }

  getContacts(roleId: number): Observable<ChatUser[]> {
    return this
      .sendGet(this.getEndpoint('contact'), {search: {role: roleId}})
      .map((response: Response) => response.json())
      .map((contacs: ILiteral[]) => contacs.map((contact) => new ChatUser(contact)));
  }

  getPrivateRoom(userId: number | string): Observable<ChatRoom> {
    return this.get({user_id: userId}).map((records: ChatRoom[]) => {
      if (records.length > 0) {
        console.log('RECEIVED ROOM:', records[0]);
        return new ChatRoom(records[0]);
      } else {
        console.log('RECEIVED ROOM OF NOTHING');
        return null;
      }
    });
  }

  getContextRoom(threadId: number): Observable<ChatRoom> {
    return this.view(threadId);
  }

  loadHistory(id: number): Observable<ChatMessage[]> {
    return this
      .sendGet(this.getEndpoint('thread/' + id + '/history'), {search: {expand: 'content,file'}})
      .map((response: Response) => {
        console.log(response.json());
        return response.json().map((item: IChatHistoryRecord) => ChatMessage.create(item));
      });
  }

  loadHistoryMessage(threadId: number, messageId: number): Observable<ChatMessage> {
    return this
      .sendGet(this.getEndpoint(`thread/${threadId}/history/${messageId}`), {search: {expand: 'content'}})
      .map((response: Response) => ChatMessage.create(response.json()));
  }

  sendMessage(id: number, text: string): Observable<ISendMessageResponse> {
    let data = {
      content: text,
      type: 1
    };

    return this.sendPost(this.getEndpoint('thread/' + id + '/message'), data)
      .map((result: any) => {
        return result.json();
      })
      .catch(this.onError.bind(this));
  }

  readThread(id: number) {
    return this.sendPut(this.getEndpoint('thread/' + id + '/read'))
      //.map((result: any) => result.json());
  }

  getFileUploadUrl(threadId: number) {
    return this.getEndpoint(this.singularEndpoint + '/' + threadId + '/files');
  }

  getSupportContact(roleId: number): Observable<ChatUser> {
    if (roleId === ERoleIds.SUPERADMIN) {
      return Observable.empty();
    }
    return this
      .sendGet(this.getEndpoint(this.singularEndpoint + '?support'))
      .map((response: Response) => response.json())
      .map((contacts: ILiteral[]) => {
        let support = new ChatUser(contacts.shift());
        support.name = 'Upcyclea representative';
        support.image = '../assets/png/representative.png';
        return support;
      });
  }
}
