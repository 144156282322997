/**
 * Created by aleksandr on 8.08.17.
 */
import * as tslib_1 from "tslib";
import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
import { PassportService } from '../services/passport.service';
var PassportResolver = /** @class */ (function (_super) {
    tslib_1.__extends(PassportResolver, _super);
    function PassportResolver(_service) {
        var _this = _super.call(this) || this;
        _this._service = _service;
        _this.query = {
            expand: 'price,custom_made_name,composite_name,catalogue_product_name,rating,user.roles,user.id,category,distribution_areas.country,gtins,passport_synchronization_data'
        };
        return _this;
    }
    return PassportResolver;
}(EntityResolver));
export { PassportResolver };
