import * as i0 from "@angular/core";
var PaginationUnmarshallingService = /** @class */ (function () {
    function PaginationUnmarshallingService() {
    }
    PaginationUnmarshallingService.prototype.unmarshall = function (response) {
        var headers = response.headers;
        return {
            currentPage: Number(headers.get('X-Pagination-Current-Page')) || 1,
            pageCount: Number(headers.get('X-Pagination-Page-Count')) || 1,
            perPage: Number(headers.get('X-Pagination-Per-Page')) || response.json().length,
            totalCount: Number(headers.get('X-Pagination-Total-Count')) || response.json().length
        };
    };
    PaginationUnmarshallingService.ngInjectableDef = i0.defineInjectable({ factory: function PaginationUnmarshallingService_Factory() { return new PaginationUnmarshallingService(); }, token: PaginationUnmarshallingService, providedIn: "root" });
    return PaginationUnmarshallingService;
}());
export { PaginationUnmarshallingService };
