<article class="ecosystem-card" (click)="onFolderClick()">
    <div class="ecosystem-card__frontside" >
      <div class="ecosystem-card__folder-header" >
        <div class="ecosystem-card__folder-name">
          
        </div>
        <div class="ecosystem-card__folder-checkbox">
          <md-checkbox class="ecosystem-card__folder-checkbox-inner" (click)="onCheckbox($event)" (change)="onCheckboxChange($event.checked)" [(ngModel)]="folder.checked">
          </md-checkbox>
        </div>
      </div>
        <header class="ecosystem-card__header-folder">
            <div class="ecosystem-card__header-title">
              <div class="ecosystem-card__folder-subtitle" >{{getFolderDisplayName(50) }} </div>
            </div>
          </header>
        <div class="ecosystem-card__folder">
          <div class="ecosystem-card__folder-text">
            <div class="ecosystem-card__folder-label">{{ folder.es_count_display}}</div>
            <div class="ecosystem-card__folder-progress">
                {{ 'Ecosystems' | translate}}
            </div>
          </div>
        </div>
        <ul class="ecosystem-card__footer">
            <li class="ecosystem-card__footer-data">
                <div class="ecosystem-card__footer-left">
                <div class="ecosystem-card__footer-group">
                    <span class="ecosystem-card__footer-title">{{ 'Created' | translate }}:</span>
                    <span class="ecosystem-card__footer-name">{{ folder.created_at | date: 'dd.MM.yyyy' }}</span>
                </div>
                </div>
                <div class="ecosystem-card__footer-right">
                <div class="ecosystem-card__footer-group">
                    <span class="ecosystem-card__footer-title">{{ 'Modified' | translate }}:</span>
                    <span class="ecosystem-card__footer-name">{{ folder.updated_at | date: 'dd.MM.yyyy' }}</span>
                </div>
                </div>
            </li>
        </ul>
      </div>
      
</article>
  