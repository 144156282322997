import { Component, Input,EventEmitter,Optional, Output } from '@angular/core';
import { IParentEvent } from '../../../../shared/interfaces/parent-event.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AffiliateBranchService } from '../../services/affiliateBranch.service';
import { AffiliateBranch } from '../../models/affiliateBranch.class';
import { Passport } from '../../models/passport.class';
import { MdDialog } from '@angular/material';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import {PassportCustomStoreService} from '../../services/passport-custom-store.service';
import { Subscription } from 'rxjs/Subscription';
import {forkJoinSafe} from '../../../../shared/helpers/fork-join-safe.helper';
import {Observable} from 'rxjs/Observable';
import { NextUsage } from '../../models/next-usage.class';
import { PASSPORT_REQUEST } from '../../values/passport-request.const';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import { NextUsageService } from '../../services/next-usage.service';
@Component({
    moduleId: module.id,
    selector: 'passport-affiliate-branch',
    templateUrl: 'passport-affiliate-branch.component.html'
  })

  export class PassportAffiliateBranchComponent  {

    @Input() parentFormGroup: FormGroup;
    @Input() public passport?: Passport;
    @Input() public nextUsage?: NextUsage;
    @Input() public isDisabled?: boolean = false;
    @Input() public listener?: EventEmitter<IParentEvent>;
    @Input() public viewMode: number;
    @Input() public affiliateBranchListener: EventEmitter<any>;
    @Output() onSave = new EventEmitter();
    loaded: boolean;
    listDescription: FormArray;
    disableStatus: boolean = false;
    subscriptions: Subscription[] = [];
    VIEW_MODE = VIEW_MODE;
    nextUsageId: any;
    
    constructor(public _fb: FormBuilder,
      public dialog: MdDialog, 
      @Optional() private passportChildComponentsEventListenerService: PassportCustomStoreService,
      @Optional() public readOnlyService: ReadOnlyService,
      public affiliateBranchService: AffiliateBranchService,
      public usageService: NextUsageService
      ) {
}

    ngOnInit(){
      this.disableStatus = this.isDisabled;

      this.affiliateBranchListener.subscribe(({data,key}) => {

        switch(key) {
          case this.usageService.AFFILIATE_BRANCH_EVENT.SAVE:
            this.nextUsage = data;
            this.save().subscribe((e) => {
              console.log(e);
            })
            break;
        }
      })
    
      if (this.passport) {
      this.changePassport(this.passport).updateAffiliateBranch();
    }
      if (this.listener) {
        this.listener.subscribe((event: IParentEvent) => {
          if (event.key === 'changePassport') {
            this.changePassport(event.data).updateAffiliateBranch();
          }
          if (event.key === 'disableStatus') {
            this.disableStatus = event.data.isDisabled;
            this.updateDisableStatus();
          }
          if (event.key === 'saveNextUsage') {
            this.nextUsage = event.data;
            this.onSave.emit({type: PASSPORT_REQUEST.AFFILIATE_BRANCH, request: this.save()});
            
          }
        });
      }
      
     
    }

    save() {
      
      if (this.nextUsage && this.nextUsage.id) {
        const requests = this.listDescription.controls.map((control) => this.getAffiliateBranch(<FormArray>control));
        return forkJoinSafe<AffiliateBranch>(requests);
      }
    }

    getAffiliateBranch(affiliateBranchCtrl: FormArray): Observable<any> {
      const affiliateBranch = this.getAffiliateBranchFromForm(affiliateBranchCtrl);
      if (!!affiliateBranch) {
        return affiliateBranch.save().map(v => {
          if (affiliateBranchCtrl.get('id') && !!v.id) {
            affiliateBranchCtrl.get('id').setValue(v.id);
          }
          return v;
        });
      }
    }
  
    getAffiliateBranchFromForm(form: FormArray): AffiliateBranch {
      if(!form) {
        return null;
      }
  
      let id = form.get('id') ? form.get('id').value : null;
      let description = form.get('description') ? form.get('description').value : null;
      let stakeholder = form.get('stakeholder') ? form.get('stakeholder').value : null;
  
      let affiliateBranch: AffiliateBranch = new AffiliateBranch({
        id: id,
        description: description,
        stakeholder: stakeholder,
        next_usage_id: this.nextUsage.id ? this.nextUsage.id : null
      });
  
      return affiliateBranch;
    }

    updateDisableStatus(): void {
      if (!this.listDescription) {
        this.listDescription = this._fb.array([]);
      }
      if (this.disableStatus) {
        this.listDescription.controls.forEach((control) => control.disable());
      } else {
        this.listDescription.controls.forEach((control) => control.enable());
      }
    }

    updateAffiliateBranch(): void {
      this.listDescription = this._fb.array([]);

      if(this.nextUsage.affiliate_branch.length === 0) {
        this.addNew();
      }
      let Counter = 0
      this.nextUsage.affiliate_branch.forEach((usage) =>{
        this.addForm(usage)
        Counter++;
        if(Counter === this.nextUsage.affiliate_branch.length){
          this.loaded = true;
        }
      } );
      this.updateDisableStatus();
  
      if(this.parentFormGroup) {
        this.parentFormGroup.setControl('nextUsages', this.listDescription);
      }
  
      if (this.passportChildComponentsEventListenerService && !this.loaded) {
        setTimeout(() => {
          this.passportChildComponentsEventListenerService
          .addFormProgrammaticlyAction.emit({controlName: 'affiliate_branch', control: this.listDescription});
        }
        );
      }
    }

    changePassport(passport: Passport): this {
      this.passport = passport;
      return this;
    }

    addNew(): void {
       if (this.isDisabled) {
      return;
    }
      let affiliate_branch = new AffiliateBranch({});
      this.addForm(affiliate_branch);
    }

    addForm(affiliateBranch: AffiliateBranch):void {
      let formGroup = this._fb.group({
        id: [affiliateBranch.id] ,
        description: [affiliateBranch.description],
        stakeholder: [affiliateBranch.stakeholder],
      })
      this.listDescription.push(formGroup);
    }
    
    removeAffiliateBranch(index: number): void{
       if (this.isDisabled) {
      return;
    }
      let affiliateBranchForm: FormArray = <FormArray>this.listDescription.controls[index];
      
      let idFormAffiliateBranch = affiliateBranchForm.get('id');
      if(!!idFormAffiliateBranch && idFormAffiliateBranch.value) {
        this.deleteAffiliateBranch(idFormAffiliateBranch.value);
      }

      this.listDescription.removeAt(index);
      this.listDescription.markAsDirty();
      if(this.listDescription.length === 0) {
        this.addNew();
      }
    }

    deleteAffiliateBranch(id: number): void {
      this.affiliateBranchService.remove(id).subscribe();
    }
    isAddButtonVisible(index: number): boolean {
    return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && index === this.listDescription.length - 1;
  }

  isRemoveButtonVisible(): boolean {
    return !this.disableStatus && this.viewMode === VIEW_MODE.DEFAULT && this.nextUsage.affiliate_branch.length > 0;
  }
  }