import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformLocation } from './transform-location.class';
import { TransformSiteJunctionService } from '../services/transform-site-junction.service';
var TransformSiteJunction = /** @class */ (function (_super) {
    tslib_1.__extends(TransformSiteJunction, _super);
    function TransformSiteJunction(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this) || this;
        _this.provider = TransformSiteJunctionService;
        _this.site = new TransformLocation();
        _this.set(data);
        _this.site = new TransformLocation({
            name: data.name,
            description: data.description,
            address: data.address,
            id: _this.site_id,
        });
        return _this;
    }
    TransformSiteJunction.prototype.fields = function () {
        return [
            'id',
            'site_id',
            'transformation_id',
            'site',
        ];
    };
    return TransformSiteJunction;
}(ActiveRecord));
export { TransformSiteJunction };
