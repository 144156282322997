import {Component, Input} from '@angular/core';
import { PASSPORT_UNITS_MAP } from '../../../values/passport-units.const';
import { PassportFamily } from '../../../models/passport.class';

@Component({
  moduleId: module.id,
  selector: 'passport-family-view-label',
  templateUrl: 'passport-family-view-label.component.html'
})
export class PassportFamilyViewLabel {

    @Input() familyType: PassportFamily;
    @Input() display: string;
    @Input() unit: string;
    constructor() {}

    getFamilyName() {
        switch(this.familyType) {
            case PassportFamily.FAMILY_GENERIC:
            case PassportFamily.FAMILY_LOCAL:
            case PassportFamily.FAMILY_MANUFACTURER:
            case PassportFamily.FAMILY_VOID:
                return `FAMILY_VIEW_TYPE_${this.familyType}`;
            default:
                return 'UNKNOWN';
        }
        
    }

    getClassName() {
        const name = this.display === 'top' ? 'top' : 'view';
        switch(this.familyType) {
            case PassportFamily.FAMILY_GENERIC:
            case PassportFamily.FAMILY_LOCAL:
            case PassportFamily.FAMILY_MANUFACTURER:
            case PassportFamily.FAMILY_VOID:
                return `passportlist__family_${name}_label--${this.familyType}`;
            default:
                return `passportlist__family_${name}_label--unknown`;
        }
        
    }

    getVoidClassName() {
        const name = this.display === 'top' ? 'top' : 'view';
        return `passportlist__family_${name}_label--unit`;
    }
    getTextClassName() {
        const name = this.display === 'top' ? 'top' : 'view';
        return `passportlist__family_${name}_text`;
    }
    getTextVoidClassName() {
        const name = this.display === 'top' ? 'top' : 'view';
        return `passportlist__family_${name}_void_text`; 
    }

    get isVoid() {
        return this.familyType === PassportFamily.FAMILY_VOID;
    }

    get getUnit() {
        return PASSPORT_UNITS_MAP[this.unit];
    }

}
