import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Type } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ServiceLocator} from "../../../shared/services/service-locator";
import { AffiliateBranchService } from '../services/affiliateBranch.service';

export class AffiliateBranch extends ActiveRecord {
  protected provider: Type<AffiliateBranchService> = AffiliateBranchService;

  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  id: number;
  next_usage_id: number;
  description: string;
  stakeholder: string;

  constructor(data){
    super(data);
    data = data||{};
    this.id = data.id;
    this.next_usage_id = data.next_usage_id;
    this.description = data.description;
    this.stakeholder = data.stakeholder;
  }

  fields() {
    return ['id','next_usage_id',  'description', 'stakeholder'];
  }


}
