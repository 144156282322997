/**
 * Created by aleksandr on 13.06.17.
 */
import { OnInit } from '@angular/core';
import { Ecosystem } from '../../../../models/ecosystem.class';
import { ECOSYSTEM_STEP_TYPE } from '../../../../values/ecosystem-step-type.const';
import { Router } from '@angular/router';
import { ECOSYSTEM_STATUS, ECOSYSTEM_STATUS_VIEW, ECOSYSTEM_STATUS_COLOR } from '../../../../values/ecosystem-status.const';
import { NavbarStateService } from '../../../../../../shared/services/navbar.service';
import { EcosystemFolderService } from '../../../../../../entities/ecosystem/services/ecosystem-folder.service';
import { ECOSYSTEM_STEP_STATUS } from '../../../../../ecosystem-manager/values/ecosystem-step-status.const';
import { ECOSYSTEM_OFFER_STATUS } from '../../../../../ecosystem-manager/values/ecosystem-offer-status.const';
var EcosystemCardComponent = /** @class */ (function () {
    function EcosystemCardComponent(_router, filterState, ecosystemFolderService) {
        this._router = _router;
        this.filterState = filterState;
        this.ecosystemFolderService = ecosystemFolderService;
        this.checked = false;
        this.ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
        this.ECOSYSTEM_STATUS_VIEW = ECOSYSTEM_STATUS_VIEW;
        this.filterStatuses = [];
    }
    Object.defineProperty(EcosystemCardComponent.prototype, "needs", {
        get: function () {
            return this.getByType(ECOSYSTEM_STEP_TYPE.NEED);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemCardComponent.prototype, "deposits", {
        get: function () {
            return this.getByType(ECOSYSTEM_STEP_TYPE.DEPOSIT);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemCardComponent.prototype, "transformations", {
        get: function () {
            return this.getByType(ECOSYSTEM_STEP_TYPE.TRANSFORM);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemCardComponent.prototype, "stakeholders", {
        get: function () {
            return this.getByType(ECOSYSTEM_STEP_TYPE.STAKEHOLDER);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemCardComponent.prototype, "virginProducts", {
        get: function () {
            return this.getByType(ECOSYSTEM_STEP_TYPE.VIRGIN_PRODUCT);
        },
        enumerable: true,
        configurable: true
    });
    EcosystemCardComponent.prototype.ngOnInit = function () {
    };
    EcosystemCardComponent.prototype.openEcosystem = function () {
        this._router.navigate(['ecosystem/ecosystems', this.ecosystem.id]);
    };
    EcosystemCardComponent.prototype.getByType = function (stepType) {
        return this.ecosystem && this.ecosystem.steps
            ? this
                .ecosystem
                .steps
                .filter(function (step) { return step.type === stepType; })
            : [];
    };
    EcosystemCardComponent.prototype.checkStatusInProcess = function () {
        return this.ecosystem.status === this.ECOSYSTEM_STATUS.IN_PROCESS || this.ecosystem.status === this.ECOSYSTEM_STATUS.DONE;
    };
    EcosystemCardComponent.prototype.getEcosystemDisplayName = function (length) {
        return this.ecosystem.name.length > length ? this.ecosystem.name.substring(0, length - 1) + '..' : this.ecosystem.name;
    };
    EcosystemCardComponent.prototype.isOfferSent = function () {
        return this.ecosystem.status == ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION;
    };
    EcosystemCardComponent.prototype.getFolderName = function (length) {
        return this.ecosystem.folder && (this.ecosystem.folder.name.length > length ? this.ecosystem.folder.name.substring(0, length - 1) + '..' : this.ecosystem.folder.name);
    };
    EcosystemCardComponent.prototype.canDisplayFolderName = function () {
        return this.ecosystem.folder !== null;
    };
    EcosystemCardComponent.prototype.onCheckbox = function (e) {
        e.stopPropagation();
    };
    EcosystemCardComponent.prototype.onCheckboxChange = function (checked) {
        if (this.ecosystemFolderService.allSelected) {
            this.ecosystemFolderService.allSelected = false;
        }
        return checked ?
            this.ecosystemFolderService.addToSelection(this.ecosystem.id, this.ecosystem.folder && this.ecosystem.folder.id) :
            this.ecosystemFolderService.removeFromSelection(this.ecosystem.id);
    };
    EcosystemCardComponent.prototype.getStatusClass = function () {
        return "ecosystem-card__status-label__" + ECOSYSTEM_STATUS_COLOR[this.ecosystem.status];
    };
    EcosystemCardComponent.prototype.shouldDisplayCheck = function (step) {
        return step.status === ECOSYSTEM_STEP_STATUS.BOOKED || step.status === ECOSYSTEM_STEP_STATUS.VALIDATED || step.status === ECOSYSTEM_STEP_STATUS.CONFIRMED;
    };
    EcosystemCardComponent.prototype.getStepStatusStyle = function (step) {
        switch (step.status) {
            case ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION:
            case ECOSYSTEM_STEP_STATUS.BOOKED:
            case ECOSYSTEM_STEP_STATUS.VALIDATED:
                return '#5B96ED';
            case ECOSYSTEM_STEP_STATUS.WAITING_FOR_CONFIRMATION:
            case ECOSYSTEM_STEP_STATUS.CONFIRMED:
                return '#58CB9F';
            default:
                return '#747474';
        }
    };
    EcosystemCardComponent.prototype.shouldDisplayNeedCheck = function (ecoOffer) {
        return ecoOffer && (ecoOffer.status === ECOSYSTEM_OFFER_STATUS.VALIDATED || ecoOffer.status === ECOSYSTEM_OFFER_STATUS.CONFIRMED);
    };
    EcosystemCardComponent.prototype.getNeedStatusStyle = function (ecoOffer) {
        if (!ecoOffer) {
            return '#747474';
        }
        switch (ecoOffer.status) {
            case ECOSYSTEM_OFFER_STATUS.WAITING_FOR_VALIDATION:
            case ECOSYSTEM_OFFER_STATUS.VALIDATED:
                return '#5B96ED';
            case ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION:
            case ECOSYSTEM_OFFER_STATUS.CONFIRMED:
                return '#58CB9F';
            default:
                return '#747474';
        }
    };
    return EcosystemCardComponent;
}());
export { EcosystemCardComponent };
