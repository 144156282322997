<article class="do-sites-list">
  <section class="do-sites-list__button-group" *ngIf="!isBuildingDO">
    <div class="do-sites-list__button-group--left">
      <button md-raised-button class="do-sites-list__button background-primary-color
      do-sites-list__button--content-left" (click)="createSite()">
        <md-icon class="do-sites-list__button">+</md-icon>
        {{ 'CREATE_SITE' | translate }}</button>
      <button md-raised-button class="do-sites-list__button background-primary-color" (click)="importSites()">
        <i class="upload-files__camera import--icon"></i>
        {{ 'IMPORT_SITE' | translate }}</button>
    </div>
    <div class="do-sites-list__button-group--left">
      <button md-raised-button class="do-sites-list__button do-sites-list__button--black"
              [disabled]="!canDeleteSearch()"
              (click)="deleteFiltered()"
      >
        <i class="upload-files__camera icon--white-magnify-deletion-bin"></i>
        {{'DELETE_SEARCH_RESULTS' | translate}}
      </button>
    </div>
  </section>
  <section class="do-sites-list__filter">
    <div class="do-sites-list__filter-button-group-container">
      <ul class="do-sites-list__filter-button-group-checkbox">
        <li class="do-sites-list__filter-button-checkbox-item">
        </li>
      </ul>
      <ul class="do-sites-list__filter-button-group">
        <li class="do-sites-list__filter-button">
          <passport-reset-button class="do-sites-list__filter-button-reset"
            [resetFilterState]="resetFilter"
            [alternativeRules]="alternativeRules"
            [passportFilter]="applyFilters"
            (onResetClick)="resetFilers()"
          >
          </passport-reset-button>
        </li>
      </ul>
    </div>
    <ul class="do-sites-list__filter-input">
      <li class="do-sites-list__filter-input-item-container">
        <div class="do-sites-list__filter-input-item-container-before">
          <md-icon class="do-sites-list__filter-input-item-icon">
            search
          </md-icon>
        </div>
        <md-input-container class="do-sites-list__filter-input-item">
          <input mdInput [formControl]="filters.search" placeholder="{{'SEARCH_SITE_PLACEHOLDER' | translate}}">
        </md-input-container>
      </li>
      <li class="do-sites-list__filter-input-item-container">
        <deposit-custom-multi-select
          *ngIf="isBuildingDO"
          class="do-sites-list__filter-input-item
                    do-sites-list__filter-input-item-custom-select
                    do-sites-list__filter-input-item-custom-select--additional-margin"
          appMultiSelectMdSelectInput
          [options]="siteStatusOptions"
          [placeholder]="'Status' | translate"
          [isSelectEmpty]="isSiteStatusEmpty.bind(this)"
          [(ngModel)]="applyFilters.siteStatus"
          (ngModelChange)="loadRecords()"
        >
        </deposit-custom-multi-select>
        <deposit-custom-multi-select *ngIf="!isBuildingDO"
                                     class="do-sites-list__filter-input-item
                    do-sites-list__filter-input-item-custom-select
                    do-sites-list__filter-input-item-custom-select--additional-margin"
                                     appMultiSelectMdSelectInput
                                     [options]="usedOptions"
                                     [(ngModel)]="applyFilters.used"
                                     [isSelectEmpty]="isUsedEmpty.bind(this)"
                                     (ngModelChange)="loadRecords()"
                                     [placeholder]="'Status' | translate"
        >
        </deposit-custom-multi-select>
      </li>
      <li class="do-sites-list__filter-input-item do-sites-list__filter-input-item-container">
        <md-select
          class="do-sites-list__filter-input-item-select"
          [(ngModel)]="filters.sort"
          (ngModelChange)="loadRecords()"
          [placeholder]="'Sort by' | translate"
        >
          <md-option *ngFor="let option of sortOptions" [value]="option.field">
            {{ option.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>

  </section>
  <section class="do-sites-list__records-container">
    <custom-list [records]="records | paginate:{id:'sites_pagination'
    , itemsPerPage: filters.perPage
    , currentPage: filters.currentPage
    , totalItems: filters.totalCount }"
                 class="do-sites-list__records">
    </custom-list>
    <pagination-controls class="passportlist__pagination do-sites-list__records-pagination"
      id="sites_pagination" (pageChange)="this.loadRecords($event)"
      maxSize="8"
      directionLinks="true"
      autoHide="true"
      previousLabel="previous"
      nextLabel="next"
      screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
      screenReaderPageLabel="{{ 'page' | translate }}"
      screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"
    ></pagination-controls>
  </section>
</article>
