/**
 * Created by aleksandr on 13.06.17.
 */
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  ECustomListHintClass,
  ECustomListUnit,
  ICustomListKeyPair,
  ICustomListRecord
} from '../../../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../../../shared/custom-list/custom-list-record.model';
import { DepositService } from '../../../../../../entities/deposit/services/deposit.service';
import { Deposit } from '../../../../../../entities/deposit/models/deposit.class';
import { TimeLeftTextPipe } from '../../../../../../shared/pipes/time-left-text.pipe';
import { MdDialog } from '@angular/material';
import { EsmDepositDialogComponent } from '../../../../dialogs/esm-deposit-dialog/esm-deposit-dialog.component';
import { DepositOwnerListService } from '../../../../../deposit-owner/services/deposit-owner-list.service';
import { Searchable } from '../../../../../../shared/models/searchable.class';
import {
  DepositDetailsDialogComponent
} from '../../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { DEPOSIT_STATUSES } from '../../../../../../entities/deposit/values/deposit-statuses.const';
import { DatePipe } from '@angular/common';
import { DIALOG_THEME } from '../../../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../../../shared/services/navbar.service';
import { SiteService } from './../../../../../../entities/depositLocation/services/SiteService';
import { TimeSinceExpire } from '../../../../../../shared/pipes/time-since-expire.pipe';
import { EEcosystemDepositAvailability } from '../../../../values/ecosystem-deposit-availability.const';
import { CustomListDateAvailabilityService } from '../../../../../../shared/services/custom-list-date-availability.service';
import { EDepositAvailableStatus } from '../../../../../../shared/values/deposit-available-status.enum';
import { DepositLocationService } from '../../../../../../entities/deposit/services/deposit-location.service';
import { DepositLocation } from '../../../../../../entities/deposit/models/deposit-location.class';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'esm-deposit-catalog',
  templateUrl: 'esm-deposit-catalog.component.html',
})
export class EsmDepositCatalogComponent implements OnInit {
  records: ICustomListRecord[] = [];
  depositOwners: Searchable[];
  depositLocations: DepositLocation[];

  depositOwnerId: number;
  depositLocationId: number;
  search = new FormControl();
  availableDateFrom: number;
  availableDateTo: number;
  sortOrder = 'name';

  filterStatuses: number[] = [];

  isListExpanded = false;
  isNextPageLoading = false;
  currentPage = 1;
  totalPagesCount = 0;
  totalCount = 0;

  beforeUpdateMap = new EventEmitter();
  sites: DepositLocation[] = [];
  private mapSubscription: Subscription;
  private depositRequestQueue = [];
  private pageRequestObserver: Subscription;
  updateMap = new EventEmitter<DepositLocation[]>();
  chosenDepositLocations: DepositLocation[] = [];

  DEPOSIT_STATUSES = DEPOSIT_STATUSES;

  private destroy$ = new Subject<void>();

  constructor(private _depositService: DepositService,
    private translateService: TranslateService,
    private dialog: MdDialog,
    private depositOwnerListService: DepositOwnerListService,
    private datePipe: DatePipe,
    private navBarState: NavbarStateService,
    private siteService: SiteService,
    public customListDateAvailable: CustomListDateAvailabilityService,) {
    this
      .depositOwnerListService
      .get()
      .subscribe((depositOwners: Searchable[]) => {
        this.depositOwners = depositOwners;
      });
  }

  openMapDialog($event: any) {
    const dialogRef = this.dialog.open(EsmDepositDialogComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('afterClosed', result);
    });
  }


  private initNewDepositLoad() {
    this.records = [];
    this.depositRequestQueue.forEach(request => request.unsubscribe());
  }

  private initSites() {
    this.beforeUpdateMap.emit();
    this.mapSubscription = this.siteService.loadSites(this.requestOptions()).subscribe(sites => {
      this.sites = sites;
      this.depositLocations = sites;
      this.updateMap.emit(sites);
    });
  }

  setChosenLocationsById(ids: number[]) {
    this.depositLocationId = ids[0];
    this.getRecords();
  }

  onEndOfListTriggered() {
    if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount) {
      this.getRecords(this.currentPage + 1);
    }
  }

  getRecords(forPage: number = 0) {
    this.isListExpanded ? this.getRecordsWithoutPagination().subscribe() : this.getRequestWithPagination(forPage);
  }

  private getRequestWithPagination(forPage: number = 0) {
    const options: any = this.requestOptions();
    options.page = forPage;

    this.isNextPageLoading = true;
    this.pageRequestObserver = this._depositService
      .getWithPaginationData(options)
      .subscribe((res) => {
        if (forPage === 0) {
          this.records = [];
          this.initNewDepositLoad();
          this.initSites();
        }
        const headers = res.headers;
        this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
        this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
        this.totalCount = Number(headers.get('X-Pagination-Total-Count'));
        res.json().map((item: any) => this.addRecord(new Deposit(item)));
        this.isNextPageLoading = false;
      });
  }

  private requestOptions() {
    return {
      expand: 'passport,creator,owner_name,creator_email,owner_email,material_names,deposit_location',
      admin: 1,
      supervisor: 1,
      esm_view: 1,
      available_from: this.availableDateFrom || null,
      available_to: this.availableDateTo || null,
      search: this.search.value || null,
      sort: this.sortOrder || null,
      owner: this.depositOwnerId || null,
      'sites[]': this.depositLocationId ? [this.depositLocationId] : null,
      'status[]': this.filterStatuses || null,
      ...(this.navBarState.multilanguage ? { english: true } : {})
    };
  }

  addRecord(data: Deposit) {
    // console.log(data);
    let hintCssClass = ECustomListHintClass.NO_CLASS;
    let hintText = '';
    const listOfUsages: ICustomListKeyPair[] = data.passport.next_usages.map((usage) => {
      return { key: '', seperator: '', value: 'test' };
    });

    let availableInListItem = null;
    const hasAvailableInText = EEcosystemDepositAvailability.IN_RANGE;
    if (data.availability_date) {
      availableInListItem = this.customListDateAvailable
        .getColumn(data.availability_date, data.availability_expiration);
      const availabilityStatus = CustomListDateAvailabilityService
        .getDepositAvailableStatus(data.availability_date, data.availability_expiration);
      availableInListItem.value = availabilityStatus !== EDepositAvailableStatus.AVAILABLE
        ? this.translateService.instant(availableInListItem.key) + ' ' + availableInListItem.value
        : this.translateService.instant(availableInListItem.value);
      availableInListItem.key = '';
      if (availabilityStatus === EDepositAvailableStatus.EXPIRED) {
        availableInListItem.value = 'Expired';
      }
      const availableTimeLeft = (new Date(data.availability_date).getTime() - Date.now());
      if (availableTimeLeft / 1000 / 60 / 60 / 24 < 7 &&
        availableTimeLeft > 0) {
        hintCssClass = ECustomListHintClass.WARNING;
        hintText = 'Deposit available in less then a week';
      }
    }

    const materialsString = data.material_names.map((item) => item.name).join(', ');

    const locationName = data.deposit_location !== null ? data.deposit_location.name : '';

    const createdDate = this.datePipe.transform(data.created_at, 'dd-MM-yyyy');
    this.records.push(
      this.createDepositRecord(
        hintCssClass,
        hintText,
        data.name,
        data.passport.name,
        data.quantity || 0,
        this.translateService.instant(data.passport.unit_text),
        data.conditionData ? data.conditionData.view.replace(/./, c => c.toUpperCase()) : '',
        createdDate,
        availableInListItem,
        materialsString,
        listOfUsages,
        locationName,
        data.deposit_location.address,
        data.owner_name,
        data,
      )
    );
  }

  ngOnInit(): void {
    this.search.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(() => this.getRecords());
    this.getRecords();
    this.initSites();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  
    if (this.mapSubscription) {
      this.mapSubscription.unsubscribe();
    }
  
    if (this.pageRequestObserver && !this.pageRequestObserver.closed) {
      this.pageRequestObserver.unsubscribe();
    }
  
    this.depositRequestQueue.forEach(request => {
      if (request && !request.closed) {
        request.unsubscribe();
      }
    });
  }
  

  updateRecordsOnListExpand() {
    if (this.currentPage === this.totalPagesCount) {
      return Observable.of(null);
    }
    return this.getRecordsWithoutPagination();
  }

  private getRecordsWithoutPagination() {
    return this._depositService
      .getWithPaginationData({ ...this.requestOptions(), nopaginate: 1 })
      .map(data => data.json().map(item => new Deposit(item)))
      .map((data: Deposit[]) => {
        this.syncWithGetWithPagination();
        this.initNewDepositLoad();
        this.initSites();
        data.forEach(item => this.addRecord(item));
      });
  }

  private syncWithGetWithPagination() {
    this.currentPage = this.totalPagesCount;
    this.isNextPageLoading = false;
    if (this.pageRequestObserver && !this.pageRequestObserver.closed) {
      this.pageRequestObserver.unsubscribe();
    }
  }

  createDepositRecord(hintCssClass: ECustomListHintClass,
    hintText: string,
    title: string,
    subtitle: string,
    amount: number | null,
    unit: ECustomListUnit,
    unitStatus: string,
    createdAt: string,
    availableInListItem: ICustomListKeyPair,
    materials: string,
    listOfUsages: ICustomListKeyPair[],
    site: string,
    address: string,
    author: string,
    deposit: Deposit,): CustomListRecord {
    return CustomListRecord
      .build()
      .setPrimaryKey(deposit.id)
      .setHint(hintText, hintCssClass)
      .addTitleColumn(title, subtitle, null, deposit.passport.english_name)
      .addListColumn([
        { key: '', value: `${amount} ${unit}`, seperator: '', cssClass: 'title' },
        { key: '', value: `${unitStatus}`, seperator: '' }
      ])
      .addListColumn([{
        key: '',
        value: createdAt,
        cssClass: 'title'
      }, { ...availableInListItem, ...{ seperator: ' ', cssClass: availableInListItem.cssClass + ' esm-catalog__deposit-list-state' } }])
      .addListColumn([{ key: '', value: site, seperator: '', cssClass: 'title text--shrinkable' }
        , { key: '', value: address, seperator: '', cssClass: 'text--shrinkable' }])
      .addAuthorColumn(author)
      .addControlsColumn({
        icon: 'menu',
        options: [{
          fn: (data: ICustomListRecord) => {
            this.dialog.open(DepositDetailsDialogComponent, {
              ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
              data: {
                readOnly: true,
                id: deposit.id,
              }
            });
          },
          name: 'See details'
        }, {
          fn: (data: ICustomListRecord) => {
            location.href = 'mailto:' + deposit.owner_email;
          },
          name: 'Send Email'
        }]
      });
  }

}
