export const ACTION_MAP = {
    CREATION_ACTION:  1,
    UPDATE_ACTION: 2
};

export const VOID_TYPE = {
    NON_VOID: 0,
    VOID: 1
};

export const PASSPORT_COPY_TYPE = {
    NON_LOCAL_PASSPORT_COPY : 0,
    LOCAL_PASSPORT_COPY : 1
};

export const TYPE_DOCUMENT = {
    DOCUMENT : 1,
    PHOTO : 2
};

export class ImportSuccessReport {
  depositsCreated: ImportSuccessSummaryDeposit[];
  depositsUpdated: ImportSuccessSummaryDeposit[];
  depositsVoided: ImportSuccessSummaryDeposit[];
  passportsCreated: ImportSuccessSummaryDeposit[];
  picturesNotFound: ImportSuccessSummaryDocuments[];
  documentsNotFound: ImportSuccessSummaryDocuments[];
  linesCount: number;
  creationDate: string;
  username: string;
}

export class ImportSuccessSummary {
  depositArray: ImportSuccessSummaryDeposit[];
  documentsArray: ImportSuccessSummaryDocuments[];
  linesCount: number;
  username: string;
  creationDate: string;
}

export class ImportSuccessSummaryDeposit {

    public deposit_id: string;
    public row_number: number;
    public action_status: number;
    public void_creation: number;
    public local_passport_copy: typeof PASSPORT_COPY_TYPE[keyof typeof PASSPORT_COPY_TYPE];
    public name: string;

    constructor(deposit_id,
        row_number,
        action_status,
        void_creation,
        name) {
            this.deposit_id = deposit_id;
            this.row_number = row_number;
            this.action_status = action_status;
            this.void_creation = void_creation;
            this.name = name;
    }
}

export class ImportSuccessSummaryDocuments {
    public row_number: number;
    public url: string;
    public type_document: number;

    constructor(row_number, url, type_document) {
            this.row_number = row_number;
            this.url = url;
            this.type_document = type_document;
    }
}
