import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {
  TPassportLongModelFormJunction
} from '../../values/passport-validation-fields.const';

export type TFormVisibility = (observableName: string, value: boolean) => boolean;

export interface IBuilderAdditionalFunctionality {
  defaultValue?: any;
  change?: (val) => void;
  visibilityValue?: boolean;
  onChangeVisibility?: TFormVisibility;
}

export interface IBuilderConfig  extends IBuilderAdditionalFunctionality {
  control?: AbstractControl;
  controlOptions?: Array<any>;
}

export interface IVisibilityObservable {
  value: boolean;
  subscription: string[];
}

export class PassportTabFormBuilderModel {
  static readonly VISIBILITY_PREFIX = '__visibility__';
  static readonly SUBSCRIPTION_CONTROL_NAME = '__subscription__';
  private configs: {[controlName: string]: IBuilderConfig} = {};
  private visibilityConfigs: {[name: string]: IVisibilityObservable} = {};

  private subscriptions = [];

  constructor(private fb: FormBuilder
              , private defaultData: TPassportLongModelFormJunction
              , private defaultValue: (controlName: string) => string
              , private defaultChange: (controlName: string) => (val) => any
              , private defaultVisibilityValue: (controlName: string) => boolean
              , private defaultOnChangeVisibility: (controlName: string) =>  (observableName, value) => boolean
              , private hasModelJunction: (controlName: string) => boolean
              ) {
  }

  public createDefault() {
    this.configs = {
      ...this.configs,
      ...this.defaultData.reduce((accumulator, name) => {
      accumulator[name] = {change: this.assignDefaultChange(name)
      , visibilityValue: this.defaultVisibilityValue(name as string)
      , onChangeVisibility: this.defaultOnChangeVisibility(name as string)
      , defaultValue: this.assignDefaultValue(name)};
      return accumulator;
    }, {})};
    return this;
  }

  private assignDefaultChange(name) {
    return !!this.hasModelJunction(name) ? this.defaultChange(name) : null;
  }

  private assignDefaultValue(name) {
    return !!this.hasModelJunction(name) ? this.defaultValue(name) : null;
  }

  omitControl(name: string) {
    if (this.configs[name]) {
      delete this.configs[name];
    }
    return this;
  }

  omitMultiControls(names: string[]) {
    names.forEach(name => this.omitControl(name));
    return this;
  }

  addControl(name: string, control: AbstractControl, options?: IBuilderAdditionalFunctionality) {
    this.configs[name] = {
      control: control,
      ...this.addAdditionalFunctionality(name, options)
    };
    return this;
  }

  private addAdditionalFunctionality(name: string, options?: IBuilderAdditionalFunctionality) {
    const getOption = (key: keyof IBuilderAdditionalFunctionality, defaultFunc: (name) => any) =>
      options && options[key] ? options[key] : defaultFunc.apply(this, [name]);
    return {
      change: getOption('change', this.assignDefaultChange),
      visibilityValue: getOption('visibilityValue', this.defaultVisibilityValue),
      onChangeVisibility: getOption('onChangeVisibility', this.defaultOnChangeVisibility),
      defaultValue: getOption('defaultValue' , this.assignDefaultValue)
    };
  }

  addControlOptions(data: {[key: string]: Array<any>}) {
    Object.keys(data).forEach(item => {
      const isOption = () => data[item].length && typeof data[item][0] === 'object';
      this.configs[item] = {
        controlOptions: data[item],
        ...this.addAdditionalFunctionality(item, isOption() ? data[item][0] : null)
      };
    });
    return this;
  }

  addVisibilityObservable(name: string, defaultValue: boolean) {
    this.visibilityConfigs[name] = {subscription: [], value: defaultValue};
    return this;
  }

  subscribeToVisibilityObservable(observableName: string, formNames: string[]) {
    const {subscription} = this.visibilityConfigs[observableName];
    subscription.push(...formNames);
    return this;
  }

  build() {
    const group = this.createFormGroup();
    this.assignOnControlChangeAction(group);
    group.addControl(PassportTabFormBuilderModel.VISIBILITY_PREFIX, this.createVisibilityGroup(group));
    group.addControl(PassportTabFormBuilderModel.SUBSCRIPTION_CONTROL_NAME, new FormControl(this.subscriptions));
    return group;
  }

  private createFormGroup() {
    const {controls, options} = this.configTransformToControlsAndControlOptions();
    const group = this.fb.group(options);
    Object.keys(controls).map(key => group.addControl(key, controls[key]));
    return group;
  }

  private configTransformToControlsAndControlOptions() {
    return Object.keys(this.configs).reduce((accumulator, configKey) => {
      const config = this.configs[configKey];
      if (config.control) {
        accumulator.controls[configKey] = config.control;
      } else {
        accumulator.options[configKey] = this.createFormControlOptions(config);
      }
      return accumulator;
    }, {controls: {}, options: {}});
  }

  private createFormControlOptions(data: {defaultValue?: any, controlOptions?: Array<any>}) {
    const {defaultValue, controlOptions} = data;
     if (controlOptions) {
      return controlOptions;
    } else {
      return [defaultValue];
    }
  }

  private assignOnControlChangeAction(group) {
    Object.keys(this.configs).forEach(option => {
      if (this.configs[option].change) {
        this.subscriptions.push(group.get(option).valueChanges.subscribe(val => this.configs[option].change(val)));
      }
    });
  }

  private createVisibilityGroup(group: FormGroup) {
   const visibilityGroup = this.createVisibilityForm(group);
   this.assignVisibilityFormOnChangeActions(visibilityGroup);
   return visibilityGroup;
  }

  private createVisibilityForm(group: FormGroup) {
    const observable = Object.keys(this.visibilityConfigs);
    const observer = Object.keys(group.controls);
    const observablesOptions = observable.reduce((accumulator, item) => {
      accumulator[PassportTabFormBuilderModel.VISIBILITY_PREFIX + item] = [this.visibilityConfigs[item].value];
      return accumulator;
    }, {});
    const observerOptions = observer.reduce((accumulator, item) => {
      accumulator[item] = [this.configs[item].visibilityValue];
      return accumulator;
    }, {});
    return  this.fb.group({...observablesOptions, ...observerOptions});
  }

  private assignVisibilityFormOnChangeActions(visibilityForm: FormGroup) {
    const getObservable = (observable) => this.visibilityConfigs[observable];
    const getObservableSubs = (observable) => getObservable(observable).subscription;
    return Object.keys(this.visibilityConfigs)
      .filter(key => !!getObservableSubs(key).length)
      .map(key => {
        const subFunction = (sub) => this.configs[sub] && this.configs[sub].onChangeVisibility;
        const subscribers = getObservableSubs(key).filter(sub => !!subFunction(sub));
        const onChangeVisibility = (val) => {
          subscribers.forEach(sub => {
            visibilityForm.get(sub).setValue(subFunction(sub)(key, val));
          });
        };
        if (getObservable(key).value !== null && getObservable(key).value !== undefined) {
          onChangeVisibility(getObservable(key));
        }
        this.subscriptions.push(visibilityForm.get(PassportTabFormBuilderModel.VISIBILITY_PREFIX + key).valueChanges.subscribe(
          (value) => onChangeVisibility(value)));
      });
  }
}
