/**
 * Created by kirill on 4.07.17.
 */
import { Injectable } from '@angular/core';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { EcosystemFileJunction } from '../../../dashboards/ecosystem-manager/models/ecosystem-file-junction.class';

@Injectable()
export class EcosystemFileJunctionService extends ExtendedCrudService {
  protected namespace = 'ecosystem-file-junctions';
  protected namespaceSingular = 'ecosystem-file-junction';
  protected ModelClass = EcosystemFileJunction;
}
