import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { GenericPassportTagJunction } from '../models/generic-passport-tag-junction.class';
var GenericPassportTagJunctionService = /** @class */ (function (_super) {
    tslib_1.__extends(GenericPassportTagJunctionService, _super);
    function GenericPassportTagJunctionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'generic-passport-tag-junctions';
        //protected namespaceSingular = 'generic-passport-tag-junction';
        _this.ModelClass = GenericPassportTagJunction;
        return _this;
    }
    return GenericPassportTagJunctionService;
}(CrudService));
export { GenericPassportTagJunctionService };
