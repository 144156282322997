import {Component, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '../../shared/services/current-user.service';
import {FormBuilder, FormGroup, Validators, FormArray, ValidationErrors, AbstractControl} from '@angular/forms';
import {LogNotificationService} from "../../shared/services/log-notification.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'confirmation-password',
  templateUrl: 'confirmation-password.component.html',
  styleUrls: ['confirmation-password.component.scss'],
})

export class ConfirmationPasswordComponent implements OnInit, OnDestroy {
  private token: string;
  private sub: any;
  public passwordForm: FormGroup;
  private activationAuthKey: string;
  private langs: any;

  constructor(
    private _fb: FormBuilder,
    private userService: CurrentUserService,
    private router: Router,
    private route: ActivatedRoute,
    private _translateService: TranslateService,
    public notification: LogNotificationService
  ) {
  }

  ngOnInit() {
    this.createPasswordForm();
    this.sub = this.route.queryParams.subscribe(params => {
      this.token = params['token']; // (+) converts string 'id' to a number
      this.activationAuthKey = params['auth_key'];
      if( this._translateService.langs.includes(params['lang'])){
        this._translateService.use(params['lang']);
      }
      else{
        this._translateService.use('fr');
      }
    });
  }
  matchPasswordValidator(control: AbstractControl) {
    return (control.get('password').value !== control.get('passwordRepeat').value)
       ? { passwordMismatch: true }
       : null;
  }
  createPasswordForm(): void {
    this.passwordForm = this._fb.group({
      password: ['', [Validators.minLength(8), Validators.required]],
      passwordRepeat: ['', [Validators.required]]
    }, {validator: this.matchPasswordValidator});
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  markPasswordFieldsTouched(field: string): void {
    this.passwordForm.get(field).markAsTouched();
  }
  submit() {
    !!this.activationAuthKey
      ? this.activateUser()
      : this.resetPassword();
  }
  activateUser() {
    this.userService.activate(this.passwordForm.get('password').value, this.activationAuthKey)
      .subscribe(() => this.router.navigate(['login']),
      err => this.handleOnSubmitError(err));
  }
  resetPassword() {
    this.userService.resetPassword(this.passwordForm.get('password').value, this.token).subscribe(
      () => {this.router.navigate(['login']); },
      err => this.handleOnSubmitError(err));
  }
  handleOnSubmitError(err) {
    this.notification.error(
      err.status !== 0
        ? err.json().message
        : 'Internet connection missing',
      null, true
    );
  }
}
