import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientSearch'
})
export class ClientSearchPipe implements PipeTransform {
  transform(clients: any[], term: string): any {
    if (clients) {
      return clients.filter((client) =>
       // client.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(term) !== -1
        client.name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(term) !== -1
      );
    }
  }

}
