/**
 * Created by Aleksandr C on 16.03.17.
 */

import { Injectable } from '@angular/core';
import { MdSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LogNotificationService {

  constructor(private _snackBar: MdSnackBar,
              private _translate: TranslateService
  ) {
  }

  error(message: string, duration?: number, translate?: boolean): void {
    if(translate) {
      this._translate.get(message).subscribe((translation) => {
        this._snackBar.open(translation, '', {extraClasses: ['error'], duration: duration || 3000});
      });
    } else {
      this._snackBar.open(message, '', {extraClasses: ['error'], duration: duration || 3000});
    }
  }

  warn(message: string, duration?: number, translate?: boolean): void {
    if(translate) {
      this._translate.get(message).subscribe((translation) => {
        this._snackBar.open(translation, '', {extraClasses: ['warn'], duration: duration || 3000});
      });
    } else {
      this._snackBar.open(message, '', {extraClasses: ['warn'], duration: duration || 3000});
    }
  }

  success(message: string, duration?: number, translate?: boolean): void {
    if(translate) {
      this._translate.get(message).subscribe((translation) => {
        this._snackBar.open(translation, '', {extraClasses: ['succes'], duration: duration || 3000});
      });
    } else {
      this._snackBar.open(message, '', {extraClasses: ['succes'], duration: duration || 3000});
    }
  }
}
