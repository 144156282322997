import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { BACKSPACE, DELETE, MdDialog, TAB } from '@angular/material';
import { DepositFile } from '../../../../../entities/deposit/models/deposit-file.class';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMPORT_DEPOSITS_TAB } from '../../../../../import-spreadsheet/values/import-deposit-tab.const';
import { DepositImport } from '../../../../../entities/deposit/models/deposit-import.class';
import { DepositImportService } from '../../../../../entities/deposit/services/deposit-import.service';
import { ImportConfigurationService } from '../../../../../import-spreadsheet/services/import-configuration.service';
import { DEPOSIT_IMPORT_COLUMNS, DEPOSIT_IMPORT_CONTROL_META, DEPOSIT_IMPORT_CONTROL_OPTIONS } from './values/deposit-import-columns.const';
import { TABLE_SYNONYM_AND_COLUMNS } from './values/deposit-import-synonym';
import { ImportValidatorsService } from '../../../../../import-spreadsheet/services/import-validators.service';
import { IMPORT_DOCUMENT_CONTROLS } from '../../../../../import-spreadsheet/values/import-document-controls.const';
import { DepositImportWarningComponent } from '../../../dialogs/deposit-import-warning/deposit-import-warning.component';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { cloneDeep } from 'lodash';
import { ImportProgressService } from '../../../../../import-spreadsheet/services/import-progress.service';
import { TABLE_TYPES } from '../../../../../import-spreadsheet/values/import-tables.const';
import { ELastImportType } from '../../../../../import-spreadsheet/values/enums/last-import-type.enum';
import { ImportDeepstreamService } from '../../../../../import-spreadsheet/services/import-deepstream.service';


@Component({
  moduleId: module.id,
  selector: 'import-deposit',
  templateUrl: 'import-deposit.component.html',
  styleUrls: ['./import-deposit.component.scss']
})
export class ImportDepositComponent implements OnInit, AfterViewInit {
  DEPOSIT_IMPORT_CONTROL_META = DEPOSIT_IMPORT_CONTROL_META;
  @Input() tables: number[] = [
    TABLE_TYPES.STATE_OF_USE,
    TABLE_TYPES.LEVEL_OF_DEMONTABILITY,
    TABLE_TYPES.DEPOSIT_ORIGIN,
    TABLE_TYPES.POTENTIAL_BECOMING,
    TABLE_TYPES.COLUMNS_MAPPING
  ];
  @Input() savePreferencesAfterNumberOfChanges = 6;
  @Input() savePreferencesAfterInInterval = -1;
  @Input() savePreferencesAfterSubmit = true;
  @Input() savePreferencesOnDestroy = true;
  @Output() submit = new EventEmitter();
  public importPreferences = TABLE_SYNONYM_AND_COLUMNS;
  public columns: any = {};
  public uploadedDepositFile: DepositFile[] = [];
  public depositImportForm: FormGroup;
  public depositImport: DepositImport = new DepositImport();
  public onUpdateUploadUrl$: EventEmitter<string> = new EventEmitter();
  public onDocumentSave$: EventEmitter<string> = new EventEmitter();
  public filesList = [];
  fields: any = {};
  errorFields = {
    tab_name: { error: 'Tab name' },
    first_row: { error: 'No. of the first line to be imported' },
    last_row: { error: 'No. of the last line to be imported' }
  };

  selectedTab = IMPORT_DEPOSITS_TAB.DOCUMENTS;
  tabs = IMPORT_DEPOSITS_TAB;
  columnFieldForm: FormGroup;

  @ViewChild('afterDate') dateTemplate;
  @ViewChild('beforeDimension') dimensionTemplate;

  constructor(
    public _fb: FormBuilder,
    public notification: LogNotificationService,
    public depositImportService: DepositImportService,
    public importValidation: ImportValidatorsService,
    public importConfigurationService: ImportConfigurationService,
    public importProgressService: ImportProgressService,
    private _dialog: MdDialog,
    private zone: NgZone,
    private _translate: TranslateService,
    private importDeepstreamService: ImportDeepstreamService
  ) { }

  ngOnInit() {
    this.columnFieldForm = this._fb.group({
      ...DEPOSIT_IMPORT_CONTROL_OPTIONS,
      passport_id_field: ['', Validators.required],
      site_name_field: ['', Validators.required],
      quantity_field: ['', Validators.required],
      unit_field: ['', Validators.required]
    }, {
      validators: [
        //      (control) => this.importValidation.validateColumnDuplication(control, this.fields),
        (control) => this.importValidation.validateRequireOneOfMany(control)
      ]
    });
    this.depositImportForm = this._fb.group({
      ...IMPORT_DOCUMENT_CONTROLS,
      id: null,
    }, {
      validators: [
        (control) => this.importValidation.validateNumberControlIsNotLess(control),
      ]
    });
    this.depositImportForm.addControl('columnFields', this.columnFieldForm);
  }

  ngAfterViewInit() {
    this.fields = cloneDeep(DEPOSIT_IMPORT_COLUMNS);
    this.fields.availability_date_field.required = this.isDateColumnRequired.bind(this);
    this.fields.availability_date_field.middleTemplate = this.dateTemplate;
    this.fields.availability_date_field.middleTemplateContext = {
      checkRequireDates: this.isDateRequired.bind(this),
      datePickerKeydown: this.datePickerKeydown.bind(this),
      parentForm: this.columnFieldForm
    };
    this.fields.length_field.beforeTemplate = this.dimensionTemplate;
    this.fields.length_field.beforeTemplateContext = { parentForm: this.columnFieldForm };
  }

  private isDateFiledRequired() {
    return this.importValidation.validateRequireOneOfMany(this.columnFieldForm);
  }

  public isDateColumnRequired() {
    return !!(this.isDateFiledRequired() || this.columnFieldForm.value.availability_date_field);
  }

  public isDateRequired() {
    return this.isDateFiledRequired() || !this.columnFieldForm.value.availability_date_field;
  }

  updateImportAndShowWarning() {
    if (!this.validateAll()) {
      return;
    }
    this.createModel();
    this._dialog.open(DepositImportWarningComponent, {
      ...DIALOG_THEME.ALERT_PRIMARY,
      data: { depositImport: this.depositImport },
    }).afterClosed().subscribe((isConfirmed) => {
      if (!isConfirmed) {
        return;
      }
      this.importProgressService.startNewProcess();
      this.depositImport.save().subscribe((depositImportInserted: DepositImport) => {
        this.importProgressService.setCurrentId(depositImportInserted.id);
        this.importDeepstreamService.setType(ELastImportType.DEPOSIT);
        this.depositImport = depositImportInserted;
        if (this.uploadedDepositFile.length === 1) {
          this.documentUploaded();
        } else {
          let url = this.depositImport.getFileUploadUrl();
          this.onUpdateUploadUrl$.emit(url);
          this.onDocumentSave$.emit();
        }
      }, () => this.importProgressService.resetProgressModal());
    });
  }

  documentUploaded() {
    const spreadsheetImport = this.depositImport;
    spreadsheetImport.startImport().subscribe(
      () => {
        this.depositImportForm.get('isFileLoaded').setValue(null);   
      },
      () => this.importProgressService.resetProgressModal()
    );
  }

  private createModel() {
    this.depositImport.id = undefined;
    this.depositImportForm.controls['id'].setValue(undefined);
    const columns = this.importValidation.parseColumnsToModel(this.columnFieldForm, this.fields);
    this.depositImport = new DepositImport({ ...this.depositImportForm.value, ...columns });
    this.depositImport.reference_date = this.formatDateToMinUTCString(this.depositImport.reference_date);
  }

  private validateAll() {
    return this.depositImportForm.valid;
  }

  private formatDateToMinUTCString(dateString: string): string {
    if (!dateString) {
      return '';
    }
    const arrDate = dateString.split('-');
    return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
  }

  fileAdded(files) {
    this.filesList = files;
  }

  isPt() {
    return this._translate.currentLang === 'pt';
  }

  datePickerKeydown(e: KeyboardEvent) {
    if (e.keyCode === BACKSPACE || e.keyCode === DELETE) {
      this.columnFieldForm.controls['reference_date'].setValue(null);
    }
    if (e.keyCode === TAB) {
      return;
    }
    e.preventDefault();
  }

}

