/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-provider-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./selectable-list-filter.component.ngfactory";
import * as i3 from "./selectable-list-filter.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/services/navigation.service";
import * as i6 from "./data-provider-filter.component";
var styles_DataProviderFilterComponent = [i0.styles];
var RenderType_DataProviderFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataProviderFilterComponent, data: {} });
export { RenderType_DataProviderFilterComponent as RenderType_DataProviderFilterComponent };
export function View_DataProviderFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "data-provider-filter-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-selectable-list-filter", [], null, [[null, "selectionChange"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectionChange" === en)) {
        var pd_1 = (_co.handleSelectionChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SelectableListFilterComponent_0, i2.RenderType_SelectableListFilterComponent)), i1.ɵdid(2, 638976, null, 0, i3.SelectableListFilterComponent, [i4.TranslateService, i5.NavigationService], { selectedItems: [0, "selectedItems"], items: [1, "items"], placeholder: [2, "placeholder"] }, { selectionChange: "selectionChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getNamesDataProvider(); var currVal_1 = _co.dataProviders; var currVal_2 = "SEARCH_BY_DATA_PROVIDER"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_DataProviderFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-data-provider-filter", [], null, null, null, View_DataProviderFilterComponent_0, RenderType_DataProviderFilterComponent)), i1.ɵdid(1, 49152, null, 0, i6.DataProviderFilterComponent, [], null, null)], null, null); }
var DataProviderFilterComponentNgFactory = i1.ɵccf("app-data-provider-filter", i6.DataProviderFilterComponent, View_DataProviderFilterComponent_Host_0, { dataProviderSelected: "dataProviderSelected" }, { dataProviderChange: "dataProviderChange" }, []);
export { DataProviderFilterComponentNgFactory as DataProviderFilterComponentNgFactory };
