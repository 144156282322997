import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, NgZone, OnInit, QueryList } from '@angular/core';
import { MdDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Report } from '../../../../models/report.class';
import { BUILDING_CATEGORIES, DEPOSIT_TYPES_FOR_REPORTS, REPORT_FORM_VALUES, REPORT_SCOPE } from '../../../../values/deposit-types-for-reports.const';
import { LogNotificationService } from '../../../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from '../../../../services/report.service';
import { CurrentUserService } from '../../../../../../shared/services/current-user.service';
import { SpinnerDialogComponent } from '../../../../../../shared/spinner/dialogs/spinner-dialog/spinner-dialog.component';
import { CalculationVariablesService } from '../../../../../superadmin/components/calculation-variables/calculation-variables.service';
import { CONSTRUCTION_LAYERS } from '../../../../../superadmin/components/calculation-variables/calculation-variables.const';
import * as moment from 'moment';
import { DepositLocationService } from '../../../../../../entities/deposit/services/deposit-location.service';
import { FormBuilder } from '@angular/forms';
import { ZoneService } from '../../../../../../shared/services/zone.service';
import { ZONE_STATUS } from '../../../../../../shared/values/zone-status.const';
import { DepositImportDialogComponent } from '../../../../../agent/dialogs/deposit-import-dialog/deposit-import-dialog.component';
import { DIALOG_THEME } from '../../../../../../shared/helpers/dialog-themes.const';
import { DeepStreamService } from '../../../../../../shared/services/deepstream.service';
import { ICarbonFootprintForm } from '../../../../../admin/interfaces/carbon-footprint.interface';
import { UserService } from '../../../../../../shared/services/user.service';
import { ReportProgressService } from '../../progress/report-progress.service';
var AdminReportsFormComponent = /** @class */ (function () {
    function AdminReportsFormComponent(snackBarNotification, changeDetectorRef, sitesService, _translate, reportService, _currentUserService, _router, _dialog, _calculationVariablesService, _fb, zone, ds, userService, zoneService, reportProgressService) {
        this.snackBarNotification = snackBarNotification;
        this.changeDetectorRef = changeDetectorRef;
        this.sitesService = sitesService;
        this._translate = _translate;
        this.reportService = reportService;
        this._currentUserService = _currentUserService;
        this._router = _router;
        this._dialog = _dialog;
        this._calculationVariablesService = _calculationVariablesService;
        this._fb = _fb;
        this.zone = zone;
        this.ds = ds;
        this.userService = userService;
        this.zoneService = zoneService;
        this.reportProgressService = reportProgressService;
        this.onReports = new EventEmitter();
        this._depositType = REPORT_FORM_VALUES.DEPOSIT_TYPE_BUILDING;
        this.filteredSites = [];
        this.deposit_types = DEPOSIT_TYPES_FOR_REPORTS;
        this.deposit_scopes = REPORT_SCOPE;
        this.constructionLayers = CONSTRUCTION_LAYERS;
        this.report_form_values = REPORT_FORM_VALUES;
        this.report = new Report();
        this.building_categories = [];
        this.deconstruction = false;
        this.rehabilitation = false;
        this.renewalBuildingComponents = false;
        this.isRenewalRatesAble = false;
        this.renewalRateVisible = true;
        this.isCarbonReportOptionSelected = false;
        this.isTableVisible = false;
        this.disableSites = true;
        this.fileItem = [];
        this.disableImportFile = true;
        this.tagOpenSuggestIndex = -1;
        this.carbonFootprintForm = {
            productAndConstruction: false,
            embodiedCarbon: false,
            icComponent: false,
            icConstruction: false,
            productStage: false,
            icConstructionCo2: null
        };
    }
    AdminReportsFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getCompany()];
                    case 1:
                        _a.loadData = _b.sent();
                        this.sitesForm = this._fb.array([]);
                        this.deposit_scope = this.isRestrictedAdmin ? this.report_form_values.DOCUMENT : this.report_form_values.ALL_SITES;
                        this.disableImportFile = !this.isRestrictedAdmin;
                        this.zoneService.get({ expand: 'name, status', nopaginate: 1, status: ZONE_STATUS.PUBLISHED }).subscribe(function (zones) {
                            return _this.zones = zones;
                        });
                        this._calculationVariablesService.getCalculationVariables().subscribe(function (response) {
                            _this.renewalVariableRate = response.renewal_rates_variables;
                        });
                        this.reportCompletionStreamSubscription = this.ds.reportImportDeepStream$.reportCompletionStream$.subscribe(function (ds_result) {
                            _this.zone.run(function () {
                                if (_this.result && ds_result.data.import_id === _this.result.import_id) {
                                    _this.reportService.view(_this.result.report_id, { expand: 'result,circularity,financial_residual_value,carbon_foot_print,material_health' })
                                        .subscribe(function (report) {
                                        _this.onReports.emit(report);
                                        _this._spinnerDialogRef.close();
                                    });
                                }
                            });
                        });
                        this.sitesService
                            .get({ expand: 'tags', sort: 'name', supervisor: 1, active: 1, report: 1 })
                            .subscribe(function (data) {
                            _this.sites = data;
                            _this.filteredSites = data;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminReportsFormComponent.prototype.getCompany = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.userService.view(_this._currentUserService.infoData.id, { expand: 'company' })
                .subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.companyType = res.company.organisation_type;
                    this.selectOption = this.companyType != 2 ? this.report_form_values.DEPOSIT_TYPE_OTHER : this.report_form_values.DEPOSIT_TYPE_BUILDING;
                    resolve(this.selectOption);
                    return [2 /*return*/];
                });
            }); }, function (err) {
                reject(err);
            });
        });
    };
    Object.defineProperty(AdminReportsFormComponent.prototype, "isRestrictedAdmin", {
        get: function () {
            return this._currentUserService.isRestrictedAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminReportsFormComponent.prototype, "depositType", {
        get: function () {
            return this._depositType;
        },
        set: function (type) {
            this._depositType = type;
            // @ts-ignore
            if (this._depositType === this.report_form_values.DEPOSIT_TYPE_OTHER) {
                this.clearAll();
            }
            else {
                this.renewalRateVisible = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    AdminReportsFormComponent.prototype.clearAll = function () {
        this.clearResidualValueTypesFields();
        this.isRenewalRatesAble = false;
        this.building_categories = [];
        this.isTableVisible = false;
        this.zoneId = null;
        this.report.financial_residual_value = false;
        this.renewalRateVisible = false;
        this.carbonFootprintForm.icComponent = false;
        this.carbonFootprintForm.icConstruction = false;
        this.carbonFootprintForm.icConstructionCo2 = null;
        this.carbonFootprintForm.productAndConstruction = false;
    };
    AdminReportsFormComponent.prototype.clearResidualValueTypesFields = function () {
        this.deconstruction = false;
        this.rehabilitation = false;
        this.renewalBuildingComponents = false;
    };
    AdminReportsFormComponent.prototype.depositScopeChange = function () {
        if (this.deposit_scope === this.report_form_values.SELECTED_SITES) {
            this.disableSites = false;
            this.disableImportFile = true;
            this.sitesForm.controls.map(function (control) { return control.enable(); });
        }
        else if (this.deposit_scope === this.report_form_values.ALL_SITES) {
            this.disableSites = true;
            this.disableImportFile = true;
            this.sitesForm.controls.map(function (control) { return control.disable(); });
        }
        else {
            this.disableSites = true;
            this.disableImportFile = false;
        }
    };
    AdminReportsFormComponent.prototype.addSite = function () {
        this.sitesForm.push(this._fb.group({
            name: '',
            id: '',
            tags: [[]],
            availableTags: [[]],
            tagSuggestion: ''
        }));
    };
    AdminReportsFormComponent.prototype.removeSite = function (i) {
        this.sitesForm.removeAt(i);
        this.filteredSites = this.filterSelectedSites(this.sites);
    };
    AdminReportsFormComponent.prototype.selectedSite = function (site, i) {
        this.sitesForm.at(i).patchValue({
            name: site.nameAddress,
            id: site.id,
            tags: [],
            availableTags: site.tags.slice()
        });
        this.filteredSites = this.filterSelectedSites(this.sites);
        // this.changeDetectorRef.detectChanges();
    };
    AdminReportsFormComponent.prototype.filterSelectedSites = function (list) {
        var _this = this;
        return list.filter(function (site) { return !_this.sitesForm.value.some(function (form) { return site.id === form.id; }); });
    };
    AdminReportsFormComponent.prototype.filterSiteSuggestion = function (value) {
        this.filteredSites = this.filterSelectedSites(this.sites).filter(this.filterByName(value));
    };
    AdminReportsFormComponent.prototype.filterByName = function (value) {
        return function (s) { return s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1; };
    };
    AdminReportsFormComponent.prototype.siteFocusOut = function (i) {
        var _this = this;
        this.filteredSites = this.filterSelectedSites(this.sites);
        setTimeout(function () {
            var formValues = _this.sitesForm.at(i).value;
            var siteBefore = _this.sites.find(function (site) { return site.id === formValues.id; });
            var nameAddress = siteBefore && siteBefore.nameAddress;
            if (!!nameAddress && nameAddress !== formValues.name) {
                _this.sitesForm.at(i).patchValue({
                    name: nameAddress
                });
            }
        });
    };
    AdminReportsFormComponent.prototype.openTagSuggest = function (i) {
        var el = this.tagInputs.find(function (_, j) { return j === i; });
        if (!!el) {
            this.tagOpenSuggestIndex = i;
            this.changeDetectorRef.detectChanges();
            el.nativeElement.focus();
        }
        this.filterTagSuggestion('', i);
    };
    AdminReportsFormComponent.prototype.tagSuggestBlur = function (i) {
        this.tagOpenSuggestIndex = this.tagOpenSuggestIndex === i ? -1 : this.tagOpenSuggestIndex;
        this.sitesForm.at(i).patchValue({
            tagSuggestion: ''
        });
    };
    AdminReportsFormComponent.prototype.selectTag = function (tag, locationIndex) {
        this.sitesForm.at(locationIndex).patchValue({
            tags: this.sitesForm.at(locationIndex).value.tags.concat([tag])
        });
    };
    AdminReportsFormComponent.prototype.removeTag = function (locationIndex, tagIndex) {
        this.sitesForm.at(locationIndex).patchValue({
            tags: this.sitesForm.at(locationIndex).value.tags.filter(function (_, i) { return i !== tagIndex; })
        });
    };
    AdminReportsFormComponent.prototype.filterTagSuggestion = function (value, siteIndex) {
        var formValues = this.sitesForm.at(siteIndex).value;
        var location = this.sites.find(function (site) { return site.id === formValues.id; });
        var allTags = [];
        if (location && location.tags) {
            allTags = location.tags;
        }
        else {
            return;
        }
        var chosenTagIds = new Set(formValues.tags.map(function (tag) { return tag.id; }));
        var availableTags = allTags.filter(function (tag) { return !chosenTagIds.has(tag.id); }).filter(this.filterByName(value));
        this.sitesForm.at(siteIndex).patchValue({ availableTags: availableTags });
        return availableTags;
    };
    AdminReportsFormComponent.prototype.reportTypeSelect = function (event) {
        if (event.checked) {
            this.isRenewalRatesAble = true;
        }
        else {
            this.clearAll();
        }
    };
    AdminReportsFormComponent.prototype.carbonReportTypeSelect = function () {
        this.isCarbonReportOptionSelected = !this.isCarbonReportOptionSelected;
        this.carbonFootprintForm.productStage = false;
        this.carbonFootprintForm.embodiedCarbon = false;
        this.carbonFootprintForm.icComponent = false;
        this.carbonFootprintForm.icConstruction = false;
        this.carbonFootprintForm.productAndConstruction = false;
    };
    AdminReportsFormComponent.prototype.checkResidualValueChecked = function (event) {
        this.isTableVisible = true;
        if (event.checked) {
            this.updateTableValue('over_30_years_commercial');
            this.buildingCategory = this.report_form_values.COMMERCIAL;
            this.building_categories = BUILDING_CATEGORIES;
        }
        else {
            this.isTableVisible = false;
            this.building_categories = [];
            this.buildingCategory = 0;
        }
    };
    AdminReportsFormComponent.prototype.buildingCategoryChanged = function (event) {
        var field;
        if (event.value === REPORT_FORM_VALUES.COMMERCIAL) {
            field = 'over_30_years_commercial';
        }
        if (event.value === REPORT_FORM_VALUES.RESIDENTIAL) {
            field = 'over_30_years_residential';
        }
        if (event.value === REPORT_FORM_VALUES.INSTINUTIONAL) {
            field = 'over_30_years_institutional';
        }
        this.updateTableValue(field);
    };
    AdminReportsFormComponent.prototype.updateTableValue = function (field) {
        var _this = this;
        this.constructionLayers.forEach(function (constructionLayer, i) {
            constructionLayer.value = _this.renewalVariableRate[i][field];
        });
    };
    AdminReportsFormComponent.prototype.getNotification = function (message) {
        this.snackBarNotification.error("" + this._translate.instant(message));
    };
    AdminReportsFormComponent.prototype.validateData = function () {
        if (this.report.financial_residual_value === false &&
            this.report.circularity === false &&
            this.report.carbon_foot_print === false &&
            this.report.material_health === false) {
            this.getNotification('At least one report need to be checked');
            return false;
        }
        // @ts-ignore
        if (this._depositType === this.report_form_values.DEPOSIT_TYPE_OTHER && this.report.financial_residual_value) {
            this.getNotification('No residual value for other deposit type');
            return false;
        }
        if (this.report.carbon_foot_print) {
            if (this.carbonFootprintForm.productStage === false
                && this.carbonFootprintForm.embodiedCarbon === false
                && this.carbonFootprintForm.icComponent === false
                && this.carbonFootprintForm.icConstruction === false
                && this.carbonFootprintForm.productAndConstruction === false) {
                this.getNotification('You must select at least one type of carbon report');
                return false;
            }
        }
        if (this.carbonFootprintForm.icConstruction && !this.carbonFootprintForm.icConstructionCo2) {
            this.getNotification('Building site kg CO2 eq/ sqm quantity is required');
            return false;
        }
        if (this.report.financial_residual_value) {
            if (this.deconstruction === false && this.rehabilitation === false && this.renewalBuildingComponents === false) {
                this.getNotification('You must select at least one type of financial report');
                return false;
            }
            if (!this.zoneId) {
                this.getNotification('Please select a zone');
                return false;
            }
        }
        if (this.deposit_scope !== this.report_form_values.ALL_SITES &&
            this.deposit_scope !== this.report_form_values.DOCUMENT &&
            !this.sitesForm.value.some(function (site) { return typeof site.id === 'number'; })) {
            this.getNotification('You need to specify at least one site');
            return false;
        }
        if (this.deposit_scope === this.report_form_values.DOCUMENT && this.fileItem.length !== 1) {
            this.getNotification('File has not been upload');
            return false;
        }
        return true;
    };
    AdminReportsFormComponent.prototype.generateReport = function () {
        var _this = this;
        if (!this.validateData()) {
            return;
        }
        var data = {
            zone_id: this.zoneId,
            material_health: this.report.material_health,
            carbon_foot_print: this.report.carbon_foot_print,
            embodied_carbon: this.carbonFootprintForm.embodiedCarbon,
            ic_component: this.carbonFootprintForm.icComponent,
            ic_construction: this.carbonFootprintForm.icConstruction,
            ic_construction_co2: this.carbonFootprintForm.icConstructionCo2,
            product_and_construction: this.carbonFootprintForm.productAndConstruction,
            product_stage: this.carbonFootprintForm.productStage,
            circularity: this.report.circularity,
            financial_residual_value: this.report.financial_residual_value,
            user_id: this._currentUserService.infoData.id,
            timezone_offset: moment().utcOffset(),
            import_id: this.deposit_scope === this.report_form_values.DOCUMENT ? this.reportImport.id : null,
            sites: this.isSiteListEmpty() ? [] : this.sitesForm.value.map(function (s) { return s.id; }).filter(function (s) { return typeof s === 'number'; }),
            tags: this.isSiteListEmpty() ? [] : this.sitesForm.value.flatMap(function (s) { return s.tags.map(function (tag) { return tag.id; }); }).filter(function (s) { return typeof s === 'number'; })
        };
        if (this.report.financial_residual_value) {
            data['deconstruction'] = this.deconstruction;
            data['rehabilitation'] = this.rehabilitation;
            data['renewal_building_components'] = this.renewalBuildingComponents;
            if (this.renewalBuildingComponents) {
                data['building_category'] = this.buildingCategory;
            }
        }
        this._spinnerDialogRef = this._dialog.open(SpinnerDialogComponent, {
            disableClose: true,
            data: {
                caption: this._translate.instant('SPINNER_SAVING_CAPTION'), isLoadingDotsShown: true
            }
        });
        this.reportService.getRequestReport(data).subscribe(function (result) {
            if (_this.deposit_scope !== _this.report_form_values.DOCUMENT) {
                _this.onReports.emit(result);
                _this._spinnerDialogRef.close();
            }
            else {
                _this.result = result;
            }
        }, function () {
            _this._spinnerDialogRef.close();
        });
    };
    AdminReportsFormComponent.prototype.isSiteListEmpty = function () {
        return this.deposit_scope === this.report_form_values.ALL_SITES || (this.filteredSites.length === 0
            && !this.sitesForm.value.find(function (siteData) { return (siteData.tags || []).length > 0 && (siteData.availableTags || []).length > 0; }));
    };
    AdminReportsFormComponent.prototype.importDeposits = function () {
        var _this = this;
        this.zone.run(function () {
            _this._dialog.open(DepositImportDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: { isReport: true } })).afterClosed().subscribe(function (doc) {
                if (doc) {
                    _this.fileItem = doc.reportFile;
                    _this.reportImport = doc.reportImport;
                }
            });
        });
    };
    AdminReportsFormComponent.prototype.removeDocument = function () {
        this.fileItem = [];
    };
    AdminReportsFormComponent.prototype.getCustomTooltipMessage = function (message) {
        return this._translate.instant(message);
    };
    AdminReportsFormComponent.prototype.getEmbodiedCarbonOptionText = function () {
        if (this.depositType === this.report_form_values.DEPOSIT_TYPE_BUILDING) {
            return "Embodied (A1-A5 + B1-B5 + C)";
        }
        else {
            return "Embodied carbon (A1-A3 + B1-B5 + C)";
        }
    };
    return AdminReportsFormComponent;
}());
export { AdminReportsFormComponent };
