<article class="ecosystem-dashboard  type--simple" test-id="superadmin-dashboard">
  <main class="ecosystem-dashboard__content">
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--counters">
      <ul class="ecosystem-dashboard__counter-list">
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{publishedNeeds}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Published needs' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary ">{{publishedTransformations}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Published transformations' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary ">{{publishedDeposits}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Published deposits' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary ">{{publishedProductPassports}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Published circular passports' | translate}}</span>
        </li>
      </ul>
    </section>

    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--tabs">
      <article class="ecosystem-catalog" test-id="ecosystem-catalog">
        <header class="ecosystem-catalog__header">
          <ul class="ecosystem-catalog__caption-list">
            <li class="ecosystem-catalog__caption-item" (click)="activeIndex = 0"
                [ngClass]="{'active': activeIndex === 0}">
              <span class="ecosystem-catalog__caption-title">{{ 'Needs' | translate }}</span>
            </li>
            <li class="ecosystem-catalog__caption-item" (click)="activeIndex = 1"
                [ngClass]="{'active': activeIndex === 1}">
              <span class="ecosystem-catalog__caption-title">{{ 'Transformations' | translate }}</span>
            </li>
            <li class="ecosystem-catalog__caption-item" (click)="activeIndex = 3"
                [ngClass]="{'active': activeIndex === 3}">
              <span class="ecosystem-catalog__caption-title">{{ 'Deposits' | translate }}</span>
            </li>
          </ul>
        </header>
        <section class="ecosystem-catalog__content">
          <ul class="ecosystem-catalog__content-list">
            <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 0">
              <admin-need-catalog></admin-need-catalog>
            </li>
            <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 1">
              <admin-transform-catalog 
              [cloudIcon]="true"></admin-transform-catalog>
            </li>
            <li class="ecosystem-catalog__content-item" *ngIf="activeIndex === 3">
              <admin-deposit-catalog></admin-deposit-catalog>
            </li>
          </ul>
        </section>
      </article>
    </section>
  </main>
</article>
