import {ActiveRecord} from '../../../shared/models/active-record.class';
import {DepositLocationSalesJunctionService} from '../services/deposit-location-sales-junction.service';

export class DepositLocationSalesJunction extends ActiveRecord {
  protected provider = DepositLocationSalesJunctionService;
  id: number;
  site_id: number;
  user_id: number;

  public fields() {
    return ['id', 'site_id', 'user_id'];
  }
}
