import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformServiceAreaService } from '../services/transform-service-area.service';
var TransformServiceArea = /** @class */ (function (_super) {
    tslib_1.__extends(TransformServiceArea, _super);
    function TransformServiceArea() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformServiceAreaService;
        return _this;
    }
    TransformServiceArea.prototype.fields = function () {
        return [
            'id',
            'name',
            'transformation_id',
        ];
    };
    return TransformServiceArea;
}(ActiveRecord));
export { TransformServiceArea };
