import { IModuleNavigation } from '../../shared/interfaces/module-navigation.interface';

export const ADMIN_NAVIGATION: IModuleNavigation[] = [
  /*{
    url: 'admin/ecosystems',
    title: 'Ecosystems',
    icon: 'ecosystem--icon'
  },*/
  {
    url: 'admin/users',
    title: 'Users',
    icon: 'users--icon'
  },
  // {
  //   url: 'admin/statistics',
  //   title: 'Statistics',
  //   icon: 'statistic--icon'
  // },
  {
    url: 'admin/reporting',
    title: 'Reporting',
    icon: 'reporting--icon'
  }
];
