import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Passport } from '../../models/passport.class';
import { PassportService } from '../../services/passport.service';
import { MdDialog } from '@angular/material';
import { ViewNomenclatureDialogComponent } from '../../dialogs/view-nomenclature/view-nomenclature.component';
import { CopyPassportDialogComponent } from '../../dialogs/copy-passport-dialog/copy-passport-dialog.component';
import { PASSPORT_STATUS, PASSPORT_STATUS_CONST } from '../../values/passport-status.const';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs/Observable';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { NomenclatureChangeDialogComponent } from '../../../nomenclature/dialogs/nomenclature-change-dialog/nomenclature-change-dialog.component';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { PASSPORT_DEPENDENCY } from '../../values/passport-dependency.const';
import { getSearchStatus } from '../../../../shared/helpers/search-status-update';
import { PassportRatingExplanationDialogComponent } from '../../dialogs/passport-rating-explanation-dialog/passport-rating-explanation-dialog';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { ViewCategoryDialogComponent } from '../../dialogs/view-category/view-category.component';
import { PASSPORT_RATING } from '../../values/passport-rating/passport-rating-values.const';
import { FilterStateService } from '../../../../shared/services/filterState.service';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
import { ManufacturerGenericComponent } from '../../dialogs/manufacturer-generic-dialog/manufacturer-generic-dialog.component';
import { cloneDeep, omit } from 'lodash';
import { switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
var PassportListComponent = /** @class */ (function () {
    function PassportListComponent(dialog, _translate, _navigationService, _mdDialog, _router, _datePipe, _service, ref, filterStateService, _currentUserService, navBarStateService) {
        var _this = this;
        this.dialog = dialog;
        this._translate = _translate;
        this._navigationService = _navigationService;
        this._mdDialog = _mdDialog;
        this._router = _router;
        this._datePipe = _datePipe;
        this._service = _service;
        this.ref = ref;
        this.filterStateService = filterStateService;
        this._currentUserService = _currentUserService;
        this.navBarStateService = navBarStateService;
        this.records = [];
        this.currentPage = 1;
        this.createdBy = [];
        this.sort_order = [];
        this.categories = [];
        this.moduleNames = ModuleNames;
        this.advancedResearchLoadPassports = false;
        this.showFilterByRating = false;
        this.advancedResearchTheme = false;
        this.includeDrafts = true;
        this.isForPublicAccess = false;
        this.onSelected = new EventEmitter();
        this.filterStatuses = [
            PASSPORT_STATUS_CONST.ASK_FOR_MODIFICATION,
            PASSPORT_STATUS_CONST.WAITING_FOR_PUBLICATION,
            PASSPORT_STATUS_CONST.PUBLISHED,
            PASSPORT_STATUS_CONST.DRAFT,
        ];
        this.alreadyLoadedIndexes = [];
        this.passportFilter = {
            categoryTags: [],
            tags: [],
            createdBy: '',
            createdDateFrom: '',
            createdDateTo: '',
            orderBy: '',
            searchStatus: this.filterStatuses.map(function (status) { return status.value; }),
            search: new FormControl(),
            filterBy: {
                C2cCertified: false,
                selectRatingFilter: false,
                composition: { color: false, black: false },
                cycle: { color: false, black: false },
                energy: { color: false, black: false },
                water: { color: false, black: false },
                social: { color: false, black: false },
                passportWithoutRating: true,
            },
            loadSelfPassports: false,
            loadDraftPassports: false,
            version_filter: -1,
            family: [],
        };
        this.isVersionFilterOn = false;
        this.resetFilterState = cloneDeep(omit(this.passportFilter, 'orderBy'));
        this.skipLoadOnFamilyInit = true;
        this._translate.get('CONFIRM_DELETE_PASSPORT').subscribe(function (translation) {
            _this._confirmDeletionText = translation;
        });
        // determine in which module component is used
        this.parentModuleName = this._navigationService.getModuleName();
        this.setDefaultSortOrder();
        if (this.parentModuleName === ModuleNames.SPECIAL_ADMIN) {
            this.filterStatuses = this.filterStatuses.filter(function (value) { return value !== PASSPORT_STATUS_CONST.DRAFT; });
        }
    }
    PassportListComponent.prototype.onClick = function (targetElement) {
        if (this.filterRating && !this.filterRating.nativeElement.contains(targetElement)) {
            this.showFilterByRating = false;
            this.ref.detectChanges();
        }
    };
    PassportListComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.includeDrafts) {
            this.passportFilter.searchStatus = this.passportFilter.searchStatus.filter(function (status) { return status !== PASSPORT_STATUS_CONST.DRAFT.value; });
        }
        this.isVersionFilterOn = false;
        var storedPage = this._service.getListPage();
        var options = {};
        if (this.parentModuleName === ModuleNames.SPECIAL_ADMIN
            || (this.canDisplayDraftFilter && !this.passportFilter.loadDraftPassports)) {
            this.passportFilter.searchStatus = this.passportFilter.searchStatus.filter(function (status) { return status !== PASSPORT_STATUS.DRAFT; });
            this.resetFilterState.searchStatus = cloneDeep(this.passportFilter.searchStatus);
        }
        this.passportFilter = this.filterStateService.productPassportList ? this.filterStateService.productPassportList : this.passportFilter;
        !!storedPage ? this.loadRecords(storedPage) : this.loadRecords();
        if (!this.passportFilter.loadSelfPassports) {
            options.supervisor = 1;
        }
        this.createViewStoreIfNotExist();
        /*this._service.getCategoriesList().subscribe((categories: string[]) => {
          let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        this._service.getCategoriesList().subscribe((categories: string[]) => {
          const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
          this.categories = categories.sort(collator.compare);
        });*/
        this.passportFilter.search.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), switchMap(function (val) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!val) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadRecords()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2:
                        this.resetSearch();
                        return [4 /*yield*/, this.loadRecords(null, true)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })).subscribe();
    };
    PassportListComponent.prototype.createViewStoreIfNotExist = function () {
        if (!this.filterStateService.passportListViewState) {
            this.filterStateService.passportListViewState = { showFamilyInfoBanner: false };
        }
    };
    PassportListComponent.prototype.updateAlreadyLoadedIndexes = function (index) {
        var lastItem = this.alreadyLoadedIndexes[this.alreadyLoadedIndexes.length - 1];
        this.alreadyLoadedIndexes = this.alreadyLoadedIndexes.concat([{
                id: index,
                colored: !!lastItem ? !lastItem.colored : true
            }]);
    };
    Object.defineProperty(PassportListComponent.prototype, "storageViewState", {
        get: function () {
            return this.filterStateService.passportListViewState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassportListComponent.prototype, "ratingWithLocalPassportFilter", {
        get: function () {
            return this.passportFilter.filterBy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassportListComponent.prototype, "agentTutorialLink", {
        get: function () {
            var lang = "/" + this._translate.currentLang;
            if (lang === 'pt') {
                lang = '/es';
            }
            else if (lang === 'fr') {
                lang = '';
            }
            return 'https://upcyclea.com' + lang + '/upcyclea-librairy-ask-for-passport-creation/';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassportListComponent.prototype, "canClearFiltersAlternativeRules", {
        get: function () {
            return {
                family: [1 /* FAMILY_LOCAL */,
                    2 /* FAMILY_MANUFACTURER */,
                    3 /* FAMILY_GENERIC */],
                filterBy: {
                    C2cCertified: true,
                    composition: { color: true, black: true },
                    cycle: { color: true, black: true },
                    energy: { color: true, black: true },
                    water: { color: true, black: true },
                    social: { color: true, black: true },
                    passportWithoutRating: true,
                    selectRatingFilter: true,
                },
                search: ''
            };
        },
        enumerable: true,
        configurable: true
    });
    PassportListComponent.prototype.clearFilters = function ($event) {
        this.passportFilter = $event;
        this.loadRecords();
    };
    PassportListComponent.prototype.isItemColored = function (item) {
        if (!item || !item.id) {
            return;
        }
        var loadedItem = this.alreadyLoadedIndexes.find(function (i) { return i.id === item.id; });
        return !!loadedItem ? loadedItem.colored : false;
    };
    PassportListComponent.prototype.setDefaultSortOrder = function () {
        var creatorKey = (this.parentModuleName === ModuleNames.MANUFACTURER || this.parentModuleName === ModuleNames.SPECIAL_ADMIN)
            ? 'company' : 'username';
        this.sort_order = [
            { key: '-updated_at', value: 'Modification date' },
            { key: '-created_at', value: 'Creation date' },
            { key: creatorKey, value: 'Creator' },
            { key: 'name', value: 'Name' },
            { key: 'performance', value: 'Performance' },
        ];
    };
    PassportListComponent.prototype.setDefaultOrderBy = function () {
        this.passportFilter.orderBy = this.advancedResearchTheme ? 'name' : 'performance';
    };
    PassportListComponent.prototype.resetSearch = function () {
        this.records = [];
        this.currentPage = null;
        this.count = null;
        this.perPage = null;
        this.totalCount = null;
    };
    PassportListComponent.prototype.loadNextPage = function (event) {
        if (this.totalCount <= 20
            || this.totalCount === this.records.length
            || event.end !== this.records.length - 1) {
            return;
        }
        this.isLoadingNextPage = true;
        this.isInfiniteLoadingPageSize = true;
        var options = this.getLoadRecordsOptions(++this.currentPage);
        this.getPage(options, true);
    };
    PassportListComponent.prototype.onVersionFilter = function (data) {
        this.isVersionFilterOn = data.filter;
        if (this.isVersionFilterOn) {
            this.passportFilter.version_filter = data.passport.link_reference ? data.passport.link_reference : data.passport.id;
        }
        else {
            this.passportFilter.version_filter = -1;
        }
        this.loadRecords();
    };
    PassportListComponent.prototype.onFamilyFilterChange = function (newFilter) {
        this.passportFilter.family = newFilter;
        if (this.skipLoadOnFamilyInit) {
            this.resetFilterState.family = newFilter;
            this.skipLoadOnFamilyInit = false;
        }
        this.loadRecords();
    };
    PassportListComponent.prototype.getLoadRecordsOptions = function (page, noSearch) {
        var options = [
            ['expand', 'user,nomenclature,price,composite_name,rating,energies,type,passport_components,category', 'family']
        ];
        var isOnlyRated = !this.passportFilter.filterBy.passportWithoutRating;
        var familyOptions = this.passportFilter.family;
        if (this.passportFilter.family.length) {
            if (isOnlyRated) {
                familyOptions = familyOptions.filter(function (status) { return ![1 /* FAMILY_LOCAL */, 3 /* FAMILY_GENERIC */].includes(Number(status)); });
                if (!familyOptions.length) {
                    familyOptions = [-1];
                }
            }
            familyOptions.forEach(function (option) { return options.push(['family[]', option]); });
        }
        else if (isOnlyRated) {
            familyOptions = [2 /* FAMILY_MANUFACTURER */];
            familyOptions.forEach(function (option) { return options.push(['family[]', option]); });
        }
        if (!this.passportFilter.loadSelfPassports && this.parentModuleName === ModuleNames.SUPERVISOR) {
            options.push(['supervisor', 1]);
        }
        if (this.parentModuleName === ModuleNames.MANUFACTURER) {
            options.push(['manufacturer', 1]);
        }
        if (!this.passportFilter.loadSelfPassports && this.parentModuleName === ModuleNames.AGENT) {
            options.push(['agent', 1]);
        }
        if (this.passportFilter.loadSelfPassports && this.parentModuleName === ModuleNames.AGENT) {
            options.push(['agentOnly', 1]);
        }
        if (this.advancedResearchTheme) {
            options.push(['supervisor', 1]);
            if (this.advancedResearchLoadPassports) {
                options.push(['manufacturer', 1]);
            }
        }
        if (this.passportFilter.search.value && this.passportFilter.search.value.length > 2 && !noSearch) {
            options.push(['search', this.passportFilter.search.value.replace(/\s*$/, '')]);
        }
        if (this.passportFilter.tags.length > 0) {
            for (var _i = 0, _a = this.passportFilter.tags; _i < _a.length; _i++) {
                var tag = _a[_i];
                options.push(['materials[]', tag.id]);
            }
        }
        if (this.passportFilter.categoryTags.length > 0) {
            for (var _b = 0, _c = this.passportFilter.categoryTags; _b < _c.length; _b++) {
                var tag = _c[_b];
                options.push(['categories[]', tag.id]);
            }
        }
        if (this.passportFilter.createdBy) {
            options.push(['company_name', this.passportFilter.createdBy]);
        }
        if (this.passportFilter.createdDateFrom) {
            options.push(['created_from', this.passportFilter.createdDateFrom]);
        }
        if (this.passportFilter.version_filter && this.passportFilter.version_filter !== -1) {
            if (this.isVersionFilterOn) {
                options.push(['version_reference', this.passportFilter.version_filter]);
            }
        }
        if (this.passportFilter.createdDateTo) {
            options.push(['created_to', this.passportFilter.createdDateTo]);
        }
        if (!this.passportFilter.orderBy) {
            this.setDefaultOrderBy();
        }
        options.push(['sort', this.passportFilter.orderBy]);
        if (this.navBarStateService.multilanguage) {
            options.push(['english', true]);
        }
        if (this.passportFilter.searchStatus.length > 0) {
            for (var _d = 0, _e = this.passportFilter.searchStatus; _d < _e.length; _d++) {
                var status_1 = _e[_d];
                options.push(['status[]', status_1]);
            }
        }
        if (this.chosenCategory) {
            options.push(['category', this.chosenCategory]);
        }
        options.push(['page', page]);
        if (this.advancedResearchTheme) {
            options.push(['page_size', 20]);
        }
        var C2C_CERTIFIED_VAL = 2;
        if (this.passportFilter.filterBy.C2cCertified) {
            options.push(['c2c_certified', C2C_CERTIFIED_VAL]);
        }
        var compositionFilterOptions = this.createOptionForRating('rating[rating_composition]', this.passportFilter.filterBy.composition);
        var recycleFilterOptions = this.createOptionForRating('rating[rating_recyclability]', this.passportFilter.filterBy.cycle);
        var energyFilterOptions = this.createOptionForRating('rating[rating_energy]', this.passportFilter.filterBy.energy);
        var waterFilterOptions = this.createOptionForRating('rating[rating_water_stewardship]', this.passportFilter.filterBy.water);
        var socialFilterOptions = this.createOptionForRating('rating[rating_social_fairness]', this.passportFilter.filterBy.social);
        if (!!compositionFilterOptions) {
            options.push(compositionFilterOptions);
        }
        if (!!recycleFilterOptions) {
            options.push(recycleFilterOptions);
        }
        if (!!energyFilterOptions) {
            options.push(energyFilterOptions);
        }
        if (!!waterFilterOptions) {
            options.push(waterFilterOptions);
        }
        if (!!socialFilterOptions) {
            options.push(socialFilterOptions);
        }
        if (this.passportFilter.filterBy.passportWithoutRating) {
            options.push(['include_none_rated', true]);
        }
        this.filterStateService.productPassportList = this.passportFilter;
        return options;
    };
    PassportListComponent.prototype.createOptionForRating = function (rating, filterBy) {
        var optionParam = null;
        if (filterBy.color && !filterBy.black) {
            optionParam = [rating, PASSPORT_RATING.DECLARATION_VALID];
        }
        else if (!filterBy.color && filterBy.black) {
            optionParam = [rating, PASSPORT_RATING.THIRD_PARTY_VALID];
        }
        else if (filterBy.color && filterBy.black) {
            optionParam = [rating, PASSPORT_RATING.DECLARATION_OR_THIRD_PARTY_VALID];
        }
        return optionParam;
    };
    PassportListComponent.prototype.loadRecords = function (page, noSearch) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filterDraftStatuses, pushDraftInStatuses, options;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isRecordsLoaded = false;
                filterDraftStatuses = function () {
                    _this.passportFilter.searchStatus = _this.passportFilter.searchStatus.filter(function (value) { return value !== PASSPORT_STATUS_CONST.DRAFT.value; });
                };
                pushDraftInStatuses = function () {
                    var isExist = _this.passportFilter.searchStatus.find(function (i) { return i === PASSPORT_STATUS_CONST.DRAFT.value; });
                    if (!!isExist) {
                        return;
                    }
                    _this.passportFilter.searchStatus.push(PASSPORT_STATUS_CONST.DRAFT.value);
                };
                if (this.parentModuleName === ModuleNames.SPECIAL_ADMIN) {
                    filterDraftStatuses();
                }
                if (this.canDisplayDraftFilter) {
                    filterDraftStatuses();
                    this.passportFilter.loadDraftPassports ? pushDraftInStatuses() : filterDraftStatuses();
                }
                if (!this.passportFilter.searchStatus.length) {
                    return [2 /*return*/, this.resetSearch()];
                }
                this.loadCreators();
                options = this.getLoadRecordsOptions(page, noSearch);
                // this.records = [] ;
                this.getPage(options, false);
                return [2 /*return*/];
            });
        });
    };
    PassportListComponent.prototype.getPage = function (options, nextPage) {
        var _this = this;
        if (this.isForPublicAccess) {
            options.push(['publicAccess', 1]);
        }
        if (nextPage) {
            this._service.getWithArray(this.getLoadRecordsOptions(++this.currentPage)).subscribe(function (res) {
                var headers = res.headers;
                _this.assignVariables(headers, res);
                res.json().map(function (item) {
                    _this.records = _this.records.concat([new Passport(item)]);
                    _this.updateAlreadyLoadedIndexes(item.id);
                });
                _this.isLoadingNextPage = false;
                _this.isRecordsLoaded = true;
            }, function () { return _this.isRecordsLoaded = true; });
        }
        else {
            this._service.getWithArray(options).subscribe(function (res) {
                var headers = res.headers;
                _this.currentPage = Number(headers.get('X-Pagination-Current-Page')) || 1;
                _this.assignVariables(headers, res);
                _this.alreadyLoadedIndexes = [];
                _this.records = res.json().map(function (item) { _this.updateAlreadyLoadedIndexes(item.id); return new Passport(item); });
                _this.isRecordsLoaded = true;
            }, function () { return _this.isRecordsLoaded = true; });
        }
    };
    PassportListComponent.prototype.assignVariables = function (headers, res) {
        this.count = Number(headers.get('X-Pagination-Page-Count')) || 1;
        this.perPage = Number(headers.get('X-Pagination-Per-Page')) || res.json().length;
        this.totalCount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length;
    };
    PassportListComponent.prototype.loadCreators = function () {
        var _this = this;
        var options = {
            'supervisor': 1,
            'publicAccess': 1,
            'type': PASSPORT_DEPENDENCY.INDEPENDENT
        };
        if (this.createdBy.length > 0) {
            return;
        }
        this._service.getCreatorIds(options).subscribe(function (res) {
            _this.createdBy = res.json().filter(function (item) { return !!item.company_name; }).map(function (item) { return ({
                'key': item.company_name,
                'value': item.company_name
            }); }).sort(function (before, after) { return (before.value).localeCompare(after.value); });
        });
    };
    PassportListComponent.prototype.openRecord = function (record) {
        this._router.navigate([this.parentModuleName === 'guest' ? '/passports' : this.parentModuleName + '/passports', record.id]);
    };
    PassportListComponent.prototype.duplicateRecord = function (record) {
        var _this = this;
        var dialogRef = this.dialog.open(CopyPassportDialogComponent, {
            data: {
                name: record.name,
                parentModule: this.parentModuleName
            }
        });
        dialogRef.afterClosed()
            .flatMap(function (result) {
            return result
                ? _this._service.duplicate(record.id, { name: result, status: PASSPORT_STATUS.DRAFT })
                : Observable.of(null);
        })
            .subscribe(function (duplicate) {
            _this.openRecord(duplicate);
        });
    };
    PassportListComponent.prototype.deleteRecord = function (record) {
        var _this = this;
        record.canDelete()
            .flatMap(function (canDelete) {
            if (canDelete) {
                var dialogRef = _this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        text: _this._confirmDeletionText
                    }
                });
                return dialogRef.afterClosed();
            }
            else {
                return Observable.of(false);
            }
        })
            .flatMap(function (confirmed) {
            return confirmed
                ? record.destroy().map(function () { return true; })
                : Observable.of(false);
        })
            .subscribe(function (deleted) {
            if (deleted) {
                _this.records.splice(_this.records.indexOf(record), 1);
            }
        });
    };
    PassportListComponent.prototype.pageChanged = function (page) {
        this._service.setListPage(page);
        this.loadRecords(page, false);
    };
    PassportListComponent.prototype.removeTag = function (tag, event) {
        event.stopPropagation();
        event.preventDefault();
        this.passportFilter.tags.splice(this.passportFilter.tags.indexOf(tag), 1);
        this.loadRecords();
    };
    PassportListComponent.prototype.removeCategoryTag = function (tag, event) {
        event.stopPropagation();
        event.preventDefault();
        this.passportFilter.categoryTags.splice(this.passportFilter.categoryTags.indexOf(tag), 1);
        this.loadRecords();
    };
    PassportListComponent.prototype.navigateToCreatePassport = function () {
        var _this = this;
        if (this._currentUserService.isSpecialAdmin) {
            this.openManufacturerGenericDialog(function (data) {
                if (data && data.isValidated) {
                    var value = data.value;
                    _this._router.navigate([_this.parentModuleName + '/passports/new', { type: value }], {});
                }
            });
        }
        else {
            this._router.navigate([this.parentModuleName + '/passports/new']);
        }
    };
    PassportListComponent.prototype.openManufacturerGenericDialog = function (cb) {
        var dialogRef = this.dialog.open(ManufacturerGenericComponent, {
            position: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            cb(result);
        });
    };
    PassportListComponent.prototype.openNomenclatureDialog = function (materiallist) {
        var _this = this;
        // MdDialogConfig
        var dialogRef = this.dialog.open(ViewNomenclatureDialogComponent, {
            height: '80%',
            width: '80%',
            position: {}
        });
        materiallist.blur();
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.passportFilter.tags.push(result);
                _this.loadRecords();
            }
        });
    };
    PassportListComponent.prototype.toggleState = function (state) {
        this.passportFilter.searchStatus = getSearchStatus(state, this.passportFilter.searchStatus);
        this.loadRecords();
    };
    PassportListComponent.prototype.askForChangeInNomenclatureOpenDialog = function () {
        this._mdDialog.open(NomenclatureChangeDialogComponent);
    };
    PassportListComponent.prototype.isSpecialAdminOrManufactureActiveRole = function () {
        return [ModuleNames.SPECIAL_ADMIN, ModuleNames.MANUFACTURER].includes(this.parentModuleName);
    };
    PassportListComponent.prototype.isAgentOrSupervisorActiveRole = function () {
        return [ModuleNames.AGENT, ModuleNames.SUPERVISOR].includes(this.parentModuleName);
    };
    PassportListComponent.prototype.isPassportRatingExplanationDialogAvailable = function () {
        var _this = this;
        var parentModuleIs = function (module) { return _this.parentModuleName === module; };
        return parentModuleIs(this.moduleNames.MANUFACTURER)
            || parentModuleIs(this.moduleNames.SUPERVISOR) || parentModuleIs(this.moduleNames.GUEST);
    };
    PassportListComponent.prototype.openRatingExplanationDialog = function () {
        this._mdDialog.open(PassportRatingExplanationDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                isActionButtonsShown: false
            } }));
    };
    PassportListComponent.prototype.getItemsAmountText = function () {
        var _this = this;
        var isEng = this._translate.currentLang.toUpperCase() === 'EN';
        var translate = function (value) { return _this._translate.instant(value); };
        return isEng
            ? translate('Found') + " " + this.totalCount + " " + translate('Items').toLowerCase()
            : this.totalCount + " " + translate('items') + " " + translate('Found').toLowerCase();
    };
    PassportListComponent.prototype.openCategoryeDialog = function (categorie) {
        var _this = this;
        categorie.blur();
        var dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
            height: '80%',
            width: '80%',
            position: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.passportFilter.categoryTags.push(result);
                _this.loadRecords();
            }
        });
    };
    PassportListComponent.prototype.showFilterByRatingPopUp = function () {
        this.showFilterByRating = true;
    };
    PassportListComponent.prototype.selectOrDeselectAllRatingFilters = function (event) {
        var _this = this;
        var check = event.checked;
        this.passportFilter.filterBy.C2cCertified = check;
        this.filterList.map(function (key) {
            _this.passportFilter.filterBy[key].color = check;
            _this.passportFilter.filterBy[key].black = check;
        });
        this.loadRecords();
    };
    Object.defineProperty(PassportListComponent.prototype, "isRatingFilterApplied", {
        get: function () {
            var _this = this;
            return this.filterList.map(function (key) {
                return _this.passportFilter.filterBy[key].color || _this.passportFilter.filterBy[key].black;
            }).some(function (a) { return a; })
                || this.passportFilter.filterBy.C2cCertified;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassportListComponent.prototype, "isAllFilterSelected", {
        get: function () {
            var _this = this;
            return this.filterList.map(function (key) {
                return _this.passportFilter.filterBy[key].color && _this.passportFilter.filterBy[key].black;
            }).every(function (a) { return a; })
                && this.passportFilter.filterBy.C2cCertified;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassportListComponent.prototype, "filterList", {
        get: function () {
            var keys = Object.keys(this.passportFilter.filterBy);
            keys.shift();
            return keys;
        },
        enumerable: true,
        configurable: true
    });
    PassportListComponent.prototype.isStatusFilterChecked = function (filteredStatus) {
        return this.passportFilter.searchStatus.some(function (status) { return status === filteredStatus; });
    };
    Object.defineProperty(PassportListComponent.prototype, "canDisplayOwnPassportFilter", {
        get: function () {
            return this.isAgentOrSupervisorActiveRole();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassportListComponent.prototype, "canDisplayDraftFilter", {
        get: function () {
            return this.isAgentOrSupervisorActiveRole();
        },
        enumerable: true,
        configurable: true
    });
    return PassportListComponent;
}());
export { PassportListComponent };
