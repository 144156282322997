import { MdDialogRef } from '@angular/material';
import { Component } from '@angular/core';
import { CategoryService } from '../../service/category.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';

@Component({
  moduleId: module.id,
  templateUrl: 'category-change-dialog.component.html',
 // providers: [CategoryService]
})
export class CategoryChangeDialogComponent {

  material: string;
  description: string;

  busy: boolean = false;

  constructor(public mdDialogRef: MdDialogRef<any>,
              private CategoryService: CategoryService,
              private notification: LogNotificationService) {
  }

  send() {
    if(!this.material) {
      this.notification.error('Material cannot be blank.');
      return;
    }

    if(this.busy) {
      //Don't spam requests if one is already in process
      return;
    }

    this.busy = true;
    this.CategoryService
      .sendChangeCategory(this.material, this.description)
      .subscribe(
        () => this.mdDialogRef.close(true),
        () => {
          this.busy = false;
        }
      );
  }
}
