/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../import-spreadsheet/partials/progress/import-progress-sticky-footer/import-progress-sticky-footer.component.ngfactory";
import * as i2 from "../../../import-spreadsheet/partials/progress/import-progress-sticky-footer/import-progress-sticky-footer.component";
import * as i3 from "@angular/router";
import * as i4 from "../components/admin-reporting/progress/wrapper/report-progress-wrapper.component.ngfactory";
import * as i5 from "../components/admin-reporting/progress/wrapper/report-progress-wrapper.component";
import * as i6 from "../../../shared/services/deepstream.service";
import * as i7 from "../components/admin-reporting/progress/report-progress.service";
import * as i8 from "../services/report.service";
import * as i9 from "./admin.component";
var styles_AdminComponent = [];
var RenderType_AdminComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminComponent, data: {} });
export { RenderType_AdminComponent as RenderType_AdminComponent };
export function View_AdminComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "import-progress-sticky-footer", [], null, null, null, i1.View_ImportProgressStickyFooterComponent_0, i1.RenderType_ImportProgressStickyFooterComponent)), i0.ɵdid(1, 49152, null, 0, i2.ImportProgressStickyFooterComponent, [], null, null), (_l()(), i0.ɵeld(2, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "report-progress-wrapper", [], null, null, null, i4.View_ReportProgressWrapperComponent_0, i4.RenderType_ReportProgressWrapperComponent)), i0.ɵdid(5, 245760, null, 0, i5.ReportProgressWrapperComponent, [i6.DeepStreamService, i7.ReportProgressService, i8.ReportService, i0.NgZone], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AdminComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "admin", [], null, null, null, View_AdminComponent_0, RenderType_AdminComponent)), i0.ɵdid(1, 49152, null, 0, i9.AdminComponent, [], null, null)], null, null); }
var AdminComponentNgFactory = i0.ɵccf("admin", i9.AdminComponent, View_AdminComponent_Host_0, {}, {}, []);
export { AdminComponentNgFactory as AdminComponentNgFactory };
