/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Config } from './env.config';
import { IApiConfig } from 'ng2-adn-common';

export const ApiConfig: IApiConfig = {
  api_url: Config.API,
  isDebug: true,
  options: {
    withCredentials: true
  },
  headers: {
    'Content-Type': 'application/json'
  }
};
