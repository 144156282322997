export const ModuleNames = {
  MANUFACTURER: 'manufacturer',
  SPECIAL_ADMIN: 'special_admin',
  SUPER_ADMIN: 'superadmin',
  DEPOSIT_OWNER: 'deposit-owner',
  SUPERVISOR: 'supervisor',
  AGENT: 'agent',
  SALES: 'sales',
  TSM: 'transform',
  TSMI: 'transform-independent',
  ADMIN: 'admin',
  ESM: 'ecosystem',
  GUEST: 'guest'
};
