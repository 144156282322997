var _a;
export var EDepositLocationStatus;
(function (EDepositLocationStatus) {
    EDepositLocationStatus[EDepositLocationStatus["IN_CONSTRUCTION"] = 1] = "IN_CONSTRUCTION";
    EDepositLocationStatus[EDepositLocationStatus["IN_OPERATION"] = 2] = "IN_OPERATION";
    EDepositLocationStatus[EDepositLocationStatus["STORAGE"] = 3] = "STORAGE";
})(EDepositLocationStatus || (EDepositLocationStatus = {}));
export var DepositLocationStatusName = (_a = {},
    _a[EDepositLocationStatus.IN_CONSTRUCTION] = 'in construction',
    _a[EDepositLocationStatus.IN_OPERATION] = 'in operation',
    _a[EDepositLocationStatus.STORAGE] = 'storage',
    _a);
