import { PassportGtin } from '../models/passport-gtin.class';
import { Observable } from 'rxjs/Observable';
import { forkJoinSafe } from '../../../shared/helpers/fork-join-safe.helper';
import { PassportGtinService } from './passport-gtin.service';
import * as i0 from "@angular/core";
import * as i1 from "./passport-gtin.service";
var PassportGtinBufferService = /** @class */ (function () {
    function PassportGtinBufferService(passportGtinService) {
        this.passportGtinService = passportGtinService;
        this.recordsForSave = [];
        this.recordTransformFunction = function (recordsForSave) { return forkJoinSafe(recordsForSave.map(function (record) { return record.save(); })); };
        this.emitSourceGtin = this.emitSourceGtin.bind(this);
    }
    PassportGtinBufferService.prototype.emitSourceGtin = function (name) {
        var errors = this.getErrorsByData(name, PassportGtin);
        if (!errors.length) {
            var record = new PassportGtin({ code: name, passport_id: this.ownerObject.id });
            return this.emitSource(record);
        }
        else {
            return this.throwFirstErrors(errors);
        }
    };
    PassportGtinBufferService.prototype.emitSourceDelete = function (model) {
        this.recordsForSave.filter(function (item) { return model !== item || !!model.id && model.id !== item.id; });
    };
    PassportGtinBufferService.prototype.emitSource = function (model, validate) {
        if (validate === void 0) { validate = false; }
        var errors = [];
        if (validate) {
            errors = this.getErrors(model);
        }
        if (!errors.length) {
            this.recordsForSave.push(model);
            this.notifierSubscription = this.onNotifierEmit();
            return Observable.of(model);
        }
        else {
            return this.throwFirstErrors(errors);
        }
    };
    PassportGtinBufferService.prototype.throwFirstErrors = function (errors) {
        return Observable.throwError(function () {
            return new Error('invalid format');
        });
    };
    /**
      Inform user that data is invalid, before actually saving it.
      Required in case if, before data saving and user data provision may pass significant time.
      This solution maybe changed in future as it ruins DRY principle
      , because in backend and front end contains the same validation rules.
      @model ActiveRecord, which will be saved in the future.
      @return string[] errorMessages.
     */
    PassportGtinBufferService.prototype.getErrors = function (model) {
        if (model instanceof PassportGtin) {
            return this.gtinValidationRule(model.code);
        }
        return ['Invalid data'];
    };
    PassportGtinBufferService.prototype.getErrorsByData = function (data, activeRecordClass) {
        if (activeRecordClass === PassportGtin) {
            return this.gtinValidationRule(data);
        }
        return ['Invalid data'];
    };
    PassportGtinBufferService.prototype.gtinValidationRule = function (code) {
        return code.trim().match(/^\d{8,14}$/) ? [] : ['Invalid GTIN format: 8 to 14-digit string requested'];
    };
    PassportGtinBufferService.prototype.onNotifierEmit = function () {
        var _this = this;
        if (!this.notifierSubscription) {
            return this.notifier.subscribe(function (data) {
                var childObserver = _this.recordTransformFunction(_this.recordsForSave);
                if (_this.notifierAction) {
                    _this.notifierAction(data, childObserver);
                }
                else {
                    childObserver.subscribe();
                }
            });
        }
        return this.notifierSubscription;
    };
    PassportGtinBufferService.prototype.setNotifier = function (observable, notifierAction) {
        this.notifier = observable;
        this.notifierAction = notifierAction;
    };
    PassportGtinBufferService.prototype.setOwnerObject = function (model) {
        this.ownerObject = model;
    };
    PassportGtinBufferService.prototype.setRecordTransform = function (transformFunction) {
        this.recordTransformFunction = transformFunction;
    };
    PassportGtinBufferService.prototype.unsubcribe = function () {
        this.recordsForSave = [];
        if (this.notifierSubscription) {
            this.notifierSubscription.unsubscribe();
        }
    };
    PassportGtinBufferService.prototype.gtinMultiSaveTransform = function (recordsForSave) {
        var gtins = recordsForSave;
        if (recordsForSave.length) {
            return this.passportGtinService.saveMultipleWithSamePassports(gtins[0].passport_id, gtins.map(function (gtin) { return gtin.code; }));
        }
        Observable.of([]);
    };
    PassportGtinBufferService.ngInjectableDef = i0.defineInjectable({ factory: function PassportGtinBufferService_Factory() { return new PassportGtinBufferService(i0.inject(i1.PassportGtinService)); }, token: PassportGtinBufferService, providedIn: "root" });
    return PassportGtinBufferService;
}());
export { PassportGtinBufferService };
