/**
 * Created by aleksandr on 14.06.17.
 */
import {
  CustomListHintClass,
  CustomListStatusClass,
  CustomListThemeClass,
  CustomListUnitCssClass,
  CustomListUnitValue,
  ECustomListColumnType,
  ECustomListHintClass,
  ECustomListRecordStatus,
  ECustomListThemeClass,
  ECustomListUnit,
  ICustomListColumn,
  ICustomListKeyPair, ICustomListMenu,
  ICustomListMenuOption,
  ICustomListRecord,
  ICustomListRecordHint,
  ICustomListRecordTooltype,
  ICustomListTheme,
  TCustomMenuValue
} from './interfaces/custom-list.interface';

export class CustomListRecord implements ICustomListRecord {
  independent: boolean;

  primaryKey: string;
  columns: ICustomListColumn[] = [];
  status: ECustomListRecordStatus = ECustomListRecordStatus.NO_STATUS;

  hint: ICustomListRecordHint = null;
  tooltype: ICustomListRecordTooltype = null;
  recordStatus: String = '';
  theme: ICustomListTheme = {
    cssClass: CustomListThemeClass[ECustomListThemeClass.LIGHT],
    value: ECustomListThemeClass.LIGHT
  };


  get statusCssClass() {
    return CustomListStatusClass[this.status];
  }

  static build(): CustomListRecord {
    return new CustomListRecord();
  }

  setStatus(status: ECustomListRecordStatus): this {
    this.status = status;
    return this;
  }

  setIndependent(value: boolean): this {
    this.independent = value;
    return this;
  }

  setHint(value: string, cssClassId: ECustomListHintClass = ECustomListHintClass.WARNING): this {
    this.hint = {value, cssClass: CustomListHintClass[cssClassId]};
    return this;
  }

  setTooltype(value: string, cssClass?: string): this {
    this.tooltype = {value, cssClass};
    return this;
  }

  setTheme(cssClassId: ECustomListThemeClass): this {
    this.theme.value = cssClassId;
    this.theme.cssClass = CustomListThemeClass[cssClassId];
    return this;
  }

  setPrimaryKey(value: string | number): this {
    this.primaryKey= String(value);
    return this;
  }

  addColumn(value: TCustomMenuValue ,
    label?: string | {text: string, english_name: string},
    type: ECustomListColumnType = ECustomListColumnType.TEXT): this {
    this.columns.push({value, label, type});
    return this;
  }

  addTextColumn(value: string): this {
    return this.addColumn(value, '', ECustomListColumnType.TEXT);
  }

  addTitleColumn(value: string, label?: string, title_english_name?: string, subtitle_english_name?: string): this {
    return this.addColumn(title_english_name ? {text: value, english_name: title_english_name} : value,
      subtitle_english_name ? {text: label, english_name: subtitle_english_name} : label,
      ECustomListColumnType.TITLE);
  }

  addUnitColumn(value: string | number, unit: ECustomListUnit, label?: string): this {
    return this.addColumn({
      amount: String(value),
      unit: Number(value) ?  CustomListUnitValue[unit] : CustomListUnitValue[''],
      cssClass: CustomListUnitCssClass[unit]
    }, label, ECustomListColumnType.UNIT);
  }

  addIconColumn(value: string): this {
    return this.addColumn(value, '', ECustomListColumnType.ICON);
  }

  addListColumn(value: ICustomListKeyPair[]): this {
    return this.addColumn(value, '', ECustomListColumnType.LIST);
  }

  addCounterColumn(value: number | string, label: string): this {
    return this.addColumn(String(value), label, ECustomListColumnType.COUNTER);
  }

  addDateColumn(value: string, label?: string): this {
    return this.addColumn(value, label, ECustomListColumnType.DATE);
  }

  addAuthorColumn(value: string): this {
    return this.addColumn(value, '', ECustomListColumnType.AUTHOR);
  }

  addImageColumn(url: string): this {
    return this.addColumn(url, '', ECustomListColumnType.IMAGE);
  }

  addControlsColumn(value: ICustomListMenuOption[] | ICustomListMenu): this {
    if (Array.isArray(value)) {
      value = {options: value, icon: 'more_vert'};
    }
    return this.addColumn(value, '', ECustomListColumnType.CONTROLS);
  }

  setRecordStatus(value: String): this {
    this.recordStatus = value;
    return this;
  }

}
