import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, EventEmitter, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Passport } from '../../models/passport.class';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { MdDialog } from '@angular/material';
import { PassportModificationDialogComponent } from '../../dialogs/passport-modification-dialog/passport-modification-dialog.component';
import { PASSPORT_STATUS_CONST } from '../../values/passport-status.const';
import { PassportService } from '../../services/passport.service';
import { PASSPORT_TAB } from '../../values/passport-tab.const';
import { PASSPORT_MANDATORY_FIELDS } from '../../values/passport-mandatory-fields.const';
import { AlertDialogComponent } from '../../../../shared/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { PERFORMANCE_RADIOS_VALUES } from '../../values/performance-radios.const';
import { CurrentUser } from '../../../../shared/auth/current-user.class';
import { ROLES_WITH_SPECIAL_ADMIN_SHORT } from '../../../../shared/values/role-ids.enum';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { PassportRatingExplanationDialogComponent } from '../../dialogs/passport-rating-explanation-dialog/passport-rating-explanation-dialog';
import { Location } from '@angular/common';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import { PassportValidationNotificationService } from '../../services/passport-validation-notification.service';
import { BaseDialogComponent } from '../../../../app/components/base/dialog/base-dialog.component';
import { CurrentUserService } from '../../../../shared/services/current-user.service';
var PassportEditV2Component = /** @class */ (function (_super) {
    tslib_1.__extends(PassportEditV2Component, _super);
    function PassportEditV2Component(route, router, _passportService, _navigationService, _mdDialog, _fb, cd, _translate, notification, ngZone, location, _passportValidationNotification, filterState, currentUserService) {
        var _this = _super.call(this, route, router) || this;
        _this.route = route;
        _this.router = router;
        _this._passportService = _passportService;
        _this._navigationService = _navigationService;
        _this._mdDialog = _mdDialog;
        _this._fb = _fb;
        _this.cd = cd;
        _this._translate = _translate;
        _this.notification = notification;
        _this.ngZone = ngZone;
        _this.location = location;
        _this._passportValidationNotification = _passportValidationNotification;
        _this.filterState = filterState;
        _this.currentUserService = currentUserService;
        _this.save = new EventEmitter();
        _this.isStatic = false;
        _this.isUploading = false;
        _this.isLastVersion = false;
        _this.moduleNames = ModuleNames;
        _this.passportStatuses = PASSPORT_STATUS_CONST;
        _this.validated = false;
        _this.isToggleChecked = true;
        _this.isInformationShown = true;
        _this.tabs = PASSPORT_TAB;
        _this.ObjectValues = Object.values;
        _this.selectedTab = PASSPORT_TAB.GENERAL;
        _this.emptyMandatoryFields = [];
        _this.isPublicAccess = false;
        _this.isFormEditable = false;
        _this.syncActionInProgress = false;
        _this.isSpecialAdmin = false;
        _this.isSpecialAdmin = _this.currentUserService.isSpecialAdmin;
        _this.form = _this._fb.group({});
        _this.parentModule = _this._navigationService.getModuleName();
        _this.isStatic = (_this.parentModule === _this.moduleNames.SPECIAL_ADMIN);
        _this.supervisorView_premium_passports = false;
        _this.isPublicAccess = _this.route.snapshot.data['isPublicAccess'];
        return _this;
    }
    PassportEditV2Component.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.parentModule == this.moduleNames.SUPERVISOR || this.parentModule == this.moduleNames.AGENT) {
            this.ispassportCreatedbyPremiumAcount();
        }
        this.initializeLastVersion();
    };
    /// check if the passport createed by TSMI or Manufacturer then forbid user from editing the passport
    PassportEditV2Component.prototype.ispassportCreatedbyPremiumAcount = function () {
        var _this = this;
        if (this.passport.user) {
            if (this.passport.user.id != CurrentUser.infoData.id) {
                this.passport.user.roles.forEach(function (element) {
                    ROLES_WITH_SPECIAL_ADMIN_SHORT.forEach(function (role) {
                        if (role.text == element) {
                            _this.isStatic = true;
                            _this.supervisorView_premium_passports = true;
                            return;
                        }
                    });
                });
                return;
            }
        }
        else {
            return;
        }
    };
    PassportEditV2Component.prototype.ngAfterViewInit = function () {
        this.cd.detectChanges();
    };
    PassportEditV2Component.prototype.onExistEntity = function (entity) {
        var _this = this;
        this.passport = entity;
        // TODO seems like this is causing ExpressionChangedAfterItHasBeenCheckedError
        if (!this.passport.isIndependent()) {
            this.supervisorView_premium_passports = this.passport.isIndependent();
        }
        this.passport.loaded = true;
        this.isNewPassport = false;
        if (this.passport.status === this.passportStatuses.ASK_FOR_MODIFICATION.value) {
            this._passportService.getModificationMessage(this.passport.id).subscribe(function (res) {
                _this.modificationMessage = res[0].message;
            });
        }
    };
    PassportEditV2Component.prototype.onNewEntity = function () {
        this.passport = new Passport();
        this.passport.loaded = true;
        this.isNewPassport = true;
    };
    PassportEditV2Component.prototype.onTabSelected = function (tab) {
        this.selectedTab = tab;
    };
    PassportEditV2Component.prototype.getTabClasses = function (tab) {
        var _a;
        return _a = {},
            _a['passport-edit__tabs--' + (tab.toLocaleLowerCase())] = true,
            _a['passport-edit__tabs--tab-active'] = tab === this.selectedTab,
            _a['background-primary-color'] = tab === this.selectedTab,
            _a;
    };
    PassportEditV2Component.prototype.onToggleChanged = function (event) {
        this.isToggleChecked = event.checked;
    };
    PassportEditV2Component.prototype.onChangePassport = function (entity) {
        this.onExistEntity(entity);
    };
    PassportEditV2Component.prototype.onSavePassport = function () {
        var _this = this;
        this.ngZone.run(function () { return _this.router.navigate([_this.parentModule, 'passports']); });
    };
    PassportEditV2Component.prototype.handleUploadStatus = function (isUploading) {
        this.isUploading = isUploading;
    };
    PassportEditV2Component.prototype.clickSave = function () {
        if (this._passportValidationNotification.showNotificationErrors(this.getPassportCtrl())) {
            this.validated = true;
            return;
        }
        this.save.emit({ key: 'updatePassport' });
    };
    PassportEditV2Component.prototype.clickPublish = function () {
        if (this._passportValidationNotification.showNotificationErrors(this.getPassportCtrl())) {
            this.validated = true;
            return;
        }
        this.save.emit({ key: 'publishPassport' });
    };
    PassportEditV2Component.prototype.clickValidate = function () {
        this.save.emit({ key: 'validatePassport' });
    };
    PassportEditV2Component.prototype.clickAskModification = function () {
        this._mdDialog.open(PassportModificationDialogComponent, {
            data: {
                passport: this.passport
            }
        });
    };
    PassportEditV2Component.prototype.clickAskForRating = function () {
        if (!this.isAskForRatingEnabled()) {
            return this.showMissingFieldsPopUp();
        }
        if (this._passportValidationNotification.showNotificationErrors(this.getPassportCtrl())) {
            this.validated = true;
            return;
        }
        return this.openRatingExplanationDialog(true);
    };
    PassportEditV2Component.prototype.getPassportCtrl = function () {
        return this.form.get('passport');
    };
    PassportEditV2Component.prototype.isAskForRatingEnabled = function () {
        var _this = this;
        var passportCtrl = this.getPassportCtrl(); // Passport model cannot be used here because not all fields are updated on data change
        if (!passportCtrl) {
            return false;
        }
        this.updatePassportNameExistence(passportCtrl.get('passportName'));
        this.updatePassportEnglishNameExistence(passportCtrl.get('passportEnglishName'));
        this.updateCategoryExistence(passportCtrl.get('category'));
        this.updateC2cCertificationExistence(passportCtrl.get('c2cCertified'), passportCtrl.get('certificationLevel'), passportCtrl.get('certificationExpiration'));
        var hasValue = function (field) { return !!_this.emptyMandatoryFields.find(function (i) { return i === field; }); };
        var notExistingValues = Object.values(PASSPORT_MANDATORY_FIELDS)
            .map(function (field) { return hasValue(field); })
            .filter(function (e) { return !!e; });
        return !notExistingValues.length;
    };
    PassportEditV2Component.prototype.updateEmptyMandatoryField = function (isExist, mandatoryField) {
        return !isExist
            ? this.addEmptyMandatoryField(mandatoryField)
            : this.removeEmptyMandatoryField(mandatoryField);
    };
    PassportEditV2Component.prototype.updatePassportNameExistence = function (passportNameCtrl) {
        var isExist = !!passportNameCtrl && !!passportNameCtrl.value;
        this.updateEmptyMandatoryField(isExist, PASSPORT_MANDATORY_FIELDS.GENERIC_NAME);
    };
    PassportEditV2Component.prototype.updatePassportEnglishNameExistence = function (passportEnglishNameCtrl) {
        var isExist = !!passportEnglishNameCtrl && !!passportEnglishNameCtrl.value;
        this.updateEmptyMandatoryField(isExist, PASSPORT_MANDATORY_FIELDS.ENGLISH_GENERIC_NAME);
    };
    PassportEditV2Component.prototype.updateCategoryExistence = function (categoryCtrl) {
        var isExist = !!categoryCtrl && !!categoryCtrl.value;
        this.updateEmptyMandatoryField(isExist, PASSPORT_MANDATORY_FIELDS.CATEGORY);
    };
    PassportEditV2Component.prototype.updateC2cCertificationExistence = function (c2cCertifiedCtrl, c2cLevelCtrl, c2cExpirationCtrl) {
        if (!!c2cCertifiedCtrl && c2cCertifiedCtrl.value === PERFORMANCE_RADIOS_VALUES.YES) {
            var isExist = !!c2cLevelCtrl.value && !!c2cExpirationCtrl.value;
            this.updateEmptyMandatoryField(isExist, PASSPORT_MANDATORY_FIELDS.C2C);
        }
        else {
            this.removeEmptyMandatoryField(PASSPORT_MANDATORY_FIELDS.C2C);
        }
    };
    PassportEditV2Component.prototype.addEmptyMandatoryField = function (field) {
        if (!!this.emptyMandatoryFields.find(function (i) { return i === field; })) {
            return;
        }
        this.emptyMandatoryFields = this.emptyMandatoryFields.concat([field]);
    };
    PassportEditV2Component.prototype.removeEmptyMandatoryField = function (field) {
        if (!this.emptyMandatoryFields.find(function (i) { return i === field; })) {
            return;
        }
        this.emptyMandatoryFields = this.emptyMandatoryFields.filter(function (e) { return e !== field; });
    };
    PassportEditV2Component.prototype.showMissingFieldsPopUp = function () {
        var _this = this;
        var getAlertContent = function () {
            var translation = _this._translate.instant(_this.emptyMandatoryFields);
            var translateValues = Object.values(translation);
            return (translation && !!translateValues.length) ? translateValues.join(', ') : null;
        };
        var getAlertTitle = function () { return _this._translate.instant('Missing fields'); };
        this._mdDialog.open(AlertDialogComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: {
                text: getAlertContent(),
                title: getAlertTitle()
            } }));
    };
    PassportEditV2Component.prototype.askForRatingMode = function () {
        return this.parentModule === this.moduleNames.MANUFACTURER;
    };
    PassportEditV2Component.prototype.getPassportTabTooltip = function (tab) {
        var _this = this;
        var translate = function (value) { return _this._translate.instant(value); };
        switch (tab) {
            case PASSPORT_TAB.GENERAL:
                return translate('General information');
            case PASSPORT_TAB.COMPOSITION:
                return translate('Composition');
            case PASSPORT_TAB.PERFORMANCE:
                return translate('Environmental and social performance');
            case PASSPORT_TAB.INFO:
                return translate('Further information');
            case PASSPORT_TAB.CYCLE:
                return translate('Use(s) and cycle');
        }
    };
    PassportEditV2Component.prototype.openRatingExplanationDialog = function (isActionButtonsShown) {
        var _this = this;
        this._mdDialog.open(PassportRatingExplanationDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                onAskForARating: function (token) {
                    if (token) {
                        _this.passport.rating_token = token;
                        _this.passport.rating_token_provided = true;
                    }
                    _this.save.emit({ key: 'askForRating' });
                },
                isActionButtonsShown: isActionButtonsShown
            } }));
    };
    PassportEditV2Component.prototype.onNextTabClick = function ($event) {
        this.selectedTab = $event;
    };
    PassportEditV2Component.prototype.backToPassportList = function () {
        this.location.back();
    };
    PassportEditV2Component.prototype.isPassportPublished = function () {
        return this.passport.status == this.passportStatuses.PUBLISHED.value;
    };
    /**
     * Initialize the isLastVersion attribute value
     */
    PassportEditV2Component.prototype.initializeLastVersion = function () {
        var _this = this;
        if (!this.passport) {
            return;
        }
        if (!this.passport.link_reference) {
            this.isLastVersion = true;
            return;
        }
        var data = {
            passport_id: this.passport.id,
            parent_passport: this.passport.link_reference
        };
        this._passportService.isLastVersion(data).subscribe(function (res) {
            _this.isLastVersion = res;
        });
    };
    /**
     * Change the information concerning the form editability
     * Mainly, the form is editable if the passport is local, and ineditable otherwise
     * @param {boolean} state - true if the form is editable, false else
     */
    PassportEditV2Component.prototype.changeEditable = function (state) {
        this.isFormEditable = state;
    };
    /**
     * Method which checks whether a passport is synchronized or not
     * @returns {boolean} true if the passport is synchronized, false else
     */
    PassportEditV2Component.prototype.isSynchronized = function () {
        return this.passport && !!this.passport.external_passport_id;
    };
    /**
     * Method which checks whether a passport can be synchronized or not
     * @returns {boolean} true if passport can be synchronized, false else
     */
    PassportEditV2Component.prototype.checkSynchronability = function () {
        return !!this.passport && !!this.passport.source && !!this.passport.operator && !!this.passport.documentId;
    };
    /**
     * Method called when the user toggles the sync toggle towards the syncing position
     */
    PassportEditV2Component.prototype.onSynchronize = function () {
        var _this = this;
        var params = {
            source: this.passport.source,
            operator: this.passport.operator,
            document_id: this.passport.documentId
        };
        this._passportService.checkExternalPassport(params).subscribe(function (res) {
            if (res) {
                _this.openSynchronizationModal();
            }
            else {
                _this.openSynchronizationImpossibleModal();
            }
        });
    };
    /**
     * Open the synchronization modal to present the user with synchronization options (Mainly if they want to save before sync)
     */
    PassportEditV2Component.prototype.openSynchronizationModal = function () {
        var _this = this;
        this._mdDialog.open(BaseDialogComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: {
                buttons: this.getSynchronizationButtons(),
                title: "Synchronization",
                paragraphs: ["PASSPORT_SYNCHRONIZATION_POPUP"]
            } })).afterClosed().subscribe(function (reason) { return _this.handleModalClosed(reason); });
    };
    PassportEditV2Component.prototype.buttonIsDisabled = function () {
        return this.syncActionInProgress;
    };
    /**
     * Method which returns the list of buttons present in the synchronization modal
     * @returns {Array<Object>}
     */
    PassportEditV2Component.prototype.getSynchronizationButtons = function () {
        return [
            {
                action: this.synchronizePassport.bind(this, false),
                content: "synchronize",
                isDisabled: this.buttonIsDisabled.bind(this)
            },
            {
                action: this.saveAndSynchronizePassport.bind(this, true),
                content: "saveAndSynchronize",
                isDisabled: this.buttonIsDisabled.bind(this)
            },
        ];
    };
    /**
     * Synchronize a passport according to the synchronization data which it contains
     * Once the sync has been done, reroute to the new passport
     */
    PassportEditV2Component.prototype.synchronizePassport = function () {
        var _this = this;
        this.syncActionInProgress = true;
        var params = this.getSynchronizationData();
        this._passportService.synchronizePassport(params).subscribe(function (res) {
            _this.router.navigate([_this.parentModule + '/passports/', res]).then(function () {
                _this.syncActionInProgress = false;
                window.location.reload();
            });
        }, function (error) {
            _this.syncActionInProgress = false;
        });
    };
    PassportEditV2Component.prototype.saveAndSynchronizePassport = function () {
        var _this = this;
        this.syncActionInProgress = true;
        var associatedObjects = this.getAssociatedObjects();
        var passportToSave = this.preparePassport(this.passport, associatedObjects);
        this._passportService.savePassportManually(passportToSave).subscribe(function (res) {
            _this.synchronizePassport();
        }, function (error) {
            _this.syncActionInProgress = false;
        });
    };
    /**
     * Prepare passport to be saved in the back
     * @param {Passport} passport - The passport to be saved
     * @param {Object} associatedObjects - The objects associated to the passport, and also to be saved
     * @returns {Object} The object to be sent to the backend
     */
    PassportEditV2Component.prototype.preparePassport = function (passport, associatedObjects) {
        return Object.assign({}, {
            additional: passport.additional,
            appearance: passport.appearance,
            c2c_certified: passport.c2c_certified,
            carbon_construction: passport.carbon_construction,
            carbon_conversion: passport.carbon_conversion,
            carbon_conversion_date: passport.carbon_conversion_date,
            carbon_conversion_description: passport.carbon_conversion_description,
            carbon_end: passport.carbon_end,
            carbon_product: passport.carbon_product,
            carbon_total: passport.carbon_total,
            catalogue_product_name: passport.catalogue_product_name,
            category_id: passport.category_id,
            cert_carbon_level: passport.cert_carbon_level,
            cert_material_health_level: passport.cert_material_health_level,
            cert_material_reuse_level: passport.cert_material_reuse_level,
            cert_socials_level: passport.cert_socials_level,
            cert_version: passport.cert_version,
            cert_water_level: passport.cert_water_level,
            certification_expiration: passport.certification_expiration,
            certification_level: passport.certification_level,
            colors: passport.colors,
            components: passport.components,
            composite_name: passport.composite_name,
            conversion: passport.conversion,
            custom_made_name: passport.custom_made_name,
            demountability_default: passport.demountability_default,
            description: passport.description,
            dimension_height: passport.dimension_height,
            dimension_length: passport.dimension_length,
            dimension_width: passport.dimension_width,
            distribution_areas: passport.distribution_areas,
            //documentId: passport.documentId,
            energies: passport.energies,
            energy_construction: passport.energy_construction,
            energy_construction_renewable: passport.energy_construction_renewable,
            energy_cycle: passport.energy_cycle,
            energy_cycle_renewable: passport.energy_cycle_renewable,
            energy_end_of_life: passport.energy_end_of_life,
            energy_end_of_life_renewable: passport.energy_end_of_life_renewable,
            energy_operational_energy_use: passport.energy_operational_energy_use,
            energy_operational_energy_use_renewable: passport.energy_operational_energy_use_renewable,
            energy_operational_water_use: passport.energy_operational_water_use,
            energy_operational_water_use_renewable: passport.energy_operational_water_use_renewable,
            energy_product: passport.energy_product,
            energy_product_renewable: passport.energy_product_renewable,
            energy_resource_recovery: passport.energy_resource_recovery,
            energy_resource_recovery_renewable: passport.energy_resource_recovery_renewable,
            energy_use_stage: passport.energy_use_stage,
            energy_use_stage_renewable: passport.energy_use_stage_renewable,
            english_name: passport.english_name,
            //epdType: passport.epdType,
            //external_passport_id: passport.external_passport_id,
            family: passport.family,
            first_life: passport.first_life,
            for_rating: passport.for_rating,
            format: passport.format,
            gtins: passport.getGtinsForSave(),
            id: passport.id,
            //language: passport.language,
            link_reference: passport.link_reference,
            manufacturer: passport.manufacturer,
            manufacturers: passport.manufacturers,
            materials: passport.materials,
            name: passport.name,
            name_type: passport.name_type,
            next_usages: passport.next_usages,
            no_banned_materials: passport.no_banned_materials,
            carbon_operational_water_use: passport.carbon_operational_water_use,
            operational_energy_use: passport.operational_energy_use,
            operator: passport.operator,
            optimization_strategy: passport.optimization_strategy,
            //otherOperator: passport.otherOperator,
            //otherSource: passport.otherSource,
            percentage_default: passport.percentage_default,
            period: passport.period,
            period_type: passport.period_type,
            product_passport_id: passport.product_passport_id,
            product_refrence: passport.product_refrence,
            purchase_price: passport.purchase_price,
            rating: passport.rating,
            rating_token: passport.rating_token,
            recommendation: passport.recommendation,
            recycle_percentage: passport.recycle_percentage,
            resource_recovery_stage: passport.resource_recovery_stage,
            social_fairness_certified: passport.social_fairness_certified,
            // source: passport.source,
            thumbnail: passport.thumbnail,
            trade_name: passport.trade_name,
            type: passport.type,
            typology: passport.typology,
            unit: passport.unit,
            use_stage: passport.use_stage,
            used_energy: passport.used_energy,
            user_id: passport.user_id,
            water_construction: passport.water_construction,
            water_conversion: passport.water_conversion,
            water_cycle: passport.water_cycle,
            water_end_of_life: passport.water_end_of_life,
            water_operational_energy_use: passport.water_operational_energy_use,
            water_operational_water_use: passport.water_operational_water_use,
            water_resource_recovery: passport.water_resource_recovery,
            water_stewardship_certified: passport.water_stewardship_certified,
            water_use_stage: passport.water_use_stage,
            passport_synchronization_data: this.getSynchronizationData()
        }, associatedObjects);
    };
    /**
     * Get all the objects associated to this passport
     * This includes next usages, distribution_areas, etc...
     * @returns {Object}
     */
    PassportEditV2Component.prototype.getAssociatedObjects = function () {
        return this.passportForm.getAssociatedObjects();
    };
    /**
     * Get the data needed to synchronize a passport
     * @returns  {Object}
     */
    PassportEditV2Component.prototype.getSynchronizationData = function () {
        return {
            source: this.passport.source,
            operator: this.passport.operator,
            document_id: this.passport.documentId,
            passport_id: this.passport.id
        };
    };
    /**
     * Open the modal explaining that the passport cannot be synchronized because no matching external passport was found
     */
    PassportEditV2Component.prototype.openSynchronizationImpossibleModal = function () {
        this._mdDialog.open(BaseDialogComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: {
                buttons: [],
                title: "Synchronization impossible",
                paragraphs: ["PASSPORT_SYNCHRONIZATION_IMPOSSIBLE_POPUP"]
            } })).afterClosed().subscribe(this.handleModalClosed.bind(this));
    };
    /**
     * Method called when user untoggles the synchronization toggle
     * Pass the external passport id to null to indicate that the passport is no longer synchronized
     */
    PassportEditV2Component.prototype.onDesynchronize = function () {
        this.openDesynchronizationModal();
    };
    /**
     * Open the desynchronization modal to confirm they want to desynchronize
     */
    PassportEditV2Component.prototype.openDesynchronizationModal = function () {
        this._mdDialog.open(BaseDialogComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: {
                buttons: this.getDesynchronizationButtons(),
                title: "Desynchronization",
                paragraphs: ["PASSPORT_DESYNCHRONIZATION_POPUP"]
            } })).afterClosed().subscribe(this.handleModalClosed.bind(this));
    };
    /**
     * Method which returns the list of buttons present in the desynchronization modal
     * @returns {Array<Object>}
     */
    PassportEditV2Component.prototype.getDesynchronizationButtons = function () {
        return [
            {
                action: this.desynchronizePassport.bind(this),
                content: "desynchronize"
            },
        ];
    };
    /**
     * Desynchronize the passport
     * This just consists in removing the external_passport_id
     * TODO Maybe we need to save as we ask for confirmation
     */
    PassportEditV2Component.prototype.desynchronizePassport = function () {
        var _this = this;
        this._passportService.desynchronizePassport({ passport_id: this.passport.id }).subscribe(function (res) {
            _this.passport.external_passport_id = null;
            _this._mdDialog.closeAll();
        });
    };
    /**
     * Handle a modal closing
     * @param {string} reason - The reason why the popup was closed
     */
    PassportEditV2Component.prototype.handleModalClosed = function (reason) {
        if (reason === "closed") {
            this.passportSynchronizationInformation.forceToggleState(this.isSynchronized());
        }
    };
    return PassportEditV2Component;
}(EntityViewerComponent));
export { PassportEditV2Component };
