import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { OrderPassportService } from '../services/order-passport.service';
import { OrderMaterial } from './order-material.class';
import { PASSPORT_UNITS } from '../../../entities/passport/values/passport-units.const';
import { ECustomListUnit } from '../../../shared/custom-list/interfaces/custom-list.interface';
import { DatePipe } from '@angular/common';
import { LocaleService } from '../../../shared/services/locale-service';
import { ValidationService } from '../../../shared/services/validation-service';
import {TranslateService} from '@ngx-translate/core';
import {ServiceLocator} from '../../../shared/services/service-locator';
import moment from 'moment';

export class OrderPassport extends ExtendedActiveRecord {
  protected provider = OrderPassportService;
  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  public id: number;
  public name: string;
  public english_name: string;
  public quantity: number;
  public transaction_quantity: number;
  public transaction_date: string;
  public transaction_price: string;
  public transaction_distance: string;
  public transaction_description: string;
  public linked_datetime: string;
  public parent_step_id: number;
  public child_step_id: number;
  public materials: OrderMaterial[];
  public unit: number;
  public description: string;
  public address: string;
  public weight: number;

  public entity: any;

  constructor(data?: any) {
    super(data);
    data = data || {};
    this.materials = (data.materials || []).map(
      (material: any) => new OrderMaterial(material)
    );
  }


  get unit_text() {
    let unit = PASSPORT_UNITS.find((item) => item.id === this.unit);
    return unit ? this.translateService.instant(unit.title) : this.unit;
  }

  get customListUnit() {
    return (<any>{
      'kg': ECustomListUnit.KG,
      'tonne': ECustomListUnit.TON,
      'm2': ECustomListUnit.M2,
      'm3': ECustomListUnit.M3,
      'unit': ECustomListUnit.UNIT,
    })[this.unit_text];
  }

  protected fields() {
    return [
      'id',
      'name',
      'english_name',
      'quantity',
      'transaction_quantity',
      'transaction_date',
      'transaction_price',
      'transaction_distance',
      'transaction_description',
      'linked_datetime',
      'parent_step_id',
      'child_step_id',
      'unit',
      'entity',
      'description',
      'address',
      'weight'
    ];
  }

  beforeSave(data: any): any {
    let dataValues = super.beforeSave(data);
    const getMoment = (date) => moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']);
    const formatDate = (date) => getMoment(date).isValid() ? getMoment(date).format('YYYY-MM-DD') : '';
    if (dataValues.linked_datetime) {
        dataValues.linked_datetime = formatDate(dataValues.linked_datetime);
    }
    if(dataValues.quantity) {
      dataValues.quantity = ValidationService.normalizeQuantity(dataValues.quantity);
    }
    return dataValues;
  }

  setOrResetWeight(data: number, reset: boolean) {
    if (!reset) {
      this.weight = data;
    } else {
      this.weight = null;
    }
  }

  isWeightExist() {
    return !!this.weight || this.weight === 0;
  }
}
