
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IMapMarker } from '../../../../shared/map-view/map-marker.interface';
import { DepositMapMarkersService } from '../../../../shared/services/deposit-map-marker.service.class';
import { Deposit } from '../../models/deposit.class';

@Component({
  moduleId: module.id,
  selector: 'deposit-map',
  templateUrl: 'deposit-map.component.html',
})
export class DepositMapComponent implements OnInit, OnDestroy {

    @Input() updateMap: EventEmitter<Deposit[]>;
    @Input() beforeMapUpdate: EventEmitter<void>;
    @Input() markerInfoWindowCloseUnZoom = true;
    @Output() onChangeDepositLocationIds = new EventEmitter<number[]>();

    updateMapBoundsAction = new EventEmitter();
    closeWindowAction = new EventEmitter();
    zoomAction = new EventEmitter<number>();
    markers: IMapMarker[] = [];
    mapIsReady = false;
    private centerMap = true;
    private denyCenterOnNextUpdateCycle = false;

    constructor(private markerService: DepositMapMarkersService) {}

    ngOnInit(): void {
       this.beforeMapUpdate.subscribe(() => this.beforeMarkerUpdate());
       this.updateMap.subscribe((data: {deposits: Deposit[], totalCount: number}) => this.updateMarkers(data.deposits, data.totalCount));

    }

    beforeMarkerUpdate() {
        this.markerService.resetMarkers();
        this.centerMap = !this.denyCenterOnNextUpdateCycle;
        this.denyCenterOnNextUpdateCycle = false;
    }

    updateMarkers(deposits: Deposit[], totalCount: number) {
         if (!!this.markerService.site && this.isMoreThenOneLocation(this.markerService.site, deposits)) {
            this.closeMarkerInfoWindow();
        }
        this.markerService.updateMarkers(deposits);
        this.markers = this.markerService.markers;
        this.markers.forEach(marker => marker.totalCount = totalCount);
        if (this.markerService.markers.length > 0 && this.centerMap) {
            this.updateMapBoundsAction.emit();
        }
        this.mapIsReady = true;
    }

    private isMoreThenOneLocation(locationId: number, depositList: Deposit[]) {
        return depositList.some(deposit => !!deposit.deposit_location
            && deposit.deposit_location.id !== locationId);
    }

    private closeMarkerInfoWindow() {
        this.markerService.handlePopUpClosed();
        this.closeWindowAction.emit();
        if (this.markerInfoWindowCloseUnZoom) {
            this.zoomAction.emit(-1);
            this.centerMap = false;
        }
    }

    ngOnDestroy(): void {
        this.updateMap.unsubscribe();
        this.beforeMapUpdate.unsubscribe();
        this.markerService.reset();
    }


    handelMarkerClick(marker: IMapMarker) {
        this.markerService.handleMarkerClick(marker);
        this.onChangeDepositLocationIds.emit([marker.id]);
    }

    handleMarkerClose(marker: IMapMarker) {
        if (this.markerService.isOpenPopUpOpen) {
            this.markerService.handlePopUpClosed();
            if (this.markerInfoWindowCloseUnZoom) {
                this.denyCenterOnNextUpdateCycle = true;
                this.zoomAction.emit(-1);
            }
            this.onChangeDepositLocationIds.emit([]);
        }
    }
}
