import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { DepositLocationService } from '../../../../entities/deposit/services/deposit-location.service';
import { DepositLocation } from '../../../../entities/deposit/models/deposit-location.class';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DepositSiteDetailsDialogComponent } from '../../../../entities/deposit/dialogs/deposit-site-details-dialog/deposit-site-details-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { MdDialog } from '@angular/material';
import { EDepositLocationStatus } from '../../../../entities/deposit/values/deposit-location-status.enum';
import { DepositLocationFilter } from '../../../../entities/deposit/models/deposit-location-filter.class';
import { EUsedFilter } from './used-filter.enum';
import { PermissionService } from '../../../../shared/services/permission.service';
import { EDepositOwnerPermission } from '../../values/deposit-owner-permission.enum';
import { PaginationUnmarshallingService } from '../../../../shared/services/pagination-unmarshalling.service';
import { cloneDeep, isEqual } from 'lodash';
import { FormControl } from '@angular/forms';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { EConfirmDialogIcon } from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import { NotificationWarningDialogComponent } from '../../../../shared/notification-dialogs/notification-warning/notification-warning-dialog.component';
import { FilterStateService } from '../../../../shared/services/filterState.service';
import { Router } from '@angular/router';
import { SpinnerDialogComponent } from '../../../../shared/spinner/dialogs/spinner-dialog/spinner-dialog.component';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { first } from 'rxjs/operators';
var DoSitesListComponent = /** @class */ (function () {
    function DoSitesListComponent(depositLocationService, translate, dialog, permissionService, paginationService, filterStorageService, router, navigation) {
        this.depositLocationService = depositLocationService;
        this.translate = translate;
        this.dialog = dialog;
        this.permissionService = permissionService;
        this.paginationService = paginationService;
        this.filterStorageService = filterStorageService;
        this.router = router;
        this.navigation = navigation;
        this.EXPIRED_AS_STATUS = -1;
        this.sortOptions = [
            { field: 'created_at', view: 'Creation date' },
            { field: 'name', view: 'Name' },
        ];
        this.siteStatusOptions = [
            { key: EDepositLocationStatus.STORAGE, value: 'storage' },
            { key: EDepositLocationStatus.IN_CONSTRUCTION, value: 'in construction' },
            { key: EDepositLocationStatus.IN_OPERATION, value: 'in operation' },
            { key: this.EXPIRED_AS_STATUS, value: 'Expired' }
        ];
        this.usedOptions = [
            { key: EUsedFilter.NOT_USED, value: 'Not used' },
            { key: EUsedFilter.USED, value: 'Used' }
        ];
        this.filters = new DepositLocationFilter();
        this.resetFilter = {};
        this.alternativeRules = { search: '' };
        this.siteStatusFilter = this.filters.site_status.concat([this.EXPIRED_AS_STATUS]);
        this.usedFilter = [EUsedFilter.NOT_USED, EUsedFilter.USED];
        this.applyFilters = {
            siteStatus: this.siteStatusFilter,
            used: this.usedFilter,
            search: this.filters.search
        };
        this.records = [];
    }
    Object.defineProperty(DoSitesListComponent.prototype, "isBuildingDO", {
        get: function () {
            return this.permissionService.hasAppliedPermission(EDepositOwnerPermission.BUILDING_DO);
        },
        enumerable: true,
        configurable: true
    });
    DoSitesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.filters.search.valueChanges
            .distinctUntilChanged().debounceTime(300)
            .subscribe(function () { return _this.loadRecords(); });
        this.resetFilter = cloneDeep(this.applyFilters);
        this.filters.pagination = true;
        if (this.isBuildingDO) {
            this.filters.addExtraFilterAsDefault(['site_status', 'active']);
        }
        if (this.filterStorageService.sitesList) {
            this.applyFilters = this.getStoredFilters(this.applyFilters, this.filters);
        }
        this.loadRecords();
    };
    DoSitesListComponent.prototype.getStoredFilters = function (filters, sortFilter) {
        var _a = this.filterStorageService.sitesList, search = _a.search, siteStatus = _a.siteStatus, sort = _a.sort, used = _a.used;
        filters.search.setValue(search.value || '', { emitEvent: false });
        filters.used = used;
        filters.siteStatus = siteStatus;
        sortFilter.sort = sort;
        return filters;
    };
    DoSitesListComponent.prototype.ngOnDestroy = function () {
        this.filterStorageService.sitesList = tslib_1.__assign({}, this.applyFilters, { sort: this.filters.sort });
        this.subscription.unsubscribe();
    };
    DoSitesListComponent.prototype.loadRecords = function (page) {
        var _this = this;
        if (page === void 0) { page = 1; }
        this.depositLocationService.getWithPaginationData(this.buildOptions(page))
            .subscribe(function (res) {
            var sites = _this.updateOptionsAndTransformToModel(res);
            _this.records = sites.map(function (record) { return _this.createRecord(record); });
        });
    };
    DoSitesListComponent.prototype.buildOptions = function (page) {
        var _this = this;
        if (this.usedOptions.length) {
            var used = !!this.applyFilters.used.find(function (item) { return EUsedFilter[EUsedFilter[item]] === EUsedFilter.USED; });
            var notUsed = !!this.applyFilters.used.find(function (item) { return EUsedFilter[EUsedFilter[item]] === EUsedFilter.NOT_USED; });
            this.filters.is_not_used = used !== notUsed ? notUsed : !notUsed;
            this.filters.is_used = used !== notUsed ? used : !used;
        }
        if (page) {
            this.filters.page = page;
        }
        else {
            this.filters.page = 0;
        }
        this.filters.assigned = 1;
        this.filters.site_status = this.applyFilters.siteStatus.filter(function (item) { return item !== _this.EXPIRED_AS_STATUS; });
        this.filters.active = !this.applyFilters.siteStatus.find(function (item) { return _this.EXPIRED_AS_STATUS === item; });
        return this.filters.buildOptions();
    };
    DoSitesListComponent.prototype.updateOptionsAndTransformToModel = function (response) {
        this.filters.updateRecords(response, this.paginationService.unmarshall(response));
        return this.filters.records.map(function (item) { return new DepositLocation(item); });
    };
    DoSitesListComponent.prototype.deleteSearch = function () {
    };
    DoSitesListComponent.prototype.createRecord = function (site) {
        var _this = this;
        var q = function (text) { return _this.translate.instant(text); };
        return CustomListRecord.build().setPrimaryKey(site.id)
            .addTitleColumn(site.name, site.nameAddress)
            .addTextColumn(site.use_count + " " + q('Deposit(s)'))
            .addListColumn([{
                key: q('Created') + ":", value: this.formattedDate(site.created_at), seperator: ' '
            }])
            .addControlsColumn({
            options: [{
                    fn: function (data) { return _this.seeDetails(site); },
                    name: 'See details'
                }].concat((!this.isBuildingDO ? [{
                    fn: function (data) { return _this.deleteSite(site); },
                    name: 'Delete'
                }] : [])),
            cssClass: 'do-sites-list__menu-burger',
            icon: 'menu'
        });
    };
    DoSitesListComponent.prototype.canDeleteSearch = function () {
        var _this = this;
        return Object.keys(this.applyFilters).some(function (key) {
            if (_this.applyFilters[key] instanceof FormControl) {
                return (_this.applyFilters[key].value || '') !== (_this.resetFilter[key].value || '');
            }
            else {
                return !isEqual(_this.applyFilters[key], _this.resetFilter[key]);
            }
        }) && this.records.length;
    };
    DoSitesListComponent.prototype.isSiteStatusEmpty = function () {
        return isEqual(this.applyFilters.siteStatus, this.resetFilter['siteStatus']);
    };
    DoSitesListComponent.prototype.isUsedEmpty = function () {
        return isEqual(this.applyFilters.used, this.resetFilter['used']) || this.applyFilters.used.length === 0;
    };
    DoSitesListComponent.prototype.resetFilers = function () {
        var _this = this;
        Object.keys(this.applyFilters).forEach(function (key) {
            if (_this.applyFilters[key] instanceof FormControl) {
                _this.applyFilters[key].setValue(_this.resetFilter[key].value);
            }
            else {
                _this.applyFilters[key] = cloneDeep(_this.resetFilter[key]);
            }
        });
    };
    DoSitesListComponent.prototype.seeDetails = function (site) {
        var _this = this;
        this.dialog.open(DepositSiteDetailsDialogComponent, {
            data: {
                id: site.id,
                readOnly: this.isBuildingDO,
            }
        }).afterClosed().subscribe(function (changedSite) {
            if (changedSite) {
                _this.loadRecords();
            }
        });
    };
    DoSitesListComponent.prototype.deleteSite = function (site) {
        var _this = this;
        this.depositLocationService.canDelete(site.id).subscribe(function (isDeletable) {
            if (isDeletable) {
                _this.openDeletionDialog(site);
            }
        });
    };
    DoSitesListComponent.prototype.openDeletionDialog = function (site) {
        var _this = this;
        this.dialog.open(ConfirmDialogComponent, {
            data: tslib_1.__assign({}, this.getDeletionDialogCommonData(), { text: this.translate.instant('SITE_DELETION_WARNING') })
        }).afterClosed().subscribe(function (isConfirmed) {
            if (isConfirmed) {
                site.destroy().subscribe(function () { return _this.loadRecords(_this.filters.page); }, function () { return _this.loadRecords(_this.filters.page); });
            }
        });
    };
    DoSitesListComponent.prototype.getDeletionDialogCommonData = function () {
        return {
            subText: this.translate.instant('SITE_DELETION_WARNING_SUBTITLE'),
            confirmText: 'Yes',
            showCross: true,
            showUsualIcon: true,
            cssClass: 'do-sites-list__confirmation-delete-warning',
            showIcon: true,
            iconData: {
                iconType: EConfirmDialogIcon.SVG,
                showIconURL: 'assets/svg/warning--icon.svg'
            }
        };
    };
    DoSitesListComponent.prototype.deleteFiltered = function () {
        var _this = this;
        this.dialog.open(ConfirmDialogComponent, {
            data: tslib_1.__assign({}, this.getDeletionDialogCommonData(), { text: "You have requested the deletion of " + this.filters.totalCount + " sites, corresponding to the selected search criteria." })
        }).afterClosed().subscribe(function (isConfirmed) {
            if (isConfirmed) {
                var process_1 = _this.showProcessSpinner();
                _this.depositLocationService.deleteAllFiltered(_this.filters.buildOptions()).subscribe(function (errors) {
                    _this.loadRecords();
                    process_1.close();
                    if (errors && !!errors.length) {
                        _this.showWarningNotification(errors);
                    }
                }, function () { return process_1.close(); });
            }
        });
    };
    DoSitesListComponent.prototype.showProcessSpinner = function () {
        var _this = this;
        if (!this.processDialog) {
            this.navigation.showNavBar = false;
            this.processDialog = this.dialog.open(SpinnerDialogComponent, {
                disableClose: true,
                data: {
                    caption: 'Delete search results',
                    isLoadingDotsShown: true
                }
            });
            this.processDialog.afterClosed().pipe(first()).subscribe(function () {
                _this.navigation.showNavBar = true;
                _this.processDialog = null;
            });
        }
        return this.processDialog;
    };
    DoSitesListComponent.prototype.showWarningNotification = function (errors) {
        this.dialog.open(NotificationWarningDialogComponent, {
            data: {
                body: "The following sites cannot be deleted because they are used",
                warnings: errors.map(function (item) { return ({ titleText: item.name, text: item.address }); }),
                showCross: true,
                cssClass: 'do-sites-list__notification-warning-dialog',
                iconData: {
                    iconType: EConfirmDialogIcon.TEXT,
                    showIconText: '!',
                    primaryFill: true
                }
            }
        });
    };
    DoSitesListComponent.prototype.createSite = function () {
        return this.seeDetails(new DepositLocation());
    };
    DoSitesListComponent.prototype.importSites = function () {
        this.router.navigate(['/deposit-owner/import-sites']);
    };
    DoSitesListComponent.prototype.formattedDate = function (date) {
        return moment(date, 'YYYY-MM-DD').format('MMM DD,YYYY');
    };
    return DoSitesListComponent;
}());
export { DoSitesListComponent };
