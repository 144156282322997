import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { NextUsage } from '../models/next-usage.class';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class NextUsageService extends CrudService {
  protected namespace = 'next-usages';
  protected namespaceSingular = 'next-usage';
  protected ModelClass = NextUsage;

  public AFFILIATE_BRANCH_EVENT = {
    SAVE: 'save'
  }
}
