/**
 * Created by Aleksandr C. on 8.02.17.
 */
import { EcosystemFileService } from '../../../entities/ecosystem/services/ecosystem-file.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';

export class EcosystemFile extends UploadedDocument {
  protected provider = EcosystemFileService;

  ecosystem_id: number;

  protected fields() {
    return super.fields().concat([
      'ecosystem_id',
    ]);
  }
}
