/**
 * Created by aleksandr on 3.08.17.
 */

import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ICrud } from '../interfaces';
import { ILiteral } from 'ng2-adn-common';
import { Observable } from 'rxjs/Observable';

@Injectable()
export abstract class EntityResolver<T> implements Resolve<T> {

  protected abstract _service: ICrud;
  protected abstract query: ILiteral;

  resolve(route: ActivatedRouteSnapshot): Observable<any | T> {
    console.info('Launch  -> EntityResolver');
    return this._service.view(Number(route.paramMap.get('id')), this.query).catch((err) => {
      console.error(err);
      return null;
    });
  }
}
