import { OnDestroy, OnInit } from '@angular/core';
import { EChatUserStatuses } from '../chat/interfaces/chat-user.interface';
import { ChatService } from '../chat/chat.service';
import { NavigationService } from '../services/navigation.service';
import { ERoleIds } from '../values/role-ids.enum';
import { GlobalEventsManager } from '../services/global-events-manager.service';
import { ChatData } from '../chat/models/chat-data.class';
import { CustomLogger } from '../custom-logger.class';
import { FormControl } from "@angular/forms";
import { TranslatePipe } from "@ngx-translate/core";
var CommonSidebarComponent = /** @class */ (function () {
    function CommonSidebarComponent(_chatService, _navigationService, _globalEvents, translatePipe) {
        this._chatService = _chatService;
        this._navigationService = _navigationService;
        this._globalEvents = _globalEvents;
        this.translatePipe = translatePipe;
        this.isOpen = true;
        this.contactStatuses = EChatUserStatuses;
        this.logger = new CustomLogger(this, 'CommonSidebar');
        this.search = new FormControl();
    }
    Object.defineProperty(CommonSidebarComponent.prototype, "contacts", {
        get: function () {
            return ChatData.contacts;
        },
        set: function (value) {
            ChatData.contacts = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonSidebarComponent.prototype, "groups", {
        get: function () {
            return ChatData.groups;
        },
        set: function (value) {
            ChatData.groups = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonSidebarComponent.prototype, "selectedContact", {
        get: function () {
            return ChatData.selectedContact;
        },
        set: function (value) {
            ChatData.selectedContact = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonSidebarComponent.prototype, "selectedChatroom", {
        get: function () {
            return ChatData.selectedChatroom;
        },
        set: function (value) {
            ChatData.selectedChatroom = value;
        },
        enumerable: true,
        configurable: true
    });
    CommonSidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        var context = this._navigationService.getContext();
        if (context) {
            this.setActiveContextData(context.moduleName);
        }
        this._navigationService
            .changeModule
            .distinctUntilChanged()
            .subscribe(function (moduleKey) {
            _this.setActiveContextData(moduleKey);
        });
        this._globalEvents
            .chatUserClicked$
            .subscribe(function (userId) { return _this.onExternalUserSelected(userId); });
        this.search.valueChanges.debounceTime(400).distinctUntilChanged().do(function (value) { return console.log(value); }).subscribe(function (value) { return _this.setVisibility(value); });
    };
    CommonSidebarComponent.prototype.setVisibility = function (value) {
        this.contacts.forEach(function (contact) {
            var isUsernameMatched = ~contact.contact_username.toLowerCase().indexOf(value.toLowerCase());
            var isContactNameMatched = ~contact.contact_name.toLowerCase().indexOf(value.toLowerCase());
            contact.visible = !!(!value || isUsernameMatched || isContactNameMatched);
        });
    };
    CommonSidebarComponent.prototype.setActiveContextData = function (moduleKey) {
        var _this = this;
        this.closeChat();
        var isGuest = !Boolean(this._navigationService.roleId);
        this.logger.log('GuestMode', isGuest, this._navigationService.roleId);
        if (isGuest) {
            this.onContactsLoaded([]);
        }
        else {
            this
                ._chatService
                .getMyThreads()
                .flatMap(function () { return _this._chatService.getContacts(_this._navigationService.roleId)
                .do(function (contacts) { return _this.onContactsLoaded(contacts); }); })
                .flatMap(function () { return _this._chatService.getSupportContact(_this._navigationService.roleId)
                .do(function (contact) { return _this.onSupportContactLoaded(contact); }); })
                .subscribe();
        }
    };
    CommonSidebarComponent.prototype.onExternalUserSelected = function (userId) {
        var user = this._chatService.contacts.find(function (user) { return Number(user.id) === Number(userId); });
        if (user) {
            this.openChat(user);
        }
        else {
            this.logger.warn('User id:', userId, 'not found', this._chatService.contacts);
        }
    };
    CommonSidebarComponent.prototype.openChat = function (contact) {
        var _this = this;
        this.logger.log('openChat', contact);
        this.closeChat();
        if (contact) {
            contact.selected = true;
            this.selectedContact = contact;
            var room = contact.getRoom();
            if (room) {
                this.activateRoom(room);
            }
            else {
                contact.createRoom()
                    .subscribe(function (room) { return _this.activateRoom(room); });
            }
        }
    };
    CommonSidebarComponent.prototype.activateRoom = function (room) {
        var _this = this;
        room
            .loadContacts()
            .flatMap(function () { return room.loadMessages(); })
            .subscribe(function () {
            _this._chatService.onRoomChanged(room);
            _this.selectedChatroom = room;
            room.readRoom();
            // setTimeout(()  => {
            //   let messageListRef=document.getElementById("messageList");
            //   console.log("label", messageListRef);
            //   console.dir("label", messageListRef);
            //   messageListRef.scrollTop = messageListRef.scrollHeight;
            // }, 200);
        });
    };
    CommonSidebarComponent.prototype.closeChat = function () {
        if (this.selectedContact)
            this.selectedContact.selected = false;
        this.selectedContact = null;
    };
    CommonSidebarComponent.prototype.groupToggle = function (key) {
        var target = this.getGroup(key);
        if (target) {
            target.isOpen = !target.isOpen;
        }
    };
    CommonSidebarComponent.prototype.getGroup = function (key) {
        return this.groups.find(function (group) { return group.name === key; });
    };
    CommonSidebarComponent.prototype.listenForNewChats = function () {
        var _this = this;
        if (this.listenSubscription) {
            this.listenSubscription.unsubscribe();
        }
        this.listenSubscription = this._chatService
            .messageReceived$
            .subscribe(function (message) { return _this.onNewMessageReceived(message); });
    };
    CommonSidebarComponent.prototype.ngOnDestroy = function () {
        if (this.listenSubscription) {
            this.listenSubscription.unsubscribe();
        }
    };
    CommonSidebarComponent.prototype.getGroupKey = function (groupId) {
        var key = 'Unknown';
        switch (groupId) {
            case ERoleIds.ADMIN:
                key = 'Admins';
                break;
            case ERoleIds.AGENT:
                key = 'Agents';
                break;
            case ERoleIds.DEPOSIT_OWNER:
                key = 'Deposit owners';
                break;
            case ERoleIds.ECOSYSTEM_MANAGER:
                key = 'Ecosystem managers';
                break;
            case ERoleIds.SALES_MANAGER:
                key = 'Need managers';
                break;
            case ERoleIds.SUPERVISOR:
                key = 'Supervisors';
                break;
            case ERoleIds.TRANSFORMATION_SITE_MANAGER:
                key = 'Transformation managers';
                break;
            case ERoleIds.TRANSFORMATION_SITE_MANAGER_INDEPENDENT:
                key = 'Transformation managers independent';
                break;
            case ERoleIds.MANUFACTURER:
                key = 'Manufacturers';
                break;
            case ERoleIds.SPECIALADMIN:
                key = 'Special admins';
                break;
        }
        return key;
    };
    CommonSidebarComponent.prototype.addNewContact = function (contact) {
        this.contacts.push(contact);
        var target = ChatData.threads.find(function (thread) { return thread.id === contact.thread_id; });
        if (!target && contact.getRoom()) {
            ChatData.threads.push(contact.getRoom());
        }
        if (contact.isContextChat()) {
            this.addContextChat(contact);
        }
        else {
            this.addRegularChat(contact);
        }
    };
    CommonSidebarComponent.prototype.addRegularChat = function (contact) {
        var key = this.getGroupKey(contact.group);
        var target = this.getGroup(key);
        if (target) {
            target.contacts.push(contact);
        }
        else {
            this.groups.push({ name: key, isOpen: true, contacts: [contact] });
        }
    };
    CommonSidebarComponent.prototype.addContextChat = function (contact) {
        var target = this.getGroup(contact.ecosystem_name);
        if (target) {
            target.contacts.push(contact);
        }
        else {
            this.groups.push({ name: contact.ecosystem_name, isOpen: true, contacts: [contact] });
        }
    };
    CommonSidebarComponent.prototype.onNewMessageReceived = function (message) {
        var _this = this;
        var findContactPredicate = function (contact) {
            return contact.thread && (Number(contact.thread.id) === Number(message.roomId));
        };
        var target = this.contacts.find(findContactPredicate);
        if (!target) {
            this.logger.log('Cant find thread #', Number(message.roomId), 'for message', message.body, 'data', message, this.contacts.map(function (contact) { return contact.name + " [" + contact.thread_id + "]"; }).join(','));
            this._chatService
                .getContacts(this._navigationService.roleId)
                .subscribe(function (contacts) {
                var target = contacts.find(findContactPredicate);
                if (target) {
                    _this.logger.log('Found new chat for message', message, target);
                    _this.addNewContact(target);
                }
            });
        }
    };
    CommonSidebarComponent.prototype.onContactsLoaded = function (contacts) {
        var _this = this;
        this.contacts.forEach(function (contact) { return contact.exitRoom(); });
        this.contacts.length = 0;
        this.groups.length = 0;
        contacts
            .filter(function (contact) { return contact.id !== ChatData.ownerId; })
            .forEach(function (contact) {
            _this.setRoom(contact);
            _this.contacts.push(contact);
        });
        var regularChats = this.contacts.filter(function (contact) { return !contact.isContextChat(); });
        var contextChats = this.contacts.filter(function (contact) { return contact.isContextChat(); });
        regularChats.forEach(function (contact) { return _this.addRegularChat(contact); });
        contextChats.forEach(function (contact) { return _this.addContextChat(contact); });
        this.logger.log('Chat CONTACTS IS', this.contacts);
        this.logger.log('Chat GROUPS IS', this.groups);
        this.listenForNewChats();
    };
    CommonSidebarComponent.prototype.onSupportContactLoaded = function (contact) {
        if (!!contact) {
            contact.exitRoom();
            this.setRoom(contact);
            contact.name = this.translatePipe.transform(contact.name);
            this.supportContact = contact;
            this.logger.log('Chat SUPPORT', this.supportContact);
            this.listenForNewChats();
        }
    };
    CommonSidebarComponent.prototype.setRoom = function (contact) {
        if (contact.thread_id) {
            var target = ChatData.threads.find(function (thread) { return thread.id === contact.thread_id; });
            if (target) {
                contact.setRoom(target).joinRoom();
            }
            else {
                this.logger.warn(contact, '- Thread #', contact.thread_id, 'not found');
            }
        }
        else {
            this.logger.warn(contact, 'has no any threads yet');
        }
    };
    CommonSidebarComponent.prototype.isVisible = function (contact) {
        return contact.visible;
    };
    return CommonSidebarComponent;
}());
export { CommonSidebarComponent };
