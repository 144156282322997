import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

/**
 * Defines the structure for tab information including the name, count, and an optional icon.
 */
export interface Tab {
  name: string; // Name of the tab to be displayed.
  count: number; // Count of items associated with the tab.
  icon?: string; // Optional icon to be displayed with the tab.
}

/**
 * `FilterTabComponent` is now designed to be more generic, capable of rendering any set of tabs provided to it via input.
 * It can display tabs with given names, counts, and optional icons, allowing for a dynamic and configurable tabbed interface.
 */
@Component({
  selector: 'app-filter-tab',
  templateUrl: './filter-tab.component.html',
  styleUrls: ['./filter-tab.component.scss']
})
export class FilterTabComponent implements OnInit {
  @Input() tabs: Tab[] = []; // Accepts an array of `Tab` objects to be displayed.
  @Input() initialTab: string = ''; // Accepts an initial tab name to be selected by default.
  @Output() tabSelected = new EventEmitter<string>(); // Emits the selected tab's name.
  
  currentTab: string = ''; // Tracks the currently selected tab, initialized as an empty string.

  ngOnInit() {
    // Set the initial tab based on `initialTab` input or default to the first tab if not specified or not found
    this.currentTab = (this.initialTab && this.tabs.some(tab => tab.name === this.initialTab)) ? this.initialTab : (this.tabs.length > 0 ? this.tabs[0].name : '');
  }

  /**
   * Handles tab selection, updating the `currentTab` state and emitting the selected tab's name.
   * @param tabName The name of the tab that was selected.
   */
  selectTab(tabName: string): void {
    if (this.currentTab !== tabName) {
      this.currentTab = tabName;
      this.tabSelected.emit(this.currentTab);
    }
  }
}
