/**
 * Created by aleksandr on 29.08.17.
 */

import { OnceBeforeRouteActivatedHook } from '../adn-common/once-before-route-activated.guard';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CurrentUserService } from '../services/current-user.service';
import { ServiceLocator } from '../services/service-locator';

export class CheckAuthHook extends OnceBeforeRouteActivatedHook {
  private _userService: CurrentUserService;
  constructor(
    //private _userService: CurrentUserService
  ) {
    super();
    this._userService = ServiceLocator.injector.get(CurrentUserService);
    console.log('Construct CheckAuthHook');
  }

  beforeRouteActivated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | void {
    console.log('Launch CheckAuthHook');
    return this
      ._userService
      .isAuth()
      .do((isAuth: boolean) => console.log('Launch completed CheckAuthHook -> isAuth', isAuth));
  }

}
