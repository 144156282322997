/**
 * Created by Aleksandr C. on 29.03.17.
 */

import { NgModule } from '@angular/core';
import { NeedModule } from '../../entities/need/need.module';
import { SALES_ROUTES } from './sales.routes';
import { SalesDashboardComponent } from './partials/sales-dashboard/sales-dashboard.component';
import { SalesNeedsArchiveComponent } from './partials/sales-needs-archive/sales-needs-archive.component';
import { ClientModule } from '../../entities/client/client.module';
import { SharedModule } from '../../shared/shared.module';
import { SalesOfferComponent } from './partials/sales-offer/sales-offer.component';
import { SalesOfferDialogComponent } from './dialogs/sales-offer-dialog/sales-offer-dialog.component';
import { SalesGuard } from './sales.guard';
import { RouterModule } from '@angular/router';
import { LoginGuard } from '../../shared/auth/login.guard';
import { PlatformGuard } from '../../shared/auth/platform.guard';
import { SALES_CONTEXT } from './sales.context';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';
import { SalesStatisticsComponent } from './partials/sales-statistics/sales-statistics.component';
import { GlobalEventsManager } from '../../shared/services/global-events-manager.service';

@NgModule({
  imports: [
    RouterModule.forChild([{
      path: SALES_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: SALES_ROUTES
    }]),
    NeedModule,
    ClientModule,
    SharedModule,
  ],
  declarations: [
    SalesDashboardComponent,
    SalesNeedsArchiveComponent,
    SalesOfferComponent,
    SalesOfferDialogComponent,
    SalesStatisticsComponent
  ],
  exports: [],
  entryComponents: [
    SalesOfferDialogComponent,
  ],
  providers: [
    SalesGuard,
  ]
})
export class SalesModule {
}
