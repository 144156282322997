import { Component, DoCheck, OnInit, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MdDialog } from '@angular/material';
import { DepositOwnerListService } from '../../../../deposit-owner/services/deposit-owner-list.service';
import { Searchable } from '../../../../../shared/models/searchable.class';
import {
  ICustomListMenuOption,
  ICustomListRecord
} from '../../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import { DepositService } from '../../../../../entities/deposit/services/deposit.service';
import { Deposit } from '../../../../../entities/deposit/models/deposit.class';
import { DepositDetailsDialogComponent }
  from '../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { IMapMarker } from '../../../../../shared/map-view/map-marker.interface';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { CurrentUserService } from '../../../../../shared/services/current-user.service';
import { DEPOSIT_STATUSES } from '../../../../../entities/deposit/values/deposit-statuses.const';
import { DepositStatus } from '../../../../../entities/deposit/models/deposit-status.class';
import { DepositLocation } from '../../../../../entities/deposit/models/deposit-location.class';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import * as moment from 'moment';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { CustomListDateAvailabilityService } from '../../../../../shared/services/custom-list-date-availability.service';
import { Subscription } from 'rxjs';
import { SiteService } from './../../../../../entities/depositLocation/services/SiteService';

interface IDepositStatusCheckbox {
  depositStatus: DepositStatus;
  checked: boolean;
  text: string;
}

@Component({
  selector: 'supervisor-data-deposit-catalog',
  moduleId: module.id,
  templateUrl: 'supervisor-data-deposit-catalog.component.html'
})
export class SupervisorDataDepositCatalogComponent implements OnInit {
  records: ICustomListRecord[] = [];
  depositOwners: Searchable[];
  searchControl = new FormControl();
  siteIds = [];
  depositOwnerId: number;
  search: string;
  availableDateFrom: number;
  availableDateTo: number;
  sortOrder = '-created_at';

  isLoadingNewPage = false;
  currentPage = 1;
  totalPagesCount = 0;
  totalCount = 0;
  beforeUpdateMap = new EventEmitter<void>();
  updateMap = new EventEmitter<DepositLocation[]>();
  sites: DepositLocation[] = [];
  private mapSubscription: Subscription;

  filterStatuses: IDepositStatusCheckbox[] = [
    { depositStatus: DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION, checked: true, text: 'WAITING_FOR_PUBLICATION' },
    { depositStatus: DEPOSIT_STATUSES.PUBLISHED, checked: true, text: 'PUBLISHED' },
    { depositStatus: DEPOSIT_STATUSES.ASK_FOR_MODIFICATION, checked: true, text: 'ASK_FOR_MODIFICATION' },
    { depositStatus: DEPOSIT_STATUSES.REJECTED, checked: false, text: 'REJECTED' }
  ];

  privacyFilterStatuses = [
    { value: '0', view: 'All company agents' },
    { value: '1', view: 'My agents only' }
  ];
  currentPrivacyFilter = this.privacyFilterStatuses[1];

  isOutOffStock: boolean = false;

  markerAvailableOnLabelText: string;

  constructor(private depositOwnerListService: DepositOwnerListService,
    private _depositService: DepositService,
    private mdDialog: MdDialog,
    private datePipe: DatePipe,
    private _globalEvents: GlobalEventsManager,
    private _currentUserService: CurrentUserService,
    private _translate: TranslateService,
    private navBarState: NavbarStateService,
    public customListDateAvailable: CustomListDateAvailabilityService,
    private siteService: SiteService,
    ) {
    this.depositOwnerListService.get().subscribe((res: Searchable[]) => this.depositOwners = res);
    this.searchControl
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(d => {
        this.search = d;
        this.loadDeposits();
      });

    this._translate.get('Available on').subscribe((translation) => {
      this.markerAvailableOnLabelText = translation;
    });
  }

  ngOnInit() {
    this.loadDeposits();
  }

  ngOnDestroy(){
    if (this.mapSubscription) {
      this.mapSubscription.unsubscribe();
    }
  
  }

  private initSites() {
    this.beforeUpdateMap.emit();
    this.mapSubscription = this.siteService.loadSites({
      supervisor_view: 1,
      ...this.getQuery()
    }).subscribe(sites => {
      this.sites = sites;
      this.updateMap.emit(sites);
    });
  }

  loadDeposits(forPage: number = 1) {
    if (!this.filterStatuses.find((status: IDepositStatusCheckbox) => status.checked)) {
      this.resetResults();
      return;
    }

    let query: any = this.getQuery(forPage);

    if (this.navBarState.multilanguage) {
      query.english = true;
    }

    if (forPage === 1) {
      this.resetResults();
    }

    this.isLoadingNewPage = true;
    this._depositService.getWithPaginationData(query).subscribe((res) => {
      let headers = res.headers;
      this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
      this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
      this.totalCount = Number(headers.get('X-Pagination-Total-Count'));
      res.json()
        .map((item: any) => this._depositService.buildModel(item))
        .forEach((deposit: Deposit) => {
          this.records.push(this.toRecord(deposit));
        });
      this.initSites();
      this.isLoadingNewPage = false;
    });
  }

  private getQuery(forPage = null) {
    return {
      expand: 'passport,creator,owner_name,owner_id,creator_email,deposit_location',
      supervisor: 1,
      available_from: this.availableDateFrom || null,
      available_to: this.availableDateTo || null,
      'sites[]': this.siteIds,
      search: this.search || null,
      'status[]': this.filterStatuses
        .filter((status: IDepositStatusCheckbox) => status.checked)
        .map((status: IDepositStatusCheckbox) => status.depositStatus.value),
      instock: Number(!this.isOutOffStock),
      sort: this.sortOrder || null,
      owner: this.depositOwnerId || null,
      supervisor_view: this.currentPrivacyFilter.value,
      page: forPage
    };
  }

  onEndOfListReached() {
    if (this.currentPage < this.totalPagesCount && !this.isLoadingNewPage) {
      this.loadDeposits(this.currentPage + 1);
    }
  }


  toMarker(data: Deposit): IMapMarker {
    return {
      lat: data.deposit_location.lat || null,
      lng: data.deposit_location.lng || null,
      name: data.name || null,
      id: data.deposit_location_id,
      lines: [
        `${data.quantity ? data.quantity : 0} ${data.passport.unit_text}`,
        `${this.markerAvailableOnLabelText}: ${data.availability_date ? data.availability_date : ''}`
      ]
    };
  }

  toRecord(data: Deposit): ICustomListRecord {
    let availableInListItem = this.customListDateAvailable
      .getColumn(data.availability_date, data.availability_expiration);

    let controls: ICustomListMenuOption[] = [
      {
        name: 'See details',
        fn: () => {
          this.mdDialog.open(DepositDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
            data: {
              readOnly: true,
              id: data.id,
            }
          });
        },
      },
      {
        fn: () => location.href = 'mailto:' + data.creator_email,
        name: 'Send Email'
      },
    ];

    if (data.owner_id !== this._currentUserService.infoData.id) {
      controls.push({
        fn: () => this.openChat(data.owner_id),
        name: 'Chat',
      });
    }

    let condData = (data.conditionData ? data.conditionData.view.replace(/./, c => c.toUpperCase()) : '');
    let locationName = data.deposit_location.name || '';

    return CustomListRecord
      .build()
      .setPrimaryKey(data.id)
      .addTitleColumn(data.name, data.passport.name, null, data.passport.english_name)
      .addUnitColumn(data.quantity ? data.quantity : 0, data.passport.customListUnit, condData)
      .addListColumn([{ key: 'Site', value: locationName }])
      .addListColumn([
        {
          key: 'Created',
          value: this.datePipe.transform(data.created_at, 'dd.MM.y')
        },
        availableInListItem
      ])
      .addAuthorColumn(data.owner_name)
      .addControlsColumn(controls);
  }

  downloadPdfReport(): string {
    return this._depositService.getPdfReportUrl({
      supervisor: 1,
      available_from: this.availableDateFrom || null,
      available_to: this.availableDateTo || null,
      search: this.search || null,
      sort: this.sortOrder || null,
      owner: this.depositOwnerId || null,
      status: this.filterStatuses
        .filter((status: IDepositStatusCheckbox) => status.checked)
        .map((status: IDepositStatusCheckbox) => status.depositStatus.value),
      instock: Number(!this.isOutOffStock),
      supervisor_view: this.currentPrivacyFilter.value,
      timezone_offset: moment().utcOffset()
    });
  }

  openChat(targetId: number) {
    console.log('openChat', targetId);
    this._globalEvents.chatUserClicked$.emit(targetId);
  }

  private resetResults() {
    this.records = [];
    this.beforeUpdateMap.emit();
  }
  setChosenLocationsById(locationId: number[]) {
    this.siteIds = locationId;
    this.loadDeposits();
  }
}
