import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { ERoleIds } from '../../shared/values/role-ids.enum';
import { ADMIN_NAVIGATION } from './admin.navigation';

export const ADMIN_CONTEXT: IRoleContextConfig = {
  navigation: ADMIN_NAVIGATION,
  moduleName: 'admin',
  themeClass: 'b-module-admin',
  apiModuleName: 'admin',
  roleId: ERoleIds.ADMIN,
  roleText: 'Admin',
  homeUrl: '/admin',
  rootPath: 'admin'
};
