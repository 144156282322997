import { Component, Inject } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  templateUrl: 'search-map.component.html',
  styleUrls: ['search-map.component.scss'],
})
export class SearchMapDiagComponent {
  constructor(private dialog: MdDialogRef<any>,
              @Inject(MD_DIALOG_DATA) private dialogData: any,) {

  }

  ngOnInit() {
    console.log(this);
  }
}
