import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Type } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ServiceLocator} from '../../../shared/services/service-locator';
import {DistributionAreaService} from '../services/distribution-area.service';
import { Country } from '../../../shared/models/country.class';

export class PassportDistributionArea extends ActiveRecord {

  protected provider: Type<DistributionAreaService> = DistributionAreaService;
  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  id: number;
  country: Country;
  country_iso: string;

  fields() {
    return ['country', 'country_iso', 'id'];
  }
  constructor(country: Country) {
    super();
    this.country = country;
  }
}
