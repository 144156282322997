<div class="process-data">
  <h1 class="title" *ngIf="location.loaded">{{ location.name | uppercase}}</h1>
  <h1 class="title" *ngIf="!location.loaded">{{ 'Create new deposit site' | translate }}</h1>

  <md-input-container class="name">
    <input mdInput placeholder="{{ 'Site Name' | translate }} *" [disabled]="isViewModeRestricted" [(ngModel)]="location.name">
  </md-input-container>

  <md-input-container class="address">
    <place-autocomplete [options]="{'types': ['geocode'], 'isCreationMode': true}" class="place-autocomplete__full-width">
      <input
        mdInput
        placeholder="{{ 'Address' | translate }} *"
        [(ngModel)]="location.address"
        [disabled]="isViewModeRestricted"
        placeAutocomplete
        (placeChange)="onPlaceChange($event)"
      >
    </place-autocomplete>
  </md-input-container>

  <div class="status-container" *ngIf="isCompanyBuilding">
    <md-select #statusList
               class="select-group"
               class="status-description"
               placeholder="{{ 'Status' | translate }}"
               [disabled]="isViewModeRestricted"
               [(ngModel)]="location.site_status"
    >
      <md-option
        *ngFor="let status of DEPOSIT_LOCATION_STATUS"
        [value]="status.value"
      >{{status.name | translate}}
      </md-option>
    </md-select>
    <md-input-container floatPlaceholder="always" class="status-end-date"
                        [ngClass]="{'status-end-date--error': location.isExpire, 'status-end-date-hide': location.site_status != DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION}"
                        >
      <input autocomplete="off"
             mdInput
             placeholder="{{ 'End of work' | translate }}"
             ng2-datetime-picker
             date-only="true"
             date-format="DD-MM-YYYY"
             parse-format="YYYY-MM-DD"
             [disabled]="isViewModeRestricted"
             [(ngModel)]="location.end_of_work">
    </md-input-container>
    <span class="status-end-date-message" [ngClass]="{'status-end-date-message--error': location.isExpire && location.site_status === DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION}">
      <i class="form-group__label--question status-end-date-message-icon">!</i>
    </span>

    <md-checkbox
    class="status-expiration"
    *ngIf="!location.is_expired_by_superadmin && !location.isExpire"
    (change)="validateExpiration()"
    (click)="validateExpiration()"
    [disabled]="location.active || isViewModeRestricted"
    [ngModel]="location.is_expired_by_superadmin"
    (ngModelChange)="checkExpire($event)"
    >{{'Expired' | translate}}</md-checkbox>

    <md-checkbox
    class="status-expiration"
    *ngIf="checkIsExpiredAndNotConstructionSite()"
    [ngModel]="location.is_expired_by_superadmin"
    (change)="validateExpiration()"
    [disabled]="isViewModeRestricted"
    (click)="validateExpiration()"
    (ngModelChange)="checkExpire($event)"
    ><p [ngClass]="{'status-expiration-text': location.is_expired_by_superadmin}">{{showExpiredSinceOrExpireText(location)}}<span class="status-expiration-text-date">{{location.expired_date | date:'d-MM-y'}}</span></p>
    </md-checkbox>

    <md-checkbox
    class="status-expiration"
    *ngIf="checkIsExpiredConstructionSite()"
    [ngClass]="{'status-expiration--error': location.isExpire}"
    [ngModel]="location.is_expired_by_superadmin"
    [disabled]="location.isExpire || isViewModeRestricted"
    (change)="validateExpiration()"
    (click)="validateExpiration()"
    (ngModelChange)="checkExpire($event)"
    ><span class="status-expiration-text">{{'Expired'| translate}}</span></md-checkbox>
</div>

  <div class="floor-area-wrapper" *ngIf="isCompanyBuilding">
    <md-input-container class="floor-area">
      <input autocomplete="off"
             mdInput
             placeholder="{{ 'Floor area' | translate }}"
             type="number"
             [disabled]="isViewModeRestricted"
             [(ngModel)]="location.floor_area">
    </md-input-container>
    <span class="floor-area-label">{{'sqm' | translate}}</span>
    <md-input-container class="floor-area" [ngClass]="{ 'floor-area--spanish' : checkSpanishLanguage()}">
      <input autocomplete="off"
             mdInput
             placeholder="{{ 'Living area' | translate }}"
             type="number"
             [disabled]="isViewModeRestricted"
             [(ngModel)]="location.living_area">
    </md-input-container>
    <span class="floor-area-label">{{'sqm' | translate}}</span>
  </div>

  <md-input-container class="description">
    <input autocomplete="off" mdInput placeholder="{{ 'Description' | translate }}" [(ngModel)]="location.description">
  </md-input-container>

  <div class="bim-group flex" *ngIf="isCompanyBuilding">
    <md-select class="select-group"
               class="bim-tool"
               placeholder="{{ 'BIM Tool' | translate }}"
               [(ngModel)]="location.bim_tool_id"
    >
      <md-option [value]="-1" (click)="location.bim_tool_id = null"></md-option>
      <md-option
        *ngFor="let bim of BIMs"
        [value]="bim.id"
      >{{bim.name}}
      </md-option>
    </md-select>

    <md-input-container class="model-id" *ngIf="isCompanyBuilding">
      <input autocomplete="off"
             mdInput
             placeholder="{{ 'Model ID' | translate }}"
             type="number"
             [(ngModel)]="location.bim_id">
    </md-input-container>
  </div>

  <div class="documents" *ngIf="isCompanyBuilding">
    <span class="documents-label">{{ 'Documents' | translate }}</span>

    <div class="documents-container">
      <documents-uploader
        class="form-group__content--row passport__uploader documents-uploader"
        [files]="files"
        [uploader]="uploader || null"
        [uploadUrl]="location.id ? location.getFileUploadUrl() : null"
        [enableQueueFileEdit]="uploaderQueueEdit"
        [isRemoveDisabled]="isViewModeRestricted"
        [service]="depositLocationFileService"
        [onUploadAll]="onDocumentSave$"
        [uploadUrlSet]="onUpdateUploadUrl$"
        [autoupload]="false"
        (onAfterAddingFile)="uncheckFiles();"
      ></documents-uploader>
      <md-checkbox class="documents-checked"
                   *ngIf="isCompanyBuilding && !isViewModeRestricted"
                   [ngModel]="location.checked"
                   (ngModelChange)="$event ? location.checked = 1 : location.checked = 0"
      >{{'Documents checked' | translate}}</md-checkbox>
    </div>

  </div>
</div>

