<div class="client__item" (click)="viewClient($event)">
  <!--<div class="need-list__checkbox">
    <input type="checkbox">
  </div>-->
  <div class="client__item-logoname">
    <div class="client__item-name">{{client.name}}</div>
    <div class="client__item-logo"><img *ngIf="client.image" [src]="client.image"/></div>
  </div>
  <div class="client__item-published lowercase">{{client.published_needs}} {{ 'Needs published' | translate }}/
    {{client.treated_needs}} {{ 'Treated' | translate }}
  </div>
  <div class="client__item-date">
    <div class="created">{{ 'Created' | translate }}: {{client.created_at | date: " dd-MM-yyyy HH:mm:ss"}}</div>
    <div class="modified">{{ 'Modified' | translate }}: {{client.updated_at | date: " dd-MM-yyyy HH:mm:ss"}}</div>
  </div>

  <span class="client__item-menu list--icon" [mdMenuTriggerFor]="menu" id="clientItemMenu#{{client.id}}"></span>
  <md-menu #menu="mdMenu" x-position="before">
    <button md-menu-item (click)="deleteClient(client)">
      <md-icon>delete</md-icon>
      <span>{{ 'Delete' | translate }}</span>
    </button>
    <button md-menu-item (click)="duplicateClient(client)">
      <md-icon>save</md-icon>
      <span>{{ 'Duplicate' | translate }}</span>
    </button>
    <button md-menu-item (click)="openClient(client)">
      <md-icon>edit</md-icon>
      <span>{{ 'Edit' | translate }}</span>
    </button>
  </md-menu>

</div>
