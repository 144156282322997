<div class="reporting">
  <div class="reporting__tabs--main">
    <p
      (click)="setMainTab(mainTabSteps.NEW_REPORT)"
      [ngClass]="{'reporting__tabs--main-active': mainTabStep === mainTabSteps.NEW_REPORT}"
    >
      + {{ 'New report' | translate }}
    </p>
    <p
      (click)="setMainTab(mainTabSteps.ARCHIVE)"
      [ngClass]="{'reporting__tabs--main-active': mainTabStep === mainTabSteps.ARCHIVE}"
    >
      {{ 'Archive' | translate }}
    </p>
  </div>
  <div class="reporting__box">
    <div *ngIf="mainTabStep === mainTabSteps.NEW_REPORT">
      <admin-reports-form
        *ngIf="!reports.report_result"
        (onReports)="setReports($event)"
      >
      </admin-reports-form>
      <div *ngIf="reports.report_result" class="reporting__tabs underlined">
        <div class="reporting__tabs--secondary">
          <ul>
            <li
              *ngIf="reports.carbon_foot_print"
              (click)="setSecondaryTabStep(secondaryTabSteps.CARBON_FOOT_PRINT)"
              [ngClass]="{'reporting__tabs--secondary-active': idSecondaryTabStep === secondaryTabSteps.CARBON_FOOT_PRINT}"
            >
              {{ 'Carbon footprint' | translate }}
            </li>
            <li
              *ngIf="reports.circularity"
              (click)="setSecondaryTabStep(secondaryTabSteps.CIRCULARITY)"
              [ngClass]="{'reporting__tabs--secondary-active': idSecondaryTabStep === secondaryTabSteps.CIRCULARITY}"
            >
              {{ 'Circularity' | translate }}
            </li>
            <li
              *ngIf="reports.material_health"
              (click)="setSecondaryTabStep(secondaryTabSteps.MATERIAL_HEALTH)"
              [ngClass]="{'reporting__tabs--secondary-active': idSecondaryTabStep === secondaryTabSteps.MATERIAL_HEALTH}"
            >
              {{ 'Material health' | translate }}
            </li>
            <li
              *ngIf="reports.financial_residual_value"
              (click)="setSecondaryTabStep(secondaryTabSteps.FINANCIAL_RESIDE_VALUE)"
              [ngClass]="{'reporting__tabs--secondary-active': idSecondaryTabStep === secondaryTabSteps.FINANCIAL_RESIDE_VALUE}"
            >
              {{ 'Residual value' | translate }}
            </li>
          </ul>
        </div>
      </div>
      <section *ngIf="reports.report_result" class="reporting-dashboard__section">
        <div
          class="reporting-dashboard__content"
          *ngIf="idSecondaryTabStep === secondaryTabSteps.CARBON_FOOT_PRINT && !!reports.carbon_foot_print"
        >
          <carbon-footprint
            *ngIf="reports.withPrevisualisation"
            [reports]="reports"
            [changeTab]= "setMainTabCallback"
          >
          </carbon-footprint>
          <ng-container *ngIf="!reports.withPrevisualisation" [ngTemplateOutlet]="noPrevisualisation"></ng-container>

        </div>
        <div
          class="reporting-dashboard__content"
          *ngIf="idSecondaryTabStep === secondaryTabSteps.CIRCULARITY && !!reports.circularity"
        >
          <circularity
          *ngIf="reports.withPrevisualisation"
           [reports]="reports"
           [changeTab]= "setMainTabCallback"
          >
          </circularity>
          <ng-container *ngIf="!reports.withPrevisualisation" [ngTemplateOutlet]="noPrevisualisation"></ng-container>
        </div>
        <div
          class="reporting-dashboard__content"
          *ngIf="idSecondaryTabStep === secondaryTabSteps.MATERIAL_HEALTH && !!reports.material_health"
        >
          <material-health
          *ngIf="reports.withPrevisualisation"
          [report]="reports"
          [changeTab]= "setMainTabCallback">
          </material-health>
          <ng-container *ngIf="!reports.withPrevisualisation" [ngTemplateOutlet]="noPrevisualisation"></ng-container>
        </div>
        <div
          class="reporting-dashboard__content"
          *ngIf="idSecondaryTabStep === secondaryTabSteps.FINANCIAL_RESIDE_VALUE && !!reports.financial_residual_value"
        >
          <residual-value
            *ngIf="reports.withPrevisualisation"
            [report]="reports"
            [changeTab]= "setMainTabCallback"
            ></residual-value>
          <ng-container *ngIf="!reports.withPrevisualisation" [ngTemplateOutlet]="noPrevisualisation"></ng-container>

        </div>
      </section>
    </div>
    <div *ngIf="mainTabStep === mainTabSteps.ARCHIVE">


      <div class="reporting__message"><i>{{'LAST_REPORT_NOT_APPEAR_MESSAGE' | translate}}</i></div>
      <div></div>
      <ul class="reporting__list">
        <h2 class="reporting__labels">
          <label class="reporting__labels-number">{{"Report number"|translate}}</label>
          <label class="reporting__labels-date">{{"Creation date"|translate}}</label>
          <label class="reporting__labels-site">{{"Site(s)" | translate}}{{' & '}}{{'Tag(s)' | translate}}</label>
          <label class="reporting__labels-type">{{"Report types(s)"|translate}}</label>
        </h2>
        <li class="reporting__item" *ngFor="let report of records">
          <div class="reporting__item-number">
             n° {{report.report_nr}} <i class="icon copy--icon pointer" (click)="copyId(report.id)"></i>
          </div>
          <div class="reporting__item-data">
            {{getDateAsString(report) | lowercase}}
          </div>
          <div *ngIf="report.sites" class="reporting__item--site-container" [attr.title]="getTitle(report.sites)">
            <ng-container *ngIf="report.sites?.length === 0">
              <span class="reporting__item--site" *ngIf="report.created_by_file">{{'Generated by spreadsheet' | translate}}</span>
              <span class="reporting__item--site reporting__item--site-name" *ngIf="!report.created_by_file">{{'All sites' | translate}}</span>
            </ng-container>
            <ng-container *ngIf="!!report.getSnapshot(); else siteSelectionDefault">
              <span class="reporting__item--site" *ngFor="let site of report.getSnapshot()">
                  <div>
                    <span class="reporting__item--site-name">{{site.site_name}}</span>
                    <span class="reporting__item--site-tags">{{getTagsAsString(site.tag_name)}}</span>
                  </div>
              </span>
            </ng-container>
            <ng-template #siteSelectionDefault *ngIf="report.sites?.length === 0 && !report.created_by_file">
                <span  class="reporting__item--site">{{'Site selection' | translate}}</span>
            </ng-template>
            <!--
            <ng-container *ngFor="let site of report.sites.slice(0, 3); let i=index ; let last = last;">
              <span *ngIf="i<2">
                <div class="reporting__item--site">{{site.name}}</div><span *ngIf="!last">,</span>
              </span>
              <span *ngIf="i===2">
                <div class="reporting__item--site">{{report.sites?.length < 4 ? site.name : site.name + '...'}}</div>
              </span>
            </ng-container>
            -->
          </div>
          <div class="reporting__item-type"           
          appCustomTooltip 
          [tooltipClass]="['admin-reporting-tooltip']"
          appCustomTemplateTooltip
          [customTemplate]="getTypesForTooltips(report)" 
          md-tooltip="{{'⁣'}}">
            {{getTypeAsString(report)}}
          </div>
          <div class="reporting__item-controls"
               [ngClass]="{'reporting__item-controls--zipfile': !!report.zipfile_link}">
            <div class="reporting__item-controls--upload"
                 (click)="uploadRecord(report)"
                 *ngIf="!!report.zipfile_link"></div>

            <div class="reporting__item-controls--delete"
                 (click)="deleteRecord(report)"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #noPrevisualisation>
  <div class="no-prev">
    <div class="no-prev-header">
      <b>{{ 'SUMMARY_NOT_AVAILABLE_HEADER' | translate }}</b>
    </div>
    <div class="no-prev-body">
      <div class="no-prev-body-content">
        <b>{{ 'SUMMARY_NOT_AVAILABLE_BODY_1' | translate }}</b>
      </div>
      <div class="no-prev-body-content">
        {{ 'SUMMARY_NOT_AVAILABLE_BODY_2' | translate }}
      </div>
    </div>
  </div>

</ng-template>