<ul class="service-area__list service-area">
  <li class="service-area__item"
      *ngFor="let sector of transform.sectors;
      let index = index; let last = last;">
    <button class="delete service-area__icon primary-color" *ngIf="!disabled" (click)="remove(sector)"></button>

    <md-input-container class="service-area__input address">
      <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
        <input
          mdInput
          name="site"
          [disabled]="disabled"
          placeholder="{{'Site or google region' | translate}} *"
          placeAutocomplete
          required
          (placeChange)="save($event, sector)"
          [(ngModel)]="sector.name"
          (change) = "onTextChange(sector)"
        >
      </place-autocomplete>
    </md-input-container>

    <button class="add service-area__icon primary-color" *ngIf="last && !disabled" (click)="append()"></button>
  </li>
</ul>
