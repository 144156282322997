import * as tslib_1 from "tslib";
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { StakeholderService } from '../services/stakeholder.service';
import * as _ from 'lodash';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import { STAKEHOLDER_TYPES } from '../values/stakeholder-types.const';
var Stakeholder = /** @class */ (function (_super) {
    tslib_1.__extends(Stakeholder, _super);
    function Stakeholder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = StakeholderService;
        _this.plumb_type = ECOSYSTEM_STEP_TYPE.STAKEHOLDER;
        return _this;
    }
    Object.defineProperty(Stakeholder.prototype, "typeView", {
        //public status:number;
        get: function () {
            return (STAKEHOLDER_TYPES[this.type] || '').toLowerCase().replace(/./, function (s) { return s.toUpperCase(); });
        },
        enumerable: true,
        configurable: true
    });
    Stakeholder.prototype.fields = function () {
        return [
            'id',
            'name',
            'surname',
            'company_name',
            'address',
            'type',
            'email',
            'function',
            'phone',
            'description',
            'created_at',
            'updated_at'
        ];
    };
    Stakeholder.prototype.includesText = function (searchText) {
        searchText = searchText.toLocaleLowerCase();
        var doesNameInclude = _.includes(this.name.toLocaleLowerCase(), searchText);
        return doesNameInclude;
    };
    return Stakeholder;
}(ExtendedActiveRecord));
export { Stakeholder };
