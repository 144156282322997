import { Component, Inject } from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  template: `
    <view-offer
      [ecosystem]="mdDialogData.ecosystem"
      (action)="mdDialogRef.close()"
    ></view-offer>
  `,
})
export class EcosystemOfferDialogComponent {
  constructor(public mdDialogRef: MdDialogRef<any>,
              @Inject(MD_DIALOG_DATA) public mdDialogData: any) {
  }
}
