/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'ecosystem-description',
  templateUrl: 'ecosystem-description.component.html',
  styleUrls: ['ecosystem-description.component.scss'],
})
export class EcosystemDescriptionDialogComponent implements OnInit, OnDestroy {

  constructor(private _dialog: MdDialogRef<any>,) {
  }

  @Input() view_description: string;

  ngOnInit() {
    this.view_description = this._dialog._containerInstance.dialogConfig.data.view_description;
    console.log('previous description' + this.view_description);
  }

  onSave() {
    this._dialog.close(this.view_description);
  }

  ngOnDestroy() {
    console.log('description dialogue -> onDestroy');
  }
}
