import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/switchMap';

import { ClientService } from '../service/client.service';
import { DetailService } from '../service/detail.service';
import { Client } from '../model/client.class';
import { FormControl } from '@angular/forms';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { MdDialog } from '@angular/material';
import { Contact } from '../model/contact.class';
import { ContactService } from '../service/contact.service';
import { ClientLocation } from '../model/location.class';
import { LocationService } from '../service/location.service';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';
import {CurrentUser} from '../../../shared/auth/current-user.class';

@Component({
  moduleId: module.id,
  selector: 'sd-clients',
  templateUrl: 'client-list.component.html',
  styleUrls: ['client-list.component.scss'],
})

export class ClientListComponent implements OnInit {
  clientList: Client[];
  searchControl = new FormControl();
  searchTerm = '';

  //newClient: Client = new Client();

  public selectedId: number;

  private _confirmDeletionText: string;
  private _newClientName: string = 'New Recipient';

  constructor(private _translate: TranslateService,
              private _clientService: ClientService,
              private _detailService: DetailService,
              private _contactService: ContactService,
              private _locationService: LocationService,
              private _router: Router,
              public dialog: MdDialog,) {
    this._translate.get('CONFIRM_DELETE_CLIENT').subscribe((translation) => {
      this._confirmDeletionText = translation;
    });

    this._translate.get('New Recipient').subscribe((translation) => {
      this._newClientName = translation;
    });
  }

  ngOnInit() {
    this.getList();
    this
      .searchControl
      .valueChanges
      .debounceTime(400)
      .subscribe((term: string) => {
        this.searchTerm = term.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      });
    this.getList();
  }

  getList() {
    this._clientService
      .get<Client>({expand: 'published_needs,treated_needs,client_contacts,client_locations', sort: '-created_at'})
      .subscribe(
      value => {
        this.clientList = value.filter(e => e.user_id === CurrentUser.infoData.id);
      },
      err => console.log(err)
    );
  }

  onSelect(client: Client) {
    this._detailService.setDetailClient(client);
    this._router.navigate(['sales/clients/', client.id]);
  }

  copyClient(client: Client) {
    this._clientService.create(client).subscribe(
      value => {
        this.clientList.push(value);
        console.log(value);
      },
    );
  }

  deleteClient(client: Client) {
    console.log('delete ' + client);

    this._clientService.remove(client.id).subscribe(
      () => {//this.clientList=this.clientList.filter(item=>item!==client)
        let index = this.clientList.indexOf(client);
        this.clientList.splice(index, 1);
      }
    );
  }

  addClient() {
    let client = new Client({name: this._newClientName});
    client.build().save().subscribe(
      value => {
        this._detailService.setDetailClient(value);
        this._router.navigate(['sales/clients', value.id]);
      },
      err => console.log(err)
    );
  }

  openRecord(record: Client) {
    this._router.navigate(['sales/clients', record.id]);
  }

  deleteRecord(record: Client) {

    record.canDelete()
      .flatMap((canDelete) => {
        if(canDelete) {
          let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              text: this._confirmDeletionText,          //'Do you really want to delete this client definitively?'
            }
          });

          return dialogRef.afterClosed();
        } else {
          return Observable.of(false);
        }
      })
      .flatMap((confirmed) => {
        return confirmed
          ? record.destroy().map(() => true)
          : Observable.of(false);
      })
      .subscribe((deleted) => {
        if(deleted) {
          this.clientList.splice(this.clientList.indexOf(record), 1);
          this.clientList = this.clientList.map((record) => record);
          console.log(this.clientList);
        }
      });
  }

  duplicateRecord(record: Client) {
    console.log('record: ', record);
    record.duplicate().subscribe((res: Client) => {
      console.log('copy of record: ', res);

      record.client_contacts.forEach((contact: Contact, index: number) => {
        if (index === (record.client_contacts.length - 1)) return;

        this
          ._contactService
          .create(new Contact({
            client_id: res.id,
            name: contact.name,
            function: contact.function,
            phone: contact.phone,
            email: contact.email,
          }))
          .subscribe((result: Contact) => console.info(result));
      });

      record.client_locations.forEach((location: ClientLocation, index: number) => {
        if (index === (record.client_locations.length - 1)) return;

        this
          ._locationService
          .create(new ClientLocation({
            client_id: res.id,
            name: location.name,
            address: location.address,
            description: location.description,
          }))
          .subscribe((result: ClientLocation) => console.info(result));
      });

      this.clientList.splice(this.clientList.indexOf(record), 0, res);
      this.clientList = this.clientList.map((record) => record);
    }, (err) => console.log('duplicateRecord -> ', err));
  }

  isSelected(client: Client) {
    return client.id === this.selectedId;
  }

  search(term: string) {
    // this.filteredClientList = this.clientList.filter((client) =>
    //   client.name.toLocaleLowerCase().indexOf(term) !== -1
    // );
  }

  // editClient(client:Client){
  //   this.clientService.update(this.newClient).subscribe(
  //     value => { let index=this.clientList.indexOf(client); this.clientList[index]=value;},
  //     err => console.log(err)
  //   );
  // }

}
