import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ESelectionListOpenMode } from './values/selection-list-open-mode.enum';
var CustomSelectionListWithChipComponent = /** @class */ (function () {
    function CustomSelectionListWithChipComponent(translateService) {
        this.translateService = translateService;
        this.LIST_OPEN_MODE = ESelectionListOpenMode;
        this.allVariants = [];
        this.chosenItems = [];
        this.disabledChip = false;
        this.disabledList = false;
        this.isItemChecked = this.defaultCheck;
        this.listOpenMode = ESelectionListOpenMode.INPUT_CLICK;
        this.placeholder = '';
        this.floatPlaceholder = 'auto';
        this.onChange = new EventEmitter();
        this.onDelete = new EventEmitter();
        this.listIsVisible = false;
    }
    Object.defineProperty(CustomSelectionListWithChipComponent.prototype, "disabled", {
        set: function (isDisabled) {
            this.disabledChip = isDisabled;
            this.disabledList = isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    CustomSelectionListWithChipComponent.prototype.onClick = function (targetElement) {
        if (!this.container.nativeElement.contains(targetElement)) {
            this.listIsVisible = false;
        }
    };
    CustomSelectionListWithChipComponent.prototype.defaultCheck = function (item) {
        return false;
    };
    CustomSelectionListWithChipComponent.prototype.openList = function (openMode) {
        if (openMode === this.listOpenMode) {
            this.listIsVisible = !this.disabledList;
        }
    };
    CustomSelectionListWithChipComponent.prototype.removeItem = function (item) {
        if (!this.disabledChip) {
            this.onDelete.emit(item);
        }
    };
    CustomSelectionListWithChipComponent.prototype.onItemChange = function (event, item) {
        this.onChange.emit({ value: item, check: !!event['checked'] });
    };
    return CustomSelectionListWithChipComponent;
}());
export { CustomSelectionListWithChipComponent };
