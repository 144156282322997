import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-toggle',
  templateUrl: './base-toggle.component.html',
  styleUrls: ['./base-toggle.component.scss']
})
export class BaseToggleComponent implements OnInit {
  @Input() isChecked: boolean = false;
  @Input() label?: string;
  @Input() fontSize: string = '14px'; // Default font size
  @Input() sliderColor: string = '#adb5bd'; // Default slider color
  @Input() sliderColorChecked: string = '#007bff'; // Default slider color when checked
  @Input() backgroundColor: string = '#fff'; // Default background color
  @Output() isCheckedChange = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.updateSliderStyles();
  }

  onChange(event: any) {
    this.isChecked = event.target.checked;
    this.isCheckedChange.emit(this.isChecked);
  }

  updateSliderStyles() {
    document.documentElement.style.setProperty('--slider-color', this.sliderColor);
    document.documentElement.style.setProperty('--slider-color-checked', this.sliderColorChecked);
    document.documentElement.style.setProperty('--background-color', this.backgroundColor);
  }
}
