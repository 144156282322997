import { Component, ViewChild } from '@angular/core';
import { MdDialog } from '@angular/material';
import { AgentService } from '../../../entities/passport/services/agent.service';
import { PassportCollectionComponent } from '../../../app/components/passport/passport-Collection/passport-collection.component';
import { Tab } from '../../../dashboards/manufacturer/components/filter-tab.component';
import { DashboardComponentBase, IFilterState } from '../../../app/components/base/dashboard/dashboard-component-base.directive';
import { Passport } from '../../../entities/passport/models/passport.class';
import { CopyPassportDialogComponent } from '../../../entities/passport/dialogs/copy-passport-dialog/copy-passport-dialog.component';
import { Router } from '@angular/router';
import { PassportRatingExplanationDialogComponent } from '../../../entities/passport/dialogs/passport-rating-explanation-dialog/passport-rating-explanation-dialog';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import { NavbarStateService } from '../../../shared/services/navbar.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { DIALOG_THEME } from '../../../shared/helpers/dialog-themes.const';

enum AgentTabs {
  Published = 'published',
  Drafts = 'Drafts',
}

export interface IAgentFilterState extends IFilterState {
  showMyPassports: boolean;
  status: number[];
  company_creators: string[];
}

@Component({
  selector: 'app-agent-passport-dashboard',
  templateUrl: './agent-passport-dashboard.component.html',
  styleUrls: ['./agent-passport-dashboard.component.scss']
})
export class AgentPassportDashboardComponent extends DashboardComponentBase<IAgentFilterState> {
  currentTab: AgentTabs = AgentTabs.Published;
  constTabsNamesPublished: string = "PublishedPlural";
  constTabsNamesDrafts: string = "Drafts";

  @ViewChild('passportCollectionRef') passportCollection: PassportCollectionComponent;

  tabs: Tab[] = [
    { name: null, count: 0, icon: null },
    { name: null, count: 0, icon: 'pen' }
  ];

  constructor(public dialog: MdDialog,
    private agentService: AgentService,
    private router: Router,
    private translate: TranslateService,
    private _userService: CurrentUserService,
    private navbarService: NavbarStateService,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  ngOnInit() {
    this.tabs[0].name = this.constTabsNamesPublished;
    this.tabs[1].name = this.constTabsNamesDrafts;
    this.initializeFilterStates();
    this.loadDataForTab();
  }

  private initializeFilterStates() {
    this.pagination = {
      current_page: 1,
      page_count: null,
      total_count: null,
      pagination_per_page: null,
      published_count: null,
      draft_count: null,
    }
    const stored = this.localStorageService.getStoredObject<{ states: { [key: string]: IAgentFilterState }, currentTab: string }>('agentPassportFilters');
    if (stored) {
      this.filterStates = stored.states;
      this.currentTab = stored.currentTab as AgentTabs;
    } else {
      // Default state setup
      this.filterStates[AgentTabs.Published] = this.getDefaultFilterState();
      this.filterStates[AgentTabs.Drafts] = this.getDefaultFilterState();
    }
  }

  public loadDataForTab(resetPage: boolean = true): void {
    if (resetPage) {
      this.pagination.current_page = 1;
      this.passportCollection.clearSelection();
    }
    this.isLoading = true;
    this.passports = []; // Clear current data for safety
    this.pagination.published_count = null;
    this.pagination.draft_count = null;
    this.updateTabCounts();

    // Cancel previous request if still ongoing
    if (this.passportsRequestSubscription) {
      this.passportsRequestSubscription.unsubscribe();
    }

    let currentFilters = this.filterStates[this.currentTab as any] || this.getDefaultFilterState();
    const requestPayload = this.convertFilterStateToObj(currentFilters);
    this.versionReference

    this.localStorageService.storeObject('agentPassportFilters', { states: this.filterStates, currentTab: this.currentTab });

    this.passportsRequestSubscription = this.agentService.getPassports(this.pagination.current_page, 10, requestPayload).subscribe({
      next: (response: any) => {
        this.handleResponse(response);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  private handleResponse(response: any): void {
    const body = JSON.parse(response._body);
    const headers = response.headers;
    this.pagination = {
      current_page: headers.get('X-Pagination-Current-Page'),
      page_count: headers.get('X-Pagination-Page-Count'),
      total_count: headers.get('X-Pagination-Total-Count'),
      pagination_per_page: headers.get('X-Pagination-Per-Page'),
      published_count: headers.get('X-Total-Published'),
      draft_count: headers.get('X-Total-Drafts'),
    }
    this.updateTabCounts();
    this.passports = body.map((item: any) => new Passport(item));
  }

  public resetFiltersToDefault() {
    this.filterStates[this.currentTab] = this.getDefaultFilterState();
    this.loadDataForTab();
  }

  public getDefaultFilterState(): IAgentFilterState {
    return {
      sortBy: 'performance',
      search: '',
      families: [],
      materials: [],
      categories: [],
      searchId: [],
      selectedOptions: [],
      databases: [],
      dataProviders: [],
      dataRepresentativity: [],
      epdType: [],
      rating: {
        passportWithoutRating: false,
        selectRatingFilter: false,
        C2cCertified: false,
        composition: { color: false, black: false },
        cycle: { color: false, black: false },
        energy: { color: false, black: false },
        water: { color: false, black: false },
        social: { color: false, black: false },
      },
      showMyPassports: false,
      status: this.currentTab === AgentTabs.Published ? [3] : [1],
      company_creators: [],
    };
  }

  onTabSelected(selectedTabName: string) {
    this.pagination.current_page = 1;
    this.versionReference = null;
    this.passportCollection.clearVersionSelection();
    this.currentTab = selectedTabName === this.constTabsNamesPublished ? AgentTabs.Published : AgentTabs.Drafts;
    this.filterStates[this.currentTab].status = selectedTabName === this.constTabsNamesPublished ? [3] : [1];
    this.loadDataForTab();
  }

  duplicatePassport(record: Passport) {
    const dialogRef = this.dialog.open(CopyPassportDialogComponent, {
      data: {
        name: record.name,
        parentModule: 'agent'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        this.agentService.duplicatePassports(record.id, result).subscribe({
          next: (response) => {
            const body = JSON.parse(response._body);
            this.isLoading = false;
            this.router.navigate(['agent/passports', body.id]);
          },
          error: (error) => {
            this.isLoading = false;
            console.error('Error duplicating passport:', error);
          }
        });
      }
    });
  }

  updateTabCounts() {
    this.tabs = [
      { ...this.tabs[0], count: this.pagination.published_count },
      { ...this.tabs[1], count: this.pagination.draft_count }
    ];
  }

  onCreateNewPassport() {
    this.router.navigate(['agent/passports/new']);
  }

  openRatingExplanationDialog(): void {
    this.dialog.open(PassportRatingExplanationDialogComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
      data: {
        isActionButtonsShown: false,
      }
    });
  }

  deleteOnePassport(passport: Passport): void {
    if (!passport || passport.user.id != this._userService.infoData.id) return;
    let passportName = passport.getProductName(this.navbarService.multilanguage) + " - V" + passport.version;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        text: this.translate.instant("confirmationPassportDeletion", { passportName: passportName }),
      },
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.performDeletion([passport.id]);
      }
    });
  }

  performDeletion(passportIds: number[]): void {
    this.isLoading = true;
    let payload = {
      passportIds: passportIds,
      isAllPagesSelected: false,
    }
    this.agentService.deletePassports(payload).subscribe({
      next: () => {
        this.isLoading = false;
        this.loadDataForTab();
      },
      error: (error) => {
        this.isLoading = false;
      }
    });
  }
}
