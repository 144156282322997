<div class="ecosystem-deletion-dialog" *ngIf="!deleteConfirmed">
  <h3>{{'Are you sure you want to delete this ecosystem ?' | translate}}</h3>
</div>
<div class="ecosystem-deletion-dialog" *ngIf="deleteConfirmed">
  <h3>{{'Your ecosystem has been successfully deleted' | translate}}</h3>
</div>

<ul class="ecosystem-deletion-dialog-group">
  <li>
    <button (click)="confirmDelete()" md-raised-button *ngIf="!deleteConfirmed">{{'Ok' | translate}} </button>
  </li>
  <li>
    <button (click)="cancel()" md-raised-button *ngIf="!deleteConfirmed">{{'Cancel' | translate}}</button>
  </li>
  <li>
    <button (click)="navigateToIndex()" md-raised-button *ngIf="deleteConfirmed">{{'Ok' | translate}}</button>
  </li>
</ul>