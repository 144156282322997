import { EventEmitter } from '@angular/core';
import { MAP_IMPORT_PROGRESS_STATUS } from '../../values/import-progres.const';
import { ImportProgressService } from '../../services/import-progress.service';
var DepositImportProgressComponent = /** @class */ (function () {
    function DepositImportProgressComponent(importProgressService) {
        this.importProgressService = importProgressService;
        this.MAP_IMPORT_PROGRESS_STATUS = MAP_IMPORT_PROGRESS_STATUS;
        this.onMaximize = new EventEmitter();
    }
    DepositImportProgressComponent.prototype.close = function () {
        this.importProgressService.resetProgressModal();
    };
    DepositImportProgressComponent.prototype.maximize = function () {
        this.onMaximize.emit();
    };
    DepositImportProgressComponent.prototype.getText = function () {
        switch (this.importProgressService.statusImportProgress) {
            case MAP_IMPORT_PROGRESS_STATUS.CHECKING:
                return 'Checking file';
            case MAP_IMPORT_PROGRESS_STATUS.PROGRESS:
                return 'Import in progress';
            case MAP_IMPORT_PROGRESS_STATUS.ERROR:
                return 'Error in file';
            case MAP_IMPORT_PROGRESS_STATUS.SUCCESS:
                return 'Import succesful';
        }
    };
    Object.defineProperty(DepositImportProgressComponent.prototype, "isSuccess", {
        get: function () {
            return MAP_IMPORT_PROGRESS_STATUS.SUCCESS === this.importProgressService.statusImportProgress;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DepositImportProgressComponent.prototype, "isError", {
        get: function () {
            return MAP_IMPORT_PROGRESS_STATUS.ERROR === this.importProgressService.statusImportProgress;
        },
        enumerable: true,
        configurable: true
    });
    return DepositImportProgressComponent;
}());
export { DepositImportProgressComponent };
