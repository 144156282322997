import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {TResidualValueReport} from '../types/residual-value-report.type';
import {IReportResponse} from '../../../../../interfaces/reportResponse.interface';

@Component({
  moduleId: module.id,
  selector: 'residual-value-report',
  templateUrl: 'residual-value-report.component.html',
  styleUrls: ['residual-value-report.component.scss']
})
export class ResidualValueReportComponent implements OnInit {
  @ViewChild('descriptionBlock') public descriptionBlock: ElementRef;

  @Input() type: TResidualValueReport;
  @Input() report: IReportResponse;
  @Input() changeTab: Function;

  ngOnInit(): void {
  }
  removeDescription(): void {
    this.descriptionBlock.nativeElement.remove();
  }
  getType(): string {
    switch (this.type) {
      case 'deconstruction':
        return 'Deconstruction';
      case 'rehabilitation':
        return 'Renovation';
      case 'renewal_building_commercial':
        return 'RENEWAL_BUILDING_COMMERCIAL';
      case 'renewal_building_residantial':
        return 'RENEWAL_BUILDING_RESIDANTIAL';
      case 'renewal_building_institutional':
        return 'RENEWAL_BUILDING_INSTITUTIONAL';
    }
  }
  getDescriptionKey(): string {
    switch (this.type) {
      case 'deconstruction':
        return 'RESIDUAL_VALUE_DESCRIPTION.DECONSTRUCTION';
      case 'rehabilitation':
        return 'RESIDUAL_VALUE_DESCRIPTION.REHABILITATION';
      case 'renewal_building_commercial':
        return 'RESIDUAL_VALUE_DESCRIPTION.RENEWAL';
      case 'renewal_building_residantial':
        return 'RESIDUAL_VALUE_DESCRIPTION.RENEWAL_RESIDANTIAL';
      case 'renewal_building_institutional':
        return 'RESIDUAL_VALUE_DESCRIPTION.RENEWAL_INSTITUTIONAL';
    }
  }
}
