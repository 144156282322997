<div class="category-search-container">
    <md-input-container class="passportlist__search-categories" [floatPlaceholder]="categories.length ? 'always' : ''"
        (click)="openCategoryDialog()">
        <md-chip-list>
            <md-basic-chip *ngFor="let category of categories" (focus)="false">
                {{category.name | translate}}
                <button (click)="removeCategory(category, $event)" class="delete"></button>
            </md-basic-chip>
        </md-chip-list>
        <input test-id="category-selector" class="passportlist__info-by" autocomplete="off"
            placeholder="{{ 'Category' | translate }}" mdInput readonly>
    </md-input-container>
</div>