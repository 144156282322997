import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { Zone } from '../../../shared/models/zone.class';
import { LogNotificationService } from '../../../shared/services/log-notification.service';
import { ZoneService } from '../../../shared/services/zone.service';
import { GenericPassport } from '../models/generic-passport.class';
import { GenericPassportService } from '../service/generic-passport.service';

@Component({
  moduleId: module.id,
  selector: 'copy-generic-passport-dialog',
  styleUrls: ['copy-generic-passport-dialog.component.scss'],
  templateUrl: 'copy-generic-passport-dialog.component.html'
})
export class CopyGenericPassportDialogComponent implements OnInit {

  genericPassport: GenericPassport;
  zones: Zone[];
  zonesForm: FormArray  = new FormArray([]);
  zoneId: number;

  constructor(public notification: LogNotificationService,
    public _dialog: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any,
    private zoneService: ZoneService,
    private genericPassportService: GenericPassportService,
    private _fb: FormBuilder) {

  }

  ngOnInit() {
    this.zonesForm = this._fb.array([]);
    this.zonesForm.push(this._fb.group({id: ''}));
    this.genericPassport = this.mdDialogData.genericPassport;
    this.zoneId = this.mdDialogData.zoneId;
    this.zoneService.get({expand: 'name', nopaginate: 1}).subscribe((zones: Zone[]) => this.zones = zones);
  }

  addZone() {
    this.zonesForm.push(this._fb.group({id: ''}));
  }

  removeZone(i) {
    this.zonesForm.removeAt(i);
  }

  confirm() {
    const zoneIds = this.zonesForm.controls.map(control => control.value.id).filter(id => id !== '');
    this.genericPassportService.copyToZones([this.genericPassport.id], zoneIds).subscribe(() => {
      this.notification.success('GP_SUCCESS_COPY_MESSAGE', 2000, true);
      this._dialog.close();
    });
  }

  cancel() {
    this._dialog.close();
  }
}
