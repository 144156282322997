import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { DepositTagLocationJunction } from '../models/deposit-tag-location-junction.class';
var DepositTagLocationJunctionService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositTagLocationJunctionService, _super);
    function DepositTagLocationJunctionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'site-tag-deposit-junctions';
        _this.namespaceSingular = 'site-tag-deposit-junctions';
        _this.ModelClass = DepositTagLocationJunction;
        return _this;
    }
    return DepositTagLocationJunctionService;
}(CrudService));
export { DepositTagLocationJunctionService };
