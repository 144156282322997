import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, NgZone, OnInit } from '@angular/core';
import { BACKSPACE, DELETE, MdDialog, TAB } from '@angular/material';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IMPORT_DEPOSITS_TAB } from '../../../../../import-spreadsheet/values/import-deposit-tab.const';
import { DepositImport } from '../../../../../entities/deposit/models/deposit-import.class';
import { DepositImportService } from '../../../../../entities/deposit/services/deposit-import.service';
import { ImportConfigurationService } from '../../../../../import-spreadsheet/services/import-configuration.service';
import { DEPOSIT_IMPORT_COLUMNS, DEPOSIT_IMPORT_CONTROL_META, DEPOSIT_IMPORT_CONTROL_OPTIONS } from './values/deposit-import-columns.const';
import { TABLE_SYNONYM_AND_COLUMNS } from './values/deposit-import-synonym';
import { ImportValidatorsService } from '../../../../../import-spreadsheet/services/import-validators.service';
import { IMPORT_DOCUMENT_CONTROLS } from '../../../../../import-spreadsheet/values/import-document-controls.const';
import { DepositImportWarningComponent } from '../../../dialogs/deposit-import-warning/deposit-import-warning.component';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { cloneDeep } from 'lodash';
import { ImportProgressService } from '../../../../../import-spreadsheet/services/import-progress.service';
import { TABLE_TYPES } from '../../../../../import-spreadsheet/values/import-tables.const';
import { ImportDeepstreamService } from '../../../../../import-spreadsheet/services/import-deepstream.service';
var ImportDepositComponent = /** @class */ (function () {
    function ImportDepositComponent(_fb, notification, depositImportService, importValidation, importConfigurationService, importProgressService, _dialog, zone, _translate, importDeepstreamService) {
        this._fb = _fb;
        this.notification = notification;
        this.depositImportService = depositImportService;
        this.importValidation = importValidation;
        this.importConfigurationService = importConfigurationService;
        this.importProgressService = importProgressService;
        this._dialog = _dialog;
        this.zone = zone;
        this._translate = _translate;
        this.importDeepstreamService = importDeepstreamService;
        this.DEPOSIT_IMPORT_CONTROL_META = DEPOSIT_IMPORT_CONTROL_META;
        this.tables = [
            TABLE_TYPES.STATE_OF_USE,
            TABLE_TYPES.LEVEL_OF_DEMONTABILITY,
            TABLE_TYPES.DEPOSIT_ORIGIN,
            TABLE_TYPES.POTENTIAL_BECOMING,
            TABLE_TYPES.COLUMNS_MAPPING
        ];
        this.savePreferencesAfterNumberOfChanges = 6;
        this.savePreferencesAfterInInterval = -1;
        this.savePreferencesAfterSubmit = true;
        this.savePreferencesOnDestroy = true;
        this.submit = new EventEmitter();
        this.importPreferences = TABLE_SYNONYM_AND_COLUMNS;
        this.columns = {};
        this.uploadedDepositFile = [];
        this.depositImport = new DepositImport();
        this.onUpdateUploadUrl$ = new EventEmitter();
        this.onDocumentSave$ = new EventEmitter();
        this.filesList = [];
        this.fields = {};
        this.errorFields = {
            tab_name: { error: 'Tab name' },
            first_row: { error: 'No. of the first line to be imported' },
            last_row: { error: 'No. of the last line to be imported' }
        };
        this.selectedTab = IMPORT_DEPOSITS_TAB.DOCUMENTS;
        this.tabs = IMPORT_DEPOSITS_TAB;
    }
    ImportDepositComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columnFieldForm = this._fb.group(tslib_1.__assign({}, DEPOSIT_IMPORT_CONTROL_OPTIONS, { passport_id_field: ['', Validators.required], site_name_field: ['', Validators.required], quantity_field: ['', Validators.required], unit_field: ['', Validators.required] }), {
            validators: [
                //      (control) => this.importValidation.validateColumnDuplication(control, this.fields),
                function (control) { return _this.importValidation.validateRequireOneOfMany(control); }
            ]
        });
        this.depositImportForm = this._fb.group(tslib_1.__assign({}, IMPORT_DOCUMENT_CONTROLS, { id: null }), {
            validators: [
                function (control) { return _this.importValidation.validateNumberControlIsNotLess(control); },
            ]
        });
        this.depositImportForm.addControl('columnFields', this.columnFieldForm);
    };
    ImportDepositComponent.prototype.ngAfterViewInit = function () {
        this.fields = cloneDeep(DEPOSIT_IMPORT_COLUMNS);
        this.fields.availability_date_field.required = this.isDateColumnRequired.bind(this);
        this.fields.availability_date_field.middleTemplate = this.dateTemplate;
        this.fields.availability_date_field.middleTemplateContext = {
            checkRequireDates: this.isDateRequired.bind(this),
            datePickerKeydown: this.datePickerKeydown.bind(this),
            parentForm: this.columnFieldForm
        };
        this.fields.length_field.beforeTemplate = this.dimensionTemplate;
        this.fields.length_field.beforeTemplateContext = { parentForm: this.columnFieldForm };
    };
    ImportDepositComponent.prototype.isDateFiledRequired = function () {
        return this.importValidation.validateRequireOneOfMany(this.columnFieldForm);
    };
    ImportDepositComponent.prototype.isDateColumnRequired = function () {
        return !!(this.isDateFiledRequired() || this.columnFieldForm.value.availability_date_field);
    };
    ImportDepositComponent.prototype.isDateRequired = function () {
        return this.isDateFiledRequired() || !this.columnFieldForm.value.availability_date_field;
    };
    ImportDepositComponent.prototype.updateImportAndShowWarning = function () {
        var _this = this;
        if (!this.validateAll()) {
            return;
        }
        this.createModel();
        this._dialog.open(DepositImportWarningComponent, tslib_1.__assign({}, DIALOG_THEME.ALERT_PRIMARY, { data: { depositImport: this.depositImport } })).afterClosed().subscribe(function (isConfirmed) {
            if (!isConfirmed) {
                return;
            }
            _this.importProgressService.startNewProcess();
            _this.depositImport.save().subscribe(function (depositImportInserted) {
                _this.importProgressService.setCurrentId(depositImportInserted.id);
                _this.importDeepstreamService.setType(1 /* DEPOSIT */);
                _this.depositImport = depositImportInserted;
                if (_this.uploadedDepositFile.length === 1) {
                    _this.documentUploaded();
                }
                else {
                    var url = _this.depositImport.getFileUploadUrl();
                    _this.onUpdateUploadUrl$.emit(url);
                    _this.onDocumentSave$.emit();
                }
            }, function () { return _this.importProgressService.resetProgressModal(); });
        });
    };
    ImportDepositComponent.prototype.documentUploaded = function () {
        var _this = this;
        var spreadsheetImport = this.depositImport;
        spreadsheetImport.startImport().subscribe(function () {
            _this.depositImportForm.get('isFileLoaded').setValue(null);
        }, function () { return _this.importProgressService.resetProgressModal(); });
    };
    ImportDepositComponent.prototype.createModel = function () {
        this.depositImport.id = undefined;
        this.depositImportForm.controls['id'].setValue(undefined);
        var columns = this.importValidation.parseColumnsToModel(this.columnFieldForm, this.fields);
        this.depositImport = new DepositImport(tslib_1.__assign({}, this.depositImportForm.value, columns));
        this.depositImport.reference_date = this.formatDateToMinUTCString(this.depositImport.reference_date);
    };
    ImportDepositComponent.prototype.validateAll = function () {
        return this.depositImportForm.valid;
    };
    ImportDepositComponent.prototype.formatDateToMinUTCString = function (dateString) {
        if (!dateString) {
            return '';
        }
        var arrDate = dateString.split('-');
        return arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
    };
    ImportDepositComponent.prototype.fileAdded = function (files) {
        this.filesList = files;
    };
    ImportDepositComponent.prototype.isPt = function () {
        return this._translate.currentLang === 'pt';
    };
    ImportDepositComponent.prototype.datePickerKeydown = function (e) {
        if (e.keyCode === BACKSPACE || e.keyCode === DELETE) {
            this.columnFieldForm.controls['reference_date'].setValue(null);
        }
        if (e.keyCode === TAB) {
            return;
        }
        e.preventDefault();
    };
    return ImportDepositComponent;
}());
export { ImportDepositComponent };
