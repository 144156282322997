/**
 * Created by aleksandr on 30.03.17.
 */

import { IRoleContextConfig } from '../interfaces/role-context-config.interface';
import { SUPERVISOR_NAVIGATION } from '../../dashboards/supervisor/supervisor.navigation';

export const DEFAULT_CONTEXT: IRoleContextConfig = {
  navigation: SUPERVISOR_NAVIGATION,
  moduleName: 'default',
  themeClass: 'b-module-default',
  roleId: 0,
  roleText: 'Default',
  homeUrl: '',
  rootPath: ''
};

