/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./passport-family-label.component";
var styles_PassportFamilyLabel = [];
var RenderType_PassportFamilyLabel = i0.ɵcrt({ encapsulation: 2, styles: styles_PassportFamilyLabel, data: {} });
export { RenderType_PassportFamilyLabel as RenderType_PassportFamilyLabel };
export function View_PassportFamilyLabel_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "passportlist__family_text"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getClassName(); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.getFamilyName())); _ck(_v, 2, 0, currVal_1); }); }
export function View_PassportFamilyLabel_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "passport-family-label", [], null, null, null, View_PassportFamilyLabel_0, RenderType_PassportFamilyLabel)), i0.ɵdid(1, 49152, null, 0, i2.PassportFamilyLabel, [], null, null)], null, null); }
var PassportFamilyLabelNgFactory = i0.ɵccf("passport-family-label", i2.PassportFamilyLabel, View_PassportFamilyLabel_Host_0, { familyType: "familyType" }, {}, []);
export { PassportFamilyLabelNgFactory as PassportFamilyLabelNgFactory };
