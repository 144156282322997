
import { EcosystemProcessCountService } from './services/ecosystem-process-count.service';
import { EcosystemFileJunctionService } from './services/ecosystem-file-junctions.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { EcosystemService } from './services/ecosystem.service';
import { EcosystemResolver } from '../../dashboards/ecosystem-manager/resolvers/ecosystem.resolver';
import { EcosystemStepService } from './services/ecosystem-step.service';
import { EcosystemFileService } from './services/ecosystem-file.service';
import {EcosystemFileToJunctionService} from './services/ecosystem-file-to-junction.service';
import {EcosystemProblemUtilsService} from './services/ecosystem-problem-utils.service';
import { EcosystemFolderService } from './services/ecosystem-folder.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    EcosystemService,
    EcosystemStepService,
    EcosystemResolver,
    EcosystemStepService,
    EcosystemFileService,
    EcosystemFileJunctionService,
    EcosystemProcessCountService,
    EcosystemFileToJunctionService,
    EcosystemProblemUtilsService,
    EcosystemFolderService
  ],
  entryComponents: [],
})
export class EcosystemModule {
}
