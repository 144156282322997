import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { PassportComponent } from '../models/component.class';
var ComponentService = /** @class */ (function (_super) {
    tslib_1.__extends(ComponentService, _super);
    function ComponentService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'passport-components';
        _this.namespaceSingular = 'passport-component';
        _this.ModelClass = PassportComponent;
        return _this;
    }
    return ComponentService;
}(CrudService));
export { ComponentService };
