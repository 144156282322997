<md-dialog-content class="bim-model">
  <dialog-close-button [color]="'white'" (click)="mdDialogRef.close()"></dialog-close-button>
  <div class="bim-model__header">
    {{'BIM models' | translate | uppercase}}
  </div>
  <div class="bim-model__content">
    <div class="bim-model__toggle-group" *ngFor="let key of groupKeys; let i = index">
      <div class="bim-model__toggle-header gray">
        <div class="bim-model__toggle-header-info">
          <span class="bim-model__tool-name">
            {{getBIMName(group[key][0])}}
          </span>
          <span class="bim-model__bim-count">{{group[key].length}} {{'BIM models' | translate}}</span>
        </div>
        <div class="flex">
          <button class="button-transparent" (click)="toggle(i)">
            <i class="icon" [ngClass]="isExpanded(i) ? 'black-arrow-down--icon x5' : 'black-arrow-up--icon x5' "></i>
          </button>
        </div>
      </div>
      <div *ngIf="isExpanded(i)">
        <ng-container *ngFor="let location of group[key]; let odd = odd">
          <div [ngClass]="{'bim-model__toggle-row': true, 'gray':odd}">
            <div class="bim-model__row-content">
              <span class="bim-model__site-name">{{location.name}}</span> - 
              <span class="bim-model__site-address">{{location.address}}</span>
            </div>
            <div class="bim-model__button-divider-holder">
              <div class="bim-model__divider"></div>
              <div class="bim-model__row-button"  (click)="openBIM(location.bim_id)">
                <span class="bim-model__button">
                  {{'open' | translate | uppercase}}  
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</md-dialog-content>