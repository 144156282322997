import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformLocationService } from '../services/transform-location.service';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import { IMapMarker } from '../../../shared/map-view/map-marker.interface';

export class TransformLocation extends ActiveRecord implements IMapMarker {
  protected provider = TransformLocationService;

  public id: number;

  public name: string;
  public address: string;
  public description: string;
  public lat: number;
  public lng: number;

  public user_id: number;

  public verification: boolean;
  public use_count: number;
  public creator: string;
  public transformation_id: number;
  public transformation_name: string;

  public plumb_type = ECOSYSTEM_STEP_TYPE.TRANSFORM;

  protected fields() {
    return [
      'id',

      'name',
      'address',
      'description',
      'lat',
      'lng',

      'user_id',
      'verification',
      'use_count',
      'creator',
    ];
  }

}


