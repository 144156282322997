import * as tslib_1 from "tslib";
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { DepositLocationImportFileService } from '../services/deposit-location-import-file.service';
var DepositLocationImportFile = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationImportFile, _super);
    function DepositLocationImportFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = DepositLocationImportFileService;
        return _this;
    }
    DepositLocationImportFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'import_id',
        ]);
    };
    return DepositLocationImportFile;
}(UploadedDocument));
export { DepositLocationImportFile };
