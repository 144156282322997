/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog-icon.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-svg-icon/svg-icon.component.ngfactory";
import * as i3 from "angular-svg-icon/svg-icon.component";
import * as i4 from "angular-svg-icon/svg-icon-registry.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i7 from "@angular/material";
import * as i8 from "./confirm-dialog-icon.component";
var styles_ConfirmDialogIconComponent = [i0.styles];
var RenderType_ConfirmDialogIconComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ConfirmDialogIconComponent, data: {} });
export { RenderType_ConfirmDialogIconComponent as RenderType_ConfirmDialogIconComponent };
function View_ConfirmDialogIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "svg-icon", [], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 770048, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.Renderer, i4.SvgIconRegistryService], { src: [0, "src"] }, null), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "primary-fill": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getEndpoint(_co.showIconURL); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "confirm-dialog-icon__icon--svg ", _co.iconClass, ""); var currVal_2 = _ck(_v, 3, 0, _co.primaryFill); _ck(_v, 2, 0, currVal_1, currVal_2); }, null); }
function View_ConfirmDialogIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "md-icon", [["role", "img"]], [[2, "mat-icon", null]], null, null, i6.View_MdIcon_0, i6.RenderType_MdIcon)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "primary-color": 0 }), i1.ɵdid(3, 16384, null, 0, i7.MdPrefixRejector, [[2, i7.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(4, 9027584, null, 0, i7.MdIcon, [i1.ElementRef, i1.Renderer, i7.MdIconRegistry], null, null), (_l()(), i1.ɵted(5, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "confirm-dialog-icon__icon--md ", _co.iconClass, ""); var currVal_2 = _ck(_v, 2, 0, _co.primaryFill); _ck(_v, 1, 0, currVal_1, currVal_2); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.showIconText; _ck(_v, 5, 0, currVal_3); }); }
function View_ConfirmDialogIconComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "confirm-dialog-icon__icon"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "confirm-dialog-icon__icon"; var currVal_1 = _co.iconClass; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ConfirmDialogIconComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "confirm-dialog-icon__icon--text"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "primary-color": 0 }), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "confirm-dialog-icon__icon--text"; var currVal_1 = _ck(_v, 2, 0, _co.primaryFill); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showIconText; _ck(_v, 3, 0, currVal_2); }); }
function View_ConfirmDialogIconComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [["class", "confirm-dialog-icon__icon--img"]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "background-primary-color": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "confirm-dialog-icon__icon--img"; var currVal_2 = _ck(_v, 2, 0, _co.primaryFill); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.showIconURL, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_ConfirmDialogIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "confirm-dialog-icon"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i5.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogIconComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogIconComponent_2)), i1.ɵdid(5, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogIconComponent_3)), i1.ɵdid(7, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogIconComponent_4)), i1.ɵdid(9, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogIconComponent_5)), i1.ɵdid(11, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconType; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.CONFIRM_DIALOG_ICON.SVG; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.CONFIRM_DIALOG_ICON.MD; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.CONFIRM_DIALOG_ICON.USUAL; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.CONFIRM_DIALOG_ICON.TEXT; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.CONFIRM_DIALOG_ICON.IMG; _ck(_v, 11, 0, currVal_5); }, null); }
export function View_ConfirmDialogIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirm-dialog-icon", [], null, null, null, View_ConfirmDialogIconComponent_0, RenderType_ConfirmDialogIconComponent)), i1.ɵdid(1, 114688, null, 0, i8.ConfirmDialogIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDialogIconComponentNgFactory = i1.ɵccf("confirm-dialog-icon", i8.ConfirmDialogIconComponent, View_ConfirmDialogIconComponent_Host_0, { iconData: "iconData", iconType: "iconType", showIconText: "showIconText", showIconURL: "showIconURL", primaryFill: "primaryFill", iconClass: "iconClass" }, {}, []);
export { ConfirmDialogIconComponentNgFactory as ConfirmDialogIconComponentNgFactory };
