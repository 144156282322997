import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NeedService } from '../../../../entities/need/services/need.service';
import { Need } from '../../../../entities/need/models/need.class';
import { LatLngBounds, LatLngBoundsLiteral, MapsAPILoader } from '@agm/core';
import { Subject } from 'rxjs/Subject';
import { Searchable } from '../../../../shared/models/searchable.class';
import { NeedSalesListService } from '../../../../entities/need/services/need-sales-list.service';
import { NeedClientListService } from '../../../../entities/need/services/need-client-list.service';
import { NEED_STATUSES } from '../../../../entities/need/values/need-statuses.const';
import { MdDialogRef } from '@angular/material';
import {CurrentUserService} from '../../../../shared/services/current-user.service';
import { CurrentUser } from '../../../../shared/auth/current-user.class';

@Component({
  moduleId: module.id,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'esm-need-dialog',
  templateUrl: 'esm-need-dialog.component.html',
})
export class EsmNeedDialogComponent implements OnInit {
  needs: Need[];
  public mapBounds: LatLngBounds | LatLngBoundsLiteral = {north: 0, south: 0, east: 0, west: 0};
  public mapsAPILoad: Promise<any>;

  // Filters
  expiresFrom: number;
  expiresTo: number;
  search: string;
  clientId: number;
  salesId: number;
  searchSubject = new Subject();
  filter = 'all';
  showIndependentFilter: boolean;
  clients: Searchable[];
  salesContacts: Searchable[];
  mapZoom = 8;
  initialZoomApplied = false;
  includeIndependent: boolean;

  constructor(private mapsAPILoader: MapsAPILoader,
              private needService: NeedService,
              private needClientListService: NeedClientListService,
              private needSalesListService: NeedSalesListService,
              private _dialog: MdDialogRef<any>,
              private changeDetectorRef: ChangeDetectorRef) {
    this.mapsAPILoad = this.mapsAPILoader.load();
    this.needClientListService.get().subscribe((res: Searchable[]) => {
      this.clients = res;
    });
    this.needSalesListService.get().subscribe((res: Searchable[]) => {
      this.salesContacts = res;
    });
  }

  ngOnInit() {
    this.getRecords();
    this.showIndependentFilter = CurrentUser.infoData.has_need_acces;
  }

  getRecords() {
    let params: any = {
      expand: 'passport,client_location',
      supervisor: 1,
      delivery_from: this.expiresFrom || null,
      delivery_to: this.expiresTo || null,
      search: this.search || null,
      client: this.clientId || null,
      sales_contact: this.salesId || null,
      independent: this.includeIndependent || null,
      'status[]': NEED_STATUSES.PUBLISHED.value || null
    };

    this.needService.get(params).subscribe((needs: Need[]) => {
      this.initialZoomApplied = false;
      this.needs = needs;

      this.mapsAPILoad.then(() => {
        this.mapBounds = this.getMapBounds(this.needs);
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  onMapReady() {
    if (!this.initialZoomApplied) {
      this.mapZoom = 12;
      this.mapBounds = this.getMapBounds(this.needs);
      this.changeDetectorRef.detectChanges();
      this.initialZoomApplied = true;
    }
  }

  /*update(needs: Need[]) {
   this.needs = needs;
   this.mapBounds = this.getMapBounds(this.needs);
   }*/

  getMapBounds(needs: Need[]): LatLngBounds {
    let bounds: any = new google.maps.LatLngBounds();

    needs.forEach((need) => {
      if (need.client_location.lat && need.client_location.lng) {
        bounds.extend(need.client_location);
      }
    });

    return bounds;
  }

  closeDialog() {
    this._dialog.close();
  }

  toggleIndependent(checked: boolean) {
    this.includeIndependent = checked;
    this.getRecords();
  }
}
