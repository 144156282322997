import * as tslib_1 from "tslib";
import { NgZone, OnInit } from '@angular/core';
import { EDepositItemType } from '../../../../entities/deposit/values/deposit-item-type.enum';
import { MdDialog } from '@angular/material';
import { NeedDetailsDialogComponent } from '../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { EDatePrecision } from '../../../../entities/need/values/date-precision.const';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EcosystemService } from '../../../../entities/ecosystem/services/ecosystem.service';
import { EConfirmDialogIcon } from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import { NeedEsmMatchDepositsFilter } from '../../../../entities/need/models/need-esm-match-deposits-filter';
import { NeedService } from '../../../../entities/need/services/need.service';
import { PaginationUnmarshallingService } from '../../../../shared/services/pagination-unmarshalling.service';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
var EsmNeedMatchComponent = /** @class */ (function () {
    function EsmNeedMatchComponent(mdDialog, router, zone, translate, ecosystemService, needService, depositService, paginationService, notificationService) {
        this.mdDialog = mdDialog;
        this.router = router;
        this.zone = zone;
        this.translate = translate;
        this.ecosystemService = ecosystemService;
        this.needService = needService;
        this.depositService = depositService;
        this.paginationService = paginationService;
        this.notificationService = notificationService;
        this.CUSTOM_ICON = EConfirmDialogIcon;
        this.DEPOSIT_ITEM_TYPE = EDepositItemType;
        this.NEED_DATE_PRECISION = EDatePrecision;
        this.NEED_MATCH_DEPOSITS_TYPE_FROM_DEPOSIT = 2 /* FROM_DEPOSIT */;
        this.NEED_MATCH_DEPOSITS_TYPE_PASSPORT = 1 /* PASSPORT */;
        this.needFilter = new NeedEsmMatchDepositsFilter();
        this.needTabIsOpen = {};
        this.isLoadingEcosystem = false;
    }
    Object.defineProperty(EsmNeedMatchComponent.prototype, "needs", {
        get: function () {
            return this.needFilter.records;
        },
        enumerable: true,
        configurable: true
    });
    EsmNeedMatchComponent.prototype.ngOnInit = function () {
        if (this.needFilter.loaded) {
            this.refreshNeedTabs([]);
        }
        else {
            this.loadRecords();
        }
    };
    EsmNeedMatchComponent.prototype.loadRecords = function (page) {
        var _this = this;
        if (page === void 0) { page = 1; }
        this.needFilter.currentPage = page;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        var previousNeeds = this.needFilter.records;
        this.subscription = this.needService.getWithPaginationData(this.needFilter.buildOptions())
            .subscribe(function (response) {
            _this.needFilter.updateRecords(response, _this.paginationService.unmarshall(response));
            _this.refreshNeedTabs(previousNeeds);
        });
    };
    EsmNeedMatchComponent.prototype.refreshNeedTabs = function (previousNeeds) {
        var _this = this;
        var skipOther = false;
        var isOpen = {};
        this.needFilter.records.forEach(function (need, i) {
            if (!skipOther) {
                skipOther = !previousNeeds[i]
                    || previousNeeds[i].id !== need.id
                    || previousNeeds[i].match_deposits.type !== need.match_deposits.type;
            }
            isOpen[need.id] = skipOther ? false : _this.needTabIsOpen[need.id];
        });
        this.needTabIsOpen = isOpen;
    };
    EsmNeedMatchComponent.prototype.openNeedDetailsDialog = function (id) {
        this.mdDialog.open(NeedDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.WIDE_PRIMARY, { data: {
                id: id,
                readOnly: true,
                showValidationButtons: false
            } }));
    };
    EsmNeedMatchComponent.prototype.createEcosystemFromNeed = function (need, deposit) {
        var _this = this;
        if (!this.isLoadingEcosystem) {
            this.isLoadingEcosystem = true;
            this.ecosystemService.publishWithDeposit({ name: this.translate.instant('For') + " " + need.name,
                need_id: need.id,
                deposit_id: deposit.id }).subscribe(function (ecosystem) {
                _this.zone.run(function () { return _this.router.navigate(['ecosystem/ecosystems', ecosystem.id]); });
                _this.isLoadingEcosystem = false;
            }, function () { return _this.isLoadingEcosystem = false; });
        }
    };
    EsmNeedMatchComponent.prototype.sendConsultDepositLinkNotificationToDO = function (deposit, need) {
        var _this = this;
        this.needService.sendConsultDepositLinkNotificationToSales(need.id, deposit.id)
            .subscribe(function () { return _this.notificationService.success('Send'); });
    };
    return EsmNeedMatchComponent;
}());
export { EsmNeedMatchComponent };
