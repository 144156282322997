<ng-container *ngIf="loading$ | async; else loaded">
    <div>{{ 'Loading' | translate }}...</div>
</ng-container>

<ng-template #loaded>
    <app-selectable-button-list-filter
        [items]="getListCountriesName()"
        [selectedItem]="getCountriesSelectedValue()"
        placeholder="SEARCH_BY_REPRESENTATIVITY"
        (selectionChange)="onSelectionChange($event)"
        [disabled]="disabled">
    </app-selectable-button-list-filter>
</ng-template>
