import * as tslib_1 from "tslib";
import { TransformOrder } from '../models/transform-order.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var TransformOrderService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformOrderService, _super);
    function TransformOrderService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-orders';
        _this.namespaceSingular = 'transformation-order';
        _this.ModelClass = TransformOrder;
        return _this;
    }
    TransformOrderService.prototype.getEsmList = function (query) {
        return this
            .sendGet(this.getEndpoint('transformation-order/esm_list'), { search: query })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    TransformOrderService.prototype.getTsmList = function (query) {
        return this
            .sendGet(this.getEndpoint('transformation-order/tsm_list'), { search: query })
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    TransformOrderService.prototype.getReportUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/problem');
    };
    TransformOrderService.prototype.sendReport = function (id, problem) {
        return this.sendPost(this.getReportUrl(id), { problem: problem });
    };
    TransformOrderService.prototype.getResendUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/resend');
    };
    TransformOrderService.prototype.getCount = function () {
        return this
            .sendGet(this.getEndpoint(this.namespaceSingular + '/count'))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    TransformOrderService.prototype.resend = function (id, data) {
        return this.sendPost(this.getResendUrl(id), data)
            .map(function (res) { return new TransformOrder(res.json()); })
            .catch(this.onError.bind(this));
    };
    return TransformOrderService;
}(ExtendedCrudService));
export { TransformOrderService };
