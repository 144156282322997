
    <dialog-close-button
      (click)="mdDialogRef.close()"
    ></dialog-close-button>

    <need-details
      [id]="mdDialogData.id"
      [readOnly]="mdDialogData.readOnly"
      [showValidationButtons]="mdDialogData.showValidationButtons"
      (action)="mdDialogRef.close()"
    ></need-details>
  