import { OrderPassport } from '../models/order-passport.class';
import { Injectable } from '@angular/core';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';

@Injectable()
export class OrderPassportService extends ExtendedCrudService {
  protected namespace = 'order-passports';
  protected namespaceSingular = 'order-passport';
  protected ModelClass = OrderPassport;
}
