
import { Router } from '@angular/router';
import { INotificationEntryRole } from '../interfaces/notification-entry.interface';
import { NotificationHandler } from '../interfaces/notification-handler.interface';
import { NotificationEntry } from '../models/notification-entry.model';
import { SuperAdminNotificationHandler } from './Superadmin-notification-handler';
import { AdminNotificationHandler } from './admin-notification-handler';


export class NotificationHandlerFactory {
  static createHandler(notification: NotificationEntry, router: Router): NotificationHandler {
    switch(notification.type) {
      case INotificationEntryRole.ROLE_ADMIN:
        return new AdminNotificationHandler(router);
      case INotificationEntryRole.ROLE_SUPERADMIN:
        return new SuperAdminNotificationHandler(router);
      // autres cas pour différents types
      default:
        return null;
    }
  }
}
