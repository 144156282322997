import * as tslib_1 from "tslib";
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { ReportImportService } from '../services/report-import.service';
var ReportImport = /** @class */ (function (_super) {
    tslib_1.__extends(ReportImport, _super);
    function ReportImport(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = ReportImportService;
        return _this;
    }
    ReportImport.prototype.fields = function () {
        return [
            'id',
            'tab_name',
            'first_row',
            'last_row',
            'passport_id_field',
            'site_name_field',
            'tags_field',
            'quantity_field',
            'unit_field',
            'availability_date_field',
            'reference_date',
            'state_field',
            'state_description_field',
            'demountability_field',
            'comments_field',
            'reused_field',
            'errors',
        ];
    };
    ReportImport.prototype.startImport = function () {
        return this.simplePost('/' + this.id + '/start');
    };
    return ReportImport;
}(ExtendedActiveRecord));
export { ReportImport };
