import * as tslib_1 from "tslib";
import { ActiveRecord } from './active-record.class';
var ExtendedActiveRecord = /** @class */ (function (_super) {
    tslib_1.__extends(ExtendedActiveRecord, _super);
    function ExtendedActiveRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExtendedActiveRecord.prototype.publish = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.publish(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.update = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.update(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    /**
   * Effectue une mise à jour partielle de l'instance en cours en se basant sur les champs spécifiés.
   * Cette méthode est conçue pour envoyer uniquement les champs modifiés à la couche de service pour la mise à jour,
   * plutôt que de transmettre l'ensemble de l'objet. Ceci est utile pour les scénarios où seule une partie
   * des données de l'objet nécessite une mise à jour, permettant ainsi d'éviter des requêtes lourdes
   * et de minimiser le trafic réseau.
   *
   * @param updates Un objet contenant les champs à mettre à jour avec leurs nouvelles valeurs.
   *                Seuls ces champs seront inclus dans la requête de mise à jour.
   * @returns Observable<any> Un Observable qui émet la réponse du service de mise à jour.
   *                          L'objet actuel est également mis à jour avec les nouvelles valeurs.
   *
   * Utilisation :
   * Supposons qu'un objet `step` de type `EcosystemStep` nécessite une mise à jour des champs `x_pos` et `y_pos`,
   * cette méthode peut être appelée comme suit : `step.partialUpdate({ x_pos: newX, y_pos: newY }).subscribe();`
   */
    ExtendedActiveRecord.prototype.partialUpdate = function (updates) {
        var _this = this;
        this.build();
        var dataToUpdate = this.beforeSave(updates);
        this.beforeSave$.emit(this);
        return this.service.update(this.id, dataToUpdate).map(function (res) {
            Object.assign(_this, updates);
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.unpublish = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.unpublish(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.reject = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.reject(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.askForModification = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.askForModification(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.askForRating = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.askForRating(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.validate = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.validate(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.validateAll = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.validateAll(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.unvalidate = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.unvalidate(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.confirm = function () {
        var _this = this;
        this.build();
        this.beforeSave$.emit(this);
        return this.service.confirm(this.id, this.beforeSave(this.extractData(this))).map(function (res) {
            _this.afterSave$.emit(_this);
            return res;
        });
    };
    ExtendedActiveRecord.prototype.simplePost = function (endpointPrefix) {
        var _this = this;
        this.build();
        var service = this.service;
        var endpoint = service.getEndpoint(service.singularEndpoint + endpointPrefix);
        this.beforeSave$.emit(this);
        return service
            .sendPost(endpoint, this.beforeSave(this.extractData(this)))
            .map(function (res) {
            var text = res.text();
            return text ? JSON.parse(text) : null;
        })
            .catch(function (error) { return service.onError(error); })
            .do(function (res) { return _this.afterSave$.emit(_this); });
    };
    ExtendedActiveRecord.prototype.canDelete = function () {
        this.build();
        return this.service.canDelete(this.id);
    };
    ExtendedActiveRecord.prototype.canUpdate = function () {
        this.build();
        return this.service.canUpdate(this.id);
    };
    ExtendedActiveRecord.prototype.getFileUploadUrl = function () {
        this.build();
        return this.service.getFileUploadUrl(this.id);
    };
    return ExtendedActiveRecord;
}(ActiveRecord));
export { ExtendedActiveRecord };
