/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../passport/partials/edit-passport/edit-passport.component.ngfactory";
import * as i2 from "../../../../passport/services/passport-gtin-buffer.service";
import * as i3 from "../../../../passport/services/passport-gtin.service";
import * as i4 from "../../../../../shared/read-only/read-only.service";
import * as i5 from "../../../../passport/partials/edit-passport/edit-passport.component";
import * as i6 from "@angular/material";
import * as i7 from "@angular/forms";
import * as i8 from "../../../../passport/services/passport.service";
import * as i9 from "../../../../passport/services/passport-file.service";
import * as i10 from "../../../../passport/services/format.service";
import * as i11 from "../../../../../shared/services/navbar.service";
import * as i12 from "../../../../../shared/services/loading-spinner.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../../../shared/services/navigation.service";
import * as i15 from "../../../../../shared/services/user.service";
import * as i16 from "../../../../passport/services/material.service";
import * as i17 from "@ngx-translate/core";
import * as i18 from "../../../../../shared/services/log-notification.service";
import * as i19 from "../../../../passport/services/passport-validation-notification.service";
import * as i20 from "../../../../passport/services/passport-group-builder/passport-group-builder.service";
import * as i21 from "./product-passport-step.component";
import * as i22 from "../../../services/deposit-photo.service";
var styles_DepositPassportStepComponent = [];
var RenderType_DepositPassportStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DepositPassportStepComponent, data: {} });
export { RenderType_DepositPassportStepComponent as RenderType_DepositPassportStepComponent };
export function View_DepositPassportStepComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "passport-edit-form", [], [[2, "highlighted", null]], [[null, "onChange"], [null, "onSaveComplete"], [null, "onSaveError"], [null, "onInvalidField"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onChangePassport($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSaveComplete" === en)) {
        var pd_1 = (_co.onSaveCompleteHandler($event) !== false);
        ad = (pd_1 && ad);
    } if (("onSaveError" === en)) {
        var pd_2 = (_co.onSaveErrorHandler($event) !== false);
        ad = (pd_2 && ad);
    } if (("onInvalidField" === en)) {
        var pd_3 = (_co.onInvalidFieldHandler($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_EditPassportComponent_0, i1.RenderType_EditPassportComponent)), i0.ɵprd(512, null, i2.PassportGtinBufferService, i2.PassportGtinBufferService, [i3.PassportGtinService]), i0.ɵprd(512, null, i4.ReadOnlyService, i4.ReadOnlyService, []), i0.ɵdid(3, 8634368, null, 0, i5.EditPassportComponent, [i6.MdDialog, i7.FormBuilder, i2.PassportGtinBufferService, i8.PassportService, i9.PassportFileService, i10.PassportFormatService, i11.NavbarStateService, i12.LoadingSpinnerService, i13.Router, i4.ReadOnlyService, i14.NavigationService, i15.UserService, i16.MaterialService, i0.Renderer, i17.TranslateService, i0.ChangeDetectorRef, i18.LogNotificationService, i19.PassportValidationNotificationService, i0.ChangeDetectorRef, i20.PassportGroupBuilderService, i3.PassportGtinService], { listener: [0, "listener"], parentModule: [1, "parentModule"], passport: [2, "passport"], spinnerEnabled: [3, "spinnerEnabled"], controlStates: [4, "controlStates"] }, { onChange: "onChange", onSaveError: "onSaveError", onSaveComplete: "onSaveComplete", onInvalidField: "onInvalidField" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.eventStream$; var currVal_2 = _co.parentModule; var currVal_3 = ((_co.deposit == null) ? null : _co.deposit.passport); var currVal_4 = _co.spinnerEnabled; var currVal_5 = ((_co.controlStates == null) ? null : _co.controlStates.passport); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.controlStates == null) ? null : ((_co.controlStates.passport_id == null) ? null : _co.controlStates.passport_id.highlighted)); _ck(_v, 0, 0, currVal_0); }); }
export function View_DepositPassportStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "deposit-passport-step", [], null, null, null, View_DepositPassportStepComponent_0, RenderType_DepositPassportStepComponent)), i0.ɵdid(1, 4440064, null, 0, i21.DepositPassportStepComponent, [i6.MdDialog, i22.DepositPhotoService, i8.PassportService, i0.ChangeDetectorRef, i14.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DepositPassportStepComponentNgFactory = i0.ɵccf("deposit-passport-step", i21.DepositPassportStepComponent, View_DepositPassportStepComponent_Host_0, { deposit: "deposit", controlStates: "controlStates", listener: "listener", spinnerEnabled: "spinnerEnabled" }, { onChangeDeposit: "onChangeDeposit", onInvalidField: "onInvalidField", onSaveComplete: "onSaveComplete", onSaveError: "onSaveError" }, []);
export { DepositPassportStepComponentNgFactory as DepositPassportStepComponentNgFactory };
