import { ElementRef, EventEmitter } from '@angular/core';
import { UploadedDocument } from '../../../models/uploaders/document.class';
import { LogNotificationService } from '../../../services/log-notification.service';
var DocumentItemComponent = /** @class */ (function () {
    function DocumentItemComponent(notification) {
        this.notification = notification;
        this.noLink = false;
        this.transformFileNameBeforeEdit = this.defaultTransformFileNameBeforeEdit;
        this.isRemoveFromFilesDisabled = true;
        this.disableNameChangeDefault = false;
        this.tooltipPosition = 'above';
        this.itemClass = [];
        this.onClick = new EventEmitter();
        this.onNameChange = new EventEmitter();
        this.remove = new EventEmitter();
        this.editMode = false;
        this.toolTipPositionCustomClassBinding = {
            'left': 'right',
            'right': 'left',
            'above': 'top',
            'bellow': 'bottom'
        };
    }
    Object.defineProperty(DocumentItemComponent.prototype, "file", {
        set: function (fileInput) {
            this.inputFile = fileInput;
            if (!!fileInput) {
                this.docHref = this.docHref === undefined ? fileInput.file : this.docHref;
                this.srcImg = this.srcImg === undefined ? fileInput.file : this.srcImg;
                this.isImage = this.isImage === undefined ? fileInput.isImage : this.isImage;
                this.extension = this.extension === undefined ? fileInput.extension : this.extension;
                this.fileName = this.fileName === undefined ? fileInput.basename : this.fileName;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentItemComponent.prototype, "customClassPosition", {
        get: function () {
            var classModifier = this.toolTipPositionCustomClassBinding[this.tooltipPosition];
            return classModifier ?
                "passportlist__name-tooltip--" + classModifier : 'passportlist__name-tooltip--left';
        },
        enumerable: true,
        configurable: true
    });
    DocumentItemComponent.prototype.defaultTransformFileNameBeforeEdit = function (name) {
        return name;
    };
    DocumentItemComponent.prototype.clickOnLink = function () {
        this.onClick.emit();
        if (this.noLink) {
            return false;
        }
    };
    DocumentItemComponent.prototype.changeName = function ($event) {
        if (!this.isRemoveFromFilesDisabled) {
            if (this.disableNameChangeDefault || this.validateFileDefault()) {
                this.showEditName(this.transformFileNameBeforeEdit(this.fileName));
                $event.stopPropagation();
            }
            return false;
        }
    };
    DocumentItemComponent.prototype.validateFileDefault = function () {
        var uploadedFile = this.getUploadedFile();
        return uploadedFile instanceof UploadedDocument;
    };
    DocumentItemComponent.prototype.getUploadedFile = function () {
        try {
            return this.inputFile;
        }
        catch (_a) {
            return null;
        }
    };
    DocumentItemComponent.prototype.showEditName = function (name) {
        var _this = this;
        this.editMode = true;
        this.editName = name;
        setTimeout(function () {
            if (_this.editInput) {
                _this.editInput.nativeElement.focus();
            }
        });
    };
    DocumentItemComponent.prototype.editSend = function () {
        if (this.editMode) {
            if (!this.disableNameChangeDefault) {
                this.defaultFileRename();
            }
            else {
                this.onNameChange.emit(this.editName);
            }
            this.editName = '';
            this.editMode = false;
        }
    };
    DocumentItemComponent.prototype.defaultFileRename = function () {
        var _this = this;
        var uploadedFile = this.getUploadedFile();
        if (uploadedFile) {
            if (this.editName.trim().length > 0 && this.editName !== uploadedFile.basename) {
                uploadedFile.basename = this.editName;
                uploadedFile.save().subscribe(function (file) {
                    _this.file = file;
                    _this.fileName = file.basename;
                });
            }
        }
        else {
            this.notification.error('IMPORT_ERROR_UNKNOWN_ERROR', undefined, true);
        }
    };
    return DocumentItemComponent;
}());
export { DocumentItemComponent };
