import {Component, OnInit} from '@angular/core';
import {ReportService} from '../../../../services/report.service';
import {ActivatedRoute} from '@angular/router';
import {ReportBase} from '../report-base/report-base.class';
import {REPORT_NAME} from '../types/report-name.const';
import {LanguageService} from '../../../../../../entities/client/service/language.service';
import {IReportPDFTable} from '../types/report-pdf-table.interface';
import {IReportError} from '../../../../interfaces/report-error.interface';
import {TReportName} from '../types/report-name.type';
import { IResidualValue,
  IResidualValueCategory,
  IResidualValueCategoryItem } from '../../new-report/residual-value/types/residual-value.interface';
import {TranslateService} from '@ngx-translate/core';
import {ReportSiteTagPresenterService} from '../../../../services/report-site-tag-presenter.service';
import {ElRowCounterService} from '../../../../services/el-row-counter.service';
import {EResidualValueStatusEmpty, EResidualValueStatus} from '../../../../values/residual-value-status.enum';



@Component({
  moduleId: module.id,
  selector: 'residual-value-pdf-report',
  templateUrl: 'residual-value-pdf-report.component.html',
  styleUrls: ['residual-value-pdf-report.component.scss'],
  providers: [ReportSiteTagPresenterService, ElRowCounterService]
})
export class ResidualValuePdfReportComponent extends ReportBase<IResidualValue, IResidualValueCategory> implements OnInit {
  RESIDUAL_VALUE_TYPE = {...EResidualValueStatus, ...EResidualValueStatusEmpty};
  private pageCounter = 1;
  allResultsChunked: {
    deconstruction: any[];
    rehabilitation: any[];
    renewal_building_commercial: any[];
    renewal_building_institutional: any[];
    renewal_building_residantial: any[];
  };
  allCategories: {
    renewal_building_commercial: IReportPDFTable[];
    rehabilitation: IReportPDFTable[];
    deconstruction: IReportPDFTable[];
    renewal_building_institutional: IReportPDFTable[];
    renewal_building_residantial: IReportPDFTable[];
  };
  categories_renewal_building_commercial: IResidualValueCategory[];
  categories_rehabilitation: IResidualValueCategory[];
  categories_deconstruction: IResidualValueCategory[];
  categories_renewal_building_institutional: IResidualValueCategory[];
  categories_renewal_building_residantial: IResidualValueCategory[];

  protected reportName: TReportName = REPORT_NAME.RESIDUAL_VALUE;

  public categoriesTable: IReportPDFTable[]= [];
  public categoriesTable_renewal_building_commercial: IReportPDFTable[]= [];
  public categoriesTable_renewal_building_institutional: IReportPDFTable[]= [];
  public categoriesTable_renewal_building_residantial: IReportPDFTable[]= [];
  public categoriesTable_rehabilitation: IReportPDFTable[]= [];
  public categoriesTable_deconstruction: IReportPDFTable[]= [];

  public errorsTable: IReportPDFTable[] = [];

  public resultKeys:any[] = [];

  public categories: IResidualValueCategory[] = [];

  constructor(
    public reportService: ReportService,
    public activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    public translateService: TranslateService,
    public reportPresenter: ReportSiteTagPresenterService,
    public rowCounter: ElRowCounterService) {
      super(reportService, activatedRoute, languageService, translateService, reportPresenter, rowCounter);
  }

  ngOnInit(): void {
    this.createReport();
  }

  createReport(): void {
    this.reportRequest()
      .subscribe(() => {
        this.resultKeys = Object.keys(this.result).slice(0, -2);
        this.resultKeys = !!this.resultKeys.length ? this.resultKeys : [EResidualValueStatusEmpty.empty];
        this.prepareTablesDataForRender();
      });
  }

  isEmptyDepositCategory(type: any ) {
    if ( this.result[type]) {
      // aggregate?.hasOwnProperty('total_number_of_deposit')
      this.filteredProductCategory = this.result[type].aggregate.total_number_of_deposit;
      console.log(this.result[type].aggregate.total_number_of_deposit);
      return this.result[type].aggregate.total_number_of_deposit === 0;
    }
    return true;

  }

  setCategories(): void {
    this.categories_deconstruction = this.result.deconstruction ? [
      this.getDefaultCategory('deconstruction'),
      ...this.getSortedProductsBy('deposit_total_weight', 'deconstruction', 'deposit_considered')
    ] : [];

    this.categories_rehabilitation = this.result.rehabilitation ? [
      this.getDefaultCategory('rehabilitation'),
      ...this.getSortedProductsBy('deposit_total_weight', 'rehabilitation', 'deposit_considered')
    ] : [];

    this.categories_renewal_building_commercial = this.result.renewal_building_commercial ? [
      this.getDefaultCategory('renewal_building_commercial'),
      ...this.getSortedProductsBy('deposit_total_weight', 'renewal_building_commercial', 'deposit_considered')
    ] : [];

    this.categories_renewal_building_residantial = this.result.renewal_building_residantial ? [
      this.getDefaultCategory('renewal_building_residantial'),
      ...this.getSortedProductsBy('deposit_total_weight', 'renewal_building_residantial', 'deposit_considered')
    ] : [];

    this.categories_renewal_building_institutional = this.result.renewal_building_institutional ? [
      this.getDefaultCategory('renewal_building_institutional'),
      ...this.getSortedProductsBy('deposit_total_weight', 'renewal_building_institutional', 'deposit_considered')
    ] : [];
  }

  getDefaultCategory(type: string): IResidualValueCategoryItem {
    return {
      category: {name: 'Total', parent_id: void 0, id: void 0},
      deposit_considered: this.result[type].aggregate.deposit_considered,
      weight: this.result[type].aggregate.deposit_total_weight,
      added_value_down_if_economic: this.result[type].aggregate.total_added_value_down_if_economic,
      added_value_up: this.result[type].aggregate.total_added_value_up,
      added_value_up_if_economic: this.result[type].aggregate.total_added_value_up_if_economic,
      added_value_down: this.result[type].aggregate.total_added_value_down,
      mass_percentage: 100,
      financial_recovery_percentage_down:             this.result[type].aggregate.total_financial_recovery_percentage_down,
      financial_recovery_percentage_down_if_economic: this.result[type].aggregate.total_financial_recovery_percentage_down_if_economic,
      financial_recovery_percentage_up:               this.result[type].aggregate.total_financial_recovery_percentage_up,
      financial_recovery_percentage_up_if_economic:   this.result[type].aggregate.total_financial_recovery_percentage_up_if_economic,
      material_recovery_pourcentage_down:              this.result[type].aggregate.total_material_recovery_percentage_down,
      material_recovery_percentage_down_if_economic:  this.result[type].aggregate.total_material_recovery_percentage_down,
      material_recovery_pourcentage_up:                this.result[type].aggregate.total_material_recovery_percentage_up,
      material_recovery_percentage_up_if_economic:    this.result[type].aggregate.total_material_recovery_percentage_up_if_economic,
      purchase_value: this.result[type].aggregate.total_purchase_value,
      isDefault: true
    };
  }

  prepareTablesDataForRender(): void {
    this.prepareCategoriesTable();
    this.prepareErrorsTable();
  }

  prepareCategoriesTable(): void {
    this.setCategories();
    this.allResultsChunked = {
      deconstruction: this.prepareThirdChartChunks('deconstruction'),
      rehabilitation: this.prepareThirdChartChunks('rehabilitation'),
      renewal_building_institutional: this.prepareThirdChartChunks('renewal_building_institutional'),
      renewal_building_commercial: this.prepareThirdChartChunks('renewal_building_commercial'),
      renewal_building_residantial: this.prepareThirdChartChunks('renewal_building_residantial'),
    };

    this.pushCategoryByType(this.categoriesTable_renewal_building_commercial, this.categories_renewal_building_commercial);
    this.pushCategoryByType(this.categoriesTable_renewal_building_institutional, this.categories_renewal_building_institutional);
    this.pushCategoryByType(this.categoriesTable_renewal_building_residantial, this.categories_renewal_building_residantial);
    this.pushCategoryByType(this.categoriesTable_rehabilitation, this.categories_rehabilitation);
    this.pushCategoryByType(this.categoriesTable_deconstruction, this.categories_deconstruction);

    this.allCategories = {
      renewal_building_commercial: this.categoriesTable_renewal_building_commercial,
      renewal_building_institutional: this.categoriesTable_renewal_building_institutional,
      renewal_building_residantial: this.categoriesTable_renewal_building_residantial,
      rehabilitation: this.categoriesTable_rehabilitation,
      deconstruction: this.categoriesTable_deconstruction,
    };
  }

  pushCategoryByType(categoryListToPush: IReportPDFTable[], categories: IResidualValueCategory[]) {
    this.categories = categories;
    this.onEachChunkedCategory((chuck: IResidualValueCategory[], index: number) => {
      categoryListToPush.push({
        pageNumber: index + 2,
        pageContent: chuck
      });
    });
  }

  prepareErrorsTable(): void {
    this.onEachChunkedError((chuck: IReportError[], index: number) => {
      this.errorsTable.push({
        pageNumber: index + this.categoriesTable.length + 2,
        pageContent: chuck
      });
    });
  }

  prepareThirdChartChunks(type: string): any[] {
    if (!this.result[type]) {return []; }
    const categories = Object.entries(this.getSortedProductsBy('financial_recovery_percentage_up_if_economic', type, 'deposit_considered'));
    const splicedCategories = [];
    const splicedCategoriesArray = [];
    while (categories.length > 0) {
      const resultCopy = JSON.parse(JSON.stringify(this.result[type]))
      resultCopy.aggregate.category = [];
      resultCopy.aggregate.category = this.ObjectFromEntries(categories.splice(0,8));
      const result = {};
      result[type] = resultCopy;
      splicedCategories.push(result);
    }
    while (splicedCategories.length > 0) {
      splicedCategoriesArray.push(splicedCategories.splice(0, 2));
    }
    return splicedCategoriesArray;
  }
  initPageNumber() {
    this.pageCounter = 1;
    return this.getPageNumber();
  }

  initOrGetPageNumber(): number {
    return this.showSitesInOnePage ? this.getPageNumber() : this.initPageNumber();
  }

  getPageNumber() {
    return this.pageCounter++;
  }

  ObjectFromEntries(iter) {
    const obj = {};
    for (const pair of iter) {
      if (Object(pair) !== pair) {
        throw new TypeError('iterable for fromEntries should yield objects');
      }
      const { '0': key, '1': val } = pair;
      Object.defineProperty(obj, key, {
        configurable: true,
        enumerable: true,
        writable: true,
        value: val,
      });
    }
    return obj;
  }

}
