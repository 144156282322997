import { Directive, Optional } from '@angular/core';
import { ReadOnlyService } from './read-only.service';
@Directive({
  selector: 'input:not([readonly]),textarea:not([readonly])',
  host: {
    '[readonly]': 'readOnlyService?.readOnly',
  }
})
export class FormElemsReadOnlyDirective {
  constructor(@Optional() private readOnlyService: ReadOnlyService,) {
  }
}
