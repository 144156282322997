import {TransformFormatJunctionService} from '../services/transform-format-junction.service';
import {ActiveRecord} from '../../../shared/models/active-record.class';

export class TransformFormatJunction extends ActiveRecord {
  protected provider = TransformFormatJunctionService;

  public id: number;
  public name: string;
  public transformation_id: number;
  public format_id: number;
  public is_input: number;

  protected fields() {
    return [
      'id',
      'format_id',
      'transformation_id',
      'is_input',

      'name',
    ];
  }
}
