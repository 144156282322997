import { EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Passport } from '../../../models/passport.class';
import { PASSPORT_STATUS_CONST } from '../../../values/passport-status.const';
import { ModuleNames } from '../../../../../shared/values/module-names.const';
import { TranslateService } from '@ngx-translate/core';
import { PASSPORT_CERTIFICATION_LEVELS } from '../../../values/passport-certification-levels.const';
import { PERIOD_OF_USE_TYPES } from '../../../values/period-of-use.values';
import { PASSPORT_RATING } from '../../../values/passport-rating/passport-rating-values.const';
import { PASSPORT_RATING_LABEL } from '../../../values/passport-rating/passport-rating-label.const';
import { PASSPORT_DEPENDENCY } from '../../../values/passport-dependency.const';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { Utils } from '../../../../../shared/utils/utils.class';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
var PassportItemComponent = /** @class */ (function () {
    function PassportItemComponent(router, translateService, notification, filterState) {
        this.router = router;
        this.translateService = translateService;
        this.notification = notification;
        this.filterState = filterState;
        this.onOpen = new EventEmitter();
        this.onDelete = new EventEmitter();
        this.onDuplicate = new EventEmitter();
        this.onVersionFilter = new EventEmitter();
        this.moduleNames = ModuleNames;
        this.passportRatinglabel = PASSPORT_RATING_LABEL;
    }
    PassportItemComponent.prototype.ngOnInit = function () {
        // this.getVersionOnCla÷ss()
    };
    PassportItemComponent.prototype.openPassport = function (record) {
        this.onOpen.emit(record);
    };
    PassportItemComponent.prototype.copyProductId = function () {
        if (this.passport.product_passport_id) {
            var msg = 'Id copied';
            Utils.copyToClipBoard(this.passport.product_passport_id);
            this.notification.success(msg, 2000, true);
        }
        else {
            var msg = 'Id not yet set';
            this.notification.error(msg, 2000, true);
        }
    };
    PassportItemComponent.prototype.filterByVersion = function (record) {
        if (this.onVersionFilter) {
            this.isVersionFilterOn = !this.isVersionFilterOn;
            var data = { passport: record, filter: this.isVersionFilterOn };
            this.onVersionFilter.emit(data);
            this.getVersionOnClass();
        }
    };
    PassportItemComponent.prototype.getVersionOnClass = function () {
        var isIndep = !this.isPassportIndependent() || this.isForPublicAccess;
        return {
            'passportlist__item-version-indep': isIndep,
            'passportlist__item-version': !isIndep,
            'background_selected': this.isVersionFilterOn
        };
    };
    PassportItemComponent.prototype.duplicatePassport = function (record) {
        if (this.onDuplicate) {
            this.onDuplicate.emit(record);
        }
    };
    PassportItemComponent.prototype.deletePassport = function (record) {
        if (this.onDelete) {
            this.onDelete.emit(record);
        }
    };
    PassportItemComponent.prototype.createNew = function () {
        this.router.navigate(['passports/new']);
    };
    PassportItemComponent.prototype.getStatusView = function (statusValue) {
        if (this.parentModuleName === ModuleNames.SUPERVISOR || this.parentModuleName === ModuleNames.AGENT) {
            if (statusValue === PASSPORT_STATUS_CONST.DRAFT.value) {
                return PASSPORT_STATUS_CONST.DRAFT.view;
            }
            return;
        }
        switch (statusValue) {
            case PASSPORT_STATUS_CONST.ASK_FOR_MODIFICATION.value:
                return PASSPORT_STATUS_CONST.ASK_FOR_MODIFICATION.view;
            case PASSPORT_STATUS_CONST.DRAFT.value:
                return PASSPORT_STATUS_CONST.DRAFT.view;
            case PASSPORT_STATUS_CONST.PUBLISHED.value:
                return PASSPORT_STATUS_CONST.PUBLISHED.view;
            case PASSPORT_STATUS_CONST.WAITING_FOR_PUBLICATION.value:
                return PASSPORT_STATUS_CONST.WAITING_FOR_PUBLICATION.view;
            default:
                break;
        }
    };
    PassportItemComponent.prototype.isCompanyVisible = function () {
        return (this.parentModuleName === ModuleNames.MANUFACTURER || this.parentModuleName === ModuleNames.SPECIAL_ADMIN);
    };
    PassportItemComponent.prototype.areRatingsVisible = function () {
        return !!(this.parentModuleName === ModuleNames.MANUFACTURER
            || this.parentModuleName === ModuleNames.SUPERVISOR
            || this.parentModuleName === ModuleNames.AGENT
            || this.parentModuleName === ModuleNames.SPECIAL_ADMIN || this.isForPublicAccess);
    };
    PassportItemComponent.prototype.getRatingClass = function (rating, value) {
        var _a;
        var getPostfix = function () {
            switch (value) {
                case PASSPORT_RATING.THIRD_PARTY_VALID:
                    return 'colored';
                case PASSPORT_RATING.DECLARATION_VALID:
                    return 'dark';
            }
        };
        return _a = {
                'passportlist__item-content-ratings-icon': true
            },
            _a["passportlist__item-content-ratings-icon-" + rating + "-" + getPostfix()] = true,
            _a;
    };
    PassportItemComponent.prototype.isRatingVisible = function (value) {
        return !!value;
    };
    PassportItemComponent.prototype.getC2cTooltipValue = function () {
        var _this = this;
        var certificationLevel = PASSPORT_CERTIFICATION_LEVELS.find(function (i) { return i.key === _this.passport.certification_level; });
        return {
            X: !!certificationLevel ? this.translateService.instant(certificationLevel.value) : '?',
            Y: this.passport.certification_expiration || '?'
        };
    };
    PassportItemComponent.prototype.getCircularityTooltipValue = function () {
        var _this = this;
        var isPeriodExist = !!this.passport.period && !!this.passport.period_type;
        var periodViewValue = isPeriodExist ? PERIOD_OF_USE_TYPES.find(function (i) { return i.value === _this.passport.period_type; }).view : '';
        var periodOfUse = isPeriodExist ? this.passport.period + " " + (this.translateService.instant(periodViewValue) || '') : '?';
        var nextUsageViewValue = function () {
            if (!!_this.passport.next_usages && !!_this.passport.next_usages.length) {
                return _this.passport.next_usages.map(function (i) { return i.getTypeText(); }).join(', ') || '?';
            }
            return '?';
        };
        return {
            X: !!this.passport.recycle_percentage && this.passport.recycle_percentage.toString() + '%' || '?',
            Y: periodOfUse,
            Z: nextUsageViewValue()
        };
    };
    PassportItemComponent.prototype.getEnergyTooltipValues = function () {
        var _this = this;
        var getRenewableEnergiesSum = function () {
            var energiesSum = null;
            if (!!_this.passport.energies && !!_this.passport.energies.length) {
                _this.passport.energies.forEach(function (energy) {
                    if (energy.isRenewable()) {
                        energiesSum += energy.percentage;
                    }
                });
            }
            return !!energiesSum ? energiesSum + "%" : '?';
        };
        var getCarbonValue = function (value) { return !!value ? value : '?'; };
        return {
            energy_in_production: getRenewableEnergiesSum(),
            total_whole_life: getCarbonValue(this.passport.carbon_total),
            product_stage: getCarbonValue(this.passport.carbon_product),
            end_of_life: getCarbonValue(this.passport.carbon_end)
        };
    };
    PassportItemComponent.prototype.isPassportIndependent = function () {
        return this.parentModuleName !== this.moduleNames.SPECIAL_ADMIN
            && !(this.passport.type === PASSPORT_DEPENDENCY.INDEPENDENT && (this.parentModuleName === this.moduleNames.SUPERVISOR || this.parentModuleName === this.moduleNames.AGENT));
    };
    PassportItemComponent.prototype.getCreatedBy = function () {
        if (!this.passport || !this.passport.user) {
            return;
        }
        if (this.isCompanyVisible()) {
            return this.passport.user.company_name;
        }
        return (this.passport.type === PASSPORT_DEPENDENCY.INDEPENDENT) ? this.passport.user.company_name : this.passport.user.username;
    };
    PassportItemComponent.prototype.formatPassportModifiedDate = function () {
        return Utils.formatDate(this.passport.updated_at);
    };
    PassportItemComponent.prototype.formatPassportCreationDate = function () {
        return Utils.formatDate(this.passport.created_at);
    };
    Object.defineProperty(PassportItemComponent.prototype, "passportName", {
        get: function () {
            return this.passport.getProductName(this.filterState.multilanguage); // || this.passport.name;
        },
        enumerable: true,
        configurable: true
    });
    return PassportItemComponent;
}());
export { PassportItemComponent };
