<div class="dublicat">
  <div class="dublicat__close">
    <div class="ib-icon" [ngClass]="'close--icon'" (click)="dialog.close()"></div>
  </div>

  <div class="dublicat__info">
    <label class="dublicat__label-name">{{ 'Duplicate data from existing deposit' | translate }}</label>

    <div class="dublicat__info-table">
      <div class="dublicat__info-navigation">
        <!--
          <md-input-container id="nylon" >
            <input mdInput placeholder="Material" class="dublicat__nylon" [(ngModel)]="materialSearchTerm" (keyup)="search()">
          </md-input-container>
          <md-input-container id="site" >
            <input mdInput placeholder="Site" class="dublicat__site" [(ngModel)]="siteSearchTerm" (keyup)="search()">
          </md-input-container>
        -->
        <md-input-container id="name">
          <input mdInput autocomplete="off" 
                 placeholder="{{ 'Name' | translate }}, {{ 'Material' | translate }}, {{ 'Site' | translate }}.."
                 class="dublicat__name" [(ngModel)]="nameSearchTerm" (keyup)="search()">
        </md-input-container>
      </div>
      <label class="dublicat__label-found">{{ 'Found' | translate }} {{totalcount}} <span class="lowercase">{{ 'Items' | translate }}</span></label>

      <!--"| depositListFilter:searchkey"-->
      <duplicate-list-item
        *ngFor="let record of records | paginate:{id:'deposits', itemsPerPage: perpage, currentPage: currentpage, totalItems: totalcount }"
        [record]="record"
        (onSelect)="select($event)">
      </duplicate-list-item>
    </div>
  </div>
  <pagination-controls
    class="dublicat__pagination"
    id="deposits"
    (pageChange)="pageChanged($event)"
    maxSize="8"
    directionLinks="true"
    autoHide="true"
    previousLabel=""
    nextLabel=""
    screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
    screenReaderPageLabel="{{ 'page' | translate }}"
    screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}">
  </pagination-controls>
</div>
