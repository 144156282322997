import { Directive, Optional } from '@angular/core';
import { ReadOnlyService } from './read-only.service';
@Directive({
  selector: `
    button:not([hidden]):not([read-only-disabled])
  `,
  host: {
    '[hidden]': 'readOnlyService?.readOnly',
  }
})
export class FormElemsHiddenDirective {
  constructor(@Optional() private readOnlyService: ReadOnlyService,) {
  }
}
