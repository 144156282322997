import { AfterContentInit, ChangeDetectorRef, Component, ContentChild, Input, Optional } from '@angular/core';
import { PlaceAutocompleteDirective } from './place-autocomplete.directive';
import { Observable } from 'rxjs/Observable';
import { MapsAPILoader } from '@agm/core';
import { ReadOnlyService } from '../read-only/read-only.service';

@Component({
  moduleId: module.id,
  selector: 'place-autocomplete',
  templateUrl: 'place-autocomplete.component.html',
  styleUrls: ['place-autocomplete.component.scss'],
})
export class PlaceAutocompleteComponent implements AfterContentInit {
  @Input() options: any = {};

  @ContentChild(PlaceAutocompleteDirective) directive: PlaceAutocompleteDirective;
  mapsAPILoaderLoad: Promise<any>;
  autocompleteService: google.maps.places.AutocompleteService;

  inputElem: HTMLInputElement;
  results: google.maps.places.AutocompletePrediction[] = [];
  isFocus = false;
  focusedResultIndex: number = null;

  constructor(private mapsAPILoader: MapsAPILoader,
              private changeDetectorRef: ChangeDetectorRef,
              @Optional() private readOnlyService: ReadOnlyService) {
    this.mapsAPILoaderLoad = this.mapsAPILoader.load();
  }

  ngAfterContentInit() {
    const directive = this.directive;
    this.directive.isCreationMode = !!this.options.isCreationMode;
    this.inputElem = directive.elementRef.nativeElement;
    this.initFocusBlur();
    this.initKeyboardActions();
    this.mapsAPILoaderLoad.then(() => this.initAutocomplete());
  }

  initAutocomplete() {
    this.autocompleteService = new google.maps.places.AutocompleteService;

    Observable
      .merge(
        Observable.fromEvent(this.inputElem, 'focus'),
        Observable.fromEvent(this.inputElem, 'input')
      )
      .debounceTime(200)
      .subscribe(() => {
        const value = this.inputElem.value;

        if (value) {
          this.options.input = value;
          this.autocompleteService.getPlacePredictions(this.options,
            (results: google.maps.places.AutocompletePrediction[], status: any) => {
              if (status !== 'OK' && status !== 'ZERO_RESULTS') {
                console.error('AutocompleteService status is not OK, but is ', status);
                return;
              }

            this.results = results || [];
            this.focusedResultIndex = null;
            this.changeDetectorRef.detectChanges();
          });
        } else {
          this.results = [];
          this.focusedResultIndex = null;
        }
      });
  }

  initFocusBlur() {
    Observable
      .fromEvent(this.inputElem, 'focus')
      .subscribe(() => {
        if (this.readOnlyService && this.readOnlyService.readOnly) {
          return;
        }

        this.isFocus = true;
      });

    Observable
      .fromEvent(this.inputElem, 'blur')
      .subscribe(() => {
        if (this.readOnlyService && this.readOnlyService.readOnly) {
          return;
        }

        const value = this.inputElem.value;

        if (value) {
          this.options.input = value;
          this.autocompleteService.getPlacePredictions(this.options,
            (places: google.maps.places.AutocompletePrediction[], status: any) => {
              if (status !== 'OK' && status !== 'ZERO_RESULTS') {
                console.error('AutocompleteService status is not OK, but is ', status);
                return;
              }

              if (places) {
                this.select(places[0]);
              }
            });
        }

        setTimeout(() => {
          this.isFocus = false;
          this.focusedResultIndex = null;
        }, 300);
      });
  }

  initKeyboardActions() {
    Observable
      .fromEvent(this.inputElem, 'keydown')
      .filter((e: KeyboardEvent) => e.keyCode === 40 || e.keyCode === 38 || e.keyCode === 13)
      .subscribe((event: KeyboardEvent) => {
        event.preventDefault();
        switch (event.keyCode) {
          case 40: // arrow down
            if (this.focusedResultIndex === null) {
              this.focusedResultIndex = 0;
            } else if (this.focusedResultIndex >= 0 && this.focusedResultIndex < this.results.length - 1) {
              this.focusedResultIndex = this.focusedResultIndex + 1;
            }
            break;
          case 38: // arrow up
            if (this.focusedResultIndex !== null && this.focusedResultIndex > 0) {
              this.focusedResultIndex = this.focusedResultIndex - 1;
            }
            break;
          case 13: // enter
            if (this.focusedResultIndex !== null && this.focusedResultIndex >= 0) {
              this.select(this.results[this.focusedResultIndex]);
            }
            this.inputElem.blur();
            break;
          default:
            break;
        }
      });
  }

  select(place: google.maps.places.AutocompletePrediction) {
    this.directive.placeSelect.emit(place);
  }
}
