import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DepositTag } from '../../models/deposit-tag.class';
import { DepositLocation } from '../../models/deposit-location.class';
import { DepositLocationService } from '../../services/deposit-location.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { DepositTagService } from '../../services/deposit-tag.service';
import { MdDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../../../shared/utils/utils.class';
var DepositTagListComponent = /** @class */ (function () {
    function DepositTagListComponent(locationService, notification, depositTagService, tagLocationJunctionService, mdDialog, translate) {
        this.locationService = locationService;
        this.notification = notification;
        this.depositTagService = depositTagService;
        this.tagLocationJunctionService = tagLocationJunctionService;
        this.mdDialog = mdDialog;
        this.translate = translate;
        this.depositLocation = [];
        this.isDescriptionShown = true;
        this.openDepositLocations = {};
        this.sitesPagination = {};
        this.queryDepositLocationParams = { expand: 'use_count,tags,tags.deposit_count', agent: 1, pagination: 1, page: 0, assigned: 1 };
    }
    DepositTagListComponent.prototype.ngOnInit = function () {
        this.updateRecords();
    };
    DepositTagListComponent.prototype.getTagsSorted = function (location) {
        return location.tags ? location.tags.sort(function (next, previous) { return next.editable && !previous.editable ? -1 : 1; }) : [];
    };
    DepositTagListComponent.prototype.closeDescription = function () {
        this.isDescriptionShown = false;
    };
    DepositTagListComponent.prototype.getAddressWithoutCountryPart = function (address) {
        return this.getCountryAddressAndCountry(address).countryAddress;
    };
    DepositTagListComponent.prototype.getCountryPart = function (address) {
        return this.getCountryAddressAndCountry(address).country;
    };
    DepositTagListComponent.prototype.getCountryAddressAndCountry = function (address) {
        var obligeCommasInValidAddress = 2;
        if (!address) {
            return { countryAddress: '', country: '' };
        }
        var addressParts = address.split(',');
        if (addressParts.length >= obligeCommasInValidAddress) {
            return { countryAddress: addressParts.slice(0, -1).join(',') + ',',
                country: addressParts[addressParts.length - 1] };
        }
        return { countryAddress: address, country: '' };
    };
    DepositTagListComponent.prototype.toggleLocation = function (id) {
        this.openDepositLocations[id] = !this.openDepositLocations[id];
    };
    DepositTagListComponent.prototype.isTagEdited = function (tagId, siteId) {
        return this.editTag && this.editTag.id === tagId
            && this.editTag.site_id === siteId;
    };
    DepositTagListComponent.prototype.removeTag = function (tag, siteId) {
        var _this = this;
        var message = this.translate.instant("Are you sure you want delete this tag ?");
        this.confirmTagChange(message, tag.deposit_count).subscribe(function (result) { return result ? tag.destroy().flatMap(function () { return _this.updateLocation(siteId); }).subscribe() : null; });
    };
    DepositTagListComponent.prototype.confirmTagChange = function (mainMessage, usageNumber) {
        var main = mainMessage;
        var additional = !!usageNumber && !!Number(usageNumber) ?
            this.translate.instant("It is used N in deposits", { X: usageNumber }) : '';
        return this.mdDialog.open(ConfirmDialogComponent, { data: { text: main + " " + additional } }).afterClosed();
    };
    DepositTagListComponent.prototype.updateTag = function (tag) {
        var _this = this;
        var message = this.translate.instant("Are you sure you want edit this tag ?");
        this.confirmTagChange(message, tag.deposit_count).subscribe(function (result) {
            if (result) {
                _this.editTag = tag;
                _this.newEditTagName = tag.name;
                setTimeout(function () {
                    if (!!_this.input) {
                        _this.input.nativeElement.focus();
                    }
                });
            }
        });
    };
    DepositTagListComponent.prototype.editInputSize = function (name) {
        var additionalChars = 4;
        return Math.max(this.newEditTagName.length, name.length + additionalChars);
    };
    DepositTagListComponent.prototype.updateEditTag = function (oldTag) {
        var _this = this;
        var copyTag = new DepositTag(tslib_1.__assign({}, oldTag));
        copyTag.name = this.newEditTagName;
        copyTag.save().do(function (tag) {
            Object.assign(oldTag, tag);
        }).subscribe(function () { return _this.editTag = null; }, function () { return _this.editTag = null; });
    };
    DepositTagListComponent.prototype.resetEdit = function (oldTag) {
        if (oldTag.name === this.newEditTagName) {
            this.editTag = null;
            return;
        }
    };
    DepositTagListComponent.prototype.copyTagName = function (name) {
        try {
            Utils.copyToClipBoard(name);
            this.notification.success('Tag name copied', 2000, true);
        }
        catch (e) {
            this.notification.error('Tag name not yet set', 2000, true);
        }
    };
    DepositTagListComponent.prototype.createNewTag = function (targetInput, siteId) {
        var _this = this;
        var value = targetInput.value;
        if (!value) {
            return;
        }
        this.isLoadingNewTag(true, targetInput);
        this.createDepositTag(value, siteId)
            .flatMap(function () { return _this.updateLocation(siteId); })
            .subscribe(function () { return _this.isLoadingNewTag(false, targetInput); }, function () {
            targetInput.disabled = false;
            targetInput.focus();
        });
    };
    DepositTagListComponent.prototype.isLoadingNewTag = function (loading, input) {
        if (loading) {
            input.disabled = true;
        }
        else {
            input.value = '';
            input.disabled = false;
            input.focus();
        }
    };
    DepositTagListComponent.prototype.createDepositTag = function (name, site_id) {
        var depositTag = new DepositTag({ name: name, site_id: site_id });
        return depositTag.save();
    };
    DepositTagListComponent.prototype.updateLocation = function (siteId) {
        var _this = this;
        var index = this.depositLocation.findIndex(function (location) { return location.id === siteId; });
        return this.depositLocation[index].load(this.queryDepositLocationParams)
            .do(function (location) { return Object.assign(_this.depositLocation[index], location); });
    };
    DepositTagListComponent.prototype.pageChange = function (page) {
        this.queryDepositLocationParams.page = page;
        this.sitesPagination.currentPage = page;
        this.updateRecords();
    };
    DepositTagListComponent.prototype.updateRecords = function () {
        var _this = this;
        this.locationService
            .getWithPaginationData(this.queryDepositLocationParams)
            .subscribe(function (res) {
            var headers = res.headers;
            _this.sitesPagination.currentPage = Number(headers.get('X-Pagination-Current-Page')) || 1,
                _this.sitesPagination.pageCount = Number(headers.get('X-Pagination-Page-Count')) || 1,
                _this.sitesPagination.perPage = Number(headers.get('X-Pagination-Per-Page')) || res.json().length,
                _this.sitesPagination.totalCount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length;
            _this.depositLocation = res.json().map(function (dl) { return new DepositLocation(dl); });
        });
    };
    return DepositTagListComponent;
}());
export { DepositTagListComponent };
