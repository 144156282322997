/**
 * Created by aleksandr on 31.07.17.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';
import { IUserInfo } from './user-info.interface';

@Injectable()
export class AuthResolver implements Resolve<IUserInfo> {

  constructor(private _userService: CurrentUserService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    console.info('Launch  -> AuthResolver');
    return this
      ._userService
      .loggedInChecked$
      .map((res) => this._userService.infoData);
  }
}
