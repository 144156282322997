<div class="agent-passport-dashboard">
    <div class="header">
        <app-create-button (create)="onCreateNewPassport()"
            [label]="'Create new Circular Passport'"></app-create-button>
        <div (click)="openRatingExplanationDialog()" class="passportlist__rating-explanation ng-star-inserted"></div>
    </div>
    <div class="filter">
        <app-filter-tab (tabSelected)="onTabSelected($event)" [tabs]="tabs" [initialTab]="currentTab"></app-filter-tab>
        <div class="filter-container">
            <button class="clearButton" (click)="resetFiltersToDefault()" [disabled]="filtersAreDefault()">
                {{'Clear filters' | translate}}
            </button>
            <div *ngIf="currentTab === 'published'">
                <app-base-toggle [(isChecked)]="filterStates[currentTab].showMyPassports"
                    (isCheckedChange)="onMyPassportsToggleChange($event)" label="{{'seeOnlyMyPassports' | translate}}" fontSize="14px"
                    sliderColor="#adb5bd" sliderColorChecked="#2dce77" backgroundColor="#fff">
                </app-base-toggle>
            </div>
            <div class="main-wrapper">
                <div class="column">
                    <app-search [searchTerm]="filterStates[currentTab].search"
                        (searchChanged)="onSearchFilterChange($event)"></app-search>
                    <div class="row">
                        <app-material-search [materials]="filterStates[currentTab].materials"
                            (materialsChange)="onMaterialsFilterChange($event)"></app-material-search>
                        <app-category-search [categories]="filterStates[currentTab].categories"
                            (categoriesChange)="onCategoriesFilterChange($event)"></app-category-search>
                    </div>
                    <div class="row">
                        <app-database-filter [databaseSelected]="filterStates[currentTab].databases"
                            (databaseChange)="onDatabaseFilterChange($event)"></app-database-filter>
                        <app-data-provider-filter [dataProviderSelected]="filterStates[currentTab].dataProviders"
                            (dataProviderChange)="onDataProviderFilterChange($event)"></app-data-provider-filter>
                    </div>
                    <app-sort-by [defaultSort]="filterStates[currentTab].sortBy"
                        (sortChanged)="onSortChange($event)"></app-sort-by>
                </div>
                <div class="column">
                    <app-family-filter [familySelected]="filterStates[currentTab].families" [userRole]="'agent'"
                        (familyChange)="onFamilyFilterChange($event)" [disabled]="filterStates[currentTab].showMyPassports"></app-family-filter>
                    <app-passport-id-search [searchTerm]="filterStates[currentTab].searchId"
                        (searchChange)="onSearchIdFilterChange($event)"></app-passport-id-search>
                    <app-epd-type-filter [epdTypeSelected]="filterStates[currentTab].epdType"
                        (epdTypeChange)="onEpdTypeFilterChange($event)"></app-epd-type-filter>
                </div>
                <div class="column ">
                    <app-rating-filter [ratingSelected]="filterStates[currentTab].rating"
                        (ratingChange)="onRatingFilterChange($event)"></app-rating-filter>
                    <app-data-representativity-filter
                        [representativitySelected]="filterStates[currentTab].dataRepresentativity"
                        (representativityChange)="onDataRepresentativityFilterChange($event)"></app-data-representativity-filter>
                    <app-creator-filter [creatorSelected]="filterStates[currentTab].company_creators"
                        (creatorChange)="onCreatorFilterChange($event)"></app-creator-filter>
                </div>
            </div>
        </div>
    </div>
    <div class="passport-container">
        <app-passport-collection #passportCollectionRef [currentPage]="pagination.current_page"
            [totalPages]="pagination.page_count" [canSelect]="false" (pageChange)="onPageChange($event)"
            [passports]="passports" (deletePassportEvent)="deleteOnePassport($event)"
            (duplicateRequest)="duplicatePassport($event)" (versionClicked)="onVersionClicked($event)"
            [totalPassportCount]="pagination.total_count"
            [isLoading]="isLoading" [showStatus]="false">
        </app-passport-collection>
    </div>
</div>