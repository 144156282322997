import * as tslib_1 from "tslib";
import { ApiService } from '../../../shared/services/api.service';
import { NeedStatsContainer } from '../models/need-stats-container.class';
import { DepositStatsContainer } from '../models/deposit-stats-container.class';
import { ClientStatsContainer } from '../models/client-stats-container.class';
import { TransformationStatsContainer } from '../models/transformation-stats-container.class';
import { SupervisorStatsContainer } from '../models/supervisor-stats-container.class';
import { CurrentUser } from '../../../shared/auth/current-user.class';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { DepositOwnerStatsContainer } from '../models/deposit-owner-stats-container.class';
import { SalesStatsContainer } from '../models/sales-stats-container.class';
import { AgentStatsContainer } from '../models/agent-stats-container.class';
import { TSMStatsContainer } from '../models/tsm-stats-container.class';
import { EcosystemStatsContainer } from '../models/ecosystem-stats-container.class';
var StatsService = /** @class */ (function (_super) {
    tslib_1.__extends(StatsService, _super);
    function StatsService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'stats';
        _this.navigationService = ServiceLocator.injector.get(NavigationService);
        return _this;
    }
    StatsService.prototype.getNeedsUrl = function () {
        return this.getEndpoint(this.namespace + '/needs');
    };
    StatsService.prototype.getDepositsUrl = function () {
        return this.getEndpoint(this.namespace + '/deposits');
    };
    StatsService.prototype.getClientsUrl = function () {
        return this.getEndpoint(this.namespace + '/clients');
    };
    StatsService.prototype.getTransformationsUrl = function () {
        return this.getEndpoint(this.namespace + '/transformations');
    };
    StatsService.prototype.getUserUrl = function () {
        return this.getEndpoint(this.namespace + '/user');
    };
    StatsService.prototype.getNeeds = function (query) {
        return this
            .sendGet(this.getNeedsUrl(), { search: query })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new NeedStatsContainer(data); })
            .catch(this.onError.bind(this));
    };
    StatsService.prototype.getDeposits = function (query) {
        return this
            .sendGet(this.getDepositsUrl(), { search: query })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new DepositStatsContainer(data); })
            .catch(this.onError.bind(this));
    };
    StatsService.prototype.getClients = function (query) {
        return this
            .sendGet(this.getClientsUrl(), { search: query })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new ClientStatsContainer(data); })
            .catch(this.onError.bind(this));
    };
    StatsService.prototype.getTransformations = function (query) {
        return this
            .sendGet(this.getTransformationsUrl(), { search: query })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new TransformationStatsContainer(data); })
            .catch(this.onError.bind(this));
    };
    StatsService.prototype.getSupervisorStats = function () {
        var id = CurrentUser.infoData.id;
        var role = this.navigationService.roleId;
        return this
            .sendGet(this.getUserUrl(), { search: { 'id[]': id, 'roles[]': role } })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new SupervisorStatsContainer(data[id][role]); })
            .catch(this.onError.bind(this));
    };
    StatsService.prototype.getDepositOwnerStats = function () {
        var id = CurrentUser.infoData.id;
        var role = this.navigationService.roleId;
        return this
            .sendGet(this.getUserUrl(), { search: { 'id[]': id, 'roles[]': role } })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new DepositOwnerStatsContainer(data[id][role]); })
            .catch(this.onError.bind(this));
    };
    StatsService.prototype.getSalesStats = function () {
        var id = CurrentUser.infoData.id;
        var role = this.navigationService.roleId;
        return this
            .sendGet(this.getUserUrl(), { search: { 'id[]': id, 'roles[]': role } })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new SalesStatsContainer(data[id][role]); })
            .catch(this.onError.bind(this));
    };
    StatsService.prototype.getAgentStats = function () {
        var id = CurrentUser.infoData.id;
        var role = this.navigationService.roleId;
        return this
            .sendGet(this.getUserUrl(), { search: { 'id[]': id, 'roles[]': role } })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new AgentStatsContainer(data[id][role]); })
            .catch(this.onError.bind(this));
    };
    StatsService.prototype.getTSMStats = function () {
        var id = CurrentUser.infoData.id;
        var role = this.navigationService.roleId;
        return this
            .sendGet(this.getUserUrl(), { search: { 'id[]': id, 'roles[]': role } })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new TSMStatsContainer(data[id][role]); })
            .catch(this.onError.bind(this));
    };
    StatsService.prototype.getEcosystemStats = function (query) {
        var id = CurrentUser.infoData.id;
        var role = this.navigationService.roleId;
        var searchQuery = Object.assign({ 'id[]': id, 'roles[]': role }, query);
        return this
            .sendGet(this.getUserUrl(), { search: searchQuery })
            .map(function (res) { return res.json(); })
            .map(function (data) { return new EcosystemStatsContainer(data[id][role]); })
            .catch(this.onError.bind(this));
    };
    return StatsService;
}(ApiService));
export { StatsService };
