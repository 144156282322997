<div>
  <div class="needlist__item">
    <div>
      <!-- Photo of the passport of the need if it is a non targeted need and the deposit’s main photo if it is -->
      <img *ngIf="!imageError" [src]="getImageSrc(need)" [alt]="getImageAlt(need)" (error)="onImageError($event)">
      <img *ngIf="need.independent === 1" src="../../../../../assets/svg/public_icon.svg" alt="" class="public_icon">
      <div *ngIf="imageError" class="fallback-image"></div>
    </div>
    <div>
      <div>
        <span title="{{need.name}}">{{need.name}}</span>
        <span title="{{need.quantity}} {{PASSPORT_UNITS_MAP[need?.passport?.unit]}}">{{need.quantity}} {{PASSPORT_UNITS_MAP[need?.passport?.unit]}}</span>
        <span [ngClass]="{'expired': isExpired()}">{{need.delivery_date}}</span>
        <span title="{{need.client_location.name}}">{{need.client_location.name}}</span>
      </div>
      <div>
        <!-- Either the targeted deposit’s name or the passport’s name of the need -->
        <ng-container *ngIf="need.from_deposit">
          <!-- If the deposit is available, display its name -->
          <span *ngIf="need.fromDepositEntity" title="{{need.fromDepositEntity.name}}">
            <img src="../../../../../assets/svg/target_icon.svg" alt="target icon">
            {{need.fromDepositEntity.name}}
          </span>
          <!-- If the deposit is not available, display the warning message in red -->
          <span *ngIf="!need.fromDepositEntity" class="error">
            <img src="../../../../../assets/svg/target_icon.svg" alt="target icon">
            {{'Deposit_no_longer_available' | translate}}
          </span>
        </ng-container>
        <!-- Display the passport's name if the deposit is not present -->
        <span *ngIf="!need.from_deposit" title="{{need.passport.name}}">{{need.passport.name}}</span>
        <span>{{need.satisfying_quantity}} {{PASSPORT_UNITS_MAP[need?.passport?.unit]}} {{(need.satisfying_quantity > 1 ? 'received_plural' : 'received_singular') | translate}}</span>
        <span>{{need.date_precision_text}}</span>
        <span title="{{need.client_location.address}}">{{need.client_location.address}}</span>
      </div>
    </div>
    <div [ngClass]="getColorByStatusClass()">
      <div>
        <span>{{getKeyStatus() | translate }}</span>
        <ng-container *ngIf="need.ecosystem_count > 0">
          <span>
            {{
              (need.ecosystem_count === 1 ? 'in_ecosystem' : 'in_ecosystems')
              | translate:{X: need.ecosystem_count.toString()}
            }}
          </span>
        </ng-container>
      </div>
      <div>
        <span class="hamburger-menu" [mdMenuTriggerFor]="menu" id="needItemMenu#{{need.id}}">
          <span></span>
          <span></span>
          <span></span>
        </span>
        <md-menu #menu="mdMenu" x-position="before">
          <button md-menu-item (click)="emitArchive(need)" *ngIf="need.status != 15 && need.status != 13">
            {{ 'Archive' | translate }}
          </button>
          <button md-menu-item (click)="viewDeposit(need)" *ngIf="need.from_deposit && need.fromDepositEntity">
            {{ 'See deposit' | translate }}
          </button>
          <button md-menu-item (click)="emitDuplicate(need)" *ngIf="need.status != 15">
              {{ 'Duplicate' | translate }}
          </button>
          <button md-menu-item (click)="viewNeed(need)" *ngIf="need.status != 15">
            {{ 'Edit' | translate }}
          </button>
          <button md-menu-item (click)="deleteNeed(need)">
            {{ 'Delete' | translate }}
          </button>
        </md-menu>
      </div>
    </div>
  </div>
  <div *ngIf="offers.length > 0" class="offer-bar">
    <header (click)="toggleOffers()">
      <div>
        <img src="../../../../../assets/svg/need_offers.svg" alt="offer icon">
        <span>{{offers.length}} {{(offers.length > 1 ? 'offers_for_this_need' : 'offer_for_this_need') | translate}}</span>
      </div>
      <div>
        <img *ngIf="!showOffers" src="../../../../../assets/svg/need-offers-arrow-down.svg" alt="">
        <img *ngIf="showOffers" src="../../../../../assets/svg/need-offers-arrow-up.svg" alt="">
      </div>
    </header>
    <div class="need-item-offer" *ngFor="let offer of offers; let i = index">
      <div *ngIf="showOffers">
        <span title="{{offer.ecosystem.name}}">{{offer.ecosystem.name}}</span>
        <span *ngIf="offer.quantity; else noQuantity">
          {{offer.quantity}} {{PASSPORT_UNITS_MAP[need?.passport?.unit]}}
        </span>
        <ng-template #noQuantity>
          <span>{{'No_quantity_available' | translate}}</span>
        </ng-template>
        <span>{{offer.delivery_date}}</span>
        <div>
              <!--
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="7.5" stroke="white" />
            <path
              d="M8.63965 3.89062L8.52246 8.90527H7.5166L7.39453 3.89062H8.63965ZM7.36035 10.4434C7.36035 10.2643 7.41895 10.1146 7.53613 9.99414C7.65658 9.87044 7.82259 9.80859 8.03418 9.80859C8.24251 9.80859 8.4069 9.87044 8.52734 9.99414C8.64779 10.1146 8.70801 10.2643 8.70801 10.4434C8.70801 10.6159 8.64779 10.764 8.52734 10.8877C8.4069 11.0081 8.24251 11.0684 8.03418 11.0684C7.82259 11.0684 7.65658 11.0081 7.53613 10.8877C7.41895 10.764 7.36035 10.6159 7.36035 10.4434Z"
              fill="white" />
          </svg>
      
          <span>The offer expires on 23/04/2023</span>-->
        </div>
      </div>
      <div *ngIf="showOffers" [ngClass]="getStatusOfferColorClass(i)">
        <span *ngIf="statusoffer.length > 0">{{statusoffer[i] | translate}}</span>
        <span class="hamburger-menu" [mdMenuTriggerFor]="offerMenu">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>      
      <md-menu #offerMenu="mdMenu" x-position="before">
        <button md-menu-item (click)="emitViewOffer(offer)">
          {{ 'See offer' | translate }}
        </button>
        <button  md-menu-item *ngIf="offer.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION"
            (click)="emitReport(offer)">
          {{ 'Problem with offer' | translate }}
        </button>
        <button md-menu-item *ngIf="offer.status === ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION"
                (click)="emitConfirm(offer)">
          {{ 'Confirm' | translate }}
        </button>
      </md-menu>
    </div>
</div>