import { Component, Input, OnInit } from '@angular/core';
import { ToxicityState } from '../../../entities/passport/partials/passport-rating/passport-rating.component';


@Component({
    selector: 'app-rating-water-stewardship-icon',
    templateUrl: './rating-water-stewardship-icon.component.html',
})
export class RatingWaterStewardshipIconComponent {
    @Input() toxicityState: ToxicityState; // Toxicity state of the product
    ToxicityState = ToxicityState; // Enum for toxicity state for the template
    
    constructor() { }
}
