/**
 * Created by Aleksandr C. on 9.02.17.
 */

import { PassportService } from '../services/passport.service';
import { PASSPORT_UNITS, PASSPORT_UNITS_MAP, PASSPORT_UNITS_TYPE } from '../values/passport-units.const';
import { Material } from './material.class';
import { PassportComponent } from './component.class';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
import { ISearchable } from '../../../shared/interfaces';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { ComponentService } from '../services/component.service';
import { EventEmitter } from '@angular/core';
import { MaterialService } from '../services/material.service';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { CustomListUnitCssClass, ECustomListUnit } from '../../../shared/custom-list/interfaces/custom-list.interface';
import { PASSPORT_STATUS } from '../values/passport-status.const';
import { User } from '../../../shared/models/user.class';
import { NextUsage } from './next-usage.class';
import { Observable } from 'rxjs/Observable';
import { PassportKeywordJunction } from './passport-keyword-junction.class';
import { TranslateService } from '@ngx-translate/core';
import { Keyword } from './keyword.class';
import { UsedEnergy } from './used-energy.class';
import { PASSPORT_NAME_TYPE } from '../values/passport-name-types.const';
import { Manufacturers } from './manufacturers.class';
import { ManufacturersService } from '../services/manufacturers.service';
import { Colors } from './colors.class';
import { ColorsService } from '../services/colors.service';
import { DEFAULT_RADIO_VALUE, DEFAULT_RADIO_VALUES } from '../values/default-radio-values.const';
import { UsedEnergyService } from '../services/used-energy.service';
import { DatePipe } from '@angular/common';
import { PassportRating } from './passport-rating.class';
import { CategoryItem } from '../../category/models/category-item.class';
import { PassportDistributionArea } from './passport-distribution-area.class';
import { PASSPORT_TYPES } from '../values/passport-types.const';
import { PassportGtin } from './passport-gtin.class';
import { ModuleNames } from '../../../shared/values/module-names.const';
import { PassportSynchronizationData } from './passport-synchronization-data.class';

export const PASSPORT_DEFAULT_CATEGORY = null;

export const PASSPORT_DEFAULT_NAME_TYPE = PASSPORT_NAME_TYPE.CATALOGUE;
export const PASSPORT_DEFAULT_RADIO_KEY = DEFAULT_RADIO_VALUES.find(i => i.key === DEFAULT_RADIO_VALUE.DONTKNOW).key;

export const enum PassportFamily {
  FAMILY_LOCAL = 1,
  FAMILY_MANUFACTURER = 2,
  FAMILY_GENERIC = 3,
  FAMILY_VOID = 4,
}
export class Passport extends ExtendedActiveRecord implements ISearchable {

  protected provider = PassportService;
  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  protected componentService: ComponentService = ServiceLocator.injector.get(ComponentService);
  protected materialService: MaterialService = ServiceLocator.injector.get(MaterialService);
  protected manufacturersService: ManufacturersService = ServiceLocator.injector.get(ManufacturersService);
  protected colorsService: ColorsService = ServiceLocator.injector.get(ColorsService);
  protected usedEnergyService: UsedEnergyService = ServiceLocator.injector.get(UsedEnergyService);
  protected passportService: PassportService = ServiceLocator.injector.get(PassportService);
  id: number;
  product_passport_id: string;
  name: string;
  english_name: string;
  image: string;
  thumbnail: string;
  validity: any;
  unit: number;
  type: number;

  /**
   * Replaced by keywords
   * @deprecated
   */
  format: string;
  keywords: Keyword[] = [];
  manufacturer: string;
  logo: string;
  category_id: number;
  c2c_certified: number;
  period: number;
  period_type: number;
  name_type: number;
  trade_name: string;
  product_refrence: string;
  typology: string;
  additional: string;
  first_life: string;
  recycle_percentage: number;
  conversion: number;
  dimension_length: number;
  dimension_width: number;
  dimension_height: number;
  purchase_price: number;
  appearance: string;
  custom_made_name: string;
  composite_name: string;
  catalogue_product_name: string;
  for_rating: number;
  certification_expiration: string;
  certification_level: number;
  distribution_areas: PassportDistributionArea[];

  /**
   * @deprecated
   */
  next_use: string;

  next_usages: NextUsage[] = [];
  manufacturers: Manufacturers[] = [];
  colors: Colors[] = [];
  description: string;
  recommendation: string;
  status: number;
  created_at: string;
  updated_at: string;
  user: User;
  components: PassportComponent[];
  message: string;
  used_energy: UsedEnergy[] = [];
  water_stewardship_certified: number;
  social_fairness_certified: number;
  no_banned_materials: number;
  carbon_total: number;
  carbon_product: number;
  carbon_construction: number;
  carbon_end: number;
  carbon_conversion: number;
  carbon_conversion_description: string;
  carbon_conversion_date: string;
  rating: PassportRating;
  energies: UsedEnergy[];
  optimization_strategy: string;
  category: CategoryItem;
  demountability_default: number;
  percentage_default: number;
  version: string;
  count_versions: number; //DON'T USE THIS REMOVE IN LEVEL2
  link_reference: number;
  water_cycle: number;
  water_conversion: number;
  water_construction: number;
  water_use_stage: number;
  water_operational_energy_use: number;
  water_operational_water_use: number;
  water_end_of_life: number;
  water_resource_recovery: number;
  energy_cycle_renewable: number;
  energy_product_renewable: number;
  energy_construction_renewable: number;
  energy_use_stage_renewable: number;
  energy_operational_energy_use_renewable: number;
  energy_operational_water_use_renewable: number;
  energy_end_of_life_renewable: number;
  energy_resource_recovery_renewable: number;
  energy_cycle: number;
  energy_product: number;
  energy_construction: number;
  energy_use_stage: number;
  energy_operational_energy_use: number;
  energy_operational_water_use: number;
  energy_end_of_life: number;
  energy_resource_recovery: number;
  cert_version: number;
  cert_material_health_level: number;
  cert_material_reuse_level: number;
  cert_carbon_level: number;
  cert_water_level: number;
  cert_socials_level: number;
  family: PassportFamily;
  use_stage: number;
  operational_energy_use: number;
  resource_recovery_stage: number;
  carbon_operational_water_use: number;
  language: string;
  source: number;
  otherSource: string;
  epdType: number;
  documentId: string;
  operator: number;
  otherOperator: string;
  synchronized: boolean;
  external_passport_id: number;
  synchronization_data: PassportSynchronizationData;

  // TODO remove this as it is going to be moved to PassportComponent
  /**
   * @deprecated
   */
  materials: Material[];

  nomenclature: NomenclatureItem[];
  price: number;
  rating_token: string;
  rating_token_provided: boolean;
  gtins: PassportGtin[];
  onSyncComponents$: EventEmitter<PassportComponent[]> = new EventEmitter();
  onSyncManufacturers$: EventEmitter<Manufacturers[]> = new EventEmitter();
  user_id: number;
  /**
   * @deprecated
   * @type {EventEmitter<any>}
   */
  onSyncMaterials$: EventEmitter<Material[]> = new EventEmitter();
  onSyncColors$: EventEmitter<Colors[]> = new EventEmitter();
  onSyncDistributionAreas$: EventEmitter<PassportDistributionArea[]> = new EventEmitter();
  onSyncUsedEnergy$: EventEmitter<UsedEnergy[]> = new EventEmitter();

  private oldName: string;
  private oldCompositeName: string;
  private oldEnglishName: string;

  public static EPD_TYPE_DESCRIPTIONS = {
    1: 'FDES individuelle',
    2: 'FDES collective',
    3: 'DED - Données environnementales par défaut',
    4: 'DEC - données environnementales conventionnelles'
  };

  public static SOURCE_DESCRIPTIONS = {
    1: 'Inies'
  };

  public static OPERATOR_DESCRIPTIONS = {
    1: 'Inies'
  };

  get supervisor_warning() {
    if (localStorage.getItem('roles').indexOf('supervisor') > -1) {
      return this.status === PASSPORT_STATUS.PUBLISHED;
    } else {
      return false;
    }
  }

  get unit_text() {
    let unit = PASSPORT_UNITS.find((item) => item.id === this.unit);
    // no need to translate it , then we will have different matching if we change the language
    // return unit ? this.translateService.instant(unit.title) : this.unit;
    return unit ? unit.title : this.unit;
  }

  get unitView() {
    return this.unit_text;
  }

  get customListUnit() {
    return (<any>{
      'kg': ECustomListUnit.KG,
      'ton(s)': ECustomListUnit.TON,
      'm²': ECustomListUnit.M2,
      'm³': ECustomListUnit.M3,
      'unit': ECustomListUnit.UNIT,
      'piece(s)': ECustomListUnit.PCS,
      'm': ECustomListUnit.M,
      'L': ECustomListUnit.L
    })[this.unit_text];
  }

  // TODO Used in ecosystem step, should be refactored
  get unitIconCss() {
    return CustomListUnitCssClass[this.customListUnit];
  }

  fields() {
    return [
      'id',
      'product_passport_id',
      'name',
      'english_name',
      'image',
      'thumbnail',
      'status',
      'validity',
      'unit',
      'manufacturer',
      'message',
      'next_usages',
      'description',
      'recommendation',
      'created_at',
      'updated_at',
      'user',
      'category_id',
      'category',
      'c2c_certified',
      'period',
      'period_type',
      'keywords',
      'price',
      'format',
      'type',
      'trade_name',
      'product_refrence',
      'typology',
      'additional',
      'name_type',
      'first_life',
      'recycle_percentage',
      'conversion',
      'dimension_length',
      'dimension_width',
      'dimension_height',
      'purchase_price',
      'appearance',
      'user_id',
      'custom_made_name',
      'composite_name',
      'catalogue_product_name',
      'social_fairness_certified',
      'water_stewardship_certified',
      'for_rating',
      'certification_expiration',
      'certification_level',
      'no_banned_materials',
      'carbon_total',
      'carbon_product',
      'carbon_construction',
      'carbon_end',
      'carbon_conversion',
      'carbon_conversion_description',
      'carbon_conversion_date',
      'used_energy',
      'rating',
      'energies',
      'optimization_strategy',
      'demountability_default',
      'percentage_default',
      'link_reference',
      'distribution_areas',
      'rating_token',
      'rating_token_provided',
      'version',
      'water_cycle',
      'water_conversion',
      'water_construction',
      'water_use_stage',
      'water_operational_energy_use',
      'water_operational_water_use',
      'water_end_of_life',
      'water_resource_recovery',
      'energy_cycle_renewable',
      'energy_product_renewable',
      'energy_construction_renewable',
      'energy_use_stage_renewable',
      'energy_operational_energy_use_renewable',
      'energy_operational_water_use_renewable',
      'energy_end_of_life_renewable',
      'energy_resource_recovery_renewable',
      'energy_cycle',
      'energy_product',
      'energy_construction',
      "energy_use_stage",
      'energy_operational_energy_use',
      'energy_operational_water_use',
      'energy_end_of_life',
      'energy_resource_recovery',
      'cert_version',
      'cert_material_health_level',
      'cert_material_reuse_level',
      'cert_carbon_level',
      'cert_water_level',
      'cert_socials_level',
      'family',
      'gtins',
      'use_stage',
      'operational_energy_use',
      'carbon_operational_water_use',
      'resource_recovery_stage',
      'external_passport_id',
      'synchronization_data',
      'count_versions'
    ];
  }
  syncColors() {
    if (!this.id) {
      this.colors = [];
      return;
    }
    this.colorsService
      .get<Colors>({ passport_id: this.id })
      .subscribe((colors: Colors[]) => {
        this.colors = colors;
        this.onSyncColors$.emit(this.colors);
      });
  }
  syncUsedEnergy() {
    if (!this.id) {
      this.used_energy = [];
      return;
    }
    this.usedEnergyService
      .get<UsedEnergy>({ passport_id: this.id })
      .subscribe((usedEnergy: UsedEnergy[]) => {
        this.used_energy = usedEnergy;
        this.onSyncUsedEnergy$.emit(this.used_energy);
      });
  }
  syncManufacturers() {
    if (!this.id) {
      this.manufacturers = [];
      return;
    }
    this.manufacturersService
      .get<Manufacturers>({ passport_id: this.id })
      .subscribe((manufacturers: Manufacturers[]) => {
        this.manufacturers = manufacturers;
        this.onSyncManufacturers$.emit(this.manufacturers);
      });
  }
  syncComponents() {
    if (!this.id) {
      this.setComponents([]);
      return;
    }
    this.componentService
      .get<PassportComponent>({ passport_id: this.id })
      .subscribe((components: PassportComponent[]) => this.setComponents(components));
  }
  syncDistributionArea() {
    if (!this.id) {
      this.distribution_areas = [];
      return;
    }
    this.passportService.view(this.id, { expand: 'distribution_areas.country' })
      .subscribe((passport: Passport) => {
        this.distribution_areas = passport.distribution_areas;
        this.onSyncDistributionAreas$.emit(this.distribution_areas);
      });
  }

  setComponents(components: PassportComponent[]): void {
    this.syncMaterials()
      .subscribe((materials: Material[]) => {
        let defaultComponent = this.buildDefaultComponent();
        if (components) {
          components.push(defaultComponent);
        }

        defaultComponent.materials.push(...materials);
        this.components = components;
        this.onSyncComponents$.emit(this.components);
      });
  }

  /**
   * Used to get materials NOT linked to components
   */
  syncMaterials(): Observable<Material[]> {
    if (!this.id) {
      return Observable.of([]);
    }
    return this.materialService
      .get<Material>({ passport_id: this.id })
      .map((materials: Material[]) => materials.filter((mat: Material) => !mat.component_id));
  }
  isCatalogueProduct(): boolean {
    return this.name_type === PASSPORT_NAME_TYPE.CATALOGUE;
  }
  isCustomProduct(): boolean {
    return this.name_type === PASSPORT_NAME_TYPE.CUSTOM;
  }
  buildDefaultComponent() {
    return new PassportComponent({
      name: this.translateService.instant('(misc)'),
      isDefault: true,
      percentage_weight: this.percentage_default,
      demountable: this.demountability_default
    });
  }
  getProductName(useEnglishName = false) {
    const getField = (field): string => field ? ' - ' + field : '';
    // tslint:disable-next-line:max-line-length
    const productName = (fields): string => `${useEnglishName ? this.english_name : this.name}${fields.map(field => getField(field)).join('')}`;

    if (this.isCatalogueProduct()) {
      return productName([this.manufacturer, this.trade_name, this.product_refrence]);
    }

    if (this.isCustomProduct()) {
      return productName([this.typology, this.format, this.additional]);
    }
    return this.name;
  }

  constructor(data?: any) {
    super(data);
    if (!this.name) {
      this.name = '';
    }

    data = data || {};
    if (this.family === PassportFamily.FAMILY_VOID) {
      const regex = '\\s*(' + Object.keys(PASSPORT_UNITS_MAP).join('|') + ')$';
      this.oldName = this.name;
      this.name = this.name.replace(new RegExp(regex), '');
      this.oldEnglishName = this.english_name;
      this.english_name = this.name;
      if (this.composite_name) {
        this.oldCompositeName = this.composite_name;
        this.composite_name = this.name;
      }
    }
    this.image = (data.image);
    this.components = (data.components || data.passport_components || []).map((d: any) => new PassportComponent(d));
    this.nomenclature = (data.nomenclature || []).map((d: any) => new NomenclatureItem(d));
    this.materials = (data.materials || data.passport_materials || []).map((mat: any, index: number) => {
      mat.nomenclature = this.nomenclature.find(nomen => nomen.id === mat.nomenclature_id);
      return new Material(mat);
    });
    this.next_usages = (data.next_usages || []).map((usage: any) => new NextUsage(usage));
    this.manufacturers = (data.manufacturers || []).map((manufacturer: any) => new Manufacturers(manufacturer));
    this.keywords = (data.keywords || []).map((d: any) => new PassportKeywordJunction(d));
    this.category_id = data.category ? data.category.id : PASSPORT_DEFAULT_CATEGORY;
    this.name_type = data.name_type ? Number(data.name_type) : PASSPORT_DEFAULT_NAME_TYPE;
    const getRadioValue = (val: number) => typeof val === 'number' ? val : PASSPORT_DEFAULT_RADIO_KEY;
    //this.c2c_certified = getRadioValue(data.c2c_certified);
    this.c2c_certified = data.c2c_certified ? data.c2c_certified : DEFAULT_RADIO_VALUE.NO;
    //getRadioValue(data.c2c_certified);
    this.water_stewardship_certified = getRadioValue(data.water_stewardship_certified);
    this.social_fairness_certified = getRadioValue(data.social_fairness_certified);
    this.rating = new PassportRating(data.rating);
    this.energies = (data.energies || []).map((energy: any) => new UsedEnergy(energy));
    this.category = data.category;
    this.gtins = (data.gtins || []).map(gtin => new PassportGtin(gtin));
    this.synchronization_data = new PassportSynchronizationData(data.passport_synchronization_data);
    this.addSynchronizationData(data.passport_synchronization_data);
  }

  beforeSave(data: any): any {
    let dataValues = super.beforeSave(data);
    if (dataValues.certification_expiration) {
      try {
        dataValues.certification_expiration = new DatePipe(dataValues.certification_expiration)
          .transform(dataValues.certification_expiration, 'yyyy-MM-dd');
      } catch (e) {
      }
    }
    if (dataValues.family === PassportFamily.FAMILY_VOID) {
      dataValues.name = this.oldName;
      dataValues.english_name = this.oldEnglishName;
      dataValues.composite_name = this.oldCompositeName;
    }
    return dataValues;
  }
  isPublished() {
    return this.status == PASSPORT_STATUS.PUBLISHED;
  }
  hasProductPassportId() {
    return [PASSPORT_STATUS.PUBLISHED, PASSPORT_STATUS.FROZEN].includes(this.status) || !!this.product_passport_id;
  }
  isStatus(status: any) {
    return this.status == status;
  }
  isIndependent() {
    return this.type == PASSPORT_TYPES.MANUFACTURER;
  }
  get isVoid() {
    return this.family === PassportFamily.FAMILY_VOID;
  }

  get isGtinVisible() {
    return [PassportFamily.FAMILY_LOCAL, PassportFamily.FAMILY_MANUFACTURER].includes(this.family);
  }

  get isRatingVisible() {
    return [PassportFamily.FAMILY_MANUFACTURER].includes(this.family);
  }

  getPassportFamilyBaseOnModule(role: typeof ModuleNames[keyof typeof ModuleNames]) {
    if (role === 'manufacturer') {
      return PassportFamily.FAMILY_MANUFACTURER;
    } else {
      return PassportFamily.FAMILY_LOCAL;
    }
  }

  /**
   * Function to inject passport synchronization data into the passport object
   * @param {Object} passportSynchronizationData - The passport synchronization data to put into the passport
   */
  addSynchronizationData(passportSynchronizationData) {
    if (!passportSynchronizationData) {
      return;
    }

    this.language = passportSynchronizationData.language;
    this.source = passportSynchronizationData.source;
    this.otherSource = passportSynchronizationData.other_source;
    this.operator = passportSynchronizationData.operator;
    this.otherOperator = passportSynchronizationData.other_operator;
    this.documentId = passportSynchronizationData.document_id;
    this.synchronized = passportSynchronizationData.synchronized;
    this.epdType = passportSynchronizationData.epd_type;
  }

  /**
   * Aggregate the synchronization data to send to the back
   * @returns {object}
   */
  public aggregateSynchronizationData() {
    return {
      passport_id: this.id,
      language: this.language,
      source: this.source,
      other_source: this.otherSource,
      operator: this.operator,
      other_operator: this.otherOperator,
      document_id: this.documentId,
      epd_type: this.epdType
    }
  }

  /**
   * Get the name of the epd type of the passport
   * @returns {string}
   */
  public getEpdTypeString(): string {
    return Passport.EPD_TYPE_DESCRIPTIONS[this.epdType];
  }

  /**
   * Get the name of the source of the passport
   * @returns {string}
   */
  public getSourceString(): string {
    return Passport.SOURCE_DESCRIPTIONS[this.source];
  }

  /**
   * Get the name of the operator of the passport
   * @returns {string}
   */
  public getOperatorString(): string {
    return Passport.OPERATOR_DESCRIPTIONS[this.operator];
  }

  public haveCarbonData() {
    return this.carbon_product !== null || this.carbon_construction !== null || this.use_stage !== null ||
      this.operational_energy_use !== null ||
      this.carbon_operational_water_use !== null || this.carbon_end !== null ||
      this.resource_recovery_stage !== null || this.carbon_total !== null;
  }

  /**
   * Get the Gtins and format them to be saved
   * @returns {Array<Object>}
   */
  public getGtinsForSave() {
    return this.gtins.map(gtin => {
      return {
        id: gtin.id,
        code: gtin.code
      }
    })
  }
}
