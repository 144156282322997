/**
 * Created by aleksandr on 8.08.17.
 */
import * as tslib_1 from "tslib";
import { EntityResolver } from '../../../shared/resolvers/entity.resolver';
import { TransformService } from './transform.service';
var TransformationResolver = /** @class */ (function (_super) {
    tslib_1.__extends(TransformationResolver, _super);
    function TransformationResolver(_service) {
        var _this = _super.call(this) || this;
        _this._service = _service;
        _this.extraFields = [
            'conditions',
            'tags',
            'materials',
            'locations',
            'files',
            'nomenclature',
            'sectors',
            'formats',
            'quantities',
            'qualities',
            'packagings',
            'categories',
            'transformation_categories'
        ];
        _this.query = {
            expand: _this.extraFields.join(',')
        };
        return _this;
    }
    return TransformationResolver;
}(EntityResolver));
export { TransformationResolver };
