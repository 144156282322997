<section class="ecosystem-dashboard__header ecosystem-dashboard__section
  ecosystem-dashboard__section--two-column ecosystem-catalog">
  <section class="ecosystem-dashboard__section--counters">
    <ul class="ecosystem-dashboard__counter-list" >
      <li class="ecosystem-dashboard__counter-item" *ngFor="let item of data">
        <div class="ecosystem-dashboard__counter-value primary-color">
          {{item.counter}}
          <ng-container *ngTemplateOutlet="item.template"></ng-container>
        </div>
        <span class="ecosystem-dashboard__counter-title">{{item.text}}</span>
      </li>
    </ul>
  </section>
</section>
