/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-with-header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material";
import * as i3 from "../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i4 from "../../read-only/form-elems-hidden.directive";
import * as i5 from "../../read-only/read-only.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./confirm-with-header.component";
var styles_ConfirmWithHeaderComponent = [i0.styles];
var RenderType_ConfirmWithHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ConfirmWithHeaderComponent, data: {} });
export { RenderType_ConfirmWithHeaderComponent as RenderType_ConfirmWithHeaderComponent };
export function View_ConfirmWithHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "confirm-with-header-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [["class", "confirm-with-header-dialog__header background-primary-color"], ["md-dialog-title", ""]], [[2, "mat-dialog-title", null]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MdPrefixRejector, [[2, i2.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(3, 16384, null, 0, i2.MdDialogTitle, [], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(6, 0, null, null, 18, "ul", [["class", "confirm-with-header-dialog__button-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "li", [["class", "confirm-with-header-dialog__button confirm-with-header-dialog__button--confirm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "button", [["md-button", ""], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i1.ɵdid(9, 16384, null, 0, i2.MdPrefixRejector, [[2, i2.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(10, 180224, null, 0, i2.MdButton, [i1.ElementRef, i1.Renderer, i2.FocusOriginMonitor], { disabled: [0, "disabled"] }, null), i1.ɵdid(11, 16384, null, 0, i2.MdButtonCssMatStyler, [], null, null), i1.ɵdid(12, 16384, null, 0, i2.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(13, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i1.ɵted(14, 0, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 8, "li", [["class", "confirm-with-header-dialog__button "]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 7, "button", [["md-button", ""], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MdButton_0, i3.RenderType_MdButton)), i1.ɵdid(18, 16384, null, 0, i2.MdPrefixRejector, [[2, i2.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(19, 180224, null, 0, i2.MdButton, [i1.ElementRef, i1.Renderer, i2.FocusOriginMonitor], null, null), i1.ɵdid(20, 16384, null, 0, i2.MdButtonCssMatStyler, [], null, null), i1.ɵdid(21, 16384, null, 0, i2.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(22, 16384, null, 0, i4.FormElemsHiddenDirective, [[2, i5.ReadOnlyService]], null, null), (_l()(), i1.ɵted(23, 0, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.disabled; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10).disabled; var currVal_3 = true; var currVal_4 = true; var currVal_5 = ((i1.ɵnov(_v, 13).readOnlyService == null) ? null : i1.ɵnov(_v, 13).readOnlyService.readOnly); _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = (_co.confirmButtonText || i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("Confirm"))); _ck(_v, 14, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 19).disabled; var currVal_9 = true; var currVal_10 = true; var currVal_11 = ((i1.ɵnov(_v, 22).readOnlyService == null) ? null : i1.ɵnov(_v, 22).readOnlyService.readOnly); _ck(_v, 17, 0, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = (_co.cancelButtonText || i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("Cancel"))); _ck(_v, 23, 0, currVal_12); }); }
export function View_ConfirmWithHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirm-with-header", [], null, null, null, View_ConfirmWithHeaderComponent_0, RenderType_ConfirmWithHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i7.ConfirmWithHeaderComponent, [], null, null)], null, null); }
var ConfirmWithHeaderComponentNgFactory = i1.ɵccf("confirm-with-header", i7.ConfirmWithHeaderComponent, View_ConfirmWithHeaderComponent_Host_0, { confirmButtonText: "confirmButtonText", cancelButtonText: "cancelButtonText", title: "title", disabled: "disabled" }, { onConfirm: "onConfirm", onCancel: "onCancel" }, ["*"]);
export { ConfirmWithHeaderComponentNgFactory as ConfirmWithHeaderComponentNgFactory };
