<section class="confirmation-password">
  <div class="confirmation-password__block">
    <div class="confirmation-password__image">
      <img md-card-image src="../../assets/png/login_logo.png">
    </div>
    <div class="confirmation-password__body" [formGroup]="passwordForm">
      <div *ngIf="passwordForm">
      <div class="confirmation-password__send">
        <md-input-container>
          <input
            mdInput
            type="password"
            autocomplete="off" 
            (input)="markPasswordFieldsTouched('password')"
            name="password"
            placeholder="{{ 'Enter password' | translate }}"
            autofocus
            formControlName="password"
          >
          <md-hint
            *ngIf="passwordForm.get('password').hasError('minlength')"
            class="error-hint error-hint__small"
            align="start">
            {{'Minimum length of password is 8 characters' | translate}}
          </md-hint>
        </md-input-container>
        <md-input-container>
          <input
            mdInput
            autocomplete="off" 
            name="passwordRepeat"
            type="password"
            placeholder="{{ 'Repeat password' | translate}}"
            formControlName="passwordRepeat"
          >
          <md-hint
            *ngIf="passwordForm.get('password').valid && passwordForm.hasError('passwordMismatch')"
            class="confirmation-password__confirm-error error-hint__small"
            align="start">
              {{'Passwords do not match' | translate}}
          </md-hint>
        </md-input-container>
      </div>
      <div class="confirmation-password__button-group">
        <md-card-actions>
          <button
            [disabled]="!passwordForm.valid"
            md-raised-button
            color="primary"
            (click)="submit()">
          {{ 'Submit'  | translate}}
          </button>
        </md-card-actions>
      </div>
      </div>
    </div>
  </div>
</section>
