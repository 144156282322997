<md-dialog-content>
    <dialog-close-button color="white" (click)="closeDialog()"></dialog-close-button>
    <div class="deposit-mass-action">
        <div class="deposit-mass-action__header">{{(isMassUpdate ? 'MASS_UPDATE' : 'DEPOSIT_QUICK_UPDATE_BUTTON') |
            translate}}</div>
        <div class="deposit-mass-action__content">
            <div class="deposit-mass-action__textWrapper">
                <div class="deposit-mass-action__textWrapper-first">{{(isMassUpdate ? 'MASS_UPDATE_TITLE' :
                    'QUICK_UPDATE_TITLE') | translate}}</div>
                <div class="deposit-mass-action__textWrapper-second">{{(isMassUpdate ? 'MASS_UPDATE_SUBTITLE' :
                    'QUICK_UPDATE_SUBTITLE') | translate}}</div>
            </div>
            <div class="deposit-mass-action__depositsList">
                <div class="deposit-mass-action__deposit-header">
                    <div class="deposit-mass-action__deposit-header-picture with-border-bottom"></div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom"></div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom"
                        [formGroup]="massUpdateForm">
                        <div class="deposit-mass-action__deposit-header-title">{{ "Quantity" | translate }}</div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-input-container>
                                <input mdInput type="number" min="0" formControlName="quantity" class="form-control">
                                <div *ngIf="quantity.invalid && (quantity.dirty || quantity.touched)"
                                    class="input-error">

                                    <div *ngIf="quantity.errors?.min">
                                        {{ "MASS_UPDATE_VALUE_POSITIVE" | translate}}
                                    </div>
                                </div>
                            </md-input-container>

                            <i [ngClass]="isValuePositive('quantity') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow"
                                (click)="applyPositiveValue('quantity')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom">
                        <div class="deposit-mass-action__deposit-header-title">{{ "Circular passport" | translate }}
                        </div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-input-container (click)="openAdvancedResearch(isMassUpdate === true)">
                                <input readonly mdInput [(ngModel)]="form.passport.name">
                            </md-input-container>
                            <i [ngClass]="canApplyArrow('passport') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow" (click)="applyToAllColumn('passport')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom">
                        <div class="deposit-mass-action__deposit-header-title">{{ "Availability date" | translate }}
                        </div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-input-container>
                                <input ng2-datetime-picker date-only="true" date-format="DD-MM-YYYY"
                                    parse-format="YYYY-MM-DD" mdInput autocomplete="off"
                                    [(ngModel)]="form.availability_date" class="calendar--icon" />
                                <div class="input-error" *ngIf="checkMassUpdateDateIsUnvalid()">
                                    <div>
                                        {{ "MASS_UPDATE_DATE_ERROR" | translate}}
                                    </div>
                                </div>
                            </md-input-container>
                            <i [ngClass]="canApplyArrow('availability_date') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow"
                                (click)="applyToAllColumn('availability_date')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom">
                        <div class="deposit-mass-action__deposit-header-title">{{ "Expiration date" | translate }}</div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-input-container>
                                <input ng2-datetime-picker date-only="true" date-format="DD-MM-YYYY"
                                    parse-format="YYYY-MM-DD" mdInput autocomplete="off"
                                    [(ngModel)]="form.availability_expiration" class="calendar--icon" />
                                <div class="input-error" *ngIf="checkMassUpdateDateIsUnvalid()">
                                    <div>
                                        {{ "MASS_UPDATE_DATE_ERROR" | translate}}
                                    </div>
                                </div>
                            </md-input-container>
                            <i [ngClass]="canApplyArrow('availability_expiration') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow"
                                (click)="applyToAllColumn('availability_expiration')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom">
                        <div class="deposit-mass-action__deposit-header-title">{{ "Reuse potential" | translate }}</div>
                        <div class="deposit-mass-action__deposit-header-input deposit-mass-action__deposit-header-checkbox"
                            *ngIf="isMassUpdate">

                            <md-checkbox [(ngModel)]="form.potential_reuse"></md-checkbox>
                            <i [ngClass]="canApplyArrow('potential_reuse') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow"
                                (click)="applyToAllColumn('potential_reuse')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom">
                        <div class="deposit-mass-action__deposit-header-title">{{ "Demountability" | translate }}</div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-select class="select-group deposit-mass-action__select"
                                [(ngModel)]="form.deposit_demountability">
                                <md-option *ngFor="let dem of demountabilityStates" [value]="dem.value">{{dem.view |
                                    capitalize | translate}}
                                </md-option>
                            </md-select>
                            <i [ngClass]="canApplyArrow('deposit_demountability') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow"
                                (click)="applyToAllColumn('deposit_demountability')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom">
                        <div class="deposit-mass-action__deposit-header-title">{{ "State of use" | translate }}</div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-select class="select-group deposit-mass-action__select" [(ngModel)]="form.condition">
                                <md-option *ngFor="let d of depositConditions" [value]="d.value">{{d.view | capitalize |
                                    translate}}
                                </md-option>
                            </md-select>
                            <i [ngClass]="canApplyArrow('condition') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow" (click)="applyToAllColumn('condition')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom">
                        <div class="deposit-mass-action__deposit-header-title">{{ "DEPOSIT_ORIGIN_FIELD" | translate }}
                        </div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-select class="select-group deposit-mass-action__select"
                                [(ngModel)]="form.deposit_source">
                                <md-option *ngFor="let d of depositReuse" [value]="d.value">{{d.view | capitalize |
                                    translate}}
                                </md-option>
                            </md-select>
                            <i [ngClass]="canApplyArrow('deposit_source') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow"
                                (click)="applyToAllColumn('deposit_source')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom"
                        [formGroup]="massUpdateForm">
                        <div class="deposit-mass-action__deposit-header-title">{{ "Conversion" | translate }}</div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-input-container>
                                <input mdInput [(ngModel)]="form.conversion" formControlName="conversion"
                                    class="form-control" type="number" min="0">
                                <div *ngIf="conversion.invalid && (conversion.dirty || conversion.touched)"
                                    class="input-error">

                                    <div *ngIf="conversion.errors?.min">
                                        {{ "MASS_UPDATE_VALUE_POSITIVE" | translate}}
                                    </div>
                                </div>
                            </md-input-container>
                            <i [ngClass]="isValueStrictlyPositive('conversion') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow"
                                (click)="applyStrictlyPositiveValue('conversion')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom"
                        [formGroup]="massUpdateForm">
                        <div class="deposit-mass-action__deposit-header-title">{{"Price" | translate}}</div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-input-container class="deposit-mass-action__deposit-unit-input">
                                <input mdInput [(ngModel)]="form.price" formControlName="price" class="form-control"
                                    type="number" min="0">
                                <div *ngIf="price.invalid && (price.dirty || price.touched)" class="input-error">

                                    <div *ngIf="price.errors?.min">
                                        {{ "MASS_UPDATE_VALUE_POSITIVE" | translate}}
                                    </div>
                                </div>
                            </md-input-container>
                            <div>€</div>
                            <i [ngClass]="isValuePositive('price') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow"
                                (click)="applyPositiveValue('price')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom">
                        <div class="deposit-mass-action__deposit-header-title">{{"Site" | translate}}</div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-select class="select-group deposit-mass-action__select"
                                [(ngModel)]="form.deposit_location">
                                <md-option *ngFor="let d of depositLocation" [value]="d">{{d.name}}
                                </md-option>
                            </md-select>
                            <i [ngClass]="canApplyArrow('deposit_location') ? 'arrow-down-apply--icon' : 'arrow-down-apply-disabled--icon'"
                                class="icon deposit-mass-action_down-arrow"
                                (click)="applyToAllColumn('deposit_location')"></i>
                        </div>
                    </div>
                    <div class="deposit-mass-action__deposit-header-item with-border-bottom">
                        <div class="deposit-mass-action__deposit-header-title">Tags</div>
                        <div class="deposit-mass-action__deposit-header-input" *ngIf="isMassUpdate">
                            <md-select [disabled]="!overrideForm.deposit_location"
                                class="select-group deposit-mass-action__select" [(ngModel)]="form.tags">
                                <md-option *ngFor="let d of overrideForm.displayedTags" [value]="d">{{d.name}}
                                </md-option>
                            </md-select>
                            <i *ngIf="form.tags.id" class="icon button-add--icon deposit-mass-action_down-arrow"
                                (click)="addTags(form.tags)"></i>
                            <i *ngIf="form.tags.id" class="icon button-minus--icon deposit-mass-action_down-arrow"
                                (click)="removeTags(form.tags)"></i>
                        </div>
                    </div>
                </div>
                <div class="deposit-mass-action_depositContent">
                    <div class="deposit-mass-action__deposit-results" *ngIf="isLoaded">
                        <div *ngFor="let record of transformedRecords | paginate:{id:'depositsSelection'
                        , itemsPerPage: filter.perPage
                        , currentPage: filter.currentPage
                        , totalItems: filter.totalCount }; let i = index;" class="deposit-mass-action_depositRow">
                            <div class="deposit-mass-action__deposit-header-picture "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <img [src]="record.thumbnail ? record.thumbnail : record.passport.thumbnail"
                                        class="passport_image">
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container>
                                        <input [readonly]="isMassUpdate" mdInput [ngModel]="record.name"
                                            (ngModelChange)="updateRecord($event,record,'name',i)" #deposit_name_input>
                                    </md-input-container>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container class="deposit-mass-action__deposit-unit-input"
                                        *ngIf="!isMassUpdate" [formGroup]="getFormGroup(i)">
                                        <input [readonly]="isMassUpdate" mdInput min="0" type="number"
                                            formControlName="quantity" class="form-control">

                                        <div *ngIf="getFormControl('quantity', i).invalid && (getFormControl('quantity', i).dirty || getFormControl('quantity', i).touched)"
                                            class="input-error">

                                            <div *ngIf="getFormControl('quantity', i).errors?.min">
                                                {{ "MASS_UPDATE_VALUE_POSITIVE" | translate}}
                                            </div>

                                            <div *ngIf="getFormControl('quantity', i).errors?.quantityBooked">
                                               {{ "MASS_UPDATE_QUANTITY_BOOKED_ERROR" | translate}}
                                            </div>
                                        </div>

                                    </md-input-container>
                                    <md-input-container *ngIf="isMassUpdate && !(overrideForm.quantity || overrideForm.quantity === 0)"
                                        class="deposit-mass-action__deposit-unit-input">
                                        <input readonly mdInput [(ngModel)]="record.quantity">
                                    </md-input-container>
                                    <md-input-container *ngIf="isMassUpdate && (overrideForm.quantity || overrideForm.quantity === 0)"
                                        class="deposit-mass-action__deposit-unit-input">
                                        <input readonly mdInput [(ngModel)]="overrideForm.quantity">
                                    </md-input-container>


                                    <div class="deposit-mass-action__deposit-unit">{{getUnit(record) | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container
                                        (click)="openAdvancedResearch(isMassUpdate === false, record, i)">
                                        <input [readonly]="isMassUpdate" *ngIf="!overrideForm.passport" mdInput disabled
                                            [(ngModel)]="record.passport.name">
                                        <input readonly *ngIf="overrideForm.passport" mdInput disabled
                                            [(ngModel)]="overrideForm.passport.name">
                                    </md-input-container>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container>
                                        <input *ngIf="!overrideForm.availability_date" [readonly]="isMassUpdate"
                                            ng2-datetime-picker date-only="true" date-format="DD-MM-YYYY"
                                            parse-format="YYYY-MM-DD" mdInput autocomplete="off"
                                            [(ngModel)]="record.availability_date"
                                            (ngModelChange)="updateRecord($event,record,'availability_date',i)"
                                            class="calendar--icon" />
                                        <div class="input-error" *ngIf="checkQuickUpdateDateIsUnvalid(record)">
                                            <div>
                                                {{ "MASS_UPDATE_DATE_ERROR" | translate}}
                                            </div>
                                        </div>
                                        <input readonly *ngIf="overrideForm.availability_date" mdInput
                                            [(ngModel)]="overrideForm.availability_date">
                                    </md-input-container>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container>
                                        <input *ngIf="!overrideForm.availability_expiration" [readonly]="isMassUpdate"
                                            ng2-datetime-picker date-only="true" date-format="DD-MM-YYYY"
                                            parse-format="YYYY-MM-DD" mdInput autocomplete="off"
                                            [(ngModel)]="record.availability_expiration"
                                            (ngModelChange)="updateRecord($event,record,'availability_expiration',i)"
                                            class="calendar--icon" />
                                        <div class="input-error" *ngIf="checkQuickUpdateDateIsUnvalid(record)">
                                            <div>
                                                {{ "MASS_UPDATE_DATE_ERROR" | translate}}
                                            </div>
                                        </div>
                                        <input readonly *ngIf="overrideForm.availability_expiration" mdInput
                                            [(ngModel)]="overrideForm.availability_expiration">
                                    </md-input-container>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div
                                    class="deposit-mass-action__deposit-header-input deposit-mass-action__deposit-header-checkbox">
                                    <md-checkbox [disabled]="isMassUpdate" *ngIf="!overrideForm.potential_reuse"
                                        [(ngModel)]="record.potential_reuse"
                                        (ngModelChange)="updateRecord($event,record,'potential_reuse',i)"></md-checkbox>
                                    <md-checkbox disabled *ngIf="overrideForm.potential_reuse" mdInput
                                        [(ngModel)]="overrideForm.potential_reuse"></md-checkbox>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container *ngIf="overrideForm.deposit_demountability">
                                        <input readonly mdInput
                                            (ngModelChange)="overrideForm.deposit_demountability = $event"
                                            [ngModel]="displayDemountability(overrideForm.deposit_demountability)">
                                    </md-input-container>
                                    <md-select [disabled]="isMassUpdate" *ngIf="!overrideForm.deposit_demountability"
                                        class="select-group deposit-mass-action__select"
                                        [(ngModel)]="record.deposit_demountability"
                                        (ngModelChange)="updateRecord($event,record,'deposit_demountability',i)">
                                        <md-option *ngFor="let dem of demountabilityStates" [value]="dem.value">
                                            {{dem.view | capitalize | translate}}
                                        </md-option>
                                    </md-select>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container *ngIf="overrideForm.condition">
                                        <input readonly mdInput (ngModelChange)="ovverideForm.condition = $event"
                                            [ngModel]="displayDepositCondition(overrideForm.condition)">
                                    </md-input-container>
                                    <md-select [disabled]="isMassUpdate" *ngIf="!overrideForm.condition"
                                        class="select-group deposit-mass-action__select" [(ngModel)]="record.condition"
                                        (ngModelChange)="updateRecord($event,record,'condition',i)">
                                        <md-option *ngFor="let d of depositConditions" [value]="d.value">{{d.view |
                                            capitalize | translate}}
                                        </md-option>
                                    </md-select>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container *ngIf="overrideForm.deposit_source !== null && overrideForm.deposit_source !== undefined">
                                        <input readonly mdInput (ngModelChange)="overrideForm.deposit_source = $event"
                                            [ngModel]="displayDepositSource(overrideForm.deposit_source)">
                                    </md-input-container>
                                    <md-select [disabled]="isMassUpdate" *ngIf="overrideForm.deposit_source === null || overrideForm.deposit_source === undefined"
                                        class="select-group deposit-mass-action__select"
                                        [(ngModel)]="record.deposit_source"
                                        (ngModelChange)="updateRecord($event,record,'deposit_source',i)">
                                        <md-option *ngFor="let d of depositReuse" [value]="d.value">{{d.view |
                                            capitalize | translate}}
                                        </md-option>
                                    </md-select>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container [formGroup]="getFormGroup(i)"
                                        *ngIf="!isMassUpdate">
                                        <input [readonly]="isMassUpdate" mdInput min="0" type="number"
                                            formControlName="conversion" class="form-control">

                                        <div *ngIf="getFormControl('conversion', i).invalid && (getFormControl('conversion', i).dirty || getFormControl('conversion', i).touched)"
                                            class="input-error">

                                            <div *ngIf="getFormControl('conversion', i).errors?.min">
                                                {{ "MASS_UPDATE_VALUE_POSITIVE" | translate}}
                                            </div>
                                            <div *ngIf="getFormControl('conversion', i).errors?.voidPassport">
                                                {{ "MASS_UPDATE_VOID_PASSPORT" | translate }}
                                            </div>
                                        </div>
                                    </md-input-container>
                                    <md-input-container *ngIf="isMassUpdate && !overrideForm.conversion">
                                        <input readonly mdInput [(ngModel)]="record.conversion">
                                    </md-input-container>
                                    <md-input-container *ngIf="isMassUpdate && overrideForm.conversion">
                                        <input readonly mdInput [(ngModel)]="overrideForm.conversion">
                                    </md-input-container>

                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container class="deposit-mass-action__deposit-unit-input"
                                        [formGroup]="getFormGroup(i)" *ngIf="!isMassUpdate">
                                        <input [readonly]="isMassUpdate" mdInput min="0" type="number"
                                            formControlName="price" class="form-control">

                                        <div *ngIf="getFormControl('price', i).invalid && (getFormControl('price', i).dirty || getFormControl('price', i).touched)"
                                            class="input-error">

                                            <div *ngIf="getFormControl('price', i).errors?.min">
                                                {{ "MASS_UPDATE_VALUE_POSITIVE" | translate}}
                                            </div>
                                        </div>
                                    </md-input-container>
                                    <md-input-container *ngIf="isMassUpdate && !(overrideForm.price || overrideForm.price === 0)"
                                        class="deposit-mass-action__deposit-unit-input">
                                        <input readonly mdInput [(ngModel)]="record.price">
                                    </md-input-container>
                                    <md-input-container *ngIf="isMassUpdate && (overrideForm.price || overrideForm.price === 0)">
                                        <input readonly mdInput [(ngModel)]="overrideForm.price"
                                            class="deposit-mass-action__deposit-unit-input">
                                    </md-input-container>

                                    <div>€</div>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-header-input">
                                    <md-input-container *ngIf="overrideForm.deposit_location">
                                        <input readonly mdInput [(ngModel)]="overrideForm.deposit_location.name">
                                    </md-input-container>
                                    <md-select class="select-group deposit-mass-action__select"
                                        [disabled]="isMassUpdate" *ngIf="!overrideForm.deposit_location"
                                        [(ngModel)]="record.deposit_location"
                                        (ngModelChange)="updateRecord($event,record,'deposit_location',i)">
                                        <md-option *ngFor="let d of depositLocation" [value]="d">{{d.name}}
                                        </md-option>
                                    </md-select>
                                </div>
                            </div>
                            <div class="deposit-mass-action__deposit-header-item center-input "
                                [ngClass]="{'white-bg': i % 2, 'grey-bg': !(i % 2) }">
                                <div class="deposit-mass-action__deposit-tags">

                                    <md-chip-list class="chip-list-container">
                                        <md-basic-chip class="deposit-mass-action__tags background-primary-color"
                                            *ngFor="let tag of computeTags(record)" (focus)="false"
                                            [mdTooltip]="tag.name">
                                            {{getTagName(tag.name) }}
                                        </md-basic-chip>
                                    </md-chip-list>
                                    <md-select *ngIf="!isMassUpdate"
                                        class="select-group deposit-mass-action__select-tags"
                                        [(ngModel)]="record.tmp_tag" (ngModelChange)="onTagsSelected($event,record)">
                                        <md-option *ngFor="let d of record.deposit_location.tags" [value]="d">{{d.name}}
                                        </md-option>
                                    </md-select>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>

            </div>
            <div class="depositlist__container-pagination" test-id="deposit-list-pagination">
                <pagination-controls class="depositlist__pagination" id="depositsSelection"
                    (pageChange)="pageChange($event)" maxSize="8" directionLinks="true" autoHide="true" previousLabel=""
                    nextLabel="" screenReaderPaginationLabel="{{ 'Pagination' | translate }}"
                    screenReaderPageLabel="{{ 'page' | translate }}"
                    screenReaderCurrentLabel="{{ 'You\'re on page' | translate }}"></pagination-controls>
            </div>
        </div>
        <div class="deposit-mass-action__footer">
            <button class="deposit-mass-action__button" md-raised-button [disabled]="!canConfirm()"
                (click)="openConfirmationWarning()">{{ 'MASS_ACTION_SAVE' |
                translate }}</button>
            <button class="deposit-mass-action__button gray" [disabled]="!hasChanges()" md-raised-button
                (click)="reset()">{{ 'MASS_ACTION_RESET' | translate }}</button>
        </div>
    </div>
</md-dialog-content>