/**
 * Created by Simo C. on 25.10.19.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericPassportListComponent } from './partials/generic-passport-list/generic-passport-list.component';
import { SharedModule } from '../../shared/shared.module';
import { GenericPassportService } from './service/generic-passport.service';
import { GenericPassportItemComponent } from './partials/generic-passport-list/generic-passport-item/generic-passport-item.component';
import { GenericPassportEdit } from './partials/generic-passport-edit/generic-passport-edit.component';
import { GenericPassportResolver } from './resolvers/generic-passport.resolver';
import { GenericPassportFormComponent } from './partials/generic-passport-form/generic-passport-form.component';
import { GenericPassportMaterialService } from './service/generic-passport-material.service';
import { GenericPassportTagJunctionService } from './service/generic-passport-tag-junction.service';
import { GenericPassportTagService } from './service/generic-passport-tag.service';
import { LandfillVariablesComponent } from '../../dashboards/superadmin/components/landfill-variables/landfill-variables.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
  ],
  declarations: [
    GenericPassportListComponent,
    LandfillVariablesComponent,
    GenericPassportItemComponent,
    GenericPassportEdit,
    GenericPassportFormComponent
  ],
  exports: [
    GenericPassportListComponent,
    LandfillVariablesComponent,
    GenericPassportItemComponent,
    GenericPassportEdit,
    GenericPassportFormComponent
  ],
  providers: [
    GenericPassportService,
    GenericPassportResolver,
    GenericPassportMaterialService,
    GenericPassportTagJunctionService,
    GenericPassportTagService,
  ],
  entryComponents: [
    // CreateClientDialogComponent,
  ]
})
export class PassportGenericModule {
}
