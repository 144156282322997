/// <reference types="@types/googlemaps" />
declare let google: any;
import { MdDialogRef } from '@angular/material';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { DepositLocationService } from '../../services/deposit-location.service';
import { DepositLocation } from '../../models/deposit-location.class';
import { MapCoords } from '../../models/map-coords.class';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';

@Component({
  moduleId: module.id,
  selector: 'view-map-dialog',
  templateUrl: 'view-map.component.html',
  styleUrls: ['view-map.component.scss'],
})
export class MapDialogComponent implements OnInit {
  depositLocations: DepositLocation[];

  selectionAddress = '';
  selectionCoords: MapCoords;
  isSelectionInfoWindowOpen: boolean = true;

  currentCoords: MapCoords;
  currentCoordsPromise: Promise<MapCoords>;

  mapZoom = 13;
  mapCoords: MapCoords = {lat:0, lng:0};

  readonlyMode: boolean = false;

  @ViewChild('placesAutocompleteSearch') public searchElementRef: ElementRef;

  private geocoder: any;

  private initData = this.dialog._containerInstance.dialogConfig.data;

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private dialog: MdDialogRef<any>,
              private locationService: DepositLocationService,
              public notification: LogNotificationService) {

    this.readonlyMode = this.initData.readonlyMode;
  }

  ngOnInit() {
    this.initCurrentCoords();
    this.initSelection();
    this.initMap();

    this.initPlaceAutocomplete();
    this.initGeocoder();

    this.initDeposits();
  }

  initDeposits() {
    this.locationService.get().subscribe((depositLocations: DepositLocation[]) => {
      this.depositLocations = depositLocations
        .filter(depositLocation => depositLocation.lat && depositLocation.lng);
    });
  }

  initSelection() {
    if (this.initData.coords.lat && this.initData.coords.lng) {
      this.selectionCoords = Object.assign({}, this.initData.coords);
    } else {
      this.currentCoordsPromise.then((coords) => {
        this.selectionCoords = Object.assign({}, coords);
        if (!this.selectionAddress) {
          this.updateSelectionAddress();
        }
      });
    }

    if (this.initData.address) {
      this.selectionAddress = this.initData.address;
    }
  }

  initCurrentCoords() {
    this.currentCoordsPromise = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((currentPosition) => {
          let coords = currentPosition.coords;

          this.currentCoords = {
            lat: coords.latitude,
            lng: coords.longitude,
          };

          if(this.readonlyMode) {
            this.depositLocations = [];
          }

          resolve(Object.assign({}, this.currentCoords));
        },
        (error) => {
          console.error(error);
          this.notification.error(error.message, null, true);
          reject(error);
        },
        {
          enableHighAccuracy: true
        }
      );
    });
  }

  initMap() {
    if (this.initData.coords.lat && this.initData.coords.lng) {
      this.mapCoords = Object.assign({}, this.initData.coords);
    } else {
      this.currentCoordsPromise.then((coords) => {
        this.mapCoords = Object.assign({}, coords);
      });
    }
  }

  initPlaceAutocomplete() {
    this.mapsAPILoader.load().then(() => {
        if(!this.readonlyMode) {
          let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ['address']
          });

          autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
              if(!place.geometry) {
                return;
              }

              this.selectionCoords = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
              };
              this.mapCoords = Object.assign({}, this.selectionCoords);
            });
          });
        }
      },
      console.error
    );
  }

  initGeocoder() {
    this.mapsAPILoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder;
      },
      console.error
    );
  }

  updateSelectionAddress() {
    if (!this.geocoder) {
      return;
    }

    if (this.selectionCoords && this.selectionCoords.lat && this.selectionCoords.lng) {
      this.geocoder.geocode({'location': this.selectionCoords}, (results: any[], status: string) => {
        if (status === 'OK') {
          let result = results[0];
          if (result) {
            this.selectionAddress = result.formatted_address;
          }
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
    }
  }

  getAddressFromSelectionMarker() {
    this.updateSelectionAddress();
    this.isSelectionInfoWindowOpen = false;
  }

  mapClick($event: any) {
    if(this.readonlyMode) {
      return;
    }

    this.selectionCoords = $event.coords;
    this.isSelectionInfoWindowOpen = false;
  }

  selectionMarkerDragEnd($event: any) {
    if(this.readonlyMode) {
      return;
    }

    this.selectionCoords = $event.coords;
    this.isSelectionInfoWindowOpen = false;
  }

  select(depositLocation?: DepositLocation) {
    if(this.readonlyMode) {
      return;
    }

    if (depositLocation) {
      this.dialog.close({depositLocation});
    } else {
      this.dialog.close({
        coords: this.selectionCoords,
        address: this.selectionAddress,
      });
    }
  }

}
