/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./jump-page-icon.component";
var styles_JumpPageIconComponent = [];
var RenderType_JumpPageIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JumpPageIconComponent, data: {} });
export { RenderType_JumpPageIconComponent as RenderType_JumpPageIconComponent };
export function View_JumpPageIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, ":svg:svg", [["fill", "none"], ["height", "4"], ["viewBox", "0 0 18 4"], ["width", "18"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3Z"], ["stroke", "#001A72"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z"], ["stroke", "#001A72"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:path", [["d", "M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z"], ["stroke", "#001A72"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null))], null, null); }
export function View_JumpPageIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-jump-page-icon", [], null, null, null, View_JumpPageIconComponent_0, RenderType_JumpPageIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.JumpPageIconComponent, [], null, null)], null, null); }
var JumpPageIconComponentNgFactory = i0.ɵccf("app-jump-page-icon", i1.JumpPageIconComponent, View_JumpPageIconComponent_Host_0, {}, {}, []);
export { JumpPageIconComponentNgFactory as JumpPageIconComponentNgFactory };
