/**
 * Created by aleksandr on 21.06.17.
 */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { EcosystemStep } from '../../../models/ecosystem-step.class';
import { NeedService } from '../../../../../entities/need/services/need.service';
import { Need } from '../../../../../entities/need/models/need.class';
import { PassportFileService } from '../../../../../entities/passport/services/passport-file.service';
import { PassportFile } from '../../../../../entities/passport/models/passport-file.class';
import { EcosystemOfferService } from '../../../../../entities/ecosystem-offer/ecosystem-offer.service';
import { EcosystemOffer } from '../../../../../entities/ecosystem-offer/ecosystem-offer.model';
import { ECOSYSTEM_OFFER_STATUS } from '../../../values/ecosystem-offer-status.const';
import { MdDialog } from '@angular/material';
import { EcosystemOfferDialogComponent } from '../../../dialogs/ecosystem-offer-dialog/ecosystem-offer-dialog.component';
import { ECOSYSTEM_STATUS } from '../../../values/ecosystem-status.const';
import { ChatRoom } from '../../../../../shared/chat/models/chat-room.model';
import { ChatService } from '../../../../../shared/chat/chat.service';
import { EThreadType } from '../../../../../shared/chat/interfaces/chat-message.interface';
import { ViewPassportDialogComponent } from '../../../../../entities/passport/dialogs/view-passport-dialog/view-passport-dialog.component';
import { PassportKeywordService } from '../../../../../entities/passport/services/keyword.service';
import { CurrentUser } from '../../../../../shared/auth/current-user.class';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { EcosystemEditComponent } from '../ecosystem-edit/ecosystem-edit.component';
import { setTextareaHeightToFitContent } from '../../../../../shared/helpers/set-textarea-height-to-fit-content';
import { EcosystemFileSelectorDialogComponent } from '../../../../../dashboards/ecosystem-manager/dialogs/ecosystem-file-selector/ecosystem-file-selector.component';
import { EcosystemFileJunction } from '../../../../../dashboards/ecosystem-manager/models/ecosystem-file-junction.class';
import { EcosystemFile } from '../../../../../dashboards/ecosystem-manager/models/ecosystem-file.class';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../../../../dashboards/ecosystem-manager/values/ecosystem-file-junction-item-type.const';
import { EcosystemFileJunctionService } from '../../../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import { Ecosystem } from '../../../../../dashboards/ecosystem-manager/models/ecosystem.class';
import {TranslateService} from '@ngx-translate/core';
import { EcosystemFileDialogComponent } from '../../../../../dashboards/ecosystem-manager/dialogs/ecosystem-file/ecosystem-file.component';
import {EcosystemPrefillService, PREFILL_OFFER_ID} from '../../../services/ecosystem-prefill.service';
import {EcosystemCompilerSharedActionsService} from '../../../services/ecosystem-compiler-shared-actions.service';
import { EDatePrecision } from '../../../../../entities/need/values/date-precision.const';
import * as moment from 'moment';
import { PASSPORT_UNITS_MAP, PASSPORT_UNITS_TYPE } from '../../../../../entities/passport/values/passport-units.const';
@Component({
  moduleId: module.id,
  selector: 'esm-compiler-need',
  templateUrl: 'esm-compiler-need.component.html'
})
export class EsmCompilerNeedComponent implements OnDestroy, OnInit {
  @Input() step: EcosystemStep;
  @Output() stepChange = new EventEmitter;
  @Input() openChat?: boolean;
  @Input() quantity: any;
  @Input() ecosystem: Ecosystem;
  @Output() action = new EventEmitter();
  @ViewChild('offerDeliveryAddress', { read: ElementRef }) offerDeliveryAddressTextarea: ElementRef;
  @ViewChild('offerPassportDescription', { read: ElementRef }) offerPassportDescriptionTextarea: ElementRef;
  @ViewChild('needSummaryAddress', { read: ElementRef }) needSummaryAddressTextarea: ElementRef;
  @ViewChild('needSummaryDescription', { read: ElementRef }) needDescriptionTextarea: ElementRef;
  tabStatus = {
    CLOSED: 0,
    OPENED: 1
  }
  tabType = {
    OFFER: 1,
    SUMMARY: 2,
    CHAT: 3
  }
  public steps: EcosystemStep[];
  public room: ChatRoom;
  public need: Need = new Need;
  public offer: EcosystemOffer = new EcosystemOffer;
  public ECOSYSTEM_OFFER_STATUS = ECOSYSTEM_OFFER_STATUS;
  public ECOSYSTEM_STATUS = ECOSYSTEM_STATUS;
  PASSPORT_UNIT_MAP = PASSPORT_UNITS_MAP;
  showCommunication : boolean;
  files: PassportFile[] = [];
  quantityPrecision: any;
  datePrecision: any;
  EDatePrecision: EDatePrecision;
  arrSection = [this.tabStatus.CLOSED, this.tabStatus.CLOSED, this.tabStatus.CLOSED];
  hasNeedAccess : boolean;
  fileUploadeUrl: any;
  isVisible: boolean;
  connections: any;
  prefillUpdateObserver;

  constructor(
              private _chatService: ChatService,
              private ecoFileJunctionService: EcosystemFileJunctionService,
              private ecosystemEditComponent: EcosystemEditComponent,
              public translate: TranslateService,
              public needService: NeedService,
              public passportFileService: PassportFileService,
              public offerService: EcosystemOfferService,
              public passportKeywordService: PassportKeywordService,
              public dialog: MdDialog,
              public filterState: NavbarStateService,
              private prefillService: EcosystemPrefillService,
              private ecosystemCompilerSharedActionsService: EcosystemCompilerSharedActionsService
              ) {
  }


  ngOnInit() {
    this.hasNeedAccess = CurrentUser.infoData.has_transform_acces;
    if(this.openChat && !this.arrSection[this.tabType.CHAT]) {
      this.changeTab(this.tabType.CHAT);
    }

    this.load();
    this.steps = this.step.ecosystem.steps;
    this.findConnectionsToStep(this.step);
    if(this.connections.target.length === 0 && this.connections.source.length === 0){
      this.isVisible = false;
    } else if (this.connections.target.length > 0 || this.connections.source.length > 0) {
      this.isVisible = true;
    }

    this.prefillUpdateObserver =
      this.ecosystemCompilerSharedActionsService.onPrefillFinished.subscribe(() => {
          if (this.prefillService.getPrefilledValue(PREFILL_OFFER_ID)) {
            this.offer.quantity = this.prefillService.getPrefilledValue(PREFILL_OFFER_ID).in;
          }
        });
  }

  load() {
    this.needService
      .view(this.step.entity_id, {
        expand: 'passport,nomenclature,files,client,sales_user,sales_user.company,client_location,client_contact,offers,sales_contact,sales_avatar',
      })
      .subscribe((need: Need) => {
        //console.log(need);
        this.need = need;

        this.showCommunication = need.isOwnedByCurrentcompany() || (need.isPublic() && !need.isOwnedByCurrentcompany() && this.hasNeedAccess ) || !need.isPublic()
        //this.order.loaded = true;
        this.quantityPrecision = need.quantity_precision_text;
        this.datePrecision = need.date_precision_text;

        if(this.need.passport) {
          this.need.passport.syncComponents();
        }
      }, () => {
        this.step.ecosystem.isNeedDeleted().subscribe((needDeleted:boolean) => {
          if (needDeleted) {
            this.step.ecosystem.reload();
          }
        })
      });
    if (this.step.ecosystem.id) {
      this.loadOffer();
    }
  }
  isReadOnly(): boolean {
    return ![
      ECOSYSTEM_OFFER_STATUS.DRAFT,
      ECOSYSTEM_OFFER_STATUS.WAITING_FOR_CONFIRMATION,
      ECOSYSTEM_OFFER_STATUS.ASK_FOR_MODIFICATION
    ].includes(this.offer.status);
  }

  private loadOffer() {
    this.offerService.get({
      supervisor: true,
      ecosystem_id: this.step.ecosystem.id || null,
      expand: 'ecosystem_file_junction, default_quantity'
    }).subscribe((offers: EcosystemOffer[]) => {
      if (offers && offers.length > 0) {
        this.offer = offers.shift();  // Offer Exist
        this._chatService
          .getContextThreads(this.offer.id, EThreadType.TYPE_ECOSYSTEM_OFFER)
          .subscribe((rooms: ChatRoom[]) => this.setChatroom(rooms[0]));
      } else {
        this.offer = new EcosystemOffer({ecosystem_id: this.step.ecosystem.id}); //New offer
        this.offer.save().subscribe(res => this.offer.id = res.id);
      }
      this.offer.ecosystem = this.step.ecosystem;
      if (!this.offer.quantity) {
        this.offer.quantity = this.prefillService.getOrderInPrefill(this.offer);
      }
    });
  }

  ngOnDestroy() {
    this.prefillUpdateObserver.unsubscribe();
    /*if (this.need.loaded) {
     this.need.save().subscribe();
     this.need.passport.save().subscribe();
     }*/
  }
  getStepById(id: number): EcosystemStep {
    return this.steps.find((step: EcosystemStep) =>
      step.id === id
    );
  }

  getStepBySelectorId(selectorId: string): EcosystemStep {
    return this.getStepById(EcosystemStep.fromSelectorId(selectorId));
  }

  findConnectionsToStep(step: { endpoints: any[]; id: number; }) {
    const connections = { source: [], target: []};
    step.endpoints.forEach(endpoint => {

      endpoint.connections.forEach(connection => {

        const target = this.getStepBySelectorId(connection.targetId);
        const source = this.getStepBySelectorId(connection.sourceId);


        if(target.id !== step.id) {
          connections.target.push(target);
        }
        if(source.id !== step.id) {
          connections.source.push(source);
        }
      });
    })
  this.connections = connections;
  return connections
  }

  expandOfferTextAreas() {
    setTimeout(() => setTextareaHeightToFitContent([ this.offerPassportDescriptionTextarea
      , this.offerDeliveryAddressTextarea
    ]));
  }

  expandNeedSummaryTextAreas() {
    setTimeout(() => setTextareaHeightToFitContent([ this.needDescriptionTextarea, this.needSummaryAddressTextarea ]));
  }

  viewDetails(step: EcosystemStep) {
    this.ecosystemEditComponent.viewDetails(step.type, step.entity_id);
  }

  private updateOffer(callback?: Function) {
    this.offer.save().subscribe(() => {
      this.ecosystemCompilerSharedActionsService.ecosystem.ecosystem_offer = this.offer;
      this.action.emit();
      if (callback) {
        callback();
      }
    });
  }

  onPriceChange(amount: number) {
    this.offer.price = amount;
    this.updateOffer();
  }

  onOfferDescriptionChange(description: string) {
    this.offer.description = description;
    this.updateOffer();
  }

  updateQuantity(amount: any) {
    this.offer.quantity = amount;
    this.updateOffer();
  }

  sameUnitButDifferentConversion() {
    return this.ecosystemCompilerSharedActionsService.sameUnitButDifferentConversion(this.offer, null, this.need.passport);
  }

  onDeliveryDateChange(date: any) {
    this.offer.delivery_date = date;
    this.updateOffer(() => {
      this.step.load({ecosystem_id: this.step.ecosystem_id}).subscribe(
        (step) => {
          this.step = Object.assign(this.step, step);
          this.stepChange.emit(this.step);
        });
    });
  }

  changeTab(index: number) {
    if(this.arrSection[index] === this.tabStatus.OPENED){
      this.arrSection[index] = this.tabStatus.CLOSED;
    } else {
      this.arrSection[index] = this.tabStatus.OPENED;
    }
  }

  isOpen(index: number) {
    return this.arrSection[index] === this.tabStatus.OPENED;
  }

  askForValidation() {
    if (!this.offer.id) {
      this.offerService
        .create(this.offer)
        .subscribe((offer: EcosystemOffer) => {
          this.offer = offer;

          this._askForValidation();
        });
    } else {
      this._askForValidation();
    }
  }

  protected _askForValidation() {
    this.offer.publish().subscribe((res) => {
      if (res.status) {
        this.offer.status = res.status;
      }
    });
  }

  editSendOffer() {
    this
      .dialog
      .open(EcosystemOfferDialogComponent, {
        position: {
          top: '123px',
        },
        height: '84%',
        data: {
          ecosystem: this.step.ecosystem,
        }
      })
      .afterClosed()
      .subscribe(() => {
        this.step.ecosystem.reload();
      })
    ;
  }

  //TODO remove unused dialog ?
  displayPassport() {
    this
      .dialog
      .open(ViewPassportDialogComponent, {
        position: {
          top: '123px',
        },
        data: {
          passport: this.need.passport
        }
      })
      .afterClosed()
      .subscribe()
    ;
  }

  sendEmail() {
    let email = this.need.client_contact && this.need.client_contact.email ? this.need.client_contact.email : '';

    window.location.href = 'mailto:' + email;
  }

  setChatroom(room: ChatRoom) {
    room.loadContacts().subscribe(() => {
      this.room = room;
    });
  }

  getAvatar() {
    if (this.need.sales_avatar) {
      return {'background-image': 'url(' + this.need.sales_avatar + ')'};
    }
    return null;
  }
  isPublicNeed(): boolean {
    return this.need.isPublic() ;
  }
  canAccessChat() {

  }
  saveAndCloseDialog() {
    this.offer.save().subscribe(() => this.action.emit());
  }

  openFiles() {
    let dialogRef = this.dialog.open(EcosystemFileDialogComponent, {
      width: '800px',
      height: '600px',
      data: {
        ecosystem: this.step.ecosystem,
        stepId: this.step.id
      }
    });
    dialogRef.afterClosed().subscribe((file: EcosystemFile) => {
      if (file) {
        this.addFileToOffer(file);
      }
    });
  }
  removeJunctionFromOffer(junction: EcosystemFileJunction) {
    this.ecoFileJunctionService
      .remove(junction.id)
      .subscribe(() => {
        this.offer.ecosystem_file_junction
          .splice(this.offer.ecosystem_file_junction.indexOf(junction), 1);
      });
  }

  addFileToOffer(file: EcosystemFile) {
    console.log("ADDING FILE ", file);
    let fileJunction = new EcosystemFileJunction({
      file_id: file.id,
      item_id: this.offer.id,
      item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_OFFER
    });
    this.ecoFileJunctionService
      .create(fileJunction)
      .subscribe((createdFileJunction: EcosystemFileJunction) => {
        if(createdFileJunction) {
          this.offer.ecosystem_file_junction.push(createdFileJunction);
        }
      });
  }

  getPrecisionText(){
    if(this.need.quantity_precision_text === 'precise'){
      return this.translate.instant('precisely');
    } else if(this.need.quantity > 0 && this.need.quantity_precision_text){
    return this.translate.instant(this.need.quantity_precision_text);
    }
     return;
  }

  getDatePrecisionText(){
    if(this.need.date_precision_text === ''){
      return
    }
    else if (this.need.date_precision === EDatePrecision.PRECISELY){
      return this.translate.instant('precisely');
    }
    else if(this.need.date_precision === EDatePrecision.UNKNOWN){
      return this.translate.instant('to be defined');
    } else if(this.need.date_precision === EDatePrecision.RECURRENT){
      return this.translate.instant('to be defined');
    } else if(this.need.date_precision_text) {
      return this.translate.instant(this.need.date_precision_text);
    }
  }

  getRequiredDate(){
    if(this.need.delivery_date){
      return moment(this.need.delivery_date).format('DD-MM-YYYY')
    } else {
      return this.translate.instant('to be defined');
    }
  }

  getPrecisionTextStyles(unit: number){
    const languages = ['es'];
    const checkableUnits = [PASSPORT_UNITS_TYPE.KG, PASSPORT_UNITS_TYPE.TONNE];
    if(checkableUnits.includes(unit)){
      return "ecosystem-compiler-offer-section-needed-quantity-text--tons-or-kg-unit"
    } else if (languages.includes(this.translate.currentLang) && checkableUnits.includes(unit)){
      return "ecosystem-compiler-offer-section-needed-quantity-text--remove-padding"
    }
  }
  checkIsEcosystemStepStatusIsNotDraft(){
    if(this.step.ecosystem.status !== ECOSYSTEM_STATUS.DRAFT){
      return true;
     }
    }

  updateOfferWeight(data: {value: number, isActive: boolean}) {
    this.offer.setOrResetWeight(data.value, data.isActive);
    if (!data.isActive) {
      this.offer.weight = data.value;
      this.updateOffer();
    }
  }
}
