/**
 * Created by aleksandr on 7.08.17.
 */
import { ChatUser } from './chat-user.model';
import { ChatRoom } from './chat-room.model';
import { IChatUser, IChatUserGroup } from '../interfaces/chat-user.interface';
import { IChatRoom } from '../interfaces/chat-room.interface';
export class ChatData {
  public static contacts: ChatUser[] = [];
  public static activeRoom: ChatRoom;
  public static rooms: ChatRoom[] = [];
  public static threads: ChatRoom[] = [];
  public static groups: IChatUserGroup[] = [];
  public static ownerId: number;
  public static ownerAvatar: string;

  public static selectedContact: IChatUser;
  public static selectedChatroom: IChatRoom;

}
