/**
 * Created by aleksandr on 30.03.17.
 */
import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { SUPERVISOR_NAVIGATION } from './supervisor.navigation';
import { ERoleIds } from '../../shared/values/role-ids.enum';

export const SUPERVISOR_CONTEXT: IRoleContextConfig = {
  navigation: SUPERVISOR_NAVIGATION,
  moduleName: 'supervisor',
  themeClass: 'b-module-supervisor',
  roleId: ERoleIds.SUPERVISOR,
  roleText: 'Supervisor',
  apiModuleName: 'supervisor',
  homeUrl: '/supervisor',
  rootPath: 'supervisor'
};

