import {Component, Input, NgZone, OnInit} from '@angular/core';
import {Deposit} from '../../../../entities/deposit/models/deposit.class';
import {Need} from '../../../../entities/need/models/need.class';
import {EDepositItemType} from '../../../../entities/deposit/values/deposit-item-type.enum';
import {MdDialog} from '@angular/material';
import {NeedDetailsDialogComponent} from '../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {EDatePrecision} from '../../../../entities/need/values/date-precision.const';
import {Ecosystem} from '../../models/ecosystem.class';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {EcosystemService} from '../../../../entities/ecosystem/services/ecosystem.service';
import {EConfirmDialogIcon} from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import {NeedEsmMatchDepositsFilter} from '../../../../entities/need/models/need-esm-match-deposits-filter';
import {NeedService} from '../../../../entities/need/services/need.service';
import {ENeedMatchDepositsType} from '../../../../entities/need/values/need-match-deposits-type.enum';
import {PaginationUnmarshallingService} from '../../../../shared/services/pagination-unmarshalling.service';
import {Subscription} from 'rxjs/Subscription';
import {DepositService} from '../../../../entities/deposit/services/deposit.service';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';

@Component({
  selector: 'esm-need-match',
  templateUrl: './esm-need-match.component.html',
  styleUrls: ['./esm-need-match.component.scss']
})
export class EsmNeedMatchComponent implements OnInit {
  CUSTOM_ICON = EConfirmDialogIcon;
  DEPOSIT_ITEM_TYPE = EDepositItemType;
  NEED_DATE_PRECISION = EDatePrecision;
  NEED_MATCH_DEPOSITS_TYPE_FROM_DEPOSIT = ENeedMatchDepositsType.FROM_DEPOSIT;
  NEED_MATCH_DEPOSITS_TYPE_PASSPORT = ENeedMatchDepositsType.PASSPORT;
  @Input() needFilter = new NeedEsmMatchDepositsFilter();
  needTabIsOpen = {};
  private isLoadingEcosystem = false;
  private subscription: Subscription;
  constructor(private mdDialog: MdDialog
              , private router: Router
              , private zone: NgZone
              , private translate: TranslateService
              , private ecosystemService: EcosystemService
              , private needService: NeedService
              , private depositService: DepositService
              , private paginationService: PaginationUnmarshallingService
              , private notificationService: LogNotificationService
  ) { }

  get needs() {
    return this.needFilter.records;
  }

  ngOnInit() {
    if (this.needFilter.loaded) {
      this.refreshNeedTabs([]);
    } else {
      this.loadRecords();
    }
  }

   loadRecords(page = 1) {
    this.needFilter.currentPage = page;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const previousNeeds = this.needFilter.records;
    this.subscription = this.needService.getWithPaginationData(this.needFilter.buildOptions())
      .subscribe((response) => {
        this.needFilter.updateRecords(response, this.paginationService.unmarshall(response));
        this.refreshNeedTabs(previousNeeds);
      }
    );
  }

  private refreshNeedTabs(previousNeeds: Need[]) {
    let skipOther = false;
    const isOpen = {};
    this.needFilter.records.forEach((need, i) => {
      if (!skipOther) {
        skipOther = !previousNeeds[i]
          || previousNeeds[i].id !== need.id
          || previousNeeds[i].match_deposits.type !== need.match_deposits.type;
      }
      isOpen[need.id] = skipOther ? false : this.needTabIsOpen[need.id];
    });
    this.needTabIsOpen = isOpen;
  }

  openNeedDetailsDialog(id: number) {
    this.mdDialog.open(NeedDetailsDialogComponent, {
      ...DIALOG_THEME.WIDE_PRIMARY,
      data: {
        id: id,
        readOnly: true,
        showValidationButtons: false
      }
    });
  }

  createEcosystemFromNeed(need: Need, deposit: Deposit) {
    if (!this.isLoadingEcosystem) {
      this.isLoadingEcosystem = true;
      this.ecosystemService.publishWithDeposit(
        {name: `${this.translate.instant('For')} ${need.name}`,
        need_id: need.id,
        deposit_id: deposit.id}).subscribe((ecosystem: Ecosystem) => {
          this.zone.run(() => this.router.navigate(['ecosystem/ecosystems', ecosystem.id]));
          this.isLoadingEcosystem = false;
        }, () => this.isLoadingEcosystem = false );
    }
  }

  sendConsultDepositLinkNotificationToDO(deposit: Deposit, need: Need) {
    this.needService.sendConsultDepositLinkNotificationToSales(need.id, deposit.id)
      .subscribe(() => this.notificationService.success('Send'));
  }
}
