export function chunkAnArray<T>(array: T[], size: number, firstChunkSize?: number): T[][] {
  const chunkedArray: T[][] = [];
  const copied = [...array];
  let numOfChild;
  if(firstChunkSize) {
    chunkedArray.push(copied.splice(0, firstChunkSize));
    numOfChild = Math.ceil((copied.length / firstChunkSize) / size);
  } else {
    numOfChild = Math.ceil(copied.length / size);
  }
  for (let i = 0; i < numOfChild; i++) {
    chunkedArray.push(copied.splice(0, size));
  }
  return chunkedArray;
}
