import {EventEmitter, Injectable} from '@angular/core';
import {DeepStreamService} from '../../shared/services/deepstream.service';
import {IDeepstreamImport} from '../../shared/values/deepstream-import.interface';
import {IImportDeepstream} from '../values/interfaces/import-deepstream.interface';
import {ISpinnerAbstractRecordContainer} from '../../shared/spinner/spinner-completion.interface';
import {Subscription} from 'rxjs/Subscription';
import {ELastImportType} from '../values/enums/last-import-type.enum';
import {Observable} from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class ImportDeepstreamService implements IImportDeepstream {

  private type: ELastImportType = ELastImportType.DEPOSIT;
  private commons;
  private imports = {
    [ELastImportType.DEPOSIT]: this.ds.depositImportDeepStream$,
    [ELastImportType.SITES]: this.ds.depositLocationDeepStream$
  };
  public emitTypeChange = new EventEmitter();

  constructor(
    public ds: DeepStreamService,
  ) { }

  getStream(): IDeepstreamImport {
    return this.imports[this.type];
  }

  getAllImportStream() {
    return this.commons ? this.commons :  this.initAllImportStream();
  }

  private initAllImportStream() {
    const combineImportsAndReturnType = (type: keyof IDeepstreamImport) => Observable.merge(
        this.imports[ELastImportType.DEPOSIT][type].asObservable()
          .map(value => ({value, type: ELastImportType.DEPOSIT, stream: type})),
        this.imports[ELastImportType.SITES][type].asObservable()
          .map((value: ISpinnerAbstractRecordContainer) => {
            return {value, type: ELastImportType.SITES, stream: type};
          })
      );
    const common = {
      depositImportParsedRowsStream$: combineImportsAndReturnType('depositImportErrorsStream$'),
      depositCompletionStream$: combineImportsAndReturnType('depositCompletionStream$'),
      depositImportErrorsStream$: combineImportsAndReturnType('depositImportParsedRowsStream$'),
      depositImportFatalError$: combineImportsAndReturnType('depositImportFatalError$')
    };
    console.log(common);
    return common;
  }

  public setType(type: ELastImportType) {
    if (this.type !== type) {
      this.type = type;
      this.emitTypeChange.emit(type);
    }
  }
  public getType() {
    return this.type;
  }
}
