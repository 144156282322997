import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CheckAuthHook} from "../shared/auth/check-auth.guard";
import {GuestGuard} from "../shared/auth/guest.guard";
import {LOGIN_ROUTES} from "./login.routes";
// import { LoginRegisterComponent } from './login-register/login-register.component';
// import { ResetPasswordComponenet } from './reset-password/reset-password.component';

// RouterModule.forChild([{
//   path: 'login',
//   canActivate: [CheckAuthHook, GuestGuard],
//   children: LOGIN_ROUTES
// }]),
const routes: Routes = [
  // { path: '', component: LoginRegisterComponent }, // <-------- Without this,
  //                                                  // the module will load with a blank screen
  //                                                  // as there's no route to handle the request.
  // { path: 'reset-password/:token', component: ResetPasswordComponenet },
  {
    path: 'login',
    canActivate: [CheckAuthHook, GuestGuard],
    children: LOGIN_ROUTES
  }
];
// canActivate: [CheckAuthHook, GuestGuard],
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LoginRoutingModule { }
