import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { Keyword } from '../models/keyword.class';
var PassportKeywordService = /** @class */ (function (_super) {
    tslib_1.__extends(PassportKeywordService, _super);
    function PassportKeywordService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'passport-keywords';
        _this.namespaceSingular = 'passport-keyword';
        _this.ModelClass = Keyword;
        return _this;
    }
    return PassportKeywordService;
}(CrudService));
export { PassportKeywordService };
