/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateClientDialogComponent } from './dialogs/create-client/create-client.component';
import { CreateLocationDialogComponent } from './dialogs/create-location/create-location.component';
import { CreateContactDialogComponent } from './dialogs/create-contact/create-contact.component';
import { ViewContactDialogComponent } from './dialogs/view-contact/view-contact.component';

import { ContactService } from '../client/service/contact.service';
import { ProductPassportStepComponent } from './partials/need-edit/product-passport-step/product-passport-step.component';
import { PassportService } from '../passport/services/passport.service';
import { NeedService } from './services/need.service';
import { ClientService } from '../client/service/client.service';
import { LocationService } from '../client/service/location.service';
import { NeedEditComponent } from './partials/need-edit/need-edit.component';
import { OtherStepComponent } from './partials/need-edit/other-step/other-step.component';
import { NameClientStepComponent } from './partials/need-edit/name-client-step/name-client-step.component';
import { ClientModule } from '../client/client.module';
import { NeedListComponent } from './partials/need-list/need-list.component';
import { NeedSitesComponent } from './partials/need-directory/need-sites/need-sites.component';
import { NeedRecipientsComponent } from './partials/need-directory/need-recipients/need-recipients.component';
import { NewSiteDialogComponent } from './partials/need-directory/need-sites/PopupDialog/new-site-dialog.component';
import { NewRecipientsDialogComponent } from './partials/need-directory/need-recipients/PopupDialog/new-recipients-dialog.component';
import {NeedCatalogComponent} from './partials/need-catalog/need-catalog.component';
import { NeedItemComponent } from './partials/need-list/need-item/need-item.component';
import { NeedFilterComponent } from './partials/need-list/need-filter/need-filter.component';
import { NeedCategoryFilter } from './pipes/need-category-filter.pipe';
import { CreatePassportDialogComponent } from './dialogs/create-passport/create-passport.component';
import { ViewPassportDialogComponent } from '../passport/dialogs/view-passport-dialog/view-passport-dialog.component';
import { PassportModule } from '../passport/passport.module';
import { NeedViewService } from './services/need-view.service';
import { NeedFileService } from './services/need-file.service';
import { NeedListFilter } from './pipes/need-list-filter.pipe';
import { NeedSort } from './pipes/need-sort.pipe';

import { SharedModule } from '../../shared/shared.module';
import { NeedSalesListService } from './services/need-sales-list.service';
import { NeedClientListService } from './services/need-client-list.service';
import { NeedDetailsDialogComponent } from './dialogs/need-details-dialog/need-details-dialog.component';
import { NeedDetailsComponent } from './partials/need-details/need-details.component';
import {NeedDirectoryComponent} from './partials/need-directory/need-directory.component';
import { NeedResolver } from './resolvers/need.resolver';
import {NeedListResolver} from './resolvers/needs-list.resolver';
import { NeedDepositListComponent } from './partials/need-deposit-list/need-deposit-list.component';
import { FilterStateOfUseComponent } from '../deposit/partials/deposit-list/filter-state-of-use/filter-state-of-use.component';
import { NguiInfiniteListModule } from '@ngui/infinite-list';
import { DepositModule } from '../deposit/deposit.module';
import {NeedMatchWithDepositResolve} from './resolvers/need-match-with-deposit.resolve';
import { LocalStorageService } from '../../shared/services/localStorage.service';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ClientModule,
    PassportModule,
    NguiInfiniteListModule,
    DepositModule
  ],
  declarations: [
    NeedListComponent,
    NeedSitesComponent,
    NeedRecipientsComponent,
    NewSiteDialogComponent,
    NewRecipientsDialogComponent,
    NeedCatalogComponent,
    NeedDepositListComponent,
    NeedItemComponent,
    NeedFilterComponent,
    NeedEditComponent,
    NameClientStepComponent,
    OtherStepComponent,
    ProductPassportStepComponent,
    CreateClientDialogComponent,
    CreateContactDialogComponent,
    CreateLocationDialogComponent,
    ViewContactDialogComponent,
    CreatePassportDialogComponent,
    NeedCategoryFilter,
    NeedListFilter,
    NeedSort,
    NeedDetailsDialogComponent,
    NeedDetailsComponent,
    NeedDirectoryComponent
  ],
  exports: [
    NeedEditComponent,
    NeedListComponent,
    NeedSitesComponent,
    NeedRecipientsComponent,
    NewSiteDialogComponent,
    NewRecipientsDialogComponent,
    NeedDepositListComponent,
  ],
  providers: [
    ClientService,
    LocationService,
    NeedService,
    ContactService,
    PassportService,
    NeedViewService,
    NeedFileService,
    NeedSalesListService,
    NeedClientListService,
    NeedResolver,
    NeedListResolver,
    NeedMatchWithDepositResolve,
    LocalStorageService
  ],
  entryComponents: [
    CreateClientDialogComponent,
    CreateContactDialogComponent,
    CreatePassportDialogComponent,
    ViewPassportDialogComponent,
    ViewContactDialogComponent,
    CreateLocationDialogComponent,
    NeedDetailsDialogComponent,
    NewSiteDialogComponent,
    NewRecipientsDialogComponent
  ]
})
export class NeedModule {
}
