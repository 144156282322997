import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { EcosystemProblemService } from '../services/ecosystem-problem.service';
var EcosystemProblem = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemProblem, _super);
    function EcosystemProblem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = EcosystemProblemService;
        return _this;
    }
    EcosystemProblem.prototype.fields = function () {
        return [
            'description',
            'step_id'
        ];
    };
    return EcosystemProblem;
}(ActiveRecord));
export { EcosystemProblem };
