import * as tslib_1 from "tslib";
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { RequestFileService } from '../services/request-file.service';
var RequestFile = /** @class */ (function (_super) {
    tslib_1.__extends(RequestFile, _super);
    function RequestFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = RequestFileService;
        return _this;
    }
    return RequestFile;
}(UploadedDocument));
export { RequestFile };
