/**
 * Created by aleksandr on 30.06.17.
 */

import { NgModule } from '@angular/core';
import { ChatHistoryComponent } from './components/chat-form/chat-history/chat-history.component';
import { ChatFormComponent } from './components/chat-form/chat-form.component';
import { ChatService } from './chat.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { CustomTranslateModule } from '../custom-translate/custom-translate.module';

@NgModule({
  imports: [
    CommonModule,
    CustomTranslateModule,
    FormsModule,
    FileUploadModule
  ],
  declarations: [
    ChatHistoryComponent,
    ChatFormComponent
  ],
  exports: [
    ChatHistoryComponent,
    ChatFormComponent
  ],
  providers: [
    ChatService
  ],
})
export class ChatModule {

}
