import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NavigationService } from '../../../../shared/services/navigation.service';

/**
 * The `PaginationComponent` handles the rendering of pagination controls and emits events
 * for page changes. It uses OnPush change detection strategy for performance optimization
 * by reducing the number of checks Angular makes.
 */
@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit {
    /**
     * The current page number.
     */
    @Input() currentPage: number = 1;

    /**
     * The total number of pages.
     */
    @Input() totalPages: number;

    /**
     * Event emitter for page changes. Emits the new page number.
     */
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    /**
     * User role, fetched on component initialization for conditional styling or logic.
     */
    role: string = '';

    /**
     * Injects `NavigationService` to fetch user context like role.
     * 
     * @param _navigation The navigation service for accessing application-wide navigational context.
     */
    constructor(private _navigation: NavigationService) { }

    /**
     * Fetches the user's role on component initialization from the navigation service context.
     */
    ngOnInit() {
        this.role = this._navigation.getContext().roleText;
    }

    /**
     * Navigates to a given page number if it's valid (not current, previous, or out of range).
     * 
     * @param page The page number to navigate to.
     */
    navigateToPage(page: number): void {
        if (page < 1 || page > this.totalPages || page === this.currentPage) {
            return;
        }
        this.currentPage = page;
        this.pageChange.emit(this.currentPage);
    }

    /**
     * Computes and returns an array representing the pages to be displayed by the pagination control,
     * including ellipses for skipped sections in the case of a large number of pages.
     * 
     * @returns An array of numbers or strings representing pagination buttons.
     */
    get pages(): Array<number | string> {
        // Ensure currentPage and totalPages are treated as numbers
        const currentPage = Number(this.currentPage);
        const totalPages = Number(this.totalPages);

        let pages: Array<number | string> = [];
        let start = Math.max(currentPage - 2, 1);
        let end = Math.min(currentPage + 2, totalPages);

        // Adds the first page and ellipsis if currentPage is greater than 4
        if (currentPage > 4) {
            pages.push(1);
            pages.push('...');
        }

        // Adds the current, previous, and next pages
        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        // Adds ellipsis and last page if currentPage is far from the last page
        if (currentPage < totalPages - 3) {
            pages.push('...');
            pages.push(totalPages);
        }

        return pages;
    }

    /**
     * Determines the CSS class for coloring pagination based on the user's role.
     * 
     * @returns The CSS class corresponding to the user's role.
     */
    getClassColor(): string {
        const roleClassMap = {
            'supervisor': 'role-supervisor',
            'Specialadmin': 'role-special-admin',
            'Agent': 'role-agent',
            'admin': 'role-admin',
            'Manufacturer': 'role-manufacturer'
        };
        return roleClassMap[this.role] || '';
    }
}
