import { StatsContainer } from './stats-container.class';
import { ILiteral } from 'ng2-adn-common';
import { ISalesStats } from '../interfaces/sales-stats.interface';
import { SalesStats } from './sales-stats.class';

export class SalesStatsContainer extends StatsContainer<ISalesStats> {

  extractData(data: any): any {
    let json = super.extractData(data);
    this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
    return json;
  }

  createModel(json: ILiteral, key: string[]) {
    key.forEach((k) => {
      if (!!json[k]) {
        json[k] = json[k].map((data: ILiteral) => new SalesStats(data));
      }
    });
  }

  getNeeds() {
    let result: any = {};
    let published = 0, done = 0, treated = 0, refused = 0;
    if (this.total) {
      this.total.forEach(item => {
        if (item.needs_published) {
          published += item.needs_published;
        }
        if (item.needs_done) {
          done += item.needs_done;
        }
        if (item.needs_treated) {
          treated += item.needs_treated;
        }
        if (item.needs_refused) {
          refused += item.needs_refused;
        }
      });
    }
    result.total = published + refused;
    result.published = published;
    result.published_pct = this.calculatePercentage(published,result.total);
    result.done = done;
    result.done_pct = this.calculatePercentage(done,result.total);
    result.treated = treated;
    result.treated_pct = this.calculatePercentage(treated,result.total);
    return result;
  }

  calculatePercentage(value: number, total: number): string {
    if (total === 0) {
      return '0%';
    }
    return (value / total * 100).toFixed(1) + '%';
  }
}
