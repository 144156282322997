import { NgModule } from '@angular/core';
import { TransformEditComponent } from './partials/transform-edit/transform-edit.component';
import { SharedModule } from '../../shared/shared.module';
import { ConditionsComponent } from './partials/transform-details/conditions/conditions.component';
import { AcceptedMaterialsComponent } from './partials/transform-details/accepted-materials/accepted-materials.component';
import { IncomeOutcomeComponent } from './partials/transform-details/income-outcome/income-outcome.component';
import { StoringSitesComponent } from './partials/transform-details/storing-sites/storing-sites.component';
import { ControlTypeComponent } from './partials/transform-details/control-type/control-type.component';
import { ServiceAreaComponent } from './partials/transform-details/service-area/service-area.component';
// import { TransformListComponent } from './partials/transform-list/transform-list.component';
import { OrderListComponent } from './partials/order-list/order-list.component';
import { TransformDashboardComponent } from './partials/transform-dashboard/transform-dashboard.component';
import { TsmOrdersCatalogComponent } from './partials/tsm-orders-catalog/tsm-orders-catalog.component';
import { TsmSitesCatalogComponent } from './partials/tsm-sites-catalog/tsm-sites-catalog.component';
import { TsmTransformationsCatalogComponent } from './partials/tsm-transformations-catalog/tsm-transformations-catalog.component';
import { TsmCatalogComponent } from './partials/tsm-catalog/tsm-catalog.component';
import { TsmOrderDetailsDialogComponent } from './dialogs/tsm-order-details-dialog/tsm-order-details-dialog.component';
import { TsmOrderDetailsComponent } from './partials/tsm-order-details/tsm-order-details.component';
import { TransformDetailsComponent } from './partials/transform-details/transform-details.component';
import { TransformDetailsDialogComponent } from './dialogs/transform-details-dialog/transform-details-dialog.component';
import { CreateTransformSiteDialogComponent } from './dialogs/create-transform-site-dialog/create-transform-site-dialog.component';
import { TransformSiteDetailsDialogComponent } from './dialogs/transform-site-details-dialog/transform-site-details-dialog.component';
import { TransformSiteDetailsComponent } from './partials/transform-site-details/transform-site-details.component';
import { CommonModule } from '@angular/common';
import { TransformationModule } from '../../entities/transformation/transformation.module';
import { TransformationManagerGuard } from './transformation-manager.guard';
import { RouterModule } from '@angular/router';
import { TSM_ROUTES } from './transformation-manager.routes';
import { TRANSFORM_CONTEXT } from './transformation-manager.context';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';
import { TransformStatisticsComponent } from './partials/tsm-statistics/tsm-statistics.component';
import { TsmOrdersArchiveCatalogComponent } from './partials/tsm-order-archive-catalog/tsm-order-archive-catalog.component';
import { InputOutputComponent } from './partials/transform-details/input-output/input-output.component';
import { TransformationOrderTransactionConfirmComponent
} from './partials/transformation-order-transaction-confirm/transformation-order-transaction-confirm.component';
import { TransformationOrderTransactionConfirmDialogComponent
} from './dialogs/transformation-order-transaction-confirm-dialog/transformation-order-transaction-confirm-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild([{
      path: TRANSFORM_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: TSM_ROUTES
    }]),
    TransformationModule
  ],
  declarations: [
    OrderListComponent,
    // TransformListComponent,
    TransformEditComponent,
    ConditionsComponent,
    AcceptedMaterialsComponent,
    IncomeOutcomeComponent,
    InputOutputComponent,
    StoringSitesComponent,
    ControlTypeComponent,
    ServiceAreaComponent,
    TransformDashboardComponent,
    TsmCatalogComponent,
    TsmOrdersCatalogComponent,
    TsmSitesCatalogComponent,
    TsmTransformationsCatalogComponent,
    TsmOrdersArchiveCatalogComponent,
    TsmOrderDetailsDialogComponent,
    TsmOrderDetailsComponent,
    TransformDetailsComponent,
    TransformDetailsDialogComponent,
    TransformSiteDetailsDialogComponent,
    TransformSiteDetailsComponent,
    CreateTransformSiteDialogComponent,
    TransformStatisticsComponent,
    TransformationOrderTransactionConfirmComponent,
    TransformationOrderTransactionConfirmDialogComponent,
  ],
    exports: [
        TransformDashboardComponent,
        OrderListComponent,
        TransformEditComponent,
        TransformStatisticsComponent
    ],
  providers: [TransformationManagerGuard],
  entryComponents: [
    TsmOrderDetailsDialogComponent,
    TransformDetailsDialogComponent,
    TransformSiteDetailsDialogComponent,
    CreateTransformSiteDialogComponent,
    TransformationOrderTransactionConfirmDialogComponent
  ],
})
export class TransformationManagerModule {
}
