import { Injectable } from '@angular/core';

@Injectable()

export class WeightAndVolumeConversionService {
  public readonly NUMBERS_AFTER_COMMA = 4;

// Sum will be shown in tons as 1kg to 0,0010 tons
  conversionToWeighUnits(conversion: number, volume: number){
    volume = this.transformToNumber(volume);
    conversion = this.transformToNumber(conversion);
    if (Number.isNaN(conversion) || Number.isNaN(volume)) {
      return '';
    }
    return ((volume * conversion) / 1000).toFixed(this.NUMBERS_AFTER_COMMA).replace('.', ',');
  }

  transformToNumber(field: any) {
    if (typeof field === 'string' && field.length > 0) {
      field = field.replace(',', '.');
      field = field.replace(/[,.]$/, '');
      return Number(field);
    }
    if (typeof field === 'number') {
      return field;
    }
    return Number.NaN;
  }
// Quantity in functional unity = conversion factor*weight*1000
// Weighable quantity calculated back to volume units
  conversionToVolumeUnits(conversion: number, weight: number) {
    weight = this.transformToNumber(weight);
    conversion = this.transformToNumber(conversion);
    if (conversion === 0 || Number.isNaN(conversion) || Number.isNaN(weight)) {
      return Number.NaN;
    }
    return (weight * 1000) / conversion;
  }
}
