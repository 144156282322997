import { Injectable } from '@angular/core';
import {CrudService} from '../../../shared/services/crud.service';
import {DepositLocationSalesJunction} from '../models/deposit-location-sales-junction.class';
import {Response} from '@angular/http';

@Injectable()
export class DepositLocationSalesJunctionService extends CrudService {
  protected namespace = 'sales-site-junctions';
  protected namespaceSingular = 'sales-site-junction';
  protected ModelClass = DepositLocationSalesJunction;

  updateLocationList(deleteIds: number[], insert: number[], userId: number) {
    return this.sendPost(this.getEndpoint(this.namespaceSingular) + '/update_list'
      , {delete_ids: deleteIds, insert_sites: insert}, {search: {user_id: userId}})
      .map((res: Response) =>
        res.json().map((item: DepositLocationSalesJunction) => this.buildModel<DepositLocationSalesJunction>(item)))
      .catch(this.onError.bind(this));
  }
}
