/**
 * Created by aleksandr on 14.02.17.
 */

export enum EDatePrecision {
  UNKNOWN = 0,
  PRECISELY = 1,
  FLEXIBLE = 2,
  RECURRENT = 3
}

export const DATE_PRECISION: any[] = [{
  key: EDatePrecision.RECURRENT,
  value: 'recurrent'
}, {
  key: EDatePrecision.PRECISELY,
  value: 'precise'
}, {
  key: EDatePrecision.FLEXIBLE,
  value: 'flexible'
}, {
  key: EDatePrecision.UNKNOWN,
  value: 'unknown'
}];
export const NEED_DATE_PRECISION = DATE_PRECISION;
