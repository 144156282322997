/**
 * Created by aleksandr on 21.07.17.
 */
import { ActiveRecord } from './active-record.class';
import { UserService } from '../services/user.service';
import { IUserCompany } from '../../dashboards/superadmin/interfaces/user-company.interface';
import { Observable } from 'rxjs/Observable';
import { IUser } from './user.interface';
import { USER_CONTEXTS } from '../auth/user-contexts.const';
import { USER_ROLES } from '../values/role-ids.enum';
import { IRoleContextConfig } from '../interfaces/role-context-config.interface';

interface ServerRoleWithParent {
  role: string;
  parent_id: number;
}

export interface UserRole {
  roleId: number;
  parentId: number;
}

export class User extends ActiveRecord implements IUser {

  public id: number;
  public username: string;
  public password: string;
  public first_name: string;
  public last_name: string;
  public email: string;
  public phone: string;
  public description: string;
  public status: number;
  public language: string;
  public avatar_url: string;
  public roles: string[];
  public company: IUserCompany;
  public oldPassword: string;
  public passwordConfirm: string;
  public receive_notifications: boolean;
  public receive_deposit_import_notification: boolean;
  public company_name: string;
  public SIRET: string;
  public plan_id:number;
  public formRoles:number[][];
  public has_accepted_eula: string;
  public number_of_sites: number;
  public name: string;
  protected provider = UserService;
  protected service: UserService;
  public roles_with_parent: ServerRoleWithParent[];

  private USER_ROLES = USER_ROLES;

  get roleText() {
    return this.rolesText || this.rolesWithParentText || [];
  }

  get rolesText() {
    return !!this.roles
      ? this.roles
        .map(roleName => this.getRoleContext(roleName))
        .filter(roleContext => !!roleContext)
        .map(roleContext => roleContext.roleText)
      : null;
  }

  get rolesWithParentText() {
    return !!this.roles_with_parent
      ? this.roles_with_parent
        .map(userRole => this.getRoleContext(userRole.role))
        .filter(roleContext => !!roleContext)
        .map(roleContext => roleContext.roleText)
      : null;
  }

  get viewName() {
    return this.first_name ? this.first_name + ' ' + this.last_name : this.username;
  }

  protected fields() {
    return [
      'id',
      'username',
      'password',
      'first_name',
      'last_name',
      'email',
      'phone',
      'description',
      'status',
      'company',
      'roles',
      'roles_with_parent',
      'avatar_url',
      'receive_notifications',
      'receive_deposit_import_notification',
      'formRoles',
      'company_name',
      'SIRET',
      'plan_id',
      'language',
      'has_accepted_eula',
      'number_of_sites'
    ];
  }

  beforeSave(data: any): any {
    return super.beforeSave(data);
  }

  save(): Observable<any> {
    // You have to update the password separately
    if (this.id && this.id > 0 && this.password) {
      let password = this.password;
      let oldPassword = this.oldPassword;
      this.password = null;
      this.oldPassword = null;
      return super.save().flatMap((res: any) => {
        this.password = password;
        this.oldPassword = oldPassword;
        console.log('Update password');
        return this.service.changePassword(this.id, this.password, this.oldPassword).map((data) => res);
      });
    } else {
      return super.save();
    }
  }

  getUserRoles(): UserRole[] {
    let userRoles: UserRole[] = [];
    if(!!this.roles_with_parent && this.roles_with_parent.length > 0) {
      this.roles_with_parent.forEach((serverRole: ServerRoleWithParent) => {
        let roleContext = this.getRoleContext(serverRole.role);
        if(!!roleContext) {
          userRoles.push({ roleId: roleContext.roleId, parentId: this.getRoleParentId(serverRole.role) });
        }
      });
    } else if(!!this.roles) {
      this.roles.forEach((roleName) => {
        let roleContext = this.getRoleContext(roleName);
        if(!!this.getRoleContext(roleName))
          userRoles.push({ roleId: roleContext.roleId, parentId: null });
      });
    }
    return userRoles;
  }

  getRoleParentId(roleApiName: string): number {
    return this.roles_with_parent.find((role) => role.role === roleApiName).parent_id;
  }

  getRoleContext(apiModuleName: string): IRoleContextConfig {
    return USER_CONTEXTS.find((context) => context.apiModuleName === apiModuleName);
  }

  hasTransformAccess() {
    return this.company.transformation_acces ;
  }
}
