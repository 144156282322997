import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IMapMarker } from '../../../../shared/map-view/map-marker.interface';
import { DepositNeedMapMarkersService } from '../../../../shared/services/deposit-need-map-marker.service.class';
import { DepositLocation } from '../../../deposit/models/deposit-location.class';

@Component({
  moduleId: module.id,
  selector: 'deposit-need-map',
  templateUrl: 'deposit-need-map.component.html',
})

export class DepositNeedMapComponent implements OnInit, OnDestroy {

    @Input() updateMap: EventEmitter<DepositLocation[]>;
    @Input() beforeMapUpdate: EventEmitter<void>;
    @Input() markerInfoWindowCloseOnZoom = true;
    @Output() onChangeDepositLocationIds = new EventEmitter<number[]>();
    @Output() onChangeMarkerClose = new EventEmitter<void>();

    updateMapBoundsAction = new EventEmitter();
    closeWindowAction = new EventEmitter();
    zoomAction = new EventEmitter<number>();
    markers: IMapMarker[] = [];
    mapIsReady = false;
    private centerMap = true;
    private denyCenterOnNextUpdateCycle = false;

    constructor(private markerService: DepositNeedMapMarkersService) {}

    ngOnInit(): void {
       this.beforeMapUpdate.subscribe(() => this.beforeMarkerUpdate());
       this.updateMap.subscribe((locationList: DepositLocation[]) => this.updateMarkers(locationList));
    }

    /**
     * Method to prepare the update of the map markers
     * @todo Is this really needed?
     */
    beforeMarkerUpdate() {
        this.markerService.resetMarkers();
        this.centerMap = !this.denyCenterOnNextUpdateCycle;
        this.denyCenterOnNextUpdateCycle = false;
    }

    /**
     * Update the markers on the map by placing the locations passed as argument
     * @param {DepositLocation[]} locationList - The list of location to place on the map as markers
     */
    updateMarkers(locationList: DepositLocation[]) {
        if (!!this.markerService.site && locationList.some(location => location.id !== this.markerService.site)) {
            this.closeMarkerInfoWindow();
        }
        this.markerService.updateMarkers(locationList);
        this.markers = this.markerService.markers;
        if (this.markerService.markers.length > 0 && this.centerMap) {
            this.updateMapBoundsAction.emit();
        }
        this.mapIsReady = true;
    }

    /**
     * Triggered when closing an information window representing a specific site
     * @todo Isn't this redundant with "handleMarkerClose"?
     */
    private closeMarkerInfoWindow() {
        this.markerService.handlePopUpClosed();
        this.closeWindowAction.emit();
        if (this.markerInfoWindowCloseOnZoom) {
            this.zoomAction.emit(-1);
            this.centerMap = false;
        }
    }

    ngOnDestroy(): void {
        this.updateMap.unsubscribe();
        this.beforeMapUpdate.unsubscribe();
        this.markerService.reset();
    }

    /**
     * Function which handles a click on a marker
     */
    handleMarkerClick(marker: IMapMarker) {
        this.markerService.handleMarkerClick(marker);
        this.onChangeDepositLocationIds.emit([marker.id]);
    }

    /**
     * Handle the closing of a marker info
     * @param {IMapMarker} marker - The marker which was opened
     * @todo Is the param really needed?
     */
    handleMarkerClose(marker: IMapMarker) {
        if (this.markerService.isOpenPopUpOpen) {
            this.markerService.handlePopUpClosed();
            if (this.markerInfoWindowCloseOnZoom) {
                this.denyCenterOnNextUpdateCycle = true;
                this.zoomAction.emit(-1);
            }
            this.onChangeDepositLocationIds.emit([]);
            this.onChangeMarkerClose.emit();
        }
    }
}
