import * as tslib_1 from "tslib";
var _a;
import { OrganisationTIds } from '../../shared/values/company-values.enum';
import { ERoleIds } from '../../shared/values/role-ids.enum';
import { AbstractPermission } from '../../shared/models/abstract-permission.class';
import { EAgentPermission } from './values/agent-permission.enum';
var RestrictedBuildingPermission = /** @class */ (function (_super) {
    tslib_1.__extends(RestrictedBuildingPermission, _super);
    function RestrictedBuildingPermission() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = 'Agent without privileges to create new sites';
        return _this;
    }
    RestrictedBuildingPermission.prototype.isValid = function (user) {
        return user.company.organisation_type === OrganisationTIds.Building;
    };
    RestrictedBuildingPermission.id = EAgentPermission.RESTRICTED_AGENT;
    return RestrictedBuildingPermission;
}(AbstractPermission));
export { RestrictedBuildingPermission };
export var AGENT_PERMISSIONS = {
    roleId: ERoleIds.AGENT,
    permissions: (_a = {},
        _a[RestrictedBuildingPermission.id] = new RestrictedBuildingPermission(),
        _a),
    defaultPermissionsIdsBefore: [],
    defaultPermissionsIdsAfter: [RestrictedBuildingPermission.id]
};
