<div class="control-group">
  <ul class="control-group__list"
  *ngFor="let item of listCtrl?.controls; let i=index;"
    [formGroup]="listCtrl.controls[i]">
    <li class="control-group__item control-group__item--icon" *ngIf="!isDisabled && viewMode === VIEW_MODE.DEFAULT">
      <div class="control-group__item-button"
      (click)="removeMaterial(i)">
        <md-icon>remove</md-icon>
      </div>
    </li>
    <li class="control-group__item control-group__item--component" (click)="openNomenclatureDialog(item)">
      <div class="flex align-center">
        <div class="control-group__label"
            [ngClass]="getLabelClasses(item.get('name').value)">{{ 'Materials'  | translate }}</div>
      </div>
      <md-input-container class="control-group__item--aligned">
        <input mdInput class="fieldreadonly" autocomplete="off"
        formControlName="name"
        value = "{{listCtrl.controls[i].get('name').value | translate }} "
        >
      </md-input-container>
    </li>
    <li class="control-group__item control-group__item--component-percent">
      <div class="flex align-center">
        <div class="control-group__label"
            [ngClass]="getLabelClasses(item.get('percentage').value)">% {{ 'by weight of the' | translate}} {{'Component' | translate | lowercase}}</div>
      </div>
      <div class="control-group__item--percentage-wrapper">
        <md-input-container class="control-group__item--percentage">
          <input
          mdInput
          autocomplete="off"
          min="0"
          max="100"
          (keypress)="preventLettersAndNumberGreaterThanOneHundred($event)"
          type="number"
          formControlName="percentage">
        </md-input-container> %
      </div>
    </li>
  </ul>
  <ul (click)="addNew()" class="control-group__list control-group__list--narrow"
  *ngIf="isAddButtonVisible()">
    <li class="control-group__item control-group__item--icon">
      <div class="control-group__item-button">
        <md-icon>add</md-icon>
      </div>
    </li>
    <li class="control-group__item control-group__item--component">
      <div class="control-group__item--subtext-material">{{'add a material' | translate}}</div>
    </li>
    <li class="control-group__item control-group__item--component">
    </li>
  </ul>
</div>
