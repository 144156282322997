import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { Material } from '../models/material.class';
import { merge as mergeStatic } from 'rxjs';
var MaterialService = /** @class */ (function (_super) {
    tslib_1.__extends(MaterialService, _super);
    function MaterialService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'passport-materials';
        _this.namespaceSingular = 'passport-material';
        _this.ModelClass = Material;
        return _this;
    }
    MaterialService.prototype.updateMaterials = function (materials) {
        var requests = materials.map(function (item) { return item.save(); });
        return mergeStatic.apply(void 0, requests);
    };
    return MaterialService;
}(CrudService));
export { MaterialService };
