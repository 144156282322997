import { Component, Input, OnInit } from '@angular/core';
import { IMaterialHealthPieChart } from './values/material-heath-pie-chart-data.interface';
import { IReportResponse } from '../../../../interfaces/reportResponse.interface';
import {IMaterialHealthCategory, } from '../../../../interfaces/material-health.interface';
import { MAIN_TAB_STEPS } from '../../admin-reporting.const';
import { ReportBase } from '../report-base/report-base.class';
import { MaterialPieCharData } from '../../../../services/material-pie-char-data.service'

@Component({
  moduleId: module.id,
  selector: 'material-health',
  templateUrl: 'material-health.component.html',
  styleUrls: ['material-health.component.scss']
})
export class MaterialHealthComponent extends ReportBase implements OnInit {
  @Input() report: IReportResponse;
  @Input() changeTab: Function;
  public isDescriptionBlockVisible = true;

  public minDepositsConsidered: number;
  public maxDepositsConsidered: number;
  public tonsOfDeposits: number;

  public mainChartData: IMaterialHealthPieChart;
  public mostImportantProductChartData: IMaterialHealthPieChart;
  public healthiestProductChartData: IMaterialHealthPieChart;
  public mostToxicProductChartData: IMaterialHealthPieChart;
  public filteredProductCategory: object = {};

  constructor(private materialPieCharData: MaterialPieCharData) { super() }

  hideDescriptionBlock() {
    this.isDescriptionBlockVisible = false;
  }

  ngOnInit() {
    this.prepareMainChartData();
    this.prepareSecondaryChartsData();
  }

  prepareMainChartData() {
    this.mainChartData = this.materialPieCharData.getRoundPieChartData({mainData: this.report.report_result.material_health.aggregate});
    this.minDepositsConsidered = this.report.report_result.material_health.aggregate.total_deposit_considered;
    this.maxDepositsConsidered = this.report.report_result.material_health.aggregate.total_deposit_count;
    this.tonsOfDeposits = this.report.report_result.material_health.aggregate.total_weight;
  }

  prepareSecondaryChartsData() {
    this.setHealthiestProduct();
    this.setMostToxicProduct();
    this.setMostImportantProduct();
  }

  setHealthiestProduct(): void {
    this.healthiestProductChartData = this.materialPieCharData.getRoundPieChartData({product: this.getProductByHealthiness('MOST')});
  }
  getProductByHealthiness(type: 'MOST' | 'LEAST') {
    const products: IMaterialHealthCategory[] = this.orderProductsBy('healthiness');
    if (!!products.length) {
      switch (type) {
        case 'LEAST':
          return products[products.length - 1];
        case 'MOST':
          return products[0];
    }
    }
  }

  setMostToxicProduct(): void {
    this.mostToxicProductChartData = this.materialPieCharData.getRoundPieChartData({product: this.getProductByHealthiness('LEAST')});
  }

  setMostImportantProduct(): void {
    const product: IMaterialHealthCategory = this.orderProductsBy('weight')[0];
    this.mostImportantProductChartData = this.materialPieCharData.getRoundPieChartData({product});
  }

  orderProductsBy(orderBy: string): any {
    const categories = this.report.report_result.material_health.aggregate.category;
    if (!!categories) {
      const categoriesFiltered = this.filteredProductCategory = { ...Object.values(categories).filter(e => !!e['deposits_considered']) };
      return Object.values(categoriesFiltered).sort((a, b) => a[orderBy] < b[orderBy] ? 1 : (b[orderBy] < a[orderBy] ? -1 : 0));
    }
  }

  isEmptyProductCategory(): boolean {
    return Object.keys(this.filteredProductCategory).length === 0;
  }

  goToArchive() {
    this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
  }
}
