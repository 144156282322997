<div class="need-list sales-needs-archive">
  <section class="need-list__header">
    <div class="ib-row need-list__header-content">
      <div class="ib-column need-list__search">
        <div class="need-list__search-wrapper">
          <md-input-container class="input-group">
            <input test-id="search"
                   mdInput
                   autocomplete="off" 
                   [formControl]="search"
                   (change)="getRecords()"
                   placeholder="{{ 'Search by anything' | translate }}"
            >
          </md-input-container>
        </div>
      </div>
      <ul class="ib-column need-list__tab-headings">
        <li
          class="need-list__tab-heading"
          (click)="goToNeeds()"
        >{{ 'Creation' | translate }}
        </li>
        <li
          class="need-list__tab-heading"
          (click)="goToTreatment()"
        >{{ 'Treatment' | translate }}
        </li>
        <li
          class="need-list__tab-heading active"
        >{{ 'Archive' | translate }}
        </li>
      </ul>
    </div>
    <div class="need-list__md-checkbox-list">
      <md-checkbox class="esm-catalog__group--checkbox checkbox-group"
                   (change)="getRecords()"
                   [(ngModel)]="status.checked"
                   *ngFor="let status of filterStatuses"
                   [name]="status.text">
        {{ status.needStatus.view | lowercase | capitalize | translate }}
      </md-checkbox>
    </div>
    <div class="ib-row">
    <div class="ib-column need-list__creation-date">
      <div class="need-list__date-wrapper">
        <md-input-container>
          <input
            test-id="creation-date-from-filter"
            ng2-datetime-picker
            date-only="true"
            autocomplete="off" 
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            mdInput
            placeholder="{{ 'Created FROM' | translate }}"
            [(ngModel)]="creationDateFrom"
            (ngModelChange)="getRecords()"
          />
        </md-input-container>
      </div>
      <div class="need-list__date-separator">-</div>
      <div class="need-list__date-wrapper">
        <md-input-container>
          <input
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Created TO' | translate }}"
            [(ngModel)]="creationDateTo"
            (ngModelChange)="getRecords()"
          />
        </md-input-container>
      </div>
    </div>
    <div class="ib-column need-list__deliver-date">
      <div class="need-list__date-wrapper">
        <md-input-container>
          <input
            test-id="delivery-date-from-filter"
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Delivery date FROM' | translate }}"
            [(ngModel)]="deliveryDateFrom"
            (ngModelChange)="getRecords()"
          />
        </md-input-container>
      </div>
      <div class="need-list__date-separator">-</div>
      <div class="need-list__date-wrapper">
        <md-input-container>
          <input
            ng2-datetime-picker
            date-only="true"
            date-format="DD-MM-YYYY"
            parse-format="YYYY-MM-DD"
            mdInput
            autocomplete="off" 
            placeholder="{{ 'Delivery date TO' | translate }}"
            [(ngModel)]="deliveryDateTo"
            (ngModelChange)="getRecords()"
          />
        </md-input-container>
      </div>
    </div>
    <div class="ib-column need-list__sort-group">
      <md-select
        placeholder="{{ 'Sort By' | translate }}"
        [(ngModel)]="orderBy"
        (ngModelChange)="getRecords()"
      >
        <md-option *ngFor="let item of NEED_SORT_ORDER" [value]="item.key">{{item.value | translate}}</md-option>
      </md-select>
    </div>
  </div>
  </section>

  <custom-list
    [records]="records"
    [expandable]="true"
    (onEndOfList)="onEndOfListReached()"
  ></custom-list>
</div>
