import { Injectable } from '@angular/core';
import { VirginProduct } from '../models/virgin-product.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';

@Injectable()
export class VirginProductService extends ExtendedCrudService {
  protected namespace = 'virgin-products';
  protected namespaceSingular = 'virgin-product';
  protected ModelClass = VirginProduct;
}
