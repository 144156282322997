import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { Injectable } from '@angular/core';
import { EcosystemStep } from '../../../dashboards/ecosystem-manager/models/ecosystem-step.class';

@Injectable()
export class EcosystemStepService extends ExtendedCrudService {
  protected namespace = 'ecosystem-steps';
  protected namespaceSingular = 'ecosystem-step';
  protected ModelClass = EcosystemStep;
}
