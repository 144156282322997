import { Component, DoCheck, OnInit, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MdDialog } from '@angular/material';
import { DepositOwnerListService } from '../../../../deposit-owner/services/deposit-owner-list.service';
import { DepositService } from '../../../../../entities/deposit/services/deposit.service';
import { Searchable } from '../../../../../shared/models/searchable.class';
import {
  ICustomListMenuOption,
  ICustomListRecord
} from '../../../../../shared/custom-list/interfaces/custom-list.interface';
import { Deposit } from '../../../../../entities/deposit/models/deposit.class';
import { CustomListRecord } from '../../../../../shared/custom-list/custom-list-record.model';
import {
  DepositDetailsDialogComponent
} from '../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { GlobalEventsManager } from '../../../../../shared/services/global-events-manager.service';
import { CurrentUserService } from '../../../../../shared/services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NEED_STATUSES } from '../../../../../entities/need/values/need-statuses.const';
import { NavigationService } from '../../../../../shared/services/navigation.service';
import { ModuleNames } from '../../../../../shared/values/module-names.const';
import { getSearchStatus } from '../../../../../shared/helpers/search-status-update';
import { DIALOG_THEME } from '../../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../../shared/services/navbar.service';
import { CustomListDateAvailabilityService } from '../../../../../shared/services/custom-list-date-availability.service';
import { DepositExportDialogComponent } from '../../../dialogs/deposit-export/deposit-export-dialog.component';
import { SiteService } from './../../../../../entities/depositLocation/services/SiteService';
import { DepositLocation } from '../../../../../entities/deposit/models/deposit-location.class';
import { DEPOSIT_STATUSES } from '../../../../../entities/deposit/values/deposit-statuses.const';
import { DepositTag } from '../../../../../entities/deposit/models/deposit-tag.class';
import { Subscription } from 'rxjs';


@Component({
  selector: 'admin-deposit-catalog',
  moduleId: module.id,
  templateUrl: 'admin-deposit-catalog.component.html'
})
export class AdminDepositCatalogComponent implements DoCheck, OnInit {
  records: ICustomListRecord[] = [];
  depositOwners: Searchable[];
  searchControl = new FormControl();

  beforeUpdateMap = new EventEmitter();
  updateMap = new EventEmitter<DepositLocation[]>();
  changeFilteredLocations = new EventEmitter<DepositLocation[]>();
  availableDepositLocations: DepositLocation[] = [];
  chosenDepositLocations: DepositLocation[] = [];
  chosenTags: DepositTag[] = [];
  search: string;
  availableDateFrom: number;
  availableDateTo: number;
  sortOrder = 'name';
  sites: DepositLocation[] = [];
  private mapSubscription: Subscription;

  currentPage = 1;
  totalPagesCount = 0;
  totalCounts = 0;
  isLoadingNewPage = false;
  specialAdminFilterStatuses = [
    NEED_STATUSES.ASK_FOR_MODIFICATION,
    NEED_STATUSES.WAITING_FOR_PUBLICATION,
    NEED_STATUSES.PUBLISHED,
  ];
  searchStatus = this.specialAdminFilterStatuses.map((status) => status.value);
  markerAvailableOnLabelText: string;
  private depositRequestQueue = [];

  constructor(private depositOwnerListService: DepositOwnerListService,
    private _depositService: DepositService,
    private mdDialog: MdDialog,
    private _globalEvents: GlobalEventsManager,
    private _currentUserService: CurrentUserService,
    protected _navigationService: NavigationService,
    private _translate: TranslateService,
    private navBarState: NavbarStateService,
    public customListDateAvailable: CustomListDateAvailabilityService,
    private siteService: SiteService,) {
    this.depositOwnerListService.get().subscribe((res: Searchable[]) => this.depositOwners = res);
    this
      .searchControl
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .do((keyword) => this.search = keyword)
      .subscribe(() => this.loadDeposits());
  }

  ngOnInit() {
    this.loadDeposits();
    this.initSites();
    this._translate.get('Available on').subscribe((translation) => {
      this.markerAvailableOnLabelText = translation;
    });
  }

  private initSites() {
    this.beforeUpdateMap.emit();
    this.mapSubscription = this.siteService.loadSites({
      admin_view: 1,
      ...this.getDepositQuery(),
    }).subscribe(sites => {
      this.sites = sites;
      if(this.chosenDepositLocations.length === 0) 
        this.availableDepositLocations = sites;
      this.updateMap.emit(sites);
    });
  }

  private getDepositQuery(forPage = null) {
    let statuses = [DEPOSIT_STATUSES.PUBLISHED.value];
    if (this.isSpecialAdminModule()) {
      statuses = this.searchStatus;
    }

    return {
      expand: 'passport,creator,owner_name,owner_id,creator_email,deposit_location,passport_materials,material_names',
      admin: 1,
      supervisor: 1,
      available_from: this.availableDateFrom || null,
      available_to: this.availableDateTo || null,
      search: this.search || null,
      sort: this.sortOrder || null,
      'status[]': statuses || null,
      'sites[]': this.chosenDepositLocations.map(location => location.id) || null,
      'tags[]': this.chosenTags.map(tag => tag.id) || null,
      page: forPage
    };
  }

  ngDoCheck() {
    // this.loadDeposits();
  }

  loadDeposits(forPage: number = 1) {
    const query: any = this.getDepositQuery(forPage);

    if (this.navBarState.multilanguage) {
      query.english = true;
    }

    if (forPage === 1) {
      this.initNewDepositLoad();
    }
    this.isLoadingNewPage = true;
    const request = this._depositService.getWithPaginationData(query)
      .subscribe((res) => this.handleResponse(res));
    this.depositRequestQueue.push(request);
  }

  isSpecialAdminModule() {
    return this._navigationService.getModuleName() === ModuleNames.SPECIAL_ADMIN;
  }

  private initNewDepositLoad() {
    this.records = [];
    this.beforeUpdateMap.emit();
    this.depositRequestQueue.forEach(request => request.unsubscribe());
  }

  private handleResponse(res) {
    const headers = res.headers;
    this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
    this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
    this.totalCounts = Number(headers.get('X-Pagination-Total-Count'));
    res.json().map((data: any) => {
      const deposit = new Deposit(data);
      this.records.push(this.toRecord(deposit));
    });
    this.initSites();
    this.isLoadingNewPage = false;
  }

  onEndOfListReached() {
    if (this.currentPage < this.totalPagesCount && !this.isLoadingNewPage) {
      this.loadDeposits(this.currentPage + 1);
    }
  }

  toRecord(data: Deposit): ICustomListRecord {
    const availableInListItem = this.customListDateAvailable
      .getColumn(data.availability_date, data.availability_expiration);

    let passportName = '';
    if (data.passport) {
      passportName = data.passport.name;
    }

    const usagesString = Array.from(new Set(data.passport.next_usages.map((usage) => 'test'))).join(', ');

    const materials: { name: string }[] = data.material_names;
    const materialsString = Array.from(new Set(materials.map((material) => this._translate.instant(material.name)))).join(', ');

    const controls: ICustomListMenuOption[] = [
      {
        name: 'See details',
        fn: () => {
          this.mdDialog.open(DepositDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
            data: {
              readOnly: true,
              id: data.id,
            }
          });
        },
      },
      {
        fn: () => location.href = 'mailto:' + data.creator_email,
        name: 'Send Email'
      },
    ];

    if (data.owner_id !== this._currentUserService.infoData.id) {
      controls.push({
        fn: () => this.openChat(data.owner_id),
        name: 'Chat',
      });
    }

    const locationName = data.deposit_location.name || '';
    const valuePubDate = data.publication_date || data.updated_at;
    const pubDate = moment(valuePubDate).format('DD.MM.YYYY');

    return CustomListRecord
      .build()
      .setPrimaryKey(data.id)
      .addTitleColumn(data.name, passportName, null, data.passport.english_name)
      .addListColumn([{ key: '', value: data.passport.manufacturer }])
      .addListColumn([{ key: 'Materials', value: materialsString }])
      .addUnitColumn(data.quantity ? data.quantity : 0, data.passport.customListUnit)
      .addListColumn([{ key: 'Usages', value: usagesString }])
      .addListColumn([{ key: 'Site', value: locationName }])
      .addListColumn([{ key: 'Published', value: pubDate }, availableInListItem])
      .addAuthorColumn(data.owner_name)
      .addControlsColumn(controls);
  }

  exportDeposit() {
    this.mdDialog.open(DepositExportDialogComponent, {
      data: {
        data: {
          supervisor: 1,
          admin: 1,
          available_from: this.availableDateFrom || null,
          available_to: this.availableDateTo || null,
          search: this.search || null,
          sort: this.sortOrder || null,

          'status[]': DEPOSIT_STATUSES.PUBLISHED.value || null,
          timezone_offset: moment().utcOffset(),
          'sites': this.chosenDepositLocations.map(location => location.id) || null,
        }
      }
    }).afterClosed().subscribe();
  }
  downloadPdfReport(): string {
    return this._depositService.getPdfReportUrl({
      supervisor: 1,
      available_from: this.availableDateFrom || null,
      available_to: this.availableDateTo || null,
      search: this.search || null,
      sort: this.sortOrder || null,
      owner: null,
      timezone_offset: moment().utcOffset()
    });
  }

  openChat(targetId: number) {
    console.log('openChat', targetId);
    this._globalEvents.chatUserClicked$.emit(targetId);
  }

  setChosenLocationsById(ids: number[]) {
    for (const id of ids) {
      const location = this.availableDepositLocations.find(depositLocation => depositLocation.id === id);
      if (location && !this.chosenDepositLocations.some(chosenLocation => chosenLocation.id === id)) {
        this.chosenDepositLocations.push(location);
      } else if (location) {
        return;
      }
    }
    this.loadDeposits();
  }

  toggleState(state: number) {
    this.searchStatus = getSearchStatus(state, this.searchStatus);
    this.loadDeposits();
  }

  setChosenLocation(locations: DepositLocation[]) {
    this.chosenDepositLocations = locations;
    this.loadDeposits();
  }
}
