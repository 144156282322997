/**
 * Created by atat on 18/06/2017.
 */

import { Component, OnInit } from '@angular/core';
import {
  ECustomListHintClass,
  ECustomListRecordStatus,
  ECustomListUnit,
  ICustomListRecord
} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { TransformOrderService } from '../../../ecosystem-manager/services/transform-order.service';
import { TransformOrder } from '../../../ecosystem-manager/models/transform-order.class';
import { DatePipe } from '@angular/common';
import { TRANSFORM_ORDER_STATUS } from '../../values/transform-order-statuses.const';
import { MdDialog } from '@angular/material';
import { TsmOrderDetailsDialogComponent } from '../../dialogs/tsm-order-details-dialog/tsm-order-details-dialog.component';
import { TRANSFORM_TYPES } from '../../values/transform-types.const';
import { OrderMaterial } from '../../../ecosystem-manager/models/order-material.class';
import * as moment from 'moment';
import { OrderPassport } from '../../../ecosystem-manager/models/order-passport.class';
import {
  TransformationOrderTransactionConfirmDialogComponent
} from '../../dialogs/transformation-order-transaction-confirm-dialog/transformation-order-transaction-confirm-dialog.component';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {DepositOrder} from '../../../ecosystem-manager/models/deposit-order.class';
import {ECOSYSTEM_ORDER_STATUS} from '../../../ecosystem-manager/values/ecosystem-order-statuses.const';
import {EcosystemProblemUtilsService} from '../../../../entities/ecosystem/services/ecosystem-problem-utils.service';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';

@Component({
  moduleId: module.id,
  selector: 'tsm-order-archive-catalog',
  templateUrl: 'tsm-order-archive-catalog.component.html'
})
export class TsmOrdersArchiveCatalogComponent implements OnInit {
  records: ICustomListRecord[] = [];

  esmContacts: any[];

  currentPage: number = 1;
  totalPagesCount: number = 0;
  isNextPageLoading: boolean = false;

  sortOptions: any = [
    { field: 'publication_date', view: 'Reception date'},
    { field: 'arrival_date', view: 'Arrival date'},
    { field: 'creator', view: 'Ecosystem Manager'},
    { field: 'transformation_name', view: 'Transformation name'}
  ];
  sortValue: string = 'publication_date';

  // Filters
  TRANSFORM_ORDER_STATUSES = [
    {
      text: 'Confirmed',
      value: TRANSFORM_ORDER_STATUS.CONFIRMED
    },
    {
      text: 'Rejected',
      value: TRANSFORM_ORDER_STATUS.REJECTED
    },
    {
      text: 'Not achieved',
      value: TRANSFORM_ORDER_STATUS.PROBLEM_REPORTED
    }
  ];

  TRANSFORM_TYPES = TRANSFORM_TYPES;
  type: number = null;

  public searchStatus: number[] = this.TRANSFORM_ORDER_STATUSES.map((status) => status.value);
  esmContact: number;
  search: string;

  constructor(private _ordersService: TransformOrderService,
              private mdDialog: MdDialog,
              public datepipe: DatePipe,
              private ecosystemProblemUtilsService: EcosystemProblemUtilsService,
              private notificationService: LogNotificationService
              ) {
    const options = {
      'tsm_view': 1,
      'is_archive': true
    };
    this._ordersService.getEsmList(options).subscribe((esmContacts) => {
      this.esmContacts = esmContacts;
    });
  }


  toggleStatus(state: number) {
    let index: number = this.searchStatus.indexOf(state);
    if (index > -1) {
      this.searchStatus.splice(index, 1);
    } else {
      this.searchStatus.push(state);
    }
    console.info(this.searchStatus);
  }

  addRecord(order: TransformOrder) {
    let arrivalAt = order.in_order_passport.linked_datetime;
    let arrivalIsToday = this.datepipe.transform(arrivalAt, 'dd.MM.yyyy') === this.datepipe.transform(new Date(), 'dd.MM.yyyy');
    let departureAt = order.out_order_passport.linked_datetime;
    let transformationType = TRANSFORM_TYPES.find((TYPE) => TYPE.value === order.transformation.transformation_type).view;
    arrivalAt = arrivalAt ? this.datepipe.transform(arrivalAt, 'dd.MM.yyyy') : '';
    departureAt = departureAt ? this.datepipe.transform(departureAt, 'dd.MM.yyyy') : '';
    let receptedAt = moment(order.publication_date || order.confirmation_date).format('DD.MM.YYYY');

    let date = this.datepipe.transform(order.confirmation_date, 'dd.MM.yyyy');
    let dateLabel = '';
    if(order.status === TRANSFORM_ORDER_STATUS.CONFIRMED) {
      dateLabel = 'Confirmed';
    } else if(order.status === TRANSFORM_ORDER_STATUS.REJECTED) {
      dateLabel = 'Rejected';
    }

    let status:ECustomListRecordStatus;
    switch (order.status) {
      case TRANSFORM_ORDER_STATUS.CONFIRMED:
        status = ECustomListRecordStatus.NO_STATUS;
        break;
      case TRANSFORM_ORDER_STATUS.REJECTED:
        status = ECustomListRecordStatus.CANCELED;
        break;
      default:
        status = ECustomListRecordStatus.NO_STATUS;
    }

    /*
    TODO date of reception of the order, date of rejection [if rejected]
"
    */

    this.records.push(
      this.createOrderRecord(
        status,
        arrivalIsToday ? ECustomListHintClass.CONFIRM : ECustomListHintClass.NO_CLASS,
        arrivalIsToday ? 'Arrival is today' : '',
        transformationType,
        order.transformation.name,
        transformationType.charAt(0).toUpperCase() + transformationType.slice(1).toLowerCase(),
        order.ecosystem_name,
        receptedAt,
        arrivalAt,
        departureAt,
        date,
        dateLabel,
        order.creator,
        order
      )
    );
  }

  ngOnInit(): void {
    this.getRecords();
  }

  onEndOfListTriggered() {
    if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount)  {
      this.getRecords(this.currentPage + 1);
    }
  }

  getRecords(forPage: number = 0) {
    if (this.searchStatus.length === 0) {
      this.records.length = 0;
    }else {
      let options = {
        'expand': 'transformation,in_order_passport,out_order_passport,creator,ecosystem_name',
        'supervisor': 1,
        'tsm_view': 1,
        'search': this.search || null,
        'esm': this.esmContact || null,
        'sort': this.sortValue || null,
        'status[]': this.searchStatus,
        'transformation_type': this.type,
        'page': forPage,
      };
      this.isNextPageLoading = true;
      this._ordersService
        .getWithPaginationData(options)
        .subscribe((res) => {
          if (forPage === 0) {
            this.records = [];
          }
          let headers = res.headers;
          this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
          this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
          res.json().map((item: any) => this.addRecord(new TransformOrder(item)));
          this.isNextPageLoading = false;
        });
    }
  }

  createOrderRecord(status: ECustomListRecordStatus,
                    hintCssClass: ECustomListHintClass,
                    hintText: string,
                    icon: string,
                    title: string,
                    subtitle: string,
                    esmName: string,
                    receptedAt: string,
                    arrivalAt: string,
                    departureAt: string,
                    date: string,
                    dateLabel: string,
                    author: string,
                    entity: TransformOrder): CustomListRecord {

    let incomePassport: OrderPassport = entity.in_order_passport;
    let outcomePassport: OrderPassport = entity.out_order_passport;

    let income: {key: string, value: string}[] = [];
    if(incomePassport.materials.length > 0) {
      income.push({
        key: 'Input',
        value: incomePassport.materials.map(material => material.nomenclature.name).join(',')
      });
    }
    if(incomePassport.quantity !== null) {
      income.push({
        key: 'Quantity',
        value: incomePassport.quantity !== null
          ? (incomePassport.quantity + ' ' + (incomePassport.unit_text !== null ? incomePassport.unit_text : ''))
          : ''
      });
    }

    let outcome: {key: string, value: string}[] = [];
    if(outcomePassport.materials.length > 0) {
      outcome.push({
        key: 'Output',
        value: outcomePassport.materials.map(material => material.nomenclature.name).join(',')
      });
    }
    if(outcomePassport.quantity !== null) {
      outcome.push({
        key: 'Quantity',
        value: outcomePassport.quantity !== null
          ? (outcomePassport.quantity + ' ' + (outcomePassport.unit_text !== null ? outcomePassport.unit_text : ''))
          : ''
      });
    }

    return CustomListRecord
      .build()
      .setStatus(status)
      .setHint(hintText, hintCssClass)
      .addIconColumn(icon)
      .addTitleColumn(title, subtitle)
      .addTextColumn(esmName)
      .addListColumn(income)
      .addListColumn(outcome)
      .addListColumn([{
        key: 'Arrival', value: arrivalAt
      }, {
        key: 'Departure',
        value: departureAt,
        cssClass: 'important-value'
      }])
      .addDateColumn(date, dateLabel)
      .addDateColumn(receptedAt, 'Reception')
      .addAuthorColumn(author)
      .addControlsColumn([
        {
          fn: (data: ICustomListRecord) => {
            let dialogRef = this.mdDialog.open(TsmOrderDetailsDialogComponent, {
              data: {
                id: entity.id,
                readOnly: true,
              },
            });
            dialogRef.afterClosed().subscribe((result: any) => {
              this.getRecords();
            });
          },
          name: 'See order'
        }, ...(entity.status === TRANSFORM_ORDER_STATUS.CONFIRMED ? [{
        fn: (data: ICustomListRecord) => {
          this.mdDialog.open(TransformationOrderTransactionConfirmDialogComponent, {
            ...DIALOG_THEME.TRANSACTION_ORDER_CONFIRMATION,
            data: {
              id: entity.id,
              disabled: true,
            },
          });
          },
          name: 'See confirmation'
        }] : []),
          ...(entity.status === TRANSFORM_ORDER_STATUS.PROBLEM_REPORTED ? [{
          fn: (data: ICustomListRecord) => this._ordersService
            .view(entity.id, {expand: 'problem'}).subscribe((orderWithProblem: TransformOrder) => {
              if (orderWithProblem.status === ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED) {
                this.ecosystemProblemUtilsService.openOrderProblemDialog(orderWithProblem.problem.description || '');
              } else {
                this.notificationService.error('No result found', null, true);
                this.getRecords();
              }
            }),
          name: 'See order problem'
        }] : []),
        {
          fn: (data: ICustomListRecord) => console.log(data),
          name: 'Contacts'
        }
      ]);
  }
}
