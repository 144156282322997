import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { isEmpty, values } from 'lodash';

@Pipe({
  name: 'floatingDigitNumberAfterComma'
}) @Injectable()
export class FloatingDigitNumberAfterComma implements PipeTransform {

    transform(valueRaw: number | string, ...locales: any[]) {
        const value = Number(valueRaw);
        if(isNaN(value)) return null;
        let format = '';
        const valueAbs = Math.abs(value);
        if (valueAbs >= 100) {
            format = '1.0-0';
        } else if (valueAbs >= 10) {
            format = '1.1-1';
        } else if (valueAbs >= 1) {
            format = '1.2-2';
        } else {
            format = '1.3-3';
        }
        return locales[0] ? formatNumber(value, locales[0], format) : formatNumber(value, 'en-US', format);
    }
}
