import * as tslib_1 from "tslib";
import { CrudService } from '../../shared/services/crud.service';
import { ActiveImport } from '../models/active-import.class';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../../shared/services/log-notification.service";
import * as i3 from "../../shared/services/navigation.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../shared/services/mock/mock-api.service";
var ActiveImportService = /** @class */ (function (_super) {
    tslib_1.__extends(ActiveImportService, _super);
    function ActiveImportService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ModelClass = ActiveImport;
        _this.namespace = 'active-imports';
        _this.namespaceSingular = 'active-import';
        return _this;
    }
    ActiveImportService.prototype.readEndpointUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/read');
    };
    ActiveImportService.prototype.read = function (importId) {
        return this.sendPost(this.readEndpointUrl(importId));
    };
    ActiveImportService.ngInjectableDef = i0.defineInjectable({ factory: function ActiveImportService_Factory() { return new ActiveImportService(i0.inject(i1.Http), i0.inject(i2.LogNotificationService), i0.inject(i3.NavigationService), i0.inject(i4.TranslateService), i0.inject(i5.MockApiService)); }, token: ActiveImportService, providedIn: "root" });
    return ActiveImportService;
}(CrudService));
export { ActiveImportService };
