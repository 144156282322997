import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { TransformTagJunction } from '../models/transform-tag-junction.class';

@Injectable()
export class TransformTagJunctionService extends CrudService {
  protected namespace = 'transformation-tag-junctions';
  protected namespaceSingular = 'transformation-tag-junction';
  protected ModelClass = TransformTagJunction;
}
