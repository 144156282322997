/**
 * Created by kirill on 6.04.17.
 */
import {FormControl, Validators} from '@angular/forms';

export class ValidationService {
  static readonly URL_MAX_LENGTH = 2048;
  static readonly URL_RULE_PATTERN_YII2 =
    /^(?:http|https):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(?::\d{1,5})?(?:$|[?\/#])/i;

  static isValidFloatString(value: string) {

  }
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config: any = {
      'invalidQuantity': 'Invalid Quantity'
    };
    return config[validatorName];
  }

  static normalizeQuantity(value: string| number): number {
    return parseFloat(String(value).replace(',', '.').trim());
  }

  static validateQuantity(control: FormControl) {
    //Null check
    if (!control.value && control.value !== 0) {
      return null;
    }

    //format check
    const isValidFormat = /^\d+([,|.]?\d+)?$/g.test(String(control.value));
    if (!isValidFormat) {
      console.warn('Validator expects control to has a valid float value', control.value);
      return { 'invalidQuantity': true };
    }

    //Positive check
    if (ValidationService.normalizeQuantity(control.value) > 0) {
      return null;
    } else {
      console.warn('Validator expects number greater than 0');
      return  { 'invalidQuantity': true };
    }
  }

  static validateNumberOnChange() {
    return Validators.pattern(/^[+-]?\d+([,|.]\d*)?$/);
  }
  static validateNumberOnBlur() {
    return Validators.pattern(/^[+-]?\d+([,|.]\d+)?$/);
  }
  static validateURLLength() {
    return Validators.maxLength(ValidationService.URL_MAX_LENGTH);
  }
  static validateURL() {
    return Validators.pattern(ValidationService.URL_RULE_PATTERN_YII2);
  }
}
