/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../../../dashboards/manufacturer/components/selectable-button-list-filter.component.ngfactory";
import * as i3 from "../../../../../dashboards/manufacturer/components/selectable-button-list-filter.component";
import * as i4 from "../../../../../shared/services/navigation.service";
import * as i5 from "@angular/common";
import * as i6 from "./country-data-input-list.component";
import * as i7 from "../../../../../shared/services/country.service";
var styles_CountryDataInputListComponent = [];
var RenderType_CountryDataInputListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CountryDataInputListComponent, data: {} });
export { RenderType_CountryDataInputListComponent as RenderType_CountryDataInputListComponent };
function View_CountryDataInputListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", "..."])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Loading")); _ck(_v, 2, 0, currVal_0); }); }
function View_CountryDataInputListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-selectable-button-list-filter", [["placeholder", "SEARCH_BY_REPRESENTATIVITY"]], null, [[null, "selectionChange"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectionChange" === en)) {
        var pd_1 = (_co.onSelectionChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SelectableButtonListFilterComponent_0, i2.RenderType_SelectableButtonListFilterComponent)), i0.ɵdid(1, 638976, null, 0, i3.SelectableButtonListFilterComponent, [i1.TranslateService, i4.NavigationService], { selectedItem: [0, "selectedItem"], items: [1, "items"], placeholder: [2, "placeholder"], disabled: [3, "disabled"] }, { selectionChange: "selectionChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCountriesSelectedValue(); var currVal_1 = _co.getListCountriesName(); var currVal_2 = "SEARCH_BY_REPRESENTATIVITY"; var currVal_3 = _co.disabled; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CountryDataInputListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_CountryDataInputListComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["loaded", 2]], null, 0, null, View_CountryDataInputListComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.loading$)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CountryDataInputListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "country-data-input-list", [], null, null, null, View_CountryDataInputListComponent_0, RenderType_CountryDataInputListComponent)), i0.ɵdid(1, 114688, null, 0, i6.CountryDataInputListComponent, [i7.CountryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountryDataInputListComponentNgFactory = i0.ɵccf("country-data-input-list", i6.CountryDataInputListComponent, View_CountryDataInputListComponent_Host_0, { selectedValue: "selectedValue", disabled: "disabled" }, { selectionChange: "selectionChange" }, []);
export { CountryDataInputListComponentNgFactory as CountryDataInputListComponentNgFactory };
