import {Component, OnDestroy, OnInit} from '@angular/core';
import {DepositLocationService} from '../../../../entities/deposit/services/deposit-location.service';
import {Subscription} from 'rxjs/Subscription';
import {DepositLocation} from '../../../../entities/deposit/models/deposit-location.class';
import {CustomListRecord} from '../../../../shared/custom-list/custom-list-record.model';
import {ICustomListRecord} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import {
  DepositSiteDetailsDialogComponent
} from '../../../../entities/deposit/dialogs/deposit-site-details-dialog/deposit-site-details-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {MdDialog} from '@angular/material';
import {EDepositLocationStatus} from '../../../../entities/deposit/values/deposit-location-status.enum';
import {DepositLocationFilter} from '../../../../entities/deposit/models/deposit-location-filter.class';
import {EUsedFilter} from './used-filter.enum';
import {PermissionService} from '../../../../shared/services/permission.service';
import {EDepositOwnerPermission} from '../../values/deposit-owner-permission.enum';
import {PaginationUnmarshallingService} from '../../../../shared/services/pagination-unmarshalling.service';
import {cloneDeep, isEqual, omit} from 'lodash';
import {FormControl} from '@angular/forms';
import {ConfirmDialogComponent} from '../../../../shared/confirm-dialog/confirm-dialog.component';
import {IConfirmDialogData} from '../../../../shared/confirm-dialog/values/interface/confirm-dialog-data.interface';
import {EConfirmDialogIcon} from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import {
  NotificationWarningDialogComponent
} from '../../../../shared/notification-dialogs/notification-warning/notification-warning-dialog.component';
import {IDoSitesListFilters} from '../../values/do-sites-list-filters.interface';
import {FilterStateService} from '../../../../shared/services/filterState.service';
import {Router} from '@angular/router';
import {SpinnerDialogComponent} from '../../../../shared/spinner/dialogs/spinner-dialog/spinner-dialog.component';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-do-sites-list',
  templateUrl: './do-sites-list.component.html',
  styleUrls: ['./do-sites-list.component.scss']
})
export class DoSitesListComponent implements OnInit, OnDestroy {

  readonly EXPIRED_AS_STATUS = -1;
  sortOptions = [
    { field: 'created_at', view: 'Creation date'},
    { field: 'name', view: 'Name'},
  ];
  siteStatusOptions = [
    {key: EDepositLocationStatus.STORAGE, value: 'storage'},
    {key: EDepositLocationStatus.IN_CONSTRUCTION, value: 'in construction'},
    {key: EDepositLocationStatus.IN_OPERATION, value: 'in operation'},
    {key: this.EXPIRED_AS_STATUS, value: 'Expired'}
  ];
  usedOptions = [
    {key: EUsedFilter.NOT_USED, value: 'Not used'},
    {key: EUsedFilter.USED, value: 'Used'}
  ];

  filters = new DepositLocationFilter();
  resetFilter = {};
  alternativeRules = {search: ''};
  siteStatusFilter = [...this.filters.site_status, this.EXPIRED_AS_STATUS];
  usedFilter = [EUsedFilter.NOT_USED, EUsedFilter.USED];
  applyFilters: IDoSitesListFilters = {
    siteStatus: this.siteStatusFilter,
    used: this.usedFilter,
    search: this.filters.search
  };
  records = [];

  private subscription: Subscription;
  private processDialog;

  constructor(
    private depositLocationService: DepositLocationService,
    private translate: TranslateService,
    private dialog: MdDialog,
    private permissionService: PermissionService,
    private paginationService: PaginationUnmarshallingService,
    private filterStorageService: FilterStateService,
    private router: Router,
    private navigation: NavigationService
  ) { }

  get isBuildingDO() {
    return this.permissionService.hasAppliedPermission(EDepositOwnerPermission.BUILDING_DO);
  }

  ngOnInit() {
    this.subscription = this.filters.search.valueChanges
      .distinctUntilChanged().debounceTime(300)
      .subscribe(() => this.loadRecords());
    this.resetFilter = cloneDeep(this.applyFilters);
    this.filters.pagination = true;
    if (this.isBuildingDO) {
      this.filters.addExtraFilterAsDefault(['site_status', 'active']);
    }
    if (this.filterStorageService.sitesList) {
      this.applyFilters = this.getStoredFilters(this.applyFilters, this.filters);
    }
    this.loadRecords();
  }

  private getStoredFilters(filters: IDoSitesListFilters, sortFilter: {sort: string}) {
    const {search, siteStatus, sort, used} = this.filterStorageService.sitesList;
    filters.search.setValue(search.value || '', {emitEvent: false});
    filters.used = used;
    filters.siteStatus = siteStatus;
    sortFilter.sort = sort;
    return filters;
  }

  ngOnDestroy() {
    this.filterStorageService.sitesList = {...this.applyFilters, sort: this.filters.sort};
    this.subscription.unsubscribe();
  }

  loadRecords(page = 1) {
      this.depositLocationService.getWithPaginationData(this.buildOptions(page))
        .subscribe((res) => {
          const sites = this.updateOptionsAndTransformToModel(res);
          this.records = sites.map(record => this.createRecord(record));
        });
  }


  private buildOptions(page: number) {
    if (this.usedOptions.length) {
      const used =  !!this.applyFilters.used.find(item => EUsedFilter[EUsedFilter[item]] === EUsedFilter.USED);
      const notUsed =  !!this.applyFilters.used.find(item => EUsedFilter[EUsedFilter[item]] === EUsedFilter.NOT_USED);
      this.filters.is_not_used = used !== notUsed ? notUsed : !notUsed;
      this.filters.is_used =  used !== notUsed ? used : !used;
    }
    if (page) {
      this.filters.page = page;
    } else {
      this.filters.page = 0;
    }
    this.filters.assigned = 1;

    this.filters.site_status = this.applyFilters.siteStatus.filter(item => item !== this.EXPIRED_AS_STATUS) as EDepositLocationStatus[];
    this.filters.active = !this.applyFilters.siteStatus.find(item => this.EXPIRED_AS_STATUS === item);
    return this.filters.buildOptions();
  }

  private updateOptionsAndTransformToModel(response) {
    this.filters.updateRecords(response, this.paginationService.unmarshall(response));
    return this.filters.records.map(item => new DepositLocation(item));
  }

  deleteSearch() {

  }

  createRecord(site: DepositLocation) {
    const q = (text: string) => this.translate.instant(text);
    return CustomListRecord.build().setPrimaryKey(site.id)
      .addTitleColumn(site.name, site.nameAddress)
      .addTextColumn(`${site.use_count} ${q('Deposit(s)')}`)
      .addListColumn([{
        key: `${q('Created')}:`, value: this.formattedDate(site.created_at), seperator: ' '
      }])
      .addControlsColumn({
        options: [{
        fn: (data: ICustomListRecord) => this.seeDetails(site),
        name: 'See details'
        },
        ...(!this.isBuildingDO ? [{
          fn: (data: ICustomListRecord) => this.deleteSite(site),
          name: 'Delete'
        }] : [])],
        cssClass: 'do-sites-list__menu-burger',
        icon: 'menu'
      });
  }

  canDeleteSearch() {
    return Object.keys(this.applyFilters).some(key => {
      if (this.applyFilters[key] instanceof FormControl) {
        return (this.applyFilters[key].value || '') !== (this.resetFilter[key].value || '');
      } else {
        return !isEqual(this.applyFilters[key], this.resetFilter[key]);
      }
    }) && this.records.length;
  }

  isSiteStatusEmpty() {
    return isEqual(this.applyFilters.siteStatus, this.resetFilter['siteStatus']);
  }

  isUsedEmpty() {
    return isEqual(this.applyFilters.used, this.resetFilter['used']) || this.applyFilters.used.length === 0;
  }

  resetFilers() {
    Object.keys(this.applyFilters).forEach(key => {
      if (this.applyFilters[key] instanceof FormControl) {
        this.applyFilters[key].setValue(this.resetFilter[key].value);
      } else {
        this.applyFilters[key] = cloneDeep(this.resetFilter[key]);
      }
    });
  }

  public seeDetails(site: DepositLocation) {
      this.dialog.open(DepositSiteDetailsDialogComponent, {
        data: {
          id: site.id,
          readOnly: this.isBuildingDO,
        }
      }).afterClosed().subscribe((changedSite: DepositLocation) => {
        if (changedSite) {
          this.loadRecords();
        }
      });
  }

  public deleteSite(site: DepositLocation) {
    this.depositLocationService.canDelete(site.id).subscribe((isDeletable) => {
      if (isDeletable) {
        this.openDeletionDialog(site);
      }
    });
  }

  private openDeletionDialog(site: DepositLocation) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        ...this.getDeletionDialogCommonData(),
        text: this.translate.instant('SITE_DELETION_WARNING')
      } as Partial<IConfirmDialogData>
    }).afterClosed().subscribe((isConfirmed: boolean) => {
      if (isConfirmed) {
        site.destroy().subscribe(() => this.loadRecords(this.filters.page), () => this.loadRecords(this.filters.page));
      }
    });
  }

  private getDeletionDialogCommonData() {
    return  {
      subText: this.translate.instant('SITE_DELETION_WARNING_SUBTITLE'),
      confirmText: 'Yes',
      showCross: true,
      showUsualIcon: true,
      cssClass: 'do-sites-list__confirmation-delete-warning',
      showIcon: true,
      iconData: {
      iconType: EConfirmDialogIcon.SVG,
        showIconURL: 'assets/svg/warning--icon.svg'
        }
    };
  }

  public deleteFiltered() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        ...this.getDeletionDialogCommonData(),
        text: `You have requested the deletion of ${this.filters.totalCount} sites, corresponding to the selected search criteria.`
      } as Partial<IConfirmDialogData>
    }).afterClosed().subscribe((isConfirmed: boolean) => {
      if (isConfirmed) {
        const process = this.showProcessSpinner();
        this.depositLocationService.deleteAllFiltered(this.filters.buildOptions()).subscribe((errors) =>  {
            this.loadRecords();
            process.close();
            if (errors && !!errors.length) {
              this.showWarningNotification(errors);
            }
          }, () => process.close());
      }
    });
  }

  private showProcessSpinner() {
    if (!this.processDialog) {
      this.navigation.showNavBar = false;
      this.processDialog = this.dialog.open(SpinnerDialogComponent, {
        disableClose: true,
        data: {
          caption: 'Delete search results'
          , isLoadingDotsShown: true
        }
      });
      this.processDialog.afterClosed().pipe(first()).subscribe(() => {
        this.navigation.showNavBar = true;
        this.processDialog = null;
      });
    }
    return this.processDialog;
  }

   showWarningNotification(errors: Array<any>) {
    this.dialog.open(NotificationWarningDialogComponent, {
      data: {
        body: `The following sites cannot be deleted because they are used`,
        warnings: errors.map(item => ({titleText: item.name, text: item.address})),
        showCross: true,
        cssClass: 'do-sites-list__notification-warning-dialog',
        iconData: {
          iconType: EConfirmDialogIcon.TEXT,
          showIconText: '!',
          primaryFill: true
        }
      } as Partial<NotificationWarningDialogComponent>
    });
  }

  public createSite() {
    return this.seeDetails(new DepositLocation());
  }

  importSites() {
    this.router.navigate(['/deposit-owner/import-sites']);
  }

  private formattedDate(date: string) {
    return moment(date, 'YYYY-MM-DD' ).format('MMM DD,YYYY');
  }
}
