<nav class="main-navbar">

  <div class="logo"><a [routerLink]="[homeUrl]" [routerLinkActive]="['router-link-active']"
                       [routerLinkActiveOptions]="{exact:true}">{{ 'Upcyclea' | translate }}</a></div>

  <notification-wrapper *ngIf="!isRestrictedAdmin" [context]="_navigation.getContext()" class="notifier"></notification-wrapper>
  <ul class="navigation">
    <li class="navigation-item" *ngFor="let item of menu">
      <a (click)="item.url === 'admin/reporting' ? emitCircularSignatureClickEvent() : null"
         [routerLink]="[item.url]" [routerLinkActive]="['router-link-active']" [routerLinkActiveOptions]="{exact:true}">
        <span class="navigation-item-wrapper">
          <i class="ib-icon" [ngClass]="item.icon"></i><span>{{item.title | translate}}</span>
        </span>
      </a>
    </li>
    <li class="navigation-item" *ngIf="isBuildDepositOwner">
      <a (click)="openBIMModal()">
        <span class="navigation-item-wrapper">
              <i class="ib-icon" [ngClass]="'bim--icon'"></i>
              <span>{{ 'BIM models' | translate }}</span>
        </span>
      </a>
    </li>

    <li class="navigation-item navigation-item-multilanguage">
      <a>
        <span class="navigation-item-wrapper" md-tooltip="{{ 'MULTILANGUAGE_TOGGLE' | translate }}">
          <mat-slide-toggle
          (change)="navbarStateService.multilanguage = !navbarStateService.multilanguage"
          [checked]="!navbarStateService.multilanguage">
        </mat-slide-toggle>
        <i *ngIf="navbarStateService.multilanguage" class="icon icon--terra-blanc" style="margin-left: 10px;margin-bottom: 2px;"></i>
        <i *ngIf="!navbarStateService.multilanguage" class="icon icon--terra-blanc30" style="margin-left: 10px;margin-bottom: 2px;"></i>
      </span>
      </a>
    </li>
  </ul>

  <div class="user-menu">

    <div class="avatar">
      <div [ngStyle]="getAvatar()"></div>
    </div>

    <div class="info">
      <div class="name">{{username}}</div>
      <div class="position">{{roleText | translate}}</div>
    </div>

    <ul class="dropdown-menu">
      <li class="user-profile">
        <a
          [routerLink]="[profileUrl]"
          [routerLinkActive]="['active']"
        >
          <i></i>
          {{ 'My Profile' | translate }}
        </a>
      </li>
      <li class="switch-role" *ngIf="roles">
        <div><i></i>{{ 'Switch Role' | translate}}</div>
        <div class="role">
          <ng-container
            *ngFor="let context of contexts"
          >
            <a
              [routerLink]="context.homeUrl"
              [routerLinkActive]="['active']"
            >
              <span>{{context.roleText | translate}}</span>
            </a>
          </ng-container>
        </div>
      </li>
      <li class="logout">
        <a target="_self" (click)="logout()"><i></i>{{ 'Logout' | translate }}
        </a>
      </li>
    </ul>
  </div>

  <ul>
    <li class="navigation-item navigation-item-help">
      <a href="https://help.upcyclea.com/" target="_blank">
            <span class="navigation-item-wrapper">
                <i class="ib-icon" [ngClass]="'faq--icon'"></i>
                <span>{{ 'Help' | translate }}</span>
          </span>
      </a>
    </li>
  </ul>
</nav>

