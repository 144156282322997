import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { Zone } from '../../../shared/models/zone.class';
import { FormBuilder } from '@angular/forms';
import { ZoneService } from '../../../shared/services/zone.service';
@Component({
  moduleId: module.id,
  selector: 'zone-form-dialog',
  styleUrls: ['zone-form-dialog.component.scss'],
  templateUrl: 'zone-form-dialog.component.html'
})
export class ZoneFormDialogComponent implements OnInit {

  zone: Zone;

  constructor(public _dialog: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any,
    private zoneService: ZoneService,) {
  }

  ngOnInit() {
    this.zone = this.mdDialogData.zone;
    this.zone.onSave$.subscribe(() => this._dialog.close());
  }

  save() {
    this.zone.saveZone();
  }

  publish() {
    this.zone.publish();
  }
}
