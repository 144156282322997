import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'passport-family-info',
  templateUrl: 'passport-family-info.component.html'
})
export class PassportFamilyInfoComponent implements OnInit {

    @Input() userRole  = 'AGENT';
    @Input() isShown = true;
    @Output() isShownChange = new EventEmitter<boolean>();
    translationType = 'AGENT';
    constructor() {
    }

    ngOnInit() {
        switch (this.userRole) {
            case 'supervisor':
            case 'sale':
            case 'agent':
                this.translationType = 'AGENT';
                return;
            default:
                this.translationType = 'MANUFACTURER';
                return;
        }
    }

    toggle() {
        this.isShown = !this.isShown;
        this.isShownChange.emit(this.isShown);
    }


}
