<button (click)="toggleConversion()"
[mdTooltip]="getConversionTooltip()"
        [disabled]="isDisabled"
mdTooltipPosition="above"
class="convert-button"
>
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_367_21087)">
        <rect x="1" width="15" height="8" rx="2" fill="white"/>
        <rect [ngClass]="getConversionStatus() ? 'primary-stroke' : 'convert-stroke-deactivated' " x="1.15" y="0.15" width="14.7" height="7.7" rx="1.85" stroke-width="0.3"/>
        </g>
        <path [ngClass]="getConversionStatus() ? 'primary-fill' : 'convert-fill-deactivated' "  d="M2 4L5.75 6.16506V1.83494L2 4ZM15 4L11.25 1.83494V6.16506L15 4ZM5.375 4.375H11.625V3.625H5.375V4.375Z" />
        <defs>
        <filter id="filter0_d_367_21087" x="1" y="0" width="17" height="10" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="1"/>
        <feGaussianBlur stdDeviation="0.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_367_21087"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_367_21087" result="shape"/>
        </filter>
        </defs>
        </svg>
</button>
