import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Client } from '../model/client.class';
import { FileUploader } from 'ng2-file-upload';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';

@Injectable()
export class ClientService extends ExtendedCrudService {
  protected namespace = 'clients';
  protected namespaceSingular = 'client';
  protected ModelClass = Client;
  public relations = ['client_locations', 'client_contacts'];

  getLogoUploader(id: number): FileUploader {
    return new FileUploader({url: this.getEndpoint(this.singularEndpoint + '/' + id + '/logo')});
  }

  getLogoUploadUrl(id: number): string {
    return this.getEndpoint(this.singularEndpoint + '/' + id + '/logo');
  }

  removeLogo(id:number) {
    return this.sendDelete(this.getEndpoint(`${this.namespaceSingular}/${id}/logo`))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  //TODO check location service if this could be made there instead
  removeLocation(clientId: number, locationId: number) {
    return this.sendDelete(this.getEndpoint(this.singularEndpoint + '/' + clientId + '/locations/' + locationId))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  removeContact(clientId: number, contactId: number) {
    return this.sendDelete(this.getEndpoint(this.singularEndpoint + '/' + clientId + '/contacts/' + contactId))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  canDelete(clientId: number) {
    return this.sendGet(this.getEndpoint(this.singularEndpoint + '/' + clientId + '/candelete'))
      .catch(this.onError.bind(this));
  }
}
