import * as tslib_1 from "tslib";
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { CompanyFileService } from '../services/company-file.service';
var CompanyFile = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyFile, _super);
    function CompanyFile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = CompanyFileService;
        return _this;
    }
    CompanyFile.prototype.fields = function () {
        return _super.prototype.fields.call(this).concat([
            'company_id'
        ]);
    };
    return CompanyFile;
}(UploadedDocument));
export { CompanyFile };
