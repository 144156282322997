import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { MdDialog } from '@angular/material';
import { ViewCategoryDialogComponent } from '../../../entities/passport/dialogs/view-category/view-category.component';
import { CategoryItem } from '../../../entities/category/models/category-item.class';

/**
 * `CategorySearchComponent` facilitates category search and selection functionality within the application.
 * It allows users to open a dialog to search for and select categories, as well as to remove selected categories.
 * Utilizes Angular Material's Dialog component to present a user interface for category selection.
 * The component is optimized for performance with Angular's OnPush change detection strategy.
 */
@Component({
  selector: 'app-category-search',
  templateUrl: './category-search.component.html',
  styleUrls: ['./category-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategorySearchComponent {
  @Input() categories: CategoryItem[] = []; // The list of selected categories.
  @Output() categoriesChange = new EventEmitter<CategoryItem[]>(); // Emits an event when the list of selected categories changes.

  constructor(public dialog: MdDialog) { }

  /**
   * Opens a dialog for users to search for and select categories. Adds the selected categories to the existing list
   * and emits an updated list through the `categoriesChange` event.
   */
  openCategoryDialog() {
    const dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
      width: '80%',
      height: '80%'
    });

    // Subscribes to the dialog close event to handle the result.
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.categories = this.categories.concat(result);
        this.categoriesChange.emit(this.categories);
      }
    });
  }

  /**
   * Removes a selected category from the list when the user interacts with the remove button.
   * Prevents the event from propagating to avoid unwanted side effects.
   * @param category The category to be removed.
   * @param event The DOM event triggered by the user interaction.
   */
  removeCategory(category: CategoryItem, event: Event) {
    event.stopPropagation();
    const index = this.categories.indexOf(category);
    if (index >= 0) {
      this.categories = this.categories.filter((_, i) => i !== index);
      this.categoriesChange.emit(this.categories);
    }
  }
}
