
    <img
      src="assets/png/ecosystem/icon--close_s.png"
      class="close-button"
      (click)="mdDialogRef.close()"
    >

    <stakeholder-details
      [id]="mdDialogData.id"
      [readOnly]="mdDialogData.readOnly"
      (saved)="mdDialogRef.close()"
    ></stakeholder-details>
  