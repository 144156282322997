
<div class="statistics">
    <h1>{{'Segment statistics' | translate}}</h1>
    <div class="statistics__box widget--shadow widget--rounded-corners">
        <div class="statistics__header">
            <div class="statistics__tabs">
                <ul>
                    <li
                            *ngFor="let segment of segments"
                            (click)="changeSegment(segment)"
                            [ngClass]="{'active': segment === selectedSegment}"
                    >{{ SALES_STATISTICS_SEGMENTS_VIEW[segment] | translate }}</li>
                </ul>
            </div>
        </div>
        <div class="statistics__body">
            <div class="statistics__total-stats">
                <div class="stat">
                    <span class="value">{{ segmentStats.total }}</span>
                    <span class="title">{{ 'Total' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.published }}</span>
                    <span class="title">{{ 'Published' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.published_pct }}</span>
                    <span class="title">{{ 'Published' | translate }}(%)</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.done }}</span>
                    <span class="title">{{ 'Done' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.done_pct }}</span>
                    <span class="title">{{ 'Done' | translate }}(%)</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.treated }}</span>
                    <span class="title">{{ 'Treated' | translate }}</span>
                </div>
                <div class="stat">
                    <span class="value">{{ segmentStats.treated_pct }}</span>
                    <span class="title">{{ 'Treated' | translate }}(%)</span>
                </div>
            </div>
        </div>
    </div>
</div>
