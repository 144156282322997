/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./base-checkbox.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../shared/read-only/form-elems-read-only.directive";
import * as i4 from "../../../../shared/read-only/read-only.service";
import * as i5 from "@angular/common";
import * as i6 from "./base-checkbox.component";
var styles_BaseCheckboxComponent = [i0.styles];
var RenderType_BaseCheckboxComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BaseCheckboxComponent, data: {} });
export { RenderType_BaseCheckboxComponent as RenderType_BaseCheckboxComponent };
function View_BaseCheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "checkbox-label-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_BaseCheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "checkbox-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "checkbox-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [8, "readOnly", 0]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.isChecked = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.onChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(8, 16384, null, 0, i3.FormElemsReadOnlyDirective, [[2, i4.ReadOnlyService]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaseCheckboxComponent_1)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.isChecked; _ck(_v, 5, 0, currVal_8); var currVal_9 = _co.label; _ck(_v, 10, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; var currVal_7 = ((i1.ɵnov(_v, 8).readOnlyService == null) ? null : i1.ɵnov(_v, 8).readOnlyService.readOnly); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_BaseCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-base-checkbox", [], null, null, null, View_BaseCheckboxComponent_0, RenderType_BaseCheckboxComponent)), i1.ɵdid(1, 49152, null, 0, i6.BaseCheckboxComponent, [], null, null)], null, null); }
var BaseCheckboxComponentNgFactory = i1.ɵccf("app-base-checkbox", i6.BaseCheckboxComponent, View_BaseCheckboxComponent_Host_0, { isChecked: "isChecked", label: "label" }, { isCheckedChange: "isCheckedChange" }, []);
export { BaseCheckboxComponentNgFactory as BaseCheckboxComponentNgFactory };
