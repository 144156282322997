export enum TRANSFORM_ORDER_STATUS {
  STAND_BY = 1,
  WAITING_FOR_VALIDATION = 2,
  ASK_FOR_MODIFICATION = 3,
  PENDING = 4,
  VALIDATED = 5,
  CONFIRMED = 6,
  PROBLEM_REPORTED = 7,
  REJECTED = 8,
  DONE = 9,
  WAITING_FOR_CONFIRMATION = 12,
}

export const TRANSFORM_ORDER_STATUSES: {
  view: string,
  text: string,
  value: number,
}[] = [
  {
    text: 'STAND_BY',
    view: 'Stand by',
    value: 1
  },
  {
    text: 'WAITING_FOR_VALIDATION',
    view: 'Waiting for validation',
    value: 2,
  },
  {
    text: 'ASK_FOR_MODIFICATION',
    view: 'Asked for modification',
    value: 3,
  },
  {
    text: 'PENDING',
    view: 'Booked',
    value: 4,
  },
  {
    text: 'VALIDATED',
    view: 'Validated',
    value: 5,
  },
  {
    text: 'CONFIRMED',
    view: 'Confirmed',
    value: 6,
  },
  {
    text: 'PROBLEM_REPORTED',
    view: 'Not achieved',
    value: 7,
  },
  {
    text: 'REJECTED',
    view: 'Rejected',
    value: 8,
  },
  {
    text: 'CONFIRMED',
    view: 'Confirmed',
    value: 9,
  },
  {
    text: 'EXPIRED',
    view: 'Expired',
    value: 10,
  },
  {
    text: 'WAITING_FOR_CONFIRMATION',
    view: 'Waiting for confirmation',
    value: 12,
  }
];

export function GET_TRANSFORM_ORDER_STEP_STATUS_LABEL(status: TRANSFORM_ORDER_STATUS):string {
  if(!status){
    status = 1;
  }
  let label = TRANSFORM_ORDER_STATUSES.find((item) => item.value === status);
  return label ? label.view : 'Unknown';
}
