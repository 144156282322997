/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../client-detail/client-detail.component.ngfactory";
import * as i2 from "../../client-detail/client-detail.component";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/router";
import * as i5 from "../../service/client.service";
import * as i6 from "../../service/location.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../service/contact.service";
import * as i9 from "./client-page.component";
var styles_ClientPageComponent = [];
var RenderType_ClientPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ClientPageComponent, data: {} });
export { RenderType_ClientPageComponent as RenderType_ClientPageComponent };
export function View_ClientPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "client-detail", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ClientDetailComponent_0, i1.RenderType_ClientDetailComponent)), i0.ɵdid(1, 245760, null, 0, i2.ClientDetailComponent, [i3.FormBuilder, i4.Router, i5.ClientService, i6.LocationService, i7.TranslateService, i8.ContactService], { clientId: [0, "clientId"], asDialog: [1, "asDialog"] }, { onSave: "onSave" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clientId; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ClientPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "client-page", [], null, null, null, View_ClientPageComponent_0, RenderType_ClientPageComponent)), i0.ɵdid(1, 245760, null, 0, i9.ClientPageComponent, [i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientPageComponentNgFactory = i0.ɵccf("client-page", i9.ClientPageComponent, View_ClientPageComponent_Host_0, {}, {}, []);
export { ClientPageComponentNgFactory as ClientPageComponentNgFactory };
