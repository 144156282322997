/**
 * Created by aleksandr on 28.07.17.
 */
import { NgModule } from '@angular/core';
import {
  MdAutocompleteModule,
  MdButtonModule,
  MdCheckboxModule,
  MdChipsModule,
  MdDialogModule,
  MdIconModule,
  MdInputModule,
  MdMenuModule,
  MdOptionModule,
  MdProgressBarModule,
  MdProgressSpinnerModule,
  MdRadioModule,
  MdSelectModule,
  MdSidenavModule,
  MdSliderModule,
  MdSlideToggleModule,
  MdSnackBarModule,
  MdTabsModule,
  MdTooltipModule
} from '@angular/material';

@NgModule({
  exports: [
    MdMenuModule,
    MdButtonModule,
    MdSnackBarModule,
    MdCheckboxModule,
    MdTooltipModule,
    MdTabsModule,
    MdRadioModule,
    MdChipsModule,
    MdInputModule,
    MdIconModule,
    MdSliderModule,
    MdSlideToggleModule,
    MdSidenavModule,
    MdOptionModule,
    MdSelectModule,
    MdAutocompleteModule,
    MdProgressSpinnerModule,
    MdProgressBarModule,
    MdDialogModule,
    MdCheckboxModule,
    MdChipsModule,
    MdInputModule
  ]
})

export class CustomMaterialModule {
}
