import { DepositFileService } from '../services/deposit-file.service';
import { UploadedDocument } from '../../../shared/models/uploaders/document.class';

export class DepositFile extends UploadedDocument {
  protected provider = DepositFileService;

  deposit_file_id: number;

  protected fields() {
    return super.fields().concat([
      'deposit_file_id',
    ]);
  }
}
