import * as tslib_1 from "tslib";
import { ClientLocation } from '../model/location.class';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
var LocationService = /** @class */ (function (_super) {
    tslib_1.__extends(LocationService, _super);
    function LocationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'client-locations';
        _this.namespaceSingular = 'client-location';
        _this.ModelClass = ClientLocation;
        return _this;
    }
    return LocationService;
}(ExtendedCrudService));
export { LocationService };
