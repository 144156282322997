import { EcosystemStep } from "../models/ecosystem-step.class";

export enum EEcosystemIndicatorReportType {
    WASTE,
    CO2_EMISSION,
    WATER_CONSUMPTION,
    ECONOMIC_PROFITS
} 

export interface IEcosystemIndicatorReportSteps {
    virginProducts?: EcosystemStep[],
    deposits?: EcosystemStep[],
    transforms?: EcosystemStep[],
    transports?: EcosystemStep[],
    needs?: EcosystemStep[]
}