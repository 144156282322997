import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TranslateService } from '@ngx-translate/core';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { ManufacturersService } from '../services/manufacturers.service';
var Manufacturers = /** @class */ (function (_super) {
    tslib_1.__extends(Manufacturers, _super);
    function Manufacturers() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = ManufacturersService;
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        return _this;
    }
    Manufacturers.prototype.fields = function () {
        return ['name', 'passport_id', 'id'];
    };
    return Manufacturers;
}(ActiveRecord));
export { Manufacturers };
