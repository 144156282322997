<div>
  <div>
    <carbon-footprint-report
      *ngFor="let reportType of reportTypes"
      [reportType]="reportType"
      [reports]="reports"
      [changeTab]= "changeTab"
    >
    </carbon-footprint-report>
  </div>
</div>
