<article class="ecosystem-dashboard  type--simple" test-id="superadmin-dashboard">
  <main class="ecosystem-dashboard__content">
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--counters">
      <ul class="ecosystem-dashboard__counter-list">
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary">{{nomenclatureChangeRequests}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'New nomenclature change requests' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary ">{{companies}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Registered companies' | translate}}</span>
        </li>
        <li class="ecosystem-dashboard__counter-item">
          <div class="ecosystem-dashboard__counter-value theme-color--primary ">{{usersRegistered}}</div>
          <span class="ecosystem-dashboard__counter-title">{{'Registered users' | translate}}</span>
        </li>
      </ul>
      <span class="ecosystem-dashboard__download-raw-report">
        <button class="download-raw-report" (click)="openRawReportDownloader()">{{'Download raw report' | translate}}</button>
      </span>
    </section>
    <!--<section class="ecosystem-dashboard__section ecosystem-dashboard__section&#45;&#45;auto">-->
      <!--<ul class="ecosystem-catalog__content-list  widget&#45;&#45;shadow widget&#45;&#45;rounded-corners">-->
        <!--<li class="ecosystem-catalog__content-item">-->
          <!--<superadmin-changes-catalog></superadmin-changes-catalog>-->
        <!--</li>-->
      <!--</ul>-->
    <!--</section>-->
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--auto">
      <ul class="ecosystem-catalog__content-list  widget--shadow widget--rounded-corners">
        <li class="ecosystem-catalog__content-item">
          <ranking-formula></ranking-formula>
        </li>
      </ul>
    </section>
    <section class="ecosystem-dashboard__section ecosystem-dashboard__section--auto">
      <ul class="ecosystem-catalog__content-list  widget--shadow widget--rounded-corners">
        <li class="ecosystem-catalog__content-item">
          <calculation-variables></calculation-variables>
        </li>
      </ul>
    </section>
  </main>
</article>
