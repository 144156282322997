import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
var EcosystemProcessCountService = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemProcessCountService, _super);
    function EcosystemProcessCountService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'ecosystem/process_count';
        _this.ModelClass = String;
        return _this;
    }
    EcosystemProcessCountService.prototype.getData = function () {
        var _this = this;
        return this
            .getResponse()
            .map(function (res) { return _this.buildModel(res.json()); });
    };
    return EcosystemProcessCountService;
}(CrudService));
export { EcosystemProcessCountService };
