import { MdDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { EAskForRating } from './passport-rating-explanation-dialog.consts';
import { proceedIfNonNegativeInt, pasteOnlyIfNonNegativeInt } from '../../../../shared/helpers/proceed-if-non-negative-int';
var PassportRatingExplanationDialogComponent = /** @class */ (function () {
    function PassportRatingExplanationDialogComponent(mdDialogData, mdDialogRef, _router, translate) {
        this.mdDialogData = mdDialogData;
        this.mdDialogRef = mdDialogRef;
        this._router = _router;
        this.translate = translate;
        this.tokenFormControl = new FormControl('', [Validators.pattern(/^\d{8,10}$/), Validators.required]);
        this.proceedIfNonNegativeInt = proceedIfNonNegativeInt;
        this.pasteOnlyIfNonNegativeInt = pasteOnlyIfNonNegativeInt;
        this.askForRating = EAskForRating;
        this.PASSPORT_RATING_EXPLANATION_CARDS = [
            { iconClass: 'composition', label: 'Non-toxicity', content: 'PASSPORT_RATING_EXPLANATION.TOXICITY.CONTENT' },
            { iconClass: 'cycle', label: 'Circularity', content: 'PASSPORT_RATING_EXPLANATION.CIRCULARITY.CONTENT' },
            { iconClass: 'energy', label: 'Energy & Carbon Management', content: 'PASSPORT_RATING_EXPLANATION.ENERGY.CONTENT' },
            { iconClass: 'water', label: 'Water management', content: 'PASSPORT_RATING_EXPLANATION.WATER.CONTENT' },
            { iconClass: 'social', label: 'Social', content: 'PASSPORT_RATING_EXPLANATION.SOCIAL.CONTENT' },
            { iconClass: 'c2c-certification', label: 'C2C certification', content: 'PASSPORT_RATING_EXPLANATION.C2C.CONTENT' },
        ];
        this.askForRatingOption = null;
        this.isActionButtonsShown = this.mdDialogData.isActionButtonsShown;
    }
    PassportRatingExplanationDialogComponent.prototype.getExplanationCards = function () {
        return this.PASSPORT_RATING_EXPLANATION_CARDS;
    };
    PassportRatingExplanationDialogComponent.prototype.getIconClass = function (iconClass, cardClass) {
        return cardClass + '-' + iconClass;
    };
    PassportRatingExplanationDialogComponent.prototype.applyPrimaryColor = function (translateConst, isListItem) {
        var translation = this.translate.instant(translateConst);
        return isListItem ? "<span class=\"primary-color passport-rating-explanation__list-item\"><strong>" + translation + "</strong></span>"
            : "<span class=\"primary-color\"><strong>" + translation + "</strong></span>";
    };
    PassportRatingExplanationDialogComponent.prototype.getCardContent = function (content) {
        switch (content) {
            case 'PASSPORT_RATING_EXPLANATION.C2C.CONTENT':
                return this.translate.instant(content, {
                    X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.C2C.ITEM.CRADLE'),
                    Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.C2C.ITEM.CERTIFICATE')
                });
            case 'PASSPORT_RATING_EXPLANATION.SOCIAL.CONTENT':
                return this.translate.instant(content, {
                    X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.SOCIAL.ITEM.CONFIRMATION'),
                    Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.SOCIAL.ITEM.CHECKBOX')
                });
            case 'PASSPORT_RATING_EXPLANATION.WATER.CONTENT':
                return this.translate.instant(content, {
                    X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.WATER.ITEM.CONFIRMATION'),
                    Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.WATER.ITEM.CHECKBOX')
                });
            case 'PASSPORT_RATING_EXPLANATION.TOXICITY.CONTENT':
                return this.translate.instant(content, {
                    X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.TOXICITY.ITEM.BANNED_LIST'),
                    Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.TOXICITY.ITEM.COMMIT'),
                });
            case 'PASSPORT_RATING_EXPLANATION.CIRCULARITY.CONTENT':
                return this.translate.instant(content, {
                    X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.PORTION_OF_MATERIALS', true),
                    Y: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.OPTIMIZATION_STRATEGY'),
                    Z: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.SHARE', true),
                    V: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.FUTURE', true),
                    W: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.CIRCULARITY.ITEM.FUTURE_USE', true),
                });
            case 'PASSPORT_RATING_EXPLANATION.ENERGY.CONTENT':
                return this.translate.instant(content, {
                    V: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.ENERGY.ITEM.CARBON_FOOTPRINT'),
                    W: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.ENERGY.ITEM.RAW_MATERIAL', true),
                    X: this.applyPrimaryColor('PASSPORT_RATING_EXPLANATION.ENERGY.ITEM.RENEWABLE', true),
                });
        }
    };
    PassportRatingExplanationDialogComponent.prototype.isValidRatingToken = function () {
        return this.tokenFormControl.status === 'VALID';
    };
    Object.defineProperty(PassportRatingExplanationDialogComponent.prototype, "isAskForRatingDisabled", {
        get: function () {
            return !(this.askForRatingOption && (this.askForRatingOption === EAskForRating.SUBSCRIPTION
                || (this.askForRatingOption === EAskForRating.TOKEN && this.isValidRatingToken())));
        },
        enumerable: true,
        configurable: true
    });
    PassportRatingExplanationDialogComponent.prototype.onAskForARating = function () {
        this.askForRatingOption === EAskForRating.TOKEN
            ? this.mdDialogData.onAskForARating(this.tokenFormControl.value)
            : this.mdDialogData.onAskForARating();
    };
    return PassportRatingExplanationDialogComponent;
}());
export { PassportRatingExplanationDialogComponent };
