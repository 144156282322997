import { ElementRef } from '@angular/core';

export function setTextareaHeightToFitContent( els: ElementRef[]) {
    els.forEach(el => {
        if (el && el.nativeElement && el.nativeElement instanceof HTMLTextAreaElement) {
            const textarea = (el.nativeElement as HTMLTextAreaElement);
            textarea.style.height = 'auto';
            const height = textarea.scrollHeight;
            if (height) {
              textarea.style.height = `${height}px`;
            }
        }
      }
    );
}
