/**
 * Created by Aleksandr C. on 8.02.17.
 */
import * as tslib_1 from "tslib";
import { Observable } from 'rxjs/Observable';
import { Passport } from '../../passport/models/passport.class';
import { Client } from '../../client/model/client.class';
import { ClientLocation } from '../../client/model/location.class';
import { NeedService } from '../services/need.service';
import { Contact } from '../../client/model/contact.class';
import { NEED_STATUSES, ENeedStatusesView } from '../values/need-statuses.const';
import { DatePipe } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
import { ClientService } from '../../client/service/client.service';
import { ServiceLocator } from '../../../shared/services/service-locator';
import * as _ from 'lodash';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import { DATE_PRECISION } from '../values/date-precision.const';
import { QUANTITY_PRECISION } from '../values/quantity-precision.const';
import { NeedFile } from './need-file.class';
import { Ecosystem } from '../../../dashboards/ecosystem-manager/models/ecosystem.class';
import { EcosystemOffer } from '../../ecosystem-offer/ecosystem-offer.model';
import { ValidationService } from '../../../shared/services/validation-service';
import { TranslateService } from '@ngx-translate/core';
import { NEED_DEPENDENCY } from '../../../dashboards/sales/values/sale-dependency.const';
import { CurrentUser } from '../../../shared/auth/current-user.class';
import { EDatePrecision } from '../values/date-precision.const';
import { isArray } from 'lodash';
import { Deposit } from '../../../entities/deposit/models/deposit.class';
var Need = /** @class */ (function (_super) {
    tslib_1.__extends(Need, _super);
    function Need(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.provider = NeedService;
        _this.clientService = ServiceLocator.injector.get(ClientService);
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        _this.plumb_type = ECOSYSTEM_STEP_TYPE.NEED;
        _this.onChangeClient = new EventEmitter();
        _this.onChangePassport = new EventEmitter();
        _this.onChangeContact = new EventEmitter();
        _this.onChangeLocation = new EventEmitter();
        return _this;
    }
    Object.defineProperty(Need.prototype, "date_precision", {
        get: function () {
            return this._date_precision;
        },
        set: function (newValue) {
            if (newValue === EDatePrecision.UNKNOWN || newValue === EDatePrecision.RECURRENT) {
                this.delivery_date = null;
            }
            this._date_precision = newValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Need.prototype, "datePrecisionViewValue", {
        get: function () {
            var _this = this;
            var datePres = DATE_PRECISION.find(function (i) { return i.key === _this._date_precision; });
            return datePres ? datePres.value : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Need.prototype, "quantityTypeCasted", {
        get: function () {
            return ValidationService.normalizeQuantity(this.quantity);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Need.prototype, "quantity_precision", {
        get: function () {
            return this._quantity_precision;
        },
        set: function (newValue) {
            if (newValue === 0) {
                this.quantity = null;
            }
            this._quantity_precision = newValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Need.prototype, "quantity_text", {
        get: function () {
            if (this.quantity === null) {
                return this.translateService.instant('unknown');
            }
            else {
                return this.quantity.toString();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Need.prototype, "statusData", {
        get: function () {
            var _this = this;
            return Object
                .values(NEED_STATUSES)
                .find(function (item) { return item.value === _this.status; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Need.prototype, "isDeliveryDateRequired", {
        get: function () {
            return !this.delivery_date && this.date_precision !== EDatePrecision.RECURRENT
                && this.date_precision !== EDatePrecision.UNKNOWN;
        },
        enumerable: true,
        configurable: true
    });
    Need.prototype.fields = function () {
        return [
            'id',
            'name',
            'status',
            'user_id',
            'client_id',
            'client',
            'passport_id',
            'passport',
            'client_contact_id',
            'client_contact',
            'client_location_id',
            'client_location',
            'quantity',
            'quantity_precision',
            '_quantity_precision',
            'satisfying_quantity',
            'delivery_date',
            'date_precision',
            '_date_precision',
            'description',
            'location_comment',
            'created_at',
            'updated_at',
            'nomenclature',
            'files',
            'passport_materials',
            'passport_components',
            'passport_price',
            'ecosystems',
            'ecosystem_count',
            'sales_contact',
            'sales_name',
            'sales_email',
            'modification',
            'esm_name',
            'esm_id',
            'sales_avatar',
            'offer',
            'independent',
            'sales_user',
            'treatable',
            'display_status',
            'deposits',
            'from_deposit',
            'fromDepositEntity',
            'match_deposits',
            'from_noah'
        ];
    };
    Need.prototype.dropClient = function () {
        this.client = new Client();
        this.client_id = null;
    };
    Need.prototype.setClient = function (value) {
        if (value instanceof Client) {
            this.client = value;
            this.client_id = value.id;
        }
        else {
            this.client = new Client();
            this.client_id = null;
        }
        this.onChangeClient.emit(this.client);
    };
    Need.prototype.setLocation = function (value) {
        if (value instanceof ClientLocation) {
            this.client_location = value;
            this.client_location_id = value.id;
        }
        else {
            this.client_location = new ClientLocation();
            this.client_location_id = null;
        }
        this.onChangeLocation.emit(this.client_location);
    };
    Need.prototype.setPassport = function (value) {
        if (value instanceof Passport) {
            this.passport = value;
            this.passport_id = value.id;
        }
        else {
            this.passport = new Passport();
            this.passport_id = null;
        }
    };
    Need.prototype.setClientContact = function (value) {
        if (value instanceof Contact) {
            this.client_contact = value;
            this.client_contact_id = value.id;
        }
        else {
            this.client_contact = null;
            this.client_contact_id = null;
        }
    };
    Need.prototype.getContact = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (!_this.client_contact_id) {
                observer.next(null);
            }
            else if (_this.client_contact && _this.client_contact.id === _this.client_contact_id) {
                observer.next(_this.client_contact);
            }
            else {
                _this.client_contact = new Contact({ id: _this.client_contact_id });
                _this.client_contact.load().subscribe(function (res) {
                    _this.client_contact.set(res);
                    observer.next(_this.client_contact);
                });
            }
        });
    };
    Need.prototype.getClient = function () {
        var _this = this;
        if (!this.client_id) {
            return Observable.of(null);
        }
        else if (this.client && this.client.id === this.client_id) {
            return this.client.getContacts().map(function (res) { return _this.client; });
        }
        else {
            this.client = new Client({ id: this.client_id });
            return this
                .client
                .load({ expand: this.clientService.relations.join(',') })
                .map(function (res) { return _this.client.getContacts(); })
                .map(function (res) { return _this.client; });
        }
    };
    Need.prototype.getProgress = function () {
        var disableValue = this.translateService.instant('unknown');
        if (this.client_id > 0 && this.client_contact_id > 0 && this.name) {
            if (this.passport_id > 0) {
                if (this.client_location_id > 0 &&
                    (!this.isDeliveryDateRequired) &&
                    (this.quantityTypeCasted > 0 || this.quantity_precision_text === disableValue)) {
                    return 3;
                }
                return 2;
            }
            return 1;
        }
        return 0;
    };
    Need.prototype.getValidationErrors = function () {
        var disableValue = this.translateService.instant('unknown');
        var disableDate = this.translateService.instant('recurrent');
        var errors = [];
        if (!this.name) {
            errors.push({ type: 'name', text: 'Need name is required', progressStep: 0 });
        }
        if (!(this.client_id > 0)) {
            errors.push({ type: 'client_id', text: 'Recipient must be set', progressStep: 0 });
        }
        if (!(this.client_contact_id > 0)) {
            errors.push({ type: 'client_contact_id', text: 'Recipient contact must be set', progressStep: 0 });
        }
        if (!(this.passport_id > 0)) {
            errors.push({ type: 'passport_id', text: 'Passport must be set', progressStep: 1 });
        }
        if (!(this.client_location_id > 0)) {
            errors.push({ type: 'client_location_id', text: 'Delivery location is required', progressStep: 2 });
        }
        if (this.isDeliveryDateRequired) {
            errors.push({ type: 'delivery_date', text: 'Delivery date is required', progressStep: 2 });
        }
        if (!(this.quantityTypeCasted > 0) && this.quantity_precision_text !== disableValue) {
            errors.push({ type: 'quantity', text: 'Quantity must be greater than 0', progressStep: 2 });
        }
        return errors;
    };
    Object.defineProperty(Need.prototype, "status_data", {
        get: function () {
            var _this = this;
            return _.values(NEED_STATUSES).find(function (STATUS) { return STATUS.value === _this.status; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Need.prototype, "date_precision_text", {
        get: function () {
            var _this = this;
            var found = DATE_PRECISION.find(function (item) { return item.key === _this.date_precision; });
            return found ? this.translateService.instant(found.value) : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Need.prototype, "quantity_precision_text", {
        get: function () {
            var _this = this;
            var found = QUANTITY_PRECISION.find(function (item) { return item.key === _this.quantity_precision; });
            return found ? this.translateService.instant(found.value) : '';
        },
        enumerable: true,
        configurable: true
    });
    Need.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        if (!this.client || json.client)
            json.client = new Client(json.client);
        if (!this.client_location || json.client_location) {
            json.client_location = new ClientLocation(json.client_location);
        }
        if (!this.passport || json.passport) {
            Object.assign(json.passport || {}, {
                components: json.passport_components,
                materials: json.passport_materials,
                nomenclature: json.nomenclature,
                price: json.passport_price,
            });
            json.passport = new Passport(json.passport);
        }
        if (!this.client_contact || json.client_contact)
            json.client_contact = new Contact(json.client_contact);
        if (!this.nomenclature || json.nomenclature) {
            var array = [];
            if (json.nomenclature) {
                json.nomenclature.map(function (item) { return new NomenclatureItem((item)); });
            }
        }
        json.files = this.files || (json.files || []).map(function (d) { return new NeedFile(d); });
        json.ecosystems = this.ecosystems || (json.ecosystems || []).map(function (d) { return new Ecosystem(d); });
        if (json.match_deposits && json.match_deposits.data) {
            json.match_deposits.data = (json.match_deposits.data || []).map(function (rawDeposit) { return new Deposit(rawDeposit); });
        }
        if (json.offer) {
            if (isArray(json.offer)) {
                json.offer = json.offer.map(function (needOffer) {
                    needOffer.need = null;
                    return new EcosystemOffer(needOffer);
                });
            }
            else {
                json.offer.need = null;
                json.offer = [new EcosystemOffer(json.offer)];
            }
        }
        return json;
    };
    Need.prototype.hasUnEditableStatus = function () {
        var status = [
            NEED_STATUSES.REFUSED.value,
            NEED_STATUSES.REJECTED.value,
            NEED_STATUSES.DONE.value
        ];
        return status.includes(this.status);
    };
    Need.prototype.getViewStatus = function () {
        if ([ENeedStatusesView.SUSPENDED,
            ENeedStatusesView.EXPIRED,
            ENeedStatusesView.REFUSED,
            ENeedStatusesView.REJECTED,
            ENeedStatusesView.DONE].includes(this.status)) {
            return ENeedStatusesView.ASK_FOR_MODIFICATION;
        }
        if ([ENeedStatusesView.DRAFT,
            ENeedStatusesView.WAITING_FOR_PUBLICATION,
            ENeedStatusesView.ASK_FOR_MODIFICATION,
            ENeedStatusesView.PUBLISHED].includes(this.status)) {
            return ENeedStatusesView.DRAFT;
        }
        if ([ENeedStatusesView.TREATED,
            ENeedStatusesView.WAITING_FOR_VALIDATION,
            ENeedStatusesView.IN_PROGRESS,
            ENeedStatusesView.WAITING_FOR_CONFIRMATION,
            ENeedStatusesView.WAITING_FOR_QUOTE].includes(this.status)) {
            return ENeedStatusesView.WAITING_FOR_PUBLICATION;
        }
        return ENeedStatusesView.CREATED;
    };
    Need.prototype.includesText = function (searchText) {
        searchText = searchText.toLocaleLowerCase();
        var doesNameInclude = _.includes(this.name.toLocaleLowerCase(), searchText);
        var doesClientNameInclude = this.client && this.client.name && _.includes(this.client.name.toLocaleLowerCase(), searchText);
        var doesClientContactNameInclude = this.client_contact && this.client_contact.name && _.includes(this.client_contact.name.toLocaleLowerCase(), searchText);
        var doesPassportNameInclude = this.passport && this.passport.name && _.includes(this.passport.name.toLocaleLowerCase(), searchText);
        var doesClientLocationNameInclude = this.client_location && this.client_location.name && _.includes(this.client_location.name.toLocaleLowerCase(), searchText);
        var isInNomenclature = this.passport_id && this.nomenclature.some(function (item) { return item.name.toLocaleLowerCase() === searchText; });
        return doesNameInclude ||
            doesClientNameInclude ||
            doesClientContactNameInclude ||
            doesPassportNameInclude ||
            doesClientLocationNameInclude ||
            isInNomenclature;
    };
    Need.prototype.beforeSave = function (data) {
        var dataValues = _super.prototype.beforeSave.call(this, data);
        if (dataValues.delivery_date) {
            try {
                dataValues.delivery_date = new DatePipe('en-Us').transform(dataValues.delivery_date, 'yyyy-MM-dd');
            }
            catch (e) {
                console.warn(e);
            }
        }
        if (dataValues._date_precision !== null) {
            dataValues.date_precision = dataValues._date_precision;
        }
        if (dataValues._quantity_precision !== null) {
            dataValues.quantity_precision = dataValues._quantity_precision;
        }
        if (dataValues.quantity) {
            dataValues.quantity = this.quantityTypeCasted;
        }
        return dataValues;
    };
    Need.prototype.isPublic = function () {
        return this.independent == NEED_DEPENDENCY.PUBLIC;
    };
    Need.prototype.isOwnedByCurrentcompany = function () {
        return this.sales_user.company.id == CurrentUser.infoData.company_id;
    };
    return Need;
}(ExtendedActiveRecord));
export { Need };
