import * as tslib_1 from "tslib";
/**
 * Created by aleksandr on 29.08.17.
 */
import { OnceBeforeRouteActivatedHook } from '../adn-common/once-before-route-activated.guard';
import { Observable } from 'rxjs/Observable';
import { CurrentUserService } from '../services/current-user.service';
import { ChatService } from '../chat/chat.service';
import { DeepStreamService } from '../services/deepstream.service';
import { ServiceLocator } from '../services/service-locator';
var DeepStreamInitHook = /** @class */ (function (_super) {
    tslib_1.__extends(DeepStreamInitHook, _super);
    function DeepStreamInitHook(
    // private _userService: CurrentUserService,
    //private _chatService: ChatService,
    // private _deepStreamService: DeepStreamService
    ) {
        var _this = _super.call(this) || this;
        _this._userService = ServiceLocator.injector.get(CurrentUserService);
        _this._chatService = ServiceLocator.injector.get(ChatService);
        _this._deepStreamService = ServiceLocator.injector.get(DeepStreamService);
        console.log('Construct DeepStreamInitHook');
        return _this;
    }
    DeepStreamInitHook.prototype.beforeRouteActivated = function (route, state) {
        var _this = this;
        console.log('Launch DeepStreamInitHook');
        return this
            ._userService
            .loggedInChecked$
            .map(function (isChecked) { return _this._userService.isLoggedIn; })
            .do(function (isLoggedIn) { return isLoggedIn ? _this.setupDeepstream().subscribe(function () { }, function (err) { return console.log('Error while try ro connect deepstream', err); }) : null; });
    };
    DeepStreamInitHook.prototype.setupDeepstream = function () {
        var _this = this;
        return Observable.create(function (observer) {
            console.log('DeepStreamInitHook -> Chat', _this._userService.infoData);
            var info = _this._userService.infoData;
            _this._chatService.setOwnerId(info.id);
            if (info.avatar_url) {
                _this._chatService.setOwnerAvatar(info.avatar_url);
            }
            else {
                _this._chatService.setOwnerAvatar('../assets/png/profile.png');
            }
            var timeout = setTimeout(function () {
                observer.error('Failed to connect to the deepstream');
                observer.complete();
            }, 4000);
            if (!!info.username) {
                console.log('DeepStreamInitHook -> Chat -> try to login');
                _this._deepStreamService.login(null, function (success, data) {
                    console.log('DeepStreamInitHook -> Chat -> login', success, data);
                    if (success) {
                        _this._deepStreamService.subscribeChannel(info.username);
                        _this._chatService.connectToStream();
                    }
                    clearTimeout(timeout);
                    observer.next(null);
                    observer.complete();
                });
            }
        });
    };
    return DeepStreamInitHook;
}(OnceBeforeRouteActivatedHook));
export { DeepStreamInitHook };
