import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformConditionService } from '../services/transform-condition.service';

export class TransformCondition extends ActiveRecord {
  protected provider = TransformConditionService;

  public id: number;
  public description: string;
  public transformation_id: number;

  protected fields() {
    return [
      'id',
      'description',
      'transformation_id',
    ];
  }

}
