import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformQualityJunction } from '../models/transform-quality-junction.class';
var TransformQualityJunctionService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformQualityJunctionService, _super);
    function TransformQualityJunctionService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-qualities-junctions';
        _this.namespaceSingular = 'transformation-qualities-junction';
        _this.ModelClass = TransformQualityJunction;
        return _this;
    }
    return TransformQualityJunctionService;
}(CrudService));
export { TransformQualityJunctionService };
