import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import { TRANSFORM_TYPES } from '../../values/transform-types.const';
import { ActivatedRoute, Router } from '@angular/router';
import { Transform } from '../../../../entities/transformation/models/transform.class';
import { EntityViewerComponent } from '../../../../shared/base/enitity-viewer.component';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { TRANSFORM_DEPENDENCY } from '../../values/transform-dependency.const';
import { ModuleNames } from '../../../../shared/values/module-names.const';
import { TransformType } from '../../../../entities/transformation/models/transform-type.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'transform-edit',
  templateUrl: 'transform-edit.component.html',
  styleUrls: ['transform-edit.component.scss']
})
export class TransformEditComponent extends EntityViewerComponent<Transform> implements OnInit, OnDestroy {

  type: number;
  id: number;
  step: number;
  transformation: Transform;
  parentModule: string;
  TRANSFORM_TYPES = TRANSFORM_TYPES;
  constructor(protected route: ActivatedRoute,
              protected _navigationService: NavigationService,
              protected router: Router,
              private _translate: TranslateService,
              private ngZone: NgZone) {
    super(route, router);
    this.parentModule = this._navigationService.getModuleName();
  }


  onExistEntity(entity: Transform): void {
    this.transformation = entity;
    this.step = 1;
    this.id = this.transformation.id;
  }

  onNewEntity(): void {
    this.step = 0;
  }


  goToProcessStep() {
    let dependency_type = TRANSFORM_DEPENDENCY.COMPANY;
    if (this.parentModule === ModuleNames.TSMI) {
      dependency_type = TRANSFORM_DEPENDENCY.INDEPENDENT;
    }
    new Transform({
      transformation_type: this.type,
      independent: dependency_type
    }).save().subscribe((res) => {
      this.ngZone.run(() => this.router.navigate([this.parentModule, 'transformations', res.id]));
    });
  }

  onAction() {
    this.router.navigate([this.parentModule]);
  }
  getTypeView(type: string): string {
      return this._translate.instant(type)
      .replace('/', '\n/').toUpperCase();
  }
  getImageClass(): string {
    let className = 'transform-edit__item-img';
    if (this.parentModule === ModuleNames.TSMI) {
      className += ` ${className}--tsmi`;
    }
    return className;
  }
}
