/**
 * Created by aleksandr on 17.07.17.
 */
import { EventEmitter, NgZone } from '@angular/core';
import { Config } from '../config/env.config';
import { ServiceLocator } from './service-locator';
import { DepositImportDeepStream } from './deepstream/deposit-deepstream.service';
import { ZoneDeepStream } from './deepstream/zone-deepstream.service';
import { ReportImportDeepStream } from './deepstream/report-deepstream.service';
import { DepositLocationDeepstreamService } from './deepstream/deposit-location-deepstream.service';
import { ReportPdfDeepStream } from './deepstream/report-pdf-deepstream.service';
var DeepStreamService = /** @class */ (function () {
    function DeepStreamService() {
        this.build();
    }
    Object.defineProperty(DeepStreamService.prototype, "stream$", {
        get: function () {
            return DeepStreamService._stream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeepStreamService.prototype, "depositImportDeepStream$", {
        get: function () {
            return DeepStreamService._depositImportDeepStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeepStreamService.prototype, "reportImportDeepStream$", {
        get: function () {
            return DeepStreamService._reportImportDeepStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeepStreamService.prototype, "zoneDeepStream$", {
        get: function () {
            return DeepStreamService._zoneDeepStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeepStreamService.prototype, "depositLocationDeepStream$", {
        get: function () {
            return DeepStreamService._depositLocationDeepStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeepStreamService.prototype, "reportPdfDeepStream$", {
        get: function () {
            return DeepStreamService._reportPdfDeepStream;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeepStreamService.prototype, "messages$", {
        get: function () {
            return DeepStreamService
                ._stream$
                .asObservable()
                .filter(function (record) { return record.target === DeepStreamService.channel; })
                .map(function (record) { return record.data; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeepStreamService.prototype, "dsInstance", {
        get: function () {
            return DeepStreamService._dsInstance;
        },
        set: function (value) {
            DeepStreamService._dsInstance = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeepStreamService.prototype, "ds", {
        get: function () {
            return DeepStreamService._ds;
        },
        set: function (value) {
            DeepStreamService._ds = value;
        },
        enumerable: true,
        configurable: true
    });
    DeepStreamService.prototype.build = function () {
        var _this = this;
        var ngZone = ServiceLocator.injector.get(NgZone);
        ngZone.runOutsideAngular(function () {
            _this.ds = _this.dsInstance = null;
            console.log("DEEPSTREAM API is", Config.DEEPSTREAM_API);
            console.log("deepstream is", deepstream(Config.DEEPSTREAM_API));
            _this.ds = _this.dsInstance = deepstream(Config.DEEPSTREAM_API);
            console.log("Error?");
            _this.ds.on('error', function (error, event, topic) {
                console.log('Deepstream ERROR IS', error, event, topic);
            });
        });
    };
    DeepStreamService.prototype.canCloseConnection = function () {
        return this.ds && this.ds.getConnectionState() !== 'CLOSED';
    };
    DeepStreamService.prototype.logout = function () {
        console.log('Deepstream close STATE IS', this.ds.getConnectionState());
        if (this.canCloseConnection()) {
            this.ds.close();
            //this.ds = null;
        }
        //this.build();
    };
    DeepStreamService.prototype.login = function (credentials, loginHandler) {
        // {username: 'chris', password:'password'}
        this.ds.login(credentials, loginHandler);
    };
    DeepStreamService.prototype.getRecord = function (name) {
        return this.ds.record.getRecord(name);
    };
    DeepStreamService.prototype.getList = function (name) {
        return this.ds.record.getList(name);
    };
    DeepStreamService.prototype.getDeepStreamServices = function () {
        return [
            DeepStreamService._depositImportDeepStream,
            DeepStreamService._zoneDeepStream,
            DeepStreamService._reportImportDeepStream,
            DeepStreamService._depositLocationDeepStream,
            DeepStreamService._reportPdfDeepStream
        ];
    };
    DeepStreamService.prototype.subscribeChannel = function (username) {
        var _this = this;
        console.log('subscribeChannel', 'message/' + username);
        DeepStreamService.channel = username;
        var deepStreamServices = this.getDeepStreamServices();
        deepStreamServices.forEach(function (service) { return service.receiver = username; });
        this.ds.event.subscribe('message/' + username, function (data) {
            // handle published data
            console.log('DeepStreamService -> MESSAGE:', data);
            var foundService = deepStreamServices.find(function (service) { return service.isMessageTypeIncluded(data.type); });
            if (!!foundService) {
                foundService.emit(data);
                return;
            }
            _this.stream$.emit({ target: username, data: data });
        });
    };
    DeepStreamService.prototype.listenMessages = function (predicate) {
        return this.messages$.filter(predicate);
    };
    DeepStreamService._stream$ = new EventEmitter();
    DeepStreamService._depositImportDeepStream = new DepositImportDeepStream();
    DeepStreamService._reportImportDeepStream = new ReportImportDeepStream();
    DeepStreamService._zoneDeepStream = new ZoneDeepStream();
    DeepStreamService._depositLocationDeepStream = new DepositLocationDeepstreamService();
    DeepStreamService._reportPdfDeepStream = new ReportPdfDeepStream();
    return DeepStreamService;
}());
export { DeepStreamService };
