import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformServiceArea } from '../models/transform-service-area.class';
var TransformServiceAreaService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformServiceAreaService, _super);
    function TransformServiceAreaService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-sectors';
        _this.ModelClass = TransformServiceArea;
        return _this;
    }
    return TransformServiceAreaService;
}(CrudService));
export { TransformServiceAreaService };
