import { ActiveRecord } from '../../../shared/models/active-record.class';
import { NomenclatureService } from '../service/nomenclature.service';
import {TranslateService} from "@ngx-translate/core";
import { ServiceLocator } from '../../../shared/services/service-locator';

export class NomenclatureItem extends ActiveRecord {

  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);

  protected provider: any = NomenclatureService;
  id: number;
  name: string;
  parent_id: number;
  depth: number;
  selectable: boolean;
  has_custom: boolean;
  in_view: boolean;
  search_count: number;
  children: NomenclatureItem[];
  expanded: boolean = false;

  fields() {
    return ['id', 'name', 'parent_id', 'depth', 'selectable', 'has_custom', 'children'];
  }

  extractData(data: any): any {
    let json = super.extractData(data);
    if(json.name){
      if(!this.translateService){
      this.translateService= ServiceLocator.injector.get(TranslateService);
      }
      json.name=this.translateService.instant(json.name);
     }

    if (json.children) {
      let array: NomenclatureItem[] = [];
      for (let child of json.children) {
        let newchild = new NomenclatureItem(child);
        array.push(newchild);
      }
      json.children = array;
    }
    return json;
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
