import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-create-button',
  templateUrl: './create-button.component.html',
  styleUrls: ['./create-button.component.scss']
})
export class CreateButtonComponent {

  @Input() label: string;
  @Output() create = new EventEmitter<void>();

  constructor() {
  }

  onCreate(): void {
    this.create.emit();
  }
}
