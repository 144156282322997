import * as tslib_1 from "tslib";
var _a;
import { OrganisationTIds } from '../../shared/values/company-values.enum';
import { ADMIN_ESM_OPTIONS, ERoleIds } from '../../shared/values/role-ids.enum';
import { EAdminPermission } from './values/admin-permission.enum';
import { AbstractPermission } from '../../shared/models/abstract-permission.class';
var BuildingPermission = /** @class */ (function (_super) {
    tslib_1.__extends(BuildingPermission, _super);
    function BuildingPermission() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = 'Building admin';
        return _this;
    }
    BuildingPermission.prototype.isValid = function (user) {
        return user.company.organisation_type === OrganisationTIds.Building
            && user.plan_id !== ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN;
    };
    BuildingPermission.id = EAdminPermission.BUILDING_ADMIN;
    return BuildingPermission;
}(AbstractPermission));
export { BuildingPermission };
var RestrictedAdminPermission = /** @class */ (function (_super) {
    tslib_1.__extends(RestrictedAdminPermission, _super);
    function RestrictedAdminPermission() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = 'Restricted admin';
        return _this;
    }
    RestrictedAdminPermission.prototype.isValid = function (user) {
        return user.plan_id === ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN;
    };
    RestrictedAdminPermission.id = EAdminPermission.RESTRICTED_ADMIN;
    return RestrictedAdminPermission;
}(AbstractPermission));
export { RestrictedAdminPermission };
export var ADMIN_PERMISSIONS = {
    roleId: ERoleIds.ADMIN,
    permissions: (_a = {},
        _a[RestrictedAdminPermission.id] = new RestrictedAdminPermission(),
        _a[BuildingPermission.id] = new BuildingPermission(),
        _a),
    defaultPermissionsIdsBefore: [RestrictedAdminPermission.id],
    defaultPermissionsIdsAfter: [BuildingPermission.id]
};
