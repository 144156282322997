import { SortMode } from '../models/sort-mode.class';
export const SORT_MODES: {
  NAME: SortMode,
  DATE_OF_CREATION: SortMode,
  DATE_OF_DELIVERY: SortMode,
} = {
  NAME: {
    view: 'name',
    comparator: (a: { name: string }, b: { name: string }) => {
      return (a.name || '').localeCompare(b.name || '');
    }
  },
  DATE_OF_CREATION: {
    view: 'date of creation',
    comparator: (a: { created_at: string }, b: { created_at: string }) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    }
  },
  DATE_OF_DELIVERY: {
    view: 'date of delivery',
    comparator: (a: { delivery_date: string }, b: { delivery_date: string }) => {
      return new Date(b.delivery_date).getTime() - new Date(a.delivery_date).getTime();
    }
  },
};
