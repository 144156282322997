import { Injectable } from '@angular/core';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { OrderMaterial } from '../models/order-material.class';

@Injectable()
export class OrderMaterialService extends ExtendedCrudService {
  protected namespace = 'order-materials';
  protected namespaceSingular = 'order-material';
  protected ModelClass = OrderMaterial;
}
