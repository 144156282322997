import { Injectable } from '@angular/core';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { ReportImport } from '../models/report-import.class';

@Injectable()
export class ReportImportService extends ExtendedCrudService {
  protected namespace = 'report-imports';
  protected namespaceSingular = 'report-import';
  protected ModelClass = ReportImport;
}
