import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { NomenclatureItem } from '../models/nomenclature-item.class';
var NomenclatureService = /** @class */ (function (_super) {
    tslib_1.__extends(NomenclatureService, _super);
    function NomenclatureService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'nomenclatures';
        _this.namespaceSingular = 'nomenclature';
        _this.ModelClass = NomenclatureItem;
        return _this;
    }
    NomenclatureService.prototype.getChangeNomenclatureUrl = function () {
        return this.getEndpoint(this.singularEndpoint + '/request');
    };
    NomenclatureService.prototype.sendChangeNomenclature = function (material, description) {
        return this.sendPost(this.getChangeNomenclatureUrl(), { name: material, description: description })
            .catch(this.onError.bind(this));
    };
    return NomenclatureService;
}(CrudService));
export { NomenclatureService };
