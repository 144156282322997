<div class="report-pdf__sites-chips-container">
  <div *ngIf="isLongVersion && sites.length === 0">
    <span class="report-pdf__sites-chips" *ngIf="isGenerateFromFile === true">
          {{'Generated by spreadsheet' | translate}}
    </span>
    <span class="report-base__sites-chips" *ngIf="isGenerateFromFile === false">
        {{'All sites' | translate}}
    </span>
  </div>
  <ng-container *ngFor="let site of sites">
    <div #sites class="report-pdf__sites-chips">
        <span class="report-pdf__sites-chips-name">{{site.name}}</span>
        <span class="report-pdf__sites-chips-tag" *ngFor="let tag of site.tags">
          {{tag.name}}
        </span>
    </div>
  </ng-container>
</div>
