import * as tslib_1 from "tslib";
import { StatsContainer } from './stats-container.class';
import { TransformationStats } from './transformation-stats.class';
var TransformationStatsContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TransformationStatsContainer, _super);
    function TransformationStatsContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TransformationStatsContainer.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
        return json;
    };
    TransformationStatsContainer.prototype.createModel = function (json, key) {
        key.forEach(function (k) {
            if (!!json[k]) {
                json[k] = json[k].map(function (data) { return new TransformationStats(data); });
            }
        });
    };
    TransformationStatsContainer.prototype.getTotal = function () {
        var result = { total: 0, last_year: 0, last_month: 0, last_week: 0 };
        this.total.forEach(function (item) {
            result.total += item.published + item.rejected;
        });
        this.last_year.forEach(function (item) {
            result.last_year += item.published + item.rejected;
        });
        this.last_month.forEach(function (item) {
            result.last_month += item.published + item.rejected;
        });
        this.last_week.forEach(function (item) {
            result.last_week += item.published + item.rejected;
        });
        return result;
    };
    TransformationStatsContainer.prototype.getEvolution = function () {
        var result = [];
        if (this.total.length) {
            result.push(['Date', 'Transformation sites']);
        }
        this.total.forEach(function (item) {
            result.push([new Date(item.y, item.m - 1, 1), (item.published + item.rejected) || 0]);
        });
        return result;
    };
    return TransformationStatsContainer;
}(StatsContainer));
export { TransformationStatsContainer };
