import * as tslib_1 from "tslib";
import { MdDialog } from '@angular/material';
import { AgentService } from '../../../entities/passport/services/agent.service';
import { PassportCollectionComponent } from '../../../app/components/passport/passport-Collection/passport-collection.component';
import { DashboardComponentBase } from '../../../app/components/base/dashboard/dashboard-component-base.directive';
import { Passport } from '../../../entities/passport/models/passport.class';
import { CopyPassportDialogComponent } from '../../../entities/passport/dialogs/copy-passport-dialog/copy-passport-dialog.component';
import { Router } from '@angular/router';
import { PassportRatingExplanationDialogComponent } from '../../../entities/passport/dialogs/passport-rating-explanation-dialog/passport-rating-explanation-dialog';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import { NavbarStateService } from '../../../shared/services/navbar.service';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { DIALOG_THEME } from '../../../shared/helpers/dialog-themes.const';
var AgentTabs;
(function (AgentTabs) {
    AgentTabs["Published"] = "published";
    AgentTabs["Drafts"] = "Drafts";
})(AgentTabs || (AgentTabs = {}));
var AgentPassportDashboardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AgentPassportDashboardComponent, _super);
    function AgentPassportDashboardComponent(dialog, agentService, router, translate, _userService, navbarService, localStorageService) {
        var _this = _super.call(this) || this;
        _this.dialog = dialog;
        _this.agentService = agentService;
        _this.router = router;
        _this.translate = translate;
        _this._userService = _userService;
        _this.navbarService = navbarService;
        _this.localStorageService = localStorageService;
        _this.currentTab = AgentTabs.Published;
        _this.constTabsNamesPublished = "PublishedPlural";
        _this.constTabsNamesDrafts = "Drafts";
        _this.tabs = [
            { name: null, count: 0, icon: null },
            { name: null, count: 0, icon: 'pen' }
        ];
        return _this;
    }
    AgentPassportDashboardComponent.prototype.ngOnInit = function () {
        this.tabs[0].name = this.constTabsNamesPublished;
        this.tabs[1].name = this.constTabsNamesDrafts;
        this.initializeFilterStates();
        this.loadDataForTab();
    };
    AgentPassportDashboardComponent.prototype.initializeFilterStates = function () {
        this.pagination = {
            current_page: 1,
            page_count: null,
            total_count: null,
            pagination_per_page: null,
            published_count: null,
            draft_count: null,
        };
        var stored = this.localStorageService.getStoredObject('agentPassportFilters');
        if (stored) {
            this.filterStates = stored.states;
            this.currentTab = stored.currentTab;
        }
        else {
            // Default state setup
            this.filterStates[AgentTabs.Published] = this.getDefaultFilterState();
            this.filterStates[AgentTabs.Drafts] = this.getDefaultFilterState();
        }
    };
    AgentPassportDashboardComponent.prototype.loadDataForTab = function (resetPage) {
        var _this = this;
        if (resetPage === void 0) { resetPage = true; }
        if (resetPage) {
            this.pagination.current_page = 1;
            this.passportCollection.clearSelection();
        }
        this.isLoading = true;
        this.passports = []; // Clear current data for safety
        this.pagination.published_count = null;
        this.pagination.draft_count = null;
        this.updateTabCounts();
        // Cancel previous request if still ongoing
        if (this.passportsRequestSubscription) {
            this.passportsRequestSubscription.unsubscribe();
        }
        var currentFilters = this.filterStates[this.currentTab] || this.getDefaultFilterState();
        var requestPayload = this.convertFilterStateToObj(currentFilters);
        this.versionReference;
        this.localStorageService.storeObject('agentPassportFilters', { states: this.filterStates, currentTab: this.currentTab });
        this.passportsRequestSubscription = this.agentService.getPassports(this.pagination.current_page, 10, requestPayload).subscribe({
            next: function (response) {
                _this.handleResponse(response);
                _this.isLoading = false;
            },
            error: function () {
                _this.isLoading = false;
            }
        });
    };
    AgentPassportDashboardComponent.prototype.handleResponse = function (response) {
        var body = JSON.parse(response._body);
        var headers = response.headers;
        this.pagination = {
            current_page: headers.get('X-Pagination-Current-Page'),
            page_count: headers.get('X-Pagination-Page-Count'),
            total_count: headers.get('X-Pagination-Total-Count'),
            pagination_per_page: headers.get('X-Pagination-Per-Page'),
            published_count: headers.get('X-Total-Published'),
            draft_count: headers.get('X-Total-Drafts'),
        };
        this.updateTabCounts();
        this.passports = body.map(function (item) { return new Passport(item); });
    };
    AgentPassportDashboardComponent.prototype.resetFiltersToDefault = function () {
        this.filterStates[this.currentTab] = this.getDefaultFilterState();
        this.loadDataForTab();
    };
    AgentPassportDashboardComponent.prototype.getDefaultFilterState = function () {
        return {
            sortBy: 'performance',
            search: '',
            families: [],
            materials: [],
            categories: [],
            searchId: [],
            selectedOptions: [],
            databases: [],
            dataProviders: [],
            dataRepresentativity: [],
            epdType: [],
            rating: {
                passportWithoutRating: false,
                selectRatingFilter: false,
                C2cCertified: false,
                composition: { color: false, black: false },
                cycle: { color: false, black: false },
                energy: { color: false, black: false },
                water: { color: false, black: false },
                social: { color: false, black: false },
            },
            showMyPassports: false,
            status: this.currentTab === AgentTabs.Published ? [3] : [1],
            company_creators: [],
        };
    };
    AgentPassportDashboardComponent.prototype.onTabSelected = function (selectedTabName) {
        this.pagination.current_page = 1;
        this.versionReference = null;
        this.passportCollection.clearVersionSelection();
        this.currentTab = selectedTabName === this.constTabsNamesPublished ? AgentTabs.Published : AgentTabs.Drafts;
        this.filterStates[this.currentTab].status = selectedTabName === this.constTabsNamesPublished ? [3] : [1];
        this.loadDataForTab();
    };
    AgentPassportDashboardComponent.prototype.duplicatePassport = function (record) {
        var _this = this;
        var dialogRef = this.dialog.open(CopyPassportDialogComponent, {
            data: {
                name: record.name,
                parentModule: 'agent'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.isLoading = true;
                _this.agentService.duplicatePassports(record.id, result).subscribe({
                    next: function (response) {
                        var body = JSON.parse(response._body);
                        _this.isLoading = false;
                        _this.router.navigate(['agent/passports', body.id]);
                    },
                    error: function (error) {
                        _this.isLoading = false;
                        console.error('Error duplicating passport:', error);
                    }
                });
            }
        });
    };
    AgentPassportDashboardComponent.prototype.updateTabCounts = function () {
        this.tabs = [
            tslib_1.__assign({}, this.tabs[0], { count: this.pagination.published_count }),
            tslib_1.__assign({}, this.tabs[1], { count: this.pagination.draft_count })
        ];
    };
    AgentPassportDashboardComponent.prototype.onCreateNewPassport = function () {
        this.router.navigate(['agent/passports/new']);
    };
    AgentPassportDashboardComponent.prototype.openRatingExplanationDialog = function () {
        this.dialog.open(PassportRatingExplanationDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR_WIDE, { data: {
                isActionButtonsShown: false,
            } }));
    };
    AgentPassportDashboardComponent.prototype.deleteOnePassport = function (passport) {
        var _this = this;
        if (!passport || passport.user.id != this._userService.infoData.id)
            return;
        var passportName = passport.getProductName(this.navbarService.multilanguage) + " - V" + passport.version;
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: {
                text: this.translate.instant("confirmationPassportDeletion", { passportName: passportName }),
            },
        });
        dialogRef.afterClosed().subscribe(function (confirmed) {
            if (confirmed) {
                _this.performDeletion([passport.id]);
            }
        });
    };
    AgentPassportDashboardComponent.prototype.performDeletion = function (passportIds) {
        var _this = this;
        this.isLoading = true;
        var payload = {
            passportIds: passportIds,
            isAllPagesSelected: false,
        };
        this.agentService.deletePassports(payload).subscribe({
            next: function () {
                _this.isLoading = false;
                _this.loadDataForTab();
            },
            error: function (error) {
                _this.isLoading = false;
            }
        });
    };
    return AgentPassportDashboardComponent;
}(DashboardComponentBase));
export { AgentPassportDashboardComponent };
