import * as tslib_1 from "tslib";
import { ImportConfiguration } from '../models/import-configuration.class';
import { CrudService } from '../../shared/services/crud.service';
var ImportConfigurationService = /** @class */ (function (_super) {
    tslib_1.__extends(ImportConfigurationService, _super);
    function ImportConfigurationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'import-configuration';
        _this.namespaceSingular = 'import-configurations';
        _this.ModelClass = ImportConfiguration;
        return _this;
    }
    ImportConfigurationService.prototype.getOrCreate = function (tables) {
        return this.sendPost(this.getEndpoint('import-configuration/get_or_create'), { tables: tables })
            .map(function (data) { return data.json(); })
            .map(function (res) { return new ImportConfiguration(res); })
            .catch(this.onError.bind(this));
    };
    ImportConfigurationService.prototype.addSynonym = function (lookUpTableId, fieldOrder, fieldValue) {
        return this.sendPost(this.getEndpoint('import-configuration/add_synonym'), {
            lookUpTableId: lookUpTableId,
            fieldOrder: fieldOrder,
            fieldValue: fieldValue
        })
            .map(function (data) { return data.json(); })
            .catch(this.onErrorTranslation.bind(this));
    };
    ImportConfigurationService.prototype.deleteSynonym = function (id) {
        return this.sendDelete(this.getEndpoint("import-configuration/delete_synonym/" + id))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    ImportConfigurationService.prototype.getUpdateColumnListEndpoint = function () {
        return this.getEndpoint('import-configuration/update_columns_list');
    };
    ImportConfigurationService.prototype.updateColumnsList = function (columnsList) {
        return this.sendPost(this.getUpdateColumnListEndpoint(), {
            columnsList: columnsList,
        }).map(function (data) { return data.json(); })
            .catch(this.onErrorTranslation.bind(this));
    };
    return ImportConfigurationService;
}(CrudService));
export { ImportConfigurationService };
