import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { DepositLocationFile } from '../models/deposit-location-file.class';

@Injectable()
export class DepositLocationFileService extends ExtendedCrudService {
  protected namespace = 'deposit-location-files';
  protected namespaceSingular = 'deposit-location-file';
  protected ModelClass = DepositLocationFile;

}