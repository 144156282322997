<div class="deposit-import-success">
    <dialog-close-button (click)="mdDialogRef.close()"></dialog-close-button>
    <div class="deposit-import-success__wrapper">
        <div class="deposit-import-success__logo-wrapper">
          <confirm-dialog-icon
            [iconData]="iconLogo"
          >
          </confirm-dialog-icon>
        </div>
        <div class="deposit-import-success__title">
          {{report.linesCount || 0}}
            <ng-container *ngIf="getNumber(report.linesCount) === 1">
               {{ 'IMPORT_SUCCESS_SUMMARY_MAIN_TITLE_ONE'| translate | uppercase}}
            </ng-container>
            <ng-container *ngIf="getNumber(report.linesCount) !== 1">
              {{ 'IMPORT_SUCCESS_SUMMARY_MAIN_TITLE'| translate | uppercase}}
            </ng-container>
        </div>
        <div class="deposit-import-success__username primary-color">
            {{ 'IMPORT_SUCCESS_SUMMARY_MAIN_USERNAME'| translate}} {{report.username}}
        </div>
        <div class="deposit-import-success__username deposit-import-success__username--offset-bottom primary-color">
            {{ 'IMPORT_SUCCESS_SUMMARY_MAIN_DATE'| translate}} {{report.creationDate | date: 'dd/MM/y'}}
        </div>
        <div class="deposit-import-success__passport">
            <div class="deposit-import-success__row-title deposit-import-success__row-title--with-tooltip">
                <div class="deposit-import-success__row-left-part">
                  <confirm-dialog-icon
                    [iconData]="iconCreate"
                  ></confirm-dialog-icon>
                </div>
                <div class="deposit-import-success__row-right-part">
                    {{passportCreateText}}
                </div>
            </div>
            <div class="deposit-import-success__row-tooltip primary-color">
              <common-info-sign class="deposit-import-success__row-tooltip-sign"
                                [borderSizeClassModifier]="'1-half'">
                <i class="icon icon--information-agent" ></i>
              </common-info-sign>
              <span class="deposit-import-success__row-tooltip-text">
                  {{'IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_CREATED_TOOLTIP' | translate}}
                </span>
            </div>

            <div class="deposit-import-success__row-content" *ngFor="let d of report.passportsCreated">
                <div class="deposit-import-success__row-right-part">
                    <b>{{'IMPORT_SUCCESS_SUMMARY_MAIN_LINE' | translate}} {{d.row_number}} </b> - {{d.name}}
                </div>
            </div>

        </div>
        <div class="deposit-import-success__deposit-created">
            <div class="deposit-import-success__row-title">
                <div class="deposit-import-success__row-left-part">
                  <confirm-dialog-icon
                    [iconData]="iconCreate"
                  ></confirm-dialog-icon>
                </div>
                <div class="deposit-import-success__row-right-part">
                    {{depositCreateText}}
                </div>
            </div>

            <div class="deposit-import-success__row-content" *ngFor="let d of report.depositsCreated">
                <div class="deposit-import-success__row-right-part">
                    <b>{{ 'IMPORT_SUCCESS_SUMMARY_MAIN_LINE' | translate}} {{d.row_number}} </b> - {{d.name}}
                </div>
            </div>
        </div>
        <div class="deposit-import-success__deposit-updated">

            <div class="deposit-import-success__row-title">
                <div class="deposit-import-success__row-left-part">
                  <confirm-dialog-icon
                    [iconData]="iconUpdated"
                  ></confirm-dialog-icon>
                </div>
                <div class="deposit-import-success__row-right-part">
                    {{depositUpdatedText}}
                </div>
            </div>

            <div class="deposit-import-success__row-content"  *ngFor="let d of report.depositsUpdated">
                <div class="deposit-import-success__row-right-part">
                    <b>{{ 'IMPORT_SUCCESS_SUMMARY_MAIN_LINE'  | translate}} {{d.row_number}} </b> - {{d.name}}
                </div>
            </div>

        </div>
        <div class="deposit-import-success__deposit-voided" *ngIf="report.depositsVoided.length">

            <div class="deposit-import-success__row-title">
                <div class="deposit-import-success__row-left-part">
                  <confirm-dialog-icon [iconData]="iconWarning"></confirm-dialog-icon>
                </div>
                <div class="deposit-import-success__row-right-part">
                    {{depositWithVoidCreatedText}}
                </div>
            </div>

            <div class="deposit-import-success__row-content"  *ngFor="let d of report.depositsVoided">
                <div class="deposit-import-success__row-right-part">
                    <b>{{ 'IMPORT_SUCCESS_SUMMARY_MAIN_LINE'| translate}} {{d.row_number}} </b> - {{d.name}}
                </div>
            </div>

        </div>
        <div class="deposit-import-success__deposit-pictures">

            <div class="deposit-import-success__row-title">
                <div class="deposit-import-success__row-left-part">
                  <confirm-dialog-icon [iconData]="iconWarning"></confirm-dialog-icon>
                </div>
                <div class="deposit-import-success__row-right-part">
                    {{ 'IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_PHOTOS' | translate | capitalize}}
                </div>
            </div>

            <div class="deposit-import-success__row-content"  *ngFor="let d of report.picturesNotFound">
                <div class="deposit-import-success__row-right-part">
                    <b>{{ 'IMPORT_SUCCESS_SUMMARY_MAIN_LINE'| translate}} {{d.row_number}} </b> - {{d.url}}
                </div>
            </div>

        </div>
        <div class="deposit-import-success__deposit-documents">

            <div class="deposit-import-success__row-title">
                <div class="deposit-import-success__row-left-part">
                  <confirm-dialog-icon [iconData]="iconWarning"></confirm-dialog-icon>
                </div>
                <div class="deposit-import-success__row-right-part">
                    {{ 'IMPORT_SUCCESS_SUMMARY_MAIN_DEPOSIT_DOCUMENTS' | translate  | capitalize}}
                </div>
            </div>

            <div class="deposit-import-success__row-content" *ngFor="let d of report.documentsNotFound">
                <div class="deposit-import-success__row-right-part">
                    <b>{{ 'IMPORT_SUCCESS_SUMMARY_MAIN_LINE' | translate}} {{d.row_number}} </b> - {{d.url}}
                </div>
            </div>

        </div>
    </div>
  </div>
