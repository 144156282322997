/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./left-arrow-icon.component";
var styles_LeftArrowIconComponent = [];
var RenderType_LeftArrowIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LeftArrowIconComponent, data: {} });
export { RenderType_LeftArrowIconComponent as RenderType_LeftArrowIconComponent };
export function View_LeftArrowIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "12"], ["viewBox", "0 0 7 12"], ["width", "7"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M6 1L1 6L6 11"], ["stroke", "#333333"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null))], null, null); }
export function View_LeftArrowIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-left-arrow-icon", [], null, null, null, View_LeftArrowIconComponent_0, RenderType_LeftArrowIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.LeftArrowIconComponent, [], null, null)], null, null); }
var LeftArrowIconComponentNgFactory = i0.ɵccf("app-left-arrow-icon", i1.LeftArrowIconComponent, View_LeftArrowIconComponent_Host_0, {}, {}, []);
export { LeftArrowIconComponentNgFactory as LeftArrowIconComponentNgFactory };
