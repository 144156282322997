<div class="esm-catalog">
  <header class="esm-catalog__header checkbox header--title-group">
    <h3 class="esm-catalog__inline-title">{{'Orders' | translate}}</h3>
    <md-checkbox class="esm-catalog__group--checkbox checkbox-group"
                 [(ngModel)]="status.checked"
                 (change)="getRecords()"
                 *ngFor="let status of statuses">
      {{ status.text | translate}}
    </md-checkbox>
  </header>

  <section class="esm-catalog__choice type--flex">
    <ul class="esm-catalog__choice-list type--auto">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input mdInput
                 autocomplete="off" 
                 placeholder="{{'Search' | translate}}"
                 [(ngModel)]="search"
                 (ngModelChange)="getRecords()"
          >
          <!--(change)="getRecords()"-->
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--people-grey"></i>
        <md-select class="select-group"
                   placeholder="{{'Esm contact' | translate}}"
                   [(ngModel)]="esmContact"
                   (change)="getRecords()"
        >
          <md-option [value]="0">{{'Any' | translate}}</md-option>
          <md-option *ngFor="let contact of esmContacts"
                     [value]="contact.id">
            {{ contact.name }}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select-long">
        <md-select
          class="select-group"
          placeholder="{{ 'Type of transformation' | translate }}"
          [(ngModel)]="type"
          (ngModelChange)="getRecords()"
        >
          <md-option [value]="null">{{ 'Any' | translate }}</md-option>
          <md-option
            *ngFor="let type of TRANSFORM_TYPES"
            [value]="type.value"
          >{{type.view | capitalize | translate}}
          </md-option>
        </md-select>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--gray-sort"></i>
        <md-select
          class="select-group"
          placeholder="{{ 'Sort by' | translate }}"
          [(ngModel)]="sortValue"
          (ngModelChange)="getRecords()"
        >
          <md-option
            *ngFor="let sortValue of sortOptions"
            [value]="sortValue.field"
          >{{ sortValue.view | translate }}
          </md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list
    [records]="records"
    [expandable]="true"
    (onEndOfList)="onEndOfListTriggered()"
  ></custom-list>
</div>
