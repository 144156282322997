/**
 * Created by aleksandr on 20.07.17.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationEntry } from '../../models/notification-entry.model';
@Component({
  moduleId: module.id,
  selector: 'notification-entry',
  templateUrl: 'notification-entry.component.html'
})
export class NotificationEntryComponent {
  @Input() public record: NotificationEntry;
  @Output() public onRead = new EventEmitter<NotificationEntry>();
  @Output() public onAction = new EventEmitter<NotificationEntry>();

  public readNotification() {
    this.onRead.emit(this.record);
  }

  public callToAction() {
    this.onAction.emit(this.record);
  }
}
