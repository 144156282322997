import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgentService } from '../../../entities/passport/services/agent.service';
import { SpecialAdminService } from '../../../entities/passport/services/specialAdmin.service';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
    selector: 'app-creator-filter',
    templateUrl: './creator-filter.component.html',
})
export class CreatorFilterComponent implements OnInit{
    @Input() creatorSelected: string[] = [];
    @Output() creatorChange = new EventEmitter<string[]>();
    creators: string[];

    constructor(
        private _navigation: NavigationService, 
        private agentService: AgentService,
        private specialAdminService: SpecialAdminService
    ) {}

    ngOnInit() {
        let role = this._navigation.getContext().roleText;   
        let requestObservable = null; 
        switch (role) {
            case 'Agent':     
                requestObservable = this.agentService.getPassportCreator();
                break;
            case 'Specialadmin':
                requestObservable = this.specialAdminService.getPassportCreator();
                break;
            default:
                return;
        }
        requestObservable.subscribe({
            next: (response: any) => {
                const body = JSON.parse(response._body);
                this.creators = body.map(creator => creator.company_name);
                this.creators.push('Unknown');
            },
            error: (error) => {
                console.error('Error fetching creators', error);
            }
        });
    }

    handleSelectionChange(selectedCreators: string[]) {
        this.creatorChange.emit(selectedCreators);
    }
}
