import { current } from 'codelyzer/util/syntaxKind';
import { Component, Input, OnInit } from '@angular/core';
import {ICarbonFootprintCategory} from '../../../../../interfaces/carbon-footprint.interface';
import { DecimalPipe } from '@angular/common';
import { DecimalSeparator } from '../../../../../../../shared/pipes/decimalSeparator';
import { ReportBase } from '../../report-base/report-base.class';
import { ECarbonFootprintReportType } from '../../../../../values/carbon-footprint-status.enum';
import {convertUnit,convertUnitTarget} from '../../../../../../../helper/Unithelper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'bar-chart',
  providers: [DecimalPipe, DecimalSeparator],
  templateUrl: 'bar-chart.component.html',
  styleUrls: ['bar-chart.component.scss']
})
export class BarChartComponent extends ReportBase {
  @Input() firstFootprint: ICarbonFootprintCategory;
  @Input() secondFootprint: ICarbonFootprintCategory;
  @Input() thirdFootprint: ICarbonFootprintCategory;
  @Input() isReportPdf = false;
  @Input() reportType: ECarbonFootprintReportType;
  public graphicCanvasHeight: number = 400;
  public numberOfChartRows: number = 7;
  public numberOfHrElements: number = 8;
  private maxUnit: string;

  constructor(
    private decimalPipe: DecimalPipe,
    private _translate: TranslateService,
    private decimalSeparator: DecimalSeparator)
  {
    super();        
  }

  getMainDataForChart(carbonFootprint: ICarbonFootprintCategory){
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return carbonFootprint.footprint;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return carbonFootprint.embodied_carbon_total;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return carbonFootprint.ic_component_total;
      case ECarbonFootprintReportType.IC_CONSTRUCTION:
        return carbonFootprint.ic_construction_total;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
          return carbonFootprint.product_and_construction_total;
    }
  }

  /**
   * Get the weight of the item depending on the report type
   * @param {ICarbonFootprintCategory} category - The category to get the weight from
   * @returns {Number} - Weight of the category
   */
  getWeight(category) {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return category.weight;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return category.weight_considered_product;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return category.weight_considered_embodied;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return category.weight_considered_ic_comp_const;
    }
  }

  /**
   * Get the mass percentage of the item depending on the report type
   * @param {ICarbonFootprintCategory} category - The category to get the mass percentage from
   * @returns {Number} - Weight of the category
   */
  getMassPercentage(category) {
    switch (this.reportType) {
      case ECarbonFootprintReportType.PRODUCT_STAGE:
        return category.mass_percentage_considered;
      case ECarbonFootprintReportType.PRODUCT_AND_CONSTRUCTION:
        return category.mass_percentage_considered_construction;
      case ECarbonFootprintReportType.EMBODIED_CARBON:
        return category.mass_percentage_considered_embodied;
      case ECarbonFootprintReportType.IC_COMPONENT:
        return category.mass_percentage_considered_ic_comp;
    }
  }

  isReportTypeIcComponent(){
    return this.reportType === ECarbonFootprintReportType.IC_COMPONENT
  }

  findMaxValue(){
    let maxvalue = this.getDataForChartColumns().reduce((max, item) => {
      return Math.abs(item.mainDataForChart) > max ? Math.abs(item.mainDataForChart) : max;
    }, 0);
    let unit = this.reportType === ECarbonFootprintReportType.IC_COMPONENT || this.reportType ===  ECarbonFootprintReportType.IC_CONSTRUCTION ? 'kgs' : 'tons';
    this.maxUnit = convertUnit({value : maxvalue, unit : unit }).unit; 
  }

  getUnit(){
    if(!this.maxUnit)
      this.findMaxValue();
    if(this.isReportTypeIcComponent()){
      return this.maxUnit + ' Co<sub>2</sub> eq. / sqm'
    }
    return this._translate.instant(this.maxUnit) + ' Co<sub>2</sub>  eq.';
  }

  getUnitValue(value: number): number {
    if(!this.maxUnit)
      this.findMaxValue();
    let unit = this.reportType === ECarbonFootprintReportType.IC_COMPONENT || this.reportType ===  ECarbonFootprintReportType.IC_CONSTRUCTION ? 'kgs' : 'tons';
    return convertUnitTarget({value : value, unit : unit }, this.maxUnit).value;
    }

  getDataForChartColumns() {
    return [
      {
        mainDataForChart: this.firstFootprint ? this.getMainDataForChart(this.firstFootprint) : null,
        categoryName: this.firstFootprint ? this.firstFootprint.category.name : null,
        weight: this.firstFootprint ? this.getWeight(this.firstFootprint) : null,
        percentageOfTotalWeight: this.firstFootprint ? this.getMassPercentage(this.firstFootprint) : null,
        itemNumber: 'first'
      },
      {
        mainDataForChart: this.secondFootprint ? this.getMainDataForChart(this.secondFootprint) : null,
        categoryName: this.secondFootprint ? this.secondFootprint.category.name : null,
        weight: this.secondFootprint ? this.getWeight(this.secondFootprint) : null,
        percentageOfTotalWeight: this.secondFootprint ? this.getMassPercentage(this.secondFootprint) : null,
        itemNumber: 'second'

      },
      {
        mainDataForChart: this.thirdFootprint ? this.getMainDataForChart(this.thirdFootprint) : null,
        categoryName: this.thirdFootprint ? this.thirdFootprint.category.name : null,
        weight: this.thirdFootprint ? this.getWeight(this.thirdFootprint) : null,
        percentageOfTotalWeight: this.thirdFootprint ? this.getMassPercentage(this.thirdFootprint) : null,
        itemNumber: 'third'
      }
    ];
  }
  getModifiedClassName(className: string, itemNumber: string): string {
    return `${className} ${className}-${itemNumber}`;
  }
  getGraphicCanvasHeight(): string {
    return this.graphicCanvasHeight + 'px';
  }

  getOnePointVerticalAxisValues(): number {
    const maxPointForVerticalAxisValues = Math.max.apply(Math, [
      this.firstFootprint ? this.getMainDataForChart(this.firstFootprint) : null,
      this.secondFootprint ? this.getMainDataForChart(this.secondFootprint) : null,
      this.thirdFootprint ? this.getMainDataForChart(this.thirdFootprint) : null
    ]);

    let step;
    if (maxPointForVerticalAxisValues === 0) {
      step = 1;
    } else {
      let [shortValue, exposant] = maxPointForVerticalAxisValues.toExponential(2).split('e');
      let stepBase = Math.ceil(10 * shortValue / 7);
      step = stepBase * Math.pow(10, exposant-1);
    }
    return step;
  }

  getNumberOfPixelsInOneRowVerticalAxis(): number {
    return this.graphicCanvasHeight / this.numberOfChartRows;
  }
  isCurrentValueLessThanOneRowVerticalAxis(currentValue: number): boolean {
    return this.getNumberOfPixelsInOneRowVerticalAxis() < (this.getAmountForOneChartRowInPixel() * currentValue);
  }
  numberOfHrMarginTopValues(): number[] {
    // -1px is minus the height of the border hr
    return new Array(this.numberOfHrElements).fill((this.getNumberOfPixelsInOneRowVerticalAxis() - 1) + 'px');
  }
  getVerticalAxisValues(): number[] {
    let step = this.getOnePointVerticalAxisValues();
    const pointChartValues = [];
    for (let i = 0; i <= this.getNumberOfChartRowsInTable() - 1; i++) {
      pointChartValues.push(i * step);
    }
    return pointChartValues;
  }
  getNumberOfChartRowsInTable(): number{
    const numberOfChartRowsInTable = 8;
    return numberOfChartRowsInTable;
  }
  getAmountForOneChartRowInPixel(): number {
    return this.graphicCanvasHeight / (this.getOnePointVerticalAxisValues() * (this.numberOfChartRows - 1 ) );
  }
  getAmountForPillarInPixels(currentValue: number): string {
    return (this.getAmountForOneChartRowInPixel() * currentValue) + 'px';
  }
  getAmountForPillar(currentValue : number): number {
    return (this.getAmountForOneChartRowInPixel() * currentValue);
  }
}
