/**
 * Created by aleksandr on 13.06.17.
 */
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Searchable} from '../../../../../../shared/models/searchable.class';
import {
  CustomListUnitValue,
  ECustomListHintClass,
  ECustomListRecordStatus,
  ECustomListUnit,
  ICustomListRecord
} from '../../../../../../shared/custom-list/interfaces/custom-list.interface';
import {NeedService} from '../../../../../../entities/need/services/need.service';
import {DatePipe} from '@angular/common';
import {NeedClientListService} from '../../../../../../entities/need/services/need-client-list.service';
import {NeedSalesListService} from '../../../../../../entities/need/services/need-sales-list.service';
import {MdDialog} from '@angular/material';
import {Router} from '@angular/router';
import {EsmNeedDialogComponent} from '../../../../dialogs/esm-need-dialog/esm-need-dialog.component';
import {NEED_STATUSES} from '../../../../../../entities/need/values/need-statuses.const';
import {Need} from '../../../../../../entities/need/models/need.class';
import {CustomListRecord} from '../../../../../../shared/custom-list/custom-list-record.model';
import {Ecosystem} from '../../../../models/ecosystem.class';
import {NeedDetailsDialogComponent} from '../../../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import {DIALOG_THEME} from '../../../../../../shared/helpers/dialog-themes.const';
import {CurrentUser} from '../../../../../../shared/auth/current-user.class';
import {Subscription} from 'rxjs/Rx';
import {NavbarStateService} from '../../../../../../shared/services/navbar.service';
import {QUANTITY_PRECISION} from '../../../../../../entities/need/values/quantity-precision.const';
import {EDatePrecision} from '../../../../../../entities/need/values/date-precision.const';
import {TranslateService} from '@ngx-translate/core';
import {IMapMarker} from '../../../../../../shared/map-view/map-marker.interface';
import {
  DepositDetailsDialogComponent
} from '../../../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';


@Component({
  moduleId: module.id,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'esm-need-catalog',
  templateUrl: 'esm-need-catalog.component.html'
})
export class EsmNeedCatalogComponent implements OnInit, OnDestroy {

  sortOptions: any = [
    { field: 'delivery_date', view: 'Delivery date'},
    { field: 'client_name', view: 'Client'},
    { field: 'name', view: 'Name'},
    { field: 'sales_name', view: 'Need manager'}
  ];
  sortValue = 'delivery_date';

  clientId: number;
  salesId: number;
  search = new FormControl();
  expiresFrom: string;
  expiresTo: string;

  clients: Searchable[] = [];
  filteredClients: Searchable[] = [];
  salesContacts: Searchable[];
  records: ICustomListRecord[] = [];
  showIndependentFilter: boolean;
  includeIndependent: number;
  miltilanguageSubscription: Subscription;

  markers: IMapMarker[] = [];
  oldQuery: any;
  needs: Need[];
  filteredSales: Searchable[] = [];
  saleses: Searchable[];

  markerAddedLabelText: string;
  markerByLabelText: string;

  constructor(private _needService: NeedService,
              private datePipe: DatePipe,
              private router: Router,
              private needClientListService: NeedClientListService,
              private needSalesListService: NeedSalesListService,
              private dialog: MdDialog,
              private changeDetectorRef: ChangeDetectorRef,
              private navBarState: NavbarStateService,
              private _translate: TranslateService,
              private translateService: TranslateService
  ) {
    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => this.getRecords())
    ;

    this.getSalesList();
    this.getNeedClientList();

    this._translate.get('Added').subscribe((translation) => this.markerAddedLabelText = translation);
    this._translate.get('By').subscribe((translation) => this.markerByLabelText = translation);

  }


  toMarker(need: Need): IMapMarker {
    return {
      lat: need.client_location.lat,
      lng: need.client_location.lng,
      name: need.name,
      lines: [
        `${need.quantity ? need.quantity : 0} ${need.passport.unit_text}`,
        `<br>`,
        `${need.description ? need.description : ''}`,
        `<br>`,
        `${this.markerAddedLabelText}: ${this.datePipe.transform(need.created_at, 'dd.MM.y')}`,
        `${this.markerByLabelText}: ${need.sales_contact}`,
      ]
    };
  }


  getSalesList() {
    this.needSalesListService.get({independent : this.includeIndependent }).subscribe((res: Searchable[]) => {
      this.salesContacts = res;
    });
  }
  getNeedClientList() {
    this.needClientListService.get({independent : this.includeIndependent }).subscribe((res: Searchable[]) => {
      this.clients = res;
    });
  }

  openMapDialog($event: any) {
    const dialogRef = this.dialog.open(EsmNeedDialogComponent, {
      width: '65%',
      height: '500px',
      position: {}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('afterClosed', result);
    });
  }

  getRecords() {
    this.records = [];
    let params: any = {
      expand: 'client,passport,client_contact,client_location,sales_user,ecosystems,sales_contact,sales_email,sales_name,treatable',
      admin: 1,
      supervisor: 1,
      exclude_refused: 1,
      delivery_from: this.expiresFrom || null,
      delivery_to: this.expiresTo || null,
      search: this.search.value || null,
      client: this.clientId || null,
      independent: this.includeIndependent || null,
      sales_contact: this.salesId || null,
      'status[]': [NEED_STATUSES.PUBLISHED.value] || null,
      sort: this.sortValue,
    };

    if (this.navBarState.multilanguage) {
      params.english = true;
    }

    this._needService
      .get(params)
      .subscribe((data: Need[]) => {
        data.forEach((item) => this.addRecord(new Need(item)));
        this.markers = data.map((need: Need) => this.toMarker(need))
        this.filteredClients = this.clients
          .filter((item) => data.find((need: Need) => need.client && need.client.id === item.id));
          this.changeDetectorRef.detectChanges();
      });
      this.getSalesList();
      this.getNeedClientList();

  }

  addRecord(data: Need) {
    // Treated need : nothing;
    // not treated : blue hand
    // tslint:disable-next-line:prefer-const
    let status: ECustomListRecordStatus;

    let hintCssClass = ECustomListHintClass.NO_CLASS;
    let hintText = '';
    let isCloseToExpired = false;
    const needIsTreated = data.status === NEED_STATUSES.TREATED.value;
    const quantityPrecision = QUANTITY_PRECISION.find(item => data.quantity_precision === item.key);

    if (data.delivery_date) {
      const deliveryTimeLeft = (new Date(data.delivery_date).getTime() - Date.now());

      if (
        !needIsTreated &&
        deliveryTimeLeft / 1000 / 60 / 60 / 24 < 7 &&
        deliveryTimeLeft > 0
      ) {
        hintCssClass = ECustomListHintClass.WARNING;
        hintText = 'There is less than a week left, take action!';
        isCloseToExpired = true;
      }
    }
    const translate = (text) => this.translateService.instant(text);
    const isUnlimitedQuantity = quantityPrecision && quantityPrecision.value === 'unknown';
    const isDateUndefined = data.date_precision === EDatePrecision.UNKNOWN || data.date_precision === EDatePrecision.RECURRENT;
    const unit = data.passport.unit_text;
    const quantity = isUnlimitedQuantity
      ? translate('unlimited')
      : `${translate(data.quantity_text)} ${unit ? translate(unit) : ''}`;
    const quantityPrecisionText = quantityPrecision && !isUnlimitedQuantity ? translate(quantityPrecision.value) : '';
    const getDateUndefinedText = (record) => record.date_precision === EDatePrecision.UNKNOWN ? translate('To be defined')
      : translate(record.datePrecisionViewValue);
    const dateOfDelivery = isDateUndefined ? getDateUndefinedText(data)
      : (data.delivery_date ? this.datePipe.transform(data.delivery_date, 'dd.MM.y') : '');
    const dateOfDeliveryPrecision = (!!data.datePrecisionViewValue && !isDateUndefined)
      ? translate(data.datePrecisionViewValue) : '';

    this.records.push(
      this.createNeedRecord(
        data,
        status,
        hintCssClass,
        hintText,
        data.name,
        data.passport.name || '',
        data.passport.english_name,
        quantity,
        quantityPrecisionText,
        data.client_location.name,
        data.client_location.address,
        dateOfDelivery,
        dateOfDeliveryPrecision,
        isCloseToExpired,
        data.sales_name,
      )
    );
  }

  ngOnInit(): void {
    this.miltilanguageSubscription = this.navBarState.multilanguageObservable$.subscribe(() => this.changeDetectorRef.detectChanges());
    this.getRecords();
    this.showIndependentFilter = CurrentUser.infoData.has_need_acces;
  }

  ngOnDestroy() {
    this.miltilanguageSubscription.unsubscribe();
  }

  /*
  Name of Need,
  Name of PP,
  Status of the need,
  client name,
  description,
  expected delivery date,
  surname and name of sale,
  quantity (with unit)
 */

  createNeedRecord(need: Need,
                   status: ECustomListRecordStatus,
                   hintCssClass: ECustomListHintClass,
                   hintText: string,
                   title: string,
                   subtitle: string,
                   english_name: string,
                   amount: string,
                   quantityPrecision: string,
                   locationName: string,
                   locationAddress: string,
                   expiredAt: string,
                   expirePrecision: string,
                   isCloseToExpired: boolean,
                   author: string): CustomListRecord {
    const controls = [{
      fn: (data: ICustomListRecord) => {
        this.dialog.open(NeedDetailsDialogComponent, {
          ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
          data: {
            readOnly: true,
            id: need.id,
          },
        });
      },
      name: 'See details'
    }, ...(need.from_deposit && need.from_deposit > 0 ? [{
      name: 'See targeted deposit',
      fn: () => {
        this.dialog.open(DepositDetailsDialogComponent, {
          data: {
            id: need.from_deposit,
            readOnly: true
          }
        });
      }
    }] : []), {
      fn: (data: ICustomListRecord) => location.href = 'mailto:' + need.sales_email,
      name: 'Send email to Need manager'
    }];

      controls.push({
        fn: (data: ICustomListRecord) => this.createEcosystemFromNeed(need),
        name: 'Create ecosystem'
      });

    return CustomListRecord
      .build()
      .setPrimaryKey(need.id)
      .setIndependent(!!need.independent)
      .setStatus(status)
      .setHint(hintText, hintCssClass)
      .addTitleColumn(title, subtitle, null, english_name)
      .addListColumn([
        ...(!!amount ? [{value: amount, key: '', seperator: '', cssClass: 'title'}] : []),
        ...(!!quantityPrecision ? [{value: `${quantityPrecision}`, key: '', seperator: ''}] : [])
       ])
      .addListColumn([
        ...(!!expiredAt ? [{value: `${expiredAt}`, key: '', seperator: '', cssClass: 'title'}] : []),
        ...(!!expirePrecision ? [{value: `${expirePrecision}`, key: '', seperator: ''}] : [])
         ])
      .addListColumn([
        {value: `${locationName}`, key: '', seperator: '', cssClass: 'title'},
        ...(!!locationAddress ? [{value: `${locationAddress}`, key: '', seperator: ''}] : [])
      ])
      .addAuthorColumn(need.independent ? need.sales_user.company_name : author)
      .addControlsColumn({options: controls, icon: 'menu'});
  }

  createEcosystemFromNeed(need: Need) {
    new Ecosystem({
      name: `${this.translateService.instant('For')} ${need.name}`,
      need_id: need.id,
    }).save().subscribe((ecosystem: Ecosystem) => {
      this.router.navigate(['ecosystem/ecosystems', ecosystem.id]);
    });
  }

  toggleIndependent(checked: boolean) {
    this.includeIndependent = +checked;
    this.getRecords();
  }
}
/*
 this.records.push(
 this.createNeedRecord(
 new Need(),
 ECustomListRecordStatus.IMPORTANT, //is for new need
 ECustomListHintClass.NO_CLASS,
 '',
 'Plastic Granules',
 'Samsung inc',
 12,
 ECustomListUnit.TON,
 'Need for plastic granules. Cant be less than defined amount. Second line of description text',
 '20.04.2017',
 '27.04.2017',
 false,
 'Roland Lestman'
 ),
 this.createNeedRecord(
 new Need(),
 ECustomListRecordStatus.IMPORTANT, //is for new need
 ECustomListHintClass.WARNING,
 'There is less than a week left! Take action!',
 'Plastic Granules',
 'Samsung inc',
 12,
 ECustomListUnit.TON,
 'Need for plastic granules. Cant be less than defined amount. Second line of description text',
 '20.04.2017',
 '27.04.2017',
 true,
 'Roland Lestman'
 ),
 this.createNeedRecord(
 new Need(),
 ECustomListRecordStatus.MANUAL, //is for new need
 ECustomListHintClass.NO_CLASS,
 '',
 'Plastic Granules',
 'Samsung inc',
 12,
 ECustomListUnit.TON,
 'Need for plastic granules. Cant be less than defined amount. Second line of description text',
 '20.04.2017',
 '27.04.2017',
 true,
 'Roland Lestman'
 ),
 );
 */
