export class PassportSynchronizationData {
    passportId: number;
    language: string;
    source: number;
    otherSource: string;
    operator: number;
    otherOperator: string;
    documentId: string;
    synchronized: boolean;
    epdType: number;

    private static SOURCES = {
        1: 'INIES',
        2: 'Eco-platform',
        3: 'Baubook',
        4: 'B-EPD',
        5: 'EPD Danmark',
        6: 'CO2data',
        7: 'RTS EPD',
        8: 'OKOBAU.DAT',
        9: 'IGBC',
        10: 'EPD Italy',
        11: 'NMD',
        12: 'EPD Norge',
        13: 'ITB',
        14: 'DAP Habitat',
        15: 'GBCE',
        16: 'Boverket',
        17: 'The International EPD System',
        18: 'KBOB',
        19: 'Built Environment Carbon Database',
        20: 'IBU',
        21: 'iftRosenheim',
        22: 'EPD Ireland',
        23: 'EPD Hub',
        24: 'BRE',
        0: 'unknownSource',
        99: 'Other'
    };

    private static OPERATORS = {
        1: 'INIES',
        2: 'IBU',
        3: 'The International EPD® System',
        4: 'BRE Global',
        5: 'Kiwa-Ecobility Experts',
        6: 'Stichting MRPI®',
        7: 'The Norwegian EPD Foundation',
        8: 'Bau EPD',
        9: 'DAPconstrucción®',
        10: 'EPD Danmark',
        11: 'EPD Ireland',
        12: 'EPD Italy',
        13: 'Global EPD',
        14: 'Global GreenTag International EPD Program',
        15: 'ift Rosenheim',
        16: 'ITB EPD Program',
        17: 'RTS EPD',
        18: 'DAPhabitat',
        19: 'Programm für Umweltproduktedeklarationen des SÜGB',
        20: 'ZAG EPD',
        0: 'unknownOperator',
        99: 'Other'
    };

    private static EPD_TYPES = {
        1: 'PEP_Individual_FDES',
        2: 'PEP_Collective_FDES',
        3: 'DED_French_default_environmental_data',
        4: 'PEP_Customized_FDES',
        5: 'DEP_Individual_Environmental_Product_Declaration',
        6: 'DEP_Collective_Environmental_Product_Declaration',
        7: 'DEP_Environmental_Product_Declaration_by_sector',
        8: 'DEP_Customized_Environmental_Product_Declaration',
        0: 'Unknown_epd_type',
        99: 'Other'
    };

    private static short_EPD_TYPES = {
        1: "PEP / FDES indiv",
        2: "PEP / FDES coll",
        3: "DED",
        4: "PEP / FDES  “sur mesure”",
        5: "DEP indiv",
        6: "DEP coll",
        7: "DEP par secteur",
        8: "DEP “sur mesure”",
        0: "Type de document inconnu",
        99: "Other"
    };

    constructor(data: any) {
        if(!data) return;
        this.passportId = data.passport_id;
        this.language = data.language;
        this.source = data.source;
        this.otherSource = data.other_source;
        this.operator = data.operator;
        this.otherOperator = data.other_operator;
        this.documentId = data.document_id;
        this.synchronized = data.synchronized;
        this.epdType = data.epd_type;
    }

    public getSourceName(): string {
        return PassportSynchronizationData.SOURCES[this.source] || 'unknownSource';
    }

    public getOperatorName(): string {
        return PassportSynchronizationData.OPERATORS[this.operator] || 'unknownOperator';
    }

    public getEpdTypeName() {
        return PassportSynchronizationData.EPD_TYPES[this.epdType] || "Unknown_epd_type";
    }

    public getShortEpdTypeName() {
        return PassportSynchronizationData.short_EPD_TYPES[this.epdType] || "Unknown_epd_type";
    }

    public getLanguage(): string {
        return this.language || '';
    }

    public getLogoPath(): string | null {
        switch (this.source) {
            case 1: // Inies
                return 'assets/png/IniesLogo.png';
            case 2: // EcoPlateforme
                return 'assets/png/EcoPlateformLogo.png';
            default:
                return null;
        }
    }

    public static getSourceKeyList(): string[] {
        return Object.keys(PassportSynchronizationData.SOURCES);
    }

    public static getSourceValueList(): string[] {
        return Object.keys(PassportSynchronizationData.SOURCES).map(key => PassportSynchronizationData.SOURCES[key]);
    }

    public static getOperatorKeyList(): string[] {
        return Object.keys(PassportSynchronizationData.OPERATORS);
    }

    public static getOperatorValueList(): string[] {
        return Object.keys(PassportSynchronizationData.OPERATORS).map(key => PassportSynchronizationData.OPERATORS[key]);
    }

    public static getEpdTypesKeyList(): string[] {
        return Object.keys(PassportSynchronizationData.EPD_TYPES);
    }

    public static getEpdTypesValueList(): string[] {
        return Object.keys(PassportSynchronizationData.EPD_TYPES).map(key => PassportSynchronizationData.EPD_TYPES[key]);
    }

    public static getEpdTypeKey(epdType: string): number {
        return parseInt(Object.keys(PassportSynchronizationData.EPD_TYPES).find(key => PassportSynchronizationData.EPD_TYPES[key] === epdType) || '0');
    }

    public static getEpdTypeValue(epdType: number): string {
        return PassportSynchronizationData.EPD_TYPES[epdType] || "Unknown_epd_type";
    }
    
    public static getSourceKey(source: string): number {
        return parseInt(Object.keys(PassportSynchronizationData.SOURCES).find(key => PassportSynchronizationData.SOURCES[key] === source) || '0');
    }

    public static getSourceValue(source: number): string {
        return PassportSynchronizationData.SOURCES[source] || 'unknownSource';
    }

    public static getOperatorKey(operator: string): number {
        return parseInt(Object.keys(PassportSynchronizationData.OPERATORS).find(key => PassportSynchronizationData.OPERATORS[key] === operator) || '0');
    }

    public static getOperatorValue(operator: number): string {
        return PassportSynchronizationData.OPERATORS[operator] || 'unknownOperator';
    }
}
