import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {EDepositFilterStatusChangeType} from './deposit-filter-status-change-type.const';
import {isEqual} from 'lodash';

@Component({
  selector: 'deposit-filter-status',
  templateUrl: './deposit-filter-status.component.html'
})
export class DepositFilterStatusComponent implements OnInit {
  @HostBinding('class.depositlist__form-item-list-sub-item') justifyContent = true;

  @Input('filterStatuses') set setFilterStatuses(data: (any | {value: any, text: string, view: string})[]) {
    this.filterStatuses = [
      ...data.map(item => this.createSelectOption(item)),
      this.createOptionSelect()
    ];
  }  

  @Input() public searchStatus: number[] = [];
  @Output() searchStatusChange = new EventEmitter<Array<number>>();

  @Input() isOutOfStockSelected: boolean;
  @Output() isOutOfStockSelectedChange = new EventEmitter<boolean>();

  @Input() isAllSelected: boolean;
  @Input() isSelectEmpty = this.isDefaultStatus.bind(this);
  @Input() defaultStatusValue: {searchStatus: number[], isOutOfStockSelected: boolean, isAllSelected: boolean
  } = {searchStatus: [], isOutOfStockSelected: false, isAllSelected: false};
  @Output() isAllSelectedChange = new EventEmitter<boolean>();

  @Output() change = new EventEmitter<EDepositFilterStatusChangeType>();

  public filterStatuses;
  public isOutOfStockNotSelected = false;
  isFilterStateOfUseVisible = false;
  isFilterStatusVisible = false;

  constructor() { }

  ngOnInit() {
    this.checkAllStatusesSelected();
  }

  private createSelectOption(item) {
    return  {
      text: item.text,
      view: item.view,
      linkToSelectedFunc: this.isOptionSelected.bind(this),
      value: {type: EDepositFilterStatusChangeType.OPTION, value: item.value},
      get selected() {
        return this.linkToSelectedFunc(this.value.value);
      }
    };
  }

  private createOutOfStock() {
    return {
      linkToSelectedFunc: this.isOutOptionSelected.bind(this),
      value: {type: EDepositFilterStatusChangeType.OUT_OF_STOCK},
      get selected() {
        return this.linkToSelectedFunc();
      },
      text: 'OUT_OF_STOCK',
      view: 'Out of stock'
    };
  }

  private createOptionSelect() {
    return  {
      linkToSelectedFunc: this.isAllOptionSelected.bind(this),
      value: {type: EDepositFilterStatusChangeType.ALL_SELECTED},
      get selected() {
        return this.linkToSelectedFunc();
      },
      text: 'SELECT_ALL',
      view: 'Select all'
    };
  }

  public onSelectChange(optionValue) {
    switch (optionValue.type) {
      case EDepositFilterStatusChangeType.OPTION:
        this.addState(optionValue.value);
        break;
      case EDepositFilterStatusChangeType.OUT_OF_STOCK:
        this.toggleOutOfStock();
        break;
      case EDepositFilterStatusChangeType.ALL_SELECTED:
        this.toggleAllSelected();
        break;
    }
    this.change.emit();
  }

  addState(state: number) {
    const index: number = this.searchStatus.indexOf(state);
    if (index > -1) {
      this.searchStatus.splice(index, 1);
    } else {
      this.searchStatus.push(state);
      this.searchStatus.sort((previous, next) => previous - next);
    }
    this.checkAllStatusesSelected();
    this.searchStatusChange.emit(this.searchStatus);
    this.isAllSelectedChange.emit(this.isAllSelected);
  }

  toggleAllSelected() {
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      this.selectAllCheckboxes();
    } else {
      this.searchStatus = [];
      this.isOutOfStockSelected = false;
    }
    this.isOutOfStockSelectedChange.emit(this.isOutOfStockSelected);
    this.searchStatusChange.emit(this.searchStatus);
    this.isAllSelectedChange.emit(this.isAllSelected);
  }

  toggleOutOfStock() {
    this.isOutOfStockSelected = !this.isOutOfStockSelected;
    this.checkAllStatusesSelected();
    this.isOutOfStockSelectedChange.emit(this.isOutOfStockSelected);
    this.isAllSelectedChange.emit(this.isAllSelected);
  }

  private selectAllCheckboxes() {
    this.searchStatus = this.filterStatuses
      .filter(status => status.value.type === EDepositFilterStatusChangeType.OPTION)
      .map((status) => status.value.value);
    this.isOutOfStockSelected = true;
  }

  public isOptionSelected(value) {
    return (this.searchStatus  || []).find((option) => option === value);
  }

  public isOutOptionSelected() {
    return !!this.isOutOfStockSelected;
  }

  public isAllOptionSelected() {
    return !!this.isAllSelected;
  }

  public checkAllStatusesSelected() {
    this.isAllSelected = this.isOutOfStockSelected
      && this.filterStatuses.every((status) => {
          return status.selected;
        });
  }

  private isDefaultStatus() {
    // tslint:disable-next-line:triple-equals
        return this.isAllSelected == this.defaultStatusValue.isAllSelected
          // tslint:disable-next-line:triple-equals
          && this.isOutOfStockSelected == this.defaultStatusValue.isOutOfStockSelected
          && isEqual(this.searchStatus, this.defaultStatusValue.searchStatus || []);
  }
}
