<div
  class="client-edit"
  [formGroup]="clientForm"
  (ngSubmit)="save(clientForm)"
  *ngIf="client"
  [ngClass]="{validated: validated}"
>
  <div class="client-edit__form">
    <div class="client-edit__text">
      <h2>
        <ng-container *ngIf="client.name">{{client.name}}</ng-container>
        <ng-container *ngIf="!client.name">{{'Add recipient' | translate}}</ng-container>
      </h2>
    </div>
    <div class="client-edit__enter">
      <div class="client-edit__company">
        <div class="client-edit__company-view">
          <md-input-container class="client-edit__company-name">
            <input mdInput formControlName="name" class="client-edit__company-name-inp"
            autocomplete="off" placeholder="{{ 'Company Name' | translate }} *" [(ngModel)]="client.name">
            <md-hint
                         *ngIf="isControlInvalid(['name'])"
                         class="error-hint"
                         align="start">
                         {{'Company name' | translate }} {{ getControlErrorMessage(['name']) | async }}
            </md-hint>
          </md-input-container>
          <md-input-container class="client-edit__company-address">
            <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
              <input mdInput formControlName="address" class="client-edit__company-address-inp"
                     placeholder="{{ 'Recipient\'s address' | translate }}"
                     placeAutocomplete
                     [(ngModel)]="client.address">
            </place-autocomplete>
            <md-hint
                     *ngIf="isControlInvalid(['address'])"
                     class="error-hint"
                     align="start">
                     {{'Recipient\'s address' | translate }} {{ getControlErrorMessage(['address']) | async }}
            </md-hint>
          </md-input-container>
        </div>
        <div class="client-edit__company-icon">
          <p><label class="client-edit__company-choose-file">{{ 'Company Logo' | translate }}</label></p>
          <logo-uploader
            [isDisabled]="!client.id"
            [preview]="client.image"
            [targetUrl]="getLogoUploaderUrl()"
            (onLoad)="onLogoUpload($event)"
            (onRemove)="removeClientLogo($event)"
          ></logo-uploader>
        </div>
      </div>
      <div class="client-edit__info">
        <div class="client-edit__info-box">
          <div formArrayName="contacts">
            <div *ngFor="let contact of contactCtrl.controls; let i=index;" [formGroupName]="i">
              <div [formGroup]="contactCtrl.controls[i]">
                <div class="client-edit__info-icon">
                  <button md-fab (click)="removeContact(i)" class="client-edit__info-add">
                    <md-icon>remove</md-icon>
                  </button>
                </div>
                <md-input-container class="client-edit__info-contact">
                  <input mdInput formControlName="name" class="clien-edit"
                  autocomplete="off" placeholder="{{ (i+1) }}. {{ 'Contact Name' | translate }} *">
                  <md-hint
                         *ngIf="isControlInvalid(['name'], 'contact',i)"
                         class="error-hint"
                         align="start">
                         {{'Contact name' | translate }} {{ getControlErrorMessage(['name'] , 'contact',i) | async }}
                  </md-hint>
                </md-input-container>
                <md-input-container class="client-edit__info-phone">
                  <input mdInput formControlName="phone" class="client-edit"
                  autocomplete="off" placeholder="{{ 'Phone' | translate }}">
                  <md-hint
                         *ngIf="isControlInvalid(['phone'], 'contact',i)"
                         class="error-hint"
                         align="start">
                         {{'Phone' | translate }} {{ getControlErrorMessage(['phone'] , 'contact' ,i) | async }}
                  </md-hint>
                </md-input-container>
                <md-input-container class="client-edit__info-email">
                  <input mdInput formControlName="email" class="client-edit"
                  autocomplete="off" placeholder="{{ 'Email' | translate }} *">
                  <md-hint
                         *ngIf="isControlInvalid(['email'], 'contact',i)"
                         class="error-hint"
                         align="start">
                         {{'email' | translate }} {{ getControlErrorMessage(['email'] , 'contact',i) | async }}
                  </md-hint>
                </md-input-container>
                <md-input-container class="client-edit__info-function">
                  <input mdInput formControlName="function" class="client-edit"
                  autocomplete="off" placeholder="{{ 'Function' | translate }}">
                </md-input-container>
                <div class="client-edit__info-icon">
                  <button md-fab (click)="newContact()"  *ngIf="i === contactCtrl.length - 1"
                          class="client-edit__info-add">
                    <md-icon>add</md-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div formArrayName="locations">
            <div *ngFor="let location of locationCtrl.controls; let i=index;" [formGroupName]="i">
              <div [formGroup]="locationCtrl.controls[i]">
                <div class="client-edit__info-icon">
                  <button md-fab (click)="removeLocation(i)" class="client-edit__info-add">
                    <md-icon>remove</md-icon>
                  </button>
                </div>
                <md-input-container class="client-edit__info-delivery">
                  <input mdInput class="client-edit" autocomplete="off" formControlName="name"
                         placeholder="{{ (i+1) }}. {{ 'Delivery Address name' | translate }} *">
                  <md-hint
                         *ngIf="isControlInvalid(['name'] , 'location',i)"
                         class="error-hint"
                         align="start">
                         {{'Delivery Address name' | translate }} {{ getControlErrorMessage(['name'] , 'location',i) | async }}
                  </md-hint>
                </md-input-container>
                <md-input-container class="client-edit__info-address">
                  <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
                    <input mdInput class="client-edit" formControlName="address"
                           placeholder="{{ 'Address' | translate }} *"
                           placeAutocomplete
                           (placeChange)="onPlaceChange($event, location)">
                  </place-autocomplete>
                  <md-hint
                     *ngIf="isControlInvalid(['address'] , 'location',i)"
                     class="error-hint"
                     align="start">
                     {{'Address' | translate }} {{ getControlErrorMessage(['address'],'location',i) | async }}
                  </md-hint>
                </md-input-container>
                <md-input-container class="client-edit__info-description">
                  <input mdInput class="client-edit" autocomplete="off" formControlName="description"
                         placeholder="{{ 'Description' | translate }}">
                </md-input-container>
                <div class="client-edit__info-icon">
                  <button *ngIf="i === locationCtrl.length - 1" md-fab (click)="newLocation()" class="client-edit__info-add">
                    <md-icon>add</md-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="client-edit__button">
        <button class="client-edit__create uppercase"
                (click)="save(clientForm)"
                md-raised-button
                [disabled]="busy"
        >
          <ng-container *ngIf="clientId">{{'Save' | translate}}</ng-container>
          <ng-container *ngIf="!clientId">{{'Create' | translate}}</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
