import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {IConfirmWithHeader} from '../values/interface/confirm-with-header';

@Component({
  selector: 'confirm-with-header',
  templateUrl: './confirm-with-header.component.html',
  styleUrls: ['./confirm-with-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmWithHeaderComponent implements IConfirmWithHeader {
  @Input() confirmButtonText?;
  @Input() cancelButtonText?;
  @Input() title = '';
  @Input() disabled = false;
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();


  confirm() {
    this.onConfirm.emit();
  }

  cancel() {
    this.onCancel.emit();
  }

}
