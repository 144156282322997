import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { CategoryItem } from '../models/category-item.class';
var CategoryService = /** @class */ (function (_super) {
    tslib_1.__extends(CategoryService, _super);
    function CategoryService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'categories';
        _this.namespaceSingular = 'category';
        _this.ModelClass = CategoryItem;
        return _this;
    }
    CategoryService.prototype.getChangeCategoryUrl = function () {
        return this.getEndpoint(this.singularEndpoint + '/request');
    };
    CategoryService.prototype.sendChangeCategory = function (material, description) {
        return this.sendPost(this.getChangeCategoryUrl(), { name: material, description: description })
            .catch(this.onError.bind(this));
    };
    return CategoryService;
}(CrudService));
export { CategoryService };
