import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { ERoleIds } from '../../shared/values/role-ids.enum';
import {SPECIALADMIN_NAVIGATION} from './specialadmin.navigation';
import {ModuleNames} from '../../shared/values/module-names.const';

export const SPECIALADMIN_CONTEXT: IRoleContextConfig = {
  navigation: SPECIALADMIN_NAVIGATION,
  moduleName: ModuleNames.SPECIAL_ADMIN,
  themeClass: 'b-module-specialadmin',
  roleId: ERoleIds.SPECIALADMIN,
  roleText: 'Specialadmin',
  homeUrl: '/special_admin',
  rootPath: 'special_admin'
};
