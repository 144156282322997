import { Injectable } from '@angular/core';
import { LandfillVariables } from './landfill-variables.model';
import {ExtendedCrudService} from '../../../../shared/services/extended-crud.service';
import {Response} from '@angular/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class LandfillVariablesService extends ExtendedCrudService {
  protected namespace = 'landfill-variables';
  protected namespaceSingular = '';
  protected ModelClass = LandfillVariables;

  getLandfillVariables(): Observable<LandfillVariables>  {
    return this.sendGet(this.getEndpoint(this.singularEndpoint))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  public updateLandfillVariables(landfillValue: number, id: number): Observable<LandfillVariables> {
    return this.sendPut((this.getEndpoint(this.singularEndpoint) + '/' + id), {value: landfillValue})
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }
}
