import { Ecosystem } from '../../models/ecosystem.class';
import {SummaryDetailAbstract, SummaryDetailOrder} from '../../models/summary-details.intercface';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../values/ecosystem-file-junction-item-type.const';
import { IEcosystemIndicatorReportSteps } from '../../values/ecosystem-indicator-report.const';
import { AbstractReportStrategyWithDetails } from './abstract-report-strategy.service';
import {EcosystemStep} from '../../models/ecosystem-step.class';


export class EconomicProfitsStrategy extends AbstractReportStrategyWithDetails {
    fileType = ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_ECOSYSTEM_ECONOMIC;
    unit = '€';

    getSteps(allSteps: IEcosystemIndicatorReportSteps): IEcosystemIndicatorReportSteps {
      return {
            virginProducts: allSteps.virginProducts,
            deposits: allSteps.deposits,
            transforms: allSteps.transforms,
            transports: allSteps.transports,
            needs: allSteps.needs
      };
    }

    getOrders(step: EcosystemStep): SummaryDetailOrder[] {
      return step.summary_details.orders.length > 1 ? [step.summary_details.orders[0]] : step.summary_details.orders;
    }

  formatValue<T extends SummaryDetailAbstract>(step: T): string {
        return step.confirmed_price.toString();
    }
    isDataMissing<T extends SummaryDetailAbstract>(errors: Array<any>, step: T): boolean {
        return step.confirmed_price === null;
    }
    otherAdd(ecosystem: Ecosystem, value?: number) {
      this.setOtherTotal(value, (val) => ecosystem.add_other_profits = val);
      return ecosystem.add_other_profits;
    }

    otherSubtract(ecosystem: Ecosystem, value?: number) {
      this.setOtherTotal(value, (val) => ecosystem.subtract_other_profits = val);
      return ecosystem.subtract_other_profits;
    }

    otherDescription(ecosystem: Ecosystem, value?: string) {
      this.setOtherDescription(value, (val) => ecosystem.description_other_profits = val);
      return ecosystem.description_other_profits;
    }

    getTotalErrors(ecosystem: Ecosystem) {
        return ecosystem.economic_total.errors;
    }
}
