<div class="esm-catalog">
  <deposit-need-map [beforeMapUpdate]="beforeUpdateMap" [updateMap]="updateMap"
    (onChangeDepositLocationIds)="setChosenLocationsById($event)">
  </deposit-need-map>
  <header [ngClass]="{'esm-catalog__header': true, 'alone-button': !isSpecialAdminModule()}">
    <ng-container *ngIf="isSpecialAdminModule()">
      <div class="esm-catalog__header-filter" *ngFor="let status of specialAdminFilterStatuses">
        <md-checkbox class="esm-catalog__group--checkbox checkbox-group" [name]="status.text"
          (change)="toggleState(status.value);" checked="true">
          {{status.view | capitalize | translate}}
        </md-checkbox>
      </div>
    </ng-container>
    <div class="esm-catalog__map">
      <i class="icon icon--export"></i>
      <a [routerLink]="" (click)="exportDeposit()" class="pdf-link esm-catalog__map-view">{{ 'Export' | translate }}</a>
    </div>
  </header>

  <section class="esm-catalog__choice">
    <ul class="esm-catalog__choice-list" style="height: auto; min-height: 33px;">
      <li class="esm-catalog__choice-item search-long">
        <i class="icon icon--search"></i>
        <md-input-container class="input-group">
          <input autocomplete="off" test-id="search" mdInput placeholder="{{ 'Search' | translate }}"
            [formControl]="searchControl">
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item suggest">
        <deposit-location-filter field="name" placeholder="{{'Site(s)' | translate}}"
          [availableItems]="availableDepositLocations" [(ngModel)]="chosenDepositLocations"
          (ngModelChange)="loadDeposits()">
        </deposit-location-filter>
      </li>
      <li class="esm-catalog__choice-item suggest">
        <deposit-location-filter field="name" placeholder="{{'Tag(s)' | translate}}"
          [disabled]="chosenDepositLocations.length !== 1"
          [availableItems]="chosenDepositLocations.length === 1 ? chosenDepositLocations[0]?.tags : []"
          [(ngModel)]="chosenTags" (ngModelChange)="loadDeposits()">
        </deposit-location-filter>
      </li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput autocomplete="off" test-id="available-date-from-filter" ng2-datetime-picker date-only="true"
            date-format="DD-MM-YYYY" placeholder="{{ 'Available FROM' | translate }}" [(ngModel)]="availableDateFrom"
            (ngModelChange)="loadDeposits()">
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-dash">-</li>
      <li class="esm-catalog__choice-item">
        <i class="icon icon--calendar"></i>
        <md-input-container class="date input-group">
          <input mdInput autocomplete="off" ng2-datetime-picker date-only="true" date-format="DD-MM-YYYY"
            placeholder="{{ 'Available TO' | translate }}" [(ngModel)]="availableDateTo"
            (ngModelChange)="loadDeposits()">
        </md-input-container>
      </li>
      <li class="esm-catalog__choice-item select">
        <i class="icon icon--gray-sort"></i>
        <md-select class="select-group" [(ngModel)]="sortOrder" (ngModelChange)="loadDeposits()">
          <md-option value="publication_date">{{ 'By date of publication' | translate }}</md-option>
          <md-option value="site">{{ 'By site name' | translate }}</md-option>
          <md-option value="name">{{ 'By name' | translate }}</md-option>
          <md-option value="deposit_owner">{{ 'By deposit owner' | translate }}</md-option>
          <md-option value="availability_date">{{ 'By date of availability' | translate }}</md-option>
        </md-select>
      </li>
    </ul>
  </section>
  <custom-list [records]="records" (onEndOfList)="onEndOfListReached()"></custom-list>
</div>