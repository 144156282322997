/**
 * Created by Aleksandr C. on 9.02.17.
 */
import * as tslib_1 from "tslib";
import { PassportService } from '../services/passport.service';
import { PASSPORT_UNITS, PASSPORT_UNITS_MAP } from '../values/passport-units.const';
import { Material } from './material.class';
import { PassportComponent } from './component.class';
import { NomenclatureItem } from '../../nomenclature/models/nomenclature-item.class';
import { ServiceLocator } from '../../../shared/services/service-locator';
import { ComponentService } from '../services/component.service';
import { EventEmitter } from '@angular/core';
import { MaterialService } from '../services/material.service';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { CustomListUnitCssClass, ECustomListUnit } from '../../../shared/custom-list/interfaces/custom-list.interface';
import { PASSPORT_STATUS } from '../values/passport-status.const';
import { NextUsage } from './next-usage.class';
import { Observable } from 'rxjs/Observable';
import { PassportKeywordJunction } from './passport-keyword-junction.class';
import { TranslateService } from '@ngx-translate/core';
import { UsedEnergy } from './used-energy.class';
import { PASSPORT_NAME_TYPE } from '../values/passport-name-types.const';
import { Manufacturers } from './manufacturers.class';
import { ManufacturersService } from '../services/manufacturers.service';
import { ColorsService } from '../services/colors.service';
import { DEFAULT_RADIO_VALUE, DEFAULT_RADIO_VALUES } from '../values/default-radio-values.const';
import { UsedEnergyService } from '../services/used-energy.service';
import { DatePipe } from '@angular/common';
import { PassportRating } from './passport-rating.class';
import { PASSPORT_TYPES } from '../values/passport-types.const';
import { PassportGtin } from './passport-gtin.class';
import { PassportSynchronizationData } from './passport-synchronization-data.class';
export var PASSPORT_DEFAULT_CATEGORY = null;
export var PASSPORT_DEFAULT_NAME_TYPE = PASSPORT_NAME_TYPE.CATALOGUE;
export var PASSPORT_DEFAULT_RADIO_KEY = DEFAULT_RADIO_VALUES.find(function (i) { return i.key === DEFAULT_RADIO_VALUE.DONTKNOW; }).key;
var Passport = /** @class */ (function (_super) {
    tslib_1.__extends(Passport, _super);
    function Passport(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = PassportService;
        _this.translateService = ServiceLocator.injector.get(TranslateService);
        _this.componentService = ServiceLocator.injector.get(ComponentService);
        _this.materialService = ServiceLocator.injector.get(MaterialService);
        _this.manufacturersService = ServiceLocator.injector.get(ManufacturersService);
        _this.colorsService = ServiceLocator.injector.get(ColorsService);
        _this.usedEnergyService = ServiceLocator.injector.get(UsedEnergyService);
        _this.passportService = ServiceLocator.injector.get(PassportService);
        _this.keywords = [];
        _this.next_usages = [];
        _this.manufacturers = [];
        _this.colors = [];
        _this.used_energy = [];
        _this.onSyncComponents$ = new EventEmitter();
        _this.onSyncManufacturers$ = new EventEmitter();
        /**
         * @deprecated
         * @type {EventEmitter<any>}
         */
        _this.onSyncMaterials$ = new EventEmitter();
        _this.onSyncColors$ = new EventEmitter();
        _this.onSyncDistributionAreas$ = new EventEmitter();
        _this.onSyncUsedEnergy$ = new EventEmitter();
        if (!_this.name) {
            _this.name = '';
        }
        data = data || {};
        if (_this.family === 4 /* FAMILY_VOID */) {
            var regex = '\\s*(' + Object.keys(PASSPORT_UNITS_MAP).join('|') + ')$';
            _this.oldName = _this.name;
            _this.name = _this.name.replace(new RegExp(regex), '');
            _this.oldEnglishName = _this.english_name;
            _this.english_name = _this.name;
            if (_this.composite_name) {
                _this.oldCompositeName = _this.composite_name;
                _this.composite_name = _this.name;
            }
        }
        _this.image = (data.image);
        _this.components = (data.components || data.passport_components || []).map(function (d) { return new PassportComponent(d); });
        _this.nomenclature = (data.nomenclature || []).map(function (d) { return new NomenclatureItem(d); });
        _this.materials = (data.materials || data.passport_materials || []).map(function (mat, index) {
            mat.nomenclature = _this.nomenclature.find(function (nomen) { return nomen.id === mat.nomenclature_id; });
            return new Material(mat);
        });
        _this.next_usages = (data.next_usages || []).map(function (usage) { return new NextUsage(usage); });
        _this.manufacturers = (data.manufacturers || []).map(function (manufacturer) { return new Manufacturers(manufacturer); });
        _this.keywords = (data.keywords || []).map(function (d) { return new PassportKeywordJunction(d); });
        _this.category_id = data.category ? data.category.id : PASSPORT_DEFAULT_CATEGORY;
        _this.name_type = data.name_type ? Number(data.name_type) : PASSPORT_DEFAULT_NAME_TYPE;
        var getRadioValue = function (val) { return typeof val === 'number' ? val : PASSPORT_DEFAULT_RADIO_KEY; };
        //this.c2c_certified = getRadioValue(data.c2c_certified);
        _this.c2c_certified = data.c2c_certified ? data.c2c_certified : DEFAULT_RADIO_VALUE.NO;
        //getRadioValue(data.c2c_certified);
        _this.water_stewardship_certified = getRadioValue(data.water_stewardship_certified);
        _this.social_fairness_certified = getRadioValue(data.social_fairness_certified);
        _this.rating = new PassportRating(data.rating);
        _this.energies = (data.energies || []).map(function (energy) { return new UsedEnergy(energy); });
        _this.category = data.category;
        _this.gtins = (data.gtins || []).map(function (gtin) { return new PassportGtin(gtin); });
        _this.synchronization_data = new PassportSynchronizationData(data.passport_synchronization_data);
        _this.addSynchronizationData(data.passport_synchronization_data);
        return _this;
    }
    Object.defineProperty(Passport.prototype, "supervisor_warning", {
        get: function () {
            if (localStorage.getItem('roles').indexOf('supervisor') > -1) {
                return this.status === PASSPORT_STATUS.PUBLISHED;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Passport.prototype, "unit_text", {
        get: function () {
            var _this = this;
            var unit = PASSPORT_UNITS.find(function (item) { return item.id === _this.unit; });
            // no need to translate it , then we will have different matching if we change the language
            // return unit ? this.translateService.instant(unit.title) : this.unit;
            return unit ? unit.title : this.unit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Passport.prototype, "unitView", {
        get: function () {
            return this.unit_text;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Passport.prototype, "customListUnit", {
        get: function () {
            return {
                'kg': ECustomListUnit.KG,
                'ton(s)': ECustomListUnit.TON,
                'm²': ECustomListUnit.M2,
                'm³': ECustomListUnit.M3,
                'unit': ECustomListUnit.UNIT,
                'piece(s)': ECustomListUnit.PCS,
                'm': ECustomListUnit.M,
                'L': ECustomListUnit.L
            }[this.unit_text];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Passport.prototype, "unitIconCss", {
        // TODO Used in ecosystem step, should be refactored
        get: function () {
            return CustomListUnitCssClass[this.customListUnit];
        },
        enumerable: true,
        configurable: true
    });
    Passport.prototype.fields = function () {
        return [
            'id',
            'product_passport_id',
            'name',
            'english_name',
            'image',
            'thumbnail',
            'status',
            'validity',
            'unit',
            'manufacturer',
            'message',
            'next_usages',
            'description',
            'recommendation',
            'created_at',
            'updated_at',
            'user',
            'category_id',
            'category',
            'c2c_certified',
            'period',
            'period_type',
            'keywords',
            'price',
            'format',
            'type',
            'trade_name',
            'product_refrence',
            'typology',
            'additional',
            'name_type',
            'first_life',
            'recycle_percentage',
            'conversion',
            'dimension_length',
            'dimension_width',
            'dimension_height',
            'purchase_price',
            'appearance',
            'user_id',
            'custom_made_name',
            'composite_name',
            'catalogue_product_name',
            'social_fairness_certified',
            'water_stewardship_certified',
            'for_rating',
            'certification_expiration',
            'certification_level',
            'no_banned_materials',
            'carbon_total',
            'carbon_product',
            'carbon_construction',
            'carbon_end',
            'carbon_conversion',
            'carbon_conversion_description',
            'carbon_conversion_date',
            'used_energy',
            'rating',
            'energies',
            'optimization_strategy',
            'demountability_default',
            'percentage_default',
            'link_reference',
            'distribution_areas',
            'rating_token',
            'rating_token_provided',
            'version',
            'water_cycle',
            'water_conversion',
            'water_construction',
            'water_use_stage',
            'water_operational_energy_use',
            'water_operational_water_use',
            'water_end_of_life',
            'water_resource_recovery',
            'energy_cycle_renewable',
            'energy_product_renewable',
            'energy_construction_renewable',
            'energy_use_stage_renewable',
            'energy_operational_energy_use_renewable',
            'energy_operational_water_use_renewable',
            'energy_end_of_life_renewable',
            'energy_resource_recovery_renewable',
            'energy_cycle',
            'energy_product',
            'energy_construction',
            "energy_use_stage",
            'energy_operational_energy_use',
            'energy_operational_water_use',
            'energy_end_of_life',
            'energy_resource_recovery',
            'cert_version',
            'cert_material_health_level',
            'cert_material_reuse_level',
            'cert_carbon_level',
            'cert_water_level',
            'cert_socials_level',
            'family',
            'gtins',
            'use_stage',
            'operational_energy_use',
            'carbon_operational_water_use',
            'resource_recovery_stage',
            'external_passport_id',
            'synchronization_data',
            'count_versions'
        ];
    };
    Passport.prototype.syncColors = function () {
        var _this = this;
        if (!this.id) {
            this.colors = [];
            return;
        }
        this.colorsService
            .get({ passport_id: this.id })
            .subscribe(function (colors) {
            _this.colors = colors;
            _this.onSyncColors$.emit(_this.colors);
        });
    };
    Passport.prototype.syncUsedEnergy = function () {
        var _this = this;
        if (!this.id) {
            this.used_energy = [];
            return;
        }
        this.usedEnergyService
            .get({ passport_id: this.id })
            .subscribe(function (usedEnergy) {
            _this.used_energy = usedEnergy;
            _this.onSyncUsedEnergy$.emit(_this.used_energy);
        });
    };
    Passport.prototype.syncManufacturers = function () {
        var _this = this;
        if (!this.id) {
            this.manufacturers = [];
            return;
        }
        this.manufacturersService
            .get({ passport_id: this.id })
            .subscribe(function (manufacturers) {
            _this.manufacturers = manufacturers;
            _this.onSyncManufacturers$.emit(_this.manufacturers);
        });
    };
    Passport.prototype.syncComponents = function () {
        var _this = this;
        if (!this.id) {
            this.setComponents([]);
            return;
        }
        this.componentService
            .get({ passport_id: this.id })
            .subscribe(function (components) { return _this.setComponents(components); });
    };
    Passport.prototype.syncDistributionArea = function () {
        var _this = this;
        if (!this.id) {
            this.distribution_areas = [];
            return;
        }
        this.passportService.view(this.id, { expand: 'distribution_areas.country' })
            .subscribe(function (passport) {
            _this.distribution_areas = passport.distribution_areas;
            _this.onSyncDistributionAreas$.emit(_this.distribution_areas);
        });
    };
    Passport.prototype.setComponents = function (components) {
        var _this = this;
        this.syncMaterials()
            .subscribe(function (materials) {
            var _a;
            var defaultComponent = _this.buildDefaultComponent();
            if (components) {
                components.push(defaultComponent);
            }
            (_a = defaultComponent.materials).push.apply(_a, materials);
            _this.components = components;
            _this.onSyncComponents$.emit(_this.components);
        });
    };
    /**
     * Used to get materials NOT linked to components
     */
    Passport.prototype.syncMaterials = function () {
        if (!this.id) {
            return Observable.of([]);
        }
        return this.materialService
            .get({ passport_id: this.id })
            .map(function (materials) { return materials.filter(function (mat) { return !mat.component_id; }); });
    };
    Passport.prototype.isCatalogueProduct = function () {
        return this.name_type === PASSPORT_NAME_TYPE.CATALOGUE;
    };
    Passport.prototype.isCustomProduct = function () {
        return this.name_type === PASSPORT_NAME_TYPE.CUSTOM;
    };
    Passport.prototype.buildDefaultComponent = function () {
        return new PassportComponent({
            name: this.translateService.instant('(misc)'),
            isDefault: true,
            percentage_weight: this.percentage_default,
            demountable: this.demountability_default
        });
    };
    Passport.prototype.getProductName = function (useEnglishName) {
        var _this = this;
        if (useEnglishName === void 0) { useEnglishName = false; }
        var getField = function (field) { return field ? ' - ' + field : ''; };
        // tslint:disable-next-line:max-line-length
        var productName = function (fields) { return "" + (useEnglishName ? _this.english_name : _this.name) + fields.map(function (field) { return getField(field); }).join(''); };
        if (this.isCatalogueProduct()) {
            return productName([this.manufacturer, this.trade_name, this.product_refrence]);
        }
        if (this.isCustomProduct()) {
            return productName([this.typology, this.format, this.additional]);
        }
        return this.name;
    };
    Passport.prototype.beforeSave = function (data) {
        var dataValues = _super.prototype.beforeSave.call(this, data);
        if (dataValues.certification_expiration) {
            try {
                dataValues.certification_expiration = new DatePipe(dataValues.certification_expiration)
                    .transform(dataValues.certification_expiration, 'yyyy-MM-dd');
            }
            catch (e) {
            }
        }
        if (dataValues.family === 4 /* FAMILY_VOID */) {
            dataValues.name = this.oldName;
            dataValues.english_name = this.oldEnglishName;
            dataValues.composite_name = this.oldCompositeName;
        }
        return dataValues;
    };
    Passport.prototype.isPublished = function () {
        return this.status == PASSPORT_STATUS.PUBLISHED;
    };
    Passport.prototype.hasProductPassportId = function () {
        return [PASSPORT_STATUS.PUBLISHED, PASSPORT_STATUS.FROZEN].includes(this.status) || !!this.product_passport_id;
    };
    Passport.prototype.isStatus = function (status) {
        return this.status == status;
    };
    Passport.prototype.isIndependent = function () {
        return this.type == PASSPORT_TYPES.MANUFACTURER;
    };
    Object.defineProperty(Passport.prototype, "isVoid", {
        get: function () {
            return this.family === 4 /* FAMILY_VOID */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Passport.prototype, "isGtinVisible", {
        get: function () {
            return [1 /* FAMILY_LOCAL */, 2 /* FAMILY_MANUFACTURER */].includes(this.family);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Passport.prototype, "isRatingVisible", {
        get: function () {
            return [2 /* FAMILY_MANUFACTURER */].includes(this.family);
        },
        enumerable: true,
        configurable: true
    });
    Passport.prototype.getPassportFamilyBaseOnModule = function (role) {
        if (role === 'manufacturer') {
            return 2 /* FAMILY_MANUFACTURER */;
        }
        else {
            return 1 /* FAMILY_LOCAL */;
        }
    };
    /**
     * Function to inject passport synchronization data into the passport object
     * @param {Object} passportSynchronizationData - The passport synchronization data to put into the passport
     */
    Passport.prototype.addSynchronizationData = function (passportSynchronizationData) {
        if (!passportSynchronizationData) {
            return;
        }
        this.language = passportSynchronizationData.language;
        this.source = passportSynchronizationData.source;
        this.otherSource = passportSynchronizationData.other_source;
        this.operator = passportSynchronizationData.operator;
        this.otherOperator = passportSynchronizationData.other_operator;
        this.documentId = passportSynchronizationData.document_id;
        this.synchronized = passportSynchronizationData.synchronized;
        this.epdType = passportSynchronizationData.epd_type;
    };
    /**
     * Aggregate the synchronization data to send to the back
     * @returns {object}
     */
    Passport.prototype.aggregateSynchronizationData = function () {
        return {
            passport_id: this.id,
            language: this.language,
            source: this.source,
            other_source: this.otherSource,
            operator: this.operator,
            other_operator: this.otherOperator,
            document_id: this.documentId,
            epd_type: this.epdType
        };
    };
    /**
     * Get the name of the epd type of the passport
     * @returns {string}
     */
    Passport.prototype.getEpdTypeString = function () {
        return Passport.EPD_TYPE_DESCRIPTIONS[this.epdType];
    };
    /**
     * Get the name of the source of the passport
     * @returns {string}
     */
    Passport.prototype.getSourceString = function () {
        return Passport.SOURCE_DESCRIPTIONS[this.source];
    };
    /**
     * Get the name of the operator of the passport
     * @returns {string}
     */
    Passport.prototype.getOperatorString = function () {
        return Passport.OPERATOR_DESCRIPTIONS[this.operator];
    };
    Passport.prototype.haveCarbonData = function () {
        return this.carbon_product !== null || this.carbon_construction !== null || this.use_stage !== null ||
            this.operational_energy_use !== null ||
            this.carbon_operational_water_use !== null || this.carbon_end !== null ||
            this.resource_recovery_stage !== null || this.carbon_total !== null;
    };
    /**
     * Get the Gtins and format them to be saved
     * @returns {Array<Object>}
     */
    Passport.prototype.getGtinsForSave = function () {
        return this.gtins.map(function (gtin) {
            return {
                id: gtin.id,
                code: gtin.code
            };
        });
    };
    Passport.EPD_TYPE_DESCRIPTIONS = {
        1: 'FDES individuelle',
        2: 'FDES collective',
        3: 'DED - Données environnementales par défaut',
        4: 'DEC - données environnementales conventionnelles'
    };
    Passport.SOURCE_DESCRIPTIONS = {
        1: 'Inies'
    };
    Passport.OPERATOR_DESCRIPTIONS = {
        1: 'Inies'
    };
    return Passport;
}(ExtendedActiveRecord));
export { Passport };
