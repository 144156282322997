// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// let url = "//api.stag.upc.wount.com";
// let url = process.env.NODE_ENV == 'test'
//   ? "//localhost:8080"
//   : "//api.stag.upc.wount.com";

export const environment = {
  production: false,
  //API:"https://api.stag.upc.wount.com",
  API:"http://localhost:8080",
  // API:"https://api.noah.staging.upcyclea.com",
  ENV:"DEV",
  //DEEPSTREAM_API: "wss://ds.upc.wount.com/deepstream",
  DEEPSTREAM_API: 'localhost:6020'
};
