import { PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
var FloatingDigitNumberAfterComma = /** @class */ (function () {
    function FloatingDigitNumberAfterComma() {
    }
    FloatingDigitNumberAfterComma.prototype.transform = function (valueRaw) {
        var locales = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            locales[_i - 1] = arguments[_i];
        }
        var value = Number(valueRaw);
        if (isNaN(value))
            return null;
        var format = '';
        var valueAbs = Math.abs(value);
        if (valueAbs >= 100) {
            format = '1.0-0';
        }
        else if (valueAbs >= 10) {
            format = '1.1-1';
        }
        else if (valueAbs >= 1) {
            format = '1.2-2';
        }
        else {
            format = '1.3-3';
        }
        return locales[0] ? formatNumber(value, locales[0], format) : formatNumber(value, 'en-US', format);
    };
    return FloatingDigitNumberAfterComma;
}());
export { FloatingDigitNumberAfterComma };
