import * as tslib_1 from "tslib";
/**
 * Created by Aleksandr C. on 7.02.17.
 */
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { USER_CONTEXTS } from '../auth/user-contexts.const';
import { DEFAULT_CONTEXT } from '../navigation/default.context';
import { CurrentUser } from '../auth/current-user.class';
import { ApiService } from './api.service';
import { ADMIN_ESM_OPTIONS } from '../values/role-ids.enum';
var CurrentUserService = /** @class */ (function (_super) {
    tslib_1.__extends(CurrentUserService, _super);
    function CurrentUserService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onLoggedInChecked$ = new EventEmitter();
        _this.currentUserDidChanged = new EventEmitter();
        return _this;
    }
    Object.defineProperty(CurrentUserService.prototype, "isLoggedIn", {
        get: function () {
            return CurrentUser.isLoggedIn;
        },
        set: function (value) {
            CurrentUser.isLoggedIn = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserService.prototype, "isLoggedChecked", {
        get: function () {
            return CurrentUser.isLoggedChecked;
        },
        set: function (value) {
            CurrentUser.isLoggedChecked = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserService.prototype, "infoData", {
        get: function () {
            return CurrentUser.infoData;
        },
        set: function (value) {
            CurrentUser.infoData = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserService.prototype, "loggedInChecked$", {
        get: function () {
            return this.isLoggedChecked
                ? Observable.of(this.isLoggedChecked)
                : this.onLoggedInChecked$.asObservable().first();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserService.prototype, "roleName", {
        get: function () {
            if (this.infoData && this.infoData.roles && this.infoData.roles.length > 0) {
                var special_index = this.infoData.roles.indexOf('special_admin');
                if (special_index !== -1) {
                    return this.infoData.roles[special_index];
                }
                return this.infoData.roles[0];
            }
            else {
                return 'guest';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserService.prototype, "isSpecialAdmin", {
        get: function () {
            return this.roleName === 'special_admin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserService.prototype, "userDefaultContext", {
        get: function () {
            var roleName = this.roleName;
            return USER_CONTEXTS.find(function (context) { return context.apiModuleName === roleName || context.moduleName === roleName; }) || DEFAULT_CONTEXT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserService.prototype, "isRestrictedAdmin", {
        get: function () {
            return this.infoData.plan_id === ADMIN_ESM_OPTIONS.RESTRICTED_ADMIN;
        },
        enumerable: true,
        configurable: true
    });
    CurrentUserService.prototype.hasRoles = function () {
        return this.infoData && this.infoData.roles && this.infoData.roles.length > 0;
    };
    CurrentUserService.prototype.hasContext = function (roleName) {
        return this.hasRoles() && this.infoData.roles.indexOf(roleName) > -1;
    };
    CurrentUserService.prototype.getContext = function (roleName) {
        return USER_CONTEXTS.find(function (context) { return context.apiModuleName === roleName || context.moduleName === roleName; });
    };
    CurrentUserService.prototype.getAvailableContexts = function () {
        var _this = this;
        return this.hasRoles()
            ? USER_CONTEXTS.filter(function (context) {
                return _this.infoData.roles.indexOf(context.moduleName) > -1
                    || _this.infoData.roles.indexOf(context.apiModuleName) > -1;
            })
            : [];
    };
    CurrentUserService.prototype.login = function (data) {
        var _this = this;
        console.log("Fichier de login 1");
        console.log(this.getEndpoint('user/login'));
        return this
            .sendPost(this.getEndpoint('user/login'), data)
            .do(function () {
            _this.isLoggedIn = true;
        });
    };
    CurrentUserService.prototype.resetPassword = function (password, token) {
        return this
            .sendPost(this.getEndpoint('user/reset-password'), { password: password, token: token })
            .do(function () {
            console.log('email sent');
        });
    };
    CurrentUserService.prototype.requestPassword = function (username) {
        return this
            .sendPost(this.getEndpoint('user/request-password-reset'), { username: username })
            .do(function () {
            console.log('email sent');
        });
    };
    CurrentUserService.prototype.logout = function () {
        var _this = this;
        return this
            .sendPost(this.getEndpoint('user/logout'))
            .do(function () {
            localStorage.clear();
            _this.isLoggedIn = false;
        });
    };
    CurrentUserService.prototype.roles = function () {
        return this.sendGet(this.getEndpoint('user/roles'));
    };
    CurrentUserService.prototype.info = function () {
        return this.sendGet(this.getEndpoint('user/info'));
    };
    CurrentUserService.prototype.isAuth = function () {
        var _this = this;
        return this.info()
            .do(function (data) { return _this.setLoginData(data); })
            .map(function (data) { return true; })
            .catch(function (err, caught) {
            console.log(err);
            return Observable.of(false);
        })
            .do(function (result) {
            if (!result) {
                localStorage.removeItem('username');
            }
            _this.isLoggedIn = result;
            _this.isLoggedChecked = true;
            _this.onLoggedInChecked$.emit(_this.isLoggedChecked);
        });
    };
    CurrentUserService.prototype.setLoginData = function (data) {
        this.infoData = data.json();
        console.log('setLoginData ', this.infoData);
        localStorage.setItem('username', this.infoData.username);
        localStorage.setItem('roles', JSON.stringify(this.infoData.roles));
        var language = this.infoData.language;
        if (language) {
            localStorage.setItem('language', language);
        }
        localStorage.setItem('avatar_url', JSON.stringify(this.infoData.avatar_url));
    };
    CurrentUserService.prototype.markAsLoggedIn = function () {
        this.isLoggedIn = true;
        this.isLoggedChecked = true;
        this.onLoggedInChecked$.emit(this.isLoggedChecked);
    };
    CurrentUserService.prototype.activate = function (password, token) {
        return this
            .sendPost(this.getEndpoint('user/newpw'), { password: password, token: token });
    };
    CurrentUserService.prototype.isUsernameStored = function () {
        return !!localStorage.getItem('username');
    };
    return CurrentUserService;
}(ApiService));
export { CurrentUserService };
