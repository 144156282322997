<article class="company-sites__container">
  <header class="company-sites__header">{{'Site(s)' | translate}}</header>
  <section class="company-sites__menu">
    <div class="company-sites__menu-filters">
      <i class="icon icon--search"></i>
      <md-input-container class="input-group">
        <input mdInput
               autocomplete="off"
               placeholder="{{ 'Search' | translate }}"
               [(ngModel)]="search"
               (ngModelChange)="searchChanged$.emit($event)"
        >
      </md-input-container>
    </div>
    <div class="company-sites__menu-actions">
      <button class="company-sites__menu-actions-add background-primary-color" (click)="createNewSite()">
        {{'+ '}}{{'add a new site' | translate}}
      </button>
    </div>
  </section>
  <section class="company-sites__list-container">
      <ul *ngFor="let item of records" class="company-sites__list">
        <li class="company-sites__list-item-check">
          <md-icon *ngIf="item.checked" class="company-sites__list-item-check">check</md-icon>
        </li>
        <li class="company-sites__list-item company-sites__list-item--block">
          <div class="company-sites__list-item-name">{{item.name}}</div>
          <div class="company-sites__list-item-address">{{item.address}}</div>
        </li>
        <li class="company-sites__list-item--no-padding">
          <div class="company-sites__list-item-sub">
            <span class="company-sites__list-item-status" *ngIf="!!item.site_status">
              {{DEPOSIT_LOCATION_STATUS_NAME[item.site_status] | translate}}
            </span>
            <span *ngIf="(item.end_of_work && item.site_status === DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION)|| item.is_expired_by_superadmin"
              [ngClass]="{'company-sites__list-item-end-date': true
              , 'company-sites__list-item-end-date--expire': !!getSiteExpireDate(item)}">
              {{showExpireOrEndOfWorkText(item)}}
              <span class="company-sites__list-item-end-date--bold">
              {{getSiteExpireDate(item)| date: 'dd-MM-yyyy'}}</span>
            </span>
          </div>
        </li>
        <li class="company-sites__list-item company-sites__list-item-floor-area">
          <span class="company-sites__list-item-floor-area-value">
            {{(item.floor_area || 0) | number:'' :'fr'}}
          </span>
          <span>sqm</span>
        </li>
        <li class="company-sites__list-item">
          <md-icon md-icon-button [mdMenuTriggerFor]="menu"
                   class="company-sites__list-item-menu">density_medium</md-icon>
          <md-menu #menu="mdMenu" >
            <button md-menu-item (click)="openSiteView(item)">{{'Site details' | translate}}</button>
            <button md-menu-item (click)="removeSite(item)">{{'Delete' | translate}}</button>
          </md-menu>
        </li>
      </ul>
  </section>
  <footer class="company-sites__totals">
    <div class="company-sites__totals-item company-sites__totals-item--offset-right">
      <span class="company-sites__totals-item--label">{{'total' | translate | uppercase}}</span>
      <span>{{' '}}{{'Site(s)' | translate | lowercase}}</span>
      <span class="company-sites__totals-item--major company-sites__totals-item--major-small">{{records.length}}</span>
    </div>
    <div class="company-sites__totals-item">
      <span class="company-sites__totals-item--label">{{'total' | translate | uppercase}}</span>
      <span>{{' '}}{{'area' | translate}}{{' : '}}</span>
      <span class="company-sites__totals-item--major">{{totalSqm()}}</span>
      <span class="company-sites__totals-item--shift-down">sqm</span>
    </div>
  </footer>
</article>
