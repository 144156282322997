import { EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { DepositLocation } from '../models/deposit-location.class';
import * as i0 from "@angular/core";
var DepositLocationTemporaryStorageService = /** @class */ (function () {
    function DepositLocationTemporaryStorageService() {
        this.onLoad$ = new EventEmitter();
        this.maxAllowedInstance = 100;
        this.maxAllowedItems = 999;
        this.maxUploadInstanceAtOnce = 10;
        this.resetObservers = true;
        this.uploaderMap = new Map();
        this.loadingLocationCount = 0;
        this.loadingIndex = 0;
        this.locationList = [];
    }
    DepositLocationTemporaryStorageService.prototype.setResetObservers = function (state) {
        this.resetObservers = state;
    };
    DepositLocationTemporaryStorageService.prototype.getUploader = function (location) {
        var uploaderIns = this.uploaderMap.get(location);
        if (uploaderIns) {
            var currentQueue = uploaderIns.queue.length;
            uploaderIns.options.queueLimit = this.getAvailableItemNumber() + currentQueue;
            return uploaderIns;
        }
        return null;
    };
    DepositLocationTemporaryStorageService.prototype.getAvailableItemNumber = function () {
        return Object.values(this.uploaderMap)
            .reduce(function (totalAvailable, uploader) {
            return totalAvailable - uploader.queue.length;
        }, this.maxAllowedItems);
    };
    DepositLocationTemporaryStorageService.prototype.getDepositLocations = function () {
        return this.locationList;
    };
    DepositLocationTemporaryStorageService.prototype.getAvailableInstanceNumber = function () {
        return this.maxAllowedInstance - this.locationList.length;
    };
    DepositLocationTemporaryStorageService.prototype.addLocation = function () {
        if (this.locationList.length > this.maxAllowedInstance) {
            return { location: null, fileUploader: null };
        }
        var location = new DepositLocation();
        var fileUploader = new FileUploader({ queueLimit: this.getAvailableItemNumber() });
        this.locationList.push(location);
        this.uploaderMap.set(location, fileUploader);
        return { location: location, fileUploader: fileUploader };
    };
    DepositLocationTemporaryStorageService.prototype.remove = function (item) {
        this.locationList = this.locationList.filter(function (location) { return !Object.is(location, item); });
        this.uploaderMap.delete(item);
    };
    DepositLocationTemporaryStorageService.prototype.isLoading = function () {
        return this.loadingLocationCount !== 0;
    };
    DepositLocationTemporaryStorageService.prototype.configUploader = function (url, location) {
        var uploaderIns = this.uploaderMap.get(location);
        if (uploaderIns) {
            uploaderIns.setOptions({ url: url });
            if (this.resetObservers) {
                uploaderIns.onSuccessItem = function () { };
                uploaderIns.onCompleteItem = function () { };
                uploaderIns.onCompleteAll = function () { };
            }
        }
        return uploaderIns;
    };
    DepositLocationTemporaryStorageService.prototype.uploadTo = function (location, url) {
        var uploader = this.configUploader(url, location);
        if (uploader) {
            uploader.uploadAll();
        }
    };
    DepositLocationTemporaryStorageService.prototype.uploadAll = function (user) {
        if (!this.isLoading()) {
            this.loadingIndex = 0;
            this.uploadChunk(user, this.locationList.slice());
        }
    };
    DepositLocationTemporaryStorageService.prototype.uploadChunk = function (user, list) {
        var _this = this;
        if (this.loadingIndex >= list.length) {
            if (!this.isLoading()) {
                this.onLoad$.emit();
            }
            return;
        }
        Array(this.maxUploadInstanceAtOnce).fill(1).forEach(function () {
            if (list.length <= _this.loadingIndex) {
                return;
            }
            var site = list[_this.loadingIndex++];
            _this.loadingLocationCount += 1;
            site.user_id = user.id;
            site.save().subscribe(function (item) {
                _this.uploadTo(site, item.getFileUploadUrl());
                --_this.loadingLocationCount;
                _this.uploadChunk(user, list);
            }, function () {
                --_this.loadingLocationCount;
                _this.uploadChunk(user, list);
            });
        });
    };
    DepositLocationTemporaryStorageService.ngInjectableDef = i0.defineInjectable({ factory: function DepositLocationTemporaryStorageService_Factory() { return new DepositLocationTemporaryStorageService(); }, token: DepositLocationTemporaryStorageService, providedIn: "root" });
    return DepositLocationTemporaryStorageService;
}());
export { DepositLocationTemporaryStorageService };
