<div class="independent-toggle__container">
    <div class="independent-toggle__premium-toggle">
        <mat-slide-toggle (change)="changeEmtier($event)" [checked]="isChecked">
        </mat-slide-toggle>
    </div>
    <div>
        <img class="independent-toggle__premium-img" src="{{img}}" alt="">
    </div>
    <div class="independent-toggle__premium-text">
        <label>
            {{ text | translate}}
        </label>
    </div>
</div>