<div class="import-deposit-columns" [formGroup]="form">
  <section class="import-deposit-columns__header">
  <div class="flex-between">
    <label class="passport__tab--label import-deposit-columns__header-label">{{ 'COLUMN_SELECTION' | translate }}</label>
    <button class="import-deposit-columns__reset" md-raised-button
            (click)="clearFilters()"
            [disabled]="importValidatorsService.isEmptyField(form)">{{ 'CLEAR_ALL_FIELDS' | translate}}</button>
  </div>
    <div class="import-deposit-columns__header-label--small">
        {{ 'SPECIFY_XLS_COLUMNS' | translate}} :
        <div class="import-deposit-columns__header-label--small">{{ '* required columns' | translate}}</div>
    </div>
  </section>
  <div class="import-deposit-columns__row-container import-deposit-columns__row--odd-stripe">
    <ng-container *ngFor="let item of list">
      <ng-container *ngIf="item.beforeTemplate">
        <ng-container *ngTemplateOutlet="item.beforeTemplate; context: item.beforeTemplateContext || {}"></ng-container>
      </ng-container>
      <deposit-import-column-row *ngIf="!item.hideDefault"
                                 [className]="item.className"
                                 label="{{item.name | translate }}"
                                 [controlName]="item.controlName"
                                 [required]="item.required && item.required()"
                                 [showWarningSign]="item.showWarningSign === undefined ? true : item.showWarningSign"
                                 [columnName]="item.columnLetter"
                                 [description]="item.description | translate"
                                 [additionalDescription]="item.additionalDescription | translate"
                                 (onBlurColumn)="columnBlur$.emit($event)"
      >
        <ng-content></ng-content>
        <ng-container *ngIf="item.middleTemplate">
          <ng-container *ngTemplateOutlet="item.middleTemplate; context: item.middleTemplateContext || {}"></ng-container>
        </ng-container>
      </deposit-import-column-row>
      <ng-container *ngIf="item.afterTemplate">
        <ng-container *ngTemplateOutlet="item.afterTemplate"></ng-container>
      </ng-container>
    </ng-container>
    <ng-content></ng-content>
  </div>
</div>
