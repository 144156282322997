/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i3 from "@angular/material";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./spinner-dialog.component";
var styles_SpinnerDialogComponent = [i0.styles];
var RenderType_SpinnerDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerDialogComponent, data: {} });
export { RenderType_SpinnerDialogComponent as RenderType_SpinnerDialogComponent };
function View_SpinnerDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "first-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "second-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "third-dot"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."]))], null, null); }
export function View_SpinnerDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "spinner-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "md-progress-spinner", [["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0], [2, "mat-progress-spinner", null]], null, null, i2.View_MdProgressSpinner_0, i2.RenderType_MdProgressSpinner)), i1.ɵdid(3, 180224, null, 0, i3.MdProgressSpinner, [i1.NgZone, i1.ElementRef, i1.Renderer], { mode: [0, "mode"] }, null), i1.ɵdid(4, 16384, null, 0, i3.MdProgressSpinnerCssMatStyler, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "spinner-dialog__caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerDialogComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = "indeterminate"; _ck(_v, 3, 0, currVal_5); var currVal_7 = _co.isLoadingDotsShown; _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3)._ariaValueMin; var currVal_1 = i1.ɵnov(_v, 3)._ariaValueMax; var currVal_2 = i1.ɵnov(_v, 3).value; var currVal_3 = i1.ɵnov(_v, 3).mode; var currVal_4 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.caption)); _ck(_v, 6, 0, currVal_6); }); }
export function View_SpinnerDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "spinner-dialog", [], null, null, null, View_SpinnerDialogComponent_0, RenderType_SpinnerDialogComponent)), i1.ɵdid(1, 114688, null, 0, i6.SpinnerDialogComponent, [i3.MD_DIALOG_DATA, i3.MdDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpinnerDialogComponentNgFactory = i1.ɵccf("spinner-dialog", i6.SpinnerDialogComponent, View_SpinnerDialogComponent_Host_0, {}, {}, []);
export { SpinnerDialogComponentNgFactory as SpinnerDialogComponentNgFactory };
