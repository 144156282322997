<div *ngIf="reportData">
  <report-pdf [pageNumber]=1 [reportHeader]="reportHeader" *ngIf="showSitesInOnePage">
    <report-pdf-site-list [sites]="reportData.sites"></report-pdf-site-list>
    <div class="first-block__body">
      <div class="first-block__body--description">
        <p class="first-block__body--description-text"
          [innerHTML]="'CARBON_FOOT_PRINT_TITLE_REPORT_PDF' | translate"></p>
      </div>
  </div>
  </report-pdf>


  <div *ngFor="let reportType of reportTypes, let index = index">
    <report-pdf 
    [pageNumber]="index + getStartingNumber()" [reportHeader]="reportHeader" [subtitle]="getSubTitleText(reportType)" [noneItalicSubtitle]="true">
      <report-pdf-site-list *ngIf="!showSitesInOnePage"
                            [sites]="reportData.sites"
                            [isLongVersion]="true"
                            [measureRows]="false"
                            [isGenerateFromFile]="reportData.created_by_file"
      ></report-pdf-site-list>
    <div *ngIf="!isEmptySpecificProductCategory(reportType)" class="first-block">
      <div class="first-block__body">
        <div class="first-block__body--description">
          <span class="first-block__body--description-text"
            [innerHTML]="getCarbonFootprintDescriptionText(reportType) | translate"></span>
        </div>
        <div class="first-block__body--main-charts">
          <div class="first-block__body--main-charts-headline"
          [ngClass]="{'first-block__body--main-charts--ic-component': isReportTypeIcComponent(reportType) }">
            <span class="first-block__body--main-charts-headline--type">{{ getMainChartHeadlineText(reportType)}}&nbsp;-&nbsp;</span>
            {{'all deposits' | translate}}
          </div>
          <div *ngIf="isReportTypeIcComponentOrConstruction(reportType)" class="first-block__body--main-charts-living-area">
            {{ 'Living area' | translate}}
            :&nbsp;<strong>{{ result.aggregate.living_area_total}}</strong>&nbsp;
            {{ 'sqm' | translate}}
          </div>
          <div *ngIf="isReportTypeIcConstruction(reportType)"  class="first-block__body--main-charts-building-site">
            {{ 'Ic' | translate }} <sub>{{ 'chantier' | translate }}</sub>
            :&nbsp;<strong>{{ result.aggregate.ic_construction_co2 }}</strong>&nbsp;kg Co<sub>2</sub> eq. / {{ 'sqm' | translate }}
          </div>
          <div class="first-block__body--main-charts-circle">
            <p class="first-block__body--main-charts-circle-amount">
              {{ getCarbonFootPrintCircleAmount(reportType).value | floatingDigitNumberAfterComma:'fr' | decimalSeparator: ','}} </p>
            <p
              class="first-block__body--main-charts-circle-text">
              {{getCarbonFootPrintCircleAmount(reportType).unit}}
              <span *ngIf="isReportTypeIcComponentOrConstruction(reportType)">Co<sub>2</sub> eq. / {{ 'sqm' | translate }}</span>
              <span *ngIf="!isReportTypeIcComponentOrConstruction(reportType)">CO<sub>2</sub> eq.</span>
            </p>
            <div class="first-block__body--main-charts-circle-prompt">
            </div>
          </div>

          <div class="first-block__body--missing-data-wrapper">
            <div class="first-block__flex" *ngIf="shouldDisplayPartialData(reportType)"></div>
            <div class="first-block__body--main-charts-amount first-block__flex">
              <p><span class="first-block__body--main-charts-amount-totals">
                <span [ngClass]="{'first-block__partial-data': shouldDisplayPartialData(reportType)}">{{ getTotalConsideredDepositsByReportType(reportType) }}</span> / {{result.aggregate.total_deposit_count}}
              </span>
                {{ 'deposits considered' | translate }}</p>
              <p><span class="first-block__body--main-charts-amount-totals">
                <span [ngClass]="{'first-block__partial-data': shouldDisplayPartialData(reportType)}">{{ getTotalConsideredWeightByReportType(reportType) | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}</span>
                /
                {{result.aggregate.total_weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}</span> {{ 'tons of deposits' | translate }}
              </p>
            </div>
            <div class="first-block__body--main-charts-amount first-block__flex first-block__missing-data-content" *ngIf="shouldDisplayPartialData(reportType)" >
        
              <i class="icon icon--exclamation-point-red"></i>
              <div class="first-block__partial-data">{{ getTranslation(reportType) }}</div>
            </div>
          </div>

          <hr class="first-block__body--main-charts-hr">
          <div class="first-block__body--main-charts-chart-wrapper">
            <bar-chart
            *ngIf="!isReportTypeIcConstruction(reportType)"
            [firstFootprint]="getSortedProductsBy(getTypeTotalValueParameter(reportType), null, getDiscriminantProperty(reportType))[0]"
            [secondFootprint]="getSortedProductsBy(getTypeTotalValueParameter(reportType), null, getDiscriminantProperty(reportType))[1]"
            [thirdFootprint]="getSortedProductsBy(getTypeTotalValueParameter(reportType), null, getDiscriminantProperty(reportType))[2]"
            [isReportPdf]="true"
            [reportType]="reportType">
          </bar-chart>
          </div>

        </div>
      </div>
      <span *ngIf="isReportTypeIcComponentOrConstruction(reportType)" class="first-block__body--bottom-description">
        *{{ 'FRENCH_RE2020_EQUIVALENT_INDICATORS_TOOLTIP' | translate}}
      </span>
    </div>
    <section *ngIf="isEmptySpecificProductCategory(reportType)" class="section-error">
      <h3 class="section-error__title">{{ 'No deposit considered' | translate }}</h3>
    </section>
  </report-pdf>
  <div *ngIf="shouldDisplayTable(reportType)">
    <report-pdf *ngFor="let table of getCategoriesTable(reportType); let i = index" [pageNumber]="initPageNumber()" [subtitle]="getSubTitleText(reportType)" [noneItalicSubtitle]="true"
    [reportHeader]="reportHeader" >
    <div class="second-block">
      <div class="second-block__title--wrapper">
        <h2 class="second-block__title--item">{{'ALL CATEGORIES OF PRODUCTS' | translate}}</h2>
      </div>
      <div class="second-block__table">
        <div class="second-block__table--row second-block__table--row-default">
          <p class="second-block__table--row-title">{{'Category' | translate}}</p>
          <p class="second-block__table--row-title">{{'Number of deposits considered' | translate}}</p>
          <p class="second-block__table--row-title">{{'Weight (Tons)' | translate}}</p>
          <p class="second-block__table--row-title">{{'Percentage of total weight' | translate}}</p>
          <div class=second-block__table--row-title>
            <p >{{'Carbon footprint' | translate}}</p>
            <p class="second-block__table--row-title-description" >{{ getCategorySubtitleTextForCarbonFootprint(reportType) | translate}}</p>
          </div>
          <div  class="second-block__table--row-title">
            <p>{{'Emission intensity' | translate}}</p>
            <p class="second-block__table--row-title-description">{{'(Tons CO2 eq. /Ton of products)' | translate}}
          </p>
        </div>
        </div>
        <div class="second-block__table--row-wrapper">
          <div *ngFor="let item of table.pageContent; let index = index;" >
            <div class="second-block__table--row" *ngIf="index <  getTableRenderingBreakPoint(table.pageContent)" >
              <p
              [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
              {{item['category'].name | translate}}
            </p>
            <p
              [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
              {{getDepositAmount(item, reportType) | number: '1.0-0':'fr' | decimalSeparator:','}}
            </p>
            <p
              [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
              {{getWeight(item, reportType) | floatingDigitNumberAfterComma: 'fr' | decimalSeparator:','}}
            </p>
            <p
              [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
              {{getMassPercentage(item, reportType) | number: '1.1-1':'fr' | decimalSeparator:','}}%
            </p>
            <p
              [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
              {{getCarbonValue(item, reportType) | floatingDigitNumberAfterComma: 'fr' | decimalSeparator:','}}
            </p>
            <p
              [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
              {{shoudDisplayEmissionColumn(reportType) ? ( getEmissionValue(item, reportType)| floatingDigitNumberAfterComma: 'fr' | decimalSeparator:',') : ('Unknown' | translate)}}
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </report-pdf>
  </div>
  
  </div>
 

  <report-pdf *ngFor="let table of errorsTable; let i = index" [pageNumber]="getPageNumber()" 
    [reportHeader]="reportHeader">
    <div class="third-block">
      <div class="third-block__title--wrapper">
        <h2 class="third-block__title--item">
          {{'Error report' | translate}} (<span class="third-block__title--item-span">{{getAmountOf('errors')}}
            {{'errors' | translate}}, {{getAmountOf('warnings')}} {{'warnings' | translate}}</span>)
        </h2>
      </div>
      <div class="third-block__body">
        <div class="third-block__body--description">
          <p [innerHTML]="'ERROR_REPORT_DESCRIPTION_INTRO' | translate"></p>
          <br>
          <p><i class="icon--admin-warning icon"></i>{{'ERROR_REPORT_DESCRIPTION_WARNING' | translate}}</p>
          <br>
          <p><i class="icon--admin-error icon"></i>{{'ERROR_REPORT_DESCRIPTION_ERROR' | translate}}</p>
          <br>
          <p [innerHTML]="'ERROR_REPORT_DESCRIPTION_OUTRO' | translate"></p>
        </div>
       
        <div *ngIf="reportData.is_asynchronous_generated" class="third-block__body--table">
          <div class="third-block__body--table-row">
            <div class="third-block__body--table-row-title">
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'NUMBER_DEPOSITS_CONSIDERED' | translate}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'Error explanation' | translate}}</p>
            </div>
          </div>
          <div *ngFor="let item of table.pageContent; let index = index" class="third-block__body--table-row">
            <div class="third-block__body--table-row-item">
              <i [ngClass]="[isError(item['type']) ? 'icon--admin-error' : 'icon--admin-warning', 'icon']"></i>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{item.counter}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{getErrorMessage(item)}}</p>
            </div>
          </div>
        </div>
        
        <div *ngIf="!reportData.is_asynchronous_generated" class="third-block__body--table">
          <div class="third-block__body--table-row">
            <div class="third-block__body--table-row-title">
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'Deposit name' | translate}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'Error explanation' | translate}}</p>
            </div>
          </div>
          <div *ngFor="let item of table.pageContent; let index = index" class="third-block__body--table-row">
            <div class="third-block__body--table-row-item">
              <i [ngClass]="[isError(item['type']) ? 'icon--admin-error' : 'icon--admin-warning', 'icon']"></i>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{item.deposit_name}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{getErrorMessage(item)}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </report-pdf>
</div>