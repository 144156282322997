import {DataProviderWithPagination} from '../../../shared/models/data-provider-with-pagination.class';
import {DepositLocation} from './deposit-location.class';
import {FormControl} from '@angular/forms';
import {EDepositLocationStatus} from '../values/deposit-location-status.enum';
import {type} from 'os';

export class DepositLocationFilter extends DataProviderWithPagination<DepositLocation> {
  expand = 'use_count,floor_area';
  sort = 'name';
  assigned: number;
  search = new FormControl('');
  site_status = [EDepositLocationStatus.STORAGE, EDepositLocationStatus.IN_CONSTRUCTION
    , EDepositLocationStatus.IN_OPERATION];
  is_not_used = true;
  is_used = true;
  active =  false;
  pagination = false;
  page: number;

  private filterEqualOption: Partial<keyof DepositLocationFilter>[] = [
    'expand',
    'is_not_used',
    'is_used',
    'sort'
  ];

  private extraFilters: Partial<keyof DepositLocationFilter>[] = [
    'site_status', 'active'
  ];

  public addExtraFilterAsDefault(keys: Array<keyof DepositLocationFilter>) {
    const keysSet = new Set(this.extraFilters);
    this.filterEqualOption.push(...keys.filter(key => keysSet.has(key)));
  }

  buildOptions(): Array<any[]> {
    const options: any = {...this.filterEqualOption.reduce((acc, key) => {
      const addIfNotEmpty = () => this[key] ? acc[key] = this[key] : null;
      if (typeof this[key] === 'object') {
        if (Array.isArray(this[key]) && (this[key] as any).length) {
            acc[key] = this[key];
          }
        } else {
          addIfNotEmpty();
        }
        return acc;
      }, {})
    };
    if (this.assigned != null) {
      options.assigned = this.assigned;
    }
    if (this.search.value) {
      options.search = this.search.value;
    }
    if (this.pagination) {
      options.pagination = true;
      if (this.page) {
        options.page = this.page;
      }
    }
    // This is needed due to PHP needing brackets to understand that the 
    // If you don't put the brackets, only one of the values will be read
    if (options.site_status) {
      options["site_status[]"] = [].concat(options.site_status);
      delete options.site_status;
    }
    return options;
  }
}
