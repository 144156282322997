import { Component, EventEmitter,Input, Output } from '@angular/core';
import { MdDialog } from '@angular/material';
import { ViewNomenclatureDialogComponent } from '../../../entities/passport/dialogs/view-nomenclature/view-nomenclature.component';
import { NomenclatureItem } from '../../../entities/nomenclature/models/nomenclature-item.class';

@Component({
  selector: 'app-material-search',
  templateUrl: './material-search.component.html',
  styleUrls: ['./material-search.component.scss']
})
export class MaterialSearchComponent {
  @Input() materials: NomenclatureItem[] = [];
  @Output() materialsChange = new EventEmitter<NomenclatureItem[]>();

  constructor(public dialog: MdDialog) {}

  openNomenclatureDialog() {
    const dialogRef = this.dialog.open(ViewNomenclatureDialogComponent, {
      width: '80%',
      height: '80%'
    });

    dialogRef.afterClosed().subscribe((result: NomenclatureItem) => {
      if (result) {
        this.materials.push(result);
        this.materialsChange.emit(this.materials);
      }
    });
  }

  removeMaterial(material: NomenclatureItem, event: Event) {
    event.stopPropagation();
    const index = this.materials.indexOf(material);
    if (index >= 0) {
      this.materials.splice(index, 1);
      this.materialsChange.emit(this.materials);
    }
  }
}
