import * as tslib_1 from "tslib";
import { ClientService } from '../service/client.service';
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Contact } from './contact.class';
import { ClientLocation } from './location.class';
import { Observable } from 'rxjs/Observable';
import { ContactService } from '../service/contact.service';
import { ServiceLocator } from '../../../shared/services/service-locator';
var Client = /** @class */ (function (_super) {
    tslib_1.__extends(Client, _super);
    function Client() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = ClientService;
        _this.contactService = ServiceLocator.injector.get(ContactService);
        _this.published_needs_count = 120;
        _this.treated_needs_count = 2;
        return _this;
    }
    Client.prototype.fields = function () {
        return [
            'id',
            'name',
            'verified',
            'address',
            'logo',
            'image',
            'contact_name',
            'description',
            'sirent',
            'client_contacts',
            'client_locations',
            'created_at',
            'updated_at',
            'published_needs',
            'treated_needs',
            'user_id'
        ];
    };
    ;
    Client.prototype.getContacts = function () {
        var _this = this;
        if (this.client_contacts) {
            return Observable.of(this.client_contacts);
        }
        else {
            return this.contactService.get({ client_id: this.id }).map(function (contacts) {
                _this.client_contacts = contacts;
                return contacts;
            });
        }
    };
    Client.prototype.canDelete = function () {
        this.build();
        return this.service.canDelete(this.id);
    };
    Client.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        try {
            if (json.client_contacts) {
                json.client_contacts = json.client_contacts.map(function (item) { return new Contact(item); });
            }
            if (json.client_locations) {
                json.client_locations = json.client_locations.map(function (item) { return new ClientLocation(item); });
            }
        }
        catch (error) {
            console.error(error);
        }
        return json;
    };
    return Client;
}(ActiveRecord));
export { Client };
