import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'toggle-independent',
    templateUrl: 'toggle-independent.component.html',
    styleUrls: ['toggle-independent.component.scss'],
})

export class ToggleIndependent {
    @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() text: string;
    @Input() img: string;
    @Input() isChecked : boolean = false;

    changeEmtier(event: Event) {
        this.toggle.emit(event['checked']);
    }
}
