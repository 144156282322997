<passport-edit-form
  [passport]="deposit?.passport"
  [listener]="eventStream$"
  [parentModule]="parentModule"
  (onChange)="onChangePassport($event)"
  (onSaveComplete)="onSaveCompleteHandler($event)"
  (onSaveError)="onSaveErrorHandler($event)"
  [class.highlighted]="controlStates?.passport_id?.highlighted"
  [controlStates]="controlStates?.passport"
  (onInvalidField)="onInvalidFieldHandler($event)"
  [spinnerEnabled]="spinnerEnabled"
>
</passport-edit-form>
