import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MdDialog } from '@angular/material';
import { EcosystemFileJunctionService } from '../../../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import { EcosystemFileJunction } from '../../../models/ecosystem-file-junction.class';
import { EcosystemFile } from '../../../models/ecosystem-file.class';
import { EcosystemFileDialogComponent } from '../../ecosystem-file/ecosystem-file.component';
import { EIndicatorReportOtherFields } from '../../../values/ecosystem-indicator-report-other-fields.const';
import { EEcosystemIndicatorReportType } from '../../../values/ecosystem-indicator-report.const';
import { EcosystemIndicatorReportContext } from '../../../services/ecosystem-indicator-report/ecosystem-indicator-report-context.service';


@Component({
    moduleId: module.id,
    selector: 'ecosystem-indicator-report-base',
    templateUrl: 'ecosystem-indicator-report-base.component.html',
    styleUrls: ['../ecosystem-indicators.component.scss'],
  })
  export class EcosystemIndicatorReportBaseComponent implements OnInit {
    @Input() cssStyleClass = '';
    @Input() unitOther: string;
    @Input() reportContext: EcosystemIndicatorReportContext;
    @Input() type: EEcosystemIndicatorReportType;
    @Input() addPlaceholder: string;
    @Input() subtractPlaceholder: string;

    isOtherOpen = false;
    OTHER_FIELDS =  EIndicatorReportOtherFields;
    reportDocs: EcosystemFileJunction[];

    get totalErrors() {
       return this.reportContext.getTotalsErrors(this.type);
    }

    get addOther() {
        return this.reportContext.otherAdd(this.type);
    }

    set addOther(value: number) {
        this.reportContext.otherAdd(this.type, value);
    }

    get subtractOther() {
        return this.reportContext.otherSubtract(this.type);
    }

    set subtractOther(value: number) {
        this.reportContext.otherSubtract(this.type, value);
    }

    get descriptionOther() {
        return this.reportContext.otherDescription(this.type);
    }

    set descriptionOther(value: string) {
        this.reportContext.otherDescription(this.type, value);
    }

    constructor(public dialog: MdDialog, private ecoFileJunctionService: EcosystemFileJunctionService) {}


    ngOnInit() {
        this.reportContext.setContextByType(this.type);
        this.getDocs();
    }

    getDocs() {
        this.ecoFileJunctionService.get<EcosystemFileJunction>({
          ecosystem_id: this.reportContext.getEcosystem().id,
          item_type: this.reportContext.otherFileJunctionType(this.type)
        })
        .subscribe(docs => {
            this.reportDocs = docs;
          }
        );
    }

    toggleOther() {
        this.isOtherOpen = !this.isOtherOpen;
    }

    saveChanges() {
        this.reportContext.onOtherChange.emit();
    }

    openFiles() {
        this.dialog.open(EcosystemFileDialogComponent, {
            width: '800px',
            height: '600px',
            data: {
                ecosystem: this.reportContext.getEcosystem()
            }
        }).afterClosed().subscribe((file: EcosystemFile) => {
            if (file) {
                this.addFileToOrder(file);
            }
        });
    }


    addFileToOrder(file: EcosystemFile) {
        const fileJunction = new EcosystemFileJunction({
            file_id: file.id,
            item_id: 0,
            item_type: this.reportContext.otherFileJunctionType(this.type)
        });
        this.ecoFileJunctionService.create(fileJunction).subscribe((createdFileJunction: EcosystemFileJunction) => {
            if (createdFileJunction) {
            this.reportDocs.push(createdFileJunction);
          }
        });
      }

    removeJunctionFromOrder(junction: EcosystemFileJunction) {
        this.ecoFileJunctionService.remove(junction.id).subscribe(() => {
          this.getDocs();
        });
    }


    isImage(filename: string): boolean {
        return ['png', 'jpeg', 'jpg', 'gif', 'bmp', 'tif']
        .includes(filename.split('.').reverse()[0]);
      }
}
