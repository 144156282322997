import { Component, EventEmitter, OnDestroy, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA, MdDialog } from '@angular/material';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Deposit } from '../../models/deposit.class';
import { Subscription } from 'rxjs/Subscription';
import { DepositService } from '../../services/deposit.service';
import { PaginationUnmarshallingService } from '../../../../shared/services/pagination-unmarshalling.service';
import { DepositFilter } from '../../models/deposit-filter';
import { DEPOSIT_STATUSES } from '../../values/deposit-statuses.const';
import { AdvancedResearchDialogComponent } from '../../../../entities/passport/dialogs/advanced-research-dialog/advanced-research-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { DEMOUNTABILITY_STATES, DEMOUNTABILITY_MAP } from '../../values/demountability-states.const';
import { DEPOSIT_CONDITIONS, DEPOSIT_CONDITION_MAP } from '../../values/deposit-conditions.const';
import { DEPOSIT_REUSE, DEPOSIT_REUSE_SOURCE_VIEW } from '../../values/deposit-reuse-source.const';
import { TranslateService } from '@ngx-translate/core';
import { DepositLocationService } from '../../services/deposit-location.service';
import { DepositLocation } from '../../models/deposit-location.class';
import * as moment from 'moment';
import { DepositMassUpdateWarningComponent } from '../deposit-mass-update-warning/deposit-mass-update-warning.component';
import { GENERIC_PASSPORT_UNITS_MAP } from '../../../..//entities/generic-passport/models/values/generic-passport-units.const';
import { PassportFamily } from '../../../passport/models/passport.class';
@Component({
    moduleId: module.id,
    selector: 'deposit-mass-action',
    templateUrl: 'deposit-mass-action-dialog.component.html',
    styleUrls: ['deposit-mass-action-dialog.component.scss'],
})
/**
 * mdDialogData API
 * filter : Instance of DepositFilter to fetch deposits from
 * isMassUpdate : Boolean TRUE => MASS UPDATE, FALSE => QUICK UPDATE
 * isAllSelected: Wether all is selected or not
 * depositsIds: List of ids of the deposit selected ( or unselected in allSelected mode)
 */
export class DepositMassActionDialogComponent implements OnInit, OnDestroy {

    subscriptionSelection: Subscription;
    isLoaded: boolean = false;
    public records: Deposit[] = [];
    public transformedRecords: Deposit[] = [];
    filter: DepositFilter;
    form: any;
    overrideForm: any;
    demountabilityStates = DEMOUNTABILITY_STATES;
    depositConditions = DEPOSIT_CONDITIONS;
    depositReuse = DEPOSIT_REUSE;
    isMassUpdate: any = true;
    @ViewChild('deposit_name_input') depositNameInput: ElementRef;
    private queryDepositLocationParams = { expand: 'tags', agent: 1 };
    depositLocation: DepositLocation[] = [];
    MAX_LENGTH = 10;
    currentTags = {};
    applyArrowMap = {
        quantity: false,
        passport: false,
        availability_date: false,
        availability_expiration: true,
        potential_reuse: false,
        deposit_demountability: false,
        condition: false,
        deposit_source: false,
        conversion: false,
        price: true,
        deposit_location: false,
    }
    quickUpdateMap = {}
    massUpdateForm: FormGroup;
    quickUpdateFormArray: FormArray;
    unvalidDateStateMap = {};
    constructor(
        private _fb: FormBuilder,
        public dialog: MdDialogRef<any>,
        public dialogService: MdDialog,
        private paginationService: PaginationUnmarshallingService,
        private depositService: DepositService,
        public translate: TranslateService,
        private locationService: DepositLocationService,
        @Inject(MD_DIALOG_DATA) public mdDialogData: any) {
    }

    ngOnInit() {

        this.isMassUpdate = this.mdDialogData.isMassUpdate;
        this.locationService.get(this.queryDepositLocationParams).subscribe((res: DepositLocation[]) => {
            this.depositLocation = res;
        });

        this.filter = new DepositFilter(
            {
                ...this.mdDialogData.filter,
                depositsIds: this.mdDialogData.depositsIds,
                isAllSelected: this.mdDialogData.isAllSelected,

            });
        this.filter.expand += ",tags,deposit_location.tags";
        this.updateRecordsForSelection();
        this.form = this.getDefaultHeaderForm();
        this.overrideForm = this.getDefaultOverrideForm();
        this.massUpdateForm = this._fb.group({
            quantity: new FormControl(this.overrideForm.quantity, [
                Validators.min(0),
            ]),
            conversion: new FormControl(this.overrideForm.conversion, [
                Validators.min(0.00001),
            ]),
            price: new FormControl(this.overrideForm.price, [
                Validators.min(0),
            ]),
        });

    }

    ngOnDestroy() {

    }

    /**
     * Get the default header form object
     * @returns {Object}
     */
    getDefaultHeaderForm() {
        return {
            passport: {},
            deposit_demountability: null,
            potential_reuse: false,
            deposit_conditon: null,
            deposit_source: null,
            deposit_location: {
                tags: []
            },
            tags: {},
        };
    }

    /**
     * Get the default override form object
     * @returns {Object}
     */
    getDefaultOverrideForm() {
        return {
            quantity: null,
            passport: null,
            availability_date: null,
            availability_expiration: null,
            potential_reuse: null,
            deposit_demountability: null,
            condition: null,
            deposit_source: null,
            conversion: null,
            price: null,
            deposit_location: null,
            displayedTags: [],
            tags_to_add: [],
            tags_to_remove: [],

        };
    }

    prepareForm(record) {

        this.quickUpdateFormArray = this._fb.array([]);
        record.forEach((r, i) => {
            const g = this._fb.group(
                {
                    quantity: new FormControl(r.quantity, [
                        Validators.min(0),
                        this.createQuantityReservedValidator(r)
                    ]),
                    conversion: new FormControl(r.conversion, [
                        Validators.min(0.00001),
                        this.createConversionFactorVoidValidator(r)
                    ]),
                    price: new FormControl(r.price, [
                        Validators.min(0.00001),
                    ]),
                });
            g.get("quantity").valueChanges.subscribe((v) => {
                this.updateRecord(v, record[i], "quantity", i);
            })
            g.get("conversion").valueChanges.subscribe((v) => {
                this.updateRecord(v, record[i], "conversion", i);
            })
            g.get("price").valueChanges.subscribe((v) => {
                this.updateRecord(v, record[i], "price", i);
            })
            this.quickUpdateFormArray.push(g);

        })

    }

    canConfirm() {
        return this.isMassUpdate ? this.canConfirmMassUpdate() : this.canConfirmQuickUpdate();
    }

    canConfirmMassUpdate() {
        return !this.checkMassUpdateDateIsUnvalid() && this.hasMassUpdateChanges();
    }

    canConfirmQuickUpdate() {
        return this.quickUpdateFormArray && this.quickUpdateFormArray.valid && this.checkAllDateAreValid();
    }

    confirm() {

        this.isMassUpdate ? this.confirmMassUpdate() : this.confirmQuickUpdate();

    }

    confirmMassUpdate() {
        const options = this.buildOptions();

        const fields: any = {};

        if (this.overrideForm.quantity !== null) {
            fields.quantity = this.overrideForm.quantity;
        }

        if (this.overrideForm.passport !== null) {
            fields.passport_id = this.overrideForm.passport.id;
        }

        if (this.overrideForm.availability_date !== null) {
            fields.availability_date = moment(this.overrideForm.availability_date).format('YYYY-MM-DD');
        }

        if (this.overrideForm.availability_expiration !== null) {
            fields.availability_expiration = moment(this.overrideForm.availability_expiration).format('YYYY-MM-DD');
        }

        if (this.overrideForm.potential_reuse !== null) {
            fields.potential_reuse = this.overrideForm.potential_reuse;
        }

        if (this.overrideForm.deposit_demountability !== null) {
            fields.deposit_demountability = this.overrideForm.deposit_demountability;
        }

        if (this.overrideForm.condition !== null) {
            fields.condition = this.overrideForm.condition;
        }

        if (this.overrideForm.deposit_source !== null) {
            fields.deposit_source = this.overrideForm.deposit_source;
            fields.reused = fields.deposit_source === 0 ? 0 : 1;
        }

        if (this.overrideForm.conversion !== null) {
            fields.conversion = this.overrideForm.conversion;
        }

        if (this.overrideForm.price !== null) {
            fields.price = this.overrideForm.price;
        }

        if (this.overrideForm.deposit_location !== null) {
            fields.deposit_location_id = this.overrideForm.deposit_location.id;
        }

        if (this.overrideForm.tags_to_add.length !== 0) {
            fields.tags_to_add = this.overrideForm.tags_to_add.map(({ id }) => id);
        }

        if (this.overrideForm.tags_to_remove.length !== 0) {
            fields.tags_to_remove = this.overrideForm.tags_to_remove.map(({ id }) => id);
        }


        this.depositService.massUpdate({
            fields,
            deposits_ids: this.mdDialogData.depositsIds,
            all_selected: this.mdDialogData.isAllSelected,
        }, options).subscribe(
            (r) => {
                this.dialog.close();
            });
        
    }

    confirmQuickUpdate() {

        const depositsList = Object.keys(this.quickUpdateMap).map((id) => {
            const data = {
                id,
                ...this.quickUpdateMap[id]
            };

            if (data.tags) {
                data.tags_ids = data.tags.map(({ id }) => id)
                delete data.tags;
            }

            if (data.deposit_location) {
                data.deposit_location_id = data.deposit_location.id;
                delete data.deposit_location;
            }

            return data;

        });
        this.depositService.quickUpdate({ depositsList }).subscribe(
            (r) => {
                this.dialog.close();
            } 
        );
        
    }

    /**
     * Check if there has been any changes in the popup
     * Call the corresponding method depending on the popup mode
     * @returns {Boolean}
     */
    hasChanges() {
        return this.isMassUpdate ? this.hasMassUpdateChanges() : this.hasQuickUpdateChanges();
    }

    /**
     * Check whether the mass update popup has any changes or not
     * @returns {Boolean}
     */
    hasMassUpdateChanges() {
        let defaultOverrideForm = this.getDefaultOverrideForm();
        for (const key of Object.keys(this.overrideForm)) {
            let defaultValue = defaultOverrideForm[key];
            let currentValue = this.overrideForm[key];

            if (defaultValue === undefined) {
                return true;
            }

            if (Array.isArray(defaultValue)) {
                // If the current override form value has any element inside it, this means that an override element has been added
                // Thus, the popup has changes
                if (currentValue.length) {
                    return true;
                }
            } else {
                if (currentValue !== defaultValue) {
                    return true;
                }
            }
        }

        // If no difference has been found, that means that the current ovveride form is still the default one
        return false;
    }

    /**
     * Check whether the quick update popup has any changes or not
     * @returns {Boolean}
     */
    hasQuickUpdateChanges() {
        return Object.keys(this.quickUpdateMap).length !== 0;
    }

    /**
     * Reset the mass action popup to its original values
     * Depending on the mode of the popup, call the corresponding method
     */
    reset() {
        this.isMassUpdate ? this.resetMassUpdate() : this.resetQuickUpdate();
    }

    /**
     * Reset the mass update popup
     */
    resetMassUpdate() {
        this.form = this.getDefaultHeaderForm();
        this.massUpdateForm.reset();
        this.overrideForm = this.getDefaultOverrideForm();
    }

    /**
     * Reset the quick update popup
     */
    resetQuickUpdate() {
        this.quickUpdateMap = {};
        this.transformRecords(this.records);
        setTimeout(() => this.depositNameInput.nativeElement.focus(), 0);
    }

    closeDialog() {
        this.dialog.close();
    }

    updateRecordsForSelection() {
        const options = this.buildOptions();
        if (this.subscriptionSelection) {
            this.subscriptionSelection.unsubscribe();
        }
        if (this.filter.searchStatus.length > 0) {
            this.isLoaded = false;
            this.subscriptionSelection = this.depositService.getWithArray(options).subscribe((res) => {
                this.filter.updateRecords(res, this.paginationService.unmarshall(res));
                this.records = this.filter.records.map((item: any) => new Deposit(item));
                this.transformRecords(this.records);
                this.isLoaded = true;
                // if (this.depositFilter.totalCount === 0) { this.isDeletable = false; }
            }, () => this.isLoaded = true);
        } else {
            this.isLoaded = true;
            this.records = [];
            this.filter.currentPage = 1;
            this.filter.totalCount = 0;
            this.filter.perPage = 1;
            // this.isDeletable = false;
        }
    }

    searchChangeForSelection(deletable = true) {
        this.filter.currentPage = deletable ? 0 : this.filter.currentPage;
        // this.isDeletable = deletable;
        this.updateRecordsForSelection();
    }

    pageChange(page: number) {
        this.filter.currentPage = page;
        this.updateRecordsForSelection();
    }

    buildOptions() {
        let options = this.filter.buildOptions();

        options = this.filter.buildRating(options);
        if (this.filter.searchStatus) {
            const draftIndex = this.filter.searchStatus.indexOf(DEPOSIT_STATUSES.DRAFT.value);
            if ((!!this.filter.searchStatus.length && (draftIndex === -1)) || this.filter.isOutOfStockSelected) {
                options.push(['instock', Number(!this.filter.isOutOfStockSelected)]);
            }
            this.filter.searchStatus.forEach(stat => options.push(['status[]', stat]));
        }
        // if (this.navBarStateService.multilanguage) {
        //   options.push(['english', true]);
        // }
        // if (this.isDeletable) {
        //   this.stateService.depositList = this.depositFilter;
        // }
        return options;
    }

    openAdvancedResearch(canOpen, record, i) {

        if (!canOpen) {
            return;
        }
        this.dialogService.open(AdvancedResearchDialogComponent, {
            ...DIALOG_THEME.WIDE_PRIMARY,
            data: {}
        }).afterClosed().subscribe((data) => {

            if (!data) {
                return;
            }

            if (record) {
                const index = this.records.findIndex(({ id }) => id === record.id);
                this.records[index].passport = data;
                this.updateRecord(data, this.records[index], 'passport', index)
                return;
            }

            this.form.passport = data;
        });
    }

    applyToAllColumn(field) {

        if (!this.canApplyArrow(field)) {
            return;
        }

        this.overrideForm[field] = this.form[field];

        if (field === "deposit_location") {
            this.currentTags = this.form.deposit_location.tags;
            this.overrideForm.displayedTags = this.form.deposit_location.tags;
            this.overrideForm.tags_to_add = [];
        }
    }

    /**
     * Apply a value to all the lines if the value is positive
     * @param {String} field - The name of the field
     * @returns {undefined}
     */
    applyPositiveValue(field) {
        if (!this.isValuePositive(field)) {
            return;
        }

        this.overrideForm[field] = this.massUpdateForm.get(field).value;
    }

    /**
     * Apply a value to all the lines if the value is strictly positive
     * @param {String} field - The name of the field
     * @returns {undefined}
     */
    applyStrictlyPositiveValue(field) {
        if (!this.isValueStrictlyPositive(field)) {
            return;
        }

        this.overrideForm[field] = this.massUpdateForm.get(field).value;
    }

    displayDemountability(v) {
        if (!v) {
            return this.translate.instant(DEMOUNTABILITY_MAP[0])
        }
        return this.translate.instant(DEMOUNTABILITY_MAP[v]);
    }

    displayDepositCondition(v) {
        return this.translate.instant(DEPOSIT_CONDITION_MAP[v]);
    }

    displayDepositSource(v) {
        if (v === null || v === undefined) {
            return this.translate.instant(DEPOSIT_REUSE_SOURCE_VIEW[4])
        }
        return this.translate.instant(DEPOSIT_REUSE_SOURCE_VIEW[v]);
    }

    getTagName(name) {
        return name.length > this.MAX_LENGTH ? `${name.substring(0, this.MAX_LENGTH)}...` : name;
    }

    addTags(tag) {

        this.overrideForm.tags_to_add.push(tag)
        this.overrideForm.displayedTags = this.overrideForm.displayedTags.filter((t) => {
            return t.id !== tag.id
        })
        this.form.tags = {}

    }

    removeTags(tag) {
        this.overrideForm.tags_to_remove.push(tag)
        this.overrideForm.displayedTags = this.overrideForm.displayedTags.filter((t) => {
            return t.id !== tag.id
        })
        this.form.tags = {}
    }

    computeTags(record) {

        return this.isMassUpdate ? this.computeTagsMassUpdate(record) : this.computeTagsQuickUpdate(record);

    }

    computeTagsMassUpdate(record) {
        let tmp = (this.overrideForm.deposit_location !== null && this.overrideForm.deposit_location.id !== record.deposit_location.id) ? [] : [...record.tags];

        for (let i = 0; i < this.overrideForm.tags_to_add.length; i++) {
            const found = tmp.find(({ id }) => id === this.overrideForm.tags_to_add[i].id);
            if (!found) {
                tmp.push(this.overrideForm.tags_to_add[i])
            }

        }

        for (let i = 0; i < this.overrideForm.tags_to_remove.length; i++) {
            tmp = tmp.filter((item) => {
                return item.id !== this.overrideForm.tags_to_remove[i].id
            })
        }

        return tmp;
    }

    computeTagsQuickUpdate(record) {
        return !!this.quickUpdateMap[record.id] && !!this.quickUpdateMap[record.id].tags ? this.quickUpdateMap[record.id].tags : record.tags;
    }


    canApplyArrow(name) {

        if (name === "passport") {
            return !!this.form.passport.id;
        }

        if (name === "deposit_location") {
            return !!this.form.deposit_location.id;
        }

        if (name === "tags") {
            return !!this.form.tags.id;
        }

        if (name === "deposit_source") {
            return this.form.deposit_source != null && this.form.deposit_source >= 0;
        }

        return !!this.form[name];
    }

    /**
     * Check whether a value is positive and valid
     * @param {String} name - Name of the field
     * @returns {undefined}
     */
    isValuePositive(name) {
        const d = this.massUpdateForm.get(name);
        return (!!d.value || d.value === 0) && !d.invalid;
    }

    /**
     * Check whether a value is strictly positive and valid
     * @param {String} name - Name of the field
     * @returns {undefined}
     */
    isValueStrictlyPositive(name) {
        const d = this.massUpdateForm.get(name);
        return !!d.value && !d.invalid;
    }

    updateRecord(event, record, field, i) {


        if (field === 'availability_date') {
            event = moment(event).format('YYYY-MM-DD');
        }

        if (field === 'availability_expiration') {
            event = moment(event).format('YYYY-MM-DD');
        }




        if (!this.quickUpdateMap[record.id]) {
            this.quickUpdateMap[record.id] = {}
        }
        this.quickUpdateMap[record.id][field] = event;

        if (field === 'deposit_location') {
            this.quickUpdateMap[record.id].tags = [];
        }
    }

    transformRecords(records) {

        const re = records.map((r) => {
            return this.quickUpdateMap[r.id] ? {
                ...r,
                deposit_location: this.depositLocation.find(({ id }) => id === r.deposit_location_id) || {},
                ...this.quickUpdateMap[r.id],

            } : {
                ...r,
                deposit_location: this.depositLocation.find(({ id }) => id === r.deposit_location_id) || {},
            };
        })
        this.prepareForm(re);
        this.transformedRecords = re
    }
    /**
     * Open the confirmation warning popup
     */
    openConfirmationWarning() {
        this.dialogService.open(DepositMassUpdateWarningComponent, {
            ...DIALOG_THEME.WIDE_PRIMARY,
            width: '50%',
            data: {}
        }).afterClosed().subscribe((data) => {

            if (!data) {
                return;
            }

            this.confirm();

        });
    }

    onTagsSelected(tag, record) {
        if (!this.quickUpdateMap[record.id]) {
            this.quickUpdateMap[record.id] = {}
        }

        if (!this.quickUpdateMap[record.id].tags) {
            this.quickUpdateMap[record.id].tags = [...record.tags]
        }

        const found = this.quickUpdateMap[record.id].tags.find(({ id }) => id === tag.id)

        if (!found) {
            this.quickUpdateMap[record.id].tags.push(tag);
        }

        record.tmp_tag = null;

    }

    getUnit(record) {
        return this.overrideForm.passport ? GENERIC_PASSPORT_UNITS_MAP[this.overrideForm.passport.unit] : GENERIC_PASSPORT_UNITS_MAP[record.passport.unit];
    }

    get quantity() {
        return this.massUpdateForm.get('quantity');
    }

    get conversion() {
        return this.massUpdateForm.get('conversion');
    }

    get price() {
        return this.massUpdateForm.get('price');
    }

    getFormGroup(i) {
        return this.isMassUpdate ? this._fb.group({}) : this.quickUpdateFormArray.at(i);
    }

    getFormControl(name, i) {
        return this.quickUpdateFormArray.at(i).get(name);
    }

    checkMassUpdateDateIsUnvalid() {
        if (!this.overrideForm.availability_date || !this.overrideForm.availability_expiration) {
            return false;
        }
        return moment(this.overrideForm.availability_date).isAfter(moment(this.overrideForm.availability_expiration))
    }

    checkQuickUpdateDateIsUnvalid(record) {
        const newValue = this.quickUpdateMap[record.id];
        const d = newValue && newValue.availability_date ? newValue.availability_date : record.availability_date;
        const exp = newValue && newValue.availability_expiration ? newValue.availability_expiration : record.availability_expiration;

        const unvalid = moment(d).isAfter(moment(exp));
        this.unvalidDateStateMap[record.id] = unvalid;
        return unvalid;
    }

    checkAllDateAreValid() {
        return !Object.keys(this.unvalidDateStateMap).reduce((acc, curr) => {
            return acc || this.unvalidDateStateMap[curr];
        }, false);
    }

    createConversionFactorVoidValidator(r): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const value = control.value;
            if (r.passport.family === PassportFamily.FAMILY_VOID && value === null) {
                return { voidPassport: true };
            }
            return null;

        }
    }

    createQuantityReservedValidator(r): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (value === null || r.booked === null) {
                return null;
            }

            if (value < r.booked) {
                return { quantityBooked: true };
            }
        }
    }
}
