import {AbstractControl, FormGroup} from '@angular/forms';
import {EventEmitter, Injectable} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Passport} from '../models/passport.class';
import {cloneDeep, omit} from 'lodash';
import {ActiveRecord} from '../../../shared/models/active-record.class';

export interface IAction {
  props: any;
  name: string;
}

@Injectable()
export class PassportCustomStoreService {
  private form: FormGroup;
  private model: Passport;
  private snapshot: Passport;
  public addFormProgrammaticlyAction = new EventEmitter<{control: AbstractControl, controlName: string, skip?: boolean}>();
  public changeModelProgramaticly = new EventEmitter<Passport>();
  public formChangedByUserAction = new EventEmitter();
  private programaticChangeEmitted = false;
  private subscription: Subscription;

  private addFormReducer(props: {control: AbstractControl, controlName: string, skip: boolean}) {
    this.programaticChangeEmitted = true;
    this.form.addControl(props.controlName,  props.control);
    if (!props.skip) {
      props.control.valueChanges.subscribe(data => this.formChanged(props.controlName));
    }
  }

  private changePassport(data) {
    this.snapshot = cloneDeep(omit(data, ['provider']));
  }

  constructor() {
    this.addFormProgrammaticlyAction.subscribe(data => {
      this.addFormReducer(data);
    });
    this.changeModelProgramaticly.subscribe(data => this.changePassport(data));
  }

  private checkFields() {
    if (this.model) {
      return this.checkFieldsRecursive(this.snapshot, this.model);
    }
    return false;
  }

  private checkFieldsRecursive(obj: any, model) {
    if (!(typeof obj === 'object')) {
      return obj === model || (!obj && !model && obj !== 0 && model !== 0);
    }
    return Object.keys(obj).every((key) => {
        if (Array.isArray(obj[key])) {
          return obj[key].every((item, index) => this.checkFieldsRecursive(item || {}, model[key][index] || {}));
        }
        if (typeof obj[key] === 'object' && obj[key]) {
          return this.checkFieldsRecursive(obj[key], model[key] || {});
        }
        return obj[key] === model[key] || (!obj[key] && !model[key] && obj[key] !== 0 && model[key] !== 0) ;
      }
    );
  }

  public setForm(form: FormGroup) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.form = form;
    this.subscription = this.form.valueChanges.subscribe(data => this.valueChange());
  }
  public unSubscribeStore(){
    this.subscription.unsubscribe();
  }
  private valueChange() {
    if (!this.checkFields()) {
      this.formChangedByUserAction.emit();
    }
  }

  private formChanged(name) {
    if (!this.programaticChangeEmitted) {
      this.formChangedByUserAction.emit();
    }
    this.programaticChangeEmitted = false;
  }

  public setModel(model: Passport) {
    this.model = model;
    this.snapshot = this.cloneActiveRecordFields(model) as any;
  }

  private cloneActiveRecordFields(model: ActiveRecord) {
    return (model as any).fields().reduce((acumulator, field) => {
        let item = model[field];
        if (typeof item === 'object') {
          if (item instanceof ActiveRecord) {
            item = this.cloneActiveRecordFields(item);
          } else if (Array.isArray(item) && item.length && item[0] instanceof ActiveRecord) {
            item = item.map(sub => this.cloneActiveRecordFields(sub));
          } else {
            item = cloneDeep(item);
          }
        }
        acumulator[field] = item;
        return acumulator;
      }, {});
  }
}
