import {Component, OnInit} from '@angular/core';
import {DepositLocation} from '../../../../entities/deposit/models/deposit-location.class';
import {DepositLocationService} from '../../../../entities/deposit/services/deposit-location.service';
import {BIMService} from '../../../../shared/services/bim.service';
import {BIM} from '../../../../shared/models/bim.class';
import {DepositLocationFileService} from '../../../../entities/deposit/services/deposit-location-file.service';
import {UserService} from '../../../../shared/services/user.service';
import {ERoleIds} from '../../../../shared/values/role-ids.enum';
import {User} from '../../../../shared/models/user.class';
import {animate, AnimationEvent, state, style, transition, trigger} from '@angular/animations';
import {MdDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {
  AdminWarningConfirmDialogComponent
} from '../../dialogs/admin-warning-confirm/admin-warning-confirm-dialog/admin-warning-confirm-dialog.component';
import moment from 'moment';
import {PermissionService} from '../../../../shared/services/permission.service';
import {EAdminPermission} from '../../values/admin-permission.enum';
import {DepositLocationStatusName, EDepositLocationStatus} from '../../../../entities/deposit/values/deposit-location-status.enum';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {RequestSiteCreationDialogComponent} from '../../dialogs/request-site-creation-dialog/request-site-creation-dialog.component';
import {
  AdminWarningWithHeaderDialogComponent
} from '../../dialogs/admin-warning-confirm/admin-warning-with-header/admin-warning-with-header-dialog.component';
import {
  IAdminWarningWithHeader
} from '../../dialogs/admin-warning-confirm/admin-warning-with-header/values/admin-warning-with-header.interface';
import {RequestSiteDeletionDialogComponent} from '../../dialogs/request-site-deletion-dialog/request-site-deletion-dialog.component';
import {
  RequestEsmNumberChangeDialogComponent
} from '../../dialogs/request-esm-number-change-dialog/request-esm-number-change-dialog.component';
import {
  RequestSiteDeletionConfirmDialogComponent
} from '../../dialogs/admin-warning-confirm/request-site-deletion-confirm-dialog/request-site-deletion-confirm-dialog.component';
import {RequestSentDialogComponent} from '../../dialogs/request-sent-dialog/request-sent-dialog.component';
import {Observable} from 'rxjs/Observable';
import {RequestSiteExtendDateComponent} from '../../dialogs/request-site-extend-date/request-site-extend-date.component';
import {ISiteRequestParamsStateNewSite} from '../../interfaces/site-request-params/site-request-params-new-site.interface';
import {ESiteRequestType} from '../../values/site-request-type.enum';
import {ISiteRequestParamsStateSiteDeletion} from '../../interfaces/site-request-params/site-request-params-site-deletions.interface';
import {ISiteRequestParamsEsmNumber} from '../../interfaces/site-request-params/site-request-params-esm-number.interface';
import {ISiteRequestParamsDateChanged} from '../../interfaces/site-request-params/site-request-params-date-change.interface';

const animationTime = '0.3s ease-in-out';

@Component({
  selector: 'admin-administration',
  templateUrl: './admin-administration.component.html',
  styleUrls: ['./admin-administration.component.scss'],
  animations: [trigger('container', [
    state('true', style({height: 0})),
    state('false', style({height: '*'})),
    transition('true => false', animate(animationTime)),
    transition('* => true', animate(animationTime))
  ])]
})
export class AdminAdministrationComponent implements OnInit {
  DEPOSIT_LOCATION_STATUS = EDepositLocationStatus;
  DEPOSIT_LOCATION_STATUS_NAME = DepositLocationStatusName;
  sites: DepositLocation[] = [];
  bimTools = [];
  depositOwners = [];
  closeSites = {};
  maxEsm = 0;
  assignedEsm = 0;
  sitesPagination = {} as any;
  sumFloorArea = 0;
  private allowRequestButtonFocus = true;
  private queryDepositLocationParams = {expand: 'tags,status,end-work,floor_area,files,bim_tool_id,bim_id,assigned,files.basename,files.file,files.type,use_count', supervisor: true, pagination: 1, page: 0};

  constructor(public siteFileService: DepositLocationFileService,
              public translate: TranslateService,
              public siteService: DepositLocationService,
              private bimService: BIMService,
              private userService: UserService,
              private mdDialog: MdDialog,
              private permission: PermissionService
              ) {
                  this.isOutClick = this.isOutClick.bind(this);
              }

  ngOnInit() {
    this.updateRecords();
    this.bimService.get({expand: 'name'}).subscribe((tools: BIM[]) => this.bimTools = tools);
    this.userService.get({role: ERoleIds.DEPOSIT_OWNER, sort: 'username'}).subscribe((users: User[]) => this.depositOwners = users);
    this.userService.getRoleCounters({role: ERoleIds.ADMIN}).subscribe(
      (data: any) => {
          if (data) {
            this.maxEsm = data.max_esm || 0;
            this.assignedEsm = data.esm_count || 0;
          }
      }
    );
  }
  

  get sitesCount() {
    return this.sitesPagination.totalCount;
  }

  get isBuildAdmin() {
    return this.permission.hasAppliedPermission(EAdminPermission.BUILDING_ADMIN);
  }

  toggleSite(id: number) {
    this.closeSites[id] = !this.closeSites[id];
  }
  checkSpanishLanguage(){
    if(this.translate.currentLang === 'es'){
      return true;
    };
  }
  checkFrenchLanguage(){
    if(this.translate.currentLang === 'fr'){
      return true;
    };
  }
  checkPortugeseLanguage(){
    if(this.translate.currentLang === 'pt'){
      return true;
    };
  }
  checkIsPortugesOrSpanishConstructionSite(site_status){
    if (this.checkSpanishLanguage() && site_status === EDepositLocationStatus.IN_OPERATION){
      return 'admin-administration__site-status--width-spain-in-operation';
    }
    else if (this.checkSpanishLanguage() && site_status === EDepositLocationStatus.IN_CONSTRUCTION){
      return 'admin-administration__site-status--width-spain-in-construction';
    }
    else if (this.checkSpanishLanguage() && site_status === EDepositLocationStatus.STORAGE){
      return 'admin-administration__site-status--width-spain-storage';
    }
    else if (this.checkPortugeseLanguage() && site_status === EDepositLocationStatus.IN_OPERATION){
      return 'admin-administration__site-status--width-portugese-in-operation';
    }
    else if (this.checkPortugeseLanguage() && site_status === EDepositLocationStatus.STORAGE){
      return 'admin-administration__site-status--width-portugese-storage';
    }
    else {
      return 'admin-administration__site-status';
    }
  }
  checkSiteArea(){
    if (this.checkSpanishLanguage()){
      return 'admin-administration__site-floor-area--width-spain';
    }
    else if (this.checkFrenchLanguage()){
      return 'admin-administration__site-floor-area--width-france';
    }
    else {
      return 'admin-administration__site-floor-area';
    }
  }

  validateFocus($event: FocusEvent) {
    if (!this.allowRequestButtonFocus) {
      $event.preventDefault();
      $event.stopPropagation();
      if (event.target && 'blur' in $event.target) {
        ($event.target as any).blur();
      }
    }
    return this.allowRequestButtonFocus;
  }

  openCreateSiteRequestDialog() {
    const toLang = this.toLang();
    this.mdDialog.open(RequestSiteCreationDialogComponent, {...DIALOG_THEME.CHANGE_REQUEST_WIDTH_IN_INTERVAL}).afterClosed()
      .do(this.isOutClick).filter((confirmedData) => !!confirmedData)
      .subscribe((confirmedData: ISiteRequestParamsStateNewSite) => {
          const data: IAdminWarningWithHeader = {
            titleText: toLang('Request the creation of a new site'),
            confirmText: toLang('Yes, create site'),
            subHeaders: [toLang('Are you sure you want to create a new site?')],
            main: [toLang('This action will have an impact on your subscription invoice.')]
          };
          this.mdDialog.open(AdminWarningWithHeaderDialogComponent, {data, ...DIALOG_THEME.CHANGE_REQUEST_FIXED_WIDTH}).afterClosed()
            .subscribe(this.sentRequestIfConfirm(this.userService.sendRequest(confirmedData)));
        });
  }

  private toLang() {
    return (text: string) => this.translate.instant(text) + '';
  }
  private sentRequestIfConfirm(request: Observable<any>) {
    return (confirm: boolean) => {
      if (confirm) {
        request.subscribe(() => {
          this.mdDialog.open(RequestSentDialogComponent);
        });
      }
    };
  }
  private isOutClick(data: any) {
      if (data === undefined) {
        this.prohibitDialogToFocusInitialEl();
      }
  }

  private prohibitDialogToFocusInitialEl() {
      this.allowRequestButtonFocus = false;
        setTimeout(() => {
          this.allowRequestButtonFocus = true;
        });
  }


  openDeleteSiteRequestDialog() {
    this.mdDialog.open(RequestSiteDeletionDialogComponent,
      {data: {sites: this.sites}, ...DIALOG_THEME.CHANGE_REQUEST_PRIMARY}).afterClosed()
      .do(this.isOutClick).filter((confirmedSites: DepositLocation[]) => !!confirmedSites)
      .subscribe((confirmedSites: DepositLocation[]) => {
        const numberOfDeposits = confirmedSites.reduce((sum, site: DepositLocation) => sum + (Number(site.use_count) || 0), 0);
        const request: ISiteRequestParamsStateSiteDeletion = {
          type: ESiteRequestType.SITE_DELETIONS,
          sites: confirmedSites.map(site => site.id)};
        this.mdDialog.open(RequestSiteDeletionConfirmDialogComponent, {
          data: {sites: confirmedSites, depositsCount: numberOfDeposits}, ...DIALOG_THEME.CHANGE_REQUEST_PRIMARY
        }).afterClosed().subscribe(this.sentRequestIfConfirm(this.userService.sendRequest(request)));
      });
  }

  openEsmCountChangeDialog() {
    const toLang = this.toLang();
    this.mdDialog.open(RequestEsmNumberChangeDialogComponent, {...DIALOG_THEME.CHANGE_REQUEST_FIXED_WIDTH}).afterClosed()
      .do(this.isOutClick).filter((confirmedData) => typeof confirmedData === 'number').subscribe(
      (confirmedData) => {
          const data: IAdminWarningWithHeader = {
            className: 'request-esm-count-change',
            titleText: toLang('Change the number of ecosystem manager(s)'),
            confirmText: toLang('Yes, change the number'),
            subHeaders: [toLang('Are you sure you want to change the number of Ecosystem Manager(s)?')],
            main: [toLang('This action will have an impact on the invoice for your subscription.')]};
          const request: ISiteRequestParamsEsmNumber = {type: ESiteRequestType.ESM_NUMBER, new_number: confirmedData};
          this.mdDialog.open(AdminWarningWithHeaderDialogComponent, {data, ...DIALOG_THEME.CHANGE_REQUEST_FIXED_WIDTH}).afterClosed()
            .subscribe(this.sentRequestIfConfirm(this.userService.sendRequest(request)));
        });
  }

  saveSite(site: DepositLocation) {
    const oldSite = new DepositLocation({...site});
    setTimeout(() => {
      this._saveSite(oldSite, site);
    });
  }

  private _saveSite(oldVersion: DepositLocation, newVersion: DepositLocation) {
    newVersion.save().subscribe(
      () => {},
      () => Object.assign(newVersion, oldVersion)
    );
  }

  private _assignSite(newVersion: DepositLocation) {
    newVersion.assign().subscribe(
      () => { newVersion.assigned = newVersion.user_id; },
      () => newVersion.user_id = null);
  }

  isSiteExpire(dateString: string) {
   return dateString ? moment().diff(moment(dateString, 'YYYY-MM-DD'), 'days') > 0 : false;
  }

  confirmChangeSite(site: DepositLocation) {
    const label = this.translate.instant('Warning');
    const text = this.translate
      .instant('Once a site is assigned to a deposit owner, it will no longer be possible to change it');
    this.mdDialog.open(AdminWarningConfirmDialogComponent, {data: {text: text, warningLabel: label}})
      .afterClosed().subscribe(confirm => {
        confirm ?  this._assignSite(site) : site.user_id = null;
      });
  }

  animationResetTempStyles($event: AnimationEvent) {
    $event.element.style.display = null;
  }
  
  correctDateFormat(date){
    if(date !== null){
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }
  }

  animationApplyInitStyles($event: AnimationEvent) {
    $event.element.style.display = 'block';
  }

  openExtendEndOfWork(endDateBefore: string, id: number) {
    this.mdDialog.open(RequestSiteExtendDateComponent,
      {data: {date: endDateBefore, siteId: id}, ...DIALOG_THEME.CHANGE_REQUEST_FIXED_WIDTH}).afterClosed()
      .filter((data) => !!data).subscribe((request: ISiteRequestParamsDateChanged) => {
        this.userService.sendRequest(request).subscribe(() => this.mdDialog.open(RequestSentDialogComponent));
    });
  }

  log() {
    console.log('focus');
  }

  pageChange(page: number) {
    this.queryDepositLocationParams.page = page;
    this.sitesPagination.currentPage = page;
    this.updateRecords();
  }

  updateRecords() {
    this.siteService
    .getWithPaginationData(this.queryDepositLocationParams)
    .subscribe((res) => {
      const headers = res.headers;
      this.sitesPagination.currentPage = Number(headers.get('X-Pagination-Current-Page')) || 1,
      this.sitesPagination.pageCount = Number(headers.get('X-Pagination-Page-Count')) || 1,
      this.sitesPagination.perPage = Number(headers.get('X-Pagination-Per-Page')) || res.json().length,
      this.sitesPagination.totalCount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length
      this.sumFloorArea = Number(headers.get('X-Pagination-Floor-Area')) || 0
      this.sites = res.json().map((dl) => new DepositLocation(dl));
      this.sites.forEach(site => {
        if (!site.assigned) {
          site.user_id = null;
        }
      });
    })
  }
  
  
}
