import * as tslib_1 from "tslib";
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { EcosystemService } from '../../../entities/ecosystem/services/ecosystem.service';
import * as _ from 'lodash';
import { Need } from '../../../entities/need/models/need.class';
import { EcosystemStep } from './ecosystem-step.class';
import { EcosystemOffer } from '../../../entities/ecosystem-offer/ecosystem-offer.model';
import { DepositOrder } from './deposit-order.class';
import { TransformOrder } from './transform-order.class';
import { ECOSYSTEM_STATUS, EEcosystemResend } from '../values/ecosystem-status.const';
import { ECOSYSTEM_STEP, ECOSYSTEM_STEP_TYPE } from '../values/ecosystem-step-type.const';
import { EventEmitter } from '@angular/core';
var STATUS = ECOSYSTEM_STEP.STATUS;
import moment from 'moment';
import { ECOSYSTEM_OFFER_STATUS } from '../values/ecosystem-offer-status.const';
import { TRANSFORMATION_TYPE } from '../../../../e2e/_values/transformation-statuses.const';
var Ecosystem = /** @class */ (function (_super) {
    tslib_1.__extends(Ecosystem, _super);
    function Ecosystem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = EcosystemService;
        _this.onFetch$ = new EventEmitter();
        return _this;
    }
    Ecosystem.prototype.fields = function () {
        return [
            'id',
            'name',
            'steps',
            'need',
            'description',
            'created_at',
            'updated_at',
            'status',
            'need_id',
            'file_count',
            'creator_name',
            'updater_name',
            'steps_complete',
            'steps_total',
            'delivery_date',
            'publication_date',
            'client_name',
            'ecosystem_offer',
            'deposit_orders',
            'transformation_orders',
            'waste_total',
            'economic_total',
            'emissions_total',
            'water_total',
            'add_other_waste',
            'subtract_other_waste',
            'description_other_waste',
            'description_other_CO2',
            'description_other_water',
            'description_other_profits',
            'summary_steps',
            'add_other_profits',
            'add_other_CO2',
            'add_other_water',
            'subtract_other_CO2',
            'subtract_other_water',
            'subtract_other_profits',
            'user_id',
            'validation_resend',
            'folder'
        ];
    };
    Ecosystem.prototype.includesText = function (searchText) {
        searchText = searchText.toLocaleLowerCase();
        var doesNameInclude = _.includes(this.name.toLocaleLowerCase(), searchText);
        return doesNameInclude;
    };
    Object.defineProperty(Ecosystem.prototype, "progress", {
        get: function () {
            return Math.ceil(this.steps_complete / this.steps_total * 100);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ecosystem.prototype, "canEditSteps", {
        get: function () {
            return this.status === ECOSYSTEM_STATUS.DRAFT;
        },
        enumerable: true,
        configurable: true
    });
    Ecosystem.prototype.extractData = function (data) {
        var _this = this;
        var json = _super.prototype.extractData.call(this, data);
        try {
            if (json.steps) {
                json.steps = json.steps.map(function (item) {
                    var step = new EcosystemStep(item);
                    step.ecosystem = _this;
                    return step;
                });
            }
        }
        catch (error) {
            console.error(error);
        }
        json.need = new Need(json.need);
        json.ecosystem_offer = json.ecosystem_offer ?
            new EcosystemOffer(Object.assign(json.ecosystem_offer, { ecosystem: this })) :
            null;
        if (json.deposit_orders) {
            json.deposit_orders.map(function (item) { return new DepositOrder((item)); });
        }
        if (json.transformation_orders) {
            json.transformation_orders.map(function (item) { return new TransformOrder((item)); });
        }
        return json;
    };
    Ecosystem.prototype.fetch = function () {
        var _this = this;
        return this
            .load({ expand: 'steps.transformation,steps.deposit,need,need.passport.unit,ecosystem_offer,deposit_orders,transformation_orders' +
                ',deposit_orders.order_passport,deposit_orders.deposit_passport,transformation_orders.transformation,validation_resend' })
            .do(function (res) {
            _this.status = res.status;
            _this.validation_resend = res.validation_resend;
            _this.deposit_orders = res.deposit_orders.map(function (data) { return new DepositOrder(data); }) || [];
            _this.transformation_orders = res.transformation_orders.map(function (data) { return new TransformOrder(data); }) || [];
            _this.steps.forEach(function (step, i) {
                step.status = res.steps[i].status;
                step.order_aggregate = res.steps[i].order_aggregate;
                step.linked_date = res.steps[i].linked_date;
                if (step.type === ECOSYSTEM_STEP_TYPE.DEPOSIT) {
                    step.order = new DepositOrder(_this.deposit_orders.filter(function (order) { return order.step_id === step.id; }).shift());
                }
                if (step.type === ECOSYSTEM_STEP_TYPE.TRANSFORM) {
                    step.orders = _this.transformation_orders.filter(function (order) { return order.step_id === step.id; });
                    if (TRANSFORMATION_TYPE[step.transformation_type] === "Transport" || !!step.transform && TRANSFORMATION_TYPE[step.transform.transformation_type] === "Transport") {
                        step["estimated_distance"] = res.steps[i]["estimated_distance"];
                    }
                }
                step.updateEndpoints(_this.canEditSteps);
                step.updateOverlay();
            });
            _this.need = new Need(res.need);
            if (!('id' in _this.need)) {
                _this.need_id = null;
            }
            _this.ecosystem_offer = new EcosystemOffer(res.ecosystem_offer
                || { ecosystem_id: _this.id, ecosystem: _this, status: ECOSYSTEM_OFFER_STATUS.DRAFT });
            _this.ecosystem_offer.need = _this.need;
            _this.folder = res.folder;
            _this.onFetch$.emit();
        });
    };
    Ecosystem.prototype.reload = function () {
        this
            .fetch()
            .subscribe();
    };
    Ecosystem.prototype.isNeedDeleted = function () {
        return this.load({ expand: 'need' })
            .map(function (res) {
            return !('id' in res.need);
        });
    };
    Ecosystem.prototype.destroy = function () {
        return this.simplePost('/' + this.id + '/delete_all');
    };
    Object.defineProperty(Ecosystem.prototype, "canDeleteByStatus", {
        get: function () {
            return [
                ECOSYSTEM_STATUS.DONE,
                ECOSYSTEM_STATUS.REFUSED,
                ECOSYSTEM_STATUS.DRAFT,
            ].includes(this.status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ecosystem.prototype, "canStopOrEdit", {
        get: function () {
            return [
                ECOSYSTEM_STATUS.CREATED,
                ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION,
                ECOSYSTEM_STATUS.WAITING_FOR_QUOTE
            ].includes(this.status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ecosystem.prototype, "isOrdersConfirmed", {
        get: function () {
            return this.steps.every(function (step) { return [STATUS.CONFIRMED, STATUS.DONE].includes(step.status)
                || [ECOSYSTEM_STEP_TYPE.NEED, ECOSYSTEM_STEP_TYPE.STAKEHOLDER].includes(step.type); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ecosystem.prototype, "isAskForOfferValidationAgain", {
        get: function () {
            return [ECOSYSTEM_STATUS.WAITING_FOR_QUOTE, ECOSYSTEM_STATUS.WAITING_FOR_CLIENT_VALIDATION].includes(this.status)
                && this.validation_resend === EEcosystemResend.ASK_FOR_OFFER_VALIDATION_AGAIN;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ecosystem.prototype, "isAskForOfferConfirmationAgain", {
        get: function () {
            return this.validation_resend === EEcosystemResend.ASK_FOR_OFFER_CONFIRMATION_AGAIN;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ecosystem.prototype, "isAskForValidationAgain", {
        get: function () {
            if ([ECOSYSTEM_STATUS.CREATED, ECOSYSTEM_STATUS.WAITING_FOR_ORDER_VALIDATION, ECOSYSTEM_STATUS.WAITING_FOR_QUOTE].includes(this.status)
                && this.validation_resend === EEcosystemResend.ASK_FOR_VALIDATION_AGAIN) {
                return true;
            }
            else if ([ECOSYSTEM_STATUS.IN_PROCESS].includes(this.status)
                && !this.isOrdersConfirmed && this.validation_resend === EEcosystemResend.ASK_FOR_CONFIRMATION_AGAIN) {
                return true;
            }
            else if ([ECOSYSTEM_STATUS.IN_PROCESS].includes(this.status)
                && this.isOrdersConfirmed && this.validation_resend === EEcosystemResend.ASK_FOR_OFFER_CONFIRMATION_AGAIN) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Ecosystem.prototype.ordersValidated = function () {
        return this.filterOffer().every(function (step) { return step.status === STATUS.VALIDATED; });
    };
    Ecosystem.prototype.ordersConfirmed = function () {
        return this.filterOffer().every(function (step) { return step.status === STATUS.CONFIRMED; });
    };
    Ecosystem.prototype.ordersBooked = function () {
        return this.filterOffer().every(function (step) { return step.status === STATUS.PENDING; });
    };
    Ecosystem.prototype.filterOffer = function () {
        return (this.steps || []).filter(function (step) { return step.type !== ECOSYSTEM_STEP_TYPE.NEED && step.type !== ECOSYSTEM_STEP_TYPE.STAKEHOLDER; });
    };
    Ecosystem.prototype.ordersWaitingForConfirmation = function () {
        return this.filterOffer().every(function (step) { return moment(step.linked_date, ['YYYY-MM-DD', 'DD-MM-YYYY']).isBefore(moment()); });
    };
    return Ecosystem;
}(ExtendedActiveRecord));
export { Ecosystem };
