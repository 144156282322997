import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE } from '../../../../dashboards/ecosystem-manager/values/ecosystem-file-junction-item-type.const';
import { EcosystemFileJunctionService } from '../../../ecosystem/services/ecosystem-file-junctions.service';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CapitalizePipe } from '../../../../shared/pipes/capitalize.pipe';
import { EcosystemFileService } from '../../../ecosystem/services/ecosystem-file.service';
import { EcosystemService } from '../../../ecosystem/services/ecosystem.service';
import { EOrderTransactionConfirmResultFieldType } from '../../../../shared/convert-components/order-transaction-confirm-result-field/order-transaction-confirm-result-field-type.enum';
import { touchEachFormControl } from '../../../../shared/helpers/touch-each-form-control';
import { EcosystemOffer } from '../../ecosystem-offer.model';
import { EcosystemOfferService } from '../../ecosystem-offer.service';
import moment from 'moment';
import { EcosystemFileToJunctionService } from '../../../ecosystem/services/ecosystem-file-to-junction.service';
import { RequestFileSenderService } from '../../../../dashboards/admin/services/request-file-sender.service';
import { mergeMap } from 'rxjs/operators';
var OfferTransactionConfirmComponent = /** @class */ (function () {
    function OfferTransactionConfirmComponent(ecosystemService, ecosystemFileService, requestFileSenderService, ecosystemJunctionService, ecosystemOfferService, notifications, translationService, capitalizePipe, fb, ecosystemFileToJunctionService) {
        this.ecosystemService = ecosystemService;
        this.ecosystemFileService = ecosystemFileService;
        this.requestFileSenderService = requestFileSenderService;
        this.ecosystemJunctionService = ecosystemJunctionService;
        this.ecosystemOfferService = ecosystemOfferService;
        this.notifications = notifications;
        this.translationService = translationService;
        this.capitalizePipe = capitalizePipe;
        this.fb = fb;
        this.ecosystemFileToJunctionService = ecosystemFileToJunctionService;
        this.RESULT_FIELD_TYPE = EOrderTransactionConfirmResultFieldType;
        this.disabled = false;
        this.showESMText = false;
        this.onConfirm = new EventEmitter();
        this.onCancel = new EventEmitter();
        this.onFileUploadingFail = new EventEmitter();
        this.onFileUploadingComplete = new EventEmitter();
        this.allFiles = [];
        this.filesWithJunction = [];
    }
    OfferTransactionConfirmComponent.prototype.ngOnInit = function () {
        this.loadTransformation();
    };
    OfferTransactionConfirmComponent.prototype.loadTransformation = function () {
        if (this.offer) {
            this.onAfterOrderLoaded();
        }
        else if (this.id) {
            this.loadTransformationById();
        }
        else {
            this.onLoadErrorMessage();
        }
    };
    OfferTransactionConfirmComponent.prototype.loadTraceabilityFiles = function () {
        var _this = this;
        this.ecosystemJunctionService.get({
            item_id: this.id, item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_OFFER_TRACEABILITY
        }).subscribe(function (junctions) {
            _this.filesWithJunction = junctions;
            _this.allFiles = junctions.map(function (junction) { return junction.ecosystem_file; });
        });
    };
    OfferTransactionConfirmComponent.prototype.loadTransformationById = function () {
        var _this = this;
        this.ecosystemOfferService.view(this.id, { expand: this.getCommonRequestExpandAsString() })
            .subscribe(function (offer) {
            _this.offer = offer;
            _this.onAfterOrderLoaded();
        });
    };
    OfferTransactionConfirmComponent.prototype.getCommonRequestExpandAsString = function () {
        return 'need.passport,ecosystem,passport';
    };
    OfferTransactionConfirmComponent.prototype.onAfterOrderLoaded = function () {
        this.initForm();
        if (!this.disabled) {
            this.resetTransactionConfirmation();
        }
        this.loadTraceabilityFiles();
    };
    OfferTransactionConfirmComponent.prototype.onLoadErrorMessage = function () {
        this.notifications.error('No result found', null, true);
    };
    OfferTransactionConfirmComponent.prototype.initForm = function () {
        var _this = this;
        var getConfirmControlConfig = function (value) { return ([{ disabled: _this.disabled, value: value }, Validators.required]); };
        var asControl = function (name, confirmed) {
            var _a;
            return (_a = {}, _a[name] = getConfirmControlConfig(confirmed), _a);
        };
        this.form = this.fb.group(tslib_1.__assign({}, asControl('quantity', this.transactionConfirmValue(this.offer.transaction_quantity, this.offer.quantity)), asControl('date', this.transactionConfirmValue(this.offer.transaction_date, this.offer.delivery_date)), asControl('price', this.transactionConfirmValue(this.offer.transaction_price, this.offer.price)), { description: [{ disabled: this.disabled, value: this.offer.description }] }));
    };
    OfferTransactionConfirmComponent.prototype.transactionConfirmValue = function (confirm, defaultValue) {
        return this.disabled ? (confirm || (confirm === 0 ? 0 : defaultValue)) : '';
    };
    OfferTransactionConfirmComponent.prototype.resetTransactionConfirmation = function () {
        this.offer.transaction_price = null;
        this.offer.transaction_date = null;
        this.offer.transaction_quantity = null;
    };
    Object.defineProperty(OfferTransactionConfirmComponent.prototype, "title", {
        get: function () {
            return "" + this.translationService.instant('confirmation of offer fulfilment');
        },
        enumerable: true,
        configurable: true
    });
    OfferTransactionConfirmComponent.prototype.createFileJunctions = function (ecosystemFiles) {
        var _this = this;
        var junctionService = this.ecosystemFileToJunctionService;
        ecosystemFiles = junctionService.filterFilesWithoutJunction(ecosystemFiles, this.filesWithJunction);
        if (!ecosystemFiles.length) {
            this.emitUploadingStatus();
            return;
        }
        junctionService.createJunctionFromFiles(this.offer, ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_OFFER_TRACEABILITY, ecosystemFiles).subscribe(function (_a) {
            var junctions = _a.junctions, files = _a.files, failed = _a.failed;
            _this.allFiles = files;
            _this.filesWithJunction = junctions;
            _this.emitUploadingStatus(!!failed.length);
        });
    };
    OfferTransactionConfirmComponent.prototype.emitUploadingStatus = function (isFailed) {
        if (isFailed) {
            this.requestFileSenderService.onCompleteItem({});
        }
        this.requestFileSenderService.onAllItemsUploaded(this.allFiles);
    };
    OfferTransactionConfirmComponent.prototype.deleteOnlyLinkIfUploaded = function () {
        var _this = this;
        return function (file) {
            var reset = function (junction) {
                _this.allFiles = _this.allFiles.filter(function (item) { return item.id !== junction.file_id; });
                _this.filesWithJunction = _this.filesWithJunction.filter(function (item) { return item.file_id !== file.id; });
            };
            return !_this.ecosystemFileToJunctionService.deleteLinkInsteadIfExist(file, _this.filesWithJunction, reset);
        };
    };
    OfferTransactionConfirmComponent.prototype.confirmOrder = function () {
        if (this.form && this.form.valid) {
            this.offer.transaction_date = moment(this.form.controls['date'].value, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
            this.onConfirm.emit(this.offer.save().pipe(mergeMap(function (offer) { return offer.confirm(); })));
        }
        else {
            touchEachFormControl(this.form);
            this.notifications.error('You must fill in all the fields on the right to confirm the data', null, true);
        }
    };
    return OfferTransactionConfirmComponent;
}());
export { OfferTransactionConfirmComponent };
