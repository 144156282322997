/**
 * Created by natalja on 29/06/2017.
 */
import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ECustomListKeyPairClass, ECustomListRecordStatus, ECustomListThemeClass, ICustomListKeyPair, ICustomListRecord } from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { FormControl } from '@angular/forms';
import { TransformService } from '../../../../entities/transformation/services/transform.service';
import { TsmListService } from '../../../../entities/transformation/services/tsm-list.service';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DatePipe } from '@angular/common';
import { TRANSFORM_STATUSES } from '../../../transformation-manager/values/transform-statuses.const';
import { TransformDetailsDialogComponent } from '../../../transformation-manager/dialogs/transform-details-dialog/transform-details-dialog.component';
import { MdDialog } from '@angular/material';
import { getTransformTypeLcView, TRANSFORM_TYPES } from '../../../transformation-manager/values/transform-types.const';
import { getSearchStatus } from '../../../../shared/helpers/search-status-update';
import { TranslateService } from "@ngx-translate/core";
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
var SupervisorTransformationsCatalogComponent = /** @class */ (function () {
    function SupervisorTransformationsCatalogComponent(_transformService, _translationService, _tsmListService, _datePipe, dialog, navBarState) {
        var _this = this;
        this._transformService = _transformService;
        this._translationService = _translationService;
        this._tsmListService = _tsmListService;
        this._datePipe = _datePipe;
        this.dialog = dialog;
        this.navBarState = navBarState;
        this.records = [];
        //filters
        this.filterStatuses = [
            TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION,
            TRANSFORM_STATUSES.PUBLISHED,
            TRANSFORM_STATUSES.ASK_FOR_MODIFICATION,
        ];
        this.searchStatus = this.filterStatuses.map(function (status) { return status.value; });
        this.search = new FormControl();
        this.sortOptions = [
            { field: '-created_at', view: 'Creation date' },
            { field: 'tsm_name', view: 'Transformation Manager' },
            { field: 'name', view: 'Name' },
        ];
        this.sortValue = '-created_at';
        this.TRANSFORM_TYPES = TRANSFORM_TYPES;
        this
            .search
            .valueChanges
            .debounceTime(400)
            .distinctUntilChanged()
            .subscribe(function () { return _this.getRecords(); });
        this
            ._tsmListService
            .get()
            .subscribe(function (tsms) { return _this.tsms = tsms; });
    }
    SupervisorTransformationsCatalogComponent.prototype.ngOnInit = function () {
        this.getRecords();
    };
    SupervisorTransformationsCatalogComponent.prototype.getRecords = function () {
        var _this = this;
        this.records.length = 0;
        var params = {
            expand: 'files,materials,tsm_name,tags,sites,sectors',
            supervisor: true,
            supervisor_view: 1,
            search: this.search.value || null,
            tsm: this.tsmId || null,
            transformation_type: this.transformType || null,
            'status[]': [],
            sort: this.sortValue,
            nopaginate: 1,
        };
        for (var _i = 0, _a = this.searchStatus; _i < _a.length; _i++) {
            var stat = _a[_i];
            params['status[]'].push(stat);
        }
        if (this.navBarState.multilanguage) {
            params.english = true;
        }
        this._transformService
            .get(params)
            .subscribe(function (data) { return data.forEach(function (item) { return _this.addRecord(item); }); });
    };
    SupervisorTransformationsCatalogComponent.prototype.addRecord = function (data) {
        var _this = this;
        //-blue hand for waiting for publication
        //-green check for published
        var status;
        switch (data.status) {
            case TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.value:
                status = ECustomListRecordStatus.MANUAL;
                break;
            case TRANSFORM_STATUSES.PUBLISHED.value:
                status = ECustomListRecordStatus.CONFIRM;
                break;
            default:
                status = ECustomListRecordStatus.NO_STATUS;
        }
        var incomeMaterials = data.materials.filter(function (material) { return material.is_input === 1; });
        var incomeMaterialsString = (incomeMaterials.map(function (material) { return _this._translationService.instant(material.name); })).join(', ');
        var outcomeMaterials = data.materials.filter(function (material) { return material.is_input !== 1; });
        var outcomeMaterialsString = (outcomeMaterials.map(function (material) { return _this._translationService.instant(material.name); })).join(', ');
        var incomeTags = data.tags.filter(function (tag) { return tag.is_input === 1; }).map(function (tag) { return tag.name; }).join(', ');
        var outcomeTags = data.tags.filter(function (tag) { return tag.is_input !== 1; }).map(function (tag) { return tag.name; }).join(', ');
        this.records.push(this.createTransformationRecord(status, ECustomListThemeClass.DARK, data.transformation_type_data.value ? getTransformTypeLcView(data.transformation_type_data.value) : '', data.viewName, data.transformation_type_data.view.replace(/./, function (c) { return c.toUpperCase(); }), incomeMaterialsString, outcomeMaterialsString, incomeTags, outcomeTags, data.transformation_type_data.includes.storingSites ? data.sites.length : data.sectors.length, this._datePipe.transform(data.created_at, 'dd.MM.y'), data.tsm_name, data));
    };
    SupervisorTransformationsCatalogComponent.prototype.createTransformationRecord = function (status, theme, icon, title, subtitle, inputMaterials, outputMaterials, inputTags, outputTags, totalSites, addedDate, author, transform) {
        var _this = this;
        var showValidationButtons = transform.status === TRANSFORM_STATUSES.WAITING_FOR_PUBLICATION.value;
        var reversedColumnEntries = { cssClass: ECustomListKeyPairClass.REVERSED_LIST_COLUMN, seperator: ' ' };
        var materials = [];
        var keywordsOrControlType = [];
        if (transform.transformation_type_data.includes.incomeOutcome) {
            materials = [{
                    key: 'Input', value: inputMaterials
                }, {
                    key: 'Output', value: outputMaterials
                }];
            keywordsOrControlType = [{
                    key: 'Keywords', value: inputTags
                }, {
                    key: 'Keywords', value: outputTags
                }];
        }
        else if (transform.transformation_type_data.includes.acceptedMaterials) {
            materials = [tslib_1.__assign({ key: 'Accepted materials', value: transform.acceptedMaterialsView }, reversedColumnEntries)];
            if (transform.transformation_type_data.includes.controlType) {
                keywordsOrControlType = [
                    tslib_1.__assign({ key: 'Control type', value: transform.controlTypeView }, reversedColumnEntries),
                ];
            }
        }
        return CustomListRecord
            .build()
            .setPrimaryKey(transform.id)
            .setStatus(status)
            .setTheme(theme)
            .addIconColumn(icon)
            .addTitleColumn(title, subtitle, transform.english_name)
            .addListColumn(materials)
            .addListColumn(keywordsOrControlType)
            .addCounterColumn(totalSites, transform.transformation_type_data.includes.storingSites
            ? 'Sites attached'
            : 'Sectors attached')
            .addDateColumn(addedDate, 'Added')
            .addAuthorColumn(author)
            .addControlsColumn([{
                fn: function (data) {
                    _this.dialog.open(TransformDetailsDialogComponent, tslib_1.__assign({}, DIALOG_THEME.BELOW_NAVBAR, { data: {
                            id: transform.id,
                            readOnly: true,
                            showValidationButtons: showValidationButtons,
                        } })).afterClosed().subscribe(function () { return _this.getRecords(); });
                },
                name: 'See details'
            }].concat(([])));
    };
    SupervisorTransformationsCatalogComponent.prototype.toggleState = function (state) {
        this.searchStatus = getSearchStatus(state, this.searchStatus);
        this.getRecords();
    };
    return SupervisorTransformationsCatalogComponent;
}());
export { SupervisorTransformationsCatalogComponent };
