import * as tslib_1 from "tslib";
import { ExtendedCrudService } from '../../../shared/services/extended-crud.service';
import { DepositLocation } from '../models/deposit-location.class';
var DepositLocationService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationService, _super);
    function DepositLocationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.relations = ['tags'];
        _this.namespace = 'deposit-locations';
        _this.namespaceSingular = 'deposit-location';
        _this.ModelClass = DepositLocation;
        return _this;
    }
    DepositLocationService.prototype.getFileUploadUrlExpanded = function (id) {
        return _super.prototype.getFileUploadUrl.call(this, id) + '?expand=file,basename';
    };
    DepositLocationService.prototype.getAssignUrl = function (id) {
        return this.getEndpoint(this.singularEndpoint + '/' + id + '/assign');
    };
    DepositLocationService.prototype.assign = function (id, data) {
        return this.sendPost(this.getAssignUrl(id), tslib_1.__assign({}, data, { deposit_location_id: data.id }))
            .map(function (res) { return res.json(); })
            .catch(this.onError.bind(this));
    };
    DepositLocationService.prototype.deleteAllFiltered = function (filtersData) {
        return this.sendPost(this.getEndpoint(this.namespaceSingular + "/deleteall"), filtersData)
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    DepositLocationService.prototype.canUseName = function (name) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + "/can_use_name"), { search: { name: name } })
            .catch(this.onError.bind(this));
    };
    DepositLocationService.prototype.getTargetedNeedLocations = function (params) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + "/list_targeted_deposit_location"), { search: params })
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    /**
     * Request the backend to send locations for the map
     * /!\ This is only for the need-deposit-list component for now /!\
     * @param {Object} params - Params for the location search
     * @returns {DepositLocation[]}
     */
    DepositLocationService.prototype.getMapLocations = function (params) {
        return this.sendGet(this.getEndpoint(this.namespaceSingular + "/map_locations"), { search: params })
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    return DepositLocationService;
}(ExtendedCrudService));
export { DepositLocationService };
