import * as tslib_1 from "tslib";
/**
 * Created by kirill on 4.07.17.
 */
import { EcosystemFileJunctionService } from '../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { EcosystemFile } from './ecosystem-file.class';
var EcosystemFileJunction = /** @class */ (function (_super) {
    tslib_1.__extends(EcosystemFileJunction, _super);
    function EcosystemFileJunction(data) {
        var _this = _super.call(this, data) || this;
        _this.provider = EcosystemFileJunctionService;
        _this.ecosystem_file = data.ecosystem_file
            ? new EcosystemFile(data.ecosystem_file)
            : null;
        return _this;
    }
    EcosystemFileJunction.prototype.fields = function () {
        return [
            'id',
            'file_id',
            'item_id',
            'item_type',
            'ecosystem_file'
        ];
    };
    return EcosystemFileJunction;
}(ExtendedActiveRecord));
export { EcosystemFileJunction };
