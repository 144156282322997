import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../../entities/stats/services/stats.service';
import { DepositOwnerStatsContainer } from '../../../../entities/stats/models/deposit-owner-stats-container.class';
import { DO_STATISTICS_SEGMENTS, DO_STATISTICS_SEGMENTS_VIEW } from '../../values/do-statistics-segments.const';

@Component({
  moduleId: module.id,
  templateUrl: 'do-statistics.component.html'
})
export class DoStatisticsComponent implements OnInit {
  DO_STATISTICS_SEGMENTS = DO_STATISTICS_SEGMENTS;
  DO_STATISTICS_SEGMENTS_VIEW = DO_STATISTICS_SEGMENTS_VIEW;
  segments: DO_STATISTICS_SEGMENTS[] = [
    DO_STATISTICS_SEGMENTS.DEPOSITS,
    DO_STATISTICS_SEGMENTS.AGENTS,
    DO_STATISTICS_SEGMENTS.SITES
  ];
  selectedSegment: number = DO_STATISTICS_SEGMENTS.DEPOSITS;
  segmentStats: any = {};

  constructor(private statsService: StatsService) {
  }

  ngOnInit() {
    this.getSegmentData();
  }

  changeSegment(segment: number) {
    this.selectedSegment = segment;
    this.getSegmentData();
  }

  getSegmentData() {
    switch (this.selectedSegment) {
      case DO_STATISTICS_SEGMENTS.DEPOSITS:
        this.getDepositsData();
        break;
      case DO_STATISTICS_SEGMENTS.AGENTS:
        this.getAgentsData();
        break;
      case DO_STATISTICS_SEGMENTS.SITES:
        this.getSitesData();
        break;
      default:
        break;
    }
  }

  getDepositsData() {
    this.statsService.getDepositOwnerStats().subscribe((depositOwnerStats: DepositOwnerStatsContainer) => {
        this.segmentStats = depositOwnerStats.getDeposits();
    });
  }

  getAgentsData() {
    this.statsService.getDepositOwnerStats().subscribe((depositOwnerStats: DepositOwnerStatsContainer) => {
        this.segmentStats = depositOwnerStats.getAgents();
    });
  }

  getSitesData() {
    this.statsService.getDepositOwnerStats().subscribe((depositOwnerStats: DepositOwnerStatsContainer) => {
        this.segmentStats = depositOwnerStats.getSites();
    });
  }
}
