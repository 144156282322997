<div
  id="plumbRoot"
  class="ecosystem-edit__plumb-container plumb"
  droppable
  (onDrop)="stepDrop($event)"
  (stationaryclick)="rootStationaryClick($event)"
>
  <div
    class="plumb__item"
    *ngFor="let step of ecosystem.steps"
    [title]="step.title"
    [ngClass]="step.extraClass"
    [attr.id]="'item_' + step.id"
    [style.top.px]="step.y_pos"
    [style.left.px]="step.x_pos"

    (stationaryclick)="stepStationaryClick($event, step)"
    [class.active]="activeStep === step"
  >
    <div class="plumb__type">{{step.typeView | capitalize | translate}}</div>
    <div class="plumb__title" *ngIf="!isDeletedStep(step)">{{getStepTitle(step)}}</div>
    <div class="plumb__title" *ngIf="isDeletedStep(step)">{{step.title | translate}}</div>
    <div class="plumb__status">{{step.getStatusText() | lowercase | capitalize | translate}}</div>

    <div
      *ngIf="step.type !== ECOSYSTEM_STEP_TYPE.NEED && ecosystem.canEditSteps"
      class="plumb__button plumb__button--delete"
      (click)="$event.stopPropagation();deleteStep(step)"
    >
      <md-icon>clear</md-icon>
    </div>
    <!--
    <div class="plumb__button plumb__button--copy">
      <md-icon>check</md-icon>
    </div>
    -->

    <div
      class="plumb__button plumb__button--menu"
      [mdMenuTriggerFor]="stepMenu"
      (click)="$event.stopPropagation()"
      *ngIf="!isDeletedStep(step)"
    >
      <md-icon>menu</md-icon>
    </div>
    <md-menu #stepMenu="mdMenu">

      <ng-container *ngIf="step.type !== ECOSYSTEM_STEP_TYPE.NEED">
        <ng-container *ngTemplateOutlet="details"></ng-container>
        <ng-container *ngTemplateOutlet="see_problem; context: {text: 'See order problem'}">
        </ng-container>
        <!--<button
          md-menu-item
          *ngIf="!activeStep && step.canEditOrder"
          (click)="setActiveStep(step)"
        >
          {{ 'Edit order' | translate }}
        </button>-->
        <!--<button
          md-menu-item
          *ngIf="step.canConfirmManually && !isOrderInAskForConfirmation(step)"
          (click)="askForValidationAgain(step)">
          {{ 'Ask for confirmation' | translate }}
        </button>-->
        <button

          md-menu-item
          *ngIf="step.canConfirmManually && step.type !== ECOSYSTEM_STEP_TYPE.STAKEHOLDER"
          (click)="confirmStep(step)"
          >
          {{ 'Confirm manually' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="step.canEdit"
          (click)="deleteStep(step)"
        >
          {{ 'Remove' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="step.canAskForValidation && !step.canAskValidateAgain"
          (click)="askForValidate(step)"
        >
          {{ 'Ask for validation' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="step.canAskForValidation && step.canValidateManually && step.canAskValidateAgain"
          (click)="askForValidationAgain(step)"
        >
          {{ 'Ask for validation again' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="step.canValidateManually"
          (click)="validateStep(step)"
        >
          {{ 'Validate manually' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="step.canConfirmManually && isOrderInAskForConfirmation(step)"
          (click)="askForValidationAgain(step)">
          {{ 'Ask for confirmation again' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="step.status === ECOSYSTEM_STEP_STATUS.CONFIRMED || step.status === ECOSYSTEM_STEP_STATUS.DONE && step.status !== ECOSYSTEM_STEP_STATUS.PROBLEM_REPORTED"
          (click)="seeConfirmation(step)"
          > {{ 'See confirmation' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="step.type === ECOSYSTEM_STEP_TYPE.NEED">
        <ng-container *ngTemplateOutlet="details"></ng-container>
        <button
          md-menu-item
          *ngIf="ecosystem.need?.from_deposit && ecosystem.need?.from_deposit > 0 "
          (click)="viewNeedDeposit(ecosystem.need)">
          {{ 'See targeted deposit' | translate }}
        </button>
        <ng-container *ngTemplateOutlet="see_problem; context: {text: 'See offer problem'}">
        </ng-container>
        <!--<button
          md-menu-item
          (click)="ecosystemEditComponent.viewOffer()"
        >
          {{ ecosystem.ecosystem_offer?.canConfirm ? ('View offer' | translate) : ('View/send offer' | translate) }}
        </button>-->
        <!--<button
          md-menu-item
          *ngIf="step.canConfirmManually"
          (click)="askForValidationAgain(step)"
        >
          {{ 'Ask for confirmation' | translate }}
        </button>-->
        <button
          md-menu-item
          *ngIf="ecosystem.ecosystem_offer?.canAskForValidation && ecosystem.ecosystem_offer?.canValidateManually"
          (click)="askForValidate(step)">
          {{ 'Ask for validation' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="!ecosystem.ecosystem_offer?.canAskForValidation && ecosystem.ecosystem_offer?.canValidateManually"
          (click)="askForValidationAgain(step)"
        >
          {{ 'Ask for validation again' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="ecosystem?.ecosystem_offer?.canValidateManually"
          (click)="acceptOffer(step)"
        >
          {{ 'Validate manually' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="ecosystem.ecosystem_offer?.canAskConfirmationAgain &&  step.canConfirmManually"
          (click)="askForValidationAgain(step)">
          {{ 'Ask for confirmation again' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="step.canNeedConfirmManually  || step.canConfirmWhenProblemReported"
          (click)="confirmStep(step)"
          >
          {{ 'Confirm manually' | translate }}
        </button>
        <button
          md-menu-item
          *ngIf="ecosystem.status === ECOSYSTEM_STATUS.DONE && step.status !== ECOSYSTEM_STEP_STATUS.PROBLEM_REPORTED"
          (click)="seeConfirmation(step)"
        >
          {{ 'See confirmation' | translate }}
        </button>
      </ng-container>

      <ng-template #details>
        <button
          md-menu-item
          (click)="viewDetails(step)"
        >
          {{ 'See details' | translate }}
        </button>
      </ng-template>
      <ng-template #see_problem let-text="text">
        <button
          md-menu-item
          *ngIf="ECOSYSTEM_STEP_STATUS.PROBLEM_REPORTED === step.status"
          (click)="seeOrderProblem(step)"
        >
          {{ text | translate }}
        </button>
      </ng-template>

    </md-menu>
  </div>
</div>
