import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../shared/services/user.service';
import {User} from '../../../../shared/models/user.class';
import {ADMIN_ESM_OPTIONS, ERoleIds} from '../../../../shared/values/role-ids.enum';
import {MdDialog} from '@angular/material';
import {CompanyDeletionDialogComponent} from '../../dialogs/company-deletion-dialog.component';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {Router} from '@angular/router';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {ORGANISATION_TYPES, OrganisationTIds} from '../../../../shared/values/company-values.enum';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {USER_STATUS} from '../../../../shared/values/user-status.const';
import {DepositLocationTemporaryStorageService} from '../../../../entities/deposit/services/deposit-location-temporary-storage.service';
import {Subscription} from 'rxjs/Subscription';
import {ICompanyUpdateRequest} from '../../interfaces/company-update-request';
import {ICompanyCreateRequest} from '../../interfaces/company-create-request';
import {first} from 'rxjs/operators';
import {CompanyFileService} from '../../services/company-file.service';
import {UploadedDocument} from '../../../../shared/models/uploaders/document.class';
import {CompanyFile} from '../../models/company-file.class';
import {Observable} from 'rxjs/Observable';
import {concat} from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'company-details',
  templateUrl: 'company-details.component.html',
  styleUrls: ['company-details.component.scss'],
  providers: [DepositLocationTemporaryStorageService],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    'class': 'material-reset',
  }
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @Output() action = new EventEmitter();
  @Output() onDeleteAction = new EventEmitter();

  organisation_types = ORGANISATION_TYPES;
  ORGANISATION_TYPE_IDS = OrganisationTIds;
  moduleNames = ModuleNames;

  userForm: FormGroup;
  parentModule: string;

  user: User = new User({company: {}, language: 'en'});
  companyFiles: UploadedDocument[] = [];
  emailAsUsername = false;
  saveUserDocuments$ = new EventEmitter();
  filesUploaded$ = new EventEmitter();
  fileUploadUrlSet$ = new EventEmitter();
  isLoading = false;

  private companyTypeChange: Subscription;
  private formChange: Subscription;
  private isESMConst = false;

  constructor(public fileUploaderService: CompanyFileService,
              private _fb: FormBuilder,
              private userService: UserService,
              private _router: Router,
              private notification: LogNotificationService,
              private dialog: MdDialog,
              private _notificationService: LogNotificationService,
              private _translate: TranslateService,
              private _navigationService: NavigationService,
              private datePipe: DatePipe,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private zone: NgZone,
              public storage: DepositLocationTemporaryStorageService
              ) {
              this.parentModule = this._navigationService.getModuleName();
  }

  ngOnInit() {
    this.userForm = this.initUserForm();
    // ( !Boolean(c.value[0]) && !Boolean(c.value[1]) ) || c.value[0] !== c.value[1]
    // console.log(this.userForm.controls['password']);
    if (this.id) {
     this.loadUserData();
     this.isESMConst = this.userForm.controls['organisation_type'].value === OrganisationTIds.Building;
    }
    this.formChange = this.userForm.valueChanges.subscribe(() => this.cdr.detectChanges());
    this.companyTypeChange = this.userForm.controls['organisation_type'].valueChanges.debounceTime(300)
      .subscribe(() => {
        if (this.id && this.user.id) {
            this.userService.updateCompany(this.user.company.id, this.buildUpdateOptions()).subscribe();
            this.updateUser().subscribe(() => this.updateESMNumber());
        }
      });
  }

  ngOnDestroy() {
    if (this.companyTypeChange) {
      this.companyTypeChange.unsubscribe();
    }
    if (this.formChange) {
      this.formChange.unsubscribe();
    }
    this.fileUploadUrlSet$.unsubscribe();
  }

  private initUserForm(): FormGroup {
    return this._fb.group({
      company_name: ['', [Validators.required]],
      first_name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      last_name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      phone: [''],
      plan_id: [!!this.id ? this.user.plan_id : ADMIN_ESM_OPTIONS.M2, [Validators.required]],
      organisation_type: [this.user.company.organisation_type, [Validators.required]],
      company_start_date: [this.datePipe.transform(new Date, 'yyyy-MM-dd')],
      duration: [this.user.company.duration],
      construction: [this.user.company.construction],
      operation: [this.user.company.operation],
      storage: [this.user.company.storage],
      max_ESM: [this.user.company.max_ESM, [Validators.min(0)]],
      free_pilot: [this.user.company.free_pilot],
      pilot_end_date: [this.user.company.pilot_end_date],
      need_access: [this.user.company.need_access],
      transformation_acces: [this.user.company.transformation_acces],
      noah_access: new FormControl(false),
      noah_account: new FormControl(false),
      //   plan_id : ADMIN_CAN_CREATE_ESM.forEach(question => {
      //     new FormControl(question.value || '');
      //   }),
      email: ['', [Validators.required, Validators.maxLength(254)]],
      description: [''],
      SIRET: ['', [Validators.minLength(14), Validators.maxLength(14)]],
      admin_entity_name: ['', [Validators.required, Validators.maxLength(32)]],
      username: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(64)]],
      ...(!!this.id && {password: this._fb.array(
        [
          ['', [Validators.minLength(6)
            , Validators.maxLength(32)].concat(this.isExistingUser() ? [] : [Validators.required])],
          [''],
        ], (c: FormArray) =>
          this.checkIfPasswordsIdentical(c) ? {message: 'password confirm failed'} : null
      )}),
    });
  }

  private checkIfPasswordsIdentical(c: FormArray) {
    if (c.value[0] && c.value[1]) {
      return c.value[0] !== c.value[1];
    }
    return (!c.value[1] && c.value[0]) || (c.value[1] && !c.value[0]);
  }

  private loadUserData() {
    this.userService.view(this.id, {expand: 'company,roles'}).subscribe((user: User) => {
      this.user = user;
      this.fillUserForm();
      this.emailAsUsername = this.user.username === this.user.email;
      this.updateEmailAsUsername();
      if (this.isRoleAdmin()) {
        this.setFileUploaderUrl(user);
        this.fileUploaderService.get({company_id: user.company.id})
          .subscribe((files: CompanyFile[]) => this.companyFiles = files);
      }
    });
  }

  private setFileUploaderUrl(user: User) {
    this.fileUploadUrlSet$.emit(this.userService.getCompanyFileUploadUrl(user.company.id));
  }

  private fillUserForm() {
      this.userForm.controls['plan_id'].setValue(this.user.plan_id ? this.user.plan_id : ADMIN_ESM_OPTIONS.M2);
      this.userForm.controls['organisation_type'].setValue(this.user.company.organisation_type, { emitEvent: false});
      this.userForm.controls['free_pilot'].setValue(this.user.company.free_pilot);
      this.userForm.controls['pilot_end_date'].setValue(this.user.company.pilot_end_date);
      this.userForm.controls['need_access'].setValue(this.user.company.need_access);
      this.userForm.controls['transformation_acces'].setValue(this.user.company.transformation_acces);
      this.userForm.controls['max_ESM'].setValue(this.user.company.max_ESM);
      this.userForm.controls['operation'].setValue(this.user.company.operation);
      this.userForm.controls['construction'].setValue(this.user.company.construction);
      this.userForm.controls['storage'].setValue(this.user.company.storage);
      this.userForm.controls['noah_access'].setValue(this.user.company.noah_access);
      this.userForm.controls['noah_account'].setValue(this.user.company.noah_account);
  }

  private updateESMNumber() {
  if (!this.isESMConst && this.userForm.controls['organisation_type'].value === OrganisationTIds.Building) {
    this.isESMConst = true;
    this.userService.view(this.user.id, {expand: 'company'})
      .subscribe((user: User) => this.userForm.controls['max_ESM'].setValue(user.company.max_ESM)
        , () => this.isESMConst = false);
  }
}

  updateEmailAsUsername() {
    this.updateUsernameDisabled();
    this.copyEmailAsUsername();
  }

  protected copyEmailAsUsername(): void {
    this.user.username = this.emailAsUsername ? this.user.email : this.user.username;
  }

  private updateUsernameDisabled() {
    this.emailAsUsername ? this.userForm.get('username').disable() : this.userForm.get('username').enable();
  }

  isExistingUser() {
    return this.id > 0;
  }

  isRoleAdmin(): boolean {
    return !!this.user.getUserRoles().find((role) => role.roleId === ERoleIds.ADMIN);
  }

  isAdministrator() {
    return this.isRoleAdmin() || !this.isExistingUser();
  }

  isPasswordFieldsVisible(): boolean {
    return this.user.status === USER_STATUS.ACTIVE && this.user.id > -1;
  }

  resendActivation() {
    if (this.user.id) {
      this.userService.resendActivation(this.user.id).subscribe(() => {
        this._router.navigate(['superadmin/users']);
      });
    }
  }

  isExistingAgent() {
    return this.isExistingUser() && this.isRoleAdmin();
  }

  emailChanged(): void {
    this.copyEmailAsUsername();
  }

  deleteCompany() {
    if (this.user.id && this.user.company.id) {
      const dialogRef = this.dialog.open(CompanyDeletionDialogComponent, {
        data: {
          companyId: this.user.company.id
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.onDeleteAction.emit();
        }
      });
    }
  }

  save(): boolean {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const enableSave = () => this.isLoading = false;
    if (!this.isExistingUser()) {
      this.userService.createCompany(this.buildCreateOptions())
        .subscribe((user: User) => this.onCompanyCreate(user), () => enableSave());
    } else {
      const companyObserver = this.userService.updateCompany(this.user.company.id, this.buildUpdateOptions()).map((user: User) => {
         // this.user = user ;
          this.successSnackBar();
      });
      // if (!this.userForm.controls['passwordConfirm'].hasError('notEquivalent')) {
      const userObserver = this.updateUser().map((user: User) => {
        this.successSnackBar();
        // this._currentUserService.currentUserDidChanged.emit('changed');
      });
      companyObserver.combineLatest([userObserver]).subscribe(() => enableSave(), () => enableSave());
    }
    return false;
  }

  private buildCreateOptions(): ICompanyCreateRequest {
    const fromForm = (name: keyof ICompanyCreateRequest) => this.userForm.controls[name].value;
    return {
      company_name: fromForm('company_name'),
      company_start_date: fromForm('company_start_date'),
      duration: fromForm('duration'),
      organisation_type: fromForm('organisation_type'),
      construction: fromForm('construction'),
      operation: fromForm('operation'),
      storage: fromForm('storage'),
      max_ESM: fromForm('max_ESM'),
      free_pilot: fromForm('free_pilot'),
      pilot_end_date: fromForm('pilot_end_date'),
      need_access: fromForm('need_access'),
      transformation_acces: fromForm('transformation_acces'),
      noah_access: fromForm('noah_access'),
      first_name: fromForm('first_name'),
      last_name: fromForm('last_name'),
      phone: fromForm('phone'),
      email: fromForm('email'),
      description: fromForm('description'),
      username: fromForm('username'),
      ...(!!this.id && {password: this.userForm.controls['password'].value[0]}),
      formRoles: [[ERoleIds.ADMIN, 0]],
      SIRET: fromForm('SIRET'),
      admin_entity_name: fromForm('admin_entity_name'),
      language: this.user.language,
      noah_account: fromForm('noah_account'),
    };
  }

  private onCompanyCreate(user: User) {
    let onLocationsCreate = () => {
      this.isLoading = false;
      this.zone.run(() => {
        this.router.navigate(['superadmin/users']);
      });
    };
    const observers: Observable<any>[] = [];
    onLocationsCreate = onLocationsCreate.bind(this);
    if (this.storage
      && !!this.storage.getDepositLocations().length
      && !this.storage.isLoading()) {
        observers.push(this.storage.onLoad$.pipe(first()));
        this.storage.uploadAll(user);
    }
    this.user.set({id: user.id, status: user.status});
    const fileSaveObserver = concat(this.getCompanyAndSaveFiles(user), this.filesUploaded$.pipe(first()));
    fileSaveObserver.combineLatest(observers).subscribe(() => onLocationsCreate()
      , () => onLocationsCreate());
  }

  private getCompanyAndSaveFiles(user: User) {
    return this.userService.view(user.id, {expand: 'company'})
      .map((userWithCompany: User) => {
        this.setFileUploaderUrl(userWithCompany);
        this.saveUserDocuments$.emit();
      });
  }

  private buildUpdateOptions(): ICompanyUpdateRequest {
    const fromForm = (name: keyof ICompanyUpdateRequest) => this.userForm.controls[name].value;
    return {
      company_name: fromForm('company_name'),
      duration: fromForm('duration'),
      first_name: fromForm('first_name'),
      last_name: fromForm('last_name'),
      phone: fromForm('phone'),
      email: fromForm('email'),
      description: fromForm('description'),
      username: fromForm('username'),
      ...(!!this.id && {password: this.userForm.controls['password'].value[0]}),
      formRoles: [[ERoleIds.ADMIN, 0]],
      SIRET: fromForm('SIRET'),
      admin_entity_name: fromForm('admin_entity_name'),
      name: this.userForm.controls['admin_entity_name'].value,
      language: this.user.language,
      organisation_type: fromForm('organisation_type'),
      max_ESM: fromForm('max_ESM'),
      construction: fromForm('construction'),
      operation: fromForm('operation'),
      storage: fromForm('storage'),
      free_pilot: fromForm('free_pilot'),
      pilot_end_date: fromForm('pilot_end_date'),
      need_access: fromForm('need_access'),
      transformation_acces: fromForm('transformation_acces'),
      noah_access: fromForm('noah_access'),
      noah_account: fromForm('noah_account')
    };
  }

  private updateUser() {
    this.user.plan_id = this.userForm.controls['plan_id'].value ;
    return this.user.save();
  }

  private successSnackBar() {
    this._notificationService.success(`${this._translate.instant('UPDATED_SUCCESSFULY')}`);
  }

  loginAsUser() {
    if (this.user.id) {
      this.userService.loginAs(this.user.id).subscribe(() => {
        location.href = '/';
      });
    }
  }
}

