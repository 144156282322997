import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { LogNotificationService } from '../../services/log-notification.service';
import { Utils } from '../../utils/utils.class';

@Component({
  moduleId: module.id,
  selector: 'logo-uploader',
  templateUrl: 'logo-uploader.component.html'
})
export class LogoUploaderComponent {

  uploader: FileUploader = new FileUploader({});

  @Input() preview: string;
  @Input() targetUrl: string;
  @Input() notShowClickErrors: boolean;
  @Input() isDisabled: boolean;
  @Input() uploadUrlMissingErrorText: string;
  @Input() dontUpload: boolean = false;  // New input to control immediate upload
  @Output() onLoad: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(public notification: LogNotificationService) {
  }

  onSelected() {
    if (this.uploader.queue.length > 0) {
      const file = this.uploader.queue[0]._file;

      if (!this.isValidFileExtension(file.name)) {
        this.notification.error('Invalid file type. Please upload a PNG, JPG, or JPEG.', null, true);
        this.uploader.clearQueue();
        return;
      }

      if (!this.isValidFileSize(file)) {
        this.notification.error('File size is too large. Please upload a file smaller than 5MB.', null, true);
        this.uploader.clearQueue();
        return;
      }

      this.setPreview(file);
      this.uploader.setOptions({
        url: this.targetUrl,
        itemAlias: 'file'
      });
      this.uploader.onCompleteItem = (item, response) => this.onLoad.emit(response);
      this.uploader.onCompleteAll = () => console.log('onCompleteAll');
      this.uploader.onErrorItem = Utils.onUploadError((event: string) => this.notification.error(event, null, true));

      // Check if dontUpload is false. If it's true, don't upload immediately.
      if (!this.dontUpload) {
        this.uploader.uploadAll();
      }
    }
  }

  isValidFileExtension(filename: string): boolean {
    const ext = filename.split('.').pop().toLowerCase();
    return ['png', 'jpg', 'jpeg'].includes(ext);
  }

  isValidFileSize(file: File): boolean {
    const maxSizeInBytes = 5 * 1024 * 1024;  // 5 Mo
    return file.size <= maxSizeInBytes;
  }

  // New function to handle the upload externally
  triggerUpload(_targetUrl: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.uploader.setOptions({
        url: _targetUrl,
        itemAlias: 'file'
      });
      this.uploader.onCompleteAll = () => {
        resolve();  
      };
      this.uploader.onErrorItem = (item, response, status, headers) => {
        reject(new Error('Upload failed')); 
      };

      this.uploader.uploadAll();
    });
  }


  onUploadButtonClick(event: Event) {
    this.onClick.emit(event);
    if (this.notShowClickErrors) {
      if (this.isDisabled && this.uploadUrlMissingErrorText) {
        this.notification.error(this.uploadUrlMissingErrorText, null, true);
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  setPreview(file: any): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.preview = event.target.result;
    }
    reader.readAsDataURL(file);
  }

  uploaderStyles() {
    if (this.preview) {
      return {
        'background': `url(${this.preview})`
      };
    }
    return null;
  }

  removeFile() {
    if (this.dontUpload) {
      this.preview = null;
      this.uploader.clearQueue();
    }
    this.onRemove.emit();
  }

  isEmptyQueue(): boolean {
    return this.uploader.queue.length === 0;
  }

}
