<ng-container *ngIf="virginProduct.loaded">
  <h2 *ngIf="!virginProduct.id">{{ 'Create virgin product' | translate }}</h2>

  <passport-edit-form
    [listener]="savePassport"
    [(passport)]="virginProduct.passport"
    (passportChange)="virginProduct.passport = $event; virginProduct.passport_id = $event.id"
  ></passport-edit-form>

  <div class="location-and-contact">
    <h3>{{ 'Location & Contact' | translate }}</h3>

    <md-input-container class="address">
      <input mdInput autocomplete="off" placeholder="{{ 'Address of manufacturing' | translate }}" [(ngModel)]="virginProduct.address">
    </md-input-container>
    <md-input-container class="contact">
      <input mdInput autocomplete="off" placeholder="{{ 'Contact' | translate }}" [(ngModel)]="virginProduct.contact_name">
    </md-input-container>
    <md-input-container class="title">
      <input mdInput autocomplete="off" placeholder="{{ 'Contact\'s function' | translate }}" [(ngModel)]="virginProduct.contact_title">
    </md-input-container>

    <div class="newline"></div>
    <md-input-container class="phone">
      <input mdInput autocomplete="off" placeholder="{{ 'Phone' | translate }}" [(ngModel)]="virginProduct.contact_phone">
    </md-input-container>
    <md-input-container class="email">
      <input mdInput autocomplete="off" placeholder="{{ 'Email' | translate }}" [(ngModel)]="virginProduct.contact_email">
    </md-input-container>
    <div class="empty"></div>
  </div>

  <div class="sector-of-delivery">
    <h3>{{ 'Sector of delivery' | translate }}</h3>

    <md-input-container
      class="sectors"
      [floatPlaceholder]="virginProduct.sectors.length ? 'always' : ''"
    >
      <md-chip-list>
        <md-chip *ngFor="let sector of virginProduct.sectors">
          {{sector.name}}
          <button (click)="removeSector(sector)" class="delete"></button>
        </md-chip>
      </md-chip-list>

      <place-autocomplete [options]="{'types': ['geocode']}" class="place-autocomplete__full-width">
        <input
          placeAutocomplete
          mdInput
          [(ngModel)]="sectorName"
          (placeChange)="addSector()"
          placeholder="{{ 'Sectors' | translate }}"
        >
      </place-autocomplete>
    </md-input-container>
  </div>

  <div class="buttons">
    <button md-raised-button (click)="save()">
      <ng-container *ngIf="!virginProduct.id">{{ 'Create virgin product' | translate }}</ng-container>
      <ng-container *ngIf="virginProduct.id">{{ 'Save' | translate }}</ng-container>
    </button>
  </div>

</ng-container>
