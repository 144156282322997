/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-passport-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../generic-passport-form/generic-passport-form.component.ngfactory";
import * as i4 from "../../../../shared/read-only/read-only.service";
import * as i5 from "../generic-passport-form/generic-passport-form.component";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/material";
import * as i8 from "../../../../dashboards/superadmin/components/landfill-variables/landfill-variables.service";
import * as i9 from "../../../../shared/services/zone.service";
import * as i10 from "../../service/generic-passport-tag.service";
import * as i11 from "../../../../../node_modules/@angular/material/typings/index.ngfactory";
import * as i12 from "../../../../shared/read-only/form-elems-hidden.directive";
import * as i13 from "./generic-passport-edit.component";
import * as i14 from "@angular/router";
import * as i15 from "../../../../shared/services/log-notification.service";
var styles_GenericPassportEdit = [i0.styles];
var RenderType_GenericPassportEdit = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericPassportEdit, data: {} });
export { RenderType_GenericPassportEdit as RenderType_GenericPassportEdit };
export function View_GenericPassportEdit_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "article", [["class", "genericpassport-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "section", [["class", "genericpassport-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "passport-edit__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "passport-edit__header--heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "generic-passport-form", [], null, null, null, i3.View_GenericPassportFormComponent_0, i3.RenderType_GenericPassportFormComponent)), i1.ɵprd(4608, null, i4.ReadOnlyService, i4.ReadOnlyService, []), i1.ɵdid(8, 8503296, null, 0, i5.GenericPassportFormComponent, [i6.FormBuilder, i1.ChangeDetectorRef, i7.MdDialog, i2.TranslateService, i8.LandfillVariablesService, i9.ZoneService, i10.GenericPassportTagService], { genericPassport: [0, "genericPassport"], parentFormGroup: [1, "parentFormGroup"], zoneId: [2, "zoneId"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 10, "footer", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 9, "ul", [["class", "button-group__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 8, "li", [["class", "button-group__item button-group__item--wide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 7, "button", [["md-button", ""], ["md-raised-button", ""]], [[8, "disabled", 0], [2, "mat-button", null], [2, "mat-raised-button", null], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MdButton_0, i11.RenderType_MdButton)), i1.ɵdid(13, 16384, null, 0, i7.MdPrefixRejector, [[2, i7.MATERIAL_COMPATIBILITY_MODE]], null, null), i1.ɵdid(14, 180224, null, 0, i7.MdButton, [i1.ElementRef, i1.Renderer, i7.FocusOriginMonitor], null, null), i1.ɵdid(15, 16384, null, 0, i7.MdButtonCssMatStyler, [], null, null), i1.ɵdid(16, 16384, null, 0, i7.MdRaisedButtonCssMatStyler, [], null, null), i1.ɵdid(17, 16384, null, 0, i12.FormElemsHiddenDirective, [[2, i4.ReadOnlyService]], null, null), (_l()(), i1.ɵted(18, 0, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.genericPassport; var currVal_2 = _co.form; var currVal_3 = _co.zoneId; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("New System Passport")); _ck(_v, 4, 0, currVal_0); var currVal_4 = i1.ɵnov(_v, 14).disabled; var currVal_5 = true; var currVal_6 = true; var currVal_7 = ((i1.ɵnov(_v, 17).readOnlyService == null) ? null : i1.ɵnov(_v, 17).readOnlyService.readOnly); _ck(_v, 12, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("Save")); _ck(_v, 18, 0, currVal_8); }); }
export function View_GenericPassportEdit_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "generic-passport-edit", [], null, null, null, View_GenericPassportEdit_0, RenderType_GenericPassportEdit)), i1.ɵdid(1, 4440064, null, 0, i13.GenericPassportEdit, [i14.ActivatedRoute, i14.Router, i6.FormBuilder, i1.ChangeDetectorRef, i2.TranslateService, i15.LogNotificationService, i1.NgZone, i9.ZoneService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericPassportEditNgFactory = i1.ɵccf("generic-passport-edit", i13.GenericPassportEdit, View_GenericPassportEdit_Host_0, {}, {}, []);
export { GenericPassportEditNgFactory as GenericPassportEditNgFactory };
