/**
 * Created by aleksandr on 17.07.17.
 */
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import {
  IChatHistoryRecord,
  IChatHistoryRecordContainer,
  TChatHistoryEvent
} from '../chat/interfaces/chat-message.interface';
import { Observable } from 'rxjs/Observable';
import { Config } from '../config/env.config';
import { ServiceLocator } from './service-locator';
import { DepositImportDeepStream } from './deepstream/deposit-deepstream.service';
import { ZoneDeepStream } from './deepstream/zone-deepstream.service';
import { BaseDeepStreamService } from './deepstream/base-deepstream.service';
import { ReportImportDeepStream } from './deepstream/report-deepstream.service';
import {DepositLocationDeepstreamService} from './deepstream/deposit-location-deepstream.service';
import { ReportPdfDeepStream } from './deepstream/report-pdf-deepstream.service';

declare const deepstream: any;

@Injectable()
export class DeepStreamService {
  public static _stream$: EventEmitter<IChatHistoryRecordContainer> = new EventEmitter<IChatHistoryRecordContainer>();
  public static _dsInstance: any;
  public static _ds: any;
  public static channel: string;

  private static _depositImportDeepStream: DepositImportDeepStream = new DepositImportDeepStream();
  private static _reportImportDeepStream: ReportImportDeepStream = new ReportImportDeepStream();
  private static _zoneDeepStream: ZoneDeepStream = new ZoneDeepStream();
  private static _depositLocationDeepStream: DepositLocationDeepstreamService = new DepositLocationDeepstreamService();
  private static _reportPdfDeepStream : ReportPdfDeepStream = new ReportPdfDeepStream();

  public get stream$(): EventEmitter<IChatHistoryRecordContainer> {
    return DeepStreamService._stream$;
  }

  public get depositImportDeepStream$(): DepositImportDeepStream {
    return DeepStreamService._depositImportDeepStream;
  }

  public get reportImportDeepStream$(): ReportImportDeepStream {
    return DeepStreamService._reportImportDeepStream;
  }

  public get zoneDeepStream$(): ZoneDeepStream {
    return DeepStreamService._zoneDeepStream;
  }
  public get depositLocationDeepStream$(): DepositLocationDeepstreamService {
    return DeepStreamService._depositLocationDeepStream;
  }

   public get reportPdfDeepStream$(): ReportPdfDeepStream {
    return DeepStreamService._reportPdfDeepStream;
  }

  public get messages$(): Observable<IChatHistoryRecord> {
    return DeepStreamService
      ._stream$
      .asObservable()
      .filter((record: IChatHistoryRecordContainer) => record.target === DeepStreamService.channel)
      .map((record: IChatHistoryRecordContainer) => record.data);
  }

  public get dsInstance(): any {
    return DeepStreamService._dsInstance;
  }

  public set dsInstance(value: any) {
    DeepStreamService._dsInstance = value;
  }

  get ds(): any {
    return DeepStreamService._ds;
  }

  set ds(value: any) {
    DeepStreamService._ds = value;
  }


  constructor() {
    this.build();
  }

  build() {
    let ngZone = ServiceLocator.injector.get(NgZone);
    ngZone.runOutsideAngular(() => {
      this.ds = this.dsInstance = null;
      console.log("DEEPSTREAM API is", Config.DEEPSTREAM_API);
      console.log("deepstream is", deepstream(Config.DEEPSTREAM_API));
      this.ds = this.dsInstance = deepstream(Config.DEEPSTREAM_API);
      console.log("Error?");
      this.ds.on('error', (error: any, event: any, topic: any) => {
        console.log('Deepstream ERROR IS', error, event, topic);
      });
    });
  }

  canCloseConnection() {
    return this.ds && this.ds.getConnectionState() !== 'CLOSED';
  }

  logout() {
    console.log('Deepstream close STATE IS', this.ds.getConnectionState());
    if (this.canCloseConnection()) {
      this.ds.close();
      //this.ds = null;
    }
    //this.build();
  }
  login(credentials?: any, loginHandler?: (success: any, data: any) => void) {
    // {username: 'chris', password:'password'}
    this.ds.login(credentials, loginHandler);
  }

  getRecord(name: any) {
    return this.ds.record.getRecord(name);
  }

  getList(name: any) {
    return this.ds.record.getList(name);
  }

  getDeepStreamServices(): BaseDeepStreamService[] {
    return [
      DeepStreamService._depositImportDeepStream,
      DeepStreamService._zoneDeepStream,
      DeepStreamService._reportImportDeepStream,
      DeepStreamService._depositLocationDeepStream,
      DeepStreamService._reportPdfDeepStream
    ];
  }

  subscribeChannel(username: string) {
    console.log('subscribeChannel', 'message/' + username);
    DeepStreamService.channel = username;

    const deepStreamServices = this.getDeepStreamServices();

    deepStreamServices.forEach(service => service.receiver = username);

    this.ds.event.subscribe('message/' + username, (data: IChatHistoryRecord | any) => {
      // handle published data
      console.log('DeepStreamService -> MESSAGE:', data);
      const foundService = deepStreamServices.find((service) => service.isMessageTypeIncluded(data.type));

      if (!!foundService) {
        foundService.emit(data);
        return;
      }

      this.stream$.emit({ target: username, data });
    });
  }

  listenMessages(predicate: (message: TChatHistoryEvent) => boolean) {
    return this.messages$.filter(predicate);
  }
}
