import { Component, OnInit } from '@angular/core';
import { DepositService } from '../../../../entities/deposit/services/deposit.service';
import { ISearchable } from '../../../../shared/interfaces/searchable.interface';
import { DEPOSIT_STATUSES } from '../../../../entities/deposit/values/deposit-statuses.const';
import {
  ECustomListHintClass,
  ECustomListRecordStatus,
  ECustomListUnit,
  ICustomListRecord
} from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { FormControl } from '@angular/forms';
import { Deposit } from '../../../../entities/deposit/models/deposit.class';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { DatePipe } from '@angular/common';
import { MdDialog } from '@angular/material';
import {
  DepositDetailsDialogComponent
} from '../../../../entities/deposit/dialogs/deposit-details-dialog/deposit-details-dialog.component';
import { IDepositStatusCheckbox } from '../../../../entities/deposit/models/deposit-status-checkbox.interface';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import { NavbarStateService } from '../../../../shared/services/navbar.service';
import {DepositLocation} from '../../../../entities/deposit/models/deposit-location.class';
import {DepositTag} from '../../../../entities/deposit/models/deposit-tag.class';
import {DepositLocationService} from '../../../../entities/deposit/services/deposit-location.service';

@Component({
  moduleId: module.id,
  selector: 'do-deposits-list',
  templateUrl: 'do-deposits-list.component.html'
})
export class DoDepositsListComponent implements OnInit {
  records: ICustomListRecord[] = [];
  statuses = DEPOSIT_STATUSES;
  availableDepositLocations: DepositLocation[] = [];
  filterStatuses: IDepositStatusCheckbox[] = [
    {depositStatus: DEPOSIT_STATUSES.WAITING_FOR_VALIDATION, checked: true, text: 'WAITING_FOR_VALIDATION'},
    {depositStatus: DEPOSIT_STATUSES.WAITING_FOR_PUBLICATION, checked: true, text: 'WAITING_FOR_PUBLICATION'},
    {depositStatus: DEPOSIT_STATUSES.PUBLISHED, checked: true, text: 'PUBLISHED'},
    {depositStatus: DEPOSIT_STATUSES.ASK_FOR_MODIFICATION, checked: true, text: 'ASK_FOR_MODIFICATION'},
    {depositStatus: DEPOSIT_STATUSES.REJECTED, checked: false, text: 'REJECTED'},
  ];

  outOfStock = false;

  search = new FormControl;

  availableFrom: string;
  availableTo: string;

  agentContacts: ISearchable[] = [];
  agentContactId: number;

  sortOrders: {
    value: string,
    view: string
  }[] = [
    {value: 'name', view: 'Name'},
    {value: '-created_at', view: 'Creation date'},
    {value: 'availability_date', view: 'Availability date'},
    {value: 'agent', view: 'Agent'}
  ];
  sortOrder = '-created_at';

  isLoadingNewPage = false;
  currentPage = 1;
  totalPagesCount = 0;
  chosenDepositLocations: DepositLocation[] = [];
  chosenTags: DepositTag[] = [];

  constructor(private depositService: DepositService,
              private mdDialog: MdDialog,
              private datePipe: DatePipe,
              private navBarState: NavbarStateService,
              private siteService: DepositLocationService) {
  }

  ngOnInit() {
    this
      .search
      .valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .subscribe(() => this.loadDeposits());
    this.loadDeposits();
    this.siteService.get({expand: 'tags'}).subscribe((sites: DepositLocation[]) => this.availableDepositLocations = sites);
  }

  // TODO need to block it somehow to avoid spamming requests
  loadDeposits(forPage: number = 1) {
    if (!this.filterStatuses.find((status: IDepositStatusCheckbox) => status.checked)) {
      this.records = [];
      return;
    }

    const query: any = {
      expand: 'creator,creator_email,passport,deposit_location',
      deposit_owner: 1,
      supervisor: 1,
      available_from: this.availableFrom || null,
      available_to: this.availableTo || null,
      search: this.search.value || null,
      sort: this.sortOrder || null,
      creator_id: this.agentContactId || null,
      instock: Number(!this.outOfStock),
      'sites[]': this.chosenDepositLocations.map(location => location.id) || null,
      'tags[]': this.chosenTags.map(tag => tag.id) || null,
      page: forPage
    };

    if (this.navBarState.multilanguage) {
      query.english = true;
    }

    query['status[]'] = this.filterStatuses
      .filter((status: IDepositStatusCheckbox) => status.checked)
      .map((status: IDepositStatusCheckbox) => status.depositStatus.value);

    if (forPage === 1) {
      this.records = [];
    }

    this.isLoadingNewPage = true;
    this.depositService.getWithPaginationData(query).subscribe((res) => {
      const headers = res.headers;
      this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
      this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
      res.json().map((deposit: Deposit) => {
        this.addRecord(new Deposit(deposit));
      });
      this.isLoadingNewPage = false;
    });
  }

  onEndOfListReached() {
    if (this.currentPage < this.totalPagesCount && !this.isLoadingNewPage) {
      const newValue = this.currentPage + 1;
      this.loadDeposits(newValue);
    }
  }

  addRecord(deposit: Deposit) {
    let hintCssClass = ECustomListRecordStatus.NO_STATUS;
    const passportName = deposit.passport !== null ? deposit.passport.name : '';
    const site = deposit.deposit_location !== null ? deposit.deposit_location.name : '';
    if (deposit.status === DEPOSIT_STATUSES.PUBLISHED.value) {
      hintCssClass = ECustomListRecordStatus.CONFIRM;
    } else if (deposit.status === DEPOSIT_STATUSES.WAITING_FOR_VALIDATION.value) {
      hintCssClass = ECustomListRecordStatus.MANUAL;
    }

    if (!this.agentContacts.find((creator: any) => creator.id === deposit.creator_id)) {
      this.agentContacts.push({id: deposit.creator_id, name: deposit.creator});
    }

    this.records.push(
      this.createRecord(
        hintCssClass,
        ECustomListHintClass.NO_CLASS,
        '',
        deposit.name,
        passportName,
        deposit.quantity || 0,
        deposit.passport.customListUnit,
        this.datePipe.transform(deposit.created_at, 'dd.MM.y'),
        this.datePipe.transform(deposit.availability_date, 'dd/MM/y') + (deposit.availability_expiration
        ? ' - ' + this.datePipe.transform(deposit.availability_expiration, 'dd/MM/y') : ''),
        site,
        deposit.creator,
        deposit,
      )
    );
  }

  createRecord(status: ECustomListRecordStatus,
               hintCssClass: ECustomListHintClass,
               hintText: string,
               title: string,
               subtitle: string,
               amount: number,
               unit: ECustomListUnit,
               createdAt: string,
               availableIn: string,
               site: string,
               author: string,
               deposit: Deposit): CustomListRecord {

    const waitingValidationState = deposit.status === DEPOSIT_STATUSES.WAITING_FOR_VALIDATION.value;
    return CustomListRecord
      .build()
      .setPrimaryKey(deposit.id)
      .setStatus(status)
      .setHint(hintText, hintCssClass)
      .addTitleColumn(title, subtitle, null, deposit.passport.english_name)
      .addUnitColumn(amount, unit)
      .addListColumn([{key: '', value: site}])
      .addListColumn([{
        key: 'Created', value: createdAt
      },
        availableIn ? {key: 'Availability', value: availableIn} : null
      ])
      .addAuthorColumn(author)
      .addControlsColumn([{
        fn: () => {
          this.mdDialog.open(DepositDetailsDialogComponent, {
            ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
            data: {
              id: deposit.id,
              readOnly: waitingValidationState,
              showValidationButtons: waitingValidationState,
            },
          }).afterClosed().subscribe(() => this.loadDeposits());
        },
        name: 'See details'
      }]);
  }
}
