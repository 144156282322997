/**
 * Created by Aleksandr C. on 7.02.17.
 */
import { Injectable } from '@angular/core';
import { IApiConfig } from 'ng2-adn-common';
import { ApiConfig } from '../config/api.config';
import { Headers, Http, RequestOptionsArgs, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { LogNotificationService } from './log-notification.service';
import { NavigationService } from './navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { MockApiService } from './mock/mock-api.service';


@Injectable()
export class ApiService {
  private config: IApiConfig;

  constructor(private http: Http, public notification: LogNotificationService ,public navigation : NavigationService, public translateService: TranslateService, protected mockService: MockApiService) {
    this.onInit();
  }

  public onInit() {
    this.config = ApiConfig;
  }

  public isCurrentContextESM() {
    return this.navigation.isESM();
  }
  public getApiUrl() {
    return this.config.api_url;
  }

  public getEndpoint(route: string) {
    return this.getApiUrl() + '/' + route;
  }

  public getOptions() {
    return JSON.parse(JSON.stringify(this.config.options));
  }

  public getHeaders() {
    return JSON.parse(JSON.stringify(this.config.headers));
  }

  public mergeOptions(options: any = {}): RequestOptionsArgs {
    let params: URLSearchParams = new URLSearchParams();
    if (!!options.search) {
      Object
        .keys(options.search)
        .filter((key) => typeof options.search[key] !== 'undefined' && options.search[key] !== null)
        .forEach((key) => {
            if (Array.isArray(options.search[key])) {
              options.search[key].forEach((val: any) => {
                params.append(key, String(val));
              });
            } else {
              params.set(key, String(options.search[key]));
            }
          }
        );
      options.search = params;
    }
    return Object.assign(this.getOptions(), {
      headers: new Headers(this.getHeaders())
    }, options);
  }

  public sendPost(url: string, data: any = {}, options: Object = {}) {
    let queryOptions: RequestOptionsArgs = this.mergeOptions(options);
    //console.log('%c POST ', 'background: forestgreen; color: white', url, data, queryOptions);
    return this.http.post(url, data, queryOptions);
  }

  public sendPut(url: string, data: any = {}, options: Object = {}) {
    let queryOptions: RequestOptionsArgs = this.mergeOptions(options);
    //console.log('%c PUT ',  'background: forestgreen; color: white' ,url, data, queryOptions);
    return this.http.put(url, data, queryOptions);
  }

  public sendGet(url: string, options: any = {}) {
    let queryOptions = this.mergeOptions(options);
    //console.log('%c GET ',  'background: forestgreen; color: white' , url, queryOptions);
    return this.http.get(url, queryOptions);
  }

  public sendGetWithArray(url: string, options: any = []) {
    let params: URLSearchParams = new URLSearchParams();
    if (!!options) {
      for (let option of options) {
        if (option[1] !== null && option[1] !== undefined) {
          params.append(String(option[0]), String(option[1]));
        }
      }
    }
    let queryOptions = Object.assign(this.getOptions(), {
      headers: new Headers(this.getHeaders())
    }, {search: params});
    //console.log('%c GET ',  'background: forestgreen; color: white' , url, queryOptions);
    return this.http.get(url, queryOptions);
  }

  public sendPostWithArray(url: string, data: any, options: any = []) {
    let params: URLSearchParams = new URLSearchParams();
    if (!!options) {
      for (let option of options) {
        if (option[1] !== null && option[1] !== undefined) {
          params.append(String(option[0]), String(option[1]));
        }
      }
    }
    let queryOptions = Object.assign(this.getOptions(), {
      headers: new Headers(this.getHeaders())
    }, {search: params});
    return this.http.post(url, data, queryOptions);
  }

  public sendDelete(url: string, options: any = {}) {
    let queryOptions: RequestOptionsArgs = this.mergeOptions(options);
    ////console.log('%c DELETE ', 'background: forestgreen; color: white', url, queryOptions);
    return this.http.delete(url, queryOptions);
  }


  public onError(error: Response, translate = true) {

    let json = error.json();
    let message = json.message || json[0] && json[0].message;
    if(error.status === 0) {
      message = 'Internet connection missing';
    }
    if (error.status === 401) {
      console.log('you have been logedout or not authorized to this page');
      location.reload();
      message="you have been logedout or not authorized";
      //this.router.navigate(['login/']);
    }
    this.notification.error(message || `${error.status} - ${error.statusText}`, null, true);
    return Observable.throw(error);
  }

  public onErrorTranslation(error: Response){
    let json = error.json();
    let message = json.message || json[0] && json[0].message;
    if(error.status === 0) {
      message = 'Internet connection missing';
    }
    if (error.status === 401) {
      console.log('you have been logedout or not authorized to this page');
      location.reload();
      message="you have been logedout or not authorized";
      //this.router.navigate(['login/']);
    }
    const translated = this.translateService.instant(message);
    this.notification.error(translated || message || `${error.status} - ${error.statusText}`, null, true);
    return Observable.throw(error);
  } 
}