import { OnInit } from '@angular/core';
import { MdDialog } from '@angular/material';
import { EcosystemFileJunctionService } from '../../../../../entities/ecosystem/services/ecosystem-file-junctions.service';
import { EcosystemFileJunction } from '../../../models/ecosystem-file-junction.class';
import { EcosystemFileDialogComponent } from '../../ecosystem-file/ecosystem-file.component';
import { EIndicatorReportOtherFields } from '../../../values/ecosystem-indicator-report-other-fields.const';
import { EEcosystemIndicatorReportType } from '../../../values/ecosystem-indicator-report.const';
import { EcosystemIndicatorReportContext } from '../../../services/ecosystem-indicator-report/ecosystem-indicator-report-context.service';
var EcosystemIndicatorReportBaseComponent = /** @class */ (function () {
    function EcosystemIndicatorReportBaseComponent(dialog, ecoFileJunctionService) {
        this.dialog = dialog;
        this.ecoFileJunctionService = ecoFileJunctionService;
        this.cssStyleClass = '';
        this.isOtherOpen = false;
        this.OTHER_FIELDS = EIndicatorReportOtherFields;
    }
    Object.defineProperty(EcosystemIndicatorReportBaseComponent.prototype, "totalErrors", {
        get: function () {
            return this.reportContext.getTotalsErrors(this.type);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemIndicatorReportBaseComponent.prototype, "addOther", {
        get: function () {
            return this.reportContext.otherAdd(this.type);
        },
        set: function (value) {
            this.reportContext.otherAdd(this.type, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemIndicatorReportBaseComponent.prototype, "subtractOther", {
        get: function () {
            return this.reportContext.otherSubtract(this.type);
        },
        set: function (value) {
            this.reportContext.otherSubtract(this.type, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EcosystemIndicatorReportBaseComponent.prototype, "descriptionOther", {
        get: function () {
            return this.reportContext.otherDescription(this.type);
        },
        set: function (value) {
            this.reportContext.otherDescription(this.type, value);
        },
        enumerable: true,
        configurable: true
    });
    EcosystemIndicatorReportBaseComponent.prototype.ngOnInit = function () {
        this.reportContext.setContextByType(this.type);
        this.getDocs();
    };
    EcosystemIndicatorReportBaseComponent.prototype.getDocs = function () {
        var _this = this;
        this.ecoFileJunctionService.get({
            ecosystem_id: this.reportContext.getEcosystem().id,
            item_type: this.reportContext.otherFileJunctionType(this.type)
        })
            .subscribe(function (docs) {
            _this.reportDocs = docs;
        });
    };
    EcosystemIndicatorReportBaseComponent.prototype.toggleOther = function () {
        this.isOtherOpen = !this.isOtherOpen;
    };
    EcosystemIndicatorReportBaseComponent.prototype.saveChanges = function () {
        this.reportContext.onOtherChange.emit();
    };
    EcosystemIndicatorReportBaseComponent.prototype.openFiles = function () {
        var _this = this;
        this.dialog.open(EcosystemFileDialogComponent, {
            width: '800px',
            height: '600px',
            data: {
                ecosystem: this.reportContext.getEcosystem()
            }
        }).afterClosed().subscribe(function (file) {
            if (file) {
                _this.addFileToOrder(file);
            }
        });
    };
    EcosystemIndicatorReportBaseComponent.prototype.addFileToOrder = function (file) {
        var _this = this;
        var fileJunction = new EcosystemFileJunction({
            file_id: file.id,
            item_id: 0,
            item_type: this.reportContext.otherFileJunctionType(this.type)
        });
        this.ecoFileJunctionService.create(fileJunction).subscribe(function (createdFileJunction) {
            if (createdFileJunction) {
                _this.reportDocs.push(createdFileJunction);
            }
        });
    };
    EcosystemIndicatorReportBaseComponent.prototype.removeJunctionFromOrder = function (junction) {
        var _this = this;
        this.ecoFileJunctionService.remove(junction.id).subscribe(function () {
            _this.getDocs();
        });
    };
    EcosystemIndicatorReportBaseComponent.prototype.isImage = function (filename) {
        return ['png', 'jpeg', 'jpg', 'gif', 'bmp', 'tif']
            .includes(filename.split('.').reverse()[0]);
    };
    return EcosystemIndicatorReportBaseComponent;
}());
export { EcosystemIndicatorReportBaseComponent };
