import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { Searchable } from '../../../shared/models/searchable.class';
var NeedClientListService = /** @class */ (function (_super) {
    tslib_1.__extends(NeedClientListService, _super);
    function NeedClientListService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'need/client_list';
        _this.ModelClass = Searchable;
        return _this;
    }
    return NeedClientListService;
}(CrudService));
export { NeedClientListService };
