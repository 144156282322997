/**
 * Created by aleksandr on 30.03.17.
 */

import { NgModule } from '@angular/core';
import { PassportModule } from '../../entities/passport/passport.module';
import { SupervisorNeedsComponent } from './partials/supervisor-needs/supervisor-needs.component';
import { SharedModule } from '../../shared/shared.module';
import { SupervisorTransformsComponent } from './partials/supervisor-transforms/supervisor-transforms.component';
import { SupervisorDashboardComponent } from './partials/supervisor-dashboard/supervisor-dashboard.component';
import { SupervisorDepositsComponent } from './partials/supervisor-deposits/supervisor-deposits.component';
import { NeedModule } from '../../entities/need/need.module';
import { SupervisorNeedComponent } from './partials/supervisor-need/supervisor-need.component';
import { ModificationService } from '../../shared/services/modfication.service';
import { SupervisorDepositsCatalogComponent } from './partials/supervisor-deposits-catalog/supervisor-deposits-catalog.component';
import { SupervisorNeedsCatalogComponent } from './partials/supervisor-needs-catalog/supervisor-needs-catalog.component';
import {
  SupervisorTransformationsCatalogComponent
} from './partials/supervisor-transformations-catalog/supervisor-transformations-catalog.component';
import { SupervisorStatisticsComponent } from './partials/supervisor-statistics/supervisor-statistics.component';
import { DepositModule } from '../../entities/deposit/deposit.module';
import { TransformationModule } from '../../entities/transformation/transformation.module';
import { SuperVisorGuard } from './supervisor.guard';
import { SupervisorDataComponent } from './partials/supervisor-data/supervisor-data.component';
import {
  SupervisorDataNeedCatalogComponent
} from './partials/supervisor-data/supervisor-data-need-catalog/supervisor-data-need-catalog.component';
import {
  SupervisorDataTransformationsCatalogComponent
} from './partials/supervisor-data/supervisor-data-transformations-catalog/supervisor-data-transformations-catalog.component';
import {
  SupervisorDataDepositCatalogComponent
} from './partials/supervisor-data/supervisor-data-deposit-catalog/supervisor-data-deposit-catalog.component';
import { RouterModule } from '@angular/router';
import { SUPERVISOR_ROUTES } from './supervisor.routes';
import { SUPERVISOR_CONTEXT } from './supervisor.context';
import { DASHBOARD_GUARDS } from '../../shared/auth/dashboard-guards.const';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([{
      path: SUPERVISOR_CONTEXT.rootPath,
      canActivate: [...DASHBOARD_GUARDS],
      children: SUPERVISOR_ROUTES
    }]),
    PassportModule,
    DepositModule,
    TransformationModule,
    NeedModule,
  ],
  declarations: [
    SupervisorNeedsComponent,
    SupervisorTransformsComponent,
    SupervisorDashboardComponent,
    SupervisorDepositsComponent,
    SupervisorNeedComponent,
    SupervisorDepositsCatalogComponent,
    SupervisorNeedsCatalogComponent,
    SupervisorTransformationsCatalogComponent,
    SupervisorStatisticsComponent,
    SupervisorDataComponent,
    SupervisorDataNeedCatalogComponent,
    SupervisorDataTransformationsCatalogComponent,
    SupervisorDataDepositCatalogComponent,
  ],
  exports: [],
  providers: [
    ModificationService,
    SuperVisorGuard
  ],
  entryComponents: [
  ]
})
export class SuperVisorModule {

}
