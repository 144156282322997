import {ChangeDetectorRef, Component, Inject, NgZone} from '@angular/core';
import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';
import {IConfirmDialogIconData} from '../../../../shared/confirm-dialog/values/interface/confirm-dialog-data.interface';
@Component({
  moduleId: module.id,
  selector: 'import-spinner',
  templateUrl: 'import-spinner.component.html',
  styleUrls: ['import-spinner.component.scss'],
})
export class ImportSpinnerComponent {

  readonly STEP_MIN = 1;
  iconData: IConfirmDialogIconData;
  isPercentageShownByDefault = false;
  progressLabel = 'Import of deposits ...';
  stepLabel = {
    [this.STEP_MIN]: 'Checking for errors'
  };
  stepShowProgress = {
    [this.STEP_MIN]: {
      percentage: false,
      totals: false,
    }
  };
  footerText = 'Please do not close myUpcyclea during the import process.';
  currentTotalText = '';
  showTotalsDefault = false;
  waitingForErrors = true;
  isStepShown = false;
  stepMax = 2;
  stepCurrent = this.STEP_MIN;

  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any
              , public dialogRef: MdDialogRef<any>
              , private ngZone: NgZone
              , private detector: ChangeDetectorRef) {
    const assignIfNotUndefined = (property: keyof ImportSpinnerComponent) =>
      property === undefined ? this[property] : this.mdDialogData[property];
    this.stepLabel = assignIfNotUndefined('stepLabel');
    this.progressLabel =  assignIfNotUndefined('progressLabel');
    this.stepShowProgress = assignIfNotUndefined('stepShowProgress');
    this.footerText = assignIfNotUndefined('footerText');
    this.currentTotalText = assignIfNotUndefined('currentTotalText');
    this.iconData = assignIfNotUndefined('iconData');
    console.log(this.iconData);
  }

  waitForImport() {
    this.ngZone.run(() => {
      this.isPercentageShownByDefault = true;
    });
  }
  showPercentage(isShown = true) {
    this.ngZone.run(() => {
      this.isPercentageShownByDefault = isShown;
      this.detector.markForCheck();
    });
  }

  get progressOfStep() {
    return this.stepShowProgress[this.stepCurrent];
  }

  get isPercentageShown() {
    return this.progressOfStep['percentage'] === undefined
      ? this.isPercentageShownByDefault : this.progressOfStep['percentage'];
  }
  get isTotalsShown() {
    return (this.progressOfStep['totals'] === undefined
      ? this.showTotalsDefault : this.progressOfStep['totals']) && this.mdDialogData.totals.getValue();
  }

  get pseudoCurrentTotal() {
    return Math.trunc(this.mdDialogData.percentage.getValue() * this.mdDialogData.totals.getValue() / 100);
  }

  changeStep(step: number) {
    if (step > this.stepMax) {
      this.stepMax = step;
    }
    if (this.isStepShown) {
      this.showStep();
    }
    this.ngZone.run(() => {
      this.stepCurrent = step;
      this.detector.markForCheck();
    });
  }

  showStep(isShown: boolean = true, stepMax: number = this.stepMax) {
    this.ngZone.run(() => {
      this.isStepShown = isShown;
      this.stepMax = stepMax;
      this.detector.markForCheck();
    });
  }
}
