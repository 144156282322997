import {UploadedDocument} from '../../../shared/models/uploaders/document.class';
import {DepositDocumentFileService} from '../services/deposit-document-file.service';

export class DepositDocumentFile extends UploadedDocument {
  protected provider = DepositDocumentFileService;

  public deposit_id: number;

  protected fields() {
    return super.fields().concat([
      'deposit_id'
    ]);
  }
}
