/**
 * Created by Aleksandr C. on 9.02.17.
 */
import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/services/crud.service';
import { NeedFile } from '../models/need-file.class';

@Injectable()
export class NeedFileService extends CrudService {
  protected namespace = 'need-files';
  protected namespaceSingular = 'need-file';
  protected ModelClass = NeedFile;

}
