import * as tslib_1 from "tslib";
import { DepositLocationImportFile } from '../models/deposit-location-import-file.class';
import { CrudService } from '../../../shared/services/crud.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../../../shared/services/log-notification.service";
import * as i3 from "../../../shared/services/navigation.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../shared/services/mock/mock-api.service";
var DepositLocationImportFileService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositLocationImportFileService, _super);
    function DepositLocationImportFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'deposit-location-import-files';
        _this.namespaceSingular = 'deposit-location-import-file';
        _this.ModelClass = DepositLocationImportFile;
        return _this;
    }
    DepositLocationImportFileService.ngInjectableDef = i0.defineInjectable({ factory: function DepositLocationImportFileService_Factory() { return new DepositLocationImportFileService(i0.inject(i1.Http), i0.inject(i2.LogNotificationService), i0.inject(i3.NavigationService), i0.inject(i4.TranslateService), i0.inject(i5.MockApiService)); }, token: DepositLocationImportFileService, providedIn: "root" });
    return DepositLocationImportFileService;
}(CrudService));
export { DepositLocationImportFileService };
