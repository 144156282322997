import { MdDialog, MdDialogRef } from '@angular/material';
import { Component } from '@angular/core';
import { NomenclatureService } from '../../service/nomenclature.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
import { NomenclatureChangeConfirmationDialogComponent } from '../nomenclature-change-confirmation-dialog/nomenclature-change-confirmation-dialog.component';
@Component({
  moduleId: module.id,
  templateUrl: 'nomenclature-change-dialog.component.html'
})
export class NomenclatureChangeDialogComponent {

  material: string;
  description: string;

  busy: boolean = false;

  constructor(private mdDialog: MdDialog,
              public mdDialogRef: MdDialogRef<any>,
              private nomenclatureService: NomenclatureService,
              private notification: LogNotificationService) {
  }

  send() {
    if(!this.material) {
      this.notification.error('Material cannot be blank.');
      return;
    }

    if(this.busy) {
      //Don't spam requests if one is already in process
      return;
    }

    this.busy = true;
    this.nomenclatureService
      .sendChangeNomenclature(this.material, this.description)
      .subscribe(
        () => {
          this.mdDialogRef.close(true);
          this.mdDialog.open(NomenclatureChangeConfirmationDialogComponent);
          }, 
        () => {
          this.busy = false;
        }
      );
  }
}
