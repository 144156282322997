<md-input-container
  (click)="focusInput()"
  (keydown)="preventOnEnterEventBubble($event)"
  (keyup)="preventOnEnterEventBubble($event)"
  (keypress)="preventOnEnterEventBubble($event)"
  class="passport-multiple-field-input"
  [floatPlaceholder]="'always'"
>
  <ng-container *ngIf="this.showValuesAsChip()">
    <md-chip *ngFor="let record of records" class="passport-multiple-field-input__chip"
             [ngClass]="{'passport-multiple-field-input__chip--deleting' : deletionSet.has(record?.id)}"
             (click)="$event.stopPropagation()">
      <div>{{recordNameFunc(record)}}</div>
      <button class="passport-multiple-field-input__chip-close-button"
              (click)="recordDelete(record)"
              [disabled]="deletionSet.has(record?.id)"
      >
        <md-icon *ngIf="!disabled"
                 class="passport-multiple-field-input__chip-close"
                 >close</md-icon>
      </button>
    </md-chip>
  </ng-container>
  <ng-container *ngIf="!this.showValuesAsChip()">
    <span *ngFor="let record of records" class="passport-multiple-field-input__text">{{recordNameFunc(record)}}</span>
  </ng-container>
  <section class="passport-multiple-field-input__input-container"
           [ngClass]="{'passport-multiple-field-input__input-container--disabled': this.disabled}"
           [attr.data-value]="control.value">
    <input [placeholder]="placeholder"
           [autocomplete]="'off'"
           [size]="inputSize"
           [readonly]="isLoading"
           mdInput
           #input
           (blur)="changeRecord()"
           class="passport-multiple-field-input__input"
           [formControl]="control">
  </section>
</md-input-container>
