import {ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Passport, PassportFamily} from '../../models/passport.class';
import {PassportService} from '../../services/passport.service';
import {MdDialog} from '@angular/material';
import {ViewNomenclatureDialogComponent} from '../../dialogs/view-nomenclature/view-nomenclature.component';
import {CopyPassportDialogComponent} from '../../dialogs/copy-passport-dialog/copy-passport-dialog.component';
import {PASSPORT_STATUS, PASSPORT_STATUS_CONST} from '../../values/passport-status.const';
import {DatePipe} from '@angular/common';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogComponent} from '../../../../shared/confirm-dialog/confirm-dialog.component';
import {Observable} from 'rxjs/Observable';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {PassportStatus} from '../../models/passport-status.class';
import {
  NomenclatureChangeDialogComponent
} from '../../../nomenclature/dialogs/nomenclature-change-dialog/nomenclature-change-dialog.component';
import {ModuleNames} from '../../../../shared/values/module-names.const';
import {PASSPORT_DEPENDENCY} from '../../values/passport-dependency.const';
import {getSearchStatus} from '../../../../shared/helpers/search-status-update';
import {
  PassportRatingExplanationDialogComponent
} from '../../dialogs/passport-rating-explanation-dialog/passport-rating-explanation-dialog';
import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
import {ViewCategoryDialogComponent} from '../../dialogs/view-category/view-category.component';
import {ChangeEvent} from 'angular2-virtual-scroll';
import {PASSPORT_RATING} from '../../values/passport-rating/passport-rating-values.const';
import {FilterStateService} from '../../../../shared/services/filterState.service';
import {IPassportFilter} from '../../../../shared/interfaces/filter-states.interface';
import {NavbarStateService} from '../../../../shared/services/navbar.service';
import {CurrentUserService} from '../../../../shared/services/current-user.service';
import {ManufacturerGenericComponent} from '../../dialogs/manufacturer-generic-dialog/manufacturer-generic-dialog.component';
import {cloneDeep, omit, orderBy} from 'lodash';
import {IPassportRatingWithLocalPassportFilter} from '../../values/passport-rating/passport-rating-filter.interface';
import { switchMap,debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'passport-list',
  templateUrl: 'passport-list.component.html',
})
export class PassportListComponent implements OnInit {
  @ViewChild('resultsList') resultsList;
  @ViewChild('filterRating') filterRating;

  public records: Passport[] = [];
  public currentPage = 1;
  public count: number;
  public perPage: number;
  public totalCount: number;
  public parentModuleName: string;
  public createdBy: any[] = [];
  public sort_order: any[] = [];
  public categories: string[] = [];
  public chosenCategory: string;
  public moduleNames = ModuleNames;
  public advancedResearchLoadPassports = false;
  public showFilterByRating = false;

  @Input() advancedResearchTheme = false;
  @Input() hasNoPagination: boolean;
  @Input() isInfiniteLoadingPageSize: boolean;
  @Input() includeDrafts = true;
  @Input() isForPublicAccess = false ;
  @Output() public onSelected = new EventEmitter();

  filterStatuses: PassportStatus[] = [
    PASSPORT_STATUS_CONST.ASK_FOR_MODIFICATION,
    PASSPORT_STATUS_CONST.WAITING_FOR_PUBLICATION,
    PASSPORT_STATUS_CONST.PUBLISHED,
    PASSPORT_STATUS_CONST.DRAFT,
  ];
  isRecordsLoaded: boolean;
  isLoadingNextPage: boolean;
  private _confirmDeletionText: string;
  private alreadyLoadedIndexes: {id: number, colored: boolean}[] = [];

  passportFilter: IPassportFilter = {
    categoryTags: [],
    tags: [],
    createdBy: '',
    createdDateFrom: '',
    createdDateTo: '',
    orderBy: '',
    searchStatus: this.filterStatuses.map((status) => status.value),
    search: new FormControl(),
    filterBy: {
      C2cCertified: false,
      selectRatingFilter: false,
      composition: {color: false, black: false},
      cycle: {color: false, black: false},
      energy: {color: false, black: false},
      water: {color: false, black: false},
      social: {color: false, black: false},
      passportWithoutRating: true,
    },
    loadSelfPassports: false,
    loadDraftPassports: false,
    version_filter : -1,
    family: [] ,
  };
  isVersionFilterOn = false;
  resetFilterState = cloneDeep(omit(this.passportFilter, 'orderBy'));
  private skipLoadOnFamilyInit = true;

  constructor(public dialog: MdDialog,
              private _translate: TranslateService,
              private _navigationService: NavigationService,
              private _mdDialog: MdDialog,
              private _router: Router,
              private _datePipe: DatePipe,
              private _service: PassportService,
              private ref: ChangeDetectorRef,
              private filterStateService: FilterStateService,
              private _currentUserService: CurrentUserService,
              public navBarStateService: NavbarStateService
  ) {
    this._translate.get('CONFIRM_DELETE_PASSPORT').subscribe((translation) => {
      this._confirmDeletionText = translation;
    });
    // determine in which module component is used
    this.parentModuleName = this._navigationService.getModuleName();
    this.setDefaultSortOrder();
    if (this.parentModuleName === ModuleNames.SPECIAL_ADMIN) {
      this.filterStatuses = this.filterStatuses.filter(
        (value) => value !== PASSPORT_STATUS_CONST.DRAFT
      );
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (this.filterRating && !this.filterRating.nativeElement.contains(targetElement)) {
       this.showFilterByRating = false;
       this.ref.detectChanges();
    }
  }

  ngOnInit() {
    if (!this.includeDrafts) {
      this.passportFilter.searchStatus = this.passportFilter.searchStatus.filter(status => status !== PASSPORT_STATUS_CONST.DRAFT.value);
    }
    this.isVersionFilterOn = false ;
    const storedPage = this._service.getListPage();
    const options: any = {};
    if (this.parentModuleName === ModuleNames.SPECIAL_ADMIN
      || (this.canDisplayDraftFilter && !this.passportFilter.loadDraftPassports)) {
      this.passportFilter.searchStatus = this.passportFilter.searchStatus.filter(status => status !== PASSPORT_STATUS.DRAFT);
      this.resetFilterState.searchStatus = cloneDeep(this.passportFilter.searchStatus);
    }

    this.passportFilter = this.filterStateService.productPassportList ? this.filterStateService.productPassportList : this.passportFilter;
    !!storedPage ? this.loadRecords(storedPage) : this.loadRecords();


    if (!this.passportFilter.loadSelfPassports) {
      options.supervisor = 1;
    }
    this.createViewStoreIfNotExist();

    /*this._service.getCategoriesList().subscribe((categories: string[]) => {
      let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    this._service.getCategoriesList().subscribe((categories: string[]) => {
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      this.categories = categories.sort(collator.compare);
    });*/
    this.passportFilter.search.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(async val => {
        if (val) {
          await this.loadRecords();
          return;
        }
        this.resetSearch();
        await this.loadRecords(null, true);
      })
    ).subscribe();
  }
  private createViewStoreIfNotExist() {
    if (!this.filterStateService.passportListViewState) {
      this.filterStateService.passportListViewState = {showFamilyInfoBanner: false};
    }
  }
  updateAlreadyLoadedIndexes(index: number) {
    const lastItem = this.alreadyLoadedIndexes[this.alreadyLoadedIndexes.length - 1];
    this.alreadyLoadedIndexes = [...this.alreadyLoadedIndexes, {
      id: index,
      colored: !!lastItem ? !lastItem.colored : true
    }];
  }

  get storageViewState() {
    return this.filterStateService.passportListViewState;
  }

  get ratingWithLocalPassportFilter(): IPassportRatingWithLocalPassportFilter {
    return this.passportFilter.filterBy as IPassportRatingWithLocalPassportFilter;
  }

  get agentTutorialLink() {
    let lang =  `/${this._translate.currentLang}`;
    if (lang === 'pt') {
      lang = '/es';
    } else if ( lang === 'fr') {
      lang = '';
    }
    return 'https://upcyclea.com' + lang  + '/upcyclea-librairy-ask-for-passport-creation/';
  }
  get canClearFiltersAlternativeRules() {
    return {
      family: [PassportFamily.FAMILY_LOCAL,
        PassportFamily.FAMILY_MANUFACTURER,
        PassportFamily.FAMILY_GENERIC],
      filterBy: {
        C2cCertified: true,
        composition: {color: true, black: true},
        cycle: {color: true, black: true},
        energy: {color: true, black: true},
        water: {color: true, black: true},
        social: {color: true, black: true},
        passportWithoutRating: true,
        selectRatingFilter: true,
      },
      search: ''
  };
  }

  clearFilters($event) {
    this.passportFilter = $event;
    this.loadRecords();
  }

  isItemColored(item): boolean {
    if (!item || !item.id) {
      return;
    }
    const loadedItem = this.alreadyLoadedIndexes.find(i => i.id === item.id);
    return !!loadedItem ? loadedItem.colored : false;
  }

  setDefaultSortOrder(): void {
    const creatorKey: string = (this.parentModuleName === ModuleNames.MANUFACTURER || this.parentModuleName === ModuleNames.SPECIAL_ADMIN)
      ? 'company' : 'username';
    this.sort_order = [
      { key: '-updated_at', value: 'Modification date' },
      { key: '-created_at', value: 'Creation date' },
      { key: creatorKey, value: 'Creator' },
      { key: 'name', value: 'Name' },
      { key: 'performance', value: 'Performance' },
    ];
  }

  setDefaultOrderBy(): void {
    this.passportFilter.orderBy = this.advancedResearchTheme ? 'name' : 'performance';
  }

  resetSearch(): void {
    this.records = [];
    this.currentPage = null;
    this.count = null;
    this.perPage = null;
    this.totalCount = null;
  }
  loadNextPage(event: ChangeEvent) {
    if (this.totalCount <= 20
      || this.totalCount === this.records.length
      || event.end !== this.records.length - 1) {
        return;
      }
      this.isLoadingNextPage = true;
      this.isInfiniteLoadingPageSize = true;
      const options = this.getLoadRecordsOptions(++this.currentPage) ;
    this.getPage(options, true);
  }

  onVersionFilter(data: any) {
      this.isVersionFilterOn = data.filter ;

      if ( this.isVersionFilterOn) {
        this.passportFilter.version_filter = data.passport.link_reference ? data.passport.link_reference : data.passport.id ;
      } else {
        this.passportFilter.version_filter = -1 ;
      }
      this.loadRecords();
  }

  public onFamilyFilterChange(newFilter) {
    this.passportFilter.family = newFilter;
    if (this.skipLoadOnFamilyInit) {
      this.resetFilterState.family = newFilter;
      this.skipLoadOnFamilyInit = false;
    }
    this.loadRecords();
  }

  getLoadRecordsOptions(page?: number, noSearch?: boolean) {
    const options: any = [

      ['expand', 'user,nomenclature,price,composite_name,rating,energies,type,passport_components,category', 'family']

    ];
    const isOnlyRated = !(this.passportFilter.filterBy as IPassportRatingWithLocalPassportFilter).passportWithoutRating;
    let familyOptions = this.passportFilter.family;
    if (this.passportFilter.family.length) {
      if (isOnlyRated) {
        familyOptions = familyOptions.filter(
          status => ![PassportFamily.FAMILY_LOCAL, PassportFamily.FAMILY_GENERIC].includes(Number(status)));
        if (!familyOptions.length) {
          familyOptions = [-1];
        }
      }
      familyOptions.forEach(option => options.push(['family[]', option]));
    } else if (isOnlyRated) {
      familyOptions = [PassportFamily.FAMILY_MANUFACTURER];
      familyOptions.forEach(option => options.push(['family[]', option]));
    }

    if (!this.passportFilter.loadSelfPassports && this.parentModuleName === ModuleNames.SUPERVISOR) {
      options.push(['supervisor', 1]);
    }

    if (this.parentModuleName === ModuleNames.MANUFACTURER) {
      options.push(['manufacturer', 1]);
    }

    if (!this.passportFilter.loadSelfPassports && this.parentModuleName === ModuleNames.AGENT) {
      options.push(['agent', 1]);
    }

    if (this.passportFilter.loadSelfPassports && this.parentModuleName === ModuleNames.AGENT) {
      options.push(['agentOnly', 1]);
    }

    if (this.advancedResearchTheme) {
      options.push(['supervisor', 1]);
      if (this.advancedResearchLoadPassports) {
        options.push(['manufacturer', 1]);
      }
    }

    if (this.passportFilter.search.value && this.passportFilter.search.value.length > 2 && !noSearch) {
      options.push(['search', this.passportFilter.search.value.replace(/\s*$/, '')]);
    }

    if (this.passportFilter.tags.length > 0) {
      for (const tag of this.passportFilter.tags) {
        options.push(['materials[]', tag.id]);
      }
    }

    if (this.passportFilter.categoryTags.length > 0) {
      for (const tag of this.passportFilter.categoryTags) {
        options.push(['categories[]', tag.id]);
      }
    }
    if (this.passportFilter.createdBy) {
      options.push(['company_name', this.passportFilter.createdBy]);
    }

    if (this.passportFilter.createdDateFrom) {
      options.push(['created_from', this.passportFilter.createdDateFrom]);
    }
    if (this.passportFilter.version_filter && this.passportFilter.version_filter !== -1 ) {
      if (this.isVersionFilterOn) {
        options.push(['version_reference', this.passportFilter.version_filter]);
      }
    }

    if (this.passportFilter.createdDateTo) {
      options.push(['created_to', this.passportFilter.createdDateTo]);
    }

    if (!this.passportFilter.orderBy) {
      this.setDefaultOrderBy();
    }
    options.push(['sort', this.passportFilter.orderBy]);
    if (this.navBarStateService.multilanguage) {
      options.push(['english', true]);
    }

    if (this.passportFilter.searchStatus.length > 0) {
      for (const status of this.passportFilter.searchStatus) {
        options.push(['status[]', status]);
      }
    }

    if (this.chosenCategory) {
      options.push(['category', this.chosenCategory]);
    }
    options.push(['page', page]);

    if (this.advancedResearchTheme) {
      options.push(['page_size', 20]);
    }
    const C2C_CERTIFIED_VAL = 2;
    if (this.passportFilter.filterBy.C2cCertified) { options.push(['c2c_certified', C2C_CERTIFIED_VAL]); }

    const compositionFilterOptions = this.createOptionForRating('rating[rating_composition]', this.passportFilter.filterBy.composition);
    const recycleFilterOptions = this.createOptionForRating('rating[rating_recyclability]', this.passportFilter.filterBy.cycle);
    const energyFilterOptions = this.createOptionForRating('rating[rating_energy]', this.passportFilter.filterBy.energy);
    const waterFilterOptions = this.createOptionForRating('rating[rating_water_stewardship]', this.passportFilter.filterBy.water);
    const socialFilterOptions = this.createOptionForRating('rating[rating_social_fairness]', this.passportFilter.filterBy.social);

    if (!!compositionFilterOptions) { options.push(compositionFilterOptions); }
    if (!!recycleFilterOptions) { options.push(recycleFilterOptions); }
    if (!!energyFilterOptions) { options.push(energyFilterOptions); }
    if (!!waterFilterOptions) { options.push(waterFilterOptions); }
    if (!!socialFilterOptions) { options.push(socialFilterOptions); }
    if ((this.passportFilter.filterBy as IPassportRatingWithLocalPassportFilter).passportWithoutRating) {
      options.push(['include_none_rated', true]);
    }

    this.filterStateService.productPassportList = this.passportFilter;

    return options;
  }

  createOptionForRating(rating: string, filterBy: {color: boolean, black: boolean})  {
    let optionParam = null;
    if (filterBy.color && !filterBy.black) {
      optionParam =  [rating, PASSPORT_RATING.DECLARATION_VALID];
    } else if (!filterBy.color && filterBy.black) {
      optionParam = [rating, PASSPORT_RATING.THIRD_PARTY_VALID];
    } else if (filterBy.color && filterBy.black) {
      optionParam = [rating, PASSPORT_RATING.DECLARATION_OR_THIRD_PARTY_VALID];
    }
    return optionParam;
  }

  async loadRecords(page?: number, noSearch?: boolean) {
    this.isRecordsLoaded = false;
    const filterDraftStatuses = (): void => {
      this.passportFilter.searchStatus = this.passportFilter.searchStatus.filter(
        (value) => value !== PASSPORT_STATUS_CONST.DRAFT.value
      );
    };
    const pushDraftInStatuses = (): void => {
      const isExist = this.passportFilter.searchStatus.find(i => i === PASSPORT_STATUS_CONST.DRAFT.value);
      if (!!isExist) {
        return;
      }
      this.passportFilter.searchStatus.push(PASSPORT_STATUS_CONST.DRAFT.value);
    };
    if (this.parentModuleName === ModuleNames.SPECIAL_ADMIN) {
      filterDraftStatuses();
    }
    if (this.canDisplayDraftFilter) {
      filterDraftStatuses();
      this.passportFilter.loadDraftPassports ? pushDraftInStatuses() : filterDraftStatuses();
    }
    if (!this.passportFilter.searchStatus.length) {
      return this.resetSearch();
    }
    this.loadCreators();

    const options = this.getLoadRecordsOptions(page, noSearch);
   // this.records = [] ;
    this.getPage(options, false);


  }

  getPage(options, nextPage) {
    if (this.isForPublicAccess) {
      options.push(['publicAccess', 1]);
    }
    if (nextPage) {
      this._service.getWithArray(this.getLoadRecordsOptions(++this.currentPage)).subscribe((res) => {
        const headers = res.headers;
        this.assignVariables(headers, res);
        res.json().map((item: any) => {
          this.records = [...this.records, new Passport(item)];
          this.updateAlreadyLoadedIndexes(item.id);
        });
        this.isLoadingNextPage = false;
        this.isRecordsLoaded = true;
      }, () => this.isRecordsLoaded = true);
    } else {
      this._service.getWithArray(options).subscribe((res) => {
        const headers = res.headers;
        this.currentPage = Number(headers.get('X-Pagination-Current-Page')) || 1;
        this.assignVariables(headers, res);
        this.alreadyLoadedIndexes = [];
        this.records = res.json().map((item: any) => {this.updateAlreadyLoadedIndexes(item.id); return new Passport(item); });
        this.isRecordsLoaded = true;
      }, () => this.isRecordsLoaded = true);
    }


  }
  assignVariables (headers , res) {
    this.count = Number(headers.get('X-Pagination-Page-Count')) || 1;
    this.perPage = Number(headers.get('X-Pagination-Per-Page')) || res.json().length;
    this.totalCount = Number(headers.get('X-Pagination-Total-Count')) || res.json().length;
  }
  loadCreators() {
    const options: any = {
      'supervisor': 1,
      'publicAccess': 1,
      'type': PASSPORT_DEPENDENCY.INDEPENDENT
    };

    if (this.createdBy.length > 0) {
      return;
    }

    this._service.getCreatorIds(options).subscribe((res: any) => {
      this.createdBy = res.json().filter(item => !!item.company_name).map(item => ({
        'key': item.company_name,
        'value': item.company_name
      })).sort((before, after) => (before.value).localeCompare(after.value));
    });
  }

  openRecord(record: Passport) {
    this._router.navigate([this.parentModuleName === 'guest' ? '/passports' : this.parentModuleName + '/passports', record.id]);
  }

  duplicateRecord(record: Passport) {
    const dialogRef = this.dialog.open(CopyPassportDialogComponent, {
      data: {
        name: record.name,
        parentModule: this.parentModuleName
      }
    });

    dialogRef.afterClosed()
      .flatMap((result) => {
        return result
          ? this._service.duplicate(record.id, { name: result , status: PASSPORT_STATUS.DRAFT })
          : Observable.of(null);
      })
      .subscribe((duplicate: Passport) => {
        this.openRecord(duplicate);

      });

  }

  deleteRecord(record: Passport) {
    record.canDelete()
      .flatMap((canDelete) => {
        if (canDelete) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              text: this._confirmDeletionText
            }
          });

          return dialogRef.afterClosed();
        } else {
          return Observable.of(false);
        }
      })
      .flatMap((confirmed) => {
        return confirmed
          ? record.destroy().map(() => true)
          : Observable.of(false);
      })
      .subscribe((deleted) => {
        if (deleted) {
          this.records.splice(this.records.indexOf(record), 1);
        }
      });
  }

  pageChanged(page: number) {
    this._service.setListPage(page);
    this.loadRecords(page, false);
  }

  removeTag(tag: any, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.passportFilter.tags.splice(this.passportFilter.tags.indexOf(tag), 1);
    this.loadRecords();
  }
  removeCategoryTag(tag: any, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.passportFilter.categoryTags.splice(this.passportFilter.categoryTags.indexOf(tag), 1);
    this.loadRecords();
  }

  navigateToCreatePassport() {

    if (this._currentUserService.isSpecialAdmin) {
      this.openManufacturerGenericDialog((data) => {
        if (data && data.isValidated) {
          const { value } = data;
          this._router.navigate([this.parentModuleName + '/passports/new', { type: value }], {});
        }
      });
    } else {
      this._router.navigate([this.parentModuleName + '/passports/new']);
    }
  }

  openManufacturerGenericDialog(cb) {
    const dialogRef = this.dialog.open(ManufacturerGenericComponent, {
      position: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      cb(result);
    });
  }

  openNomenclatureDialog(materiallist: any) {
    // MdDialogConfig
    const dialogRef = this.dialog.open(ViewNomenclatureDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    });
    materiallist.blur();
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.passportFilter.tags.push(result);
        this.loadRecords();
      }
    });
  }

  toggleState(state: number) {
    this.passportFilter.searchStatus = getSearchStatus(state, this.passportFilter.searchStatus);
    this.loadRecords();
  }

  askForChangeInNomenclatureOpenDialog() {
    this._mdDialog.open(NomenclatureChangeDialogComponent);
  }
  isSpecialAdminOrManufactureActiveRole() {
    return [ModuleNames.SPECIAL_ADMIN, ModuleNames.MANUFACTURER].includes(this.parentModuleName);
  }
  isAgentOrSupervisorActiveRole() {
    return [ModuleNames.AGENT, ModuleNames.SUPERVISOR].includes(this.parentModuleName);
  }
  isPassportRatingExplanationDialogAvailable(): boolean {
    const parentModuleIs = (module: string): boolean => this.parentModuleName === module;
    return parentModuleIs(this.moduleNames.MANUFACTURER)
      || parentModuleIs(this.moduleNames.SUPERVISOR) || parentModuleIs(this.moduleNames.GUEST);
  }
  openRatingExplanationDialog(): void {
    this._mdDialog.open(PassportRatingExplanationDialogComponent, {
      ...DIALOG_THEME.BELOW_NAVBAR_WIDE,
      data: {
        isActionButtonsShown: false
      }
    });
  }
  getItemsAmountText(): string {
    const isEng = this._translate.currentLang.toUpperCase() === 'EN';
    const translate = (value: string) => this._translate.instant(value);
    return isEng
      ? `${translate('Found')} ${this.totalCount} ${translate('Items').toLowerCase()}`
      : `${this.totalCount} ${translate('items')} ${translate('Found').toLowerCase()}`;
  }

  openCategoryeDialog(categorie: any): void {

    categorie.blur();
    const dialogRef = this.dialog.open(ViewCategoryDialogComponent, {
      height: '80%',
      width: '80%',
      position: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.passportFilter.categoryTags.push(result);
        this.loadRecords();

      }
    });
  }

  showFilterByRatingPopUp(): void {
    this.showFilterByRating = true;
  }

  selectOrDeselectAllRatingFilters(event): void {
    const check = event.checked;
    this.passportFilter.filterBy.C2cCertified = check;
    this.filterList.map(key => {
      this.passportFilter.filterBy[key].color = check;
      this.passportFilter.filterBy[key].black = check;
    });
    this.loadRecords();
  }

  get isRatingFilterApplied(): boolean {
    return this.filterList.map(key => {
      return this.passportFilter.filterBy[key].color || this.passportFilter.filterBy[key].black;
    }).some(a => a)
    || this.passportFilter.filterBy.C2cCertified;
  }

  get isAllFilterSelected(): boolean {
    return this.filterList.map(key => {
      return this.passportFilter.filterBy[key].color && this.passportFilter.filterBy[key].black;
    }).every(a => a)
    && this.passportFilter.filterBy.C2cCertified;
  }

  get filterList() {
    const keys = Object.keys(this.passportFilter.filterBy);
    keys.shift();
    return keys;
  }

  isStatusFilterChecked(filteredStatus): boolean {
    return this.passportFilter.searchStatus.some(status => status === filteredStatus);
  }

  get canDisplayOwnPassportFilter() {
    return this.isAgentOrSupervisorActiveRole();
  }

  get canDisplayDraftFilter() {
    return this.isAgentOrSupervisorActiveRole();
  }
}
