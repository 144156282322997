import { Injectable } from '@angular/core';
import {PassportGtin} from '../models/passport-gtin.class';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {ActiveRecord} from '../../../shared/models/active-record.class';
import {forkJoinSafe} from '../../../shared/helpers/fork-join-safe.helper';
import {PassportGtinService} from './passport-gtin.service';

@Injectable({
  providedIn: 'root'
})
export class PassportGtinBufferService {
  private ownerObject: ActiveRecord;

  private recordsForSave: ActiveRecord[] = [];
  private notifierSubscription: Subscription;
  private notifier: Observable<any>;
  private notifierAction: (data: any, observer: Observable<any>) => unknown;
  private recordTransformFunction =
    (recordsForSave: ActiveRecord[]) => forkJoinSafe(recordsForSave.map(record => record.save()))

  constructor(private passportGtinService: PassportGtinService) {
    this.emitSourceGtin = this.emitSourceGtin.bind(this);
  }

  emitSourceGtin(name: string): Observable<any> {
    const errors = this.getErrorsByData(name, PassportGtin);
    if (!errors.length) {
      const record = new PassportGtin({code: name, passport_id: this.ownerObject.id});
      return this.emitSource(record);
    } else {
      return this.throwFirstErrors(errors);
    }
  }

  emitSourceDelete(model: ActiveRecord) {
    this.recordsForSave.filter(item => model !== item || !!model.id && model.id !== item.id);
  }

  emitSource(model: ActiveRecord, validate = false) {
    let errors = [];
    if (validate) {
       errors = this.getErrors(model);
    }
    if (!errors.length) {
      this.recordsForSave.push(model);
      this.notifierSubscription = this.onNotifierEmit();
      return Observable.of(model);
    } else {
     return this.throwFirstErrors(errors);
    }
  }

  private throwFirstErrors(errors: string[]) {
    return  Observable.throwError(() => {
      return new Error('invalid format');
    });
  }

  /**
    Inform user that data is invalid, before actually saving it.
    Required in case if, before data saving and user data provision may pass significant time.
    This solution maybe changed in future as it ruins DRY principle
    , because in backend and front end contains the same validation rules.
    @model ActiveRecord, which will be saved in the future.
    @return string[] errorMessages.
   */
  private getErrors(model: ActiveRecord) {
    if (model instanceof PassportGtin) {
      return this.gtinValidationRule(model.code);
    }
    return ['Invalid data'];
  }
  private getErrorsByData(data: any, activeRecordClass: {new (...args: any[]): ActiveRecord}) {
    if (activeRecordClass  === PassportGtin) {
      return this.gtinValidationRule(data);
    }
    return ['Invalid data'];
  }

  private gtinValidationRule(code: string) {
    return code.trim().match(/^\d{8,14}$/) ? [] : ['Invalid GTIN format: 8 to 14-digit string requested'];
  }

  private onNotifierEmit() {
    if (!this.notifierSubscription) {
      return this.notifier.subscribe((data) => {
        const childObserver = this.recordTransformFunction(this.recordsForSave);
        if (this.notifierAction) {
          this.notifierAction(data, childObserver);
        } else {
          childObserver.subscribe();
        }
      });
    }
    return this.notifierSubscription;
  }

  setNotifier(observable: Observable<any>, notifierAction?: (data: any, observer: Observable<any>) => any) {
    this.notifier = observable;
    this.notifierAction = notifierAction;
  }

  setOwnerObject(model: ActiveRecord) {
    this.ownerObject = model;
  }

  setRecordTransform(transformFunction: (recordsForSave: ActiveRecord[]) => Observable<any>) {
    this.recordTransformFunction = transformFunction;
  }

  unsubcribe() {
    this.recordsForSave = [];
    if (this.notifierSubscription) {
      this.notifierSubscription.unsubscribe();
    }
  }

  public gtinMultiSaveTransform(recordsForSave: ActiveRecord[]) {
    const gtins = recordsForSave as PassportGtin[];
    if (recordsForSave.length) {
      return this.passportGtinService.saveMultipleWithSamePassports(gtins[0].passport_id, gtins.map(gtin => gtin.code));
    }
    Observable.of([]);
  }
}
