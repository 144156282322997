import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { ContactService } from '../service/contact.service';
var Contact = /** @class */ (function (_super) {
    tslib_1.__extends(Contact, _super);
    function Contact() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = ContactService;
        return _this;
    }
    Contact.prototype.fields = function () {
        return ['id', 'name', 'client_id', 'email', 'function', 'phone'];
    };
    return Contact;
}(ActiveRecord));
export { Contact };
