import * as tslib_1 from "tslib";
import { CrudService } from './crud.service';
import { Synonym } from '../models/synonym.class';
var SynonymService = /** @class */ (function (_super) {
    tslib_1.__extends(SynonymService, _super);
    function SynonymService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'synonyms';
        _this.namespaceSingular = 'synonym';
        _this.ModelClass = Synonym;
        return _this;
    }
    return SynonymService;
}(CrudService));
export { SynonymService };
