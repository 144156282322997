import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { Searchable } from '../../../shared/models/searchable.class';
var NeedSalesListService = /** @class */ (function (_super) {
    tslib_1.__extends(NeedSalesListService, _super);
    function NeedSalesListService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'need/sales_list';
        _this.ModelClass = Searchable;
        return _this;
    }
    return NeedSalesListService;
}(CrudService));
export { NeedSalesListService };
