/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, Input } from '@angular/core';
import { CategoryItem } from '../../../category/models/category-item.class';
import { MdDialogRef } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'view-category',
  templateUrl: 'view-category.component.html',
  styleUrls: ['view-category.component.scss'],
})
export class ViewCategoryDialogComponent {

  @Input() public item: CategoryItem;

  constructor(private dialog: MdDialogRef<any>) {
  }

  onSave(item: CategoryItem) {
    this.item = item;
    this.dialog.close(this.item);
  }
}
