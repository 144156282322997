import * as tslib_1 from "tslib";
import { StatsContainer } from './stats-container.class';
import { DepositOwnerStats } from './deposit-owner-stats.class';
var DepositOwnerStatsContainer = /** @class */ (function (_super) {
    tslib_1.__extends(DepositOwnerStatsContainer, _super);
    function DepositOwnerStatsContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DepositOwnerStatsContainer.prototype.extractData = function (data) {
        var json = _super.prototype.extractData.call(this, data);
        this.createModel(json, ['total', 'last_year', 'last_month', 'last_week']);
        return json;
    };
    DepositOwnerStatsContainer.prototype.createModel = function (json, key) {
        key.forEach(function (k) {
            if (!!json[k]) {
                json[k] = json[k].map(function (data) { return new DepositOwnerStats(data); });
            }
        });
    };
    DepositOwnerStatsContainer.prototype.getDeposits = function () {
        var result = {};
        var deposits_assigned = 0, deposits_characterized = 0, deposits_published = 0, deposits_rejected = 0;
        if (this.total) {
            this.total.forEach(function (item) {
                if (item.deposits_assigned) {
                    deposits_assigned += item.deposits_assigned;
                }
                if (item.deposits_characterized) {
                    deposits_characterized += item.deposits_characterized;
                }
                if (item.deposits_published) {
                    deposits_published += item.deposits_published;
                }
                if (item.deposits_rejected) {
                    deposits_rejected += item.deposits_rejected;
                }
            });
        }
        result.deposits_assigned = deposits_assigned;
        result.deposits_published = deposits_published;
        result.deposits_characterized = deposits_characterized;
        result.deposits_characterized_pct = this.calculatePercentage(deposits_characterized, deposits_published + deposits_rejected);
        result.deposits_rejected = deposits_rejected;
        result.deposits_rejected_pct = this.calculatePercentage(deposits_rejected, deposits_published + deposits_rejected);
        return result;
    };
    DepositOwnerStatsContainer.prototype.getAgents = function () {
        var result = {};
        var new_agents = 0;
        if (this.total) {
            this.total.forEach(function (item) {
                if (item.new_agents) {
                    new_agents += item.new_agents;
                }
            });
        }
        result.new_agents = new_agents;
        return result;
    };
    DepositOwnerStatsContainer.prototype.getSites = function () {
        var result = {};
        var locations_assigned = 0, locations_published = 0;
        if (this.total) {
            this.total.forEach(function (item) {
                if (item.deposit_locations_assigned) {
                    locations_assigned += item.deposit_locations_assigned;
                }
                if (item.deposit_locations_published) {
                    locations_published += item.deposit_locations_published;
                }
            });
        }
        result.locations_assigned = locations_assigned;
        result.locations_published = locations_published;
        return result;
    };
    DepositOwnerStatsContainer.prototype.calculatePercentage = function (value, total) {
        if (total === 0) {
            return '0%';
        }
        return (value / total * 100).toFixed(1) + '%';
    };
    return DepositOwnerStatsContainer;
}(StatsContainer));
export { DepositOwnerStatsContainer };
