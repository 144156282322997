/**
 * Created by atat on 18/06/2017.
 */
import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ECustomListHintClass, ECustomListRecordStatus, ICustomListRecord } from '../../../../shared/custom-list/interfaces/custom-list.interface';
import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
import { TransformOrderService } from '../../../ecosystem-manager/services/transform-order.service';
import { TransformOrder } from '../../../ecosystem-manager/models/transform-order.class';
import { DatePipe } from '@angular/common';
import { TRANSFORM_ORDER_STATUS } from '../../values/transform-order-statuses.const';
import { MdDialog } from '@angular/material';
import { TsmOrderDetailsDialogComponent } from '../../dialogs/tsm-order-details-dialog/tsm-order-details-dialog.component';
import { TRANSFORM_TYPES } from '../../values/transform-types.const';
import * as moment from 'moment';
import { TransformationOrderTransactionConfirmDialogComponent } from '../../dialogs/transformation-order-transaction-confirm-dialog/transformation-order-transaction-confirm-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import { ECOSYSTEM_ORDER_STATUS } from '../../../ecosystem-manager/values/ecosystem-order-statuses.const';
import { EcosystemProblemUtilsService } from '../../../../entities/ecosystem/services/ecosystem-problem-utils.service';
import { LogNotificationService } from '../../../../shared/services/log-notification.service';
var TsmOrdersArchiveCatalogComponent = /** @class */ (function () {
    function TsmOrdersArchiveCatalogComponent(_ordersService, mdDialog, datepipe, ecosystemProblemUtilsService, notificationService) {
        var _this = this;
        this._ordersService = _ordersService;
        this.mdDialog = mdDialog;
        this.datepipe = datepipe;
        this.ecosystemProblemUtilsService = ecosystemProblemUtilsService;
        this.notificationService = notificationService;
        this.records = [];
        this.currentPage = 1;
        this.totalPagesCount = 0;
        this.isNextPageLoading = false;
        this.sortOptions = [
            { field: 'publication_date', view: 'Reception date' },
            { field: 'arrival_date', view: 'Arrival date' },
            { field: 'creator', view: 'Ecosystem Manager' },
            { field: 'transformation_name', view: 'Transformation name' }
        ];
        this.sortValue = 'publication_date';
        // Filters
        this.TRANSFORM_ORDER_STATUSES = [
            {
                text: 'Confirmed',
                value: TRANSFORM_ORDER_STATUS.CONFIRMED
            },
            {
                text: 'Rejected',
                value: TRANSFORM_ORDER_STATUS.REJECTED
            },
            {
                text: 'Not achieved',
                value: TRANSFORM_ORDER_STATUS.PROBLEM_REPORTED
            }
        ];
        this.TRANSFORM_TYPES = TRANSFORM_TYPES;
        this.type = null;
        this.searchStatus = this.TRANSFORM_ORDER_STATUSES.map(function (status) { return status.value; });
        var options = {
            'tsm_view': 1,
            'is_archive': true
        };
        this._ordersService.getEsmList(options).subscribe(function (esmContacts) {
            _this.esmContacts = esmContacts;
        });
    }
    TsmOrdersArchiveCatalogComponent.prototype.toggleStatus = function (state) {
        var index = this.searchStatus.indexOf(state);
        if (index > -1) {
            this.searchStatus.splice(index, 1);
        }
        else {
            this.searchStatus.push(state);
        }
        console.info(this.searchStatus);
    };
    TsmOrdersArchiveCatalogComponent.prototype.addRecord = function (order) {
        var arrivalAt = order.in_order_passport.linked_datetime;
        var arrivalIsToday = this.datepipe.transform(arrivalAt, 'dd.MM.yyyy') === this.datepipe.transform(new Date(), 'dd.MM.yyyy');
        var departureAt = order.out_order_passport.linked_datetime;
        var transformationType = TRANSFORM_TYPES.find(function (TYPE) { return TYPE.value === order.transformation.transformation_type; }).view;
        arrivalAt = arrivalAt ? this.datepipe.transform(arrivalAt, 'dd.MM.yyyy') : '';
        departureAt = departureAt ? this.datepipe.transform(departureAt, 'dd.MM.yyyy') : '';
        var receptedAt = moment(order.publication_date || order.confirmation_date).format('DD.MM.YYYY');
        var date = this.datepipe.transform(order.confirmation_date, 'dd.MM.yyyy');
        var dateLabel = '';
        if (order.status === TRANSFORM_ORDER_STATUS.CONFIRMED) {
            dateLabel = 'Confirmed';
        }
        else if (order.status === TRANSFORM_ORDER_STATUS.REJECTED) {
            dateLabel = 'Rejected';
        }
        var status;
        switch (order.status) {
            case TRANSFORM_ORDER_STATUS.CONFIRMED:
                status = ECustomListRecordStatus.NO_STATUS;
                break;
            case TRANSFORM_ORDER_STATUS.REJECTED:
                status = ECustomListRecordStatus.CANCELED;
                break;
            default:
                status = ECustomListRecordStatus.NO_STATUS;
        }
        /*
        TODO date of reception of the order, date of rejection [if rejected]
    "
        */
        this.records.push(this.createOrderRecord(status, arrivalIsToday ? ECustomListHintClass.CONFIRM : ECustomListHintClass.NO_CLASS, arrivalIsToday ? 'Arrival is today' : '', transformationType, order.transformation.name, transformationType.charAt(0).toUpperCase() + transformationType.slice(1).toLowerCase(), order.ecosystem_name, receptedAt, arrivalAt, departureAt, date, dateLabel, order.creator, order));
    };
    TsmOrdersArchiveCatalogComponent.prototype.ngOnInit = function () {
        this.getRecords();
    };
    TsmOrdersArchiveCatalogComponent.prototype.onEndOfListTriggered = function () {
        if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount) {
            this.getRecords(this.currentPage + 1);
        }
    };
    TsmOrdersArchiveCatalogComponent.prototype.getRecords = function (forPage) {
        var _this = this;
        if (forPage === void 0) { forPage = 0; }
        if (this.searchStatus.length === 0) {
            this.records.length = 0;
        }
        else {
            var options = {
                'expand': 'transformation,in_order_passport,out_order_passport,creator,ecosystem_name',
                'supervisor': 1,
                'tsm_view': 1,
                'search': this.search || null,
                'esm': this.esmContact || null,
                'sort': this.sortValue || null,
                'status[]': this.searchStatus,
                'transformation_type': this.type,
                'page': forPage,
            };
            this.isNextPageLoading = true;
            this._ordersService
                .getWithPaginationData(options)
                .subscribe(function (res) {
                if (forPage === 0) {
                    _this.records = [];
                }
                var headers = res.headers;
                _this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
                _this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
                res.json().map(function (item) { return _this.addRecord(new TransformOrder(item)); });
                _this.isNextPageLoading = false;
            });
        }
    };
    TsmOrdersArchiveCatalogComponent.prototype.createOrderRecord = function (status, hintCssClass, hintText, icon, title, subtitle, esmName, receptedAt, arrivalAt, departureAt, date, dateLabel, author, entity) {
        var _this = this;
        var incomePassport = entity.in_order_passport;
        var outcomePassport = entity.out_order_passport;
        var income = [];
        if (incomePassport.materials.length > 0) {
            income.push({
                key: 'Input',
                value: incomePassport.materials.map(function (material) { return material.nomenclature.name; }).join(',')
            });
        }
        if (incomePassport.quantity !== null) {
            income.push({
                key: 'Quantity',
                value: incomePassport.quantity !== null
                    ? (incomePassport.quantity + ' ' + (incomePassport.unit_text !== null ? incomePassport.unit_text : ''))
                    : ''
            });
        }
        var outcome = [];
        if (outcomePassport.materials.length > 0) {
            outcome.push({
                key: 'Output',
                value: outcomePassport.materials.map(function (material) { return material.nomenclature.name; }).join(',')
            });
        }
        if (outcomePassport.quantity !== null) {
            outcome.push({
                key: 'Quantity',
                value: outcomePassport.quantity !== null
                    ? (outcomePassport.quantity + ' ' + (outcomePassport.unit_text !== null ? outcomePassport.unit_text : ''))
                    : ''
            });
        }
        return CustomListRecord
            .build()
            .setStatus(status)
            .setHint(hintText, hintCssClass)
            .addIconColumn(icon)
            .addTitleColumn(title, subtitle)
            .addTextColumn(esmName)
            .addListColumn(income)
            .addListColumn(outcome)
            .addListColumn([{
                key: 'Arrival', value: arrivalAt
            }, {
                key: 'Departure',
                value: departureAt,
                cssClass: 'important-value'
            }])
            .addDateColumn(date, dateLabel)
            .addDateColumn(receptedAt, 'Reception')
            .addAuthorColumn(author)
            .addControlsColumn([
            {
                fn: function (data) {
                    var dialogRef = _this.mdDialog.open(TsmOrderDetailsDialogComponent, {
                        data: {
                            id: entity.id,
                            readOnly: true,
                        },
                    });
                    dialogRef.afterClosed().subscribe(function (result) {
                        _this.getRecords();
                    });
                },
                name: 'See order'
            }
        ].concat((entity.status === TRANSFORM_ORDER_STATUS.CONFIRMED ? [{
                fn: function (data) {
                    _this.mdDialog.open(TransformationOrderTransactionConfirmDialogComponent, tslib_1.__assign({}, DIALOG_THEME.TRANSACTION_ORDER_CONFIRMATION, { data: {
                            id: entity.id,
                            disabled: true,
                        } }));
                },
                name: 'See confirmation'
            }] : []), (entity.status === TRANSFORM_ORDER_STATUS.PROBLEM_REPORTED ? [{
                fn: function (data) { return _this._ordersService
                    .view(entity.id, { expand: 'problem' }).subscribe(function (orderWithProblem) {
                    if (orderWithProblem.status === ECOSYSTEM_ORDER_STATUS.PROBLEM_REPORTED) {
                        _this.ecosystemProblemUtilsService.openOrderProblemDialog(orderWithProblem.problem.description || '');
                    }
                    else {
                        _this.notificationService.error('No result found', null, true);
                        _this.getRecords();
                    }
                }); },
                name: 'See order problem'
            }] : []), [
            {
                fn: function (data) { return console.log(data); },
                name: 'Contacts'
            }
        ]));
    };
    return TsmOrdersArchiveCatalogComponent;
}());
export { TsmOrdersArchiveCatalogComponent };
