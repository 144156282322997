import {Component} from '@angular/core';
import {MdDialogRef} from '@angular/material';
@Component({
  moduleId: module.id,
  selector: 'import-checked',
  templateUrl: 'import-checked.component.html',
  styleUrls: ['import-checked.component.scss'],
})
export class ImportCheckedComponent {
  constructor(public mdDialogRef: MdDialogRef<any>,) {
  }
}
