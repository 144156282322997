import { UploadedDocument } from '../../../shared/models/uploaders/document.class';
import { DepositLocationFileService } from '../services/deposit-location-file.service';

export class DepositLocationFile extends UploadedDocument {
  protected provider = DepositLocationFileService;

  public deposit_location_id: number;

  protected fields() {
    return super.fields().concat([
      'deposit_location_id'
    ]);
  }
}
