export enum ECOSYSTEM_STATISTICS_SEGMENTS {
  IN_PROCESS = 1,
  CANCELED = 2,
  CREATED = 3,
  DELETED = 4,
  MODIFIED = 5,
  DONE = 6
}

export const ECOSYSTEM_STATISTICS_SEGMENTS_VIEW = {
  1: 'In process',
  2: 'Canceled',
  3: 'Created',
  4: 'Deleted',
  5: 'Modified',
  6: 'Done'
};
