import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformFormat } from '../models/transform-format.class';
var TransformFormatService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformFormatService, _super);
    function TransformFormatService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-formats';
        _this.namespaceSingular = 'transformation-format';
        _this.ModelClass = TransformFormat;
        return _this;
    }
    return TransformFormatService;
}(CrudService));
export { TransformFormatService };
