import { Component, Input } from '@angular/core';
import { Transform } from '../../../../../entities/transformation/models/transform.class';
import { TransformCondition } from '../../../../../entities/transformation/models/transform-condition.class';
import { TransformConditionService } from '../../../../../entities/transformation/services/transform-condition.service';

@Component({
  moduleId: module.id,
  selector: 'transform-conditions',
  templateUrl: 'conditions.component.html',
  styleUrls: ['conditions.component.scss']
})
export class ConditionsComponent {
  @Input() transform: Transform;
  @Input() readOnly: boolean;
  inputText: string;

  constructor(private conditionService: TransformConditionService) {
  }

  addCondition() {
    if (this.inputText) {
      let condition = new TransformCondition();
      condition.description = this.inputText;
      condition.transformation_id = this.transform.id;
      condition.save().subscribe((res) => {
        condition.id = res.id;
        this.transform.conditions.push(condition);
      });

      this.inputText = '';
    }
  }

  removeCondition(condition: TransformCondition) {
    condition.destroy().subscribe((res) => {
      let index = this.transform.conditions.indexOf(condition);
      if (index !== -1) {
        this.transform.conditions.splice(index, 1);
      }
    });
  }
}
