import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ApiService } from '../../../../shared/services/api.service';
import { ICalculationVariables } from './calculation-variables.interface';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class CalculationVariablesService extends ApiService {
  protected namespace = 'calculation-variables';
  protected namespaceSingular = 'calculation-variables';
  protected ModelClass = '';

  public getCalculationVariables(): Observable<ICalculationVariables> {
    return this.sendGet(this.getEndpoint(this.namespaceSingular))
      .map((data: Response) => data.json())
      .catch(this.onError.bind(this));
  }

  public updateCalculationVariables(calculationVariables: ICalculationVariables): Observable<ICalculationVariables> {
    return this.sendPost(this.getEndpoint(this.namespaceSingular + '/edit-all-variables'), calculationVariables)
      .map((response: Response) => response.json())
      .catch(this.onError.bind(this));
  }
}
