import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../../entities/stats/services/stats.service';
import { NeedStatsContainer } from '../../../../entities/stats/models/need-stats-container.class';
import { DepositStatsContainer } from '../../../../entities/stats/models/deposit-stats-container.class';
import { ClientStatsContainer } from '../../../../entities/stats/models/client-stats-container.class';
import { TransformationStatsContainer } from '../../../../entities/stats/models/transformation-stats-container.class';
import {
  ADMIN_STATISTICS_SEGMENTS,
  ADMIN_STATISTICS_SEGMENTS_VIEW
} from '../../values/admin-statistics-segments.const';
import { DatePipe } from '@angular/common';

@Component({
  moduleId: module.id,
  templateUrl: 'admin-statistics.component.html'
})

export class AdminStatisticsComponent implements OnInit {

  ADMIN_STATISTICS_SEGMENTS = ADMIN_STATISTICS_SEGMENTS;
  ADMIN_STATISTICS_SEGMENTS_VIEW = ADMIN_STATISTICS_SEGMENTS_VIEW;
  segments: ADMIN_STATISTICS_SEGMENTS[] = [
    ADMIN_STATISTICS_SEGMENTS.DEPOSITS,
    ADMIN_STATISTICS_SEGMENTS.NEEDS,
    ADMIN_STATISTICS_SEGMENTS.CLIENTS,
    ADMIN_STATISTICS_SEGMENTS.TRANSFORMATIONS
  ];
  selectedSegment: number = ADMIN_STATISTICS_SEGMENTS.DEPOSITS;
  segmentStats: any = {};
  segmentsDateFrom: string;
  segmentsDateTo: string;

  constructor(private statsService: StatsService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.segmentsDateFrom = this.datePipe.transform(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd');
    this.segmentsDateTo = this.datePipe.transform(new Date, 'yyyy-MM-dd');
    this.getSegmentData();
  }

  changeSegment(segment: number) {
    this.selectedSegment = segment;
    this.getSegmentData();
  }

  getSegmentData() {
    let query: any = {
      group_by: 'month',
      from: this.segmentsDateFrom || null,
      to: this.segmentsDateTo || null
    };
    switch (this.selectedSegment) {
      case ADMIN_STATISTICS_SEGMENTS.DEPOSITS:
        this.getDepositsData(query);
        break;
      case ADMIN_STATISTICS_SEGMENTS.NEEDS:
        this.getNeedsData(query);
        break;
      case ADMIN_STATISTICS_SEGMENTS.CLIENTS:
        this.getClientsData(query);
        break;
      case ADMIN_STATISTICS_SEGMENTS.TRANSFORMATIONS:
        this.getTransformationsData(query);
        break;
      default:
        break;
    }
  }

  getDepositsData(query: any) {
    this.statsService.getDeposits(query).subscribe((depositStats: DepositStatsContainer) => {
      this.segmentStats = depositStats.getTotal();
      this.segmentStats['evolution'] = depositStats.getEvolution();
    });
  }

  getNeedsData(query: any) {
    this.statsService.getNeeds(query).subscribe((needStats: NeedStatsContainer) => {
      this.segmentStats = needStats.getTotal();
      this.segmentStats['evolution'] = needStats.getEvolution();
    });
  }

  getClientsData(query: any) {
    this.statsService.getClients(query).subscribe((clientStats: ClientStatsContainer) => {
      this.segmentStats = clientStats.getTotal();
      this.segmentStats['evolution'] = clientStats.getEvolution();
    });
  }

  getTransformationsData(query: any) {
    this.statsService.getTransformations(query).subscribe((transformationsStats: TransformationStatsContainer) => {
      this.segmentStats = transformationsStats.getTotal();
      this.segmentStats['evolution'] = transformationsStats.getEvolution();
    });
  }
}
