import { EventEmitter } from '@angular/core';
import { IZoneCopyingData, IZoneCopyingDataContainer, IZoneDeletionData, IZoneDeletionDataContainer } from '../../interfaces/zone-deepstream-message.interface';
import { EDeepStreamMessage } from '../../values/deepstream-message-type.enum';
import { BaseDeepStreamService } from './base-deepstream.service';

export class ZoneDeepStream extends BaseDeepStreamService {

    private static _copyingStream$: EventEmitter<IZoneCopyingDataContainer> = new EventEmitter<IZoneCopyingDataContainer>();
    private static _deletionStream$: EventEmitter<IZoneDeletionDataContainer> = new EventEmitter<IZoneDeletionDataContainer>();

    protected messageTypes: EDeepStreamMessage[] = [
        EDeepStreamMessage.ZONE_COPIED,
        EDeepStreamMessage.ZONE_DELETED
    ];

    public receiver: string

    public emit(data: IZoneCopyingData | IZoneDeletionData) {
        switch (data.type) {
            case EDeepStreamMessage.ZONE_COPIED:
                return this.copyingStream$.emit({ target: this.receiver, data } as { target: string, data: IZoneCopyingData });
            case EDeepStreamMessage.ZONE_DELETED:
                return this.deletionStream$.emit({ target: this.receiver, data } as { target: string, data: IZoneDeletionData });
        }
    }

    public get copyingStream$(): EventEmitter<IZoneCopyingDataContainer> {
        return ZoneDeepStream._copyingStream$;
    }
    public get deletionStream$(): EventEmitter<IZoneDeletionDataContainer> {
        return ZoneDeepStream._deletionStream$;
    }

}