import { Component, OnInit } from '@angular/core';
import {
  SUPERVISOR_STATISTICS_SEGMENTS,
  SUPERVISOR_STATISTICS_SEGMENTS_VIEW
} from '../../values/supervisor-statistics-segments.const';
import { StatsService } from '../../../../entities/stats/services/stats.service';
import { SupervisorStatsContainer } from '../../../../entities/stats/models/supervisor-stats-container.class';

@Component({
  moduleId: module.id,
  templateUrl: 'supervisor-statistics.component.html'
})
export class SupervisorStatisticsComponent implements OnInit {
  SUPERVISOR_STATISTICS_SEGMENTS = SUPERVISOR_STATISTICS_SEGMENTS;
  SUPERVISOR_STATISTICS_SEGMENTS_VIEW = SUPERVISOR_STATISTICS_SEGMENTS_VIEW;
  segments: SUPERVISOR_STATISTICS_SEGMENTS[] = [
    SUPERVISOR_STATISTICS_SEGMENTS.DEPOSITS,
    SUPERVISOR_STATISTICS_SEGMENTS.NEEDS,
    SUPERVISOR_STATISTICS_SEGMENTS.TRANSFORMATIONS
  ];
  selectedSegment: number = SUPERVISOR_STATISTICS_SEGMENTS.DEPOSITS;
  segmentStats: any = {};

  constructor(private statsService: StatsService) {
  }

  ngOnInit() {
    this.getSegmentData();
  }

  changeSegment(segment: number) {
    this.selectedSegment = segment;
    this.getSegmentData();
  }

  getSegmentData() {
    switch (this.selectedSegment) {
      case SUPERVISOR_STATISTICS_SEGMENTS.DEPOSITS:
        this.getDepositsData();
        break;
      case SUPERVISOR_STATISTICS_SEGMENTS.NEEDS:
        this.getNeedsData();
        break;
      case SUPERVISOR_STATISTICS_SEGMENTS.TRANSFORMATIONS:
        this.getTransformationsData();
        break;
      default:
        break;
    }
  }

  getDepositsData() {
    this.statsService.getSupervisorStats().subscribe((supervisorStats: SupervisorStatsContainer) => {
      this.segmentStats = supervisorStats.getDepositsTotals();
    });
  }

  getNeedsData() {
    this.statsService.getSupervisorStats().subscribe((supervisorStats: SupervisorStatsContainer) => {
      this.segmentStats = supervisorStats.getNeedsTotals();
    });
  }

  getTransformationsData() {
    this.statsService.getSupervisorStats().subscribe((supervisorStats: SupervisorStatsContainer) => {
      this.segmentStats = supervisorStats.getTransformationsTotals();
    });
  }
}
