<deposit-import-column-custom
  [className]="className"
  [description]="description"
  [additionalDescription]="additionalDescription"
  [showWarningSign]="showWarningSign"
>
    <deposit-import-column
      column
      [label]="label"
      [formControlName]="controlName"
      [required]="required"
      [isWideClass]="custom"
      [columnName]="columnName"
      (onKeyDown)="onKeyDown.emit($event)"
      (onBlur)="onBlurColumn.emit(controlName)"
    ></deposit-import-column>
  <ng-content description></ng-content>
</deposit-import-column-custom>

