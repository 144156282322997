import * as tslib_1 from "tslib";
import { OnInit, Renderer2 } from '@angular/core';
import { PIE_CHART_SIZE } from '../../../../../values/pie-chart-size.const';
import { ReportBase } from '../../report-base/report-base.class';
var CircularityProductCategoryBlockComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CircularityProductCategoryBlockComponent, _super);
    function CircularityProductCategoryBlockComponent(_render) {
        var _this = _super.call(this) || this;
        _this._render = _render;
        _this.pieChartSize = PIE_CHART_SIZE;
        return _this;
    }
    CircularityProductCategoryBlockComponent.prototype.ngOnInit = function () {
        this.incomingDataProcessing();
    };
    CircularityProductCategoryBlockComponent.prototype.incomingDataProcessing = function () {
        var _a;
        for (var item in this.data) {
            if (typeof this.data[item] === 'number') {
                this.data = tslib_1.__assign({}, this.data, (_a = {}, _a[item] = this.data[item], _a));
            }
        }
    };
    return CircularityProductCategoryBlockComponent;
}(ReportBase));
export { CircularityProductCategoryBlockComponent };
