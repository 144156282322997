import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import {EConfirmDialogIcon} from '../../../../shared/confirm-dialog/values/enums/confirm-dialog-icon.enum';
import {IConfirmDialogData, IConfirmDialogIconData} from '../../../../shared/confirm-dialog/values/interface/confirm-dialog-data.interface';

@Component({
  moduleId: module.id,
  templateUrl: 'deposit-import-errors.component.html',
  styleUrls: ['deposit-import-errors.component.scss'],
})
export class DepositImportErrorsComponent {

  iconData: IConfirmDialogIconData = {
    iconType: EConfirmDialogIcon.SVG,
    showIconURL: 'assets/svg/warning--icon.svg',
    iconClass: 'deposit-import-errors__image-triangle'
  };

  constructor(public mdDialogRef: MdDialogRef<any>,
    @Inject(MD_DIALOG_DATA) public mdDialogData: any) {
  }

  selectText(selector: string) {
    const element = document.querySelector(selector);
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  onSelectAll(e: KeyboardEvent) {
    const controlKeyDown = (e.ctrlKey || e.metaKey);
    const characterADown = (e.code === 'KeyA');

    if (controlKeyDown && characterADown) {
      e.preventDefault();

      this.selectText('#table-errors');
    }
  }

  get imgSrc() {
    return `assets/png/warning_${this.mdDialogData.color}.png`;
  }
}
