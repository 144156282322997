import { Component, OnInit } from '@angular/core';
import { ICalculationVariables } from './calculation-variables.interface';
import { CalculationVariablesService } from './calculation-variables.service';
import {
  DEMOUNTABILITY_GRADE_VARIABLES,
  FUTURE_LIFE_GRADE_VARIABLES,
  POTENTIAL_GRADE_VARIABLES,
  RATING_GRADE_VARIABLES,
  RENEWAL_RATES_VARIABLES,
  RENEWAL_RATES_VARIABLE_ITEMS,
  RESIDUAL_VALUE_VARIABLES
} from './calculation-variables.const';
import {SpinnerDialogComponent} from '../../../../shared/spinner/dialogs/spinner-dialog/spinner-dialog.component';
import {MdDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/Subject';

@Component({
  moduleId: module.id,
  selector: 'calculation-variables',
  templateUrl: 'calculation-variables.component.html',
  styleUrls: ['calculation-variables.component.scss'],
})
export class CalculationVariablesComponent implements OnInit {
  private _spinnerDialogRef;
  public calculationVariables: ICalculationVariables;
  public demountabilityGradeNames = DEMOUNTABILITY_GRADE_VARIABLES;
  public futureLifeGradeNames = FUTURE_LIFE_GRADE_VARIABLES;
  public potentialGradeNames = POTENTIAL_GRADE_VARIABLES;
  public ratingGradeNames = RATING_GRADE_VARIABLES;
  public renewalRatesNames = RENEWAL_RATES_VARIABLES;
  public renewalRatesNamesItems = RENEWAL_RATES_VARIABLE_ITEMS;
  public residualValueNames = RESIDUAL_VALUE_VARIABLES;
  public inputObservable = new Subject<ICalculationVariables>();

  constructor(private _calculationVariablesService: CalculationVariablesService,
              private _dialog: MdDialog,
              private _translate: TranslateService) {
  }

  ngOnInit(): void {
    this.inputObservable.debounceTime(1000).subscribe(() => this.save());

    this._calculationVariablesService.getCalculationVariables().subscribe((response: ICalculationVariables) => {
      this.calculationVariables = response;
    });
  }

  changeInputValue() {
    this.inputObservable.next();
  }
  save(): void {
    this._spinnerDialogRef = this._dialog.open(SpinnerDialogComponent, {
      disableClose: true,
      data: {
        caption: this._translate.instant('Saving calculation variables'), isLoadingDotsShown: true
      }
    });
    this._calculationVariablesService.updateCalculationVariables(this.calculationVariables).subscribe(
      (response: ICalculationVariables) => {
        this.calculationVariables = response;
        this._spinnerDialogRef.close();
      },
      () => {
        this._spinnerDialogRef.close();
      }
    );
  }
}
