/**
 * Created by Aleksandr C. on 6.02.17.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientLocation } from '../../model/location.class';
import { LocationService } from '../../service/location.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'client-location-form',
  templateUrl: 'location-form.component.html',
  styleUrls: ['location-form.component.scss'],
})

export class LocationFormComponent implements OnInit {
  @Input() public clientId?: number;
  @Input() public asDialog?: boolean = false;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Input() location;
  locationForm: FormGroup;
  //location: ClientLocation;
  created: boolean;

  constructor(private _fb: FormBuilder,
              private locationService: LocationService) {
  }

  ngOnInit() {
    this.created = false;
    
    //this.location.name = 'New ClientLocation';
    //this.location.client_id = this.clientId;
    
    this.locationForm = this._fb.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      address: [''],
      description: ['']
    });

  }

  onPlaceChange(place: google.maps.places.PlaceResult) {
    if (place.geometry) {
      this.location.lat = place.geometry.location.lat();
      this.location.lng = place.geometry.location.lng();
    } else {
      this.location.lat = null;
      this.location.lng = null;
    }
  }

  save(locationForm: FormGroup): boolean {
    if (this.created === true) {
      this.locationService.update(this.location.id, this.location).subscribe(
        result => {
          this.location = result;
          this.locationForm.markAsPristine();
        }
      );
    } else {
      this.locationService.create(this.location).subscribe(
        result => {
          this.location = result;
          this.locationForm.markAsPristine();
          this.closeDialog();
        },
        err => console.log(err)
      );
    }
    return false;
  }

  closeDialog() {
    this.onSave.emit(this.location);
  }
}
