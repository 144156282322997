import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { EDeepStreamMessage } from '../../values/deepstream-message-type.enum';
import { BaseDeepStreamService } from './base-deepstream.service';
var ReportPdfDeepStream = /** @class */ (function (_super) {
    tslib_1.__extends(ReportPdfDeepStream, _super);
    function ReportPdfDeepStream() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.messageTypes = [
            EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_ERRORS,
            EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_PROGRESS,
            EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_SUCCESS
        ];
        return _this;
    }
    ReportPdfDeepStream.prototype.emit = function (data) {
        switch (data.type) {
            case EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_ERRORS:
                return ReportPdfDeepStream._reportPdfErrorsStream$.emit(data);
            case EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_PROGRESS:
                return ReportPdfDeepStream._reportPdfProgressStream$.emit(data);
            case EDeepStreamMessage.DEEPSTREAM_EVENT_REPORT_SUCCESS:
                return ReportPdfDeepStream._reportPdfSuccessStream$
                    .emit(data);
        }
    };
    Object.defineProperty(ReportPdfDeepStream.prototype, "reportPdfErrorsStream$", {
        get: function () {
            return ReportPdfDeepStream._reportPdfErrorsStream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportPdfDeepStream.prototype, "reportPdfSuccessStream$", {
        get: function () {
            return ReportPdfDeepStream._reportPdfSuccessStream$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportPdfDeepStream.prototype, "reportPdfProgressStream$", {
        get: function () {
            return ReportPdfDeepStream._reportPdfProgressStream$;
        },
        enumerable: true,
        configurable: true
    });
    ReportPdfDeepStream._reportPdfErrorsStream$ = new EventEmitter();
    ReportPdfDeepStream._reportPdfSuccessStream$ = new EventEmitter();
    ReportPdfDeepStream._reportPdfProgressStream$ = new EventEmitter();
    return ReportPdfDeepStream;
}(BaseDeepStreamService));
export { ReportPdfDeepStream };
