<form
  *ngIf="passport"
  [formGroup]="passportForm"
  class="passport view-{{viewMode}}"
  [ngClass]="{'form-controls-disabled': areFormControlsDisabled}"
>
  <section>
    <div *ngIf="supervisorWarning" class="">
      {{ 'Warning!' | translate }}
      {{ 'Be careful when editing published passports' | translate }}.
      {{ 'Changes will carry over to all needs and deposits' | translate }}.
    </div>

    <ng-content></ng-content>

    <!--    General tab-->
    <div cdk-scrollable class="passport__tab-wrapper">
      <div>
        <div [hidden]="selectedTab !== PASSPORT_TAB.GENERAL">
          <div class="passport__tab flex justify-between">
            <section class="passport-edit__name-field flex-column">
              <label class="passport__tab--label">1. {{ 'General information' | translate }}</label>

              <div class="passport__name-info-wrapper">
                <passport-catalog-name *ngIf="passport.getProductName()"
                         [passport]="passport"
                         [showCopyId]="passport.hasProductPassportId()"
                         [multiLanguage]="filterState.multilanguage"
                         class="form-group__content--row passport-edit__header--name-container">
                </passport-catalog-name>
                <div [ngClass]="{'form-group__content--row passport-edit__item-field': viewMode == VIEW_MODE.DEFAULT}">

                  <!-- composite name doesnt exist-->
                  <div>

                    <div class="form-group__field passport__name-field"
                         (click)="onOpenGenerationNameDialog()"
                         *ngIf="!passport.getProductName()"
                    >
                      <custom-search
                        *ngIf="enableSearch"
                        [preset]="passport"
                        [service]="passportService"
                        [listener]="onChange"
                        (onSelect)="onSelect($event)"
                        (onCreate)="onCreate($event)"
                        (onChangeName)="onChangeName($event)"
                        [CustomName]="getPassportCustomName"
                        label="{{ 'Create New Passport' | translate }}"
                        [required]="true"
                        [disabled]="this.disabled"
                        [class.highlighted]="controlStates?.name?.highlighted"
                      ></custom-search>

                      <md-input-container *ngIf="!enableSearch && allowNameChange">
                        <input
                          mdInput
                          autocomplete="off"
                          [value]="passport.getProductName()"
                          placeholder="{{ 'Passport name' | translate }} *"
                          required="true"
                          [readonly]="!allowNameChange"
                          (change)="onCreate($event.target.value)"
                          [class.highlighted]="controlStates?.name?.highlighted"
                          [disabled]="this.disabled"
                        >
                      </md-input-container>

                      <md-input-container *ngIf="!enableSearch && !allowNameChange">
                        <input
                          mdInput
                          autocomplete="off"
                          [value]="passport.getProductName()"
                          (change)="onNameEdit($event.target.value)"
                          placeholder="{{ 'Passport name' | translate }}"
                          [class.highlighted]="controlStates?.name?.highlighted"
                          [disabled]="this.disabled"
                        >
                      </md-input-container>
                    </div>
                  </div>

                  <!-- when composite name exist-->
                  <div *ngIf="passport.getProductName()" class="flex-column">

                    <div class="passport-edit__header--input-group" >
                      <md-input-container (click)="onOpenGenerationNameDialog()" class="form-group__field passport-edit__header--input-disabled">
                        <input
                          mdInput
                          autocomplete="off"
                          [value]="passport.name"
                          placeholder="{{ 'Generic name' | translate }}{{setAsterisk()}}"
                          [appReadonlyWithoutFocus]="true"
                        >
                      </md-input-container>
                      <md-input-container  (click)="onOpenGenerationNameDialog()" class="form-group__field passport-edit__header--input-disabled">
                        <input
                          mdInput
                          autocomplete="off"
                          [value]="passport.english_name"
                          placeholder="{{ 'English version of the generic name' | translate }}{{setAsterisk()}}"
                          [appReadonlyWithoutFocus]="true"

                        >
                      </md-input-container>
                      <md-input-container (click)="onOpenGenerationNameDialog()" class="form-group__field passport-edit__header--input-disabled">
                        <input
                          mdInput
                          autocomplete="off"
                          [value]="passport.isCatalogueProduct() ? passport.manufacturer || '' : passport.typology || ''"
                          placeholder="{{ (passport.isCatalogueProduct() ? 'Name of the manufacturer' : 'Typology' ) | translate }}"
                          [appReadonlyWithoutFocus]="true"

                        >
                      </md-input-container>
                      <md-input-container (click)="onOpenGenerationNameDialog()" class="form-group__field passport-edit__header--input-disabled">
                        <input
                          mdInput
                          autocomplete="off"
                          [value]="passport.isCatalogueProduct() ? passport.trade_name || '' : passport.format || ''"
                          placeholder="{{ ( passport.isCatalogueProduct() ? 'Trade name of the product' : 'Format/ Dimensions' ) | translate }}"
                          [appReadonlyWithoutFocus]="true"

                        >
                      </md-input-container>
                      <md-input-container (click)="onOpenGenerationNameDialog()" class="form-group__field passport-edit__header--input-disabled">
                        <input
                          mdInput
                          autocomplete="off"
                          [value]="passport.isCatalogueProduct() ? passport.product_refrence || '' : passport.additional || ''"
                          placeholder="{{ ( passport.isCatalogueProduct() ? 'Product reference (if different from the trade name)' : 'Additional information' ) | translate }}"
                          [appReadonlyWithoutFocus]="true"
                          mdTooltip="{{ passport.isCatalogueProduct() ? passport.product_refrence || '' : passport.additional || '' }}"
                          [mdTooltipPosition]="'right'"
                        >
                      </md-input-container>
                      <md-input-container (click)="onOpenGenerationNameDialog($event)" class="form-group__field passport-edit__header--input-disabled">
                        <input style="pointer-events: none"
                          mdInput
                          autocomplete="off"
                          [value]="passport.product_passport_id ? passport.product_passport_id : ''"
                          placeholder="{{ 'Circular Passport ID' | translate }}"
                          [appReadonlyWithoutFocus]="true"
                        >
                      </md-input-container>


                      <div style="margin-top:5%;width: 306px;" class="form-group__field passport-edit__header--input-disabled">
                        <ul class="button-group__list">
                          <li class="button-group__item name-generation-dialog__button">
                            <div class="name-generation-dialog__field" (click)="onOpenGenerationNameDialog()">
                              <md-input-container>
                                <input mdInput autocomplete="off" [value]="passport.version || ''"
                                  placeholder="{{ 'Product version' | translate }}" [appReadonlyWithoutFocus]="true">
                              </md-input-container>
                            </div>
                          </li>
                          <li class="button-group__item name-generation-dialog__button" *ngIf="isNewVersionCreationAllowed()">
                            <button md-button md-raised-button (click)="createAnotherVersion()">{{ '+ New version' | translate }}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="passport-edit__category-block">
                  <div class="edit-passport-edit__item-field"
                       [ngClass]="{'form-group__content--row passport-edit__item-field': viewMode == VIEW_MODE.DEFAULT}"
                       (click)="onControlClick($event);">
                    <div class="form-group__field passport__manufacturer-field" (click)="onControlClick($event);">

                        <md-input-container (click)="openCategoryeDialog($event)">
                          <input
                            [readonly]="disabled"
                            mdInput
                            autocomplete="off"
                            formControlName="category"
                            placeholder="{{ 'Product category' | translate }} *"
                          >
                        </md-input-container>

                    </div>
                    <label class="form-group__label"
                           *ngIf="viewMode == VIEW_MODE.DEFAULT"
                           mdTooltip="{{ 'PASSPORT_CATEGORY_TOOLTIP' | translate }}"
                    >
                      <i class="form-group__label--question">?</i>
                    </label>
                  </div>

                  <passport-multiple-field-input
                    *ngIf="passport.isGtinVisible"
                    [records]="passport.gtins"
                    [recordNameField]="'code'"
                    [placeholder]="'GTIN'"
                    [disabled]="disabled"
                    [addRecordObservable]="gtinBuffer.emitSourceGtin"
                    (onDeleteSuccess)="gtinBuffer.emitSourceDelete($event)"
                    [isOnAddErrorErrorMessageShown]="true"
                    [errorMessage]="'Invalid GTIN format: 8 to 14-digit string requested' | translate"
                  >

                  </passport-multiple-field-input>

                  <div class="passport-edit__item-unit"
                       [ngClass]="{'form-group__content--row': viewMode == VIEW_MODE.DEFAULT}">
                    <div class="form-group__field input-small" (click)="onControlClick($event);">
                      <md-select
                        formControlName="passportUnit"
                        placeholder="{{ ('Circular passport unit' | translate)  + '*'}}"
                        [disabled]="disabled"
                        (change) = "unitChange()"
                        [class.highlighted]="controlStates?.unit?.highlighted"
                      >
                        <md-option
                          *ngFor="let unit of units"
                          [value]="unit.id"
                        >{{unit.title | translate}}
                        </md-option>
                      </md-select>
                    </div>
                    <div class="flex flex-column" *ngIf="isPassportConversionShown()">
                      <div class="flex passport-edit__info--group-conversion"
                           [ngClass]="getLabelClasses(passport.conversion)"
                      >
                        {{'Conversion' | translate}}{{setAsterisk()}}
                      </div>
                      <passport-conversion
                        [disabled]="isLocked()"
                        [unitView]="passport.unit_text"
                        formControlName="conversion"
                        [(ngModel)]="passport.conversion"
                        [showValidationError]="isConversionInvalid.bind(this)"
                      >
                      </passport-conversion>
                  </div>
                </div>


                  <div class="flex flex-column">
                    <div class="flex passport-edit__info--group-rating"
                         [ngClass]="getLabelClasses(passport.rating)"
                    >
                      {{'Product rating' | translate}}
                    </div>
                  </div>
                  <passport-rating-display
                    [passport]="passport">
                  </passport-rating-display>
                </div>
              </div>
            </section>

            <div class="passport-edit__main-photo">
              <label class="passport-edit__main-photo--label">{{ 'Main photo' | translate }}</label>
              <logo-uploader
                class="passport-edit__main-photo--uploader"
                [isDisabled]="areFormControlsDisabled || disabled"
                [preview]="passport.image"
                [targetUrl]="passport?.id && !areFormControlsDisabled ? getLogoUploaderUrl() : null"
                (onLoad)="onLogoUpload($event)"
                (onRemove)="removePassportPreview()"
                [class.highlighted]="controlStates?.logo?.highlighted"
                [uploadUrlMissingErrorText]="!isLocked() ? uploadErrorText: ''"
              ></logo-uploader>
            </div>
          </div>
        </div>

        <!--    Composition tab-->
        <div class="passport__tab" [hidden]="selectedTab !== PASSPORT_TAB.COMPOSITION">

          <section class="passport__components-materials-field"
                   [ngClass]="{'form-group__content--row': viewMode != VIEW_MODE.DEFAULT}">
            <div class="flex align-center">
              <label>
                <label class="passport__tab--label" *ngIf="viewMode == VIEW_MODE.DEFAULT"
                >2. {{ 'Composition' | translate }}
                </label>&nbsp;
                <span
                  class="passport-edit__composition--info-label-explanation">{{ 'PASSPORT_COMPONENT_EXPLANATION' | translate }}</span>
              </label>
              <label class="form-group__label"
                     *ngIf="viewMode == VIEW_MODE.DEFAULT"
                     mdTooltip="{{ 'PASSPORT_COMPONENTS_INFO_TOOLTIP_TOP' | translate}}{{'\n\n'}}{{'PASSPORT_COMPONENTS_INFO_TOOLTIP_BOT' | translate}}">
                <i class="form-group__label--question passport-edit__info--preview-tooltip">?</i>
              </label>
            </div>

            <div [ngClass]="{'form-group__content--row': viewMode == VIEW_MODE.DEFAULT}">
              <div
                class="form-group__field passport__components-field"
                (click)="onControlClick($event);"
              >
                <passport-components
                  #passportComponents
                  (onSave)="onUpdatePassportData($event)"
                  [listener]="eventStream$"
                  [passport]="passport"
                  [isDisabled]="areFormControlsDisabled || disabled"
                  [viewMode]="viewMode"
                  [parentFormGroup]="passportForm"
                  [class.highlighted]="controlStates?.components?.highlighted"
                  [isComponentNameMandatory]="true"
                ></passport-components>
              </div>
            </div>
          </section>

          <div [hidden]="!isVisible('noBannedMaterials')">
            <div class="flex align-center">
              <div class="passport-edit__composition--button">
                <button md-button md-raised-button
                type="button"
                        (click)="openBannedComponentsList($event)"
                >
                  <div
                    class="passport-edit__composition--button-text">{{'List of banned C2C components' | translate}}</div>
                </button>
              </div>
              <div class="passport-edit__composition--agreement-block">
                <div class="passport-edit__composition--agreement">
                  {{ 'C2C_CERTIFY' | translate }}
                </div>
                <md-radio-group
                  class="passport-edit__composition--radio-buttons"
                  formControlName="noBannedMaterials"
                  [disabled]="disabled || areFormControlsDisabled"
                >
                  <md-radio-button
                    *ngFor="let radio of compositionRadios"
                    (click)="handleC2cRadioClick(radio.key)"
                    [value]="radio.key"
                    class="passport-edit__composition--radio-button"
                  >
                    {{radio.value | translate}}
                  </md-radio-button>
                </md-radio-group>
              </div>

            </div>

          </div>

        </div>

        <!--    Uses and cycle tab-->
        <div class="passport__tab" [hidden]="selectedTab !== PASSPORT_TAB.CYCLE">
          <label class="passport__tab--label" *ngIf="viewMode == VIEW_MODE.DEFAULT"
          >3. {{ 'Use(s) and cycle' | translate }}
          </label>
          <div class="passport-edit__cycle--caption">
            {{ 'First life' | translate }}
          </div>
          <section>

            <div>
              <div (click)="onControlClick($event);">

                <div>
                  <div class="passport-edit__cycle--life-wrapper"
                       [hidden]="!isVisible(['recyclePercentage', 'firstLife'])">
                    <div class="passport-edit__cycle--life-recycle-wrapper">

                      <div class="flex-column passport-edit__cycle--life-recycle-row">
                        <div class="flex align-center">
                          <div class="passport-edit__cycle--life-recycle-label"
                               [ngClass]="getLabelClasses(passport.recycle_percentage)"
                          >{{'Share of recycled or rapidly renewable materials' | translate}}</div>
                          <label class="form-group__label"
                                 mdTooltip="{{ 'PASSPORT_PART_OF_RECYCLED_TOOLTIP' | translate }}"
                          ><i class="form-group__label--question">?</i></label>
                        </div>
                        <div class="passport-edit__cycle--life-recycle-input-container">
                          <md-input-container class="passport-edit__cycle--life-recycle-input passport-edit__input"
                                              (click)="onControlClick($event);">
                            <input
                              mdInput
                              type="number"
                              autocomplete="off"
                              formControlName="recyclePercentage"
                              min="0"
                              max="100"
                            >
                            <md-hint
                              *ngIf="isFieldInvalid('recyclePercentage')"
                              class="error-hint"
                              align="start"
                            >{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>

                          </md-input-container>
                          <div class="passport-edit__cycle--life-recycle-symbol">%</div>
                        </div>
                      </div>

                      <div class="flex passport-edit__cycle--description-row">
                        <div class="passport-edit__cycle--description"
                             (click)="onControlClick($event);">
                          <md-input-container class="textarea-field">
                          <textarea
                            name="description"
                            maxlength="2000"
                            formControlName="firstLife"
                            mdInput
                            placeholder="{{ 'Description' | translate }}"
                          ></textarea>
                          </md-input-container>
                        </div>
                      </div>

                    </div>

                  </div>

                  <div class="passport-edit__cycle--life-recycle-wrapper flex-column">
                    <div class="passport-edit__cycle--life-label"
                         [ngClass]="getLabelClasses(passport.period)">{{'Period of use' | translate}}</div>

                    <div class="passport-edit__cycle--life-inputs-container">
                      <div class="passport-edit__cycle--life-quantity" (click)="onControlClick($event);">
                        <md-input-container class="passport-edit__cycle--life-quantity-input passport-edit__input">
                          <input
                            mdInput
                            autocomplete="off"
                            type="number"
                            formControlName="quantity"
                            min="0">
                            <md-hint
                              *ngIf="isFieldInvalid('quantity')"
                              class="error-hint"
                              align="start"
                            >{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>
                        </md-input-container>
                      </div>
                      <div class="form-group__field passport-edit__cycle--life-period">
                        <md-select
                          formControlName="period"
                          placeholder="{{ 'Period' | translate }}"
                        >
                          <md-option
                            *ngFor="let periodType of period_types"
                            [value]="periodType.value"
                          >{{ periodType.view | translate }}
                          </md-option>
                        </md-select>
                      </div>

                      <label class="form-group__label"
                             *ngIf="viewMode == VIEW_MODE.DEFAULT"
                             mdTooltip="{{ 'PASSPORT_PERIOD_OF_USE_TOOLTIP' | translate }}"
                      ><i class="form-group__label--question">?</i></label>
                    </div>
                  </div>
                </div>

                <div class="passport-edit__cycle--next-wrapper">
                  <div class="passport-edit__cycle--caption">
                    {{ 'Next lives' | translate }}{{setAsterisk()}}
                    <label class="form-group__label"
                           *ngIf="viewMode == VIEW_MODE.DEFAULT"
                           mdTooltip="{{ 'PASSPORT_NEXT_USAGES_TOOLTIP' | translate }}"
                    ><i class="form-group__label--question">?</i>
                    </label>
                  </div>

                  <div class="passport-edit__cycle--next-usage">
                    <div [ngClass]="{'form-group__content--row': viewMode == VIEW_MODE.DEFAULT}">
                      <div class="form-group__field passport__materials-field"
                           (click)="onControlClick($event);"
                      >
                        <passport-next-usage
                          #passportNextUsages
                          (onSave)="onUpdatePassportData($event)"
                          [listener]="eventStream$"
                          [passport]="passport"
                          [isDisabled]="areFormControlsDisabled"
                          [viewMode]="viewMode"
                          [parentFormGroup]="passportForm"
                        ></passport-next-usage>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </section>

        </div>

        <!--    Performance and environmental tab-->
        <div class="passport__tab" [hidden]="selectedTab !== PASSPORT_TAB.PERFORMANCE">
          <passport-social-performance
            [passport]="passport"
            [isLongVersionActive]="isLongVersionActive"
            [asterisk]="setAsterisk()"
            [listener]="eventStream$"
            [areFormControlsDisabled]="areFormControlsDisabled || disabled"
            [disabled]="disabled"
            [viewMode]="viewMode"
            [parentFormGroup]="passportForm"
            (onUpdatePassportData)="onUpdatePassportData($event)"
            (onElementWhichNotifiesClick)="onControlClick($event)">
          </passport-social-performance>

        </div>

        <div class="passport__tab" [hidden]="selectedTab !== PASSPORT_TAB.INFO">
          <label class="passport__tab--label">5. {{ 'Further information' | translate }}</label>

          <div>
            <div class="passport-edit__info--group"
                 [hidden]="!isVisible(
                 ['appearance', 'dimensionLength', 'dimensionWidth', 'dimensionHeight', 'colors'])">
              <div class="flex align-center">
                <span class="passport-edit__info--group-label">{{'Appearances' | translate}}</span>
              </div>

              <div class="flex-column passport-edit__info--group-colors">

                <passport-colors
                  #passportColors
                  (onSave)="onUpdatePassportData($event)"
                  [listener]="eventStream$"
                  [passport]="passport"
                  [isDisabled]="areFormControlsDisabled || disabled"
                  [viewMode]="viewMode"
                  [parentFormGroup]="passportForm"
                ></passport-colors>

                <div class="passport-used-energy__item--description flex">
                  <md-input-container>
                  <textarea
                    formControlName="appearance"
                    mdInput
                    maxlength="2000"
                    placeholder="{{ 'Description of the appearance' | translate }}"
                  ></textarea>
                  </md-input-container>
                </div>
              </div>

              <div class="passport-edit__info--group">
                <div class="flex-column">
                  <div class="passport-edit__info--group-label">{{'Dimensions and measurements' | translate}}</div>
                  <div class="flex passport-edit__info--group-dimensions">
                    <div class="flex passport-edit__info--dimensions-item">
                      <md-input-container class="control-group__item--small  passport-edit__input">
                        <input mdInput
                               autocomplete="off"
                               placeholder="{{ 'Length' | translate }}"
                               formControlName="dimensionLength"
                               type="number"
                               min="0"
                        >
                        <md-hint
                          *ngIf="isFieldInvalid('dimensionLength')"
                          class="error-hint"
                          align="start"
                        >{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>
                      </md-input-container>
                      <div class="passport-edit__info--dimensions-size">
                        mm X
                      </div>
                    </div>
                    <div class="flex passport-edit__info--dimensions-item">
                      <md-input-container class="control-group__item--small  passport-edit__input">
                        <input mdInput
                               autocomplete="off"
                               placeholder="{{ 'Width' | translate }}"
                               formControlName="dimensionWidth"
                               type="number"
                               min="0"
                        >
                        <md-hint
                          *ngIf="isFieldInvalid('dimensionWidth')"
                          class="error-hint"
                          align="start"
                        >{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>
                      </md-input-container>
                      <div class="passport-edit__info--dimensions-size">
                        mm X
                      </div>
                    </div>
                    <div class="flex passport-edit__info--dimensions-item">
                      <md-input-container class="control-group__item--small  passport-edit__input">
                        <input mdInput
                               autocomplete="off"
                               placeholder="{{ 'Height' | translate }}"
                               formControlName="dimensionHeight"
                               type="number"
                               min="0">
                        <md-hint
                          *ngIf="isFieldInvalid('dimensionHeight')"
                          class="error-hint"
                          align="start"
                        >{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>
                      </md-input-container>
                      <div class="passport-edit__info--dimensions-size">
                        mm
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div class="passport-edit__info--group">
                <div class="flex align-center">
                  <span class="passport-edit__info--group-label">{{'Purchase price' | translate}}</span>
                </div>
                <div class="flex passport-edit__info--group-price-label"
                     [ngClass]="getLabelClasses(passport.purchase_price)"
                >
                  {{'List price' | translate}}
                </div>
                <div class="flex passport-edit__info--group-price align-center">
                  <div class="flex passport-edit__info--group-price-from">
                    {{'from' | translate}}
                  </div>
                  <div class="flex passport-edit__info-price-item align-center">
                    <md-input-container class="control-group__item--xsmall  passport-edit__input">
                      <input mdInput
                             autocomplete="off"
                             type="number"
                             min="0"
                             formControlName="purchasePrice"
                      >
                      <md-hint
                        *ngIf="isFieldInvalid('purchasePrice')"
                        class="error-hint"
                        align="start"
                      >{{ 'VALIDATOR_ERROR_PRIMARY_MESSAGE' | translate }}</md-hint>
                    </md-input-container>
                    <div class="flex align-center">
                      <div class="passport-edit__info--group-price-symbol">
                        € /
                      </div>
                      <div>
                        {{passport.unit_text | translate}}
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="passport-edit__info--group">
                <div class="flex align-center">
                  <span class="passport-edit__info--group-label">{{'Place(s) of manufacture' | translate}}</span>
                </div>
                <passport-manufacturers
                  #passportManufacturers
                  (onSave)="onUpdatePassportData($event)"
                  [listener]="eventStream$"
                  [passport]="passport"
                  [isDisabled]="areFormControlsDisabled || disabled"
                  [viewMode]="viewMode"
                  [parentFormGroup]="passportForm"
                ></passport-manufacturers>
              </div>
              <div class="passport-edit__info--group">
                <div class="form-group__field passport-edit__countries-list-container gpmaterials">
                  <div class="flex align-center">
                    <span class="passport-edit__info--group-label">{{'Distribution area(s)' | translate}}</span>
                  </div>
                  <passport-distribution-areas
                    #passportDistributionAreas
                    (onSave)="onUpdatePassportData($event)"
                    [listener]="eventStream$"
                    [passport]="passport"
                    [isDisabled]="areFormControlsDisabled || disabled"
                    [viewMode]="viewMode"
                  ></passport-distribution-areas>
                </div>
              </div>
            </div>



            <div class="passport-edit__info--group">
              <div class="flex align-center">
                <span class="passport-edit__info--group-label">{{'Commentaries' | translate}}</span>
              </div>

              <div class="passport-edit__info--area-fields">
                <div (click)="onControlClick($event);">
                  <md-input-container class="textarea-field">
                    <textarea
                      id="passportDescriptiom"
                      name="description"
                      mdInput
                      maxlength="65535"
                      formControlName="passportDescription"
                      [placeholder]="getCommetariesPlaceholder()"
                      [class.highlighted]="controlStates?.description?.highlighted"
                    ></textarea>
                  </md-input-container>
                </div>

                <div class="passport-edit__info--area-fields-item"
                     (click)="onControlClick($event);"
                     *ngIf="isVisible('passportR')">
                  <md-input-container class="textarea-field">
                    <textarea
                      name="next"
                      mdInput
                      formControlName="passportR"
                      maxlength="65535"
                      [placeholder]="getRecommendationsForUsePlaceholder()"
                      [class.highlighted]="controlStates?.recommendation?.highlighted">
                    </textarea>
                  </md-input-container>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      <div *ngIf="isSupportingDocumentsVisible()">
        <div class="passport__file-uploader">
          <section class="form-group">
            <div class="flex align-center">
              <label for="passportDescriptiom"
                     class="passport__file-uploader--label">{{ 'Supporting documents' | translate }}&nbsp;</label>
              <label for="passportDescriptiom"
                     class="passport__file-uploader--sub-label">({{ 'these documents are only visible by Upcyclea' | translate }}
                )</label>

              <label class="form-group__label"
                     *ngIf="viewMode == VIEW_MODE.DEFAULT"
                     mdTooltip="{{ 'PASSPORT_SUPPORTING_DOCUMENTS_TOOLTIP' | translate }}"
              >
                <i class="form-group__label--question passport-edit__uploader--tooltip">?</i>
              </label>
            </div>

            <documents-uploader
              class="form-group__content--row passport__uploader"
              [files]="getUploadFiles()"
              [type]="getActiveType()"
              (onItemUploaded)="onItemUploaded($event)"
              (onAfterRemove)="onAfterRemove($event)"
              [uploadUrl]="passport?.id && !areFormControlsDisabled ? passport.getFileUploadUrl() : null"
              [service]="passportFileService"
              [uploadUrlSet]="onUpdateUploadUrl$"
              [uploadUrlMissingErrorText]="!isLocked() ? uploadErrorText: ''"
            ></documents-uploader>
          </section>
        </div>
      </div>
      <div *ngIf="selectedTab === PASSPORT_TAB.INFO">
        <div class="passport__file-uploader">
          <section class="form-group">
            <div class="flex align-center">
              <label for="passportDescriptiom"
                     class="passport__file-uploader--label">{{ 'Documents' | translate }}&nbsp;</label>
              <label for="passportDescriptiom"
                     class="passport__file-uploader--sub-label">({{ 'these documents are visible to everyone' | translate }})</label>

            </div>

            <documents-uploader
              class="form-group__content--row passport__uploader"
              [files]="getUploadFiles()"
              [hideUploadButton]="disabled || areFormControlsDisabled"
              [type]="getActiveType()"
              (onItemUploaded)="onItemUploaded($event)"
              (onAfterRemove)="onAfterRemove($event)"
              (isUploading)="handleUploadStatus($event)"
              [uploadUrl]="passport?.id && !areFormControlsDisabled ? passport.getFileUploadUrl() : null"
              [service]="passportFileService"
              [uploadUrlSet]="onUpdateUploadUrl$"
              [uploadUrlMissingErrorText]="!isLocked() ? uploadErrorText: ''"
              [maxFileSize]="5242880"
            ></documents-uploader>
          </section>
        </div>
      </div>
      <div class="passport-edit__next-tab-button" *ngIf="selectedTab !== PASSPORT_TAB.INFO && !disabled">
        <button type="button" md-raised-button class="" (click)="nextPassportStep()">{{'Next step' | translate }}</button>
      </div>
    </div>
  </section>

  <div class="passport-edit__rating-group"
       *ngIf="isRatingBlockVisible()"
  >
    <evaluate-passport-rating
      class="passport-edit__rating-block"
      [passport]="passport"
      [parentForm]="passportForm"
    >
    </evaluate-passport-rating>
  </div>

</form>

