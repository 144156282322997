import * as tslib_1 from "tslib";
import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformPackagingJunctionService } from '../services/transform-packaging-junction.service';
var TransformPackagingJunction = /** @class */ (function (_super) {
    tslib_1.__extends(TransformPackagingJunction, _super);
    function TransformPackagingJunction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = TransformPackagingJunctionService;
        return _this;
    }
    TransformPackagingJunction.prototype.fields = function () {
        return [
            'id',
            'packaging_id',
            'transformation_id',
            'is_input',
            'name',
        ];
    };
    return TransformPackagingJunction;
}(ActiveRecord));
export { TransformPackagingJunction };
