<agm-map *ngIf="markers && mapApiReady" (idle)="onMapReady()" (zoomChange)="mapZoom = $event" [zoom]="mapZoom"
  [fitBounds]="mapBounds" [maxZoom]="maxZoom" [styles]="mapStyles" class="map" style="width: 100%; height: 300px;">
  <agm-marker-cluster *ngIf="mapType === MAP_TYPE.CLUSTER || (mapType === MAP_TYPE.ALL && mapZoom<=12)"
    [minimumClusterSize]="2" imagePath="assets/png/map/cluster/m">
    <div *ngFor="let marker of markers">
      <agm-marker *ngIf="marker.lat && marker.lng" (markerClick)="onMarkerClickEvent(marker)"
        [iconUrl]="!!marker.independent ? 'assets/png/map/localisation-blue.png' : (marker.icon || 'assets/png/map/localisation-orange.png')"
        [latitude]="marker.lat" [longitude]="marker.lng">
        <agm-info-window (infoWindowClose)="onInfoWindowClose(marker)">
          <div class="info center-text">
            <div *ngIf="marker.name"><strong>{{filterState.multilanguage && marker.english_name ? marker.english_name :
                marker.name }}</strong></div>
            <div *ngIf="marker.address">{{marker.address}}</div>
            <div *ngIf="marker.totalCount != null">
              {{ marker.totalCount }}
              <ng-container *ngIf="marker.totalCount <= 1; else plural">
                {{ 'Deposit' | translate | lowercase }}
              </ng-container>
              <ng-template #plural>
                {{ 'Deposits' | translate | lowercase }}
              </ng-template>
            </div>
            <ng-container *ngIf="marker.lines">
              <div *ngFor="let line of marker.lines" [innerHtml]="line"></div>
            </ng-container>
          </div>
        </agm-info-window>
      </agm-marker>
    </div>
  </agm-marker-cluster>
  <agm-marker-spider #spider *ngIf="mapType === MAP_TYPE.SPIDER || (mapType === MAP_TYPE.ALL && mapZoom > 12)">
    <div *ngFor="let marker of markers">
      <agm-marker *ngIf="marker.lat && marker.lng"
        [iconUrl]="!!marker.independent ? 'assets/png/map/localisation-blue.png' : (marker.icon || 'assets/png/map/localisation-orange.png')"
        [latitude]="marker.lat" [longitude]="marker.lng" (markerClick)="onMarkerClickEvent(marker)">
        <agm-info-window (infoWindowClose)="onInfoWindowClose(marker)">
          <div class="info center-text">
            <div *ngIf="marker.name"><strong>{{filterState.multilanguage && marker.english_name ? marker.english_name :
                marker.name }}</strong></div>
            <div *ngIf="marker.address">{{marker.address}}</div>
            <div *ngIf="marker.totalCount != null">
              {{ marker.totalCount }}
              <ng-container *ngIf="marker.totalCount <= 1; else plural">
                {{ 'Deposit' | translate | lowercase }}
              </ng-container>
              <ng-template #plural>
                {{ 'Deposits' | translate | lowercase }}
              </ng-template>
            </div>
            <ng-container *ngIf="marker.lines">
              <div *ngFor="let line of marker.lines" [innerHtml]="line"></div>
            </ng-container>
          </div>
        </agm-info-window>
      </agm-marker>
    </div>
  </agm-marker-spider>
</agm-map>