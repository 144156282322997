import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { MANUFACTURER_NAVIGATION } from './manufacturer.navigation';
import { ERoleIds } from '../../shared/values/role-ids.enum';
import {ModuleNames} from '../../shared/values/module-names.const';

export const MANUFACTURER_CONTEXT: IRoleContextConfig = {
  navigation: MANUFACTURER_NAVIGATION,
  moduleName: ModuleNames.MANUFACTURER,
  themeClass: 'b-module-manufacturer',
  roleId: ERoleIds.MANUFACTURER,
  roleText: 'Manufacturer',
  apiModuleName: 'manufacturer',
  homeUrl: '/manufacturer',
  rootPath: 'manufacturer'
};

