import * as tslib_1 from "tslib";
/**
 * Created by aleksandr on 21.07.17.
 */
import { ActiveRecord } from './active-record.class';
import { UserService } from '../services/user.service';
import { USER_CONTEXTS } from '../auth/user-contexts.const';
import { USER_ROLES } from '../values/role-ids.enum';
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.provider = UserService;
        _this.USER_ROLES = USER_ROLES;
        return _this;
    }
    Object.defineProperty(User.prototype, "roleText", {
        get: function () {
            return this.rolesText || this.rolesWithParentText || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "rolesText", {
        get: function () {
            var _this = this;
            return !!this.roles
                ? this.roles
                    .map(function (roleName) { return _this.getRoleContext(roleName); })
                    .filter(function (roleContext) { return !!roleContext; })
                    .map(function (roleContext) { return roleContext.roleText; })
                : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "rolesWithParentText", {
        get: function () {
            var _this = this;
            return !!this.roles_with_parent
                ? this.roles_with_parent
                    .map(function (userRole) { return _this.getRoleContext(userRole.role); })
                    .filter(function (roleContext) { return !!roleContext; })
                    .map(function (roleContext) { return roleContext.roleText; })
                : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "viewName", {
        get: function () {
            return this.first_name ? this.first_name + ' ' + this.last_name : this.username;
        },
        enumerable: true,
        configurable: true
    });
    User.prototype.fields = function () {
        return [
            'id',
            'username',
            'password',
            'first_name',
            'last_name',
            'email',
            'phone',
            'description',
            'status',
            'company',
            'roles',
            'roles_with_parent',
            'avatar_url',
            'receive_notifications',
            'receive_deposit_import_notification',
            'formRoles',
            'company_name',
            'SIRET',
            'plan_id',
            'language',
            'has_accepted_eula',
            'number_of_sites'
        ];
    };
    User.prototype.beforeSave = function (data) {
        return _super.prototype.beforeSave.call(this, data);
    };
    User.prototype.save = function () {
        var _this = this;
        // You have to update the password separately
        if (this.id && this.id > 0 && this.password) {
            var password_1 = this.password;
            var oldPassword_1 = this.oldPassword;
            this.password = null;
            this.oldPassword = null;
            return _super.prototype.save.call(this).flatMap(function (res) {
                _this.password = password_1;
                _this.oldPassword = oldPassword_1;
                console.log('Update password');
                return _this.service.changePassword(_this.id, _this.password, _this.oldPassword).map(function (data) { return res; });
            });
        }
        else {
            return _super.prototype.save.call(this);
        }
    };
    User.prototype.getUserRoles = function () {
        var _this = this;
        var userRoles = [];
        if (!!this.roles_with_parent && this.roles_with_parent.length > 0) {
            this.roles_with_parent.forEach(function (serverRole) {
                var roleContext = _this.getRoleContext(serverRole.role);
                if (!!roleContext) {
                    userRoles.push({ roleId: roleContext.roleId, parentId: _this.getRoleParentId(serverRole.role) });
                }
            });
        }
        else if (!!this.roles) {
            this.roles.forEach(function (roleName) {
                var roleContext = _this.getRoleContext(roleName);
                if (!!_this.getRoleContext(roleName))
                    userRoles.push({ roleId: roleContext.roleId, parentId: null });
            });
        }
        return userRoles;
    };
    User.prototype.getRoleParentId = function (roleApiName) {
        return this.roles_with_parent.find(function (role) { return role.role === roleApiName; }).parent_id;
    };
    User.prototype.getRoleContext = function (apiModuleName) {
        return USER_CONTEXTS.find(function (context) { return context.apiModuleName === apiModuleName; });
    };
    User.prototype.hasTransformAccess = function () {
        return this.company.transformation_acces;
    };
    return User;
}(ActiveRecord));
export { User };
