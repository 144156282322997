/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./ecosystem-wrapper.component";
var styles_EcosystemWrapperComponent = [];
var RenderType_EcosystemWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EcosystemWrapperComponent, data: {} });
export { RenderType_EcosystemWrapperComponent as RenderType_EcosystemWrapperComponent };
export function View_EcosystemWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ecosystem-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_EcosystemWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ecosystem-wrapper", [], null, null, null, View_EcosystemWrapperComponent_0, RenderType_EcosystemWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i2.EcosystemWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EcosystemWrapperComponentNgFactory = i0.ɵccf("ecosystem-wrapper", i2.EcosystemWrapperComponent, View_EcosystemWrapperComponent_Host_0, {}, {}, []);
export { EcosystemWrapperComponentNgFactory as EcosystemWrapperComponentNgFactory };
