import {ChangeDetectorRef, Component, NgZone} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'tsm-catalog',
  templateUrl: 'tsm-catalog.component.html'
})
export class TsmCatalogComponent {

  activeIndex: number = 0;

  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone) {
  }

  changeTab(index: number) {
    this.activeIndex = index;
    this.ngZone.run(() => this.cdr.detectChanges());
  }


}
