<div class="alert-dialog">
  <h1 md-dialog-title *ngIf="title">
    {{title}}
  </h1>
  <div class="alert-dialog__content" *ngIf="text">
    {{text}}
  </div>
  <ul class="button-group__list alert-dialog__controls">
    <li class="button-group__item">
      <button md-button md-raised-button
              (click)="onClose()">{{ 'Ok' | translate }}</button>
    </li>
  </ul>
</div>
