import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { CrudService } from '../../../shared/services/crud.service';
import { GenericPassportTag } from '../models/generic-passport-tag.class';
import { Observable } from 'rxjs';


@Injectable()
export class GenericPassportTagService extends CrudService {
  protected namespace = 'generic-passport-tags';
  protected namespaceSingular = 'generic-passport-tag';
  protected ModelClass = GenericPassportTag;


  public deleteTag( tag_id: number): Observable<any> {
    return this.sendDelete(this.getEndpoint(this.singularEndpoint + '/' + tag_id)).map((data: Response) => data.json())
    .catch(this.onError.bind(this));
    
  }
}
