import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import { IParentEvent } from '../../../../shared/interfaces/parent-event.interface';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Passport } from '../../models/passport.class';
import { PASSPORT_REQUEST } from '../../values/passport-request.const';
import { MdDialog } from '@angular/material';
import { ReadOnlyService } from '../../../../shared/read-only/read-only.service';
import { VIEW_MODE } from '../../values/view-mode-values.const';
import {ManufacturersService} from '../../services/manufacturers.service';
import {Colors} from '../../models/colors.class';
import {PASSPORT_TRANSPARENCE_TYPES} from '../../values/passport-trasparence-types.const';
import {PASSPORT_COLOR_TYPES} from '../../values/passport-color-types.const';
import {ColorsService} from '../../services/colors.service';
import {forkJoinSafe} from '../../../../shared/helpers/fork-join-safe.helper';
import { Subscription } from 'rxjs';
import {PassportCustomStoreService} from '../../services/passport-custom-store.service';

@Component({
  moduleId: module.id,
  selector: 'passport-colors',
  templateUrl: 'passport-colors.component.html'
})
export class PassportColorsComponent implements OnInit {

  @Input() public listener?: EventEmitter<IParentEvent>;
  @Input() public passport?: Passport;
  @Input() public isDisabled?: boolean = false;
  @Input() public viewMode: number;
  @Input() parentFormGroup: FormGroup;
  @Output() onSave = new EventEmitter();
  transparenceTypes = PASSPORT_TRANSPARENCE_TYPES;
  colorTypes = PASSPORT_COLOR_TYPES;
  subscription: Subscription;
  listCtrl: FormArray;

  disableStatus: boolean = false;

  VIEW_MODE = VIEW_MODE;

  constructor(public _fb: FormBuilder,
              public dialog: MdDialog,
              public colorsService: ColorsService,
              @Optional() private passportChildComponentsEventListenerService: PassportCustomStoreService,
              @Optional() public readOnlyService: ReadOnlyService
  ) {
  }
  getColors() {
    this.passport.syncColors();
  }
  updateSubscription(): PassportColorsComponent {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.passport.onSyncColors$.subscribe((records: any) => this.updateColors());
    return this;
  }
  ngOnInit() {
    this.disableStatus = this.isDisabled;
    if (this.passport) {
      this.changePassport(this.passport).updateSubscription().getColors();
    }

    if (this.listener) {
      this.listener.subscribe((event: IParentEvent) => {
        if(event.key === 'changePassport') {
          this.changePassport(event.data).updateSubscription().getColors();
        }
        if(event.key === 'disableStatus') {
          this.disableStatus = event.data.isDisabled;
          this.updateDisableStatus();
        }
        if (event.key === 'savePassport') {
          this.onSave.emit({type: PASSPORT_REQUEST.COLORS, request: this.save()});
        }
      });
    }

  }

  changePassport(passport: Passport): this {
    this.passport = passport;
    return this;
  }

  updateColors(): void {
    this.listCtrl = this._fb.array([]);
    if (this.passport.colors.length === 0) {
      this.addNew();
    }
    this.passport.colors.forEach((usage) => this.addCtrl(usage));
    this.updateDisableStatus();

    if (this.passportChildComponentsEventListenerService) {
      this.passportChildComponentsEventListenerService
        .addFormProgrammaticlyAction.emit({controlName: 'colors', control: this.listCtrl});
    }
  }

  addNew(): void {
    if (this.isDisabled) {
      return;
    }
    this.addCtrl(new Colors({}));
  }

  removeColor(index: number): void {
    if (this.isDisabled) {
      return;
    }
    const manufacturersForm: FormArray = <FormArray>this.listCtrl.controls[index];
    if (!manufacturersForm) {
      console.error('Failed to get next usage form by index (' + index + '):', this.listCtrl);
    }

    const idCtrl = manufacturersForm.get('id');
    if (!!idCtrl && idCtrl.value) {
      this.colorsService.remove(idCtrl.value).subscribe();
    }

    this.listCtrl.removeAt(index);
    this.listCtrl.markAsDirty();
    if (this.listCtrl.length === 0) {
      this.addNew();
    }
  }

  addCtrl(color: Colors): void {
    const formGroup = this._fb.group({
      id: [color.id],
      color_id: [color.color_id],
      opacity: [color.opacity],
    });
    this.listCtrl.push(formGroup);
  }

  updateDisableStatus(): void {
    if (!this.listCtrl) {
      this.listCtrl = this._fb.array([]);
    }
    if (this.disableStatus) {
      this.listCtrl.controls.forEach((control) => control.disable());
    } else {
      this.listCtrl.controls.forEach((control) => control.enable());
    }
  }

  save() {
    if (this.passport && this.passport.id) {
      const requests = this.listCtrl.controls.map((control) => this.getColorsUsage(<FormArray>control));
      return forkJoinSafe<Colors>(requests);
    }
  }

  getColorsUsage(colorsCtrlUsage: FormArray) {
    const colorsCtrl = this.getColorsFromForm(colorsCtrlUsage);
    if (!!colorsCtrl) {
      return colorsCtrl.save().map(color => {
        if (colorsCtrlUsage.get('id') && !!color.id) {
          colorsCtrlUsage.get('id').setValue(color.id);
        }
        return color;
      });
    }
  }

  getColorsFromForm(form: FormArray): Colors {
    if (!form) {
      return null;
    }

    const id = form.get('id') ? form.get('id').value : null;
    const color_id = form.get('color_id') ? form.get('color_id').value : null;
    const opacity = form.get('opacity') ? form.get('opacity').value : null;
    const passport_id = form.get('passport_id') ? form.get('passport_id').value : null;

    if (!id && !passport_id && !color_id && !opacity) {
      return null;
    }

    return new Colors({
      id: id,
      passport_id: this.passport ? this.passport.id : null,
      color_id: color_id,
      opacity: opacity
    });
  }

  /**
   * Get the current list of colors
   * @returns {Array<PassportColor>}
   */
  getCurrentColors() {
    return this.listCtrl.getRawValue() || [];
  }
}
