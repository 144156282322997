<div class="deposit-import-column-custom" [ngClass]="className">
  <div class="deposit-import-column-custom__column">
      <ng-content select="[column]"></ng-content>
  </div>
  <div class="deposit-import-column-custom__description-wrapper">
    <deposit-import-column-description
      class="deposit-import-column-custom__description"
      [description]="description"
      [showWarningSign]="showWarningSign"
    ></deposit-import-column-description>
    <deposit-import-column-description
      class="deposit-import-column-custom__description"
      [description]="additionalDescription"
      [showWarningSign]="!!additionalDescription"
    ></deposit-import-column-description>
    <ng-content select="[description]"></ng-content>
  </div>
</div>

