import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PassportSynchronizationData } from '../../../entities/passport/models/passport-synchronization-data.class';

@Component({
  selector: 'app-database-filter',
  templateUrl: './database-filter.component.html',
  styleUrls: ['./database-filter.component.scss']
})
export class DatabaseFilterComponent{
  @Input() databaseSelected: number[] = [];
  @Output() databaseChange = new EventEmitter<number[]>();
  databases: string[];

  ngOnInit() {
    this.databases = PassportSynchronizationData.getSourceValueList();
  }

  getNamesDatabase(): string[] {
    return this.databaseSelected.map(database => PassportSynchronizationData.getSourceValue(database));
  }

  handleSelectionChange(selectedDatabases: string[]) {
    this.databaseChange.emit(selectedDatabases.map(database => PassportSynchronizationData.getSourceKey(database)));
  }
}
