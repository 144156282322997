<md-dialog-content>
  <div class="copygenericpassport__content">

    <h2 class="copygenericpassport__title">{{'Duplicate the generic passport ?' | translate}}</h2>

    <label class="copygenericpassport__label">{{'Tag name' | translate}}</label>

    <div class="form-group__field">
      <div class="copygenericpassport__chips">
        <span class="copygenericpassport__chip" *ngFor="let tag of genericPassport.tags">
          {{tag.name}}
        </span>
      </div>
    </div>

    <label class="copygenericpassport__label">{{'Zone(s) to duplicate the passport' | translate}}</label>

    <div class="flex">
      <div class="flex-column">
        <div *ngFor="let item of zonesForm?.controls; let i=index">
          <div class="copygenericpassport__row" [formGroup]="zonesForm.controls[i]">
            <li class="control-group__item control-group__item--icon-compact">
              <div class="control-group__item-button" (click)="removeZone(i)">
                <md-icon role="img" class="material-icons mat-icon">remove</md-icon>
              </div>
            </li>
            <div class="control-group__item copygenericpassport__select-wrapper">
                <small class="copygenericpassport__select-label">{{ 'Zone' | translate }}</small>
                <md-select floatPlaceholder="never" formControlName="id">
                  <md-option *ngFor="let item of zones" [value]="item.id">{{item.name}}
                  </md-option>
                </md-select>
            </div>
          </div>
        </div>  
      </div>
      <div class="control-group__item-button copygenericpassport__add-button" (click)="addZone()">
        <md-icon role="img" class="material-icons mat-icon">add</md-icon>
      </div>
    </div>

    <div class="copygenericpassport__buttons">
        <button md-button md-raised-button (click)="confirm()">{{ 'Confirm' | translate }}</button>
        <button md-button md-raised-button (click)="cancel()">{{ 'Cancel' | translate }}</button>
    </div>

  </div>
</md-dialog-content>