import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'admin',
  templateUrl: 'admin.component.html'
})

export class AdminComponent {

}
