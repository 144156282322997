/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../report-progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../report-progress.component.ngfactory";
import * as i3 from "../report-progress.component";
import * as i4 from "../report-progress.service";
import * as i5 from "./report-progress-wrapper.component";
import * as i6 from "../../../../../../shared/services/deepstream.service";
import * as i7 from "../../../../services/report.service";
var styles_ReportProgressWrapperComponent = [i0.styles];
var RenderType_ReportProgressWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportProgressWrapperComponent, data: {} });
export { RenderType_ReportProgressWrapperComponent as RenderType_ReportProgressWrapperComponent };
export function View_ReportProgressWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "report-progress", [], null, null, null, i2.View_ReportProgressComponent_0, i2.RenderType_ReportProgressComponent)), i1.ɵdid(1, 49152, null, 0, i3.ReportProgressComponent, [i4.ReportProgressService], null, null)], null, null); }
export function View_ReportProgressWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "report-progress-wrapper", [], null, null, null, View_ReportProgressWrapperComponent_0, RenderType_ReportProgressWrapperComponent)), i1.ɵdid(1, 245760, null, 0, i5.ReportProgressWrapperComponent, [i6.DeepStreamService, i4.ReportProgressService, i7.ReportService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportProgressWrapperComponentNgFactory = i1.ɵccf("report-progress-wrapper", i5.ReportProgressWrapperComponent, View_ReportProgressWrapperComponent_Host_0, {}, {}, []);
export { ReportProgressWrapperComponentNgFactory as ReportProgressWrapperComponentNgFactory };
