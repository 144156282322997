<md-dialog-content>
  <dialog-close-button (click)="cancel()">
  </dialog-close-button>
  <confirm-with-header
    appNotificationAsWideDialogContent
    class="order-transaction-confirmation__confirm-with-header-dialog"
    [ngClass]="{'order-transaction-confirmation--disabled': readOnly}"
    [confirmButtonText]="'OK, send' | translate"
    [cancelButtonText]="'Cancel' | translate"
    title="{{title | translate}}"
    (onConfirm)="reportProblem()"
    (onCancel)="cancel()"
    [disabled]="readOnly"
  >
    <section class="report-problem-dialog__header" [ngClass]="{'report-problem-dialog__header--readonly': readOnly}">
      <div class="report-problem-dialog__header-text--bold">
        {{foreword | translate}}
      </div>
      <div class="report-problem-dialog__header-text">
        {{subForeword | translate}}
      </div>
    </section>
    <section class="report-problem-dialog__request" [ngClass]="{'report-problem-dialog__request--readonly': readOnly}">
      <md-input-container class="report-problem-dialog__request-problem">
        <textarea 
        #problemDescriptionTextarea 
        mdInput 
        [readonly]="readOnly" 
        [(ngModel)]="problemDescription" 
        (input)="expandProblemDescriptionTextarea()" 
        rows="1"></textarea>
      </md-input-container>
    </section>
  </confirm-with-header>
</md-dialog-content>
