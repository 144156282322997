<section class="activating-account" *ngIf="allowedForRender">
  <div class="activating-account__block">
    <div class="activating-account__block--header">
      <section class="activating-account__section activating-account__section--lang">
        <div class="activating-account__lang-menu">
          <div class="activating-account__lang-menu--preview">
            <img class="activating-account__lang-menu--image" [src]="getLangImage()">
            <i class="activating-account__lang-menu--icon-down"></i>
          </div>
          <ul class="activating-account__lang-menu--list">
            <li class="activating-account__lang-menu--list-item" (click)="setLang('en')">
              <div class="activating-account__lang-menu--list-item-image">
                <img [src]="getImage('en')" alt="en">
              </div>
              <div class="activating-account__lang-menu--list-item-label">English</div>
            </li>
            <li class="activating-account__lang-menu--list-item" (click)="setLang('fr')">
              <div class="activating-account__lang-menu--list-item-image">
                <img [src]="getImage('fr')" alt="fr"/>
              </div>
              <div class="activating-account__lang-menu--list-item-label">Français</div>
            </li>
            <li class="activating-account__lang-menu--list-item" (click)="setLang('es')">
              <div class="activating-account__lang-menu--list-item-image">
                <img [src]="getImage('es')" alt="es"/>
              </div>
              <div class="activating-account__lang-menu--list-item-label">Español</div>
            </li>
            <li class="activating-account__lang-menu--list-item" (click)="setLang('pt')">
              <div class="activating-account__lang-menu--list-item-image">
                <img [src]="getImage('pt')" alt="pt"/>
              </div>
              <div class="activating-account__lang-menu--list-item-label">Português</div>
            </li>
          </ul>
        </div>
      </section>
      <p class="activating-account__block--header-title">
        {{ 'Welcome to' | translate }} <br>
        <b>myUpcyclea</b><br>
      </p>
      <p class="activating-account__block--header-text">{{ 'SHORT_INFO_ABOUT_PLATFORM' | translate }}</p>
    </div>
    <section class="activating-account__section activating-account__section--steps-block">
      <div *ngIf="activationAuthKey; else ourConditions" class="activating-account__steps-block">
        <p [className]="activeStep === 1 ? 'activating-account__steps-block--item-active' : 'activating-account__steps-block--item-inactive'">1</p>
        <p class="activating-account__steps-block--hr"></p>
        <p [className]="activeStep === 2 ? 'activating-account__steps-block--item-active' : 'activating-account__steps-block--item-inactive'">2</p>
      </div>
      <ng-template #ourConditions>
        <p class="activating-account__steps-block-text">
          {{ 'Our Terms and Conditions of Use has changed, please accept them before logging in the platform' | translate }}
        </p>
      </ng-template>
    </section>
    <div class="activating-account__context-block">
      <section class="activating-account__section activating-account__section--main-block">
        <div [className]="activeStep === 1 ? 'activating-account__main-block--first-step-width' : 'activating-account__main-block--second-step-width'">
          <h1 *ngIf="activeStep === 1" class="activating-account__main-block--title">
            <span>
              {{ 'Terms and conditions of use' | translate }}
            </span>
          </h1>

          <div class="activating-account__main-block--iframe-pdf" *ngIf="activeStep === 1">
            <md-progress-spinner
              *ngIf="!eula?.hasFiles()"
              mode="indeterminate">
            </md-progress-spinner>
            <iframe *ngIf="eula?.hasFiles()"
                    [src]="getSafePdfSource()"
                    width="100%"
                    height="100%"
            ></iframe>
          </div>
          <p *ngIf="activeStep === 2" class="activating-account__main-block--main-text">{{ 'MAIN_TEXT_FOR_USER_ACCEPTATION' | translate }}</p>
          <div *ngIf="activeStep === 1" class="activating-account__main-block--checkbox-container">
            <md-checkbox [disabled]="!eula?.hasFiles()" color="primary" [(ngModel)]="isAgreedWithEULA" class="activating-account__main-block--checkbox-container-item">
              {{ 'ACTIVATE_ACCOUNT_CHECKBOX_MESSAGE' | translate }}
            </md-checkbox>
          </div>
          <div *ngIf="activeStep === 2" class="activating-account__main-block--img-container">
            <img class="activating-account__main-block--img-container-item" [src]="getImageMainBlock()"/>
          </div>
          <div class="activating-account__main-block--submit-btn">
            <div *ngIf="activeStep === 1 && !activationAuthKey; else nexStep">
              <div class="activating-account__main-block--btn-container">
                <p class="activating-account__main-block--refuse-btn-link" (click)="refuseEula()">
                  {{ 'Refuse' | translate }}
                </p>
                <button md-button [disabled]="!isAgreedWithEULA" (click)="onRedirectedFromLoginAcceptance()" class="activating-account__main-block--submit-btn-link">
                  {{ 'Login' | translate }}
                </button>
              </div>
            </div>
            <ng-template #nexStep>
              <button md-button [disabled]="!isAgreedWithEULA" class="activating-account__main-block--submit-btn-link" *ngIf="activeStep === 1 && activationAuthKey" (click)="setStepNumber(2)" >
                {{ 'activate my account' | translate }}
              </button>
              <button md-button [disabled]="!isAgreedWithEULA" class="activating-account__main-block--submit-btn-link" *ngIf="activeStep === 2" (click)="onEulaAcceptance()" >
                {{ 'Access the platform' | translate }}
              </button>
            </ng-template>
          </div>
        </div>
      </section>
    </div>
    <section class="activating-account__section activating-account__section--footer-block">
      <div class="activating-account__footer-block">
        <div *ngIf="activeStep === 2" class="activating-account__footer-block--items">
          <p class="activating-account__footer-block--item-text">{{'SUPPORT_AVAILABLE_ON_MYUPCYCLEA' | translate }}</p>
          <a href="https://help.upcyclea.com" target="_blank" class="activating-account__main-block--link">
            <i class="ib-icon faq--icon"></i>
          </a>
        </div>
      </div>
    </section>
  </div>
</section>
