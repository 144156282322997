import {Component, OnInit, Inject, NgZone} from '@angular/core';
import { MdDialogRef, MD_DIALOG_DATA } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'deposit-import-warning',
  templateUrl: 'deposit-import-warning.component.html',
  styleUrls: ['deposit-import-warning.component.scss']
})
export class DepositImportWarningComponent implements OnInit {

  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any,
  public mdDialogRef: MdDialogRef<any>) { }

  ngOnInit() {
  }

  confirm() {
    this.mdDialogRef.close(true);
  }

  closeDialog() {
    this.mdDialogRef.close(false);
  }
}
