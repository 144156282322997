<div class="dialog-wrapper">
  <h1 md-dialog-title>Download raw report</h1>
  <div md-dialog-content class="input-wrapper">
    <md-input-container >
      <input mdInput type="text" [(ngModel)]="reportId">
    </md-input-container>
    <button md-raised-button (click)="confirm()" class="button-download" color="primary">Download</button>
  </div>
  <div md-dialog-actions>
    <button md-button md-dialog-close>Close</button>
  </div>
</div>