import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LogNotificationService} from '../../../../shared/services/log-notification.service';
import {ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE} from '../../../../dashboards/ecosystem-manager/values/ecosystem-file-junction-item-type.const';
import {EcosystemFileJunction} from '../../../../dashboards/ecosystem-manager/models/ecosystem-file-junction.class';
import {EcosystemFileJunctionService} from '../../../ecosystem/services/ecosystem-file-junctions.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {CapitalizePipe} from '../../../../shared/pipes/capitalize.pipe';
import {EcosystemFileService} from '../../../ecosystem/services/ecosystem-file.service';
import {EcosystemService} from '../../../ecosystem/services/ecosystem.service';
import {EcosystemFile} from '../../../../dashboards/ecosystem-manager/models/ecosystem-file.class';
import {
  EOrderTransactionConfirmResultFieldType
} from '../../../../shared/convert-components/order-transaction-confirm-result-field/order-transaction-confirm-result-field-type.enum';
import {touchEachFormControl} from '../../../../shared/helpers/touch-each-form-control';
import {EcosystemOffer} from '../../ecosystem-offer.model';
import {EcosystemOfferService} from '../../ecosystem-offer.service';
import moment from 'moment';
import {UploadedDocument} from '../../../../shared/models/uploaders/document.class';
import {EcosystemFileToJunctionService} from '../../../ecosystem/services/ecosystem-file-to-junction.service';
import {RequestFileSenderService} from '../../../../dashboards/admin/services/request-file-sender.service';
import {Observable} from 'rxjs/Observable';
import {mergeMap} from 'rxjs/operators';


@Component({
  selector: 'offer-transaction-confirm',
  templateUrl: './offer-transaction-confirm.component.html',
  styleUrls: ['./offer-transaction-confirm.component.scss'],
})
export class OfferTransactionConfirmComponent implements OnInit {
  RESULT_FIELD_TYPE = EOrderTransactionConfirmResultFieldType;
  @Input() disabled = false;
  @Input() id: number;
  @Input() showESMText = false;
  @Input() offer: EcosystemOffer;
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onFileUploadingFail = new EventEmitter();
  @Output() onFileUploadingComplete = new EventEmitter();
  allFiles: EcosystemFile[] = [];
  filesWithJunction: EcosystemFileJunction[] = [];
  form: FormGroup;

  constructor(
    public ecosystemService: EcosystemService,
    public ecosystemFileService: EcosystemFileService,
    public requestFileSenderService: RequestFileSenderService,
    private ecosystemJunctionService: EcosystemFileJunctionService,
    private ecosystemOfferService: EcosystemOfferService,
    private notifications: LogNotificationService,
    private translationService: TranslateService,
    private capitalizePipe: CapitalizePipe,
    private fb: FormBuilder,
    private ecosystemFileToJunctionService: EcosystemFileToJunctionService,
  ) { }

  ngOnInit() {
    this.loadTransformation();
  }

  private loadTransformation() {
    if (this.offer) {
      this.onAfterOrderLoaded();
    } else if (this.id) {
      this.loadTransformationById();
    } else  {
      this.onLoadErrorMessage();
    }
  }

  private loadTraceabilityFiles() {
    this.ecosystemJunctionService.get({
      item_id: this.id, item_type: ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_OFFER_TRACEABILITY
    }).subscribe((junctions: EcosystemFileJunction[]) => {
      this.filesWithJunction = junctions;
      this.allFiles = junctions.map(junction => junction.ecosystem_file);
      }
    );
  }

  private loadTransformationById() {
    this.ecosystemOfferService.view(this.id, {expand: this.getCommonRequestExpandAsString()})
      .subscribe((offer) => {
        this.offer = offer;
        this.onAfterOrderLoaded();
      });
  }

  private getCommonRequestExpandAsString() {
    return 'need.passport,ecosystem,passport';
  }

  private onAfterOrderLoaded() {
    this.initForm();
    if (!this.disabled) {
      this.resetTransactionConfirmation();
    }
    this.loadTraceabilityFiles();
  }

  private onLoadErrorMessage() {
    this.notifications.error('No result found', null, true);
  }

  private initForm() {
    const getConfirmControlConfig = (value) => ([{disabled: this.disabled, value}, Validators.required]);
    const asControl = (name: string, confirmed: any) => ({[name]: getConfirmControlConfig(confirmed)});
    this.form = this.fb.group({
      ...asControl('quantity', this.transactionConfirmValue(this.offer.transaction_quantity, this.offer.quantity)),
      ...asControl('date', this.transactionConfirmValue(this.offer.transaction_date, this.offer.delivery_date)),
      ...asControl('price', this.transactionConfirmValue(this.offer.transaction_price, this.offer.price)),
      description: [{disabled: this.disabled, value: this.offer.description}]
    });
  }

  private transactionConfirmValue(confirm, defaultValue) {
    return this.disabled ? (confirm || (confirm === 0 ? 0 : defaultValue)) : '';
  }

  private resetTransactionConfirmation() {
    this.offer.transaction_price = null;
    this.offer.transaction_date = null;
    this.offer.transaction_quantity = null;
  }

  get title() {
    return `${this.translationService.instant('confirmation of offer fulfilment')}`;
  }

  createFileJunctions(ecosystemFiles) {
    const junctionService = this.ecosystemFileToJunctionService;
    ecosystemFiles = junctionService.filterFilesWithoutJunction(ecosystemFiles, this.filesWithJunction);
    if (!ecosystemFiles.length) {
      this.emitUploadingStatus();
      return;
    }
   junctionService.createJunctionFromFiles(
     this.offer, ECOSYSTEM_FILE_JUNCTION_ITEM_TYPE.TYPE_OFFER_TRACEABILITY, ecosystemFiles
   ).subscribe(
       ({junctions, files, failed}) => {
         this.allFiles = files;
         this.filesWithJunction = junctions;
         this.emitUploadingStatus(!!failed.length);
       });
  }

  private emitUploadingStatus(isFailed?: boolean) {
    if (isFailed) {
      this.requestFileSenderService.onCompleteItem({});
    }
    this.requestFileSenderService.onAllItemsUploaded(this.allFiles);
  }

  deleteOnlyLinkIfUploaded() {
    return (file: UploadedDocument) => {
      const reset = (junction) => {
        this.allFiles = this.allFiles.filter(item => item.id !== junction.file_id);
        this.filesWithJunction = this.filesWithJunction.filter(item => item.file_id !== file.id);
      };
      return !this.ecosystemFileToJunctionService.deleteLinkInsteadIfExist(file as EcosystemFile, this.filesWithJunction, reset);
    };
  }

  confirmOrder() {
    if (this.form && this.form.valid) {
      this.offer.transaction_date = moment(this.form.controls['date'].value, [ 'DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
      this.onConfirm.emit(this.offer.save().pipe(mergeMap((offer: EcosystemOffer) => offer.confirm())));
    } else {
      touchEachFormControl(this.form);
      this.notifications.error(
        'You must fill in all the fields on the right to confirm the data', null, true);
    }
  }
}
