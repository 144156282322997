import {IAdditionalDatetimeNames} from './additional-datetime-names.interface';

export const ADDITIONAL_DATETIME_NAMES_EN: IAdditionalDatetimeNames = {
  date: 'Date',
  time: 'Time',
  year: 'Year',
  month: 'Month',
  day: 'Day',
  hour: 'Hour',
  minute: 'Minute',
  currentTime: 'Current time',
};

export const ADDITIONAL_DATETIME_NAMES_FR: IAdditionalDatetimeNames = {
  date: 'Date',
  time: 'Horaire',
  year: 'Année',
  month: 'Mois',
  day: 'Journée',
  hour: 'Heure',
  minute: 'Minute',
  currentTime: 'Heure actuelle',
};

export const ADDITIONAL_DATETIME_NAMES_ES: IAdditionalDatetimeNames = {
  date: 'Fecha',
  time: 'Horario',
  year: 'Año',
  month: 'Mes',
  day: 'Día',
  hour: 'Hora',
  minute: 'Minuto',
  currentTime: 'Hora actual',
};
