<div class="nomenclature-change-confirmation-dialog">
  <h3 class="title">
    {{ "Your request for a change in the nomenclature has been taken into account. It will be processed shortly." | translate }}
  </h3>
  <div class="actions">
    <button (click)="close()" md-button class="ok">
      <span class="text">
      {{ 'Ok' | translate }}
    </span>
    </button>
  </div>
</div>
