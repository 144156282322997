import { ActiveRecord } from '../../../shared/models/active-record.class';
import { TransformTagService } from '../services/transform-tag.service';

export class TransformTag extends ActiveRecord {
  protected provider = TransformTagService;

  public id: number;
  public name: string;

  protected fields() {
    return [
      'id',
      'name',
    ];
  }
}


