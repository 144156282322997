import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { PassportFile } from '../models/passport-file.class';
var PassportFileService = /** @class */ (function (_super) {
    tslib_1.__extends(PassportFileService, _super);
    function PassportFileService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'passport-files';
        _this.namespaceSingular = 'passport-file';
        _this.ModelClass = PassportFile;
        return _this;
    }
    return PassportFileService;
}(CrudService));
export { PassportFileService };
