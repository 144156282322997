<section *ngIf="landfillVariables"  class="variables-block">
  <h2 class="variables-block__header">
    {{ 'List of landfill cost of all waste variables' | translate }}
  </h2>
  <div class="variables-block__section">
    <div *ngFor="let item of landfillVariables" class="variables-block__item">
      <md-input-container class="additionaly__number-input variables-block__item">
        <label>{{item.name | translate}} ({{currency.symbol}}/T): </label>
        <input
          class="variables-block__item--input"
          mdInput
          autocomplete="off" 
          type="number"
          maxlength="6"
          [(ngModel)]="item.value"
          (input)="changeInputValue(item)"
        />
      </md-input-container>
    </div>
  </div>
</section>
