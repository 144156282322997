/**
 * Created by aleksandr on 31.03.17.
 */

import { IModuleNavigation } from '../../shared/interfaces/module-navigation.interface';

export const AGENT_NAVIGATION: IModuleNavigation[] = [
{
  url: 'agent/passports',
  title: 'Circular passports',
  icon: 'cube--icon'
}, {
  url: 'agent/deposits',
  title: 'Deposits',
  icon: 'my-deposits--icon'
},
{
  url: 'agent/tags',
  title: 'sites & tags',
  icon: 'my-deposit-tags--icon'
}
//  {
//   url: 'agent/statistics',
//   title: 'Statistics',
//   icon: 'statistic--icon'
// }
];
