<div class="dublicat__item">
  <div class="dublicat__item-img"><img src="{{record.image?.file}}" class="dublicat__item-imglogo"></div>
  <div class="dublicat__item-info">
    <div class="dublicat__item-logotextile">{{deposit.name}}</div>

    <div
      class="dublicat__item-container"
      *ngFor="let entry of materialsAndComponents"
    >
      <div
        class="dublicat__item-align"
        [ngClass]="{'invisible': !entry.passport_component}"
      >
        <div class="dublicat__item-component">{{ 'Component' | translate }}:</div>
        <span class="dublicat__item-slice">{{entry.passport_component?.name}}</span>
      </div>
      <div
        class="dublicat__item-material"
        *ngIf="entry.material_name"
      >{{ 'Material' | translate }}: <span class="dublicat__item-textile">{{entry.material_name?.name}}</span></div>
    </div>

    <div
      class="dublicat__item-container"
      *ngIf="deposit.description || deposit.quantity !== null"
    >
      <div class="dublicat__item-align" [ngClass]="{'invisible': deposit.quantity === null}">
        <div class="dublicat__item-unitstring">{{ 'QTY' | translate }}:</div>
        <span class="dublicat__item-unit">{{deposit.quantity}} {{deposit?.passport.unit_text}}</span>
      </div>
      <div
        class="dublicat__item-desc-string"
        [ngClass]="{'invisible': !deposit.description}"
      >{{ 'Description' | translate }}: <span class="dublicat__item-description">{{deposit.description}}</span></div>
    </div>
  </div>
  <div class="dublicat__item-choose uppercase" (click)="select(deposit)"> {{ 'Choose' | translate }}</div>
</div>

<!--{{ deposit  |json}}-->
<!--<md-card class="deposit-item">-->
<!--{{deposit.name}}-->
<!--<a (click)="selectDeposit(deposit)">CHOOSE</a>-->
<!--</md-card>-->


<!--
<div class="depositlist__item" [ngClass]="{'depositlist__item--gray': (deposit.status === 1 || deposit.status ===2)}">
    <div class="depositlist__item-id">{{deposit.id}}</div>
    <div class="depositlist__item-info" (click)="select(deposit)">
        <div class="depositlist__item-top">
            <div class="depositlist__item-name">{{deposit.name}}</div>
            <div class="depositlist__item-pcs" > {{deposit.quantity ? deposit.quantity : 0}} {{getUnitText(deposit.passport.unit)}}</div>
            <div class="depositlist__item-appellation">Address: {{deposit.deposit_location?.name}}</div>
        </div>
        <div class="depositlist__item-bottom">
            <div class="depositlist__item-date">Created: {{deposit.created_at | date: "dd. MMM y"}}</div>
            <div class="depositlist__item-time"> {{deposit.updated_at | date: "shortTime"}}</div>
            <div class="depositlist__item-dispon">Availibility: {{deposit.availability_date | date: " dd. MMM y"}}</div>
        </div>
    </div>
</div>

-->
