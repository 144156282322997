<div class="residual-value-second-chart" *ngIf="report && type && hasCategory()">

  <div class="residual-value-second-chart__title" *ngIf="!isPDFReport">
    <h3>{{ 'Profit oriented scenario' | translate | uppercase}}</h3>
    <div class="residual-value-second-chart__title--hr"></div>
  </div>


  <div class="residual-value-second-chart__total" *ngIf="isPDFReport">

    <div class="residual-value-second-chart__total--low-container">
      <svg viewBox="0 0 200 200" style="fill: gray;">
        <path id="curveLow" d="M 100 185 C 0 100, 100 0, 100 5" stroke="transparent" fill="transparent"></path>
        <text>
          <textPath xlink:href="#curveLow" style="text-anchor: initial; font-size: 26px;">
            {{ 'Minimum' | translate }} </textPath>
        </text>
      </svg>
    </div>

    <div class="residual-value-second-chart__total--high-container">
      <svg viewBox="0 0 200 200" style="fill: gray;">
        <path id="curveHigh" d="M 110 20 C 172 50, 172, 150, 100 185" stroke="transparent" fill="transparent"></path>
        <text>
          <textPath xlink:href="#curveHigh" style="text-anchor: initial; font-size: 26px;">
            {{ 'Maximum' | translate }}   </textPath>
        </text>
      </svg>
    </div>

    <div class="residual-value-second-chart__total--title">{{'total residual value' | translate}}</div>
    <div class="residual-value-second-chart__total--chart">

      <div
        [ngClass]="['residual-value-second-chart__value-wrapper-pdf', 'residual-value-second-chart-bar__chart--column-pillar-radius', getModifiedClassName('residual-value-second-chart-bar__chart--column-pillar', totalValueCategory.itemNumber)]">
        <div [ngClass]="['residual-value-second-chart__high-value-pdf', 'residual-value-second-chart-bar__chart--column-pillar-circle', getModifiedClassName('residual-value-second-chart-bar__chart--column-pillar-circle-pdf', totalValueCategory.itemNumber),'residual-value-second-chart-bar__chart--column-pillar-circle-'+totalValueCategory.itemNumber
      ]">
          <p>
            {{totalValueCategory.mainDataForChart.high | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
            %</p>
        </div>
        <div [ngClass]="[
         'residual-value-second-chart__low-value-pdf','residual-value-second-chart-bar__chart--column-pillar-circle',getModifiedClassName('residual-value-second-chart-bar__chart--column-pillar-circle-pdf', totalValueCategory.itemNumber),
         'residual-value-second-chart-bar__chart--column-pillar-circle-'+totalValueCategory.itemNumber
        ]">
          <p>
            {{totalValueCategory.mainDataForChart.low | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
            %</p>
        </div>

      </div>

    </div>

    <div class="residual-value-second-chart__body--left-block-text-pdf">
      <p><span class="residual-value-second-chart__body--left-block-text-value-pdf">{{totalValueCategory.considered}}
          /
          {{totalValueCategory.totalDeposits}}</span> {{ 'deposits considered' | translate }}</p>
      <p><span class="residual-value-second-chart__body--left-block-text-value-pdf">
          {{totalValueCategory.tonsOfWeight | floatingDigitNumberAfterComma: 'fr' | decimalSeparator:','}}      
        </span>
        {{ 'tons of deposits in total' | translate }}</p>
    </div>

  </div>



  <div [ngClass]="{'residual-value-second-chart__charts':!isPDFReport, 'residual-value-second-chart__charts-pdf':isPDFReport}">
    <div *ngIf="isPDFReport"
      class="residual-value-second-chart-bar__chart--left-text-about-arrow-pdf residual-value-second-chart__description--text">
      <p [innerHTML]="'Percentage of residual value over the initial material cost (%)' | translate"></p>
    </div>
    <div class="residual-value-second-chart__left" *ngIf="!isPDFReport">
      <div class="residual-value-second-chart-bar">
        <section class="residual-value-second-chart-bar__chart">
          <div class="residual-value-second-chart-bar__chart--wrapper">
            <div class="residual-value-second-chart-bar__chart--content">
              <div
                class="residual-value-second-chart-bar__chart--left-text-about-arrow residual-value-second-chart__description--text">
                <p [innerHTML]="'Percentage of residual value over the initial material cost (%)' | translate"></p>
              </div>
              <div class="residual-value-second-chart-bar__chart--left-number-wrapper ">
                <div *ngFor="let item of getVerticalAxisValues(); let i = index"
                  class="residual-value-second-chart-bar__chart--left-number">
                  <p>{{item}}%</p>
                </div>
              </div>
              <div class="residual-value-second-chart-bar__chart--columns">
                <div class="residual-value-second-chart-bar__chart--column-hr-wrapper">
                  <div *ngFor="let item of numberOfHrMarginTopValues(); let i = index"
                    class="residual-value-second-chart-bar__chart--column-hr-items">
                    <div [ngStyle]="{'margin-top': item}"
                      class="residual-value-second-chart-bar__chart--column-hr-item"></div>
                  </div>
                </div>
                <div class="residual-value-second-chart-bar__chart--column" [ngStyle]="{'background-color': '#F7F7F7'}">
                  <div class="residual-value-second-chart-bar__chart--column-title-wrapper">
                    <h3
                      [ngClass]="getModifiedClassName('residual-value-second-chart-bar__chart--column-title', totalValueCategory.itemNumber)"
                      [innerHTML]="totalValueCategory.title | translate">
                    </h3>
                  </div>

                  <div
                    [ngStyle]="{'height': getGraphicCanvasHeight()}"
                    class="residual-value-second-chart-bar__chart--column-pillar-wrapper"
                  >
                    <div
                      [ngStyle]="{
                        'height': getAmountForPillarInPixels(totalValueCategory.mainDataForChart.high, totalValueCategory.mainDataForChart.low),
                        'bottom': getBottomForPillarInPixels(totalValueCategory.mainDataForChart.high, totalValueCategory.mainDataForChart.low),
                        'min-height': isMaxMoreThanMinByOnePercent(totalValueCategory.mainDataForChart.high, totalValueCategory.mainDataForChart.low)
                          ? heightLimitForTwoCircles + 'px'
                          : heightLimitForOneCircle + 'px'
                       }"
                      [ngClass]="[
                        'residual-value-second-chart__value-wrapper',
                        'residual-value-second-chart-bar__chart--column-pillar-radius',
                        getModifiedClassName('residual-value-second-chart-bar__chart--column-pillar', totalValueCategory.itemNumber)
                      ]"
                    >
                      <div
                        [ngClass]="[
                          'residual-value-second-chart__high-value',
                          'residual-value-second-chart-bar__chart--column-pillar-circle',
                          getModifiedClassName('residual-value-second-chart-bar__chart--column-pillar-circle-up', totalValueCategory.itemNumber),
                          'residual-value-second-chart-bar__chart--column-pillar-circle-'+totalValueCategory.itemNumber
                        ]"
                      >
                        <p class="residual-value-second-chart-bar__chart--column-pillar-circle-value">
                          {{totalValueCategory.mainDataForChart.high | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}%
                        </p>
                      </div>
                      <div
                        [ngClass]="[
                          'residual-value-second-chart__low-value',
                          'residual-value-second-chart-bar__chart--column-pillar-circle',
                          getModifiedClassName('residual-value-second-chart-bar__chart--column-pillar-circle-up', totalValueCategory.itemNumber),
                          'residual-value-second-chart-bar__chart--column-pillar-circle-'+totalValueCategory.itemNumber
                        ]"
                      >
                        <p class="residual-value-second-chart-bar__chart--column-pillar-circle-value">
                          {{getAverageOrMinValue(totalValueCategory.mainDataForChart.high, totalValueCategory.mainDataForChart.low) | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}%
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="residual-value-second-chart__body--left-block-text">
                    <p>
                      <span class="residual-value-second-chart__body--left-block-text-value">
                        {{totalValueCategory.considered}} / {{totalValueCategory.totalDeposits}}
                      </span>
                      {{ 'deposits considered' | translate }}
                    </p>
                    <p>
                      <span class="residual-value-second-chart__body--left-block-text-value">
                        {{totalValueCategory.tonsOfWeight | floatingDigitNumberAfterComma: 'fr' | decimalSeparator:','}}
                      </span>
                      {{ 'tons of deposits in total' | translate }}
                    </p>
                    <div class="residual-value-second-chart__body--left-block-link">
                      <i class="icon blue-eye--icon"></i>
                      <a
                        class="residual-value-second-chart__body--left-block-link-item"
                        (click)="goToArchive()"
                      >
                        {{ 'See error report for more details' | translate }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div
      [ngClass]="{'residual-value-second-chart__right': !isPDFReport,'residual-value-second-chart__right_pdf': isPDFReport}">
      <div class="residual-value-second-chart-bar">
        <section class="residual-value-second-chart-bar__chart">
          <div class="residual-value-second-chart-bar__chart--wrapper">
            <div class="residual-value-second-chart-bar__chart--content">
              <div class="residual-value-second-chart-bar__chart--left-number-wrapper ">
                <div
                  *ngFor="let item of getVerticalAxisValues(); let i = index"
                  class="residual-value-second-chart-bar__chart--left-number"
                >
                  <p>{{item}}%</p>
                </div>
              </div>
              <div class="residual-value-second-chart-bar__chart--columns">
                <div class="residual-value-second-chart-bar__chart--column-hr-wrapper">
                  <div
                    *ngFor="let item of numberOfHrMarginTopValues(); let i = index"
                     class="residual-value-second-chart-bar__chart--column-hr-items"
                  >
                    <div
                      [ngStyle]="{'margin-top': item}"
                       class="residual-value-second-chart-bar__chart--column-hr-item"
                    >
                    </div>
                  </div>
                </div>
                <div
                  *ngFor="let item of getDataForChartColumns()"
                   class="residual-value-second-chart-bar__chart--column"
                >
                  <div class="residual-value-second-chart-bar__chart--column-title-wrapper">
                    <h3
                      [ngClass]="getModifiedClassName('residual-value-second-chart-bar__chart--column-title', item.itemNumber)"
                      [innerHTML]="item.title | translate">
                    </h3>
                  </div>

                  <div
                    [ngStyle]="{'height': getGraphicCanvasHeight()}"
                    class="residual-value-second-chart-bar__chart--column-pillar-wrapper"
                  >
                    <div
                      [ngStyle]="{
                        'height': getAmountForPillarInPixels(item.mainDataForChart.high, item.mainDataForChart.low),
                        'bottom': getBottomForPillarInPixels(item.mainDataForChart.high, item.mainDataForChart.low),
                        'min-height': isMaxMoreThanMinByOnePercent(item.mainDataForChart.high, item.mainDataForChart.low)
                          ? heightLimitForTwoCircles + 'px'
                          : heightLimitForOneCircle + 'px'
                       }"
                      [ngClass]="[
                        'residual-value-second-chart__value-wrapper',
                        'residual-value-second-chart-bar__chart--column-pillar-radius',
                        getModifiedClassName('residual-value-second-chart-bar__chart--column-pillar', item.itemNumber)
                      ]"
                    >
                      <div
                        [ngClass]="[
                          'residual-value-second-chart__high-value',
                          getModifiedClassName('residual-value-second-chart-bar__chart--column-pillar-circle-up', item.itemNumber),
                          'residual-value-second-chart-bar__chart--column-pillar-circle-'+item.itemNumber
                        ]"
                      >
                        <p class="residual-value-second-chart-bar__chart--column-pillar-circle-value">
                          {{item.mainDataForChart.high | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}%
                        </p>
                      </div>

                      <div
                        [ngClass]="[
                          'residual-value-second-chart__low-value',
                          'residual-value-second-chart-bar__chart--column-pillar-circle',
                          getModifiedClassName('residual-value-second-chart-bar__chart--column-pillar-circle-up', item.itemNumber),
                          'residual-value-second-chart-bar__chart--column-pillar-circle-'+item.itemNumber
                        ]"
                      >
                        <p class="residual-value-second-chart-bar__chart--column-pillar-circle-value">
                          {{getAverageOrMinValue(item.mainDataForChart.high, item.mainDataForChart.low) | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}%
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="residual-value-second-chart-bar__chart--column-footer">
                    <h4>{{item.categoryName | translate}}</h4>
                    <p>{{item.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}  {{ 'tons' | translate }}</p>
                    <p>{{item.percentageOfTotalWeight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}% {{ 'of the total weight' | translate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
