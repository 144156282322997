<div class="conversion-field-flex-container">
    <span [ngClass]="isDisabled ? 'conversion-field-title conversion-field-title--disabled' : 'conversion-field-title primary-color'">{{placeholder}}</span>

    <div class="conversion-field" *ngIf="!isDefaultUnits()">
        <div class="conversion-field-input"
        [ngClass]="{'conversion-field-input--disabled-by-toggle': isDisabled}">
            <md-input-container>
              <input
                [ngModel]="quantityInView"
                (ngModelChange)="onQuantityChange($event)"
                mdInput
                (input)="preventInputUI($event, quantityInView)"
                [disabled]="isDisabled"
                autocomplete="off">
            </md-input-container>
            <span class="conversion-field-input-suffix">{{ volumeUnitText | translate }}</span>
          </div>
          <convert-button [identifier]="identifier"
                          [isDisabled]="isDisabled || isConversionDisabled"
                          [setToggleColorActive]="setToggleColorActive"
                          (toggle)="activateToggleConversion($event)">
          </convert-button>
          <div class="conversion-field-input"
          [ngClass]="{'conversion-field-input--disabled-by-toggle': isDisabled}">
            <md-input-container >
              <input
                mdInput
                [ngModel]="quantityInTons"
                (ngModelChange)="onWeightChange($event)"
                (input)="preventInputUI($event, quantityInTons)"
                [disabled]="isDisabled"
                autocomplete="off">
            </md-input-container>
            <span class="conversion-field-input-suffix">{{ 'ton(s)' | translate}}</span>
          </div>
    </div>

    <div class="conversion-field" *ngIf="isDefaultUnits()">
        <div class="conversion-field-input">
            <md-input-container>
              <input
                [ngModel]="quantityInView"
                (ngModelChange)="onQuantityChange($event)"
                mdInput
                [disabled]="isDisabled"
                (input)="preventInputUI($event, quantityInView)"
                autocomplete="off">
            </md-input-container>
            <span class="conversion-field-input-suffix">{{ volumeUnitText | translate }}</span>
          </div>
    </div>

</div>
