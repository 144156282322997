<div *ngIf="reportData">
  <report-pdf [pageNumber]=1 [reportHeader]="reportHeader" *ngIf="showSitesInOnePage">
    <report-pdf-site-list [sites]="reportData.sites"></report-pdf-site-list>
    <div class="first-block__body">
      <div class="first-block__body--description">
        <p class="first-block__body--description-text"
           [innerHTML]="'DESCRIPTION_TEXT_FOR_HEAD_IN_MATERIAL_HEALTH_PDF' | translate"></p>
      </div>
    </div>
  </report-pdf>
  <report-pdf
    [pageNumber]="addTitlePagesNumber(-1)"
    [reportHeader]="reportHeader"
  >
    <report-pdf-site-list *ngIf="!showSitesInOnePage"
                          [sites]="reportData.sites"
                          [isLongVersion]="true"
                          [measureRows]="false"
                          [isGenerateFromFile]="reportData.created_by_file"
    ></report-pdf-site-list>
    <div *ngIf="!isEmptyProductCategory()" class="first-block">
      <div class="first-block__body">
        <div class="first-block__body--description">
          <p class="first-block__body--description-text"
             [innerHTML]="'DESCRIPTION_TEXT_FOR_HEAD_IN_MATERIAL_HEALTH_PDF' | translate">
          </p>
          <p class="first-block__body--description-text">
            {{'DESCRIPTION_TEXT_FOR_HEAD_IN_MATERIAL_HEALTH_II' | translate}}
          </p>
        </div>
        <div class="first-block__body--main-charts">
          <div class="first-block__body--main-charts-left-block">
            <div class="first-block__body--main-charts-left-block-i">
              <side-report-component
                [title]="'HEALTHY_PRODUCTS' | translate"
                [content]="'HEALTHY_PRODUCTS_DESCRIPTION_TEXT_PDF' | translate"
                [leafsToDisplay]="3"
                [showToggle]="false"
                [isVisible]="true">
              </side-report-component>
            </div>

            <div class="first-block__body--main-charts-left-block-ii">

              <side-report-component
                [title]="'PRODUCTS_WITH_CONTROLLED_AND_VERIFIED_TOXICITY_PDF' | translate"
                [content]="'PRODUCTS_WITH_CONTROLLED_AND_VERIFIED_TOXICITY_DESCRIPTION_TEXT_PDF' | translate"
                [leafsToDisplay]="2"
                [showToggle]="false"
                [isVisible]="true">
              </side-report-component>
            </div>
            <div class="first-block__body--main-charts-left-block-ii">
              <side-report-component
                [title]="'PRODUCTS_WITH_CONTROLLED_TOXICITY_PDF' | translate"
                [content]="'PRODUCTS_WITH_CONTROLLED_TOXICITY_DESCRIPTION_TEXT_PDF' | translate"
                [leafsToDisplay]="1"
                [showToggle]="false"
                [isVisible]="true">
              </side-report-component>
            </div>


            <side-report-component
            [title]="'PRODUCTS_WITH_UNKNOWN_TOXICITY' | translate"
            [content]="'PRODUCTS_WITH_UNKNOWN_TOXICITY_DESCRIPTION_TEXT' | translate"
            [leafsToDisplay]="4"
            [showToggle]="false"
            [isVisible]="true">
            </side-report-component>

            <side-report-component
              [title]="'PRODUCTS_WITH_KNOWN_TOXICITY' | translate"
              [content]="'PRODUCTS_WITH_KNOWN_TOXICITY_DESCRIPTION_TEXT' | translate"
              [leafsToDisplay]="0"
              [showToggle]="false"
              [isVisible]="true">
            </side-report-component>
            
           


          </div>

          <div class="first-block__body--main-charts-right-block">
            <div>
              <div class="first-block__body--main-charts-main-chart">
                <material-pie-chart [data]="mainChartData"></material-pie-chart>
              </div>
              <p class="first-block__body--main-charts-main-chart-paragraph">{{minDepositsConsidered}}
                / {{maxDepositsConsidered}} <span
                  [ngStyle]="{'color': '#000'}">{{ 'deposits considered' | translate }}</span></p>
              <p class="first-block__body--main-charts-main-chart-paragraph">
                {{tonsOfDeposits | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}} <span
                [ngStyle]="{'color': '#000'}">
              {{ 'tons of deposits in total' | translate }}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section *ngIf="isEmptyProductCategory()" class="section-error">
      <h3 class="section-error__title">{{ 'No deposit considered' | translate }}</h3>
    </section>
  </report-pdf>

    <report-pdf
      *ngFor="let table of categoriesTable; let i = index"
      [pageNumber]="addTitlePagesNumber(table.pageNumber)"
      [reportHeader]="reportHeader"
    >
      <div class="second-block">
        <section *ngIf="!isEmptyProductCategory()" class="second-block__footer">
          <div class="second-block__footer--pie-charts">
            <div class="second-block__footer--bottom-chart">
              <div class="second-block__footer--bottom-chart-header"
                   [innerHTML]="'MOST_IMPORTANT_PRODUCT_CATEGORY_BY_MASS' | translate"></div>
              <div class="second-block__footer-container-small">
                <material-pie-chart [data]="mostImportantProductChartData"></material-pie-chart>
              </div>
              <div class="second-block__footer--bottom-chart-bottom-text-i">
                {{mostImportantProductChartData.category.name | translate}}</div>
              <b>
                <div class="second-block__footer--bottom-chart-bottom-text-ii">
                  <span>{{mostImportantProductChartData.category.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}</span>
                  {{ 'tons' | translate }}</div>
              </b>
              <div class="second-block__footer--bottom-chart-bottom-text-ii">
                <span>{{mostImportantProductChartData.category.percentTotalWeight | number: numberFormat(mostImportantProductChartData.category.percentTotalWeight):'fr' | decimalSeparator:','}}</span>%
                {{ 'of the total weight' | translate }}</div>
            </div>
            <div class="second-block__footer--bottom-chart">
              <div class="second-block__footer--bottom-chart-header-green"
                   [innerHTML]="'HEALTHIEST_PRODUCT_CATEGORY' | translate"></div>
              <div class="second-block__footer-container-small">
                <material-pie-chart [data]="healthiestProductChartData"></material-pie-chart>
              </div>
              <div class="second-block__footer--bottom-chart-bottom-text-i">
                {{healthiestProductChartData.category.name | translate}}
              </div>
              <b>
                <div class="second-block__footer--bottom-chart-bottom-text-ii">
                  <span>{{healthiestProductChartData.category.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}</span>
                  {{ 'tons' | translate }}</div>
              </b>
              <div class="second-block__footer--bottom-chart-bottom-text-ii">
                <span>{{healthiestProductChartData.category.percentTotalWeight | number: numberFormat(healthiestProductChartData.category.percentTotalWeight):'fr' | decimalSeparator:','}}</span>%
                {{ 'of the total weight' | translate }}</div>
            </div>
            <div class="second-block__footer--bottom-chart">
              <div class="second-block__footer--bottom-chart-header-red"
                   [innerHTML]="'MOST_TOXIC_PRODUCT_CATEGORY' | translate"></div>
              <div class="second-block__footer-container-small">
                <material-pie-chart [data]="mostToxicProductChartData"></material-pie-chart>
              </div>
              <div class="second-block__footer--bottom-chart-bottom-text-i">
                {{mostToxicProductChartData.category.name | translate}}
              </div>
              <b>
                <div class="second-block__footer--bottom-chart-bottom-text-ii">
                  <span>{{mostToxicProductChartData.category.weight | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}</span>
                  {{ 'tons' | translate }}</div>
              </b>
              <div class="second-block__footer--bottom-chart-bottom-text-ii">
                <span>{{mostToxicProductChartData.category.percentTotalWeight | number: numberFormat(mostToxicProductChartData.category.percentTotalWeight):'fr' | decimalSeparator:','}}</span>%
                {{ 'of the total weight' | translate }}</div>
            </div>
          </div>
        </section>


        <div class="second-block__title--wrapper">
          <h2 class="second-block__title--item">{{'ALL_CATEGORIES' | translate}}</h2>
        </div>


        <div class="second-block__table">
          <div class="second-block__table--row second-block__table--row-default">
            <p class="second-block__table--row-title">{{'Category' | translate}}</p>
            <p class="second-block__table--row-title">{{'NUMBER_OF_DEPOSITS_CONSIDERED' | translate}}</p>
            <p class="second-block__table--row-title" [innerHTML]="'Weight (Tons)' | translate"></p>
            <p class="second-block__table--row-title">{{'PERCENTAGE_OF_TOTAL_WEIGHT' | translate}}</p>
            <p class="second-block__table--row-title">{{'PERCENTAGE_OF_HEALTHY_PRODUCTS' | translate}}</p>
            <p
              class="second-block__table--row-title">{{'PERCENTAGE_OF_PRODUCTS_WITH_VERIFIED_AND_CONTROLLED_TOXICITY' | translate}}</p>
            <p
              class="second-block__table--row-title">{{'PERCENTAGE_OF_PRODUCT_WITH_CONTROLLED_TOXICITY' | translate}}</p>
            <p
              class="second-block__table--row-title">{{'PERCENTAGE_OF_PRODUCTS_WITH_UNKNOWN_TOXICITY' | translate}}</p>
            <p
              class="second-block__table--row-title">{{'PERCENTAGE_OF_PRODUCTS_WITH_KNOWN_TOXICITY' | translate}}</p>
          </div>
          <div class="second-block__table--row-wrapper">
            <div class="second-block__table--row" *ngFor="let item of table.pageContent; let index = index;">
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['category'].name | translate}}
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['deposits_considered']}}
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['weight'] | floatingDigitNumberAfterComma:'fr' | decimalSeparator:','}}
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['category_mass_percentage'] | number:'1.1-1':'fr' | decimalSeparator:','}}%
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['percentage_of_healthy_product'] | number: '1.1-1':'fr' | decimalSeparator:','}}%
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['percentage_of_controlled_verified_toxicity'] | number: '1.1-1':'fr' | decimalSeparator:','}}%
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['percentage_of_controlled_toxicity'] | number: '1.1-1':'fr' | decimalSeparator:','}}%
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['percentage_of_unknown_toxicity'] | number: '1.1-1':'fr' | decimalSeparator:','}}%
              </p>
              <p
                [ngClass]="{'second-block__table--first-row-item': item['isDefault'],'second-block__table--row-item': true}">
                {{item['percentage_of_known_toxicity'] | number: '1.1-1':'fr' | decimalSeparator:','}}%
              </p>
            </div>
          </div>
        </div>
      </div>

    </report-pdf>

  <report-pdf
    *ngFor="let table of errorsTable; let i = index"

    [pageNumber]="addTitlePagesNumber(table.pageNumber)"
    [reportHeader]="reportHeader"
  >
    <div class="third-block">
      <div class="third-block__title--wrapper">
        <h2 class="third-block__title--item">
          {{'ERROR_REPORT' | translate}} (<span
          class="third-block__title--item-span">{{getAmountOf('errors')}} {{'ERRORS' | translate}}
          , {{getAmountOf('warnings')}} {{'WARNINGS' | translate}}</span>)
        </h2>
      </div>
      <div class="third-block__body">
        <div class="third-block__body--description">
          <div class="third-block__body--description">
            <p [innerHTML]="'ERROR_REPORT_DESCRIPTION_INTRO' | translate"></p>
            <br>
            <p><i class="icon--admin-warning icon"></i>{{'ERROR_REPORT_DESCRIPTION_WARNING' | translate}}</p>
            <br>
            <p><i class="icon--admin-error icon"></i>{{'ERROR_REPORT_DESCRIPTION_ERROR' | translate}}</p>
            <br>
            <p [innerHTML]="'ERROR_REPORT_DESCRIPTION_OUTRO' | translate"></p>
          </div>
        </div>
        <div *ngIf="reportData.is_asynchronous_generated" class="third-block__body--table">
          <div class="third-block__body--table-row">
            <div class="third-block__body--table-row-title">
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'NUMBER_DEPOSITS_CONSIDERED' | translate}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'Error explanation' | translate}}</p>
            </div>
          </div>
          <div *ngFor="let item of table.pageContent; let index = index" class="third-block__body--table-row">
            <div class="third-block__body--table-row-item">
              <i [ngClass]="[isError(item['type']) ? 'icon--admin-error' : 'icon--admin-warning', 'icon']"></i>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{item.counter}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{getErrorMessage(item)}}</p>
            </div>
          </div>
        </div>
        
        <div *ngIf="!reportData.is_asynchronous_generated" class="third-block__body--table">
          <div class="third-block__body--table-row">
            <div class="third-block__body--table-row-title">
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'Deposit name' | translate}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-title">
              <p>{{'Error explanation' | translate}}</p>
            </div>
          </div>
          <div *ngFor="let item of table.pageContent; let index = index" class="third-block__body--table-row">
            <div class="third-block__body--table-row-item">
              <i [ngClass]="[isError(item['type']) ? 'icon--admin-error' : 'icon--admin-warning', 'icon']"></i>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{item.deposit_name}}</p>
            </div>
            <span class="third-block__body--table-row-item-divider"></span>
            <div class="third-block__body--table-row-item">
              <p>{{getErrorMessage(item)}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </report-pdf>
</div>