import { arch } from 'os';
/**
 * Created by Aleksandr C. on 8.02.17.
 */

import { ServiceLocator } from '../services/service-locator';
import { ICrud } from '../interfaces/crud.interface';
import { Observable } from 'rxjs/Observable';
import { EventEmitter } from '@angular/core';

export abstract class ActiveRecord {

  protected service: ICrud;
  public abstract id: number;
  protected abstract provider: any;

  protected abstract fields(): string[];

  public loaded: boolean = false;

  beforeSave$: EventEmitter<any> = new EventEmitter();
  afterSave$: EventEmitter<any> = new EventEmitter();

  constructor(data?: any) {
    if (data) {
      this.set(data);
    }
  }

  set(data: any) {
    try {
      //Object.assign(this, this.extractData(typeof data === 'string' ? JSON.parse(data) : data));
      let dataValues = this.extractData(typeof data === 'string' ? JSON.parse(data) : data);
      Object.keys(dataValues).forEach((key) => {
        (<any>this)[key] = dataValues[key];
      });
    } catch (e) {
      console.error(e);
    }
    return this;
  }

  build() {
    if (!this.service) {
      if (!this.provider) {
        throw new Error('Provider not found');
      }
      this.service = ServiceLocator.injector.get(this.provider);
    }
    return this;
  }

  load<T>(query?: any): Observable<any | T> {
    this.build();
    if (this.id && this.id > 0) {
      return this.service.view(this.id, query);
    } else {
      throw new Error('Id is required');
    }
  }

  save(): Observable<any> {
    this.build();
    this.beforeSave$.emit(this);
    if (this.id && this.id > 0) {
      return this.service.update(this.id, this.beforeSave(this.extractData(this))).map((res) => {
        this.afterSave$.emit(this);
        return res;
      });
    } else {
      return this.service.create(this.beforeSave(this.extractData(this))).map((res) => {
        this.afterSave$.emit(this);
        return res;
      });
    }
  }

  destroy(): Observable<any> {
    this.build();
    if (this.id && this.id > 0) {
      return this.service.remove(this.id);
    } else {
      throw new Error('Id is required');
    }
  }

  duplicate() {
    this.build();
    if (this.id && this.id > 0) {
      return this.service.duplicate(this.id);
    } else {
      throw new Error('Id is required');
    }
  }

  archive() {
    this.build();
    if (this.id && this.id > 0) {
      return this.service.archive(this.id);
    } else {
      throw new Error('Id is required');
    }
  }

  extractData(data: any): any {
    let json: any = {};
    this.fields().forEach((field) => {
      if (data.hasOwnProperty(field)) {
        json[field] = data[field];
      }
    });
    return json;
  }

  toJSON(): any {
    return this.extractData(<any>this);
  }

  beforeSave(data: any): any {
    return data;
  }
}
