/**
 * Created by aleksandr on 31.03.17.
 */
import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { AGENT_NAVIGATION } from './agent.navigation';
import { ERoleIds } from '../../shared/values/role-ids.enum';

export const AGENT_CONTEXT: IRoleContextConfig = {
  navigation: AGENT_NAVIGATION,
  moduleName: 'agent',
  themeClass: 'b-module-deposits',
  roleId: ERoleIds.AGENT,
  roleText: 'Agent',
  homeUrl: '/agent',
  apiModuleName: 'agent',
  rootPath: 'agent'
};

