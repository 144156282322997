import * as tslib_1 from "tslib";
import { ApiService } from '../../../../shared/services/api.service';
var CalculationVariablesService = /** @class */ (function (_super) {
    tslib_1.__extends(CalculationVariablesService, _super);
    function CalculationVariablesService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'calculation-variables';
        _this.namespaceSingular = 'calculation-variables';
        _this.ModelClass = '';
        return _this;
    }
    CalculationVariablesService.prototype.getCalculationVariables = function () {
        return this.sendGet(this.getEndpoint(this.namespaceSingular))
            .map(function (data) { return data.json(); })
            .catch(this.onError.bind(this));
    };
    CalculationVariablesService.prototype.updateCalculationVariables = function (calculationVariables) {
        return this.sendPost(this.getEndpoint(this.namespaceSingular + '/edit-all-variables'), calculationVariables)
            .map(function (response) { return response.json(); })
            .catch(this.onError.bind(this));
    };
    return CalculationVariablesService;
}(ApiService));
export { CalculationVariablesService };
