import { ElementRef, EventEmitter } from '@angular/core';
import { NavigationService } from '../../../../../../shared/services/navigation.service';
/**
 * Provides a component for filtering items based on various rating criteria.
 * This component allows users to select specific ratings to filter items,
 * such as passports, based on their environmental and social impact ratings.
 */
var NewRatingFilterComponent = /** @class */ (function () {
    function NewRatingFilterComponent(_navigation) {
        this._navigation = _navigation;
        // Tracks whether the filter popup is currently open.
        this.isOpen = false;
        // Model representing the current state of rating filters selected by the user.
        this.filterModel = {
            passportWithoutRating: false,
            selectRatingFilter: false,
            C2cCertified: false,
            composition: { color: false, black: false },
            cycle: { color: false, black: false },
            energy: { color: false, black: false },
            water: { color: false, black: false },
            social: { color: false, black: false },
        };
        // Emits the current filter model state when any changes occur.
        this.ratingChange = new EventEmitter();
        // Ensures that the "Select All" checkbox is correctly set on initialization.
        this.updateSelectAllRatings();
    }
    Object.defineProperty(NewRatingFilterComponent.prototype, "setFilterModel", {
        // Allows external entities to set the filter model, such as when resetting or initializing.
        set: function (filterModel) {
            this.filterModel = filterModel;
            this.updateSelectAllRatings();
        },
        enumerable: true,
        configurable: true
    });
    // Toggles the visibility of the filter dropdown.
    NewRatingFilterComponent.prototype.toggleView = function () {
        this.isOpen = !this.isOpen;
    };
    /**
     * Handles changes to individual filters, updating the model and emitting the change.
     * If a key and subKey are provided, it toggles the value for that specific filter.
     * This method also checks if all ratings are selected after any change.
     */
    NewRatingFilterComponent.prototype.onFilterChange = function (key, subKey) {
        var value = subKey ? !this.filterModel[key][subKey] : !this.filterModel[key];
        if (key) {
            if (subKey) {
                this.filterModel[key][subKey] = value;
            }
            else {
                this.filterModel[key] = value;
            }
        }
        this.updateSelectAllRatings();
        this.ratingChange.emit(this.filterModel);
    };
    /**
     * Toggles the "Select All" filter, setting all rating filters to the same value.
     * It then calls `onFilterChange` to handle the update and emit the change.
     */
    NewRatingFilterComponent.prototype.onSelectAllRatingsChange = function () {
        var _this = this;
        var newValue = !this.filterModel.selectRatingFilter;
        Object.keys(this.filterModel).forEach(function (key) {
            if (typeof _this.filterModel[key] === 'object' && key !== 'C2cCertified') {
                Object.keys(_this.filterModel[key]).forEach(function (subKey) {
                    _this.filterModel[key][subKey] = newValue;
                });
            }
        });
        this.filterModel.selectRatingFilter = newValue;
        this.onFilterChange();
    };
    /**
     * Updates the `selectRatingFilter` property based on whether all rating filters are selected.
     * This ensures the "Select All" checkbox reflects the actual state of individual selections.
     */
    NewRatingFilterComponent.prototype.updateSelectAllRatings = function () {
        var _this = this;
        var allSelected = Object.keys(this.filterModel)
            .filter(function (key) { return typeof _this.filterModel[key] === 'object' && key !== 'C2cCertified'; })
            .every(function (key) { return Object.values(_this.filterModel[key]).every(function (value) { return value === true; }); });
        this.filterModel.selectRatingFilter = allSelected;
    };
    NewRatingFilterComponent.prototype.removeRating = function (rating, type) {
        if (type) {
            this.filterModel[rating][type] = false;
        }
        else {
            this.filterModel[rating] = false;
        }
        this.ratingChange.emit(this.filterModel);
        this.updateSelectAllRatings();
    };
    NewRatingFilterComponent.prototype.removeC2cCertified = function () {
        this.filterModel.C2cCertified = false;
        this.ratingChange.emit(this.filterModel);
    };
    /**
     * Closes the filter dropdown if a click occurs outside the filter element.
     * This provides a better user experience by allowing users to click away to close.
     */
    NewRatingFilterComponent.prototype.onDocumentClick = function (event) {
        var targetElement = event.target;
        if (this.isOpen && !this.filterWrapper.nativeElement.contains(targetElement)) {
            this.isOpen = false;
        }
    };
    /**
 * Determines the CSS class for coloring pagination based on the user's role.
 *
 * @returns The CSS class corresponding to the user's role.
 */
    NewRatingFilterComponent.prototype.getClassColor = function () {
        if (!this._navigation || !this._navigation.getContext())
            return 'role-guest';
        var role = this._navigation.getContext().roleText;
        switch (role) {
            case 'Agent':
                return 'role-agent';
            case 'Manufacturer':
                return 'role-manufacturer';
            case 'Specialadmin':
                return 'role-specialadmin';
            default:
                return '';
        }
    };
    return NewRatingFilterComponent;
}());
export { NewRatingFilterComponent };
