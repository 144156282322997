import { Component, ViewChild, AfterViewInit, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { TResidualValueReport } from '../../types/residual-value-report.type';
import { IReportResponse } from '../../../../../../interfaces/reportResponse.interface';
import { IResidualValueAggregate } from '../../types/residual-value.interface';
import { MAIN_TAB_STEPS } from '../../../../admin-reporting.const';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import { ReportBase } from '../../../report-base/report-base.class';

@Component({
  selector: 'residual-value-first-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './residual-value-first-chart.component.html',
  styleUrls: ['./residual-value-first-chart.component.scss']
})
export class ResidualValueFirstChartComponent extends ReportBase implements AfterViewInit {
  @ViewChild('containerChart', { read: false }) lineChartRefEl: ElementRef;

  PROFIT_ORIENTED: { x: number; y: number; }[];
  ENVIRONMENT_ORIENTED: { x: number; y: number; }[];
  svg: d3.Selection<SVGGElement, any, HTMLElement, any>;
  margin: { top: number; right: number; bottom: number; left: number; };
  @Input() type: TResidualValueReport;
  @Input() report: IReportResponse;
  @Input() changeTab: Function;

  @Input() width = 750;
  @Input() height = 500;
  @Input() isPDFReport: boolean = false;
  xAxis: any;
  yAxis: any;
  y: any;
  x: any;
  line: any;
  minY: number;
  maxY: number;
  valueForTransformation: number = 100;
  formatter: Function;
  public filteredProductCategory: object = {};

  constructor(private translateService: TranslateService) {super(); }

  ngAfterViewInit() {
    this.setup();
    if (!this.isEmptyProductCategory() || this.isPDFReport) {
      this.initializeSVG();
      this.drawnChart();
    }
  }
  get data(): IResidualValueAggregate {
    let categories = null;
    if (this.report.report_result) {
      categories = this.report.report_result.financial_residual_value[this.type].aggregate;
    } else {
      categories = this.report[this.type].aggregate;
    }

    return categories.hasOwnProperty('deposit_considered')
      ? categories['deposit_considered'] ? this.filteredProductCategory = categories : {}
      : null;
  }

  isEmptyProductCategory(): boolean {
    return Object.keys(this.filteredProductCategory).length === 0;
  }

  getRoundValue(value: number): number {
    return Math.round( value * 10 ) / 10;
  }

  setup() {
    this.margin = { top: 50, right: 0, bottom: 100, left: 40 };
    this.width = this.width - this.margin.left - this.margin.right;
    this.height = this.height - this.margin.top - this.margin.bottom;
    let locale = d3.formatLocale({
      decimal: ",",
      thousands: "\u00a0",
      percent: "\u202f%"
    });
    this.formatter = locale.format(",.1%");

    if (this.data) {
      this.PROFIT_ORIENTED =
        [{
          y: this.data.total_financial_recovery_percentage_down_if_economic / this.valueForTransformation,
          x: this.data.total_material_recovery_percentage_down_if_economic / this.valueForTransformation
        },
          {
            y: this.data.total_financial_recovery_percentage_up_if_economic / this.valueForTransformation,
            x: this.data.total_material_recovery_percentage_up_if_economic / this.valueForTransformation
          }];

      this.ENVIRONMENT_ORIENTED =
        [{
          y: this.data.total_financial_recovery_percentage_down / this.valueForTransformation,
          x: this.data.total_material_recovery_percentage_down / this.valueForTransformation
        },
          {
            y: this.data.total_financial_recovery_percentage_up / this.valueForTransformation,
            x: this.data.total_material_recovery_percentage_up / this.valueForTransformation
          }];
    } else {
      this.PROFIT_ORIENTED =
        [{
          y: 0,
          x: 0
        },
          {
            y: 0,
            x: 0
          }];

      this.ENVIRONMENT_ORIENTED =
        [{
          y: 0,
          x: 0
        },
          {
            y: 0,
            x: 0
          }];
    }

    const minX = [...this.PROFIT_ORIENTED, ...this.ENVIRONMENT_ORIENTED].sort((a, b) => a.x > b.x ? 1 : -1)[0].x;
    const maxX = [...this.PROFIT_ORIENTED, ...this.ENVIRONMENT_ORIENTED].sort((a, b) => a.x < b.x ? 1 : -1)[0].x;

    this.maxY = [...this.PROFIT_ORIENTED, ...this.ENVIRONMENT_ORIENTED].sort((a, b) => a.y < b.y ? 1 : -1)[0].y;
    this.minY = [...this.PROFIT_ORIENTED, ...this.ENVIRONMENT_ORIENTED].sort((a, b) => a.y > b.y ? 1 : -1)[0].y;

    // set up the x scale
    this.x = d3.scaleLinear()
      .domain([minX - 0.01, maxX + 0.01])
      .range([0, this.width]);

    if (this.minY < 0 && -this.minY < this.maxY) {
      this.minY = -this.maxY;
    }

    // set up the y scale
    this.y = d3.scaleLinear()
      .domain([this.getScaleLinearMinY(this.minY), this.getScaleLinearMaxY(this.maxY)])
      .range([this.height, 0]); // actual length of axis
  }

  getScaleLinearMinY(number: number): number {
    return number > 0
      ? Math.floor(((number) * this.valueForTransformation)) / this.valueForTransformation
      : Math.ceil(((number - 0.01) * this.valueForTransformation)) / this.valueForTransformation;
  }

  getScaleLinearMaxY(number: number): number {
    return number > 0
      ? Math.floor(((number + 0.01) * this.valueForTransformation)) / this.valueForTransformation
      : Math.ceil(((number + 0.01) * this.valueForTransformation)) / this.valueForTransformation;
  }

  initializeSVG() {
    this.line = d3.line()
      .x((d: any) => this.x(d.x))
      .y((d: any) => this.y(d.y));

    const formatPercent = d3.format('.0%');

    this.xAxis = d3.axisBottom(this.x)
      .ticks(this.getNumberOfIntBetweenTwoValues())
      .tickFormat(formatPercent);

    this.yAxis = d3.axisLeft(this.y)
      .ticks(this.getNumberOfIntBetweenTwoValues())
      .tickFormat(formatPercent);

    const svg = d3.select(this.lineChartRefEl.nativeElement).append('svg')
      .attr('width', this.width + 2 * this.margin.left)
      .attr('height', this.height + 2 * this.margin.top);

    this.svg = svg.append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  getPositionOnAxisX(): number {
    if (this.minY > 0) {
      return this.height;
    } else {
      return (this.height / this.getNumberOfIntBetweenTwoValues()) * (this.getScaleLinearMaxY(this.maxY) * this.valueForTransformation);
    }
  }

  getNumberOfIntBetweenTwoValues(): number {
    const min = this.getScaleLinearMinY(this.minY) * this.valueForTransformation;
    const max = this.getScaleLinearMaxY(this.maxY) * this.valueForTransformation;
    return (_.range(min, max)).length;
  }

  drawnChart() {
    //Y axis
    this.svg.append('g')
      .attr('class', 'axis')
      .call(this.yAxis);
    // Y guidelines
    this.svg.append('g')
      .attr('class', 'grid')
      .call(this.yAxis
        .tickSize(-this.width)
        .tickFormat('')
      );
    //X axis
    this.svg.append('g')
      .attr('class', 'axis')
      .attr('transform', 'translate(0,' + this.getPositionOnAxisX() + ')')
      .call(this.xAxis);
    //X guidelines
    this.svg.append('g')
      .attr('class', 'grid')
      .call(this.xAxis
        .tickSize(this.height)
        .tickFormat('')
      );

    this.svg.append('path')
      .attr('class', 'purple-line')
      .datum(this.PROFIT_ORIENTED)
      .attr('d', this.line);

    this.svg.append('path')
      .attr('class', 'green-line')
      .datum(this.ENVIRONMENT_ORIENTED)
      .attr('d', this.line);

    this.svg.append('circle')
      .attr('class', 'purple-circle')
      .datum(this.PROFIT_ORIENTED)
      .attr('cx', d => this.x(d[0].x))
      .attr('cy', d => this.y(d[0].y))
      .attr('r', 30);

    this.svg.append('circle')
      .attr('class', 'purple-circle')
      .datum(this.PROFIT_ORIENTED)
      .attr('cx', d => this.x(d[1].x))
      .attr('cy', d => this.y(d[1].y))
      .attr('r', 30);

    this.svg.append('text')
      .datum(this.PROFIT_ORIENTED)
      .attr('x', d => this.x(d[0].x) - this.centerValueLabel((d[0].y * this.valueForTransformation).toFixed(1)))
      .attr('y', d => this.y(d[0].y) + 5)
      .text(d => this.formatter(d[0].y));

    this.svg.append('text')
      .datum(this.PROFIT_ORIENTED)
      .attr('x', d => this.x(d[1].x) - this.centerValueLabel((d[1].y * this.valueForTransformation).toFixed(1)))
      .attr('y', d => this.y(d[1].y) + 5)
      .text(d => this.formatter(d[1].y));

    this.svg.append('circle')
      .attr('class', 'green-circle')
      .datum(this.ENVIRONMENT_ORIENTED)
      .attr('cx', d => this.x(d[0].x))
      .attr('cy', d => this.y(d[0].y))
      .attr('r', 30);

    this.svg.append('circle')
      .attr('class', 'green-circle')
      .datum(this.ENVIRONMENT_ORIENTED)
      .attr('cx', d => this.x(d[1].x))
      .attr('cy', d => this.y(d[1].y))
      .attr('r', 30);

    this.svg.append('text')
      .datum(this.ENVIRONMENT_ORIENTED)
      .attr('x', d => this.x(d[0].x) - this.centerValueLabel((d[0].y * this.valueForTransformation).toFixed(1)))
      .attr('y', d => this.y(d[0].y) + 5)
      .text(d => this.formatter(d[0].y));

    this.svg.append('text')
      .datum(this.ENVIRONMENT_ORIENTED)
      .attr('x', d => this.x(d[1].x) - this.centerValueLabel((d[1].y * this.valueForTransformation).toFixed(1)))
      .attr('y', d => this.y(d[1].y) + 5)
      .text(d => this.formatter(d[1].y));

    //APPEND ARC TEXTS
    this.svg.append('path')
      .attr('id', 'profit_low_hypothesis_arc' + this.data.total_financial_recovery_percentage_down + this.data.total_material_recovery_percentage_down)
      .datum(this.PROFIT_ORIENTED)
      .attr('d', d => `M ${this.x(d[0].x) - 30 - 8},${this.y(d[0].y)} A 30,30 0 0,1 ${this.x(d[0].x) + 30 + 8},${this.y(d[0].y)}`)
      .style('fill', 'none')
      .style('stroke-opacity', '0');

    this.svg.append('text')
      .append('textPath')
      .attr('class', 'arcText')
      .attr('xlink:href', '#profit_low_hypothesis_arc' + this.data.total_financial_recovery_percentage_down + this.data.total_material_recovery_percentage_down)
      .attr('startOffset', '50%')
      .text(this.translateService.instant('Minimum'));

    this.svg.append('path')
      .attr('id', 'profit_high_hypothesis_arc' + this.data.total_financial_recovery_percentage_down + this.data.total_material_recovery_percentage_down)
      .datum(this.PROFIT_ORIENTED)
      .attr('d', d => `M ${this.x(d[1].x) - 30 - 8},${this.y(d[1].y)} A 30,30 0 0,1 ${this.x(d[1].x) + 30 + 8},${this.y(d[1].y)}`)
      .style('fill', 'none')
      .style('stroke-opacity', '0');

    this.svg.append('text')
      .append('textPath')
      .attr('class', 'arcText')
      .attr('xlink:href', '#profit_high_hypothesis_arc' + this.data.total_financial_recovery_percentage_down + this.data.total_material_recovery_percentage_down)
      .attr('startOffset', '50%')
      .text(this.translateService.instant('Maximum'));

    this.svg.append('path')
      .attr('id', 'environment_low_hypothesis_arc' + this.data.total_financial_recovery_percentage_down + this.data.total_material_recovery_percentage_down)
      .datum(this.ENVIRONMENT_ORIENTED)
      .attr('d', d => `M ${this.x(d[0].x) - 30 - 8},${this.y(d[0].y)} A 30,30 0 0,1 ${this.x(d[0].x) + 30 + 8},${this.y(d[0].y)}`)
      .style('fill', 'none')
      .style('stroke-opacity', '0');

    this.svg.append('text')
      .attr('class', 'arcText')
      .append('textPath')
      .attr('xlink:href', '#environment_low_hypothesis_arc' + this.data.total_financial_recovery_percentage_down + this.data.total_material_recovery_percentage_down)
      .attr('startOffset', '50%')
      .text(this.translateService.instant('Minimum'));

    this.svg.append('path')
      .attr('id', 'environment_high_hypothesis_arc' + this.data.total_financial_recovery_percentage_down + this.data.total_material_recovery_percentage_down)
      .datum(this.ENVIRONMENT_ORIENTED)
      .attr('d', d => `M ${this.x(d[1].x) - 30 - 8},${this.y(d[1].y)} A 30,30 0 0,1 ${this.x(d[1].x) + 30 + 8},${this.y(d[1].y)}`)
      .style('fill', 'none')
      .style('stroke-opacity', '0');

    this.svg.append('text')
      .append('textPath')
      .attr('class', 'arcText')
      .attr('xlink:href', '#environment_high_hypothesis_arc' + this.data.total_financial_recovery_percentage_down + this.data.total_material_recovery_percentage_down)
      .attr('startOffset', '50%')
      .text(this.translateService.instant('Maximum'));
  }

  centerValueLabel(label: string): number {
    if(label.length > 4) {
      return 24
    } else if (label.length > 3) {
      return 18
    } else if (label.length > 1) {
      return 15;
    } else {
      return 10;
    }
  }
  goToArchive() {
    this.changeTab(MAIN_TAB_STEPS.ARCHIVE);
  }
}
