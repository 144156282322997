import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { TransformLocation } from '../models/transform-location.class';
var TransformLocationService = /** @class */ (function (_super) {
    tslib_1.__extends(TransformLocationService, _super);
    function TransformLocationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'transformation-locations';
        _this.namespaceSingular = 'transformation-location';
        _this.ModelClass = TransformLocation;
        return _this;
    }
    return TransformLocationService;
}(CrudService));
export { TransformLocationService };
