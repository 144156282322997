import { OnInit } from '@angular/core';
import { EOrderTransactionConfirmResultFieldType } from './order-transaction-confirm-result-field-type.enum';
import { PASSPORT_UNITS_MAP, PASSPORT_UNITS_TYPE } from '../../../entities/passport/values/passport-units.const';
import { ControlContainer, ControlValueAccessor, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { lowerCase } from 'lodash';
import { OrderPassport } from '../../../dashboards/ecosystem-manager/models/order-passport.class';
import moment from 'moment';
var OrderTransactionConfirmResultFieldComponent = /** @class */ (function () {
    function OrderTransactionConfirmResultFieldComponent(controlContainer, translationService) {
        this.controlContainer = controlContainer;
        this.translationService = translationService;
        this.PASSPORT_UNIT_MAP = PASSPORT_UNITS_MAP;
        this.DEFAULT_CONVERSION = 1;
        this.FIELD_TYPE = EOrderTransactionConfirmResultFieldType;
        this.conversionFactor = 0;
        this.conversionUnit = PASSPORT_UNITS_TYPE.KG;
        this.disabled = false;
    }
    Object.defineProperty(OrderTransactionConfirmResultFieldComponent.prototype, "placeholder", {
        set: function (placeholder) {
            this.placeholderExpected = this.getPlaceholderResultText(placeholder, false);
            this.placeholderConfirmed = this.getPlaceholderResultText(placeholder, true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderTransactionConfirmResultFieldComponent.prototype, "setConversionUnit", {
        set: function (unit) {
            this.conversionUnit = unit || PASSPORT_UNITS_TYPE.KG;
        },
        enumerable: true,
        configurable: true
    });
    OrderTransactionConfirmResultFieldComponent.prototype.ngOnInit = function () {
        this.control = this.controlContainer.control.get(this.formControlName);
    };
    OrderTransactionConfirmResultFieldComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    OrderTransactionConfirmResultFieldComponent.prototype.registerOnTouched = function (fn) {
        this.onTouch = fn;
    };
    OrderTransactionConfirmResultFieldComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    OrderTransactionConfirmResultFieldComponent.prototype.writeValue = function (obj) {
    };
    OrderTransactionConfirmResultFieldComponent.prototype.getPlaceholderResultText = function (name, isConfirmed) {
        var confirmText = lowerCase(this.translationService.instant(isConfirmed ? 'Confirmed' : 'expected'));
        return this.translationService.instant(name) + " (" + confirmText + ")";
    };
    OrderTransactionConfirmResultFieldComponent.prototype.changed = function (event) {
        this.onTouch();
        this.onChange(event);
    };
    OrderTransactionConfirmResultFieldComponent.prototype.changeDate = function (event) {
        this.control.setValue(moment(event).format('DD-MM-YYYY'));
    };
    OrderTransactionConfirmResultFieldComponent.prototype.autofillButtonClick = function () {
        this.onChange(this.defaultValue);
    };
    return OrderTransactionConfirmResultFieldComponent;
}());
export { OrderTransactionConfirmResultFieldComponent };
