<md-tab-group class="transform-edit__wrapper tab-group-large" [selectedIndex]="step">
  <md-tab label="{{'Type of transformation' | translate}}" disabled>
    <div class="transform-edit__content">

      <md-radio-group [(ngModel)]="type" class="transform-edit__list radio-button-background">
        <md-radio-button
          class="transform-edit__item"
          *ngFor="let TYPE of TRANSFORM_TYPES"
          [value]="TYPE.value">
          <img [src]="TYPE.iconUrl" [ngClass]="getImageClass()">
          <div class="transform-edit__item-label">{{getTypeView(TYPE.view)}}</div>
        </md-radio-button>
      </md-radio-group>

      <button class="transform-edit__next-step-button"
              md-raised-button
              [disabled]="!type"
              (click)="goToProcessStep()">
        {{'Next step' | translate}}
      </button>
    </div>
  </md-tab>

  <md-tab label="{{'Process' | translate}}" disabled>
    <transform-details
      *ngIf="step === 1"
      [transform]="transformation"
      (action)="onAction()">
    </transform-details>
  </md-tab>
</md-tab-group>
