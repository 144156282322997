/**
 * Created by atat on 18/06/2017.
 */

 import { Component, OnInit } from '@angular/core';
 import {
   ECustomListHintClass,
   ECustomListRecordStatus,
   ICustomListRecord
 } from '../../../../shared/custom-list/interfaces/custom-list.interface';
 import { CustomListRecord } from '../../../../shared/custom-list/custom-list-record.model';
 import { DepositOrderService } from '../../../ecosystem-manager/services/deposit-order.service';
 import { DepositOrder } from '../../../ecosystem-manager/models/deposit-order.class';
 import { MdDialog } from '@angular/material';
 import { DepositOrderDetailsDialogComponent } from '../../dialogs/deposit-order-details-dialog/deposit-order-details-dialog.component';
 import { Searchable } from '../../../../shared/models/searchable.class';
 import { DatePipe } from '@angular/common';
 import { TRANSFORM_ORDER_STATUS } from '../../../transformation-manager/values/transform-order-statuses.const';
 import { FormControl } from '@angular/forms';
 import * as moment from 'moment';
 import { TranslateService } from '@ngx-translate/core';
 import { GlobalEventsManager } from '../../../../shared/services/global-events-manager.service';
 import {
   DepositOrderTransactionConfirmDialogComponent
 } from '../../dialogs/deposit-order-transaction-confirm-dialog/deposit-order-transaction-confirm-dialog.component';
 import {DIALOG_THEME} from '../../../../shared/helpers/dialog-themes.const';
 import {ReportProblemDialogComponent} from '../../../../shared/report-problem-dialog/report-problem-dialog.component';
import {IReportProblemDialogInput} from '../../../../shared/report-problem-dialog/values/report-problem-dialog-input.interface';
import {NotificationSuccessComponent} from '../../../../shared/notification-dialogs/notification-success/notification-success.component';

 @Component({
   moduleId: module.id,
   selector: 'do-orders-catalog',
   templateUrl: 'do-orders-catalog.component.html'
 })
 export class DoOrdersCatalogComponent implements OnInit {
   records: ICustomListRecord[] = [];
 
   filterStatuses: { value: TRANSFORM_ORDER_STATUS, view: string, text: string, checked: boolean }[] = [
     {value: TRANSFORM_ORDER_STATUS.WAITING_FOR_VALIDATION, view: 'Waiting for validation',text: 'WAITING_FOR_VALIDATION', checked: true},
     {value: TRANSFORM_ORDER_STATUS.VALIDATED, view: 'Validated', text: 'VALIDATED', checked: true},
     {value: TRANSFORM_ORDER_STATUS.ASK_FOR_MODIFICATION, view: 'Asked for modification', text: 'ASK_FOR_MODIFICATION', checked: false},
     {value: TRANSFORM_ORDER_STATUS.PENDING, view: 'Booked', text: 'PENDING', checked: false},
     {value: TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION, view: 'Waiting for confirmation', text: 'WAITING_FOR_CONFIRMATION', checked: true},
   ];
 
   sortOptions: any = [
     { field: 'pickup_date', view: 'Pickup date'},
     { field: 'publication_date', view: 'Days since reception'},
     { field: 'esm_name', view: 'Ecosystem manager'},
     { field: 'name', view: 'Name'},
   ];
   sortValue: string = '-created_at';
 
   //Filters
   ecosystemManagerList: Searchable[];
   ecosystemManager: number;
   search = new FormControl();
   currentPage: number = 1;
   totalPagesCount: number = 0;
   isNextPageLoading: boolean = false;
 
   _daysStr: string = '';
 
   constructor(private _orderService: DepositOrderService,
               private _translate: TranslateService,
               private mdDialog: MdDialog,
               private _globalEvents: GlobalEventsManager,
               private datePipe: DatePipe,) {
     this
       .search
       .valueChanges
       .debounceTime(400)
       .distinctUntilChanged()
       .subscribe(() => this.getRecords())
     ;
 
     this._translate.get('Days')
       .subscribe((text) => {
         this._daysStr = String(text).toLowerCase() || text;
       });
   }
 
   getRecords(forPage: number = 0) {
     if (!this.filterStatuses.find((status) => status.checked)) {
       this.records.length = 0;
     }else {
       let options = {
         expand: 'deposit,creator,deposit_passport,order_passport,ecosystem.ownerField',
         supervisor: 1,
         deposit_owner: true,
         esm: this.ecosystemManager || null,
         sort: this.sortValue || null,
         search: this.search.value || null,
         'status[]': this.filterStatuses
           .filter(status => status.checked)
           .map(status => status.value),
         page: forPage,
       };
       this.isNextPageLoading = true;
       this._orderService
         .getWithPaginationData(options)
         .subscribe((res) => {
           if (forPage === 0) {
             this.records.length = 0;
           }
           let headers = res.headers;
           this.currentPage = Number(headers.get('X-Pagination-Current-Page'));
           this.totalPagesCount = Number(headers.get('X-Pagination-Page-Count'));
           res.json().map((item: any) => this.addRecord(new DepositOrder(item)));
           this.isNextPageLoading = false;
         });
     }
   }
 
   addRecord(data: DepositOrder) {
 
     let showValidationButtons = data.status === TRANSFORM_ORDER_STATUS.WAITING_FOR_VALIDATION;
     let showConfirmationButton = data.status === TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION;
     let showInvalidateButton = data.status === TRANSFORM_ORDER_STATUS.PENDING;
 
     // -blue hand for waiting for validation
     // -nothing for pending & ask for a modification
     // -green check for validated
     // -blue ? for waiting for confirmation
     let status:ECustomListRecordStatus;
     switch (data.status) {
       case TRANSFORM_ORDER_STATUS.WAITING_FOR_VALIDATION:
         status = ECustomListRecordStatus.MANUAL;
         break;
       case TRANSFORM_ORDER_STATUS.PENDING:
         status = ECustomListRecordStatus.NO_STATUS;
         break;
       case TRANSFORM_ORDER_STATUS.ASK_FOR_MODIFICATION:
         status = ECustomListRecordStatus.NO_STATUS;
         break;
       case TRANSFORM_ORDER_STATUS.VALIDATED:
         status = ECustomListRecordStatus.CONFIRM;
         break;
       case TRANSFORM_ORDER_STATUS.WAITING_FOR_CONFIRMATION:
         status = ECustomListRecordStatus.QUESTION;
         break;
       default:
         status = ECustomListRecordStatus.NO_STATUS;
     }
 
     let daysPassed = moment()
       .diff(moment(data.publication_date || data.confirmation_date), 'days')
       .toFixed();
 
     if(!data.publication_date && !data.confirmation_date) {
       daysPassed = null;
     }
 
     let record = CustomListRecord
       .build()
       .setPrimaryKey(data.id)
       .setStatus(status)
       .setHint('', ECustomListHintClass.NO_CLASS)
       .addTitleColumn(data.deposit.name, '')
       .addUnitColumn(data.order_passport.quantity || 0, data.deposit.passport.customListUnit)
       .addListColumn([{key: '', value: !!daysPassed ? daysPassed + ' ' + this._daysStr : ''}])
       .addDateColumn(this.datePipe.transform(data.order_passport.linked_datetime, 'dd.MM.y'))
       .addAuthorColumn(data.creator)
       .addControlsColumn([{
         fn: () => {
           this
             .mdDialog
             .open(DepositOrderDetailsDialogComponent, {
               data: {
                 id: data.id,
                 readOnly: !showValidationButtons
               },
             })
             .afterClosed()
             .subscribe((result: any) => {
               this.getRecords();
             })
           ;
         },
         name: 'See details'
       },
       ...(showConfirmationButton ? [{
       fn: () => {
         const dialogRef = this.mdDialog.open(ReportProblemDialogComponent, {
           ...DIALOG_THEME.PROBLEM_REPORT,
           data: {
             readOnly: !showConfirmationButton,
             onConfirm: (problem) => this._orderService.sendReport(data.id, problem).map(
               () =>  {
                 return true;
               })
           } as IReportProblemDialogInput,
         });
         dialogRef.afterClosed().subscribe((result: any) => {
           if (result) {
             this.getRecords();
             this.mdDialog.open(NotificationSuccessComponent, DIALOG_THEME.PROBLEM_REPORT);
           }
         });
       },
       name: 'Problem with order'
     }] : []),  
    //  {
    //      fn: (customData: ICustomListRecord) => {
 
    //        this.openChat(data.ecosystem.user_id)
    //      },
    //      name: 'Chat with ESM'
    //    },
         ...(showValidationButtons ? [
           {
             fn: () => {
               data.validate().subscribe(() => {
                 this.getRecords();
               });
             },
             name: 'Validate'
           },
           {
             fn: () => {
               data.reject().subscribe(() => {
                 this.getRecords();
               });
             },
             name: 'Reject'
           }
         ] : []),
         ...(showConfirmationButton ? [
           {
             fn: () => {
               this.mdDialog.open(DepositOrderTransactionConfirmDialogComponent, {
                ...DIALOG_THEME.TRANSACTION_ORDER_CONFIRMATION,
                 data: {
                 id: data.id
               }}).afterClosed().subscribe(order => {
                 if (order) {
                   this.getRecords();
                 }
               });
             },
             name: 'Confirm'
           }
         ] : []),
         ...(showInvalidateButton ? [
           {
             fn: () => {
               data.unvalidate().subscribe( () => {
                 this.getRecords();
               });
             },
             name: 'Invalidate'
           }
         ] : [])
       ])
     ;
 
     this.records.push(record);
   }
 
   onEndOfListTriggered() {
     if (!this.isNextPageLoading && this.currentPage < this.totalPagesCount)  {
       this.getRecords(this.currentPage + 1);
     }
   }
 
   ngOnInit(): void {
     this.getRecords();
 
     this._orderService.getEcoSystemManagerList().subscribe((managers) => this.ecosystemManagerList = managers);
   }
 
   searchChange() {
     this.getRecords();
   }
   openChat(targetId: number) {
     console.log('openChat', targetId);
     this._globalEvents.chatUserClicked$.emit(targetId);
   }
 }