import { ElementRef, EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var ElRowCounterService = /** @class */ (function () {
    function ElRowCounterService() {
        this.updateRowCount$ = new EventEmitter();
        this.rows = -1;
    }
    ElRowCounterService.prototype.countRows = function () {
        var _this = this;
        var rowCounter = 0, rowTopY = 0, rowHeight = 0;
        if (this.sites) {
            this.sites.forEach(function (el) {
                var siteChild = el.nativeElement.children;
                for (var i = 0; i < siteChild.length; i++) {
                    var childEl = siteChild[i];
                    if (!rowHeight) {
                        rowHeight = childEl.getBoundingClientRect().height - 1;
                    }
                    var nextRowY = _this.getNextRowTopY(rowHeight, childEl, rowTopY);
                    if (nextRowY) {
                        rowTopY = nextRowY;
                        rowCounter += 1;
                    }
                }
            });
        }
        else {
            rowCounter = -1;
        }
        this.rows = rowCounter;
        this.updateRowCount$.emit(rowCounter);
    };
    ElRowCounterService.prototype.getNextRowTopY = function (rowHeight, childEl, rowTopY) {
        if (!rowTopY && rowTopY !== 0) {
            rowTopY = childEl.getBoundingClientRect().top;
        }
        if (rowTopY + rowHeight <= childEl.getBoundingClientRect().top) {
            rowTopY = childEl.getBoundingClientRect().top;
            return rowTopY;
        }
        return null;
    };
    ElRowCounterService.prototype.getRows = function () {
        return this.rows;
    };
    ElRowCounterService.prototype.setSite = function (sites) {
        this.sites = sites;
    };
    ElRowCounterService.prototype.isRowNumberBiggerThan = function (rowNumber) {
        if (rowNumber === void 0) { rowNumber = 1; }
        return this.rows > rowNumber;
    };
    ElRowCounterService.ngInjectableDef = i0.defineInjectable({ factory: function ElRowCounterService_Factory() { return new ElRowCounterService(); }, token: ElRowCounterService, providedIn: "root" });
    return ElRowCounterService;
}());
export { ElRowCounterService };
