import {Component, Inject, OnInit} from '@angular/core';
import {MD_DIALOG_DATA, MdDialogRef} from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'spinner-dialog',
  templateUrl: 'spinner-dialog.component.html',
  styleUrls: ['spinner-dialog.component.scss']
})
export class SpinnerDialogComponent implements OnInit {

  caption: string;
  isLoadingDotsShown: boolean;

  constructor(@Inject(MD_DIALOG_DATA) public mdDialogData: any
  , private dialog: MdDialogRef<any>,) {}

  ngOnInit(): void {
    this.caption = this.mdDialogData.caption;
    this.isLoadingDotsShown = this.mdDialogData.isLoadingDotsShown;
  }

}
