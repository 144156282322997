/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./deposit-import-column-description.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/common-info-sign/common-info-sign.component.ngfactory";
import * as i3 from "../../../../../shared/common-info-sign/common-info-sign.component";
import * as i4 from "@angular/common";
import * as i5 from "./deposit-import-column-description.component";
var styles_DepositImportColumnDescriptionComponent = [i0.styles];
var RenderType_DepositImportColumnDescriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DepositImportColumnDescriptionComponent, data: {} });
export { RenderType_DepositImportColumnDescriptionComponent as RenderType_DepositImportColumnDescriptionComponent };
function View_DepositImportColumnDescriptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "common-info-sign", [["class", "deposit-import-column-description__sign"]], null, null, null, i2.View_CommonInfoSignComponent_0, i2.RenderType_CommonInfoSignComponent)), i1.ɵdid(1, 49152, null, 0, i3.CommonInfoSignComponent, [], { borderSizeClassModifier: [0, "borderSizeClassModifier"], sizeClassModifier: [1, "sizeClassModifier"] }, null), (_l()(), i1.ɵted(-1, 0, ["!"]))], function (_ck, _v) { var currVal_0 = "1"; var currVal_1 = "18"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DepositImportColumnDescriptionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "deposit-import-column-description__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
export function View_DepositImportColumnDescriptionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "flex align-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositImportColumnDescriptionComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositImportColumnDescriptionComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showWarningSign; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isDescriptionString(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DepositImportColumnDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "deposit-import-column-description", [], null, null, null, View_DepositImportColumnDescriptionComponent_0, RenderType_DepositImportColumnDescriptionComponent)), i1.ɵdid(1, 49152, null, 0, i5.DepositImportColumnDescriptionComponent, [], null, null)], null, null); }
var DepositImportColumnDescriptionComponentNgFactory = i1.ɵccf("deposit-import-column-description", i5.DepositImportColumnDescriptionComponent, View_DepositImportColumnDescriptionComponent_Host_0, { description: "description", showWarningSign: "showWarningSign" }, {}, []);
export { DepositImportColumnDescriptionComponentNgFactory as DepositImportColumnDescriptionComponentNgFactory };
