import { MD_DIALOG_DATA, MdDialogRef } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Passport } from '../../models/passport.class';
import { FormControl } from '@angular/forms';
import { NomenclatureItem } from '../../../nomenclature/models/nomenclature-item.class';
import { ModuleNames } from '../../../../shared/values/module-names.const';

@Component({
  moduleId: module.id,
  templateUrl: 'advanced-research-dialog.component.html',
  selector: 'advanced-research-dialog'
})
export class AdvancedResearchDialogComponent {
  public records: Passport[] = [];
  public search = new FormControl();
  public currentpage: number = 1;
  public count: number;
  public perpage: number;
  public totalcount: number;
  public created_by: any[] = [];
  public sort_order: any[] = [
    { key: '-created_at', value: 'Creation date' },
    { key: 'name', value: 'Name' },
  ];
  public orderBy: string = 'created_at';
  public createdBy: string = null;
  public tags: NomenclatureItem[] = [];
  public categories: string[] = [];
  public chosenCategory: string;
  public moduleNames = ModuleNames;
  public loadSelfPassports: boolean = true;

  constructor(public mdDialogRef: MdDialogRef<any>,
    protected _router: Router,
    private translate: TranslateService,
    @Inject(MD_DIALOG_DATA) public data: any
  ) {
  }

}
