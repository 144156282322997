/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../company-details/company-details.component.ngfactory";
import * as i2 from "../../../../entities/deposit/services/deposit-location-temporary-storage.service";
import * as i3 from "../company-details/company-details.component";
import * as i4 from "../../services/company-file.service";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../shared/services/user.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../../shared/services/log-notification.service";
import * as i9 from "@angular/material";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../../../../shared/services/navigation.service";
import * as i12 from "@angular/common";
import * as i13 from "./company-edit.component";
var styles_CompanyEditComponent = ["[_nghost-%COMP%] {\n      width: initial !important;\n      margin: auto;\n      margin-top: 55px;\n      background: white;\n      border-radius: 0.3125rem;\n      box-shadow: 0 0.09375rem 0.09375rem 0 rgba(0, 0, 0, 0.22);\n    }"];
var RenderType_CompanyEditComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_CompanyEditComponent, data: {} });
export { RenderType_CompanyEditComponent as RenderType_CompanyEditComponent };
export function View_CompanyEditComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "company-details", [["class", "material-reset"]], null, [[null, "onDeleteAction"], [null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDeleteAction" === en)) {
        var pd_0 = (_co.router.navigate([(_co.parentModule + "/users")]) !== false);
        ad = (pd_0 && ad);
    } if (("action" === en)) {
        var pd_1 = (_co.router.navigate([(_co.parentModule + "/users")]) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CompanyDetailsComponent_0, i1.RenderType_CompanyDetailsComponent)), i0.ɵprd(512, null, i2.DepositLocationTemporaryStorageService, i2.DepositLocationTemporaryStorageService, []), i0.ɵdid(2, 245760, null, 0, i3.CompanyDetailsComponent, [i4.CompanyFileService, i5.FormBuilder, i6.UserService, i7.Router, i8.LogNotificationService, i9.MdDialog, i8.LogNotificationService, i10.TranslateService, i11.NavigationService, i12.DatePipe, i0.ChangeDetectorRef, i7.Router, i0.NgZone, i2.DepositLocationTemporaryStorageService], { id: [0, "id"] }, { action: "action", onDeleteAction: "onDeleteAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activatedRoute.snapshot.params["id"] * 1); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CompanyEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CompanyEditComponent_0, RenderType_CompanyEditComponent)), i0.ɵdid(1, 49152, null, 0, i13.CompanyEditComponent, [i7.ActivatedRoute, i11.NavigationService, i7.Router], null, null)], null, null); }
var CompanyEditComponentNgFactory = i0.ɵccf("ng-component", i13.CompanyEditComponent, View_CompanyEditComponent_Host_0, {}, {}, []);
export { CompanyEditComponentNgFactory as CompanyEditComponentNgFactory };
