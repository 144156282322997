import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EcosystemOffer } from '../../../../entities/ecosystem-offer/ecosystem-offer.model';
import { Need } from '../../../../entities/need/models/need.class';
import * as _ from 'lodash';
import { ECOSYSTEM_STEP_STATUS } from '../../../ecosystem-manager/values/ecosystem-step-status.const';
import { EcosystemOfferService } from '../../../../entities/ecosystem-offer/ecosystem-offer.service';
import { UploadedDocument } from '../../../../shared/models/uploaders/document.class';
import { ECOSYSTEM_OFFER_STATUS } from '../../../ecosystem-manager/values/ecosystem-offer-status.const';
import { NeedDetailsDialogComponent } from '../../../../entities/need/dialogs/need-details-dialog/need-details-dialog.component';
import { DIALOG_THEME } from '../../../../shared/helpers/dialog-themes.const';
import * as moment from 'moment';
import { MdDialog } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
  moduleId: module.id,
  selector: 'sales-offer',
  templateUrl: 'sales-offer.component.html',
  styleUrls: ['sales-offer.component.scss'],
})
export class SalesOfferComponent implements OnInit {
  @Input() offer: EcosystemOffer;
  @Output() refused = new EventEmitter();
  @Output() validated = new EventEmitter();
  @Output() unvalidated = new EventEmitter();
  @Output() askedForModification = new EventEmitter;
  @Output() confirmed = new EventEmitter();
  need: Need;
  modificationModalIsVisible = false;
  modificationIsNull: boolean = false;
  files: UploadedDocument[] = [];

  ECOSYSTEM_OFFER_STATUS = ECOSYSTEM_OFFER_STATUS;

  constructor(
    private _ecosystemOfferService: EcosystemOfferService,
    private dialog: MdDialog,
    public datePipe: DatePipe) {
  }

  ngOnInit() {
    this.need = this.offer.need;
    if (this.offer.ecosystem_file_junction) {
      this.files = this.offer.ecosystem_file_junction.map((junction) => junction.ecosystem_file);
    }
  }

  get passportMaterialsComponents(): any {
    let passport = this.need.passport;
    return _.zip(<any>passport.materials, <any>passport.components);
  }

  validate() {
    this.validated.emit();
    this.offer.validate().subscribe();
  }

  unvalidate() {
    this.unvalidated.emit();
    this.offer.unvalidate().subscribe();
  }

  reject() {
    this.refused.emit();
    this.offer.reject().subscribe();
  }

  //TODO why ecosystem step status is checked ?
  checkStatusValidated() {
    return this.offer.status !== ECOSYSTEM_STEP_STATUS.STAND_BY
      && this.offer.status !== ECOSYSTEM_STEP_STATUS.WAITING_FOR_VALIDATION
      && this.offer.status !== ECOSYSTEM_STEP_STATUS.ASK_FOR_MODIFICATION;
  }

  changeVisibilityOfModificationModal() {
    this.offer.modification = '';
    this.modificationModalIsVisible = !this.modificationModalIsVisible;
  }

  modificationChanged() {
    this.modificationIsNull = !this.offer.modification;
  }

  askForModification(id: number, modification: string) {
    if (!modification) {
      this.modificationIsNull = true;
      return;
    }
    this.askedForModification.emit();
    this._ecosystemOfferService.sendModification(id, modification).subscribe();
  }

  confirm() {
    this.confirmed.emit();
    this.offer.confirm().subscribe();
  }
  setRowHeight(inputString: string, rowLength: number){
    let rowSize = 1;
    if(!inputString){
      return rowSize;
    } else if(inputString.length < rowLength){
      return rowSize
    } else {
      rowSize = Math.ceil(inputString.length / rowLength);
      return rowSize;
    }
  }
  openNeedDetailsDialog(id: number) {
    this.dialog.open(NeedDetailsDialogComponent, {
      ...DIALOG_THEME.WIDE_PRIMARY,
      data: {
        id: id,
        readOnly: true, 
        showValidationButtons: false
      }
    })
  }
}
