<div #baseDialog class="app-base-dialog" [ngClass]="getClassColor()">
    <div class="close" (click)="closeModal()">
        <i class="icon icon--close_s"></i>
    </div>
  
    <div class="dialog-wrapper">
        <div class="dialog-header-wrapper">
            <h2 *ngIf="data.title" class="dialog-title">{{ getTranslatedText(data.title) }}</h2>
            <h3 *ngIf="data.subtitle" class="dialog-subtitle">{{ getTranslatedText(data.subtitle) }}</h3>
        </div>
    
        <div class="dialog-body-wrapper">
            <span *ngFor="let paragraph of data.paragraphs" class="dialog-paragraph">{{ getTranslatedText(paragraph) }}</span>
        </div>
    
        <div class="dialog-button-wrapper">
            <div class="dialog-button-container" *ngFor="let button of buttons">
                <button
                        class="dialog-button"
                        (click)="executeAction(button.action)"
                        [disabled]="button.isDisabled ? button.isDisabled() : false"
                >{{ getTranslatedText(button.content) }}</button>
            </div>
        </div>
    </div>
</div>
  