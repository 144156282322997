<md-dialog-content class="ecosystem-indicators__content">
  <div class="ecosystem-indicators__header-wrapper">
    <header class="ecosystem-indicators__header">
      <h2 class="ecosystem-indicators__header-title">{{'ecosystem indicators' | translate | uppercase}}</h2>
      <a class="ecosystem-indicators__header-link" href="http://help.upcyclea.com/indicators" target="_blank">
        {{'+ more information about indicators' | translate}}
      </a>
    </header>
    <dialog-close-button [color]="'white'" (click)="mdDialogRef.close()" class="ecosystem-indicators__close-button">
    </dialog-close-button>
  </div>
  <div class="ecosystem-indicators__content-wrapper">
    <div class="ecosystem-indicators__cards-wrapper">
      <ecosystem-indicator-report-base
        cssStyleClass="green"
        unitOther="kg"
        [type]="ECOSYSTEM_REPORT_TYPE.WASTE"
        [reportContext]="reportContext"
        addPlaceholder="{{'Additional waste' | translate}}"
        subtractPlaceholder="{{'Avoided waste' | translate}}"
      >
        <span title>{{'waste report' | translate | uppercase}}</span>
        <div logo>
          <img class="ecosystem-indicators__infoimage-logo" src="assets/png/waste-avoided.png" alt="">
          <label class="ecosystem-indicators__infoimage-value ecosystem-indicators__infoimage-value--green">
            {{ecosystem.waste_total.total >= 0 ? '+' : ''}}{{ formatTotal(ecosystem.waste_total ? convertUnit({value : ecosystem.waste_total.total, unit : 'kgs' }).value : 0) }}
            <span>{{ convertUnit({value : ecosystem.waste_total.total, unit : 'kgs' }).unit | translate }}</span>
          </label>
        </div>
        <span other-title>{{'other waste' | translate | uppercase}}</span>
      </ecosystem-indicator-report-base>

      <ecosystem-indicator-report-base
        unitOther="kg eq. CO₂"
        cssStyleClass="purple"
        [type]="ECOSYSTEM_REPORT_TYPE.CO2_EMISSION"
        [reportContext]="reportContext"
        addPlaceholder="{{'Emitted CO2' | translate}}"
        subtractPlaceholder="{{'Avoided CO2' | translate}}"
      >
        <span title [innerHTML]="'CO2 report' | translate | uppercase"></span>
        <div logo>
          <img src="assets/png/CO2--purple.png" class="ecosystem-indicators__infoimage-logo" alt="">
          <label class="ecosystem-indicators__infoimage-value ecosystem-indicators__infoimage-value--purple">
            {{ecosystem.emissions_total.total >= 0 ? '+' : ''}}{{ formatTotal(ecosystem.emissions_total ? convertUnit({value : ecosystem.emissions_total.total, unit : 'kgs' }).value : 0) }}
            <span>{{ convertUnit({value : ecosystem.emissions_total.total, unit : 'kgs' }).unit | translate }} eq. CO₂</span>
          </label>
        </div>
        <ecosystem-indicator-report-details-tab report-details-tab
          cssStyleClass="purple"
          subTitle="{{ 'CO2 emissions details' | translate | uppercase}}"
          [type]="ECOSYSTEM_REPORT_TYPE.CO2_EMISSION"
          [reportContext]="reportContext">
        </ecosystem-indicator-report-details-tab>
        <span other-title [innerHTML]="'other CO2 emissions' | translate | uppercase"></span>
      </ecosystem-indicator-report-base>

      <ecosystem-indicator-report-base unitOther="L"
        cssStyleClass="blue"
        [type]="ECOSYSTEM_REPORT_TYPE.WATER_CONSUMPTION"
        [reportContext]="reportContext"
        addPlaceholder="{{'Emitted consumption' | translate}}"
        subtractPlaceholder="{{'Avoided consumption' | translate}}"
      >
            <span title>{{'water report' | translate | uppercase}}</span>
            <div logo>
              <img src="assets/png/large-WATER.png"
                class="ecosystem-indicators__infoimage-logo ecosystem-indicators__infoimage-logo--blue" alt="">
              <label class="ecosystem-indicators__infoimage-value ecosystem-indicators__infoimage-value--blue">
                {{(ecosystem.water_total ? ecosystem.water_total.total : 0) >= 0 ? '+ ' : ''}}{{ formatTotal(ecosystem.water_total ? convertUnit({value : ecosystem.water_total.total, unit : 'L' }).value : 0) | number:'1.1-1':'fr'}}
                <span>{{ convertUnit({value : ecosystem.water_total.total, unit : 'L' }).unit | translate}}</span>
              </label>
            </div>
            <ecosystem-indicator-report-details-tab report-details-tab
              cssStyleClass="blue"
              subTitle="{{'consumption details' | translate |uppercase}} "
              [type]="ECOSYSTEM_REPORT_TYPE.WATER_CONSUMPTION"
              [reportContext]="reportContext">
            </ecosystem-indicator-report-details-tab>
            <span other-title>{{'other consumption' | translate | uppercase}}</span>
      </ecosystem-indicator-report-base>

      <ecosystem-indicator-report-base
        unitOther="€"
        cssStyleClass="red"
        [type]="ECOSYSTEM_REPORT_TYPE.ECONOMIC_PROFITS"
        [reportContext]="reportContext"
        addPlaceholder="{{'Additional profits' | translate}}"
        subtractPlaceholder="{{'Additional expenses' | translate}}"
      >
        <span title>{{'economic report' | translate | uppercase}}</span>
        <div logo>
          <img src="assets/png/economic.png"  class="ecosystem-indicators__infoimage-logo" alt="">
          <label class="ecosystem-indicators__infoimage-value   ecosystem-indicators__infoimage-value--red">
            {{ecosystem.economic_total.total >= 0 ? '+' : ''}}{{ecosystem.economic_total.total | number:'1.0-0':'fr' | decimalSeparator:'.'}}
            <span>€</span>
          </label>
        </div>
        <ecosystem-indicator-report-details-tab report-details-tab
          cssStyleClass="red"
          subTitle="{{'profit/expanses details' | translate | uppercase}}"
          [type]="ECOSYSTEM_REPORT_TYPE.ECONOMIC_PROFITS"
          [reportContext]="reportContext">
        </ecosystem-indicator-report-details-tab>
        <span other-title>{{'other profit/expanses' | translate | uppercase}}</span>
      </ecosystem-indicator-report-base>
   </div>

    <div class="ecosystem-indicators__summary-wrapper">
      <div class="ecosystem-indicators__summary-label">{{'ecosystem summary' | translate | uppercase}}</div>
      <ng-container *ngFor="let step of ecosystemSteps">
        <summary-expander [(step)]="step"
                          [stepNames]="stepNames"
                          (change)="updateReportValues()"></summary-expander>
      </ng-container>
    </div>
    <button class="ecosystem-indicators__button ecosystem-indicators__button--confirm" (click)="saveSummary()">
      {{ 'Save changes' | translate }}
    </button>
  </div>
</md-dialog-content>
