import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ImportValidatorsService } from '../../services/import-validators.service';
import { auditTime, filter, pairwise, startWith } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
var ImportDepositColumnsComponent = /** @class */ (function () {
    function ImportDepositColumnsComponent(importValidatorsService) {
        this.importValidatorsService = importValidatorsService;
        this.userProhibitLetters = [];
        this.letterGenerator = this.defaultLetterGenerator;
        this.metaObject = {};
        this.columnBlur$ = new EventEmitter();
        this.nextLetterIndex = 0;
        this.columnProhibitLetters = [];
        this.allProhibit = new Set();
        this.subscriptions = [];
    }
    Object.defineProperty(ImportDepositColumnsComponent.prototype, "setForm", {
        set: function (form) {
            this.form = form;
            this.formChangeSubscribe();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportDepositColumnsComponent.prototype, "fields", {
        set: function (fields) {
            this.list = this.transformColumnObjectToList(fields);
            this.metaObject = fields;
            this.formChangeSubscribe();
            this.generateMissingColumnLetters();
        },
        enumerable: true,
        configurable: true
    });
    ImportDepositColumnsComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe();
    };
    ImportDepositColumnsComponent.prototype.unsubscribe = function () {
        this.subscriptions.forEach(function (item) { return item.unsubscribe(); });
    };
    ImportDepositColumnsComponent.prototype.formChangeSubscribe = function () {
        var _this = this;
        this.unsubscribe();
        if (this.form) {
            this.subscriptions = [];
            var service_1 = this.importValidatorsService;
            service_1.prohibitForForm(this.form, this.metaObject, [
                service_1.prohibitLength(3).bind(service_1),
                service_1.prohibitNoneLetters.bind(service_1)
            ]).map(function (meta) { return _this.prohibitWithDelay(900, meta, [
                function (next, prev) { return !!service_1.prohibitDuplicationInputAndShowWarning(meta.name, next, prev, _this.form, _this.metaObject); }
            ]); }).forEach(function (subscription) { return _this.subscriptions.push(subscription); });
            this.form.setValidators(function () { return service_1.validateDuplicatedColumnNames(_this.form); });
        }
    };
    ImportDepositColumnsComponent.prototype.prohibitWithDelay = function (delay, controlMeta, validators) {
        var control = controlMeta.control, name = controlMeta.name, observable = controlMeta.observable;
        var prohibit = new Subject();
        var listener = new Subject();
        var subProhibit = prohibit.pipe(startWith(''), pairwise()).subscribe(function (_a) {
            var prev = _a[0], next = _a[1];
            var isValid = validators.every(function (fn) { return fn(next, prev); });
            if (!isValid) {
                control.setValue(prev);
                prohibit.next(prev);
            }
        });
        var subListener = listener.pipe(auditTime(delay)).subscribe(function (value) { return prohibit.next(value); });
        var subBlur = this.columnBlur$.pipe(filter(function (controlName) { return name === controlName; }))
            .subscribe(function () { return prohibit.next(control.value); });
        this.subscriptions.push(subProhibit, subListener, subBlur);
        return observable.subscribe(function (_a) {
            var prev = _a[0], next = _a[1];
            return listener.next(next);
        });
    };
    ImportDepositColumnsComponent.prototype.transformColumnObjectToList = function (fields) {
        return Object.keys(fields).map(function (key) {
            return tslib_1.__assign({}, fields[key], { controlName: key });
        });
        // .sort((previous, next) => previous.order - next.order);
    };
    ImportDepositColumnsComponent.prototype.generateMissingColumnLetters = function () {
        var _this = this;
        this.nextLetterIndex = 0;
        this.list.forEach(function (item) {
            if (item.columnLetter) {
                _this.columnProhibitLetters.push(item.columnLetter);
            }
        });
        this.allProhibit = new Set(this.columnProhibitLetters.concat(this.userProhibitLetters));
        this.list.forEach(function (item) {
            if (!item.columnLetter) {
                item.columnLetter = _this.letterGenerator();
            }
        });
    };
    ImportDepositColumnsComponent.prototype.defaultLetterGenerator = function () {
        var lettersFirst = 'A'.charCodeAt(0);
        var lettersLast = 'Z'.charCodeAt(0);
        var letterInterval = lettersLast + 1 - lettersFirst;
        return this.findNextValidLetter(letterInterval);
    };
    ImportDepositColumnsComponent.prototype.findNextValidLetter = function (letterInterval) {
        var nextExelColumn = this.createLetter(this.nextLetterIndex, letterInterval);
        this.nextLetterIndex += 1;
        if (this.allProhibit.has(nextExelColumn)) {
            return this.findNextValidLetter(letterInterval);
        }
        else {
            return nextExelColumn;
        }
    };
    ImportDepositColumnsComponent.prototype.createLetter = function (orderInList, letterInterval) {
        var lettersFirst = 'A'.charCodeAt(0);
        var letter = String.fromCharCode(lettersFirst + (orderInList % letterInterval));
        if (letterInterval > orderInList) {
            return letter;
        }
        else {
            return this.createLetter(Math.floor(orderInList / letterInterval), letterInterval) + letter;
        }
    };
    ImportDepositColumnsComponent.prototype.clearFilters = function () {
        var _this = this;
        Object.keys(this.form.controls).forEach(function (item) { return _this.form.get(item).setValue(''); });
    };
    ImportDepositColumnsComponent.prototype.allowOnlyLetters = function (e, multi) {
        if (multi === void 0) { multi = false; }
        this.importValidatorsService.preventKeypressIfNotLetterOrList(e, multi);
    };
    return ImportDepositColumnsComponent;
}());
export { ImportDepositColumnsComponent };
