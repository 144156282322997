<ng-container [ngSwitch]="toxicityState">
    <!--None-->
    <svg *ngSwitchCase="ToxicityState.None" width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
            <path
                d="M9.88286 7.47556V9.07452L2.22304 22.4504C2.22304 22.4504 0.936193 23.9264 2.77455 23.8956C5.07249 23.8649 19.6874 23.9264 19.6874 23.9264C19.6874 23.9264 21.679 24.0186 20.7904 22.5734C20.2696 21.7124 13.0081 8.98227 13.0081 8.98227V7.41406L9.88286 7.47556Z"
                fill="#555C63" />
            <path
                d="M19.7799 25.0012C19.7186 25.0012 19.6879 25.0012 19.6573 25.0012C19.0139 25.0012 5.01172 24.9397 2.77505 24.9704C1.54948 25.0012 1.05925 24.4477 0.844779 24.0172C0.41583 23.1255 1.02861 22.203 1.33501 21.834L8.81099 8.79637V6.42869L14.0809 6.36719V8.73487C15.092 10.5183 21.2505 21.2498 21.7101 22.0493C22.2923 22.9717 22.1084 23.679 21.8633 24.0787C21.3731 24.8782 20.2701 25.0012 19.7799 25.0012ZM5.74706 22.818C10.6187 22.818 19.1977 22.8487 19.6573 22.8487H19.6879C18.493 20.7885 12.3345 10.0263 12.0588 9.53435L11.9056 9.28836V8.51963H10.9251V9.34986L3.23464 22.818C3.84743 22.818 4.70533 22.818 5.74706 22.818Z"
                fill="white" />
            <path
                d="M14.142 8.88651C14.0501 8.73276 13.9888 8.45602 13.9888 8.30227V2.12169H14.4484C15.0306 2.12169 15.4902 1.66045 15.4902 1.07622C15.4902 0.491987 14.9999 0 14.4178 0H8.5044C7.92225 0 7.46267 0.461238 7.46267 1.04547C7.46267 1.62971 7.92225 2.09094 8.5044 2.09094H8.96399V8.30227C8.96399 8.48677 8.90271 8.73276 8.81079 8.88651L1.21225 22.0779C0.323716 23.6154 1.05906 24.8453 2.8055 24.8453H20.1473C21.8938 24.8453 22.6291 23.5846 21.7406 22.0779L14.142 8.88651ZM9.57677 1.47596H8.47376C8.25929 1.47596 8.07545 1.29146 8.07545 1.07622C8.07545 0.860976 8.25929 0.676482 8.47376 0.676482H14.4178C14.6323 0.676482 14.8161 0.860976 14.8161 1.07622C14.8161 1.29146 14.6323 1.47596 14.4178 1.47596H13.3148V8.48677C13.3148 8.67126 13.376 8.91726 13.468 9.071L21.3729 22.7544C21.5567 23.0619 21.7406 24.5378 19.3201 24.3226H2.74422C1.7944 24.4148 1.42673 23.4001 1.54929 22.7236L9.42358 9.071C9.5155 8.91726 9.57677 8.64051 9.57677 8.48677V1.47596Z"
                fill="#555C63" />
        </g>
    </svg>
    <!--Unverified-->
    <svg *ngSwitchCase="ToxicityState.Unverified" width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.88286 7.47556V9.07452L2.22304 22.4504C2.22304 22.4504 0.936193 23.9264 2.77455 23.8956C5.07249 23.8649 19.6874 23.9264 19.6874 23.9264C19.6874 23.9264 21.679 24.0186 20.7904 22.5734C20.2696 21.7124 13.0081 8.98227 13.0081 8.98227V7.41406L9.88286 7.47556Z"
            fill="black" fill-opacity="0.8" />
        <path
            d="M19.7799 25.0012C19.7186 25.0012 19.6879 25.0012 19.6573 25.0012C19.0139 25.0012 5.01172 24.9397 2.77505 24.9704C1.54948 25.0012 1.05925 24.4477 0.844779 24.0172C0.41583 23.1255 1.02861 22.203 1.33501 21.834L8.81099 8.79637V6.42869L14.0809 6.36719V8.73487C15.092 10.5183 21.2505 21.2498 21.7101 22.0493C22.2923 22.9717 22.1084 23.679 21.8633 24.0787C21.3731 24.8782 20.2701 25.0012 19.7799 25.0012ZM5.74706 22.818C10.6187 22.818 19.1977 22.8487 19.6573 22.8487H19.6879C18.493 20.7885 12.3345 10.0263 12.0588 9.53435L11.9056 9.28836V8.51963H10.9251V9.34986L3.23464 22.818C3.84743 22.818 4.70533 22.818 5.74706 22.818Z"
            fill="white" />
        <path
            d="M14.142 8.88651C14.0501 8.73276 13.9888 8.45602 13.9888 8.30227V2.12169H14.4484C15.0306 2.12169 15.4902 1.66045 15.4902 1.07622C15.4902 0.491987 14.9999 0 14.4178 0H8.5044C7.92225 0 7.46267 0.461238 7.46267 1.04547C7.46267 1.62971 7.92225 2.09094 8.5044 2.09094H8.96399V8.30227C8.96399 8.48677 8.90271 8.73276 8.81079 8.88651L1.21225 22.0779C0.323716 23.6154 1.05906 24.8453 2.8055 24.8453H20.1473C21.8938 24.8453 22.6291 23.5846 21.7406 22.0779L14.142 8.88651ZM9.57677 1.47596H8.47376C8.25929 1.47596 8.07545 1.29146 8.07545 1.07622C8.07545 0.860976 8.25929 0.676482 8.47376 0.676482H14.4178C14.6323 0.676482 14.8161 0.860976 14.8161 1.07622C14.8161 1.29146 14.6323 1.47596 14.4178 1.47596H13.3148V8.48677C13.3148 8.67126 13.376 8.91726 13.468 9.071L21.3729 22.7544C21.5567 23.0619 21.7406 24.5378 19.3201 24.3226H2.74422C1.7944 24.4148 1.42673 23.4001 1.54929 22.7236L9.42358 9.071C9.5155 8.91726 9.57677 8.64051 9.57677 8.48677V1.47596Z"
            fill="#4D4D4D" />
    </svg>
    <!--Verified-->
    <svg *ngSwitchCase="ToxicityState.Verified" width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.88286 7.47556V9.07452L2.22304 22.4504C2.22304 22.4504 0.936193 23.9264 2.77455 23.8956C5.07249 23.8649 19.6874 23.9264 19.6874 23.9264C19.6874 23.9264 21.679 24.0186 20.7904 22.5734C20.2696 21.7124 13.0081 8.98227 13.0081 8.98227V7.41406L9.88286 7.47556Z"
            fill="#8DC53F" />
        <path
            d="M19.7799 25.0012C19.7186 25.0012 19.6879 25.0012 19.6573 25.0012C19.0139 25.0012 5.01172 24.9397 2.77505 24.9704C1.54948 25.0012 1.05925 24.4477 0.844779 24.0172C0.41583 23.1255 1.02861 22.203 1.33501 21.834L8.81099 8.79637V6.42869L14.0809 6.36719V8.73487C15.092 10.5183 21.2505 21.2498 21.7101 22.0493C22.2923 22.9717 22.1084 23.679 21.8633 24.0787C21.3731 24.8782 20.2701 25.0012 19.7799 25.0012ZM5.74706 22.818C10.6187 22.818 19.1977 22.8487 19.6573 22.8487H19.6879C18.493 20.7885 12.3345 10.0263 12.0588 9.53435L11.9056 9.28836V8.51963H10.9251V9.34986L3.23464 22.818C3.84743 22.818 4.70533 22.818 5.74706 22.818Z"
            fill="white" />
        <path
            d="M14.142 8.88651C14.0501 8.73276 13.9888 8.45602 13.9888 8.30227V2.12169H14.4484C15.0306 2.12169 15.4902 1.66045 15.4902 1.07622C15.4902 0.491987 14.9999 0 14.4178 0H8.5044C7.92225 0 7.46267 0.461238 7.46267 1.04547C7.46267 1.62971 7.92225 2.09094 8.5044 2.09094H8.96399V8.30227C8.96399 8.48677 8.90271 8.73276 8.81079 8.88651L1.21225 22.0779C0.323716 23.6154 1.05906 24.8453 2.8055 24.8453H20.1473C21.8938 24.8453 22.6291 23.5846 21.7406 22.0779L14.142 8.88651ZM9.57677 1.47596H8.47376C8.25929 1.47596 8.07545 1.29146 8.07545 1.07622C8.07545 0.860976 8.25929 0.676482 8.47376 0.676482H14.4178C14.6323 0.676482 14.8161 0.860976 14.8161 1.07622C14.8161 1.29146 14.6323 1.47596 14.4178 1.47596H13.3148V8.48677C13.3148 8.67126 13.376 8.91726 13.468 9.071L21.3729 22.7544C21.5567 23.0619 21.7406 24.5378 19.3201 24.3226H2.74422C1.7944 24.4148 1.42673 23.4001 1.54929 22.7236L9.42358 9.071C9.5155 8.91726 9.57677 8.64051 9.57677 8.48677V1.47596Z"
            fill="#B2B2B2" />
    </svg>
</ng-container>