import { IRoleContextConfig } from '../../shared/interfaces/role-context-config.interface';
import { ERoleIds } from '../../shared/values/role-ids.enum';
import { SUPERADMIN_NAVIGATION } from './superadmin.navigation';

export const SUPERADMIN_CONTEXT: IRoleContextConfig = {
  navigation: SUPERADMIN_NAVIGATION,
  moduleName: 'superadmin',
  themeClass: 'b-module-superadmin',
  roleId: ERoleIds.SUPERADMIN,
  roleText: 'Superadmin',
  homeUrl: '/superadmin',
  rootPath: 'superadmin'
};
