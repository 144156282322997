import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { Searchable } from '../../../shared/models/searchable.class';
var DepositOwnerListService = /** @class */ (function (_super) {
    tslib_1.__extends(DepositOwnerListService, _super);
    function DepositOwnerListService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'deposit/list_owners';
        _this.ModelClass = Searchable;
        return _this;
    }
    return DepositOwnerListService;
}(CrudService));
export { DepositOwnerListService };
