<div class="bar">
  <h3 class="bar__title" [ngClass]="{ 'bar__title--pdf': isReportPdf}">
    {{ 'HIGHEST CARBON FOOTPRINTS - by category of products' | translate }}</h3>
  <div class="bar__title--hr" *ngIf="!isReportPdf"></div>
  <section class="bar__chart">
    <div class="bar__chart--wrapper">
      <div class="bar__chart--content">
        <div
          [ngClass]="{'bar__chart--left-text-about-arrow': !isReportPdf,'bar__chart--left-text-about-arrow--pdf': isReportPdf}">
          <p [innerHTML]="getUnit() | translate"></p>
        </div>
        <div class="bar__chart--left-number-wrapper " [ngClass]="{'bar__chart--left-number-wrapper--pdf': isReportPdf}">
          <div *ngFor="let item of getVerticalAxisValues(); let i = index" class="bar__chart--left-number">
            <p>{{getUnitValue(item) | number:'1.1-1':'fr' | decimalSeparator:','}}</p>
          </div>
        </div>
        <div class="bar__chart--columns">
          <div class="bar__chart--column-hr-wrapper">
            <div *ngFor="let item of numberOfHrMarginTopValues(); let i = index" class="bar__chart--column-hr-items">
              <div [ngStyle]="{'margin-top': item}" class="bar__chart--column-hr-item"></div>
            </div>
          </div>
          <div *ngFor="let item of getDataForChartColumns()" class="bar__chart--column">
            <div class="bar__chart--column-title-wrapper">
              <h3 *ngIf="item.title" [ngClass]="getModifiedClassName('bar__chart--column-title', item.itemNumber)"
                [innerHTML]="item.title | translate">
              </h3>
            </div>
            <div [ngStyle]="{'height': getGraphicCanvasHeight()}" class="bar__chart--column-pillar-wrapper">
              <div *ngIf="item.mainDataForChart !== null"
                [ngStyle]="{'height': getAmountForPillarInPixels(item.mainDataForChart)}" [ngClass]="[
                  isCurrentValueLessThanOneRowVerticalAxis(item.mainDataForChart)
                    ? 'bar__chart--column-pillar-radius'
                    : '',
                  getModifiedClassName('bar__chart--column-pillar', item.itemNumber)
                ]">

              </div>
              <div *ngIf="item.mainDataForChart !== undefined && item.mainDataForChart !== null" [ngStyle]="{'top': (400 - getAmountForPillar(item.mainDataForChart) - 20 > 390 ? 390 :
                400 - getAmountForPillar(item.mainDataForChart) - 25 
              ) + 'px'}" [ngClass]="[
              'bar__chart--column-pillar-circle',
              'bar__chart--column-pillar-circle-'+item.itemNumber
            ]">
                <p class="bar__chart--column-pillar-circle-value">{{ getUnitValue(item.mainDataForChart) |
                  number:'1.1-1':'fr' | decimalSeparator:',' }}</p>
              </div>
            </div>
            <div *ngIf="item.categoryName" class="bar__chart--column-footer">
              <h4>{{item.categoryName | translate}}</h4>
              <p>{{item.weight | floatingDigitNumberAfterComma: 'fr' | decimalSeparator:','}} {{ 'tons' | translate }}
              </p>
              <p>{{item.percentageOfTotalWeight | number: numberFormat(item.percentageOfTotalWeight):'fr' |
                decimalSeparator:','}}% {{ 'of the total weight' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>