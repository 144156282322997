<div class="user-details">
    <div class="user-details__wrapper">
        <h2>{{ 'Profile settings' | translate }}</h2>
        <ng-container *ngIf="user?.loaded">
            <div class="user-details__group">

                <h3 *ngIf="user.company && isAdministratorEntityVisible()">{{ 'Administrator entity' | translate }}</h3>
                <div class="user-details__row" *ngIf="user.company && isAdministratorEntityVisible() ">
                  <md-input-container>
                    <input mdInput
                           autocomplete="off"
                           [placeholder]="'Entity name' | translate"
                           [disabled]="true"
                           [(ngModel)]="user.company.name"
                    >
                  </md-input-container>
                  <md-input-container class="wide">
                    <input mdInput
                           autocomplete="off"
                           [placeholder]="'Description' | translate"
                           [disabled]="true"
                           [(ngModel)]="user.company.description"
                    >
                  </md-input-container>
                </div>

                <h3>{{ 'Contact details' | translate }}</h3>
                <div class="flex">
                    <div class="user-details__contact-detail">
                        <div class="user-details__row">
                            <md-input-container>
                                <input
                                        mdInput
                                        autocomplete="off"
                                        placeholder="{{ 'First name' | translate }}"
                                        [(ngModel)]="user.first_name"
                                >
                            </md-input-container>
                            <md-input-container>
                                <input
                                        mdInput
                                        autocomplete="off"
                                        placeholder="{{ 'Last name' | translate }}"
                                        [(ngModel)]="user.last_name"
                                >
                            </md-input-container>
                        </div>

                        <div class="user-details__row">
                            <md-input-container>
                                <input
                                        mdInput
                                        autocomplete="off"
                                        placeholder="{{ 'Phone' | translate }}"
                                        [(ngModel)]="user.phone"
                                >
                            </md-input-container>
                            <md-input-container>
                                <input
                                        mdInput
                                        autocomplete="off"
                                        placeholder="{{ 'E-mail' | translate }}"
                                        email
                                        [(ngModel)]="user.email"
                                >
                            </md-input-container>
                        </div>

                    <div class="user-details__row">
                        <md-input-container>
                        <input mdInput
                                autocomplete="off"
                                [disabled]="!isAdmin"
                                placeholder="{{ 'Organisation name' | translate }}"
                                [(ngModel)]="user.company_name"
                        >
                        </md-input-container>
                        <md-input-container>
                        <input mdInput
                                [disabled]="!isAdmin"
                                autocomplete="off"
                                placeholder="{{ 'SIRET' | translate }}"
                                [(ngModel)]="user.SIRET"
                        >
                        </md-input-container>
                    </div>

                        <div class="user-details__row">
                            <md-input-container class="wide">
                                <input
                                        mdInput
                                        autocomplete="off"
                                        placeholder="{{ 'Description' | translate }}"
                                        [(ngModel)]="user.description"
                                        name="somethingAnbsolutlluunkonw"
                                        autocomplete="new-password"
                                        type="search"
                                >
                            </md-input-container>
                        </div>
                    </div>
                    <div class="user-details__contact-detail-avatar">
                        <div class="user-details__row">
                            <avatar-uploader
                                    [(preview)]="user.avatar_url"
                                    [userId]="user.id"
                            ></avatar-uploader>
                        </div>
                        <div class="user-details__row">
                            <language-selector
                                [(language)]="user.language"
                                showPlaceHolder="'always'"
                            ></language-selector>
                        </div>
                    </div>
                </div>
            </div>

            <div class="user-details__group" [formGroup]="passwordForm">
                <h3>{{ 'Change password' | translate }}</h3>
                <div class="user-details__row">
                    <md-input-container>
                        <input
                                mdInput
                                autocomplete="off"
                                type="password"
                                placeholder="{{ 'Old password' | translate }}"
                                [(ngModel)]="user.oldPassword"
                                formControlName="oldPassword"
                        >
                    </md-input-container>
                </div>
                <div class="user-details__row">
                    <md-input-container>
                        <input
                                mdInput
                                autocomplete="off"
                                type="password"
                                placeholder="{{ 'New password' | translate }}"
                                [(ngModel)]="user.password"
                                formControlName="password"
                        >
                    </md-input-container>
                    <md-input-container
                            [class.ng-invalid]="passwordForm.controls['passwordConfirm'].hasError('notEquivalent')"
                    >
                        <input
                                mdInput
                                autocomplete="off"
                                type="password"
                                placeholder="{{ 'Repeat new password' | translate }}"
                                [(ngModel)]="user.passwordConfirm"
                                formControlName="passwordConfirm"
                        >
                    </md-input-container>
                </div>
            </div>

            <div class="user-details__group">
                <h3>{{ 'Notifications' | translate }}</h3>
                <div class="user-details__row">
                    <md-checkbox [(ngModel)]="user.receive_notifications">
                        {{ moduleName === moduleNames.SUPERVISOR ? 'Receive other notifications' : 'Receive Email notifications' | translate }}
                    </md-checkbox>
                </div>
                <div class="user-details__row" *ngIf="moduleName === moduleNames.SUPERVISOR">
                  <md-checkbox [(ngModel)]="user.receive_deposit_import_notification">
                    {{ 'Receive notifications when a deposit import has been completed' | translate }}
                  </md-checkbox>
                </div>
            </div>

            <div class="user-details__row--actions">
                <button md-raised-button
                        class="user-details__save-button"
                        (click)="save()"
                >{{ 'Save' | translate }}
                </button>
            </div>
        </ng-container>
    </div>
</div>
