import { ActiveRecord } from '../../../shared/models/active-record.class';
import { Type } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ServiceLocator} from '../../../shared/services/service-locator';
import {ColorsService} from '../services/colors.service';

export class Colors extends ActiveRecord {
  protected provider: Type<ColorsService> = ColorsService;

  protected translateService: TranslateService = ServiceLocator.injector.get(TranslateService);
  id: number;
  color_id: number;
  opacity: number;
  passport_id: number;

  fields() {
    return ['color_id', 'passport_id', 'id', 'opacity'];
  }
}
