import * as tslib_1 from "tslib";
import { CrudService } from '../../../shared/services/crud.service';
import { Contact } from '../model/contact.class';
var ContactService = /** @class */ (function (_super) {
    tslib_1.__extends(ContactService, _super);
    function ContactService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.namespace = 'client-contacts';
        _this.ModelClass = Contact;
        return _this;
    }
    return ContactService;
}(CrudService));
export { ContactService };
