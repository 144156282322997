/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../notification-entry/notification-entry.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../notification-entry/notification-entry.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./notification-list.component";
var styles_NotificationListComponent = [];
var RenderType_NotificationListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationListComponent, data: {} });
export { RenderType_NotificationListComponent as RenderType_NotificationListComponent };
function View_NotificationListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "notification__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "notification-entry", [["class", "notification-entry"]], null, [[null, "onAction"], [null, "onRead"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAction" === en)) {
        var pd_0 = (_co.onAction.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("onRead" === en)) {
        var pd_1 = (_co.onRead.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_NotificationEntryComponent_0, i1.RenderType_NotificationEntryComponent)), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "active": 0 }), i0.ɵdid(4, 49152, null, 0, i3.NotificationEntryComponent, [], { record: [0, "record"] }, { onRead: "onRead", onAction: "onAction" })], function (_ck, _v) { var currVal_0 = "notification-entry"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.status !== 1)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2); }, null); }
function View_NotificationListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "notification__item--empty"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Loading")); _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "notification__item--empty"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("No Notifications")); _ck(_v, 1, 0, currVal_0); }); }
export function View_NotificationListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "ul", [["class", "notification__list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationListComponent_1)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationListComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationListComponent_3)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.records; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.records && (_co.records.length === 0)) && !_co.isLoading); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_NotificationListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notification-list", [], null, null, null, View_NotificationListComponent_0, RenderType_NotificationListComponent)), i0.ɵdid(1, 49152, null, 0, i5.NotificationListComponent, [], null, null)], null, null); }
var NotificationListComponentNgFactory = i0.ɵccf("notification-list", i5.NotificationListComponent, View_NotificationListComponent_Host_0, { records: "records", isLoading: "isLoading" }, { onRead: "onRead", onAction: "onAction" }, []);
export { NotificationListComponentNgFactory as NotificationListComponentNgFactory };
