/**
 * Created by aleksandr on 28.07.17.
 */
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModel } from '@angular/forms';
import { TransformService } from './services/transform.service';
import { TransformSiteJunctionService } from './services/transform-site-junction.service';
import { TsmListService } from './services/tsm-list.service';
import { TransformServiceAreaService } from './services/transform-service-area.service';
import { TransformTagJunctionService } from './services/transform-tag-junction.service';
import { TransformTagService } from './services/transform-tag.service';
import { TransformMaterialService } from './services/transform-material.service';
import { TransformLocationService } from './services/transform-location.service';
import { TransformConditionService } from './services/transform-condition.service';
import { TransformFileService } from './services/transform-file.service';
import { TransformationResolver } from './services/transformation.resolver';
import {TransformationModificationDialogComponent} from './transformation-modification-dialog/transformation-modification-dialog.component';
import {CopyTransformationDialogComponent} from './dialogs/copy-transformation-dialog/copy-transformation-dialog.component';
import {TransformFormatService} from './services/transform-format.service';
import {TransformFormatJunctionService} from './services/transform-format-junction.service';
import {TransformPackagingService} from './services/transform-packaging.service';
import {TransformPackagingJunctionService} from './services/transform-packaging-junction.service';
import {TransformQualityService} from './services/transform-quality.service';
import {TransformQualityJunctionService} from './services/transform-quality-junction.service';
import {TransformConversionComponent} from './transform-conversion/transform-conversion.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule
  ],
  declarations: [
    TransformationModificationDialogComponent,
    CopyTransformationDialogComponent,
    TransformConversionComponent
  ],
  exports: [
    TransformConversionComponent
  ],
  providers: [
    NgModel,
    TransformService,
    TransformFileService,
    TransformConditionService,
    TransformLocationService,
    TransformMaterialService,
    TransformTagService,
    TransformQualityService,
    TransformQualityJunctionService,
    TransformTagJunctionService,
    TransformPackagingService,
    TransformPackagingJunctionService,
    TransformFormatService,
    TransformFormatJunctionService,
    TransformSiteJunctionService,
    TransformServiceAreaService,
    TsmListService,
    TransformationResolver
  ],
  entryComponents: [
    TransformationModificationDialogComponent,
    CopyTransformationDialogComponent
  ],
})
export class TransformationModule {
}
