import { ExtendedActiveRecord } from '../../../shared/models/extended-active-record.class';
import { StakeholderService } from '../services/stakeholder.service';
import * as _ from 'lodash';
import { IPlumbStepSource } from '../../../dashboards/ecosystem-manager/interfaces/plumb-step-source.interface';
import { ECOSYSTEM_STEP_TYPE } from '../../../dashboards/ecosystem-manager/values/ecosystem-step-type.const';
import { STAKEHOLDER_TYPES } from '../values/stakeholder-types.const';

export class Stakeholder extends ExtendedActiveRecord implements IPlumbStepSource {
  protected provider = StakeholderService;
  public plumb_type = ECOSYSTEM_STEP_TYPE.STAKEHOLDER;

  public id: number;

  public name: string;
  public surname: string;

  public company_name: string;
  public address: string;
  public description: string;

  public email: string;
  public phone: string;
  public function: string;

  public type: number;

  public created_at: string;
  public updated_at: string;

  //public status:number;

  get typeView() {
    return (STAKEHOLDER_TYPES[this.type] || '').toLowerCase().replace(/./, s => s.toUpperCase());
  }

  protected fields() {
    return [
      'id',
      'name',
      'surname',
      'company_name',
      'address',
      'type',
      'email',
      'function',
      'phone',
      'description',
      'created_at',
      'updated_at'
    ];
  }

  includesText(searchText: string) {
    searchText = searchText.toLocaleLowerCase();

    let doesNameInclude = _.includes(this.name.toLocaleLowerCase(), searchText);

    return doesNameInclude;
  }
}
