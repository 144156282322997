import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../../entities/stats/services/stats.service';
import {
  ECOSYSTEM_STATISTICS_SEGMENTS,
  ECOSYSTEM_STATISTICS_SEGMENTS_VIEW
} from '../../values/ecosystem-statistics-segments.const';
import { EcosystemStatsContainer } from '../../../../entities/stats/models/ecosystem-stats-container.class';
import { DatePipe } from '@angular/common';

@Component({
  moduleId: module.id,
  templateUrl: 'ecosystem-statistics.component.html'
})
export class EcosystemStatisticsComponent implements OnInit {
  ECOSYSTEM_STATISTICS_SEGMENTS = ECOSYSTEM_STATISTICS_SEGMENTS;
  ECOSYSTEM_STATISTICS_SEGMENTS_VIEW = ECOSYSTEM_STATISTICS_SEGMENTS_VIEW;
  segments: ECOSYSTEM_STATISTICS_SEGMENTS[] = [
    ECOSYSTEM_STATISTICS_SEGMENTS.IN_PROCESS,
    ECOSYSTEM_STATISTICS_SEGMENTS.CANCELED,
    ECOSYSTEM_STATISTICS_SEGMENTS.CREATED,
    ECOSYSTEM_STATISTICS_SEGMENTS.DELETED,
    ECOSYSTEM_STATISTICS_SEGMENTS.MODIFIED,
    ECOSYSTEM_STATISTICS_SEGMENTS.DONE
  ];
  selectedSegment: number = ECOSYSTEM_STATISTICS_SEGMENTS.IN_PROCESS;
  segmentStats: any = {};
  segmentsDateFrom: string;
  segmentsDateTo: string;

  constructor(private statsService: StatsService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.segmentsDateFrom = this.datePipe.transform(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd');
    this.segmentsDateTo = this.datePipe.transform(new Date, 'yyyy-MM-dd');
    this.getSegmentData();
  }

  changeSegment(segment: number) {
    this.selectedSegment = segment;
    this.getSegmentData();
  }

  getSegmentData() {
    let query: any = {
      group_by: 'month',
      from: this.segmentsDateFrom || null,
      to: this.segmentsDateTo || null
    };
    switch (this.selectedSegment) {
      case ECOSYSTEM_STATISTICS_SEGMENTS.IN_PROCESS:
        this.getEcosystemsInProcessData(query);
        break;
      case ECOSYSTEM_STATISTICS_SEGMENTS.CANCELED:
        this.getEcosystemsCanceledData(query);
        break;
      case ECOSYSTEM_STATISTICS_SEGMENTS.CREATED:
        this.getEcosystemsCreatedData(query);
        break;
      case ECOSYSTEM_STATISTICS_SEGMENTS.DELETED:
        this.getEcosystemsDeletedData(query);
        break;
      case ECOSYSTEM_STATISTICS_SEGMENTS.MODIFIED:
        this.getEcosystemsModifiedData(query);
        break;
      case ECOSYSTEM_STATISTICS_SEGMENTS.DONE:
        this.getEcosystemsDoneData(query);
        break;
      default:
        break;
    }
  }

  getEcosystemsInProcessData(query: any) {
    this.statsService.getEcosystemStats(query).subscribe((ecosystemsStats: EcosystemStatsContainer) => {
      this.segmentStats = ecosystemsStats.getInProcessTotals();
      this.segmentStats['evolution'] = ecosystemsStats.getInProcessEvolution();
    });
  }

  getEcosystemsCanceledData(query: any) {
    this.statsService.getEcosystemStats(query).subscribe((ecosystemsStats: EcosystemStatsContainer) => {
      this.segmentStats = ecosystemsStats.getCanceledTotals();
      this.segmentStats['evolution'] = ecosystemsStats.getCanceledEvolution();
    });
  }

  getEcosystemsCreatedData(query: any) {
    this.statsService.getEcosystemStats(query).subscribe((ecosystemsStats: EcosystemStatsContainer) => {
      this.segmentStats = ecosystemsStats.getCreatedTotals();
    });
  }

  getEcosystemsDeletedData(query: any) {
    this.statsService.getEcosystemStats(query).subscribe((ecosystemsStats: EcosystemStatsContainer) => {
      this.segmentStats = ecosystemsStats.getDeletedTotals();
    });
  }

  getEcosystemsModifiedData(query: any) {
    this.statsService.getEcosystemStats(query).subscribe((ecosystemsStats: EcosystemStatsContainer) => {
      this.segmentStats = ecosystemsStats.getModifiedTotals();
    });
  }

  getEcosystemsDoneData(query: any) {
    this.statsService.getEcosystemStats(query).subscribe((ecosystemsStats: EcosystemStatsContainer) => {
      this.segmentStats = ecosystemsStats.getDoneTotals();
    });
  }
}
