import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MdDialog } from '@angular/material';
import { DepositSiteDetailsDialogComponent } from '../../../../../entities/deposit/dialogs/deposit-site-details-dialog/deposit-site-details-dialog.component';
import { OrganisationTIds } from '../../../../../shared/values/company-values.enum';
import { DepositLocationService } from '../../../../../entities/deposit/services/deposit-location.service';
import { ConfirmDialogComponent } from '../../../../../shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DepositSiteDetailsPreviewDialogComponent } from '../../../../../entities/deposit/dialogs/deposit-site-details-preview-dialog/deposit-site-details-preview-dialog.component';
import * as _ from 'lodash';
import { LogNotificationService } from '../../../../../shared/services/log-notification.service';
import { DepositLocationTemporaryStorageService } from '../../../../../entities/deposit/services/deposit-location-temporary-storage.service';
import { DepositLocationStatusName, EDepositLocationStatus } from '../../../../../entities/deposit/values/deposit-location-status.enum';
import { UserService } from '../../../../../shared/services/user.service';
import { Observable } from 'rxjs/Observable';
var CompanySitesComponent = /** @class */ (function () {
    function CompanySitesComponent(mdDialog, translate, siteService, notification, userService, storage) {
        this.mdDialog = mdDialog;
        this.translate = translate;
        this.siteService = siteService;
        this.notification = notification;
        this.userService = userService;
        this.storage = storage;
        this.DEPOSIT_LOCATION_STATUS_ID = EDepositLocationStatus;
        this.DEPOSIT_LOCATION_STATUS_NAME = DepositLocationStatusName;
        this.records = [];
        this.search = '';
        this.searchChanged$ = new EventEmitter();
        this.validatePreview = this.validatePreview.bind(this);
    }
    Object.defineProperty(CompanySitesComponent.prototype, "userId", {
        set: function (id) {
            this._userId = id;
            if (this._userId) {
                this.loadLocations();
                // setTimeout(() => this.loadUserExpanded());
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySitesComponent.prototype, "companyId", {
        set: function (id) {
            this._companyId = id;
            if (this._companyId) {
                this.loadLocations();
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanySitesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchChanged$.debounceTime(300)
            .distinctUntilChanged().subscribe(function () {
            if (_this._userId) {
                _this.loadLocations();
            }
            else {
                _this.searchUnsafeLocation();
            }
        });
        this.storage.setResetObservers(true);
    };
    CompanySitesComponent.prototype.ngOnDestroy = function () {
        if (this.searchChanged$) {
            this.searchChanged$.unsubscribe();
        }
    };
    CompanySitesComponent.prototype.loadLocations = function () {
        var _this = this;
        if (!this._companyId) {
            return;
        }
        this.siteService.get({ expand: 'floor_area,end_of_work,expired', company_id: this._companyId, search: this.search, superadmin: 1 })
            .subscribe(function (locations) { return _this.records = locations; });
    };
    CompanySitesComponent.prototype.loadUserExpanded = function () {
        var _this = this;
        if (!this._companyId) {
            this.userService.view(this._userId, { expand: 'company' })
                .subscribe(function (user) {
                _this._companyId = user.company.id;
                _this.loadLocations();
            });
        }
    };
    CompanySitesComponent.prototype.searchUnsafeLocation = function () {
        var _this = this;
        this.records = this.storage.getDepositLocations().filter(function (site) {
            return (site.name || '').toLocaleLowerCase().indexOf(_this.search.toLocaleLowerCase()) > -1
                || (site.address || '').toLocaleLowerCase().indexOf(_this.search.toLocaleLowerCase()) > -1;
        });
    };
    CompanySitesComponent.prototype.createNewSite = function () {
        var _this = this;
        if (this._userId) {
            this.mdDialog.open(DepositSiteDetailsDialogComponent, { data: { type: OrganisationTIds.Building, userId: this._userId } }).afterClosed().subscribe(function () { return _this.loadLocations(); });
        }
        else {
            this.createNewTemporarySite();
        }
    };
    CompanySitesComponent.prototype.createNewTemporarySite = function () {
        var _this = this;
        if (this.storage.isLoading()) {
            return;
        }
        var _a = this.storage.addLocation(), location = _a.location, fileUploader = _a.fileUploader;
        this.mdDialog.open(DepositSiteDetailsPreviewDialogComponent, { data: { location: location, uploader: fileUploader, isValid: this.validatePreview } }).afterClosed()
            .subscribe(function (data) {
            if (data) {
                location.loaded = true;
            }
            else {
                _this.storage.remove(location);
            }
            _this.searchUnsafeLocation();
        });
    };
    CompanySitesComponent.prototype.validatePreview = function (data) {
        var duplication = this.storage.getDepositLocations().find(function (site) { return site !== data && site.name.trim() === data.name.trim(); });
        if (duplication) {
            this.notification.error('The site name already exists, choose another one or delete the existing one.', null, true);
            return Observable.of(false);
        }
        return Observable.of(this.syncPreviewValidation(data));
    };
    CompanySitesComponent.prototype.syncPreviewValidation = function (data) {
        /* if (data.end_of_work && data.isExpire) {
          this.notification.error('Deposit location has expired', null, true);
          return false;
        }*/
        if (data.bim_id && typeof data.bim_id !== 'number') {
            this.notification.error('Bim Id must be an integer.', null, true);
            return false;
        }
        if (!data.name) {
            this.notification.error('Name cannot be blank.', null, true);
            return false;
        }
        if (!data.address) {
            this.notification.error('The address field must be filled.', null, true);
            return false;
        }
        if (!data.site_status) {
            this.notification.error('Status cannot be blank.', null, true);
            return false;
        }
        if (data.site_status === this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION && !data.end_of_work) {
            this.notification.error('End of work cannot be blank.', null, true);
            return false;
        }
        if (data.site_status === this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION && data.active) {
            this.notification.error('Some deposits of this site are being used in ecosystems!', null, true);
            return false;
        }
        return true;
    };
    CompanySitesComponent.prototype.getSiteExpireDate = function (location) {
        if (location.is_expired_by_superadmin) {
            return location.expired_date;
        }
        if (this.isEndOfWorkExpire(location)) {
            return location.end_of_work;
        }
        return null;
    };
    CompanySitesComponent.prototype.isEndOfWorkExpire = function (location) {
        return location.site_status === this.DEPOSIT_LOCATION_STATUS_ID.IN_CONSTRUCTION && location.isExpire;
    };
    CompanySitesComponent.prototype.showExpireOrEndOfWorkText = function (item) {
        if (!!this.getSiteExpireDate(item)) {
            return this.translate.instant('Expired since') + ' : ';
        }
        else {
            return this.translate.instant('End of work') + ' : ' + item.end_of_work;
        }
    };
    CompanySitesComponent.prototype.openSiteView = function (item) {
        var _this = this;
        if (this._userId) {
            this.mdDialog.open(DepositSiteDetailsDialogComponent, { data: { id: item.id, type: OrganisationTIds.Building } }).afterClosed().subscribe(function () { return _this.loadLocations(); });
        }
        else {
            this.openTemporarySite(item);
        }
    };
    CompanySitesComponent.prototype.openTemporarySite = function (item) {
        var _this = this;
        if (this.storage.isLoading()) {
            return;
        }
        var uploader = this.storage.getUploader(item);
        var options = { data: { location: _.cloneDeep(_.omit(item, ['service'])),
                uploader: _.cloneDeep(uploader), isValid: this.validatePreview } };
        this.mdDialog.open(DepositSiteDetailsPreviewDialogComponent, options).afterClosed().subscribe(function (data) {
            if (data) {
                _.assign(item, data.location);
                Object.assign(uploader, data.uploader);
            }
            _this.searchUnsafeLocation();
        });
    };
    CompanySitesComponent.prototype.removeSite = function (site) {
        var _this = this;
        if (this._userId) {
            site.load({ expand: 'deposits,active' }).subscribe(function (expandSite) {
                if (!expandSite.active) {
                    _this.openRemoveDialog(site);
                }
                else {
                    _this.notification.error('There is one deposit in an active ecosystem', null, true);
                }
            });
        }
        else {
            this.openRemoveDialog(site);
        }
    };
    CompanySitesComponent.prototype.openRemoveDialog = function (site) {
        var _this = this;
        if (this.storage.isLoading() && !this._userId) {
            return;
        }
        this.mdDialog.open(ConfirmDialogComponent, { data: { text: this.translate.instant('Are you sure you want to delete this site ? It contains N deposits', { X: Array.isArray(site.deposits) ? site.deposits.length : 0 }) } }).afterClosed().subscribe(function (confirm) {
            if (confirm) {
                if (_this._userId) {
                    _this.siteService.remove(site.id).subscribe(function () { return _this.loadLocations(); });
                }
                else {
                    _this.storage.remove(site);
                    _this.searchUnsafeLocation();
                }
            }
        });
    };
    CompanySitesComponent.prototype.totalSqm = function () {
        return this.records.reduce(function (reduce, item) { return reduce + (Number(item.floor_area) || 0); }, 0);
    };
    return CompanySitesComponent;
}());
export { CompanySitesComponent };
