export const DEMOUNTABILITY_GRADE_VARIABLES = {
  1: 'Deposit dem grade already separated',
  2: 'Deposit dem grade easily separable',
  3: 'Deposit dem grade hardly seprable',
  4: 'Deposit dem grade inseparable',
  5: 'Deposit dem grade unknown',
  6: 'Component dem grade undemountable',
  7: 'Component dem grade not easily demoutable',
  8: 'Component dem grade easily demountable',
  9: 'Component dem grade null',
};

export const FUTURE_LIFE_GRADE_VARIABLES = {
  1: 'Demountability factor if unknown',
  2: 'Demountability factor if already separated',
  3: 'Demountability factor if easily separable',
  4: 'Demountability factor if hardly separable',
  5: 'Demountability factor if inseparable',
  6: 'Colored circularity rating grade',
  7: 'Reuse grade no rating',
  8: 'Reuse grade black rating',
  9: 'Recycling grade no rating',
  10: 'Recycling grade black rating',
  11: 'Coef one component hardly demountable',
  12: 'Coef one stakeholder has next usages',
};

export const POTENTIAL_GRADE_VARIABLES = {
  1: 'Stakeholder coef',
  2: 'Recycling composting next usage',
  3: 'Reuse refurbishing methanization',
  4: 'Energy recovery null',
};

export const RATING_GRADE_VARIABLES = {
  1: 'Rating grade null rating',
  2: 'Rating grade black rating',
  3: 'Rating grade colored rating',
  4: 'Coef rating grade',
  5: 'Coef filling grade',
};

export const RENEWAL_RATES_VARIABLES = {
  1: 'Structure / foundation',
  2: 'Facade / skin',
  3: 'Technical equipment',
  4: 'Finishing work',
  5: 'Furniture / decoration',
};
export const CONSTRUCTION_LAYERS: any[] = [
  {id: 1, title: RENEWAL_RATES_VARIABLES[1],subtitle:'',value:0},
  {id: 2, title: RENEWAL_RATES_VARIABLES[2],subtitle:'',value:0},
  {id: 3, title: RENEWAL_RATES_VARIABLES[3],subtitle:'',value:0},
  {id: 4, title: RENEWAL_RATES_VARIABLES[4],subtitle:'Partitions / Joinery / Coatings',value:0},
  {id: 5, title: RENEWAL_RATES_VARIABLES[5],subtitle:'',value:0},
];


export const RENEWAL_RATES_VARIABLE_ITEMS = {
  deconstruction: 'Deconstruction',
  rehabilitation: 'Rehabilitation',
  over_30_years_commercial: 'Over 30 years commercial',
  over_30_years_institutional: 'Over 30 years institutional',
  over_30_years_residential: 'Over 30 years residential',
};

export const RESIDUAL_VALUE_VARIABLES = {
  2: 'Coef rehabilitation makes removal expensive anyway',
  3: 'Coef has no next usage',
  4: 'Black rating in residual value',
  5: 'Colored rating in residual value',
  6: 'Deposit item state rate if irrelevant',
  7: 'Deposit item state rate if as new',
  8: 'Deposit item state rate if slightly used',
  9: 'Deposit item state rate if used',
  10: 'Deposit item state rate if deteriorated',
  11: 'Demountability factor if unknown',
  12: 'Demountability factor if easily separable',
  13: 'Demountability factor if hardly separable',
  14: 'Demountability factor if inseparable',
};
