import {Component, forwardRef, Input, ViewEncapsulation, EventEmitter} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DepositLocation} from '../../../models/deposit-location.class';
import {ActiveRecord} from '../../../../../shared/models/active-record.class';

@Component({
    moduleId: module.id,
    selector: 'deposit-location-filter',
    templateUrl: 'deposit-location-filter.component.html',
    styleUrls: ['deposit-location-filter.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DepositLocationFilterComponent),
        multi: true
      }],
  encapsulation: ViewEncapsulation.None,
})
export class DepositLocationFilterComponent<T extends ActiveRecord> implements  ControlValueAccessor {
    @Input() field: string;
    @Input() placeholder: string;
    @Input() clearFilterForm: EventEmitter<boolean>;
    @Input() set availableItems(data: Array<T>) {
      this._availableItems = data || [];
      this.filteredItems = this._availableItems;
      if (this.chosenItems.length > 0) {
        this.chosenItems = [];
        this.changeControl(this.chosenItems);
      }
    }

    @Input() set availableItemsWithoutReset(data: Array<T>) {
        this._availableItems = data || [];
        this.filteredItems = this._availableItems;
        setTimeout(() =>
            this.filteredItems = this.filteredItems.filter(item => !this.chosenItems.some(chosenItem => chosenItem.id === item.id))
        );
    }

    @Input() iconClass = 'icon--people-grey';
    @Input() inputContainerClass: string[] = ['input-group'];
    filteredItems: T[] = [];
    chosenItems: T[] = [];
    disabled = false;
    changeControl: any;
    touchControl: any;
    filteredString = '';
    private _availableItems = [];

    ngOnInit(): void {
        this.clearFilterForm.subscribe(() => {
            this.chosenItems = [];
            this.emitChange();
        } 
        );}

    get depositLocationFilterPlaceholder() {
        return this.chosenItems && this.chosenItems.length > 0 ? '' : this.placeholder;
    }

    chooseLocationList(locationList: T[]) {
        this.chosenItems = locationList.filter(location =>
            !!this._availableItems.find(available => available.id === location.id));
        this.filterItem();
    }

    filterItem() {
        const chosenIds = new Set(this.chosenItems.map(item => item.id));
        this.filteredItems = this._availableItems.filter(item => !chosenIds.has(item.id))
            .filter(location => location[this.field].toLowerCase().indexOf(this.filteredString.toLowerCase()) > -1);
    }

    chooseItem(data: T) {
        if (!!this.chosenItems.find(location => data.id === location.id)) {
            return;
        }
        this.filteredString = '';
        this.chosenItems.push(data);
        this.emitChange();
    }

    declineOfLocation(data: DepositLocation) {
        this.chosenItems = this.chosenItems.filter(location => data.id !== location.id);
        this.emitChange();
    }

    emitChange() {
        this.filterItem();
        this.changeControl(this.chosenItems);
    }

    registerOnChange(fn: any): void {
        this.changeControl = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchControl = fn;
    }

    setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
      this.chosenItems = obj;
    }
}
