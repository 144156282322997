<section class="admin-warning-confirm-dialog">
  <h1 class="admin-warning-confirm-dialog__label" md-dialog-title>{{warningLabel}}</h1>
  <div class="admin-warning-confirm-dialog__message">
    <div class="admin-warning-confirm-dialog__message-decoration border-primary-color primary-color">!</div>
    <h2 class="admin-warning-confirm-dialog__message-text">{{text}}</h2>
    <div></div>
  </div>
  <div class="button-group__list admin-warning-confirm-dialog__button-group">
    <button
      class="button-group__item admin-warning-confirm-dialog__button admin-warning-confirm-dialog__button--confirm"
      md-button
      md-raised-button
      (click)="confirm.emit(true)"
    >
      {{'Ok' | translate}}
    </button>
    <button
      class="button-group__item admin-warning-confirm-dialog__button admin-warning-confirm-dialog__button--cancel"
      md-button
      md-raised-button
      (click)="confirm.emit(false)"
    >
      {{'Cancel' | translate}}
    </button>
  </div>
</section>
