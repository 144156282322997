/**
 * Created by Aleksandr C. on 22.02.17.
 */
import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {NavigationService} from '../services/navigation.service';
import {IModuleNavigation} from '../interfaces/module-navigation.interface';
import {CurrentUserService} from '../services/current-user.service';
import {Router} from '@angular/router';
import {Response} from '@angular/http';
import {IRoleContextConfig} from '../interfaces/role-context-config.interface';
import {DeepStreamService} from '../services/deepstream.service';
import {DateTimePickerTranslationService} from '../datetime-translation/datetime-translation';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../models/user.class';
import {CurrentUser} from '../auth/current-user.class';
import {UserService} from '../services/user.service';
import 'rxjs/add/operator/distinctUntilChanged';
import {ModuleNames} from '../values/module-names.const';
import {AuthService} from '../services/auth.service';
import {LanguageService} from '../../entities/client/service/language.service';
import {TLanguage} from '../types/language-type';
import {MdDialog} from '@angular/material';
import {DIALOG_THEME} from '../helpers/dialog-themes.const';
import {BIMModelDialogComponent} from '../../dashboards/deposit-owner/dialogs/BIM-models-dialog/BIM-model-dialog.component';
import {FilterStateService} from '../services/filterState.service';
import {NavbarStateService} from '../services/navbar.service';
import {GlobalEventsManager} from '../services/global-events-manager.service';
import {EAdminPermission} from '../../dashboards/admin/values/admin-permission.enum';
import * as _ from 'lodash';
import {PermissionService} from '../services/permission.service';
import {Subscription} from 'rxjs/Subscription';
import {EDepositOwnerPermission} from '../../dashboards/deposit-owner/values/deposit-owner-permission.enum';

@Component({
  moduleId: module.id,
  selector: 'need-navbar',
  templateUrl: 'common-navbar.component.html'
})
export class NeedNavbarComponent implements OnInit, OnDestroy {
  navigationChange: Subscription;
  permissionChange: Subscription;
  contexts: IRoleContextConfig[] = [];

  menu: IModuleNavigation[] = [];
  roleText: string;
  homeUrl: string;
  username: string;
  roles: string[];
  avatar_url: string;
  user: User;
  moduleNames = ModuleNames;

  constructor(public _navigation: NavigationService,
              private _permission: PermissionService,
              private _curUserService: CurrentUserService,
              private _deepstreamService: DeepStreamService,
              private ngZone: NgZone,
              private router: Router,
              private _translateService: TranslateService,
              private dateTimePicker: DateTimePickerTranslationService,
              private _allUserService: UserService,
              private authService: AuthService,
              private languageService: LanguageService,
              private _dialog: MdDialog,
              public filterStateService: FilterStateService,
              public navbarStateService: NavbarStateService,
              private globalEventsManager: GlobalEventsManager
  ) {
    this.navigationChange = this._navigation.changeModule
      .distinctUntilChanged()
      .subscribe((moduleKey: string) => {
        this.setActiveContextData(moduleKey);
        if (this.user) {
          setTimeout(() => this._permission.setDefaultPermissionsOnUserExpandLoad(this.user));
        }
      });
    this.permissionChange = this._permission.updateAppliedPermissions.distinctUntilChanged().subscribe(
      () => this.setActiveContextData()
    );
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.filterStateService.depositList = null;
      this.filterStateService.productPassportList = null;
      this.navbarStateService.multilanguage = false;
    });
  }

  ngOnInit() {
    const context = this._navigation.getContext();
    if (context) {
      this.setActiveContextData(context.moduleName);
    }
    if (localStorage.getItem('avatar_url') !== 'undefined') {
      this.fetchUserData();
    } else {
      this.username = 'loading';
      this._curUserService.info().subscribe((data: Response) => {
        this.updateUserData(data);
        // this.setLang(data.json().language);
      });
    }
    this._curUserService.currentUserDidChanged.subscribe(() => {
      this._curUserService.info().subscribe((userData: any) => {
        this.updateUserData(userData);
        // this.setLang(data.json().language);
      });
    });

    if (CurrentUser.infoData.id) {
      this._allUserService.view(CurrentUser.infoData.id, { expand: 'company' }).subscribe((user: User) => {
        this.user = user;
        this.user.loaded = true;
        this._permission.setDefaultPermissionsOnUserExpandLoad(this.user);
        this.setLang(user.language);
      });
    }
    if (!this.languageService.availableLanguages.includes(localStorage.getItem('language') as TLanguage)) {
      this.setLang(this._translateService.getDefaultLang());
    }
  }

  updateUserData(data: Response) {
    const result = data.json();
    localStorage.setItem('username', result.username);
    localStorage.setItem('roles', result.roles);
    localStorage.setItem('avatar_url', result.avatar_url);
    this.fetchUserData();
  }

  fetchUserData() {
    this.username = localStorage.getItem('username');
    this.avatar_url = localStorage.getItem('avatar_url');
    this.roles = localStorage.getItem('roles').split(',');
  }

  ngOnDestroy() {
    if (this.navigationChange) {
      this.navigationChange.unsubscribe();
    }
    if (this.permissionChange) {
      this.permissionChange.unsubscribe();
    }
  }

  // /**
  //  * hide for certain users (by username) report output
  //  */
  // getNavigationMenu() {
  // tslint:disable-next-line:max-line-length
  //   const allowedUsernames = ['clientmaker1', 'CircularityAdmin', 'Carbonadmin', 'Toxicityadmin', 'Financialadmin', 'Nexity', 'OlivierC', 'Nexityadmin'];
  //   let menu = this._navigation.getNavigationMenu();
  //   if (allowedUsernames.includes(this._curUserService.infoData.username)) {
  //     return menu;
  //   }
  //   return menu.filter(menuItem => menuItem.title !== 'Reporting');
  // }

  setActiveContextData(moduleKey?: string) {
    const applyRule = this.applyPermissionToContext();
    if (applyRule) {
      this.setActiveContextWithRule(applyRule);
    } else {
      this.setActiveContextWithoutPermissions();
    }
  }

  private applyPermissionToContext() {
    const context = this._navigation.getContext();
    const contexts =  this._curUserService.getAvailableContexts();
    if (this.isRestrictedAdmin) {
      return this.restrictAdminContext(context, contexts);
    }
    if (this.isBuildAdmin) {
      return this.addBuildToContext(context, contexts);
    }
    return null;
  }

  private setActiveContextWithRule(context) {
    this.menu = context.navigation;
    this.roleText = context.roleText;
    this.homeUrl = context.homeUrl;
    this.contexts = context.availableContexts;
  }

  private setActiveContextWithoutPermissions(moduleKey?: string) {
    this.menu = this._navigation.getNavigationMenu();
    this.roleText = this._navigation.getRoleText();
    this.homeUrl = this._navigation.getHomeUrl();
    this.contexts = this._curUserService.getAvailableContexts();
  }

  setLang(lang: string) {
    localStorage.setItem('language', lang);
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage && this.languageService.availableLanguages.includes(storedLanguage as TLanguage)) {
      this._translateService.use(storedLanguage);
      this.dateTimePicker.setLocal(storedLanguage);
      this.user.language = storedLanguage;
      this.user.save().subscribe();
    } else {
      this._translateService.use(this._translateService.getDefaultLang());
      this.dateTimePicker.setLocal(this._translateService.getDefaultLang());
    }

  }

  get profileUrl() {
    return (this._navigation.getContext() ? ('/' + this._navigation.getContext().rootPath) : '') + '/profile';
  }

  getAvatar() {
    if (this.avatar_url && this.avatar_url !== 'null' && this.avatar_url !== 'undefined') {
      return {'background-image': 'url(' + this.avatar_url + ')'};
    }
    return null;
  }

  openBIMModal() {
    this._dialog.open(BIMModelDialogComponent, {
      ...DIALOG_THEME.WIDE_PRIMARY,
      data: {},
    });
  }

  get isRestrictedAdmin() {
    return this._permission.hasAppliedPermission(EAdminPermission.RESTRICTED_ADMIN);
  }

  restrictAdminContext(context: IRoleContextConfig, contexts: IRoleContextConfig[])
    : IRoleContextConfig & {availableContexts: IRoleContextConfig[]} {
    const availableNavigation: Partial<IModuleNavigation> =  { url: 'admin/reporting'};
    context =  _.cloneDeep(context);
    context.navigation = context.navigation.filter(navigation => availableNavigation.url === navigation.url);
    context.homeUrl = availableNavigation.url;
    return {...context, availableContexts: contexts};
  }

  get isBuildAdmin() {
    return this._permission.hasAppliedPermission(EAdminPermission.BUILDING_ADMIN);
  }

  addBuildToContext(context: IRoleContextConfig, contexts: IRoleContextConfig[])
    : IRoleContextConfig & {availableContexts: IRoleContextConfig[]} {
    const newNavigation: IModuleNavigation =  {
      url: 'admin/administration',
      title: 'Administration',
      icon: 'icon--administration',
      disabled: false
    };
    context =  _.cloneDeep(context);
    context.navigation.push(newNavigation);
    return {...context, availableContexts: contexts};
  }

  get isBuildDepositOwner() {
    return this._permission.hasAppliedPermission(EDepositOwnerPermission.BUILDING_DO);
  }

  emitCircularSignatureClickEvent() {
    this.globalEventsManager.circularSignatureClick.emit();
  }
}
