import { Router } from "@angular/router";
import { NotificationHandler } from "../interfaces/notification-handler.interface";
import { NotificationEntry } from "../models/notification-entry.model";
import { INotificationEntrySpecialAdminType } from "../interfaces/notification-entry.interface";
import { ENotificationParamObjectType } from "../interfaces/notification-param.interface";
import { SPECIALADMIN_CONTEXT } from './../../../dashboards/specialadmin/specialadmin.context';


export class SuperAdminNotificationHandler implements NotificationHandler {
  constructor(private router: Router) { }

  handle(notification: NotificationEntry) {

    switch (notification.type) {
      case INotificationEntrySpecialAdminType.TYPE_SPECIALADMIN_USER_ACTIVATED:
        break;
      case INotificationEntrySpecialAdminType.TYPE_SPECIALADMIN_NEW_PASSPORT:
        this.navigateBasedOnNotification(notification, 'passports');
        break;
      case INotificationEntrySpecialAdminType.TYPE_SPECIALADMIN_NEW_TF:
        break;
      case INotificationEntrySpecialAdminType.TYPE_ID_SPECIALADMIN_FOR_RATING:
      case INotificationEntrySpecialAdminType.TYPE_ID_SPECIALADMIN_FOR_RATING_WITH_TOKEN:
        this.navigateBasedOnNotification(notification, SPECIALADMIN_CONTEXT.rootPath);
        break;

      default:
        console.log('Action par défaut pour une notification admin', notification);
    }
  }

  private navigateBasedOnNotification(notification: NotificationEntry, path: string): void {
    const linkID = notification.extractOption(ENotificationParamObjectType.TYPE_PASSPORT).object_id;
    if (linkID > 0) {
      this.router.navigate([path, linkID]);
    }
  }
}
